import { Icon, Text } from "@arcadia/design-system";
import React from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { StateTrendSeverity, StateTrendSeverityType } from "src/js/types";
import { useTheme } from "styled-components";
import { ChipMetricTrendBox } from "./ChipMetricTrend.styles";

const ChipMetricTrend = ({
  loading,
  trendSeverity = StateTrendSeverity.Normal,
  label
}: {
  loading?: boolean;
  trendSeverity?: StateTrendSeverityType;
  label?: string;
}) => {
  const { colors } = useTheme();

  const trendSeverityStyleMap: {
    [key in StateTrendSeverityType]: { backgroundColor: string; color: string };
  } = {
    [StateTrendSeverity.Critical]: {
      backgroundColor: colors.error[900],
      color: colors.error[400]
    },
    [StateTrendSeverity.Normal]: {
      backgroundColor: colors.success[900],
      color: colors.success[100]
    }
  };

  if (loading) {
    return (
      <Skeleton
        loading={loading}
        borderRadius={12}
        minHeight={20}
        minWidth={60}
      />
    );
  }

  if (!trendSeverity || !label) {
    return null;
  }

  const trendSeverityStyle = trendSeverityStyleMap[trendSeverity];

  return (
    <ChipMetricTrendBox {...trendSeverityStyle}>
      <Icon
        initialViewbox
        icon="arrowDropDown"
        width={8}
        height={8}
        color={trendSeverityStyle.color}
        style={
          trendSeverity === StateTrendSeverity.Normal
            ? { transform: "rotate(180deg)" }
            : {}
        }
      />
      <Text type="table"> {label}</Text>
    </ChipMetricTrendBox>
  );
};

export default ChipMetricTrend;
