import { useCallback, useEffect, useRef, useState } from "react";

const useTimeout = (callback_, timeoutDelayMs) => {
  const [isTimeoutActive, setIsTimeoutActive] = useState<boolean>(false);
  const savedRefCallback = useRef(null);

  useEffect(() => {
    savedRefCallback.current = callback_;
  }, [callback_]);

  const clear = useCallback(() => {
    setIsTimeoutActive(false);
  }, []);

  const start = useCallback(() => {
    setIsTimeoutActive(true);
  }, []);

  const callback = () => {
    if (savedRefCallback.current) {
      savedRefCallback.current();
    }
    clear();
  };

  useEffect(() => {
    if (isTimeoutActive) {
      if (typeof window !== "undefined") {
        const timeout = window.setTimeout(callback, timeoutDelayMs);
        return () => {
          window.clearTimeout(timeout);
        };
      }
    }
    return () => {};
  }, [isTimeoutActive, timeoutDelayMs]);

  return {
    clear,
    isActive: isTimeoutActive,
    start,
    stop: clear
  };
};

export default useTimeout;
