import getUserClient from "src/js/modules/connection";
import {
  api_groups_migrate,
  api_join_demo_space,
  api_join_unclaimed_space,
  api_slug_space_availability,
  api_space_creation,
  api_space_leave,
  api_space_school_search_list,
  api_space_user_stats,
  api_spaces_can_create_group,
  api_spaces_checkout,
  api_spaces_customizations,
  api_spaces_details,
  api_spaces_groups_by_space_id,
  api_spaces_groups_can_migrate,
  api_spaces_members,
  api_spaces_portal_session,
  api_spaces_templates,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import {
  BasePaginationParams,
  BasePaginationResponse,
  GroupDetailsModel,
  GroupInSpaceModel,
  SpaceDetailsModel,
  SpaceListModel,
  SpaceModel,
  SpaceTemplatesModel
} from "src/js/types";
import {
  SpaceGroupsCanMigrateParams,
  SpacesCanCreateGroupParams
} from "../components/MigrateGroupsModal/MigrateGroupsModal.types";
import { ThreadUser } from "../types/models/Thread";
import { SpaceCreationPayload, SpaceUserStats } from "./types";

export const fetchSpaceCustomization = async ({ spaceId }) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_spaces_customizations({ spaceId }), "v3"))
    .then(({ data }) => {
      return data;
    });
};

export const fetchSpaceUserStats = async ({ spaceId }: { spaceId: string }) => {
  return getUserClient()
    .get<SpaceUserStats>(
      getVersionedApiUrl(api_space_user_stats({ spaceId }), "v3")
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchSpaceGroups = async ({
  spaceId,
  limit = null,
  offset = null,
  filterQueryString = null,
  filterByUser = true
}: BasePaginationParams & {
  spaceId: string;
  filterQueryString?: string;
  filterByUser?: boolean;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<GroupInSpaceModel>>(
      getVersionedApiUrl(api_spaces_groups_by_space_id({ spaceId }), "v3"),
      {
        params: {
          limit,
          offset,
          name: filterQueryString,
          filterByUser: filterByUser ? 1 : 0
        }
      }
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchSlugAvailability = async ({ slug }) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_slug_space_availability({ slug }), "v3"))
    .then(({ data }) => {
      return data;
    });
};

export const createSpace = async (payload: SpaceCreationPayload) => {
  const spaceKeys = Object.keys(payload);
  const formData = new FormData();
  spaceKeys.forEach(key => {
    if (payload[key]) {
      formData.append(key, payload[key]);
    }
  });
  const headers = {
    "Content-Type": "multipart/form-data"
  };
  return getUserClient()
    .post<SpaceModel>(
      getVersionedApiUrl(api_space_creation(), "v3"),
      formData,
      { headers }
    )
    .then(({ data }) => {
      return data;
    });
};

export const joinUnclaimedSpace = async (spaceId: string) => {
  const { data } = await getUserClient().post(
    getVersionedApiUrl(api_join_unclaimed_space({ spaceId }), "v3")
  );
  return data;
};

export const joinDemoSpace = async () => {
  const { data } = await getUserClient().post(
    getVersionedApiUrl(api_join_demo_space(), "v3")
  );
  return data;
};

export const leaveSpace = async (spaceId: string) => {
  const { data } = await getUserClient().delete(
    getVersionedApiUrl(api_space_leave({ spaceId }), "v3")
  );
  return data;
};

export const fetchSpaceDetails = async (spaceId: string) => {
  return getUserClient()
    .get<SpaceDetailsModel>(
      getVersionedApiUrl(api_spaces_details({ spaceId }), "v3")
    )
    .then(response => {
      return response.data;
    });
};

export const fetchSpaceGroupsCanMigrate = async ({
  spaceId,
  name,
  role,
  limit = null,
  offset = null
}: SpaceGroupsCanMigrateParams) => {
  return getUserClient()
    .get<BasePaginationResponse<GroupDetailsModel>>(
      getVersionedApiUrl(api_spaces_groups_can_migrate({ spaceId }), "v3"),
      {
        params: {
          name,
          role,
          limit,
          offset
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchSpacesCanCreateGroup = async ({
  name,
  limit = null,
  offset = null
}: SpacesCanCreateGroupParams) => {
  return getUserClient()
    .get<BasePaginationResponse<SpaceModel>>(
      getVersionedApiUrl(api_spaces_can_create_group(), "v3"),
      {
        params: {
          name,
          limit,
          offset
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchGroupsMigrate = async ({
  spaceId,
  groupIds
}: {
  spaceId: string;
  groupIds: number[];
}) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_groups_migrate(), "v3"), {
      spaceId,
      groupIds
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchSpacePortalSession = async ({
  spaceId
}: {
  spaceId: string;
}) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_spaces_portal_session({ spaceId }), "v3"))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const createSpaceCheckout = async ({
  spaceId,
  plan,
  seats,
  currency,
  billing
}: {
  spaceId: string;
  plan: string;
  seats: number;
  currency: string;
  billing: string;
}) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_spaces_checkout({ spaceId }), "v3"), {
      plan,
      seats,
      currency,
      billing
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchSearchSpaceSchools = async ({
  name,
  city,
  limit = null,
  offset = null
}: BasePaginationParams & {
  name?: string;
  city: string;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<SpaceListModel>>(
      getVersionedApiUrl(api_space_school_search_list(), "v3"),
      {
        params: {
          name,
          city,
          limit,
          offset
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchSpaceMembers = async ({
  spaceId,
  textSearch,
  limit = null,
  offset = null
}: BasePaginationParams & {
  spaceId: string;
  textSearch: string;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<ThreadUser>>(
      getVersionedApiUrl(api_spaces_members({ spaceId }), "v3"),
      {
        params: {
          textSearch,
          limit,
          offset
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchSpaceTemplates = async ({ spaceId }: { spaceId: string }) => {
  return getUserClient()
    .get<SpaceTemplatesModel>(
      getVersionedApiUrl(api_spaces_templates({ spaceId }), "v3")
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};
