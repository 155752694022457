import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { fetchBoard } from "src/js/repository/boardRepository";
import { useTheme } from "styled-components";
import useStores from "../hooks/useStores";
import useBoardRealtimeEvents from "./utils/useBoardRealtimeEvents";
import { handleBoardErrorMessage } from "./utils/errorHandler";

const BoardContext = React.createContext();

export const useBoardContext = () => {
  return React.useContext(BoardContext);
};

export const useActiveBoard = () => {
  const { activeBoard } = useBoardContext();

  return activeBoard;
};

export const useSetBoardId = () => {
  const { setBoardId } = useBoardContext();

  return setBoardId;
};

export const useSetBoard = () => {
  const { setBoard } = useBoardContext();

  return setBoard;
};

const BoardProvider = ({ children }) => {
  const [activeBoard, setActiveBoard] = useState(null);
  const [boardId, setBoardId] = useState(null);

  const {
    GroupStore: { activeGroup, groupId },
    GamificationStore: { reset: resetGamificationStore },
    BoardsStore: { setActiveBoard: setActiveBoardStore, updateBoardElements }
  } = useStores();
  const { whiteTheme } = useTheme();

  const updateBoard = board => {
    setActiveBoard(board);
    setActiveBoardStore({ board, groupId });
  };
  useBoardRealtimeEvents({
    activeBoard,
    updateBoard
  });

  const setBoard = (id, reset = false) => {
    if (activeBoard && activeBoard.id === id && !reset) return;

    fetchBoard(id)
      .then(res => {
        setActiveBoard(res);
        setActiveBoardStore({ board: res, groupId });
        updateBoardElements(res, res.board_elements);
      })
      .catch(err => {
        handleBoardErrorMessage(
          err.statusCode,
          err.constraints,
          activeGroup.id,
          whiteTheme
        );
      });
  };

  useEffect(() => {
    if (!boardId) {
      setActiveBoard(null);
      resetGamificationStore();
    } else {
      setBoard(boardId);
    }
    return () => {
      resetGamificationStore();
      setActiveBoard(null);
    };
  }, [activeGroup, boardId]);

  return (
    <BoardContext.Provider
      value={{ activeBoard, setBoardId, setBoard, updateBoard }}
    >
      {children}
    </BoardContext.Provider>
  );
};

export default observer(BoardProvider);
