import { Heading } from "@arcadia/design-system";
import { __NO_GROUPS_IN_SPACE_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import styled from "styled-components";

export const SpaceCardWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  position: relative;

  @media (max-width: 767px) {
    padding: 20px 12px;
  }
`;

export const AvatarContainer = styled.div`
  width: 48px;
  height: 48px;
  font-size: 18px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
  right: 20px;
`;

export const ExpandableContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
`;

export const EmptySpaceWrapper = styled.div`
  min-height: 332px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  > span {
    max-width: 240px;
    text-align: center;
  }
`;

export const NoGroupImage = styled.div`
  background-image: url(${__NO_GROUPS_IN_SPACE_PLACEHOLDER__});
  background-repeat: no-repeat;
  background-position: center;
  height: 128px;
  width: 148px;
`;

export const EllipsedTitle = styled(Heading)`
  max-width: calc(100% - 300px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 767px) {
    max-width: calc(100% - 160px);
  }
`;

export const AllGroupsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 20px 0px 20px;
  @media (max-width: 767px) {
    padding: 24px 12px 0px 20px;
  }
`;

export const AllGroupsCheckboxWrap = styled.div`
  padding: 6px;
  height: 32px;
  width: 32px;
  margin-right: 12px;
`;

export const AllGroupsCheckboxBtn = styled.button`
  all: unset;
  cursor: pointer;
`;

export const AllGroupsOptions = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
  right: 20px;
`;
