import { ChangeEvent, useState } from "react";
import { showToastError } from "src/js/modules/messageManager";
import { __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__ } from "src/js/settings/settingFileUploader";
import { useTranslation } from "src/js/translation";
import { __BADGE_IMAGE_ACCEPTED_TYPES__ } from "../SpacesBadges.utils";

const useSelectBadge = () => {
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [croppedImagePreview, setPreview] = useState<string>(null);
  const { translate } = useTranslation();

  const checkFileSize = (file: File) => {
    if (file.size > __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__) {
      return false;
    }
    return true;
  };

  const checkFileMimes = (file: File) =>
    __BADGE_IMAGE_ACCEPTED_TYPES__.includes(file.type);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    const isFileMimeAccepted = checkFileMimes(e.target.files[0]);
    if (!isFileMimeAccepted) {
      showToastError({ str: translate("file_extension_unsupported_error") });
      return;
    }

    const fileSizeAccepted = checkFileSize(e.target.files[0]);
    if (!fileSizeAccepted) {
      showToastError({
        str: translate("file_drag_drop_size_error", {
          fileName: e.target.files[0].name
        })
      });
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  return {
    selectedFile,
    setSelectedFile,
    onSelectFile,
    croppedImagePreview,
    setPreview
  };
};

export default useSelectBadge;
