import { colors, Heading, Icon } from "@arcadia/design-system";
import styled from "styled-components";
import { SPlanCardWrapper } from "./PlanCard.types";

export const PlanCardWrapper = styled.div<SPlanCardWrapper>`
  position: relative;
  background: ${({ theme }) => theme.colors.lagoon[1000]};
  border-radius: 12px;
  padding: 24px 24px 16px 24px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : null)};
  opacity: ${({ disabled }) => (disabled ? 0.6 : null)};
  border: ${({ isSelected, theme }) =>
    isSelected
      ? `1px solid ${theme.primaryColor[500]}`
      : "1px solid transparent"};
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 8px;
  @media screen and (max-width: 767px) {
    margin-bottom: 4px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Divider = styled.hr`
  background: ${({ theme }) => theme.primaryColor[500]};
  height: 1px;
  border: 0;
  opacity: 0.1;
  margin: 12px 0;
`;

export const BodyContainer = styled.div`
  margin-top: 4px;
`;

export const CircleCheckIcon = styled(Icon)`
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${colors.grey[1000]};
`;

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.primaryColor[100]} !important;
`;
