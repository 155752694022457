import { Button } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";

import * as S from "./BannerCta.styles";
import { BannerCtaProps } from "./BannerCta.types";

const BannerCta: FC<BannerCtaProps> = ({ children, ctaText, ctaOnClick }) => {
  const { whiteTheme } = useTheme();
  const { UIStore } = useStores();
  const { isLayoutModeMobile } = UIStore;

  return (
    <S.BannerCtaContainer>
      {children}
      <Button
        onClick={ctaOnClick}
        theme={whiteTheme}
        variant="tertiary"
        fullWidth={isLayoutModeMobile}
      >
        {ctaText}
      </Button>
    </S.BannerCtaContainer>
  );
};

export default observer(BannerCta);
