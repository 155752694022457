import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import {
  __QUIZ_ANSWER_TITLE_MAX_LENGTH__,
  __QUIZ_MAX_POINTS__
} from "src/js/settings/settingsExercise";
import { Button, ContextualError, RadioGroup } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { detectWeschoolImg } from "src/js/modules/exercisesFunction";
import QuizLabel from "./QuizLabel";
import { stripHtml } from "../../../modules/utility";
import { textEditorManager } from "../../../modules/textEditorManager";
import { __, getLanguage } from "../../../modules/localization";
import { fetchResourceImageInTitle } from "./quizFunctions";

function validateQuiz(title = "", vote = 0) {
  if (title == "") {
    return "Attenzione, verificare i dati inseriti";
  }
  if (title.length > __QUIZ_ANSWER_TITLE_MAX_LENGTH__) {
    return "max-question-char-800";
  }
  if (!vote || vote < 1 || vote > __QUIZ_MAX_POINTS__) {
    return "quiz_vote_validation_error";
  }
  return true;
}

const updateEditor = () => {
  textEditorManager.destroy("quiz-title");
  if (getBreakpoint() !== "desktop") {
    textEditorManager.init("#quiz-title", "mobile", getLanguage());
  } else {
    textEditorManager.init("#quiz-title", "exercise", getLanguage());
  }
};

const QuizBuilderTrueFalse = withBackbone(
  createReactClass({
    form: "#form-quiz-builder-truefalse",
    getInitialState() {
      const { quiz } = this.props;
      const solutions = quiz.getSolutions();
      let defAnswer = "true";
      if (solutions[0] !== undefined) defAnswer = solutions[0].value.toString();
      return {
        status: "loading",
        title: quiz.getTitle(),
        solution: defAnswer,
        available_points: quiz.getAvailablePoints(),
        errorMessage: null
      };
    },
    componentDidMount() {
      const { title } = this.state;
      if (title && detectWeschoolImg(title)) {
        fetchResourceImageInTitle(title)
          .then(results => {
            this.setState({ title: results, status: "" }, () => {
              updateEditor();
            });
          })
          .catch(() => {
            this.setState({ status: "" }, () => {
              updateEditor();
            });
          });
      } else {
        this.setState({ status: "" }, () => {
          updateEditor();
        });
      }

      $(this.form).on("submit", e => {
        e.preventDefault();
      });

      this.initValidation();
    },
    componentDidUpdate() {
      this.initValidation();
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(true);
    },
    componentWillUnmount() {
      textEditorManager.destroy("quiz-title");
    },
    submitForm() {
      $(this.form).trigger("submit");
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        borderColorOnError: "",
        onError() {
          self.setState({
            errorMessage: "Attenzione, verificare i dati inseriti"
          });
        },
        onSuccess() {
          const title = stripHtml(
            textEditorManager.getContent(`${self.form} #quiz-title`)
          );
          const available_points = self.state.available_points
            ? self.state.available_points
            : 1;
          const passValidation = validateQuiz(title, available_points);
          if (passValidation === true) {
            self.addQuiz();
          } else {
            self.setState({ errorMessage: passValidation });
          }
          return false;
        }
      });
    },
    updateSolution(e) {
      this.setState({ solution: e });
    },
    addQuiz() {
      const self = this;
      const { quiz } = this.props;
      const title = textEditorManager.getContent(`${self.form} #quiz-title`);
      self.setState({ status: "loading" });

      quiz.set("type", "true_false");
      quiz.set("title", title);
      if (!self.state.available_points) {
        quiz.set("available_points", 1);
      } else {
        quiz.set("available_points", parseInt(self.state.available_points, 10));
      }
      quiz.set("questions", [
        { index: 0, value: "vero" },
        { index: 1, value: "falso" }
      ]);
      quiz.set("solutions", [
        { index: 0, value: this.state.solution === "true" },
        { index: 1, value: this.state.solution === "false" }
      ]);

      const optionalCallbackSuccess = function () {
        this.setState({ status: "" });
        this.props.backFunc();
      };

      const optionalCallbackError = function () {
        this.setState({ status: "" });
      };
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(false);
      this.props.addFunc(
        quiz,
        optionalCallbackSuccess.bind(this),
        optionalCallbackError.bind(this)
      );
    },
    render() {
      const self = this;
      const { errorMessage, title, status } = this.state;
      const { whiteTheme } = this.props.theme;

      const radioOption = [
        { label: __("VERO"), value: "true" },
        { label: __("FALSO"), value: "false" }
      ];

      return (
        <div>
          <form
            id="form-quiz-builder-truefalse"
            className="form-quiz-builder"
            role="form"
            method="post"
          >
            <div>
              {status !== "loading" && (
                <textarea
                  className="form-control text-editor"
                  id="quiz-title"
                  name="title"
                  defaultValue={self.state.title}
                  onChange={e =>
                    this.setState({ title: e.target.value, errorMessage: null })
                  }
                  placeholder={__("domanda")}
                />
              )}
            </div>
            <div>
              <QuizLabel text="risposta" />
              <div className="quiz-radio-group">
                <RadioGroup
                  theme={whiteTheme}
                  options={radioOption}
                  onChangeFunction={self.updateSolution}
                  value={self.state.solution}
                />
              </div>
            </div>
            <div>
              <QuizLabel text="quiz_creation_vote_label" />
              <div className="point-section">
                <div className="point-input">
                  <input
                    type="number"
                    id="quiz_available_points"
                    className="form-control primary"
                    defaultValue={self.state.available_points}
                    placeholder={1}
                    ref="quiz_available_points"
                    onChange={e =>
                      this.setState({
                        available_points: e.target.value,
                        errorMessage: null
                      })
                    }
                  />
                  <div className="point-label">
                    {__("quiz_creation_point_label")}
                  </div>
                </div>
                <div className="submit-quiz spaced-button-left">
                  {errorMessage && (
                    <ContextualError
                      theme={whiteTheme}
                      text={__(errorMessage)}
                    />
                  )}
                  <Button type="submit" theme={whiteTheme} variant="primary">
                    {__("save")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  })
);

export default withTheme(QuizBuilderTrueFalse);
