import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import {
  fetchUserAwardedBadges,
  updateUserAwardedBadge
} from "src/js/repository/badgesRepository";
import { useTranslation } from "src/js/translation";

const useAwardedBadges = () => {
  const {
    SpaceStore: { activeSpaceId },
    SpaceBadgesStore: { updateUserAwardedBadges, userAwardedBadges },
    UserStore: { activeUser }
  } = useStores();
  const { translate } = useTranslation();

  const getAwardedBadges = async (
    requestForSpecificSpace?: boolean,
    refetchAfterHidingBadge?: boolean
  ) => {
    if (!refetchAfterHidingBadge) {
      updateUserAwardedBadges([]);
    }
    const response = await fetchUserAwardedBadges({
      spaceId: requestForSpecificSpace ? activeSpaceId : undefined,
      isViewed: false,
      userId: activeUser?.uuid
    });

    updateUserAwardedBadges(response.results);
    return response.results;
  };

  const hideAwardedBadge = async (
    badgeId: string,
    requestForSpecificSpace?: boolean
  ) => {
    try {
      const response = await updateUserAwardedBadge(activeUser.uuid, badgeId);

      if (response.message) {
        const filteredBadges = userAwardedBadges.filter(
          badge => badge.id !== badgeId
        );
        updateUserAwardedBadges(filteredBadges);
        getAwardedBadges(requestForSpecificSpace, true);
      }
    } catch (error) {
      showToastError({
        str: translate("update_user_awarded_badge_error_message")
      });
    }
  };

  return { getAwardedBadges, hideAwardedBadge };
};

export default useAwardedBadges;
