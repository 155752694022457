import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const GroupAndSpaceContainer = styled.div`
  pointer-events: none;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 8px;
  padding: 14px 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  @media screen and (max-width: 767px) {
    padding: 20px 16px;
  }
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -4px;
  right: -4px;
  border-radius: 50%;
  border: 1px solid white;
  > div {
    height: 30px;
    width: 30px;
    div {
      height: 100%;
      width: 100%;
    }
  }
  div {
    border: unset;
    margin: 0px;
    min-height: unset;
  }

  @media screen and (max-width: 767px) {
    width: 24px;
    height: 24px;
    > div {
      height: 22px;
      width: 22px;
    }
  }
`;

export const SpaceName = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
  margin-bottom: 2px;
`;

export const ColoredText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  margin-left: auto;
`;

export const UserCount = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: auto;
  color: ${({ theme }) => theme.colors.violet[500]};
  & > span {
    font-size: 14px;
    line-height: 12px;
    margin-left: 4px;
  }
`;
