import {
  Avatar,
  AvatarWithInitials,
  Box,
  DoodleLoader,
  Heading,
  Icon,
  SidebarGroupItem,
  Text,
  ToolbarSpaceItem
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import React, { FC, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  SpaceSchoolBadge,
  SpaceSchoolBadgeVariant
} from "src/js/components/SpaceSchoolBadge";
import { HeaderNotificationToggle } from "src/js/components/layout/HeaderNotification";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { openExternalLink } from "src/js/modules/commonFunction";
import { createUrl } from "src/js/modules/routing";
import { __GROUP_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { TEMPORARY_SPACE_DEADLINE } from "src/js/settings/settingsSpaces";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { DashboardEvent, EventDomain } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { ClaimableSpaceInfo } from "../../ClaimableSpaceInfo";
import { GroupCreationModal } from "../../GroupCreationModal";
import { SpaceDurationCard, spaceDuration } from "../../SpaceDurationCard";
import { SpacesDotMenuWrapper } from "../../SpacesDotMenuWrapper";
import { ScrollableDiv } from "../../global/ScrollableDiv";
import { LeaveSpaceModal } from "../../modal/LeaveSpaceModal";
import { JoinDemoSpace } from "../JoinDemoSpace";
import CreateJoinSpaceOrGroup from "./CreateJoinSpaceOrGroup";
import NoGroupsView from "./NoGroupsView";
import * as S from "./TopHeaderSpacesMobile.styles";

const TopHeaderSpacesMobile: FC = observer(() => {
  const { translate } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateOrJoinSpaceModalOpen, setCreateOrJoinSpaceModalOpen] =
    useState(false);
  const [shouldCreateGroup, setShouldCreateGroup] = useState(false);

  const { whiteTheme, colors, primaryColor } = useTheme();
  const {
    GroupStore: { activeGroup, groupCover, groupName },
    SpaceStore: {
      activeSpace,
      spacesList,
      fetchSpacesNextPage,
      hasMoreSpaces,
      activeSpaceId,
      activeSpaceSlug,
      isAdmin,
      canCreateGroups,
      isClaimed,
      setActiveSpaceById
    },
    SpaceGroupListStore: {
      activeSpaceGroups,
      activeSpaceHasMoreGroups,
      fetchActiveSpaceGroupsNextPage
    },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const hasActiveGroup = activeGroup && activeGroup.name;
  const isTemporarySpace = activeSpace?.isTemporary;
  const { duration, durationType } = spaceDuration(
    new Date(TEMPORARY_SPACE_DEADLINE)
  );

  const onToggleHamburger = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, spacesList, activeSpace]);

  const onChangeSpace = useCallback(
    (slug: string) => () => {
      setActiveSpaceById(slug);
      navigateTo(
        createUrl("space_groups", {
          space_slug: slug
        })
      );
    },
    []
  );

  const showLeaveSpaceModal = () => {
    openModal(() => <LeaveSpaceModal closeModal={() => closeModal()} />);
    onToggleHamburger();
  };

  const createGroup = useCallback(() => {
    openModal(() => <GroupCreationModal />);
    setIsOpen(!isOpen);
  }, [isOpen]);

  const goToGroup = useCallback(
    groupId => () => {
      navigateTo(createUrl("wall", { group_id: groupId }));
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  useEffect(() => {
    if (!isOpen) return () => {};
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const viewAllGroups = () => {
    if (activeSpace) {
      navigateTo(
        createUrl("space_groups", { space_slug: activeSpace.space.slug })
      );
    } else {
      navigateTo(createUrl("home"));
    }

    setIsOpen(!isOpen);
  };

  const hasSpaceAvatar = !!(
    activeSpace &&
    activeSpace.space &&
    activeSpace.space.badge
  );

  const groupImage =
    groupCover && groupCover.small ? groupCover.small : __GROUP_PLACEHOLDER__;

  const searchPlaceholderStringVariable =
    activeGroup?.name || activeSpace?.space?.name;

  const notInTrial =
    !activeSpace?.isInTrial || !(activeSpace?.trialDaysLeft > 0);

  const renderSpaceOrLabel = () => {
    if (hasActiveGroup || hasSpaceAvatar) {
      return (
        <Avatar
          theme={whiteTheme}
          url={hasActiveGroup ? groupImage : activeSpace?.space?.badge?.small}
          size="m"
          rounded={!hasActiveGroup}
        />
      );
    }
    if (activeSpace) {
      return (
        <S.ActiveSpaceItem>
          <ToolbarSpaceItem
            id="active-space-header-item"
            theme={whiteTheme}
            badge={activeSpace.space.badge?.small as string}
            spaceName={activeSpace.space.name}
            backgroundColorCode={activeSpace.space.defaultBadgeColor}
            isSelected={false}
            size="small"
          />
        </S.ActiveSpaceItem>
      );
    }
    return (
      <Box display="flex">
        <Text type="headerTitleLg">{translate("bottom_bar_button_home")}</Text>
      </Box>
    );
  };

  const navigateToDashboard = () => {
    setIsOpen(false);
    navigateTo(
      createUrl("dashboard_analytics", {
        space_id: activeSpace?.space?.id
      })
    );
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.DashboardUserAccessSpMenu
    );
  };

  return (
    <S.HeaderMobileContainer>
      <S.HeaderMobileWrapper>
        <S.SpaceGroupContainer isActive={!!hasActiveGroup} isOpen={isOpen}>
          <S.SpaceDetailsContainer>
            <S.HamburgerContainer>
              {isOpen ? (
                <Icon
                  icon="close"
                  height={16}
                  width={16}
                  onClick={onToggleHamburger}
                />
              ) : (
                <Icon
                  icon="hamburger"
                  height={20}
                  width={20}
                  viewbox="0 0 20 20"
                  onClick={onToggleHamburger}
                />
              )}
            </S.HamburgerContainer>
            <AnimatePresence exitBeforeEnter initial={false}>
              {!isOpen ? (
                <>
                  <S.AvatarContainer
                    key="avatar-container"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                  >
                    {renderSpaceOrLabel()}
                  </S.AvatarContainer>
                  <S.SpaceAndGroupDetails
                    key="spacegroup-details"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {hasActiveGroup ? (
                      <S.SpaceName>
                        <Text type="body">
                          {translate({
                            text: "space_header_from_name",
                            stringVariables: {
                              spaceName: activeSpace?.space?.name
                            }
                          })}
                        </Text>
                      </S.SpaceName>
                    ) : null}
                    <S.NameBold>
                      {hasActiveGroup ? groupName : activeSpace?.space?.name}
                    </S.NameBold>
                  </S.SpaceAndGroupDetails>
                </>
              ) : (
                <S.Title
                  key="spacegroup-details"
                  initial={{ opacity: 0, translateY: -20 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: -20 }}
                >
                  {translate({ text: "mobile_topheader_expanded_title" })}
                </S.Title>
              )}
            </AnimatePresence>
          </S.SpaceDetailsContainer>
          <S.SpaceSettings>
            {isOpen ? (
              <Box padding={16} display="flex">
                <Icon
                  icon="plus"
                  width={16}
                  height={16}
                  onClick={() => {
                    setShouldCreateGroup(false);
                    setCreateOrJoinSpaceModalOpen(true);
                  }}
                />
              </Box>
            ) : (
              <>
                {hasActiveGroup ? (
                  <S.SpaceDotWrapper
                    onClick={() =>
                      navigateTo(
                        createUrl("group_settings", {
                          group_id: activeGroup.id,
                          option: "information"
                        })
                      )
                    }
                  >
                    <Icon icon="kebab" width={20} height={20} />
                  </S.SpaceDotWrapper>
                ) : (
                  activeSpace?.space?.id && (
                    <S.SpaceDotWrapper>
                      <SpacesDotMenuWrapper
                        isAdmin={isAdmin}
                        leaveSpace={showLeaveSpaceModal}
                        activeSpace={activeSpace}
                        activeSpaceId={activeSpaceId}
                        color={colors.grey[1000]}
                        onForceClose={() => {
                          setIsOpen(false);
                        }}
                      />
                    </S.SpaceDotWrapper>
                  )
                )}
                {activeSpace?.space?.id ? (
                  <Box padding="16px 18px 16px 12px">
                    <HeaderNotificationToggle />
                  </Box>
                ) : null}
              </>
            )}
          </S.SpaceSettings>
        </S.SpaceGroupContainer>
        <AnimatePresence initial={false}>
          {isOpen && (
            <InfiniteScroll
              scrollableTarget="header-spaces-list"
              dataLength={spacesList.length}
              next={() => {
                fetchSpacesNextPage();
              }}
              hasMore={hasMoreSpaces}
              loader={null}
            >
              <S.SpacesContainer
                id="header-spaces-list"
                key="spaces-container"
                initial={{ opacity: 0, y: -20, height: 0 }}
                animate={{ opacity: 1, y: 0, height: "auto" }}
                exit={{ opacity: 0, y: -20, height: 0 }}
              >
                {spacesList.map((s, i) => {
                  const isActiveSpace = s.space?.slug === activeSpaceSlug;
                  return (
                    <S.Space
                      key={`space-${s.space?.id}`}
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                      onClick={onChangeSpace(s.space?.slug)}
                      transition={{
                        delay: i * 0.15
                      }}
                      isActive={isActiveSpace}
                    >
                      <S.SpaceAvatar isActive={isActiveSpace}>
                        {s.unreadNotificationCount +
                          s.unreadThreadMessagesCount >
                          0 && !isActiveSpace ? (
                          <S.Badge>
                            <Text type="table">
                              {s.unreadNotificationCount +
                                s.unreadThreadMessagesCount >
                              99
                                ? "99+"
                                : s.unreadNotificationCount +
                                  s.unreadThreadMessagesCount}
                            </Text>
                          </S.Badge>
                        ) : null}
                        <AvatarWithInitials
                          theme={whiteTheme}
                          url={s.space?.badge?.small as string | undefined}
                          alt={s.space?.name}
                          name={s.space?.name}
                          avatarColor={s.space?.defaultBadgeColor}
                          size="large"
                        />
                      </S.SpaceAvatar>
                      <S.SpaceTitle>{s.space?.name}</S.SpaceTitle>
                    </S.Space>
                  );
                })}
                <JoinDemoSpace isMobile />
              </S.SpacesContainer>
            </InfiniteScroll>
          )}
        </AnimatePresence>
        <AnimatePresence initial={false}>
          {isOpen ? (
            <S.GroupWrapper
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{
                opacity: 0,
                translateY: 50,
                transition: { duration: 0.1 }
              }}
            >
              <ClaimableSpaceInfo />
              <S.HeaderWrapper>
                <Heading level="4">
                  {activeSpace?.space?.name}
                  {isClaimed ? (
                    <SpaceSchoolBadge
                      variant={SpaceSchoolBadgeVariant.Violet}
                    />
                  ) : null}
                </Heading>
                {activeSpace?.space?.id && (
                  <Box marginTop="-8px">
                    <SpacesDotMenuWrapper
                      isAdmin={isAdmin}
                      leaveSpace={showLeaveSpaceModal}
                      activeSpace={activeSpace}
                      onForceClose={() => {
                        setIsOpen(false);
                      }}
                      activeSpaceId={activeSpaceId}
                      color={colors.grey[50]}
                    />
                  </Box>
                )}
              </S.HeaderWrapper>
              {activeSpace?.groupsCount !== 0 ? (
                <Box marginBottom={12} marginTop={12}>
                  <S.SearchBar
                    variant="thinner"
                    theme={whiteTheme}
                    placeholder={translate({
                      text: searchPlaceholderStringVariable
                        ? "mobile_topheader_search_placeholder"
                        : "mobile_topheader_search_placeholder_empty",
                      stringVariables: {
                        groupName: searchPlaceholderStringVariable
                      }
                    })}
                    value=""
                    onContainerClick={() => {
                      navigateTo(createUrl("search"));
                    }}
                    disabled
                  />
                </Box>
              ) : (
                <Box marginTop={12} />
              )}
              {isTemporarySpace ? (
                <Box marginBottom={24}>
                  <SpaceDurationCard
                    variant="secondary"
                    title={translate({ text: "space_duration_card_title" })}
                    link={translate({ text: "space_duration_card_link" })}
                    onClickLink={() => {
                      openExternalLink(translate("temporary_space_link_url"));
                    }}
                    duration={duration}
                    durationType={durationType}
                  />
                </Box>
              ) : null}
              {isAdmin && (
                <S.DashboardLinkWrapper
                  id="sidebarDashboardLink"
                  onClick={navigateToDashboard}
                >
                  <Icon
                    icon="compass"
                    height={24}
                    width={24}
                    color={primaryColor[500]}
                  />
                  <Heading level="5">
                    {translate("mobile_top_header_dashboard_link")}
                  </Heading>
                </S.DashboardLinkWrapper>
              )}
              <S.GroupsTile>
                <S.GroupsInfo>
                  <Icon
                    color={primaryColor[500]}
                    icon="gridViewFilled"
                    height={24}
                    width={24}
                    initialViewbox
                  />

                  <S.MyGroupsText>
                    {translate({ text: "my_groups" })}
                  </S.MyGroupsText>
                </S.GroupsInfo>
                {canCreateGroups ? (
                  <S.PlusIcon
                    onClick={() => {
                      setIsOpen(false);
                      setShouldCreateGroup(true);
                      setCreateOrJoinSpaceModalOpen(true);
                    }}
                  />
                ) : null}
              </S.GroupsTile>
              {!activeSpaceGroups.length ? (
                <NoGroupsView onClick={createGroup} />
              ) : (
                <>
                  <Box marginLeft="-8px">
                    <ScrollableDiv
                      id="top-header-group-list"
                      maxHeight={notInTrial ? "240px" : "120px"}
                    >
                      <InfiniteScroll
                        scrollableTarget="top-header-group-list"
                        dataLength={activeSpaceGroups.length}
                        next={() => {
                          fetchActiveSpaceGroupsNextPage();
                        }}
                        hasMore={activeSpaceHasMoreGroups}
                        loader={<DoodleLoader theme={whiteTheme} isMini />}
                      >
                        {activeSpaceGroups.map(sg => {
                          const isLoading =
                            sg.group?.cloningCompleted === false;
                          return (
                            <SidebarGroupItem
                              key={sg.group?.id}
                              id={sg.group?.id as any}
                              theme={whiteTheme}
                              badge={
                                <Avatar
                                  theme={whiteTheme}
                                  url={sg.group?.cover?.small}
                                />
                              }
                              groupName={sg.group?.name}
                              notification={
                                isLoading ? 0 : sg.unreadNotificationsCount
                              }
                              isSelected={
                                hasActiveGroup &&
                                activeGroup.id === sg.group?.id
                              }
                              onClick={goToGroup(sg.group.id)}
                              isLoading={isLoading}
                            />
                          );
                        })}
                      </InfiniteScroll>
                    </ScrollableDiv>
                  </Box>
                  <S.ViewAllWrapper onClick={viewAllGroups}>
                    <S.AlignIconWrapper>
                      <Icon icon="gridView" height={24} width={24} />
                    </S.AlignIconWrapper>
                    <S.ViewAllText>
                      {translate({
                        text: "mobile_topheader_viewall_button"
                      })}
                    </S.ViewAllText>
                  </S.ViewAllWrapper>
                </>
              )}
            </S.GroupWrapper>
          ) : null}
        </AnimatePresence>
      </S.HeaderMobileWrapper>
      <CreateJoinSpaceOrGroup
        createGroup={shouldCreateGroup}
        isOpen={isCreateOrJoinSpaceModalOpen}
        setOpen={setCreateOrJoinSpaceModalOpen}
      />
    </S.HeaderMobileContainer>
  );
});

export default TopHeaderSpacesMobile;
