import { ActionButton, Box, Heading } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import Title from "src/js/components/Title";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { TopHeaderMobile } from "src/js/components/layout/TopHeaderMobile";
import { useStores } from "src/js/hooks";
import { useSpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import {
  copyEvent,
  deleteEvent,
  editEvent
} from "src/js/modules/calendarFunction";
import { showToastError } from "src/js/modules/messageManager";
import { openResource } from "src/js/modules/resourceFunction";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { EventRecipientsListModal } from "src/js/components/EventRecipientsListModal";
import { CalendarEventType } from "../calendar/Calendar.types";
import * as S from "./EventDeadline.styles";
import { EventDeadlineView } from "./EventDeadlineView";

type urlParams = {
  event_id: string;
};

const EventDeadline = () => {
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: { groupId, userIsTeacher },
    ModalStore: { openModal, closeModal },
    CalendarStore: {
      getCalendarEventDetail,
      deleteCalendarEvent,
      selectedEventDetail
    }
  } = useStores();
  const { setHeaderComponent, resetHeaderComponent } = useSpaceGroupsLayout();
  const [resourceView, setResourceView] = useState<{ ReactNode }>(null);

  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const { event_id } = useParams<urlParams>();

  const openRecipientsModal = useCallback(
    ({ group_recipients, user_recipients }) => {
      openModal(() => (
        <EventRecipientsListModal
          closeModal={() => closeModal()}
          group_recipients={group_recipients}
          user_recipients={user_recipients}
        />
      ));
    },
    []
  );

  useEffect(() => {
    if (!groupId || !event_id) return;
    getCalendarEventDetail({
      groupId,
      calendarEventId: event_id
    }).catch(() => {
      showToastError({
        str: translate("calendar_event_detail_loading_error")
      });
    });
  }, [event_id, groupId]);

  const backToCalendar = () => {
    navigateTo(createUrl("calendar", { group_id: groupId }));
  };

  const eventOpenResource = async ({ id, type }) => {
    const resView = await openResource({
      id,
      type,
      groupId,
      eventType: type,
      eventId: event_id,
      onClose: () => setResourceView(null)
    });
    if (resView) {
      setResourceView(resView);
    }
  };

  const calendarEditEvent = (eventDetail: CalendarEventType) => {
    editEvent({ eventDetail, openModal, closeModal });
  };

  const calendarCopyEvent = (eventDetail: CalendarEventType) => {
    const copiedEvent = { ...eventDetail };
    delete copiedEvent.id;
    copyEvent({
      eventDetail: copiedEvent,
      openModal,
      closeModal: () => {
        closeModal();
        backToCalendar();
      }
    });
  };

  const calendarDeleteEvent = (calendarEventId: string) => {
    deleteEvent({ calendarEventId, deleteCalendarEvent });
    backToCalendar();
  };

  const onGoBack = () => {
    const redirectToProfileUrl = createUrl("calendar", {
      group_id: groupId
    });
    navigateTo(redirectToProfileUrl);
  };

  const canEdit = userIsTeacher;

  if (isLayoutModeMobile) {
    return (
      <>
        <S.MobileLayoutWrapper>
          <TopHeaderMobile
            customHeaderTitle={translate("event_activity_mobile_meta_title")}
            withEditSaveActionButtons={() =>
              canEdit ? translate("event_activity_mobile_cta_edit") : null
            }
            handleEdit={
              canEdit
                ? () =>
                    calendarEditEvent(selectedEventDetail as CalendarEventType)
                : null
            }
          />
          <S.MobileMainContainer>
            <S.MobileViewWrapper>
              <EventDeadlineView
                eventDetail={selectedEventDetail as CalendarEventType}
                openResource={eventOpenResource}
                openRecipientsModal={openRecipientsModal}
              />
            </S.MobileViewWrapper>
            {canEdit ? (
              <S.MobileButtonDelete
                onClick={() => calendarDeleteEvent(selectedEventDetail.id)}
                theme={whiteTheme}
                variant="danger"
                fullWidth
              >
                {translate("event_activity_mobile_cta_delete")}
              </S.MobileButtonDelete>
            ) : null}
          </S.MobileMainContainer>
        </S.MobileLayoutWrapper>
        {resourceView}
      </>
    );
  }
  useEffect(() => {
    setHeaderComponent(
      isLayoutModeMobile ? (
        <HeaderBack
          onGoBack={onGoBack}
          stringTitle={selectedEventDetail?.title}
          rightText={canEdit ? "event_activity_mobile_cta_edit" : undefined}
          onClickRightSection={
            canEdit
              ? () =>
                  calendarEditEvent(selectedEventDetail as CalendarEventType)
              : undefined
          }
        />
      ) : (
        <SecondaryBar
          leftSection={
            <ActionButton
              icon="chevronLeft"
              theme={whiteTheme}
              variant="secondaryColored"
              onClick={onGoBack}
            />
          }
          middleSection={
            <Box textAlign="center">
              <Heading level="5">{selectedEventDetail?.title}</Heading>
            </Box>
          }
          rightSection={
            canEdit && (
              <Box display="flex" gap={8}>
                <ActionButton
                  icon="pencil"
                  theme={whiteTheme}
                  variant="secondaryColored"
                  onClick={() =>
                    calendarEditEvent(selectedEventDetail as CalendarEventType)
                  }
                />
                <ActionButton
                  icon="copy"
                  theme={whiteTheme}
                  variant="secondaryColored"
                  onClick={() =>
                    calendarCopyEvent(selectedEventDetail as CalendarEventType)
                  }
                />
                <ActionButton
                  icon="trash"
                  theme={whiteTheme}
                  variant="secondaryColored"
                  onClick={() => calendarDeleteEvent(selectedEventDetail?.id)}
                />
              </Box>
            )
          }
        />
      )
    );

    return () => {
      resetHeaderComponent();
    };
  }, [isLayoutModeMobile, selectedEventDetail, canEdit]);
  return (
    <>
      <Title pageTitle="event_activity_meta_title" />
      <MainContainer>
        <EventDeadlineView
          eventDetail={selectedEventDetail as CalendarEventType}
          openResource={eventOpenResource}
          openRecipientsModal={openRecipientsModal}
        />
      </MainContainer>
      {resourceView}
    </>
  );
};

export default observer(EventDeadline);
