import { useEffect } from "react";
import { useLocation } from "react-router";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import changeUrl from "src/js/modules/urlFunction";
import { getUrlParameterValue } from "src/js/modules/commonFunction";

const getFilterUrlParameters = query => {
  return {
    order_by: getUrlParameterValue(query, "order_by") || null,
    sort_order: getUrlParameterValue(query, "sort_order") || null,
    name: getUrlParameterValue(query, "name") || null,
    limit: getUrlParameterValue(query, "limit") || null,
    offset: getUrlParameterValue(query, "offset") || null,
    groups: getUrlParameterValue(query, "groups") || null,
    roles: getUrlParameterValue(query, "roles") || null
  };
};

const SpacesUsersUrlHandler = () => {
  const { SpacesUsersFilterStore, SpaceStore } = useStores();
  const { filterValuesForQueryParams } = SpacesUsersFilterStore;
  const location = useLocation();

  useEffect(() => {
    if (SpaceStore?.activeSpaceId) {
      const urlSearchParams = new URLSearchParams();
      Object.keys(filterValuesForQueryParams).map(key => {
        return (
          filterValuesForQueryParams[key] &&
          urlSearchParams.append(key, filterValuesForQueryParams[key])
        );
      });
      const params = urlSearchParams.toString();
      const locationParams = location.search;
      if (params !== locationParams.substring(1)) {
        const spaceUrl = createUrl("dashboard_users", {
          space_id: SpaceStore?.activeSpaceId
        });
        changeUrl(spaceUrl, params);
      }
    }
  }, [filterValuesForQueryParams]);

  useEffect(() => {
    const query = location.search;
    if (query) {
      const filterUrlValues = getFilterUrlParameters(query);

      SpacesUsersFilterStore.setFiltersAndPaginationFromQueryParams(
        filterUrlValues
      );
    }
  }, [location.search]);

  return null;
};

export default SpacesUsersUrlHandler;
