import getUserClient from "src/js/modules/connection";
import { api_activity, getVersionedApiUrl } from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";

function fetchActivity(groupId, start, limit, scope) {
  return getUserClient()
    .get(getVersionedApiUrl(api_activity({ group_id: groupId })), {
      params: {
        group_id: groupId,
        start,
        limit: limit + 1,
        scope
      }
    })
    .then(response => {
      const hasNext = response.data.user_statistics.length > limit;
      if (hasNext) {
        response.data.user_statistics = response.data.user_statistics.slice(
          0,
          limit
        );
      }
      return {
        data: response.data,
        hasNext,
        userStatistics: response.data.user_statistics
      };
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_activity"));
      throw Err;
    });
}

export { fetchActivity as default };
