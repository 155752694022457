import { DoodleLoader } from "@arcadia/design-system";
import styled from "styled-components";
import { MAX_WIDTH_CHARGEBEE_FRAME } from "./ChargebeeModal.conts";

export const ModalInner = styled.div`
  max-width: ${MAX_WIDTH_CHARGEBEE_FRAME}px;
  width: 100vw;
  height: 100%;
  overflow: hidden;
`;

export const StyledDoodleLoader = styled(DoodleLoader)`
  height: 100%;
  > div {
    height: 100%;
  }
`;

export const IllustrationContainer = styled.div`
  margin: 20px 0;
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 60px);
  padding: 12px;
  white-space: pre-wrap;
  text-align: center;
`;
