import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import moment from "moment";
import each from "lodash/each";
import { reportAbuse } from "src/js/repository/reportRepository";
import { fetchPublicProfile } from "src/js/repository/publicProfileRepository";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { doRouteBack } from "../../modules/history";
import { getUserId } from "../../modules/activeUser";
import { createURL, truncateTextSimple } from "../../modules/utility";
import { __, getLanguage } from "../../modules/localization";

const ReportAbuse = withBackbone(
  createReactClass({
    form: "#form-report-abuse",
    maxChars: {
      desktop: 140,
      tablet: 60,
      smartphone: 40
    },
    getDefaultProps() {
      return {
        user: null,
        basePageModel: null,
        contentModel: null,
        category: ""
      };
    },
    getInitialState() {
      return {
        datepickersInitialized: false
      };
    },
    componentDidMount() {
      if (!this.state.datepickersInitialized) {
        this.initDatepickers();
      }

      this.initValidation();
    },
    initDatepickers() {
      const self = this;
      const language = getLanguage().substring(0, 2);

      $(".datepicker").datepicker({
        format: "dd-mm-yyyy",
        startView: 2,
        autoclose: true,
        disableTouchKeyboard: true,
        keyboardNavigation: false,
        startDate: "0d",
        container: "#form-report-abuse",
        language
      });

      self.setState({ datepickersInitialized: true });
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        modules: "date",
        borderColorOnError: "",
        validateOnBlur: true,
        onError() {
          showToastError({ str: __("Attenzione, verificare i dati inseriti") });
        },
        onSuccess(form) {
          self.save();
          return false;
        }
      });
    },
    submitForm() {
      const self = this;
      $(self.form).trigger("submit");
    },
    save() {
      const { contentModel } = this.props;
      const isPost = this.props.category === "post";
      const pars = {
        report: {
          contentId: isPost ? contentModel.id : contentModel.get("id"),
          contentType: this.props.category,
          contentAuthorId: isPost
            ? contentModel.user.uuid
            : contentModel.get("user").id,
          authorId: getUserId(),
          description: $(`${this.form} #report-abuse-description`).val(),
          type: $(`${this.form} #report-abuse-category`).val(),
          created: moment().format()
        }
      };

      const fields = $(this.form).serializeArray();
      each(fields, f => {
        pars.report[f.name] = f.value;
      });

      reportAbuse(pars.report)
        .then(data => {
          showToastSuccess({ str: __("report_abuse_success_message") });
          doRouteBack();
        })
        .catch(data => {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        });
    },
    render() {
      const { contentModel } = this.props;
      let contentTitle = "";
      let contentCategory = "";
      const { whiteTheme } = this.props.theme;

      let titleLabel;

      if (this.props.category === "post") {
        contentTitle = contentModel.text;
      } else if (contentModel.has("text")) {
        contentTitle = contentModel.get("text");
      } else if (contentModel.has("name")) {
        contentTitle = contentModel.get("name");
      }

      if (contentTitle.length > 0 && this.maxChars[getBreakpoint()]) {
        contentTitle = truncateTextSimple(
          contentTitle,
          this.maxChars[getBreakpoint()]
        );
      }

      if (this.props.category != "") {
        if (this.props.category == "post") {
          contentCategory = __("report_abuse_content_category_post");
          titleLabel = __("report_abuse_post_label");
        } else if (this.props.category == "resource") {
          // contentCategory = __("report_abuse_content_category_resource");
          contentCategory = contentModel.getTypeLabel();
          titleLabel = __("report_abuse_content_label");
        }
      }

      const contentTitleFormGroup = (
        <div className="form-group smartphone-inline">
          <label className="control-label col-xs-4 col-sm-4 col-lg-4">
            {titleLabel}
          </label>
          <div className="col-xs-8 col-sm-8 col-lg-8">
            <div className="margin-top-7">{contentTitle}</div>
          </div>
        </div>
      );

      const contentCategoryFormGroup = (
        <div className="form-group smartphone-inline">
          <label className="control-label col-xs-4 col-sm-4 col-lg-4">
            {__("report_abuse_content_category_label")}
          </label>
          <div className="col-xs-8 col-sm-8 col-lg-8">
            <div className="margin-top-7">{contentCategory}</div>
          </div>
        </div>
      );

      const categorySelectionFormGroup = (
        <div className="form-group">
          <label className="control-label col-sm-4 col-lg-4">
            {__("report_abuse_category_label")}
          </label>
          <div className="col-sm-8 col-lg-8">
            <div className="select-wrapper">
              <select
                id="report-abuse-category"
                name="category"
                className="form-control light"
                data-validation="required"
                data-validation-error-msg={__("report_abuse_category_error")}
              >
                <option value="">-</option>
                <option value="copyright">
                  {__("report_abuse_category_copyright")}
                </option>
                <option value="improper">
                  {__("report_abuse_category_improper")}
                </option>
                <option value="offensive">
                  {__("report_abuse_category_offensive")}
                </option>
              </select>
            </div>
          </div>
        </div>
      );

      const descriptionFormGroup = (
        <div className="form-group">
          <label className="control-label col-sm-4 col-lg-4">
            {__("report_abuse_description_label")}
          </label>
          <div className="col-sm-8 col-lg-8">
            <textarea
              id="report-abuse-description"
              name="description"
              className="form-control light"
              rows="5"
              data-validation="length"
              data-validation-length="1-1024"
              data-validation-error-msg={__("report_abuse_description_error")}
            />
          </div>
        </div>
      );

      const phoneFormGroup = (
        <div className="form-group">
          <label className="control-label col-sm-4 col-lg-4">
            {__("report_abuse_phone_label")}
          </label>
          <div className="col-sm-8 col-lg-8">
            <input
              type="text"
              className="form-control light"
              id="report-abuse-phone"
              name="phone"
              data-validation="custom"
              data-validation-regexp="^(\+?)([0-9]+)$"
              data-validation-error-msg={__("report_abuse_phone_error")}
            />
          </div>
        </div>
      );

      return (
        <>
          <form
            id="form-report-abuse"
            className="form-horizontal margin-vertical-30"
            role="form"
            method="post"
          >
            {contentTitleFormGroup}
            {contentCategoryFormGroup}
            {categorySelectionFormGroup}
            {descriptionFormGroup}
            {phoneFormGroup}
            <div className="clearfix" />
          </form>
          <div className="flex-centered-column">
            <Button
              variant="primary"
              theme={whiteTheme}
              onClick={this.submitForm}
            >
              <Translate text="report_abuse_send_label" />
            </Button>
          </div>
        </>
      );
    }
  })
);

export default withTheme(ReportAbuse);
