import { useEffect, useReducer, useState } from "react";

/**
 * @name useDeviceDimensions
 *
 * @description
 *
 */
const DEVICES = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  TABLET_LANDSCAPE: "tablet_landscape",
  SMARTPHONE: "smartphone"
};

const useBreakpoints = (width = window.innerWidth) => {
  const [breakpoint, setBreakpoint] = useState("");
  const w = width;

  const handleResize = () => {
    if (w <= 475) setBreakpoint(DEVICES.SMARTPHONE);
    if (w > 476 && w <= 768) setBreakpoint(DEVICES.TABLET);
    if (w > 768 && w <= 992) setBreakpoint(DEVICES.TABLET_LANDSCAPE);
    if (w >= 1024) setBreakpoint(DEVICES.DESKTOP);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return {
    isDesktop: breakpoint === DEVICES.DESKTOP,
    isTablet: breakpoint === DEVICES.TABLET,
    isTabletLandscape: breakpoint === DEVICES.TABLET_LANDSCAPE,
    isSmartphone: breakpoint === DEVICES.SMARTPHONE
  };
};

/**
 * @name useDeviceDimensions
 *
 * @description
 *
 */
const useDeviceDimensions = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    width: windowSize.width,
    height: windowSize.height,
    sizes: windowSize
  };
};

/**
 * @name useDeviceDetector
 *
 * @description
 *
 */
const initialDeviceState = {
  UA: navigator.userAgent,
  isTouchDevice: false,
  dimensions: { width: 0, height: 0 },
  isTablet: false,
  isDesktop: false,
  orientation: ""
};

const ACTIONS = {
  SET_DEVICE: "SET_DEVICE",
  SET_DIMENSIONS: "SET_DIMENSIONS"
};

const deviceReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DEVICE:
      return { ...state, ...action.payload };
    case ACTIONS.SET_DIMENSIONS:
      return { ...state, dimensions: action.payload.dimensions };
    default:
      return state;
  }
};

const useDeviceDetector = () => {
  // hook esistente
  const { sizes } = useDeviceDimensions();

  // hook esistente
  const breakpoints = useBreakpoints(sizes.width);

  //
  const [state, dispatch] = useReducer(deviceReducer, initialDeviceState);

  const getDeviceType = () => {
    const { UA } = state;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      UA
    );
  };

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_DEVICE,
      payload: {
        isTouchDevice: getDeviceType(),
        isTablet: breakpoints.isTablet,
        isTabletLandscape: breakpoints.isTabletLandscape,
        isSmartphone: breakpoints.isSmartphone,
        isDesktop: breakpoints.isDesktop
      }
    });
  }, [sizes]);

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_DIMENSIONS,
      payload: {
        dimensions: sizes
      }
    });
  }, [sizes]);

  const {
    UA,
    isTouchDevice,
    dimensions,
    isTablet,
    isTabletLandscape,
    isSmartphone,
    isDesktop
  } = state;

  return {
    UA,
    isTouchDevice,
    dimensions,
    isTablet,
    isTabletLandscape,
    isSmartphone,
    isDesktop
  };
};

export default useDeviceDetector;
export { useBreakpoints, useDeviceDimensions };
