import { mobileMixin } from "src/js/theme/breakpointsMixin";
import styled from "styled-components";

export const BoardsProgressRecapLoading = styled.div`
  width: 245px;
  height: 333px;
  background: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  ${mobileMixin`
    width: 100% !important;
    height: 167px !important;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `}
`;

export const BoardsProgressRecap = styled.div`
  width: 245px;
  height: 333px;
  background: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  ${mobileMixin`
    width: 100% !important;
    height: 167px !important;
    padding: 10px;
    flex-direction: row;
  `}
`;

export const BoardsProgressRecapHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;

  ${mobileMixin`
    font-size: 14px;
  `}
`;

export const ContentWrapper = styled.div`
  padding: 20px 0 36px 0;

  ${mobileMixin`
    padding: 0;
    margin: auto 0;
  `}
  display: flex;
  justify-content: center;
`;

export const TooltipWrapper = styled.div`
  margin-left: auto;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .rc-tooltip-inner {
    padding: 8px;
    color: ${({ theme }) => theme.colors.basicBlack};
    font-family: ${({ theme }) => theme.fontFamily.title};
    font-size: 10px;
    background-color: ${({ theme }) => theme.colors.paleGray};
    border-radius: 4px;
    min-height: 22px;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${({ theme }) => theme.colors.paleGray};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${({ theme }) => theme.colors.paleGray};
  }

  svg {
    ${mobileMixin`
      width: 16px !important;
      height: 16px !important;
    `}
  }
`;

export const HeaderAndGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mobileMixin`

  `}

  flex-shrink: 0;
`;
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${mobileMixin`
    margin-left: auto;
    margin-right: auto;
  `}
`;
