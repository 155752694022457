import { Box, Icon, Label } from "@arcadia/design-system";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { useDebounceCallback } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";
import styled, { useTheme } from "styled-components";
import Dropdown from "../../components/dropdown";

const StyledReorderGroup = styled(Reorder.Group)`
  list-style-type: none;
  padding: 0;
  user-select: none;
  .exercise-builder-quiz-element {
    border-left-color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

const QuizItem: FC<{
  quiz: any;
  showEditQuiz: any;
  removeQuiz: any;
}> = ({ quiz, showEditQuiz, removeQuiz }) => {
  const { whiteTheme } = useTheme();
  const y = useMotionValue(0);
  const controls = useDragControls();

  const id = quiz.get("id");

  const dropdownOptions = [
    <li role="presentation" key="edit">
      <a role="menuitem" tabIndex={-1} onClick={() => showEditQuiz(quiz)}>
        <Translate text="modifica" />
      </a>
    </li>,
    <li role="presentation" key="delete">
      <a role="menuitem" tabIndex={-1} onClick={() => removeQuiz(quiz)}>
        <Translate text="elimina" />
      </a>
    </li>
  ];

  const optionsBox = (
    <div className="exercise-builder-element-options dropdown col-xs-2 col-sm-2 col-lg-1 text-right">
      <a
        className="exercise-builder-element-options-link"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <Icon icon="kebabVertical" width={20} height={20} />
      </a>
      {/*  @ts-expect-error - Legacy stuff */}
      <Dropdown items={dropdownOptions} />
    </div>
  );

  return (
    <Reorder.Item
      value={quiz}
      id={id}
      style={{ y }}
      className="app__box exercise-builder-quiz-element js-sortable-item"
      dragControls={controls}
      dragListener={false}
    >
      <Box
        touchAction="none"
        onPointerDown={e => controls.start(e)}
        className="reorder-handle js-sortable-item-handle"
      >
        <Icon icon="upndown" width={30} height={30} color="000000" />
      </Box>
      <div className="row exercise-builder-quiz-element-wrapper">
        <div className="exercise-builder-quiz-element-info col-xs-10 col-sm-10 col-lg-11">
          <Label theme={whiteTheme} color="primary" outline={false}>
            <strong>{quiz.getAvailablePoints()}</strong>&nbsp;
            <Translate
              text={
                quiz.getAvailablePoints() > 1
                  ? "quiz_points_label"
                  : "quiz_point_label"
              }
            />
          </Label>
          <div className="exercise-builder-quiz-element-title mathjax-container margin-top-5">
            <ExerciseQuizTitle quizTitle={quiz.get("title")} />
          </div>
          <div className="exercise-builder-quiz-element-type">
            <Translate text={quiz.getType()} />
          </div>
        </div>
        {optionsBox}
        <div className="clearfix" />
      </div>
    </Reorder.Item>
  );
};

const QuizList = ({
  quizzes,
  quizStatus,
  showEditQuiz,
  removeQuiz,
  updateQuizOrder
}) => {
  const sortedQuizzes = quizzes.sortBy("position");

  const [localQuizzes, setLocalQuizzes] = useState(sortedQuizzes);

  useEffect(() => {
    setLocalQuizzes(sortedQuizzes);
  }, [quizzes]);

  const handleQuizOrder = useDebounceCallback(quizzes => {
    updateQuizOrder(quizzes);
  }, 1000);

  const onReorder = reorderQuizzes => {
    handleQuizOrder(reorderQuizzes);
    setLocalQuizzes(reorderQuizzes);
  };

  return (
    <StyledReorderGroup
      axis="y"
      values={localQuizzes}
      onReorder={onReorder}
      className={quizStatus === "loading" ? "faded" : ""}
    >
      {localQuizzes.map(quiz => (
        <QuizItem
          key={`quiz-item-${quiz.get("id")}`}
          quiz={quiz}
          showEditQuiz={showEditQuiz}
          removeQuiz={removeQuiz}
        />
      ))}
    </StyledReorderGroup>
  );
};

export default QuizList;
