import { ConfirmationAlert } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Row } from "react-awesome-styled-grid";
import { useParams } from "react-router";
import { ModuleResourcesList } from "src/js/components/modules/ModuleResourcesList";
import { ModulesResourcesFilter } from "src/js/components/modules/ModulesResourcesFilter";
import { useBoardContext } from "src/js/context/BoardProvider";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { moveModuleResources } from "src/js/modules/moduleFunction";
import createUrl from "src/js/modules/routing";
import { ModulesDraftListView } from "src/js/pages/modules/ModulesDraftListView";
import { updateBoard as putBoard } from "src/js/repository/boardRepository";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { BoardElement, ModuleResourceType, OldBoard } from "src/js/types";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import { navigateTo, replaceTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import MobilePublishButton from "./MobilePublishButton";
import { useBoardResources } from "./hooks/useBoardResources";
import { ModuleResourceViewer } from "src/js/pages/modules/ModuleResourceViewer";

type Props = {
  target: string;
};

const removeBlanksFromBoardElements = (boardElements: BoardElement[]) => {
  if (boardElements.length === 0) return [];
  const boardElementsCopy = structuredClone(boardElements);
  const reorderedBoardElements = boardElementsCopy
    ?.sort((a, b) => a.position - b.position)
    .map((el, index) => {
      if (el.position === index) {
        return el;
      }
      return {
        ...el,
        position: index
      };
    });
  return reorderedBoardElements;
};

const ModuleDetail = ({ target }: Props) => {
  const { mode, element_id, group_id, module_id } = useParams<{
    mode?: string;
    group_id: string;
    module_id: string;
    element_id?: string;
  }>();
  const {
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    GroupStore: { activeGroup },
    BoardsStore: { getSingleBoard }
  } = useStores();
  const { updatePageTitle } = useViewTracking();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    activeBoard,
    setBoard: saveBoard,
    updateBoard
  }: {
    activeBoard: OldBoard;
    setBoardId: (id: string) => void;
    setBoard: (id: string, reset?: boolean) => void;
    updateBoard: (board: OldBoard) => void;
  } = useBoardContext();

  const { boardElementGrid, hasDrafts, boardElements } = useBoardResources({
    isDraft: Boolean(mode === "drafts"),
    board: activeBoard
  });

  const [resourceComponent, setResourceComponent] = useState(null);

  useEffect(() => {
    if (target === "view") {
      updatePageTitle(activeBoard.name);
    }
  }, [activeBoard]);

  useEffect(() => {
    // if you are in the draft page but the list is empty, redirect to the view mode
    if (mode !== "drafts") return;
    if (!hasDrafts) {
      navigateTo(
        createUrl("module_detail", {
          group_id: activeGroup.id,
          module_id: `${activeBoard.id}`
        })
      );
    }
  }, [mode, hasDrafts]);

  useEffect(() => {
    RealTimeDispatcher.on("resource_preview_generated", () => {
      saveBoard(`${activeBoard.id}`, true);
    });
    return () => {
      RealTimeDispatcher.off("resource_preview_generated");
    };
  }, []);

  useEffect(() => {
    if (!element_id) return;
    const resourcesSelected = activeBoard.board_elements.find(
      element => element.id === Number(element_id)
    );
    showResource(resourcesSelected?.position || 0);
  }, [element_id]);

  const removeResource = el_id => {
    const filteredElements = activeBoard.board_elements.filter(
      el => el.id !== el_id
    );

    let reorderedBoardElements = filteredElements;
    if (activeBoard.has_mandatory_order) {
      reorderedBoardElements = removeBlanksFromBoardElements(filteredElements);
    }

    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("La risorsa sarà eliminata")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          putBoard(activeBoard.id, {
            ...activeBoard,
            board_elements: reorderedBoardElements
          })
            .then(res => {
              showToastSuccess({ str: translate("resource_delete_success") });
              saveBoard(res.id, true);
            })
            .catch(e => {
              const error = extractErrorMessage(
                e || {},
                translate(
                  "Non hai i permessi per aggiornare questa board" as TranslationKeys
                )
              );
              showToastError({ str: error });
            });
          closeConfirmDialog();
        }}
      />
    );
  };

  const showResource = position => {
    const urlLocation = createUrl("module_detail", {
      group_id: activeGroup.id,
      module_id: `${activeBoard.id}`
    });

    const onClose = () => {
      saveBoard(`${activeBoard.id}`, true);
      setResourceComponent(null);
      replaceTo(urlLocation);
    };
    // TODO find a better strategy for the BIG MODAL thing
    setResourceComponent(
      <ModuleResourceViewer indexDefault={position} onClose={onClose} />
    );
  };

  const moveResource = ({ id, nextPosition }) => {
    const previousSortState = activeBoard.board_elements;
    if (!boardElementGrid) return;

    const board = getSingleBoard({
      groupId: activeGroup.id,
      boardId: activeBoard.id
    });
    if (board.hasMandatoryOrder) {
      if (
        board.isPublished ||
        nextPosition > previousSortState.length - 1 ||
        nextPosition < 0
      ) {
        showToastError({
          str: translate("board_element_list_cant_reorder_error_message")
        });
        return;
      }
    }
    const newSortState = moveModuleResources({
      resourcesList: previousSortState,
      id,
      nextPosition
    });

    const sortedActiveBoard = {
      ...activeBoard,
      board_elements: newSortState
    };
    // Optimistically update the board to make the UX smoother
    updateBoard(sortedActiveBoard);
    putBoard(activeBoard.id, sortedActiveBoard)
      .then(res => {
        saveBoard(res.id, true);
      })
      .catch(e => {
        const error = extractErrorMessage(
          e || {},
          translate(
            "Non hai i permessi per aggiornare questa board" as TranslationKeys
          )
        );
        // On failure, restore the previous state
        updateBoard({ ...activeBoard, board_elements: previousSortState });
        showToastError({ str: error });
      });
  };

  return (
    <>
      <div className="board__detail-container js-board__detail-container">
        {/* Hide ContextSwitch in case of mandatory order board */}
        {!activeBoard.has_mandatory_order && hasDrafts && target !== "new" ? (
          <ModulesResourcesFilter
            moduleId={String(activeBoard.id)}
            tabSelected={mode || "detail"}
          />
        ) : null}
        <div className="margin-vertical-15">
          <Row>
            {mode === "drafts" ? (
              <ModulesDraftListView
                draftList={boardElements}
                moduleId={activeBoard.id}
                updateModule={() => {
                  saveBoard(String(activeBoard.id), true);
                }}
                boardIsConstrained={!!activeBoard.constrainted_boards?.length}
                hasMandatoryOrder={activeBoard.has_mandatory_order}
              />
            ) : (
              <ModuleResourcesList
                // TODO: remove this cast and fix the type
                moduleResourcesList={boardElementGrid as ModuleResourceType[]}
                removeResource={removeResource}
                moduleId={activeBoard.id}
                moveModuleResource={({ id, nextPosition }) => {
                  moveResource({ id, nextPosition });
                }}
                onClickResource={element => {
                  navigateTo(
                    createUrl("module_element", {
                      group_id: Number(group_id),
                      module_id,
                      element_id: String(element.id)
                    })
                  );
                }}
              />
            )}
          </Row>
        </div>
        <MobilePublishButton />
      </div>
      <div className="resource-manager-container hidden" />
      {resourceComponent}
    </>
  );
};

export default observer(ModuleDetail);
