export enum GroupUserRoleEnum {
  Creator = "ROLE_CREATOR",
  Teacher = "ROLE_TEACHER",
  Student = "ROLE_STUDENT",
  Support = "ROLE_SUPPORT"
}

export type GroupUserRoleFilters =
  | GroupUserRoleEnum.Teacher
  | GroupUserRoleEnum.Student;
