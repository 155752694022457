import React from "react";
import { __BOARD_COLOR__ } from "src/js/settings/settingsBoard";
import { Icon } from "@arcadia/design-system";
import * as S from "./MilestoneBanner.styles";

const MilestoneBanner = ({ color, longVariant = false }) => {
  return (
    <S.MilestoneBannerContainer color={__BOARD_COLOR__.get(color)}>
      <Icon
        icon={longVariant ? "bannerLong" : "bannerShort"}
        width={44}
        height={longVariant ? 100 : 74}
        viewbox={`0 0 44 ${longVariant ? 100 : 74}`}
      />
    </S.MilestoneBannerContainer>
  );
};

export default MilestoneBanner;
