import {
  ActionButton,
  Avatar,
  Box,
  DoodleLoader,
  Heading,
  Text
} from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import UserPoints from "src/js/components/UserPoints";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { showToastError } from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { fetchPublicProfile } from "src/js/repository/publicProfileRepository";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { wrapAnchorme } from "src/legacy/modules/utility";
import { useTheme } from "styled-components";
import * as S from "./ProfilePage.styles";
import { User } from "./ProfilePage.types";
import { BadgeEarnedList } from "./components/BadgeEarnedList";
import { GroupsInCommon } from "./components/GroupsInCommon";
import { GroupsInCommonHandle } from "./components/GroupsInCommon/GroupsInCommon.types";

const ProfilePage = () => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const { goBack } = useHistory();
  const { whiteTheme } = useTheme();
  const { headerHeight, setHeaderComponent, resetHeaderComponent } =
    useAppLayout();
  const { translate } = useTranslation();
  const {
    UserStore,
    ModalStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const { params } = useRouteMatch<{ userUuid: string }>();
  const groupsInCommonRef = useRef<GroupsInCommonHandle>();
  const pageWrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (ModalStore) {
      ModalStore.closeModal();
    }
  }, [ModalStore]);

  const isMe = useMemo(
    () => `${UserStore.activeUser.uuid}` === params.userUuid,
    [params.userUuid, UserStore.activeUser.uuid]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const pr = pageWrapperRef.current;
    const onScroll = () => {
      if (
        Math.floor(pr.scrollHeight - pr.scrollTop) ===
        Math.floor(pr.clientHeight)
      ) {
        groupsInCommonRef.current.fetchMore();
      }
    };
    if (pr) {
      pr.addEventListener("scroll", onScroll);
      return () => pr.removeEventListener("scroll", onScroll);
    }
  }, [loading]);

  useEffect(() => {
    if (!params.userUuid) {
      navigateTo(createUrl("home"));
      return;
    }
    setLoading(true);
    fetchPublicProfile(params.userUuid)
      .then((result: User) => {
        setUser(result);
        setLoading(false);
      })
      .catch(error => {
        showToastError({ str: error.message });
        setLoading(false);
        navigateTo(createUrl("home"));
      });
  }, [params.userUuid]);

  useEffect(() => {
    if (!isLayoutModeMobile) return;
    setHeaderComponent(
      <HeaderBack
        onGoBack={goBack}
        title={
          isMe
            ? "profile_page_personal_information"
            : "profile_page_profile_title"
        }
        rightText={isMe ? "modifica" : undefined}
        onClickRightSection={() => navigateTo(createUrl("profile_edit"))}
      />
    );
    // eslint-disable-next-line consistent-return
    return () => {
      resetHeaderComponent();
    };
  }, [isMe, isLayoutModeMobile]);

  return loading || !user ? (
    <DoodleLoader theme={whiteTheme} />
  ) : (
    <S.PageWrapper id="pagewrapper" ref={pageWrapperRef}>
      <S.SecondaryHeaderContainer>
        <SecondaryBar
          position="sticky"
          top={headerHeight}
          middleSection={
            <Box display="flex" alignItems="center" justifyContent="center">
              <Heading level="5">
                {translate({
                  text: isMe
                    ? "profile_page_personal_information"
                    : "profile_page_profile_title"
                })}
              </Heading>
            </Box>
          }
          leftSection={
            <Box display="flex" justifyContent="center" alignItems="center">
              <ActionButton
                variant="secondaryColored"
                icon="chevronLeft"
                theme={whiteTheme}
                onClick={() => goBack()}
              />
            </Box>
          }
          rightSection={
            isMe ? (
              <S.LinkWrapper
                onClick={() => navigateTo(createUrl("profile_edit"))}
              >
                <Text type="textLink">
                  {translate({ text: "edit_profile" })}
                </Text>
              </S.LinkWrapper>
            ) : undefined
          }
        />
      </S.SecondaryHeaderContainer>
      <Box marginTop={24} position="relative">
        <Avatar
          size="xl"
          theme={whiteTheme}
          alt={`${user.name} ${user.surname}`}
          url={user.avatar?.originalUrl ?? ""}
        />
        <UserPoints userId={user.userId} />
      </Box>
      <Box marginTop={16}>
        <Heading level="5">
          {user.name} {user.surname}
        </Heading>
      </Box>
      <Box
        paddingLeft={20}
        paddingRight={20}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <S.InfoWrapper>
          {user.birthday ? (
            <S.InfoContainer>
              <S.InfoIcon width={16} height={16} icon="birthday" />
              <Text type="body">
                {format(new Date(user.birthday), "dd-MM-yyyy")}
              </Text>
            </S.InfoContainer>
          ) : undefined}
          {user.email ? (
            <S.InfoContainer>
              <S.InfoIcon width={16} height={16} icon="mailOutline" />
              {user.email}
            </S.InfoContainer>
          ) : undefined}
          {user.phone ? (
            <S.InfoContainer>
              <S.InfoIcon width={16} height={16} icon="whatsapp" />
              {user.phone}
            </S.InfoContainer>
          ) : undefined}
          {user.instagram ? (
            <S.InfoContainer>
              <S.InfoIcon width={16} height={16} icon="instagram" />
              {user.instagram}
            </S.InfoContainer>
          ) : undefined}
          {user.facebook ? (
            <S.InfoContainer>
              <S.InfoIcon width={16} height={16} icon="facebook" />
              {user.facebook}
            </S.InfoContainer>
          ) : undefined}
        </S.InfoWrapper>
        {user.status ? (
          <S.InfoWrapper>
            <Box padding={12}>
              <Text type="formTitle">
                {translate({ text: "public_profile_bio_label" })}
              </Text>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: wrapAnchorme(user.status)
                }}
              />
            </Box>
          </S.InfoWrapper>
        ) : undefined}
        {user.showBadges ? (
          <S.InfoWrapper>
            <BadgeEarnedList
              userUuid={user.userUuid}
              userFullName={`${user.name} ${user.surname}`}
            />
          </S.InfoWrapper>
        ) : null}
        <GroupsInCommon ref={groupsInCommonRef} userUuid={user.userUuid} />
      </Box>
    </S.PageWrapper>
  );
};

export default observer(ProfilePage);
