import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";

import * as S from "./SocialLoginButton.styles";
import { SocialLoginButtonProps } from "./types";

const SocialLoginButton: FC<SocialLoginButtonProps> = ({
  theme,
  icon,
  viewBox = "0 0 18 18",
  className = undefined,
  onClick
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.Button onClick={onClick} className={className}>
        <Icon icon={icon} height="24px" width="24px" viewBox={viewBox} />
      </S.Button>
    </ThemeProvider>
  );
};

export default SocialLoginButton;
