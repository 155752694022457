import { QueryStatus } from "@tanstack/react-query";
import { useMemo } from "react";

import { AdditionalQueryOptions } from "./types";

const useIsEmptyQueryResult = <TData>({
  transformEmptyResult,
  data,
  status
}: {
  transformEmptyResult: AdditionalQueryOptions<TData>["transformEmptyResult"];
  data: TData | undefined;
  status: QueryStatus;
}) => {
  const isEmpty = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let isEmpty = false;

    if (transformEmptyResult) {
      isEmpty = transformEmptyResult(data);
    } else if (Array.isArray(data)) {
      isEmpty = data.length === 0;
    } else if (data === undefined) {
      isEmpty = true;
    }

    return isEmpty && status !== "loading";
  }, [data, status, transformEmptyResult]);

  return isEmpty;
};

export default useIsEmptyQueryResult;
