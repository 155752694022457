import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { userIsUnderage } from "src/js/UserFunction";
import { SpaceFreeTrialModal } from "src/js/components/SpaceFreeTrialModal";
import { HeroLayout } from "src/js/components/layout/HeroLayout";
import { useStores } from "src/js/hooks";
import {
  destroyLocalData,
  readLocalData
} from "src/js/modules/storageFunction";
import createUrl from "src/js/modules/routing";
import { __COOKIE_JOIN_GROUP__ } from "src/js/settings/settingsCookie";
import {
  __ACTIVATE_ACCOUNT_BG_1_DESKTOP__,
  __ACTIVATE_ACCOUNT_BG_3_DESKTOP__,
  __ACTIVATE_ACCOUNT_BG_4_DESKTOP__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  NewUserRegistrationEvent,
  SpaceModel
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { ActivateAccountPageType } from "./ActivateAccount.types";
import { SchoolClaimPage } from "./SchoolClaimPage";
import { SpaceCreationPage } from "./SpaceCreationPage";
import { UserVerificationPage } from "./UserVerificationPage";
import { HubSpotActivateAccountUser } from "../../repository/hubspotRepository";

const ActivateAccount = () => {
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal },
    UserStore: { activeUser }
  } = useStores();
  const { colors } = useTheme();
  const { option } = useParams<{ option?: string }>();

  const newSpaceRef = useRef<SpaceModel>(null);
  const [page, setPage] = useState<ActivateAccountPageType>(
    ActivateAccountPageType.Verification
  );

  useEffect(() => {
    if (option === "spacecreation") {
      setPage(ActivateAccountPageType.Creation);
    }
  }, [option]);

  const isUnderage =
    activeUser?.birthday && userIsUnderage({ birthday: activeUser?.birthday });

  const goToCreation = () => setPage(ActivateAccountPageType.Creation);
  const goToClaim = () => setPage(ActivateAccountPageType.Claim);
  const onComplete = (newSpace?: SpaceModel) => {
    if (page === ActivateAccountPageType.Verification) {
      _trackEvent(
        EventDomain.NewUserRegistration,
        NewUserRegistrationEvent.UserVerificationForm,
        new Date().getTime()
      );
      HubSpotActivateAccountUser({ ...activeUser, terms: true });
      if (newSpaceRef?.current?.slug && !isUnderage) {
        navigateTo(
          createUrl("space_groups", { space_slug: newSpaceRef.current.slug })
        );
        setTimeout(() => {
          openModal(() => <SpaceFreeTrialModal closeModal={closeModal} />);
        }, 2000);
      } else {
        const groupInvited = readLocalData(__COOKIE_JOIN_GROUP__);
        if (groupInvited) {
          navigateTo(`/group/${groupInvited}/wall`);
          destroyLocalData(__COOKIE_JOIN_GROUP__);
        } else {
          navigateTo(createUrl("home"));
        }
      }
    } else {
      if (newSpace) {
        newSpaceRef.current = newSpace;
      }
      setPage(ActivateAccountPageType.Verification);
    }
  };

  switch (page) {
    case ActivateAccountPageType.Creation:
      return (
        <HeroLayout
          desktop={{
            background: __ACTIVATE_ACCOUNT_BG_1_DESKTOP__,
            title: translate("space_creation_hero_title"),
            description: translate("space_creation_hero_description"),
            children: (
              <SpaceCreationPage
                goToClaim={goToClaim}
                onComplete={onComplete}
              />
            )
          }}
          mobile={{
            backgroundColor: colors.warning[700],
            children: (
              <SpaceCreationPage
                goToClaim={goToClaim}
                onComplete={onComplete}
              />
            )
          }}
        />
      );
    case ActivateAccountPageType.Claim:
      return (
        <HeroLayout
          desktop={{
            background: __ACTIVATE_ACCOUNT_BG_4_DESKTOP__,
            title: translate("school_claim_hero_title"),
            description: translate("school_claim_hero_description"),
            children: (
              <SchoolClaimPage
                backgroundColor={colors.grey[1000]}
                goToCreation={goToCreation}
                onComplete={onComplete}
              />
            )
          }}
          mobile={{
            backgroundColor: colors.cherry[800],
            children: (
              <SchoolClaimPage
                backgroundColor={colors.cherry[800]}
                goToCreation={goToCreation}
                onComplete={onComplete}
              />
            )
          }}
        />
      );
    case ActivateAccountPageType.Verification:
    default:
      return (
        <HeroLayout
          desktop={{
            background: __ACTIVATE_ACCOUNT_BG_3_DESKTOP__,
            title: translate("user_verification_hero_title"),
            description: translate("user_verification_hero_description"),
            children: <UserVerificationPage onComplete={onComplete} />
          }}
          mobile={{
            backgroundColor: colors.violet[700],
            children: <UserVerificationPage onComplete={onComplete} />
          }}
        />
      );
  }
};

export default ActivateAccount;
