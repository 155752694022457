import { ActionButton, Box, Icon, colors } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import WeschoolLogo from "src/img/icons/svg/weschool_logo.svg";
import { INPUT_VARIANT, Input } from "src/js/components/forms/input";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import { useStores } from "src/js/hooks";
import { UnloggedRegisterLayout } from "src/js/layout/unloggedLayout/UnloggedRegisterLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { copyToClipboard } from "src/js/modules/commonFunction";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl, { createStaticUrl } from "src/js/modules/routing";
import UnderageLockForm from "src/js/pages/user/UnderageLockForm";
import { sendTutorRequest } from "src/js/repository/tutorRepository";
import { fetchUserApproveCode } from "src/js/repository/userRepository";
import {
  __PRIVACY_LINK__,
  __TERMS_LINK__
} from "src/js/settings/settingsGeneral";
import { __URL_BASE__ } from "src/js/settings/settingsUrl";
import {
  Translate,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { EventDomain, UserEvent } from "src/js/types";
import { RegistrationInviteOptionsContainer } from "../registration/RegistrationOptions/RegistrationOptions.styles";

function createApprovalUrl(approbationCode) {
  const urlToSent = `${__URL_BASE__}#${createStaticUrl(
    "parent_activation_url",
    {
      user_code: approbationCode
    }
  )}`;
  return urlToSent;
}

const UnderageLock = () => {
  const { UserStore } = useStores();
  const [personalCode, setPersonalCode] = useState();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  // waiting for the refactor to ts it's safer to map the type in a specific obj to be sure to have all the translation in it
  const UnderageLockMainTrans = {
    user_name: UserStore.activeUser.name,
    privacy_link: __PRIVACY_LINK__,
    terms_link: translate({ text: __TERMS_LINK__ })
  };

  useEffect(() => {
    fetchUserApproveCode()
      .then(response => {
        setPersonalCode(response);
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  }, []);

  const submitFunction = data => {
    sendTutorRequest(data)
      .then(() => {
        _trackEvent(
          EventDomain.User,
          UserEvent.UnderageLock,
          "TutorEmailRequest"
        );
        showToastSuccess({ str: <Translate text="tutor_request_send" /> });
      })
      .catch(() => {
        showToastError({ str: <Translate text="tutor_request_error" /> });
      });
  };

  const urlForParent = createApprovalUrl(personalCode);

  return (
    <UnloggedRegisterLayout pageTitle="confirm_mail_meta_title">
      <RegistrationInviteOptionsContainer marginBottom={24} marginTop={24}>
        {!isLayoutModeMobile ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom={24}
          >
            <WeschoolLogo
              width="146"
              height="23"
              viewBox="0 0 207 32"
              color={colors.violet[501]}
            />
          </Box>
        ) : null}
        <div className="medium-title">
          <Translate text="underage_lock_title" />
        </div>
        <div
          className="margin-top-20"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: translate("underage_lock_main", {
              ...UnderageLockMainTrans
            })
          }}
        />
        <div className="margin-top-30">
          <label htmlFor="urlConfirmation" className="form-label">
            <Translate text="share_link" />
          </label>
          {personalCode && (
            <div className="flex">
              <div className="flex-1">
                <Input
                  id="urlConfirmation"
                  disabled
                  value={urlForParent}
                  variant={INPUT_VARIANT.DARK}
                />
              </div>
              <span className="margin-left-5">
                <ActionButton
                  icon="print"
                  onClick={() => {
                    copyToClipboard(urlForParent);
                  }}
                  theme={whiteTheme}
                  variant="secondary"
                />
              </span>
            </div>
          )}
        </div>
        <div className="margin-top-20 unlogged-or-separator">
          <hr />
          <div className="margin-horizontal-10">
            <Translate text="oppure" />
          </div>
          <hr />
        </div>
        <UnderageLockForm submitFunction={submitFunction} />
        <div className="flex-centered-column">
          <div className="unlogged-card-external-text margin-top-20">
            <a href={`#${createUrl("logout")}`}>
              <Icon icon="logout" height={10} width={10} />
              &nbsp;
              <Translate text="logout" />
            </a>
          </div>
        </div>
        <div className="flex-centered-column margin-top-10">
          <UnloggedSupportLink />
        </div>
      </RegistrationInviteOptionsContainer>
    </UnloggedRegisterLayout>
  );
};

export default observer(UnderageLock);
