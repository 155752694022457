/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Route, Switch } from "react-router";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import DeaImportLesson from "src/js/pages/deaImport/DeaImportLesson";
import DeaImportProject from "src/js/pages/deaImport/DeaImportProject";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";

export const DeaRouter = () => {
  return (
    <Switch>
      <Route
        path={WeSchoolRoutes.App.Dea.importProject}
        exact
        component={DeaImportProject}
      />
      <Route
        path={WeSchoolRoutes.App.Dea.importProjectNode}
        exact
        component={DeaImportLesson}
      />
      <Route
        render={props => (
          <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
        )}
      />
    </Switch>
  );
};
