import styled, { css } from "styled-components";
import colors from "@ds_themes/colors";

import { SPACE_INFO_CARD_SIZE_VARIANT } from "./SpaceBaseCard.const";
import { SpaceBaseCardSizeType, SpaceBaseCardStyleProps } from "./types";

export const SpaceBaseCardContainer = styled.div<SpaceBaseCardStyleProps>`
  height: ${({ sizeVariant }) => SPACE_INFO_CARD_SIZE_VARIANT[sizeVariant]};
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: ${({ sizeVariant }) =>
    sizeVariant === SpaceBaseCardSizeType.Small ? "8px" : "12px"};
  background: ${colors.grey[1000]};
  border: ${({ hasBorder }) => (hasBorder ? "1px solid black" : "0")};
  border-color: ${({ isSelected }) =>
    isSelected ? colors.violet[501] : colors.grey[800]};
  border-radius: 8px;
  opacity: ${({ isTransparent }) => (isTransparent ? 0.5 : 1)};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        border-color: ${colors.violet[501]};
      }
    `}
`;
