import React from "react";
import { Icon, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import WallBadge from "./WallBadge";
import * as S from "./WallBadgeBox.styles";
import { WallBadgesModal } from "../WallBadgesModal";

const WallBadgeBox = () => {
  const {
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile },
    GroupStore: { associatedBadge }
  } = useStores();
  const { translate } = useTranslation();

  const { badgeList, total } = associatedBadge;

  const triggerViewMoreModal = () => {
    openModal(() => <WallBadgesModal closeModal={closeModal} />);
  };

  const viewableElement: number = isLayoutModeMobile ? 1 : 3;
  const remainingElement: number = total - viewableElement;

  return badgeList.length > 0 ? (
    <S.WallBadgeBox>
      <S.WallBadgeBoxTitle>
        <Text type="formTitle">{translate("badge_wall_list_title")}</Text>
      </S.WallBadgeBoxTitle>
      <S.WallBadgeBoxList>
        {badgeList.slice(0, viewableElement).map(badge => {
          return (
            <WallBadge key={badge.id} name={badge.name} image={badge.image} />
          );
        })}
      </S.WallBadgeBoxList>
      {remainingElement > 0 && (
        <S.WallBadgeBoxFooter data-testid="badge-footer">
          <S.WallBadgeBoxCount>
            {`+${translate("badge_wall_list_rest_label", {
              badgeCount: remainingElement
            })}`}
          </S.WallBadgeBoxCount>
          <S.WallBadgeBoxNext onClick={triggerViewMoreModal}>
            <Text type="cta">
              {translate("badge_wall_list_view_more_label")}
            </Text>
            <Icon icon="arrow" height={15} width={15} />
          </S.WallBadgeBoxNext>
        </S.WallBadgeBoxFooter>
      )}
    </S.WallBadgeBox>
  ) : null;
};

export default observer(WallBadgeBox);
