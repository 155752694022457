import { Icon } from "@arcadia/design-system";
import React from "react";
import { ListItemProps } from "./types";

import * as S from "./ListItem.styles";

const ListItem = ({
  id,
  redShade = false,
  label,
  icon,
  isDisabled,
  onClick
}: ListItemProps) => {
  return (
    <li>
      <S.ListItem
        id={id}
        redShade={redShade}
        onClick={onClick}
        // Added onMouseDown handler due to safari problems
        // https://weschool-srl.atlassian.net/browse/SOSB-611
        onMouseDown={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        tabIndex={0}
        isDisabled={isDisabled}
      >
        <Icon initialViewbox icon={icon} width={16} height={16} />
        {label}
      </S.ListItem>
    </li>
  );
};

export default ListItem;
