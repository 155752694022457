import React from "react";
import { Icon, Text, colors } from "@arcadia/design-system";
import { isBefore } from "date-fns";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { __MAINNET_CHAIN_ICONS__ } from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/const";
import { useTranslation } from "src/js/translation";
import DownloadPDFCertificate from "src/js/pages/spaces/dashboard/SpacesBadges/components/DownloadPDFCertificate";
import { useStores } from "src/js/hooks";
import * as S from "./BadgeEarnedList.styles";
import { BadgeEarnedProps } from "./BadgeEarned.types";

const BadgeEarned = ({
  name,
  image,
  expiredAt,
  openBadgePublicUrl,
  nftReference,
  transactionReference,
  chainId,
  description,
  awardedAt,
  userFullName,
  spaceId,
  loggedInUserId
}: BadgeEarnedProps) => {
  const { translate } = useTranslation();
  const {
    UserStore: {
      activeUser: { uuid }
    }
  } = useStores();

  const checkIfBadgeIsExpired = () => {
    if (!expiredAt) {
      return false;
    }

    const now = new Date();
    const badgeExpiration = new Date(expiredAt);
    return isBefore(badgeExpiration, now);
  };

  const hasLink = !!openBadgePublicUrl;
  const userAwardedNFT = !!nftReference;

  const tooltipItems = [
    openBadgePublicUrl && {
      text: translate("space_badge_tooltip_item_badge_details"),
      onClick: () => window.open(openBadgePublicUrl)
    },
    userAwardedNFT && {
      text: translate("space_badge_tooltip_item_nft_details"),
      onClick: () => window.open(nftReference)
    },
    transactionReference && {
      text: translate("space_badge_tooltip_item_blockchain_explorer"),
      onClick: () => window.open(transactionReference)
    }
  ].filter(item => item);

  const handleChainIconColumn = () => {
    if (userAwardedNFT) {
      if (__MAINNET_CHAIN_ICONS__[chainId]) {
        return (
          <S.AwardedNFTIndicatorContainer>
            <Icon
              icon={__MAINNET_CHAIN_ICONS__[chainId]}
              width={12}
              height={12}
              initialViewbox
              color={colors.grey[1000]}
            />
          </S.AwardedNFTIndicatorContainer>
        );
      }
      return (
        <S.NFTLabelContainer>
          <Text type="label">NFT</Text>
        </S.NFTLabelContainer>
      );
    }
    return null;
  };

  return (
    <TranslatedTooltip
      placement="top"
      customTooltip={
        <S.TooltipContainer>
          {tooltipItems.map(({ text, onClick }) => (
            <S.TooltipItem key={text} onClick={onClick}>
              <Text type="textLink">{text}</Text>
              <Icon icon="externalLink" width={16} height={16} initialViewbox />
            </S.TooltipItem>
          ))}
          {loggedInUserId === uuid ? (
            <DownloadPDFCertificate
              badgeDescription={description}
              badgeImage={image}
              badgeName={name}
              openBadgePublicUrl={openBadgePublicUrl}
              expiredAt={expiredAt}
              userFullName={userFullName}
              awardedAt={awardedAt}
              spaceId={spaceId}
            />
          ) : null}
        </S.TooltipContainer>
      }
    >
      <S.EarnedBadgeContainer
        data-testid="earned-badge-container"
        hasLink={hasLink}
      >
        <S.BadgeImageContainer>
          {handleChainIconColumn()}
          <S.BadgeEarnedImage
            isExpired={checkIfBadgeIsExpired()}
            src={image}
            alt="Badge-logo"
          />
        </S.BadgeImageContainer>
        <S.BadgeEarnedName>
          <Text type="body">{name}</Text>
        </S.BadgeEarnedName>
      </S.EarnedBadgeContainer>
    </TranslatedTooltip>
  );
};
export default BadgeEarned;
