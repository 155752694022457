import { css } from "styled-components";
import { BREAKPOINT_PIXEL_VALUES } from "./theme.const";

export const respondTo = Object.keys(BREAKPOINT_PIXEL_VALUES).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${BREAKPOINT_PIXEL_VALUES[label]}px) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const mobileMixin = (...args) => css`
  @media (max-width: ${`${(BREAKPOINT_PIXEL_VALUES.sm - 1).toString()}px`}) {
    ${css(...args)};
  }
`;

export const tabletMixin = (...args) => css`
  @media (min-width: ${BREAKPOINT_PIXEL_VALUES.sm}px) and (max-width: ${BREAKPOINT_PIXEL_VALUES.lg -
    1}px) {
    ${css(...args)};
  }
`;

export const mobileAndTabletMixin = (...args) => css`
  @media (max-width: ${`${BREAKPOINT_PIXEL_VALUES.lg - 1}px`}) {
    ${css(...args)};
  }
`;

export const desktopAndTabletMixin = (...args) => css`
  @media (min-width: ${`${BREAKPOINT_PIXEL_VALUES.sm}px`}) {
    ${css(...args)};
  }
`;
