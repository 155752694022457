import styled from "styled-components";

export const BannerContainer = styled.div`
  margin: 40px 0;
  padding: 5px;
  background: ${({ theme }) => theme.colors.grey[1000]};
  min-height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  @media (max-width: 767px) {
    margin: 24px 0px 0px 0px;
    padding: 5px 1rem;
    border-radius: 8px;
  }
`;

export const LockedEventIcon = styled.div`
  background: ${({ theme }) => theme.colors.grey[1000]};
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 12px;
  margin-left: 6px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    flex: 1;
    margin-bottom: 15px;
  }
`;

export const TimerContainer = styled.div`
  margin-left: 15px;
  @media (max-width: 767px) {
    margin: 10px 0;
    text-align: center;
  }
`;
