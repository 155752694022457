/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { __BREAKPOINT_LG__ } from "../settings/settingsGeneral";

class UIStore {
  $layoutModeMobile = false;
  $sidebarOpen = window.innerWidth > __BREAKPOINT_LG__;
  $isMobileHeaderVisibile = true;
  $isStickyBannerOpen = true;
  $isStickyBannerVisible = true;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    window.addEventListener("resize", this.handleResize);

    this.$layoutModeMobile = getBreakpoint() === "smartphone";
  }

  handleResize = () => {
    this.$layoutModeMobile = getBreakpoint() === "smartphone";
  };

  /** ACTIONS */
  toggleSidebar = () => {
    this.$sidebarOpen = !this.$sidebarOpen;
  };

  closeSidebar = () => {
    this.$sidebarOpen = false;
  };

  openSideBar = () => {
    this.$sidebarOpen = true;
  };

  hideMobileHeader = () => {
    this.$isMobileHeaderVisibile = false;
  };

  showMobileHeader = () => {
    this.$isMobileHeaderVisibile = true;
  };

  toggleStickyBanner = () => {
    this.$isStickyBannerOpen = !this.$isStickyBannerOpen;
  };

  updateStickyBannerVisibility = (open: boolean) => {
    this.$isStickyBannerVisible = open;
  };

  /** COMPUTED */
  get isSideBarOpen() {
    return this.$sidebarOpen;
  }

  get isLayoutModeMobile() {
    return this.$layoutModeMobile;
  }

  get isMobileHeaderVisible() {
    return this.$isMobileHeaderVisibile;
  }

  get isStickyBannerOpen() {
    return this.$isStickyBannerOpen;
  }

  get isStickyBannerVisible() {
    return this.$isStickyBannerVisible;
  }
}

export default UIStore;
