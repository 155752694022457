import { IconName } from "@arcadia/design-system";
import { ObjectValues } from "./Common";
import { GroupDetailsModel } from "./Group";
import { AvatarModel, UserDetailsModel } from "./User";

export type NotificationPreference = "All" | "Daily" | "None";

export const AppPreference = {
  All: "All",
  Highlights: "Highlights",
  None: "None"
} as const;

export type AppPreference = ObjectValues<typeof AppPreference>;

export const EmailPreference = {
  Weekly: "Weekly",
  Daily: "Daily",
  None: "None"
} as const;

export type EmailPreference = ObjectValues<typeof EmailPreference>;

export type NotificationSettingsModel = {
  appPreference: AppPreference;
  emailPreference: EmailPreference;
};

// TODO check if this is correct
export type NotificationPreferenceModel = {
  app_preference: NotificationPreference;
  email_preference: NotificationPreference;
};

// remember to add also here the sections you want to be filtered via "Other" filter
export const NotificationAreaOther = {
  Templating: "templating"
} as const;

export const OtherAreaValue = Object.values(NotificationAreaOther);

export const NotificationArea = {
  Wall: "wall",
  Exercise: "exercise",
  Calendar: "calendar",
  Lesson: "lesson",
  ...NotificationAreaOther
} as const;

export const NotificationAreaValue = Object.values(NotificationArea);

export const NotificationAreaLabel = {
  Wall: "wall",
  Exercise: "exercise",
  Calendar: "calendar",
  Lesson: "lesson",
  Other: "other"
} as const;

export const NotificationRead = {
  Read: "true",
  Unread: "false",
  All: "all"
} as const;

export type NotificationReadModel = ObjectValues<typeof NotificationRead>;

export type NotificationAreaModel = ObjectValues<typeof NotificationArea>;

export type NotificationAreaLabelModel = ObjectValues<
  typeof NotificationAreaLabel
>;

export type NotificationTypeModel =
  | "Standard"
  | "Delayed"
  | "Partner"
  | "Weschool"
  | "Chat"
  | "Invitation";

export type NotificationFiltersObject = {
  groupId?: number;
  area?: NotificationAreaModel[];
  read?: NotificationReadModel;
  type?: NotificationTypeModel[];
};

export type NotificationModel = {
  id: string;
  type: NotificationTypeModel;
  area: NotificationAreaModel;
  text: string;
  redirectUrl: string;
  isRead: boolean;
  isDelivered: boolean;
  createdAt: number;
  updatedAt: number;
  sender: Omit<UserDetailsModel, "avatar" | "id"> & { avatar: AvatarModel };
  group: Partial<GroupDetailsModel>;
};

export type RealTimeNotificationModel = {
  title: string;
  message: string;
  id: string;
  senderAvatar: string;
  redirectUrl: string;
  area: NotificationAreaModel;
  groupId: number;
  spaceId: string;
  senderFullName: string;
};

export type NotificationEvent = {
  id: string;
  sender: {
    avatar: AvatarModel;
    uuid: string;
    name: string;
    surname: string;
  };
  group: {
    id: number;
    name: string;
    cover: string;
  };
  area: NotificationAreaModel;
  type: NotificationTypeModel;
  text: string;
  redirect_url: string;
  notification_identification: string;
  is_read: boolean;
  is_delivered: boolean;
  created_at: number;
  space_id: string;
};

export const AreaIcon: Record<NotificationAreaLabelModel, IconName> = {
  [NotificationAreaLabel.Calendar]: "micSolid",
  [NotificationAreaLabel.Wall]: "messageBalloonFull",
  [NotificationAreaLabel.Lesson]: "folderFull",
  [NotificationAreaLabel.Exercise]: "pencilFull",
  [NotificationAreaLabel.Other]: "notificationMarketing"
};
