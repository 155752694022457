import styled, { css } from "styled-components";

export const ColorSwatchPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0;
`;

export const ColorSwatch = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${props => props.color};
  border-radius: 4px;
  transition: box-shadow 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover {
    box-shadow: 0 0 0 4px ${props => props.color}80; // add alpha opacity to 0.5
  }
  ${props =>
    props.active &&
    css`
      box-shadow: 0 0 0 4px ${props.color}80; // add alpha opacity to 0.5
    `}
`;
