import { GroupMemberThemeOptions, Palette } from "@ds_themes/types";

import { createActionButtonTheme } from "./actionButton";
import { createAvatarTheme } from "./avatar";
import { createLabelTheme } from "./label";

export const createGroupMemberTheme = (
  palette: Palette
): { groupmember: GroupMemberThemeOptions } => {
  const labelTheme = createLabelTheme(palette);
  const avatarTheme = createAvatarTheme(palette);
  const actionButtonTheme = createActionButtonTheme(palette);

  const { grey } = palette;

  return {
    groupmember: {
      container: {
        border: `1px solid ${grey[900]}`,
        background: grey[1000]
      },
      ...labelTheme,
      ...avatarTheme,
      ...actionButtonTheme
    }
  };
};
