import { ContextualErrorThemeOptions, Palette } from "@ds_themes/types";

export const createContextualErrorTheme = ({
  error
}: Palette): { contextualerror: ContextualErrorThemeOptions } => {
  return {
    contextualerror: {
      background: error[900],
      border: `1px solid ${error[400]}`,
      text: error[300]
    }
  };
};
