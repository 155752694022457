import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import CircularLoader from "./CircularLoader/CircularLoader";
import { LoaderWrapperProps } from "./types";

const Loader: FC<LoaderWrapperProps> = ({
  theme,
  loaderType,
  mainColor,
  borderColor,
  position = "relative",
  top = "0",
  right = "inherit",
  bottom = "inherit",
  left = "0",
  loaderWidth = "100%",
  loaderHeight = "100%",
  width = "48px",
  height = "48px",
  margin = "2px",
  border = "2px",
  borderRadius = "50%"
}) => {
  theme.genericLoader = {
    position,
    top,
    right,
    bottom,
    left,
    loaderWidth,
    loaderHeight,
    width,
    height,
    margin,
    border,
    borderRadius
  };

  switch (loaderType) {
    case "CircularLoader":
      return (
        <ThemeProvider theme={theme}>
          <CircularLoader mainColor={mainColor} borderColor={borderColor} />
        </ThemeProvider>
      );

    default:
      return (
        <ThemeProvider theme={theme}>
          <CircularLoader mainColor={mainColor} borderColor={borderColor} />
        </ThemeProvider>
      );
  }
};

export default Loader;
