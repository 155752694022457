import { DoodleLoader } from "@arcadia/design-system";
import { format, isValid, parse } from "date-fns";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import useStores from "src/js/hooks/useStores";
import { UnloggedRegisterLayout } from "src/js/layout/unloggedLayout/UnloggedRegisterLayout";
import {
  _trackEvent,
  _trackNewUserEvent,
  _trackNewUserJoinEvent
} from "src/js/modules/analyticsFunction";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import {
  createLocalData,
  destroyLocalData
} from "src/js/modules/storageFunction";
import RegisterSchoolGroupContractForm from "src/js/pages/registration/RegisterSchoolGroupContractForm";
import RegisterSchoolGroupForm from "src/js/pages/registration/RegisterSchoolGroupForm";
import { fetchInviteInformation } from "src/js/repository/registrationInfoRepository";
import { submitRegistration } from "src/js/repository/registrationRepository";
import { __COOKIE_JOIN_GROUP__ } from "src/js/settings/settingsCookie";
import { __LOCAL_UNLOGGED_INVITE_INFORMATION__ } from "src/js/settings/settingsLocalStorage";
import { translateString } from "src/js/translation/TranslationProvider";
import {
  EventDomain,
  NewUserRegistrationEvent,
  SpacePurpose,
  UserEvent
} from "src/js/types";
import { useTheme } from "styled-components";
import { HubSpotRegistrationUser } from "../../../repository/hubspotRepository";
import { RegistrationOptions } from "../RegistrationOptions";
import { InviteInfo, RegisterFormData } from "./types";

/*
 * AuthStore is passed as an argument but we need just its reset function.
 * The following implementation has been made due to a Legacy constraint in the register.jsx
 * component, a deeper refactor of this component could solve this inaccurate use of the store.
 * Consider about putting the following registration logic inside AuthStore in future.
 */
function callRegistration(data, AuthStore, inviteGroupId, hasSpaceCreation) {
  submitRegistration(data)
    .then(response => {
      _trackEvent(
        EventDomain.NewUserRegistration,
        NewUserRegistrationEvent.UserDataForm,
        new Date().getTime()
      );

      if (inviteGroupId) {
        _trackNewUserEvent({
          category: "Platform",
          action: "Registration",
          label: new Date().getTime(),
          value: undefined,
          groupId: inviteGroupId,
          consent: data.terms
        });
        _trackNewUserJoinEvent({
          groupId: inviteGroupId,
          consent: data.terms
        });
        createLocalData(__COOKIE_JOIN_GROUP__, inviteGroupId);
      }
      HubSpotRegistrationUser({
        registrationData: data,
        registrationForm: inviteGroupId ? "invite" : "standard"
      });
      if (typeof response.accessToken === "undefined") {
        showToastSuccess({
          str: translateString({ text: "Utente creato con successo!" })
        });
        showToastSuccess({
          str: translateString({
            text: "Ti abbiamo inviato una mail per confermare l'iscrizione."
          })
        });
        navigateTo(
          createUrl("activate_account", {
            option: hasSpaceCreation ? "spacecreation" : "code"
          })
        );
      } else {
        AuthStore.resetUser(() => {
          navigateTo(
            createUrl("activate_account", {
              option: hasSpaceCreation ? "spacecreation" : "code"
            })
          );
        });
      }
      return response;
    })
    .catch(error => {
      showToastError({ str: error.message });
    });
}

const getFormType = (groupData: {
  space: { purpose: string; hasContract: boolean };
}) => {
  if (typeof groupData !== "undefined") {
    if (groupData.space?.purpose === SpacePurpose.School) {
      if (groupData.space?.hasContract) {
        return "registrationSchoolContract";
      }
      return "registrationSchool";
    }
  }
  return "registrationNormal";
};

const getFormData = (
  inviteType: string,
  userEmail: string,
  token: string,
  urlEmailParam?: string,
  name?: string,
  surname?: string,
  birthday?: string
) => {
  const precompiledForm = {
    invite_token: token || "",
    name,
    surname,
    birthday,
    email: urlEmailParam
  };
  if (inviteType === "private") {
    Object.assign(precompiledForm, {
      email: urlEmailParam || userEmail,
      emailDisabled: !urlEmailParam
    });
  }
  _trackNewUserEvent({
    category: EventDomain.User,
    action:
      inviteType === "private"
        ? UserEvent.SignupMailInvitationAccessPoint
        : UserEvent.SignupViaPublicInvitationAccessPoint,
    label: new Date().getTime(),
    value: undefined,
    groupId: "",
    consent: true
  });

  return precompiledForm;
};

const Register = () => {
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState("registrationNormal");
  const [formData, setFormData] = useState<RegisterFormData>({});
  const [inviteInfo, setInviteInfo] = useState<InviteInfo>();
  const {
    AuthStore,
    ThemeStore: { setSpaceCustomTheme, setCustomizationSettings }
  } = useStores();
  const { greyTheme } = useTheme();
  const { token } = useParams<{ token?: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const hasSpaceCreation = query.get("spacecreation") === "true";
  const inviteGroupId = inviteInfo?.groupData?.group?.id;

  useEffect(() => {
    const name = query.get("name") || query.get("nome") || undefined;
    const surname = query.get("surname") || query.get("cognome") || undefined;
    const email = query.get("email") || undefined;
    const birthday =
      query.get("birthday") || query.get("data_nascita") || undefined;

    const date = parse(birthday, "ddMMyyyy", new Date());
    const isDateValid = isValid(date);
    let formattedDate: string;
    if (isDateValid) {
      formattedDate = format(date, "dd-MM-yyyy");
    }
    if (typeof token !== "undefined") {
      fetchInviteInformation(token)
        .then((response: InviteInfo) => {
          createLocalData(
            __LOCAL_UNLOGGED_INVITE_INFORMATION__,
            JSON.stringify(response)
          );
          if (response.spaceCustomizationSettings) {
            setCustomizationSettings(response.spaceCustomizationSettings);
            setSpaceCustomTheme(response.groupData.space.id, {
              ctaFillColor: response.spaceCustomizationSettings?.ctaFillColor
            });
          }
          setShowForm(true);
          setFormType(getFormType(response.groupData));
          setFormData({
            ...getFormData(
              response.inviteType,
              response.userEmail,
              token,
              email,
              name,
              surname,
              formattedDate
            )
          });
          setInviteInfo(response);
        })
        .catch(error => {
          const errorMsg = extractErrorMessage(error, "invite_token_error");
          showToastError({ str: translateString({ text: errorMsg }) });
          navigateTo(createUrl("login"));
        });
    } else {
      _trackNewUserEvent({
        category: EventDomain.User,
        action: UserEvent.SignupAccessPoint,
        label: new Date().getTime(),
        value: undefined,
        groupId: "",
        consent: true
      });
      setShowForm(true);
      setFormData({ name, surname, birthday: formattedDate, email });
    }
  }, []);

  const sendRegistration = (data, id) => {
    destroyLocalData(__LOCAL_UNLOGGED_INVITE_INFORMATION__);
    // is necessary to pass the whole store instead of just its register function
    callRegistration(data, AuthStore, id, hasSpaceCreation);
  };

  // TODO: Remove login different forms (Only RegistrationOptions)
  if (showForm) {
    let contentForm;
    switch (formType) {
      case "registrationSchool":
        contentForm = (
          <RegisterSchoolGroupForm
            inviteInfo={inviteInfo}
            formData={formData}
            token={token}
            registrationFunction={data => sendRegistration(data, inviteGroupId)}
          />
        );
        break;
      case "registrationSchoolContract":
        contentForm = (
          <RegisterSchoolGroupContractForm
            inviteInfo={inviteInfo}
            formData={formData}
            token={token}
            registrationFunction={data => sendRegistration(data, inviteGroupId)}
          />
        );
        break;
      case "registrationNormal":
      default:
        contentForm = (
          <RegistrationOptions
            inviteInfo={inviteInfo}
            formData={formData}
            token={token}
            registerNormalUser={data => sendRegistration(data, inviteGroupId)}
            step="registrationNormal"
          />
        );
    }
    return (
      <UnloggedRegisterLayout inviteInfo={inviteInfo}>
        {contentForm}
      </UnloggedRegisterLayout>
    );
  }
  return <DoodleLoader theme={greyTheme} />;
};

export default observer(Register);
