import moment from "moment";
import React from "react";
import {
  __AGENDA_CAL_AUTUMN__,
  __AGENDA_CAL_SPRING__,
  __AGENDA_CAL_SUMMER__,
  __AGENDA_CAL_TODAY__,
  __AGENDA_CAL_WINTER__,
  __AGENDA_SEASON_AUTUMN__,
  __AGENDA_SEASON_SPRING__,
  __AGENDA_SEASON_SUMMER__,
  __AGENDA_SEASON_WINTER__,
  __AGENDA_TODAY_NO_EVENTS__
} from "src/js/settings/settingsImage";
import { Translate } from "src/js/translation/TranslationProvider";
import { ThemeProvider } from "styled-components";
import { agenda } from "#themes/grey/agenda";
import * as S from "./AgendaPlaceholder.styles";

const AgendaPlaceholder = ({ date, isToday = false }) => {
  const momentDate = date && moment(date);
  let iconImg = __AGENDA_CAL_TODAY__;
  let pictureImg = __AGENDA_TODAY_NO_EVENTS__;

  if (!isToday && momentDate) {
    switch (momentDate.month()) {
      case 0:
      case 1:
      case 11:
        iconImg = __AGENDA_CAL_WINTER__;
        pictureImg = __AGENDA_SEASON_WINTER__;
        break;
      case 2:
      case 3:
      case 4:
        iconImg = __AGENDA_CAL_SPRING__;
        pictureImg = __AGENDA_SEASON_SPRING__;
        break;
      case 5:
      case 6:
      case 7:
        iconImg = __AGENDA_CAL_SUMMER__;
        pictureImg = __AGENDA_SEASON_SUMMER__;
        break;
      case 8:
      case 9:
      case 10:
        iconImg = __AGENDA_CAL_AUTUMN__;
        pictureImg = __AGENDA_SEASON_AUTUMN__;
        break;
      default:
        break;
    }
  }

  return (
    <ThemeProvider theme={agenda}>
      <S.AgendaPlaceholderContainer>
        <S.IconContainer src={iconImg} isToday={isToday} />
        <S.DateLabel>
          {isToday ? (
            <Translate text="agenda_placeholder_today" />
          ) : (
            momentDate && momentDate.format("MMMM YYYY")
          )}
        </S.DateLabel>
        <S.PictureContainer src={pictureImg} />
      </S.AgendaPlaceholderContainer>
    </ThemeProvider>
  );
};

export default AgendaPlaceholder;
