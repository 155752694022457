import styled from "styled-components";

export const BadgeImageInReviewContainer = styled.div`
  position: relative;
`;

export const BadgeImageInReview = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  object-fit: cover;
`;

export const AwardedNFTIndicatorContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -8px;
  right: -8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const NFTLabelContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -12px;
  right: -12px;
  border-radius: 48px;
  padding: 4px;
`;
