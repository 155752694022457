import anchorme from "anchorme";

export const matchCase = (text: string, pattern: string) => {
  let result = "";
  for (let i = 0; i < text.length; i += 1) {
    const c = text.charAt(i);
    const p = pattern.charCodeAt(i);

    if (p >= 65 && p < 65 + 26) {
      result += c.toUpperCase();
    } else {
      result += c.toLowerCase();
    }
  }
  return result;
};

export const wrapAnchorme = (text: string) => {
  const textAnchored = anchorme(text, {
    attributes: [
      {
        name: "className",
        value: "inner-link"
      },
      // eslint-disable-next-line consistent-return
      urlObj => {
        if (urlObj.protocol !== "mailto:") {
          return { name: "target", value: "blank" };
        }
      }
    ]
  });
  return textAnchored;
};

export function highlightText(text: string, keyword: string) {
  let resultText = text;
  if (text && keyword) {
    const formattedKeyword = keyword.toLowerCase().trim();
    const index = text.toLowerCase().indexOf(formattedKeyword);
    if (index >= 0) {
      const regEx = new RegExp(formattedKeyword, "ig");
      resultText = text.replace(
        regEx,
        match =>
          `<span class="highlight">${matchCase(formattedKeyword, match)}</span>`
      );
    }
    return resultText;
  }
  return resultText;
}

export function convertMarkup(text: string) {
  let resultText;
  if (typeof text !== "undefined") {
    resultText = text.replace(
      // eslint-disable-next-line no-useless-escape
      /([\W_]|^)(\*)(?=\S)([^\r]*?\S[\*ì_]*)\2([\W_]|$)/g,
      "$1<strong>$3</strong>$4"
    );
    resultText = resultText.replace(
      // eslint-disable-next-line no-useless-escape
      /([\W_]|^)(_)(?=\S)([^\r\*_]*?\S)\2([\W_]|$)/g,
      "$1<em>$3</em>$4"
    );
  }
  return resultText;
}

export function truncateTextSimple(text: string, numberOfChars: number) {
  let aText = text || "";
  if (aText.length >= numberOfChars) {
    aText = `${aText.substring(0, numberOfChars)}...`;
  }
  return aText;
}

export function stripHtmlKeepImages(string: string) {
  return string.replace(/<(?!img)(?!\/img)[^>]+>/gi, "");
}

const truncateText = (text: string, maxChar: number) => {
  if (!text) return null;

  const truncatedText = Array.from(text);
  let auxMaxChar = maxChar;
  // prevent truncating in the middle of a word
  while (
    auxMaxChar < truncatedText.length &&
    truncatedText[auxMaxChar] !== " "
  ) {
    auxMaxChar += 1;
  }

  return truncatedText.slice(0, auxMaxChar).join("");
};

const nl2spaces = (text: string) => {
  return text.replace(/(\r\n|\n\r|\r|\n)/g, " ");
};

const nl2nothing = (text: string) => {
  return text.replace(/(\r\n|\n\r|\r|\n)/g, "");
};

const nl2br = (text: string) => {
  return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2");
};

const br2nl = (text: string) => {
  return text.replace(/(<br\s*\/?>)/gi, "\n");
};

const stripHTML = (text: string) => {
  return text.replace(/(<(?!br)([^>]+)>)/gi, "");
};

export const sanitizeText = ({ text }: { text: string }) => {
  return nl2nothing(wrapAnchorme(nl2br(stripHTML(text)))).replace(
    /(<br\s*\/?>){3,}/gi,
    "<br><br>"
  );
};

export const sanitizeLongText = ({
  text,
  maxLength
}: {
  text: string;
  maxLength: number;
}) => {
  const res = `${nl2spaces(truncateText(text, maxLength))}...`;
  return sanitizeText({ text: res });
};

export const sanitizeEditableText = ({ text }: { text: string }) => {
  return stripHTML(br2nl(text));
};
