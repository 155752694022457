import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { ModalSizeType, VariantType } from "@arcadia/design-system";
import { PostDetails, Post as PostType } from "src/js/types/models/Post";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { Router } from "react-router";
import RestoreScrollProvider from "src/js/context/RestoreScrollProvider";
import { history } from "src/legacy/modules/history";
import Post from "./Post";
import { POST_CONTEXT } from "./Post.types";

const PostModal = ({
  postFull,
  setPinnedFunc,
  onDeletePost
}: {
  postFull: PostType;
  setPinnedFunc: (post: PostDetails) => void;
  onDeletePost: (postId: number, groupId: number) => void;
}) => {
  const { post } = postFull;
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();

  return (
    <ModalLayout
      labels={{
        title: translate("wall_post_modal_view_title_text", {
          username: `${post.user.name} ${post.user.surname}`
        }),
        mobileClose: translate({ text: "close" })
      }}
      closeModal={closeModal}
      desktopProps={{ hasAdaptiveHeight: true, size: ModalSizeType.Big }}
      mobileProps={{ variant: VariantType.White }}
    >
      <Router history={history}>
        <RestoreScrollProvider>
          <Post
            postFull={postFull}
            pinned={postFull.post.isPinned}
            context={POST_CONTEXT.MODAL}
            setPinnedFunc={setPinnedFunc}
            onDeletePost={onDeletePost}
          />
        </RestoreScrollProvider>
      </Router>
    </ModalLayout>
  );
};

export default PostModal;
