import { PageNavThemeOptions, Palette } from "@ds_themes/types";

export const createPageNavTheme = ({
  primary,
  general,
  grey
}: Palette): { pagenav: PageNavThemeOptions } => {
  return {
    pagenav: {
      button: {
        active: {
          color: primary.contrastText,
          background: primary.main
        },
        color: general.contrastText,
        background: general.background,
        border: grey[800],
        hover: {
          border: primary.main
        },
        focus: {
          border: primary.light
        }
      }
    }
  };
};
