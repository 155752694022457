import React, { useState } from "react";
import * as S from "src/js/components/exercises/ReportThread/ReportThread.styles";
import moment from "moment";
import { CollapsableThread } from "src/js/components/exercises/CollapsableThread";
import { ExerciseThreadStudentReply } from "src/js/components/exercises/ExerciseThreadStudentReply";
import { useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { removeReportThreadsCorrection } from "src/js/repository/reportRepository";
import { ExerciseThreadTeacherCorrection } from "../ExerciseThreadTeacherCorrection";
import { ExerciseThreadRedelivery } from "../ExerciseThreadRedelivery";
import { ReportTeacherCorrectForm } from "../ReportTeacherCorrectForm";

function formatStudentResponse({
  execution,
  resource,
  thread_message,
  is_teacher
}) {
  return {
    name: execution.name,
    surname: execution.surname,
    resource,
    created_at: moment.unix(execution.created_at),
    thread_message,
    is_teacher
  };
}

const MessageViewer = ({
  message,
  isLastMessage = false,
  deleteFunction,
  editFunction
}) => {
  const { UserStore } = useStores();
  if (message?.is_teacher) {
    return (
      <ExerciseThreadTeacherCorrection
        response={message}
        deleteFunction={deleteFunction}
        editFunction={editFunction}
        actionEnabled={
          isLastMessage && UserStore.activeUser.id === message.user_id
        }
      />
    );
  }
  return <ExerciseThreadStudentReply response={message} />;
};

const ReportThread = ({
  thread,
  editThreadMessage = () => {},
  quizExecutionId,
  availablePoints,
  exerciseId,
  executionId,
  executionMessage,
  execution,
  resourceMessage,
  onEditSubmit,
  onDeleteMessage,
  askResubmissionValue
}) => {
  const { ReportStore } = useStores();
  const [isEditing, setIsEditing] = useState(false);
  const studentExecution = formatStudentResponse({
    execution,
    resource: resourceMessage,
    thread_message: executionMessage,
    is_teacher: false
  });

  if (thread.length === 0) {
    return (
      <S.ReportThreadContainer>
        <MessageViewer message={studentExecution} />
      </S.ReportThreadContainer>
    );
  }

  const pingPongArray = thread.filter(element => {
    return element?.system_message !== true;
  });

  const redeliveryStatusArray = thread.filter(element => {
    return element?.system_message === true;
  });

  const lastMessage = pingPongArray[pingPongArray.length - 1];
  pingPongArray.pop();

  const redeliveryMessages = redeliveryStatusArray.map(message => {
    return (
      <S.ReportThreadMessage key={message.id}>
        <ExerciseThreadRedelivery message={message} />
      </S.ReportThreadMessage>
    );
  });

  const deleteThreadMessage = id => {
    removeReportThreadsCorrection({ id, exerciseId, executionId })
      .then(() => {
        ReportStore.deleteThreadCorrection({
          correctionId: id,
          quizExecutionId
        });
        onDeleteMessage();
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  };
  const threadMessages = pingPongArray.map(message => {
    return (
      <S.ReportThreadMessage key={message.id}>
        <MessageViewer message={message} />
      </S.ReportThreadMessage>
    );
  });

  const visibleTread =
    pingPongArray.length > 2 ? (
      <CollapsableThread defaultCollapsableOpen={false}>
        <S.ReportThreadContainer>
          <MessageViewer message={studentExecution} />
        </S.ReportThreadContainer>
        {threadMessages}
      </CollapsableThread>
    ) : (
      <>
        <S.ReportThreadContainer>
          <MessageViewer message={studentExecution} />
        </S.ReportThreadContainer>
        {threadMessages}
      </>
    );

  return (
    <S.ReportThreadContainer>
      <S.ReportThreadCollapsable>{visibleTread}</S.ReportThreadCollapsable>
      {isEditing ? null : (
        <S.ReportThreadContainer>
          <MessageViewer
            message={lastMessage}
            isLastMessage
            deleteFunction={deleteThreadMessage}
            editFunction={() => {
              editThreadMessage(!isEditing);
              setIsEditing(!isEditing);
            }}
          />
        </S.ReportThreadContainer>
      )}
      {redeliveryMessages}
      {isEditing ? (
        <ReportTeacherCorrectForm
          exerciseId={exerciseId}
          executionId={executionId}
          quizExecutionId={quizExecutionId}
          availablePoints={availablePoints}
          editMode
          threadMessageId={lastMessage.id}
          oldResubmissionValue={askResubmissionValue}
          oldComment={lastMessage.thread_message}
          oldPoints={lastMessage.quiz_execution_points}
          onEditSubmit={askResubmission => {
            editThreadMessage(!isEditing);
            onEditSubmit(askResubmission);
            setIsEditing(!isEditing);
          }}
        />
      ) : null}
    </S.ReportThreadContainer>
  );
};

export default ReportThread;
