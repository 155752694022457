import { Box } from "@arcadia/design-system";
import styled from "styled-components";

export const ChipMetricTrendBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 2px 8px;
  border-radius: 12px;
  min-height: 18px;
  min-width: fit-content;
  height: auto;
  color: ${props => props.color}; // TODO: Remove when Box is fixed
`;
