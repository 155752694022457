import styled from "styled-components";

export const ConfirmRedirectButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.yellow[100]};
  text-decoration: underline;
`;

export const CancelRedirectButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.yellow[50]};
`;
