import moment from "moment";

export const ANALYTICS_DASHBOARD_FAKE_INDEXES = {
  contentsCreatedCount: 14,
  engagementRate: 1.2,
  groupsCount: 46,
  trainingTime: 90,
  usersCount: 47
};

export const generateAnalyticsDashboardFakeTrend = () => {
  const date = moment();
  const trendsArray = [];
  for (let index = 0; index < 30; index += 1) {
    const randomValue = Math.floor(Math.random() * 9);

    date.subtract(1, "days");
    trendsArray.unshift({
      time: date.format("DD MMM YYYY"),
      value: randomValue
    });
  }

  return trendsArray;
};
