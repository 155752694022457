import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Text, Button, Dropdown } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useFormContext } from "react-hook-form";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiCourseGeneratorEvent, EventDomain } from "src/js/types";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { CourseDetailsWizardForm } from "../../CourseDetailsWizard.types";
import { __COURSE_LENGTH_STEP_OPTIONS__ } from "./CourseLengthStep.utils";

const CourseLengthStep = ({ onNext }: { onNext: () => void }) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const [selectedOptionId, setSelectedOptionId] = useState<string>();
  const { setValue, register, getValues } =
    useFormContext<CourseDetailsWizardForm>();

  useEffect(() => {
    register("courseLength");
  }, []);

  useEffect(() => {
    const defaultValue = getValues().courseLength;
    if (defaultValue && !selectedOptionId)
      setSelectedOptionId(`${defaultValue}`);
  }, [getValues().courseLength]);

  useEffect(() => {
    if (selectedOptionId) {
      setValue("courseLength", parseInt(selectedOptionId, 10), true);
    }
  }, [selectedOptionId]);

  const optionsList = useMemo(
    () =>
      __COURSE_LENGTH_STEP_OPTIONS__.map(option => ({
        id: option.id,
        label: translate(option.label)
      })),
    []
  );

  const handleNext = useCallback(() => {
    onNext();
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardStep3
    );
  }, [onNext]);

  return (
    <Box padding="20px 0px" width="100%">
      <Box marginBottom={8}>
        <Text type="formSubtitle">
          {translate("ai_wizard_course_length_label")}
        </Text>
      </Box>
      <Dropdown
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        placeholder={translate("ai_wizard_course_length_placeholder")}
        optionsList={optionsList}
        setSelectedOptionId={setSelectedOptionId}
        selectedOptionId={selectedOptionId}
      />
      <Box marginTop={16}>
        <Button
          theme={whiteTheme}
          variant="primary"
          onClick={handleNext}
          disabled={!selectedOptionId}
        >
          {translate("next")}
        </Button>
      </Box>
    </Box>
  );
};

export default observer(CourseLengthStep);
