/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";

import { __DEMO_BADGE__ } from "../settings/settingsImage";
import { GeneratingDemoSpaceModel, SpaceGenerationStatesEnum } from "../types";
import { SessionStorageKey } from "../modules/storageFunction";

/**
 * @name DemoSpaceStore
 *
 * @description Demo Space generation states and checks
 */
class DemoSpaceStore {
  private $spaceDemoGenerationState: SpaceGenerationStatesEnum | string = null;
  private $previewDemoSpace: GeneratingDemoSpaceModel | null = {
    badge: { small: __DEMO_BADGE__ },
    defaultBadgeColor: 23,
    name: "Demo Space"
  };
  private $isDemoSpaceTopBannerVisible = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setSpaceDemoGenerationState = (state: SpaceGenerationStatesEnum) => {
    this.$spaceDemoGenerationState = state;
  };

  setPreviewDemoSpace = (previewDemoSpace: GeneratingDemoSpaceModel) => {
    this.$previewDemoSpace = previewDemoSpace;
  };

  setDemoSpaceTopBannerVisibility = (visible: boolean) => {
    this.$isDemoSpaceTopBannerVisible = visible;
  };

  setDemoSpaceTopBannerUserPreference = () => {
    sessionStorage.setItem(SessionStorageKey.DemoSpaceTopBannerClosed, "true");
  };

  get previewDemoSpace() {
    return this.$previewDemoSpace;
  }

  get spaceDemoGenerationState() {
    return this.$spaceDemoGenerationState;
  }

  get isDemoSpaceTopBannerVisible() {
    return this.$isDemoSpaceTopBannerVisible;
  }
}

export default DemoSpaceStore;
