import React from "react";
import { Button, Icon, Modal, Box } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import { createUrl } from "src/js/modules/routing";
import { navigateTo } from "Legacy/modules/history";
import { LogoutProps } from "src/js/components/modal/LogoutModal/types";
import { useTranslation } from "src/js/translation";
import * as S from "./LogoutModal.styles";

const LogoutModal = ({ closeModal }: LogoutProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <Modal title={translate("logout_modal_title")} onClose={closeModal}>
      <S.ModalBody>
        <S.IllustrationContainer>
          <Icon
            icon="illustration-logout"
            height={145}
            width={145}
            viewbox="0 0 151 147"
          />
        </S.IllustrationContainer>
        <S.LogoutText level="4">{translate("logout_modal_text")}</S.LogoutText>
        <S.ModalButtonsContainer>
          <Button
            variant="primary"
            theme={whiteTheme}
            fullWidth
            onClick={() => {
              navigateTo(createUrl("logout"));
              closeModal();
            }}
          >
            {translate("logout_modal_action_button")}
          </Button>
          <Box
            onClick={e => {
              e.stopPropagation();
              closeModal();
            }}
          >
            <S.Cancel type="textLink">
              {translate("logout_modal_cancel_button")}
            </S.Cancel>
          </Box>
        </S.ModalButtonsContainer>
      </S.ModalBody>
    </Modal>
  );
};

export default observer(LogoutModal);
