import {
  ActionButton,
  Button,
  DropdownMultiple,
  createPrimaryActionButtonTheme,
  createPrimaryButtonTheme
} from "@arcadia/design-system";
import moment from "moment";
import React, { useCallback } from "react";
import { useStores } from "src/js/hooks";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { CALENDAR_VIEW } from "src/js/pages/calendar";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { MonthPicker } from "../MonthPicker";
import * as S from "./CalendarHeader.styles";
import { EventCategory } from "../Calendar.types";

const CalendarHeader = ({
  navigatePage = () => {},
  view = CALENDAR_VIEW.MONTH,
  changeView = () => {},
  currentDate = null,
  setCurrentDate = () => {},
  setEventTypeFilter = () => {}
}) => {
  const {
    CalendarStore: { eventFilter },
    AgendaStore: { agendaFilter, agendaViewIsActive }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme, primaryColor, colors } = useTheme();

  const switchView = useCallback(eventView => {
    if (eventView === CALENDAR_VIEW.MONTH) {
      changeView(CALENDAR_VIEW.WEEK);
    } else {
      changeView(CALENDAR_VIEW.MONTH);
    }
  }, []);

  const customButtonPrimaryTheme = {
    button: createPrimaryButtonTheme({
      primary: {
        main: colors.grey[1000],
        contrastText: primaryColor[500],
        light: primaryColor[600]
      }
    })
  };

  const customActionButtonTheme = {
    actionButton: createPrimaryActionButtonTheme({
      appearence: "heavy",
      primary: {
        main: colors.grey[1000],
        contrastText: primaryColor[500],
        light: primaryColor[700]
      },
      secondary: {
        main: null
      }
    })
  };

  const isSmartphone = getBreakpoint() === "smartphone";

  const formattedDate =
    isSmartphone && currentDate && moment(currentDate).format("MMMM YYYY");

  const optionFilter = [
    {
      key: EventCategory.Live,
      id: EventCategory.Live,
      label: translate("calendar_filter_live_option"),
      value: "calendar_filter_live_option"
    },
    {
      key: EventCategory.Activity,
      id: EventCategory.Activity,
      label: translate("calendar_filter_activity_option"),
      value: "calendar_filter_activity_option"
    },
    {
      key: EventCategory.Deadline,
      id: EventCategory.Deadline,
      label: translate("calendar_filter_deadline_option"),
      value: "calendar_filter_deadline_option"
    }
  ];

  const viewbasedFilters = agendaViewIsActive ? agendaFilter : eventFilter;

  return (
    <S.CalendarHeaderContainer>
      <S.CalendarHeaderSection alignContent="left" hiddenMobile>
        {formattedDate ? (
          <S.CurrentDate>{formattedDate}</S.CurrentDate>
        ) : (
          <MonthPicker
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          />
        )}
      </S.CalendarHeaderSection>
      <S.CalendarHeaderSection desktopMarginTop={3}>
        <ActionButton
          onClick={() => navigatePage(true)}
          icon="chevronLeft"
          theme={isSmartphone ? whiteTheme : customActionButtonTheme}
          variant={isSmartphone ? "tertiary" : "primary"}
        />
        {isSmartphone &&
          (formattedDate ? (
            <S.WrapperCurrentDate>
              <S.CurrentDate>{formattedDate}</S.CurrentDate>
              <ActionButton
                onClick={() => switchView(view)}
                icon={
                  view === CALENDAR_VIEW.MONTH ? "chevronDown" : "chevronUp"
                }
                theme={whiteTheme}
                variant="tertiary"
              />
            </S.WrapperCurrentDate>
          ) : (
            <MonthPicker
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          ))}
        {isSmartphone ? null : (
          <Button
            onClick={() => {
              setCurrentDate(new Date());
            }}
            variant="primary"
            theme={customButtonPrimaryTheme}
          >
            {translate("calendar_today_button")}
          </Button>
        )}
        <ActionButton
          onClick={() => navigatePage(false)}
          icon="chevronRight"
          theme={isSmartphone ? whiteTheme : customActionButtonTheme}
          variant={isSmartphone ? "tertiary" : "primary"}
        />
      </S.CalendarHeaderSection>
      <S.CalendarHeaderSection alignContent="right" hiddenMobile>
        <DropdownMultiple
          theme={greyTheme}
          optionsList={optionFilter}
          setSelectedOptionIds={filterValue => {
            setEventTypeFilter(filterValue);
          }}
          selectedOptionIds={viewbasedFilters}
          placeholder={translate("calendar_filter_placeholder")}
          scrollableListId="prerequisite-dropdown-multiple"
        />
      </S.CalendarHeaderSection>
    </S.CalendarHeaderContainer>
  );
};

export default CalendarHeader;
