export const CALENDAR_VIEW = {
  WEEK: "week",
  MONTH: "month"
};

export const CALENDAR_MODE = {
  CALENDAR: "calendar",
  AGENDA: "agenda"
};

export const AGENDA_MONTH_MODE = "agenda-month-placeholder";
