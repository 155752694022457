import { isLightColor } from "src/js/theme/utils";
import styled from "styled-components";

export const AvatarWrapper = styled.div`
  display: block;
  width: max-content;
  margin: 0 auto;
  position: relative;
`;

export const AvatarWithInitialsWrapper = styled.div`
  margin: 8px auto 20px;
  zoom: 175%;
`;

export const EditableAvatarWrapper = styled.div`
  margin: 42px 0 16px;
  width: 112px;
  height: 112px;
`;

export const EditLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.primaryColor[50]}99;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.grey[1000]};
  }

  input {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const FormStyled = styled.form`
  width: 100%;
`;

export const SaveLinkButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.primaryColor[500]};
  padding-top: 8px;

  border-bottom: 1px solid ${({ theme }) => theme.primaryColor[500]};
`;

export const UploaderContainer = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  margin-bottom: 8px;
`;

export const ImageContainer = styled.div`
  width: 220px;
  margin-right: 20px;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 520px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const SquaredImage = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.base};
`;

export const RoundedImage = styled.img`
  border-radius: 50%;
  width: 128px;
  height: 128px;
  object-fit: cover;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
`;

export const ColorPickerWrapper = styled.div`
  max-width: 240px;
  @media (max-width: 767px) {
    max-width: unset;
    flex: 1;
  }
`;

export const FakeButtonContainer = styled.div<{ fillColor: string }>`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;

  & button {
    background: ${({ fillColor }) => fillColor}!important;
    border-color: ${({ fillColor }) => fillColor}!important;
    width: 160px;
    color: ${({ fillColor, theme }) =>
      isLightColor(fillColor)
        ? theme.colors.grey[50]
        : theme.colors.grey[1000]}!important;

    &:hover {
      background: ${({ fillColor, theme }) =>
        isLightColor(fillColor)
          ? theme.colors.grey[50]
          : theme.colors.grey[1000]}!important;
      border-color: ${({ fillColor }) => fillColor}!important;
      color: ${({ fillColor }) => fillColor}!important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const ContentBox = styled.div`
  margin-top: 24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 16px;
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
`;

export const FullWidthContextualAlertWrapper = styled.div`
  margin-top: 12px;
  > div {
    max-width: 100%;
    width: fit-content;
  }
`;
