import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  api_module_approve_resource,
  api_module_reject_resource,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";

export function approveModuleResource({ moduleId, elementId, position }) {
  return getUserClient()
    .patch(
      getVersionedApiUrl(
        api_module_approve_resource({
          moduleId,
          elementId
        }),
        "v3"
      ),
      {
        position
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = extractErrorMessage(error);
      throw Err;
    });
}

export function rejectModuleResource({ moduleId, elementId, groupId }) {
  return getUserClient()
    .patch(
      getVersionedApiUrl(
        api_module_reject_resource({
          moduleId,
          elementId
        })
      ),
      {
        group_Id: groupId
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = extractErrorMessage(error);
      throw Err;
    });
}
