import styled, { css } from "styled-components";
import { getHighContrastColor } from "@arcadia/design-system";

export const AvatarWrapper = styled.div`
  display: block;
  margin: 8px auto 20px;
  width: max-content;
  zoom: 175%;
`;

export const SectionSpacer = styled.div`
  margin-top: 32px;
`;

export const DescriptionContainer = styled.div`
  max-width: 675px;
`;

export const Column = styled.div`
  color: ${({ theme }) => theme.colors.primary2};

  width: 20%;
  padding-right: 20px;

  ${({ large }) =>
    large &&
    css`
      width: 30%;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 15%;
    `}

  ${({ extrasmall }) =>
    extrasmall &&
    css`
      width: 10%;
    `}

  &:last-of-type {
    text-align: right;
  }
`;

export const UsersCounter = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  min-width: 24px;
  text-align: center;
  padding: 0 10px;
  background: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => getHighContrastColor(theme.primaryColor[500])};
`;

export const UserListTitleContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UserListWrapper = styled.div`
  @media screen and (max-width: 767px) {
    padding-bottom: 32px;
  }
`;

export const UserListHeader = styled.div`
  display: flex;
  padding-left: 26px;
  padding-right: 30px;
  margin-bottom: 8px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const UserListHeaderItem = styled.span`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const UserList = styled.div``;

export const ButtonWrapper = styled.div`
  > button {
    width: 100%;
  }
`;
