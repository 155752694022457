import {
  DoodleLoader,
  FabButton,
  Lottie,
  LottieProps
} from "@arcadia/design-system";
import Error404Animation from "Images/lottie/error-404.json";
import { BoardsProgressRecap as CBoardsProgressRecap } from "src/js/components/BoardsProgressRecap";
import {
  mobileAndTabletMixin,
  mobileMixin
} from "src/js/theme/breakpointsMixin";
import styled, { css } from "styled-components";

export const MainLayoutBoards = styled.div<{ drawerOpen: boolean }>`
  width: 100%;
  ${mobileAndTabletMixin`
      padding: 0;
    `}
  transition: padding 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  ${({ drawerOpen }) =>
    drawerOpen &&
    css`
      padding-left: 16px;
      padding-right: 556px;
    `}
`;

export const StyledDoodleLoader = styled(DoodleLoader)`
  height: 100%;
  & > div {
    width: 200px;
    margin: auto;
    min-height: 500px;
    height: 100%;
  }
`;

export const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 245px;
  margin: auto;
  height: 100%;
`;

export const LottieAnimation = styled(Lottie).attrs((props: LottieProps) => ({
  ...props,
  src: Error404Animation
}))`
  width: 108px;
  margin-bottom: 16px;
`;

export const PlaceholderBoardItem = styled.div`
  width: 100%;
  background-color: #eef1f6;
  border: 1px solid ${({ theme }) => theme.colors.accent7};
  height: 125px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;

  ${mobileMixin`
    flex-direction: column-reverse;
  `}
  & .ws-board-path-popup {
    z-index: ${({ theme }) => theme.zIndex.forDrawerOverlay};

    .mbsc-popup {
      user-select: auto;
    }

    .mbsc-popup-arrow-wrapper {
      display: none;
    }

    .mbsc-material.mbsc-popup-body {
      border: none;
      border-radius: 8px;
      box-shadow: ${({ theme }) => theme.shadows.modals};

      .mbsc-popup-content {
        overflow: visible;
      }
    }
  }
`;

export const BoardsContainer = styled.div`
  padding: 24px 0px 64px 0px;
  flex-shrink: 1;
  width: 100%;

  ${mobileMixin`
    padding: 24px 0;
  `}
`;

export const BoardsListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px;
`;

export const BoardsList = styled.div`
  display: flex;
  width: 100%;

  & > div:first-child {
    flex: 1;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const LoaderWrapper = styled.div`
  overflow: hidden;
`;

export const BoardsProgressRecap = styled(CBoardsProgressRecap)`
  flex-shrink: 0;
  margin-left: 20px;

  ${mobileMixin`
    margin: 16px 0px 24px 0px;
  `}
`;

export const BoardPathWrapper = styled.div<{ tasksAreaIsVisible: boolean }>`
  position: fixed;
  bottom: ${({ tasksAreaIsVisible }) =>
    tasksAreaIsVisible ? "130px" : "20px"};
  right: 0;
  margin-right: 22px;
  z-index: ${({ theme }) => theme.zIndex.up};
`;
export const ModalLayoutContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledFabButton = styled(FabButton)`
  position: fixed;
  bottom: 100px;
  right: 16px;
  z-index: 1;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
