import colors from "@ds_themes/colors";

export const loader = {
  background: colors.violet[501],
  color: colors.grey[1000]
};

export const modal = {
  border: `1px solid ${colors.grey[700]}`
};
