import { EventCardThemeOptions, Palette } from "@ds_themes/types";

import { createLabelTheme } from "./label";
import { createLivedurationTheme } from "./liveduration";
import { createLivetagTheme } from "./livetag";

export const createEventcardTheme = (
  palette: Palette
): { eventcard: EventCardThemeOptions } => {
  const { primary, general, grey } = palette;
  const liveTagTheme = createLivetagTheme(palette);
  const labelTheme = createLabelTheme(palette);
  const livedurationTheme = createLivedurationTheme(palette);

  return {
    eventcard: {
      background: general.background,
      text: {
        color: general.contrastText
      },
      secondText: {
        color: grey[300]
      },
      border: `1px solid ${grey[800]}`,
      colorIcon: primary.main,
      livetag: liveTagTheme.livetag,
      label: labelTheme.label,
      liveduration: livedurationTheme.liveduration
    }
  };
};
