import { Box, Divider, ModalSizeType, Text } from "@arcadia/design-system";
import React from "react";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { useStores } from "src/js/hooks";
import { demoNoInvitationWarningBanner } from "src/js/pages/group/settings/GroupSettingsMembers/translations";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

import { ModalLayout } from "src/js/components/modal/ModalLayout";
import EmailInvite from "./components/EmailInvite";
import LinkInvite from "./components/LinkInvite";
import * as S from "./GroupInviteMemberModal.styles";

const GroupInviteMemberModal = ({
  shouldScrollToEmailList
}: {
  shouldScrollToEmailList?: boolean;
}) => {
  const {
    SpaceStore,
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal }
  } = useStores();
  const { isDemoSpace } = SpaceStore;
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const currentTheme = isLayoutModeMobile ? greyTheme : whiteTheme;

  return (
    <ModalLayout
      desktopProps={{
        size: ModalSizeType.Big
      }}
      labels={{
        title: translate("group_settings_invite_modal_title"),
        mobileClose: translate("close")
      }}
      closeModal={closeModal}
    >
      {isDemoSpace ? (
        <Box padding="28px 32px">
          <WarningBanner
            text={demoNoInvitationWarningBanner.text}
            cta={demoNoInvitationWarningBanner.cta}
            margin="0 0 30px 0"
            maxWidth="100%"
          />
        </Box>
      ) : null}
      <S.ModalBodyContainer>
        <Box marginBottom={14}>
          <Text type="cta">
            {translate("group_settings_invite_modal_message_title")}
          </Text>
        </Box>
        <Box marginBottom={28}>
          <Text type="body">
            {translate("group_settings_invite_modal_message_body")}
          </Text>
        </Box>
        <Divider theme={currentTheme} color="grey" />
        <Box paddingTop={32}>
          <LinkInvite theme={currentTheme} isDemoSpace={isDemoSpace} />
          <Divider theme={currentTheme} color="grey" />
          <Box marginBottom={24} />
          <EmailInvite
            theme={currentTheme}
            shouldScrollToEmailList={shouldScrollToEmailList}
          />
        </Box>
      </S.ModalBodyContainer>
    </ModalLayout>
  );
};

export default GroupInviteMemberModal;
