/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import {
  NotificationModel,
  RealTimeNotificationModel
} from "../types/models/Notification";
import SpaceGroupListStore from "./SpaceGroupListStore";
import SpaceStore from "./SpaceStore";
/**
 * @name NotificationStore
 *
 * @description
 * Store informations about the drawers notifications
 *
 */
class NotificationStore {
  $notificationsList: NotificationModel[] = [];
  $filteredNotificationsList: NotificationModel[] = [];
  $popoverNotification: {
    notification?: RealTimeNotificationModel;
  } = {
    notification: null
  };
  $notificationsNumber = null;
  private spaceStore: SpaceStore;
  private spaceGroupListStore: SpaceGroupListStore;

  constructor({
    spaceStore,
    spaceGroupListStore
  }: {
    spaceStore: SpaceStore;
    spaceGroupListStore: SpaceGroupListStore;
  }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
    this.spaceGroupListStore = spaceGroupListStore;
  }

  /** ACTIONS */

  setSpaceNotificationCounter = ({
    spaceId,
    count
  }: {
    spaceId: string;
    count: number;
  }) => {
    this.spaceStore.setNotificationCounterOnSpace({ spaceId, count });
  };

  setGroupNotificationCounter = ({
    groupId,
    count
  }: {
    groupId: number;
    count: number;
  }) => {
    this.spaceGroupListStore.setNotificationCounterOnGroup({ groupId, count });
  };

  increaseAllCounters = ({
    spaceId,
    groupId
  }: {
    spaceId: string;
    groupId: number;
  }) => {
    this.increaseNotificationCounterOnBell();
    this.spaceStore.increaseNotificationCounterOnSpace({
      spaceId
    });
    this.spaceGroupListStore.increaseNotificationCounterOnGroup({
      groupId
    });
  };

  decreaseAllCounters = ({
    spaceId,
    groupId
  }: {
    spaceId: string;
    groupId: number;
  }) => {
    this.decreaseNotificationCounterOnBell();
    this.spaceStore.decreaseNotificationCounterOnSpace({
      spaceId
    });
    this.spaceGroupListStore.decreaseNotificationCounterOnGroup({
      groupId
    });
  };

  // notification can be null
  setPopoverNotification = ({
    notification
  }: {
    notification?: RealTimeNotificationModel;
  }) => {
    this.$popoverNotification = { notification };
  };

  /** COMPUTED */

  get notificationsList() {
    return this.$notificationsList;
  }

  get filteredNotificationsList() {
    return this.$filteredNotificationsList;
  }

  get popoverNotification() {
    return this.$popoverNotification;
  }

  get newNotificationsNumber() {
    return this.$notificationsNumber;
  }

  setNotificationsNumber = (newValue: number) => {
    this.$notificationsNumber = newValue >= 0 ? newValue : 0;
  };

  increaseNotificationCounterOnBell = () => {
    this.$notificationsNumber += 1;
  };
  decreaseNotificationCounterOnBell = () => {
    if (this.$notificationsNumber > 0) {
      this.$notificationsNumber -= 1;
    }
  };

  addToNotifications = (notifications: NotificationModel[]) => {
    this.$notificationsList = [...this.$notificationsList, ...notifications];
  };

  addToFilteredNotifications = (notifications: NotificationModel[]) => {
    this.$filteredNotificationsList = [
      ...this.$filteredNotificationsList,
      ...notifications
    ];
  };

  unshiftNotification = ({
    notification
  }: {
    notification: NotificationModel;
  }) => {
    this.$notificationsList = [notification, ...this.$notificationsList];
  };

  unshiftFilteredNotification = ({
    notification
  }: {
    notification: NotificationModel;
  }) => {
    this.$filteredNotificationsList = [
      notification,
      ...this.$filteredNotificationsList
    ];
  };

  resetFilteredNotifications = () => {
    this.$filteredNotificationsList = [];
  };

  resetNotifications = () => {
    this.$notificationsList = [];
  };

  reset = () => {
    this.$notificationsList = [];
    this.$filteredNotificationsList = [];
  };
}

export default NotificationStore;
