import { Avatar, shadow } from "@arcadia/design-system";
import styled from "styled-components";

export const Overlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.primaryColor[50]}80`};
  z-index: ${({ theme }) => theme.zIndex.forModal};
`;

export const ReactionModal = styled.div`
  height: 300px;
  width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  box-shadow: ${shadow[200]};
`;

export const ModalBody = styled.div`
  padding: 12px 16px;
  overflow-y: auto;
  max-height: calc(100% - 54px);

  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 1rem !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey[800]} !important;
    border-radius: 1rem !important;
    transition: 300ms ease-in-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.grey[700]} !important;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;
export const UserCard = styled.div`
  padding: 6px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AvatarWithReaction = styled.div`
  position: relative;
  user-select: none;

  em-emoji {
    position: absolute;
    bottom: 6px;
    height: 8px;
    width: 8px;
    right: 2px;
  }
`;
