import React, { useCallback, useEffect, useRef, useState } from "react";
import * as S from "src/js/components/forms/VendorDropdown/VendorDropdown.styles";
import { useOnClickOutSide } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";
import { Icon, SpecificError } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { DROPDOWN_VARIANT, DROPDOWN_POSITION } from "./VendorDropdown.const";

const VendorDropdownBody = ({
  id = "select-dropdown-body",
  accounts = [],
  vendors = [],
  activeId,
  menuPosition,
  smallMenu,
  actionFunction = () => {}
}) => {
  if (vendors && vendors.length > 0) {
    const accountsList = accounts.map(option => {
      const isSelected = option.id === activeId;
      const currentVendor = vendors.find(
        element => element.id === option.live_vendor
      );

      return (
        <S.VendorDropdownElement
          key={option.id}
          aria-label="accountOption"
          role="option"
          data-testid="accountOption"
          isSelected={isSelected}
          onClick={() => actionFunction({ ...currentVendor, ...option })}
        >
          <S.VendorName>
            <Translate text={currentVendor?.name} />
          </S.VendorName>
          <S.AccountName>{option.account_name}</S.AccountName>
          {/* TODO: add remove account function when backand provides an endpoint for it */}
          {false && (
            <S.IconContainer>
              <Icon icon="errorCircle" width={15} height={15} />
            </S.IconContainer>
          )}
        </S.VendorDropdownElement>
      );
    });
    const vendorsList = vendors.map(option => {
      const isSelected = option.id === activeId;
      return (
        <S.VendorDropdownElement
          key={option.id}
          aria-label="vendorOption"
          role="option"
          data-testid="vendorOption"
          isSelected={isSelected}
          onClick={() => actionFunction(option)}
        >
          <Translate text={option.name} />
        </S.VendorDropdownElement>
      );
    });
    return (
      <S.VendorDropdownBody
        id={id}
        aria-label="comboboxBody"
        role="listbox"
        data-testid="listbox"
        tabindex="-1"
        menuPosition={menuPosition}
        smallMenu={smallMenu}
      >
        {accounts && accounts.length > 0 && (
          <>
            <S.DropdownBodyLabel>
              <Translate text="vendor_dropdown_used_accounts" />
            </S.DropdownBodyLabel>
            {accountsList}
          </>
        )}
        <S.DropdownBodyLabel>
          <Translate
            text={
              accounts && accounts.length > 0
                ? "vendor_dropdown_alternative_list_title"
                : "vendor_dropdown_connection_list_title"
            }
          />
        </S.DropdownBodyLabel>
        {vendorsList}
      </S.VendorDropdownBody>
    );
  }
  return "";
};

const VendorDropdown = ({
  id = "select-dropdown-body",
  name,
  last_account_used,
  accounts = [],
  vendors = [],
  defaultDropdownLabel,
  disabled,
  onChangeFunction = () => {},
  hasError,
  errorMessage,
  variant = DROPDOWN_VARIANT.DARK,
  menuPosition = DROPDOWN_POSITION.DOWN,
  smallMenu = false
}) => {
  const { whiteTheme } = useTheme();
  const lastAccountVendor =
    last_account_used &&
    vendors &&
    vendors.find(element => element.id === last_account_used.live_vendor);
  const [selectedElement, setSelectedElement] = useState({
    ...lastAccountVendor,
    ...last_account_used
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const node = useRef();
  useOnClickOutSide(node, () => setDropdownOpen(false));

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeDropdownValue = useCallback(value => {
    setDropdownOpen(false);
    setSelectedElement(value);
    onChangeFunction(value);
  });

  useEffect(() => {
    setSelectedElement({
      ...lastAccountVendor,
      ...last_account_used
    });
  }, [last_account_used]);

  let dropdownBody = "";

  if (dropdownOpen) {
    dropdownBody = (
      <VendorDropdownBody
        id={id}
        accounts={accounts}
        vendors={vendors}
        actionFunction={changeDropdownValue}
        activeId={selectedElement?.id}
        menuPosition={menuPosition}
        smallMenu={smallMenu}
      />
    );
  }
  const dropdownLabel = (
    <>
      <S.VendorName>
        <Translate
          text={
            selectedElement && selectedElement.name
              ? selectedElement.name
              : defaultDropdownLabel
          }
        />
      </S.VendorName>
      {selectedElement && selectedElement.account_name && (
        <S.AccountName>{selectedElement.account_name}</S.AccountName>
      )}
    </>
  );
  return (
    <S.VendorDropdownContainer ref={node}>
      <S.VendorDropdownButton
        type="button"
        onClick={toggleDropdown}
        disabled={disabled}
        isActive={dropdownOpen}
        aria-haspopup="listbox"
        name={name}
        aria-label="combobox"
        hasError={hasError}
        variant={variant}
      >
        {dropdownLabel}
        <S.VendorDropdownButtonIcon>
          {dropdownOpen ? (
            <Icon icon="chevronUp" width="16px" height="16px" />
          ) : (
            <Icon icon="chevronDown" width="16px" height="16px" />
          )}
        </S.VendorDropdownButtonIcon>
      </S.VendorDropdownButton>
      {dropdownBody}
      {hasError && errorMessage && (
        <S.ErrorMessageContainer>
          <SpecificError theme={whiteTheme} text={errorMessage} />
        </S.ErrorMessageContainer>
      )}
    </S.VendorDropdownContainer>
  );
};

export default VendorDropdown;
