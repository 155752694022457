import { shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const MenuContainer = styled(motion.div)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.toolbar};
  width: 296px;
  right: 0;
  top: calc(100% + 12px);
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  border-radius: 12px;
  padding: 24px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.coldGrey[900]}`};
  box-shadow: ${shadow[600]};
`;

export const EditProfileWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const DividerWrapper = styled.div`
  padding: 16px 0px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

// no ref to design-system Text/Heading styles
export const Username = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
  color: ${({ theme }) => theme.primaryColor[50]};
`;

export const OptionList = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`;
