import styled from "styled-components";

import { STagActivityProps } from "./types";

export const TagActivity = styled.div<STagActivityProps>`
  height: 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 2px 5px 2px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid ${({ theme, color }) => theme.tag.activity.variant[color]};
  color: ${({ theme, color }) => theme.tag.activity.variant[color]};
  background-color: ${({ theme }) => theme.tag.activity.background};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: ${({ theme, color }) =>
      theme.tag.activity.variant[color]};
    color: ${({ theme }) => theme.tag.activity.icon};
  }
  span {
    margin-right: 8px;
    margin-left: 8px;
  }

  svg {
    color: ${({ theme }) => theme.tag.activity.icon};
  }

  div ~ svg,
  span + svg {
    cursor: pointer;
    color: ${({ theme }) => theme.tag.activity.closeColor};
    padding-right: 5px;
  }
`;
