import { ConfirmationAlert } from "@arcadia/design-system";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { BaseComment, NestedComment } from "src/js/types/models/Comment";
import { useTheme } from "styled-components";
import { ListItemProps } from "../../DotsMenu/ListItem";

type DropdownOptionsProps = {
  comment: BaseComment | NestedComment;
  deleteComment?: () => void;
  setEditMode: (val: boolean) => void;
};

const useDropdownOptions = ({
  comment,
  deleteComment,
  setEditMode
}: DropdownOptionsProps) => {
  const {
    GroupStore: { userIsTeacher },
    UserStore: { activeUser },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const [dropdownOptions, setDropdownOptions] = useState<ListItemProps[]>([]);

  const isCurrentUserComment = useMemo(
    () => activeUser.uuid === comment.user.uuid,
    [activeUser, comment]
  );

  const deleteConfirmation = () => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("Il commento sarà eliminato")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          deleteComment();
          closeConfirmDialog();
        }}
      />
    );
  };

  useEffect(() => {
    const opts: ListItemProps[] = [];
    if (userIsTeacher) {
      if (isCurrentUserComment) {
        opts.push({
          id: `${comment.id}edit`,
          icon: "edit",
          label: translate("modifica"),
          onClick: () => {
            setEditMode(true);
          }
        });
      }
      opts.push({
        id: `${comment.id}trash`,
        icon: "trash",
        label: translate("elimina"),
        onClick: deleteConfirmation,
        redShade: true
      });
    }
    setDropdownOptions(opts);
  }, [comment, isCurrentUserComment, userIsTeacher]);

  return {
    dropdownOptions
  };
};

export default useDropdownOptions;
