import styled, { css } from "styled-components";
import { LanguageSelectorStyleProps } from "./LanguageSelector.types";

export const LanguageSelectorWrap = styled.div<LanguageSelectorStyleProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  width: max-content;
  cursor: pointer;
  z-index: 950;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  border-radius: 100px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      background: inherit;
      color: inherit;
      border-radius: inherit;
      right: 4px;
      top: 4px;
      padding: 12px 12px;
    `}
`;

export const LanguageContentFooter = styled.div`
  margin-top: auto;
`;

export const LanguageContentBody = styled.div`
  margin-bottom: 32px;
`;

export const LanguageContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LanguageModalContainer = styled.div`
  padding: 20px;
`;

export const MobileWrapper = styled.div`
  padding: 32px 16px 40px;
  height: 100%;
`;
