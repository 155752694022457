import React from "react";
import { Button, Input } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { FormInputs } from "src/js/types";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { badgeFields } from "./utils";
import * as S from "./styles";
import { BadgeRequiredFieldNames } from "./types";
import { CreateEditBadgeStep } from "../CreateEditBadgeModal/types";

const BadgeRequiredFields = ({
  setStep,
  setProgressPercentage,
  isEditing
}: {
  setStep: (step: number) => void;
  setProgressPercentage: (progress: number) => void;
  isEditing?: boolean;
}) => {
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceName },
    SpaceBadgesStore: { badgeRequiredFields, setBadgeRequiredFields }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const { register, handleSubmit, errors } = useForm<
    FormInputs<BadgeRequiredFieldNames>
  >({
    defaultValues: {
      name: badgeRequiredFields.name,
      description: badgeRequiredFields.description,
      criteria: badgeRequiredFields.criteria,
      issuer: isEditing
        ? badgeRequiredFields.issuer
        : `WeSchool - ${activeSpaceName}`
    }
  });

  const submitForm = handleSubmit(({ criteria, description, issuer, name }) => {
    setStep(CreateEditBadgeStep.OPTIONAL_FIELDS_STEP);
    setProgressPercentage(66);
    setBadgeRequiredFields({
      ...badgeRequiredFields,
      criteria,
      description,
      issuer,
      name
    });
  });

  return (
    <S.BadgeRequiredFieldsContainer>
      <S.Form onSubmit={submitForm}>
        <S.FieldsContainer>
          {badgeFields.map(field => (
            <Input
              {...field}
              key={field.name}
              theme={isLayoutModeMobile ? greyTheme : whiteTheme}
              ref={register(field.validation)}
              rounded
              label={translate(field.label)}
              placeholder={translate(field.placeholder)}
              maxLength={field.maxLength}
              error={{
                message: errors?.[field.name]
                  ? translate(errors[field.name].message as TranslationKeys)
                  : null
              }}
            />
          ))}
        </S.FieldsContainer>
        <Button theme={greyTheme} variant="primary" fullWidth type="submit">
          {translate("space_badges_create_modal_next_button")}
        </Button>
      </S.Form>
    </S.BadgeRequiredFieldsContainer>
  );
};

export default observer(BadgeRequiredFields);
