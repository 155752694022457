import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-flow: wrap;
`;

export const Cell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  width: 156px;
  height: 96px;
  border-radius: 8px;
  gap: 4px;
  border: 0;

  svg {
    color: ${({ theme }) => theme.picker.icon.color};
  }

  background-color: ${({ theme }) => theme.picker.background};
  &:hover {
    cursor: pointer;
    border: ${({ theme }) => theme.picker.border};
  }
  &:focus {
    outline: ${({ theme }) => theme.picker.focus.border};
  }
`;
