import styled from "styled-components";

export const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
`;

export const EditLayer = styled.div<{ isDefaultAvatar: boolean }>`
  position: absolute;
  top: ${({ isDefaultAvatar }) => (isDefaultAvatar ? "1px" : "0px")};
  left: ${({ isDefaultAvatar }) => (isDefaultAvatar ? "1px" : "0px")};
  display: flex;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.violet[50]}99;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.grey[1000]};
  }

  input {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
