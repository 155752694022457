import React, { useRef, useState } from "react";
import { Editor } from "@tiptap/core";
import { Icon } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { CircularLoader } from "src/js/components/global/CircularLoader";

import { AICustomPromptEditor } from "../AICustomPromptEditor";
import * as S from "./AICustomPrompt.styles";

const AICustomPrompt = ({ editor }: { editor: Editor }) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const inputRef = useRef<HTMLInputElement>();
  const { translate } = useTranslation();

  React.useLayoutEffect(() => {
    if (
      inputRef?.current &&
      editor.storage.ai.isCustomPromptActive &&
      !query.trim().length
    ) {
      inputRef?.current?.focus();
    }
  }, [editor.storage.ai.isCustomPromptActive, query]);

  return (
    <S.AICustomPromptWrapper>
      <S.InputRow
        onSubmit={e => {
          e.preventDefault();
          setSubmitDisabled(true);
          let q = inputRef.current.value;
          const { from, to, empty } = editor.state.selection;
          if (!empty)
            q = `${q} Text:"""${editor.state.doc.textBetween(from, to)}"""`;
          q = `${q} If the response contains mathematical expressions, use LaTeX notation by surrounding the expression with dollar signs ($). For example, 2 + 2 = 4 should be written as $2 + 2 = 4$. For other queries, respond normally.`;
          setQuery(q);
        }}
      >
        <Icon icon="sparkles" width={20} height={20} initialViewbox />
        <S.CustomPromptInput
          ref={inputRef}
          disabled={loading}
          placeholder={translate("ai_custom_prompt_placeholder")}
          onKeyDown={({ key }) => {
            if (!inputRef?.current?.value && key === "Backspace") {
              editor.chain().setAIPromptActive(false).focus().run();
            }
          }}
          onChange={() => {
            setSubmitDisabled(!inputRef?.current?.value?.trim());
          }}
          autoFocus
        />
        {loading ? (
          <CircularLoader size={30} />
        ) : (
          <S.RoundButton type="submit" disabled={submitDisabled}>
            <Icon icon="arrowRight" width={16} height={16} initialViewbox />
          </S.RoundButton>
        )}
      </S.InputRow>
      <AICustomPromptEditor
        query={query}
        onLoading={setLoading}
        onApply={content => {
          setQuery("");
          editor
            .chain()
            .setAIPromptActive(false)
            .focus()
            .insertContent(content)
            .run();
        }}
        onDiscard={() => {
          editor.chain().setAIPromptActive(false).focus().run();
        }}
      />
    </S.AICustomPromptWrapper>
  );
};

export default AICustomPrompt;
