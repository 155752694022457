/* eslint-disable react/no-danger */
import { Box, Text } from "@arcadia/design-system";
import React from "react";
import {
  __FORM_CONTRACT__,
  __FORM_REGISTRATION__
} from "src/js/settings/settingsFormValidation";
import {
  __PRIVACY_LINK__,
  __TERMS_LINK__
} from "src/js/settings/settingsGeneral";
import { useTranslation } from "src/js/translation";
import { InputCheckboxValidatedPrimary } from "src/js/components/forms/InputCheckboxValidatedPrimary";
import * as S from "./Terms.styles";

const Terms = ({
  register,
  watchTerms,
  watchUnderage,
  errors,
  contract,
  isTutor = false
}) => {
  const { translate } = useTranslation();
  const privacy_link = __PRIVACY_LINK__;
  const terms_link = translate({ text: __TERMS_LINK__ });

  if (watchUnderage) {
    return (
      <div className="margin-top-10">
        <span
          dangerouslySetInnerHTML={{
            __html: translate({
              text: "underage_check",
              stringVariables: {
                privacy_link,
                terms_link
              }
            })
          }}
        />
      </div>
    );
  }

  const termsLabel = contract ? (
    <span
      dangerouslySetInnerHTML={{
        __html: translate({
          text: "terms4_label",
          stringVariables: {
            terms_link
          }
        })
      }}
    />
  ) : (
    <span
      dangerouslySetInnerHTML={{
        __html: translate({
          text: "terms_label",
          stringVariables: {
            terms_link,
            privacy_link
          }
        })
      }}
    />
  );

  const weschoolContactLabel = (
    <span
      dangerouslySetInnerHTML={{
        __html: translate({
          text: isTutor
            ? "parent_terms_commercial_label"
            : "terms_commercial_label",
          stringVariables: {
            privacy_link
          }
        })
      }}
    />
  );

  const thirdPartContactLabel = (
    <span
      dangerouslySetInnerHTML={{
        __html: translate({
          text: isTutor
            ? "parent_terms_commercial_third_label"
            : "terms_commercial_third_labe",
          stringVariables: {
            privacy_link
          }
        })
      }}
    />
  );

  return (
    <>
      <Box margin="16px 0px">
        {contract ? (
          <Text type="formSmallCaption">
            {translate("treatment_responsability")}
          </Text>
        ) : (
          !isTutor && (
            <Text type="formSmallCaption">
              {translate({ text: "company_header" })}
            </Text>
          )
        )}
      </Box>
      <S.CheckboxContainer>
        {contract ? (
          <InputCheckboxValidatedPrimary
            name="terms4"
            label={termsLabel}
            register={register}
            validation={__FORM_CONTRACT__.terms4}
            errorMessage={errors?.terms4?.message}
            id="terms4"
          />
        ) : (
          <InputCheckboxValidatedPrimary
            name="terms"
            label={termsLabel}
            register={register}
            validation={__FORM_REGISTRATION__.terms}
            errorMessage={errors?.terms?.message}
            id="terms"
          />
        )}
      </S.CheckboxContainer>
      {contract && watchTerms && <div className="separator-horizontal" />}
      {contract && watchTerms && (
        <Text type="formSmallCaption">
          <Box margin="16px 0px">
            <div
              dangerouslySetInnerHTML={{
                __html: translate({
                  text: "label_treatment",
                  stringVariables: { privacy_link }
                })
              }}
            />
          </Box>
        </Text>
      )}
      {watchTerms && (
        <S.CheckboxContainer>
          <InputCheckboxValidatedPrimary
            name="weschool_contact"
            register={register}
            label={weschoolContactLabel}
            id="weschool_contact"
            validation={null}
          />
          <InputCheckboxValidatedPrimary
            name="third_part_contact"
            register={register}
            label={thirdPartContactLabel}
            id="third_part_contact"
            validation={null}
          />
        </S.CheckboxContainer>
      )}
      <Box className="margin-top-8 margin-bottom-8">
        <Text type="formSmallCaption">{translate("terms_explanation_1")}</Text>
      </Box>
      {watchTerms && (
        <Text type="formSmallCaption">
          <div
            dangerouslySetInnerHTML={{
              __html: translate({
                text: "terms_explanation_2",
                stringVariables: { privacy_link }
              })
            }}
          />
        </Text>
      )}
    </>
  );
};

export default Terms;
