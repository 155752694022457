import { ChangeEvent, useCallback, DragEvent, useState } from "react";
import { useResourcePickerErrors } from "src/js/components/ResourcePicker/providers/ResourcePickerErrorProvider";

const useFileUpload = ({ multiple }: { multiple: boolean }) => {
  const [files, setFiles] = useState<FileList>();
  const { setErrors } = useResourcePickerErrors();

  const handleDragOver = useCallback(
    (e: DragEvent<HTMLDivElement>) => e.preventDefault(),
    []
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      // Clear the errors when new files are selected
      setErrors([]);
      e.preventDefault();
      if (!multiple && e.dataTransfer.files.length > 1) {
        // Don't allow multiple files to be uploaded if input is not set to multiple
      }
      setFiles(e.dataTransfer.files);
    },
    [multiple]
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // Clear the errors when new files are selected
    setErrors([]);
    setFiles(e.target.files);
  }, []);

  return { handleDragOver, handleInputChange, handleDrop, files };
};

export default useFileUpload;
