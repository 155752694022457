import styled from "styled-components";
import { ModulesResourceDraftPropsStyle } from "src/js/components/modules/ModuleResourceDraftItem/types";

export const ResourcePreviewExercise = styled.div`
  flex: 1;
  display: flex;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  margin: 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ResourceContainerMobile = styled.div`
  display: flex;
  height: 72px;
  flex-flow: row;
  background: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px;
`;

export const ResourcePreviewMobile = styled.div<ModulesResourceDraftPropsStyle>`
  position: relative;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: ${({ isExercise }) => (isExercise ? "0 8px 8px" : "0")};
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ background, placeholder }) =>
    background ? `url(${background})` : `url(${placeholder})`};
  background-color: ${({ theme, isExercise }) =>
    isExercise ? theme.colors.coldGrey[900] : theme.colors.violet[500]};
  height: 56px;
  min-width: 56px;
  width: 56px;
  background-size: ${({ background }) => (background ? "cover" : "20px")};
`;

export const ResourceInfoMobile = styled.div`
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  justify-content: center;
  margin: 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    white-space: nowrap;
  }
`;

export const ResourceInfoCreationMobile = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const ResourceActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  gap: 20px;
`;
