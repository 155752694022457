import React from "react";
import * as S from "src/js/pages/wall/WallBadgeBox/WallBadgeBox.styles";
import { Text } from "@arcadia/design-system";
import { WallBadgeProps } from "./types";

const WallBadge = ({ name, image }: WallBadgeProps) => {
  return (
    <S.WallBadgeContainer>
      <S.BadgeImage src={image} alt="Badge-logo" />
      <S.BadgeName>
        <Text type="formField">{name}</Text>
      </S.BadgeName>
    </S.WallBadgeContainer>
  );
};

export default WallBadge;
