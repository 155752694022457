import styled from "styled-components";

export const ResponseResource = styled.div`
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.basicWhite};
  padding-right: 15px;
  padding-left: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent2};
  }
`;

export const ResponseResourceTitle = styled.span`
  width: 150px;
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 14px;
`;

export const AttachmentIconContainer = styled.div`
  height: 25px;
`;

export const RemoveButton = styled.div`
  color: ${({ theme }) => theme.colors.basicWhite};
  &:hover {
    cursor: pointer;
  }
`;
