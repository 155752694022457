import getUserClient from "src/js/modules/connection";
import {
  api_calendar_event,
  api_calendar_event_id,
  api_group_calendar,
  api_group_calendar_event,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";

export function fetchCalendar({ groupId, from, to, limit, type }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_calendar({ groupId })), {
      params: {
        from,
        to,
        limit,
        type
      }
    })
    .then(({ data }) => {
      return data;
    });
}

export function fetchCalendarEvent({ groupId, calendarEventId }) {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_group_calendar_event({ groupId, calendarEventId }))
    )
    .then(({ data }) => {
      return data;
    });
}

// axios delete payload/body needs to be inside a data option object
export function removeCalendarEvent({ calendarEventId, redirect_to }) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_calendar_event_id({ calendarEventId })), {
      data: { redirect_to }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = error.response.data;
      throw Err;
    });
}

export function addCalendarEvent({
  type,
  title,
  description,
  start,
  end,
  all_day,
  group_recipients,
  user_recipients,
  live_vendor_account_id,
  live_vendor_id,
  recursion_mode,
  recursion_times,
  redirect_to,
  resource_attachments,
  board_attachments,
  exercise_attachments,
  live_url
}) {
  const body = {
    type,
    title,
    description,
    start,
    end,
    all_day,
    group_recipients,
    user_recipients,
    live_vendor_account_id,
    live_vendor_id,
    recursion_mode,
    recursion_times,
    redirect_to,
    resource_attachments,
    board_attachments,
    exercise_attachments,
    live_url
  };

  return getUserClient()
    .post(getVersionedApiUrl(api_calendar_event()), body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = error.response.data;
      throw Err;
    });
}

export function updateCalendarEvent({
  calendarEventId,
  type,
  title,
  description,
  start,
  end,
  all_day,
  group_recipients,
  user_recipients,
  redirect_to,
  resource_attachments,
  board_attachments,
  exercise_attachments,
  live_url
}) {
  const body = {
    type,
    title,
    description,
    start,
    end,
    all_day,
    group_recipients,
    user_recipients,
    redirect_to,
    resource_attachments,
    board_attachments,
    exercise_attachments,
    live_url
  };
  return getUserClient()
    .put(getVersionedApiUrl(api_calendar_event_id({ calendarEventId })), body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = error.response.data;
      throw Err;
    });
}
