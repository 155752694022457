import { Box, Icon, Text } from "@arcadia/design-system";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import { chargebeePortal } from "src/js/pages/spaces/dashboard/SpacesSettings/ChargebeeUtils";
import { ChargebeeModal } from "../ChargebeeModal";
import * as S from "./ChargebeeWidgetCard.styles";
import { ChargebeeWidgetCardProps } from "./ChargebeeWidgetCard.types";

const ChargebeeWidgetCard: FC<ChargebeeWidgetCardProps> = ({
  title,
  iconType,
  widgetType,
  description
}) => {
  const {
    ModalStore: { openModal, closeModal },
    SpacesSettingsStore
  } = useStores();

  const { subscriptionId } =
    SpacesSettingsStore?.generalSettings?.planSettings || {};

  const openChargebeeModal = () => {
    openModal(() => (
      <ChargebeeModal
        callbackChargebeeWidget={callbacks => {
          chargebeePortal({ subscriptionId, widgetType, callbacks });
        }}
        close={closeModal}
      />
    ));
  };

  return (
    <S.CardContainer onClick={openChargebeeModal}>
      <Box flex="1" display="flex" flexDirection="column">
        <S.TitleContainer>
          <Icon initialViewbox icon={iconType} height={16} width={16} />
          <S.StyledTitle type="formField">{title}</S.StyledTitle>
        </S.TitleContainer>
        <S.DescriptionContainer>
          <Text type="formSubtitle">{description}</Text>
        </S.DescriptionContainer>
      </Box>
      <S.ArrowContainer>
        <Icon icon="chevronRight" height={16} width={16} />
      </S.ArrowContainer>
    </S.CardContainer>
  );
};

export default ChargebeeWidgetCard;
