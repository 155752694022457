import { navigateTo } from "Legacy/modules/history";
import React from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useStores } from "src/js/hooks";
import { getExternalRedirectUrl } from "src/js/modules/commonFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import createUrl from "src/js/modules/routing";
import { getLocalizedUniversityName } from "src/js/modules/universityFunction";
import { navigateToRedirectUrl } from "src/js/modules/urlFunction";
import * as S from "src/js/pages/unlogged/ExternalProviderLogin/ExternalProviderLogin.styles";
import { api_sso } from "src/js/repository/apiUrls";
import { __API_EXTERNAL_AUTH_PATH__ } from "src/js/settings/settingsUrl";
import { TranslationKeys } from "src/js/translation";
import { useTheme } from "styled-components";

const ExternalProviderLogin = ({
  savedProvider
}: {
  savedProvider: { entityID: string };
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { greyTheme } = useTheme();

  const loginExternal = () => {
    if (savedProvider) {
      const redirectUrl = getExternalRedirectUrl();
      const query = {
        provider: savedProvider.entityID,
        redirect: redirectUrl
      };
      navigateToRedirectUrl(__API_EXTERNAL_AUTH_PATH__ + api_sso(), query);
    } else {
      navigateTo(createUrl("login_university"));
    }
  };

  const providerName = savedProvider
    ? getLocalizedUniversityName(
        savedProvider,
        getCurrentLanguage().substring(0, 2)
      )
    : null;

  const label: TranslationKeys =
    providerName || "login_university_button_placeholder";

  return isLayoutModeMobile ? (
    <S.MobileContainer>
      <S.UniversityButton
        theme={greyTheme}
        icon="university"
        viewBox="0 0 24 24"
        onClick={loginExternal}
      />
    </S.MobileContainer>
  ) : (
    <TranslatedTooltip tooltipString={label}>
      <S.DesktopContainer>
        <S.UniversityButton
          theme={greyTheme}
          icon="university"
          viewBox="0 0 24 24"
          onClick={loginExternal}
        />
      </S.DesktopContainer>
    </TranslatedTooltip>
  );
};

export default ExternalProviderLogin;
