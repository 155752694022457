import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation";
import { ContextualError, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import * as S from "./UploadError.styles";

const UploadError = ({
  title,
  text
}: {
  title: TranslationKeys;
  text: TranslationKeys;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <S.UploadErrorContainer>
      <S.Image>
        <Icon
          icon="illustration-uploadError"
          width="160"
          height="151"
          viewbox="0 0 160 151"
        />
      </S.Image>
      <S.Text>{translate(title)}</S.Text>
      <S.BannerContainer>
        <ContextualError theme={whiteTheme} text={translate(text)} />
      </S.BannerContainer>
    </S.UploadErrorContainer>
  );
};

export default UploadError;
