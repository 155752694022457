import { format } from "date-fns";
import { observer } from "mobx-react";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import { useStores, useTabNotification } from "src/js/hooks";
import { readLocalData } from "src/js/modules/storageFunction";
import { fetchLoggedUser } from "src/js/repository/userRepository";
import { useTranslation } from "src/js/translation";
import { fetchLocationData } from "src/js/modules/localStorage";
import {
  __DEFAULT_USER_DETAILS__,
  __INITIAL_VIEWTRACKING_STATE__
} from "./ViewTrackingProvider.consts";
import {
  UserDetails,
  ViewTrackingContextType,
  VirtualViewDescriptionStrings
} from "./ViewTrackingProvider.types";
import {
  getPageMetaTitle,
  removeUndefinedKeys,
  resetDataLayer
} from "./ViewTrackingProvider.utils";

const ViewTrackingContext = createContext<ViewTrackingContextType>(
  __INITIAL_VIEWTRACKING_STATE__
);

export const useViewTracking = () => useContext(ViewTrackingContext);

const ViewTrackingProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState({
    id: undefined,
    birthday: undefined,
    email: undefined
  });
  const location = useLocation();
  const { translate } = useTranslation();
  const {
    SpaceStore: { activeSpaceName }
  } = useStores();
  useTabNotification();

  const setPageTitle = () => {
    const pageTitle = getPageMetaTitle(location.pathname);
    document.title = `WeSchool ${pageTitle ? `| ${translate(pageTitle)}` : ""}`;
    if (activeSpaceName && document.title === "WeSchool") {
      document.title = `WeSchool | ${activeSpaceName}`;
    }
  };

  const getUserDetails = async (): Promise<UserDetails> => {
    const userId = readLocalData("userId");
    if (userId && user.id !== userId) {
      const { birthday: isoBirthDay, email } = await fetchLoggedUser();
      const birthday = format(new Date(isoBirthDay), "yyyyMMdd");
      const userDetails = {
        id: String(userId),
        email,
        birthday
      };
      setUser(userDetails);
      return userDetails;
    }
    if (!userId) {
      return __DEFAULT_USER_DETAILS__;
    }
    return user;
  };

  const getLocationData = useCallback(fetchLocationData, [location.pathname]);

  const trackView = useCallback(async () => {
    const { id: userId, birthday, email } = await getUserDetails();
    const isWeSchoolMail = email?.endsWith("@weschool.com");
    const ld = await getLocationData();
    const dataLayer = removeUndefinedKeys({
      consent: String(ld.shouldUseTracking),
      dimension1: String(ld.groupId),
      dimension2: String(userId),
      dimension5: String(ld.isTeacher),
      dimension7: "Client",
      dimension8: String(ld.spaceId),
      dimension10: String(ld.platformLanguage),
      dimension11: String(ld.pathDescription),
      dimension12: String(ld.spaceSlug),
      dimension13: String(ld.trial),
      dimension14: String(birthday),
      dimension15: String(ld.isDemo),
      dimension16: String(isWeSchoolMail),
      event: "Pageview",
      pagePath: String(location.pathname),
      pageTitle: String(document.title)
    });

    TagManager.dataLayer({ dataLayer });
  }, [location.pathname]);

  const trackVirtualView = useCallback(
    async (virtualViewDescription: VirtualViewDescriptionStrings) => {
      const { id: userId, birthday, email } = await getUserDetails();
      const isWeSchoolMail = email?.endsWith("@weschool.com");
      const ld = await getLocationData();
      const dataLayer = removeUndefinedKeys({
        consent: String(ld.shouldUseTracking),
        dimension1: String(ld.groupId),
        dimension2: String(userId),
        dimension5: String(ld.isTeacher),
        dimension7: "Client",
        dimension8: String(ld.spaceId),
        dimension10: String(ld.platformLanguage),
        dimension11: String(virtualViewDescription),
        dimension12: String(ld.spaceSlug),
        dimension13: String(ld.trial),
        dimension14: String(birthday),
        dimension15: String(ld.isDemo),
        dimension16: String(isWeSchoolMail),
        event: "VirtualView",
        pagePath: String(location.pathname),
        pageTitle: String(document.title)
      });

      TagManager.dataLayer({ dataLayer });
    },
    []
  );

  const updatePageTitle = useCallback((title: string) => {
    if (title.trim() === "") {
      document.title = "WeSchool";
    } else {
      document.title = `WeSchool | ${title}`;
    }
  }, []);

  useEffect(() => {
    setPageTitle();
    trackView();
    resetDataLayer();
  }, [location.pathname]);

  const contextValue = { trackVirtualView, trackView, updatePageTitle };

  return (
    <ViewTrackingContext.Provider value={contextValue}>
      {children}
    </ViewTrackingContext.Provider>
  );
};

export default observer(ViewTrackingProvider);
