import moment from "moment";
import { useTheme } from "styled-components";
import React from "react";
import { GroupNumberBadge } from "src/js/components/GroupNumberBadge";
import { Avatar, Text } from "@arcadia/design-system";

import * as S from "./UserPreviewInfo.styles";

const UserPreviewInfo = ({ userObject = {} }) => {
  const { groupsCount, user } = userObject;
  const { email, birthday, name, surname, avatar } = user || {};
  const dateBirthday = birthday && moment(birthday).format("L");
  const { whiteTheme } = useTheme();

  return (
    <S.UserPreviewInfoContainer>
      <Avatar
        url={avatar?.small}
        alt={`${name} - ${surname}`}
        size="xl"
        theme={whiteTheme}
        rounded
      />
      <S.Info>
        <S.Wrapper>
          <Text type="headerTitle">
            {name}&nbsp;<S.Lastname>{surname}</S.Lastname>
          </Text>
          <GroupNumberBadge number={groupsCount} />
        </S.Wrapper>
        <Text type="formDescription">{dateBirthday}</Text>
        <S.Mail>
          <Text type="formDescription">{email}</Text>
        </S.Mail>
      </S.Info>
    </S.UserPreviewInfoContainer>
  );
};

export default UserPreviewInfo;
