/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import {
  fetchSpacesCanCreateGroup,
  fetchSpaceGroupsCanMigrate
} from "src/js/repository/spacesRepository";
import { GroupUserRoleEnum } from "src/js/types";
import { TEMPORARY_SPACE_SLUG } from "src/js/settings/settingsSpaces";
import UserStore from "./UserStore";

/**
 * @name SpaceMoveGroupsStore
 *
 * @description
 * Retrive information about user groups in temporary space
 */
class SpaceMoveGroupsStore {
  $hasTemporaryGroups = false;
  $hasDestinationsSpaces = false;
  userStore: UserStore;

  constructor({ userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.userStore = userStore;
    this.disposeOnChangeUser();
  }

  setHasTemporaryGroups = value => {
    this.$hasTemporaryGroups = value;
  };

  setHasDestinationsSpaces = value => {
    this.$hasDestinationsSpaces = value;
  };

  getTemporarySpaceGroups = () => {
    return fetchSpaceGroupsCanMigrate({
      spaceId: TEMPORARY_SPACE_SLUG,
      role: GroupUserRoleEnum.Teacher,
      limit: 30,
      offset: 0
    })
      .then(({ results }) => {
        if (results?.length > 0) {
          this.setHasTemporaryGroups(true);
        }
      })
      .catch(error => {
        this.setHasTemporaryGroups(false);
        throw error;
      });
  };

  getDestinationSpaces = () => {
    return fetchSpacesCanCreateGroup({
      limit: 30,
      offset: 0
    })
      .then(({ results }) => {
        if (results?.length > 0) {
          this.setHasDestinationsSpaces(true);
        }
      })
      .catch(error => {
        this.setHasDestinationsSpaces(false);
        throw error;
      });
  };

  get hasTemporaryGroups() {
    return this.$hasTemporaryGroups;
  }

  get hasDestinationsSpaces() {
    return this.$hasDestinationsSpaces;
  }

  disposeOnChangeUser = () => {
    reaction(
      // check if temporary space has groups on change of user
      () => this.userStore.activeUser,
      () => {
        this.getTemporarySpaceGroups();
        this.getDestinationSpaces();
      }
    );
  };

  reset = () => {
    this.$hasTemporaryGroups = false;
    this.$hasDestinationsSpaces = false;
  };
}

export default SpaceMoveGroupsStore;
