import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiCourseGeneratorEvent, EventDomain } from "src/js/types";
import { PreviewModuleList } from "./components";

import * as S from "./CourseListPreviewStep.styles";
import { ModuleListItem } from "./components/PreviewModuleList";

const CourseListPreviewStep = ({
  onSubmit,
  startingList,
  onRedo
}: {
  onRedo: () => void;
  onSubmit: (newList: ModuleListItem[]) => void;
  startingList?: ModuleListItem[];
}) => {
  const [list, setList] = useState<ModuleListItem[]>(startingList);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const onDelete = useCallback(
    (id: string) => {
      const newList = list.filter(item => item.id !== id);
      setList(newList);
    },
    [list]
  );

  const hasEmptyContents = useMemo(
    () =>
      list.some(
        listItem => !listItem.description?.trim() || !listItem.title?.trim()
      ),
    [list]
  );

  const handleRedo = useCallback(() => {
    onRedo();
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardPreviewRegenerate
    );
  }, [onRedo]);

  const handleSubmit = useCallback(() => {
    onSubmit(list);
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardPreviewGenerateDraft
    );
  }, [onSubmit, list]);

  return (
    <>
      <Box marginBottom="16px" display="flex" flexDirection="column" gap="8px">
        <Text type="formField">
          {translate("ai_wizard_course_list_preview_section_title")}
        </Text>
        <Text type="formDescription">
          {translate("ai_wizard_course_list_preview_section_description")}
        </Text>
      </Box>
      <S.ScrollableArea>
        <S.NewModuleButton
          type="button"
          onClick={() => {
            _trackEvent(
              EventDomain.AiCourseGenerator,
              AiCourseGeneratorEvent.AiWizardPreviewAddModule
            );
            // TODO remove this with real implementation
            const newItem = {
              description: "",
              title: "",
              id: `${Math.random()}`,
              time: startingList[0].time,
              isExpanded: true
            };
            setList([newItem, ...list]);
          }}
        >
          <S.CustomText type="formField">
            <Icon icon="plusThin" width="14px" height="14px" />
            {translate("ai_wizard_course_list_preview_add_new_module_cta")}
          </S.CustomText>
        </S.NewModuleButton>
        <PreviewModuleList
          list={list}
          onDelete={onDelete}
          onUpdate={newList => {
            setList([]);
            setList(newList);
          }}
        />
      </S.ScrollableArea>
      <S.Footer>
        <Button
          theme={whiteTheme}
          variant="secondary"
          type="button"
          onClick={handleRedo}
        >
          {translate("ai_wizard_course_list_preview_regenerate_course_cta")}
        </Button>
        <Button
          theme={whiteTheme}
          onClick={handleSubmit}
          disabled={!list.length || hasEmptyContents}
          variant="primary"
          type="button"
        >
          {translate("ai_wizard_course_list_preview_draft_content_cta")}
        </Button>
      </S.Footer>
    </>
  );
};

export default CourseListPreviewStep;
