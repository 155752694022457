/* eslint-disable no-restricted-syntax */
import { parseEmojis } from "@weschooleng/emoji-parser";
import { EmojiOnPick } from "./SocialInput.types";

export const getMentions = (text: string) => {
  const plainMentions: string[] = [];
  const uuidMentions: string[] = [];
  const regex = /@\[([\s\S]*?)\]\(([\s\S]*?)\)/gm;
  const fullMentions = text.match(regex);

  if (fullMentions === null) return [[], []];

  for (const fullMention of fullMentions) {
    const plainMention = fullMention
      .split("(")[0]
      .replace("[", "")
      .replace("]", "");
    plainMentions.push(plainMention);
    uuidMentions.push(fullMention);
  }
  return [uuidMentions, plainMentions];
};

export const parseEmojisInPosition = (text: string, cursorIndex: number) => {
  const beforeCursor = text.slice(0, cursorIndex);
  const afterCursor = text.slice(cursorIndex, text.length);

  const filteredBeforeCursor = parseEmojis(beforeCursor);
  const filteredAfterCursor = parseEmojis(afterCursor);

  const newText = filteredBeforeCursor + filteredAfterCursor;
  const newCursorPosition = filteredBeforeCursor.length;

  return { newText, newCursorPosition };
};

export const appendEmojiInPosition = (
  text: string,
  cursorIndex: number,
  emoji: EmojiOnPick
) => {
  const [uuidMentions, plainMentions] = getMentions(text);
  let textNoMentions = text;
  uuidMentions.forEach((mention, index) => {
    textNoMentions = textNoMentions.replace(mention, plainMentions[index]);
  });

  const beforeEmoji = textNoMentions.slice(0, cursorIndex);
  const afterEmoji = textNoMentions.slice(cursorIndex, textNoMentions.length);

  const modifiedText = `${beforeEmoji}${emoji.native}${afterEmoji}`;
  const modifiedCursor = cursorIndex + emoji.native.length;

  const beforeCursor = modifiedText.slice(0, modifiedCursor);
  const afterCursor = modifiedText.slice(modifiedCursor, modifiedText.length);

  const filteredBeforeCursor = parseEmojis(beforeCursor);
  const filteredAfterCursor = afterCursor;

  let newText = filteredBeforeCursor + filteredAfterCursor;

  plainMentions.forEach((mention, index) => {
    newText = newText.replace(mention, uuidMentions[index]);
  });

  const newCursorPosition = filteredBeforeCursor.length;
  return { newText, newCursorPosition };
};
