import styled, { DefaultTheme } from "styled-components";

import { SCardSchoolProps } from "./types";

const getBorderColor = ({
  isLocked,
  checked,
  theme
}: SCardSchoolProps & { theme: DefaultTheme }) => {
  if (isLocked === true) {
    return theme.card.cardschool.border;
  } else if (checked === true) {
    return theme.card.cardschool.checked.border;
  }

  return theme.card.cardschool.border;
};

export const CardSchool = styled.button<SCardSchoolProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.card.cardschool.background};
  border: ${({ isLocked, checked, theme }) =>
    getBorderColor({ isLocked, checked, theme })};

  box-sizing: border-box;
  border-radius: 8px;

  cursor: ${({ isLocked }) => (isLocked ? "not-allowed" : "pointer")};
  span:nth-child(1) {
    color: ${({ theme }) => theme.card.cardschool.title.color};
    text-align: initial;
  }
  span:nth-child(2) {
    color: ${({ theme }) => theme.card.cardschool.body.color};
    text-align: initial;
  }

  &:focus-within {
    outline: ${({ theme, isLocked }) =>
      !isLocked ? theme.card.cardschool.focus.border : null};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-left: 16px;
  box-sizing: border-box;
  margin-bottom: 6px;
  @media screen and (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

export const AvatarContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 8px;
  color: ${({ theme }) => theme.card.cardschool.check.background};
`;

export const SvgWrapper = styled.div`
  @media screen and (min-width: 768px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    > svg {
      position: absolute;
      left: -30px;
      top: -5px;
      transform: scale(0.5);
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
