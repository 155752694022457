import styled, { css } from "styled-components";

export const Layout = styled.div<{ noTopBorder?: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.title};
  border-top: 1px solid ${({ theme }) => theme.colors.accent7};
  padding: 24px 6px;
  display: flex;
  align-items: flex-start;
  ${({ noTopBorder }) =>
    noTopBorder &&
    css`
      border-top: none;
    `}
`;

export const IconContainer = styled.div<{ isOpen?: boolean }>`
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.primaryColor[500] : theme.colors.grey[50]};
`;

export const TextContainer = styled.div`
  flex: 1;
  margin: 0px 16px;
`;

export const Title = styled.h5`
  margin: 0 0 8px;
  color: ${({ theme }) => theme.colors.grey[50]};
  font-size: 16px;
  font-weight: 400;
`;

export const Description = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  font-weight: 400;
`;
