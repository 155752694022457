import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { ContentSwitcher, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { EventCategory, EventCategoryType } from "../Calendar.types";
import * as S from "./CalendarEventModalView.styles";

type CalendarEventModalTabsPropsType = {
  showTabs: boolean;
  selectedType: EventCategoryType;
  setEventType: (type: EventCategoryType) => void;
};

const CalendarEventContentSwitcher = ({
  showTabs,
  selectedType,
  setEventType
}: CalendarEventModalTabsPropsType) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();

  return isLayoutModeMobile && showTabs ? (
    <S.ContentSwitcherWrap>
      <ContentSwitcher
        theme={greyTheme}
        items={[
          {
            id: EventCategory.Live,
            value: EventCategory.Live,
            disabled: false,
            children: (
              <Text type="cta">
                {translate({
                  text: "calendar_event_modal_tab_live"
                })}
              </Text>
            )
          },
          {
            id: EventCategory.Activity,
            value: EventCategory.Activity,
            disabled: false,
            children: (
              <Text type="cta">
                {translate({
                  text: "calendar_event_modal_tab_activity"
                })}
              </Text>
            )
          },
          {
            id: EventCategory.Deadline,
            value: EventCategory.Deadline,
            disabled: false,
            children: (
              <Text type="cta">
                {translate({
                  text: "calendar_event_modal_tab_deadline"
                })}
              </Text>
            )
          }
        ]}
        activeOption={selectedType}
        onContentItemChange={val => {
          setEventType(EventCategory[val]);
        }}
      />
    </S.ContentSwitcherWrap>
  ) : null;
};

export default CalendarEventContentSwitcher;
