/* eslint-disable filenames/match-exported */
import indexOf from "lodash/indexOf";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { fetchPost, fetchPosts } from "src/js/repository/postRepository";
import {
  postListDependentEvents,
  postListOffDependentEvents
} from "../events/realtimeEvents";
import { getActiveGroup } from "../modules/activeGroup";
import { getUserId } from "../modules/activeUser";
import { InternalDispatcher } from "../modules/dispatcher";
import { navigateTo } from "../modules/history";
import { basePageModel } from "../modules/pageStatus";
import { createURL } from "../modules/utility";
import Wall from "../view/wall/Wall";
import NewPost from "../view/wall/post/NewPost";
import SinglePostController from "./SinglePostController";

const POST_LIMIT = 6;

const WallController = ({ groupProp }) => {
  const {
    PostCommentStore: { reset }
  } = useStores();
  const activeGroup = getActiveGroup();
  const [members, setMembers] = useState([]);
  const [membersLoaded, setMembersLoaded] = useState(false);
  const [neighborMembers, setNeighborMembers] = useState([]);
  const [neighborMembersLoaded, setNeighborMembersLoaded] = useState(false);
  const [pinnedPost, setPinnedPostState] = useState({});
  const [loading, setLoading] = useState(true);
  const [postsList, setPostsList] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [editedPost, setEditedPost] = useState(null);
  const [realtimePost, setRealtimePost] = useState(null);

  const usersToSend = [getUserId()];

  useEffect(() => {
    if (!realtimePost) return;

    setPostsList([realtimePost, ...postsList]);
    setRealtimePost(null);
  }, [realtimePost]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const group = getActiveGroup();

  const resetView = postFull => {
    if (isUndefined(postFull) || isEmpty(postFull) || !postFull.post.isPinned) {
      setPinnedPostState({});
    } else {
      setPinnedPostState(postFull);
    }
  };

  const setPinnedPost = postFull => {
    let postId = 0;

    if (!isUndefined(postFull) && !isEmpty(postFull)) {
      postId = postFull.post.id;
    }

    if (!isUndefined(postFull) && !isEmpty(postFull)) {
      fetchPost(postId).then(res => {
        resetView(res);
      });
    } else {
      resetView(postFull);
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchPosts({
      groupId: group.get("id"),
      limit: POST_LIMIT
    })
      .then(({ results, hasNext }) => {
        // check if there's more items to fetch in next calls
        if (results.length > POST_LIMIT - 1) {
          results.pop();
          setHasNext(true);
        } else {
          setHasNext(false);
        }
        setPostsList(results);

        postListDependentEvents(results, setRealtimePost);
        const pinnedPostFetched = results.find(
          postFull => postFull.post.isPinned
        );
        setPinnedPost(pinnedPostFetched);
        setLoading(false);

        if (group.shouldShowRanking()) {
          postsList.map(postFull => {
            const userP = postFull.post.user;
            if (indexOf(usersToSend, userP.id) < 0) {
              usersToSend.push(userP.id);
            }
            return postFull;
          });
          const urlPoint = createURL("api_groups_users_points", {
            group_id: group.get("id")
          });
          const paramsPoint = $.param({ user_ids: usersToSend });
          $.ajax({
            type: "GET",
            dataType: "json",
            url: `${urlPoint}?${paramsPoint}`,
            success(ret) {
              group.setUsersPoints(ret);
              InternalDispatcher.trigger("stopLoading");
            },
            error() {
              InternalDispatcher.trigger("stopLoading");
            }
          });
        } else {
          InternalDispatcher.trigger("stopLoading");
        }
      })
      .catch(e => {
        InternalDispatcher.trigger("stopLoading");
        console.error({ error: "No wall" });
        setLoading(false);
        /**
         * Redirect to group list unless error is 401 (waiting for refresh token to trigger call again)
         */
        if (e.status !== 401) {
          setPostsList([]);
          navigateTo(createUrl("home"));
        }
      });

    const membersCallback = () => {
      setMembersLoaded(true);
      setMembers(group.getRanking() || []);

      const neighborsCallback = () => {
        setNeighborMembers(group.getUserRankWindow() || []);
        setNeighborMembersLoaded(true);
      };
      group.fetchUserRankWindow(neighborsCallback);
    };

    group.fetchRank(membersCallback);
  }, []);

  const fetchNextPage = () => {
    if (!hasNext) return;

    fetchPosts({
      groupId: group.get("id"),
      limit: POST_LIMIT,
      offset: postsList.length
    })
      .then(({ results, hasNext }) => {
        if (results.length > POST_LIMIT - 1) {
          results.pop();
          setHasNext(true);
        } else {
          setHasNext(false);
        }
        setPostsList([...postsList, ...results]);
        if (group.shouldShowRanking()) {
          const userArray = [];
          postsList.map(postFull => {
            const userP = postFull.post.user;
            if (indexOf(usersToSend, userP.id) < 0) {
              userArray.push(userP.id);
            }
          });
          const urlPoint = createURL("api_groups_users_points", {
            group_id: group.get("id")
          });
          const paramsPoint = $.param({
            user_ids: usersToSend.concat(userArray)
          });
          if (typeof setUsertoSent === "function") {
            if (isArray(userArray) && userArray.length > 0) {
              usersToSend.concat(userArray);
            }
          }
          $.ajax({
            type: "GET",
            dataType: "json",
            url: `${urlPoint}?${paramsPoint}`,
            success(ret) {
              group.setUsersPoints(ret);
            },
            error() {
              // console.log('error no poit', ret);
            }
          });
        }
      })
      .catch(() => {
        setHasNext(false);
      });
  };

  useEffect(() => {
    InternalDispatcher.trigger("startLoading");
    basePageModel.set("pageTitle", group.get("name"));
    basePageModel.set("selectedTab", "bacheca");

    InternalDispatcher.on("new_wall_post", obj => {
      setEditedPost(obj.attributes);
    });
    InternalDispatcher.on("post_resource_update", postId => {
      fetchPost(postId).then(post => {
        const updatedList = [...postsList];

        const postIndex = postsList.findIndex(
          postFull => postFull.post.id === postId
        );
        if (postIndex > 0) {
          updatedList[postIndex] = post;
        } else {
          updatedList.unshift(post);
        }
        setPostsList(updatedList);
      });
    });

    return () => {
      postListOffDependentEvents();
      InternalDispatcher.off("post_resource_update");
    };
  }, []);

  useEffect(() => {
    if (!editedPost) return;
    const updatedList = [...postsList];

    updatedList[
      postsList.findIndex(postFull => postFull.post.id === editedPost.id)
    ] = editedPost;

    setEditedPost(null);
    setPostsList(updatedList);
  }, [editedPost]);

  return !loading ? (
    <Switch>
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.wall}
        render={props => (
          <Wall
            group={groupProp}
            postsList={postsList}
            onDeletePost={postId => {
              const updatedList = postsList.filter(
                postFull => postFull.post.id !== postId
              );
              setPostsList(updatedList);
            }}
            appendNewPost={postFull => {
              setPostsList([postFull, ...postsList]);
            }}
            hasNext={hasNext}
            fetchNextPage={fetchNextPage}
            pinnedPost={pinnedPost}
            onSetPinnedPost={setPinnedPost}
            members={members}
            membersLoaded={membersLoaded}
            neighborMembers={neighborMembers}
            neighborMembersLoaded={neighborMembersLoaded}
            usersToSend={usersToSend}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.wallNew}
        render={props => (
          <NewPost
            target="new"
            postsList={postsList}
            group={group}
            pinnedPost={pinnedPost}
            onSetPinnedPost={setPinnedPost}
            appendNewPost={postFull => setPostsList([postFull, ...postsList])}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.edit}
        render={props => (
          <NewPost
            postsList={postsList}
            target="edit"
            group={group}
            pinnedPost={pinnedPost}
            onSetPinnedPost={setPinnedPost}
            // Unfortunate name, newPost should not handle also the edit
            appendNewPost={postFull => {
              const updatedList = [...postsList];
              const postIndex = updatedList.findIndex(
                p => p.post.id === postFull.post.id
              );

              if (postIndex < 0) return;

              updatedList[postIndex] = postFull;
              setPostsList(updatedList);
            }}
            {...props}
          />
        )}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.post}
        render={props => (
          <SinglePostController postsList={postsList} {...props} />
        )}
      />
    </Switch>
  ) : (
    <div />
  );
};

export default WallController;
