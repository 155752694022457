import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import SingleGroupController from "./SingleGroupController";

class GroupController extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.groupId}
          component={SingleGroupController}
        />
        <Route render={() => <Redirect to={createUrl("home")} />} />
      </Switch>
    );
  }
}

export default GroupController;
