import { Box } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { ReactNode, useMemo } from "react";
import { UnloggedLanguageSelector } from "src/js/components/unlogged/UnloggedLanguageSelector";
import { useStores } from "src/js/hooks";
import WeSchoolMonogramSVG from "src/img/icons/svg/weschool_monogram.svg";
import {
  __UNLOGGED_BG_DESKTOP__,
  __UNLOGGED_BG_MOBILE__
} from "src/js/settings/settingsImage";

import * as S from "./Background.styles";

const Background = ({
  children,
  mobileHasImage = false
}: {
  children: ReactNode;
  mobileHasImage?: boolean;
}) => {
  const {
    UIStore: { isLayoutModeMobile },
    ThemeStore: { customizationSettings, hasCustomization }
  } = useStores();

  const shouldShowMobileBackground = useMemo(
    () => isLayoutModeMobile && mobileHasImage && !hasCustomization,
    [isLayoutModeMobile, mobileHasImage, hasCustomization]
  );

  const shouldShowDesktopBackground = useMemo(
    () => !isLayoutModeMobile && !hasCustomization,
    [isLayoutModeMobile, hasCustomization]
  );

  const weschoolMonogram = hasCustomization ? (
    <S.MonogramContainer isLayoutMobile={isLayoutModeMobile}>
      <WeSchoolMonogramSVG width="36px" height="26px" viewBox="0 0 26 19" />
    </S.MonogramContainer>
  ) : null;

  return (
    <S.Wrapper
      isLayoutMobile={isLayoutModeMobile}
      hasNoCustomizedLogin={!hasCustomization}
      backgroundColor={customizationSettings?.layoutColor}
    >
      {shouldShowDesktopBackground ? (
        <S.BackgroundImage src={__UNLOGGED_BG_DESKTOP__} alt="" />
      ) : undefined}
      {shouldShowMobileBackground ? (
        <S.BackgroundImage src={__UNLOGGED_BG_MOBILE__} alt="" />
      ) : undefined}

      {!isLayoutModeMobile ? (
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          paddingLeft={32}
          paddingRight={32}
        >
          <S.TopContainer hasCustomizedLogin={hasCustomization}>
            {weschoolMonogram}
            <UnloggedLanguageSelector />
          </S.TopContainer>
          <S.MiddleContainer>{children}</S.MiddleContainer>
          <S.BottomContainer />
        </Box>
      ) : (
        <Box position="relative" zIndex={1}>
          <S.TopMobileContainer hasCustomizedLogin={hasCustomization}>
            {weschoolMonogram}
            <UnloggedLanguageSelector />
          </S.TopMobileContainer>
          {children}
        </Box>
      )}
    </S.Wrapper>
  );
};

export default observer(Background);
