import { DefaultTheme } from "styled-components";
import colors from "@ds_themes/colors";
import {
  primary as ButtonPrimary,
  secondary as ButtonSecondary,
  tertiary as ButtonTertiary,
  danger as ButtonDanger
} from "@ds_themes/white/button";
import {
  primary as ActionButtonPrimary,
  secondary as ActionButtonSecondary,
  secondaryColored as ActionButtonSecondaryColored,
  tertiary as ActionButtonTertiary
} from "@ds_themes/white/actionButton";
import { link as Link } from "@ds_themes/white/link";
import { ctaTertiary as CtaTertiary } from "@ds_themes/white/ctaTertiary";
import { checkbox as Checkbox } from "@ds_themes/white/checkbox";
import { chip as Chip } from "@ds_themes/white/chip";
import { radiogroup as RadioGroup } from "@ds_themes/white/radiogroup";
import { toggle as Toggle } from "@ds_themes/white/toggle";
import { avatar as Avatar } from "@ds_themes/white/avatar";
import { wrapperchatavatar as WrapperChatAvatar } from "@ds_themes/white/wrapperchatavatar";
import {
  self as SelfChatBubble,
  other as OtherChatBubble
} from "@ds_themes/white/chatbubble";
import { liveduration as LiveDuration } from "@ds_themes/white/liveduration";
import { livebutton as LiveButton } from "@ds_themes/white/livebutton";
import { livetag as LiveTag } from "@ds_themes/white/livetag";
import { container as Container } from "@ds_themes/white/groupmember";
import { label as Label } from "@ds_themes/white/label";
import { eventcard as EventCard } from "@ds_themes/white/eventcard";
import { general as General, activity as Activity } from "@ds_themes/white/tag";
import { livepill as Livepill } from "@ds_themes/white/livepill";
import {
  cardgroup as CardGroup,
  cardcontent as CardContent,
  cardaddcontent as CardAddContent,
  cardlesson as CardLesson,
  cardschool as CardSchool,
  cardactivity as CardActivity
} from "@ds_themes/white/card";
import { input as Input } from "@ds_themes/white/input";
import {
  basic as Basic,
  comment as Comment,
  badgeScore as BadgeScore
} from "@ds_themes/white/post";
import { tooltip as Tooltip } from "@ds_themes/white/tooltip";
import { dropdown as Dropdown } from "@ds_themes/white/dropdown";
import { divider as Divider } from "@ds_themes/white/divider";
import { doodleloader as DoodleLoader } from "@ds_themes/white/doodleloader";
import { contentswitcher as ContentSwitcher } from "@ds_themes/white/contentswitcher";
import { progressbar as ProgressBar } from "@ds_themes/white/progressbar";
import { pagenav as PageNav } from "@ds_themes/white/pagenav";
import { promobanner as PromoBanner } from "@ds_themes/white/promobanner";
import { searchbarWithSuggestion as SearchbarWithSuggestion } from "@ds_themes/white/searchbarWithSuggestion";
import { socialLoginButton as SocialLoginButton } from "@ds_themes/white/socialLoginButton";
import { resourceprogressbar as ResourceProgressBar } from "@ds_themes/white/resourceprogressbar";
import { checkindicator as CheckIndicator } from "@ds_themes/white/checkindicator";
import { genericLoader as Loader } from "@ds_themes/white/genericLoader";

import { actionitemsdropdown as ActionItemsDropdown } from "./actionitemsdropdown";

const whiteTheme = {
  background: colors.grey[1000],
  checkindicator: CheckIndicator,
  text: colors.grey[50],
  button: {
    primary: ButtonPrimary,
    secondary: ButtonSecondary,
    tertiary: ButtonTertiary,
    danger: ButtonDanger
  },
  actionButton: {
    primary: ActionButtonPrimary,
    secondary: ActionButtonSecondary,
    secondaryColored: ActionButtonSecondaryColored,
    tertiary: ActionButtonTertiary
  },
  post: {
    basic: Basic,
    comment: Comment,
    badgeScore: BadgeScore
  },
  input: Input,
  dropdown: Dropdown,
  divider: Divider,
  link: Link,
  genericLoader: Loader,
  ctaTertiary: CtaTertiary,
  checkbox: Checkbox,
  chip: Chip,
  radiogroup: RadioGroup,
  toggle: Toggle,
  avatar: Avatar,
  chatbubble: {
    self: SelfChatBubble,
    other: OtherChatBubble
  },
  liveduration: LiveDuration,
  livebutton: LiveButton,
  livetag: LiveTag,
  actionitemsdropdown: ActionItemsDropdown,
  groupmember: {
    container: Container
  },
  label: Label,
  eventcard: EventCard,
  tag: {
    general: General,
    activity: Activity
  },
  livepill: Livepill,
  wrapperchatavatar: WrapperChatAvatar,
  card: {
    cardgroup: CardGroup,
    cardcontent: CardContent,
    cardaddcontent: CardAddContent,
    cardlesson: CardLesson,
    cardschool: CardSchool,
    cardactivity: CardActivity
  },
  contentswitcher: ContentSwitcher,
  doodleloader: DoodleLoader,
  socialLoginButton: SocialLoginButton,
  progressbar: ProgressBar,
  promobanner: PromoBanner,
  pagenav: PageNav,
  tooltip: Tooltip,
  resourceprogressbar: ResourceProgressBar,
  searchbarWithSuggestion: SearchbarWithSuggestion
  // TODO: fix typings
} as unknown as DefaultTheme;

export default whiteTheme;
