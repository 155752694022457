import React, { useContext, useState } from "react";
import { parseEmojis } from "@weschooleng/emoji-parser";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { WSAnalyticsInstance } from "src/js/tracking/WSAnalyticsInstance";
import { POST_CREATED_TOPIC_NAME } from "src/js/tracking/Topics.const";
import useStores from "src/js/hooks/useStores";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  Button,
  ConfirmationAlert,
  ContextualError,
  Avatar,
  Box
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import {
  createPost,
  updatePostResources
} from "src/js/repository/postRepository";
import { RESOURCE_PICKER_SECTION } from "src/js/components/ResourcePicker/ResourcePicker.const";
import { useResourcePayload } from "src/js/hooks/resources";
import { sanitizeText } from "src/js/modules/textFunction";
import { SocialInput } from "src/js/components/SocialInput";
import { ResourcePicker } from "src/js/components/ResourcePicker";
import { AddTextResourceWithAI } from "src/js/components/ResourcePicker/components/AddTextResourceWithAI";
import { observer } from "mobx-react";
import { getActiveGroup, getActiveGroupId } from "../../../modules/activeGroup";
import { getActiveUser } from "../../../modules/activeUser";
import AttachedResourceList from "./postNewResourcesList";

import * as S from "./NewPost.styles";

// TODO This and NewPost.jsx files could be written as one

const NewQuickPost = ({ appendNewPost }) => {
  const [text, setText] = useState("");
  const [resources, setResources] = useState([]);
  const [shouldSubmitResources, setSubmitResources] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // error: TranslationKeys
  const [error, setError] = useState(null);
  const { whiteTheme } = useTheme();
  const {
    ConfirmDialogStore,
    GroupStore: { activeGroup }
  } = useStores();
  const [submitting, setSubmitting] = useState(false);
  const { translate } = useTranslation();

  const onErrorCallback = errorData => {
    showToastError({
      str: errorData?.data?.message
    });
  };

  const addResource = resource => {
    setSubmitResources(true);
    setResources(res => [resource, ...res]);
  };

  // Used to upload directly FILES to s3
  const { prepareResourcePayload } = useResourcePayload({
    onSuccessCallback: addResource,
    onLoadingCallback: () => {},
    onErrorCallback
  });

  const reset = () => {
    setText("");
    setResources([]);
    setSubmitting(false);
  };

  const save = e => {
    e.preventDefault();
    setSubmitting(true);
    if (!activeGroup?.id) return;

    const sanitizedText = sanitizeText({ text });
    if (sanitizedText.trim() || !!resources.length) {
      const resourcesLength = resources.length;

      createPost({ groupId: activeGroup.id, text: parseEmojis(sanitizedText) })
        .then(post => {
          if (shouldSubmitResources) {
            const mappedResources = resources.map(resource => ({
              id: resource?.id,
              type: resource?.type
            }));
            updatePostResources({
              postId: post.id,
              resources: mappedResources
            }).then(postFull => {
              appendNewPost(postFull);
            });
          } else {
            appendNewPost({ post, resources, commentsCount: 0 });
          }

          WSAnalyticsInstance.track(POST_CREATED_TOPIC_NAME, {
            groupId: getActiveGroupId(),
            postId: post.id
          });
          _trackEvent("Wall", "WallNew", "", resourcesLength);
          reset();
        })
        .catch(error => {
          const errorMsg = extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          );
          setSubmitting(false);
          showToastError({ str: translate(errorMsg) });
        });
    } else {
      setSubmitting(false);
      setError("Attenzione, verificare i dati inseriti");
    }
  };

  const removeResource = resource => {
    ConfirmDialogStore.openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("La risorsa sarà eliminata")}
        declineText={translate("Annulla")}
        onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          setResources(resources.filter(res => res.id !== resource.id));
          ConfirmDialogStore.closeConfirmDialog();
        }}
      />
    );
  };
  const userName = getActiveUser().getFullName();

  const errorMessage = error ? (
    <ContextualError theme={whiteTheme} text={translate(error)} />
  ) : null;

  const resourcePickerEl = (
    <ResourcePicker
      section={RESOURCE_PICKER_SECTION.WALL}
      onSubmitCallbackSuccess={(_, successData) => {
        addResource(successData);
      }}
      // TODO add a loading state?
      setUploadingProgress={() => {}}
      onSubmitCallbackError={onErrorCallback}
      prepareModuleResourcePayload={prepareResourcePayload}
      elementIndex={0}
      embedded
      openNewResourceCustomWizard={() => {
        setIsOpen(true);
      }}
    />
  );

  return (
    <>
      <S.FormWrapper id="form-post-quick" onSubmit={save}>
        <Box display="flex" flexDirection="column" gap={8}>
          <S.InputWrapper>
            <Avatar
              url={getActiveUser().getAvatar("medium")}
              gender={getActiveUser().getGenderString()}
              alt={userName}
              size="m"
              theme={whiteTheme}
              rounded
            />
            <SocialInput
              renderBottom
              autoFocus
              value={text}
              placeholder={translate("new_post_input_placeholder")}
              onChange={event => {
                // remove the error only if the input have an accepted value
                if (event.target.value.trim()) {
                  setError(null);
                }
                setText(event.target.value);
              }}
            />
          </S.InputWrapper>
          {errorMessage}
        </Box>
        {resources.length ? (
          <AttachedResourceList
            resources={resources}
            removeFunc={removeResource}
          />
        ) : null}
        <S.ButtonWrapper>
          <S.CustomDotsMenu
            id="NewQuickPostDotsMenu"
            customIcon="attachment"
            customMenu={resourcePickerEl}
            listContainerId="new-post-quick-attachments-list"
          />

          <S.GlobalStyleAttachmentPostQuick />
          <Button
            type="submit"
            variant="primary"
            theme={whiteTheme}
            disabled={submitting}
            withIcon={{
              icon: "paperPlane",
              positionIcon: "left",
              heightIcon: "16",
              widthIcon: "16",
              viewbox: "0 0 24 24"
            }}
          >
            {translate("PUBBLICA")}
          </Button>
        </S.ButtonWrapper>
      </S.FormWrapper>

      {isOpen ? (
        <AddTextResourceWithAI
          onSubmit={successData => {
            const resourceIndex = resources.findIndex(
              resource => resource.id === successData.id
            );
            if (resourceIndex === -1) {
              addResource(successData);
              return;
            }
            const resourcesAux = [...resources];
            resourcesAux[resourceIndex] = successData;
            setResources(resourcesAux);
          }}
          editMode={false}
          context="post"
          onClose={() => {
            setIsOpen(false);
          }}
          setUploadingProgress={() => {}}
          elementId={0}
        />
      ) : null}
    </>
  );
};

export default observer(NewQuickPost);
