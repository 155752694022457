import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import "jquery-circle-progress";
import each from "lodash/each";
import { Text } from "@arcadia/design-system";
import { withTheme } from "styled-components";

/**
 * CircleProgress props:
 * - partial, current value
 * - total, total value
 * - caption, label underneath
 * - options, jquery plugin configuration override
 * - type, integer or decimal (default integer)
 * Examples:
 * <CircleProgress partial="28" total="30" caption="USERS" />
 * <CircleProgress partial="6.5" total="10" type="decimal" caption="AVERAGE RATE" />
 */
const CircleProgress = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          type: "integer",
          background: "transparent",
          defaultText: null,
          caption: "",
          partial: 0,
          total: 0
        };
      },
      getInitialState() {
        return {
          options: {
            value: 0.5,
            size: 65,
            startAngle: -Math.PI / 2,
            emptyFill: "rgba(0,0,0,0)",
            fill: {
              gradient: [
                this.props.theme.primaryColor[500],
                this.props.theme.primaryColor[500]
              ]
            }
          }
        };
      },
      componentDidMount() {
        const self = this;
        const { options } = self.state;
        const el = ReactDOM.findDOMNode(this.refs.circleProgress);

        if (
          self.props.partial !== undefined &&
          self.props.total !== undefined
        ) {
          options.value = self.props.partial / self.props.total;
          self.setState({ options });
        }
        if (self.props.options != undefined) {
          each(self.props.options, (value, key) => {
            options[key] = value;
          });
          self.setState({ options });
        }
        $(el)
          .find(".circle-progress-bar")
          .circleProgress(self.state.options)
          .on("circle-animation-progress", function (event, progress) {
            if (self.props.defaultText === null) {
              let count = Math.floor(progress * self.props.partial);
              if (self.props.type === "decimal") {
                const myFloat = parseFloat(progress * self.props.partial);
                if (myFloat % 1 === 0) {
                  count = myFloat;
                } else {
                  count = parseFloat(progress * self.props.partial).toFixed(1);
                }
              }
              $(this).find(".circle-progress-count").html(count);
            }
          });
      },
      render() {
        let caption = "";
        if (this.props.caption !== undefined) {
          caption = this.props.caption;
        }
        return (
          <div className="circle-progress text-center" ref="circleProgress">
            <div
              className="circle-progress-bar"
              style={{ background: this.props.background }}
            >
              <div className="circle-progress-count">
                <Text type="subHeaderTitle">{this.props.defaultText}</Text>
              </div>
            </div>
            <div>
              <Text type="formSubtitle">{caption}</Text>
            </div>
          </div>
        );
      }
    })
  )
);

export default CircleProgress;
