import {
  Box,
  Button,
  ContextualError,
  Input,
  Modal,
  ModalSizeType,
  Text
} from "@arcadia/design-system";
import React from "react";
import { useForm } from "react-hook-form";
import { showToastError } from "src/js/modules/messageManager";
import { __FORM_DELETE_ACCOUNT__ } from "src/js/settings/settingsFormValidation";
import { __DELETE_ACCOUNT_ERASER__ } from "src/js/settings/settingsImage";
import {
  Translate,
  translateString
} from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

import * as S from "./UserAccountDeleteModal.styles";

const Warning = () => {
  const { whiteTheme } = useTheme();
  return (
    <S.ModalBannerContainer>
      <ContextualError
        theme={whiteTheme}
        text={<Translate text="delete_account_modal_warning_message" />}
      />
    </S.ModalBannerContainer>
  );
};

const UserAccountDeleteModal = ({
  closeModal = () => {},
  displayAsModal = true,
  isSSOAccount,
  userEmail,
  successCallback = ({ email, password }) => {}
}) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const { whiteTheme, greyTheme } = useTheme();
  const inputEmail = watch("email");

  const handleSubmission = handleSubmit(formData => {
    successCallback(formData).catch(() => {
      // TODO change with new toastError implementation
      showToastError({ str: "Error" });
    });
  });

  const body = () => (
    <S.ModalBodyContainer>
      <>
        {displayAsModal ? (
          <Box marginBottom={40}>
            <Warning />
          </Box>
        ) : null}
        {displayAsModal ? (
          <S.ModalImageContainer src={__DELETE_ACCOUNT_ERASER__} />
        ) : null}
        <Box>
          {displayAsModal ? (
            <Text type="formField">
              <Translate text="delete_account_modal_password_check" />
            </Text>
          ) : null}

          <S.InputWrapper>
            <Input
              theme={displayAsModal ? whiteTheme : greyTheme}
              label={translateString({ text: "delete_account_modal_mail" })}
              name="email"
              ref={register(__FORM_DELETE_ACCOUNT__.email)}
              rounded
              feedback={
                errors.email
                  ? {
                      type: "error",
                      message: translateString({ text: errors.email.message })
                    }
                  : null
              }
            />
          </S.InputWrapper>
          {isSSOAccount ? null : (
            <Input
              theme={displayAsModal ? whiteTheme : greyTheme}
              label={translateString({ text: "delete_account_modal_password" })}
              name="password"
              isPassword
              ref={register(__FORM_DELETE_ACCOUNT__.password)}
              rounded
              feedback={
                errors.password
                  ? {
                      type: "error",
                      message: translateString({
                        text: errors.password.message
                      })
                    }
                  : null
              }
            />
          )}
        </Box>
        {displayAsModal ? null : (
          <Box marginTop={20}>
            <Warning />
          </Box>
        )}
      </>
      <S.ModalFooterContainer>
        {displayAsModal && (
          <Button onClick={closeModal} theme={whiteTheme} variant="secondary">
            <Translate text="delete_account_modal_cancel" />
          </Button>
        )}

        <Button
          type="submit"
          theme={whiteTheme}
          variant="danger"
          disabled={isSSOAccount ? inputEmail !== userEmail : false}
        >
          <Translate text="delete_account_modal_confirm" />
        </Button>
      </S.ModalFooterContainer>
    </S.ModalBodyContainer>
  );

  return (
    <form onSubmit={handleSubmission}>
      {displayAsModal ? (
        <Modal
          title={translateString({ text: "delete_account_modal_title" })}
          onClose={closeModal}
          scrollable
          size={ModalSizeType.Normal}
        >
          {body()}
        </Modal>
      ) : (
        body()
      )}
    </form>
  );
};

export default UserAccountDeleteModal;
