import TagManager from "react-gtm-module";
import {
  ACTIVITY_ATTACHMENT_VISUALIZED_TOPIC_NAME,
  LIVE_OPENED_TOPIC_NAME
} from "src/js/tracking/Topics.const";
import { WSAnalyticsInstance } from "src/js/tracking/WSAnalyticsInstance";
import {
  removeUndefinedKeys,
  getUserInfo
} from "src/js/context/ViewTrackingProvider/ViewTrackingProvider.utils";
import { fetchLocationData } from "src/js/modules/localStorage";
import { readLocalData } from "./storageFunction";
import { EventDomain, UserEvent } from "../types";

// add tracking event for spaceId

const getEventLocationData = async () => {
  try {
    const locationData = await fetchLocationData();
    const loggedUser = await getUserInfo();
    const { birthday, email } = loggedUser;
    const isWeSchoolMail = email?.endsWith("@weschool.com");

    return {
      isWeSchoolMail,
      birthday,
      ...locationData
    };
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const _trackEvent = async (category, action, label, value) => {
  // category is required
  // action is required
  // label = typeof label === 'undefined' ? '' : label; // default ""
  // value = typeof value === 'undefined' ? 0 : value; // default 0
  // ga('send', 'event', category, action, label, value);

  const shouldUseTracking = readLocalData("consent");
  const userId = readLocalData("userId");
  const locationData = await getEventLocationData();

  const isJoinGroupAction = action === "GroupJoin";

  // eslint-disable-next-line no-nested-ternary
  const dataLayerLabel = isJoinGroupAction
    ? locationData.groupId
    : typeof label === "undefined"
      ? ""
      : label;

  const dataLayer = removeUndefinedKeys({
    consent: shouldUseTracking,
    event: "asyncAction",
    dimension1: String(locationData.groupId),
    dimension2: userId,
    dimension5: String(locationData.isTeacher),
    dimension7: "Client",
    dimension8: String(locationData.spaceId),
    dimension10: String(locationData.platformLanguage),
    dimension11: String(locationData.pathDescription),
    dimension12: String(locationData.spaceSlug),
    dimension13: String(locationData.trial),
    dimension14: String(locationData.birthday),
    dimension15: String(locationData.isDemo),
    dimension16: String(locationData.isWeSchoolMail),
    category,
    action,
    label: dataLayerLabel,
    value: typeof value === "undefined" ? 0 : value
  });

  TagManager.dataLayer({ dataLayer });
};

export const _trackNewUserEvent = async ({
  category,
  action,
  label,
  value,
  groupId,
  consent
}) => {
  const userId = readLocalData("userId");
  const locationData = await getEventLocationData();

  const dataLayer = removeUndefinedKeys({
    consent,
    event: "asyncAction",
    dimension1: groupId,
    dimension2: userId,
    dimension5: String(locationData.isTeacher),
    dimension7: "Client",
    dimension10: String(locationData.platformLanguage),
    dimension11: String(locationData.pathDescription),
    dimension12: String(locationData.spaceSlug),
    dimension13: String(locationData.trial),
    dimension14: String(locationData.birthday),
    dimension15: String(locationData.isDemo),
    dimension16: String(locationData.isWeSchoolMail),
    category,
    action,
    label: typeof label === "undefined" ? "" : label,
    value: typeof value === "undefined" ? 0 : value
  });

  TagManager.dataLayer({ dataLayer });
};

export const _trackNewUserJoinEvent = async ({ groupId, consent }) => {
  const userId = readLocalData("userId");
  const locationData = await getEventLocationData();

  const dataLayer = removeUndefinedKeys({
    consent,
    event: "asyncAction",
    dimension1: groupId,
    dimension2: userId,
    dimension5: String(locationData.isTeacher),
    dimension6: 1, // this dimension defines a join event with registration
    dimension7: "Client",
    dimension10: String(locationData.platformLanguage),
    dimension11: String(locationData.pathDescription),
    dimension12: String(locationData.spaceSlug),
    dimension13: String(locationData.trial),
    dimension14: String(locationData.birthday),
    dimension15: String(locationData.isDemo),
    dimension16: String(locationData.isWeSchoolMail),
    category: "Group",
    action: "GroupJoin",
    label: groupId,
    value: 0
  });

  TagManager.dataLayer({ dataLayer });
};

export const trackLiveConference = ({ groupId, liveRoomId }) => {
  _trackEvent(EventDomain.User, UserEvent.AccessToTheLive, liveRoomId);
  WSAnalyticsInstance.track(LIVE_OPENED_TOPIC_NAME, {
    groupId,
    liveRoomId
  });
};

export const trackActivityAttachmentVisualized = ({
  groupId,
  activityId,
  attachmentId,
  attachmentType
}) => {
  WSAnalyticsInstance.track(ACTIVITY_ATTACHMENT_VISUALIZED_TOPIC_NAME, {
    groupId,
    activityId,
    attachmentId,
    attachmentType
  });
};
