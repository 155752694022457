import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  Icon,
  PageNav
} from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { DotsMenu } from "src/js/components/DotsMenu";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { useCreateQuickThread } from "src/js/pages/App/Spaces/hooks";
import { __GROUP_MEMBERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { ChatEvent, EventDomain } from "src/js/types";
import { GroupInviteMemberModal } from "../GroupInviteMemberModal";
import * as S from "./GroupSettingsMembers.styles";
import { Role } from "./GroupSettingsMembers.types";
import { demoNoInvitationWarningBanner } from "./translations";
import { useGroupMembers } from "./useGroupMembers";

const GroupSettingsMembers = () => {
  const {
    GroupStore: { activeGroup: group, fetchPublicInvitation },
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal },
    SpaceStore: { canCreateChats, isDemoSpace },
    UserInviteStore: { reset },
    UserStore: { activeUser }
  } = useStores();
  const { navigateToThread } = useCreateQuickThread();
  const {
    isCreator,
    isTeacher,
    members,
    invitations,
    getInvitations,
    getMembers,
    getDropdownOptions,
    hasRole,
    totalMembers,
    showResendInviteModal,
    getGroupMembersNonAdmin,
    membersNonAdmin,
    totalMembersNonAdmin,
    isStudent,
    showCancelInviteModal
  } = useGroupMembers();
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const [page, setPage] = useState(0);

  const hasMembers = useMemo(() => members && members.length, [members]);
  const hasInvitations = useMemo(
    () => invitations && invitations.length,
    [invitations]
  );

  const showInviteModal = () => {
    reset();
    openModal(() => <GroupInviteMemberModal />);
  };

  const goToProfile = (uuid: string) => {
    navigateTo(
      createUrl("profile_public", {
        user_uuid: uuid
      })
    );
  };

  useEffect(() => {
    if (!group) return;
    if (isStudent) {
      getGroupMembersNonAdmin(0);
    } else {
      getMembers(0);
      getInvitations();
    }
  }, [isStudent, group]);

  useEffect(() => {
    fetchPublicInvitation(group.id);
  }, [group.id]);

  const invitationsList = hasInvitations ? (
    <Box>
      <Divider theme={whiteTheme} color="grey" />
      <Box marginBottom={24} />
      <Heading level="5">{translate("group_settings_page_pending")}</Heading>
      <Box marginBottom={24} />
      <S.TableWrapper>
        <Box overflowX={isLayoutModeMobile ? "scroll" : "unset"}>
          <S.Table>
            <S.TableHead>
              <S.TableRow>
                <S.TableHeadItem>
                  {translate("group_settings_page_account_label")}
                </S.TableHeadItem>
                <S.TableHeadItem>{translate("email")}</S.TableHeadItem>
                <S.TableHeadItem>
                  {translate("group_settings_page_date_label")}
                </S.TableHeadItem>
                <S.TableHeadItem>
                  {translate("group_settings_page_status_label")}
                </S.TableHeadItem>
                <S.TableHeadItem />
              </S.TableRow>
            </S.TableHead>
            <S.TableBody>
              {invitations.map(invitation => (
                <S.TableRow key={invitation.id}>
                  <S.TableBodyItem>
                    <S.RoleLabelWrapper>
                      <S.RoleLabel type="label">
                        {invitation.invite_role === Role.Teacher
                          ? translate("teacher")
                          : translate("student")}
                      </S.RoleLabel>
                    </S.RoleLabelWrapper>
                  </S.TableBodyItem>
                  <S.TableBodyItem>
                    <S.TableText type="table">{invitation.email}</S.TableText>
                  </S.TableBodyItem>
                  <S.TableBodyItem>
                    <S.TableText type="table">
                      {format(invitation.created * 1000, "dd/MM/yyyy")}
                    </S.TableText>
                  </S.TableBodyItem>
                  <S.TableBodyItem>
                    <S.TableText type="table">
                      {translate("group_settings_page_confirming_email")}
                    </S.TableText>
                  </S.TableBodyItem>
                  <S.TableBodyItem width={32}>
                    <DotsMenu
                      id="settingMembersInviteDotsMenu"
                      customIcon="paperPlane"
                      items={[
                        {
                          id: "cancel-invite",
                          icon: "trash",
                          label: translate("cancel_invite"),
                          onClick: () => showCancelInviteModal(invitation.id)
                        },
                        {
                          id: "resend-invite",
                          icon: "paperPlane",
                          label: translate("resend_invite"),
                          onClick: () =>
                            showResendInviteModal(
                              invitation.id,
                              invitation.email,
                              invitation.invite_role
                            )
                        }
                      ]}
                      alignRight
                    />
                  </S.TableBodyItem>
                </S.TableRow>
              ))}
            </S.TableBody>
          </S.Table>
        </Box>
      </S.TableWrapper>
    </Box>
  ) : null;

  if (group && !isStudent && hasMembers) {
    return (
      <>
        {isDemoSpace ? (
          <WarningBanner
            text={demoNoInvitationWarningBanner.text}
            cta={demoNoInvitationWarningBanner.cta}
            margin="0 0 30px 0"
            maxWidth="100%"
          />
        ) : null}
        <Box marginBottom={24}>
          <Box
            marginBottom={24}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading level="5">
              {totalMembers !== 0
                ? `${translate(
                    "group_settings_page_members"
                  )} (${totalMembers})`
                : `${translate("group_settings_page_members")}`}
            </Heading>
            <Button
              id="groupOptionsMembersInviteButton"
              theme={whiteTheme}
              variant="primary"
              onClick={showInviteModal}
              withIcon={{
                positionIcon: "left",
                icon: "plus",
                widthIcon: 12,
                heightIcon: 12
              }}
            >
              {translate("group_settings_page_members_invite")}
            </Button>
          </Box>
          <S.TableWrapper>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadItem>
                    {translate("group_settings_page_members")}
                  </S.TableHeadItem>
                  {!isLayoutModeMobile ? (
                    <>
                      <S.TableHeadItem>{translate("email")}</S.TableHeadItem>
                      <S.TableHeadItem>
                        {translate("group_settings_page_table_signup")}
                      </S.TableHeadItem>
                      <S.TableHeadItem />
                    </>
                  ) : undefined}
                  <S.TableHeadItem />
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {members.map(member => (
                  <S.TableRow key={member.uuid}>
                    <S.TableBodyItem>
                      <S.TableBodyItemProfile
                        onClick={() => goToProfile(member.uuid)}
                      >
                        <Avatar
                          size="s"
                          theme={whiteTheme}
                          withTeacherRibbon={isTeacher || isCreator}
                          url={member.avatar?.small}
                        />
                        <Box marginLeft={8} marginRight="auto">
                          <S.TableText type="table">{`${member.name} ${member.surname}`}</S.TableText>
                        </Box>
                      </S.TableBodyItemProfile>
                    </S.TableBodyItem>
                    {!isLayoutModeMobile ? (
                      <>
                        <S.TableBodyItem>
                          <S.TableText type="table">{member.email}</S.TableText>
                        </S.TableBodyItem>
                        <S.TableBodyItem>
                          <S.TableText type="table">
                            {format(member.joined_at * 1000, "dd/MM/yyyy")}
                          </S.TableText>
                        </S.TableBodyItem>
                      </>
                    ) : undefined}
                    <S.TableBodyItem>
                      <S.AdminText type="table">
                        {hasRole(Role.Creator, member.roles)
                          ? translate("group_settings_page_creator")
                          : undefined}
                        {hasRole(Role.Teacher, member.roles) &&
                        !hasRole(Role.Creator, member.roles)
                          ? translate("teacher")
                          : undefined}
                      </S.AdminText>
                    </S.TableBodyItem>
                    <S.TableBodyItem width={32}>
                      {activeUser.uuid === member.uuid ? (
                        <S.IconButton onClick={() => goToProfile(member.uuid)}>
                          <Icon icon="chevronRight" width={16} height={16} />
                        </S.IconButton>
                      ) : (
                        <DotsMenu
                          id="settingMembersOptionsDotsMenu"
                          customIcon="kebabVertical"
                          items={getDropdownOptions(member, page)}
                          alignRight
                        />
                      )}
                    </S.TableBodyItem>
                  </S.TableRow>
                ))}
              </S.TableBody>
            </S.Table>
          </S.TableWrapper>
          <Box display="block" marginLeft="auto" width="max-content">
            <PageNav
              theme={greyTheme}
              onPageChanged={p => {
                setPage(
                  p * __GROUP_MEMBERS_LIST_LIMIT__ -
                    __GROUP_MEMBERS_LIST_LIMIT__
                );
                getMembers(
                  p * __GROUP_MEMBERS_LIST_LIMIT__ -
                    __GROUP_MEMBERS_LIST_LIMIT__
                );
              }}
              totalRecords={totalMembers}
              pageLimit={__GROUP_MEMBERS_LIST_LIMIT__}
              startingOffset={0}
              label={{ previous: translate("prev"), next: translate("next") }}
            />
          </Box>
        </Box>
        {invitationsList}
      </>
    );
  }

  return (
    <>
      <Box
        marginBottom={24}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading level="5">
          {totalMembersNonAdmin !== 0
            ? `${translate("group_settings_members")} (${totalMembersNonAdmin})`
            : `${translate("group_settings_members")}`}
        </Heading>
      </Box>
      <S.TableWrapper>
        <S.Table>
          <S.TableBody>
            {membersNonAdmin.map(member => (
              <S.TableRow key={member.uuid}>
                <S.TableBodyItem>
                  <S.TableBodyItemProfile
                    onClick={() => goToProfile(member.uuid)}
                  >
                    <Avatar
                      size="s"
                      theme={whiteTheme}
                      withTeacherRibbon={isTeacher || isCreator}
                      url={member.avatar?.small}
                    />
                    <Box marginLeft={8} marginRight="auto">
                      <S.TableText type="table">{`${member.name} ${member.surname}`}</S.TableText>
                    </Box>
                  </S.TableBodyItemProfile>
                </S.TableBodyItem>
                {canCreateChats && activeUser.uuid !== member.uuid ? (
                  <S.TableBodyItem width={32}>
                    <S.IconButton
                      onClick={() => {
                        _trackEvent(
                          EventDomain.Chat,
                          ChatEvent.ChatStartGroupMembersClick
                        );
                        navigateToThread(member.uuid);
                      }}
                    >
                      <Icon initialViewbox icon="chat" width={16} height={16} />
                    </S.IconButton>
                  </S.TableBodyItem>
                ) : null}
              </S.TableRow>
            ))}
          </S.TableBody>
        </S.Table>
      </S.TableWrapper>
      <Box display="block" marginLeft="auto" width="max-content">
        <PageNav
          theme={greyTheme}
          onPageChanged={newPage =>
            getGroupMembersNonAdmin(
              newPage * __GROUP_MEMBERS_LIST_LIMIT__ -
                __GROUP_MEMBERS_LIST_LIMIT__
            )
          }
          totalRecords={totalMembersNonAdmin}
          pageLimit={__GROUP_MEMBERS_LIST_LIMIT__}
          startingOffset={0}
          label={{ previous: translate("prev"), next: translate("next") }}
        />
      </Box>
    </>
  );
};

export default observer(GroupSettingsMembers);
