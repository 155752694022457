import styled, { css } from "styled-components";

export const ModalFooterContainer = styled.div`
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    `}
`;
