import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { getActiveGroupId } from "Legacy/modules/activeGroup";
import { createUrl } from "src/js/modules/routing";
import { useHistory } from "react-router-dom";
import { Button, Label, Heading } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const ExerciseDraftItem = ({ exercise }) => {
  let exerciseType = "esercizio";
  const quizCount = exercise.quizzes_count || 0;
  let quizCountLabel = "quiz_count_label";
  const history = useHistory();
  const { whiteTheme } = useTheme();

  const groupId = getActiveGroupId();

  if (exercise.type === "EXAMINATION") {
    exerciseType = "VERIFICA";
  }
  if (quizCount === 1) {
    quizCountLabel = "quiz_count_singular_label";
  }

  return (
    <div className="app__box exercise-draft">
      <div className="exercise-draft-info margin-bottom-xs-10">
        <div className="exercise-category">
          <Translate text={exerciseType} />
        </div>
        <div className="margin-bottom-10">
          <Heading level="4">{exercise.title}</Heading>
        </div>
        <Label theme={whiteTheme} color="primary" outline={false}>
          {quizCount} <Translate text={quizCountLabel} />
        </Label>
      </div>
      <div className="exercise-draft-action">
        <Button
          onClick={() =>
            history.push(
              createUrl("exercises_edit", {
                group_id: groupId,
                exercise_id: exercise.id
              })
            )
          }
          theme={whiteTheme}
          variant="secondary"
        >
          <Translate text="modifica" />
        </Button>
      </div>
    </div>
  );
};

export default ExerciseDraftItem;
