/* eslint-disable no-nested-ternary */
import { makeAutoObservable, reaction, toJS } from "mobx";
import { equalsArrayIgnoreOrder } from "src/js/modules/commonFunction";
import { fetchSpacesGroups } from "src/js/repository/dashboardRepository";
import { __SPACE_GROUPS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";

class SpacesGroupsFilterStore {
  $filters = {
    searchQuery: "",
    selectedStatus: [],
    selectedOrder: ["created_at_descending"]
  };

  $pagination = {
    limit: __SPACE_GROUPS_LIST_LIMIT__,
    offset: 0
  };

  $filteredGroups = [];

  $totalGroups = 0;

  $unfilteredTotalGroups = 0;

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
    this.dispose();
  }

  // actions
  setAllFilters = filterObject => {
    this.$filters = filterObject;
  };

  setFilter = (value, groupKey) => {
    this.$filters[groupKey] = value;
    this.setPaginationOffset(0);
  };

  resetFilter = groupKey => {
    this.$filters[groupKey] = [];
    this.setPaginationOffset(0);
  };

  removeKeyFromFilter = (key, groupKey) => {
    this.$filters[groupKey] = this.$filters[groupKey].filter(i => i !== key);
  };

  reset = () => {
    this.$filters = {
      searchQuery: "",
      selectedStatus: [],
      selectedOrder: ["created_at_descending"]
    };
    this.$pagination = {
      limit: __SPACE_GROUPS_LIST_LIMIT__,
      offset: 0
    };
  };

  setPaginationOffset = value => {
    this.$pagination.offset = value;
  };

  changePage = page => {
    const newOffset = Math.round((page - 1) * this.$pagination.limit);
    this.setPaginationOffset(newOffset);
  };

  setFiltersAndPaginationFromQueryParams = queryParamsObject => {
    const searchQuery = (queryParamsObject.name_filter || "").replace(
      /\+/g,
      " "
    );
    let selectedStatus = toJS(this.$filters.selectedStatus);
    if (queryParamsObject.status_filter) {
      selectedStatus = [queryParamsObject.status_filter];
    } else if (this.$filters.selectedStatus.length < 2) {
      selectedStatus = [];
    }
    const selectedOrder = [
      `${queryParamsObject.ordination_by_filter}_${queryParamsObject.ordination_direction_filter}`
    ];
    const newFilters = {
      searchQuery,
      selectedStatus,
      selectedOrder
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [keyFilter, valueFilter] of Object.entries(
      toJS(this.$filters)
    )) {
      const isArray = Array.isArray(this.$filters[keyFilter]);
      const hasSameValue = isArray
        ? equalsArrayIgnoreOrder(newFilters[keyFilter], valueFilter)
        : newFilters[keyFilter] === valueFilter;
      if (!hasSameValue) {
        this.$filters[keyFilter] = newFilters[keyFilter];
      }
    }

    const { limit, offset } = queryParamsObject;
    const newPagination = {
      limit: parseInt(limit, 10) || __SPACE_GROUPS_LIST_LIMIT__,
      offset: parseInt(offset, 10) || 0
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [keyPag, valuePag] of Object.entries(toJS(this.$pagination))) {
      const hasSameValue = newPagination[keyPag] === valuePag;
      if (!hasSameValue) {
        this.$pagination[keyPag] = newPagination[keyPag];
      }
    }
  };

  getOrderValues = selectedOrder => {
    const value =
      selectedOrder.length > 0 ? selectedOrder[0] : "created_at_descending";
    const ordination_by_filter =
      value === "name_descending" || value === "name_ascending"
        ? "name"
        : "created_at";
    const ordination_direction_filter =
      value === "name_descending" || value === "created_at_descending"
        ? "descending"
        : "ascending";
    return { ordination_by_filter, ordination_direction_filter };
  };

  setFilteredGroups = groups => {
    this.$filteredGroups = groups;
  };

  setTotalGroups = value => {
    this.$totalGroups = value;
  };

  setUnfilteredTotalGroups = value => {
    this.$unfilteredTotalGroups = value;
  };

  fetchGroups = () => {
    const {
      status_filter,
      ordination_by_filter,
      ordination_direction_filter,
      name_filter,
      limit,
      offset
    } = this.filterValuesForQueryParams;
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();
    return fetchSpacesGroups({
      spaceId,
      limit,
      offset,
      name_filter,
      status_filter,
      ordination_by_filter,
      ordination_direction_filter
    })
      .then(data => {
        this.setFilteredGroups(data?.results);
        this.setTotalGroups(data?.total);
        this.setUnfilteredTotalGroups(data?.unfilteredTotal);
        return data;
      })
      .catch(() => {
        this.setFilteredGroups([]);
        this.setTotalGroups(0);
        this.setUnfilteredTotalGroups(0);
        return {};
      });
  };

  // computed
  get filterValuesForQueryParams() {
    const { searchQuery, selectedStatus, selectedOrder } = this.$filters;
    const { ordination_by_filter, ordination_direction_filter } =
      this.getOrderValues(selectedOrder);
    const { limit, offset } = this.$pagination;
    return {
      status_filter: selectedStatus.length === 1 ? selectedStatus[0] : null,
      ordination_by_filter,
      ordination_direction_filter,
      name_filter: searchQuery,
      limit,
      offset
    };
  }

  get filters() {
    return this.$filters;
  }

  get filteredGroups() {
    return this.$filteredGroups;
  }

  get totalGroups() {
    return this.$totalGroups;
  }

  get unfilteredTotalGroups() {
    return this.$unfilteredTotalGroups;
  }

  get paginationOffset() {
    return this.$pagination.offset;
  }

  dispose = () => {
    reaction(
      // reacts on change of query params
      () => this.filterValuesForQueryParams,
      () => {
        this.fetchGroups();
      }
    );
  };
}

export default SpacesGroupsFilterStore;
