/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { ModulesNewResourceProps } from "src/js/components/modules/ModuleResourceNewItem/types";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { AddOrDragContent, AddOrPasteLink } from "./components";
import * as S from "./ModulesResourceNewItem.styles";

const ModulesResourceNewItem = ({
  id,
  openBoard,
  position,
  resourceElementIndex,
  prepareModuleResourcePayload,
  isLocked,
  hasMandatoryOrder
}: ModulesNewResourceProps) => {
  const [addContentBoxVisible, setVisible] = useState(false);
  const {
    BoardsStore: { errorUploadingElements },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  useEffect(() => {
    setVisible(false);
  }, [isLocked]);

  useEffect(() => {
    if (
      errorUploadingElements[resourceElementIndex] &&
      errorUploadingElements[resourceElementIndex].hasError
    ) {
      setVisible(true);
    }
  }, [errorUploadingElements]);

  return (
    <S.Container
      id={id}
      onClick={() => {
        if (isLocked) return;
        setVisible(true);
      }}
      className="js-board-detail-resource-draggable"
      data-movable-item={position}
      data-element-id={id}
      addContentBoxVisible={addContentBoxVisible}
      isLocked={isLocked}
    >
      {hasMandatoryOrder && isLayoutModeMobile && !addContentBoxVisible && (
        <S.ModuleNumberWrapper>
          <Text type="cta">{position + 1}</Text>
        </S.ModuleNumberWrapper>
      )}
      {isLocked ? (
        <S.LockWrapper>
          <S.IconLockWrapper>
            <Icon
              icon="lockClosed"
              height={isLayoutModeMobile ? 8 : 16}
              width={isLayoutModeMobile ? 8 : 16}
              initialViewbox
            />
          </S.IconLockWrapper>
          {isLayoutModeMobile ? (
            <AddOrDragContent openBoard={false} />
          ) : (
            <Text type="formDescription">
              {translate("new_board_element_item_locked_message")}
            </Text>
          )}
        </S.LockWrapper>
      ) : addContentBoxVisible ? (
        <AddOrPasteLink
          resourceElementIndex={resourceElementIndex}
          prepareModuleResourcePayload={prepareModuleResourcePayload}
        />
      ) : (
        <AddOrDragContent openBoard={openBoard} />
      )}
    </S.Container>
  );
};

export default observer(ModulesResourceNewItem);
