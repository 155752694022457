import React from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { Icon, EmptyListCard } from "@arcadia/design-system";
import * as S from "./SpacesBadgesListPlaceholder.styles";

type badgeListPlaceholderProps = {
  onClick: () => void;
};

const SpacesBadgesListPlaceholder = ({
  onClick
}: badgeListPlaceholderProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  return (
    <S.BoardEmptyContainer data-testid="badge-list-placeholder">
      <EmptyListCard
        theme={whiteTheme}
        image={
          <Icon
            icon="illustration-badge"
            width="128"
            height="129"
            viewbox="0 0 128 129"
          />
        }
        text={
          <S.TextContainer>
            {translate("dashboard_badge_list_placeholder_text")}
          </S.TextContainer>
        }
        title={
          <S.TextContainer>
            {translate("dashboard_badge_list_placeholder_title")}
          </S.TextContainer>
        }
        ctaText={translate("dashboard_badge_list_placeholder_cta")}
        onClickCta={onClick}
      />
    </S.BoardEmptyContainer>
  );
};

export default SpacesBadgesListPlaceholder;
