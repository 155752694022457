import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react";
import React, { FC, useEffect, useReducer, useRef, useState } from "react";
import { useDebounce, useStores } from "src/js/hooks";
import { useResourcePayload } from "src/js/hooks/resources";
import {
  PrepareResourcePayload,
  PrepareWSResource
} from "src/js/hooks/resources/useResourcePayload";
import {
  checkFileSize,
  convertFileListToTempResources,
  getIsMediaResource
} from "src/js/modules/fileFunction";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { Resource, TempResource } from "src/js/types";
import { WebSocketSendError } from "src/js/types/models/WebSocket";
import { useTheme } from "styled-components";
import { ResourceItemPreview } from "../resources/ResourceItemPreview";
import { ResourceItemPreviewVariant } from "../resources/ResourceItemPreview/ResourceItemPreview";
import ResourceView from "../resources/ResourceView";
import {
  LIMIT_ATTACHMENTS,
  MAX_CHARACTER_LIMIT
} from "./TextEditorInput.const";
import * as S from "./TextEditorInput.styles";
import { ResourceActions, TextEditorInputProps } from "./TextEditorInput.types";
import { KeyboardHandler, resourcesReducer } from "./TextEditorInput.utils";
import { TextEditorMenu } from "./components/TextEditorMenu";
import { TextEditorMobileFooter } from "./components/TextEditorMobileFooter";

const TextEditorInput: FC<TextEditorInputProps> = ({
  submitFunction = () => {},
  onClick = () => {},
  onKeyDown = () => {},
  submitDisabled = false,
  placeholder = "",
  editableMessage,
  setEditableMessage = () => {},
  editFunction = () => {}
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const [dropActive, setDropActive] = useState<boolean>(false);
  const dropActiveDebounced = useDebounce(dropActive, 100);
  const [resources, dispatchResources] = useReducer(resourcesReducer, []);
  const [resourceViewPosition, setResourceViewPosition] =
    useState<number>(null);
  const [characterLimitReached, setCharacterLimitReached] =
    useState<boolean>(false);

  const hasResources = resources?.length > 0;
  const hasResourcesInPending = resources.some(r => r.id === -1);

  const extensions = [
    StarterKit,
    Link,
    Placeholder.configure({
      placeholder
    })
  ];

  if (isLayoutModeMobile === false) {
    extensions.push(KeyboardHandler);
  }

  const editor = useEditor({
    autofocus: !submitDisabled,
    editable: true,
    content: editableMessage?.message,
    extensions
  });

  // necessary to make placeholder extension reactive to changes
  useEffect(() => {
    if (editor !== null && placeholder !== "") {
      editor.extensionManager.extensions.filter(
        extension => extension.name === "placeholder"
      )[0].options.placeholder = placeholder;
      editor.view.dispatch(editor.state.tr);
    }
  }, [editor, placeholder]);

  useEffect(() => {
    editor?.commands.setContent(editableMessage?.message);
  }, [editableMessage]);

  useEffect(() => {
    if (editor?.getHTML().length > MAX_CHARACTER_LIMIT) {
      if (!characterLimitReached) {
        showToastError({
          str: translate("text_editor_input_character_limit_reached")
        });
        setCharacterLimitReached(true);
        editor.commands.blur();
      }
    } else {
      setCharacterLimitReached(false);
    }
  }, [editor?.getHTML().length]);

  const hasTextOrResource = resources?.length > 0 || editor?.getText().trim();

  const submitIsDisabled =
    submitDisabled ||
    !hasTextOrResource ||
    characterLimitReached ||
    hasResourcesInPending;

  const handleSubmit = async () => {
    const noText = !editor?.getText().trim();
    try {
      if (editableMessage) {
        editFunction({ message: noText ? null : editor.getHTML() });
      } else {
        await submitFunction({
          message: noText ? null : editor.getHTML(),
          resources
        });
      }
      editor.commands.clearContent();
      dispatchResources({ type: "RESET" });
    } catch (error) {
      if (error?.message) {
        showToastError({ str: error.message });
      } else if (error === WebSocketSendError.SocketNotReady) {
        showToastError({
          str: translate("text_editor_input_send_message_connection_error")
        });
      }
    } finally {
      editor.commands.focus();
    }
  };

  const handleKeyPressEnter: React.KeyboardEventHandler<
    HTMLDivElement
  > = event => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !isLayoutModeMobile &&
      !submitIsDisabled
    ) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const isAddingResourcesAllowed = () => {
    if (hasResourcesInPending) {
      showToastError({
        str: "text_editor_input_pending_attachments"
      });
      return false;
    }
    if (resources?.length >= LIMIT_ATTACHMENTS) {
      showToastError({
        str: translate("text_editor_input_limit_attachments", {
          limit: LIMIT_ATTACHMENTS
        })
      });
      return false;
    }
    return true;
  };

  const onSuccessCallback = useRef<(resource: Resource) => void>(() => {});
  const onErrorCallback = useRef<(error: any) => void>(() => {});

  const { prepareResourcePayload } = useResourcePayload({
    onSuccessCallback: (resource: Resource) => {
      onSuccessCallback.current(resource);
    },
    onErrorCallback: error => {
      showToastError({
        str: translate("text_editor_error_upload_attachment")
      });
      onErrorCallback.current(error);
    },
    options: {
      hasGallery: false
    }
  });

  const uploadTempResourcesFiles = (tempResourcesFiles: TempResource[]) => {
    const uploadResource = (index: number) => {
      const tempResource = tempResourcesFiles[index];
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(tempResource?.file);

      const uploadNextResource = () => {
        if (index < tempResourcesFiles.length - 1) {
          uploadResource(index + 1);
        } else {
          onSuccessCallback.current = () => {};
          onErrorCallback.current = () => {};
        }
      };

      onSuccessCallback.current = (resource: Resource) => {
        dispatchResources({
          type: ResourceActions.ReplaceTempResource,
          payload: {
            resource,
            id: tempResource?.tempId
          }
        });
        uploadNextResource();
      };
      onErrorCallback.current = () => {
        dispatchResources({
          type: ResourceActions.RemoveTempResourceById,
          payload: {
            id: tempResource?.tempId
          }
        });
        uploadNextResource();
      };

      prepareResourcePayload(dataTransfer.files);
    };

    uploadResource(0);
  };

  const uploadFiles = (files: FileList) => {
    if (editableMessage) return;
    const canAddResources = isAddingResourcesAllowed();
    if (!canAddResources) return;
    if (files) {
      const largeFiles = checkFileSize(files);
      if (largeFiles.length) {
        largeFiles.map(fileName =>
          showToastError({
            str: translate("file_drag_drop_size_error", {
              fileName
            })
          })
        );
      }
      const dataTransfer = new DataTransfer();
      const filesArray = Array.from(files);
      for (let i = 0; i < filesArray.length; i += 1) {
        const file = filesArray[i];
        if (!largeFiles.includes(file.name)) {
          if (resources?.length + i >= LIMIT_ATTACHMENTS) {
            showToastError({
              str: translate("text_editor_input_limit_attachments", {
                limit: LIMIT_ATTACHMENTS
              })
            });
            break;
          }
          dataTransfer.items.add(file);
        }
      }
      const tempResources = convertFileListToTempResources(dataTransfer.files);
      dispatchResources({
        type: ResourceActions.SetTempsResources,
        payload: {
          tempResources
        }
      });
      uploadTempResourcesFiles(tempResources);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = e => {
    setDropActive(false);
    e.preventDefault();
    const { files } = e.dataTransfer;
    uploadFiles(files);
  };

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    const { files } = e.clipboardData;
    uploadFiles(files);
  };

  const dragLeave = e => {
    setDropActive(false);
    e.preventDefault();
  };

  const dragEnter = e => {
    setDropActive(true);
    e.preventDefault();
  };

  const dragOver = e => {
    setDropActive(true);
    e.preventDefault();
  };

  const getResourceItemPreviewVariant = (mimeType: string) => {
    return getIsMediaResource(mimeType)
      ? ResourceItemPreviewVariant.Image
      : ResourceItemPreviewVariant.Card;
  };

  const prepareModuleResourcePayload: PrepareResourcePayload = fileList => {
    uploadFiles(fileList);
  };
  const prepareWSResource: PrepareWSResource = (tempResource, saveResource) => {
    const canAddResources = isAddingResourcesAllowed();
    if (!canAddResources) return;
    dispatchResources({
      type: ResourceActions.SetTempsResources,
      payload: { tempResources: [tempResource] }
    });
    saveResource()
      .then(resource => {
        dispatchResources({
          type: ResourceActions.ReplaceTempResource,
          payload: {
            resource: resource as Resource,
            id: tempResource?.tempId
          }
        });
      })
      .catch(() => {
        showToastError({
          str: translate("text_editor_error_upload_attachment")
        });
        dispatchResources({
          type: ResourceActions.RemoveTempResourceById,
          payload: {
            id: tempResource?.tempId
          }
        });
      });
  };

  const clearEditMode = () => {
    setEditableMessage(null);
    editor.commands.clearContent();
    dispatchResources({ type: "RESET" });
  };

  return (
    <S.EditorContainer
      dropActive={dropActiveDebounced && !editableMessage}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={handleDrop}
      onPaste={handlePaste}
      onClick={onClick}
      isFocused={editor?.isFocused}
    >
      {dropActiveDebounced && !editableMessage ? (
        <S.DropActiveText>
          {translate("text_editor_input_active_drop")}
        </S.DropActiveText>
      ) : null}
      {isLayoutModeMobile ? null : (
        <TextEditorMenu
          editor={editor}
          prepareModuleResourcePayload={prepareModuleResourcePayload}
          prepareWSResource={editableMessage ? null : prepareWSResource}
        />
      )}
      <S.StyledEditableContent
        hasResources={hasResources}
        editor={editor}
        onKeyDown={event => {
          handleKeyPressEnter(event);
          onKeyDown();
        }}
      />
      {hasResources ? (
        <S.TextEditorResources>
          <ResourceView.Portal>
            {typeof resourceViewPosition === "number" ? (
              <ResourceView.Content
                resources={resources}
                startingPosition={resourceViewPosition}
                onClose={() => setResourceViewPosition(null)}
              />
            ) : null}
          </ResourceView.Portal>
          {resources.map((resource, i) => (
            <ResourceItemPreview
              key={`text-editor-resource-${resource?.name}`}
              resource={resource}
              variant={getResourceItemPreviewVariant(resource?.fileMimeType)}
              loading={resource?.id === -1}
              onClick={() => setResourceViewPosition(i)}
              onDelete={id => {
                dispatchResources({
                  type: "REMOVE_RESOURCE_BY_ID",
                  payload: { id }
                });
              }}
            />
          ))}
        </S.TextEditorResources>
      ) : null}
      {isLayoutModeMobile ? (
        <TextEditorMobileFooter
          editor={editor}
          prepareModuleResourcePayload={prepareModuleResourcePayload}
          prepareWSResource={editableMessage ? null : prepareWSResource}
          submitDisabled={submitIsDisabled}
          handleSubmit={handleSubmit}
          isEditMode={!!editableMessage}
          clearEditMode={clearEditMode}
        />
      ) : null}
      {!isLayoutModeMobile ? (
        <>
          {editableMessage ? (
            <S.CancelButton
              variant="secondary"
              theme={whiteTheme}
              icon="close"
              onClick={clearEditMode}
            />
          ) : null}
          <S.SubmitButton
            variant="primary"
            theme={whiteTheme}
            icon={editableMessage ? "check2" : "paperPlane"}
            onClick={handleSubmit}
            disabled={submitIsDisabled}
          />
        </>
      ) : null}
    </S.EditorContainer>
  );
};

export default observer(TextEditorInput);
