import React, { useEffect } from "react";
import { BubbleMenu, EditorContent, FloatingMenu } from "@tiptap/react";
import WSTheme from "src/js/theme/theme";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { AICustomPrompt } from "./components/AICustomPrompt";
import { ControlledMenu } from "./components/ControlledMenu";
import { BaseEditorProps } from "./BaseTextEditor.types";

import * as S from "./BaseTextEditor.styles";
import { AIDiscoveryTooltip } from "./components/AIDiscoveryTooltip";
import { useAIEditor } from "./hooks";
import { LoadingMenu } from "./components/LoadingMenu";

// This base editor comes with no style and high customization capabilities
// use the hook to improve the customization of the editor tool and the classNames to have a default style

const BaseTextEditor = ({
  fixedMenu,
  bubbleMenu,
  floatingMenu,
  editor,
  className
}: BaseEditorProps) => {
  const [isDiscoveryModalClosed, setIsDiscoveryModalClosed] =
    React.useState(false);

  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { isAIEditor } = useAIEditor(editor, () => {
    setIsDiscoveryModalClosed(true);
  });

  let isInContext = true;
  // ControlledMenu is outside the context when open in a modal
  // this checks if you are within the context or not.
  // TODO finx a better solution
  try {
    useAppLayout();
    isInContext = true;
  } catch (e) {
    isInContext = false;
  }

  useEffect(() => {
    if (!isDiscoveryModalClosed) return;
    editor.commands.focus("start");
    const firstWord = editor.getText().split(" ")[0] || "";
    editor.commands.setTextSelection({
      from: 0,
      to: firstWord ? firstWord.length + 1 : 0
    });
  }, [isDiscoveryModalClosed, editor]);

  return (
    <S.EditorWrapper>
      {editor && fixedMenu && <S.FixedMenu>{fixedMenu(editor)}</S.FixedMenu>}
      {editor && bubbleMenu && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            zIndex: WSTheme.zIndex.sidebar + 1,
            placement: isLayoutModeMobile ? "bottom-start" : "top-start"
          }}
        >
          {!editor.storage.ai?.loading ? bubbleMenu(editor) : <LoadingMenu />}
        </BubbleMenu>
      )}
      {editor && isInContext && (
        <ControlledMenu
          hidden={!editor.storage.ai?.isCustomPromptActive}
          editor={editor}
        >
          <AICustomPrompt editor={editor} />
        </ControlledMenu>
      )}
      {editor && (
        <BubbleMenu
          editor={editor}
          shouldShow={() => !editor.storage.ai?.discovered && editor.isEmpty}
          pluginKey="aiDiscovery"
          tippyOptions={{
            arrow: true,
            animation: "fade",
            placement: "bottom-start",
            duration: 300,
            delay: 300,
            zIndex: WSTheme.zIndex.sidebar + 1
          }}
        >
          {isAIEditor && <AIDiscoveryTooltip editor={editor} />}
        </BubbleMenu>
      )}
      {editor && floatingMenu && (
        <FloatingMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            zIndex: WSTheme.zIndex.sidebar + 1,
            placement: isLayoutModeMobile ? "bottom-start" : "top-start"
          }}
        >
          {!editor.storage.ai?.loading ? floatingMenu(editor) : <LoadingMenu />}
        </FloatingMenu>
      )}
      <EditorContent
        editor={editor}
        className={className}
        id="tiptap-editor-wrapper"
        onContextMenu={e => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      />
      <div id="ai-writer-portal" />
    </S.EditorWrapper>
  );
};

export default BaseTextEditor;
