import colors from "@ds_themes/colors";
import { TooltipThemeOptions } from "@ds_themes/types";

export const tooltip: TooltipThemeOptions = {
  background: colors.violet[501],
  text: colors.grey[1000],
  top: {
    borderWidth: "0 9px 12px 9px",
    borderColor: `transparent transparent ${colors.violet[501]} transparent`
  },
  bottom: {
    borderWidth: "12px 9px 0 9px",
    borderColor: ` ${colors.violet[501]} transparent transparent transparent`
  },
  left: {
    borderWidth: "9px 12px 9px 0",
    borderColor: ` transparent ${colors.violet[501]} transparent transparent`
  },
  right: {
    borderWidth: "9px 0 9px 12px",
    borderColor: `transparent transparent transparent ${colors.violet[501]} `
  }
};
