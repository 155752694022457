import React, { useEffect, useRef, useState } from "react";
import { isCollapsedElement } from "src/js/modules/commonFunction";
import * as S from "src/js/pages/wall/DescriptionBox/DescriptionBox.styles";
import { useTranslation } from "src/js/translation";

const DescriptionBox = ({
  groupDescription,
  isCollapsed = false
}: {
  groupDescription: string;
  isCollapsed: boolean;
}) => {
  const { translate } = useTranslation();
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const ellipsableNode = useRef();

  useEffect(() => {
    const element: HTMLElement = ellipsableNode.current;
    if (!element) return;
    setHasEllipsis(isCollapsedElement(element) || element?.offsetHeight > 20);
  }, []);

  if (!groupDescription) return null;

  return (
    <S.DescriptionBoxContainer>
      {groupDescription && (
        <S.CollapsableContainer collapsed={collapsed}>
          <S.CollapsableContent
            ref={ellipsableNode}
            collapsed={collapsed}
            dangerouslySetInnerHTML={{
              __html: groupDescription.replace(/\n/g, "<br />")
            }}
          />
          {hasEllipsis && (
            <S.CollapseButton onClick={() => setCollapsed(!collapsed)}>
              {translate(collapsed ? "more_label" : "less_label")}
            </S.CollapseButton>
          )}
        </S.CollapsableContainer>
      )}
    </S.DescriptionBoxContainer>
  );
};

export default DescriptionBox;
