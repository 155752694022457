import { useEffect, useRef } from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { clearInterval, setInterval } from "worker-timers";
import { capitalizeFirstLetter } from "../modules/commonFunction";
import useStores from "./useStores";

const TAB_NOTIFICATION_INTERVAL = 2000;

function useTabNotification() {
  const {
    ChatStore: { tabNotification }
  } = useStores();
  const { translate } = useTranslation();
  const originalTitle = useRef<string | undefined>(undefined);

  useEffect(() => {
    let intervalId;
    const { count, lastUser } = tabNotification;
    if (count === 0 && originalTitle.current) {
      document.title = originalTitle.current;
      originalTitle.current = undefined;
    } else if (count > 0) {
      if (count === 1) {
        originalTitle.current = document.title;
      }
      const titleCount = translate({
        text: "tab_notification_title_count",
        stringVariables: { count }
      });
      const titleUser = translate({
        text: "tab_notification_title_user",
        stringVariables: {
          user: capitalizeFirstLetter(`${lastUser?.name} ${lastUser?.surname}`)
        }
      });
      document.title = titleCount;
      intervalId = setInterval(() => {
        document.title = document.title === titleCount ? titleUser : titleCount;
      }, TAB_NOTIFICATION_INTERVAL);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [tabNotification, originalTitle]);
}

export default useTabNotification;
