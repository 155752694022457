import styled, { css } from "styled-components";

import { SToastContainerProps } from "./types";

export const ToastContainer = styled.div<SToastContainerProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  font-family: "WeSchoolSans";
  position: relative;
  min-height: 37px;
  justify-content: flex-start;
  border-radius: 8px;
  width: 100%;

  ${({ type, theme }) =>
    type === "success" &&
    css`
      background-color: ${theme.toast.success.background};
      color: ${theme.toast.icon.light.color};
    `}
  ${({ type, theme }) =>
    type === "warning" &&
    css`
      background-color: ${theme.toast.warning.background};
      color: ${theme.toast.icon.dark.color};
    `}
  ${({ type, theme }) =>
    type === "error" &&
    css`
      background-color: ${theme.toast.error.background};
      color: ${theme.toast.icon.light.color};
    `};
`;

export const ToastText = styled.div`
  align-self: center;
  width: 100%;
  padding: 10px;
`;

export const ToastIconType = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  padding-left: 16px;
  padding-right: 8px;
`;

export const ToastButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  padding-left: 16px;
  padding-right: 18px;
  color: inherit;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;

  &:focus-visible {
    outline: ${({ theme }) => `3px solid ${theme.toast.button.outline}`};
  }
`;
