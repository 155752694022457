import Backbone from "backbone";
import each from "lodash/each";
import { createURL } from "../modules/utility";
import { getActiveGroupId } from "../modules/activeGroup";
import ExerciseModel from "../models/exerciseModel";
import { getBreakpoint } from "src/js/modules/layoutFunction";

const ExerciseCollection = Backbone.Collection.extend({
  model: ExerciseModel,
  url: createURL("api_exercises"),
  limit: {
    desktop: 5,
    tablet: 5,
    smartphone: 5
  },
  offset: 0,
  noMoreItems: false,
  nextPage(callback, filter) {
    const self = this;
    const limit = self.limit[getBreakpoint()] + 1;
    this.offset = this.offset + self.limit[getBreakpoint()];

    const pars = {
      limit,
      offset: this.offset,
      group_id: getActiveGroupId(),
      filter
    };

    $.ajax({
      url: createURL("api_exercises"),
      data: pars,
      success(ret) {
        if (ret.length > self.limit[getBreakpoint()]) {
          ret.pop();
        } else {
          self.noMoreItems = true;
        }
        if (ret[0].is_pinned_up) {
          // se lista contiene solo elementi pinnati
          self.noMoreItems = true;
        }
        each(ret, post => {
          self.add(post);
        });
        self.isLoading = false;
        if (typeof callback === "function") callback(ret);
      },
      error(ret) {
        // console.log("empty");
        self.isLoading = false;
        if (typeof callback === "function") callback(ret);
      }
    });
  }
});

export default ExerciseCollection;
