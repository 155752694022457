import { shadow } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const DragHandler = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: -20px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-left: 4px;

  display: none;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  overflow: visible;
  box-shadow: ${({ isActive }) => (isActive ? shadow[300] : undefined)};
`;

export const BoardLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 4px;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  min-height: 125px;
  display: flex;
  cursor: pointer;
  position: relative;
  ${({ semiTransparent }) =>
    semiTransparent &&
    css`
      opacity: 0.5;
    `}

  &:hover ${DragHandler} {
    display: unset !important;
  }

  user-select: none;
`;

export const ColorColumn = styled.div`
  width: 6px;
  flex: 0 0 6px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ color }) => color};
`;

export const BoardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  @media screen and (min-width: 768px) {
    padding-left: 25px;
  }
`;

export const BoardBody = styled.div`
  flex: 1;
  display: flex;
`;

export const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const ActionsContainer = styled.div`
  flex: 0 0 auto;
  padding: 30px 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex: 0 0 auto;
    padding-top: 16px;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h5`
  display: inline-block;
  margin: 0;
  color: ${({ theme }) => theme.colors.basicBlack};
  font-size: 16px;
  font-weight: 500;
`;

export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isLocked }) =>
    isLocked ? theme.colors.basicBlack : theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.basicWhite};
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 12px;
  margin-right: 16px;
`;

export const PlayIconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  padding-left: 2px;
`;

export const TagsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 5px 0;
  gap: 10px;
  flex-wrap: wrap;
`;

export const FacultativeTag = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.basicWhite};
  background-color: ${({ theme }) => theme.primaryColor[500]};
  padding: 0 12px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
`;

const BaseTag = css`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 0 12px;
  height: 24px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
`;

export const DraftTag = styled.span`
  ${BaseTag}
  color: ${({ theme }) => theme.colors.semantic1};
  border: 1px solid ${({ theme }) => theme.colors.semantic1};
`;

export const AIGeneratedTag = styled.span`
  ${BaseTag}
  display:flex;
  color: ${({ theme }) => theme.primaryColor[500]};
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
  gap: 4px;

  > svg {
    flex: 0 0 16px;
  }
`;

export const BoardFooter = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const BoardFooterText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ hasMarginLeft }) =>
    hasMarginLeft &&
    css`
      margin-left: 10px;
    `}
`;

export const ProgressBarGutter = styled.div`
  margin-left: 10px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.accent3};
  width: 70px;
  height: 4px;
  border-radius: 2px;
  @media screen and (min-width: 768px) {
    width: 88px;
  }
`;

export const ProgressBar = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  width: 0%;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
  ${({ percentage }) =>
    percentage &&
    css`
      width: ${percentage}%;
    `}
`;

export const GrayDot = styled.span`
  background-color: ${({ theme }) => theme.colors.accent7};
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 8px;
  @media screen and (min-width: 768px) {
    margin: 0 16px;
  }
`;

export const MilestoneMobileContainer = styled.div`
  color: ${({ color }) => color};
  margin-right: 16px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const BaseActionButton = css`
  position: relative;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
`;

export const MainActionButton = styled.button`
  ${BaseActionButton}
  & svg {
    flex: 0 0 18px;
  }
  @media screen and (min-width: 768px) {
    ${({ isActive }) =>
      !isActive &&
      css`
        border: none;
      `}
  }
`;

export const QuickActionButton = styled.button`
  ${BaseActionButton}
  margin-right: ${({ noMargin }) => (noMargin ? 0 : "10px")};

  ${({ disabledStyle }) =>
    disabledStyle &&
    css`
      color: ${({ theme }) => theme.colors.accent7};
      cursor: not-allowed !important;
    `}
`;

export const QuickActionCounter = styled.span`
  position: absolute;
  left: 25px;
  top: -1px;
  border-radius: 6px;
  padding: 0 3px;
  z-index: 1;
  min-width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.basicWhite};
  font-size: 10px;
  font-weight: 400;
`;

export const HideOnMobile = styled.span`
  display: none;
  @media screen and (min-width: 768px) {
    display: inline-flex;
  }
`;

export const HideOnDesktop = styled.span`
  display: inline-flex;
  align-items: center;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MilestoneWrapper = styled.div`
  margin-top: -17px;
  margin-right: 24px;
`;
