import React from "react";
import { AvatarWithInitials, Heading, Text } from "@arcadia/design-system";
import { createUrl } from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import handleThumbnails from "src/js/modules/imageFunction";
import { groupGetCover } from "src/js/GroupFunction";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { GroupInCommon } from "./GroupsInCommon.types";
import * as S from "./GroupsInCommon.styles";

const GroupsInCommonElement = ({ item }: { item: GroupInCommon }) => {
  const { UIStore } = useStores();
  const backgroundImage = `${handleThumbnails(
    groupGetCover(item.group),
    "medium"
  )}`;
  const { whiteTheme } = useTheme();
  return (
    <S.GroupCard
      onClick={() =>
        navigateTo(
          createUrl("wall", {
            group_id: item.group.id,
            space_slug: item.space.slug
          })
        )
      }
    >
      {UIStore.isLayoutModeMobile ? (
        <>
          <S.GroupBadgeContainer>
            {item.space ? (
              <S.GroupCardSpaceCover>
                <AvatarWithInitials
                  url={item.space.badge?.small}
                  theme={whiteTheme}
                  size="auto"
                  alt={item.space.name}
                  avatarColor={item.space.defaultBadgeColor}
                  name={item.space.name}
                />
              </S.GroupCardSpaceCover>
            ) : null}
            <S.GroupCardPlaceholder background={backgroundImage} />
          </S.GroupBadgeContainer>
          <S.GroupCardGroupInfo>
            <S.GroupCardGroupName>
              <Heading level="6">{item.group.name}</Heading>
            </S.GroupCardGroupName>
          </S.GroupCardGroupInfo>
        </>
      ) : (
        <>
          <S.GroupCardPlaceholder background={backgroundImage}>
            {item.space ? (
              <S.GroupCardSpaceCover>
                <AvatarWithInitials
                  url={item.space.badge?.small}
                  theme={whiteTheme}
                  size="auto"
                  alt={item.space.name}
                  avatarColor={item.space.defaultBadgeColor}
                  name={item.space.name}
                />
              </S.GroupCardSpaceCover>
            ) : null}
          </S.GroupCardPlaceholder>
          <S.GroupCardGroupInfo>
            {item.space ? (
              <Text type="formSmallCaption">{item.space.name}</Text>
            ) : null}
            <S.GroupCardGroupName>
              <Heading level="6">{item.group.name}</Heading>
            </S.GroupCardGroupName>
          </S.GroupCardGroupInfo>
        </>
      )}
    </S.GroupCard>
  );
};

export default GroupsInCommonElement;
