import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  BasePaginationParams,
  BasePaginationResponse,
  StandardPaginationResponse
} from "../types";
import {
  BadgeRelatedGroupType,
  BadgeRelatedUserType,
  SpaceBadge,
  SpaceBadgeListSearch,
  UserAwardedBadgeType,
  WallBadgeList
} from "../types/models/Badge";
import {
  api_badge_delete,
  api_group_badges,
  api_space_badges_create_badge,
  api_space_badges_edit_badge,
  api_space_badges_list,
  api_space_badges_related_groups,
  api_space_badges_related_users,
  api_space_badges_transactions,
  api_space_badges_transaction,
  api_user_awarded_badge,
  api_user_awarded_badges,
  getVersionedApiUrl
} from "./apiUrls";
import {
  BadgeCreationRequestPayload,
  BadgeCreationResponsePayload,
  BadgeTransactionStatusType
} from "./types";

export const fetchBadgesList = async ({
  limit,
  offset = 0,
  name,
  spaceId
}: SpaceBadgeListSearch) => {
  return getUserClient()
    .get<StandardPaginationResponse<SpaceBadge>>(
      getVersionedApiUrl(
        api_space_badges_list({
          spaceId
        }),
        "v3"
      ),
      {
        params: { limit, offset, name }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

export const deleteBadge = async (badgeId: string) => {
  return getUserClient()
    .delete(getVersionedApiUrl(api_badge_delete({ badgeId }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

export const updateBadgeRelatedGroups = async (
  addGroupIds: number[],
  removeGroupIds: number[],
  badgeId: string
) => {
  try {
    const response = await getUserClient().put<{
      groups: BadgeRelatedGroupType[];
    }>(getVersionedApiUrl(api_space_badges_related_groups({ badgeId }), "v3"), {
      addGroupIds,
      removeGroupIds
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const createSpaceBadge = async (badge: BadgeCreationRequestPayload) => {
  const {
    spaceId,
    name,
    description,
    criteria,
    image,
    issuerName,
    duration,
    isPrivate
  } = badge;
  try {
    const response = await getUserClient().post<SpaceBadge>(
      getVersionedApiUrl(api_space_badges_create_badge(), "v3"),
      {
        spaceId,
        name,
        description,
        criteria,
        image,
        issuerName,
        duration,
        isPrivate
      }
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const editSpaceBadge = async (
  badge: BadgeCreationRequestPayload,
  badgeId: string
) => {
  const {
    spaceId,
    name,
    description,
    criteria,
    image,
    issuerName,
    duration,
    isPrivate
  } = badge;
  try {
    const response = await getUserClient().put<SpaceBadge>(
      getVersionedApiUrl(api_space_badges_edit_badge({ badgeId }), "v3"),
      {
        spaceId,
        name,
        description,
        criteria,
        image,
        issuerName,
        duration,
        isPrivate
      }
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const fetchBadgeRelatedUsers = async ({
  limit,
  offset,
  badgeId,
  showUnassociated,
  textSearch,
  groupIds
}: BasePaginationParams & {
  badgeId: string;
  showUnassociated?: boolean;
  textSearch?: string;
  groupIds?: string[];
}) => {
  let parsedGroupIds: number[] = [];
  if (Array.isArray(groupIds)) {
    parsedGroupIds = groupIds.map(groupId => parseInt(groupId, 10));
  }
  return getUserClient()
    .get<BasePaginationResponse<BadgeRelatedUserType>>(
      getVersionedApiUrl(api_space_badges_related_users({ badgeId }), "v3"),
      {
        params: {
          limit,
          offset,
          showUnassociated: showUnassociated ? 1 : 0,
          name: textSearch || undefined,
          groupIds: Array.isArray(parsedGroupIds) ? parsedGroupIds : undefined
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchGroupBadgesList = async ({
  limit,
  offset = 0,
  name,
  groupId
}: WallBadgeList) => {
  return getUserClient()
    .get<StandardPaginationResponse<SpaceBadge>>(
      getVersionedApiUrl(
        api_group_badges({
          groupId
        }),
        "v3"
      ),
      {
        params: { limit, offset, name }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

export const fetchBadgeRelatedGroups = async ({
  limit,
  offset,
  badgeId,
  showUnassociated,
  textSearch
}: BasePaginationParams & {
  badgeId: string;
  showUnassociated?: boolean;
  textSearch?: string;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<BadgeRelatedGroupType>>(
      getVersionedApiUrl(api_space_badges_related_groups({ badgeId }), "v3"),
      {
        params: {
          limit,
          offset,
          showUnassociated: showUnassociated ? 1 : 0,
          name: textSearch || undefined
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const updateBadgeEmittedUsers = async (
  userIds: string[],
  badgeId: string,
  isNftRequired: boolean
) => {
  try {
    const response = await getUserClient().post<BadgeCreationResponsePayload>(
      getVersionedApiUrl(api_space_badges_related_users({ badgeId }), "v3"),
      { userIds, isNftRequired }
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const fetchUserAwardedBadges = async ({
  spaceId,
  isViewed,
  userId
}: {
  spaceId: string;
  isViewed: boolean;
  userId: string;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<UserAwardedBadgeType>>(
      getVersionedApiUrl(api_user_awarded_badges({ userId }), "v3"),
      {
        params: {
          spaceId,
          isViewed: isViewed ? 1 : 0
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const updateUserAwardedBadge = async (
  userId: string,
  badgeId: string
) => {
  try {
    const response = await getUserClient().patch<{ message: string }>(
      getVersionedApiUrl(api_user_awarded_badge({ userId, badgeId }), "v3"),
      { isViewed: true }
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const createBadgeTransaction = async (
  badgeId: string,
  awardedUserIds: string[],
  transactionReference: string,
  chainId: string,
  contractAddress: string
) => {
  const payload = {
    awardedUserIds,
    transactionReference,
    chainId,
    contractAddress
  };
  try {
    const response = await getUserClient().post<{ id: string }>(
      getVersionedApiUrl(api_space_badges_transactions({ badgeId }), "v3"),
      payload
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};

export const updateBadgeTransaction = async (
  badgeId: string,
  transactionId: string,
  userReferences: { userWalletAddress: string; nftReference: string }[],
  status: BadgeTransactionStatusType
) => {
  const payload = { userReferences, status };
  try {
    const response = await getUserClient().patch<{ chainId: string }>(
      getVersionedApiUrl(
        api_space_badges_transaction({ badgeId, transactionId }),
        "v3"
      ),
      payload
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
};
