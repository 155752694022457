import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  & svg {
    margin-bottom: 16px;
  }
  & span:nth-of-type(2) {
    margin-top: 8px;
  }
`;
