import React, { useMemo } from "react";

import * as S from "./ProgressBar.styles";

type ProgressBarProps = {
  percentage: number;
};

const ProgressBar = ({ percentage }: ProgressBarProps) => {
  const progress = useMemo(() => {
    if (percentage < 0) {
      return 0;
    }
    if (percentage > 100) {
      return 100;
    }
    return percentage;
  }, [percentage]);

  return (
    <S.ProgressBarWrapper>
      <S.PercentageText type="table">{percentage}%</S.PercentageText>
      <S.ProgressBar>
        <S.ProgressBarFilled style={{ width: `${progress}%` }} />
      </S.ProgressBar>
    </S.ProgressBarWrapper>
  );
};

export default ProgressBar;
