import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  NotificationArea,
  NotificationAreaModel,
  NotificationAreaValue,
  NotificationFiltersObject,
  NotificationRead,
  NotificationReadModel
} from "src/js/types/models/Notification";

const DefaultArea = Object.values(NotificationArea);

const useNotificationsParameters = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const area: NotificationAreaModel[] = useMemo(() => {
    const areaParam = new URLSearchParams(search).get("area");
    if (!areaParam) return DefaultArea;
    const areaParamArray = areaParam.split(",");
    let historyToReplace = false;
    const areasFiltered = areaParamArray.filter(areaFiltered => {
      if (
        NotificationAreaValue.includes(areaFiltered as NotificationAreaModel)
      ) {
        return areaFiltered;
      }
      historyToReplace = true;
      return "";
    });

    const filteredParam = new URLSearchParams(search);

    filteredParam.set("area", areasFiltered.join(","));

    if (historyToReplace) {
      history.replace({
        pathname,
        search: decodeURIComponent(filteredParam.toString())
      });
    }

    return (
      (filteredParam.get("area").split(",") as NotificationAreaModel[]) ??
      DefaultArea
    );
  }, [search]);

  const groupId = useMemo(() => {
    const groupIdParam = new URLSearchParams(search).get("groupid");
    if (!groupIdParam) return undefined;
    return parseInt(groupIdParam, 10);
  }, [search]);

  const read = useMemo(() => {
    const readParam = new URLSearchParams(search).get(
      "read"
    ) as NotificationReadModel;
    if (!readParam) return NotificationRead.All;
    return readParam;
  }, [search]);

  const setParams = useCallback(
    (newParams: NotificationFiltersObject) => {
      const searchParams = new URLSearchParams(search);
      if (newParams.read) {
        searchParams.set("read", newParams.read);
      } else {
        searchParams.set("read", NotificationRead.All);
      }
      if (newParams.groupId) {
        searchParams.set("groupid", `${newParams.groupId}`);
      } else {
        searchParams.delete("groupid");
      }
      if (newParams.area?.length > 0) {
        searchParams.set("area", newParams.area.join(","));
      }
      history.replace({
        pathname,
        search: decodeURIComponent(searchParams.toString())
      });
    },
    [history, pathname, search]
  );

  return { area, groupId, read, setParams };
};

export default useNotificationsParameters;
