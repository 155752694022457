import colors from "@ds_themes/colors";

export const resourceprogressbar = {
  default: colors.blueGrey[800],
  line: colors.violet[600],
  text: colors.blueGrey[200],
  hover: colors.coldGrey[900],
  finished: {
    background: colors.violet[501],
    icon: "check2",
    color: colors.grey[1000]
  },
  failed: {
    background: colors.error[600],
    icon: "closeBold",
    color: colors.grey[1000]
  },
  canceled: {
    background: colors.yellow[500],
    icon: "danger",
    color: colors.grey[50]
  }
};
