import styled from "styled-components";

export const ModalBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  margin-bottom: 12px;
`;

export const ModalBadgeImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 32px;
  max-height: 32px;
`;

export const ModalBadgeName = styled.div`
  margin-left: 16px;
  align-items: center;
  display: flex;
  min-width: 0px;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ListWrapper = styled.div`
  padding: 24px;
  height: 400px;
  @media (max-width: 767px) {
    height: 100%;
  }
`;
