import styled, { css } from "styled-components";

export const Container = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.grey[50]};
  width: 90px;
  height: 40px;
  padding: 0 16px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: ${({ theme }) => theme.colors.grey[1000]};
    background-color: ${({ theme }) => theme.colors.grey[50]};
    cursor: pointer;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${({ theme }) => theme.colors.grey[1000]};
      background-color: ${({ theme }) => theme.colors.grey[50]};
    `}
`;

export const NumberContainer = styled.span`
  display: flex;
  align-items: center;
  line-height: normal;
  gap: 4px;
`;

export const Text = styled.span`
  font-size: 14px;
  user-select: none;
  font-weight: 500;
  display: flex;
`;
