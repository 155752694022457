import { PlaceType } from "./SchoolClaimSearch.types";

export const CARD_NEW_SPACE_ID = "create-new-space";

export function parseObjPlaceData(place): PlaceType {
  const { address_components = [] } = place || {};

  function getValueFromAddressComponents(name: string, short = false) {
    return address_components.find(({ types }) => types?.includes(name))?.[
      short ? "short_name" : "long_name"
    ];
  }

  // postal town is the city in UK,
  // the combination of political and locality is the correct city for most of the countries
  // political alone is a fallback in case the combination with locality is not available
  function getCityFromAddressComponents() {
    return address_components.find(
      ({ types }) =>
        types?.includes("postal_town") ||
        (types?.includes("locality") && types?.includes("political")) ||
        types?.includes("political")
    )?.long_name;
  }

  return {
    streetNumber: getValueFromAddressComponents("street_number"),
    address: [
      getValueFromAddressComponents("route"),
      getValueFromAddressComponents("street_number")
    ].join(" "),
    city: getCityFromAddressComponents(),
    province: getValueFromAddressComponents(
      "administrative_area_level_2",
      true
    ),
    zip: getValueFromAddressComponents("postal_code") || "-"
  };
}
