import React from "react";
import { Box, SocialLoginButton, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { FeatureFlag } from "src/js/feature_flag/FeatureFlagProvider";
import { ExternalProviderLogin } from "src/js/pages/unlogged/ExternalProviderLogin";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { readLocalData } from "src/js/modules/storageFunction";
import { __LOCAL_UNIVERSITY_SAVED__ } from "src/js/settings/settingsLocalStorage";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useSocialLogin } from "./useSocialLogin";
import * as S from "./SocialLoginOptions.styles";

const SocialLoginOptions = ({ inviteToken }: { inviteToken?: string }) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const { loginOptions } = useSocialLogin(inviteToken);
  const providerName: { entityID: string } = JSON.parse(
    readLocalData(__LOCAL_UNIVERSITY_SAVED__)
  );

  return (
    <S.LoginOptionsContainer>
      <Box display="flex" alignItems="center" marginBottom={32}>
        <S.Border />
        <S.OtherOptionsTitle type="formSubtitle">
          {translate("sign_in_other_options")}
        </S.OtherOptionsTitle>
        <S.Border />
      </Box>
      <S.LoginOptions>
        <S.SocialLoginContainer>
          {loginOptions.map(({ provider, label, viewBox, onClick }) => {
            return isLayoutModeMobile ? (
              <Box key={provider}>
                <S.SocialMobileButton
                  theme={greyTheme}
                  icon={provider}
                  viewBox={viewBox}
                  onClick={onClick}
                />
              </Box>
            ) : (
              <TranslatedTooltip key={provider} tooltipString={label}>
                <Box>
                  <SocialLoginButton
                    theme={greyTheme}
                    icon={provider}
                    viewBox={viewBox}
                    onClick={onClick}
                  />
                </Box>
              </TranslatedTooltip>
            );
          })}
        </S.SocialLoginContainer>

        <FeatureFlag flagKey="universityLogin">
          <ExternalProviderLogin savedProvider={providerName} />
        </FeatureFlag>
      </S.LoginOptions>
      {providerName && providerName.entityID && (
        <S.ExternalProviderChangeLink
          onClick={() => navigateTo(createUrl("login_university"))}
        >
          <Text type="textLink">
            {translate("login_change_university_link")}
          </Text>
        </S.ExternalProviderChangeLink>
      )}
    </S.LoginOptionsContainer>
  );
};

export default SocialLoginOptions;
