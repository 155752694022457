import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { AddUserPermissionModal } from "src/js/components/AddUserPermissionModal";
import { useStores } from "src/js/hooks";
import { debounce } from "src/js/modules/commonFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import { SPACE_ROLES } from "./SpacesAddUserPermissionModal.const";

const SpacesAddUserPermissionModal = () => {
  const { SpaceStore, SpacesSettingsStore, ModalStore } = useStores();

  const {
    usersList,
    usersListHasNext,
    searchSpaceUsersByName,
    searchNextSpaceUsers,
    saveSpaceUserRole
  } = SpacesSettingsStore;
  const { activeSpaceName } = SpaceStore;

  useEffect(() => {
    searchSpaceUsersByName().catch(() => {
      showToastError({
        str: <Translate text="space_user_search_error_message" />
      });
    });
  }, []);

  let debounceFunc = null;

  const submitPermission = ({ accessPermission, userId }) => {
    const role = accessPermission
      ? SPACE_ROLES.ROLE_ADMIN
      : SPACE_ROLES.ROLE_POWERUSER;
    saveSpaceUserRole({ userId, role })
      .then(() => {
        showToastSuccess({
          str: <Translate text="space_add_user_permission_success_message" />
        });
        ModalStore.closeModal();
      })
      .catch(() => {
        showToastError({
          str: <Translate text="space_add_user_permission_error_message" />
        });
      });
  };

  return (
    <AddUserPermissionModal
      closeModal={() => ModalStore.closeModal()}
      fetchUsers={event => {
        event.persist();
        if (!debounceFunc) {
          debounceFunc = debounce(() => {
            searchSpaceUsersByName(event.target.value).catch(() => {
              showToastError({
                str: <Translate text="space_user_search_error_message" />
              });
            });
          }, 500);
        }
        debounceFunc();
      }}
      handleSubmit={({ accessPermission, userId }) =>
        submitPermission({ accessPermission, userId })
      }
      userList={usersList}
      usersListHasNext={usersListHasNext}
      searchNextSpaceUsers={searchNextSpaceUsers}
      spaceName={activeSpaceName}
    />
  );
};

export default observer(SpacesAddUserPermissionModal);
