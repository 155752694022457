import { differenceInYears, isValid, parse } from "date-fns";
import { useEffect } from "react";
import { useQuery } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { AnalyticsFilter, DashboardRangeTime } from "src/js/types";
import useAnalyticsFilter from "./useAnalyticsFilter";

type AnalyticsValidationProps = {
  spaceGroupIds: string[];
};

const useAnalyticsValidation = (
  { spaceGroupIds }: AnalyticsValidationProps = { spaceGroupIds: [] }
) => {
  const { translate } = useTranslation();
  const { queryParams } = useQuery<AnalyticsFilter>();

  const { setGroupIds, setRangeDate, setIntervalDate } = useAnalyticsFilter();

  useEffect(() => {
    // groupIds
    if (spaceGroupIds !== undefined) {
      const isGroupIdsSelected = queryParams?.groups?.length > 0;
      const isGroupIdsValid =
        isGroupIdsSelected &&
        spaceGroupIds?.some(id => queryParams?.groups?.includes(id));

      if (isGroupIdsSelected && !isGroupIdsValid) {
        showToastError({
          str: translate("analytics_filter_group_ids_invalid")
        });
        setGroupIds(undefined);
      }
    }

    // range
    const isRangeSelected = queryParams?.range?.length > 0;
    const isRangeValid = Boolean(
      isRangeSelected &&
        Object.values(DashboardRangeTime).includes(queryParams?.range)
    );

    if (isRangeSelected && !isRangeValid) {
      showToastError({
        str: translate("analytics_filter_range_invalid")
      });
      setRangeDate(undefined);
    }

    // interval
    const from = queryParams?.from
      ? parse(queryParams.from, "yyyy-MM-dd", new Date())
      : undefined;
    const to = queryParams?.to
      ? parse(queryParams.to, "yyyy-MM-dd", new Date())
      : undefined;
    const isIntervalSelected = Boolean(queryParams?.from && queryParams?.to);
    const isOutOfRange = differenceInYears(to, from) >= 3;
    const isIntervalValid = Boolean(
      isIntervalSelected &&
        isValid(from) &&
        isValid(to) &&
        from <= to &&
        !isOutOfRange
    );

    if (isIntervalSelected && !isIntervalValid) {
      if (isIntervalValid) {
        showToastError({
          str: translate("analytics_filter_interval_out_of_range")
        });
      } else {
        showToastError({
          str: translate("analytics_filter_interval_invalid")
        });
      }
      setIntervalDate(undefined, undefined);
    }
  }, [queryParams]);
};

export default useAnalyticsValidation;
