import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import * as S from "./Label.styles";

const Label = ({
  text = "",
  children = null,
  visuallyHidden = false,
  bold = false,
  marginTop = null,
  marginBottom = null,
  ...props
}) => {
  return (
    <S.LabelContainer
      visuallyHidden={visuallyHidden}
      bold={bold}
      marginTop={marginTop}
      marginBottom={marginBottom}
      {...props}
    >
      {children || <Translate text={text} />}
    </S.LabelContainer>
  );
};

export default Label;
