import { Modal } from "@arcadia/design-system";
import React, { FC, PropsWithChildren } from "react";
import { useStores } from "src/js/hooks";

type Props = {
  title: string;
};

const ModalContent: FC<PropsWithChildren<Props>> = ({ children, title }) => {
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore
  } = useStores();

  return isLayoutModeMobile ? (
    (children as JSX.Element)
  ) : (
    <Modal title={title} onClose={ModalStore.closeModal}>
      {children}
    </Modal>
  );
};

export default ModalContent;
