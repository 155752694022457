import styled from "styled-components";

export const SortableItem = styled.li`
  box-sizing: border-box;
  list-style: none;
`;

export const Handle = styled.button`
  touch-action: none;
  cursor: grab;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
`;
