export const __FORM_AI_COURSE_WIZARD__ = {
  audience: {
    required: "ai_wizard_error_audience_required",
    validate: val => !!val.trim() || "ai_wizard_error_audience_required"
  },
  topic: {
    required: "ai_wizard_error_topic_required",
    validate: val => !!val.trim() || "ai_wizard_error_topic_required"
  },
  courseLength: {},
  effort: {},
  typology: {}
};
