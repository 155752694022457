import { extractErrorMessage } from "src/js/modules/messageManager";
import { api_search, getVersionedApiUrl } from "src/js/repository/apiUrls";
import getUserClient from "src/js/modules/connection";
import { SearchResponse, ContextFilterKey } from "src/js/types";

async function fetchSearchResult(
  keyword: string,
  contexts: ContextFilterKey[],
  groupIds: string[],
  userIds: string[],
  spaceId: string,
  page: number
) {
  const limit = 10;
  const offset = (page - 1) * limit;
  let context = null;

  const mappedContext = contexts.map(c => (c.includes("module") ? "board" : c));

  if (mappedContext.length > 0) {
    context = mappedContext.join(",");
  }

  const params = {
    textSearch: keyword || undefined,
    offset,
    limit,
    context,
    groupIds,
    spaceId,
    userIds
  };
  return getUserClient()
    .get<SearchResponse>(getVersionedApiUrl(api_search(), "v3"), {
      params
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_group"));
      throw Err;
    });
}

export { fetchSearchResult as default };
