import React from "react";
import styled, { useTheme } from "styled-components";
import { __NO_GROUPS_IN_SPACE_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { Box, Button, Heading } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

const NoGroupImage = styled.div`
  background-image: url(${__NO_GROUPS_IN_SPACE_PLACEHOLDER__});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex: 1;
  width: 148px;
`;

const NoGroupsView = ({ onClick }: { onClick: () => void }) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      flex="1 1 0"
    >
      <NoGroupImage />
      <Box marginTop={12} marginBottom={34}>
        <Heading level="5">
          {translate({ text: "sidebar_mobile_no_groups_view_title" })}
        </Heading>
      </Box>

      <Button
        variant="primary"
        theme={whiteTheme}
        type="button"
        onClick={onClick}
      >
        {translate({
          text: "sidebar_mobile_no_groups_view_button_placeholder"
        })}
      </Button>
    </Box>
  );
};

export default NoGroupsView;
