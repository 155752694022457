import { Box, Button, Icon, ImageViewer } from "@arcadia/design-system";
import React from "react";
import { createStaticUrl } from "src/js/modules/routing";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { AvatarModel, DashboardEvent, EventDomain } from "src/js/types";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import * as S from "./GroupTooltip.styles";

export type GroupTooltipProps = {
  id: number;
  name: string;
  cover: AvatarModel | null;
  totalUsers: number;
};

const GroupTooltip = ({ id, name, cover, totalUsers }: GroupTooltipProps) => {
  const { whiteTheme, primaryColor } = useTheme();
  const { translate } = useTranslation();

  return (
    <S.Wrapper>
      <Box display="flex" gap="12px" alignItems="center">
        <S.ImageContainer>
          <ImageViewer
            alt="group badge"
            url={cover?.small}
            fallbackImage={__GROUP_COVER_DEFAULT__}
          />
        </S.ImageContainer>
        <Box
          display="flex"
          gap="4px"
          flexDirection="column"
          padding="4px 0"
          minWidth={0}
        >
          <S.NameContainer>{name}</S.NameContainer>
          <S.IconContainer>
            <Icon
              initialViewbox
              icon="user"
              width={16}
              height={16}
              color={primaryColor[500]}
            />{" "}
            {totalUsers}
          </S.IconContainer>
        </Box>
      </Box>
      <Box display="flex" gap="12px" alignItems="center">
        <Button
          onClick={() => {
            _trackEvent(
              EventDomain.SpaceDashboardUsage,
              DashboardEvent.CourseprogressCTARecordModule,
              id
            );
            window.open(
              `#${createStaticUrl("activity", {
                group_id: id
              })}`,
              "_blank"
            );
          }}
          variant="primary"
          theme={whiteTheme}
          fullWidth
        >
          {translate("metric_card_objectives_group_tooltip_primary_cta")}
        </Button>
        <Button
          onClick={() => {
            _trackEvent(
              EventDomain.SpaceDashboardUsage,
              DashboardEvent.CourseprogressCTAModule,
              id
            );
            window.open(
              `#${createStaticUrl("modules", {
                group_id: id
              })}`,
              "_blank"
            );
          }}
          variant="secondary"
          theme={whiteTheme}
          fullWidth
        >
          {translate("metric_card_objectives_group_tooltip_secondary_cta")}
        </Button>
      </Box>
    </S.Wrapper>
  );
};

export default GroupTooltip;
