import { Duration } from "date-fns";
import React from "react";
import { useTranslation } from "src/js/translation";

export type TimerViewProps = {
  time: Duration;
};

const TimerView = ({ time }: TimerViewProps) => {
  const { translate } = useTranslation();
  if (!time) return null;
  return (
    <>
      {time.years > 0 && (
        <>
          {time.years}{" "}
          {translate({
            text: time.years === 1 ? "event_live_year" : "event_live_years"
          })}
          {", "}
        </>
      )}
      {time.months > 0 && (
        <>
          {time.months}{" "}
          {translate({
            text: time.months === 1 ? "event_live_month" : "event_live_months"
          })}
          {", "}
        </>
      )}
      {time.days > 0 && (
        <>
          {time.days}{" "}
          {translate({
            text: time.days === 1 ? "event_live_day" : "event_live_days"
          })}
          {", "}
        </>
      )}
      {time.hours > 0 && (
        <>
          {time.hours}{" "}
          {translate({
            text: time.hours === 1 ? "event_live_hour" : "event_live_hours"
          })}
          {", "}
        </>
      )}
      {time.minutes > 0 && (
        <>
          {time.minutes} {translate({ text: "event_live_minutes" })}
          {", "}
        </>
      )}
      <>
        {time.seconds} {translate({ text: "event_live_seconds" })}
      </>
    </>
  );
};

export default TimerView;
