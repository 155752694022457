import React from "react";
import { useDraggable } from "@dnd-kit/core";
import * as S from "./Draggable.styles";
import { getRandomInRange } from "../../FadingMatches.utils";

interface DraggableProps {
  id: string;
}

const Draggable: React.FC<DraggableProps> = ({ id, children }) => {
  const [y] = React.useState(getRandomInRange(-10, 20));
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id
    });

  return (
    <S.Item
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      exit={{
        scale: 0,
        opacity: 0,
        transition: { duration: 0 },
        transitionEnd: { display: "none" }
      }}
      animate={{
        x: transform?.x,
        y: transform?.y ?? y
      }}
      transition={{ type: "spring", stiffness: 500, mass: 0.1 }}
    >
      {children}
    </S.Item>
  );
};

export default Draggable;
