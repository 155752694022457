import React, { FC } from "react";
import { AvatarMultiple } from "src/js/components/AvatarMultiple";
import { Text } from "@arcadia/design-system";
import { translateString } from "src/js/translation/TranslationProvider";
import * as S from "./EventRecipientsList.styles";
import { EventRecipientsListProps } from "./EventRecipientsList.types";

const EventRecipientsList: FC<EventRecipientsListProps> = ({
  group_recipients,
  user_recipients,
  onClickItem
}) => {
  let names = [];
  let avatars = [];

  if (user_recipients && user_recipients.length > 0) {
    user_recipients.slice(0, 3).forEach(({ name, surname, avatar }) => {
      names = [...names, `${name} ${surname}`];
      avatars = [...avatars, avatar?.small];
    });
  } else {
    group_recipients.slice(0, 3).forEach(({ name, cover }) => {
      names = [...names, name];
      avatars = [...avatars, cover?.small];
    });
  }

  const maxNumber = Math.max(user_recipients.length, group_recipients.length);

  return (
    <S.EventRecipientsListWrap>
      <AvatarMultiple avatars={avatars} size="s" />
      <S.EventRecipientsNames>
        <Text type="formDescription">
          {names.join(", ")}{" "}
          {maxNumber === 4 && (
            <S.ShowMore
              onClick={onClickItem}
              dangerouslySetInnerHTML={{
                __html: translateString({
                  text: "event_detail_recipients_show_one_more"
                })
              }}
            />
          )}
          {maxNumber > 4 && (
            <S.ShowMore
              onClick={onClickItem}
              dangerouslySetInnerHTML={{
                __html: translateString({
                  text: "event_detail_recipients_show_more",
                  stringVariables: {
                    recipientsNumber: ` ${maxNumber - 3}`
                  }
                })
              }}
            />
          )}
        </Text>
      </S.EventRecipientsNames>
    </S.EventRecipientsListWrap>
  );
};

export default EventRecipientsList;
