import React, { useEffect } from "react";
import createUrl from "src/js/modules/routing";
import getUserClient from "src/js/modules/connection";
import {
  api_users_change_mail_confirm,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { navigateTo } from "Legacy/modules/history";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const UserConfirmMail = props => {
  const { match } = props;
  const { AuthStore } = useStores();
  const { greyTheme } = useTheme();
  useEffect(() => {
    getUserClient()
      .get(
        getVersionedApiUrl(
          api_users_change_mail_confirm({ token: match.params.token })
        )
      )
      .then(() => {
        if (!AuthStore.userIsLogged) {
          showToastSuccess({
            str: <Translate text="new_mail_confirmation_ok" />
          });
          navigateTo(createUrl("login"));
        } else {
          AuthStore.resetUser(() => {
            showToastSuccess({
              str: <Translate text="new_mail_confirmation_ok" />
            });
            navigateTo(createUrl("home"));
          });
        }
      })
      .catch(error => {
        showToastError({
          str: (
            <Translate
              text={extractErrorMessage(
                error.responseJSON || {},
                "Attenzione, si è verificato un errore"
              )}
            />
          )
        });
        navigateTo(createUrl("home"));
      });
  }, [match.params.token]);

  return <DoodleLoader theme={greyTheme} />;
};

export default observer(UserConfirmMail); // Todo Spostare l'intero flusso in pages/unlogged
