import { EditorContent } from "@tiptap/react";
import styled from "styled-components";
import { editableContentMixin } from "../TextEditorInput";

export const StyledEditableContentRead = styled(EditorContent)`
  .ProseMirror {
    ${editableContentMixin()}
    padding: 4px 0 0;
    @media screen and (max-width: 767px) {
      user-select: none;
    }
  }
`;
