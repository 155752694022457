import { Box } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { SpaceDurationCard } from "../SpaceDurationCard";
import { SpaceTrialCardProps } from "./SpaceTrialCard.types";

const SpaceTrialCard: FC<SpaceTrialCardProps> = ({ className }) => {
  const { translate } = useTranslation();
  const { SpaceStore } = useStores();
  const { activeSpace, isAdmin, activeSpaceId } = SpaceStore;

  if (!activeSpace?.isInTrial || !(activeSpace?.trialDaysLeft > 0)) {
    return null;
  }
  return (
    <Box className={className}>
      <SpaceDurationCard
        variant="primary"
        title={translate({
          text: "space_trial_card_title",
          stringVariables: { trialDays: activeSpace?.trialDaysLeft }
        })}
        link={isAdmin ? translate("space_trial_card_cta_redirect") : null}
        onClickLink={() => {
          if (!isAdmin) return;
          navigateTo(
            createUrl("dashboard_settings", {
              space_id: activeSpaceId,
              option: "plan"
            })
          );
        }}
        duration={activeSpace?.trialDaysLeft}
        durationType="days"
      />
    </Box>
  );
};

export default observer(SpaceTrialCard);
