import { LinkButton, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const ClaimableSpaceInfoContainer = styled(motion.div)`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.coldGrey[900]};
  padding: 8px 4px;
  margin-left: 140px;
  margin-top: -16px;
  max-width: fit-content;
  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 16px;
    word-break: break-word;
    min-width: 100%;
  }
`;

export const TextBody = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`;

export const TextAndCta = styled.div`
  color: ${({ theme }) => theme.colors.blueGrey[400]};
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 0 8px;
  margin-left: auto;
`;

export const StyledLinkButton = styled(LinkButton)`
  white-space: nowrap;
  display: inline;
  padding-left: 0;
  padding-right: 4px;
`;
