export const ChargebeeWidgetType = {
  SubscriptionDetails: "SUBSCRIPTION_DETAILS",
  SubscriptionCancellation: "SUBSCRIPTION_CANCELLATION",
  EditSubscription: "EDIT_SUBSCRIPTION",
  ViewScheduledChanges: "VIEW_SCHEDULED_CHANGES",
  AccountDetails: "ACCOUNT_DETAILS",
  EditAccountDetails: "EDIT_ACCOUNT_DETAILS",
  Address: "ADDRESS",
  EditBillingAddress: "EDIT_BILLING_ADDRESS",
  EditShippingAddress: "EDIT_SHIPPING_ADDRESS",
  EditSubscriptionCustomFields: "EDIT_SUBSCRIPTION_CUSTOM_FIELDS",
  PaymentSources: "PAYMENT_SOURCES",
  AddPaymentSource: "ADD_PAYMENT_SOURCE",
  EditPaymentSource: "EDIT_PAYMENT_SOURCE",
  ViewPaymentSource: "VIEW_PAYMENT_SOURCE",
  ChoosePaymentMethodForSubscription: "CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION",
  BillingHistory: "BILLING_HISTORY"
} as const;
export type ChargebeeWidgetType =
  (typeof ChargebeeWidgetType)[keyof typeof ChargebeeWidgetType];

export type ChargebeeCallbacksType = {
  close?: () => void;
  success?: () => void;
  error?: (error) => void;
  loaded?: () => void;
};
