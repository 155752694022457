import React from "react";
import { Box, Checkbox, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { BadgeRelatedGroupType } from "src/js/types";
import { groupGetCover } from "src/js/GroupFunction";
import handleThumbnails from "src/js/modules/imageFunction";
import { useTranslation } from "src/js/translation";
import * as S from "../styles";

const BadgeRelatedGroup = ({
  badgeRelatedGroup,
  badgeImage,
  isAssociated = false,
  isGroupRemoved,
  isSelected,
  selectGroup
}: {
  badgeRelatedGroup: BadgeRelatedGroupType;
  isGroupRemoved?: boolean;
  badgeImage?: string;
  isAssociated?: boolean;
  isSelected?: boolean;
  selectGroup?: (isChecked: boolean) => void;
}) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <S.BadgeRelatedGroup isActive={isSelected} withHover={!isAssociated}>
      <S.BadgeImageAndName>
        <S.GroupImage
          src={handleThumbnails(groupGetCover(badgeRelatedGroup), "medium")}
          alt="group-logo"
        />
        <Text type="headerTitle">{badgeRelatedGroup.name}</Text>
      </S.BadgeImageAndName>
      {isAssociated ? (
        <Box display="flex" alignItems="center" gap={16}>
          <S.GroupStatus isRemoved={isGroupRemoved}>
            <Text type="label">
              {isGroupRemoved
                ? translate("badge_association_new_group_removed")
                : translate("badge_association_new_group_added")}
            </Text>
          </S.GroupStatus>
          <S.BadgeImageInReview src={badgeImage} />
        </Box>
      ) : (
        <Checkbox
          theme={greyTheme}
          checked={isSelected}
          onChange={selectGroup}
        />
      )}
    </S.BadgeRelatedGroup>
  );
};

export default BadgeRelatedGroup;
