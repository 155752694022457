import * as icons from "../../assets/svg/iconsReactComponent";
import * as loader from "../../assets/svg/loader";

import { IconsMap } from "./types";

const {
  AddImage,
  AmbientScreen,
  Analytics,
  Apple,
  ActivityZone,
  ArbitrumChain,
  Arrow,
  ArrowLeft,
  ArrowLeftWLine,
  ArrowRight,
  ArrowRightWLine,
  ArrowTopRight,
  ArrowUp,
  Asterisk,
  Attachment,
  Back,
  Back2,
  Back3,
  Backpack,
  Badge,
  BadgeAppStore,
  BadgeGooglePlay,
  BadgeHuawei,
  BannerLong,
  BannerShort,
  BarChart,
  BellCrossed12,
  BellCrossed,
  BellEmpty,
  BellEmptyCrossed,
  BellEmptyWithUser,
  BellFull,
  BellFullCrossed,
  BellFullWithUser,
  BellWithUser,
  Birthday,
  Block,
  BoardEmpty,
  Board,
  Bold,
  Book,
  Briefcase,
  BulletedList,
  Calendar,
  CalendarFilled,
  Cards,
  Cart,
  Chat,
  ChatFilled,
  Chain,
  Check,
  Check2,
  CheckList,
  CheckBadge,
  CheckboxOutlineBlank,
  ChevronDown,
  ChevronLargeDown,
  ChevronLargeLeft,
  ChevronLargeRight,
  ChevronLargeUp,
  ChevronLeft,
  ChevronRight,
  ChevronSmallDown,
  ChevronSmallLeft,
  ChevronSmallRight,
  ChevronSmallUp,
  ChevronUp,
  CircleLoader,
  Clip,
  Clock,
  Close,
  CloseBold,
  Cloud,
  Code,
  CodeBlock,
  Comment,
  Compass,
  ContentGrid,
  CompassEmpty,
  Copy,
  CopyImport,
  Creditcard,
  Crown,
  CrownEmpty,
  Danger,
  DangerCircle,
  DangerTriangle,
  Delete,
  Desktop,
  DoubleA,
  Download,
  Drag,
  Edit,
  Emoji,
  EmojiEmpty,
  EmojiFull,
  EmojiPlus,
  ErrorCircle,
  Exclamation,
  Exercise,
  EyeClosed,
  EyeOpen,
  EyeOpened,
  EthereumChain,
  ExternalLink,
  Facebook,
  FacebookEmpty,
  File,
  Filter,
  FlagBadge,
  FloppySave,
  FolderFull,
  FolderZip,
  Forward,
  FourWayArrow,
  FrameSource,
  Gear,
  Globe,
  Google,
  GraduateCap,
  GridView,
  GridViewFilled,
  Group,
  Hamburger,
  HamburgerArrowLeft,
  HamburgerArrowRight,
  Heading1,
  Heading2,
  Heading3,
  TextFormat,
  Help,
  HelpCircle,
  Home,
  HomeFilled,
  HourHalf,
  IconGoogle,
  IllustrationActivateWhiteboard,
  IllustrationActivity,
  IllustrationBadge,
  IllustrationBusiness,
  IllustrationCreateNewGroup,
  IllustrationDeleteBadge,
  IllustrationGroup,
  IllustrationLive,
  IllustrationLogout,
  IllustrationOther,
  IllustrationSchool,
  IllustrationScribbleAirplane,
  IllustrationScribbleArrow,
  IllustrationScribbleBrackets,
  IllustrationScribbleClock,
  IllustrationScribbleFlag,
  IllustrationScribbleFlask,
  IllustrationScribbleGraphic,
  IllustrationScribbleLamp,
  IllustrationScribbleMolecules,
  IllustrationScribbleSets,
  IllustrationScribbleTurbolence,
  IllustrationScribbleZigzagLine,
  IllustrationStudent,
  IllustrationGhost,
  IllustrationTeacher,
  IllustrationTeacherTraining,
  IllustrationUnderage,
  IllustrationUniversity,
  IllustrationUploadError,
  IllustrationCreate,
  IllustrationDraw,
  IllustrationNoDataFolder,
  Image,
  Images2,
  Info,
  Instagram,
  Italic,
  Join,
  JoinInBox,
  Kebab,
  KebabVertical,
  LightBulb,
  Link,
  LinkDiagonal,
  List,
  ListView,
  Live,
  LocalActivity,
  LocalLibrary,
  Loading,
  LockClosed,
  LockOpen,
  Locked,
  Logout,
  LoveThought,
  MailAsterisk,
  MailClock,
  SubScript,
  SuperScript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  MailCrossed,
  MailFull,
  MailOutline,
  MailWithCalendar,
  ManageContent,
  MessageArrowFull,
  MessageBalloonFull,
  MetamaskFox,
  MicSolid,
  MicrophoneFull,
  Microsoft,
  Minus,
  NotificationCalendar,
  NotificationExercise,
  NotificationHighlights,
  NotificationLessons,
  NotificationMarketing,
  NotificationMuted,
  NotificationUnmuted,
  NotificationWall,
  OrderedList,
  OptimismChain,
  PaperPlane,
  PaperPlus,
  Path,
  Pencil,
  PencilFull,
  PickerDropbox,
  PickerExcel,
  PickerFile,
  PickerGoogle,
  PickerGoogledoc,
  PickerGoogledrive,
  PickerGoogledriveVertical,
  PickerGooglemaps,
  PickerGooglesheet,
  PickerGoogleslides,
  PickerGoogleforms,
  PickerImages,
  PickerPdf,
  PickerPowerpoint,
  PickerScorm,
  PickerScormWhite,
  PickerTestweschool,
  PickerTexteditor,
  PickerTexteditorWhite,
  PickerVimeo,
  PickerVimeoSingleChar,
  PickerWebpage,
  PickerWord,
  PickerYoutube,
  PickertestAnswerwithattachment,
  PickertestCompletesentence,
  PickertestCrosswordpuzzle,
  PickertestMatchcard,
  PickertestMatchpairs,
  PickertestMultipleanswers,
  PickertestOpenanswer,
  PickertestShortanswer,
  PickertestTruefalse,
  PickertestVideoquiz,
  Pin,
  Play,
  PlaylistRemove,
  PlayCircle,
  Plus,
  PlusThin,
  PlusInBox,
  PlusInCircle,
  PolygonChain,
  Print,
  Quote,
  PlusWithCard,
  RecordVoiceOver,
  Refresh,
  Reply,
  Search,
  Send,
  Shield,
  ShieldCrossed,
  ShieldPermissions,
  SixPoints,
  Sliders,
  Snowflake,
  Speedometer,
  SquareCheck,
  CircleCheck,
  Squares,
  Sparkles,
  Star,
  StarEmpty,
  Stopwatch,
  StopWatchSolid,
  Strikethrough,
  Stripe,
  StylusNote,
  SuccessCircle,
  Sunglasses,
  SwapVertical,
  TeacherRibbon,
  TeamDashboard,
  TestFlask,
  Thunderbolt,
  Tornado,
  Trash,
  TransferCards,
  Tune,
  Undo,
  University,
  Unlocked,
  UnorderedList,
  Upndown,
  Upload,
  User,
  UserEmpty,
  UserGroup,
  UserWithFlagBadge,
  VideoCamera,
  VideoCameraSolid,
  Camera,
  Invite,
  EditSecondary,
  Wallet,
  Extend,
  Shorten,
  Simplify,
  Summarise,
  Emojify,
  MicrophoneEmpty,
  AiMenu,
  Wand,
  Waves,
  Weight,
  Whatsapp,
  Document,
  Location,
  WindowPlus,
  InfoI,
  Error,
  Cancel,
  CheckCircle,
  Flag,
  ArrowDropDown,
  FolderZipVertical,
  ArrowFull,
  BookmarkStar,
  BrightnessEmpty,
  CancelOutline,
  ContentPaste,
  ControlPointDuplicate,
  School,
  StackStar,
  Workspaces,
  WsLogo
} = icons;

const {
  DoodleFrame1,
  DoodleFrame2,
  DoodleFrame3,
  DoodleFrame4,
  DoodleFrame5,
  DoodleFrame6
} = loader;

export const iconMap: IconsMap = {
  arrowFull: ArrowFull,
  addImage: AddImage,
  ambientScreen: AmbientScreen,
  analytics: Analytics,
  apple: Apple,
  arbitrumChain: ArbitrumChain,
  activityZone: ActivityZone,
  arrow: Arrow,
  arrowLeft: ArrowLeft,
  arrowLeftWLine: ArrowLeftWLine,
  arrowRight: ArrowRight,
  arrowRightWLine: ArrowRightWLine,
  arrowTopRight: ArrowTopRight,
  arrowUp: ArrowUp,
  asterisk: Asterisk,
  attachment: Attachment,
  back: Back,
  back2: Back2,
  back3: Back3,
  backpack: Backpack,
  badge: Badge,
  "badge-appStore": BadgeAppStore,
  "badge-googlePlay": BadgeGooglePlay,
  "badge-huawei": BadgeHuawei,
  bannerLong: BannerLong,
  bannerShort: BannerShort,
  barChart: BarChart,
  bellCrossed_12: BellCrossed12,
  bellCrossed: BellCrossed,
  bellEmpty: BellEmpty,
  bellEmptyCrossed: BellEmptyCrossed,
  bellEmptyWithUser: BellEmptyWithUser,
  bellFull: BellFull,
  bellFullCrossed: BellFullCrossed,
  bellFullWithUser: BellFullWithUser,
  bellWithUser: BellWithUser,
  birthday: Birthday,
  block: Block,
  "board-empty": BoardEmpty,
  board: Board,
  bold: Bold,
  book: Book,
  briefcase: Briefcase,
  bulletedList: BulletedList,
  calendar: Calendar,
  calendarFilled: CalendarFilled,
  cards: Cards,
  cart: Cart,
  chat: Chat,
  chain: Chain,
  chatFilled: ChatFilled,
  check: Check,
  check2: Check2,
  checkList: CheckList,
  checkBadge: CheckBadge,
  checkboxOutlineBlank: CheckboxOutlineBlank,
  chevronDown: ChevronDown,
  code: Code,
  codeBlock: CodeBlock,
  editSecondary: EditSecondary,
  invite: Invite,
  camera: Camera,
  chevronLargeDown: ChevronLargeDown,
  chevronLargeLeft: ChevronLargeLeft,
  chevronLargeRight: ChevronLargeRight,
  chevronLargeUp: ChevronLargeUp,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronSmallDown: ChevronSmallDown,
  chevronSmallLeft: ChevronSmallLeft,
  chevronSmallRight: ChevronSmallRight,
  chevronSmallUp: ChevronSmallUp,
  chevronUp: ChevronUp,
  circleLoader: CircleLoader,
  clip: Clip,
  clock: Clock,
  close: Close,
  closeBold: CloseBold,
  cloud: Cloud,
  comment: Comment,
  contentGrid: ContentGrid,
  compass: Compass,
  compassEmpty: CompassEmpty,
  copy: Copy,
  copyImport: CopyImport,
  creditcard: Creditcard,
  crown: Crown,
  crownEmpty: CrownEmpty,
  danger: Danger,
  dangerCircle: DangerCircle,
  dangerTriangle: DangerTriangle,
  delete: Delete,
  desktop: Desktop,
  doodleFrame1: DoodleFrame1,
  doodleFrame2: DoodleFrame2,
  doodleFrame3: DoodleFrame3,
  doodleFrame4: DoodleFrame4,
  doodleFrame5: DoodleFrame5,
  doodleFrame6: DoodleFrame6,
  doubleA: DoubleA,
  download: Download,
  drag: Drag,
  edit: Edit,
  emoji: Emoji,
  emojiEmpty: EmojiEmpty,
  emojiFull: EmojiFull,
  emojiPlus: EmojiPlus,
  errorCircle: ErrorCircle,
  ethereumChain: EthereumChain,
  exclamation: Exclamation,
  exercise: Exercise,
  eyeClosed: EyeClosed,
  eyeOpen: EyeOpen,
  eyeOpened: EyeOpened,
  externalLink: ExternalLink,
  facebook: Facebook,
  facebookEmpty: FacebookEmpty,
  file: File,
  filter: Filter,
  flagBadge: FlagBadge,
  floppySave: FloppySave,
  folderFull: FolderFull,
  folderZip: FolderZip,
  forward: Forward,
  fourWayArrow: FourWayArrow,
  frameSource: FrameSource,
  gear: Gear,
  globe: Globe,
  google: Google,
  graduateCap: GraduateCap,
  gridView: GridView,
  gridViewFilled: GridViewFilled,
  group: Group,
  hamburger: Hamburger,
  hamburgerArrowLeft: HamburgerArrowLeft,
  hamburgerArrowRight: HamburgerArrowRight,
  heading1: Heading1,
  heading2: Heading2,
  heading3: Heading3,
  textFormat: TextFormat,
  help: Help,
  helpCircle: HelpCircle,
  home: Home,
  homeFilled: HomeFilled,
  hourHalf: HourHalf,
  "icon-google": IconGoogle,
  "illustration-activateWhiteboard": IllustrationActivateWhiteboard,
  "illustration-activity": IllustrationActivity,
  "illustration-badge": IllustrationBadge,
  "illustration-delete-badge": IllustrationDeleteBadge,
  "illustration-business": IllustrationBusiness,
  "illustration-createNewGroup": IllustrationCreateNewGroup,
  "illustration-group": IllustrationGroup,
  "illustration-live": IllustrationLive,
  "illustration-logout": IllustrationLogout,
  "illustration-other": IllustrationOther,
  "illustration-school": IllustrationSchool,
  "illustration-scribble-airplane": IllustrationScribbleAirplane,
  "illustration-scribble-arrow": IllustrationScribbleArrow,
  "illustration-scribble-brackets": IllustrationScribbleBrackets,
  "illustration-scribble-clock": IllustrationScribbleClock,
  "illustration-scribble-flag": IllustrationScribbleFlag,
  "illustration-scribble-flask": IllustrationScribbleFlask,
  "illustration-scribble-graphic": IllustrationScribbleGraphic,
  "illustration-scribble-lamp": IllustrationScribbleLamp,
  "illustration-scribble-molecules": IllustrationScribbleMolecules,
  "illustration-scribble-sets": IllustrationScribbleSets,
  "illustration-scribble-turbolence": IllustrationScribbleTurbolence,
  "illustration-scribble-zigzagLine": IllustrationScribbleZigzagLine,
  "illustration-student": IllustrationStudent,
  "illustration-ghost": IllustrationGhost,
  "illustration-teacher": IllustrationTeacher,
  "illustration-teacher_training": IllustrationTeacherTraining,
  "illustration-underage": IllustrationUnderage,
  "illustration-university": IllustrationUniversity,
  "illustration-uploadError": IllustrationUploadError,
  "illustration-create": IllustrationCreate,
  "illustration-draw": IllustrationDraw,
  "illustration-no-data-folder": IllustrationNoDataFolder,
  image: Image,
  image2: Images2,
  info: Info,
  instagram: Instagram,
  italic: Italic,
  join: Join,
  joinInBox: JoinInBox,
  kebab: Kebab,
  kebabVertical: KebabVertical,
  lightBulb: LightBulb,
  link: Link,
  linkDiagonal: LinkDiagonal,
  list: List,
  listView: ListView,
  live: Live,
  localActivity: LocalActivity,
  localLibrary: LocalLibrary,
  loading: Loading,
  lockClosed: LockClosed,
  lockOpen: LockOpen,
  locked: Locked,
  logout: Logout,
  loveThought: LoveThought,
  mailAsterisk: MailAsterisk,
  mailClock: MailClock,
  subScript: SubScript,
  superScript: SuperScript,
  alignLeft: AlignLeft,
  alignCenter: AlignCenter,
  alignRight: AlignRight,
  mailCrossed: MailCrossed,
  mailFull: MailFull,
  mailOutline: MailOutline,
  mailWithCalendar: MailWithCalendar,
  manageContent: ManageContent,
  messageArrowFull: MessageArrowFull,
  messageBalloonFull: MessageBalloonFull,
  metamaskFox: MetamaskFox,
  micSolid: MicSolid,
  microphoneFull: MicrophoneFull,
  microsoft: Microsoft,
  minus: Minus,
  notificationCalendar: NotificationCalendar,
  notificationExercise: NotificationExercise,
  notificationHighlights: NotificationHighlights,
  notificationLessons: NotificationLessons,
  notificationMarketing: NotificationMarketing,
  notificationMuted: NotificationMuted,
  notificationUnmuted: NotificationUnmuted,
  notificationWall: NotificationWall,
  orderedList: OrderedList,
  optimismChain: OptimismChain,
  paperPlane: PaperPlane,
  paperPlus: PaperPlus,
  path: Path,
  pencil: Pencil,
  pencilFull: PencilFull,
  "picker-dropbox": PickerDropbox,
  "picker-excel": PickerExcel,
  "picker-file": PickerFile,
  "picker-google": PickerGoogle,
  "picker-googledoc": PickerGoogledoc,
  "picker-googledrive": PickerGoogledrive,
  "picker-googledrive-vertical": PickerGoogledriveVertical,
  "picker-googlemaps": PickerGooglemaps,
  "picker-googlesheet": PickerGooglesheet,
  "picker-googleslides": PickerGoogleslides,
  "picker-googleforms": PickerGoogleforms,
  "picker-images": PickerImages,
  "picker-pdf": PickerPdf,
  "picker-powerpoint": PickerPowerpoint,
  "picker-scorm": PickerScorm,
  "picker-scorm-white": PickerScormWhite,
  "picker-testweschool": PickerTestweschool,
  "picker-texteditor": PickerTexteditor,
  "picker-texteditor-white": PickerTexteditorWhite,
  "picker-vimeo": PickerVimeo,
  "picker-vimeo-single-char": PickerVimeoSingleChar,
  "picker-webpage": PickerWebpage,
  "picker-word": PickerWord,
  "picker-youtube": PickerYoutube,
  "pickertest-answerwithattachment": PickertestAnswerwithattachment,
  "pickertest-completesentence": PickertestCompletesentence,
  "pickertest-crosswordpuzzle": PickertestCrosswordpuzzle,
  "pickertest-matchcard": PickertestMatchcard,
  "pickertest-matchpairs": PickertestMatchpairs,
  "pickertest-multipleanswers": PickertestMultipleanswers,
  "pickertest-openanswer": PickertestOpenanswer,
  "pickertest-shortanswer": PickertestShortanswer,
  "pickertest-truefalse": PickertestTruefalse,
  "pickertest-videoquiz": PickertestVideoquiz,
  pin: Pin,
  play: Play,
  playlistRemove: PlaylistRemove,
  playCircle: PlayCircle,
  plus: Plus,
  plusThin: PlusThin,
  plusInBox: PlusInBox,
  plusInCircle: PlusInCircle,
  print: Print,
  plusWithCard: PlusWithCard,
  polygonChain: PolygonChain,
  quote: Quote,
  recordVoiceOver: RecordVoiceOver,
  reply: Reply,
  refresh: Refresh,
  search: Search,
  send: Send,
  shield: Shield,
  shieldCrossed: ShieldCrossed,
  shieldPermissions: ShieldPermissions,
  sixPoints: SixPoints,
  sliders: Sliders,
  snowflake: Snowflake,
  speedometer: Speedometer,
  squareCheck: SquareCheck,
  strikethrough: Strikethrough,
  circleCheck: CircleCheck,
  squares: Squares,
  sparkles: Sparkles,
  star: Star,
  starEmpty: StarEmpty,
  stopwatch: Stopwatch,
  stopWatchSolid: StopWatchSolid,
  stripe: Stripe,
  stylusNote: StylusNote,
  successCircle: SuccessCircle,
  sunglasses: Sunglasses,
  swapVertical: SwapVertical,
  teacherRibbon: TeacherRibbon,
  teamDashboard: TeamDashboard,
  testFlask: TestFlask,
  thunderbolt: Thunderbolt,
  tornado: Tornado,
  trash: Trash,
  transferCards: TransferCards,
  tune: Tune,
  undo: Undo,
  university: University,
  unlocked: Unlocked,
  upndown: Upndown,
  upload: Upload,
  unorderedList: UnorderedList,
  user: User,
  userEmpty: UserEmpty,
  userGroup: UserGroup,
  userWithFlagBadge: UserWithFlagBadge,
  videoCamera: VideoCamera,
  videoCameraSolid: VideoCameraSolid,
  wallet: Wallet,
  extend: Extend,
  shorten: Shorten,
  simplify: Simplify,
  summarise: Summarise,
  emojify: Emojify,
  microphoneEmpty: MicrophoneEmpty,
  aiMenu: AiMenu,
  wand: Wand,
  waves: Waves,
  weight: Weight,
  whatsapp: Whatsapp,
  document: Document,
  location: Location,
  windowPlus: WindowPlus,
  infoI: InfoI,
  error: Error,
  cancel: Cancel,
  checkCircle: CheckCircle,
  flag: Flag,
  arrowDropDown: ArrowDropDown,
  folderZipVertical: FolderZipVertical,
  bookmarkStar: BookmarkStar,
  brightnessEmpty: BrightnessEmpty,
  cancelOutline: CancelOutline,
  contentPaste: ContentPaste,
  controlPointDuplicate: ControlPointDuplicate,
  school: School,
  stackStar: StackStar,
  workspaces: Workspaces,
  wsLogo: WsLogo
};
