import styled, { css } from "styled-components";

export const CalendarAttachmentPickerContainer = styled.div`
  position: relative;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.title};
`;

export const CalendarAttachmentPickerButton = styled.button`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: 500;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.inputColor};
  border: 1px solid ${({ theme }) => theme.colors.accent3};
  background-color: ${({ theme }) => theme.colors.basicWhite};
  height: 40px;
  width: 40px;
  box-shadow: none;
  touch-action: manipulation;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    flex: 0 0 40px; // for maintaining width in safari
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.darkestGray};
    `}
`;

export const CalendarAttachmentPickerBody = styled.div`
  position: absolute;
  bottom: 55px;
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border: 1px solid ${({ theme }) => theme.colors.lightBlueGrey};
  border-radius: 6px;
  width: 100%;
  max-width: 380px;
  max-height: 400px;
  z-index: ${({ theme }) => theme.zIndex.comboBody};
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
  scrollbar-color: ${({ theme }) =>
    theme.colors.accent3 + theme.colors.accent4};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 25px;
    height: 25px;
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3};
    border: 10px solid transparent;
    background-clip: padding-box;
    border-radius: 13px;
    min-height: 60px;
    &:hover {
      background: ${({ theme }) => theme.colors.accent2};
      background-clip: padding-box;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.accent4};
    border: 10px solid transparent;
    background-clip: padding-box;
    border-radius: 13px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const CalendarAttachmentPickerElement = styled.div`
  height: 55px;
  padding: 10px;
  border-radius: 6px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    cursor: pointer;
  }
`;

export const InternalResourceLabelContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
`;

export const InternalResourceLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const OptionName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DropdownBodyLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-transform: uppercase;
  margin: 10px 10px 0;
`;
