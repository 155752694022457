import styled, { css } from "styled-components";

export const Card = styled.div`
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}

  @media screen and (max-width: 767px) {
    padding-right: 10px;
  }
`;

export const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.success[500]};
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 2px;
`;
