import {
  Box,
  Button,
  Dropdown,
  Heading,
  Link,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import {
  convertPriceInteger,
  getCurrencySymbol
} from "src/js/modules/priceFunction";
import { PLAN_BILLING_PERIOD } from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesSettingsPlan/CreatePlanForm/CreatePlanForm.const";
import { PlanCard } from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesSettingsPlan/PlanCard";
import {
  PlanCardChip,
  PlanCardChipType
} from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesSettingsPlan/PlanCard/PlanCardChip";
import { TranslationKeys, useTranslation } from "src/js/translation";
import {
  BillingFrequencyType,
  PlanType,
  SubscriptionStatusType
} from "src/js/types";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { chargebeeCheckout } from "../../ChargebeeUtils/ChargebeeUtils";
import { ChargebeeModal } from "../ChargebeeModal";
import * as S from "./CreatePlanForm.styles";

const handleChip = ({ planId, mostPopular, hasWrongSeatsNumber }) => {
  if (hasWrongSeatsNumber) {
    return <PlanCardChip type={PlanCardChipType.WrongSeatsNumber} />;
  }
  if (planId === mostPopular) {
    return <PlanCardChip type={PlanCardChipType.MostPopular} />;
  }
  return null;
};

const handleSubscriptionStatus = ({
  subscriptionStatus
}: {
  subscriptionStatus: SubscriptionStatusType;
}) => {
  switch (subscriptionStatus) {
    case SubscriptionStatusType.InTrial:
      return PlanCardChipType.TrialPeriod;
    case SubscriptionStatusType.Cancelled:
      return PlanCardChipType.PlanCancelled;
    default:
      return PlanCardChipType.PlanActive;
  }
};

const hasNoSeatsInOptions = ({ seats, seatsOptions }) => {
  if (!seats) return false;
  const seatsIndex = seatsOptions.findIndex(element => element.seats === seats);
  return seatsIndex < 0;
};

const CreatePlanForm = () => {
  const {
    ModalStore: { openModal, closeModal },
    SpacesSettingsStore,
    UIStore,
    SpaceStore
  } = useStores();
  const { isLayoutModeMobile } = UIStore;
  const { activeSpace, setActiveSpaceTrialStatus } = SpaceStore;
  const {
    getSpaceSettings,
    spacePlansOptions,
    generalSettings: { planSettings }
  } = SpacesSettingsStore;
  const { plans, seatTiers, mostPopular } = spacePlansOptions || {};
  const { planPriceCurrency, subscriptionStatus } = planSettings || {};
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const [planSeats, setPlanSeats] = useState<unknown>(null);
  const [planBilling, setPlanBilling] = useState<BillingFrequencyType>(null);
  const [planType, setPlanType] = useState<PlanType>(null);

  const successCallback = () => {
    getSpaceSettings({
      spaceId: activeSpace?.space?.id,
      checkSubscription: true
    })
      .then(() => {
        setActiveSpaceTrialStatus({ isInTrial: false, trialDaysLeft: null });
      })
      .catch(() => {
        showToastError({
          str: translate("spaces_settings_checkout_reload_error")
        });
      });
  };

  const handlePlanPrice = useCallback(
    ({ seats, billing, seatsOptions, currency }) => {
      if (seatsOptions.length < 1) return "";
      const selectedPrice = seatsOptions.find(
        element => element.seats === seats
      );
      const currencySymbol = getCurrencySymbol(currency);
      // if there is no corresponding price return basic price
      const fromString = seats
        ? ""
        : translate({
            text: "space_settings_plan_billing_from"
          });

      if (!selectedPrice) {
        if (billing === BillingFrequencyType.Yearly) {
          return `${fromString} ${translate({
            text: "space_settings_plan_price",
            stringVariables: {
              amount: convertPriceInteger(seatsOptions[0].priceYearly),
              currency: currencySymbol,
              planBilling: billing
            }
          })}`;
        }

        return `${fromString} ${translate({
          text: "space_settings_plan_price",
          stringVariables: {
            amount: convertPriceInteger(seatsOptions[0].priceMonthly),
            currency: currencySymbol,
            planBilling: billing
          }
        })}`;
      }

      if (billing === BillingFrequencyType.Yearly) {
        return `${translate({
          text: "space_settings_plan_price",
          stringVariables: {
            amount: convertPriceInteger(selectedPrice.priceYearly),
            currency: currencySymbol,
            planBilling: billing
          }
        })}`;
      }

      return `${translate({
        text: "space_settings_plan_price",
        stringVariables: {
          amount: convertPriceInteger(selectedPrice.priceMonthly),
          currency: currencySymbol,
          planBilling: billing
        }
      })}`;
    },
    []
  );

  const handleClick = planId => {
    if (!planSeats || !planBilling) {
      showToastError({ str: translate("space_settings_plan_select_error") });
    } else {
      setPlanType(planId);
    }
  };

  const openChargebeeModal = () => {
    openModal(() => (
      <ChargebeeModal
        callbackChargebeeWidget={callbacks => {
          chargebeeCheckout({
            activeSpaceId: activeSpace?.space?.id,
            planType,
            planSeats: planSeats as number,
            planBilling,
            planPriceCurrency,
            callbacks: {
              close() {
                closeModal();
              },
              success() {
                successCallback();
              },
              ...callbacks
            }
          });
        }}
        close={closeModal}
      />
    ));
  };

  return (
    <>
      <S.FormTitle>
        <Heading level="4">
          {translate({
            text: "space_settings_plan_creation_title"
          })}
        </Heading>
        <PlanCardChip
          type={handleSubscriptionStatus({ subscriptionStatus })}
          stringVariables={{ days: activeSpace?.trialDaysLeft }}
        />
      </S.FormTitle>
      <S.DropdownHeader>
        <Box flex="1">
          <S.DropdownLabel type="formTitle">
            {translate({
              text: "space_settings_plan_edit_seats_label"
            })}
          </S.DropdownLabel>
          <Dropdown
            id="seats"
            theme={whiteTheme}
            optionsList={
              seatTiers?.map(option => ({
                id: option,
                label: option
              })) || []
            }
            selectedOptionId={planSeats as string}
            setSelectedOptionId={id => {
              setPlanSeats(id);
            }}
            placeholder={translate({
              text: "space_settings_plan_edit_seats_placeholder"
            })}
          />
        </Box>
        <Box flex="1">
          <S.DropdownLabel type="formTitle">
            {translate({
              text: "space_settings_plan_edit_billing_label"
            })}
          </S.DropdownLabel>
          <Dropdown
            id="billings"
            theme={whiteTheme}
            optionsList={PLAN_BILLING_PERIOD.map(option => ({
              id: option.id,
              label: translate({
                text: option.label as TranslationKeys
              })
            }))}
            selectedOptionId={planBilling}
            setSelectedOptionId={id => {
              setPlanBilling(id as BillingFrequencyType);
            }}
            placeholder={translate({
              text: "space_settings_plan_edit_billing_placeholder"
            })}
          />
        </Box>
      </S.DropdownHeader>
      <S.Divider />
      <S.PlanCardList>
        {plans.map(plan => {
          const hasWrongSeatsNumber = hasNoSeatsInOptions({
            seats: planSeats,
            seatsOptions: plan.seatsOptions
          });
          if (hasWrongSeatsNumber && planType === plan.id) {
            setPlanType(null);
          }
          return (
            <PlanCard
              key={plan.id}
              titleLabel={translate({
                text: "space_settings_plan_info_name",
                stringVariables: { planType: plan.id }
              })}
              priceLabel={handlePlanPrice({
                seats: planSeats,
                billing: planBilling,
                seatsOptions: plan.seatsOptions,
                currency: planPriceCurrency
              })}
              chip={handleChip({
                planId: plan.id,
                mostPopular,
                hasWrongSeatsNumber
              })}
              isSelected={planType === plan.id}
              disabled={hasWrongSeatsNumber}
              onClick={() => handleClick(plan.id)}
            >
              <S.PlanCardBody>
                <Text type="body">
                  {translate({
                    text: "space_settings_plan_explanation",
                    stringVariables: { planId: plan.id }
                  })}
                </Text>
                <Link
                  theme={whiteTheme}
                  href={translate({
                    text: "space_settings_plan_read_more_cta"
                  })}
                  target="_blank"
                  onClick={event => event.stopPropagation()}
                >
                  {translate({
                    text: "space_settings_plan_read_more"
                  })}
                </Link>
              </S.PlanCardBody>
            </PlanCard>
          );
        })}
      </S.PlanCardList>
      <Box flex="1" display="flex" flexDirection="column" alignItems="flex-end">
        <Button
          onClick={() => {
            openChargebeeModal();
            _trackEvent("Checkout", "CheckoutAccessPoint");
          }}
          theme={whiteTheme}
          variant="primary"
          fullWidth={isLayoutModeMobile}
          disabled={!planSeats || !planBilling || !planType}
        >
          {translate({
            text: "space_settings_plan_checkout"
          })}
        </Button>
      </Box>
    </>
  );
};

export default observer(CreatePlanForm);
