import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Icon,
  DropdownMultiple,
  ContextualAlert
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { filterBoards } from "src/js/repository/shareRepository";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { PrerequisiteMenuItem } from "../../PrerequisiteMenuItem";
import * as ToggleBaseS from "../EditBoardToggleElement/EditBoardToggleElement.styles";
import * as S from "./EditBoardConstraintsSection.style";

const LIMIT = 50;

const EditBoardConstraintsSection = ({
  selectedItem = [],
  onAddBoardConstraint,
  onRemoveBoardConstraint,
  groupId,
  boardId
}) => {
  const [isOpen, setOpen] = useState(!!selectedItem.length);
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  useEffect(() => {
    if (!groupId) {
      return;
    }

    setPage(1);
    setHasNext(false);
    filterBoards(query, 0, LIMIT, groupId).then(res => {
      setItems(res.boards);
      setHasNext(res.has_next);
    });
  }, [query, groupId, boardId]);

  function onNext() {
    filterBoards(query, LIMIT * page, LIMIT, groupId).then(res => {
      setItems(items.concat(res.boards));
      setHasNext(res.has_next);
      setPage(page + 1);
    });
  }

  function setSearchQuery(e) {
    setQuery(e.target.value);
  }
  const onToggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const parsedOptions = useMemo(() => {
    if (!items) {
      return [];
    }

    return items
      .map(item => ({
        ...item,
        key: item.id,
        label: item.name,
        isDisabled: item.is_open || item.id === boardId
      }))
      .sort((a, b) => {
        // Sort disabled items at the end
        if (a.isDisabled) {
          return 1;
        }
        if (b.isDisabled) {
          return -1;
        }
        return 0;
      });
  }, [items, boardId]);

  const selectedOptions = useMemo(() => {
    return selectedItem
      .map(id => {
        return parsedOptions.find(item => item.id === id.id);
      })
      .filter(constraint => constraint);
  }, [selectedItem, parsedOptions]);

  const selectedOptionsIds = useMemo(() => {
    return selectedItem.map(id => {
      return parsedOptions.find(item => item.id === id.id)?.id;
    });
  }, [selectedItem, parsedOptions]);

  const hasDraftConstraint = useMemo(() => {
    if (!selectedOptions) {
      return false;
    }
    return selectedOptions.find(constraint => !constraint?.is_published);
  }, [selectedOptions]);

  function onRemoveElements(id) {
    return () => {
      onRemoveBoardConstraint(id);
    };
  }

  function handleChange(_newConstraints) {
    const newConstraints = _newConstraints.map(constraint => {
      if (typeof constraint === "number") {
        return parsedOptions.find(item => item.id === constraint);
      }
      return constraint;
    });

    const selectedIds = selectedItem.map(a => a.id);

    selectedIds.forEach(id => {
      if (!newConstraints.find(constraint => constraint.id === id)) {
        return onRemoveBoardConstraint(id);
      }
      return undefined;
    });

    newConstraints.forEach(id => {
      const constraint = parsedOptions.find(item => item.id === id.id);

      if (!selectedIds.includes(id.id)) {
        return onAddBoardConstraint(constraint);
      }
      return undefined;
    });
  }

  return (
    <S.LayoutWrapper>
      <S.Layout $isOpen={isOpen} onClick={onToggle}>
        <ToggleBaseS.IconContainer isOpen={isOpen}>
          <Icon icon="path" height={16} width={16} />
        </ToggleBaseS.IconContainer>
        <ToggleBaseS.TextContainer>
          <ToggleBaseS.Title>
            {translate("add_prerequisite_sidebar_title")}
          </ToggleBaseS.Title>
          <ToggleBaseS.Description>
            {translate("add_prerequisite_sidebar_description")}
          </ToggleBaseS.Description>
        </ToggleBaseS.TextContainer>
        <S.ChevronWrapper>
          {isOpen ? (
            <Icon icon="chevronSmallUp" height={16} width={16} />
          ) : (
            <Icon icon="chevronSmallDown" height={16} width={16} />
          )}
        </S.ChevronWrapper>
      </S.Layout>
      <S.Content $isOpen={isOpen}>
        <DropdownMultiple
          theme={whiteTheme}
          optionsList={parsedOptions}
          selectedOptionIds={selectedOptionsIds}
          setSelectedOptionIds={handleChange}
          placeholder={translate(
            "add_prerequisite_sidebar_dropdown_placeholder"
          )}
          searchPlaceholder={translate(
            "board_module_prerequisites_search_placeholder"
          )}
          hideButtonTags
          hasNext={hasNext}
          showNext={onNext}
          scrollableListId="prerequisite-dropdown-multiple"
          searchable
          searchQuery={query}
          onSearchFunction={setSearchQuery}
        />
        <S.SelectedItemsWrapper>
          {selectedOptions.map(item => (
            <PrerequisiteMenuItem
              key={item.id}
              title={item.name}
              isDraft={!item.is_published}
              color={item.color}
              onDismiss={onRemoveElements(item.id)}
            />
          ))}

          {hasDraftConstraint && (
            <S.DraftWarning>
              <ContextualAlert
                theme={whiteTheme}
                text={translate("add_prerequisite_sidebar_draft_warning")}
              />
            </S.DraftWarning>
          )}
        </S.SelectedItemsWrapper>
      </S.Content>
    </S.LayoutWrapper>
  );
};

export default EditBoardConstraintsSection;
