import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { Text } from "@ds_universal/Text";

import { RadioButtonProps, SRadioGroupProps, SRadioLabelProps } from "./types";

export const RadioGroup = styled.div<SRadioGroupProps>`
  display: flex;
  gap: 12px;
  ${({ orientationColumn }) =>
    orientationColumn &&
    css`
      flex-flow: column;
      gap: 8px;
    `}
`;

const Input = styled.input`
  all: unset;
  margin: 0 !important; //overrides some default css radio button styling on arcadia
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: ${({ theme }) => theme.radiogroup.background};
  background-clip: content-box;
  padding: 4px;
  border-radius: 50%;
  border: ${({ theme }) => theme.radiogroup.border};
  cursor: pointer;

  & + span {
    color: ${({ theme }) => theme.radiogroup.text};
  }

  &:checked {
    background-color: ${({ theme }) => theme.radiogroup.checked.background};
    border: ${({ theme }) => theme.radiogroup.checked.border};
  }

  &:hover {
    background-color: ${({ theme }) => theme.radiogroup.hover.background};
  }

  &:focus-visible {
    outline: ${({ theme }) => theme.radiogroup.focus.border};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme, checked }) =>
      checked
        ? theme.radiogroup.disabled.checked.background
        : theme.radiogroup.disabled.background};
    border: ${({ theme }) => theme.radiogroup.disabled.border};
  }

  &:disabled + span {
    color: ${({ theme }) => theme.radiogroup.disabled.text};
  }
`;

const Label = styled.label<SRadioLabelProps>`
  all: unset;
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  gap: ${({ orientationColumn }) => (orientationColumn ? "12px" : "4px")};

  ${({ orientationRight }) =>
    !orientationRight &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
    `}
`;

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      id = "default",
      name = "default",
      label = "default",
      value,
      checked = false,
      disabled = false,
      onChange,
      orientationLabel,
      orientationColumn,
      parentId = "",
      textType = "cta"
    },
    ref
  ) => {
    const idProp = `${id}${parentId}`;

    return (
      <Label
        htmlFor={idProp}
        disabled={disabled}
        orientationRight={orientationLabel === "right"}
        orientationColumn={orientationColumn}
      >
        <Input
          id={idProp}
          type="radio"
          role="radio"
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          ref={ref}
        />
        <Text type={textType}>{label}</Text>
      </Label>
    );
  }
);

RadioButton.displayName = "RadioButton";
