import styled from "styled-components";

export const SpaceGroupsLayoutWrapper = styled.div``;

export const SpaceGroupsHeaderWrapper = styled.div<{ topHeaderHeight: number }>`
  width: 100%;
  position: sticky;
  top: ${({ topHeaderHeight }) => `${topHeaderHeight}px`};
  z-index: ${({ theme }) => theme.zIndex.topHeader - 3};
`;
