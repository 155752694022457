import colors from "@ds_themes/colors";
import { ButtonThemeOptions } from "@ds_themes/types";

export const primary: ButtonThemeOptions = {
  background: colors.violet[501],
  text: colors.grey[1000],
  type: "cta",
  border: `1px solid ${colors.violet[501]}`,
  hover: {
    background: colors.grey[1000],
    text: colors.violet[501]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  },
  disabled: {
    background: colors.violet[700],
    text: colors.grey[1000],
    border: `1px solid ${colors.violet[700]}`
  }
};

export const secondary: ButtonThemeOptions = {
  background: colors.grey[1000],
  text: colors.grey[50],
  type: "cta",
  border: `1px solid ${colors.grey[50]}`,
  hover: {
    background: colors.grey[50],
    text: colors.grey[1000]
  },
  focus: {
    border: `${colors.grey[700]} solid 3px`
  },
  disabled: {
    background: colors.grey[1000],
    text: colors.grey[800],
    border: `1px solid ${colors.grey[800]}`
  }
};

export const tertiary: ButtonThemeOptions = {
  background: colors.yellow[500],
  text: colors.grey[50],
  type: "cta",
  border: `1px solid ${colors.yellow[500]}`,
  hover: {
    background: colors.yellow[800],
    text: colors.grey[50]
  },
  focus: {
    border: `${colors.yellow[800]} solid 3px`
  },
  disabled: {
    background: colors.yellow[900],
    text: colors.grey[700],
    border: `1px solid ${colors.yellow[900]}`
  }
};

export const danger: ButtonThemeOptions = {
  background: colors.error[600],
  text: colors.grey[1000],
  type: "cta",
  border: `1px solid ${colors.error[600]}`,
  hover: {
    background: colors.grey[1000],
    text: colors.error[600]
  },
  focus: {
    border: `${colors.error[700]} solid 3px`
  },
  disabled: {
    background: colors.error[700],
    text: colors.grey[1000],
    border: `1px solid ${colors.error[700]}`
  }
};
