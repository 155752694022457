import { Box, Icon, Text, ToolbarMenuItem } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import useUnreadThreadMessagesCounter from "src/js/hooks/useUnreadThreadMessagesCounter";
import createUrl from "src/js/modules/routing";
import { getAppRoutingContext } from "src/js/routing/appRoutingContext";
import { ChatEvent, EventDomain } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { TranslationKeys, useTranslation } from "src/js/translation";

/**
 * @name ChatButtonItem
 *
 * @description the item to handle the chat button inside the toolbar
 */

const ChatButtonItem = () => {
  const location = useLocation();
  const context = getAppRoutingContext(location);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    SpaceStore: { activeSpace, isDemoSpace, isClaimable },
    UIStore: { isLayoutModeMobile },
    ChatStore: { threadsList }
  } = useStores();

  const { hasInSpaceUnreadMessages } = useUnreadThreadMessagesCounter();

  const goToChat = () => {
    _trackEvent(EventDomain.Chat, ChatEvent.ChatStartHomeClick);

    const spaceSlug = activeSpace.space.slug;
    if (isLayoutModeMobile) {
      navigateTo(
        createUrl("space_chats", {
          space_slug: spaceSlug
        })
      );
      return;
    }

    if (threadsList.length > 0) {
      navigateTo(
        createUrl("chat_thread", {
          space_slug: spaceSlug,
          thread_id: threadsList[0].id
        })
      );
    } else {
      navigateTo(createUrl("chat_new", { space_slug: spaceSlug }));
    }
  };

  const getDisabledLabel = (): TranslationKeys => {
    if (isDemoSpace) {
      return "toolbar_chat_button_disabled_demo_space_tooltip_text";
    }
    if (activeSpace?.isTemporary) {
      return "toolbar_chat_button_disabled_temporary_space_tooltip_text";
    }
    if (isClaimable)
      return "toolbar_chat_button_disabled_claimable_space_tooltip_text";
    return "" as TranslationKeys;
  };

  const isDisabledButton =
    !activeSpace || isDemoSpace || activeSpace?.isTemporary || isClaimable;

  return (
    <TranslatedTooltip
      placement="right"
      customTooltip={
        <Box
          maxWidth="160px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="12px 16px"
        >
          <Text type="table">{translate(getDisabledLabel())}</Text>
        </Box>
      }
      forceHide={!isDisabledButton || !activeSpace || isLayoutModeMobile}
    >
      <div>
        <ToolbarMenuItem
          icon={<Icon icon="chat" height={24} width={24} />}
          onClick={goToChat}
          theme={whiteTheme}
          id="toolbarChatLink"
          isSelected={context === "chat"}
          isDisabled={isDisabledButton && !isLayoutModeMobile}
          hasEvent={hasInSpaceUnreadMessages()}
        />
      </div>
    </TranslatedTooltip>
  );
};

export default observer(ChatButtonItem);
