import QuizModel from "./quizModel";

class QuizDocumentAnswerModel extends QuizModel {
  getFormattedUserAnswer() {
    const userAnswer = this.getUserAnswer();
    const comment = userAnswer.value;
    const resourceId = userAnswer.resource;
    return [{ index: 0, value: comment, resource: { id: resourceId } }];
  }
  checkUserAnswer() {
    return true; // per ora sempre true
  }
  needsReview() {
    return true;
  }
  isFullyAnswered() {
    const answer = this.getUserAnswer();
    return answer && answer.resource !== 0 && answer.value !== "";
  }
}

export default QuizDocumentAnswerModel;
