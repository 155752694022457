import { Chip } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { PLAN_CARD_CHIP_TYPES } from "./PlanCardChip.const";
import { PlanCardChipProps } from "./PlanCardChip.types";

const PlanCardChip: FC<PlanCardChipProps> = ({ type, stringVariables }) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const planCardChip = PLAN_CARD_CHIP_TYPES[type];
  return (
    <Chip variant={planCardChip.variant} theme={whiteTheme}>
      {translate({ text: planCardChip.label, stringVariables })}
    </Chip>
  );
};

export default PlanCardChip;
