import styled, { css } from "styled-components";

export const PostActionButton = styled.button<{ isActive?: boolean }>`
  all: unset;
  width: 100%;
  border-radius: 8px;
  max-width: 116px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  gap: 8px;
  padding: 0px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[900]};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primaryColor[500]};
    `}
`;
