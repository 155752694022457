import { Text } from "@arcadia/design-system";
import React from "react";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { GroupSection } from "src/js/types";
import { GROUP_SECTION } from "src/js/types/enums/Group";
import styled from "styled-components";

const SectionPillWrapper = styled.div`
  height: 28px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border-radius: 100px;
  width: fit-content;
`;

const sectionTitle: Record<GroupSection, TranslationKeys> = {
  [GROUP_SECTION.WALL]: "section_pill_wall",
  [GROUP_SECTION.MODULES]: "section_pill_modules",
  [GROUP_SECTION.ASSESSMENTS]: "section_pill_assessments",
  [GROUP_SECTION.BOARD]: "section_pill_board",
  [GROUP_SECTION.REPORT]: "section_pill_report",
  [GROUP_SECTION.CALENDAR]: "section_pill_calendar"
};

const SectionPill = ({ section }: { section: GroupSection }) => {
  const { translate } = useTranslation();
  return (
    <SectionPillWrapper>
      <Text type="formSmallCaption">{translate(sectionTitle[section])}</Text>
    </SectionPillWrapper>
  );
};

export default SectionPill;
