import { ConfirmationAlert } from "@arcadia/design-system";
import React, { useCallback, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { useStores, useUnsavedChangesWarning } from "src/js/hooks";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

type Props = {
  shouldBlock: boolean;
  promptText: TranslationKeys;
  acceptText: TranslationKeys;
  declineText: TranslationKeys;
  onNavigateCb?: (path: string) => void;
};

const PreventNavigation = ({
  shouldBlock,
  promptText,
  acceptText,
  declineText,
  onNavigateCb
}: Props) => {
  const [newLocation, setNewLocation] = useState<string>(null);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const history = useHistory();
  const {
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();

  const [preventBrowserActions, enableBrowserActions] =
    useUnsavedChangesWarning(promptText);

  useEffect(() => {
    if (shouldBlock) {
      preventBrowserActions();
    } else {
      enableBrowserActions();
    }
  }, [shouldBlock]);

  const closePrompt = useCallback(() => {
    closeConfirmDialog();
    setNewLocation(null);
  }, []);

  useEffect(() => {
    if (shouldNavigate) {
      history.push(newLocation);
      onNavigateCb?.(newLocation);
      setShouldNavigate(false);
      closePrompt();
    }
  }, [shouldNavigate]);

  const onChangePath = useCallback(
    (nextLocation: string) => {
      if (shouldNavigate) return true;
      setNewLocation(nextLocation);
      return false;
    },
    [shouldNavigate]
  );

  useEffect(() => {
    if (!newLocation) return;
    openConfirmDialog(
      <ConfirmationAlert
        acceptText={translate(acceptText)}
        declineText={translate(declineText)}
        onAcceptFunction={() => {
          setShouldNavigate(true);
        }}
        onDeclineFunction={closePrompt}
        theme={whiteTheme}
        text={translate(promptText)}
      />
    );
  }, [newLocation]);

  return (
    <Prompt
      when={shouldBlock}
      message={location => {
        return onChangePath(location.pathname);
      }}
    />
  );
};

export default PreventNavigation;
