import { Icon, Text } from "@arcadia/design-system";
import { format } from "date-fns";
import React, { useMemo, useRef, useState } from "react";
import TimeAgo from "react-timeago";
import { useStores } from "src/js/hooks";
import { timeFormatter } from "src/js/modules/commonFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import {
  getDateFnsLocale,
  TranslationKeys,
  useTranslation
} from "src/js/translation";
import {
  AreaIcon,
  NotificationAreaLabel,
  NotificationModel
} from "src/js/types/models/Notification";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { useNotificationsParameters } from "../../hooks";
import { getNotificationTypeFromArea } from "../../NotificationPage.utils";

import * as S from "./Notification.styles";

type NotificationProps = {
  notification?: NotificationModel;
  loading?: boolean;
  onMarkRead?: (notificationId: string) => void;
  onMarkUnread?: (notificationId: string) => void;
};

const Notification = ({
  notification,
  loading,
  onMarkRead,
  onMarkUnread
}: NotificationProps) => {
  const [hover, setHover] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const ref = useRef<HTMLDivElement>(null);
  const { groupId } = useNotificationsParameters();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const hasGroup = useMemo(() => !!groupId, [groupId]);

  const titleText = useMemo(() => {
    if (!notification || !notification.text) return "";
    return hasGroup
      ? `${notification?.sender?.name} ${notification?.sender?.surname}`
      : translate({
          text: "notification_drawer_space_notification_title",
          stringVariables: { groupName: notification?.group?.name }
        });
  }, [notification?.text, hasGroup]);

  const buttonText = useMemo<TranslationKeys>(
    () =>
      notification?.isRead
        ? "notification_set_unread_button_text"
        : "notification_set_read_button_text",
    [notification?.isRead]
  );

  const contentText = useMemo(() => {
    if (!notification || !notification.text) return "";
    const text = hasGroup
      ? notification?.text
          .replace(
            `${notification?.sender.name} ${notification?.sender.surname}`,
            ""
          )
          .trim()
      : notification?.text.trim();
    return [text[0].toUpperCase(), ...text.slice(1)].join("");
  }, [notification?.text, hasGroup]);

  const onClick = () => {
    if (loading) return;
    if (!notification.isRead && onMarkRead) onMarkRead(notification?.id);
    navigateTo(notification.redirectUrl.replace("#", ""));
  };

  const onHoverStart = () => setHover(true);
  const onHoverEnd = () => setHover(false);

  const toggleNotificationStatus = async (
    e: React.MouseEvent<HTMLDivElement>,
    isRead: boolean
  ) => {
    e.stopPropagation();
    if (isRequestInProgress) return;

    const action = isRead ? onMarkUnread : onMarkRead;
    if (!action) return;

    setIsRequestInProgress(true);
    try {
      await action(notification.id);
    } catch (err) {
      console.error("Error marking notification status", err);
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return loading ? (
    <S.NotificationWrapper loading>
      <S.Skeleton width={40} height={40} borderRadius="50%" />
      <S.TextColumn>
        <S.Skeleton width={115} height={16} marginBottom={2} borderRadius={4} />
        <S.Skeleton width={170} height={16} marginTop={2} borderRadius={4} />
      </S.TextColumn>
      <S.TimeColumn>
        <S.Skeleton width={40} height={8} borderRadius={4} />
        <S.Skeleton width={30} height={16} borderRadius={4} />
      </S.TimeColumn>
    </S.NotificationWrapper>
  ) : (
    <S.NotificationWrapper
      ref={ref}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      onClick={onClick}
    >
      <S.AvatarContainer rounded={hasGroup}>
        <S.AvatarImage
          theme={whiteTheme}
          url={
            hasGroup
              ? notification?.sender?.avatar?.small
              : (notification?.group?.cover?.small ?? __GROUP_COVER_DEFAULT__)
          }
          rounded={false}
          size="m"
          alt={`${notification?.sender?.name} ${notification?.sender?.surname}`}
        />
        <S.NotificationType
          area={getNotificationTypeFromArea(notification?.area)}
        >
          <Icon
            icon={
              AreaIcon[notification?.area] ||
              AreaIcon[NotificationAreaLabel.Other]
            }
            width={8}
            height={8}
            initialViewbox
          />
        </S.NotificationType>
      </S.AvatarContainer>
      <S.TextColumn>
        <S.TitleRow>
          {!notification?.isRead && <S.UnreadDot />}
          <Text type="formTitle">{titleText}</Text>
        </S.TitleRow>
        <S.SubtitleText type="formSmallCaption">{contentText}</S.SubtitleText>
      </S.TextColumn>
      {/* TODO: Handle Click */}
      <S.TimeColumn
        onClick={e => toggleNotificationStatus(e, notification?.isRead)}
      >
        <S.TimeText>
          {hover && !isLayoutModeMobile ? (
            translate(buttonText)
          ) : (
            <TimeAgo
              title={format(
                notification?.updatedAt * 1000,
                "EEEE d MMM yyyy HH:mm:ss",
                { locale: getDateFnsLocale(getCurrentLanguage()) }
              )}
              date={notification?.updatedAt * 1000}
              formatter={(value, unit, suffix) =>
                timeFormatter(value, unit, suffix, translate)
              }
            />
          )}
        </S.TimeText>
        {isLayoutModeMobile && <Icon icon="kebab" width={16} height={16} />}
      </S.TimeColumn>
    </S.NotificationWrapper>
  );
};

export default Notification;
