import React, { FC } from "react";
import * as S from "./ScrollableDiv.styles";
import { ScrollableDivProps } from "./ScrollableDiv.types";

const ScrollableDiv: FC<ScrollableDivProps> = ({
  id,
  maxHeight,
  className,
  children,
  ...props
}) => {
  return (
    <S.ScrollableDivStyle
      id={id}
      maxHeight={maxHeight}
      {...props}
      className={className}
    >
      {children}
    </S.ScrollableDivStyle>
  );
};

export default ScrollableDiv;
