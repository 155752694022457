import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { __ } from "../../modules/localization";
import { InternalDispatcher } from "../../modules/dispatcher";
import ExerciseModel from "../../models/exerciseModel";
import ExerciseDraftItem from "src/js/pages/exercise/ExerciseDraftItem";

const ExercisesDraftList = withBackbone(
  createReactClass({
    getInitialState() {
      return {
        exercisesList: this.props.exercisesList
      };
    },
    componentDidMount() {
      InternalDispatcher.on("change:ratio", this.updateBP);
    },
    componentWillReceiveProps(nextProps) {
      const self = this;
      self.setState({
        exercisesList: nextProps.exercisesList
      });
    },
    componentWillUnmount() {
      InternalDispatcher.off("change:ratio", this.updateBP);
    },
    updateBP() {
      this.forceUpdate();
    },
    render() {
      const self = this;
      let list = "";
      const exercisesList = this.state.exercisesList;
      let exercise = {};

      if (exercisesList.length > 0) {
        list = exercisesList.map(exercisePar => (
          <ExerciseDraftItem exercise={exercisePar.attributes} />
        ));

        return <div>{list}</div>;
      }
      const messageText = __("msg_no_drafts_available");
      exercise = new ExerciseModel({
        title: "Primo esercizio"
      });
      return (
        <div>
          <div className="relative">
            <div className="box-placeholder-wrapper">
              <span className="box-placeholder-wrapper-text">
                {messageText}
              </span>
            </div>
            <div className="blurred">
              <ExerciseDraftItem exercise={exercise.attributes} />
            </div>
          </div>
        </div>
      );
    }
  })
);

export default ExercisesDraftList;
