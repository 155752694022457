import React, { forwardRef } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";

import * as S from "./Checkbox.styles";
import { CheckboxProps } from "./types";
import BareCheckbox from "./BareCheckbox";

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      theme,
      value = "checkbox",
      label,
      onChange,
      checked,
      disabled,
      id = "checkbox",
      name = "checkbox",
      tabIndex,
      labelDirection = "right",
      "data-testid": testId,
      variant,
      ...rest
    },
    ref
  ) => {
    return (
      <ThemeProvider theme={theme}>
        <S.Container
          labelDirection={labelDirection}
          variant={variant}
          disabled={disabled}
          {...rest}
        >
          <BareCheckbox
            checked={checked}
            value={value}
            disabled={disabled}
            onChange={e => onChange?.(e.target.checked)}
            onClick={e => {
              if (e.target instanceof HTMLInputElement) {
                onChange?.(e.target.checked);
              }
            }}
            id={id}
            aria-checked={checked}
            ref={ref}
            name={name}
            tabIndex={tabIndex}
            testId={testId}
            variant={variant}
          />
          <label htmlFor={id} tabIndex={-1}>
            {typeof label === "string" ? (
              <Text type="cta">{label}</Text>
            ) : (
              label
            )}
          </label>
        </S.Container>
      </ThemeProvider>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
