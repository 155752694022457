import React from "react";
import * as S from "./CommentSubtitle.styles";

const CommentSubtitle = ({ children, testId, ...props }) => {
  return (
    <S.CommentSubtitleStyle data-testid={testId} {...props}>
      {children}
    </S.CommentSubtitleStyle>
  );
};

export default CommentSubtitle;
