import { shadow } from "@arcadia/design-system";
import styled from "styled-components";

export const Wrapper = styled.ul`
  list-style: none;
  position: absolute;
  top: 24px;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.coldGrey[900]};
  box-shadow: ${shadow[200]};
  padding: 8px;
  margin: 0px;
  min-width: fit-content;
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 1rem !important;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3} !important;
    border-radius: 1rem !important;
    transition: 300ms ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.accent2} !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    max-height: 260px;
  }
`;

export const Option = styled.li`
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;
