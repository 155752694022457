import { ResourcePickerSectionType } from "src/js/components/ResourcePicker/ResourcePicker.types";

type ValueOf<T> = T[keyof T];

export const FileType = {
  PDF: "pdf",
  FILE: "file",
  SPREADSHEET: "excel",
  DOCUMENT: "word",
  SLIDE: "powerpoint",
  SCORM: "scorm",
  IMAGE: "image",
  VIDEO: "video"
} as const;

export type FileUploaderProps = {
  type?: ValueOf<typeof FileType>;
  multiple?: boolean;
  section: ResourcePickerSectionType;
  prepareModuleResourcePayload?: (
    fileList: FileList,
    isScorm?: boolean
  ) => void;
  onClose?: () => void;
};
