import React, { forwardRef, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";

import * as S from "./WrapperChatAvatar.styles";
import { WrapperChatAvatarProps } from "./types";

const WrapperChatAvatar = forwardRef<
  HTMLDivElement,
  PropsWithChildren<WrapperChatAvatarProps>
>(
  (
    {
      theme,
      fullName,
      body,
      children,
      state = "default",
      avatar,
      onClick,
      swipeHandlers = {},
      muted,
      muteActionText,
      unreadMessagesCount
    },
    ref
  ) => {
    return (
      <ThemeProvider theme={theme}>
        <S.Wrapper>
          <S.WrapperChatAvatar
            state={state}
            onClick={onClick}
            {...swipeHandlers}
            ref={ref}
          >
            {avatar}
            <S.WrapperText state={state}>
              <S.TextAndIconContainer>
                <S.FullName type="subHeaderSubtitle">{fullName}</S.FullName>
                <S.MutedIconDesktopContainer>
                  {muted ? (
                    <Icon
                      icon="bellCrossed_12"
                      width="12px"
                      height="12px"
                      viewBox="0 0 12 12"
                    />
                  ) : null}
                </S.MutedIconDesktopContainer>
              </S.TextAndIconContainer>
              <S.MessagePreview type="formSmallCaption">
                {body}
              </S.MessagePreview>
            </S.WrapperText>
            <S.TimeAndUnreadContainer hasUnreadMessages={!!unreadMessagesCount}>
              <S.WrapperTime state={state}>
                <Text type="formSmallCaption">{children}</Text>
              </S.WrapperTime>
              <S.UnreadMessagesWrapper>
                <S.MutedIconMobileContainer
                  hasUnreadMessages={!!unreadMessagesCount}
                >
                  {muted ? (
                    <Icon icon="bellCrossed" height="14px" width="14px" />
                  ) : null}
                </S.MutedIconMobileContainer>
                {!!unreadMessagesCount && (
                  <S.UnreadMessages type="table">
                    {unreadMessagesCount}
                  </S.UnreadMessages>
                )}
              </S.UnreadMessagesWrapper>
            </S.TimeAndUnreadContainer>
          </S.WrapperChatAvatar>
          <S.BellContainer>
            <S.IconTextContainer>
              <Icon
                icon={muted ? "bellEmpty" : "bellCrossed"}
                height="22px"
                width="22px"
              />
              <Text type="formSmallCaption">{muteActionText}</Text>
            </S.IconTextContainer>
          </S.BellContainer>
        </S.Wrapper>
      </ThemeProvider>
    );
  }
);

export default WrapperChatAvatar;

WrapperChatAvatar.displayName = "WrapperChatAvatar";
