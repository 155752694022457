import React, { useEffect } from "react";
import { Editor } from "@tiptap/core";
import { useStores } from "src/js/hooks";
import { Icon, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { observer } from "mobx-react";

import { useAITransformer } from "../AITransformer/useAITransformer";
import * as S from "./AITransformerModal.styles";

const AITransformerModal = ({ editor }: { editor: Editor }) => {
  const { items, aiTransformerProps, isSubMenu, onGoBack } =
    useAITransformer(editor);
  const { translate } = useTranslation();

  const {
    ModalStore: { closeModal }
  } = useStores();

  useEffect(() => {
    editor.commands.blur();
  }, []);

  return (
    <ModalLayout
      closeModal={isSubMenu ? onGoBack : closeModal}
      labels={{
        title: translate("ai_editor_transformation_modal_title"),
        mobileClose: isSubMenu ? translate("back") : translate("close")
      }}
    >
      {!isSubMenu && (
        <S.TitleWrapper>
          <Text type="formField">
            {translate("ai_editor_transformation_modal_tools")}
          </Text>
        </S.TitleWrapper>
      )}
      {items.map(item => (
        <S.AITransformation
          key={aiTransformerProps[item].title}
          onClick={aiTransformerProps[item].action}
        >
          {aiTransformerProps[item].leadingIcon && (
            <S.IconWrapper>
              <Icon
                icon={aiTransformerProps[item].leadingIcon}
                width={16}
                height={16}
                initialViewbox
              />
            </S.IconWrapper>
          )}
          <S.TextWrapper>
            <S.TransformationName type="formField">
              {translate(aiTransformerProps[item].title)}
            </S.TransformationName>
            {/* {aiTransformerProps[item].subtitle && (
              <S.TransformationDescription type="formFieldEmpty">
                {translate(aiTransformerProps[item].subtitle)}
              </S.TransformationDescription>
            )} */}
          </S.TextWrapper>
          {aiTransformerProps[item].trailingIcon && (
            <S.TrailingIconWrapper>
              <Icon
                icon={aiTransformerProps[item].trailingIcon}
                width={16}
                height={16}
                initialViewbox
              />
            </S.TrailingIconWrapper>
          )}
        </S.AITransformation>
      ))}
    </ModalLayout>
  );
};

export default observer(AITransformerModal);
