import styled from "styled-components";

export const SoundWave = styled.div`
  display: flex;
  align-items: center;
`;

export const Bar = styled.div`
  background: red;
  border-radius: 1px;
  margin: 0.3px;
  width: 0.7px;
  animation: sound 0ms -800ms linear infinite alternate;

  @keyframes sound {
    0% {
      height: 1px;
    }
    100% {
      height: 8px;
    }
  }

  &:nth-child(1) {
    left: 0px;
    animation-duration: 474ms;
  }

  &:nth-child(2) {
    animation-duration: 433ms;
  }

  &:nth-child(3) {
    animation-duration: 407ms;
  }

  &:nth-child(4) {
    animation-duration: 458ms;
  }

  &:nth-child(5) {
    animation-duration: 400ms;
  }

  &:nth-child(6) {
    animation-duration: 427ms;
  }

  &:nth-child(7) {
    animation-duration: 441ms;
  }
`;
