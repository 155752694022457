import { Palette } from "@ds_themes/types";

export const createPictureUploaderTheme = ({ primary, general }: Palette) => {
  return {
    pictureUploader: {
      label: general.contrastText,
      background: primary.contrastText,
      color: primary.main,
      border: `1px dashed ${primary.main}`,
      opacityImg: 0.3,
      focus: {
        background: primary.main,
        color: primary.contrastText,
        border: `1px solid ${primary.main}`
      }
    }
  };
};
