import { colors } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledActionsText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.grey[1000]};
  a {
    position: relative;
    color: ${colors.yellow[500]};
    text-decoration: inherit;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${colors.yellow[500]};
      opacity: 0;
      transition:
        opacity 150ms,
        transform 150ms;
      opacity: 1;
      transform: scale(0);
      transform-origin: center;
    }
    &:hover:after,
    &:focus:after {
      transform: scale(1);
    }
  }
`;
