import React from "react";
import styled from "styled-components";
import { __SUPPORT_MAIL__ } from "src/js/settings/settingsGeneral";
import { Translator } from "src/js/translation/TranslationProvider";
import { Text } from "@arcadia/design-system";

const StyledLink = styled.div`
  a {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

const UnloggedSupportLink = ({ className = "" }) => {
  return (
    <Translator>
      {({ translate }) => {
        return (
          <Text type="formSubtitle">
            <StyledLink>
              <div
                className={["unlogged-support-link", className].join(" ")}
                dangerouslySetInnerHTML={{
                  __html: translate("support_label_new", {
                    support_mail: __SUPPORT_MAIL__
                  })
                }}
              />
            </StyledLink>
          </Text>
        );
      }}
    </Translator>
  );
};

export default UnloggedSupportLink;
