import { FilterBar } from "src/js/components/FilterBar";
import { TagsCarousel } from "src/js/components/FilterBar/FilterBar.styles";
import styled from "styled-components";

export const FiltersContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  max-width: 400px;
  margin-top: 24px;

  @media (max-width: 768px) {
    max-width: 768px;
  }
`;

export const DropdownContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    max-width: 768px;
    width: 100%;
  }
`;

export const DropdownLabelContainer = styled.div`
  align-self: center;
  margin-right: 24px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const DropdownFilterContainer = styled.div`
  align-self: center;
  flex: 1;
  max-width: 228px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1 0 50%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledFilterBar = styled(FilterBar)`
  @media (max-width: 767px) {
    ${TagsCarousel} {
      > div {
        max-width: 200px;
        svg {
          width: 24px;
        }
        > span {
          width: max-content;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
    }
  }
`;
