import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "src/js/translation";
import { Label } from "@arcadia/design-system";
import { useTheme } from "styled-components";

import { DotsMenu } from "src/js/components/DotsMenu";
import { DefaultPanel } from "src/js/components/dashboard/DefaultPanel";
import * as S from "./BadgePanel.styles";
import { BadgePanelProps } from "./types";

const BadgePanel = ({
  spaceBadge,
  duration,
  menuItems,
  openGroupsModal,
  openUsersModal
}: BadgePanelProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { image, name, isPrivate, associatedGroupsCount, awardedUsersCount } =
    spaceBadge;

  return (
    <DefaultPanel height={80}>
      <S.InfoContainer>
        <S.BadgeDetails>
          <S.BadgeImageContainer>
            <S.BadgeImage src={image} alt={`${name} logo`} />
          </S.BadgeImageContainer>
          <S.BadgeTitle>{name}</S.BadgeTitle>
        </S.BadgeDetails>
        <S.BadgeInfo>
          <S.BadgeInfoColumn>
            {isPrivate ? (
              <div data-testid="privateLabel">
                <Label theme={whiteTheme} color="lightOrange" outline={false}>
                  {translate("dashboard_badge_private_label")}
                </Label>
              </div>
            ) : (
              <div data-testid="publicLabel">
                <Label theme={whiteTheme} color="lightGreen" outline={false}>
                  {translate("dashboard_badge_public_label")}
                </Label>
              </div>
            )}
          </S.BadgeInfoColumn>
          <S.BadgeInfoColumn>
            {associatedGroupsCount ? (
              <S.ActionLabel data-testid="openGroups" onClick={openGroupsModal}>
                {translate("dashboard_badge_groups_label", {
                  groupCount: associatedGroupsCount
                })}
              </S.ActionLabel>
            ) : (
              <u>
                {translate("dashboard_badge_groups_label", {
                  groupCount: associatedGroupsCount
                })}
              </u>
            )}
          </S.BadgeInfoColumn>
          <S.BadgeInfoColumn>
            {awardedUsersCount ? (
              <S.ActionLabel data-testid="openUsers" onClick={openUsersModal}>
                {translate("dashboard_badge_users_label", {
                  userCount: awardedUsersCount
                })}
              </S.ActionLabel>
            ) : (
              <u>
                {translate("dashboard_badge_users_label", {
                  userCount: awardedUsersCount
                })}
              </u>
            )}
          </S.BadgeInfoColumn>
          <S.BadgeInfoColumn data-testid="duration">
            {duration}
          </S.BadgeInfoColumn>
          <S.BadgeActions>
            <DotsMenu id="DotsMenuDesktop" items={menuItems} alignRight />
          </S.BadgeActions>
        </S.BadgeInfo>
      </S.InfoContainer>
    </DefaultPanel>
  );
};

export default observer(BadgePanel);
