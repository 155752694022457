import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import {
  ActionButton,
  Box,
  Button,
  Heading,
  Icon,
  Text
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import {
  __MAINNET_CHAIN_ICONS__,
  __MAINNET_CHAIN_IDS__
} from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/const";
import useAwardedBadges from "./hooks/useAwardedBadges";
import * as S from "./styles";

const AwardedBadges = ({
  requestForSpecificSpace
}: {
  requestForSpecificSpace?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getAwardedBadges, hideAwardedBadge } = useAwardedBadges();
  const {
    SpaceBadgesStore: { userAwardedBadges, updateUserAwardedBadges },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  useEffect(() => {
    getAwardedBadges(requestForSpecificSpace);

    return () => updateUserAwardedBadges([]);
  }, []);

  const handleExpand = () => {
    const el = document.getElementById("awarded-badges-container");
    if (el) {
      el.classList.toggle("groups-invitations-expand");
      el.scrollTo({ top: 0 });
    }
    setIsExpanded(!isExpanded);
  };

  const groupsThreshold = isLayoutModeMobile ? 1 : 2;

  const handleBadgeImage = (awardedNFT: boolean, chainId: string) => {
    if (awardedNFT) {
      const tooltipString = `${translate(
        "emit_nft_to_students_selected_chain_in_footer",
        { chainId: __MAINNET_CHAIN_IDS__[chainId]?.toLowerCase() }
      )}`;
      if (__MAINNET_CHAIN_ICONS__[chainId]) {
        return (
          <TranslatedTooltip
            tooltipString={tooltipString as TranslationKeys}
            placement="top"
          >
            <S.NFTChainIconContainer>
              <Icon
                icon={__MAINNET_CHAIN_ICONS__[chainId]}
                width={12}
                height={12}
                initialViewbox
              />
            </S.NFTChainIconContainer>
          </TranslatedTooltip>
        );
      }
      return (
        <S.NFTLabelContainer>
          <Text type="label">NFT</Text>
        </S.NFTLabelContainer>
      );
    }
    return null;
  };

  return Array.isArray(userAwardedBadges) && userAwardedBadges.length ? (
    <>
      <S.AwardedBadgesContainer id="awarded-badges-container">
        {userAwardedBadges.map(badge => (
          <S.AwardedBadge key={badge.id}>
            <S.BadgeImageContainer>
              <Box position="relative">
                {handleBadgeImage(!!badge.nftReference, badge.chainId)}
                <S.BadgeImage src={badge.image} alt="badge-logo" />
              </Box>
              {isLayoutModeMobile ? (
                <Heading level="6">{badge.name}</Heading>
              ) : null}
            </S.BadgeImageContainer>
            <S.BadgeDetails>
              {isLayoutModeMobile ? null : (
                <Heading level="5">{badge.name}</Heading>
              )}
              <S.BadgeDescription type="formSubtitle">
                {badge.description}
              </S.BadgeDescription>
            </S.BadgeDetails>
            <S.ButtonsContainer>
              <S.NavigateButton
                theme={greyTheme}
                variant="primary"
                onClick={() => {
                  window.open(badge.openBadgePublicUrl, "_blank");
                  hideAwardedBadge(badge.id, requestForSpecificSpace);
                }}
              >
                {translate("user_awarded_badge_navigate_cta")}
              </S.NavigateButton>
              {isLayoutModeMobile ? (
                <Button
                  theme={greyTheme}
                  variant="secondary"
                  onClick={() =>
                    hideAwardedBadge(badge.id, requestForSpecificSpace)
                  }
                >
                  {translate("close")}
                </Button>
              ) : (
                <ActionButton
                  icon="close"
                  theme={greyTheme}
                  variant="secondary"
                  onClick={() =>
                    hideAwardedBadge(badge.id, requestForSpecificSpace)
                  }
                />
              )}
            </S.ButtonsContainer>
          </S.AwardedBadge>
        ))}
        {!isExpanded && userAwardedBadges.length > groupsThreshold && (
          <S.BlurRow />
        )}
      </S.AwardedBadgesContainer>

      {userAwardedBadges.length > groupsThreshold && (
        <Box textAlign="center" onClick={handleExpand} marginBottom={16}>
          <S.ViewMore type="textLink">
            {translate(
              isExpanded
                ? "user_awarded_badges_view_less"
                : "user_awarded_badges_view_more"
            )}
          </S.ViewMore>
        </Box>
      )}
    </>
  ) : null;
};

export default observer(AwardedBadges);
