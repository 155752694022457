// eslint-disable-next-line no-shadow
export enum Role {
  Teacher = "ROLE_TEACHER",
  Creator = "ROLE_CREATOR",
  Student = "ROLE_STUDENT"
}

export interface Avatar {
  small: string;
  medium: string;
  large: string;
  originalUrl: string;
}

export interface Member {
  email: string;
  id: number;
  uuid: string;
  name: string;
  surname: string;
  birthday: string;
  roles: Role[];
  joined_at: number;
  avatar?: Avatar;
}

export interface PaginatedMembers {
  limit: number;
  offset: number;
  total: number;
  results: Member[];
}

export interface MemberNonAdmin {
  username: string;
  id: number;
  uuid: string;
  name: string;
  surname: string;
  birthday: string;
  avatar?: Avatar;
  roles: Role[];
}
