import { Box, Heading, skeletonAnimationMixin } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const ResourceViewerWrap = styled.div`
  height: 100vh;
  width: 100vw;
  height: 100dvh;
  width: 100dvw;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
`;

export const WhiteHeader = styled(Heading)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const StyledHeader = styled.div<{ backgroundColor: string }>`
  height: 48px;
  overflow: hidden;
  display: block;
  background: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
`;

export const BaseStyle = css`
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.violet[300]};
  background-color: ${({ theme }) => theme.colors.violet[800]};
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.violet[300]};
  }
`;

export const StyledOptionButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-right: 12px;
  align-self: center;
  height: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BaseStyle}
`;

export const ResourceWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  width: 100%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  overflow: hidden;
`;

export const StyledTextWrapper = styled.div`
  font-size: 16px;
  color: ${({ color }) => color};
  line-height: 48px;
  padding: 0 12px;
  height: 48px;
  display: flex;
`;

export const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-top: 1px solid ${({ theme }) => theme.colors.grey[900]};
  height: 52px;
  width: 100%;
  text-align: center;
  padding: 0px 12px;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  align-items: center;
`;

export const CommentsToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResourceViewerBody = styled.div`
  height: -webkit-calc(100% - 48px);
  height: calc(100% - 48px);
  overflow: hidden;
  display: flex;
`;

export const StyledDropdownButton = styled.button`
  all: unset;
`;

export const Skeleton = styled(Box)`
  ${({ theme }) =>
    skeletonAnimationMixin(theme.primaryColor[700], theme.primaryColor[800])};
`;
