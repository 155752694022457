import { Model } from "backbone";
import { createURL } from "../modules/utility";

class RankingModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    this.urlRoot = createURL("api_group_ranking");
  }

  initialize() {
    this.set("name", "");
  }
  getUser() {
    return this.get("user") || {};
  }
  getPosition() {
    return this.get("position") || 0;
  }
  getScore() {
    return this.get("score") || 0;
  }
  getMove() {
    return this.get("move") || 0;
  }
}

export default RankingModel;
