import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import find from "lodash/find";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import moment from "moment";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { Translate } from "src/js/translation/TranslationProvider";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { getExerciseIconString } from "src/js/modules/exercisesFunction";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { capitalizeFirstLetter } from "../../../modules/utility";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";

const BaseCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: null,
        execution: null,
        quiz_execution: null,
        corrector: null,
        number: 0,
        isVideoSubquiz: false
      };
    },
    render() {
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const quiz = tempQuizCollection.at(0);
      const { quiz_execution, execution } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();

      const quizType = quiz.getType();
      const quizIcon = getExerciseIconString(quizType);

      let subQuizImage = null;
      if (this.props.isVideoSubquiz) {
        subQuizImage = <Icon icon={quizIcon} width="60" height="60" />;
      }

      let result_copy = "";

      const isCorrect = execution.checkQuizIsCorrectById(quiz.get("id"));
      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));
      const answersGiven = map(questions, question => {
        let risposta = find(userAnswers, { index: question.index });
        let soluzione = find(solutions, { index: question.index });

        risposta = risposta === undefined ? "" : risposta.value.toString();
        soluzione = soluzione === undefined ? "" : soluzione.value.toString();

        /** check if answer is composed only by underscores or is empty */
        if (risposta.match(/_+$/g) || risposta == "") {
          risposta = __("quiz_not_answered_label");
        }

        let quiz_class = "is-wrong";
        if (risposta.toLowerCase() === soluzione.toLowerCase()) {
          quiz_class = "is-correct";
        }

        return (
          <tr>
            <td>
              <div className="correction_answer-item">
                <strong>{question.value}</strong>
              </div>
            </td>
            <td>
              <div className="correction_answer-item">{soluzione}</div>
            </td>
            <td>
              <div className="correction_answer-item">
                <span
                  className={["exen_summary_quiz_label", quiz_class].join(" ")}
                >
                  {risposta}
                </span>
              </div>
            </td>
          </tr>
        );
      });

      if (isCorrect) {
        result_copy = __("corretta");
      } else {
        result_copy = __("errata");
      }

      let quizTitle = quiz.getTitle();
      if (quizType === "quizcrosswords")
        quizTitle = capitalizeFirstLetter(__("quizcrosswords").toLowerCase());

      const executionData = execution.get("updated_at");
      const executionDataFormatted = moment
        .unix(executionData)
        .format("DD/MM/YYYY HH:mm");
      const executionFromNow = moment.unix(executionData).fromNow();

      let voteType;
      if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon icon={quizIcon} width="60" height="60" />;
              <ColorLabel type={voteType}>
                <strong>
                  {quiz_execution.points}/{quiz.getAvailablePoints()}
                </strong>{" "}
                <Translate text="quiz_points_label" />
              </ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  {subQuizImage}
                  <ExerciseQuizTitle quizTitle={quizTitle} />
                </div>
              </div>
              <div className="correction_answers-wrapper unselectable">
                <div className="answer_info margin-bottom-10">
                  <CommentTitle>
                    {__("quiz_report_reply_of_label")}&nbsp;
                    {execution.getQuizExecutioner()}
                  </CommentTitle>
                  <div className="margin-left-10 margin-left-xs-0">
                    <CommentSubtitle>
                      {`${executionDataFormatted} (${executionFromNow})`}
                    </CommentSubtitle>
                  </div>
                </div>
                <table className="table table-condensed table-borderless small">
                  <tbody>
                    <tr>
                      <th>{__("domanda")}</th>
                      <th>{__("risposta corretta")}</th>
                      <th>{__("risposta utente")}</th>
                    </tr>
                    {answersGiven}
                  </tbody>
                </table>
              </div>
              {this.props.corrector}
            </div>
          </Col>
        </>
      );
    }
  })
);

export default BaseCorrectionDisplayer;
