import colors from "@ds_themes/colors";

export const promobanner = {
  background: colors.yellow[500],
  color: colors.grey[50],
  link: colors.violet[500],
  button: {
    outline: colors.violet[700]
  }
};
