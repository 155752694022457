import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import indexOf from "lodash/indexOf";
import map from "lodash/map";
import filter from "lodash/filter";
import renderLatex from "src/js/modules/mathjaxFunction";
import { Text, Icon } from "@arcadia/design-system";

const QuizMultiChoice = withBackbone(
  createReactClass({
    componentDidMount() {
      renderLatex();
    },
    setAnswer(answer) {
      let answers = this.props.quiz.getUserAnswer() || [];
      if (indexOf(answers, answer) >= 0) {
        // c'è già, rimuovo
        answers = filter(answers, item => item !== answer);
      } else {
        // aggiungo
        answers.push(answer);
      }
      this.props.quiz.setUserAnswer(answers);
      this.forceUpdate();
    },
    render() {
      const self = this;
      const { quiz } = this.props;
      const answers = this.props.quiz.getUserAnswer();
      return (
        <div className="quiz__multichoice mathjax-container" ref="answersForm">
          {map(quiz.getQuestions(), question => {
            const i = question.index;
            const q = question.value;
            let checkboxBtn = "";
            if (indexOf(answers, q) >= 0) {
              checkboxBtn = (
                <div
                  className={[
                    "clickable",
                    "quiz__multichoice-option",
                    "is-active"
                  ].join(" ")}
                  onClick={self.setAnswer.bind(null, q)}
                  data-index={i}
                  data-question={q}
                >
                  <div className="quiz__multichoice-option-check is-active">
                    <Icon
                      icon="check"
                      width={20}
                      height={20}
                      viewbox="0 0 10 10"
                    />
                  </div>
                  <Text type="formField">{q}</Text>
                </div>
              );
            } else {
              checkboxBtn = (
                <div
                  className={["clickable", "quiz__multichoice-option"].join(
                    " "
                  )}
                  onClick={self.setAnswer.bind(null, q)}
                  data-index={i}
                  data-question={q}
                >
                  <div className="quiz__multichoice-option-check " />
                  <Text type="formField">{q}</Text>
                </div>
              );
            }
            return checkboxBtn;
          })}
        </div>
      );
    }
  })
);

export default QuizMultiChoice;
