import { Text } from "@arcadia/design-system";
import React, { FC, MouseEventHandler } from "react";
import {
  useResourceMediaFetcher,
  useResourcePreviewDetails
} from "src/js/hooks/resources";
import { parseResourceNameAsServer } from "src/js/modules/resourceFunction";
import { ObjectValues, Resource } from "src/js/types";
import ResourceAssetPreview from "../ResourceAssetPreview";
import * as S from "./ResourceItemPreview.styles";
import { ResourceCircleBtn } from "./components";

export const ResourceItemPreviewVariant = {
  Card: "card",
  Image: "image"
} as const;

export type ResourceItemPreviewVariant = ObjectValues<
  typeof ResourceItemPreviewVariant
>;

export type ResourceItemPreviewProps = {
  className?: string;
  loading?: boolean;
  resource?: Resource;
  variant?: ResourceItemPreviewVariant;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDelete?: (id: number) => void;
};

const ResourceItemPreview: FC<ResourceItemPreviewProps> = ({
  className,
  loading,
  resource,
  variant,
  onClick,
  onDelete
}) => {
  const { getResourceTypeLabel } = useResourcePreviewDetails();
  const { uuid, id, name, type, fileMimeType } = resource || {};
  const nameLabel = parseResourceNameAsServer(name.replace(`${uuid}_`, ""));
  const typeLabel = getResourceTypeLabel(type, fileMimeType);

  const { isMediaResource, mediaPublicUrl } = useResourceMediaFetcher(resource);

  const isLoading = (isMediaResource && !mediaPublicUrl) || loading;

  if (variant === ResourceItemPreviewVariant.Image) {
    if (isLoading) {
      return (
        <S.ResourceItemMedia isLoading={isLoading} className={className}>
          <S.ResourceAsset>
            <ResourceAssetPreview isLoading />
          </S.ResourceAsset>
          <ResourceCircleBtn isLoading />
        </S.ResourceItemMedia>
      );
    }
    return (
      <S.ResourceItemMedia
        isLoading={isLoading}
        onClick={onClick}
        className={className}
      >
        <S.ResourceAsset>
          <ResourceAssetPreview
            resource={resource}
            isMediaResource={isMediaResource}
            mediaPublicUrl={mediaPublicUrl}
          />
        </S.ResourceAsset>
        <ResourceCircleBtn
          hasDelete={typeof onDelete === "function"}
          onDelete={e => {
            if (onDelete) {
              onDelete(id);
              e.stopPropagation();
            }
          }}
        />
      </S.ResourceItemMedia>
    );
  }

  if (isLoading) {
    return (
      <S.ResourceItemCard isLoading={isLoading} className={className}>
        <S.ResourceAsset>
          <ResourceAssetPreview isLoading />
        </S.ResourceAsset>
        <S.ResourceContent>
          {nameLabel ? (
            <S.ResourceTitle>{nameLabel}</S.ResourceTitle>
          ) : (
            <S.SkeletonResourceTitle />
          )}
          <S.SkeletonResourceType />
        </S.ResourceContent>
        <ResourceCircleBtn isLoading />
      </S.ResourceItemCard>
    );
  }
  return (
    <S.ResourceItemCard
      isLoading={isLoading}
      onClick={onClick}
      className={className}
    >
      <S.ResourceAsset>
        <ResourceAssetPreview
          resource={resource}
          isMediaResource={isMediaResource}
          mediaPublicUrl={mediaPublicUrl}
        />
      </S.ResourceAsset>
      <S.ResourceContent>
        <S.ResourceTitle>{nameLabel}</S.ResourceTitle>
        {typeLabel ? (
          <S.ResourceTypeLabel>
            <Text type="label">{typeLabel}</Text>
          </S.ResourceTypeLabel>
        ) : null}
      </S.ResourceContent>
      <ResourceCircleBtn
        isLoading={loading}
        hasDelete={typeof onDelete === "function"}
        onDelete={e => {
          if (onDelete) {
            onDelete(id);
            e.stopPropagation();
          }
        }}
      />
    </S.ResourceItemCard>
  );
};

export default ResourceItemPreview;
