import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  padding-top: 32px;
  margin-top: auto;
  @media screen and (max-width: 768px) {
    padding-top: 8px;
    flex-direction: column-reverse;
  }
  button {
    width: auto;
    min-width: 92px;
  }
`;

export const VideoWrapper = styled.div`
  padding-bottom: 8px;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const IntroPresentationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  white-space: pre-wrap;
  text-align: center;
  height: 100%;
  span {
    display: block;
  }
  @media screen and (max-width: 768px) {
    padding: 12px 20px 32px 20px;
  }
`;
