import styled from "styled-components";
import { Text } from "@arcadia/design-system";

export const Title = styled(Text)`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: block;
`;

export const SubTitle = styled(Text)`
  display: block;
  margin: 16px 16px 0px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  min-height: 150px;
  border: 1px solid #d6d6d6;
  border-radius: 16px;
  position: relative;
  @media (min-width: 767px) {
    width: 680px;
  }
`;

export const Container = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px 26px;
`;

export const WrapperCard = styled.div`
  width: 310px;
`;

export const WrapperButton = styled.div`
  display: none;
  padding: 0px 16px 16px;

  @media (min-width: 767px) {
    display: block;
  }
`;
