import React, { useEffect } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { ConfirmationAlert } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { CourseDetailsWizard } from "../CourseDetailsWizard";
import { Drafting } from "../Drafting";
import { CourseListPreviewStep } from "../CourseListPreviewStep";

import * as S from "./AICourseWizardModal.styles";
import { useAIState } from "./hooks/useAIState";
import { CourseWizardModalStep } from "./AICourseWizardModal.const";

export const AICourseWizardModal = () => {
  const {
    ModalStore: { closeModal, setCustomCloseCb },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();

  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    list,
    firstStepData,
    onBack,
    onCourseDetailsSubmit,
    onPreviewModulesSubmit,
    onRegenerateModules,
    step
  } = useAIState();

  const closeConfirmation = () => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("ai_wizard_course_modal_close_alert_text")}
        declineText={translate("ai_wizard_course_modal_close_alert_cancel_cta")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("ai_wizard_course_modal_close_alert_confirm_cta")}
        onAcceptFunction={() => {
          closeModal();
          closeConfirmDialog();
        }}
      />
    );
  };

  useEffect(() => {
    setCustomCloseCb(closeConfirmation);
    return () => {
      setCustomCloseCb(null);
    };
  }, []);

  return (
    <ModalLayout
      closeModal={closeConfirmation}
      labels={{
        title: translate("ai_wizard_course_modal_title"),
        mobileClose: translate("ai_wizard_course_modal_mobile_cancel_cta")
      }}
      mobileProps={{
        withDrag: false
      }}
      desktopProps={{
        hasAdaptiveWidth: true,
        onBack: step === CourseWizardModalStep.modulesPreview && onBack
      }}
    >
      {step === CourseWizardModalStep.courseDetails && (
        <S.FirstStepWrapper>
          <CourseDetailsWizard
            onSubmit={onCourseDetailsSubmit}
            defaultValues={firstStepData}
          />
        </S.FirstStepWrapper>
      )}
      {step === CourseWizardModalStep.draftingModulePreview && (
        <S.LoadingWrapper>
          <Drafting type="course" />
        </S.LoadingWrapper>
      )}
      {step === CourseWizardModalStep.modulesPreview && (
        <S.ThirdStepWrapper>
          <CourseListPreviewStep
            onRedo={onRegenerateModules}
            onSubmit={onPreviewModulesSubmit}
            startingList={list}
          />
        </S.ThirdStepWrapper>
      )}
      {step === CourseWizardModalStep.draftingCourse && (
        <S.LoadingWrapper>
          <Drafting type="content" />
        </S.LoadingWrapper>
      )}
    </ModalLayout>
  );
};
