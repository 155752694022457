import { userIsUnderage } from "src/js/UserFunction";

export const __FORM_TUTOR_APPROVE__ = {
  name: {
    required: "Inserire un nome (massimo 50 caratteri)",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "Inserire un nome (massimo 50 caratteri)"
    }
  },
  surname: {
    required: "Inserire un cognome (massimo 50 caratteri)",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
      message: "Inserire un cognome (massimo 50 caratteri)"
    }
  },
  birthday: {
    required: "tutor_birthday_required_error",
    validate: value => {
      return (
        !userIsUnderage({ birthday: value }) ||
        "tutor_birthday_validation_error"
      );
    }
  },
  email: {
    required: "Inserire una mail valida",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "Inserire una mail valida"
    }
  },
  terms: {
    required: "registration_terms_privacy_validation_error"
  }
};
