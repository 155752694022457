export const __DEFAULT_LANG__ = "en_US";
export const __DEFAULT_LOCALE__ = "it";

export const __COUNTRY_PARAMS_NAME__ = "r";
export const __LANGUAGE_PARAMS_NAME__ = "lang";

export const __AVAILABLE_LANG__ = {
  it_IT: "Italiano",
  en_US: "English",
  es_ES: "Español",
  fr_FR: "Français"
};

export const __FLAG_LANG__ = {
  it_IT: "🇮🇹",
  en_US: "🇬🇧",
  es_ES: "🇪🇸",
  fr_FR: "🇫🇷"
};

export const __DEFAULT_LANGUAGE_COUNTRY__ = {
  it: "it_IT",
  en: "en_US",
  es: "es_ES",
  fr: "fr_FR"
};
