import { Box, DoodleLoader, Heading, Icon, Text } from "@arcadia/design-system";
import React from "react";
import { EventAttachmentItem } from "src/js/components/EventAttachmentItem";
import { Pill } from "src/js/components/Pill";
import { isEmptyObject } from "src/js/modules/commonFunction";
import theme from "src/js/theme/theme";
import { useTheme } from "styled-components";

import { format } from "date-fns";
import { CalendarTag } from "src/js/components/Calendar/CalendarTag";
import { useStores } from "src/js/hooks";
import {
  CalendarEventAssignedUser,
  CalendarEventGroup,
  CalendarEventType,
  EventAttachmentMode
} from "src/js/pages/calendar/Calendar.types";
import {
  getDatesUtils,
  getEventStatus
} from "src/js/pages/calendar/CalendarUtils";
import { EventDownload } from "src/js/pages/calendar/EventDownload";
import { EventRecipientsList } from "src/js/pages/calendar/EventRecipientsList/";
import { useTranslation } from "src/js/translation";
import {
  RESOURCE_DEPRECATED_TYPE,
  RESOURCE_TYPE,
  ResourceType
} from "src/js/types";
import * as S from "./EventDeadlineView.styles";

type EventDetailViewPropsType = {
  eventDetail: CalendarEventType;
  openResource: ({ id, type }: { id: number; type: ResourceType }) => void;
  openRecipientsModal: ({
    group_recipients,
    user_recipients
  }: {
    group_recipients: CalendarEventGroup[];
    user_recipients: CalendarEventAssignedUser[];
  }) => void;
};

const EventDeadlineView = ({
  eventDetail,
  openResource,
  openRecipientsModal = () => {}
}: EventDetailViewPropsType) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();

  const {
    id: eventId,
    title,
    start,
    end,
    description,
    all_day,
    type,
    group_recipients = [],
    user_recipients = [],
    resource_attachments = [],
    board_attachments = [],
    exercise_attachments = []
  } = eventDetail || {};

  const { startDate, endDate, dateNow, hasSameTime } = getDatesUtils({
    start,
    end
  });

  const status = getEventStatus({
    dateNow,
    startDate,
    endDate,
    eventType: type
  });

  if (isEmptyObject(eventDetail)) return <DoodleLoader theme={greyTheme} />;

  const groupName = group_recipients[0]?.name;

  return (
    <S.EventViewContainer>
      <CalendarTag type={type} status={status} />
      <S.TitleContainer>
        <Heading level="1">{title}</Heading>
      </S.TitleContainer>
      <S.DataGrid>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_detail_groups_label")}</Text>
          </S.DataRowLabel>
          <S.PillContainer>
            <Pill
              id="event-group"
              value={groupName}
              isDeletable={false}
              color={theme.colors.blueGrey[500]}
            />
          </S.PillContainer>
        </S.DataRow>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_detail_recipients")}</Text>
          </S.DataRowLabel>
          <EventRecipientsList
            group_recipients={group_recipients}
            user_recipients={user_recipients}
            onClickItem={() => {
              openRecipientsModal({
                group_recipients,
                user_recipients
              });
            }}
          />
        </S.DataRow>
        {!hasSameTime && (
          <S.DataRow>
            <S.DataRowLabel>
              <Text type="label">{translate("event_detail_start_date")}</Text>
            </S.DataRowLabel>
            <Icon icon="calendar" height={16} width={16} />
            <S.DateContainer>{startDate.toLocaleDateString()}</S.DateContainer>
            {!all_day && startDate ? format(startDate, "HH:mm") : null}
          </S.DataRow>
        )}
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">
              {translate(
                hasSameTime
                  ? "event_detail_single_date"
                  : "event_detail_end_date"
              )}
            </Text>
          </S.DataRowLabel>
          <Icon icon="calendar" height={16} width={16} />
          <S.DateContainer>{endDate.toLocaleDateString()}</S.DateContainer>
          {!all_day && endDate ? format(endDate, "HH:mm") : null}
        </S.DataRow>
        {isLayoutModeMobile && (
          <S.DataRow>
            <EventDownload eventId={eventId} />
          </S.DataRow>
        )}
      </S.DataGrid>
      {description && (
        <S.DescriptionRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_detail_description")}</Text>
          </S.DataRowLabel>
          <S.DescriptionContainer>
            <Text type="formDescription">{description}</Text>
          </S.DescriptionContainer>
        </S.DescriptionRow>
      )}
      {!isLayoutModeMobile && (
        <Box marginTop={16}>
          <EventDownload eventId={eventId} />
        </Box>
      )}
      <S.EventAttachments>
        {resource_attachments.map(({ id, type, name }) => {
          return (
            <EventAttachmentItem
              key={id}
              mode={EventAttachmentMode.View}
              id={id}
              type={type}
              name={name}
              openResource={openResource}
            />
          );
        })}
        {board_attachments.map(({ id, name }) => {
          return (
            <EventAttachmentItem
              key={id}
              mode={EventAttachmentMode.View}
              id={id}
              type={RESOURCE_DEPRECATED_TYPE.BOARD}
              name={name}
              openResource={openResource}
            />
          );
        })}
        {exercise_attachments.map(({ id, name }) => {
          return (
            <EventAttachmentItem
              key={id}
              mode={EventAttachmentMode.View}
              id={id}
              type={RESOURCE_TYPE.EXERCISE}
              name={name}
              openResource={openResource}
            />
          );
        })}
      </S.EventAttachments>
    </S.EventViewContainer>
  );
};

export default EventDeadlineView;
