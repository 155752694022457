import styled from "styled-components";

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 52px;

  > div > div {
    margin-top: 0px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;
