import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import React, { useCallback, useEffect } from "react";
import { useEditor } from "src/js/components/global/BaseTextEditor/hooks";
import { TODO } from "src/js/types";
import styled from "styled-components";
import { editableContentMixin } from "./TextEditorInput";
import { TextEditorMenu } from "./TextEditorInput/components/TextEditorMenu";
import { BaseTextEditor } from "./global/BaseTextEditor";

const StyledInput = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[700]};
  .ProseMirror {
    padding: 4px 16px;
    overflow: auto;
    height: 100%;
    min-height: 200px;
    max-height: 200px;
    ${scrollbarMixin(VariantType.White)}
    ${editableContentMixin()}
  }
`;

const StyledTextEditorMenu = styled(TextEditorMenu)`
  margin: 0;
  padding: 8px;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TextEditor = ({
  content,
  setContent = () => {}
}: {
  content: string;
  setContent: (value: string) => void;
}) => {
  const editor = useEditor({
    content,
    options: {
      extensions: [
        StarterKit,
        Subscript,
        Superscript,
        Underline,
        Link.configure({
          openOnClick: false
        })
      ],
      content
    }
  });

  const fixedMenu = useCallback(
    () => (
      <StyledTextEditorMenu
        editor={editor}
        prepareModuleResourcePayload={() => undefined as TODO}
        prepareWSResource={() => undefined as TODO}
      />
    ),
    [editor]
  );

  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      setContent(editor.getHTML());
    }
  }, [editor?.getHTML()]);

  return (
    <StyledInput className="custom-editor">
      <BaseTextEditor editor={editor} fixedMenu={fixedMenu} />
    </StyledInput>
  );
};

export default TextEditor;
