import { useSortable } from "@dnd-kit/sortable";
import { Box, ContextualAlert } from "@arcadia/design-system";
import React from "react";
import useModuleResourceUpload from "src/js/components/modules/useModuleResourcePayload";
import { useStores } from "src/js/hooks";
import { checkFileSize } from "src/js/modules/fileFunction";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { ModulesResourceNewItem } from "src/js/components/modules/ModuleResourceNewItem";
import { useTheme } from "styled-components";
import * as S from "./ResourcePlaceholder.styles";
import { ResourcePlaceholderProps } from "./ResourcePlaceholder.types";

const ResourcePlaceholder = ({
  id,
  isOpen,
  elementIndex,
  moduleResourcesList,
  isIndexAvailable,
  hasMandatoryOrder
}: ResourcePlaceholderProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    GroupStore: { roles }
  } = useStores();

  const { prepareModuleResourcePayload } = useModuleResourceUpload({
    moduleResourcesList,
    elementIndex
  });

  const boxContainerId = `placeholder-container-${elementIndex}`;

  const isStudent = roles?.includes("ROLE_STUDENT");

  let dragCounter = 0;
  const {
    GroupStore: { userIsTeacher }
  } = useStores();
  const { setNodeRef } = useSortable({ id, disabled: !userIsTeacher });

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const el = document.getElementById(boxContainerId);
    dragCounter += 1;
    el.classList.add("resource-drag-placeholder-hidden");
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const el = document.getElementById(boxContainerId);
    dragCounter -= 1;
    if (dragCounter === 0) {
      el.classList.remove("resource-drag-placeholder-hidden");
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    const el = document.getElementById(boxContainerId);
    if (!files.length || (files.length === 1 && !files[0].size)) {
      el.classList.remove("resource-drag-placeholder-hidden");
      return;
    }
    if (files) {
      const largeFiles = checkFileSize(files);
      if (largeFiles.length) {
        el.classList.remove("resource-drag-placeholder-hidden");
        largeFiles.map(fileName =>
          showToastError({
            str: translate("file_drag_drop_size_error", {
              fileName
            })
          })
        );
      }
      prepareModuleResourcePayload(files);
    }
  };

  // Prevent the student to see the placeholders if hasMandatoryOrder === true
  return hasMandatoryOrder && isStudent ? null : (
    <Box position="relative">
      {hasMandatoryOrder ? (
        <S.PlaceholderIndex enabled={isIndexAvailable}>
          {elementIndex + 1}
        </S.PlaceholderIndex>
      ) : null}
      <S.PlaceholderContainer
        ref={setNodeRef}
        id={String(id)}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <S.PreUploadBox
          id={boxContainerId}
          className="resource-drag-placeholder-item"
        >
          <Box width="100%" padding={28}>
            <ContextualAlert
              theme={whiteTheme}
              text={translate("resource_preupload_placeholder_limit_message")}
            />
          </Box>
        </S.PreUploadBox>

        <ModulesResourceNewItem
          position={elementIndex}
          id={`new-resource-element-${elementIndex}`}
          isLocked={!isIndexAvailable}
          openBoard={isOpen}
          resourceElementIndex={elementIndex}
          hasMandatoryOrder={hasMandatoryOrder}
          prepareModuleResourcePayload={prepareModuleResourcePayload}
        />
      </S.PlaceholderContainer>
    </Box>
  );
};

export default ResourcePlaceholder;
