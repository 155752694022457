import { Button } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { SingleTaskCard } from "src/js/components/task/SingleTaskCard";
import { useStores } from "src/js/hooks";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import * as S from "./UserSettingsTasks.styles";

const UserSettingsTasks = ({ taskList }) => {
  const { whiteTheme, colors } = useTheme();
  const { UserTasksStore } = useStores();
  const { setTasksAreaVisible, tasksAreaIsVisible } = UserTasksStore;
  return (
    <>
      <S.Title type="formSubtitle">
        {translateString({ text: "tips_tricks_title_page" })}
      </S.Title>
      <S.Wrapper>
        <S.SubTitle type="formSubtitle">
          {translateString({ text: "tips_tricks_subtitle_page" })}
        </S.SubTitle>
        <S.Container>
          {taskList?.map(task => {
            return (
              <S.WrapperCard key={task.id}>
                <SingleTaskCard
                  image={task.image}
                  title={task.title}
                  description={task.description}
                  url={task.targetUrl}
                  isCompleted={task.isCompleted}
                  backgroundColor={colors.coldGrey[900]}
                />
              </S.WrapperCard>
            );
          })}
        </S.Container>
        <S.WrapperButton>
          <Button
            theme={whiteTheme}
            variant="primary"
            onClick={() => setTasksAreaVisible(true)}
            disabled={tasksAreaIsVisible}
          >
            {translateString({ text: "tips_tricks_button_page" })}
          </Button>
        </S.WrapperButton>
      </S.Wrapper>
    </>
  );
};

export default observer(UserSettingsTasks);
