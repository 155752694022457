import { motion } from "framer-motion";
import styled from "styled-components";

export const ButtonRelativeWrapper = styled.div`
  position: relative;
  @media (max-width: 767px) {
    position: unset;
  }
`;

export const EmojiPickerWrapper = styled(motion.div)`
  position: absolute;
  width: 320px;
  bottom: 34px;
  right: 0;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
    left: 0;
    bottom: 56px;
  }
`;
