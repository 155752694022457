import {
  AIInitialTransformationsValue,
  AIInitialTransformations,
  AIToneTransformationsValue,
  AIToneTransformations,
  AITranslateTransformationsValue,
  AITranslateTransformations
} from "./AITransformer.types";

export const __INITIAL_TRANSFORMATIONS__: AIInitialTransformationsValue[] = [
  AIInitialTransformations.AIwriter,
  AIInitialTransformations.CompleteSentence,
  AIInitialTransformations.Extend,
  AIInitialTransformations.Shorten,
  AIInitialTransformations.FixSpellingAndGrammar,
  AIInitialTransformations.Rephrase,
  AIInitialTransformations.Simplify,
  AIInitialTransformations.Summarise,
  AIInitialTransformations.Emojify,
  AIInitialTransformations.ChangeTone,
  AIInitialTransformations.Translate
];

export const __TONE_TRANSFORMATIONS__: AIToneTransformationsValue[] = [
  AIToneTransformations.Default,
  AIToneTransformations.Academic,
  AIToneTransformations.Business,
  AIToneTransformations.Casual,
  AIToneTransformations.Childfriendly,
  AIToneTransformations.Confident,
  AIToneTransformations.Conversational,
  AIToneTransformations.Creative,
  AIToneTransformations.Emotional,
  AIToneTransformations.Excited,
  AIToneTransformations.Formal,
  AIToneTransformations.Friendly,
  AIToneTransformations.Funny,
  AIToneTransformations.Humorous,
  AIToneTransformations.Informative,
  AIToneTransformations.Inspirational,
  AIToneTransformations.Memeify,
  AIToneTransformations.Narrative,
  AIToneTransformations.Objective,
  AIToneTransformations.Persuasive,
  AIToneTransformations.Poetic
];

export const __TRANSLATE_TRANSFORMATIONS__: AITranslateTransformationsValue[] =
  [
    AITranslateTransformations.English,
    AITranslateTransformations.Italian,
    AITranslateTransformations.French,
    AITranslateTransformations.Spanish
  ];
