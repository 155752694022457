import { observer } from "mobx-react";
import React from "react";
import { useRouteMatch } from "react-router";
import {
  ID_NEW_THREAD_ITEM,
  getAvatarData,
  getTitleFromUsers,
  getUsersWithoutMe
} from "src/js/components/Thread/Thread.const";
import { useAppRoutingContext, useDebounce, useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { useTranslation } from "src/js/translation";
import { ChatEvent, EventDomain, SpaceChatsParams } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { SidebarThreadItem } from "./SidebarThreadItem";
import * as S from "./SidebarThreadList.styles";

const SidebarThreadList = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceStore: { activeSpaceSlug },
    ChatStore: { threadsList },
    UserStore: { activeUser }
  } = useStores();
  const { context } = useAppRoutingContext();
  const {
    params: { thread_id }
  } = useRouteMatch<SpaceChatsParams>(
    WeSchoolRoutes.App.Spaces.SpaceChat.threadId
  ) ?? { params: { thread_id: "" } };

  const navigateToThread = (threadId: string) => {
    if (context !== "chat") {
      _trackEvent(EventDomain.Chat, ChatEvent.ChatStartThreadListClick);
    }
    navigateTo(
      createUrl("chat_thread", {
        space_slug: activeSpaceSlug,
        thread_id: threadId
      })
    );
  };

  const isNewThreadActive = Boolean(
    useRouteMatch(WeSchoolRoutes.App.Spaces.SpaceChat.new)
  );
  const activeThreadId = isNewThreadActive
    ? ID_NEW_THREAD_ITEM
    : `sidebar-thread-${thread_id}`;

  const threadsListDebounced = useDebounce(threadsList, 300);

  const listElement = threadsListDebounced.map(thread => {
    const threadId = `sidebar-thread-${thread?.id}`;
    const usersWithoutMe = getUsersWithoutMe(thread?.users, activeUser?.uuid);
    const userList = usersWithoutMe.map(
      user => ` ${user.name} ${user.surname}`
    );
    const usersTooltipString = userList ? userList.toString() : "";
    const { disabled, url } = getAvatarData(usersWithoutMe);

    return (
      <SidebarThreadItem
        key={threadId}
        title={getTitleFromUsers(usersWithoutMe, {
          removed: translate("chat_thread_user_removed")
        })}
        id={threadId}
        isMuted={thread?.muted}
        isSelected={threadId === activeThreadId}
        onClick={() => navigateToThread(thread.id)}
        notification={thread?.unreadMessages}
        badge={
          <S.AvatarThreadItem
            disabled={disabled}
            size="image"
            rounded
            url={url}
            theme={whiteTheme}
          />
        }
        numberUsers={usersWithoutMe.length}
        usersTooltipString={usersTooltipString}
      />
    );
  });

  return (
    <S.SidebarThreadListWrapper>
      {isNewThreadActive ? (
        <SidebarThreadItem
          id={ID_NEW_THREAD_ITEM}
          title={translate("sidebar_cta_new_thread")}
          isSelected={ID_NEW_THREAD_ITEM === activeThreadId}
          onClick={() => {}}
          badge={
            <S.AvatarThreadItem
              size="image"
              rounded
              theme={whiteTheme}
              url={null}
            />
          }
        />
      ) : null}
      {listElement}
    </S.SidebarThreadListWrapper>
  );
};

export default observer(SidebarThreadList);
