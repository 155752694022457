import React from "react";
import { Icon } from "@arcadia/design-system";
import * as S from "./MessageBanner.styles";

const MessageBanner = ({
  text = "",
  closeFunction = () => {},
  isVisible = false
}) => {
  return (
    <S.BannerContainer isVisible={isVisible}>
      <S.BannerText>{text}</S.BannerText>
      <S.BannerIcon onClick={closeFunction}>
        <Icon icon="close" width={16} height={16} />
      </S.BannerIcon>
    </S.BannerContainer>
  );
};

export default MessageBanner;
