import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { SpaceModel } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./SchoolClaimForm.styles";
import {
  SchoolClaimFormContext,
  SchoolClaimFormProps,
  SchoolClaimFormStatus
} from "./SchoolClaimForm.types";
import { SchoolClaimSearch } from "./SchoolClaimSearch";
import { SchoolClaimSelected } from "./SchoolClaimSelected";

const SchoolClaimForm = forwardRef(
  (
    {
      theme,
      onSuccessCallback,
      backgroundColor,
      onChangeStatus,
      goToSpaceCreation
    }: SchoolClaimFormProps,
    ref
  ) => {
    const { whiteTheme } = useTheme();
    const currentTheme = theme || whiteTheme;

    const [status, setStatus] = useState<SchoolClaimFormStatus>(
      SchoolClaimFormStatus.Selection
    );
    const [spaceSelected, setSpaceSelected] =
      useState<Partial<SpaceModel>>(null);

    useEffect(() => {
      onChangeStatus(status);
    }, [status]);

    useImperativeHandle(ref, () => {
      return {
        back() {
          setStatus(SchoolClaimFormStatus.Selection);
        }
      };
    }, []);

    const context = useMemo<SchoolClaimFormContext>(() => {
      if (status === SchoolClaimFormStatus.Selection) {
        return SchoolClaimFormContext.Search;
      }
      return SchoolClaimFormContext.Selected;
    }, [status]);

    return (
      <>
        <S.SchoolClaimFormWrapper
          show={context === SchoolClaimFormContext.Search}
        >
          <SchoolClaimSearch
            theme={currentTheme}
            onSelectedCallback={(newStatus, newSpace = null) => {
              setStatus(newStatus);
              setSpaceSelected(newSpace);
            }}
            backgroundColor={backgroundColor}
            goToSpaceCreation={goToSpaceCreation}
          />
        </S.SchoolClaimFormWrapper>
        <S.SchoolClaimFormWrapper
          show={context === SchoolClaimFormContext.Selected}
        >
          <SchoolClaimSelected
            theme={currentTheme}
            status={status}
            spaceSelected={spaceSelected}
            onSuccessCallback={onSuccessCallback}
          />
        </S.SchoolClaimFormWrapper>
      </>
    );
  }
);

SchoolClaimForm.displayName = "SchoolClaimForm";

export default SchoolClaimForm;
