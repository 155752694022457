import React from "react";
import { useTheme } from "styled-components";
import { Box, Button, Heading, Link, SearchBar } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { AnimatePresence } from "framer-motion";
import { UniversityRadioButton } from "../UniversityRadioButton";
import { NoResults } from "../NoResults";
import { UniversityFinderProps } from "../../UniversityFinderPage.types";

import * as S from "./UniversityFinderDesktopView.styles";

const UniversityFinderDesktopView = ({
  searchText,
  unis,
  selected,
  onToggleUni,
  onChangeText,
  onCancelLogin,
  onGoToProvider
}: UniversityFinderProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  return (
    <S.Card
      key="reset-card"
      initial={{
        opacity: 0,
        scale: 1.15
      }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 0.35, ease: "easeInOut" }}
    >
      <Heading level="3">{translate("unlogged_find_university_label")}</Heading>
      <Box marginTop={32} marginBottom={8} width="100%">
        <SearchBar
          theme={whiteTheme}
          value={searchText}
          onChange={event => {
            onChangeText(event);
          }}
          placeholder={translate("unlogged_search_university_placeholder")}
        />
      </Box>
      <Box width="100%">
        <Link
          theme={whiteTheme}
          href="https://www.weschool.com/idem"
          target="_blank"
        >
          {translate("login_university_external_reference_label")}
        </Link>
      </Box>

      <AnimatePresence>
        {unis.length > 0 ? (
          <S.UniListWrapper
            key="uni-list-wrapper"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ opacity: 0, height: 0 }}
          >
            {unis.map(uni => (
              <UniversityRadioButton
                key={`uni-${uni.entityID}`}
                university={uni}
                onClick={onToggleUni(uni.entityID)}
                isSelected={selected === uni.entityID}
              />
            ))}
          </S.UniListWrapper>
        ) : undefined}
      </AnimatePresence>
      {unis.length === 0 && searchText !== "" ? (
        <S.BottomContainer>
          <Box marginBottom={16}>
            <Heading level="5">{translate("search_no_results")}</Heading>
          </Box>
          <NoResults />
        </S.BottomContainer>
      ) : undefined}
      <AnimatePresence>
        {selected ? (
          <S.ButtonWrapper
            key="button-wrapper"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
          >
            <Button
              theme={whiteTheme}
              variant="secondary"
              onClick={onCancelLogin}
            >
              {translate("Annulla")}
            </Button>
            <Button
              theme={whiteTheme}
              variant="primary"
              onClick={onGoToProvider}
            >
              {translate("confirm")}
            </Button>
          </S.ButtonWrapper>
        ) : undefined}
      </AnimatePresence>
    </S.Card>
  );
};

export default UniversityFinderDesktopView;
