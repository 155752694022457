import * as PopoverRadix from "@radix-ui/react-popover";
import * as TooltipRadix from "@radix-ui/react-tooltip";
import styled, { css, keyframes } from "styled-components";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" }
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" }
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" }
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" }
});

const tooltipMixin = (withPortal: boolean) => css`
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  white-space: pre-wrap;
  text-align: left;
  z-index: 9999;

  max-width: 348px;
  @media (max-width: 364px) {
    max-width: calc(100vw - 16px);
  }

  ${!withPortal &&
  css`
    z-index: auto;
  `}

  &[data-state="delayed-open"] {
    &[data-side="top"] {
      animation-name: ${slideDownAndFade};
    }
    &[data-side="right"] {
      animation-name: ${slideLeftAndFade};
    }
    &[data-side="bottom"] {
      animation-name: ${slideUpAndFade};
    }
    &[data-side="left"] {
      animation-name: ${slideRightAndFade};
    }
  }
`;

export const StyledTooltipContent = styled(TooltipRadix.Content)<{
  withPortal: boolean;
}>`
  ${({ withPortal }) => tooltipMixin(withPortal)}
`;

export const StyledPopoverContent = styled(PopoverRadix.Content)<{
  withPortal: boolean;
}>`
  ${({ withPortal }) => tooltipMixin(withPortal)}
`;
