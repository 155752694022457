import styled from "styled-components";

export const ThreadPageWrapper = styled.div<{ pageMinHeight: string }>`
  display: flex;
  flex-direction: column;
  height: ${({ pageMinHeight }) => pageMinHeight};
  width: 100%;
`;

/** Mobile */
export const ThreadPageMobileWrapper = styled.div<{ pageMinHeight: string }>`
  display: flex;
  flex-direction: column;
  height: ${({ pageMinHeight }) => pageMinHeight};
`;
