import { Avatar, TabButtons, TabButtonsOption } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FadeableWrapper } from "src/js/components/FadeableWrapper";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { createUrl } from "src/js/modules/routing";
import { fetchPublicProfileSettings } from "src/js/repository/publicProfileRepository";
import { translateString } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { AccountSettings } from "./AccountSettings";
import { SettingsMobile } from "./SettingsMobile";
import * as S from "./UserSettings.styles";
import { UserSettingsNotice } from "./UserSettingsNotice";
import UserSettingsTasks from "./UserSettingsTasks";

type SettingsPage = "account" | "notice" | "tips" | undefined;

const UserSettings = () => {
  const { whiteTheme } = useTheme();
  const { headerHeight } = useAppLayout();
  const { UserStore, UserTasksStore, UIStore } = useStores();
  const { isLayoutModeMobile } = UIStore;
  const { option } = useParams<{ option: string }>();
  const { tasksList } = UserTasksStore;
  let pageContent;
  const tinted =
    option === "notice" || option === "account" || option === "tips";

  useEffect(() => {
    // TODO: remove this call that create a profile andter BE fix (need to create a profile before soing action like patch name)
    fetchPublicProfileSettings().then(() => {
      return true;
    });
  }, []);

  const pageOptions: TabButtonsOption[] = [
    {
      id: "account",
      icon: "gear",
      label: translateString({
        text: "settings_account_btn_account"
      })
    },
    {
      id: "notice",
      icon: "bellEmpty",
      label: translateString({
        text: "settings_account_btn_notifications"
      })
    }
  ];

  switch (option) {
    case "notice":
      pageContent = (
        <FadeableWrapper key="notifications-container">
          <UserSettingsNotice />
        </FadeableWrapper>
      );
      break;
    case "tips":
      pageContent = (
        <FadeableWrapper key="tips-container">
          <UserSettingsTasks taskList={tasksList} />
        </FadeableWrapper>
      );
      break;
    case "account":
      pageContent = (
        <FadeableWrapper key="account-container">
          <AccountSettings />
        </FadeableWrapper>
      );
      break;
    default:
      pageContent = (
        <FadeableWrapper key="settings-container">
          <SettingsMobile />
        </FadeableWrapper>
      );
      break;
  }

  const onNavigate = useCallback((page: SettingsPage) => {
    switch (page) {
      case "account":
        navigateTo(
          createUrl("user_settings", {
            option: "account"
          })
        );
        break;
      case "notice":
        navigateTo(
          createUrl("user_settings", {
            option: "notice"
          })
        );
        break;
      case "tips":
        navigateTo(
          createUrl("user_settings", {
            option: "tips"
          })
        );
        break;
      case undefined:
        break;
      default:
        navigateTo(
          createUrl("user_settings", {
            option: "account"
          })
        );
        break;
    }
  }, []);

  return (
    <S.SettingsWrapper tinted={tinted} isMobile={isLayoutModeMobile}>
      {!isLayoutModeMobile ? (
        <S.UserNameWrapper headerHeight={headerHeight}>
          <HeaderBack stringTitle={UserStore.activeUserFullname} />
        </S.UserNameWrapper>
      ) : undefined}
      <S.ContentWrapper>
        {!isLayoutModeMobile ? (
          <>
            <S.AvatarWrapper>
              <Avatar
                theme={whiteTheme}
                url={UserStore.activeUser.avatar?.small}
                alt={UserStore.activeUserFullname}
                size="xl"
                rounded
              />
            </S.AvatarWrapper>
            <TabButtons
              options={pageOptions}
              selectedId={option}
              onSelected={onNavigate}
            />
          </>
        ) : undefined}
        <AnimatePresence exitBeforeEnter initial={false}>
          {pageContent}
        </AnimatePresence>
      </S.ContentWrapper>
    </S.SettingsWrapper>
  );
};

export default observer(UserSettings);
