export const UsetifulId = {
  Toolbar: "toolbar",
  Sidebar: "sidebar",
  SidebarDashboardLink: "sidebarDashboardLink",
  SidebarGroupList: "sidebarGroupList",
  SidebarChatList: "sidebarChatList",
  TopHeaderContainer: "topheader-container",
  ToolbarHomeLink: "toolbarHomeLink",
  GroupsEmptyStateCreateNewGroupCta: "groupsEmptyStateCreateNewGroupCta",
  SpaceHomeCreateGroupCta: "spaceHomeCreateGroupCta",
  DashboardSecondaryHeader: "dashboardSecondaryHeader",
  GroupSecondaryHeader: "groupSecondaryHeader",
  GroupSecondaryHeaderMembersBlock: "groupSecondaryHeaderMembersBlock",
  GroupSecondaryHeaderInviteCta: "groupSecondaryHeaderInviteCta",
  GroupOptionsDotsMenu: "groupOptionsDotsMenu",
  ToolbarActiveSpaceDropdownButton: "toolbarActiveSpaceDropdownButton",
  ToolbarDemoSpaceElement: "toolbarDemoSpaceElement",
  ToolbarActiveSpaceAndActions: "toolbarActiveSpaceAndActions"
} as const;
