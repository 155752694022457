import React, { useEffect, useState } from "react";
import { parseEmojis } from "@weschooleng/emoji-parser";
import createUrl from "src/js/modules/routing";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useStores } from "src/js/hooks";
import { WSAnalyticsInstance } from "src/js/tracking/WSAnalyticsInstance";
import { POST_CREATED_TOPIC_NAME } from "src/js/tracking/Topics.const";
import {
  Button,
  ConfirmationAlert,
  Avatar,
  ContextualError,
  Box
} from "@arcadia/design-system";

import { useTheme } from "styled-components";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { useParams } from "react-router";
import {
  createPost,
  updatePostResources,
  updatePostText
} from "src/js/repository/postRepository";
import { ResourcePicker } from "src/js/components/ResourcePicker";
import { useResourcePayload } from "src/js/hooks/resources";
import { RESOURCE_PICKER_SECTION } from "src/js/components/ResourcePicker/ResourcePicker.const";
import { useTranslation } from "src/js/translation";
import {
  sanitizeEditableText,
  sanitizeText
} from "src/js/modules/textFunction";
import { SocialInput } from "src/js/components/SocialInput";
import { AddTextResourceWithAI } from "src/js/components/ResourcePicker/components/AddTextResourceWithAI";
import { getActiveUser } from "../../../modules/activeUser";
import { getActiveGroup, getActiveGroupId } from "../../../modules/activeGroup";
import { navigateTo } from "../../../modules/history";
import { createURL } from "../../../modules/utility";
import { basePageModel } from "../../../modules/pageStatus";
import { InternalDispatcher } from "../../../modules/dispatcher";
import AttachedResourceList from "./postNewResourcesList";

import * as S from "./NewPost.styles";

// TODO This and NewQuickPost.jsx files could be written as one

const NewPost = ({ postsList, target, appendNewPost }) => {
  const [text, setText] = useState("");
  const [postState, setPost] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [resources, setResources] = useState([]);
  // this state is used to check if the resources are dirty
  const [shouldSubmitResources, setShouldSubmitResources] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = React.useRef();

  const { greyTheme, whiteTheme } = useTheme();
  const {
    ConfirmDialogStore,
    UIStore: { isLayoutModeMobile: mobile },
    GroupStore: { activeGroup }
  } = useStores();
  const { translate } = useTranslation();
  // error: TranslationKeys
  const [error, setError] = useState(null);
  const { post_id } = useParams();

  useEffect(() => {
    const title = translate("new_post_label");
    const back = translate("wall_new_post_back_label");
    let buttons;

    const back_function = () => {
      navigateTo(createURL("wall", { group_id: getActiveGroupId() }), {
        trigger: true
      });
    };

    basePageModel.set({
      selectedTab: "custom",
      backFunc: {
        back,
        back_function,
        title,
        buttons
      }
    });
    if (getActiveGroupId() === 0) navigateTo(createUrl("home"));

    if (target === "edit") {
      const oldPost = postsList.find(post => {
        return Number(post.post.id) === Number(post_id);
      });
      if (oldPost !== undefined) {
        setPost(oldPost);
        setResources(oldPost.resources);
        setText(sanitizeEditableText({ text: oldPost.post.text }));
      } else {
        navigateTo(createURL("wall", { group_id: getActiveGroupId() })); // with no post found
      }
    } else {
      setText("");
    }
  }, []);

  const setLeaveAlert = () => {
    let alertText = "post_delete_confirmation_message";
    if (postState?.post.id) {
      alertText = "post_edit_leave_confirmation_message";
    }
    InternalDispatcher.trigger("leaveAlertCheck", {
      status: true,
      text: alertText
    });
  };

  const onTextareaKeyUp = () => {
    setLeaveAlert();
  };

  const handleChange = e => {
    // remove the error only if the input have an accepted value
    if (e.target.value.trim()) {
      setError(null);
    }
    setText(e.target.value);
  };

  const save = e => {
    e.preventDefault();
    // evito submit multiplo
    setSubmitting(true);

    const sanitizedText = sanitizeText({ text });

    if (sanitizedText.trim() || !!resources.length) {
      const resourcesLength = resources.length;

      if (target === "edit") {
        const afterSubmissionCb = () => {
          setSubmitting(false);
          navigateTo(createURL("wall", { group_id: getActiveGroupId() }));
        };
        updatePostText({
          postId: postState.post.id,
          text: parseEmojis(sanitizedText)
        })
          .then(post => {
            if (shouldSubmitResources) {
              const mappedResources = resources.map(resource => ({
                id: resource.id,
                type: resource?.attributes?.type
              }));
              updatePostResources({
                postId: postState.post.id,
                resources: mappedResources
              }).then(postFull => {
                appendNewPost(postFull);
                afterSubmissionCb();
              });
            } else {
              appendNewPost({ ...postState, post });
              afterSubmissionCb();
            }
          })
          .catch(e => {
            const errorMsg = extractErrorMessage(
              e,
              "Attenzione, si è verificato un errore"
            );
            showToastError({ str: translate(errorMsg) });
            setSubmitting(false);
          });
      } else {
        createPost({
          groupId: activeGroup.id,
          text: parseEmojis(sanitizedText)
        })
          .then(post => {
            if (shouldSubmitResources) {
              const mappedResources = resources.map(resource => ({
                id: resource.id,
                type: resource?.attributes?.type
              }));
              updatePostResources({
                postId: post.id,
                resources: mappedResources
              }).then(postFull => {
                appendNewPost(postFull);
                navigateTo(createURL("wall", { group_id: getActiveGroupId() }));
              });
            } else {
              appendNewPost({ post, resources, commentsCount: 0 });
              navigateTo(createURL("wall", { group_id: getActiveGroupId() }));
            }

            setSubmitting(false);
            InternalDispatcher.trigger("new_wall_post", post);
            _trackEvent("Wall", "WallNew", "", resourcesLength);
            WSAnalyticsInstance.track(POST_CREATED_TOPIC_NAME, {
              groupId: getActiveGroupId(),
              postId: post.id
            });
          })
          .catch(error => {
            const errorMsg = extractErrorMessage(
              error,
              "Attenzione, si è verificato un errore"
            );
            showToastError({ str: translate(errorMsg) });
            setSubmitting(false);
          });
      }
    } else {
      setSubmitting(false);
      setError("Attenzione, verificare i dati inseriti");
    }
  };

  const addResource = resource => {
    setShouldSubmitResources(true);
    setResources(res => [resource, ...res]);
  };

  const onErrorCallback = errorData => {
    showToastError({
      str: errorData?.data?.message
    });
  };

  // Used to upload directly FILES to s3
  const { prepareResourcePayload } = useResourcePayload({
    onSuccessCallback: addResource,
    onLoadingCallback: () => {},
    onErrorCallback
  });

  const removeResource = resource => {
    ConfirmDialogStore.openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("La risorsa sarà eliminata")}
        declineText={translate("Annulla")}
        onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          setShouldSubmitResources(true);
          setResources(resources.filter(res => res.id !== resource.id));
          ConfirmDialogStore.closeConfirmDialog();
        }}
      />
    );
  };

  const errorMessage = error ? (
    <ContextualError theme={whiteTheme} text={translate(error)} />
  ) : null;

  const userName = getActiveUser().getFullName();

  const resourcePickerEl = (
    <ResourcePicker
      section={RESOURCE_PICKER_SECTION.WALL}
      onSubmitCallbackSuccess={(_, successData) => {
        addResource(successData);
      }}
      // TODO add a loading state?
      setUploadingProgress={() => {}}
      onSubmitCallbackError={onErrorCallback}
      prepareModuleResourcePayload={prepareResourcePayload}
      elementIndex={0}
      embedded
      openNewResourceCustomWizard={() => {
        setIsOpen(true);
      }}
    />
  );

  return (
    <div>
      <form
        id="form-wall-new-post"
        className="post__new clearfix"
        onSubmit={save}
      >
        <div className="flex" ref={targetRef}>
          <div className="margin-right-10">
            <Avatar
              url={getActiveUser().getAvatar("medium")}
              gender={getActiveUser().getGenderString()}
              alt={userName}
              size="m"
              theme={whiteTheme}
              rounded
            />
          </div>
          <div className="flex-centered-row full-width">
            <div className="full-width">
              <Box display="flex" gap="8px">
                <SocialInput
                  renderBottom
                  placeholder={translate("scrivi qualcosa")}
                  targetRef={targetRef}
                  onKeyUp={onTextareaKeyUp}
                  onChange={handleChange}
                  maxLength={2000}
                  value={text}
                  variant="grey"
                />
                <S.GlobalStyleAttachmentPost />
                {!isOpen && (
                  <S.CustomDotsMenu
                    id="NewPostDotsMenu"
                    alignRight
                    customIcon="attachment"
                    customMenu={resourcePickerEl}
                    listContainerId="new-post-attachments-list"
                    withMobileHeaderLabels
                    withMobileAdaptiveHeight={false}
                    mobileCloseLabel={translate("resource_mobile_close_modal")}
                    mobileConfirmLabel={translate(
                      "resource_mobile_confirm_modal"
                    )}
                    mobileTitle={translate("resource_mobile_title_modal")}
                  />
                )}
              </Box>
              <S.BottomWrapper>
                <Button
                  type="submit"
                  variant="primary"
                  theme={greyTheme}
                  fullWidth={mobile}
                  disabled={submitting}
                >
                  {translate("PUBBLICA")}
                </Button>
              </S.BottomWrapper>
            </div>
          </div>
        </div>
        <Box marginTop="8px">{errorMessage}</Box>
        <div className="margin-top-16">
          {resources.length ? (
            <AttachedResourceList
              resources={resources}
              removeFunc={removeResource}
              key="toDisplay3"
            />
          ) : null}
        </div>
      </form>
      <div className="resource-manager-container hidden" />
      <div className="users-manager-container hidden" />
      {isOpen ? (
        <AddTextResourceWithAI
          onSubmit={successData => {
            const resourceIndex = resources.findIndex(
              resource => resource.id === successData.id
            );
            if (resourceIndex === -1) {
              addResource(successData);
              return;
            }
            const resourcesAux = [...resources];
            resourcesAux[resourceIndex] = successData;
            setResources(resourcesAux);
          }}
          editMode={false}
          context="post"
          onClose={() => {
            setIsOpen(false);
          }}
          setUploadingProgress={() => {}}
          elementId={0}
        />
      ) : null}
    </div>
  );
};

export default NewPost;
