import { Heading, Input } from "@arcadia/design-system";
import { DotsMenu } from "src/js/components/DotsMenu";
import styled from "styled-components";

export const ResourceInfoContainer = styled.div`
  height: 96px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ResourceInfoDetail = styled.div`
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  padding: 16px 16px 0px 16px;
`;

export const ResourceInfoCreation = styled.div`
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grey[500]};

  & > span {
    white-space: nowrap;
  }
`;

export const ResourceCompletedMarks = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const ResourceTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 8px;
`;

export const ResourceTitle = styled(Heading)`
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
`;

export const GamificationPointsBox = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 2px 4px;
  border-radius: 4px;
  height: 16px;
`;

export const CustomDotsMenu = styled(DotsMenu)`
  width: max-content;
  height: max-content;
  position: relative;
`;

export const InputContainer = styled(Input)`
  & > div:last-child {
    margin-top: 0px;

    input {
      padding-left: 8px;
      font-weight: 700;
      line-height: 18px;
    }

    & > div:last-child {
      top: 10px;
    }
  }
`;

export const ResourceTitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
  height: 37px;
`;

export const EditIcon = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
  }
`;
