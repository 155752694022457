import React, { FC, useCallback, useEffect, useState } from "react";
import {
  SelectableList,
  SelectableListEmptyState
} from "src/js/components/layout/SelectableList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { fetchSpaceMembers } from "src/js/repository/spacesRepository";
import {
  checkThreadExists,
  createThread
} from "src/js/repository/threadsRepository";
import { useTranslation } from "src/js/translation";
import { BasePaginationParams, ThreadUser } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { UserSelectableItem } from "./UserSelectableItem";

const addIdToUser = (user: ThreadUser) => ({
  ...user,
  id: user.uuid
});

const NewThreadModal: FC<{ selectedUser?: ThreadUser }> = ({
  selectedUser
}) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { closeModal },
    SpaceStore: { activeSpaceId, activeSpaceSlug },
    ChatStore: { addNewThreadToThreadList }
  } = useStores();

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    selectedUser ? [selectedUser.uuid] : []
  );
  const [destinationThreadId, setDestinationThreadId] = useState<string>();

  useEffect(() => {
    if (!activeSpaceId || !selectedUsers || selectedUsers.length === 0) return;
    checkThreadExists({
      spaceId: activeSpaceId,
      participants: selectedUsers
    })
      .then(({ threadId }) => setDestinationThreadId(threadId))
      .catch(() => {
        setDestinationThreadId(null);
      });
  }, [activeSpaceId, selectedUsers]);

  const fetchItems = useCallback(
    (params: BasePaginationParams & { name: string }) =>
      fetchSpaceMembers({
        spaceId: activeSpaceId,
        textSearch: params.name,
        ...params
      }).then(data => {
        let results = data.results.map(addIdToUser);
        if (selectedUser) {
          const user = addIdToUser(selectedUser);
          results = [
            user,
            ...results.filter(u => u.uuid !== selectedUser.uuid)
          ];
        }
        return { ...data, results };
      }),
    [activeSpaceId]
  );

  const navigateToThread = (threadId: string) => {
    navigateTo(
      createUrl("chat_thread", {
        space_slug: activeSpaceSlug,
        thread_id: threadId
      })
    );
    closeModal();
  };

  const handleOnConfirm = () => {
    if (!activeSpaceId || !selectedUsers || selectedUsers.length === 0) return;
    if (destinationThreadId) {
      navigateToThread(destinationThreadId);
    } else {
      createThread({
        spaceId: activeSpaceId,
        userIds: selectedUsers
      }).then(thread => {
        addNewThreadToThreadList(thread);
        navigateToThread(thread.id);
      });
    }
  };

  const emptyEl = status => (
    <SelectableListEmptyState
      status={status}
      emptySearchLabel="new_thread_modal_empty_search"
      emptyListLabel="new_thread_modal_empty_list"
    />
  );

  return (
    <ModalLayout
      labels={{
        title: translate("new_thread_modal_title"),
        mobileClose: translate("new_thread_modal_cta_close")
      }}
      mobileProps={{
        withDrag: false
      }}
      closeModal={closeModal}
    >
      <SelectableList
        multipleSelect
        onConfirm={handleOnConfirm}
        fetchItems={fetchItems}
        selectedItemsIds={selectedUsers}
        onChangeSelectedItemsIds={ids => setSelectedUsers(ids as string[])}
        customComponents={{
          getCustomItemCard: UserSelectableItem,
          getEmptyStateFromStatus: emptyEl
        }}
        labels={{
          searchPlaceholder: translate("new_thread_modal_placeholder_search"),
          translatedTitle: translate("new_thread_modal_title_content"),
          translatedConfirm: translate("new_thread_modal_btn_confirm", {
            counter:
              selectedUsers.length > 0 ? `(${selectedUsers.length})` : null
          })
        }}
      />
    </ModalLayout>
  );
};

export default NewThreadModal;
