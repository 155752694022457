import styled from "styled-components";
import { Container } from "react-awesome-styled-grid";
import { Text } from "@arcadia/design-system";
import { StylesHeaderHomeStats } from "src/js/components/HomeHeaderStats";

export const HeaderContainer = styled(Container)`
  z-index: ${({ theme }) => theme.zIndex.header};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  width: 100%;

  ${StylesHeaderHomeStats.Wrapper} {
    padding: 0;
    background-color: unset;
    margin: 0;
    justify-content: flex-start;
  }

  @media screen and (max-width: 767px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    top: 54px;
    width: 100%;
    padding: 0;
  }
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const HeaderDescription = styled(Text)`
  max-height: 120px;
  max-width: 544px;
  overflow: auto;
  @media screen and (max-width: 767px) {
    order: 2;
  }
  @media screen and (min-width: 767px) {
    margin-bottom: 8px;
  }
`;

export const HeroBannerWrapper = styled.div<{ src: string }>`
  position: relative;
  width: 100%;
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${({ src }) => src}) center / cover no-repeat;
    filter: blur(8px);
    transform: scale(1.1);
    z-index: 1;
  }
`;

export const HeroBanner = styled.img`
  object-fit: cover;
  height: 330px;
  margin: 0 auto;
  width: 100%;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 767px) {
    height: 180px;
  }
`;

const __DESKTOP_IMAGE_SIZE__ = 132;

export const SpaceBadge = styled.div`
  width: ${__DESKTOP_IMAGE_SIZE__}px;
  height: ${__DESKTOP_IMAGE_SIZE__}px;
  font-size: 60px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  button {
    width: 160px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 24px 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
