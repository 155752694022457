import { colors, Heading, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { DotSelectionIcon } from "./SpacesWelcome.icons";
import { SStep } from "./SpacesWelcome.types";
import { StyledActionsText } from "./SpacesWelcomeActionsLabel";

export const TextDescription = styled(Heading).attrs(() => ({
  level: 6
}))`
  line-height: 22px;
`;

export const StatusTasksLabel = styled(motion.span)`
  display: flex;
  align-items: center;
  color: ${colors.yellow[500]};
  padding-left: 36px;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const StyledDotSelectionIcon = styled(DotSelectionIcon)``;

export const ArrowBtn = styled(motion.button)`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 100px;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const StepHelp = styled(Text)`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

export const StepContent = styled.div`
  flex: 1;
  padding: 0px 8px 0 12px;
`;

export const Step = styled(motion.div)<SStep>`
  display: flex;
  align-items: center;
  min-width: 300px;
  min-height: 76px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  ${({ status }) => {
    switch (status) {
      case "HIDE":
        return css`
          display: none;
        `;
      case "SHOW":
        return css`
          ${StyledDotSelectionIcon} {
            display: none;
          }
        `;
      case "UNDONE":
        return css`
          ${StyledDotSelectionIcon} *:not(:first-child) {
            display: none;
          }
        `;
      case "DONE":
        return css`
          ${StyledActionsText} {
            text-decoration: line-through;
          }
        `;
      default:
        return css``;
    }
  }};
  @media screen and (max-width: 767px) {
    min-width: auto;
    width: 100%;
  }
`;

export const Steps = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 32px 16px 0;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  ::-webkit-scrollbar-track:horizontal {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }
  @media screen and (max-width: 767px) {
    padding: 8px 0 4px 0;
    flex-direction: column;
  }
`;

export const StepsMotion = styled(motion.div)`
  padding-left: 32px;
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100%;
  gap: 16px;
  padding: 16px;
  @media screen and (max-width: 767px) {
    zoom: 80%;
  }
  @media screen and (max-width: 767px) {
    padding: 8px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 0 20px;
  span {
    color: ${colors.grey[1000]};
  }
  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

export const MainBoxBg = styled.img`
  position: absolute;
  top: -4px;
  left: -4px;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
  @media screen and (max-width: 767px) {
    top: -150px;
    left: -200px;
  }
`;

export const MainBox = styled(motion.div)`
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px 0;
  z-index: 1;
  background-color: #574ffa;
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
`;
