import { createPalette, getHighContrastColor } from "@ds_themes/utils";
import { DropdownThemeOptions, Palette } from "@ds_themes/types";

import { createCheckboxTheme } from "./checkbox";
import { createSearchbarTheme } from "./searchbar";
import { createTagTheme } from "./tag";

export const createDropdownTheme = ({
  appearence,
  primary,
  general,
  blueGrey,
  coldGrey,
  grey
}: Palette): { dropdown: DropdownThemeOptions } => {
  const contrastColor = appearence === "heavy" ? grey[1000] : coldGrey[900];

  const hoverTextColor = getHighContrastColor(contrastColor);

  const searchbarTheme = createSearchbarTheme(
    createPalette({ primary: primary.main, background: contrastColor })
  );

  return {
    dropdown: {
      backgroundButton: general.background,
      backgroundContainer: general.background,
      filterIconBackground: general.background,
      filterIconActiveColor: primary.main,
      filterIconInactiveColor: general.contrastText,
      text: `${general.contrastText}`,
      border: `1px solid ${general.background}`,
      option: {
        background: general.background,
        text: general.contrastText,
        hover: {
          text: hoverTextColor
        }
      },
      hover: {
        background:
          appearence === "heavy" ? primary.lighter : general.background,
        border: `1px solid ${
          appearence === "heavy" ? primary.lighter : grey[800]
        }`
      },
      focus: {
        border: `1px solid ${
          appearence === "heavy" ? primary.main : primary.light
        }`,
        outline: `${primary.lighter} solid 3px`,
        backgroundOptions: contrastColor,
        backgroundButton: general.background
      },
      disabled: {
        background: general.background,
        color: blueGrey[700]
      },
      searchbar: searchbarTheme.searchbar
    }
  };
};

export const createDropdownMultipleTheme = (palette: Palette) => {
  const { appearence, grey, coldGrey, primary } = palette;
  const contrastColor = appearence === "heavy" ? coldGrey[900] : grey[1000];

  const dropdownTheme = createDropdownTheme(palette);
  const tagTheme = createTagTheme(
    createPalette({ primary: primary.main, background: contrastColor })
  );
  const checkbox = createCheckboxTheme(
    createPalette({ primary: primary.main, background: contrastColor })
  );
  return {
    dropdown: {
      ...dropdownTheme.dropdown,
      tag: tagTheme.tag,
      checkbox: checkbox.checkbox
    }
  };
};
