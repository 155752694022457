import { Button } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { AdminOverquotaBlocked } from "src/js/components/AdminOverquotaBlocked";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import createUrl from "src/js/modules/routing";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import * as S from "./GroupOverquotaBlocked.style";

const GroupOverquotaBlocked = () => {
  const { translate } = useTranslation();
  const {
    SpaceStore: { isAdmin, activeSpace }
  } = useStores();
  const { pageMinHeight } = useAppLayout();
  const { whiteTheme } = useTheme();
  const spaceAdminUuid = activeSpace?.spaceAdminUuid;
  const {
    SpaceStore: { activeSpaceSlug },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const chatWithAdmin = () => {
    navigateTo(
      `${createUrl(isLayoutModeMobile ? "space_chats" : "chat_new", {
        space_slug: activeSpaceSlug
      })}?user_id=${spaceAdminUuid}`
    );
  };

  if (isAdmin) {
    return <AdminOverquotaBlocked />;
  }

  return (
    <S.Wrapper pageMinHeight={pageMinHeight}>
      <S.ModalOverlay pageMinHeight={pageMinHeight}>
        <S.ModalContainer>
          <VideoPlayer video={S3Video.SpaceBlocked} />
          <S.Description type="formSubtitle">
            {translate("space_group_overquota_description_user")}
          </S.Description>
          <S.ModalFooterContainer>
            <Button
              onClick={chatWithAdmin}
              theme={whiteTheme}
              variant="primary"
              fullWidth={getBreakpoint() === "smartphone"}
            >
              {translate("space_group_overquota_button_user")}
            </Button>
          </S.ModalFooterContainer>
        </S.ModalContainer>
      </S.ModalOverlay>
    </S.Wrapper>
  );
};

export default observer(GroupOverquotaBlocked);
