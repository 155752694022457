/* eslint-disable default-case */
import styled, { css, DefaultTheme } from "styled-components";

import {
  SContainerProps,
  SBasicInputProps,
  SHiddenGrowTextProps,
  SInputProps,
  SInputLabelProps,
  SMessageProps,
  SIconWrapperProps
} from "./types";

const getBorder = ({
  theme,
  required,
  feedbackType
}: Pick<SBasicInputProps, "required" | "feedbackType"> & {
  theme: DefaultTheme;
}) => {
  switch (feedbackType) {
    case "error":
      return theme.input.error.border;
    case "success":
      return theme.input.success.border;
  }

  if (required) return theme.input.required.border;

  return theme.input.border;
};

const getIconColor = ({
  theme,
  feedbackType
}: Pick<SBasicInputProps, "feedbackType"> & { theme: DefaultTheme }) => {
  switch (feedbackType) {
    case "success":
      return theme.input.success.text;
    default:
      return "transparent";
  }
};

const BasicInput = css<SBasicInputProps>`
  width: ${props => props.maxWidth};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px 16px;
  border-radius: ${({ rounded }) => (rounded ? "25px" : "8px")};
  -webkit-border-radius: ${({ rounded }) => (rounded ? "25px" : "8px")};
  box-sizing: border-box;
  background: ${({ theme, required }) =>
    required ? theme.input.required.background : theme.input.background};
  border: ${({ theme, required, feedbackType }) =>
    getBorder({ theme, required, feedbackType })};
  color: ${({ theme }) => theme.input.text};
  font-style: ${({ theme }) => theme.input.font.style};
  font-weight: ${({ theme }) => theme.input.font.weight};
  font-size: ${({ theme }) => theme.input.font.size};
  line-height: ${({ theme }) => theme.input.font.style};

  &::placeholder {
    color: ${({ theme }) => theme.input.textPlaceholder};
  }

  &:hover:not([disabled]) {
    border: ${({ theme }) => theme.input.hover.border};
    background: ${({ theme }) => theme.input.hover.background};
  }

  &:focus:not([disabled]) {
    /* TODO: try to put this one into a working variable */
    box-shadow: 0px 0px 0px 2px #ebe5ff;
    background-color: ${({ theme }) => theme.input.focus.background};
    border: ${({ theme }) => theme.input.focus.border};
    outline: ${({ theme }) => theme.input.focus.outline};
    outline-color: transparent;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.input.disabled.background};
    color: ${({ theme }) => theme.input.disabled.text};
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: ${({ theme }) => theme.input.disabled.text};
  }
`;

export const HiddenGrowText = styled.div<SHiddenGrowTextProps>`
  white-space: pre-wrap;
  visibility: hidden;
  grid-area: 1 / 1 / 2 / 2;
  border: ${({ theme, required, feedbackType }) =>
    getBorder({ theme, required, feedbackType })};
  padding: 8px 16px;
  font-style: ${({ theme }) => theme.input.font.style};
  font-weight: ${({ theme }) => theme.input.font.weight};
  font-size: ${({ theme }) => theme.input.font.size};
  line-height: ${({ theme }) => theme.input.font.style};
  overflow: hidden;
  overflow-wrap: break-word;
`;

/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
export const AutoGrowTextArea = styled.div<{
  withRightSection: boolean;
  maxHeight?: string;
}>`
  display: grid;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
      overflow: hidden;
    `}

  & > *:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }

  & textarea {
    overflow: hidden;
  }

  & ${HiddenGrowText}, textarea {
    padding-right: ${({ withRightSection }) =>
      withRightSection && "52px !important"};
  }
`;

export const Input = styled.input<SInputProps>`
  ${BasicInput}
  padding-left: ${({ withIcon }) => (withIcon ? "55px" : "20px")};
  padding-right: ${({ withRightSection }) => withRightSection && "55px"};
`;

export const Label = styled.label<SInputLabelProps>`
  ${({ visuallyHidden }) =>
    visuallyHidden &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    `}

  color: ${({ theme }) => theme.input.label};
`;

export const Counter = styled.div`
  position: absolute;
  margin-top: 5px;
  right: 8px;
`;

export const TextareaContainer = styled.div<{ maxHeight?: string }>`
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  position: relative;

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};

      textarea {
        max-height: ${maxHeight};
        overflow: auto !important;
      }
    `}
`;

export const Textarea = styled.textarea`
  ${BasicInput}
  resize: none;
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 40px;

  @media (max-width: 767px) {
    min-height: 100px;
  }
`;
export const Container = styled.div<SContainerProps>`
  width: ${props => props.maxWidth};
  display: block;
  position: relative;
`;

export const Wrapper = styled.div<{ rounded: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;

  &:focus-within::before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: ${({ theme }) => theme.input.focus.outline};
    border-radius: ${({ rounded }) => (rounded ? "25px" : "8px")};
  }
`;

export const IconWrapper = styled.div<SIconWrapperProps>`
  position: absolute;
  left: 20px;
  display: flex;
  align-items: center;
  color: ${({ theme, colorIcon }) =>
    colorIcon ? theme.input.text : theme.input.textPlaceholder};
`;

export const RightSectionWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
`;

export const Message = styled.div<SMessageProps>`
  margin-top: 8px;
  margin-left: ${({ type }) => (type === "field" ? "20px" : "10px")};
  display: flex;

  svg {
    color: ${({ theme, feedbackType }) =>
      getIconColor({ theme, feedbackType })};
  }

  span {
    margin-left: 4px;
    color: ${({ theme }) => theme.input.text};
  }
`;
