import { Heading } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { GroupCreationForm } from "src/js/components/GroupCreationForm";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import { GroupPanelActivationModal } from "src/js/components/GroupPanelActivationModal";
import { UsersListModal } from "src/js/components/UsersListModal";
import MainContainer from "src/js/components/layout/MainContainer/MainContainer";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import SpacesGroupsBlocked from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsBlocked";
import { SpacesGroupsFilters } from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsFilters";
import { SpacesGroupsList } from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsList";
import { SpacesGroupsUrlHandler } from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsUrlHandler";
import { SpacesNoGroups } from "src/js/pages/spaces/dashboard/SpacesNoGroups";
import { activateGroup, deleteGroup } from "src/js/repository/groupRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import { EventDomain, GroupEvent } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./SpacesGroups.styles";

const SpacesGroups = () => {
  const {
    GroupStore: { fetchGroup },
    SpacesGroupsFilterStore,
    ModalStore: { openModal, closeModal },
    SpaceStore,
    SpacesSettingsStore,
    SpaceGroupListStore: { removeGroupFromList, addGroupAtStart }
  } = useStores();
  const {
    filteredGroups,
    unfilteredTotalGroups,
    totalGroups,
    paginationOffset,
    filters
  } = SpacesGroupsFilterStore;
  const { searchQuery, selectedStatus, selectedOrder } = filters;
  const { activeSpace, activeSpaceId, activeSpaceName } = SpaceStore;
  const { generalSettings } = SpacesSettingsStore;
  const [isLoading, setLoading] = useState(true);
  const { greyTheme } = useTheme();

  const { state }: { state: { showTooltipFirstTemplate: boolean } } =
    useLocation();
  const [showTooltipFirstTemplate] = useState(
    state?.showTooltipFirstTemplate || false
  );

  useEffect(() => {
    if (
      generalSettings?.planSettings?.dashboardGroupsEnabled &&
      activeSpace &&
      SpacesGroupsFilterStore
    ) {
      setLoading(true);
      SpacesGroupsFilterStore.fetchGroups().finally(() => setLoading(false));
    }
    return () => {
      if (activeSpace) {
        SpacesGroupsFilterStore.reset();
      }
    };
  }, [activeSpace, generalSettings]);

  const changeGroupStatus = (groupId, is_active) => {
    if (is_active) {
      deleteGroup(groupId)
        .then(() => {
          removeGroupFromList({ groupId });
          SpacesGroupsFilterStore.fetchGroups();
        })
        .catch(() =>
          showToastError({
            str: <Translate text="group_delete_error_message" />
          })
        );
    } else {
      activateGroup(groupId)
        .then(() => {
          SpacesGroupsFilterStore.fetchGroups().then(({ results }) => {
            const groupToAdd = results.find(
              ({ group }) => group.id === groupId
            );
            addGroupAtStart(groupToAdd);
          });
        })
        .catch(error =>
          showToastError({
            str: extractErrorMessage(
              error,
              "Attenzione, si è verificato un errore"
            )
          })
        );
    }
  };

  const createNewGroup = () => {
    _trackEvent(EventDomain.Group, GroupEvent.DashboardGroupCreation);
    openModal(() => <GroupCreationModal />);
  };

  const createNewGroupEmptyState = () => {
    _trackEvent(EventDomain.Group, GroupEvent.DashboardGroupsEmptyState);
    openModal(() => <GroupCreationModal />);
  };

  const cloneGroup = ({ groupId }: { groupId: string }) => {
    fetchGroup(groupId)
      .then(({ group }) => {
        openModal(() => <GroupCreationForm clonedGroup={group} />);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const openGroupPanelActivationModal = selectedGroup => {
    openModal(() => (
      <GroupPanelActivationModal
        closeModal={() => closeModal()}
        callbackOk={() =>
          changeGroupStatus(selectedGroup.id, selectedGroup.isActive)
        }
        groupName={selectedGroup.name}
        isActive={selectedGroup.isActive}
      />
    ));
  };

  const openUsersListModal = (teachers: any[], groupName: string) => {
    openModal(() => (
      <UsersListModal
        closeModal={closeModal}
        title={groupName}
        subtitle="teachers"
        users={teachers}
      />
    ));
  };

  let pageContent = <S.StyledDoodleLoader theme={greyTheme} />;

  if (generalSettings?.planSettings?.dashboardGroupsEnabled === false) {
    pageContent = <SpacesGroupsBlocked spaceId={activeSpaceId} />;
  }

  if (
    generalSettings?.planSettings?.dashboardGroupsEnabled &&
    !isLoading &&
    unfilteredTotalGroups === 0
  ) {
    pageContent = (
      <SpacesNoGroups
        spaceName={activeSpaceName}
        newGroup={createNewGroupEmptyState}
      />
    );
  }

  if (
    generalSettings?.planSettings?.dashboardGroupsEnabled &&
    !isLoading &&
    unfilteredTotalGroups > 0
  ) {
    pageContent = (
      <MainContainer>
        <S.PageContainer>
          <S.TitleWrapper>
            <Heading level="1">
              <Translate text="groups" />
            </Heading>
            <S.GroupCounter>{unfilteredTotalGroups}</S.GroupCounter>
          </S.TitleWrapper>
          <SpacesGroupsFilters
            statusFilterSelected={selectedStatus}
            sortFilterSelected={selectedOrder}
            searchDefaultValue={searchQuery}
            setFilter={(value, key) =>
              SpacesGroupsFilterStore.setFilter(value, key)
            }
            removeStatusFilter={key =>
              SpacesGroupsFilterStore.removeKeyFromFilter(key, "selectedStatus")
            }
            removeAllStatusFilters={() =>
              SpacesGroupsFilterStore.resetFilter("selectedStatus")
            }
            newGroup={createNewGroup}
          />
          <SpacesGroupsList
            showTooltipFirstTemplate={showTooltipFirstTemplate}
            filteredGroups={filteredGroups}
            totalGroups={totalGroups}
            currentOffset={paginationOffset}
            toggleActivation={selectedGroup => {
              openGroupPanelActivationModal(selectedGroup);
            }}
            cloneGroup={groupId => cloneGroup({ groupId })}
            showTeachers={(teachers, groupName) => {
              openUsersListModal(teachers, groupName);
            }}
            changePage={page => SpacesGroupsFilterStore.changePage(page)}
          />
        </S.PageContainer>
      </MainContainer>
    );
  }

  return (
    <>
      <SpacesGroupsUrlHandler />
      {pageContent}
    </>
  );
};

export default observer(SpacesGroups);
