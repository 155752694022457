import {
  ActionButton,
  Box,
  Button,
  Icon,
  SearchbarWithDropdown
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SpaceCreationModal } from "src/js/components/SpaceCreation";
import {
  SpaceSchoolBadge,
  SpaceSchoolBadgeVariant
} from "src/js/components/SpaceSchoolBadge";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { createUrl } from "src/js/modules/routing";
import { __BREAKPOINT_TABLET__ } from "src/js/settings/settingsGeneral";
import { TranslationKeys } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { EventDomain, NavigationEvent } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { MigrateGroupsModal } from "../../MigrateGroupsModal";
import { SpacesDotMenuWrapper } from "../../SpacesDotMenuWrapper";
import { LeaveSpaceModal } from "../../modal/LeaveSpaceModal";
import HeaderNotificationToggle from "../HeaderNotification/HeaderNotificationToggle";
import { StickyInfoBannerDesktop } from "../StickyInfoBanner";
import * as S from "./TopHeader.styles";
import useSearchByContext from "./hooks/useSearchByContext";
import useStickyBannerContext from "./hooks/useStickyBannerContext";
import { TopHeaderProps } from "./types";
import { ProfileMenu } from "./components";

const TopHeader = ({ context = "space" }: TopHeaderProps) => {
  const { translate } = useTranslation();
  const {
    UIStore: { isSideBarOpen, toggleSidebar },
    ThemeStore,
    SpaceStore,
    ModalStore: { openModal, closeModal },
    SpaceMoveGroupsStore,
    DemoSpaceStore: { isDemoSpaceTopBannerVisible }
  } = useStores();

  const { colors } = useTheme();
  const { whiteTheme } = ThemeStore;

  const { activeSpaceId, activeSpaceName, isAdmin, activeSpace, isClaimed } =
    SpaceStore;
  const { hasDestinationsSpaces, hasTemporaryGroups } = SpaceMoveGroupsStore;
  // under 980px we should show the search button instead of the searchbar
  const [showSearchButton, setShowSearchButton] = useState(
    getBreakpoint() !== "desktop" && context !== "search"
  );
  const stickyBannerProps = useStickyBannerContext();
  const searchbarProps = useSearchByContext({ context });

  const handleResize = useCallback(() => {
    if (context === "search") return;
    setShowSearchButton(window.innerWidth < __BREAKPOINT_TABLET__);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showLeaveSpaceModal = () => {
    openModal(() => <LeaveSpaceModal closeModal={closeModal} />);
  };

  const showSpaceCreationModal = () => {
    openModal(() => <SpaceCreationModal />);
  };

  const showMigrateGroupsModal = () => {
    openModal(() => <MigrateGroupsModal onClose={closeModal} />);
  };

  const searchbar = (
    <SearchbarWithDropdown
      theme={whiteTheme}
      {...searchbarProps}
      expandedWidth="100%"
      width="70%"
      dropdownWidth="100%"
    />
  );

  // contextes where the searchbar is (for now) not present
  const showSearchbar = !(
    (context === "space" || context === "groups") &&
    activeSpace?.groupsCount === 0
  );

  // to have the correct spacing in the style
  const withoutSidebar =
    context === "home" || context === "search" || context === "profile";

  const showButtons =
    context === "chat" ||
    context === "space" ||
    context === "groups" ||
    context === "dashboard";

  const showCreateSpace =
    (context === "home" || context === "groups") &&
    hasTemporaryGroups &&
    !hasDestinationsSpaces;

  const showMoveGroups =
    (context === "home" || context === "groups") &&
    hasTemporaryGroups &&
    hasDestinationsSpaces;

  const contextName = useMemo(() => {
    if (
      context === "chat" ||
      context === "space" ||
      context === "groups" ||
      context === "dashboard"
    ) {
      return activeSpaceName;
    }

    return translate(`top_header_title_${context}_context` as TranslationKeys);
  }, [context, activeSpaceName, translate]);

  const handleToggle = () => {
    _trackEvent(
      EventDomain.Navigation,
      isSideBarOpen ? NavigationEvent.SidebarClose : NavigationEvent.SidebarOpen
    );
    toggleSidebar();
  };

  if (!stickyBannerProps) return null;

  return (
    <S.TopHeader isDemoSpaceTopBannerVisible={isDemoSpaceTopBannerVisible}>
      <S.LeftContainer
        isSidebarOpen={isSideBarOpen}
        context={context}
        withoutSidebar={withoutSidebar}
      >
        {showButtons ? (
          <S.SidebarToggleContainer
            id="sidebar-toggle-button"
            onClick={handleToggle}
          >
            <S.IconContainer
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Icon
                icon={isSideBarOpen ? "arrowLeftWLine" : "hamburger"}
                width={20}
                height={20}
                initialViewbox
              />
            </S.IconContainer>
          </S.SidebarToggleContainer>
        ) : (
          <Box paddingLeft="24px" />
        )}
        <S.ContextName level="6">{contextName}</S.ContextName>
        {isClaimed ? (
          <SpaceSchoolBadge variant={SpaceSchoolBadgeVariant.Yellow} />
        ) : null}
        {context === "chat" ||
        context === "space" ||
        context === "groups" ||
        context === "dashboard" ? (
          <S.DotMenuWrapper>
            <SpacesDotMenuWrapper
              isAdmin={isAdmin}
              leaveSpace={showLeaveSpaceModal}
              activeSpace={activeSpace}
              activeSpaceId={activeSpaceId}
              color={colors.headerTextColor}
            />
          </S.DotMenuWrapper>
        ) : null}
      </S.LeftContainer>
      {showSearchbar && !showSearchButton ? (
        <S.SearchbarContainer>
          <S.AbsoluteWrapper>{searchbar}</S.AbsoluteWrapper>
        </S.SearchbarContainer>
      ) : null}

      <S.RightButtonsWrapper showButtons={showButtons}>
        {showSearchButton ? (
          <ActionButton
            theme={whiteTheme}
            variant="global"
            icon="search"
            onClick={() => navigateTo(createUrl("search"))}
          />
        ) : null}

        {showButtons ? <HeaderNotificationToggle /> : null}
        <ProfileMenu />
        {showCreateSpace ? (
          <S.ButtonMigrationWrapper>
            <Button
              theme={whiteTheme}
              variant="tertiary"
              onClick={showSpaceCreationModal}
            >
              {translate("migration_cta_create_space")}
            </Button>
          </S.ButtonMigrationWrapper>
        ) : null}
        {showMoveGroups ? (
          <S.ButtonMigrationWrapper>
            <Button
              theme={whiteTheme}
              variant="tertiary"
              onClick={showMigrateGroupsModal}
            >
              {translate("migration_cta_move_groups")}
            </Button>
          </S.ButtonMigrationWrapper>
        ) : null}
      </S.RightButtonsWrapper>
      <StickyInfoBannerDesktop {...stickyBannerProps} />
    </S.TopHeader>
  );
};

export default observer(TopHeader);
