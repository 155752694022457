import { Box } from "@arcadia/design-system";
import React from "react";
import { SpaceBlockModal } from "src/js/components/SpaceBlockModal";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { AnalyticsContainer } from "src/js/pages/spaces/dashboard/Analytics/Analytics";
import { MetricObjectives } from "src/js/pages/spaces/dashboard/Analytics/Objectives/components/MetricObjectives";
import { fakeTrainingHoursResponse } from "src/js/pages/spaces/dashboard/Analytics/Objectives/components/TrainingHours/TrainingHours.utils";
import { FilterSelectors } from "src/js/pages/spaces/dashboard/Analytics/components/FilterSelectors";
import { SingleLineChart } from "src/js/pages/spaces/dashboard/Analytics/components/LineChart";
import { MetricCard } from "src/js/pages/spaces/dashboard/Analytics/components/MetricCard";
import { PrimaryMetricCard } from "src/js/pages/spaces/dashboard/Analytics/components/PrimaryMetricCard";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  BaseChartItem,
  DashboardAggregation,
  DashboardXAxisFrequency,
  StateMetricSeverity,
  StateTrendSeverity
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";

const AnalyticsDashboardBlocked = ({ spaceId }) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  const data: BaseChartItem[] = fakeTrainingHoursResponse(
    "2024-04-01",
    "2024-04-08",
    DashboardAggregation.Day
  )?.results.map(element => {
    return {
      label: element.label,
      value: parseFloat((element.value / 100).toFixed(1))
    };
  });

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <SpaceBlockModal
        video={S3Video.DashboardAnalytics}
        description="space_block_modal_description"
        primaryCta={() =>
          navigateTo(
            createUrl("dashboard_settings", {
              space_id: spaceId,
              option: "plan"
            })
          )
        }
        primaryCtaLabel="space_block_modal_primary_cta"
        zIndex="890"
        secondaryCtaLabel={null}
      />
      <div style={{ filter: "blur(5px)" }}>
        <Box width="100%">
          <FilterSelectors spaceGroups={[]} />
          <AnalyticsContainer>
            <PrimaryMetricCard
              margin="20px 0 16px"
              {...(isLayoutModeMobile && {
                margin: "0 0 16px"
              })}
              titleLabel={translate("primary_metric_card_objectives_title")}
              dateLabel={translate("last_7_days_graph_interval")}
            >
              <MetricObjectives
                resultLabel="74%"
                metricSeverity={StateMetricSeverity.Normal}
                descriptionLabel={translate("metric_objectives_description")}
                trendLabel={`1.4 ${translate(
                  "analytics_percentage_point_abbreviation"
                )}`}
                trendSeverity={StateTrendSeverity.Critical}
                progressPercentage={74}
              />
            </PrimaryMetricCard>
            <Box
              display="flex"
              flexDirection="column"
              gap={16}
              {...(isLayoutModeMobile && {
                padding: "0 16px"
              })}
            >
              <MetricCard
                titleLabel={translate(
                  "metric_card_objectives_overall_course_progress_title"
                )}
                resultLabel="13h"
                descriptionLabel={translate(
                  "metric_card_objectives_overall_course_progress_description"
                )}
                trendLabel={`1.2 ${translate("analytics_hour_abbreviation")}`}
                trendSeverity={StateTrendSeverity.Normal}
                dateLabel={translate("last_7_days_graph_interval")}
              >
                <SingleLineChart
                  data={data}
                  tooltipTitle={translate("training_hours_tooltip_title")}
                  measurementUnit={translate("analytics_hour_abbreviation")}
                  xAxisFrequency={DashboardXAxisFrequency.EveryDay}
                />
              </MetricCard>
            </Box>
          </AnalyticsContainer>
        </Box>
      </div>
    </div>
  );
};

export default AnalyticsDashboardBlocked;
