import React, { useEffect, useState } from "react";
import { DoodleLoader, Icon } from "@arcadia/design-system";
import { motion, AnimatePresence, Variants } from "framer-motion";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";

import * as S from "./AILoadingView.styles";

const variants: Variants = {
  enter: () => {
    return {
      y: 10,
      opacity: 0
    };
  },
  exit: () => {
    return {
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1
  }
};

const LoadingMessages: TranslationKeys[] = [
  "ai_loading_message_1",
  "ai_loading_message_2",
  "ai_loading_message_3",
  "ai_loading_message_4",
  "ai_loading_message_5",
  "ai_loading_message_6"
];

const AILoadingView = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const [msgIndex, setMsgIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMsgIndex(val => {
        if (LoadingMessages.length === val + 1) return 0;
        return val + 1;
      });
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <S.AILoadingContainer>
      <S.LoaderWrapper>
        <DoodleLoader theme={whiteTheme} />
      </S.LoaderWrapper>
      <S.TextWrapper>
        <S.StyledHeading level="5">
          <Icon icon="sparkles" initialViewbox width={24} height={24} />
          {translate("ai_editor_loading_response_text")}
        </S.StyledHeading>
        <AnimatePresence initial={false} exitBeforeEnter>
          <motion.div
            key={msgIndex}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              ease: "easeIn",
              duration: 0.4,
              opacity: { duration: 0.4 }
            }}
          >
            <S.StyledMessage type="formDescription">
              {translate(LoadingMessages[msgIndex])}
            </S.StyledMessage>
          </motion.div>
        </AnimatePresence>
      </S.TextWrapper>
    </S.AILoadingContainer>
  );
};

export default AILoadingView;
