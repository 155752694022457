import { rejectGroupInvitation } from "src/js/repository/groupRepository";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import fetchGroupInvitesList from "src/js/repository/groupInvitesListRepository";
import { joinGroup } from "src/js/repository/joinRepository";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useStores } from "src/js/hooks";
import { GroupInSpaceModel, SpaceListModel } from "src/js/types";
import { useTheme } from "styled-components";
import { GroupInvitation } from "./types";
import { showConfirmToast } from "./components/utils";

export const useGroupInvitation = (
  setGroupInvites: React.Dispatch<React.SetStateAction<GroupInvitation[]>>,
  groupInvites: GroupInvitation[],
  appendToGroupList: (group: GroupInSpaceModel) => void
) => {
  const { translate } = useTranslation();
  const {
    SpaceStore: { addNewSpaceToSpaceList, spacesList, activeSpace },
    SpaceGroupListStore: { addGroupAtStart },
    GroupStore: { fetchGroup }
  } = useStores();
  const { whiteTheme } = useTheme();

  const getInvites = async () => {
    const invites = await fetchGroupInvitesList();

    return invites;
  };

  const acceptInvite = async (inviteCode: string) => {
    return joinGroup(inviteCode).then(({ data }) => {
      const newGroups = groupInvites.filter(invite => {
        return invite.inviteCode !== inviteCode;
      });

      setGroupInvites(newGroups);
      if (data.id) {
        localStorage.setItem("groupId", `${data.id}`);
        if (data.is_joining) {
          _trackEvent("Group", "GroupJoin");
        }
        return data.id;
      }
      return null;
    });
  };

  const showConfirmation = async ({
    invitation
  }: {
    invitation: GroupInvitation;
  }) => {
    const groupId = await acceptInvite(invitation.inviteCode);

    if (!groupId) {
      return;
    }

    fetchGroup(groupId, false).then(group => {
      if (activeSpace && activeSpace.space.id === group.space.id) {
        appendToGroupList(group);
        addGroupAtStart(group);
      }

      // Space already exists in the list
      if (
        spacesList.find(
          ({ space: spaceInList }) => spaceInList.id === group.space.id
        )
      )
        return;

      addNewSpaceToSpaceList({ space: group.space } as SpaceListModel);
    });

    showConfirmToast({
      groupId,
      groupName: invitation.groupData.group.name,
      spaceSlug: invitation.groupData.space.slug,
      whiteTheme,
      translate
    });
  };

  const rejectInvite = ({ invitation }: { invitation: GroupInvitation }) => {
    rejectGroupInvitation(invitation.groupInviteUserId)
      .then(() => {
        showToastSuccess({ str: translate("invite_rejected") });
        setGroupInvites(
          groupInvites.filter(
            invite => invite.inviteCode !== invitation.inviteCode
          )
        );
      })
      .catch(() => {
        showToastError({ str: translate("reject_invitation_error_message") });
      });
  };

  return { showConfirmation, rejectInvite, getInvites };
};
