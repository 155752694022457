import styled, { css } from "styled-components";

export const MobileLogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 36px;
`;

export const Container = styled.div<{ hasGroupInvitation: boolean }>`
  display: flex;
  justify-content: center;
  gap: 148px;
  position: relative;
  flex-wrap: wrap;
  padding: 0px 100px 100px;

  ${({ hasGroupInvitation }) =>
    !hasGroupInvitation &&
    css`
      height: 100%;
      min-height: calc(100vh - 65px);
      align-items: center;
    `}

  @media screen and (max-width: 1300px) {
    gap: 24px;
  }

  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 32px 28px 32px;
  @media screen and (max-width: 767px) {
    padding: 28px 0 40px 0;
    justify-content: center;
  }
`;

export const LogoContainer = styled.div<{ hasInvite: boolean }>`
  width: 48px;
  height: 48px;
  background-color: ${({ theme, hasInvite }) =>
    hasInvite ? "unset" : theme.colors.violet[501]};
  color: ${({ theme, hasInvite }) =>
    hasInvite ? theme.colors.grey[1000] : theme.colors.yellow[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const LanguageSelectorContainer = styled.div`
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 60px;
    right: 4px;
    color: ${({ theme }) => theme?.colors?.grey[1000]};
  }
`;

export const GroupInformationContainer = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0px 16px;
  }
`;

export const MobileLinkContainer = styled.div`
  display: none;

  @media screen and (max-width: 1000px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const ChildrenContainer = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
