import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";

export function groupGetSettings(group) {
  if (group) {
    return group.group_settings;
  }
  return {};
}

export function groupGetCover(group) {
  if (typeof group.cover === "undefined" || group.cover == null) {
    return __GROUP_COVER_DEFAULT__;
  }
  return group.cover;
}

export function groupShouldShowRanking(group) {
  const group_settings = groupGetSettings(group);
  if (
    group_settings &&
    "gamification" in group_settings &&
    group_settings.gamification === "enabled"
  ) {
    return true;
  }
  return false;
}
