import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import find from "lodash/find";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import moment from "moment";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { Translate } from "src/js/translation/TranslationProvider";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { getExerciseIconString } from "src/js/modules/exercisesFunction";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";

const TrueFalseCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        execution: null,
        quiz_execution: null,
        corrector: null,
        number: 0
      };
    },
    render() {
      /* use collection in order to auto init correct submodel */
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const quiz = tempQuizCollection.at(0);
      const { execution, quiz_execution } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();

      const quizType = quiz.getType();
      const quizIcon = getExerciseIconString(
        this.props.isVideoSubquiz ? "quizvideo" : quizType
      );

      let subQuizImage = null;
      if (this.props.isVideoSubquiz) {
        subQuizImage = (
          <Icon icon={getExerciseIconString(quizType)} width="48" height="38" />
        );
      }

      let result_class = "";

      const isCorrect = execution.checkQuizIsCorrectById(quiz.get("id"));
      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));

      const trueAnswer = find(solutions, { value: true });
      const userTrueAnswer = find(userAnswers, { value: true });

      const userText =
        userTrueAnswer !== undefined &&
        questions[userTrueAnswer.index] !== undefined ? (
          questions[userTrueAnswer.index].value.toString()
        ) : (
          <Translate text="quiz_not_answered_label" />
        );
      const correctText = questions[trueAnswer.index].value.toString();

      if (isCorrect) {
        result_class = "is-correct";
      } else {
        result_class = "is-wrong";
      }

      const getCorrectLabel = value => {
        if (value === "vero") {
          return <Translate text="exercises_tests_see_results_true" />;
        }
        if (value === "falso")
          return <Translate text="exercises_tests_see_results_false" />;
        return value;
      };

      let voteType;
      if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      const executionData = execution.get("updated_at");
      const executionDataFormatted = moment
        .unix(executionData)
        .format("DD/MM/YYYY HH:mm");
      const executionFromNow = moment.unix(executionData).fromNow();

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon icon={quizIcon} width="60" height="60" />
              <ColorLabel type={voteType}>
                <strong>
                  {quiz_execution.points}/{quiz.getAvailablePoints()}
                </strong>{" "}
                <Translate text="quiz_points_label" />
              </ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  {subQuizImage}
                  <ExerciseQuizTitle quizTitle={quiz.getTitle()} />
                </div>
              </div>
              <div className="correction_answers-wrapper unselectable">
                <div className="answer_info margin-bottom-10">
                  <CommentTitle>
                    {__("quiz_report_reply_of_label")}&nbsp;
                    {execution.getQuizExecutioner()}
                  </CommentTitle>
                  <div className="margin-left-10 margin-left-xs-0">
                    <CommentSubtitle>
                      {`${executionDataFormatted} (${executionFromNow})`}
                    </CommentSubtitle>
                  </div>
                </div>
                <table className="table table-condensed table-borderless small">
                  <tbody>
                    <tr>
                      <th>
                        <Translate text="risposta corretta" />
                      </th>
                      <th>
                        <Translate text="risposta utente" />
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <div className="correction_answer-item">
                          {getCorrectLabel(correctText)}
                        </div>
                      </td>
                      <td>
                        <div className="correction_answer-item">
                          <span
                            className={[
                              "exen_summary_quiz_label",
                              "uppercase",
                              result_class
                            ].join(" ")}
                          >
                            {getCorrectLabel(userText)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {this.props.corrector}
            </div>
          </Col>
        </>
      );
    }
  })
);

export default TrueFalseCorrectionDisplayer;
