import { history } from "Legacy/modules/history";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import { UnderageApprovationPage } from "src/js/pages/App/UnderageApprovationPage";
import { JoinPage } from "src/js/pages/JoinPage";
import { Register } from "src/js/pages/registration/Register";
import ConfirmRegistration from "src/js/pages/unlogged/ConfirmRegistration";
import ExternalAuth from "src/js/pages/unlogged/ExternalAuth";
import { LoginPage } from "src/js/pages/unlogged/LoginPage";
import { LoginSSOPage } from "src/js/pages/unlogged/LoginSSOPage";
import { PrivacyPolicy } from "src/js/pages/unlogged/PrivacyPolicy";
import { RequestResetPasswordPage } from "src/js/pages/unlogged/RequestResetPasswordPage";
import { ResetPasswordPage } from "src/js/pages/unlogged/ResetPasswordPage";
import { UniversityFinderPage } from "src/js/pages/unlogged/UniversityFinderPage";
import UserConfirmMail from "src/js/pages/user/UserConfirmMail";
import SetLandingUrlAndRedirect from "src/js/routing/SetLandingUrlAndRedirect";
import { WeSchoolRoutes } from "../Routes.const";

export const AuthRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={WeSchoolRoutes.Auth.register}
          key="registrationRoute"
          component={Register}
        />
        <Route
          key="unloggedJoinRoute"
          exact
          path={WeSchoolRoutes.Auth.joinCodiceOption}
          component={JoinPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.loginUniversity}
          key="loginUniversityRoute"
          component={UniversityFinderPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.privacyPolicy}
          key="privacyPolicy"
          component={PrivacyPolicy}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.confirmRegistration}
          key="confirmRegistrationRoute"
          component={ConfirmRegistration}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.confirmRegistrationWithAlias}
          key="confirmRegistrationRoutewhitAlias"
          component={ConfirmRegistration}
        />
        <Route
          path={WeSchoolRoutes.Auth.requestPasswordReset}
          key="requestPassword"
          component={RequestResetPasswordPage}
        />
        <Route
          path={WeSchoolRoutes.Auth.resetPassword}
          key="requestPassword"
          component={ResetPasswordPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.login}
          key="loginRoute"
          component={LoginPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.sso}
          key="loginSSORoute"
          component={LoginSSOPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.externalAuth}
          key="AuthRoute"
          component={ExternalAuth}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.underageApprovation}
          key="parentApprobation"
          component={UnderageApprovationPage}
        />
        <Route
          exact
          path={WeSchoolRoutes.Auth.userConfirmMail}
          key="userConfirmMail"
          component={UserConfirmMail}
        />
        <Route
          // eslint-disable-next-line react/no-unstable-nested-components
          render={props => (
            <SetLandingUrlAndRedirect {...props} to={createUrl("login")} />
          )}
        />
      </Switch>
    </Router>
  );
};
