import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import React from "react";
import { BaseTextEditor } from "src/js/components/global/BaseTextEditor";
import { useEditor } from "src/js/components/global/BaseTextEditor/hooks";
import { TextEditorOptions } from "src/js/components/global/BaseTextEditor/EditorOptions.const";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { OldResource, Resource } from "src/js/types";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  height: 1px;
  min-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const EditorWrapper = styled.div`
  tr,
  td {
    border: 1px solid black;
  }
  height: 100%;
  border-radius: 8px;
  overflow: hidden;

  .EditorWrapper {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.grey[1000]};

    @media (max-width: 767px) {
      min-width: unset;
    }
  }

  .ProseMirror {
    ${scrollbarMixin(VariantType.Grey)}
    overflow: auto;
    padding: 16px;
    height: 100%;
    @media (max-width: 767px) {
      padding: 16px;
    }
  }
`;

const TextEditor = ({ resource }: { resource: OldResource | Resource }) => {
  const textContent =
    "content" in resource ? resource.content : resource.textContent;
  const editor = useEditor({
    options: { ...TextEditorOptions, editable: false, content: textContent }
  });
  return (
    <Wrapper>
      <MainContainer paddingTop="0px" paddingTopMobile="0px">
        <EditorWrapper>
          <BaseTextEditor editor={editor} className="EditorWrapper" />
        </EditorWrapper>
      </MainContainer>
    </Wrapper>
  );
};

export default TextEditor;
