import React from "react";
import { useStores } from "src/js/hooks";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { ClaimModal } from "../../ClaimModal";
import * as S from "./SidebarThreadsBlocked.styles";
import { SidebarThreadsBlockedProps } from "./SidebarThreadsBlocked.types";

const SidebarThreadsBlocked = ({
  isTemporarySpace,
  isClaimable,
  isDemoSpace
}: SidebarThreadsBlockedProps) => {
  const { whiteTheme } = useTheme();
  const {
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { translate } = useTranslation();

  const openClaimModal = () => {
    openModal(() => <ClaimModal close={closeModal} />);
  };

  let titleKey: TranslationKeys = null;
  if (isTemporarySpace) {
    titleKey = "sidebar_threads_blocked_temporary_space";
  } else if (isClaimable) {
    titleKey = "sidebar_threads_blocked_unclaimed";
  } else if (isDemoSpace) {
    titleKey = "sidebar_threads_blocked_demo_space";
  }

  return (
    <S.SidebarThreadsBlockedWrapper>
      <S.NoMessagesAnimation />
      {titleKey ? <S.Text>{translate(titleKey)}</S.Text> : null}
      {isClaimable ? (
        <S.Button onClick={openClaimModal} variant="primary" theme={whiteTheme}>
          {translate("sidebar_threads_blocked_cta_claim")}
        </S.Button>
      ) : null}
    </S.SidebarThreadsBlockedWrapper>
  );
};

export default SidebarThreadsBlocked;
