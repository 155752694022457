import { Text } from "@arcadia/design-system";
import { FooterWrapper } from "src/js/pages/spaces/space_creation/SchoolClaimForm/SchoolClaimSearch/SchoolClaimSearch.styles";
import styled from "styled-components";

export const SubtitleText = styled(Text)`
  display: block;
  text-align: center;
  white-space: pre-line;
  font-size: 16px;
`;

export const SchoolClaimModal = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  @media (max-width: 767px) {
    padding: 20px 16px 0px 16px;
  }
  ${FooterWrapper} {
    @media (max-width: 767px) {
      padding: 20px 16px;
    }
    border-radius: 8px;
  }
`;
