import { Box, Icon, Text } from "@arcadia/design-system";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { InfoChip } from "src/js/components/InfoChip";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { ResourcePickerLabel } from "src/js/types";
import { ResourcePickerModal } from "./components/ResourcePickerModal";
import * as S from "./ResourcePicker.styles";
import { DropdownItem, ResourcePickerProps } from "./ResourcePicker.types";
import useDropdownItems from "./useDropdownItems";
import {
  RESOURCE_PICKER_ITEMS,
  RESOURCE_PICKER_SECTION
} from "./ResourcePicker.const";
import {
  getEventDomainFromSection,
  getEventLabelFromItem
} from "./useDropdownItems.utils";

const ResourcePicker = ({
  section,
  onSubmitCallbackSuccess,
  onSubmitCallbackError,
  prepareModuleResourcePayload,
  setUploadingProgress,
  // This is an hacky way to avoid the opening in modal
  openNewResourceCustomWizard,
  onStartOfUpload,
  elementIndex,
  embedded,
  prepareWSResource,
  className,
  defaultIsOpen
}: ResourcePickerProps) => {
  const [searchValue, setValue] = useState("");
  const { dropdownItems, mergedItems } = useDropdownItems(
    section,
    prepareModuleResourcePayload,
    setUploadingProgress,
    elementIndex,
    onStartOfUpload,
    prepareWSResource
  );
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal, closeModal }
  } = useStores();

  const filterItems = (arr: DropdownItem[]) =>
    arr
      .filter(item =>
        searchValue
          ? item.label.toLowerCase().includes(searchValue.toLowerCase())
          : true
      )
      .filter(
        item =>
          !item.sections || (item.sections && item.sections.includes(section))
      );

  const displayEmptyState = () =>
    filterItems(mergedItems).length ? null : (
      <S.EmptyState>
        <Icon
          icon="illustration-ghost"
          height={130}
          width={98}
          initialViewbox
        />
        <Box textAlign="center" marginTop={16}>
          <Text type="formSubtitle">
            {translate("resource_picker_empty_state")}
          </Text>
        </Box>
      </S.EmptyState>
    );

  const openResourcePickerModal = (dropdownItem: DropdownItem) =>
    openModal(() => (
      <ResourcePickerModal
        body={
          <dropdownItem.ModalBody
            onSuccessCallback={successData => {
              onSubmitCallbackSuccess(dropdownItem.item, successData);
            }}
            onErrorCallback={onSubmitCallbackError}
          />
        }
        title={dropdownItem.title}
        subtitle={dropdownItem.subtitle}
        hasConfirm={dropdownItem.hasConfirm}
        hasCancel={dropdownItem.hasCancel}
        onCancel={closeModal}
        confirmText={dropdownItem.confirmText}
        cancelText={dropdownItem.cancelText}
        withWarningMessage={dropdownItem.withWarningMessage}
        type={dropdownItem.item}
        modalConfig={dropdownItem.modalConfig}
        onConfirm={() => {
          // Handle success callback
          // onSubmitCallbackSuccess(dropdownItem.item);
          // Handle error
          // onSubmitCallbackError();
        }}
      />
    ));

  const customMenu = (
    <S.MenuContainer className={className} id="resource-dropdown-container">
      <S.SearchInput
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        label=""
        hideLabel
        rounded={isLayoutModeMobile}
        type="field"
        onClick={() => {
          _trackEvent(
            getEventDomainFromSection(section),
            "Click",
            ResourcePickerLabel.Search
          );
        }}
        onChange={event => setValue(event.target.value)}
        placeholder={translate("search_for_resource_type")}
        value={searchValue}
        icon="search"
      />
      {Object.keys(dropdownItems).map(group => {
        return (
          <Box key={group} paddingRight={12} paddingLeft={12}>
            {filterItems(dropdownItems[group]).length ? (
              <Box marginBottom={12} paddingRight={4} paddingLeft={4}>
                <Text type="subHeaderSubtitle">{group}</Text>
              </Box>
            ) : null}

            <Box>
              {filterItems(dropdownItems[group]).map(
                (dropdownItem: DropdownItem) => {
                  return (
                    <S.MenuItem
                      key={dropdownItem.label}
                      id={dropdownItem.id}
                      onClick={() => {
                        _trackEvent(
                          getEventDomainFromSection(section),
                          "Click",
                          getEventLabelFromItem(dropdownItem.item)
                        );
                        if (
                          dropdownItem.item === RESOURCE_PICKER_ITEMS.EDITOR &&
                          (section === RESOURCE_PICKER_SECTION.MODULES ||
                            section === RESOURCE_PICKER_SECTION.WALL)
                        ) {
                          return openNewResourceCustomWizard?.(dropdownItem);
                        }
                        return openResourcePickerModal(dropdownItem);
                      }}
                    >
                      <S.IconContainer purple={dropdownItem.purpleBackground}>
                        {dropdownItem.resourceIcon}
                      </S.IconContainer>
                      <Box
                        marginLeft={12}
                        display="flex"
                        flexDirection="column"
                      >
                        <Text type="subHeaderSubtitle">
                          {dropdownItem.label}
                        </Text>
                        <S.ItemDescription type="formSmallCaption">
                          {dropdownItem.description}
                        </S.ItemDescription>
                      </Box>
                      {dropdownItem.isBeta && (
                        <Box marginLeft={8}>
                          <InfoChip
                            text={
                              dropdownItem.item === "editor"
                                ? "ai_tools_chip_text"
                                : "beta_chip_text"
                            }
                          />
                        </Box>
                      )}
                    </S.MenuItem>
                  );
                }
              )}
            </Box>
          </Box>
        );
      })}
      {displayEmptyState()}
    </S.MenuContainer>
  );

  if (embedded) {
    return customMenu;
  }

  return (
    <S.CustomDotsMenu
      id="resourcePickerDotsMenu"
      alignRight={false}
      customIcon="plusThin"
      customMenu={customMenu}
      defaultIsOpen={defaultIsOpen}
      withMobileHeaderLabels
      withMobileAdaptiveHeight={false}
      mobileCloseLabel={translate("resource_mobile_close_modal")}
      mobileConfirmLabel={translate("resource_mobile_confirm_modal")}
      mobileTitle={translate("resource_mobile_title_modal")}
    />
  );
};

export default ResourcePicker;
