import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const CardWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  transition: 300ms ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const CardDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
