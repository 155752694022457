import {
  Heading,
  Text,
  Slideshow as WSSlideshow
} from "@arcadia/design-system";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Slideshow = styled(WSSlideshow)`
  height: 174px;

  @media (max-width: 350px) {
    height: 200px;
  }
  @media (max-width: 292px) {
    height: 250px;
  }
`;

export const BlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 30px 1fr;
  gap: 8px 16px;
  padding: 16px;

  @media (max-width: 767px) {
    padding: 8px 16px 16px;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTitle = styled(Heading)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledText = styled(Text)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grey[300]};
`;
