import React from "react";
import { Heading, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

import * as S from "./BlockedBoardElement.styles";

const BlockedBoardElement = () => {
  const { translate } = useTranslation();
  return (
    <S.BlockedBoardWrapper>
      <S.BlockedBoardElementImage />
      <S.TextBlock>
        <Heading level="6">
          {translate("board_element_view_blocked_title")}
        </Heading>
        <Text type="formDescription">
          {translate("board_element_view_blocked_description")}
        </Text>
      </S.TextBlock>
    </S.BlockedBoardWrapper>
  );
};

export default BlockedBoardElement;
