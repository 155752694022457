import {
  fetchAssessmentScores,
  fetchGroupsProgress,
  fetchUserTrainingHours,
  fetchUsersLearningTime,
  fetchUsersSessionsCount
} from "../../repository/analyticsRepository";
import {
  FetchAssessmentScoresParams,
  FetchGroupsProgressParams,
  FetchUserTrainingHoursParams,
  FetchUsersLearningTimeParams,
  FetchUsersSessionsCountParams
} from "../../types";
import { useQuery } from "../hooks";

// Query keys

const __ANALYTICS_KEY_ALL__ = "analytics";

const groupsProgress = (
  spaceId: string,
  groupIds: string[],
  withChartData: boolean,
  fromDate: string,
  toDate: string,
  offset: number
) => [
  __ANALYTICS_KEY_ALL__,
  "groupsProgress",
  spaceId,
  groupIds,
  withChartData,
  fromDate,
  toDate,
  offset
];

const assessmentScores = (
  spaceId: string,
  groupIds: string[],
  withChartData: boolean,
  fromDate: string,
  toDate: string
) => [
  __ANALYTICS_KEY_ALL__,
  "assessmentScores",
  spaceId,
  groupIds,
  withChartData,
  fromDate,
  toDate
];

const userTrainingHours = (
  spaceId: string,
  groupIds: string[],
  withChartData: boolean,
  fromDate: string,
  toDate: string,
  aggregation: string
) => [
  __ANALYTICS_KEY_ALL__,
  "userTrainingHours",
  spaceId,
  groupIds,
  withChartData,
  fromDate,
  toDate,
  aggregation
];

const usersLearningTime = (
  spaceId: string,
  groupIds: string[],
  withChartData: boolean,
  fromDate: string,
  toDate: string,
  aggregation: string
) => [
  __ANALYTICS_KEY_ALL__,
  "usersLearningTime",
  spaceId,
  groupIds,
  withChartData,
  fromDate,
  toDate,
  aggregation
];

const usersSessionsCount = (
  spaceId: string,
  groupIds: string[],
  withChartData: boolean,
  fromDate: string,
  toDate: string,
  aggregation: string
) => [
  __ANALYTICS_KEY_ALL__,
  "usersSessionsCount",
  spaceId,
  groupIds,
  withChartData,
  fromDate,
  toDate,
  aggregation
];

export const analyticsQueryKey = {
  groupsProgress,
  assessmentScores,
  userTrainingHours,
  usersLearningTime,
  usersSessionsCount
} as const;

// Hooks

export const useGroupsProgress = (params: FetchGroupsProgressParams) => {
  return useQuery(
    analyticsQueryKey.groupsProgress(
      params?.spaceId,
      params?.groupIds,
      params?.withChartData,
      params?.fromDate,
      params?.toDate,
      params?.offset
    ),
    () => fetchGroupsProgress(params)
  );
};

export const useAssessmentScores = (params: FetchAssessmentScoresParams) => {
  return useQuery(
    analyticsQueryKey.assessmentScores(
      params?.spaceId,
      params?.groupIds,
      params?.withChartData,
      params?.fromDate,
      params?.toDate
    ),
    () => fetchAssessmentScores(params)
  );
};

export const useUserTrainingHours = (params: FetchUserTrainingHoursParams) => {
  return useQuery(
    analyticsQueryKey.userTrainingHours(
      params?.spaceId,
      params?.groupIds,
      params?.withChartData,
      params?.fromDate,
      params?.toDate,
      params?.aggregation
    ),
    () => fetchUserTrainingHours(params)
  );
};

export const useUsersLearningTime = (params: FetchUsersLearningTimeParams) => {
  return useQuery(
    analyticsQueryKey.usersLearningTime(
      params?.spaceId,
      params?.groupIds,
      params?.withChartData,
      params?.fromDate,
      params?.toDate,
      params?.aggregation
    ),
    () => fetchUsersLearningTime(params)
  );
};

export const useUsersSessionsCount = (
  params: FetchUsersSessionsCountParams
) => {
  return useQuery(
    analyticsQueryKey.usersSessionsCount(
      params?.spaceId,
      params?.groupIds,
      params?.withChartData,
      params?.fromDate,
      params?.toDate,
      params?.aggregation
    ),
    () => fetchUsersSessionsCount(params)
  );
};
