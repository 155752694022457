import React, { FC } from "react";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";

import * as S from "./LinkButton.styles";
import { LinkButtonProps } from "./LinkButton.types";

const LinkButton: FC<LinkButtonProps> = ({
  className,
  children,
  disabled,
  onClick,
  icon = {},
  type = "button",
  id
}) => {
  const idButton = id || String(Math.floor(Math.random() * 1000) + 1);
  return (
    <S.LinkButtonWrap
      className={className}
      type={type}
      disabled={disabled}
      onClick={onClick}
      icon={icon}
      id={idButton}
    >
      <Text type="textLink">{children}</Text>
      {icon.icon ? (
        <Icon
          initialViewbox
          icon={icon.icon}
          height={icon.heightIcon}
          width={icon.widthIcon}
          viewbox={icon.viewbox}
        />
      ) : null}
    </S.LinkButtonWrap>
  );
};

export default LinkButton;
