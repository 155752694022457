import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import {
  createSpaceBadge,
  editSpaceBadge,
  updateBadgeRelatedGroups
} from "src/js/repository/badgesRepository";
import { useTranslation } from "src/js/translation";
import useResourceUpload, {
  ResponseFileData
} from "src/js/hooks/resources/useResourceUpload";
import { RESOURCE_TYPE, SpaceBadge } from "src/js/types";
import { BadgeCreationRequestPayload } from "src/js/repository/types";

const useCreateOrEditBadge = ({
  setLoading
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const { translate } = useTranslation();
  const { handleResourceFileUpload } = useResourceUpload();
  const {
    SpaceBadgesStore: {
      badgeOptionalFields,
      badgeRequiredFields,
      selectedBadgeRelatedGroups,
      setEditingBadge,
      setEmitStudentsDrawerOpen,
      reset
    },
    SpaceStore: { activeSpaceId },
    ModalStore: { closeModal },
    SpaceBadgesListStore: { fetchFilteredBadges }
  } = useStores();

  const handleEditBadge = async (badgeId: string, data?: ResponseFileData) => {
    const { criteria, description, issuer, image, name, customBadgeImage } =
      badgeRequiredFields;
    const { duration, isPrivate } = badgeOptionalFields;
    const hasCustomBadgeImage = !!customBadgeImage;
    const badgeToEdit: BadgeCreationRequestPayload = {
      spaceId: activeSpaceId,
      name,
      description,
      criteria,
      image: hasCustomBadgeImage ? data.fileUrl : image,
      issuerName: issuer,
      duration,
      isPrivate
    };

    try {
      setLoading(true);
      const badge = await editSpaceBadge(badgeToEdit, badgeId);

      fetchFilteredBadges(activeSpaceId);
      closeModal();
      setLoading(false);

      reset();

      return badge;
    } catch (error) {
      setLoading(false);
      showToastError({
        str: translate("space_badge_create_badge_error_alert")
      });
      return error;
    }
  };

  const handleCreateBadge = async (
    data?: ResponseFileData,
    createAndEmitBadge?: boolean
  ) => {
    const { criteria, description, issuer, image, name, customBadgeImage } =
      badgeRequiredFields;
    const { duration, isPrivate } = badgeOptionalFields;
    const hasCustomBadgeImage = !!customBadgeImage;
    const badgeToCreate: BadgeCreationRequestPayload = {
      spaceId: activeSpaceId,
      name,
      description,
      criteria,
      image: hasCustomBadgeImage ? data.fileUrl : image,
      issuerName: issuer,
      duration,
      isPrivate
    };

    try {
      setLoading(true);
      const badge = await createSpaceBadge(badgeToCreate);

      const badgeToEdit: SpaceBadge = {
        ...badgeToCreate,
        id: badge.id
      };

      if (badge.id && selectedBadgeRelatedGroups.length) {
        const groupIds = selectedBadgeRelatedGroups.map(groupId =>
          parseInt(groupId, 10)
        );
        await updateBadgeRelatedGroups(groupIds, [], badge.id);
        fetchFilteredBadges(activeSpaceId);
        closeModal();

        if (createAndEmitBadge) {
          setEditingBadge(badgeToEdit);
          setEmitStudentsDrawerOpen(true);
        }
        reset();
        setLoading(false);
        return badge;
      }

      fetchFilteredBadges(activeSpaceId);
      closeModal();

      if (createAndEmitBadge) {
        setEditingBadge(badgeToEdit);
        setEmitStudentsDrawerOpen(true);
      }
      reset();
      setLoading(false);

      return badge;
    } catch (error) {
      showToastError({
        str: translate("space_badge_create_badge_error_alert")
      });
      return error;
    }
  };

  const editBadge = async (badgeId: string) => {
    const { customBadgeImage } = badgeRequiredFields;

    const hasCustomBadgeImage = !!customBadgeImage;

    if (hasCustomBadgeImage) {
      return handleResourceFileUpload(
        {
          file: customBadgeImage,
          spaceSlug: activeSpaceId,
          uploadingElementId: 0
        },
        false,
        (data?: ResponseFileData) => handleEditBadge(badgeId, data),
        RESOURCE_TYPE.BADGE
      );
    }

    return handleEditBadge(badgeId);
  };

  const createBadge = async (createAndEmitBadge?: boolean) => {
    const { customBadgeImage } = badgeRequiredFields;

    const hasCustomBadgeImage = !!customBadgeImage;

    if (hasCustomBadgeImage) {
      return handleResourceFileUpload(
        {
          file: customBadgeImage,
          spaceSlug: activeSpaceId,
          uploadingElementId: 0
        },
        false,
        responseFileData =>
          handleCreateBadge(responseFileData, createAndEmitBadge),
        RESOURCE_TYPE.BADGE
      );
    }

    return handleCreateBadge(null, createAndEmitBadge);
  };

  return { createBadge, editBadge };
};

export default useCreateOrEditBadge;
