import React, { useRef, useState } from "react";
import { Datepicker, locale } from "@mobiscroll/react";
import { getCurrentLanguage } from "src/js/modules/localization";
import { Icon } from "@arcadia/design-system";
import { calendar } from "#themes/grey/calendar";
import * as S from "./MonthPicker.styles";

const MonthPicker = ({ currentDate, setCurrentDate }) => {
  const wrapperRef = useRef();
  const datepickerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const currentLanguage = getCurrentLanguage().split("_")[0];

  const togglePicker = () => {
    return isOpen
      ? datepickerRef.current.close()
      : datepickerRef.current.open();
  };

  return (
    <S.DatePickerStyled ref={wrapperRef} theme={calendar}>
      <Datepicker
        ref={datepickerRef}
        controls={["calendar"]}
        touchUi={false}
        dateFormat="MMMM YYYY"
        cssClass="ws-single-month-picker"
        context={wrapperRef.current}
        theme="material"
        locale={locale[currentLanguage]}
        value={currentDate}
        onChange={({ value }) => setCurrentDate(value)}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      />
      <S.ChevronContainer open={isOpen} onClick={() => togglePicker()}>
        <Icon icon="chevronDown" height={15} width={15} />
      </S.ChevronContainer>
    </S.DatePickerStyled>
  );
};

export default MonthPicker;
