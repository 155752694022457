import { Avatar } from "@arcadia/design-system";
import styled from "styled-components";

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  width: calc(100% - 40px);
`;

export const StyledAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
`;
