import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import { Text, Icon } from "@arcadia/design-system";
import { __ } from "../../../modules/localization";

const QuizTrueFalse = withBackbone(
  createReactClass({
    setAnswer(answer) {
      this.props.quiz.setUserAnswer(answer);
    },
    render() {
      const self = this;
      const { quiz } = this.props;
      return (
        <div className="quiz__truefalse" ref="answersForm">
          {map(quiz.getQuestions(), question => {
            const i = question.index;
            const q = question.value;
            let activeClass = "";
            let icon = null;

            if (quiz.getUserAnswer() === question.value) {
              activeClass = "is-active";
              icon = (
                <Icon icon="check" width={20} height={20} viewbox="0 0 10 10" />
              );
            }
            return (
              <div
                className={[
                  "clickable",
                  "quiz__multichoice-option",
                  activeClass
                ].join(" ")}
                onClick={self.setAnswer.bind(null, q)}
                onTouchEnd={self.setAnswer.bind(null, q)}
                data-index={i}
                data-question={q}
              >
                <div
                  className={[
                    "quiz__multichoice-option-check",
                    activeClass
                  ].join(" ")}
                >
                  {icon}
                </div>
                <Text type="formField">{__(q.toUpperCase())}</Text>
              </div>
            );
          })}
        </div>
      );
    }
  })
);

export default QuizTrueFalse;
