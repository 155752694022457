import styled from "styled-components";

import { SLiveTagProps } from "./types";

export const LiveTag = styled.div<SLiveTagProps>`
  width: 51px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  border-radius: 100px;
  background: ${({ theme, status }) =>
    status === "finished"
      ? theme.livetag.finished.background
      : theme.livetag.background};
  color: ${({ theme, status }) =>
    status === "finished" ? theme.livetag.finished.text : theme.livetag.text};
  border: ${({ theme, status }) =>
    status === "finished"
      ? theme.livetag.finished.border
      : theme.livetag.border};
  box-sizing: border-box;
  gap: 6px;
  & div {
    display: flex;
  }
  margin: 2px;
`;
