import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "@arcadia/design-system";
import { DotsMenu as DSDotsMenu } from "../DotsMenu";

export const DotsMenu = styled(DSDotsMenu)<{ color: string }>`
  font: inherit;
  margin-top: 2px;
  margin-left: auto;
  color: ${({ color }) => color};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  color: ${colors.grey[50]};
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid ${colors.grey[800]};
  padding: 12px 0;
  position: relative;
  min-width: 260px;
  text-align: left;
`;

export const PlanInfoBox = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  text-align: left;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
`;

export const LearnMoreLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grey[1000]};
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;
