import { Palette } from "@ds_themes/types";

export const createAnalyticsTooltipTheme = ({ primary, grey }: Palette) => {
  return {
    analyticstooltip: {
      background: grey[1000],
      subject: {
        color: primary.main
      },
      yValue: {
        color: grey[50]
      },
      xValue: {
        color: grey[300]
      }
    }
  };
};
