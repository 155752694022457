export const SpaceSchoolCardVariant = {
  Unique: "unique",
  Claimed: "claimed",
  Unclaimed: "unclaimed",
  Selected: "selected"
} as const;
export type SpaceSchoolCardVariant =
  (typeof SpaceSchoolCardVariant)[keyof typeof SpaceSchoolCardVariant];

export type SpaceSchoolCardProps = {
  className?: string;
  variant: SpaceSchoolCardVariant;
  spaceName: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  isSelected?: boolean;
  isTransparent?: boolean;
  color?: number;
  url?: string;
  address?: string;
};
