import React, { useEffect, useState } from "react";
import { useTranslation } from "src/js/translation";
import * as S from "./UploadingResource.styles";

const UploadingResource = ({ progress }: { progress: number }) => {
  const { translate } = useTranslation();
  const [uploadingProgress, setUploadingProgress] = useState<number>(1);

  useEffect(() => {
    if (progress) {
      setUploadingProgress(progress);
    }
  }, [progress]);

  return (
    <S.UploadingContainer>
      <S.UploadText type="cta">
        {translate("uploading_resource_placeholder")}.
      </S.UploadText>
      <S.ProgressBarContainer>
        <S.ProgressBar uploadingProgress={uploadingProgress} />
      </S.ProgressBarContainer>
      <S.PercentageTextContainer>
        <S.PercentageMovingText
          uploadingProgress={uploadingProgress}
          type="formSmallCaption"
        >
          {uploadingProgress}%
        </S.PercentageMovingText>
      </S.PercentageTextContainer>
    </S.UploadingContainer>
  );
};

export default UploadingResource;
