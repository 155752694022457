import { Icon } from "@arcadia/design-system";
import React, { useMemo } from "react";
import { __BOARD_COLOR__ } from "src/js/settings/settingsBoard";
import * as S from "./ColorSwatchPicker.styles";

// eslint-disable-next-line react/function-component-definition
export default function ColorSwatchPicker({ onChange, value }) {
  const colorsSwatch = useMemo(() => {
    return [...__BOARD_COLOR__.entries()].map(colorCode => {
      return {
        code: colorCode[0],
        hex: colorCode[1]
      };
    });
  }, []);

  function onColorChange(color) {
    onChange(color);
  }

  return (
    <S.ColorSwatchPickerWrapper>
      {colorsSwatch.map(colorObj => (
        <S.ColorSwatch
          role="button"
          color={colorObj.hex}
          onClick={() => onColorChange(colorObj.code)}
          active={value === colorObj.code}
          key={colorObj.code}
        >
          {colorObj.code === value && (
            <Icon icon="check2" height={10} width={10} />
          )}
        </S.ColorSwatch>
      ))}
    </S.ColorSwatchPickerWrapper>
  );
}
