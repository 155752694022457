import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";

import * as S from "./Tag.styles";
import { TagProps } from "./types";

const Tag: FC<TagProps> = ({
  theme,
  id,
  label = "tag default",
  icon,
  color = "violet",
  type = "solid",
  onClick = () => {},
  fat
}) => {
  const handleRemoveKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    switch (e.key) {
      case "Enter":
      case " ":
        onClick(id);
        break;
      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <S.Tag
        id={id}
        icon={icon}
        role="checkbox"
        aria-checked
        color={color}
        type={type}
        fat={fat}
        onClick={e => {
          e.stopPropagation();
          onClick(id);
        }}
        onKeyDown={handleRemoveKeyDown}
        tabIndex={0}
        aria-label={label}
      >
        {icon ? (
          <Icon
            icon={icon}
            height={fat ? "16px" : "12px"}
            width={fat ? "16px" : "12px"}
          />
        ) : null}
        <Text type={fat ? "labelWide" : "label"} aria-hidden="true">
          {label}
        </Text>

        <Icon icon="closeBold" height="8px" width="8px" aria-hidden="true" />
      </S.Tag>
    </ThemeProvider>
  );
};

export default Tag;
