import styled from "styled-components";

import { motion } from "framer-motion";

export const LoaderWrapper = styled(motion.div)<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
