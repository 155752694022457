import { add, formatISO } from "date-fns";
import { Avatar, Icon, Text } from "@arcadia/design-system";
import React from "react";
import { useTheme } from "styled-components";
import { BadgeRelatedUserType, SpaceBadge } from "src/js/types";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useTranslation } from "src/js/translation";
import useBadgeOptionFields from "src/js/pages/spaces/dashboard/SpacesBadges/components/BadgeOptionalFields/useBadgeOptionalFields";
import * as S from "./SpaceBadgeRewardedUserModal.styles";
import SpaceAwardedBadgeImage from "../SpaceAwardedBadgeImage";
import DownloadPDFCertificate from "../DownloadPDFCertificate";

type BadgeRewardedUserProps = {
  user: BadgeRelatedUserType;
  badge: SpaceBadge;
};

const SpaceBadgeRewardedUser = ({ user, badge }: BadgeRewardedUserProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { durationPeriodOptions } = useBadgeOptionFields();

  const {
    name,
    surname,
    avatar,
    nftReference,
    openBadgePublicUrl,
    transactionReference,
    awardedWeschool
  } = user;
  const userAwardedNFT = !!nftReference;
  const { image } = badge;

  const tooltipItems = [
    awardedWeschool && {
      text: translate("space_badge_tooltip_item_badge_details"),
      onClick: () => window.open(openBadgePublicUrl)
    },
    userAwardedNFT && {
      text: translate("space_badge_tooltip_item_nft_details"),
      onClick: () => window.open(nftReference)
    },
    userAwardedNFT && {
      text: translate("space_badge_tooltip_item_blockchain_explorer"),
      onClick: () => window.open(transactionReference)
    }
  ].filter(item => item);

  const getDurationLabel = () => {
    const selectedDuration = durationPeriodOptions.find(
      option => option.id === badge.duration
    );
    const { months, years } = selectedDuration;
    return formatISO(add(new Date(user.awardedAt), { months, years }));
  };

  return (
    <S.ModalUserContainer>
      <S.ModalUserAvatar>
        <Avatar
          size="s"
          theme={whiteTheme}
          alt={`${name} ${surname}`}
          url={avatar?.originalUrl ?? ""}
        />
      </S.ModalUserAvatar>
      <S.ModalUserName>
        <Text type="headerTitle">{`${name} ${surname}`}</Text>
      </S.ModalUserName>
      <TranslatedTooltip
        placement="topRight"
        customTooltip={
          <S.TooltipContainer>
            {tooltipItems.map(({ text, onClick }) => (
              <S.TooltipItem key={text} onClick={onClick}>
                <Text type="textLink">{text}</Text>
                <Icon
                  icon="externalLink"
                  width={16}
                  height={16}
                  initialViewbox
                />
              </S.TooltipItem>
            ))}
            <DownloadPDFCertificate
              badgeDescription={badge.description}
              badgeImage={badge.image}
              badgeName={badge.name}
              openBadgePublicUrl={user.openBadgePublicUrl}
              userFullName={`${user.name} ${user.surname}`}
              awardedAt={user.awardedAt}
              expiredAt={badge.duration ? getDurationLabel() : null}
              spaceId={badge.spaceId}
            />
          </S.TooltipContainer>
        }
      >
        <S.BadgeImageContainer>
          <S.BadgeImageHoverLayer />
          <SpaceAwardedBadgeImage
            chainId={user.chainId}
            image={image}
            userAwardedNFTBadge={userAwardedNFT}
          />
        </S.BadgeImageContainer>
      </TranslatedTooltip>
    </S.ModalUserContainer>
  );
};

export default SpaceBadgeRewardedUser;
