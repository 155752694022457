import { SocialLoginButtonThemeOptions, Palette } from "@ds_themes/types";

export const createSocialLoginButtonTheme = ({
  general,
  primary,
  grey
}: Palette): { socialLoginButton: SocialLoginButtonThemeOptions } => {
  return {
    socialLoginButton: {
      background: general.background,
      text: general.contrastText,
      border: `1px solid ${grey[800]}`,
      hover: {
        border: `1px solid ${primary.main}`
      },
      focus: {
        border: `3px solid ${primary.light}`
      }
    }
  };
};
