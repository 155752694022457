import React from "react";
import { useTheme } from "styled-components";
import { ActionButton } from "@arcadia/design-system";
import * as S from "./ModalHeader.styles";

const ModalHeader = ({ closeModal = () => {}, children }) => {
  const { whiteTheme } = useTheme();
  return (
    <S.ModalHeaderWrapper>
      <S.ModalCloseContainer>
        <ActionButton
          theme={whiteTheme}
          icon="close"
          variant="tertiary"
          onClick={closeModal}
        />
      </S.ModalCloseContainer>
      <S.ModalTitleContainer>{children}</S.ModalTitleContainer>
    </S.ModalHeaderWrapper>
  );
};

export default ModalHeader;
