import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./PickerTest.styles";
import { PickerTestProps } from "./types";

const PickerTest: FC<PickerTestProps> = ({ theme, pickerElement, onClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.Grid>
        {pickerElement.map(({ type, logo, title }, i) => {
          return (
            <S.Cell
              key={`${i} ${type}`}
              type="button"
              onClick={() => {
                onClick?.(type);
              }}
            >
              <Icon icon={logo} width="24" height="24" />
              <Text type="body">{title}</Text>
            </S.Cell>
          );
        })}
      </S.Grid>
    </ThemeProvider>
  );
};

export default PickerTest;
