import { RefObject, useRef, useEffect, useCallback } from "react";

const useScrollToBottom = <T extends HTMLElement>(
  onScrollToBottom: () => void
): RefObject<T> => {
  const ref = useRef<T>(null);

  const handleScroll = useCallback(() => {
    const element = ref.current;
    if (element) {
      const { scrollTop, clientHeight, scrollHeight } = element;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;

      if (isAtBottom) {
        onScrollToBottom();
      }
    }
  }, [onScrollToBottom]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return () => {};
    element.addEventListener("scroll", handleScroll);
    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return ref;
};

export default useScrollToBottom;
