import {
  Box,
  Button,
  ClosableFeedback,
  ContentSwitcher,
  Heading,
  Input,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { ClosableWrapper } from "src/js/components/global/ClosableWrapper";
import { GroupInviteImportModal } from "src/js/components/group/GroupInviteImportModal";
import { useOnboarding, useStores, useTimeout } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import {
  createMarketingData,
  updateMarketingData
} from "src/js/repository/marketingDataRepository";
import {
  __ROLE_STUDENT__,
  __ROLE_TEACHER__
} from "src/js/settings/settingsUser";
import { useTranslation } from "src/js/translation";
import { EventDomain, GroupUserRoleEnum, OnboardingEvent } from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { useEmailInvite } from "../useEmailInvite";
import { EmailInviteProps } from "./types";

import * as S from "./EmailInvite.styles";

const EmailInvite = ({ theme, shouldScrollToEmailList }: EmailInviteProps) => {
  const {
    UserStore: { setUserOnboarding },
    ModalStore: { openModal },
    UserInviteStore: {
      usersToInvite,
      usersToInviteRole,
      setRole,
      resetGroupList,
      fetchEmailList,
      removeUserFromList
    },
    SpaceStore: { isDemoSpace, activeSpace },
    GroupStore: { activeGroup },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const {
    onChange,
    inviteMembers,
    onKeyDown,
    onPaste,
    setIsFocused,
    setInvitationSent,
    setUsersInvited,
    inputValue,
    isFocused,
    invitationSent,
    usersInvited
  } = useEmailInvite();
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const { isOnboardingFlow } = useOnboarding();
  const inputRef = useRef<HTMLInputElement>();

  const importUser = (groupId: number) => {
    fetchEmailList(groupId).catch(() => {
      showToastError({
        str: translate("error_fetch_group_import_email_list")
      });
    });
  };

  const scrollToEmailList = () => {
    inputRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const openImportModal = () => {
    resetGroupList();
    openModal(() => (
      <GroupInviteImportModal
        importGroup={(groupId: number) => importUser(groupId)}
      />
    ));
  };

  const handleClosableFeedback = () => {
    setUsersInvited(false);
    setInvitationSent(false);
    inputRef.current.focus();
  };

  const hideSuccessFeedback = useTimeout(handleClosableFeedback, 5000);

  const setOnboardingAndRedirect = () => {
    _trackEvent(EventDomain.Onboarding, OnboardingEvent.CompleteFlow);
    setUserOnboarding({
      userType: USER_TYPE.BUSINESS,
      isCompleted: true,
      step: WeSchoolRoutes.Onboarding.membersInvite
    });
    navigateTo(
      createUrl("wall", {
        space_slug: activeSpace.space.slug,
        group_id: activeGroup.id
      })
    );
  };

  const goToActiveGroup = () => {
    updateMarketingData({
      userType: USER_TYPE.BUSINESS,
      onboardingCompleted: true
    })
      .then(() => {
        setOnboardingAndRedirect();
      })
      .catch(error => {
        if (error.response?.status === 404) {
          createMarketingData({
            userType: USER_TYPE.BUSINESS,
            onboardingCompleted: true
          })
            .then(() => {
              setOnboardingAndRedirect();
            })
            .catch(debugError => {
              // ideally this should never happen
              console.log({ debugError });
            });
        }
      });
  };

  const handleInviteMembers = () => {
    setInvitationSent(true);
    inviteMembers(() => {
      if (isOnboardingFlow) {
        _trackEvent(EventDomain.Onboarding, OnboardingEvent.SendInvite);
        goToActiveGroup();
      }
      setUsersInvited(true);
      hideSuccessFeedback.start();
    });
  };

  const skipOnboardingStep = () => {
    if (isOnboardingFlow) {
      _trackEvent(EventDomain.Onboarding, OnboardingEvent.SkipInvite);
      goToActiveGroup();
    }
  };

  useEffect(() => {
    if (inputRef.current && shouldScrollToEmailList) {
      scrollToEmailList();
    }
  }, [shouldScrollToEmailList]);

  useEffect(() => {
    if (isOnboardingFlow) {
      setRole(__ROLE_TEACHER__);
    }
  }, []);

  const isInviteDisabled =
    !usersToInvite.length || isDemoSpace || invitationSent;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !isInviteDisabled) {
      handleInviteMembers();
    }
    onKeyDown(event);
  };

  const onContentItemChange = (item: string) => {
    setRole(item as GroupUserRoleEnum);
    handleClosableFeedback();
  };

  const standardView = (
    <S.Container>
      <S.InputWrapper
        isFocused={isFocused}
        onClick={() => inputRef.current.focus()}
        onKeyDown={handleKeyDown}
        onPaste={onPaste}
      >
        {!!usersToInvite.length && (
          <S.ChipContainer>
            {usersToInvite.map(email => (
              <S.Chip key={email}>
                <Text type="label">{email}</Text>
                <Box
                  marginLeft={8}
                  width="max-content"
                  onClick={() => removeUserFromList(email)}
                >
                  <S.CloseIcon icon="close" width={8} height={8} />
                </Box>
              </S.Chip>
            ))}
          </S.ChipContainer>
        )}
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={onChange}
          label=""
          hideLabel
          theme={whiteTheme}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={translate("input_email_invite_placeholder")}
        />
      </S.InputWrapper>
      <Box
        marginTop={isLayoutModeMobile ? 16 : 32}
        display="flex"
        justifyContent={usersInvited ? "space-between" : "flex-end"}
        alignItems="center"
        flexDirection={isLayoutModeMobile ? "column" : "row"}
        gap={isLayoutModeMobile ? "16px" : null}
      >
        {usersInvited && (
          <S.SuccessFeedback>
            <ClosableWrapper>
              <ClosableFeedback
                text={translate(
                  "group_invite_modal_success_message_invitation"
                )}
                onClickFunction={handleClosableFeedback}
              />
            </ClosableWrapper>
          </S.SuccessFeedback>
        )}
        <S.ButtonsContainer onboardingFlow={isOnboardingFlow}>
          <Button
            theme={theme}
            variant="secondary"
            onClick={skipOnboardingStep}
            disabled={isDemoSpace}
          >
            {translate("onboarding_skip_invite_members_step")}
          </Button>
          <Button
            theme={theme}
            variant="primary"
            onClick={handleInviteMembers}
            disabled={!usersToInvite.length || isDemoSpace || invitationSent}
          >
            {translate("invite")}
          </Button>
        </S.ButtonsContainer>
      </Box>
    </S.Container>
  );

  const collapsibleView = (
    <S.CollapsibleContainer
      HeaderComponent={
        <Heading level="5">
          {translate("group_settings_invite_modal_section_email")}
        </Heading>
      }
    >
      <ContentSwitcher
        theme={greyTheme}
        items={[
          {
            id: "Student",
            value: __ROLE_STUDENT__,
            children: (
              <Text type="cta">{translate("group_invite_modal_students")}</Text>
            )
          },
          {
            id: "Teacher",
            value: __ROLE_TEACHER__,
            children: (
              <Text type="cta">{translate("group_invite_modal_teachers")}</Text>
            )
          }
        ]}
        squared={false}
        onContentItemChange={onContentItemChange}
        disabled={false}
        activeOption={usersToInviteRole}
      />
      <Box marginTop={20} marginBottom={8}>
        <S.LabelText type="formSubtitle">
          {translate(
            usersToInviteRole === __ROLE_STUDENT__
              ? "group_settings_invite_modal_label_students"
              : "group_settings_invite_modal_label_teachers"
          )}
        </S.LabelText>
      </Box>

      <S.InputWrapper
        isFocused={isFocused}
        onClick={() => inputRef.current.focus()}
        onKeyDown={handleKeyDown}
        onPaste={onPaste}
      >
        {!!usersToInvite.length && (
          <S.ChipContainer>
            {usersToInvite.map(email => (
              <S.Chip key={email}>
                <Text type="label">{email}</Text>
                <Box
                  marginLeft={8}
                  width="max-content"
                  onClick={() => removeUserFromList(email)}
                >
                  <S.CloseIcon icon="close" width={8} height={8} />
                </Box>
              </S.Chip>
            ))}
          </S.ChipContainer>
        )}
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={onChange}
          label=""
          hideLabel
          theme={whiteTheme}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={translate("input_email_invite_placeholder")}
        />
      </S.InputWrapper>
      <Box
        marginTop={isLayoutModeMobile ? 16 : 32}
        marginBottom={24}
        display="flex"
        justifyContent={usersInvited ? "space-between" : "flex-end"}
        alignItems="center"
        flexDirection={isLayoutModeMobile ? "column" : "row"}
        gap={isLayoutModeMobile ? "16px" : null}
      >
        {usersInvited && (
          <S.SuccessFeedback>
            <ClosableWrapper>
              <ClosableFeedback
                text={translate(
                  "group_invite_modal_success_message_invitation"
                )}
                onClickFunction={handleClosableFeedback}
              />
            </ClosableWrapper>
          </S.SuccessFeedback>
        )}
        <Box display="flex" gap={8}>
          <Button
            theme={theme}
            variant="secondary"
            onClick={openImportModal}
            disabled={isDemoSpace}
          >
            {`${translate("group_settings_invite_modal_import")} ${translate(
              usersToInviteRole === __ROLE_STUDENT__
                ? "group_invite_modal_students"
                : "group_invite_modal_teachers"
            )}`}
          </Button>
          <Button
            theme={theme}
            variant="primary"
            onClick={handleInviteMembers}
            disabled={isInviteDisabled}
          >
            {translate("invite")}
          </Button>
        </Box>
      </Box>
    </S.CollapsibleContainer>
  );

  return isOnboardingFlow ? standardView : collapsibleView;
};

export default observer(EmailInvite);
