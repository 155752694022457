import React from "react";
import createReactClass from "create-react-class";
import { Button, ContextualError } from "@arcadia/design-system";
import { Translate } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import {
  api_custom_activities_create,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { __API_UNVERSIONED_PATH__ } from "src/js/settings/settingsUrl";
import { navigateTo } from "../../modules/history";
import { getActiveGroupId } from "../../modules/activeGroup";
import { __ } from "../../modules/localization";

const NewCustomActivity = withTheme(
  createReactClass({
    form: "#form-new-activity",
    getDefaultProps() {
      return {
        className: ""
      };
    },
    getInitialState() {
      return {
        hasError: false
      };
    },
    componentDidMount() {
      const self = this;
      $(self.form).on("submit", e => {
        e.preventDefault();
      });
      self.initValidation();
    },

    initValidation() {
      const self = this;
      $.validate({
        form: self.form,
        borderColorOnError: "",
        validateOnBlur: true,
        onError() {
          self.setState({ hasError: true });
        },
        onSuccess() {
          self.createCustom();
        }
      });
    },
    createCustom() {
      const self = this;
      const name = $(`${self.form} #activity_name`).val();

      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          __API_UNVERSIONED_PATH__ +
          getVersionedApiUrl(
            api_custom_activities_create({ group_id: getActiveGroupId() })
          ),
        data: {
          name
        },
        success() {
          showToastSuccess({ str: <Translate text="new_activity_success" /> });
          const back_url = createUrl("activity_inclass", {
            group_id: getActiveGroupId()
          });
          navigateTo(back_url, { trigger: true });
        },
        error(data) {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
          const back_url = createUrl("activity_inclass", {
            group_id: getActiveGroupId()
          });
          navigateTo(back_url, { trigger: true });
        }
      });
    },
    render() {
      const self = this;
      const { greyTheme, whiteTheme } = this.props.theme;
      const input = (
        <div className="form-group margin-top-20 single-email-wrapper">
          <label
            htmlFor="activity_name"
            className="uppercase margin-right-15 margin-bottom-xs-20"
          >
            {__("activity_name")}
          </label>
          <input
            type="text"
            className="form-control light"
            id="activity_name"
            data-validation="length"
            data-validation-length="1-255"
            data-validation-error-msg="&nbsp;"
            onChange={() => {
              self.setState({ hasError: false });
            }}
          />
        </div>
      );
      const buttons = (
        <div className="margin-top-20">
          <Button type="submit" theme={greyTheme} variant="primary">
            <Translate text="save" />
          </Button>
        </div>
      );
      return (
        <div
          className={["margin-top-20", "font-16", self.props.className].join(
            " "
          )}
        >
          <p>{__("new_costum_activity_description")}</p>
          {self.state.hasError ? (
            <div className="margin-bottom-15">
              <ContextualError
                theme={whiteTheme}
                text={<Translate text="activity_name_validation_error" />}
              />
            </div>
          ) : (
            ""
          )}
          <form
            id="form-new-activity"
            className="form-single-email"
            role="form"
            ref="formEmail"
            noValidate
          >
            {input}
            {buttons}
          </form>
        </div>
      );
    }
  })
);

export default NewCustomActivity;
