import { useState } from "react";
import { useStores } from "src/js/hooks";
import { updateBadgeRelatedGroups } from "src/js/repository/badgesRepository";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { BadgeRelatedGroupType } from "src/js/types";
import {
  AssociateGroupStep,
  AssociateGroupStepType
} from "../components/AssociateBadgeToGroups/types";

const useAssociateGroups = ({
  setStep
}: {
  setStep: (step: AssociateGroupStepType) => void;
}) => {
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: {
      badgeToEdit,
      setUpdatedGroupAssociations,
      setIsAbleToEmitBadge
    }
  } = useStores();
  const [initialGroups, setInitialGroups] = useState<BadgeRelatedGroupType[]>(
    []
  );
  const [removedGroups, setRemovedGroups] = useState<BadgeRelatedGroupType[]>(
    []
  );
  const [addedGroups, setAddedGroups] = useState<BadgeRelatedGroupType[]>([]);
  const associateGroupsToBadge = () => {
    const addGroupIds = addedGroups.map(group => group.id);
    const removeGroupIds = removedGroups.map(group => group.id);
    const listOfAllUpdatedGroups = {
      added: addedGroups,
      removed: removedGroups
    };
    setUpdatedGroupAssociations(listOfAllUpdatedGroups);
    updateBadgeRelatedGroups(addGroupIds, removeGroupIds, badgeToEdit.id)
      .then(response => {
        if (response.groups.length) {
          setIsAbleToEmitBadge(true);
        }
        setStep(AssociateGroupStep.REVIEW_SELECTED_GROUPS);
      })
      .catch(() => {
        showToastError({
          str: translate("space_badge_associate_groups_error_alert")
        });
      });
  };

  const toggleGroup = (isChecked: boolean, group: BadgeRelatedGroupType) => {
    if (isChecked) {
      setInitialGroups([...initialGroups, group]);
      if (removedGroups.some(removed => removed.id === group.id)) {
        // Case: Previously unselected group and now is selected
        const newRemovedGroups = removedGroups.filter(
          removed => removed.id !== group.id
        );
        setRemovedGroups(newRemovedGroups);
      } else {
        setAddedGroups([...addedGroups, group]);
      }
    } else {
      const newFilteredGroups = initialGroups.filter(gr => gr.id !== group.id);
      setInitialGroups(newFilteredGroups);
      if (addedGroups.some(removed => removed.id === group.id)) {
        // Case: Previously selected group and now is unselected
        const newAddedGroups = addedGroups.filter(gr => gr.id !== group.id);
        setAddedGroups(newAddedGroups);
      } else {
        setRemovedGroups([...removedGroups, group]);
      }
    }
  };

  const handleCheckedGroup = (group: BadgeRelatedGroupType) => {
    if (removedGroups.some(gr => gr.id === group.id)) {
      return false;
    }
    if (addedGroups.some(gr => gr.id === group.id)) {
      return true;
    }
    return initialGroups.some(gr => gr.id === group.id);
  };

  return {
    initialGroups,
    addedGroups,
    setInitialGroups,
    setAddedGroups,
    setRemovedGroups,
    handleCheckedGroup,
    toggleGroup,
    associateGroupsToBadge
  };
};

export default useAssociateGroups;
