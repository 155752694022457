import { BottomModalSheet } from "@arcadia/design-system";
import React, { FC, useEffect, useState } from "react";
import { EditBoard } from "src/js/components/DrawerEditBoard/EditBoard";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import * as S from "./DrawerEditBoard.style";
import { DrawerEditBoardProps } from "./types";

const DrawerEditBoard: FC<DrawerEditBoardProps> = ({
  drawerOpen,
  setDrawerOpen,
  boardId
}) => {
  const [shouldUpdateBulkValues, setShouldUpdateBulkValues] = useState(false);
  const {
    UIStore: { isSideBarOpen, toggleSidebar, isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  useEffect(() => {
    if (isSideBarOpen && drawerOpen) {
      toggleSidebar();
    }
  }, [drawerOpen]);

  if (isLayoutModeMobile) {
    return (
      <BottomModalSheet
        isOpen={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setShouldUpdateBulkValues(false);
        }}
        onConfirm={() => {
          setShouldUpdateBulkValues(true);
        }}
        title={translate("module_settings_mobile_modal_header_title")}
        confirmLabel={translate("module_settings_mobile_modal_header_confirm")}
        closeLabel={translate("module_settings_mobile_modal_header_cancel")}
      >
        <S.BottomSheetBody>
          <EditBoard
            boardId={boardId}
            shouldUpdateBulkValues={shouldUpdateBulkValues}
            closeDrawer={() => {
              setDrawerOpen(false);
              setShouldUpdateBulkValues(false);
            }}
          />
        </S.BottomSheetBody>
      </BottomModalSheet>
    );
  }
  return (
    <S.DrawerEl
      open={drawerOpen}
      disableOverlay
      size="540px"
      isInsideMainLayout
      showCloseButton={false}
      onDismiss={() => {
        setDrawerOpen(false);
      }}
    >
      <EditBoard
        boardId={boardId}
        closeDrawer={() => {
          setDrawerOpen(false);
        }}
      />
    </S.DrawerEl>
  );
};

export default DrawerEditBoard;
