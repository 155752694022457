import { RadioGroup } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { SelectorProps } from "./Selector.types";
import * as S from "./Selector.styles";

const Selector: FC<SelectorProps> = props => {
  const { whiteTheme } = useTheme();
  const { UIStore } = useStores();
  const { isLayoutModeMobile: isMobile } = UIStore;
  const { onChange, current, list } = props;
  return (
    <S.SelectorWrap isMobile={isMobile}>
      <RadioGroup
        theme={whiteTheme}
        orientationOption="column"
        options={list}
        onChangeFunction={onChange}
        value={current}
      />
    </S.SelectorWrap>
  );
};

export default Selector;
