import { Text, shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const DropdownWrapper = styled.div`
  max-width: 300px;
  position: relative;
  margin-left: auto;
  display: block;
`;

export const DropdownTrigger = styled(motion.button)`
  background: none;
  transition: box-shadow 300ms ease-in-out;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  border: none;
  outline: none;
  overflow: hidden;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const DropdownContent = styled(motion.div)`
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  box-shadow: ${shadow[400]};
`;

export const ButtonContent = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 24px;
  cursor: pointer;
  user-select: none;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const DropdownItem = styled(motion.div)`
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px 24px;
  user-select: none;
  transition: background-color 300ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    cursor: pointer;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
