import {
  Button,
  DoodleLoader,
  Heading,
  Modal,
  SearchBar,
  Text
} from "@arcadia/design-system";
import spacesNoUsersImage from "Images/spaces-no-user.png";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import { ListSelector } from "src/js/components/global/ListSelector";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { ModalScrollableBody } from "src/js/components/modal/ModalScrollableBody";
import { UserCard } from "src/js/components/UserCard";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import createUrl from "src/js/modules/routing";
import { CheckboxBlock } from "src/js/pages/spaces/dashboard/SpacesSettings/CheckboxBlock";
import { PermissionCard } from "src/js/pages/spaces/dashboard/SpacesSettings/PermissionCard";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

import InfiniteScroll from "react-infinite-scroll-component";
import * as S from "./AddUserPermissionModal.styles";

const AddUserPermissionModal = ({
  closeModal = () => {},
  handleSubmit = () => {},
  fetchUsers = () => {},
  searchNextSpaceUsers = () => {},
  userList = [],
  usersListHasNext = false,
  spaceName = ""
}) => {
  const [queryString, setQueryString] = useState("");
  const [userSelected, setUserSelected] = useState({});
  const [listStep, setListStep] = useState(true);
  const [accessPermission, setAccessPermission] = useState(false);

  const { whiteTheme } = useTheme();
  const mobile = getBreakpoint() === "smartphone";

  const { name, surname, email, avatar } = userSelected?.user || {};

  const selectUserById = useCallback(userId => {
    const userItem = userList.find(({ user }) => user.id === userId);
    setUserSelected(userItem);
  });

  const UserListWithFormattedName = useMemo(() => {
    return userList.map(({ user }) => {
      return {
        id: user?.id,
        name: (
          <S.ListElementWrapper>
            <Text type="formDescription">
              <S.Ellipsis>
                {user?.name}&nbsp;<strong>{user?.surname}</strong>
              </S.Ellipsis>
            </Text>
            <S.ListElementMail>
              <Text type="formSmallCaption">{user?.email}</Text>
            </S.ListElementMail>
          </S.ListElementWrapper>
        )
      };
    });
  }, [userList]);

  const modalBody = listStep ? (
    <S.BodyContainer>
      <S.SearchContainer>
        <Translator>
          {({ translate }) => (
            <SearchBar
              id="search"
              theme={whiteTheme}
              onChange={event => {
                fetchUsers(event);
                setQueryString(event.target.value);
              }}
              value={queryString}
              placeholder={translate("space_settings_user_search_label")}
            />
          )}
        </Translator>
      </S.SearchContainer>
      {UserListWithFormattedName && UserListWithFormattedName.length > 0 ? (
        <>
          <S.DescriptionLabel>
            <Text type="label">
              <Translate text="name" />
            </Text>
          </S.DescriptionLabel>
          <ScrollableDiv maxHeight="158px" id="user-permission-list">
            <InfiniteScroll
              scrollableTarget="user-permission-list"
              dataLength={UserListWithFormattedName.length}
              next={searchNextSpaceUsers}
              hasMore={usersListHasNext}
              loader={<DoodleLoader theme={whiteTheme} isMini />}
            >
              <ListSelector
                itemList={UserListWithFormattedName}
                selectedItem={userSelected?.user?.id}
                toggleItem={item => {
                  selectUserById(item.id);
                }}
              />
            </InfiniteScroll>
          </ScrollableDiv>
        </>
      ) : (
        <S.NoResult>
          <S.NoUsersImage src={spacesNoUsersImage} />
          <S.NoUserTitle>
            <Heading level="1">
              <Translate text="space_not_found_user_title" />
            </Heading>
          </S.NoUserTitle>
          <Text type="formSubtitle">
            <Translate text="space_not_found_user_subtitle" />
          </Text>
        </S.NoResult>
      )}
    </S.BodyContainer>
  ) : (
    <ModalScrollableBody maxHeight={300} maxHeightOffset={240}>
      <S.BodyContainer>
        <S.DescriptionLabel>
          <Text type="label">
            <Translate text="space_settings_modal_user_label" />
          </Text>
        </S.DescriptionLabel>
        <UserCard
          firstname={name}
          lastname={surname}
          email={email}
          avatar={avatar?.small}
        />
        <S.SubtitleLabel>
          <Text type="formTitle">
            <Translate text="spaces_select_settings_permissions" />
          </Text>
        </S.SubtitleLabel>
        <S.DescriptionLabel>
          <Text type="label">
            <Translate text="space_settings_modal_handle_permissions_label" />
          </Text>
        </S.DescriptionLabel>
        <PermissionCard marginBottom={8}>
          <Text type="formDescription">
            <Translate
              text="spaces_group_creation_permission_bold_label"
              stringVariables={{ spaceName }}
            />
          </Text>
        </PermissionCard>
        <CheckboxBlock
          isSelected={accessPermission}
          onClick={() => setAccessPermission(!accessPermission)}
        >
          <Text type="formDescription">
            <Translate text="spaces_access_permission_bold_label" />
          </Text>
        </CheckboxBlock>
      </S.BodyContainer>
    </ModalScrollableBody>
  );

  const userListButtons =
    userList.length > 0 ? (
      <>
        <Button
          onClick={closeModal}
          variant="secondary"
          theme={whiteTheme}
          fullWidth={mobile}
        >
          <Translate text="undo_button_tooltip" />
        </Button>
        <Button
          disabled={!userSelected}
          onClick={() => setListStep(false)}
          variant="primary"
          theme={whiteTheme}
          fullWidth={mobile}
        >
          <Translate text="confirm_button_label" />
        </Button>
      </>
    ) : (
      <Button
        disabled={!userSelected}
        onClick={() => {
          closeModal();
          navigateTo(createUrl("home"));
        }}
        variant="primary"
        theme={whiteTheme}
        fullWidth={mobile}
      >
        <Translate text="spaces_settings_navigate_home" />
      </Button>
    );

  const modalButtons = listStep ? (
    userListButtons
  ) : (
    <>
      <Button
        disabled={!userSelected}
        onClick={() => setListStep(true)}
        variant="secondary"
        theme={whiteTheme}
        fullWidth={mobile}
      >
        <Translate text="undo_button_tooltip" />
      </Button>
      <Button
        onClick={() =>
          handleSubmit({ accessPermission, userId: userSelected?.user?.id })
        }
        variant="primary"
        theme={whiteTheme}
        fullWidth={mobile}
      >
        <Translate text="spaces_confirm_permission_label" />
      </Button>
    </>
  );

  return (
    <Translator>
      {({ translate }) => (
        <Modal title={translate("add_permissions_title")} onClose={closeModal}>
          {modalBody}
          <S.ModalFooterContainer>
            <S.ButtonContainer>{modalButtons}</S.ButtonContainer>
          </S.ModalFooterContainer>
        </Modal>
      )}
    </Translator>
  );
};

export default observer(AddUserPermissionModal);
