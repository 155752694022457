import React from "react";
import { useTheme } from "styled-components";
import {
  Box,
  Button,
  Heading,
  Link,
  Text,
  SearchBar
} from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { AnimatePresence } from "framer-motion";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import StarsLeftSVG from "src/img/icons/svg/stars-recover-left.svg";
import StarsRightSVG from "src/img/icons/svg/stars-recover-right.svg";
import { UniversityFinderProps } from "../../UniversityFinderPage.types";
import { UniversityRadioButton } from "../UniversityRadioButton";
import { NoResults } from "../NoResults";

import * as S from "./UniversityFinderMobileView.styles";

const UniversityFinderMobileView = ({
  searchText,
  unis,
  selected,
  onToggleUni,
  onChangeText,
  onCancelLogin,
  onGoToProvider
}: UniversityFinderProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <S.MobileWrapper>
      <S.LogoWrapper>
        <WeSchoolLogoSVG />
      </S.LogoWrapper>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <S.MobileTitleWrapper>
          <S.MobileTitle level="3">
            {translate("unlogged_find_university_label")}
          </S.MobileTitle>
          <Box position="absolute" left={-40} bottom={2}>
            <StarsLeftSVG />
          </Box>
          <Box position="absolute" right={-40} top={2}>
            <StarsRightSVG />
          </Box>
        </S.MobileTitleWrapper>
        <Box marginTop={32} marginBottom={8} width="100%">
          <SearchBar
            theme={whiteTheme}
            value={searchText}
            onChange={event => {
              onChangeText(event);
            }}
            placeholder={translate("unlogged_search_university_placeholder")}
          />
        </Box>
        <Box width="100%">
          <Link
            theme={whiteTheme}
            href="https://www.weschool.com/idem"
            target="_blank"
          >
            {translate("login_university_external_reference_label")}
          </Link>
        </Box>

        <AnimatePresence exitBeforeEnter>
          {unis.length > 0 ? (
            <S.MobileSelectorWrapper
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ opacity: 0, height: 0 }}
            >
              {unis.map((uni, idx) => (
                <UniversityRadioButton
                  key={`uni-${uni.entityID}`}
                  initial={{ y: 20, opacity: 0, scale: 0.85 }}
                  animate={{ y: 0, opacity: 1, scale: 1 }}
                  transition={{ delay: 0.1 * idx }}
                  university={uni}
                  onClick={onToggleUni(uni.entityID)}
                  isSelected={selected === uni.entityID}
                />
              ))}
              {selected ? (
                <S.MobileButtonSection
                  key="button-section"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 30 }}
                >
                  <Button
                    theme={whiteTheme}
                    variant="primary"
                    fullWidth
                    onClick={onGoToProvider}
                  >
                    {translate("confirm")}
                  </Button>
                  <S.LinkWrapper onClick={onCancelLogin}>
                    <Text type="textLink">{translate("Annulla")}</Text>
                  </S.LinkWrapper>
                </S.MobileButtonSection>
              ) : undefined}
            </S.MobileSelectorWrapper>
          ) : undefined}
          {unis.length === 0 && searchText !== "" ? (
            <Box
              marginTop={40}
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box marginBottom={16}>
                <Heading level="5">{translate("search_no_results")}</Heading>
              </Box>
              <NoResults />
            </Box>
          ) : undefined}
        </AnimatePresence>
      </Box>
    </S.MobileWrapper>
  );
};

export default UniversityFinderMobileView;
