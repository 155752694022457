import styled from "styled-components";

export const ModalBodyContainer = styled.div`
  padding: 16px 24px 32px 28px;
`;

export const ModalImageContainer = styled.img`
  margin: 20px auto;
  display: block;
  max-height: 120px;

  @media screen and (min-width: 767px) {
    margin: 40px auto;
  }
`;

export const ModalBannerContainer = styled.div`
  & > div:first-child {
    max-width: 100%;
  }
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  padding-top: 50px;
  gap: 10px;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 18px;
`;

export const ModalOauthMessage = styled.div`
  text-align: center;
`;
