import { useEffect } from "react";
import { useUserChannel } from "src/js/realtime";

import { SpaceGenerationStatesEnum } from "src/js/types";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";

const RealtimeUserSubscribe = () => {
  const { UserStore } = useStores();
  const userChannel = useUserChannel(UserStore.activeUser);

  useEffect(() => {
    if (!userChannel) return () => {};

    const subscriptions = [];

    subscriptions.push(
      userChannel.subscribe(
        SpaceGenerationStatesEnum.START_MESSAGE_TYPE,
        () => {
          RealTimeDispatcher.trigger(
            SpaceGenerationStatesEnum.START_MESSAGE_TYPE,
            SpaceGenerationStatesEnum.START_MESSAGE_TYPE
          );
        }
      )
    );

    subscriptions.push(
      userChannel.subscribe(SpaceGenerationStatesEnum.FAIL_MESSAGE_TYPE, () => {
        RealTimeDispatcher.trigger(
          SpaceGenerationStatesEnum.FAIL_MESSAGE_TYPE,
          SpaceGenerationStatesEnum.FAIL_MESSAGE_TYPE
        );
      })
    );

    subscriptions.push(
      userChannel.subscribe(
        SpaceGenerationStatesEnum.END_MESSAGE_TYPE,
        data => {
          RealTimeDispatcher.trigger(
            SpaceGenerationStatesEnum.END_MESSAGE_TYPE,
            data
          );
        }
      )
    );

    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    };
  }, [userChannel]);

  return null;
};

export default observer(RealtimeUserSubscribe);
