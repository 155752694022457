import { Location } from "history";
import { AppRoutingContext } from "src/js/types";

const getContextName = (
  pathname: string,
  possiblePaths: AppRoutingContext[]
): AppRoutingContext => {
  if (pathname.includes("dashboard")) return "dashboard";
  if (pathname.includes("groups")) return "groups";

  if (pathname.includes("group") || pathname.includes("notifications")) {
    return "space";
  }

  const possiblePath = possiblePaths.find(path => pathname.includes(path));

  if (possiblePath) {
    if (possiblePath === "settings" || possiblePath === "users") {
      return "profile";
    }

    return possiblePath;
  }

  const indexOfQuestionMark = pathname.indexOf("?");

  return (
    pathname
      // Remove the first slash: /chat => chat
      // Remove the query string: /chat?foo=bar => chat
      .substring(
        1,
        indexOfQuestionMark === -1 ? undefined : indexOfQuestionMark
      )
      // Replace all the remaining slashes with underscores: chat/t/123 => chat_t_123
      .replace(/\//g, "_") as AppRoutingContext
  );
};

export const getAppRoutingContext = (location: Location) => {
  const { pathname = "" } = location || {};
  return getContextName(pathname, [
    "chat",
    "home",
    "settings",
    "users",
    "profile",
    "dashboard"
  ]);
};
