import { shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const DropdownRoot = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: relative;
`;

export const LiveButton = styled(motion.button)<{
  hasMoreThanOneLive: boolean;
}>`
  border: none;
  outline: none;
  min-width: 96px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: ${({ hasMoreThanOneLive }) =>
    hasMoreThanOneLive ? "space-between" : "center"};
  padding: 10px 16px;
  border-radius: 24px;
  box-sizing: border-box;
  gap: 10px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  cursor: pointer;
`;

export const ChevronIcon = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`;

export const RightSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
`;

export const VerticalSpacer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 38px;
  background-color: ${({ theme }) => theme.colors.grey[500]};
  width: 1px;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const DropdownWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  min-width: 240px;
  right: 0;
  top: calc(100% + 20px);
  overflow: hidden;
  padding: 8px;
  border-radius: 8px;
  box-shadow: ${shadow[500]};
`;

export const Event = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[900]};
  }
`;
