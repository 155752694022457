import styled, { css } from "styled-components";

export const Column = styled.div<{
  large?: boolean;
  small?: boolean;
  extrasmall?: boolean;
}>`
  color: ${({ theme }) => theme.colors.grey[50]};
  width: 20%;
  padding-right: 20px;

  ${({ large }) =>
    large &&
    css`
      width: 30%;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 15%;
    `}

  ${({ extrasmall }) =>
    extrasmall &&
    css`
      width: 10%;
    `}
`;

export const TriggerRowArea = styled.span`
  width: calc(100% - 44px);
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.default};

  @media screen and (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TopMobile = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent4};
  padding: 24px 24px 8px;
  display: flex;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const TopMobileTitle = styled.div`
  flex: 1;
  overflow-x: hidden;
`;

export const TopMobileActionContainer = styled.div`
  margin-left: 5px;
`;

export const UserColumn = styled.span`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const UserFullName = styled.div`
  margin-left: 10px;
  font-size: 16px;
  overflow-x: hidden;
`;

export const UserSurname = styled.span`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const Mail = styled.div`
  color: ${({ theme }) => theme.colors.accent2};
  font-size: 14px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UsersCounter = styled.span`
  font-size: 16px;
  margin-left: 5px;
`;

export const BottomMobile = styled.div`
  width: 100%;
  padding: 24px 32px;
  font-size: 14px;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const BottomMobileTitle = styled.div<{ smallMargin?: boolean }>`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.accent2};
  margin-bottom: 10px;
  text-transform: uppercase;

  ${({ smallMargin }) =>
    smallMargin &&
    css`
      margin-bottom: 6px;
    `}
`;

export const TabletAndUp = styled.span`
  width: 100%;
  display: none;
  align-items: center;

  @media screen and (min-width: 992px) {
    display: flex;
  }
`;

export const HideOnXS = styled.span`
  @media screen and (min-width: 320px) {
    display: none;
  }

  @media screen and (min-width: 992px) {
    display: block;
  }
`;
