import styled, { css } from "styled-components";
import { SUnloggedLanguageSelectorWrap } from "./UnloggedLanguageSelectorWrap.types";

export const UnloggedLanguageSelectorWrap = styled.div<SUnloggedLanguageSelectorWrap>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  width: max-content;
  cursor: pointer;
  z-index: 950;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  border-radius: 100px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      background: inherit;
      color: inherit;
      border-radius: inherit;
      right: 4px;
      top: 4px;
      padding: 12px 12px;
    `}
`;
