import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MatchReportRoute from "src/js/context/utils/MatchReportRoute";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ExerciseModel from "../models/exerciseModel";
import { getActiveGroupId } from "../modules/activeGroup";
import { navigateTo } from "../modules/history";
import { __ } from "../modules/localization";
import { createURL } from "../modules/utility";
import ExerciseEngine from "../view/exercise/engine/exerciseEngine";
import ExerciseBuilder from "../view/exercise/exerciseBuilder";
import ExerciseDetailView from "../view/exercise/exercisesDetailView";
import EserciziReportsUsers from "../view/exercise/report/exerciseReportUsers";
import SingleExecutionController from "./SingleExecutionController";
import SingleInstantController from "./SingleInstantController";

class SingleExerciseController extends React.Component {
  constructor() {
    super();
    this.state = { exercise: null };
    this.loaded = false;
  }

  componentWillReceiveProps() {
    if (typeof this.props.match.params.exercise_id !== "undefined") {
      this.loaded = false;
      this.fetchExercise();
    }
  }

  fetchExercise() {
    const self = this;
    if (typeof this.props.match.params.exercise_id !== "undefined") {
      this.exerciseId = parseInt(this.props.match.params.exercise_id, 10);
      if (typeof this.exerciseId === "number") {
        const exercise = new ExerciseModel();
        exercise.url = createURL("api_exercises_entities", {
          exercise_id: this.exerciseId
        });
        exercise.on("change", newExercise => {
          this.setState({ exercise: newExercise });
        });
        exercise.fetch({
          success(model) {
            self.setState({ exercise: model });
            self.loaded = true;
          },
          error(params) {
            showToastError({
              str: extractErrorMessage(params.responseJSON, __("e_404"))
            });
            navigateTo(
              createUrl("exercises", { group_id: getActiveGroupId() })
            );
          }
        });
      }
    } else {
      navigateTo(createUrl("exercises", { group_id: getActiveGroupId() }));
    }
  }

  render() {
    const self = this;
    if (self.state.exercise === null) {
      self.fetchExercise();
    }
    return self.state.exercise !== null ? (
      <Switch>
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseEdit}
          render={props => (
            <ExerciseBuilder exercise={self.state.exercise} {...props} />
          )}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseRun}
          render={props => (
            <ExerciseEngine exercise={self.state.exercise} {...props} />
          )}
        />
        <Route
          exact
          path={
            WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseRunOption
          }
          render={props => (
            <ExerciseEngine exercise={self.state.exercise} {...props} />
          )}
        />
        <Route
          exact
          path={
            WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseReportsUsers
          }
          render={props => (
            <EserciziReportsUsers exercise={self.state.exercise} {...props} />
          )}
        />
        <Route
          exact
          path={
            WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseInstantRun
          }
          render={props => (
            <ExerciseEngine exercise={self.state.exercise} instant {...props} />
          )}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseInstant}
          render={props => (
            <SingleInstantController
              exercise={self.state.exercise}
              {...props}
            />
          )}
        />
        <Route
          path={
            WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises
              .exerciseReportsSession
          }
          render={props => {
            return (
              <>
                <MatchReportRoute />
                <SingleExecutionController
                  exercise={self.state.exercise}
                  {...props}
                />
              </>
            );
          }}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exercise}
          component={props => (
            <ExerciseDetailView exercise={self.state.exercise} {...props} />
          )}
        />
        <Route render={() => <Redirect to={createUrl("home")} />} />
      </Switch>
    ) : (
      <div />
    );
  }
}

export default SingleExerciseController;
