import { Palette, RadioGroupThemeOptions } from "@ds_themes/types";
import { increaseBrightness } from "@ds_themes/utils";

export const createRadioGroupTheme = ({
  general,
  primary,
  grey
}: Palette): { radiogroup: RadioGroupThemeOptions } => {
  const checkedColor = increaseBrightness(primary.main, 10);

  return {
    radiogroup: {
      background: "transparent",
      text: general.contrastText,
      border: `1.5px solid ${checkedColor}`,
      hover: {
        border: `1.5px solid ${primary.main}`,
        background: primary.main
      },
      disabled: {
        border: `1.5px solid ${primary.light}`,
        background: "transparent",
        text: grey[800],
        checked: {
          background: primary.light
        }
      },
      checked: {
        background: checkedColor,
        border: `1.5px solid ${checkedColor}`
      },
      focus: {
        border: `${primary.lighter} solid 3px`
      }
    }
  };
};
