/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import fetchSearchResult from "src/js/repository/searchRepository";
import { DoodleLoader, Box } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  SearchFiltersObject,
  SearchResponseItem,
  SearchUrlParameters
} from "src/js/types";
import { useInfiniteScrollFetcher } from "src/js/hooks";
import { getFormattedContexts, getFormattedSearchParam } from "./util";
import SearchResultsDesktop from "./SearchResultsDesktop";

const SearchFetch = ({
  keyword,
  filters
}: {
  keyword: string;
  filters: SearchFiltersObject;
}) => {
  const { greyTheme } = useTheme();
  const {
    fetch,
    reset,
    data = [],
    isLoading,
    hasMoreResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    SearchResponseItem[],
    { params: SearchUrlParameters }
  >(
    async ({ page, params }) => {
      const res = await fetchSearchResult(
        params?.keyword,
        getFormattedContexts(params),
        params?.groupFilter,
        params?.userFilter,
        getFormattedSearchParam(params?.spaceFilter),
        page + 1
      );
      return res.results;
    },
    {
      limit: 9
    }
  );

  useEffect(() => {
    reset();
    fetch(0, { params: { keyword, ...filters } });
  }, [keyword, filters]);

  return (
    <Box marginTop="24px">
      {isLoading ? (
        <DoodleLoader theme={greyTheme} />
      ) : (
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            fetchNextPage({ params: { keyword, ...filters } });
          }}
          hasMore={hasMoreResults}
          loader={<DoodleLoader theme={greyTheme} isMini />}
          style={{ overflowX: "hidden" }}
        >
          <SearchResultsDesktop results={data} keyword={keyword} />
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default SearchFetch;
