import React, { createContext, useEffect, useState } from "react";

import { __AWS_MQTT_ENDPOINT__ } from "src/js/settings/settingsGeneral";
import AWS from "aws-sdk/global";
import AWSMqttClient from "aws-mqtt";

import { InternalRealtimeDispatcher } from "./observer";

AWS.config.region = process.env.__REALTIME_MQTT_REGION__;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.__REALTIME_MQTT_CREDENTIAL__
});

const RealtimeContext = createContext({
  client: null
});

const RealtimeProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const topicPrefix = process.env.__REALTIME_MQTT_BASE_TOPIC__;

  useEffect(() => {
    const innerClient = new AWSMqttClient({
      region: AWS.config.region,
      credentials: AWS.config.credentials,
      endpoint: __AWS_MQTT_ENDPOINT__,
      clientId: `mqtt-client-${Math.floor(Math.random() * 100012300 + 1)}`, // clientId to register with MQTT broker. Need to be unique per client
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false
      },
      resubscribe: true,
      reconnectPeriod: 10000
    });

    innerClient.on("connect", () => {
      setClient(innerClient);
      innerClient.on("close", () => {});
      innerClient.on("offline", () => {});

      innerClient.on("error", error => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      });
    });
  }, []);

  useEffect(() => {
    if (!client) return () => {};

    const fn = (topic, message) => {
      try {
        InternalRealtimeDispatcher.trigger(
          topic,
          JSON.parse(message.toString("utf8"))
        );
      } catch (e) {
        InternalRealtimeDispatcher.trigger(topic, {});
      }
    };

    client.on("message", fn);

    return () => client.removeListener("message", fn);
  }, [client]);

  window.client = client;
  return (
    <RealtimeContext.Provider value={{ client, topicPrefix }}>
      {children}
    </RealtimeContext.Provider>
  );
};

export { RealtimeProvider, RealtimeContext };
