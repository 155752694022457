import styled, { css } from "styled-components";

export const ItemTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: 700;
  font-size: 16px;
  margin: 0 auto 0 16px;
  padding-right: 16px;
  height: 100%;
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isLocked }) =>
    isLocked ? theme.colors.basicBlack : theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.basicWhite};
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 12px;
  margin-left: 8px;
  padding-left: ${({ isLocked }) => (isLocked ? 0 : "2px")};
`;

export const PrerequisiteMenuItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding: 8px 0;
  height: 100%;
  min-height: 50px;
  color: ${({ theme }) => theme.colors.grey[50]};
  border: 1px solid ${({ theme }) => theme.colors.coldGrey[900]};
  border-left: 4px solid ${props => props.selectedColorHex};
  border-radius: 4px;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 8px 2px rgb(24 24 24 / 5%);
      }
    `}

  ${({ isLocked }) =>
    isLocked &&
    css`
      opacity: 0.65;
    `}
`;

export const DraftBadge = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;

  color: ${({ theme }) => theme.colors.semantic1};
  border: 1px solid ${({ theme }) => theme.colors.semantic1};

  border-radius: 20px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 10px;
  margin: 0;

  margin-right: 15px;
`;

export const IconClose = styled.div`
  cursor: pointer;
  margin-right: 16px;
  flex-shrink: 0;
`;

export const PathGoButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
`;

export const PathText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.title};
  margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
`;

export const IconArrow = styled.div`
  margin-right: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const IconMilestone = styled.div`
  margin-right: 16px;
  flex-shrink: 0;
`;
