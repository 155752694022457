import React from "react";
import createReactClass from "create-react-class";
import map from "lodash/map";
import createUrl from "src/js/modules/routing";
import { Icon } from "@arcadia/design-system";
import { navigateTo } from "../../modules/history";
import { getActiveGroupId } from "../../modules/activeGroup";

const Pager = createReactClass({
  getDefaultProps() {
    return {
      status: "",
      pageViewed: 5,
      pageTotalNumber: 5,
      pageActive: 1,
      type: "ranking",
      changePage: ""
    };
  },
  getInitialState() {
    return {
      pageActive: 1,
      viewPages: []
    };
  },
  componentDidMount() {
    const self = this;
    const pageNumber = self.props.pageTotalNumber;
    let { pageViewed } = self.props;
    if (pageNumber < pageViewed) {
      pageViewed = pageNumber;
    }
    self.setState(
      {
        pageActive: self.props.pageActive,
        pageViewed,
        pageTotalNumber: pageNumber
      },
      () => {
        self.getPages();
      }
    );
  },
  getPages() {
    const self = this;
    const { pageActive } = self.state;
    const total = self.state.pageTotalNumber;
    const { pageViewed } = self.state;
    const arrayPage = [];
    if (pageActive != 1 && pageActive != total) {
      const pageNumberCheck = Math.floor(pageViewed / 2);
      let indexPage = pageActive;
      if (indexPage - pageNumberCheck < 1) {
        indexPage = 1;
      } else if (indexPage + pageNumberCheck > total) {
        indexPage = total - pageViewed + 1;
      } else {
        indexPage -= pageNumberCheck;
      }
      for (let i = 0; i < pageViewed; i++) {
        arrayPage.push(indexPage + i);
      }
    } else if (pageActive == 1) {
      for (let i = 0; i < pageViewed; i++) {
        arrayPage.push(i + 1);
      }
    } else {
      for (let i = 0; i < pageViewed; i++) {
        arrayPage.push(total - i);
      }
      arrayPage.reverse();
    }
    self.setState({ viewPages: arrayPage });
  },
  nextPage() {
    this.changePage(this.state.pageActive + 1);
  },
  previusPage() {
    this.changePage(this.state.pageActive - 1);
  },
  changePage(page) {
    const self = this;
    if (typeof self.props.changePage === "function") {
      const callback = function (page) {
        self.setState({ pageActive: page }, () => {
          const pages = self.getPages();
          self.setState({ viewPages: pages });
        });
      };
      self.props.changePage(page, callback);
    } else {
      switch (self.props.type) {
        case "ranking":
        default:
          navigateTo(
            createUrl("ranking", {
              group_id: getActiveGroupId(),
              page_number: page
            }),
            { trigger: true }
          );
      }
    }
  },

  render() {
    const self = this;
    const pages = self.state.viewPages;
    let pagesList = "";
    let nextButton = (
      <a onClick={self.nextPage} className="next">
        <Icon icon="chevronSmallRight" width={15} height={15} />
      </a>
    );
    let prevButton = (
      <a onClick={self.previusPage} className="previus">
        <Icon icon="chevronSmallLeft" width={15} height={15} />
      </a>
    );
    if (self.state.pageActive == 1) {
      prevButton = (
        <span className="previus">
          <Icon icon="chevronSmallLeft" width={15} height={15} />
        </span>
      );
    }
    if (self.state.pageActive == self.state.pageTotalNumber) {
      nextButton = (
        <span className="previus">
          <Icon icon="chevronSmallRight" width={15} height={15} />
        </span>
      );
    }
    pagesList = map(pages, page => {
      if (page == self.state.pageActive) {
        return <span className="active">{page}</span>;
      }
      return <a onClick={self.changePage.bind(self, page)}>{page}</a>;
    });
    return (
      <div className="text-center margin-20">
        <div className="pager">
          {prevButton}
          {pagesList}
          {nextButton}
        </div>
      </div>
    );
  }
});

export default Pager;
