import React, { FC, PropsWithChildren } from "react";

import * as S from "./Text.styles";
import { TextProps } from "./types";

export const Text: FC<PropsWithChildren<TextProps>> = ({
  type,
  children,
  className
}) => {
  return (
    <S.Text type={type} className={className}>
      {children}
    </S.Text>
  );
};

export default Text;
