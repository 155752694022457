/* eslint-disable no-restricted-globals */
import {
  AvatarWithInitials,
  Box,
  Heading,
  colors
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { HomeHeaderStats } from "src/js/components/HomeHeaderStats";
import {
  SpaceSchoolBadge,
  SpaceSchoolBadgeVariant
} from "src/js/components/SpaceSchoolBadge";
import { useStores } from "src/js/hooks";
import { __HERO_GROUPS_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { ImageModel } from "src/js/types";
import CreateJoinSpaceOrGroup from "src/js/components/layout/TopHeaderSpacesMobile/CreateJoinSpaceOrGroup";
import { useTheme } from "styled-components";
import * as S from "./HeaderWhiteHome.styles";
import { CreateOrJoinGroup } from "./components";
import { SpacesDotMenuWrapper } from "../../SpacesDotMenuWrapper";
import { LeaveSpaceModal } from "../../modal/LeaveSpaceModal";

const HeaderWhiteHome = () => {
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: {
      activeSpace,
      isClaimed,
      spaceUserStats,
      isAdmin,
      activeSpaceId
    },
    ModalStore: { closeModal, openModal }
  } = useStores();
  const { whiteTheme } = useTheme();
  const [isCreateOrJoinSpaceModalOpen, setCreateOrJoinSpaceModalOpen] =
    useState(false);

  const showLeaveSpaceModal = () => {
    openModal(() => <LeaveSpaceModal closeModal={closeModal} />);
  };

  return (
    <>
      <S.HeroBannerWrapper
        src={
          activeSpace?.space?.spaceCover?.large || __HERO_GROUPS_PLACEHOLDER__
        }
      >
        <S.HeaderContainer>
          <S.HeroBanner
            src={
              activeSpace?.space?.spaceCover?.large ||
              __HERO_GROUPS_PLACEHOLDER__
            }
          />
        </S.HeaderContainer>
      </S.HeroBannerWrapper>
      <S.HeaderContainer>
        <S.ContentWrapper>
          <S.ContentContainer>
            <Box flexDirection="row" display="flex" width="100%">
              <Box marginRight="12px" marginBottom={8}>
                {!isLayoutModeMobile ? (
                  <S.SpaceBadge>
                    <AvatarWithInitials
                      theme={whiteTheme}
                      url={
                        (activeSpace?.space?.badge as ImageModel | undefined)
                          ?.large
                      }
                      alt={activeSpace?.space?.name}
                      name={activeSpace?.space?.name || ""}
                      avatarColor={activeSpace?.space?.defaultBadgeColor}
                      size="auto"
                    />
                  </S.SpaceBadge>
                ) : null}
              </Box>
              <Box width="100%" margin="auto">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  width="100%"
                  alignItems="flex-start"
                >
                  <S.HeaderContentWrapper>
                    {!isLayoutModeMobile ? (
                      <Box display="flex" alignItems="center">
                        <Heading level="1">
                          {activeSpace?.space?.name}
                          {isClaimed ? (
                            <SpaceSchoolBadge
                              variant={SpaceSchoolBadgeVariant.Violet}
                            />
                          ) : null}
                        </Heading>
                        <SpacesDotMenuWrapper
                          isAdmin={isAdmin}
                          leaveSpace={showLeaveSpaceModal}
                          activeSpace={activeSpace}
                          activeSpaceId={activeSpaceId}
                          color={colors.grey[50]}
                          alignRight={false}
                        />
                      </Box>
                    ) : null}

                    <S.HeaderDescription type="formSubtitle">
                      {activeSpace?.space?.description}
                    </S.HeaderDescription>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      gap={12}
                      flexWrap="wrap"
                    >
                      <HomeHeaderStats
                        groupsCount={spaceUserStats?.groupsNumber ?? 0}
                        participantsCount={
                          spaceUserStats?.usersGroupsNumber ?? 0
                        }
                        excludeNotifications
                        isTemporary={activeSpace?.isTemporary}
                      />
                      {!isLayoutModeMobile && !activeSpace?.isTemporary && (
                        <CreateOrJoinGroup />
                      )}
                    </Box>
                  </S.HeaderContentWrapper>
                </Box>
              </Box>
            </Box>
            {isLayoutModeMobile && !activeSpace?.isTemporary && (
              <CreateOrJoinGroup />
            )}
          </S.ContentContainer>
        </S.ContentWrapper>
      </S.HeaderContainer>
      <CreateJoinSpaceOrGroup
        createGroup
        isOpen={isCreateOrJoinSpaceModalOpen}
        setOpen={setCreateOrJoinSpaceModalOpen}
      />
    </>
  );
};

export default observer(HeaderWhiteHome);
