import { HighlightedLink } from "@arcadia/design-system";
import queryString from "query-string";
import React from "react";
import createUrl from "src/js/modules/routing";
import { translateString } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

const UnloggedGoLogin = ({ alias }) => {
  const { whiteTheme } = useTheme();
  const loginUrl = alias
    ? queryString.stringifyUrl({
        url: createUrl("login"),
        query: {
          redirect_url: encodeURIComponent(
            createUrl("join", {
              alias_id: alias
            })
          ),
          inviteToken: alias
        }
      })
    : createUrl("login");

  return (
    <HighlightedLink
      theme={whiteTheme}
      description={translateString({ text: "unlogged_go_login_text_label" })}
      onClick={() => navigateTo(loginUrl)}
    >
      {translateString({ text: "unlogged_go_login_link_label" })}
    </HighlightedLink>
  );
};

export default UnloggedGoLogin;
