import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const EllipsisNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const EllipsisNameContainer = styled.div<{ isClickable?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    & > span {
      text-decoration: ${({ isClickable }) =>
        isClickable ? "underline" : "none"};
    }
  }
`;

export const EllipsisNameText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[200]};
  font-size: 14px;
`;
