import { useState } from "react";

export function useArrayState<T>(
  init: T[]
): [T[], unknown, React.Dispatch<React.SetStateAction<T>>] {
  const [array, setArray] = useState(init);

  function add(newArray = []) {
    setArray(a => {
      return [...a, ...newArray];
    });
  }

  function reset() {
    setArray(() => []);
  }

  return [array, add, reset];
}

export default useArrayState;
