/* eslint-disable @typescript-eslint/no-shadow */
import {
  QueryKey,
  useInfiniteQuery as useInfiniteQueryDefault
} from "@tanstack/react-query";
import {
  InfiniteQueryPagination,
  UseInfiniteQueryFn,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult
} from "./types";
import useIsEmptyQueryResult from "./useIsEmptyQueryResult";

const useInfiniteQuery = <
  TQueryFnData = unknown,
  TError = Error,
  TData = TQueryFnData,
  TExtraData = Record<string, unknown>
>(
  queryKey: QueryKey,
  queryFn: UseInfiniteQueryFn<TQueryFnData, TExtraData>,
  options?: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData>
) => {
  const { transformEmptyResult, limit = 30, ...otherOptions } = options || {};

  const { data, status, ...queryResult } = useInfiniteQueryDefault({
    queryFn: async ({ pageParam = 0, queryKey }) => {
      const offset = pageParam * limit;
      const res = await queryFn(
        { limit, offset, start: offset },
        { queryKey, page: pageParam, pageParam }
      );
      const { results, extraData } = res;

      return {
        results,
        page: pageParam,
        limit,
        extraData
      };
    },
    queryKey,
    getNextPageParam: ({ page = 0, results = [] }) => {
      if (results.length >= limit) {
        return page + 1;
      }

      return undefined;
    },
    select: ({ pages, pageParams }) => {
      const newData = pages.reduce((arr, val) => {
        const results = (val?.results || []) as unknown as TData[];
        return [...arr, ...results];
      }, [] as TData[]);

      const { extraData = {}, page } = pages?.[0] || {};

      return {
        results: newData,
        extraData,
        pages,
        pageParams,
        page
      };
    },
    ...(otherOptions as object)
  });
  const infiniteData = data as unknown as Awaited<
    ReturnType<UseInfiniteQueryFn<TData, TExtraData>>
  >;
  const { results, extraData } = infiniteData || {};
  const isEmpty = useIsEmptyQueryResult({
    transformEmptyResult,
    data: results as never,
    status
  });

  return {
    ...queryResult,
    data: results,
    extraData,
    status,
    isEmpty
  } as UseInfiniteQueryResult<
    InfiniteQueryPagination<TData, TExtraData>,
    TError,
    TData,
    TExtraData
  >;
};

export default useInfiniteQuery;
