import moment from "moment";
import { DurationType } from "./SpaceDurationCard.types";

export const spaceDuration = (
  deadline: Date
): { duration: number; durationType: DurationType } => {
  const momentDeadline = moment(deadline);
  const momentNow = moment();

  const monthDifference = momentDeadline.diff(momentNow, "months");
  if (monthDifference > 0) {
    return { duration: monthDifference, durationType: "months" };
  }

  const dayDifference = momentDeadline.diff(momentNow, "days");
  return {
    duration: dayDifference > 0 ? dayDifference : 0,
    durationType: "days"
  };
};
