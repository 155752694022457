import styled from "styled-components";

import { SSvgLoaderProps } from "./types";

export const DoodleLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    position: absolute;
    opacity: 0;
    color: white;

    -webkit-animation: play 0.96s infinite;
    -moz-animation: play 0.96s infinite;
    -ms-animation: play 0.96s infinite;
    -o-animation: play 0.96s infinite;
    animation: play 0.96s infinite;

    &:nth-child(1) {
      position: absolute;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      position: absolute;
      animation-delay: 0.16s;
    }
    &:nth-child(3) {
      position: absolute;
      animation-delay: 0.32s;
    }
    &:nth-child(4) {
      animation-delay: 0.48s;
    }
    &:nth-child(5) {
      animation-delay: 0.64s;
    }
    &:nth-child(6) {
      animation-delay: 0.8s;
    }
  }
  @keyframes play {
    to {
      opacity: 0;
    }
    from,
    16% {
      opacity: 1;
    }
    17% {
      opacity: 0;
    }
  }
`;

export const SvgLoader = styled.div<SSvgLoaderProps>`
  background-color: ${({ theme }) => theme.doodleloader.background};
  width: ${({ isMini }) => (isMini ? "unset" : "100vw")};
  height: ${({ isMini }) => (isMini ? "20px" : "100vh")};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    min-height: ${({ isMini }) => (isMini ? "auto" : "100vh")};
  }

  svg {
    height: auto;
    width: auto;
    position: absolute;
    opacity: 0;
    color: ${({ theme }) => theme.doodleloader.color};

    -webkit-animation: play 0.96s infinite;
    -moz-animation: play 0.96s infinite;
    -ms-animation: play 0.96s infinite;
    -o-animation: play 0.96s infinite;
    animation: play 0.96s infinite;

    &:nth-child(1) {
      position: absolute;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      position: absolute;
      animation-delay: 0.16s;
    }
    &:nth-child(3) {
      position: absolute;
      animation-delay: 0.32s;
    }
    &:nth-child(4) {
      animation-delay: 0.48s;
    }
    &:nth-child(5) {
      animation-delay: 0.64s;
    }
    &:nth-child(6) {
      animation-delay: 0.8s;
    }
  }
  @keyframes play {
    to {
      opacity: 0;
    }
    from,
    16% {
      opacity: 1;
    }
    17% {
      opacity: 0;
    }
  }
`;
