import { DividerThemeMap, Palette } from "@ds_themes/types";

export const createDividerTheme = ({
  grey,
  primary
}: Palette): { divider: DividerThemeMap } => {
  return {
    divider: { black: grey[50], grey: grey[800], primary: primary.light }
  };
};
