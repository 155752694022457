import React, { useState } from "react";
import { useStores } from "src/js/hooks";

import { readLocalParsedData } from "src/js/modules/storageFunction";
import { __LOCAL_SEARCH_SUGGESTION__ } from "src/js/settings/settingsLocalStorage";
import { useTranslation } from "src/js/translation";
import { AppRoutingContext, EventDomain, SearchbarEvent } from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { DropdownContent } from "../DropdownContent";
import { ContextProps, UseSearchByContext } from "./types";
import { useHomeContext } from "./useHomeContext";
import { useProfileContext, useSearchContext, useSpaceContext } from ".";

const SEARCHBAR_ID = "top_header_searchbar";

const useSearchByContext = ({
  context
}: {
  context: AppRoutingContext;
}): UseSearchByContext => {
  const [searchQuery, setQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const {
    GroupStore: { activeGroup }
  } = useStores();

  const spaceContext = useSpaceContext({ searchQuery });
  const profileContext = useProfileContext({ searchQuery });
  const searchContext = useSearchContext({
    searchQuery,
    setSearchQuery: setQuery
  });
  const homeContext = useHomeContext({ searchQuery });
  const { translate } = useTranslation();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onReset = () => {
    setQuery("");
  };

  const suggestions = readLocalParsedData<string[]>(
    __LOCAL_SEARCH_SUGGESTION__
  );

  let contextProps: ContextProps;

  switch (context) {
    case "space":
      contextProps = spaceContext;
      break;
    case "profile":
      contextProps = profileContext;
      break;
    case "search":
      contextProps = searchContext;
      break;
    default:
      contextProps = homeContext;
  }

  // Adding _trackEvent to the regular onSubmit without editing all the hooks original function
  const originalOnSubmit = contextProps.onSubmit;

  contextProps.onSubmit = e => {
    originalOnSubmit(e);
    _trackEvent(EventDomain.Searchbar, SearchbarEvent.SearchBarUseSearch);
  };

  const {
    onSubmit,
    placeholder,
    rightFilterBadge,
    groupFilter,
    setGroupFilter
  } = contextProps;

  const suggestedGroup = activeGroup?.id
    ? { id: activeGroup.id, name: activeGroup.name }
    : null;

  const dropdownContent =
    searchQuery || suggestedGroup || suggestions ? (
      <DropdownContent
        suggestions={suggestions}
        searchQuery={searchQuery}
        onSubmit={onSubmit}
        setGroupFilter={setGroupFilter}
        groupFilterId={groupFilter?.id}
        suggestedGroup={suggestedGroup}
        onSuggestionClick={option => {
          setQuery(option);
          document.getElementById(SEARCHBAR_ID).focus();
        }}
      />
    ) : null;

  return {
    id: SEARCHBAR_ID,
    value: searchQuery,
    onChange,
    onReset,
    dropdownContent,
    onSubmit,
    rightFilterBadge,
    placeholder: isOpen
      ? translate({ text: "top_header_searchbar_desktop_open_placeholder" })
      : placeholder,
    onToggle: (value: boolean) => {
      setIsOpen(value);
    },
    activeLeftFilterLabel: groupFilter?.name
  };
};

export default useSearchByContext;
