import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.basicBlack};
`;

export const IconContainer = styled.span`
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
`;

export const TitleText = styled.h5`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.title};
  line-height: 20px;
  font-size: 18px;
  font-weight: 700;
  overflow-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const HiddenGrowText = styled.div`
  white-space: pre-wrap;
  visibility: hidden;
  grid-area: 1 / 1 / 2 / 2;
  border: 1px solid black;
  padding: 10px 16px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: 18px;
  overflow: hidden;
  letter-spacing: 0.15px;
`;

export const InputWrapper = styled.div``;
