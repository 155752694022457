import React from "react";
import createReactClass from "create-react-class";
import { InternalDispatcher } from "../../modules/dispatcher";
import { MainContainer } from "src/js/components/layout/MainContainer";

const AppLogged = createReactClass({
  getDefaultProps() {
    return {
      showGroupBadge: true
    };
  },
  getInitialState() {
    return {
      renderTrigger: true
    };
  },
  componentDidMount() {
    let self = this;
    InternalDispatcher.on(
      "collectionMessageChange",
      self.updateConmponent.bind(self)
    );
  },
  updateConmponent() {
    let self = this;
    self.setState({
      renderTrigger: !self.state.renderTrigger
    });
  },
  render() {
    return (
      <MainContainer>
        {this.props.children}
        <span id="main-layout-portal" />
      </MainContainer>
    );
  }
});

export default AppLogged;
