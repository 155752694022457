const __BOARD_ELEMENTS_KEY_ALL__ = "board-elements";

export const boardElementsQueryKey = {
  all: [__BOARD_ELEMENTS_KEY_ALL__],
  boardElementDetails: ({ resourceId }: { resourceId: string }) => [
    ...boardElementsQueryKey.all,
    { resourceId },
    "details"
  ]
};
