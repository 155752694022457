import { DropdownContextualThemeOptions, Palette } from "@ds_themes/types";

export const createDropdownContextualTheme = ({
  coldGrey,
  grey,
  primary
}: Palette): { dropdownContextual: DropdownContextualThemeOptions } => {
  return {
    dropdownContextual: {
      button: {
        background: coldGrey[900],
        border: grey[800],
        focus: {
          border: primary.main,
          background: coldGrey[900],
          color: primary.main,
          outline: primary.lighter
        }
      },
      body: {
        background: grey[1000],
        border: grey[800]
      },
      option: {
        background: grey[1000],
        focus: {
          background: coldGrey[900]
        }
      }
    }
  };
};
