import { observer } from "mobx-react";
import React from "react";
import { MilestoneBanner } from "src/js/components/MilestoneBanner";
import { __BOARD_COLOR__ } from "src/js/settings/settingsBoard";
import { Translate } from "src/js/translation/TranslationProvider";
import { Icon } from "@arcadia/design-system";
import * as S from "./Board.styles";

const Board = ({
  id,
  name,
  isTeacher = false,
  isPublished = true,
  color,
  isOpen = false,
  isMandatory = true,
  isAIGenerated = false,
  hasMandatoryOrder = false,
  isMilestone = false,
  isLocked = false,
  activitiesCount = 0,
  boardElementCount = 0,
  boardElementDraftCount = 0,
  completedBoardElementCount = 0,
  suggestionCount = 0,
  prerequisitesCount = 0,
  availableScore = 0,
  layoutClick = () => {},
  mainActionClick = () => {},
  prerequisitesClick = () => {},
  activitiesClick = () => {},
  isActive = false,
  testId = "board-testid"
}) => {
  const disabledPrerequisitesBtn =
    !prerequisitesCount || prerequisitesCount === 0;
  const disabledActivitiesBtn = !activitiesCount || activitiesCount === 0;
  const handleMainAction = event => {
    event.stopPropagation();
    mainActionClick(id);
  };

  const handlePrerequisitesClick = event => {
    event.stopPropagation();
    if (!disabledPrerequisitesBtn) prerequisitesClick(id);
  };

  const handleActivitiesClick = event => {
    event.stopPropagation();
    if (!disabledActivitiesBtn) activitiesClick(id);
  };

  let statusIcon = <Icon icon="play" height={8} width={8} />;
  if (
    boardElementCount - boardElementDraftCount === completedBoardElementCount &&
    !isOpen
  ) {
    statusIcon = <Icon icon="check2" height={8} width={8} />;
  }
  if (isLocked) {
    statusIcon = <Icon icon="locked" height={10} width={10} />;
  }

  return (
    <S.RelativeWrapper isActive={isActive}>
      <S.BoardLayout
        semiTransparent={!isTeacher && isLocked}
        onClick={layoutClick}
        data-testid={testId}
      >
        <S.ColorColumn color={__BOARD_COLOR__.get(color)} />
        {isTeacher && (
          <S.DragHandler>
            <Icon icon="drag" height={16} width={16} />
          </S.DragHandler>
        )}
        <S.BoardContent>
          <S.BoardBody>
            <S.InfoContainer>
              <S.TitleContainer>
                {!isTeacher && (
                  <S.StatusIconContainer isLocked={isLocked}>
                    {statusIcon}
                  </S.StatusIconContainer>
                )}
                <S.Title>{name}</S.Title>
              </S.TitleContainer>
              <S.TagsContainer>
                {!isMandatory && (
                  <S.FacultativeTag data-testid="facultative-tag">
                    <Translate text="board_optional" />
                  </S.FacultativeTag>
                )}
                {!isPublished && (
                  <S.DraftTag data-testid="draft-tag">
                    <Translate text="board_draft" />
                  </S.DraftTag>
                )}
                {isAIGenerated && isTeacher && (
                  <S.AIGeneratedTag>
                    <Icon
                      icon="sparkles"
                      height={16}
                      width={16}
                      initialViewbox
                    />
                    <Translate text="ai_generated_module_tag" />
                  </S.AIGeneratedTag>
                )}
              </S.TagsContainer>
            </S.InfoContainer>
            <S.ActionsContainer>
              <S.HideOnMobile>
                {isMilestone && (
                  <S.MilestoneWrapper>
                    <MilestoneBanner color={color} />
                  </S.MilestoneWrapper>
                )}
                {isTeacher && (
                  <S.QuickActionButton
                    disabledStyle={disabledPrerequisitesBtn}
                    onClick={event => handlePrerequisitesClick(event)}
                  >
                    <Icon icon="path" />
                    {prerequisitesCount > 0 && (
                      <S.QuickActionCounter>
                        {prerequisitesCount}
                      </S.QuickActionCounter>
                    )}
                  </S.QuickActionButton>
                )}
                <S.QuickActionButton
                  noMargin={!isTeacher}
                  disabledStyle={disabledActivitiesBtn}
                  onClick={event => handleActivitiesClick(event)}
                >
                  <Icon icon="calendar" />
                  {activitiesCount > 0 && (
                    <S.QuickActionCounter>
                      {activitiesCount}
                    </S.QuickActionCounter>
                  )}
                </S.QuickActionButton>
              </S.HideOnMobile>
              {isTeacher && (
                <S.MainActionButton
                  onClick={event => handleMainAction(event)}
                  isActive={isActive}
                >
                  <Icon icon="kebabVertical" height={18} width={18} />
                </S.MainActionButton>
              )}
            </S.ActionsContainer>
          </S.BoardBody>
          <S.BoardFooter>
            {isMilestone && (
              <S.MilestoneMobileContainer color={__BOARD_COLOR__.get(color)}>
                <Icon icon="crown" height={22} width={22} />
              </S.MilestoneMobileContainer>
            )}
            {isTeacher && isLocked && (
              <>
                <Icon icon="locked" height={16} width={16} />
                <S.GrayDot />
              </>
            )}
            <Icon icon="cards" height={16} width={16} />
            <S.BoardFooterText hasMarginLeft>
              {!isTeacher && !isOpen && `${completedBoardElementCount}/`}
              {boardElementCount - boardElementDraftCount}
            </S.BoardFooterText>
            {hasMandatoryOrder && (
              <>
                <S.GrayDot />
                <Icon
                  icon="contentGrid"
                  height={16}
                  width={16}
                  initialViewbox
                />
                <S.BoardFooterText hasMarginLeft>
                  <Translate text="fixed_order_toggle_title" />
                </S.BoardFooterText>
              </>
            )}
            {!isTeacher && !isOpen && (
              <S.ProgressBarGutter>
                <S.ProgressBar
                  data-testid="progress-bar"
                  percentage={
                    (100 / (boardElementCount - boardElementDraftCount)) *
                    completedBoardElementCount
                  }
                />
              </S.ProgressBarGutter>
            )}
            {isTeacher &&
              !isOpen &&
              suggestionCount > 0 &&
              !hasMandatoryOrder && (
                <>
                  <S.GrayDot />
                  <S.HideOnDesktop>
                    <Icon icon="lightBulb" height={16} width={16} />
                    <S.BoardFooterText hasMarginLeft>
                      {suggestionCount}
                    </S.BoardFooterText>
                  </S.HideOnDesktop>
                  <S.HideOnMobile>
                    <S.BoardFooterText>
                      <Translate
                        text="board_suggestion"
                        stringVariables={{ suggestionCount }}
                      />
                    </S.BoardFooterText>
                  </S.HideOnMobile>
                </>
              )}
            {isOpen && (
              <>
                <S.GrayDot />
                <Icon icon="paperPlus" height={16} width={16} />
                <S.BoardFooterText hasMarginLeft>
                  <Translate text="board_open" />
                </S.BoardFooterText>
              </>
            )}
            {availableScore > 0 && (
              <>
                <S.GrayDot />
                <S.HideOnDesktop>
                  <Icon icon="starEmpty" height={16} width={16} />
                  <S.BoardFooterText hasMarginLeft>
                    {availableScore}
                  </S.BoardFooterText>
                </S.HideOnDesktop>

                <S.HideOnMobile>
                  <S.BoardFooterText>
                    {availableScore}{" "}
                    <Translate
                      text={
                        availableScore === 1 ? "board_point" : "board_points"
                      }
                    />
                  </S.BoardFooterText>
                </S.HideOnMobile>
              </>
            )}
          </S.BoardFooter>
        </S.BoardContent>
      </S.BoardLayout>
    </S.RelativeWrapper>
  );
};

export default observer(Board);
