import React, { FC } from "react";

import { LoaderColor } from "../types";

import * as S from "./CircularLoader.styles";

const CircularLoader: FC<LoaderColor> = ({ mainColor, borderColor }) => {
  return (
    <S.LoaderContainer>
      <S.CircleOne mainColor={mainColor} borderColor={borderColor} />
      <S.CircleTwo mainColor={mainColor} borderColor={borderColor} />
      <S.CircleThree mainColor={mainColor} borderColor={borderColor} />
      <S.CircleFour mainColor={mainColor} borderColor={borderColor} />
    </S.LoaderContainer>
  );
};

export default CircularLoader;
