export const SpaceSchoolBadgeVariant = {
  Violet: "violet",
  Yellow: "yellow"
} as const;
export type SpaceSchoolBadgeVariant =
  (typeof SpaceSchoolBadgeVariant)[keyof typeof SpaceSchoolBadgeVariant];

export type SpaceSchoolBadgeProps = {
  variant?: SpaceSchoolBadgeVariant;
  className?: string;
};
