import React from "react";
import {
  OldExercise,
  OldResource,
  RESOURCE_DEPRECATED_TYPE,
  RESOURCE_POC_TYPE,
  RESOURCE_TYPE,
  Resource
} from "src/js/types";
import {
  Exercise,
  FallbackPlaceholder,
  Image,
  ImageGallery,
  Link,
  Lti,
  Maps,
  Pdf,
  Scorm,
  TextEditor,
  VideoPlayer,
  Vimeo,
  Youtube
} from "src/legacy/models/resources";

const getContentFromResourceMimeType = (resource: OldResource | Resource) => {
  const mimeType =
    "mime_type" in resource
      ? resource.mime_type
      : "fileMimeType" in resource
        ? resource.fileMimeType
        : null;
  if (!mimeType) return null;
  const publicUrl =
    "publicUrls" in resource
      ? resource.publicUrls[0]
      : "public_url" in resource
        ? resource.public_url
        : "";

  switch (mimeType) {
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
    case "image/gif":
    case "image/tiff":
      return <Image resource={resource} />;
    case "video/mp4":
    case "video/quicktime":
      return <VideoPlayer publicUrl={publicUrl} videoId={resource.id} />;
    case "application/pdf":
      return <Pdf id={resource.id} publicUrl={publicUrl} />;
    default:
      return <FallbackPlaceholder url={publicUrl} ctaLabel="scarica il file" />;
  }
};

// TODO improve typings
export const getContentFromResource = ({
  resource,
  exercise,
  boardElementId
}: {
  resource?: OldResource | Resource;
  exercise?: OldExercise;
  boardElementId?: number;
}) => {
  switch (resource?.type || exercise.type) {
    // Deprecated?
    case RESOURCE_TYPE.VIMEO:
      return (
        <Vimeo resource={resource as OldResource & { vimeo_id: string }} />
      );
    // Deprecated?
    case RESOURCE_TYPE.YOUTUBE:
      return (
        <Youtube resource={resource as OldResource & { youtube_id: string }} />
      );
    case RESOURCE_POC_TYPE.LTI:
      return <Lti elementId={boardElementId} />;
    case RESOURCE_TYPE.LINK:
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link resource={resource} boardElementId={boardElementId} />
      );
    case RESOURCE_TYPE.SCORM:
      // @ts-expect-error - TODO fix the error
      return <Scorm resource={resource} boardElementId={boardElementId} />;
    // Retro compatibility with depcrecated getReact()
    case RESOURCE_TYPE.IMAGE_GALLERY:
    case RESOURCE_DEPRECATED_TYPE.IMAGEGALLERY:
      return (
        <ImageGallery
          resource={
            resource as (OldResource | Resource) & { images: OldResource[] }
          }
        />
      );
    case RESOURCE_TYPE.FILE:
    case RESOURCE_TYPE.DROPBOX:
    case RESOURCE_TYPE.GOOGLE_DRIVE:
    case RESOURCE_TYPE.DOCUMENT:
    case RESOURCE_TYPE.SLIDESHOW:
    case RESOURCE_TYPE.SPREADSHEET:
    case RESOURCE_DEPRECATED_TYPE.ONEDRIVE:
      return getContentFromResourceMimeType(resource);
    case RESOURCE_DEPRECATED_TYPE.MAPS:
      return (
        <Maps
          resource={
            resource as OldResource & {
              maps_longitude: string;
              maps_latitude: string;
              maps_zoom: string;
            }
          }
        />
      );
    case RESOURCE_TYPE.EXERCISE:
    case RESOURCE_TYPE.EXAMINATION:
    case RESOURCE_DEPRECATED_TYPE.ASSIGNMENT:
      return <Exercise exercise={exercise} />;
    case RESOURCE_TYPE.TEXT:
      return <TextEditor resource={resource} />;
    default:
      if (exercise) {
        return <Exercise exercise={exercise} />;
      }
      let url;
      if ("public_url" in resource) {
        url = resource.public_url;
      } else {
        url = resource.publicUrls[0];
      }
      return (
        <iframe
          title={resource.name}
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          src={url}
        />
      );
  }
};
