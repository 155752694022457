import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "../modules/messageManager";
import { BasePaginationResponse } from "../types";
import {
  ReactableEntity,
  Reaction,
  ReactionAuthor,
  ReactionType
} from "../types/models/Reaction";
import {
  api_reaction,
  api_reactions,
  api_user_reactions,
  getVersionedApiUrl
} from "./apiUrls";

type AddReactionProps = {
  reactableEntity: ReactableEntity;
  reactedId: string;
  reactionType: ReactionType;
};

export type FetchUserProps = {
  limit?: number;
  offset?: number;
  reactableEntity?: ReactableEntity;
  reactedId?: string;
  reactionType?: ReactionType;
};

export const addReactionToEntity = async ({
  reactableEntity,
  reactedId,
  reactionType
}: AddReactionProps) => {
  const body = { reactableEntity, reactedId, reactionType };

  return getUserClient()
    .post<Reaction>(getVersionedApiUrl(api_reactions(), "v3"), body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

export const deleteReaction = async ({
  reactionId
}: {
  reactionId: string;
}) => {
  return getUserClient()
    .delete(getVersionedApiUrl(api_reaction({ reactionId }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

export const fetchUserReaction = async ({
  limit = 10,
  offset = 0,
  reactableEntity,
  reactedId,
  reactionType
}: FetchUserProps) => {
  // remove null values
  const params = Object.fromEntries(
    Object.entries({
      limit,
      offset,
      reactableEntity,
      reactedId,
      reactionType
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([_, v]) => v != null)
  );
  return getUserClient()
    .get<BasePaginationResponse<ReactionAuthor>>(
      getVersionedApiUrl(api_user_reactions(), "v3"),
      {
        params
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};
