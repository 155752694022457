import styled from "styled-components";

export const ModalContentContainer = styled.div`
  text-align: center;
  padding: 0 24px;
`;

export const ModalContentTitle = styled.p`
  margin: 32px 0 8px;

  @media screen and (max-width: 767px) {
    margin: 48px 0px 8px;
  }
`;

export const ModalContentSubtitle = styled.p`
  margin: 8px 0px 16px;
`;

export const ModalContentGroup = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 8px 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
`;

export const ModalContentGroupTitle = styled.span`
  flex: 1;
  margin-right: 15px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-family: ${({ theme }) => theme.fontFamily.default};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  line-height: 25px;

  @media screen and (max-width: 767px) {
    line-height: 20px;
  }
`;

export const ModalFooterContainer = styled.div`
  padding: 24px 24px 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
