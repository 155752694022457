import { makeAutoObservable, reaction } from "mobx";
import { fetchFilteredGroupList } from "src/js/repository/groupsListRepository";
import { __NOTIFICATION_FILTER_GROUPS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";

/**
 * @name NotificationFilterStore
 *
 * @description
 * Store informations about the groups used in the filter page
 *
 */

// TODO this store is almost useless, fix the group fetch and move the logic out

class NotificationFilterStore {
  $loading = true;

  $notificationGroups = {
    filterString: "",
    groupList: [],
    offset: 0,
    limit: __NOTIFICATION_FILTER_GROUPS_LIST_LIMIT__,
    total: 0,
    hasNext: false
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.disposeGroups();
  }

  fetchGroupList = async () => {
    try {
      const { groups, has_next } = await fetchFilteredGroupList({
        name: this.groupFilterString,
        start: 0,
        limit: this.groupLimit
      });

      const groupList = groups.map(({ id, name }) => ({
        key: id,
        value: name
      }));

      this.setGroupList({ list: groupList });
      this.setHasNextGroups({ hasNext: has_next });
      this.setGroupOffset({ offset: groups.length });
    } catch (e) {
      this.setGroupList({ list: [] });
      this.setHasNextGroups({ hasNext: false });
      this.setGroupOffset({ offset: 0 });
    }
  };

  fetchNextGroups = async () => {
    const { groups, has_next } = await fetchFilteredGroupList({
      name: this.groupFilterString,
      start: this.groupOffset,
      limit: this.groupLimit
    });

    const groupList = groups
      .filter(({ id }) => !this.groupList.find(group => group.key === id))
      .map(({ id, name }) => ({ key: id, value: name }));

    this.setGroupList({ list: [...this.groupList, ...groupList] });
    this.setHasNextGroups({ hasNext: has_next });
    this.setGroupOffset({ offset: this.groupList.length });
  };

  setGroupList = ({ list }) => {
    this.$notificationGroups.groupList = list;
  };

  setGroupFilterString = ({ filterString }) => {
    this.$notificationGroups.filterString = filterString;
  };

  setHasNextGroups = ({ hasNext }) => {
    this.$notificationGroups.hasNext = hasNext;
  };

  setGroupOffset = ({ offset }) => {
    this.$notificationGroups.offset = offset;
  };

  setIsLoading = ({ loading }) => {
    this.$loading = loading;
  };

  // computed

  get groupList() {
    return this.$notificationGroups.groupList;
  }

  get groupFilterString() {
    return this.$notificationGroups.filterString;
  }

  get groupOffset() {
    return this.$notificationGroups.offset;
  }

  get groupLimit() {
    return this.$notificationGroups.limit;
  }

  get groupHasNext() {
    return this.$notificationGroups.hasNext;
  }

  get isLoading() {
    return this.$loading;
  }

  disposeGroups = () => {
    reaction(
      // reacts on change of filterString
      () => this.groupFilterString,
      () => {
        this.fetchGroupList();
      }
    );
  };
}

export default NotificationFilterStore;
