import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";

import * as S from "./Label.styles";
import { LabelProps } from "./types";

const Label: FC<PropsWithChildren<LabelProps>> = ({
  theme,
  children,
  icon,
  color = "violet",
  outline = true
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.Label color={color} outline={outline}>
        {icon ? (
          <div>
            <Icon icon={icon} height="10px" width="10px" />
          </div>
        ) : null}
        <Text type="label">{children}</Text>
      </S.Label>
    </ThemeProvider>
  );
};

export default Label;
