import { Palette } from "@ds_themes/types";

export const createAnalyticsBoxTheme = ({ general, primary }: Palette) => {
  return {
    analyticsbox: {
      background: general.background,
      color: general.contrastText,
      title: {
        color: primary.main
      }
    }
  };
};
