import React from "react";
import createReactClass from "create-react-class";
import isFunction from "lodash/isFunction";
import videojs from "video.js";
import "videojs-youtube";

const VideoEmbed = createReactClass({
  getDefaultProps() {
    return {
      id: "video-embed",
      src: "",
      addClass: "",
      preload: "auto",
      width: "100%",
      height: "360",
      vimeo_id: "",
      youtube_id: "",
      updateTime: "",
      updateState: "",
      status: "",
      video: ""
    };
  },
  getInitialState() {
    return {
      player: {}
    };
  },
  componentDidMount() {
    this.init();
  },
  componentDidUpdate() {
    const self = this;
    const player = this.state.player;
    const youtubeSrc = self.props.youtube_id
      ? `https://www.youtube.com/embed/${self.props.youtube_id}`
      : null;
    if (self.props.vimeo_id != "") {
      if (self.props.status == "pause") {
        player.api("pause");
      }

      if (self.props.status == "play") {
        player.api("play");
      }
    } else if (
      youtubeSrc &&
      youtubeSrc != player.currentSrc() &&
      player.currentSrc() != ""
    ) {
      // console.log('= NEW SOURCE [' + self.props.src + ']');
      player.src({ type: "video/youtube", src: youtubeSrc });
    } else if (self.props.status == "pause") {
      player.pause();
    } else if (self.props.status == "play") {
      player.play();
    }
  },
  componentWillUnmount() {
    const self = this;
    if (self.props.vimeo_id != "") {
      self.state.player.removeEvent("playProgress");
      self.state.player.removeEvent("play");
      self.state.player.removeEvent("pause");
    } else {
      self.state.video.off("timeupdate");
      self.state.video.off("play");
      self.state.video.off("pause");
    }
  },
  init() {
    const self = this;
    let player = {};

    if (self.props.vimeo_id != "") {
      player = $f($(`#${self.props.id}`).get(0));
      player.addEvent("ready", () => {
        player.addEvent("playProgress", data => {
          if (isFunction(self.props.updateTime)) {
            self.props.updateTime(Math.floor(data.seconds));
          }
        });

        player.addEvent("play", () => {
          if (isFunction(self.props.updateState)) {
            self.props.updateState("play");
          }
        });
        player.addEvent("pause", () => {
          if (isFunction(self.props.updateState)) {
            self.props.updateState("pause");
          }
        });
      });
    } else {
      player = videojs($(`#${self.props.id}`).get(0), {
        techOrder: ["youtube"]
      });
      player.ready(function () {
        let video = this;
        self.setState(
          {
            video
          },
          () => {
            self.state.video.on("timeupdate", () => {
              if (isFunction(self.props.updateTime)) {
                self.props.updateTime(Math.floor(video.currentTime()));
              }
            });
            self.state.video.on("play", () => {
              if (isFunction(self.props.updateState)) {
                self.props.updateState("play");
              }
            });
            self.state.video.on("pause", () => {
              if (isFunction(self.props.updateState)) {
                self.props.updateState("pause");
              }
            });
          }
        );
      });
    }
    self.setState({ player });
  },
  render() {
    const self = this;
    let embed = <div />;

    if (self.props.src != "") {
      if (self.props.vimeo_id != "") {
        embed = (
          <iframe
            id={self.props.id}
            className={self.props.addClass}
            src={`https://player.vimeo.com/video/${self.props.vimeo_id}?api=1&&player_id=${self.props.id}`}
            width={self.props.width}
            height={self.props.height}
            frameBorder="0"
            mozallowfullscreen
            allowFullScreen
          />
        );
      } else {
        embed = (
          <video
            id={self.props.id}
            src={`https://www.youtube.com/embed/${self.props.youtube_id}`}
            className={`video-js vjs-default-skin ${self.props.addClass}`}
            preload={self.props.preload}
            width={self.props.width}
            height={self.props.height}
            data-setup={`{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "https://www.youtube.com/embed/${self.props.youtube_id}"}],"youtube": { "playsinline" : 1}}`}
          />
        );
      }
    }

    return embed;
  }
});

export default VideoEmbed;
