import React from "react";
import { motion } from "framer-motion";
import { Notification } from "..";

const NotificationSkeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, i) => (
        <motion.div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Notification loading />
        </motion.div>
      ))}
    </>
  );
};

export default NotificationSkeleton;
