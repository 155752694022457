import React, { createContext, useContext } from "react";
import { observer } from "mobx-react";
import { useAppLayout } from "src/js/layout/AppLayout";
import {
  DashboardLayoutContextType,
  DashboardLayoutProps
} from "./DashboardLayout.types";
import { useDashboardLayoutHeader } from "./hooks/useDashboardLayoutHeader";

import * as S from "./DashboardLayout.styles";

const DashboardLayoutContext = createContext<
  DashboardLayoutContextType | undefined
>(undefined);

export const withDashboardLayout = Component => {
  const WithDashboardLayout = props => {
    return (
      <DashboardLayoutContext.Consumer>
        {context => {
          if (!context) {
            throw new Error(
              "withDashboardLayout must be used within a DashboardLayoutContext.Provider"
            );
          }
          return <Component {...props} dashboardLayout={context} />;
        }}
      </DashboardLayoutContext.Consumer>
    );
  };

  return WithDashboardLayout;
};

export const useDashboardLayout = () => {
  const context = useContext(DashboardLayoutContext);
  if (!context) {
    throw new Error(
      "useDashboardLayout must be used within an DashboardLayoutContext.Provider"
    );
  }
  return context;
};

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { headerHeight } = useAppLayout();
  const { headerComponent, setHeaderComponent, resetHeaderComponent } =
    useDashboardLayoutHeader();
  return (
    <DashboardLayoutContext.Provider
      value={{ setHeaderComponent, resetHeaderComponent }}
    >
      <S.DashboardLayoutWrapper>
        <S.DashboardLayoutHeader headerHeight={headerHeight}>
          {headerComponent}
        </S.DashboardLayoutHeader>
        {children}
      </S.DashboardLayoutWrapper>
    </DashboardLayoutContext.Provider>
  );
};

export default observer(DashboardLayout);
