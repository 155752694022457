import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { useStores } from "src/js/hooks";
import { useUserTrainingHours } from "src/js/query/analytics";
import { useTranslation } from "src/js/translation";
import { BaseChartItem, DashboardEvent, EventDomain } from "src/js/types";
import { handleMetricCard } from "../../../Analytics.utils";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { EmptyMetric } from "../../../components/EmptyMetric";
import { SingleLineChart } from "../../../components/LineChart";
import { MetricCard } from "../../../components/MetricCard";
import { useAnalyticsFilter } from "../../../hooks";

const TrainingHours = ({
  noGroupsButton
}: {
  noGroupsButton?: ReactElement;
}) => {
  const {
    SpaceStore: { activeSpaceId },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { dateLabel, fromDate, toDate, groupIds, aggregation, xAxisFrequency } =
    useAnalyticsFilter();

  const { data: response, isFetching: loading } = useUserTrainingHours({
    spaceId: activeSpaceId,
    withChartData: !isLayoutModeMobile,
    fromDate,
    toDate,
    groupIds,
    aggregation
  });

  const hasSomeResults = response?.results.some(element => element.value !== 0);
  const isEmpty =
    !response?.results ||
    response?.results.length === 0 ||
    !!noGroupsButton ||
    !hasSomeResults;

  const {
    resultLabel = null,
    trendLabel = null,
    trendSeverity = null
  } = !isEmpty
    ? handleMetricCard({
        baseChartResponse: response,
        measurementUnit: translate("analytics_hour_abbreviation"),
        trendMeasurementUnit: translate("analytics_hour_abbreviation")
      })
    : {};

  const data: BaseChartItem[] = response?.results.map(element => {
    return {
      label: element.label,
      value: parseFloat((element.value / 100).toFixed(1))
    };
  });

  return (
    <MetricCard
      loading={loading}
      titleLabel={translate(
        "metric_card_objectives_training_hours_per_user_title"
      )}
      resultLabel={resultLabel}
      descriptionLabel={translate(
        "metric_card_objectives_training_hours_per_user_description"
      )}
      trendLabel={trendLabel}
      trendSeverity={trendSeverity}
      dateLabel={dateLabel}
      activeUsersLabel={`${
        response ? (response.totalActiveUsers / 100).toFixed(1) : 0
      } ${translate("metric_card_objectives_active_users_label")}`}
      infoTooltip={
        <CustomTooltip
          maxWidth="224px"
          title={translate(
            "metric_card_objectives_training_hours_per_user_info_tooltip_title"
          )}
          description={translate(
            "metric_card_objectives_training_hours_per_user_info_tooltip_description"
          )}
        />
      }
      trackingEvents={{
        tooltip: {
          domain: EventDomain.SpaceDashboardUsage,
          action: DashboardEvent.TimeonplatformViewInfo
        }
      }}
    >
      {isEmpty ? (
        <EmptyMetric
          title={translate("empty_metric_no_data_available")}
          description={translate(
            !noGroupsButton
              ? "empty_metric_change_time_period"
              : "empty_metric_no_groups"
          )}
        >
          {noGroupsButton}
        </EmptyMetric>
      ) : null}
      {!isEmpty && !isLayoutModeMobile ? (
        <SingleLineChart
          data={data}
          tooltipTitle={translate("training_hours_tooltip_title")}
          measurementUnit={translate("analytics_hour_abbreviation")}
          xAxisFrequency={xAxisFrequency}
        />
      ) : null}
    </MetricCard>
  );
};

export default observer(TrainingHours);
