import React from "react";
import styled, { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { Avatar } from "@arcadia/design-system";

const BoxContainer = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 5px 15px;
  text-align: center;
  background-color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: 12px;
`;

const InviterBadge = styled.div`
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  border: 1px solid #c9cfdf;
  overflow: hidden;
  margin-right: 15px;
  & div > div {
    height: 28px;
    width: 28px;
  }
  img {
    width: 30px !important;
    height: 30px !important;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const UnloggedInviterBox = ({ inviter }) => {
  const { avatar, name, surname } = inviter;
  const { whiteTheme } = useTheme();
  return (
    <BoxContainer>
      <InviterBadge>
        <Avatar
          theme={whiteTheme}
          url={avatar?.small}
          alt={`${name}-${surname}`}
          rounded
        />
      </InviterBadge>
      <Translate
        text="unlogged_inviter_banner_label"
        stringVariables={{ inviter_name: `${name} ${surname}` }}
      />
    </BoxContainer>
  );
};
