import { mobileMixin } from "src/js/theme/breakpointsMixin";
import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${mobileMixin`
  flex-direction: column-reverse`}
`;

export const TitleContainer = styled.div`
  flex: 1;
  margin-right: 16px;
`;

export const ActionsContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  margin-bottom: 32px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

export const CloseButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  border: none;

  & svg {
    flex: 0 0 16px;
  }
`;

export const ExtraInfo = styled.div`
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.primaryColor[500]};
  ${mobileMixin`order: -1;`}
`;

export const ExtraInfoText = styled.div<{ hasMarginLeft?: boolean }>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ hasMarginLeft }) =>
    hasMarginLeft &&
    css`
      margin-left: 10px;
    `}
`;

export const GrayDot = styled.span`
  background-color: ${({ theme }) => theme.colors.accent7};
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 16px;
`;
