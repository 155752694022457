import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./LiveDuration.styles";
import { LiveDurationProps } from "./types";

const LiveDuration: FC<LiveDurationProps> = ({ theme, duration }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.LiveDuration>
        <Icon icon="hourHalf" height="8px" width="8px" />
        <Text type="captionInfoDetails">{duration}</Text>
      </S.LiveDuration>
    </ThemeProvider>
  );
};

export default LiveDuration;
