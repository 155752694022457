import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "src/js/translation";

import { DotsMenu } from "src/js/components/DotsMenu";
import { DefaultPanel } from "src/js/components/dashboard/DefaultPanel";
import { Text } from "@arcadia/design-system";
import * as S from "./BadgePanel.styles";
import { BadgePanelProps } from "./types";

const BadgePanelMobile = ({
  spaceBadge,
  duration,
  menuItems,
  openGroupsModal,
  openUsersModal
}: BadgePanelProps) => {
  const { translate } = useTranslation();
  const {
    id,
    image,
    name,
    associatedGroupsCount,
    awardedUsersCount,
    isPrivate
  } = spaceBadge;

  return (
    <DefaultPanel key={id}>
      <S.InfoContainerMobile>
        <S.BadgeDetailsMobile>
          <S.BadgeImageContainer>
            <S.BadgeImage src={image} alt={`${name} logo`} />
            <S.PrivacyIndicationContainer isprivate={isPrivate}>
              <S.PrivacyIcon
                icon={isPrivate ? "eyeClosed" : "eyeOpen"}
                isprivate={isPrivate}
              />
            </S.PrivacyIndicationContainer>
          </S.BadgeImageContainer>
          <S.BadgeTitle>{name}</S.BadgeTitle>
          <S.BadgeActionsMobile>
            <DotsMenu id="DotsMenuMobile" items={menuItems} alignRight />
          </S.BadgeActionsMobile>
        </S.BadgeDetailsMobile>
        <S.BadgeInfoMobile>
          <S.BadgeInfoColumnMobile>
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_association")}
              </Text>
            </S.HeaderInfoColumn>
            {associatedGroupsCount ? (
              <S.ActionLabel data-testid="openGroups" onClick={openGroupsModal}>
                {translate("dashboard_badge_groups_label", {
                  groupCount: associatedGroupsCount
                })}
              </S.ActionLabel>
            ) : (
              <u>
                {translate("dashboard_badge_groups_label", {
                  groupCount: associatedGroupsCount
                })}
              </u>
            )}
          </S.BadgeInfoColumnMobile>
          <S.BadgeInfoColumnMobile>
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_award")}
              </Text>
            </S.HeaderInfoColumn>
            {awardedUsersCount ? (
              <S.ActionLabel data-testid="openUsers" onClick={openUsersModal}>
                {translate("dashboard_badge_users_label", {
                  userCount: awardedUsersCount
                })}
              </S.ActionLabel>
            ) : (
              <u>
                {translate("dashboard_badge_users_label", {
                  userCount: awardedUsersCount
                })}
              </u>
            )}
          </S.BadgeInfoColumnMobile>
          <S.BadgeInfoColumnMobile data-testid="duration">
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_duration")}
              </Text>
            </S.HeaderInfoColumn>
            {duration}
          </S.BadgeInfoColumnMobile>
        </S.BadgeInfoMobile>
      </S.InfoContainerMobile>
    </DefaultPanel>
  );
};

export default observer(BadgePanelMobile);
