import { skeletonAnimationMixin, Text } from "@arcadia/design-system";
import styled from "styled-components";
import { DotsMenu } from "../DotsMenu";

export const ScormActivityTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .dots-menu__open {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const PillStatus = styled.div<{ darkBk?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 100px;
  width: fit-content;

  background-color: ${({ theme, darkBk }) =>
    darkBk ? theme.primaryColor[500] : theme.colors.violet[900]};
  color: ${({ theme, darkBk }) =>
    darkBk ? theme.colors.grey[1000] : theme.colors.violet[200]};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const TableHeader = styled.thead`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const TableHeaderCell = styled.th`
  line-height: 15px; // Line height of the text in the header
  border: none; // Remove the default border
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor[900]};
  padding: 6px 0px;
  user-select: none;

  &:first-child {
    padding-left: 8px;
    border-top-left-radius: 8px;
  }
  &:last-child {
    padding-right: 8px;
    border-top-right-radius: 8px;
  }
`;

export const TableRow = styled.tr<{
  noHover?: boolean;
  cursorPointer?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  transition: background-color 225ms ease-in-out;

  ${({ cursorPointer }) => cursorPointer && "cursor: pointer;"}

  &:hover {
    background-color: ${({ theme, noHover }) =>
      !noHover && theme.colors.coldGrey[900]};
  }

  &:last-child {
    td:first-child {
      border-bottom-left-radius: 8px;
    }
    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
`;

export const TableHeaderText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
  text-transform: uppercase;
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td`
  border: none; // Remove the default border
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor[900]};
  padding: 16px 0px;
  line-height: 15px; // Line height of the text in the cell
  user-select: none;
  padding-left: 8px;
  &:first-child {
    padding-left: 16px;
  }
`;

export const TableCellText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[200]};
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Skeleton = styled.div`
  width: 60%;
  height: 12px;
  border-radius: 4px;
  background-color: transparent;
  ${({ theme }) =>
    skeletonAnimationMixin(
      theme.colors.coldGrey[900],
      theme.primaryColor[700]
    )};
`;

export const ScormNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 24px);

  > svg {
    min-width: 16px;
  }
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  min-height: 460px;
`;

export const NoResultsMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export const CustomDotsMenu = styled(DotsMenu)<{ isOpen?: boolean }>`
  position: relative;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.grey[50]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;
