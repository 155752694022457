import React from "react";
import { useTheme } from "styled-components";
import { Avatar, Text } from "@arcadia/design-system";
import * as S from "./UserCard.styles";

const UserCard = ({ firstname, lastname, avatar, email }) => {
  const { whiteTheme } = useTheme();
  return (
    <S.Card>
      <Avatar
        theme={whiteTheme}
        url={avatar}
        size="xs"
        alt={`${firstname}-${lastname}`}
        rounded
      />
      <S.UserData>
        <S.NameWrapper>
          <Text type="formDescription">
            {firstname}&nbsp;<S.UserLastname>{lastname}</S.UserLastname>
          </Text>
        </S.NameWrapper>
        <S.Mail>
          <Text type="formDescription">{email}</Text>
        </S.Mail>
      </S.UserData>
    </S.Card>
  );
};

export default UserCard;
