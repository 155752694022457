import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { __METATITLE_PREFIX__ } from "src/legacy/settings/settings";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "../translation";

type Props = {
  pageTitle: TranslationKeys;
};

const Title: FC<Props> = ({ pageTitle }) => {
  const { translate } = useTranslation();

  return (
    <Helmet>
      <title>
        {`${__METATITLE_PREFIX__}
        ${pageTitle ? ` | ${translate(pageTitle)}` : ""}`}
      </title>
    </Helmet>
  );
};

export default Title;
