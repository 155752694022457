import { makeAutoObservable } from "mobx";
import { AICourseGenFail, AICourseGenSuccess } from "../types/models/WebSocket";
import { ObjectValues } from "../types";

export const AITransactionStatus = {
  fail: "fail",
  success: "success",
  pending: "pending"
} as const;

type AITransactionStatus = ObjectValues<typeof AITransactionStatus>;

// data is just for precaution, is not necesseraly needed
type AITransaction = {
  aiTransactionId: string;
  status: AITransactionStatus;
  data?: AICourseGenFail["data"] | AICourseGenSuccess["data"];
};

class AIStore {
  $aiTransaction: AITransaction = {
    aiTransactionId: null,
    status: "pending",
    data: null
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get aiTransactionId() {
    return this.$aiTransaction.aiTransactionId;
  }

  get status() {
    return this.$aiTransaction.status;
  }

  get data() {
    return this.$aiTransaction.data;
  }

  setAITransactionId = (aiTransactionId?: string) => {
    this.$aiTransaction.aiTransactionId = aiTransactionId;
  };

  onAICourseGenSuccess = (data: AICourseGenSuccess) => {
    if (this.aiTransactionId !== data.data.aiTransactionId) return;
    this.$aiTransaction.status = AITransactionStatus.success;
    this.$aiTransaction.data = data.data;
  };

  onAICourseGenFail = (data: AICourseGenFail) => {
    if (this.aiTransactionId !== data.data.aiTransactionId) return;
    this.$aiTransaction.status = AITransactionStatus.fail;
    this.$aiTransaction.data = data.data;
  };

  reset = () => {
    this.$aiTransaction = {
      aiTransactionId: null,
      status: "pending",
      data: null
    };
  };
}

export default AIStore;
