import { AxiosResponse } from "axios";
import slug from "slug";
import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  api_lti_resource,
  api_module_resource_edit_title,
  api_module_resource_link_preview,
  api_module_resource_thumbnail,
  api_resource,
  api_resource_edit_title,
  api_resource_embeddable,
  api_spaces_resources,
  api_spaces_upload_file_authorize,
  api_group_resource,
  api_board_element_resource,
  api_post_resource,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import {
  NewResourceModel,
  RESOURCE_TYPE,
  Resource,
  ResourceType
} from "src/js/types";

slug.defaults.mode = "rfc3986";

type ResourceLinkPreviewResponseType = {
  description: string;
  title: string;
  url: string;
};

export type SaveResourceResponseType = {
  id: number;
  name: string;
  type: ResourceType;
};

type ResourceActionUrlResponse = {
  actionUrl: string;
  fileUuid: string;
  inputs: {
    "Content-Type": string;
    "X-Amz-Algorithm": string;
    "X-Amz-Credential": string;
    "X-Amz-Date": string;
    "X-Amz-Signature": string;
    Policy: string;
    bucket: string;
    key: string;
  };
};

export const fetchResource = (id: number) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_resource({ resource_id: id })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
};

export const fetchResourceEmbeddable = ({ url }: { url: string }) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_resource_embeddable()), {
      params: {
        url
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
};

export const saveResource = (data: {
  name: string;
  textContent: string;
  type: string;
  spaceSlug: string;
  copy_custom_preview_from?: number;
}) => {
  return getUserClient()
    .post(
      getVersionedApiUrl(
        api_spaces_resources({ spaceSlug: data?.spaceSlug }),
        "v3"
      ),
      data
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
};

export const savePostResourceTitle = (
  postId: number,
  resourceId: number,
  title: string
) => {
  return getUserClient()
    .put(
      getVersionedApiUrl(
        api_resource_edit_title({ post_id: postId, resource_id: resourceId })
      ),
      { title }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
};

// LTI POC
export function fetchLTIResource({ elementId }: { elementId: number }) {
  return getUserClient()
    .post(getVersionedApiUrl(api_lti_resource({ elementId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
}

// END LTI POC

export const saveModuleResourceTitle = (
  moduleId: number,
  resourceId: number,
  title: string
) => {
  return getUserClient()
    .put(
      getVersionedApiUrl(
        api_module_resource_edit_title({
          moduleId,
          resourceId
        })
      ),
      { name: title }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
};

export const saveResourceNew = async (data: NewResourceModel) => {
  try {
    const response: AxiosResponse<SaveResourceResponseType> =
      await getUserClient().post(
        getVersionedApiUrl(
          api_spaces_resources({ spaceSlug: data?.spaceSlug }),
          "v3"
        ),
        data
      );
    return response.data;
  } catch (error) {
    const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
    throw Err;
  }
};

export const getUploadFileActionUrl = async ({
  filePayload,
  isScorm,
  fileType
}: {
  filePayload: {
    file: File;
    spaceSlug: string;
  };
  isScorm?: boolean;
  fileType?: ResourceType;
}) => {
  const {
    file: { name, size: fileSize, type: fileMimeType },
    spaceSlug
  } = filePayload;

  const handleType = () => {
    if (fileType) {
      return fileType;
    }
    return isScorm ? RESOURCE_TYPE.SCORM : RESOURCE_TYPE.FILE;
  };

  const params = {
    fileType: handleType(),
    fileName: slug(name),
    spaceSlug,
    fileMimeType,
    fileSize
  };
  try {
    const response: AxiosResponse<ResourceActionUrlResponse> =
      await getUserClient().get(
        getVersionedApiUrl(
          api_spaces_upload_file_authorize({ spaceSlug }),
          "v3"
        ),
        { params }
      );

    return response.data;
  } catch (error) {
    const Err = new Error(error);
    throw Err;
  }
};

const getLinkPreview = async (link: string) => {
  const res = await getUserClient().post<ResourceLinkPreviewResponseType>(
    getVersionedApiUrl(api_module_resource_link_preview(), "v3"),
    { url: link }
  );

  return res.data;
};

export const getResourceLinkPreview = async (
  link: string,
  spaceSlug: string,
  elementId: number,
  setUploadingProgress?: (id: number, progress: number) => void,
  altTitle?: string,
  altUrl?: string
) => {
  try {
    // Since this is not an upload of a file, we're setting the progress directly to 99, until the process is complete.
    if (setUploadingProgress) setUploadingProgress(elementId, 99);
    let response: ResourceLinkPreviewResponseType;

    if (altTitle && altUrl) {
      response = {
        title: altTitle,
        url: altUrl,
        description: ""
      };
    } else {
      response = await getLinkPreview(link);
    }

    const { title, url } = response;
    const resourceResponse = await saveResourceNew({
      name: title || link,
      previewPublicUrl: url,
      type: "LINK",
      spaceSlug,
      publicUrls: [link]
    });
    if (setUploadingProgress) setUploadingProgress(elementId, 100);
    return resourceResponse;
  } catch (error) {
    throw error.response;
  }
};

export const saveResourceText = async (
  // TODO: remove all this useless chain of function and optimize in a simpler way
  content: string,
  title: string,
  spaceSlug: string,
  elementId: number,
  setUploadingProgress?: (id: number, progress: number) => void,
  aiTransactionId?: string
) => {
  try {
    if (setUploadingProgress) setUploadingProgress(elementId, 99);
    const resourceResponse = await saveResourceNew({
      name: title,
      textContent: content,
      type: "TEXT",
      spaceSlug,
      aiTransactionId
    });
    if (setUploadingProgress) setUploadingProgress(elementId, 100);
    return resourceResponse;
  } catch (error) {
    const Err = new Error(error);
    throw Err;
  }
};

export const saveResourceDropbox = async (
  // TODO: remove all this useless chain of function and optimize in a simpler way
  originalUrl: string,
  file: { name: string; bytes: number; thumbnailLink: string },
  spaceSlug: string,
  elementId: number,
  setUploadingProgress?: (id: number, progress: number) => void
) => {
  try {
    if (setUploadingProgress) setUploadingProgress(elementId, 50);
    const resourceResponse = await saveResourceNew({
      name: file.name,
      originalUrl,
      publicUrls: [originalUrl],
      originalName: file.name,
      size: file.bytes,
      preview: file.thumbnailLink,
      type: "DROPBOX",
      spaceSlug
    });
    if (setUploadingProgress) setUploadingProgress(elementId, 100);
    return resourceResponse;
  } catch (error) {
    const Err = new Error(error);
    throw Err;
  }
};

export const saveResourceGDrive = async (
  // TODO: remove all this useless chain of function and optimize in a simpler way
  token: string,
  title: string,
  spaceSlug: string,
  originalUrl: string,
  elementId: number,
  fileSize: number,
  setUploadingProgress?: (id: number, progress: number) => void
) => {
  try {
    if (setUploadingProgress) setUploadingProgress(elementId, 99);
    const resourceResponse = await saveResourceNew({
      name: title,
      applicationToken: token,
      publicUrls: [originalUrl],
      originalUrl,
      originalName: title,
      type: "GDRIVE",
      spaceSlug,
      size: fileSize
    });
    if (setUploadingProgress) setUploadingProgress(elementId, 100);
    return resourceResponse;
  } catch (error) {
    const Err = new Error(error);
    throw Err;
  }
};

export const updatePostResource = async ({
  data,
  postId
}: {
  data: { resources: unknown[] };
  postId: number;
}) => {
  return getUserClient()
    .put(getVersionedApiUrl(api_post_resource({ post_id: postId }), "v2"), data)
    .then(res => res.data);
};

export const updateBoardElementResource = async ({
  boardElementId,
  data
}: {
  boardElementId: number;
  data: { resource: unknown };
}) => {
  return getUserClient()
    .put(
      getVersionedApiUrl(
        api_board_element_resource({ board_element_id: boardElementId }),
        "v2"
      ),
      data
    )
    .then(res => res.data);
};
export const updateResourceThumbnail = async (
  previewImg: File,
  moduleId?: number,
  elementId?: number
) => {
  const formData = new FormData();
  formData.append("previewImg", previewImg);
  try {
    const response = await getUserClient().post(
      getVersionedApiUrl(
        api_module_resource_thumbnail({ moduleId, elementId }),
        "v3"
      ),
      formData
    );
    return response;
  } catch (error) {
    const Err = new Error(error);
    throw Err;
  }
};

export const updateTextResource = async ({
  resourceId,
  groupId,
  name,
  textContent
}: {
  groupId: number;
  resourceId: number;
  name: string;
  textContent: string;
}) => {
  return getUserClient()
    .patch<Resource>(
      getVersionedApiUrl(api_group_resource({ groupId, resourceId }), "v3"),
      {
        name,
        textContent
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_Resource"));
      throw Err;
    });
};
