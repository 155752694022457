import React from "react";
import * as S from "./GroupWallCover.styles";

const GroupWallCover = ({ image, groupName }) => {
  return (
    <S.GroupCover image={image}>
      <S.GroupTitle>{groupName}</S.GroupTitle>
    </S.GroupCover>
  );
};

export default GroupWallCover;
