import styled from "styled-components";

export const PostComment = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  gap: 2%;
  @media screen and (min-width: 1110px) {
    gap: 1%;
  }
`;

export const WrapperText = styled.div`
  background-color: ${({ theme }) => theme.post.comment.background};
  border-radius: 8px;
  width: 90%;

  @media screen and (min-width: 768px) {
    padding: 15px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
`;

export const WrapperUser = styled.div`
  display: flex;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  svg {
    margin-left: 4px;
    margin-bottom: -2px;
    color: ${({ theme }) => theme.post.comment.ribbon};
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.post.comment.date.text};
  @media screen and (min-width: 768px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 767px) {
    margin-left: 0px;
  }
`;
