import React, { FC } from "react";
import {
  SelectableList,
  SelectableListEmptyState
} from "src/js/components/layout/SelectableList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { showToastFunctional } from "src/js/modules/messageManager";
import {
  cloneBoardModule,
  fetchResourcesBoards
} from "src/js/repository/boardRepository";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { ImportModuleItemCard } from "./ImportModuleItemCard";

export type ModuleType = {
  name: string;
  id: number;
  groupName: string;
  group_name: string;
};

const getEmptyStateFromStatus = status => (
  <SelectableListEmptyState
    status={status}
    emptyListLabel="board_module_import_empty_list"
    emptySearchLabel="board_module_import_empty_search"
  />
);
const getCustomItemCard = props => <ImportModuleItemCard {...props} />;

const ImportModuleModal: FC = () => {
  const { translate } = useTranslation();
  const {
    BoardsStore: { fetchBoardsList },
    GroupStore: { groupId },
    ModalStore: { closeModal }
  } = useStores();
  const { whiteTheme } = useTheme();

  const handleImport = (boardIds: string[]) => {
    closeModal();
    Promise.all(boardIds.map(boardId => cloneBoardModule({ boardId, groupId })))
      .then(() => {
        fetchBoardsList({ groupId, isPublished: undefined });
      })
      .catch(() => {
        showToastFunctional(
          translate("board_module_import_warning_incomplete_message"),
          whiteTheme
        );
      });
  };

  const fetchItems = ({ name = "", limit, offset }) => {
    return fetchResourcesBoards({
      name,
      offset,
      limit
    }).then(data => {
      const results: ModuleType[] = data.results.map((item: ModuleType) => ({
        name: item?.name,
        groupName: item?.group_name,
        id: item?.id
      }));
      return { results, hasNext: null };
    });
  };

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate("board_module_import_title"),
        mobileClose: translate("board_module_import_cta_mobile_close")
      }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false
      }}
      desktopProps={{
        hasAdaptiveHeight: false
      }}
    >
      <SelectableList
        onCancel={closeModal}
        onConfirm={handleImport}
        fetchItems={fetchItems}
        multipleSelect
        selectedItemsIds={[]}
        customComponents={{
          getEmptyStateFromStatus,
          getCustomItemCard
        }}
        labels={{
          translatedConfirm: translate("board_module_import_cta_confirm"),
          translatedCancel: translate("board_module_import_cta_cancel"),
          translatedTitle: translate("board_module_import_content_title"),
          searchPlaceholder: translate(
            "board_module_import_mobile_search_placeholder"
          )
        }}
      />
    </ModalLayout>
  );
};

export default ImportModuleModal;
