import React, { useCallback } from "react";
import { AnimatePresence } from "framer-motion";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import { Box } from "@ds_universal/Box";

import * as S from "./VerticalStepper.styles";
import {
  verticalStepperVariants,
  defaultContentWrapperProps,
  activeItemIconProps
} from "./VerticalStepper.utils";
import {
  VerticalStepperProps,
  VerticalStepperStep
} from "./VerticalStepper.types";

const VerticalStepper = ({
  step = 0,
  items,
  jumpToStep = false,
  onStepJump
}: VerticalStepperProps) => {
  const jump = useCallback(
    stepIndex => () => {
      if (!jumpToStep) return;
      onStepJump?.(stepIndex);
    },
    [jumpToStep, onStepJump]
  );

  const renderActiveItem = (index: number, item: VerticalStepperStep) => {
    const { hasLoading, hasError } = item;
    if (hasLoading || hasError) {
      return (
        <Icon
          icon={hasLoading ? "circleLoader" : "errorCircle"}
          width={16}
          height={16}
          initialViewbox
        />
      );
    }
    return <Text type="formField">{index + 1}</Text>;
  };

  const handleItemIcon = (index: number, item: VerticalStepperStep) => {
    const { hasLoading } = item;
    if (index === step) {
      return (
        <S.DotContentWrapper
          key={`dot-container-${index}`}
          {...activeItemIconProps(hasLoading)}
        >
          {renderActiveItem(index, item)}
        </S.DotContentWrapper>
      );
    } else if (index < step) {
      return (
        <S.DotContentWrapper
          key={`dot-container-${index}`}
          {...defaultContentWrapperProps}
        >
          <Icon icon="check2" width={16} height={16} initialViewbox />
        </S.DotContentWrapper>
      );
    }

    return (
      <S.DotContentWrapper
        key={`dot-container-check-${index}`}
        {...defaultContentWrapperProps}
      >
        <Text type="formField">{index + 1}</Text>
      </S.DotContentWrapper>
    );
  };

  return (
    <S.DropdownWrapper>
      {items.map((item, index) => (
        <S.DropdownHeader
          active={index < step}
          key={`dropdown-${item.title}-header-${index}`}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <S.Dot
              isActive={step === index}
              isCompleted={step >= index}
              hasError={item.hasError}
              onClick={jump(index)}
            >
              <AnimatePresence exitBeforeEnter>
                {handleItemIcon(index, item)}
              </AnimatePresence>
            </S.Dot>
            <S.TextWrapper active={step >= index}>
              <Text type="formField">{item.title}</Text>
            </S.TextWrapper>
          </Box>
          <S.DropdownContent
            animate={step === index ? "stepOpen" : "stepClosed"}
            initial={false}
            variants={verticalStepperVariants}
            key={`dropdown-${item.title}-content`}
          >
            <S.LineSpacer />
            {item.content}
          </S.DropdownContent>
        </S.DropdownHeader>
      ))}
    </S.DropdownWrapper>
  );
};

export default VerticalStepper;
