import moment from "moment";
import React from "react";
import { useTheme } from "styled-components";
import { Icon, Text, ActionButton } from "@arcadia/design-system";
import { Col, Row } from "react-awesome-styled-grid";
import { Translate } from "src/js/translation/TranslationProvider";
import { Panel } from "../Panel";

import * as S from "./UserPermissionPanel.styles";

const ROLES = { ROLE_POWERUSER: "ROLE_POWERUSER", ROLE_ADMIN: "ROLE_ADMIN" };

const UserPermissionPanel = ({
  firstName = "",
  lastName = "",
  email = "",
  permission = ROLES.ROLE_POWERUSER,
  createdAt = "",
  onEditClick = () => {}
}) => {
  const dateCreation = createdAt && moment(createdAt).format("L");
  const { whiteTheme } = useTheme();

  const userFullNameWithMail = (
    <Text type="formDescription">
      <S.UserFullName>
        {firstName}&nbsp;<S.UserSurname>{lastName}</S.UserSurname>
        <Text type="formSmallCaption">
          <S.Mail>{email}</S.Mail>
        </Text>
      </S.UserFullName>
    </Text>
  );

  const userPermissions = (
    <S.PermissionContainer>
      <S.Permission>
        <S.CheckIconContainer>
          <Icon icon="successCircle" height="16px" width="16px" />
        </S.CheckIconContainer>
        <Text type="formDescription">
          <Translate text="space_group_creation_permission_label" />
        </Text>
      </S.Permission>
      <S.Permission>
        {permission === ROLES.ROLE_POWERUSER ? (
          <S.CrossIconContainer>
            <Icon icon="errorCircle" height="16px" width="16px" />
          </S.CrossIconContainer>
        ) : (
          <S.CheckIconContainer>
            <Icon icon="successCircle" height="16px" width="16px" />
          </S.CheckIconContainer>
        )}
        <Text type="formDescription">
          <Translate text="space_access_permission_label" />
        </Text>
      </S.Permission>
    </S.PermissionContainer>
  );

  return (
    <Panel height={120} padding={20}>
      <S.InfoContainer>
        <S.TopMobile>
          <S.TopMobileTitle>{userFullNameWithMail}</S.TopMobileTitle>
          <S.TopMobileActionContainer>
            <S.ActionButtonWrapper>
              <ActionButton
                onClick={onEditClick}
                theme={whiteTheme}
                variant="tertiary"
                icon="shieldPermissions"
              />
            </S.ActionButtonWrapper>
          </S.TopMobileActionContainer>
        </S.TopMobile>

        <S.BottomMobile>
          <Row justify={{ xs: "space-between" }}>
            <Col>
              <S.BottomMobileTitle>
                <Text type="label">
                  <Translate text="space_settings_user_list_permissions_title" />
                </Text>
              </S.BottomMobileTitle>
              {userPermissions}
            </Col>

            <Col>
              <Row align={{ lg: "flex-end", xl: "flex-end" }}>
                <Col>
                  <S.BottomMobileTitle>
                    <Text type="label">
                      <Translate text="nomination_date_title" />
                    </Text>
                  </S.BottomMobileTitle>
                  {dateCreation}
                </Col>
              </Row>
            </Col>
          </Row>
        </S.BottomMobile>

        <S.TabletAndUp>
          <S.Column large>{userFullNameWithMail}</S.Column>
          <S.Column large>{userPermissions}</S.Column>
          <S.Column>{dateCreation}</S.Column>
          <S.Column>
            <S.ActionsWrapper>
              <S.ActionButtonWrapper>
                <ActionButton
                  onClick={onEditClick}
                  theme={whiteTheme}
                  variant="tertiary"
                  icon="shieldPermissions"
                />
              </S.ActionButtonWrapper>
            </S.ActionsWrapper>
          </S.Column>
        </S.TabletAndUp>
      </S.InfoContainer>
    </Panel>
  );
};

export default UserPermissionPanel;
