import styled from "styled-components";

export const ContextualErrorContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.contextualerror.background};
  border: ${({ theme }) => theme.contextualerror.border};
  color: ${({ theme }) => theme.contextualerror.text};
  vertical-align: center;
  max-width: 390px;
  width: 100%;
`;

export const ContextualErrorIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ContextualErrorMessage = styled.div`
  padding-left: 10px;
  @media screen and (max-width: 767px) {
    padding-left: 12px;
  }
`;
