import React, { forwardRef, useCallback, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Box } from "@ds_universal/Box";
import { Tag } from "@ds_universal/data_display";

import { SearchBar } from "../SearchBar";

import { SearchbarWithDropdownProps } from "./types";
import { useClickOutsideState } from "./hooks";
import * as S from "./SearchbarWithDropdown.styles";

const SearchbarWithDropdown = forwardRef<
  HTMLInputElement,
  SearchbarWithDropdownProps
>(
  (
    {
      theme,
      width = "75%",
      expandedWidth = "100%",
      dropdownWidth = "100%",
      value,
      onChange,
      onReset,
      onLeftFilterClick,
      placeholder,
      onSubmit,
      onFocus,
      onBlur,
      onContainerClick,
      dropdownContent,
      activeLeftFilterLabel,
      rightFilterBadge,
      isOpen = false,
      onToggle,
      ...props
    },
    ref
  ) => {
    const { searchRef, isExpanded, setExpanded } = useClickOutsideState(isOpen);

    useEffect(() => {
      if (!onToggle) return;
      onToggle(isExpanded);
    }, [isExpanded, onToggle]);

    const handleKeyPress: React.KeyboardEventHandler<
      HTMLDivElement
    > = event => {
      setExpanded(true);
      if (event.key === "Enter") {
        event.preventDefault();
        onSubmit(
          event as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>
        );
        setExpanded(false);
      }
    };

    const onSearchbarClick: React.MouseEventHandler<HTMLDivElement> = e => {
      onContainerClick?.(e);

      setExpanded(true);
    };

    const endAdornment = useCallback(() => {
      const leftSection = value ? (
        <Box
          cursor="pointer"
          display="flex"
          onClick={e => {
            e.stopPropagation();
            onReset();
          }}
        >
          <Icon icon="close" />
        </Box>
      ) : isExpanded ? null : (
        <Icon icon="search" />
      );
      const rightSection =
        rightFilterBadge && isExpanded ? (
          <S.BadgeWrapper>{rightFilterBadge}</S.BadgeWrapper>
        ) : null;

      return (
        <Box display="flex" gap="16px" alignItems="center">
          {leftSection} {rightSection}
        </Box>
      );
    }, [isExpanded, onReset, rightFilterBadge, value]);

    const startAdornment = useCallback(() => {
      const rightSection = activeLeftFilterLabel ? (
        <Tag
          id={activeLeftFilterLabel}
          theme={theme}
          color="violet"
          label={activeLeftFilterLabel}
          onClick={onLeftFilterClick}
        />
      ) : null;
      const leftSection = isExpanded ? <Icon icon="search" /> : null;

      return (
        <Box display="flex" alignItems="center" gap="8px">
          {leftSection} {rightSection}
        </Box>
      );
    }, [isExpanded, activeLeftFilterLabel, theme, onLeftFilterClick]);

    return (
      <ThemeProvider theme={theme}>
        <S.Wrapper
          expanded={isExpanded}
          hasActiveLeftFilter={!!activeLeftFilterLabel}
          ref={searchRef}
          onKeyPress={handleKeyPress}
          width={width}
          expandedWidth={expandedWidth}
        >
          <SearchBar
            theme={theme}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            ref={ref}
            endAdornment={endAdornment()}
            startAdornment={startAdornment()}
            value={value}
            onChange={onChange}
            variant="thinner"
            onContainerClick={onSearchbarClick}
            {...props}
          />
          {dropdownContent && isExpanded ? (
            <S.DropdownWrapper width={dropdownWidth}>
              {dropdownContent}
            </S.DropdownWrapper>
          ) : null}
        </S.Wrapper>
      </ThemeProvider>
    );
  }
);

SearchbarWithDropdown.displayName = "SearchbarWithDropdown";

export default SearchbarWithDropdown;
