import {
  AIModule,
  ModulesReqBody
} from "src/js/hooks/useAIServiceFetcher.types";
import { ModuleListItem } from "../CourseListPreviewStep/components/PreviewModuleList";
import { CourseDetailsWizardForm } from "../CourseDetailsWizard/CourseDetailsWizard.types";

export const parseAIModule = (
  aiModuleList: AIModule[],
  timeEffort: number
): ModuleListItem[] => {
  return aiModuleList.map(module => ({
    description: module.description,
    title: module.title,
    id: `module-${module.number}`,
    isExpanded: module.number === 1,
    time: timeEffort
  }));
};

export const parseModuleListItem = (
  moduleListItems: ModuleListItem[]
): AIModule[] => {
  return moduleListItems.map((module, index) => ({
    description: module.description,
    title: module.title,
    number: index + 1
  }));
};

export const parseModulesReqBody = (
  rawData: CourseDetailsWizardForm
): ModulesReqBody => {
  return {
    modules: {
      hoursForModules: rawData.effort,
      numberOfModules: rawData.courseLength,
      target: rawData.audience,
      topic: rawData.topic,
      type: rawData.courseTypology
    }
  };
};
