import styled from "styled-components";

import { SProgressBarProps } from "./types";

export const LineProgressBar = styled.div<SProgressBarProps>`
  width: ${({ percentage }) => `${percentage}%`};
  height: ${({ variant }) => (variant === "thick" ? "10px" : "4px")};
  background-color: ${({ theme }) => theme.progressbar.background};
  border-radius: ${({ variant }) =>
    variant === "thick" ? "5px" : "0px 2px 2px 0px"};
  transition: width 0.2s linear;
  min-width: ${({ variant }) => variant === "thick" && "10px"};
`;

export const ProgressBarGutter = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.progressbar.gutter};
  border-radius: 5px;
`;
