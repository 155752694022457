import {
  SearchBarStyles,
  VariantType,
  colors,
  scrollbarMixin
} from "@arcadia/design-system";
import styled from "styled-components";
import { ListSelectorItem } from "../../global/ListSelector";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 8px 8px 8px;
`;

export const SearchBarWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
  ${SearchBarStyles.SearchBar} {
    height: 36px;
  }
  @media screen and (max-width: 768px) {
    ${SearchBarStyles.SearchBar} {
      background: ${colors.grey[1000]};
    }
  }
`;

export const FooterWrapper = styled.div`
  position: absolute;
  padding: 20px;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  background: ${`linear-gradient(180deg, transparent 0%, ${colors.grey[1000]} 100%)`};
  @media screen and (max-width: 768px) {
    background: ${`linear-gradient(180deg, transparent 0%, ${colors.grey[900]} 100%)`};
    padding: 16px;
    flex-direction: column-reverse;
    margin-top: auto;
    zoom: 1.15;
  }
  button {
    width: auto;
    min-width: 92px;
  }
`;

export const ScrollableArea = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 0 8px;
  ${ListSelectorItem}:last-child {
    margin-bottom: 72px;
  }
  ${scrollbarMixin(VariantType.White)}
  @media screen and (max-width: 768px) {
    ${scrollbarMixin(VariantType.Grey)}
  }
`;

export const SelectableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px 0 12px;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 16px 8px 0 8px;
  }
`;
