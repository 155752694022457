import styled, { css } from "styled-components";
import * as ToggleBaseS from "../EditBoardToggleElement/EditBoardToggleElement.styles";

type Props = {
  isOpen: boolean;
};

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Layout = styled(ToggleBaseS.Layout)<Props>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      padding: 24px 6px 0 6px;
    `};
`;

export const Content = styled.div<Props>`
  max-height: 0px;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      overflow: unset;
      max-height: unset;
      padding-bottom: 24px;
    `};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding-top: 16px;
`;

export const TextAreaWrapper = styled.div`
  padding-top: 16px;
`;

export const BannerWrapper = styled.div`
  margin-top: 8px;

  & > div:first-child {
    max-width: 100%;
  }
`;
