import styled from "styled-components";
import { Heading, Text } from "@arcadia/design-system";

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const Img = styled.img`
  width: 152px;
`;

export const Message = styled(Heading)`
  color: ${({ theme }) => theme.primaryColor[50]};
`;

export const ButtonWrapper = styled.div`
  display: block;
  max-width: 340px;
  margin: auto;
  margin-top: 36px;
`;

export const CancelText = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;
