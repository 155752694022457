import { ReactionCounter } from "./Reaction";
import { UserDetailsModel } from "./User";

/* eslint-disable no-use-before-define */
export type BaseComment = {
  id: number;
  user: Omit<UserDetailsModel, "id">;
  text: string;
  status: boolean;
  nestedComments: NestedComment[];
  reactions: ReactionCounter;
  createdAt: number;
  updatedAt: number;
  moderated: unknown;
};

export type PostComment = BaseComment & {
  postId: number;
};

export type BoardElementComment = BaseComment & {
  boardElementId: number;
};

export type NestedComment = Omit<BaseComment, "nestedComments"> & {
  parentCommentId: number;
};

// custom type guard
export const isPostComment = (arg: any): arg is PostComment => {
  return arg.postId !== undefined;
};
