import { Box, Icon, IconName, Text, colors } from "@arcadia/design-system";
import React from "react";
import styled from "styled-components";

export const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.yellow[500]};
  background: ${colors.yellow[800]};
  padding: 10px 16px;
`;

const StyledIcon = styled(Icon)`
  flex: none;
`;

const BannerWarning = ({
  icon,
  children
}: {
  icon: IconName;
  children: React.ReactNode;
}) => {
  return (
    <StyledWarning>
      <StyledIcon initialViewbox icon={icon} height={16} width={16} />
      <Box marginLeft={16}>
        <Text type="captionAlert">{children}</Text>
      </Box>
    </StyledWarning>
  );
};

export default BannerWarning;
