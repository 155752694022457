import { Button } from "@arcadia/design-system";
import React from "react";
import { userIsUnderage } from "src/js/UserFunction";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import { GroupJoinWithCodeModal } from "src/js/components/GroupJoinWithCodeModal";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTranslation } from "src/js/translation";
import { EventDomain, GroupEvent } from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { useTheme } from "styled-components";

import * as S from "../HeaderWhiteHome.styles";

const CreateOrJoinGroup = () => {
  const {
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser },
    SpaceStore: { canCreateGroups },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const { trackVirtualView } = useViewTracking();

  const openGroupJoinWithCodeModal = () => {
    openModal(() => <GroupJoinWithCodeModal closeModal={closeModal} />);
    trackVirtualView(WeSchoolVirtualViewDescriptions.Groups.Join.WithCode);
  };

  const openGroupCreationModal = () => {
    openModal(() => <GroupCreationModal />);
    _trackEvent(EventDomain.Group, GroupEvent.HomePageGroupCreation);
  };

  return (
    <S.ButtonsWrapper>
      <S.ButtonWrapper>
        <Button
          onClick={openGroupJoinWithCodeModal}
          theme={whiteTheme}
          variant="secondary"
          fullWidth={isLayoutModeMobile}
        >
          {translate("join_group_button")}
        </Button>
      </S.ButtonWrapper>
      {!userIsUnderage(activeUser) && canCreateGroups && (
        <S.ButtonWrapper>
          <Button
            id={UsetifulId.SpaceHomeCreateGroupCta}
            onClick={openGroupCreationModal}
            theme={whiteTheme}
            variant="primary"
            fullWidth={isLayoutModeMobile}
          >
            {translate("crea nuovo gruppo")}
          </Button>
        </S.ButtonWrapper>
      )}
    </S.ButtonsWrapper>
  );
};

export default CreateOrJoinGroup;
