import React, { useRef } from "react";

import {
  ScrollableContainer,
  AutoScroll,
  CarouselDropdown
} from "src/js/pages/wall/ButtonsCarousel/ButtonsCarousel.styles";

const ButtonsCarousel = ({ children }) => {
  const containerRef = useRef();

  let isOverflowing = false;

  if (containerRef && containerRef.current) {
    isOverflowing =
      containerRef.current.scrollWidth > containerRef.current.offsetWidth;
  }

  return (
    <>
      <ScrollableContainer ref={containerRef}>
        {isOverflowing ? <AutoScroll>{children}</AutoScroll> : children}
      </ScrollableContainer>
      <CarouselDropdown id="carousel-dropdown" />
    </>
  );
};

export default ButtonsCarousel;
