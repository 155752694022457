import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import { createURL } from "../modules/utility";
import { __ } from "../modules/localization";
import { navigateTo } from "../modules/history";
import { getActiveGroupId } from "../modules/activeGroup";
import { InternalDispatcher } from "../modules/dispatcher";
import InstantsLive from "../view/exercise/instantsLive";
import createUrl from "src/js/modules/routing";
import { showToastError } from "src/js/modules/messageManager";

class SingleInstantController extends React.Component {
  constructor() {
    super();
    this.state = { instant: null };
    this.loaded = false;
  }

  componentWillReceiveProps() {
    if (typeof this.props.match.params.exercise_id !== "undefined") {
      this.loaded = false;
    }
  }

  fetchInstants() {
    InternalDispatcher.trigger("startLoading");
    const self = this;
    if (!isUndefined(this.props.match.params.instant_id)) {
      this.instantId = parseInt(this.props.match.params.instant_id, 10);
      if (isNumber(this.instantId) && !isNaN(this.instantId)) {
        const data = {
          type: "instant",
          instant_id: this.instantId
        };
        $.ajax({
          type: "GET",
          dataType: "json",
          url: createURL("api_exercises_reports_users_instant", {
            exercise_id: this.props.exercise.get("id")
          }),
          data,
          success(ret) {
            InternalDispatcher.trigger("stopLoading");
            if (ret.status == "finished") {
              showToastError({ str: __("instant_no_longer_available_error") });
              navigateTo(createURL("x", { group_id: getActiveGroupId() }), {
                trigger: true
              });
            } else {
              self.setState({ instant: ret });
            }
          }
        });
      } else {
        navigateTo(createURL("exercises", { group_id: getActiveGroupId() }));
      }
    }
  }

  render() {
    const self = this;
    if (self.state.instant === null) {
      self.fetchInstants();
    }
    return self.state.instant !== null ? (
      <Switch>
        <Route
          exact
          path={
            WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseInstantLive
          }
          component={props => (
            <InstantsLive
              exercise={self.props.exercise}
              instant={self.state.instant}
              reports={self.state.instant.exercise_executions}
              {...props}
            />
          )}
        />
        <Route component={() => <Redirect to={createUrl("home")} />} />
      </Switch>
    ) : (
      <div />
    );
  }
}

export default SingleInstantController;
