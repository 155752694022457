import { Text } from "@arcadia/design-system";
import React from "react";
import { CircularLoader } from "src/js/components/global/CircularLoader";
import { useStores } from "src/js/hooks";
import { TranslationKeys, useTranslation } from "src/js/translation";
import styled from "styled-components";

const StyledLoadingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px;
  width: fit-content;
  gap: 8px;
  padding: 28px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.primaryColor[500]};
`;
const StyledTitle = styled(Text)`
  font-weight: bold;
`;

const LoadingCard = ({
  className,
  title,
  subtitle
}: {
  className?: string;
  title: TranslationKeys;
  subtitle: TranslationKeys;
}) => {
  const { translate } = useTranslation();
  const {
    GroupStore: { activeGroup }
  } = useStores();
  return (
    <StyledLoadingCard className={className}>
      <CircularLoader size={32} />
      <StyledTitle type="formSubtitle">
        {translate(title, { group: activeGroup?.name })}
      </StyledTitle>
      <Text type="formSubtitle">{translate(subtitle)}</Text>
    </StyledLoadingCard>
  );
};

export default LoadingCard;
