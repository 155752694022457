import { Model } from "backbone";
import { createURL } from "../modules/utility";

class InstantModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    this.urlRoot = createURL("api_exercises_instant_list");
  }
}

export default InstantModel;
