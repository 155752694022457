import React from "react";
import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import { useTranslation } from "src/js/translation";
import { CircularLoader } from "src/js/components/global/CircularLoader";

import * as S from "./LoadingMenu.styles";

const LoadingMenu = () => {
  const { translate } = useTranslation();
  return (
    <S.LoadingMenuWrapper>
      <CircularLoader size={20} />
      <Text type="formSubtitle">
        {translate("ai_text_editor_loader_text")}
        {[...Array.from({ length: 3 })].map((_, index) => (
          <motion.span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            animate={{
              opacity: [0, 1, 0]
            }}
            transition={{
              duration: 2,
              loop: Infinity,
              delay: 0.2 * index
            }}
          >
            .
          </motion.span>
        ))}
      </Text>
    </S.LoadingMenuWrapper>
  );
};

export default LoadingMenu;
