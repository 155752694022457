import styled from "styled-components";
import {
  __GROUP_OVERQUOTA_BLOCKED_BACKGROUND_DESKTOP__,
  __GROUP_OVERQUOTA_BLOCKED_BACKGROUND_MOBILE__
} from "src/js/settings/settingsImage";
import { Text, shadow } from "@arcadia/design-system";

type withPageStyle = {
  pageMinHeight: string;
};

export const Wrapper = styled.div<withPageStyle>`
  width: 100%;
  height: ${({ pageMinHeight }) => pageMinHeight};
  background-image: url(${__GROUP_OVERQUOTA_BLOCKED_BACKGROUND_DESKTOP__});
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  @media screen and (max-width: 768px) {
    background-image: url(${__GROUP_OVERQUOTA_BLOCKED_BACKGROUND_MOBILE__});
    background-size: calc(100% + 60px);
  }
`;

export const ModalOverlay = styled.div<withPageStyle>`
  position: absolute;
  width: 100%;
  height: ${({ pageMinHeight }) => pageMinHeight};
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 890;
`;

export const ModalContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[700]};
  border-radius: 8px;
  box-shadow: ${shadow[200]};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  max-width: 500px;
  padding: 24px;

  @media screen and (max-height: 735px) {
    max-height: 500px;
    overflow-y: scroll;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
    max-height: initial;
    padding: 16px;
    position: fixed;
    bottom: 58px;
    border-radius: 8px 8px 0 0;
    overflow-x: hidden;
    overflow-y: initial;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-top: 24px;
`;

export const SlideshowWrapper = styled.div`
  min-height: 100px;
  margin-top: 24px;
  & > div {
    & > div:last-child {
      margin-top: 90px;
    }
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const CheckListContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const CheckList = styled.div`
  color: ${({ theme }) => theme.colors.violet[500]};
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
`;

export const ListElement = styled(Text)`
  & svg {
    margin-right: 8px;
  }
`;

export const Description = styled(Text)`
  display: block;
  margin-top: 24px;
  text-align: center;
`;

export const ModalFooterContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;

  @media screen and (max-width: 767px) {
    button {
      width: 50%;
    }
  }
`;
