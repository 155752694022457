import React, { useEffect, useState } from "react";
import { Box, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { UserAwardedBadgeType } from "src/js/types";
import { fetchPublicProfileBadgeList } from "src/js/repository/profileRepository";
import * as S from "./BadgeEarnedList.styles";
import BadgeEarned from "./BadgeEarned";

const BadgeEarnedList = ({
  userUuid,
  userFullName
}: {
  userUuid: string;
  userFullName: string;
}) => {
  const { translate } = useTranslation();
  const [badgeList, setBadgeList] = useState<UserAwardedBadgeType[]>([]);

  useEffect(() => {
    fetchPublicProfileBadgeList({
      userUuid,
      limit: 50,
      offset: 0
    }).then(data => {
      setBadgeList(data.results);
    });
  }, []);

  return badgeList.length > 0 ? (
    <Box padding={12}>
      <Text type="formTitle">
        {translate("profile_badge_earned_panel_title")}
      </Text>
      <S.BadgeEarnedList>
        {badgeList.map(badge => {
          return (
            <BadgeEarned
              key={badge.id}
              {...badge}
              userFullName={userFullName}
              loggedInUserId={userUuid}
            />
          );
        })}
      </S.BadgeEarnedList>
    </Box>
  ) : null;
};
export default BadgeEarnedList;
