import {
  Avatar,
  Button,
  Heading,
  Icon,
  Label,
  Text
} from "@arcadia/design-system";
import createReactClass from "create-react-class";
import filter from "lodash/filter";
import map from "lodash/map";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { getQueryParamFromFragment } from "src/js/modules/localization";
import createUrl from "src/js/modules/routing";
import { Translate } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import QuizCollection from "../../../collections/quizzesCollection";
import { getActiveGroupId } from "../../../modules/activeGroup";
import { getActiveUser } from "../../../modules/activeUser";
import { instants } from "../../../modules/instant";
import { __ } from "../../../modules/localization";
import { convertSecondsToFormattedString } from "../../../modules/utility";

const ExerciseEngineSummary = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          exercise: null,
          /** @type {ExerciseModel} The exercise */
          user: null,
          /** @type {UserModel} The user that completed the exercise */
          session: null,
          /** @type {Object} The execution to review */
          exenStatus: "review",
          /** @type {String} The exercise engine status. At this point it should always be review or review_pending */
          retry: null
        };
      },

      goBack() {
        instants.resetActiveInstantId();

        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          window.location.href = `#${createUrl("module_detail", {
            group_id: getActiveGroupId(),
            module_id: moduleId
          })}`;
          return;
        }

        let route = "exercises";

        if (!this.props.exercise.isPublished()) {
          route = "exercises_drafts";
        }

        window.location.href = `#${createUrl(route, { group_id: getActiveGroupId() })}`;
      },
      render() {
        const user = getActiveUser();
        const self = this;
        const { weschoolTheme } = this.props.theme;
        const exercise = self.props.exercise;
        const session = this.props.session;
        const backCopy = __("Chiudi");
        const quizzes = new QuizCollection(exercise.getQuizzes());
        const quiz_executions = session.quiz_executions;
        let quizzesResults = [];
        const backButton = (
          <div className="exercise-splash-box-footer">
            <Button
              onClick={this.goBack}
              variant="primary"
              theme={weschoolTheme.white}
            >
              {backCopy}
            </Button>
          </div>
        );

        let exec_time = session.stop_at - session.start_at;
        const timeThreshold = exercise.getTimeThreshold();
        /** If duration is longer than threshold default to threshold */
        if (timeThreshold > 0 && exec_time > timeThreshold) {
          exec_time = timeThreshold;
        }

        const executionDate = moment.unix(session.stop_at).format("DD/MM/YYYY");
        const executionTime = moment.unix(session.stop_at).format("HH:mm");

        quizzesResults = quizzes.map((quiz, index) => {
          const collection = new QuizCollection([quiz]); // creo collection così ho cast automatico al sottomodello di quiz corretto
          var quiz = collection.at(0);
          let result_color = "";
          let result_copy = "";
          const quiz_execution = filter(
            quiz_executions,
            exec => quiz.get("id") === exec.quiz.id
          );
          if (
            quiz_execution !== undefined &&
            quiz_execution[0] !== undefined &&
            quiz_execution[0].result
          ) {
            if (quiz.needsReview()) {
              result_color = "orange";
              result_copy = __("in attesa di correzione");
            } else {
              result_color = "green";
              result_copy = __("corretta");
            }
          } else if (quiz.needsReview()) {
            result_color = "orange";
            result_copy = __("in attesa di correzione");
          } else {
            result_color = "red";
            result_copy = __("errata");
          }
          return (
            <p className="flex-centered-row">
              <span className="margin-right-10">
                <Text type="table">
                  {__("quiz")} {index + 1}
                </Text>
              </span>
              <Label
                theme={weschoolTheme.white}
                color={result_color}
                outline={false}
              >
                {result_copy}
              </Label>
            </p>
          );
        });

        return this.props.exenStatus === "review_pending" ? (
          <div className="exen__splash-wrapper">
            <div className="exen__splash-box">
              <div className="app__box exercise-splash-box">
                <div>
                  <div className="alert alert-danger">
                    <h4>{__("warning_test_submit_no_internet_title")}</h4>
                    <p>{__("warning_test_submit_no_internet_text_no_save")}</p>
                    <p>{__("warning_test_submit_no_internet_text_retry")}</p>
                    <p>{__("warning_test_submit_no_internet_text_exit")}</p>
                    <p>&nbsp;</p>
                    <div className="exercise-splash-box-footer">
                      <Button
                        onClick={() => this.props.retry()}
                        variant="secondary"
                        theme={weschoolTheme.white}
                      >
                        {__("warning_test_submit_no_internet_button_retry")}
                      </Button>
                    </div>
                    <p>&nbsp;</p>
                    <div className="exercise-splash-box-footer">
                      <Button
                        onClick={this.goBack}
                        variant="secondary"
                        theme={weschoolTheme.white}
                      >
                        {__("warning_test_submit_no_internet_button_close")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="exen__splash-wrapper">
            <div className="exen__splash-box">
              <div className="app__box exercise-splash-box">
                <Heading level="5">{exercise.get("title")}</Heading>
                <div className="flex flex-centered-row margin-top-15">
                  <Avatar
                    url={user.getAvatar("medium")}
                    gender={user.getGenderString()}
                    alt=""
                    size="s"
                    theme={weschoolTheme.white}
                    rounded
                  />
                  <div className="margin-left-10">
                    <Text type="formSmallCaption">
                      <div className="exercise-splash-title-created">
                        {__("exen_executed_by", {
                          name: user.getFullName(),
                          date: executionDate,
                          time: executionTime
                        })}
                      </div>
                    </Text>
                  </div>
                </div>
                <div className="exercise-splash-info-wrapper">
                  <Row align="center">
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <div className="flex">
                        <div className="exercise-splash-icon">
                          <Icon icon="cards"></Icon>
                        </div>
                        <div className="exercise-splash-info">
                          <Text type="label">{__("quiz_count_label")}</Text>
                          <Text type="subHeaderTitle">
                            {exercise.getQuizzes().length}
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <div className="flex margin-top-xs-15">
                        <div className="exercise-splash-icon">
                          <Icon icon="clock" />
                        </div>
                        <div className="exercise-splash-info">
                          <Text type="label">
                            {__("exen_summary_time_used")}
                          </Text>
                          <Text type="subHeaderTitle">
                            {convertSecondsToFormattedString(exec_time)}
                          </Text>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="exercise-summary-quizzes clearfix">
                  {this.props.exercise.attributes.show_results ? (
                    <>
                      <div className="col-sm-6 col-xs-12">
                        {map(quizzesResults, (item, index) =>
                          index <= quizzes.length / 2 ? item : null
                        )}
                      </div>
                      <div className="col-sm-6 col-xs-12">
                        {map(quizzesResults, (item, index) =>
                          index > quizzes.length / 2 ? item : null
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="exercise-summary-quizzes__no-result">
                      <Translate text="exercise_result_wait_teacher_correction" />
                    </div>
                  )}
                </div>
                {backButton}
              </div>
            </div>
          </div>
        );
      }
    })
  )
);

export default ExerciseEngineSummary;
