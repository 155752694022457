import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Thread } from "src/js/components/Thread";
import useThreadIsTyping from "src/js/components/Thread/hooks/useThreadIsTyping";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { SpaceChatsParams, Thread as ThreadType } from "src/js/types";
import useFoundThread from "../hooks/useFoundThread";
import * as S from "./ThreadPage.styles";
import { ThreadToolbar } from "./components/ThreadToolbar";

const ThreadPage: FC = () => {
  const {
    UIStore: { isLayoutModeMobile },
    ChatStore: { threadsList },
    SpaceStore: { setActiveSpaceBySlug },
    UserStore: { activeUser }
  } = useStores();
  const { pageMinHeight } = useAppLayout();
  const { thread_id, space_slug } = useParams<SpaceChatsParams>();
  const [activeThread, setActiveThread] = useState<ThreadType>();
  const { foundThread } = useFoundThread({
    threadsList
  });

  useEffect(() => {
    if (space_slug) setActiveSpaceBySlug(space_slug);
  }, [space_slug]);

  useEffect(() => {
    setActiveThread(foundThread);
  }, [threadsList, thread_id]);

  const { isTypingString, handleIsTyping } = useThreadIsTyping({
    userId: activeUser?.uuid,
    thread: activeThread
  });

  const threadEl = (
    <>
      <ThreadToolbar
        thread={activeThread}
        usersWritingString={isTypingString}
      />
      <Thread activeThread={activeThread} handleIsTyping={handleIsTyping} />
    </>
  );

  if (isLayoutModeMobile) {
    return (
      <S.ThreadPageMobileWrapper pageMinHeight={pageMinHeight}>
        {threadEl}
      </S.ThreadPageMobileWrapper>
    );
  }

  return (
    <S.ThreadPageWrapper pageMinHeight={pageMinHeight}>
      {threadEl}
    </S.ThreadPageWrapper>
  );
};

export default observer(ThreadPage);
