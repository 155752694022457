import { CREATE_MODULE_TYPE } from "src/js/pages/boards/components/ModuleCreateButton/ModuleCreateButton.types";
import { DropdownItem } from "./ModuleCreateDropdown.types";

export const __DROPDOWN_LIST__: DropdownItem[] = [
  {
    title: "module_create_dropdown_create_title",
    type: CREATE_MODULE_TYPE.NEW,
    subtitle: "module_create_dropdown_create_desc",
    icon: "book"
  },
  {
    title: "module_create_dropdown_import_title",
    type: CREATE_MODULE_TYPE.IMPORT,
    subtitle: "module_create_dropdown_import_desc",
    icon: "copyImport"
  },
  {
    title: "module_create_dropdown_ai_title",
    type: CREATE_MODULE_TYPE.AI,
    subtitle: "module_create_dropdown_ai_desc",
    icon: "wand"
  }
];

export const dropdownAnimationVariants = {
  initial: {
    y: -20,
    opacity: 0,
    filter: "blur(12px)"
  },
  animate: {
    y: 0,
    opacity: 1,
    filter: "blur(0px)"
  },
  exit: {
    y: -20,
    opacity: 0,
    filter: "blur(12px)",
    transition: { duration: 0.1 }
  }
};
