import { useCallback, useMemo, useState } from "react";
import { Editor } from "@tiptap/core";
import { IconName } from "@arcadia/design-system";
import { TranslationKeys } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiTextEditorEvent, EventDomain } from "src/js/types";
import { TextOptions } from "@tiptap-pro/extension-ai";
import {
  AIInitialTransformations,
  AIToneTransformations,
  AITransformationValue,
  AITranslateTransformations
} from "./AITransformer.types";
import {
  __INITIAL_TRANSFORMATIONS__,
  __TONE_TRANSFORMATIONS__,
  __TRANSLATE_TRANSFORMATIONS__
} from "./AITransformer.const";

type ReturnType = {
  leadingIcon?: IconName;
  trailingIcon?: IconName;
  title: TranslationKeys;
  // subtitle?: TranslationKeys;
  action: () => void;
};

const getSelectedText = (editor: Editor): string => {
  if (!editor) return "";
  const { from, to, empty } = editor.state.selection;
  if (empty) return "";
  return editor.state.doc.textBetween(from, to, " ");
};

const config: TextOptions = {
  modelName: "gpt-4o",
  stream: true
};

export const useAITransformer = (editor: Editor) => {
  const [items, setItems] = useState<AITransformationValue[]>(
    __INITIAL_TRANSFORMATIONS__
  );
  const [isSubMenu, setIsSubMenu] = useState(false);

  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const onGoBack = useCallback(() => {
    setItems(__INITIAL_TRANSFORMATIONS__);
    setIsSubMenu(false);
  }, []);

  const aiTransformerProps = useMemo<Record<AITransformationValue, ReturnType>>(
    () => ({
      [AIInitialTransformations.AIwriter]: {
        leadingIcon: "sparkles",
        title: "ai_editor_ai_writter",
        // subtitle: "ai_editor_ai_writter_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandAiWritter,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .setAiDiscovered(true)
            .setAIPromptActive(true)
            .run();
        }
      },
      [AIInitialTransformations.CompleteSentence]: {
        leadingIcon: "pencil",
        title: "ai_editor_complete_sentence",
        // subtitle: "ai_editor_complete_sentence_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandCompleteSentence,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();

          const selectedText = editor.state.doc.textBetween(
            editor.state.selection.from,
            editor.state.selection.to
          );

          editor
            .chain()
            .insertContentAt(
              editor.state.selection.to,
              [{ type: "text", text: "   " }],
              {
                parseOptions: {
                  preserveWhitespace: true
                }
              }
            )
            .setTextSelection({
              from: editor.state.selection.to + 2,
              to: editor.state.selection.to + 3
            })
            .focus()
            .run();
          editor
            .chain()
            .focus()
            .setAiDiscovered(true)
            .aiComplete({ text: selectedText, ...config })
            .run();
        }
      },
      [AIInitialTransformations.Extend]: {
        leadingIcon: "extend",
        title: "ai_editor_extend",
        // subtitle: "ai_editor_extend_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandExtend,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor.chain().focus().setAiDiscovered(true).aiExtend(config).run();
        }
      },
      [AIInitialTransformations.Shorten]: {
        leadingIcon: "shorten",
        title: "ai_editor_shorten",
        // subtitle: "ai_editor_shorten_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandShorten,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor.chain().focus().setAiDiscovered(true).aiShorten(config).run();
        }
      },
      [AIInitialTransformations.FixSpellingAndGrammar]: {
        leadingIcon: "editSecondary",
        title: "ai_editor_fix_spelling_and_grammar",
        // subtitle: "ai_editor_fix_spelling_and_grammar_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandFixSpellingAndGrammar,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .setAiDiscovered(true)
            .aiFixSpellingAndGrammar(config)
            .run();
        }
      },
      [AIInitialTransformations.Rephrase]: {
        leadingIcon: "undo",
        title: "ai_editor_rephrase",
        // subtitle: "ai_editor_rephrase_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandRephrase,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor.chain().focus().setAiDiscovered(true).aiRephrase(config).run();
        }
      },
      [AIInitialTransformations.Simplify]: {
        leadingIcon: "simplify",
        title: "ai_editor_simplify",
        // subtitle: "ai_editor_simplify_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandSimplify,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor.chain().focus().setAiDiscovered(true).aiSimplify(config).run();
        }
      },
      [AIInitialTransformations.Summarise]: {
        leadingIcon: "summarise",
        title: "ai_editor_summarise",
        // subtitle: "ai_editor_summarise_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandSummarise,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .setAiDiscovered(true)
            .aiSummarize(config)
            .run();
        }
      },
      [AIInitialTransformations.Emojify]: {
        leadingIcon: "emojify",
        title: "ai_editor_emojify",
        // subtitle: "ai_editor_emojify_description",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandEmojify,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor.chain().focus().setAiDiscovered(true).aiEmojify(config).run();
        }
      },
      [AIInitialTransformations.ChangeTone]: {
        leadingIcon: "microphoneEmpty",
        title: "ai_editor_change_tone",
        trailingIcon: "chevronRight",
        // subtitle: "ai_editor_change_tone_description",
        action: () => {
          setIsSubMenu(true);
          setItems(__TONE_TRANSFORMATIONS__);
        }
      },
      [AIInitialTransformations.Translate]: {
        leadingIcon: "globe",
        title: "ai_editor_translate",
        trailingIcon: "chevronRight",
        // subtitle: "ai_editor_translate_description",
        action: () => {
          setIsSubMenu(true);
          setItems(__TRANSLATE_TRANSFORMATIONS__);
        }
      },
      [AIToneTransformations.Default]: {
        title: "ai_editor_tone_default",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneDefault,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Default, config)
            .run();
        }
      },
      [AIToneTransformations.Academic]: {
        title: "ai_editor_tone_academic",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneAcademic,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Academic, config)
            .run();
        }
      },
      [AIToneTransformations.Business]: {
        title: "ai_editor_tone_business",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneBusiness,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Business, config)
            .run();
        }
      },
      [AIToneTransformations.Casual]: {
        title: "ai_editor_tone_casual",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneCasual,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Casual, config)
            .run();
        }
      },
      [AIToneTransformations.Childfriendly]: {
        title: "ai_editor_tone_child_friendly",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneChildFriendly,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Childfriendly, config)
            .run();
        }
      },
      [AIToneTransformations.Confident]: {
        title: "ai_editor_tone_confident",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneConfident,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Confident, config)
            .run();
        }
      },
      [AIToneTransformations.Conversational]: {
        title: "ai_editor_tone_conversational",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneConversational,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Conversational, config)
            .run();
        }
      },
      [AIToneTransformations.Creative]: {
        title: "ai_editor_tone_creative",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneCreative,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Creative, config)
            .run();
        }
      },
      [AIToneTransformations.Emotional]: {
        title: "ai_editor_tone_emotional",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneEmotional,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Emotional, config)
            .run();
        }
      },
      [AIToneTransformations.Excited]: {
        title: "ai_editor_tone_excited",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneExcited,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Excited, config)
            .run();
        }
      },
      [AIToneTransformations.Formal]: {
        title: "ai_editor_tone_formal",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneFormal,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Formal, config)
            .run();
        }
      },
      [AIToneTransformations.Friendly]: {
        title: "ai_editor_tone_friendly",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneFriendly,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Friendly, config)
            .run();
        }
      },
      [AIToneTransformations.Funny]: {
        title: "ai_editor_tone_funny",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneFunny,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Funny, config)
            .run();
        }
      },
      [AIToneTransformations.Humorous]: {
        title: "ai_editor_tone_humorous",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneHumorous,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Humorous, config)
            .run();
        }
      },
      [AIToneTransformations.Informative]: {
        title: "ai_editor_tone_informative",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneInformative,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Informative, config)
            .run();
        }
      },
      [AIToneTransformations.Inspirational]: {
        title: "ai_editor_tone_inspirational",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneInspirational,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Inspirational, config)
            .run();
        }
      },
      [AIToneTransformations.Memeify]: {
        title: "ai_editor_tone_memeify",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneMemeify,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Memeify, config)
            .run();
        }
      },
      [AIToneTransformations.Narrative]: {
        title: "ai_editor_tone_narrative",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneNarrative,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Narrative, config)
            .run();
        }
      },
      [AIToneTransformations.Objective]: {
        title: "ai_editor_tone_objective",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandToneObjective,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Objective, config)
            .run();
        }
      },
      [AIToneTransformations.Persuasive]: {
        title: "ai_editor_tone_persuasive",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandTonePersuasive,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Persuasive, config)
            .run();
        }
      },
      [AIToneTransformations.Poetic]: {
        title: "ai_editor_tone_poetic",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandTonePoetic,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiAdjustTone(AIToneTransformations.Poetic, config)
            .run();
        }
      },
      [AITranslateTransformations.English]: {
        title: "ai_editor_translation_english",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandTranslateEnglish,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiTranslate(AITranslateTransformations.English, config)
            .run();
        }
      },
      [AITranslateTransformations.Italian]: {
        title: "ai_editor_translation_italian",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandTranslateItalian,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiTranslate(AITranslateTransformations.Italian, config)
            .run();
        }
      },
      [AITranslateTransformations.French]: {
        title: "ai_editor_translation_french",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandTranslateFrench,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiTranslate(AITranslateTransformations.French, config)
            .run();
        }
      },
      [AITranslateTransformations.Spanish]: {
        title: "ai_editor_translation_spanish",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.CommandTranslateSpanish,
            getSelectedText(editor)
          );
          if (isLayoutModeMobile) closeModal();
          editor
            .chain()
            .focus()
            .aiTranslate(AITranslateTransformations.Spanish, config)
            .run();
        }
      }
    }),
    [editor]
  );
  return { items, aiTransformerProps, onGoBack, isSubMenu };
};
