import { VariantType, scrollbarMixin, shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  box-shadow: ${shadow[500]};
  padding: 8px 12px;
  border-radius: 8px;
`;

export const Button = styled.button<{ active?: boolean }>`
  border: none;
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[900] : "transparent"};
  color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[300] : theme.colors.blueGrey[500]};
  transition: 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
    color: ${({ theme }) => theme.colors.blueGrey[300]};
  }

  // Fix safari flex
  > svg {
    flex: 0 0 16px;
  }
`;

export const TextButton = styled.button<{ active?: boolean }>`
  border: none;
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
  padding: 0px 4px;
  height: 28px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[900] : "transparent"};
  color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[300] : theme.colors.blueGrey[500]};
  transition: 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
    color: ${({ theme }) => theme.colors.blueGrey[300]};
  }
`;

export const Dropdown = styled(motion.div)`
  left: 0px;
  top: calc(100% + 8px);
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  box-shadow: ${shadow[500]};
  max-height: 220px;
  overflow: auto;

  ${scrollbarMixin(VariantType.White)}
`;

export const DropdownItem = styled.button`
  all: unset;
  cursor: pointer;
  padding: 4px;
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  width: calc(100% - 8px);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.coldGrey[500]};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span[type="formTitle"] {
    color: ${({ theme }) => theme.colors.blueGrey[300]};
  }
  span[type="formSmallCaption"] {
    color: ${({ theme }) => theme.colors.blueGrey[500]};
  }
`;

export const Divider = styled.div`
  height: 20px;
  width: 1px;
  margin: 0px 4px;
  background-color: ${({ theme }) => theme.colors.blueGrey[800]};
`;
