import { differenceInDays, format, isValid, parse } from "date-fns";
import { useMemo } from "react";
import { useQuery } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import {
  AnalyticsFilter,
  DashboardAggregation,
  DashboardAggregationType,
  DashboardRangeTime,
  DashboardRangeTimeType,
  DashboardXAxisFrequencyType
} from "src/js/types";
import { RangeTimeOptions } from "../Analytics.const";
import { generateFromToDate, getXAxisFrequency } from "../Analytics.utils";

const useAnalyticsFilter = () => {
  const { translate } = useTranslation();
  const { queryParams, setQueryParams } = useQuery<AnalyticsFilter>();

  const queryParamsParsed = useMemo(() => {
    const from = queryParams?.from
      ? parse(queryParams.from, "yyyy-MM-dd", new Date())
      : undefined;
    const to = queryParams?.to
      ? parse(queryParams.to, "yyyy-MM-dd", new Date())
      : undefined;

    return {
      groupIds: queryParams?.groups,
      range: queryParams?.range || DashboardRangeTime.Last7Days,
      interval:
        isValid(from) && isValid(to)
          ? {
              to,
              from
            }
          : undefined
    };
  }, [queryParams]);

  const intervalDate =
    queryParamsParsed?.interval ||
    (queryParamsParsed?.range && generateFromToDate(queryParamsParsed?.range));

  const daysDiff = useMemo(() => {
    const { from, to } = intervalDate;
    return differenceInDays(to, from);
  }, [queryParams]);

  const aggregation: DashboardAggregationType = useMemo(() => {
    if (daysDiff) {
      if (daysDiff >= 180) {
        return DashboardAggregation.Week;
      }
    }
    return DashboardAggregation.Day;
  }, [queryParams]);

  const xAxisFrequency: DashboardXAxisFrequencyType = useMemo(
    () => getXAxisFrequency(daysDiff),
    [queryParams]
  );

  const dateLabel: string = useMemo(() => {
    if (queryParamsParsed?.interval) {
      const { from, to } = intervalDate;
      if (from && to) {
        const fromDate = from ? from.toLocaleDateString() : "";
        const toDate = to ? to.toLocaleDateString() : "";
        return `${fromDate}-${toDate}`;
      }
    }
    const selectedOption = RangeTimeOptions.find(
      option => option.key === queryParamsParsed?.range
    )?.value;
    return translate(selectedOption);
  }, [queryParams]);

  const setGroupIds = (groups: string[]) => {
    setQueryParams({
      ...queryParams,
      groups: groups?.length > 0 ? groups : undefined
    });
  };

  const setRangeDate = (range: DashboardRangeTimeType) => {
    setQueryParams({ ...queryParams, range, from: undefined, to: undefined });
  };

  const setIntervalDate = (fromInterval: Date, toInterval: Date) => {
    setQueryParams({
      ...queryParams,
      from: fromInterval
        ? format(new Date(fromInterval), "yyyy-MM-dd")
        : undefined,
      to: toInterval ? format(new Date(toInterval), "yyyy-MM-dd") : undefined,
      range: undefined
    });
  };

  return {
    groupIds: queryParamsParsed?.groupIds,
    setGroupIds,
    rangeDate: queryParamsParsed?.range,
    setRangeDate,
    intervalDate,
    setIntervalDate,
    aggregation,
    xAxisFrequency,
    dateLabel,
    fromDate: format(intervalDate?.from, "yyyy-MM-dd"),
    toDate: format(intervalDate?.to, "yyyy-MM-dd")
  };
};

export default useAnalyticsFilter;
