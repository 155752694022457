import React, { useState, FC } from "react";
import {
  AvatarWithInitials,
  Box,
  Button,
  Heading,
  Icon,
  Input,
  Text
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import { useTranslation } from "src/js/translation";
import { createUrl } from "src/js/modules/routing";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import AppStoreSVG from "src/img/icons/svg/badge_appstore.svg";
import GooglePlaySVG from "src/img/icons/svg/badge_googleplay.svg";
import AppGallerySVG from "src/img/icons/svg/badge_appgallery.svg";

import { navigateTo } from "src/legacy/modules/history";
import { LoginViewProps } from "src/js/pages/unlogged/LoginPage/LoginPage.types";
import { __FORM_LOGIN__ } from "src/js/pages/unlogged/LoginPage/LoginPage.utils";
import * as Styled from "src/js/pages/unlogged/LoginPage/LoginPage.styles";
import { SocialLoginOptions } from "src/js/components/SocialLoginOptions";
import { useStores } from "src/js/hooks";
import { LoginWithSSO } from "../LoginWithSSO";
import * as S from "./LoginMobileView.styles";
import { LoginErrors } from "../LoginErrors";

const LoginMobileView: FC<LoginViewProps> = ({
  form,
  onLogin,
  shouldDisplayPasswordField,
  ssoData,
  loginError,
  inviteToken
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    ThemeStore: { customizationSettings, hasCustomization }
  } = useStores();
  const { register, handleSubmit, watch, formState, errors } = form;
  const isSSOAccount = ssoData && ssoData.provider;
  const passwordInput = watch("password");

  return (
    <S.ScreenWrapper hasCustomizedLogin={hasCustomization}>
      {customizationSettings?.whiteLabelLogo ? (
        <Box marginBottom="16px">
          <AvatarWithInitials
            theme={whiteTheme}
            alt={customizationSettings?.whiteLabelName}
            url={customizationSettings?.whiteLabelLogo?.originalUrl}
            name={customizationSettings?.whiteLabelName}
            avatarColor={0}
            size="medium"
          />
        </Box>
      ) : null}
      <Box
        display="flex"
        alignItems="baseline"
        justifyContent="center"
        textAlign="center"
      >
        {customizationSettings?.whiteLabelName ? (
          <Heading level="2">
            {`${translate("login_on_heading")} `}
            <S.InviteSpaceName>
              {customizationSettings?.whiteLabelName}
            </S.InviteSpaceName>
          </Heading>
        ) : (
          <S.LogoWrapper>
            <WeSchoolLogoSVG />
          </S.LogoWrapper>
        )}
      </Box>
      <Box>
        {isSSOAccount ? (
          <LoginWithSSO ssoData={ssoData} />
        ) : (
          <>
            <Box marginBottom={8}>
              <LoginErrors loginError={loginError} inputErrors={errors} />
            </Box>
            <S.Form onSubmit={handleSubmit(onLogin)}>
              <Box width="100%">
                <S.CustomInput
                  hasError={!!errors.username}
                  theme={whiteTheme}
                  label=""
                  hideLabel
                  name="username"
                  placeholder={translate("login_username_placeholder")}
                  ref={register(__FORM_LOGIN__.username)}
                  rounded
                />
              </Box>
              {shouldDisplayPasswordField && (
                <Box marginTop={4} width="100%">
                  <Input
                    theme={whiteTheme}
                    label=""
                    hideLabel
                    name="password"
                    placeholder={translate("login_password_placeholder")}
                    ref={register(__FORM_LOGIN__.password)}
                    rounded
                    isPassword={!passwordVisible}
                    rightSection={
                      <S.IconWrapper
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        <Icon
                          icon={passwordVisible ? "eyeClosed" : "eyeOpen"}
                        />
                      </S.IconWrapper>
                    }
                  />
                </Box>
              )}

              <Box marginTop={12} width="100%">
                <Button
                  disabled={
                    !passwordInput || (passwordInput && !formState.isValid)
                  }
                  variant="primary"
                  theme={whiteTheme}
                  fullWidth
                  type="submit"
                >
                  {translate("login")}
                </Button>
              </Box>
            </S.Form>
            <Box
              textAlign="center"
              onClick={() => navigateTo(createUrl("requestPassword"))}
            >
              <Styled.Link type="textLink">
                {translate("login_reset_password_label")}
              </Styled.Link>
            </Box>
            <Box marginBottom={32}>
              <SocialLoginOptions inviteToken={inviteToken} />
            </Box>
          </>
        )}
        <S.RegisterWrapper>
          <Text type="formSmallSubtitle">
            {translate("login_page_link_register")}
          </Text>
          <Box onClick={() => navigateTo(createUrl("register"))} marginLeft={8}>
            <Styled.Link type="textLink">
              {translate("registration_submit_button_label")}
            </Styled.Link>
          </Box>
        </S.RegisterWrapper>
        <UnloggedSupportLink />
        <Box display="flex" justifyContent="space-between" marginTop={24}>
          <a
            href="https://apps.apple.com/it/app/weschool/id1451504331"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Download WeSchool on the App Store"
          >
            <AppStoreSVG />
          </a>
          <a
            href="http://play.google.com/store/apps/details?id=com.weschool.androidapp"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Download WeSchool on Google Play"
          >
            <GooglePlaySVG />
          </a>
          <a
            href="https://appgallery.cloud.huawei.com/ag/n/app/C102291829?channelId=Homepage+App&id=af3df68e485444228064b84be544f789&s=DE22B1DF363EAD35F7649D2A390760DCBAD86C3D2B4D83511392FCDC7C296E58&detailType=0&v="
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Download WeSchool on AppGallery"
          >
            <AppGallerySVG />
          </a>
        </Box>
      </Box>
    </S.ScreenWrapper>
  );
};

export default LoginMobileView;
