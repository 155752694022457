import "Legacy/events/internalEvents";
import "Legacy/events/notificationEvents";
import "Legacy/events/realtimeEvents";
import React from "react";
import { render } from "react-dom";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "src/commonImports";
import "katex/dist/katex.min.css";
import "src/css/main.scss";
import { initLayout } from "src/js/modules/layoutFunction";
import sentry from "src/js/modules/sentry";
import "src/js/modules/zendesk";
import { __ONEDRIVE_CLIENT_ID__ } from "src/js/settings/settingsGeneral";
import App from "./js/App";
import { initEmoji } from "./js/init/initEmoji";
import { __GTM_ID__ } from "./js/settings/settingsApp";

// import "airbnb-browser-shims"; //https://www.npmjs.com/package/airbnb-browser-shims

const tagManagerArgs = {
  gtmId: __GTM_ID__
};

TagManager.initialize(tagManagerArgs);

const { __APP_VER__, __SENTRY__ } = process.env;

if (__SENTRY__ === "true") {
  sentry.init();
}

const head = document.getElementsByTagName("head")[0];
const script = document.createElement("script");
script.id = "onedrive-js";
script.type = "text/javascript";
script.src = "https://js.live.net/v5.0/OneDrive.js";
script.setAttribute("client-id", __ONEDRIVE_CLIENT_ID__);
head.appendChild(script);

function startApp() {
  initLayout();
  initEmoji();

  console.log(`WeSchool ${__APP_VER__}`);

  render(<App />, document.getElementById("page"));
}

startApp();
