/* eslint-disable no-useless-escape */

import { ValidationOptions } from "react-hook-form";
import { TranslationKeys } from "src/js/translation";
import { FieldType } from "./types";

const getFieldValidationRules = (key: TranslationKeys): ValidationOptions => ({
  required: key,
  validate: input => !!input.trim() || key
});

export const badgeFields: FieldType[] = [
  {
    name: "name",
    type: "field",
    label: "badge_field_name_title",
    placeholder: "badge_name_placeholder",
    maxLength: 64,
    validation: getFieldValidationRules("badge_name_required_validation_error")
  },
  {
    name: "description",
    type: "textarea",
    rows: 4,
    maxLength: 256,
    label: "badge_field_description_title",
    placeholder: "badge_description_placeholder",
    validation: getFieldValidationRules(
      "badge_description_required_validation_error"
    )
  },
  {
    name: "criteria",
    type: "textarea",
    rows: 4,
    maxLength: 256,
    label: "badge_field_criteria_title",
    placeholder: "badge_criteria_placeholder",
    validation: getFieldValidationRules(
      "badge_criteria_required_validation_error"
    )
  },
  {
    name: "issuer",
    type: "field",
    label: "badge_field_issuer_title",
    placeholder: "badge_issuer_placeholder",
    maxLength: 64,
    validation: getFieldValidationRules(
      "badge_issuer_required_validation_error"
    )
  }
];
