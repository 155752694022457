import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const LinkBtn = styled.button`
  margin-left: 4px;
  background: none;
  border: none;
  text-decoration-line: underline;
  :hover {
    color: ${({ theme }) => theme.colors.grey[50]};
  }
`;

export const AccountContainer = styled.div`
  margin-top: 24px;
`;

export const AccountInputWrapper = styled.div`
  margin-bottom: 16px;
`;

export const LanguageLabel = styled.label`
  margin-bottom: 13px;
  font-weight: 400;
`;

export const AccountButtonWrapper = styled.div`
  margin-bottom: 8px;
  width: max-content;
  margin-left: auto;
`;

export const FullWidthContextualAlertWrapper = styled.div`
  margin-bottom: 12px;
  > div {
    max-width: 100%;
  }
`;

export const PendingEmailButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

export const PrivacyPolicyText = styled(Text)`
  margin-bottom: 20px;
  width: max-content;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.violet[500]};
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const SaveButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const LogoutButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
`;

export const DeleteAccountButtonWrapper = styled.div`
  margin-top: 16px;
`;
