import { Heading } from "@arcadia/design-system";
import styled from "styled-components";

export const SpaceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 24px;
  ul {
    justify-content: center;
  }
`;

export const MobileUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const UserSettingsSectionTitle = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;

  @media screen and (max-width: 767px) {
    margin-top: 28px;
    margin-bottom: 20px;
  }
`;

export const StyledHeading = styled(Heading)`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const UserSettingsSearch = styled.div`
  display: flex;
  & > div {
    margin-right: 5px;
    min-width: 270px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 8px;
    & > div {
      min-width: auto;
    }
  }
`;
