import React from "react";
import HeaderClean from "src/js/components/layout/HeaderClean/HeaderClean";

const EmptyLayout = props => {
  const { pageTitle, children } = props;
  return (
    <>
      <HeaderClean pageTitle={pageTitle} />
      <div className="page__container">{children}</div>
    </>
  );
};

export default EmptyLayout;
