import { makeAutoObservable } from "mobx";
import { fetchBoardResourcesScore } from "src/js/repository/gamificationRepository";

/**
 * @name GamificationStore
 *
 * @description
 * Store for get gamification information
 */
class GamificationStore {
  $boardId = null;

  $boardResourcesScore = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get resourcesScore() {
    return this.$boardResourcesScore;
  }

  fetchBoardResourcesScoreById(boardId) {
    if (boardId !== this.$boardId) {
      this.setBoardId(boardId);
      fetchBoardResourcesScore({ boardIds: [boardId] }).then(res => {
        this.setResourcesScore(res);
      });
    }
  }

  setBoardId = id => {
    this.$boardId = id;
  };

  setResourcesScore = score => {
    this.$boardResourcesScore = score;
  };

  reset = () => {
    this.$boardId = [];
    this.$boardResourcesScore = [];
  };
}

export default GamificationStore;
