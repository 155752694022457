import React, { useRef } from "react";
import { useOnClickOutSide, useToggle } from "src/js/hooks";
import { AnalyticsCalendar } from "src/js/components/AnalyticsCalendar";
import { Translator } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { Icon, Dropdown } from "@arcadia/design-system";
import {
  __ACCURACY_OPTIONS__,
  __INTERVAL_OPTIONS__,
  __TOPIC_OPTIONS__,
  POSTS_AND_COMMENTS_TOPIC
} from "../AnalyticsDashboard.const";
import { AnalyticsTrendGraph } from "./AnalyticsTrendGraph";
import { elaborateInterval } from "./elaborateInterval";

import * as S from "./AnalyticsSectionTrend.styles";

const getCurrentOptionValue = (options, key) => {
  const current = options.find(opt => opt.key === key);

  return current.value;
};

const AnalyticsSectionTrend = ({
  data = {},
  topic = POSTS_AND_COMMENTS_TOPIC,
  setTopic = () => {},
  periodAccuracy,
  setPeriodAccuracy = () => {},
  dateRange = { from_date: null, to_date: null },
  setDateRange = () => {},
  datePlaceholder = "last_month_graph_interval",
  setDatePlaceholder = () => {}
}) => {
  const { from_date, to_date } = dateRange;
  const [calendarOpen, setCalendarOpen] = useToggle(false);

  const analyticsCalendar = useRef();
  useOnClickOutSide(analyticsCalendar, () => setCalendarOpen(false));
  const { whiteTheme } = useTheme();
  return (
    <Translator>
      {({ translate }) => (
        <S.TrendContainer>
          <S.FiltersContainer>
            <Dropdown
              id="analytics-topic-dropdown"
              theme={whiteTheme}
              optionsList={__TOPIC_OPTIONS__.map(({ key, value }) => ({
                id: key,
                label: translate(value)
              }))}
              selectedOptionId={topic}
              setSelectedOptionId={id => {
                setTopic(id);
              }}
              width="232px"
              placeholder={translate(topic)}
            />
            <S.TimeFilterContainer>
              <S.CalendarDropdownContainer>
                <Dropdown
                  id="analytics-interval-dropdown"
                  theme={whiteTheme}
                  optionsList={__INTERVAL_OPTIONS__.map(({ key, value }) => ({
                    id: key,
                    label: translate(value)
                  }))}
                  selectedOptionId={datePlaceholder}
                  setSelectedOptionId={id => {
                    elaborateInterval({
                      value: id,
                      setDatePlaceholder,
                      setDateRange
                    });
                  }}
                  placeholder={translate(datePlaceholder)}
                />

                <S.AnalyticsIconAndCalendarContainer ref={analyticsCalendar}>
                  <S.CalendarIconContainer
                    onClick={() => setCalendarOpen(!calendarOpen)}
                  >
                    <Icon icon="calendar" width={16} height={16} />
                  </S.CalendarIconContainer>
                  {calendarOpen ? (
                    <S.AnalyticsCalendarContainer>
                      <AnalyticsCalendar
                        startDate={from_date}
                        endDate={to_date}
                        onChange={dates =>
                          elaborateInterval({
                            value: "custom",
                            start: dates[0],
                            end: dates[1],
                            setDatePlaceholder,
                            setDateRange
                          })
                        }
                      />
                    </S.AnalyticsCalendarContainer>
                  ) : null}
                </S.AnalyticsIconAndCalendarContainer>
              </S.CalendarDropdownContainer>

              <S.SpecificityFilterContainer>
                <Dropdown
                  id="analytics-accuracy-dropdown"
                  theme={whiteTheme}
                  optionsList={__ACCURACY_OPTIONS__.map(({ key, value }) => ({
                    id: key,
                    label: translate(value)
                  }))}
                  selectedOptionId={periodAccuracy}
                  setSelectedOptionId={id => {
                    setPeriodAccuracy(id);
                  }}
                  placeholder={translate(periodAccuracy)}
                />
              </S.SpecificityFilterContainer>
            </S.TimeFilterContainer>
          </S.FiltersContainer>
          <S.GraphContainer>
            <AnalyticsTrendGraph
              data={data}
              topic={getCurrentOptionValue(__TOPIC_OPTIONS__, topic)}
              xLabel="time"
              yLabel="value"
            />
          </S.GraphContainer>
        </S.TrendContainer>
      )}
    </Translator>
  );
};

export default AnalyticsSectionTrend;
