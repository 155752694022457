import React, { useEffect, useMemo, useState } from "react";
import ListSelector from "src/js/components/ListSelector";
import { useArrayState } from "src/js/hooks";
import { filterBoards } from "src/js/repository/shareRepository";
import { __SEARCH_BOARD_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { Translate } from "src/js/translation/TranslationProvider";

export const BoardItem = ({ group_name, name }) => {
  return (
    <>
      {group_name} | <i>{name}</i>
    </>
  );
};

const BoardSelector = ({
  groupId = null,
  filterString = "",
  doSelectBoard = () => {}
}) => {
  const [boardsList, addBoards, resetBoards] = useArrayState([]);
  const [isLoadingBoards, setIsLoadingBoards] = useState(true);
  const [hasNext, setHasNext] = useState(false);
  const [paginationStart, setPaginationStart] = useState(0);

  useEffect(() => {
    setIsLoadingBoards(true);
    filterBoards(filterString, 0, __SEARCH_BOARD_LIST_LIMIT__, groupId)
      .then(({ boards, has_next }) => {
        addBoards(boards);
        setIsLoadingBoards(false);
        setHasNext(has_next);
      })
      .catch(() => {
        resetBoards([]);
        setIsLoadingBoards(false);
      });

    return () => {
      resetBoards([]);
      setPaginationStart(0);
    };
  }, [filterString, groupId]);

  const showNext = () => {
    setIsLoadingBoards(true);
    filterBoards(
      filterString,
      paginationStart + __SEARCH_BOARD_LIST_LIMIT__,
      __SEARCH_BOARD_LIST_LIMIT__,
      groupId
    )
      .then(({ boards, has_next }) => {
        setPaginationStart(paginationStart + __SEARCH_BOARD_LIST_LIMIT__);
        addBoards(boards);
        setIsLoadingBoards(false);
        setHasNext(has_next);
      })
      .catch(() => {
        setIsLoadingBoards(false);
      });
  };

  const itemList = useMemo(
    () =>
      boardsList.map(({ group_name, name, id }) => {
        return { id, name: <BoardItem group_name={group_name} name={name} /> };
      }),
    [boardsList]
  );
  return (
    <ListSelector
      itemList={itemList}
      doSelect={id => {
        doSelectBoard(
          boardsList.find(item => {
            return item.id === id;
          })
        );
      }}
      isLoading={isLoadingBoards}
      title="share_on"
      hasNext={hasNext}
      showNext={showNext}
      noItemMessage={<Translate text="no_boards_available" />}
    />
  );
};

export default BoardSelector;
