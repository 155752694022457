import { LinkButton } from "@arcadia/design-system";
import { SisOnboarding } from "src/js/types/models/MarketingData";
import styled, { css } from "styled-components";
import { SWithMobileProps } from "./SpaceCreationForm.types";

export const SpaceCreationFormContainer = styled.form<SisOnboarding>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey[50]};
  ${({ isOnboarding }) =>
    isOnboarding &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      border-radius: 12px;
      width: 100%;
    `}
`;

export const Spacer = styled.div`
  padding-bottom: 20px;
`;

export const SchoolSpaceRedeemContainer = styled.div`
  width: 100%;
  margin: 0 20px;
  padding-top: 12px;
  text-align: center;
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey[900]};
    padding-top: 12px;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  display: inline-block;
  color: ${({ theme }) => theme.primaryColor[500]};
  & span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
  }
`;

export const SpaceInfoCardContainer = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  min-height: 68px;

  label {
    margin-bottom: 0px;
  }
`;

export const InputSubmitWrapper = styled.div<SWithMobileProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px 8px 0;
  z-index: 1;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: auto;
    `}
`;

export const DropdownLabel = styled.div`
  display: flex;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const SpaceBadgeImageContainer = styled.div`
  margin-bottom: 12px;
  & span:nth-child(2) {
    font-size: 14px;
  }
  & div:first-child {
    & div:nth-child(2) {
      & div {
        & div {
          padding: 0 20px 0 84px;
        }
      }
    }
  }
`;

export const HiddenInputWrapper = styled.div`
  display: none;
`;

export const AvatarWrapper = styled.div`
  display: block;
  width: max-content;
  margin: 0 auto;
  position: absolute;
  top: 48px;
  left: 22px;
`;

export const AvatarWithInitialsWrapper = styled.div`
  zoom: 125%;
`;

export const Message = styled.div`
  margin-top: 8px;
  margin-left: 12px;
  display: flex;

  svg {
    color: ${({ theme }) => theme.colors.error[700]};
  }

  span {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.error[700]};
  }
`;
