import React from "react";
import { Icon } from "@arcadia/design-system";
import * as S from "./Pill.styles";

const Pill = ({
  id,
  value,
  color,
  isDeletable = true,
  deleteFunction = () => {}
}) => {
  return (
    <S.PillContainer backgroundColor={color}>
      {value}
      {isDeletable && (
        <S.IconContainer onClick={() => deleteFunction(id)}>
          <Icon icon="chevronRight" width={14} height={14} />
        </S.IconContainer>
      )}
    </S.PillContainer>
  );
};

export default Pill;
