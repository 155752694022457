import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ExampleCarouselThemeVariant } from "./ExampleCarousel.types";

export const Wrapper = styled(motion.div)<{
  variant?: ExampleCarouselThemeVariant;
}>`
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.grey[1000] : theme.primaryColor[900]};
  padding: 16px;
  overflow: hidden;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.coldGrey[500]};
`;

export const Example = styled(Text)`
  color: ${({ theme }) => theme.colors.coldGrey[600]};
  user-select: none;
`;

export const SwipeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0px;
`;

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DotWrapper = styled.div`
  width: max-content;
  display: flex;
  gap: 8px;
`;

export const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor[500] : theme.colors.coldGrey[800]};
  transition: background-color 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  cursor: pointer;
`;

export const Button = styled(motion.button)`
  width: max-content;
  background: none;
  border: none;
  outline: none;
  padding: 12px 24px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.primaryColor[500]};
  transition: box-shadow 300ms ease-in-out;

  &:focus,
  &:active {
    box-shadow: ${({ theme }) => theme.primaryColor[700]} 0 0px 0px 3px;
  }

  @media screen and (max-width: 767px) {
    color: ${({ theme }) => theme.colors.grey[1000]};
    background-color: ${({ theme }) => theme.colors.coldGrey[500]};
    &:focus,
    &:active {
      box-shadow: ${({ theme }) => theme.colors.coldGrey[800]} 0 0px 0px 3px;
    }
  }
`;
