import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const SmallCaptionText = styled(Text)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.blueGrey[500]};
`;

export const StyledLinkBtn = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};
  &:hover {
    text-decoration: underline;
  }
`;

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.primaryColor[500]};
  font-weight: 500;
`;

export const ToggleContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 82px;
  @media screen and (max-width: 767px) {
    gap: 12px;
  }
`;

export const NumberInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 82px;
  & input {
    text-align: right;
    margin-top: -8px;
  }
`;
