export const __NOTIFICATION_HEADER_LENGHT__ = 210;

export const __PROFILE_STATUS_LENGTH__ = 250;

export const __BOARD_TITLE_MAX_LENGTH__ = 80;
export const __BOARD_TITLE_LENGHT__ = {
  desktop: 190,
  tablet: 50,
  smartphone: 80
};
export const __BOARD_ELEMENT_TITLE_MAX_LENGTH__ = 255;

export const __QUIZ_DESCRIPTION_MAX_LENGTH__ = 255;
export const __QUIZ_ANSWER_TITLE_MAX_LENGTH__ = 2500;
export const __QUIZ_ANSWER_DESCRIPTION_MAX_LENGTH__ = 400;
export const __QUIZ_CORRECTION_MAX_LENGHT__ = 1500;
export const __QUIZ_MATCHING_LENGHT__ = {
  desktop: 100,
  tablet: 60,
  smartphone: 15
};

export const __REPORT_ABUSE_LENGTH__ = {
  desktop: 140,
  tablet: 60,
  smartphone: 40
};

export const __RESOURCE_TITLE_LENGTH__ = {
  desktop: 30,
  tablet: 30,
  smartphone: 20
};

export const __SEARCH_KEYWORD_LENGTH__ = 3;
