import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { checkAlias, saveAlias } from "src/js/repository/groupRepository";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { useEffect } from "react";

export const useLinkInvite = (
  isDisabled: boolean,
  setDisabled: (val: boolean) => void
) => {
  const { translate } = useTranslation();
  const {
    GroupStore: {
      activeGroup: { id },
      updatePublicInvitation,
      fetchPublicInvitation,
      updateGroupAlias
    }
  } = useStores();

  useEffect(() => {
    fetchPublicInvitation(id);
  }, []);

  const handleGroupAlias = ({
    groupAlias,
    setAlreadyTaken
  }: {
    groupAlias: string;
    setAlreadyTaken: (value: boolean) => void;
  }) => {
    checkAlias(id, groupAlias)
      .then(({ availability }) => {
        if (availability) {
          saveAlias(id, groupAlias)
            .then((response: { alias: string }) => {
              updateGroupAlias(response.alias);
              setDisabled(true);
              showToastSuccess({
                str: translate("group_alias_edit_success")
              });
            })
            .catch(error => {
              showToastError({ str: error.message });
            });
        } else {
          setAlreadyTaken(true);
        }
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const togglePublicInvite = () => {
    updatePublicInvitation(id)
      .then(({ is_public }) => {
        setDisabled(is_public || isDisabled);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  return { handleGroupAlias, togglePublicInvite };
};
