import { __ } from "../../modules/localization";
import each from "lodash/each";
import reject from "lodash/reject";
import QuizModel from "./quizModel";

class QuizFillblanksModel extends QuizModel {
  /** This quiz has no title. Return the instructions instead */
  getTitle() {
    return __("quiz_fillblanks_execution_label");
  }
  getUserAnswer() {
    return this.get("userAnswer") || {};
  }
  checkUserAnswer(success_threshold) {
    const answers = this.getUserAnswer();
    const solutions = this.getSolutions();
    const questions = this.getQuestions();
    let ret = true;
    let correct = 0;
    const wrong = 0;

    each(solutions, (val, key) => {
      if (
        answers[val.index] !== undefined &&
        answers[val.index].trim().toLowerCase() ===
          val.value.trim().toLowerCase()
      )
        correct++;
    });

    if (success_threshold === undefined)
      success_threshold = this.getDefaultThreshold(); // se non è specificata percentuale, metto default

    const perc = (correct / solutions.length) * 100;

    ret = perc >= success_threshold;

    return ret;
  }
  getFormattedUserAnswer() {
    const answers = this.getUserAnswer();
    const formattedObj = [];
    for (let key in answers) {
      formattedObj.push({
        index: parseInt(key, 10),
        value: answers[key]
      });
    }
    return formattedObj;
  }
  isFullyAnswered() {
    const answers = this.getFormattedUserAnswer();
    const questions = this.getQuestions();
    const given_answers = reject(
      answers,
      answer =>
        /* exclude empty answers from count */
        answer.value.length === 0
    );

    /**
     * We should not use the quiz solutions object
     * In theory we should not even have it during the execution.
     * This is just to avoid having old fillblanks counting new line as a question
     * Just in case you want to do it, use this:
     * let real_questions = this.getSolutions().length;
     */

    const real_questions = reject(
      questions,
      question =>
        // exclude questions with value from count. They are not real questions, just normal words
        question.value.length > 0
    );

    return given_answers.length === real_questions.length;
  }
}

export default QuizFillblanksModel;
