import { useMemo } from "react";
import { BoardElement, OldBoard } from "src/js/types";
import { getMaxPosition } from "../ModuleDetail.utils";
import { getModulePlaceholderNumber } from "src/js/modules/moduleFunction";

export const useBoardResources = ({
  isDraft,
  board
}: {
  isDraft: boolean;
  board: OldBoard;
}) => {
  const boardElements = useMemo(
    () =>
      board.board_elements
        .slice()
        .sort((a, b) => a.position - b.position)
        .filter(
          boardElement =>
            boardElement.dtype ===
            (isDraft ? "boardelementdraft" : "boardelement")
        ),
    [board, isDraft]
  );

  const hasDrafts = useMemo(
    () => board.board_elements.some(el => el.dtype === "boardelementdraft"),
    [board]
  );

  const numberOfElementsInGrid = useMemo(() => {
    const positionAfterLastBoardElement = getMaxPosition({ boardElements });
    const placeHoldersAfterLastBoardElement = getModulePlaceholderNumber(
      positionAfterLastBoardElement
    );
    return positionAfterLastBoardElement + placeHoldersAfterLastBoardElement;
  }, [boardElements]);

  const boardElementGrid = useMemo<BoardElement[]>(() => {
    const arr = new Array(numberOfElementsInGrid);
    arr.fill({});
    boardElements.forEach(element => {
      arr[element.position] = element;
    });
    return arr;
  }, [boardElements, numberOfElementsInGrid]);

  return { boardElementGrid, hasDrafts, boardElements };
};
