import { shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const SubmenuRow = styled(motion.div)`
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  width: max-content;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  box-shadow: ${shadow[500]};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Button = styled.button<{ active?: boolean }>`
  border: none;
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[900] : "transparent"};
  color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[300] : theme.colors.blueGrey[500]};
  transition: 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
    color: ${({ theme }) => theme.colors.blueGrey[300]};
  }

  // Fix safari flex
  > svg {
    flex: 0 0 16px;
  }
`;

export const IconWrapper = styled.button<{ active: boolean }>`
  border: none;
  outline: none;
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;
  padding: 0px 4px;
  height: 28px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[900] : "transparent"};
  color: ${({ theme, active }) =>
    active ? theme.colors.blueGrey[300] : theme.colors.blueGrey[500]};
  transition: 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;
