import React, { forwardRef, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";

import { getPageNumbers } from "./utils";
import * as S from "./PageNav.styles";
import { PageNavProps } from "./types";

const PageNav = forwardRef<HTMLElement, PageNavProps>(
  (
    {
      theme,
      totalRecords,
      pageLimit,
      startingOffset = 0,
      onPageChanged,
      label,
      showCounter,
      separator = "/"
    },
    ref
  ) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalRecords / pageLimit);

    useEffect(() => {
      const calculatedPage = Math.round(startingOffset / pageLimit + 1);
      setCurrentPage(calculatedPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startingOffset]);

    if (!totalRecords || totalPages === 1) return null;

    const pages = getPageNumbers(currentPage, totalPages);
    const displayPrevControl = currentPage > 1;
    const disabledPrevControl = currentPage === 1;
    const displayNextControl = currentPage < totalPages;
    const disabledNextControl = currentPage === totalPages;

    const elementCount =
      startingOffset + pageLimit < totalRecords
        ? startingOffset + pageLimit
        : totalRecords;

    const gotoPage = (page: number) => {
      if (currentPage !== page) {
        setCurrentPage(page);
        onPageChanged(page);
      }
    };

    return (
      <ThemeProvider theme={theme}>
        <S.PageNav ref={ref} role="tablist">
          {showCounter && (
            <S.PaginationCounter>
              {elementCount} {separator} {totalRecords}
            </S.PaginationCounter>
          )}
          <S.PageNavList>
            <S.PageItem key="page-item-key-prev">
              <S.PageLink
                displayControl={displayPrevControl}
                disabled={disabledPrevControl}
                aria-label="previous pages"
                role="button"
                onClick={() => gotoPage(currentPage - 1)}
                tabIndex={currentPage - 1}
              >
                <Text type="table">{label.previous}</Text>
              </S.PageLink>
            </S.PageItem>
            {pages.map(page => {
              return (
                <S.PageItem key={`page-item-key-${page}`}>
                  <S.PageLink
                    aria-selected={currentPage === page}
                    onClick={() => gotoPage(page)}
                    aria-label={`page ${page}`}
                    role="tab"
                    numberedPageButton
                    tabIndex={currentPage}
                  >
                    <Text type="table">{page}</Text>
                  </S.PageLink>
                </S.PageItem>
              );
            })}
            <S.PageItem key="page-item-key-next">
              <S.PageLink
                displayControl={displayNextControl}
                disabled={disabledNextControl}
                aria-label="next pages"
                role="button"
                onClick={() => gotoPage(currentPage + 1)}
                tabIndex={currentPage + 1}
              >
                <Text type="table">{label.next}</Text>
              </S.PageLink>
            </S.PageItem>
          </S.PageNavList>
        </S.PageNav>
      </ThemeProvider>
    );
  }
);

export default PageNav;

PageNav.displayName = "PageNav";
