import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Icon, VerticalStepper } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { FormContext, useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { CourseDetailsWizardForm } from "./CourseDetailsWizard.types";
import { AudienceStep } from "./components/AudienceStep";
import { TopicStep } from "./components/TopicStep";
import { CourseLengthStep } from "./components/CourseLengthStep";
import { EffortStep } from "./components/EffortStep";
import { CourseTypologyStep } from "./components/CourseTypologyStep";

import * as S from "./CourseDetailsWizard.styles";

const CourseDetailsWizard = ({
  onSubmit,
  defaultValues = undefined
}: {
  onSubmit: (data: CourseDetailsWizardForm) => void;
  defaultValues?: CourseDetailsWizardForm;
}) => {
  const [step, setStep] = useState(0);
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();

  const methods = useForm<CourseDetailsWizardForm>({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (!defaultValues) return;
    Object.keys(defaultValues).forEach(key => {
      methods.setValue(key, defaultValues[key]);
    });
    methods.triggerValidation();
  }, [defaultValues]);

  const onStepJump = useCallback(
    (newStep: number) => {
      if (newStep > step) return;
      setStep(newStep);
    },
    [step, setStep]
  );

  return (
    <FormContext {...methods}>
      <VerticalStepper
        step={step}
        onStepJump={onStepJump}
        jumpToStep
        items={[
          {
            title: translate("ai_wizard_audience_title"),
            content: <AudienceStep onNext={() => setStep(1)} />
          },
          {
            title: translate("ai_wizard_topic_title"),
            content: <TopicStep onNext={() => setStep(2)} />
          },
          {
            title: translate("ai_wizard_course_length_title"),
            content: <CourseLengthStep onNext={() => setStep(3)} />
          },
          {
            title: translate("ai_wizard_effort_title"),
            content: <EffortStep onNext={() => setStep(4)} />
          },
          {
            title: translate("ai_wizard_course_typology_title"),
            content: <CourseTypologyStep />
          }
        ]}
      />
      <S.ButtonWrapper isLayoutMobile={isLayoutModeMobile}>
        <Button
          variant="primary"
          disabled={
            !methods.formState.isValid || methods.formState.dirtyFields.size < 5
          }
          theme={whiteTheme}
          fullWidth
          onClick={methods.handleSubmit(onSubmit)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={8}
          >
            <Icon icon="sparkles" width={20} height={20} />
            {translate("ai_wizard_generate_button")}
          </Box>
        </Button>
      </S.ButtonWrapper>
    </FormContext>
  );
};

export default observer(CourseDetailsWizard);
