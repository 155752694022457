import React, { useCallback } from "react";
import { Box, Icon, Text } from "@arcadia/design-system";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { AnimatePresence } from "framer-motion";
import { useBoardModuleCreate } from "src/js/pages/boards/useBoardModuleCreate";
import { useTranslation } from "src/js/translation";
import { InfoChip } from "src/js/components/InfoChip";
import { CREATE_MODULE_TYPE } from "src/js/pages/boards/components/ModuleCreateButton/ModuleCreateButton.types";
import { ImportModuleModal } from "src/js/pages/boards/ImportModuleModal";
import { AiCourseGeneratorEvent, EventDomain, TODO } from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { FeatureFlagKey } from "src/js/settings/settingsFeatureFlag";
import {
  isFeatureFlagEnabled,
  useFeatureFlag
} from "src/js/feature_flag/FeatureFlagProvider";
import { DropdownItem } from "./ModuleCreateDropdown.types";
import { AICourseWizardModal } from "../../AICourseWizardModal";

import {
  __DROPDOWN_LIST__,
  dropdownAnimationVariants
} from "./ModuleCreateDropdown.utils";
import * as S from "./ModuleCreateDropdown.styles";

const ModuleCreateDropdown = () => {
  const { translate } = useTranslation();
  const { isOpen, setIsOpen, ref } = useCloseOnClickOutside(false);
  const {
    ModalStore: { openModal }
  } = useStores();
  const { handleCreateBoardModule: createModule } = useBoardModuleCreate();
  const featureFlagConfig = useFeatureFlag();

  const onClickOption = useCallback(
    (type: DropdownItem["type"]) => () => {
      switch (type) {
        case CREATE_MODULE_TYPE.NEW:
          createModule();
          _trackEvent(
            EventDomain.AiCourseGenerator,
            (AiCourseGeneratorEvent as TODO).NewModuleDropdownNew
          );
          break;
        case CREATE_MODULE_TYPE.IMPORT:
          openModal(() => <ImportModuleModal />);
          _trackEvent(
            EventDomain.AiCourseGenerator,
            (AiCourseGeneratorEvent as TODO).NewModuleDropdownImport
          );
          break;
        case CREATE_MODULE_TYPE.AI:
          openModal(() => <AICourseWizardModal />);
          _trackEvent(
            EventDomain.AiCourseGenerator,
            AiCourseGeneratorEvent.NewModuleDropdownAi
          );
          break;
        default:
          break;
      }
    },
    [openModal, createModule]
  );

  const aiCourseGenEnabled = isFeatureFlagEnabled({
    flagKey: FeatureFlagKey.aiCourseGeneration,
    featureFlagConfig
  });

  return (
    <S.DropdownWrapper ref={ref}>
      <S.DropdownTrigger
        onClick={() => setIsOpen(!isOpen)}
        role="button"
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
      >
        <S.ButtonContent>
          <S.LeftSection>
            <Icon icon="book" width={24} height={24} />
            {translate("module_create_dropdown_toggle")}
          </S.LeftSection>
          <Icon icon="plusThin" width={16} height={16} />
        </S.ButtonContent>
      </S.DropdownTrigger>
      <AnimatePresence initial={false}>
        {isOpen && (
          <S.DropdownContent
            variants={dropdownAnimationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {__DROPDOWN_LIST__
              .filter(
                dropdownItem => aiCourseGenEnabled || dropdownItem.type !== "ai"
              )
              .map(dropdownItem => (
                <S.DropdownItem
                  whileTap={{ scale: 0.99 }}
                  key={dropdownItem.title}
                  onClick={onClickOption(dropdownItem.type)}
                >
                  <Icon
                    initialViewbox
                    icon={dropdownItem.icon}
                    width={24}
                    height={24}
                  />
                  <S.Column>
                    <Box display="flex" alignItems="center">
                      <Text type="subHeaderTitle">
                        {translate(dropdownItem.title)}
                      </Text>
                      {dropdownItem.type === CREATE_MODULE_TYPE.AI && (
                        <Box marginLeft={8}>
                          <InfoChip text="ai_tools_chip_text" />
                        </Box>
                      )}
                    </Box>
                    <S.Subtitle type="formSmallCaption">
                      {translate(dropdownItem.subtitle)}
                    </S.Subtitle>
                  </S.Column>
                </S.DropdownItem>
              ))}
          </S.DropdownContent>
        )}
      </AnimatePresence>
    </S.DropdownWrapper>
  );
};

export default ModuleCreateDropdown;
