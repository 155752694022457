import { ObjectValues } from "src/js/types";

export const DateInputStartDates = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
} as const;

export type DateInputStartDatesType = ObjectValues<typeof DateInputStartDates>;
