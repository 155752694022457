import { Button, Icon, Modal } from "@arcadia/design-system";
import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import styled, { useTheme } from "styled-components";

export const StyledFooter = styled.div.attrs(props => ({
  ...props,
  className: "main-modal__footer--buttons"
}))`
  justify-content: center;
`;

const ExerciseShowVoteConfirmModal = ({ closeModal, callbackOk }) => {
  const confirmMessage = "show_vote_and_result_confirm_message";

  const { whiteTheme } = useTheme();

  const alertMessage = "show_vote_and_result_alert_text";

  const confirmPublish = () => {
    closeModal();
    callbackOk();
  };

  return (
    <Modal
      title={<Translate text="show_vote_and_result_confirm_modal_title" />}
      onClose={closeModal}
    >
      <div className="main-modal__container">
        <div className="main-modal__content">
          <div className="text-center margin-vertical-20">
            <div className="margin-top-30">
              <Icon icon="dangerTriangle" height="60px" width="60px" />
            </div>
            <div className="medium-title ">
              <Translate text="exercise_publish_modal_subtitle" />
            </div>
            <div className="font-14 margin-top-10">
              <Translate text={confirmMessage} />
            </div>
            <div className="font-14">
              <Translate text={alertMessage} />
            </div>
          </div>
        </div>
        <StyledFooter>
          <Button
            type="button"
            onClick={closeModal}
            theme={whiteTheme}
            variant="secondary"
          >
            <Translate text="undo_button_tooltip" />
          </Button>
          <Button
            type="button"
            onClick={confirmPublish}
            theme={whiteTheme}
            variant="primary"
          >
            <Translate text="confirm" />
          </Button>
        </StyledFooter>
      </div>
    </Modal>
  );
};

export default ExerciseShowVoteConfirmModal;
