import styled from "styled-components";

import { SLabelProps } from "./types";

export const Label = styled.div<SLabelProps>`
  height: 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 2px 5px 2px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid
    ${({ theme, color }) => theme.label.variant[color].background};
  color: ${({ theme, color, outline }) =>
    outline
      ? theme.label.variant[color].background
      : theme.label.variant[color].text};
  background-color: ${({ theme, color, outline }) =>
    outline ? "transparent" : theme.label.variant[color].background};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: ${({ theme, color }) =>
      theme.label.variant[color].background};
    color: ${({ theme }) => theme.label.icon};
  }
  span {
    margin-right: 8px;
    margin-left: 8px;
    white-space: pre;
  }

  svg {
    color: ${({ theme }) => theme.label.icon};
  }
`;
