import { Model } from "backbone";
import React from "react";
import ReactDom from "react-dom";
import throttle from "lodash/throttle";
import FullPageModal from "../components/fullPageModal";
import ThemeProvider from "src/js/theme/ThemeProvider";

class ModalPage extends Model {
  initialize() {
    this.on("change:body", () => {
      const modalPage = document.getElementById("modal-page");
      if (modalPage) ReactDom.unmountComponentAtNode(modalPage); // altrimenti apre sempre stesso component su nuova apertura
    });
  }
  show(addClass) {
    const self = this;

    ReactDom.render(
      <ThemeProvider>
        <FullPageModal
          modalMessage={this}
          hideFunc={this.hide}
          addClass={addClass}
        />
      </ThemeProvider>,
      $("#modal-page").get(0)
    );
    $(".react-modal-page").modal("show");
    $("body").addClass("modal-page-open"); // forzo aggiunta class
    // $("body").on("touchmove",  function(e){e.preventDefault();}); //stop scrolling on mobile
    $(".react-modal-page").on("hide.bs.modal", () => {
      $("body").removeClass("modal-page-open modal-open"); // forzo rimozione class
    });
    $(".react-modal-page").on("shown.bs.modal", e => {
      self.resize();
      $(window).resize(
        throttle(() => {
          self.resize();
        }, 250)
      );
    });
  }
  hide() {
    ReactDom.render(<div />, $("#modal-msg").get(0));
    ReactDom.render(<div />, $("#modal-page").get(0));
    $(".react-modal-page").modal("hide");
    ReactDom.unmountComponentAtNode($("#modal-page").get(0));
    // $("body").off("touchmove"); //stop scrolling on mobile
    $("body").removeClass("modal-page-open modal-open"); // forzo rimozione class
    $("body").css("padding-right", 0);
  }
  resize() {
    // ricalcolo dimensioni per rendere fullpage
    const pageHeight = $(window).innerHeight();
    const headerHeight = 0;
    const footerHeight = 0;
    // let bodyHeight = pageHeight + headerHeight + footerHeight - 2;
    const bodyHeight = pageHeight;
    // $(".modal-fullpage .modal-body").innerHeight(bodyHeight+"px");
  }
}

export const modalPage = new ModalPage();

modalPage.set({
  header: { content: "", type: "HTML" },
  body: { content: "", type: "HTML" },
  footer: { content: "", type: "HTML" },
  addCloseButton: false
});
