import getUserClient from "src/js/modules/connection";
import {
  getVersionedApiUrl,
  api_users_verify,
  api_users_verify_resend
} from "src/js/repository/apiUrls";

export function codeVerification(data) {
  return getUserClient()
    .post(getVersionedApiUrl(api_users_verify(), "v3"), data)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function resendCodeVerificationOnEmail() {
  return getUserClient()
    .post(getVersionedApiUrl(api_users_verify_resend(), "v3"))
    .then(res => {
      return res.data;
    });
}
