export const DROPDOWN_OPTIONS = [
  {
    key: "ROLE_TEACHER",
    value: "registration_role_teacher"
  },
  {
    key: "ROLE_STUDENT",
    value: "registration_role_student"
  }
];
