import React from "react";
import { CheckIndicator } from "src/js/components/global/CheckIndicator";
import * as S from "./CheckboxBlock.styles";

const CheckboxBlock = ({
  onClick = () => {},
  isSelected = false,
  children,
  ...props
}) => {
  return (
    <S.CheckboxBlockContainer
      isSelected={isSelected}
      onClick={onClick}
      {...props}
    >
      <S.CheckboxBlockInfoContainer>{children}</S.CheckboxBlockInfoContainer>
      <CheckIndicator isSelected={isSelected} />
    </S.CheckboxBlockContainer>
  );
};

export default CheckboxBlock;
