import styled, { css } from "styled-components";

export const ModalBody = styled.div`
  display: flex;
  width: calc(100% - 12px);
  margin: 10px;
  margin-right: 2px;
  border-radius: 8px;
  padding: 12px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent4};
  }
`;

export const ModalBodyTextContainer = styled.div`
  font-size: 16px;
  padding-left: 32px;
  -webkit-touch-callout: none;
  user-select: none;
  align-self: center;
`;

export const LoaderContainer = styled.div`
  align-self: center;
  width: 48px;
  height: 48px;
`;

const LoaderIconContainer = css`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PercentageContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey[50]};
  font-weight: 500;
  font-size: 12px;
  -webkit-touch-callout: none;
  user-select: none;
`;

export const PercentageText = styled.span`
  font-size: 14px;
`;

export const CancelIcon = styled.div`
  ${LoaderIconContainer}
  cursor: pointer;
`;

export const SuccessIcon = styled.div`
  ${LoaderIconContainer}
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ErrorIcon = styled.div`
  ${LoaderIconContainer}
  background-color: ${({ theme }) => theme.colors.error[600]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const AlertIcon = styled.div`
  ${LoaderIconContainer}
  background-color: ${({ theme }) => theme.colors.yellow[500]};
  color: ${({ theme }) => theme.colors.grey[50]};
`;
