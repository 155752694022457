import styled from "styled-components";

export const WallBadgeBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 16px;
`;

export const WallBadgeBoxTitle = styled.div`
  margin-bottom: 12px;
`;

export const WallBadgeBoxList = styled.div`
  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[800]};
    padding: 12px 0;

    &:first-child {
      padding-top: 0px;
    }
    &:nth-last-child(1):nth-child(even),
    &:only-child,
    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
`;

export const WallBadgeBoxFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.blueGrey[800]};
`;

export const WallBadgeBoxCount = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const WallBadgeBoxNext = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

export const WallBadgeContainer = styled.div`
  display: flex;
  align: center;
  justify-content: flex-start;
  flex-flow: row;
  &:hover {
    cursor: pointer;
  }
`;

export const BadgeImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 36px;
  max-height: 36px;
  border-radius: 4px;
`;

export const BadgeName = styled.div`
  margin-left: 16px;
  align-items: center;
  display: flex;
  min-width: 0px;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
