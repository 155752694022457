import styled, { css } from "styled-components";

export const UserListHeader = styled.div`
  padding: 0 32px 8px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey[500]};
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderColumn = styled.div`
  width: 20%;
  padding-right: 20px;

  ${({ large }) =>
    large &&
    css`
      width: 30%;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 15%;
    `}

  ${({ extrasmall }) =>
    extrasmall &&
    css`
      width: 10%;
    `}
`;

export const ActionColumn = styled.div`
  width: 44px;
  direction: rtl;
`;

export const RightAligner = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
