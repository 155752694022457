import { Icon } from "@arcadia/design-system";
import { isUndefined } from "lodash";
import moment from "moment";
import React from "react";

const BoardActivityRows = ({
  userStatistics = [],
  boards,
  countTotalBoard
}) => {
  const rows = userStatistics.map((userRow, indexRow) => {
    let overallClass = "green-color";
    const boardStatistic = userRow.board_statistics;
    const timestamp = moment(userRow.last_active).format("ll");
    let countUserBoard = 0;

    const column = boards.map((board, indexColumn) => {
      if (
        isUndefined(boardStatistic) ||
        boardStatistic[board.id] == null ||
        (boardStatistic[board.id].comments_number == 0 &&
          boardStatistic[board.id].viewed_elements == 0)
      ) {
        return (
          <td key={`board-activity-column-${indexRow}-${indexColumn}`}>-</td>
        );
      }

      const boardValues = boardStatistic[board.id];
      let check = null;

      if (boardValues.viewed_elements === board.board_element_count) {
        countUserBoard += 1;
        check = (
          <div className="percentage">
            <Icon icon="squareCheck" width="12" height="12" />
          </div>
        );
      } else if (boardValues.viewed_elements !== 0) {
        const percentage = Math.round(
          (boardValues.viewed_elements / board.board_element_count) * 100
        );
        check = <div className="percentage">{percentage}%</div>;
      }

      return (
        <td key={`board-activity-column-${indexRow}-${indexColumn}`}>
          <div className="summary">
            {check}
            <div className="comment">
              {boardValues.comments_number > 0 ? (
                <>
                  <div className="comment-number">
                    {boardValues.comments_number}
                  </div>
                  <Icon icon="chat" height="12" width="12" />
                </>
              ) : null}
            </div>
          </div>
        </td>
      );
    });

    if (countUserBoard === 0) {
      overallClass = "red-color";
    } else if (countTotalBoard - countUserBoard !== 0) {
      overallClass = "orange-color";
    }

    return (
      <tr
        key={`board-activity-row-${indexRow}`}
        id={`board-activity-row-${userRow.user.id}`}
      >
        <td className="user">
          <strong>{userRow.user.surname}, </strong> {userRow.user.name}
        </td>
        <td className="last-active">{timestamp}</td>
        <td className={["overall-progress", overallClass].join(" ")}>
          {countUserBoard}/{countTotalBoard}
        </td>
        {column}
      </tr>
    );
  });

  return <>{rows}</>;
};

export default BoardActivityRows;
