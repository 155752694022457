import styled from "styled-components";
import { motion } from "framer-motion";

export const Card = styled(motion.div)`
  max-width: 280px;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
`;

export const Avatar = styled(motion.div)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const SkeletonContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: space-around;
`;

export const Skeleton = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.coldGrey[800]};
  height: 8px;
  border-radius: 4px;
`;
