import { __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__ } from "../settings/settingFileUploader";
import { TempResource } from "../types";

export const isAllowedMimeType = (file: File, mimeArray: string[] | "*") => {
  return mimeArray === "*" || mimeArray.find(type => file.type === type);
};

export const isAllowedFileSize = (file: File, maxSize: number) => {
  return file.size <= maxSize;
};

export const getMimeTypeString = ({ mimeType }) => {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "application/vnd.google-apps.document":
      return "doc";
    case "application/vnd.google-apps.spreadsheet":
      return "xls";
    case "application/vnd.google-apps.presentation":
      return "ppt";
    case "application/vnd.google-apps.drawing":
      return "img";
    case "application/mspowerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "ppt";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "doc";
    case "application/vnd.ms-excel":
    case "application/msexcel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xls";
    case "video/mp4":
    case "video/quicktime":
      return "video";
    case "application/x-zip-compressed":
      return "zip";
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
    case "image/gif":
    case "image/tiff":
      return "image";
    default:
      return "File";
  }
};

export const getIsMediaResource = (mimeType: string) => {
  return getMimeTypeString({ mimeType }) === "image";
};

const generateTempId = (): string => {
  if (typeof crypto !== "undefined" && crypto.randomUUID) {
    return crypto.randomUUID();
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = Math.random() * 16 || 0;
    const v = c === "x" ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
};

export const generateTempResource = ({
  type,
  name = "",
  id = -1,
  uuid = "",
  size = 0,
  fileExtension = "",
  fileMimeType = "",
  aiGenerated = false,
  preview,
  textContent = "",
  exerciseId = "",
  file
}: Partial<TempResource> = {}): TempResource => ({
  id,
  uuid,
  type,
  name,
  size,
  fileExtension,
  fileMimeType,
  aiGenerated,
  preview,
  textContent,
  exerciseId,
  file,
  tempId: generateTempId()
});

export const convertFileListToTempResources = (fileList: FileList) => {
  return Array.from(fileList).map(file =>
    generateTempResource({
      name: file?.name,
      size: file?.size,
      fileMimeType: file?.type,
      file
    })
  );
};

export const checkFileSize = (fileList: FileList) => {
  let largeFiles: string[] = [];
  Array.from(fileList).forEach(file => {
    if (file.size > __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__) {
      largeFiles = [...largeFiles, file.name];
    }
  });
  return largeFiles;
};
