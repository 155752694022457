import React from "react";
import { Box, Button, ContextualAlert } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import * as S from "../styles";
import BadgeRelatedUser from "./BadgeRelatedUser";

const ReviewSelectedGroups = ({
  handleClose
}: {
  handleClose: (shouldRefetchBadges?: boolean) => void;
}) => {
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: { usersToEmitBadge, badgeToEdit, transactionFailed },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme, greyTheme } = useTheme();
  const { image, name } = badgeToEdit || {};

  return (
    <S.DrawerBodyContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginBottom={32}
      >
        <S.BadgeEmiitedImage src={image} alt="badge-logo" />
        <S.SuccessMessage level="5">
          {translate("space_badge_emit_to_students_success_message", {
            badgeName: name,
            numberOfUsers: usersToEmitBadge.length
          })}
        </S.SuccessMessage>
        {transactionFailed && (
          <S.AlertContainer>
            <ContextualAlert
              theme={greyTheme}
              text={translate(
                "emit_nft_to_students_review_screen_failed_transaction_message"
              )}
            />
          </S.AlertContainer>
        )}
      </Box>
      <Box id="scroll-area-container" minHeight={400} marginBottom="auto">
        <S.ScrollableArea id="badge-emitted-users-scrollable-list">
          <S.BadgeEmittedUsersContainer>
            {usersToEmitBadge.map(user => (
              <BadgeRelatedUser
                key={user.id}
                badgeRelatedUser={user}
                isEmitted
              />
            ))}
          </S.BadgeEmittedUsersContainer>
        </S.ScrollableArea>
      </Box>
      {isLayoutModeMobile ? null : (
        <S.DrawerFooter>
          <S.ReviewButtonsContainer>
            <Button
              theme={whiteTheme}
              variant="primary"
              onClick={() => handleClose(true)}
            >
              {translate("close")}
            </Button>
          </S.ReviewButtonsContainer>
        </S.DrawerFooter>
      )}
    </S.DrawerBodyContainer>
  );
};

export default observer(ReviewSelectedGroups);
