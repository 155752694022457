import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

import * as S from "./CustomTick.styles";
import {
  GroupTooltip,
  GroupTooltipProps
} from "../../Objectives/components/GroupTooltip";

export type CustomTickProps = {
  x: number;
  y: number;
  payload: {
    index: number;
    value: string;
  };
  groups: GroupTooltipProps;
};

const CustomTick = ({ x, y, payload, groups }: CustomTickProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { id, name, cover, totalUsers } = groups[payload.index] || {};

  return (
    <g>
      <g>
        <foreignObject x={x - 90} y={y - 8} width="88" height="12">
          <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <Tooltip.Trigger asChild onClick={() => setIsTooltipOpen(true)}>
                <S.Text>{payload.value}</S.Text>
              </Tooltip.Trigger>
              <Tooltip.Portal forceMount>
                <AnimatePresence>
                  {isTooltipOpen ? (
                    <S.StyledTooltipContent
                      forceMount
                      asChild
                      side="bottom"
                      sideOffset={16}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, y: -10 }}
                        exit={{ opacity: 0, y: 0 }}
                        transition={{
                          duration: 0.2
                        }}
                      >
                        <GroupTooltip
                          id={id}
                          name={name}
                          cover={cover}
                          totalUsers={totalUsers}
                        />
                      </motion.div>
                    </S.StyledTooltipContent>
                  ) : null}
                </AnimatePresence>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </foreignObject>
      </g>
    </g>
  );
};

export default CustomTick;
