import React, { useEffect, useState } from "react";
import { Text } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { TabBarProps } from "./TabBar.types";

import * as S from "./TabBar.styles";

const TabBar = <T extends string | number>({
  items,
  underLineDistance = -8,
  onTabChange
}: TabBarProps<T>) => {
  const [selected, setSelected] = useState(items[0].id);

  useEffect(() => {
    if (onTabChange) onTabChange(items.find(item => item?.id === selected));
  }, [selected, onTabChange]);

  return (
    <S.TabBar>
      {items.map(tabItem => {
        const isSelected = selected === tabItem.id;
        return (
          <S.Tab
            key={tabItem.label}
            onClick={() => {
              setSelected(tabItem.id);
            }}
            active={isSelected}
          >
            <Text type="formField">{tabItem.label}</Text>
            <AnimatePresence presenceAffectsLayout>
              {tabItem.count !== undefined && tabItem.count > 0 && (
                <S.TabCountPill
                  active={isSelected}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                >
                  <S.TabCountText type="label">
                    {tabItem.count > 99 ? "99+" : tabItem.count}
                  </S.TabCountText>
                </S.TabCountPill>
              )}
            </AnimatePresence>
            {isSelected && (
              <S.UnderLine
                underLineDistance={underLineDistance}
                layoutId="underline"
              />
            )}
          </S.Tab>
        );
      })}
    </S.TabBar>
  );
};

export default TabBar;
