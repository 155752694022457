import {
  QueryFunction,
  QueryKey,
  useQuery as useQueryDefault
} from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "./types";
import useIsEmptyQueryResult from "./useIsEmptyQueryResult";

const useQuery = <TQueryFnData = unknown, TError = Error, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
): UseQueryResult<TData, TError> => {
  const { transformEmptyResult, ...otherOptions } = options || {};
  const { data, status, ...queryResult } = useQueryDefault({
    queryFn,
    queryKey,
    ...otherOptions
  });
  const isEmpty = useIsEmptyQueryResult({ transformEmptyResult, data, status });

  return {
    ...queryResult,
    data,
    status,
    isEmpty
  } as UseQueryResult<TData, TError>;
};

export default useQuery;
