export const BLOCKED_FAKE_USERS = [
  {
    groupsCount: 4,
    user: {
      id: 8080,
      uuid: "73f57c9a-9eaa-4c99-974b-50ac431d7b7d",
      avatar: {
        small:
          "https://static-stage.weschool.com/user/14332/avatar/large_e813dbe85de0807f69b680ca34f5550d.jpeg"
      },
      name: "Mario",
      surname: "Rossi",
      birthday: "1992-03-01T12:11:44+00:00",
      email: "username1@weschool.com",
      created: "2021-03-31T12:11:44+00:00"
    }
  },
  {
    groupsCount: 4,
    user: {
      id: 8081,
      uuid: "73f57c9a-9eaa-4c99-974b-50ac431d7c4c",
      name: "Giorgio",
      surname: "Bianchi",
      birthday: "1987-02-01T12:11:44+00:00",
      email: "username2@weschool.com",
      created: "2020-07-06T12:11:44+00:00"
    }
  },
  {
    groupsCount: 4,
    user: {
      id: 8082,
      uuid: "73f57c9a-9eaa-4c99-974b-50ac431d7a3a",
      avatar: {
        small:
          "https://static-stage.weschool.com/user/14332/avatar/large_e813dbe85de0807f69b680ca34f5550d.jpeg"
      },
      name: "Camilla",
      surname: "Verdi",
      birthday: "1999-12-11T12:11:44+00:00",
      email: "username3@weschool.com",
      created: "2020-12-03T12:11:44+00:00"
    }
  },
  {
    groupsCount: 10,
    user: {
      id: 8083,
      uuid: "73f57c9a-9eaa-4c99-974b-50ac431d7a3a",
      avatar: {
        small:
          "https://static-stage.weschool.com/user/14332/avatar/large_e813dbe85de0807f69b680ca34f5550d.jpeg"
      },
      name: "Gianluigi II",
      surname: "Baccadelli de Gregori",
      birthday: "1991-01-11T12:11:44+00:00",
      email: "username1@weschool.com",
      created: "2020-09-03T12:11:44+00:00"
    }
  }
];
