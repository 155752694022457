import * as Tooltip from "@radix-ui/react-tooltip";
import { Box, BoxProps, Heading, Icon, Text } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { EventDomain } from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import * as S from "../MetricCard";

type PrimaryMetricCardProps = {
  loading?: boolean;
  children: ReactNode;
  titleLabel: string;
  dateLabel?: string;
  infoTooltip?: ReactNode;
  trackingEvents?: {
    tooltip: { domain: EventDomain; action: string };
  };
} & BoxProps;

const PrimaryMetricCard = ({
  loading,
  children,
  titleLabel,
  dateLabel,
  infoTooltip,
  trackingEvents,
  ...props
}: PrimaryMetricCardProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { colors, primaryColor } = useTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (isTooltipOpen && trackingEvents?.tooltip) {
      _trackEvent(trackingEvents.tooltip.domain, trackingEvents.tooltip.action);
    }
  }, [isTooltipOpen]);

  return (
    <Box
      borderRadius={12}
      backgroundColor={colors.grey[1000]}
      {...(isLayoutModeMobile && {
        borderRadius: 0
      })}
      {...props}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        gap={8}
        borderBottom={`1px solid ${primaryColor[900]}`}
        padding="16px 32px"
        {...(isLayoutModeMobile && {
          padding: 16
        })}
      >
        <Skeleton loading={loading} borderRadius={16}>
          <Icon
            initialViewbox
            width={20}
            height={20}
            icon="flag"
            color={primaryColor[500]}
          />
        </Skeleton>
        <Skeleton loading={loading} borderRadius={16}>
          <Heading level="4">{titleLabel}</Heading>
        </Skeleton>
        {dateLabel ? (
          <Skeleton loading={loading} borderRadius={16}>
            <S.ChipBox>
              <Icon
                style={{ flexShrink: 0 }}
                initialViewbox
                icon="calendar"
                width={16}
                height={16}
              />
              <Text type="formSmallCaption">{dateLabel}</Text>
            </S.ChipBox>
          </Skeleton>
        ) : null}
        {infoTooltip ? (
          <Box paddingLeft={8} marginLeft="auto">
            <Skeleton loading={loading} borderRadius={16}>
              <Tooltip.Provider delayDuration={0}>
                <Tooltip.Root
                  open={isTooltipOpen}
                  onOpenChange={setIsTooltipOpen}
                >
                  <Tooltip.Trigger
                    asChild
                    onClick={() => setIsTooltipOpen(true)}
                  >
                    <S.InfoButton>
                      <Icon
                        initialViewbox
                        icon="infoI"
                        width={12}
                        height={12}
                      />
                    </S.InfoButton>
                  </Tooltip.Trigger>
                  <Tooltip.Portal forceMount>
                    <AnimatePresence>
                      {isTooltipOpen ? (
                        <Tooltip.Content
                          forceMount
                          asChild
                          side="left"
                          sideOffset={16}
                        >
                          <S.MotionTooltipContent
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, y: -10 }}
                            exit={{ opacity: 0, y: 0 }}
                            transition={{
                              duration: 0.2
                            }}
                          >
                            {infoTooltip}
                          </S.MotionTooltipContent>
                        </Tooltip.Content>
                      ) : null}
                    </AnimatePresence>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </Skeleton>
          </Box>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};

export default PrimaryMetricCard;
