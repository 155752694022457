import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.default};
  flex: 1;
`;

export const AvatarContainer = styled.div`
  margin-top: 5px;
  margin-right: 15px;
`;

export const MessageHeader = styled.div`
  margin-bottom: 7px;
  display: flex;
  max-width: calc(100% - 45px);
  align-items: baseline;
  flex-wrap: wrap;
`;

export const FromWho = styled.div`
  padding-right: 10px;
`;

export const DateTime = styled.div`
  margin-top: -3px;
`;

export const MessageContent = styled.div`
  font-size: 14px;
`;

export const EditIconContainer = styled.div`
  height: 30px;
  width: 30px;
  padding: 4px;
  margin-top: 5px;
  border: 1px solid ${({ theme }) => theme.colors.defaultBorderColor};
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.primary2};
  }
`;

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ editing }) =>
    editing &&
    css`
      height: 150px;
    `}
`;

export const EditButtonsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 5px;
  }
`;
