import { extractErrorMessage } from "src/js/modules/messageManager";
import getUserClient from "src/js/modules/connection";
import {
  api_execution_comments,
  api_report_threads,
  api_execution_redo,
  api_report_threads_delete_correction,
  api_report_threads_edit_correction,
  getVersionedApiUrl,
  api_report_abuse
} from "src/js/repository/apiUrls";

export function fetchReportComments({ executionId }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_execution_comments({ executionId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_exercise_comments")
      );
      throw Err;
    });
}
export function saveReportComment({ executionId, commentText }) {
  return getUserClient()
    .post(getVersionedApiUrl(api_execution_comments({ executionId })), {
      comment_text: commentText
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_save_exercise_comments")
      );
      throw Err;
    });
}
export function editReportComment({ executionId, commentId, commentText }) {
  return getUserClient()
    .put(getVersionedApiUrl(api_execution_comments({ executionId })), {
      comment_id: commentId,
      comment_text: commentText
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_edit_exercise_comments")
      );
      throw Err;
    });
}

export const saveReportThreadMessage = ({
  exerciseId,
  executionId,
  userId,
  quizExecutionId,
  threadMessage = null,
  points = null,
  redoExecution = false,
  resourceId = null
}) => {
  const body = {
    user_id: userId,
    quiz_execution_id: quizExecutionId,
    thread_message: threadMessage,
    points,
    redo_execution: redoExecution,
    resource_id: resourceId
  };
  return getUserClient()
    .post(getVersionedApiUrl(api_report_threads({ exerciseId, executionId })), {
      ...body
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_save_report_thread_message")
      );
      throw Err;
    });
};

export function fetchReportThreads({ exerciseId, executionId }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_report_threads({ exerciseId, executionId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_report_thread")
      );
      throw Err;
    });
}

export function removeReportThreadsCorrection({ exerciseId, executionId, id }) {
  return getUserClient()
    .post(
      getVersionedApiUrl(
        api_report_threads_delete_correction({ exerciseId, executionId })
      ),
      {
        id
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_report_thread")
      );
      throw Err;
    });
}

export function editReportThreadsCorrection({
  exerciseId,
  executionId,
  quizExecutionId,
  id,
  text,
  points,
  redoExecution
}) {
  return getUserClient()
    .post(
      getVersionedApiUrl(
        api_report_threads_edit_correction({ exerciseId, executionId })
      ),
      {
        id,
        quiz_execution_id: quizExecutionId,
        thread_message: text,
        quiz_execution_points: points,
        redo_execution: redoExecution
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_report_thread")
      );
      throw Err;
    });
}

export function askExecutionRedo({
  exerciseId,
  executionId,
  quizExecutionId,
  status
}) {
  const body = {
    quiz_execution_id: quizExecutionId,
    status
  };

  return getUserClient()
    .post(getVersionedApiUrl(api_execution_redo({ exerciseId, executionId })), {
      ...body
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_report_thread")
      );
      throw Err;
    });
}

export const reportAbuse = body => {
  return getUserClient()
    .post(getVersionedApiUrl(api_report_abuse(), "v3"), body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_submit_abuse_report")
      );
      throw Err;
    });
};
