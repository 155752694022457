import { Heading, Text, shadow } from "@arcadia/design-system";
import styled from "styled-components";

// Mobile

export const SwitchDashboardMobileHeading = styled(Heading)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const SwitchDashboardMobileButton = styled.button`
  all: unset;
  cursor: pointer;
  margin: auto;
  padding: 8px;
  margin: 8px 24px;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const SwitchDashboardMobileWrap = styled.div`
  position: sticky;
  bottom: 60px;
  left: 0;
  width: 100%;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
  box-shadow: ${shadow[500]};
`;

// Desktop

export const SwitchDashboardTitle = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor[500]};
`;

export const SwitchDashboardDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const SwitchDashboardButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
`;
