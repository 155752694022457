import React, { ChangeEvent, useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { debounce } from "lodash";
import { getLocalizedUniversityName } from "src/js/modules/universityFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { getExternalRedirectUrl } from "src/js/modules/commonFunction";
import createUrl from "src/js/modules/routing";
import { createLocalData } from "src/js/modules/storageFunction";
import { navigateToRedirectUrl } from "src/js/modules/urlFunction";
import { __LOCAL_UNIVERSITY_SAVED__ } from "src/js/settings/settingsLocalStorage";
import { __API_EXTERNAL_AUTH_PATH__ } from "src/js/settings/settingsUrl";
import { api_sso } from "src/js/repository/apiUrls";
import { navigateTo } from "src/legacy/modules/history";
import { Background } from "../components/Background";
import { UniversityFinderMobileView } from "./components/UniversityFinderMobileView";
import { UniversityFinderDesktopView } from "./components/UniversityFinderDesktopView";

import { University } from "./UniversityFinderPage.types";

const UniversityFinderPage = () => {
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState("");
  const [unis, setUnis] = useState<University[]>([]);
  const { UniversityStore, UIStore } = useStores();

  let debounceFunc = null;

  useEffect(() => {
    UniversityStore.fetchUniversityListElement();
  }, []);

  const onToggleUni = (id: string) => () => {
    if (selected === id) {
      setSelected("");
    } else {
      setSelected(id);
    }
  };

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);

    event.persist();
    if (!debounceFunc) {
      debounceFunc = debounce(() => {
        const { universityList } = UniversityStore;
        const result: University[] = universityList.filter(university =>
          getLocalizedUniversityName(
            university,
            getCurrentLanguage().substring(0, 2)
          )
            .toLowerCase()
            .includes(event.target.value && event.target.value.toLowerCase())
        );
        setUnis(result);
        if (event.target.value === "") {
          setUnis([]);
        } else {
          setUnis(result);
        }
      }, 600);
    }
    debounceFunc();
  };

  const onGoToProvider = () => {
    const redirectUrl = getExternalRedirectUrl();

    const query = {
      provider: selected,
      redirect: redirectUrl
    };
    createLocalData(
      __LOCAL_UNIVERSITY_SAVED__,
      JSON.stringify(unis.find(uni => uni.entityID === selected))
    );
    navigateToRedirectUrl(__API_EXTERNAL_AUTH_PATH__ + api_sso(), query);
  };

  const onCancel = () => {
    navigateTo(createUrl("login"));
  };

  return (
    <Background>
      {UIStore.isLayoutModeMobile ? (
        <UniversityFinderMobileView
          onChangeText={onChangeText}
          onToggleUni={onToggleUni}
          searchText={searchText}
          selected={selected}
          onGoToProvider={onGoToProvider}
          onCancelLogin={onCancel}
          unis={unis}
        />
      ) : (
        <UniversityFinderDesktopView
          onChangeText={onChangeText}
          onToggleUni={onToggleUni}
          searchText={searchText}
          selected={selected}
          onGoToProvider={onGoToProvider}
          onCancelLogin={onCancel}
          unis={unis}
        />
      )}
    </Background>
  );
};

export default UniversityFinderPage;
