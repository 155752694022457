import { observer } from "mobx-react";
import React from "react";
import { useOnboarding, useStores } from "src/js/hooks";
import { AppRouter } from "src/js/pages/App/AppRouter";
import { AuthRouter } from "src/js/pages/Auth/AuthRouter";
import { UnderageRouter } from "src/js/pages/Underage/UnderageRouter";
import { userUnderageNeedApprobation } from "src/js/UserFunction";
import { UserVerificationRouter } from "./UserVerification/UserVerificationRouter";
import { OnboardingRouter } from "./Onboarding/OnboardingRouter";

export const PagesRouter = observer(() => {
  const { UserStore } = useStores();

  if (!UserStore.activeUser) {
    return <AuthRouter />;
  }

  if (UserStore.activeUser.status !== "enabled") {
    return <UserVerificationRouter />;
  }

  if (userUnderageNeedApprobation(UserStore.activeUser)) {
    return <UnderageRouter />;
  }

  const { userRegisteredPreOnboarding } = useOnboarding();

  const onboardingCompleted =
    UserStore.activeUser.onboardingCompleted ||
    UserStore.userOnboarding.isCompleted;

  if (!onboardingCompleted && !userRegisteredPreOnboarding) {
    return <OnboardingRouter />;
  }

  return <AppRouter />;
});
