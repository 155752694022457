import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./Divider.styles";
import { DividerProps } from "./types";

const Divider: FC<DividerProps> = ({ theme, color, width, id }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.Container id={id}>
        <S.Divider color={color} width={width} />
      </S.Container>
    </ThemeProvider>
  );
};

export default Divider;
