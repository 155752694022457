import styled, { css } from "styled-components";

export const ExerciseThreadDarkBoxLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.paleGray};
  padding: 20px;
  position: relative;
  border-radius: 6px;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      flex-direction: column;
    `}

  ${({ isSelectable }) =>
    isSelectable &&
    css`
      cursor: pointer;
    `}
`;
