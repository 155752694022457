import styled, { css } from "styled-components";
import shadow from "@ds_themes/shadow";

import { SInputLabelProps } from "../Input";

import { SSearchbarWSuggestionOptionProps } from "./types";

export const SearchBarWithSuggestions = styled.input`
  width: 100%;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 13px 0;
  padding-left: 24px;
  border-radius: 50px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.searchbarWithSuggestion.background};
  border: ${({ theme }) => theme.searchbarWithSuggestion.border};
  color: ${({ theme }) => theme.searchbarWithSuggestion.text};
  font-style: ${({ theme }) => theme.searchbarWithSuggestion.font.style};
  font-weight: ${({ theme }) => theme.searchbarWithSuggestion.font.weight};
  font-size: ${({ theme }) => theme.searchbarWithSuggestion.font.size};
  line-height: ${({ theme }) => theme.searchbarWithSuggestion.font.style};

  &::placeholder {
    color: ${({ theme }) => theme.searchbarWithSuggestion.textPlaceholder};
  }
  &:focus {
    outline: ${({ theme }) => theme.searchbarWithSuggestion.focus.outline};
  }
`;
export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 15px;
  color: ${({ theme }) => theme.searchbarWithSuggestion.text};
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export const OptionContainer = styled.ul`
  position: absolute;
  top: 60px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  user-select: none;
  color: ${({ theme }) => theme.searchbarWithSuggestion.option.text};
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  border: ${({ theme }) => theme.searchbarWithSuggestion.border};
  background-color: ${({ theme }) =>
    theme.searchbarWithSuggestion.backgroundContainer};
  box-shadow: ${shadow[500]};
  border-radius: 24px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.average};
  flex-direction: column;

  /* styles to make the scroll bar customized */
  ::-webkit-scrollbar {
    width: 1.5em;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    height: 1em;
    border: 0.5em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1em;
    background-color: #c4c4c4;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const Label = styled.label<SInputLabelProps>`
  margin-bottom: 8px;
  ${({ visuallyHidden }) =>
    visuallyHidden &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      margin-bottom: 0px;
    `}
`;

export const Option = styled.li<SSearchbarWSuggestionOptionProps>`
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 30px;
  border: 0px;
  background: ${({ theme }) => theme.searchbarWithSuggestion.option.background};
  display: flex;
  align-items: center;
  justify-content: start;

  &:focus,
  &:hover {
    background: ${({ theme }) =>
      theme.searchbarWithSuggestion.focus.background};
    outline: none;
    color: ${({ theme }) => theme.searchbarWithSuggestion.focus.text};
  }
  & span {
    margin-left: 10px;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      &:focus {
        outline: none;
        background: ${theme.searchbarWithSuggestion.option.background};
        border: none;
      }
      cursor: not-allowed;
      color: ${theme.searchbarWithSuggestion.disabled.color};
      background: ${theme.searchbarWithSuggestion.option.background};
    `}
`;
