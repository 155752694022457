import {
  REACTION_TYPES,
  Reaction,
  ReactionCounter,
  ReactionCounterType,
  ReactionType
} from "src/js/types/models/Reaction";

export const getShortcodeFromType = (type: ReactionType) => {
  switch (type) {
    case REACTION_TYPES.LAUGH:
      return ":joy:";
    case REACTION_TYPES.SURPRISE:
      return ":hushed:";
    case REACTION_TYPES.STAREYES:
      return ":star-struck:";
    case REACTION_TYPES.HEART:
      return ":heart:";
    case REACTION_TYPES.ANGRY:
      return ":rage:";
    case REACTION_TYPES.SWEAT:
      return ":disappointed_relieved:";
    case REACTION_TYPES.THUMBSUP:
      return ":+1:";
    default:
      return null;
  }
};

type BaseReactionListProps = {
  reactionList: ReactionCounterType[];
  reactionType: ReactionType;
};
type UpdateReactionListProps = {
  oldReactions?: ReactionCounter;
  newReaction?: Reaction;
};

export const decreaseReactionListCounter = ({
  reactionList = [],
  reactionType
}: BaseReactionListProps): ReactionCounterType[] => {
  const auxMap = new Map(reactionList.map(r => [r.reactionType, r.count]));
  if (auxMap.get(reactionType) === 1) {
    auxMap.delete(reactionType);
  } else {
    auxMap.set(reactionType, auxMap.get(reactionType) - 1);
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  return Array.from(auxMap, ([reactionType, count]) => ({
    reactionType,
    count
  }));
};

export const increaseReactionListCounter = ({
  reactionList = [],
  reactionType
}: BaseReactionListProps): ReactionCounterType[] => {
  const auxMap = new Map(reactionList.map(r => [r.reactionType, r.count]));
  if (auxMap.has(reactionType)) {
    auxMap.set(reactionType, auxMap.get(reactionType) + 1);
  } else {
    auxMap.set(reactionType, 1);
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  return Array.from(auxMap, ([reactionType, count]) => ({
    reactionType,
    count
  }));
};

// If newReaction === null id will only decrease
export const updateReactionList = ({
  oldReactions = null,
  newReaction
}: UpdateReactionListProps) => {
  const { reactions, total = 0, reactionLoggedUser } = oldReactions || {};
  let updatedUserReaction = reactionLoggedUser;
  let updatedList = reactions;
  let updatedTotal = total;

  if (reactionLoggedUser) {
    updatedUserReaction = null;
    updatedList = decreaseReactionListCounter({
      reactionList: reactions,
      reactionType: reactionLoggedUser.reactionType
    });
    if (total > 1) {
      updatedTotal = total - 1;
    } else {
      updatedTotal = 0;
    }
  }

  if (newReaction) {
    updatedUserReaction = newReaction;

    updatedList = increaseReactionListCounter({
      reactionList: updatedList,
      reactionType: newReaction.reactionType
    });

    updatedTotal += 1;
  }

  return {
    reactions: updatedList,
    reactionLoggedUser: updatedUserReaction,
    total: updatedTotal
  };
};
