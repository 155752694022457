import { __NO_FILTERED_NOTIFICATIONS_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import styled from "styled-components";

export const NoNotificationsContainer = styled.div`
  padding: 64px 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
`;
export const NoNotificationsImage = styled.div`
  height: 100px;
  width: 264px;
  background-size: contain;
  background-image: url(${__NO_FILTERED_NOTIFICATIONS_PLACEHOLDER__});
  background-repeat: no-repeat;
  background-position: center;
`;

export const NoNotificationsTextContainer = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  text-align: center;
  margin-top: 16px;
`;
