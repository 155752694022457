import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import it from "date-fns/locale/it";
import fr from "date-fns/locale/fr";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTheme } from "styled-components";
import { Input } from "@arcadia/design-system";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __DEFAULT_LANGUAGE_COUNTRY__ } from "src/js/settings/settingsLocalization";
import { useTranslation } from "src/js/translation";
import * as S from "./DatePickerInput.styles";

/**
 * Taken from Analytics Calendar, consider merging both in a single generic component
 */
const DatePickerInput = ({
  id = "date-picker-input",
  selectedDate,
  onChange = () => {},
  label,
  minimumDate = null,
  hasError = false,
  errorMessage = "",
  calendarStartDay = 0
}) => {
  const [internalDate, setInternalDate] = useState(selectedDate);
  const { whiteTheme, colors, fontFamily, primaryColor } = useTheme();
  const { translate } = useTranslation();

  useEffect(() => {
    setInternalDate(selectedDate);
  }, [selectedDate]);

  const onSelectingDate = date => {
    setInternalDate(date);
    onChange(date);
  };

  switch (getCurrentLanguage()) {
    case __DEFAULT_LANGUAGE_COUNTRY__.it:
      registerLocale("locale", it);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.en:
      registerLocale("locale", en);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.es:
      registerLocale("locale", es);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.fr:
      registerLocale("locale", fr);
      break;
    default:
      registerLocale("locale", it);
  }

  const enabledDates = date => {
    if (minimumDate && minimumDate instanceof Date) {
      return date.getTime() >= minimumDate.getTime();
    }
    return true;
  };

  return (
    <S.DatePickerInputContainer
      theme={{
        colors,
        datepicker: whiteTheme.datepicker,
        fontFamily,
        primaryColor
      }}
    >
      <DatePicker
        calendarStartDay={calendarStartDay}
        locale="locale"
        popperPlacement="bottom-start"
        selected={internalDate}
        onChange={onSelectingDate}
        monthsShown={1}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        filterDate={enabledDates}
        customInput={
          <Input
            id={id}
            value={internalDate}
            icon="calendar"
            label={translate(label)}
            error={{ message: hasError ? translate(errorMessage) : null }}
            theme={whiteTheme}
            name="datepicker-input"
            rounded
          />
        }
        dateFormat="dd-MM-yyyy"
      />
    </S.DatePickerInputContainer>
  );
};

export default DatePickerInput;
