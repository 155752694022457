import { Icon } from "@arcadia/design-system";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useResourcePickerErrors } from "src/js/components/ResourcePicker/providers/ResourcePickerErrorProvider";
import { showToastError } from "src/js/modules/messageManager";
import {
  __SCORM_UPLOAD_STANDARD_LIMITS__,
  __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__
} from "src/js/settings/settingFileUploader";
import { useTranslation } from "src/js/translation";
import { RESOURCE_PICKER_SECTION } from "../ResourcePicker/ResourcePicker.const";
import * as S from "./FileUploader.styles";
import { FileType, FileUploaderProps } from "./FileUploader.types";
import { removeDuplicatesFromArray } from "./FileUploader.utils";
import { useFileType, useFileUpload } from "./hooks";

const FileUploader = ({
  type = FileType.FILE,
  multiple = true,
  section,
  prepareModuleResourcePayload,
  onClose
}: FileUploaderProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const { mimes } = useFileType(type);
  const { files, handleDragOver, handleDrop, handleInputChange } =
    useFileUpload({ multiple });
  const { translate } = useTranslation();
  const { errors, setErrors } = useResourcePickerErrors();

  const checkMimeType = (fileList: FileList) => {
    let ers: string[] = [];

    if (mimes === "*") {
      return ers;
    }

    Array.from(fileList).forEach(file => {
      if (
        Array.isArray(mimes) &&
        mimes.every(mimeType => file.type !== mimeType)
      ) {
        ers = [...errors, translate("file_extension_unsupported_error")];
        setErrors(removeDuplicatesFromArray(ers));
      }
    });

    return ers;
  };

  const checkFileSize = (fileList: FileList) => {
    let ers: string[] = [];
    const isScorm = type === FileType.SCORM;
    Array.from(fileList).forEach(file => {
      if (
        (isScorm && file.size > __SCORM_UPLOAD_STANDARD_LIMITS__) ||
        (!isScorm && file.size > __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__)
      ) {
        ers = [...ers, file.name];
      }
    });

    return ers;
  };

  const accept = useMemo(() => {
    if (mimes !== "*") {
      return (mimes as unknown as string[]).join(",");
    }
    return mimes;
  }, [mimes]);

  const handleOpenFilePicker = useCallback(() => {
    if (inputRef) {
      inputRef.current.click();
    }
  }, [inputRef]);

  useEffect(() => {
    if (files) {
      const isScorm = type === FileType.SCORM;
      if (
        section === RESOURCE_PICKER_SECTION.MODULES ||
        section === RESOURCE_PICKER_SECTION.CHAT ||
        section === RESOURCE_PICKER_SECTION.WALL ||
        section === RESOURCE_PICKER_SECTION.EXERCISE
      ) {
        const largeFiles = checkFileSize(files);
        const hasFileMimeErrors = checkMimeType(files);
        if (hasFileMimeErrors && hasFileMimeErrors.length) return;
        if (largeFiles.length) {
          largeFiles.map(fileName =>
            showToastError({
              str: translate("file_drag_drop_size_error", {
                fileName
              })
            })
          );
        }
        if (onClose) onClose();
        prepareModuleResourcePayload(files, isScorm);
      }
    }
  }, [files]);

  return (
    <S.FileUploadWrapper
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleOpenFilePicker}
    >
      <S.TextWrapper>
        <Icon icon="arrowUp" width={32} height={32} />
        <S.UploadMessage type="formTitle">
          {translate("file_upload_input_message")}
        </S.UploadMessage>
      </S.TextWrapper>
      <S.HiddenInput
        ref={inputRef}
        type="file"
        hidden
        multiple={multiple}
        accept={accept}
        onChange={handleInputChange}
      />
    </S.FileUploadWrapper>
  );
};

export default FileUploader;
