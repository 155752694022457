import { Button } from "@arcadia/design-system";
import React from "react";
import { observer } from "mobx-react";
import teacher_1 from "Images/teacher-1.png";
import teacher_2 from "Images/teacher-2.png";
import teacher_3 from "Images/teacher-3.png";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import ClaimModal from "../layout/ClaimModal/ClaimModal";

import * as S from "./ClaimableSpaceCard.styles";

/**
 * @name ClaimableSpaceCard
 *
 * @description ClaimableSpaceCard component.
 */

const ClaimableSpaceCard = () => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    SpaceStore,
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { isClaimable } = SpaceStore;

  if (!isClaimable) {
    return null;
  }

  const openClaimModal = () => {
    openModal(() => <ClaimModal close={closeModal} />);
  };

  return (
    <S.ClaimableSpaceCardContainer>
      <S.FirstRow>
        <S.Teachers>
          <S.FirstTeacher
            src={teacher_1}
            alt={translate("claimable_space_card_alt_teacher_1")}
          />
          <S.SecondTeacher
            src={teacher_2}
            alt={translate("claimable_space_card_alt_teacher_2")}
          />
          <S.ThirdTeacher
            src={teacher_3}
            alt={translate("claimable_space_card_alt_teacher_3")}
          />
        </S.Teachers>
        <S.ClaimableText>
          {translate("claimable_space_card_text")}
        </S.ClaimableText>
      </S.FirstRow>
      <S.SecondRow>
        <Button
          theme={whiteTheme}
          variant="primary"
          fullWidth
          onClick={openClaimModal}
        >
          {translate("claimable_space_card_cta")}
        </Button>
      </S.SecondRow>
    </S.ClaimableSpaceCardContainer>
  );
};

export default observer(ClaimableSpaceCard);
