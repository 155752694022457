import {
  MutationFunction,
  UseMutationResult,
  useMutation as useMutationDefault,
  useQueryClient
} from "@tanstack/react-query";
import { UseMutationOptions } from "./types";

const useMutation = <
  TData = unknown,
  TError extends Error = Error,
  TVariables = void,
  TContext = unknown,
  TUpdateData = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext, TUpdateData>
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const queryClient = useQueryClient();
  const {
    onUpdateData,
    updateQueryKey: updateQueryKeyOrFn,
    ...otherOptions
  } = options || {};

  const getUpdateQueryKey = (variables: TVariables) => {
    if (typeof updateQueryKeyOrFn === "function") {
      return updateQueryKeyOrFn(variables);
    }

    return updateQueryKeyOrFn;
  };

  return useMutationDefault(mutationFn, {
    onError(err, variables, context) {
      const updateQueryKey = getUpdateQueryKey(variables);

      if (updateQueryKey) {
        queryClient.setQueryData(updateQueryKey, context);
      }
    },
    onMutate(variables) {
      let previousData: TData | undefined;
      const updateQueryKey = getUpdateQueryKey(variables);

      if (updateQueryKey) {
        previousData = queryClient.getQueryData<TData | undefined>(
          updateQueryKey
        );

        queryClient.setQueryData<TUpdateData | undefined>(
          updateQueryKey,
          old => {
            if (old) {
              return onUpdateData ? onUpdateData(old, variables) : old;
            }

            queryClient.removeQueries(updateQueryKey);
            return undefined;
          }
        );
      }

      return previousData as unknown as TContext;
    },
    ...otherOptions
  });
};

export default useMutation;
