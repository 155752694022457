import { useState } from "react";
import { useStores } from "src/js/hooks";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { fetchUserSSOProvider } from "src/js/repository/userRepository";
import { EventDomain, UserEvent } from "src/js/types";
import { useMutation } from "src/js/query/hooks";
import { LoginForm } from "./LoginPage.types";

export const useLogin = () => {
  const { AuthStore } = useStores();
  const { translate } = useTranslation();
  const [loginError, setLoginErrors] = useState<string>(null);
  const { mutate: onLogin } = useMutation(
    async (data: LoginForm) => {
      const { username, password } = data;
      _trackEvent(EventDomain.User, UserEvent.ManualLogin);
      await AuthStore.loginUser(username, password);
    },
    {
      onError(error) {
        setLoginErrors(translate(error.message as TranslationKeys));
      }
    }
  );

  const getUserSSOProvider = async (email: string) => {
    const response = await fetchUserSSOProvider(email);
    return response;
  };

  return { onLogin, getUserSSOProvider, setLoginErrors, loginError };
};
