import Semaphore from "semaphore-async-await";
import axios from "axios";
import { __CLIENT_ID__ } from "src/js/settings/settingsGeneral";
import { __API_AUTH_PATH__ } from "src/js/settings/settingsUrl";

const lock = new Semaphore(1);

/**
 * A lock semaphore is needed because if multiple calls are stalling
 * many simultaneous requests of getting the refresh token from BE api will cause
 * a BE 401 error in one of these.
 * Having the semaphore will make the calls one after the other, preventing an unwanted 401 error
 */
export const commonRefreshToken = () =>
  lock.execute(() =>
    axios({
      withCredentials: true,
      method: "post",
      url: __API_AUTH_PATH__,
      data: {
        client_id: __CLIENT_ID__,
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }).then(res => res.status)
  );
