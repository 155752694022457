import React from "react";
import { Link } from "@arcadia/design-system";
import styled, { useTheme } from "styled-components";
import { createStaticUrl } from "src/js/modules/routing";
import { Translate } from "src/js/translation";
import { SearchResponseItem } from "src/js/types";

const StyledLinkWrapper = styled.div`
  margin-bottom: 4px;

  a:first-of-type {
    padding-left: 0px;
  }
`;

const SearchResultBreadcrumb = ({ item }: { item: SearchResponseItem }) => {
  const { greyTheme } = useTheme();

  switch (item.type) {
    case "post":
    case "post-comment":
    case "post-comment-reply":
    case "post-resource":
      return (
        <StyledLinkWrapper>
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("wall", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            {item.groupName}
          </Link>
          {">"}
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("wall", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            <Translate text="bacheca" />
          </Link>
        </StyledLinkWrapper>
      );
    case "board":
      return (
        <StyledLinkWrapper>
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("wall", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            {item.groupName}
          </Link>
          {">"}
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("modules", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            <Translate text="boards" />
          </Link>
        </StyledLinkWrapper>
      );
    case "board-element":
    case "board-element-comment":
    case "board-element-comment-reply":
      return (
        <StyledLinkWrapper>
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("wall", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            {item.groupName}
          </Link>
          {">"}
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("modules", {
              group_id: item.groupId,
              space_slug: item.spaceSlug
            })}`}
          >
            <Translate text="boards" />
          </Link>
          &#62;&nbsp;
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("module_detail", {
              space_slug: item.spaceSlug,
              group_id: item.groupId,
              module_id: item.boardId
            })}`}
          >
            {item.boardName}
          </Link>
        </StyledLinkWrapper>
      );
    case "exercise":
      return (
        <StyledLinkWrapper>
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("wall", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            {item.groupName}
          </Link>
          {">"}
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("exercises", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            <Translate text="esercizi" />
          </Link>
        </StyledLinkWrapper>
      );
    default:
      return (
        <StyledLinkWrapper>
          <Link
            theme={greyTheme}
            href={`#${createStaticUrl("wall", {
              space_slug: item.spaceSlug,
              group_id: item.groupId
            })}`}
          >
            Group &#62;
          </Link>
        </StyledLinkWrapper>
      );
  }
};

export default SearchResultBreadcrumb;
