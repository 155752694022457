import {
  Avatar,
  Text as WSText,
  skeletonAnimationMixin
} from "@arcadia/design-system";
import { getPillColorFromType } from "src/js/pages/App/Spaces/NotificationsPage/NotificationPage.utils";
import { NotificationAreaLabelModel } from "src/js/types/models/Notification";
import styled from "styled-components";

export const NotificationWrapper = styled.div<{ read?: boolean }>`
  padding: 12px 16px;
  background-color: ${({ theme, read = true }) =>
    read ? theme.colors.grey[1000] : theme.primaryColor[900]};
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  min-height: 100px;
  cursor: pointer;
  user-select: none;
`;

export const NotificationSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
`;

export const AvatarContainer = styled.div`
  object-fit: cover;
  width: 56px;
  height: 56px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor[700]};
`;

export const AvatarContainerSkeleton = styled(AvatarContainer)`
  background-color: transparent;
  ${({ theme }) =>
    skeletonAnimationMixin(
      theme.colors.blueGrey[900],
      theme.primaryColor[800]
    )};
`;

export const NotificationTextSection = styled.div`
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
`;

export const NotificationTextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
  max-width: 232px;
  height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 18px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const NotificationTextSkeleton = styled.div<{ width: number }>`
  height: 12px;
  width: ${({ width }) => width}px;
  border-radius: 4px;
  ${({ theme }) =>
    skeletonAnimationMixin(
      theme.colors.blueGrey[900],
      theme.primaryColor[800]
    )};
`;

export const LargeText = styled(WSText)`
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const SmallText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const AvatarImage = styled(Avatar)`
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  width: 56px;
  height: 56px;
`;

export const NotificationType = styled.div<{
  area: NotificationAreaLabelModel;
}>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[1000]};
  background-color: ${({ area }) => getPillColorFromType(area)};
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const TimeRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor[700]};
`;

export const MarkReadButton = styled.button`
  color: ${({ theme }) => theme.primaryColor[500]};
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  padding: 0px;
  text-align: center;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  align-self: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;
