import styled from "styled-components";

export const Container = styled.div`
  display: block;
  position: relative;
`;

export const Label = styled.label`
  all: unset;
  margin-bottom: 8px;
  display: block;
  color: ${({ theme }) => theme.colors.grey[50]};
`;
