import { IconName } from "@arcadia/design-system";
import resourcePlaceholder from "Images/placeholders/resource_placeholder.png";
import { getMimeTypeString } from "src/js/modules/fileFunction";
import { useTranslation } from "src/js/translation";
import {
  RESOURCE_DEPRECATED_TYPE,
  RESOURCE_TYPE,
  ResourceType
} from "src/js/types";
import { updateResourceThumbnail } from "src/js/repository/resourceRepository";
import { useStores } from "src/js/hooks";
import { useSetBoard, useActiveBoard } from "src/js/context/BoardProvider";

const useResourcePreviewDetails = () => {
  const { translate } = useTranslation();
  const {
    ModalStore: { closeModal }
  } = useStores();
  const saveBoard = useSetBoard();
  const activeBoard = useActiveBoard();

  const getResourcePreview = (
    hasPreviewGenerated: boolean,
    resourceImage: string
  ) => {
    if (hasPreviewGenerated) return resourceImage;
    return resourcePlaceholder;
  };

  const getMimeTypeIcon = (mimeType: string): IconName => {
    const type = getMimeTypeString({ mimeType });
    switch (type) {
      case "video":
        return "playCircle";
      case "image":
        return "image";
      default:
        return "desktop";
    }
  };

  const getResourcePlaceholderIcon = (
    resourceType: ResourceType,
    mimeType: string
  ): IconName => {
    switch (resourceType) {
      case RESOURCE_TYPE.TEXT:
        return "document";
      case RESOURCE_TYPE.EXERCISE:
        return "weight";
      case RESOURCE_TYPE.FILE:
        return getMimeTypeIcon(mimeType);
      case RESOURCE_TYPE.SCORM:
        return "folderZip";
      case RESOURCE_TYPE.SPREADSHEET:
      case RESOURCE_TYPE.SLIDESHOW:
      case RESOURCE_TYPE.DOCUMENT:
        return "desktop";
      case RESOURCE_TYPE.GOOGLE_DRIVE:
      case RESOURCE_TYPE.DROPBOX:
      case RESOURCE_TYPE.LINK:
        return "link";
      case RESOURCE_TYPE.IMAGE_GALLERY:
        return "image";
      default:
        return null;
    }
  };

  const getResourceTypeLabel = (
    resourceType: ResourceType,
    mimeType: string
  ) => {
    const fileMimeType = getMimeTypeString({ mimeType });
    const getTranslatedFileMimeType = () => {
      if (fileMimeType === "File") {
        return translate("resource_type_label_file");
      }
      if (fileMimeType === "image") {
        return translate("resource_type_label_image");
      }
      if (fileMimeType === "video") {
        return translate("resource_type_label_video");
      }
      return fileMimeType;
    };
    switch (resourceType) {
      case RESOURCE_TYPE.TEXT:
        return translate("resource_type_label_text");
      case RESOURCE_TYPE.EXERCISE:
        return translate("resource_type_label_exercise");
      case RESOURCE_TYPE.SCORM:
        return translate("resource_type_label_scorm");
      case RESOURCE_TYPE.FILE:
      case RESOURCE_DEPRECATED_TYPE.SPREADSHEET:
      case RESOURCE_DEPRECATED_TYPE.DOCUMENT:
      case RESOURCE_DEPRECATED_TYPE.SLIDESHOW:
        return getTranslatedFileMimeType();
      case RESOURCE_TYPE.GOOGLE_DRIVE:
        return "Google Drive";
      case RESOURCE_TYPE.DROPBOX:
        return "Dropbox";
      case RESOURCE_TYPE.LINK:
      case RESOURCE_TYPE.YOUTUBE:
      case RESOURCE_TYPE.VIMEO:
      case RESOURCE_DEPRECATED_TYPE.MAPS:
      case RESOURCE_DEPRECATED_TYPE.ONEDRIVE:
      case RESOURCE_DEPRECATED_TYPE.GOOGLE_DRIVE:
        return translate("resource_type_label_link");
      case RESOURCE_TYPE.IMAGE_GALLERY:
        return translate("resource_type_label_gallery");
      case RESOURCE_DEPRECATED_TYPE.DRAWING:
        return translate("resource_type_label_file");
      default:
        return null;
    }
  };

  const shouldDisplayIconAsResourceLabel = (
    resourceType: ResourceType,
    mimeType: string,
    resourceUrl: string
  ) => {
    if (
      resourceType === RESOURCE_TYPE.FILE ||
      resourceType === RESOURCE_TYPE.DOCUMENT ||
      resourceType === RESOURCE_TYPE.SLIDESHOW ||
      resourceType === RESOURCE_TYPE.SPREADSHEET
    ) {
      const type = getMimeTypeString({ mimeType });
      if (type === "doc" || type === "ppt" || type === "xls") {
        return true;
      }
    } else if (resourceType === RESOURCE_TYPE.LINK) {
      if (
        resourceUrl.includes("youtube") ||
        resourceUrl.includes("youtu.be") ||
        resourceUrl.includes("vimeo") ||
        resourceUrl.includes("docs.google")
      ) {
        return true;
      }
      return false;
    } else if (
      resourceType === RESOURCE_TYPE.VIMEO ||
      resourceType === RESOURCE_TYPE.YOUTUBE
    ) {
      return true;
    }
    return false;
  };

  const getResourceMimeTypeIcon = (
    resourceType: ResourceType,
    mimeType: string,
    resourceUrl: string
  ): IconName => {
    switch (resourceType) {
      case RESOURCE_TYPE.VIMEO:
        return "picker-vimeo-single-char";
      case RESOURCE_TYPE.YOUTUBE:
        return "picker-youtube";
      case RESOURCE_DEPRECATED_TYPE.SPREADSHEET:
      case RESOURCE_DEPRECATED_TYPE.DOCUMENT:
      case RESOURCE_DEPRECATED_TYPE.SLIDESHOW:
      case RESOURCE_TYPE.FILE: {
        const type = getMimeTypeString({ mimeType });
        switch (type) {
          case "doc":
            return "picker-word";
          case "xls":
            return "picker-excel";
          case "ppt":
            return "picker-powerpoint";
          default:
            return null;
        }
      }
      case RESOURCE_TYPE.LINK: {
        if (
          resourceUrl.includes("youtube") ||
          resourceUrl.includes("youtu.be")
        ) {
          return "picker-youtube";
        }
        if (resourceUrl.includes("vimeo")) {
          return "picker-vimeo-single-char";
        }
        if (resourceUrl.includes("docs.google")) {
          if (resourceUrl.includes("document")) {
            return "picker-googledoc";
          }
          if (resourceUrl.includes("spreadsheet")) {
            return "picker-googlesheet";
          }
          if (resourceUrl.includes("presentation")) {
            return "picker-googleslides";
          }
          if (resourceUrl.includes("forms")) {
            return "picker-googleforms";
          }
        }
        return null;
      }
      default:
        return null;
    }
  };

  const saveNewCroppedImage = (
    newCroppedImage: File,
    setButtonDisabled: (isDisabled: boolean) => void,
    moduleId: number,
    resourceId: number
  ) => {
    setButtonDisabled(true);
    updateResourceThumbnail(newCroppedImage, moduleId, resourceId)
      .then(() => {
        setButtonDisabled(false);
        closeModal();
        saveBoard(activeBoard.id, true)();
      })
      .catch(() => setButtonDisabled(false));
  };

  return {
    getMimeTypeIcon,
    getResourcePreview,
    getResourcePlaceholderIcon,
    getResourceTypeLabel,
    shouldDisplayIconAsResourceLabel,
    getResourceMimeTypeIcon,
    saveNewCroppedImage
  };
};

export default useResourcePreviewDetails;
