export const AIGenerationRequestEnum = {
  course: "course",
  modules: "modules",
  content: "content",
  tipTapAuth: "tipTapAuth"
} as const;

export type AIGenerationRequest = keyof typeof AIGenerationRequestEnum;

export type CourseReqBody = {
  groupId: number;
  userUuid: string;
  modules: {
    number: number;
    title: string;
    description: string;
  }[];
};

export type ModulesReqBody = {
  modules: {
    target: string;
    topic: string;
    numberOfModules: number;
    hoursForModules: number;
    type: string;
  };
};

export type ContentReqBody = {
  subject: string;
  message: string;
};

export type AIModule = {
  number: number;
  title: string;
  description: string;
};

export type CourseResponse = {
  result: string;
};

export type ModulesResponse = {
  modules: AIModule[];
};

export type ContentResponse = {
  content: string;
};

export type AIRequest = {
  [AIGenerationRequestEnum.course]: {
    request: CourseReqBody;
    response: CourseResponse;
  };
  [AIGenerationRequestEnum.modules]: {
    request: ModulesReqBody;
    response: ModulesResponse;
  };
  [AIGenerationRequestEnum.content]: {
    request: ContentReqBody;
    response: ContentResponse;
  };
  [AIGenerationRequestEnum.tipTapAuth]: {
    request: Record<string, unknown>;
    response: { jwt: string };
  };
};
