export const ROLE_FILTER_OPTIONS = [
  {
    key: "teacher",
    value: "registration_role_teacher",
    filter_family: "role"
  },
  {
    key: "student",
    value: "registration_role_student",
    filter_family: "role"
  }
];

export const SORT_FILTER_OPTIONS = [
  {
    key: "name_asc",
    value: "name_ascending",
    filter_family: "ascending"
  },
  {
    key: "name_desc",
    value: "name_descending",
    filter_family: "descending"
  },
  {
    key: "birthday_desc",
    value: "birth_date_descending",
    filter_family: "descending"
  },
  {
    key: "birthday_asc",
    value: "birth_date_ascending",
    filter_family: "ascending"
  },
  {
    key: "created_desc",
    value: "registration_date_descending",
    filter_family: "descending"
  },
  {
    key: "created_asc",
    value: "registration_date_ascending",
    filter_family: "ascending"
  }
];

export const NO_GROUPS_FILTER_OPTION = { key: 0, value: "no_groups" };
