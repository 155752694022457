import React, { useEffect, useState } from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import {
  Box,
  DoodleLoader,
  ModalSizeType,
  SearchBar,
  Text
} from "@arcadia/design-system";
import { useDebounce, useInfiniteScrollFetcher, useStores } from "src/js/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import { observer } from "mobx-react";
import { fetchBadgeRelatedGroups } from "src/js/repository/badgesRepository";
import { BadgeRelatedGroupType } from "src/js/types";
import { groupGetCover } from "src/js/GroupFunction";
import handleThumbnails from "src/js/modules/imageFunction";
import { NoResults } from "src/js/components/global/NoResults";
import SpaceBadgeAssociatedGroup from "./SpaceBadgeAssociatedGroup";
import * as S from "./SpaceBadgeAssociatedGroupsModal.styles";
import { BadgeRelatedGroupsFetchParams } from "../AssociateBadgeToGroups/types";

type SpaceBadgeAssociatedGroupsModalProps = {
  closeModal: () => void;
  badgeId: string;
};

const SpaceBadgeAssociatedGroupsModal = ({
  closeModal,
  badgeId
}: SpaceBadgeAssociatedGroupsModalProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const [filterString, setFilterString] = useState("");
  const [loading, setLoading] = useState(true);
  const filterValueDebounced = useDebounce(filterString, 300);
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    data: badgeRelatedGroups = [],
    hasMoreResults,
    fetch,
    reset: resetResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    BadgeRelatedGroupType[],
    BadgeRelatedGroupsFetchParams
  >(
    async ({
      limit,
      page: _page,
      showUnassociated,
      badgeId: _badgeId,
      textSearch
    }) => {
      const data = await fetchBadgeRelatedGroups({
        badgeId: _badgeId,
        textSearch,
        showUnassociated,
        limit,
        offset: _page * limit
      });
      return data.results;
    },
    { lazy: true, limit: 10 }
  );

  useEffect(() => {
    setLoading(true);
    fetch(0, {
      badgeId,
      textSearch: filterValueDebounced,
      showUnassociated: false
    }).then(() => {
      setLoading(false);
    });
    return () => resetResults();
  }, [filterValueDebounced]);

  let modalContent: React.ReactNode;

  if (loading) {
    modalContent = <DoodleLoader theme={greyTheme} isMini />;
  } else if (badgeRelatedGroups.length > 0) {
    modalContent = badgeRelatedGroups.map(group => {
      return (
        <SpaceBadgeAssociatedGroup
          key={group.id}
          name={group.name}
          cover={handleThumbnails(groupGetCover(group), "small")}
        />
      );
    });
  } else {
    modalContent = (
      <Box marginTop={64} marginBottom={64}>
        <NoResults
          title={translate(
            "dashboard_badges_associated_group_modal_noresult_title"
          )}
          subtitle={translate(
            "dashboard_badges_associated_group_modal_noresult_text"
          )}
        />
      </Box>
    );
  }

  return (
    <S.CustomModalLayout
      closeModal={closeModal}
      labels={{
        title: translate("dashboard_badges_associated_group_modal_title"),
        mobileTitle: translate("dashboard_badges_associated_group_modal_title"),
        mobileClose: translate("close")
      }}
      desktopProps={{ hasAdaptiveHeight: true, size: ModalSizeType.Normal }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false
      }}
    >
      <Box id="modal-groups-associated-container">
        <S.SearchBarWrapper>
          <SearchBar
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            label="searchQuery"
            value={filterString}
            onChange={event => {
              setFilterString(event.target.value);
            }}
            placeholder={translate(
              "dashboard_badges_associated_group_search_placeholder"
            )}
          />
        </S.SearchBarWrapper>
        <Box
          id="scroll-area-container"
          minHeight={400}
          maxHeight={400}
          overflow="auto"
          marginBottom="auto"
        >
          <S.ScrollableArea id="modal-associated-groups-list">
            <InfiniteScroll
              scrollableTarget="scroll-area-container"
              next={() => {
                fetchNextPage({
                  badgeId,
                  textSearch: filterValueDebounced,
                  showUnassociated: false
                });
              }}
              dataLength={badgeRelatedGroups.length}
              hasMore={hasMoreResults}
              loader={<DoodleLoader theme={whiteTheme} isMini />}
            >
              {badgeRelatedGroups.length ? (
                <Box paddingLeft={16} marginBottom={12}>
                  <Text type="label">
                    {translate("dashboard_badges_associated_group_list_label")}
                  </Text>
                </Box>
              ) : null}
              <S.ListWrapper>{modalContent}</S.ListWrapper>
            </InfiniteScroll>
          </S.ScrollableArea>
        </Box>
      </Box>
    </S.CustomModalLayout>
  );
};

export default observer(SpaceBadgeAssociatedGroupsModal);
