import { differenceInDays } from "date-fns";
import { DashboardAggregation, DashboardAggregationType } from "src/js/types";

export const fakeTrainingHoursResponse = (
  fromDate: string,
  toDate: string,
  aggregation: DashboardAggregationType
) => {
  const results = [];
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  const days = differenceInDays(endDate, startDate);
  const multiplier = aggregation === DashboardAggregation.Week ? 7 : 1;

  for (let i = 0; i <= days / multiplier; i += 1) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i * multiplier);
    const label = currentDate.toISOString().split("T")[0];
    const value = Math.floor(Math.random() * 1000); // Random value between 0 and 999
    results.push({ label, value });
  }

  return {
    averageNumber: 350,
    averageNumberTrend: -150,
    results,
    totalActiveUsers: 112
  };
};
