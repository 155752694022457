import { Box, Button } from "@arcadia/design-system";
import React from "react";
import { SpaceSchoolCard } from "src/js/components/SpaceCards/SpaceSchoolCard";
import { SpaceSchoolCardVariant } from "src/js/components/SpaceCards/SpaceSchoolCard/SpaceSchoolCard.types";
import { showToastError } from "src/js/modules/messageManager";
import {
  createSpace,
  joinUnclaimedSpace
} from "src/js/repository/spacesRepository";
import { SpaceCreationPayload } from "src/js/repository/types";
import { useTranslation } from "src/js/translation";
import { SchoolClaimFormStatus } from "../SchoolClaimForm.types";
import * as S from "./SchoolClaimSelected.styles";
import { SchoolClaimSelectedProps } from "./SchoolClaimSelected.types";

const SchoolClaimSelected = ({
  theme,
  status,
  spaceSelected,
  onSuccessCallback
}: SchoolClaimSelectedProps) => {
  const { translate } = useTranslation();

  const isClaimed = status === SchoolClaimFormStatus.ClaimedSpace;
  const textLabel = isClaimed
    ? "school_claim_message_cannot_joined"
    : "school_claim_message_recap";

  const join = () => {
    const callJoin =
      status === SchoolClaimFormStatus.NewSpace
        ? createSpace(spaceSelected as unknown as SpaceCreationPayload)
        : joinUnclaimedSpace(spaceSelected?.id);

    callJoin
      .then(newSpace => onSuccessCallback(newSpace))
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  return (
    <S.SchoolClaimSelectedWrapper>
      <Box marginTop="auto" marginBottom="auto">
        <S.StyledText>{translate(textLabel)}</S.StyledText>
        <SpaceSchoolCard
          spaceName={spaceSelected?.name}
          address={spaceSelected?.address}
          color={spaceSelected?.defaultBadgeColor}
          url={spaceSelected?.badge?.small as string}
          variant={
            isClaimed
              ? SpaceSchoolCardVariant.Claimed
              : SpaceSchoolCardVariant.Selected
          }
        />
      </Box>
      <S.InputSubmitWrapper>
        <Button
          disabled={isClaimed}
          type="button"
          onClick={join}
          theme={theme}
          variant="primary"
        >
          {translate("school_claim_cta_join_space")}
        </Button>
      </S.InputSubmitWrapper>
    </S.SchoolClaimSelectedWrapper>
  );
};

export default SchoolClaimSelected;
