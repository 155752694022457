import { mobileMixin, tabletMixin } from "src/js/theme/breakpointsMixin";
import styled from "styled-components";

export const BoardPathLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.accent7};
  font-family: ${({ theme }) => theme.fontFamily.title};
  color: ${({ theme }) => theme.colors.basicBlack};
  width: 440px;
  box-shadow: ${({ theme }) => theme.shadows.modals};

  ${mobileMixin`
    border: none;
    box-shadow: none;
    width: 100%;
  `}
  ${tabletMixin`
    width: 100%;
  `}
`;

export const BoardPathHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: 60px;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent7};
  padding: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const BoardPathHeaderTitle = styled.div`
  display: flex;
`;

export const ModuleTitle = styled.div`
  text-decoration: underline;
`;

export const HeaderIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
  margin-left: 16px;
`;

export const BoardPathBody = styled.div`
  padding: 16px 16px 0;
`;

export const PrerequisiteMenuItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;

  & > li {
    margin-bottom: 16px;
  }

  overflow-y: auto;
  position: relative;
  height: 100%;
  max-height: 200px;
`;

export const ToggleAction = styled.button`
  background: none;
  border: none;
  padding: 8px 0 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  & > svg {
    margin-left: 16px;
  }
`;
