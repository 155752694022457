import getUserClient from "src/js/modules/connection";
import {
  api_groups_notification_settings,
  api_space_notification_settings,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import {
  AppPreference,
  EmailPreference,
  NotificationSettingsModel
} from "../types/models/Notification";

export const updateSpaceNotificationSettings = ({
  spaceId,
  appPreference,
  emailPreference
}: {
  spaceId: string;
  emailPreference?: EmailPreference;
  appPreference?: AppPreference;
}) => {
  const body = appPreference ? { appPreference } : { emailPreference };

  return getUserClient()
    .patch<NotificationSettingsModel>(
      getVersionedApiUrl(api_space_notification_settings({ spaceId }), "v3"),
      body
    )
    .then(response => {
      return response.data;
    });
};

export const updateGroupNotificationSettings = ({
  groupId,
  appPreference,
  emailPreference
}: {
  groupId: number;
} & NotificationSettingsModel) => {
  const body = {
    appPreference,
    emailPreference
  };
  return getUserClient()
    .patch<NotificationSettingsModel>(
      getVersionedApiUrl(api_groups_notification_settings({ groupId }), "v3"),
      body
    )
    .then(response => {
      return response.data;
    });
};
