/* eslint-disable react/no-danger */
import { Box, Button, Input, Text } from "@arcadia/design-system";
import { format, subYears } from "date-fns";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Calendar } from "src/js/components/Calendar";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { trimValue } from "src/js/modules/commonFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __FORM_TUTOR__ } from "src/js/settings/settingsFormValidation";
import { TranslationKeys } from "src/js/translation";
import {
  Translate,
  translateString,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { Terms } from "../Terms";
import { FormTypes, RegistrationFormData } from "../types";

import * as S from "../Registration.styles";

function setupData(data: RegistrationFormData, token: string, terms: boolean) {
  const tutorData: RegistrationFormData = {};
  tutorData.name = data.name;
  tutorData.surname = data.surname;
  tutorData.email = data.email;
  tutorData.language = getCurrentLanguage();
  tutorData.birthday = data.birthday;

  return {
    tutor: tutorData,
    terms: terms || false,
    customTerms: !!data?.terms4,
    weschoolContact: data.weschool_contact || false,
    thirdPartContact: data.third_part_contact || false,
    inviteToken: token
  };
}

// TODO: Move to common section
export const subtractYears = (years: number) => {
  return new Date(new Date().setFullYear(new Date().getFullYear() - years));
};

const Tutor: FC<FormTypes> = ({ formData, onSubmitTutorData, contract }) => {
  const { register, handleSubmit, errors, watch, setValue, control } =
    useForm<RegistrationFormData>({ defaultValues: formData });
  const {
    isOpen: isCalendarOpen,
    ref: calendarRef,
    setIsOpen: setCalendarOpen
  } = useCloseOnClickOutside(false);
  const [date, setDate] = useState(subtractYears(18));
  const { whiteTheme } = useTheme();
  const { ThemeStore } = useStores();
  const { translate } = useTranslation();
  const { customUnloggedPrimaryButtonTheme } = ThemeStore;
  const tutor = formData && formData.tutor ? formData.tutor : {};
  const watchTerms = contract ? watch("terms4") : watch("terms");
  const token = formData.invite_token || "";

  const onSubmit = (data: RegistrationFormData) => {
    onSubmitTutorData(setupData(data, token, watchTerms));
  };

  const handleChangeDate = (dateChanged: Date) => {
    setValue("birthday", format(new Date(dateChanged), "dd-MM-yyyy"));
    setDate(dateChanged);
    setCalendarOpen(false);
  };

  const yearsToShow = Array.from(
    { length: 100 },
    (_, i) => new Date(subYears(new Date(), 18)).getFullYear() - i
  );

  return (
    <>
      <Box marginBottom={16}>
        <Translate text="parent_form_intro" />
      </Box>

      {!contract && (
        <Box marginBottom={16}>
          <Text type="formSmallCaption">
            {translateString({ text: "company_header" })}
          </Text>
        </Box>
      )}

      <form
        id="form-tutor-registration"
        className="form-registration"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box marginBottom={16}>
          <Input
            name="name"
            placeholder={translate("parent_name_label")}
            ref={register(__FORM_TUTOR__.name)}
            feedback={
              errors.name
                ? {
                    message: translate(errors.name.message as TranslationKeys),
                    type: "error"
                  }
                : null
            }
            theme={whiteTheme}
            label=""
            hideLabel
            rounded
          />
        </Box>
        <Box marginBottom={16}>
          <Input
            name="surname"
            placeholder={translate("parent_surname_label")}
            ref={register(__FORM_TUTOR__.surname)}
            theme={whiteTheme}
            label=""
            hideLabel
            rounded
            feedback={
              errors.surname
                ? {
                    message: translate(
                      errors.surname.message as TranslationKeys
                    ),
                    type: "error"
                  }
                : null
            }
          />
        </Box>
        <Input
          name="email"
          placeholder={translate("parent_email_label")}
          ref={register(__FORM_TUTOR__.email)}
          theme={whiteTheme}
          label=""
          hideLabel
          rounded
          feedback={
            errors.email
              ? {
                  message: translate(errors.email.message as TranslationKeys),
                  type: "error"
                }
              : null
          }
          onBlur={trimValue}
        />
        <Box marginTop={16}>
          <Controller
            as={
              <S.CalendarInputWrapper ref={calendarRef}>
                <Input
                  label="birthday"
                  hideLabel
                  name="birthday"
                  theme={whiteTheme}
                  rounded
                  autoComplete="off"
                  readOnly
                  onKeyPress={e => {
                    e.preventDefault();
                  }}
                  error={{
                    message: translateString({
                      text: errors.birthday?.message as TranslationKeys
                    })
                  }}
                  placeholder={translateString({
                    text: "Data di nascita"
                  })}
                  onClick={() => setCalendarOpen(val => !val)}
                  ref={register(__FORM_TUTOR__.birthday)}
                />
                <Box zIndex={1} position="absolute" left={0} top="40px">
                  <Calendar
                    visible={isCalendarOpen}
                    selectedDate={date}
                    onChange={handleChangeDate}
                    years={yearsToShow}
                    maxDate={subtractYears(18)}
                    minDate={subtractYears(117)}
                  />
                </Box>
              </S.CalendarInputWrapper>
            }
            control={control}
            rules={__FORM_TUTOR__.birthday}
            name="birthday"
            defaultValue={tutor.birthday}
          />
        </Box>
        <Terms
          errors={errors}
          watchTerms={watchTerms}
          watchUnderage={false}
          register={register}
          contract={contract}
          isTutor
        />
        <div className="margin-top-10">
          <Button
            type="submit"
            variant="primary"
            theme={customUnloggedPrimaryButtonTheme || whiteTheme}
            fullWidth
          >
            <Translate text="continue_label" />
          </Button>
        </div>
      </form>
    </>
  );
};

export default observer(Tutor);
