import React, { useState } from "react";
import { showToastError } from "src/js/modules/messageManager";
import { addBoardElement } from "src/js/repository/boardRepository";

import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useTranslation } from "src/js/translation";
import { VariantType } from "@arcadia/design-system";
import * as S from "./SaveRecordingModal.styles";
import SaveRecordingStepOne from "./SaveRecordingStepOne";
import SaveRecordingStepTwo from "./SaveRecordingStepTwo";

const SaveRecordingModal = ({
  resourceTitle,
  resourceId,
  closeFunction = () => {},
  registrationsNumber = 1,
  storybookMode = false
}) => {
  const [step, setStep] = useState(1);
  const [recordingImage, setRecordingImage] = useState(null);
  const [recordingTitle, setRecordingTitle] = useState(resourceTitle);

  const { translate } = useTranslation();

  let modalBody = null;
  let modalTitle = null;
  const progressPercentage = (100 * (step - 1)) / 2;

  const stepOne = (
    <SaveRecordingStepOne
      registrationsNumber={registrationsNumber}
      recordingImage={recordingImage}
      recordingTitle={recordingTitle}
      onSaveSubmit={(recordingTitleSubmit, recordingImageSubmit) => {
        setRecordingTitle(recordingTitleSubmit);
        setRecordingImage(recordingImageSubmit);
        setStep(2);
      }}
    />
  );

  const stepTwo = (
    <SaveRecordingStepTwo
      onSaveSubmit={targetBoardId => {
        if (storybookMode) {
          console.log(
            `Titolo: ${recordingTitle}`,
            `Board id: ${targetBoardId}`,
            "immagine: "
          );
          console.log(recordingImage);
        } else {
          addBoardElement({
            id: targetBoardId,
            title: recordingTitle,
            file: recordingImage,
            resourceId
          })
            .then(() => {
              closeFunction();
            })
            .catch(() => {
              showToastError({
                str: translate("error_add_recording_board_element")
              });
            });
        }
      }}
      storybookMode={storybookMode}
    />
  );

  switch (step) {
    case 1:
      modalBody = stepOne;
      modalTitle = "save_recording_modal_step_one_title";
      break;
    case 2:
      modalBody = stepTwo;
      modalTitle = "save_recording_modal_step_two_title";
      break;
    default:
      modalBody = stepOne;
      modalTitle = "save_recording_modal_step_one_title";
  }

  return (
    <ModalLayout
      labels={{
        title: translate(modalTitle),
        mobileClose: translate({ text: step === 2 ? "back" : "close" })
      }}
      progressPercentage={progressPercentage}
      closeModal={closeFunction}
      desktopProps={{
        onBack: step === 2 ? () => setStep(step - 1) : null
      }}
      mobileProps={{
        variant: VariantType.White,
        onClose: step === 2 ? () => setStep(step - 1) : closeFunction
      }}
    >
      <S.BodyWrapper>{modalBody}</S.BodyWrapper>
    </ModalLayout>
  );
};

export default SaveRecordingModal;
