import React, { useEffect } from "react";
import { Box, SearchBar, Button, Heading } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { groupGetCover } from "src/js/GroupFunction";
import handleThumbnails from "src/js/modules/imageFunction";
import { NoResults } from "src/js/components/global/NoResults";
import useAssociateGroups from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/useAssociateGroups";
import BadgeRelatedGroup from "./BadgeRelatedGroup";
import * as S from "../styles";
import { SelectGroupsTypes } from "../types";

const SelectGroups = ({
  filterString,
  setFilterString,
  fetchNextPage,
  filterValueDebounced,
  hasMoreResults,
  setStep,
  badgeRelatedGroups
}: SelectGroupsTypes) => {
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: {
      badgeToEdit,
      shouldDisplayEmptyState,
      isGroupsDrawerOpen
    },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const {
    initialGroups,
    addedGroups,
    setInitialGroups,
    setAddedGroups,
    setRemovedGroups,
    handleCheckedGroup,
    toggleGroup,
    associateGroupsToBadge
  } = useAssociateGroups({ setStep });
  const { associatedGroupsCount } = badgeToEdit || {};
  const hasAlreadyAssociatedGroups = !!associatedGroupsCount;

  useEffect(() => {
    const filteredSelectedGroups = badgeRelatedGroups.filter(
      group => group.hasBadge
    );
    setInitialGroups(filteredSelectedGroups);
  }, [badgeRelatedGroups]);

  useEffect(() => {
    if (!isGroupsDrawerOpen) {
      setInitialGroups([]);
      setAddedGroups([]);
      setRemovedGroups([]);
    }
  }, [isGroupsDrawerOpen]);

  const isAssociateButtonDisabled = () => {
    if (!hasAlreadyAssociatedGroups) {
      return !addedGroups.length;
    }

    return false;
  };

  const renderGroups = () => {
    return shouldDisplayEmptyState ? (
      <Box marginTop={40}>
        <NoResults
          subtitle={translate("dashboard_badge_list_no_results_text")}
          title={translate("associate_badge_to_groups_no_results_title")}
        />
      </Box>
    ) : (
      badgeRelatedGroups.map(group => {
        return (
          <BadgeRelatedGroup
            key={group.id}
            badgeRelatedGroup={group}
            isSelected={handleCheckedGroup(group)}
            selectGroup={isChecked => toggleGroup(isChecked, group)}
          />
        );
      })
    );
  };

  return (
    <S.DrawerBodyContainer>
      <Box paddingLeft={24} paddingRight={24}>
        <SearchBar
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          value={filterString}
          onChange={event => setFilterString(event.target.value)}
          placeholder={translate(
            "space_badge_associate_to_groups_search_placeholder"
          )}
        />
      </Box>
      <Box id="scroll-area-container" minHeight={400} marginBottom="auto">
        <S.ScrollableArea id="badge-related-groups-scrollable-list">
          <InfiniteScroll
            scrollableTarget="badge-related-groups-scrollable-list"
            dataLength={badgeRelatedGroups.length}
            next={() =>
              fetchNextPage({
                textSearch: filterValueDebounced,
                badgeId: badgeToEdit.id,
                showUnassociated: true
              })
            }
            hasMore={hasMoreResults}
            loader={null}
          >
            <S.BadgeRelatedGroupsContainer>
              {renderGroups()}
            </S.BadgeRelatedGroupsContainer>
          </InfiniteScroll>
        </S.ScrollableArea>
      </Box>
      {shouldDisplayEmptyState ? null : (
        <S.DrawerFooter>
          <S.SelectedGroups>
            <S.GroupImagesContainer>
              {initialGroups.slice(0, 3).map(group => (
                <div key={group.id}>
                  <S.GroupImage
                    src={handleThumbnails(groupGetCover(group), "medium")}
                    alt="group-logo"
                    style={{ borderRadius: 6 }}
                  />
                </div>
              ))}
            </S.GroupImagesContainer>
            <Heading level="6">
              {translate("space_badge_associate_to_groups_selected_label", {
                numberOfGroups: initialGroups.length
              })}
            </Heading>
          </S.SelectedGroups>
          <Button
            theme={whiteTheme}
            variant="primary"
            disabled={isAssociateButtonDisabled()}
            onClick={associateGroupsToBadge}
          >
            {hasAlreadyAssociatedGroups
              ? translate(
                  "space_badge_update_associate_to_groups_primary_button"
                )
              : translate("space_badge_associate_to_groups_primary_button")}
          </Button>
        </S.DrawerFooter>
      )}
    </S.DrawerBodyContainer>
  );
};

export default observer(SelectGroups);
