import { useMemo } from "react";
import { useLocation, useParams } from "react-router";
import createUrl from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { GroupDetailsModel, UserDetailsModel, UserRole } from "src/js/types";
import { Board } from "src/js/types/models/Board";
import { navigateTo } from "src/legacy/modules/history";
import { __MODULE_DETAILS_URL__ } from "./utils";

export const useMobileHeader = (
  activeBoard: Board,
  activeExerciseName: string,
  activeUser: UserDetailsModel,
  activeGroup: GroupDetailsModel,
  roles: UserRole[]
) => {
  const { translate } = useTranslation();
  const { pathname } = useLocation();
  const params = useParams<{ userUuid?: string; option: string }>();
  const isGroupCreator = roles?.includes("ROLE_CREATOR");
  const isGroupEditMode = params?.option === "edit" && isGroupCreator;

  const isInsideBoardDetail = () => {
    // boardId is being calculated & used when the user is inside the board details and refresh the page.
    const splitted = pathname.split("/");
    const indexOfBoards = splitted.indexOf("modules");
    const boardId = parseInt(splitted[indexOfBoards + 1], 10);

    return !!(
      pathname.includes("modules") &&
      indexOfBoards &&
      Number.isInteger(boardId)
    );
  };

  const isInsideModuleDetails = useMemo(
    () => __MODULE_DETAILS_URL__.test(pathname),
    [pathname]
  );

  const shouldDisplayWhiteHeader = () => {
    const boardDetail = isInsideBoardDetail();

    return (
      pathname.includes("reports") ||
      pathname.includes("settings") ||
      pathname.includes("notifications") ||
      (pathname.includes("users") && !pathname.includes("dashboard")) ||
      pathname.includes("publicprofile") ||
      boardDetail ||
      isInsideModuleDetails
    );
  };

  const withEditSaveActionButtons = () => {
    let buttonLabel: string | null = null;
    if (pathname.includes("users") && activeUser.uuid === params?.userUuid) {
      buttonLabel = "Edit";
    }
    return buttonLabel;
  };

  const handleEdit = () => {
    if (pathname.includes("users")) {
      return navigateTo(createUrl("profile_edit"));
    }

    return null;
  };

  const renderMobileHeaderTitle = () => {
    let title: string | null = null;
    if (isInsideBoardDetail() || isInsideModuleDetails) {
      title = activeBoard?.name || " ";
    } else if (
      // Case of viewing other user profile
      (pathname.includes("users") || pathname.includes("publicprofile")) &&
      params?.userUuid &&
      activeUser.uuid !== params?.userUuid
    ) {
      title = translate("mobile_settings_profile");
    } else if (
      // Case of group settings or editing group settings
      pathname.includes("settings") &&
      pathname.includes("group")
    ) {
      if (isGroupCreator && !isGroupEditMode) {
        title = activeGroup?.name;
      } else {
        title = translate("group_settings_page_header");
      }
    } else if (pathname.includes("reports")) {
      // Case of exercise reports
      title = activeExerciseName;
    }
    return title;
  };

  return {
    renderMobileHeaderTitle,
    shouldDisplayWhiteHeader,
    isInsideBoardDetail,
    withEditSaveActionButtons,
    handleEdit
  };
};
