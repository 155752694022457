import { Palette } from "@ds_themes/types";

export const createTablinkTheme = ({
  grey,
  primary,
  blueGrey
}: {
  grey: Palette["grey"];
  primary: Palette["primary"];
  blueGrey: Palette["blueGrey"];
}) => {
  return {
    tablink: {
      active: {
        color: grey[50],
        backgroundTab: primary.main
      },
      default: {
        color: grey[500],
        hover: {
          color: grey[50]
        }
      },
      disabled: {
        color: blueGrey[700]
      },
      label: {
        color: grey[1000],
        background: primary.main
      }
    }
  };
};
