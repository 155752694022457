import { Text } from "@arcadia/design-system";
import styled, { createGlobalStyle } from "styled-components";

export const ResourceAsset = styled.div`
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    width: 32px;
    height: 32px;
    min-height: 40px;
  }
`;

export const ResourceTitle = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-bottom: 6px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 2px;
  }
`;

export const ResourceTypeLabel = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const ResourceContent = styled.div`
  overflow: hidden;
`;

export const ResourceItemCard = styled.div<{ isLoading: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  padding: 8px 12px;
  min-width: 200px;
  width: 200px;
  max-width: 200px;
  height: 56px;
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "pointer")};
  @media screen and (max-width: 767px) {
    min-width: 152px;
    width: 152px;
    max-width: 152px;
    padding: 4px 8px;
  }
`;

export const ResourceItemMedia = styled.div<{ isLoading: boolean }>`
  position: relative;
  cursor: pointer;
  background: transparent !important;
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "zoom-in")};
  &,
  ${ResourceAsset} {
    height: 56px;
    width: 56px;
    min-width: 56px;
  }
`;

export const GlobalStyleModal = createGlobalStyle`
  .modal-fullpage .modal-body {
    padding: 0 !important;
  }
`;

/** skeleton */

export const SkeletonResourceTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  height: 18px;
  width: 148px;
  border-radius: 4px;
  margin-bottom: 6px;
  @media screen and (max-width: 767px) {
    height: 16px;
    width: 112px;
  }
`;

export const SkeletonResourceType = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  height: 20px;
  width: 50px;
  border-radius: 4px;
`;
