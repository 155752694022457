import { IconName } from "@arcadia/design-system";
import { TranslationKeys } from "src/js/translation";
import { ObjectValues } from "./Common";
import { GroupDetailsModel, GroupSection } from "./Group";

export const GroupTemplate = {
  SELF_PACED: "SELF_PACED",
  GROUP_BASED: "GROUP_BASED",
  COMMUNITY: "COMMUNITY",
  ONE_TO_ONE: "ONE_TO_ONE",
  ONBOARDING: "ONBOARDING",
  ONBOARDING_INSTRUCTOR: "ONBOARDING_INSTRUCTOR",
  TEAM_TRAINING: "TEAM_TRAINING",
  K12: "K12"
} as const;

export type GroupTemplateType = ObjectValues<typeof GroupTemplate>;

export const TemplateSelection = {
  Blank: "blank",
  Weschool: "weschool",
  Custom: "custom"
} as const;

export type TemplateSelectionType = ObjectValues<typeof TemplateSelection>;

export type WeschoolTemplateInfoModel = {
  title: TranslationKeys;
  description: TranslationKeys;
  icon: IconName;
  cover: TranslationKeys;
  sections: GroupSection[];
  templateTitle: TranslationKeys;
  templateAbstract: TranslationKeys;
};

export type WeschoolTemplateModel = {
  group: GroupDetailsModel;
  templateInfo: WeschoolTemplateInfoModel;
};
