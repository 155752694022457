import styled, { css } from "styled-components";

export const TableHeader = styled.div`
  flex: 0 0 40px;
  border: 1px solid ${({ theme }) => theme.colors.accent3};
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.accent2};
  font-family: ${({ theme }) => theme.fontFamily.default};
`;

export const TableHeaderName = styled.span`
  margin-left: 30px;
  flex: 1;
`;

export const TableHeaderRole = styled.span`
  width: 186px;
`;

export const TableHeaderRemove = styled.span`
  width: 100px;
  text-align: right;
  padding-right: 20px;
  margin-right: 8px;
`;

export const TableBody = styled.div`
  margin-bottom: auto;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-top: none;
  border-radius: 0 0 8px 8px;
  min-height: 400px;
  padding: 16px;
`;

export const ItemDescription = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.semantic1};
    `}
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[50]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;

  margin-bottom: 8px;

  // rules for firefox scrollbar spacing
  @supports (-moz-appearance: none) {
    margin-right: ${({ hasScrollbar }) => (hasScrollbar ? "11px" : "20px")};
  }
`;

export const ItemAction = styled.div`
  margin-left: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GroupNameWrapper = styled.div`
  flex: 1;
  max-width: 250px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.grey[500]};
    `}
`;

export const DropdownWrapper = styled.div`
  width: 186px;
`;
