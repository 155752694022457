import styled, { keyframes } from "styled-components";

// inspired from https://codepen.io/hopefulcodegirl/pen/WNwmOQX

const load = keyframes`
    0% {
      width: 0;
    }
    
    100% {
      width: 100%;
    }
`;

export const LoaderStyle = styled.div`
  position: relative;
  display: inline-block;
  width: 200px;
  height: 10px;
  background: ${({ theme }) => theme.colors.accent4};
  border-radius: 5px;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 5px;
    animation: ${load} 3s infinite;
    background: ${({ theme }) => theme.primaryColor[500]};
  }
`;
