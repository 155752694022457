import { Box, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import * as S from "../styles";

const SelectStudentsTableTitles = () => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  return (
    <S.BadgeTableTitles>
      <S.NameTitle type="label">
        {translate("emit_badge_students_table_name_column")}
      </S.NameTitle>
      <Text type="label">
        {translate("emit_badge_students_table_badge_column")}
      </Text>
      <S.WalletTitle type="label">
        {translate(
          isLayoutModeMobile
            ? "emit_badge_students_table_wallet_mobile_column"
            : "emit_badge_students_table_wallet_column"
        )}
      </S.WalletTitle>
      <Box width={28} />
    </S.BadgeTableTitles>
  );
};

export default observer(SelectStudentsTableTitles);
