import { useCallback, useEffect, useRef, useState } from "react";

export const useClickOutsideState = (defaultValue: boolean) => {
  const [isExpanded, setExpanded] = useState(defaultValue);

  const searchRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setExpanded(false);
      }
    },
    [setExpanded]
  );

  useEffect(() => {
    if (!isExpanded) {
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [searchRef, isExpanded, handleClickOutside]);

  return { searchRef, isExpanded, setExpanded };
};
