import { Avatar, AvatarWithInitials, Icon } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTheme } from "styled-components";
import { MigrateGroupsContextEnum } from "../MigrateGroupsModal.types";
import * as S from "./MigrateGroupsItemCard.styles";

import { MigrateGroupsItemCardProps } from "./MigrateGroupsItemCard.types";

const MigrateGroupsItemCard: FC<MigrateGroupsItemCardProps> = ({
  context,
  spaceName,
  isActive,
  onClick,
  el
}) => {
  const { whiteTheme } = useTheme();

  const placeholder =
    context === MigrateGroupsContextEnum.SpaceSelection ? (
      <AvatarWithInitials
        theme={whiteTheme}
        alt={el?.name}
        url={el?.badge?.small as string | undefined}
        name={el?.name || ""}
        avatarColor={el?.defaultBadgeColor}
        size="medium"
      />
    ) : (
      <Avatar theme={whiteTheme} url={el?.cover?.small} />
    );

  return (
    <S.MigrateGroupsItemCardWrapper onClick={onClick} isActive={isActive}>
      <S.AvatarWrapper
        isSquared={context === MigrateGroupsContextEnum.GroupsSelection}
      >
        {placeholder}
      </S.AvatarWrapper>
      <S.MigrateGroupsItemCardContent>
        {context === MigrateGroupsContextEnum.GroupsSelection ? (
          <S.Description>{spaceName}</S.Description>
        ) : null}
        <S.Title>{el?.name}</S.Title>
      </S.MigrateGroupsItemCardContent>
      <S.CheckIndicator isSelected={isActive}>
        <Icon icon="circleCheck" width={24} height={24} initialViewbox />
      </S.CheckIndicator>
    </S.MigrateGroupsItemCardWrapper>
  );
};

export default MigrateGroupsItemCard;
