import { Text, shadow } from "@arcadia/design-system";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 400px;
  margin: -16px;

  @media (max-width: 767px) {
    gap: 16px;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Label = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[100]};
`;

export const LeftWrapper = styled.div`
  margin: 12px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 16px;
  overflow: hidden;
  box-shadow: ${shadow[200]};

  @media (max-width: 767px) {
    padding: 20px 16px 0px;
    margin: 16px;
    width: calc(100% - 32px);
    min-height: 168px;
  }
`;
