import React from "react";
import { useTheme } from "styled-components";
import { EllipsisName } from "../EllipsisName";
import * as S from "./User.styles";

type UserProps = {
  fullName: string;
  avatarUrl: string;
};

const User = ({ fullName, avatarUrl }: UserProps) => {
  const { whiteTheme } = useTheme();
  return (
    <S.UserWrapper>
      <S.StyledAvatar
        url={avatarUrl}
        alt="avatar"
        size="s"
        theme={whiteTheme}
      />
      <EllipsisName name={fullName} />
    </S.UserWrapper>
  );
};

export default User;
