import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import sortBy from "lodash/sortBy";
import React, { useEffect } from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import renderLatex from "src/js/modules/mathjaxFunction";
import withBackbone from "with-backbone";
import QuizCollection from "../../../collections/quizzesCollection";
import ColorCodedNumber from "../../../components/colorCodedNumber";
import { __ } from "../../../modules/localization";
import { TranslationKeys } from "src/js/translation";

const QuizzesReportItem = ({ report, quiz, index, addClass }) => {
  useEffect(() => {
    renderLatex();
  }, []);

  const renderSubcontent = (subQuizzesList, report) => {
    const subQuizzes = new QuizCollection(subQuizzesList);
    return subQuizzes.map((sub, subIndex) => {
      const subReport = find(report.subReports, { quizId: sub.get("id") });
      return (
        <QuizzesReportItem
          key={sub.get("id")}
          report={subReport}
          quiz={sub}
          addClass="subquiz-report"
          index={subIndex}
        />
      );
    });
  };

  const passed = report?.quizExecutionsPassedCount || 0;
  const failed = report?.quizExecutionsFailedCount || 0;
  const total = passed + failed;
  let percentage = 0;
  let percentageText;

  if (total !== 0) {
    percentage = Math.round((passed / total) * 100);
    percentageText = <ColorCodedNumber text="%" number={percentage} />;
  } else {
    percentageText = "—";
  }

  const reportTitle = isUndefined(report)
    ? __("non ci sono dati per questa domanda")
    : __("_report_quiz_label_", { percentage });

  const tooltipPlacement = getBreakpoint() === "smartphone" ? "top" : "left";

  if (quiz.getType() === "quizvideo") {
    let subQuizzesList = sortBy(quiz.getQuizzes(), q => q.position);

    return (
      <>
        <TranslatedTooltip
          tooltipString={reportTitle as unknown as TranslationKeys}
          placement={tooltipPlacement}
        >
          <div className={["quiz-report", addClass, "clearfix"].join(" ")}>
            <div className="quiz-report-number">{index + 1}</div>
            <div className="quiz-report-title mathjax-container">
              <ExerciseQuizTitle quizTitle={quiz.get("title")} />
            </div>
            <div
              className="quiz-report-percentage"
              style={{ lineHeight: "0", right: "20px", marginTop: "-10px" }}
            >
              <span style={{ fontSize: "10px" }}>{__("video")}</span>
            </div>
          </div>
        </TranslatedTooltip>
        {report?.questions ? renderSubcontent(subQuizzesList, report) : null}
      </>
    );
  }

  return (
    <TranslatedTooltip
      tooltipString={reportTitle as unknown as TranslationKeys}
      placement={tooltipPlacement}
    >
      <div className={["quiz-report", addClass, "clearfix"].join(" ")}>
        <div className="quiz-report-number">{index + 1}</div>
        <div className="quiz-report-title mathjax-container">
          <ExerciseQuizTitle quizTitle={quiz.get("title")} />
        </div>
        <div className="quiz-report-percentage">{percentageText}</div>
      </div>
    </TranslatedTooltip>
  );
};

export default withBackbone(QuizzesReportItem);
