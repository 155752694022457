import styled, { css } from "styled-components";
import { DefaultPanelStyleProps } from "./types";

export const Panel = styled.div<DefaultPanelStyleProps>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 10px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  width: 100%;

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;
