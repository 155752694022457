import colors from "@ds_themes/colors";

export const radiogroup = {
  background: colors.grey[1000],
  text: colors.grey[50],
  border: `1.5px solid ${colors.violet[600]}`,
  hover: {
    border: `1.5px solid ${colors.violet[501]}`,
    background: colors.violet[501]
  },
  disabled: {
    border: `1.5px solid ${colors.violet[700]}`,
    background: colors.grey[1000],
    text: colors.grey[800],
    checked: {
      background: colors.violet[700]
    }
  },
  checked: {
    background: colors.violet[600],
    border: `1.5px solid ${colors.violet[600]}`
  },
  focus: {
    border: `${colors.violet[800]} solid 3px`
  }
};
