import React from "react";
import { CheckIndicator } from "src/js/components/global/CheckIndicator";
import { ListSelectorItem } from "./ListSelector.styles";
import { ListElementProps, ListSelectorProps } from "./ListSelector.types";
import { genericIncludes } from "./ListSelector.utils";

const ListElement = <
  TItem extends { id?: string | number; [key: string]: unknown }
>({
  item,
  isActive,
  selectElement,
  getCustomItemCard
}: ListElementProps<TItem>) => {
  const onClick = () => {
    selectElement(item);
  };

  if (getCustomItemCard) {
    return getCustomItemCard({ isActive, onClick, el: item });
  }
  return (
    <ListSelectorItem onClick={onClick} isActive={isActive}>
      {item?.name}
      <CheckIndicator isSelected={isActive} />
    </ListSelectorItem>
  );
};

const ListSelector = <
  TItem extends { id?: string | number; [key: string]: unknown }
>({
  itemList = [],
  toggleItem = () => {},
  selectedItem,
  getCustomItemCard,
  multipleSelect = false
}: ListSelectorProps<TItem>) => {
  return (
    <ul style={{ all: "unset" }}>
      {itemList.map(item => {
        const isActive =
          multipleSelect && Array.isArray(selectedItem)
            ? genericIncludes(selectedItem, item.id)
            : item?.id === selectedItem;
        return (
          <ListElement
            key={item.id}
            item={item}
            isActive={isActive}
            getCustomItemCard={getCustomItemCard}
            selectElement={() => {
              toggleItem(item);
            }}
          />
        );
      })}
    </ul>
  );
};

export default ListSelector;
