import React from "react";
import { useTheme } from "styled-components";
import {
  Box,
  Button,
  Heading,
  Icon,
  ModalSizeType,
  Text
} from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

import { ModalLayout } from "src/js/components/modal/ModalLayout";
import * as S from "./SpaceBadgesDeleteModal.styles";

type SpaceBadgesDeleteModal = {
  badgeName: string;
  onCloseModal: () => void;
  onDeleteBadge: () => void;
};

const SpaceBadgesDeleteModal = ({
  badgeName,
  onCloseModal,
  onDeleteBadge
}: SpaceBadgesDeleteModal) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <ModalLayout
      closeModal={onCloseModal}
      labels={{
        title: translate("badge_delete_modal_header"),
        mobileTitle: translate("badge_delete_modal_header"),
        mobileClose: ""
      }}
      desktopProps={{ hasAdaptiveHeight: true, size: ModalSizeType.Normal }}
      mobileProps={{
        withDrag: false,
        withAdaptiveHeight: true,
        withHeaderLabels: false
      }}
    >
      <Box padding={40} textAlign="center">
        <S.ImageWrapper>
          <Icon
            icon="illustration-delete-badge"
            height={127}
            width={72}
            viewbox="0 0 72 127"
          />
        </S.ImageWrapper>
        <Box marginBottom="16px">
          <Heading level="3">
            {translate("badge_delete_modal_title", { badgeName })}
          </Heading>
        </Box>
        <Text type="body">{translate("badge_delete_modal_text")}</Text>
        <S.ButtonWrapper>
          <Button
            theme={whiteTheme}
            onClick={onDeleteBadge}
            fullWidth
            variant="primary"
          >
            {translate("badge_delete_modal_btn_delete")}
          </Button>
          <Box cursor="pointer" onClick={onCloseModal} marginTop={16}>
            <S.CancelText type="textLink">
              {translate("badge_delete_modal_btn_cancel")}
            </S.CancelText>
          </Box>
        </S.ButtonWrapper>
      </Box>
    </ModalLayout>
  );
};

export default SpaceBadgesDeleteModal;
