import shareWebPlaceholder from "Images/placeholders/share-web.png";
import React from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  INPUT_VARIANT,
  LABEL_POSITIONS
} from "src/js/components/forms/input";
import InputFile from "src/js/components/forms/InputFile";
import { Label } from "src/js/components/forms/Label";
import {
  __SHARE_PREVIEW_MIME__,
  __SHARE_PREVIEW_SIZE__
} from "src/js/settings/settingFileUploader";
import { __FORM_SHARE__ } from "src/js/settings/settingsFormValidation";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const ShareInformationStep = ({
  resourceTitle,
  continueFunction,
  previewImageUrl,
  fileImage,
  setFileImage
}) => {
  const defaultValues = {
    title: resourceTitle
  };
  const { greyTheme } = useTheme();
  const { register, handleSubmit, errors } = useForm({ defaultValues });

  const preview = fileImage ? URL.createObjectURL(fileImage) : previewImageUrl;
  return (
    <div className="padding-header margin-horizontal-default">
      <div className="share__search-board-wrapper">
        <form onSubmit={handleSubmit(formData => continueFunction(formData))}>
          <Input
            id="title"
            name="title"
            label="share_resource_title_label"
            labelDirection={LABEL_POSITIONS.FROM_TOP}
            placeholder="share_resource_title_placeholder"
            marginBottom={20}
            ref={register(__FORM_SHARE__.title)}
            hasError={errors.title}
            errorMessage={errors.title?.message}
            variant={INPUT_VARIANT.LIGHT}
          />
          <Label
            marginBottom={10}
            htmlFor="file"
            text="share_resource_title_preview"
          />
          <div className="share__preview-container">
            <img src={preview || shareWebPlaceholder} alt={resourceTitle} />
          </div>
          <InputFile
            id="file"
            name="file"
            buttonUploadText="share_resource_upload_button"
            maxSize={__SHARE_PREVIEW_SIZE__}
            mimeArray={__SHARE_PREVIEW_MIME__}
            setInputFile={setFileImage}
          />
          <div className="text-center margin-top-20">
            <Button type="submit" variant="primary" theme={greyTheme}>
              <Translate text="share_resource_info_button" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShareInformationStep;
