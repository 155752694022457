import { Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

export const createDoodleloaderTheme = ({ primary }: Palette) => {
  return {
    doodleloader: {
      color: getHighContrastColor(primary.main, primary.dark, primary.main),
      background: "transparent"
    }
  };
};
