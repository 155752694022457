import { ActionButton } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { SchoolClaimSelected } from "src/js/pages/spaces/space_creation/SchoolClaimForm/SchoolClaimSelected";
import { updateMarketingData } from "src/js/repository/marketingDataRepository";
import {
  __EXAMPLE_BACKGROUND_PATTERN__,
  __EXAMPLE_CLAIM_SCHOOL_SPACE_IMAGE__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  OnboardingEvent,
  SpaceListModel,
  SpaceModel,
  SpaceUserRoleEnum
} from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import styled, { useTheme } from "styled-components";
import { OnboardingSchoolSpaceCreation } from "../Onboarding.utils";
import Example from "../components/Example";
import StepperBlock from "../components/StepperBlock";
import { TitleBlock } from "../components/TitleBlock";
import { useSchoolSpaceCreation } from "../utils/SchoolSpaceCreationProvider";

export const Container = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 20px;
  }
`;

const SchoolSpaceConfirmation = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UserStore: { userOnboarding, setUserOnboarding },
    SpaceStore: { addNewSpaceToSpaceList }
  } = useStores();

  const currentStepIndex = OnboardingSchoolSpaceCreation.indexOf("confirm") + 1;

  const { status, spaceSelected } = useSchoolSpaceCreation();

  const goBack = () => {
    setUserOnboarding({
      userType: userOnboarding.userType,
      isCompleted: false,
      step: WeSchoolRoutes.Onboarding.schoolSpaceCreation
    });
    navigateTo(WeSchoolRoutes.Onboarding.schoolSpaceCreation);
  };

  const onSuccessCallback = (newSpace: SpaceModel) => {
    const { userType } = userOnboarding;
    updateMarketingData({ userType, onboardingCompleted: true }).then(() => {
      const spaceListModel: SpaceListModel = {
        space: newSpace,
        role: SpaceUserRoleEnum.Owner,
        unreadNotificationCount: 0,
        unreadThreadMessagesCount: 0
      };
      addNewSpaceToSpaceList(spaceListModel);
      setUserOnboarding({
        userType: USER_TYPE.K12,
        isCompleted: true,
        step: WeSchoolRoutes.Onboarding.schoolSpaceConfirmation
      });
      _trackEvent(
        EventDomain.Onboarding,
        OnboardingEvent.SchoolSpaceConfirmation
      );
      _trackEvent(EventDomain.Onboarding, OnboardingEvent.CompleteFlow);
      navigateTo(createUrl("space_groups", { space_slug: newSpace.slug }));
    });
  };

  const pagecontainer = (
    <Container>
      <TitleBlock
        backComponent={
          <ActionButton
            icon="chevronLeft"
            theme={whiteTheme}
            variant="tertiary"
            onClick={goBack}
          />
        }
        title={translate("onboarding_school_space_confirm_title")}
      />
      <SchoolClaimSelected
        theme={whiteTheme}
        status={status}
        spaceSelected={spaceSelected}
        onSuccessCallback={onSuccessCallback}
      />
    </Container>
  );

  return (
    <OnboardingLayout
      mainContent={pagecontainer}
      sideContent={
        <Example
          background={__EXAMPLE_BACKGROUND_PATTERN__}
          image={__EXAMPLE_CLAIM_SCHOOL_SPACE_IMAGE__}
          imagePosition="right: 0"
          description={translate(
            "onboarding_promotional_school_space_confirmation"
          )}
        />
      }
      stepper={
        <StepperBlock
          totalSteps={OnboardingSchoolSpaceCreation.length}
          currentStep={currentStepIndex}
        />
      }
      mobileCentered
    />
  );
};

export default SchoolSpaceConfirmation;
