import { Button, Modal } from "@arcadia/design-system";
import React from "react";
import ExerciseQuizCountBadge from "src/js/components/exercises/ExerciseQuizCountBadge";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

const ExerciseSettingsViewer = ({
  shuffle,
  time_threshold = 0,
  show_results
}) => {
  return (
    <div className="exercise-publish-modal-settings">
      <div className="exercise-publish-modal-setting">
        <div className="exercise-publish-modal-setting-label margin-right-20">
          <Translate text="exercise_publish_shuffle_label" />
        </div>
        <div className="uppercase">
          <Translate
            text={
              shuffle
                ? "exercise_publish_shuffle_casual"
                : "exercise_publish_shuffle_normal"
            }
          />
        </div>
      </div>
      <div className="exercise-publish-modal-setting">
        <div className="exercise-publish-modal-setting-label margin-right-20">
          <Translate text="exercise_publish_duration_label" />
        </div>
        <div className="uppercase">
          {`${Math.round(time_threshold / 60)} `}
          <Translate text="minuti" />
        </div>
      </div>

      <div className="exercise-publish-modal-setting">
        <div className="exercise-publish-modal-setting-label margin-right-20">
          <Translate text="exercise_publish_vote_and_result" />
        </div>
        <div className="uppercase">
          <Translate
            text={
              show_results
                ? "exercise_publish_visible_vote"
                : "exercise_publish_hidden_vote"
            }
          />
        </div>
      </div>
    </div>
  );
};

const ExercisePublishConfirmModal = ({ exercise, closeModal, callbackOk }) => {
  const { quizzes_count, time_threshold, shuffle, show_results, type, title } =
    exercise || {};
  const confirmMessages =
    type === "EXAMINATION"
      ? "exercise_publish_examination_message"
      : "exercise_publish_test_message";
  const { whiteTheme } = useTheme();

  const confirmPublish = () => {
    closeModal();
    callbackOk();
  };
  return (
    <div className="main-modal__container">
      <Translator>
        {({ translate }) => (
          <Modal
            title={translate("exercise_publish_modal_title")}
            onClose={closeModal}
            scrollable
          >
            <div className="main-modal__content">
              <div className="text-center margin-vertical-20">
                <div className="medium-title">
                  <Translate text="exercise_publish_modal_subtitle" />
                </div>
                <Translate text={confirmMessages} />
              </div>
              <div className="separator-horizontal" />
              <div className="flex-row margin-top-20">
                <div className="medium-title flex">
                  <span className="margin-right-10">{title}</span>
                  <ExerciseQuizCountBadge quizCount={quizzes_count} />
                </div>
              </div>
              <ExerciseSettingsViewer
                shuffle={shuffle}
                time_threshold={time_threshold}
                show_results={show_results}
              />
            </div>
            <div className="main-modal__footer--buttons">
              <Button
                type="button"
                onClick={closeModal}
                theme={whiteTheme}
                variant="secondary"
              >
                <Translate text="edit" />
              </Button>
              <Button
                type="button"
                onClick={confirmPublish}
                theme={whiteTheme}
                variant="primary"
              >
                <Translate text="confirm" />
              </Button>
            </div>
          </Modal>
        )}
      </Translator>
    </div>
  );
};

export default ExercisePublishConfirmModal;
