import React from "react";
import { Icon, IconName, Text } from "@arcadia/design-system";

import * as S from "./PostActionButton.styles";

const PostActionButton = ({
  iconName,
  onClick,
  isActive,
  label
}: {
  iconName: IconName;
  onClick: () => void;
  isActive?: boolean;
  label: string;
}) => {
  return (
    <S.PostActionButton type="button" onClick={onClick} isActive={isActive}>
      <Icon icon={iconName} width="24px" height="24px" viewBox="0 0 24 24" />
      <Text type="ctaTertiary">{label}</Text>
    </S.PostActionButton>
  );
};

export default PostActionButton;
