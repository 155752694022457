import { DragHandle } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const AutoGrowTextArea = styled.div<{ maxWidth?: string }>`
  display: grid;
  max-width: ${({ maxWidth }) => maxWidth};

  & > *:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }

  & textarea {
    overflow: hidden;
  }
`;

export const CustomDragHandle = styled(DragHandle)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin-top: 8px;
  margin-left: 8px;
  // Fix safari flex
  > svg {
    flex: 0 0 28px;
  }

  // Add hover effect only if the device handles hovering effect
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[900]};
    }
  }
  // Add active effect in thouch devices
  @media (hover: none) {
    &:active {
      background-color: ${({ theme }) => theme.colors.grey[900]};
    }
  }
`;

export const FakeIosInput = styled.input`
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

export const TimeChip = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  padding: 2px 4px;
  margin-right: 6px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.primaryColor[900]};
`;

export const HiddenGrowText = styled.div<{ fontWeight: number }>`
  white-space: pre-wrap;
  visibility: hidden;
  grid-area: 1 / 1 / 2 / 2;
  overflow: hidden;
  overflow-wrap: break-word;
  line-height: 20px;
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const Wrapper = styled(motion.div)`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  user-select: none;
  padding: 12px;
  padding-left: 8px;
`;

export const NakedInput = styled.textarea`
  all: unset;
  line-height: 20px;
  letter-spacing: 0px;
  font-size: 16px;
  cursor: text;
`;

export const Title = styled(NakedInput)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey[100]};

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[500]};
    opacity: 1;
  }
`;
export const Description = styled(NakedInput)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey[300]};

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[300]};
    opacity: 1;
  }
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Option = styled.button<{ isDelete?: boolean }>`
  all: unset;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ isDelete, theme }) =>
    isDelete ? theme.colors.error[500] : theme.colors.grey[50]};

  // Add hover effect only if the device handles hovering effect
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, isDelete }) =>
        isDelete ? theme.colors.error[900] : theme.colors.grey[900]};
    }
  }
  // Add active effect in thouch devices
  @media (hover: none) {
    &:active {
      background-color: ${({ theme, isDelete }) =>
        isDelete ? theme.colors.error[900] : theme.colors.grey[900]};
    }
  }
`;

export const MobileOptions = styled.div`
  margin-top: 16px;
  margin-left: -32px;
  display: flex;
  gap: 8px;
`;

export const MobileOption = styled(motion.button)<{ isDelete?: boolean }>`
  all: unset;
  cursor: pointer;
  flex: 1;
  gap: 8px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  color: ${({ isDelete, theme }) =>
    isDelete ? theme.colors.error[500] : theme.colors.grey[50]};
  background-color: ${({ theme, isDelete }) =>
    isDelete ? theme.colors.error[900] : theme.colors.grey[900]};
`;
