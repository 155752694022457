import React from "react";
import { BoardExerciseSplash } from "src/js/components/BoardExerciseSplash";
import ExerciseModel from "../exerciseModel";
import { OldExercise } from "src/js/types";

const Exercise = ({ exercise }: { exercise: OldExercise }) => {
  const exercise_id = exercise.id;
  const exerciseModel = new ExerciseModel(exercise);

  return (
    <div className="scroll-inner-wrapper">
      <BoardExerciseSplash exerciseId={exercise_id} exercise={exerciseModel} />
    </div>
  );
};

export default Exercise;
