/* eslint-disable no-bitwise */
export const changeOpacity = (hexColor: string, percentage: number) => {
  const validPercentage = Math.min(100, Math.max(0, percentage));
  const alpha = validPercentage / 100;
  const hex = hexColor.replace(/^#/, "");
  const bigint = parseInt(hex, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  return rgbaColor;
};

export const customPromptEditorAnimations = {
  initial: { height: 0, overflow: "hidden" },
  animate: {
    height: "auto",
    overflow: "hidden",
    transitionEnd: {
      overflow: "initial"
    }
  },
  exit: {
    height: 0,
    overflow: "hidden",
    transitionEnd: {
      overflow: "initial"
    }
  }
};
