import React, { useCallback } from "react";
import { useTranslation } from "src/js/translation";
import { BasePaginationParams, BasePaginationResponse } from "src/js/types";
import {
  SelectableList,
  SelectableListEmptyState
} from "../layout/SelectableList";
import { ModalLayout } from "../modal/ModalLayout";
import EventRecipientsCard from "./EventRecipientsCard";

export type UserRecipient = {
  avatar?: {
    small: string;
    medium: string;
    large: string;
    original_url: string;
  };
  uuid: string;
  id: number;
  name: string;
  surname: string;
};

export type GroupRecipient = {
  cover?: {
    small: string;
    medium: string;
    large: string;
    original_url: string;
  };
  id: number;
  name: string;
};

type EventRecipientsListModalProps = {
  closeModal: () => void;
  group_recipients?: GroupRecipient[];
  user_recipients?: UserRecipient[];
};

const EventRecipientsListModal = ({
  closeModal = () => {},
  group_recipients = [],
  user_recipients = []
}: EventRecipientsListModalProps) => {
  const { translate } = useTranslation();

  const fetchItems = useCallback(
    (
      params: BasePaginationParams & {
        name?: string;
        [key: string]: any;
      }
    ): Promise<BasePaginationResponse<UserRecipient | GroupRecipient>> => {
      // hasNext workaround
      if (params?.offset > 0) {
        return new Promise(resolve => {
          resolve({
            hasNext: false,
            results: []
          });
        });
      }

      const { name } = params;
      const recipients =
        user_recipients?.length > 0 ? user_recipients : group_recipients;
      const filteredRecipients =
        name && name !== ""
          ? recipients.filter(recipient => {
              const fullName = `${recipient.name} ${
                (recipient as UserRecipient)?.surname || ""
              }`
                .toLowerCase()
                .replace(/ /g, "");
              return fullName?.includes(name?.toLowerCase().replace(/ /g, ""));
            })
          : recipients;
      const data: BasePaginationResponse<UserRecipient | GroupRecipient> = {
        hasNext: false, // TODO: hasNext not working
        results: filteredRecipients
      };
      return new Promise(resolve => {
        resolve(data);
      });
    },
    [user_recipients, group_recipients]
  );

  const getEmptyStateFromStatus = status => (
    <SelectableListEmptyState
      status={status}
      emptyListLabel="event_recipients_empty_list"
      emptySearchLabel="event_recipients_empty_search"
    />
  );

  const getCustomItemCard = props => <EventRecipientsCard {...props} />;

  return (
    <ModalLayout
      labels={{
        title: translate("event_recipients_title"),
        mobileClose: translate("close")
      }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false
      }}
      desktopProps={{
        hasAdaptiveHeight: false
      }}
      closeModal={closeModal}
    >
      <SelectableList
        onConfirm={closeModal}
        fetchItems={fetchItems}
        selectedItemsIds={[]}
        customComponents={{
          getEmptyStateFromStatus,
          getCustomItemCard
        }}
        labels={{
          searchPlaceholder: translate("event_recipients_search_placeholder"),
          translatedTitle:
            user_recipients?.length > 0
              ? translate("event_recipients_users")
              : translate("event_recipients_groups")
        }}
        withConfirmButton={false}
      />
    </ModalLayout>
  );
};

export default EventRecipientsListModal;
