import { Box } from "@arcadia/design-system";
import { Container } from "react-awesome-styled-grid";
import styled, { css } from "styled-components";

type SectionProps = {
  isProfilePage: boolean;
};

export const Bar = styled(Box)`
  width: 100%;
  min-height: 64px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.header};
`;

const BaseContainer = css`
  display: flex;
  align-items: center;
`;
export const GridContent = styled(Container)`
  ${BaseContainer}
`;

export const Content = styled.div`
  ${BaseContainer};
  width: 100%;
  max-width: 100%;
`;

export const LeftSection = styled.div<SectionProps>`
  flex: ${({ isProfilePage }) => (isProfilePage ? "0.05" : "0.1")};

  @media (max-width: 48rem) {
    flex: 0.1;
  }
`;

export const MiddleSection = styled.div<SectionProps>`
  flex: ${({ isProfilePage }) => (isProfilePage ? "0.9" : "0.8")};

  @media (max-width: 48rem) {
    flex: 0.8;
  }
`;

export const RightSection = styled.div<SectionProps>`
  flex: ${({ isProfilePage }) => (isProfilePage ? "0.05" : "0.1")};

  @media (max-width: 48rem) {
    flex: 0.1;
  }
`;
