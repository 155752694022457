import React from "react";
import { Icon } from "@arcadia/design-system";

const DropdownIconChevron = ({ open }) => {
  return open ? (
    <Icon icon="chevronUp" height={15} width={15} />
  ) : (
    <Icon icon="chevronDown" height={15} width={15} />
  );
};

export default DropdownIconChevron;
