import React, { useState } from "react";
import * as S from "src/js/components/global/RoundImage/RoundImage.styles";

const RoundImage = ({ isSelectable, type, placeholder, src, ...props }) => {
  const [imageHasError, setImageHasError] = useState(false);
  return (
    <S.RoundImageContainer
      isSelectable={isSelectable}
      type={type}
      placeholder={placeholder}
    >
      {src && !imageHasError && (
        <S.RoundImageLayout
          src={src}
          {...props}
          onError={() => setImageHasError(true)}
        />
      )}
    </S.RoundImageContainer>
  );
};

export default RoundImage;
