import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { SearchBar, DropdownMultiple, Box } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { FilterBar } from "src/js/components/FilterBar";
import { useStores } from "src/js/hooks";
import useBadgeRelatedGroups from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/useBadgeRelatedGroups";
import * as S from "../styles";

const StudentsFilters = ({
  filterString,
  filterGroupString,
  setFilterGroupString,
  setFilterString
}: {
  filterString: string;
  setFilterString: (value: string) => void;
  filterGroupString: string;
  setFilterGroupString: (value: string) => void;
}) => {
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceBadgesStore: {
      selectBadgeRelatedGroups,
      selectedBadgeRelatedGroups,
      badgeToEdit,
      isEmitStudentsDrawerOpen
    }
  } = useStores();
  const {
    badgeRelatedGroups,
    hasMoreResults,
    fetch,
    resetResults,
    fetchNextPage
  } = useBadgeRelatedGroups();
  const { id } = badgeToEdit || {};

  const handleSearchGroups = (value: string) => {
    setFilterGroupString(value);
  };

  useEffect(() => {
    if (
      (filterGroupString || filterGroupString === "") &&
      isEmitStudentsDrawerOpen
    ) {
      fetch(0, {
        badgeId: id,
        textSearch: filterGroupString,
        showUnassociated: false
      });
    }
    return () => resetResults();
  }, [filterGroupString, id, isEmitStudentsDrawerOpen]);

  return (
    <S.StudentsFiltersContainer>
      <S.SearchFiltersContainer>
        <SearchBar
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          value={filterString}
          onChange={event => setFilterString(event.target.value)}
          placeholder={translate("emit_badge_students_search_placeholder")}
        />
        <Box>
          <DropdownMultiple
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            hideButtonTags
            optionsList={badgeRelatedGroups.map(activeGroup => ({
              id: activeGroup.id.toString(),
              label: activeGroup.name
            }))}
            selectedOptionIds={selectedBadgeRelatedGroups}
            setSelectedOptionIds={groupIds =>
              selectBadgeRelatedGroups(groupIds)
            }
            placeholder={translate("badge_field_assign_to_group_placeholder")}
            searchPlaceholder={translate(
              "badge_field_assign_to_groups_search_placeholder"
            )}
            scrollableListId="badge-assign-groups-filter-dropdown-multiple"
            searchable
            searchQuery={filterGroupString}
            onSearchFunction={event => handleSearchGroups(event.target.value)}
            hasNext={hasMoreResults}
            showNext={() =>
              fetchNextPage({
                badgeId: id,
                showUnassociated: false,
                textSearch: filterString
              })
            }
            withFilterIconAsAButton
            optionsListAlignment={{ right: "0px" }}
            optionsListWidth="320px"
            withLoader={false}
          />
        </Box>
      </S.SearchFiltersContainer>
      <S.FilterBarContainer>
        <FilterBar
          selectedFiltersKeys={selectedBadgeRelatedGroups}
          options={badgeRelatedGroups.map(activeGroup => ({
            id: activeGroup.id.toString(),
            label: activeGroup.name
          }))}
          removeFilter={groupId =>
            selectBadgeRelatedGroups(
              selectedBadgeRelatedGroups.filter(item => item !== groupId)
            )
          }
          removeAllFilters={() => selectBadgeRelatedGroups([])}
        />
      </S.FilterBarContainer>
    </S.StudentsFiltersContainer>
  );
};

export default observer(StudentsFilters);
