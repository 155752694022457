import React from "react";
import { Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useCloseOnClickOutside } from "src/js/hooks";
import { ReactionCounterType } from "src/js/types/models/Reaction";
import { useTranslation } from "src/js/translation";
import {
  REACTION_MODAL_TAB,
  ReactionModalTabType
} from "../../Reactions.types";
import { getShortcodeFromType } from "../../Reactions.utils";

import * as S from "./ReactionModalHeader.styles";

type HeaderProps = {
  reactions: ReactionCounterType[];
  selectedTab: ReactionModalTabType;
  setSelectedTab: (tab: ReactionModalTabType) => void;
  onClose: () => void;
};

const ReactionModalHeader = ({
  reactions,
  selectedTab,
  setSelectedTab,
  onClose
}: HeaderProps) => {
  const {
    isOpen: isOpenOther,
    ref,
    setIsOpen: setIsOpenOther
  } = useCloseOnClickOutside(false);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const viewableTabs = reactions.length > 3 ? reactions.slice(0, 3) : reactions;
  const hiddenTabs = reactions.length > 3 ? reactions.slice(3) : null;

  return (
    <S.ModalHeader>
      <S.TabWrapper>
        <S.Tab>
          <S.ClickableTab
            type="button"
            onClick={() => setSelectedTab(REACTION_MODAL_TAB.ALL)}
            isActive={selectedTab === REACTION_MODAL_TAB.ALL}
          >
            <Text type="formSubtitle">
              {translate("reaction_modal_header_all_tab_text")}
            </Text>
          </S.ClickableTab>
          {selectedTab === REACTION_MODAL_TAB.ALL ? (
            <S.ActiveBar layoutId="underline" />
          ) : null}
        </S.Tab>
        {viewableTabs.map(({ reactionType, count }) => (
          <S.Tab key={reactionType}>
            <S.ClickableTab
              type="button"
              onClick={() => setSelectedTab(reactionType)}
              isActive={selectedTab === reactionType}
            >
              <em-emoji
                shortcodes={getShortcodeFromType(reactionType)}
                set="native"
                size="14px"
              />
              <Text type="formSubtitle">{count}</Text>
            </S.ClickableTab>
            {selectedTab === reactionType ? (
              <S.ActiveBar layoutId="underline" />
            ) : null}
          </S.Tab>
        ))}
        {hiddenTabs ? (
          <S.HiddenTabsWrapper ref={ref}>
            <S.Tab>
              <S.ClickableTab
                type="button"
                onClick={() => setIsOpenOther(val => !val)}
                isActive={
                  !!hiddenTabs.find(
                    ({ reactionType }) => reactionType === selectedTab
                  )
                }
              >
                <Text type="formSubtitle">
                  {" "}
                  {translate("reaction_modal_header_other_tab_text")}
                </Text>
                <Icon
                  icon={isOpenOther ? "chevronLargeUp" : "chevronLargeDown"}
                  width={8}
                  height={8}
                />
              </S.ClickableTab>
              {hiddenTabs.find(
                ({ reactionType }) => reactionType === selectedTab
              ) ? (
                <S.ActiveBar layoutId="underline" />
              ) : null}
            </S.Tab>
            {isOpenOther ? (
              <S.OtherDropdown>
                {hiddenTabs.map(({ reactionType, count }) => (
                  <S.Tab key={reactionType}>
                    <S.ClickableTab
                      type="button"
                      onClick={() => {
                        setSelectedTab(reactionType);
                        setIsOpenOther(false);
                      }}
                      fullWidth
                    >
                      <em-emoji
                        shortcodes={getShortcodeFromType(reactionType)}
                        set="native"
                        size="14px"
                      />
                      <Text type="formSubtitle">{count}</Text>
                    </S.ClickableTab>
                  </S.Tab>
                ))}
              </S.OtherDropdown>
            ) : null}
          </S.HiddenTabsWrapper>
        ) : null}
      </S.TabWrapper>
      <S.StyledCloseButton
        type="button"
        variant="tertiary"
        theme={whiteTheme}
        icon="close"
        onClick={onClose}
      />
    </S.ModalHeader>
  );
};

export default ReactionModalHeader;
