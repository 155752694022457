import React, { useEffect, useState } from "react";
import __FEATURE_FUNCTION_CONFIGURATION__, {
  FeatureFlagConfiguration,
  FeatureFlagKey
} from "src/js/settings/settingsFeatureFlag";
import fetchFeatureConfig from "src/js/repository/featureFlagRepository";
import { readLocalData } from "src/js/modules/storageFunction";
import { captureException } from "@sentry/browser";
import { __LOCAL_FEATURE_FLAG__ } from "src/js/settings/settingsLocalStorage";

export const FeatureFlagContext = React.createContext<{
  localConfiguration: FeatureFlagConfiguration;
  remoteConfiguration: FeatureFlagConfiguration;
  defaultConfiguration: FeatureFlagConfiguration;
}>({
  localConfiguration: null,
  remoteConfiguration: null,
  defaultConfiguration: __FEATURE_FUNCTION_CONFIGURATION__
});

function getFeatureConfig(
  key: FeatureFlagKey,
  localConfiguration: FeatureFlagConfiguration,
  remoteConfiguration?: FeatureFlagConfiguration,
  defaultConfiguration?: FeatureFlagConfiguration
) {
  if (localConfiguration && typeof localConfiguration[key] !== "undefined") {
    return localConfiguration[key];
  }
  if (remoteConfiguration && typeof remoteConfiguration[key] !== "undefined") {
    return remoteConfiguration[key];
  }
  if (
    defaultConfiguration &&
    typeof defaultConfiguration[key] !== "undefined"
  ) {
    return defaultConfiguration[key];
  }
  return false;
}

export const useFeatureFlag = () => React.useContext(FeatureFlagContext);

export const FeatureFlag = ({
  flagKey,
  children
}: {
  flagKey: FeatureFlagKey;
  children: React.ReactNode;
}) => {
  return (
    <FeatureFlagContext.Consumer>
      {({ localConfiguration, remoteConfiguration, defaultConfiguration }) => {
        if (
          getFeatureConfig(
            flagKey,
            localConfiguration,
            remoteConfiguration,
            defaultConfiguration
          )
        ) {
          return children;
        }
        return null;
      }}
    </FeatureFlagContext.Consumer>
  );
};

export const isFeatureFlagEnabled = ({
  flagKey,
  featureFlagConfig
}: {
  flagKey: FeatureFlagKey;
  featureFlagConfig: {
    localConfiguration?: FeatureFlagConfiguration;
    remoteConfiguration?: FeatureFlagConfiguration;
    defaultConfiguration: FeatureFlagConfiguration;
  };
}) => {
  const { localConfiguration, remoteConfiguration, defaultConfiguration } =
    featureFlagConfig;

  // TODO we could use the new hook in this fn, so the component that use it must not pass "featureFlagConfig" anymore

  // const {
  //   localConfiguration,
  //   remoteConfiguration,
  //   defaultConfiguration
  // } = useFeatureFlag();

  return getFeatureConfig(
    flagKey,
    localConfiguration,
    remoteConfiguration,
    defaultConfiguration
  );
};

const FeatureFlagProvider = ({ children }) => {
  const [remoteConfiguration, setRemoteConfiguration] =
    useState<FeatureFlagConfiguration>(null);

  const defaultConfiguration = __FEATURE_FUNCTION_CONFIGURATION__;
  const localConfiguration: FeatureFlagConfiguration = JSON.parse(
    readLocalData(__LOCAL_FEATURE_FLAG__)
  );

  useEffect(() => {
    fetchFeatureConfig()
      .then(result => {
        setRemoteConfiguration(result as FeatureFlagConfiguration);
      })
      .catch(error => {
        captureException(error);
      });
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{ remoteConfiguration, defaultConfiguration, localConfiguration }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
