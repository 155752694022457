import styled, { css } from "styled-components";
import { ActionButton } from "@ds_universal/data_entry/ActionButton";
import { scrollbarMixin } from "@ds_themes/mixin";
import shadow from "@ds_themes/shadow";
import { VariantType } from "@ds_themes/types";
import colors from "@ds_themes/colors";

import {
  ModalSizeType,
  SModalProps,
  SModalScrollableArea
} from "./Modal.types";

export const Modal = styled.div<SModalProps>`
  position: relative;
  width: calc(100vw - 40px);
  border: ${({ border }) => border};
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: ${shadow[200]};
  overflow: hidden;
  background-color: ${colors.grey[1000]};
  pointer-events: all;
  ${({ size }) => {
    switch (size) {
      case ModalSizeType.Normal:
      default:
        return css`
          max-width: 500px;
        `;
      case ModalSizeType.Big:
        return css`
          max-width: 680px;
        `;
    }
  }}

  ${({ hasAdaptiveWidth }) =>
    hasAdaptiveWidth &&
    css`
      width: auto;
      max-width: unset;
    `}
`;

export const ProgressBarWrapper = styled.div`
  margin-top: -4px;
`;

export const StyledActionButton = styled(ActionButton)<{
  slot: "right" | "left";
}>`
  position: absolute;
  ${({ slot }) => {
    switch (slot) {
      case "right":
      default:
        return css`
          right: 10px;
        `;
      case "left":
        return css`
          left: 10px;
        `;
    }
  }}
`;

export const Title = styled.div<Pick<SModalProps, "border">>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  border-bottom: ${({ title, border }) => (title ? border : "0px")};
  @media screen and (max-width: 767px) {
    span {
      text-align: center;
      width: 80%;
    }
  }
`;

export const TitleText = styled.div`
  width: calc(100% - 120px);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ModalScrollableArea = styled.div<SModalScrollableArea>`
  overflow-y: auto;
  min-height: 400px;
  height: calc(90vh - 120px);
  ${({ size }) => {
    switch (size) {
      case ModalSizeType.Normal:
      default:
        return css`
          max-height: 600px;
        `;
      case ModalSizeType.Big:
        return css`
          max-height: 816px;
        `;
    }
  }}

  ${({ hasAdaptiveHeight }) =>
    hasAdaptiveHeight &&
    css`
      min-height: initial;
      max-height: calc(90vh - 120px);
      height: initial;
    `}

    ${scrollbarMixin(VariantType.White)}
`;
