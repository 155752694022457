import colors from "@ds_themes/colors";

export const wrapperchatavatar = {
  background: colors.grey[1000],
  text: colors.grey[50],
  message: colors.blueGrey[500],
  time: colors.grey[50],
  active: {
    background: colors.violet[501],
    text: colors.grey[1000]
  }
};
