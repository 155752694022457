import styled, { css } from "styled-components";
import { RESOURCE_ICON_TYPE } from "./ResourceIcon.const";

export const ColoredBackground = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size.container}px;
  height: ${({ size }) => size.container}px;
  border-radius: 4px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.grey[1000]};

  ${({ type }) =>
    type &&
    type === RESOURCE_ICON_TYPE.BOARD &&
    css`
      background-color: ${({ theme }) => theme.colors.lagoon[500]};
    `}

  ${({ type }) =>
    type &&
    type === RESOURCE_ICON_TYPE.FILE &&
    css`
      color: ${({ theme }) => theme.colors.lagoon[500]};
      background-color: ${({ theme }) => theme.colors.grey[1000]};
    `}

  ${({ type }) =>
    type &&
    type === RESOURCE_ICON_TYPE.LINK &&
    css`
      color: ${({ theme }) => theme.colors.success[500]};
      background-color: ${({ theme }) => theme.colors.grey[1000]};
    `}

  ${({ type }) =>
    type &&
    type === RESOURCE_ICON_TYPE.EXERCISE &&
    css`
      background-color: ${({ theme }) => theme.colors.violet[500]};
    `}

  ${({ type }) =>
    type &&
    (type === RESOURCE_ICON_TYPE.GDRIVE ||
      type === RESOURCE_ICON_TYPE.GOOGLE_DRIVE ||
      type === RESOURCE_ICON_TYPE.GOOGLE_MAPS ||
      type === RESOURCE_ICON_TYPE.DROPBOX) &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[1000]};
    `}
`;
