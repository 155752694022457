import {
  ActionButton,
  Button,
  Dropdown,
  Input,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { InputPhone } from "src/js/components/forms/InputPhone";
import { useOnboarding, useStores } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import { HubSpotOnboardingMarketingData } from "src/js/repository/hubspotRepository";
import { updateMarketingData } from "src/js/repository/marketingDataRepository";
import {
  __TESTIMONIAL_BACKGROUND_2__,
  __TESTIMONIAL_BRAND_2__,
  __TESTIMONIAL_IMAGE_2__
} from "src/js/settings/settingsImage";
import { TranslationKeys } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { EventDomain, OnboardingEvent } from "src/js/types";
import {
  MarketingDataFormFields,
  USER_TYPE
} from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { WeSchoolRoutes } from "../../Routes.const";
import StepperBlock from "../components/StepperBlock";
import Testimonial from "../components/Testimonial";
import { TitleBlock } from "../components/TitleBlock";
import {
  __FORM_MARKETING_DATA_SECOND_STEP__,
  companyRoleOptions,
  companySizeOptions,
  weschoolUseCaseOptions
} from "./MarketingData.utils";

import * as S from "./MarketingData.styles";

/**
 * @name MarketingData
 *
 * @description MarketingData component. This is the form that a new WeSchool User, that choose BUSINESS as userType, have to fill before the first space creation.
 *
 */

const MarketingData = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UserStore: { activeUser, userOnboarding, setUserOnboarding }
  } = useStores();

  const { flowSwitcher, routeGuard } = useOnboarding();

  useEffect(() => {
    routeGuard();
  }, []);

  const [country, setCountry] = useState({
    name: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dial_code: "+39"
  });

  const { register, handleSubmit, setValue, watch, clearError, errors } =
    useForm<{
      [key in keyof typeof __FORM_MARKETING_DATA_SECOND_STEP__]: string;
    }>({
      defaultValues: {
        companyName: userOnboarding?.marketingData?.companyName,
        companyRole: userOnboarding?.marketingData?.companyRole,
        companySize: userOnboarding?.marketingData?.companySize,
        weschoolUseCase: userOnboarding?.marketingData?.weschoolUseCase,
        phoneNumber: userOnboarding?.marketingData?.phoneNumber
      }
    });

  const companyName = watch("companyName");
  const companyRole = watch("companyRole");
  const companySize = watch("companySize");
  const weschoolUseCase = watch("weschoolUseCase");
  const phoneNumber = watch("phoneNumber");

  const [selectedCompanySize, setSelectedCompanySize] = useState(
    userOnboarding?.marketingData?.companySize
  );
  const [selectedCompanyRole, setSelectedCompanyRole] = useState(
    userOnboarding?.marketingData?.companyRole
  );
  const [selectedUseCase, setSelectedUseCase] = useState(
    userOnboarding?.marketingData?.weschoolUseCase
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (
      !companyName ||
      !companyRole ||
      !companySize ||
      !weschoolUseCase ||
      !phoneNumber
    ) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [companyName, companyRole, companySize, weschoolUseCase, phoneNumber]);

  const submitMarketingData = (formData: MarketingDataFormFields) => {
    setUserOnboarding({
      ...userOnboarding,
      step: WeSchoolRoutes.Onboarding.spaceCreation,
      marketingData: formData
    });
    updateMarketingData({ ...formData, userType: userOnboarding.userType })
      .then(marketingData => {
        HubSpotOnboardingMarketingData({
          ...formData,
          email: activeUser?.email
        });
        _trackEvent(
          EventDomain.Onboarding,
          OnboardingEvent.MarketingData,
          OnboardingEvent.MarketingData,
          formData
        );
        flowSwitcher(marketingData.userType);
      })
      .catch(error => {
        showToastError(error);
      });
  };

  const goBack = () => {
    setUserOnboarding({
      userType: USER_TYPE.BUSINESS,
      isCompleted: false,
      step: WeSchoolRoutes.Onboarding.welcome
    });
    navigateTo(WeSchoolRoutes.Onboarding.welcome);
  };

  const backButton = (
    <ActionButton
      variant="tertiary"
      icon="chevronSmallLeft"
      theme={whiteTheme}
      onClick={goBack}
    />
  );

  const marketingDataForm = (
    <S.Container>
      <TitleBlock
        title={translate("onboarding_business_marketing_data_page_title")}
        subtitle={translate("onboarding_business_marketing_data_page_subtitle")}
        backComponent={backButton}
      />
      <S.StyledForm
        id="form-onboarding-marketing-data"
        onSubmit={handleSubmit(submitMarketingData)}
      >
        <S.InputWrapper>
          <Input
            key="companyName"
            theme={whiteTheme}
            type="field"
            rounded
            label={translate(
              "oboarding_marketing_data_form_company_name_label"
            )}
            name="companyName"
            placeholder={translate(
              "onboarding_marketing_data_form_company_name_placeholder"
            )}
            ref={register(__FORM_MARKETING_DATA_SECOND_STEP__.companyName)}
            feedback={
              errors.companyName
                ? {
                    message: translate(
                      errors.companyName.message as TranslationKeys
                    ),
                    type: "error"
                  }
                : null
            }
          />
        </S.InputWrapper>
        <Text type="formSubtitle">
          <S.StyledLabel>
            {translate("onboarding_marketing_data_form_company_size_label")}
          </S.StyledLabel>
        </Text>
        <Dropdown
          id="companySize"
          theme={whiteTheme}
          optionsList={companySizeOptions().map(option => ({
            id: option.value,
            label: translate(option.label)
          }))}
          selectedOptionId={selectedCompanySize}
          setSelectedOptionId={id => {
            setSelectedCompanySize(id);
            setValue("companySize", id);
            clearError("companySize");
          }}
          placeholder={translate(
            "onboarding_marketing_data_form_company_size_placeholder"
          )}
          error={{
            message:
              errors.companySize &&
              translate(errors.companySize.message as TranslationKeys)
          }}
        />
        <input
          id="companySizeHidden"
          name="companySize"
          type="hidden"
          value={selectedCompanySize}
          ref={register(__FORM_MARKETING_DATA_SECOND_STEP__.companySize)}
        />
        <Text type="formSubtitle">
          <S.StyledLabel>
            {translate("onboarding_marketing_data_form_company_role_label")}
          </S.StyledLabel>
        </Text>
        <Dropdown
          id="companyRole"
          theme={whiteTheme}
          optionsList={companyRoleOptions().map(option => ({
            id: option.value,
            label: translate(option.label)
          }))}
          selectedOptionId={selectedCompanyRole}
          setSelectedOptionId={id => {
            setSelectedCompanyRole(id);
            setValue("companyRole", id);
            clearError("companyRole");
          }}
          placeholder={translate(
            "onboarding_marketing_data_form_company_role_placeholder"
          )}
          error={{
            message:
              errors.companyRole &&
              translate(errors.companyRole.message as TranslationKeys)
          }}
        />
        <input
          id="companyRoleHidden"
          name="companyRole"
          type="hidden"
          value={selectedCompanyRole}
          ref={register(__FORM_MARKETING_DATA_SECOND_STEP__.companyRole)}
        />
        <Text type="formSubtitle">
          <S.StyledLabel>
            {translate(
              "onboarding_marketing_data_form_weschool_use_case_label"
            )}
          </S.StyledLabel>
        </Text>
        <Dropdown
          id="weschoolUseCase"
          theme={whiteTheme}
          optionsList={weschoolUseCaseOptions().map(option => ({
            id: option.value,
            label: translate(option.label)
          }))}
          selectedOptionId={selectedUseCase}
          setSelectedOptionId={id => {
            setSelectedUseCase(id);
            setValue("weschoolUseCase", id);
            clearError("weschoolUseCase");
          }}
          placeholder={translate(
            "onboarding_marketing_data_form_weschool_use_case_placeholder"
          )}
          error={{
            message:
              errors.weschoolUseCase &&
              translate(errors.weschoolUseCase.message as TranslationKeys)
          }}
        />
        <input
          id="weschoolUseCaseHidden"
          name="weschoolUseCase"
          type="hidden"
          value={selectedUseCase}
          ref={register(__FORM_MARKETING_DATA_SECOND_STEP__.weschoolUseCase)}
        />
        <S.InputWrapper>
          <InputPhone
            label={translate("onboarding_marketing_data_phone_number_label")}
            theme={whiteTheme}
            country={country}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onChangeCountry={country => {
              setCountry(country);
            }}
            name="phoneNumber"
            ref={register(__FORM_MARKETING_DATA_SECOND_STEP__.phoneNumber)}
            fromTop
            error={{
              message: translate({
                text: errors?.phoneNumber?.message as TranslationKeys
              })
            }}
          />
        </S.InputWrapper>
        <S.SubmitWrapper>
          <Button
            type="submit"
            variant="primary"
            theme={whiteTheme}
            fullWidth
            disabled={!!errors?.phoneNumber || isSubmitDisabled}
          >
            {translate("onboarding_marketing_data_submit_button")}
          </Button>
        </S.SubmitWrapper>
      </S.StyledForm>
    </S.Container>
  );

  return (
    <OnboardingLayout
      mainContent={marketingDataForm}
      sideContent={
        <Testimonial
          background={__TESTIMONIAL_BACKGROUND_2__}
          image={__TESTIMONIAL_IMAGE_2__}
          text={translate("onboarding_testimonial_marketing_text")}
          author={translate("onboarding_testimonial_marketing_author")}
          brand={__TESTIMONIAL_BRAND_2__}
        />
      }
      stepper={<StepperBlock currentStep={1} totalSteps={4} />}
    />
  );
};

export default observer(MarketingData);
