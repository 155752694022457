import styled, { css } from "styled-components";
import { DROPDOWN_POSITION } from "./VendorDropdown.const";

const variantOptions = theme => ({
  dark: {
    backgroundColor: theme.colors.coldGrey[900],
    color: theme.colors.grey[50],
    borderColor: theme.colors.coldGrey[900],
    placeholderColor: theme.colors.grey[50],
    focus: {
      background: theme.colors.coldGrey[900],
      color: theme.colors.grey[50],
      border: theme.colors.coldGrey[900]
    },
    error: {
      color: theme.colors.error[600],
      border: theme.colors.error[600]
    },
    disabled: {
      background: theme.colors.coldGrey[900],
      color: theme.colors.blueGrey[700],
      border: theme.colors.coldGrey[900]
    }
  },

  light: {
    backgroundColor: theme.colors.basicWhite,
    color: theme.colors.primary2,
    borderColor: theme.colors.accent3,
    placeholderColor: theme.colors.accent2,
    focus: {
      background: theme.colors.basicWhite,
      color: theme.colors.primary2,
      border: theme.colors.primary1
    },
    error: {
      background: theme.colors.basicWhite,
      color: theme.colors.semantic1,
      border: theme.colors.semantic1
    },
    disabled: {
      background: theme.colors.disabledBk,
      color: theme.colors.blueGrey[500],
      border: theme.colors.lightBlueGrey
    }
  }
});

export const VendorDropdownContainer = styled.div`
  position: relative;
  font-size: 16px;
  margin: 16px 0px;
`;

export const VendorDropdownButton = styled.button`
  position: relative;
  font-weight: 500;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.grey[50]};
  border-color: ${({ theme }) => theme.colors.grey[1000]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  height: 40px;
  padding: 0 40px 0 20px;
  box-shadow: none;
  text-align: left;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  ${({ theme, variant }) =>
    variant &&
    css`
      background-color: ${variantOptions(theme)[variant].backgroundColor};
      color: ${variantOptions(theme)[variant].color};
      border: 1px solid ${variantOptions(theme)[variant].borderColor};

      ::-webkit-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      ::-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-ms-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      &:hover,
      &:focus {
        background-color: ${variantOptions(theme)[variant].focus
          .background}!important;
        color: ${variantOptions(theme)[variant].focus.color}!important;
        border-color: ${variantOptions(theme)[variant].focus.border}!important;
      }

      ${({ hasError }) =>
        hasError &&
        css`
          color: ${variantOptions(theme)[variant].error.color};
          border-color: ${variantOptions(theme)[variant].error.border};

          ::-webkit-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          ::-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-ms-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }
        `}

      ${({ disabled }) =>
        disabled &&
        css`
          cursor: not-allowed !important;
          opacity: 1;
          -webkit-text-fill-color: ${variantOptions(theme)[variant].disabled
            .color};
          -webkit-opacity: 1;
          background-color: ${variantOptions(theme)[variant].disabled
            .background};
          color: ${variantOptions(theme)[variant].disabled.color};
          border-color: ${variantOptions(theme)[variant].disabled.border};

          &:hover,
          &:focus {
            background-color: ${variantOptions(theme)[variant].disabled
              .background}!important;
            color: ${variantOptions(theme)[variant].disabled.color}!important;
            border-color: ${variantOptions(theme)[variant].disabled
              .border}!important;
          }
        `}
    `}
`;

export const VendorDropdownBody = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 16px;
  width: 100%;
  max-height: ${({ smallMenu }) => (smallMenu ? "220px" : "300px")};
  z-index: ${({ theme }) => theme.zIndex.comboBody};
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
  -webkit-padding-end: 0;
  scrollbar-color: ${({ theme }) =>
    theme.colors.accent3 + theme.colors.accent4};
  scrollbar-width: thin;

  ${({ menuPosition }) =>
    menuPosition === DROPDOWN_POSITION.UP
      ? css`
          bottom: 45px;
        `
      : css`
          top: 45px;
        `}

  ::-webkit-scrollbar {
    width: 25px;
    height: 25px;
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3};
    border: 10px solid transparent;
    background-clip: padding-box;
    border-radius: 13px;
    min-height: 60px;
    &:hover {
      background: ${({ theme }) => theme.colors.accent2};
      background-clip: padding-box;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.accent4};
    border: 10px solid transparent;
    background-clip: padding-box;
    border-radius: 13px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const VendorDropdownElement = styled.div`
  height: ${({ theme }) => theme.touchDimension};
  line-height: ${({ theme }) => theme.touchDimension};
  padding: 0 10px;
  border-radius: 20px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey[50]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    cursor: pointer;
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.coldGrey[900]};
    `}
`;

export const VendorName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AccountName = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
`;

export const DropdownBodyLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-transform: uppercase;
  margin: 10px;
`;

export const VendorDropdownButtonIcon = styled.span`
  top: 10px;
  right: 15px;
  position: absolute;
`;

export const IconContainer = styled.span`
  margin-left: 8px;
  font-size: 14px;
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 5px;
`;
