import React from "react";
import { translateString } from "src/js/translation";
import {
  SelectableListEmptyState,
  SelectableListEmptyStatusType
} from "../layout/SelectableList";
import {
  MigrateGroupsItemCard,
  MigrateGroupsItemCardProps
} from "./MigrateGroupsItemCard";
import {
  MigrateGroupsContextEnum,
  MigrateGroupsStepType
} from "./MigrateGroupsModal.types";

// TODO change the usage of this function, translateString is obsolete, try solutions
// that involves useTranslation hook (also for type safety in the end component)
export const getMigrationSteps = (): MigrateGroupsStepType[] => [
  {
    context: MigrateGroupsContextEnum.GroupsSelection,
    multipleSelect: true,
    modalLabels: {
      title: translateString({
        text: "migrate_groups_selection_title"
      }),
      mobileTitle: translateString({
        text: "migrate_groups_selection_title_mobile"
      })
    },
    // TODO missing the translated search placeholder
    listLabels: {
      translatedTitle: translateString({
        text: "migrate_groups_selection_title_content"
      }),
      translatedCancel: translateString({
        text: "migrate_groups_modal_cancel"
      }),
      searchPlaceholder: "Search...",
      translatedConfirm: translateString({
        text: "migrate_groups_selection_btn_confirm"
      })
    }
  },
  {
    context: MigrateGroupsContextEnum.SpaceSelection,
    multipleSelect: false,
    modalLabels: {
      title: translateString({
        text: "migrate_groups_destination_title"
      }),
      mobileTitle: translateString({
        text: "migrate_groups_destination_title_mobile"
      })
    },
    // TODO missing the translated search placeholder
    listLabels: {
      translatedTitle: translateString({
        text: "migrate_groups_destination_title_content"
      }),
      searchPlaceholder: "Search...",
      translatedCancel: translateString({ text: "migrate_groups_modal_back" }),
      translatedConfirm: translateString({
        text: "migrate_groups_destination_btn_confirm"
      })
    }
  }
];

export const getCustomItemCardFromContext = (
  context: MigrateGroupsContextEnum,
  spaceName: string
) => {
  const Element = (props: MigrateGroupsItemCardProps) => (
    <MigrateGroupsItemCard spaceName={spaceName} context={context} {...props} />
  );
  return Element;
};

export const getCustomEmptyStateFromContext = (
  context: MigrateGroupsContextEnum
) => {
  const emptySearchLabel =
    context === MigrateGroupsContextEnum.GroupsSelection
      ? "migrate_groups_selection_empty_search"
      : "migrate_groups_destination_empty_search";

  const emptyListLabel =
    context === MigrateGroupsContextEnum.GroupsSelection
      ? "migrate_groups_selection_empty_list"
      : "migrate_groups_destination_empty_list";

  const Element = (status: SelectableListEmptyStatusType) => (
    <SelectableListEmptyState
      status={status}
      emptySearchLabel={emptySearchLabel}
      emptyListLabel={emptyListLabel}
    />
  );
  return Element;
};
