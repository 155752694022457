import React, { useState } from "react";
import { Text } from "@arcadia/design-system";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "src/js/translation";
import { carouselVariants } from "./ExampleCarousel.utils";
import { ExampleCarouselProps } from "./ExampleCarousel.types";

import * as S from "./ExampleCarousel.styles";

const ExampleCarousel = ({
  items,
  title,
  onApplyExample = () => {},
  variant = "dark"
}: ExampleCarouselProps) => {
  const [positiveDirection, setPositiveDirection] = useState(true);
  const [position, setPosition] = useState(0);
  const { translate } = useTranslation();

  const handlers = useSwipeable({
    onSwipedRight: () => {
      setPositiveDirection(false);
      setPosition(position === 0 ? items.length - 1 : position - 1);
    },
    onSwipedLeft: () => {
      setPositiveDirection(true);
      setPosition(position === items.length - 1 ? 0 : position + 1);
    },
    trackMouse: true,
    trackTouch: true,
    delta: 65
  });

  return (
    <S.Wrapper variant={variant}>
      <S.Title type="formField">{title}</S.Title>
      <S.SwipeWrapper {...handlers}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {items.map(
            (item, index) =>
              index === position && (
                <motion.div
                  key={item}
                  style={{ width: "100%" }}
                  initial={positiveDirection ? "right" : "left"}
                  animate="current"
                  exit={positiveDirection ? "left" : "right"}
                  variants={carouselVariants}
                  transition={{
                    ease: "easeInOut",
                    x: { duration: 0.3 },
                    opacity: { duration: 0.4 }
                  }}
                >
                  <S.Example>{item}</S.Example>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </S.SwipeWrapper>
      <S.BottomWrapper>
        <S.DotWrapper>
          {[...Array(items.length)].map((_, index) => (
            <S.Dot
              onClick={() => {
                setPositiveDirection(index > position);
                setPosition(index);
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={`dot-${index}`}
              active={index === position}
            />
          ))}
        </S.DotWrapper>
        <S.Button
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
          onClick={() => onApplyExample(items[position])}
        >
          <Text type="cta">{translate("ai_course_hints_button")}</Text>
        </S.Button>
      </S.BottomWrapper>
    </S.Wrapper>
  );
};

export default ExampleCarousel;
