import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useQuery, useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import {
  LocalStorageKey,
  createLocalData,
  destroyLocalData
} from "src/js/modules/storageFunction";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { DashboardEvent, EventDomain } from "src/js/types";
import * as S from "./SwitchDashboard.styles";
import {
  SwitchDashboardMode,
  SwitchDashboardModeType
} from "./SwitchDashboard.types";

const SwitchDashboard = ({ className }: { className?: string }) => {
  const {
    SpaceStore: { activeSpaceId },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { queryParams, setQueryParams } = useQuery();

  const switchMode: SwitchDashboardModeType = queryParams.old
    ? SwitchDashboardMode.New
    : SwitchDashboardMode.Old;

  const handleSwitchMode = () => {
    const url = createUrl("dashboard_analytics", {
      space_id: activeSpaceId
    });
    if (switchMode !== SwitchDashboardMode.Old) {
      destroyLocalData(LocalStorageKey.SwitchDashboardAnalyticsVersion);
      _trackEvent(
        EventDomain.SpaceDashboardUsage,
        DashboardEvent.DashboardUserAccessOldDash
      );
      navigateTo(url);
      return;
    }
    createLocalData(
      LocalStorageKey.SwitchDashboardAnalyticsVersion,
      SwitchDashboardMode.Old
    );
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.OldDashboardUserAccess
    );
    setQueryParams({ old: "true" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [switchMode]);

  if (isLayoutModeMobile) {
    return (
      <S.SwitchDashboardMobileWrap className={className}>
        <S.SwitchDashboardMobileButton
          onClick={handleSwitchMode}
          className={className}
        >
          <S.SwitchDashboardMobileHeading level="6">
            {translate(`switch_dashboard_mobile_label_title_${switchMode}`)}
          </S.SwitchDashboardMobileHeading>
        </S.SwitchDashboardMobileButton>
      </S.SwitchDashboardMobileWrap>
    );
  }

  const labelTitle = translate(`switch_dashboard_label_title_${switchMode}`);
  const labelDescription = translate(
    `switch_dashboard_label_description_${switchMode}`
  );

  return (
    <S.SwitchDashboardButton
      onClick={handleSwitchMode}
      className={className}
      data-testid={`switch-mode-${switchMode}`}
    >
      <S.SwitchDashboardTitle type="formTitle">
        {labelTitle}
      </S.SwitchDashboardTitle>
      <S.SwitchDashboardDescription type="formTitle">
        {labelDescription}
      </S.SwitchDashboardDescription>
    </S.SwitchDashboardButton>
  );
};

export default observer(SwitchDashboard);
