import { GoogleButtonThemeOptions, Palette } from "@ds_themes/types";

export const createGoogleButtonTheme = ({
  general,
  primary
}: Palette): { googleButton: GoogleButtonThemeOptions } => {
  return {
    googleButton: {
      background: general.background,
      text: general.contrastText,
      border: `1px solid ${general.contrastText}`,
      hover: {
        border: `1px solid ${primary.main}`
      },
      focus: {
        border: `3px solid ${primary.light}`
      }
    }
  };
};
