import React from "react";
import { observer } from "mobx-react";
import {
  ActionButton,
  Button,
  Heading,
  Icon,
  Input,
  Text
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { __BOARD_ELEMENT_TITLE_MAX_LENGTH__ } from "src/js/settings/settingsCharLenght";
import { MessageBanner } from "src/js/components/MessageBanner";
import { DrawerEditBoard } from "src/js/components/DrawerEditBoard";
import { Animations } from "src/js/settings/animations";
import { useTranslation } from "src/js/translation";
import { MilestoneBanner } from "src/js/components/MilestoneBanner";
import { useModuleDetailsHeader } from "./hooks/useModuleDetailsHeader";

import * as S from "./SpaceModuleDetailsHeader.styles";

const SpaceModuleDetailsHeader = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    groupName,
    name,
    isMilestone,
    color,
    milestoneMessage,
    isTeacher,
    setEditMode,
    isPublished,
    goBackToModule,
    isMandatory,
    editMode,
    titleValueState,
    handleSubmission,
    handleInputChange,
    handleKeyPress,
    showBanner,
    setShowBanner,
    hasEditMode,
    id,
    isDrawerOpen,
    togglePublishFunction,
    setDrawerOpen,
    drawerContainerRef,
    isLayoutModeMobile,
    isStillUploading
  } = useModuleDetailsHeader();

  return (
    <S.SpaceModuleDetailsHeaderWrapper>
      <S.ContentWrapper>
        <S.LeftSection>
          <S.ActionButtonWrapper
            {...Animations.Fade}
            transition={{ delay: 0.2 }}
          >
            <ActionButton
              variant="secondaryColored"
              theme={whiteTheme}
              icon="chevronLeft"
              onClick={goBackToModule}
            />
          </S.ActionButtonWrapper>
          {!isLayoutModeMobile && (
            <S.MiddleSection {...Animations.Fade} transition={{ delay: 0.4 }}>
              {isMilestone && (
                <S.MileStoneWrapper>
                  <S.MilestoneFlag>
                    <MilestoneBanner color={color} />
                  </S.MilestoneFlag>
                </S.MileStoneWrapper>
              )}
              {!editMode ? (
                <S.Column>
                  <Text type="formSubtitle">{groupName}</Text>
                  <S.ModuleNameRow
                    onClick={() => {
                      if (isStillUploading) return;
                      setEditMode(hasEditMode);
                    }}
                    disabled={isStillUploading}
                  >
                    <Heading level="5">{name}</Heading>
                    <Icon icon="pencil" height={16} width={16} />
                  </S.ModuleNameRow>
                </S.Column>
              ) : (
                <S.InputWrapper onKeyPress={handleKeyPress}>
                  <Input
                    name="title"
                    label="title"
                    theme={whiteTheme}
                    hideLabel
                    value={titleValueState}
                    onBlur={handleSubmission}
                    onChange={handleInputChange}
                    maxLength={__BOARD_ELEMENT_TITLE_MAX_LENGTH__}
                    autofocus
                    rounded
                  />
                </S.InputWrapper>
              )}
              {!isMandatory && !isTeacher && (
                <S.OptionalTag type="label">
                  {translate("board_optional")}
                </S.OptionalTag>
              )}
            </S.MiddleSection>
          )}
        </S.LeftSection>
        {isLayoutModeMobile && (
          <S.ModuleNameRowMobile
            {...Animations.Fade}
            transition={{ delay: 0.4 }}
          >
            <S.HeadingMobile level="5">{name}</S.HeadingMobile>
          </S.ModuleNameRowMobile>
        )}
        {isTeacher && (
          <S.RightSection {...Animations.Fade} transition={{ delay: 0.6 }}>
            {!isLayoutModeMobile && (
              <Button
                variant="secondary"
                theme={whiteTheme}
                onClick={togglePublishFunction}
                disabled={isStillUploading}
              >
                {translate(
                  isPublished
                    ? "board_header_unpublish"
                    : "board_detail_header_publish"
                )}
              </Button>
            )}
            <ActionButton
              theme={whiteTheme}
              variant="secondaryColored"
              disabled={isStillUploading}
              onClick={() => setDrawerOpen(!isDrawerOpen)}
              icon="kebabVertical"
            />
          </S.RightSection>
        )}
      </S.ContentWrapper>
      <S.MilestoneMessageWrapper isVisible={showBanner}>
        <MessageBanner
          closeFunction={() => setShowBanner(false)}
          isVisible={showBanner}
          text={
            milestoneMessage ||
            translate("milestone_sidebar_input_default_value")
          }
        />
      </S.MilestoneMessageWrapper>
      <div ref={isLayoutModeMobile ? undefined : drawerContainerRef}>
        <DrawerEditBoard
          boardId={id}
          drawerOpen={isDrawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </div>
    </S.SpaceModuleDetailsHeaderWrapper>
  );
};

export default observer(SpaceModuleDetailsHeader);
