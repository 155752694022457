import { Text } from "@arcadia/design-system";
import React from "react";
import * as S from "./SpaceBadgeAssociatedGroupsModal.styles";

type BadgeAssociatedGroupsProps = {
  name: string;
  cover: string;
};

const SpaceBadgeAssociatedGroup = ({
  name,
  cover
}: BadgeAssociatedGroupsProps) => {
  return (
    <S.ModalGroupContainer>
      <S.ModalGroupImage src={cover} alt="Group-cover" />
      <S.ModalGroupName>
        <Text type="headerTitle">{name}</Text>
      </S.ModalGroupName>
    </S.ModalGroupContainer>
  );
};

export default SpaceBadgeAssociatedGroup;
