import useReactions from "src/js/components/reactions/hooks/useReactions";
import { updateReactionList } from "src/js/components/reactions/Reactions.utils";
import { useStores } from "src/js/hooks";
import { Post } from "src/js/types/models/Post";
import { REACTABLE_ENTITY, ReactionType } from "src/js/types/models/Reaction";

const usePostState = () => {
  const {
    PostCommentStore: { setNewPost }
  } = useStores();
  const { addReaction, removeReaction } = useReactions();

  const removeOldReaction = async ({ post }: { post: Post }) => {
    return removeReaction(post.reactions.reactionLoggedUser.id).then(() => {
      const newPost = { ...post };
      const newReactions = updateReactionList({
        oldReactions: newPost.reactions
      });
      newPost.reactions = newReactions;
      setNewPost(newPost.post.id, newPost);
    });
  };

  const addNewReaction = async ({
    type,
    post
  }: {
    type: ReactionType;
    post: Post;
  }) => {
    return addReaction(
      REACTABLE_ENTITY.POST,
      post.post.id as unknown as string,
      type,
      post.reactions?.reactionLoggedUser
    ).then(reaction => {
      const newPost = { ...post };
      const newReactions = updateReactionList({
        oldReactions: newPost.reactions,
        newReaction: reaction
      });
      newPost.reactions = newReactions;
      setNewPost(newPost.post.id, newPost);
    });
  };

  const onNewComment = ({ post }: { post: Post }) => {
    const newPost = { ...post };
    newPost.commentsCount += 1;
    setNewPost(newPost.post.id, newPost);
  };

  const onDeleteComment = ({
    post,
    nestedCommentNumber
  }: {
    post: Post;
    nestedCommentNumber?: number;
  }) => {
    const newPost = { ...post };
    newPost.commentsCount -= 1;

    if (nestedCommentNumber) {
      newPost.commentsCount -= nestedCommentNumber;
    }

    setNewPost(newPost.post.id, newPost);
  };

  return { addNewReaction, removeOldReaction, onNewComment, onDeleteComment };
};

export default usePostState;
