import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import filter from "lodash/filter";
import QuizCollection from "../../../collections/quizzesCollection";
import { __ } from "../../../modules/localization";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import moment from "moment";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import {
  Translate,
  translateString
} from "src/js/translation/TranslationProvider";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { getExerciseIconString } from "src/js/modules/exercisesFunction";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";

const MultiChoiceCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        execution: null,
        quiz_execution: null,
        corrector: null,
        number: 0
      };
    },
    render() {
      /* use collection in order to auto init correct submodel */
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const quiz = tempQuizCollection.at(0);
      const quiz_execution = this.props.quiz_execution;
      const execution = this.props.execution;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();
      let answersGiven = null;
      let answersFalseList = [];

      const quizType = quiz.getType();
      const quizIcon = getExerciseIconString(
        this.props.isVideoSubquiz ? "quizvideo" : quizType
      );

      let subQuizImage = null;
      if (this.props.isVideoSubquiz) {
        subQuizImage = (
          <Icon icon={getExerciseIconString(quizType)} width="30" height="30" />
        );
      }

      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));

      answersFalseList = filter(userAnswers, answer => answer.value == "false");

      const getTranslatedSolutionAnswer = solution => {
        if (solution === "true")
          return translateString({ text: "exercises_tests_see_results_true" });
        if (solution === "false")
          return translateString({ text: "exercises_tests_see_results_false" });
        return solution.toUpperCase();
      };

      if (answersFalseList.length == userAnswers.length) {
        answersGiven = map(questions, (val, key) => {
          const userAnswerValue = __("quiz_not_answered_label");

          let quiz_class = "is-wrong";
          if (
            userAnswerValue.toLowerCase() === solutions[key].value.toLowerCase()
          ) {
            quiz_class = "is-correct";
          }

          return (
            <tr>
              <td>
                <div className="correction_answer-item">
                  <strong>{questions[key].value}</strong>
                </div>
              </td>
              <td>
                <div className="correction_answer-item">
                  {getTranslatedSolutionAnswer(solutions[key].value)}
                </div>
              </td>
              <td>
                <div className="correction_answer-item">
                  <span
                    className={["exen_summary_quiz_label", quiz_class].join(
                      " "
                    )}
                  >
                    {getTranslatedSolutionAnswer(userAnswerValue)}
                  </span>
                </div>
              </td>
            </tr>
          );
        });
      } else {
        answersGiven = map(questions, (val, key) => {
          let userAnswerValue = __("quiz_not_answered_label");

          if (userAnswers[key] !== undefined && userAnswers[key].value != "") {
            userAnswerValue = userAnswers[key].value;
          }

          let quiz_class = "is-wrong";
          if (
            userAnswerValue.toLowerCase() === solutions[key].value.toLowerCase()
          ) {
            quiz_class = "is-correct";
          }

          return (
            <tr>
              <td>
                <div className="correction_answer-item">
                  <strong>{questions[key].value}</strong>
                </div>
              </td>
              <td>
                <div className="correction_answer-item">
                  {getTranslatedSolutionAnswer(solutions[key].value)}
                </div>
              </td>
              <td>
                <div className="correction_answer-item">
                  <span
                    className={["exen_summary_quiz_label", quiz_class].join(
                      " "
                    )}
                  >
                    {getTranslatedSolutionAnswer(userAnswerValue)}
                  </span>
                </div>
              </td>
            </tr>
          );
        });
      }

      let voteType;
      if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      const executionData = execution.get("updated_at");
      const executionDataFormatted = moment
        .unix(executionData)
        .format("DD/MM/YYYY HH:mm");
      const executionFromNow = moment.unix(executionData).fromNow();

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon icon={quizIcon} width="60" height="60" />
              <ColorLabel type={voteType}>
                <strong>
                  {quiz_execution.points}/{quiz.getAvailablePoints()}
                </strong>{" "}
                <Translate text={"quiz_points_label"} />
              </ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  {subQuizImage}
                  <ExerciseQuizTitle quizTitle={quiz.getTitle()} />
                </div>
              </div>
              <div className="correction_answers-wrapper unselectable">
                <div className="answer_info margin-bottom-10">
                  <CommentTitle>
                    {__("quiz_report_reply_of_label")}&nbsp;
                    {execution.getQuizExecutioner()}
                  </CommentTitle>
                  <div className="margin-left-10 margin-left-xs-0">
                    <CommentSubtitle>
                      {`${executionDataFormatted} (${executionFromNow})`}
                    </CommentSubtitle>
                  </div>
                </div>
                <table className="table table-condensed table-borderless small">
                  <tbody>
                    <tr>
                      <th>{__("domanda")}</th>
                      <th>{__("risposta corretta")}</th>
                      <th>{__("risposta utente")}</th>
                    </tr>
                    {answersGiven}
                  </tbody>
                </table>
              </div>
              {this.props.corrector}
            </div>
          </Col>
        </>
      );
    }
  })
);

export default MultiChoiceCorrectionDisplayer;
