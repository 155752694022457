import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import colors from "@ds_themes/colors";
import { scrollbarMixin } from "@ds_themes/mixin";
import shadow from "@ds_themes/shadow";
import { Text } from "@ds_universal/Text";
import { ProgressBar } from "@ds_universal/data_display";

import {
  SCloseButtonProps,
  SModalScrollableArea,
  SModalSheet
} from "./BottomModalSheet.types";
import { BOTTOM_MODAL_SHEET_BACKGROUND_COLOR } from "./BottomModalSheet.const";

export const TrasparentLayer = styled(motion.div)`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ModalSheet = styled(motion.div)<SModalSheet>`
  position: absolute;
  top: ${({ withAdaptiveHeight }) => (withAdaptiveHeight ? undefined : "32px")};
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  box-shadow: ${shadow[200]};
  overflow: hidden;
  pointer-events: all;
  ${({ variant }) => css`
    background-color: ${BOTTOM_MODAL_SHEET_BACKGROUND_COLOR[variant]};
  `}
`;

export const ProgressBarWrapper = styled.div`
  background-color: ${colors.violet[700]};
  height: 1px;
  display: flex;
  align-items: center;
`;

export const StyledProgressBar = styled(ProgressBar)`
  z-index: 1;
`;

export const Header = styled.div<{ withProgressBar: boolean }>`
  width: 100%;
  height: 56px;
  display: grid;
  grid-template-columns: 84px 1fr 84px;
  grid-column-gap: 8px;
  color: ${colors.grey[50]};
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ withProgressBar }) =>
    withProgressBar ? undefined : `1px solid ${colors.violet[700]}`};
`;

export const HeaderSubtitle = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  max-height: 16px;
`;

export const HeaderTitle = styled(Text)`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  overflow: hidden;
  max-height: 36px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

export const HeaderIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0px;
`;

export const Indicator = styled.div`
  width: 36px;
  height: 6px;
  background-color: ${colors.grey[600]};
  border-radius: 100px;
`;

export const DismissButton = styled.button<SCloseButtonProps>`
  padding: 0px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-align: center;
  border: none;
  background: none;
  padding-left: 16px;
`;

export const ConfirmButton = styled.button<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? colors.violet[700] : colors.violet[501]};
  border: none;
  background: none;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
`;

export const ModalScrollableArea = styled.div<SModalScrollableArea>`
  height: calc(100% - 58px);
  overflow-y: overlay;
  ${({ variant }) => scrollbarMixin(variant)}
`;
