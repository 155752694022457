import React from "react";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { Modal, Text } from "@arcadia/design-system";

import * as S from "./UsersListModal.styles";

const UsersListModal = ({
  closeModal = () => {},
  title = "",
  subtitle = "",
  users = []
}) => {
  return (
    <Translator>
      {({ translate }) => {
        return (
          <Modal onClose={closeModal} scrollable title={translate(title)}>
            <S.ModalContentContainer>
              <S.ModalContentTitle>
                <Translate text={subtitle} />
              </S.ModalContentTitle>
              {users.map(({ first_name, last_name, email }, idx) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <S.ModalUserCard key={idx}>
                    <S.ModalUserCardName>
                      <Text type="formTitle">
                        {first_name} {last_name}
                      </Text>
                    </S.ModalUserCardName>

                    <Text type="formSubtitle">{email}</Text>
                  </S.ModalUserCard>
                );
              })}
            </S.ModalContentContainer>
          </Modal>
        );
      }}
    </Translator>
  );
};

export default UsersListModal;
