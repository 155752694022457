import styled from "styled-components";
import { __NO_GROUPS_IN_SPACE_PLACEHOLDER__ } from "src/js/settings/settingsImage";

export const Wrapper = styled.div`
  max-width: 296px;
  width: 100%;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
`;

export const EmptyImage = styled.div`
  width: 126px;
  margin-bottom: 4px;
  height: 126px;
  background-image: url(${__NO_GROUPS_IN_SPACE_PLACEHOLDER__});
  background-size: cover;
`;
