import {
  ActionButton,
  Heading,
  Text,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import { Container } from "react-awesome-styled-grid";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import { MainContainer as MainContainerComp } from "src/js/components/layout/MainContainer";
import styled from "styled-components";

export const NakedInput = styled.input<{ inputLength: number }>`
  all: unset;
  font-weight: 700;
  font-size: 18px;
  max-width: calc(100vw - 300px);

  cursor: text;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.grey[200]};
  width: 8px;
`;

export const StyledHeading = styled(Heading)`
  text-wrap: nowrap;
  max-width: calc(100vw - 300px);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AutosaveContainer = styled.div`
  position: absolute;
  gap: 4px;
  right: 16px;
`;

export const StlyedActionButton = styled(ActionButton)`
  @media (max-width: 767px) {
    &:hover:not([disabled]) {
      background-color: transparent;
    }
  }
`;

export const AutosaveText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-wrap: nowrap;
`;

export const Wrapper = styled.div<{ isSidebarOpen?: boolean }>`
  height: 100vh;
  background: ${({ theme }) => theme.colors.grey[1000]};
  z-index: 930;
  width: 100%;
  position: absolute;
  top: 72px; // 72px is the height of the secondary bar
  left: 0;
  right: 0;
`;

export const ChangeTitleButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;

export const MainContainer = styled(MainContainerComp)`
  position: relative;
`;

export const UpdateTitleButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileHeader = styled(Container)`
  height: 72px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  z-index: ${({ theme }) => theme.zIndex.header};
`;

export const EditorWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;

  .EditorWrapper {
    width: 100%;
    overflow: auto;
    padding-top: 54px;
    padding-bottom: 460px;
    height: calc(100vh - 126px);
    ${scrollbarMixin(VariantType.White)}

    @media (max-width: 767px) {
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      min-width: unset;
      margin-top: 54px;
      height: calc(100vh - 54px);
      padding-top: 0px;
      padding-bottom: 380px;
    }
  }
  .ProseMirror {
    padding: 16px;
  }
`;

export const StyledSecondaryBar = styled(SecondaryBar)`
  height: 72px;
  position: sticky;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
  z-index: ${({ theme }) => theme.zIndex.sidebar};
`;
