const __OLD_PASSWORD_SETTINGS__ = {
  required: "password_required_error"
};

const __PASSWORD_SETTINGS__ = {
  // Da sostituire con nuovi sviluppi "pwd strength"
  required: "registration_password_error",
  pattern: {
    value: /^.{8,50}$/,
    message: "password_validation_error"
  }
};

export const __CHANGE_PASSWORD_SCHEMA__ = {
  oldPassword: __OLD_PASSWORD_SETTINGS__,
  newPassword: __PASSWORD_SETTINGS__,
  confirmNewPassword: __PASSWORD_SETTINGS__
};
