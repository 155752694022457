import React from "react";
import * as S from "./FormSubtitle.styles";

const FormSubtitle = ({ children, testId, ...props }) => {
  return (
    <S.FormSubtitleStyle data-testid={testId} {...props}>
      {children}
    </S.FormSubtitleStyle>
  );
};

export default FormSubtitle;
