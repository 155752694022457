import { Heading } from "@arcadia/design-system";
import { motion } from "framer-motion";
import { AppRoutingContext } from "src/js/types";
import styled, { css } from "styled-components";

export const DotMenuWrapper = styled.div`
  padding-left: 4px;
  padding-right: 12px;
  margin-left: auto;
`;

export const TopHeader = styled.header<{
  isDemoSpaceTopBannerVisible: boolean;
}>`
  background: ${({ theme }) => theme.colors.headerPrimaryColor};
  height: 54px;
  display: flex;
  flex-direction: row;
  z-index: ${({ theme }) => theme.zIndex.topHeader};
  align-items: center;
  color: ${({ theme }) => theme.colors.headerTextColor};
  text-align: center;
  width: 100%;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const SidebarToggleContainer = styled.div`
  padding-right: 16px;
  padding-left: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.headerTextColor};

  cursor: pointer;
`;

export const IconContainer = styled(motion.div)`
  display: flex;
`;

export const LeftContainer = styled.div<{
  isSidebarOpen: boolean;
  context: AppRoutingContext;
  withoutSidebar: boolean;
}>`
  display: flex;
  border-right: 1px solid transparent;
  border-right: ${({ isSidebarOpen, context, theme }) =>
    isSidebarOpen &&
    (context === "chat" ||
      context === "space" ||
      context === "groups" ||
      context === "dashboard") &&
    `1px solid ${theme.colors.headerSecondaryColor}`};
  align-items: center;

  ${({ withoutSidebar }) =>
    !withoutSidebar &&
    css`
      // This width Data should follow the width of the sidebar
      min-width: ${({ theme }) => theme.sizes.mainSidebarWidth.xl};
      width: ${({ theme }) => theme.sizes.mainSidebarWidth.xl};
      max-width: 375px;

      @media screen and (min-width: 1200px) and (max-width: 1439px) {
        min-width: ${({ theme }) => theme.sizes.mainSidebarWidth.lg};
        width: ${({ theme }) => theme.sizes.mainSidebarWidth.lg};
      }

      @media screen and (min-width: 992px) and (max-width: 1199px) {
        min-width: ${({ theme }) => theme.sizes.mainSidebarWidth.md};
        width: ${({ theme }) => theme.sizes.mainSidebarWidth.md};
      }
    `}
`;

export const ContextName = styled(Heading)`
  user-select: none;
  color: ${({ theme }) => theme.colors.headerTextColor};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchbarContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
  & input::placeholder {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0px auto;
  max-width: 980px;
`;

export const RightButtonsWrapper = styled.div<{ showButtons: boolean }>`
  margin-left: auto;
  margin-right: 16px;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;

  ${({ showButtons }) =>
    !showButtons &&
    css`
      min-width: 56px;
    `}
`;

export const ButtonMigrationWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  & span {
    white-space: nowrap;
  }
`;
