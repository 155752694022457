import React, { useState } from "react";
import { Button } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import useCreateOrEditBadge from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/useCreateOrEditBadge";
import * as S from "./styles";
import { DurationPeriodType } from "./types";
import BadgeDuration from "./components/BadgeDuration";
import BadgePrivacy from "./components/BadgePrivacy";
import BadgeAssignToGroups from "./components/BadgeAssignToGroups";

const BadgeOptionalFields = ({ isEditing }: { isEditing: boolean }) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceBadgesStore: {
      badgeOptionalFields,
      selectedBadgeRelatedGroups,
      badgeToEdit
    }
  } = useStores();
  const { id } = badgeToEdit || {};
  const [selectedDurationOption, setDurationOption] =
    useState<DurationPeriodType>(
      isEditing ? badgeOptionalFields.duration : null
    );
  const [durationActive, setDurationActive] = useState(
    isEditing ? !!badgeOptionalFields.duration : false
  );
  const [privacyActive, setPrivacyActive] = useState(
    isEditing ? badgeOptionalFields.isPrivate : false
  );
  const [isLoading, setLoading] = useState(false);
  const { createBadge, editBadge } = useCreateOrEditBadge({ setLoading });

  return (
    <S.BadgeOptionalFieldsContainer>
      <S.FieldsContainer>
        <BadgeDuration
          durationActive={durationActive}
          selectedDurationOption={selectedDurationOption}
          setDurationActive={setDurationActive}
          setDurationOption={setDurationOption}
        />
        <BadgePrivacy
          privacyActive={privacyActive}
          setPrivacyActive={setPrivacyActive}
        />
        {isEditing ? null : <BadgeAssignToGroups />}
      </S.FieldsContainer>
      <S.ButtonsContainer>
        {isEditing ? null : (
          <Button
            type="button"
            theme={whiteTheme}
            variant="secondary"
            disabled={!selectedBadgeRelatedGroups.length || isLoading}
            onClick={isEditing ? () => editBadge(id) : () => createBadge(true)}
          >
            {translate("badge_create_optional_fields_secondary_button")}
          </Button>
        )}
        {isLayoutModeMobile ? null : (
          <Button
            type="button"
            theme={whiteTheme}
            variant="primary"
            disabled={isLoading}
            onClick={isEditing ? () => editBadge(id) : () => createBadge(false)}
          >
            {translate(
              isEditing
                ? "badge_edit_optional_fields_primary_button"
                : "badge_create_optional_fields_primary_button"
            )}
          </Button>
        )}
      </S.ButtonsContainer>
    </S.BadgeOptionalFieldsContainer>
  );
};

export default observer(BadgeOptionalFields);
