export const dropdownAnimationVariants = {
  initial: {
    y: -20,
    opacity: 0,
    filter: "blur(12px)"
  },
  animate: {
    y: 0,
    opacity: 1,
    filter: "blur(0px)"
  },
  exit: {
    y: -20,
    opacity: 0,
    filter: "blur(12px)",
    transition: { duration: 0.1 }
  }
};
