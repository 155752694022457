import React, { useEffect, useRef, useState } from "react";
import { hasCollapsedHeight } from "src/js/modules/commonFunction";
import { useTranslation } from "src/js/translation";
import { Text, TextType } from "@arcadia/design-system";
import * as S from "./CollapsibleTextBox.styles";

const CollapsibleTextBox = ({
  text,
  lineNumber,
  textType
}: {
  text: string;
  lineNumber: number;
  textType: TextType;
}) => {
  const { translate } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const collapsibleNode = useRef();
  const [hasEllipsis, setHasEllipsis] = useState(false);

  useEffect(() => {
    setHasEllipsis(hasCollapsedHeight(collapsibleNode.current));
  }, []);

  if (!text) return null;

  return (
    <Text type={textType}>
      <S.CollapsableContent
        ref={collapsibleNode}
        collapsed={collapsed}
        lineNumber={lineNumber}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />
      {hasEllipsis && (
        <S.ShowMoreCta onClick={() => setCollapsed(!collapsed)} type="button">
          {translate(collapsed ? "more_label" : "less_label")}
        </S.ShowMoreCta>
      )}
    </Text>
  );
};

export default CollapsibleTextBox;
