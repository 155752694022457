import styled from "styled-components";

export const TextEditorLinkModalWrapper = styled.div`
  padding: 20px 20px 24px 20px;
  @media (max-width: 767px) {
    padding: 20px 16px 40px 16px;
  }
`;

export const Spacer = styled.div`
  margin-top: 16px;
`;

export const FooterWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  button {
    width: auto;
    min-width: 92px;
  }
`;
