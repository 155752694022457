import { colors } from "@arcadia/design-system";

export const calendar = {
  body: {
    background: colors.grey[1000]
  },
  label: {
    live: {
      background: colors.grey[800],
      color: colors.grey[100]
    },
    activity: {
      background: colors.lagoon[900],
      color: colors.lagoon[500]
    },
    deadline: {
      background: colors.warning[900],
      color: colors.warning[500]
    }
  },
  cell: {
    selected: {
      color: colors.violet[500]
    },
    hover: {
      background: colors.violet[500],
      color: colors.grey[1000]
    },
    text: {
      color: colors.blueGrey[200]
    },
    border: {
      color: colors.blueGrey[800]
    },
    current: {
      background: colors.violet[500],
      color: colors.grey[1000]
    },
    outer: {
      background: colors.blueGrey[900],
      color: colors.blueGrey[500]
    }
  }
};
