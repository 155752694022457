import {
  Box,
  Button,
  ModalSizeType,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { groupGetCover } from "src/js/GroupFunction";
import {
  SelectableImageCardItem,
  SelectableImageCardList
} from "src/js/components/SelectableList/SelectableImageCardList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import handleThumbnails from "src/js/modules/imageFunction";
import { useTemplates } from "src/js/query/spaces";
import { useTranslation } from "src/js/translation";
import { EventDomain, GroupDetailsModel, TemplatingEvent } from "src/js/types";
import styled, { DefaultTheme } from "styled-components";

export const ScrollableDiv = styled.div`
  height: 100%;
  max-height: calc(90vh - 244px);
  padding: 0px 12px;
  overflow: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  ${scrollbarMixin(VariantType.White)}
  @media (max-width: 767px) {
    max-height: 100%;
    padding: unset;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  display: flex;
  gap: 16px;
  margin: 16px 72px 0px;
  @media (max-width: 767px) {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 32px;
    margin: auto;
  }
`;

export type TemplateCustomSelectionProps = {
  theme: DefaultTheme;
  onSubmit: (templateGroup: GroupDetailsModel) => void;
  onGoBack: () => void;
};

const TemplateCustomSelection = ({
  theme,
  onSubmit,
  onGoBack
}: TemplateCustomSelectionProps) => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceName },
    SpaceStore: { activeSpaceId },
    ModalStore: { closeModal }
  } = useStores();
  const { trackVirtualView } = useViewTracking();

  useEffect(() => {
    _trackEvent(
      EventDomain.Templating,
      TemplatingEvent.CustomTemplateSelection
    );
    trackVirtualView(
      WeSchoolVirtualViewDescriptions.Groups.Templates.CustomTemplateSelection
    );
  }, []);

  const { data: templatesData } = useTemplates({
    spaceId: activeSpaceId
  });
  const templates = templatesData?.customTemplates;

  const list: SelectableImageCardItem[] = templates?.map(template => ({
    id: template?.group.id,
    title: template?.group.name,
    cover: handleThumbnails(groupGetCover(template?.group), "large"),
    description:
      template?.group.description ||
      translate("template_custom_card_description")
  }));

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(
    list[0]?.id
  );

  const handleSubmit = () => {
    const template = templates?.find(t => t.group.id === selectedTemplateId);
    onSubmit(template?.group);
  };

  return (
    <ModalLayout
      labels={{
        title: isLayoutModeMobile
          ? translate("template_custom_selection_title_mobile", {
              space: activeSpaceName
            })
          : translate("template_custom_selection_title")
      }}
      progressPercentage={50}
      closeModal={closeModal}
      mobileProps={{
        withDrag: false,
        ...(!onGoBack
          ? {}
          : {
              closeLabel: translate("back"),
              onClose: onGoBack
            })
      }}
      desktopProps={{ hasAdaptiveWidth: true, size: ModalSizeType.Big }}
    >
      <Box padding={isLayoutModeMobile ? "16px 16px 80px" : "32px 20px"}>
        <ScrollableDiv>
          <SelectableImageCardList
            list={list}
            onChange={id => {
              setSelectedTemplateId(id);
            }}
            selected={selectedTemplateId}
          />
        </ScrollableDiv>
        <FooterWrapper>
          {!isLayoutModeMobile && onGoBack ? (
            <Button
              variant="secondary"
              type="button"
              theme={theme}
              fullWidth
              onClick={onGoBack}
            >
              {translate("back")}
            </Button>
          ) : undefined}
          <Button
            variant="primary"
            type="button"
            theme={theme}
            fullWidth
            onClick={handleSubmit}
          >
            {translate("template_custom_selection_submit_cta")}
          </Button>
        </FooterWrapper>
      </Box>
    </ModalLayout>
  );
};

export default observer(TemplateCustomSelection);
