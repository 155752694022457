import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ContextualAlert.styles";
import { ContextualAlertProps } from "./types";

// TODO: this and the two other contextual components could've been unified into a single one
const ContextualAlert: FC<ContextualAlertProps> = ({ theme, text }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ContextualAlertContainer>
        <S.ContextualAlertIcon>
          <Icon icon="dangerCircle" width={16} height={16} />
        </S.ContextualAlertIcon>
        <S.ContextualAlertMessage>
          <Text type="cta">{text}</Text>
        </S.ContextualAlertMessage>
      </S.ContextualAlertContainer>
    </ThemeProvider>
  );
};

export default ContextualAlert;
