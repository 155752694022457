import { Icon, ImageViewer } from "@arcadia/design-system";
import React from "react";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { SpacesGroupType } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./AssessmentGroupItemCard.styles";

const AssessmentGroupItemCard = ({
  isActive,
  onClick,
  el
}: {
  isActive: boolean;
  el: SpacesGroupType;
  onClick: () => void;
}) => {
  const { primaryColor } = useTheme();

  return (
    <S.AssessmentGroupItemCardWrapper onClick={onClick} isActive={isActive}>
      <S.ImageContainer>
        <ImageViewer
          alt="group badge"
          url={el?.group?.cover?.small}
          fallbackImage={__GROUP_COVER_DEFAULT__}
        />
      </S.ImageContainer>
      <S.AssessmentGroupItemCardContent>
        <S.Description>
          <Icon icon="user" width={12} height={12} color={primaryColor[500]} />{" "}
          {el?.usersCount}
        </S.Description>
        <S.Title>{el?.group?.name}</S.Title>
      </S.AssessmentGroupItemCardContent>
      <S.CheckIndicator isSelected={isActive}>
        <Icon icon="circleCheck" width={24} height={24} initialViewbox />
      </S.CheckIndicator>
    </S.AssessmentGroupItemCardWrapper>
  );
};

export default AssessmentGroupItemCard;
