import { Button, VariantType, scrollbarMixin } from "@arcadia/design-system";
import { TemplateDescription } from "src/js/components/GroupCreationModal/components/TemplateDescription";
import styled from "styled-components";

export const StyledTemplateDescription = styled(TemplateDescription)`
  max-height: unset;
  margin: 8px;
`;

export const ScrollableList = styled.div`
  height: 100%;
  max-height: 420px;
  padding-right: 12px;
  overflow: auto;
  margin: 12px 0 16px;
  ${scrollbarMixin(VariantType.White)}
  @media (max-width: 767px) {
    max-height: initial;
    padding-right: unset;
    ul {
      grid-template-columns: 1fr;
    }
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.basicWhite};
    padding: 24px 20px 24px 20px;
    border-radius: 12px;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 12px;
`;

export const DescriptionWrapper = styled.div`
  display: none;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 767px) {
    position: sticky;
    bottom: 20px;
  }
`;
