import { Box, Button, Icon, Link } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const IconBannerClosed = styled(Icon)`
  flex-shrink: 0;
`;

export const ContentInfoBannerClosed = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 4px;
  width: 100%;
  padding: 0px 24px;
  align-items: center;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grey[50]} !important;
  &:focus {
    outline: none;
    border: none;
  }
`;

export const InfoBanner = styled(motion.div)`
  position: absolute;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  margin: auto;
  color: ${({ theme }) => theme.colors.grey[50]};
  top: 54px;
  @media screen and (max-width: 767px) {
    left: 0;
  }
`;

export const StickyInfoBannerContainer = styled(motion.div)`
  background: ${({ theme }) => theme.colors.yellow[500]};
  min-height: 8px;
  height: auto;
`;

export const StickyInfoBannerClosedContainer = styled.div`
  margin: 0 auto -2px auto;
  cursor: pointer;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 767px) {
    border-bottom-left-radius: initial;
    border-bottom-right-radius: initial;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const StickyInfoBannerClosed = styled(motion.div)`
  position: relative;
  background: ${({ theme }) => theme.colors.yellow[500]};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;

  @media screen and (max-width: 767px) {
    height: 42px;
    margin: 0 auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const ContentInfoBannerOpened = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;
`;

export const StickyInfoBannerOpened = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  padding: 0px 20px;
  max-width: 600px;
  gap: 12px;
  overflow: hidden;
`;

export const CloseInfoBanner = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin: -8px auto;
  padding: 12px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: ${({ theme }) => theme.colors.yellow[500]};
  cursor: pointer;
`;

export const SubmitButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
