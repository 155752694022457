/* eslint-disable react/jsx-no-useless-fragment */
import { Text } from "@arcadia/design-system";
import React, { useCallback, useEffect, useRef } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import {
  DOMAIN,
  EVENT,
  ThreadReadMessage
} from "src/js/types/models/WebSocket";
import { ThreadMessage } from "../ThreadMessage";
import { colorArray } from "./ThreadMessageList.const";
import * as S from "./ThreadMessageList.styles";
import { ThreadMessageListProps } from "./ThreadMessageList.types";
import { getGroupedMessages, getPillDate } from "./ThreadMessageList.utils";

const ThreadMessageList = ({
  threadData,
  removeMessageFromList,
  scrollToChatBottom,
  activeThreadId,
  users,
  oldestUnreadMessage,
  editableMessageId,
  setEditableMessage,
  createMessageReaction,
  deleteMessageReaction
}: ThreadMessageListProps) => {
  const {
    SpaceStore: { activeSpaceId },
    UserStore: { activeUser },
    WebSocketStore: { sendMessage },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  const newMessageBannerRef = useRef(null);

  const handleMarkAsRead = (messageId: string) => {
    const readMessageWS: ThreadReadMessage = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.READ_MESSAGE_EVENT,
      data: {
        id: messageId,
        userId: activeUser?.uuid,
        threadId: activeThreadId,
        spaceId: activeSpaceId
      }
    };
    sendMessage(readMessageWS);
  };

  useEffect(() => {
    if (newMessageBannerRef.current) {
      setTimeout(() => {
        newMessageBannerRef.current.scrollIntoView();
      }, 0);
    } else {
      scrollToChatBottom();
    }
  }, []);

  const threadContent = useCallback(() => {
    const ThreadMessages: React.ReactNode[] = [];
    const mappedMessages = getGroupedMessages(threadData);
    const unreadMessagesLabel = isLayoutModeMobile
      ? translate("thread_unread_messages_mobile_label")
      : translate("thread_unread_messages_label");

    mappedMessages.forEach(({ groupMessageDate, groupMessageList }) => {
      const pillDate = getPillDate(groupMessageDate);
      let showPillUnread = false;

      const DailyMessages = groupMessageList.map(
        (singleGroup, indexSingleGroup) => {
          const { messages, lastMessageDate, author } = singleGroup;
          const isSender = author === activeUser?.uuid;
          // The users is not updated
          const messageUserIndex = users.findIndex(
            user => user.uuid === author
          );

          const DayGroup = messages.map((msg, indexMsg) => {
            const isFirstMessage = indexSingleGroup === 0 && indexMsg === 0;
            const isOldestUnreadMessage =
              !isSender && oldestUnreadMessage?.id === msg?.id;
            if (isFirstMessage && isOldestUnreadMessage) {
              showPillUnread = true;
            }
            const unreadAlreadyShown = isFirstMessage && showPillUnread;
            const showSeparatorUnreadMessage =
              !unreadAlreadyShown && isOldestUnreadMessage;
            return (
              <div key={`ThreadMessages ${msg.id}`}>
                {showSeparatorUnreadMessage ? (
                  <S.SeparatorRelative ref={newMessageBannerRef}>
                    <S.PillUnread>
                      <Text type="body">{unreadMessagesLabel}</Text>
                    </S.PillUnread>
                  </S.SeparatorRelative>
                ) : null}
                <ThreadMessage
                  messageData={msg}
                  isSender={isSender}
                  removeMessageFromList={removeMessageFromList}
                  user={indexMsg === 0 ? users[messageUserIndex] : null}
                  userNameColor={colorArray[messageUserIndex % 8]}
                  lastMessageDate={lastMessageDate}
                  handleMarkAsRead={() => handleMarkAsRead(msg.id)}
                  isEditMode={editableMessageId === msg?.id}
                  setEditableMessage={setEditableMessage}
                  createMessageReaction={createMessageReaction}
                  deleteMessageReaction={deleteMessageReaction}
                />
              </div>
            );
          });
          return DayGroup;
        }
      );

      ThreadMessages.push(
        <S.DayWrapper
          key={pillDate}
          ref={showPillUnread ? newMessageBannerRef : null}
        >
          <S.SeparatorAbsolute />
          <S.PillStickyDay>
            <Text type="body">{pillDate}</Text>
          </S.PillStickyDay>
          {showPillUnread ? (
            <S.PillUnread>
              <Text type="body">{unreadMessagesLabel}</Text>
            </S.PillUnread>
          ) : null}
          {DailyMessages}
        </S.DayWrapper>
      );
    });

    return ThreadMessages;
  }, [threadData, editableMessageId]);

  return <>{threadContent()}</>;
};

export default ThreadMessageList;
