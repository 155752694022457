import { Icon } from "@arcadia/design-system";
import { default as React } from "react";

export type OrderState = "asc" | "desc" | "";

const OrderIcon = ({ orderState }: { orderState: OrderState }) => {
  let orderSymbol = <Icon icon="minus" width="12" height="12" />;
  switch (orderState) {
    case "asc":
      orderSymbol = <Icon icon="chevronLargeUp" width="15" height="15" />;
      break;
    case "desc":
      orderSymbol = <Icon icon="chevronLargeDown" width="15" height="15" />;
      break;
    default:
      orderSymbol = <Icon icon="minus" width="15" height="15" />;
      break;
  }
  return orderSymbol;
};

export default OrderIcon;
