import React, { useCallback } from "react";
import { fetchResourcesBoards } from "src/js/repository/boardRepository";
import {
  SelectableList,
  SelectableListEmptyStatusType
} from "src/js/components/layout/SelectableList";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { BasePaginationParams } from "src/js/types";
import { CardItemProps } from "./AddExerciseResource.types";
import { CustomCardItem, EmptyState } from "./components";
import * as S from "./AddExerciseResource.styles";

type Exercise = {
  id: number;
  name: string;
};

type AddExerciseProps = {
  onClose?: () => void;
  onSubmit: (exercise: Exercise) => void;
  setUploadingProgress?: (id: number, progress: number) => void;
  elementIndex?: number;
};

const AddExerciseResource = ({
  onClose,
  onSubmit,
  elementIndex,
  setUploadingProgress
}: AddExerciseProps) => {
  const {
    GroupStore: { activeGroup }
  } = useStores();
  const { translate } = useTranslation();

  const getEmptyState = (status: SelectableListEmptyStatusType) => (
    <EmptyState status={status} />
  );

  const fetchExerciseItems = useCallback(
    (params: BasePaginationParams & { name: string }) =>
      fetchResourcesBoards({
        type: "EXERCISE",
        group_id: activeGroup.id,
        ...params
      }),
    [activeGroup]
  );

  const getCustomItemCard = (props: CardItemProps) => (
    <CustomCardItem {...props} />
  );

  const handleSubmit = (ids: number[], list: Exercise[]) => {
    const selectedId = ids[0];
    const selectedItem = list.find(item => item.id === selectedId);
    if (setUploadingProgress) setUploadingProgress(elementIndex, 99);
    if (onClose) onClose();
    onSubmit(selectedItem);
  };

  return (
    <S.ListContainer id="exercise-modal-picker">
      <SelectableList
        withCancelButton={!!onClose}
        onCancel={onClose}
        onConfirm={handleSubmit}
        fetchItems={fetchExerciseItems}
        labels={{
          translatedConfirm: translate("add_exercise_resource_submit_cta"),
          searchPlaceholder: translate(
            "add_exercise_resource_search_placeholder"
          ),
          translatedCancel: translate("add_exercise_resource_cancel_cta")
        }}
        customComponents={{
          getEmptyStateFromStatus: getEmptyState,
          getCustomItemCard
        }}
        selectedItemsIds={[]}
      />
    </S.ListContainer>
  );
};

export default AddExerciseResource;
