import { useStores } from "src/js/hooks";
import { ModuleType } from "src/js/types";

type Props = {
  setSelectedFilter: (value: string) => void;
  groupId: number;
  selectedFilter: "ALL" | ModuleType;
};

export const useBoardFetch = ({
  setSelectedFilter,
  groupId,
  selectedFilter
}: Props) => {
  const {
    BoardsStore: { fetchBoardsList, fetchBoardsListNextPage }
  } = useStores();

  const handleIsPublished = (value: string) => {
    if (value === "ALL") return undefined;
    if (value === "isPublished") return 1;
    return 0;
  };

  const handleFilterChange = async (value: string) => {
    setSelectedFilter(value);
    const isPublished = handleIsPublished(value);
    await fetchBoardsList({
      groupId,
      isPublished
    });
  };

  const showNext = async () => {
    const isPublished = handleIsPublished(selectedFilter);
    await fetchBoardsListNextPage({ groupId, isPublished });
  };

  return { handleFilterChange, showNext };
};
