import colors from "@ds_themes/colors";

export const searchbarWithSuggestion = {
  background: colors.grey[1000],
  backgroundContainer: colors.grey[1000],

  text: colors.grey[50],
  textPlaceholder: colors.grey[300],
  border: `1px solid ${colors.coldGrey[900]}`,
  focus: {
    background: colors.coldGrey[900],
    outline: `3px solid ${colors.violet[700]}`
  },
  option: {
    background: colors.grey[1000]
  },
  disabled: {
    color: colors.grey[800]
  },
  font: {
    style: "normal",
    weight: "400",
    size: "16px",
    lineHeight: "19px"
  }
};
