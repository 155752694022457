import React from "react";
import { toast, ToastId } from "react-toastify";
import { ClosableWrapper } from "src/js/components/global/ClosableWrapper";
import { AxiosError } from "axios";
import { DefaultTheme } from "styled-components";
import {
  ClosableError,
  ClosableFeedback,
  ClosableInfo,
  ContextualFeedback,
  Toast
} from "@arcadia/design-system";

type ShowToastOptions = {
  str: React.ReactNode;
};

export function showToastError({ str = "" }: ShowToastOptions) {
  return toast.error(
    <ClosableWrapper>
      <ClosableError text={str} />
    </ClosableWrapper>,
    {
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    }
  );
}

export function showToastSuccess({ str = "" }: ShowToastOptions) {
  return toast.success(
    <ClosableWrapper>
      <ClosableFeedback text={str} />
    </ClosableWrapper>,
    {
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    }
  );
}

export function showToastSuccessPermanent({
  content = "",
  theme
}: {
  content: React.ReactNode;
  theme: DefaultTheme;
}) {
  return toast.success(
    <ClosableWrapper>
      <ContextualFeedback theme={theme}>{content}</ContextualFeedback>
    </ClosableWrapper>,
    {
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false
    }
  );
}

export function showToastInfo({ str = "" }: ShowToastOptions) {
  return toast.info(
    <ClosableWrapper>
      <ClosableInfo text={str} />
    </ClosableWrapper>,
    {
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    }
  );
}

export function showToastFunctional(str: React.ReactNode, theme: DefaultTheme) {
  toast.warn(
    <Toast theme={theme} hasIcon type="warning" visible>
      {str}
    </Toast>,
    {
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false
    }
  );
}

export function dismissToast(id: ToastId) {
  toast.dismiss(id);
}

// TODO: function has to be deprecate
export function extractErrorMessage(error, fallbackMessage) {
  try {
    const { message_id, message } = error;
    let messageId;
    let messageText;
    let return_error;

    if (error !== undefined) {
      messageId = message_id;
      messageText = message;
    }

    if (messageId !== undefined) {
      return_error = messageId;
    }

    if (return_error === messageId || return_error === undefined) {
      if (messageText === undefined) {
        return_error = fallbackMessage;
      } else {
        return_error = messageText;
      }
    }

    return return_error !== undefined ? return_error : "error";
  } catch {
    return fallbackMessage;
  }
}

// TODO: this one should be used
export const handleErrorMessage = (error: AxiosError) => {
  if (error.response.data) {
    throw new Error(error.response.data.message_id);
  }
  throw new Error("general_error");
};

export function extractErrorKey(error, fallbackMessage) {
  const { message_id } = error;
  if (message_id) {
    return message_id;
  }
  return fallbackMessage;
}

export function getErrorMessage(errorObj, fallbackMessage) {
  const { error_description } = errorObj;
  return error_description !== undefined ? error_description : fallbackMessage;
}
