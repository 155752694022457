import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStores } from "src/js/hooks";

const CookieBot = () => {
  const {
    UserStore: { isTraceable }
  } = useStores();

  useEffect(() => {
    if (isTraceable) {
      return;
    }
    if (window.CookiebotDialog?.DOM) {
      window.CookiebotDialog.DOM.remove();
    }
  }, [isTraceable]);

  if (!isTraceable) {
    return null;
  }

  return (
    <Helmet>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={process.env.__COOKIE_BOT_ID__}
        data-blockingmode="auto"
        type="text/javascript"
      />
    </Helmet>
  );
};

export default observer(CookieBot);
