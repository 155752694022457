import React, { forwardRef } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";

import { SpecificError } from "../../data_display/SpecificError";

import * as S from "./Input.styles";
import { InputProps } from "./types";

const Input = forwardRef<HTMLInputElement, InputProps>(function ForwardedInput(
  {
    theme,
    placeholder = "",
    label,
    hideLabel = false,
    icon,
    name,
    feedback,
    required = false,
    maxWidth = "100%",
    maxHeight,
    rounded = false,
    type = "field",
    onChange,
    onClick,
    value,
    onBlur,
    onFocus,
    onKeyPress,
    maxLength,
    rows = 1,
    disabled,
    isPassword,
    autofocus,
    autoComplete,
    showCounter,
    rightSection,
    error,
    readOnly = false,
    "data-testid": testId,
    ...rest
  },
  ref
) {
  return (
    <ThemeProvider theme={theme}>
      <S.Container maxWidth={maxWidth} {...rest}>
        <S.Label
          htmlFor={`inputText${label.replace(/\s/g, "")}`}
          visuallyHidden={hideLabel}
        >
          <Text type="formSubtitle">{label}</Text>
        </S.Label>
        <S.Wrapper rounded={rounded}>
          {icon ? (
            <S.IconWrapper colorIcon={value}>
              <Icon icon={icon} height="16" width="16" viewbox="0 0 24 24" />
            </S.IconWrapper>
          ) : null}
          {type === "field" ? (
            <S.Input
              id={`inputText${label.replace(/\s/g, "")}`}
              placeholder={placeholder}
              feedbackType={feedback?.type}
              type={isPassword ? "password" : "text"}
              required={required}
              maxWidth={maxWidth}
              onClick={onClick}
              withIcon={!!icon}
              readOnly={readOnly}
              withRightSection={!!rightSection}
              onChange={onChange}
              name={name}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              rounded={rounded}
              ref={ref}
              disabled={disabled}
              autoFocus={autofocus}
              autoComplete={autoComplete}
              data-testid={testId}
              maxLength={maxLength}
            />
          ) : (
            <S.TextareaContainer maxHeight={maxHeight}>
              <S.AutoGrowTextArea
                withRightSection={!!rightSection}
                maxHeight={maxHeight}
              >
                <S.Textarea
                  role="textbox"
                  id={`inputText${label.replace(/\s/g, "")}`}
                  name={name}
                  placeholder={placeholder}
                  feedbackType={feedback?.type}
                  required={required}
                  rounded={rounded}
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={onBlur}
                  onClick={onClick}
                  onFocus={onFocus}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  value={value}
                  maxLength={maxLength}
                  rows={rows}
                  readOnly={readOnly}
                  ref={
                    ref as unknown as React.ForwardedRef<HTMLTextAreaElement>
                  }
                  disabled={disabled}
                  autoFocus={autofocus}
                  autoComplete={autoComplete}
                  data-testid={testId}
                />
                <S.HiddenGrowText
                  feedbackType={feedback?.type}
                  required={required}
                >
                  {value}
                </S.HiddenGrowText>
              </S.AutoGrowTextArea>
              {maxLength && showCounter ? (
                <S.Counter>
                  <Text type="formSubtitle">
                    {value ? value.length : 0}/{maxLength}
                  </Text>
                </S.Counter>
              ) : null}
            </S.TextareaContainer>
          )}
          {rightSection ? (
            <S.RightSectionWrapper>{rightSection}</S.RightSectionWrapper>
          ) : null}
        </S.Wrapper>
        {error?.message ? (
          <SpecificError
            // TODO: fix typings
            theme={theme.input as unknown as DefaultTheme}
            text={error.message}
          />
        ) : null}
        {feedback && feedback.message ? (
          feedback.type === "error" ? (
            <SpecificError
              // TODO: fix typings
              theme={theme.input as unknown as DefaultTheme}
              text={feedback.message}
            />
          ) : (
            <S.Message feedbackType={feedback.type} type={type}>
              <Icon
                icon="successCircle"
                height="12"
                width="12"
                viewbox="0 0 24 24"
              />
              <Text type="label">{feedback.message}</Text>
            </S.Message>
          )
        ) : null}
      </S.Container>
    </ThemeProvider>
  );
});

export default Input;
