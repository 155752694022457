import { observer } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useStores } from "src/js/hooks";
import { getUrlParameterValue } from "src/js/modules/commonFunction";
import createUrl from "src/js/modules/routing";
import changeUrl from "src/js/modules/urlFunction";

const getDateFormat = () => {
  const startDate = moment()
    .startOf("month")
    .subtract(1, "months")
    .toDate()
    .toDateString()
    .replace(/ /g, "+");
  const endDate = moment()
    .startOf("month")
    .toDate()
    .toDateString()
    .replace(/ /g, "+");
  return { endDate, startDate };
};

const getFilterUrlParameters = query => {
  return {
    user_filter: getUrlParameterValue(query, "user_filter") || null,
    groups_filter: getUrlParameterValue(query, "groups_filter") || null,
    type: getUrlParameterValue(query, "type") || "post_and_comments",
    from_date:
      getUrlParameterValue(query, "from_date") ||
      moment()
        .startOf("month")
        .subtract(1, "months")
        .toDate()
        .toDateString()
        .replace(/ /g, "+"),
    to_date:
      getUrlParameterValue(query, "to_date") ||
      moment().startOf("month").toDate().toDateString().replace(/ /g, "+"),
    date_placeholder:
      getUrlParameterValue(query, "date_placeholder") ||
      "last_month_graph_interval",
    range: getUrlParameterValue(query, "range") || "day"
  };
};

const AnalyticsFiltersUrlHandler = () => {
  const { SpaceAnalyticsFilterStore, SpaceStore } = useStores();
  const { filterValuesForQueryParams } = SpaceAnalyticsFilterStore;
  const location = useLocation();

  useEffect(() => {
    if (SpaceStore?.activeSpaceId) {
      const urlSearchParams = new URLSearchParams();
      Object.keys(filterValuesForQueryParams).map(key => {
        return (
          filterValuesForQueryParams[key] &&
          urlSearchParams.append(key, filterValuesForQueryParams[key])
        );
      });
      const params = urlSearchParams.toString();
      const locationParams = location.search;
      // Here it is necessary to get the activeSpace ID directly from the store
      // to ensure using the last updated value
      if (params !== locationParams.substring(1)) {
        const analyticsUrl = createUrl("dashboard_analytics", {
          space_id: SpaceStore?.activeSpaceId
        });
        changeUrl(analyticsUrl, params);
      }
    }
  }, [filterValuesForQueryParams]);

  useEffect(() => {
    const query = location.search;
    const { startDate, endDate } = getDateFormat();
    if (query && !(query.includes(startDate) && query.includes(endDate))) {
      const filterUrlValues = getFilterUrlParameters(query);
      SpaceAnalyticsFilterStore.setFiltersFromQueryParams(filterUrlValues);
    }
  }, [location.search]);

  return null;
};

export default observer(AnalyticsFiltersUrlHandler);
