import { useStores } from "src/js/hooks";
import useSocket from "src/js/hooks/websocket/useSocket";
import {
  DOMAIN,
  EVENT,
  NotificationMessage
} from "src/js/types/models/WebSocket";

const useNotificationListener = () => {
  const {
    WebSocketStore: { socket },
    NotificationStore: { setPopoverNotification }
  } = useStores();

  const notificationHandler = (data: NotificationMessage) => {
    setPopoverNotification({ notification: data.data });
  };

  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_REACTION_POST,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.All_DAY_ACTIVITY_START_INCOMING,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.ACTIVITY_START_INCOMING,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_EDIT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_ELEMENT_DRAFT_APPROVED,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_ELEMENT_DRAFT_NEW,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_ELEMENT_DRAFT_REJECTED,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_LOCK_MODE,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_PUBLISH,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_UNLOCK_MODE,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXAMINATION_EXECUTION_FINISH,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_FINISH,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_REACTION_NESTED_BOARD_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXAMINATION_PUBLISH,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_REACTION_NESTED_POST_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_REACTION_BOARD_ELEMENT_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_REACTION_POST_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_COMMENT_NEW,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_CORRECTION_MODIFIED,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_CLOSED,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_REQUEST,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_PUBLISH,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_GROUP_INVITE_USER_ACCEPT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_MENTION_NESTED_POST_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_MENTION_NESTED_BOARD_ELEMENT_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_POST,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_POST_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_MENTION_POST,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_MENTION_POST_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_MENTION_BOARD_ELEMENT_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.LIVE_START_INCOMING,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_QUIZ_EXECUTION_REDO_OPEN,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_EXERCISE_EXECUTION_CORRECTION_FINISHED,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_NESTED_BOARD_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_NESTED_POST_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DO_BOARD_ELEMENT_COMMENT,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.DEADLINE_END_INCOMING,
    handler: notificationHandler
  });
  useSocket({
    socket,
    domain: DOMAIN.NOTIFICATIONS_DOMAIN,
    event: EVENT.GROUP_CLONING_COMPLETED,
    handler: notificationHandler
  });
};

export default useNotificationListener;
