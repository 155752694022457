import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./ContentSwitcher.styles";
import { ContentSwitcherProps } from "./types";

const ContentSwitcher: FC<ContentSwitcherProps> = ({
  theme,
  items,
  activeOption,
  onContentItemChange,
  squared,
  disabled: disabledAll
}) => {
  return (
    <ThemeProvider theme={theme}>
      {squared ? (
        <S.TabWrapperSquared
          role="tablist"
          aria-orientation="horizontal"
          tabIndex={-1}
        >
          {items.map(({ id, value, disabled, children }) => (
            <S.TabSquared
              id={`${id} - ${value}`}
              key={id}
              role="tab"
              aria-label={value}
              aria-selected={value === activeOption}
              disabled={disabled || disabledAll}
              type="button"
              tabIndex={0}
              onClick={() => {
                onContentItemChange(value);
              }}
            >
              {children}
            </S.TabSquared>
          ))}
        </S.TabWrapperSquared>
      ) : (
        <S.TabWrapper
          role="tablist"
          aria-orientation="horizontal"
          tabIndex={-1}
        >
          {items.map(({ id, value, disabled, children }) => (
            <S.Tab
              id={`${id} - ${value}`}
              key={id}
              role="tab"
              aria-label={value}
              aria-selected={value === activeOption}
              disabled={disabled || disabledAll}
              type="button"
              tabIndex={0}
              onClick={() => {
                onContentItemChange(value);
              }}
            >
              {children}
            </S.Tab>
          ))}
        </S.TabWrapper>
      )}
    </ThemeProvider>
  );
};

export default ContentSwitcher;
