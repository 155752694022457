import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`;

export const InputWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
  margin-top: auto;
`;

export const NewCommentWrapper = styled.div<{ isNestedComment?: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: ${({ isNestedComment }) =>
    isNestedComment ? "8px 0px 8px 0px" : "20px 0px 24px 0px"};
  border-top: ${({ isNestedComment, theme }) =>
    !isNestedComment && `1px solid ${theme.colors.grey[900]}`};
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  div {
    margin-top: 0px;
  }
  button {
    min-width: 40px;
  }
`;
