import { BaseComponentProps, Box, DoodleLoader } from "@arcadia/design-system";
import React, { FC, useEffect, useRef } from "react";
import { useTheme } from "styled-components";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  isLoadingBottom?: boolean;
  isEmpty?: boolean;
  EmptyContent?: React.ReactElement;
  HeaderContent?: React.ReactElement;
  onEndReached?: () => Promise<void>;
} & BaseComponentProps;

const ListView: FC<Props> = ({
  children,
  isLoading,
  isLoadingBottom,
  isEmpty,
  EmptyContent,
  HeaderContent,
  onEndReached,
  ...rest
}) => {
  const promiseRef = useRef<Promise<void>>();
  const { whiteTheme, greyTheme } = useTheme();
  const lastElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];

        if (entry.isIntersecting && !promiseRef.current) {
          promiseRef.current = (async () => {
            try {
              await onEndReached?.();
            } finally {
              promiseRef.current = undefined;
            }
          })();
        }
      },
      {
        rootMargin: "20px"
      }
    );

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [onEndReached]);

  if (isLoading) {
    return <DoodleLoader theme={whiteTheme} />;
  }

  if (isEmpty) {
    return EmptyContent || null;
  }

  return (
    <div {...rest}>
      <div>{HeaderContent}</div>
      {children}
      {isLoadingBottom ? (
        <Box margin="16px 0">
          <DoodleLoader theme={greyTheme} isMini />
        </Box>
      ) : null}
      <div ref={lastElementRef} />
    </div>
  );
};

export default ListView;
