import styled from "styled-components";

import { SocialLoginButton, Text } from "@arcadia/design-system";

export const LoginOptionsContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const Border = styled.div`
  height: 1px;
  flex: auto;
  background-color: ${({ theme }) => theme.colors.grey[800]};

  @media screen and (max-width: 767px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const OtherOptionsTitle = styled(Text)`
  margin: 0px 12px;
`;

export const LoginOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const ExternalProviderChangeLink = styled.div`
  text-align: center;
  margin-top: 16px;
  cursor: pointer;

  & > span {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: 767px) {
    flex: 3.5;

    & > div {
      height: 72px;
      flex: 1;
    }
  }
`;

export const SocialMobileButton = styled(SocialLoginButton)`
  width: auto;
  height: 100%;
  padding: 0px;
`;
