import React from "react";
import { Model } from "@weschooleng/backbone";
import isEmpty from "lodash/isEmpty";
import reject from "lodash/reject";
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import { getActiveGroup, getActiveGroupId } from "./activeGroup";
import { InternalDispatcher } from "./dispatcher";
import { __ } from "./localization";
import ExerciseModel from "../models/exerciseModel";
import { createURL } from "./utility";
import { getActiveUser, getUserId } from "./activeUser";
import { navigateTo } from "./history";
import { instantList } from "../collections/instantsCollection";
import swalGroup from "Images/swal-group.jpg";
import { handleAlert } from "src/js/modules/alertManager";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";

class Instants extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    // @deprecated
    this.activeInstantsList = []; // list of active instants in user groups
  }

  subscribeInstant(group_id, groupName, instant_id) {
    const user = getActiveUser();
    let subscribedInstants = [];

    if (
      !isUndefined(group_id) &&
      !isUndefined(groupName) &&
      !isUndefined(instant_id)
    ) {
      if (
        user.has("subscribedInstants") &&
        !isEmpty(user.get("subscribedInstants"))
      ) {
        subscribedInstants = user.get("subscribedInstants");
      }

      if (!this.checkInstantSubscription(instant_id)) {
        subscribedInstants.push({ group_id, groupName, instant_id });
      }

      user.set({ subscribedInstants });
      console.info(subscribedInstants);
    }
  }

  unsubscribeInstant(instant_id) {
    const user = getActiveUser();
    let subscribedInstants = [];

    if (
      user.has("subscribedInstants") &&
      !isEmpty(user.get("subscribedInstants")) &&
      !isUndefined(instant_id)
    ) {
      subscribedInstants = user.get("subscribedInstants");
      subscribedInstants = reject(
        subscribedInstants,
        instant => instant.instant_id == instant_id
      );
      user.set({ subscribedInstants });
    }
  }

  checkInstantSubscription(instant_id) {
    const user = getActiveUser();
    let subscribedInstants = [];
    let result = false;

    if (
      user.has("subscribedInstants") &&
      !isEmpty(user.get("subscribedInstants"))
    ) {
      subscribedInstants = user.get("subscribedInstants");

      if (!isUndefined(find(subscribedInstants, { instant_id }))) {
        result = true;
      }
    }

    return result;
  }

  showInstant(
    execution_id,
    exercise_id,
    group_id,
    role,
    groupName,
    exerciseName
  ) {
    // Modal di invito a instant
    const self = this;

    let alertText = __("_new_instant_in_group_", { group_name: groupName });
    if (role === "TEACHER") {
      alertText = __("_new_instant_in_group_teacher_", {
        group_name: groupName
      });
    }

    if (group_id === getActiveGroupId()) {
      if (role === "TEACHER") {
        alertText = __(
          "Un Docente ha lanciato un Instant in questo gruppo. Vuoi seguire lo svolgimento adesso?"
        );
      } else {
        alertText = __(
          "Un docente ha lanciato un instant in questo gruppo.<br/>Vuoi eseguirlo ora?"
        );
      }
    }
    handleAlert({
      text: alertText,
      className: "swal-modal--info",
      icon: swalGroup,
      buttons: {
        cancel: __("No, grazie"),
        confirm: __("Si, vai all'Instant")
      }
    }).then(isConfirm => {
      if (isConfirm) {
        instantList.fetch({
          success(collection, response, options) {
            InternalDispatcher.trigger("stopLoading");
            if (!isEmpty(collection.filterById(execution_id).models)) {
              if (role === "TEACHER") {
                self.subscribeInstant(group_id, groupName, execution_id);
              }

              self.executeInstant(
                execution_id,
                exercise_id,
                group_id,
                role,
                exerciseName
              );
            } else {
              showToastError({ str: __("instant_no_longer_available_error") });
            }
          },
          error() {
            InternalDispatcher.trigger("stopLoading");
          }
        });
      }
    });
  }

  executeInstant(execution_id, exercise_id, group_id, role, exerciseName) {
    const exerciseModel = new ExerciseModel({
      id: exercise_id
    });

    if (role === "TEACHER") {
      const reportURL = createURL("exercises_instants_live", {
        group_id,
        exercise_id,
        execution_id
      });
      navigateTo(reportURL, { trigger: true });
    } else {
      this.setActiveInstantId(execution_id);
      const runURL = createURL("exercises_instants_run", {
        group_id,
        exercise_id,
        execution_id
      });
      navigateTo(runURL, { trigger: true });
    }
  }

  hideInstant() {
    React.render(<div />, $("#super-overlay").get(0));
    $("#super-overlay").fadeOut();
  }

  endInstant(execution, automatic) {
    const group = getActiveGroup();
    const user = getActiveUser();
    const execution_id = execution.target.id;
    const agent_id = execution.agent.id;
    const agent_name = execution.agent.name;
    const group_id = execution.action.group_id;
    let warningText = "";
    if (
      group.isTeacher() &&
      getUserId() != agent_id &&
      this.checkInstantSubscription(execution_id)
    ) {
      if (!isUndefined(automatic) && automatic == true) {
      } else {
        const groupCurrent = find(user.get("subscribedInstants"), { group_id });
        warningText = `<strong>${__("warning")}!</strong> ${__(
          "instant_manual_stop_message",
          {
            agent_name,
            group_name: groupCurrent.groupName
          }
        )}`;
      }

      if (warningText != "") {
        showToastSuccess({ str: warningText });
      }
    } else if (
      group.isStudent() &&
      getActiveGroupId() == group_id &&
      !isUndefined(execution_id) &&
      this.getActiveInstantId() == execution_id
    ) {
      if (!isUndefined(automatic) && automatic == true) {
        warningText = `<strong>${__("warning")}!</strong> ${__(
          "exercise_time_expired"
        )}`;
      } else {
        warningText = `<strong>${__("warning")}!</strong> ${__(
          "instant_manual_stop_message",
          {
            agent_name,
            group_name: this.group.get("name")
          }
        )}`;
      }

      this.resetActiveInstantId();
      if (warningText != "") {
        showToastSuccess({ str: warningText });
      }
    }

    if (this.checkInstantSubscription(execution_id)) {
      this.unsubscribeInstant(execution_id);
    }
  }

  getActiveInstantId() {
    const user = getActiveUser();
    let result = 0;
    if (user.has("activeInstantId") && user.get("activeInstantId") > 0) {
      result = user.get("activeInstantId");
    }
    return result;
  }

  setActiveInstantId(execution_id) {
    const user = getActiveUser();
    if (!isUndefined(execution_id)) {
      user.set({ activeInstantId: execution_id });
    }
  }

  resetActiveInstantId() {
    const user = getActiveUser();
    user.set({ activeInstantId: 0 });
  }
}

export const instants = new Instants();
