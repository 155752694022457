import { ConfirmationAlert } from "@arcadia/design-system";
import React, { useEffect, useMemo, useState } from "react";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { deletePost, updatePinnedPost } from "src/js/repository/postRepository";
import { useTranslation } from "src/js/translation";
import { PostDetails } from "src/js/types/models/Post";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { ContextType, POST_CONTEXT } from "../Post.types";

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

type UseDropdownOptionsProps = {
  post: PostDetails;
  pinned: boolean;
  setPinnedFunc: (post: PostDetails) => void;
  onDeletePost: (postId: number, groupId: number) => void;
  embedded: boolean;
  context: ContextType;
};

const useDropdownOptions = ({
  post,
  pinned,
  setPinnedFunc,
  onDeletePost,
  context
}: UseDropdownOptionsProps) => {
  const {
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    GroupStore: { userIsTeacher, activeGroup },
    UserStore: { activeUser },
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const [dropdownOptions, setDropdownOptions] = useState<ListItemProps[]>([]);

  const isCurrentUserPost = useMemo(
    () => activeUser.uuid === post.user?.uuid,
    [activeUser, post]
  );

  const showEditPost = () => {
    navigateTo(
      createUrl("post_edit", {
        group_id: activeGroup.id,
        post_id: post.id
      })
    );
    if (context === POST_CONTEXT.MODAL) closeModal();
  };

  const destroyPost = () => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("post_delete_confirmation_message")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          deletePost({ postId: post.id })
            .then(() => {
              onDeletePost(post.id, activeGroup.id);
              if (context === POST_CONTEXT.MODAL) closeModal();
            })
            .catch(e => {
              const errorMsg = extractErrorMessage(
                e.responseJSON,
                "Attenzione, si è verificato un errore"
              );
              showToastError({ str: translate(errorMsg) });
            });
          closeConfirmDialog();
        }}
      />
    );
  };

  const pinPost = () => {
    updatePinnedPost({ postId: post.id }).then(() => {
      setPinnedFunc(post);
      scrollTop();
      _trackEvent("Wall", "WallPin", "Pin");
    });
    if (context === POST_CONTEXT.MODAL) closeModal();
  };

  const unpinPost = () => {
    updatePinnedPost({ postId: post.id }).then(() => {
      setPinnedFunc(post);
      scrollTop();
      _trackEvent("Wall", "WallPin", "Unpin");
    });
    if (context === POST_CONTEXT.MODAL) closeModal();
  };

  const reportAbuse = () => {
    const reportAbuseUrl = createUrl("report_abuse_post", {
      group_id: activeGroup.id,
      post_id: post.id
    });

    navigateTo(reportAbuseUrl);
    if (context === POST_CONTEXT.MODAL) closeModal();
  };

  useEffect(() => {
    const opts: ListItemProps[] = [];
    if (userIsTeacher) {
      opts.push({
        id: `${post.id}-${pinned ? "unpin" : "pin"}`,
        icon: "pin",
        label: translate(pinned ? "remove pin to top" : "pin to top"),
        onClick: pinned ? unpinPost : pinPost
      });
      if (isCurrentUserPost) {
        opts.push({
          id: `${post.id}-edit`,
          icon: "edit",
          label: translate("modifica"),
          onClick: showEditPost
        });
      }
      opts.push({
        id: `${post.id}-trash`,
        icon: "trash",
        label: translate("elimina"),
        onClick: destroyPost,
        redShade: true
      });
    }
    if (!isCurrentUserPost) {
      opts.splice(opts.length - 1, 0, {
        id: `${post.id}-report`,
        icon: "danger",
        label: translate("post_report_abuse_option_label"),
        onClick: reportAbuse
      });
    }
    setDropdownOptions(opts);
  }, [userIsTeacher, isCurrentUserPost, post, pinned]);

  return {
    dropdownOptions
  };
};

export default useDropdownOptions;
