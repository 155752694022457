/* eslint-disable  @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { PrepareWSResource } from "src/js/hooks/resources/useResourcePayload";
import { generateTempResource } from "src/js/modules/fileFunction";
import { showToastError } from "src/js/modules/messageManager";
import { SaveResourceResponseType } from "src/js/repository/resourceRepository";
import {
  __DROPBOX_APP_ID__,
  __RESOURCE_SIZE_LIMIT__
} from "src/js/settings/settingsResource";
import { useTranslation } from "src/js/translation";
import { RESOURCE_TYPE, Resource } from "src/js/types";

export type DropboxResourceProps = {
  onSubmit?: (tempResource?: Resource) => void;
  setUploadingProgress?: (id: number, progress: number) => void;
  onSuccess?: (response: SaveResourceResponseType) => void;
  onClose?: () => void;
  elementIndex?: number;
  prepareWSResource?: PrepareWSResource;
};

// TODO: change dropbox script whit a new version

const AddDropboxResource = ({
  onSubmit,
  onSuccess,
  setUploadingProgress,
  onClose,
  elementIndex,
  prepareWSResource
}: DropboxResourceProps) => {
  const { translate } = useTranslation();
  const { handleResourceDropboxUpload } =
    useResourceUpload(setUploadingProgress);
  const {
    BoardsStore: { setUploadingElementIndexes }
  } = useStores();

  const addResource = ({ file }) => {
    const originalUrl = file.link.replace("dl=0", "dl=1");
    if (file.bytes < __RESOURCE_SIZE_LIMIT__) {
      setUploadingElementIndexes(elementIndex);
      const tempResource = generateTempResource({
        type: RESOURCE_TYPE.DROPBOX
      });
      const resourceUploadHandler = () =>
        handleResourceDropboxUpload(file, originalUrl, elementIndex);

      if (onClose) onClose();

      if (prepareWSResource) {
        prepareWSResource(tempResource, resourceUploadHandler);
        return;
      }

      if (onSubmit) onSubmit(tempResource);
      resourceUploadHandler().then(response => {
        onSuccess(response);
      });
    } else {
      if (onClose) onClose();
      showToastError({
        str: translate("file_size_error")
      });
    }
  };
  const toggleDropbox = () => {
    const options = {
      multiselect: false,
      linkType: "preview",
      success(files) {
        addResource({ file: files[0] });
      }
    };
    // @ts-ignore
    Dropbox.choose(options);
  };

  useEffect(() => {
    const id = "dropboxjs";
    if (document.getElementById(id) === null) {
      const fileref = document.createElement("script");
      fileref.setAttribute("type", "text/javascript");
      fileref.setAttribute("data-app-key", __DROPBOX_APP_ID__);
      fileref.setAttribute("id", id);
      document.body.appendChild(fileref);
      fileref.setAttribute(
        "src",
        "https://www.dropbox.com/static/api/2/dropins.js"
      );
      fileref.onload = function onload() {
        toggleDropbox();
      };
    } else {
      toggleDropbox();
    }
  }, []);

  return null;
};

export default AddDropboxResource;
