import { colors } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const ResourceContainer = styled.div<{
  isSuggestedResource?: boolean;
  isDragged?: boolean;
  isBoardElementEnabled?: boolean;
}>`
  height: 335px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border: 1px solid transparent;
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    aspect-ratio: 0.9;
  }

  ${({ isSuggestedResource, isBoardElementEnabled }) =>
    // eslint-disable-next-line no-nested-ternary
    isSuggestedResource
      ? css`
          &:hover {
            & #suggested-resource-action-buttons-container {
              display: flex;
              gap: 16px;
            }
          }
        `
      : isBoardElementEnabled
        ? css`
            &:hover {
              border: 1px solid ${colors.violet[700]};
              & #initial-resource-title-heading {
                background-color: ${colors.coldGrey[900]};
              }

              & #resource-edit-icon {
                display: flex;
              }

              & #fake-input-field {
                display: flex;
              }

              & #thumbnail-icon-container {
                display: flex;
              }
            }
          `
        : css`
            cursor: not-allowed;
          `}
  ${({ isDragged }) =>
    isDragged &&
    css`
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      border: 1px solid ${colors.violet[500]};
      cursor: grabbing;
      &:hover {
        border: 1px solid ${colors.violet[500]};
      }
    `}
`;
