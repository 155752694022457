import styled, { css } from "styled-components";

export const ModalContentContainer = styled.div`
  padding: 0 30px;

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 20px;
    `}
`;

export const ModalContentSubtitle = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 8px;
`;

export const ModalContentTitle = styled.p`
  text-align: center;
  margin: 24px auto 16px;
`;

export const UserFullName = styled.span`
  flex: 1;
  margin-right: 15px;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UserSurname = styled.span`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const GroupName = styled.span`
  font-size: 16px;
  max-width: 70%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const ModalFooterContainer = styled.div`
  padding: 24px 20px 16px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;

  flex-direction: column-reverse;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    button {
      margin: 0 5px 10px;
    }
  }
`;

export const LoaderContainer = styled.div`
  text-align: center;
  padding: 60px 0px 30px;
`;

export const LoaderTitleContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamily.title};
  color: ${({ theme }) => theme.colors.primary2};
`;

export const ModalBannerContainer = styled.div`
  padding: 10px 30px 0;

  @media screen and (max-width: 767px) {
    padding: 10px 20px 0;
  }
`;

export const CaptionWrapper = styled.span`
  display: inline-block;
  margin-top: 4px;
  margin-left: 4px;
  > span {
    display: inline-block;
  }
`;
