import { Box, Input } from "@arcadia/design-system";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Calendar } from "src/js/components/Calendar";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { TranslationKeys } from "src/js/translation";
import { differenceInCalendarYears, isAfter, isBefore, sub } from "date-fns";
import { VisibilityToggle } from "../VisibilityToggle";
import { EditBirthdayProps } from "./type";

import * as S from "./EditBirthday.styles";

const EditBirthday = forwardRef<HTMLInputElement, EditBirthdayProps>(
  (
    { birthday, placeholder, showBirthday, onChangeShow, onChangeBirthday },
    ref
  ) => {
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [error, setError] = useState<string>(null);
    const { translate } = useTranslation();
    const { greyTheme } = useTheme();

    const isAdult = differenceInCalendarYears(new Date(), birthday) >= 18;

    const calendarRef = useRef<HTMLDivElement>();

    const handleHideCalendar: React.FocusEventHandler<HTMLDivElement> = e => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setCalendarOpen(false);
      }
    };

    useEffect(() => {
      if (isCalendarOpen && calendarRef.current) {
        calendarRef.current.focus();
      }
    }, [isCalendarOpen]);

    const handleChangeDate = (date: Date) => {
      const limitDate = sub(new Date(), { years: 18 });

      if (isAdult && isAfter(date, limitDate)) {
        setError("change_birthday_became_underage_error_message");
        return;
      }
      if (!isAdult && isBefore(date, limitDate)) {
        setError("change_birthday_became_adult_error_message");
        return;
      }
      setError(null);
      onChangeBirthday(date);
      setCalendarOpen(false);
    };

    if (!birthday) return null;
    return (
      <S.Wrapper onBlur={handleHideCalendar} tabIndex={0}>
        <Input
          label={translate({ text: "public_profile_birthday_label" })}
          theme={greyTheme}
          name="birthday"
          readOnly
          rounded
          placeholder={placeholder}
          onClick={() => setCalendarOpen(val => !val)}
          error={{
            message: translate({
              text: error as TranslationKeys
            })
          }}
          ref={ref}
          icon="birthday"
          rightSection={
            <VisibilityToggle visible={showBirthday} onChange={onChangeShow} />
          }
        />
        <Box position="absolute" right={0} top="72px">
          <Calendar
            visible={isCalendarOpen}
            selectedDate={birthday}
            onChange={handleChangeDate}
          />
        </Box>
      </S.Wrapper>
    );
  }
);

EditBirthday.displayName = "EditBirthday";

export default EditBirthday;
