import React, { FC } from "react";
import { Icon } from "@ds_universal/Icon";

import * as S from "./CheckIndicator.styles";
import { CheckIndicatorProps } from "./types";

const CheckIndicator: FC<CheckIndicatorProps> = ({ isSelected, theme }) => {
  return (
    <S.CheckIndicatorContainer isSelected={isSelected} theme={theme}>
      <Icon icon="check2" width={10} height={10} />
    </S.CheckIndicatorContainer>
  );
};

export default CheckIndicator;
