import map from "lodash/map";
import find from "lodash/find";
import QuizModel from "./quizModel";

class QuizShortAnswerModel extends QuizModel {
  getFormattedUserAnswer() {
    return [{ index: 0, value: this.getUserAnswer() }];
  }
  checkUserAnswer() {
    let ret = false;
    const answer = this.getUserAnswer().trim().toLowerCase();
    let solutions = this.getSolutions();

    // riconverto tutto a lower case, anche se dovrebbe arrivare già così da server
    solutions = map(solutions, s => {
      s.value = s.value.trim().toLowerCase();
      return s;
    });

    const item = find(solutions, { value: answer });
    if (item !== undefined) {
      ret = true;
    }

    return ret;
  }
}

export default QuizShortAnswerModel;
