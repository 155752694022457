import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { setActiveUser } from "src/legacy/modules/activeUser";

const SetLegacyActiveUserModel = () => {
  const { UserStore } = useStores();

  if (!UserStore.activeUser) return null;

  setActiveUser(UserStore.activeUser);

  return null;
};

export default observer(SetLegacyActiveUserModel);
