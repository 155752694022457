import React, { useEffect } from "react";
import { Text } from "@arcadia/design-system";
import { DateRowProps } from "./DateRow.types";

import * as S from "./DateRow.styles";

const DateRow = ({
  scrollContainerRef,
  date,
  onDateHidden,
  onDateVisible
}: DateRowProps) => {
  const itemRef = React.useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = React.useState(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const itemToCheck = itemRef.current;

    const checkScroll = () => {
      if (!scrollContainer || !itemToCheck) {
        return;
      }

      const itemRect = itemToCheck.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();

      const itemTopRelativeToContainer = itemRect.top - containerRect.top;

      if (itemTopRelativeToContainer < 0 && !isScrolled) {
        setIsScrolled(true);
        onDateHidden(date);
      } else if (itemTopRelativeToContainer >= 0 && isScrolled) {
        setIsScrolled(false);
        onDateVisible(date);
      }
    };

    scrollContainer.addEventListener("scroll", checkScroll);

    return () => scrollContainer.removeEventListener("scroll", checkScroll);
  }, [isScrolled, onDateVisible, onDateHidden, date]);
  return (
    <S.DateRow ref={itemRef}>
      <Text type="formSmallCaption">{date}</Text>
    </S.DateRow>
  );
};

export default DateRow;
