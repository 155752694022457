import { Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const ReactionListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const ViewAllReactionButton = styled.button<{
  hasInlineViewAll: boolean;
}>`
  all: unset;
  cursor: pointer;
  margin-bottom: ${({ hasInlineViewAll }) =>
    hasInlineViewAll ? "0px" : "12px"};
  &:hover {
    > span {
      text-decoration: underline;
    }
  }
`;

export const StyledText = styled(Text)<{
  hasInlineViewAll: boolean;
}>`
  color: ${({ theme }) => theme.colors.grey[300]};
  line-height: 14px;

  ${({ hasInlineViewAll }) =>
    hasInlineViewAll &&
    css`
      font-size: 12px;
      font-weight: 400;
    `}
`;

export const CustomTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 12px;
`;

export const ReactionElement = styled.div<{
  isActive: boolean;
  light: boolean;
}>`
  padding: 4px 12px;
  background-color: ${({ theme, light }) =>
    light ? theme.colors.grey[1000] : theme.colors.grey[900]};
  height: 24px;
  border-radius: 96px;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  cursor: pointer;

  gap: 4px;

  .emoji-mart-emoji {
    display: flex;
  }

  > span {
    line-height: 14px !important;
  }
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.primaryColor[800]};
      border-color: ${theme.primaryColor[500]};
      > span {
        font-weight: 600;
        color: ${theme.primaryColor[500]};
      }
    `}
`;
