import { ActionButton, Avatar, Box, Icon, Text } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { parseMentions } from "src/js/modules/mentionParser";
import { sanitizeEditableText } from "src/js/modules/textFunction";
import { useTranslation } from "src/js/translation";
import { BaseComment, NestedComment } from "src/js/types/models/Comment";
import {
  REACTABLE_ENTITY,
  ReactableEntity,
  ReactionType
} from "src/js/types/models/Reaction";
import { useTheme } from "styled-components";
import { DotsMenu } from "../../DotsMenu";
import TranslatedTooltip from "../../TranslatedTooltip";
import { ReactionList } from "../../reactions/ReactionList";
import { ReactionPicker } from "../../reactions/ReactionPicker";
import { useDropdownOptions } from "../hooks";
import NewCommentInput from "./NewCommentInput";

import SocialInput from "../../SocialInput/SocialInput";
import { UserProfileTooltip } from "../../UserProfileTooltip";
import * as S from "./Comment.styles";
import { CollapsibleTextBox } from "../../global/CollapsibleTextBox";

type CommentBoxProps = {
  entity: ReactableEntity;
  comment: BaseComment | NestedComment;
  commentIndex: number;
  onUpdate: (newText: string) => void;
  onRemoveReaction: (reactionId: string) => void;
  onAddReaction: (reactionType: ReactionType) => void;
  onDelete: () => void;
  onSubmitNewNestedComment?: (text: string) => void;
  hideActions?: boolean;
  hideReply?: boolean;
  customReplyAction?: () => void;
};

const CommentBox = ({
  entity,
  comment,
  onUpdate,
  onDelete,
  onSubmitNewNestedComment,
  onRemoveReaction,
  onAddReaction,
  hideActions,
  hideReply,
  commentIndex,
  customReplyAction
}: CommentBoxProps) => {
  const { user, createdAt, text } = comment;
  const [editMode, setEditMode] = useState(false);
  const {
    isOpen: isOpenEmojiPicker,
    ref: emojiRef,
    setIsOpen: setIsOpenEmojiPicker
  } = useCloseOnClickOutside(false);

  const [editText, setEditText] = useState(sanitizeEditableText({ text }));
  const [isOpenReply, setOpenReply] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { translate } = useTranslation();
  const { primaryColor, whiteTheme } = useTheme();
  const {
    GroupStore: { checkIfUserIsTeacher }
  } = useStores();

  const { dropdownOptions } = useDropdownOptions({
    comment,
    deleteComment: onDelete,
    setEditMode
  });

  const userName = `${user.name} ${user.surname}`;

  const isTeacher = checkIfUserIsTeacher(user.uuid);

  return (
    <Box position="relative">
      <S.CommentWrapper>
        <S.Header>
          <UserProfileTooltip user={user}>
            <Box display="flex" gap="12px">
              <Avatar
                url={user.avatar?.medium}
                alt={userName}
                size="s"
                theme={whiteTheme}
                rounded
              />
              <S.UsernameWrapper>
                <S.CustomText type="subHeaderTitle">
                  {user.name} {user.surname}
                </S.CustomText>
                {isTeacher ? (
                  <TranslatedTooltip tooltipString="teacher_badge_tooltip_label">
                    <Icon
                      icon="teacherRibbon"
                      height="16"
                      width="16"
                      color={primaryColor[500]}
                    />
                  </TranslatedTooltip>
                ) : null}
              </S.UsernameWrapper>
            </Box>
          </UserProfileTooltip>
          <S.DateText type="formSmallCaption">
            {format(createdAt * 1000, "dd/MM/yyyy HH:mm")}
          </S.DateText>
          {!editMode ? (
            <DotsMenu
              id={`commentBoxDotsMenu-${comment.id}`}
              items={dropdownOptions}
              alignRight
            />
          ) : null}
        </S.Header>
        <S.Body>
          {editMode ? (
            <>
              <SocialInput
                autoFocus
                renderBottom={
                  commentIndex <= 2 &&
                  entity === REACTABLE_ENTITY.BOARDELEMENT_COMMENT
                }
                maxHeight="60px"
                value={editText}
                placeholder={translate("nested_comment_placeholder")}
                variant="grey"
                onChange={event => {
                  setEditText(event.target.value);
                }}
              />
              <ActionButton
                type="button"
                onClick={() => {
                  onUpdate(editText);
                  setEditMode(false);
                }}
                theme={whiteTheme}
                variant="primary"
                icon="paperPlane"
              />
            </>
          ) : (
            <CollapsibleTextBox
              text={parseMentions(text, primaryColor[500])}
              lineNumber={6}
              textType="formSubtitle"
            />
          )}
        </S.Body>

        <ReactionList
          light
          entityId={comment.id as unknown as string}
          entity={entity}
          reactionsCounter={comment.reactions}
          onClick={reactionType => {
            if (
              reactionType ===
              comment.reactions.reactionLoggedUser?.reactionType
            ) {
              onRemoveReaction(comment.reactions.reactionLoggedUser.id);
              return;
            }
            onAddReaction(reactionType);
          }}
        />
      </S.CommentWrapper>
      {hideActions ? null : (
        <S.ActionsWrapper>
          <S.EmojiPickerWrapper ref={emojiRef}>
            <AnimatePresence>
              {isOpenEmojiPicker && (
                <S.AnimatedWrapper
                  initial={{ top: 0, scale: 0, opacity: 0 }}
                  animate={{ top: -56, scale: 1, opacity: 1 }}
                  exit={{ top: 0, scale: 0, opacity: 0 }}
                >
                  <ReactionPicker
                    onSelect={reactionType => {
                      onAddReaction(reactionType);
                      setIsOpenEmojiPicker(false);
                    }}
                  />
                </S.AnimatedWrapper>
              )}
            </AnimatePresence>

            <S.StyledButton
              onClick={() => {
                setIsOpenEmojiPicker(val => !val);
              }}
              isActive={isOpenEmojiPicker}
            >
              <Text type="ctaTertiary">{translate("comment_react_cta")}</Text>
              <Icon icon="emojiPlus" width="12px" height="14px" />
            </S.StyledButton>
          </S.EmojiPickerWrapper>
          {hideReply ? null : (
            <S.StyledButton
              onClick={() => {
                if (customReplyAction) {
                  customReplyAction();
                  return;
                }
                setOpenReply(true);
                inputRef.current?.focus();
              }}
            >
              <Text type="ctaTertiary">{translate("comment_reply_cta")}</Text>
              <Icon icon="reply" width="12px" height="12px" />
            </S.StyledButton>
          )}
        </S.ActionsWrapper>
      )}
      {isOpenReply ? (
        <NewCommentInput
          openMentionsFromBottom={
            commentIndex <= 2 &&
            entity === REACTABLE_ENTITY.BOARDELEMENT_COMMENT
          }
          newCommentInputRef={inputRef}
          isNestedComment
          onSubmit={nestedCommentText => {
            onSubmitNewNestedComment(nestedCommentText);
            setOpenReply(false);
          }}
        />
      ) : null}
    </Box>
  );
};

export default CommentBox;
