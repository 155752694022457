import styled from "styled-components";

export const ButtonWrapper = styled.div<{ isLayoutMobile: boolean }>`
  position: absolute;
  bottom: 20px;
  width: calc(100% - 72px);
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    width: calc(100% - 40px);
  }
`;
