import React from "react";
import {
  __RESOURCE_GALLERY_LIMITS__,
  __RESOURCE_GALLERY_MIME__
} from "src/js/settings/settingFileUploader";
import MultipleResourceUploader from "src/js/components/MultipleResourceUploader";
import { getActiveGroupId } from "Legacy/modules/activeGroup";

const ResourceImageGalleryEditor = props => {
  const { addFunc, resource, title } = props;
  return (
    <MultipleResourceUploader
      successCallback={addFunc}
      errorCallback={addFunc}
      maxSize={__RESOURCE_GALLERY_LIMITS__}
      mimeArray={__RESOURCE_GALLERY_MIME__}
      type="IMAGE_GALLERY"
      uploadText="resource_imagegallery_text"
      title={title}
      buttonText="add_gallery_button_lable"
      resource={resource}
      group_id={getActiveGroupId()}
    />
  );
};

export default ResourceImageGalleryEditor;
