import { motion } from "framer-motion";
import styled from "styled-components";

export const Indicator = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  position: relative;
  display: block;
  height: 4px;
  border-radius: 2px;
  margin-top: 6px;
`;

export const Progress = styled(motion.div)`
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;
