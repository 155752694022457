import { Box, Input, Text, colors } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { TemplateDescription } from "src/js/components/GroupCreationModal/components/TemplateDescription";
import {
  SelectableIconCardItem,
  SelectableIconCardList
} from "src/js/components/SelectableList/SelectableIconCardList";
import { useOnboarding, useStores } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import { useTemplates } from "src/js/query/spaces";
import { cloneGroup } from "src/js/repository/groupRepository";
import { __FORM_CREATE_BUSINESS_GROUP__ } from "src/js/settings/settingsFormValidation";
import { __GROUP_DESCRIPTION_LIMIT__ } from "src/js/settings/settingsGroups";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { EventDomain, OnboardingEvent } from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { WeSchoolRoutes } from "../../Routes.const";
import BackToCreateGroupSelection from "../components/BackToCreateGroupSelection";
import StepperBlock from "../components/StepperBlock";
import { TitleBlock } from "../components/TitleBlock";
import * as S from "./WeschoolTemplateCreation.styles";

const WeschoolTemplateCreation = () => {
  const { routeGuard, flowSwitcher } = useOnboarding();

  useEffect(() => {
    routeGuard();
  }, []);

  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    UserStore: { userOnboarding, setUserOnboarding },
    SpaceStore: { activeSpaceId },
    GroupStore: { setActiveGroupFull },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  // handle refresh edge case passing through the onboarding init check
  if (!activeSpaceId) {
    navigateTo(WeSchoolRoutes.Onboarding.initOnboarding);
    return null;
  }

  const { data: templatesData } = useTemplates({
    spaceId: activeSpaceId
  });
  const templates = templatesData?.weschoolTemplates;

  // i'm using the same configuration and error validation since is the same that creating a business group
  const { register, handleSubmit, setValue, errors } = useForm<{
    [key in keyof typeof __FORM_CREATE_BUSINESS_GROUP__]: string;
  }>();

  useEffect(() => {
    setValue("spaceId", activeSpaceId);
  }, []);

  const [descriptionState, setDescriptionState] = useState("");
  const [selectedGroupTemplateId, setSelectedGroupTemplateId] =
    useState<number>();
  const [showTemplateDescription, setShowTemplateDescription] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!templates?.length) return;
    setSelectedGroupTemplateId(templates[0]?.group?.id);
  }, [templates]);

  const selectedTemplate = useMemo(
    () =>
      templates?.find(
        template => template?.group?.id === selectedGroupTemplateId
      ),
    [templates, selectedGroupTemplateId]
  );

  useEffect(() => {
    setValue("name", selectedTemplate?.group?.name);
    setValue("description", selectedTemplate?.group?.description);
  }, [selectedTemplate]);

  const submitCloneGroup = async (payload: {
    name: string;
    description: string;
  }) => {
    setIsSubmitting(true);
    try {
      _trackEvent(
        EventDomain.Onboarding,
        OnboardingEvent.WeSchoolTemplateCreation
      );
      const data = await cloneGroup(
        { ...payload, targetSpaceId: activeSpaceId },
        selectedGroupTemplateId
      );
      setActiveGroupFull(data);
      setUserOnboarding({
        ...userOnboarding,
        step: WeSchoolRoutes.Onboarding.membersInvite
      });
      flowSwitcher(USER_TYPE.BUSINESS);
    } catch (error) {
      showToastError({ str: error.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const listSelectableIconCard: SelectableIconCardItem[] = templates?.map(
    template => ({
      id: template?.group?.id,
      description: translate(template?.templateInfo?.description),
      title: translate(template?.templateInfo?.title),
      icon: template?.templateInfo?.icon
    })
  );

  const WeSchooleTemplateSelectableList = (
    <S.StyledForm
      id="form-onboarding-marketing-data"
      onSubmit={handleSubmit(submitCloneGroup)}
    >
      <Input
        key="name"
        theme={whiteTheme}
        type="field"
        rounded
        label={translate("group_creation_form_name")}
        name="name"
        placeholder={translate("group_creation_form_name_placeholder")}
        ref={register(__FORM_CREATE_BUSINESS_GROUP__.name)}
        feedback={
          errors.name
            ? {
                message: translate(errors.name.message as TranslationKeys),
                type: "error"
              }
            : null
        }
      />
      <S.DescriptionWrapper>
        <Input
          type="textarea"
          key="description"
          name="description"
          rows={4}
          maxHeight="120px"
          maxLength={__GROUP_DESCRIPTION_LIMIT__}
          theme={whiteTheme}
          label={translate("group_creation_form_description")}
          placeholder={translate("group_creation_form_description_placeholder")}
          onChange={e => {
            setDescriptionState(e.target.value);
          }}
          value={descriptionState}
          ref={register(__FORM_CREATE_BUSINESS_GROUP__.description)}
          showCounter
          feedback={
            errors.description
              ? {
                  message: translate(
                    errors.description.message as TranslationKeys
                  ),
                  type: "error"
                }
              : null
          }
        />
      </S.DescriptionWrapper>
      <Box marginTop={16}>
        <Text type="formSubtitle">
          {translate("template_weschool_selection_subtitle")}
        </Text>
      </Box>
      <S.ScrollableList>
        <SelectableIconCardList
          selected={selectedGroupTemplateId}
          list={listSelectableIconCard}
          onChange={el => setSelectedGroupTemplateId(el.id)}
          labels={{
            footerCta: translate("preview")
          }}
          handleFooterCta={() => setShowTemplateDescription(true)}
        />
      </S.ScrollableList>
      <S.StyledButton
        variant="primary"
        type="submit"
        theme={whiteTheme}
        fullWidth
        disabled={isSubmitting}
      >
        {translate("template_weschool_selection_submit_cta")}
      </S.StyledButton>
    </S.StyledForm>
  );

  return (
    <>
      <OnboardingLayout
        mainContent={
          <S.MainContentContainer>
            <TitleBlock
              backComponent={<BackToCreateGroupSelection />}
              title={translate("group_template_creation_title")}
            />
            {WeSchooleTemplateSelectableList}
          </S.MainContentContainer>
        }
        sideContent={
          <Box
            background={colors.coldGrey[900]}
            height="100dvh"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={780}
          >
            <S.StyledTemplateDescription
              cover={selectedTemplate?.templateInfo?.cover}
              sections={selectedTemplate?.templateInfo?.sections}
              templateTitle={translate(
                selectedTemplate?.templateInfo?.templateTitle
              )}
              templateAbstract={translate(
                selectedTemplate?.templateInfo?.templateAbstract
              )}
            />
          </Box>
        }
        stepper={<StepperBlock currentStep={3} totalSteps={4} />}
      />
      {isLayoutModeMobile && showTemplateDescription ? (
        <TemplateDescription
          cover={selectedTemplate?.templateInfo?.cover}
          sections={selectedTemplate?.templateInfo?.sections}
          templateTitle={translate(
            selectedTemplate?.templateInfo?.templateTitle
          )}
          templateAbstract={translate(
            selectedTemplate?.templateInfo?.templateAbstract
          )}
          onClose={() => setShowTemplateDescription(false)}
        />
      ) : null}
    </>
  );
};

export default observer(WeschoolTemplateCreation);
