import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  color: ${({ theme }) => theme.resourceprogressbar.text};
  padding: 10px 20px;
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.resourceprogressbar.hover};
  }
`;

export const CircleProgressBar = styled.div`
  position: relative;
  & svg:nth-child(2) {
    position: absolute;
    top: 19px;
    left: 19px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.resourceprogressbar.text};
`;
