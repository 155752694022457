export function convertPriceInteger(price) {
  return parseFloat(String(price / 100));
}

export function getCurrencySymbol(currency: string) {
  switch (currency?.toLowerCase()) {
    case "eur":
      return "€";
    default:
      return "€";
  }
}
