import { Icon, Text } from "@arcadia/design-system";
import React, { FC } from "react";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { SelectableListEmptyStatusType } from "../SelectableList.types";
import * as S from "./SelectableListEmptyState.styles";

const SelectableListEmptyState: FC<{
  status: SelectableListEmptyStatusType;
  emptySearchLabel: TranslationKeys;
  emptyListLabel: TranslationKeys;
}> = ({ status, emptySearchLabel, emptyListLabel }) => {
  const { translate } = useTranslation();
  return (
    <S.SelectableListEmptyStateWrapper>
      <S.IllustrationContainer>
        <Icon
          icon="illustration-ghost"
          height={130}
          width={98}
          initialViewbox
        />
      </S.IllustrationContainer>
      {status === "empty-search" ? (
        <Text>{translate(emptySearchLabel)}</Text>
      ) : (
        <Text>{translate(emptyListLabel)}</Text>
      )}
    </S.SelectableListEmptyStateWrapper>
  );
};

export default SelectableListEmptyState;
