import Axios from "axios";
import React from "react";
import { api_oauth_logout } from "src/js/repository/apiUrls";
import { __API_UNVERSIONED_PATH__ } from "src/js/settings/settingsUrl";
import { destroyAccessToken } from "src/legacy/modules/ajax";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";

function logoutRequest() {
  return Axios({
    withCredentials: true,
    method: "post",
    url: __API_UNVERSIONED_PATH__ + api_oauth_logout()
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
}

const logout = () => {
  destroyAccessToken();

  logoutRequest().then(() => {
    sessionStorage.clear();
    window.location.reload();
  });
};

const LogoutPage = () => {
  const { greyTheme } = useTheme();
  logout();
  return <DoodleLoader theme={greyTheme} />;
};

export default LogoutPage;
