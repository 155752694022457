import {
  Avatar,
  Box,
  Text,
  skeletonAnimationMixin
} from "@arcadia/design-system";
import { NotificationAreaLabelModel } from "src/js/types/models/Notification";
import styled from "styled-components";
import { getPillColorFromType } from "../../NotificationPage.utils";

export const NotificationWrapper = styled.div<{ loading?: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  border-radius: 8px;
  transition: background-color 300ms ease-in-out;
  user-select: none;
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme, loading }) =>
        !loading && theme.colors.blueGrey[900]};
    }
  }
`;

export const Skeleton = styled(Box)`
  background-color: transparent;
  ${({ theme }) =>
    skeletonAnimationMixin(
      theme.colors.blueGrey[900],
      theme.colors.violet[800]
    )};
`;

export const AvatarContainer = styled.div<{ rounded: boolean }>`
  object-fit: cover;
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? "50%" : "4px")};
  background-color: ${({ theme }) => theme.colors.violet[900]};
`;

export const AvatarImage = styled(Avatar)`
  object-fit: cover;
  width: 40px;
  height: 40px;
`;

export const NotificationType = styled.div<{
  area: NotificationAreaLabelModel;
}>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(5px, 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[1000]};
  background-color: ${({ area }) => getPillColorFromType(area)};
`;

export const TitleRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const UnreadDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.violet[501]};
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
`;

export const SubtitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  margin-left: auto;
`;

export const TimeText = styled.span`
  color: ${({ theme }) => theme.colors.violet[501]};
  font-size: 8px;
  font-weight: 500;
`;
