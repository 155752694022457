/* eslint-disable react/no-array-index-key */
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  rectSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Col } from "react-awesome-styled-grid";
import { PreventNavigation } from "src/js/components/global/PreventNavigation";
import { ModulesResourceItem } from "src/js/components/modules/ModuleResourceItem";
import { ModulesResourceItemProps } from "src/js/components/modules/ModuleResourceItem/types";
import { DraggedResource } from "src/js/components/modules/ModuleResourcesList/components/DraggedResource";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { getResourceImage } from "src/js/modules/resourceFunction";
import { ModuleResourceType, ResourceType } from "src/js/types";
import * as S from "./ModuleResourcesList.styles";
import { ModuleResourceProps } from "./ModuleResourcesList.types";
import { ResourcePlaceholder, UploadingResource } from "./components";

type SortableModuleResourceProps = ModulesResourceItemProps & {
  id: number;
  key: string;
  removeResource: (id: number) => void;
  resourceItem: ModuleResourceType;
  isBoardElementEnabled?: boolean;
};

const SortableResourceItem = observer(
  ({ resourceItem, onClick, ...props }: SortableModuleResourceProps) => {
    const {
      GroupStore: { userIsTeacher }
    } = useStores();
    const [isEditMode, setEditMode] = useState(false);
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: resourceItem.id,
      disabled: !userIsTeacher || isEditMode
    });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition
    };

    // Placeholder item when dragging
    if (isDragging) {
      return (
        <S.PlaceholderModuleResource
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          data-moduleid={resourceItem.id}
          key={`moduleItem-dragged-${resourceItem.id}`}
        />
      );
    }

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        data-moduleid={resourceItem.id}
        key={`moduleItemWrapper-${resourceItem.id}`}
      >
        <ModulesResourceItem
          {...props}
          setDraggable={editMode => setEditMode(editMode)}
          onClick={isEditMode ? () => {} : onClick}
        />
      </div>
    );
  }
);

const ModuleResourcesList = ({
  moduleResourcesList,
  onClickResource,
  moduleId,
  moveModuleResource,
  removeResource
}: ModuleResourceProps) => {
  const [activeId, setActiveId] = useState(null);
  const { cancelRemainingUploads } = useResourceUpload();
  const {
    GroupStore: { activeGroup, roles },
    BoardsStore: {
      uploadingElementIndexes,
      resetUploadingElementIndexes,
      resetUploadingProgress,
      uploadingProgressOfBoardElement,
      getSingleBoard,
      getActiveBoardId
    }
  } = useStores();

  const activeBoard = getSingleBoard({
    groupId: activeGroup.id,
    boardId: getActiveBoardId
  });

  const isStudent = roles?.includes("ROLE_STUDENT");

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5
      }
    })
  );

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const newIndex = moduleResourcesList.find(item => {
        return item.id === over.id;
      });

      const position =
        newIndex?.position || newIndex?.position === 0
          ? newIndex?.position
          : over.id; // if next position is a placeholder the id of the placeholder matches at the next position

      moveModuleResource({ id: active.id, nextPosition: position });
    }

    setActiveId(null);
  };

  let firstEmptyObjectEncountered = false;
  let firstNotCompletedResourceEncountered = false;

  const hasMandatoryOrder = activeBoard?.hasMandatoryOrder;

  return (
    <>
      <PreventNavigation
        shouldBlock={!!uploadingElementIndexes.length}
        promptText="uploading_in_progress_warning_text"
        acceptText="uploading_in_progress_warning_accept_button"
        declineText="uploading_in_progress_warning_decline_button"
        onNavigateCb={() => {
          cancelRemainingUploads();
          resetUploadingElementIndexes();
          resetUploadingProgress();
        }}
      />
      <DndContext
        sensors={sensors}
        autoScroll
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCorners}
      >
        <SortableContext
          items={moduleResourcesList as unknown as string[]}
          strategy={rectSortingStrategy}
        >
          {moduleResourcesList.map((element, index) => {
            if (isEmptyObject(element)) {
              let indexAvailable = true;
              if (firstEmptyObjectEncountered) {
                indexAvailable = false;
              } else {
                firstEmptyObjectEncountered = true;
              }

              return (
                <Col xs={12} sm={6} md={4} key={`element-${index}`}>
                  {uploadingElementIndexes.includes(index) ? (
                    <UploadingResource
                      progress={
                        uploadingProgressOfBoardElement[index]?.progress
                      }
                    />
                  ) : (
                    <ResourcePlaceholder
                      id={index}
                      isOpen={activeBoard?.isOpen}
                      isIndexAvailable={
                        hasMandatoryOrder ? indexAvailable : true
                      }
                      hasMandatoryOrder={hasMandatoryOrder}
                      elementIndex={index}
                      moduleResourcesList={moduleResourcesList}
                    />
                  )}
                </Col>
              );
            }
            const resourceImage =
              element.preview_img_url ||
              getResourceImage(element.resource.attributes || element.resource);
            const resourceName = element.name || element.resource.name || "";
            const resourceUser = element.user || element.resource.attributes;

            let boardElementEnabled = true;
            if (!element.is_completed || firstNotCompletedResourceEncountered) {
              if (firstNotCompletedResourceEncountered) {
                boardElementEnabled = false;
              } else {
                firstNotCompletedResourceEncountered = true;
              }
            }
            return (
              <Col xs={12} sm={6} md={4} key={index + 1}>
                <SortableResourceItem
                  key={`moduleItem${element.id}`}
                  id={element.id}
                  resourceItem={element}
                  resourcePosition={element.position}
                  resourceId={element.id}
                  moduleId={moduleId}
                  title={resourceName}
                  author={resourceUser}
                  createdAt={format(
                    new Date(element?.created ? element.created * 1000 : null),
                    "d MMMM yyyy"
                  )}
                  isCompleted={element.is_completed}
                  resourcePoints={element.assigned_score || 0}
                  commentsCount={element.comments_count}
                  mimeType={element.resource.mime_type}
                  resourceImage={resourceImage}
                  onClick={() => onClickResource(element)}
                  resourceType={element.resource.type as ResourceType}
                  isBoardElementEnabled={
                    hasMandatoryOrder && isStudent ? boardElementEnabled : true
                  }
                  hasPreviewGenerated={
                    !!(
                      element.preview_img_url ||
                      element.resource.is_preview_generated
                    )
                  }
                  removeResource={removeResource}
                  resourcePublicUrl={element.resource.public_url}
                />
              </Col>
            );
          })}
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <DraggedResource
              moduleId={moduleId}
              resource={moduleResourcesList.find(o => {
                return o.id === activeId;
              })}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default observer(ModuleResourcesList);
