import { Input, colors } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Card = styled(motion.div)`
  padding: 40px 52px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  margin-left: 4px;
`;

export const InviteSpaceName = styled.span`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const Form = styled.form<{ hasErrors: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${({ hasErrors }) => (hasErrors ? "26px" : "32px")};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-top: -3px;
  border-radius: 50%;
  cursor: pointer;
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.violet[501]};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UniIconWrapper = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  margin-right: 4px;
`;

export const RegisterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin-bottom: 20px;
`;

export const CustomInput = styled(Input)<{ hasError: boolean }>`
  ${({ hasError }) =>
    hasError &&
    css`
      & > div {
        input {
          border: 1px solid ${colors.error[600]};
        }
      }
    `}
`;
