import React, { FC, PropsWithChildren } from "react";
import { DefaultTheme } from "styled-components";
import { ProgressBar } from "@ds_universal/data_display/ProgressBar";
import { Text } from "@ds_universal/Text";
import { modal } from "@ds_themes/general";
import { whiteTheme } from "@ds_themes/white";

import { ModalProps, ModalSizeType } from "./Modal.types";
import * as S from "./Modal.styles";

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  size = ModalSizeType.Normal,
  onBack,
  onClose,
  children,
  withProgressBar = false,
  percentage = 0,
  hasAdaptiveWidth = false,
  hasAdaptiveHeight = true
}) => {
  return (
    <S.Modal
      size={size}
      hasAdaptiveWidth={hasAdaptiveWidth}
      title={title}
      border={modal.border}
      onClick={event => event.stopPropagation()}
    >
      <S.Title title={title} border={modal.border}>
        {onBack && (
          <S.StyledActionButton
            theme={whiteTheme as unknown as DefaultTheme}
            icon="chevronLeft"
            variant="tertiary"
            onClick={onBack}
            slot="left"
          />
        )}
        {title ? (
          <S.TitleText>
            <Text type="subHeaderTitle">{title}</Text>
          </S.TitleText>
        ) : null}
        {onClose && (
          <S.StyledActionButton
            theme={whiteTheme as unknown as DefaultTheme}
            icon="close"
            variant="tertiary"
            onClick={onClose}
            slot="right"
          />
        )}
      </S.Title>
      {withProgressBar ? (
        <S.ProgressBarWrapper>
          <ProgressBar
            theme={whiteTheme as unknown as DefaultTheme}
            percentage={percentage}
          />
        </S.ProgressBarWrapper>
      ) : null}
      <S.ModalScrollableArea size={size} hasAdaptiveHeight={hasAdaptiveHeight}>
        {children}
      </S.ModalScrollableArea>
    </S.Modal>
  );
};

export default Modal;
