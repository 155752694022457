import { ConfirmationAlert, Text } from "@arcadia/design-system";
import React, { useCallback, useState } from "react";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { useCreateQuickThread } from "src/js/pages/App/Spaces/hooks";
import { GroupResendInviteModal } from "src/js/pages/group/settings/GroupResendInviteModal";
import {
  changeUserRole,
  deleteInvite,
  fetchGroupUsers,
  getAllGroupMembers,
  inviteUsers,
  removeUser
} from "src/js/repository/groupRepository";
import { __GROUP_MEMBERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { ChatEvent, EventDomain } from "src/js/types";
import {
  Member,
  MemberNonAdmin,
  PaginatedMembers,
  Role
} from "./GroupSettingsMembers.types";

export const useGroupMembers = () => {
  const {
    GroupStore: {
      activeGroup: group,
      roles,
      alias,
      groupInvitations,
      fetchGroupInvitations
    },
    SpaceStore: { canCreateChats },
    ModalStore: { openModal, closeModal },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    UserStore: { activeUser }
  } = useStores();
  const { navigateToThread } = useCreateQuickThread();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const [members, setMembers] = useState<Member[]>([]);
  const [membersNonAdmin, setMembersNonAdmin] = useState<MemberNonAdmin[]>([]);
  const [loadingMembersNonAdmin, setLoadingMembersNonAdmin] = useState(false);
  const [totalMembersNonAdmin, setTotalMembersNonAdmin] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingInvitations, setLoadingInvitations] = useState(false);

  const isCreator = roles?.includes(Role.Creator);
  const isTeacher = roles?.includes(Role.Teacher);
  const isStudent = roles?.includes(Role.Student);

  const getMembers = (offset: number) => {
    if (!group?.id) return;
    setLoadingMembers(true);
    getAllGroupMembers(
      group.id,
      __GROUP_MEMBERS_LIST_LIMIT__,
      offset,
      isTeacher || isCreator,
      Date.now()
    )
      .then((data: PaginatedMembers) => {
        setMembers(data.results);
        setTotalMembers(data.total);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingMembers(false);
      });
  };

  const getInvitations = () => {
    setLoadingInvitations(true);
    fetchGroupInvitations(group.id)
      .catch(() => {
        showToastError({ str: translate("group_not_available") });
      })
      .finally(() => setLoadingInvitations(false));
  };

  const sendInvite = (newMail: string, inviteRole: string) => {
    const emailsToInvite = [newMail];
    const data = {
      alias,
      inviteRole,
      userEmails: emailsToInvite
    };
    inviteUsers(group.id, data)
      .then(() => {
        showToastSuccess({ str: translate("group_invite_emails_1") });
        _trackEvent("Group", "GroupInvite", "EmailLink", emailsToInvite.length);
        closeModal();
        getInvitations();
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const reSendInvite = (id: number, newMail: string, inviteRole: string) => {
    deleteInvite(id)
      .then(() => {
        sendInvite(newMail, inviteRole);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const cancelInvite = (inviteId: number) => {
    deleteInvite(inviteId)
      .then(() => {
        showToastSuccess({ str: translate("invite_deleted_message") });
        closeModal();
        getInvitations();
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const showResendInviteModal = (
    id: number,
    email: string,
    inviteRole: string
  ) => {
    openModal(() => (
      <GroupResendInviteModal
        closeModal={() => closeModal()}
        defaultMail={email}
        reSendFunction={(newMail: string) =>
          reSendInvite(id, newMail, inviteRole)
        }
      />
    ));
  };

  const showCancelInviteModal = (inviteId: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("cancel_invite_message")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          cancelInvite(inviteId);
          closeConfirmDialog();
        }}
      />
    );
  };

  const hasRole = useCallback((desired: Role, memberRoles: Role[]) => {
    return memberRoles.includes(desired);
  }, []);

  const removeMember = (user: Member, page: number) => {
    removeUser(group.id, user.id)
      .then(() => {
        showToastSuccess({
          str: translate("group_user_removed_message")
        });
        closeModal();
        getMembers(page);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const showRemoveMemberModal = (user: Member, page: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={
          user.roles.includes(Role.Teacher) ? (
            translate("remove_user_title")
          ) : (
            <Text type="captionAlert">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: translate("alert_remove_user_warning")
                }}
              />
            </Text>
          )
        }
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          removeMember(user, page);
          closeConfirmDialog();
        }}
      />
    );
  };

  const changeMemberRole = (user: Member, role: string, page: number) => {
    const toasterMessage =
      role === "ROLE_TEACHER"
        ? "user_promoted_to_teacher_message"
        : "user_demoted_to_student_message";
    changeUserRole(group.id, user.id, role)
      .then(() => {
        showToastSuccess({ str: translate(toasterMessage) });
        closeModal();
        getMembers(page);
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const showChangeMemberRoleModal = (
    user: Member,
    role: string,
    page: number
  ) => {
    const bodyMessage =
      role === "ROLE_TEACHER"
        ? "promote_to_teacher_confirmation_message"
        : "demote_to_student_confirmation_message";
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate(bodyMessage)}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          changeMemberRole(user, role, page);
          closeConfirmDialog();
        }}
      />
    );
  };

  const getDropdownOptions = (user: Member, page: number): ListItemProps[] => {
    const viewProfile: ListItemProps = {
      id: "go-to-profile",
      icon: "user",
      label: translate("go_to_profile"),
      onClick: () => {
        navigateTo(
          createUrl("profile_public", {
            user_uuid: user.uuid
          })
        );
      }
    };

    const startConversation: ListItemProps = {
      id: "go-to-chat",
      icon: "chat",
      label: translate("group_members_start_conversation"),
      onClick: () => {
        _trackEvent(EventDomain.Chat, ChatEvent.ChatStartGroupMembersClick);
        navigateToThread(user.uuid);
      }
    };

    const promoteToTeacher: ListItemProps = {
      id: "promote-teacher",
      icon: "edit",
      label: translate("promote_teacher"),
      onClick: () => {
        showChangeMemberRoleModal(user, "ROLE_TEACHER", page);
      }
    };

    const demoteToStudent: ListItemProps = {
      id: "demote-student",
      icon: "edit",
      label: translate("demote_student"),
      onClick: () => {
        showChangeMemberRoleModal(user, "ROLE_STUDENT", page);
      }
    };

    const removeFromGroup: ListItemProps = {
      id: "remove-from-group",
      icon: "trash",
      label: translate("remove_from_group"),
      redShade: true,
      onClick: () => {
        showRemoveMemberModal(user, page);
      }
    };
    const creator = user.roles.includes(Role.Creator);
    const teacher = user.roles.includes(Role.Teacher);
    const student = user.roles.includes(Role.Student);

    let dropdownOptions =
      canCreateChats && activeUser.uuid !== user.uuid
        ? [startConversation, viewProfile]
        : [viewProfile];

    if (isCreator) {
      if (!creator && !teacher) {
        dropdownOptions = [
          promoteToTeacher,
          ...dropdownOptions,
          removeFromGroup
        ];
      } else if (!creator && teacher) {
        dropdownOptions = [
          demoteToStudent,
          ...dropdownOptions,
          removeFromGroup
        ];
      }
    } else if (isTeacher && student) {
      dropdownOptions = [promoteToTeacher, ...dropdownOptions, removeFromGroup];
    }

    return dropdownOptions;
  };

  const getGroupMembersNonAdmin = (offset: number) => {
    if (!group?.id) return;
    setLoadingMembersNonAdmin(true);
    fetchGroupUsers({
      groupId: group.id,
      limit: __GROUP_MEMBERS_LIST_LIMIT__,
      offset
    })
      .then(
        ({ results, total }: { results: MemberNonAdmin[]; total: number }) => {
          setMembersNonAdmin(results);
          setTotalMembersNonAdmin(total || 0);
        }
      )
      .catch(error => showToastError({ str: error.message }))
      .finally(() => setLoadingMembersNonAdmin(false));
  };

  return {
    isCreator,
    isStudent,
    isTeacher,
    invitations: groupInvitations,
    getInvitations,
    loadingInvitations,
    members,
    totalMembers,
    getMembers,
    loadingMembers,
    getDropdownOptions,
    showRemoveMemberModal,
    showResendInviteModal,
    hasRole,
    getGroupMembersNonAdmin,
    membersNonAdmin,
    loadingMembersNonAdmin,
    totalMembersNonAdmin,
    showCancelInviteModal
  };
};
