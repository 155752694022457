/* eslint-disable no-bitwise */
/**
 *
 * @param {*} hexColor
 * @returns true if the hexColor is a light color, false otherwise
 */

import colors from "../themes/colors";

export const isLightColor = hexColor => {
  if (!hexColor) return false;
  const hex = hexColor.replace(/#/, "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const formula =
    [0.299 * r, 0.587 * g, 0.114 * b].reduce((a, currVal) => a + currVal) / 255;

  return formula > 0.65;
};

/**
 *
 * @param {*} hexColor
 * @param {*} percent
 * @returns new string (hex color) that is {hexColor} props with brightness increased by {percent}
 */

export const increaseBrightness = ({ hexColor, percent }) => {
  if (!hexColor) return undefined;
  // strip the leading # if it's there
  const hex = hexColor.replace(/^\s*#|\s*$/g, "");

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return `#${(0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
    .toString(16)
    .substr(1)}`;
};

/**
 *
 * @param {*} hexColor
 * @param {*} percent
 * @returns new string (hex color) that is {hexColor} props with brightness decreased by {percent}
 */

const decreaseBrightness = ({ hexColor, percent }) => {
  if (!hexColor) return undefined;
  // strip the leading # if it's there
  const hex = hexColor.replace(/^\s*#|\s*$/g, "");

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return `#${(0 | ((1 << 8) + (r * (100 - percent)) / 100))
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + (g * (100 - percent)) / 100))
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + (b * (100 - percent)) / 100))
    .toString(16)
    .substr(1)}`;
};

/**
 *
 * @param {*} hexColor
 * @param {*} percent
 * @returns the correct shade of color (bright or dark) of {hexColor} based on its light
 */

export const getCorrectShade = ({ hexColor, percent }) => {
  if (!hexColor) return undefined;
  return isLightColor(hexColor)
    ? decreaseBrightness({ hexColor, percent })
    : increaseBrightness({ hexColor, percent });
};

export const getCustomThemeFromSettings = ({ customizationSettings }) => {
  const layoutColor = customizationSettings?.layoutColor;
  const ctaFillColor = customizationSettings?.ctaFillColor;

  let ctaPrimaryTextColor;
  let layoutTextColor;

  if (ctaFillColor) {
    ctaPrimaryTextColor = isLightColor(ctaFillColor)
      ? colors.grey[50]
      : colors.grey[1000];
  }

  if (layoutColor) {
    layoutTextColor = isLightColor(layoutColor)
      ? colors.grey[50]
      : colors.grey[1000];
  }

  const customTheme = {
    colors: {
      headerPrimaryColor: layoutColor,
      headerTextColor: layoutTextColor,
      headerSecondaryColor: getCorrectShade({
        hexColor: layoutColor,
        percent: 20
      }),
      headerTextSecondaryColor: getCorrectShade({
        hexColor: layoutColor,
        percent: 80
      }),

      customButtonPrimaryBk: ctaFillColor,
      customButtonPrimaryBorder: ctaFillColor,
      customButtonPrimaryText: ctaPrimaryTextColor
    }
  };

  Object.keys(customTheme.colors).forEach(key =>
    customTheme.colors[key] ? {} : delete customTheme.colors[key]
  );

  return customTheme;
};
