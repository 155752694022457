import { TextTypesMap } from "./types";

const text: TextTypesMap = {
  // 10px
  captionInfoDetails: {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "11px"
  },
  label: {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    textTransform: "uppercase",
    letterSpacing: "0.5px"
  },
  tableSmall: {
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "10px"
  },

  // 12px
  formSmallCaption: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "17px"
  },
  labelWide: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "12px",
    textTransform: "uppercase",
    letterSpacing: "0.5px"
  },
  table: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px"
  },

  // 14px
  body: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px"
  },
  formDescription: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px"
  },
  formSubtitle: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px"
  },
  captionAlert: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px"
  },
  textLink: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    textDecoration: "underline",
    textUnderlineOffset: "2px"
  },
  ctaTertiary: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px"
  },
  subHeaderSubtitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px"
  },
  formTitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px"
  },
  menuLabel: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "14px"
  },

  // 15px
  cta: {
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "17px"
  },

  // 16px
  formFieldEmpty: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px"
  },
  headerTitle: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px"
  },
  formField: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px"
  },
  subHeaderTitle: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px"
  },

  // 18px
  headerTitleLg: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "18px"
  }
};

export default text;
