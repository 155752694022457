import React from "react";
import { ModulesResourceItemProps } from "src/js/components/modules/ModuleResourceItem/types";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import * as S from "./ModulesResourceItem.styles";
import { ResourceInfo, ResourcePreview } from "./components";

const ModulesResourceItem = ({
  title,
  resourceId,
  resourcePosition,
  author,
  createdAt,
  commentsCount,
  resourcePoints,
  isCompleted,
  resourceImage,
  mimeType,
  onClick,
  resourceType,
  hasPreviewGenerated,
  moduleId,
  isSuggestedResource,
  isDragged,
  resourcePublicUrl,
  isBoardElementEnabled,
  approveSuggestedResource,
  rejectSuggestedResource,
  removeResource,
  setDraggable
}: ModulesResourceItemProps) => {
  const {
    BoardsStore: { boardElementCommentNumberMap }
  } = useStores();

  return (
    <S.ResourceContainer
      onClick={e => {
        if (!isBoardElementEnabled) return;
        onClick(e);
      }}
      isBoardElementEnabled={isBoardElementEnabled}
      isSuggestedResource={isSuggestedResource}
      isDragged={isDragged}
    >
      <ResourceInfo
        resourceId={resourceId}
        author={author}
        createdAt={createdAt}
        isCompleted={isCompleted}
        resourcePoints={resourcePoints}
        title={title}
        moduleId={moduleId}
        isSuggestedResource={isSuggestedResource}
        removeResource={removeResource}
        hasPreviewGenerated={hasPreviewGenerated}
        resourceImage={resourceImage}
        mimeType={mimeType}
        setDraggable={setDraggable}
        resourcePosition={resourcePosition}
      />

      <ResourcePreview
        isBoardElementEnabled={isBoardElementEnabled}
        resourceImage={resourceImage}
        resourceType={resourceType}
        commentsCount={
          boardElementCommentNumberMap.get(resourceId) === undefined
            ? commentsCount
            : boardElementCommentNumberMap.get(resourceId)
        }
        mimeType={mimeType}
        hasPreviewGenerated={hasPreviewGenerated}
        isSuggestedResource={isSuggestedResource}
        approveSuggestedResource={approveSuggestedResource}
        rejectSuggestedResource={rejectSuggestedResource}
        resourceId={resourceId}
        moduleId={moduleId}
        resourcePosition={resourcePosition}
        resourcePublicUrl={resourcePublicUrl}
        author={author}
        title={title}
      />
    </S.ResourceContainer>
  );
};

export default observer(ModulesResourceItem);
