import React from "react";
import { useTheme } from "styled-components";
import { ActionButton, Icon } from "@arcadia/design-system";

import { EditBoardIconButton } from "src/js/components/DrawerEditBoard/EditBoardIconButton";
import { EditBoardTitleElement } from "src/js/components/DrawerEditBoard/EditBoardTitleElement";
import { Translate } from "src/js/translation/TranslationProvider";
import useDeviceDetector from "src/js/hooks/useDeviceDetector";

import * as S from "./EditBoardHeader.styles";

type EditBoardHeaderProps = {
  isPublished?: boolean;
  title: string;
  boardElementCount?: number;
  suggestionCount?: number;
  availableScore?: number;
  editTitleFunction: (value: string) => void;
  togglePublishFunction: (value: boolean) => void;
  deleteFunction: () => void;
  closeFunction: () => void;
};

const EditBoardHeader = ({
  isPublished = false,
  title,
  boardElementCount = 0,
  suggestionCount = 0,
  availableScore = 0,
  editTitleFunction,
  togglePublishFunction,
  deleteFunction,
  closeFunction
}: EditBoardHeaderProps) => {
  const { whiteTheme } = useTheme();
  const { isDesktop } = useDeviceDetector();

  return (
    <S.HeaderContainer>
      {isDesktop && (
        <S.TitleContainer>
          <EditBoardTitleElement
            value={title}
            editFunction={editTitleFunction}
          />
        </S.TitleContainer>
      )}
      <S.ActionsContainer>
        {!isDesktop && (
          <S.TitleContainer>
            <EditBoardTitleElement
              value={title}
              editFunction={editTitleFunction}
            />
          </S.TitleContainer>
        )}
        <EditBoardIconButton
          isAltered={isPublished}
          defaultIconName="eyeOpen"
          alteredIconName="eyeClosed"
          defaultTooltipMessage="publish_board_tooltip"
          alteredTooltipMessage="unpublish_board_tooltip"
          clickFunction={() => togglePublishFunction(!isPublished)}
        />
        <EditBoardIconButton
          defaultIconName="trash"
          defaultTooltipMessage="delete_board_tooltip"
          clickFunction={deleteFunction}
        />
        {isDesktop && (
          <ActionButton
            theme={whiteTheme}
            icon="close"
            variant="tertiary"
            onClick={closeFunction}
          />
        )}
      </S.ActionsContainer>
      <S.ExtraInfo>
        <Icon icon="cards" height={16} width={16} />
        <S.ExtraInfoText hasMarginLeft>{boardElementCount}</S.ExtraInfoText>
        {suggestionCount > 0 && (
          <>
            <S.GrayDot />
            <S.ExtraInfoText>
              <Translate
                text="board_suggestion"
                stringVariables={{ suggestionCount }}
              />
            </S.ExtraInfoText>
          </>
        )}
        {availableScore > 0 && (
          <>
            <S.GrayDot />
            <S.ExtraInfoText>
              {availableScore}{" "}
              <Translate
                text={availableScore === 1 ? "board_point" : "board_points"}
              />
            </S.ExtraInfoText>
          </>
        )}
      </S.ExtraInfo>
    </S.HeaderContainer>
  );
};

export default EditBoardHeader;
