import styled from "styled-components";

export const BodyWrapper = styled.div`
  padding: 20px;
  position: relative;

  @media (max-width: 767px) {
    padding: 20px 40px;
  }
`;

export const RegistrationNumber = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: 500;
  position: absolute;
  display: inline-block;
  top: 45px;
  right: 75px;
  font-size: 12px;
`;

export const PreviewWrapper = styled.div`
  margin-top: 25px;
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent4};
  border-radius: 6px;
  margin-bottom: 10px;
  height: 270px;
  img {
    height: 100%;
    border-radius: inherit;
  }
`;

export const Footer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const NoBoardsMessage = styled.div`
  text-align: center;
  font-size: 16px;
  padding: 25px 0px 15px;
`;
