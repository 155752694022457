import React, { FC } from "react";
import { useTheme } from "styled-components";
import { Dropdown, AvatarWithInitials, Text } from "@arcadia/design-system";
import { translateString } from "src/js/translation/TranslationProvider";
import { SpacesDropdownProps } from "./SpacesDropdown.types";
import * as S from "./SpacesDropdown.styles";

const SpacesDropdown: FC<SpacesDropdownProps> = ({
  disabled = false,
  label = translateString({ text: "space_group_dropdown_label" }),
  placeholder = translateString({ text: "space_group_dropdown_placeholder" }),
  spacesList,
  selectedSpaceId,
  setSelectedSpaceId,
  error
}) => {
  const { whiteTheme } = useTheme();

  return (
    <S.Container>
      <Text type="formSubtitle">
        <S.Label htmlFor="dropdown-space-group" id="spaceGroupLabel">
          {label}
        </S.Label>
      </Text>
      <Dropdown
        theme={whiteTheme}
        placeholder={placeholder}
        selectedOptionId={selectedSpaceId}
        setSelectedOptionId={setSelectedSpaceId}
        disabled={disabled}
        error={error}
      >
        {spacesList.map(({ space }) => (
          <Dropdown.Option
            id={space.id}
            label={space.name}
            key={`option-${space.id}`}
          >
            <AvatarWithInitials
              theme={whiteTheme}
              alt={space.name}
              url={space?.badge?.small as string | undefined}
              name={space.name}
              avatarColor={space.defaultBadgeColor}
              size="extraSmall"
            />
            <span>{space.name}</span>
          </Dropdown.Option>
        ))}
      </Dropdown>
    </S.Container>
  );
};

export default SpacesDropdown;
