import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Box, Text, Button, Input, Icon } from "@arcadia/design-system";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "styled-components";
import { useFormContext } from "react-hook-form";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiCourseGeneratorEvent, EventDomain } from "src/js/types";
import { ExampleCarousel } from "../ExampleCarousel";
import { __FORM_AI_COURSE_WIZARD__ } from "../../CourseDetailsWizard.utils";
import { CourseDetailsWizardForm } from "../../CourseDetailsWizard.types";

import { hintsVariants } from "./TopicStep.utils";
import * as S from "./TopicStep.styles";

// TODO: Actual data
export const items: TranslationKeys[] = [
  "ai_prompt_example_1",
  "ai_prompt_example_2",
  "ai_prompt_example_3",
  "ai_prompt_example_4",
  "ai_prompt_example_5"
];

const TopicStep = ({ onNext }: { onNext: () => void }) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const [showHints, setShowHints] = useState(false);
  const {
    register,
    errors,
    setValue,
    triggerValidation,
    formState: { dirtyFields }
  } = useFormContext<CourseDetailsWizardForm>();

  const onApplyExample = useCallback(
    (example: string) => {
      setValue("topic", example);
      triggerValidation();
      _trackEvent(
        EventDomain.AiCourseGenerator,
        AiCourseGeneratorEvent.AiWizardStep2ApplyExample
      );
    },
    [setValue, triggerValidation]
  );

  const handleNext = useCallback(() => {
    onNext();
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardStep2
    );
  }, [onNext]);

  const toggleHints = useCallback(() => {
    if (!showHints) {
      _trackEvent(
        EventDomain.AiCourseGenerator,
        AiCourseGeneratorEvent.AiWizardStep2Examples
      );
    }
    setShowHints(!showHints);
  }, [showHints, setShowHints]);

  return (
    <Box padding="20px 0px" width="100%">
      <Text type="formSubtitle">{translate("ai_wizard_topic_label")}</Text>
      <Input
        name="topic"
        type="textarea"
        rows={5}
        ref={register(__FORM_AI_COURSE_WIZARD__.topic)}
        error={{
          message:
            errors.topic && translate(errors.topic.message as TranslationKeys)
        }}
        placeholder={translate("ai_wizard_topic_placeholder")}
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        label="topic"
        hideLabel
      />
      <S.HintsToggle onClick={toggleHints}>
        <S.ToggleLeftSection>
          <S.IconWrapper>
            <Icon icon="lightBulb" width={16} height={16} initialViewbox />
          </S.IconWrapper>
          <S.AccordionText type="body">
            {translate("ai_course_hints_toggle")}
          </S.AccordionText>
        </S.ToggleLeftSection>
        <motion.div animate={{ rotate: showHints ? 0 : "180deg" }}>
          <Icon icon="chevronUp" width={12} height={12} initialViewbox />
        </motion.div>
      </S.HintsToggle>
      <AnimatePresence initial={false} presenceAffectsLayout>
        {showHints && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={hintsVariants}
          >
            <ExampleCarousel
              title={translate("ai_course_hints_title")}
              onApplyExample={onApplyExample}
              items={items.map(item => translate(item))}
              variant={isLayoutModeMobile ? "light" : "dark"}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <Box marginTop={16}>
        <Button
          theme={whiteTheme}
          disabled={!dirtyFields.has("topic") || errors.topic !== undefined}
          variant="primary"
          onClick={handleNext}
        >
          {translate("next")}
        </Button>
      </Box>
    </Box>
  );
};

export default observer(TopicStep);
