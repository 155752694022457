import find from "lodash/find";
import QuizModel from "./quizModel";

class QuizTrueFalseModel extends QuizModel {
  checkUserAnswer() {
    let ret = false;
    const answer = this.getUserAnswer();
    const questions = this.getQuestions();
    const solutions = this.getSolutions();

    const item = find(questions, { value: answer });
    if (item !== undefined) {
      const realSolution = find(solutions, { index: item.index });
      ret = realSolution.value;
    }

    return ret;
  }
}

export default QuizTrueFalseModel;
