import QuizModel from "./quizModel";

class QuizOpenAnswerModel extends QuizModel {
  getFormattedUserAnswer() {
    return [{ index: 0, value: this.getUserAnswer() }];
  }
  checkUserAnswer() {
    return true; // per ora sempre true
  }
  needsReview() {
    return true;
  }
}

export default QuizOpenAnswerModel;
