import styled from "styled-components";

export const ModalContentContainer = styled.div`
  text-align: center;
  padding: 0 30px 60px;

  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
`;

export const ModalContentTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 20px;
`;

export const ModalUserCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #f3f3fb;
  color: #000000;
  margin-bottom: 8px;
  text-align: left;
  line-height: 20px;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ModalUserCardName = styled.span`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
