import { ActionButton, colors } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { SchoolClaimSearch } from "src/js/pages/spaces/space_creation/SchoolClaimForm/SchoolClaimSearch";
import {
  __TESTIMONIAL_BACKGROUND_1__,
  __TESTIMONIAL_IMAGE_3__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import styled, { useTheme } from "styled-components";
import { OnboardingSchoolSpaceCreation } from "../Onboarding.utils";
import StepperBlock from "../components/StepperBlock";
import Testimonial from "../components/Testimonial";
import { TitleBlock } from "../components/TitleBlock";
import { useSchoolSpaceCreation } from "../utils/SchoolSpaceCreationProvider";

export const Container = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 20px;
  }
`;

const SchoolSpaceCreation = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UserStore: { setUserOnboarding }
  } = useStores();

  const { setStatus, setSpaceSelected } = useSchoolSpaceCreation();

  const currentStepIndex =
    OnboardingSchoolSpaceCreation.indexOf("creation") + 1;

  const selectSchool = ({ status, space }) => {
    setStatus(status);
    setSpaceSelected(space);
    navigateTo(createUrl("onboarding_school_space_confirmation"));
  };

  const goBack = () => {
    setUserOnboarding({
      userType: USER_TYPE.K12,
      isCompleted: false,
      step: WeSchoolRoutes.Onboarding.welcome
    });
    navigateTo(WeSchoolRoutes.Onboarding.welcome);
  };

  const pagecontainer = (
    <Container>
      <TitleBlock
        backComponent={
          <ActionButton
            icon="chevronLeft"
            theme={whiteTheme}
            variant="tertiary"
            onClick={goBack}
          />
        }
        title={translate("onboarding_school_space_creation_title")}
      />
      <SchoolClaimSearch
        theme={whiteTheme}
        onSelectedCallback={(newStatus, newSpace = null) => {
          selectSchool({ status: newStatus, space: newSpace });
        }}
        backgroundColor={colors.grey[1000]}
        goToSpaceCreation={() => {}}
      />
    </Container>
  );

  return (
    <OnboardingLayout
      mainContent={pagecontainer}
      sideContent={
        <Testimonial
          background={__TESTIMONIAL_BACKGROUND_1__}
          image={__TESTIMONIAL_IMAGE_3__}
          text={translate("onboarding_testimonial_school_space_creation")}
        />
      }
      stepper={
        <StepperBlock
          totalSteps={OnboardingSchoolSpaceCreation.length}
          currentStep={currentStepIndex}
        />
      }
    />
  );
};

export default SchoolSpaceCreation;
