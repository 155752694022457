import { useState, useEffect, useCallback } from "react";
import {
  createLocalData,
  createLocalStringifiedData,
  destroyLocalData,
  readLocalData
} from "src/js/modules/storageFunction";
import {
  SpaceGenerationStatesEnum,
  SpaceListModel,
  SpaceModel
} from "src/js/types";
import { DEMO_SPACE_LS_EXPIRATION_TIME } from "src/js/settings/settingsSpaces";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import createUrl from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import { useStores } from "src/js/hooks";
import { joinDemoSpace } from "src/js/repository/spacesRepository";
import { JoinDemoSpaceLocalStorageNames } from "../JoinDemoSpace.types";

const useDemoSpaceGeneration = () => {
  const {
    DemoSpaceStore: { setSpaceDemoGenerationState, setPreviewDemoSpace },
    SpaceStore: {
      demoSpace,
      spacesList,
      addNewSpaceToSpaceList,
      hasDemoSpace,
      isOwnerOrAdminInSomeSpace
    }
  } = useStores();

  const [isJoinDemoSpaceCtaVisible, setJoinDemoSpaceCtaVisible] =
    useState(false);
  const [isBlockingLoaderVisible, setIsBlockingLoaderVisible] = useState(false);

  let spaceGenerationObj = readLocalData(
    JoinDemoSpaceLocalStorageNames.SpaceGenerationState
  );

  // cleanup the previous pending demo space generation from local storage
  if (
    !spaceGenerationObj?.value ||
    spaceGenerationObj?.expiresAt <= new Date().getTime()
  ) {
    destroyLocalData(JoinDemoSpaceLocalStorageNames.SpaceGenerationState);
  }

  // read the data from local storage again, after potentially removing it
  spaceGenerationObj = readLocalData(
    JoinDemoSpaceLocalStorageNames.SpaceGenerationState
  );

  const isGeneratingDemoSpace =
    spaceGenerationObj?.value ===
      SpaceGenerationStatesEnum.START_MESSAGE_TYPE && !hasDemoSpace;

  useEffect(() => {
    const shouldShowJoinDemoSpaceCTA =
      !hasDemoSpace &&
      !isGeneratingDemoSpace &&
      (isOwnerOrAdminInSomeSpace || spacesList.length === 0);

    setJoinDemoSpaceCtaVisible(shouldShowJoinDemoSpaceCTA);
  }, [
    spacesList,
    hasDemoSpace,
    isGeneratingDemoSpace,
    isOwnerOrAdminInSomeSpace
  ]);

  useEffect(() => {
    const handleSpaceGeneration = (
      messageType: SpaceGenerationStatesEnum,
      // TODO This type needs to be fixed
      space?: SpaceModel & SpaceListModel
    ) => {
      setSpaceDemoGenerationState(messageType);
      createLocalData(
        JoinDemoSpaceLocalStorageNames.SpaceGenerationState,
        messageType,
        DEMO_SPACE_LS_EXPIRATION_TIME
      );
      destroyLocalData(JoinDemoSpaceLocalStorageNames.PreviewDemoSpace);
      if (space) {
        setIsBlockingLoaderVisible(false);
        addNewSpaceToSpaceList(space);
        navigateTo(createUrl("space_groups", { space_slug: space.space.slug }));
      }
    };

    RealTimeDispatcher.on(SpaceGenerationStatesEnum.FAIL_MESSAGE_TYPE, () =>
      handleSpaceGeneration(SpaceGenerationStatesEnum.FAIL_MESSAGE_TYPE)
    );
    RealTimeDispatcher.on(
      SpaceGenerationStatesEnum.END_MESSAGE_TYPE,
      (space: SpaceModel & SpaceListModel) =>
        handleSpaceGeneration(SpaceGenerationStatesEnum.END_MESSAGE_TYPE, space)
    );

    return () => {
      setSpaceDemoGenerationState(
        readLocalData(JoinDemoSpaceLocalStorageNames.SpaceGenerationState)
      );
      RealTimeDispatcher.off(SpaceGenerationStatesEnum.FAIL_MESSAGE_TYPE);
      RealTimeDispatcher.off(SpaceGenerationStatesEnum.END_MESSAGE_TYPE);
    };
  }, []);

  const handleJoinDemoSpace = useCallback(async () => {
    if (demoSpace) {
      navigateTo(
        createUrl("space_groups", { space_slug: demoSpace.space.slug })
      );
    } else {
      setIsBlockingLoaderVisible(true);
      setSpaceDemoGenerationState(SpaceGenerationStatesEnum.START_MESSAGE_TYPE);
      createLocalData(
        JoinDemoSpaceLocalStorageNames.SpaceGenerationState,
        SpaceGenerationStatesEnum.START_MESSAGE_TYPE,
        DEMO_SPACE_LS_EXPIRATION_TIME
      );

      try {
        const demoSpaceMinimal = await joinDemoSpace();
        setPreviewDemoSpace(demoSpaceMinimal);
        createLocalStringifiedData(
          JoinDemoSpaceLocalStorageNames.PreviewDemoSpace,
          demoSpaceMinimal
        );
      } catch (error) {
        console.error(error);
      }
      setJoinDemoSpaceCtaVisible(prevState => !prevState);
    }
  }, [
    setIsBlockingLoaderVisible,
    setSpaceDemoGenerationState,
    setPreviewDemoSpace,
    setJoinDemoSpaceCtaVisible
  ]);

  return {
    handleJoinDemoSpace,
    isJoinDemoSpaceCtaVisible,
    isBlockingLoaderVisible,
    isGeneratingDemoSpace
  };
};

export default useDemoSpaceGeneration;
