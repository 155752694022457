import { Button, Icon } from "@arcadia/design-system";
import React from "react";
import { openExternalLink } from "src/js/modules/commonFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

const FallbackPlaceholder = ({
  ctaLabel = "Visualizza in una nuova scheda",
  url
}: {
  url: string;
  ctaLabel?: TranslationKeys;
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <div className="scroll-wrapper">
      <div className="resource-content-placeholder-outer-wrapper">
        <div className="resource-content-placeholder-inner-wrapper">
          <div className="app__box text-center resource-content-placeholder">
            <Icon icon="link" height={40} width={40} />
            <div className="app__subtitle resource-content-placeholder-title">
              {translate("resource_preview_not_available")}
            </div>
            <Button
              onClick={() => {
                openExternalLink(url);
              }}
              variant="primary"
              theme={whiteTheme}
              fullWidth={getBreakpoint() === "smartphone"}
            >
              {translate(ctaLabel)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FallbackPlaceholder;
