import theme from "src/js/theme/theme";

export const PILL_COLOR = {
  GRAY: theme.colors.pillGray,
  BLUE: theme.colors.pillBlue,
  ORANGE: theme.colors.pillOrange,
  YELLOW: theme.colors.pillYellow,
  RED: theme.colors.pillRed,
  PURPLE: theme.colors.pillPurple,
  GREEN: theme.colors.pillGreen
};
