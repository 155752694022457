import { TranslationKeys } from "src/js/translation";
import {
  NotificationRead,
  NotificationReadModel
} from "src/js/types/models/Notification";

export const READ_FILTER_OPTIONS: {
  key: NotificationReadModel;
  value: TranslationKeys;
}[] = [
  {
    key: NotificationRead.Read,
    value: "notification_filter_read"
  },
  {
    key: NotificationRead.Unread,
    value: "notification_filter_unread"
  },
  {
    key: NotificationRead.All,
    value: "notification_filter_all"
  }
];

export const NOTIFICATION_AREA = {
  WALL: "wall",
  EXERCISE: "exercise",
  LESSON: "lesson",
  CALENDAR: "calendar"
};
