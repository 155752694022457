import {
  ContentSwitcher,
  DoodleLoader,
  Heading,
  Icon,
  Text
} from "@arcadia/design-system";
import createReactClass from "create-react-class";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import React from "react";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { withSpaceStore } from "src/js/hooks/useStores";
import createUrl from "src/js/modules/routing";
import { Translate } from "src/js/translation/TranslationProvider";
import { __EXERCISE_MAX_RATE__ } from "src/legacy/settings/settings";
import styled, { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import SingleEmailForm from "../../components/singleEmailForm";
import { getActiveGroupId } from "../../modules/activeGroup";
import { getActiveUser } from "../../modules/activeUser";
import { changeUrl } from "../../modules/history";
import { __ } from "../../modules/localization";
import { basePageModel } from "../../modules/pageStatus";
import { createURL } from "../../modules/utility";
import { BoardActivityTable } from "./BoardActivityTable";
import InclassActivityTable from "./InclassActivityTable";
import QuizActivityTable from "./quizActivityTable";
import { warningProps } from "./translations";
import { ScormActivityTab } from "src/js/components/ScormActivityTab";

const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  position: absolute;
`;

const ContainerActivity = ({ children }) => {
  return (
    <div className="activity-container margin-bottom-80 margin-top-40">
      {children}
    </div>
  );
};

const ActivityPage = withSpaceStore(
  withTheme(
    withBackbone(
      createReactClass({
        getDefaultProps() {
          return {
            activeTab: "board",
            filter: "assignment"
          };
        },
        getInitialState() {
          return {
            activeTab: ""
          };
        },
        componentWillMount() {
          const self = this;
          basePageModel.set("pageTitle", this.props.group.get("name"));
          basePageModel.set("selectedTab", "activity");
          let activeTab = self.getActiveTab(self.props.match.params.mode);
          self.setState({
            activeTab
          });
        },
        getActiveTab(tab) {
          let activeTab = "";
          switch (tab) {
            case "board":
              activeTab = "board";
              break;
            case "assignment":
              activeTab = "assignment";
              break;
            case "examination":
              activeTab = "examination";
              break;
            case "inclass":
              activeTab = "inclass";
              break;
            case "download":
              activeTab = "download";
              break;
            case "scorm":
              activeTab = "scorm";
              break;
            default:
              activeTab = "board";
              break;
          }
          return activeTab;
        },
        getUrl(tab) {
          let url = "";
          switch (tab) {
            case "board":
              url = createURL("activity", { group_id: getActiveGroupId() });
              break;
            case "assignment":
              url = createURL("activity_assignment", {
                group_id: getActiveGroupId()
              });
              break;
            case "examination":
              url = createURL("activity_examination", {
                group_id: getActiveGroupId()
              });
              break;
            case "inclass":
              url = createUrl("activity_inclass", {
                group_id: getActiveGroupId()
              });
              break;
            case "download":
              url = createURL("activity_download", {
                group_id: getActiveGroupId()
              });
              break;
            case "scorm":
              url = createURL("activity_scorm", {
                group_id: getActiveGroupId()
              });
              break;
            default:
              url = createURL("activity", { group_id: getActiveGroupId() });
              break;
          }
          return url;
        },
        changeTab(tab) {
          let self = this;
          self.setState({
            activeTab: self.getActiveTab(tab)
          });
          changeUrl(self.getUrl(tab));
        },

        render() {
          const self = this;
          const { activityData, loading, theme, SpaceStore } = self.props;
          const { isDemoSpace } = SpaceStore;
          const { greyTheme } = theme;
          const { hasDecimalGrading } = SpaceStore || {};
          const bestGrade = hasDecimalGrading
            ? __EXERCISE_MAX_RATE__
            : `${__EXERCISE_MAX_RATE__ * 10}%`;
          const activeTab = self.state.activeTab;
          let content = "";
          let newElementBlock = "";
          let marginClass = "";
          let legend = "";
          let placeholder = "";
          const type = activeTab == "assignment" ? "ASSIGNMENT" : "EXAMINATION";
          let loader = <DoodleLoader theme={greyTheme} isMini />;

          if (!isEmpty(activityData)) {
            switch (activeTab) {
              case "board":
                if (activityData.boards.length > 0) {
                  content = (
                    <BoardActivityTable
                      activityData={activityData}
                      loading={loading}
                    />
                  );
                  legend = (
                    <div className="table-legend margin-vertical-20">
                      <div className="legend-item">
                        <Icon icon="squareCheck" width="12" height="12" /> ={" "}
                        <Translate text="legend_board_completed" />
                      </div>
                      <div className="legend-item">
                        90% = <Translate text="legend_board_grade" />
                      </div>
                      <div className="legend-item">
                        <Icon icon="chat" height="12" width="12" /> ={" "}
                        <Translate text="legend_comment_number" />
                      </div>
                      <div className="legend-item">
                        <Icon icon="group" height="12" width="12" /> ={" "}
                        <Translate text="legend_board_unlocked" />
                      </div>
                    </div>
                  );
                } else {
                  // pagination = "";
                  placeholder = (
                    <p className="text-center padding-15 font-18">
                      <Translate text="placeholder_board_activity" />
                    </p>
                  );
                }
                break;
              case "assignment":
                if (
                  activityData.exercises.length > 0 &&
                  findIndex(activityData.exercises, { type: "ASSIGNMENT" }) > -1
                ) {
                  content = (
                    <QuizActivityTable
                      activityData={activityData}
                      exerciseType={type}
                      loading={loading}
                    />
                  );
                  legend = (
                    <div className="table-legend margin-vertical-20">
                      <div className="legend-item">
                        {bestGrade} = <Translate text="legend_exercise_grade" />
                      </div>
                      <div className="legend-item">
                        (20) = <Translate text="legend_exercise_try" />
                      </div>
                      <div className="legend-item">
                        <Icon icon="dangerTriangle" width="12" height="12" /> ={" "}
                        <Translate text="legend_exercise_need_review" />
                      </div>
                      <div className="legend-item">
                        <Icon icon="back" width="12" height="12" /> ={" "}
                        <Translate text="legend_exercise_resend_asked" />
                      </div>
                    </div>
                  );
                } else {
                  placeholder = (
                    <p className="text-center padding-15 font-18">
                      <Translate text="placeholder_exercise_activity" />
                    </p>
                  );
                }
                break;
              case "examination":
                if (
                  activityData.exercises.length > 0 &&
                  findIndex(activityData.exercises, { type: "EXAMINATION" }) >
                    -1
                ) {
                  content = (
                    <QuizActivityTable
                      activityData={activityData}
                      exerciseType={type}
                      loading={loading}
                    />
                  );
                  legend = (
                    <div className="table-legend margin-vertical-20">
                      <div className="legend-item">
                        {bestGrade} = <Translate text="legend_exercise_grade" />
                      </div>
                      <div className="legend-item">
                        <IconsWrapper>
                          <IconContainer>
                            <Icon
                              icon="dangerTriangle"
                              width="12"
                              height="12"
                            />
                            = <Translate text="legend_exercise_need_review" />
                          </IconContainer>
                        </IconsWrapper>
                      </div>

                      <div className="legend-item">
                        <IconsWrapper>
                          <IconContainer>
                            <Icon icon="back" width="12" height="12" />
                            = <Translate text="legend_exercise_resend_asked" />
                          </IconContainer>
                        </IconsWrapper>
                      </div>
                    </div>
                  );
                } else {
                  placeholder = (
                    <p className="text-center padding-15 font-18">
                      <Translate text="placeholder_test_activity" />
                    </p>
                  );
                }
                break;
              case "inclass":
                if (activityData.custom_activities.length > 0) {
                  content = (
                    <InclassActivityTable
                      activityData={activityData}
                      loading={loading}
                    />
                  );
                } else {
                  placeholder = (
                    <p className="text-center padding-15 font-18">
                      <Translate text="placeholder_custom_activities_activity" />
                    </p>
                  );
                }
                newElementBlock = (
                  <a
                    href={`#${createURL("activity_new", { group_id: getActiveGroupId() })}`}
                    className="default-box new-element text-center"
                  >
                    <Heading level="5">
                      <Translate text="create_new_costum_activity" />
                    </Heading>
                  </a>
                );
                marginClass = "margin-bottom-80";
                break;
              case "download":
                const urlToSent = createURL("api_activity_download", {
                  group_id: getActiveGroupId()
                });
                content = (
                  <div className="padding-15 text-center">
                    <p className="padding-vertical-15">
                      <Translate text="activity_download_help" />
                    </p>
                    <SingleEmailForm
                      ajaxUrl={urlToSent}
                      method="POST"
                      className="text-center"
                      precompiledMail={getActiveUser().get("email")}
                      buttonLabel={__("download_register")}
                      successMessages={__("group_send_report_success")}
                    />
                  </div>
                );
                marginClass = "margin-bottom-80";
                break;
              case "scorm":
                content = <ScormActivityTab />;
                break;
              default:
                content = "board";
            }
          }
          if (loading != true) {
            loader = "";
          }

          const items = [
            {
              id: 1,
              value: "board",
              children: (
                <Text type="cta">
                  <Translate text="activity_board_tab" />
                </Text>
              )
            },
            {
              id: 2,
              value: "assignment",
              children: (
                <Text type="cta">
                  <Translate text="activity_exercise_tab" />
                </Text>
              )
            },
            {
              id: 3,
              value: "examination",
              children: (
                <Text type="cta">
                  <Translate text="activity_quiz_tab" />
                </Text>
              )
            },
            {
              id: 4,
              value: "inclass",
              children: (
                <Text type="cta">
                  <Translate text="activity_inclass_tab" />
                </Text>
              )
            },
            {
              id: 5,
              value: "download",
              children: (
                <Text type="cta">
                  <Translate text="activity_download_tab" />
                </Text>
              )
            },
            {
              id: 6,
              value: "scorm",
              children: (
                <Text type="cta">
                  <Translate text="record_scorm_tab_header_title" />
                </Text>
              )
            }
          ];

          return (
            <ContainerActivity>
              <div className="flex flex-centered-column flex-column flex-centered-row margin-bottom-20">
                <ContentSwitcher
                  theme={greyTheme}
                  onContentItemChange={value => {
                    self.changeTab(value);
                  }}
                  activeOption={activeTab}
                  items={items}
                />
                {isDemoSpace ? (
                  <WarningBanner
                    text={warningProps.text}
                    cta={warningProps.cta}
                    margin="20px 0"
                  />
                ) : null}
              </div>
              {newElementBlock}
              {placeholder}
              {legend}
              {content}
              {loader}
            </ContainerActivity>
          );
        }
      })
    )
  )
);

export default ActivityPage;
