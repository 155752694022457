import {
  BaseComponentProps,
  Icon,
  RadioGroup,
  Text
} from "@arcadia/design-system";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

import * as S from "./FilterBy.styles";
import { FilterOptionVariant, InitialFilterOption } from "./types";

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  selectedOption: FilterOptionVariant;
  setSelectedOption: Dispatch<SetStateAction<string>>;
  initialOptions: InitialFilterOption[];
};

const FilterBy: FC<Props & BaseComponentProps> = ({
  isVisible,
  setIsVisible,
  selectedOption,
  setSelectedOption,
  initialOptions,
  ...rest
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <S.Popover
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      content={
        <S.PopoverContentWrapper>
          <RadioGroup
            theme={whiteTheme}
            options={initialOptions}
            value={selectedOption}
            onChangeFunction={val => {
              setSelectedOption(val);
              setIsVisible(false);
            }}
            orientationOption="column"
            orientationLabel="right"
          />
        </S.PopoverContentWrapper>
      }
      {...rest}
    >
      <S.FilterButton>
        <Text type="formDescription">
          {translate({ text: "home_filter_by_button_title" })}
        </Text>
        <S.IconWrapper
          animate={isVisible ? "visible" : "hidden"}
          variants={{
            visible: {
              rotateZ: "180deg"
            },
            hidden: {
              rotateZ: "0deg"
            }
          }}
          transition={{ duration: 0.5 }}
        >
          <Icon icon="chevronDown" />
        </S.IconWrapper>
      </S.FilterButton>
    </S.Popover>
  );
};

export default FilterBy;
