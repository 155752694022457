import { useEffect, useState } from "react";
import {
  AITransactionInfoResponse,
  fetchAiAuth,
  fetchAiTransactionId
} from "../repository/aiRepository";
import useStores from "./useStores";
import { AIGenerationRequest, AIRequest } from "./useAIServiceFetcher.types";

const generateFetchFromRequest = <
  T extends keyof AIRequest = never,
  K extends keyof AIRequest = T
>({
  apiUrl,
  request,
  aiTransactionId,
  jwt
}: AITransactionInfoResponse & {
  request: T;
}) => {
  return ({
    body
  }: {
    body: AIRequest[T]["request"];
  }): Promise<AIRequest[K]["response"]> =>
    fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify({
        request,
        aiTransactionId,
        ...body
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${jwt}`
      }
    }).then(async res => {
      if (!res.ok) {
        const err = (await res.json()) as { error: string };
        throw new Error(err?.error);
      }
      return res.json();
    });
};

// Call this each time you need to start a AI flow
export const useAITransactionInfo = ({ isAI }: { isAI: boolean }) => {
  const [AITransactionInfo, setAITransactionInfo] =
    useState<AITransactionInfoResponse>(null);
  const {
    GroupStore: { activeGroup }
  } = useStores();

  useEffect(() => {
    if (!activeGroup || !isAI) return;
    Promise.all([
      fetchAiAuth(activeGroup.id),
      fetchAiTransactionId(activeGroup.id)
    ])
      .then(res => {
        const transactionInfo = res.reduce((reduced, current) => {
          return { ...reduced, ...current };
        }, {}) as AITransactionInfoResponse;
        setAITransactionInfo(transactionInfo);
      })
      .catch(error => {
        console.error("Error fetching AI transaction info:", error);
        setAITransactionInfo(null);
      });
  }, []);

  return AITransactionInfo;
};

const useAIServiceFetcher = <T extends AIGenerationRequest>({
  requestType,
  AITransactionInfo
}: {
  requestType: T;
  AITransactionInfo: AITransactionInfoResponse;
}) => {
  if (!AITransactionInfo) return null;
  const { aiTransactionId, apiUrl, expiresAt, jwt } = AITransactionInfo;

  return generateFetchFromRequest({
    aiTransactionId,
    apiUrl,
    expiresAt,
    jwt,
    request: requestType
  });
};

export default useAIServiceFetcher;
