import { Button, Heading, Icon, Link, Slideshow } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { openExternalLink } from "src/js/modules/commonFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import createUrl from "src/js/modules/routing";
import { GroupOverquotaBlocked } from "src/js/pages/group_overquota_blocked";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import * as S from "./AdminOverquotaBlocked.styles";

const AdminOverquotaBlocked = () => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile: isMobile },
    SpaceStore: { isAdmin, activeSpace }
  } = useStores();
  const { pageMinHeight } = useAppLayout();
  const { whiteTheme } = useTheme();

  if (!isAdmin) {
    return <GroupOverquotaBlocked />;
  }

  const items = [
    <S.CheckList key="first-slide">
      <S.ListElement>
        <Icon icon="check" width={10} height={10} initialViewbox />
        {translate("space_group_overquota_list_01")}
      </S.ListElement>
      <S.ListElement>
        <Icon icon="check" width={10} height={10} initialViewbox />
        {translate("space_group_overquota_list_02")}
      </S.ListElement>
      <S.ListElement>
        <Icon icon="check" width={10} height={10} initialViewbox />
        {translate("space_group_overquota_list_03")}
      </S.ListElement>
    </S.CheckList>,
    <S.CheckList key="second-slide">
      <S.ListElement>
        <Icon icon="check" width={10} height={10} initialViewbox />
        {translate("space_group_overquota_list_04")}
      </S.ListElement>
      <S.ListElement>
        <Icon icon="check" width={10} height={10} initialViewbox />
        {translate("space_group_overquota_list_05")}
      </S.ListElement>
      <S.ListElement>
        <Icon icon="check" width={10} height={8} initialViewbox />
        {translate("space_group_overquota_list_06")}
      </S.ListElement>
    </S.CheckList>
  ];

  return (
    <S.Wrapper pageMinHeight={pageMinHeight}>
      <S.ModalOverlay pageMinHeight={pageMinHeight}>
        <S.ModalContainer>
          <VideoPlayer video={S3Video.SpaceBlocked} />
          <S.TitleContainer>
            <Heading level="5">
              {translate("space_group_overquota_title")}
            </Heading>
          </S.TitleContainer>
          <S.Description type="formSubtitle">
            {translate("space_group_overquota_description_admin")}
          </S.Description>
          {isMobile ? (
            <S.SlideshowWrapper>
              <Slideshow
                items={items}
                showPagination
                swipeable
                msTimeout={5000}
              />
            </S.SlideshowWrapper>
          ) : (
            <S.CheckListContainer>{items}</S.CheckListContainer>
          )}
          <S.ModalFooterContainer>
            <>
              <Button
                onClick={() =>
                  navigateTo(
                    createUrl("dashboard_settings", {
                      space_id: activeSpace?.space?.id,
                      option: "plan"
                    })
                  )
                }
                theme={whiteTheme}
                variant="primary"
                fullWidth={getBreakpoint() === "smartphone"}
              >
                {translate("space_group_overquota_second_button_admin")}
              </Button>
              <Button
                variant="secondary"
                theme={whiteTheme}
                fullWidth={getBreakpoint() === "smartphone"}
                onClick={() => {
                  openExternalLink("https://www.weschool.com/get-started/");
                }}
              >
                {translate("onboarding_resume_demo_action_button")}
              </Button>
            </>
          </S.ModalFooterContainer>
          <S.LinkWrapper>
            <Link
              theme={whiteTheme}
              href={`mailto:${translate(
                "space_group_overquota_extend_trial_email"
              )}`}
            >
              {translate("space_group_overquota_extend_trial_text")}
            </Link>
          </S.LinkWrapper>
        </S.ModalContainer>
      </S.ModalOverlay>
    </S.Wrapper>
  );
};

export default observer(AdminOverquotaBlocked);
