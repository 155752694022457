/* eslint-disable react/no-unstable-nested-components */
import { observer } from "mobx-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import RestoreScrollProvider from "src/js/context/RestoreScrollProvider";
import MatchModuleRoute from "src/js/context/utils/MatchModuleRoute";
import { useNotificationListener } from "src/js/hooks";
import { AppLayout } from "src/js/layout/AppLayout";
import { createUrl } from "src/js/modules/routing";
import LogoutPage from "src/js/pages/App/LogoutPage/LogoutPage";
import SharePage from "src/js/pages/App/SharePage/SharePage";
import { UnderageApprovationPage } from "src/js/pages/App/UnderageApprovationPage";
import { JoinPage } from "src/js/pages/JoinPage";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import Search from "src/js/pages/search/Search";
import ExternalAuth from "src/js/pages/unlogged/ExternalAuth";
import { PrivacyPolicy } from "src/js/pages/unlogged/PrivacyPolicy";
import { ProfileEdit } from "src/js/pages/user/ProfileEdit";
import UserConfirmMail from "src/js/pages/user/UserConfirmMail";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";
import { RedirectToSpaceHome } from "../home";
import { DashboardRouter } from "./Dashboard/DashboardRouter";
import { DeaRouter } from "./Dea/DeaRouter";
import { SettingsRouter } from "./Settings/SettingsRouter";
import { SpacesRouter } from "./Spaces/SpacesRouter";
import { UsersRouter } from "./Users/UsersRouter";
import { useInitSpace } from "./hooks";

export const AppRouter = observer(() => {
  useNotificationListener();
  useInitSpace();
  return (
    <RestoreScrollProvider>
      <AppLayout>
        <MatchModuleRoute />
        <Switch>
          <Route
            path={WeSchoolRoutes.App.home}
            component={RedirectToSpaceHome}
          />
          <Route
            exact
            path={WeSchoolRoutes.App.externalAuth}
            component={ExternalAuth}
          />
          <Route path={WeSchoolRoutes.App.logout} component={LogoutPage} />
          <Route
            path={WeSchoolRoutes.App.joinCodiceOption}
            component={JoinPage}
          />
          <Route
            path={WeSchoolRoutes.App.privacyPolicy}
            component={PrivacyPolicy}
          />
          <Route exact path={WeSchoolRoutes.App.share} component={SharePage} />
          <Route
            exact
            path={WeSchoolRoutes.App.underageApprovation}
            component={UnderageApprovationPage}
          />
          <Route
            exact
            path={WeSchoolRoutes.App.publicProfile}
            component={ProfileEdit}
          />
          <Route
            exact
            path={WeSchoolRoutes.App.changeMail}
            component={UserConfirmMail}
          />
          <Route exact path={WeSchoolRoutes.App.search} component={Search} />
          <Route
            path={WeSchoolRoutes.App.Users.initialRouteName}
            component={UsersRouter}
          />
          <Route
            path={WeSchoolRoutes.App.Dea.initialRouteName}
            component={DeaRouter}
          />
          <Route
            path={WeSchoolRoutes.App.Settings.initialRouteName}
            component={SettingsRouter}
          />
          <Route
            path={WeSchoolRoutes.App.Dashboard.initialRouteName}
            component={DashboardRouter}
          />
          <Route
            path={WeSchoolRoutes.App.Spaces.initialRouteName}
            component={SpacesRouter}
          />
          <Route
            render={props => (
              <RedirectToLandingUrlOrDefault
                {...props}
                to={createUrl("home")}
              />
            )}
          />
        </Switch>
      </AppLayout>
    </RestoreScrollProvider>
  );
});
