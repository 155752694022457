import { Dropdown, Icon } from "@arcadia/design-system";
import React, { useCallback, useRef } from "react";
import { AnalyticsCalendar } from "src/js/components/AnalyticsCalendar";
import { useOnClickOutSide, useStores, useToggle } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import {
  DashboardEvent,
  DashboardRangeTimeType,
  EventDomain
} from "src/js/types";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { RangeTimeOptions } from "../../../Analytics.const";
import * as S from "./FilterTime.styles";

type FilterTimeProps = {
  range?: DashboardRangeTimeType;
  interval?: {
    from?: Date;
    to?: Date;
  };
  setRange: (range: DashboardRangeTimeType) => void;
  setInterval: (from: Date, to: Date) => void;
  dateLabel: string;
};

const FilterTime = ({
  range,
  interval,
  setRange,
  setInterval,
  dateLabel
}: FilterTimeProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const [calendarOpen, setCalendarOpen] = useToggle(false);

  const analyticsCalendar = useRef();
  useOnClickOutSide(analyticsCalendar, () => setCalendarOpen(false));

  const isIntervalSelected = interval?.from && interval?.to;
  const selectedValue = isIntervalSelected
    ? `${interval.from?.toLocaleDateString()}-${interval.to?.toLocaleDateString()}`
    : RangeTimeOptions.find(option => option.key === range)?.key;

  const onOptionsOpen = useCallback(isOpen => {
    if (!isOpen) return;
    _trackEvent(EventDomain.SpaceDashboardUsage, DashboardEvent.FilterDateOpen);
  }, []);

  return (
    <S.CalendarDropdownContainer>
      <Dropdown
        id="analytics-interval-dropdown"
        theme={whiteTheme}
        optionsList={RangeTimeOptions.map(({ key, value }) => ({
          id: key,
          label: translate(value)
        }))}
        selectedOptionId={selectedValue}
        setSelectedOptionId={id => {
          setRange(id);
          _trackEvent(
            EventDomain.SpaceDashboardUsage,
            DashboardEvent.FilterDateSelect,
            id
          );
        }}
        placeholder={dateLabel}
        onOptionsOpen={onOptionsOpen}
      />
      {!isLayoutModeMobile ? (
        <S.AnalyticsIconAndCalendarContainer ref={analyticsCalendar}>
          <S.CalendarIconContainer
            onClick={() => {
              setCalendarOpen(!calendarOpen);
              if (!calendarOpen) {
                _trackEvent(
                  EventDomain.SpaceDashboardUsage,
                  DashboardEvent.FilterDateCustom
                );
              }
            }}
          >
            <Icon icon="calendar" width={16} height={16} />
          </S.CalendarIconContainer>
          {calendarOpen ? (
            <S.AnalyticsCalendarContainer>
              <AnalyticsCalendar
                startDate={interval?.from}
                endDate={interval?.to}
                onChange={dates => {
                  setInterval(dates[0], dates[1]);
                }}
              />
            </S.AnalyticsCalendarContainer>
          ) : null}
        </S.AnalyticsIconAndCalendarContainer>
      ) : null}
    </S.CalendarDropdownContainer>
  );
};

export default FilterTime;
