import { useCallback, useEffect, useRef, useState } from "react";

const useCloseOnClickOutside = (defaultValue: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent | FocusEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("focusout", handleClickOutside, true);
      document.addEventListener("click", handleClickOutside, true);
      document.addEventListener("dragenter", handleClickOutside, true);
    } else {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("dragenter", handleClickOutside, true);
      document.removeEventListener("focusout", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("dragenter", handleClickOutside, true);
      document.removeEventListener("focusout", handleClickOutside, true);
    };
  }, [ref, isOpen, handleClickOutside]);

  return { ref, isOpen, setIsOpen };
};

export default useCloseOnClickOutside;
