import { TranslationKeys } from "src/js/translation/translated.types";

export const HEADER_TITLES: Record<string, TranslationKeys> = {
  delete: "mobile_header_settings_delete_account_option",
  notice: "mobile_settings_notifications",
  account: "mobile_settings_account",
  email: "mobile_header_settings_delete_account_email",
  name: "mobile_header_settings_change_name_option",
  password: "change_password",
  notifications: "mobile_header_space_notifications"
};
