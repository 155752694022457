import { createUrl } from "src/js/modules/routing";
import {
  createSearchString,
  updateLocalSearchSuggestion
} from "src/js/modules/searchFunction";
import { navigateTo } from "src/legacy/modules/history";
import { useTranslation } from "src/js/translation";
import { useSearchParameters } from "src/js/pages/search/hooks";
import { getSearchFiltersObject } from "src/js/pages/search/util";
import { useEffect } from "react";
import { SearchContextProps } from "./types";

export const useSearchContext = ({
  searchQuery,
  setSearchQuery
}: SearchContextProps & { setSearchQuery: (text: string) => void }) => {
  const { translate } = useTranslation();
  const { searchParams } = useSearchParameters();

  const parameters = getSearchFiltersObject(searchParams);

  useEffect(() => {
    setSearchQuery(searchParams.keyword);
  }, []);

  const placeholder = translate({
    text: "top_header_searchbar_desktop_search_placeholder"
  });

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateLocalSearchSuggestion(searchQuery);
    const url = `${createUrl("search")}?${createSearchString({
      ...parameters,
      keyword: searchQuery
    })}`;

    navigateTo(url);
  };

  return { onSubmit, placeholder };
};
