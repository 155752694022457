const colors = {
  violet: {
    100: "#16004D",
    300: "#5333A1",
    400: "#7351C8",
    500: "#8B5CFF",
    501: "#8452FF",
    600: "#9C80FF",
    700: "#C7B7FF",
    800: "#EDE5FF",
    900: "#EBE5FF"
  },
  coldGrey: {
    600: "#7979D2",
    700: "#9F9FDF",
    800: "#C6C6EC",
    900: "#F3F3FB"
  },
  blueGrey: {
    200: "#303A4F",
    300: "#43526F",
    400: "#57698F",
    500: "#8A99B8",
    700: "#B0BACF",
    800: "#CFD5E2",
    900: "#F3F3FB"
  },
  grey: {
    50: "#000000",
    100: "#262626",
    300: "#626262",
    500: "#8C8C8C",
    600: "#A6A6A6",
    700: "#BFBFBF",
    800: "#D6D6D6",
    900: "#F2F2F2",
    1000: "#FFFFFF"
  },
  success: {
    300: "#4BA013",
    500: "#80E83C",
    700: "#B5F28D"
  },
  error: {
    500: "#FF251A",
    600: "#FF5047",
    700: "#FF8680"
  },
  warning: {
    400: "#E66500",
    500: "#FF7F1B",
    600: "#FF9B4D",
    700: "#FFB780"
  },
  yellow: {
    400: "#D5E600",
    500: "#EDFF00",
    550: "#E0CA03",
    600: "#F2FF4D",
    800: "#FAFFB3"
  },
  cherry: {
    300: "#8A2934",
    400: "#C43A4A",
    500: "#CB4E5C",
    800: "#EEC4C9"
  },
  carnation: {
    400: "#D3123E",
    600: "#F15B7D",
    700: "#F58AA2",
    800: "#F7A3B6"
  },
  royalPurple: {
    300: "#583E74",
    400: "#694A8B",
    500: "#8B69AF",
    700: "#F58AA2"
  },
  summerGreen: {
    300: "#46743E",
    500: "#73AF6A",
    600: "#85B97D",
    700: "#B1D2AC"
  },
  lagoon: {
    300: "#1D6A95",
    500: "#278FC9",
    600: "#6AB7E2",
    700: "#94CBEA",
    900: "#EAF5FB"
  },
  goldenFizz: {
    500: "#F8EE33"
  }
};
export default colors;
