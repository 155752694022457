import { PageNav, Text } from "@arcadia/design-system";
import React from "react";
import { UserPanel } from "src/js/components/UserPanel";
import { __SPACE_USERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

import * as S from "./SpacesUsersList.styles";

const SpacesUsersList = ({
  filteredUsers,
  totalUsers,
  currentOffset,
  showProfile = () => {},
  manageGroupsRoles = () => {},
  removeFromGroups = () => {},
  openChat = () => {},
  changePage = () => {}
}) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <>
      <S.UserListHeader>
        <S.HeaderContent>
          <S.HeaderColumn large>
            <Text type="label">
              <Translate text="spaces_users_header_name" />
            </Text>
          </S.HeaderColumn>
          <S.HeaderColumn small>
            <Text type="label">
              <Translate text="spaces_users_header_birth_date" />
            </Text>
          </S.HeaderColumn>
          <S.HeaderColumn large>
            <Text type="label">
              <Translate text="spaces_users_header_mail" />
            </Text>
          </S.HeaderColumn>
          <S.HeaderColumn extrasmall>
            <Text type="label">
              <Translate text="spaces_users_header_groups_number" />
            </Text>
          </S.HeaderColumn>
          <S.HeaderColumn small>
            <Text type="label">
              <Translate text="spaces_users_header_registration_date" />
            </Text>
          </S.HeaderColumn>
        </S.HeaderContent>
        <S.ActionColumn>
          <Text type="label">
            <Translate text="actions" />
          </Text>
        </S.ActionColumn>
      </S.UserListHeader>
      {filteredUsers.map(userObject => (
        <UserPanel
          key={userObject?.user?.id}
          userObject={userObject}
          showProfile={showProfile}
          manageGroupsRoles={manageGroupsRoles}
          removeFromGroups={removeFromGroups}
          openChat={openChat}
        />
      ))}

      <S.RightAligner>
        <PageNav
          theme={greyTheme}
          totalRecords={totalUsers}
          pageLimit={__SPACE_USERS_LIST_LIMIT__}
          startingOffset={currentOffset}
          onPageChanged={changePage}
          label={{
            previous: translate({ text: "prev" }),
            next: translate({ text: "next" })
          }}
        />
      </S.RightAligner>
    </>
  );
};

export default SpacesUsersList;
