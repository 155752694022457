import { ActionButton, Box, Heading } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { useSpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import {
  copyEvent,
  deleteEvent,
  editEvent
} from "src/js/modules/calendarFunction";
import { showToastError } from "src/js/modules/messageManager";
import { openResource } from "src/js/modules/resourceFunction";
import { createUrl } from "src/js/modules/routing";
import { EventCategory } from "src/js/pages/calendar/Calendar.types";
import { EventActivityView } from "src/js/pages/event_activity/EventActivityView";
import { useTranslation } from "src/js/translation";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { EventRecipientsListModal } from "src/js/components/EventRecipientsListModal";
import * as S from "./EventActivity.styles";

const EventActivity = () => {
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: { groupId, userIsTeacher },
    CalendarStore,
    ModalStore
  } = useStores();
  const { setHeaderComponent, resetHeaderComponent } = useSpaceGroupsLayout();
  const { selectedEventDetail, getCalendarEventDetail, deleteCalendarEvent } =
    CalendarStore;
  const { openModal, closeModal } = ModalStore;
  const [resourceView, setResourceView] = useState(null);

  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const { event_id } = useParams();

  const openRecipientsModal = useCallback(
    ({ group_recipients, user_recipients }) => {
      openModal(() => (
        <EventRecipientsListModal
          closeModal={() => closeModal()}
          group_recipients={group_recipients}
          user_recipients={user_recipients}
        />
      ));
    },
    []
  );

  useEffect(() => {
    if (!groupId || !event_id) return;
    getCalendarEventDetail({
      groupId,
      calendarEventId: event_id
    }).catch(() => {
      showToastError({
        str: <Translate text="calendar_event_detail_loading_error" />
      });
    });
  }, [event_id, groupId]);

  const backToCalendar = () => {
    navigateTo(createUrl("calendar", { group_id: groupId }));
  };

  const eventOpenResource = async ({ id, type }) => {
    const resView = await openResource({
      id,
      type,
      groupId,
      eventType: EventCategory.Activity,
      eventId: event_id,
      onClose: () => setResourceView(null)
    });
    if (resView) {
      setResourceView(resView);
    }
  };

  const activityEditEvent = eventDetail => {
    editEvent({ eventDetail, openModal, closeModal });
  };

  const activityCopyEvent = eventDetail => {
    const copiedEvent = { ...eventDetail };
    delete copiedEvent.id;
    copyEvent({
      eventDetail: copiedEvent,
      openModal,
      closeModal: () => {
        closeModal();
        backToCalendar();
      }
    });
  };

  const activityDeleteEvent = calendarEventId => {
    deleteEvent({ calendarEventId, deleteCalendarEvent });
    backToCalendar();
  };

  const onGoBack = () => {
    const redirectToProfileUrl = createUrl("calendar", {
      group_id: groupId
    });
    navigateTo(redirectToProfileUrl);
  };

  const canEdit = userIsTeacher;

  useEffect(() => {
    setHeaderComponent(
      isLayoutModeMobile ? (
        <HeaderBack
          onGoBack={onGoBack}
          stringTitle={selectedEventDetail?.title}
          rightText={canEdit ? "event_activity_mobile_cta_edit" : undefined}
          onClickRightSection={
            canEdit ? () => activityEditEvent(selectedEventDetail) : undefined
          }
        />
      ) : (
        <SecondaryBar
          leftSection={
            <ActionButton
              icon="chevronLeft"
              theme={whiteTheme}
              variant="secondaryColored"
              onClick={onGoBack}
            />
          }
          middleSection={
            <Box textAlign="center">
              <Heading level="5">{selectedEventDetail?.title}</Heading>
            </Box>
          }
          rightSection={
            canEdit && (
              <Box display="flex" gap={8}>
                <ActionButton
                  icon="pencil"
                  theme={whiteTheme}
                  variant="secondaryColored"
                  onClick={() => activityEditEvent(selectedEventDetail)}
                />
                <ActionButton
                  icon="copy"
                  theme={whiteTheme}
                  variant="secondaryColored"
                  onClick={() => activityCopyEvent(selectedEventDetail)}
                />
                <ActionButton
                  icon="trash"
                  theme={whiteTheme}
                  variant="secondaryColored"
                  onClick={() => activityDeleteEvent(selectedEventDetail?.id)}
                />
              </Box>
            )
          }
        />
      )
    );

    return () => {
      resetHeaderComponent();
    };
  }, [isLayoutModeMobile, selectedEventDetail, canEdit]);

  if (isLayoutModeMobile) {
    return (
      <>
        <S.MobileLayoutWrapper>
          <S.MobileMainContainer>
            <S.MobileViewWrapper>
              <EventActivityView
                userIsTeacher={userIsTeacher}
                eventDetail={selectedEventDetail}
                openResource={eventOpenResource}
                openRecipientsModal={openRecipientsModal}
              />
            </S.MobileViewWrapper>
            {canEdit ? (
              <S.MobileButtonDelete
                onClick={() => activityDeleteEvent(selectedEventDetail?.id)}
                theme={whiteTheme}
                variant="danger"
                fullWidth
              >
                {translate("event_activity_mobile_cta_delete")}
              </S.MobileButtonDelete>
            ) : null}
          </S.MobileMainContainer>
        </S.MobileLayoutWrapper>
        {resourceView}
      </>
    );
  }

  return (
    <>
      <MainContainer>
        <EventActivityView
          userIsTeacher={userIsTeacher}
          eventDetail={selectedEventDetail}
          openResource={eventOpenResource}
          openRecipientsModal={openRecipientsModal}
        />
      </MainContainer>
      {resourceView}
    </>
  );
};

export default observer(EventActivity);
