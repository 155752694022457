import styled from "styled-components";

export const MonogramContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.coldGrey[800]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
