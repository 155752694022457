import colors from "@ds_themes/colors";

export const toggle = {
  backgroundOn: colors.grey[1000],
  backgroundOff: colors.violet[501],
  sliderOn: colors.violet[501],
  sliderOff: colors.grey[1000],
  disabled: {
    background: colors.grey[1000],
    sliderOn: colors.violet[700],
    sliderOff: colors.violet[700]
  },
  hover: {
    background: colors.grey[1000],
    slider: colors.violet[400]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};
