import { BaseComponentProps, Box, CardLesson } from "@arcadia/design-system";
import React, { FC } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { groupGetCover } from "src/js/GroupFunction";
import handleThumbnails from "src/js/modules/imageFunction";
import { createUrl } from "src/js/modules/routing";
import { NOTIFICATION_SETTINGS_TYPE } from "src/js/settings/settingsNotice";
import { GroupInSpaceModel } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

// With this cardLesson we lose the icons that determine the choosen settings
const getNotificationsNumber = ({ appPreference, notificationsNumber }) => {
  if (!appPreference) return notificationsNumber;
  if (appPreference === NOTIFICATION_SETTINGS_TYPE.NONE) return -1;

  if (!notificationsNumber) return 0;

  return notificationsNumber;
};

type Props = {
  groupsList: GroupInSpaceModel[];
} & BaseComponentProps;

const GroupsList: FC<Props> = ({ groupsList, className }) => {
  const { whiteTheme } = useTheme();

  return (
    <div className={`margin-top-15 ${className}`} id="tileContainer">
      <Box>
        <Row>
          {groupsList.map(item => {
            const { group, space } = item || {};
            const { id: spaceId = "" } = space || {};
            const isLoading = group?.cloningCompleted === false;
            return (
              <Col xs={12} sm={6} md={4} key={`${group.id}-${spaceId}`}>
                <div className="group-tile">
                  <div className="group-tile__content">
                    <CardLesson
                      isLoading={isLoading}
                      theme={whiteTheme}
                      title={group.name}
                      notificationsNumber={getNotificationsNumber({
                        appPreference:
                          item.notificationSettingsPreference?.appPreference,
                        notificationsNumber: item.unreadNotificationsCount
                      })}
                      imgUrl={handleThumbnails(groupGetCover(group), "large")}
                      hideOptions={!item.isTeacher}
                      onOptionsClick={() =>
                        navigateTo(
                          createUrl("group_settings", {
                            group_id: group.id,
                            option: "information"
                          })
                        )
                      }
                      onClick={() => {
                        navigateTo(
                          createUrl("wall", {
                            group_id: group.id,
                            space_slug: space.slug
                          })
                        );
                      }}
                      subscribersNumber={item.usersCount}
                      testsNumber={
                        item.isTeacher ? item.needReviewExercises : 0
                      }
                      space={{
                        name: space?.name,
                        badge: space?.badge?.small as string,
                        defaultBadgeColor: space?.defaultBadgeColor
                      }}
                    />
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Box>
    </div>
  );
};

export default GroupsList;
