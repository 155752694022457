import theme from "src/js/theme/theme";

export const colorArray = [
  theme.colors.violet[501],
  theme.colors.error[700],
  theme.colors.grey[50],
  theme.colors.yellow[300],
  theme.colors.royalPurple[500],
  theme.colors.summerGreen[500],
  theme.colors.lagoon[500],
  theme.colors.warning[500]
];
