import React from "react";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { showToastError } from "src/js/modules/messageManager";
import { FormSubtitle } from "src/js/components/typography/FormSubtitle";
import { askExecutionRedo } from "src/js/repository/reportRepository";
import { useStores } from "src/js/hooks";
import { Toggle } from "@arcadia/design-system";

import * as S from "./ReportSimpleFooter.styles";

const ReportSimpleFooter = ({
  defaultValue = false,
  exerciseId,
  executionId,
  quizExecutionId,
  onToggleChange = () => {}
}) => {
  const { ReportStore } = useStores();
  const { whiteTheme } = useTheme();
  const [loading, setLoading] = React.useState(false);

  const handleToggleChange = toggleStatus => {
    const { checked } = toggleStatus.target;
    setLoading(true);
    askExecutionRedo({
      exerciseId,
      executionId,
      quizExecutionId,
      status: checked
    })
      .then(() => {
        onToggleChange(checked);
        const threadCopy = checked
          ? "quiz_report_redelivery_open_label"
          : "quiz_report_redelivery_closed_label";

        ReportStore.addThreadAutomaticMessage({
          quizExecutionId,
          threadMessage: threadCopy
        });
      })
      .catch(() => {
        showToastError({ str: <Translate text="ask_resubmission_error" /> });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <S.TeacherFooterContainer>
      <S.ToggleContainer>
        <Toggle
          title={`requestResubmission ${quizExecutionId}`}
          theme={whiteTheme}
          checked={defaultValue}
          disabled={loading}
          onChange={toggleStatus => {
            handleToggleChange(toggleStatus);
          }}
        />
        <FormSubtitle>
          <S.ToggleLabel>
            <Translate text="report_ask_for_resubmission_toggle_label" />
          </S.ToggleLabel>
        </FormSubtitle>
      </S.ToggleContainer>
    </S.TeacherFooterContainer>
  );
};

export default ReportSimpleFooter;
