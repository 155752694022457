import React from "react";
import createReactClass from "create-react-class";
import { isElementInViewport } from "../modules/utility";
import {
  __IMG_LOADING__,
  __IMG_PLACEHOLDER__
} from "src/js/settings/settingsImage";
import { withTheme } from "styled-components";

const ImagePreviewer = createReactClass({
  getDefaultProps() {
    return {
      isReady: false,
      src: "",
      className: "",
      alt: "",
      ref: "",
      width: "auto",
      height: "auto",
      placeholder: __IMG_PLACEHOLDER__,
      background: "transparent",
      is_preview_generated: false,
      preview_available_generator: true,
      created: Date.now(),
      type: "IMG", // IMG con src oppure DIV con background
      threshold: 300000,
      forceSrc: false,
      showLoading: true,
      multiple: false
    };
  },
  getInitialState() {
    const self = this;
    const node = document.createElement("img");
    node.onload = self.onImageLoaded;
    return {
      hasImage: false,
      stopFetching: false,
      node,
      counter: 0,
      isReady: this.props.isReady || false,
      interval: {},
      isElementInViewport: false
    };
  },
  componentWillMount() {
    const self = this;
    if (!self.state.forceSrc) {
      self.setState({
        interval: setInterval(self.fetchImage, 1000)
      });
    }
  },
  componentDidMount() {
    if (!this.state.forceSrc) {
      this.fetchImage();
    }
    this.checkElementInViewport();
    $(window).on("scroll", this.checkElementInViewport);
  },
  componentWillUnmount() {
    this.clearInterval();
    $(window).off("scroll", this.checkElementInViewport);
  },
  checkElementInViewport() {
    if (isElementInViewport(this.domElement, true)) {
      this.setState({
        isElementInViewport: true
      });
    }
  },
  fetchImage() {
    if (this.shouldFetch()) {
      this.setState({
        counter: this.state.counter + 1
      });
      if (this.state.counter <= 1) {
        this.state.node.src = this.props.src;
      } else {
        console.info("Failed after", this.state.counter, this.props.src);
        this.setState({
          stopFetching: true
        });
        this.clearInterval();
      }
    }
  },
  onImageLoaded() {
    this.clearInterval();
    if (this.isMounted()) {
      this.setState({
        hasImage: true
      });
    }
  },
  clearInterval() {
    if (!this.state.forceSrc) {
      clearInterval(this.state.interval);
    }
  },
  /**
   * Use this.state.threshold 300k ms (5 mins) during the preview fetching.
   * @returns {Boolean} Preview generated.
   */
  shouldFetch() {
    if (
      !this.state.forceSrc &&
      this.props.preview_available_generator &&
      !this.state.stopFetching &&
      this.state.isElementInViewport
    ) {
      return (
        this.props.is_preview_generated ||
        Date.now() - this.props.created >= this.props.threshold
      );
    }

    return false;
  },
  render() {
    if (
      (this.props.isReady || this.props.forceSrc) &&
      this.props.type === "IMG"
    ) {
      return (
        <img
          src={this.props.src}
          className={this.props.className}
          alt={this.props.alt}
          ref={dom => {
            this.domElement = dom;
          }}
          width={this.props.width}
          height={this.props.height}
          style={{ backgroundColor: this.props.background }}
        />
      );
    }

    const self = this;
    const multiple = self.props.multiple;
    const placeholder = self.props.placeholder;
    let src = self.props.placeholder;
    const background = self.props.theme.primaryColor[500];

    if (self.shouldFetch() && self.props.showLoading) {
      src = __IMG_LOADING__;
    }

    if (self.state.hasImage || self.props.isReady || self.state.forceSrc) {
      src = self.props.src;
    }

    /** try to use custom placeholder instead of general placeholder */
    if (src === __IMG_PLACEHOLDER__) {
      src = self.props.placeholder;
    }

    if (self.props.type === "IMG") {
      return (
        <img
          src={src}
          className={self.props.className}
          alt={self.props.alt}
          ref={dom => {
            this.domElement = dom;
          }}
          width={self.props.width}
          height={self.props.height}
          style={{ backgroundColor: background }}
        />
      );
    }
    let addStyle;
    if (multiple) {
      addStyle = {
        backgroundImage: `url(${src}), url(${placeholder})`,
        backgroundColor: background,
        backgroundSize: self.state.hasImage ? "cover,auto" : "contain,auto"
      };
    } else {
      addStyle = {
        backgroundImage: `url(${src})`,
        backgroundColor: background,
        backgroundSize: self.state.hasImage ? "cover" : "contain"
      };
    }
    if (self.state.hasImage || self.props.isReady || self.state.forceSrc) {
      return (
        <div
          className={[
            "app__div-previewer",
            "has-image",
            self.props.className
          ].join(" ")}
          ref={dom => {
            this.domElement = dom;
          }}
          style={addStyle}
        />
      );
    }
    return (
      <div
        className={[
          "app__div-previewer",
          "has-placeholder",
          self.props.className
        ].join(" ")}
        ref={dom => {
          this.domElement = dom;
        }}
        style={addStyle}
      />
    );
  }
});

export default withTheme(ImagePreviewer);
