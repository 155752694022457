import styled from "styled-components";

import { LoaderColor } from "../types";

export const LoaderContainer = styled.div`
  display: flex;
  position: ${({ theme }) => theme.genericLoader.position};
  width: ${({ theme }) => theme.genericLoader.loaderWidth};
  height: ${({ theme }) => theme.genericLoader.loaderHeight};
  align-items: center;
  justify-content: center;
  top: ${({ theme }) => theme.genericLoader.top};
  right: ${({ theme }) => theme.genericLoader.right};
  bottom: ${({ theme }) => theme.genericLoader.bottom};
  left: ${({ theme }) => theme.genericLoader.left};

  @keyframes loader-container {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CircleOne = styled.div<LoaderColor>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${({ theme }) => theme.genericLoader.width};
  height: ${({ theme }) => theme.genericLoader.height};
  margin: ${({ theme }) => theme.genericLoader.margin};
  border: ${({ theme }) => theme.genericLoader.border} solid
    ${({ mainColor }) => mainColor};
  border-radius: ${({ theme }) => theme.genericLoader.borderRadius};
  animation: loader-container 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ borderColor }) => borderColor} transparent transparent
    transparent;
  animation-delay: -0.45s;
`;

export const CircleTwo = styled.div<LoaderColor>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${({ theme }) => theme.genericLoader.width};
  height: ${({ theme }) => theme.genericLoader.height};
  margin: ${({ theme }) => theme.genericLoader.margin};
  border: ${({ theme }) => theme.genericLoader.border} solid
    ${({ mainColor }) => mainColor};
  border-radius: ${({ theme }) => theme.genericLoader.borderRadius};
  animation: loader-container 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ borderColor }) => borderColor} transparent transparent
    transparent;
  animation-delay: -0.3s;
`;

export const CircleThree = styled.div<LoaderColor>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${({ theme }) => theme.genericLoader.width};
  height: ${({ theme }) => theme.genericLoader.height};
  margin: ${({ theme }) => theme.genericLoader.margin};
  border: ${({ theme }) => theme.genericLoader.border} solid
    ${({ mainColor }) => mainColor};
  border-radius: ${({ theme }) => theme.genericLoader.borderRadius};
  animation: loader-container 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ borderColor }) => borderColor} transparent transparent
    transparent;
  animation-delay: -0.15s;
`;

export const CircleFour = styled.div<LoaderColor>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${({ theme }) => theme.genericLoader.width};
  height: ${({ theme }) => theme.genericLoader.height};
  margin: ${({ theme }) => theme.genericLoader.margin};
  border: ${({ theme }) => theme.genericLoader.border} solid
    ${({ mainColor }) => mainColor};
  border-radius: ${({ theme }) => theme.genericLoader.borderRadius};
  animation: loader-container 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ borderColor }) => borderColor} transparent transparent
    transparent;
`;
