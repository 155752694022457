import { RefObject, useEffect, useRef } from "react";

type ResizeObserverCallback = (rect: DOMRectReadOnly) => void;

function useResizeObserver<T extends HTMLElement>(
  callback: ResizeObserverCallback
): RefObject<T> {
  const elementRef = useRef<T>(null);
  const observer = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    observer.current = new ResizeObserver(entries => {
      if (entries[0]) {
        callback(entries[0].contentRect);
      }
    });

    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [callback]);

  return elementRef;
}

export default useResizeObserver;
