/* eslint-disable react/no-unstable-nested-components */
import { InternalDispatcher } from "Legacy/modules/dispatcher";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ResourceEditor from "src/js/pages/resources/ResourceEditor";
import { Post } from "src/js/pages/wall/post";
import { POST_CONTEXT } from "src/js/pages/wall/post/Post.types";
import { fetchPost } from "src/js/repository/postRepository";
import { useTranslation } from "src/js/translation";
import { getActiveGroup } from "../modules/activeGroup";
import { getActiveUser } from "../modules/activeUser";
import { doRouteBack, navigateTo } from "../modules/history";
import { basePageModel } from "../modules/pageStatus";
import ReportAbuse from "../view/report/reportAbuseView";

const SinglePostController = ({ postsList }) => {
  const [postFull, setPostFull] = useState(null);
  const { translate } = useTranslation();
  const { post_id } = useParams();

  useEffect(() => {
    InternalDispatcher.on("post_resource_update", () => {
      setPostFull(null);
    });

    return () => {
      InternalDispatcher.off("post_resource_update");
    };
  }, []);

  useEffect(() => {
    if (!post_id) {
      navigateTo(createUrl("home"));
    } else {
      const oldPost = postsList.find(p => p.post.id === post_id);

      if (oldPost) {
        setPostFull(oldPost);
        return;
      }
      fetchPost(post_id)
        .then(res => {
          setPostFull(res);
        })
        .catch(e => {
          showToastError({
            str: extractErrorMessage(
              e.responseJSON,
              translate("error_post_not_found")
            )
          });
          navigateTo(createUrl("home"));
        });
    }
  }, [post_id]);

  return postFull ? (
    <Switch>
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.post}
        render={props => (
          <Post
            postFull={postFull}
            user={getActiveUser()}
            onDeletePost={(_, groupId) => {
              navigateTo(createUrl("wall", { group_id: groupId }));
            }}
            embedded={false}
            context={POST_CONTEXT.FULL_POST}
            {...props}
          />
        )}
      />

      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.postResource}
        render={props => (
          <Post
            postFull={postFull}
            user={getActiveUser()}
            onDeletePost={(_, groupId) => {
              navigateTo(createUrl("wall", { group_id: groupId }));
            }}
            embedded={false}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.postResourceEdit}
        render={props => (
          <ResourceEditor
            groupId={props.match.params.group_id}
            objId={props.match.params.resource_id}
            contextId={props.match.params.post_id}
            target="post"
          />
        )}
      />

      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.postReport}
        render={props => {
          const title = translate("report_abuse_title");
          const back = translate("back");
          const back_function = () => {
            doRouteBack();
          };

          let buttons;

          basePageModel.setTitle(getActiveGroup().get("name"));
          basePageModel.set({
            selectedTab: "custom",
            backFunc: {
              back,
              back_function,
              title,
              buttons
            }
          });

          return (
            // Needs to be fixed, contentModel props expect a backbone model, for now i've add some patch inside ReportAbuse
            <ReportAbuse
              category="post"
              contentModel={postFull.post}
              {...props}
            />
          );
        }}
      />
      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  ) : null;
};

export default SinglePostController;
