import styled from "styled-components";

export const Live = styled.div`
  width: 8px;
  height: 8px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: flex;
    width: 16px;
    height: 16px;
    top: -1px;
    left: -5px;
    border-radius: 50%;
    background-color: red;
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    display: flex;
    width: 8px;
    height: 8px;
    top: 3px;
    left: -1px;
    background-color: red;
    border-radius: 50%;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.5);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes circle {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
