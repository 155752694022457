import styled, { css } from "styled-components";

export const ParagraphContainer = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: ${({ theme }) => theme.weights.base};
  line-height: ${({ theme }) => theme.lineHeights.base};
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
`;
