import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import each from "lodash/each";
import clone from "lodash/clone";
import find from "lodash/find";
import { __ } from "../../../modules/localization";
import ResourceModel from "../../../models/resourceModel";
import {
  addHttpToUrl,
  isValidUrl,
  truncateText
} from "../../../modules/utility";
import ImagePreviewer from "../../../components/imagePreviewer";
import { fetchYoutubeSearchResult } from "src/js/repository/youtubeRepository";
import { DoodleLoader } from "@arcadia/design-system";
import { ActionButton } from "@arcadia/design-system";
import { Button } from "@arcadia/design-system";
import { SearchBar } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";

const ResourceYoutube = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        context: ""
      };
    },
    getInitialState() {
      this.props.setWorking(false);
      return {
        loading: true,
        items: [],
        nextPageToken: "",
        prevPageToken: "",
        youtubeId: "",
        q: "",
        queryIsUrl: false /** @type {Boolean} Check if the searched string is a valid url */
      };
    },
    componentDidMount() {
      const self = this;
      const input = this.searchInput;
      const $input = $(input);
      const container = this.container;
      const $container = $(container);
      self.setState({ $input, $container, loading: false });
      $("#searchInput").on("keyup", e => {
        if (e.keyCode == 13) {
          self.search();
        }
      });
    },
    componentWillUnmount() {
      $("#searchInput").off("keyup");
    },
    search() {
      this.setState({ loading: true });
      const self = this;
      const s = this.state.$input.val();

      // controllo se è un url valido
      const urlToTest = addHttpToUrl(s);
      if (
        isValidUrl(urlToTest) &&
        urlToTest.match(/youtube.com|yt.be|youtu.be/gi)
      ) {
        const videoId = self.getIdYoutube(urlToTest);
        this.setState({
          queryIsUrl: true,
          youtubeId: videoId
        });
      } else {
        this.setState({ queryIsUrl: false });
      }

      fetchYoutubeSearchResult(s).then(response => {
        self.setState({
          items: response.items,
          loading: false,
          nextPageToken: response.nextPageToken || "",
          prevPageToken: response.prevPageToken || "",
          q: s
        });
      });
    },
    prevPage() {
      const self = this;
      this.setState({ loading: true });

      fetchYoutubeSearchResult(self.state.q, this.state.prevPageToken).then(
        response => {
          self.setState({
            items: response.items,
            loading: false,
            nextPageToken: response.nextPageToken || "",
            prevPageToken: response.prevPageToken || ""
          });
        }
      );
    },
    nextPage() {
      const self = this;
      this.setState({ loading: true });

      fetchYoutubeSearchResult(self.state.q, this.state.nextPageToken).then(
        response => {
          self.setState({
            items: response.items,
            loading: false,
            nextPageToken: response.nextPageToken || "",
            prevPageToken: response.prevPageToken || ""
          });
        }
      );
    },
    toggleResource(item, e) {
      const self = this;
      const list = clone(self.state.items);
      const items = [];

      each(list, (element, index) => {
        if (element == item) {
          element.selected = true;
        } else {
          element.selected = false;
        }
        items.push(element);
      });

      self.setState({ items });

      e.stopPropagation();
    },
    addResource(item, e) {
      const self = this;

      self.props.setWorking(true);
      self.setState({ loading: true });

      const resource = new ResourceModel({
        name: item.snippet.title,
        youtube_id: item.id.videoId,
        type: "YOUTUBE"
      });

      resource.save(null, {
        success(model, data) {
          self.props.addFunc(resource.toJSON());
        },
        error(model, data) {
          self.props.setWorking(false);
          self.setState({ loading: false });
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        }
      });

      e.stopPropagation(); // altrimenti viene invocato anche il deselect della risorsa
    },
    getLinkForEmbed(link) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = link.match(regExp);
      if (match && match[7].length == 11) {
        const videoId = match[7];
        const linkSanitized = `https://www.youtube.com/embed/${videoId}?rel=0`;
        return linkSanitized;
      }
      return link;
    },
    youtubeTimeToSeconds(timeString) {
      if (!isNaN(timeString)) return timeString;
      // this regex gets the digits from a time format like this: 8h23m42s
      const regExp = /((\d+)h)?((\d+)m)?((\d+)s)/;
      const match = timeString.match(regExp);
      const hours = match[2] ? match[2] * 3600 : 0;
      const minutes = match[4] ? match[4] * 60 : 0;
      const seconds = parseInt(match[6], 10);
      return hours + minutes + seconds;
    },
    queryParamsFromHash(hashString) {
      if (!hashString) return { hashT: null, hashRel: null };
      const hashParams = new URLSearchParams(hashString.substring(1));
      const hashT = hashParams.get("t");
      const hashRel = hashParams.get("rel");
      return { hashT, hashRel };
    },
    getIdYoutube(link) {
      const url = new URL(link);
      const { hashT, hashRel } = this.queryParamsFromHash(url.hash);
      const urlSearchParams = new URLSearchParams();
      const queryParams = {
        start: this.youtubeTimeToSeconds(url.searchParams.get("t") || hashT),
        rel: url.searchParams.get("rel") || hashRel
      };
      Object.keys(queryParams).map(key => {
        return (
          queryParams[key] && urlSearchParams.append(key, queryParams[key])
        );
      });

      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = link.match(regExp);
      if (match && match[7].length == 11) {
        return (
          match[7] +
          (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : "")
        );
      }
      return false;
    },

    checkSanitizedLink(item, e) {
      const self = this;
      const idVideo = self.getIdYoutube(item.link);
      if (idVideo) {
        fetchYoutubeSearchResult(idVideo).then(response => {
          if (response.items.length > 0) {
            const itemFound = response.items[0];
            self.addResource(itemFound, e);
          } else {
            self.addResourceAsLink(item, e);
          }
        });
      } else {
        self.addResourceAsLink(item, e);
      }
    },

    /**
     * Add the video as a link (so we can add unlisted youtube videos)
     * @param {Object} item The resource object to add
     * @param {Event} e
     */
    addResourceAsLink(item, e) {
      const self = this;

      self.props.setWorking(true);
      self.setState({ loading: true });

      item.link = addHttpToUrl(item.link);

      if (
        isValidUrl(item.link) && // is valid link
        item.link.match(/youtube.com|yt.be|youtu.be/gi) // contains a youtube domain
      ) {
        const sanitizedLink = self.getLinkForEmbed(item.link);
        const resource = new ResourceModel({
          original_url: sanitizedLink,
          name: item.title,
          link_description: item.htmlSnippet,
          type: "LINK"
        });
        resource.save(null, {
          success(model, data) {
            self.props.setWorking(false);
            self.setState({ loading: false });
            self.props.addFunc(resource.toJSON());
          },
          error(model, data) {
            self.props.setWorking(false);
            self.setState({ loading: false });
            const errorMsg = extractErrorMessage(
              data.responseJSON,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
          }
        });
      } else {
        showToastError({ str: __("invalid_url_error") });
        self.setState({ loading: false });
      }
      e.stopPropagation(); // altrimenti viene invocato anche il deselect della risorsa
    },

    viewPreview(url) {
      window.open(url, "_blank");
    },
    render() {
      const self = this;
      let list = "";
      let content = "";
      let totalResults = 0;
      let selectedItem = null;
      let attachButtonLabel = __("resource_youtube_attach_button_label");
      let item = {};
      const { greyTheme, whiteTheme } = this.props.theme;

      if (self.props.context == "board") {
        attachButtonLabel = __("resource_youtube_add_button_label");
      }

      selectedItem = find(self.state.items, { selected: true });

      if (self.state.items !== undefined) {
        list = self.state.items.map(item => {
          let element = "";
          let elementExtraClass = "";
          let selectedOverlay = "";
          const videoUrl = `https://youtu.be/${item.id.videoId}`;

          element = (
            <div className="clearfix">
              <div className="row resource__search-item-wrapper">
                <div className="resource__search-item-image-wrapper">
                  <ImagePreviewer
                    className="resource__search-item-image"
                    type="DIV"
                    src={item.snippet.thumbnails.medium.url}
                    isReady
                  />
                </div>
                <div className="resource__search-item-text-wrapper">
                  <div className="resource__search-item-text">
                    <div className="resource__search-item-title">
                      {truncateText(item.snippet.title, 50)}
                    </div>
                    {item.snippet.channelTitle !== "" ? (
                      <div className="resource__search-item-subtitle">
                        {__("by")} {truncateText(item.snippet.channelTitle, 50)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );

          if (item == selectedItem) {
            elementExtraClass = "is-selected";
            selectedOverlay = (
              <div
                className="resource__selected-overlay"
                onClick={self.toggleResource.bind(null, null)}
              >
                <div className="full-height flex-column flex-centered-column flex-centered-row">
                  <div className="margin-bottom-10">
                    <Button
                      onClick={self.viewPreview.bind(self, videoUrl)}
                      variant="secondary"
                      theme={whiteTheme}
                    >
                      {__("ANTEPRIMA")}
                    </Button>
                  </div>
                  <Button
                    onClick={self.addResource.bind(self, selectedItem)}
                    variant="secondary"
                    theme={whiteTheme}
                  >
                    {attachButtonLabel}
                  </Button>
                </div>
              </div>
            );
          }

          return (
            <div className="col-lg-3 col-sm-4 col-ms-6 col-xs-12">
              <div
                className={[
                  "resource__search-item",
                  "card",
                  elementExtraClass
                ].join(" ")}
                onClick={self.toggleResource.bind(null, item)}
              >
                {element}
                {selectedOverlay}
              </div>
            </div>
          );
        });
      }

      if (self.state.searchInformation !== undefined) {
        totalResults = this.state.searchInformation.totalResults;
      }

      if (self.state.loading) {
        content = <DoodleLoader theme={greyTheme} />;
      } else if (list != "") {
        content = <div className="row">{list}</div>;
      } else if (list == "" && self.state.q != "") {
        content = (
          <div className="empty-list-message-box">
            <div className="empty-list-message-wrapper">
              {__("search_no_result")}
            </div>
          </div>
        );
      }

      let validLink = "";
      if (self.state.queryIsUrl && self.state.items.length == 0) {
        const title = $("#videoTitle").text();
        item = {
          snippet: { title },
          id: { videoId: self.state.youtubeId }
        };
        validLink = (
          <div className="alert alert-info">
            <div>
              <span>{__("unlisted_video_message")}</span>
              <div className="resource-attach-url-wrapper">
                <Button
                  onClick={self.addResource.bind(undefined, item)}
                  variant="primary"
                  theme={greyTheme}
                >
                  {__("use_video")}
                </Button>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="resource__search resource__search-cards">
          <div className="clearfix">
            <div className="resource__search-form form-group clearfix">
              <form action="#" style={{ width: "100%", maxWidth: "580px" }}>
                <SearchBar
                  theme={greyTheme}
                  type="text"
                  onChange={this.typeEvent}
                  ref={searchInput => {
                    this.searchInput = searchInput;
                  }}
                  onClick={e => {
                    e.preventDefault();

                    this.search();
                  }}
                  placeholder={__("cerca su Youtube")}
                />
              </form>
            </div>
          </div>
          {validLink}
          <div
            className="resource__search-results resource__youtube"
            ref={container => (this.container = container)}
          >
            {content}
            {this.state.items.length > 0 && !this.state.loading ? (
              <nav className="margin-vertical-10 flex-centered-column">
                {this.state.prevPageToken !== "" ? (
                  <span className="margin-right-5">
                    <ActionButton
                      icon="chevronLeft"
                      onClick={this.prevPage}
                      theme={whiteTheme}
                      variant="secondary"
                    />
                  </span>
                ) : null}
                {this.state.nextPageToken !== "" ? (
                  <span className="margin-left-5">
                    <ActionButton
                      icon="chevronRight"
                      onClick={this.nextPage}
                      theme={whiteTheme}
                      variant="secondary"
                    />
                  </span>
                ) : null}
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  })
);

export default withTheme(ResourceYoutube);
