import colors from "@ds_themes/colors";
import { primary as ButtonPrimary } from "@ds_themes/purple/button";
import {
  primary as ActionButtonPrimary,
  tertiary as ActionButtonTertiary
} from "@ds_themes/purple/actionButton";
import { checkbox as Checkbox } from "@ds_themes/purple/checkbox";
import { radiogroup as RadioGroup } from "@ds_themes/purple/radiogroup";
import { toggle as Toggle } from "@ds_themes/purple/toggle";
import { doodleloader as DoodleLoader } from "@ds_themes/purple/doodleloader";
import { searchbar as SearchBar } from "@ds_themes/purple/searchbar";

const purpleTheme = {
  background: colors.violet[501],
  text: colors.grey[1000],
  button: {
    primary: ButtonPrimary
  },
  actionButton: {
    primary: ActionButtonPrimary,
    tertiary: ActionButtonTertiary
  },
  checkbox: Checkbox,
  radiogroup: RadioGroup,
  toggle: Toggle,
  doodleloader: DoodleLoader.background,
  searchbar: SearchBar
};

export default purpleTheme;
