import { Avatar } from "@arcadia/design-system";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Hidden, Row } from "react-awesome-styled-grid";
import { GroupNumberBadge } from "src/js/components/GroupNumberBadge";
import { Panel } from "src/js/components/Panel";
import { useStores } from "src/js/hooks";
import {
  getBreakpoint,
  getVerticalBreakpoint
} from "src/js/modules/layoutFunction";
import {
  Translate,
  translateString
} from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { DotsMenu } from "../DotsMenu";
import { ListItemProps } from "../DotsMenu/ListItem";
// styles
import * as S from "./UserPanel.styles";

const UserPanel = ({
  userObject,
  isSelectable,
  onSelectRow,
  showProfile,
  openChat,
  removeFromGroups,
  manageGroupsRoles,
  ...props
}) => {
  const { groupsCount, user } = userObject || {};
  const { id, uuid, avatar, name, surname, birthday, email, created } = user;

  const {
    UserStore: { activeUser }
  } = useStores();
  const { whiteTheme } = useTheme();

  const dateBirthday = birthday && moment(birthday).format("L");
  const dateRegistration = created && moment(created).format("L");

  const manageRolesId = "manage-roles";

  // create dots menu items
  const allMenuItems: ListItemProps[] = [
    {
      id: "show-profile",
      label: translateString({ text: "show_profile" }),
      icon: "user",
      onClick: () => showProfile(uuid)
    },
    {
      id: manageRolesId,
      label: translateString({ text: "manage_groups_roles" }),
      icon: "gear",
      onClick: () => manageGroupsRoles(userObject)
    },
    {
      id: "remove-from-all-groups",
      label: translateString({
        text: "remove_from_all_groups_space_action"
      }),
      icon: "trash",
      onClick: () => removeFromGroups(userObject)
    },
    {
      id: "open-chat",
      label: translateString({ text: "open_chat" }),
      icon: "chat",
      onClick: () => openChat(uuid)
    }
  ];

  let menuItems =
    getBreakpoint() === "smartphone" || getVerticalBreakpoint() === "smartphone"
      ? allMenuItems.filter(item => item.id !== manageRolesId)
      : allMenuItems;

  if (activeUser.uuid === uuid) {
    menuItems = menuItems.filter(item => item.id !== "open-chat");
  }

  return (
    <Panel {...props} isSelectable={isSelectable} height={130}>
      <S.TriggerRowArea
        onClick={
          isSelectable
            ? () => {
                onSelectRow(id);
              }
            : null
        }
      >
        <S.InfoContainer>
          <S.TopMobile>
            <S.TopMobileTitle>
              <S.UserColumn>
                <Avatar
                  theme={whiteTheme}
                  url={avatar?.small}
                  alt={`${name} - ${surname}`}
                  rounded
                  size="m"
                />
                <S.UserFullName>
                  {name}&nbsp;<S.UserSurname>{surname}</S.UserSurname>
                  <S.Mail>{email}</S.Mail>
                </S.UserFullName>
              </S.UserColumn>
            </S.TopMobileTitle>
            <S.TopMobileActionContainer>
              <DotsMenu
                id="userPanelMobileDotsMenu"
                items={menuItems}
                alignRight
              />
            </S.TopMobileActionContainer>
          </S.TopMobile>

          <S.BottomMobile>
            <Row justify={{ xs: "space-between" }}>
              <div>
                <S.BottomMobileTitle>
                  <Translate text="spaces_users_header_birth_date" />
                </S.BottomMobileTitle>
                <div>{dateBirthday}</div>
              </div>
              <div>
                <S.BottomMobileTitle smallMargin>
                  <Translate text="spaces_users_header_groups_number" />
                </S.BottomMobileTitle>
                <GroupNumberBadge number={groupsCount} />
              </div>
              <div>
                <S.BottomMobileTitle>
                  <Translate text="spaces_users_header_registration_date" />
                </S.BottomMobileTitle>
                <div>{dateRegistration}</div>
              </div>
            </Row>
          </S.BottomMobile>

          <S.TabletAndUp>
            <S.Column large>
              <S.UserColumn>
                <Hidden lg>
                  <Avatar
                    theme={whiteTheme}
                    url={avatar?.small}
                    alt={`${name} - ${surname}`}
                    rounded
                    size="m"
                  />
                </Hidden>
                <S.UserFullName>
                  {name}&nbsp;<S.UserSurname>{surname}</S.UserSurname>
                </S.UserFullName>
              </S.UserColumn>
            </S.Column>
            <S.Column small>{dateBirthday}</S.Column>
            <S.Column large>
              <S.Mail>{email}</S.Mail>
            </S.Column>
            <S.Column extrasmall>
              <GroupNumberBadge number={groupsCount} />
            </S.Column>
            <S.Column small>{dateRegistration}</S.Column>
          </S.TabletAndUp>
        </S.InfoContainer>
      </S.TriggerRowArea>
      <S.HideOnXS>
        <DotsMenu id="userPanelDesktopDotsMenu" items={menuItems} alignRight />
      </S.HideOnXS>
    </Panel>
  );
};

export default observer(UserPanel);
