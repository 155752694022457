import styled from "styled-components";
import { __GROUP_PLACEHOLDER__ } from "src/js/settings/settingsImage";

export const GroupCover = styled.div`
  position: relative;
  margin-bottom: 20px;
  border-radius: 6px;
  background-image: ${props =>
    ` url(${props.image}) , url(${__GROUP_PLACEHOLDER__})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #3d7dea;
  height: 195px;
  display: flex;
  align-items: flex-end;
  padding: 20px;

  @media screen and (min-width: 992px) and (max-width: 1399px) {
    height: 185px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    height: 150px;
  }

  @media screen and (max-width: 767px) {
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 180px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const GroupTitle = styled.h3`
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    z-index: 1;
  }
`;

export const PremiumLabelWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;
