import { motion } from "framer-motion";
import styled from "styled-components";
import shadow from "@ds_themes/shadow";

export const DropdownWrapper = styled.div`
  max-width: 300px;
  position: relative;
  display: block;
`;

export const DropdownTrigger = styled(motion.button)`
  background: none;
  transition: box-shadow 300ms ease-in-out;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  border: none;
  outline: none;
  overflow: hidden;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.actionitemsdropdown.shadow};
  }
`;

export const DropdownContent = styled(motion.div)`
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.actionitemsdropdown.background};
  box-shadow: ${shadow[400]};
`;

export const ButtonContent = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.actionitemsdropdown.background};
  padding: 24px;
  cursor: pointer;
  user-select: none;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
