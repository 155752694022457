import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import styled from "styled-components";

export const ModalUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 10px;
  margin-bottom: 12px;
`;

export const ModalUserAvatar = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ModalUserName = styled.div`
  margin-left: 16px;
  align-items: center;
  display: flex;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const BadgeImageHoverLayer = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.violet[900]};
  border-radius: 4px;
  opacity: 0;
`;

export const BadgeImageContainer = styled.div`
  margin-left: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${BadgeImageHoverLayer} {
    opacity: 1;
  }
`;

export const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grey[50]};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
`;

export const ScrollableArea = styled(ScrollableDiv)`
  padding: 0px 24px;
  margin-right: 0px;
`;

export const SearchBarWrapper = styled.div`
  padding: 16px 24px;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const CustomModalLayout = styled(ModalLayout)`
  overflow-y: hidden;
`;

export const UserListWrapper = styled.div`
  @media (max-width: 767px) {
    max-height: calc(100% - 125px);
    height: 100%;
  }
`;
