import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Editor, posToDOMRect } from "@tiptap/core";
import { AnimatePresence, motion } from "framer-motion";
import WSTheme from "src/js/theme/theme";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { useAppLayout } from "src/js/layout/AppLayout";

const getDomRect = (editor: Editor) => {
  try {
    const cursorDomRect = posToDOMRect(
      editor.view,
      editor.state.selection.from,
      editor.state.selection.to
    );

    const editorDom = editor?.view?.dom;
    const editorStyles = window.getComputedStyle(editorDom);
    const {
      paddingLeft: pl,
      paddingRight: pr,
      paddingTop: pt,
      paddingBottom: pb
    } = editorStyles;
    const calculatedRect = editorDom.getBoundingClientRect();
    const modifiedRect = new DOMRect(
      calculatedRect.x + parseFloat(pl),
      calculatedRect.y + parseFloat(pt),
      calculatedRect.width - (parseFloat(pl) + parseFloat(pr)),
      calculatedRect.height - (parseFloat(pt) + parseFloat(pb))
    );

    return new DOMRect(
      modifiedRect.x,
      cursorDomRect.y + window.scrollY,
      modifiedRect.width,
      cursorDomRect.height
    );
  } catch (e) {
    return new DOMRect();
  }
};
const ControlledMenu: React.FC<{
  editor: Editor;
  hidden: boolean;
  children: ReactNode;
}> = ({ editor, hidden, children }) => {
  const [domRect, setDomRect] = useState(new DOMRect());
  const [scrollY, setScrollY] = useState(0);
  const [initialScroll, setInitialScroll] = useState(0);
  const {
    UIStore: { isSideBarOpen, isLayoutModeMobile }
  } = useStores();

  const { headerHeight } = useAppLayout();

  const domEl = document.getElementById("ai-writer-portal");

  useEffect(() => {
    if (hidden) return () => {};
    setDomRect(getDomRect(editor));
    return () => {
      setDomRect(getDomRect(editor));
    };
  }, [hidden, editor.state.selection]);

  useEffect(() => {
    setDomRect(getDomRect(editor));
  }, [isSideBarOpen]);

  useEffect(() => {
    const handleResize = () => {
      setDomRect(getDomRect(editor));
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [hidden]);

  // TODO check if this is still working, should keep the element in the viewport during scroll.
  // the only problem is about the overlap of the header

  useEffect(() => {
    const editorContainer = document.getElementById("tiptap-editor-wrapper");

    if (!editorContainer || hidden) return () => {};

    setInitialScroll(editorContainer.scrollTop);

    const handleScroll = () => {
      const scrollOffset = editorContainer.scrollTop - initialScroll;
      setScrollY(scrollOffset);
    };

    handleScroll();

    if (!hidden) {
      setInitialScroll(editorContainer.scrollTop);
      editorContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      setScrollY(0);
      editorContainer.removeEventListener("scroll", handleScroll);
    };
  }, [hidden]);

  const topPosition = isLayoutModeMobile
    ? domRect.top + domRect.height - 42 - 54 - headerHeight - scrollY
    : domRect.top + domRect.height - 120 - headerHeight - scrollY;

  if (!domEl) return null;

  return createPortal(
    <AnimatePresence>
      {!hidden && (
        <motion.div
          onKeyDown={e => {
            if (e.key === "Escape") {
              editor.chain().setAIPromptActive(false).focus();
            }
          }}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
          style={{
            position: "absolute",
            top: `${topPosition}px`,
            left: "12px",
            width: isLayoutModeMobile
              ? `${domRect.width + 16}px`
              : `${domRect.width}px`,
            zIndex: WSTheme.zIndex.header - 1
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    domEl
  );
};

export default observer(ControlledMenu);
