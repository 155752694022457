import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import each from "lodash/each";
import clone from "lodash/clone";
import map from "lodash/map";
import find from "lodash/find";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import moment from "moment";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { Translate } from "src/js/translation/TranslationProvider";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";

const FillBlankCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        execution: null,
        quiz_execution: null,
        corrector: null,
        number: 0
      };
    },
    render() {
      /* use collection in order to auto init correct submodel */
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const quiz = tempQuizCollection.at(0);
      const { quiz_execution } = this.props;
      const { execution } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();

      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));

      let answersGiven = null;

      const formattedUserAnswer = [];
      const formattedSolution = [];

      each(questions, (word, index) => {
        const w = clone(word);
        w.isSolution = false;
        w.hasSolution = false;
        if (w.value === "") {
          /**
           * Word is a question when it has a valid related solution.
           * It would be just an empty string otherwise.
           */
          const relatedSolution = find(solutions, { index: w.index });
          if (relatedSolution !== undefined && relatedSolution !== "") {
            w.isSolution = true;
            w.hasSolution = true;
            w.value = relatedSolution.value;
          }
        }
        formattedSolution.push(w);
      });

      each(questions, (word, index) => {
        const w = clone(word);
        w.isSolution = false;
        w.hasSolution = false;
        if (w.value === "") {
          /**
           * Word is a question when it is not undefined AND its related question is actually a question
           */
          const relatedSolution = find(userAnswers, { index: w.index });
          if (
            relatedSolution !== undefined &&
            formattedSolution[index].isSolution
          ) {
            w.isSolution = true;
            w.hasSolution = true;
            w.value = relatedSolution.value;
          }
        }
        formattedUserAnswer.push(w);
      });

      answersGiven = (
        <tr>
          <td>
            <div className="correction_answer-item">
              {map(formattedSolution, (w, index) => {
                if (w.isSolution !== undefined && w.isSolution) {
                  return (
                    <span>
                      <span className="exen_summary_quiz_label">{w.value}</span>{" "}
                    </span>
                  );
                }
                return `${w.value} `;
              })}
            </div>
          </td>
          <td>
            <div className="correction_answer-item">
              {map(formattedUserAnswer, (w, index) => {
                let quiz_class = "is-wrong";
                if (w.isSolution) {
                  if (w.hasSolution) {
                    const answer = w.value;
                    const solution = formattedSolution[index].value;
                    if (answer.toLowerCase() === solution.toLowerCase()) {
                      quiz_class = "is-correct";
                    }
                    return (
                      <span>
                        <span
                          className={[
                            "exen_summary_quiz_label",
                            quiz_class
                          ].join(" ")}
                        >
                          {answer}
                        </span>
                      </span>
                    );
                  }
                  return (
                    <span>
                      <span
                        className={["exen_summary_quiz_label", quiz_class].join(
                          " "
                        )}
                      >
                        {__("quiz_not_answered_label")}
                      </span>
                    </span>
                  );
                }
                return `${w.value} `;
              })}
            </div>
          </td>
        </tr>
      );

      let voteType;
      if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      const executionData = execution.get("updated_at");
      const executionDataFormatted = moment
        .unix(executionData)
        .format("DD/MM/YYYY HH:mm");
      const executionFromNow = moment.unix(executionData).fromNow();

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon icon="pickertest-completesentence" width="60" height="60" />
              <ColorLabel type={voteType}>
                <strong>
                  {quiz_execution.points}/{quiz.getAvailablePoints()}
                </strong>{" "}
                <Translate text="quiz_points_label" />
              </ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail quizfillblank">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  <ExerciseQuizTitle quizTitle={quiz.getTitle()} />
                </div>
              </div>
              <div className="correction_answers-wrapper margin-bottom-10 unselectable">
                <div className="answer_info">
                  <CommentTitle>
                    {__("quiz_report_reply_of_label")}&nbsp;
                    {execution.getQuizExecutioner()}
                  </CommentTitle>
                  <div className="margin-left-10 margin-left-xs-0">
                    <CommentSubtitle>
                      {`${executionDataFormatted} (${executionFromNow})`}
                    </CommentSubtitle>
                  </div>
                </div>
                <table className="table table-condensed table-borderless small">
                  <tbody>
                    <tr>
                      <th>{__("domanda")}</th>
                      <th>{__("risposta utente")}</th>
                    </tr>
                    {answersGiven}
                  </tbody>
                </table>
              </div>
              {this.props.corrector}
            </div>
          </Col>
        </>
      );
    }
  })
);

export default FillBlankCorrectionDisplayer;
