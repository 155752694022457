import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { NoMessages } from "src/js/components/Thread/components/NoMessages";
import { useStores, useTimeout } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { TextEditorInput } from "../TextEditorInput";
import { getPlaceholderUsersList } from "./Thread.const";
import * as S from "./Thread.styles";
import { HandleSendMessageProps, ThreadProps } from "./Thread.types";
import { ThreadMessageList } from "./components/ThreadMessageList";
import useThreads, { ThreadEvent } from "./hooks/useThreads";

const Thread = ({
  activeThread,
  handleIsTyping,
  isReadOnly = false
}: ThreadProps) => {
  const {
    SpaceStore: { activeSpaceId },
    UserStore: { activeUser }
  } = useStores();

  const requestAnimationRef = useRef<number>(null);
  const scrollToBottomRef = useRef<HTMLDivElement>(null);
  const chatScrollRef = useRef<HTMLDivElement>();
  const chatContainerRef = useRef<HTMLDivElement>();

  const { translate } = useTranslation();
  const { greyTheme } = useTheme();

  useEffect(() => {
    return () => {
      cancelAnimationFrame(requestAnimationRef.current);
    };
  }, []);

  const scrollToChatBottom = useTimeout(() => {
    // Scroll to the bottom of the chat messages scrollable container
    if (scrollToBottomRef?.current) {
      scrollToBottomRef.current.scrollIntoView({
        behavior: "auto",
        block: "start"
      });
    }
  }, 50);

  const handleEvents = event => {
    if (event === ThreadEvent.OnAddNewMessage) {
      scrollToChatBottom.start();
    }
  };

  const {
    messageList,
    hasNextMessages,
    isLoading,
    getNextMessages,
    addNewMessage,
    removeMessageFromList,
    oldestUnreadMessage,
    editableMessage,
    setEditableMessage,
    editMessage,
    createMessageReaction,
    deleteMessageReaction
  } = useThreads({
    spaceId: activeSpaceId,
    userId: activeUser?.uuid,
    thread: activeThread,
    handleEvents,
    startScrollToChatBottom: scrollToChatBottom.start
  });

  const nextInfiniteScroll = () => {
    getNextMessages();
    // Anchor scroll
    if (requestAnimationRef.current) {
      cancelAnimationFrame(requestAnimationRef.current);
    }
    const scroll = chatScrollRef.current;
    const container = chatContainerRef.current;
    let previousBoundingRect = container.getBoundingClientRect();
    function scrollAdjustment() {
      const boundingRect = container.getBoundingClientRect();
      const isHeightIncreased =
        boundingRect.height !== previousBoundingRect.height;
      if (isHeightIncreased) {
        const newScrollYPosition =
          scroll.scrollHeight - previousBoundingRect.height;
        previousBoundingRect = boundingRect;
        scroll.scrollTo(0, newScrollYPosition);
        requestAnimationRef.current = null;
        return;
      }
      requestAnimationRef.current = requestAnimationFrame(scrollAdjustment);
    }
    requestAnimationRef.current = requestAnimationFrame(scrollAdjustment);
  };

  const handleKeyDown = () => {
    handleIsTyping();
  };

  const handleSendMessage = async ({
    message,
    resources
  }: HandleSendMessageProps) => {
    await addNewMessage({
      message,
      resources
    } as HandleSendMessageProps);
    scrollToChatBottom.start();
  };

  const placeholder = `${translate(
    "chat_input_placeholder"
  )}${getPlaceholderUsersList(activeThread?.users, activeUser?.uuid)}`;

  let ThreadMessageListComponent: JSX.Element;

  if (messageList.length > 0) {
    ThreadMessageListComponent = (
      <S.MessageListWrapper ref={chatContainerRef}>
        <S.StyledInfiniteScroll
          scrollableTarget="message-list"
          dataLength={messageList.length}
          next={nextInfiniteScroll}
          hasMore={hasNextMessages}
          loader={null}
          inverse
        >
          <ThreadMessageList
            threadData={messageList}
            users={activeThread?.users}
            removeMessageFromList={removeMessageFromList}
            activeThreadId={activeThread?.id}
            scrollToChatBottom={scrollToChatBottom.start}
            unreadMessages={activeThread?.unreadMessages}
            oldestUnreadMessage={oldestUnreadMessage}
            editableMessageId={editableMessage?.id}
            setEditableMessage={setEditableMessage}
            createMessageReaction={createMessageReaction}
            deleteMessageReaction={deleteMessageReaction}
          />
          <div ref={scrollToBottomRef} />
        </S.StyledInfiniteScroll>
      </S.MessageListWrapper>
    );
  } else {
    ThreadMessageListComponent = isLoading ? (
      <S.StyledDoodleLoader theme={greyTheme} />
    ) : (
      <NoMessages />
    );
  }

  return (
    <>
      <S.ContainerBody ref={chatScrollRef} id="message-list">
        {ThreadMessageListComponent}
      </S.ContainerBody>
      {isReadOnly ? null : (
        <S.ThreadInputWrapper>
          <TextEditorInput
            submitFunction={handleSendMessage}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            editableMessage={editableMessage}
            setEditableMessage={setEditableMessage}
            editFunction={editMessage}
          />
        </S.ThreadInputWrapper>
      )}
    </>
  );
};

export default observer(Thread);
