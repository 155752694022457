import { DoodleLoader, getHighContrastColor } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledDoodleLoader = styled(DoodleLoader)`
  & > div {
    width: 100%;
  }
`;

export const PageContainer = styled.div`
  padding: 60px 0 120px;
  @media (max-width: 768px) {
    padding: 10px 0 120px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const UsersCounter = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  min-width: 24px;
  text-align: center;
  padding: 0 10px;
  background: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => getHighContrastColor(theme.primaryColor[500])};
`;
