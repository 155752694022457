import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import {
  SaveResourceResponseType,
  saveResourceNew
} from "src/js/repository/resourceRepository";
import {
  IMAGE_MIME,
  __SCORM_UPLOAD_STANDARD_LIMITS__,
  __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__
} from "src/js/settings/settingFileUploader";
import { RESOURCE_TYPE, ResourceType, TempResource } from "src/js/types";

type FileResponseData = {
  fileUuid: string;
  fileUrl: string;
  file: File;
  name: string;
  type: ResourceType;
};

type ImageGalleryData = {
  [propKey: string]: string;
  galleryName: string;
  fileUuid: string;
};

export type PrepareResourcePayload = (
  fileList: FileList,
  isScorm?: boolean
) => void;

export type PrepareWSSaveResource = () => Promise<SaveResourceResponseType>;

export type PrepareWSResource = (
  tempResource: TempResource,
  saveResource: PrepareWSSaveResource
) => void;

type ResourcePayloadOptions = {
  hasGallery?: boolean;
};

type ResourcePayloadProps = {
  onLoadingCallback?: (id: number, progress: number) => void;
  onSuccessCallback: (successData) => void;
  onErrorCallback?: (error) => void;
  options?: ResourcePayloadOptions;
};

type ResourcePayloadReturnProps = {
  prepareResourcePayload: PrepareResourcePayload;
};

const useResourcePayload = ({
  onLoadingCallback = () => {},
  onSuccessCallback = () => {},
  onErrorCallback = () => {},
  options
}: ResourcePayloadProps): ResourcePayloadReturnProps => {
  const {
    SpaceStore: { activeSpaceSlug: spaceSlug }
  } = useStores();
  const { hasGallery = true } = options || {};

  const { handleResourceFileUpload } = useResourceUpload(onLoadingCallback);

  const { handleResourceGalleryUpload } = useResourceUpload(onLoadingCallback);

  const isImageGallery = (files: File[]) => {
    if (files.length === 1) return false;
    let count = 0;
    files.map(el => {
      if (IMAGE_MIME.find(type => el.type === type)) count += 1;
      return true;
    });
    return count === files.length;
  };

  const onSuccessOfGalleryUpload = (resourceGalleryData: ImageGalleryData) => {
    const { galleryName, fileUuid, ...rest } = resourceGalleryData;
    saveResourceNew({
      name: galleryName,
      type: RESOURCE_TYPE.IMAGE_GALLERY,
      spaceSlug,
      fileUuid,
      publicUrls: rest
    })
      .then(resp => {
        onSuccessCallback(resp);
      })
      .catch(error => {
        onErrorCallback(error);
      });
  };

  const onSuccessOfUpload = (resourceFileResponseData: FileResponseData) => {
    saveResourceNew({
      name: resourceFileResponseData.name,
      type: resourceFileResponseData.type,
      size: resourceFileResponseData.file.size,
      fileMimeType: resourceFileResponseData.file.type,
      spaceSlug,
      fileUuid: resourceFileResponseData.fileUuid,
      publicUrls: [resourceFileResponseData.fileUrl]
    })
      .then(resp => {
        onSuccessCallback(resp);
      })
      .catch(error => {
        onErrorCallback(error);
      });
  };

  const prepareResourcePayload = (fileList: FileList, isScorm = false) => {
    const arrayListOfFiles = Array.from(fileList);
    const filteredFiles = arrayListOfFiles.filter(file =>
      isScorm
        ? file.size < __SCORM_UPLOAD_STANDARD_LIMITS__
        : file.size < __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__
    );

    if (!filteredFiles.length) return;

    if (hasGallery && isImageGallery(filteredFiles)) {
      filteredFiles.forEach(file => {
        handleResourceGalleryUpload([file], 0, res =>
          onSuccessOfGalleryUpload(res)
        );
      });
    } else {
      filteredFiles.map(async (file, index) => {
        const payload: {
          file: File;
          spaceSlug: string;
          uploadingElementId: number;
        } = {
          file,
          spaceSlug,
          uploadingElementId: index
        };

        handleResourceFileUpload(payload, isScorm, resp =>
          onSuccessOfUpload(resp)
        );
      });
    }
  };
  return { prepareResourcePayload };
};

export default useResourcePayload;
