import { Button } from "@arcadia/design-system";
import React from "react";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import * as S from "./SpaceBlockModal.styles";

const SpaceBlockModal = ({
  video,
  description,
  primaryCta = () => {},
  primaryCtaLabel,
  secondaryCta = () => {},
  secondaryCtaLabel,
  zIndex
}) => {
  const mobile = getBreakpoint() !== "desktop";
  const { whiteTheme } = useTheme();

  return (
    <S.ModalOverlay zIndex={zIndex}>
      <S.ModalContainer>
        <VideoPlayer video={video} />
        <S.Description type="formSubtitle">
          {translateString({ text: description })}
        </S.Description>
        <S.ModalFooterContainer>
          {primaryCtaLabel && (
            <Button
              onClick={primaryCta}
              theme={whiteTheme}
              variant="primary"
              fullWidth={mobile}
            >
              {translateString({ text: primaryCtaLabel })}
            </Button>
          )}
          {secondaryCtaLabel && (
            <Button
              onClick={secondaryCta}
              theme={whiteTheme}
              variant="secondary"
              fullWidth={mobile}
            >
              {translateString({ text: secondaryCtaLabel })}
            </Button>
          )}
        </S.ModalFooterContainer>
      </S.ModalContainer>
    </S.ModalOverlay>
  );
};

export default SpaceBlockModal;
