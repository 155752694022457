import styled, { css } from "styled-components";

const transforms = {
  top: "translateY(-100%)",
  right: "translateX(100%)",
  bottom: "translateY(100%)",
  left: "translateX(-100%)"
};

const placements = {
  top: {
    top: "54px",
    right: 0,
    left: 0
  },
  right: {
    top: "54px",
    right: 0,
    bottom: 0
  },
  bottom: {
    right: 0,
    bottom: 0,
    left: 0
  },
  left: {
    top: 0,
    bottom: 0,
    left: 0
  }
};

export const DrawerContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.title};
  display: flex;
  flex-direction: column;
  flex: 1 0 60px;
  position: fixed;
  ${({ position }) => placements[position]}
  z-index: ${({ theme }) => theme.zIndex.toTheSky};
  width: 415px;
  max-width: ${({ size }) => `${size}`};
  transform: ${({ open, position }) => (!open ? transforms[position] : null)};
  transition: transform 0.2s ease-out;
  overflow-x: hidden;
  color: ${({ theme }) => theme.colors.grey[50]};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.basicWhite};

  ${({ open }) =>
    open &&
    css`
      box-shadow: -5px 0px 10px rgb(0 0 0 / 8%);
    `}

  @media screen and (max-width:767px) {
    width: 100%;
    top: 0;
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  position: fixed;
  width: inherit;
  height: 72px;
  align-items: center;
  padding: 0px 15px 0px 20px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
  background-color: ${({ theme }) => theme.colors.basicWhite};
`;

export const DrawerContent = styled.div`
  padding-top: 72px;
  padding-bottom: 180px;

  height: 100%;
`;

export const DrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > :first-child {
    ${({ from_bottom }) =>
      from_bottom &&
      css`
        margin-top: auto;
      `}
  }
`;

export const DrawerFooter = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
  padding: 20px;
  position: fixed;
  height: 181px;
  bottom: 0;
  width: inherit;
  background-color: ${({ theme }) => theme.colors.basicWhite};
`;

export const LastElement = styled.div``;
