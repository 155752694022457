import React, { FC } from "react";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ClosableError.styles";
import { ClosableErrorProps } from "./types";

const ClosableError: FC<ClosableErrorProps> = ({ text, onClickFunction }) => {
  return (
    <S.ClosableErrorContainer>
      <S.ClosableErrorIcon>
        <Icon icon="dangerTriangle" width={16} height={16} />
      </S.ClosableErrorIcon>
      <S.ClosableErrorMessage>
        <Text type="cta">{text}</Text>
      </S.ClosableErrorMessage>
      <S.ClosableErrorClose onClick={onClickFunction}>
        <Icon icon="close" width={16} height={16} />
      </S.ClosableErrorClose>
    </S.ClosableErrorContainer>
  );
};

export default ClosableError;
