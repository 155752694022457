import styled from "styled-components";

export const RecentSearchTitle = styled.div`
  padding: 8px 0px 8px 16px;
`;

export const SuggestionContainer = styled.div`
  z-index: 900;
`;

export const UserCardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const UserCard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  span {
    margin-left: 0px;
  }
`;

export const Name = styled.div`
  margin-left: 10px;
`;

export const Cross = styled.div`
  position: flex;
  margin-left: auto;

  cursor: pointer;
`;
