import { Loader } from "@arcadia/design-system";
import React from "react";
import { createPortal } from "react-dom";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";

import * as S from "./LoadingDemoSpace.styles";

const LoadingDemoSpace: React.FC = () => {
  const { primaryColor, whiteTheme } = useTheme();
  const { translate } = useTranslation();

  let domEl = document.getElementById("loading-demo-space-root");

  if (!domEl) {
    domEl = document.createElement("div");
    domEl.setAttribute("id", "loading-demo-space-root");
    document.body.appendChild(domEl);
  }

  return createPortal(
    <S.BlockingModalLayout>
      <S.BlockingModalWrapper>
        <Loader
          theme={whiteTheme}
          loaderType="CircularLoader"
          loaderWidth="20px"
          loaderHeight="20px"
          width="20px"
          height="20px"
          mainColor={primaryColor[800]}
          borderColor={primaryColor[500]}
        />
        <S.LoaderText type="body">
          {translate("demo_space_loader_text")}
        </S.LoaderText>
      </S.BlockingModalWrapper>
    </S.BlockingModalLayout>,
    domEl
  );
};

export default LoadingDemoSpace;
