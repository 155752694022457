import React, { useEffect, useState } from "react";
import { fetchPublicProfile } from "src/js/repository/publicProfileRepository";

import { useHistory, useLocation } from "react-router";
import { Icon } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { ImageModel, ProfileUser } from "src/js/types";
import { UserProfileTooltip } from "src/js/components/UserProfileTooltip";

import * as S from "./Header.styles";

const Header = () => {
  const location = useLocation();
  const { translate } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState<ProfileUser | null>(null);
  const queryParams = new URLSearchParams(location.search);
  const userUuid = queryParams.get("userUuid");

  const removeQueryParams = () => {
    history.push(history.location.pathname);
  };

  useEffect(() => {
    if (!userUuid) return;
    fetchPublicProfile(userUuid).then(res => {
      setUser(res as ProfileUser);
    });
  }, [userUuid]);

  return (
    <S.HeaderWrapper>
      <S.BreadCrumbWrapper>
        <S.Text>
          {user ? (
            <>
              <S.MutedText>
                {" "}
                ... {">"}
                <S.ClickableText onClick={removeQueryParams}>
                  <span>{translate("record_scorm_tab_header_title")}</span>
                </S.ClickableText>{" "}
                {">"}
              </S.MutedText>

              <UserProfileTooltip
                user={{
                  name: user.name,
                  surname: user.surname,
                  avatar: user.avatar as unknown as ImageModel,
                  uuid: user.userUuid
                }}
                openInNewTab
              >
                <S.ClickableText>
                  <Icon icon="user" width="16px" height="16px" initialViewbox />
                  <span>
                    {user.name} {user.surname}
                  </span>
                </S.ClickableText>
              </UserProfileTooltip>
            </>
          ) : (
            <>
              <S.MutedText>
                {translate("activity")} {">"}{" "}
              </S.MutedText>
              {translate("record_scorm_tab_header_title")}
            </>
          )}
        </S.Text>
      </S.BreadCrumbWrapper>
    </S.HeaderWrapper>
  );
};

export default Header;
