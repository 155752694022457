import { PDFDownloadLink } from "@react-pdf/renderer";
import styled from "styled-components";

export const DownloadPDF = styled(PDFDownloadLink)<{ ispdfready?: string }>`
  color: ${({ theme }) => theme.colors.grey[50]};
  cursor: ${({ ispdfready }) => (ispdfready ? "pointer" : "not-allowed")};

  &:hover {
    text-decoration: none;
  }
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
`;
