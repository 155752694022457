import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import {
  changeSearchParams,
  createSearchString
} from "src/js/modules/searchFunction";
import SearchFetch from "src/js/pages/search/SearchFetch";
import SearchFilter from "src/js/pages/search/SearchFilter";
import { SearchFiltersObject } from "src/js/types";
import { SearchModalMobile } from "./SearchModalMobile";
import { useSearchParameters } from "./hooks";
import { getSearchFiltersObject } from "./util";

const Search = () => {
  const { UIStore } = useStores();
  const [filters, setFilters] = useState<SearchFiltersObject>(null);
  const { searchParams } = useSearchParameters();

  useEffect(() => {
    setFilters(getSearchFiltersObject(searchParams));
  }, []);

  const { keyword } = searchParams;

  const updateFilters = (newFilters: SearchFiltersObject) => {
    const newSearchObj = {
      keyword,
      ...newFilters
    };

    changeSearchParams(createSearchString(newSearchObj));
    setFilters(getSearchFiltersObject(newSearchObj));
  };

  if (!filters) return null;

  return (
    <>
      <SearchModalMobile />
      {UIStore.isLayoutModeMobile ? null : (
        <MainContainer paddingTop="86px">
          <SearchFilter filters={filters} onChange={updateFilters} />

          <SearchFetch keyword={keyword} filters={filters} />
        </MainContainer>
      )}
    </>
  );
};

export default observer(Search);
