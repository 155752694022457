import styled from "styled-components";

export const AcknowledgmentAlertContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.aknowledgmentalert.background};
  color: ${({ theme }) => theme.aknowledgmentalert.text};
  width: 700px;

  @media screen and (max-width: 767px) {
    max-width: 335px;
    width: 100%;
  }
`;

export const AcknowledgmentAlertInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
`;

export const AcknowledgmentAlertIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const AcknowledgmentAlertMessage = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 767px) {
    padding-right: 0px;
    padding-left: 12px;
  }
`;

export const AcknowledgmentAlertAction = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-flow: row-reverse;
    padding-left: 12px;
    margin-top: 16px;
  }
`;

export const AcknowledgmentAlertAccept = styled.div`
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & > span {
    white-space: nowrap;
  }
`;
