import { ActionItemsDropdownThemeOptions, Palette } from "@ds_themes/types";

export const createActionItemsDropdownTheme = ({
  grey,
  violet
}: Palette): { actionitemsdropdown: ActionItemsDropdownThemeOptions } => {
  return {
    actionitemsdropdown: {
      background: grey[1000],
      text: grey[50],
      shadow: violet[500]
    }
  };
};
