import React from "react";
import { Box, Button } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import * as S from "../styles";
import BadgeRelatedGroup from "./BadgeRelatedGroup";
import { AssociateGroupStep, AssociateGroupStepType } from "../types";

const ReviewSelectedGroups = ({
  handleClose,
  setStep
}: {
  handleClose: (shouldRefetchBadges?: boolean) => void;
  setStep: (step: AssociateGroupStepType) => void;
}) => {
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: {
      badgeToEdit,
      updatedBadgeAssociations,
      isAbleToEmitBadge,
      setEmitStudentsDrawerOpen,
      setGroupsDrawerOpen,
      reset
    },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { associatedGroupsCount } = badgeToEdit || {};
  const hasAlreadyAssociatedGroups = !!associatedGroupsCount;

  return (
    <S.DrawerBodyContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginBottom={32}
      >
        <S.GroupAssociatedImage src={badgeToEdit.image} alt="group-logo" />
        <S.SuccessMessage level="5">
          {translate({
            text: hasAlreadyAssociatedGroups
              ? "space_badge_associate_update_groups_success_message"
              : "space_badge_associate_group_success_message",
            stringVariables: {
              badgeName: badgeToEdit.name,
              numberOfGroups: updatedBadgeAssociations.length
            }
          })}
        </S.SuccessMessage>
      </Box>
      <Box id="scroll-area-container" minHeight={400} marginBottom="auto">
        <S.ScrollableArea id="badge-related-groups-scrollable-list">
          <S.BadgeRelatedGroupsContainer>
            {updatedBadgeAssociations.map(group => (
              <BadgeRelatedGroup
                key={group.id}
                badgeRelatedGroup={group}
                badgeImage={badgeToEdit.image}
                isGroupRemoved={group.isRemoved}
                isAssociated
              />
            ))}
          </S.BadgeRelatedGroupsContainer>
        </S.ScrollableArea>
      </Box>
      <S.DrawerFooter>
        <S.ReviewButtonsContainer>
          <Button
            theme={whiteTheme}
            variant="secondary"
            disabled={!isAbleToEmitBadge}
            onClick={() => {
              setGroupsDrawerOpen(false);
              setEmitStudentsDrawerOpen(true);
              setStep(AssociateGroupStep.SELECT_GROUPS);
              reset();
            }}
          >
            {translate("badge_create_optional_fields_secondary_button")}
          </Button>
          {isLayoutModeMobile ? null : (
            <Button
              theme={whiteTheme}
              variant="primary"
              onClick={() => handleClose(true)}
            >
              {translate("close")}
            </Button>
          )}
        </S.ReviewButtonsContainer>
      </S.DrawerFooter>
    </S.DrawerBodyContainer>
  );
};

export default observer(ReviewSelectedGroups);
