import React, { useState, useMemo } from "react";
import { createLocalData, readLocalData } from "src/js/modules/storageFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  Avatar,
  ActionButton,
  SearchBarOption,
  Text
} from "@arcadia/design-system";
import { useTheme } from "styled-components";

import * as S from "./SearchBoxUserSuggestions.styles";

const updateRecentUserList = saveLocalObj =>
  createLocalData("analytics-last-user-search", JSON.stringify(saveLocalObj));

const emphasizeSubstring = (text, filterString) => {
  if (!filterString) return text;

  const reg = new RegExp(filterString, "gi");

  const finalText = text.replace(
    reg,
    str =>
      `<span style="font-weight: 500 !important; color: #8a99b8">${str}</span>`
  );

  return `<span style="font-weight: 600 ">${finalText}</span>`;
};

const SearchBoxUserSuggestions = ({
  filterString = "",
  usersList = [],
  showRecentUsersList = false,
  onSelectUser,
  recentUserList = JSON.parse(readLocalData("analytics-last-user-search"))
}) => {
  const [lastSearch, setLastSearch] = useState(recentUserList || []);
  const { whiteTheme, greyTheme } = useTheme();

  const userCard = (user, removable = false) => {
    return (
      <SearchBarOption
        key={user.id}
        theme={greyTheme}
        onClick={() => {
          const saveLocalObj = lastSearch.filter(
            lastSearchUser => lastSearchUser.id !== user.id
          );

          saveLocalObj.unshift({
            id: user.id,
            avatar: user.avatar,
            name: user.name,
            surname: user.surname,
            uuid: user.uuid
          });

          onSelectUser(saveLocalObj[0]);
          setLastSearch(saveLocalObj);
          updateRecentUserList(saveLocalObj);
        }}
      >
        <S.UserCardContainer key={user.id}>
          <S.UserCard>
            <Avatar
              theme={whiteTheme}
              url={user.avatar?.medium}
              alt={user.name}
              rounded
            />
            {removable ? (
              <S.Name>
                {user.name} {user.surname}
              </S.Name>
            ) : (
              <S.Name
                dangerouslySetInnerHTML={{
                  __html: emphasizeSubstring(
                    `${user.name} ${user.surname}`,
                    filterString
                  )
                }}
              />
            )}
          </S.UserCard>
          {removable ? (
            <S.Cross>
              <ActionButton
                icon="close"
                theme={whiteTheme}
                variant="tertiary"
                onClick={e => {
                  e.stopPropagation();
                  const newLastSearch = lastSearch.filter(
                    lastSearchUser => lastSearchUser.id !== user.id
                  );

                  setLastSearch(newLastSearch);
                  updateRecentUserList(newLastSearch);
                }}
              />
            </S.Cross>
          ) : null}
        </S.UserCardContainer>
      </SearchBarOption>
    );
  };

  const content = usersList
    .filter(({ user }) => {
      const fullName = `${user.name} ${user.surname}`;
      return (
        user.name.toLowerCase().includes(filterString.toLowerCase()) ||
        user.surname.toLowerCase().includes(filterString.toLowerCase()) ||
        fullName
          .toLocaleLowerCase()
          .includes(filterString.toLocaleLowerCase()) ||
        user.email.toLowerCase().includes(filterString.toLocaleLowerCase())
      );
    })
    .map(({ user }) => userCard(user));

  const lastSearchList = useMemo(
    () => (lastSearch ? lastSearch.map(user => userCard(user, true)) : null),
    [lastSearch]
  );

  return showRecentUsersList
    ? lastSearchList.length > 0 && (
        <S.SuggestionContainer>
          <S.RecentSearchTitle>
            <Text type="formTitle">
              <Translate text="recent_search_title" />
            </Text>
          </S.RecentSearchTitle>
          {lastSearchList}
        </S.SuggestionContainer>
      )
    : content;
};

export default SearchBoxUserSuggestions;
