import React from "react";
import {
  __OAUTH_ORIGIN_URL__,
  __OAUTH_COSTUMER__
} from "src/js/settings/settingsLocalStorage";
import { readLocalData } from "src/js/modules/storageFunction";
import { navigateTo } from "Legacy/modules/history";
import {
  isLoginUrl,
  isProfileUrl,
  isRegisterUrl
} from "src/js/modules/urlFunction";
import { __OAUTH_CLIENT__ } from "src/js/settings/settingsGeneral";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";

function getRedirectUrl(token) {
  const url = readLocalData(__OAUTH_ORIGIN_URL__);
  const client_oauth = readLocalData(__OAUTH_COSTUMER__);
  const client_id = __OAUTH_CLIENT__[client_oauth];
  if (isLoginUrl(url)) {
    const baseInitialUrl = url.substr(0, url.indexOf("?")) || url;
    const searchParams =
      url.indexOf("?") === -1 ? "" : url.substr(url.indexOf("?"), url.length);
    return `/${baseInitialUrl}/${token}/${client_id}${searchParams}`;
  }
  if (isProfileUrl(url)) {
    return `/${url}/${token}/${client_id}`;
  }
  if (isRegisterUrl(url)) {
    return `/${url}?auth_code=${token}&client_id=${client_id}`;
  }
  return "/login";
}

const ExternalAuth = props => {
  const { match } = props; // todo controllo url e variabili
  const token = match.params ? match.params.auth_code : null;
  const { greyTheme } = useTheme();
  navigateTo(getRedirectUrl(token));
  return <DoodleLoader theme={greyTheme} />;
};

export default ExternalAuth;
