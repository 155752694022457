import { Box, Icon } from "@arcadia/design-system";
import React, { useRef } from "react";
import { useTranslation } from "src/js/translation";

import * as S from "../AddTextResourceWithAI.styles";

const TextResourceTitle = ({
  defaultTitle,
  onSubmit
}: {
  defaultTitle: string;
  onSubmit: (newTitle: string) => void;
}) => {
  const [title, setTitle] = React.useState(defaultTitle);
  const [editMode, setEditMode] = React.useState(false);
  const { translate } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  React.useEffect(() => {
    setTitle(defaultTitle);
  }, [editMode]);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = "0px";
      const newWidth = inputRef.current.scrollWidth;
      inputRef.current.style.width = `${newWidth}px`;
    }
  }, [title, editMode]);

  return (
    <Box display="flex" gap={12} alignItems="center" justifyContent="center">
      {editMode ? (
        <>
          <S.NakedInput
            ref={inputRef}
            type="text"
            autoFocus
            value={title}
            inputLength={title.length}
            onChange={e => setTitle(e.target.value)}
          />
          <S.ChangeTitleButton
            onClick={() => {
              const newTitle = title.trim() || translate("text_new");
              onSubmit(newTitle);
              setTitle("");
              setEditMode(false);
            }}
          >
            <Icon icon="check" initialViewbox />
          </S.ChangeTitleButton>
        </>
      ) : (
        <>
          <S.StyledHeading level="5">{defaultTitle}</S.StyledHeading>
          <S.ChangeTitleButton onClick={() => setEditMode(true)}>
            <Icon icon="pencil" initialViewbox />
          </S.ChangeTitleButton>
        </>
      )}
    </Box>
  );
};

export default TextResourceTitle;
