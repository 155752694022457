import styled, { css } from "styled-components";

export const Column = styled.div`
  color: ${({ theme }) => theme.colors.grey[50]};

  width: 20%;
  padding-right: 20px;

  ${({ large }) =>
    large &&
    css`
      width: 30%;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 15%;
    `}

  ${({ extrasmall }) =>
    extrasmall &&
    css`
      width: 10%;
    `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TopMobile = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[800]};
  padding: 16px 20px 10px;
  display: flex;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const TopMobileTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow-x: hidden;
`;

export const TopMobileActionContainer = styled.div`
  margin-left: 5px;
`;

export const UserFullName = styled.div`
  margin-left: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 992px) {
    margin-left: 0;
  }
`;

export const UserSurname = styled.span`
  font-weight: 600;
`;

export const Mail = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-top: 8px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 992px) {
    margin-top: 0px;
  }
`;

export const BottomMobile = styled.div`
  width: 100%;
  padding: 20px 20px 25px;
  font-size: 14px;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const ActionButtonWrapper = styled.div`
  svg {
    zoom: 120%;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 6px;
`;

export const BottomMobileTitle = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 8px;

  ${({ smallMargin }) =>
    smallMargin &&
    css`
      margin-bottom: 6px;
    `}
`;

export const TabletAndUp = styled.span`
  width: 100%;
  display: none;
  align-items: center;

  @media screen and (min-width: 992px) {
    display: flex;
  }
`;

export const PermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Permission = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export const CheckIconContainer = styled.span`
  color: ${({ theme }) => theme.colors.success[500]};
  margin-right: 8px;
  display: flex;
`;

export const CrossIconContainer = styled.span`
  color: ${({ theme }) => theme.colors.error[500]};
  margin-right: 8px;
  display: flex;
`;

export const PermissionName = styled.span`
  font-size: 16px;

  @media (max-width: 992px) {
    font-size: 14px;
  }
`;
