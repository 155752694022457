import React from "react";
import {
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from "recharts";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";

import CustomTooltip from "./CustomTooltip";
import * as S from "./AnalyticsTrendGraph.styles";

/**
 *
 * @param {{xValue: any, yValue:any} []} data should be an object that represent the topic
 * @param {string} topic the topic you want to represent
 */

const AnalyticsTrendGraph = ({
  data = [],
  topic,
  xLabel = null,
  yLabel = null
}) => {
  const { primaryColor } = useTheme();
  if (!topic || data.length === 0) return null;

  const xLbl = xLabel || Object.keys(data[0])[0];
  const yLbl = yLabel || Object.keys(data[0])[1];

  return (
    <>
      <S.LegendaContainer>
        <S.LegendaCircle />
        <Translate text={topic} />
      </S.LegendaContainer>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data} margin={{ top: 50, right: 10, bottom: 10 }}>
          <Area type="linear" dataKey={yLbl} fill={primaryColor[500]} />
          <CartesianGrid stroke="#c4c4c4" strokeDasharray="1 1" />
          <Line
            type="linear"
            dataKey={yLbl}
            strokeWidth={2}
            stroke={primaryColor[500]}
            dot={{ stroke: primaryColor[500], strokeWidth: 2, r: 6 }}
          />
          <XAxis
            dataKey={xLbl}
            height={120}
            angle={-60}
            tickMargin={10}
            minTickGap={15}
            textAnchor="end"
            axisLine={false}
            tick={{ fontSize: 11 }}
            tickLine={false}
            hide={data.length > 40}
            interval={2}
          />
          <YAxis
            tickCount={10}
            tickMargin={10}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            animationDuration={200}
            content={
              <CustomTooltip topic={topic} xLabel={xLbl} yLabel={yLbl} />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default AnalyticsTrendGraph;
