import React from "react";
import { ActionButton, Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import * as S from "./AddOrDragContent.styles";

const AddOrDragContent = ({ openBoard }: { openBoard: boolean }) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: { userIsTeacher }
  } = useStores();

  return (
    <S.Content>
      {isLayoutModeMobile ? (
        <Icon icon="plus" width={13} height={13} />
      ) : (
        <ActionButton theme={whiteTheme} variant="primary" icon="plus" />
      )}
      <Text type="cta">
        {userIsTeacher || openBoard
          ? translate("module_resource_add_resource_cta_label")
          : translate("module_resource_suggest_resource_cta_label")}
      </Text>
    </S.Content>
  );
};

export default AddOrDragContent;
