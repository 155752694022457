import getUserClient from "src/js/modules/connection";
import {
  api_tutor_check_token,
  api_tutor_email_request,
  api_tutor_registration,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";

export function checkTutorApprobationToken(token) {
  return getUserClient()
    .get(getVersionedApiUrl(api_tutor_check_token({ token })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_tutor_check_approbation_code")
      );
      throw Err;
    });
}

export function sendTutorApprobation(token, data) {
  return getUserClient()
    .post(getVersionedApiUrl(api_tutor_registration({ token })), data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_tutor_send_approbation")
      );
      throw Err;
    });
}

export function sendTutorRequest(data) {
  return getUserClient()
    .post(getVersionedApiUrl(api_tutor_email_request()), data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_tutor_send_request")
      );
      throw Err;
    });
}
