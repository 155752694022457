import colors from "@ds_themes/colors";

export const primary = {
  background: colors.grey[50],
  text: colors.grey[1000],
  type: "cta",
  border: `1px solid ${colors.grey[1000]}`,
  hover: {
    background: colors.grey[1000],
    text: colors.grey[50]
  },
  focus: {
    border: `${colors.grey[700]} solid 3px`
  },
  disabled: {
    background: colors.grey[50],
    text: colors.grey[500],
    border: `1px solid ${colors.grey[500]}`
  },
  badge: {
    background: colors.violet[501],
    text: colors.grey[1000],
    border: `1px solid ${colors.grey[1000]}`
  }
};
