import React, { FC } from "react";
import { Icon } from "@ds_universal/Icon";

import { TabButtonsProps } from "./TabButtons.types";
import * as S from "./TabButtons.styles";

export const TabButtons: FC<TabButtonsProps> = ({
  options,
  selectedId,
  onSelected
}) => {
  return (
    <S.TabButtonContainer>
      {options.map(option => (
        <S.TabButton
          key={option.id}
          active={option.id === selectedId}
          onClick={() => onSelected(option.id)}
        >
          {option?.icon && <Icon icon={option.icon} width={20} height={20} />}
          <S.TabButtonText>{option.label}</S.TabButtonText>
        </S.TabButton>
      ))}
    </S.TabButtonContainer>
  );
};

export default TabButtons;
