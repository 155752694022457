import { oneOfType, bool, func, oneOf, string, number } from "prop-types";
import React from "react";
import { ActionButton, colors } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { DRAWER_TYPE } from "./Drawer.const";

import * as S from "./Drawer.styles";

/**
 * @name Drawer
 *
 * @description Layout of a Generic drawer that can appear from any side (default is right)
 */
const Drawer = ({
  children,
  open,
  size,
  position,
  onDismiss,
  backgroundColor = colors.grey[1000],
  showCloseButton,
  isInsideMainLayout = false,
  className = "",
  disableOverlay = false
}) => {
  const { greyTheme } = useTheme();
  return (
    <S.DrawerWrapper open={open} size={size} position={position}>
      {!disableOverlay && (
        <S.DrawerOverlay
          open={open}
          onClick={onDismiss}
          isInsideMainLayout={isInsideMainLayout}
        />
      )}
      <S.DrawerContent
        className={className}
        open={open}
        size={size}
        position={position}
        backgroundColor={backgroundColor}
        isInsideMainLayout={isInsideMainLayout}
      >
        {showCloseButton && (
          <S.DrawerCloseTrigger>
            <ActionButton
              theme={greyTheme}
              variant="tertiary"
              onClick={onDismiss}
              icon="close"
            />
          </S.DrawerCloseTrigger>
        )}
        {children}
      </S.DrawerContent>
    </S.DrawerWrapper>
  );
};

Drawer.propTypes = {
  showCloseButton: bool,
  size: oneOfType([string, number]),
  position: oneOf(Object.values(DRAWER_TYPE)),
  onDismiss: func.isRequired
};

Drawer.defaultProps = {
  showCloseButton: true,
  position: "right",
  size: "675px"
};

export default Drawer;
