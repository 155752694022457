import React, { useEffect, useState } from "react";
import {
  Box,
  ToolbarSpaceItem,
  Text,
  Icon,
  Button,
  Avatar
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { GroupInSpaceModel } from "src/js/types";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { InternalDispatcher } from "src/legacy/modules/dispatcher";
import { groupGetCover } from "src/js/GroupFunction";
import { GroupInvitation } from "./types";
import { useGroupInvitation } from "./useGroupInvitation";
import * as S from "./GroupsInvitations.styles";

const GroupsInvitations = ({
  appendToGroupList,
  whiteBackground
}: {
  appendToGroupList?: (group: GroupInSpaceModel) => void;
  whiteBackground?: boolean;
}) => {
  const [groupInvites, setGroupInvites] = useState<GroupInvitation[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { showConfirmation, rejectInvite, getInvites } = useGroupInvitation(
    setGroupInvites,
    groupInvites,
    appendToGroupList
  );

  useEffect(() => {
    InternalDispatcher.on("new_group_invite", getInvites);
    getInvites().then(response => {
      setGroupInvites(response);
    });

    return () => InternalDispatcher.off("new_group_invite", getInvites);
  }, []);

  const handleExpand = () => {
    setIsExpanded(oldVal => !oldVal);
  };

  return groupInvites.length ? (
    <>
      <Box position="relative">
        <S.GroupsCollapsableInvitations isExpanded={isExpanded}>
          {groupInvites.map(invitation => {
            return (
              <S.GroupInvitation
                key={invitation.inviteCode}
                whiteBackground={whiteBackground}
              >
                <Box
                  position="relative"
                  height={isLayoutModeMobile ? "40px" : "64px"}
                >
                  <Avatar
                    theme={whiteTheme}
                    url={groupGetCover({
                      cover: invitation.groupData.group.cover?.small
                    })}
                    alt="Group-Cover"
                    size={isLayoutModeMobile ? "m" : "l"}
                    rounded={false}
                  />
                  <S.AvatarWrapper>
                    <ToolbarSpaceItem
                      id="space-badge"
                      theme={whiteTheme}
                      badge={invitation.groupData.space.badge?.small}
                      backgroundColorCode={
                        invitation.groupData.space.defaultBadgeColor
                      }
                      spaceName={invitation.groupData.space.name}
                      isSelected={false}
                      size="auto"
                    />
                  </S.AvatarWrapper>
                </Box>
                <S.MidSectionWrapper alignCenter={isLayoutModeMobile}>
                  <S.GreyText type="formSmallCaption">
                    {invitation.groupData.space.name}
                  </S.GreyText>
                  <Text type="formField">
                    {invitation.groupData.group.name}
                  </Text>
                  {!isLayoutModeMobile && (
                    <Box
                      marginTop="4px"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      <S.GreyText type="formSubtitle">
                        {translate("group_invitation_description_text")}
                      </S.GreyText>
                    </Box>
                  )}
                </S.MidSectionWrapper>
                <Box display="flex" alignItems="center" marginLeft="auto">
                  <Box
                    display="flex"
                    gap={isLayoutModeMobile ? 20 : 8}
                    alignItems="center"
                  >
                    {isLayoutModeMobile ? (
                      <S.MobileIcon
                        isReject
                        onClick={() => rejectInvite({ invitation })}
                      >
                        <Icon icon="close" width={14} height={14} />
                      </S.MobileIcon>
                    ) : (
                      <Button
                        variant="secondary"
                        theme={whiteTheme}
                        onClick={() => rejectInvite({ invitation })}
                      >
                        {translate("group_invitation_decline_buttton")}
                      </Button>
                    )}
                    {isLayoutModeMobile ? (
                      <S.MobileIcon
                        isReject={false}
                        onClick={() =>
                          showConfirmation({
                            invitation
                          })
                        }
                      >
                        <Icon icon="check2" width={16} height={16} />
                      </S.MobileIcon>
                    ) : (
                      <Button
                        variant="primary"
                        theme={whiteTheme}
                        onClick={() =>
                          showConfirmation({
                            invitation
                          })
                        }
                      >
                        {translate("group_invitation_accept_buttton")}
                      </Button>
                    )}
                  </Box>
                </Box>
              </S.GroupInvitation>
            );
          })}
        </S.GroupsCollapsableInvitations>

        <S.BlurRow shouldShow={!isExpanded && groupInvites.length > 2} />
      </Box>

      {groupInvites.length > 2 && (
        <Box justifyContent="center" display="flex" marginBottom={16}>
          <Box onClick={handleExpand}>
            <S.ViewMore type="textLink">
              {translate(
                isExpanded
                  ? "groups_invitations_view_less_invites"
                  : "groups_invitations_view_more_invites"
              )}
            </S.ViewMore>
          </Box>
        </Box>
      )}
    </>
  ) : null;
};

export default GroupsInvitations;
