import { Model } from "backbone";
import React from "react";
import ReactDom from "react-dom";
import ThemeProvider from "src/js/theme/ThemeProvider";
import BaseModal from "../components/baseModal";

class ModalMessage extends Model {
  show(addClass) {
    $("body").addClass("modal-msg-open modal-open");

    ReactDom.unmountComponentAtNode($("#modal-msg").get(0));
    ReactDom.render(
      <ThemeProvider>
        <BaseModal
          modalMessage={this}
          hideFunc={this.hide}
          addClass={addClass}
        />
      </ThemeProvider>,
      $("#modal-msg").get(0),
      () => {
        if ($(".react-modal-msg").is(":hidden")) {
          $(".react-modal-msg").modal({
            backdrop: "static"
          });
          $(".react-modal-msg").modal("show");
          $(".react-modal-msg").on("hide.bs.modal", () => {
            $("body").removeClass("modal-msg-open modal-open"); // forzo rimozione class
            $(".react-modal-msg").removeClass("in block");
          });
        } else {
          $(".react-modal-msg").scrollTop(0);
        }
      }
    );
  }
  showBlock(addClass) {
    $("body").addClass("modal-msg-open"); // forzo aggiunta class
    ReactDom.unmountComponentAtNode($("#modal-msg").get(0));
    ReactDom.render(
      <ThemeProvider>
        <BaseModal
          modalMessage={this}
          hideFunc={this.hide}
          addClass={addClass}
        />
      </ThemeProvider>,
      $("#modal-msg").get(0)
    );

    if ($(".react-modal-msg").is(":hidden")) {
      $(".react-modal-msg").modal({
        backdrop: "static"
      });
      $(".react-modal-msg").modal({ backdrop: "static", keyboard: false });
      $(".react-modal-msg").on("hide.bs.modal", () => {
        $("body").removeClass("modal-msg-open modal-open"); // forzo rimozione class
      });
    } else {
      $(".react-modal-msg").scrollTop(0);
    }
  }
  hide() {
    $(".react-modal-msg").modal("hide");
    $("body").removeClass("modal-msg-open modal-open"); // forzo rimozione class
  }
  reset() {
    this.set({
      header: { content: "", type: "HTML" },
      body: { content: "", type: "HTML" },
      footer: { content: "", type: "HTML" },
      addCloseButton: false,
      addCloseIcon: false,
      escapeDisabled: false
    });
  }
}

export const modalMessage = new ModalMessage();
// modalMessage.reset();
