import React from "react";
import { useDroppable } from "@dnd-kit/core";

import * as S from "./Droppable.styles";

interface DroppableProps {
  id: string;
}

const Droppable: React.FC<DroppableProps> = ({ id, children }) => {
  const { setNodeRef, over, active } = useDroppable({ id });

  const isBeingDraggedOver = React.useMemo(
    () => over?.id === id && active?.id !== id,
    [over, active, id]
  );

  return (
    <S.Wrapper isBeingDraggedOver={isBeingDraggedOver} ref={setNodeRef}>
      {children}
    </S.Wrapper>
  );
};

export default Droppable;
