import { DoodleLoader } from "@arcadia/design-system";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { AgendaDayContainer } from "src/js/pages/agenda/AgendaDayContainer";
import { AgendaPlaceholder } from "src/js/pages/agenda/AgendaPlaceholder";
import { AGENDA_MONTH_MODE, CALENDAR_MODE } from "src/js/pages/calendar";
import { CalendarDesktopControls } from "src/js/pages/calendar/CalendarDesktopControls";
import { CalendarHeader } from "src/js/pages/calendar/CalendarHeader";
import { useTheme } from "styled-components";
import { format, isSameDay } from "date-fns";
import * as S from "./AgendaBody.styles";

const AgendaBody = ({
  agendaDays = [],
  events = [],
  agendaDownscrollLoading = () => {},
  agendaUpscrollLoading = () => {},
  agendaHasNextPage,
  agendaHasPreviousPage,
  numberOfRowsAdded = 0,
  resetNumberOfRowsAdded = () => {},
  urlDateString,
  lastViewDate,
  firstViewDate,
  selectedEvent = {},
  newEvent = () => {},
  userIsTeacher = false,
  getEventDetail = () => {},
  openRecipientsModal = () => {},
  openResource = () => {},
  deleteCalendarEvent = () => {},
  editCalendarEvent = () => {},
  copyCalendarEvent = () => {},
  setEventTypeFilter = () => {},
  selectedEventAgendaKey,
  setSelectedEventAgendaKey = () => {},
  agendaUrl,
  calendarUrl,
  groupId
}) => {
  const [currentDate, setCurrentDate] = useState(
    moment(urlDateString, "YYYY-MM-DD")
  );
  const [showLoader, setShowLoader] = useState(true);
  const listRef = useRef({});
  const rowHeights = useRef({});
  const { whiteTheme } = useTheme();

  useEffect(() => {
    setShowLoader(true);
    agendaDownscrollLoading({
      viewStart: currentDate,
      overrideEvents: true
    }).then(data => {
      if (data && data.length) {
        agendaUpscrollLoading({ viewEnd: currentDate }).then(() =>
          setShowLoader(false)
        );
      } else {
        setShowLoader(false);
      }
    });
  }, [currentDate]);

  useEffect(() => {
    const parsedDate = moment(urlDateString, "YYYY-MM-DD");
    if (parsedDate.isValid() && !parsedDate.isSame(currentDate, "day")) {
      setCurrentDate(parsedDate.clone());
    }
  }, [urlDateString]);

  useEffect(() => {
    if (numberOfRowsAdded > 0) {
      if (listRef && listRef.current) {
        const scrollToCorrectRow = () => {
          listRef.current.scrollToItem(
            currentDate.isSame(moment(), "day")
              ? numberOfRowsAdded + 1
              : numberOfRowsAdded,
            "start"
          );
        };
        scrollToCorrectRow();

        // This is needed to fix a bug where the list doesn't scroll to the correct row on first load
        setTimeout(() => {
          scrollToCorrectRow();
        });
      }
      resetNumberOfRowsAdded();
    }
  }, [numberOfRowsAdded]);

  const openPopupCallback = useCallback(({ id, eventDetail, agendaDate }) => {
    setSelectedEventAgendaKey(agendaDate);
    if (isEmptyObject(eventDetail)) {
      getEventDetail(id);
    }
  }, []);

  const navigatePage = useCallback(
    prev => {
      const prevNextPage = prev
        ? currentDate.clone().startOf("month").subtract(1, "months")
        : currentDate.clone().startOf("month").add(1, "months");
      setCurrentDate(prevNextPage);
      listRef.current.scrollToItem(0, "end");
    },
    [currentDate, setCurrentDate]
  );

  const setHeaderDate = value => {
    setCurrentDate(moment(value).startOf("day"));
    listRef.current.scrollToItem(0, "end");
  };

  const scrollFunction = ({
    scrollDirection,
    scrollOffset,
    scrollUpdateWasRequested
  }) => {
    if (
      scrollDirection === "backward" &&
      scrollOffset === 0 &&
      firstViewDate &&
      agendaHasPreviousPage &&
      !scrollUpdateWasRequested
    ) {
      agendaUpscrollLoading({ viewEnd: firstViewDate });
    }
  };

  const itemCount = agendaHasNextPage
    ? agendaDays.length + 1
    : agendaDays.length;
  const isItemLoaded = index =>
    index < agendaDays.length && agendaDays[index] !== null;

  const loadMoreItems = () => {
    if (lastViewDate) {
      return agendaDownscrollLoading({ viewStart: lastViewDate });
    }
    return () => {};
  };

  function getRowHeight(index) {
    return rowHeights.current[index] || 0;
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  const renderAgendaDays = ({ index, style }) => {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    const dayEvents = events.filter(event =>
      agendaDays[index][1].includes(event.id)
    );
    const eventDate = new Date(agendaDays[index][0]);
    const showTodayPlaceholder = isSameDay(eventDate, new Date());
    const showMonthPlaceholder =
      agendaDays[index][1].includes(AGENDA_MONTH_MODE);

    return (
      <div key={agendaDays[index][0]} style={style}>
        <div ref={rowRef}>
          {showMonthPlaceholder && (
            <S.AgendaPlaceholderWrapper>
              <AgendaPlaceholder
                key={`placeholder-${format(eventDate, "yyyy-MM-dd")}`}
                date={format(eventDate, "yyyy-MM-dd")}
              />
            </S.AgendaPlaceholderWrapper>
          )}
          {showTodayPlaceholder && <AgendaPlaceholder isToday />}
          <AgendaDayContainer
            date={new Date(agendaDays[index][0])}
            events={dayEvents}
            selectedEventId={selectedEvent?.id}
            selectedEventAgendaKey={selectedEventAgendaKey}
            setSelectedEventAgendaKey={setSelectedEventAgendaKey}
            groupId={groupId}
            userIsTeacher={userIsTeacher}
            openPopupCallback={openPopupCallback}
            openRecipientsModal={openRecipientsModal}
            openResource={openResource}
            deleteCalendarEvent={deleteCalendarEvent}
            editCalendarEvent={editCalendarEvent}
            copyCalendarEvent={copyCalendarEvent}
          />
        </div>
      </div>
    );
  };

  return (
    <S.AgendaBodyContainer>
      <CalendarDesktopControls
        newEvent={type => newEvent({ defaultDate: new Date(), type })}
        userIsTeacher={userIsTeacher}
        agendaUrl={agendaUrl}
        calendarUrl={calendarUrl}
        mode={CALENDAR_MODE.AGENDA}
      />
      <CalendarHeader
        currentDate={currentDate}
        navigatePage={navigatePage}
        setCurrentDate={setHeaderDate}
        setEventTypeFilter={setEventTypeFilter}
      />
      {agendaDays && agendaDays.length > 0 && (
        <S.AgendaListContainer>
          {showLoader && <DoodleLoader theme={whiteTheme} />}
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={10}
                treshold={10}
              >
                {({ onItemsRendered }) => (
                  <List
                    className="ws-agenda-list"
                    height={height}
                    itemCount={agendaDays.length}
                    itemSize={getRowHeight}
                    ref={listRef}
                    width={width}
                    onItemsRendered={onItemsRendered}
                    onScroll={scrollFunction}
                    estimatedItemSize={136}
                  >
                    {renderAgendaDays}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </S.AgendaListContainer>
      )}
    </S.AgendaBodyContainer>
  );
};

export default AgendaBody;
