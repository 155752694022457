import React from "react";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { RealtimeProvider as MQTTRealtimeProvider } from "./aws-mqtt/RealTimeProvider";
// eslint-disable-next-line import/no-named-default
// import { default as PusherRealTimeProvider } from "./pusher/WSPusherProvider";

const RealTimeProvider = observer(({ children }) => {
  const { AuthStore } = useStores();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!AuthStore.userIsLogged) return <>{children}</>;

  return <MQTTRealtimeProvider>{children}</MQTTRealtimeProvider>;
});

export { RealTimeProvider };
export {
  useGroupChannel,
  useUserChannel,
  useScormChannel
} from "./aws-mqtt/hooks";
