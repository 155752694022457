import { CheckIndicatorMap, Palette } from "@ds_themes/types";

export const createCheckIndicatorTheme = ({
  general,
  primary,
  grey
}: Palette): { checkindicator: CheckIndicatorMap } => {
  return {
    checkindicator: {
      background: general.background,
      border: `1px solid ${grey[800]}`,
      color: general.background,
      selected: {
        border: `1px solid ${primary.main}`,
        background: primary.main,
        color: primary.contrastText
      }
    }
  };
};
