import React from "react";
import WeSchoolMonogramSVG from "src/img/icons/svg/weschool_monogram.svg";
import { LogoProps } from "src/js/components/layout/WeschoolMonogram/types";
import * as S from "./WeschoolMonogram.styles";

const WeschoolMonogram = ({ onClick }: LogoProps) => {
  return (
    <S.MonogramContainer onClick={onClick}>
      <WeSchoolMonogramSVG alt="Weschool Icon" />
    </S.MonogramContainer>
  );
};

export default WeschoolMonogram;
