import { BottomModalSheet } from "@arcadia/design-system";
import React from "react";
import { createPortal } from "react-dom";
import { useModalProps } from "src/js/components/reactions/ReactionListModal/hooks";
import { useTranslation } from "src/js/translation";
import * as S from "./TemplateDescription.styles";

const TemplateDescriptionMobile = ({
  onClose,
  children
}: {
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const { translate } = useTranslation();

  const { domEl } = useModalProps({
    onClose: () => {},
    portalId: "template-description-portal"
  });

  return (
    <>
      <S.GlobalStyleTemplateDescription />
      {createPortal(
        <BottomModalSheet
          isOpen
          title={translate("template_weschool_details_title_mobile")}
          onClose={onClose}
          closeLabel={translate("back")}
          withDrag={false}
        >
          {children}
        </BottomModalSheet>,
        domEl
      )}
    </>
  );
};

export default TemplateDescriptionMobile;
