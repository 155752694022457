import styled, { css } from "styled-components";

export const Wrapper = styled.div<{
  isLayoutMobile: boolean;
  hasNoCustomizedLogin?: boolean;
  backgroundColor?: string;
}>`
  width: 100vw;
  min-height: 100vh;
  background-color: ${({ theme, isLayoutMobile, backgroundColor }) =>
    backgroundColor && !isLayoutMobile
      ? backgroundColor
      : theme.colors.grey[1000]};
  background-size: cover;
  position: relative;
  ${({ hasNoCustomizedLogin }) =>
    hasNoCustomizedLogin &&
    css`
      background-color: ${({ theme }) => theme.colors.lagoon[700]};
    `}
`;

export const BackgroundImage = styled.img`
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  left: 0;
  top: 0;
`;

export const TopMobileContainer = styled.div<{ hasCustomizedLogin: boolean }>`
  position: absolute;
  color: ${({ theme }) => theme.primaryColor[500]};
  top: 4px;
  width: 100%;
  display: flex;
  justify-content: ${({ hasCustomizedLogin }) =>
    hasCustomizedLogin ? "space-between" : "flex-end"};
  align-items: center;
`;

export const TopContainer = styled.div<{ hasCustomizedLogin: boolean }>`
  flex: 0.25;
  width: 100%;
  min-height: 92px;
  display: flex;
  justify-content: ${({ hasCustomizedLogin }) =>
    hasCustomizedLogin ? "space-between" : "flex-end"};
  align-items: flex-start;
  padding-top: 25px;
  z-index: 1;
`;

export const MiddleContainer = styled.div`
  flex: 0.5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  gap: 48px 148px;
`;

export const BottomContainer = styled.div`
  flex: 0.25;
  width: 100%;
  min-height: 92px;
  padding-bottom: 25px;
  z-index: 1;
`;

export const MonogramContainer = styled.div<{
  isLayoutMobile: boolean;
}>`
  color: ${({ theme, isLayoutMobile }) =>
    isLayoutMobile ? theme.primaryColor[500] : theme.colors.grey[1000]};
  height: 26px;
  padding-left: 12px;
`;
