import styled from "styled-components";

export const CheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  margin: 0;
  cursor: pointer;
  font-size: 22px;
  line-height: 16px;
  height: 16px;
  width: 16px;
  clear: both;
`;

export const CheckboxCustom = styled.span`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  transition: all 0.3s ease-out;
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  border-radius: 6px;

  &:after {
    position: absolute;
    content: "";
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid ${({ theme }) => theme.colors.grey[50]};
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  height: 16px;
  width: 16px;

  &:checked ~ ${CheckboxCustom}:after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid ${({ theme }) => theme.colors.grey[50]};
    border-width: 0 3px 3px 0;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const CheckboxText = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 30px);
  margin-left: 10px;

  & a {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;
