import {
  ContextFilterKey,
  SearchFiltersObject,
  SearchUrlParameters
} from "src/js/types";

export const getNumberOfAppliedFilters = (
  filtersObject: SearchFiltersObject
) => {
  return Object.keys(filtersObject).reduce(
    (total, key: keyof SearchFiltersObject) =>
      filtersObject[key] ? total + 1 : total,
    0
  );
};

export const getSearchFiltersObject = (
  searchParams: SearchUrlParameters
): SearchFiltersObject => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { keyword, ...filters } = searchParams;

  return filters;
};

const isObjectEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj || {}).every(key => !obj?.[key]);
};

export const areSearchParamsEmpty = (params: SearchUrlParameters) => {
  return isObjectEmpty(params);
};

export const areSearchFiltersEmpty = (filters: SearchFiltersObject) => {
  return isObjectEmpty(filters);
};

export const getFormattedContexts = (params: SearchUrlParameters) => {
  const { module, wall, exercise } = params || {};
  return Object.keys({ module, wall, exercise }).reduce<ContextFilterKey[]>(
    (context, option: ContextFilterKey) => {
      if (params[option]) {
        context.push(option);
      }
      return context;
    },
    []
  );
};

export const getFormattedSearchParam = (strArray: string[]) => {
  return strArray?.join?.(",");
};
