import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import isUndefined from "lodash/isUndefined";
import { Button } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { __ } from "../modules/localization";

const FullPageModal = withTheme(
  withBackbone(
    createReactClass({
      componentDidMount() {
        $(document).on("keyup", this.closeOnEsc);
      },
      componentWillUnmount() {
        $(document).off("keyup", this.closeOnEsc);
      },
      /**
       * Close modal windows mith "ESC" key
       * @param  {object} e the keyup event
       */
      closeOnEsc(e) {
        if (e.keyCode == 27) {
          // ESC
          this.hide();
        }
      },
      hide() {
        this.props.hideFunc();
      },
      render() {
        const { modalMessage } = this.props;
        const { greyTheme } = this.props.theme;
        const addClass = this.props.addClass || "";

        const headerObj = modalMessage.get("header");
        const bodyObj = modalMessage.get("body");
        const footerObj = modalMessage.get("footer");

        let closeBtn = "";
        let header = "";
        let body = "";
        let footer = "";

        if (headerObj.content !== "") {
          if (headerObj.type === "REACT") {
            header = <div className="modal-header">{headerObj.content}</div>;
          } else {
            header = (
              <div
                className="modal-header"
                dangerouslySetInnerHTML={{ __html: headerObj.content }}
              />
            );
          }
        }

        if (bodyObj.content !== "") {
          let bodyExtraClass = "";

          if (!isUndefined(bodyObj.extraClass) && bodyObj.extraClass != "") {
            bodyExtraClass = bodyObj.extraClass;
          }

          if (bodyObj.type === "REACT") {
            body = (
              <div className={`modal-body ${bodyExtraClass}`}>
                {bodyObj.content}
              </div>
            );
          } else {
            body = (
              <div
                className={`modal-body ${bodyExtraClass}`}
                dangerouslySetInnerHTML={{ __html: bodyObj.content }}
              />
            );
          }
        }

        if (modalMessage.get("addCloseButton")) {
          closeBtn = (
            <Button theme={greyTheme} variant="secondary" onClick={this.hide}>
              {__("Chiudi")}
            </Button>
          );
        }

        if (footerObj.content !== "" || closeBtn !== "") {
          if (footerObj.type === "REACT") {
            footer = (
              <div className="modal-footer">
                {footerObj.content}
                {closeBtn}
              </div>
            );
          } else {
            footer = (
              <div className="modal-footer">
                <div dangerouslySetInnerHTML={{ __html: footerObj.content }} />
                {closeBtn}
              </div>
            );
          }
        }

        return (
          <div
            className={[
              "modal",
              "modal-fullpage",
              "fade",
              "react-modal-page",
              addClass
            ].join(" ")}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                {header}
                {body}
                {footer}
              </div>
            </div>
          </div>
        );
      }
    })
  )
);

export default FullPageModal;
