import styled from "styled-components";
import { mobileMixin } from "src/js/theme/breakpointsMixin";

export const QuickActionButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.grey[50]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.paleGray};
  }

  & svg {
    flex: 0 0 16px;
  }

  ${mobileMixin`
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};`}
`;

export const TooltipWrapper = styled.div`
  .rc-tooltip-inner {
    padding: 8px;
    color: ${({ theme }) => theme.colors.grey[50]};
    font-family: ${({ theme }) => theme.fontFamily.title};
    font-size: 10px;
    background-color: ${({ theme }) => theme.colors.paleGray};
    border-radius: 4px;
    min-height: 22px;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${({ theme }) => theme.colors.paleGray};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${({ theme }) => theme.colors.paleGray};
  }
`;
