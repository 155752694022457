import styled, { css } from "styled-components";

export const TabWrapper = styled.div`
  display: flex;
  width: max-content;
  background: ${({ theme }) => theme.contentswitcher.wrapper.background};
  border: 1px solid ${({ theme }) => theme.contentswitcher.wrapper.border};
  border-radius: 48px;
`;

export const Tab = styled.button`
  height: 32px;
  line-height: 32px;
  min-width: 113px;
  border-radius: 48px;
  margin: 4px;
  padding: 0 20px;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
  border: none;
  background: ${({ theme }) => theme.contentswitcher.tab.background};
  color: ${({ theme }) => theme.contentswitcher.tab.color};

  @media screen and (max-width: 767px) {
    min-width: unset;
  }

  &[aria-selected="true"] {
    background: ${({ theme }) => theme.contentswitcher.tab.active.background};
    color: ${({ theme }) => theme.contentswitcher.tab.active.color};
    &:hover {
      background: ${({ theme, disabled }) =>
        disabled ? null : theme.contentswitcher.tab.active.background};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background: ${({ theme }) => theme.contentswitcher.tab.active.disabled};
      `}
  }

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? null : theme.contentswitcher.tab.hover.background};
  }

  &:focus-visible {
    outline: ${({ theme }) =>
      `3px solid ${theme.contentswitcher.tab.focus.color}`};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.contentswitcher.tab.disabled.color};
      cursor: not-allowed;
    `}
`;

export const TabWrapperSquared = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4px;
    row-gap: 4px;
  }
`;

export const TabSquared = styled.button`
  flex: 1;
  height: 60px;
  line-height: 60px;
  border-radius: 10px;
  margin: 4px;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;
  border: none;
  background: ${({ theme }) => theme.contentswitcher.tab.backgroundSquared};
  color: ${({ theme }) => theme.contentswitcher.tab.color};
  transition: all 0.5s ease-in-out;

  &[aria-selected="true"] {
    background: ${({ theme }) => theme.contentswitcher.tab.active.background};
    color: ${({ theme }) => theme.contentswitcher.tab.active.color};
    &:hover {
      background: ${({ theme, disabled }) =>
        disabled ? null : theme.contentswitcher.tab.active.background};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background: ${({ theme }) => theme.contentswitcher.tab.active.disabled};
      `}
  }

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? null : theme.contentswitcher.tab.hover.background};
  }

  &:focus-visible {
    outline: ${({ theme }) =>
      `3px solid ${theme.contentswitcher.tab.focus.color}`};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.contentswitcher.tab.disabled.color};
      cursor: not-allowed;
    `}
`;
