import {
  InternalDispatcher,
  NotificationDispatcher
} from "../modules/dispatcher";

let leaveAlert = {
  status: false,
  text: ""
};

NotificationDispatcher.on("do_post", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("leaveAlertCheck", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
  leaveAlert = obj;
});

NotificationDispatcher.on("do_post_comment", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_publish", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_edit", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_edit_mode", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_element_draft_new", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_element_draft_approved", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_element_draft_rejected", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_group_invite_user_new", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_quiz_execution_review", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_exercise_execution_review", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_board_element_comment", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_exercise_publish", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_group_invite_user_accept", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_exercise_execution_finish", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_exercise_execution_finish_all", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_group_invite_request_new", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_group_invite_request_approved", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_group_user_new", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_nested_comment", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_post_accepted_moderation", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_post_rejected_moderation", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_comment_accepted_moderation", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("do_comment_rejected_moderation", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});
//Pingpong exercise
NotificationDispatcher.on("do_exercise_execution_correction_finished", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on(
  "do_exercise_execution_quiz_execution_correction_modified",
  obj => {
    InternalDispatcher.trigger("realtime_notification", obj);
  }
);

NotificationDispatcher.on(
  "do_exercise_execution_quiz_execution_redo_request",
  obj => {
    InternalDispatcher.trigger("realtime_notification", obj);
  }
);

NotificationDispatcher.on(
  "do_exercise_execution_quiz_execution_redo_open",
  obj => {
    InternalDispatcher.trigger("realtime_notification", obj);
  }
);

NotificationDispatcher.on(
  "do_exercise_execution_quiz_execution_redo_closed",
  obj => {
    InternalDispatcher.trigger("realtime_notification", obj);
  }
);

NotificationDispatcher.on("do_exercise_execution_comment_new", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on(
  "do_exercise_execution_quiz_execution_redo_done",
  obj => {
    InternalDispatcher.trigger("realtime_notification", obj);
  }
);

NotificationDispatcher.on("live_start_incoming", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("activity_start_incoming", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("activity_end_incoming", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("all_day_activity_start_incoming", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});

NotificationDispatcher.on("all_day_activity_end_incoming", obj => {
  InternalDispatcher.trigger("realtime_notification", obj);
});
