import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  useAppRoutingContext,
  useResizeObserver,
  useStores
} from "src/js/hooks";
import { matchPath, useLocation } from "react-router";
import { TopHeader } from "src/js/components/layout/TopHeader";
import { TopHeaderMobileWrapper } from "src/js/components/layout/TopHeaderMobileWrapper";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { HeaderProfileTab } from "src/js/components/layout/HeaderProfileTab";

export const useAppLayoutHeader = () => {
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerContainerRef = useResizeObserver<HTMLDivElement>(header => {
    setHeaderHeight(header.height ?? 0);
  });
  const {
    UIStore: { isLayoutModeMobile: isMobile }
  } = useStores();
  const { context } = useAppRoutingContext();
  const [headerComponent, setHeaderComponentInner] = useState<ReactNode>(
    <TopHeader context={context} />
  );
  const [headerComponentManual, setHeaderComponentManual] =
    useState<ReactNode>();

  const setHeaderComponent = useCallback((component: ReactNode) => {
    setHeaderComponentManual(component);
  }, []);

  const resetHeaderComponent = useCallback(() => {
    setHeaderComponentManual(undefined);
  }, []);

  useEffect(() => {
    if (headerComponentManual) {
      setHeaderComponentInner(headerComponentManual);
    } else if (
      matchPath(location.pathname, {
        path: WeSchoolRoutes.App.Settings.initialRouteName,
        exact: true
      }) &&
      isMobile
    ) {
      setHeaderComponentInner(<HeaderProfileTab />);
    } else if (
      matchPath<{ option: string }>(
        location.pathname,
        WeSchoolRoutes.App.Settings.settingsAccountOption
      )?.params?.option === "name" &&
      isMobile
    ) {
      setHeaderComponentInner(
        <HeaderBack title="mobile_header_settings_change_name_option" />
      );
    } else if (
      matchPath<{ option?: string }>(
        location.pathname,
        WeSchoolRoutes.App.Settings.settingsOption
      )?.params?.option === "account" &&
      isMobile
    ) {
      setHeaderComponentInner(
        <HeaderBack title="mobile_header_settings_default_title" />
      );
    } else if (
      matchPath<{ option?: string }>(
        location.pathname,
        WeSchoolRoutes.App.Settings.settingsOption
      )?.params?.option === "notice" &&
      isMobile
    ) {
      setHeaderComponentInner(<HeaderBack title="settings_notice_header" />);
    } else {
      setHeaderComponentInner(
        isMobile ? <TopHeaderMobileWrapper /> : <TopHeader context={context} />
      );
    }
  }, [location.pathname, headerComponentManual, isMobile]);

  return {
    headerComponent,
    setHeaderComponent,
    resetHeaderComponent,
    headerHeight,
    headerContainerRef
  };
};
