import {
  VariantType,
  scrollbarMixin,
  shadow,
  Icon as WeSchoolIcon,
  Text as WeSchoolText
} from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const TextButton = styled.button<{ active?: boolean }>`
  border: none;
  outline: none;
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;
  padding: 0px 4px;
  height: 28px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.violet[501]};
  transition: 220ms ease-in-out;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.violet[900]};
  }
`;

export const Dropdown = styled(motion.div)`
  left: 0px;
  top: calc(100% + 8px);
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  gap: 6px;
  box-shadow: ${shadow[500]};
  overflow: auto;
  ${scrollbarMixin(VariantType.White)}
  border-radius: 8px;
  max-height: 452px;
`;

export const DropdownItem = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[900]};
  }
`;

export const Text = styled(WeSchoolText)`
  color: ${({ theme }) => theme.colors.grey[200]};
  user-select: none;
`;

export const LeadingIcon = styled(WeSchoolIcon)`
  user-select: none;
  color: ${({ theme }) => theme.colors.violet[501]};
`;

export const NavIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: max-content;
`;

export const NavIcon = styled(WeSchoolIcon)`
  user-select: none;
  color: ${({ theme }) => theme.colors.grey[100]};
`;
