import { getHighContrastColor } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const CheckIndicatorContainer = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.lightBlueGrey};
  background-color: ${({ theme }) => theme.colors.basicWhite};
  font-size: 10px;
  text-align: center;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.basicWhite};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.primaryColor[500]};
      background-color: ${({ theme }) => theme.primaryColor[500]};
      color: ${({ theme }) => getHighContrastColor(theme.primaryColor[500])};
    `}
`;
