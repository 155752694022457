import styled, { css } from "styled-components";

import { SListItemProps } from "./types";

export const TabList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 20px;
`;

export const ListItem = styled.li<SListItemProps>`
  width: 175px;
  display: flex;
  height: 50px;
  border-radius: 67px;

  align-items: center;
  margin-bottom: 10px;

  ${({ isSelected, theme }) =>
    !isSelected &&
    css`
      background-color: ${theme.sidemenu.background};
      color: ${theme.sidemenu.text};
    `};

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.sidemenu.selected.background};
      color: ${theme.sidemenu.selected.text};
    `};
`;

export const Tab = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  color: inherit;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 67px;
  &:focus-visible {
    outline: ${({ theme }) => theme.sidemenu.focus.border};
  }
`;

export const IconContainer = styled.div`
  margin-left: auto;
`;
