import styled, { css } from "styled-components";

import { SPostProps } from "./types";

export const Post = styled.div<SPostProps>`
  border: 1px solid
    ${({ theme, pinned }) => (pinned ? theme.post.basic.pin : "#fff")};

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.post.basic.background};
  border-radius: 16px;
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
`;

export const Body = styled.div`
  margin: 20px 0 20px 0;
`;

export const WrapperAuthor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  & > span {
    color: ${({ theme }) => theme.post.basic.date.text};
    margin-right: 10px;
  }
`;
export const WrapperUser = styled.div`
  display: flex;
  align-items: center;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 80px);
  }
  svg {
    margin-left: 4px;
    margin-top: 4px;
    color: ${({ theme }) => theme.post.basic.ribbon};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12px;
  & > svg {
    color: ${({ theme }) => theme.post.basic.pin};
  }
  @media (min-width: 768px) {
    position: absolute;
    right: 10px;
  }
`;

export const BadgeScoreSmall = styled.div`
  height: 24px;
  width: 24px;
  z-index: ${({ theme }) => theme.zIndex.regular};
  bottom: 0px;
  left: 5px;
  font-size: 11px;
  line-height: 21px;
  border-radius: 35px;
  display: block;
  position: absolute;
  text-align: center;
  background-color: ${({ theme }) => theme.post.badgeScore.background};
  border: 1px solid ${({ theme }) => theme.post.badgeScore.border};
  @media (min-width: 768px) {
    bottom: 12px;
    left: 19px;
  }
`;

export const BadgeScoreBig = styled.div`
  height: 30px;
  width: 30px;
  left: 7px;
  top: 55px;
  line-height: 26px;
  border-radius: 35px;
  display: block;
  position: absolute;
  text-align: center;
  background-color: ${({ theme }) => theme.post.badgeScore.background};
  border: 1px solid ${({ theme }) => theme.post.badgeScore.border};
  @media (min-width: 768px) {
    left: 17px;
    top: 66px;
  }
`;

export const ActionButtonWrapper = styled.div`
  margin-top: 7px;
`;
