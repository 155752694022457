import {
  __COOKIE_LOGIN__,
  __COOKIE_REFRESH__
} from "src/js/settings/settingsCookie";

export function createCookie(
  name: string,
  value: string,
  days: number,
  path?: string,
  domain?: string
) {
  const strPath = typeof path === "undefined" ? "path=/" : `path=${path}`;
  const strDomain = typeof domain === "undefined" ? "" : `; domain=${domain}`;
  let expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    // eslint-disable-next-line dot-notation
    expires = `expires=${date["toGMTString"]()}`;
  }
  // console.log(name + "=" + value + "; " + expires + ";" + strDomain + ";" + strPath + ";");
  document.cookie = `${name}=${value}; ${expires}${strDomain};${strPath};`;
}

const parseToJson = <TData>(str: string): TData | undefined => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return undefined;
  }
};

const isJsonParsable = (s: string | null) => {
  try {
    JSON.parse(s || "");
  } catch {
    return false;
  }

  return true;
};

export function readCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name: string) {
  createCookie(name, "", -1);
}

export function createSessionData(name: string, value: string) {
  return sessionStorage.setItem(name, value);
}

export function readSessionData(name: string) {
  return sessionStorage.getItem(name);
}

export function destroySessionData(name: string) {
  sessionStorage.removeItem(name);
}

export function destroyLocalData(name: string) {
  localStorage.removeItem(name);
}

export const LocalStorageKey = {
  AiTextEditorToolTip: "teAiDiscovered",
  AiTextEditorModal: "teAiModalDiscovered",
  NewNavigationInfoModal: "newNavigationInfoModalShown",
  ActiveUser: "activeUser",
  ActiveGroup: "activeGroup",
  ActiveSpace: "activeSpace",
  NewAnalyticsInfoModal: "newAnalyticsInfoModal",
  SwitchDashboardAnalyticsVersion: "switchDashboardAnalyticsVersion"
} as const;

export const SessionStorageKey = {
  DemoSpaceTopBannerClosed: "demoSpaceTopBannerClosed"
};

// Ideally the name could be of type "localStorageKey"
export function createLocalStringifiedData(name: string, value: unknown) {
  let stringified = value;

  if (typeof value !== "string") {
    stringified = JSON.stringify(value);
  }

  return localStorage.setItem(name, stringified as string);
}

export function createLocalData(
  name: string,
  value: string,
  expiresIn?: number,
  expiresAt?: string
) {
  if (expiresIn) {
    const date = new Date();
    const expiresAtFromExpiresIn = new Date(date.getTime() + expiresIn * 60000);

    return createLocalStringifiedData(name, {
      value,
      expiresAt: expiresAtFromExpiresIn
    });
  }
  if (expiresAt) {
    return createLocalStringifiedData(name, { value, expiresAt });
  }
  return localStorage.setItem(name, value);
}

export function readLocalData(name: string) {
  const data = localStorage.getItem(name);

  if (isJsonParsable(data) && JSON.parse(data)?.expiresAt) {
    return new Date(JSON.parse(data)?.expiresAt).getTime() <=
      new Date().getTime()
      ? destroyLocalData(name)
      : JSON.parse(data);
  }

  return data;
}

export function readLocalParsedData<TData = unknown>(
  name: string
): TData | undefined {
  let item = localStorage.getItem(name) || "";

  // If the item is not parsable, we need to wrap it in quotes
  if (item && !isJsonParsable(item)) {
    item = `"${item}"`;
  }

  return parseToJson<TData>(item);
}

export function destroyAllLocalData() {
  localStorage.clear();
}

export function destroyAccessData() {
  eraseCookie(__COOKIE_LOGIN__);
  eraseCookie(__COOKIE_REFRESH__);
}
