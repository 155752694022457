import { Extension } from "@tiptap/core";
import { Resource, TempResource } from "src/js/types";
import { ResourceActions } from "./TextEditorInput.types";

export const resourcesReducer = (
  state: TempResource[],
  action: {
    type: ResourceActions;
    payload?: {
      tempResources?: TempResource[];
      resource?: Resource;
      id?: string | number;
    };
  }
) => {
  switch (action.type) {
    case ResourceActions.SetTempsResources:
      return [...state, ...action?.payload?.tempResources];

    case ResourceActions.RemoveTempResourceById:
      return state.filter(resource => resource?.tempId !== action?.payload?.id);

    case ResourceActions.ReplaceTempResource:
      return state.map(r => {
        if (r.tempId === action?.payload?.id && action?.payload?.resource) {
          return action?.payload?.resource;
        }
        return r;
      });

    case ResourceActions.RemoveResourceById:
      return state.filter(resource => resource?.id !== action?.payload?.id);

    case ResourceActions.Reset:
      return [];

    default:
      return state;
  }
};

export const KeyboardHandler = Extension.create({
  name: "keyboardHandler",
  addKeyboardShortcuts() {
    return {
      Enter: () => true,
      "Shift-Enter": () => {
        /**
         * currently we do not have an option to show a soft line break in the posts, so we overwrite
         * the behavior from tiptap with the default behavior on pressing enter
         */
        return this.editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.splitListItem("listItem"),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock()
        ]);
      }
    };
  }
});
