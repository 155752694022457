import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ConnectWalletButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
`;

export const WalletAddressWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  padding: 12px;
  margin-top: 18px;
  margin-bottom: 24px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
`;

export const ConnectWalletButtonWrapperContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const WalletSupportedChainsInformation = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[400]};
`;
