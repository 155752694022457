import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "styled-components";
import { ActionItem } from "@ds_universal/data_entry/ActionItem";
import { Icon } from "@ds_universal/Icon";

import { dropdownAnimationVariants } from "./ActionItemsDropdown.utils";
import * as S from "./ActionItemsDropdown.styles";
import { ActionItemsDropdownProps } from "./types";

const ActionItemsDropdown = ({
  theme,
  mainButtonString,
  mainButtonIcon,
  dropdownOptions
}: ActionItemsDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <S.DropdownWrapper ref={ref}>
        <S.DropdownTrigger
          onClick={() => setIsOpen(!isOpen)}
          role="button"
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          <S.ButtonContent>
            <S.LeftSection>
              <Icon
                icon={mainButtonIcon}
                width={24}
                height={24}
                initialViewbox
              />
              {mainButtonString}
            </S.LeftSection>
            <Icon icon="plusThin" width={16} height={16} />
          </S.ButtonContent>
        </S.DropdownTrigger>
        <AnimatePresence initial={false}>
          {isOpen && (
            <S.DropdownContent
              variants={dropdownAnimationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              {dropdownOptions.map(
                ({
                  title,
                  subtitle,
                  icon,
                  betaIndicator,
                  isDisabled,
                  onClick
                }) => (
                  <ActionItem
                    key={title}
                    theme={theme}
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                    betaIndicator={betaIndicator}
                    isDisabled={isDisabled}
                    onClick={() => {
                      onClick();
                      setIsOpen(false);
                    }}
                  />
                )
              )}
            </S.DropdownContent>
          )}
        </AnimatePresence>
      </S.DropdownWrapper>
    </ThemeProvider>
  );
};

export default ActionItemsDropdown;
