import { useCallback } from "react";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { checkThreadExists } from "src/js/repository/threadsRepository";
import { navigateTo } from "src/legacy/modules/history";

const useCreateQuickThread = () => {
  const {
    SpaceStore: { activeSpaceId, activeSpaceSlug },
    UIStore: { isLayoutModeMobile },
    ModalStore: { show, closeModal }
  } = useStores();

  const navigateToThread = useCallback(
    (uuid: string, openInNewTab?: boolean) => {
      if (!activeSpaceSlug || !uuid) return;
      checkThreadExists({
        spaceId: activeSpaceId,
        participants: [uuid]
      })
        .then(({ threadId }) => {
          if (openInNewTab) {
            window.open(
              `${window.location.origin}/#${createUrl("chat_thread", {
                space_slug: activeSpaceSlug,
                thread_id: threadId
              })}`,
              "_blank"
            );
            return;
          }

          navigateTo(
            createUrl("chat_thread", {
              space_slug: activeSpaceSlug,
              thread_id: threadId
            })
          );
        })
        .catch(() => {
          if (openInNewTab) {
            window.open(
              `${window.location.origin}/#${createUrl(
                isLayoutModeMobile ? "space_chats" : "chat_new",
                {
                  space_slug: activeSpaceSlug
                }
              )}?user_id=${uuid}`,
              "_blank"
            );
            return;
          }
          navigateTo(
            `${createUrl(isLayoutModeMobile ? "space_chats" : "chat_new", {
              space_slug: activeSpaceSlug
            })}?user_id=${uuid}`
          );
        })
        .finally(() => {
          if (show) closeModal();
        });
    },
    [activeSpaceSlug, activeSpaceId, isLayoutModeMobile]
  );

  return { navigateToThread };
};

export default useCreateQuickThread;
