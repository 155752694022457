import * as PopoverRadix from "@radix-ui/react-popover";
import * as TooltipRadix from "@radix-ui/react-tooltip";
import React from "react";
import { useStores } from "src/js/hooks";

type TooltipProps = {
  open: boolean;
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  withPortal?: boolean;
};

export const Tooltip = ({
  open,
  children,
  tooltipContent,
  withPortal = true
}: TooltipProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  if (isLayoutModeMobile) {
    return (
      <PopoverRadix.Root open={open}>
        <PopoverRadix.Trigger asChild>{children}</PopoverRadix.Trigger>
        {withPortal ? (
          <PopoverRadix.Portal>{tooltipContent}</PopoverRadix.Portal>
        ) : (
          tooltipContent
        )}
      </PopoverRadix.Root>
    );
  }

  return (
    <TooltipRadix.Provider delayDuration={0}>
      <TooltipRadix.Root open={open}>
        <TooltipRadix.Trigger asChild>{children}</TooltipRadix.Trigger>
        {withPortal ? (
          <TooltipRadix.Portal>{tooltipContent}</TooltipRadix.Portal>
        ) : (
          tooltipContent
        )}
      </TooltipRadix.Root>
    </TooltipRadix.Provider>
  );
};

export default Tooltip;
