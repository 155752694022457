import { Palette, SpecificErrorThemeOptions } from "@ds_themes/types";

export const createSpecificErrorTheme = ({
  error
}: Palette): SpecificErrorThemeOptions => {
  return {
    specificerror: {
      text: error[300]
    }
  };
};
