import React from "react";
import { Icon } from "@arcadia/design-system";
import * as S from "./DocumentAnswerCard.styles";

const DocumentAnswerCard = ({
  resource,
  onClick = () => {},
  disableRemove = false,
  onRemove = () => {}
}) => {
  return (
    <S.ResponseResource onClick={() => onClick()}>
      <S.AttachmentIconContainer>
        <Icon icon="link" width={25} height={25} />
      </S.AttachmentIconContainer>
      <S.ResponseResourceTitle>{resource.name}</S.ResponseResourceTitle>
      {disableRemove ? (
        ""
      ) : (
        <S.RemoveButton
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <Icon icon="close" width={15} height={15} />
        </S.RemoveButton>
      )}
    </S.ResponseResource>
  );
};

export default DocumentAnswerCard;
