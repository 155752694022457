import { useCallback } from "react";
import { getExternalRedirectUrl } from "src/js/modules/commonFunction";
import { navigateToRedirectUrl } from "src/js/modules/urlFunction";
import { LoginOption } from "src/js/pages/unlogged/LoginPage/LoginPage.types";
import { api_oauth_sso } from "src/js/repository/apiUrls";
import { __API_EXTERNAL_AUTH_PATH__ } from "src/js/settings/settingsUrl";
import { SSOProvider } from "src/js/types";

export const useSocialLogin = (inviteToken?: string) => {
  const handleSSO = useCallback(
    (provider: SSOProvider, tenantId?: string | undefined) => {
      navigateToRedirectUrl(__API_EXTERNAL_AUTH_PATH__ + api_oauth_sso(), {
        provider,
        tenantId,
        redirect: getExternalRedirectUrl(),
        invite_token: inviteToken
      });
    },
    []
  );

  const loginOptions: LoginOption[] = [
    {
      provider: "google",
      label: "login_google",
      onClick: () => handleSSO("GOOGLE_SSO")
    },
    {
      provider: "apple",
      label: "login_apple",
      viewBox: "0 0 24 24",
      onClick: () => handleSSO("APPLE_SSO")
    },
    {
      provider: "microsoft",
      label: "login_microsoft",
      viewBox: "0 0 20 20",
      onClick: () => handleSSO("MICROSOFT_SSO")
    }
  ];

  return { loginOptions, handleSSO };
};
