import { TODO } from "src/js/types";
import { SettingOptionType } from "./SettingsMobile.types";

export const SettingOptions: SettingOptionType[] = [
  {
    icon: "gear",
    text: "mobile_settings_account",
    type: "account"
  },
  {
    icon: "user",
    text: "mobile_settings_profile",
    type: "profile"
  },
  {
    icon: "bellEmpty",
    text: "mobile_settings_notifications",
    type: "notifications"
  },
  {
    icon: "frameSource" as TODO,
    text: "mobile_settings_join_group_with_code",
    type: "joinGroupWithCode"
  },
  {
    icon: "help",
    text: "mobile_settings_help",
    type: "help"
  }
];
