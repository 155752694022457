import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import {
  deleteGroup as deleteGroupRepo,
  removeUser
} from "src/js/repository/groupRepository";
import { TranslationKeys } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";

export const getOnDeleteOrLeave = ({
  groupId,
  translate
}: {
  groupId: number;
  translate: (val: TranslationKeys) => string;
}) => {
  return {
    deleteGroup: () =>
      deleteGroupRepo(groupId)
        .then(() => {
          showToastSuccess({
            str: translate("group_delete_confirmation_message")
          });
        })
        .catch(() => {
          showToastError({
            str: translate("group_delete_error_message")
          });
        }),
    removeMe: () =>
      removeUser(groupId, "me")
        .then(() => {
          showToastSuccess({
            str: translate("group_leave_confirmation_message")
          });
        })
        .catch(() => {
          showToastError({
            str: translate("group_leave_error_message")
          });
        })
  };
};

export const getBaseStudentOptions = ({
  groupId,
  translate
}: {
  groupId: number;
  translate: (val: TranslationKeys) => string;
}): ListItemProps[] => [
  {
    id: "informations",
    icon: "gear",
    label: translate("header_group_settings_information_label"),
    onClick: () =>
      navigateTo(
        createUrl("group_settings", {
          group_id: groupId,
          option: "information"
        })
      )
  },
  {
    id: "view-members",
    icon: "user",
    label: translate("view_members"),
    onClick: () =>
      navigateTo(
        createUrl("group_settings", {
          group_id: groupId,
          option: "members"
        })
      )
  },
  {
    id: "notifications",
    icon: "bellEmpty",
    label: translate("group_notice_settings_dropdown_item"),
    onClick: () =>
      navigateTo(
        createUrl("group_settings", {
          group_id: groupId,
          option: "notice"
        })
      )
  }
];

export const getBaseTeacherOptions = ({
  groupId,
  translate
}: {
  groupId: number;
  translate: (val: TranslationKeys) => string;
}): ListItemProps[] => [
  {
    id: "informations",
    icon: "gear",
    label: translate("header_group_settings_edit_information_label"),
    onClick: () =>
      navigateTo(
        createUrl("group_settings", {
          group_id: groupId,
          option: "information"
        })
      )
  },
  {
    id: "view-members",
    icon: "user",
    label: translate("view_members"),
    onClick: () =>
      navigateTo(
        createUrl("group_settings", {
          group_id: groupId,
          option: "members"
        })
      )
  },
  {
    id: "notification-settings",
    icon: "bellEmpty",
    label: translate("group_notice_settings_dropdown_item"),
    onClick: () =>
      navigateTo(
        createUrl("group_settings", {
          group_id: groupId,
          option: "notice"
        })
      )
  }
];
