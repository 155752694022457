import React from "react";
import { useScript } from "src/js/hooks";
import {
  __CHARGEBEE_API_KEY__,
  __CHARGEBEE_SITE__
} from "src/js/settings/settingsGeneral";
import { createGlobalStyle } from "styled-components";

const GlobalStyleChargebee = createGlobalStyle`
  #cb-container {
    display: none !important;
  }
`;

const InitChargebee = () => {
  useScript({
    src: "https://js.chargebee.com/v2/chargebee.js",
    onload: () => {
      window.Chargebee.init({
        site: __CHARGEBEE_SITE__,
        api_key: __CHARGEBEE_API_KEY__,
        iframeOnly: true
      });
    }
  });

  return <GlobalStyleChargebee />;
};

export default InitChargebee;
