import styled from "styled-components";
import { Heading as WSHeading } from "@arcadia/design-system";

export const ToolTipWrapper = styled.div`
  padding: 16px;
  max-width: 300px;
  background-color: ${({ theme }) => theme.colors.violet[100]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.violet[100]} transparent;
  }
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.violet[700]};
  cursor: pointer;
`;

export const Heading = styled(WSHeading)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ToolTipHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  max-width: 260px;
`;
