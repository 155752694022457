import { ObjectValues } from "..";
// Category
export const EventDomain = {
  AiCourseGenerator: "AiCourseGenerator",
  AiTextEditor: "AiTextEditor",
  Calendar: "Calendar",
  Navigation: "Navigation",
  Group: "Group",
  Chat: "Chat",
  Searchbar: "Searchbar",
  Lessons: "Lessons",
  ModuleAddContent: "ModuleAddContent",
  Onboarding: "Onboarding",
  ChatAddContent: "ChatAddContent",
  ExerciseAddContent: "ExerciseAddContent",
  WallAddContent: "WallAddContent",
  User: "User",
  NewUserRegistration: "NewUserRegistration",
  SpaceCreation: "SpaceCreation",
  SpaceDashboardUsage: "SpaceDashboardUsage",
  Templating: "Templating"
  /* ...other Domains */
} as const;

export type EventDomain = ObjectValues<typeof EventDomain>;

/** AiTextEditorEvent */

export const AiTextEditorEvent = {
  CommandAiWritter: "ai_editor_ai_writter",
  CommandCompleteSentence: "ai_editor_complete_sentence",
  CommandExtend: "ai_editor_extend",
  CommandShorten: "ai_editor_shorten",
  CommandFixSpellingAndGrammar: "ai_editor_fix_spelling_and_grammar",
  CommandRephrase: "ai_editor_rephrase",
  CommandSimplify: "ai_editor_simplify",
  CommandSummarise: "ai_editor_summarise",
  CommandEmojify: "ai_editor_emojify",
  CommandToneDefault: "ai_editor_tone_default",
  CommandToneAcademic: "ai_editor_tone_academic",
  CommandToneBusiness: "ai_editor_tone_business",
  CommandToneCasual: "ai_editor_tone_casual",
  CommandToneChildFriendly: "ai_editor_tone_child_friendly",
  CommandToneConfident: "ai_editor_tone_confident",
  CommandToneConversational: "ai_editor_tone_conversational",
  CommandToneCreative: "ai_editor_tone_creative",
  CommandToneEmotional: "ai_editor_tone_emotional",
  CommandToneExcited: "ai_editor_tone_excited",
  CommandToneFormal: "ai_editor_tone_formal",
  CommandToneFriendly: "ai_editor_tone_friendly",
  CommandToneFunny: "ai_editor_tone_funny",
  CommandToneHumorous: "ai_editor_tone_humorous",
  CommandToneInformative: "ai_editor_tone_informative",
  CommandToneInspirational: "ai_editor_tone_inspirational",
  CommandToneMemeify: "ai_editor_tone_memeify",
  CommandToneNarrative: "ai_editor_tone_narrative",
  CommandToneObjective: "ai_editor_tone_objective",
  CommandTonePersuasive: "ai_editor_tone_persuasive",
  CommandTonePoetic: "ai_editor_tone_poetic",
  CommandTranslateEnglish: "ai_editor_translation_english",
  CommandTranslateItalian: "ai_editor_translation_italian",
  CommandTranslateFrench: "ai_editor_translation_french",
  CommandTranslateSpanish: "ai_editor_translation_spanish",
  AiWriterCommandExecuteQuery: "ai_writer_execute_query",
  AiWriterCommandSpaceTrigger: "ai_writer_space_trigger",
  AiWriterCommandExtend: "ai_writer_extend",
  AiWriterCommandApply: "ai_writer_apply",
  AiWriterCommandDiscard: "ai_writer_discard",
  AiWriterCommandSimplify: "ai_writer_simplify",
  AiWriterCommandSummarise: "ai_writer_summarise",
  AiWriterCommandTryAgain: "ai_writer_try_again",
  AiWriterCommandShorten: "ai_writer_shorten",
  AiWriterCommandToneDefault: "ai_writer_tone_default",
  AiWriterCommandToneAcademic: "ai_writer_tone_academic",
  AiWriterCommandToneBusiness: "ai_writer_tone_business",
  AiWriterCommandToneCasual: "ai_writer_tone_casual",
  AiWriterCommandToneChildFriendly: "ai_writer_tone_child_friendly",
  AiWriterCommandToneConfident: "ai_writer_tone_confident",
  AiWriterCommandToneConversational: "ai_writer_tone_conversational",
  AiWriterCommandToneCreative: "ai_writer_tone_creative",
  AiWriterCommandToneEmotional: "ai_writer_tone_emotional",
  AiWriterCommandToneExcited: "ai_writer_tone_excited",
  AiWriterCommandToneFormal: "ai_writer_tone_formal",
  AiWriterCommandToneFriendly: "ai_writer_tone_friendly",
  AiWriterCommandToneFunny: "ai_writer_tone_funny",
  AiWriterCommandToneHumorous: "ai_writer_tone_humorous",
  AiWriterCommandToneInformative: "ai_writer_tone_informative",
  AiWriterCommandToneInspirational: "ai_writer_tone_inspirational",
  AiWriterCommandToneMemeify: "ai_writer_tone_memeify",
  AiWriterCommandToneNarrative: "ai_writer_tone_narrative",
  AiWriterCommandToneObjective: "ai_writer_tone_objective",
  AiWriterCommandTonePersuasive: "ai_writer_tone_persuasive",
  AiWriterCommandTonePoetic: "ai_writer_tone_poetic"
} as const;

export type AiTextEditorEvent = ObjectValues<typeof AiTextEditorEvent>;

/** AiCourseGeneratorEvent */

export const AiCourseGeneratorEvent = {
  NewModuleEmptyAi: "new_module_empty_AI",
  NewModuleDropdownAi: "new_module_dropdown_AI",
  AiWizardStep1: "ai_wizard_step_1",
  AiWizardStep2: "ai_wizard_step_2",
  AiWizardStep2Examples: "ai_wizard_step_2_examples",
  AiWizardStep2ApplyExample: "ai_wizard_step_2_apply_example",
  AiWizardStep3: "ai_wizard_step_3",
  AiWizardStep4: "ai_wizard_step_4",
  AiWizardStep5GeneratePreview: "ai_wizard_step_5_generate_preview",
  AiWizardPreviewAddModule: "ai_wizard_preview_add_module",
  AiWizardPreviewRegenerate: "ai_wizard_preview_regenerate",
  AiWizardPreviewGenerateDraft: "ai_wizard_preview_generate_draft",
  AiWizardPreviewViewPath: "ai_wizard_preview_view_path",
  AiWizardPreviewJumpContent: "ai_wizard_preview_jump_content"
} as const;

export type AiCourseGeneratorEvent = ObjectValues<
  typeof AiCourseGeneratorEvent
>;

/** CalendarEvent */

export const CalendarAnalyticsEvent = {
  CalendarAgendaViewClick: "calendar_agenda_view_click",
  CalendarGridViewClick: "calendar_grid_view_click",
  CalendarMonthViewClick: "calendar_month_view_click",
  CalendarWeekViewClick: "calendar_week_view_click"
} as const;

export type CalendarAnalyticsEvent = ObjectValues<
  typeof CalendarAnalyticsEvent
>;

/** NavigationEvent */

export const NavigationEvent = {
  SidebarClose: "sidebar_close",
  SidebarOpen: "sidebar_open"
} as const;

export type NavigationEvent = ObjectValues<typeof NavigationEvent>;

/** GroupEvent */

export const GroupEvent = {
  BackToGroupCreateSelection: "BackToGroupCreateSelection",
  CloneGroup: "CloneGroup",
  DashboardGroupsEmptyState: "DashboardGroupsEmptyState",
  DashboardGroupCreation: "DashboardGroupCreation",
  GroupCreation: "GroupCreation",
  GroupsEmptyState: "GroupsEmptyState",
  GroupJoin: "GroupJoin",
  InviteButtonClick: "invite_button_click",
  HomePageGroupCreation: "HomePageGroupCreation",
  JoinCodeFlow: "JoinCodeFlow",
  NavBarGroupCreation: "NavBarGroupCreation",
  StandardGroupSelected: "StandardGroupSelected"
} as const;

export type GroupEvent = ObjectValues<typeof GroupEvent>;

/** ChatEvent */

export const ChatEvent = {
  ChatStartHomeClick: "chat_start_home_click",
  ChatStartWallClick: "chat_start_wall_click",
  ChatStartGroupMembersClick: "chat_start_group_members_click",
  ChatStartThreadListClick: "chat_start_thread_list_click",
  ChatStartThreadListNewClick: "chat_start_thread_list_new_click",
  ChatStartDashboardClick: "chat_start_dashboard_click"
} as const;

export type ChatEvent = ObjectValues<typeof ChatEvent>;

/** SearchbarEvent */

export const SearchbarEvent = {
  SearchBarUseSearch: "SearchBarUseSearch",
  SearchResultsClick: "SearchResultsClick"
};

export type SearchbarEvent = ObjectValues<typeof SearchbarEvent>;

/** ResourcePickerEvent */

export const ResourcePickerEvent = {
  Click: "Click"
} as const;

export type ResourcePickerEvent = ObjectValues<typeof ResourcePickerEvent>;

export const ResourcePickerLabel = {
  Search: "Search",
  DraftWithAI: "DraftWithAI",
  Editor: "Editor",
  Test: "Test",
  File: "File",
  Link: "Link",
  Images: "Images",
  Video: "Video",
  Scorm: "Scorm",
  GoogleDrive: "GoogleDrive",
  Dropbox: "Dropbox",
  PDF: "PDF",
  GoogleDocs: "GoogleDocs",
  GoogleSheet: "GoogleSheet",
  GoogleSlides: "GoogleSlides",
  Excel: "Excel",
  Powerpoint: "Powerpoint",
  Word: "Word",
  Vimeo: "Vimeo",
  YouTube: "YouTube"
} as const;

export type ResourcePickerLabel = ObjectValues<typeof ResourcePickerLabel>;

/** UserEvent */

export const UserEvent = {
  // TODO check these 3 are called at the end of the flow
  SignupMailInvitationAccessPoint: "SignupMailInvitationAccessPoint",
  SignupViaPublicInvitationAccessPoint: "SignupViaPublicInvitationAccessPoint",
  SignupAccessPoint: "SignupAccessPoint",
  ManualLogin: "ManualLogin",
  AccessToTheLive: "AccessToTheLive",
  UnderageApprovation: "UnderageApprovation",
  UserActivation: "UserActivation",
  UnderageLock: "UnderageLock"
} as const;

export const NewUserRegistrationEvent = {
  UserVerificationForm: "UserVerificationForm",
  UserDataForm: "UserDataForm"
} as const;

/** AnalyticsEvents */

export type AnalyticsEvents = {
  [EventDomain.AiCourseGenerator]: AiCourseGeneratorEvent;
  [EventDomain.AiTextEditor]: AiTextEditorEvent;
  [EventDomain.Navigation]: NavigationEvent;
  [EventDomain.Group]: GroupEvent;
  [EventDomain.Chat]: ChatEvent;
  [EventDomain.Searchbar]: SearchbarEvent;
  [EventDomain.ModuleAddContent]: ResourcePickerEvent;
  [EventDomain.ChatAddContent]: ResourcePickerEvent;
  [EventDomain.ExerciseAddContent]: ResourcePickerEvent;
  [EventDomain.WallAddContent]: ResourcePickerEvent;
};

/** AnalyticsLabel */

export type AnalyticsLabel = {
  [EventDomain.AiCourseGenerator]: string;
  [EventDomain.AiTextEditor]: string;
  [EventDomain.Navigation]: string;
  [EventDomain.Group]: string;
  [EventDomain.Chat]: string;
  [EventDomain.ModuleAddContent]: ResourcePickerLabel;
  [EventDomain.ChatAddContent]: ResourcePickerLabel;
  [EventDomain.ExerciseAddContent]: ResourcePickerLabel;
  [EventDomain.WallAddContent]: ResourcePickerLabel;
};

/** SpaceCreationEvent */
export const SpaceCreationEvent = {
  Creation: "Creation",
  InAppSpaceCreationAccessPoint: "InAppSpaceCreationAccessPoint",
  SchoolSpaceJoin: "SchoolSpaceJoin",
  SchoolSpaceCreate: "SchoolSpaceCreate",
  SchoolSpaceConfirmation: "SchoolSpaceConfirmation"
} as const;

/** LessonsEvent */
export const LessonsEvent = {
  CommentCreate: "CommentCreate",
  CommentDelete: "CommentDelete",
  NewModuleDropdownImport: "new_module_dropdown_import",
  NewModuleDropdownNew: "new_module_dropdown_new",
  NewModuleEmptyImport: "new_module_empty_import",
  NewModuleEmptyNew: "new_module_empty_new",
  MandatoryOrder: "MandatoryOrder", // board settings
  LessonsLocking: "LessonsLocking" // board settings
} as const;

/** DashboardEvent */
export const DashboardEvent = {
  DashboardUserAccessSecNav: "DashboardUserAccessSecNav",
  DashboardUserAccessSpMenu: "DashboardUserAccessSpMenu",
  DashboardUserAccessOldDash: "DashboardUserAccessOldDash",
  ViewDashboard: "ViewDashboard",
  OldDashboardUserAccess: "OldDashboardUserAccess",
  FilterGroupsOpen: "FilterGroupsOpen",
  FilterGroupsSearch: "FilterGroupsSearch",
  FilterGroupsCheckbox: "FilterGroupsCheckbox",
  FilterDateOpen: "FilterDateOpen",
  FilterDateSelect: "FilterDateSelect",
  FilterDateCustom: "FilterDateCustom",
  ObjectivesViewInfo: "ObjectivesViewInfo",
  CourseprogressViewInfo: "CourseprogressViewInfo",
  CourseprogressCTARecordModule: "CourseprogressCTARecordModule",
  CourseprogressCTAModule: "CourseprogressCTAModule",
  SeeDetailRecordModule: "SeeDetailRecordModule",
  SeeDetailModule: "SeeDetailModule",
  AssessmentsViewInfo: "AssessmentsViewInfo",
  AssessmentsClickGroupDetail: "AssessmentsClickGroupDetail",
  AssessmentsOpenGroupDetailModal: "AssessmentsOpenGroupDetailModal",
  AssessmentsCTAScores: "AssessmentsCTAScores",
  SeeDetailRecordTest: "SeeDetailRecordTest",
  TimeonplatformViewInfo: "TimeonplatformViewInfo",
  TimespentlearningViewInfo: "TimespentlearningViewInfo",
  NumberofsessionsViewInfo: "NumberofsessionsViewInfo"
} as const;

export type DashboardEvent = ObjectValues<typeof DashboardEvent>;

export const OnboardingEvent = {
  BookDemo: "BookDemo",
  BusinessSpaceFlow: "BusinessSpaceFlow",
  CompleteFlow: "CompleteFlow",
  GroupCreation: "GroupCreation",
  WeSchoolTemplateSelection: "WeSchoolTemplateSelection",
  WeSchoolTemplateCreation: "WeSchoolTemplateCreation",
  JoinedSchool: "JoinedSchool",
  JoinCodeFlow: "JoinCodeFlow",
  JoinedSpaceCode: "JoinedSpaceCode",
  Logout: "Logout",
  MarketingData: "MarketingData",
  ResumeStandardGroupSelected: "ResumeStandardGroupSelected",
  ResumeWeSchoolTemplateSelection: "ResumeWeSchoolTemplateCreation",
  ResumeSpaceCreation: "ResumeSpaceCreation",
  SendInvite: "SendInvite",
  SkipInvite: "SkipInvite",
  SchoolSpaceConfirmation: "SchoolSpaceConfirmation",
  SchoolSpaceCreate: "SchoolSpaceCreate",
  SchoolSpaceFlow: "SchoolSpaceFlow",
  SchoolSpaceJoin: "SchoolSpaceJoin",
  SpaceCreation: "SpaceCreation",
  StandardGroupSelected: "StandardGroupSelected",
  StartFlow: "StartFlow"
};

export const TemplatingEvent = {
  ConfirmSetCustomTemplate: "ConfirmSetCustomTemplate",
  ConfirmUnsetCustomTemplate: "ConfirmUnsetCustomTemplate",
  CustomTemplateSelection: "CustomTemplateSelection",
  WeSchoolTemplateSelection: "WeSchoolTemplateSelection",
  CustomTemplateCreation: "CustomTemplateCreation",
  WeSchoolTemplateCreation: "WeSchoolTemplateCreation",
  SetCustomTemplate: "SetCustomTemplate",
  UnsetCustomTemplate: "UnsetCustomTemplate"
};

/** ExternalLinkEvent */
export const ExternalLinkEvent = {
  BookDemo: "BookDemo"
};
