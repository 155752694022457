import {
  shadow,
  Heading,
  scrollbarMixin,
  VariantType
} from "@arcadia/design-system";
import { motion } from "framer-motion";
import WSTheme from "src/js/theme/theme";
import styled, { css } from "styled-components";

export const NotificationDrawerWrapper = styled(motion.div)<{
  isLayoutModeMobile?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.primaryColor[800]};
  color: ${({ theme }) => theme.colors.grey[50]};
  ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          position: absolute;
          top: calc(100% + 12px);
          right: 0;
          z-index: ${WSTheme.zIndex.header + 1};
          width: 408px;
          border-radius: 12px;
          box-shadow: ${shadow[300]};
        `};
  overflow: hidden;
`;

export const NotificationHeaderMobile = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  padding: 24px 16px;
`;

export const NotificationHeader = styled.div`
  padding: 24px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.colors.grey[50]};
  user-select: none;
`;

export const IconWrapper = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const DateRow = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MarkReadButton = styled.button`
  color: ${({ theme }) => theme.primaryColor[500]};
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  padding: 0px;
`;

export const FooterWrapper = styled.div<{ isLayoutModeMobile?: boolean }>`
  ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile
      ? css`
          position: fixed;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          bottom: 20px;
          width: calc(100% - 32px);
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px;
          border-top: 1px solid ${({ theme }) => theme.colors.grey[900]};
        `};
`;

export const NotificationContent = styled(motion.div)<{
  isLayoutModeMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  transition: max-height 0.35s ease-in-out;

  ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile
      ? css`
          max-height: 100%;
          padding-bottom: 256px;
        `
      : css`
          max-height: 408px;
        `};

  ${scrollbarMixin(VariantType.White)}
`;
