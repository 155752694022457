import { ObjectValues } from "src/js/types";

export const AIInitialTransformations = {
  AIwriter: "aiWriter",
  CompleteSentence: "completeSentence",
  Extend: "extend",
  Shorten: "shorten",
  FixSpellingAndGrammar: "fixSpellingAndGrammar",
  Rephrase: "rephrase",
  Simplify: "simplify",
  Summarise: "summarise",
  Emojify: "emojify",
  ChangeTone: "changeTone",
  Translate: "translate"
} as const;

export const AIToneTransformations = {
  Default: "default",
  Academic: "academic",
  Business: "business",
  Casual: "casual",
  Childfriendly: "childfriendly",
  Confident: "confident",
  Conversational: "conversational",
  Creative: "creative",
  Emotional: "emotional",
  Excited: "excited",
  Formal: "formal",
  Friendly: "friendly",
  Funny: "funny",
  Humorous: "humorous",
  Informative: "informative",
  Inspirational: "inspirational",
  Memeify: "memeify",
  Narrative: "narrative",
  Objective: "objective",
  Persuasive: "persuasive",
  Poetic: "poetic"
} as const;

export const AITranslateTransformations = {
  English: "en",
  Italian: "it",
  French: "fr",
  Spanish: "es"
} as const;

export type AIInitialTransformationsValue = ObjectValues<
  typeof AIInitialTransformations
>;
export type AIToneTransformationsValue = ObjectValues<
  typeof AIToneTransformations
>;
export type AITranslateTransformationsValue = ObjectValues<
  typeof AITranslateTransformations
>;

export type AITransformationValue =
  | AIInitialTransformationsValue
  | AITranslateTransformationsValue
  | AIToneTransformationsValue;
