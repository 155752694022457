import React, { useEffect, useRef } from "react";
import { ScrollableDiv } from "../global/ScrollableDiv";
import * as S from "./PersistentDrawer.styles";

const PersistentDrawer = ({
  position = "right",
  from_bottom = true,
  isOpen,
  header,
  footer,
  children
}) => {
  const lastDivRef = useRef();

  useEffect(() => {
    lastDivRef.current.scrollIntoView();
  }, [children]);
  return (
    <S.DrawerContainer open={isOpen} position={position}>
      <S.DrawerHeader>{header}</S.DrawerHeader>
      <S.DrawerContent>
        <ScrollableDiv>
          <S.DrawerContentWrapper from_bottom={from_bottom}>
            {children}
            <S.LastElement ref={lastDivRef} />
          </S.DrawerContentWrapper>
        </ScrollableDiv>
      </S.DrawerContent>

      <S.DrawerFooter>{footer}</S.DrawerFooter>
    </S.DrawerContainer>
  );
};

export default PersistentDrawer;
