import styled, { css } from "styled-components";

import {
  DiretctionLabelProps,
  SCheckboxProps,
  CheckboxVariantProps
} from "./types";
import {
  useGetCheckboxContainerStyles,
  useGetCheckboxStyles,
  useGetHiddenCheckboxStyles
} from "./hooks";

export const CheckboxContainer = styled("div")<SCheckboxProps>(({
  disabled,
  checked,
  variant
}) => {
  const { checkedColor, hoverColor, hoverBackground, hoverBorder } =
    useGetCheckboxContainerStyles(checked, disabled, variant);

  return css`
    display: inline;
    vertical-align: middle;
    position: relative;
    cursor: ${disabled ? "not-allowed" : "pointer"};
    outline: none;
    & svg {
      color: ${checkedColor};
      display: ${checked ? "inline" : "none"};
      cursor: ${disabled ? "not-allowed" : "pointer"};
    }

    &:hover div {
      background-color: ${hoverBackground};

      svg {
        color: ${hoverColor};
      }
      border: ${hoverBorder};
    }
  `;
});

export const HiddenCheckbox = styled("input").attrs({
  type: "checkbox"
})<CheckboxVariantProps>(({ disabled, variant }) => {
  const { outline } = useGetHiddenCheckboxStyles(variant);

  return css`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: 0px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    outline-offset: 0px !important;
    cursor: ${disabled ? "not-allowed" : "pointer"};
    &:focus {
      outline: ${outline} !important;
      box-sizing: content-box;
    }
  `;
});

export const StyledCheckbox = styled("div")<SCheckboxProps>(({
  checked,
  disabled,
  variant
}) => {
  const { backgroundColor, border: borderColor } = useGetCheckboxStyles(
    checked,
    disabled,
    variant
  );

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background-color: ${backgroundColor};
    border-radius: 4px;
    border: ${borderColor};
  `;
});

export const Container = styled.div<
  Pick<SCheckboxProps, "disabled" | "variant"> & DiretctionLabelProps
>`
  display: ${({ labelDirection }) =>
    labelDirection === "right" ? "flex" : "inline-flex"};
  align-items: center;
  height: 100%;
  flex-direction: ${({ labelDirection }) =>
    labelDirection === "right" ? undefined : "row-reverse"};

  label {
    margin-left: ${({ labelDirection }) =>
      labelDirection === "right" ? "8px" : undefined};
    margin-right: ${({ labelDirection }) =>
      labelDirection === "left" ? "8px" : undefined};
    margin-bottom: 1px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    text-align: start;
    outline: none;
  }
`;
