import { Box, Icon, Text } from "@arcadia/design-system";
import React, { FC } from "react";
import {
  CustomListElementProps,
  ListSelectorItem
} from "src/js/components/global/ListSelector";
import styled, { css } from "styled-components";
import { ModuleType } from "./BoardPicker";

export const StyledIcon = styled(Icon)<{ isActive: boolean }>`
  color: ${({ theme }) => theme.primaryColor[500]};
  ${({ isActive }) =>
    !isActive &&
    css`
      path,
      circle {
        display: none;
      }
    `}
`;

export const StyledText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledListSelectorItem = styled(ListSelectorItem)`
  padding: 16px;
  margin-bottom: 12px;
  span {
    display: block;
  }
`;

const BoardPickerCard: FC<CustomListElementProps<ModuleType>> = ({
  isActive,
  onClick,
  el
}) => {
  return (
    <StyledListSelectorItem onClick={onClick} isActive={isActive}>
      <Box width="100%" padding="0 12px" overflow="hidden">
        <StyledText>{el?.name}</StyledText>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <StyledIcon
          icon="circleCheck"
          width={24}
          height={24}
          initialViewbox
          isActive={isActive}
        />
      </Box>
    </StyledListSelectorItem>
  );
};

export default BoardPickerCard;
