import styled, { createGlobalStyle } from "styled-components";
import { ResourcePicker } from "../../ResourcePicker";
import { DotsMenu } from "../../DotsMenu";

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const InputPointContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const EvaluationText = styled.span`
  padding: 5px 10px;
  font-weight: 700;
  text-transform: capitalize;
`;

export const StudentFooterContainer = styled.div`
  margin: 20px -20px 0px;
  border-top: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
  padding: 20px 25px 0px;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.title};
  @media (max-width: 490px) {
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  align-self: flex-start;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 490px) {
    margin-top: 10px;
  }
`;

export const SubmitButton = styled.span`
  margin-left: 15px;
`;

export const IconClipContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};

  &:hover {
    cursor: pointer;
    border: solid 1px ${({ theme }) => theme.colors.primary2};
  }
`;

export const CustomResourcePicker = styled(ResourcePicker)`
  width: 300px;
  background: ${({ theme }) => theme.colors.grey[1000]};
  max-height: calc(70vh - 217px);
  @media screen and (max-width: 767px) {
    max-height: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    width: 100%;
  }
`;

export const CustomDotsMenu = styled(DotsMenu)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.grey[50]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[50]};
    color: ${({ theme }) => theme.colors.grey[1000]};
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const GlobalStyleAttachmentDotsMenu = createGlobalStyle`
  #text-editor-attachments-list {
    max-width: fit-content !important;
    border-radius: 4px !important;
    overflow: hidden !important;
    bottom: 48px !important;
    right:0px !important;
  }
`;
