import { getUrlParameterValue } from "src/js/modules/commonFunction";
import { fetchSpaceCustomization } from "src/js/repository/spacesRepository";
import { useStores } from "src/js/hooks";

export const SetCustomerTheme = () => {
  const {
    ThemeStore: { setSpaceCustomTheme, setCustomizationSettings }
  } = useStores();

  const spaceId = getUrlParameterValue(window.location.hash, "org");

  if (spaceId) {
    fetchSpaceCustomization({ spaceId }).then(data => {
      setCustomizationSettings(data);
      setSpaceCustomTheme(spaceId, data);
    });
  }

  return null;
};
