import { Palette, WrapperChatAvatarThemeOptions } from "@ds_themes/types";

export const createWrapperchatavatarTheme = ({
  general,
  blueGrey,
  primary
}: Palette): { wrapperchatavatar: WrapperChatAvatarThemeOptions } => {
  return {
    wrapperchatavatar: {
      background: general.background,
      text: general.contrastText,
      message: blueGrey[500],
      time: general.contrastText,
      active: {
        background: primary.main,
        text: primary.contrastText
      }
    }
  };
};
