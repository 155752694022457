import { colors } from "@arcadia/design-system";
import styled from "styled-components";
import { SSelectorWrap } from "./Selector.types";

export const SelectorWrap = styled.div<SSelectorWrap>`
  > div {
    border-radius: 12px;
    background-color: ${({ isMobile }) =>
      isMobile ? colors.grey[1000] : colors.coldGrey[900]};
    input:focus {
      outline: none;
    }
    label {
      font-family: "Twemoji Country Flags", "WeSchoolSans";
      padding: 16px;
      margin: 0;
    }
  }
`;
