import {
  getHumanReadablePathDescription,
  getIsTeacher,
  getSpaceInfo
} from "src/js/context/ViewTrackingProvider/ViewTrackingProvider.utils";
import { readLocalData } from "./storageFunction";

export const fetchLocationData = async () => {
  const { location } = window;
  // Remove query params and take only the prefix before the "?"
  // if we want more data about the query params we can parse the rest of the string if needed
  const pathname = location.hash.replace("#", "/").split("?")[0];
  const shouldUseTracking = readLocalData("consent");
  const platformLanguage = readLocalData("i18nextLng");

  const { description: pathDescription, params } =
    getHumanReadablePathDescription(pathname);

  const { group_id: groupId } = params || {};
  const { id: spaceId, slug, isDemo, trial } = await getSpaceInfo(params);
  const isTeacher = await getIsTeacher(Number(groupId));

  return {
    shouldUseTracking,
    platformLanguage,
    pathDescription,
    groupId,
    spaceId,
    spaceSlug: slug,
    isDemo,
    trial,
    isTeacher
  };
};
