import { useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { hasLiveAccess } from "src/js/pages/calendar/CalendarUtils";
import { CalendarEvent } from "../LiveButton.types";

export const useLiveEvents = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const {
    CalendarStore: { todayLive, timerMilliseconds, setNextEventTimer }
  } = useStores();

  const filterLiveEvents = () => {
    const onGoingLive = [];
    todayLive.forEach(live => {
      if (
        live.start &&
        hasLiveAccess({
          dateNow: new Date(Date.now()),
          startDate: new Date(live.start),
          endDate: new Date(live.end) || new Date(null)
        })
      ) {
        onGoingLive.push(live);
      }
    });
    setEvents(onGoingLive);
  };

  useEffect(() => {
    filterLiveEvents();
  }, [todayLive]);

  useEffect(() => {
    let timer;
    if (timerMilliseconds) {
      let anticipatedTimer = timerMilliseconds - 600000;
      anticipatedTimer = anticipatedTimer >= 0 ? anticipatedTimer : null;
      timer = setTimeout(() => {
        filterLiveEvents();
        setNextEventTimer();
      }, anticipatedTimer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [timerMilliseconds]);
  return { events };
};
