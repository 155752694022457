import { arrayMove } from "@dnd-kit/sortable";
import { makeAutoObservable, toJS } from "mobx";
import {
  addConstraint,
  createNewBoard,
  deleteBoard,
  editBoard,
  fetchBoards,
  fetchBoardsList,
  getConstraints,
  publishBoard,
  removeConstraint,
  reorderBoard,
  unpublishBoard
} from "src/js/repository/boardRepository";
import { _trackEvent } from "../modules/analyticsFunction";
import { structuredClone } from "../modules/commonFunction";

export const sanitizeBoardData = data =>
  data.map(
    ({
      activities_count,
      activitiesCount,
      available_score,
      availableScore,
      board_element_count,
      boardElementCount,
      board_element_draft_count,
      boardElementDraftCount,
      color,
      completed_board_element_count,
      completedElementCount,
      id,
      is_locked,
      isLocked,
      is_mandatory = false,
      isMandatory = false,
      has_mandatory_order = false,
      hasMandatoryOrder = false,
      is_milestone,
      isMilestone,
      is_open,
      isOpen,
      is_published = false,
      isPublished = false,
      name,
      position,
      prerequisites_count,
      prerequisitesCount,
      milestone_message,
      milestoneMessage,
      board
    }) => {
      return {
        id,
        name,
        isPublished: is_published || isPublished,
        color: parseInt(color, 10) || 6,
        isOpen: is_open || isOpen,
        isMandatory: is_mandatory || isMandatory,
        isAIGenerated: board?.aiGenerated,
        isMilestone: is_milestone || isMilestone,
        isLocked: is_locked || isLocked,
        hasMandatoryOrder:
          board?.has_mandatory_order ||
          board?.hasMandatoryOrder ||
          hasMandatoryOrder ||
          has_mandatory_order,
        activitiesCount: activities_count || activitiesCount,
        boardElementCount: board_element_count || boardElementCount,
        boardElementDraftCount:
          board_element_draft_count || boardElementDraftCount,
        completedBoardElementCount:
          completed_board_element_count || completedElementCount,
        suggestionCount: board_element_draft_count || boardElementDraftCount,
        prerequisitesCount: prerequisites_count || prerequisitesCount,
        position,
        availableScore: available_score || availableScore,
        milestoneMessage: milestone_message || milestoneMessage,
        ...board
      };
    }
  );

const sortBoardsByPosition = (boardsArray = []) => {
  const orderedBoards = boardsArray.sort((a, b) =>
    a.position > b.position ? 1 : -1
  );
  return orderedBoards;
};

const boardPositionIsOverEndOfArray = ({ boardsArray = [], boardPosition }) => {
  if (boardsArray.length < 1) return false;
  const lastPosition = boardsArray[boardsArray.length - 1].position;
  return lastPosition < boardPosition;
};

export default class BoardsStore {
  $boardsList = {};

  $activeBoardId = null;

  $activeBoardStore = null;

  $activeBoardElements = [];

  $constraints = {};

  $uploadingProgressOfBoardElement = {};

  $listOfUploadingElementIndexes = [];

  $uploadingErrorElements = {};

  // I'm using this map to avoid the update of all the different objects that stores the modules and the modules elements
  // and detach the comment number in this value -> key map (boardElementId -> commentNumber)
  $boardElementCommentNumberMap = new Map();

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setBoardElementCommentNumberMap = (boardElementId, number) => {
    this.$boardElementCommentNumberMap.set(boardElementId, number);
  };

  get boardElementCommentNumberMap() {
    return this.$boardElementCommentNumberMap;
  }

  fetchBoardsList = ({ groupId, isPublished }) => {
    if (!groupId) {
      return null;
    }

    this.$boardsList[groupId] = {
      status: "pending"
    };

    return fetchBoards({ groupId, isPublished })
      .then(data => {
        this.$boardsList[groupId].results = sanitizeBoardData(data?.results);
        this.$boardsList[groupId].status = "success";
        this.$boardsList[groupId].hasNext = data?.hasNext;
        return this.getBoardsLists;
      })
      .catch(() => {
        this.$boardsList[groupId].status = "error";
      });
  };

  fetchBoardsListNextPage = ({ groupId, isPublished }) => {
    if (!this.$boardsList[groupId]) {
      // Non dovrebbe esser necessario
      this.$boardsList[groupId] = {
        status: "pending"
      };
    }

    const currentData = toJS(this.$boardsList[groupId].results);

    return fetchBoards({
      groupId,
      offset: currentData.length,
      isPublished
    })
      .then(data => {
        this.$boardsList[groupId].results = [
          ...this.$boardsList[groupId].results,
          ...sanitizeBoardData(data?.results)
        ];
        this.$boardsList[groupId].hasNext = data?.hasNext;
        this.$boardsList[groupId].status = "success";
      })
      .catch(() => {
        this.$boardsList[groupId].status = "error";
      });
  };

  get getBoardsLists() {
    return this.$boardsList;
  }

  getSingleBoard({ groupId, boardId }) {
    if (!groupId || !boardId) return null;
    return this.$boardsList[groupId]?.results?.find(el => el.id === boardId);
  }

  getSingleBoardConstraints({ boardId }) {
    if (!boardId) return [];
    return this.$constraints[boardId] || [];
  }

  moveBoardElement = async (newIndex, oldIndex, groupId) => {
    if (!this.$boardsList[groupId]) {
      return null;
    }

    const targetBoardList = this.$boardsList[groupId].results;
    const boardId = targetBoardList[oldIndex]?.id;

    const targetListFiltered = targetBoardList.filter(
      board => board.id !== boardId
    );

    this.$boardsList[groupId].results = arrayMove(
      targetBoardList,
      oldIndex,
      newIndex
    );

    // If you move the board to the top of the list, the prevId is fixed to null
    // otherwise is the newIndex - 1 id of the list without the moved element
    const prevId = newIndex === 0 ? null : targetListFiltered[newIndex - 1].id;

    // If you move the board to the bottom of the list, the nextId is fixed to null
    // otherwise is the same new index id of the list without the moved element
    const nextId =
      newIndex === targetListFiltered.length
        ? null
        : targetListFiltered[newIndex].id;

    return reorderBoard({ boardId, prevId, nextId }).then(({ position }) => {
      this.$boardsList[groupId].results[newIndex].position = position;
    });
  };

  addNewBoard(groupId, lessonName, opt = { draft: false, editMode: false }) {
    const boardPayload = {
      drafts_filtered: [],
      board_elements_filtered: [],
      mode: opt.editMode ? "EDIT " : "VIEW",
      name: lessonName,
      rating: 0
    };

    return createNewBoard({ groupId, boardPayload }).then(async data => {
      this.$boardsList[groupId].results.unshift(data);

      _trackEvent("Lessons", "LessonsNew", new Date().getTime());
      if (!opt.draft) {
        await publishBoard({ boardId: data.id });
      }

      // Refetch list
      this.fetchBoardsList(groupId);

      return data;
    });
  }

  editBoardField = ({ groupId, boardId, value, field }) => {
    const board = structuredClone(this.getSingleBoard({ groupId, boardId }));
    const oldValue = board[field];
    board[field] = value;

    // create a temporary object that gives us the flexibility
    // to pass any field to the repository function
    const payloadBody = {};
    payloadBody[field] = value;

    // Optimistic update
    this.$boardsList[groupId].results = this.$boardsList[groupId].results.map(
      el => (el.id === boardId ? board : el)
    );

    return editBoard({ boardId, ...payloadBody })
      .then(
        ({ isOpen, isMandatory, isMilestone, isLocked, hasMandatoryOrder }) => {
          board.isOpen = isOpen;
          board.isMandatory = isMandatory;
          board.isMilestone = isMilestone;
          board.isLocked = isLocked;
          board.hasMandatoryOrder = hasMandatoryOrder;
          this.$boardsList[groupId].results = this.$boardsList[
            groupId
          ].results.map(el => (el.id === boardId ? board : el));
        }
      )
      .catch(error => {
        board[field] = oldValue;
        this.$boardsList[groupId].results = this.$boardsList[
          groupId
        ].results.map(el => (el.id === boardId ? board : el));
        throw error;
      });
  };

  editBoardFields = ({ boardId, groupId, boardData }) => {
    const board = this.getSingleBoard({ groupId, boardId });
    return editBoard({ boardId, ...boardData })
      .then(
        ({
          color,
          isOpen,
          isMandatory,
          isMilestone,
          isLocked,
          milestoneMessage,
          name
        }) => {
          board.color = color;
          board.isOpen = isOpen;
          board.isMandatory = isMandatory;
          board.hasMandatoryOrder = boardData.hasMandatoryOrder;
          board.isMilestone = isMilestone;
          board.isLocked = isLocked;
          board.milestoneMessage = milestoneMessage;
          board.name = name;
          return {
            color,
            isOpen,
            isMandatory,
            isMilestone,
            isLocked,
            milestoneMessage,
            name
          };
        }
      )
      .catch(error => {
        throw error;
      });
  };

  togglePublishBoard = ({ groupId, boardId, publish }) => {
    const board = this.getSingleBoard({ groupId, boardId });
    board.isPublished = publish;
    if (publish) {
      return publishBoard({ boardId })
        .then(() => {
          _trackEvent("Lessons", "LessonPublished", new Date().getTime());
        })
        .catch(error => {
          board.isPublished = !publish;
          throw error;
        });
    }
    return unpublishBoard({ boardId })
      .then(() =>
        _trackEvent("Lessons", "LessonUnpublished", new Date().getTime())
      )
      .catch(error => {
        board.isPublished = !publish;
        throw error;
      });
  };

  removeBoard = ({ boardId, groupId }) => {
    return deleteBoard({ boardId }).then(() => {
      this.$boardsList[groupId].results = this.$boardsList[
        groupId
      ]?.results.filter(el => el.id !== boardId);
    });
  };

  get getActiveBoardId() {
    return this.$activeBoardId;
  }

  setActiveBoardId = boardId => {
    this.$activeBoardId = boardId;
  };

  get activeBoardStore() {
    return this.$activeBoardStore;
  }

  setActiveBoardStore = board => {
    const completedBoardElementCount = board.board_elements.filter(
      el => el.is_completed === true
    ).length;

    const deepCopyBoard = structuredClone(board);

    this.$activeBoardStore = {
      ...deepCopyBoard,
      board_element_count: board.board_elements.length + 1,
      completed_board_element_count: completedBoardElementCount,
      constraints: this.$constraints[board.id],
      constrainted_boards: this.$constraints
    };
  };

  setActiveBoard = ({ board, groupId }) => {
    if (board) {
      this.setActiveBoardId(board.id);
    }
    if (
      !groupId ||
      this.getSingleBoard({
        boardId: board?.id,
        groupId
      })
    ) {
      return;
    }
    // calculate single board completed elements
    const boardWithElementsCounted = board;
    if (boardWithElementsCounted.board_elements) {
      boardWithElementsCounted.board_element_count =
        board.board_elements.length;
      boardWithElementsCounted.completed_board_element_count =
        board.board_elements.filter(el => el.is_completed === true).length;
    }
    if (!this.$boardsList[groupId]) {
      this.$boardsList[groupId] = {
        results: sanitizeBoardData([boardWithElementsCounted])
      };
    } else {
      this.$boardsList[groupId].results = [
        ...this.$boardsList[groupId].results,
        ...sanitizeBoardData([boardWithElementsCounted])
      ];
    }
  };

  get activeBoardElements() {
    return this.$activeBoardElements;
  }

  setActiveBoardElements = ({ board, boardElements }) => {
    if (!board) return;
    this.$activeBoardElements = boardElements;
  };

  updateBoardElements = (board, updatedBoardElements) => {
    if (!board) return;
    this.setActiveBoardElements({ board, boardElements: updatedBoardElements });
    this.setActiveBoardStore({
      ...board,
      board_elements: updatedBoardElements
    });
  };

  // Better than updateBoardElements (the one above) if we need just to update a single element

  updateBoardElementViewById = (board, boardElementId) => {
    if (!board) return;
    const boardElementsCopy = structuredClone(board.board_elements);
    boardElementsCopy.find(el => el.id === boardElementId).is_completed = true;

    this.setActiveBoardElements({ board, boardElements: boardElementsCopy });
    this.setActiveBoardStore({
      ...board,
      board_elements: boardElementsCopy
    });
  };

  fetchBoardsConstraints = ({ boardId }) => {
    getConstraints({ boardId }).then(data => {
      this.$constraints[boardId] = data?.constraints;
    });
  };

  addBoardConstraint = ({ groupId, boardId, constraint }) => {
    return addConstraint({ boardId, constraintId: constraint.id })
      .then(() => {
        this.$constraints[boardId] = [
          ...(this.$constraints[boardId] || []),
          constraint
        ];

        const board = this.getSingleBoard({ groupId, boardId });
        board.prerequisitesCount += 1;
      })
      .catch(error => {
        const status = error.response?.status;

        if (status === 400) {
          // Circluar dependency

          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ error: "circluar_dependency" });
        }

        throw error;
      });
  };

  removeBoardConstraint = ({ groupId, boardId, constraintId }) => {
    return removeConstraint({ boardId, constraintId }).then(() => {
      this.$constraints[boardId] = this.$constraints[boardId]?.filter(
        el => el.id !== constraintId
      );

      const board = this.getSingleBoard({ groupId, boardId });

      if (board.prerequisitesCount > 0) {
        board.prerequisitesCount -= 1;
      }
    });
  };

  realtimeAddBoard = ({ board, group, roles }) => {
    const groupId = group?.id;
    const isTeacher = roles?.includes("ROLE_TEACHER");
    if (!isTeacher) return;
    // only teachers see the new board on top of the list
    if (!this.$boardsList[groupId]) {
      this.$boardsList[groupId] = {
        results: sanitizeBoardData([board])
      };
      return;
    }
    this.$boardsList[groupId].results = [
      ...sanitizeBoardData([board]),
      ...this.$boardsList[groupId].results
    ];
  };

  realtimeBoardEdit = ({ board }) => {
    const groupId = board?.group?.id;
    const currentBoard = this.getSingleBoard({ groupId, boardId: board?.id });
    if (currentBoard) {
      const {
        name,
        color,
        is_open,
        is_mandatory,
        is_milestone,
        milestone_message,
        position
      } = board;
      currentBoard.name = name;
      currentBoard.color = color;
      currentBoard.isOpen = is_open;
      currentBoard.isMandatory = is_mandatory;
      currentBoard.isMilestone = is_milestone;
      currentBoard.milestoneMessage = milestone_message;
      if (position !== currentBoard.position) {
        currentBoard.position = position;
        this.$boardsList[groupId].results = sortBoardsByPosition(
          this.$boardsList[groupId].results
        );
      }
    }
  };

  realtimePublishBoard = ({ board }) => {
    const groupId = board?.group?.id;
    if (!groupId) return;
    if (!this.$boardsList[groupId]) {
      this.$boardsList[groupId] = {
        results: sanitizeBoardData([board])
      };
      return;
    }
    const currentBoard = this.getSingleBoard({ groupId, boardId: board?.id });
    if (currentBoard) {
      // users that already have the board in the list (like other teachers)
      // need just to update it
      currentBoard.isPublished = true;
    } else {
      // users that do not see the board in the list (like students)
      // need the new board in the array
      fetchBoardsList({ groupId, boards: [board?.id] })
        .then(data => {
          if (
            data &&
            data.boards &&
            data.boards.length === 1 &&
            !boardPositionIsOverEndOfArray({
              boardsArray: this.$boardsList[groupId].results,
              boardPosition: data.boards[0].position
            })
          ) {
            this.$boardsList[groupId].results = sortBoardsByPosition([
              ...this.$boardsList[groupId].results,
              ...sanitizeBoardData(data.boards)
            ]);
          }
        })
        .catch(() => {});
    }
  };

  realtimeUnpublishBoard = ({ board, group, roles }) => {
    if (!group) return;
    const groupId = group?.id;
    const isTeacher = roles?.includes("ROLE_TEACHER");
    // student user sees an unpublish event as a remove of that board
    if (!isTeacher) {
      this.$boardsList[groupId].results = this.$boardsList[
        groupId
      ]?.results.filter(el => el.id !== board?.id);
      return;
    }
    // teacher user need just an update of the board state, if present in their view
    const currentBoard = this.getSingleBoard({ groupId, boardId: board?.id });
    if (currentBoard) {
      currentBoard.isPublished = false;
    }
  };

  realtimeDeleteBoard = ({ boardId, groupId }) => {
    this.$boardsList[groupId].results = this.$boardsList[
      groupId
    ]?.results.filter(el => el.id !== boardId);
  };

  realtimeBoardCompleted = ({ boardId, groupId }) => {
    const board = this.getSingleBoard({ groupId, boardId });
    board.completedBoardElementCount = board.boardElementCount;
  };

  setUploadingProgressOfBoardElement = (id, progress) => {
    this.$uploadingProgressOfBoardElement = {
      [id]: { progress }
    };
  };

  resetUploadingProgress = () => {
    this.$uploadingProgressOfBoardElement = {};
  };

  get uploadingProgressOfBoardElement() {
    return this.$uploadingProgressOfBoardElement;
  }

  setUploadingElementIndexes = elementIndex => {
    const newElementIndexes = [
      ...this.$listOfUploadingElementIndexes,
      elementIndex
    ];
    this.$listOfUploadingElementIndexes = newElementIndexes;
  };

  filterUploadingElementIndexes = elementToFilter => {
    const newElementIndexes = this.$listOfUploadingElementIndexes.filter(
      item => item !== elementToFilter
    );
    this.$listOfUploadingElementIndexes = newElementIndexes;
  };

  resetUploadingElementIndexes = () => {
    this.$listOfUploadingElementIndexes = [];
  };

  get uploadingElementIndexes() {
    return this.$listOfUploadingElementIndexes;
  }

  setErrorInUploadingElement = (id, hasError, errorMessage) => {
    this.$uploadingErrorElements = {
      [id]: { hasError, errorMessage }
    };
    this.filterUploadingElementIndexes(id);
    this.setUploadingProgressOfBoardElement(id, null);
  };

  get errorUploadingElements() {
    return this.$uploadingErrorElements;
  }
}
