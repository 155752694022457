import { Palette } from "@ds_themes/types";

export const createDatepickerTheme = ({ coldGrey, blueGrey }: Palette) => {
  return {
    datepicker: {
      background: coldGrey[900],
      text: {
        color: blueGrey[300]
      }
    }
  };
};
