import { useEffect } from "react";

const usePreventBodyScroll = () => {
  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);
};

export default usePreventBodyScroll;
