import { Avatar, DoodleLoader, Text } from "@arcadia/design-system";
import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./SearchUsersDropdown.styles";
import {
  SearchUsersDropdownProps,
  SearchUsersDropdownRef
} from "./SearchUsersDropdown.types";

const SearchUsersDropdown = forwardRef<
  SearchUsersDropdownRef,
  SearchUsersDropdownProps
>(
  (
    {
      className,
      onClickUser = () => {},
      spaceUsers = [],
      hasNext = false,
      showNext = () => {},
      textQuery
    }: SearchUsersDropdownProps,
    ref: Ref<SearchUsersDropdownRef>
  ) => {
    const { translate } = useTranslation();
    const { whiteTheme } = useTheme();

    const [indexOnFocus, setIndexOnFocus] = useState<number>(0);

    const optionsRef = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
      setIndexOnFocus(0);
    }, [spaceUsers]);

    const scrollToFocusedOption = (index: number) => {
      optionsRef.current[index]?.scrollIntoView({
        block: "nearest",
        inline: "nearest"
      });
    };

    useImperativeHandle(ref, () => ({
      focusPrevOption: () => {
        const index =
          indexOnFocus - 1 >= 0 ? indexOnFocus - 1 : spaceUsers.length - 1;
        setIndexOnFocus(index);
        scrollToFocusedOption(index);
      },
      focusNextOption: () => {
        const index =
          indexOnFocus === spaceUsers.length - 1 || indexOnFocus === -1
            ? 0
            : indexOnFocus + 1;
        setIndexOnFocus(index);
        scrollToFocusedOption(index);
      },
      clickFocusedOption: () => {
        optionsRef.current[indexOnFocus]?.click();
      }
    }));

    if (spaceUsers.length === 0) {
      return (
        <S.SearchUsersDropdownWrapper className={className}>
          <S.SearchUsersDropdownEmpty data-testid="search-users-dropdown-empty">
            <Text type="formTitle">
              {translate("search_users_dropdown_empty")}{" "}
              <mark>{textQuery}</mark>
            </Text>
          </S.SearchUsersDropdownEmpty>
        </S.SearchUsersDropdownWrapper>
      );
    }

    return (
      <S.SearchUsersDropdownWrapper
        data-testid="search-users-dropdown"
        className={className}
      >
        <S.ScrollableWrapper>
          <InfiniteScroll
            scrollableTarget="dropdown-multiple-list"
            dataLength={spaceUsers.length}
            next={showNext}
            hasMore={hasNext}
            loader={<DoodleLoader theme={whiteTheme} isMini />}
          >
            {spaceUsers.map((user, index) => {
              const label = `${user.name} ${user.surname}`;
              return (
                <S.Option
                  data-testid={`spd-option-${user.uuid}`}
                  key={`spd-option-${user.uuid}`}
                  role="option"
                  aria-label={label}
                  data-selected={indexOnFocus === index}
                  isSelected={indexOnFocus === index}
                  ref={el => {
                    optionsRef.current[index] = el;
                  }}
                  onClick={() => onClickUser(user)}
                  onMouseEnter={() => {
                    setIndexOnFocus(index);
                  }}
                >
                  <Avatar
                    url={user?.avatar?.small}
                    size="s"
                    theme={whiteTheme}
                    rounded
                  />
                  <Text type="cta">{label}</Text>
                </S.Option>
              );
            })}
          </InfiniteScroll>
        </S.ScrollableWrapper>
      </S.SearchUsersDropdownWrapper>
    );
  }
);

SearchUsersDropdown.displayName = "SearchUsersDropdown";

export default SearchUsersDropdown;
