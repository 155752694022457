import { Box, IconName } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTranslation } from "src/js/translation";
import * as S from "./HomeHeaderStats.styles";

type StatItemProps = {
  title: string;
  icon: IconName;
  count: number;
};

const StatItem: FC<StatItemProps> = ({ title, icon, count }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <S.StatItemIcon icon={icon} width="16px" height="16px" />
      <S.StatItemTitle type="formSubtitle">
        <span id="count">{count}</span> <span id="title">{title}</span>
      </S.StatItemTitle>
    </Box>
  );
};

type HomeHeaderStatsProps = {
  groupsCount: number;
  notificationsCount?: number;
  participantsCount: number;
  excludeNotifications?: boolean;
  isTemporary?: boolean;
};

const HomeHeaderStats: FC<HomeHeaderStatsProps> = ({
  groupsCount,
  notificationsCount,
  participantsCount,
  excludeNotifications,
  isTemporary
}) => {
  const { translate } = useTranslation();

  return (
    <S.Wrapper>
      <StatItem
        count={groupsCount}
        title={translate("stats_groups", { count: groupsCount })}
        icon="gridView"
      />
      {!isTemporary && (
        <StatItem
          count={participantsCount}
          title={translate("stats_participants", { count: participantsCount })}
          icon="user"
        />
      )}
      {excludeNotifications ? null : (
        <StatItem
          count={notificationsCount}
          title={translate("stats_notifications", {
            count: notificationsCount
          })}
          icon="notificationUnmuted"
        />
      )}
    </S.Wrapper>
  );
};

export default HomeHeaderStats;
