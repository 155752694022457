import { ChipThemeOptions, Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

export const createChipTheme = ({
  primary
}: Palette): { chip: ChipThemeOptions } => {
  return {
    chip: {
      background: primary.lighter,
      color: getHighContrastColor(primary.lighter)
    }
  };
};
