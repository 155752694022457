import styled from "styled-components";
import colors from "@ds_themes/colors";

import { SearchBarProps } from "./types";

export const SearchBar = styled.input<{
  variant: SearchBarProps["variant"];
  startAdornment: boolean;
}>`
  width: 100%;
  height: ${({ variant }) => (variant === "default" ? "50px" : "30px")};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: ${({ startAdornment }) =>
    startAdornment ? "13px 50px 13px 40px" : "13px 50px 13px 24px"};
  border-radius: 50px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.searchbar.background};
  border: ${({ theme }) => theme.searchbar.border};
  color: ${({ theme }) => theme.searchbar.text};
  font-style: ${({ theme }) => theme.searchbar.font.style};
  font-weight: ${({ theme }) => theme.searchbar.font.weight};
  font-size: ${({ theme }) => theme.searchbar.font.size};
  line-height: ${({ theme }) => theme.searchbar.font.style};

  &::placeholder {
    color: ${({ theme, variant }) =>
      variant === "default"
        ? theme.searchbar.textPlaceholder
        : colors.violet[500]};
    opacity: ${({ variant }) => (variant === "default" ? undefined : "0.5")};
  }
  &:focus {
    outline: ${({ theme }) => theme.searchbar.focus.outline};
    outline-color: transparent;
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.searchbar.text};

  [type="submit"] {
    position: absolute;
    right: 15px;
    color: ${({ theme }) => theme.searchbar.text};
    cursor: pointer;
  }

  &:focus-within::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: ${({ theme }) => theme.searchbar.focus.outline};
    border-radius: 50px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 40px;
  right: 15px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export const SearchButtonThinner = styled.button`
  display: flex;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: ${colors.violet[500]};
`;

export const EndAdornmentWrapper = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
`;

export const StartAdornmentWrapper = styled.div`
  position: absolute;
  left: 24px;
  display: flex;
`;
