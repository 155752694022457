import { Text } from "@arcadia/design-system";
import { Container } from "react-awesome-styled-grid";
import { FilterBar } from "src/js/components/FilterBar";
import { TagsCarousel } from "src/js/components/FilterBar/FilterBar.styles";
import styled from "styled-components";

export const WarningDataText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const StyledContainer = styled(Container)<{ padding?: string }>`
  padding: ${({ padding }) => padding || "0"};
`;

export const StyledFilterBar = styled(FilterBar)`
  @media (max-width: 767px) {
    margin: 0px;
    ${TagsCarousel} {
      margin-top: 0;
      padding: 16px;
      > div {
        max-width: 200px;
        svg {
          width: 24px;
        }
        > span {
          width: max-content;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
    }
  }
`;
