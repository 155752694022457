import { useCallback, useEffect, useState } from "react";
import { FetchNotificationsParams } from "src/js/repository/types";
import {
  fetchNotifications as getNotifications,
  markGroupNotificationsRead,
  markNotificationRead,
  markNotificationUnread,
  markSpaceNotificationsRead
} from "src/js/repository/notificationRepository";
import { NotificationModel } from "src/js/types/models/Notification";

type UseNotificationFetcherProps = {
  params: Omit<FetchNotificationsParams, "limit" | "offset">;
  onSuccess?: (notifications: NotificationModel[]) => void;
  onError?: () => void;
};

export const useNotificationFetcher = ({
  params,
  onError,
  onSuccess
}: UseNotificationFetcherProps) => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(false);

  const deps = [
    params.groupId,
    params.spaceId,
    params.read,
    params.type,
    params.area
  ];

  const fetchNotifications = useCallback(
    async (fetchParams: FetchNotificationsParams, append: boolean = false) => {
      setIsLoading(!append);
      setIsLoadingNextPage(append);
      try {
        const notificationResponse = await getNotifications({
          ...fetchParams,
          limit: 10
        });
        if (onSuccess) onSuccess(notificationResponse.results);
        setNotifications(prevNotifications =>
          append
            ? [...prevNotifications, ...notificationResponse.results].sort(
                (a, b) => b.updatedAt - a.updatedAt
              )
            : notificationResponse.results.sort(
                (a, b) => b.updatedAt - a.updatedAt
              )
        );
        setHasNext(notificationResponse.hasNext);
      } catch (error) {
        if (onError) onError();
        console.error("Failed to fetch notifications:", error);
      } finally {
        setIsLoading(false);
        setIsLoadingNextPage(false);
      }
    },
    [onSuccess]
  );

  const fetchNext = useCallback(() => {
    if (hasNext && !isLoadingNextPage) {
      fetchNotifications({ ...params, offset: notifications.length }, true);
    }
  }, [
    fetchNotifications,
    hasNext,
    isLoadingNextPage,
    notifications.length,
    ...deps
  ]);

  const markAsRead = useCallback(
    async (notificationId: string) => {
      try {
        await markNotificationRead({ notificationId });
        setNotifications(prevNotifications =>
          prevNotifications.map(notification =>
            notification.id === notificationId
              ? { ...notification, isRead: true }
              : notification
          )
        );
      } catch {
        console.error("Failed to mark notification as read");
      }
    },
    [notifications]
  );

  const markAsUnread = useCallback(
    async (notificationId: string) => {
      try {
        await markNotificationUnread({ notificationId });
        setNotifications(prevNotifications =>
          prevNotifications.map(notification =>
            notification.id === notificationId
              ? { ...notification, isRead: false }
              : notification
          )
        );
      } catch {
        console.error("Failed to mark notification as unread");
      }
    },
    [setNotifications]
  );

  const markGroupAsRead = useCallback(
    async (groupId: number) => {
      try {
        await markGroupNotificationsRead({ groupId });
        setNotifications(prevNotifications =>
          prevNotifications.map(notification =>
            notification.group.id === groupId
              ? { ...notification, isRead: true }
              : notification
          )
        );
      } catch {
        console.error("Failed to mark all group notifications as read");
      }
    },
    [setNotifications]
  );

  const markSpaceAsRead = useCallback(
    async (spaceId: string) => {
      try {
        await markSpaceNotificationsRead({ spaceId });
        setNotifications(prevNotifications =>
          prevNotifications.map(notification => ({
            ...notification,
            isRead: true
          }))
        );
      } catch {
        console.error("Failed to mark all space notifications as read");
      }
    },
    [setNotifications]
  );

  useEffect(() => {
    if (!params.groupId && !params.spaceId) return;
    fetchNotifications(params);
  }, [fetchNotifications, ...deps]);

  return {
    notifications,
    hasNext,
    isLoading,
    isLoadingNextPage,
    fetchNext,
    markAsRead,
    markAsUnread,
    markGroupAsRead,
    markSpaceAsRead
  };
};
