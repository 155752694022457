import { ContentSwitcherThemeOptions, Palette } from "@ds_themes/types";
import { decreaseBrightness, getHighContrastColor } from "@ds_themes/utils";

export const createContentSwitcherTheme = ({
  primary,
  grey,
  coldGrey,
  general,
  blueGrey
}: Palette): { contentswitcher: ContentSwitcherThemeOptions } => {
  return {
    contentswitcher: {
      wrapper: {
        background: general.background,
        border: grey[800]
      },
      tab: {
        background: general.background,
        backgroundSquared: general.background,
        color: general.contrastText,
        active: {
          background: getHighContrastColor(
            primary.main,
            primary.dark,
            primary.main
          ),
          color: primary.contrastText,
          disabled: getHighContrastColor(
            primary.main,
            decreaseBrightness(primary.main, 5),
            primary.light
          )
        },
        hover: {
          background: coldGrey[900]
        },
        disabled: {
          color: blueGrey[500]
        },
        focus: {
          color: primary.light
        }
      }
    }
  };
};
