import {
  createComment as createCommentService,
  deleteComment as deleteCommentService,
  updateComment as updateCommentService,
  updateNestedComment as updateNestedCommentService,
  createNestedComment as createNestedCommentService,
  deleteNestedComment as deleteNestedCommentService,
  fetchComments
} from "src/js/repository/commentRepository";
import {
  BoardElementComment,
  NestedComment,
  PostComment
} from "src/js/types/models/Comment";
import { useInfiniteScrollFetcher, useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { Reaction } from "src/js/types/models/Reaction";
import { useTranslation } from "src/js/translation";
import { COMMENT_CONTEXT, CommentFetchParams } from "../CommentList.types";
import { updateReactionList } from "../../reactions/Reactions.utils";

const useComments = ({ parentId, limit, context }: CommentFetchParams) => {
  const {
    PostCommentStore: {
      getCommentListById,
      setCommentList: setCommentListStore
    },
    BoardsStore: { setBoardElementCommentNumberMap }
  } = useStores();

  const { commentList, hasNext } = getCommentListById(parentId);

  const setCommentList = (
    newComments: PostComment[] | BoardElementComment[],
    hasMoreResults?: boolean
  ) => {
    setCommentListStore(parentId, newComments, hasMoreResults);
  };

  // Needed to handle paginations outside the infinite scroll fetcher
  let hasNextAux = false;
  const { fetch, isLoading, fetchNextPage } = useInfiniteScrollFetcher<
    PostComment[] | BoardElementComment[],
    Omit<CommentFetchParams, "shouldFetch">
  >(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ page: _, ...params }) => {
      const res = await fetchComments({
        parentId,
        limit: params.limit,
        offset: params.offset,
        context
      });
      hasNextAux = res.hasNext;

      if ("totalComments" in res && context === COMMENT_CONTEXT.MODULES) {
        setBoardElementCommentNumberMap(parentId, res.totalComments);
      }
      return res.results;
    },
    {
      onSuccess: newComments => {
        setCommentList(
          [...commentList, ...newComments] as
            | PostComment[]
            | BoardElementComment[],
          hasNextAux
        );
        hasNextAux = false;
      },
      limit: limit || 10,
      lazy: true
    }
  );
  const { translate } = useTranslation();

  const nextPage = () => {
    if (hasNext) {
      fetchNextPage({ limit, offset: commentList.length, parentId, context });
    }
  };

  const createComment = async (text: string) => {
    return createCommentService({ parentId, text, context }).then(
      newComment => {
        const latestCommentList = getCommentListById(parentId).commentList;

        const newCommentList = [newComment, ...latestCommentList] as
          | PostComment[]
          | BoardElementComment[];

        setCommentList(newCommentList, hasNext);
      }
    );
  };
  const deleteComment = async (comment: PostComment | BoardElementComment) => {
    return deleteCommentService({ comment })
      .then(() => {
        showToastSuccess({ str: translate("delete_comment_success") });

        const latestCommentList = getCommentListById(parentId).commentList;
        const newList = latestCommentList.filter(c => c.id !== comment.id);

        const newCommentList = [...newList] as
          | PostComment[]
          | BoardElementComment[];

        setCommentList(newCommentList, hasNext);

        if (!newList.length) {
          nextPage();
        }
      })
      .catch(e => {
        showToastError({
          str: extractErrorMessage(e, translate("general_error_retry"))
        });
      });
  };
  const updateComment = async (
    comment: PostComment | BoardElementComment,
    newText: string
  ) => {
    return updateCommentService({
      comment,
      text: newText
    })
      .then(() => {
        const latestCommentList = getCommentListById(parentId).commentList;
        const indexToUpdate = latestCommentList.findIndex(
          (c: PostComment | BoardElementComment) => c.id === comment.id
        );
        if (indexToUpdate < 0) return;

        const updatedCommentList = [
          ...latestCommentList.slice(0, indexToUpdate),
          { ...comment, text: newText },
          ...latestCommentList.slice(indexToUpdate + 1)
        ] as PostComment[] | BoardElementComment[];

        setCommentList(updatedCommentList, hasNext);
      })
      .catch(e => {
        showToastError({
          str: extractErrorMessage(e, translate("general_error_retry"))
        });
      });
  };

  const updateCommentReactionList = async (
    commentId: number,
    newReaction?: Reaction,
    nestedCommentId?: number
  ) => {
    const latestCommentList = getCommentListById(parentId).commentList;

    const indexToUpdate = latestCommentList.findIndex(
      (c: PostComment | BoardElementComment) => c.id === commentId
    );
    if (indexToUpdate < 0) return;

    let nestedIndexToUpdate: number = null;

    const { reactions, nestedComments } = latestCommentList[indexToUpdate];

    if (nestedCommentId) {
      nestedIndexToUpdate = nestedComments.findIndex(
        nC => nC.id === nestedCommentId
      );
    }

    if (nestedIndexToUpdate < 0) return;

    const newReactions = updateReactionList({
      newReaction,
      oldReactions:
        nestedIndexToUpdate !== null
          ? nestedComments[nestedIndexToUpdate].reactions
          : reactions
    });

    // Create a new comment obj with the updated reactions
    const commentToUpdate = {
      ...latestCommentList[indexToUpdate],
      reactions: nestedCommentId
        ? latestCommentList[indexToUpdate].reactions
        : newReactions,
      nestedComments: nestedCommentId
        ? latestCommentList[indexToUpdate].nestedComments.map((nC, i) =>
            i === nestedIndexToUpdate ? { ...nC, reactions: newReactions } : nC
          )
        : latestCommentList[indexToUpdate].nestedComments
    };

    // Create a new comment list with the updated comment
    const newCommentList: PostComment[] | BoardElementComment[] = [
      ...latestCommentList.slice(0, indexToUpdate),
      commentToUpdate,
      ...latestCommentList.slice(indexToUpdate + 1)
    ] as PostComment[] | BoardElementComment[];

    setCommentList(newCommentList, hasNext);
  };
  const updateNestedComment = async (
    nestedComment: NestedComment,
    newText: string
  ) => {
    return updateNestedCommentService({
      parentId: nestedComment.parentCommentId,
      nestedCommentId: nestedComment.id,
      text: newText,
      context
    }).then(nestedCommentRes => {
      const latestCommentList = getCommentListById(parentId).commentList;

      const indexToUpdate = latestCommentList.findIndex(
        (c: PostComment | BoardElementComment) =>
          c.id === nestedCommentRes.parentCommentId
      );
      if (indexToUpdate < 0) return;

      // Create a new nested comment obj with the updated text
      const updatedNestedComment = { ...nestedComment, text: newText };

      const commentToUpdate = {
        ...latestCommentList[indexToUpdate],
        nestedComments: latestCommentList[indexToUpdate].nestedComments.map(
          nC => (nC.id === nestedCommentRes.id ? updatedNestedComment : nC)
        )
      };

      const newCommentList = [
        ...latestCommentList.slice(0, indexToUpdate),
        commentToUpdate,
        ...latestCommentList.slice(indexToUpdate + 1)
      ] as PostComment[] | BoardElementComment[];

      setCommentList(newCommentList, hasNext);
    });
  };
  const deleteNestedComment = async (nestedComment: NestedComment) => {
    return deleteNestedCommentService({
      commentId: nestedComment.parentCommentId,
      nestedCommentId: nestedComment.id,
      context
    }).then(() => {
      const latestCommentList = getCommentListById(parentId).commentList;

      const indexToUpdate = latestCommentList.findIndex(
        (c: PostComment | BoardElementComment) =>
          c.id === nestedComment.parentCommentId
      );
      if (indexToUpdate < 0) return;

      // Create a new nested comment list without the deleted nested comment
      const updatedNestedComments = latestCommentList[
        indexToUpdate
      ].nestedComments.filter(nC => nC.id !== nestedComment.id);

      const commentToUpdate = {
        ...latestCommentList[indexToUpdate],
        nestedComments: updatedNestedComments
      };

      const newCommentList = [
        ...latestCommentList.slice(0, indexToUpdate),
        commentToUpdate,
        ...latestCommentList.slice(indexToUpdate + 1)
      ] as PostComment[] | BoardElementComment[];

      setCommentList(newCommentList, hasNext);
    });
  };

  const createNestedComment = async (commentId: number, text: string) => {
    return createNestedCommentService({ commentId, text, context }).then(
      nestedComment => {
        const latestCommentList = getCommentListById(parentId).commentList;
        const indexToUpdate = latestCommentList.findIndex(
          (comment: PostComment | BoardElementComment) =>
            comment.id === nestedComment.parentCommentId
        );

        const updatedCommentList = [
          ...latestCommentList.slice(0, indexToUpdate),
          {
            ...latestCommentList[indexToUpdate],
            nestedComments: [
              ...latestCommentList[indexToUpdate].nestedComments,
              nestedComment
            ]
          },
          ...latestCommentList.slice(indexToUpdate + 1)
        ] as PostComment[] | BoardElementComment[];

        setCommentList(updatedCommentList, hasNext);
      }
    );
  };

  return {
    fetch,
    isLoading,
    nextPage,
    deleteComment,
    updateComment,
    createComment,
    updateCommentReactionList,
    updateNestedComment,
    deleteNestedComment,
    createNestedComment
  };
};

export default useComments;
