import { ActionButton } from "@arcadia/design-system";
import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px 12px 12px;
`;

export const CloseButton = styled.button`
  border: none;
  padding: 0;
  width: 28px;
  height: 28px;
  flex: 0 0 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const MenuContainer = styled.div`
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 40px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: auto;
`;

export const CancelButton = styled(ActionButton)`
  margin-right: 8px;
`;
