import styled from "styled-components";

export const CalendarDropdownContainer = styled.div`
  position: relative;
  display: flex;
  width: 220px;

  > div > button:first-of-type {
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    z-index: 2;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-wrap: break-word;
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
    }
    &:not(:hover) {
      background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    }
    svg {
      margin-top: -1px;
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 1024px) {
    margin-right: 0px;
  }
  @media (max-width: 767px) {
    width: 50%;
  }
`;

export const AnalyticsIconAndCalendarContainer = styled.div`
  position: relative;
  display: contents;
`;

export const AnalyticsCalendarContainer = styled.div`
  position: absolute;
  margin-top: 44px;
  right: 0px;
  margin-left: 80px;
  z-index: ${({ theme }) => theme.zIndex.forModal};
`;

export const CalendarIconContainer = styled.button`
  height: 36px;
  margin-top: 0px;
  border: none;
  position: absolute;
  right: 0px;
  height: 40px;
  border-radius: 36px;
  cursor: pointer;
  width: 40px;
  z-index: 3 !important;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey[900]};

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor[800]};
  }
`;
