import { observer } from "mobx-react";
import { useEffect } from "react";
import {
  LocalStorageKey,
  createLocalStringifiedData,
  destroyLocalData
} from "src/js/modules/storageFunction";
import { useStores } from "../hooks";

const GTMLocalStorageAligner = () => {
  const {
    UserStore,
    GroupStore: { activeGroupFull },
    SpaceStore: { activeSpace }
  } = useStores();

  const { activeUser } = UserStore;

  useEffect(() => {
    if (activeUser) {
      localStorage.setItem("userId", activeUser.id);
      localStorage.setItem("consent", activeUser.shouldUseTracking);
      createLocalStringifiedData(LocalStorageKey.ActiveUser, activeUser);
    } else {
      localStorage.removeItem("groupId");
      localStorage.removeItem("userId");
      localStorage.removeItem("consent");
      destroyLocalData(LocalStorageKey.ActiveUser);
    }
  }, [activeUser]);

  useEffect(() => {
    if (activeGroupFull) {
      localStorage.setItem("groupId", activeGroupFull.group.id);
      createLocalStringifiedData(LocalStorageKey.ActiveGroup, activeGroupFull);
    } else {
      localStorage.removeItem("groupId");
      destroyLocalData(LocalStorageKey.ActiveGroup);
    }
  }, [activeGroupFull]);

  useEffect(() => {
    if (activeSpace) {
      createLocalStringifiedData(LocalStorageKey.ActiveSpace, activeSpace);
    } else {
      destroyLocalData(LocalStorageKey.ActiveSpace);
    }
  }, [activeSpace]);

  return null;
};

export default observer(GTMLocalStorageAligner);
