import { ActionButton, CtaTertiary, Tag, Text } from "@arcadia/design-system";
import React, { useRef } from "react";
import { useStores } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

import * as S from "./FilterBar.styles";
import { FilterBarProps } from "./FilterBar.types";

const FilterBar = ({
  selectedFiltersKeys = [],
  options = [],
  removeFilter,
  removeAllFilters,
  autoScroll = true,
  className
}: FilterBarProps) => {
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile: isMobile }
  } = useStores();

  const activeFilters =
    selectedFiltersKeys.length > 0
      ? options.filter(opt => selectedFiltersKeys.includes(opt.id))
      : [];
  const carouselRef = useRef<HTMLDivElement>();
  let isOverflowing = false;

  if (carouselRef && carouselRef.current) {
    isOverflowing =
      carouselRef.current.scrollWidth > carouselRef.current.offsetWidth;
  }

  if (activeFilters.length === 0) return null;

  const activeFiltersTags = activeFilters.map(({ id, label }) => {
    return (
      <Tag
        key={id}
        theme={whiteTheme}
        onClick={() => {
          removeFilter(id);
        }}
        fat
        type="solid"
        color="violet"
        label={label}
      />
    );
  });

  if (isMobile) {
    return (
      <S.MobileWrapper className={className}>
        {removeAllFilters ? (
          <S.HeaderWrapper>
            <Text type="captionAlert">
              <Translate text="spaces_groups_active_filters_label" />
            </Text>
            <S.RemoveAllButton>
              <CtaTertiary
                theme={whiteTheme}
                type="button"
                onClick={removeAllFilters}
              >
                <Translate text="remove_filters" />
              </CtaTertiary>
            </S.RemoveAllButton>
          </S.HeaderWrapper>
        ) : null}
        <S.TagsCarousel ref={carouselRef}>
          {autoScroll && isOverflowing ? (
            <S.AutoScroll>{activeFiltersTags}</S.AutoScroll>
          ) : (
            activeFiltersTags
          )}
        </S.TagsCarousel>
      </S.MobileWrapper>
    );
  }

  return (
    <S.TabletAndUpWrapper className={className}>
      <S.MaxWidthWrapper>
        <S.SelectableFilterContainer>
          {activeFiltersTags}
        </S.SelectableFilterContainer>
        {removeAllFilters ? (
          <ActionButton
            theme={whiteTheme}
            icon="trash"
            variant="global"
            onClick={removeAllFilters}
          />
        ) : null}
      </S.MaxWidthWrapper>
    </S.TabletAndUpWrapper>
  );
};

export default FilterBar;
