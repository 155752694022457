import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  api_user_public_profile,
  api_user_public_profile_score,
  api_user_public_profile_settings,
  api_user_public_profile_shared_group,
  api_users_public_profile_rank_list,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";

/**
 * @deprecated since typescript move the function to profile profileRepository.ts
 */

export function fetchPublicProfile(userUuid) {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_public_profile({ userUuid }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_profile"));
      throw Err;
    });
}

export function fetchPublicProfileSettings() {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_public_profile_settings(), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_profile"));
      throw Err;
    });
}

export const savePublicProfileSettings = body => {
  return getUserClient()
    .patch(getVersionedApiUrl(api_user_public_profile_settings(), "v3"), body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error?.response?.data, "error_fetch_profile")
      );
      throw Err;
    });
};

export function fetchSharedGroupsList(id, start, limit, timestamp) {
  return getUserClient()
    .get(
      getVersionedApiUrl(
        api_user_public_profile_shared_group({ userUuid: id }),
        "v3"
      ),
      {
        params: {
          start,
          limit,
          max_timestamp: timestamp
        }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_share_group_list")
      );
      throw Err;
    });
}

export function fetchPublicProfileScore(id) {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_public_profile_score({ user_id: id })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_profile_score")
      );
      throw Err;
    });
}

export function fetchPublicProfileRankList(id) {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_users_public_profile_rank_list({ user_id: id }))
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_profile_rank_list")
      );
      throw Err;
    });
}
