import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  api_board,
  api_board_element_create,
  api_board_element_details,
  api_board_element_state,
  api_board_element_track,
  api_board_path,
  api_boards,
  api_boards_list,
  api_constraints_board,
  api_edit_board,
  api_edit_constraints_board,
  api_group_progress,
  api_module_resource_suggest,
  api_new_board,
  api_publish_board,
  api_reorder_board,
  api_resources_search,
  api_unpublish_board,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";

export function fetchBoards({ groupId, offset, isPublished }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_boards({ groupId }), "v3"), {
      params: {
        offset,
        isPublished
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = extractErrorMessage(error);
      throw Err;
    });
}

export function createNewBoard({ groupId, boardPayload }) {
  return getUserClient()
    .post(getVersionedApiUrl(api_new_board()), {
      group_id: groupId,
      ...boardPayload
    })
    .then(({ data }) => {
      return data;
    });
}

export function publishBoard({ boardId }) {
  return getUserClient()
    .patch(getVersionedApiUrl(api_publish_board({ board_id: boardId })))
    .then(({ data }) => {
      return data;
    });
}

export function unpublishBoard({ boardId }) {
  return getUserClient()
    .patch(getVersionedApiUrl(api_unpublish_board({ board_id: boardId })))
    .then(({ data }) => {
      return data;
    });
}

export function editBoard({
  boardId,
  color,
  isMandatory,
  isMilestone,
  milestoneMessage,
  hasMandatoryOrder,
  isOpen,
  name
}) {
  return getUserClient()
    .patch(getVersionedApiUrl(api_edit_board({ board_id: boardId }), "v3"), {
      color,
      isMandatory,
      isMilestone,
      milestoneMessage,
      hasMandatoryOrder,
      isOpen,
      name
    })
    .then(({ data }) => {
      return data;
    });
}

export async function fetchResourcesBoards({
  type = "BOARD",
  group_role = "ROLE_TEACHER",
  group_id = null,
  name = "",
  offset = 0,
  limit = 20
}) {
  const params = Object.fromEntries(
    Object.entries({
      type,
      group_role,
      group_id,
      query: name,
      start: offset,
      limit
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([_, v]) => v || v === 0)
  );

  return getUserClient()
    .get(getVersionedApiUrl(api_resources_search()), {
      params
    })
    .then(({ data }) => {
      return { results: data, hasNext: false };
    })
    .catch(error => {
      const Err = extractErrorMessage(error);
      throw Err;
    });
}

// eslint-disable-next-line import/prefer-default-export
export function fetchBoard(id) {
  return getUserClient()
    .get(getVersionedApiUrl(api_board({ board_id: id })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = {
        statusCode: error.response.status,
        constraints: error.response.data.constraints
      };
      throw Err;
      // const Err = new Error(extractErrorMessage(error, "error_fetch_board"));
      // throw Err;
    });
}

export function fetchBoardPath({ boardId }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_board_path({ board_id: boardId })))
    .then(({ data }) => {
      return data;
    });
}

export const saveBoardElementState = ({ board_element_id, body }) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_board_element_state({ board_element_id })), {
      new_state: body
    })
    .then(({ data }) => {
      return data;
    });
};

export const fetchBoardElementState = ({ board_element_id }) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_board_element_state({ board_element_id })))
    .then(({ data }) => {
      return data;
    });
};

export function addBoardElement({ id, url = null, title, file, resourceId }) {
  const formData = new FormData();
  if (url) formData.append("url", url);
  formData.append("name", title);
  if (resourceId) {
    formData.append("resource_id", resourceId);
  }
  if (file) formData.append("file", file);
  const headers = {
    "Content-Type": "multipart/form-data"
  };
  return getUserClient()
    .post(
      getVersionedApiUrl(api_board_element_create({ boardId: id })),
      formData,
      { headers }
    )
    .then(response => {
      return { response };
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_add_board_element")
      );
      throw Err;
    });
}

export function deleteBoard({ boardId }) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_board({ board_id: boardId })))
    .then(({ data }) => {
      return data;
    });
}

export function getConstraints({ boardId }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_constraints_board({ board_id: boardId })))
    .then(({ data }) => {
      return data;
    });
}

export function addConstraint({ boardId, constraintId }) {
  return getUserClient()
    .post(
      getVersionedApiUrl(
        api_edit_constraints_board({
          board_id: boardId,
          constraint_id: constraintId
        })
      )
    )
    .then(({ data }) => {
      return data;
    });
}

export function removeConstraint({ boardId, constraintId }) {
  return getUserClient().delete(
    getVersionedApiUrl(
      api_edit_constraints_board({
        board_id: boardId,
        constraint_id: constraintId
      })
    )
  );
}

export const reorderBoard = async ({ boardId, prevId, nextId }) => {
  return getUserClient()
    .patch(getVersionedApiUrl(api_reorder_board({ board_id: boardId }), "v3"), {
      previous: prevId,
      next: nextId
    })
    .then(({ data }) => {
      return data;
    });
};

export function getStudentProgress({ groupId }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_progress({ groupId })))
    .then(({ data }) => {
      return data;
    });
}

export function fetchBoardsList({ groupId, boards }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_boards_list({ groupId })), {
      params: {
        boards
      }
    })
    .then(({ data }) => {
      return data;
    });
}

export const cloneBoardModule = ({ boardId, groupId }) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_board({ board_id: boardId })), {
      group_id: groupId
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error;
    });
};

export const updateBoard = (boardId, boardPayload) => {
  return getUserClient()
    .put(getVersionedApiUrl(api_board({ board_id: boardId })), boardPayload)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export const suggestModuleResource = (
  moduleId,
  position,
  resourcePayload,
  groupId
) => {
  return getUserClient()
    .post(getVersionedApiUrl(api_module_resource_suggest({ moduleId })), {
      resource: resourcePayload,
      group_id: groupId,
      position
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export const boardResourceOpened = (boardId, elementId) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_board_element_track({ boardId, elementId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchBoardElement = async boardElementId => {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_board_element_details({ boardElementId }), "v3")
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};
