import { ActionButton, Box, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { GroupJoinWithCodeForm } from "src/js/components/GroupJoinWithCodeForm";
import { useStores } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import {
  __TESTIMONIAL_BACKGROUND_1__,
  __TESTIMONIAL_IMAGE_4__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { EventDomain, OnboardingEvent } from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { updateMarketingData } from "src/js/repository/marketingDataRepository";
import Testimonial from "../components/Testimonial";
import { TitleBlock } from "../components/TitleBlock";

import * as S from "./Join.styles";

const Join = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    UserStore: { userOnboarding, setUserOnboarding }
  } = useStores();

  const onSuccessCallback = () => {
    // this update api call let the user/me update and prevents the create space modal
    // showing on the main page just after join
    const { userType } = userOnboarding;
    updateMarketingData({ userType, onboardingCompleted: true }).then(() => {
      _trackEvent(EventDomain.Onboarding, OnboardingEvent.JoinedSpaceCode);
      _trackEvent(EventDomain.Onboarding, OnboardingEvent.CompleteFlow);
      setUserOnboarding({
        ...userOnboarding,
        isCompleted: true
      });
    });
  };

  const goBack = () => {
    setUserOnboarding({
      userType: USER_TYPE.MEMBER,
      isCompleted: false,
      step: WeSchoolRoutes.Onboarding.welcome
    });
    navigateTo(WeSchoolRoutes.Onboarding.welcome);
  };

  const pageContainer = (
    <S.Container>
      <TitleBlock
        title={translate("onboarding_join_page_title")}
        backComponent={
          <ActionButton
            variant="tertiary"
            icon="chevronSmallLeft"
            theme={whiteTheme}
            onClick={goBack}
          />
        }
      />
      <Box textAlign="center" marginTop={10}>
        <Text type="body">{translate("onboarding_join_page_text")}</Text>
      </Box>
      <GroupJoinWithCodeForm onSuccessCallback={onSuccessCallback} />
    </S.Container>
  );

  return (
    <OnboardingLayout
      mainContent={pageContainer}
      sideContent={
        <Testimonial
          background={__TESTIMONIAL_BACKGROUND_1__}
          image={__TESTIMONIAL_IMAGE_4__}
          text={translate("onboarding_testimonial_join")}
        />
      }
      mobileCentered
    />
  );
};

export default observer(Join);
