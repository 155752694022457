export const __EXTERNAL_RESOURCES__ = [
  {
    type: "LINK",
    name: "calendar_attachment_link"
  },
  {
    type: "FILE",
    name: "calendar_attachment_file"
  },
  {
    type: "GOOGLE_DRIVE",
    name: "calendar_attachment_google_drive"
  },
  {
    type: "DROPBOX",
    name: "calendar_attachment_dropbox"
  },
  {
    type: "GOOGLE_MAPS",
    name: "calendar_attachment_google_maps"
  }
];

export const __INTERNAL_RESOURCES__ = [
  {
    type: "BOARD",
    name: "calendar_attachment_board"
  },
  {
    type: "EXERCISE",
    name: "calendar_attachment_exercise"
  }
];
