import { extractErrorMessage } from "src/js/modules/messageManager";
import getUserClient from "src/js/modules/connection";
import {
  getVersionedApiUrl,
  api_board_elements_score
} from "src/js/repository/apiUrls";

export function fetchBoardResourcesScore({ boardIds = [] }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_board_elements_score()), {
      params: {
        board_ids: boardIds
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_board_resources_score")
      );
      throw Err;
    });
}
