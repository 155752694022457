import { useEffect } from "react";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { EventDomain, OnboardingEvent } from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";

const useResumeOnboarding = () => {
  const {
    UserStore: { userOnboarding, setUserOnboarding }
  } = useStores();

  useEffect(() => {
    // handle the edge case where in the onboarding the user refresh the page at the group create selection step
    if (userOnboarding.step === "") {
      navigateTo(WeSchoolRoutes.Onboarding.initOnboarding);
    }
  }, []);

  const isGroupCreateSelectionStep = () => {
    return (
      userOnboarding.step === WeSchoolRoutes.Onboarding.groupCreateSelection
    );
  };

  const isGroupCreationStep = () => {
    return userOnboarding.step !== WeSchoolRoutes.Onboarding.resumeOnboarding;
  };

  const isSpaceCreationStep = () => {
    return userOnboarding.step === WeSchoolRoutes.Onboarding.resumeOnboarding;
  };

  const isLogoutVisible = () => {
    return (
      userOnboarding.step !== WeSchoolRoutes.Onboarding.groupCreateSelection
    );
  };

  const getRoute = (onboardingTemplateWeSchool: boolean) => {
    const groupCreationFlowSelected = onboardingTemplateWeSchool
      ? WeSchoolRoutes.Onboarding.weschoolTemplateCreation
      : WeSchoolRoutes.Onboarding.groupCreation;

    return isGroupCreationStep()
      ? groupCreationFlowSelected
      : WeSchoolRoutes.Onboarding.spaceCreation;
  };

  const getTrackingEvent = (onboardingTemplateWeSchool: boolean) => {
    const groupCreationFlowSelected = onboardingTemplateWeSchool
      ? OnboardingEvent.ResumeWeSchoolTemplateSelection
      : OnboardingEvent.ResumeStandardGroupSelected;

    return isGroupCreationStep()
      ? groupCreationFlowSelected
      : OnboardingEvent.ResumeSpaceCreation;
  };

  const setupTracking = (onboardingTemplateWeSchool: boolean) => {
    const nextStep = getRoute(onboardingTemplateWeSchool);
    _trackEvent(
      EventDomain.Onboarding,
      getTrackingEvent(onboardingTemplateWeSchool)
    );
    setUserOnboarding({
      ...userOnboarding,
      step: nextStep,
      userType: USER_TYPE.BUSINESS
    });
  };

  return {
    isGroupCreateSelectionStep,
    isGroupCreationStep,
    isSpaceCreationStep,
    isLogoutVisible,
    setupTracking
  };
};

export default useResumeOnboarding;
