import { DoodleLoader, ModalSizeType } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./WallBadgesModal.styles";
import WallModalBadge from "./WallModalBadge";

const WallBadgesModal = ({ closeModal }) => {
  const {
    GroupStore: { associatedBadge, fetchNextBadgesPage },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const { badgeList, offset, total } = associatedBadge;
  const hasMore = offset < total;

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate("badge_wall_modal_title"),
        mobileTitle: translate("badge_wall_modal_title"),
        mobileClose: translate("close")
      }}
      desktopProps={{ hasAdaptiveHeight: true, size: ModalSizeType.Normal }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false
      }}
    >
      <S.ListWrapper>
        <ScrollableDiv
          maxHeight={isLayoutModeMobile ? "100%" : "400px"}
          id="wall-modal-badge-list"
        >
          <InfiniteScroll
            scrollableTarget="wall-modal-badge-list"
            dataLength={total}
            next={() => {
              fetchNextBadgesPage();
            }}
            hasMore={hasMore}
            loader={<DoodleLoader theme={whiteTheme} isMini />}
          >
            {badgeList.map(badge => {
              return (
                <WallModalBadge
                  key={badge.id}
                  name={badge.name}
                  image={badge.image}
                />
              );
            })}
          </InfiniteScroll>
        </ScrollableDiv>
      </S.ListWrapper>
    </ModalLayout>
  );
};

export default observer(WallBadgesModal);
