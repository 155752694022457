import React from "react";
import moment from "moment";
import isUndefined from "lodash/isUndefined";
import isNumber from "lodash/isNumber";
import template from "lodash/template";
import isObject from "lodash/isObject";
import anchorme from "anchorme";
import { URLS } from "../settings/settingsUrl";

export function isElementInViewport(el, allowPartial) {
  // special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  const rect = el.getBoundingClientRect();
  const innerHeight =
    window.innerHeight || document.documentElement.clientHeight;
  /* or $(window).height() */
  const innerWidth = window.innerWidth || document.documentElement.clientWidth;
  /* or $(window).width() */

  if (allowPartial === true) {
    return (
      (rect.top >= 0 &&
        rect.top <= innerHeight &&
        rect.left >= 0 &&
        rect.left <= innerWidth) || // top left in view
      (rect.top >= 0 &&
        rect.top <= innerHeight &&
        rect.right >= 0 &&
        rect.right <= innerWidth) || // top right in view
      (rect.bottom >= 0 &&
        rect.bottom <= innerHeight &&
        rect.left >= 0 &&
        rect.left <= innerWidth) || // bottom left in view
      (rect.bottom >= 0 &&
        rect.bottom <= innerHeight &&
        rect.right >= 0 &&
        rect.right <= innerWidth) // top right in view
    );
  }
  // element must be completely visible
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= innerHeight &&
    rect.right <= innerWidth
  );
}

let spaceSlug = null;

export const updateSpaceSlugLegacy = value => {
  spaceSlug = value;
};

export function createURL(type, obj) {
  // TODO: Inserire condizione nel router che permette di verificare se l'utente è attivo e reindirizzarlo a pagina account info
  let result = "";
  const typeUrl = type;
  const objectWithSpaceSlug = { space_slug: spaceSlug, ...obj };

  if (typeof URLS[typeUrl] !== "undefined") {
    result = template(URLS[typeUrl])(objectWithSpaceSlug);
  }
  return result;
}

export function truncateTextSimple(text, numberOfChars) {
  let aText = text || "";
  if (aText.length >= numberOfChars) {
    aText = `${aText.substring(0, numberOfChars)}...`;
  }
  return aText;
}

export function stripHtml(target) {
  let result = "";

  if (typeof target !== "undefined") {
    result = $("<div/>").html(target).text();
  }

  return result;
}

export function mobileAndTabletcheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function isValidUrl(s) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[;&a-z\\d%_.~()+!:=-]*)*" + // port and path
      "(\\?[;&a-z\\d%/_.~()+!:=-]*)?" + // query string
      "(\\#[;&a-z\\d%/_.~()+!:=-]*)?$",
    "i"
  ); // fragment locator
  if (!pattern.test(s)) {
    return false;
  }
  return true;
}

export function addHttpToUrl(s) {
  const http = s.substring(0, 7);
  const https = s.substring(0, 8);
  if (http === "http://" || https === "https://") {
    return s;
  }
  return ["http://", s].join("");
}

export function addHttp(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = `http://${url}`;
  }
  return url;
}

export function scrollToError() {
  if ($(".has-error").length > 0) {
    const offsetTopBar =
      typeof $(".header__top-bar").height() === "undefined"
        ? 0
        : $(".header__top-bar").height();
    let offset = $(".has-error").offset().top;

    offset = offset - offsetTopBar - 80;
    $("html, body").animate({ scrollTop: offset });
  }
}

export function scrollToElement(selector, duration) {
  if ($(selector).length > 0) {
    const offsetTopBar = $(".header__top-bar").height();
    let offset = $(selector).offset().top;

    offset = offset - offsetTopBar - 80;
    $("html, body").animate(
      {
        scrollTop: offset
      },
      isUndefined(duration) ? 0 : duration
    );
  }
}

export function truncateText(text, numberOfChars) {
  /** OLD VERSION DO NOT USE - LEFT HERE FOR REFERENCE */
  /* //tronca il testo dopo @numberOfChars caratteri fino alla fine della parola.
     var re = new RegExp("^(.{" + numberOfChars + "}[^\\s]*).*");
     text = text.replace(re, "$1");
     return text; */

  if (typeof text == "undefined") {
    return text;
  }
  // trim the string to the maximum length
  let trimmedString = text.substr(0, numberOfChars);

  // minimum from length, last " " character or last newline char
  const trimmedLength = trimmedString.length;
  let minLength = trimmedLength;
  const lastSpace = trimmedString.lastIndexOf(" ");
  const lastNewLine = trimmedString.lastIndexOf("\n");

  if (lastSpace > 0) {
    minLength = Math.min(trimmedLength, lastSpace);
  }
  if (lastNewLine > 0) {
    minLength = Math.min(trimmedLength, lastNewLine);
  }
  /* if we have both spaces and new lines, keep the last good one */
  if (lastSpace > 0 && lastNewLine > 0) {
    minLength = Math.min(trimmedLength, Math.max(lastSpace, lastNewLine));
  }

  // re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(0, minLength);
  return trimmedString;
}

export function nl2spaces(str) {
  const breakTag = "";
  return `${str}`.replace(/(\r\n|\n\r|\r|\n)/g, " ");
}

export function convertSecondsToFormattedString(seconds) {
  let timeMinutesPartial = "";
  let timeSecondsPartial = "";
  const minuti = moment.duration(seconds, "seconds").asMinutes();
  const secondi = moment.duration(seconds, "seconds").seconds();
  if (minuti < 1) {
    timeMinutesPartial = "0'";
  } else {
    timeMinutesPartial = `${parseInt(minuti)}'`;
  }
  if (secondi > 0) {
    timeSecondsPartial = `${secondi >= 10 ? secondi : `0${secondi}`}"`;
  } else {
    timeSecondsPartial = '00"';
  }
  return timeMinutesPartial + " " + timeSecondsPartial;
}

export function formatElapsedTime(time) {
  let result = "";
  let timeHoursPartial = "";
  let timeMinutesPartial = "00";
  let timeSecondsPartial = "00";

  if (moment.duration(time, "seconds").hours() > 0) {
    timeHoursPartial = moment.duration(time, "seconds").hours();
    result += `${timeHoursPartial}:`;
  }

  if (moment.duration(time, "seconds").minutes() > 0) {
    var zero = "";

    if (moment.duration(time, "seconds").minutes() < 10) {
      zero = "0";
    }
    timeMinutesPartial = zero + moment.duration(time, "seconds").minutes();
  }

  if (moment.duration(time, "seconds").seconds() > 0) {
    var zero = "";

    if (moment.duration(time, "seconds").seconds() < 10) {
      zero = "0";
    }
    timeSecondsPartial = zero + moment.duration(time, "seconds").seconds();
  }

  result += `${timeMinutesPartial}:${timeSecondsPartial}`;

  return result;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertTime(time) {
  let result = {};

  if (isNumber(time)) {
    result = {
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    if (moment.duration(time, "seconds").hours() > 0) {
      result.hours = moment.duration(time, "seconds").hours();
    }

    if (moment.duration(time, "seconds").minutes() > 0) {
      result.minutes = moment.duration(time, "seconds").minutes();
    }

    if (moment.duration(time, "seconds").seconds() > 0) {
      result.seconds = moment.duration(time, "seconds").seconds();
    }
  } else if (isObject(time)) {
    result =
      parseInt(time.seconds, 10) +
      parseInt(time.minutes, 10) * 60 +
      parseInt(time.hours, 10) * 3600;
  }

  return result;
}

export function wrapAnchorme(text) {
  let textAnchored = anchorme(text, {
    attributes: [
      {
        name: "className",
        value: "inner-link"
      },
      function (urlObj) {
        if (urlObj.protocol !== "mailto:") {
          return { name: "target", value: "blank" };
        }
      }
    ]
  });
  return textAnchored;
}
