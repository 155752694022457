import React, { useState } from "react";
import { useTheme } from "styled-components";
import {
  __AVAILABLE_LANG__,
  __FLAG_LANG__
} from "src/js/settings/settingsLocalization";
import { readLocalData } from "src/js/modules/storageFunction";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import { translateString } from "src/js/translation/TranslationProvider";
import { BottomModalSheet, Button, Modal } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { TranslationKeys } from "src/js/translation";
import { ModalScrollableBody } from "src/js/components/modal/ModalScrollableBody";
import { Selector } from "src/js/components/CountryLanguageModal/Selector";
import * as S from "../LanguageSelector.styles";
import { LanguageModalProps } from "../LanguageSelector.types";

const CountryLanguageContent = ({ successCallback }: LanguageModalProps) => {
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { whiteTheme } = useTheme();

  const [selectedLanguage, setSelectedLanguage] = useState(
    readLocalData(__COOKIE_LANG__)
  );

  const saveFunction = () => {
    successCallback(selectedLanguage);
    closeModal();
  };

  const selectLanguage = language => {
    setSelectedLanguage(language);
  };

  return (
    <S.LanguageContentWrap>
      <S.LanguageContentBody>
        <Selector
          list={Object.keys(__AVAILABLE_LANG__).map(lang => ({
            label: `${__FLAG_LANG__[lang]} ${translateString({
              text: __AVAILABLE_LANG__[lang] as TranslationKeys
            })}`,
            value: lang
          }))}
          onChange={selectLanguage}
          current={selectedLanguage}
        />
      </S.LanguageContentBody>
      <S.LanguageContentFooter>
        <Button
          onClick={saveFunction}
          fullWidth
          variant="primary"
          theme={whiteTheme}
        >
          {translateString({
            text: "country_language_modal_confirm"
          })}
        </Button>
      </S.LanguageContentFooter>
    </S.LanguageContentWrap>
  );
};

const LanguageModal = ({ successCallback }: LanguageModalProps) => {
  const { UIStore } = useStores();
  const {
    ModalStore: { closeModal }
  } = useStores();
  const title = translateString({
    text: "country_language_modal_title"
  });

  const [showBottomSheet, setShowBottomSheet] = useState(true);

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
    setTimeout(() => closeModal(), 500);
  };

  return UIStore.isLayoutModeMobile ? (
    <BottomModalSheet
      title={title}
      isOpen={showBottomSheet}
      onClose={closeBottomSheet}
      closeLabel={translateString({ text: "close" })}
    >
      <S.MobileWrapper>
        <CountryLanguageContent successCallback={successCallback} />
      </S.MobileWrapper>
    </BottomModalSheet>
  ) : (
    <Modal onClose={closeModal} title={title}>
      <ModalScrollableBody
        maxHeight={1000}
        maxHeightOffset={100}
        flexHeight={null}
        flexHeightOffset={0}
      >
        <S.LanguageModalContainer>
          <CountryLanguageContent successCallback={successCallback} />
        </S.LanguageModalContainer>
      </ModalScrollableBody>
    </Modal>
  );
};

export default LanguageModal;
