import { Property } from "csstype";
import { ElementColorOptions } from "@ds_types/common";
import {
  AvatarSize,
  ButtonVariant,
  CodeInputVariant,
  IconName,
  LabelVariant,
  Owner,
  TagActivityColor
} from "@ds_types/components";

export const VariantType = {
  White: "WHITE",
  Grey: "GREY"
};
export type VariantType = (typeof VariantType)[keyof typeof VariantType];

export type Palette = {
  appearence: "heavy" | "light";
  general: { background: string; contrastText: string };
  grey: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  };
  blueGrey: {
    200: string;
    300: string;
    400: string;
    500: string;
    700: string;
    800: string;
    900: string;
  };
  coldGrey: { 600: string; 700: string; 800: string; 900: string };
  violet: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    501: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  error: {
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    900: string;
  };
  lagoon: {
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  };
  yellow: {
    50: string;
    100: string;
    300: string;
    400: string;
    450: string;
    500: string;
    600: string;
    800: string;
    900: string;
  };
  success: {
    100: string;
    300: string;
    500: string;
    600: string;
    700: string;
  };
  primary: {
    main: string;
    darker: string;
    dark: string;
    light: string;
    lighter: string;
    contrastText: string;
  };
  secondary: {
    main: string;
    darker: string;
    dark: string;
    light: string;
    lighter: string;
    contrastText: string;
  };
  warning: {
    main: string;
    darker: string;
    dark: string;
    light: string;
    lighter: string;
    contrastText: string;
  };
};

export type PaletteProps = {
  primary: string;
  secondary?: string;
  grey?: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  };
  blueGrey?: {
    200: string;
    300: string;
    400: string;
    500: string;
    700: string;
    800: string;
    900: string;
  };
  coldGrey?: { 600: string; 700: string; 800: string; 900: string };
  violet?: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    501: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  background: string;
  error?: {
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    900: string;
  };
  yellow?: {
    50: string;
    100: string;
    300: string;
    400: string;
    450: string;
    500: string;
    600: string;
    800: string;
    900: string;
  };
  lagoon?: {
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  };
  success?: {
    100: string;
    300: string;
    500: string;
    600: string;
    700: string;
  };
  warning?: string;
  appearence?: "heavy" | "light";
};

export type AvatarOptions = {
  mask: string;
  img: string;
};

export type AvatarSizesMap = Record<
  Exclude<AvatarSize, "m" | "image">,
  AvatarOptions
> & {
  m: AvatarOptions & {
    badge: {
      background: string;
      text: string;
    };
  };
  image: {
    width: string;
  };
};

export type CreateAvatarOptions = AvatarSizesMap & {
  ribbon?: string;
};

export type DividerColor = "black" | "grey" | "primary";

export type DividerThemeMap = Record<DividerColor, string>;

export type CheckIndicatorMap = {
  background: string;
  border: string;
  color: string;
  selected: {
    border: string;
    background: string;
    color: string;
  };
};

export type DoodleLoaderMap = {
  color: string;
  background: string;
};

export type ButtonOptions = {
  background: string;
  text: string;
  type: string;
  border: string;
  hover: {
    background: string;
    text: string;
    border?: string;
  };
  focus: {
    border: string;
    background?: string;
    text?: string;
  };
  disabled: {
    background?: string;
    text: string;
    border?: string;
  };
  badge: {
    background: string;
    text: string;
    border: string;
  };
};

export type ActionButtonsMap = Record<ButtonVariant, ButtonOptions>;

export type ActionButtonThemeOptions = Omit<ActionButtonsMap, "danger">;

export type ProgressBarMap = {
  background: string;
  gutter: string;
};

export type PickerMap = {
  background: string;
  border: string;
  focus: {
    border: string;
  };
  icon: {
    color: string;
  };
};

export type SidebarItemMap = {
  background: string;
  text: string;
  hover: {
    background: string;
    text: string;
  };
};

export type SideMenuMap = {
  background: string;
  text: string;
  selected: {
    background: string;
    text: string;
  };
  focus: {
    border: string;
  };
};

export type PostStylesMap = {
  basic: {
    background: string;
    text: string;
    pin: string;
    ribbon: string;
    date: {
      text: string;
    };
  };
  comment: {
    background: string;
    date: {
      text: string;
    };
    ribbon: string;
  };
  badgeScore: {
    background: string;
    border: string;
  };
};

export type ButtonThemeOptions = {
  background: string;
  text: string;
  type: string;
  border: string;
  hover: {
    background: string;
    text: string;
  };
  focus: {
    border: string;
  };
  disabled: {
    background: string;
    text: string;
    border: string;
  };
};

export type ButtonsMap = Record<ButtonVariant, ButtonThemeOptions>;

export type CardActivityThemeOptions = {
  title: string;
  body: {
    color: string;
  };
  background: string;
  border: string;
  check: {
    background: string;
    color: string;
  };
  checked: {
    border: string;
  };
  focus: {
    border: string;
  };
  icon: {
    background: string;
    color: string;
  };
};

export type CardAddContentThemeOptions = {
  border: string;
  background: string;
  wrapperIcon: {
    background: string;
    text: string;
    border: string;
  };
  wrapperText: {
    background: string;
    text: string;
  };
  focus: {
    border: string;
  };
};

export type CardContentThemeOptions = {
  title: string;
  body: string;
  background: string;
  border: string;
  viewed: {
    background: string;
    color: string;
  };
  notViewed: {
    background: string;
    color: string;
  };
  wrapperIcon: {
    background: string;
    text: string;
    border: string;
  };
  wrapperText: {
    background: string;
    title: string;
    body: string;
  };
  comment: {
    text: string;
    color: string;
  };
  resource: {
    background: string;
    text: string;
  };
  focus: {
    border: string;
  };
};

export type CardGroupThemeOptions = {
  title: string;
  body: {
    color: string;
  };
  background: string;
  check: {
    background: string;
    color: string;
  };
  border: string;
  checked: {
    border: string;
  };
  focus: {
    border: string;
  };
};

export type CardLessonThemeOptions = {
  background: string;
  text: string;
  icon: string;
  loading: {
    color: string;
  };
  cart: {
    background: string;
    icon: string;
  };
  focus: {
    border: string;
  };
};

export type CardSchoolThemeOptions = {
  title: {
    color: string;
  };
  body: {
    color: string;
  };
  background: string;
  border: string;
  check: {
    background: string;
    color: string;
  };
  checked: {
    border: string;
  };
  focus: {
    border: string;
  };
  checkindicator?: CheckIndicatorMap;
  avatar?: CreateAvatarOptions;
};

export type CheckboxThemeOptions = {
  background: string;
  border: string;
  checked: {
    background: string;
    text: string;
  };
  disabled: {
    border: string;
    background: string;
    text: string;
  };
  hover: {
    border: string;
    background: string;
    text: string;
  };
  focus: {
    border: string;
  };
  text: string;
};

export type ChipThemeOptions = {
  background: string;
  color: string;
};

export type CtaTertiaryThemeOptions = {
  background: string;
  text: string;
  type: string;
  hover: {
    text: string;
  };
  disabled: {
    text: string;
  };
  focus: {
    border: string;
  };
};

export type SearchbarThemeOptions = {
  background: string;
  text: string;
  textPlaceholder: string;
  border: string;
  focus: {
    outline: string;
  };
  font: {
    style: string;
    weight: string;
    size: string;
    lineHeight: string;
  };
};

export type DropdownThemeOptions = {
  backgroundButton: string;
  backgroundContainer: string;
  filterIconBackground: string;
  filterIconActiveColor: string;
  filterIconInactiveColor: string;
  text: string;
  border: string;
  option: {
    background: string;
    text?: string;
    border?: string;
    hover: {
      text: string;
    };
  };
  hover: {
    background: string;
    border: string;
  };
  focus: {
    border: string;
    outline: string;
    backgroundOptions: string;
    backgroundButton: string;
  };
  disabled: {
    background: string;
    color: string;
  };
  searchbar?: SearchbarThemeOptions;
  tag?: TagThemeOptions;
  checkbox?: CheckboxThemeOptions;
};

export type DropdownContextualThemeOptions = {
  button: {
    background: string;
    border: string;
    focus: {
      border: string;
      background: string;
      color: string;
      outline: string;
    };
  };
  body: {
    background: string;
    border: string;
  };
  option: {
    background: string;
    focus: {
      background: string;
    };
  };
};

export type TagThemeOptions = {
  general: {
    outline: {
      violet: {
        background: string;
        text: string;
      };
      red: {
        background: string;
        text: string;
      };
      royalpurple: {
        background: string;
        text: string;
      };
      summergreen: {
        background: string;
        text: string;
      };
      lagoon: {
        background: string;
        text: string;
      };
    };
    focus: {
      background: string;
      border: string;
    };
    solid: {
      violet: {
        background: string;
        text: string;
      };
      yellow: {
        background: string;
        text: string;
      };
      red: {
        background: string;
        text: string;
      };
      carnation: {
        background: string;
        text: string;
      };
      royalpurple: {
        background: string;
        text: string;
      };
      summergreen: {
        background: string;
        text: string;
      };
      lagoon: {
        background: string;
        text: string;
      };
    };
  };
  activity: {
    background: string;
    closeColor: string;
    icon: string;
    variant: {
      [key in TagActivityColor]: string;
    };
  };
};

export type GoogleButtonThemeOptions = {
  background: string;
  text: string;
  border: string;
  hover: {
    border: string;
  };
  focus: {
    border: string;
  };
};

export type SocialLoginButtonThemeOptions = {
  background: string;
  text: string;
  border: string;
  hover: {
    border: string;
  };
  focus: {
    border: string;
  };
};

export type SpecificErrorThemeOptions = {
  specificerror: {
    text: string;
  };
};

export type InputThemeOptions = SpecificErrorThemeOptions & {
  background: string;
  text: string;
  textPlaceholder: string;
  label: string;
  border: string;
  hover: {
    background: string;
    border: string;
  };
  focus: {
    background: string;
    outline: string;
    border: string;
  };
  required: {
    background: string;
    text: string;
    border: string;
  };
  disabled: {
    background: string;
    text: string;
  };
  error: {
    text: string;
    border: string;
  };
  success: {
    text: string;
    border: string;
  };
  font: {
    style: string;
    weight: string;
    size: string;
    lineHeight: string;
  };
};

export type PictureUploaderThemeOptions = {
  label: string;
  background: string;
  color: string;
  border: string;
  opacityImg: number;
  focus: {
    background: string;
    color: string;
    border: string;
  };
};

export type CodeInputThemeOptions = {
  background: string;
  label: string;
  focus: {
    border: string;
  };
};

export type CodeInputMap = Record<CodeInputVariant, CodeInputThemeOptions>;

export type LinkThemeOptions = {
  background: string;
  text: string;
  hover: {
    text: string;
  };
  disabled: {
    text: string;
  };
  focus: {
    outline: string;
    border: string;
  };
  visited: {
    text: string;
  };
};

type LoaderPositionType = "relative" | "absolute";

export type LoaderThemeOptions = {
  position?: LoaderPositionType;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  loaderWidth?: string;
  loaderHeight?: string;
  width?: string;
  height?: string;
  margin?: string;
  border?: string;
  borderRadius?: string;
};

export type ActionItemsDropdownThemeOptions = {
  background: string;
  text: string;
  shadow: string;
};

export type RadioGroupThemeOptions = {
  background: string;
  text: string;
  border: string;
  hover: {
    border: string;
    background: string;
  };
  disabled: {
    border: string;
    background: string;
    text: string;
    checked: {
      background: string;
    };
  };
  checked: {
    background: string;
    border: string;
  };
  focus: {
    border: string;
  };
};

export type SearchbarWithSuggestionThemeOptions = SpecificErrorThemeOptions & {
  background: string;
  backgroundContainer: string;
  text: string;
  textPlaceholder: string;
  border: string;
  focus: {
    background: string;
    text: string;
    outline: string;
  };
  option: {
    background: string;
    text: string;
  };
  disabled: {
    color: string;
  };
  font: {
    style: string;
    weight: string;
    size: string;
    lineHeight: string;
  };
};

export type ToggleThemeOptions = {
  backgroundOn: string;
  backgroundOff: string;
  sliderOn: string;
  sliderOff: string;
  disabled: {
    background: string;
    sliderOn: string;
    sliderOff: string;
  };
  hover: {
    background: string;
    slider: string;
  };
  focus: {
    border: string;
  };
};

export type AcknowledgementAlertThemeOptions = {
  background: string;
  border: string;
  text: string;
};

export type ChatBubbleThemeOptions = {
  background: string;
  text: string;
  hover: {
    background: string;
  };
  timeText: string;
  attachmentBg: string;
  showMore: string;
  deletedMessage: {
    border?: string;
    text: string;
    background: string;
  };
  borderRadius: {
    top: string;
    middle: string;
    bottom: string;
  };
};

export type ChatBubbleThemeOptionsMap = Record<Owner, ChatBubbleThemeOptions>;

export type ClosableErrorThemeOptions = {
  background: string;
  text: string;
};

export type ClosableFeedbackThemeOptions = {
  background: string;
  text: string;
};

export type ConfirmationAlertThemeOptions = {
  background: string;
  text: string;
  accept: string;
  decline: string;
};

export type ContentSwitcherThemeOptions = {
  wrapper: {
    background: string;
    border: string;
  };
  tab: {
    background: string;
    backgroundSquared: string;
    color: string;
    active: {
      background: string;
      color: string;
      disabled: string;
    };
    hover: {
      background: string;
    };
    disabled: {
      color: string;
    };
    focus: {
      color: string;
    };
  };
};

export type ContextualAlertThemeOptions = {
  background: string;
  border: string;
  text: string;
};

export type ContextualErrorThemeOptions = {
  background: string;
  border: string;
  text: string;
};

export type ContextualFeedbackThemeOptions = {
  background: string;
  text: string;
};

export type LiveTagThemeOptions = {
  background: string;
  border: string;
  text: string;
  finished: {
    background: string;
    border: string;
    text: string;
  };
};

export type LabelThemeOptions = {
  closeColor: string;
  icon: string;
  variant: Record<LabelVariant, ElementColorOptions>;
};

export type LiveDurationThemeOptions = {
  background: string;
  text: string;
};

export type EventCardThemeOptions = {
  background: string;
  text: {
    color: string;
  };
  secondText: {
    color: string;
  };
  border: string;
  colorIcon: string;
  livetag: LiveTagThemeOptions;
  label: LabelThemeOptions;
  liveduration: LiveDurationThemeOptions;
};

export type EmptyListCardThemeOptions = {
  background: string;
};

export type GroupMemberThemeOptions = {
  container: {
    border: string;
    background: string;
  };
  label: LabelThemeOptions;
  avatar: CreateAvatarOptions;
  actionButton: ActionButtonThemeOptions;
};

export type LiveButtonThemeOptions = ElementColorOptions;

export type PageNavThemeOptions = {
  button: {
    active: {
      color: string;
      background: string;
    };
    color: string;
    background: string;
    border: string;
    hover: {
      border: string;
    };
    focus: {
      border: string;
    };
  };
};

export type PromoBannerThemeOptions = {
  background: string;
  color: string;
  link: string;
  button: {
    outline: string;
  };
};

export type ResourceProgressBarStateOptions = {
  background: string;
  icon: IconName;
  color: string;
};

export type ResourceProgressBarThemeOptions = {
  default: string;
  line: string;
  text: string;
  hover: string;
  finished: ResourceProgressBarStateOptions;
  failed: ResourceProgressBarStateOptions;
  canceled: ResourceProgressBarStateOptions;
};

export type SidebarGroupItem = {
  background: string;
  color: string;
  hover: {
    background: string;
  };
  notificationstat: {
    color: string;
    selected: string;
  };
  notification: {
    background: string;
    color: string;
    selected: string;
    selectedColor: string;
  };
  selected: {
    background: string;
    color: string;
  };
  loading: {
    color: string;
    selectedColor: string;
  };
};

export type ToastThemeOptions = {
  success: {
    background: string;
    color: string;
  };
  warning: {
    background: string;
    color: string;
  };
  error: {
    background: string;
    color: string;
  };
  icon: {
    dark: {
      color: string;
    };
    light: {
      color: string;
    };
  };
  button: {
    outline: string;
  };
};

export type ToolbarMenuItem = {
  background: string;
  color: string;
  selected: {
    background: string;
  };
  disabled: {
    color: string;
  };
  hover: {
    color: string;
  };
  dot: {
    background: string;
    border: string;
  };
};

export type ToolbarSpaceItem = {
  border: string;
  selected: {
    border: string;
  };
  counter: {
    backgroundColor: string;
    textColor: string;
    border: string;
  };
};

export type TooltipSide = "top" | "bottom" | "left" | "right";

export type TooltipPositionOptions = {
  borderWidth: Property.BorderWidth;
  borderColor: Property.BorderColor;
};

export type TooltipThemeOptions = ElementColorOptions & {
  [key in TooltipSide]: TooltipPositionOptions;
};

export type WrapperChatAvatarThemeOptions = {
  background: string;
  text: string;
  message: string;
  time: string;
  active: ElementColorOptions;
};

export type ZIndexThemeOptions = {
  regular: 1;
  behind: -1;
  average: 5;
  high: 20;
  higher: 999;
  highest: 99999;
};

export interface DSTheme extends SpecificErrorThemeOptions {
  avatar: CreateAvatarOptions;
  divider: DividerThemeMap;
  checkindicator: CheckIndicatorMap;
  doodleloader: DoodleLoaderMap;
  actionButton: ActionButtonsMap;
  progressbar: ProgressBarMap;
  picker: PickerMap;
  sidebarItem: SidebarItemMap;
  sidemenu: SideMenuMap;
  post: PostStylesMap;
  button: ButtonsMap;
  card: {
    cardactivity: CardActivityThemeOptions;
    cardaddcontent: CardAddContentThemeOptions;
    cardcontent: CardContentThemeOptions;
    cardgroup: CardGroupThemeOptions;
    cardlesson: CardLessonThemeOptions;
    cardschool: CardSchoolThemeOptions;
  };
  checkbox: CheckboxThemeOptions;
  chip: ChipThemeOptions;
  ctaTertiary: CtaTertiaryThemeOptions;
  dropdown: DropdownThemeOptions;
  searchbar: SearchbarThemeOptions;
  dropdownContextual: DropdownContextualThemeOptions;
  tag: TagThemeOptions;
  googleButton: GoogleButtonThemeOptions;
  socialLoginButton: SocialLoginButtonThemeOptions;
  input: InputThemeOptions;
  pictureUploader: PictureUploaderThemeOptions;
  codeInput: CodeInputMap;
  link: LinkThemeOptions;
  genericLoader: LoaderThemeOptions;
  radiogroup: RadioGroupThemeOptions;
  searchbarWithSuggestion: SearchbarWithSuggestionThemeOptions;
  toggle: ToggleThemeOptions;
  aknowledgmentalert: AcknowledgementAlertThemeOptions;
  chatbubble: ChatBubbleThemeOptionsMap;
  closableerror: ClosableErrorThemeOptions;
  closablefeedback: ClosableFeedbackThemeOptions;
  confirmationalert: ConfirmationAlertThemeOptions;
  contentswitcher: ContentSwitcherThemeOptions;
  contextualerror: ContextualErrorThemeOptions;
  contextualfeedback: ContextualFeedbackThemeOptions;
  contextualalert: ContextualAlertThemeOptions;
  eventcard: EventCardThemeOptions;
  emptylistcard: EmptyListCardThemeOptions;
  livetag: LiveTagThemeOptions;
  liveduration: LiveDurationThemeOptions;
  label: LabelThemeOptions;
  groupmember: GroupMemberThemeOptions;
  livebutton: LiveButtonThemeOptions;
  actionitemsdropdown: ActionItemsDropdownThemeOptions;
  pagenav: PageNavThemeOptions;
  promobanner: PromoBannerThemeOptions;
  resourceprogressbar: ResourceProgressBarThemeOptions;
  sidebargroupitem: SidebarGroupItem;
  toast: ToastThemeOptions;
  toolbarmenuitem: ToolbarMenuItem;
  toolbarspaceitem: ToolbarSpaceItem;
  tooltip: TooltipThemeOptions;
  wrapperchatavatar: WrapperChatAvatarThemeOptions;
  zIndex: ZIndexThemeOptions;
}
