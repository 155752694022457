import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import { LoginPage } from "src/js/pages/unlogged/LoginPage";
import { SSOProvider } from "src/js/types";

const LoginSSOPage = () => {
  const location = useLocation();
  const { email, provider, tenantId } = queryString.parse(location.search);
  return (
    <LoginPage
      email={email as string}
      provider={provider as SSOProvider}
      tenantId={tenantId as string}
    />
  );
};

export default LoginSSOPage;
