import {
  ActionButton,
  Box,
  Button,
  ConfirmationAlert,
  ContextualError,
  Heading,
  Text
} from "@arcadia/design-system";
import createReactClass from "create-react-class";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { render } from "react-dom";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { useStores } from "src/js/hooks";
import { withConfirmDialogStore, withModalStore } from "src/js/hooks/useStores";
import { withSpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { getQueryParamFromFragment } from "src/js/modules/localization";
import renderLatex from "src/js/modules/mathjaxFunction";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { ExerciseModalSettings } from "src/js/pages/exercise/ExerciseModalSettings";
import ExercisePublishConfirmModal from "src/js/pages/exercise/ExercisePublishConfirmModal";
import ThemeProvider from "src/js/theme/ThemeProvider";
import { Translate } from "src/js/translation/TranslationProvider";
import styled, { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import QuizCollection from "../../collections/quizzesCollection";
import ExerciseModel from "../../models/exerciseModel";
import QuizModel from "../../models/quiz/quizModel";
import { getActiveGroup, getActiveGroupId } from "../../modules/activeGroup";
import { InternalDispatcher } from "../../modules/dispatcher";
import { navigateTo } from "../../modules/history";
import { __ } from "../../modules/localization";
import { basePageModel } from "../../modules/pageStatus";
import { createURL } from "../../modules/utility";
import QuizList from "./QuizList";
import QuizBuilder from "./quiz/quizBuilder";

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
  margin-bottom: 8px;
  display: inline-block;
`;

const InputWrapper = styled.div`
  input {
    text-align: center;
    width: 70px;
  }
`;

const PageWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: -52px;
  }
`;

const StyledCaption = styled.div`
  margin-left: 32px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;

const StyledInputWrapper = styled.div`
  input {
    max-width: 100% !important;
    &:hover {
      border: 1.5px solid ${({ theme }) => theme.primaryColor[800]} !important;
      background-color: ${({ theme }) => theme.primaryColor[800]} !important;
    }

    &:focus {
      outline: 2px solid ${({ theme }) => theme.primaryColor[800]} !important;
      border: 1.5px solid ${({ theme }) => theme.primaryColor[500]} !important;
    }
  }
`;

const ExerciseContainer = observer(({ children }) => {
  const { UIStore } = useStores();

  useEffect(() => {
    UIStore.hideMobileHeader();

    return () => UIStore.showMobileHeader();
  }, []);

  return (
    <div
      className={`${UIStore.isSideBarOpen ? "exercise-builder-footer-wrapper--sidebar-open" : ""}`}
    >
      {children}
    </div>
  );
});

const ExerciseBuilder = withBackbone(
  createReactClass({
    form: "#form-exercise-builder",
    getDefaultProps() {
      return {
        exercise: new ExerciseModel()
      };
    },
    getInitialState() {
      const self = this;
      return {
        type: self.props.type || "ASSIGNMENT",
        status: "",
        quizStatus: "",
        title: self.props.exercise.get("title"),
        description: self.props.exercise.get("description"),
        time_threshold: self.props.exercise.getTimeThreshold() / 60,
        correction_type: self.props.exercise.get("correction_type"),
        correction_side: self.props.exercise.getCorrectionSide(),
        repeatable: self.props.exercise.isRepeatable(),
        public_result: self.props.exercise.isPublic(),
        success_threshold: self.props.exercise.get("success_threshold") || 70,
        isNew: self.props.exercise.isNew(),
        publishCall: false,
        errorMessage: null
      };
    },
    componentWillMount() {
      const self = this;
      const exercise = new ExerciseModel();
      const exerciseId = this.props.match.params.exercise_id;
      if (typeof exerciseId !== "undefined") {
        exercise.set({ id: exerciseId });
        exercise.fetch({
          success() {
            self.setState({
              type: exercise.get("type") || "ASSIGNMENT",
              status: "",
              quizStatus: "",
              title: exercise.get("title"),
              description: exercise.get("description"),
              time_threshold: exercise.getTimeThreshold() / 60,
              correction_type: exercise.get("correction_type"),
              correction_side: exercise.getCorrectionSide(),
              repeatable: exercise.isRepeatable(),
              public_result: exercise.isPublic(),
              success_threshold: exercise.get("success_threshold") || 70,
              isNew: exercise.isNew(),
              publishCall: false
            });
          }
        });
      } else {
        const title =
          self.state.type == "EXAMINATION"
            ? __("Nuovo esercizio")
            : __("examination_create_title");
        exercise.set({
          title,
          type: self.state.type
        });
      }
      self.setState({
        exercise
      });
    },
    componentDidMount() {
      const self = this;
      this.updateBasePageModel();

      renderLatex();
      $("#form-exercise-builder").on("submit", e => {
        e.preventDefault();
      });
      this.initValidation();

      if (this.state.isNew) {
        $(`${self.form} input[name="title"]`).one("click", () => {
          self.setState({
            title: "",
            isNew: false
          });
        });
      }
      this.checkRefresh();
      this.updateTitle();
    },
    componentDidUpdate() {
      this.updateBasePageModel();
      renderLatex();
    },
    componentWillUnmount() {
      this.props.spaceGroupsLayout.resetHeaderComponent();
    },
    updateTitle() {
      const exercise = this.state.exercise;
      let title;
      if (exercise.has("id")) {
        title = this.state.title;
      } else if (
        exercise.has("type") &&
        exercise.get("type") == "EXAMINATION"
      ) {
        title = __("exercises_examinations_new_meta_title");
      } else {
        title = __("exercises_new_meta_title");
      }
      const back_function = () => {
        let back_url = createUrl("exercises_drafts", {
          group_id: getActiveGroupId()
        });
        if (exercise.isPublished()) {
          back_url = createUrl("exercises", { group_id: getActiveGroupId() });
        }
        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          navigateTo(
            createUrl("module_detail", {
              group_id: getActiveGroupId(),
              module_id: moduleId
            })
          );
          return false;
        }
        navigateTo(back_url, { trigger: true });
        return false;
        /** avoid wrong loading of wall route */
      };
      this.props.spaceGroupsLayout.setHeaderComponent(
        <HeaderBack onGoBack={back_function} />
      );
    },
    checkRefresh() {
      if (typeof window.fromNew !== "undefined" && window.fromNew == true) {
        $("#addQuiz").click();
        delete window.fromNew;
      }
    },
    setLeaveAlert(obj) {
      if (typeof obj == "undefined") {
        obj = {
          status: true,
          text: "exercise_leave_confirmation_message"
        };
      }
      InternalDispatcher.trigger("leaveAlertCheck", obj);
    },
    updateBasePageModel() {
      const exercise = this.state.exercise;
      let title;
      const back = __("back");
      if (exercise.has("id")) {
        title = exercise.get("title");
      } else if (
        exercise.has("type") &&
        exercise.get("type") == "EXAMINATION"
      ) {
        title = __("exercises_examinations_new_meta_title");
      } else {
        title = __("exercises_new_meta_title");
      }

      const back_function = function () {
        let back_url = createUrl("exercises_drafts", {
          group_id: getActiveGroupId()
        });

        if (exercise.isPublished()) {
          back_url = createUrl("exercises", { group_id: getActiveGroupId() });
        }
        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          navigateTo(
            createUrl("module_detail", {
              group_id: getActiveGroupId(),
              module_id: moduleId
            })
          );
          return;
        }
        navigateTo(back_url, { trigger: true });
        return false;
        /** avoid wrong loading of wall route */
      };
      basePageModel.set({
        selectedTab: "custom",
        backFunc: {
          back,
          back_function,
          title
        }
      });
    },
    submitForm() {
      $("#form-exercise-builder").submit();
    },
    initValidation() {
      const self = this;

      $.validate({
        form: "#form-exercise-builder",
        modules: "date, security",
        borderColorOnError: "",
        onError() {
          self.setState({
            errorMessage: (
              <Translate text="exercise_test_title_length_validation_error" />
            )
          });
        },
        onSuccess() {
          self.saveExercise();
          return false;
        }
      });
    },
    updateQuizOrder(quizzes) {
      const self = this;
      const data = JSON.stringify({
        quizzes: quizzes.map((quiz, i) => ({
          id: quiz.get("id"),
          order: i
        }))
      });
      $.ajax({
        type: "PATCH",
        dataType: "json",
        contentType: "application/json",
        url: createURL("api_exercises_quizzes_reorder", {
          exercise_id: this.state.exercise.get("id")
        }),
        data,
        success(quizzes) {
          self.state.exercise.set("quizzes", quizzes);
          self.setState({ quizStatus: "" });
        },
        error(ret) {
          const errorMsg = extractErrorMessage(
            ret,
            "Attenzione, si è verificato un errore"
          );
          showToastError({ str: <Translate text={errorMsg} /> });
          self.setState({ quizStatus: "" });
        }
      });
    },
    showQuizBuilder(quiz, altAddFunc) {
      if (this.state.exercise.isCreated()) {
        const back_function = function () {
          this.hideQuizBuilder();
        }.bind(this);

        basePageModel.set({
          selectedTab: "custom",
          backFunc: {
            back: __("back"),
            back_function,
            title: this.state.exercise.get("title"),
            buttons: undefined
          }
        });

        let quizModel = new QuizModel();
        let addFunc = this.addQuiz;

        if (quiz != undefined) {
          quizModel = quiz;
        }
        if (typeof altAddFunc === "function") {
          addFunc = altAddFunc;
        }

        render(<div />, $(".quiz-builder-container").get(0));
        render(
          <ThemeProvider>
            <QuizBuilder
              addFunc={addFunc}
              editFunc={this.editQuiz}
              backFunc={this.hideQuizBuilder}
              quiz={quizModel}
              exercise={this.state.exercise}
            />
          </ThemeProvider>,
          $(".quiz-builder-container").get(0)
        );

        $(".main").addClass("hidden");
        $(".quiz-builder-container").removeClass("hidden");
      } else {
        window.fromNew = true;
        this.saveExercise(this.showQuizBuilder.bind(this, undefined));
      }
    },
    hideQuizBuilder() {
      $(".main").removeClass("hidden");
      $(".quiz-builder-container").addClass("hidden");
      render(<div />, $(".quiz-builder-container").get(0));
      this.updateBasePageModel(); // ripristino back iniziale
    },
    saveExercise(optionalCallbackOk, optionalCallbackError) {
      const self = this;
      let title = self.state.title;
      let messageText = __("Esercizio salvato con successo");

      let creating = false;
      if (self.state.exercise.isNew()) creating = true;

      if (self.state.exercise.isVerification()) {
        messageText = __("examination_save_success");
      }
      if (typeof title === "undefined") {
        title =
          self.state.type == "EXAMINATION"
            ? __("examination_create_title")
            : __("Nuovo esercizio");
      }
      self.setState({ status: "loading" });
      self.state.exercise.set("group_id", getActiveGroupId());
      self.state.exercise.set("title", title);
      self.state.exercise.save(null, {
        success() {
          showToastSuccess({ str: messageText });
          self.setState({ status: "" });
          if (typeof optionalCallbackOk === "function") optionalCallbackOk();

          if (creating) {
            _trackEvent(
              "Assignments",
              "AssignmentsNewDraft",
              self.state.exercise.isExercise() ? "DraftExercise" : "DraftTest"
            );
            navigateTo(
              `${createUrl("exercises_edit", {
                group_id: getActiveGroupId(),
                exercise_id: self.state.exercise.get("id")
              })}`,
              { trigger: false }
            );
          } else if (self.state.publishCall) {
            self.publishCall();
          }
        },
        error(model, data) {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            "Attenzione, si è verificato un errore"
          );
          showToastError({ str: <Translate text={errorMsg} /> });
          self.setState({ status: "" });
          if (typeof optionalCallbackError === "function")
            optionalCallbackError();
        }
      });
      this.updateTitle();
      self.setLeaveAlert({
        status: false,
        text: ""
      });
    },
    addQuiz(quiz, optionalCallbackOk, optionalCallbackError, options) {
      // quiz.position =  this.state.exercise.getQuizzes().length;
      const callbackOk = function (data) {
        const self = this; // this viene bindato al valore corretto durante la chiamata
        this.state.exercise.fetch({
          success(model, response, options) {
            self.forceUpdate();
            if (options === undefined || options.redirect === true)
              self.hideQuizBuilder();
            if (options === undefined || options.showAlert === true)
              showToastSuccess({ str: __("Quiz aggiunto con successo") });
            if (typeof optionalCallbackOk === "function")
              optionalCallbackOk(model, response, options, data);
          },
          error() {
            // non posso refreshare model...?
            if (options === undefined || options.redirect === true)
              self.hideQuizBuilder();
            if (options === undefined || options.showAlert === true)
              showToastSuccess({ str: __("quiz aggiunto") });
          }
        });
      };
      const callbackError = function (data) {
        if (typeof data.validation_errors !== "undefined") {
          const validationTitleError = data.validation_errors.find(
            element => element.property_path === "title"
          );
          if (typeof validationTitleError !== "undefined") {
            data.message_id = "_err_q_question_empty_";
            data.message = "Attenzione: inserisci una domanda";
          }
        }
        const errorMsg = extractErrorMessage(
          data,
          "Attenzione, si è verificato un errore"
        );
        showToastError({ str: <Translate text={errorMsg} /> });
        if (typeof optionalCallbackError === "function")
          optionalCallbackError();
      };
      this.state.exercise.addQuiz(quiz, callbackOk.bind(this), callbackError);
    },
    editQuiz(quiz, optionalCallbackOk, optionalCallbackError, options) {
      const self = this; // this viene bindato al valore corretto durante la chiamata
      const callbackOk = function (data) {
        let exerciseNew = self.state.exercise;
        exerciseNew.fetch({
          success(model, response, options) {
            if (options === undefined || options.redirect === true)
              self.hideQuizBuilder();
            if (options === undefined || options.showAlert === true)
              showToastSuccess({ str: __("quiz modificato") });
            if (typeof optionalCallbackOk === "function")
              optionalCallbackOk(model, response, options, data);
            self.forceUpdate();
          },
          error() {
            // non posso refreshare model...?
            if (options === undefined || options.redirect === true)
              self.hideQuizBuilder();
            if (options === undefined || options.showAlert === true)
              showToastSuccess({ str: __("quiz modificato") });
          }
        });
      };
      const callbackError = function (data) {
        const errorMsg = extractErrorMessage(
          data,
          "Attenzione, si è verificato un errore"
        );
        showToastError({ str: <Translate text={errorMsg} /> });
        if (typeof optionalCallbackError === "function")
          optionalCallbackError();
      };
      this.state.exercise.editQuiz(quiz, callbackOk.bind(this), callbackError);
    },
    removeQuiz(quiz) {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      const callbackOk = function () {
        self.state.exercise.fetch({
          success() {
            showToastSuccess({ str: __("Quiz rimosso con successo") });
            self.forceUpdate();
          },
          error() {
            showToastSuccess({ str: __("Quiz rimosso con successo") });
          }
        });
      };
      const callbackError = function (data) {
        const errorMsg = extractErrorMessage(
          data,
          "Attenzione, si è verificato un errore"
        );
        showToastError({ str: <Translate text={errorMsg} /> });
      };

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={__("Il quiz sarà eliminato")}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            self.state.exercise.removeQuiz(
              quiz,
              callbackOk.bind(this),
              callbackError.bind(this)
            );
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    publish() {
      const self = this;
      $("#form-exercise-builder").submit();
      self.setState({
        publishCall: true
      });
    },
    publishCall() {
      const self = this;
      const { exercise } = self.state;
      const exerciseIsVerification = exercise.isVerification();
      let messageText = exerciseIsVerification
        ? "exercise_publish_examination_success"
        : "exercise_publish_test_success";

      const callbackOk = () => {
        const exerciseTypeEvent = exercise.isExercise()
          ? "PublishedExercise"
          : "PublishedTest";
        const numberOfQuestions = exercise.getQuizzes().length;
        _trackEvent(
          "Assignments",
          "AssignmentsPublished",
          exerciseTypeEvent,
          numberOfQuestions
        );
        self.setState({
          publishCall: false,
          status: ""
        });
        showToastSuccess({ str: <Translate text={messageText} /> });
        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          navigateTo(
            createUrl("module_detail", {
              group_id: getActiveGroupId(),
              module_id: moduleId
            })
          );
          return;
        }
        navigateTo(createUrl("exercises", { group_id: getActiveGroupId() }));
      };

      const callbackError = data => {
        if (data.message_id === "error_e_not_publish_without_quiz") {
          if (exerciseIsVerification) {
            showToastError({
              str: <Translate text="error_v_not_publish_without_quiz" />
            });
          } else {
            showToastError({
              str: <Translate text="error_e_not_publish_without_quiz" />
            });
          }
        }
        self.setState({
          publishCall: false,
          status: ""
        });
      };
      self.state.exercise.publish(
        callbackOk.bind(self),
        callbackError.bind(self)
      );
    },
    unpublish() {
      this.setState({ status: "loading" });
      const callbackOk = function () {
        this.setState({ status: "" }); // this viene bindato al valore corretto durante la chiamata
        showToastError({
          str: <Translate text="L\'esercizio è tornato tra i draft" />
        });
        navigateTo(
          createUrl("exercises_drafts", { group_id: getActiveGroupId() }),
          { trigger: true }
        );
      };
      const callbackError = function (data) {
        this.setState({ status: "" }); // this viene bindato al valore corretto durante la chiamata
        const errorMsg = extractErrorMessage(
          data,
          "Attenzione, si è verificato un errore"
        );
        showToastError({ str: <Translate text={errorMsg} /> });
      };
      this.state.exercise.unpublish(
        callbackOk.bind(this),
        callbackError.bind(this)
      );
    },
    doInstant() {
      if (this.state.time_threshold === 0) {
        showToastError({ str: __("Inserire un tempo massimo") });
      } else {
        this.setState({ status: "loading" });

        const callbackOk = function () {
          this.setState({ status: "" }); // this viene bindato al valore corretto durante la chiamata
          showToastSuccess({ str: __("Instant lanciato") });
        };
        const callbackError = function (data) {
          this.setState({ status: "" }); // this viene bindato al valore corretto durante la chiamata
          const errorMsg = extractErrorMessage(
            data,
            "Attenzione, si è verificato un errore"
          );
          showToastError({ str: <Translate text={errorMsg} /> });
        };

        const doInstant = function () {
          this.state.exercise.doInstant(
            callbackOk.bind(this),
            callbackError.bind(this)
          );
        };

        this.saveExercise(doInstant.bind(this));
      }
    },
    remove() {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;
      let alertText = __("L'esercizio sarà eliminato");
      let messageText = __("Esercizio eliminato con successo");

      if (self.state.exercise.get("type") == "EXAMINATION") {
        alertText = __("examination_delete_alert");
        messageText = __("examination_delete_success");
      }

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={alertText}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            self.setLeaveAlert({
              status: false,
              text: ""
            });
            ConfirmDialogStore.closeConfirmDialog();
            self.state.exercise.destroy({
              wait: true,
              success() {
                showToastSuccess({ str: messageText });

                let route = "exercises";
                if (!self.state.exercise.isPublished())
                  route = "exercises_drafts";
                const goToUrl = createUrl(route, {
                  group_id: getActiveGroupId()
                });
                const moduleId = getQueryParamFromFragment("module");
                if (moduleId) {
                  navigateTo(
                    createUrl("module_detail", {
                      group_id: getActiveGroupId(),
                      module_id: moduleId
                    })
                  );
                  return;
                }
                navigateTo(goToUrl, { trigger: true });
              },
              error(model, data) {
                const errorMsg = extractErrorMessage(
                  data.responseJSON,
                  "Attenzione, si è verificato un errore"
                );
                showToastError({ str: <Translate text={errorMsg} /> });
              }
            });
          }}
        />
      );
    },
    requestConfirm() {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={__("edit_constrainted_exercise")}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            self.submitForm();
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    showEditQuiz(quiz) {
      this.showQuizBuilder(quiz, this.editQuiz);
    },
    render() {
      const self = this;
      const group = getActiveGroup();
      const { ModalStore } = this.props;
      const { type, errorMessage } = this.state;
      let exerciseBuilderContent = "";
      let exerciseBuilderFooter = "";
      let exerciseBuilderTimeBox = "";
      const exercise = this.state.exercise;
      const exerciseId = exercise.get("id");
      const quizzes = new QuizCollection(this.state.exercise.getQuizzes());
      let quizList = null;
      let quizCountBox = "";
      let exerciseBuilderQuizBox = "";
      let previewButton = "";
      let publishButtons = "";
      let deleteBtn = "";
      let titlePlaceholder =
        type === "EXAMINATION"
          ? __("examination_create_title")
          : __("Nuovo esercizio");

      const saveFunction = self.submitForm;

      let exerciseTypeRadioClass = "";

      const openExercisePublishConfirmModal = () => {
        ModalStore.openModal(() => (
          <ExercisePublishConfirmModal
            exercise={exercise.attributes}
            callbackOk={self.publish}
            closeModal={() => ModalStore.closeModal()}
          />
        ));
      };

      if (exercise.has("id")) {
        // TODO: useHistory instead of navigateTo
        previewButton = (
          <Button
            onClick={() =>
              navigateTo(
                createUrl("exercises_fake", {
                  group_id: getActiveGroupId(),
                  exercise_id: exercise.get("id")
                })
              )
            }
            theme={this.props.theme.whiteTheme}
            variant="secondary"
          >
            <Translate text="ANTEPRIMA" />
          </Button>
        );
      }

      if (quizzes.length > 0) {
        let quizCountLabel = __("quizzes");
        if (quizzes.length == 1) {
          quizCountLabel = __("quiz");
        }
        quizCountBox = (
          <div className="app__subtitle exercise-builder-quiz-count">
            {quizzes.length} {quizCountLabel}
          </div>
        );
        quizList = (
          <QuizList
            quizzes={quizzes}
            quizStatus={this.state.quizStatus}
            showEditQuiz={this.showEditQuiz}
            removeQuiz={this.removeQuiz}
            updateQuizOrder={this.updateQuizOrder}
          />
        );
      }

      if (exercise.isCreated() && !exercise.isPublished()) {
        publishButtons = (
          <Button
            onClick={openExercisePublishConfirmModal}
            theme={this.props.theme.whiteTheme}
            variant="primary"
          >
            <Translate text="PUBBLICA" />
          </Button>
        );
      }

      if (group.isTeacher()) {
        deleteBtn = (
          <Button
            onClick={this.remove}
            theme={this.props.theme.whiteTheme}
            variant="secondary"
          >
            <Translate text="elimina" />
          </Button>
        );
      }

      exerciseBuilderQuizBox = (
        <Box marginTop="16px">
          {quizCountBox}
          {quizList}
          <Box
            margin="32px 0px 16px"
            display="flex"
            justifyContent="space-between"
          >
            {deleteBtn}
            <Button
              onClick={() => {
                this.showQuizBuilder(new QuizModel());
              }}
              theme={this.props.theme.whiteTheme}
              variant="secondary"
              id="addQuiz"
            >
              <Translate text="AGGIUNGI QUIZ" />
            </Button>
          </Box>
        </Box>
      );

      if (this.state.exercise.hasConstraint()) {
        exerciseTypeRadioClass = "hidden";
      }

      const showSettingsModal = () => {
        if (this.state.isNew) {
          showToastError({
            str: <Translate text="exercise_settings_mandatory_save" />
          });
        } else {
          ModalStore.openModal(() => (
            <ExerciseModalSettings
              closeModal={() => {
                self.state.exercise.fetch({
                  success() {
                    self.setState({
                      type: exercise.get("type") || "ASSIGNMENT",
                      time_threshold: exercise.getTimeThreshold() / 60,
                      correction_type: exercise.get("correction_type"),
                      correction_side: exercise.getCorrectionSide(),
                      repeatable: exercise.isRepeatable()
                    });
                    self.forceUpdate();
                  },
                  error() {}
                });
                ModalStore.closeModal();
              }}
              exerciseId={exerciseId}
            />
          ));
        }
      };

      exerciseBuilderContent = (
        <div className="exercise-builder-content-wrapper">
          <form id="form-exercise-builder" method="post">
            <div className={[exerciseTypeRadioClass].join(" ")}>
              <div className="quiz-radio-group">
                <Box
                  width="100%"
                  height={40}
                  display="flex"
                  margin="8px 0px"
                  justifyContent="space-between"
                >
                  <Heading level="3">
                    <Translate
                      text={
                        self.state.isNew
                          ? "exercise_new_title"
                          : "exercise_edit_title"
                      }
                    />
                  </Heading>
                  <ActionButton
                    theme={this.props.theme.whiteTheme}
                    variant="secondary"
                    onClick={showSettingsModal}
                    icon="gear"
                  />
                </Box>
              </div>
            </div>
            <StyledInputWrapper width="100%">
              <div className="app__box">
                <StyledText type="formSubtitle">
                  <Translate text="TITOLO" />
                </StyledText>
                <div>
                  <input
                    type="text"
                    className="form-control primary"
                    name="title"
                    defaultValue={titlePlaceholder}
                    value={self.state.title}
                    onChange={e =>
                      self.setState({
                        title: e.target.value,
                        errorMessage: null
                      })
                    }
                    data-validation="length"
                    data-validation-length="1-200"
                    data-validation-error-msg=" "
                  />
                </div>
                {errorMessage && (
                  <ContextualError
                    theme={self.props.theme.whiteTheme}
                    text={errorMessage}
                  />
                )}
                {exerciseBuilderTimeBox}
              </div>
            </StyledInputWrapper>
          </form>
          {exerciseBuilderQuizBox}
        </div>
      );

      let saveButton = (
        <Button
          onClick={saveFunction}
          theme={this.props.theme.whiteTheme}
          variant="primary"
          type="submit"
        >
          <Translate text="Salva" />
        </Button>
      );

      exerciseBuilderFooter = (
        <ExerciseContainer>
          <Box
            display="flex"
            flexFlow="row"
            gap="8px"
            justifyContent="flex-end"
          >
            {previewButton}
            {saveButton}
            {publishButtons}
          </Box>
        </ExerciseContainer>
      );

      return (
        <>
          <div className="main">
            <div className="exercise-builder-content">
              {exerciseBuilderContent}
              <div className="clearfix" />
            </div>
            <Box marginBottom={16}>{exerciseBuilderFooter}</Box>
          </div>
          <div className="quiz-builder-container" />
        </>
      );
    }
  })
);

export default withTheme(
  withConfirmDialogStore(withModalStore(withSpaceGroupsLayout(ExerciseBuilder)))
);
