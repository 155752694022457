import { __STROKE_BACKGROUND__ } from "src/js/settings/settingsImage";
import styled, { css } from "styled-components";
import { IsMobileCentered } from "./OnboardingLayout.types";

export const DesktopWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.basicWhite};
  display: flex;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[900]};
    background-image: url(${__STROKE_BACKGROUND__});
    height: 100dvh;
    background-size: contain;
  }
`;

export const InnerContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MainColumn = styled.div<IsMobileCentered>`
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    justify-content: initial;
    width: 100%;
    ${({ mobileCentered }) =>
      mobileCentered &&
      css`
        justify-content: center;
      `}
  }
`;

export const TestimonialColumn = styled.div`
  width: 50%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TopSection = styled.div<IsMobileCentered>`
  position: absolute;
  top: 0;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px;
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    padding: 22px;
    ${({ mobileCentered }) =>
      mobileCentered &&
      css`
        position: absolute;
      `}
  }
`;

export const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  width: 100%;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const LangSelectorWrapper = styled.div`
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  & div {
    border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  }
  @media screen and (max-width: 768px) {
    color: ${({ theme }) => theme.colors.violet[500]};
    left: 16px;
    & div {
      border: 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  max-width: 450px;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
