import { Box, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { __DEMO_BADGE__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import * as THSM from "../TopHeaderSpacesMobile/TopHeaderSpacesMobile.styles";
import * as JDS from "./JoinDemoSpace.styles";
import { DemoSpaceLayoutProps } from "./JoinDemoSpace.types";
import { LoadingDemoSpace } from "./components";
import { useDemoSpaceGeneration } from "./hooks";

/**
 * @name JoinDemoSpace
 *
 * @description JoinDemoSpace manages the generation process of a demo space.
 */

const JoinDemoSpace = ({
  isMobile,
  isTopBanner,
  children
}: DemoSpaceLayoutProps) => {
  const {
    SpaceStore: { spacesList }
  } = useStores();
  const { translate } = useTranslation();

  const {
    isJoinDemoSpaceCtaVisible,
    isBlockingLoaderVisible,
    handleJoinDemoSpace
  } = useDemoSpaceGeneration();

  const desktopCtaTopBanner = (
    <Box id="join-icon-container" cursor="pointer">
      <JDS.JoinDemoSpace
        id="toolbarSpaceDemo"
        onClick={handleJoinDemoSpace}
        isMobile={isMobile}
        isTopBanner={isTopBanner}
      >
        <Text>{translate("join_demo_space_text")}</Text>
      </JDS.JoinDemoSpace>
    </Box>
  );

  const mobileCta = isJoinDemoSpaceCtaVisible ? (
    <THSM.Space
      id="toolbarSpaceDemo"
      key="join-demo-space-cta"
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      onClick={() => {}}
      transition={{
        delay: spacesList.length * 0.15
      }}
      isActive={false}
    >
      <THSM.SpaceAvatar isActive={false}>
        <JDS.JoinDemoSpace
          onClick={handleJoinDemoSpace}
          isMobile={isMobile}
          isTopBanner={isTopBanner}
        >
          <img alt="demo-space" src={__DEMO_BADGE__} />
        </JDS.JoinDemoSpace>
      </THSM.SpaceAvatar>
      <THSM.SpaceTitle>
        {translate("toolbar_join_demo_space_mobile_placeholder")}
      </THSM.SpaceTitle>
    </THSM.Space>
  ) : null;

  const desktopCta = isTopBanner ? (
    desktopCtaTopBanner
  ) : (
    <Box onClick={handleJoinDemoSpace}>{children}</Box>
  );

  return (
    <>
      {isBlockingLoaderVisible && <LoadingDemoSpace />}
      <Box>{isMobile ? mobileCta : desktopCta}</Box>
    </>
  );
};

export default observer(JoinDemoSpace);
