import { useRouteMatch } from "react-router";
import { useSetBoardId } from "src/js/context/BoardProvider";

/**
 * @name MatchModuleRoute
 *
 * @description
 * ...
 */
const MatchModuleRoute = () => {
  const setBoardId = useSetBoardId();
  const matchBoard = useRouteMatch(
    "/:space_slug?/group/:group_id/modules/:module_id/"
  );

  if (matchBoard) {
    // works if board_id is a number
    if (!parseInt(matchBoard.params.module_id, 10)) return null;
    setBoardId(matchBoard.params.module_id);
  } else {
    setBoardId(null);
  }

  return null;
};

export default MatchModuleRoute;
