import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";

const QuizFillblank = withBackbone(
  createReactClass({
    getInitialState() {
      return {
        answers: this.props.quiz.getUserAnswer()
      };
    },
    defaultInputSize: 10,
    cleanAnswer(answer) {
      return answer.replace(/\s+/g, "");
    },
    setAnswer(e) {
      const answer = this.cleanAnswer(e.target.value);
      const answers = this.state.answers == "" ? {} : this.state.answers;
      answers[e.target.getAttribute("data-index")] = answer;
      this.props.quiz.setUserAnswer(answers);
      this.setState({ answers });

      if (answer.length > this.defaultInputSize) {
        answers[e.target.setAttribute("size", answer.length)];
      } else {
        answers[e.target.setAttribute("size", this.defaultInputSize)];
      }
    },
    render() {
      const self = this;
      const quiz = this.props.quiz;
      const questions = quiz.getQuestions();
      const answers = this.state.answers;
      const words = map(questions, w => {
        if (w.value === "") {
          let defValue = "";
          if (answers[w.index] !== undefined) defValue = answers[w.index];
          return (
            <span>
              <input
                className="quiz__fillblanks-input"
                type="text"
                size={self.defaultInputSize}
                defaultValue={defValue}
                onChange={self.setAnswer}
                data-index={w.index}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </span>
          );
        }
        return (
          <span>
            <span>{w.value}</span>{" "}
          </span>
        );
      });
      return <div className="quiz__fillblanks">{words}</div>;
    }
  })
);

export default QuizFillblank;
