import React from "react";
import * as S from "./ExerciseThreadDarkBox.styles";

const ExerciseThreadDarkBox = ({
  flex = true,
  children,
  isSelectable,
  isSelected,
  ...props
}) => {
  return (
    <S.ExerciseThreadDarkBoxLayout
      flex={flex}
      isSelectable={isSelectable}
      isSelected={isSelected}
      {...props}
    >
      {children}
    </S.ExerciseThreadDarkBoxLayout>
  );
};

export default ExerciseThreadDarkBox;
