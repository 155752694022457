import React from "react";
import { translateString } from "src/js/translation/TranslationProvider";
import * as S from "./NoMessages.styles";

const NoMessages = () => {
  return (
    <S.NoMessagesContainer>
      <S.NoMessagesAnimation />
      <S.NoMessagesHeading>
        {translateString({ text: "chat_no_messages_in_conversation_title" })}
      </S.NoMessagesHeading>
      <S.NoMessagesText type="body">
        {translateString({
          text: "thread_no_messages_in_conversation_text"
        })}
      </S.NoMessagesText>
    </S.NoMessagesContainer>
  );
};

export default NoMessages;
