import { ImageModel, ObjectValues, ResourceType } from "src/js/types";

export type CalendarEventResource = {
  id: number;
  name: string;
  type: ResourceType;
};

export const EventRecurrence = {
  Daily: "daily",
  DailyWeekdays: "daily-weekdays",
  Weekly: "weekly",
  Monthly: "monthly"
} as const;

export type EventRecurrenceType = ObjectValues<typeof EventRecurrence>;

export const EventCategory = {
  Live: "LIVE",
  Activity: "ACTIVITY",
  Deadline: "DEADLINE"
} as const;

export type EventCategoryType = ObjectValues<typeof EventCategory>;

export const EventStatus = {
  Default: "default",
  Ongoing: "ongoing",
  Finished: "finished"
} as const;

export type EventStatusType = ObjectValues<typeof EventStatus>;

export const CalendarViewSettings = {
  Week: "week",
  Month: "month"
} as const;

export type CalendarViewSettingsType = ObjectValues<
  typeof CalendarViewSettings
>;

export const CalendarMode = {
  Calendar: "calendar",
  Agenda: "agenda"
} as const;

export type CalendarModeType = ObjectValues<typeof CalendarMode>;

export type CalendarEventGroup = {
  id: number;
  name: string;
  cover: ImageModel;
};

export type CalendarEventAssignedUser = {
  id: number;
  name: string;
  surname: string;
  uuid: string;
};

export type CalendarEventType = {
  id: string;
  title: string;
  start: string;
  end: string;
  description: string;
  all_day: boolean;
  type: EventCategoryType;
  resource_attachments: CalendarEventResource[];
  board_attachments: CalendarEventResource[];
  exercise_attachments: CalendarEventResource[];
  group_recipients: CalendarEventGroup[];
  user_recipients: CalendarEventAssignedUser[];
};

export const EventAttachmentMode = {
  View: "view",
  Edit: "edit"
} as const;

export type EventAttachmentModeType = ObjectValues<typeof EventAttachmentMode>;
