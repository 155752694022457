const colors = {
  violet: {
    50: "#07001A",
    100: "#16004D", // decreaseBrightness(theme.primaryColor, 78) not precise, can't find exact nuance with this function
    200: "#321F61", // decreaseBrightness(theme.primaryColor, 62)
    300: "#5333A1", // decreaseBrightness(theme.primaryColor, 37)
    400: "#7351C8",
    500: "#8B5CFF", // theme.primaryColor
    501: "#8452FF", // theme.primaryColor
    600: "#9C80FF",
    700: "#C7B7FF", // increaseBrightness(theme.primaryColor, 55)
    800: "#EDE5FF", // increaseBrightness(theme.primaryColor, 85)
    900: "#F4F0FF" // increaseBrightness(theme.primaryColor, 90)
  },
  coldGrey: {
    300: "#2d2d86",
    500: "#5353C6",
    600: "#7979D2",
    700: "#9F9FDF",
    800: "#C6C6EC",
    900: "#F3F3FB"
  },
  blueGrey: {
    200: "#303A4F",
    300: "#43526F",
    400: "#57698F",
    500: "#8A99B8",
    600: "#909EBC",
    700: "#B0BACF",
    800: "#CFD5E2",
    900: "#EFF1F5"
  },
  grey: {
    50: "#000000",
    100: "#262626",
    200: "#404040",
    300: "#626262",
    400: "#737373",
    500: "#8C8C8C",
    600: "#A6A6A6",
    700: "#BFBFBF",
    800: "#D6D6D6",
    900: "#F2F2F2",
    1000: "#FFFFFF"
  },
  success: {
    100: "#204408",
    300: "#4BA013",
    400: "#60CD18",
    500: "#80E83C",
    600: "#97EC5F",
    700: "#B5F28D",
    900: "#F0FCE8"
  },
  error: {
    100: "#4D0400",
    300: "#B30900",
    400: "#E60B00",
    500: "#FF251A",
    600: "#FF5047",
    700: "#FF8680",
    800: "#FFB6B3",
    900: "#FFE7E5"
  },
  warning: {
    400: "#E66500",
    500: "#FF7F1B",
    600: "#FF9B4D",
    700: "#FFB780",
    900: "#FFF1E5"
  },
  yellow: {
    50: "#181A00",
    100: "#474D00",
    300: "#A6B300",
    400: "#D5E600",
    450: "#E0CA03",
    500: "#EDFF00",
    600: "#F2FF4D",
    800: "#FEFCBC",
    900: "#FDFFE5"
  },
  cherry: {
    300: "#8A2934",
    400: "#C43A4A",
    500: "#CB4E5C"
  },
  carnation: {
    600: "#F15B7D",
    700: "#F58AA2",
    800: "#F7A3B6"
  },
  royalPurple: {
    300: "#583E74",
    400: "#694A8B",
    500: "#8B69AF",
    700: "#F58AA2"
  },
  summerGreen: {
    300: "#46743E",
    600: "#85B97D",
    700: "#B1D2AC"
  },
  lagoon: {
    300: "#1D6A95",
    400: "#2589C0",
    500: "#278FC9",
    600: "#6AB7E2",
    700: "#94CBEA",
    800: "#BFE0F3",
    900: "#EAF5FB",
    1000: "#F6F9FF"
  },
  goldenFizz: {
    500: "#F8EE33"
  },
  orange: {
    500: "#FF7F1B",
    900: "#FFF1E5"
  },
  gradients: {
    grey: {
      100: "linear-gradient(270deg, #DEE0E6 0%, #EFF1F5 33.04%)"
    }
  }
};
export default colors;
