import styled from "styled-components";
import { Icon } from "@ds_universal/Icon";

import { SStyledIcon } from "./BoxWithIcons.types";

export const StyledIcon = styled(Icon)<SStyledIcon>`
  position: absolute;
  top: ${({ top = "" }) => `${top}`};
  bottom: ${({ bottom = "" }) => `${bottom}`};
  right: ${({ right = "" }) => `${right}`};
  left: ${({ left = "" }) => `${left}`};
`;

export const ContentWrapper = styled.div`
  position: relative;
`;
