import { Box, Heading, Icon, IconName, Text } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import * as S from "./SelectableIconCardList.styles";

export type SelectableIconCardItem = {
  id: number;
  title: string;
  description: string;
  icon: IconName;
};

type SelectableIconCardListProps = {
  list: SelectableIconCardItem[];
  selected: number;
  onChange: (el: SelectableIconCardItem) => void;
  handleFooterCta?: (el: SelectableIconCardItem) => void;
  labels?: {
    footerCta?: string;
  };
};

const SelectableIconCardList = ({
  selected,
  list = [],
  onChange,
  handleFooterCta,
  labels
}: SelectableIconCardListProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  if (!list.length) return null;

  return (
    <S.StyledUl>
      {list.map(el => {
        const isSelected = selected === el?.id;
        return (
          <S.StyledLi
            aria-label={el?.title}
            key={el?.id}
            onClick={() => {
              onChange(el);
            }}
            whileTap={{ scale: 0.98 }}
            isSelected={isSelected}
          >
            <S.Card isSelected={isSelected}>
              <S.RadioDot isSelected={isSelected} />
              <S.IconWrapper>
                <Icon initialViewbox icon={el?.icon} height={24} width={24} />
              </S.IconWrapper>
              <Box display="flex" flexDirection="column" gap="4px">
                <Heading level="6">{el?.title}</Heading>
                <Text type="body">{el?.description}</Text>
              </Box>
              {labels?.footerCta && isLayoutModeMobile ? (
                <S.FooterBtn
                  isSelected={isSelected}
                  onClick={() => handleFooterCta(el)}
                  type="button"
                >
                  <Text type="ctaTertiary">
                    {labels?.footerCta}
                    <Icon icon="arrow" height="16px" width="16px" />
                  </Text>
                </S.FooterBtn>
              ) : null}
            </S.Card>
          </S.StyledLi>
        );
      })}
    </S.StyledUl>
  );
};

export default SelectableIconCardList;
