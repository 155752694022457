export const __LOCAL_SEARCH_SUGGESTION__ = "weschool-search-suggestion";

export const __LOCAL_FEATURE_FLAG__ = "weschool-feature-flag";

export const __LOCAL_GROUP_COLLAPSED_DESCRIPTION__ =
  "weschool-group-collapsed-description";

export const __LOCAL_UNIVERSITY_SAVED__ = "weschool-university-logged";

export const __LOCAL_UNLOGGED_INVITE_INFORMATION__ = "weschool-unlogged-invite";

export const __OAUTH_COSTUMER__ = "weschool-oauth-costumer";
export const __OAUTH_ORIGIN_CLIENT__ = "weschool-oauth-origin-client";
export const __OAUTH_ORIGIN_URL__ = "weschool-oauth-origin-url";
