import { init as initEmojiMart } from "emoji-mart";
import emojiData from "@emoji-mart/data";

export const initEmoji = () => {
  try {
    initEmojiMart({ data: emojiData });
  } catch (e) {
    console.error(e);
  }
};
