import {
  Button,
  DoodleLoader,
  Modal,
  SearchBar,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListSelector } from "src/js/components/global/ListSelector";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { ModalFooter } from "src/js/components/modal/ModalFooter";
import { ModalScrollableBody } from "src/js/components/modal/ModalScrollableBody";
import { useStores } from "src/js/hooks";
import { debounce } from "src/js/modules/commonFunction";
import { GroupInviteMemberModal } from "src/js/pages/group/settings/GroupInviteMemberModal";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { GroupUserRoleEnum } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./GroupInviteImportModal.styles";

const GroupInviteImportModal = ({
  importGroup
}: {
  importGroup: (groupId: number) => void;
}) => {
  const {
    UserInviteStore: {
      groupImportList,
      hasMoreGroup,
      usersToInviteRole,
      fetchGroupList,
      fetchFilteredGroup,
      fetchNextPage
    },
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const [groupSelected, setGroupSelected] = useState();
  const [queryString, setQueryString] = useState<string>();
  const { whiteTheme, greyTheme } = useTheme();

  const isTeacher = usersToInviteRole === GroupUserRoleEnum.Teacher;

  useEffect(() => {
    fetchGroupList();
  }, []);

  const stepBack = () => {
    openModal(() => <GroupInviteMemberModal shouldScrollToEmailList />);
  };

  const submitGroup = () => {
    importGroup(groupSelected);
    stepBack();
  };

  const selectGroup = item => {
    setGroupSelected(item.id);
  };

  let debounceFunc = null;

  const modalTitle = isTeacher
    ? "group_invite_import_modal_teacher_title"
    : "group_invite_import_modal_student_title";

  const listTitle = isTeacher
    ? "group_import_user_teacher_list_title"
    : "group_import_user_student_list_title";

  let maxBodyHeight = 1000;
  let maxHeightOffset = 100;
  if (isLayoutModeMobile) {
    maxBodyHeight = 500;
    maxHeightOffset = 80;
  }

  return (
    <Translator>
      {({ translate }) => (
        <Modal title={translate(modalTitle)} onBack={stepBack}>
          <ModalScrollableBody
            maxHeight={maxBodyHeight}
            maxHeightOffset={maxHeightOffset}
            flexHeight={null}
            flexHeightOffset={0}
          >
            <S.ModalWrapper>
              <S.GroupInviteImportSearch>
                <SearchBar
                  theme={whiteTheme}
                  onChange={event => {
                    event.persist();
                    if (!debounceFunc) {
                      debounceFunc = debounce(() => {
                        fetchFilteredGroup({
                          query: event.target.value
                        });
                      }, 500);
                    }
                    debounceFunc();
                    setQueryString(event.target.value);
                  }}
                  value={queryString}
                  placeholder={translate("group_import_user_search_label")}
                />
              </S.GroupInviteImportSearch>
              <S.GroupInviteImportListTitle>
                <Text type="formSubtitle">
                  <Translate text={listTitle} />
                </Text>
              </S.GroupInviteImportListTitle>
              <ScrollableDiv maxHeight="300px" id="import-user-group-list">
                <InfiniteScroll
                  scrollableTarget="import-user-group-list"
                  dataLength={groupImportList.length}
                  next={() => {
                    fetchNextPage();
                  }}
                  hasMore={hasMoreGroup}
                  loader={<DoodleLoader theme={greyTheme} isMini />}
                >
                  <ListSelector
                    itemList={groupImportList}
                    toggleItem={selectGroup}
                    selectedItem={groupSelected}
                  />
                </InfiniteScroll>
              </ScrollableDiv>

              <ModalFooter flex={false}>
                <S.GroupInviteFooterButton centered>
                  <Button
                    theme={whiteTheme}
                    variant="secondary"
                    onClick={stepBack}
                    fullWidth={isLayoutModeMobile}
                  >
                    <Translate text="group_invite_import_users_cancel" />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={submitGroup}
                    theme={whiteTheme}
                    fullWidth={isLayoutModeMobile}
                  >
                    <Translate text="group_invite_import_users_confirm" />
                  </Button>
                </S.GroupInviteFooterButton>
              </ModalFooter>
            </S.ModalWrapper>
          </ModalScrollableBody>
        </Modal>
      )}
    </Translator>
  );
};

export default observer(GroupInviteImportModal);
