import { Button, Input, Modal, ModalSizeType } from "@arcadia/design-system";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { __FORM_GROUP_RESEND_MAIL__ } from "src/js/settings/settingsFormValidation";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./GroupResendInviteModal.styles";

type Props = {
  defaultMail: string;
  reSendFunction: (mail: string) => void;
  closeModal: () => void;
};

const GroupResendInviteModal: FC<Props> = ({
  defaultMail,
  reSendFunction,
  closeModal
}) => {
  const defaultValues = {
    email: defaultMail
  };
  const { register, handleSubmit, errors } = useForm<{ email: string }>({
    defaultValues
  });
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <Modal
      width={ModalSizeType.Big}
      title={translate("resend_invite")}
      onClose={closeModal}
    >
      <S.StyledContentWrapper>
        <form
          onSubmit={handleSubmit(formData => reSendFunction(formData.email))}
        >
          <Input
            theme={whiteTheme}
            name="email"
            label={translate("email")}
            ref={register(__FORM_GROUP_RESEND_MAIL__.email)}
            feedback={
              errors.email
                ? {
                    type: "error",
                    message: translate(errors.email.message as TranslationKeys)
                  }
                : null
            }
            rounded
          />
          <S.ButtonWrapper>
            <Button type="submit" theme={whiteTheme} variant="primary">
              {translate("invite")}
            </Button>
          </S.ButtonWrapper>
        </form>
      </S.StyledContentWrapper>
    </Modal>
  );
};

export default GroupResendInviteModal;
