import React, { useCallback, useMemo, useState } from "react";
import { CustomListElementProps } from "src/js/components/global/ListSelector";
import {
  SelectableList,
  SelectableListEmptyState,
  SelectableListEmptyStatusType
} from "src/js/components/layout/SelectableList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { createStaticUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import {
  BasePaginationParams,
  BasePaginationResponse,
  DashboardEvent,
  EventDomain,
  SpacesGroupType
} from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AssessmentGroupItemCard } from "./AssessmentGroupItemCard";

const AssessmentDetailModal = ({
  spaceGroups
}: {
  spaceGroups: SpacesGroupType[];
}) => {
  const { translate } = useTranslation();
  const {
    ModalStore: { closeModal },
    SpaceStore: { activeSpaceId }
  } = useStores();

  const groups = useMemo(
    () =>
      spaceGroups
        .filter(spaceGroup => spaceGroup?.isActive)
        .map(spaceGroup => ({
          ...spaceGroup,
          id: spaceGroup?.group?.id
        })),
    [spaceGroups]
  );

  const [selectedGroups, setSelectedGroups] = useState<string[] | number[]>([]);

  const fetchItems = useCallback(
    (
      params: BasePaginationParams & {
        name?: string;
        [key: string]: any;
      }
    ): Promise<BasePaginationResponse<SpacesGroupType>> => {
      // hasNext workaround
      if (params?.offset > 0) {
        return new Promise(resolve => {
          resolve({
            hasNext: false,
            results: []
          });
        });
      }

      const { name } = params;
      const data: BasePaginationResponse<SpacesGroupType> = {
        hasNext: false, // TODO: hasNext not working
        results:
          name && name !== ""
            ? groups.filter(group =>
                group?.group?.name
                  ?.toLocaleLowerCase()
                  ?.replace(/ /g, "")
                  .includes(name.toLocaleLowerCase().replace(/ /g, ""))
              )
            : groups
      };
      return new Promise(resolve => {
        resolve(data);
      });
    },
    [activeSpaceId]
  );

  const getCustomItemCard = (
    props: CustomListElementProps<SpacesGroupType>
  ) => <AssessmentGroupItemCard {...props} />;

  const getEmptyStateFromStatus = (status: SelectableListEmptyStatusType) => (
    <SelectableListEmptyState
      status={status}
      emptySearchLabel="assessment_detail_modal_empty_search"
      emptyListLabel="assessment_detail_modal_empty_list"
    />
  );

  const handleOnConfirm = () => {
    const selectedGroup = selectedGroups[0];
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.AssessmentsCTAScores,
      selectedGroup
    );
    const url = `#${createStaticUrl("activity_examination", {
      group_id: Number(selectedGroup)
    })}`;
    window.open(url, "_blank");
    closeModal();
  };

  return (
    <ModalLayout
      labels={{
        title: translate("assessment_detail_modal_title")
      }}
      mobileProps={{
        withDrag: false
      }}
      closeModal={closeModal}
      desktopProps={{ hasAdaptiveHeight: false }}
    >
      <SelectableList
        withCancelButton
        onCancel={closeModal}
        onConfirm={handleOnConfirm}
        fetchItems={fetchItems}
        selectedItemsIds={selectedGroups}
        onChangeSelectedItemsIds={setSelectedGroups}
        customComponents={{
          getCustomItemCard,
          getEmptyStateFromStatus
        }}
        labels={{
          translatedConfirm: translate("assessment_detail_modal_cta_confirm"),
          translatedCancel: translate("assessment_detail_modal_cta_cancel"),
          translatedTitle: translate("assessment_detail_modal_content_title"),
          searchPlaceholder: translate(
            "assessment_detail_modal_search_placeholder"
          )
        }}
      />
    </ModalLayout>
  );
};

export default AssessmentDetailModal;
