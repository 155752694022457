import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import clone from "lodash/clone";
import find from "lodash/find";
import maxBy from "lodash/maxBy";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import reject from "lodash/reject";
import map from "lodash/map";
import { modalPage } from "Legacy/models/modalPageModel";
import { __QUIZ_MAX_POINTS__ } from "src/js/settings/settingsExercise";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  Button,
  Icon,
  ConfirmationAlert,
  ContextualAlert,
  ContextualError,
  Heading,
  Box
} from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import { Crossword } from "../../../models/quiz/quizModel_crosswords";
import Dropdown from "../../../components/dropdown";
import CrosswordsViewer from "./quizCrosswordViewer";
import { __ } from "../../../modules/localization";
import QuizLabel from "./QuizLabel";

const QuizBuilderCrosswords = withBackbone(
  createReactClass({
    form: "#form-quiz-builder-crosswords",
    subform: "#form-quiz-builder-crosswords-couples",
    getInitialState() {
      const { quiz } = this.props;
      return {
        status: "",
        title: quiz.get("title"),
        available_points: quiz.getAvailablePoints(),
        questions: quiz.getQuestions(),
        solutions: quiz.getSolutions(),
        crosswordsPreview: "",
        listEditing: false,
        mode: "insert",
        editQuestion: {},
        editSolution: {},
        cw_words: [],
        errorMessage: null,
        errorMessageSub: null
      };
    },
    componentDidMount() {
      const { quiz } = this.props;
      const questions = quiz.getQuestions();
      const solutions = quiz.getSolutions();
      const defObject = map(questions, q => {
        const obj = clone(q);
        const s = find(solutions, { index: obj.index });
        obj.question = obj.value;
        obj.value = s.value;
        return obj;
      });
      // formatto questions e solutions e genero oggetti da passare a createpreview
      this.createPreview(defObject);
    },
    componentDidUpdate() {
      if ($(this.subform).length > 0) {
        this.setupSubform();
      }
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(true);
    },
    setupSubform() {
      $(this.subform).on("submit", e => {
        e.preventDefault();
      });

      this.initValidation();
    },
    submitSubform() {
      $(this.subform).trigger("submit");
    },
    initValidation() {
      const self = this;

      $.validate({
        validateOnBlur: false,
        form: `${self.subform}`,
        borderColorOnError: "",
        onError() {
          self.setState({
            errorMessageSub: "Attenzione, verificare i dati inseriti"
          });
        },
        onSuccess(form) {
          if (`#${form[0].id}` == self.subform) {
            self.saveWord();
          }
          return false;
        }
      });
    },
    checkSubmit(e) {
      if (e.which === 13) {
        this.submitSubform();
      }
    },
    saveWord() {
      const self = this;
      const arrQuestions = JSON.parse(JSON.stringify(this.state.questions));
      const arrSolutions = JSON.parse(JSON.stringify(this.state.solutions));
      let question = {};
      let solution = {};

      let newMaxIndex = 0;
      if (arrQuestions.length > 0) {
        const maxItem = maxBy(arrQuestions, q => q.index);
        newMaxIndex = maxItem.index + 1;
      }

      if (isEmpty(this.state.editQuestion)) {
        question = {
          index: newMaxIndex,
          value: $("#crosswords-couples-question").val()
        };
        arrQuestions.push(question);
      } else {
        question = JSON.parse(JSON.stringify(this.state.editQuestion));
        question.value = $("#crosswords-couples-question").val();
      }

      if (isEmpty(this.state.editSolution)) {
        solution = {
          index: newMaxIndex,
          value: $("#crosswords-couples-solution").val().toLowerCase()
        };
        arrSolutions.push(solution);
      } else {
        solution = JSON.parse(JSON.stringify(this.state.editSolution));
        solution.value = $("#crosswords-couples-solution").val().toLowerCase();
      }

      if (solution.value.length > 1) {
        // in crossword non posso accettare parole di una lettera sola
        // controllo che parola non sia già inserita
        let alreadyUsed = false;
        each(arrSolutions, s => {
          if (s.value === solution.value && s.index != solution.index) {
            alreadyUsed = true;
          }
        });

        // controllo che la parola sia solo lettere
        let notOnlyLetters = false;
        const regex =
          /^[ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]+$/;
        if (!regex.test(solution.value)) {
          notOnlyLetters = true;
        }

        if (alreadyUsed) {
          self.setState({ errorMessageSub: "Attenzione, parola già inserita" });
        } else if (notOnlyLetters) {
          self.setState({
            errorMessageSub:
              "Attenzione, hai inserito un carattere non valido. Non puoi utilizzare spazi, lettere accentate o caratteri speciali."
          });
        } else {
          // aggiorno array tenuti in sessione, altrimenti non aggiorna view
          // in teoria potrei non aggiornare in fase di creazione, ma vabbè
          each(arrQuestions, (q, index) => {
            if (q.index === question.index) {
              arrQuestions[index] = question;
            }
          });

          each(arrSolutions, (s, index) => {
            if (s.index === solution.index) {
              arrSolutions[index] = solution;
            }
          });

          this.setState(
            {
              questions: arrQuestions,
              solutions: arrSolutions
            },
            function () {
              this.createPreview();
            }
          );
          // N.B. obbligatorio creare la preview per il funzionamento del salvataggio
          this.toggleCreate("insert");
        }
      } else {
        self.setState({
          errorMessage: "Inserire una parola di almeno due lettere"
        });
      }
    },
    removeWord(index) {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={<Translate text="La definizione sarà eliminata" />}
          declineText={<Translate text="Annulla" />}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={<Translate text="Conferma" />}
          onAcceptFunction={() => {
            const arrQuestions = reject(
              self.state.questions,
              item => item.index === index
            );
            const arrSolutions = reject(
              self.state.solutions,
              item => item.index === index
            );
            self.setState({
              questions: arrQuestions,
              solutions: arrSolutions
            });
            self.createPreview();
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    addQuiz() {
      const self = this;
      this.setState({ status: "loading" });

      if (this.state.cw_words.length === 0) {
        this.setState({
          status: "",
          errorMessage: "Inserire almeno due parole che si intersecano"
        });
      } else if (
        self.state.available_points &&
        (self.state.available_points < 1 ||
          self.state.available_points > __QUIZ_MAX_POINTS__)
      ) {
        this.setState({
          status: "",
          errorMessage: "quiz_vote_validation_error"
        });
      } else {
        const { quiz } = this.props;

        quiz.set("type", "crosswords");
        quiz.set("title", __("quiz_crosswords_execution_label"));
        if (!self.state.available_points) {
          quiz.set("available_points", 1);
        } else {
          quiz.set(
            "available_points",
            parseInt(self.state.available_points, 10)
          );
        }
        if (!quiz.isCreated()) {
          quiz.set("questions", []);
        }

        const formattedQuestions = map(this.state.cw_words, obj => ({
          index: obj.index,
          value: obj.question,
          x: obj.x,
          y: obj.y,
          orientation: obj.orientation
        }));

        quiz.set("solutions", this.state.cw_words);
        quiz.set("questions", formattedQuestions);

        const optionalCallbackSuccess = function () {
          this.setState({ status: "" });
          this.props.backFunc();
        };

        const optionalCallbackError = function () {
          this.setState({ status: "" });
        };
        if (typeof this.props.setChanged === "function")
          this.props.setChanged(false);
        this.props.addFunc(
          quiz,
          optionalCallbackSuccess.bind(this),
          optionalCallbackError.bind(this)
        );
      }
    },
    createPreview(defaultData) {
      const self = this;
      const { theme } = self.props;
      const { whiteTheme } = theme;

      if (this.state.questions.length > 1) {
        if (defaultData !== undefined) {
          var words = defaultData;
        } else {
          // estraggo definizioni
          const new_words = map(this.state.solutions, "value");
          const new_clues = map(this.state.questions, "value");

          // Create crossword object with the words and clues
          const cw = new Crossword(new_words, new_clues);

          // create the crossword grid (try to make it have a 1:1 width to height ratio in 10 tries)
          const tries = 10;
          const grid = cw.getSquareGrid(tries);
          var words = cw.getFormattedObj(grid);
        }

        this.setState({
          cw_words: words,
          crosswordsPreview: <CrosswordsViewer words={words} />
        });
      } else {
        this.setState({
          crosswordsPreview: (
            <ContextualAlert
              theme={whiteTheme}
              text={__(
                "Inserire almeno due parole per visualizzare l'anteprima"
              )}
            />
          )
        });
      }
    },
    focusInput() {
      setTimeout(() => {
        $("#crosswords-couples-question").trigger("focus");
      }, 1);
    },
    edit(question, solution) {
      this.setState({
        editQuestion: question,
        editSolution: solution
      });
      this.toggleCreate("edit");
    },
    toggleCreate(mode) {
      const { listEditing } = this.state;

      this.setState({
        listEditing: !listEditing,
        errorMessage: null,
        mode
      });

      if (mode == "insert") {
        this.setState({
          editQuestion: {},
          editSolution: {}
        });
      }

      if (!listEditing) {
        this.focusInput();
        if ($(".js-scroll-here").length > 0) {
          $("html, body").animate({
            scrollTop: $(".js-scroll-here").offset().top
          });
        }
      }
    },
    showPreview() {
      const previewBox = (
        <div className="quiz-builder-preview-box">
          {this.state.crosswordsPreview}
        </div>
      );

      modalPage.set({
        header: {
          content: `<h3 class="app__title">${__("ANTEPRIMA")}</h3>`,
          type: "HTML"
        },
        body: { content: previewBox, type: "REACT" },
        footer: { content: "", type: "HTML" },
        addCloseButton: true
      });
      modalPage.show();
    },
    render() {
      const self = this;
      const { errorMessage, errorMessageSub } = self.state;
      let couplesContent = "";
      let couplesButtons = "";
      let footerSection = "";
      let listTitle = "";
      const { whiteTheme } = this.props.theme;

      const definitions = this.state.questions.map((q, key) => {
        const solution = find(self.state.solutions, { index: q.index });

        const dropdownOptions = [];

        dropdownOptions.push(
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              onClick={self.edit.bind(null, q, solution)}
            >
              {__("modifica")}
            </a>
          </li>
        );
        dropdownOptions.push(
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              onClick={self.removeWord.bind(null, q.index)}
            >
              {__("elimina")}
            </a>
          </li>
        );

        const optionsBox = (
          <div className="quiz-builder-couples-element-options dropdown col-xs-2 col-sm-1 col-lg-1 text-center">
            <a
              className="quiz-builder-couples-element-options-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className=" quiz-builder-couples-element-options-icon">
                <Icon icon="kebabVertical" width={20} height={20} />
              </span>
            </a>
            <Dropdown items={dropdownOptions} />
          </div>
        );

        return (
          <Box display="flex" alignItems="center" key={key} marginTop={16}>
            <Box width="100%" display="flex" gap="16px" flexWrap="wrap">
              <div className="quiz-builder-couples-col1 pull-left-not-xs">
                <div className="quiz-builder-couples-text">{q.value}</div>
              </div>
              <div className="quiz-builder-couples-col2 pull-left-not-xs">
                <div className="quiz-builder-couples-text">
                  {solution.value}
                </div>
              </div>
            </Box>
            {optionsBox}
          </Box>
        );
      });

      if (this.state.listEditing) {
        let textQuestion = "";
        let textSolution = "";

        if (this.state.mode == "edit") {
          listTitle = __("modifica definizione");
          textQuestion = this.state.editQuestion.value;
          textSolution = this.state.editSolution.value;
        } else {
          listTitle = __("INSERISCI DEFINIZIONE");
        }

        couplesContent = (
          <div className="form-quiz-builder-crosswords-couples-wrapper">
            <form
              id="form-quiz-builder-crosswords-couples"
              role="form"
              method="post"
            >
              <div>
                <QuizLabel text="DEFINIZIONE" />
                <div>
                  <textarea
                    className="form-control"
                    id="crosswords-couples-question"
                    name="question"
                    placeholder={__("DEFINIZIONE")}
                    rows="4"
                    defaultValue={textQuestion}
                    onChange={() => {
                      self.setState({ errorMessageSub: null });
                    }}
                    data-validation="length"
                    data-validation-length="1-200"
                    data-validation-error-msg={" "}
                  />
                </div>
              </div>
              <div>
                <QuizLabel text="risposta" />
                <div>
                  <input
                    type="text"
                    className="form-control full-width"
                    id="crosswords-couples-solution"
                    name="solution"
                    placeholder={__("crossword_answer_placeholder")}
                    defaultValue={textSolution}
                    onChange={() => {
                      self.setState({ errorMessageSub: null });
                    }}
                    data-validation="length"
                    data-validation-length="2-20"
                    data-validation-error-msg={" "}
                  />
                </div>
              </div>
            </form>
          </div>
        );

        couplesButtons = (
          <>
            <Box marginTop={16}>
              <div className="flex flex-right-column spaced-button-left">
                <Button
                  onClick={self.toggleCreate.bind(null, "insert")}
                  theme={whiteTheme}
                  variant="secondary"
                >
                  {__("Annulla")}
                </Button>
                <Button
                  onClick={self.submitSubform}
                  theme={whiteTheme}
                  variant="primary"
                >
                  {__("Conferma")}
                </Button>
              </div>
            </Box>
            <Box marginTop={16} display="flex" justifyContent="flex-end">
              {errorMessageSub && (
                <ContextualError
                  theme={whiteTheme}
                  text={__(errorMessageSub)}
                />
              )}
            </Box>
          </>
        );
      } else {
        listTitle = __("ELENCO DEFINIZIONI");

        couplesContent = <Box marginTop={16}>{definitions}</Box>;

        couplesButtons = (
          <Box marginTop={16}>
            <Button
              onClick={self.toggleCreate.bind(null, "insert")}
              theme={whiteTheme}
              variant="secondary"
            >
              {__("aggiungi definizione")}
            </Button>
          </Box>
        );

        footerSection = (
          <>
            <QuizLabel text="quiz_creation_vote_label" />
            <div className="point-section">
              <div className="point-input">
                <input
                  type="number"
                  id="quiz_available_points"
                  className="form-control primary"
                  defaultValue={self.state.available_points}
                  placeholder={1}
                  ref="quiz_available_points"
                  onChange={e =>
                    this.setState({
                      available_points: e.target.value,
                      errorMessage: null
                    })
                  }
                />
                <div className="point-label">
                  {__("quiz_creation_point_label")}
                </div>
              </div>

              <Box display="flex" justifyContent="flex-end" gap="16px">
                <Button
                  onClick={this.showPreview}
                  theme={whiteTheme}
                  variant="secondary"
                >
                  {__("quiz_crossword_preview_button_label")}
                </Button>
                <Button
                  onClick={this.addQuiz}
                  theme={whiteTheme}
                  variant="primary"
                >
                  {__("save")}
                </Button>
              </Box>
            </div>
            <Box display="flex" justifyContent="flex-end" marginTop={16}>
              {errorMessage && (
                <ContextualError theme={whiteTheme} text={__(errorMessage)} />
              )}
            </Box>
          </>
        );
      }

      return (
        <div>
          <div className="quiz-builder-crosswords-wrapper">
            <div>
              <Box>
                <Heading level="5">{listTitle}</Heading>
              </Box>
              {couplesContent}
              {couplesButtons}
              <div className="clearfix" />
            </div>

            {footerSection}
          </div>
        </div>
      );
    }
  })
);

export default withTheme(withConfirmDialogStore(QuizBuilderCrosswords));
