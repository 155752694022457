import styled from "styled-components";
import { SDesktopHero, SMobileHeroLayout } from "./HeroLayout.types";

export const Header = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
`;

/**
 * Desktop
 */

export const HeroTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 4px;
  margin: 0;
`;

export const HeroDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

export const HeroWrapText = styled.span`
  max-width: 560px;
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const MonogramContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  top: 32px;
  left: 32px;
`;

export const DesktopHero = styled.div<SDesktopHero>`
  display: flex;
  align-items: end;
  height: 100%;
  flex: 50%;
  background: ${({ background }) =>
    background ? `url(${background}) center no-repeat` : ""};
  background-color: ${({ backgroundColor = "" }) => `${backgroundColor}`};
  background-size: cover;
  padding: 40px;
  span {
    display: block;
  }
`;

export const DesktopContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  height: 100%;
  flex: 50%;
  overflow-y: auto;
`;

export const DesktopHeroLayout = styled.div`
  display: flex;
  height: 100vh;
`;

export const DesktopAvatarWrapper = styled.div`
  margin: auto;
  width: 186px;
  height: 186px;
`;

/**
 * Mobile
 */

export const MobileHeroLayout = styled.div<SMobileHeroLayout>`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  background: ${({ background }) =>
    background ? `url(${background}) center no-repeat` : ""};
  background-color: ${({ backgroundColor = "" }) => `${backgroundColor}`};
  background-size: cover;
`;
