import styled from "styled-components";

export const TrendContainer = styled.div`
  width: 100%;
  padding: 30px 40px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const GraphContainer = styled.div`
  width: calc(100% + 30px);
  height: 450px;
  margin-left: -30px;
  position: relative;
  padding: 20px 0px 20px 0px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const TimeFilterContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    margin-left: 0;
    max-width: 349px;
    flex-direction: column;
  }
`;

export const Spacer = styled.div`
  margin-left: 15px;
`;

export const CalendarDropdownContainer = styled.div`
  margin-right: 15px;
  position: relative;
  display: flex;
  width: 220px;

  button:first-of-type {
    z-index: 2;
  }

  @media (max-width: 1024px) {
    margin-right: 0px;
  }
`;

export const AnalyticsIconAndCalendarContainer = styled.div`
  position: relative;
  display: contents;
`;

export const AnalyticsCalendarContainer = styled.div`
  position: absolute;
  margin-top: 45px;
  right: 0px;
  margin-left: 80px;
  z-index: ${({ theme }) => theme.zIndex.forModal};
`;

export const CalendarIconContainer = styled.button`
  height: 36px;
  margin-top: 0px;
  border: none;
  position: absolute;
  right: 0px;
  height: 39px;
  border-radius: 36px;
  cursor: pointer;
  width: 39px;
  z-index: 3 !important;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3fb;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor[800]};
  }
`;

export const SpecificityFilterContainer = styled.div`
  width: 190px;

  @media (max-width: 1024px) {
    margin-top: 8px;
    max-width: 100%;
  }
`;
