import { Box } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import {
  convertPriceInteger,
  getCurrencySymbol
} from "src/js/modules/priceFunction";
import { useTranslation } from "src/js/translation";
import { PlanType } from "src/js/types";
import { ChargebeeWidgetType } from "../../ChargebeeUtils";
import { ChargebeeWidgetCard } from "../ChargebeeWidgetCard";
import { PlanCard } from "../PlanCard";
import { PlanCardDetailBody } from "../PlanCard/PlanCardDetailBody";

const ManagePlan: FC = () => {
  const { translate } = useTranslation();
  const { SpacesSettingsStore } = useStores();

  const { planType, planPriceAmount, planPriceCurrency, planBilling } =
    SpacesSettingsStore?.generalSettings?.planSettings || {};

  return (
    <Box>
      <PlanCard
        titleLabel={
          planType
            ? translate({
                text: "space_settings_plan_info_name",
                stringVariables: { planType }
              })
            : null
        }
        priceLabel={
          planBilling
            ? translate({
                text: "space_settings_plan_price",
                stringVariables: {
                  amount: convertPriceInteger(planPriceAmount),
                  currency: getCurrencySymbol(planPriceCurrency),
                  planBilling
                }
              })
            : translate("space_settings_plan_free_label")
        }
      >
        <PlanCardDetailBody />
      </PlanCard>
      <ChargebeeWidgetCard
        title={translate("manage_plan_card_billing_title")}
        iconType="location"
        widgetType={ChargebeeWidgetType.EditBillingAddress}
        description={translate("manage_plan_card_billing_text")}
      />
      {planType !== PlanType.FREE ? (
        <ChargebeeWidgetCard
          title={translate("manage_plan_card_payment_title")}
          iconType="wallet"
          widgetType={ChargebeeWidgetType.EditPaymentSource}
          description={translate("manage_plan_card_payment_text")}
        />
      ) : null}
      <ChargebeeWidgetCard
        title={translate("manage_plan_card_cancel_title")}
        iconType="trash"
        widgetType={ChargebeeWidgetType.SubscriptionCancellation}
        description={translate("manage_plan_card_cancel_text")}
      />
    </Box>
  );
};

export default observer(ManagePlan);
