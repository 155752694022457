import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import styled from "styled-components";

export const ModalGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 10px;
  margin-bottom: 12px;
`;

export const ModalGroupImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  object-fit: cover;
`;

export const ModalGroupName = styled.div`
  margin-left: 16px;
  align-items: center;
  display: flex;
`;

export const SearchBarWrapper = styled.div`
  padding: 16px 24px;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const ListWrapper = styled.div`
  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const CustomModalLayout = styled(ModalLayout)`
  overflow-y: hidden;
`;

export const ScrollableArea = styled(ScrollableDiv)`
  padding: 0px 24px;
  margin-right: 0px;
`;
