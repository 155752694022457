import { __CALENDAR_ARROW_ICON__ } from "src/js/settings/settingsImage";
import styled from "styled-components";

export const AnalyticsCalendarContainer = styled.div`
  & .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.title};
    display: flex;
    flex-wrap: wrap;
    max-width: 670px;
    padding-bottom: 10px;
    border: 1px solid ${({ theme }) => theme.colors.grey[800]};

    & .react-datepicker__aria-live {
      position: absolute;
      clip-path: circle(0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
      white-space: nowrap;
    }

    & .react-datepicker__navigation--previous,
    & .react-datepicker__navigation--next {
      background: no-repeat center;
    }

    & .react-datepicker__navigation-icon--previous::before,
    & .react-datepicker__navigation-icon--next::before {
      display: none;
    }

    & .react-datepicker__navigation--previous {
      left: 15px;
      transform: scaleX(-1);
      background-image: url(${__CALENDAR_ARROW_ICON__});
    }

    & .react-datepicker__navigation--next {
      right: 15px;
      background-image: url(${__CALENDAR_ARROW_ICON__});
    }

    & .react-datepicker__navigation {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      border: none;
      top: 10px;

      &:hover {
        box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  & .react-datepicker__header {
    background-color: white;
    height: 70px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[800]};

    & .react-datepicker__day-name {
      color: ${({ theme }) => theme.colors.grey[50]} !important;
      line-height: 18px;
      font-size: 12px;
      text-transform: uppercase;
    }

    & .react-datepicker__current-month {
      font-weight: ${({ theme }) => theme.weights.semiBold};
      color: ${({ theme }) => theme.colors.grey[50]};
      padding: 5px 0px 10px 0px;
    }

    & .react-datepicker__day-name,
    & .react-datepicker__day,
    & .react-datepicker__time-name {
      max-width: 45px;
      width: 100%;
      text-align: center;
      margin: auto;
      @media (max-width: 380px) {
        max-width: 35px;
      }
    }
  }

  & .react-datepicker__month-container {
    flex: 1;
    width: 100%;
    max-width: 670px;

    & .react-datepicker__month {
      font-size: 14px;

      & .react-datepicker__week {
        height: 35px;

        & .react-datepicker__day--in-selecting-range,
        & .react-datepicker__day--in-range {
          background-color: ${({ theme }) =>
            theme.primaryColor[900]} !important;
          color: ${({ theme }) => theme.primaryColor[500]} !important;
        }

        & .react-datepicker__day--outside-month {
          padding: 0px !important;
          color: ${({ theme }) => theme.colors.grey[800]} !important;
          height: 0px !important;
        }

        // selected (last)
        & .react-datepicker__day--range-start,
        & .react-datepicker__day--selected,
        & .react-datepicker__day--range-end {
          background-color: ${({ theme }) =>
            theme.primaryColor[500]} !important;
          color: ${({ theme }) => theme.colors.grey[1000]} !important;
        }
        & .react-datepicker__day--keyboard-selected {
          background-color: transparent;
          color: ${({ theme }) => theme.colors.grey[50]};
        }
      }

      & .react-datepicker__day-name,
      & .react-datepicker__day,
      & .react-datepicker__time-name {
        color: ${({ theme }) => theme.colors.grey[50]};
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 5px 10px 10px 5px;

        @media (max-width: 380px) {
          margin: 2px 5px 5px 2px;
        }
      }
    }
  }
`;
