import { Transition } from "framer-motion";

export const verticalStepperVariants = {
  stepOpen: {
    height: "auto",
    opacity: 1,
    scale: 1,
    originX: 0,
    originY: 0,
    display: "flex",
    transition: {
      type: "spring",
      height: { duration: 0.25 },
      opacity: { duration: 0.15 },
      scale: { duration: 0.25 }
    }
  },
  stepClosed: {
    height: 0,
    opacity: 0,
    scale: 0.85,
    originX: 0,
    originY: "100%",
    transitionEnd: {
      display: "none"
    },
    transition: {
      type: "spring",
      height: { duration: 0.25 },
      opacity: { duration: 0.15 },
      scale: { duration: 0.25 }
    }
  }
};

export const dotContentVariants = {
  initial: {
    scale: 0.75,
    opacity: 0
  },
  animate: {
    scale: 1,
    opacity: 1
  },
  exit: {
    scale: 0.75,
    opacity: 0
  }
};

export const defaultContentWrapperProps = {
  variants: dotContentVariants,
  initial: "initial",
  animate: "animate",
  exit: "exit",
  transition: { duration: 0.15 }
};

export const activeItemIconProps = (hasLoading?: boolean) => {
  const transition: Transition = hasLoading
    ? {
        duration: 1,
        ease: "linear",
        loop: Infinity,
        repeatType: "loop",
        repeatDelay: 0
      }
    : { duration: 0.15 };
  return {
    variants: dotContentVariants,
    animate: hasLoading ? { rotate: [0, 360] } : "animate",
    transition
  };
};
