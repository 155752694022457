import styled from "styled-components";

export const IconContainer = styled.div`
  font-size: 24px;
  height: 40px;
  width: 40px;
  display: block;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.3);
  text-align: center;
  line-height: 40px;
  margin: auto 15px auto 0px;
  color: ${({ theme }) => theme.colors.headerTextColor};
  position: absolute;
  cursor: pointer;
  right: 0px;
`;
