/* eslint-disable no-restricted-imports */
import { modalPage } from "Legacy/models/modalPageModel";
import { useTheme } from "styled-components";
import ResourceDropbox from "Legacy/view/resource/resourceManager/resourceDropbox";
import ResourceExercise from "Legacy/view/resource/resourceManager/resourceExercise";
import ResourceLink from "Legacy/view/resource/resourceManager/resourceLink";
import ResourceMaps from "Legacy/view/resource/resourceManager/resourceMaps";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { BoardPicker } from "src/js/components/BoardPicker";
import { RESOURCE_ICON_TYPE } from "src/js/components/ResourceIcon/ResourceIcon.const";
import { useStores } from "src/js/hooks";
import { AddGDriveResource } from "src/js/components/ResourcePicker/components/AddGDriveResource";
import { showToastError } from "src/js/modules/messageManager";
import ResourceUpload from "src/js/pages/resources/ResourceUpload";
import ResourceViewer from "src/legacy/view/resource/ResourceViewer";
import { ActionButton } from "@arcadia/design-system";
import { fetchResource } from "src/js/repository/resourceRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import CalendarEventModalView from "./CalendarEventModalView";
import { normalizeAttachments } from "../CalendarUtils";

const CalendarEventModal = ({
  defaultStartDate,
  defaultEndDate,
  hasCollapsableDates = false,
  hasInstantCreationMode = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeModal = (_url, _start) => {},
  creationMode = true,
  defaultEventDetail = {},
  type = undefined,
  copyEvent = false // workaround to allow copy of an event without touch all the files, delete when refactored
}) => {
  const {
    VendorsStore: { vendors, accounts, lastAccountUsed },
    GroupStore: { activeGroup },
    CalendarStore: {
      groupList,
      hasMoreGroup,
      fetchGroupNextPage,
      fetchGroupList,
      resetGroupList,
      userList,
      hasMoreUser,
      fetchUserNextPage,
      fetchUserList,
      resetUserList,
      createCalendarEvent,
      resourceAttachments,
      setResourceAttachments,
      addResourceAttachment,
      removeResourceAttachment,
      resetResourceAttachments,
      editCalendarEvent
    }
  } = useStores();

  const { whiteTheme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [resourceView, setResourceView] = useState(null);

  const currentGroup = {
    key: activeGroup?.id,
    value: activeGroup?.name
  };

  const [showBoardPicker, setShowBoardPicker] = useState(false);

  useEffect(() => {
    fetchGroupList();
    fetchUserList();

    const { resource_attachments, board_attachments, exercise_attachments } =
      defaultEventDetail || {};

    const resources = normalizeAttachments({
      boardAttachments: board_attachments || [],
      exerciseAttachments: exercise_attachments || [],
      resourceAttachments: resource_attachments || []
    });

    setResourceAttachments(resources || []);
    return () => {
      resetGroupList();
      resetUserList();
      resetResourceAttachments();
    };
  }, []);

  const newEvent = value => {
    setIsLoading(true);
    const hasVendorAccount = value.live_vendor_account_id;
    createCalendarEvent({
      ...value,
      redirect_to:
        hasInstantCreationMode && hasVendorAccount ? null : window.location.href
    })
      .then(({ liveUrl, start }) => {
        setIsLoading(false);
        closeModal(liveUrl, start);
      })
      .catch(({ location }) => {
        setIsLoading(false);
        if (location) {
          window.open(
            location,
            hasInstantCreationMode && hasVendorAccount ? "_blank" : "_self"
          );
          closeModal();
        } else {
          showToastError({
            str: <Translate text="error_add_calendar_event" />
          });
        }
      });
  };

  const editEvent = value => {
    setIsLoading(true);
    const hasVendorAccount = value.live_vendor_account_id;
    editCalendarEvent({
      ...value,
      redirect_to: hasVendorAccount ? null : window.location.href
    })
      .then(() => {
        setIsLoading(false);
        closeModal();
      })
      .catch(({ location }) => {
        setIsLoading(false);
        if (location) {
          window.open(location, "_self");
        } else {
          showToastError({
            str: <Translate text="error_edit_calendar_event" />
          });
        }
      });
  };

  // eslint-disable-next-line no-shadow
  const openResource = useCallback(({ id, type }) => {
    if (id) {
      // No opening of internal resources on modal until we find a ceal ux solution
      if (
        type === RESOURCE_ICON_TYPE.BOARD ||
        type === RESOURCE_ICON_TYPE.EXERCISE
      ) {
        return;
      }
      fetchResource(id)
        .then(resourceFetched => {
          setResourceView(
            <ResourceViewer
              disableOption
              resources={[resourceFetched]}
              showComments={false}
              startingPosition={0}
              onClose={() => setResourceView(null)}
            />
          );
        })
        .catch(() => {
          showToastError({
            str: <Translate text="quiz_resource_fetch_error" />
          });
        });
    }
  }, []);

  // eslint-disable-next-line no-shadow
  const addResource = ({ id, name, type }) => {
    const newResource = { id, name, type };
    addResourceAttachment(newResource);
    modalPage.hide();
  };

  const selectResource = resourceType => {
    let resourceContent;
    switch (resourceType) {
      case "LINK":
        resourceContent = (
          <ResourceLink
            addFunc={addResource}
            setWorking={() => {}}
            type="LINK"
          />
        );
        break;
      case "FILE":
        resourceContent = <ResourceUpload addFunc={addResource} />;
        break;
      case "GOOGLE_DRIVE":
        resourceContent = (
          // UI wise could be better, but hopefully this will be a temporary file
          <AddGDriveResource
            onSuccess={addResource}
            onClose={() => {
              modalPage.hide();
            }}
            setUploadingProgress={() => {}}
            elementIndex={0}
          />
        );
        break;
      case "DROPBOX":
        resourceContent = (
          <ResourceDropbox addFunc={addResource} setWorking={() => {}} />
        );
        break;
      case "GOOGLE_MAPS":
        resourceContent = (
          <ResourceMaps addFunc={addResource} setWorking={() => {}} />
        );
        break;
      case "EXERCISE":
        resourceContent = (
          <ResourceExercise addFunc={addResource} setWorking={() => {}} />
        );
        break;
      case "BOARD":
        setShowBoardPicker(true);
        return;
      default:
        resourceContent = null;
    }

    modalPage.set({
      header: { content: "", type: "HTML" },
      body: {
        content: resourceContent,
        type: "REACT"
      },
      footer: {
        content: (
          <div className="picker-modal-close">
            <ActionButton
              variant="tertiary"
              theme={whiteTheme}
              icon="close"
              onClick={modalPage.hide}
            />
          </div>
        ),
        type: "REACT"
      },
      addCloseButton: false
    });
    modalPage.show("picker-modal");
  };

  const onSelectBoard = selectedBoardItem => {
    setShowBoardPicker(false);
    addResourceAttachment({ ...selectedBoardItem, type: "BOARD" });
  };

  return (
    <>
      {showBoardPicker && (
        <BoardPicker
          onSaveSubmit={onSelectBoard}
          groupId={activeGroup?.id}
          onClose={() => setShowBoardPicker(false)}
          hasInstantCreationMode={hasInstantCreationMode}
        />
      )}
      <CalendarEventModalView
        hidden={showBoardPicker}
        closeModal={() => closeModal()}
        currentGroup={currentGroup}
        vendors={toJS(vendors)}
        accounts={toJS(accounts)}
        lastAccountUsed={toJS(lastAccountUsed)}
        fromHomepage={hasInstantCreationMode}
        submitFunction={
          creationMode || copyEvent
            ? value => newEvent(value)
            : value => editEvent(value)
        }
        groupsList={groupList}
        showNextGroups={fetchGroupNextPage}
        hasNextGroups={hasMoreGroup}
        usersList={userList}
        showNextUsers={fetchUserNextPage}
        hasNextUsers={hasMoreUser}
        resourceAttachments={resourceAttachments}
        removeResourceAttachment={removeResourceAttachment}
        selectResource={selectResource}
        openResource={openResource}
        hasCollapsableDates={hasCollapsableDates}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        creationMode={creationMode}
        defaultEventDetail={defaultEventDetail}
        type={type}
        showTabs={creationMode && !type}
        resourceView={resourceView}
        copyEvent={copyEvent}
        isLoading={isLoading}
      />
    </>
  );
};

export default observer(CalendarEventModal);
