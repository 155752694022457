import { motion } from "framer-motion";
import styled from "styled-components";

export const CardWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};

  display: flex;
  flex-direction: row;
  width: 100%;

  @media (min-width: 768px) {
    &:hover {
      border: 1px solid ${({ theme }) => theme.primaryColor[600]};
    }
  }
`;
