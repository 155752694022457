const __EXERCISE_KEY_ALL__ = "exercises";

export const exercisesQueryKey = {
  all: [__EXERCISE_KEY_ALL__],
  v1: [__EXERCISE_KEY_ALL__, "v1"],
  v3: [__EXERCISE_KEY_ALL__, "v3"],
  exerciseIdV1: ({
    exerciseId,
    reportBy
  }: {
    exerciseId: string;
    reportBy: "best" | "last";
  }) => [...exercisesQueryKey.v1, { exerciseId, reportBy }]
} as const;
