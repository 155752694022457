import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ModuleDetail from "../view/board/ModuleDetail";
import SingleModuleController from "./SingleModuleController";

const ModulesController = () => {
  const {
    PostCommentStore: { reset }
  } = useStores();
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <Switch>
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.initialRouteName}
        render={props => {
          return (
            <Redirect
              to={createUrl("modules", {
                group_id: props.match.params.group_id
              })}
            />
          );
        }}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.boardNew}
        render={props => {
          return (
            <Redirect
              to={createUrl("module_new", {
                group_id: props.match.params.group_id
              })}
            />
          );
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.newModule}
        render={props => <ModuleDetail target="new" {...props} />}
      />
      <Route
        key="boardDetailRedirectToModuleDetail"
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.boardId}
        render={props => {
          return (
            <Redirect
              to={createUrl("module_detail", {
                group_id: props.match.params.group_id,
                module_id: props.match.params.board_id
              })}
            />
          );
        }}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.module}
        render={props => {
          return <SingleModuleController {...props} />;
        }}
      />
      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  );
};

export default ModulesController;
