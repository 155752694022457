import useStores from "./useStores";

const useUnreadThreadMessagesCounter = () => {
  const {
    ChatStore: { threadsList },
    SpaceStore: { spacesListWithNoDemo }
  } = useStores();
  const globalUnreadThreadMessagesCount: number = spacesListWithNoDemo.reduce(
    (sum, space) => sum + space.unreadThreadMessagesCount,
    0
  );

  const inSpaceUnreadThreadMessagesCount: number = threadsList.reduce(
    (sum, thread) => sum + thread.unreadMessages,
    0
  );

  const hasGlobalUnreadThreadMessages = () => {
    return globalUnreadThreadMessagesCount > 0;
  };

  const hasInSpaceUnreadMessages = () => {
    return inSpaceUnreadThreadMessagesCount > 0;
  };

  return {
    hasGlobalUnreadThreadMessages,
    hasInSpaceUnreadMessages
  };
};

export default useUnreadThreadMessagesCounter;
