import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { markNotificationRead } from "src/js/repository/notificationRepository";
import { useStores } from "src/js/hooks";

type Timer = ReturnType<typeof setTimeout>;

const usePopoverState = () => {
  const [loading, setLoading] = useState(false);
  const timerRef = useRef<Timer | null>(null);
  const {
    NotificationStore: {
      popoverNotification: { notification },
      setPopoverNotification
    },
    GroupStore: { activeGroup },
    SpaceStore: { activeSpace },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const popoverRef = useRef<HTMLDivElement>();

  const resetNotification = useCallback(() => {
    setPopoverNotification({
      notification: null
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      resetNotification();
    }, 6000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [notification?.id, resetNotification]);

  const onMouseEnter = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const onMouseLeave = () => {
    timerRef.current = setTimeout(() => resetNotification(), 6000);
  };

  const handlePopoverMove = useCallback(
    ({ movement }: { movement: number }) => {
      if (movement > 0) {
        popoverRef.current.style.top = "16px";
        return;
      }
      resetNotification();
    },
    []
  );

  const swipeHandlers = useSwipeable({
    onSwiped: eventData => {
      handlePopoverMove({ movement: eventData.deltaY });
    },
    onSwiping: ({ deltaY, event }) => {
      event.stopPropagation();
      if (deltaY > 0) {
        popoverRef.current.style.top = "16px";
        return;
      }
      popoverRef.current.style.top = `${deltaY}px`;
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true
  });

  const context = useMemo(
    () => (activeGroup?.id === notification?.groupId ? "group" : "space"),
    [activeGroup?.id, notification]
  );

  const shouldShowPopover = useMemo(
    () => notification && activeSpace?.space?.id === notification.spaceId,
    [notification, notification?.spaceId, activeSpace?.space?.id]
  );

  const passtroughRef = (el: HTMLDivElement) => {
    swipeHandlers.ref(el);
    popoverRef.current = el;
  };

  // From right to left for desktop, from top to bottom for mobile
  const popoverAnimation = useMemo(
    () =>
      isLayoutModeMobile
        ? {
            initial: { translateY: -100 },
            animate: { translateY: 0 },
            exit: { translateY: -350 }
          }
        : {
            initial: { translateX: 100 },
            animate: { translateX: 0 },
            exit: { translateX: 350 }
          },
    [isLayoutModeMobile]
  );

  const onDismiss: React.MouseEventHandler<HTMLDivElement> = e => {
    if (loading) return;
    e.stopPropagation();
    resetNotification();
  };

  const onClickNotification = async () => {
    if (loading) return;
    setLoading(true);
    markNotificationRead({ notificationId: notification.id })
      .catch(error => {
        console.error("Error marking notification as read", error);
      })
      .finally(() => {
        resetNotification();
      });
  };

  return {
    swipeHandlers,
    notification,
    onMouseEnter,
    onMouseLeave,
    shouldShowPopover,
    resetNotification,
    ref: passtroughRef,
    popoverAnimation,
    context,
    onDismiss,
    onClickNotification
  };
};

export default usePopoverState;
