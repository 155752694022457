import React from "react";
import { Editor } from "@tiptap/core";
import { Icon, IconName } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { useCloseOnClickOutside } from "src/js/hooks";
import { dropdownAnimationVariants } from "src/js/pages/boards/components/ModuleCreateDropdown/ModuleCreateDropdown.utils";

import * as S from "./SubMenu.styles";

const SubMenu = ({ editor }: { editor: Editor }) => {
  const {
    ref: subMenuRef,
    isOpen: isSubMenuOpen,
    setIsOpen: setIsSubMenuOpen
  } = useCloseOnClickOutside(false);

  const subMenuItems: {
    icon: IconName;
    action: () => void;
    active: boolean;
  }[] = [
    {
      icon: "alignLeft",
      action: () =>
        editor.isActive({ textAlign: "left" })
          ? editor.chain().focus().unsetTextAlign().run()
          : editor.chain().focus().setTextAlign("left").run(),
      active: editor.isActive({ textAlign: "left" })
    },
    {
      icon: "alignCenter",
      action: () =>
        editor.isActive({ textAlign: "center" })
          ? editor.chain().focus().unsetTextAlign().run()
          : editor.chain().focus().setTextAlign("center").run(),
      active: editor.isActive({ textAlign: "center" })
    },
    {
      icon: "alignRight",
      action: () =>
        editor.isActive({ textAlign: "right" })
          ? editor.chain().focus().unsetTextAlign().run()
          : editor.chain().focus().setTextAlign("right").run(),
      active: editor.isActive({ textAlign: "right" })
    },
    {
      icon: "subScript",
      action: () => editor.chain().focus().toggleSubscript().run(),
      active: editor.isActive("subscript")
    },
    {
      icon: "superScript",
      action: () => editor.chain().focus().toggleSuperscript().run(),
      active: editor.isActive("superscript")
    }
  ];

  return (
    <div ref={subMenuRef} style={{ position: "relative" }}>
      <S.IconWrapper
        active={isSubMenuOpen}
        onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
      >
        <Icon width={20} height={20} initialViewbox icon="kebab" />
      </S.IconWrapper>
      <AnimatePresence initial={false}>
        {isSubMenuOpen && (
          <S.SubmenuRow
            variants={dropdownAnimationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {subMenuItems.map(item => (
              <S.Button
                key={item.icon}
                onClick={item.action}
                active={item.active}
              >
                <Icon icon={item.icon} width={13} height={13} initialViewbox />
              </S.Button>
            ))}
          </S.SubmenuRow>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SubMenu;
