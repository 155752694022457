import React, { createContext } from "react";
import { UserStore } from "src/js/stores";
import { RootStore } from "src/js/types";
import { rootStore } from "../StoreProvider";

const userStore = {
  activeUser: {
    uuid: 1234,
    name: "Pippo",
    avatar: {
      small:
        "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
    }
  },
  fetchUser: () => {}
};

const mockRootStore: RootStore = {
  ...rootStore,
  UserStore: userStore as unknown as UserStore
};

const StoresContext = createContext(mockRootStore);

const StoreProvider = ({ children }) => (
  <StoresContext.Provider value={{ ...mockRootStore }}>
    {children}
  </StoresContext.Provider>
);

export { StoreProvider, StoresContext };
