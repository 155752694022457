import styled from "styled-components";

export const Container = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SelectionContainer = styled.div`
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 20px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-bottom: 20px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  justify-content: space-between;
  gap: 12px;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
