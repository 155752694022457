import React from "react";
import ReactDOM from "react-dom";
import { observer } from "mobx-react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { Heading, Icon } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";

import { transformRealTimeNotificationToNotification } from "./PopoverNotificationItem.utils";
import { usePopoverState } from "./hooks";
import * as S from "./PopoverNotificationItem.styles";
import { Notification } from "../NotificationDrawer/components";

const PopoverNotificationItem = () => {
  const {
    swipeHandlers,
    notification,
    shouldShowPopover,
    onDismiss,
    ref,
    onMouseEnter,
    onMouseLeave,
    popoverAnimation,
    onClickNotification
  } = usePopoverState();

  const { translate } = useTranslation();

  const domEl = document.getElementById("notification-popover");

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {domEl &&
        ReactDOM.createPortal(
          <AnimatePresence>
            {shouldShowPopover && (
              <S.PopoverWrapper
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...swipeHandlers}
                ref={ref}
                {...popoverAnimation}
              >
                <S.PopoverHeader>
                  <Heading level="6">
                    {translate("notification_popover_notification_title")}
                  </Heading>
                  <S.CloseButton onClick={onDismiss}>
                    <Icon icon="close" width={12} height={12} />
                  </S.CloseButton>
                </S.PopoverHeader>
                <Notification
                  notification={transformRealTimeNotificationToNotification(
                    notification
                  )}
                  onMarkRead={onClickNotification}
                  onClick={onClickNotification}
                />
              </S.PopoverWrapper>
            )}
          </AnimatePresence>,
          domEl
        )}
    </>
  );
};

export default observer(PopoverNotificationItem);
