import { ContextualAlertThemeOptions, Palette } from "@ds_themes/types";

export const createContextualAlertTheme = ({
  yellow,
  grey
}: Palette): { contextualalert: ContextualAlertThemeOptions } => {
  return {
    contextualalert: {
      background: yellow[800],
      border: `1px solid ${yellow[450]}`,
      text: grey[50]
    }
  };
};
