import React, { FC } from "react";
import { Avatar, ToolbarSpaceItem } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { groupGetCover } from "src/js/GroupFunction";

import { GroupInformationProps } from "./types";
import * as S from "./UnloggedGroupInformation.styles";

const UnloggedGroupInformation: FC<GroupInformationProps> = ({
  inviteInfo
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  if (!inviteInfo) return null;

  const membersCount =
    inviteInfo.usersGroupCount - inviteInfo.groupUsers?.length;

  return (
    <S.GroupInformationContainer>
      <S.GroupCoverContainer>
        <S.AvatarBadgeContainer>
          <ToolbarSpaceItem
            id="space-invitation"
            theme={whiteTheme}
            isSelected={false}
            backgroundColorCode={inviteInfo.groupData.space.defaultBadgeColor}
            spaceName={inviteInfo.groupData.space.name}
            badge={inviteInfo.groupData.space.badge?.small}
            size="small"
          />
        </S.AvatarBadgeContainer>
        <S.GroupCover
          src={groupGetCover({
            cover: inviteInfo.groupData.group.cover?.small
          })}
        />
      </S.GroupCoverContainer>
      <S.GroupInformation>
        <S.SpaceName type="formSmallCaption">
          {inviteInfo.groupData.space.name}
        </S.SpaceName>
        <S.GroupName>{inviteInfo.groupData.group.name}</S.GroupName>
        <S.GroupDescription
          dangerouslySetInnerHTML={{
            __html: inviteInfo.groupData.group.description?.replace(
              /\n/g,
              "<br />"
            )
          }}
        />
        <S.GroupMembers>
          <S.GroupMembersAvatar>
            {inviteInfo.groupUsers &&
              inviteInfo.groupUsers.length &&
              inviteInfo.groupUsers.slice(0, 3).map(user => {
                return (
                  <Avatar
                    url={user.avatar ? user.avatar?.small : ""}
                    size="s"
                    theme={whiteTheme}
                    rounded
                    key={user.uuid}
                  />
                );
              })}
          </S.GroupMembersAvatar>
          {membersCount > 0 ? (
            <S.MembersCounter type="formSubtitle">
              +{" "}
              {translate({
                text: "registration_screen_group_invitation_members_count",
                stringVariables: { membersCount }
              })}
            </S.MembersCounter>
          ) : null}
        </S.GroupMembers>
      </S.GroupInformation>
    </S.GroupInformationContainer>
  );
};

export default UnloggedGroupInformation;
