import React, { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import {
  Box,
  Button,
  ContextualError,
  Heading,
  Input,
  Text,
  Icon
} from "@arcadia/design-system";
import { AnimatePresence, motion } from "framer-motion";
import { useParams } from "react-router";
import { useTranslation } from "src/js/translation";
import { useForm, useStores } from "src/js/hooks";
import PasswordChangeSVG from "src/img/icons/svg/password-change.svg";
import PasswordChangeLargeSvg from "src/img/icons/svg/password-change-lg.svg";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import StarsLeftSVG from "src/img/icons/svg/stars-recover-left.svg";
import StarsRightSVG from "src/img/icons/svg/stars-recover-right.svg";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import { __CLIENT_ID__ } from "src/js/settings/settingsGeneral";
import { PasswordStrengthIndicator } from "src/js/components/forms/PasswordStrenghtIndicator";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { checkPasswordStrength } from "src/js/modules/formFunction";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import { resetPassword } from "src/js/repository/unloggedRepository";

import { Background } from "../components/Background";
import * as S from "./ResetPasswordPage.styles";

type ResetPasswordForm = {
  password: string;
  password_confirmation: string;
};

const ResetPasswordPage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { UIStore, AuthStore } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const { register, handleSubmit, errors, formState, watch } =
    useForm<ResetPasswordForm>({
      mode: "onChange",
      reValidateMode: "onChange"
    });

  const password = watch("password");
  const __FORM_RESET_PASSWORD__ = {
    password: {
      required: "reset_password_password_required_error"
    },
    password_confirmation: {
      required: "reset_password_password_repeat_required_error",
      validate: value => {
        if (value === password && checkPasswordStrength(value) >= 4)
          return true;
        return "reset_password_password_repeat_validation_error";
      }
    }
  };

  const onSubmit = useCallback((formData: ResetPasswordForm) => {
    const requestData = formData as Record<string, string>;
    requestData.token = token;
    requestData.client_id = __CLIENT_ID__;
    resetPassword(requestData)
      .then(() => {
        AuthStore.resetUser(() => {
          showToastSuccess({
            str: translate("reset_password_success")
          });
          navigateTo(createUrl("home"));
        });
      })
      .catch(error => {
        showToastError({ str: translate(error.message) });
      });
  }, []);

  return (
    <Background>
      {!UIStore.isLayoutModeMobile ? (
        <S.Card
          key="reset-card"
          initial={{
            opacity: 0,
            scale: 1.15
          }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ duration: 0.35, ease: "easeInOut" }}
        >
          <Heading level="3">{translate("resetpassword_page_title")}</Heading>
          <AnimatePresence>
            {errors.password_confirmation && (
              <motion.div
                key="error-feedback"
                style={{ overflow: "hidden" }}
                initial={{ height: 0 }}
                animate={{ height: 1 }}
                exit={{ height: 0 }}
              >
                <ContextualError
                  theme={whiteTheme}
                  text={errors.password_confirmation}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <S.SVGWrapper>
            <PasswordChangeSVG />
          </S.SVGWrapper>
          <Box textAlign="center" marginTop={20}>
            <Text type="body">{translate("resetpassword_page_body")}</Text>
          </Box>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="password"
              label=""
              type="field"
              rounded
              placeholder={translate("reset_password_password_placeholder")}
              ref={register(__FORM_RESET_PASSWORD__.password)}
              error={errors.password}
              isPassword={!passwordVisible}
              theme={whiteTheme}
              rightSection={
                <S.IconWrapper
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <Icon icon={passwordVisible ? "eyeClosed" : "eyeOpen"} />
                </S.IconWrapper>
              }
            />
            <AnimatePresence>
              {password?.length !== 0 && (
                <motion.div
                  key="strength-indicator"
                  initial={{ height: 0, filter: "blur(10px)" }}
                  animate={{ height: "auto", filter: "blur(0px)" }}
                  exit={{ height: 0, filter: "blur(10px)" }}
                  style={{ overflow: "hidden" }}
                >
                  <PasswordStrengthIndicator
                    strength={checkPasswordStrength(password)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <Input
              name="password_confirmation"
              label=""
              type="field"
              rounded
              placeholder={translate(
                "reset_password_password_repeat_placeholder"
              )}
              ref={register(__FORM_RESET_PASSWORD__.password_confirmation)}
              error={errors.password}
              isPassword={!isConfirmPasswordVisible}
              theme={whiteTheme}
              rightSection={
                <S.IconWrapper
                  onClick={() =>
                    setConfirmPasswordVisible(!isConfirmPasswordVisible)
                  }
                >
                  <Icon
                    icon={isConfirmPasswordVisible ? "eyeClosed" : "eyeOpen"}
                  />
                </S.IconWrapper>
              }
            />
            <Box marginTop={24}>
              <Button
                theme={whiteTheme}
                variant="primary"
                fullWidth
                type="submit"
                disabled={!formState.isValid}
              >
                {translate("Conferma")}
              </Button>
            </Box>
          </S.Form>
          <UnloggedSupportLink />
        </S.Card>
      ) : (
        <S.MobileWrapper>
          <S.LogoWrapper>
            <WeSchoolLogoSVG />
          </S.LogoWrapper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={80}
          >
            <S.MobileTitleWrapper>
              <S.MobileTitle level="3">
                {translate("resetpassword_page_title")}
              </S.MobileTitle>
              <Box position="absolute" left={-40} bottom={2}>
                <StarsLeftSVG />
              </Box>
              <Box position="absolute" right={-40} top={2}>
                <StarsRightSVG />
              </Box>
            </S.MobileTitleWrapper>
            <Box marginTop={40}>
              <PasswordChangeLargeSvg />
            </Box>
            <S.MobileTextWrapper type="body">
              {translate("resetpassword_page_body")}
            </S.MobileTextWrapper>
          </Box>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="password"
              label=""
              hideLabel
              type="field"
              isPassword={!passwordVisible}
              rounded
              placeholder={translate("reset_password_password_placeholder")}
              ref={register(__FORM_RESET_PASSWORD__.password)}
              error={errors.password}
              theme={whiteTheme}
              rightSection={
                <S.IconWrapper
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <Icon icon={passwordVisible ? "eyeClosed" : "eyeOpen"} />
                </S.IconWrapper>
              }
            />
            <AnimatePresence>
              {password?.length !== 0 && (
                <motion.div
                  key="strength-indicator"
                  initial={{ height: 0, filter: "blur(10px)" }}
                  animate={{ height: "auto", filter: "blur(0px)" }}
                  exit={{ height: 0, filter: "blur(10px)" }}
                  style={{ overflow: "hidden" }}
                >
                  <PasswordStrengthIndicator strength={password?.length} />
                </motion.div>
              )}
            </AnimatePresence>
            <Box marginTop={16}>
              <Input
                name="password_confirmation"
                isPassword={!isConfirmPasswordVisible}
                label=""
                type="field"
                hideLabel
                rounded
                placeholder={translate(
                  "reset_password_password_repeat_placeholder"
                )}
                ref={register(__FORM_RESET_PASSWORD__.password_confirmation)}
                error={errors.password_confirmation}
                theme={whiteTheme}
                rightSection={
                  <S.IconWrapper
                    onClick={() =>
                      setConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  >
                    <Icon
                      icon={isConfirmPasswordVisible ? "eyeClosed" : "eyeOpen"}
                    />
                  </S.IconWrapper>
                }
              />
            </Box>
            <Box marginTop={40}>
              <Button
                theme={whiteTheme}
                variant="primary"
                fullWidth
                type="submit"
                disabled={!formState.isValid}
              >
                {translate("Conferma")}
              </Button>
            </Box>
          </S.Form>
        </S.MobileWrapper>
      )}
    </Background>
  );
};

export default ResetPasswordPage;
