import styled from "styled-components";

import { DemoSpaceLayoutProps } from "./JoinDemoSpace.types";

export const JoinDemoSpace = styled.div<DemoSpaceLayoutProps>`
  width: ${({ isMobile }) => (!isMobile ? "auto" : "52px")};
  height: ${({ isMobile }) => (!isMobile ? "auto" : "52px")};

  > img {
    width: ${({ isMobile }) => (!isMobile ? "40px" : "52px")};
    height: ${({ isMobile }) => (!isMobile ? "40px" : "52px")};
    object-fit: cover;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.grey[800]};
  }

  > span {
    border-bottom: 1px solid;
  }
`;
