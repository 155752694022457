import { makeAutoObservable, reaction } from "mobx";
import {
  activateWhiteboard,
  deactivateWhiteboard,
  fetchActiveWhiteboard
} from "src/js/repository/groupRepository";

/**
 * @name WhiteboardStore
 *
 * @description
 * control if the whiteboard is active or not
 */
class WhiteboardStore {
  $whiteboardUrl = null;

  $isAudioVideoLimited = false;

  constructor({ groupStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.groupStore = groupStore;
    this.dispose();
  }

  startWhiteboard = async groupId => {
    if (!groupId) {
      this.setWhiteboardUrl(null);
    } else
      try {
        const { url, is_av_limited } = await activateWhiteboard(groupId);
        this.setWhiteboardUrl(url);
        this.setIsAudioVideoLimited(is_av_limited);
      } catch (e) {
        this.setWhiteboardUrl(null);
      }
  };

  closeWhiteboard = async groupId => {
    if (groupId) {
      // eslint-disable-next-line no-useless-catch
      try {
        await deactivateWhiteboard(groupId);
        this.setWhiteboardUrl(null);
      } catch (e) {
        throw e;
      }
    }
  };

  fetchWhiteboardUrl = async groupId => {
    if (!groupId) {
      this.setWhiteboardUrl(null);
    } else
      try {
        const { url, is_av_limited } = await fetchActiveWhiteboard(groupId);
        this.setWhiteboardUrl(url);
        this.setIsAudioVideoLimited(is_av_limited);
      } catch (e) {
        this.setWhiteboardUrl(null);
      }
  };

  setWhiteboardUrl = url => {
    this.$whiteboardUrl = url;
  };

  setIsAudioVideoLimited = isAvLimited => {
    this.$isAudioVideoLimited = isAvLimited;
  };

  get whiteboardUrl() {
    return this.$whiteboardUrl;
  }

  get isAudioVideoLimited() {
    return this.$isAudioVideoLimited;
  }

  dispose = () => {
    reaction(
      // reacts on change of group Id
      () => this.groupStore.groupId,
      groupId => {
        this.fetchWhiteboardUrl(groupId);
      }
    );
  };
}

export default WhiteboardStore;
