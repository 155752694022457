import { Button, ContextualAlert, Text, Toggle } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import createReactClass from "create-react-class";
import "jquery-ui";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import moment from "moment";
import React from "react";
import { withModalStore, withSpaceStore } from "src/js/hooks/useStores";
import { openExternalLink } from "src/js/modules/commonFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import ExerciseShowVoteConfirmModal from "src/js/pages/exercise/ExerciseShowVoteConfirmModal";
import { showResultsToSingleUser } from "src/js/repository/exerciseRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import styled, { css, withTheme } from "styled-components";
import withBackbone from "with-backbone";
import CircleProgress from "../../../components/circleProgress";
import { modalMessage } from "../../../models/modalMessageModel";
import { getActiveGroup, getActiveGroupId } from "../../../modules/activeGroup";
import { __ } from "../../../modules/localization";
import {
  convertSecondsToFormattedString,
  createURL
} from "../../../modules/utility";
import { __EXERCISE_MAX_RATE__ } from "../../../settings/settings";

const StyledUsername = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const StyledDateTime = styled.span`
  color: #c9cfdf;
  font-size: 16px;
  font-weight: 500;
`;
const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCardHeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const StyledUserData = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const StyledExecutionInfoContainer = styled.div`
  ${({ fromActivity }) =>
    !fromActivity &&
    css`
      width: 100%;
      max-width: 675px;
    `}

  @media screen and(max-width: 768px) {
    width: 100%;
  }
`;

const StyledDetailsContainer = styled.div`
  max-height: 41px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    max-height: unset;
    display: flex;
    margin-top: 30px;
    flex-flow: column-reverse;
    gap: 16px;
  }
`;

const StyledDetailsToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: row-reverse;
  }
`;

const EserciziReportsUsersItem = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: {},
        execution: {},
        showArchive: true,
        live: false,
        singleUser: false,
        targetLink: "_self",
        fromActivity: false
      };
    },
    getInitialState() {
      return {
        loadingBtn: "",
        executionDate: "",
        showResults: false
      };
    },
    componentDidMount() {
      this.setState({ showResults: this.props.execution.show_results });
    },
    showResultsToUser() {
      showResultsToSingleUser(
        this.props.exercise.id,
        this.props.execution.user_id
      )
        .then(res => {
          this.setState(prevState => ({
            showResults: !prevState.showResults
          }));
        })
        .catch(err => {
          throw err;
        });
    },
    showUserSession() {
      const self = this;
      const execution = self.props.execution;

      self.setState({ loadingBtn: "loading" });

      $.ajax({
        type: "GET",
        dataType: "json",
        url: createURL("api_exercises_reports_users_sessions", {
          exercise_id: self.props.exercise.get("id"),
          user_id: execution.user_id
        }),
        success(ret) {
          const body = (
            <div>
              {map(ret, execution => (
                <EserciziReportsUsersItem
                  exercise={self.props.exercise}
                  execution={execution}
                  showArchive={false}
                  fromActivity={true}
                  theme={self.props.theme}
                  SpaceStore={self.props.SpaceStore}
                />
              ))}
            </div>
          );
          modalMessage.reset();
          modalMessage.set({
            header: {
              content: `<Text type="subHeaderTitle">${__("sessioni utente", {
                name: execution.name,
                surname: execution.surname
              })}</Text>`,
              type: "HTML"
            },
            body: { content: body, type: "REACT" },
            footer: { content: "", type: "HTML" },
            addCloseButton: true
          });
          self.setState({ loadingBtn: "" });
          modalMessage.show();
        },
        error(data) {
          self.setState({ loadingBtn: "" });
          const errorMsg = extractErrorMessage(
            data,
            __("impossibile recuperare i dati richiesti")
          );
          showToastError({ str: errorMsg });
        }
      });
    },
    render() {
      const self = this;
      const exercise = self.props.exercise;
      const execution = self.props.execution;
      const fromActivity = self.props.fromActivity;
      const {
        ModalStore: { openModal, closeModal },
        SpaceStore
      } = this.props;
      const { hasDecimalGrading } = SpaceStore || {};
      let voto = 0;
      let executionInfo = "";
      let bestExecution = "";
      let needReviewExecution = "";
      let dataFine = "";
      let sessionTimestamp = 0;
      let voteColor = "#fb5f39";
      let resultClass = "red-color";
      const { whiteTheme } = this.props.theme;
      let labelVote = __("quiz_report_detail_list_vote");

      if (
        !isUndefined(self.props.execution.vote) &&
        self.props.execution.vote > -1
      ) {
        voto =
          Math.round(self.props.execution.vote * 100) /
          (hasDecimalGrading ? 10 : 1);
      }

      if (
        !isUndefined(execution.session_init) &&
        !isUndefined(execution.session_time)
      ) {
        sessionTimestamp = execution.session_init + execution.session_time;
      } else if (!isUndefined(execution.final_report)) {
        sessionTimestamp =
          execution.final_report.session_init +
          execution.final_report.session_time;
      }
      if (sessionTimestamp > 0) {
        dataFine = moment(moment.unix(sessionTimestamp)).format("L LT");
      }

      let realDuration = execution.session_time;
      const timeThreshold = exercise.getTimeThreshold();
      /** If duration is longer than threshold default to threshold */
      if (timeThreshold > 0 && realDuration > timeThreshold) {
        realDuration = timeThreshold;
      }

      let refUrl = "exercises_reports_sessions";

      if (this.props.live) {
        refUrl = "exercises_reports_sessions_live";
      }

      const detailURL = `${createUrl(refUrl, {
        group_id: getActiveGroupId(),
        exercise_id: self.props.exercise.get("id"),
        session_id: execution.session_id
      })}`;

      let executionListBox = "";
      const timePartial = convertSecondsToFormattedString(
        execution.session_time
      );

      if (self.props.showArchive) {
        executionListBox = (
          <Button
            onClick={this.showUserSession}
            variant="secondary"
            fullWidth={getBreakpoint() === "smartphone"}
            theme={whiteTheme}
          >
            {__("visualizza altre esecuzioni")}
          </Button>
        );
      }

      if (getActiveGroup().isTeacher() && execution.count_reviews_needed > 0) {
        const alertCopy =
          execution.count_reviews_needed === 1
            ? "exercise_banner_need_review"
            : "exercise_banner_need_reviews";
        needReviewExecution = (
          <ContextualAlert
            theme={whiteTheme}
            text={execution.count_reviews_needed + " " + __(alertCopy)}
          />
        );
      }

      if (!getActiveGroup().isTeacher() && execution.count_redo_needed > 0) {
        const alertCopy =
          execution.count_redo_needed === 1
            ? "exercise_banner_need_redo"
            : "exercise_banner_need_redos";
        needReviewExecution = (
          <ContextualAlert
            theme={whiteTheme}
            text={execution.count_redo_needed + " " + __(alertCopy)}
          />
        );
      }

      executionInfo = (
        <div className="execution-info">
          {bestExecution}
          {needReviewExecution}
        </div>
      );

      const voteText = hasDecimalGrading ? voto : `${voto}%`;
      const normalizedVote = hasDecimalGrading ? voto : voto / 10;
      if (normalizedVote > 7) {
        voteColor = "#80e83d";
        resultClass = "green-color";
      } else if (normalizedVote > 4) {
        voteColor = "#ffc013";
        resultClass = "orange-color";
      }
      if (self.props.singleUser) {
        labelVote = __("voto");
      }

      const openExerciseShowVoteConfirmModal = () => {
        openModal(() => (
          <ExerciseShowVoteConfirmModal
            closeModal={closeModal}
            callbackOk={this.showResultsToUser}
          />
        ));
      };

      return (
        <>
          <div
            className="app__box user-report__wrapper"
            key={execution.session_id}
          >
            <StyledCardHeaderContainer>
              <StyledUserData>
                <StyledUsername>
                  {execution.name} {execution.surname}
                </StyledUsername>
                <StyledDateTime>{dataFine}</StyledDateTime>
              </StyledUserData>
              <StyledExecutionInfoContainer fromActivity={fromActivity}>
                {executionInfo}
              </StyledExecutionInfoContainer>
            </StyledCardHeaderContainer>

            <div className="row margin-right-15 margin-bottom-20">
              <div
                className={`col-sm-4 col-sm-offset-2 col-xs-6 text-center ${resultClass}`}
              >
                <CircleProgress
                  defaultText={voteText}
                  partial={voto}
                  total={
                    hasDecimalGrading
                      ? __EXERCISE_MAX_RATE__
                      : __EXERCISE_MAX_RATE__ * 10
                  }
                  type="decimal"
                  caption={labelVote.toUpperCase()}
                  colorBegin={voteColor}
                  colorEnd={voteColor}
                  key={`report-user-detail-vote-${Math.random()}`}
                />
              </div>
              <div className="col-sm-4 col-xs-6 text-center">
                <div className="circle-wrapper">
                  <div className="circle">
                    <Text type="subHeaderTitle">{timePartial}</Text>
                  </div>
                  <div className="text-center">
                    <Text type="formSubtitle">
                      <Translate text="tempo" />
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            {fromActivity ? (
              <StyledButtonContainer>
                <Button
                  onClick={() => {
                    modalMessage.hide();
                    openExternalLink(`#${detailURL}`);
                  }}
                  variant="primary"
                  theme={whiteTheme}
                  fullWidth={getBreakpoint() === "smartphone"}
                >
                  {__("correggi")}
                </Button>
              </StyledButtonContainer>
            ) : (
              <StyledDetailsContainer>
                <Button
                  onClick={() => {
                    navigateTo(detailURL);
                    modalMessage.hide();
                  }}
                  variant="primary"
                  theme={whiteTheme}
                  fullWidth={getBreakpoint() === "smartphone"}
                >
                  {__("dettagli")}
                </Button>
                {executionListBox}

                {!this.props.singleUser ? (
                  <StyledDetailsToggleContainer>
                    <Translate text="report_show_result_single_user" />
                    <Toggle
                      title={`report_show_to_user_${execution.user_id}`}
                      theme={whiteTheme}
                      checked={this.state.showResults}
                      onChange={openExerciseShowVoteConfirmModal}
                      disabled={this.state.showResults}
                    />
                  </StyledDetailsToggleContainer>
                ) : null}
              </StyledDetailsContainer>
            )}
          </div>
        </>
      );
    }
  })
);

export default withTheme(
  withSpaceStore(withModalStore(EserciziReportsUsersItem))
);
