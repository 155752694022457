import { CardActivity } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { DemoSpaceLayoutProps } from "./JoinDemoSpace.types";
import { useDemoSpaceGeneration } from "./hooks";
import { LoadingDemoSpace } from "./components";

const JoinDemoSpaceCard = ({ isMobile }: DemoSpaceLayoutProps) => {
  const { translate } = useTranslation();
  const { handleJoinDemoSpace, isBlockingLoaderVisible } =
    useDemoSpaceGeneration();
  const { greyTheme } = useTheme();

  return (
    <>
      {isBlockingLoaderVisible && <LoadingDemoSpace />}

      <CardActivity
        theme={greyTheme}
        size={isMobile ? "small" : "standard"}
        title={translate("space_empty_demo_title")}
        body={translate("space_empty_demo_subtitle")}
        type="draw"
        rightIcon="arrow"
        onClick={handleJoinDemoSpace}
      />
    </>
  );
};

export default JoinDemoSpaceCard;
