import styled from "styled-components";

export const TitleBlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  flex-direction: column;
  flex-flow: column;
  & button {
    position: absolute;
    left: 0;
    top: 15px;
    transform: translateY(-50%);
  }
`;

export const TitleContainer = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.grey[500]};
  margin-top: 25px;
`;
