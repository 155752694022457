import React, { useCallback } from "react";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { openExternalLink } from "src/js/modules/commonFunction";
import { Icon, SoundWave, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { dropdownAnimationVariants } from "src/js/pages/boards/components/ModuleCreateDropdown/ModuleCreateDropdown.utils";
import { trackLiveConference } from "src/js/modules/analyticsFunction";
import { AnimatePresence } from "framer-motion";
import { useLiveEvents } from "./hooks/useLiveEvents";

import * as S from "./LiveButton.styles";

const LiveButton = ({ groupId }: { groupId?: number }) => {
  const { isOpen, ref: dropdownRef, setIsOpen } = useCloseOnClickOutside(false);
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { events } = useLiveEvents();

  const onClick = useCallback(() => {
    if (events.length > 1) {
      setIsOpen(!isOpen);
    } else if (events.length === 1) {
      openExternalLink(events[0].liveUrl);
      trackLiveConference({
        groupId,
        liveRoomId: events[0].id
      });
    }
  }, [events, isOpen]);

  if (events.length === 0 || !groupId) return null;
  if (isLayoutModeMobile) return <SoundWave />;

  return (
    <S.DropdownRoot onClick={onClick} ref={dropdownRef}>
      <S.LiveButton
        hasMoreThanOneLive={events.length > 1}
        onClick={() => {}}
        whileTap={{ scale: 0.95 }}
      >
        <S.LeftSection>
          <SoundWave />
          <span>{translate("active_live_header_button")}</span>
        </S.LeftSection>
        {events.length > 1 && (
          <S.RightSection>
            <S.VerticalSpacer />
            <S.ChevronIcon animate={{ rotate: isOpen ? 180 : 0 }}>
              <Icon icon="chevronDown" width={16} height={16} initialViewbox />
            </S.ChevronIcon>
          </S.RightSection>
        )}
      </S.LiveButton>
      <AnimatePresence>
        {isOpen && (
          <S.DropdownWrapper
            variants={dropdownAnimationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {events.map(event => (
              <S.Event
                key={event.id}
                onClick={() => {
                  openExternalLink(event.liveUrl);
                }}
              >
                <SoundWave />
                <Text type="ctaTertiary">{event.title}</Text>
              </S.Event>
            ))}
          </S.DropdownWrapper>
        )}
      </AnimatePresence>
    </S.DropdownRoot>
  );
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const { whiteTheme } = useTheme();
  // console.log(events.map(e => toJS(e)));
  // const node = useRef();
  // useOnClickOutSide(node, () => setDropdownOpen(false));
  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };
  // let dropdownBody = null;
  // if (dropdownOpen) {
  //   dropdownBody = (
  //     <SelectDropdownBody
  //       items={events}
  //       carouselMode={carouselMode}
  //       actionFunction={(liveUrl, liveId) => {
  //         onClick(liveId);
  //         openExternalLink(liveUrl);
  //       }}
  //     />
  //   );
  // }
  // if (events.length === 0) return null;
  // if (events.length === 1)
  //   return (
  //     <S.LiveButtonWrapper>
  //       <LiveBtn
  //         theme={whiteTheme}
  //         onClick={() => {
  //           onClick(events[0].id);
  //           openExternalLink(events[0].liveUrl);
  //         }}
  //       />
  //     </S.LiveButtonWrapper>
  //   );
  // const portalDropdown = setupPortal(dropdownBody);
  // return (
  //   <ThemeProvider theme={whiteTheme}>
  //     <S.LiveButtonWrapper>
  //       <S.LiveButtonContainer
  //         onClick={toggleDropdown}
  //         ref={carouselMode ? null : node}
  //       >
  //         <S.LeftContainer>
  //           <SoundWave />
  //           <Text type="ctaTertiary">{children}</Text>
  //         </S.LeftContainer>
  //         <S.Divider />
  //         <S.IconWrapper>
  //           {dropdownOpen ? (
  //             <Icon icon="chevronUp" height="16" width="16" color="white" />
  //           ) : (
  //             <Icon icon="chevronDown" height="16" width="16" color="white" />
  //           )}
  //         </S.IconWrapper>
  //         {carouselMode ? portalDropdown : dropdownBody}
  //       </S.LiveButtonContainer>
  //     </S.LiveButtonWrapper>
  //   </ThemeProvider>
  // );
};

export default LiveButton;
