import colors from "@ds_themes/colors";
import {
  CardActivityThemeOptions,
  CardAddContentThemeOptions,
  CardContentThemeOptions,
  CardGroupThemeOptions,
  CardLessonThemeOptions,
  CardSchoolThemeOptions
} from "@ds_themes/types";

export const cardactivity: CardActivityThemeOptions = {
  title: colors.grey[50],
  body: {
    color: colors.grey[500]
  },
  background: colors.grey[1000],
  border: `1.5px solid ${colors.coldGrey[900]}`,
  check: {
    background: colors.violet[501],
    color: colors.grey[1000]
  },
  checked: {
    border: `1.5px solid ${colors.violet[501]}`
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  },
  icon: {
    background: colors.violet[800],
    color: colors.violet[501]
  }
};

export const cardcontent: CardContentThemeOptions = {
  title: colors.grey[50],
  body: colors.grey[300],
  background: colors.grey[1000],
  border: `1px solid ${colors.grey[800]}`,
  viewed: {
    background: colors.violet[501],
    color: colors.grey[1000]
  },
  notViewed: {
    background: colors.grey[800],
    color: colors.grey[1000]
  },
  wrapperIcon: {
    background: colors.violet[501],
    text: colors.grey[1000],
    border: `2px solid ${colors.grey[800]}`
  },
  wrapperText: {
    background: colors.grey[1000],
    title: colors.grey[50],
    body: colors.grey[50]
  },
  comment: {
    text: colors.grey[300],
    color: colors.violet[501]
  },
  resource: {
    background: colors.coldGrey[900],
    text: colors.blueGrey[300]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};

export const cardaddcontent: CardAddContentThemeOptions = {
  border: `1px solid ${colors.grey[800]}`,
  background: colors.grey[1000],
  wrapperIcon: {
    background: colors.coldGrey[900],
    text: colors.grey[50],
    border: `2px solid ${colors.grey[800]}`
  },
  wrapperText: {
    background: colors.grey[1000],
    text: colors.grey[50]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};

export const cardgroup: CardGroupThemeOptions = {
  title: colors.grey[50],
  body: {
    color: colors.grey[300]
  },
  background: colors.grey[1000],
  check: {
    background: colors.violet[501],
    color: colors.grey[1000]
  },
  border: `1.5px solid ${colors.coldGrey[900]}`,
  checked: {
    border: `1.5px solid ${colors.violet[501]}`
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};

export const cardlesson: CardLessonThemeOptions = {
  background: colors.coldGrey[900],
  text: colors.grey[50],
  icon: colors.violet[501],
  loading: {
    color: colors.violet[501]
  },
  cart: {
    background: colors.violet[501],
    icon: colors.grey[1000]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};

export const cardschool: CardSchoolThemeOptions = {
  title: {
    color: colors.grey[50]
  },
  body: {
    color: colors.grey[300]
  },
  background: colors.coldGrey[900],
  border: `1.5px solid ${colors.coldGrey[900]}`,
  check: {
    background: colors.violet[501],
    color: colors.grey[1000]
  },
  checked: {
    border: `1.5px solid ${colors.violet[501]}`
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};
