import styled from "styled-components";
import { motion } from "framer-motion";

export const AppLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StickyBannerWrapper = styled(motion.div)`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.topHeader + 1};
`;

export const ToolbarContainer = styled.div<{ stickyBannerHeight?: number }>`
  width: auto;
  height: calc(
    100dvh - ${({ stickyBannerHeight }) => stickyBannerHeight ?? 0}px
  );
  position: sticky;
  top: ${({ stickyBannerHeight }) => stickyBannerHeight ?? 0}px;
  z-index: ${({ theme }) => theme.zIndex.toolbar};
`;

export const SidebarContainer = styled(motion.div)<{
  stickyBannerHeight?: number;
}>`
  height: calc(
    100dvh - ${({ theme }) => theme.sizes.defaultHeaderHeight} -
      ${({ stickyBannerHeight }) => stickyBannerHeight ?? 0}px
  );
  top: ${({ theme, stickyBannerHeight }) =>
    `calc(${theme.sizes.defaultHeaderHeight} + ${stickyBannerHeight ?? 0}px)`};
  position: sticky;
  overflow: hidden;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopHeaderContainer = styled.div<{ stickyBannerHeight?: number }>`
  position: sticky;
  top: ${({ stickyBannerHeight }) => stickyBannerHeight ?? 0}px;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.topHeader};
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  > div {
    width: 100%;
  }
`;

export const PageContent = styled(motion.div)<{ isLayoutModeMobile: boolean }>`
  flex: 1;
  position: relative !important;
  padding-bottom: ${({ isLayoutModeMobile }) => isLayoutModeMobile && "60px"};
`;
