import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { __ } from "../../../modules/localization";

const QuizShortAnswer = withBackbone(
  createReactClass({
    setAnswer(e) {
      const answer = e.target.value;
      this.props.quiz.setUserAnswer(answer);
    },
    render() {
      const self = this;
      const quiz = this.props.quiz;
      return (
        <div className="quiz__shortanswer">
          <input
            type="text"
            className="form-control light input-primary--rounded quiz__shortanswer-text"
            placeholder={__("inserisci la tua risposta")}
            defaultValue={quiz.getUserAnswer()}
            onKeyUp={this.setAnswer}
            onInput={this.setAnswer}
            data-index="0"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
      );
    }
  })
);

export default QuizShortAnswer;
