import { ObjectValues } from "./Common";

export const QUIZ_TYPE = {
  TRUEFALSE: "quiztruefalse",
  SHORTANSWER: "quizshortanswer",
  OPENANSWER: "quizopenanswer",
  MULTICHOICE: "quizmultichoice",
  CROSSWORDS: "quizcrosswords",
  VIDEO: "quizvideo",
  FILLBLANK: "quizfillblank",
  MATCHING: "quizmatching",
  FADINGMATCHING: "quizfadingmatching",
  DOCUMENTANSWER: "quizdocumentanswer"
} as const;

export type QuizType = ObjectValues<typeof QUIZ_TYPE>;

export const ExerciseType = {
  EXAMINATION: "EXAMINATION",
  ASSIGNMENT: "ASSIGNMENT"
} as const;

export type ExerciseType = ObjectValues<typeof ExerciseType>;

export const CorrectionSide = {
  SERVER: "SERVER",
  CLIENT: "CLIENT"
} as const;

export type CorrectionSide = ObjectValues<typeof CorrectionSide>;

export const CorrectionType = {
  BULK: "BULK",
  STEP: "STEP"
} as const;

export type CorrectionType = ObjectValues<typeof CorrectionType>;

export type OldExercise = {
  show_results: boolean;
  done: boolean;
  quizzes_count: number;
  id: number;
  group: {
    id: number;
    name: string;
    subject: string;
    created: string;
    updated: string;
  };
  title: string;
  success_threshold: number;
  time_threshold: number;
  repeatable: boolean;
  correction_type: CorrectionType;
  correction_side: CorrectionSide;
  created_by: {
    username: string;
    id: number;
    uuid: string;
    name: string;
    surname: string;
  };
  published: boolean;
  deleted: boolean;
  public_result: boolean;
  updated_at: number;
  created_at: number;
  shuffle: boolean;
  quizzes: [
    {
      type: string;
      dtype: string;
      id: number;
      title: string;
      questions: unknown[];
      solutions: unknown[];
      hints: unknown[];
      position: number;
      countdown: number;
      allowed_retries: boolean;
      available_points: number;
      created_at: number;
    }
  ];
  type: ExerciseType;
  constraints: unknown[];
};
