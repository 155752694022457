import React, { FC, PropsWithChildren, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@ds_universal/Icon";
import colors from "@ds_themes/colors";

import * as S from "./Collapsable.styles";
import { CollapsableProps } from "./types";

const Collapsable: FC<PropsWithChildren<CollapsableProps>> = ({
  HeaderComponent,
  children,
  isCollapsed = false,
  setIsCollapsed = () => {},
  controlled = true, // Used to handle the functionality outside of this component
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () =>
    controlled
      ? setCollapsed(collapsed => !collapsed)
      : setIsCollapsed(!isCollapsed);

  const handleCollapsed = controlled ? collapsed : isCollapsed;

  return (
    <div {...rest}>
      <S.CollapsableHeader onClick={toggleCollapse}>
        {HeaderComponent}
        <motion.div
          animate={{
            rotateZ: handleCollapsed ? -180 : 0
          }}
          transition={{
            bounce: 0
          }}
        >
          <Icon
            icon="chevronLargeUp"
            width="16px"
            height="16px"
            stroke={colors.grey[50]}
            strokeWidth={1.5}
          />
        </motion.div>
      </S.CollapsableHeader>
      <S.CollapsableContent
        animate={{
          height: handleCollapsed ? 0 : "auto"
        }}
        transition={{
          ease: "easeInOut"
        }}
      >
        {children}
      </S.CollapsableContent>
    </div>
  );
};

export default Collapsable;
