import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { DoodleLoader } from "@arcadia/design-system";

const LoaderWithText = ({ showText, theme, fontSizeClass }) => {
  return (
    <>
      <div className={["text-center", fontSizeClass].join(" ")}>
        <Translate text={showText} />
      </div>
      <DoodleLoader theme={theme} />
    </>
  );
};

export default LoaderWithText;
