import { Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const GroupsCollapsableInvitations = styled.div<{ isExpanded: boolean }>`
  margin-bottom: 32px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  height: auto;
  max-height: 272px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-height: 504px;
      overflow: auto;
    `}

  & > div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const BlurRow = styled.div<{ shouldShow?: boolean }>`
  position: absolute;
  transition: visibility 0.5s ease;
  visibility: ${({ shouldShow }) => (shouldShow ? "visible" : "hidden")};
  bottom: 0px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => `linear-gradient(
    180deg,
    ${theme.colors.grey[1000]}00 0%,
    ${theme.colors.grey[1000]}80 44.27%,
    ${theme.colors.grey[1000]}FF 100%
  )`};
`;

export const GroupInvitation = styled.div<{ whiteBackground?: boolean }>`
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  background-color: ${({ theme, whiteBackground }) =>
    whiteBackground ? theme.colors.grey[1000] : theme.colors.grey[900]};

  @media screen and (max-width: 767px) {
    padding: 20px 18px 20px 12px;
  }
`;

export const AvatarWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -4px;
  right: -4px;
  border-radius: 50%;
  border: 1px solid white;
  > div {
    height: 30px;
    width: 30px;
    div {
      height: 100%;
      width: 100%;
    }
  }
  div {
    border: unset;
    margin: 0px;
    min-height: unset;
  }

  @media screen and (max-width: 767px) {
    width: 24px;
    height: 24px;
    > div {
      height: 22px;
      width: 22px;
    }
  }
`;

export const MidSectionWrapper = styled.div<{ alignCenter?: boolean }>`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-self: ${({ alignCenter }) => (alignCenter ? "center" : "unset")};
`;

export const GreyText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const ViewMore = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;
`;

export const MobileIcon = styled.div<{ isReject: boolean }>`
  background-color: ${({ theme, isReject }) =>
    isReject ? theme.primaryColor[50] : theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;
