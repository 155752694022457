import { Palette, PromoBannerThemeOptions } from "@ds_themes/types";

export const createPromoBannerTheme = ({
  warning,
  primary
}: Palette): { promobanner: PromoBannerThemeOptions } => {
  return {
    promobanner: {
      background: warning.main,
      color: warning.contrastText,
      link: primary.main,
      button: {
        outline: primary.light
      }
    }
  };
};
