import { Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const MainWrapper = styled.div`
  margin: 28px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  max-width: 756px;

  @media (max-width: 767px) {
    margin: 24px auto;
  }
`;

export const SectionWrapper = styled.div<{ zIndex?: number }>`
  margin-top: 24px;
  position: relative;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex};

  label {
    margin-bottom: 0px;
  }
`;
export const SectionPanel = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: relative;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  > span {
    margin-bottom: 1px;
  }
`;

const TitleWrapperBase = css`
  margin-bottom: 15px;
  text-transform: uppercase;
`;

export const TitleWrapperMobile = styled.div`
  ${TitleWrapperBase}
  @media (min-width: 767px) {
    display: none;
  }
`;
export const TitleWrapperDesktop = styled.div`
  ${TitleWrapperBase}
  @media (max-width: 767px) {
    display: none;
  }
`;

export const SaveLinkButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.violet[500]};
  margin-right: 8px;

  &:disabled {
    color: ${({ theme }) => theme.colors.violet[700]};
    cursor: not-allowed;
  }
`;

export const BackButton = styled.div`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

export const BadgeSubtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[600]};
`;
