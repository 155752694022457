import { Box, Button, Input } from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Calendar } from "src/js/components/Calendar";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { trimValue } from "src/js/modules/commonFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __FORM_UNDERAGE__ } from "src/js/settings/settingsFormValidation";
import { TranslationKeys } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { InputPasswordStrengthValidation } from "src/js/components/forms/InputPasswordStrengthValidation";
import { subtractYears } from "../Tutor/Tutor";
import { FormTypes, RegistrationFormData } from "../types";
import * as S from "../Registration.styles";

const setupUnderageData = (data: RegistrationFormData, token: string) => {
  const dataResult = data;
  dataResult.language = getCurrentLanguage();
  dataResult.inviteToken = token;

  return dataResult;
};

const Underage: FC<FormTypes> = ({ formData, handleRegister }) => {
  const { register, handleSubmit, errors, setValue, control } =
    useForm<RegistrationFormData>({ defaultValues: formData });
  const {
    isOpen: isCalendarOpen,
    ref: calendarRef,
    setIsOpen: setCalendarOpen
  } = useCloseOnClickOutside(false);
  const [date, setDate] = useState(null);
  const { whiteTheme } = useTheme();
  const { ThemeStore } = useStores();
  const { translate } = useTranslation();
  const { customUnloggedPrimaryButtonTheme } = ThemeStore;
  const token = formData.invite_token || "";

  const onSubmit = (data: RegistrationFormData) => {
    handleRegister(setupUnderageData(data, token));
  };

  const renderParentInfo = () => {
    const { tutor } = formData;
    return translate("parent_recap", {
      parent_name: tutor.name,
      parent_surname: tutor.surname,
      parent_email: tutor.email,
      parent_birthday: tutor.birthday
    });
  };

  const handleChangeDate = (dateChanged: Date) => {
    setValue("birthday", format(new Date(dateChanged), "dd-MM-yyyy"));
    setDate(dateChanged);
    setCalendarOpen(false);
  };

  return (
    <div>
      {renderParentInfo()}
      <form
        id="form-underage-registration"
        className="form-registration"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box marginBottom={16}>
          <Input
            name="name"
            placeholder={translate("underage_name_label")}
            ref={register(__FORM_UNDERAGE__.name)}
            feedback={
              errors.name
                ? {
                    message: translate(errors.name.message as TranslationKeys),
                    type: "error"
                  }
                : null
            }
            theme={whiteTheme}
            label=""
            hideLabel
            rounded
          />
        </Box>

        <Box marginBottom={16}>
          <Input
            name="surname"
            placeholder={translate("underage_surname_label")}
            ref={register(__FORM_UNDERAGE__.surname)}
            theme={whiteTheme}
            label=""
            hideLabel
            rounded
            feedback={
              errors.surname
                ? {
                    message: translate(
                      errors.surname.message as TranslationKeys
                    ),
                    type: "error"
                  }
                : null
            }
          />
        </Box>

        <Input
          name="email"
          disabled={formData.emailDisabled}
          value={formData.email}
          placeholder={translate("underage_email_label")}
          ref={register(__FORM_UNDERAGE__.email)}
          label=""
          hideLabel
          rounded
          feedback={
            errors.email
              ? {
                  message: translate(errors.email.message as TranslationKeys),
                  type: "error"
                }
              : null
          }
          onBlur={trimValue}
          theme={whiteTheme}
        />

        <Box margin="16px 0px">
          <Controller
            as={
              <S.CalendarInputWrapper ref={calendarRef}>
                <Input
                  label="birthday"
                  hideLabel
                  name="birthday"
                  theme={whiteTheme}
                  autoComplete="off"
                  readOnly
                  rounded
                  onKeyPress={e => e.preventDefault()}
                  error={{
                    message: translate(
                      errors.birthday?.message as TranslationKeys
                    )
                  }}
                  placeholder={translate("Data di nascita")}
                  onClick={() => setCalendarOpen(val => !val)}
                  ref={register(__FORM_UNDERAGE__.birthday)}
                />
                <Box zIndex={1} position="absolute" left={0} top="40px">
                  <Calendar
                    visible={isCalendarOpen}
                    selectedDate={date}
                    onChange={handleChangeDate}
                    minDate={subtractYears(18)}
                  />
                </Box>
              </S.CalendarInputWrapper>
            }
            control={control}
            rules={__FORM_UNDERAGE__.birthday}
            name="birthday"
            defaultValue={formData.birthday}
          />
        </Box>
        <InputPasswordStrengthValidation
          name="password"
          placeholder="underage_password_label"
          register={register}
          validation={__FORM_UNDERAGE__.password}
          hasError={!!errors.password}
          errorMessage={translate(errors.password?.message as TranslationKeys)}
          rounded
        />
        <Box marginTop={24}>
          <Button
            type="submit"
            variant="primary"
            theme={customUnloggedPrimaryButtonTheme || whiteTheme}
            fullWidth
          >
            {translate("register_form_main_title")}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default observer(Underage);
