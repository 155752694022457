import { decreaseBrightness, increaseBrightness } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { mergeThemes } from "src/js/modules/layoutFunction";
import WSTheme from "src/js/theme/theme";
import {
  // createGlobalStyle,
  ThemeProvider as WSThemeProvider
} from "styled-components";
import { getCustomThemeFromSettings } from "./utils";
// import reset from "styled-reset";

// To be included when bootstrap can be removed

// https://github.com/LoicMahieu/react-styled-flexboxgrid

// const GlobalStyle = createGlobalStyle`
//   ${reset}
//   html, body{
//     font-family:${({ theme }) => theme.fontFamily.default};
//   }
//   a{
//     color: ${({ theme }) => theme.colors.primary2};
//     font-size: 14px;
//   }
// `;

const ThemeProvider = ({ children }) => {
  const {
    ThemeStore: {
      whiteTheme,
      greyTheme,
      primaryColor,
      weschoolGreyTheme,
      weschoolWhiteTheme,
      customizationSettings
    }
  } = useStores();

  const customTheme = getCustomThemeFromSettings({
    customizationSettings,
    WSTheme
  });

  return (
    <WSThemeProvider
      theme={{
        ...mergeThemes(WSTheme, customTheme),
        whiteTheme,
        greyTheme,
        primaryColor: {
          50: decreaseBrightness(primaryColor, 92), // not precise, can't find exact nuance with this function
          100: decreaseBrightness(primaryColor, 78), // not precise, can't find exact nuance with this function
          200: decreaseBrightness(primaryColor, 62),
          300: decreaseBrightness(primaryColor, 37),
          400: decreaseBrightness(primaryColor, 20),
          500: primaryColor,
          600: increaseBrightness(primaryColor, 20),
          700: increaseBrightness(primaryColor, 55),
          800: increaseBrightness(primaryColor, 85),
          900: increaseBrightness(primaryColor, 90)
        },
        weschoolTheme: {
          white: weschoolWhiteTheme,
          grey: weschoolGreyTheme
        }
      }}
    >
      {/* <GlobalStyle /> */}
      {children}
    </WSThemeProvider>
  );
};
export default observer(ThemeProvider);
