import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import { HeaderBack } from "src/js/components/layout/HeaderBack";
import { SpaceModuleDetailsHeader } from "src/js/components/layout/SpaceModuleDetailsHeader";
import { SpaceSecondaryHeader } from "src/js/components/layout/SpaceSecondaryHeader";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { navigateTo } from "src/legacy/modules/history";

export const useSpaceGroupsHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    ExerciseStore: { activeExerciseName },
    GroupStore: { groupId }
  } = useStores();

  const [headerComponent, setHeaderComponentInner] = useState<ReactNode>(
    <SpaceSecondaryHeader />
  );
  const [headerComponentManual, setHeaderComponentManual] =
    useState<ReactNode>();

  const setHeaderComponent = useCallback((component: ReactNode) => {
    setHeaderComponentManual(component);
  }, []);

  const resetHeaderComponent = useCallback(() => {
    setHeaderComponentManual(undefined);
  }, []);

  useEffect(() => {
    if (headerComponentManual) {
      setHeaderComponentInner(headerComponentManual);
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exercisesNew
      )
    ) {
      setHeaderComponentInner(<HeaderBack title="exercises_new_meta_title" />);
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseReportsSession
      )
    ) {
      setHeaderComponentInner(<HeaderBack stringTitle={activeExerciseName} />);
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.Post.postReport
      )
    ) {
      setHeaderComponentInner(<HeaderBack title="report_abuse_title" />);
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.resourceReport
      )
    ) {
      setHeaderComponentInner(<HeaderBack title="report_abuse_title" />);
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseReportsUsers
      )
    ) {
      setHeaderComponentInner(
        <HeaderBack
          stringTitle={activeExerciseName}
          onGoBack={() => {
            if (history.length > 1) {
              history.goBack();
              return;
            }
            navigateTo(
              createUrl("exercises", {
                group_id: groupId
              })
            );
          }}
        />
      );
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.groupOverquotaBlocked
      )
    ) {
      setHeaderComponentInner(null);
    } else if (
      matchPath(
        location.pathname,
        WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.module
      )
    ) {
      setHeaderComponentInner(<SpaceModuleDetailsHeader />);
    } else {
      setHeaderComponentInner(<SpaceSecondaryHeader />);
    }
  }, [location.pathname, headerComponentManual, activeExerciseName]);

  return { headerComponent, setHeaderComponent, resetHeaderComponent };
};
