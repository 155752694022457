import styled from "styled-components";

export const ModalBody = styled.div`
  padding: 24px 20px 20px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const TextWrapper = styled.div`
  margin: auto auto 24px;
  display: flex;
  justify-content: center;
  max-width: 352px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & > span {
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    margin: 48px auto 24px;
    max-width: 100%;
    gap: 42px;
  }
`;

export const AvatarWrapperText = styled.div`
  pointer-events: none;
  & > div:first-child {
    margin-bottom: 0;

    & > div:first-child {
      &:hover {
        border: none;
        padding: 0;
      }
    }
  }
`;

export const Form = styled.form`
  @media screen and (max-width: 767px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const SubmitContainer = styled.div`
  margin-top: 24px;

  @media screen and (max-width: 767px) {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }
`;

export const WarningContainer = styled.div`
  margin-top: 12px;
  > div {
    max-width: 100%;
  }
`;
