import styled from "styled-components";

import { SPromoBannerProps } from "./types";

export const PromoBannerContainer = styled.div<SPromoBannerProps>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.promobanner.background};
  color: ${({ theme }) => theme.promobanner.color};
  & button:not([aria-label="close"]) {
    background: transparent;
    color: ${({ theme }) => theme.promobanner.link};
    &::after {
      background: ${({ theme }) => theme.promobanner.link};
    }
    @media screen and (min-width: 768px) {
      padding-left: 8px;
    }
  }
`;

export const PromoBannerText = styled.div`
  align-self: center;
  padding-right: 0px;
  @media screen and (min-width: 768px) {
    padding-right: 22px;
  }
`;

export const PromoBannerAvatar = styled.div`
  align-self: center;
  padding: 5px 16px;
`;

export const PromoBannerButton = styled.button`
  align-self: center;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;

  &:focus-visible {
    outline: ${({ theme }) => `3px solid ${theme.promobanner.button.outline}`};
  }

  @media screen and (min-width: 767px) {
    position: absolute;
    right: 0;
  }
`;
