import { Text } from "@arcadia/design-system";
import styled from "styled-components";
import { GroupInCommonStyleProps } from "src/js/pages/user/ProfilePage/components/GroupsInCommon/GroupsInCommon.types";

export const GroupWrapper = styled.div`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  max-width: 688px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const GroupTitle = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[50]};
`;

export const GroupCardWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
export const GroupCard = styled.div`
  width: 100%;
  max-width: 212px;
  position: relative;
  border-radius: 16px;
  box-sizing: border-box;
  height: 142px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey[900]};

  border: 0px;
  padding: 0px;
  cursor: pointer;

  transition: box-shadow 0.3s ease-in-out;

  img {
    transition: transform 0.3s ease-in-out;
    transform: scale3d(1, 1, 1);
    backface-visibility: hidden;
  }

  &:hover {
    img {
      transform: scale3d(1.03, 1.03, 1);
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    border-radius: 8px;
    height: 50px;
    padding: 9px;
    display: flex;
    flex-flow: row;
    align-items: center;
  }
`;

export const GroupCardPlaceholder = styled.div<GroupInCommonStyleProps>`
  display: flex;
  height: 84px;
  width: 100%;
  background-image: ${({ background }) => `url(${background})`};
  background-color: ${({ theme }) => theme.colors.violet[500]};
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    height: 32px;
    border-radius: 4px;
    width: 32px;
    position: absolute;
    right: 0;
    z-index: 2;
  }
`;

export const GroupBadgeContainer = styled.div`
  width: 56px;
  position: relative;
  height: 100%;
  flex: 0 0 56px;
`;

export const GroupCardGroupInfo = styled.div`
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: calc(100% - 84px);
  @media screen and (max-width: 768px) {
    height: 100%;
    min-width: 0;
  }
`;

export const GroupCardGroupName = styled.div`
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > span {
    white-space: nowrap;
  }
`;

export const GroupCardSpaceCover = styled.div`
  margin-top: 12px;
  margin-left: 12px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  border: ${({ theme }) => theme.colors.grey[1000]};
  @media screen and (max-width: 768px) {
    min-width: 32px;
    height: 32px;
    margin-top: 0;
    margin-left: 0;
    position: absolute;
    left: 0;
    z-index: 1;
  }
`;
