import { Palette, ResourceProgressBarThemeOptions } from "@ds_themes/types";

export const createResourceProgressBarTheme = ({
  blueGrey,
  coldGrey,
  primary,
  error,
  warning,
  grey
}: Palette): { resourceprogressbar: ResourceProgressBarThemeOptions } => {
  return {
    resourceprogressbar: {
      default: blueGrey[800],
      line: primary.main,
      text: blueGrey[200],
      hover: coldGrey[900],
      finished: {
        background: primary.main,
        icon: "check2",
        color: primary.contrastText
      },
      failed: {
        background: error[500],
        icon: "closeBold",
        color: grey[1000]
      },
      canceled: {
        background: warning.main,
        icon: "danger",
        color: warning.contrastText
      }
    }
  };
};
