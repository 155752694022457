import { Icon } from "@arcadia/design-system";
import moment from "moment";
import { default as React } from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import OrderIcon from "./OrderIcon";

const BoardActivityHeader = ({
  boards,
  orderState,
  orderId,
  orderType,
  setOrder
}) => {
  return boards.map(board => (
    <th key={`board-activity-header-${board.id}`} id={`board-${board.id}`}>
      <div className="inline-block full-width">
        {board.name}
        {board.mode === "EDIT" && (
          <span className="margin-left-5">
            <Icon icon="group" height="12" width="12" />
          </span>
        )}
      </div>
      <TranslatedTooltip
        tooltipString="order_by_board_complete"
        placement="bottom"
      >
        <div
          className="filter text-center half"
          onClick={() => setOrder("viewed-element", board.id)}
        >
          {orderType === "viewed-element" && orderId === board.id ? (
            <OrderIcon orderState={orderState} />
          ) : (
            <Icon icon="minus" width="12" height="12" />
          )}
        </div>
      </TranslatedTooltip>
      <TranslatedTooltip
        tooltipString="order_by_comments_number"
        placement="bottom"
      >
        <div
          className="filter text-center half"
          onClick={() => setOrder("comments", board.id)}
        >
          {orderType === "comments" && orderId === board.id ? (
            <OrderIcon orderState={orderState} />
          ) : (
            <Icon icon="minus" width="12" height="12" />
          )}
        </div>
      </TranslatedTooltip>
      <TranslatedTooltip tooltipString="publication_date" placement="bottom">
        <div className="publish-date">
          {moment.unix(board.created).format("ll")}
        </div>
      </TranslatedTooltip>
    </th>
  ));
};

export default BoardActivityHeader;
