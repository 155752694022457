import "moment/locale/it";
import "moment/locale/es";
import "moment/locale/en-gb";
import { getDefaultLanguage } from "src/js/modules/localization";
import i18next from "i18next";

let language = getDefaultLanguage();

export function __(key, obj) {
  const translatedText = i18next.t(key, obj);
  return translatedText;
}

/**
 * Get the app language
 * @return {String}
 */
export function getLanguage() {
  return language;
}
