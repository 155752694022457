/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import {
  createPalette,
  updatePalette,
  createTheme,
  colors,
  createPrimaryButtonTheme,
  getHighContrastColor,
  decreaseBrightness
} from "@arcadia/design-system";
import { CustomizationSettings } from "../types/models/Customization";
import UserStore from "./UserStore";
import SpaceStore from "./SpaceStore";
import GroupStore from "./GroupStore";
import SpacesSettingsStore from "./SpacesSettingsStore";

/**
 * @name ThemeStore
 *
 * @description
 * ...
 */
class ThemeStore {
  private $primaryColor = colors.violet[501];

  private $whitePalette = createPalette({
    primary: colors.violet[501],
    background: colors.grey[1000],
    appearence: "light"
  });

  private $greyPalette = createPalette({
    primary: colors.violet[501],
    background: colors.coldGrey[900],
    appearence: "heavy"
  });

  private $whiteTheme = createTheme(this.$whitePalette);
  private $greyTheme = createTheme(this.$greyPalette);

  private $customWhitePalette = null;
  private $customGreyPalette = null;

  private $customizationSettings: CustomizationSettings = null;

  // TODO, remove when unlogged/logged page theme are unified
  $customUnloggedPrimaryButtonTheme = null;
  private $spaceId: string = null;

  private userStore: UserStore;
  private spaceStore: SpaceStore;
  private groupStore: GroupStore;
  private spacesSettingsStore: SpacesSettingsStore;

  constructor({ userStore, groupStore, spacesSettingsStore, spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.groupStore = groupStore;
    this.userStore = userStore;
    this.spaceStore = spaceStore;
    this.spacesSettingsStore = spacesSettingsStore;
    this.disposeGroupCustomization();
    this.disposeSpaceCustomization();
    this.disposeDashboardCustomization();
  }

  setSpaceCustomTheme = (spaceId, settings) => {
    // TODO, remove when unlogged/logged page theme are unified
    if (spaceId === this.$spaceId) {
      this.setCustomPalette({
        primaryColor: null
      });
      this.$spaceId = null;
      return;
    }
    this.$spaceId = spaceId;
    const ctaFillColor = settings?.ctaFillColor || colors.violet[501];

    const colorLink = getHighContrastColor(
      ctaFillColor,
      decreaseBrightness(ctaFillColor, 25),
      ctaFillColor
    );
    const colorButton = getHighContrastColor(
      ctaFillColor,
      decreaseBrightness(ctaFillColor, 5),
      ctaFillColor
    );

    this.setCustomUnloggedPrimaryButton({ color: colorButton });

    this.setCustomPalette({
      primaryColor: colorLink
    });
  };

  setCustomUnloggedPrimaryButton = ({ color }) => {
    const newPalette = updatePalette({
      palette: this.$whitePalette,
      primary: color
    });

    const customButtonTheme = createPrimaryButtonTheme(newPalette);

    this.$customUnloggedPrimaryButtonTheme = { button: customButtonTheme };
  };

  setCustomPalette = ({ primaryColor = null, secondaryColor = null }) => {
    this.$primaryColor = primaryColor || colors.violet[501];

    if (!primaryColor && !secondaryColor) {
      this.$customGreyPalette = null;
      this.$customWhitePalette = null;
      return;
    }

    this.$customWhitePalette = updatePalette({
      palette: this.$whitePalette,
      primary: primaryColor,
      secondary: secondaryColor
    });
    this.$customGreyPalette = updatePalette({
      palette: this.$greyPalette,
      primary: primaryColor,
      secondary: secondaryColor
    });
  };

  setCustomizationSettings = settings => {
    this.$customizationSettings = settings;
  };

  // https://weschool-srl.atlassian.net/wiki/spaces/DI/pages/529072129/Spaces+Customizations#Login-and-registration-pages-with-group-invite
  get hasCustomization(): boolean | null {
    return !!(
      this.$customizationSettings?.ctaFillColor ||
      this.$customizationSettings?.layoutColor
    );
  }

  get primaryColor() {
    return this.$primaryColor;
  }

  get whitePalette() {
    return this.$whitePalette;
  }

  get customUnloggedPrimaryButtonTheme() {
    return this.$customUnloggedPrimaryButtonTheme;
  }

  get whiteTheme() {
    if (this.$customGreyPalette) return createTheme(this.$customGreyPalette);

    return this.$greyTheme;
  }

  // Utils methods for exercise execution part (that will not follow customizations for now)
  get weschoolGreyTheme() {
    return this.$greyTheme;
  }
  get weschoolWhiteTheme() {
    return this.$whiteTheme;
  }

  get greyTheme() {
    if (this.$customWhitePalette) return createTheme(this.$customWhitePalette);
    return this.$whiteTheme;
  }

  get customizationSettings() {
    return this.$customizationSettings;
  }

  disposeGroupCustomization = () => {
    reaction(
      // reacts on change of groupSettings
      () => this.groupStore.settings,
      settings => {
        if (settings || !this.spaceStore?.activeSpace?.customizationSettings) {
          this.setCustomizationSettings(settings);
          this.setCustomPalette({
            primaryColor: settings?.ctaFillColor
          });
        }
      }
    );
  };

  disposeSpaceCustomization = () => {
    reaction(
      // reacts on change of active space
      () => this.spaceStore?.activeSpace?.customizationSettings,
      settings => {
        this.setCustomizationSettings(settings);
        this.setCustomPalette({
          primaryColor: settings?.ctaFillColor
        });
      }
    );
  };

  disposeDashboardCustomization = () => {
    reaction(
      // reacts on change of group spaceSettings
      () => this.spacesSettingsStore.generalSettings,
      settings => {
        this.setCustomizationSettings(settings?.customizationSettings);
        this.setCustomPalette({
          primaryColor: settings?.customizationSettings?.ctaFillColor
        });
      }
    );
  };
}

export default ThemeStore;
