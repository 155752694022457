import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import debounce from "lodash/debounce";
import reject from "lodash/reject";
import filter from "lodash/filter";
import find from "lodash/find";
import each from "lodash/each";
import QuizMatchingItem from "./exerciseEngine_quiz_matching_item";
import { basePageModel } from "../../../modules/pageStatus";
import { __ } from "Legacy/modules/localization";
import ResourceViewer from "../../resource/ResourceViewer";

const QuizMatching = withBackbone(
  createReactClass({
    getInitialState() {
      return {
        questions: this.props.quiz.getQuestions(),
        answers: this.props.quiz.getUserAnswer(),
        status: "",
        activeItem: null,
        resourceView: null
      };
    },
    componentDidMount() {
      basePageModel.on("change:breakpoint", this.updateBP);
      this.adjustColumns();
      $(window).on("resize", this.adjustColumns);
    },
    componentWillUnmount() {
      basePageModel.off("change:breakpoint", this.updateBP);
      $(window).off("resize", this.adjustColumns);
    },
    updateBP() {
      this.forceUpdate();
    },
    adjustColumns: debounce(() => {
      const $left = $(".js-col-left");
      const $right = $(".js-col-right");
      const leftHeight = $left.innerHeight();
      const rightHeight = $right.innerHeight();
      let $colToAdjust = null;
      let $colToCopy = null;
      if (leftHeight < rightHeight) {
        $colToAdjust = $left;
        $colToCopy = $right;
      }
      if (leftHeight > rightHeight) {
        $colToAdjust = $right;
        $colToCopy = $left;
      }
      // se uguali non faccio nulla, non serve

      if ($colToAdjust !== null) {
        const defaultMargin = 5;
        const $itemsA = $colToAdjust.find(".js-quiz__matching-wrapper");
        const $itemsC = $colToCopy.find(".js-quiz__matching-wrapper");

        // resetto margini prima del calcolo
        $itemsA.css("marginBottom", "0");
        $itemsC.css("marginBottom", "0");

        const adjustHeight = $colToAdjust.innerHeight();
        const adjustCopy = $colToCopy.innerHeight();

        const difference = adjustCopy - adjustHeight;
        const marginToAdd = Math.floor(
          difference / ($itemsA.length - 1) + defaultMargin
        ); // -1 perchè ultimo margine non deve essere calcolato

        // $itemsA.css("marginBottom", marginToAdd + "px");
        // $itemsC.css("marginBottom", defaultMargin + "px");
        $itemsA.not(":last").animate(
          {
            // non aggiorno l'ultimo, non serve
            marginBottom: `${marginToAdd}px`
          },
          300
        );
        $itemsC.not(":last").animate(
          {
            marginBottom: `${defaultMargin}px`
          },
          300
        );
      }
    }, 300),
    showResource(resource) {
      this.setState({
        resourceView: (
          <ResourceViewer
            resources={[resource]}
            hideTitle
            disableOption
            onClose={() => this.setState({ resourceView: null })}
          />
        )
      });
    },
    setAnswer(answer) {
      const self = this;
      let answers = this.state.answers;
      const existing_answer = find(answers, { index_1: answer.index_1 });
      if (existing_answer !== undefined) {
        answers = reject(answers, item => item.index_1 === answer.index_1);
      }
      const existing_object = find(answers, { index_2: answer.index_2 });
      if (existing_object !== undefined) {
        answers = reject(answers, item => item.index_2 === answer.index_2);
      }
      answers.push(answer);
      this.props.quiz.setUserAnswer(answers);
      this.setState(
        {
          answers
        },
        () => {
          self.forceUpdate();
        }
      );
    },
    removeAnswerFromIndex(index) {
      let answers = this.state.answers;
      answers = reject(
        answers,
        item => index === answer.index_1 || index === answer.index_2
      );
      this.setState({
        answers
      });
    },
    selectElement(index_1, index_2) {
      this.setAnswer({
        index_1,
        index_2
      });
    },
    handleClickItem(item) {
      if (this.state.activeItem === null) {
        this.setState({ activeItem: item });
      } else {
        // controllo se sto cambiando selezione attiva o se sto abbinando elemento
        // se stessa colonna cambio, altrimento associo
        if (this.state.activeItem.column === item.column) {
          if (this.state.activeItem.index === item.index) {
            this.setState({ activeItem: null });
          } else {
            this.setState({ activeItem: item });
          }
        } else {
          if (this.state.activeItem.column === 1) {
            this.selectElement(this.state.activeItem.index, item.index);
          } else {
            this.selectElement(item.index, this.state.activeItem.index);
          }
          this.setState({ activeItem: null }); // resetto selezione dopo settaggio risposta
        }
      }
    },
    getColorByPosition(index) {
      const colors = [
        "#54b3e9",
        "#1dc63a",
        "#561efd",
        "#ba440d",
        "#e3ae2d",
        "#b12f34",
        "#057f5a",
        "#b77e3e",
        "#8f5620",
        "#944abc",
        "#af72aa",
        "#2ca991",
        "#048ac0",
        "#5da375",
        "#4d2b97",
        "#4a6f09",
        "#280f0e",
        "#276433",
        "#1d78bb",
        "#8c09c2",
        "#9a3d19",
        "#30bd7d",
        "#ef9d99",
        "#d47756",
        "#72a346",
        "#727794",
        "#b203d5",
        "#906738",
        "#9055f5",
        "#2b4ac8",
        "#f91c59",
        "#962cd6",
        "#95cb7a",
        "#ae5097",
        "#664677"
      ];
      if (typeof colors[index] === "undefined") {
        return "#000000";
      }
      return colors[index];
    },
    /**
     * Transform an hexadecimal color into rgb
     * @param  {String} hex An hexadecimal color
     * @return {Object}     An object containing the values for r, g and b
     */
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    },
    render() {
      const self = this;
      const quiz = this.props.quiz;
      const questions = this.state.questions;
      const answers = this.state.answers;

      const column1 = filter(questions, { column: 1 }); // domande, nella colonna di sx
      const column2 = filter(questions, { column: 2 }); // "risposte", nella colonna di dx

      let activeAnswerByIndex;
      if (self.state.activeItem !== null) {
        activeAnswerByIndex = find(
          answers,
          a =>
            a.index_1 === self.state.activeItem.index ||
            a.index_2 === self.state.activeItem.index
        );
      }

      return (
        <>
          <div className="quiz__matching">
            <div className="row clearfix">
              <div
                className="col-lg-4 col-lg-offset-2 col-md-5 col-md-offset-1 col-sm-6 col-xs-6 js-col-left"
                style={{ borderRight: "1px solid #DDDDDD" }}
              >
                {map(column1, q => {
                  let in_answers = false;
                  let in_answers_position = null;
                  let color = "";
                  let addStyle = {};
                  let addClass = "";
                  each(answers, (a, i) => {
                    if (a.index_1 === q.index) {
                      if (
                        activeAnswerByIndex === undefined ||
                        activeAnswerByIndex.index_1 !== q.index
                      ) {
                        in_answers = true;
                        in_answers_position = i;
                        color = self.getColorByPosition(in_answers_position);
                        const rgbColor = self.hexToRgb(color);
                        addStyle = {
                          background: `rgba(${[
                            rgbColor.r,
                            rgbColor.g,
                            rgbColor.b,
                            0.5
                          ].join(", ")})`,
                          "border-color": color
                        };
                        addClass = "is-active";
                      }
                    }
                  });

                  if (self.state.activeItem !== null) {
                    if (q.index === self.state.activeItem.index) {
                      addStyle = {
                        background: "none",
                        "border-color": "#00adef"
                      };
                      addClass = "is-active";
                    }
                  }

                  return (
                    <div
                      className={[
                        "quiz__matching-wrapper",
                        "js-quiz__matching-wrapper",
                        addClass
                      ].join(" ")}
                      style={addStyle}
                    >
                      <QuizMatchingItem
                        item={q}
                        onClick={self.handleClickItem.bind(self, q)}
                        openResourceFunc={self.showResource}
                        className={["quiz__matching-item"].join(" ")}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="col-lg-4 col-md-5 col-sm-6 col-xs-6 js-col-right">
                {map(column2, s => {
                  let in_answers = false;
                  let in_answers_position = null;
                  let color = "";
                  let addStyle = {};
                  let addClass = "";
                  each(answers, (a, i) => {
                    if (a.index_2 === s.index) {
                      if (
                        activeAnswerByIndex === undefined ||
                        activeAnswerByIndex.index_2 !== s.index
                      ) {
                        in_answers = true;
                        in_answers_position = i;
                        color = self.getColorByPosition(in_answers_position);
                        const rgbColor = self.hexToRgb(color);
                        addStyle = {
                          background: `rgba(${[
                            rgbColor.r,
                            rgbColor.g,
                            rgbColor.b,
                            0.5
                          ].join(", ")})`,
                          "border-color": color
                        };
                        addClass = "is-active";
                      }
                    }
                  });

                  if (self.state.activeItem !== null) {
                    if (s.index === self.state.activeItem.index) {
                      addStyle = {
                        background: "none",
                        "border-color": "#00adef"
                      };
                      addClass = "is-active";
                    }
                  }

                  return (
                    <div
                      className={[
                        "quiz__matching-wrapper",
                        "js-quiz__matching-wrapper",
                        addClass
                      ].join(" ")}
                      style={addStyle}
                    >
                      <QuizMatchingItem
                        item={s}
                        onClick={self.handleClickItem.bind(self, s)}
                        openResourceFunc={self.showResource}
                        className={["quiz__matching-item"].join(" ")}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {this.state.resourceView}
        </>
      );
    }
  })
);

export default QuizMatching;
