export const getRandomInRange = (min: number, max: number): number => {
  if (min > max) {
    throw new Error("Minimum value cannot be greater than maximum value.");
  }

  // Adjust the range so that the random number is a multiple of 5
  const adjustedMin = Math.ceil(min / 5);
  const adjustedMax = Math.floor(max / 5);

  if (adjustedMin > adjustedMax) {
    throw new Error("No multiples of 5 within the range.");
  }

  // Generate a random number in the adjusted range and multiply by 5
  const randomMultipleOfFive =
    Math.floor(Math.random() * (adjustedMax - adjustedMin + 1) + adjustedMin) *
    5;

  return randomMultipleOfFive;
};
