import styled, { css } from "styled-components";

const BaseCSS = css`
  border: 1.5px solid transparent;
  border-radius: 8px;
  padding: 0px 7px 5px 7px;
`;

export const Link = styled.a`
  ${BaseCSS}
  color: ${({ theme }) => theme.link.text};

  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.link.hover.text};
    text-decoration: none;
  }

  &:focus {
    outline: ${({ theme }) => theme.input.focus.outline};
    border: ${({ theme }) => theme.input.focus.border};
  }

  &:visited {
    color: ${({ theme }) => theme.link.visited.text};
  }
`;

export const DisabledLink = styled.a`
  ${BaseCSS}
  color: ${({ theme }) => theme.link.disabled.text};
  cursor: not-allowed;
`;
