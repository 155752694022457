import React, { useState } from "react";
import { AvatarWithInitials } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { createSearchString } from "src/js/modules/searchFunction";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { TODO } from "src/js/types";
import { GroupFilter, SearchContextProps } from "./types";

export const useSpaceContext = ({ searchQuery }: SearchContextProps) => {
  const { SpaceStore } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { activeSpace } = SpaceStore;
  const [groupFilter, setGroupFilter] = useState<GroupFilter>(null);

  const rightFilterBadge = activeSpace ? (
    <AvatarWithInitials
      theme={whiteTheme}
      alt={activeSpace?.space?.name}
      url={activeSpace?.space?.badge?.small as string | undefined}
      name={activeSpace?.space?.name || ""}
      avatarColor={activeSpace?.space?.defaultBadgeColor}
      size="auto"
    />
  ) : null;

  const placeholder = activeSpace
    ? translate({
        text: "top_header_searchbar_desktop_space_placeholder",
        stringVariables: { space: activeSpace.space.name }
      })
    : "";

  const spaceFilterArray = activeSpace ? [activeSpace.space.id] : undefined;
  const groupFilterArray = groupFilter ? [groupFilter.id] : undefined;

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const url = `${createUrl("search")}?${createSearchString({
      keyword: searchQuery,
      spaceFilter: spaceFilterArray,
      groupFilter: groupFilterArray as TODO
    })}`;

    navigateTo(url);
  };

  return {
    onSubmit,
    placeholder,
    rightFilterBadge,
    setGroupFilter,
    groupFilter
  };
};
