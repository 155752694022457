import React from "react";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { DoodleLoader, Button } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const DeaSelectGroup = ({
  groupsList,
  selectGroup,
  filterList,
  filterString,
  isLoading,
  hasNext,
  showNext
}) => {
  const { greyTheme } = useTheme();
  const searchBox = (
    <Translator>
      {({ translate }) => {
        return (
          <div className="form-group">
            <input
              type="text"
              className="deaimport__search-text form-control"
              name="importSearchText"
              placeholder={translate("cerca")}
              onChange={event => filterList(event.target.value)}
              defaultValue={filterString}
            />
          </div>
        );
      }}
    </Translator>
  );

  if (groupsList.length === 0 && !isLoading) {
    return (
      <div className="deaimport__search-group-wrapper">
        {searchBox}
        <div>
          <div className="empty-list-message-box">
            <div className="empty-list-message-wrapper">
              <Translate text="no_groups_available" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const list = groupsList.map(group => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        key={`SelectGroupView__${group.id}`}
        className="list-group-item"
        onClick={() => {
          selectGroup(group);
        }}
      >
        {group.name}
      </a>
    );
  });
  return (
    <div className="deaimport__search-group-wrapper">
      {searchBox}
      <p>
        <Translate text="select_group" />:
      </p>
      <div className="list-group">{list}</div>
      {isLoading ? <DoodleLoader theme={greyTheme} /> : null}
      {hasNext ? (
        <div className="flex-centered-column">
          <Button
            theme={greyTheme}
            variant="secondary"
            onClick={showNext}
            disabled={isLoading}
          >
            <Translate text="mostra altro" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default DeaSelectGroup;
