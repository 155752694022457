import styled, { css } from "styled-components";
import { Link, Text, shadow } from "@arcadia/design-system";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 96px;
  max-height: 96px;
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.colors.coldGrey[900] : theme.colors.grey[1000]};
  border-radius: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  position: relative;
  transition: all 0.5s ease;
  box-shadow: ${shadow[400]};
  z-index: ${({ theme }) => theme.zIndex.forDrawer - 1};
  opacity: ${({ opacity }) => opacity};

  ${({ positionFixed }) =>
    positionFixed &&
    css`
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 342px;
      max-height: min(456px, calc(100vh - 175px));
    `}

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      cursor: pointer;
    `};
`;

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CustomLink = styled(Link)`
  margin: 0px -7px -5px;
`;

export const WidgetActionsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      opacity: 1;
      pointer-events: unset;
    `};
`;

export const WidgetButton = styled.button`
  outline: none;
  border: 0;
  background: none;
  padding: 0;
  height: 16px;
  width: 40px;
  text-align: center;
  cursor: pointer;
`;

export const Divider = styled.div`
  height: 16px;
  width: 1px;
  margin: 0 8px;
  background-color: ${({ theme }) => theme.colors.grey[800]};
`;

export const LinkLayer = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
`;

export const ListArea = styled.div`
  transition: all 0.5s ease;
  overflow: hidden;
  max-height: 0px;
  display: flex;
  flex-direction: column;
  margin: 0 -16px;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-height: 420px;
    `};
`;

export const CustomScrollableDiv = styled(ScrollableDiv)`
  padding: 8px 16px;
  margin-top: 8px;
  margin-right: 0;
  overflow: overlay;

  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
  }

  ::-webkit-scrollbar-track {
    border: 6px solid transparent;
  }

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const DesktopLinkArea = styled.div`
  text-align: center;
  padding: 8px 0;
`;

export const TextCounterContainer = styled(Text)`
  margin-top: 8px;
  line-height: 10px;
  display: block;
`;
