import { IconName, colors } from "@arcadia/design-system";
import { TranslationKeys } from "src/js/translation";
import {
  InstructionTopic,
  InstructionTopicType
} from "./AddAITextEditorResource.types";

export const getIconFromInstructionTopic = (
  topic: InstructionTopicType
): { color: string; icon: IconName } => {
  switch (topic) {
    case InstructionTopic.AIAccuracy:
      return { color: colors.warning[600], icon: "playlistRemove" };
    case InstructionTopic.Language:
      return { color: colors.lagoon[600], icon: "recordVoiceOver" };
    case InstructionTopic.SensitiveData:
    default:
      return { color: colors.success[400], icon: "badge" };
  }
};

export const getInstructionFromTopic = (
  topic: InstructionTopicType
): { title: TranslationKeys; description: TranslationKeys } => {
  switch (topic) {
    case InstructionTopic.AIAccuracy:
      return {
        title: "ai_accuracy_instruction_title",
        description: "ai_accuracy_instruction_description"
      };
    case InstructionTopic.Language:
      return {
        title: "ai_language_instruction_title",
        description: "ai_language_instruction_description"
      };
    case InstructionTopic.SensitiveData:
    default:
      return {
        title: "ai_sensitive_data_instruction_title",
        description: "ai_sensitive_data_instruction_description"
      };
  }
};
