import { ObjectValues } from "./Common";
import { AvatarModel } from "./User";

export const REACTION_TYPES = {
  LAUGH: "laugh",
  SURPRISE: "surprise",
  STAREYES: "stareyes",
  HEART: "heart",
  ANGRY: "angry",
  SWEAT: "sweat",
  THUMBSUP: "thumbsup"
} as const;

export const REACTABLE_ENTITY = {
  POST: "post",
  POST_COMMENT: "post_comment",
  POST_NESTED_COMMENT: "post_nested_comment",
  BOARDELEMENT_COMMENT: "boardelement_comment",
  BOARDELEMENT_NESTED_COMMENT: "boardelement_nested_comment",
  THREAD_MESSAGE: "thread_message"
} as const;

export type ReactionType = ObjectValues<typeof REACTION_TYPES>;
export type ReactableEntity = ObjectValues<typeof REACTABLE_ENTITY>;

export type ReactionCounterType = {
  reactionType: ReactionType;
  count: number;
};

export type Reaction = {
  id: string;
  userUuid: string;
  userId?: string;
  reactableEntity: ReactableEntity;
  reactedId: string;
  reactionType: ReactionType;
  createdAt: string;
};

export type ReactionCounter = {
  total: number;
  reactions: ReactionCounterType[];
  reactionLoggedUser?: Reaction;
} | null;

export type ReactionAuthor = {
  reactionType: ReactionType;
  user: {
    uuid: string;
    name: string;
    surname: string;
    avatar: AvatarModel;
  };
};
