import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  margin-top: 4px;
  justify-content: center;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
  }
`;

export const EditorWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.coldGrey[900]};
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;

  .EditorWrapper {
    height: calc(100vh - 390px);
    width: 100%;
    max-height: 830px;
    min-width: 766px;
    max-width: 766px;
    padding-bottom: 220px;
    overflow: auto;
    ${scrollbarMixin(VariantType.White)}

    @media (max-width: 767px) {
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      min-width: unset;
    }
  }
  .ProseMirror {
    padding: 16px;
  }
`;
