import { createURL } from "../modules/utility";
import { Model } from "backbone";
import isUndefined from "lodash/isUndefined";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import { getActiveGroup } from "../modules/activeGroup";
import {
  __EXERCISE_MAX_RATE__,
  __INSTANT_AUTOMATIC_STOP_TIMEOUT__
} from "../settings/settings";
import moment from "moment";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { shuffleArray } from "src/js/modules/commonFunction";

function updatePosition(array) {
  for (let i = 0; i < array.length; i += 1) {
    array[i].position = i;
  }
  return array;
}

class ExerciseModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    this.urlRoot = createURL("api_exercises");
    this.defaultTimeThreshold = 600;
  }

  initialize() {
    if (this.get("report_stats") === undefined) {
      this.set("report_stats", {});
    }
  }

  isCreated() {
    return this.get("id") !== undefined;
  }

  isStepByStep() {
    return this.get("correction_type") === "STEP";
  }

  isRepeatable() {
    return this.get("repeatable");
  }

  isPublic() {
    return this.get("public_result");
  }

  isPublished() {
    return this.get("published");
  }

  isDone() {
    // return this.get("done") !== undefined ? this.get("done") : false;
    return (
      !isUndefined(this.get("user_last_execution_report")) &&
      !isUndefined(this.get("user_last_execution_report").session_id)
    );
  }

  isDoable() {
    return (
      (this.isPublished() && (this.isRepeatable() || !this.isDone())) ||
      getActiveGroup().isTeacher()
    );
  }

  isExercise() {
    return this.get("type") === "ASSIGNMENT";
  }

  isVerification() {
    return this.get("type") === "EXAMINATION";
  }

  hasTimeThreshold() {
    return this.get("time_threshold") !== undefined;
  }

  getTimeThreshold() {
    const self = this;
    let result = 0;
    if (self.hasTimeThreshold() && self.get("time_threshold") > 0) {
      result = self.get("time_threshold");
    }
    return result;
  }

  getQuizzes() {
    return this.get("quizzes") || [];
  }

  addQuiz(quiz, callbackOk, callbackError) {
    if (this.isCreated()) {
      // console.log('==== EXERCISE MODEL -> ADD QUIZ');
      // console.info(quiz);
      let quizPosition = 0;

      if (!isUndefined(this.getQuizzes()) && !isEmpty(this.getQuizzes())) {
        quizPosition = this.getQuizzes().length;
      }

      quiz.setOrder(quizPosition);
      const data = JSON.stringify(quiz);
      $.ajax({
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        url: createURL("api_exercises_quizzes", {
          exercise_id: this.get("id")
        }),
        data,
        success(res) {
          _trackEvent("Assignments", "AssignmentsNewQuestion", res.type);
          if (typeof callbackOk === "function") callbackOk(res);
        },
        error(res) {
          if (typeof callbackError === "function")
            callbackError(res.responseJSON);
        }
      });
    }
  }

  editQuiz(quiz, callbackOk, callbackError) {
    if (this.isCreated()) {
      quiz.setOrder(quiz.get("position"));
      const data = JSON.stringify(quiz.toJSON());
      $.ajax({
        type: "PUT",
        dataType: "json",
        contentType: "application/json",
        url: createURL("api_exercises_quizzes_entities", {
          exercise_id: this.get("id"),
          quiz_id: quiz.get("id")
        }),
        data,
        success() {
          if (typeof callbackOk === "function") callbackOk(data);
        },
        error() {
          if (typeof callbackError === "function")
            callbackError(data.responseJSON);
        }
      });
    }
  }

  removeQuiz(quiz, callbackOk, callbackError) {
    if (this.isCreated()) {
      $.ajax({
        type: "DELETE",
        dataType: "json",
        contentType: "x-www-form-urlencoded",
        url: createURL("api_exercises_quizzes_entities", {
          exercise_id: this.get("id"),
          quiz_id: quiz.get("id")
        }),
        success(data) {
          if (typeof callbackOk === "function") callbackOk(data);
        },
        error(data) {
          if (typeof callbackError === "function")
            callbackError(data.responseJSON);
        }
      });
    }
  }

  publish(callbackOk, callbackError) {
    const self = this;
    if (this.isCreated()) {
      $.ajax({
        type: "PATCH",
        dataType: "json",
        contentType: "x-www-form-urlencoded",
        url: createURL("api_exercises_publish", {
          exercise_id: this.get("id")
        }),
        success(data) {
          const exerciseType = self.isExercise()
            ? "PublishedExercise"
            : "PublishedTest";
          const numberOfQuestions = self.getQuizzes().length;
          _trackEvent(
            "Assignments",
            "AssignmentsPublished",
            exerciseType,
            numberOfQuestions
          );
          if (typeof callbackOk === "function") callbackOk(data);
        },
        error(data) {
          if (typeof callbackError === "function")
            callbackError(data.responseJSON);
        }
      });
    }
  }

  unpublish(callbackOk, callbackError) {
    if (this.isCreated()) {
      $.ajax({
        type: "PATCH",
        dataType: "json",
        contentType: "x-www-form-urlencoded",
        url: createURL("api_exercises_unpublish", {
          exercise_id: this.get("id")
        }),
        success(data) {
          if (typeof callbackOk === "function") callbackOk(data);
        },
        error(data) {
          if (typeof callbackError === "function")
            callbackError(data.responseJSON);
        }
      });
    }
  }

  doInstant(callbackOk, callbackError) {
    if (this.isCreated()) {
      const data = JSON.stringify({
        duration: __INSTANT_AUTOMATIC_STOP_TIMEOUT__
      });
      const eventType = this.isExercise()
        ? "LaunchInstantExercise"
        : "LaunchInstantTest";
      $.ajax({
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        url: createURL("api_exercises_instant", {
          exercise_id: this.get("id")
        }),
        data,
        success() {
          _trackEvent("Assignments", "AssignmentsLaunchInstant", eventType);
          if (typeof callbackOk === "function") callbackOk(data);
        },
        error() {
          if (typeof callbackError === "function")
            callbackError(data.responseJSON);
        }
      });
    }
  }

  getTotalUsers() {
    return (
      (this.get("report_stats").users_failed_count || 0) +
      (this.get("report_stats").users_passed_count || 0)
    );
  }

  getAverageTimeSeconds() {
    return this.get("report_stats").time_avg;
  }

  getAverageTime() {
    let result = "";
    const time = this.getAverageTimeSeconds();
    let hours = "";
    let minutes = "";
    let seconds = "";

    if (moment.duration(time, "seconds").hours() > 0) {
      hours = `${moment.duration(time, "seconds").hours()}h`;
    }
    if (moment.duration(time, "seconds").minutes() > 0) {
      minutes = `${moment.duration(time, "seconds").minutes()}'`;
    }
    if (moment.duration(time, "seconds").seconds() > 0) {
      seconds = `${moment.duration(time, "seconds").seconds()}"`;
    }

    result = hours + minutes + seconds;

    return result;
  }

  getAverageVote() {
    const avgVote = this.get("report_stats").vote_avg || 0;
    return Math.round(avgVote * 100) / 10;
  }

  getCorrectionSide() {
    return this.get("correction_side") || "CLIENT";
  }

  isCorrectedInFrontend() {
    return this.getCorrectionSide() === "CLIENT";
  }

  isCorrectedInBackend() {
    return this.getCorrectionSide() === "SERVER";
  }

  isExecutedByUser() {
    return (
      this.get("user_last_execution_report") !== undefined &&
      this.get("user_last_execution_report").hasOwnProperty("session_id")
    );
  }

  getUserExecutionTime(type) {
    if (type !== undefined && type === "string") {
      let time = this.get("user_last_execution_report").session_time || 0;
      if (time > this.getTimeThreshold()) {
        time = this.getTimeThreshold();
      }
      time = new Date(time * 1000);
      return `${time.getMinutes()}:${time.getSeconds()}`;
    }
    let time = this.get("user_last_execution_report").session_time;
    if (time > this.getTimeThreshold()) {
      time = this.getTimeThreshold();
    }
    return time;
  }

  getUserExecutionVote() {
    const user_vote =
      Math.floor(this.get("user_last_execution_report").vote * 100) /
      __EXERCISE_MAX_RATE__;
    return user_vote;
  }

  setSession(info) {
    this.set("session", info);
  }

  getSession() {
    return this.get("session") || {};
  }

  setUpdatedQuizzes(quizzes) {
    this.set("quizzes", quizzes);
  }

  setExecutions(execution) {
    this.set("execution", execution);
  }

  getExecution() {
    return this.get("execution");
  }

  updateExecution(newExecution) {
    const self = this;
    const execution = self.getExecution();

    if (execution && newExecution) {
      execution.quiz_executions = newExecution;
      self.setExecutions(execution);
    }
  }

  getExecutionIndex(array, id) {
    let indexExec = -1;
    array.forEach((execution, index) => {
      if (execution.quiz_id == id) {
        indexExec = index;
      }
    });
    return indexExec;
  }

  updateInnerExecution(newExecution, parentId) {
    const self = this;
    const execution = self.getExecution();
    const parentIndex = self.getExecutionIndex(
      execution.quiz_executions,
      parentId
    );
    const currentIndex = self.getExecutionIndex(
      execution.quiz_executions[parentIndex].quiz_executions,
      parentId
    );
    execution.quiz_executions[parentIndex].quiz_executions[currentIndex] =
      newExecution;
    self.setExecutions(execution);
  }

  getQuizExecution() {
    const self = this;
    const execution = self.getExecution();
    return execution?.quiz_executions || [];
  }

  getInnerQuizExecution(quizId, parentId) {
    const self = this;
    const innerQuizExecution = self.getQuizExecution();
    const parentIndex = self.getExecutionIndex(innerQuizExecution, parentId);
    let innerExecution = {};
    innerQuizExecution[parentIndex].quiz_executions.forEach(execution => {
      if (execution.quiz_id == quizId) {
        innerExecution = execution;
      }
    });
    return innerExecution;
  }

  saveExecution(executionQuiz) {
    const self = this;
    const quizzesExecution = self.getQuizExecution();
    const quizzesExecutionClone = quizzesExecution.slice(0);
    quizzesExecution.forEach((quiz, index) => {
      if (quiz.quiz_id == executionQuiz.id) {
        quizzesExecutionClone[index].result = executionQuiz.result;
        quizzesExecutionClone[index].value = executionQuiz.result;
        quizzesExecutionClone[index].answers = executionQuiz.answers;
      }
    });
    self.updateExecution(quizzesExecutionClone);
  }

  saveInnerExecution(executionInnerQuiz) {
    const self = this;
    const innerQuizId = executionInnerQuiz.id;
    const quizzesExecution = self.getInnerQuizExecution(
      innerQuizId,
      executionInnerQuiz.parent_id
    );
    quizzesExecution.result = executionInnerQuiz.result;
    quizzesExecution.value = executionInnerQuiz.result;
    quizzesExecution.answers = executionInnerQuiz.answers;
    self.updateInnerExecution(quizzesExecution, executionInnerQuiz.parent_id);
  }

  setExecutionResults(params) {
    const self = this;
    const execution = self.getExecution();
    const executionClone = clone(execution);

    if (typeof params !== "undefined" && typeof execution !== "undefined") {
      executionClone.quizzes_ok = params.quiz_ok;
      executionClone.quizzes_nok = params.quiz_nok;
      executionClone.result = params.result;
      executionClone.vote = params.vote;
      executionClone.answer = params.answer;
      self.setExecutions(executionClone);
    }
  }

  startSession(exerciseInfo, callback_ok, callback_error) {
    const self = this;
    $.ajax({
      type: "POST",
      dataType: "json",
      contentType: "application/json",
      url: createURL("api_exercises_executions", {
        exercise_id: this.get("id")
      }),
      data: exerciseInfo,
      success(data) {
        self.setExecutions(data);
        if (isFunction(callback_ok)) callback_ok(data);
      },
      error(data) {
        if (isFunction(callback_error)) callback_error(data);
      }
    });
  }

  stopSession(exerciseResult, callback_ok, callback_error, async) {
    const self = this;
    // console.log(exerciseResult);
    self.setExecutionResults(exerciseResult);
    const execution = self.getExecution();
    const executionInfo = JSON.stringify(execution);
    const executionId = execution.id;
    if (typeof executionId !== "undefined") {
      $.ajax({
        type: "PUT",
        dataType: "json",
        contentType: "application/json",
        url: createURL("api_exercises_executions_entities", {
          exercise_id: this.get("id"),
          session_id: executionId
        }),
        data: executionInfo,
        success(data) {
          if (isFunction(callback_ok)) callback_ok(data);
        },
        error(data) {
          if (isFunction(callback_error)) callback_error(data);
        }
      });
    } else if (typeof callback_error === "function") callback_error();
  }

  getConstraints() {
    return this.get("constraints") || [];
  }

  hasConstraint() {
    const self = this;
    const constrainted = self.getConstraints();
    if (constrainted.length > 0) {
      return true;
    }
    return false;
  }

  shuffleQuiz() {
    const self = this;
    let quizzes = self.getQuizzes();
    let suffledArray = shuffleArray(quizzes);
    self.set("quizzes", updatePosition(suffledArray));
  }
}

export default ExerciseModel;
