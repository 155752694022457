import React from "react";
import map from "lodash/map";
import findIndex from "lodash/findIndex";
import { DoodleLoader } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { __ } from "../../modules/localization";
import { createURL } from "../../modules/utility";
import { getActiveGroupId } from "../../modules/activeGroup";
import ModerationElement from "./moderationElement";

class ModerationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      elementList: []
    };
  }

  componentDidMount() {
    const self = this;
    const data = {}; // maybe pgination
    if (self.state.loaded == false) {
      $.ajax({
        type: "GET",
        dataType: "json",
        url: createURL("api_moderations", { group_id: getActiveGroupId() }),
        success(ret) {
          self.setState({
            elementList: ret,
            loaded: true
          });
        },
        error(ret) {
          self.setState({
            elementList: ret,
            loaded: true
          });
        }
      });
    }
  }

  approveElement(id) {
    const self = this;
    const elementList = self.state.elementList.slice();
    const index = findIndex(self.state.elementList, ["id", id]);
    elementList.splice(index, 1);
    self.setState({
      elementList
    });
  }

  rejectElement(id) {
    const self = this;
    const elementList = self.state.elementList.slice();
    const index = findIndex(self.state.elementList, ["id", id]);
    elementList.splice(index, 1);
    self.setState({
      elementList
    });
  }

  render() {
    const self = this;
    const { greyTheme } = this.props.theme;
    let content = <DoodleLoader theme={greyTheme} />;
    if (self.state.loaded) {
      if (self.state.elementList.length == 0) {
        content = (
          <div className="text-center margin-top-30 font-18">
            <p>{__("moderation_no_result")}</p>
          </div>
        );
      } else {
        content = (
          <div className="margin-top-30">
            {map(self.state.elementList, element => (
              <ModerationElement
                key={element.id}
                element={element}
                approveElement={this.approveElement.bind(this)}
                rejectElement={this.rejectElement.bind(this)}
              />
            ))}
          </div>
        );
      }
    }
    return <>{content}</>;
  }
}

export default withTheme(ModerationPage);
