import { useCallback, useState } from "react";

export type MeasureDistance = {
  id: number;
};

export const useMeasureDistance = ({
  targetRef
}: {
  targetRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const [targetElementLeft, setTargetElementLeft] = useState(0);
  const [targetElementRight, setTargetElementRight] = useState(0);
  const [targetElementWidth, setTargetElementWidth] = useState(0);
  const [dynamicElementWidth, setDynamicElementWidth] = useState(0);
  const [targetElementHeight, setTargetElementHeight] = useState(0);
  const [dynamicElementLeft, setDynamicElementLeft] = useState(0);
  const [dynamicElementRight, setDynamicElementRight] = useState(0);

  const dynamicElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (
        node !== null &&
        targetRef !== undefined &&
        targetRef.current !== null
      ) {
        const targetElement = targetRef.current;
        const dynamicElement = node;

        const targetElementRect = targetElement.getBoundingClientRect();
        const dynamicElementRect = dynamicElement.getBoundingClientRect();

        setTargetElementLeft(targetElementRect.left);
        setTargetElementRight(targetElementRect.right);
        setTargetElementWidth(targetElementRect.width);
        setTargetElementHeight(targetElementRect.height);
        setDynamicElementLeft(dynamicElementRect.left);
        setDynamicElementRight(dynamicElementRect.right);
        setDynamicElementWidth(dynamicElementRect.width);
      }
    },
    [targetRef]
  );

  return {
    dynamicElementRef,
    dynamicElementLeft,
    dynamicElementWidth,
    dynamicElementRight,
    targetElementLeft,
    targetElementWidth,
    targetElementHeight,
    targetElementRight
  };
};
