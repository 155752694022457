/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import {
  fetchSpaceMembers,
  fetchSpacePlansOptions,
  fetchSpaceSettings,
  fetchSpacesUsers,
  revokeSpaceUserRole,
  updateSpaceUserRole
} from "src/js/repository/dashboardRepository";
import { __SPACE_USERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";

/**
 * @name SpacesSettingsStore
 *
 * @description
 * Manages settings page of spaces
 */
class SpacesSettingsStore {
  $members = [];
  $usersList = [];
  $usersListParams = {
    name: null,
    offset: 0,
    hasNext: false
  };
  $generalSettings = {};
  $spacePlansOptions = {
    plans: [],
    seatTiers: [],
    mostPopular: null
  };

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
  }

  setSpaceMembers = () => {
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();
    return fetchSpaceMembers({
      spaceId
    })
      .then(data => {
        this.setMembers(data?.users);
      })
      .catch(error => {
        this.setMembers([]);
        throw error;
      });
  };

  searchSpaceUsersByName = name => {
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();
    return fetchSpacesUsers({
      spaceId,
      name,
      offset: 0
    })
      .then(data => {
        this.setUsersList(data?.results);
        this.setUsersListParams({
          name,
          offset: 0,
          hasNext: data?.total > __SPACE_USERS_LIST_LIMIT__
        });
      })
      .catch(error => {
        this.setUsersList([]);
        throw error;
      });
  };

  searchNextSpaceUsers = () => {
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();
    const newOffset =
      this.$usersListParams?.offset + __SPACE_USERS_LIST_LIMIT__;
    return fetchSpacesUsers({
      spaceId,
      name: this.$usersListParams?.name,
      offset: newOffset
    })
      .then(data => {
        this.setUsersList([...this.$usersList, ...data?.results]);
        this.setUsersListParams({
          name: this.$usersListParams?.name,
          offset: newOffset,
          hasNext: data?.total > newOffset + __SPACE_USERS_LIST_LIMIT__
        });
      })
      .catch(error => {
        this.setUsersList([]);
        throw error;
      });
  };

  saveSpaceUserRole = ({ userId, role }) => {
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();
    return updateSpaceUserRole({
      spaceId,
      userId,
      role
    }).then(({ user }) => {
      this.updateMembers(user);
    });
  };

  removeSpaceUserRole = ({ userId }) => {
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();

    return revokeSpaceUserRole({
      spaceId,
      userId
    }).then(() => {
      this.removeMember(userId);
    });
  };

  getSpaceSettings = ({ spaceId, checkSubscription }) => {
    if (!spaceId) return Promise.resolve();

    return fetchSpaceSettings({
      spaceId,
      checkSubscription
    })
      .then(settings => {
        this.setGeneralSettings(settings);
      })
      .catch(error => {
        this.setGeneralSettings({});
        throw error;
      });
  };

  getSpacePlansOptions = () => {
    return fetchSpacePlansOptions()
      .then(data => {
        this.setSpacePlansOptions(data);
      })
      .catch(error => {
        this.setSpacePlansOptions({
          plans: [],
          seatTiers: [],
          mostPopular: null
        });
        throw error;
      });
  };

  setUsersList = value => {
    this.$usersList = value;
  };

  setUsersListParams = value => {
    this.$usersListParams = value;
  };

  setMembers = value => {
    this.$members = value;
  };

  updateMembers = value => {
    let found = false;
    this.$members = this.members.map(member => {
      if (member.id === value.id) {
        found = true;
        return value;
      }
      return member;
    });
    if (!found) {
      this.$members.push(value);
    }
  };

  removeMember = userId => {
    this.$members = this.members.filter(member => member.id !== userId);
  };

  setGeneralSettings = value => {
    this.$generalSettings = value;
  };

  setSpacePlansOptions = value => {
    this.$spacePlansOptions = value;
  };

  reset = () => {
    this.$members = [];
    this.$usersList = [];
    this.$generalSettings = {};
  };

  get usersList() {
    return this.$usersList;
  }

  get usersListHasNext() {
    return this.$usersListParams?.hasNext;
  }

  get members() {
    return this.$members;
  }

  get generalSettings() {
    return this.$generalSettings;
  }

  get spacePlansOptions() {
    return this.$spacePlansOptions;
  }
}

export default SpacesSettingsStore;
