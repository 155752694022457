import React from "react";
import moment from "moment";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import { ExerciseThreadDarkBox } from "src/js/components/exercises/ExerciseThreadDarkBox";
import { Translate } from "src/js/translation/TranslationProvider";
import * as S from "./ExerciseThreadRedelivery.styles";

const ExerciseThreadRedelivery = ({ message = {} }) => {
  const dateCorrection = moment(message.created_at).format("DD/MM/YYYY HH:mm");
  const timeFromNow = moment(message.created_at).fromNow();

  return (
    <ExerciseThreadDarkBox>
      <S.RedeliveryBody>
        <CommentTitle>
          {`${message.name} ${message.surname}`}&nbsp;
          <Translate text={message.thread_message} />
        </CommentTitle>
        <S.RedeliveryFromNow>
          <CommentSubtitle>
            {`${dateCorrection} (${timeFromNow})`}
          </CommentSubtitle>
        </S.RedeliveryFromNow>
      </S.RedeliveryBody>
    </ExerciseThreadDarkBox>
  );
};

export default ExerciseThreadRedelivery;
