import { Box, PickerTest, Text } from "@arcadia/design-system";
import createReactClass from "create-react-class";
import findIndex from "lodash/findIndex";
import React from "react";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import styled, { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import QuizModel from "../../../models/quiz/quizModel";
import { InternalDispatcher } from "../../../modules/dispatcher";
import { __ } from "../../../modules/localization";
import QuizBuilderCrosswords from "./quizBuilderCrosswords";
import QuizBuilderDocumentAnswer from "./quizBuilderDocumentAnswer";
import QuizBuilderFillBlanks from "./quizBuilderFillBlanks";
import QuizBuilderMatching from "./quizBuilderMatching";
import QuizBuilderMultipleChoice from "./quizBuilderMultipleChoice";
import QuizBuilderOpenanswer from "./quizBuilderOpenanswer";
import QuizBuilderShortanswer from "./quizBuilderShortanswer";
import QuizBuilderTrueFalse from "./quizBuilderTrueFalse";
import QuizBuilderVideo from "./quizBuilderVideo";

const StyledInputWrapper = styled.div`
  input[type="text"],
  input[type="number"],
  textarea {
    &:hover {
      border: 1.5px solid ${({ theme }) => theme.primaryColor[800]} !important;
      background-color: ${({ theme }) => theme.primaryColor[800]} !important;
    }

    &:focus {
      outline: 2px solid ${({ theme }) => theme.primaryColor[800]} !important;
      border: 1.5px solid ${({ theme }) => theme.primaryColor[500]} !important;
    }
  }
`;

const pickerElements = [
  {
    logo: "pickertest-truefalse",
    type: "quiztruefalse",
    title: "quiztruefalse"
  },
  {
    logo: "pickertest-shortanswer",
    type: "quizshortanswer",
    title: "quizshortanswer"
  },
  {
    logo: "pickertest-openanswer",
    type: "quizopenanswer",
    title: "quizopenanswer"
  },
  {
    logo: "pickertest-multipleanswers",
    type: "quizmultichoice",
    title: "quizmultichoice"
  },
  {
    logo: "pickertest-crosswordpuzzle",
    type: "quizcrosswords",
    title: "quizcrosswords"
  },
  {
    logo: "pickertest-videoquiz",
    type: "quizvideo",
    title: "quizvideo"
  },
  {
    logo: "pickertest-completesentence",
    type: "quizfillblank",
    title: "quizfillblank"
  },
  {
    logo: "pickertest-matchpairs",
    type: "quizmatching",
    title: "quizmatching"
  },
  {
    logo: "pickertest-matchcard",
    type: "quizfadingmatching",
    title: "quizfadingmatching"
  },
  {
    logo: "pickertest-answerwithattachment",
    type: "quizdocumentanswer",
    title: "quizdocumentanswer"
  }
];

const QuizBuilder = withBackbone(
  createReactClass({
    changed: false,
    getInitialState() {
      const quiz = this.props.quiz || new QuizModel();
      let quizType = "";
      if (typeof this.props.quiz !== undefined) {
        quizType = this.props.quiz.getType();
      }
      return {
        quiz,
        type: quizType
      };
    },
    componentDidMount() {
      InternalDispatcher.on("change:breakpoint", this.updateBP);
    },
    componentWillUnmount() {
      InternalDispatcher.off("change:breakpoint", this.updateBP);
    },
    updateBP() {
      this.forceUpdate();
    },
    setChanged(changed) {
      this.changed = changed;
    },
    addQuiz(quiz, optionalCallbackOk, optionalCallbackError, options) {
      this.props.addFunc(
        quiz,
        optionalCallbackOk,
        optionalCallbackError,
        options
      );
    },
    selectQuizType(type) {
      this.setState({
        type
      });
    },

    render() {
      const self = this;
      let quizType = "";
      let quizCopy = "";
      const { quiz } = self.state;
      const { exercise } = self.props;
      let quizBuilderContent = "";
      let quizBuilderTitle = __("Nuovo quiz");

      switch (this.state.type) {
        case "quiztruefalse":
          quizType = (
            <QuizBuilderTrueFalse
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_truefalse_label");
          break;
        case "quizshortanswer":
          quizType = (
            <QuizBuilderShortanswer
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_shortanswer_label");
          break;
        case "quizopenanswer":
          quizType = (
            <QuizBuilderOpenanswer
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_openanswer_label");
          break;
        case "quizmultichoice":
          quizType = (
            <QuizBuilderMultipleChoice
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_multichoice_label");
          break;
        case "quizcrosswords":
          quizType = (
            <QuizBuilderCrosswords
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_crosswords_label");
          break;
        case "quizvideo":
          quizType = (
            <QuizBuilderVideo
              addFunc={this.addQuiz}
              editFunc={this.props.editFunc}
              backFunc={this.props.backFunc}
              quiz={quiz}
              exercise={exercise}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_video_label");
          break;
        case "quizfillblank":
          quizType = (
            <QuizBuilderFillBlanks
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              exercise={exercise}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_fillblank_label");
          break;
        case "quizmatching":
          quizType = (
            <QuizBuilderMatching
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_matching_label");
          break;
        case "quizfadingmatching":
          quizType = (
            <QuizBuilderMatching
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              fading
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_fadingmatching_label");
          break;
        case "quizdocumentanswer":
          quizType = (
            <QuizBuilderDocumentAnswer
              addFunc={this.addQuiz}
              backFunc={this.props.backFunc}
              quiz={quiz}
              setChanged={self.setChanged}
            />
          );
          quizCopy = __("quiz_openanswer_label");
          break;
      }

      if (exercise.has("title") && exercise.get("title") != "") {
        let quizCountCaption = "";
        if (exercise.has("quizzes") && exercise.get("quizzes").length > 1) {
          const quizIndex =
            findIndex(exercise.get("quizzes"), { id: quiz.get("id") }) + 1;
          if (quizIndex > 0) {
            quizCountCaption =
              __(` - Quiz ${quizIndex} di `) + exercise.get("quizzes").length;
          }
        }

        quizBuilderTitle = exercise.get("title") + quizCountCaption;
      }

      let quizTypesList = "";
      if (getBreakpoint() === "smartphone") {
        quizTypesList = (
          <select
            className="form-control light"
            onChange={e => this.setState({ type: e.target.value })}
          >
            <option value="">-</option>
            <option value="quiztruefalse">{__("quiztruefalse")}</option>
            <option value="quizshortanswer">{__("quizshortanswer")}</option>
            <option value="quizopenanswer">{__("quizopenanswer")}</option>
            <option value="quizmultichoice">{__("quizmultichoice")}</option>
            <option value="quizcrosswords">{__("quizcrosswords")}</option>
            <option value="quizvideo">{__("quizvideo")}</option>
            <option value="quizfillblank">{__("quizfillblank")}</option>
            <option value="quizmatching">{__("quizmatching")}</option>
            <option value="quizfadingmatching">
              {__("quizfadingmatching")}
            </option>
            <option value="quizdocumentanswer">
              {__("quizdocumentanswer")}
            </option>
          </select>
        );
      } else {
        quizTypesList = (
          <PickerTest
            theme={this.props.theme.greyTheme}
            pickerElement={pickerElements.map(({ logo, type, title }) => ({
              logo,
              type,
              title: __(title)
            }))}
            onClick={type => {
              self.selectQuizType(type);
            }}
          />
        );
      }

      quizBuilderContent = (
        <div>
          <div>
            {quiz.isNew() && (
              <form id="form-quiz-builder-main" role="form" method="post">
                <div className="margin-bottom-20">{quizTypesList}</div>
              </form>
            )}
            <div>
              {quizCopy.length > 0 && (
                <Box marginTop={quiz.isNew() ? 32 : undefined} marginBottom={8}>
                  <Text type="formDescription">{quizCopy}</Text>
                </Box>
              )}
            </div>
          </div>
          {this.state.type !== "" && (
            <div className="app__box" key={`quiz-${this.state.type}`}>
              <StyledInputWrapper>{quizType}</StyledInputWrapper>
            </div>
          )}
        </div>
      );

      return (
        <div className="quiz-builder-container">
          <div className="quiz-builder-wrapper">
            <div className="quiz-builder-header">
              <div className="clearfix" />
            </div>
            <div className="quiz-builder-content">
              {quizBuilderContent}
              <div className="clearfix" />
            </div>
          </div>
        </div>
      );
    }
  })
);

export default withTheme(QuizBuilder);
