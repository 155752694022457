import { DoodleLoader } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { LocalStorageKey } from "src/js/modules/storageFunction";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { HomeEmptyState } from "./HomeEmptyState";
import { InformingModal } from "./InformingModal";

const RedirectToSpaceHome = () => {
  const {
    SpaceStore: { getLastActiveSpace, spacesList, userSpacesListLoading },
    ModalStore: { openModal },
    AuthStore: { resetUserLoggedInSuccessfully, userLoggedInSuccessfully },
    UIStore: { openSideBar, isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();

  const lastActiveSpace = getLastActiveSpace();

  useEffect(() => {
    openSideBar();
  }, []);

  useEffect(() => {
    const modalDataJSON = localStorage.getItem(
      LocalStorageKey.NewNavigationInfoModal
    );
    let shouldShowModal = true;

    if (modalDataJSON) {
      shouldShowModal = false;
    }

    if (userLoggedInSuccessfully && shouldShowModal && !isLayoutModeMobile) {
      openModal(() => <InformingModal />);
      resetUserLoggedInSuccessfully();
    }
  }, [userLoggedInSuccessfully, userSpacesListLoading]);

  if (lastActiveSpace && lastActiveSpace.space) {
    const spaceSlug = lastActiveSpace.space.slug;
    navigateTo(createUrl("space_groups", { space_slug: spaceSlug }));
    return null;
  }
  if (spacesList.length > 0) {
    navigateTo(
      createUrl("space_groups", { space_slug: spacesList[0].space.slug })
    );
    return null;
  }

  if (userSpacesListLoading) {
    return <DoodleLoader theme={whiteTheme} />;
  }

  // No last spaces nor spaces, show empty home
  return <HomeEmptyState />;
};

export default observer(RedirectToSpaceHome);
