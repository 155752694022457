import { ChangeEvent, useCallback, useState } from "react";
import { Area, Point } from "react-easy-crop";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__ } from "src/js/settings/settingFileUploader";
import { getCroppedImg } from "./CropImageModal.utils";

const useCropImageModal = ({
  saveNewCroppedImage,
  minimumImageWidth,
  minimumImageHeight
}: {
  saveNewCroppedImage: (
    croppedImage: File,
    setButtonDisabled: (isDisalbed: boolean) => void
  ) => void;
  minimumImageWidth?: number;
  minimumImageHeight?: number;
}) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);
  const [selectedPreview, setPreview] = useState<string>(null);
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const { translate } = useTranslation();

  const onCropComplete = useCallback((_, areaPixels: Area) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (
      resourcePreview: string,
      imageMimeType: string,
      imageTitle: string
    ) => {
      try {
        const newCroppedImage = await getCroppedImg(
          selectedPreview || resourcePreview,
          croppedAreaPixels,
          imageMimeType,
          imageTitle,
          minimumImageWidth,
          minimumImageHeight
        );
        if (newCroppedImage) {
          saveNewCroppedImage(newCroppedImage, setDisabled);
        } else {
          showToastError({
            str: translate({
              text: "thumbnail_minimum_size_toast_alert",
              stringVariables: {
                width: minimumImageWidth,
                height: minimumImageHeight
              }
            })
          });
          setDisabled(false);
        }
      } catch (e) {
        const Err = new Error(e);
        throw Err;
      }
    },
    [croppedAreaPixels]
  );

  const checkFileSize = (file: File) => {
    if (file.size > __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__) {
      return false;
    }
    return true;
  };

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    const fileSizeAccepted = checkFileSize(e.target.files[0]);
    if (!fileSizeAccepted) {
      showToastError({
        str: translate("file_drag_drop_size_error", {
          fileName: e.target.files[0].name
        })
      });
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  return {
    onSelectFile,
    showCroppedImage,
    onCropComplete,
    selectedFile,
    setPreview,
    selectedPreview,
    crop,
    zoom,
    disabled,
    setCrop,
    setZoom,
    croppedAreaPixels
  };
};

export default useCropImageModal;
