import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import {
  Box,
  Button,
  Divider,
  Dropdown,
  Heading,
  Icon,
  Input,
  Text
} from "@arcadia/design-system";
import { __BUSINESS_SUBJECTS__ } from "src/js/settings/settingsSelect";
import { AvatarModel, GroupDetailsModel } from "src/js/types";
import { editGroupInfo } from "src/js/repository/groupRepository";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { navigateTo } from "src/legacy/modules/history";
import { PreventNavigation } from "src/js/components/global/PreventNavigation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { __FORM_GROUP_INFO__ } from "src/js/settings/settingsFormValidation";
import { useForm } from "react-hook-form";
import { useGroupActions } from "./hooks";

import * as S from "./GroupSettingsInformation.styles";

const GroupSettingsInformation = ({
  group,
  groupCoverFile,
  groupCoverUrl,
  roles,
  isEditMode,
  setEditMode
}: {
  group: GroupDetailsModel;
  groupCoverUrl?: string;
  groupCoverFile?: File;
  roles: string[];
  isEditMode: boolean;
  setEditMode: (value: boolean) => void;
}) => {
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    GroupStore: { setActiveGroupFull, activeGroupFull },
    SpaceGroupListStore: { updateGroupInfo }
  } = useStores();

  const [groupDescription, setGroupDescription] = useState(group.description);
  const [subject, setSubject] = useState(group.subject);

  const { showDeleteGroupModal, showLeaveGroupConfirmation } =
    useGroupActions();

  const { register, handleSubmit, errors, watch, setValue } = useForm<{
    name: string;
  }>({
    defaultValues: { name: group.name }
  });

  useEffect(() => {
    setValue([{ name: group.name }]);
  }, [group.name, setValue, isEditMode]);

  const groupName = watch("name");

  const hasChanges =
    groupDescription !== group?.description ||
    groupCoverFile !== null ||
    subject !== group?.subject ||
    groupName !== group?.name;

  const isCreator = roles?.includes("ROLE_CREATOR");
  const isStudent = roles?.includes("ROLE_STUDENT");

  const hasEditPermission = isCreator || roles?.includes("ROLE_TEACHER");

  useEffect(() => {
    return () => {
      setEditMode(false);
    };
  }, []);

  useEffect(() => {
    setGroupDescription(group.description);
    setSubject(group.subject);
  }, [isEditMode]);

  const submitInformation = (formData: { name: string }) => {
    if (!hasChanges && !isEditMode) return;

    const groupInfoData: {
      name?: string;
      subject?: string;
      description?: string;
      cover?: AvatarModel;
    } = {
      name: formData.name,
      subject,
      description: groupDescription,
      cover: {
        small: groupCoverUrl,
        medium: groupCoverUrl,
        large: groupCoverUrl,
        originalUrl: groupCoverUrl
      }
    };

    editGroupInfo({
      id: group.id,
      name: formData.name,
      subject,
      description: groupDescription,
      fileCover: groupCoverFile
    })
      .then(() => {
        setActiveGroupFull({
          ...activeGroupFull,
          group: {
            ...activeGroupFull.group,
            ...groupInfoData
          }
        });
        updateGroupInfo({
          groupId: group.id,
          groupInfo: {
            ...groupInfoData
          }
        });
        setEditMode(false);
        showToastSuccess({ str: translate("group_edit_success") });
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  return (
    <form onSubmit={handleSubmit(submitInformation)}>
      <PreventNavigation
        shouldBlock={hasChanges && isEditMode}
        promptText="group_settings_information_unsaved_changes_warning_text"
        acceptText="group_settings_information_unsaved_changes_accept_cta"
        declineText="group_settings_information_unsaved_changes_decline_cta"
        onNavigateCb={path => {
          setEditMode(false);
          navigateTo(path);
        }}
      />

      <Box display="block">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Heading level="5">
            {translate("group_settings_page_tab_info")}
          </Heading>
          {hasEditPermission &&
            (!isEditMode ? (
              <div onClick={() => setEditMode(true)}>
                <S.SaveLinkButton type="button">
                  <Icon icon="pencil" width={12} height={12} />
                  <Text type="formTitle">
                    {translate({
                      text: "space_settings_general_action_edit"
                    })}
                  </Text>
                </S.SaveLinkButton>
              </div>
            ) : (
              <S.SaveLinkButton type="submit">
                <Text type="formTitle">
                  {translate({
                    text: "space_settings_general_action_save"
                  })}
                </Text>
              </S.SaveLinkButton>
            ))}
        </Box>
        {isEditMode ? (
          <>
            <Box marginTop="16px">
              <Input
                theme={greyTheme}
                name="name"
                label={translate("group_name")}
                rounded
                ref={register(__FORM_GROUP_INFO__.name)}
                feedback={
                  errors.name
                    ? {
                        message: translate(
                          errors.name.message as TranslationKeys
                        ),
                        type: "error"
                      }
                    : null
                }
              />
            </Box>

            <Box marginTop={16}>
              <Text type="formSubtitle">{translate("subject_label")}</Text>
              <Box marginTop={13}>
                <Dropdown
                  theme={greyTheme}
                  optionsList={__BUSINESS_SUBJECTS__.map(option => ({
                    id: option.id,
                    label: translate(option.name as TranslationKeys)
                  }))}
                  selectedOptionId={subject}
                  setSelectedOptionId={id => {
                    setSubject(id);
                  }}
                  placeholder={translate("error_choose_group_subject")}
                />
              </Box>
            </Box>

            <Box marginTop={16}>
              <Text type="formSubtitle">{translate("group_description")}</Text>
              <Box marginTop={13}>
                <Input
                  hideLabel
                  label={translate("group_description_disclaimer")}
                  value={groupDescription}
                  theme={greyTheme}
                  onChange={e => {
                    setGroupDescription(e.target.value);
                  }}
                  rows={3}
                  type="textarea"
                  maxLength={1000}
                  showCounter
                />
              </Box>
            </Box>
          </>
        ) : (
          <S.InformationBox>
            <Box display="flex" flexDirection="column">
              <Box marginBottom={8}>
                <Text type="formField">{group.name}</Text>
              </Box>
              <Text type="formFieldEmpty">{group.description}</Text>
            </Box>
          </S.InformationBox>
        )}
        <Box marginTop={40} marginBottom={24}>
          <Divider theme={whiteTheme} color="grey" />
        </Box>
        {isCreator ? (
          <Button
            theme={whiteTheme}
            variant="danger"
            onClick={() => showDeleteGroupModal(group.id)}
          >
            {translate("group_settings_page_tab_info_delete")}
          </Button>
        ) : undefined}
        {isStudent ? (
          <Button
            theme={whiteTheme}
            variant="danger"
            onClick={() => showLeaveGroupConfirmation(group.id)}
          >
            {translate("group_settings_page_tab_info_leave")}
          </Button>
        ) : undefined}
      </Box>
    </form>
  );
};

export default observer(GroupSettingsInformation);
