import { createLocalData, readLocalData } from "src/js/modules/storageFunction";
import { __LOCAL_SEARCH_SUGGESTION__ } from "src/js/settings/settingsLocalStorage";
import { __SEARCH_AUTOCOMPLETE_LIMIT__ } from "src/js/settings/settingsPagination";
import { SearchUrlParameters } from "../types";

export function createSearchString({
  keyword,
  ...filters
}: SearchUrlParameters) {
  const queryString = [];
  if (keyword) {
    queryString.push(`q=${keyword}`);
  }
  if (filters.wall) {
    queryString.push("w=true");
  }
  if (filters.module) {
    queryString.push("m=true");
  }
  if (filters.exercise) {
    queryString.push("e=true");
  }
  if (filters.userFilter?.length > 0) {
    queryString.push(`u=${filters.userFilter.join(",")}`);
  }
  if (filters.groupFilter?.length > 0) {
    queryString.push(`g=${filters.groupFilter.join(",")}`);
  }
  if (filters.spaceFilter?.length > 0) {
    queryString.push(`s=${filters.spaceFilter.join(",")}`);
  }
  return encodeURI(queryString.join("&"));
}

export function getFiltersCount(filters) {
  let count = 0;
  if (filters.contextsFilter.wall) {
    count += 1;
  }
  if (filters.contextsFilter.module) {
    count += 1;
  }
  if (filters.contextsFilter.exercise) {
    count += 1;
  }
  if (filters.userFilter) {
    count += 1;
  }
  if (filters.groupFilter) {
    count += 1;
  }
  return count;
}

export function updateLocalSearchSuggestion(keyword) {
  let localData = JSON.parse(readLocalData(__LOCAL_SEARCH_SUGGESTION__));
  if (localData != null) {
    if (localData.indexOf(keyword) < 0) {
      localData.unshift(keyword);
      if (localData.length > __SEARCH_AUTOCOMPLETE_LIMIT__) {
        localData.splice(-1, 1);
      }
      createLocalData(__LOCAL_SEARCH_SUGGESTION__, JSON.stringify(localData));
    }
  } else {
    localData = [keyword];
    createLocalData(__LOCAL_SEARCH_SUGGESTION__, JSON.stringify(localData));
  }
}

export const changeSearchParams = params => {
  const url = new URL(window.location as never);
  let newHash = url.hash;

  if (url.hash.indexOf("?") > 0) {
    // Remove the old query string
    newHash = newHash.substring(newHash.indexOf("#"), newHash.indexOf("?"));
  }

  if (params) {
    // Add the new query string
    url.hash = `${newHash}?${params}`;
  } else {
    url.hash = newHash;
  }

  // Update the current URL wihtout causing a page reload, component re-render or
  // history entry
  window.history.replaceState({}, "", url);
};
