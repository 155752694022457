import React, { useEffect, useState } from "react";
import { BottomModalSheet, Box, Icon } from "@arcadia/design-system";
import { useStores, useDebounce, useInfiniteScrollFetcher } from "src/js/hooks";
import { fetchBadgeRelatedGroups } from "src/js/repository/badgesRepository";
import { observer } from "mobx-react";
import { BadgeRelatedGroupType } from "src/js/types";
import { useTranslation } from "src/js/translation";
import * as S from "./styles";
import {
  AssociateGroupStep,
  AssociateGroupStepType,
  BadgeRelatedGroupsFetchParams
} from "./types";
import SelectGroups from "./components/SelectGroups";
import ReviewSelectedGroups from "./components/ReviewSelectedGroups";

const AssociateBadgeToGroups = () => {
  const [filterString, setFilterString] = useState("");
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [step, setStep] = useState<AssociateGroupStepType>(
    AssociateGroupStep.SELECT_GROUPS
  );
  const { translate } = useTranslation();
  const filterValueDebounced = useDebounce(filterString, 300);
  const {
    UIStore: { isSideBarOpen, toggleSidebar, isLayoutModeMobile },
    SpaceBadgesStore: {
      reset,
      setEmptyStateVisible,
      setGroupsDrawerOpen,
      badgeToEdit,
      isGroupsDrawerOpen
    },
    SpaceBadgesListStore: { fetchFilteredBadges },
    SpaceStore: { activeSpaceId }
  } = useStores();
  const {
    data: badgeRelatedGroups = [],
    fetch,
    reset: resetResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    BadgeRelatedGroupType[],
    BadgeRelatedGroupsFetchParams
  >(
    async ({ limit, page: _page, showUnassociated, badgeId, textSearch }) => {
      const offset = _page * limit;
      setEmptyStateVisible(false);
      const data = await fetchBadgeRelatedGroups({
        badgeId,
        textSearch,
        showUnassociated,
        limit,
        offset
      });

      if (!data.results.length) {
        setEmptyStateVisible(true);
      }

      setHasMoreResults(data.hasNext);

      return data.results;
    },
    {
      lazy: true,
      limit: 10
    }
  );
  const { id, image, name } = badgeToEdit || {};

  useEffect(() => {
    if (id && isGroupsDrawerOpen) {
      fetch(0, {
        badgeId: id,
        textSearch: filterValueDebounced,
        showUnassociated: true
      });
    }
    return () => resetResults();
  }, [id, isGroupsDrawerOpen, filterValueDebounced]);

  useEffect(() => {
    if (isSideBarOpen && isGroupsDrawerOpen) {
      toggleSidebar();
    }
  }, [isGroupsDrawerOpen]);

  const handleClose = (shouldRefetchBadges?: boolean) => {
    setGroupsDrawerOpen(!isGroupsDrawerOpen);
    setStep(AssociateGroupStep.SELECT_GROUPS);
    setFilterString("");
    reset();
    if (shouldRefetchBadges) {
      fetchFilteredBadges(activeSpaceId);
    }
  };

  const handleStepToRender = () => {
    if (step === AssociateGroupStep.SELECT_GROUPS) {
      return (
        <SelectGroups
          fetchNextPage={fetchNextPage}
          filterString={filterString}
          filterValueDebounced={filterValueDebounced}
          hasMoreResults={hasMoreResults}
          setFilterString={setFilterString}
          setStep={setStep}
          badgeRelatedGroups={badgeRelatedGroups}
        />
      );
    }
    return <ReviewSelectedGroups handleClose={handleClose} setStep={setStep} />;
  };

  if (!badgeToEdit) {
    return null;
  }

  return isLayoutModeMobile ? (
    <BottomModalSheet
      isOpen={isGroupsDrawerOpen}
      title={translate("dashboard_badge_dropdown_associate_group")}
      closeLabel={
        step === AssociateGroupStep.SELECT_GROUPS
          ? translate("Annulla")
          : translate("close")
      }
      onClose={handleClose}
    >
      <Box id="drawer-body-wrapper" height="100%" paddingTop={24}>
        {handleStepToRender()}
      </Box>
    </BottomModalSheet>
  ) : (
    <S.StyledDrawer
      onDismiss={() => setGroupsDrawerOpen(!isGroupsDrawerOpen)}
      open={isGroupsDrawerOpen}
      disableOverlay
      size="540px"
      isInsideMainLayout
      showCloseButton={false}
    >
      <Box id="drawer-body-wrapper" height="100%">
        <S.DrawerHeaderContainer>
          <Box display="flex" alignItems="center" minWidth={0}>
            <S.BadgeImage src={image} alt="badge-alternative" />
            <S.DrawerTitle level="5">
              {translate("space_badge_associate_to_group_sidebar_header", {
                badgeName: name
              })}
            </S.DrawerTitle>
          </Box>
          <S.HeaderIconContainer onClick={() => handleClose(true)}>
            <Icon icon="close" />
          </S.HeaderIconContainer>
        </S.DrawerHeaderContainer>
        {handleStepToRender()}
      </Box>
    </S.StyledDrawer>
  );
};

export default observer(AssociateBadgeToGroups);
