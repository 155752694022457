import gdocPlaceholder from "Images/placeholders/gdoc.png";
import gsheetPlaceholder from "Images/placeholders/gsheet.png";
import gslidePlaceholder from "Images/placeholders/gslide.png";
import gdrivePlaceholder from "Images/placeholders/drive.png";
import googleMapsPlaceholder from "Images/placeholders/googlemaps.png";
import pdfPlaceholder from "Images/placeholders/pdf.png";
import videoPlaceholder from "Images/placeholders/video.png";
import powerpointPlaceholder from "Images/placeholders/powerpoint.png";
import wordPlaceholder from "Images/placeholders/word.png";
import excelPlaceholder from "Images/placeholders/exc.png";
import boardPlaceholder from "Images/placeholders/board.png";
import webPlaceholder from "Images/placeholders/web.png";
import vimeoPlaceholder from "Images/placeholders/vimeo.png";
import youtubePlaceholder from "Images/placeholders/yt_logo_mono_dark.png";
import filePlaceholder from "Images/placeholders/file.png";
import dropboxPlaceholder from "Images/placeholders/dropbox.png";
import oneDrivePlaceholder from "Images/placeholders/onedrive.png";
import weschoolExercisePlaceholder from "Images/placeholders/test.png";
import weschoolTextPlaceholder from "Images/placeholders/testo.png";
import weschoolDrawPlaceholder from "Images/placeholders/draw.png";
import instagramPlaceholder from "Images/placeholders/insta.png";
import imagePlaceholder from "Images/placeholders/img.png";

const getFirstPath = s => {
  const link = String(s);
  const regExp = /^http[s]?:\/\/.*?\/([a-zA-Z-_]+).*$/;
  const match = link.match(regExp);
  if (match != null) {
    return match[1];
  }
  return "";
};

// I don't understand exactly the purpose of this function
const getPlaceholderByUrl = resourceName => {
  const link = getFirstPath(resourceName);
  switch (link) {
    case "document":
      return gdocPlaceholder;
    case "spreadsheets":
      return gsheetPlaceholder;
    case "presentation":
      return gslidePlaceholder;
    default:
      return null;
  }
};

const getPlaceholderByMimeType = resource => {
  switch (resource.mime_type) {
    case "application/pdf":
      return pdfPlaceholder;
    case "application/vnd.google-apps.document":
      return gdocPlaceholder;
    case "application/vnd.google-apps.spreadsheet":
      return gsheetPlaceholder;
    case "application/vnd.google-apps.presentation":
      return gslidePlaceholder;
    case "application/vnd.google-apps.drawing":
      return gdrivePlaceholder;
    case "application/mspowerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return powerpointPlaceholder;
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return wordPlaceholder;
    case "application/vnd.ms-excel":
    case "application/msexcel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return excelPlaceholder;
    case "video/mp4":
      return videoPlaceholder;
    default:
      return null;
  }
};

const getPlaceholder = resource => {
  switch (resource.type) {
    case "BOARD":
      return boardPlaceholder;
    case "LINK":
      return getPlaceholderByUrl(resource.name) || webPlaceholder;
    case "VIMEO":
      return vimeoPlaceholder;
    case "YOUTUBE":
      return youtubePlaceholder;
    case "FILE":
      return getPlaceholderByMimeType(resource) || filePlaceholder;
    case "DROPBOX":
      return getPlaceholderByMimeType(resource) || dropboxPlaceholder;
    case "GOOGLE_DRIVE":
    case "GOOGLEDRIVE":
      return getPlaceholderByMimeType(resource) || gdrivePlaceholder;
    case "ONE_DRIVE":
    case "ONEDRIVE":
      return getPlaceholderByMimeType(resource) || oneDrivePlaceholder;
    case "GOOGLE_MAPS":
    case "GOOGLEMAPS":
      return googleMapsPlaceholder;
    case "EXERCISE":
      return weschoolExercisePlaceholder;
    case "TEXT":
      return weschoolTextPlaceholder;
    case "DRAWING":
      return weschoolDrawPlaceholder;
    case "INSTAGRAM":
      return instagramPlaceholder;
    case "IMAGE_GALLERY":
    case "IMAGEGALLERY":
    case "IMAGE":
      return imagePlaceholder;
    case "SLIDESHOW":
      return powerpointPlaceholder;
    case "DOCUMENT":
      return wordPlaceholder;
    case "SPREADSHEET":
      return excelPlaceholder;
    case "PDF":
      return pdfPlaceholder;
    default:
      return filePlaceholder;
  }
};

export { getPlaceholder as default };
