import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { fetchSharedGroupsList } from "src/js/repository/publicProfileRepository";
import { Box, DoodleLoader } from "@arcadia/design-system";
import { __GROUPS_SHARED_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { translateString } from "src/js/translation";
import { useTheme } from "styled-components";
import GroupsInCommonElement from "src/js/pages/user/ProfilePage/components/GroupsInCommon/GroupsInCommonElement";
import {
  GroupsInCommonHandle,
  GroupsInCommonDTO,
  GroupsInCommonProps
} from "./GroupsInCommon.types";
import * as S from "./GroupsInCommon.styles";

const GroupsInCommon = forwardRef<GroupsInCommonHandle, GroupsInCommonProps>(
  ({ userUuid }, ref) => {
    const { whiteTheme } = useTheme();
    const [hasNext, setHasNext] = useState(true);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState<GroupsInCommonDTO["results"]>([]);

    const fetchGroups = useCallback(() => {
      if (hasNext) {
        setLoading(true);
        fetchSharedGroupsList(
          userUuid,
          start,
          __GROUPS_SHARED_LIST_LIMIT__,
          Date.now()
        )
          .then((gs: GroupsInCommonDTO) => {
            setGroups([...(groups || []), ...(gs.results || [])]);
            setHasNext(gs.has_next);
            if (gs.has_next) {
              setStart(start + __GROUPS_SHARED_LIST_LIMIT__);
            }
          })
          .finally(() => setLoading(false));
      }
    }, [start, hasNext]);

    useImperativeHandle(ref, () => ({
      fetchMore() {
        fetchGroups();
      }
    }));

    useEffect(() => {
      fetchGroups();
    }, []);

    return groups.length !== 0 ? (
      <S.GroupWrapper>
        <S.GroupTitle>
          {translateString({ text: "common_groups" })}
        </S.GroupTitle>
        <S.GroupCardWrapper>
          {groups.length > 0
            ? groups.map(g => (
                <GroupsInCommonElement key={g.group.id} item={g} />
              ))
            : undefined}
        </S.GroupCardWrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          {loading ? <DoodleLoader theme={whiteTheme} isMini /> : undefined}
        </Box>
      </S.GroupWrapper>
    ) : undefined;
  }
);

GroupsInCommon.displayName = "GroupsInCommon";

export default GroupsInCommon;
