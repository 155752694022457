import { AvatarWithInitialsProps } from "@ds_universal/AvatarWithInitials/types";

export const SpaceBaseCardSizeType = {
  Big: "big",
  Medium: "medium",
  Small: "small",
  Auto: "auto"
};
export type SpaceBaseCardSizeType =
  (typeof SpaceBaseCardSizeType)[keyof typeof SpaceBaseCardSizeType];

export type SpaceBaseCardStyleProps = {
  sizeVariant: SpaceBaseCardSizeType;
  hasBorder?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  isSelected?: boolean;
  isTransparent?: boolean;
};

export type SpaceBaseCardProps = SpaceBaseCardStyleProps & {
  spaceAvatar: Partial<AvatarWithInitialsProps>;
  centralContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  className?: string | undefined;
};
