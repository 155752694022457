import styled from "styled-components";

/**
 * Empty State Style
 */

export const IllustrationContainer = styled.div`
  margin: 20px 0;
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 160px);
  padding-bottom: 40px;
  white-space: pre-wrap;
  text-align: center;
`;

/**
 * Custom Card Style
 */

export const CustomCardWrapper = styled.li<{ isActive: boolean }>`
  all: unset;
  cursor: pointer;
  height: 54px;
  display: flex;
  padding: 12px 14px;
  margin-bottom: 12px;
  align-items: center;
  border-radius: 8px;
  gap: 16px;
  color: ${({ theme }) => theme.colors.grey[50]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.primaryColor[500] : theme.colors.grey[900]};
`;

export const IconContainer = styled.div<{ isActive: boolean }>`
  height: 52px;
  width: 52px;
  display: flex;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.grey[1000]};
  align-items: center;
  justify-content: center;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.primaryColor[500] : theme.colors.grey[800]};
`;

export const ListContainer = styled.div`
  & > div#selectable-list-footer {
    position: relative;
    padding-bottom: 0px;

    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      padding: 0px;
      & > div {
        padding: 0px;
      }
    }
  }
`;
