import { Avatar } from "@arcadia/design-system";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { Animations } from "src/js/settings/animations";
import { useTheme } from "styled-components";

import * as S from "./HeaderProfileTab.styles";

const HeaderProfileTab = () => {
  const {
    UserStore: { activeUserFullname, activeUser }
  } = useStores();
  const { whiteTheme } = useTheme();

  // const onNavigateProfile = useCallback(() => {
  //   navigateTo(createUrl("profile_public", { user_uuid: activeUser?.uuid }));
  // }, []);

  return (
    <AnimatePresence>
      {/* TODO: onClick doesn't exist on S.HeaderProfileTabWrapper */}
      {/* <S.HeaderProfileTabWrapper onClick={onNavigateProfile}> */}
      <S.HeaderProfileTabWrapper>
        <motion.div {...Animations.Fade} transition={{ delay: 0.2 }}>
          <Avatar
            theme={whiteTheme}
            url={activeUser.avatar?.small}
            alt={activeUser.name}
            size="m"
          />
        </motion.div>
        <motion.div {...Animations.Fade} transition={{ delay: 0.4 }}>
          <S.Name level="4">{activeUserFullname}</S.Name>
        </motion.div>
      </S.HeaderProfileTabWrapper>
    </AnimatePresence>
  );
};

export default observer(HeaderProfileTab);
