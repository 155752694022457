import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState
} from "react";
import { SpaceModel } from "src/js/types";

const SchoolSpaceCreationContext = createContext<{
  setStatus: (status: string) => void;
  status: string;
  setSpaceSelected: (space: Partial<SpaceModel>) => void;
  spaceSelected: Partial<SpaceModel>;
}>({
  setStatus: () => {},
  status: "",
  setSpaceSelected: () => {},
  spaceSelected: {}
});

export const useSchoolSpaceCreation = () =>
  useContext(SchoolSpaceCreationContext);

export const SchoolSpaceCreationProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState<string>("");
  const [spSelected, setSpSelected] = useState<Partial<SpaceModel>>({});

  const setSpaceSelected = useCallback(
    (sp: Partial<SpaceModel>) => {
      setSpSelected(sp);
    },
    [spSelected]
  );

  const setStatus = useCallback(
    (st: string) => {
      setState(st);
    },
    [state]
  );

  const contextValue = {
    setSpaceSelected,
    spaceSelected: spSelected,
    setStatus,
    status: state
  };

  return (
    <SchoolSpaceCreationContext.Provider value={contextValue}>
      {children}
    </SchoolSpaceCreationContext.Provider>
  );
};
