import { shadow, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";
import { SSpaceDurationCardContainer } from "./SpaceDurationCard.types";

const colorsMixin = ({ theme, variant }) => {
  if (variant === "primary") {
    return css`
      --space-duration-card-bg-color: ${`linear-gradient(
      259.75deg,
      ${theme.primaryColor[500]} 27.31%,
      ${theme.primaryColor[600]} 83.06%,
      ${theme.primaryColor[700]} 115.59%
    )`};
      --space-duration-card-text-color: ${theme.colors.grey[1000]};
      --space-duration-card-color: ${theme.colors.grey[1000]};
    `;
  }
  // secondary
  return css`
    --space-duration-card-bg-color: ${theme.colors.grey[1000]};
    --space-duration-card-text-color: ${theme.colors.grey[50]};
    --space-duration-card-color: ${theme.primaryColor[500]};
  `;
};

export const SpaceDurationContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 8px;
`;

export const Title = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  color: var(--space-duration-card-text-color);
`;

export const LinkBtn = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-top: 4px;
  span {
    color: var(--space-duration-card-color);
  }
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: var(--space-duration-card-color);
`;

export const DurationBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 4px auto auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--space-duration-card-color);
  width: 74px;
  min-width: 74px;
  height: 74px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

export const SpaceDurationCardContainer = styled.div<SSpaceDurationCardContainer>`
  ${({ theme, variant }) => colorsMixin({ theme, variant })}
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
  box-shadow: ${shadow[500]};
  background: var(--space-duration-card-bg-color);
  border-radius: 8px;
`;
