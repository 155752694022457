/* eslint-disable default-case */
/* eslint-disable no-jquery/no-bind */
import {
  ActionButton,
  Avatar,
  Box,
  ContextualAlert,
  ContextualError,
  FabButton,
  Heading,
  Icon,
  Text
} from "@arcadia/design-system";
import { each, filter, first, indexOf, isUndefined, map } from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { GeneralCommentSidebar } from "src/js/components/GeneralCommentSidebar";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import { ToggleCommentSidebar } from "src/js/components/ToggleCommentSidebar";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { useStores } from "src/js/hooks";
import { useSpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import renderLatex from "src/js/modules/mathjaxFunction";
import { useTranslation } from "src/js/translation";
import QuizCollection from "src/legacy/collections/quizzesCollection";
import CircleProgress from "src/legacy/components/circleProgress";
import UserModel from "src/legacy/models/UserModel";
import {
  getActiveGroup,
  getActiveGroupId
} from "src/legacy/modules/activeGroup";
import { getUserId } from "src/legacy/modules/activeUser";
import { doRouteBack, replaceTo } from "src/legacy/modules/history";
import {
  convertSecondsToFormattedString,
  createURL
} from "src/legacy/modules/utility";
import { __EXERCISE_MAX_RATE__ } from "src/legacy/settings/settings";
import QuizCorrectionDisplayer from "src/legacy/view/exercise/report/quizCorrectionDisplayer";
import styled, { useTheme } from "styled-components";
import withBackbone from "with-backbone";

const scrollToExercise = id => {
  const element = document.getElementById(id);
  element.scrollIntoView();
};

export const StyledFabButton = styled(FabButton)`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    position: fixed;
    bottom: 72px;
    right: 16px;
  }
`;

const ExerciseReportSessions = ({ exercise, execution }) => {
  const [isCommentBarOpen, setIsCommentBarOpen] = React.useState(false);
  const {
    ReportStore: { numberOfComments, reportThreads },
    UIStore: { isSideBarOpen, isLayoutModeMobile, closeSidebar },
    ExerciseStore: { setActiveExerciseMinimal, activeExerciseName },
    SpaceStore: { hasDecimalGrading }
  } = useStores();
  const { setHeaderComponent, resetHeaderComponent } = useSpaceGroupsLayout();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const group = getActiveGroup();

  const print = () => {
    setTimeout(() => {
      window.print();
    }, 0);
  };

  useEffect(() => {
    let back_url = createURL("exercises_reports_users", {
      group_id: getActiveGroupId(),
      exercise_id: exercise.get("id")
    });

    if (group.isStudent()) {
      // studente non può vedere la lista delle esecuzioni altrui, lo rimando all'elenco degli esercizi
      back_url = createURL("exercises", {
        group_id: getActiveGroupId()
      });
    }

    const back = () => {
      if (history.length > 1) {
        doRouteBack();
        return;
      }
      replaceTo(back_url);
    };

    setHeaderComponent(
      <SecondaryBar
        leftSection={
          <ActionButton
            icon="chevronLeft"
            theme={whiteTheme}
            variant="secondaryColored"
            onClick={back}
          />
        }
        middleSection={
          <Box textAlign="center">
            <Heading level="5">{activeExerciseName}</Heading>
          </Box>
        }
        rightSection={
          !isLayoutModeMobile && (
            <Box display="flex" flexDirection="row" gap={8}>
              {getActiveGroup().isTeacher() && (
                <ActionButton
                  icon="print"
                  onClick={print}
                  theme={whiteTheme}
                  variant="secondaryColored"
                />
              )}
              {!isCommentBarOpen && (
                <ToggleCommentSidebar
                  numberOfComment={numberOfComments}
                  isOpen={isCommentBarOpen}
                  onClick={() => {
                    closeSidebar();
                    setIsCommentBarOpen(true);
                  }}
                />
              )}
            </Box>
          )
        }
      />
    );

    return () => {
      resetHeaderComponent();
    };
  }, [
    activeExerciseName,
    isCommentBarOpen,
    numberOfComments,
    isLayoutModeMobile,
    exercise
  ]);

  const shouldShowResult = () => {
    return (
      (execution.attributes.show_results &&
        getUserId() === execution.get("user_id")) ||
      getActiveGroup().isTeacher()
    );
  };

  useEffect(() => {
    if (isSideBarOpen && isCommentBarOpen) {
      setIsCommentBarOpen(false);
    }
  }, [isSideBarOpen, isCommentBarOpen]);

  useEffect(() => {
    setActiveExerciseMinimal({
      name: exercise.get("title"),
      type: exercise.get("type")
    });

    return () => {
      setActiveExerciseMinimal(null);
    };
  }, []);

  useEffect(() => {
    if (getActiveGroup().isStudent()) {
      $(window).bind("keydown", event => {
        if (event.ctrlKey || event.metaKey) {
          switch (String.fromCharCode(event.which).toLowerCase()) {
            case "a":
              event.preventDefault();
              break;
          }
        }
      });
    }
    renderLatex();

    return () => {
      $(window).unbind("keydown");
    };
  }, []);

  let mainContent = "";
  let timePartial = 0;
  let quiz_num = 0;
  let quiz_passed_count = 0;
  let quiz_failed_count = 0;
  let executionList = null;
  let userPersonalInfo = null;
  let userBox = null;
  let pendingCorrectionsBlock = null;
  let pendingRepliesBlock = null;
  let lastCorrectionId = null;
  let lastReplyId = null;
  const quizzes = exercise.get("quizzes");
  const quizzes_executions = execution.get("quiz_executions");
  const avgVote = exercise.getAverageVote();

  const shouldStudentReplyList = Object.keys(reportThreads).map(quizExecId => {
    const thread = reportThreads[quizExecId];

    const pingPongArray = thread.filter(element => {
      return element?.system_message !== true;
    });

    const lastThreadElement =
      pingPongArray.length > 0 ? pingPongArray[pingPongArray.length - 1] : {};

    return { quizExecId, shouldReply: lastThreadElement?.is_teacher };
  });

  let realDuration = execution.get("session_time");
  const timeThreshold = exercise.getTimeThreshold();
  /** If duration is longer than threshold default to threshold */
  if (timeThreshold > 0 && realDuration > timeThreshold) {
    realDuration = timeThreshold;
  }

  const hideResourceManager = () => {
    $(".js-board__detail-container").removeClass("hidden");
    $(".resource-manager-container-wrapper").addClass("hidden");
  };

  const userModel = new UserModel(execution.get("user"));
  const userName = userModel.getFullName();
  const formattedDate = moment
    .unix(
      execution.get("session_init") + execution.get("session_time"),
      "YYYY-MM-DDTHH:mm"
    )
    .format("L LT");
  timePartial = convertSecondsToFormattedString(execution.get("session_time"));
  const avgPartial = convertSecondsToFormattedString(
    exercise.getAverageTimeSeconds()
  );
  const executionVote = execution.get("vote");
  let vote = 0;

  let voteCaption = translate("voto");
  if (group.isStudent()) {
    voteCaption = translate("il tuo voto");
  }

  if (!isUndefined(executionVote) && executionVote > -1) {
    vote = Math.round(executionVote * 100) / (hasDecimalGrading ? 10 : 1);
  }

  if (execution.has("quiz_passed_count")) {
    quiz_passed_count = execution.get("quiz_passed_count");
  }

  if (execution.has("quiz_failed_count")) {
    quiz_failed_count = execution.get("quiz_failed_count");
  }

  if (execution.has("total_quiz")) {
    quiz_num = execution.get("total_quiz");
  }

  if (group.isTeacher() || getUserId() === execution.get("user_id")) {
    let pendingCorrections = 0;
    let pendingReplies = 0;
    const checkedExecutionIds = [];
    let allExecutions = [];
    allExecutions = allExecutions.concat(quizzes_executions); // add all standard executions

    /** Video quiz executions must also be counted, they are in a different level of the array */
    each(quizzes_executions, quiz_execution => {
      if (quiz_execution.dtype === "quizexecutionvideo") {
        allExecutions = allExecutions.concat(quiz_execution.quiz_executions); // add all videoquiz executions
      }
    });

    each(allExecutions, quiz_execution => {
      const tempQuizCollection = new QuizCollection([quiz_execution.quiz]); // fake collection, to init correct submodel
      const tempQuiz = tempQuizCollection.at(0);
      if (
        tempQuiz.needsReview() &&
        !execution.getQuizReviewedByExecId(quiz_execution.id)
      ) {
        if (indexOf(checkedExecutionIds, quiz_execution.id) < 0) {
          // do not add same execution twice
          if (lastCorrectionId === null) {
            lastCorrectionId = quiz_execution.quiz_id;
          }
          pendingCorrections += 1;
          checkedExecutionIds.push(quiz_execution.id);
        }
      }
      if (quiz_execution.redo_execution) {
        const shouldStudentReply = shouldStudentReplyList.find(
          el => el.quizExecId === quiz_execution.id
        );
        if (shouldStudentReply && shouldStudentReply.shouldReply) {
          if (lastCorrectionId === null) {
            lastReplyId = quiz_execution.quiz_id;
          }
          pendingReplies += 1;
        }
      }
    });

    if (pendingCorrections > 0 && group.isTeacher()) {
      const pendingCorrectionsCopy =
        pendingCorrections === 1
          ? translate("pending_correction_label")
          : translate("pending_corrections_label");
      pendingCorrectionsBlock = (
        <div className="pull-right pull-left-xs clear-both-xs">
          <div className="margin-top-xs-10">
            <ColorLabel
              type={COLOR_LABEL_TYPE.COLOR_LABEL_ORANGE}
              isClickable
              clickFunction={() => {
                scrollToExercise(lastCorrectionId);
              }}
            >
              <div className="inline-block">
                <strong>{pendingCorrections}</strong>
                &nbsp;
                {pendingCorrectionsCopy}
              </div>
              <div className="inline-block margin-left-10 exercise-report-view-label">
                {translate("exercise_report_view_label")}
                <Icon icon="chevronDown" height={15} width={15} />
              </div>
            </ColorLabel>
          </div>
        </div>
      );
    } else if (pendingReplies > 0 && !group.isTeacher()) {
      const pendingRepliesCopy =
        pendingReplies === 1
          ? translate("pending_reply_label")
          : translate("pending_replies_label");
      pendingRepliesBlock = (
        <div className="pull-right pull-left-xs clear-both-xs">
          <div className="margin-top-xs-10">
            <ColorLabel
              type={COLOR_LABEL_TYPE.COLOR_LABEL_ORANGE}
              isClickable
              clickFunction={() => {
                scrollToExercise(lastReplyId);
              }}
            >
              <div className="inline-block">
                <strong>{pendingReplies}</strong>
                &nbsp;
                {pendingRepliesCopy}
              </div>
              <div className="inline-block margin-left-10 exercise-report-view-label">
                {translate("exercise_report_view_label")}
                <Icon icon="chevronDown" height={15} width={15} />
              </div>
            </ColorLabel>
          </div>
        </div>
      );
    }

    userPersonalInfo = (
      <div className="pull-left">
        <a
          href={userModel.getLink()}
          className="post__user-avatar"
          title={userName}
        >
          <Avatar
            url={userModel.getAvatar("medium")}
            gender={userModel.getGenderString()}
            alt={userName}
            size="m"
            theme={whiteTheme}
            rounded
          />
        </a>
        <a
          href={userModel.getLink()}
          className="quiz-report-user-name"
          title={userName}
        >
          {userName}
        </a>
        <div className="post__date">{formattedDate}</div>
      </div>
    );

    if (!shouldShowResult()) {
      return (
        <div className="app__box report_wrapper">
          <div className="report_user-wrapper">
            <div className="post__user clearfix">{userPersonalInfo}</div>
          </div>
          <div className="margin-top-15 ">
            <ContextualAlert
              theme={whiteTheme}
              text={translate("exercise_result_teacher_wont_show")}
            />
          </div>
        </div>
      );
    }

    const total = hasDecimalGrading
      ? __EXERCISE_MAX_RATE__
      : __EXERCISE_MAX_RATE__ * 10;
    const defaultVote = hasDecimalGrading ? vote : `${vote}%`;
    const averageVote = hasDecimalGrading ? avgVote / 10 : avgVote;
    const defaultAvg = hasDecimalGrading ? averageVote : `${averageVote}%`;

    userBox = (
      <div className="app__box report_wrapper">
        <div className="report_user-wrapper">
          <div className="post__user clearfix">
            {userPersonalInfo}
            {pendingCorrectionsBlock}
            {pendingRepliesBlock}
          </div>
        </div>
        <div className="report_correction-btn-wrapper" />
        <div className="report_user-info">
          <div className="row">
            <div className="col-sm-8 exercise-stats-box-wrapper">
              <div className="exercise-stats-box exercise-stats-box-vote">
                <div className="col-xs-4 text-center">
                  <CircleProgress
                    defaultText={defaultVote}
                    partial={vote}
                    total={total}
                    type="decimal"
                    caption={voteCaption}
                    colorBegin="#8B5CFF"
                    colorEnd="#8B5CFF"
                    background="#FFFFFF"
                  />
                </div>
                <div className="col-xs-4 text-center hidden-print">
                  <CircleProgress
                    defaultText={defaultAvg}
                    partial={averageVote}
                    total={total}
                    type="decimal"
                    caption={translate("voto medio")}
                    colorBegin="#8B5CFF"
                    colorEnd="#8B5CFF"
                    background="#FFFFFF"
                  />
                </div>
                <div className="col-xs-4 text-center">
                  <div className="exercise-stats-box-students-numbers">
                    <Heading level="1">{quiz_passed_count}</Heading>
                    <span className="grey800">
                      <Heading level="5">&nbsp;/ {quiz_num}</Heading>
                    </span>
                  </div>
                  <div>
                    <Text type="formSubtitle">
                      {translate("risposte corrette")}
                    </Text>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 exercise-stats-box-wrapper">
              <div className="exercise-stats-box exercise-stats-box-time">
                <div className="col-sm-6 col-xs-4 text-center">
                  <CircleProgress
                    defaultText={timePartial}
                    partial={null}
                    total={1}
                    type="decimal"
                    caption={translate("exen_summary_time_used")}
                    colorBegin="#8B5CFF"
                    colorEnd="#8B5CFF"
                    background="#FFFFFF"
                  />
                </div>
                <div className="col-sm-6 col-xs-8 text-center hidden-print">
                  <CircleProgress
                    defaultText={avgPartial}
                    partial={null}
                    total={1}
                    type="decimal"
                    caption={translate("tempo medio")}
                    colorBegin="#8B5CFF"
                    colorEnd="#8B5CFF"
                    background="#FFFFFF"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    executionList = map(quizzes, (quiz, index) => {
      let result = <div />;
      const quiz_execution = first(
        filter(quizzes_executions, element => element.quiz.id == quiz.id)
      );
      if (quiz.dtype === "quizvideo") {
        const subExecutionList = map(quiz.quizzes, (subquiz, subindex) => {
          const subquiz_execution = first(
            filter(
              quiz_execution.quiz_executions,
              element => element.quiz.id === subquiz.id
            )
          );
          return (
            <QuizCorrectionDisplayer
              quiz_execution={subquiz_execution}
              execution={execution}
              exercise={exercise}
              number={`${parseInt(1 + index, 10)}.${parseInt(
                1 + subindex,
                10
              )}`}
              isVideoSubquiz
              parentVideoQuiz={quiz}
              key={`${parseInt(1 + index, 10)}.${parseInt(1 + subindex, 10)}`}
            />
          );
        });
        result = <div className="relative">{subExecutionList}</div>;
      } else {
        result = (
          <QuizCorrectionDisplayer
            quiz_execution={quiz_execution}
            execution={execution}
            exercise={exercise}
            number={1 + index}
          />
        );
      }
      return result;
    });

    mainContent = (
      <div className="report_wrapper--commentbar mathjax-container">
        {userBox}
        {executionList}
        <StyledFabButton
          theme={whiteTheme}
          variant="primary"
          icon="comment"
          onClick={() => setIsCommentBarOpen(true)}
        />
      </div>
    );
  } else {
    mainContent = (
      <ContextualError
        theme={whiteTheme}
        text={translate("Non hai i permessi per visualizzare questa pagina")}
      />
    );
  }

  return (
    <div className="report-container">
      {mainContent}
      <GeneralCommentSidebar
        isOpen={isCommentBarOpen}
        onClose={() => {
          setIsCommentBarOpen(false);
        }}
      />

      <div className="resource-manager-container-wrapper resource_exercise hidden">
        <div className="resource-manager-container" />
        <div className="picker-modal-close">
          <ActionButton
            variant="tertiary"
            theme={whiteTheme}
            icon="close"
            onClick={hideResourceManager}
          />
        </div>
      </div>
    </div>
  );
};

export default withBackbone(observer(ExerciseReportSessions));
