import { Avatar } from "@arcadia/design-system";
import styled from "styled-components";

export const AvatarThreadItem = styled(Avatar)`
  width: 40px;
  height: 40px;
`;

export const SearchBarWrapper = styled.div`
  padding: 16px 16px 0 16px;
  input {
    height: 36px;
  }
`;

export const ChatThreads = styled.ul`
  padding: 12px 0 66px 0;
`;

export const NewChatWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: 12px;

  cursor: pointer;
`;

export const ChatThreadsWrapper = styled.div<{ pageMinHeight: string }>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  width: 100vw;
  min-height: ${({ pageMinHeight }) => pageMinHeight};
`;

export const ChatThreadsBlockedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 64px;
`;

export const OptionsMessageContainerMobile = styled.ul`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  height: 100%;
  min-width: 160px;
  margin: 0;
  list-style-type: none;
  text-align: left;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
