import React, { FC } from "react";
import { Icon, Button, IconName } from "@arcadia/design-system";
import { LoginPageProps } from "src/js/pages/unlogged/LoginPage/LoginPage.types";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { useSocialLogin } from "src/js/components/SocialLoginOptions/useSocialLogin";
import * as S from "./LoginWithSSO.styles";

type Props = {
  ssoData: LoginPageProps;
};

const LoginWithSSO: FC<Props> = ({ ssoData }) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const { handleSSO } = useSocialLogin();
  const { email, provider, tenantId } = ssoData;

  const handleProvider = () => {
    switch (provider) {
      case "GOOGLE_SSO":
        return "Google";
      case "APPLE_SSO":
        return "Apple";
      default:
        return "Microsoft";
    }
  };

  return (
    <S.Container>
      <S.EmailContainer>
        <Icon
          icon={handleProvider().toLowerCase() as IconName}
          width={16}
          height={16}
          viewBox={handleProvider() === "Apple" ? "0 0 24 24" : "0 0 18 18"}
        />
        <S.Email type="headerTitle">{email}</S.Email>
      </S.EmailContainer>
      <Button
        variant="primary"
        theme={whiteTheme}
        fullWidth
        onClick={() => handleSSO(provider, tenantId)}
      >
        {translate({
          text: "continue_with_sso_service",
          stringVariables: { provider: handleProvider() }
        })}
      </Button>
      <S.GoBackContainer onClick={() => navigateTo(createUrl("login"))}>
        <S.GoBack type="textLink">
          {translate("try_other_sign_in_method")}
        </S.GoBack>
      </S.GoBackContainer>
    </S.Container>
  );
};

export default LoginWithSSO;
