import { WeSchoolRouteMapType } from "src/js/pages/PagesRouter.types";

export const WeSchoolRoutesDescription: WeSchoolRouteMapType<string> = {
  App: {
    externalAuth: "External Authentication",
    home: "Home Page",
    joinCodiceOption: "Join with a Code",
    changeMail: "Change Email Address",
    logout: "Log Out",
    publicProfile: "Public User Profile",
    search: "Search",
    share: "Share Content",
    underageApprovation: "Underage User Approval",
    privacyPolicy: "Privacy Policy",
    Users: {
      initialRouteName: "Default User Page",
      userProfile: "Individual User Profile"
    },
    Spaces: {
      initialRouteName: "Default Space Page",
      spaceGroups: "Space Groups",
      spaceGroupsOverquotaBlocked: "Space Groups Over Quota Blocked",
      spaceChats: "Space Chats",
      spaceNotifications: "Space Notifications",
      SpaceGroup: {
        initialRouteName: "Default Group Page",
        groupId: "Group ID",
        blocked: "Group Blocked Users",
        calendar: "Group Calendar",
        calendarActivity: "Group Calendar Activities",
        calendarDeadline: "Group Calendar Deadlines",
        calendarAgenda: "Group Calendar Agenda",
        calendarLive: "Group Live Calendar Events",
        exerciseInstant: "Group Instant Exercise",
        exerciseInstantLive: "Group Live Instant Exercise",
        exerciseRun: "Group Execute Exercise",
        groupOverquotaBlocked: "Group Over Quota Blocked",
        settings: "Group Settings",
        whiteboard: "Group Whiteboard",
        modules: "Group Modules",
        Activity: {
          initialRouteName: "Group Activity Page",
          new: "Group New Activity",
          mode: "Group Activity Mode"
        },
        Boards: {
          initialRouteName: "Default Board Page",
          boardNew: "Group New Board",
          boardId: "Group Board ID",
          boardElementEdit: "Group Edit Board Element",
          boardElementResource: "Group Board Element Resource",
          boardElementResourceComments:
            "Group Comments on Board Element Resource"
        },
        Exercises: {
          initialRouteName: "Default Exercise Page",
          exercisesDrafts: "Group Draft Exercises",
          exercisesNew: "Group New Exercise",
          exercise: "Group Exercise Details",
          exerciseRun: "Group Run Exercise",
          exerciseRunOption: "Group Exercise Run Options",
          exerciseEdit: "Group Edit Exercise",
          exerciseInstant: "Group Instant Exercise",
          exerciseInstantLive: "Group Live Instant Exercise",
          exerciseInstantRun: "Group Run Instant Exercise",
          exerciseReportsSession: "Group Session Exercise Reports",
          exerciseReportsUsers: "Group User Exercise Reports"
        },
        Modules: {
          initialRouteName: "Default Module Page",
          newModule: "Group New Module",
          module: "Group Module Details",
          moduleElement: "Group Module Element",
          moduleElementEdit: "Group Edit Module Element",
          moduleElementComments: "Group Comments on Module Element",
          moduleMode: "Group Module Mode"
        },
        Post: {
          edit: "Group Edit Post",
          post: "Group Create Post",
          postReport: "Group Report Post",
          postResource: "Group Post Resource",
          postResourceEdit: "Group Edit Post Resource"
        },
        issueReport: "Group Report an Issue",
        moderation: "Group Moderation Tools",
        rankingPage: "Group Ranking Page",
        resourceReport: "Group Report a Resource",
        resourceEdit: "Group Edit Resource",
        wall: "Group Public Wall",
        wallNew: "Group New Wall Post"
      },
      SpaceChat: {
        initialRouteName: "Default Chat Page",
        new: "Space New Chat",
        threadId: "Space Chat Thread ID"
      }
    },
    Dashboard: {
      initialRouteName: "Default Dashboard",
      space: "Overview",
      analytics: "Analytics Dashboard",
      groups: "Groups Management",
      settings: "Dashboard Settings",
      users: "User Management",
      badges: "Badges and Achievements"
    },
    Dea: {
      initialRouteName: "Default DEA Page",
      importProject: "Import Project",
      importProjectNode: "Import Project Node"
    },
    Settings: {
      initialRouteName: "Default Settings Page",
      settingsOption: "Settings Options",
      settingsAccountOption: "Account Settings"
    }
  },
  Onboarding: {
    initOnboarding: "Onboarding Init",
    resumeOnboarding: "Onboarding Resume",
    resumeSpace: "Onboarding Space Resume",
    welcome: "Onboarding Welcome",
    marketingData: "Onboarding Marketing Data",
    spaceCreation: "Onboarding Space Creation",
    groupCreateSelection: "Onboarding Group Create Selection",
    groupCreation: "Onboarding Group Creation",
    weschoolTemplateCreation: "Onboarding WeSchool Template Creation",
    membersInvite: "Onboarding Member Invite",
    schoolSpaceCreation: "Onboarding School Space Creation",
    schoolSpaceConfirmation: "Onboarding School Space Confirmation",
    join: "Onboarding Join with a Code",
    logout: "Logout for Onboarding"
  },
  Auth: {
    register: "User Registration",
    sso: "Single Sign-On",
    joinCodiceOption: "Join with a Code",
    loginUniversity: "University Login",
    confirmRegistration: "Confirm Registration",
    confirmRegistrationWithAlias: "Confirm Registration with Alias",
    requestPasswordReset: "Request Password Reset",
    resetPassword: "Reset Password",
    login: "User Login",
    externalAuth: "External Authentication",
    underageApprovation: "Underage User Approval",
    userConfirmMail: "Confirm Email",
    privacyPolicy: "Privacy Policy"
  },
  UserVerification: {
    activateAccount: "Activate Account",
    logout: "Logout for Activate Account"
  },
  Underage: {
    underageLock: "Underage User Lock",
    joinCodiceOption: "Join with a Code for Underage Users",
    underageApprovation: "Underage User Approval",
    logout: "Logout for Underage Users"
  }
};

// To track virtual views like Modals
export const WeSchoolVirtualViewDescriptions = {
  Spaces: {
    Create: {
      InitialSpaceCreationModal: "Initial Space Creation Modal", // Modal when the app first loads and there are no spaces
      SpaceCreationModal: "Space Creation Modal",
      SchoolSpaceCreationModal: "School Space Creation Modal"
    }
  },
  Groups: {
    DemoSpaceGroups: "DemoSpaceGroups",
    Boards: {
      OpenCommentBar: "Content Comment Open Bar",
      CloseCommentBar: "Content Comment Close Bar"
    },
    GroupCreateClose: "Group Creation Close Modal",
    GroupCreateSelection: "Group Create Selection Flow Modal",
    GroupCreate: "Group Creation Modal",
    GroupSchoolCreate: "School Group Creation Modal",
    Join: {
      WithCode: "Join Group With Code Modal"
    },
    Templates: {
      CustomTemplateSelection: "Custom Templates Selection Modal",
      WeSchoolTemplateSelection: "WeSchool Templates Selection Modal",
      ConfirmSetCustomTemplate: "Confirm Set Custom Template Modal",
      ConfirmUnsetCustomTemplate: "Confirm Unset Custom Template Modal"
    }
  }
} as const;
