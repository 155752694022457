import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PrerequisiteMenuItem } from "src/js/components/PrerequisiteMenuItem";
import { useStores } from "src/js/hooks";
// eslint-disable-next-line import/no-named-as-default
import createUrl from "src/js/modules/routing";
import { fetchBoardPath } from "src/js/repository/boardRepository";
import {
  Translate,
  translateString
} from "src/js/translation/TranslationProvider";
// eslint-disable-next-line no-restricted-imports
import { navigateTo } from "src/legacy/modules/history";
import { Icon, Box } from "@arcadia/design-system";
import * as S from "./BoardPath.styles";

const BoardPath = ({
  id,
  name,
  closeFunction = () => {},
  boardPathMock = false
}) => {
  const {
    GroupStore: { groupId }
  } = useStores();
  const [boards, setBoards] = useState([]);
  const [showLocked, setShowLocked] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    if (boardPathMock) {
      setBoards(boardPathMock.path);
      return;
    }
    fetchBoardPath({ boardId: id }).then(({ path }) => {
      setBoards(path);
    });
  }, [id]);

  const lockedBoards = boards.filter(
    element =>
      element.is_locked &&
      element.completed_board_element_count < element.board_element_count
  );
  const availableBoards = boards.filter(
    element =>
      !element.is_locked &&
      element.completed_board_element_count < element.board_element_count
  );

  const boardDetailNavigation = ({ boardId: board_id }) => {
    navigateTo(
      createUrl("module_detail", {
        group_id: groupId,
        module_id: board_id
      })
    );
  };

  return (
    <S.BoardPathLayout>
      <S.BoardPathHeader>
        <Box>
          <Box>{translateString({ text: "board_path_title" })}</Box>
          <Box textDecoration="underline">{name}</Box>
        </Box>
        <S.HeaderIcon onClick={closeFunction}>
          <Icon icon="close" height={12} width={12} />
        </S.HeaderIcon>
      </S.BoardPathHeader>
      <S.BoardPathBody>
        <S.PrerequisiteMenuItemsWrapper>
          {availableBoards.map(
            ({ id: availableId, name: title, color, is_milestone }) => (
              <PrerequisiteMenuItem
                key={availableId}
                title={title}
                color={color}
                isMilestone={is_milestone}
                onClickFunction={() =>
                  boardDetailNavigation({ boardId: availableId })
                }
                isPathMode
              />
            )
          )}
        </S.PrerequisiteMenuItemsWrapper>
        {lockedBoards && lockedBoards.length > 0 && (
          <>
            <S.ToggleAction onClick={() => setShowLocked(!showLocked)}>
              <Translate text="board_path_show_all" />
              {showLocked ? (
                <Icon icon="chevronSmallUp" height={8} width={8} />
              ) : (
                <Icon icon="chevronSmallDown" height={8} width={8} />
              )}
            </S.ToggleAction>
            {showLocked && (
              <S.PrerequisiteMenuItemsWrapper>
                {lockedBoards.map(
                  ({ id: lockedId, name: title, color, is_milestone }) => (
                    <PrerequisiteMenuItem
                      key={lockedId}
                      title={title}
                      color={color}
                      isMilestone={is_milestone}
                      isLocked
                      isPathMode
                    />
                  )
                )}
              </S.PrerequisiteMenuItemsWrapper>
            )}
          </>
        )}
      </S.BoardPathBody>
    </S.BoardPathLayout>
  );
};

export default observer(BoardPath);
