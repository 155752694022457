import { ChatBubbleThemeOptions, Palette } from "@ds_themes/types";
import { increaseBrightness } from "@ds_themes/utils";

const createSelfTheme = ({
  primary,
  blueGrey,
  grey
}: Palette): { SelfChatBubble: ChatBubbleThemeOptions } => {
  return {
    SelfChatBubble: {
      background: primary.main,
      text: primary.contrastText,
      hover: {
        background: increaseBrightness(primary.main, 15)
      },
      timeText: blueGrey[500],
      attachmentBg: grey[700],
      showMore: primary.lighter,
      deletedMessage: {
        border: primary.light,
        text: increaseBrightness(primary.main, 15),
        background: primary.contrastText
      },
      borderRadius: {
        top: "24px 24px 8px 24px",
        middle: "24px 8px 8px 24px",
        bottom: "24px 8px 24px 24px"
      }
    }
  };
};

const createOtherTheme = ({
  blueGrey,
  grey,
  primary,
  violet
}: Palette): { OtherChatBubble: ChatBubbleThemeOptions } => {
  return {
    OtherChatBubble: {
      background: blueGrey[900],
      text: grey[50],
      hover: {
        background: blueGrey[800]
      },
      timeText: blueGrey[500],
      attachmentBg: grey[700],
      showMore: primary.lighter,
      deletedMessage: {
        text: primary.contrastText,
        background: violet[200]
      },
      borderRadius: {
        top: "24px 24px 24px 8px",
        middle: "8px 24px 24px 8px",
        bottom: "8px 24px 24px 24px"
      }
    }
  };
};

export const createChatBubbleTheme = (palette: Palette) => {
  return {
    chatbubble: {
      ...createSelfTheme(palette),
      ...createOtherTheme(palette)
    }
  };
};
