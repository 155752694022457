import {
  __HUBSPOT_ACTIVATE_ACCOUNT_ENDPOINT__,
  __HUBSPOT_ONBOARDING_MARKETING_DATA_ENDPOINT__,
  __HUBSPOT_REGISTRATION_FORM_ENDPOINT__,
  __HUBSPOT_UPDATE_USER_ENDPOINT__
} from "src/js/settings/settingsGeneral";
import { userIsUnderage } from "src/js/UserFunction";
import { User } from "../types";
import { MarketingDataHubSpot } from "../types/models/MarketingData";

type RegistrationData = Partial<User> & {
  terms?: boolean;
  externalRegistrationToken?: string;
  weschoolContact?: boolean;
  thirdPartContact?: boolean;
};

const getLegalConsentOptions = (registrationData: RegistrationData) => {
  const isUnderage = userIsUnderage({ birthday: registrationData?.birthday });
  const communications = [
    {
      value: registrationData?.terms,
      subscriptionTypeId: 999,
      text: "terms"
    }
  ];
  if (registrationData?.weschoolContact !== undefined) {
    communications.push({
      value: registrationData?.weschoolContact,
      subscriptionTypeId: 888,
      text: "weschoolContact"
    });
  }
  if (registrationData?.thirdPartContact !== undefined) {
    communications.push({
      value: registrationData?.thirdPartContact,
      subscriptionTypeId: 777,
      text: "thirdPartContact"
    });
  }

  return {
    // Include this object when GDPR options are enabled
    consent: {
      consentToProcess: isUnderage ? true : registrationData?.terms, // For underage students it's legitimate to use their data, so we're setting this field always to true.
      text: "terms",
      communications
    }
  };
};

const getContext = () => {
  const hutk = document.cookie.replace(
    // eslint-disable-next-line no-useless-escape
    /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  if (hutk) {
    return {
      hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      pageUri: window.location.href,
      pageName: document.title
    };
  }

  return {
    pageUri: window.location.href,
    pageName: document.title
  };
};

export const HubSpotRegistrationUser = ({
  registrationData,
  registrationForm
}: {
  registrationData: RegistrationData;
  registrationForm?: string;
}) => {
  const isUnderage = userIsUnderage({ birthday: registrationData?.birthday });

  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: registrationData?.email
      },
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: registrationData?.name
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: registrationData?.surname
      },
      {
        objectTypeId: "0-1",
        name: "date_of_birth_app_registration",
        value: isUnderage ? "Underage" : "Adult"
      },
      {
        objectTypeId: "0-1",
        name: "ws_language",
        value: registrationData?.language
      }
    ],
    context: getContext(),
    legalConsentOptions: getLegalConsentOptions(registrationData)
  };

  if (registrationForm) {
    data.fields.push({
      objectTypeId: "0-1",
      name: "ws_registration_form",
      value: registrationForm
    });
  }

  fetch(__HUBSPOT_REGISTRATION_FORM_ENDPOINT__, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).catch(error => {
    console.log("hubspot form error", error);
  });
};

export const HubSpotActivateAccountUser = (userData: RegistrationData) => {
  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: userData.email
      },
      {
        objectTypeId: "0-1",
        name: "activatedinplatform",
        value: true
      }
    ],
    context: getContext(),
    legalConsentOptions: getLegalConsentOptions(userData)
  };

  fetch(__HUBSPOT_ACTIVATE_ACCOUNT_ENDPOINT__, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).catch(error => {
    console.log("hubspot form error", error);
  });
};

export const HubSpotUpdateUser = (userData: RegistrationData) => {
  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: userData?.email
      },
      {
        objectTypeId: "0-1",
        name: "ws_language",
        value: userData?.language
      }
    ],
    context: getContext()
  };

  fetch(__HUBSPOT_UPDATE_USER_ENDPOINT__, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).catch(error => {
    console.log("hubspot form error", error);
  });
};

export const HubSpotOnboardingMarketingData = (
  marketingData: MarketingDataHubSpot
) => {
  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: marketingData?.email
      },
      {
        objectTypeId: "0-2",
        name: "name",
        value: marketingData?.companyName
      },
      {
        objectTypeId: "0-2",
        name: "company_size_from_ws_onboarding",
        value: marketingData?.companySize
      },
      {
        objectTypeId: "0-1",
        name: "job_role_from_ws_onboarding",
        value: marketingData?.companyRole
      },
      {
        objectTypeId: "0-1",
        name: "use_case_from_ws_onboarding",
        value: marketingData?.weschoolUseCase
      },
      {
        objectTypeId: "0-1",
        name: "mobilephone",
        value: marketingData?.phoneNumber
      }
    ],
    context: getContext()
  };

  fetch(__HUBSPOT_ONBOARDING_MARKETING_DATA_ENDPOINT__, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).catch(error => {
    console.log("hubspot form error", error);
  });
};
