import React, { useCallback, useEffect, useRef, useState } from "react";

import { useStores } from "src/js/hooks";
import { UploadError } from "src/js/components/UploadError";
import { observer } from "mobx-react";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import {
  LMSCommitHandler,
  LMSFinishHandler,
  LMSSetValueHandler,
  readySCORMHandler
} from "src/js/modules/SCORMFunction";
import { __SCORM_PLAYER_URL__ } from "src/js/settings/settingsUrl";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import {
  SCORM2004Methods,
  SCORMErrorType,
  SCORMEventData,
  SCORMMethods,
  SCORMPlayerStatus
} from "src/js/types/models/Scorm";
import { OldResource, Resource } from "src/js/types";

const ErrorMessage: Record<SCORMErrorType & "default", TranslationKeys> = {
  [SCORMErrorType.ManifestNotFound]: "manifest_not_found_SCORM_error",
  [SCORMErrorType.ExtensionNotValid]: "extension_not_valid_SCORM_error",
  [SCORMErrorType.VersionNotCompatible]: "version_not_valid_SCORM_error",
  default: "generic_SCORM_error"
};

const handleError = (type: SCORMErrorType) =>
  ErrorMessage[type] || ErrorMessage["default"];

const Scorm = ({
  boardElementId,
  resource
}: {
  boardElementId: number;
  resource: (OldResource & { url: string }) | Resource;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TranslationKeys>(null);
  const { greyTheme } = useTheme();

  const scormIframe = useRef<HTMLIFrameElement>(null);

  const {
    UserStore: { activeUser },
    UploadStore
  } = useStores();
  const { translate } = useTranslation();
  const { uploadOnWatch } = UploadStore;

  let publicUrl = "url" in resource ? resource.url : "";
  publicUrl = "public_url" in resource ? resource.public_url : publicUrl;
  publicUrl = "publicUrls" in resource ? resource.publicUrls[0] : publicUrl;

  useEffect(() => {
    if (!uploadOnWatch) return () => {};

    RealTimeDispatcher.on(`${uploadOnWatch}_upload_scorm_error`, () => {
      setLoading(false);
      UploadStore.setWatchOnResource(null);
    });
    RealTimeDispatcher.on(`${uploadOnWatch}_upload_scorm_success`, () => {
      setLoading(false);
      UploadStore.setWatchOnResource(null);
    });
    return () => {
      RealTimeDispatcher.off(`${uploadOnWatch}_upload_scorm_error`);
      RealTimeDispatcher.off(`${uploadOnWatch}_upload_scorm_success`);
      UploadStore.setWatchOnResource(null);
    };
  }, [uploadOnWatch]);

  const handleEvent = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== __SCORM_PLAYER_URL__) return;

      const req: SCORMEventData = JSON.parse(event.data);

      if (req.method === SCORMPlayerStatus.Ready) {
        readySCORMHandler({
          boardElementId: `${boardElementId}`,
          userId: activeUser.id,
          username: `${activeUser.name} ${activeUser.surname}`,
          iframe: scormIframe,
          version: req.data.version
        });
      }
    },
    [boardElementId, activeUser, readySCORMHandler]
  );

  useEffect(() => {
    if (!scormIframe.current) return;

    const eventListeners = (event: MessageEvent) => {
      if (event.origin !== __SCORM_PLAYER_URL__) return;
      const req: SCORMEventData = JSON.parse(event.data);
      switch (req.method) {
        case SCORMPlayerStatus.Error:
          setError(handleError(req.data.type));
          break;
        case SCORMPlayerStatus.Uploading:
          UploadStore.setWatchOnResource(req.data.scormId);

          setLoading(true);
          break;
        case SCORM2004Methods.Terminate:
        case SCORMMethods.LMSFinish:
          LMSFinishHandler({
            boardElementId: `${boardElementId}`,
            userId: activeUser.id,
            data: req.data.CMIData
          });
          break;
        case SCORM2004Methods.Commit:
        case SCORMMethods.LMSCommit:
          LMSCommitHandler({
            boardElementId: `${boardElementId}`,
            userId: activeUser.id,
            data: req.data.CMIData
          });
          break;
        case SCORM2004Methods.SetValue:
        case SCORMMethods.LMSSetValue:
          LMSSetValueHandler({
            boardElementId: `${boardElementId}`,
            userId: activeUser.id,
            data: req.data.CMIData
          });
          break;
        default:
      }
    };
    window.addEventListener("message", handleEvent);
    window.addEventListener("message", eventListeners, false);

    return () => {
      window.removeEventListener("message", handleEvent);
      window.removeEventListener("message", eventListeners, false);

      if (!scormIframe?.current) return;
      const closeJson = { method: "LMSExit" };
      scormIframe.current.contentWindow.postMessage(
        JSON.stringify(closeJson),
        "*"
      );
    };
  }, [handleEvent]);

  if (loading) {
    return <DoodleLoader theme={greyTheme} />;
  }
  if (error) {
    return <UploadError title="upload_error_page_title" text={error} />;
  }
  if (!publicUrl) {
    return (
      <div className="text-center">{translate("general_error_message")}</div>
    );
  }

  return (
    <div className="full-height">
      <iframe
        title={`${resource.id}`}
        id="link-player"
        allow="autoplay"
        className="resource__iframe"
        width="100%"
        frameBorder="0"
        ref={scormIframe}
        src={publicUrl}
      />
    </div>
  );
};

export default observer(Scorm);
