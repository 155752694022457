import swalTrash from "Images/swal-trash.jpg";
import swal from "sweetalert";
import { __ } from "src/legacy/modules/localization";

// eslint-disable-next-line import/prefer-default-export
export function handleAlert(customOptions) {
  const dafaultOptions = {
    title: __("Sei sicuro?"),
    type: "warning",
    buttons: {
      cancel: __("Annulla"),
      confirm: __("Conferma")
    },
    icon: swalTrash,
    closeOnConfirm: true,
    className: "swal-modal--warning"
  };

  const options = customOptions
    ? Object.assign(dafaultOptions, customOptions)
    : customOptions;

  return swal(options);
}
