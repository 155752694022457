import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { Text } from "@arcadia/design-system";
import * as S from "./CustomTooltip.styles";

const CustomTooltip = ({ topic, payload = [], xLabel = "", yLabel = "" }) => {
  if (!payload || payload.length === 0 || !topic) return null;
  const { payload: innerPayload } = payload[0];

  if (
    !innerPayload ||
    !Object.keys(innerPayload)[0] ||
    !Object.keys(innerPayload)[1]
  )
    return null;

  const xValue = xLabel || Object.keys(innerPayload)[0];
  const yValue = yLabel || Object.keys(innerPayload)[1];

  return (
    <S.TooltipContainer>
      <S.TooltipYValue data-testid="y-value">
        <Text type="captionAlert">{innerPayload[yValue]}</Text>
      </S.TooltipYValue>
      <S.TooltipSubject data-testid="subject">
        <Text type="subHeaderSubtitle">
          <Translate text={topic} />
        </Text>
      </S.TooltipSubject>
      <S.TooltipXValue data-testid="x-value">
        <Text type="captionInfoDetails">{innerPayload[xValue]}</Text>
      </S.TooltipXValue>
    </S.TooltipContainer>
  );
};

export default CustomTooltip;
