import { ObjectValues } from "src/js/types";

export const FeatureFlagKey = {
  Oauth: "oauth",
  universityLogin: "universityLogin",
  analyticsV2: "analyticsV2",
  aiCourseGeneration: "aiCourseGeneration"
} as const;

export type FeatureFlagKey = ObjectValues<typeof FeatureFlagKey>;

export type FeatureFlagConfiguration = Record<FeatureFlagKey, boolean>;

const __FEATURE_FUNCTION_CONFIGURATION_STAGE__: FeatureFlagConfiguration = {
  oauth: true,
  universityLogin: true,
  analyticsV2: true,
  aiCourseGeneration: true
};

const __FEATURE_FUNCTION_CONFIGURATION__PROD__: FeatureFlagConfiguration = {
  oauth: false,
  universityLogin: true,
  analyticsV2: false,
  aiCourseGeneration: true
};

const __FEATURE_FUNCTION_CONFIGURATION__ =
  process.env.prod === "true"
    ? __FEATURE_FUNCTION_CONFIGURATION__PROD__
    : __FEATURE_FUNCTION_CONFIGURATION_STAGE__;

export { __FEATURE_FUNCTION_CONFIGURATION__ as default };
