import styled from "styled-components";

export const ReportThreadContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ReportThreadCollapsable = styled.div`
  margin-bottom: 30px;
`;

export const ReportThreadMessage = styled.div`
  margin-bottom: 15px;
`;
