import { Box, colors } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import styled from "styled-components";
import { WeSchoolRoutes } from "../../Routes.const";

type withImgPosition = {
  imagePosition: "left: 0" | "right: 0";
};

export const ExampleMediaWrapper = styled.div<withImgPosition>`
  display: flex;
  flex-flow: column;
  & > img,
  & > video {
    position: absolute;
    max-width: 650px;
    width: 95%;
    top: 80px;
    ${({ imagePosition }) => imagePosition};
  }
  & > video {
    max-width: 765px;
    width: 115%;
    right: -150px;
    border-radius: 8px 0px 0px 8px;
    box-shadow: -18px 18px 45px -25px rgba(0, 0, 0, 0.26);
  }
`;

export const Description = styled.div`
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  padding: 100px 80px 80px 80px;
  text-align: center;
  margin-top: 60%;
`;

interface OnboardingExampleBasics {
  background: string;
  imagePosition: "left: 0" | "right: 0";
  description: string;
}
interface OnboardingExampleVideo extends OnboardingExampleBasics {
  video: string;
  image?: string;
}
interface OnboardingExampleImage extends OnboardingExampleBasics {
  image: string;
  video?: string;
}

type OnboardingExampleProps = OnboardingExampleImage | OnboardingExampleVideo;

const Example = ({
  background,
  image,
  video,
  imagePosition,
  description
}: OnboardingExampleProps) => {
  const {
    UserStore: { userOnboarding }
  } = useStores();
  return (
    <Box
      background={`url(${background}), ${colors.coldGrey[900]}`}
      backgroundSize="cover"
      height="100vh"
      minHeight={
        userOnboarding.step === WeSchoolRoutes.Onboarding.groupCreation
          ? "780px"
          : "735px"
      }
      justifyContent="center"
      alignItems="center"
      display="flex"
      position="relative"
      flexFlow="column"
    >
      <ExampleMediaWrapper imagePosition={imagePosition}>
        {image ? <img src={image} alt="Example" /> : null}
        {video ? (
          <video width="100%" autoPlay muted loop>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}
      </ExampleMediaWrapper>
      <Description>{description}</Description>
    </Box>
  );
};

export default Example;
