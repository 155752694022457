import React, { FC, PropsWithChildren } from "react";

import * as S from "./Heading.styles";
import { HeadingProps } from "./types";

export const Heading: FC<PropsWithChildren<HeadingProps>> = ({
  level = "1",
  children,
  className
}) => {
  const ariaLevel = parseInt(level, 10) || undefined;

  return (
    <S.Heading
      level={level}
      role="heading"
      aria-level={ariaLevel}
      className={className}
    >
      {children}
    </S.Heading>
  );
};

export default Heading;
