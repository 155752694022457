/* eslint-disable lines-between-class-members */
import { ModalSizeType } from "@arcadia/design-system";
import { makeAutoObservable } from "mobx";

type ModalOptions = {
  customStyle?: string;
  portalName?: string;
};
class ModalStore {
  $show = false;
  $isAnimating = false;
  $body: () => React.ReactNode = null;
  $options: ModalOptions = null;
  $customCloseCb: () => void = null;
  $size = ModalSizeType.Normal;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  closeModal = () => {
    this.$show = false;
    this.$body = null;
    this.$size = ModalSizeType.Normal;
    this.$options = null;
  };

  openModal = (body: () => React.ReactNode, options: ModalOptions = null) => {
    if (!body) return;
    this.$show = true;
    this.$body = body;
    this.$options = options;
  };

  setCustomCloseCb = (cb: () => void) => {
    this.$customCloseCb = cb;
  };

  setAnimating = (isAnimating: boolean) => {
    this.$isAnimating = isAnimating;
  };

  get isAnimating() {
    return this.$isAnimating;
  }

  get show() {
    return this.$show;
  }

  get body() {
    return this.$body;
  }
  get options() {
    return this.$options;
  }

  get size() {
    return this.$size;
  }

  get customClose() {
    return this.$customCloseCb || this.closeModal;
  }
}

export default ModalStore;
