import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { WSAnalyticsInstance } from "./WSAnalyticsInstance";

const WSAnalytics = () => {
  const { UserStore } = useStores();

  if (!UserStore.activeUser) return null;
  const { id } = UserStore.activeUser;

  WSAnalyticsInstance.init({ userId: id });

  WSAnalyticsInstance.setShouldTrack(UserStore.activeUser.shouldUseTracking);

  return null;
};

export default observer(WSAnalytics);
