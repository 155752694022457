import styled, { css } from "styled-components";

const transforms = {
  top: "translateY(-100%)",
  right: "translateX(100%)",
  bottom: "translateY(100%)",
  left: "translateX(-100%)"
};

const placements = {
  top: {
    top: 0,
    right: 0,
    left: 0
  },
  right: {
    top: 0,
    right: 0,
    bottom: 0
  },
  bottom: {
    right: 0,
    bottom: 0,
    left: 0
  },
  left: {
    top: 0,
    bottom: 0,
    left: 0
  }
};

export const DrawerCloseTrigger = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const DrawerWrapper = styled.div`
  z-index: ${({ theme }) => theme.zIndex.forDrawer};
  display: block;
  width: ${({ position, size }) =>
    position !== "top" && position !== "bottom" && size ? size : "300px"};
  height: ${({ position, size }) =>
    (position === "top" || position === "bottom") && size ? size : "100%"};
  font-family: ${({ theme }) => theme.fontFamily.title};
`;

export const DrawerOverlay = styled.div`
  position: fixed;
  top: ${({ isInsideMainLayout }) => (isInsideMainLayout ? "124px" : "0px")};
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: ${({ theme }) => theme.zIndex.forDrawerOverlay};
  display: ${({ open }) => (open ? null : "none")};
  background-color: ${({ theme }) => theme.colors.overlayWhite};
`;

export const DrawerContent = styled.div`
  display: block;
  position: fixed;
  padding: 30px;
  ${({ position }) => placements[position]}
  top: ${({ isInsideMainLayout }) => (isInsideMainLayout ? "124px" : "0px")};
  z-index: ${({ theme }) => theme.zIndex.forDrawer};
  width: ${({ position, size }) =>
    position !== "top" && position !== "bottom" && size ? size : "300px"};
  max-width: ${({ size }) => `${size}`};
  transform: ${({ open, position }) => (!open ? transforms[position] : null)};
  transition: transform 0.2s ease-out;
  overflow-x: hidden;
  color: ${({ theme }) => theme.colors.primary2};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.basicWhite};

  ${({ open }) =>
    open &&
    css`
      box-shadow: -5px 0px 10px rgb(0 0 0 / 8%);
    `}

  @media screen and (max-width:476px) {
    width: 100%;
  }
`;
