import React from "react";
import { MainBoxLayout } from "./MainBox.styles";

const MainBox = ({
  flex = true,
  children,
  isSelectable,
  isSelected,
  ...props
}) => {
  return (
    <MainBoxLayout
      flex={flex}
      isSelectable={isSelectable}
      isSelected={isSelected}
      {...props}
    >
      {children}
    </MainBoxLayout>
  );
};

export default MainBox;
