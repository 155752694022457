import styled from "styled-components";
import { Heading } from "@arcadia/design-system";
import { Container } from "react-awesome-styled-grid";

export const HeaderProfileTabWrapper = styled(Container)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  min-height: 70px;
`;

export const Name = styled(Heading)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;
