export const Animations = {
  Fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  },
  Height: {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 }
  },
  Dropdown: {
    initial: {
      y: -20,
      opacity: 0,
      filter: "blur(12px)"
    },
    animate: {
      y: 0,
      opacity: 1,
      filter: "blur(0px)"
    },
    exit: {
      y: -20,
      opacity: 0,
      filter: "blur(12px)",
      transition: { duration: 0.1 }
    }
  },
  FadeInRightFadeOutScale: {
    initial: {
      opacity: 0,
      x: 10
    },
    animate: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 0,
      scale: 0
    }
  }
};
