import React from "react";
import { LinkButton, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import {
  api_group_calendar_eventFile,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { __API_UNVERSIONED_PATH__ } from "src/js/settings/settingsUrl";
import styled from "styled-components";
import { TODO } from "src/js/types";

export const StyledLinkButton = styled(LinkButton)`
  padding-left: 0;
  padding-right: 4px;
`;

const EventDownload = ({ eventId }: { eventId: string }) => {
  const { translate } = useTranslation();
  const {
    GroupStore: { groupId }
  } = useStores();

  const fileUrl =
    __API_UNVERSIONED_PATH__ +
    getVersionedApiUrl(
      api_group_calendar_eventFile({
        groupId,
        calendarEventId: eventId as TODO
      }),
      "v3"
    );

  return (
    <StyledLinkButton
      onClick={() => {
        window.open(fileUrl);
      }}
    >
      <Text type="textLink">{translate("event_calendar_download_link")}</Text>
    </StyledLinkButton>
  );
};

export default EventDownload;
