import { observer } from "mobx-react";
import React, { useEffect, useState, FC } from "react";
import { useRouteMatch } from "react-router";
import { useTheme } from "styled-components";
import { ConfirmationAlert } from "@arcadia/design-system";

import appUrl from "src/js/appUrl";
import { EditBoardHeader } from "src/js/components/DrawerEditBoard/EditBoardHeader";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import {
  Translate,
  useTranslation
} from "src/js/translation/TranslationProvider";
import useDeviceDetector from "src/js/hooks/useDeviceDetector";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { EventDomain, LessonsEvent } from "src/js/types";
import { EditBoardBody } from "../EditBoardBody";
import { useEditBoard } from "./useEditBoard";
import { EditBoardTypes } from "./types";

const EditBoard: FC<EditBoardTypes> = ({
  boardId,
  shouldUpdateBulkValues = false,
  closeDrawer = () => {}
}) => {
  const [boardData, setBoardData] = useState({
    name: "",
    milestoneMessage: "",
    isPublished: false,
    color: 0,
    isOpen: false,
    isMandatory: false,
    isMilestone: false,
    hasMandatoryOrder: false
  });
  const {
    editTitle,
    editColor,
    editMilestone,
    editMandatory,
    editOpen,
    editMilestoneMessage,
    onAddBoardConstraint,
    onRemoveBoardConstraint,
    togglePublish,
    confirmDelete,
    editMandatoryOrder,
    refetchModules
  } = useEditBoard(boardId, boardData, setBoardData);
  const { isDesktop } = useDeviceDetector();

  const {
    BoardsStore: {
      getSingleBoard,
      getSingleBoardConstraints,
      fetchBoardsConstraints,
      editBoardFields,
      togglePublishBoard
    },
    GroupStore: { groupId },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();
  const boardsMatch = useRouteMatch(appUrl.modules);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const board = getSingleBoard({ groupId, boardId });
  const constraints = getSingleBoardConstraints({ boardId });
  const {
    id,
    name,
    isPublished,
    color,
    isOpen,
    isMandatory,
    isMilestone,
    boardElementCount,
    suggestionCount,
    availableScore,
    milestoneMessage,
    hasMandatoryOrder
  } = board || {};

  useEffect(() => {
    if (!boardId) {
      return;
    }
    fetchBoardsConstraints({ boardId });
  }, [boardId]);

  useEffect(() => {
    // This useEffect is only run in mobile devices and while the users presses "Done" in the Drawer Header.
    const editFields = async () => {
      const res = await editBoardFields({
        boardId: id,
        groupId,
        boardData: {
          color: boardData.color,
          name: boardData.name,
          isMandatory: boardData.isMandatory,
          isMilestone: boardData.isMilestone,
          hasMandatoryOrder: boardData.hasMandatoryOrder,
          isOpen: boardData.isOpen,
          milestoneMessage: boardData.milestoneMessage
        }
      });
      if (res) {
        closeDrawer();
      } else {
        showToastError({ str: <Translate text="board_error_edit" /> });
      }
    };
    if (shouldUpdateBulkValues) {
      if (
        color !== boardData.color ||
        name !== boardData.name ||
        isMandatory !== boardData.isMandatory ||
        isMilestone !== boardData.isMilestone ||
        hasMandatoryOrder !== boardData.hasMandatoryOrder ||
        isOpen !== boardData.isOpen ||
        milestoneMessage !== boardData.milestoneMessage
      ) {
        editFields().then(() => {
          if (
            hasMandatoryOrder !== boardData.hasMandatoryOrder &&
            boardData.hasMandatoryOrder
          ) {
            refetchModules();
          }
        });
        // Tracking events for mobile
        if (isOpen !== boardData.isOpen) {
          _trackEvent(
            EventDomain.Lessons,
            LessonsEvent.LessonsLocking,
            boardData.isOpen ? "Lock" : "Unlock"
          );
        }
        if (hasMandatoryOrder !== boardData.hasMandatoryOrder) {
          _trackEvent(
            EventDomain.Lessons,
            LessonsEvent.MandatoryOrder,
            boardData.hasMandatoryOrder ? "Fixed" : "NotFixed"
          );
        }
      }

      if (isPublished !== boardData.isPublished) {
        togglePublishBoard({
          groupId,
          boardId: id,
          publish: boardData.isPublished
        })
          .then(() => closeDrawer())
          .catch(() => {
            if (boardData.isPublished) {
              showToastError({ str: <Translate text="board_error_publish" /> });
            } else {
              showToastError({
                str: <Translate text="board_error_unpublish" />
              });
            }
          });
        return;
      }

      closeDrawer();
    }
  }, [shouldUpdateBulkValues]);

  useEffect(() => {
    // boardData is used only in mobile devices, to update the values while pressing Done in Drawer Header.
    if (board && Object.keys(board).length) {
      setBoardData({
        name,
        isPublished,
        color,
        isOpen,
        isMandatory,
        isMilestone,
        milestoneMessage,
        hasMandatoryOrder
      });
    }
  }, [board]);

  // We're using different approach based on device type.
  // In mobile devices we're keeping the values in state
  // and update them while clicking on Done button,
  // while in large devices we're instant updating the new values.
  const handleValuesBasedOnDevice = {
    name: isDesktop ? name : boardData.name,
    isPublished: isDesktop ? isPublished : boardData.isPublished,
    color: isDesktop ? color : boardData.color,
    isOpen: isDesktop ? isOpen : boardData.isOpen,
    isMandatory: isDesktop ? isMandatory : boardData.isMandatory,
    isMilestone: isDesktop ? isMilestone : boardData.isMilestone,
    hasMandatoryOrder: isDesktop
      ? hasMandatoryOrder
      : boardData.hasMandatoryOrder
  };

  return (
    <>
      <EditBoardHeader
        title={handleValuesBasedOnDevice.name}
        boardElementCount={boardElementCount}
        suggestionCount={suggestionCount}
        availableScore={availableScore}
        isPublished={handleValuesBasedOnDevice.isPublished}
        editTitleFunction={editTitle}
        togglePublishFunction={togglePublish}
        deleteFunction={() => {
          openConfirmDialog(
            <ConfirmationAlert
              theme={whiteTheme}
              text={translate("delete_board_modal_description")}
              acceptText={translate("delete_board_modal_confirm")}
              declineText={translate("delete_board_modal_cancel")}
              onAcceptFunction={() => {
                confirmDelete(!boardsMatch?.isExact, closeDrawer);
              }}
              onDeclineFunction={closeConfirmDialog}
            />
          );
        }}
        closeFunction={closeDrawer}
      />
      <EditBoardBody
        color={handleValuesBasedOnDevice.color}
        isMandatory={handleValuesBasedOnDevice.isMandatory}
        constraints={constraints}
        isMilestone={handleValuesBasedOnDevice.isMilestone}
        isOpen={handleValuesBasedOnDevice.isOpen}
        hasMandatoryOrder={handleValuesBasedOnDevice.hasMandatoryOrder}
        milestoneMessage={boardData.milestoneMessage}
        originalMilestoneMessage={milestoneMessage}
        boardId={id}
        groupId={groupId}
        isDesktop={isDesktop}
        setMilestoneMessage={message =>
          setBoardData(prevData => ({
            ...prevData,
            milestoneMessage: message
          }))
        }
        editMilestone={editMilestone}
        editColor={editColor}
        editMandatory={editMandatory}
        editMandatoryOrder={editMandatoryOrder}
        editOpen={editOpen}
        onAddBoardConstraint={onAddBoardConstraint}
        onRemoveBoardConstraint={onRemoveBoardConstraint}
        onSaveMilestoneMessage={editMilestoneMessage}
      />
    </>
  );
};

export default observer(EditBoard);
