import React from "react";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation";
import { Toggle, Icon, IconName } from "@arcadia/design-system";
import * as S from "./EditBoardToggleElement.styles";

type EditBoardToggleElementProps = {
  title: TranslationKeys;
  description: TranslationKeys;
  iconName: IconName;
  noTopBorder?: boolean;
  onToggleChange: (value: boolean) => void;
  toggleValue?: boolean;
};

const EditBoardToggleElement = ({
  title,
  description,
  iconName,
  noTopBorder = false,
  onToggleChange,
  toggleValue
}: EditBoardToggleElementProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  return (
    <S.Layout noTopBorder={noTopBorder}>
      <S.IconContainer>
        <Icon icon={iconName} height={16} width={16} initialViewbox />
      </S.IconContainer>
      <S.TextContainer>
        <S.Title>{translate(title)}</S.Title>
        <S.Description>{translate(description)}</S.Description>
      </S.TextContainer>
      <Toggle
        title={translate(title)}
        theme={whiteTheme}
        checked={toggleValue}
        size="small"
        onChange={event => onToggleChange(event.target.checked)}
      />
    </S.Layout>
  );
};

export default EditBoardToggleElement;
