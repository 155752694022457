import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const SelectionContainer = styled.div`
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 24px 20px 20px 24px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const ButtonBlock = styled.div<{ isSelected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.violet[500] : theme.colors.grey[800]};
  border-radius: 12px;
  height: 46px;
  padding: 0 12px;
  gap: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    color: ${({ theme }) => theme.colors.violet[500]};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.violet[500]};
    cursor: pointer;
  }
`;

export const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: auto;
`;
