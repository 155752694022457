import { BottomModalSheet, Icon } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import useCloseOnClickOutside from "src/js/hooks/useCloseOnClickOutside";
import { ListItem } from "./ListItem";
import { Props } from "./types";

import * as S from "./DotsMenu.styles";

const DotsMenu = ({
  id,
  listContainerId,
  customIcon = "kebab",
  items = [],
  header = null,
  className,
  defaultIsOpen = false,
  alignRight = false,
  openFromTop = false,
  customMenu = null,
  withMobileHeaderLabels = false,
  withMobileAdaptiveHeight = true,
  mobileCloseLabel,
  mobileConfirmLabel,
  mobileTitle,
  forceMount = true,
  onClickCustom = () => {}
}: Props) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { isOpen, setIsOpen, ref } = useCloseOnClickOutside(defaultIsOpen);

  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: openFromTop ? "20px" : "-20px" }
  };

  const menuList =
    forceMount || isOpen
      ? customMenu || (
          <>
            {header}
            <S.DotsMenuList>
              {items.map(
                ({
                  icon,
                  id: itemId,
                  label,
                  onClick,
                  redShade,
                  isDisabled
                }) => (
                  <ListItem
                    key={`dots-menu-item-${itemId}`}
                    id={itemId}
                    icon={icon}
                    label={label}
                    redShade={redShade}
                    isDisabled={isDisabled}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClick(e);
                      setIsOpen(false);
                    }}
                  />
                )
              )}
            </S.DotsMenuList>
          </>
        )
      : null;

  if (!customMenu && !items.length) return null;

  return (
    <S.Wrapper ref={ref}>
      <AnimatePresence>
        <S.DotsMenuButton
          id={id}
          key={id}
          className={[className, isOpen ? "dots-menu__open" : ""].join(" ")}
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(val => !val);
            onClickCustom();
          }}
        >
          <Icon icon={customIcon} height={20} width={20} />
        </S.DotsMenuButton>
        {isLayoutModeMobile ? (
          <BottomModalSheet
            withDrag={false}
            withHeaderLabels={withMobileHeaderLabels}
            isOpen={isOpen}
            onClose={() => setIsOpen(val => !val)}
            onConfirm={() => setIsOpen(val => !val)}
            withAdaptiveHeight={withMobileAdaptiveHeight}
            title={mobileTitle}
            closeLabel={mobileCloseLabel}
            confirmLabel={mobileConfirmLabel}
          >
            <S.MobileBottomModalSheet>{menuList}</S.MobileBottomModalSheet>
          </BottomModalSheet>
        ) : (
          isOpen && (
            <S.DotsMenuListContainer
              id={listContainerId}
              isOpen={isOpen}
              alignRight={alignRight}
              openFromTop={openFromTop}
              variants={variants}
              initial="closed"
              animate="open"
            >
              {menuList}
            </S.DotsMenuListContainer>
          )
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};

export default observer(DotsMenu);
