import React, { useMemo } from "react";
import { __BOARD_COLOR__ } from "src/js/settings/settingsBoard";
import { Translate } from "src/js/translation/TranslationProvider";
import { Icon } from "@arcadia/design-system";
import * as S from "./PrerequisiteMenuItem.styles";

const PrerequisiteMenuItem = ({
  title,
  color,
  isDraft,
  onClickFunction = () => {},
  onDismiss = () => {},
  isPathMode = false,
  isMilestone = false,
  isLocked = false
}) => {
  const selectedColorHex = useMemo(() => {
    return __BOARD_COLOR__.get(color);
  });

  return (
    <S.PrerequisiteMenuItem
      selectedColorHex={selectedColorHex}
      isClickable={isPathMode && !isLocked}
      isLocked={isLocked}
      onClick={onClickFunction}
    >
      {isPathMode && (
        <S.StatusIconContainer isLocked={isLocked}>
          {isLocked ? (
            <Icon icon="locked" height={10} width={10} />
          ) : (
            <Icon icon="play" height={8} width={8} />
          )}
        </S.StatusIconContainer>
      )}
      <S.ItemTitle>{title}</S.ItemTitle>
      {isDraft && (
        <S.DraftBadge>
          <Translate text="board_draft" />
        </S.DraftBadge>
      )}
      {isPathMode ? (
        <>
          {isMilestone && (
            <S.IconMilestone>
              <Icon
                icon="crown"
                height={22}
                width={22}
                color={selectedColorHex}
              />
            </S.IconMilestone>
          )}
          {!isLocked && (
            <S.PathGoButton>
              <S.PathText>
                <Translate text="board_path_go" />
              </S.PathText>
              <S.IconArrow>
                <Icon icon="arrowRight" height={12} width={12} />
              </S.IconArrow>
            </S.PathGoButton>
          )}
        </>
      ) : (
        <S.IconClose onClick={onDismiss}>
          <Icon icon="close" height={16} width={16} />
        </S.IconClose>
      )}
    </S.PrerequisiteMenuItem>
  );
};

export default PrerequisiteMenuItem;
