import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { IconName } from "@ds_types/components";

import * as S from "./Toast.styles";
import { ToastProps, ToastType } from "./types";

const getIcon = (type: ToastType): IconName => {
  switch (type) {
    case "warning":
      return "dangerCircle";

    case "error":
      return "dangerTriangle";

    case "success":
    default:
      return "check2";
  }
};

const Toast: FC<PropsWithChildren<ToastProps>> = ({
  theme,
  hasIcon,
  type,
  children,
  visible = false,
  onClose
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ToastContainer
        type={type}
        visible={visible}
        role="alert"
        aria-label={type}
        aria-live="polite"
      >
        <S.ToastIconType>
          {hasIcon ? (
            <Icon icon={getIcon(type)} width="18px" height="18px" />
          ) : null}
        </S.ToastIconType>
        <S.ToastText>{children}</S.ToastText>
        {onClose ? (
          <S.ToastButton onClick={onClose} aria-label="close">
            <Icon icon="close" width="14px" height="14px" />
          </S.ToastButton>
        ) : null}
      </S.ToastContainer>
    </ThemeProvider>
  );
};

export default Toast;
