import { DoodleLoader } from "@arcadia/design-system";
import styled from "styled-components";

export const NewThreadPageContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  flex: 1;
`;

export const NewThreadPageWrapper = styled.div<{ pageMinHeight: string }>`
  display: flex;
  flex-direction: column;
  height: ${({ pageMinHeight }) => pageMinHeight};
  width: 100%;
`;

export const StyledDoodleLoader = styled(DoodleLoader)`
  height: 100%;
  & > div {
    width: 200px;
    height: 100%;
    min-height: 100%;
  }
`;
