import React, { FC } from "react";
import * as S from "./SpaceSchoolBadge.styles";
import {
  SpaceSchoolBadgeProps,
  SpaceSchoolBadgeVariant
} from "./SpaceSchoolBadge.types";

const SpaceSchoolBadge: FC<SpaceSchoolBadgeProps> = ({
  variant = SpaceSchoolBadgeVariant.Violet,
  className
}) => {
  return (
    <S.BadgeIcon
      variant={variant}
      className={className}
      icon="checkBadge"
      width="16px"
      height="16px"
    />
  );
};

export default SpaceSchoolBadge;
