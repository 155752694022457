import React from "react";
import { Icon } from "@arcadia/design-system";
import * as S from "./NumberSelector.styles";

type NumberSelectorProps = {
  value: number;
  min: number;
  max: number;
  step: number;
  label?: string;
  className?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
};

const NumberSelector = ({
  value,
  min = 0,
  max = 10,
  step = 1,
  label,
  className,
  disabled,
  onChange = () => {}
}: NumberSelectorProps) => {
  const increment = () => {
    if (disabled) return;
    const newValue = value + step;
    if (newValue <= max) {
      onChange(newValue);
    }
  };

  const decrement = () => {
    if (disabled) return;
    const newValue = value - step;
    if (newValue >= min) {
      onChange(newValue);
    }
  };

  return (
    <S.Wrapper className={className} disabled={disabled}>
      <S.ActionButton type="button" onClick={decrement} disabled={disabled}>
        <Icon icon="minus" width={12} height={12} />
      </S.ActionButton>
      <div>
        {value}
        {label}
      </div>
      <S.ActionButton type="button" onClick={increment} disabled={disabled}>
        <Icon icon="plusThin" width={12} height={12} />
      </S.ActionButton>
    </S.Wrapper>
  );
};

export default NumberSelector;
