import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { Box, Icon } from "@arcadia/design-system";
import { DataFunc, Mention, MentionsInput } from "react-mentions";
import { AnimatePresence } from "framer-motion";
import theme from "src/js/theme/theme";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import EmojiPicker from "@emoji-mart/react";
import { getCurrentLanguage } from "src/js/modules/localization";
import {
  fetchMostMentionedUsers,
  fetchUsersToMention
} from "src/js/repository/groupRepository";
import { MemberNonAdmin } from "src/js/pages/group/settings/GroupSettingsMembers/GroupSettingsMembers.types";
import { TODO } from "src/js/types";
import { MentionList } from "./components/MentionList";
import * as S from "./SocialInput.styles";
import { SocialInputProps, EmojiOnPick } from "./SocialInput.types";
import { Suggestion } from "./components/Suggestion";
import {
  appendEmojiInPosition,
  parseEmojisInPosition
} from "./SocialInput.utils";
import { useMeasureDistance } from "./hooks/useMeasureDistance";

const SocialInput = forwardRef<
  HTMLTextAreaElement | HTMLInputElement,
  SocialInputProps
>(
  (
    {
      onKeyPress,
      value,
      onChange,
      variant = "white",
      maxHeight,
      singleLine = false,
      targetRef,
      renderBottom = false,
      ...rest
    },
    ref
  ) => {
    const [showPicker, setShowPicker] = useState(false);
    const [emojiPickerTargetOffset, setEmojiPickerTargetOffset] = useState(0);
    const [users, setUsers] = useState<MemberNonAdmin[]>([]);
    const [cursorPosition, setCursorPosition] = useState(0);
    const defInputRef = useRef<HTMLTextAreaElement>();
    const defTargetRef = useRef<HTMLDivElement>();
    const {
      dynamicElementRef,
      dynamicElementRight,
      targetElementRight,
      targetElementWidth
    } = useMeasureDistance({
      targetRef: targetRef ?? defTargetRef
    });
    useImperativeHandle(
      ref,
      () =>
        ({
          focus: () => defInputRef.current.focus()
        }) as TODO
    );
    const {
      GroupStore: { activeGroup }
    } = useStores();
    const getCursorPosition = () => defInputRef.current?.selectionStart ?? 0;
    useLayoutEffect(() => {
      const input = defInputRef.current;
      if (input !== undefined) {
        input.focus();
        input.selectionStart = cursorPosition;
        input.selectionEnd = cursorPosition;
      }
    }, [cursorPosition]);
    const interceptedKeyPress = (
      e: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      const { key } = e;
      const text = value ?? "";
      const cursorIndex = getCursorPosition();
      if (key === " ") {
        const { newText, newCursorPosition } = parseEmojisInPosition(
          text,
          cursorIndex
        );
        // Propagate change
        if (onChange !== undefined) {
          onChange({ target: { value: newText } }, newText, newText, []);
        }
        setCursorPosition(newCursorPosition);
      }
      if (onKeyPress !== undefined) {
        onKeyPress(e);
      }
    };
    const togglePicker = useCallback(() => {
      setShowPicker(!showPicker);
    }, [showPicker]);
    const onEmojiSelect = (emoji: EmojiOnPick) => {
      const cursorIndex = getCursorPosition();
      const text = value ?? "";
      const { newCursorPosition, newText } = appendEmojiInPosition(
        text,
        cursorIndex,
        emoji
      );
      // Propagate change
      if (onChange !== undefined) {
        onChange({ target: { value: newText } }, newText, newText, []);
      }
      setCursorPosition(newCursorPosition);
    };
    const fetchUsers: DataFunc = useCallback(async (query, callback) => {
      try {
        if (query === "") {
          const { results: mostMentioned }: { results: MemberNonAdmin[] } =
            await fetchMostMentionedUsers(activeGroup?.id);
          setUsers(mostMentioned);
          if (mostMentioned.length !== 0) {
            callback(
              mostMentioned.map(user => ({
                id: user.uuid,
                display: `${user.name} ${user.surname}`
              }))
            );
          } else {
            const { results: defaultUsers }: { results: MemberNonAdmin[] } =
              await fetchUsersToMention(activeGroup?.id);
            setUsers(defaultUsers);
            callback(
              defaultUsers.map(user => ({
                id: user.uuid,
                display: `${user.name} ${user.surname}`
              }))
            );
          }
        } else if (query !== "") {
          const { results: usersToMention }: { results: MemberNonAdmin[] } =
            await fetchUsersToMention(activeGroup?.id, query);
          setUsers(usersToMention);
          callback(
            usersToMention.map(user => ({
              id: user.uuid,
              display: `${user.name} ${user.surname}`
            }))
          );
        }
      } catch {
        setUsers([]);
      }
    }, []);
    useLayoutEffect(() => {
      const diff = dynamicElementRight - targetElementRight;
      if (diff !== 0) {
        setEmojiPickerTargetOffset(diff);
      }
    }, [targetElementRight, dynamicElementRight]);
    return (
      <Box position="relative" width="100%" maxHeight={maxHeight || "unset"}>
        <AnimatePresence>
          {showPicker ? (
            <S.EmojiPickerWrapper
              ref={dynamicElementRef}
              offsetRight={emojiPickerTargetOffset}
              targetElementRight={targetElementRight}
              targetElementWidth={targetElementWidth}
              renderBottom={renderBottom}
              key="emoji-picker"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <EmojiPicker
                locale={getCurrentLanguage().split("_")[0] || "en"}
                theme="light"
                emojiButtonRadius="8px"
                dynamicWidth
                onEmojiSelect={onEmojiSelect}
                onClickOutside={togglePicker}
                emojiButtonColors={[theme.colors.coldGrey[600]]}
              />
            </S.EmojiPickerWrapper>
          ) : undefined}
        </AnimatePresence>
        <S.WsMentionWrapper
          ref={defTargetRef}
          variant={variant}
          maxHeight={maxHeight || "unset"}
        >
          <Box flex={1} width={20}>
            <MentionsInput
              value={value}
              allowSuggestionsAboveCursor
              onChange={onChange}
              onKeyDown={interceptedKeyPress}
              className="ws-mentions"
              rows={5}
              singleLine={singleLine}
              allowSpaceInQuery
              inputRef={defInputRef}
              // eslint-disable-next-line react/no-unstable-nested-components
              customSuggestionsContainer={children => (
                <MentionList
                  renderBottom={renderBottom}
                  targetRef={targetRef !== undefined ? targetRef : defTargetRef}
                >
                  {children}
                </MentionList>
              )}
              {...rest}
            >
              <Mention
                trigger="@"
                appendSpaceOnAdd
                displayTransform={(id, display) => `@${display}`}
                renderSuggestion={(
                  suggest,
                  search,
                  highlighted,
                  index,
                  focused
                ) => (
                  <Suggestion
                    suggestion={suggest}
                    users={users}
                    focused={focused}
                  />
                )}
                data={fetchUsers}
              />
            </MentionsInput>
          </Box>
          <S.IconWrapper isActive={showPicker}>
            <Icon onClick={togglePicker} icon="emoji" width={24} height={24} />
          </S.IconWrapper>
        </S.WsMentionWrapper>
      </Box>
    );
  }
);
SocialInput.displayName = "SocialInput";
export default observer(SocialInput);
