import React from "react";
import { Icon } from "@arcadia/design-system";
import * as S from "./ToggleCommentSidebar.styles";

const ToggleCommentSidebar = ({
  numberOfComment = 0,
  isOpen = false,
  onClick = () => {}
}) => {
  return (
    <S.Container isOpen={isOpen} onClick={onClick}>
      <S.NumberContainer>
        <S.Text>{numberOfComment}</S.Text>
        <Icon icon="comment" height={16} width={16} />
      </S.NumberContainer>
      {isOpen ? (
        <Icon icon="chevronRight" height={16} width={16} />
      ) : (
        <Icon icon="plusThin" height={16} width={16} />
      )}
    </S.Container>
  );
};

export default ToggleCommentSidebar;
