import styled from "styled-components";
import { motion } from "framer-motion";

export const StackWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  position: absolute;
  overflow: visible;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.primaryColor[500]};
  z-index: 3;
  user-select: none;
`;

export const MidShadow = styled(motion.div)`
  position: absolute;
  width: 40px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor[600]};
  z-index: 2;
`;

export const UnderShadow = styled(motion.div)`
  position: absolute;
  width: 34px;
  height: 38px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor[700]};
  z-index: 1;
`;
