import { DoodleLoader } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useOnboarding, useStores } from "src/js/hooks";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

const RedirectToOnboarding = () => {
  const {
    AuthStore: { resetUserLoggedInSuccessfully, userLoggedInSuccessfully },
    UserStore: { activeUser, userOnboarding }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { initUserOnboarding } = useOnboarding();

  useEffect(() => {
    if (!userOnboarding.isCompleted) {
      initUserOnboarding();
    }
  }, []);

  useEffect(() => {
    if (userLoggedInSuccessfully) {
      resetUserLoggedInSuccessfully();
    }
  }, [userLoggedInSuccessfully]);

  if (activeUser && !userOnboarding.isCompleted === true) {
    return <DoodleLoader theme={whiteTheme} />;
  }

  if (activeUser && userOnboarding.isCompleted === true) {
    navigateTo("home");
    return null;
  }

  return null;
};

export default observer(RedirectToOnboarding);
