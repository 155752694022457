import styled, { css } from "styled-components";

export const QuickActionLayout = styled.div`
  height: 30px;
  width: 30px;
  padding: 4px;
  z-index: ${({ theme }) => theme.zIndex.quickActionButton};
  margin-top: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightBlueGrey};
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.paleGray};
      color: ${({ theme }) => theme.colors.paleGray};
      cursor: not-allowed;
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.paleGray};
      }
    `}
`;
