import React, { useCallback, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { SlideshowProps } from "./types";
import { dragOptions, variants } from "./utils";
import * as S from "./Slideshow.styles";

const Slideshow = ({
  items,
  showPagination,
  swipeable,
  msTimeout,
  startingIndex = 0,
  className,
  classNameDotsSlider = "ws-slideshow-dots-slider"
}: SlideshowProps) => {
  const validIndex =
    startingIndex === items.length || startingIndex > items.length
      ? 0
      : startingIndex;
  const [[page, direction], setPage] = useState([validIndex, 0]);

  const paginate = useCallback(
    (newDirection: number) => {
      const newPage = page + newDirection;
      if (newPage === items.length || newPage > items.length) {
        setPage([0, newDirection]);
        return;
      }
      if (newPage < 0) {
        setPage([items.length - 1, newDirection]);
        return;
      }
      setPage([newPage, newDirection]);
    },
    [page, items.length]
  );

  useEffect(() => {
    if (!msTimeout) return;
    const intervalId = setInterval(() => {
      paginate(1);
    }, msTimeout);

    return () => {
      clearInterval(intervalId);
    };
  }, [msTimeout, paginate]);

  return (
    <S.Wrapper className={className}>
      <S.ContentContainer>
        <AnimatePresence initial={false} custom={direction}>
          <S.MotionDiv
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 100 },
              opacity: { duration: 0.4 }
            }}
            {...dragOptions(paginate, swipeable)}
          >
            {items[page]}
          </S.MotionDiv>
        </AnimatePresence>
      </S.ContentContainer>
      {showPagination && (
        <S.DotContainer className={classNameDotsSlider}>
          {items.map((_, index) => (
            <S.SlideshowDot key={index} isActive={page === index} />
          ))}
        </S.DotContainer>
      )}
    </S.Wrapper>
  );
};

export default Slideshow;
