import { TODO } from "src/js/types";

export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as TODO).msMaxTouchPoints > 0
  );
}

export function isAndroidDevice() {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isTouch = isTouchDevice();

  return isAndroid && isTouch;
}
