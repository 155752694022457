import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled(motion.div)`
  width: 100%;
  min-height: 80px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 12px;
  padding-right: 20px;
  margin-bottom: 12px;
`;

export const UniversityName = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[200]};
`;

export const UniImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 16px;
  object-fit: cover;
`;
