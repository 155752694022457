import React from "react";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { createStaticUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { EventDomain, OnboardingEvent } from "src/js/types";
import styled from "styled-components";

export const LogoutBanner = styled.div`
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.violet[900]};
  text-align: center;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 20px;
  }

  & a:hover {
    color: ${({ theme }) => theme.colors.violet[300]};
  }
`;

const onLogout = () => {
  _trackEvent(EventDomain.Onboarding, OnboardingEvent.Logout);
};

const LogoutBlock = () => {
  const { translate } = useTranslation();
  return (
    <LogoutBanner>
      {translate("onboarding_logout_message")}&nbsp;
      <a href={`#${createStaticUrl("logout")}`} onClick={onLogout}>
        {translate("onboarding_logout_link")}
      </a>
    </LogoutBanner>
  );
};

export default LogoutBlock;
