import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  getVersionedApiUrl,
  api_user_public_profile_badge_earned
} from "src/js/repository/apiUrls";
import getUserClient from "src/js/modules/connection";
import { UserAwardedBadgeType, StandardPaginationResponse } from "../types";

export function fetchPublicProfileBadgeList({ limit, offset = 0, userUuid }) {
  return getUserClient()
    .get<StandardPaginationResponse<UserAwardedBadgeType>>(
      getVersionedApiUrl(
        api_user_public_profile_badge_earned({ userUuid }),
        "v3"
      ),
      {
        params: { limit, offset }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_profile_rank_list")
      );
      throw Err;
    });
}
