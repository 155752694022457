import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { ViewAllReactionButton } from "../../reactions/ReactionList/ReactionList.styles";

export const CommentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  padding: 16px;
  border-radius: 8px;

  ${ViewAllReactionButton} {
    margin-bottom: 8px;
  }
`;

export const ShowMoreCta = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};

  &:hover {
    color: ${({ theme }) => theme.primaryColor[300]};
    text-decoration: underline;
  }
`;

export const TextWrapper = styled(Text)`
  overflow: hidden;
  word-break: break-word;
  line-height: unset;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  height: 40px;
`;

export const DateText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
  line-height: 16px;
  flex: 1;
`;

export const UsernameWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;

export const CustomText = styled(Text)`
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor[500]}!important;
  }
`;

export const Body = styled.div`
  margin: 4px 0px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  button {
    min-width: 40px;
  }
  > div > div:first-of-type {
    margin-top: 0px;
  }
`;

export const ActionsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 4px 0px 8px;
  gap: 4px;
  justify-content: flex-end;
`;

export const AnimatedWrapper = styled(motion.div)`
  position: absolute;
  top: -56px;
  right: 0px;

  @media (max-width: 767px) {
    left: 0px;
  }
`;

export const StyledButton = styled.button<{ isActive?: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 4px;
  padding: 0px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[900]};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primaryColor[500]};
    `}
`;

export const EmojiPickerWrapper = styled.div``;
