/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { string, oneOf } from "prop-types";
import React, { forwardRef } from "react";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { SpecificError } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { Label } from "../Label";
import { LABEL_POSITIONS, INPUT_VARIANT, ICON_POSITION } from "./Input.const";

import * as S from "./Input.styles";

/**
 * @name input
 *
 * @description
 * Input component, will handle ref for validation and auto translate values
 */
const Input = forwardRef(
  (
    {
      id,
      name,
      defaultValue = undefined,
      value = undefined,
      placeholder = null,
      label = null,
      hasError = false,
      errorMessage = "",
      type = "text",
      disabled = false,
      labelDirection = LABEL_POSITIONS.FROM_LEFT,
      icon = null,
      iconPosition = ICON_POSITION.RIGHT,
      handleInputChange = () => {},
      handleFocus = () => {},
      handleBlur = () => {},
      variant = INPUT_VARIANT.DARK,
      titleMode = false,
      maxLength,
      ...props
    },
    ref
  ) => {
    const { whiteTheme } = useTheme();
    const getLabel = () => {
      if (!label) return <Label htmlFor={id} text={id} visuallyHidden />;
      return React.isValidElement(label) ? (
        label
      ) : (
        <Label marginBottom="0" htmlFor={id} text={label} />
      );
    };

    const input = (
      <S.InputContainer
        {...props}
        labelDirection={labelDirection}
        hasLabel={label}
      >
        {getLabel()}
        <Translator>
          {({ translate }) => {
            return (
              <S.AlignContainer>
                <S.StyledInput
                  hasError={hasError}
                  id={id}
                  ref={ref}
                  type={type}
                  name={name}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  value={value}
                  placeholder={translate(placeholder)}
                  onBlur={handleBlur ? event => handleBlur(event) : null}
                  onFocus={handleFocus ? event => handleFocus(event) : null}
                  onChange={
                    handleInputChange ? event => handleInputChange(event) : null
                  }
                  variant={variant}
                  titleMode={titleMode}
                  maxLength={maxLength}
                />
                {hasError && errorMessage && (
                  <SpecificError
                    theme={whiteTheme}
                    text={<Translate text={errorMessage} />}
                  />
                )}
              </S.AlignContainer>
            );
          }}
        </Translator>
      </S.InputContainer>
    );

    return icon ? (
      <S.IconWrapper iconPosition={iconPosition}>
        {input}
        <S.Icon
          variant={variant}
          iconPosition={iconPosition}
          hasError={hasError}
          errorMessage={errorMessage}
        >
          {icon}
        </S.Icon>
      </S.IconWrapper>
    ) : (
      input
    );
  }
);

Input.displayName = "Input";

/* eslint-disable react/require-default-props */
Input.propTypes = {
  id: string.isRequired,
  labelDirection: oneOf(Object.values(LABEL_POSITIONS))
};

export default Input;
