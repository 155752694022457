import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import colors from "@ds_themes/colors";

const __DOT_SIZE__ = 32;
const __MARGIN_BOTTOM__ = 16;
const __DOT_SPACING__ = 12;

type DotItemType = {
  isCompleted: boolean;
  isActive: boolean;
  hasError?: boolean;
};

const handleDotColour = (itemProps: DotItemType) => {
  const { hasError, isActive, isCompleted } = itemProps;
  if (hasError && isActive) {
    return colors.error[500];
  } else if (isCompleted || isActive) {
    return colors.violet[501];
  }
  return colors.blueGrey[700];
};

export const Box = styled.div`
  width: 100%;
`;

export const DotContentWrapper = styled(motion.div)`
  width: ${__DOT_SIZE__}px;
  height: ${__DOT_SIZE__}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const Dot = styled(motion.div)<DotItemType>`
  width: ${__DOT_SIZE__}px;
  height: ${__DOT_SIZE__}px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive, isCompleted, hasError }) =>
    handleDotColour({ isActive, hasError, isCompleted })};
  margin-right: ${__DOT_SPACING__}px;
  color: ${colors.grey[1000]};
  overflow: hidden;
  transition: 350ms ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

export const DropdownHeader = styled(motion.div)<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: ${__MARGIN_BOTTOM__}px;
    &::before {
      position: absolute;
      content: "";
      left: ${__DOT_SIZE__ / 2}px;
      width: 1px;
      border-radius: 16px;
      transform: translateX(-50%);
      background-color: ${({ active }) =>
        active ? colors.violet[501] : colors.blueGrey[700]};
      ${({ active }) =>
        active
          ? css`
              top: ${__DOT_SIZE__}px;
              bottom: -${__MARGIN_BOTTOM__}px;
            `
          : css`
              top: ${__DOT_SIZE__ + 4}px;
              bottom: ${4 - __MARGIN_BOTTOM__}px;
              @media screen and (max-width: 768px) {
                top: calc(100% + 4px);
              }
            `}
    }
  }
`;

export const TextWrapper = styled(motion.div)<{ active?: boolean }>`
  color: ${({ active }) => (active ? colors.grey[50] : colors.blueGrey[700])};
`;

export const DropdownContent = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const LineSpacer = styled.div`
  width: ${__DOT_SIZE__}px;
  margin-right: ${__DOT_SPACING__}px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
