import React from "react";
import { Box, CheckIndicator } from "@arcadia/design-system";
import { HTMLMotionProps } from "framer-motion";
import { useTheme } from "styled-components";
import { getCurrentLanguage } from "src/js/modules/localization";
import { getLocalizedUniversityName } from "src/js/modules/universityFunction";
import { RoundImage } from "src/js/components/global/RoundImage";
import { __SPACE_BADGE_SCHOOL_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { ROUND_ICON_TYPE } from "src/js/components/global/RoundImage/RoundImage.const";

import { University } from "../../UniversityFinderPage.types";

import * as S from "./UniversityRadioButton.styles";

export type UniversityRadioButtonProps = {
  university: University;
  isSelected: boolean;
} & HTMLMotionProps<"div">;

const UniversityRadioButton = ({
  university,
  isSelected,
  ...rest
}: UniversityRadioButtonProps) => {
  const { whiteTheme } = useTheme();

  return (
    <S.Wrapper {...rest}>
      <Box marginRight={10}>
        <RoundImage
          isSelectable={false}
          src={university.Logos[0].value}
          placeholder={__SPACE_BADGE_SCHOOL_PLACEHOLDER__}
          type={ROUND_ICON_TYPE.UNLOGGED_UNIVERSITY_LIST_LOGO}
        />
      </Box>
      <S.UniversityName>
        {getLocalizedUniversityName(
          university,
          getCurrentLanguage().substring(0, 2)
        )}
      </S.UniversityName>
      <Box width="max-content" display="block" marginLeft="auto">
        <CheckIndicator isSelected={isSelected} theme={whiteTheme} />
      </Box>
    </S.Wrapper>
  );
};

export default UniversityRadioButton;
