import { TranslationKeys } from "src/js/translation";

export const __EFFORT_STEP_OPTIONS__: {
  id: string;
  label: TranslationKeys;
}[] = [
  {
    id: "1",
    label: "effort_dropdown_1_hours"
  },
  {
    id: "2",
    label: "effort_dropdown_2_hours"
  },
  {
    id: "3",
    label: "effort_dropdown_3_hours"
  },
  {
    id: "4",
    label: "effort_dropdown_4_hours"
  }
];
