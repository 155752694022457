import React from "react";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Button } from "@arcadia/design-system";
import { SHeaderSubtitle } from "../../typography/SHeaderSubtitle";
import { SHeaderTitle } from "../../typography/SHeaderTitle";
import { ModalHeader } from "../ModalHeader";
import { ModalScrollableBody } from "../ModalScrollableBody";
import * as S from "./CancelUploadModal.styles";

const CancelUploadModal = ({
  successCallback = () => {},
  closeModal = () => {}
}) => {
  const { whiteTheme } = useTheme();
  return (
    <>
      <ModalHeader closeModal={closeModal}>
        <Translate text="cancel_upload_modal_title" />
      </ModalHeader>
      <ModalScrollableBody maxHeight={300} maxHeightOffset={240}>
        <S.ModalContentTitle>
          <SHeaderTitle>
            <Translate text="choice_confirmation_question" />
          </SHeaderTitle>
        </S.ModalContentTitle>
        <S.ModalContentSubtitle>
          <SHeaderSubtitle>
            <Translate text="upload_is_not_ended_yet" />
          </SHeaderSubtitle>
        </S.ModalContentSubtitle>
      </ModalScrollableBody>
      <S.ModalFooterContainer>
        <S.ButtonContainer>
          <Button
            onClick={closeModal}
            variant="secondary"
            theme={whiteTheme}
            fullWidth={getBreakpoint() === "smartphone"}
          >
            <Translate text="undo_button_tooltip" />
          </Button>
          <Button
            onClick={successCallback}
            variant="primary"
            theme={whiteTheme}
            fullWidth={getBreakpoint() === "smartphone"}
          >
            <Translate text="cancel_upload" />
          </Button>
        </S.ButtonContainer>
      </S.ModalFooterContainer>
    </>
  );
};

export default CancelUploadModal;
