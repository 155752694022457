import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState
} from "react";

const ResourcePickerErrorContext = createContext<{
  setErrors: (er: string[]) => void;
  errors: string[];
}>({ setErrors: () => {}, errors: [] });

export const useResourcePickerErrors = () =>
  useContext(ResourcePickerErrorContext);

export const ResourcePickerErrorProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [err, setErr] = useState<string[]>([]);

  const setErrors = useCallback(
    (er: string[]) => {
      setErr(er);
    },
    [setErr]
  );

  const contextValue = {
    setErrors,
    errors: err
  };

  return (
    <ResourcePickerErrorContext.Provider value={contextValue}>
      {children}
    </ResourcePickerErrorContext.Provider>
  );
};
