import { Text } from "@arcadia/design-system";
import { ListSelectorItem } from "src/js/components/global/ListSelector";
import styled, { css } from "styled-components";

export const CheckIndicator = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor[500]};
  ${({ isSelected }) =>
    !isSelected &&
    css`
      path,
      circle {
        display: none;
      }
    `}
`;

export const Description = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImportModuleItemCardContent = styled.div`
  width: 100%;
  padding: 0 12px;
  overflow: hidden;
  span {
    display: block;
  }
`;

export const ImportModuleItemCardWrapper = styled(ListSelectorItem)`
  padding: 16px;
  margin-bottom: 12px;
`;
