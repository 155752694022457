import styled, { css } from "styled-components";
import { Text } from "@ds_universal/Text";

import {
  SCardActivityProps,
  SCardActivityRightIcon
} from "./CardActivity.types";

export const RightIcon = styled.div<SCardActivityRightIcon>`
  position: absolute;
  top: 16px;
  right: 16px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.card.cardactivity.icon.background};
  color: ${({ theme }) => theme.card.cardactivity.icon.color};
`;

export const Title = styled(Text)`
  display: block;
  font-weight: 700;
  letter-spacing: 0.2px;
`;

export const Subtitle = styled(Text)`
  display: block;
  margin-top: 4px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.card.cardactivity.body.color};
`;

export const Wrapper = styled.div``;

export const Content = styled.div`
  text-align: left;
  flex: 1;
  & > svg {
    width: auto;
    margin-bottom: 8px;
  }
`;

export const CardActivity = styled.button<SCardActivityProps>`
  position: relative;
  width: 321px;
  height: 151px;
  display: flex;
  align-items: center;
  border: ${({ theme }) => theme.card.cardactivity.border};
  background-color: ${({ theme }) => theme.card.cardactivity.background};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0px 16px 0px 20px;
  cursor: pointer;
  &:hover {
    border: ${({ theme }) => theme.card.cardactivity.checked.border};
  }
  ${({ size }) =>
    size === "small" &&
    css`
      height: 92px;
      ${RightIcon} {
        position: initial;
      }
    `}
`;
