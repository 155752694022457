import {
  AvatarWithInitials,
  Box,
  Checkbox,
  Divider,
  DoodleLoader,
  Heading,
  Icon,
  Text
} from "@arcadia/design-system";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { GroupInSpaceModel, SpaceModel } from "src/js/types";
import { useTheme } from "styled-components";
import { useSpaceGroups } from "../hooks";
import { DesktopPreferencePicker } from "./DesktopPreferencePicker";
import { GroupList } from "./GroupList";
import { MobilePreferencePicker } from "./MobilePreferencePicker";
import * as S from "./SpaceCard.styles";

const SpaceCard = ({
  name,
  badge,
  defaultBadgeColor,
  id,
  searchedGroups
}: SpaceModel & { searchedGroups?: GroupInSpaceModel[] }) => {
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const [isExpanded, setExpanded] = useState(false);
  const [isAllGroupsSelected, setIsAllGroupsSelected] = useState(false);
  const {
    groupList,
    fetchNextPage,
    isLoading,
    fetch,
    setGroupList,
    hasMoreResults,
    notificationsPreference: { appPreference, emailPreference },
    updateAllGroups,
    updateSingleGroupPreference
  } = useSpaceGroups();
  const { translate } = useTranslation();

  useEffect(() => {
    if (!searchedGroups) {
      setGroupList([]);
      setExpanded(false);
      return;
    }
    setGroupList(searchedGroups);
    setExpanded(true);
  }, [searchedGroups]);

  useEffect(() => {
    if (!isExpanded) {
      setIsAllGroupsSelected(false);
    }
    if (searchedGroups || groupList.length || !isExpanded) return;
    fetch(0, { limit: 10, offset: 0, spaceId: id });
  }, [isExpanded]);

  const content = useMemo(() => {
    if (!isExpanded) return null;
    if (isLoading && !searchedGroups) {
      return (
        <Box
          height="160px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <DoodleLoader theme={whiteTheme} />
        </Box>
      );
    }
    if (!groupList.length && !isLoading) {
      return (
        <>
          <Divider theme={whiteTheme} color="grey" width="calc(100% - 40px)" />
          <Box padding="24px 20px 20px ">
            <Heading level="6">
              {translate("user_notice_settings_groups_title")}
            </Heading>
            <S.EmptySpaceWrapper>
              <S.NoGroupImage />
              <Heading level="5">
                {translate("user_notice_settings_no_groups_title")}
              </Heading>
              <Text type="body">
                {translate("user_notice_settings_no_groups_text")}
              </Text>
            </S.EmptySpaceWrapper>
          </Box>
        </>
      );
    }

    return (
      <>
        <Divider theme={whiteTheme} color="grey" width="calc(100% - 40px)" />
        {groupList.length > 1 ? (
          <S.AllGroupsItem>
            <S.AllGroupsCheckboxWrap>
              <Checkbox
                theme={whiteTheme}
                variant="black-and-white"
                checked={isAllGroupsSelected}
                onChange={() => setIsAllGroupsSelected(!isAllGroupsSelected)}
              />
            </S.AllGroupsCheckboxWrap>
            <S.AllGroupsCheckboxBtn
              onClick={() => setIsAllGroupsSelected(!isAllGroupsSelected)}
            >
              <Heading level="6">
                {translate("user_notice_all_groups_checkbox_label")}
              </Heading>
            </S.AllGroupsCheckboxBtn>
            <S.OptionsWrapper onClick={e => e.stopPropagation()}>
              {isLayoutModeMobile ? (
                <MobilePreferencePicker
                  disabled={!isAllGroupsSelected}
                  emailPreference={emailPreference}
                  appPreference={appPreference}
                  onSelectEmail={newPreference => {
                    updateAllGroups({
                      spaceId: id,
                      emailPreference: newPreference
                    });
                  }}
                  onSelectApp={newPreference => {
                    updateAllGroups({
                      spaceId: id,
                      appPreference: newPreference
                    });
                  }}
                />
              ) : (
                <DesktopPreferencePicker
                  disabled={!isAllGroupsSelected}
                  emailPreference={emailPreference}
                  appPreference={appPreference}
                  onSelectEmail={newPreference => {
                    updateAllGroups({
                      spaceId: id,
                      emailPreference: newPreference
                    });
                  }}
                  onSelectApp={newPreference => {
                    updateAllGroups({
                      spaceId: id,
                      appPreference: newPreference
                    });
                  }}
                />
              )}
            </S.OptionsWrapper>
          </S.AllGroupsItem>
        ) : null}
        <GroupList
          groupList={groupList}
          fetchNextPage={fetchNextPage}
          hasMoreResults={hasMoreResults}
          updateSingleGroupPreference={updateSingleGroupPreference}
          spaceId={id}
          disabled={isAllGroupsSelected}
        />
      </>
    );
  }, [isExpanded, groupList, isLoading, searchedGroups, isAllGroupsSelected]);

  return (
    <S.ExpandableContainer>
      <S.SpaceCardWrapper
        onClick={() => {
          setExpanded(!isExpanded);
        }}
      >
        <Icon icon={isExpanded ? "chevronLargeUp" : "chevronLargeDown"} />
        <S.AvatarContainer>
          <AvatarWithInitials
            theme={whiteTheme}
            alt={name}
            url={badge?.small as string | undefined}
            name={name || ""}
            avatarColor={defaultBadgeColor}
            size="auto"
          />
        </S.AvatarContainer>
        <S.EllipsedTitle level="5">{name}</S.EllipsedTitle>
      </S.SpaceCardWrapper>
      {content}
    </S.ExpandableContainer>
  );
};

export default SpaceCard;
