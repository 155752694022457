import styled from "styled-components";
import colors from "@ds_themes/colors";

import { SLinkButtonProps } from "./LinkButton.types";

export const LinkButtonWrap = styled.button<SLinkButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 20px;
  flex-direction: ${({ icon = {} }) =>
    icon.positionIcon === "left" ? "row-reverse" : "row"};
  gap: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.violet[500]};
  &:hover {
    color: ${colors.violet[400]};
    text-decoration: none;
  }
`;
