import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { Box, Text } from "@arcadia/design-system";
import Tooltip from "rc-tooltip";
import { TranslationKeys } from "../translation";

/**
 * The style of this components reside into "tooltip.scss" file
 */

type AlignType =
  | "left"
  | "right"
  | "top"
  | "bottom"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "rightTop"
  | "rightBottom"
  | "leftTop"
  | "leftBottom";

type TranslatedTooltipProps = {
  placement?: AlignType;
  tooltipString?: TranslationKeys;
  customTooltip?: React.ReactNode;
  children: JSX.Element;
  forceHide?: boolean;
  onVisibleChange?: (visible: boolean) => void;
};

const TranslatedTooltip = ({
  placement = "bottom",
  tooltipString,
  customTooltip,
  children,
  forceHide,
  onVisibleChange
}: TranslatedTooltipProps) => {
  const { translate } = useTranslation();

  return (
    <Tooltip
      placement={placement}
      overlay={
        customTooltip || (
          <Box padding="8px 16px">
            <Text type="formField">{translate(tooltipString)}</Text>
          </Box>
        )
      }
      {...(forceHide && { visible: false })}
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
      mouseEnterDelay={0.3}
    >
      {children}
    </Tooltip>
  );
};

export default TranslatedTooltip;
