import axios from "axios";
import { api_youtube_search } from "src/js/repository/apiUrls";
import { __GOOGLE_API_KEY__ } from "src/js/settings/settingsGeneral";

// eslint-disable-next-line import/prefer-default-export
export const fetchYoutubeSearchResult = (
  q,
  pageToken = "",
  type = "video",
  part = "snippet",
  maxResults = 12,
  key = __GOOGLE_API_KEY__
) => {
  const params = { q, type, part, maxResults, key, pageToken };
  return axios
    .get(api_youtube_search, { params })
    .then(({ data }) => {
      return data;
    })
    .catch(err => console.log("err", err));
};
