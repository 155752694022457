import React from "react";
import EmptyLayout from "src/js/layout/cleanLayout/EmptyLayout";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { Button } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const InvalidUrlRedirect = ({ pageTitle }) => {
  const { greyTheme } = useTheme();
  return (
    <EmptyLayout pageTitle={pageTitle}>
      <div className="margin-horizontal-default padding-header--no-white-header">
        <h2 className="text-center margin-top-60">
          <Translate text="invalid_url_error" />
        </h2>
        <div className="full-width flex-centered-column margin-top-60">
          <Button
            onClick={() => navigateTo("/home")}
            variant="primary"
            theme={greyTheme}
          >
            <Translate text="back-weschool" />
          </Button>
        </div>
      </div>
    </EmptyLayout>
  );
};

export default InvalidUrlRedirect;
