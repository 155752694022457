import { changeLocale } from "../repository/userRepository";
import { useTranslation } from "../translation";
import useStores from "./useStores";

type LanguageProps = {
  onSuccessCallback?: () => void;
  onErrorCallback?: (error) => void;
};

const useLanguageSwitch = ({
  onSuccessCallback,
  onErrorCallback
}: LanguageProps) => {
  const {
    UserStore: { changeActiveUserLanguage }
  } = useStores();

  const { setLocale } = useTranslation();
  const changeLanguage = async (language: string) => {
    try {
      await setLocale(language);
      await changeLocale(language);
      await changeActiveUserLanguage(language);
      if (typeof onSuccessCallback === "function") onSuccessCallback();
    } catch (error) {
      if (typeof onErrorCallback === "function") onErrorCallback(error);
    }
  };

  return { changeLanguage };
};

export default useLanguageSwitch;
