import { BoardElementDetail } from "src/js/types";
import { UseQueryOptions, useQuery } from "src/js/query/hooks";
import { fetchBoardElement } from "src/js/repository/boardRepository";
import { ErrorResponse } from "src/js/types/models/ErrorReponse";
import { boardElementsQueryKey } from "./boardElements.query";

export const useBoardElementDetails = (
  resourceId: string,
  options?: UseQueryOptions<
    BoardElementDetail,
    ErrorResponse,
    BoardElementDetail
  >
) => {
  const { data: boardElement, ...otherData } = useQuery(
    boardElementsQueryKey.boardElementDetails({ resourceId }),
    () => fetchBoardElement(resourceId),
    options
  );

  return {
    data: boardElement,
    ...otherData
  };
};
