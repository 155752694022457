import Backbone from "backbone";
import QuizTrueFalseModel from "../models/quiz/quizModel_truefalse";
import QuizShortAnswerModel from "../models/quiz/quizModel_shortanswer";
import QuizOpenAnswerModel from "../models/quiz/quizModel_openanswer";
import QuizMultiChoiceModel from "../models/quiz/quizModel_multichoice";
import QuizCrosswordsModel from "../models/quiz/quizModel_crosswords";
import QuizVideoModel from "../models/quiz/quizModel_video";
import QuizFillblanksModel from "../models/quiz/quizModel_fillblanks";
import QuizMatchingModel from "../models/quiz/quizModel_matching";
import QuizFadingMatchesModel from "../models/quiz/quizModel_matching_fading";
import QuizDocumentAnswerModel from "../models/quiz/quizModel_documentanswer";
import QuizModel from "../models/quiz/quizModel";

const QuizCollection = Backbone.Collection.extend({
  model(attrs, options) {
    switch (attrs.dtype) {
      case "quiztruefalse":
        return new QuizTrueFalseModel(attrs, options);

      case "quizshortanswer":
        return new QuizShortAnswerModel(attrs, options);

      case "quizopenanswer":
        return new QuizOpenAnswerModel(attrs, options);

      case "quizmultichoice":
        return new QuizMultiChoiceModel(attrs, options);

      case "quizcrosswords":
        return new QuizCrosswordsModel(attrs, options);

      case "quizvideo":
        return new QuizVideoModel(attrs, options);

      case "quizfillblank":
        return new QuizFillblanksModel(attrs, options);

      case "quizmatching":
        return new QuizMatchingModel(attrs, options);

      case "quizfadingmatching":
        return new QuizFadingMatchesModel(attrs, options);

      case "quizdocumentanswer":
        return new QuizDocumentAnswerModel(attrs, options);

      default:
        return new QuizModel(attrs, options);
    }
  },
  comparator: "position"
});

export default QuizCollection;
