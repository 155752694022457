import { ObjectValues } from "src/js/types";
import { Post, PostDetails } from "src/js/types/models/Post";

export const POST_CONTEXT = {
  WALL: "wall",
  FULL_POST: "full_post",
  MODAL: "modal"
} as const;

export type ContextType = ObjectValues<typeof POST_CONTEXT>;

export type PostProps = {
  pinned?: boolean;
  postFull: Post;
  setPinnedFunc?: (post: PostDetails) => void;
  onDeletePost?: (postId: number, groupId: number) => void;
  embedded?: boolean;
  context?: ContextType;
};
