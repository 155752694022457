import styled from "styled-components";
import { NoNotifications as NoNotif } from "src/js/components/notification/NotificationDrawer/components/NoNotifications";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { VariantType, scrollbarMixin } from "@arcadia/design-system";

export const StyledMainContainer = styled(MainContainer)`
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const NotificationPageWrapper = styled.div<{
  isLayoutModeMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  overflow: hidden;
  padding: ${({ isLayoutModeMobile }) => !isLayoutModeMobile && "0px 16px"};
`;

export const TopSection = styled.div<{ isLayoutModeMobile: boolean }>`
  padding: ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile ? "0px 16px" : "0px"};
`;

export const SpaceInfo = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 20px;
  min-height: 40px;
  align-items: center;
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  ${scrollbarMixin(VariantType.White)}
`;

export const NoNotifications = styled(NoNotif)`
  padding: 144px 0px;

  @media (max-width: 767px) {
    padding: 100px 0px;
  }
`;

export const SettingsMobileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 20px;
  height: 20px;
`;
