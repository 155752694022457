import styled from "styled-components";

export const CropperContainer = styled.div`
  position: relative;
  height: 324px;
`;

export const ZoomIconContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

export const ZoomBarContainer = styled.div`
  border-radius: 10px;
  width: 270px;
  height: 4px;
  position: relative;
  background-color: ${({ theme }) => theme.primaryColor[700]};
`;

export const ZoomBar = styled.div<{ width: number }>`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  position: absolute;
  width: ${({ width }) => `${width}%`};
  height: 100%;
  border-radius: 10px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  user-select: none;
`;

export const AlertContainer = styled.div`
  margin: 16px 16px 24px 16px;

  & > div:first-child {
    max-width: inherit;
  }
`;
