import { Box } from "@arcadia/design-system";
import React, { useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useTheme } from "styled-components";
import { CustomLegend } from "../CustomLegend";
import { LegendItem } from "../CustomLegend/CustomLegend";
import { CustomTooltip } from "../CustomTooltip";

export type HorizontalStackedBarChartData = {
  name: string;
  tier0: number;
  tier1: number;
  tier2: number;
  tier3: number;
  description: string[];
};

export type HorizontalStackedBarChartProps = {
  data: HorizontalStackedBarChartData[];
  legend: LegendItem[];
  customTick: (props: any) => React.JSX.Element;
};

const HorizontalStackedBarChart = ({
  data,
  legend,
  customTick
}: HorizontalStackedBarChartProps) => {
  const { colors, primaryColor } = useTheme();
  const [yIndex, setYIndex] = useState<number | undefined | null>(null);
  const [xIndex, setXIndex] = useState<number | undefined | null>(null);

  const fillBarFunction = (x: number, y: number) => {
    if (xIndex === x && y === yIndex) {
      return primaryColor[(x + 1) * 200];
    }
    return primaryColor[(x + 1) * 200 + 100];
  };

  return (
    <Box width="100%" height={data?.length * 36 + 48}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 0, right: 0, bottom: -12, left: 38 }}
          style={{ stroke: colors.grey[1000], strokeWidth: 4 }}
          barCategoryGap={8}
          onMouseMove={state => {
            if (state.isTooltipActive) {
              setYIndex(state.activeTooltipIndex);
            } else {
              setYIndex(null);
              setXIndex(null);
            }
          }}
        >
          <Legend
            verticalAlign="top"
            iconType="rect"
            content={<CustomLegend legendItems={legend} marginBottom="8px" />}
          />
          <XAxis
            type="number"
            stroke={colors.grey[900]}
            fontSize={10}
            fontWeight={500}
            tick={{ fill: colors.grey[100] }}
            tickLine={false}
            axisLine={false}
            tickFormatter={value => `${value}%`}
          />
          <YAxis
            dataKey="name"
            type="category"
            stroke={colors.grey[100]}
            fontSize={10}
            fontWeight={500}
            tickLine={false}
            axisLine={false}
            tick={customTick}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            animationDuration={0}
            content={value => {
              const { active, payload, label } = value;
              if (
                active &&
                payload &&
                payload.length &&
                xIndex !== null &&
                xIndex !== undefined
              ) {
                return CustomTooltip({
                  title: label,
                  description: payload[0].payload?.description[xIndex],
                  color: primaryColor[(xIndex + 1) * 200 + 100]
                });
              }
              return null;
            }}
          />
          {[...Array.from({ length: 4 })].map((_, index) => (
            <Bar
              dataKey={`tier${index}`}
              key={`tier${index + 1}`}
              stackId="stack"
              barSize={24}
              radius={12}
              onMouseMove={() => setXIndex(index)}
            >
              {data.map((element, cellIndex) => (
                <Cell
                  key={`cell-${element.name}`}
                  fill={fillBarFunction(index, cellIndex)}
                />
              ))}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default HorizontalStackedBarChart;
