import React, { useState, useEffect } from "react";
import EmptyLayout from "src/js/layout/cleanLayout/EmptyLayout";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import ShareDestinationStep from "src/js/pages/App/SharePage/components/ShareDestinationStep";
import ShareInformationStep from "src/js/pages/App/SharePage/components/ShareInformationStep";
import { getUrlInfo } from "src/js/repository/shareRepository";
import { addBoardElement } from "src/js/repository/boardRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import LoaderWithText from "src/js/components/global/LoaderWithText";
import { useTheme } from "styled-components";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import { useAppLayout } from "src/js/layout/AppLayout";
import { ActionButton, Text, Heading, Box } from "@arcadia/design-system";

const SharePage = ({ location }) => {
  const { headerHeight } = useAppLayout();
  const [stepNumber, setStepNumber] = useState(0);
  const [stepTitle, setStepTitle] = useState("share_resource_information");
  const [resourceTitle, setResourceTitle] = useState();
  const [fileImage, setFileImage] = useState();
  const [previewImageUrl, setPreviewImageUrl] = useState();
  const { greyTheme } = useTheme();

  const myRegexp = /\?url=([^"]+)/gm;
  const match = myRegexp.exec(location.search);
  const url =
    match && match.length > 0 ? match[1].replace(/^%22+|%22+$/g, "") : null;

  useEffect(() => {
    if (!url) {
      showToastError({ str: <Translate text="invalid_url_error" /> });
      return;
    }
    getUrlInfo(url)
      .then(({ title, image }) => {
        setResourceTitle(title);
        setPreviewImageUrl(image);
        setStepNumber(1);
      })
      .catch(() => {
        showToastError({ str: <Translate text="invalid_url_error" /> });
      });
  }, [url]);

  const goToStep = (stepNumberValue, stepTitleValue) => {
    setStepNumber(stepNumberValue);
    setStepTitle(stepTitleValue);
  };

  const stepOneContinue = ({ title }) => {
    setResourceTitle(title);
    goToStep(2, "share_select_destination");
  };

  const attachContent = selectedBoard => {
    if (selectedBoard && url) {
      const regexUrl = url.replace(/^"+|"+$/g, ""); // regex for removing " at the beginning and end of the url
      addBoardElement({
        id: selectedBoard.id,
        url: regexUrl,
        title: resourceTitle,
        file: fileImage
      })
        .then(() => {
          showToastSuccess({
            str: <Translate text="risorsa aggiunta con successo" />
          });
          const boardUrl = createUrl("module_detail", {
            group_id: selectedBoard.group_id,
            module_id: selectedBoard.id
          });
          navigateTo(boardUrl);
        })
        .catch(() => {
          showToastError({ str: <Translate text="error_add_board_element" /> });
        });
    }
  };

  return (
    <EmptyLayout pageTitle="share_meta_title">
      <SecondaryBar
        position="sticky"
        top={headerHeight}
        leftSection={
          stepNumber === 2 && (
            <ActionButton
              variant="secondaryColored"
              icon="chevronLeft"
              theme={greyTheme}
              onClick={() => goToStep(1, "share_resource_information")}
            />
          )
        }
        middleSection={
          <Box display="flex" flexDirection="column" alignItems="center">
            <Heading level="5">
              <Translate text={stepTitle} />
            </Heading>
            <Text type="formSmallCaption">
              <Translate text="step" />
              &nbsp;{`${stepNumber}/${2}`}
            </Text>
          </Box>
        }
        rightSection={
          <Box display="flex" justifyContent="flex-end">
            <ActionButton
              variant="secondaryColored"
              icon="close"
              theme={greyTheme}
              onClick={() => navigateTo(createUrl("home"))}
            />
          </Box>
        }
      />
      {stepNumber === 0 ? (
        <div className="padding-header margin-horizontal-default">
          <div className="share__search-board-wrapper">
            <LoaderWithText
              showText="courtesy_share_text"
              fontSizeClass="font-32"
              theme={greyTheme}
            />
          </div>
        </div>
      ) : null}
      {stepNumber === 1 ? (
        <ShareInformationStep
          previewImageUrl={previewImageUrl}
          resourceTitle={resourceTitle}
          setFileImage={setFileImage}
          fileImage={fileImage}
          continueFunction={stepOneContinue}
        />
      ) : null}
      {stepNumber === 2 ? (
        <ShareDestinationStep attachResource={attachContent} />
      ) : null}
    </EmptyLayout>
  );
};

export default SharePage;
