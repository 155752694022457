/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import {
  editReportComment,
  fetchReportComments,
  fetchReportThreads,
  saveReportComment
} from "src/js/repository/reportRepository";

class ReportStore {
  $activeReportId = null;
  $commentList = [];
  $reportThreads = {};

  constructor({ userStore }) {
    this.userStore = userStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // actions
  setReportThreads = ({ thread = {} }) => {
    this.$reportThreads = thread;
  };

  setCommentList = ({ comments }) => {
    this.$commentList = comments;
  };

  addToCommentList = ({ comment }) => {
    this.$commentList.push(comment);
  };

  editCommentOnCommentList = ({ id, newText }) => {
    this.$commentList = this.$commentList.map(comment => {
      if (comment.comment_id === id) {
        return {
          ...comment,
          comment_text: newText
        };
      }
      return comment;
    });
  };

  initActiveReport = ({ exerciseId, executionId }) => {
    this.$activeReportId = executionId;
    fetchReportComments({ executionId }).then(({ comments }) =>
      this.setCommentList({ comments })
    );
    fetchReportThreads({ exerciseId, executionId }).then(thread => {
      // this endpoint res have [] if empty and {...} if there is any object
      if (thread.length === 0) {
        this.setReportThreads({ thread: {} });
      } else this.setReportThreads({ thread });
    });
  };

  addComment = ({ executionId, commentText }) => {
    saveReportComment({ executionId, commentText }).then(({ id }) => {
      const { activeUser } = this.userStore;
      this.addToCommentList({
        comment: {
          avatar: { small: activeUser.avatar?.small },
          comment_id: id,
          comment_text: commentText,
          created_at: Date.now(),
          name: activeUser.name,
          surname: activeUser.surname,
          user_id: activeUser.id
        }
      });
    });
  };

  editComment = ({ executionId, commentId, commentText }) => {
    editReportComment({
      executionId,
      commentId,
      commentText
    }).then(() => {
      this.editCommentOnCommentList({
        id: commentId,
        newText: commentText
      });
    });
  };

  appendToThread = ({ quizExecutionId, threadNewMessage }) => {
    if (this.$reportThreads[quizExecutionId]?.length) {
      const newThread = { ...this.$reportThreads };
      newThread[quizExecutionId].push(threadNewMessage);
      this.$reportThreads = newThread;
    } else {
      const newThread = { ...this.$reportThreads };
      newThread[quizExecutionId] = [threadNewMessage];
      this.$reportThreads = newThread;
    }
  };

  addThreadAutomaticMessage = ({ quizExecutionId, threadMessage = null }) => {
    const { activeUser } = this.userStore;
    const threadNewAutomaticMsg = {
      id: Math.random(),
      created_at: Date.now(),
      name: activeUser.name,
      surname: activeUser.surname,
      system_message: true,
      thread_message: threadMessage,
      user_id: activeUser.id,
      is_teacher: true
    };
    this.appendToThread({
      quizExecutionId,
      threadNewMessage: threadNewAutomaticMsg
    });
  };

  addThreadMessage = ({
    id,
    quizExecutionId,
    threadMessage = null,
    points = null,
    resourceId = null,
    availablePoints,
    messageCorrection
  }) => {
    const { activeUser } = this.userStore;
    const threadNewMessage = {
      created_at: Date.now(),
      id,
      is_teacher: messageCorrection,
      name: activeUser.name,
      surname: activeUser.surname,
      quiz_execution_points: points,
      quiz_available_points: availablePoints,
      thread_message: threadMessage,
      user_id: activeUser.id,
      resource_id: resourceId
    };

    this.appendToThread({ quizExecutionId, threadNewMessage });
  };

  editThreadCorrection = ({
    correctionId,
    quizExecutionId,
    correctionNew,
    pointsNew
  }) => {
    const allThreads = { ...this.$reportThreads };
    const singleThread = allThreads[quizExecutionId];
    const indexCorrection = singleThread.findIndex(
      message => message.id === correctionId
    );

    singleThread[indexCorrection].thread_message = correctionNew;
    singleThread[indexCorrection].quiz_execution_points = pointsNew;
    allThreads[quizExecutionId] = singleThread;

    this.setReportThreads({ thread: allThreads });
  };

  deleteThreadCorrection = ({ correctionId, quizExecutionId }) => {
    const allThreads = { ...this.$reportThreads };
    const singleThread = allThreads[quizExecutionId];
    const editedThread = singleThread.filter(message => {
      return message.id !== correctionId;
    });
    allThreads[quizExecutionId] = editedThread;

    this.setReportThreads({ thread: allThreads });
  };

  resetReport = () => {
    this.setReportThreads({ thread: {} });
    this.setCommentList({ comments: [] });
    this.$activeReportId = null;
  };

  get commentList() {
    return this.$commentList;
  }

  get activeReportId() {
    return this.$activeReportId;
  }

  get numberOfComments() {
    return this.$commentList.length;
  }

  get reportThreads() {
    return this.$reportThreads;
  }
}

export default ReportStore;
