import { colors } from "@arcadia/design-system";

export const agenda = {
  background: colors.grey[1000],
  border: colors.blueGrey[800],
  text: {
    color: colors.grey[50]
  },
  eventCard: {
    highlighted: {
      border: colors.violet[500]
    },
    onGoing: {
      border: colors.grey[50]
    },
    border: colors.grey[800],
    background: colors.grey[1000],
    text: {
      color: colors.grey[50]
    },
    clockIcon: {
      color: colors.violet[500]
    }
  },
  dayContainer: {
    today: {
      border: colors.violet[500],
      background: colors.violet[500]
    },
    border: colors.blueGrey[800]
  },
  card: {
    activity: {
      color: colors.grey[1000],
      background: colors.lagoon[400],
      finished: {
        background: colors.lagoon[800]
      }
    },
    deadline: {
      color: colors.grey[1000],
      background: colors.warning[500],
      finished: {
        background: colors.warning[700]
      }
    }
  }
};
