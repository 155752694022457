import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { LiveDuration, Text } from "@arcadia/design-system";
import { DefaultTheme } from "styled-components";

const DurationCounterBadge = ({
  duration,
  theme
}: {
  duration: number;
  theme: DefaultTheme;
}) => {
  const { translate } = useTranslation();
  if (!duration || duration < 1) return null;
  const roundedHours = Math.floor(duration / 60);
  const roundedMinutes = duration;

  const durationText =
    roundedHours > 0 ? (
      <Text type="captionInfoDetails">
        {roundedHours < 24 ? roundedHours : "+24"}
        &nbsp;
        {translate(roundedHours > 1 ? "hours" : "hour")}
      </Text>
    ) : (
      <Text type="captionInfoDetails">
        {roundedMinutes}
        &nbsp;
        {translate(roundedMinutes > 1 ? "minutes" : "minute")}
      </Text>
    );

  return <LiveDuration theme={theme} duration={durationText} />;
};

export default DurationCounterBadge;
