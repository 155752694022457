import { Heading, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ModalBody = styled.div`
  padding: 42px 78px;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 34px;
  display: flex;
  flex-flow: column;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const IllustrationContainer = styled.div`
  margin-bottom: 12px;
`;

export const LogoutText = styled(Heading)`
  max-width: 215px;
  text-align: center;
`;

export const Cancel = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;
`;
