import React, { forwardRef } from "react";
import { ModulesResourceItem } from "src/js/components/modules/ModuleResourceItem";
import { format } from "date-fns";
import { ModuleResourceType, ResourceType } from "src/js/types";
import { getResourceImage } from "src/js/modules/resourceFunction";

type DraggedResourceType = {
  resource: ModuleResourceType;
  moduleId: number;
};

const DraggedResource = forwardRef<HTMLDivElement, DraggedResourceType>(
  ({ resource, moduleId }, ref) => {
    const resourceImage = getResourceImage(
      resource.resource.attributes || resource.resource
    );
    const resourceName = resource.name || resource.resource.name || "";
    const resourceUser = resource.user || resource.resource.attributes;

    return (
      <div ref={ref}>
        <ModulesResourceItem
          key={`moduleItem${resource.id}`}
          resourceId={resource.id}
          moduleId={moduleId}
          title={resourceName}
          author={resourceUser}
          createdAt={format(
            new Date(resource?.created ? resource.created * 1000 : null),
            "d MMMM yyyy"
          )}
          isCompleted={resource.is_completed}
          resourcePoints={resource.assigned_score || 0}
          commentsCount={resource.comments_count}
          mimeType={resource.resource.mime_type}
          resourceImage={resourceImage}
          resourceType={resource.resource.type as ResourceType}
          onClick={() => {
            return true;
          }}
          isDragged
          isBoardElementEnabled
          hasPreviewGenerated={resource.resource.is_preview_generated}
          resourcePosition={resource.position}
        />
      </div>
    );
  }
);

DraggedResource.displayName = "Item";

export default DraggedResource;
