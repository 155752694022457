import {
  Box,
  Button,
  Divider,
  Input,
  RadioGroup,
  Text,
  Toggle
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import {
  fetchExerciseEntities,
  saveExerciseEntity,
  saveExerciseSettings
} from "src/js/repository/exerciseRepository";
import { useTranslation } from "src/js/translation";
import {
  CorrectionSide,
  CorrectionType,
  OldExercise,
  ExerciseType
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import * as S from "./ExerciseModalSettings.styles";

const ExerciseModalSettings = ({
  exerciseId,
  closeModal
}: {
  exerciseId: string;
  closeModal: () => void;
}) => {
  const [exercise, setExercise] = useState<OldExercise>();
  const { translate } = useTranslation();
  const {
    SpaceStore: {
      activeSpaceCustomization,
      hasDecimalGrading,
      isAdmin,
      activeSpaceSlug,
      activeSpaceId,
      activeSpace
    },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { assessmentsCompletionThreshold } = activeSpaceCustomization || {};
  const { whiteTheme, greyTheme } = useTheme();
  const customTheme = isLayoutModeMobile ? greyTheme : whiteTheme;
  const inputRef = useRef<HTMLInputElement>();
  const spaceAdminUuid = activeSpace?.spaceAdminUuid;

  const {
    shuffle = false,
    show_results: showResults = false,
    repeatable = true,
    time_threshold = 0
  } = exercise || {};
  const time = Math.round(time_threshold / 60);

  const radioGroupOptions = [
    {
      label: translate("exercise_settings_show_results"),
      value: "show"
    },
    {
      label: translate("exercise_settings_hide_results"),
      value: "hide"
    }
  ];

  const Navigation = (
    <S.StyledLinkBtn
      onClick={() => {
        closeModal();
        navigateTo(
          isAdmin
            ? createUrl("dashboard_settings", {
                space_id: activeSpaceId,
                option: "general"
              })
            : `${createUrl(isLayoutModeMobile ? "space_chats" : "chat_new", {
                space_slug: activeSpaceSlug
              })}?user_id=${spaceAdminUuid}`
        );
      }}
    />
  );

  useEffect(() => {
    fetchExerciseEntities({ exerciseId, report_by: "best" })
      .then(data => {
        setExercise(data);
      })
      .catch(() => {
        showToastError({
          str: translate("exercise_settings_fetch_error")
        });
        closeModal();
      });
  }, []);

  const handleSubmit = () => {
    saveExerciseSettings(exerciseId, {
      shuffle: exercise.shuffle,
      show_results: exercise.show_results
    }).catch(error => {
      showToastError({
        str: extractErrorMessage(error, "Attenzione, si è verificato un errore")
      });
    });
    saveExerciseEntity({ exerciseId, data: exercise })
      .then(() => {
        closeModal();
        showToastSuccess({
          str: translate("exercise_settings_save_success")
        });
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  };

  const handleRandomQuestion = event => {
    const { checked } = event.target;
    setExercise(prevValue => {
      return { ...prevValue, shuffle: checked };
    });
  };

  const handleShowResults = value => {
    setExercise(prevValue => {
      return { ...prevValue, show_results: value === "show" };
    });
  };

  const handleTimeThreshold = event => {
    const { value = 0 } = event.target;
    if (value && !Number(value) && Number(value) !== 0) return;
    setExercise(prevValue => {
      return {
        ...prevValue,
        time_threshold: Number(value) * 60
      };
    });
  };

  const handleRepeatable = event => {
    const { checked } = event.target;
    setExercise(prevValue => {
      return {
        ...prevValue,
        repeatable: checked,
        type: checked ? ExerciseType.ASSIGNMENT : ExerciseType.EXAMINATION,
        correction_type: checked ? CorrectionType.STEP : CorrectionType.BULK,
        correction_side: checked ? CorrectionSide.CLIENT : CorrectionSide.SERVER
      };
    });
  };

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate("exercise_settings_modal_title")
      }}
      mobileProps={{
        onConfirm: () => {
          handleSubmit();
        },
        onClose: closeModal,
        confirmLabel: translate("exercise_settings_modal_save_cta"),
        closeLabel: translate("exercise_settings_modal_cancel_cta")
      }}
    >
      <Box padding="20px">
        <Text type="subHeaderTitle">
          {translate("exercise_settings_modal_assessment_order_title")}
        </Text>
        <S.ToggleContainer>
          <Text type="body">
            {translate("exercise_settings_random_question_label")}
          </Text>
          <Toggle
            size="small"
            title={translate("exercise_settings_random_question_label")}
            theme={customTheme}
            checked={shuffle}
            onChange={handleRandomQuestion}
          />
        </S.ToggleContainer>
        <Divider theme={customTheme} color="grey" width="100%" />
        <Box marginTop="16px">
          <Text type="subHeaderTitle">
            {translate("exercise_vote_and_result_settings_title")}
          </Text>
        </Box>
        <Box marginTop="16px" marginBottom="16px">
          <RadioGroup
            theme={customTheme}
            options={radioGroupOptions}
            orientationOption="column"
            orientationLabel="left"
            onChangeFunction={handleShowResults}
            value={showResults ? "show" : "hide"}
            textType="body"
          />
        </Box>
        <Divider theme={customTheme} color="grey" width="100%" />
        <Box marginTop="16px">
          <Text type="subHeaderTitle">
            {translate("exercise_settings_modal_threshold_title")}
          </Text>
        </Box>
        <Box marginTop="12px">
          <Text type="body">
            {translate("exercise_settings_modal_threshold_description")}{" "}
            <S.HighlightedText>
              {hasDecimalGrading
                ? `${assessmentsCompletionThreshold / 10}/10`
                : `${assessmentsCompletionThreshold}%`}
            </S.HighlightedText>
          </Text>
        </Box>
        <S.SmallCaptionText type="formSmallCaption">
          <Trans
            i18nKey={
              isAdmin
                ? "exercise_settings_modal_threshold_admin_caption"
                : "exercise_settings_modal_threshold_teacher_caption"
            }
            components={{ Navigation }}
          />
        </S.SmallCaptionText>
        <Box marginTop="16px">
          <Divider theme={customTheme} color="grey" width="100%" />
        </Box>
        <Box marginTop="16px">
          <Text type="subHeaderTitle">
            {translate("exercise_settings_modal_time_title")}
          </Text>
        </Box>
        <Box marginTop="12px" marginBottom="16px" display="flex">
          <Box display="flex" flexDirection="column" flex="1">
            <Text type="body">
              {translate("exercise_settings_modal_time_description")}
            </Text>
            <S.SmallCaptionText type="formSmallCaption">
              {translate("exercise_settings_modal_time_caption")}
            </S.SmallCaptionText>
          </Box>
          <S.NumberInputContainer>
            <Input
              ref={inputRef}
              theme={customTheme}
              value={time as unknown as string}
              label=""
              hideLabel
              rounded
              onChange={handleTimeThreshold}
            />
            <Text type="body">
              {translate("exercise_settings_modal_time_measurement")}
            </Text>
          </S.NumberInputContainer>
        </Box>
        <Divider theme={customTheme} color="grey" width="100%" />
        <Box marginTop="16px">
          <Text type="subHeaderTitle">
            {translate("exercise_settings_modal_repeatable_title")}
          </Text>
        </Box>
        <S.ToggleContainer>
          <Text type="body">
            {translate("exercise_settings_modal_repeatable_description")}
          </Text>
          <Box width="60px">
            <Toggle
              size="small"
              title={translate(
                "exercise_settings_modal_repeatable_description"
              )}
              theme={customTheme}
              checked={repeatable}
              onChange={handleRepeatable}
            />
          </Box>
        </S.ToggleContainer>
        {!isLayoutModeMobile ? (
          <Box
            display="flex"
            justifyContent="center"
            gap="12px"
            marginTop="24px"
          >
            <Button
              type="button"
              onClick={closeModal}
              theme={whiteTheme}
              variant="secondary"
            >
              {translate("exercise_settings_modal_cancel_cta")}
            </Button>
            <Button
              type="button"
              onClick={handleSubmit}
              theme={whiteTheme}
              variant="primary"
            >
              {translate("exercise_settings_modal_save_cta")}
            </Button>
          </Box>
        ) : null}
      </Box>
    </ModalLayout>
  );
};

export default observer(ExerciseModalSettings);
