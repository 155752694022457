import { colors } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { createUrl } from "src/js/modules/routing";
import {
  SchoolClaimForm,
  getLabelsSchoolClaim
} from "src/js/pages/spaces/space_creation/SchoolClaimForm";
import { SchoolClaimFormStatus } from "src/js/pages/spaces/space_creation/SchoolClaimForm/SchoolClaimForm.types";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  EventDomain,
  SpaceCreationEvent,
  SpaceListModel,
  SpaceModel,
  SpaceUserRoleEnum
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { ModalLayout } from "../../modal/ModalLayout";
import { SpaceCreationModal } from "../SpaceCreationModal";
import * as S from "./SchoolClaimModal.styles";

const SchoolClaimModal = () => {
  const { trackVirtualView, trackView } = useViewTracking();
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal, setCustomCloseCb, customClose },
    UIStore: { isLayoutModeMobile },
    SpaceStore: { addNewSpaceToSpaceList }
  } = useStores();
  const formRef = useRef(null);
  const [formStatus, setFormStatus] = useState<SchoolClaimFormStatus>(
    SchoolClaimFormStatus.Selection
  );

  const initialStep = formStatus === SchoolClaimFormStatus.Selection;
  const progressPercentage = initialStep ? 50 : 100;
  const { title, subtitle } = getLabelsSchoolClaim(formStatus);

  useEffect(() => {
    // Same as SpaceCreationModal
    setCustomCloseCb(() => {
      trackView();
      closeModal();
    });
    return () => {
      setCustomCloseCb(null);
    };
  }, []);

  useEffect(() => {
    _trackEvent(
      EventDomain.SpaceCreation,
      SpaceCreationEvent.InAppSpaceCreationAccessPoint
    );
    trackVirtualView(
      WeSchoolVirtualViewDescriptions.Spaces.Create.SchoolSpaceCreationModal
    );
  }, []);

  const onSuccessCallback = (newSpace: SpaceModel) => {
    const spaceListModel: SpaceListModel = {
      space: newSpace,
      role: SpaceUserRoleEnum.Owner,
      unreadNotificationCount: 0,
      unreadThreadMessagesCount: 0
    };
    _trackEvent(
      EventDomain.SpaceCreation,
      SpaceCreationEvent.SchoolSpaceConfirmation
    );
    addNewSpaceToSpaceList(spaceListModel);
    closeModal();
    navigateTo(createUrl("space_groups", { space_slug: newSpace.slug }));
  };

  const openSpaceCreationModal = () => {
    openModal(() => <SpaceCreationModal />);
  };

  const onBack = () => {
    if (initialStep) {
      openSpaceCreationModal();
      return;
    }
    formRef?.current?.back();
  };

  return (
    <ModalLayout
      labels={{
        title: translate(title),
        mobileClose: translate({
          text: initialStep
            ? "school_claim_modal_cta_close"
            : "school_claim_modal_cta_back"
        }),
        subtitle: translate({
          text: "school_claim_modal_subtitle_mobile",
          stringVariables: { index: initialStep ? 1 : 2, total: 2 }
        })
      }}
      closeModal={customClose}
      progressPercentage={progressPercentage}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false,
        onClose: initialStep ? customClose : onBack
      }}
      desktopProps={{
        hasAdaptiveHeight: !initialStep,
        onBack
      }}
    >
      <S.SchoolClaimModal>
        {subtitle && (
          <S.SubtitleText type="body">{translate(subtitle)}</S.SubtitleText>
        )}
        <SchoolClaimForm
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          ref={formRef}
          onChangeStatus={status => setFormStatus(status)}
          onSuccessCallback={onSuccessCallback}
          backgroundColor={colors.grey[1000]}
          goToSpaceCreation={openSpaceCreationModal}
        />
      </S.SchoolClaimModal>
    </ModalLayout>
  );
};

export default observer(SchoolClaimModal);
