import React from "react";
import { groupShouldShowRanking } from "src/js/GroupFunction";
import { showToastError } from "src/js/modules/messageManager";
import { fetchPublicProfileScore } from "src/js/repository/publicProfileRepository";
import { Translator } from "src/js/translation/TranslationProvider";
import { getActiveGroupJson } from "Legacy/modules/activeGroup";

class UserPoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      score: null
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    if (getActiveGroupJson() && groupShouldShowRanking(getActiveGroupJson())) {
      // todo eliminare vecchia funzione get active group
      fetchPublicProfileScore(userId)
        .then(result => {
          this.setState({
            score: result.score
          });
        })
        .catch(error => {
          showToastError({ str: error });
        });
    }
  }

  render() {
    const { customClass } = this.props;
    const { score } = this.state;
    if (score == null) {
      return "";
    }
    return (
      <Translator>
        {({ translate }) => {
          return (
            <div
              className={`user-points ${customClass}`}
              title={translate("score_comparison_tooltip_you", {
                score
              })}
            >
              {score}
            </div>
          );
        }}
      </Translator>
    );
  }
}

export default UserPoints;
