import styled from "styled-components";

import { SCheckProps } from "./types";

export const CardContent = styled.button`
  width: 332px;
  height: 242px;
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.card.cardcontent.border};
  background-color: ${({ theme }) => theme.card.cardcontent.background};
  padding: 0px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  & > div > span {
    color: ${({ theme }) => theme.card.cardcontent.body};
  }
  & span {
    color: ${({ theme }) => theme.card.cardcontent.title};
  }
  &:focus-within {
    outline: ${({ theme }) => theme.card.cardcontent.focus.border};
  }
`;

export const WrapperIcon = styled.div`
  height: 175px;
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px 3px 0px 0px;
  background-color: ${({ theme }) =>
    theme.card.cardcontent.wrapperIcon.background};
  color: ${({ theme }) => theme.card.cardcontent.wrapperIcon.text};
  border-bottom: ${({ theme }) => theme.card.cardcontent.wrapperIcon.border};
`;

export const WrapperText = styled.div`
  background-color: ${({ theme }) =>
    theme.card.cardcontent.wrapperText.background};
  position: relative;
  height: 67px;
  padding-left: 20px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 3px;
  div {
    display: flex;
    align-items: center;
  }
`;

export const Check = styled.div<SCheckProps>`
  display: block;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme, viewed }) =>
    viewed
      ? theme.card.cardcontent.viewed.background
      : theme.card.cardcontent.notViewed.background};
  margin-right: 8px;
  svg {
    color: ${({ theme, viewed }) =>
      viewed
        ? theme.card.cardcontent.viewed.color
        : theme.card.cardcontent.notViewed.color};
  }
`;

export const Resource = styled.div`
  padding: 6px;
  border-radius: 4px;
  margin-left: 10px;
  background: ${({ theme }) => theme.card.cardcontent.resource.background};
  span {
    color: ${({ theme }) => theme.card.cardcontent.resource.text};
  }
`;

export const WrapperTextBody = styled.div`
  span {
    color: ${({ theme }) => theme.card.cardcontent.body};
  }
`;

export const WrapperComment = styled.div`
  span {
    color: ${({ theme }) => theme.card.cardcontent.comment.color};
  }
  svg {
    color: ${({ theme }) => theme.card.cardcontent.comment.color};
    margin-right: 4px;
  }
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0px 8px;
  background-color: ${({ theme }) => theme.card.cardcontent.comment.text};
`;
