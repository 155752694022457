import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { GlobalStyleHideNavigationBar } from "src/js/components/layout/ToolbarMobile/ToolbarMobile.styles";
import { useActiveBoard } from "src/js/context/BoardProvider";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ResourceEditor from "src/js/pages/resources/ResourceEditor";
import ModuleDetail from "../view/board/ModuleDetail";

const SingleModuleController = () => {
  const {
    GroupStore: { settings },
    GamificationStore: { fetchBoardResourcesScoreById }
  } = useStores();

  const activeBoard = useActiveBoard();

  if (activeBoard) {
    if (settings.gamification === "enabled") {
      fetchBoardResourcesScoreById(activeBoard.id);
    }
  }

  return activeBoard !== null ? (
    <Switch>
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.moduleMode}
        render={properties => (
          <>
            <GlobalStyleHideNavigationBar />
            <ModuleDetail target="view" {...properties} />
          </>
        )}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.boardId}
        render={props => {
          return (
            <Redirect
              to={createUrl("module_detail", {
                group_id: props.match.params.group_id,
                module_id: props.match.params.board_id
              })}
            />
          );
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.boardElementResource}
        render={props => {
          return (
            <Redirect
              to={createUrl("module_element", {
                group_id: props.match.params.group_id,
                module_id: props.match.params.board_id,
                resource_id: props.match.params.resource_id
              })}
            />
          );
        }}
      />
      <Route
        exact
        path={
          WeSchoolRoutes.App.Spaces.SpaceGroup.Boards
            .boardElementResourceComments
        }
        render={properties => {
          return (
            <>
              <GlobalStyleHideNavigationBar />
              <ModuleDetail
                elementToShow={properties.match.params.element_id}
                {...properties}
              />
            </>
          );
        }}
      />
      <Route
        exact
        path={
          WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.moduleElementComments
        }
        render={properties => {
          return (
            <>
              <GlobalStyleHideNavigationBar />
              <ModuleDetail
                elementToShow={properties.match.params.element_id}
                {...properties}
              />
            </>
          );
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.boardElementEdit}
        render={props => {
          return (
            <Redirect
              to={createUrl("module_element_edit", {
                group_id: props.match.params.group_id,
                module_id: props.match.params.board_id,
                board_element_id: props.match.params.board_element_id
              })}
            />
          );
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.moduleElementEdit}
        render={props => (
          <>
            <GlobalStyleHideNavigationBar />
            <ResourceEditor
              groupId={props.match.params.group_id}
              objId={props.match.params.board_element_id}
              contextId={props.match.params.module_id}
              target="board"
              isDraft={props.match.params.draft === "draft"}
            />
          </>
        )}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.moduleElement}
        render={properties => (
          <>
            <GlobalStyleHideNavigationBar />
            <ModuleDetail
              elementToShow={properties.match.params.element_id}
              {...properties}
            />
          </>
        )}
      />
      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  ) : (
    <div />
  );
};

export default observer(SingleModuleController);
