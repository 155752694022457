import { Box, Text, Toggle, Dropdown } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import useBadgeOptionalFields from "../useBadgeOptionalFields";
import { DurationPeriodType } from "../types";
import * as S from "../styles";

const BadgeDuration = ({
  durationActive,
  setDurationActive,
  selectedDurationOption,
  setDurationOption
}: {
  durationActive: boolean;
  setDurationActive: (duration: boolean) => void;
  selectedDurationOption: DurationPeriodType;
  setDurationOption: (option: DurationPeriodType) => void;
}) => {
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceBadgesStore: { setBadgeOptionalFields, badgeOptionalFields }
  } = useStores();
  const { durationPeriodOptions } = useBadgeOptionalFields();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={8}
      >
        <Text type="headerTitle">
          {translate("badge_duration_field_title")}
        </Text>
        <Toggle
          theme={whiteTheme}
          title={translate("badge_duration_field_title")}
          size="small"
          checked={durationActive}
          onChange={event => {
            const isChecked = event.target.checked;
            setDurationActive(isChecked);
            if (!isChecked) {
              setBadgeOptionalFields({
                ...badgeOptionalFields,
                duration: null
              });
              setDurationOption(null);
            }
          }}
        />
      </Box>
      <Box>
        <Box marginBottom={16}>
          <S.DescriptionText type="formSubtitle">
            {translate("badge_duration_field_description")}
          </S.DescriptionText>
        </Box>
        <Dropdown
          disabled={!durationActive}
          optionsList={durationPeriodOptions}
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          placeholder={translate("badge_duration_field_dropdown_placeholder")}
          selectedOptionId={selectedDurationOption}
          setSelectedOptionId={(id: DurationPeriodType) => {
            setDurationOption(id);
            setBadgeOptionalFields({ ...badgeOptionalFields, duration: id });
          }}
        />
      </Box>
    </Box>
  );
};

export default observer(BadgeDuration);
