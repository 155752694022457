import colors from "@ds_themes/colors";

export const toggle = {
  backgroundOn: colors.violet[501],
  backgroundOff: colors.coldGrey[900],
  sliderOn: colors.grey[1000],
  sliderOff: colors.violet[501],
  disabled: {
    background: colors.violet[700],
    sliderOn: colors.grey[1000],
    sliderOff: colors.violet[700]
  },
  hover: {
    background: colors.violet[400],
    slider: colors.grey[1000]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};
