import { CSSProperties, useTheme } from "styled-components";
import colors from "@ds_themes/colors";

import { CheckboxVariant } from "./types";

export const useGetCheckboxStyles = (
  checked: boolean,
  disabled = false,
  variant: CheckboxVariant = "default"
) => {
  const theme = useTheme();

  let backgroundColor = "";
  let border = "";

  switch (variant) {
    case "default":
      if (checked && !disabled) {
        backgroundColor = theme.checkbox.checked.background;
      } else if (checked && disabled) {
        backgroundColor = theme.checkbox.disabled.background;
      } else {
        backgroundColor = theme.checkbox.background;
      }

      if (disabled) {
        border = theme.checkbox.disabled.border;
      } else {
        border = theme.checkbox.border;
      }
      break;

    case "black-and-white":
      backgroundColor = colors.grey[1000];
      border = `1px solid ${disabled ? colors.grey[500] : colors.grey[50]}`;
      break;

    default:
      break;
  }

  return {
    backgroundColor,
    border
  };
};

export const useGetCheckboxContainerStyles = (
  checked: boolean,
  disabled = false,
  variant: CheckboxVariant = "default"
) => {
  const theme = useTheme();

  let checkedColor: CSSProperties["color"] = "";
  let hoverColor = "";
  let hoverBackground = "";
  let hoverBorder = "";

  switch (variant) {
    case "default":
      checkedColor = theme.checkbox.checked.text;
      hoverColor = checked && !disabled ? theme.checkbox.hover.text : "";
      hoverBackground =
        checked && !disabled ? theme.checkbox.hover.background : "";
      hoverBorder = disabled ? theme.checkbox.hover.border : "";
      break;

    case "black-and-white": {
      const disabledStateColor = disabled ? colors.grey[500] : colors.grey[50];

      checkedColor = disabledStateColor;
      hoverColor = disabledStateColor;
      hoverBackground = colors.grey[1000];
      hoverBorder = `1px solid ${disabledStateColor}`;
      break;
    }

    default:
      break;
  }

  return {
    checkedColor,
    hoverColor,
    hoverBackground,
    hoverBorder
  };
};

export const useGetHiddenCheckboxStyles = (
  variant: CheckboxVariant = "default"
) => {
  const theme = useTheme();

  let outline = "";

  switch (variant) {
    case "default":
      outline = theme.checkbox.focus.border;
      break;

    case "black-and-white":
      outline = `1px solid ${colors.grey[50]}`;
      break;

    default:
      break;
  }

  return {
    outline
  };
};
