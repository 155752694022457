import styled, { keyframes } from "styled-components";

const scroll = keyframes`
  0% { 
    transform: translateX(0);
  }
  50% { 
    transform: translateX(calc(-100% + 100vw));  
  }
  100% { 
    transform: translateX(0%);  
  }
`;

export const TabletAndUpWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding: 18px 20px;
  border-radius: 10px;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.primaryColor[800]};
`;

export const SelectableFilterContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
  flex-flow: wrap;
`;

export const RemoveAllButton = styled.span`
  align-self: center;
  > button {
    background-color: transparent;
  }
`;

export const MaxWidthWrapper = styled.div`
  margin: 0 auto;
  max-width: 1340px;
  display: flex;
  width: 100%;
`;

export const MobileWrapper = styled.div`
  margin: 20px -1rem;
  padding: 0px 1rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TagsCarousel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 8px -1rem 0px;
  gap: 8px;
  padding: 8px 16px;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.primaryColor[800]};
  & > div {
    white-space: nowrap;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const AutoScroll = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  animation: ${scroll} 2s ease;
  animation-delay: 2s;
  padding: 0px 0px 16px;
`;
