import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const UploadingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  height: 335px;

  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const UploadText = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  margin-bottom: 20px;
`;

export const ProgressBarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border-radius: 20px;
  width: 100%;
  padding: 4px;
`;

export const ProgressBar = styled.div<{ uploadingProgress: number }>`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  border-radius: 4px;
  height: 4px;
  width: ${({ uploadingProgress }) => `${uploadingProgress}%`};
  max-width: 100%;
`;

export const PercentageTextContainer = styled.div`
  width: 100%;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PercentageMovingText = styled(Text)<{ uploadingProgress: number }>`
  margin-right: ${({ uploadingProgress }) => `${100 - uploadingProgress}%`};
  color: ${({ theme }) => theme.primaryColor[500]};
`;
