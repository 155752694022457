import {
  Heading,
  Text,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyleTemplateDescription = createGlobalStyle`
  #template-description-portal {
    position: absolute;
    z-index: 9999;
  }
`;

export const TemplateDescriptionWrap = styled.div`
  position: relative;
  width: 486px;
  max-height: calc(90vh - 180px);
  overflow: auto;
  ${scrollbarMixin(VariantType.White)}
  @media (max-width: 767px) {
    display: block;
    padding: 16px 16px 80px;
    width: 100%;
    max-height: unset;
    ${scrollbarMixin(VariantType.White)}
  }
`;

export const CoverImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CoverWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};
  margin-bottom: 20px;
  min-height: 270px;
  height: 270px;
  border-radius: 12px;
  overflow: hidden;
  @media (max-width: 767px) {
    min-height: unset;
    height: unset;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.grey[900]};
    margin-bottom: 20px;
  }
`;

export const SectionWrapper = styled.div`
  overflow: hidden;
  padding: 24px 20px;
  width: 100%;
  min-height: 300px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: fit-content;
  @media (max-width: 767px) {
    background: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const PillsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const GreyHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.blueGrey[200]};
`;

export const GreyText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
