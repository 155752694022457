import { ObjectValues } from "src/js/types";

export type CreateEditBadgeModalProps = {
  isEditing?: boolean;
  badgeImageCroppedPreview?: string;
  customBadgeImage?: File;
};

export const CreateEditBadgeStep = {
  SELECT_IMAGE_STEP: 1,
  REQUIRED_FIELDS_STEP: 2,
  OPTIONAL_FIELDS_STEP: 3
} as const;

export type CreateEditBadgeStepType = ObjectValues<typeof CreateEditBadgeStep>;
export type SelectBadgeProps = {
  setNextDisabled: (isDisabled: boolean) => void;
  isEditing?: boolean;
  badgeImageCroppedPreview?: string;
};
