import styled from "styled-components";

export const BodyContainer = styled.div`
  padding: 0 24px;
  min-height: 200px;

  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

export const SearchContainer = styled.div`
  margin-top: 24px;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
`;

export const SubtitleLabel = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  text-align: center;
`;

export const DescriptionLabel = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const NoResult = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const NoUsersImage = styled.img`
  width: 100%;
  max-width: 360px;
  padding: 0 32px 20px;
`;

export const NoUserTitle = styled.div`
  margin-bottom: 8px;
`;

export const ModalFooterContainer = styled.div`
  padding: 24px 16px 16px;
  @media screen and (max-width: 767px) {
    padding-top: 16px;
  }
`;

export const ButtonContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    button {
      margin: 0 5px 10px;
    }
  }
`;

export const ListElementWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > span:first-of-type {
    max-width: 50%;
    display: inline-flex;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ListElementMail = styled.span`
  color: ${({ theme }) => theme.colors.grey[500]};
  margin: 0 10px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

export const Ellipsis = styled.span`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
