import { Icon } from "@arcadia/design-system";
import React, { FC } from "react";
import { CustomListElementProps } from "src/js/components/global/ListSelector";
import { useTranslation } from "src/js/translation";
import { ModuleType } from "../ImportModuleModal";
import * as S from "./ImportModuleItemCard.styles";

const ImportModuleItemCard: FC<CustomListElementProps<ModuleType>> = ({
  isActive,
  onClick,
  el
}) => {
  const { translate } = useTranslation();
  return (
    <S.ImportModuleItemCardWrapper onClick={onClick} isActive={isActive}>
      <S.ImportModuleItemCardContent>
        <S.Description>
          {translate("board_module_import_card_description", {
            groupName: el?.groupName
          })}
        </S.Description>
        <S.Title>{el?.name}</S.Title>
      </S.ImportModuleItemCardContent>
      <S.CheckIndicator isSelected={isActive}>
        <Icon icon="circleCheck" width={24} height={24} initialViewbox />
      </S.CheckIndicator>
    </S.ImportModuleItemCardWrapper>
  );
};

export default ImportModuleItemCard;
