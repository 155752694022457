const getRange = (from: number, to: number) => {
  let rangeArray: number[] = [];

  while (from <= to) {
    rangeArray = [...rangeArray, from];
    from += 1;
  }

  return rangeArray;
};

export const getPageNumbers = (currentPage: number, totalPages: number) => {
  const startPage = Math.max(1, currentPage - 1);
  const endPage = Math.min(totalPages, currentPage + 1);
  let pages = getRange(startPage, endPage);

  if (totalPages > 2) {
    const hasHiddenPageToLeft = startPage > 1;
    const hasHiddenPageToRight = totalPages - endPage > 0;
    const hiddenPageOffset = 3 - pages.length;

    if (hasHiddenPageToLeft && !hasHiddenPageToRight) {
      const extraPages = getRange(startPage - hiddenPageOffset, startPage - 1);
      pages = [...extraPages, ...pages];
    } else if (!hasHiddenPageToLeft && hasHiddenPageToRight) {
      const extraPages = getRange(endPage + 1, endPage + hiddenPageOffset);
      pages = [...pages, ...extraPages];
    }
  }
  return [...pages];
};
