import styled from "styled-components";

export const SortableList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 100%;
`;
