import React from "react";
import { ActionButton, Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { ModulesResourceDraftProps } from "src/js/components/modules/ModuleResourceDraftItem/types";
import * as S from "./ModulesResourceDraftItem.styles";

const ModulesResourceDraftItemMobile = ({
  title,
  id,
  author,
  createdAt,
  isExercise,
  resourceImage,
  rejectFunction,
  approveFunction,
  placeholder,
  onClick,
  roles,
  position
}: ModulesResourceDraftProps) => {
  const { whiteTheme } = useTheme();
  const titleMaxChar = 40;

  const isTeacher = roles.includes("ROLE_TEACHER");

  return (
    <S.ResourceContainerMobile id={id.toString()} onClick={onClick}>
      <S.ResourcePreviewMobile
        background={resourceImage}
        placeholder={placeholder}
        isExercise={isExercise}
      >
        {isExercise && (
          <S.ResourcePreviewExercise>
            <Icon icon="weight" height={40} width={40} />
          </S.ResourcePreviewExercise>
        )}
      </S.ResourcePreviewMobile>
      <S.ResourceInfoMobile>
        <Text type="body">
          {title.length > titleMaxChar
            ? `${title.substring(0, 80).trim()}...`
            : title}
        </Text>
        <S.ResourceInfoCreationMobile>
          <Text type="formSmallCaption">
            {`${author.name} ${author.surname}`} - {createdAt}
          </Text>
        </S.ResourceInfoCreationMobile>
      </S.ResourceInfoMobile>
      {isTeacher ? (
        <S.ResourceActionContainer>
          <ActionButton
            variant="primary"
            theme={whiteTheme}
            icon="check2"
            onClick={e => {
              e.stopPropagation();
              approveFunction(id, position);
            }}
          />
          <ActionButton
            variant="secondary"
            theme={whiteTheme}
            icon="close"
            onClick={e => {
              e.stopPropagation();
              rejectFunction(id);
            }}
          />
        </S.ResourceActionContainer>
      ) : undefined}
    </S.ResourceContainerMobile>
  );
};

export default ModulesResourceDraftItemMobile;
