import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";

import * as S from "./Toggle.styles";
import { ToggleProps } from "./types";

export const Toggle: FC<PropsWithChildren<ToggleProps>> = ({
  size,
  title,
  theme,
  checked = false,
  withIcon = false,
  disabled = false,
  onChange,
  ...props
}) => {
  const id = title.replace(/\s+/g, "") + size;
  return (
    <ThemeProvider theme={theme}>
      <S.Label size={size} title={title} htmlFor={id} disabled={disabled}>
        <S.Input
          id={id}
          value={title}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <S.Slider
          disabled={disabled}
          checked={checked}
          withIcon={withIcon}
          size={size}
        />
        {checked && withIcon ? (
          <Icon
            icon="eyeOpen"
            height={size === "big" ? "24px" : "18px"}
            width={size === "big" ? "24px" : "18px"}
            aria-hidden="true"
            focusable="false"
          />
        ) : (
          <Icon
            icon="eyeClosed"
            height={size === "big" ? "24px" : "18px"}
            width={size === "big" ? "24px" : "18px"}
            aria-hidden="true"
            focusable="false"
          />
        )}
      </S.Label>
    </ThemeProvider>
  );
};

export default Toggle;
