import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { DoodleLoader, Button } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { __DROPBOX_APP_ID__ } from "src/js/settings/settingsResource";
import { __UPLOAD_SIZE_LIMIT__ } from "../../../settings/settings";
import ResourceModel from "../../../models/resourceModel";
import { __ } from "../../../modules/localization";

const ResourceDropbox = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          context: ""
        };
      },
      getInitialState() {
        this.props.setWorking(false);
        return { loading: true };
      },
      componentWillMount() {
        const self = this;
        const id = "dropboxjs";
        if (document.getElementById(id) === null) {
          const fileref = document.createElement("script");
          fileref.setAttribute("type", "text/javascript");
          fileref.setAttribute("data-app-key", __DROPBOX_APP_ID__);
          fileref.setAttribute("id", id);
          document.body.appendChild(fileref);
          fileref.onload = function () {
            self.setState({ loading: false });
          };
          fileref.setAttribute(
            "src",
            "https://www.dropbox.com/static/api/2/dropins.js"
          );
        } else {
          self.setState({ loading: false });
        }
      },
      toggleDropbox() {
        const self = this;

        const options = {
          multiselect: false,
          linkType: "preview",
          success(files) {
            self.props.setWorking(true);
            self.setState({ loading: true });
            self.addResource(files[0]);
          }
        };

        Dropbox.choose(options);
      },
      addResource(file) {
        const self = this;
        const { theme } = self.props;
        if (file.bytes > __UPLOAD_SIZE_LIMIT__) {
          file.link = file.link.replace("dl=1", "dl=0");
        } else {
          file.link = file.link.replace("dl=0", "dl=1");
        }
        const resource = new ResourceModel({
          original_url: file.link,
          original_name: file.name,
          size: file.bytes,
          preview: file.thumbnailLink,
          type: "DROPBOX"
        });

        resource.save(null, {
          success(model, data) {
            self.props.addFunc(resource.toJSON());
          },
          error(model, data) {
            self.props.setWorking(false);
            self.setState({ loading: false });
            const errorMsg = extractErrorMessage(
              data.responseJSON,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
          }
        });
      },
      render() {
        const self = this;
        let button = "";
        let attachButtonLabel = __("resource_dropbox_attach_button_label");
        const { greyTheme } = this.props.theme;

        if (self.props.context == "board") {
          attachButtonLabel = __("resource_dropbox_add_button_label");
        }

        if (self.state.loading) {
          button = <DoodleLoader theme={greyTheme} />;
        } else {
          button = (
            <Button
              theme={greyTheme}
              variant="primary"
              ref="dropboxButton"
              onClick={self.toggleDropbox}
            >
              {attachButtonLabel}
            </Button>
          );
        }
        return (
          <div>
            <br />
            <div className="col-sm-12 text-center flex-centered-column">
              {button}
            </div>
          </div>
        );
      }
    })
  )
);

export default ResourceDropbox;
