import { EditorOptions } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import Subscript from "@tiptap/extension-subscript";
import Highlight from "@tiptap/extension-superscript";
import Superscript from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Color from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import { Markdown } from "tiptap-markdown";
import {
  BackgroundColor,
  FontSize,
  TipTapAi
} from "src/js/components/global/BaseTextEditor/customExtensions";
import Placeholder, { PlaceholderOptions } from "@tiptap/extension-placeholder";
import { AiOptions } from "@tiptap-pro/extension-ai";
import Mathematics from "@tiptap-pro/extension-mathematics";

export const { __TIPTAP_AI_CLIENT_ID__ } = process.env;

export const __DEFAULT_EXTENSIONS__ = [
  StarterKit,
  Link.configure({
    openOnClick: false
  })
];

export const TextEditorOptions: Partial<EditorOptions> = {
  onCreate({ editor: e }) {
    e.commands.focus();
  },
  onUpdate({ editor: e }) {
    e.commands.scrollIntoView();
  },
  extensions: [
    StarterKit,
    Table.configure({
      resizable: true
    }),
    TableRow,
    TableHeader,
    TableCell,
    TextStyle,
    Subscript,
    Superscript,
    Highlight,
    Color,
    BackgroundColor,
    FontSize,
    Underline,
    Mathematics,
    Link.configure({
      openOnClick: false
    }),
    Markdown.configure({
      html: true,
      transformCopiedText: true
    }),
    TextAlign.configure({
      types: ["heading", "paragraph"]
    })
  ],
  content: `<h3>Untitled Document</h3>
  <p>Write something here...</p>
  `,
  injectCSS: true
};

export const AiExtensions = (props: {
  aiConfig?: Partial<AiOptions>;
  placeholderConfig?: Partial<PlaceholderOptions>;
}) => {
  return [
    TipTapAi.configure(props?.aiConfig),
    Placeholder.configure(props?.placeholderConfig)
  ];
};
