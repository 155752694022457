import { Location } from "history";
import { HEADER_TITLES } from "./TopHeaderMobile.const";

export const getPath = (location: Location) => {
  const hash = location.pathname;
  const paths = hash && hash.split("/");
  const path = paths && paths.length && paths[paths.length - 1];
  return path;
};

export const getHeaderTitle = (location: Location) => {
  const path = getPath(location);
  const headerTitle = HEADER_TITLES[path];
  return headerTitle || "mobile_header_settings_default_title";
};
