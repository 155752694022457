import styled, { css } from "styled-components";
import { MainBox } from "src/js/components/global/MainBox";
import { CheckIndicatorContainer } from "src/js/components/global/CheckIndicator/CheckIndicator.styles";

export const CheckboxBlockText = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
`;

export const CheckboxBlockContainer = styled(MainBox)`
  position: relative;
  width: 100%;
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.title};
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}

  & ${CheckIndicatorContainer} {
    position: absolute;
    right: 15px;
    transform: translateY(-50%);
    top: 50%;
  }
  @media (max-width: 767px) {
    & ${CheckIndicatorContainer} {
      right: 10px;
    }
  }
`;

export const SpaceImageContainer = styled.div`
  width: 80px;
  height: 80px;
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 50px;
    display: none;
  }
`;

export const CheckboxBlockInfoContainer = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    padding-right: 50px;
  }
`;
