import { ActionButton, colors, shadow } from "@arcadia/design-system";
import styled from "styled-components";

export const Modal = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: ${shadow[200]};
  overflow: hidden;
  pointer-events: all;
  border-radius: 12px;
`;

export const CloseActionButton = styled(ActionButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const CardWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  max-width: 788px;
`;

export const VideoContainer = styled.div`
  flex: 1;
  max-width: 372px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  > div {
    height: 100%;
    border-radius: 0px;
    video {
      object-fit: cover;
    }
  }
`;

export const ActionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const TooltipLinkBtn = styled.button`
  all: unset;
  cursor: pointer;
  color: ${colors.grey[1000]};
  text-decoration: underline;
`;
