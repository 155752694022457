import React, { FC } from "react";
import { Box } from "@ds_universal/Box";

import * as S from "./BoxWithIcons.styles";
import { BoxWithIconsProps } from "./BoxWithIcons.types";

const BoxWithIcons: FC<BoxWithIconsProps> = ({
  icons = [],
  showIcons = true,
  ...props
}) => {
  return (
    <Box position="relative" {...props}>
      {showIcons
        ? icons.map((iconProp, i) => (
            <S.StyledIcon key={i} initialViewbox {...iconProp} />
          ))
        : null}
      <S.ContentWrapper>{props?.children}</S.ContentWrapper>
    </Box>
  );
};

export default BoxWithIcons;
