import React, { FC } from "react";
import { useTranslation } from "src/js/translation";
import * as S from "./EmptyState.styles";

const EmptyState: FC = () => {
  const { translate } = useTranslation();

  return (
    <S.EmptyStateWrapper>
      <S.LottieAnimation />
      {translate("school_claim_message_empty")}
    </S.EmptyStateWrapper>
  );
};

export default EmptyState;
