import { Icon } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";

import * as S from "./ExerciseListEmptyState.styles";

const ExerciseListEmptyState = () => {
  const {
    GroupStore: { userIsTeacher }
  } = useStores();
  const { translate } = useTranslation();

  return (
    <S.Container>
      <Icon
        initialViewbox
        icon="illustration-no-data-folder"
        width="100"
        height="100"
      />
      <S.Title level="5">{translate("exercise_list_empty_title")}</S.Title>
      <S.Description type="body">
        {translate(
          userIsTeacher
            ? "exercise_list_empty_teacher_description"
            : "exercise_list_empty_student_description"
        )}
      </S.Description>
    </S.Container>
  );
};

export default observer(ExerciseListEmptyState);
