import { Box, Icon, Text } from "@arcadia/design-system";
import React, { useState } from "react";
import { useTranslation } from "src/js/translation";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import * as S from "./SidebarHeader.styles";
import { SidebarHeaderProps } from "./SidebarHeader.types";

const SidebarHeader = ({
  id,
  icon,
  title,
  actions = [],
  onDirectClickAction = null
}: SidebarHeaderProps) => {
  const { translate } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(true);

  const hasActions = actions.length !== 0;

  return (
    <TranslatedTooltip
      customTooltip={
        <Box flex={1} padding="8px">
          {actions.map(({ label, onClick }) => (
            <S.TooltipOption
              key={label}
              onClick={() => {
                onClick();
                setPopoverVisible(false);
              }}
            >
              <Text type="menuLabel">{translate(label)}</Text>
            </S.TooltipOption>
          ))}
        </Box>
      }
      placement="right"
      forceHide={!hasActions || !popoverVisible}
    >
      <S.SidebarHeaderWrapper
        id={id}
        isClickable={!!onDirectClickAction}
        hasActions={hasActions}
        onClick={() => onDirectClickAction?.()}
        onMouseOver={() => setPopoverVisible(true)}
      >
        <S.LeftSection>
          <S.SidebarHeaderIcon>
            <Icon icon={icon} height={24} width={24} initialViewbox />
          </S.SidebarHeaderIcon>
          <S.SidebarHeaderTitle>{translate(title)}</S.SidebarHeaderTitle>
        </S.LeftSection>
        {hasActions && (
          <S.SidebarHeaderAction>
            <Icon icon="plusThin" height={16} width={16} />
          </S.SidebarHeaderAction>
        )}
      </S.SidebarHeaderWrapper>
    </TranslatedTooltip>
  );
};

export default SidebarHeader;
