import { Avatar, Box, Icon } from "@arcadia/design-system";
import React, { FC, useEffect, useState } from "react";
import { DotsMenu } from "src/js/components/DotsMenu";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import {
  getAvatarData,
  getTitleFromUsers,
  getUsersWithoutMe
} from "src/js/components/Thread/Thread.const";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { editThreadSettings } from "src/js/repository/threadsRepository";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { ThreadUsersModal } from "../ThreadUsersModal";
import * as S from "./ThreadToolbar.styles";
import { ThreadToolbarProps } from "./ThreadToolbar.types";

const ThreadToolbar: FC<ThreadToolbarProps> = ({
  className,
  thread,
  usersWritingString
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal },
    UserStore: { activeUser },
    ChatStore: { setThreadMuted },
    SpaceStore: { activeSpaceSlug }
  } = useStores();

  const { users = [], muted: defaultMuted, id } = thread || {};
  const [muted, setMuted] = useState<boolean>(defaultMuted);
  useEffect(() => {
    setMuted(defaultMuted);
  }, [defaultMuted]);
  const showUsersModal = () => {
    openModal(() => <ThreadUsersModal users={users} />);
  };

  const navigateToChats = () => {
    navigateTo(
      createUrl("space_chats", {
        space_slug: activeSpaceSlug
      })
    );
  };

  const menuItems: ListItemProps[] = [
    {
      id: "users",
      label: translate("thread_toolbar_cta_users"),
      icon: "userEmpty",
      onClick: showUsersModal
    },
    {
      id: "muteUnmute",
      label: translate(
        muted ? "thread_toolbar_cta_unmute" : "thread_toolbar_cta_mute"
      ),
      icon: muted ? "bellEmpty" : "bellEmptyCrossed",
      onClick: () => {
        editThreadSettings({
          threadId: id,
          muted: !muted
        })
          .then(() => {
            setThreadMuted({ threadId: id, muted: !muted });
            setMuted(!muted);
          })
          .catch(error => {
            showToastError({ str: error.message });
          });
      }
    }
  ];

  const usersWithoutMe = getUsersWithoutMe(thread?.users, activeUser?.uuid);
  const { url } = getAvatarData(usersWithoutMe);

  const limitTitleUsers = 2;
  const namesTitleLabel = getTitleFromUsers(
    usersWithoutMe?.slice(0, limitTitleUsers),
    {
      removed: translate("chat_thread_user_removed")
    }
  );
  const othersTitleLabel =
    usersWithoutMe?.length - 1 >= limitTitleUsers
      ? `, ${usersWithoutMe.length - limitTitleUsers} ${translate(
          "thread_toolbar_title_others"
        )}`
      : "";
  const title = `${namesTitleLabel}${othersTitleLabel}`;

  const subtitleEl = usersWritingString ? (
    <Box data-testid="thread-toolbar-subtitle-typing">
      <S.ThreadToolbarSubtitle>{usersWritingString}</S.ThreadToolbarSubtitle>
    </Box>
  ) : (
    <Box data-testid="thread-toolbar-subtitle-users">
      <S.ThreadToolbarSubtitle>
        {translate("thread_toolbar_subtitle_users")}
      </S.ThreadToolbarSubtitle>
    </Box>
  );

  if (isLayoutModeMobile) {
    return (
      <S.ThreadToolbarMobileWrapper className={className}>
        <S.ThreadToolbarMobileBackBtn onClick={navigateToChats}>
          <Icon icon="chevronSmallLeft" width={16} height={16} />
        </S.ThreadToolbarMobileBackBtn>
        <S.ThreadToolbarMobileContent onClick={showUsersModal}>
          <Avatar theme={whiteTheme} url={url} alt={title} size="s" rounded />
          <S.ThreadToolbarMobileText>
            <S.ThreadToolbarMobileTitle>{title}</S.ThreadToolbarMobileTitle>
            {subtitleEl}
          </S.ThreadToolbarMobileText>
        </S.ThreadToolbarMobileContent>
      </S.ThreadToolbarMobileWrapper>
    );
  }

  return (
    <S.ThreadToolbarWrapper className={className}>
      <Box height="100%" width="100%">
        <S.ThreadToolbarContent onClick={showUsersModal}>
          <Avatar theme={whiteTheme} url={url} alt={title} size="m" rounded />
          <Box>
            <S.ThreadToolbarTitle>
              {title} {muted ? <S.ThreadToolbarBellIcon /> : null}
            </S.ThreadToolbarTitle>
            {subtitleEl}
          </Box>
        </S.ThreadToolbarContent>
      </Box>
      <S.ThreadToolbarActions>
        <DotsMenu id="chatMessageHeaderMenu" items={menuItems} alignRight />
      </S.ThreadToolbarActions>
    </S.ThreadToolbarWrapper>
  );
};

export default ThreadToolbar;
