import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { DoodleLoader, PageNav } from "@arcadia/design-system";
import { Translator } from "src/js/translation/TranslationProvider";
import { __GROUP_MEMBERS_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { withTheme } from "styled-components";
import { createURL } from "../../modules/utility";
import { getActiveGroupId } from "../../modules/activeGroup";
import { __RANKING_TIME_RANGE__ } from "../../settings/settings";
import Pager from "./pager";
import RankMember from "./rankMemberView";

const RankingGroup = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          day: __RANKING_TIME_RANGE__,
          sort: "desc",
          pageNumber: 1
        };
      },
      getInitialState() {
        return {
          name: this.props.groupModel.get("name"),
          ranking: [],
          rankingLoaded: false,
          pagination: {
            page: 1,
            last_page: 2
          }
        };
      },
      componentWillMount() {
        const self = this;
        const day = __RANKING_TIME_RANGE__;
        const sort = "desc";
        const page = self.props.pageNumber;
        const data = {
          page,
          sort,
          num_day_before: day
        };

        const url = createURL("api_groups_ranking", {
          group_id: getActiveGroupId()
        });

        $.ajax({
          type: "GET",
          dataType: "json",
          url,
          data,
          success(ret) {
            self.setState({
              ranking: ret.chart,
              pagination: ret.pagination,
              rankingLoaded: true
            });
          },
          error(ret) {}
        });
      },
      render() {
        const self = this;
        const { ranking } = this.state;
        const showMore = "";
        let rankingViewed = "";
        const { greyTheme } = this.props.theme;
        let content = <DoodleLoader theme={greyTheme} />;
        if (self.state.rankingLoaded) {
          let pager = "";
          if (ranking.length > 0) {
            const rankingList = ranking.map(m => {
              return <RankMember rankMember={m} />;
            });
            rankingViewed = <ul>{rankingList}</ul>;
          } else {
            rankingViewed = <p>Nessun elemento in classifica</p>;
          }
          if (self.state.pagination.last_page > 1) {
            pager = (
              <Pager
                pageTotalNumber={self.state.pagination.last_page}
                type="ranking"
                pageActive={self.state.pagination.page}
              />
            );
          }
          content = (
            <div className="ranking-main default-box">
              {rankingViewed}
              {showMore}
              {pager}
            </div>
          );
        }
        return <>{content}</>;
      }
    })
  )
);

export default RankingGroup;
