import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Link } from "@ds_universal/data_entry/Link";

import * as S from "./HighlightedLink.styles";
import { HighlightedLinkProps } from "./HighlightedLink.types";

export const HighlightedLink: FC<
  PropsWithChildren<HighlightedLinkProps>
> = props => {
  const { theme, description } = props;
  return (
    <ThemeProvider theme={theme}>
      <S.HighlightedLink>
        <S.Content>
          {description && <Text type="formTitle">{description}</Text>}
          <Link {...props} />
        </S.Content>
      </S.HighlightedLink>
    </ThemeProvider>
  );
};

export default HighlightedLink;
