import { Icon, Text } from "@arcadia/design-system";
import React, { useState } from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./PreferenceDropdown.styles";
import {
  PreferenceDropdownMode,
  PreferenceDropdownProps
} from "./PreferenceDropdown.types";

// TODO improve the component with accessibility
const PreferenceDropdown = ({
  options,
  selectedOptionId,
  setSelectedOptionId,
  openTopPopover = false,
  disabled = false,
  mode = PreferenceDropdownMode.Radio,
  iconDefault
}: PreferenceDropdownProps) => {
  const { whiteTheme, primaryColor, colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { translate } = useTranslation();

  const translatedOptions = options.map(option => ({
    ...option,
    label: translate(option.label),
    id: String(option.id),
    value: String(option.value)
  }));

  const optionsEl =
    mode === PreferenceDropdownMode.Radio ? (
      <S.StyledRadioGroup
        orientationLabel="right"
        theme={whiteTheme}
        orientationOption="column"
        options={translatedOptions}
        onChangeFunction={id => setSelectedOptionId(id)}
        value={String(selectedOptionId)}
        color={primaryColor[500]}
        hoverBgColor={colors.coldGrey[900]}
      />
    ) : (
      options.map(({ id, label }) => {
        return (
          <S.Option
            onClick={() => {
              setSelectedOptionId(id);
              setIsOpen(false);
            }}
            key={id}
          >
            <Text type="formField">{translate(label)}</Text>
          </S.Option>
        );
      })
    );

  return (
    <S.Popover
      isVisible={isOpen}
      setIsVisible={setIsOpen}
      openTopPopover={openTopPopover}
      content={<S.PopoverContentWrapper>{optionsEl}</S.PopoverContentWrapper>}
    >
      <S.StyledButton disabled={disabled} isOpen={isOpen}>
        <Icon
          height={16}
          width={16}
          icon={
            options.find(({ id }) => selectedOptionId === id)?.icon ||
            iconDefault
          }
        />
        <S.IconWrapper
          animate={isOpen ? "visible" : "hidden"}
          variants={{
            visible: {
              rotateZ: "180deg"
            },
            hidden: {
              rotateZ: "0deg"
            }
          }}
          transition={{ duration: 0.5 }}
        >
          <Icon icon="chevronLargeDown" />
        </S.IconWrapper>
      </S.StyledButton>
    </S.Popover>
  );
};

export default PreferenceDropdown;
