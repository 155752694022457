import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ContextualFeedback.styles";
import { ContextualFeedbackProps } from "./types";

// TODO: this and the two other contextual components could've been unified into a single one
const ContextualFeedback: FC<ContextualFeedbackProps> = ({
  theme,
  children
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ContextualFeedbackContainer>
        <S.ContextualFeedbackIcon>
          <Icon icon="successCircle" width={16} height={16} />
        </S.ContextualFeedbackIcon>
        <S.ContextualFeedbackMessage>
          <Text type="cta">{children}</Text>
        </S.ContextualFeedbackMessage>
      </S.ContextualFeedbackContainer>
    </ThemeProvider>
  );
};

export default ContextualFeedback;
