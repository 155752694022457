/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button, Input, PictureUploader } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useOnboarding, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { formatBytes } from "src/js/modules/commonFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { cloneGroup, createGroup } from "src/js/repository/groupRepository";
import {
  __GROUP_COVER_MIME__,
  __GROUP_COVER_SIZE__
} from "src/js/settings/settingFileUploader";
import { __FORM_CREATE_BUSINESS_GROUP__ } from "src/js/settings/settingsFormValidation";
import { __GROUP_DESCRIPTION_LIMIT__ } from "src/js/settings/settingsGroups";
import { __GROUP_COVER_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation/translated.types";
import {
  EventDomain,
  GroupEvent,
  OnboardingEvent,
  TemplatingEvent
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { BusinessGroupCreationProps } from "./BusinessGroupCreationForm.types";

import * as S from "./BusinessGroupCreationForm.styles";

const BusinessGroupCreationForm = ({
  theme,
  closeForm,
  clonedGroup,
  onGoBack,
  isWeschoolTemplate,
  isCustomTemplate
}: BusinessGroupCreationProps) => {
  const { translate } = useTranslation();
  const { trackView } = useViewTracking();
  const { isOnboardingFlow, flowSwitcher } = useOnboarding();
  const { UserStore, UIStore, SpaceStore, SpaceGroupListStore, GroupStore } =
    useStores();
  const { activeSpaceId, activeSpace } = SpaceStore;
  const { setUserOnboarding } = UserStore;
  const { trackVirtualView } = useViewTracking();

  const { register, handleSubmit, watch, setValue, errors } = useForm<{
    [key in keyof typeof __FORM_CREATE_BUSINESS_GROUP__]: string;
  }>({
    defaultValues: {
      name: ""
    }
  });

  const { isLayoutModeMobile: isMobile } = UIStore;
  const isCloning = !!clonedGroup;
  const groupName = watch("name");
  const [descriptionState, setDescriptionState] = useState(
    clonedGroup?.description || ""
  );
  const [loading, setLoading] = useState(false);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  useEffect(() => {
    setValue("spaceId", activeSpaceId);
    setValue("name", clonedGroup?.name);
    setValue("description", clonedGroup?.description);
  }, []);

  useEffect(() => {
    if (!groupName) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [groupName]);

  useEffect(() => {
    if (!isOnboardingFlow && !isCloning) {
      _trackEvent(EventDomain.Group, GroupEvent.StandardGroupSelected);
      trackVirtualView(WeSchoolVirtualViewDescriptions.Groups.GroupCreate);
    }
  }, []);

  const submitForm = handleSubmit(formData => {
    if (loading) return;
    const submitData = {
      spaceId: activeSpaceId,
      ...formData,
      cover: coverImage,
      sourceGroup: clonedGroup?.id
    };
    setLoading(true);
    if (isCloning) {
      if (isCustomTemplate || isWeschoolTemplate) {
        _trackEvent(
          EventDomain.Templating,
          isCustomTemplate
            ? TemplatingEvent.CustomTemplateCreation
            : TemplatingEvent.WeSchoolTemplateCreation
        );
      } else {
        _trackEvent(EventDomain.Group, GroupEvent.CloneGroup);
      }
      const payload = {
        // check if the submitted is the same of clonedGroup?.name in case of no change from starting cloning group
        name: submitData.name,
        description: submitData.description,
        cover: submitData.cover,
        ...(isWeschoolTemplate && { targetSpaceId: activeSpaceId })
      };
      cloneGroup(payload, clonedGroup.id)
        .then(data => {
          SpaceGroupListStore.addGroupAtStart(data);
          closeForm();
          if (data?.group?.cloningCompleted && !isOnboardingFlow) {
            showToastSuccess({
              str: translate("toast_ask_new_group_redirect", {
                group: data?.group?.name
              })
            });
          }
          navigateTo(createUrl("wall", { group_id: data.group.id }));
        })
        .catch(error => {
          closeForm();
          trackView();
          showToastError({ str: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (isOnboardingFlow) {
        _trackEvent(EventDomain.Onboarding, OnboardingEvent.GroupCreation);
      } else {
        _trackEvent(EventDomain.Group, GroupEvent.GroupCreation);
      }
      createGroup(submitData)
        .then(data => {
          SpaceGroupListStore.addGroupAtStart(data);
          if (isOnboardingFlow) {
            GroupStore.setActiveGroupFull(data);
            setUserOnboarding({
              userType: "BUSINESS",
              isCompleted: false,
              step: WeSchoolRoutes.Onboarding.membersInvite
            });
            flowSwitcher(UserStore.userOnboarding.userType);
          } else {
            closeForm();
            showToastSuccess({
              str: translate("toast_ask_new_group_redirect", {
                group: data?.group?.name
              })
            });
            navigateTo(createUrl("wall", { group_id: data.group.id }));
          }
        })
        .catch(error => {
          if (!isOnboardingFlow) {
            closeForm();
          }
          trackView();
          showToastError({ str: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const buttonSubmitCta = () => {
    if (isOnboardingFlow)
      return translate("onboarding_group_creation_form_submit_cta");

    return translate("group_creation_form_submit_cta_final");
  };

  const pictureUploadedPlaceHolderCover =
    activeSpace?.space?.spaceCover?.large || __GROUP_COVER_PLACEHOLDER__;

  return (
    <S.StyledForm
      id="form-create-group-first-step"
      onSubmit={submitForm}
      isMobile={isMobile}
      isOnboarding={isOnboardingFlow}
    >
      <PictureUploader
        key="cover"
        label={translate("group_creation_form_cover")}
        theme={theme}
        imageSrc={coverImage}
        onChangeHandler={setCoverImage}
        defaultCover={
          clonedGroup?.cover?.originalUrl || pictureUploadedPlaceHolderCover
        }
        feedbacks={[
          {
            id: "upload_image_limit",
            type: "warning",
            message: `${translate("upload_image_limit")} ${formatBytes(
              __GROUP_COVER_SIZE__
            )}`
          },
          {
            id: "file_extension_unsupported_error",
            type: "error",
            message: translate("file_extension_unsupported_error")
          },
          {
            id: "file_size_error",
            type: "error",
            message: translate("file_size_error")
          }
        ]}
        maxSize={__GROUP_COVER_SIZE__}
        mimeArray={__GROUP_COVER_MIME__}
      />
      <S.Spacer />
      <Input
        key="name"
        theme={theme}
        type="field"
        rounded
        label={translate("group_creation_form_name")}
        name="name"
        placeholder={translate("group_creation_form_name_placeholder")}
        ref={register(__FORM_CREATE_BUSINESS_GROUP__.name)}
        feedback={
          errors.name
            ? {
                message: translate(errors.name.message as TranslationKeys),
                type: "error"
              }
            : null
        }
      />
      <S.Spacer />
      <Input
        type="textarea"
        key="description"
        name="description"
        rows={4}
        maxHeight="120px"
        maxLength={__GROUP_DESCRIPTION_LIMIT__}
        theme={theme}
        label={translate("group_creation_form_description")}
        placeholder={translate("group_creation_form_description_placeholder")}
        onChange={e => {
          setDescriptionState(e.target.value);
        }}
        value={descriptionState}
        ref={register(__FORM_CREATE_BUSINESS_GROUP__.description)}
        showCounter
        feedback={
          errors.description
            ? {
                message: translate(
                  errors.description.message as TranslationKeys
                ),
                type: "error"
              }
            : null
        }
      />
      <S.Spacer />
      <S.SubmitWrapper isMobile={isMobile}>
        {!isOnboardingFlow && !isMobile && onGoBack ? (
          <Button
            variant="secondary"
            type="button"
            theme={theme}
            fullWidth
            onClick={onGoBack}
          >
            {translate("back")}
          </Button>
        ) : null}
        <Button
          disabled={loading || isSubmitDisabled}
          type="submit"
          theme={theme}
          variant="primary"
          fullWidth
        >
          {buttonSubmitCta()}
        </Button>
      </S.SubmitWrapper>
    </S.StyledForm>
  );
};

export default observer(BusinessGroupCreationForm);
