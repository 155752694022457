import React from "react";
import styled, { css, useTheme } from "styled-components";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { fetchSearchGroupList } from "src/js/repository/groupsListRepository";
import { useFetcher, useStores } from "src/js/hooks";
import {
  DropdownMultiple,
  Dropdown,
  DoodleLoader
} from "@arcadia/design-system";
import { TODO } from "src/js/types";
import { getFormattedContexts } from "./util";
import { useUserFilter, useFilterSetter } from "./hooks";
import { FilterProps } from "./types";

const StyledFiltersWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 24px;
`;

const StyledDrodpownMultiple = styled.div<{ active: boolean }>`
  flex: 1;

  ${({ active }) =>
    !active &&
    css`
      > div > div:first-of-type > span {
        color: ${({ theme }) => theme.colors.blueGrey[500]};
      }
    `};

  > div > div:first-of-type {
    min-height: 48px;
    border-radius: 32px;
    align-items: center;
    display: flex;
  }
  > div > div:first-of-type > div {
    display: flex;
  }
`;
const StyledDropdown = styled.div<{ active: boolean }>`
  flex: 1;
  ${({ active }) =>
    !active &&
    css`
      > div > button > span {
        color: ${({ theme }) => theme.colors.blueGrey[500]};
      }
    `};

  > div > button {
    height: 48px;
    border-radius: 32px;
    align-items: center;
    display: flex;
  }
`;

const SearchFilter = ({ filters, onChange }: FilterProps) => {
  const {
    UserStore: { activeUser },
    SpaceStore: { spacesList }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    usersLoading,
    paginatedUsers,
    selectedUser,
    setUserFilter,
    filterString,
    setFilterString,
    showNext
  } = useUserFilter({
    activeUserUuid: filters.userFilter ? filters.userFilter[0] : null,
    filters,
    onChange
  });
  const { data: groupsData = [], isLoading: groupsLoading } = useFetcher(
    async () => fetchSearchGroupList(activeUser?.id as TODO)
  );

  const { setSpaceFilter, setGroupFilter, setContextFilter, contextOptions } =
    useFilterSetter({ filters, onChange });

  if (usersLoading || groupsLoading) return <DoodleLoader theme={greyTheme} />;

  return (
    <StyledFiltersWrapper>
      <StyledDropdown active={!!filters.spaceFilter}>
        <Dropdown
          theme={greyTheme}
          optionsList={spacesList.map(space => ({
            id: `${space.space.id}`,
            label: space.space.name
          }))}
          placeholder={translate({
            text: "search_spaces_filter_placeholder"
          })}
          setSelectedOptionId={spaceId => {
            setSpaceFilter(spaceId);
          }}
          onReset={() => {
            setSpaceFilter(null);
          }}
          resetPlaceholder={translate({ text: "remove_filter" })}
          selectedOptionId={filters.spaceFilter && filters.spaceFilter[0]}
        />
      </StyledDropdown>
      <StyledDropdown active={!!filters.groupFilter}>
        <Dropdown
          theme={greyTheme}
          optionsList={groupsData.map(group => ({
            id: `${group.id}`,
            label: group.name
          }))}
          placeholder={translate({ text: "search_group_filter_placeholder" })}
          setSelectedOptionId={groupId => {
            setGroupFilter(groupId);
          }}
          onReset={() => {
            setGroupFilter(null);
          }}
          resetPlaceholder={translate({ text: "remove_filter" })}
          selectedOptionId={filters.groupFilter && filters.groupFilter[0]}
        />
      </StyledDropdown>
      <StyledDropdown active={!!filters.userFilter}>
        <Dropdown
          theme={greyTheme}
          optionsList={paginatedUsers.data.map(userItem => ({
            id: userItem.uuid,
            label: `${userItem.name} ${userItem.surname}`
          }))}
          placeholder={
            selectedUser && selectedUser.label
              ? selectedUser.label
              : translate({ text: "search_user_filter_placeholder" })
          }
          setSelectedOptionId={userId => {
            setUserFilter(userId);
          }}
          searchQuery={filterString}
          onSearchFunction={e => {
            setFilterString(e.target.value);
          }}
          selectedOptionId={filters.userFilter && filters.userFilter[0]}
          searchable
          searchPlaceholder={translate({ text: "cerca" })}
          onReset={() => {
            setUserFilter(null);
          }}
          resetPlaceholder={translate({ text: "remove_filter" })}
          hasNext={paginatedUsers.hasNext}
          showNext={showNext}
          scrollableListId="paginated-users-dropdown"
        />
      </StyledDropdown>
      <StyledDrodpownMultiple
        active={filters.wall || filters.exercise || filters.module}
      >
        <DropdownMultiple
          theme={greyTheme}
          optionsList={contextOptions}
          selectedOptionIds={getFormattedContexts(filters)}
          setSelectedOptionIds={arrayValues => {
            setContextFilter(arrayValues as ["wall" | "exercise" | "module"]);
          }}
          placeholder={translate({ text: "type_filter" })}
        />
      </StyledDrodpownMultiple>
    </StyledFiltersWrapper>
  );
};

export default SearchFilter;
