import { observer } from "mobx-react";
import React from "react";
import { useTheme } from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { LoaderProgressBar } from "src/js/components/LoaderProgressBar";
import { ModalScrollableBody } from "src/js/components/modal/ModalScrollableBody";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import {
  ContextualError,
  DoodleLoader,
  Button,
  Text,
  Modal
} from "@arcadia/design-system";

import * as S from "./RemoveFromGroupsModal.styles";

const RemoveFromGroupsModal = ({
  closeModal = () => {},
  handleSubmit = () => {},
  userName,
  userSurname,
  groups = [],
  hasNext = false,
  showNext = () => {},
  isLoading = false,
  groupsWithError = []
}) => {
  const { whiteTheme, greyTheme } = useTheme();
  let modalBody = (
    <>
      <S.ModalContentTitle>
        <Text type="formTitle">
          <Translate text="choice_confirmation_question" />
        </Text>
      </S.ModalContentTitle>
      <S.ModalContentSubtitle>
        <Text type="formSubtitle">
          <Translate text="remove_from_all_groups_description" />
        </Text>
      </S.ModalContentSubtitle>
      <S.ModalContentContainer>
        <InfiniteScroll
          scrollableTarget="remove-from-groups-modal"
          dataLength={groups.length}
          next={showNext}
          hasMore={hasNext}
          loader={<DoodleLoader theme={greyTheme} isMini />}
        >
          {groups.map((group, idx) => {
            const { groupName } = group;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <S.UserGroupCard key={idx}>
                <S.UserFullName>
                  {userName} <S.UserSurname>{userSurname}</S.UserSurname>
                </S.UserFullName>
                <S.GroupName>{groupName}</S.GroupName>
              </S.UserGroupCard>
            );
          })}
        </InfiniteScroll>
      </S.ModalContentContainer>
    </>
  );

  if (isLoading) {
    modalBody = (
      <S.LoaderContainer>
        <LoaderProgressBar />
        <S.LoaderTitleContainer>
          <Text type="formDescription">
            <Translate text="remove_loading" />
          </Text>
        </S.LoaderTitleContainer>
      </S.LoaderContainer>
    );
  }

  if (groupsWithError && groupsWithError.length > 0) {
    modalBody = (
      <S.ModalContentContainer marginTop>
        {groupsWithError.map((group, idx) => {
          const { group_name } = group;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <S.UserGroupCard key={idx} hasError>
              <Text type="formDescription">
                <S.UserFullName>
                  {userName} <S.UserSurname>{userSurname}</S.UserSurname>
                </S.UserFullName>
              </Text>
              <Text type="formDescription">
                <S.GroupName>{group_name}</S.GroupName>
              </Text>
            </S.UserGroupCard>
          );
        })}
      </S.ModalContentContainer>
    );
  }

  return (
    <Translator>
      {({ translate }) => (
        <Modal
          onClose={closeModal}
          title={translate("remove_from_all_groups_modal_title")}
        >
          <ModalScrollableBody
            maxHeight={300}
            maxHeightOffset={240}
            id="remove-from-groups-modal"
          >
            {modalBody}
          </ModalScrollableBody>
          {groupsWithError && groupsWithError.length > 0 && (
            <S.ModalBannerContainer>
              <ContextualError
                theme={whiteTheme}
                text={<Translate text="user_group_remove_error" />}
              />
            </S.ModalBannerContainer>
          )}
          <S.ModalFooterContainer>
            <S.ButtonContainer>
              <Button
                onClick={closeModal}
                variant="secondary"
                theme={whiteTheme}
                fullWidth={getBreakpoint() === "smartphone"}
              >
                <Translate text="undo_button_tooltip" />
              </Button>
              {!(groupsWithError && groupsWithError.length > 0) && (
                <Button
                  disabled={isLoading}
                  onClick={handleSubmit}
                  variant="primary"
                  theme={whiteTheme}
                  fullWidth={getBreakpoint() === "smartphone"}
                >
                  <Translate text="remove_user_title" />
                </Button>
              )}
            </S.ButtonContainer>
          </S.ModalFooterContainer>
        </Modal>
      )}
    </Translator>
  );
};

export default observer(RemoveFromGroupsModal);
