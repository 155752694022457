import { Text, VariantType, scrollbarMixin } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { changeOpacity } from "./AICustomPromptEditor.utils";

export const BodyWrapper = styled.div`
  padding: 16px;
  padding-top: 0px;
`;

export const EditorWrapper = styled(motion.div)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 4px;
  padding-right: 4px;
  overflow: auto;
  height: auto;
  max-height: 292px;
  ${scrollbarMixin(VariantType.Grey)}

  .tiptap.ProseMirror {
    padding: 0px 12px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      border-radius: 1px;
      background-color: ${({ theme }) => theme.colors.coldGrey[800]};
    }
  }
`;

export const PreviewText = styled(Text)`
  /* TODO: Add coldGrey[300] to DS */
  /* color: ${({ theme }) => theme.colors.coldGrey[300]} */
  color: #2d2d86;
`;

export const FooterWrapper = styled(motion.div)`
  border-top: 1px solid
    ${({ theme }) => changeOpacity(theme.colors.coldGrey[800], 40)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
`;

export const FooterButton = styled.button<{ variant?: "danger" | "default" }>`
  background: none;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  min-width: 96px;
  border-radius: 4px;
  position: relative;
  /* TODO: Add coldGrey[300] to DS */
  /* color: ${({ theme }) => theme.colors.coldGrey[300]} */
  color: ${({ theme, variant }) =>
    variant === "danger" ? theme.colors.error[500] : "#2d2d86"};
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme, variant }) =>
      variant === "danger"
        ? theme.colors.error[900]
        : theme.colors.violet[800]};
  }
`;

export const EditSheetRoot = styled.div`
  position: relative;
`;

export const FooterRightSection = styled.div<{ isLayoutModeMobile: boolean }>`
  ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile
      ? css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `
      : css`
          margin-left: auto;
        `}
`;
