import colors from "@ds_themes/colors";

export const link = {
  background: colors.grey[1000],
  text: colors.violet[500],
  hover: {
    text: colors.violet[400]
  },
  disabled: {
    text: colors.grey[600]
  },
  focus: {
    outline: `2px solid ${colors.violet[900]}`,
    border: `1.5px solid ${colors.violet[500]}`
  },
  visited: {
    text: colors.violet[300]
  }
};
