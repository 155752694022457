import colors from "#themes/colors";

export const __BOARD_COLOR__ = new Map([
  [2, colors.error[500]],
  [3, colors.warning[500]],
  [4, colors.goldenFizz[500]],
  [5, colors.success[500]],
  [1, colors.lagoon[600]],
  [6, colors.violet[600]]
]);
