import React from "react";

const Image = ({ resource }) => {
  const publicUrl =
    resource.public_url || resource.url || resource.publicUrls[0];
  return (
    <div className="full-height">
      <div className="container-resource-detail__image">
        <div
          className="resource-detail__image"
          style={{
            backgroundImage: `url(${publicUrl})`
          }}
        />
      </div>
    </div>
  );
};

export default Image;
