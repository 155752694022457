import styled, { css } from "styled-components";
import { SContainer } from "./EventAttachmentItem.types";

export const Container = styled.div<SContainer>`
  display: flex;
  align-items: center;
  width: 200px;
  min-height: 45px;
  border-radius: 5px;
  padding: 5px 10px 5px 5px;
  color: ${({ theme }) => theme.colors.grey[50]};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  background-color: ${({ theme, type }) => {
    switch (type) {
      case "EXERCISE":
        return theme.colors.violet[800];
      case "BOARD":
        return theme.colors.lagoon[800];
      default:
        return theme.colors.accent6;
    }
  }};

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DeleteContainer = styled.div`
  margin-left: auto;
  width: 28px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.span`
  flex: 1;
  min-width: 0px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
