import React, { useMemo, FC } from "react";
import { Box } from "@arcadia/design-system";
import { useForm } from "react-hook-form";
import { TranslationKeys } from "src/js/translation/translated.types";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { PasswordStrengthIndicator } from "src/js/components/forms/PasswordStrenghtIndicator";
import { checkPasswordStrength } from "src/js/modules/formFunction";
import { PasswordInput } from "src/js/components/PasswordInput";
import { __CHANGE_PASSWORD_SCHEMA__ } from "./utils";
import { ModalBody, ModalContent } from "../settings/Modal";

type ModalProps = {
  handleSubmission: (
    newPassword: string,
    confirmNewPassword: string,
    oldPassword: string
  ) => void;
  displayAsModal?: boolean;
};

type ChangePasswordFormProps = {
  newPassword: string;
  confirmNewPassword: string;
  oldPassword: string;
};

const UserAccountChangePasswordModal: FC<ModalProps> = ({
  handleSubmission,
  displayAsModal
}) => {
  const { whiteTheme, greyTheme } = useTheme();
  const { register, errors, handleSubmit, formState, watch } =
    useForm<ChangePasswordFormProps>({
      mode: "onChange"
    });

  const newPass = watch("newPassword");

  const strength = useMemo(() => {
    return checkPasswordStrength(newPass || "");
  }, [newPass]);

  const onSubmit = (formData: ChangePasswordFormProps) => {
    handleSubmission(
      formData.newPassword,
      formData.confirmNewPassword,
      formData.oldPassword
    );
  };
  return (
    <ModalContent title={translateString({ text: "change_password" })}>
      <ModalBody
        onSubmit={handleSubmit(onSubmit)}
        formId="change-password-form"
        isDisabled={!formState.isValid}
      >
        <Box marginTop={20}>
          <PasswordInput
            theme={displayAsModal ? whiteTheme : greyTheme}
            name="oldPassword"
            label={translateString({ text: "old_password_label" })}
            rounded
            ref={register(__CHANGE_PASSWORD_SCHEMA__.oldPassword)}
            error={{
              message: translateString({
                text: errors?.oldPassword?.message as TranslationKeys
              })
            }}
          />
        </Box>
        <Box marginTop={20}>
          <PasswordInput
            theme={displayAsModal ? whiteTheme : greyTheme}
            name="newPassword"
            label={translateString({ text: "new_password_label" })}
            rounded
            ref={register(__CHANGE_PASSWORD_SCHEMA__.newPassword)}
            error={{
              message: translateString({
                text: errors?.newPassword?.message as TranslationKeys
              })
            }}
          />
        </Box>
        {!!strength && (
          <Box marginBottom={20}>
            <PasswordStrengthIndicator strength={strength} />
          </Box>
        )}
        <Box marginTop={20}>
          <PasswordInput
            theme={displayAsModal ? whiteTheme : greyTheme}
            name="confirmNewPassword"
            label={translateString({
              text: "profile_password_confirmation_label"
            })}
            rounded
            ref={register(__CHANGE_PASSWORD_SCHEMA__.confirmNewPassword)}
            error={{
              message: translateString({
                text: errors?.confirmNewPassword?.message as TranslationKeys
              })
            }}
          />
        </Box>
      </ModalBody>
    </ModalContent>
  );
};

export default UserAccountChangePasswordModal;
