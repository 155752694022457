import { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  startGroupRealtimeEvents,
  stopGroupRealtimeEvents
} from "src/js/context/utils/GroupRealtimeEvents";
import { handleGroupErrorMessage } from "src/js/context/utils/errorHandler";
import {
  isFeatureFlagEnabled,
  useFeatureFlag
} from "src/js/feature_flag/FeatureFlagProvider";
import { useStores } from "src/js/hooks";
import {
  updateNextThreeLive,
  updateTodayLive
} from "src/js/modules/calendarFunction";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import {
  fetchGroupStudentsCount,
  fetchGroupUsers
} from "src/js/repository/groupRepository";
import { FeatureFlagKey } from "src/js/settings/settingsFeatureFlag";
import { __GROUP_USERS_PREVIEW_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { ActiveGroupFull, GroupUserRoleEnum } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";

const useSetupSpaceGroup = () => {
  const [blocked, setBlocked] = useState(false);
  const {
    GroupStore: {
      fetchGroup,
      setTeachers,
      setUsersPreview,
      setUsersCount,
      setStudentsCount,
      setActiveGroupFull,
      fetchGroupBadges,
      reset: resetGroupStore
    },
    SpaceStore: { setActiveSpaceById, activeSpaceId },
    CalendarStore,
    BoardsStore,
    NotificationStore
  } = useStores();
  const {
    params: { group_id, space_slug }
  } = useRouteMatch<{ group_id: string; space_slug: string }>({
    path: WeSchoolRoutes.App.Spaces.SpaceGroup.groupId
  });
  const location = useLocation();
  const featureFlagConfig = useFeatureFlag();
  const isAnalyticsV2Enabled = isFeatureFlagEnabled({
    flagKey: FeatureFlagKey.analyticsV2,
    featureFlagConfig
  });

  const checkGroupBlocked = (response: ActiveGroupFull) => {
    setBlocked(response?.settings?.blocked);
  };

  const checkAndSetActiveSpace = (response: ActiveGroupFull) => {
    const { space } = response;
    if (space.id === activeSpaceId) return Promise.resolve();
    return setActiveSpaceById(space.id);
  };

  const checkAndSetUsers = (response: ActiveGroupFull) => {
    const {
      group: { id: groupId }
    } = response;
    fetchGroupUsers({
      groupId,
      limit: 9999,
      offset: 0,
      roles: [GroupUserRoleEnum.Teacher]
    })
      .then(({ results }) => {
        setTeachers(results);
      })
      .catch(() => setTeachers([]));
    fetchGroupUsers({
      groupId,
      limit: __GROUP_USERS_PREVIEW_LIST_LIMIT__,
      offset: 0
    })
      .then(({ results, total }) => {
        setUsersPreview(results);
        setUsersCount(total || 0);
      })
      .catch(() => setUsersPreview([]));
    fetchGroupStudentsCount(groupId)
      .then(({ count }) => setStudentsCount(count))
      .catch(() => setStudentsCount(0));
  };

  const updateCalendarEvents = () => {
    updateTodayLive({
      groupId: group_id,
      getTodayLive: CalendarStore.getTodayLive
    });

    updateNextThreeLive({
      groupId: group_id,
      getCalendarNextEvents: CalendarStore.getCalendarNextEvents
    });
  };

  useEffect(() => {
    const numericGroupId = parseInt(group_id, 10);
    if (numericGroupId) {
      fetchGroup(group_id, !isAnalyticsV2Enabled)
        .then((response: ActiveGroupFull) => {
          checkAndSetActiveSpace(response).then(() =>
            setActiveGroupFull(response)
          );
          checkGroupBlocked(response);
          checkAndSetUsers(response);
          updateCalendarEvents();
          startGroupRealtimeEvents({
            group: response.group,
            roles: response.roles,
            BoardsStore,
            CalendarStore,
            updateCalendarEvents
          });
          fetchGroupBadges(parseInt(group_id, 10));
        })
        .catch(() => {
          handleGroupErrorMessage();
        });
    }

    return () => {
      stopGroupRealtimeEvents();
      resetGroupStore();
      NotificationStore.reset();
    };
  }, [group_id]);

  useEffect(() => {
    const numericGroupId = parseInt(group_id, 10);
    if (
      numericGroupId &&
      blocked &&
      !location.pathname.includes("overquotablocked") &&
      !location.pathname.includes("chat")
    ) {
      navigateTo(
        createUrl("group_overquota_blocked", { group_id: numericGroupId })
      );
    }
  }, [blocked, location.pathname]);

  return { groupId: group_id, spaceSlug: space_slug, groupIsBlocked: blocked };
};

export default useSetupSpaceGroup;
