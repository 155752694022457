import { ActionButtonThemeOptions, Palette } from "@ds_themes/types";

export const createActionButtonTheme = (
  palette: Palette
): { actionButton: ActionButtonThemeOptions } => {
  return {
    actionButton: {
      ...createPrimaryActionButtonTheme(palette),
      ...createSecondaryActionButtonTheme(palette),
      ...createSecondaryColoredActionButtonTheme(palette),
      ...createTertiaryActionButtonTheme(palette),
      ...createGlobalActionButtonTheme(palette)
    }
  };
};

export const createPrimaryActionButtonTheme = ({
  appearence,
  primary,
  secondary
}: {
  appearence: Palette["appearence"];
  primary: Palette["primary"];
  secondary: Palette["secondary"];
}) => {
  const badgeColor = appearence === "heavy" ? primary : secondary;
  return {
    primary: {
      background: primary.main,
      text: primary.contrastText,
      type: "cta",
      border: `1px solid ${primary.main}`,
      hover: {
        background: primary.contrastText,
        text: primary.main
      },
      focus: {
        border: `${primary.light} solid 3px`
      },
      disabled: {
        background: primary.light,
        text: primary.contrastText,
        border: `1px solid ${primary.light}`
      },
      badge: {
        background: badgeColor.main,
        text: badgeColor.contrastText,
        border: `1px solid ${
          appearence === "heavy" ? badgeColor.contrastText : secondary.main
        }`
      }
    }
  };
};

export const createSecondaryActionButtonTheme = ({
  grey,
  primary,
  secondary,
  appearence
}: {
  grey: Palette["grey"];
  primary: Palette["primary"];
  secondary: Palette["secondary"];
  appearence: Palette["appearence"];
}) => {
  const badgeColor = appearence === "heavy" ? primary : secondary;
  return {
    secondary: {
      background: grey[1000],
      text: grey[50],
      type: "cta",
      border: `1px solid ${grey[50]}`,
      hover: {
        background: grey[50],
        text: grey[1000]
      },
      focus: {
        border: `${grey[700]} solid 3px`
      },
      disabled: {
        background: grey[1000],
        text: grey[800],
        border: `1px solid ${grey[800]}`
      },
      badge: {
        background: badgeColor.main,
        text: badgeColor.contrastText,
        border: `1px solid ${
          appearence === "heavy" ? badgeColor.contrastText : secondary.main
        }`
      }
    }
  };
};

export const createSecondaryColoredActionButtonTheme = ({
  grey,
  primary
}: {
  grey: Palette["grey"];
  primary: Palette["primary"];
}) => {
  return {
    secondaryColored: {
      background: primary.lighter,
      text: primary.main,
      type: "cta",
      border: "none",
      hover: {
        background: primary.lighter,
        text: primary.main,
        border: `1px solid ${primary.main}`
      },
      focus: {
        border: `1px solid ${primary.main}`,
        background: primary.light
      },
      disabled: {
        background: primary.lighter,
        text: primary.light
      },
      badge: {
        background: primary.main,
        text: grey[1000],
        border: `1px solid ${grey[1000]}`
      }
    }
  };
};

export const createTertiaryActionButtonTheme = ({
  grey,
  blueGrey,
  primary,
  secondary,
  appearence
}: {
  grey: Palette["grey"];
  primary: Palette["primary"];
  secondary: Palette["secondary"];
  appearence: Palette["appearence"];
  blueGrey: Palette["blueGrey"];
}) => {
  const badgeColor = appearence === "heavy" ? primary : secondary;
  return {
    tertiary: {
      background: "none",
      text: grey[50],
      type: "ctaTertiary",
      border: "0px",
      hover: {
        background: blueGrey[900],
        text: grey[50]
      },
      focus: {
        border: `${grey[50]} solid 1px`
      },
      disabled: {
        text: grey[800]
      },
      badge: {
        background: badgeColor.main,
        text: badgeColor.contrastText,
        border: `1px solid ${
          appearence === "heavy" ? badgeColor.contrastText : secondary.main
        }`
      }
    }
  };
};

export const createGlobalActionButtonTheme = ({
  primary,
  secondary,
  grey
}: {
  primary: Palette["primary"];
  secondary: Palette["secondary"];
  grey: Palette["grey"];
}) => {
  return {
    global: {
      background: primary.lighter,
      text: primary.dark,
      type: "cta",
      border: `1px solid ${primary.lighter}`,
      hover: {
        background: primary.lighter,
        border: `1px solid ${primary.dark}`,
        text: primary.dark
      },
      focus: {
        text: grey[1000],
        border: `1px solid ${primary.darker}`,
        background: primary.darker
      },
      disabled: {
        background: primary.lighter,
        text: primary.light,
        border: primary.lighter
      },
      badge: {
        background: secondary.main,
        text: secondary.contrastText,
        border: `1px solid transparent`
      }
    }
  };
};
