import styled from "styled-components";

import { STriangleProps } from "./types";

export const Tooltip = styled.div`
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  background-color: ${({ theme }) => theme.tooltip.background};
  color: ${({ theme }) => theme.tooltip.text};
  & span {
    margin-bottom: 7px;
  }
`;
export const Triangle = styled.div<STriangleProps>`
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${({ theme, side }) => theme.tooltip[side].borderWidth};
  border-color: ${({ theme, side }) => theme.tooltip[side].borderColor};
  margin-left: ${({ side, position }) =>
    (side === "top" || side === "bottom") && position === "right"
      ? "calc(300px - 40px - 18px)"
      : "0px"};
  margin-top: 0px;
  top: ${({ side }) => (side === "top" ? "-12px" : "")};
  bottom: ${({ side, position }) =>
    side === "bottom"
      ? "-12px"
      : (side === "left" || side === "right") && position === "bottom"
        ? "20px"
        : ""};
  left: ${({ side }) => (side === "left" ? "-12px" : "")};
  right: ${({ side }) => (side === "right" ? "-12px" : "")};
`;
