import { observer } from "mobx-react";
import React from "react";
import { UnloggedLanguageSelector } from "src/js/components/unlogged/UnloggedLanguageSelector";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import WeschoolLogo from "src/img/icons/svg/weschool_logo.svg";
import * as S from "./UnloggedLayout.styles";

const UnloggedLayout = ({ children, inviteInfo }) => {
  const {
    UIStore: { isLayoutModeMobile },
    ThemeStore: { customizationSettings }
  } = useStores();
  const { colors } = useTheme();
  const layoutColor = customizationSettings?.layoutColor;
  const hasGroupInvitation = inviteInfo && inviteInfo.groupData?.group;

  return (
    <S.WrapperUnloggedDiv
      backgroundColor={layoutColor}
      isLayoutModeMobile={isLayoutModeMobile}
      hasGroupInvitation={hasGroupInvitation}
    >
      {!hasGroupInvitation && (
        <S.LanguageSelector>
          <UnloggedLanguageSelector />
        </S.LanguageSelector>
      )}
      {isLayoutModeMobile && !hasGroupInvitation ? (
        <S.MobileLogoWrapper>
          <WeschoolLogo color={colors.grey[1000]} />
        </S.MobileLogoWrapper>
      ) : null}
      {children}
    </S.WrapperUnloggedDiv>
  );
};

export default observer(UnloggedLayout);
