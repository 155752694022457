export function getLocalizedUniversityName(university, lang) {
  if (university.DisplayNames) {
    if (university.DisplayNames.length === 1) {
      return university.DisplayNames[0].value;
    }
    const universityName = university.DisplayNames.find(
      name => name.lang === lang
    );

    if (typeof universityName === "undefined") {
      const universityEng = university.DisplayNames.find(
        name => name.lang === "en"
      );
      if (typeof universityEng === "undefined") {
        return university.DisplayNames[0].value;
      }
      return universityEng.value;
    }
    return universityName.value;
  }
  return "";
}

export function getUniversityLargestLogo(university) {
  if (university.Logos) {
    const maxPeak = university.Logos.reduce((p, c) =>
      p.width > c.width ? p : c
    );
    return maxPeak.value;
  }
  return "";
}
