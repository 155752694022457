import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { GroupJoinWithCodeForm } from "../GroupJoinWithCodeForm";

import * as S from "./GroupJoinWithCodeModal.styles";

type GroupJoinWithCodeModalProps = {
  closeModal: () => void;
};

const GroupJoinWithCodeModal = ({
  closeModal
}: GroupJoinWithCodeModalProps) => {
  const { translate } = useTranslation();
  return (
    <ModalLayout
      labels={{
        title: translate("join_group_button"),
        mobileClose: translate("close")
      }}
      closeModal={closeModal}
    >
      <S.ModalBody>
        <GroupJoinWithCodeForm onSuccessCallback={closeModal} />
      </S.ModalBody>
    </ModalLayout>
  );
};

export default GroupJoinWithCodeModal;
