export const RESOURCE_ICON_TYPE = {
  LINK: "LINK",
  FILE: "FILE",
  DROPBOX: "DROPBOX",
  GOOGLE_DRIVE: "GOOGLE_DRIVE",
  GDRIVE: "GDRIVE",
  GOOGLE_MAPS: "GOOGLE_MAPS",
  BOARD: "BOARD",
  EXERCISE: "EXERCISE"
};

export const RESOURCE_ICON_SIZE = {
  CALENDAR_ATTACHMENT: {
    container: 34,
    icon: 20
  }
};
