import { ChipVariant } from "@arcadia/design-system";
import { StringVariables, TranslationKeys } from "src/js/translation";

export const PlanCardChipType = {
  PlanCancelled: "PLAN_CANCELLED",
  MostPopular: "MOST_POPULAR",
  WrongSeatsNumber: "WRONG_SEATS_NUMBER",
  PlanActive: "PLAN_ACTIVE",
  TrialPeriod: "TRIAL_PERIOD"
} as const;
export type PlanCardChipType =
  (typeof PlanCardChipType)[keyof typeof PlanCardChipType];

export type PlanCardChipTypes = {
  [key in PlanCardChipType]: {
    label: TranslationKeys;
    variant: ChipVariant;
  };
};

export type PlanCardChipProps = {
  type: PlanCardChipType;
  stringVariables?: StringVariables;
};
