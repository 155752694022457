import styled from "styled-components";

export const CorrectionHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: initial;
  }

  @media screen and (max-width: 450px) {
    margin-top: 26px;
  }
`;

export const CorrectionfromNow = styled.div`
  margin-left: 10px;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;

export const CorrectionText = styled.div`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 14px;
`;

export const CorrectionFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const CorrectionVote = styled.div`
  display: flex;
  margin-right: 20px;
  align-items: center;
`;

export const QuickActionContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  & div + div {
    margin-left: 5px;
  }
`;
