import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import each from "lodash/each";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import indexOf from "lodash/indexOf";
import difference from "lodash/difference";
import sortBy from "lodash/sortBy";
import renderLatex from "src/js/modules/mathjaxFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { ActionButton, Button } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { modalMessage } from "../../../models/modalMessageModel";
import { __ } from "../../../modules/localization";

const QuizCrosswords = withTheme(
  withBackbone(
    createReactClass({
      grid: [],
      placeholderChar: "_", // default. viene preso dalle props del quiz in DidMount.
      getInitialState() {
        return {
          activeWordIndex: null,
          zoom: 1 /** {Number} The decimal zoom used to view the crossword */
        };
      },
      componentDidMount() {
        const self = this;
        this.placeholderChar = this.props.quiz.placeholderChar; // aggiorno valore placeholder
        // basePageModel.on("change:breakpoint", this.updateBP);
      },
      componentDidUpdate() {
        renderLatex();
      },
      componentWillUnmount() {
        // basePageModel.off("change:breakpoint", this.updateBP);
      },
      updateBP() {
        this.forceUpdate();
      },
      setAnswer(e) {
        const answer = e.target.value;
        this.props.quiz.setUserAnswer(answer);
      },

      /** Reduce the zoom level */
      zoomOut() {
        let zoom = this.getZoom();
        zoom -= 0.1;
        this.setZoom(zoom);
      },

      /** Increase the zoom level */
      zoomIn() {
        let zoom = this.getZoom();
        zoom += 0.1;
        this.setZoom(zoom);
      },

      /**
       * Set the new zoom and updates the view
       * @param {Number} zoom The new decimal zoom value
       */
      setZoom(zoom) {
        this.setState(
          {
            zoom
          },
          () => {
            $(".js-zoomable").css("zoom", zoom);
            $(".js-zoomable").css("-moz-transform", `scale(${zoom})`);
            $(".js-zoomable").css("transform-origin", "top left");
          }
        );
      },

      /**
       * @return {Number} The current decimal zoom.
       */
      getZoom() {
        return this.state.zoom;
      },

      keywordToLetters(word) {
        let a;
        let key;
        let keyint;
        let letter;
        let p;
        let posX;
        let posY;
        let result;
        if (word.orientation == undefined) {
          word.orientation = "horizontal";
        }
        if (word.x === undefined) {
          word.x = 0;
        }
        if (word.y === undefined) {
          word.y = 0;
        }
        result = [];
        a = word.value.split("");
        for (key in a) {
          letter = a[key];
          keyint = parseInt(key);
          if (word.orientation === "horizontal") {
            posX = word.x + keyint;
            posY = word.y;
          } else {
            posX = word.x;
            posY = word.y + keyint;
          }
          result.push({
            letter,
            posX,
            posY
          });
        }
        return result;
      },
      setWordFromModal() {
        const self = this;
        const index = this.state.activeWordIndex;
        let word = "";
        $(".crossword__single-input").each(function () {
          let v = $(this).val();
          if (v === "") v = self.placeholderChar;
          word += v;
        });
        this.setWordByIndex(index, word);
        modalMessage.hide();
        this.setState({ activeWordIndex: null });
      },
      setWordByIndex(index, word) {
        const { quiz } = this.props;
        const questions = quiz.getQuestions();
        const currentQuestion = find(questions, { index });
        if (currentQuestion !== undefined) {
          const { x } = currentQuestion;
          const { y } = currentQuestion;
          const l = currentQuestion.length;
          const o = currentQuestion.orientation;
          for (let i = 0; i < l; i++) {
            const value =
              word[i] === undefined ? self.placeholderChar : word[i];
            if (o === "vertical") {
              quiz.setUserInputBySquare(value, y + i, x);
            } else {
              quiz.setUserInputBySquare(value, y, x + i);
            }
          }
        }
        this.forceUpdate();
      },
      getWordByIndex(index) {
        let word = "";
        const { quiz } = this.props;
        const questions = quiz.getQuestions();
        const currentQuestion = find(questions, { index });
        if (currentQuestion !== undefined) {
          const { x } = currentQuestion;
          const { y } = currentQuestion;
          const l = currentQuestion.length;
          const o = currentQuestion.orientation;
          for (let i = 0; i < l; i++) {
            let letter = "";
            if (o === "vertical") {
              letter = quiz.getUserInputBySquare(y + i, x);
            } else {
              letter = quiz.getUserInputBySquare(y, x + i);
            }
            if (letter === null) {
              word += this.placeholderChar;
            } else {
              word += letter;
            }
          }
        }
        return word;
      },
      clickedSquare(row, col, forcedIndex) {
        const self = this;
        let word = {};
        const questions = this.props.quiz.getQuestions();

        if (typeof forcedIndex === "number") {
          word = find(questions, { index: forcedIndex });
          const row = word.y;
          const col = word.x;
          const square = this.grid[row][col];
        } else {
          const square = this.grid[row][col];
          const { words } = square;
          if (words.length > 1) {
            if (square.isFirstLetter) {
              word = find(words, { index: square.firstLetterOf[0] });
            } else {
              word = find(words, { orientation: "horizontal" }); // se più di una parola, prendo orizzontale
            }
          } else {
            word = words[0];
          }
        }

        const currentQuestion = find(questions, { index: word.index });
        const currentSolution = this.getWordByIndex(word.index);

        // cerco caratteri diversi dal placeholder. se non ce ne sono, quindi === undefined, allora segno come mai compilato
        const definitionIsEmpty =
          find(currentSolution, l => l !== self.placeholderChar) === undefined;

        this.setState({ activeWordIndex: word.index }, () => {
          let jSquare = $(
            `.js-crossword-square[data-row=${row}][data-col=${col}]`
          );
          jSquare.focus();
          if (getBreakpoint() !== "desktop") {
            // <input id="js-cw-modal-word" type="text" className="form-control" style={{"textTransform": "uppercase"}} placeholder={  __("inserisci la tua risposta")} maxLength={currentQuestion.length}/>
            const body = (
              <div>
                <label>{currentQuestion.value}</label>
                <div className="crossword__input-container js-cw-inputs clearfix">
                  <div style={{ width: `${30 * currentSolution.length}px` }}>
                    {map(currentSolution, (l, i) => {
                      let defval = "";
                      if (l === self.placeholderChar) {
                        defval = " ";
                      } else {
                        defval = l;
                      }

                      return (
                        <input
                          type="text"
                          className="form-control pull-left crossword__single-input clickable"
                          key={["word", row, col, i].join("-")}
                          defaultValue={defval}
                          data-index={i}
                          onChange={self.handleSingleInputChange}
                          onFocus={self.handleSingleInputFocus}
                          onBlur={self.handleSingleInputBlur}
                          data-oldvalue={defval}
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            );

            const footer = (
              <div className="flex-spaced-column">
                <Button
                  type="button"
                  variant="secondary"
                  theme={this.props.theme.whiteTheme}
                  onClick={function () {
                    modalMessage.hide();
                    self.setState({ activeWordIndex: null });
                  }}
                >
                  {__("Annulla")}
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  theme={this.props.theme.whiteTheme}
                  onClick={self.setWordFromModal}
                >
                  {__("Conferma")}
                </Button>
              </div>
            );

            modalMessage.reset();
            modalMessage.set({
              body: { content: body, type: "REACT" },
              footer: { content: footer, type: "REACT" },
              addCloseButton: false
            });
            modalMessage.show();
            window.setTimeout(() => {
              $(".crossword__single-input").eq(0).trigger("focus");
              jSquare.blur();
            }, 500);
          } else {
            if (definitionIsEmpty) {
              // se non è completamente compilata metto focus al primo carattere
              let initialSquare;
              each(self.grid, (sq, row_n) => {
                each(sq, (s, col_n) => {
                  if (!isEmpty(s)) {
                    const indexes = map(s.words, "index");
                    const firstLettersIndexes = s.firstLetterOf;

                    if (
                      initialSquare === undefined &&
                      indexOf(indexes, word.index) >= 0 &&
                      s.isFirstLetter &&
                      indexOf(firstLettersIndexes, word.index) >= 0
                    ) {
                      initialSquare = {
                        x: col_n,
                        y: row_n
                      };
                    }
                  }
                });
              });

              if (initialSquare !== undefined) {
                jSquare = $(
                  `.js-crossword-square[data-row=${initialSquare.y}][data-col=${initialSquare.x}]`
                );
              }
            }

            if (jSquare.length > 0) jSquare.focus();
          }
        });
      },
      handleSingleInputFocus(e) {
        const currentInput = e.currentTarget;
        currentInput.setAttribute("data-oldvalue", currentInput.value);
        currentInput.value = "";
        // currentInput.select();
      },
      handleSingleInputBlur(e) {
        const currentInput = e.currentTarget;
        currentInput.value = currentInput.getAttribute("data-oldvalue");
      },
      handleSingleInputChange(e) {
        const currentInput = e.currentTarget;
        const index = currentInput.getAttribute("data-index");
        const oldValue = currentInput.getAttribute("data-oldvalue");
        const value = currentInput.value.toUpperCase();
        let newValue = value;

        if (oldValue !== null) {
          const oldChars = oldValue.split("");
          const newChars = value.split("");
          const diff = difference(newChars, oldChars);
          if (diff.length > 0) {
            newValue = diff[0];
          }
        }

        if (newValue.length > 1) newValue = newValue.substr(0, 1);

        newValue = newValue.toUpperCase();

        currentInput.value = newValue;
        currentInput.setAttribute("data-oldvalue", newValue);

        if (newValue !== "") {
          const next = $(
            `.crossword__single-input[data-index=${parseInt(index) + 1}]`
          );
          if (next.length > 0) {
            $(next).trigger("focus");
            // le prossime 2 righe evitano che comparsa tastiera sposti focus
            $(next)[0].style.webkitTransform = "translate3d(0px,-10000px,0)";
            webkitRequestAnimationFrame(() => {
              $(next)[0].style.webkitTransform = "";
            });
          }
        }
      },
      insertInput(row, col, e) {
        const currentSquare = $(
          `.js-crossword-square[data-row=${row}][data-col=${col}]`
        );
        const currentInput = e.currentTarget;

        const oldValue = currentInput.getAttribute("data-oldvalue");
        const value = currentInput.value.toUpperCase();
        let newValue = value;
        // currentSquare.val(value.substr(value.length - 1)); //risetto input dopo averlo troncato ad un char.

        if (oldValue !== null) {
          const oldChars = oldValue.split("");
          const newChars = value.split("");
          const diff = difference(newChars, oldChars);
          if (diff.length > 0) {
            newValue = diff[0];
          }
        }

        // alert("oldchars="+oldValue+", newchars="+value+", differenceTS="+diff.toString()+", quindi value è"+newValue);

        if (newValue.length > 1) newValue = newValue.substr(0, 1);
        if (newValue.length === 0) newValue = self.placeholderChar;

        newValue = newValue.toUpperCase();

        currentInput.value = newValue;
        currentInput.setAttribute("data-oldvalue", newValue);
        this.props.quiz.setUserInputBySquare(newValue, row, col); // salvo valore inserito dall'utente

        const square = this.grid[row][col];
        const selectedWord = find(square.words, {
          index: this.state.activeWordIndex
        });
        const nextSquare = {};
        if (selectedWord.orientation === "horizontal") {
          nextSquare.x = col + 1;
          nextSquare.y = row;
        } else {
          nextSquare.x = col;
          nextSquare.y = row + 1;
        }
        const jSquare = $(
          `.js-crossword-square[data-row=${nextSquare.y}][data-col=${nextSquare.x}]`
        );

        if (jSquare.length > 0) {
          jSquare.focus().select();
          this.forceUpdate();

          // le prossime 2 righe evitano che comparsa tastiera sposti focus
          // jSquare[0].style.webkitTransform = 'translate3d(0px,-10000px,0)';
          // webkitRequestAnimationFrame(function() { jSquare[0].style.webkitTransform = ''; }.bind(this));
        } else {
          // fine parola
          this.setState({
            activeWordIndex: null
          });
        }
      },
      toggleWord(index) {
        const self = this;
        this.setState({ activeWordIndex: index }, () => {
          self.clickedSquare(0, 0, index);
        });
      },
      focusInput(e) {
        e.target.select();
      },
      checkValidKeycode(keycode) {
        /*
        var valid =
          (keycode > 47 && keycode < 58)   || // number keys
          keycode == 32 || keycode == 13   || // spacebar & return key(s) (if you want to allow carriage returns)
          (keycode > 64 && keycode < 91)   || // letter keys
          (keycode > 95 && keycode < 112)  || // numpad keys
          (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
          (keycode > 218 && keycode < 223);   // [\]' (in order)
        */

        const valid = keycode > 64 && keycode < 122; // solo lettere

        return valid;
      },
      render() {
        const self = this;
        const { quiz } = this.props;
        let questions = quiz.getQuestions();
        questions = sortBy(questions, q => q.index); // riordino per index
        const words = quiz.getSolutions(); // in realtà dovrebbe esserci param lunghezza parola
        const rows = [];
        let cols = [];
        const { greyTheme } = this.props.theme;

        const selectedWordsSquares = [];

        const grid = quiz.populateGrid();
        this.grid = grid;

        each(grid, (row, row_n) => {
          cols = [];
          each(row, (col, col_n) => {
            if (isEmpty(col)) {
              cols.push(<div className="crossword__letter empty" />);
            } else {
              const indexes = map(col.words, "index");
              const userInput = quiz.getUserInputBySquare(row_n, col_n);
              let defval = userInput;
              if (defval === self.placeholderChar) {
                defval = col.letter;
                if (defval === self.placeholderChar) {
                  defval = " ";
                }
              }
              if (indexOf(indexes, self.state.activeWordIndex) >= 0) {
                // appartiene ad una parola selezionata
                const squareToAdd = (
                  <input
                    type="text"
                    className="crossword__letter active js-crossword-square clickable"
                    data-row={row_n}
                    data-col={col_n}
                    defaultValue={defval}
                    onClick={self.clickedSquare.bind(null, row_n, col_n)}
                    onFocus={self.handleSingleInputFocus}
                    onBlur={self.handleSingleInputBlur}
                    onChange={self.insertInput.bind(null, row_n, col_n)}
                    data-oldvalue={userInput || col.letter}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                  />
                );

                cols.push(
                  <a
                    className="crossword__letter clickable is-active"
                    onClick={self.clickedSquare.bind(null, row_n, col_n)}
                  >
                    {defval}
                    {col.isFirstLetter ? (
                      <sup className="crossword__number">
                        {col.firstLetterOf[0] + 1}
                      </sup>
                    ) : (
                      ""
                    )}
                  </a>
                );
                selectedWordsSquares.push(squareToAdd);
              } else {
                cols.push(
                  <a
                    className="crossword__letter clickable"
                    onClick={self.clickedSquare.bind(null, row_n, col_n)}
                  >
                    {defval}
                    {col.isFirstLetter ? (
                      <sup className="crossword__number">
                        {col.firstLetterOf[0] + 1}
                      </sup>
                    ) : (
                      ""
                    )}
                  </a>
                );
              }
            }
          });
          rows.push(
            <div
              className="clearfix crossword__row"
              style={{ minWidth: `${cols.length * 30}px` }}
            >
              {cols}
            </div>
          );
        });

        return (
          <div className="row">
            <div className="clearfix">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 crossword__wrapper-col">
                    <div className="crossword__wrapper">
                      <div className="crossword" style={{ overflow: "auto" }}>
                        <div className="js-zoomable">{rows}</div>
                      </div>
                      <div className="crossword__zoom-buttons text-center">
                        <ActionButton
                          theme={greyTheme}
                          variant="secondary"
                          onClick={this.zoomOut}
                          icon="minus"
                        />
                        <ActionButton
                          theme={greyTheme}
                          variant="secondary"
                          onClick={this.zoomIn}
                          icon="plus"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 crossword__info-col">
                    <div className="crossword__info mathjax-container">
                      {map(questions, q => {
                        if (q.index === self.state.activeWordIndex) {
                          const currentSolution = self.getWordByIndex(
                            self.state.activeWordIndex
                          );
                          return (
                            <div>
                              <div className="crossword__current-definition">
                                <div
                                  className="app__subtitle"
                                  style={{ textTransform: "none" }}
                                >
                                  {q.index + 1}. {q.value}
                                </div>
                              </div>

                              <div className="crossword__current-squares-block">
                                <div>
                                  <div className="js-cw-inputs clearfix">
                                    <div
                                      className="crossword__current-squares-wrapper"
                                      style={{
                                        width: `${
                                          40 * currentSolution.length
                                        }px`
                                      }}
                                    >
                                      {selectedWordsSquares}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}

                      <div className="crossword__definitions">
                        <div className="row">
                          <div className="col-sm-12">
                            <h4 className="app__subtitle">
                              {__("orizzontali")}{" "}
                            </h4>
                            {map(questions, q => {
                              if (q.orientation === "horizontal") {
                                if (q.index === self.state.activeWordIndex) {
                                  return (
                                    <div>
                                      <a className="crossword__definition is-selected clickable">
                                        {q.index + 1}. {q.value}
                                      </a>
                                    </div>
                                  );
                                }
                                return (
                                  <div>
                                    <a
                                      className="crossword__definition clickable"
                                      onClick={self.toggleWord.bind(
                                        null,
                                        q.index
                                      )}
                                    >
                                      {q.index + 1}. {q.value}
                                    </a>
                                  </div>
                                );
                              }
                            })}
                            <br />
                          </div>
                          <div className="col-sm-12">
                            <h4 className="app__subtitle">
                              {__("verticali")}{" "}
                            </h4>
                            {map(questions, q => {
                              if (q.orientation === "vertical") {
                                if (q.index === self.state.activeWordIndex) {
                                  return (
                                    <div>
                                      <a className="crossword__definition is-selected clickable">
                                        {q.index + 1}. {q.value}
                                      </a>
                                    </div>
                                  );
                                }
                                return (
                                  <div>
                                    <a
                                      className="crossword__definition clickable"
                                      onClick={self.toggleWord.bind(
                                        null,
                                        q.index
                                      )}
                                    >
                                      {q.index + 1}. {q.value}
                                    </a>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    })
  )
);

export default QuizCrosswords;
