export function checkPasswordStrength(value: string) {
  try {
    let passwordStrength = 0;
    if (value.length > 0) {
      passwordStrength += 1;
    }
    if (value.length > 4) {
      passwordStrength += 1;

      if (value.match(/[0-9]+/)) {
        passwordStrength += 2;
      }
      if (!/([a-zA-Z0-9!#@$%^&£*)(+=._-]).*?\1/.test(value)) {
        passwordStrength += 2;
      }
      if (value.match(/[!#@$%^&£*)(+=._-]/)) {
        passwordStrength += 2;
      }

      if (value.length > 8) {
        passwordStrength += 1;
      }
    }
    return passwordStrength;
  } catch {
    return 0;
  }
}
