import { Heading, Icon, Text, Toggle } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStores } from "src/js/hooks";
import getUserClient from "src/js/modules/connection";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  api_groups_settings,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { useTranslation } from "src/js/translation";
import {
  GroupSectionsEnum,
  GroupSectionsType,
  GroupSettingsVisibilityEnum,
  GroupVisibilityEnum
} from "src/js/types";
import { useTheme } from "styled-components";
import { GROUP_SETTINGS_SECTIONS } from "./GroupSettingsSections.const";
import * as S from "./GroupSettingsSections.styles";

const GroupSettingsSections = () => {
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: {
      groupId,
      settings,
      setActiveGroupSettings,
      isGroupSectionVisible,
      isWhiteboardEnabled
    }
  } = useStores();
  const { whiteTheme: theme } = useTheme();
  const { translate } = useTranslation();
  const [isSectionRequestPending, setIsSectionRequestPending] = useState(false);

  const groupSectionsVisibility = Object.values(GroupSectionsEnum).reduce(
    (acc, key) => {
      acc[key] = isGroupSectionVisible(key);
      return acc;
    },
    {} as Record<GroupSectionsType, boolean>
  );

  const handleSectionVisibility = (section: GroupSectionsType) => {
    if (isSectionRequestPending) {
      return;
    }
    const countSectionsVisible = Object.values(groupSectionsVisibility).filter(
      value => value
    ).length;
    if (
      countSectionsVisible === 2 &&
      groupSectionsVisibility[section] &&
      groupSectionsVisibility[GroupSectionsEnum.Whiteboard] &&
      section !== GroupSectionsEnum.Whiteboard
    ) {
      showToastError({
        str: translate("group_settings_section_message_whiteboard_error")
      });
      return;
    }
    if (countSectionsVisible === 1 && groupSectionsVisibility[section]) {
      showToastError({
        str: translate("group_settings_section_message_hidden_error")
      });
      return;
    }

    const visibilityKey = GroupSettingsVisibilityEnum[section];
    setIsSectionRequestPending(true);
    getUserClient()
      .patch(getVersionedApiUrl(api_groups_settings({ groupId }), "v3"), {
        [visibilityKey]: groupSectionsVisibility[section]
          ? GroupVisibilityEnum.Hidden
          : GroupVisibilityEnum.Visible
      })
      .finally(() => setIsSectionRequestPending(false))
      .then(({ data }) => {
        setActiveGroupSettings({ ...settings, ...data });
        showToastSuccess({
          str: translate("group_settings_section_message_hidden_success")
        });
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(error, translate("general_error_retry"))
        });
      });
  };

  const groupSettingSections = isWhiteboardEnabled
    ? GROUP_SETTINGS_SECTIONS
    : GROUP_SETTINGS_SECTIONS.filter(
        ({ id }) => id !== GroupSectionsEnum.Whiteboard
      );

  return (
    <>
      <S.HeadingWrapper>
        <Heading level="5">
          {translate("group_settings_sections_title")}
        </Heading>
      </S.HeadingWrapper>
      <Text type={isLayoutModeMobile ? "formTitle" : "formField"}>
        {translate("group_settings_sections_subtitle")}
      </Text>
      <S.ListWrapper>
        {settings
          ? groupSettingSections.map(section => (
              <S.SectionCard key={`section-${section.id}`}>
                <S.SectionCardContent>
                  <S.IconWrapper>
                    <Icon
                      initialViewbox
                      icon={section.icon}
                      width={20}
                      height={20}
                    />
                  </S.IconWrapper>
                  <S.SectionCardContentText>
                    <S.StyledSectionTitle type="subHeaderSubtitle">
                      {translate(section.title)}
                    </S.StyledSectionTitle>
                    <S.StyledSectionDescription>
                      <Text
                        type={
                          isLayoutModeMobile
                            ? "formSmallCaption"
                            : "formDescription"
                        }
                      >
                        {translate(section.description)}
                      </Text>
                    </S.StyledSectionDescription>
                  </S.SectionCardContentText>
                </S.SectionCardContent>
                <S.ToggleWrapper>
                  <Toggle
                    data-testid={`toggle-section-${section.id}`}
                    theme={theme}
                    title={`Disable ${section.id}`}
                    onChange={() => handleSectionVisibility(section.id)}
                    checked={groupSectionsVisibility[section.id]}
                    size="small"
                  />
                </S.ToggleWrapper>
              </S.SectionCard>
            ))
          : null}
      </S.ListWrapper>
    </>
  );
};

export default observer(GroupSettingsSections);
