import {
  NotificationModel,
  NotificationTypeModel,
  RealTimeNotificationModel
} from "src/js/types/models/Notification";

export const transformRealTimeNotificationToNotification = (
  realTimeNotification: RealTimeNotificationModel,
  defaultNotificationType: NotificationTypeModel = "Standard",
  isReadDefault: boolean = false,
  isDeliveredDefault: boolean = true,
  defaultCreatedAt: number = Date.now() / 1000,
  defaultUpdatedAt: number = Date.now() / 1000
): NotificationModel => {
  const {
    title,
    message,
    id,
    senderAvatar,
    redirectUrl,
    area,
    groupId,
    senderFullName
  } = realTimeNotification;

  const [name, ...surnameParts] = senderFullName.split(" ");
  const surname = surnameParts.join(" ");

  return {
    id,
    type: defaultNotificationType,
    area,
    text: message,
    redirectUrl,
    isRead: isReadDefault,
    isDelivered: isDeliveredDefault,
    createdAt: defaultCreatedAt,
    updatedAt: defaultUpdatedAt,
    sender: {
      uuid: "",
      name,
      surname,
      avatar: {
        originalUrl: senderAvatar,
        small: senderAvatar,
        medium: senderAvatar,
        large: senderAvatar
      }
    },
    group: {
      id: groupId,
      name: title,
      cover: null,
      badge: null
    }
  };
};
