import { Heading, Text } from "@arcadia/design-system";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { useOnClickOutSide } from "src/js/hooks";
import { useDeviceDimensions } from "src/js/hooks/useDeviceDetector";
import useScrollDetection from "src/js/hooks/useScrollDetection";
import { useTranslation } from "src/js/translation";
import * as S from "./SpacesWelcomeHelper.styles";
import {
  SpacesWelcomeHelperProps,
  SpacesWelcomeHelperRef
} from "./SpacesWelcomeHelper.types";

const SpacesWelcomeHelper = forwardRef<
  SpacesWelcomeHelperRef,
  SpacesWelcomeHelperProps
>(({ title, text }, ref) => {
  const { translate } = useTranslation();

  const [waitClickOutside, setWaitClickOutside] = useState(false);
  const [showPopup, setShowPopup] = useState<{ x: number; y: number }>(null);
  const refPopup = useRef(null);

  const { sizes } = useDeviceDimensions();
  useEffect(() => {
    setShowPopup(null);
  }, [sizes]);
  useOnClickOutSide(refPopup, () => {
    setShowPopup(null);
  });

  useImperativeHandle(ref, () => ({
    hidePopup: () => {
      setShowPopup(null);
    }
  }));

  useScrollDetection(() => {
    setShowPopup(null);
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!showPopup) {
        setWaitClickOutside(false);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [showPopup]);

  const onClickHelpIcon = e => {
    if (waitClickOutside) {
      return;
    }
    setShowPopup({
      x: e.clientX,
      y: e.clientY
    });
    setWaitClickOutside(true);
  };

  return (
    <S.HelperWrap>
      <S.HelpIcon onClick={onClickHelpIcon} icon="help" />
      {showPopup && (
        <S.Popup
          top={`${showPopup?.y}px`}
          left={`${showPopup?.x}px`}
          ref={refPopup}
        >
          <S.PopupContent>
            <Heading level="6">{translate({ text: title })}</Heading>
            <Text>{translate({ text })}</Text>
          </S.PopupContent>
          <S.StyledArrowPopupIcon />
        </S.Popup>
      )}
    </S.HelperWrap>
  );
});

SpacesWelcomeHelper.displayName = "SpacesWelcomeHelper";

export default SpacesWelcomeHelper;
