import styled, { css } from "styled-components";

export const ModalScrollableArea = styled.div`
  max-height: calc(90vh - 90px);
  overflow-y: overlay;

  ${({ flexHeight, flexHeightOffset = 0 }) =>
    flexHeight &&
    css`
      flex-grow: 1;
      max-height: calc(100vh - ${flexHeightOffset}px);
      min-height: 0; // for Firefox
    `}

  ${({ maxHeight, maxHeightOffset = 0 }) =>
    maxHeight &&
    css`
      max-height: min(${maxHeight}px, calc(100vh - ${maxHeightOffset}px));
    `}

  // rules for firefox scrollbar
  @supports (-moz-appearance: none) {
    overflow-y: auto;
    // prettier was going crazy with theme variables inserted here
    scrollbar-color: #c9cfdf #f5f7fd;
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3};
    border: 8px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    min-height: 50px;
    &:hover {
      background: ${({ theme }) => theme.colors.accent2};
      background-clip: padding-box;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.accent4};
    border: 8px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
