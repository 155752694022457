import React from "react";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import {
  ActionButton,
  AvatarWithInitials,
  Box,
  Heading,
  Icon
} from "@arcadia/design-system";
import { motion } from "framer-motion";
import { useAppLayout } from "src/js/layout/AppLayout";
import { Filters } from "./components/Filters";
import { Notification } from "./components/Notification";
import { useNotificationsPage } from "./hooks";

import * as S from "./NotificationsPage.styles";

const NotificationsPage = () => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { headerHeight } = useAppLayout();
  const {
    notifications,
    scrollableRef,
    isLoading,
    isLoadingNextPage,
    activeSpace,
    toggleNotificationStatus,
    goBack
  } = useNotificationsPage();

  const goToSettings = () => {
    navigateTo(createUrl("user_settings", { option: "notice" }));
  };

  return (
    <Box width="100%">
      <SecondaryBar
        position="sticky"
        top={headerHeight}
        leftSection={
          <ActionButton
            variant="secondaryColored"
            icon="chevronLeft"
            theme={whiteTheme}
            onClick={goBack}
          />
        }
        middleSection={
          <Box display="flex" justifyContent="center">
            <Heading level="5">
              {translate("notifications_section_all_notifications_title")}
            </Heading>
          </Box>
        }
        rightSection={
          isLayoutModeMobile && (
            <S.SettingsMobileButton onClick={goToSettings}>
              <Icon icon="gear" width={20} height={20} initialViewbox />
            </S.SettingsMobileButton>
          )
        }
      />

      <S.StyledMainContainer paddingTop="56px" paddingTopMobile="60px">
        <S.NotificationPageWrapper isLayoutModeMobile={isLayoutModeMobile}>
          <S.TopSection isLayoutModeMobile={isLayoutModeMobile}>
            <S.SpaceInfo>
              {!activeSpace ? null : (
                <>
                  <AvatarWithInitials
                    name={activeSpace.space?.name || ""}
                    avatarColor={activeSpace.space?.defaultBadgeColor}
                    alt={activeSpace.space.name}
                    size="small"
                    theme={whiteTheme}
                    url={activeSpace.space?.badge?.small as string}
                  />
                  <Heading level="3">{activeSpace.space?.name}</Heading>
                </>
              )}
            </S.SpaceInfo>
            <Filters />
          </S.TopSection>
          <S.NotificationWrapper ref={scrollableRef}>
            {notifications.length === 0 && !isLoading && (
              <S.NoNotifications
                title={translate("notifications_drawer_no_notifications_title")}
                subtitle={translate(
                  "notifications_drawer_no_notifications_subtitle"
                )}
              />
            )}

            {notifications.length > 0 &&
              !isLoading &&
              notifications.map(notification => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onMarkRead={() =>
                    toggleNotificationStatus(
                      notification.id,
                      notification.isRead
                    )
                  }
                  onMarkUnread={() =>
                    toggleNotificationStatus(
                      notification.id,
                      notification.isRead
                    )
                  }
                />
              ))}
            {isLoading &&
              Array.from({ length: 10 }).map((_, i) => (
                <motion.div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Notification loading />
                </motion.div>
              ))}
            {isLoadingNextPage &&
              Array.from({ length: 10 }).map((_, i) => (
                <motion.div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Notification loading />
                </motion.div>
              ))}
          </S.NotificationWrapper>
        </S.NotificationPageWrapper>
      </S.StyledMainContainer>
    </Box>
  );
};

export default observer(NotificationsPage);
