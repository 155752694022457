import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const SearchUsersDropdownEmpty = styled.div`
  padding: 20px;
  span {
    display: block;
    overflow: hidden;
  }
  mark {
    all: unset;
    color: ${({ theme }) => theme.primaryColor[500]};
    overflow: hidden;
  }
`;

export const Option = styled.div<{
  isSelected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  border-radius: 8px;
  padding: 4px;
  scroll-margin: 12px;
  scroll-margin-block: 12px;
  cursor: pointer;
  > span {
    margin-left: 12px;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[900]};
    `};
`;

export const ScrollableWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 12px;
  max-height: 220px;
  ${scrollbarMixin(VariantType.Grey)};
  ::-webkit-scrollbar {
    width: 2em;
  }
  ::-webkit-scrollbar-thumb {
    border: 0.85em solid transparent;
  }
`;

export const SearchUsersDropdownWrapper = styled.div`
  position: absolute;
  top: 60px;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.primaryColor[700]};
  width: calc(100% - 40px);
  margin: 0 20px;
  box-sizing: border-box;
  z-index: 1;
`;
