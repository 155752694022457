import { Palette, SearchbarThemeOptions } from "@ds_themes/types";

export const createSearchbarTheme = (
  palette: Palette
): { searchbar: SearchbarThemeOptions } => {
  const { primary, grey, general } = palette;

  return {
    searchbar: {
      background: general.background,
      text: general.contrastText,
      textPlaceholder: grey[300],
      border: `1px solid ${general.background}`,
      focus: {
        outline: `1px solid ${primary.light}`
      },
      font: {
        style: "normal",
        weight: "400",
        size: "16px",
        lineHeight: "19px"
      }
    }
  };
};
