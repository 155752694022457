/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, useState } from "react";
import { useDebounce } from "src/js/hooks";
import { useS3Video } from "src/js/settings/settingsVideo";
import { useTheme } from "styled-components";
import * as S from "./VideoPlayer.styles";
import { VideoPlayerProps } from "./VideoPlayer.types";

const VideoPlayer: FC<VideoPlayerProps> = ({
  className,
  video,
  controlled = false
}) => {
  const { whiteTheme } = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const isLoadingDebounced = useDebounce(isLoading, 300);
  const { url, ratio } = useS3Video(video);

  return (
    <S.VideoPlayerWrapper
      hideVideo={isLoadingDebounced || error}
      ratio={ratio}
      className={className}
    >
      {isLoadingDebounced && <S.StyledDoodleLoader theme={whiteTheme} />}
      {error && !isLoadingDebounced && (
        <S.StyledIcon
          icon="illustration-ghost"
          height={130}
          width={98}
          initialViewbox
        />
      )}
      <video
        src={url}
        width="100%"
        autoPlay={!controlled}
        muted={!controlled}
        loop={!controlled}
        playsInline={!controlled}
        controls={controlled}
        disablePictureInPicture={controlled}
        controlsList={
          controlled ? "nodownload noremoteplayback noplaybackrate" : null
        }
        preload={controlled ? "metadata" : null}
        onLoadStart={() => setIsLoading(true)}
        onLoadedData={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          setError(true);
        }}
      />
    </S.VideoPlayerWrapper>
  );
};

export default VideoPlayer;
