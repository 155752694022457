import { CheckIndicatorContainer } from "src/js/components/global/CheckIndicator/CheckIndicator.styles";
import styled, { css } from "styled-components";
import { SListSelectorItem } from "./ListSelector.types";

export const ListSelectorItem = styled.div<SListSelectorItem>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0px 40px 0px 16px;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[800]};
  border-radius: 8px;
  font-size: 14px;
  min-height: 40px;

  &:hover {
    background-color: #eee;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${({ theme }) => theme.primaryColor[500]};
    `}

  & ${CheckIndicatorContainer} {
    position: absolute;
    right: 15px;
    transform: translateY(-50%);
    top: 50%;
  }
  @media (max-width: 767px) {
    min-height: 48px;
    & ${CheckIndicatorContainer} {
      right: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-right: 40px;
  }
`;
