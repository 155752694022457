import styled from "styled-components";

/**
 * Content
 */

export const CountryLanguageContentFooter = styled.div`
  margin-top: auto;
`;

export const CountryLanguageContentBody = styled.div`
  margin-bottom: 32px;
`;

export const CountryLanguageContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

/**
 * Modal
 */

export const CountryLanguageModalContainer = styled.div`
  padding: 20px;
`;

export const MobileWrapper = styled.div`
  padding: 32px 16px 40px;
  height: 100%;
`;
