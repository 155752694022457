import React from "react";
import GroupModel from "../models/groupModel";
import { InternalDispatcher } from "./dispatcher";

let activeGroup = new GroupModel();

export const getActiveGroupId = function () {
  return activeGroup.get("id") || 0;
};

export const getActiveGroup = function () {
  return activeGroup;
};

export const getActiveGroupJson = function () {
  return activeGroup ? activeGroup.attributes : null;
};

export const resetActiveGroup = function () {
  activeGroup = new GroupModel();
};

export const useGroup = function (group) {
  // console.log('iamusinggroup', group.get('name'));
  activeGroup = group;
  if (activeGroup.has("badge")) {
    // console.log(activeGroup.get('badge'));
    InternalDispatcher.trigger("change_badge", activeGroup.get("badge"));
  }

  InternalDispatcher.trigger("group:changed", group);
};
