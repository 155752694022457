import {
  FetchUserProps,
  fetchUserReaction
} from "src/js/repository/reactionRepository";
import { ReactionAuthor, ReactionType } from "src/js/types/models/Reaction";
import { useInfiniteScrollFetcher } from "src/js/hooks";
import { BasePaginationResponse } from "src/js/types";
import { useState } from "react";
import { structuredClone } from "src/js/modules/commonFunction";
import { REACTION_MODAL_TAB, ReactionModalTabType } from "../Reactions.types";

const useUserReactionList = ({
  limit,
  reactableEntity,
  reactedId
}: Pick<FetchUserProps, "limit" | "reactableEntity" | "reactedId">) => {
  const [userReactionMap, setUserReactionMap] = useState(
    new Map<ReactionModalTabType, BasePaginationResponse<ReactionAuthor>>([])
  );

  // Needed to handle paginations outside the infinite scroll fetcher
  let hasNextAux = false;
  const { fetch, isLoading, fetchNextPage } = useInfiniteScrollFetcher<
    ReactionAuthor[],
    FetchUserProps
  >(
    async ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      page: _,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      limit,
      offset,
      reactionType
    }) => {
      const res = await fetchUserReaction({
        limit,
        offset,
        reactedId,
        reactableEntity,
        reactionType
      });
      hasNextAux = res.hasNext;
      return res.results;
    },
    {
      onSuccess: (reactionList, { reactionType }) => {
        const auxUserReactionMap = structuredClone(userReactionMap);
        const type = reactionType || REACTION_MODAL_TAB.ALL;

        if (userReactionMap.has(type)) {
          auxUserReactionMap.set(type, {
            results: [...userReactionMap.get(type).results, ...reactionList],
            hasNext: hasNextAux
          });
        } else {
          auxUserReactionMap.set(type, {
            results: [...reactionList],
            hasNext: hasNextAux
          });
        }
        hasNextAux = false;

        setUserReactionMap(auxUserReactionMap);
      },
      limit: limit || 10,
      lazy: true
    }
  );

  const initUserReaction = (reactionType?: ReactionType) => {
    if (userReactionMap.has(reactionType || REACTION_MODAL_TAB.ALL)) return;
    fetch(0, { limit, reactableEntity, reactedId, offset: 0, reactionType });
  };

  const getUserReactionList = (reactionType: ReactionModalTabType) => {
    return (
      userReactionMap?.get(reactionType) || {
        results: [] as ReactionAuthor[],
        hasNext: false
      }
    );
  };

  const nextPage = (selectedTab: ReactionModalTabType) => {
    if (!getUserReactionList(selectedTab).hasNext) return;

    fetchNextPage({
      limit,
      offset: getUserReactionList(selectedTab).results.length,
      reactableEntity,
      reactedId,
      reactionType: selectedTab === REACTION_MODAL_TAB.ALL ? null : selectedTab
    });
  };

  return {
    initUserReaction,
    userReactionMap,
    isLoading,
    getUserReactionList,
    nextPage
  };
};

export default useUserReactionList;
