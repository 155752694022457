import { Box } from "@arcadia/design-system";
import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useTheme } from "styled-components";
import { BaseChartItem } from "src/js/types";
import { CustomLegend } from "../CustomLegend";
import { LegendItem } from "../CustomLegend/CustomLegend";
import { CustomTooltip } from "../CustomTooltip";

export type VerticalBarChartData = BaseChartItem & {
  description: string;
};

export type VerticalBarChartProps = {
  data: VerticalBarChartData[];
  threshold?: number;
  legend: LegendItem[];
  tooltipTitle: string;
  hasDecimalGrading?: boolean;
};

const VerticalBarChart = ({
  data,
  threshold = 6,
  legend,
  tooltipTitle,
  hasDecimalGrading = true
}: VerticalBarChartProps) => {
  const { colors } = useTheme();
  const [focusBar, setFocusBar] = useState<number | undefined | null>(null);

  const fillBarFunction = (index: number) => {
    if (focusBar === index) {
      return index < threshold ? colors.error[600] : colors.success[600];
    }
    return index < threshold ? colors.error[700] : colors.success[700];
  };

  return (
    <Box width="100%" height="240px">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: -28,
            bottom: -12
          }}
          barCategoryGap="20%"
          onMouseMove={state => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}
        >
          <Legend
            verticalAlign="top"
            iconType="rect"
            content={<CustomLegend legendItems={legend} />}
          />
          <CartesianGrid vertical={false} stroke={colors.grey[900]} />
          <XAxis
            dataKey="label"
            stroke={colors.grey[900]}
            fontSize={10}
            fontWeight={500}
            tick={{ fill: colors.grey[100] }}
            tickLine
            axisLine={false}
            tickFormatter={value =>
              hasDecimalGrading ? value : `${value * 10}%`
            }
          />
          <YAxis
            stroke={colors.grey[100]}
            fontSize={10}
            fontWeight={500}
            tickLine={false}
            axisLine={false}
            tickFormatter={value => `${value}%`}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            animationDuration={0}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return CustomTooltip({
                  title: tooltipTitle,
                  description: payload[0].payload?.description,
                  color:
                    label < threshold ? colors.error[700] : colors.success[700]
                });
              }
              return null;
            }}
          />
          <Bar dataKey="value" radius={[8, 8, 0, 0]}>
            {data.map((element, index) => (
              <Cell
                key={`cell-${element.label}`}
                fill={fillBarFunction(index)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default VerticalBarChart;
