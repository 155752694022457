import {
  DoodleLoader,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-anchor: none;
  ${scrollbarMixin(VariantType.Grey)}
`;

export const MessageListWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-top: auto;
  padding: 24px 0 12px;
  overflow-anchor: none;

  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const ThreadInputWrapper = styled.div`
  padding: 0 24px 24px 24px;
  width: 100%;
  background: ${({ theme }) => theme.colors.coldGrey[900]};
  z-index: ${({ theme }) => theme.zIndex.chatInput};

  @media screen and (max-width: 767px) {
    position: sticky;
    bottom: 0;
    margin-top: 0;
    padding: 0;
    background: none;
    width: 100%;
  }
`;

export const StyledDoodleLoader = styled(DoodleLoader)`
  height: 100%;
  & > div {
    width: 200px;
    height: 100%;
    min-height: 100%;
  }
`;

export const LoadingMoreContainer = styled.div`
  margin: 16px 0;
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  overflow: unset !important;
  padding-top: 12px;
`;
