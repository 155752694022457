import styled, { css } from "styled-components";

export const StyledGroupNumberBadge = styled.div`
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
  border-radius: 20px;
  display: inline-flex;
  height: 24px;
  white-space: pre;
  border: 1px solid;
  > span {
    padding-bottom: 1px;
  }

  ${({ is_active, theme }) =>
    is_active
      ? css`
          border-color: ${theme.colors.success[500]};
        `
      : css`
          border-color: ${theme.colors.violet[500]};
        `}
`;

export const CircleIndicator = styled.div`
  margin-left: 10px;
  margin-right: 5px;
  width: 5px;
  height: 5px;
  border-radius: 5px;

  ${({ is_active, theme }) =>
    is_active
      ? css`
          background-color: ${theme.colors.success[500]};
        `
      : css`
          background-color: ${theme.colors.violet[500]};
        `}
`;
