import { Icon, Link, Text } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { openExternalLink } from "src/js/modules/commonFunction";
import { useTranslation } from "src/js/translation";
import { EventDomain, ExternalLinkEvent } from "src/js/types";
import { useTheme } from "styled-components";
import { ExplainDemoSpaceModal } from "../ExplainDemoSpaceModal";
import { JoinDemoSpace } from "../JoinDemoSpace";
import { useStickyBannerContext } from "../TopHeader/hooks";
import * as S from "./TopBannerDemoSpace.styles";

const TopBannerDemoSpace = ({ onClose }: { onClose: () => void }) => {
  const { translate } = useTranslation();
  const { whiteTheme, colors } = useTheme();
  const {
    SpaceStore: { activeSpace, isDemoSpace },
    ModalStore: { openModal },
    DemoSpaceStore: { setDemoSpaceTopBannerUserPreference },
    UIStore: { isLayoutModeMobile: isMobile }
  } = useStores();
  const stickyBannerProps = useStickyBannerContext();
  const handleCloseTopBannerAndOpenModal = () => {
    if (!isMobile) openModal(() => <ExplainDemoSpaceModal />);
    setDemoSpaceTopBannerUserPreference();
    onClose();
  };

  // TODO since the JoinDemoSpace and his TopBannerDemoSpace have changed scope
  // this part should be refactored a bit
  const showJoinDemoSpace = !isDemoSpace;
  const showWhatIsDemoSpaceCta =
    isMobile && stickyBannerProps?.withDescriptionButton && isDemoSpace;

  return (
    <S.TopBannerDemoSpace>
      <S.TextWrapper>
        <Text>
          {translate(
            activeSpace?.trialDaysLeft
              ? {
                  text: "top_banner_demo_space_trial_days_left_text",
                  stringVariables: { trialDays: activeSpace?.trialDaysLeft }
                }
              : "top_banner_demo_space_trial_other_space_trial_active_text"
          )}
        </Text>
        <S.BookDemo
          onClick={() => {
            _trackEvent(EventDomain.User, ExternalLinkEvent.BookDemo);
            openExternalLink("https://www.weschool.com/get-started/");
          }}
        >
          <Text>{translate("top_banner_demo_space_book_a_demo_cta")}</Text>
        </S.BookDemo>
        {showJoinDemoSpace ? (
          <JoinDemoSpace isMobile={false} isTopBanner />
        ) : null}
        {showWhatIsDemoSpaceCta ? (
          <Link
            // whiteTheme itself doesn't contain the colors property,
            // Link from DS is wrapped on ThemeProvider and override the internal one
            theme={{ ...whiteTheme, colors: { ...colors } }}
            target="_blank"
            href={translate(stickyBannerProps?.translations.ctaLink)}
          >
            {translate(stickyBannerProps?.translations.ctaOpenedMobile)}
          </Link>
        ) : null}
      </S.TextWrapper>
      <S.IconWrapper>
        <Icon
          icon="close"
          onClick={handleCloseTopBannerAndOpenModal}
          cursor="pointer"
        />
      </S.IconWrapper>
    </S.TopBannerDemoSpace>
  );
};

export default TopBannerDemoSpace;
