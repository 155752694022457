import styled from "styled-components";

export const SpaceFreeTrialModalContainer = styled.div`
  padding: 20px;
  @media screen and (max-width: 767px) {
    padding: initial;
  }
`;

export const SpaceFreeTrialModalMobileHeader = styled.div`
  text-align: center;
  padding: 12px 20px 20px 20px;
`;

export const SpaceFreeTrialModalBody = styled.div`
  padding: 20px 0;
`;

export const SpaceFreeTrialModalText = styled.div`
  padding: 8px 16px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const WrapSpaceInfoCard = styled.div`
  background: ${({ theme }) => theme.colors.grey[900]};
  padding: 20px 28px;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    padding: 16px 20px;
    border-radius: initial;
  }
`;

export const InputSubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 0 16px 32px;
  }
`;
