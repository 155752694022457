import { useReducer } from "react";
import {
  UseMutationOptions,
  UseMutationState,
  UseMutationReducerAction
} from "./types";

const reducer = (
  state: UseMutationState,
  { type, payload }: UseMutationReducerAction
): UseMutationState => {
  switch (type) {
    case "fetch":
      return {
        ...state,
        isLoading: true
      };

    case "success":
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case "error":
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};

const initialState: UseMutationState = {
  error: undefined,
  isLoading: false
};

/**
 * This is a hook used to make API mutation calls (POST, PUT, DELETE, PATCH methods). It returns a `mutate` function which can be called to make the API call. It also returns the `isLoading` and `error` state.
 * @param cb callback function which will be called when the `mutate` function is called. It will receive the variables passed to the `mutate` function as a parameter.
 * @param options options object
 * @returns `mutate` function, `isLoading` state and `error` state
 * @example
 * // Basic
 * const { mutate, isLoading, error } = useMutation(async () => { await fetchApiRequest() });
 *
 * return (
 *    <button onClick={() => mutate()}>Make a muatate request</button>
 * );
 *
 * // With variables
 * const { mutate, isLoading, error } = useMutation(async (vars: {param1: string}) => { await fetchApiRequest(vars) });
 *
 * return (
 *   <button onClick={() => mutate({param1: 'value'})}>Make a muatate request</button>
 * );
 *
 * // With options
 * const { mutate, isLoading, error } = useMutation(async (vars: {param1: string}) => {
 *  await fetchApiRequest(vars)
 * }, {
 *  onStart: (vars) => {
 *    console.log('The mutation is starting...', vars)
 *  },
 *  onSuccess: (vars) => {
 *    console.log('The mutation was successful');
 *    showSuccessMessage('Hello from onSuccess');
 *  },
 *  onError: (err, vars) => {
 *   console.log('The mutation failed', err);
 *    showErrorMessage();
 *  }
 * });
 *
 * const onButtonClick = () => {
 *  // You can also use onSuccess, onError and onStart options when calling the mutate function (this will take precedence over the options passed to the hook)
 *  mutate({param1: 'value'}, {
 *    onSuccess: (vars) => {
 *      console.log('The mutation was successful');
 *      // The message 'Hello from inner onSuccess' will be displayed instead of 'Hello from onSuccess'
 *      showSuccessMessage('Hello from inner onSuccess');
 *    }
 * });
 *
 * return (
 *  <button onClick={onButtonClick}>Make a muatate request</button>
 * );
 */
const useMutation = <TVariables = unknown>(
  cb: (vars?: TVariables) => Promise<void>,
  options?: UseMutationOptions<TVariables>
) => {
  const [{ error, isLoading }, dispatch] = useReducer(reducer, initialState);
  const { onError, onSuccess, onStart } = options || {};

  const mutate = async (
    vars?: TVariables,
    mutateOptions?: UseMutationOptions<TVariables>
  ) => {
    const {
      onError: onInnerError,
      onStart: onInnerStart,
      onSuccess: onInnerSuccess
    } = mutateOptions || {};

    try {
      if (onInnerStart) {
        onInnerStart(vars);
      } else if (onStart) {
        onStart(vars);
      }

      dispatch({ type: "fetch" });
      await cb(vars);
      dispatch({ type: "success" });

      if (onInnerSuccess) {
        onInnerSuccess(vars);
      } else if (onSuccess) {
        onSuccess(vars);
      }
    } catch (err) {
      dispatch({ type: "error", payload: { error: err } });

      if (onInnerError) {
        onInnerError(err, vars);
      } else if (onError) {
        onError(err, vars);
      }
    }
  };

  return {
    mutate,
    error,
    isLoading
  };
};

export default useMutation;
