export const BLOCKED_FAKE_GROUPS = [
  {
    id: "fake-0",
    name: "Do",
    is_active: false,
    created_at: "2021-03-31T12:11:44+00:00",
    users_count: 4,
    teachers: [
      {
        first_name: "Andrea",
        last_name: "Calletti"
      },
      {
        first_name: "Federico",
        last_name: "Zanellato"
      },
      {
        first_name: "Paola",
        last_name: "Poggini"
      }
    ]
  },
  {
    id: "fake-1",
    name: "Not",
    is_active: false,
    created_at: "2021-03-31T12:11:44+00:00",
    users_count: 4,
    teachers: [
      {
        first_name: "Andrea",
        last_name: "Calletti"
      },
      {
        first_name: "Federico",
        last_name: "Zanellato"
      },
      {
        first_name: "Paola",
        last_name: "Poggini"
      }
    ]
  },
  {
    id: "fake-2",
    name: "Try",
    is_active: false,
    created_at: "2021-03-31T12:11:44+00:00",
    users_count: 4,
    teachers: [
      {
        first_name: "Andrea",
        last_name: "Calletti"
      },
      {
        first_name: "Federico",
        last_name: "Zanellato"
      },
      {
        first_name: "Paola",
        last_name: "Poggini"
      }
    ]
  },
  {
    id: "fake-3",
    name: "To",
    is_active: false,
    created_at: "2021-03-31T12:11:44+00:00",
    users_count: 4,
    teachers: [
      {
        first_name: "Andrea",
        last_name: "Calletti"
      },
      {
        first_name: "Federico",
        last_name: "Zanellato"
      },
      {
        first_name: "Paola",
        last_name: "Poggini"
      }
    ]
  },
  {
    id: "fake-4",
    name: "Cheat",
    is_active: false,
    created_at: "2021-03-31T12:11:44+00:00",
    users_count: 4,
    teachers: [
      {
        first_name: "Andrea",
        last_name: "Calletti"
      },
      {
        first_name: "Federico",
        last_name: "Zanellato"
      },
      {
        first_name: "Paola",
        last_name: "Poggini"
      }
    ]
  }
];
