import moment from "moment";
import {
  LAST_3_MONTH_INTERVAL,
  LAST_6_MONTH_INTERVAL,
  LAST_7_DAYS_INTERVAL,
  LAST_DAY_INTERVAL,
  LAST_MONTH_INTERVAL,
  LAST_YEAR_INTERVAL,
  __INTERVAL_OPTIONS__
} from "../AnalyticsDashboard.const";

export const getIntervalRange = value => {
  const lastDay = moment().subtract(1, "day").toDate();
  switch (value) {
    case LAST_DAY_INTERVAL:
      return {
        fromDate: lastDay,
        toDate: lastDay
      };
    case LAST_7_DAYS_INTERVAL:
      return {
        fromDate: moment().subtract(7, "day").toDate(),
        toDate: lastDay
      };
    case LAST_MONTH_INTERVAL:
      return {
        fromDate: moment().subtract(1, "months").subtract(1, "day").toDate(),
        toDate: lastDay
      };
    case LAST_3_MONTH_INTERVAL:
      return {
        fromDate: moment().subtract(3, "months").subtract(1, "day").toDate(),
        toDate: lastDay
      };
    case LAST_6_MONTH_INTERVAL:
      return {
        fromDate: moment().subtract(6, "months").subtract(1, "day").toDate(),
        toDate: lastDay
      };
    case LAST_YEAR_INTERVAL:
      return {
        fromDate: moment().subtract(1, "year").subtract(1, "day").toDate(),
        toDate: lastDay
      };
    default: {
      return {
        fromDate: moment().subtract(1, "months").subtract(1, "day").toDate(),
        toDate: lastDay
      };
    }
  }
};

export const elaborateInterval = ({
  value = null,
  start = 0,
  end = 0,
  setDatePlaceholder = () => {},
  setDateRange = () => {}
}) => {
  if (value === "custom") {
    setDateRange({
      from_date: start,
      to_date: end
    });
    setDatePlaceholder(
      `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
    );
  } else if (value) {
    const intervalRange = getIntervalRange(value);
    setDateRange({
      from_date: intervalRange.fromDate,
      to_date: intervalRange.toDate
    });
    const placeholder = __INTERVAL_OPTIONS__.find(
      ({ key }) => key === value
    ).value;
    setDatePlaceholder(placeholder);
  }
};
