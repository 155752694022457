import {
  Eventcalendar,
  localeEn,
  localeEs,
  localeIt,
  localeFr,
  momentTimezone,
  Popup,
  setOptions
} from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { EventCard } from "src/js/components/EventCard";
import { useStores } from "src/js/hooks";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { CALENDAR_MODE, CALENDAR_VIEW } from "src/js/pages/calendar";
import { CalendarDesktopControls } from "src/js/pages/calendar/CalendarDesktopControls";
import { CalendarEventDetail } from "src/js/pages/calendar/CalendarEventDetail";
import { CalendarHeader } from "src/js/pages/calendar/CalendarHeader";
import { CalendarLabel } from "src/js/pages/calendar/CalendarLabel";
import { ThemeProvider, useTheme } from "styled-components";
import { CalendarCreationSheet } from "../CalendarCreationSheet";
import * as S from "./CalendarBody.styles";

const getMobiscrollLocale = () => {
  const currentLanguage = getCurrentLanguage().split("_")[0];
  switch (currentLanguage) {
    case "it":
      return localeIt;
    case "en":
      return localeEn;
    case "es":
      return localeEs;
    case "fr":
      return localeFr;
    default:
      return localeIt;
  }
};

setOptions({
  locale: getMobiscrollLocale(),
  theme: "material",
  themeVariant: "light"
});

const CalendarBody = ({
  events = [],
  selectedEvent = {},
  newEvent = () => {},
  onPageLoading = () => {},
  userIsTeacher = false,
  getEventDetail = () => {},
  openRecipientsModal = () => {},
  deleteResource = () => {},
  openResource = () => {},
  deleteCalendarEvent = () => {},
  editCalendarEvent = () => {},
  copyCalendarEvent = () => {},
  setEventTypeFilter = () => {},
  agendaUrl,
  calendarUrl,
  groupId
}) => {
  // setup Mobiscroll Moment plugin
  momentTimezone.moment = moment;

  const [view, setView] = useState(CALENDAR_VIEW.MONTH);
  const [calView, setCalView] = useState({
    calendar: {
      type: "month"
    },
    agenda: {
      type: "day",
      scrollable: false
    }
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [popupOpen, setPopupOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(selectedEvent);
  const [showSheetSelection, setShowSheetSelection] = useState(false);

  const { UIStore } = useStores();
  const { isLayoutModeMobile } = UIStore;
  const { whiteTheme, greyTheme } = useTheme();

  const CalendarContainerRef = useRef();

  useEffect(() => {
    setCurrentEvent(selectedEvent);
  }, [selectedEvent]);

  const onSelectedDateChange = useCallback(
    event => {
      setCurrentDate(event.date);
    },
    [setCurrentDate]
  );

  const getFirstDayOfWeek = useCallback((d, prev) => {
    const day = d.getDay() - 1;
    const diff = d.getDate() - day + (prev ? -7 : 7);
    return new Date(d.setDate(diff));
  }, []);

  const navigatePage = useCallback(
    prev => {
      if (view === CALENDAR_VIEW.MONTH) {
        const prevNextPage = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + (prev ? -1 : 1),
          1
        );
        setCurrentDate(prevNextPage);
      } else {
        const prevNextMonday = getFirstDayOfWeek(currentDate, prev);
        setCurrentDate(prevNextMonday);
      }
    },
    [view, currentDate, setCurrentDate, getFirstDayOfWeek]
  );

  const changeView = useCallback(
    value => {
      let calendarView;
      switch (value) {
        case CALENDAR_VIEW.MONTH:
          calendarView = {
            calendar: {
              type: "month"
            },
            agenda: {
              type: "day",
              scrollable: false
            }
          };
          break;
        case CALENDAR_VIEW.WEEK:
          calendarView = {
            calendar: {
              type: "week"
            },
            agenda: {
              type: "week",
              scrollable: false
            }
          };
          break;
        default:
          calendarView = {
            calendar: {
              type: "month"
            },
            agenda: {
              type: "day",
              scrollable: false
            }
          };
      }
      setView(value);
      setCalView(calendarView);
    },
    [setView, setCalView]
  );

  const desktopView =
    view === CALENDAR_VIEW.MONTH
      ? {
          calendar: {
            type: calView?.calendar.type,
            labels: true
          }
        }
      : { schedule: { type: "week" } };

  const responsiveOptions = {
    xsmall: {
      view: {
        calendar: calView?.calendar,
        agenda: calView?.agenda
      }
    },
    custom: {
      breakpoint: getBreakpoint() !== "smartphone" ? 570 : 767,
      view: desktopView
    }
  };

  // TODO: MobileAllDay functionality waits for product approbation
  // const schedulerAllDayOptions = {
  //   xsmall: {
  //     view: {
  //       schedule: { type: "day" }
  //     }
  //   }
  // };

  // const hasMobileAllDayContainer =
  //   getBreakpoint() === "smartphone" && view === CALENDAR_VIEW.WEEK;

  let onEventClickStopPropagation = false;
  const onEventClick = useCallback(
    event => {
      if (getBreakpoint() !== "smartphone") {
        onEventClickStopPropagation = true;
        setAnchor(event.domEvent.currentTarget);
        if (!event.event.eventDetail) {
          getEventDetail(event.event.id);
        } else {
          setCurrentEvent(event.event);
        }
        setPopupOpen(true);
      }
    },
    [view]
  );

  const onCellClick = useCallback(
    event => {
      if (
        getBreakpoint() !== "smartphone" &&
        !onEventClickStopPropagation &&
        userIsTeacher
      ) {
        const nowDate = new Date();
        const copiedDate = new Date(event.date);
        copiedDate.setHours(nowDate.getHours(), nowDate.getMinutes());
        const defaultDate =
          view === CALENDAR_VIEW.MONTH ? copiedDate : event.date;
        newEvent({ defaultDate });
      }
      onEventClickStopPropagation = false;
    },
    [view]
  );

  const openAccordionCallback = useCallback(({ id, eventDetail }) => {
    if (isEmptyObject(eventDetail)) {
      getEventDetail(id);
    }
  }, []);

  const deleteAndClosePopup = useCallback(eventDetailId => {
    deleteCalendarEvent(eventDetailId);
    setPopupOpen(false);
  }, []);

  const editAndClosePopup = useCallback(eventDetail => {
    editCalendarEvent(eventDetail);
    setPopupOpen(false);
  }, []);

  const copyAndClosePopup = useCallback(eventDetail => {
    copyCalendarEvent(eventDetail);
    setPopupOpen(false);
  }, []);

  const renderEvent = data => {
    if (getBreakpoint() === "smartphone") {
      const { id, type, title, lastAllDay, start, end, liveUrl, eventDetail } =
        data.original;
      return (
        <EventCard
          id={id}
          type={type}
          title={title}
          allDay={lastAllDay}
          isMultiDay={data.isMultiDay}
          currentDate={view === CALENDAR_VIEW.MONTH ? currentDate : null}
          start={start}
          end={end}
          liveUrl={liveUrl}
          eventDetail={eventDetail}
          groupId={groupId}
          userIsTeacher={userIsTeacher}
          openAccordionCallback={openAccordionCallback}
          openRecipientsModal={openRecipientsModal}
          accordionMode
          deleteResource={deleteResource}
          openResource={openResource}
          deleteCalendarEvent={deleteCalendarEvent}
          editCalendarEvent={editCalendarEvent}
          copyCalendarEvent={copyCalendarEvent}
        />
      );
    }
    return (
      <CalendarLabel type={data.original?.type} title={data.original?.title} />
    );
  };

  return (
    <ThemeProvider theme={greyTheme.calendar}>
      <S.CalendarBodyContainer
        monthView={view === CALENDAR_VIEW.MONTH}
        // TODO: MobileAllDay functionality waits for product approbation
        // hasMobileAllDayContainer={hasMobileAllDayContainer}
        ref={CalendarContainerRef}
      >
        <CalendarDesktopControls
          view={view}
          changeView={changeView}
          newEvent={type => newEvent({ defaultDate: new Date(), type })}
          userIsTeacher={userIsTeacher}
          mode={CALENDAR_MODE.CALENDAR}
          agendaUrl={agendaUrl}
          calendarUrl={calendarUrl}
        />
        {/* TODO: MobileAllDay functionality waits for product approbation */}
        {/* {hasMobileAllDayContainer && (
        <S.MobileAllDayContainer>
          <Eventcalendar
            dataTimezone="utc"
            displayTimezone="local"
            timezonePlugin={momentTimezone}
            selectedDate={currentDate}
            responsive={schedulerAllDayOptions}
            renderScheduleEvent={data => {
              return <CalendarLabel data={data} allDayHeaderMode />;
            }}
            theme="material"
            data={events}
          />
        </S.MobileAllDayContainer>
      )} */}
        <Eventcalendar
          firstDay={1}
          dataTimezone="utc"
          displayTimezone="local"
          exclusiveEndDates="false"
          timezonePlugin={momentTimezone}
          onSelectedDateChange={onSelectedDateChange}
          selectedDate={currentDate}
          responsive={responsiveOptions}
          renderHeader={() => {
            return (
              <CalendarHeader
                navigatePage={navigatePage}
                view={view}
                changeView={changeView}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                setEventTypeFilter={setEventTypeFilter}
              />
            );
          }}
          renderLabel={data => {
            return (
              <CalendarLabel
                type={data.original?.type}
                title={data.original?.title}
              />
            );
          }}
          renderEvent={renderEvent}
          renderScheduleEvent={data => {
            return (
              <CalendarLabel
                type={data.original?.type}
                title={data.original?.title}
              />
            );
          }}
          theme="material"
          data={events}
          onPageLoading={onPageLoading}
          onEventClick={onEventClick}
          onCellClick={onCellClick}
          onLabelClick={useCallback(() => {
            onEventClickStopPropagation = true;
          }, [view])}
        />
        <Popup
          display="anchored"
          width={330}
          maxHeight={430}
          contentPadding={false}
          touchUi={false}
          scrollLock={false}
          theme="material"
          cssClass="ws-calendar-popup"
          anchor={anchor}
          isOpen={popupOpen}
          onClose={() => setPopupOpen(false)}
          context={CalendarContainerRef.current}
        >
          <CalendarEventDetail
            eventDetail={currentEvent?.eventDetail}
            groupId={groupId}
            userIsTeacher={userIsTeacher}
            openRecipientsModal={openRecipientsModal}
            deleteResource={deleteResource}
            openResource={openResource}
            deleteFunction={deleteAndClosePopup}
            editFunction={editAndClosePopup}
            copyFunction={copyAndClosePopup}
          />
        </Popup>
        {isLayoutModeMobile && userIsTeacher ? (
          <S.StyledFabButton
            onClick={() => setShowSheetSelection(true)}
            theme={whiteTheme}
            variant="primary"
            icon="plus"
          />
        ) : null}
        <CalendarCreationSheet
          open={showSheetSelection}
          setOpen={setShowSheetSelection}
          onTypeSelection={type => {
            newEvent({ defaultDate: currentDate, type });
          }}
        />
      </S.CalendarBodyContainer>
    </ThemeProvider>
  );
};

export default CalendarBody;
