import { SpacePurpose } from "src/js/types";

export const SPACE_PURPOSE = [
  {
    label: "space_name_creation_purpose_corporatetraining",
    id: SpacePurpose.CorporateTraining
  },
  {
    label: "space_name_creation_purpose_professionaldevelopment",
    id: SpacePurpose.ProfessionalDevelopment
  },
  {
    label: "space_name_creation_purpose_testpreparation",
    id: SpacePurpose.TestPreparation
  },
  {
    label: "space_name_creation_purpose_teachertraining",
    id: SpacePurpose.TeacherTraining
  },
  {
    label: "space_name_creation_purpose_acceleratorsprograms",
    id: SpacePurpose.AcceleratorsPrograms
  },
  {
    label: "space_name_creation_purpose_personalenrichment",
    id: SpacePurpose.PersonalEnrichment
  },
  {
    label: "space_name_creation_purpose_postgraduate",
    id: SpacePurpose.PostgraduateEducation
  },
  {
    label: "space_name_creation_purpose_other",
    id: SpacePurpose.Other
  }
];
export const STATIC_DOMAIN_URL = "app.weschool.com/#";
export const SLUGIFY_OPTIONS = {
  lower: true,
  strict: true,
  remove:
    /(space|spaces|group|groups|user|users|dashboard|auth|chat|notifications|login|logout|register|password|search|settings|share)/g
};
