import React, { forwardRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";

import * as S from "./CodeInput.styles";
import { CodeInputActions, CodeInputProps, CodeInputValues } from "./types";
import { useCodeInput } from "./utils";

const CodeInput = forwardRef<CodeInputActions, CodeInputProps>(
  (
    {
      theme,
      variant = "self",
      label = "",
      length = 4,
      disabled = false,
      autoFocus = false,
      onChange,
      onComplete
    },
    ref
  ) => {
    const [values, setValues] = useState<CodeInputValues>(
      Array(length).fill("")
    );
    const { fields } = useCodeInput({
      placeholder: "",
      values,
      disabled,
      autoFocus,
      actionRef: ref,
      onChange: values => {
        setValues(values);
        if (onChange) onChange(values);
      },
      onComplete
    });

    return (
      <ThemeProvider theme={theme}>
        <S.Container variant={variant}>
          {label && (
            <S.Label>
              <Text type="subHeaderTitle">{label}</Text>
            </S.Label>
          )}
          <S.Wrapper>
            {fields.map((fieldProps, index) => (
              <S.Input key={index} {...fieldProps} />
            ))}
          </S.Wrapper>
        </S.Container>
      </ThemeProvider>
    );
  }
);

export default CodeInput;

CodeInput.displayName = "CodeInput";
