import React, { useState } from "react";
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { shuffle } from "lodash";
import {
  __ICON_HEART_O__,
  __ICON_HEART__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { AnimatePresence, motion } from "framer-motion";

import { Draggable } from "./components/Draggable";
import { Droppable } from "./components/Droppable";
import * as S from "./FadingMatches.styles";

const FadingMatches = ({ quiz }: { quiz: any }) => {
  const { translate } = useTranslation();
  const pointerSensor = useSensor(PointerSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(touchSensor, pointerSensor);
  const [totalAttempts] = useState(quiz.getAvailableAttempts());
  const [showError, setShowError] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(
    quiz.getAvailableAttempts()
  );
  const [questions, setQuestions] = useState(shuffle(quiz.getQuestions()));
  const [solutions, setSolutions] = useState(quiz.getSolutions());
  const [unmatchedCouples, setUnmatchedCouples] = useState(
    questions.length / 2 - quiz.getUserAnswer().length
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowError(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [showError]);

  const checkForSolution = (overId: string, activeId: string) => {
    const answers = quiz.getUserAnswer();
    const draggedColumn = questions.find(
      question => `${question.index}` === activeId
    ).column;
    const draggedOpositeColumn = draggedColumn === 1 ? 2 : 1;
    const draggedSolution = solutions.find(
      solution => solution[`index_${draggedColumn}`] === parseInt(activeId)
    );
    const isCorrect =
      draggedSolution[`index_${draggedOpositeColumn}`] === parseInt(overId);
    if (isCorrect) {
      answers.push(draggedSolution);
      quiz.setUserAnswer(answers);
      setQuestions(
        questions.filter(
          question =>
            `${question.index}` !== overId && `${question.index}` !== activeId
        )
      );
      setUnmatchedCouples(unmatchedCouples - 1);
    } else {
      quiz.setAvailableAttempts(remainingAttempts - 1);
      setRemainingAttempts(remainingAttempts - 1);
      setShowError(true);
    }
  };

  return (
    <S.Wrapper className="relative js-fading-wrapper">
      {unmatchedCouples === 0 && (
        <p className="alert alert-info">
          {translate("exercise_fadingmatches_run_success")}
        </p>
      )}
      {remainingAttempts > 0 ? (
        <DndContext
          onDragEnd={e => {
            const { over, active } = e;
            if (over?.id && active?.id !== over?.id) {
              checkForSolution(over.id as string, active.id as string);
            }
          }}
          sensors={sensors}
        >
          <AnimatePresence>
            {questions.map(item => (
              <Draggable key={item.index} id={`${item.index}`}>
                <Droppable id={`${item.index}`}>{item.value}</Droppable>
              </Draggable>
            ))}
          </AnimatePresence>
        </DndContext>
      ) : (
        <p className="alert alert-warning" style={{ width: "100%" }}>
          {translate("spiacenti, hai esaurito i tentativi")}
        </p>
      )}
      <div className="quiz__fading-tries-wrapper">
        {Array.from({ length: remainingAttempts }).map(i => (
          <img alt="upload-icon" src={__ICON_HEART__} />
        ))}
        {Array.from({ length: totalAttempts - remainingAttempts }).map(i => (
          <img alt="upload-icon" src={__ICON_HEART_O__} />
        ))}
      </div>
      <AnimatePresence>
        {showError && (
          <motion.div
            className="exen__feedback-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="exen__feedback exen__feedback-error">
              <span className="exen__feedback-text">
                {translate("match errato!")}{" "}
                {remainingAttempts === 1
                  ? translate("_errore_consentito_alert_")
                  : translate("_errori_consentiti_alert_", {
                      num: remainingAttempts
                    })}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};

export default FadingMatches;
