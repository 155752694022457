import { Icon, LinkButton, Text } from "@arcadia/design-system";
import React, { useEffect, useRef, useState } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import styled, { createGlobalStyle } from "styled-components";
import { TopBannerDemoSpace } from "../TopBannerDemoSpace/TopBannerDemoSpace.styles";

export const GlobalStyleHideTopBannerDemoSpace = createGlobalStyle`
  ${TopBannerDemoSpace} {
    display: none;
  }
`;

export const StyledTopBannerErrorConnection = styled.div`
  width: 100%;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.error[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  z-index: ${({ theme }) => theme.zIndex.topBanner};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;

export const StyledIcon = styled(Icon)`
  flex: none;
`;

export const StyledTextWrap = styled.div`
  display: flex;
  gap: 8px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.grey[1000]};
  white-space: pre-wrap;
  font-weight: 500;
  line-height: 17px;
  padding: 0;
  &:hover {
    color: ${({ theme }) => theme.colors.grey[900]};
  }
`;

const TopBannerErrorConnection = () => {
  const { translate } = useTranslation();
  const {
    WebSocketStore: { socket, isConnected }
  } = useStores();

  const [hideBanner, setHideBanner] = useState(true);
  const refPreviousConnected = useRef(false);
  const refCheckDisconnectionCount = useRef(0);
  const maxCheckDisconnectionCount = 2;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const connected = isConnected();
      if (refPreviousConnected.current && !connected) {
        if (refCheckDisconnectionCount.current < maxCheckDisconnectionCount) {
          refCheckDisconnectionCount.current += 1;
          return;
        }
        setHideBanner(false);
      } else if (!refPreviousConnected.current && connected) {
        setHideBanner(true);
        refCheckDisconnectionCount.current = 0;
      }
      refPreviousConnected.current = connected;
    }, 1000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [socket, isConnected]);

  if (hideBanner) {
    return null;
  }

  return (
    <>
      <GlobalStyleHideTopBannerDemoSpace />
      <StyledTopBannerErrorConnection>
        <StyledIcon icon="dangerTriangle" height={16} width={16} />
        <StyledTextWrap>
          <StyledText>
            {translate("top_banner_error_connection_content")}
          </StyledText>
          <StyledLinkButton
            type="button"
            onClick={() => window.location.reload()}
          >
            {translate("top_banner_error_connection_refresh_cta")}
          </StyledLinkButton>
        </StyledTextWrap>
      </StyledTopBannerErrorConnection>
    </>
  );
};

export default TopBannerErrorConnection;
