import styled from "styled-components";

export const SectionRadio = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0 -${({ theme }) => theme.margin.base};
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 16px;
  margin-top: 32px;
`;

export const BottomLinkContainer = styled.div`
  margin-top: 32px;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
    margin-left: ${({ theme }) => theme.margin.base};
  }
`;

export const PageTitle = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0 ${({ theme }) => theme.margin.base};
  }
`;
