import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { useSetBoard } from "src/js/context/BoardProvider";
import { EDIT_FIELD } from "src/js/components/DrawerEditBoard/EditBoard";
import { useOnClickOutSide, useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";

export const useModuleDetailsHeader = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { translate } = useTranslation();
  const setBoard = useSetBoard();
  const {
    BoardsStore: {
      editBoardField,
      togglePublishBoard,
      getSingleBoard,
      getActiveBoardId,
      uploadingElementIndexes
    },
    GroupStore: { groupId, groupName, userIsTeacher: isTeacher },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const board = getSingleBoard({
    groupId,
    boardId: getActiveBoardId
  });
  const drawerContainerRef = useRef();
  const {
    id,
    name,
    isPublished,
    color,
    isMilestone,
    isMandatory,
    milestoneMessage,
    boardElementCount,
    completedBoardElementCount
  } = board || {};
  useOnClickOutSide(drawerContainerRef, () => setDrawerOpen(false));
  const [titleValueState, setTitleValueState] = useState(name);
  const goBackToModule = useCallback(() => {
    navigateTo(createUrl("modules", { group_id: groupId }));
  }, [groupId]);

  const isStillUploading = useMemo(
    () => uploadingElementIndexes.length !== 0,
    [uploadingElementIndexes]
  );

  const togglePublishFunction = () => {
    togglePublishBoard({ groupId, boardId: id, publish: !isPublished })
      .then(() => {
        if (!isPublished) {
          showToastSuccess({ str: translate("board_publish_success") });
        } else {
          showToastSuccess({
            str: translate("board_unpublish_success")
          });
        }
      })
      .catch(() => {
        if (!isPublished) {
          showToastError({ str: translate("board_error_publish") });
        } else {
          showToastError({ str: translate("board_error_unpublish") });
        }
      });
  };

  const editTitleFunction = value => {
    editBoardField({
      groupId,
      boardId: id,
      value,
      field: EDIT_FIELD.NAME
    })
      .then(() => setBoard(getActiveBoardId, true))
      .catch(() => showToastError({ str: translate("board_error_edit") }));
  };

  const showMilestoneMessage =
    !isTeacher &&
    isMilestone &&
    boardElementCount > 0 &&
    completedBoardElementCount === boardElementCount;

  const hasEditMode = isTeacher && !isLayoutModeMobile;

  useEffect(() => {
    setShowBanner(showMilestoneMessage);
    return () => {
      setShowBanner(false);
    };
  }, [showMilestoneMessage]);

  useEffect(() => {
    setTitleValueState(name);
  }, [name]);

  const handleSubmission = () => {
    if (titleValueState && titleValueState !== name) {
      editTitleFunction(titleValueState);
    }
    setTitleValueState(name);
    setEditMode(false);
  };

  const handleInputChange = event => {
    event.preventDefault();
    setTitleValueState(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmission();
    }
  };

  return {
    isDrawerOpen,
    setDrawerOpen,
    drawerContainerRef,
    goBackToModule,
    togglePublishFunction,
    editTitleFunction,
    showMilestoneMessage,
    id,
    milestoneMessage,
    name,
    color,
    editMode,
    isMandatory,
    isMilestone,
    isTeacher,
    isPublished,
    groupName,
    isLayoutModeMobile,
    hasEditMode,
    titleValueState,
    handleSubmission,
    handleInputChange,
    handleKeyPress,
    showBanner,
    setShowBanner,
    setEditMode,
    isStillUploading
  };
};
