import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import { Button, Text } from "@arcadia/design-system";
import { navigateTo } from "src/legacy/modules/history";
import {
  translateString,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { GroupJoinWithCodeModal } from "src/js/components/GroupJoinWithCodeModal";
import { createUrl } from "src/js/modules/routing";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { SettingTabs } from "./SettingsMobile.types";
import { LogoutMobile } from "../LogoutMobile";
import * as S from "./SettingsMobile.styles";
import { SettingOptions } from "./utils";

const SettingsMobile = () => {
  const [isModalSheetOpen, setOpen] = useState(false);
  const { headerHeight } = useAppLayout();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UserStore: { activeUser },
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal, closeModal }
  } = useStores();

  useEffect(() => {
    if (!isLayoutModeMobile) {
      navigateTo(createUrl("user_settings", { option: "account" }));
    }
  }, [isLayoutModeMobile]);

  const onClickTile = useCallback(
    (type: SettingTabs) => () => {
      switch (type) {
        case "help":
          if (typeof zE !== "undefined") {
            zE.activate({ hideOnClose: true });
          }
          break;
        case "notifications":
          navigateTo(createUrl("user_settings", { option: "notice" }));
          break;
        case "account":
          navigateTo(createUrl("user_settings", { option: "account" }));
          break;
        case "profile":
          navigateTo(
            createUrl("profile_public", {
              user_uuid: activeUser.uuid
            })
          );
          break;
        case "joinGroupWithCode":
          openModal(() => <GroupJoinWithCodeModal closeModal={closeModal} />);
          break;
        default:
          break;
      }
    },
    []
  );

  return (
    <S.SettingsWrapper headerHeight={headerHeight}>
      {SettingOptions.map(({ icon, type, text }) => (
        <S.Tile key={type} onClick={onClickTile(type)}>
          <S.LeftWrapper>
            <S.LeftIcon icon={icon} initialViewbox width={16} height={16} />
            <S.Text>{translate(text)}</S.Text>
          </S.LeftWrapper>
          <S.ChevronIcon />
        </S.Tile>
      ))}
      <S.ButtonWrapper>
        <Button
          variant="primary"
          fullWidth
          theme={whiteTheme}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Text type="headerTitleLg">
            {translateString({ text: "logout" })}
          </Text>
        </Button>
      </S.ButtonWrapper>
      {isModalSheetOpen && (
        <LogoutMobile isOpen={isModalSheetOpen} setOpen={setOpen} />
      )}
    </S.SettingsWrapper>
  );
};

export default observer(SettingsMobile);
