/* eslint-disable filenames/match-exported */
import { Persister } from "@tanstack/react-query-persist-client";
import SuperJSON from "superjson";
import { DehydratedState } from "@tanstack/react-query";
import { deleteWSDB, openWSDB } from "./db";
import { QueryModel, QueryMutationModel, WSDatabaseStoreEnum } from "./types";

const __QUERY_CLIENT_STATE_DEFAULT_ID__ = 1;

export function createIdbPersister(): Persister {
  return {
    async persistClient(client) {
      const { clientState, buster, timestamp } = client;
      const db = await openWSDB();

      const queries = clientState.queries.map<QueryModel>(query => ({
        value: SuperJSON.stringify(query.state),
        queryKey: SuperJSON.stringify(query.queryKey),
        queryHash: query.queryHash
      }));

      const mutations = clientState.mutations.map<QueryMutationModel>(
        mutation => ({
          value: SuperJSON.stringify(mutation.state),
          mutationKey: SuperJSON.stringify(mutation.mutationKey)
        })
      );

      await db.put(WSDatabaseStoreEnum.QueryClientState, {
        buster,
        timestamp,
        queries,
        mutations,
        id: __QUERY_CLIENT_STATE_DEFAULT_ID__
      });
    },
    async removeClient() {
      await deleteWSDB();
    },
    async restoreClient() {
      const db = await openWSDB();

      const clientState = await db.get(
        WSDatabaseStoreEnum.QueryClientState,
        __QUERY_CLIENT_STATE_DEFAULT_ID__
      );

      const queries: DehydratedState["queries"] = clientState?.queries.map(
        query => ({
          queryHash: query.queryHash,
          queryKey: SuperJSON.parse(query.queryKey),
          state: SuperJSON.parse(query.value)
        })
      );

      const mutations: DehydratedState["mutations"] =
        clientState?.mutations.map(mutation => ({
          mutationKey: SuperJSON.parse(mutation.mutationKey),
          state: SuperJSON.parse(mutation.value)
        }));

      return {
        buster: clientState?.buster,
        timestamp: clientState?.timestamp,
        clientState: {
          mutations,
          queries
        }
      };
    }
  };
}
