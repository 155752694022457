import styled, { css } from "styled-components";

export const TabButton = styled.button`
  height: 30px;
  width: 100px;
  text-align: center;
  text-transform: capitalize;
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.whiteTheme.tablink.disabled.color
      : theme.whiteTheme.tablink.default.color};
  border: none;
  cursor: pointer;
  background: transparent;
  white-space: nowrap;
  padding: 0 0 10px;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.whiteTheme.tablink.default.hover.color};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-bottom: ${({ theme }) =>
        `4px solid ${theme.whiteTheme.tablink.active.backgroundTab}`};
      font-weight: 600;
      color: ${({ theme }) => theme.whiteTheme.tablink.default.hover.color};
    `}

  @media (max-width: 767px) {
    flex: 1 0 115px;
    max-width: 115px;
    overflow: hidden;
    font-size: 12px;
  }
`;
