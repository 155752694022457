import { Box, DoodleLoader, Icon } from "@arcadia/design-system";
import { isUndefined, map, mapValues, values } from "lodash";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import BoardActivityHeader from "./BoardActivityHeader";
import BoardActivityRows from "./BoardActivityRows";
import OrderIcon, { OrderState } from "./OrderIcon";

const BoardActivityTable = ({ activityData: originalData, loading }) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  const [orderId, setOrderId] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderState, setOrderState] = useState<OrderState>("");

  const [activityData, setActivityData] = useState<any>(originalData);

  const userStatistics = activityData.user_statistics;
  const { boards } = activityData;
  const countTotalBoard = Object.keys(boards).length;

  useEffect(() => {
    if (loading == false) {
      createStructure();
    }
  }, [loading]);

  const createStructure = () => {
    const userStatistics = activityData.user_statistics;
    const { boards } = activityData;
    const updatedRows = map(userStatistics, userRow => {
      let boardStatistic = userRow.board_statistics;
      if (isUndefined(boardStatistic)) {
        userRow.board_statistics = {};
        boardStatistic = {};
      }
      let countUserBoard = 0;
      values(
        mapValues(boards, board => {
          if (isUndefined(boardStatistic[board.id])) {
            userRow.board_statistics[board.id] = {
              viewed_elements: 0,
              comments_number: 0
            };
          } else {
            const boardValues = boardStatistic[board.id];
            if (boardValues.viewed_elements == board.board_element_count) {
              countUserBoard += 1;
            }

            userRow.board_statistics[board.id].viewed_elements = isUndefined(
              boardStatistic[board.id].viewed_elements
            )
              ? 0
              : boardStatistic[board.id].viewed_elements;
            userRow.board_statistics[board.id].comments_number = isUndefined(
              boardStatistic[board.id].comments_number
            )
              ? 0
              : boardStatistic[board.id].comments_number;
          }
        })
      );
      userRow.countUserBoard = countUserBoard;
      return userRow;
    });
    setActivityData(activityData => ({
      ...activityData,
      user_statistics: updatedRows
    }));
  };

  const setOrder = (type, id = "") => {
    const userStatistics = activityData.user_statistics;
    let sortedStatistics = [];
    let order = orderState;
    switch (type) {
      case "login":
        sortedStatistics = sortBy(userStatistics, "last_active");
        break;
      case "board-completed":
        sortedStatistics = sortBy(userStatistics, "countUserBoard");
        break;
      case "viewed-element":
        sortedStatistics = sortBy(userStatistics, item => [
          item.board_statistics[id].viewed_elements
        ]);
        break;
      case "comments":
        sortedStatistics = sortBy(userStatistics, item => [
          item.board_statistics[id].comments_number
        ]);
        break;
    }
    switch (orderState) {
      case "asc":
        order = "desc";
        sortedStatistics.reverse();
        break;
      case "desc":
        order = "";
        sortedStatistics = originalData.user_statistics;
        break;
      default:
        order = "asc";
        break;
    }

    setActivityData(activityData => ({
      ...activityData,
      user_statistics: sortedStatistics
    }));
    setOrderId(id);
    setOrderType(type);
    setOrderState(order);
  };

  return (
    <>
      {loading ? (
        <Box marginBottom={12}>
          <DoodleLoader theme={greyTheme} isMini />{" "}
        </Box>
      ) : null}
      <div className="scrollable-x-div">
        <table className={`table activity-table ${loading ? "loading" : ""}`}>
          <tbody>
            <tr>
              <th className="user">placeholder</th>
              <th>
                <div className="full-width">{translate("last_active")}</div>
                <TranslatedTooltip
                  tooltipString="order_by_login"
                  placement="bottom"
                >
                  <div
                    className="filter text-center"
                    onClick={() => setOrder("login")}
                  >
                    {orderType == "login" ? (
                      <OrderIcon orderState={orderState} />
                    ) : (
                      <Icon icon="minus" width="12" height="12" />
                    )}
                  </div>
                </TranslatedTooltip>
              </th>
              <th>
                <div className="full-width">
                  {translate("overall_progress")}
                </div>
                <TranslatedTooltip
                  tooltipString="order_by_completed_board"
                  placement="bottom"
                >
                  <div
                    className="filter text-center"
                    onClick={() => setOrder("board-completed")}
                  >
                    {orderType == "board-completed" ? (
                      <OrderIcon orderState={orderState} />
                    ) : (
                      <Icon icon="minus" width="12" height="12" />
                    )}
                  </div>
                </TranslatedTooltip>
              </th>
              <BoardActivityHeader
                boards={boards}
                orderState={orderState}
                orderId={orderId}
                orderType={orderType}
                setOrder={setOrder}
              />
            </tr>
            <BoardActivityRows
              userStatistics={userStatistics}
              boards={boards}
              countTotalBoard={countTotalBoard}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BoardActivityTable;
