import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ExerciseExecutionModel from "../models/exercise_executionModel";
import { getActiveGroupId } from "../modules/activeGroup";
import { navigateTo } from "../modules/history";
import { __ } from "../modules/localization";
import { createURL } from "../modules/utility";
import ExerciseReportSessions from "../view/exercise/report/exerciseReportSessions";

class SingleExecutionController extends React.Component {
  constructor() {
    super();
    this.state = { execution: null };
    this.loaded = false;
  }

  componentWillReceiveProps() {
    if (typeof this.props.match.params.session_id !== "undefined") {
      this.loaded = false;
      this.fetchExecution();
    }
  }

  fetchExecution() {
    const self = this;
    if (!isUndefined(this.props.match.params.session_id)) {
      this.sessionId = parseInt(this.props.match.params.session_id);
      if (isNumber(this.sessionId) && !isNaN(this.sessionId)) {
        const executionModel = new ExerciseExecutionModel({
          exercise_id: self.props.exercise.get("id"),
          id: this.sessionId
        });
        executionModel.fetchWithStats({
          success() {
            self.setState({ execution: executionModel });
            self.loaded = true;
          },
          error(params) {
            showToastError({
              str: extractErrorMessage(params.responseJSON, __("e_404"))
            });
            navigateTo(
              createURL("exercises", { group_id: getActiveGroupId() })
            );
          }
        });
      }
    } else {
      navigateTo(createURL("exercises", { group_id: getActiveGroupId() }));
    }
  }

  render() {
    const self = this;
    if (self.state.execution === null) {
      self.fetchExecution();
    }
    return self.state.execution !== null ? (
      <Switch>
        <Route
          exact
          path={
            WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises
              .exerciseReportsSession
          }
          render={props => (
            <ExerciseReportSessions
              exercise={self.props.exercise}
              execution={self.state.execution}
              {...props}
            />
          )}
        />
        <Route render={() => <Redirect to={createUrl("home")} />} />
      </Switch>
    ) : (
      <div />
    );
  }
}

export default SingleExecutionController;
