import {
  AvatarWithInitials,
  Box,
  Button,
  Heading,
  Icon,
  Input,
  Text
} from "@arcadia/design-system";
import React, { FC, useState } from "react";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import { SocialLoginOptions } from "src/js/components/SocialLoginOptions";
import { useStores } from "src/js/hooks";
import * as Styled from "src/js/pages/unlogged/LoginPage/LoginPage.styles";
import { LoginViewProps } from "src/js/pages/unlogged/LoginPage/LoginPage.types";
import { __FORM_LOGIN__ } from "src/js/pages/unlogged/LoginPage/LoginPage.utils";
import { LoginErrors } from "../LoginErrors";
import { LoginWithSSO } from "../LoginWithSSO";
import * as S from "./LoginDesktopView.styles";

const LoginDesktopView: FC<LoginViewProps> = ({
  form,
  onLogin,
  shouldDisplayPasswordField,
  ssoData,
  loginError,
  inviteToken
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    ThemeStore: { customizationSettings }
  } = useStores();
  const { register, handleSubmit, watch, errors, formState } = form;
  const isSSOAccount = ssoData && ssoData.provider;
  const passwordInput = watch("password");
  const hasErrors = !!(loginError || errors.username || errors.password);

  return (
    <S.Card
      key="login-card"
      initial={{
        opacity: 0,
        scale: 1.15
      }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 0.35, ease: "easeInOut" }}
    >
      {hasErrors ? null : (
        <>
          {customizationSettings?.whiteLabelLogo ? (
            <Box marginBottom="16px">
              <AvatarWithInitials
                theme={whiteTheme}
                alt={customizationSettings?.whiteLabelName}
                url={customizationSettings?.whiteLabelLogo?.originalUrl}
                name={customizationSettings?.whiteLabelName}
                avatarColor={0}
                size="medium"
              />
            </Box>
          ) : null}
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="center"
            textAlign="center"
          >
            {customizationSettings?.whiteLabelName ? (
              <Heading level="2">
                {`${translate("login_on_heading")} `}
                <S.InviteSpaceName>
                  {customizationSettings?.whiteLabelName}
                </S.InviteSpaceName>
              </Heading>
            ) : (
              <>
                <Heading level="2">{`${translate(
                  "login_on_heading"
                )} `}</Heading>
                <S.LogoWrapper>
                  <WeSchoolLogoSVG
                    width="146"
                    height="23"
                    viewBox="0 0 207 32"
                  />
                </S.LogoWrapper>
              </>
            )}
          </Box>
        </>
      )}
      {isSSOAccount ? (
        <LoginWithSSO ssoData={ssoData} />
      ) : (
        <>
          <LoginErrors loginError={loginError} inputErrors={errors} />
          <S.Form hasErrors={hasErrors} onSubmit={handleSubmit(onLogin)}>
            <S.CustomInput
              hasError={!!errors.username}
              theme={whiteTheme}
              label=""
              hideLabel
              name="username"
              placeholder={translate("login_username_placeholder")}
              ref={register(__FORM_LOGIN__.username)}
              rounded
            />
            {shouldDisplayPasswordField && (
              <Box marginTop={4} width="100%">
                <Input
                  theme={whiteTheme}
                  label=""
                  hideLabel
                  name="password"
                  placeholder={translate("login_password_placeholder")}
                  ref={register(__FORM_LOGIN__.password)}
                  rounded
                  isPassword={!passwordVisible}
                  rightSection={
                    <S.IconWrapper
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      <Icon icon={passwordVisible ? "eyeClosed" : "eyeOpen"} />
                    </S.IconWrapper>
                  }
                />
              </Box>
            )}
            <Box marginTop={12} width="100%">
              <Button
                disabled={
                  !passwordInput || (passwordInput && !formState.isValid)
                }
                variant="primary"
                theme={whiteTheme}
                fullWidth
                type="submit"
              >
                {translate("login")}
              </Button>
              <Box
                marginTop={16}
                textAlign="center"
                onClick={() => navigateTo(createUrl("requestPassword"))}
              >
                <Styled.Link type="textLink">
                  {translate("login_reset_password_label")}
                </Styled.Link>
              </Box>
            </Box>
          </S.Form>
          <Box marginBottom={64}>
            <SocialLoginOptions inviteToken={inviteToken} />
          </Box>
        </>
      )}

      <S.RegisterWrapper>
        <Text type="formSmallSubtitle">
          {translate("login_page_link_register")}
        </Text>
        <Box onClick={() => navigateTo(createUrl("register"))} marginLeft={8}>
          <Styled.Link type="textLink">
            {translate("registration_submit_button_label")}
          </Styled.Link>
        </Box>
      </S.RegisterWrapper>
      <UnloggedSupportLink />
    </S.Card>
  );
};

export default observer(LoginDesktopView);
