/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useEffect } from "react";
import { Button, ContextualAlert, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { CropImageModal } from "src/js/components/CropImageModal";
import {
  BADGE_IMAGE_MINIMUM_HEIGHT,
  BADGE_IMAGE_MINIMUM_WIDTH
} from "src/js/settings/settingsResource";
import {
  __BADGE_IMAGE_ACCEPTED_TYPES__,
  checkIfBadgeHasDefaultImage,
  listOfDefaultBadgeImages
} from "src/js/pages/spaces/dashboard/SpacesBadges/SpacesBadges.utils";
import { observer } from "mobx-react";
import * as S from "./styles";
import { SelectBadgeProps } from "./types";
import useSelectBadge from "../../hooks/useSelectBadge";
import CreateEditBadgeModal from "../CreateEditBadgeModal";
import { CreateEditBadgeStep } from "../CreateEditBadgeModal/types";

const OWN_IMAGE = "ownImage";

const SelectBadgeImage = ({
  badgeImageCroppedPreview = "",
  editingBadgeImage,
  isEditing = false,
  setStep,
  setProgressPercentage
}: SelectBadgeProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const [selectedOption, setActive] = useState(OWN_IMAGE);
  const { selectedFile, onSelectFile } = useSelectBadge();
  const {
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile },
    SpaceBadgesStore: {
      badgeRequiredFields,
      badgeToEdit,
      setBadgeRequiredFields
    }
  } = useStores();
  const editingBadgeHasDefaultImage =
    checkIfBadgeHasDefaultImage(editingBadgeImage);
  const isEditingCustomBadgeImage = isEditing && !editingBadgeHasDefaultImage;

  const handleUploadClick = () => {
    inputRef?.current.click();
  };

  const isDisabled = () => {
    if (isEditingCustomBadgeImage) {
      return false;
    }
    return selectedOption === OWN_IMAGE && !badgeImageCroppedPreview;
  };

  const handleImageToBeSaved = () => {
    if (
      isEditing &&
      !editingBadgeHasDefaultImage &&
      selectedOption === OWN_IMAGE
    ) {
      return badgeToEdit.image;
    }
    return badgeImageCroppedPreview || selectedOption;
  };

  useEffect(() => {
    if (isEditing) {
      if (editingBadgeHasDefaultImage) {
        setActive(editingBadgeImage);
      }
    }
  }, [isEditing]);

  const openCreateEditBadgeModal = (
    croppedPreview: string,
    croppedImage: File
  ) => {
    openModal(() => (
      <CreateEditBadgeModal
        badgeImageCroppedPreview={croppedPreview}
        customBadgeImage={croppedImage}
        isEditing={isEditing}
      />
    ));
  };

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      openModal(() => (
        <CropImageModal
          close={closeModal}
          imagePreview={objectUrl}
          imageMimeType={selectedFile.type}
          imageTitle={selectedFile.name}
          shouldZoomInImage
          aspect={isLayoutModeMobile ? 3 / 2.2 : 6 / 6}
          minimumImageWidth={BADGE_IMAGE_MINIMUM_WIDTH}
          minimumImageHeight={BADGE_IMAGE_MINIMUM_HEIGHT}
          alertMessage="space_badges_modal_image_size_warning"
          modalTitle="space_badges_create_modal_title_editing_thumbnail"
          accept={__BADGE_IMAGE_ACCEPTED_TYPES__}
          saveNewCroppedImage={croppedImage => {
            if (croppedImage) {
              const croppedPreview = URL.createObjectURL(croppedImage);
              openCreateEditBadgeModal(croppedPreview, croppedImage);
            }
          }}
        />
      ));
    }
  }, [selectedFile]);

  const handleNewCroppedImage = () => {
    if (badgeImageCroppedPreview || isEditingCustomBadgeImage) {
      return (
        <S.NewCroppedImageContainer>
          <S.SelectedBadge
            src={badgeImageCroppedPreview || editingBadgeImage}
            alt="badge-image-cropped-preview"
            aria-label={badgeImageCroppedPreview}
          />
          <S.CroppedImageEditButtonContainer onClick={handleUploadClick}>
            <Icon icon="edit" width={16} height={16} />
            <S.HiddenInput
              ref={inputRef}
              type="file"
              hidden
              onChange={onSelectFile}
              accept={__BADGE_IMAGE_ACCEPTED_TYPES__}
            />
          </S.CroppedImageEditButtonContainer>
        </S.NewCroppedImageContainer>
      );
    }
    return (
      <S.SelectImageToUploadButton onClick={handleUploadClick}>
        <S.UploadIcon icon="upload" width={40} height={40} initialViewbox />
        <S.HiddenInput
          ref={inputRef}
          type="file"
          hidden
          onChange={onSelectFile}
          accept={__BADGE_IMAGE_ACCEPTED_TYPES__}
          data-testid="upload-input"
        />
      </S.SelectImageToUploadButton>
    );
  };

  return (
    <S.SelectBadgeImageContainer>
      {selectedOption === OWN_IMAGE ? (
        handleNewCroppedImage()
      ) : (
        <S.SelectedBadge src={selectedOption} alt="selected-badge-image" />
      )}
      <S.AlertContainer>
        <ContextualAlert
          theme={whiteTheme}
          text={translate("space_badges_modal_image_size_warning")}
        />
      </S.AlertContainer>
      <S.ImagesListContainer>
        <S.ImagesList>
          <S.SelectOwnImage
            isActive={selectedOption === OWN_IMAGE}
            isEditing={isEditingCustomBadgeImage}
            onClick={() => {
              setActive(OWN_IMAGE);
            }}
          >
            {badgeImageCroppedPreview || isEditingCustomBadgeImage ? (
              <S.SelectedBadgeSmallPreview
                isActive={selectedOption === OWN_IMAGE}
                src={badgeImageCroppedPreview || editingBadgeImage}
                alt="editing-badge-alternative"
              />
            ) : (
              <S.UploadIcon
                icon="addImage"
                width={18}
                height={18}
                initialViewbox
              />
            )}
          </S.SelectOwnImage>
          {listOfDefaultBadgeImages.map((imageSrc, index) => (
            <S.BadgeImageContainer
              key={`badge-image-${imageSrc}-${index}`}
              isActive={selectedOption === imageSrc}
              onClick={() => {
                setActive(imageSrc);
              }}
            >
              <S.BadgeImage
                src={imageSrc}
                alt={`badge-image-${index}`}
                aria-label={`badge-image-${index}`}
              />
            </S.BadgeImageContainer>
          ))}
        </S.ImagesList>
      </S.ImagesListContainer>
      <Button
        theme={greyTheme}
        variant="primary"
        fullWidth
        disabled={isDisabled()}
        onClick={() => {
          setStep(CreateEditBadgeStep.REQUIRED_FIELDS_STEP);
          setProgressPercentage(33);
          setBadgeRequiredFields({
            ...badgeRequiredFields,
            image: handleImageToBeSaved()
          });
        }}
      >
        {translate("space_badges_create_modal_next_button")}
      </Button>
    </S.SelectBadgeImageContainer>
  );
};

export default observer(SelectBadgeImage);
