import { Box, Heading, Icon } from "@arcadia/design-system";
import React from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import {
  StateMetricSeverity,
  StateMetricSeverityType,
  StateTrendSeverityType
} from "src/js/types";
import { observer } from "mobx-react";
import { ChipMetricTrend } from "../../../components/ChipMetricTrend";
import { IconMetricSeverity } from "../../../components/IconMetricSeverity";
import { DescriptionText, WarningDataText } from "./MetricObjectives.styles";

type MetricObjectivesProps = {
  loading?: boolean;
  resultLabel: string;
  metricSeverity?: StateMetricSeverityType;
  descriptionLabel: string;
  trendLabel?: string;
  trendSeverity?: StateTrendSeverityType;
  progressPercentage: number;
};

const MetricObjectives = ({
  loading,
  resultLabel,
  metricSeverity,
  descriptionLabel,
  trendLabel,
  trendSeverity,
  progressPercentage
}: MetricObjectivesProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { colors } = useTheme();
  const { translate } = useTranslation();

  const progressBarSeverityStyleMap: {
    [key in StateMetricSeverityType]: {
      background: string;
    };
  } = {
    [StateMetricSeverity.Critical]: {
      background: colors.error[400]
    },
    [StateMetricSeverity.Warning]: {
      background: colors.yellow[500]
    },
    [StateMetricSeverity.Normal]: {
      background: colors.success[400]
    }
  };

  const progressBarSeverityStyle = progressBarSeverityStyleMap[metricSeverity];

  return (
    <Box
      display="flex"
      padding="24px 32px"
      gap={88}
      {...(isLayoutModeMobile && {
        flexDirection: "column",
        padding: 16,
        gap: 16
      })}
    >
      <div>
        <Box display="flex" alignItems="center" gap={8}>
          <Skeleton loading={loading} borderRadius={16}>
            <Heading level="1">{resultLabel}</Heading>
          </Skeleton>
          <ChipMetricTrend
            loading={loading}
            trendSeverity={trendSeverity}
            label={trendLabel}
          />
        </Box>
        <Skeleton loading={loading} borderRadius={16}>
          <Box
            height={8}
            width={152}
            borderRadius={8}
            background={colors.coldGrey[900]}
            marginTop={8}
          >
            <Box
              height={8}
              borderRadius={8}
              width={`${progressPercentage}%`}
              {...progressBarSeverityStyle}
            />
          </Box>
        </Skeleton>
      </div>
      <Box display="flex" alignItems="center" gap={12}>
        <IconMetricSeverity loading={loading} metricSeverity={metricSeverity} />
        <Skeleton loading={loading} borderRadius={16}>
          <DescriptionText type="formSubtitle">
            {descriptionLabel}
          </DescriptionText>
        </Skeleton>
      </Box>
      {isLayoutModeMobile ? (
        <Box display="flex" alignItems="center" gap={8} padding="0px 8px">
          <Skeleton loading={loading} borderRadius={16}>
            <Icon
              icon="stopwatch"
              initialViewbox
              width={16}
              height={16}
              color={colors.grey[200]}
            />
            <WarningDataText type="formSubtitle">
              {translate("dashboard_analytics_warning_data_label")}
            </WarningDataText>
          </Skeleton>
        </Box>
      ) : null}
    </Box>
  );
};

export default observer(MetricObjectives);
