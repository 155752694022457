import styled from "styled-components";
import { CalendarDesktopControlsStyleParams } from "./CalendarDesktopControls.types";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const handleContentAlignment = (value: string) => {
  switch (value) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

export const Section = styled.div<CalendarDesktopControlsStyleParams>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: ${({ alignContent }) =>
    handleContentAlignment(alignContent)};
`;

export const IconContainer = styled.span`
  margin-right: 8px;
`;

export const ActionSection = styled.div`
  z-index: 10;
  display: flex;
  min-width: 300px;
  flex-direction: column;
`;
