import { Heading, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => `${theme.colors.grey[1000]}E6`};
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  padding: 0 24px;
`;

export const Title = styled(Heading)`
  max-width: 320px;
`;

export const Description = styled(Text)`
  max-width: 320px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;
