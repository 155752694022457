import {
  Popover as NonStyledPopover,
  PopoverStyle,
  RadioGroup
} from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Popover = styled(NonStyledPopover)<{ openTopPopover: boolean }>`
  ${PopoverStyle.ContentWrapper} {
    transform-origin: top right;
    z-index: ${({ theme }) => theme.zIndex.toTheSky};
    right: 0;
    margin-top: 12px;
    padding: 8px;

    ${({ openTopPopover }) =>
      openTopPopover &&
      css`
        transform-origin: bottom right;
        bottom: 56px;
        position: absolute;
      `}
  }
`;

export const StyledButton = styled.button<{ isOpen: boolean }>`
  all: unset;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 16px;
  height: 44px;
  width: max-content;
  gap: 12px;
  border-radius: 100px;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  :disabled {
    cursor: default;
    opacity: 0.4;
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      border: 1px solid ${theme.colors.violet[501]};
      outline: 3px solid ${theme.colors.violet[900]};
    `}
`;

export const IconWrapper = motion(styled.div`
  display: flex;
`);

export const Option = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;

export const PopoverContentWrapper = styled.div`
  width: max-content;
  min-width: 200px;

  ${Option} {
    transition: 0.3s;
    border-radius: 34px;
    padding: 8px 16px;
    margin-right: 0;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)<{
  color: string;
  hoverBgColor: string;
}>`
  display: flex;
  gap: 4px;

  label {
    margin: 0;
    border-radius: 8px;
    padding: 8px;
    border-radius: 20px;
    &:hover {
      background-color: ${({ hoverBgColor }) => hoverBgColor};
    }
    input {
      margin: 0;
      outline: none !important;
      &:hover {
        background-color: transparent !important;
      }
      &:checked {
        background-color: ${({ color }) => color} !important;
      }
    }
    span {
      padding-left: 16px;
    }
  }
`;
