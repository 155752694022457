import { FabButton } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const StyledFabButton = styled(FabButton)`
  z-index: 900;
`;

export const CalendarBodyContainer = styled.div`
  margin-top: 30px;

  & .ws-calendar-popup {
    z-index: 890;
    .mbsc-popup-arrow-wrapper {
      display: none;
    }
    .mbsc-material.mbsc-popup-body {
      border: 1px solid ${({ theme }) => theme.cell.border.color};
      border-radius: 8px;
      box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    }
  }
  & .mbsc-calendar {
    font-weight: 400;
    font-size: 14px;
    background: none;
    font-family: "WeSchoolSans" !important;

    .mbsc-material.mbsc-calendar-month-name.mbsc-ltr {
      padding-left: 15px !important;
    }

    .mbsc-calendar-header {
      z-index: 2;
    }

    .mbsc-calendar-controls {
      padding: 0;
    }

    .mbsc-schedule-time-indicator-x{
      border: 0.5px solid ${({ theme }) => theme.cell.selected.color};
    }

    .mbsc-material.mbsc-schedule-time-indicator-time {
      color: ${({ theme }) => theme.cell.selected.color};
    }

    .mbsc-schedule-time-indicator-day mbsc-material{
      color: ${({ theme }) => theme.cell.selected.color};
    }

    .mbsc-schedule-all-day-wrapper-shadow{
      z-index: 0 !important;
    }

    .mbsc-material.mbsc-schedule-time-col.mbsc-ltr {
      z-index: 1 !important;
    }

    .mbsc-material.mbsc-schedule-time-indicator-day::before {
      background: ${({ theme }) => theme.cell.selected.color};
    }

    .mbsc-calendar-week-day {
      color: ${({ theme }) => theme.cell.text.color};
      font-size: 14px;
      font-weight: 400;
    }

    .mbsc-calendar-week-day .mbsc-material .mbsc-ltr {
      color: ${({ theme }) => theme.cell.text.color};
    }

    .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day {
      border-color: ${({ theme }) => theme.cell.border.color} !important;
    }

    .mbsc-calendar-day-outer {
      background: ${({ theme }) => theme.cell.outer.background};
      .mbsc-calendar-cell-text,
      .mbsc-calendar-month-name {
        color: ${({ theme }) => theme.cell.outer.color} !important;
      }
    }

    .mbsc-calendar-day {
      .mbsc-calendar-month-name,
      .mbsc-calendar-day-text {
        color: ${({ theme }) => theme.cell.text.color};
        background: transparent;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &.mbsc-material .mbsc-selected .mbsc-calendar-cell-text {
      color: ${({ theme }) => theme.cell.selected.color} !important;
      background: transparent !important;
      border: transparent !important;

      &.mbsc-calendar-today{
        background: ${({ theme }) => theme.cell.current.background} !important;
        border-color: ${({ theme }) =>
          theme.cell.current.background} !important;
        color: ${({ theme }) => theme.body.background} !important;
      }
    }

    .mbsc-calendar-cell{
      width: 100%;
    }
    .mbsc-material.mbsc-hover .mbsc-calendar-cell-text{
      color: ${({ theme }) => theme.cell.hover.color} !important;
      background-color: ${({ theme }) =>
        theme.cell.hover.background} !important;
    }

  }

  .mbsc-material .mbsc-calendar-today {
    color: ${({ theme }) => theme.cell.current.color} !important;
    background: ${({ theme }) => theme.cell.current.background} !important;
    border: ${({ theme }) => theme.cell.current.color} !important;
  }

  @media (max-width: 767px) {
    margin-top: 0;

    & .mbsc-calendar-wrapper-fixed {
      position: fixed;
      z-index: 12;
      left: 0;
    }

    & .mbsc-calendar-scroll-wrapper {
      padding-bottom: 10px;
      background: white;
    }

    & .mbsc-event-list-scroll {
      padding-top: 125px;

      ${({ monthView }) =>
        monthView &&
        css`
          padding-top: 305px;
        `}

      ${({ hasMobileAllDayContainer }) =>
        hasMobileAllDayContainer &&
        css`
          padding-top: 205px;
        `}

      .mbsc-event-list-empty {
        visibility: hidden;
      }
    }

    & .mbsc-event.mbsc-list-item {
      margin: 10px 0px;
      padding: 0;

      .mbsc-ripple {
        display: none;
      }
    }

    & .mbsc-event-day.mbsc-list-header {
      height: 1px;
      padding: 0;
      overflow: hidden;
      background: ${({ theme }) => theme.cell.border.color};
      margin: 20px;
    }

    & .mbsc-calendar-day {
      padding-bottom: 0;

      .mbsc-calendar-cell-text {
        border-radius: 5px;
        margin: 2px !important;
        font-size: 14px;
      }

      .mbsc-calendar-marks {
        transform: none;
        top: 9px;

        .mbsc-calendar-mark {
          background: ${({ theme }) => theme.cell.current.background};
          width: 4px;
          height: 4px;
          border-radius: 2px;
        }
      }

      .mbsc-calendar-cell-text .msbc-material {
        &.mbsc-selected {
          background: ${({ theme }) => theme.body.background} !important;
          border-color: transparent;
          color: ${({ theme }) => theme.cell.current.color};
        }
        }
      }

      & .mbsc-calendar-today {
        background: ${({ theme }) => theme.cell.current.background} !important;
        border-color: ${({ theme }) =>
          theme.cell.current.background} !important;
        color: ${({ theme }) => theme.body.background} !important;

        & + div .mbsc-calendar-marks {
          .mbsc-calendar-mark {
            background: ${({ theme }) => theme.body.background};
          }
        }
      }
    }
  }


  @media (min-width: 768px) {
    & .mbsc-calendar {
      .mbsc-calendar-body {
        display: flex;
        background: ${({ theme }) => theme.body.background};
        border-radius: 10px;

        .mbsc-calendar-body-inner {
          margin: 40px;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 35px;
            bottom: 1px;
            width: 1px;
            z-index: 1;
            background: ${({ theme }) => theme.cell.border.color};
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 35px;
            bottom: 1px;
            width: 1px;
            z-index: 1;
            background: ${({ theme }) => theme.cell.border.color};
          }
        }
      }

      .mbsc-schedule-wrapper {
        padding: 40px;
        max-height: 75vh;
        border-radius: 10px;
        background: ${({ theme }) => theme.body.background};

        .mbsc-schedule-header-dayname {
          font-size: 12px;
          line-height: 24px;
          text-transform: capitalize;
          &.mbsc-schedule-header-dayname-curr {
            background: ${({ theme }) => theme.cell.current.background};
            color: ${({ theme }) => theme.body.background};
            border-radius: 4px;
            width: 100%;
          }
        }

        .mbsc-schedule-header-day {
          font-size: 16px;
          &.mbsc-schedule-header-day-today {
            background: ${({ theme }) => theme.cell.current.background};
            color: ${({ theme }) => theme.body.background};
            border-radius: 4px;
            width: 100%;
          }
        }

        .mbsc-schedule-time,
        .mbsc-schedule-time-indicator-time {
          font-size: 10px;
        }

        .mbsc-schedule-all-day-text {
          visibility: hidden;
        }

        .mbsc-schedule-grid,
        .mbsc-schedule-all-day {
          border-right: 1px solid #d5d5d5;
        }

        .mbsc-schedule-all-day-wrapper,
        .mbsc-schedule-grid-scroll {
          // rules for firefox scrollbar
          @supports (-moz-appearance: none) {
            scrollbar-color: ${({ theme }) =>
              theme.cell.outer.background + theme.body.background};
            scrollbar-width: thin;
          }

          ::-webkit-scrollbar {
            width: 20px;
            height: 20px;
          }

          ::-webkit-scrollbar-button {
            width: 10px;
            height: 2px;
          }

          ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.cell.border.color};
            border: 8px solid transparent;
            background-clip: padding-box;
            border-radius: 10px;
            min-height: 50px;

            &:hover {
              background: ${({ theme }) => theme.cell.border.color};
            background-clip: padding-box;
          }
        }

        ::-webkit-scrollbar-track {
          background: ${({ theme }) => theme.body.background};
          border: 8px solid transparent;
          background-clip: padding-box;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-corner {
          background: transparent;
        }
      }

      // we want same size for header all-day scrollbar,
      // but transparent color to make it not visible
      .mbsc-schedule-all-day-wrapper {
        @supports (-moz-appearance: none) {
          scrollbar-color: transparent transparent;
        }

        ::-webkit-scrollbar-track,
        ::-webkit-scrollbar-thumb {
          background: transparent;

          &:hover {
            background: transparent;
          }
        }
      }

      // adjust size for header days and "now" indicator
      // with a hidden block large as scrollbar
      .mbsc-schedule-fake-scroll-y {
        ::-webkit-scrollbar {
          width: 20px;
        }
        @supports (-moz-appearance: none) {
          scrollbar-width: thin;
        }
      }

      .mbsc-schedule-header-wrapper {
        .mbsc-schedule-header-item {
          padding: 0 4px;
        }
      }
    }
  }


  & .mbsc-calendar-day {
    border-color: ${({ theme }) => theme.cell.outer.background};
    height: 110px;

    .mbsc-calendar-labels {
      margin: 0px;
      .mbsc-calendar-text {
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &.mbsc-selected {
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid ${({ theme }) =>
          theme.cell.current.background} !important;
      }
      color: ${({ theme }) => theme.cell.selected.color};

      &:last-of-type {
        border-right: 2px solid ${({ theme }) =>
          theme.cell.border.background} !important;
      }

      &:first-of-type {
        border-left: 2px solid ${({ theme }) =>
          theme.cell.border.background} !important;
      }
    }

    & .mbsc-calendar-cell-text.mbsc-calendar-today {
      background: ${({ theme }) => theme.cell.current.background} !important;
      border-color: ${({ theme }) => theme.cell.current.background} !important;
      color: ${({ theme }) => theme.cell.current.color};
  }

  & .ws-calendar-popup {
    z-index: 890;

    .mbsc-popup {
      user-select: auto;
    }

    .mbsc-popup-arrow-wrapper {
      display: none;
    }

    .mbsc-material.mbsc-popup-body {
      border: 1px solid ${({ theme }) => theme.cell.border.color};
      border-radius: 6px;

      .mbsc-popup-content {
        overflow: visible;
      }
    }
  }
`;

export const MobileAllDayContainer = styled.div`
  & .mbsc-calendar {
    .mbsc-calendar-wrapper-fixed {
      visibility: hidden;
    }

    .mbsc-schedule-header,
    .mbsc-schedule-grid-wrapper {
      display: none;
    }

    .mbsc-schedule-all-day-wrapper {
      background: ${({ theme }) => theme.body.background};
      position: fixed;
      z-index: 99;
      top: 260px;
      width: 100%;
      left: 0;
      padding-bottom: 5px;
      padding-right: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.cell.border.color};

      .mbsc-schedule-time-col {
        width: auto;
        border: none;
        .mbsc-schedule-all-day-text {
          text-transform: none;
          font-weight: 400;
          color: ${({ theme }) => theme.cell.text.color};
          font-size: 12px;
          white-space: nowrap;
          padding: 5px;
          height: 30.5px;
        }
      }
      .mbsc-schedule-all-day-group-wrapper {
        max-height: 81px;
        overflow-y: auto;
      }
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
