import { ActionButton, Avatar } from "@arcadia/design-system";
import { parseEmojis } from "@weschooleng/emoji-parser";
import React, { useState } from "react";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { sanitizeText } from "src/js/modules/textFunction";
import { SocialInput } from "../../SocialInput";
import * as S from "../CommentList.styles";

type NewCommentInputProps = {
  newCommentInputRef?: React.MutableRefObject<HTMLInputElement>;
  openMentionsFromBottom?: boolean;
  onSubmit: (text: string) => void;
  isNestedComment?: boolean;
};
const NewCommentInput = ({
  newCommentInputRef,
  onSubmit,
  isNestedComment = false,
  openMentionsFromBottom = false
}: NewCommentInputProps) => {
  const {
    UserStore: { activeUser }
  } = useStores();
  const [newCommentText, setNewCommentText] = useState("");
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const targetRef = React.useRef<HTMLDivElement>();
  return (
    <S.NewCommentWrapper ref={targetRef} isNestedComment={isNestedComment}>
      <Avatar
        url={activeUser.avatar?.medium}
        alt="You"
        size="s"
        theme={whiteTheme}
        rounded
      />
      <SocialInput
        renderBottom={openMentionsFromBottom}
        targetRef={targetRef}
        autoFocus
        maxHeight="60px"
        value={newCommentText}
        ref={newCommentInputRef}
        placeholder={translate("new_comment_input_placeholder")}
        onChange={e => setNewCommentText(e.target.value)}
      />
      <ActionButton
        type="button"
        onClick={() => {
          onSubmit(parseEmojis(sanitizeText({ text: newCommentText })));
          setNewCommentText("");
        }}
        disabled={!sanitizeText({ text: newCommentText }).trim()}
        theme={whiteTheme}
        variant="primary"
        icon="paperPlane"
      />
    </S.NewCommentWrapper>
  );
};
export default NewCommentInput;
