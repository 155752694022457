import { IconName } from "@arcadia/design-system";

export type AcceptedPath = "groups" | "calendar" | "home" | "chats";

export const toolbarItems: {
  icon: IconName;
  path: AcceptedPath;
  id: string;
}[] = [
  // Uncomennt Calendar & Home, when these routes are defined.
  // {
  //   icon: "calendar",
  //   iconFilled: "calendarFilled",
  //   label: "calendar_button_title",
  //   path: "/calendar",
  //   viewbox: "0 0 24 24",
  //   viewboxFilled: "0 0 20 20"
  //   id:"home"
  // },

  {
    icon: "chat",
    path: "chats",
    id: "chat"
  },
  {
    icon: "home",
    path: "groups",
    id: "home"
  }
];
