/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";

/**
 * @name UploadStore
 *
 * @description
 * ...
 */
class UploadStore {
  /** 
   data type: 
      {
        fileName: null,
        eventTopic: null,
        type: null,
        onProgress: () => () => {},
        startUpload: () => {},
        successCallback: () => {},
        cancelCallback: () => {}
      }
   */
  $requestList = [];

  $pendingUpload = 0;

  $uploadOnWatch = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  appendXMLHttpUpload = ({
    fileName,
    eventTopic,
    XMLReq,
    payload,
    successCallback,
    cancelCallback
  }) => {
    this.$requestList.push({
      fileName,
      eventTopic,
      type: "XMLHttpRequest",
      onTerminate: terminateHandler => {
        XMLReq.addEventListener("load", e => {
          this.$pendingUpload -= 1;
          terminateHandler(e);
        });
      },
      onProgress: progressHandler => {
        XMLReq.upload.addEventListener("progress", progressHandler, false);
      },
      startUpload: () => {
        this.$pendingUpload += 1;
        XMLReq.send(payload);
      },
      successCallback,
      cancelCallback: () => {
        this.$pendingUpload -= 1;
        cancelCallback();
      }
    });
  };

  cancelAllPendingUpload = () => {
    if (!this.$requestList.length > 0) return;
    this.$requestList.forEach(request => {
      request.cancelCallback();
    });
  };

  reset = () => {
    this.$requestList = [];
    this.$pendingUpload = 0;
  };

  setWatchOnResource = topicName => {
    this.$uploadOnWatch = topicName;
  };

  get uploadOnWatch() {
    return this.$uploadOnWatch;
  }

  get pendingUpload() {
    return this.$pendingUpload;
  }

  get uploadRequestList() {
    return this.$requestList;
  }

  get uploadRequestLength() {
    return this.$requestList.length;
  }
}

export default UploadStore;
