import { createUrl } from "src/js/modules/routing";
import {
  createSearchString,
  updateLocalSearchSuggestion
} from "src/js/modules/searchFunction";
import { navigateTo } from "src/legacy/modules/history";
import { useTranslation } from "src/js/translation";
import { SearchContextProps } from "./types";

export const useHomeContext = ({ searchQuery }: SearchContextProps) => {
  const { translate } = useTranslation();

  const placeholder = translate({
    text: "top_header_searchbar_desktop_home_placeholder"
  });

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateLocalSearchSuggestion(searchQuery);
    const url = `${createUrl("search")}?${createSearchString({
      keyword: searchQuery
    })}`;

    navigateTo(url);
  };

  return { onSubmit, placeholder };
};
