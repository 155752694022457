import {
  Checkbox as WSCheckbox,
  Avatar as WSAvatar
} from "@arcadia/design-system";

import styled from "styled-components";

export const Checkbox = styled(WSCheckbox)`
  margin-bottom: 12px;
  padding-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 1px;

  label {
    margin-left: 0;
  }
`;

const __AVATAR_SIZE__ = "24px";

export const Avatar = styled(WSAvatar)<{ isGroupFilter: boolean }>`
  width: ${__AVATAR_SIZE__};
  height: ${__AVATAR_SIZE__};
  margin-right: 8px;
  border-radius: ${({ isGroupFilter }) => (isGroupFilter ? "4px" : undefined)};
`;

export const AvatarWithInitialsWrapper = styled.div`
  margin-right: 8px;
`;

export const FiltersWrapper = styled.div`
  margin-bottom: 24px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.violet[900]};
  }
`;
