import React from "react";
import createReactClass from "create-react-class";

/**
 * Creates a bootstrap dropdown
 * The dropdown must be opened from outside, this only creates the markup
 */
const Dropdown = createReactClass({
  getDefaultProps() {
    return {
      items: [],
      /** array of React elements. Remember to bind the correct context to any function that needs to be called */
      className: "",
      labelledBy: "",
      role: "menu"
    };
  },
  render() {
    const className = this.props.className;
    const labelledBy = this.props.labelledBy;
    const role = this.props.role;
    const items = this.props.items;
    let content = null;

    if (items.length > 0) {
      content = (
        <React.Fragment>
          <ul
            className={["dropdown-menu", className].join(" ")}
            role={role}
            aria-labelledby={labelledBy}
          >
            {items.map(item => ({ ...item, key: item }))}
          </ul>
        </React.Fragment>
      );
    }

    return content;
  }
});

export default Dropdown;
