import React from "react";
import * as S from "./ModalFooter.styles";

const ModalFooter = ({ flex, children }) => {
  return (
    <S.ModalFooterContainer flex={flex}>{children}</S.ModalFooterContainer>
  );
};

export default ModalFooter;
