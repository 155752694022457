/* eslint-disable filenames/match-exported */
import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import isUndefined from "lodash/isUndefined";
import { Button } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { __ } from "../modules/localization";
import { modalMessage } from "../models/modalMessageModel";

const BaseModal = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          modalMessage: "",
          /** @type {ModalMessage} modalMessage the model that
           *  contains the markup for the modal header, body and footer */
          hideFunc: "",
          /** @type {function} hideFunc the function that hides the modal */
          addClass: undefined,
          /** @type {string|undefined} [addClass] the class to add to the markup */
          escapeDisabled: false /** @type {bool|false} enable or disable escape for modal */
        };
      },
      componentDidMount() {
        if (!modalMessage.get("escapeDisabled")) {
          $(document).on("keyup", this.closeOnEsc);
        }
      },
      componentWillUnmount() {
        if (!modalMessage.get("escapeDisabled")) {
          // console.log('enabled');
          $(document).on("keyup", this.closeOnEsc);
        }
      },
      /**
       * Close modal windows mith "ESC" key
       * @param  {object} e the keyup event
       */
      closeOnEsc(e) {
        if (e.keyCode == 27) {
          // ESC
          this.hide();
        }
      },
      /**
       * calls the parent hide function
       */
      hide() {
        this.props.hideFunc();
      },
      render() {
        const { modalMessage } = this.props;
        const { theme } = this.props;
        const { whiteTheme } = theme;

        const addClass = isUndefined(this.props.addClass)
          ? ""
          : this.props.addClass;

        const headerObj = modalMessage.get("header");
        const bodyObj = modalMessage.get("body");
        const footerObj = modalMessage.get("footer");

        let closeIcon = "";
        let closeBtn = "";
        let header = "";
        let body = "";
        let footer = "";

        /**
         * if addCloseIcon is true in the model, then show the "x" icon in the header
         */
        if (modalMessage.get("addCloseIcon")) {
          closeIcon = (
            <button type="button" className="close" onClick={this.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          );
        }

        if (headerObj.content !== "") {
          if (headerObj.type === "REACT") {
            header = (
              <div className="modal-header">
                {closeIcon}
                {headerObj.content}
              </div>
            );
          } else {
            header = (
              <div className="modal-header">
                {closeIcon}
                <div dangerouslySetInnerHTML={{ __html: headerObj.content }} />
              </div>
            );
          }
        }

        if (bodyObj.content !== "") {
          if (bodyObj.type === "REACT") {
            body = <div className="modal-body">{bodyObj.content}</div>;
          } else {
            body = (
              <div
                className="modal-body"
                dangerouslySetInnerHTML={{ __html: bodyObj.content }}
              />
            );
          }
        }

        /**
         * if addCloseButton is true in the model, then show the default close button in the footer
         */
        if (modalMessage.get("addCloseButton")) {
          closeBtn = (
            <Button onClick={this.hide} variant="primary" theme={whiteTheme}>
              {__("Chiudi")}
            </Button>
          );
        }

        if (footerObj.content !== "" || closeBtn !== "") {
          if (footerObj.type === "REACT") {
            footer = (
              <div className="modal-footer">
                {footerObj.content}
                {closeBtn}
              </div>
            );
          } else {
            footer = (
              <div className="modal-footer">
                <div dangerouslySetInnerHTML={{ __html: footerObj.content }} />
                {closeBtn}
              </div>
            );
          }
        }

        return (
          <div
            className={[
              "modal",
              "modal-message",
              "fade",
              "react-modal-msg",
              addClass
            ].join(" ")}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                {header}
                {body}
                {footer}
              </div>
            </div>
          </div>
        );
      }
    })
  )
);

export default BaseModal;
