import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ContentText = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const ClaimedTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ClaimedIcon = styled.div`
  margin-left: 8px;
  color: ${({ theme }) => theme.primaryColor[500]};
  display: flex;
  align-items: center;
`;

export const CentralContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.error[500]};
`;
