import styled from "styled-components";

export const Wrapper = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: relative;
  height: 44px;
  min-width: 120px;
  padding: 0 8px;
  border-radius: 22px;
  background: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.coldGrey[800] : theme.colors.grey[50]};
`;

export const ActionButton = styled.button<{ disabled: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  border-radius: 16px;

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.coldGrey[800] : theme.primaryColor[500]};
    background-color: ${({ theme, disabled }) =>
      disabled ? "unset" : theme.colors.grey[1000]};
  }
`;
