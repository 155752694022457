/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction, toJS } from "mobx";
import moment from "moment";
import { isEmptyObject } from "src/js/modules/commonFunction";
import {
  addCalendarEvent,
  fetchCalendar,
  fetchCalendarEvent,
  removeCalendarEvent,
  updateCalendarEvent
} from "src/js/repository/calendarRepository";
import { getAllGroupMembers } from "src/js/repository/groupRepository";
import { fetchFilteredGroupList } from "src/js/repository/groupsListRepository";
import { __MAX_TIMEOUT_VALUE__ } from "src/js/settings/settingsGeneral";
import { __ROLE_TEACHER__ } from "src/js/settings/settingsUser";
import { EventCategory } from "src/js/pages/calendar/Calendar.types";

const sanitizeEventData = data =>
  data.map(({ id, end, start, last_all_day, live_url, title, type }) => {
    return {
      id,
      end: new Date(end),
      start: start ? new Date(start) : new Date(end),
      lastAllDay: last_all_day,
      allDay: last_all_day,
      liveUrl: live_url,
      title,
      type
    };
  });

const createEventWithDetail = data => {
  const newEventWithDetail = {
    id: data.id,
    end: new Date(data.end),
    start: new Date(data.start),
    allDay: data.all_day,
    liveUrl: data.live_room?.url,
    title: data.title,
    type: data.type,
    eventDetail: data
  };
  return newEventWithDetail;
};

/**
 * @name CalendarStore
 *
 * @description
 * gets and manipulates calendar data
 */
class CalendarStore {
  $events = [];
  $nextEvents = [];
  $selectedEvent = {};
  $resourceAttachments = [];
  $todayLive = [];
  $limit = 30;
  $groupList = [];
  $groupStart = 0;
  $hasMoreGroup = false;
  $userList = [];
  $userStart = 0;
  $hasMoreUser = false;
  $timerMilliseconds = null;
  $eventType = [];
  $calendarLastSearchValues = {};
  $selectedEventDetail = {};
  $agendaHasNextPage = false;
  $agendaHasPreviousPage = true;

  constructor({ groupStore, userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.groupStore = groupStore;
    this.userStore = userStore;
    this.dispose();
  }

  addResourceAttachment = resource => {
    const allResources = toJS(this.$resourceAttachments);
    this.setResourceAttachments([...allResources, resource]);
  };

  removeResourceAttachment = resourceId => {
    const allResources = toJS(this.$resourceAttachments);
    const filteredresources = allResources.filter(
      resource => resource.id !== resourceId
    );
    this.setResourceAttachments(filteredresources);
  };

  resetResourceAttachments = () => {
    this.$resourceAttachments = [];
  };

  setResourceAttachments = resourceAttachments => {
    this.$resourceAttachments = resourceAttachments;
  };

  get eventFilter() {
    return this.$eventType || [];
  }

  getTodayLive = ({ groupId, from, to }) => {
    return fetchCalendar({
      groupId,
      from,
      to,
      type: EventCategory.Live
    })
      .then(data => {
        const sanitizedData = sanitizeEventData(data);
        this.setTodayLive(sanitizedData);
      })
      .catch(error => {
        this.setEvents([]);
        throw error;
      });
  };

  getAgendaEvents = ({ groupId, from, to, limit, overrideEvents = false }) => {
    return fetchCalendar({
      groupId,
      from,
      to,
      limit
    })
      .then(data => {
        if (from) {
          this.setAgendaHasNextPage(data.length >= limit);
        }
        if (to) {
          this.setAgendaHasPreviousPage(data.length >= limit);
        }
        const sanitizedData = sanitizeEventData(data);
        if (overrideEvents) {
          this.setEvents(sanitizedData);
          this.setAgendaHasPreviousPage(true);
        } else {
          const allEvents = toJS(this.$events);
          const combinedEvents = from
            ? [...allEvents, ...sanitizedData]
            : [...sanitizedData, ...allEvents];
          const uniqueEvents = combinedEvents.filter(
            (value, index, self) =>
              index === self.findIndex(e => e.id === value.id)
          );
          this.setEvents(uniqueEvents);
        }
        return data;
      })
      .catch(error => {
        throw error;
      });
  };

  getCalendarEventDetail = ({ groupId, calendarEventId }) => {
    return fetchCalendarEvent({
      groupId,
      calendarEventId
    })
      .then(data => {
        const { activeUser } = this.userStore;
        const eventDetail = {
          ...data,
          userIsCreator: data.creator_id === activeUser.id
        };
        this.insertEventDetail(eventDetail);
        this.setSelectedEventDetail(eventDetail);
      })
      .catch(error => {
        this.setSelectedEventDetail({});
        throw error;
      });
  };

  insertEventDetail = eventDetail => {
    const allEvents = toJS(this.$events);
    const indexAddDetail = allEvents.findIndex(
      event => event.id === eventDetail.id
    );
    if (indexAddDetail > -1) {
      allEvents[indexAddDetail].eventDetail = eventDetail;
      this.setEvents(allEvents);
      this.setSelectedEvent(allEvents[indexAddDetail]);
    }
  };

  realtimeAddCalendarEvent = ({ eventDetail }) => {
    const { activeUser } = this.userStore;
    if (eventDetail.creator_id === activeUser.id) return;
    const allEvents = toJS(this.$events);
    const indexAddDetail = allEvents.findIndex(
      event => event.id === eventDetail.id
    );
    if (indexAddDetail > -1) return;
    const eventWithDetail = createEventWithDetail(eventDetail);
    this.setEvents([...allEvents, eventWithDetail]);
  };

  deleteCalendarEvent = ({ calendarEventId, redirect_to }) => {
    return removeCalendarEvent({
      calendarEventId,
      redirect_to
    }).then(() => {
      const allEvents = toJS(this.$events);
      const filteredEvents = allEvents.filter(
        event => event.id !== calendarEventId
      );
      this.setEvents(filteredEvents);
    });
  };

  realtimeRemoveCalendarEvent = ({ calendarEventId }) => {
    const allEvents = toJS(this.$events);
    const filteredEvents = allEvents.filter(
      event => event.id !== calendarEventId
    );
    this.setEvents(filteredEvents);
  };

  fetchGroupList = () => {
    return fetchFilteredGroupList({
      limit: this.$limit,
      start: this.$groupStart,
      role: __ROLE_TEACHER__
    }).then(({ groups, has_next }) => {
      const sanitizedData = groups?.map(({ id, name }) => {
        return {
          key: id,
          value: name
        };
      });
      this.setGroupList(sanitizedData);
      this.setHasMoreGroup(has_next);
    });
  };

  fetchGroupNextPage = () => {
    this.setGroupStart(this.$groupStart + this.$limit);
    this.fetchGroupList();
  };

  setGroupList = list => {
    this.$groupList = this.$groupList.concat(list);
  };

  setHasMoreGroup = more => {
    this.$hasMoreGroup = more || false;
  };

  setGroupStart = start => {
    this.$groupStart = start;
  };

  resetGroupList = () => {
    this.$groupList = [];
    this.$groupStart = 0;
    this.$hasMoreGroup = false;
  };

  fetchUserList = () => {
    return getAllGroupMembers(
      this.groupStore.groupId,
      this.$limit,
      this.$userStart,
      true
    ).then(({ results, total }) => {
      const filteredData = results.filter(
        user => user.id !== this.userStore.activeUser.id
      );
      const sanitizedData = filteredData?.map(({ id, name, surname }) => {
        return {
          key: id,
          value: `${name} ${surname}`
        };
      });
      this.setUserList(sanitizedData);
      this.setHasMoreUser(this.$userList.length < total);
    });
  };

  fetchUserNextPage = () => {
    this.setUserStart(this.$userStart + this.$limit);
    this.fetchUserList();
  };

  setUserList = list => {
    this.$userList = this.$userList.concat(list);
  };

  setHasMoreUser = more => {
    this.$hasMoreUser = more || false;
  };

  setUserStart = start => {
    this.$userStart = start;
  };

  resetUserList = () => {
    this.$userList = [];
    this.$userStart = 0;
    this.$hasMoreUser = false;
  };

  setEvents = events => {
    this.$events = events;
  };

  getCalendarNextEvents = ({ groupId, from, limit }) => {
    return fetchCalendar({
      groupId,
      from,
      limit
    })
      .then(data => {
        const sanitizedData = sanitizeEventData(data);
        this.setNextEvents(sanitizedData);
      })
      .catch(error => {
        this.setNextEvents([]);
        throw error;
      });
  };

  addEventWithDetail = eventWithDetail => {
    const allEvents = toJS(this.$events);
    this.setEvents([...allEvents, eventWithDetail]);
  };

  setNextEvents = events => {
    this.$nextEvents = events;
  };

  editEventWithDetail = eventWithDetail => {
    const allEvents = toJS(this.$events);
    const filteredEvents = allEvents.filter(
      event => event.id !== eventWithDetail.id
    );
    this.setEvents([...filteredEvents, eventWithDetail]);
  };

  createCalendarEvent = ({
    type,
    title,
    description,
    start,
    end,
    all_day,
    group_recipients,
    user_recipients,
    live_vendor_account_id,
    live_vendor_id,
    recursion_mode,
    recursion_times,
    redirect_to,
    resource_attachments,
    board_attachments,
    exercise_attachments,
    live_url
  }) => {
    return addCalendarEvent({
      type,
      title,
      description,
      start,
      end,
      all_day,
      group_recipients,
      user_recipients,
      live_vendor_account_id,
      live_vendor_id,
      recursion_mode,
      recursion_times,
      redirect_to,
      resource_attachments,
      board_attachments,
      exercise_attachments,
      live_url
    }).then(data => {
      const eventsArray = Array.isArray(data)
        ? sanitizeEventData(data)
        : [createEventWithDetail(data)];

      return Promise.all(eventsArray.map(e => this.addEventWithDetail(e))).then(
        () => !!Array.isArray(data) || createEventWithDetail(data)
      );
    });
  };

  editCalendarEvent = ({
    id,
    type,
    title,
    description,
    start,
    end,
    all_day,
    group_recipients,
    user_recipients,
    redirect_to,
    resource_attachments,
    board_attachments,
    exercise_attachments,
    live_url
  }) => {
    return updateCalendarEvent({
      calendarEventId: id,
      type,
      title,
      description,
      start,
      end,
      all_day,
      group_recipients,
      user_recipients,
      redirect_to,
      resource_attachments,
      board_attachments,
      exercise_attachments,
      live_url
    }).then(data => {
      const newEventWithDetail = {
        id: data.id,
        end: data.end,
        start: data.start,
        allDay: data.all_day,
        liveUrl: data.live_room?.url,
        title: data.title,
        type: data.type,
        eventDetail: { ...data, userIsCreator: true }
      };
      this.editEventWithDetail(newEventWithDetail);
      this.setSelectedEventDetail({ ...data, userIsCreator: true });
    });
  };

  realtimeEditCalendarEvent = () => {
    if (isEmptyObject(this.$calendarLastSearchValues)) return;
    this.getCalendarEvents(this.$calendarLastSearchValues);
  };

  setSelectedEvent = selectedEvent => {
    this.$selectedEvent = selectedEvent;
  };

  setTodayLive = lives => {
    this.$todayLive = lives;
  };

  setNextEventTimer = () => {
    if (this.$todayLive.length === 0) {
      this.$timerMilliseconds = null;
      return;
    }
    const DateNowMs = moment().valueOf();
    const datesArray = [];
    this.$todayLive.forEach(live => {
      datesArray.push(live.start && moment(live.start).valueOf()); // milliseconds
      datesArray.push(live.end && moment(live.end).valueOf());
    });
    const datesAfterNow = datesArray.filter(element => element >= DateNowMs);
    if (datesAfterNow.length === 0) {
      this.$timerMilliseconds = null;
      return;
    }
    const timerValue = Math.min(...datesAfterNow) - DateNowMs;
    this.$timerMilliseconds = Math.min(__MAX_TIMEOUT_VALUE__, timerValue);
  };

  getCalendarEvents = ({ groupId, from, to, limit }) => {
    const type = this.$eventType.length === 1 ? this.$eventType[0] : null;
    return fetchCalendar({
      groupId,
      from,
      to,
      limit,
      type
    })
      .then(data => {
        const sanitizedData = sanitizeEventData(data);
        this.setEvents(sanitizedData);
      })
      .catch(error => {
        this.setEvents([]);
        throw error;
      });
  };

  setEventTypeFilter = typesArray => {
    this.$eventType = typesArray;
    this.getCalendarEvents(this.$calendarLastSearchValues);
  };

  setCalendarLastSearchValues = valuesObject => {
    this.$calendarLastSearchValues = valuesObject;
  };

  setSelectedEventDetail = selectedEventDetail => {
    this.$selectedEventDetail = selectedEventDetail;
  };

  setAgendaHasNextPage = agendaHasNextPage => {
    this.$agendaHasNextPage = agendaHasNextPage;
  };

  setAgendaHasPreviousPage = agendaHasPreviousPage => {
    this.$agendaHasPreviousPage = agendaHasPreviousPage;
  };

  get events() {
    return this.$events;
  }

  get nextEvents() {
    return this.$nextEvents;
  }

  get selectedEvent() {
    return this.$selectedEvent;
  }

  get resourceAttachments() {
    return this.$resourceAttachments;
  }

  get todayLive() {
    return this.$todayLive;
  }

  get groupList() {
    return this.$groupList;
  }

  get hasMoreGroup() {
    return this.$hasMoreGroup;
  }

  get userList() {
    return this.$userList;
  }

  get hasMoreUser() {
    return this.$hasMoreUser;
  }

  get timerMilliseconds() {
    return this.$timerMilliseconds;
  }

  get selectedEventDetail() {
    return this.$selectedEventDetail;
  }

  get agendaHasNextPage() {
    return this.$agendaHasNextPage;
  }

  get agendaHasPreviousPage() {
    return this.$agendaHasPreviousPage;
  }

  resetEventTypeFilter = () => {
    this.$eventType = [];
  };

  dispose = () => {
    reaction(
      () => this.todayLive,
      () => {
        this.setNextEventTimer();
      }
    );
  };
}

export default CalendarStore;
