import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";

const InitZendesk = () => {
  const { UserStore, UserTasksStore } = useStores();

  const handleUserTasksStore = () => {
    UserTasksStore.setTasksAreaTemporarilyHidden(false);
  };

  if (UserStore.activeUser) {
    window.zE(() => {
      window.zE.identify({
        name: `${UserStore.activeUser.name} ${UserStore.activeUser.surname}`,
        email: UserStore.activeUser.email,
        externalId: UserStore.activeUser.id
      });
      window.zE("webWidget:on", "close", handleUserTasksStore);
    });
  }

  return null;
};

export default observer(InitZendesk);
