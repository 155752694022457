import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BusinessGroupCreationForm } from "src/js/components/GroupCreationForm/BusinessGroupCreationForm";
import { useOnboarding } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import {
  __EXAMPLE_BACKGROUND_PATTERN__,
  __EXAMPLE_GROUP_CREATION_IMAGE__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import styled, { useTheme } from "styled-components";
import Example from "../components/Example";
import StepperBlock from "../components/StepperBlock";
import { TitleBlock } from "../components/TitleBlock";
import BackToCreateGroupSelection from "../components/BackToCreateGroupSelection";

const MainContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.basicWhite};
    padding: 24px 20px 24px 20px;
    border-radius: 12px;
  }
`;

const GroupCreation = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { routeGuard } = useOnboarding();

  useEffect(() => {
    routeGuard();
  }, []);

  const mainContent = (
    <MainContentContainer>
      <TitleBlock
        backComponent={<BackToCreateGroupSelection />}
        title={translate("group_creation_title")}
      />
      <BusinessGroupCreationForm theme={whiteTheme} />
    </MainContentContainer>
  );

  return (
    <OnboardingLayout
      mainContent={mainContent}
      sideContent={
        <Example
          background={__EXAMPLE_BACKGROUND_PATTERN__}
          image={__EXAMPLE_GROUP_CREATION_IMAGE__}
          imagePosition="left: 0"
          description={translate(
            "onboarding_example_group_creation_description"
          )}
        />
      }
      stepper={<StepperBlock currentStep={3} totalSteps={4} />}
    />
  );
};

export default observer(GroupCreation);
