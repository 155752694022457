import {
  ActionButton,
  Box,
  Button,
  DoodleLoader,
  Icon,
  colors
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useBoardContext } from "src/js/context/BoardProvider";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";
import { boardResourceOpened } from "src/js/repository/boardRepository";
import { fetchExerciseEntities } from "src/js/repository/exerciseRepository";
import { fetchResource } from "src/js/repository/resourceRepository";
import { isLightColor } from "src/js/theme/utils";
import { useTranslation } from "src/js/translation";
import {
  BoardElement,
  EventDomain,
  OldExercise,
  LessonsEvent,
  OldBoard,
  OldResource,
  Resource
} from "src/js/types";
import Dropdown from "src/legacy/components/dropdown";
import { modalPage } from "src/legacy/models/modalPageModel";
import {
  getActiveGroup,
  getActiveGroupId
} from "src/legacy/modules/activeGroup";
import { getUserId, getUserUuid } from "src/legacy/modules/activeUser";
import {
  getCurrentRoute,
  navigateTo,
  replaceTo
} from "src/legacy/modules/history";
import { createURL } from "src/legacy/modules/utility";
import { useTheme } from "styled-components";
import ResourceComments from "./ResourceComments";
import {
  ResourceViewerWrap,
  ResourceWrapper,
  StyledHeader,
  StyledOptionButton,
  StyledTextWrapper
} from "./ResourceViewer.styles";
import { ResourceViewerProps } from "./ResourceViewer.types";
import { getContentFromResource } from "./utils";

const getBoardElement = ({
  activeBoard,
  resource,
  includeDraft = false
}: {
  activeBoard: OldBoard;
  resource?: OldResource | Resource;
  includeDraft?: boolean;
}) => {
  const elements = includeDraft
    ? activeBoard.board_elements
    : activeBoard.board_elements.filter(res => res.dtype === "boardelement");

  const activeBoardElement = elements.find(
    item => item.resource.id === resource?.id
  );

  return {
    activeBoardElement
  };
};

// TODO fix this mess
// TODO split this from Post and Board view

// This file is used in Post, Boart (only draft for now) and Chat. See ModuleResourceViewer for the plain board usage

const ResourceViewer = ({
  resources = [],
  showComments,
  startingPosition = 0,
  hideTitle = false,
  postId,
  disableOption = false,
  originalURL,
  onClose,
  isDraft
}: ResourceViewerProps) => {
  const { greyTheme, whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    ThemeStore: { customizationSettings },
    BoardsStore: {
      activeBoardStore,
      getActiveBoardId,
      activeBoardElements,
      updateBoardElements,
      setBoardElementCommentNumberMap,
      boardElementCommentNumberMap
    }
  } = useStores();
  const { trackVirtualView } = useViewTracking();
  const { translate } = useTranslation();
  const [showCommentsState, setShowComments] = useState(
    isLayoutModeMobile ? false : showComments
  );
  const [startingPositionState, setStartingPosition] =
    useState(startingPosition);
  const [resourceComponent, setResourceComponent] = useState(null);
  const [activeBoardElementState, setActiveBoardElementState] = useState(null);
  const [activeBoardElementsState, setActiveBoardElementsState] =
    useState(activeBoardElements);
  const [loading, setLoading] = useState(true);
  const [fetchedResource, setFetchedResource] = useState<
    OldResource | OldExercise
  >(null);

  // TODO ad hoc fix requested by product. will be removed soon
  let editButton = null;
  const {
    activeBoard
  }: { activeBoard?: OldBoard; updateBoard?: (board: OldBoard) => void } =
    useBoardContext() || {};

  const [updatedActiveBoard, setUpdatedActiveBoard] = useState(
    activeBoardStore || activeBoard
  );

  let thisResource: OldResource | Resource;

  thisResource = resources?.[startingPositionState];

  const updateResource = () => {
    setLoading(true);

    const cb = ({
      resource,
      exercise
    }: {
      resource?: OldResource | Resource;
      exercise?: OldExercise;
    }) => {
      if (updatedActiveBoard) {
        // this is not an hook
        const { activeBoardElement } = getBoardElement({
          activeBoard: updatedActiveBoard,
          resource,
          includeDraft: true
        });

        if (activeBoardElement) {
          const updatedBoardElements = updatedActiveBoard.board_elements.map(
            boardElement => {
              if (
                boardElement.id === activeBoardElement.id &&
                boardElement.resource.type !== "EXERCISE" &&
                activeBoardElement.dtype !== "boardelementdraft"
              ) {
                boardElement = {
                  ...boardElement,
                  is_completed: true
                };
              }
              return boardElement;
            }
          );

          setActiveBoardElementsState(updatedBoardElements);
          setUpdatedActiveBoard({
            ...updatedActiveBoard,
            board_elements: updatedBoardElements
          });
          setActiveBoardElementState({
            ...activeBoardElement,
            is_completed: true
          });
          updateBoardElements(updatedActiveBoard, updatedBoardElements);
          if (activeBoardElement.dtype !== "boardelementdraft") {
            boardResourceOpened(activeBoard.id, activeBoardElement.id);
          }
        }
        setResourceComponent(
          getContentFromResource({
            resource,
            boardElementId: activeBoardElement?.id,
            exercise
          })
        );
      } else {
        setResourceComponent(getContentFromResource({ resource, exercise }));
      }

      if (["VIMEO", "YOUTUBE"].includes(thisResource.type)) {
        _trackEvent("Lessons", "LessonsInteractions", thisResource.type);
      }

      if ("mime_type" in thisResource) {
        if (thisResource.mime_type === "video/mp4") {
          _trackEvent("Lessons", "LessonsInteractions", "Video");
        }
      }
      if ("fileMimeType" in thisResource) {
        if (thisResource.fileMimeType === "video/mp4") {
          _trackEvent("Lessons", "LessonsInteractions", "Video");
        }
      }
      setLoading(false);
    };

    if (thisResource.type === "EXERCISE") {
      if ("exerciseId" in thisResource) {
        fetchExerciseEntities({
          exerciseId: thisResource.exerciseId,
          report_by: "best"
        }).then((res: OldExercise) => {
          cb({ exercise: res });
          setFetchedResource(res);
        });
      } else {
        fetchExerciseEntities({
          exerciseId: thisResource.exercise.id,
          report_by: "best"
        }).then((res: OldExercise) => {
          cb({ exercise: res });
          setFetchedResource(res);
        });
      }
    } else {
      fetchResource(thisResource.id).then(res => {
        cb({ resource: res });
        setFetchedResource(res);
      });
    }
  };
  const activeBoardElementId = activeBoardElementState?.id;

  useEffect(() => {
    if (!updatedActiveBoard) return;

    const { activeBoardElement } = getBoardElement({
      activeBoard: updatedActiveBoard,
      resource: thisResource
    });

    setActiveBoardElementState(activeBoardElement);

    // i'm using this map from the store to keep the realtime in all places.
    // if it's undefined, i set it with the actual counter from the activeBoardElement
    if (!boardElementCommentNumberMap.get(activeBoardElement?.id)) {
      const counter = activeBoardElement?.comments_count || 0;
      setBoardElementCommentNumberMap(activeBoardElement?.id, counter);
    }
  }, [fetchedResource]);

  useEffect(() => {
    updateResource();
  }, [startingPositionState]);
  const prev = () => {
    let newPos = startingPositionState - 1;

    if (newPos < 0) newPos = 0;

    setStartingPosition(newPos);
    setShowComments(showCommentsState && !isLayoutModeMobile);

    _trackEvent("Lessons", "LessonsInteractions", "Prev");
  };

  const next = () => {
    let newPos = startingPositionState + 1;

    if (newPos >= resources.length) newPos = resources.length - 1;
    setStartingPosition(newPos);
    setShowComments(showCommentsState && !isLayoutModeMobile);

    _trackEvent("Lessons", "LessonsInteractions", "Next");
  };

  const toggleComments = () => {
    const newShowComments = !showCommentsState;
    setShowComments(newShowComments);

    if (!newShowComments) {
      setTimeout(() => {
        const scrollId = document.getElementById("scrollId");
        if (!scrollId) return;
        scrollId.scrollIntoView(true);
      }, 200);
    }
    trackVirtualView(
      newShowComments
        ? WeSchoolVirtualViewDescriptions.Groups.Boards.OpenCommentBar
        : WeSchoolVirtualViewDescriptions.Groups.Boards.CloseCommentBar
    );
  };

  const closeViewer = () => {
    setResourceComponent(null);
    onClose(activeBoardElementsState);
    if (originalURL) {
      replaceTo(originalURL);
    }
  };

  const reportAbuse = () => {
    const reportAbuseUrl = createURL("report_abuse_resource", {
      group_id: getActiveGroupId(),
      resource_id: resources[startingPositionState].id
    });
    navigateTo(reportAbuseUrl);
    modalPage.hide();
  };

  const shouldShowFooter = () => {
    return resources.length > 1 || !!activeBoardElementState?.id;
  };

  useEffect(() => {
    setResourceComponent(<DoodleLoader theme={greyTheme} />);
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    setShowComments(showCommentsState);
  }, []);

  let resourceFooter = null;
  let dropdownMenu = null;
  const dropdownOptions = [];
  let viewerBodyLayout = "";
  let resourceBodyLayout = "";
  let editFunc = null;
  let thisElementInt: BoardElement;
  if (updatedActiveBoard) {
    const elements = updatedActiveBoard.board_elements.filter(
      res => res.dtype === "boardelement"
    );
    thisElementInt = elements.find(
      item => item.resource.id === thisResource.id
    );
  }

  let resourceTitle = hideTitle ? "" : thisResource.name;
  const commentable = !!activeBoardElementId;

  if (activeBoardElementId) {
    thisElementInt = activeBoard.board_elements.find(
      boardElement => boardElement.resource.id === thisResource.id
    );

    // The problem is that, when you edit a resource within a board element, that resource is a different one
    // this means that the id is different, so we need to find the board element by the id of it and not the resource within it.
    // i've decided to keep the old find with the resource above to avoid breaking the code, but i've added this one to fix the problem.
    // soon this whole madness will be fixed
    if (!thisElementInt) {
      thisElementInt = updatedActiveBoard.board_elements.find(
        boardElement => boardElement.id === activeBoardElementId
      );
    }

    resourceBodyLayout = showCommentsState ? "resource--comments" : "";
    if (thisElementInt) {
      if (thisElementInt.name?.length > 0) {
        resourceTitle = thisElementInt.name;
      }
    }
  }

  if (
    getActiveGroup().isTeacher() ||
    // @ts-expect-error - Legacy stuff
    getUserId() === fetchedResource?.user?.id ||
    // @ts-expect-error - Legacy stuff
    getUserUuid() === fetchedResource?.user?.uuid
  ) {
    if (activeBoardElementId) {
      editFunc = () => {
        const url = createUrl(
          isDraft ? "module_element_edit_draft" : "module_element_edit",
          {
            group_id: getActiveGroupId(),
            module_id: getActiveBoardId,
            board_element_id: activeBoardElementId
          }
        );
        navigateTo(url);
        modalPage.hide();
      };
    } else if (postId) {
      editFunc = () => {
        const url = createUrl("post_resource", {
          group_id: getActiveGroupId(),
          // @ts-expect-error - Legacy stuff
          post_id: postId,
          resource_id: String(resources[startingPositionState].id)
        });
        navigateTo(`${url}/edit`);
      };
    } else {
      editFunc = () => {
        navigateTo(`${getCurrentRoute()}/edit`);
        modalPage.hide();
      };
    }

    if (fetchedResource?.type === "TEXT") {
      editButton = (
        <StyledOptionButton type="button">
          <Icon
            icon="editSecondary"
            initialViewbox
            width="20px"
            height="20px"
            onClick={editFunc}
          />
        </StyledOptionButton>
      );
    }
  }

  if (
    // @ts-expect-error - Legacy stuff
    fetchedResource?.mime_type &&
    // @ts-expect-error - Legacy stuff
    fetchedResource?.mime_type === "application/pdf"
  ) {
    dropdownOptions.push(
      <li>
        <a
          className="resource-options-dropdown-link"
          // @ts-expect-error - Legacy stuff
          href={fetchedResource.public_url}
          target="_blank"
        >
          {translate("download")}
        </a>
      </li>
    );
  }

  // @ts-expect-error - Legacy stuff
  if (getUserId() != fetchedResource?.user?.id) {
    dropdownOptions.push(
      <li>
        <a className="resource-options-dropdown-link" onClick={reportAbuse}>
          {translate("resource_report_abuse_option_label")}
        </a>
      </li>
    );
  }

  if (disableOption === false && dropdownOptions.length > 0) {
    dropdownMenu = (
      <div className="viewer-resource__header-button">
        <StyledOptionButton
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <Icon icon="gear" height={22} width={22} />
        </StyledOptionButton>
        {/* @ts-expect-error - Legacy stuff */}
        <Dropdown
          className="resource-options-dropdown"
          items={dropdownOptions}
        />
      </div>
    );
  }
  if (shouldShowFooter()) {
    resourceFooter = (
      <div className="viewer-resource__footer">
        {startingPositionState !== 0 ? (
          <ActionButton
            icon="chevronLeft"
            onClick={prev}
            theme={whiteTheme}
            variant="secondary"
          />
        ) : (
          <div style={{ height: "40px", width: "40px" }} />
        )}
        {commentable && (
          <div className="viewer-resource__comments-toggle">
            <Button
              onClick={toggleComments}
              theme={greyTheme}
              variant="primary"
              withIcon={{
                icon: showCommentsState ? "chevronLeft" : "chevronRight",
                positionIcon: "right",
                heightIcon: 16,
                widthIcon: 16,
                viewbox: "0 0 24 24"
              }}
            >
              {`${boardElementCommentNumberMap.get(activeBoardElementId)} ${translate(
                boardElementCommentNumberMap.get(activeBoardElementId) === 1
                  ? "commento"
                  : "commenti"
              )}`}
            </Button>
          </div>
        )}
        {startingPositionState !== resources.length - 1 ? (
          <ActionButton
            icon="chevronRight"
            onClick={next}
            theme={whiteTheme}
            variant="secondary"
          />
        ) : (
          <div style={{ height: "40px", width: "40px" }} />
        )}
      </div>
    );
  }

  return (
    <ResourceViewerWrap>
      <Box
        backgroundColor={colors.grey[1000]}
        height={shouldShowFooter() ? "calc(100% - 48px)" : "100%"}
        id={`resource-iframe${fetchedResource?.id}`}
      >
        <StyledHeader
          backgroundColor={
            customizationSettings?.layoutColor || colors.violet[500]
          }
        >
          <StyledTextWrapper
            color={
              isLightColor(customizationSettings?.layoutColor)
                ? colors.grey[50]
                : colors.grey[1000]
            }
          >
            <div className="viewer-resource__title-text">{resourceTitle}</div>
            {editButton}
            {dropdownMenu}
            <div className="viewer-resource__header-button">
              <ActionButton
                onClick={closeViewer}
                icon="close"
                theme={whiteTheme}
                variant="secondary"
              />
            </div>
          </StyledTextWrapper>
        </StyledHeader>
        <div className={`viewer-resource__body ${viewerBodyLayout}`}>
          <ResourceWrapper className={`resource ${resourceBodyLayout}`}>
            {loading ? <DoodleLoader theme={whiteTheme} /> : resourceComponent}
          </ResourceWrapper>
          {commentable && (
            <ResourceComments
              parentId={thisElementInt.id}
              showing={showCommentsState}
              onNewComment={() => {
                _trackEvent(EventDomain.Lessons, LessonsEvent.CommentCreate);
                const oldCommentNumber =
                  boardElementCommentNumberMap.get(activeBoardElementId);
                setBoardElementCommentNumberMap(
                  activeBoardElementId,
                  oldCommentNumber + 1
                );
              }}
              onDeleteComment={(nestedCommentNumber = 0) => {
                _trackEvent(EventDomain.Lessons, LessonsEvent.CommentDelete);
                const oldCommentNumber =
                  boardElementCommentNumberMap.get(activeBoardElementId);
                setBoardElementCommentNumberMap(
                  activeBoardElementId,
                  oldCommentNumber - 1 - nestedCommentNumber
                );
              }}
            />
          )}
        </div>
        {resourceFooter}
      </Box>
    </ResourceViewerWrap>
  );
};

export default observer(ResourceViewer);
