import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import createUrl from "src/js/modules/routing";
import { Translate } from "src/js/translation/TranslationProvider";
import { Avatar, Button, Icon } from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { withTheme } from "styled-components";
import UserModel from "../../models/UserModel";
import { getActiveGroup, getActiveGroupId } from "../../modules/activeGroup";
import { getUserId } from "../../modules/activeUser";

const RankMembersContainer = observer(({ children }) => {
  const { UIStore } = useStores();

  return (
    <li className={UIStore.isSideBarOpen ? "ranking-member--sidebar-open" : ""}>
      {children}
    </li>
  );
});

const RankMembersBox = withBackbone(
  createReactClass({
    getInitialState() {
      return {
        members: [],
        membersLoaded: false,
        neighborMembers: [],
        neighborMembersLoaded: false
      };
    },
    componentWillUnmount() {
      this.props.group.off("change", this.onGroupChange);
    },
    checkUserInRanking() {
      const self = this;
      let response = false;
      map(self.props.members, member => {
        if (member.user.id == getUserId()) {
          response = true;
        }
      });
      return response;
    },
    getStateIndicator(state, score) {
      const indicator = {
        className: "stay-position",
        icon: "minus"
      };
      if (score == 0) {
        return indicator;
      }
      switch (true) {
        case state > 0:
          indicator.className = "up-position";
          indicator.icon = "chevronUp";
          break;
        case state == 0:
          indicator.className = "stay-position";
          indicator.icon = "minus";
          break;
        case state < 0:
          indicator.className = "down-position";
          indicator.icon = "chevronDown";
          break;
      }
      return indicator;
    },
    render() {
      const self = this;
      const { members } = this.props;
      const { neighborMembers } = this.props;
      let usersBlock = <div />;
      let neighborsBlock = <div />;
      let userRankingBlock = "";
      let buttonRanking = "";
      const { whiteTheme } = this.props.theme;

      if (!isEmpty(this.props.members)) {
        if (members.length > 0) {
          usersBlock = map(members, (memberRaw, index) => {
            const { score } = memberRaw;
            const stateIndicator = self.getStateIndicator(
              memberRaw.move,
              score
            );
            const member = new UserModel(memberRaw.user);
            const memberUrl = member.getLink();
            const position = index + 1;
            let medalClass = "gold-medal";
            if (position == 2) {
              medalClass = "silver-medal";
            }
            if (position == 3) {
              medalClass = "bronze-medal";
            }
            return (
              <RankMembersContainer>
                <a
                  href={memberUrl}
                  key={`box-member-${member.get("id")}`}
                  title={member.getFullName()}
                  className="clickable"
                >
                  <div className="row">
                    <div className="col-xs-2">
                      <div className="image-profile">
                        <Avatar
                          theme={whiteTheme}
                          url={member.getAvatar("small")}
                          size="m"
                          gender={member.getGenderString()}
                          alt={member.getFullName()}
                          rounded
                        />
                        <div className={`rank-number ${medalClass}`}>
                          {position}
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <span className="hidden-name">{member.get("name")}</span>{" "}
                      {member.get("surname")}
                    </div>
                    <div className="col-xs-2">
                      <div className="point">{score}</div>
                    </div>
                    <div className="col-xs-2 text-center">
                      <div
                        className={`ranking-change ${stateIndicator.className}`}
                      >
                        <Icon
                          icon={stateIndicator.icon}
                          height={20}
                          width={20}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </RankMembersContainer>
            );
          });
        }
      }
      if (self.checkUserInRanking() == false) {
        if (this.props.neighborMembersLoaded) {
          if (neighborMembers.length > 0) {
            neighborsBlock = map(neighborMembers, (memberRaw, index) => {
              const { score } = memberRaw;
              const { position } = memberRaw;
              const isCurrentUser = memberRaw.is_current_user;
              const currentUserClass = isCurrentUser
                ? "current-user-position"
                : "";
              const stateIndicator = self.getStateIndicator(
                memberRaw.move,
                score
              );
              const member = new UserModel(memberRaw.user);
              const memberUrl = member.getLink();
              return (
                <li>
                  <a
                    href={memberUrl}
                    key={`box-member-${member.get("id")}`}
                    title={member.getFullName()}
                    className="clickable"
                  >
                    <div className={`row ${currentUserClass}`}>
                      <div className="col-xs-2">
                        <div className="image-profile">
                          <Avatar
                            theme={whiteTheme}
                            url={member.getAvatar("small")}
                            size="m"
                            gender={member.getGenderString()}
                            alt={member.getFullName()}
                            rounded
                          />
                          <div className="rank-number">{position}</div>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <span className="hidden-name">
                          {member.get("name")}
                        </span>{" "}
                        {member.get("surname")}
                      </div>
                      <div className="col-xs-2">
                        <div className="point">{score}</div>
                      </div>
                      <div className="col-xs-2 text-center">
                        <div
                          className={`ranking-change ${stateIndicator.className}`}
                        >
                          <Icon
                            icon={stateIndicator.icon}
                            height={20}
                            width={20}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              );
            });
            userRankingBlock = (
              <div>
                <hr />
                <div className="text-center vertical-margin-10">
                  <div className="default-title">
                    <Translate text="your_position" />
                  </div>
                </div>
                <ul>{neighborsBlock}</ul>
              </div>
            );
          }
        }
      }

      buttonRanking = (
        <div className="flex-centered-column margin-top-15">
          <Button
            onClick={() => {
              navigateTo(
                createUrl("ranking", {
                  group_id: getActiveGroupId(),
                  page_number: getActiveGroup().get("currentUserPosition")
                })
              );
            }}
            variant="secondary"
            theme={whiteTheme}
          >
            <Translate text="view_all" />
          </Button>
        </div>
      );

      return (
        <div className="default-box ranking-box">
          <div className="text-center vertical-margin-10">
            <div className="default-title">Top 3</div>
          </div>
          <ul>{usersBlock}</ul>
          {userRankingBlock}
          {buttonRanking}
        </div>
      );
    }
  })
);

export default withTheme(RankMembersBox);
