import { IconName } from "@arcadia/design-system";
import { TranslationKeys } from "src/js/translation";
import {
  AppPreference,
  EmailPreference
} from "src/js/types/models/Notification";

type BaseRadioElement = {
  icon: IconName;
  title: TranslationKeys;
  description: TranslationKeys;
};

type AppRadioElement = BaseRadioElement & {
  value: AppPreference;
};

type EmailRadioElement = BaseRadioElement & {
  value: EmailPreference;
};

export const getAppIconFromPreference = (
  preference: AppPreference
): IconName => {
  switch (preference) {
    case "Highlights":
      return "notificationHighlights";
    case "None":
      return "notificationMuted";
    default:
      return "notificationUnmuted";
  }
};

export const getEmailIconFromPreference = (
  preference: EmailPreference
): IconName => {
  switch (preference) {
    case "Daily":
      return "mailClock";
    case "Weekly":
      return "mailWithCalendar";
    case "None":
      return "mailCrossed";
    default:
      return "mailAsterisk";
  }
};

export const AppPreferenceRadioList: AppRadioElement[] = [
  {
    icon: getAppIconFromPreference("All"),
    value: "All",
    title: "group_settings_notification_all_title",
    description: "group_settings_notification_all_description"
  },
  {
    icon: getAppIconFromPreference("Highlights"),
    value: "Highlights",
    title: "group_settings_notification_highlights_title",
    description: "group_settings_notification_highlights_description"
  },
  {
    icon: getAppIconFromPreference("None"),
    value: "None",
    title: "group_settings_notification_none_title",
    description: "group_settings_notification_none_description"
  }
];

export const EmailPreferenceRadioList: EmailRadioElement[] = [
  /* {
    icon: getEmailIconFromPreference("All"),
    value: "All",
    title: "group_settings_notification_email_all_title",
    description: "group_settings_notification_email_all_description"
  }, option disabled because we have many complain for too many emails received */
  {
    icon: getEmailIconFromPreference("Daily"),
    value: "Daily",
    title: "group_settings_notification_email_daily_title",
    description: "group_settings_notification_email_daily_description"
  },
  {
    icon: getEmailIconFromPreference("Weekly"),
    value: "Weekly",
    title: "group_settings_notification_email_weekly_title",
    description: "group_settings_notification_email_weekly_description"
  },
  {
    icon: getEmailIconFromPreference("None"),
    value: "None",
    title: "group_settings_notification_email_none_title",
    description: "group_settings_notification_email_none_description"
  }
];
