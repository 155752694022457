import React from "react";
import { BasicPanel } from "./Panel.styles";

const Panel = ({ flex = true, children, isSelectable, ...props }) => {
  return (
    <BasicPanel flex={flex} isSelectable={isSelectable} {...props}>
      {children}
    </BasicPanel>
  );
};

export default Panel;
