import Link from "@tiptap/extension-link";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";
import * as S from "./TextEditorRender.styles";

const TextEditorRender = ({
  content,
  plainText
}: {
  content: string;
  plainText?: boolean;
}) => {
  const editor = useEditor({
    editable: false,
    content,
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false
      })
    ]
  });

  useEffect(() => {
    editor?.commands.setContent(content);
  }, [content]);

  if (plainText) {
    return <span>{editor?.getText().trim()}</span>;
  }

  return <S.StyledEditableContentRead editor={editor} />;
};

export default TextEditorRender;
