import getUserClient from "src/js/modules/connection";
import {
  api_spaces_threads,
  api_spaces_threads_exists,
  api_spaces_threads_messages,
  api_threads_settings,
  api_threads_unread_oldest,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { BasePaginationResponse, Thread, ThreadMessage } from "src/js/types";

export const fetchThreads = async ({ spaceId }: { spaceId: string }) => {
  return getUserClient()
    .get<BasePaginationResponse<Thread>>(
      getVersionedApiUrl(api_spaces_threads({ spaceId }), "v3")
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchThreadMessages = async ({
  spaceId,
  threadId,
  fromId,
  toId,
  limit
}: {
  spaceId: string;
  threadId: string;
  fromId?: string;
  toId?: string;
  limit?: number;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<ThreadMessage>>(
      getVersionedApiUrl(
        api_spaces_threads_messages({ spaceId, threadId }),
        "v3"
      ),
      {
        params: {
          fromId,
          toId,
          limit
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const checkThreadExists = async ({
  spaceId,
  participants
}: {
  spaceId: string;
  participants: string[];
}) => {
  return getUserClient()
    .get<{ threadId: string }>(
      getVersionedApiUrl(api_spaces_threads_exists({ spaceId }), "v3"),
      {
        params: {
          participants
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const createThread = async ({
  spaceId,
  userIds
}: {
  spaceId: string;
  userIds: string[];
}) => {
  return getUserClient()
    .post<Thread>(getVersionedApiUrl(api_spaces_threads({ spaceId }), "v3"), {
      userIds
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const editThreadSettings = async ({
  threadId,
  muted
}: {
  threadId: string;
  muted?: boolean;
}) => {
  return getUserClient()
    .put(getVersionedApiUrl(api_threads_settings({ threadId }), "v3"), {
      muted
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchOldestUnreadMessageThread = async ({
  threadId
}: {
  threadId: string;
}) => {
  return getUserClient()
    .get<ThreadMessage>(
      getVersionedApiUrl(api_threads_unread_oldest({ threadId }), "v3")
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};
