import { Button } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

const MobilePublishButton = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isSideBarOpen, isLayoutModeMobile },
    BoardsStore: { togglePublishBoard, getSingleBoard, getActiveBoardId },
    GroupStore: { groupId, userIsTeacher }
  } = useStores();
  const board = getSingleBoard({
    groupId,
    boardId: getActiveBoardId
  });
  const { isPublished } = board || {};

  const publishModule = () => {
    togglePublishBoard({
      groupId,
      boardId: getActiveBoardId,
      publish: !isPublished
    })
      .then(() => {
        if (!isPublished) {
          showToastSuccess({ str: translate("board_publish_success") });
        } else {
          showToastSuccess({
            str: translate("board_unpublish_success")
          });
        }
      })
      .catch(() => {
        if (!isPublished) {
          showToastError({ str: translate("board_error_publish") });
        } else {
          showToastError({ str: translate("board_error_unpublish") });
        }
      });
  };

  if (!userIsTeacher || isPublished || !isLayoutModeMobile) {
    return null;
  }

  return (
    <div
      className={`app__fixed-footer floating ${
        isSideBarOpen ? "app__fixed-footer--sidebar-open" : ""
      }`}
    >
      <Button
        onClick={publishModule}
        variant="primary"
        theme={whiteTheme}
        fullWidth
      >
        {translate("board_detail_header_publish")}
      </Button>
    </div>
  );
};

export default observer(MobilePublishButton);
