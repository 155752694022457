import { Container } from "react-awesome-styled-grid";
import styled from "styled-components";

export const DashboardSecondaryHeaderWrapper = styled.div<{
  isLayoutModeMobile: boolean;
}>`
  background-color: ${({ theme, isLayoutModeMobile }) =>
    isLayoutModeMobile
      ? theme.colors.headerPrimaryColor
      : theme.colors.grey[1000]};
  padding: ${({ isLayoutModeMobile }) => (isLayoutModeMobile ? "6px 4px" : 0)};
  padding-top: 0px;
`;

export const DashboardSecondaryHeaderContent = styled(Container)<{
  isLayoutModeMobile: boolean;
}>`
  min-height: ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile ? undefined : "72px"};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

export const SpaceNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const SpaceName = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
`;

export const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
