import React, { useCallback, useEffect } from "react";
import { Button, Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { BaseTextEditor } from "src/js/components/global/BaseTextEditor";
import { TextEditorOptions } from "src/js/components/global/BaseTextEditor/EditorOptions.const";
import { useTranslation } from "src/js/translation";
import { useEditor } from "src/js/components/global/BaseTextEditor/hooks";
import { BaseTextEditorMenu } from "src/js/components/global/BaseTextEditor/components";
import {
  Divider,
  EditorExtension,
  EditorExtensionType
} from "src/js/components/global/BaseTextEditor/BaseTextEditor.types";
import { useTypingEffect } from "../hooks";

import * as S from "./AITextEditor.styles";

const textMenuExtensions: Partial<EditorExtensionType>[] = [
  EditorExtension.TextFormatting,
  Divider,
  EditorExtension.Bold,
  EditorExtension.Italic,
  EditorExtension.Strike,
  Divider,
  EditorExtension.BulletList,
  EditorExtension.OrderedList,
  EditorExtension.BlockQuote
];

const AITextEditor = ({
  text,
  onReset,
  onSubmit,
  onBack
}: {
  text: string;
  onReset: () => void;
  onSubmit: (text: string) => void;
  onBack: () => void;
}) => {
  const { currentText, isTypingOver } = useTypingEffect({ text, delay: 5 });
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const editor = useEditor({
    editable: false,
    content: "",
    options: { ...TextEditorOptions }
  });

  useEffect(() => {
    if (!editor && !currentText) return;
    editor.commands.setContent(currentText, true);
  }, [currentText]);

  useEffect(() => {
    if (!isTypingOver) return;
    editor.setEditable(true);
  }, [isTypingOver]);

  const textEditorMenu = useCallback(
    () => (
      <BaseTextEditorMenu editor={editor} extensions={textMenuExtensions} />
    ),
    [editor]
  );

  return (
    <S.Wrapper>
      <S.EditorWrapper>
        <BaseTextEditor
          editor={editor}
          floatingMenu={textEditorMenu}
          bubbleMenu={textEditorMenu}
          className="EditorWrapper"
        />
        <S.RegenerateFooter>
          <S.RegenerateButton
            type="button"
            onClick={onReset}
            disabled={!isTypingOver}
          >
            <Icon icon="undo" />
            <Text type="formFieldEmpty">
              {translate("ai_editor_regenerate_response_cta")}
            </Text>
          </S.RegenerateButton>
        </S.RegenerateFooter>
      </S.EditorWrapper>
      <S.Footer>
        <Button
          theme={whiteTheme}
          type="button"
          variant="secondary"
          onClick={() => {
            onBack();
          }}
        >
          {translate("ai_editor_go_back_cta")}
        </Button>
        <Button
          theme={whiteTheme}
          type="button"
          variant="primary"
          disabled={!isTypingOver || !editor?.getText().trim()}
          onClick={() => {
            onSubmit(editor.getHTML());
          }}
        >
          {translate("ai_editor_submit_cta")}
        </Button>
      </S.Footer>
    </S.Wrapper>
  );
};

export default AITextEditor;
