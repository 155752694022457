import { ObjectValues } from "src/js/types";

export const CourseWizardModalStep = {
  courseDetails: "courseDetails",
  draftingModulePreview: "draftingModulesPreview",
  modulesPreview: "modulesPreview",
  draftingCourse: "draftingCourse"
} as const;

export type CourseWizardModalStep = ObjectValues<typeof CourseWizardModalStep>;

export const CourseWizardModalEvent = {
  fail: "ai_course_generation_failed",
  success: "ai_course_generation_succeded"
};

export type CourseWizardModalEvent = ObjectValues<
  typeof CourseWizardModalEvent
>;
