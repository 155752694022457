import styled, { css } from "styled-components";
import { Input, colors } from "@arcadia/design-system";

export const ScreenWrapper = styled.div<{ hasCustomizedLogin: boolean }>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-content: ${({ hasCustomizedLogin }) =>
    hasCustomizedLogin ? "center" : "space-between"};
  padding: 24px 16px;
  padding-top: 36px;
`;

export const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const InviteSpaceName = styled.span`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
`;

export const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 12px;
`;

export const CustomInput = styled(Input)<{ hasError: boolean }>`
  ${({ hasError }) =>
    hasError &&
    css`
      & > div {
        input {
          border: 1px solid ${colors.error[600]};
        }
      }
    `}
`;
