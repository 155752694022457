import colors from "@ds_themes/colors";

export const basic = {
  background: colors.grey[1000],
  text: colors.grey[50],
  pin: colors.violet[501],
  ribbon: colors.violet[501],
  date: {
    text: colors.grey[300]
  }
};

export const comment = {
  background: colors.coldGrey[900],
  date: {
    text: colors.grey[300]
  }
};

export const badgeScore = {
  background: colors.grey[1000],
  border: colors.violet[500]
};
