import React, { useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import {
  Icon,
  Box,
  Text,
  Button,
  ModalSizeType,
  ContextualAlert,
  DoodleLoader
} from "@arcadia/design-system";
import {
  THUMBNAIL_MINIMUM_HEIGHT,
  THUMBNAIL_MINIMUM_WIDTH
} from "src/js/settings/settingsResource";
import { IMAGE_MIME } from "src/js/settings/settingFileUploader";
import useCropImageModal from "./useCropImageModal";
import * as S from "./CropImageModal.styles";
import { CropImageModalProps } from "./CropImageModal.types";

const CropImageModal = ({
  close,
  imagePreview,
  shouldZoomInImage,
  aspect = 3 / 2,
  minimumImageWidth = THUMBNAIL_MINIMUM_WIDTH,
  minimumImageHeight = THUMBNAIL_MINIMUM_HEIGHT,
  alertMessage,
  modalTitle,
  imageMimeType,
  imageTitle,
  saveNewCroppedImage,
  accept = IMAGE_MIME.join(",")
}: CropImageModalProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { isAnimating }
  } = useStores();
  const {
    onSelectFile,
    showCroppedImage,
    onCropComplete,
    selectedFile,
    setPreview,
    setCrop,
    setZoom,
    selectedPreview,
    crop,
    zoom,
    disabled
  } = useCropImageModal({
    saveNewCroppedImage,
    minimumImageWidth,
    minimumImageHeight
  });

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
  }, [selectedFile]);

  const handleUploadClick = () => {
    inputRef?.current.click();
  };

  const isZoomEnabled = !!(shouldZoomInImage || selectedFile);

  const handleZoomChange = (newZoom: number) => {
    if (isZoomEnabled) {
      setZoom(newZoom);
    }
  };

  return (
    <ModalLayout
      closeModal={close}
      labels={{
        title: translate(modalTitle),
        mobileTitle: translate(modalTitle),
        mobileClose: translate("Annulla")
      }}
      desktopProps={{ hasAdaptiveHeight: false, size: ModalSizeType.Normal }}
      mobileProps={{ withDrag: false }}
    >
      {!isAnimating ? (
        <>
          <S.CropperContainer id="cropper-container">
            <Cropper
              image={selectedPreview || imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={handleZoomChange}
              showGrid={false}
              maxZoom={10}
              minZoom={1}
              objectFit="contain"
            />
          </S.CropperContainer>
          <S.AlertContainer>
            <ContextualAlert
              theme={whiteTheme}
              text={
                alertMessage
                  ? translate(alertMessage)
                  : translate({
                      text: "thumbnail_minimum_size_modal_warning",
                      stringVariables: {
                        width: minimumImageWidth,
                        height: minimumImageHeight
                      }
                    })
              }
            />
          </S.AlertContainer>
          {isLayoutModeMobile ? null : (
            <Box paddingLeft={86} paddingRight={86}>
              <Box marginTop={24}>
                <Text type="ctaTertiary">
                  {translate("resource_edit_thumbnail_zoom")}
                </Text>
              </Box>
              <Box display="flex" alignItems="center" gap={8}>
                <S.ZoomIconContainer
                  onClick={() => zoom > 1 && isZoomEnabled && setZoom(zoom - 1)}
                >
                  <Icon icon="minus" width={24} height={24} initialViewbox />
                </S.ZoomIconContainer>
                <S.ZoomBarContainer>
                  <S.ZoomBar width={zoom * 10} />
                </S.ZoomBarContainer>
                <S.ZoomIconContainer
                  onClick={() =>
                    zoom < 10 && isZoomEnabled && setZoom(zoom + 1)
                  }
                >
                  <Icon icon="plusThin" width={24} height={24} />
                </S.ZoomIconContainer>
              </Box>
            </Box>
          )}
          <S.ButtonsContainer>
            <Button
              theme={greyTheme}
              variant="secondary"
              onClick={handleUploadClick}
            >
              {translate("resource_edit_thumbnail_secondary_button")}
            </Button>
            <input
              type="file"
              onChange={onSelectFile}
              style={{ display: "none" }}
              ref={inputRef}
              accept={accept}
            />
            <Button
              onClick={() =>
                showCroppedImage(imagePreview, imageMimeType, imageTitle)
              }
              theme={greyTheme}
              variant="primary"
              disabled={disabled || !isZoomEnabled}
            >
              {translate("resource_edit_thumbnail_primary_button")}
            </Button>
          </S.ButtonsContainer>
        </>
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <DoodleLoader theme={whiteTheme} />
        </Box>
      )}
    </ModalLayout>
  );
};

export default observer(CropImageModal);
