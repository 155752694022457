import { Link } from "@arcadia/design-system";
import styled, { css } from "styled-components";
import { SFakePostContent, SFakePostLayout } from "./FakePost.types";

export const ReadMore = styled(Link)`
  font-size: 18px;
  padding: 0;
`;

export const Content = styled.div<SFakePostContent>`
  padding: 12px 0 24px;
  > span {
    white-space: pre-line;
    line-height: 22px;
  }
  ${({ showAllContent }) =>
    showAllContent &&
    css`
      > span {
        -webkit-line-clamp: initial !important;
      }
    `};
`;

export const PreOL = styled.span`
  display: inline !important;
  font-weight: 400;
`;

export const Ol = styled.ol<{ isMobile?: boolean; showAllContent?: boolean }>`
  padding-left: 18px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 18px;
    `};
  li {
    margin: 8px 0;
    line-height: 18px;
  }
  li::marker {
    color: ${({ theme }) => theme.primaryColor[500]};
    font-weight: 600;
  }
  ${({ isMobile, showAllContent }) =>
    isMobile &&
    !showAllContent &&
    css`
      display: none;
    `};
  ${({ isMobile }) =>
    isMobile &&
    css`
      li {
        line-height: 22px;
      }
    `};
`;

export const ListItemTitle = styled.span`
  color: ${({ theme }) => theme.primaryColor[500]};
  font-weight: 600;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderBody = styled.div`
  span {
    display: block;
  }
`;

export const Layout = styled.div<SFakePostLayout>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 32px 24px 24px;
  border-radius: 16px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      border-radius: 0;
      padding: 16px;
      ${Content} {
        padding: 24px 0;
        > span {
          font-size: 18px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
        }
      }
    `};
`;
