import React, { useEffect, useRef, useState } from "react";
import { fetchLTIResource } from "src/js/repository/resourceRepository";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";

// LTI POC
const Lti = ({ elementId }: { elementId: number }) => {
  const [link, setLink] = useState("");
  const linkRef = useRef<HTMLDivElement>();
  const { greyTheme } = useTheme();
  useEffect(() => {
    if (!elementId) return;
    fetchLTIResource({ elementId })
      .then(linkRes => {
        const output = `${linkRes.substring(
          0,
          linkRes.indexOf(">")
        )} target="LtiViewFrame"
                  ${linkRes.substring(linkRes.indexOf(">"))}`;
        setLink(output);
      })
      .catch(err => console.log(err));
  }, [elementId]);

  useEffect(() => {
    if (link && linkRef.current.childNodes[0]) {
      // @ts-expect-error - Legacy stuff
      linkRef.current.childNodes[0].click();
    }
  }, [link]);

  return (
    <>
      <div
        style={{ display: "none" }}
        ref={linkRef}
        dangerouslySetInnerHTML={{ __html: link }}
      />
      {link ? (
        <div className="full-height">
          <iframe
            name="LtiViewFrame"
            title="LtiViewFrame"
            width="100%"
            height="100%"
          />
        </div>
      ) : (
        <DoodleLoader theme={greyTheme} />
      )}
    </>
  );
};

export default Lti;
