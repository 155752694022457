import styled from "styled-components";
import { input } from "#themes/white/input";

export const PillList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px 4px;
  margin-bottom: 20px;
`;

export const DropdownContainer = styled.div`
  margin: 5px 5px 0 5px;

  @media screen and (min-width: 768px) {
    margin: 0 5px;
    width: 100%;
  }
`;

export const InsideGroupLabel = styled.span`
  margin-right: 5px;
  color: ${input.label};
`;
