import colors from "@ds_themes/colors";
import { primary as ButtonPrimary } from "@ds_themes/black/button";
import { primary as ActionButtonPrimary } from "@ds_themes/black/actionButton";

const blackTheme = {
  background: colors.grey[50],
  text: colors.grey[1000],
  button: {
    primary: ButtonPrimary
  },
  actionButton: {
    primary: ActionButtonPrimary
  }
};

export default blackTheme;
