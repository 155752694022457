import { ToolbarMenuItem, Palette } from "@ds_themes/types";

export const createToolbarMenuItemTheme = ({
  grey,
  violet,
  coldGrey,
  error
}: Palette): { toolbarmenuitem: ToolbarMenuItem } => {
  return {
    toolbarmenuitem: {
      background: grey[1000],
      color: coldGrey[700],
      selected: {
        background: violet[900]
      },
      disabled: {
        color: coldGrey[800]
      },
      hover: {
        color: coldGrey[600]
      },
      dot: {
        background: error[600],
        border: grey[1000]
      }
    }
  };
};
