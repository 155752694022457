import React, { useEffect, useMemo, useState } from "react";
import { Box, Checkbox, Dropdown, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { DatePickerInput } from "src/js/components/forms/DatePickerInput";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TimePicker } from "src/js/components/forms/TimePicker";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { format, isBefore } from "date-fns";
import { DateInputStartDates } from "src/js/components/forms/DatePickerInput/DatePickerInput.types";
import * as S from "./CalendarEventModalView.styles";
import { getDatesWithTime, hasSameDay } from "./CalendarEventModalUtils";
import { EventCategory, EventRecurrence } from "../Calendar.types";
import EventRecurrenceInfo from "./components/EventRecurrenceInfo";

const CalendarEventModalDates = ({
  showDates = true,
  startDate,
  setStartDate = () => {},
  startTime,
  setStartTime = () => {},
  endDate,
  setEndDate = () => {},
  endTime,
  setEndTime = () => {},
  datesHaveError = false,
  recurrence,
  setRecurrence = () => {},
  setDatesHaveError = () => {},
  eventType = EventCategory.Live,
  register,
  allDay = false,
  editMode = false
}) => {
  const [showTimePicker, setShowTimePicker] = useState(!allDay);
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  useEffect(() => {
    const { startDateWithTime, endDateWithTime } = getDatesWithTime({
      startDate,
      endDate,
      startTime,
      endTime
    });
    setDatesHaveError(isBefore(endDateWithTime, startDateWithTime));
  }, [startTime, startDate, endTime, endDate]);

  useEffect(() => {
    if (eventType === EventCategory.Deadline) {
      setEndDate(startDate);
      setEndTime(startTime);
    }
  }, [startTime, startDate, eventType]);

  const eventRecurrenceArray = [
    {
      label: "calendar_event_modal_recurrence_placeholder",
      id: null,
      detail: null
    },
    {
      label: "event_recurrence_daily_label",
      id: EventRecurrence.Daily,
      detail: null
    },
    {
      label: "event_recurrence_daily_weekdays_label",
      id: EventRecurrence.DailyWeekdays,
      detail: null
    },
    {
      label: "event_recurrence_weekly_label",
      id: EventRecurrence.Weekly,
      detail: startDate ? format(startDate, "EEEE") : null
    },
    {
      label: "event_recurrence_monthly_label",
      id: EventRecurrence.Monthly,
      detail: startDate ? format(startDate, "do") : null
    }
  ];

  const recurrenceOptions = useMemo(
    () =>
      eventRecurrenceArray.map(option => ({
        id: option.id,
        label: translate(option.label, { detail: option.detail })
      })),
    [startDate]
  );

  return eventType === EventCategory.Live && !showDates ? null : (
    <>
      <Box margin="16px 0">
        <S.FlexRow>
          <S.DatePickerWrapper>
            <DatePickerInput
              id="calendar_event_modal_start_date"
              label="calendar_event_modal_activity_start_date"
              selectedDate={startDate}
              onChange={setStartDate}
              calendarStartDay={1}
            />
          </S.DatePickerWrapper>
          {showTimePicker && (
            <S.TimePickerWrapper>
              <TimePicker
                id="calendar_event_modal_start_time"
                label="calendar_event_modal_start_time"
                hasSuggestion
                suggestionAlignedLeft={getBreakpoint() === "smartphone"}
                handleInputChange={setStartTime}
                defaultValue={startTime}
              />
            </S.TimePickerWrapper>
          )}
        </S.FlexRow>
        {eventType !== EventCategory.Deadline ? (
          <S.FlexRow marginTop={16}>
            <S.DatePickerWrapper>
              <DatePickerInput
                id="calendar_event_modal_end_date"
                label="calendar_event_modal_end_date"
                calendarStartDay={DateInputStartDates.Monday}
                selectedDate={endDate}
                onChange={setEndDate}
                hasError={datesHaveError}
                errorMessage={
                  datesHaveError &&
                  "calendar_modal_event_dates_validation_error"
                }
              />
            </S.DatePickerWrapper>
            {showTimePicker && (
              <S.TimePickerWrapper>
                <TimePicker
                  id="calendar_event_modal_end_time"
                  label="calendar_event_modal_end_time"
                  hasSuggestion
                  suggestionAlignedLeft={getBreakpoint() === "smartphone"}
                  startTime={
                    hasSameDay({ start: startDate, end: endDate })
                      ? startTime
                      : null
                  }
                  defaultValue={endTime}
                  handleInputChange={setEndTime}
                  hasError={datesHaveError}
                />
              </S.TimePickerWrapper>
            )}
          </S.FlexRow>
        ) : null}
      </Box>
      {!editMode ? (
        <S.LabelContainer>
          <Text type="formSubtitle">
            {translate("calendar_event_modal_recurrence_label")}
          </Text>
        </S.LabelContainer>
      ) : null}
      <S.AllDayContainer>
        {!editMode ? (
          <S.DropdownWrapper>
            <Dropdown
              id="calendar_event_modal_recurrence"
              theme={whiteTheme}
              optionsList={recurrenceOptions}
              setSelectedOptionId={setRecurrence}
              selectedOptionId={recurrence}
              placeholder={translate(
                "calendar_event_modal_recurrence_placeholder"
              )}
              fromTop
            />
            <EventRecurrenceInfo visible={isLayoutModeMobile} />
          </S.DropdownWrapper>
        ) : null}
        {eventType === EventCategory.Activity ? (
          <S.CheckboxContainer>
            <Checkbox
              theme={greyTheme}
              id="checkbox-all-day"
              name="allDay"
              onChange={() => setShowTimePicker(!showTimePicker)}
              ref={register()}
              label={translate("calendar_event_modal_all_day")}
              checked={!showTimePicker}
            />
          </S.CheckboxContainer>
        ) : null}
      </S.AllDayContainer>
      <EventRecurrenceInfo visible={!editMode && !isLayoutModeMobile} />
    </>
  );
};

export default observer(CalendarEventModalDates);
