import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  border: ${({ theme }) => theme.eventcard.border};
  background-color: ${({ theme }) => theme.eventcard.background};
  width: 100%;
  min-height: 90px;
  flex-direction: column;

  border-radius: 6px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.eventcard.text.color};

  font-family: "WeSchoolSans" !important;

  & > * {
    font-family: "WeSchoolSans" !important;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    height: 86px;
    padding: 13px 26px;
  }
`;

export const CardHeader = styled.div`
  position: relative;
  padding: 15px 0 15px 0px;
  width: 100%;
  display: flex;
  min-height: 90px;
  width: 94%;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}

  @media screen and (min-width: 768px) {
    width: 100%;
  }
`;

export const EventDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 6px;
`;

export const EventData = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  margin-left: 14px;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  & > div:nth-child(1) > span:nth-child(2),
  & > div:nth-child(3) > span {
    color: ${({ theme }) => theme.eventcard.secondText.color};
  }
  & > a {
    color: ${({ theme }) => theme.eventcard.text.color};
  }
  & > div:nth-child(3) > svg {
    color: ${({ theme }) => theme.eventcard.colorIcon};
  }
`;

export const VerticalCenteredRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const CompleteDateRange = styled.span`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > span {
    white-space: nowrap;
  }
`;

export const HourRange = styled.span`
  padding-left: 8px;
`;

export const EventTitle = styled.a`
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${({ accordionMode }) => (accordionMode ? "40px" : "20px")};
  & > span {
    white-space: nowrap;
  }
`;

export const ChevronContainer = styled.span`
  position: absolute;
  top: 35px;
  right: 15px;
  cursor: pointer;
`;

export const CardBody = styled.div`
  position: relative;
  padding: 5px 20px 20px;
  width: 100%;
`;

export const LineSeparator = styled.div`
  border-top: 1px solid #d6d6d6;
  width: 100%;
`;
