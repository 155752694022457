import {
  Button,
  ContextualAlert,
  CtaTertiary,
  Dropdown,
  Heading,
  Input
} from "@arcadia/design-system";
import React, { useCallback, useState } from "react";
import { LogoutModal } from "src/js/components/modal/LogoutModal";
import { useMutation, useStores } from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { useAccountChangeHandler } from "src/js/pages/user/useAccountChangeHandler";
import {
  changeLocale,
  rejectEmailChange,
  resendEmailChange
} from "src/js/repository/userRepository";
import { __LOCALE_LANGUAGE__ } from "src/js/settings/settingsSelect";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation/translated.types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { HubSpotUpdateUser } from "src/js/repository/hubspotRepository";
import UserAccountChangePasswordModal from "../../UserAccountChangePasswordModal/UserAccountChangePasswordModal";
import { UserAccountDeleteModal } from "../../UserAccountDeleteModal";
import { __FORM_EMAIL_EDIT_SETTINGS__ } from "../../utils";
import { LogoutMobile } from "../LogoutMobile";
import { UserAccountChangeEmailModal } from "../UserAccountChangeEmailModal";
import { UserAccountChangeNameModal } from "../UserAccountChangeNameModal";
import { UserSettingsRouteOption } from "../types";
import * as S from "./AccountSettings.styles";
import { MetaMaskWalletConnection } from "../MetaMaskWalletConnection";

const AccountSettings = () => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UserStore: {
      activeUser,
      changeActiveUserLanguage,
      fetchUser,
      activeUserFullname,
      isTraceable
    },
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate, setLocale } = useTranslation();
  const [lang, setLang] = useState(activeUser.language);
  const [langOptions] = useState(
    __LOCALE_LANGUAGE__.map(({ id, name }) => ({
      id,
      label: translate({ text: name })
    }))
  );
  const [isModalSheetOpen, setOpen] = useState(false);
  const isSSOAccount = activeUser.isSingleSignOnAccount;
  const userEmail = activeUser.email;
  const {
    changePasswordHandler,
    deleteUserAccountHandler,
    emailChangeHandler
  } = useAccountChangeHandler();

  const { mutate: updateLanguage, isLoading: isUpdateLanguageLoading } =
    useMutation(
      async ({ language }: { language: string }) => {
        await setLocale(language);
        await changeLocale(language);
        changeActiveUserLanguage(language);
      },
      {
        onSuccess({ language }) {
          showToastSuccess({
            str: translate("profile_update_success")
          });
          HubSpotUpdateUser({ ...activeUser, language });
        },
        onError(error) {
          showToastError({
            str: translate(extractErrorMessage(error, "error_change_locale"))
          });
        }
      }
    );

  const resendEmailConfirmationHandler = useCallback(() => {
    resendEmailChange()
      .then(() =>
        showToastSuccess({
          str: translate("change_email_confirmation_sent")
        })
      )
      .catch(err =>
        showToastError({
          str: translate(err as TranslationKeys)
        })
      );
  }, []);

  const rejectEmailConfirmationHandler = useCallback(() => {
    rejectEmailChange()
      .then(() => {
        showToastSuccess({
          str: translate("change_email_rejected")
        });
        fetchUser();
      })
      .catch(err =>
        showToastError({
          str: translate(err as TranslationKeys)
        })
      );
  }, []);

  const openSettingsRoute = ({
    routeOption,
    ModalComponent
  }: {
    routeOption: UserSettingsRouteOption;
    ModalComponent: React.ReactElement;
  }) => {
    return isLayoutModeMobile
      ? navigateTo(
          createUrl("user_mobile_settings", {
            option: routeOption
          })
        )
      : openModal(() => ModalComponent);
  };

  const onOpenChangeName = useCallback(() => {
    openSettingsRoute({
      routeOption: "name",
      ModalComponent: <UserAccountChangeNameModal />
    });
  }, []);

  const onOpenChangeEmail = useCallback(() => {
    openSettingsRoute({
      routeOption: "email",
      ModalComponent: (
        <UserAccountChangeEmailModal
          settings={__FORM_EMAIL_EDIT_SETTINGS__}
          confirm={(email: string, password: string) =>
            emailChangeHandler(email, password)
          }
        />
      )
    });
  }, []);

  const onOpenChangePassword = useCallback(() => {
    openSettingsRoute({
      routeOption: "password",
      ModalComponent: (
        <UserAccountChangePasswordModal
          handleSubmission={changePasswordHandler}
          displayAsModal
        />
      )
    });
  }, []);

  const onClickDeleteAccount = useCallback(() => {
    openSettingsRoute({
      routeOption: "delete",
      ModalComponent: (
        <UserAccountDeleteModal
          closeModal={() => closeModal()}
          successCallback={deleteUserAccountHandler}
          isSSOAccount={isSSOAccount}
          userEmail={userEmail}
        />
      )
    });
  }, []);

  const onClickSave = useCallback(async () => {
    updateLanguage({ language: lang });
  }, [lang]);

  const onClickLogout = useCallback(() => {
    if (isLayoutModeMobile) {
      setOpen(true);
    } else {
      openModal(() => <LogoutModal closeModal={() => closeModal()} />);
    }
  }, []);

  return (
    <>
      <Heading level="4">{translate("settings_account_header")}</Heading>
      <S.AccountContainer>
        <S.AccountInputWrapper>
          <Input
            theme={greyTheme}
            value={activeUserFullname}
            label={translate("nome")}
            disabled
            rounded
          />
        </S.AccountInputWrapper>
        <S.AccountButtonWrapper>
          <Button
            theme={whiteTheme}
            variant="secondary"
            onClick={onOpenChangeName}
          >
            {translate("edit")}
          </Button>
        </S.AccountButtonWrapper>
        <S.AccountInputWrapper>
          <Input
            theme={greyTheme}
            value={activeUser.email}
            label={translate("email")}
            disabled
            rounded
          />
        </S.AccountInputWrapper>
        {isSSOAccount ? (
          <S.FullWidthContextualAlertWrapper>
            <ContextualAlert
              theme={greyTheme}
              text={translate({ text: "sso_email_alert_message" })}
            />
          </S.FullWidthContextualAlertWrapper>
        ) : (
          <>
            {activeUser.pendingMail ? (
              <S.FullWidthContextualAlertWrapper>
                <ContextualAlert
                  theme={whiteTheme}
                  text={translate({
                    text: "edit_profile_confirm_mail_alert",
                    stringVariables: { email: activeUser.pendingMail }
                  })}
                />
                <S.PendingEmailButtonWrapper>
                  <CtaTertiary
                    theme={greyTheme}
                    onClick={resendEmailConfirmationHandler}
                  >
                    {translate("mail_not_received_send_again")}
                  </CtaTertiary>

                  <CtaTertiary
                    theme={greyTheme}
                    onClick={rejectEmailConfirmationHandler}
                  >
                    {translate("edit_mail_cancel")}
                  </CtaTertiary>
                </S.PendingEmailButtonWrapper>
              </S.FullWidthContextualAlertWrapper>
            ) : (
              <S.AccountButtonWrapper>
                <Button
                  theme={whiteTheme}
                  variant="secondary"
                  onClick={onOpenChangeEmail}
                >
                  {translate("edit")}
                </Button>
              </S.AccountButtonWrapper>
            )}

            <S.AccountInputWrapper>
              <Input
                theme={greyTheme}
                value="Ehi!NonSiFa"
                label={translate("password")}
                disabled
                rounded
                isPassword
              />
            </S.AccountInputWrapper>
            <S.AccountButtonWrapper>
              <Button
                theme={whiteTheme}
                variant="secondary"
                onClick={onOpenChangePassword}
              >
                {translate("edit")}
              </Button>
            </S.AccountButtonWrapper>
          </>
        )}

        <S.AccountInputWrapper>
          <S.LanguageLabel>{translate("language_label")}</S.LanguageLabel>
          <Dropdown
            id="language"
            theme={greyTheme}
            optionsList={langOptions}
            setSelectedOptionId={val => {
              setLang(val);
            }}
            selectedOptionId={lang}
          />
        </S.AccountInputWrapper>
        <S.PrivacyPolicyText type="cta">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={translate("user_profile_privacy_policy_link")}
          >
            {translate("show_privacy_link")}
          </a>
          {isTraceable && (
            <S.LinkBtn
              onClick={() => {
                window?.Cookiebot?.renew();
              }}
            >
              {translate("show_cookie_link")}
            </S.LinkBtn>
          )}
        </S.PrivacyPolicyText>
        <S.SaveButtonWrapper>
          <Button
            onClick={onClickSave}
            theme={greyTheme}
            variant="primary"
            disabled={isUpdateLanguageLoading}
          >
            {translate("save_edits")}
          </Button>
        </S.SaveButtonWrapper>
        <MetaMaskWalletConnection />
        <Heading level="6">{translate("sign_out")}</Heading>
        <S.LogoutButtonWrapper>
          <Button
            onClick={onClickLogout}
            theme={greyTheme}
            withIcon={{ icon: "logout", positionIcon: "left" }}
            variant="secondary"
          >
            {translate("disconnect")}
          </Button>
        </S.LogoutButtonWrapper>
        <Heading level="6">{translate("delete_account_section_title")}</Heading>
        <S.DeleteAccountButtonWrapper>
          <Button
            onClick={onClickDeleteAccount}
            theme={greyTheme}
            variant="danger"
          >
            {translate("delete_account_button")}
          </Button>
        </S.DeleteAccountButtonWrapper>
      </S.AccountContainer>
      {isModalSheetOpen && (
        <LogoutMobile isOpen={isModalSheetOpen} setOpen={setOpen} />
      )}
    </>
  );
};

export default AccountSettings;
