import { ActionButton } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTheme } from "styled-components";
import * as S from "./TopHeaderMobile.styles";
import { TopHeaderMobileBoardActionsProps } from "./types";

const TopHeaderMobileBoardActions: FC<TopHeaderMobileBoardActionsProps> = ({
  setDrawerOpen
}) => {
  const { whiteTheme } = useTheme();
  return (
    <S.BoardActionsContainer>
      <ActionButton
        theme={whiteTheme}
        variant="secondary"
        onClick={() => setDrawerOpen(true)}
        icon="kebabVertical"
      />
    </S.BoardActionsContainer>
  );
};

export default TopHeaderMobileBoardActions;
