import styled from "styled-components";

export const TrialLogo = styled.img`
  width: 60px;
  height: 60px;
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
`;

export const DurationBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 4px auto auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: 74px;
  min-width: 74px;
  height: 74px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.violet[501]};
`;
