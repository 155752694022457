import { Text } from "@arcadia/design-system";
import { DotsMenuButton } from "src/js/components/DotsMenu/DotsMenu.styles";
import styled, { css } from "styled-components";

export const iconButtonMixin = () => css`
  all: unset;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[900]};
    }
  }
  @media (hover: none) {
    &:active {
      background-color: ${({ theme }) => theme.colors.grey[900]};
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  border-style: hidden;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.violet[900]};
`;

export const TableBody = styled.tbody``;

export const TableBodyItem = styled.td`
  padding: 16px;
  ${DotsMenuButton} {
    ${iconButtonMixin()}
  }
`;

export const TableText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[200]};
`;

export const TableBodyItemProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  @media (min-width: 768px) {
    &:hover {
      ${TableText} {
        text-decoration: underline;
      }
    }
  }
`;

export const AdminText = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const RoleLabelWrapper = styled.div`
  padding: 4px 12px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.yellow[500]};
  width: max-content;
`;

export const RoleLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const TableHead = styled.thead``;

export const TableHeadItem = styled.th`
  text-transform: uppercase;
  padding: 16px;
  color: ${({ theme }) => theme.colors.grey[300]};
  font-weight: 600;
`;

export const IconButton = styled.button`
  ${iconButtonMixin()}
`;
