import styled from "styled-components";

export const CircleGutter = styled.circle`
  stroke: ${({ theme, gutterColor }) => theme.colors[gutterColor]};
  fill: none;
`;

export const CircleProgress = styled.circle`
  stroke: ${({ theme }) => theme.primaryColor[500]};
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const InternalCircle = styled.circle`
  fill: ${({ theme, internalCircleColor }) =>
    theme.colors[internalCircleColor]};
`;

export const PercentageText = styled.text`
  fill: ${({ theme, textColor }) => theme.colors[textColor]};
  font-size: ${({ textSize }) => textSize}px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily.title};
`;
