import React, { FC, forwardRef } from "react";
import { ThemeProvider } from "styled-components";
import { AvatarWithInitials } from "@ds_universal/AvatarWithInitials";
import { Icon } from "@ds_universal/Icon";
import { Loader } from "@ds_universal/Loader";
import { Text } from "@ds_universal/Text";

import * as S from "./CardLesson.styles";
import { CardLessonProps } from "./types";

const NotificationSection: FC<Pick<CardLessonProps, "notificationsNumber">> = ({
  notificationsNumber
}) => {
  if (notificationsNumber === 0) return null;

  if (notificationsNumber === -1) {
    return (
      <div>
        <Icon icon="notificationMuted" width="14" height="14" />
      </div>
    );
  }

  return (
    <div>
      <Icon icon="notificationUnmuted" width="16" height="16" />
      <Text type="formSubtitle">{notificationsNumber}</Text>
    </div>
  );
};

const CardLesson: FC<CardLessonProps> = forwardRef<
  HTMLDivElement,
  CardLessonProps
>(
  (
    {
      theme,
      title,
      notificationsNumber = 0,
      testsNumber = 0,
      subscribersNumber = 0,
      imgUrl = "",
      altText = "",
      onOptionsClick,
      onClick,
      naked,
      maxWidth = "100%",
      hideOptions,
      isPremium,
      isLoading,
      space: {
        badge: spaceBadge,
        name: spaceName = "",
        defaultBadgeColor = 1
      } = {},
      ...rest
    },
    ref
  ) => {
    const ariaLabelSubscribers = !naked
      ? `with ${subscribersNumber} users`
      : "";
    const ariaLabel = `${title} ${
      isLoading ? "loading" : ariaLabelSubscribers
    }`;
    return (
      <ThemeProvider theme={theme}>
        <S.CardLesson
          onClick={onClick}
          aria-label={ariaLabel}
          naked={naked}
          maxWidth={maxWidth}
          isLoading={isLoading}
          {...rest}
          ref={ref}
        >
          <S.TopWrapper hasBadge={!!spaceName}>
            {spaceName ? (
              <S.SpaceBadge>
                <AvatarWithInitials
                  url={spaceBadge}
                  theme={theme}
                  size="small"
                  alt={spaceName}
                  avatarColor={defaultBadgeColor}
                  name={spaceName}
                />
              </S.SpaceBadge>
            ) : null}

            {!naked && !hideOptions ? (
              <S.Option
                onClick={e => {
                  e.stopPropagation();
                  onOptionsClick?.(e);
                }}
                tabIndex={0}
                aria-label="option"
              >
                <Icon icon="kebab" width="20" height="20" />
              </S.Option>
            ) : null}
          </S.TopWrapper>

          <S.Cover>
            <img src={imgUrl} alt={altText} />
          </S.Cover>

          <S.WrapperText maxWidth={maxWidth}>
            {spaceName ? (
              <S.SpaceTitle type="label">{spaceName}</S.SpaceTitle>
            ) : null}
            <S.WrapperTitle>
              {isLoading ? (
                <Loader
                  loaderType="CircularLoader"
                  theme={theme}
                  loaderWidth="24px"
                  loaderHeight="24px"
                  width="24px"
                  height="24px"
                  mainColor={theme.card.cardlesson.loading.color}
                  borderColor={theme.card.cardlesson.loading.color}
                />
              ) : null}
              <Text type="subHeaderTitle">{title}</Text>
            </S.WrapperTitle>
            {!naked ? (
              <S.ContainerIcon>
                {isPremium ? (
                  <S.Cart>
                    <Icon icon="cart" width="14" height="14" />
                  </S.Cart>
                ) : null}
                <NotificationSection
                  notificationsNumber={notificationsNumber}
                />
                {testsNumber > 0 ? (
                  <div>
                    <Icon icon="testFlask" width="14" height="14" />
                    <Text type="formSubtitle">{testsNumber}</Text>
                  </div>
                ) : null}
                <div>
                  <Icon icon="user" width="14" height="14" />
                  <Text type="formSubtitle">{subscribersNumber}</Text>
                </div>
              </S.ContainerIcon>
            ) : null}
          </S.WrapperText>
        </S.CardLesson>
      </ThemeProvider>
    );
  }
);

export default CardLesson;
