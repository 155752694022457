import { BadgeRelatedUserType, ObjectValues } from "src/js/types";

export const EmitBadgeToStudentsStep = {
  SelectStudents: "select-students",
  ReviewSelectedStudents: "review-selected-students",
  Loading: "loading"
} as const;

export type EmitBadgeToStudentsStepType = ObjectValues<
  typeof EmitBadgeToStudentsStep
>;

export type BadgeRelatedUsersFetchParams = {
  badgeId: string;
  textSearch: string;
  showUnassociated: boolean;
  groupIds: string[];
};

export type SelectUsersTypes = {
  filterString: string;
  setFilterString: (value: string) => void;
  filterGroupString: string;
  setFilterGroupString: (value: string) => void;
  badgeRelatedUsers: BadgeRelatedUserType[];
  fetchNextPage: (args?: BadgeRelatedUsersFetchParams) => void;
  filterValueDebounced: string;
  hasMoreResults: boolean;
  setStep: (step: EmitBadgeToStudentsStepType) => void;
};
