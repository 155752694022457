import styled from "styled-components";

export const PillContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 15px 0 10px;
  border-radius: 15px;
  height: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme }) => theme.colors.darkestGray};
  font-family: ${({ theme }) => theme.fontFamily.title};
  line-height: 20px;
  font-size: 11px;
  font-weight: 500;
  margin: 5px;
`;

export const IconContainer = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;
