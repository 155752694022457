import React from "react";
import { captureException } from "@sentry/browser";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import ErrorBoundaryView from "src/js/components/global/ErrorBoundaryView";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    // eslint-disable-next-line react/no-unused-state
    this.setState({ hasError: true, error, info });
    // You can also log the error to an error reporting service
    captureException(error, { extra: info });
    _trackEvent("Error", "GeneralErrorTriggered");
  }

  render() {
    const { children } = this.props;
    const { hasError, error, info } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryView
          error={error.toString()}
          info={info.componentStack}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
