import styled, { css } from "styled-components";
import * as ToggleBaseS from "../EditBoardToggleElement/EditBoardToggleElement.styles";

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Layout = styled(ToggleBaseS.Layout)`
  cursor: pointer;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      padding: 24px 6px 0 6px;
    `};
`;

export const ChevronWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Content = styled.div`
  max-height: 0px;
  overflow: hidden;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      padding-top: 24px;
      padding-bottom: 16px;
      overflow: unset;
      max-height: unset;
    `};
`;

export const SelectedItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 0;
  padding: 15px 0 0;

  & > li {
    margin-bottom: 10px;
  }
`;

export const DraftWarning = styled.div`
  display: flex;
`;
