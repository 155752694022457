import React, { useEffect } from "react";
import { AvatarWithInitials, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { AnimatePresence } from "framer-motion";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import StackEffect from "./components/StackEffect";
import ToolbarSpaceList from "./components/ToolbarSpaceList";
import * as S from "./ToolbarActiveSpace.styles";

const ToolbarActiveSpace = () => {
  const { isOpen, ref, setIsOpen } = useCloseOnClickOutside(false);
  const {
    SpaceStore: { activeSpace, spacesList }
  } = useStores();
  const { whiteTheme } = useTheme();

  useEffect(() => {
    if (activeSpace) {
      setIsOpen(false);
    }
  }, [activeSpace]);

  const otherSpacesHaveNotifications = spacesList.some(
    space =>
      activeSpace &&
      space.unreadNotificationCount > 0 &&
      space.space.id !== activeSpace.space.id
  );

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <S.AvatarWrapper>
        {activeSpace ? (
          <StackEffect shouldAnimate={!isOpen}>
            <span
              onClick={() => setIsOpen(!isOpen)}
              id={UsetifulId.ToolbarActiveSpaceDropdownButton}
            >
              <AvatarWithInitials
                name={activeSpace.space.name}
                theme={whiteTheme}
                size="auto"
                url={activeSpace.space.badge?.small as string}
                alt={activeSpace.space.name}
                avatarColor={activeSpace.space.defaultBadgeColor}
              />
            </span>
            {otherSpacesHaveNotifications && <S.UnreadNotificationDot />}
          </StackEffect>
        ) : (
          <S.PlusButton
            onClick={() => setIsOpen(!isOpen)}
            id={UsetifulId.ToolbarActiveSpaceDropdownButton}
          >
            <Icon icon="plusThin" />
          </S.PlusButton>
        )}
      </S.AvatarWrapper>
      <AnimatePresence>
        {isOpen && (
          <S.SpaceListWrapper
            key="space-list-drawer"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ToolbarSpaceList />
          </S.SpaceListWrapper>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ToolbarActiveSpace;
