import { useReducer } from "react";

const toggleReducer = (state: boolean, nextValue: boolean) =>
  typeof nextValue === "boolean" ? nextValue : !state;

/**
 * @name useToggle
 *
 * @description
 * Toggle a boolean state with a reducer.
 *
 * @param {boolean} initialValue
 *
 * @example
 *
 * const [on, toggle] = useToggle(false)
 * <div onClick={() => { toggle(!on) }}>click me</div>
 */
const useToggle = (initialValue: boolean) => {
  return useReducer(toggleReducer, initialValue);
};

export default useToggle;
