import colors from "@ds_themes/colors";

export const checkindicator = {
  background: colors.coldGrey[900],
  border: `1px solid ${colors.grey[800]}`,
  color: colors.coldGrey[900],
  selected: {
    border: `1px solid ${colors.violet[501]}`,
    background: colors.violet[501],
    color: colors.grey[1000]
  }
};
