import styled, { css } from "styled-components";
import { Container } from "react-awesome-styled-grid";

export const LayoutContainer = styled(Container)`
  /* padding-top: ${({ paddingTop }) => paddingTop}; */

  /* ${({ noHeaderWhite }) =>
    noHeaderWhite &&
    css`
      padding-top: ${({ theme }) => theme.sizes.defaultHeaderHeight};
    `}

  @media (max-width: 767px) {
    padding-top: ${({ paddingTopMobile }) => paddingTopMobile};
  } */
  padding: 24px 16px;
  @media (max-width: 767px) {
    padding: 0px 12px;
  }
`;
