import styled from "styled-components";

export const CtaTertiary = styled.button`
  border: 0px;
  display: inline-block;
  background-color: ${({ theme }) => theme.ctaTertiary.background};
  color: ${({ theme }) => theme.ctaTertiary.text};
  &:hover {
    cursor: pointer;
  }
  &:disabled,
  &:disabled svg {
    color: ${({ theme }) => theme.ctaTertiary.disabled.text};
    cursor: not-allowed;
  }
  &:disabled:hover::after {
    width: 0px;
  }

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #000;
    transition: width 0.3s;
  }
  &:hover::after {
    width: 100%;
  }
  svg {
    vertical-align: middle;
    padding-left: 10px;
  }
`;
