import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";

import * as S from "./CardActivity.styles";
import { CardActivityProps } from "./CardActivity.types";

const CardActivity: FC<CardActivityProps> = ({
  theme,
  title,
  body,
  onClick,
  type = "live",
  size = "standard",
  rightIcon
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.CardActivity size={size} onClick={onClick}>
        <S.Content>
          {size === "standard" && (
            <Icon
              initialViewbox
              icon={`illustration-${type}`}
              width="0"
              height="70"
            />
          )}
          <S.Wrapper>
            <S.Title type="formField">{title}</S.Title>
            <S.Subtitle type="formSubtitle">{body}</S.Subtitle>
          </S.Wrapper>
        </S.Content>
        {rightIcon && (
          <S.RightIcon icon={rightIcon}>
            <Icon icon={rightIcon} color={theme.card.cardactivity.border} />
          </S.RightIcon>
        )}
      </S.CardActivity>
    </ThemeProvider>
  );
};

export default CardActivity;
