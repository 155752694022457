import styled, { css } from "styled-components";

export const MainBoxLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 6px;
  padding: 15px;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ isSelectable }) =>
    isSelectable &&
    css`
      cursor: pointer;
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.primaryColor[500]};
    `}
`;
