import React, { useEffect, useState, FC } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useDebounce, useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import { LoginForm, LoginPageProps } from "./LoginPage.types";
import { Background } from "../components/Background";
import { useLogin } from "./useLogin";
import { LoginDesktopView } from "./components/LoginDesktopView";
import { LoginMobileView } from "./components/LoginMobileView";

const LoginPage: FC<LoginPageProps> = ({ email, provider, tenantId }) => {
  const [shouldDisplayPasswordField, setPasswordFieldVisible] = useState(false);
  const { UIStore } = useStores();
  const { onLogin, getUserSSOProvider, setLoginErrors, loginError } =
    useLogin();
  const loginForm = useForm<LoginForm>({ mode: "onChange" });
  const { watch } = loginForm;
  const userEmail = watch("username");
  const userEmailDebounced = useDebounce(userEmail, 1000);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (userEmailDebounced) {
      setLoginErrors(null);
      getUserSSOProvider(userEmailDebounced)
        .then(response => {
          // Case that email is signed up through SSO (Microsoft/Google/Apple)
          if (response?.providerCode) {
            // Firstly we hide the password field
            setPasswordFieldVisible(false);
            // Secondly we redirect user to Continue with SSO page (src/js/pages/unlogged/LoginSSOPage).
            const url = `${createUrl(
              "login_sso"
            )}?email=${userEmailDebounced}&provider=${
              response.providerCode
            }&tenantId=${response.tenantId}`;
            navigateTo(url);
          }
        })
        // Case that email is not signed up through SSO & api will return 404. User will be prompt to enter his password.
        .catch(() => setPasswordFieldVisible(true));
    }
  }, [userEmailDebounced]);

  return (
    <Background mobileHasImage>
      {UIStore.isLayoutModeMobile ? (
        <LoginMobileView
          form={loginForm}
          onLogin={onLogin}
          shouldDisplayPasswordField={shouldDisplayPasswordField}
          ssoData={{ email, provider, tenantId }}
          loginError={loginError}
          inviteToken={searchParams.get("inviteToken")}
        />
      ) : (
        <LoginDesktopView
          form={loginForm}
          onLogin={onLogin}
          shouldDisplayPasswordField={shouldDisplayPasswordField}
          ssoData={{ email, provider, tenantId }}
          loginError={loginError}
          inviteToken={searchParams.get("inviteToken")}
        />
      )}
    </Background>
  );
};

export default LoginPage;
