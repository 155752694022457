import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";

export const startGroupRealtimeEvents = ({
  group,
  roles,
  BoardsStore,
  CalendarStore,
  updateCalendarEvents
}) => {
  RealTimeDispatcher.on("calendar_event_created", data => {
    updateCalendarEvents();
    CalendarStore.realtimeAddCalendarEvent({
      eventDetail: data
    });
  });
  RealTimeDispatcher.on("calendar_event_edited", () => {
    updateCalendarEvents();
    CalendarStore.realtimeEditCalendarEvent();
  });
  RealTimeDispatcher.on("calendar_event_deleted", data => {
    updateCalendarEvents();
    CalendarStore.realtimeRemoveCalendarEvent({
      calendarEventId: data?.calendar_event_id
    });
  });
  RealTimeDispatcher.on("board_created", data => {
    BoardsStore.realtimeAddBoard({
      board: data,
      group,
      roles
    });
  });
  RealTimeDispatcher.on("do_board_edit", data => {
    BoardsStore.realtimeBoardEdit({
      board: data?.target?.entity
    });
  });
  RealTimeDispatcher.on("do_board_publish", data => {
    BoardsStore.realtimePublishBoard({
      board: data?.target?.entity
    });
  });
  RealTimeDispatcher.on("do_board_unpublish", data => {
    BoardsStore.realtimeUnpublishBoard({
      board: data?.target?.entity,
      group,
      roles
    });
  });
  RealTimeDispatcher.on("board_deleted", data => {
    BoardsStore.realtimeDeleteBoard({
      boardId: data?.board_id,
      groupId: group?.id
    });
  });
  RealTimeDispatcher.on("do_board_completed", data => {
    BoardsStore.realtimeBoardCompleted({
      boardId: data?.target?.entity?.id,
      groupId: data?.target?.entity?.group?.id
    });
  });
};

export const stopGroupRealtimeEvents = () => {
  RealTimeDispatcher.off("calendar_event_created");
  RealTimeDispatcher.off("calendar_event_edited");
  RealTimeDispatcher.off("calendar_event_deleted");
  RealTimeDispatcher.off("board_created");
  RealTimeDispatcher.off("do_board_edit");
  RealTimeDispatcher.off("do_board_publish");
  RealTimeDispatcher.off("do_board_unpublish");
  RealTimeDispatcher.off("board_deleted");
  RealTimeDispatcher.off("do_board_completed");
};
