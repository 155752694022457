import React, { useEffect } from "react";
import createUrl from "src/js/modules/routing";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import { confirmRegistration } from "src/js/repository/authenticationRepository";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { navigateTo } from "Legacy/modules/history";
import { useStores } from "src/js/hooks";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { EventDomain, UserEvent } from "src/js/types";

const ConfirmRegistration = props => {
  const { match } = props;
  const { AuthStore } = useStores();
  const { greyTheme } = useTheme();

  useEffect(() => {
    confirmRegistration(match.params.token)
      .then(() => {
        showToastSuccess({
          str: <Translate text="la tua registrazione è stata confermata" />
        });
        _trackEvent(EventDomain.User, UserEvent.UserActivation);
        navigateTo(createUrl("home"));
        AuthStore.resetUser(() => {
          navigateTo(createUrl("home"));
        });
      })
      .catch(error => {
        showToastError({
          str: (
            <Translate
              text={extractErrorMessage(
                error.responseJSON,
                "Attenzione, si è verificato un errore"
              )}
            />
          )
        });
        navigateTo(createUrl("login"));
      });
  }, [match.params.token]);

  return <DoodleLoader theme={greyTheme} />;
};

export default ConfirmRegistration;
