import React from "react";
import { Icon, Text, colors } from "@arcadia/design-system";

import { __MAINNET_CHAIN_ICONS__ } from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/const";
import * as S from "./styles";
import { SpaceAwardedBadgeImageProps } from "./types";

const SpaceAwardedBadgeImage = ({
  userAwardedNFTBadge,
  chainId,
  image
}: SpaceAwardedBadgeImageProps) => {
  const handleChainIconColumn = () => {
    if (userAwardedNFTBadge) {
      if (__MAINNET_CHAIN_ICONS__[chainId]) {
        return (
          <S.AwardedNFTIndicatorContainer>
            <Icon
              icon={__MAINNET_CHAIN_ICONS__[chainId]}
              width={12}
              height={12}
              initialViewbox
              color={colors.grey[1000]}
            />
          </S.AwardedNFTIndicatorContainer>
        );
      }
      return (
        <S.NFTLabelContainer>
          <Text type="label">NFT</Text>
        </S.NFTLabelContainer>
      );
    }
    return null;
  };

  return (
    <S.BadgeImageInReviewContainer>
      {handleChainIconColumn()}
      <S.BadgeImageInReview src={image} />
    </S.BadgeImageInReviewContainer>
  );
};

export default SpaceAwardedBadgeImage;
