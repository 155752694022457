export enum JoinDemoSpaceLocalStorageNames {
  SpaceGenerationState = "space_generation_state",
  PreviewDemoSpace = "preview_demo_space"
}

export type DemoSpaceLayoutProps = {
  isMobile: boolean;
  isTopBanner?: boolean;
  children?: React.ReactNode;
};

export type SpaceGenerationState = {
  value: string;
  expiresAt: number;
};
