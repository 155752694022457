import { observer } from "mobx-react";
import React, { useState } from "react";
import { RemoveFromGroupsModal } from "src/js/components/RemoveFromGroupsModal";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import { deleteSpaceUserGroups } from "src/js/repository/dashboardRepository";
import { Translate } from "src/js/translation/TranslationProvider";

const SpacesRemoveFromGroupsModal = ({ user }) => {
  const {
    SpacesUsersRolesStore,
    SpacesUsersFilterStore,
    ModalStore,
    SpaceStore
  } = useStores();
  const [groupsWithError, setGroupsWithError] = useState([]);
  const [removeFromGroupsLoading, setRemoveFromGroupsLoading] = useState(false);

  const removeFromGroups = ({ spaceId, userId }) => {
    setRemoveFromGroupsLoading(true);
    deleteSpaceUserGroups({ spaceId, userId })
      .then(({ results }) => {
        if (results?.length > 0) {
          setGroupsWithError(results);
          setRemoveFromGroupsLoading(false);
        } else {
          ModalStore.closeModal();
        }
      })
      .catch(() => {
        setRemoveFromGroupsLoading(false);
        showToastError({ str: <Translate text="error_fetch_chat_user" /> });
      })
      .finally(() => {
        SpacesUsersFilterStore.fetchUsers().catch(() => {
          showToastError({
            str: <Translate text="error_fetch_space_users" />
          });
        });
      });
  };

  return (
    <RemoveFromGroupsModal
      closeModal={() => ModalStore.closeModal()}
      userName={user.name}
      userSurname={user.surname}
      handleSubmit={() =>
        removeFromGroups({
          spaceId: SpaceStore?.activeSpaceId,
          userId: user.id
        })
      }
      groups={SpacesUsersRolesStore.userRoles}
      hasNext={SpacesUsersRolesStore.hasMore}
      showNext={() => SpacesUsersRolesStore.showNextUserRoles()}
      isLoading={removeFromGroupsLoading}
      groupsWithError={groupsWithError}
    />
  );
};

export default observer(SpacesRemoveFromGroupsModal);
