/* eslint-disable no-restricted-imports */
import React from "react";
import { useStores } from "src/js/hooks";
import { isEmptyObject } from "src/js/modules/commonFunction";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { saveResource } from "src/js/repository/resourceRepository";
import { doRouteBack } from "Legacy/modules/history";
import { getActiveGroupId } from "Legacy/modules/activeGroup";
import { AddTextResourceWithAI } from "src/js/components/ResourcePicker/components/AddTextResourceWithAI";

// context === "board" || "post"
const ResourceTextEditor = ({
  resource,
  addFunc,
  onClose,
  // populated only in post context
  elementId = null,
  context
}) => {
  const {
    SpaceStore: { activeSpaceSlug }
  } = useStores();

  const saveFunction = resourceToSave => {
    const data = {
      name: resourceToSave.name,
      textContent: resourceToSave.textContent,
      type: "TEXT",
      group_id: getActiveGroupId(),
      spaceSlug: activeSpaceSlug,
      copy_custom_preview_from: resourceToSave.id
    };

    saveResource(data)
      .then(res => {
        addFunc(res);
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
        if (
          !isEmptyObject(resourceToSave) ||
          typeof resourceToSave?.postContent !== "undefined"
        ) {
          doRouteBack();
        }
      });
  };

  return (
    <AddTextResourceWithAI
      elementId={elementId}
      defaultResource={resource}
      onClose={res => onClose(res)}
      context={context}
      editMode
      onSubmit={res => {
        saveFunction(res);
      }}
      setUploadingProgress={() => {}}
    />
  );
};

export default ResourceTextEditor;
