import {
  BottomModalSheet,
  Box,
  Icon,
  RadioGroupOption
} from "@arcadia/design-system";
import React, { useCallback, useEffect, useState } from "react";
import {
  getAppIconFromPreference,
  getEmailIconFromPreference
} from "src/js/pages/group/settings/GroupSettingsNotice/utils";
import { useTranslation } from "src/js/translation";
import {
  AppPreference,
  EmailPreference
} from "src/js/types/models/Notification";
import { useTheme } from "styled-components";
import { AppPreferenceOptions, EmailPreferenceOptions } from "../utils";
import MobileRadioList from "./MobileRadioList";

import * as S from "./MobilePreferencePicker.styles";

type Step = "base" | "notification" | "email";

type MobilePreferencePicker = {
  emailPreference: EmailPreference;
  appPreference: AppPreference;
  onSelectEmail: (newEmailPref: EmailPreference) => void;
  onSelectApp: (newAppPref: AppPreference) => void;
  disabled?: boolean;
};

const MobilePreferencePicker = ({
  emailPreference,
  appPreference,
  onSelectApp,
  onSelectEmail,
  disabled = false
}: MobilePreferencePicker) => {
  const { whiteTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<Step>("base");
  const { translate } = useTranslation();

  useEffect(() => {
    setStep("base");
  }, [isOpen]);

  let content = null;

  const closeSheet = useCallback(() => {
    setIsOpen(false);
  }, []);
  const openSheet = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onSelectAppPreference = useCallback(
    (newPref: AppPreference) => {
      onSelectApp(newPref);
      closeSheet();
    },
    [onSelectApp, closeSheet]
  );

  const onSelectEmailPreference = useCallback(
    (newPref: EmailPreference) => {
      onSelectEmail(newPref);
      closeSheet();
    },
    [onSelectEmail, closeSheet]
  );

  if (step === "base") {
    content = (
      <>
        <S.PreferenceButton
          onClick={() => {
            setStep("notification");
          }}
        >
          <Box display="flex" alignItems="center">
            <Icon
              icon={getAppIconFromPreference(appPreference)}
              height={24}
              width={24}
            />
            <S.Title type="formField">
              {translate(
                "user_notice_settings_mobile_sheet_notification_title"
              )}
            </S.Title>
          </Box>
          <Icon icon="arrowRight" width={20} height={20} />
        </S.PreferenceButton>
        <S.PreferenceButton
          onClick={() => {
            setStep("email");
          }}
        >
          <Box display="flex" alignItems="center">
            <Icon
              icon={getEmailIconFromPreference(emailPreference)}
              height={24}
              width={24}
            />
            <S.Title type="formField">
              {translate("user_notice_settings_mobile_sheet_email_title")}
            </S.Title>
          </Box>
          <Icon icon="arrowRight" width={20} height={20} />
        </S.PreferenceButton>
      </>
    );
  } else {
    content = (
      <MobileRadioList
        title={
          step === "email"
            ? translate("user_notice_settings_mobile_sheet_email_title")
            : translate("user_notice_settings_mobile_sheet_notification_title")
        }
        onBack={() => {
          setStep("base");
        }}
        elementList={
          step === "email"
            ? EmailPreferenceOptions.map(
                element =>
                  ({
                    label: translate(element.label),
                    value: element.id
                  }) as RadioGroupOption
              )
            : AppPreferenceOptions.map(
                element =>
                  ({
                    label: translate(element.label),
                    value: element.id
                  }) as RadioGroupOption
              )
        }
        selectedElement={step === "email" ? emailPreference : appPreference}
        onChange={
          step === "email" ? onSelectEmailPreference : onSelectAppPreference
        }
      />
    );
  }

  return (
    <>
      <S.StyledButton
        theme={whiteTheme}
        variant="tertiary"
        icon="kebabVertical"
        onClick={openSheet}
        disabled={disabled}
      />

      <BottomModalSheet
        isOpen={isOpen}
        onClose={closeSheet}
        title="notificationOptions"
        withHeaderLabels={false}
        withAdaptiveHeight
      >
        <S.Body>{content}</S.Body>
      </BottomModalSheet>
    </>
  );
};

export default MobilePreferencePicker;
