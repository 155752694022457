import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import isEmpty from "lodash/isEmpty";
import { Translate } from "src/js/translation/TranslationProvider";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { ReportStudentDocumentAnswerForm } from "src/js/components/exercises/ReportStudentDocumentAnswerForm";
import { ReportTeacherCorrectForm } from "src/js/components/exercises/ReportTeacherCorrectForm";
import { ReportSimpleFooter } from "src/js/components/exercises/ReportSimpleFooter";
import { QuizReportResource } from "src/js/components/exercises/QuizReportResource";
import { ReportThread } from "src/js/components/exercises/ReportThread";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { getActiveGroup } from "../../../modules/activeGroup";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";

const DocumentAnswerCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        execution: null,
        quiz_execution: null,
        answer: null,
        number: 0,
        thread: []
      };
    },
    getInitialState() {
      return {
        status: "",
        answer:
          this.props.execution.getQuizReviewCommentByExecId(
            this.props.quiz_execution.id
          ) || "",
        isEditing: false,
        shouldShowForm: !this.props.execution.getQuizReviewedByExecId(
          this.props.quiz_execution.id
        ),
        resubmissionAsked: this.props.quiz_execution.redo_execution
      };
    },
    handleChange(value) {
      this.setState({
        answer: value
      });
    },
    render() {
      const { thread, exercise } = this.props;
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const group = getActiveGroup();
      const quiz = tempQuizCollection.at(0);
      const { quiz_execution } = this.props;
      const { execution } = this.props;
      let resourceViewer = (
        <div className="margin-bottom-15">{__("no_resource_uploaded")}</div>
      );

      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));
      const resourceAnswer = execution.getQuizAnswerResourceById(
        quiz.get("id")
      );
      if (!isEmpty(resourceAnswer)) {
        resourceViewer = (
          <QuizReportResource
            resource={resourceAnswer}
            resourceId={resourceAnswer.id}
          />
        );
      }

      let userText = userAnswers[0] !== undefined ? userAnswers[0].value : "";

      if (userText == "") {
        userText = __("quiz_not_answered_label");
      }

      let resubmitForm = null;

      const pingPongArray = thread.filter(element => {
        return element?.system_message !== true;
      });

      const lastThreadElement =
        pingPongArray.length > 0 ? pingPongArray[pingPongArray.length - 1] : {};

      if (group.isTeacher()) {
        if (this.state.shouldShowForm) {
          resubmitForm = (
            <ReportTeacherCorrectForm
              exerciseId={execution.get("exercise_id")}
              executionId={execution.get("id")}
              quizExecutionId={quiz_execution.id}
              availablePoints={quiz.get("available_points")}
              onSaveSubmit={askResubmission => {
                this.setState({
                  shouldShowForm: false,
                  resubmissionAsked: askResubmission
                });
              }}
            />
          );
        } else {
          resubmitForm = (
            <ReportSimpleFooter
              defaultValue={this.state.resubmissionAsked}
              exerciseId={execution.get("exercise_id")}
              executionId={execution.get("id")}
              quizExecutionId={quiz_execution.id}
              onToggleChange={toggleStatus => {
                this.setState({
                  resubmissionAsked: toggleStatus
                });
              }}
            />
          );
        }
      } else {
        // Student
        if (quiz_execution.redo_execution && lastThreadElement?.is_teacher) {
          resubmitForm = (
            <ReportStudentDocumentAnswerForm
              exerciseId={execution.get("exercise_id")}
              executionId={execution.get("id")}
              quizExecutionId={quiz_execution.id}
              availablePoints={quiz.get("available_points")}
              onSaveSubmit={() => {
                this.setState({ shouldShowForm: false });
              }}
            />
          );
        }
      }

      let voteType;
      let statusLabel = (
        <>
          <strong>
            {quiz_execution.points}/{quiz.getAvailablePoints()}
          </strong>
          &nbsp;
          <Translate text="quiz_points_label" />
        </>
      );
      if (thread.length === 0) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_WARNING;
        statusLabel = <Translate text="quiz_status_need_review_label" />;
      } else if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon
                icon="pickertest-answerwithattachment"
                width="60"
                height="60"
              />
              <ColorLabel type={voteType}>{statusLabel}</ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  {quiz.getTitle() !== "" ? (
                    <ExerciseQuizTitle quizTitle={quiz.getTitle()} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="correction_answers-wrapper">
                <ReportThread
                  execution={execution.attributes}
                  thread={thread}
                  availablePoints={quiz.getAvailablePoints()}
                  executionMessage={userText}
                  resourceMessage={resourceAnswer}
                  exerciseId={exercise.id}
                  executionId={execution.get("id")}
                  quizExecutionId={quiz_execution.id}
                  editThreadMessage={isEditing => this.setState({ isEditing })}
                  onDeleteMessage={() => {
                    this.setState({
                      shouldShowForm: true
                    });
                  }}
                  onEditSubmit={askResubmission => {
                    this.setState({
                      shouldShowForm: false,
                      resubmissionAsked: askResubmission
                    });
                  }}
                />
                {this.state.isEditing ? null : resubmitForm}
              </div>
            </div>
          </Col>
        </>
      );
    }
  })
);

export default DocumentAnswerCorrectionDisplayer;
