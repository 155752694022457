import styled from "styled-components";
import { ResourcePreviewStyleProps } from "src/js/components/modules/ModuleResourceItem/components/ResourcePreview/ResourcePreview.types";

export const ResourcePreview = styled.div<ResourcePreviewStyleProps>`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ hasPreviewGenerated, background }) =>
    hasPreviewGenerated
      ? `linear-gradient(180deg,rgba(0, 0, 0, 0) 8%,rgba(0, 0, 0, 0.3) 65.32%,#333 90.93%), url(${background})`
      : `url(${background})`};
  background-size: cover;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResourceTypeIconPlaceholder = styled.div`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const BlockedResourceOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colors.grey[700]}AA`};
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 40px;
  gap: 12px;
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ResourceTypeDetails = styled.div<{
  isSuggestedResource?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ isSuggestedResource }) =>
    isSuggestedResource ? "flex-end" : "space-between"};
  position: absolute;
  bottom: 0px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ResourceTypeLabel = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const SuggestedResourceActionButtons = styled.div`
  position: absolute;
  display: none;
`;

export const ThumbnailIconContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.primaryColor[500]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 12px;

  @media screen and (max-width: 767px) {
    display: none !important;
  }
`;
