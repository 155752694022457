import styled from "styled-components";
import { motion } from "framer-motion";
import { ItemStyleProps } from "@ds_universal/layout/SidebarGroupItem/types";

export const NotificationStatus = styled(motion.div)<ItemStyleProps>`
  width: 20px;
  height: 20px;
  display: flex;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.sidebargroupitem.notificationstat.selected
      : theme.sidebargroupitem.notificationstat.color};

  align-items: center;
  justify-content: center;
`;

export const ItemContainer = styled.div<ItemStyleProps>`
  display: flex;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.sidebargroupitem.selected.color
      : theme.sidebargroupitem.color};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.sidebargroupitem.selected.background
      : theme.sidebargroupitem.background};
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  gap: 8px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, isSelected }) =>
      !isSelected && theme.sidebargroupitem.hover.background};
  }

  transition:
    color 300ms ease-in-out,
    background-color 300ms ease-in-out;
`;

export const ImageContainer = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
`;

export const Name = styled.div<{ isLoading?: boolean }>`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  opacity: ${({ isLoading }) => (isLoading ? 0.6 : 1)};

  > span {
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NotificationNumber = styled(motion.div)<ItemStyleProps>`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 9px;
  user-select: none;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.sidebargroupitem.notification.selectedColor
      : theme.sidebargroupitem.notification.color};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.sidebargroupitem.notification.selected
      : theme.sidebargroupitem.notification.background};
`;
