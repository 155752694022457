import { shadow } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const ModalContainer = styled.div`
  width: 342px;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  position: fixed;
  right: 20px;
  bottom: ${({ higherPosition }) => (higherPosition ? "132px" : "20px")};
  box-shadow: ${shadow[200]};

  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: ${({ theme }) => theme.fontSizes.base};
  background-color: ${({ theme }) => theme.colors.basicWhite};
  z-index: ${({ theme, higherPosition }) =>
    higherPosition
      ? theme.zIndex.forDrawer - 2
      : theme.zIndex.forPersistentModal};

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};

  @media (max-width: 767px) {
    bottom: 10px;
    right: 0px;
    width: calc(100% - 30px);
    margin: 0px 15px;
  }
`;

export const ModalCloseContainer = styled.div`
  display: flex;
  top: 13px;
  text-align: center;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 20px;
  border-radius: 0 8px 0 0;
  cursor: pointer;
`;

export const ModalCollapseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  text-align: center;
  position: absolute;
  right: 80px;
  border-radius: 0 8px 0 0;
  cursor: pointer;
`;

export const Divider = styled.div`
  height: 16px;
  width: 1.5px;
  position: absolute;
  right: 56px;
  top: 13px;
  background-color: ${({ theme }) => theme.colors.grey[800]};
`;

export const ModalTitleContainer = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ collapsed }) =>
    collapsed &&
    css`
      width: calc(100% - 60px);
    `};
`;

export const ModalBodyContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[800]};
  width: 100%;
  overflow: hidden;
  display: flex;
  transition: all 0.5s ease;
  ${({ heightDimension }) =>
    heightDimension &&
    css`
      max-height: ${heightDimension}px;
    `};
  ${({ collapsed }) =>
    collapsed &&
    css`
      border-top: none;
      max-height: 0px;
    `};

  > div {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: block;
  }
`;
