import { openDB, deleteDB } from "idb";
import { WSDatabase, WSDatabaseStoreEnum } from "./types";

const __WS_DB_NAME__ = "ws";

export const openWSDB = () => {
  return openDB<WSDatabase>(__WS_DB_NAME__, 1, {
    upgrade(db) {
      db.createObjectStore(WSDatabaseStoreEnum.QueryClientState, {
        keyPath: "id",
        autoIncrement: true
      });
    }
  });
};

export const deleteWSDB = () => {
  return deleteDB(__WS_DB_NAME__);
};
