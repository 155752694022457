/* eslint-disable no-restricted-syntax */
import { format } from "date-fns";
import { matchPath } from "react-router";
import {
  LocalStorageKey,
  readLocalData,
  readLocalParsedData
} from "src/js/modules/storageFunction";
import { WeSchoolRoutes as WeSchoolRoutesDeep } from "src/js/pages/Routes.const";
import { getGroup } from "src/js/repository/groupRepository";
import { fetchSpaceDetails } from "src/js/repository/spacesRepository";
import { fetchLoggedUser } from "src/js/repository/userRepository";
import { __GTM_ID__ } from "src/js/settings/settingsApp";
import { __ROLE_TEACHER__ } from "src/js/settings/settingsUser";
import { TranslationKeys } from "src/js/translation";
import { ActiveGroupFull, SpaceDetailsModel, User } from "src/js/types";
import { WeSchoolRoutesDescription } from "./Routes.description";
import { WeSchoolPageTitles } from "./Routes.titles";
import {
  __DEFAULT_SPACE_INFO__,
  __DEFAULT_USER_DETAILS__
} from "./ViewTrackingProvider.consts";
import { SpaceInfo } from "./ViewTrackingProvider.types";

type NestedObject<T> = { [key: string]: T | NestedObject<T> };

export const flattenObject = <T>(
  obj: NestedObject<T>,
  parentKey = ""
): Record<string, T> => {
  return Object.entries(obj).reduce<Record<string, T>>((acc, [key, value]) => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "object" && value !== null) {
      return { ...acc, ...flattenObject(value as NestedObject<T>, fullKey) };
    }

    return { ...acc, [fullKey]: value as T };
  }, {});
};

export const resetDataLayer = () => {
  if (
    window.google_tag_manager &&
    window.google_tag_manager[__GTM_ID__] &&
    window.google_tag_manager[__GTM_ID__].dataLayer
  ) {
    window.google_tag_manager[__GTM_ID__].dataLayer.reset();
  }
};

export const removeUndefinedKeys = (
  obj: Record<string, any>
): Record<string, any> => {
  const cleanObject: Record<string, any> = {};
  for (const key in obj) {
    if (
      obj[key] !== undefined &&
      obj[key] !== "undefined" &&
      obj[key] !== null
    ) {
      cleanObject[key] = obj[key];
    } else {
      cleanObject[key] = "false";
    }
  }
  return cleanObject;
};

const FlatRoutes = flattenObject<string>(WeSchoolRoutesDeep);
const FlatPageTitles = flattenObject<TranslationKeys>(WeSchoolPageTitles);
const FlatRouteDescriptions = flattenObject<string>(WeSchoolRoutesDescription);
const FlatRouteNames = Object.keys(FlatRoutes);
const RouteMatchBlackList: string[] = [
  WeSchoolRoutesDeep.App.Spaces.initialRouteName
];

export const getHumanReadablePathDescription = (currentPathName: string) => {
  let matchedParams = null;
  const matchedRouteName = FlatRouteNames.find(routeName => {
    const routePath = FlatRoutes[routeName];
    const match = matchPath(currentPathName, {
      path: routePath,
      exact: true,
      strict: true
    });

    if (match && !RouteMatchBlackList.includes(match.path)) {
      matchedParams = match.params;
      return true;
    }

    return false;
  });

  return {
    description: FlatRouteDescriptions[matchedRouteName] ?? "N/A",
    params: matchedParams
  };
};

export const getPageMetaTitle = (currentPathName: string) => {
  const matchedRouteName = FlatRouteNames.find(routeName => {
    const routePath = FlatRoutes[routeName];
    const match = matchPath(currentPathName, {
      path: routePath,
      exact: true,
      strict: true
    });
    return match && !RouteMatchBlackList.includes(match.path);
  });

  return FlatPageTitles[matchedRouteName];
};

export const getUserInfo = async (): Promise<{
  email: string;
  birthday: string;
}> => {
  const userId = Number(readLocalData("userId"));
  if (!userId) return __DEFAULT_USER_DETAILS__;
  try {
    const localData = readLocalParsedData<User>(LocalStorageKey.ActiveUser);
    const userDetails =
      localData && localData.id === userId
        ? localData
        : await fetchLoggedUser();
    return {
      email: userDetails.email,
      birthday: format(new Date(userDetails.birthday), "yyyyMMdd")
    };
  } catch {
    return __DEFAULT_USER_DETAILS__;
  }
};

export const getSpaceInfo = async (
  params: Record<string, any>
): Promise<SpaceInfo> => {
  const { space_id: spaceId, space_slug: spaceSlug } = params || {};
  if (!spaceId && !spaceSlug) return __DEFAULT_SPACE_INFO__;
  try {
    const localData = readLocalParsedData<SpaceDetailsModel>(
      LocalStorageKey.ActiveSpace
    );
    const hasSameSpace =
      localData?.space &&
      (localData.space.id === spaceId || localData.space.slug === spaceSlug);
    const spaceDetails = hasSameSpace
      ? localData
      : await fetchSpaceDetails(spaceSlug || spaceId);
    const { isInTrial, space } = spaceDetails;
    return {
      id: space.id,
      slug: space.slug,
      isDemo: space.spaceType === "Demo",
      trial: isInTrial
    };
  } catch {
    return __DEFAULT_SPACE_INFO__;
  }
};

export const getIsTeacher = async (groupId?: number): Promise<boolean> => {
  if (!groupId) return undefined;
  try {
    const localData = readLocalParsedData<ActiveGroupFull>(
      LocalStorageKey.ActiveGroup
    );
    const { roles } =
      localData && localData.group.id === groupId
        ? localData
        : await getGroup(groupId);
    return roles.includes(__ROLE_TEACHER__);
  } catch {
    return false;
  }
};
