import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";
import { SoundWave } from "@ds_universal/Animation/SoundWave";

import * as S from "./LiveTag.styles";
import { LiveTagProps } from "./types";

const LiveTag: FC<LiveTagProps> = ({ theme, status }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.LiveTag status={status}>
        <div>
          {status === "ongoing" ? (
            <SoundWave />
          ) : (
            <Icon icon="waves" height="8px" width="8px" />
          )}
        </div>
        <Text type="captionInfoDetails">LIVE</Text>
      </S.LiveTag>
    </ThemeProvider>
  );
};

export default LiveTag;
