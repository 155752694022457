import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Translate } from "src/js/translation/TranslationProvider";
import { DoodleLoader, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const SelectDropdownBody = ({
  id = "select-dropdown-body",
  items,
  activeOption,
  actionFunction,
  translateElement,
  showNext,
  hasNext
}) => {
  const { greyTheme } = useTheme();
  if (items && items.length > 0) {
    const list = items.map(option => {
      let activeClass = "";
      if (option.id === activeOption) {
        activeClass = "select-dropdown__element--active";
      }
      return (
        <div
          key={option.id}
          className={`select-dropdown__element ${activeClass}`}
          onClick={() => actionFunction(option.id)}
        >
          {translateElement ? <Translate text={option.name} /> : option.name}
        </div>
      );
    });
    return (
      <div className="select-dropdown__body" id={id}>
        <InfiniteScroll
          scrollableTarget={id}
          dataLength={items.length}
          next={showNext}
          hasMore={hasNext}
          loader={
            <div className="select-dropdown__loader">
              <DoodleLoader theme={greyTheme} isMini />
            </div>
          }
        >
          {list}
        </InfiniteScroll>
      </div>
    );
  }
  return "";
};

const SelectDropdownOld = ({
  id = "select-dropdown",
  elementList,
  defaultDropdownLabel,
  activeOption,
  disabled,
  onChangeFunction,
  translateElement = true,
  showNext,
  hasNext
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const node = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (node.current && !node.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeDropdownValue = value => {
    setDropdownOpen(false);
    onChangeFunction(value);
  };

  let dropdownBody = "";
  const classStatus = dropdownOpen ? "select-dropdown__button--active" : "";
  const elementSelected =
    elementList &&
    elementList.find(element => {
      return activeOption === element.id;
    });
  if (dropdownOpen) {
    dropdownBody = (
      <SelectDropdownBody
        id={id}
        items={elementList}
        actionFunction={changeDropdownValue}
        activeOption={activeOption}
        translateElement={translateElement}
        showNext={showNext}
        hasNext={hasNext}
      />
    );
  }
  let dropdownLabel = elementSelected
    ? elementSelected.name
    : defaultDropdownLabel;
  if (translateElement) {
    dropdownLabel = (
      <Translate
        text={elementSelected ? elementSelected.name : defaultDropdownLabel}
      />
    );
  } else {
    dropdownLabel = elementSelected
      ? elementSelected.name
      : defaultDropdownLabel;
  }
  return (
    <div className="select-dropdown margin-bottom-20" ref={node}>
      <button
        type="button"
        className={`select-dropdown__button form-control ${classStatus}`}
        onClick={toggleDropdown}
        disabled={disabled}
        role="combobox"
        aria-expanded
        aria-controls={id}
      >
        {dropdownLabel}
        <span className="select-dropdown__button__icon">
          {dropdownOpen ? (
            <Icon icon="chevronUp" width={15} height={15} />
          ) : (
            <Icon icon="chevronDown" width={15} height={15} />
          )}
        </span>
      </button>
      {dropdownBody}
    </div>
  );
};

export default SelectDropdownOld;
