import styled from "styled-components";

export const EmptyListCardLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  min-height: 493px;
  width: 100%;
  max-width: 424px;
  padding: 25px 16px;
  background-color: ${({ theme }) => theme.emptylistcard.background};
  margin: 0 auto;
`;

export const EmptyListCardHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const EmptyListCardContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;
  gap: 10px;
`;

export const EmptyListCardActions = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 10px;
`;
