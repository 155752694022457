import { ethers } from "ethers";
import {
  updateBadgeEmittedUsers,
  createBadgeTransaction,
  updateBadgeTransaction
} from "src/js/repository/badgesRepository";
import {
  BadgeRelatedUserType,
  NFTReceiptLog,
  TransactionResponse
} from "src/js/types";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { TransactionStep } from "src/js/types/consts/Badge";
import {
  BadgeAssertion,
  BadgeTransactionStatus
} from "src/js/repository/types";
import { useState } from "react";
import {
  EmitBadgeToStudentsStep,
  EmitBadgeToStudentsStepType
} from "../components/EmitBadgeToStudents/types";
import {
  __TESTNET_SMART_CONTRACTS__,
  __TESTNET_SMART_CONTRACT_ABI__,
  __MAINNET_CHAIN_IDS__,
  __TESTNET_CHAIN_IDS__,
  __MAINNET_SMART_CONTRACTS__,
  __MAINNET_SMART_CONTRACT_ABI__,
  __TESTNET_TRANSACTION_LINKS__,
  __MAINNET_TRANSACTION_LINKS__
} from "./const";
import { MaxNumberOfStudentsToEmitBadge } from "../SpacesBadges.utils";

const useEmitBadgeToStudents = ({
  setStep
}: {
  setStep: (step: EmitBadgeToStudentsStepType) => void;
}) => {
  const [isSbtEnabled, toggleSbt] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: {
      badgeToEdit,
      usersToEmitBadge,
      updateTransactionStep,
      updateTransactionLink,
      updateBadgeAssertions,
      updateTransactionFailed,
      updateTransactionInProgress,
      updateTransactionChainId
    }
  } = useStores();
  const environment = process.env.__APP_ENV__;
  const isProduction = environment === "prod";
  const isEmitButtonDisabled =
    usersToEmitBadge.length > MaxNumberOfStudentsToEmitBadge ||
    isSubmitDisabled;

  const validateChainId = (chainId: string) => {
    if (isProduction) {
      return !!__MAINNET_CHAIN_IDS__[chainId];
    }
    return !!__TESTNET_CHAIN_IDS__[chainId];
  };

  const handleUpdateTransactionPayload = (
    logs: NFTReceiptLog[],
    users: BadgeRelatedUserType[]
  ): { userWalletAddress: string; nftReference: string }[] => {
    const filteredLogs = logs.filter(log => log.topics.length === 4);
    const topics = filteredLogs.map(log => log.topics[3]);
    const payload = users.map((user, index) => {
      return {
        userWalletAddress: user.walletAddress,
        nftReference: String(Number(topics[index]))
      };
    });
    return payload;
  };

  // Payload should be [[walletAddress1, ipfsReference1], [walletAddress2, ipfsReference2]] etc..
  const handleBulkMintPayload = (
    users: BadgeRelatedUserType[],
    assertions: BadgeAssertion[]
  ): string[][] =>
    users.map(user => {
      const badgeAssertion = assertions.find(
        assertion => assertion.userId === user.id
      );

      if (badgeAssertion) {
        return [user.walletAddress, badgeAssertion.ipfsReference];
      }

      return [];
    });

  const proceedToReviewScreen = () =>
    setTimeout(() => {
      setStep(EmitBadgeToStudentsStep.ReviewSelectedStudents);
    }, 3000);

  const mintNFT = async (users: BadgeRelatedUserType[]) => {
    if (!window.ethereum) {
      return;
    }
    try {
      const userIds = users.map(user => user.id);

      const openBadgeCreation = await updateBadgeEmittedUsers(
        userIds,
        badgeToEdit.id,
        true
      );
      if (openBadgeCreation) {
        const { assertions } = openBadgeCreation;

        updateTransactionStep(TransactionStep.WalletApproval);
        updateBadgeAssertions(assertions);
        updateTransactionInProgress(true);

        const bulkMintNFTsPayload = handleBulkMintPayload(users, assertions);

        const provider = new ethers.BrowserProvider(window.ethereum);

        const network = await provider.getNetwork();

        const { chainId } = network;

        const chId = String(chainId);

        const validChainId = validateChainId(chId);

        if (validChainId) {
          const signer = await provider.getSigner();

          const smartContractAddress = isProduction
            ? __MAINNET_SMART_CONTRACTS__[chId]
            : __TESTNET_SMART_CONTRACTS__[chId];

          const smartContractABI = isProduction
            ? __MAINNET_SMART_CONTRACT_ABI__
            : __TESTNET_SMART_CONTRACT_ABI__;

          const chainBaseUrl = isProduction
            ? __MAINNET_TRANSACTION_LINKS__[chId]
            : __TESTNET_TRANSACTION_LINKS__[chId];

          const contract = new ethers.Contract(
            smartContractAddress,
            smartContractABI,
            signer
          );

          const transaction: TransactionResponse =
            await contract.bulkMint(bulkMintNFTsPayload);

          updateTransactionStep(TransactionStep.MintingNFT);

          const { hash } = transaction;

          const transactionLink = `${chainBaseUrl}/${hash}`;

          updateTransactionLink(transactionLink);
          const badgeTransaction = await createBadgeTransaction(
            badgeToEdit.id,
            userIds,
            hash,
            chId,
            smartContractAddress
          );

          const receipt = await transaction.wait();

          if (receipt.status === 1) {
            updateTransactionStep(TransactionStep.Completed);
            showToastSuccess({
              str: translate("space_badge_mint_nft_to_students_success_toast", {
                badgeName: badgeToEdit.name
              })
            });

            const updateTransactionUsersPayload =
              handleUpdateTransactionPayload(receipt.logs, users);

            const updatedBadgeTransaction = await updateBadgeTransaction(
              badgeToEdit.id,
              badgeTransaction.id,
              updateTransactionUsersPayload,
              BadgeTransactionStatus.Success
            );
            updateTransactionInProgress(false);
            updateTransactionChainId(updatedBadgeTransaction.chainId);

            proceedToReviewScreen();
          }
        } else {
          showToastError({ str: translate("metamask_unsupported_network") });
          updateTransactionInProgress(false);
          updateTransactionFailed(true);
          proceedToReviewScreen();
        }
      } else {
        showToastError({ str: translate("general_error") });
        updateTransactionFailed(true);
        updateTransactionInProgress(false);
      }
    } catch (error) {
      showToastError({
        // error.debugMessage is the way to recognise if the error came from api and not from metamask.
        str: error?.debugMessage ? error?.message : translate("general_error")
      });
      updateTransactionFailed(true);
      updateTransactionStep(TransactionStep.WalletApproval);
      updateTransactionInProgress(false);
      if (error?.info?.error?.code === 4001) {
        // User rejected the metamask transaction from the extension.
        proceedToReviewScreen();
      }
      throw error;
    }
  };

  const emitBadgeToStudents = () => {
    const userIds = usersToEmitBadge.map(user => user.id);
    if (isSbtEnabled) {
      setStep(EmitBadgeToStudentsStep.Loading);
      mintNFT(usersToEmitBadge);
    } else {
      setSubmitDisabled(true);
      updateBadgeEmittedUsers(userIds, badgeToEdit.id, isSbtEnabled)
        .then(({ assertions }) => {
          setStep(EmitBadgeToStudentsStep.ReviewSelectedStudents);
          updateBadgeAssertions(assertions);
          setSubmitDisabled(false);
        })
        .catch(() => {
          showToastError({
            str: translate("space_badge_emit_to_users_error_alert")
          });
          setSubmitDisabled(false);
        });
    }
  };

  return {
    isSbtEnabled,
    toggleSbt,
    emitBadgeToStudents,
    isEmitButtonDisabled
  };
};

export default useEmitBadgeToStudents;
