import { Heading, Icon, Button, Text, Box } from "@arcadia/design-system";
import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import * as S from "./MetaMaskWalletConnection.styles";
import useWalletConnection from "./useWalletConnection";

const MetaMaskWalletConnection = () => {
  const {
    UserStore: {
      userWalletDetails: { address }
    }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const { fetchUserWalletDetails, connectToWallet, disconnectWallet } =
    useWalletConnection();

  useEffect(() => {
    fetchUserWalletDetails();
  }, []);

  return (
    <>
      <S.TitleContainer>
        <Heading level="6">{translate("metamask_wallet_heading")}</Heading>
        <TranslatedTooltip
          tooltipString="metamask_tooltip_description"
          placement="top"
        >
          <Icon icon="helpCircle" width={16} height={16} />
        </TranslatedTooltip>
      </S.TitleContainer>
      <S.ConnectWalletButtonWrapper>
        {address ? (
          <>
            <S.WalletAddressWrapper>
              <Icon icon="metamaskFox" width={36} height={36} initialViewbox />
              <Heading level="6">
                {`${address.substring(0, 6)}...${address.substring(
                  address.length - 5
                )}`}
              </Heading>
            </S.WalletAddressWrapper>
            <S.ConnectWalletButtonWrapper>
              <Button
                onClick={disconnectWallet}
                theme={greyTheme}
                variant="danger"
              >
                <Text type="cta">
                  {translate("metamask_disconnect_button")}
                </Text>
              </Button>
            </S.ConnectWalletButtonWrapper>
          </>
        ) : (
          <Box>
            <Button
              onClick={() => connectToWallet(false)}
              theme={greyTheme}
              variant="primary"
            >
              <S.ConnectWalletButtonWrapperContent>
                <Icon
                  icon="metamaskFox"
                  width={24}
                  height={19}
                  initialViewbox
                />
                <Text type="cta">{translate("metamask_connect_button")}</Text>
              </S.ConnectWalletButtonWrapperContent>
            </Button>
            <Box marginTop={8}>
              <S.WalletSupportedChainsInformation type="formSubtitle">
                {translate("metamask_information_about_supported_chains")}
              </S.WalletSupportedChainsInformation>
            </Box>
          </Box>
        )}
      </S.ConnectWalletButtonWrapper>
    </>
  );
};

export default observer(MetaMaskWalletConnection);
