import React, { useState } from "react";
import { EditorContent } from "@tiptap/react";
import { Box, Icon, Text } from "@arcadia/design-system";
import { motion, AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { AiTextEditorEvent, EventDomain } from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTranslation } from "src/js/translation";
import { AiExtensions, TextEditorOptions } from "../../EditorOptions.const";
import { useEditor } from "../../hooks";

import AICustomPromptEditorOptions from "./AICustomPromptEditorOptions";
import { customPromptEditorAnimations } from "./AICustomPromptEditor.utils";
import * as S from "./AICustomPromptEditor.styles";

const AICustomPromptEditor = ({
  query,
  onDiscard: onDiscardCb,
  onApply: onApplyCb,
  onLoading: onLoadingCb
}: {
  query: string;
  onDiscard?: () => void;
  onApply?: (content: string) => void;
  onLoading?: (loading: boolean) => void;
}) => {
  const { translate } = useTranslation();
  const [loading, setLoading] = useState(true);
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const editor = useEditor({
    content: "",
    editable: true,
    options: {
      injectCSS: true,
      extensions: [
        ...TextEditorOptions.extensions,
        ...AiExtensions({
          aiConfig: {
            autocompletion: true,
            autocompletionOptions: {
              modelName: "gpt-4o"
            },
            onLoading() {
              setLoading(true);
            },
            onError() {
              setLoading(false);
            },
            onSuccess() {
              setLoading(false);
            }
          }
        })
      ]
    }
  });
  const {
    ref: editSheetRef,
    isOpen: isEditSheetOpen,
    setIsOpen: setIsEditSheetOpen
  } = useCloseOnClickOutside(false);

  React.useEffect(() => {
    if (editor && onLoadingCb) {
      onLoadingCb(editor.storage.ai.loading);
    }
  }, [editor?.storage.ai.loading, onLoadingCb]);

  React.useEffect(() => {
    if (query.trim() !== "") {
      _trackEvent(
        EventDomain.AiTextEditor,
        AiTextEditorEvent.AiWriterCommandExecuteQuery,
        query
      );
      editor
        ?.chain()
        .focus()
        .selectAll()
        .aiTextPrompt({ text: query, modelName: "gpt-4o", stream: true })
        .run();
    }
  }, [_trackEvent, query]);

  const onDiscard = React.useCallback(() => {
    if (onDiscardCb) onDiscardCb();
    _trackEvent(
      EventDomain.AiTextEditor,
      AiTextEditorEvent.AiWriterCommandDiscard
    );
  }, [_trackEvent, onDiscardCb]);

  const onApply = React.useCallback(() => {
    const htmlContent = editor.getHTML();
    if (onApplyCb) onApplyCb(htmlContent);
    _trackEvent(
      EventDomain.AiTextEditor,
      AiTextEditorEvent.AiWriterCommandApply,
      editor.getText()
    );
  }, [onDiscardCb, editor]);

  const shouldShow = React.useMemo(() => {
    const hasText = editor?.getText().trim().length > 0;
    const hasQuery = query.trim().length > 0;
    return (hasText || hasQuery) && !loading;
  }, [editor, loading, query]);

  React.useEffect(() => {
    if (shouldShow) editor.commands.blur();
  }, [shouldShow]);

  return (
    <AnimatePresence>
      {shouldShow && (
        <motion.div {...customPromptEditorAnimations}>
          <Box padding="0 16px 16px 16px">
            <S.EditorWrapper>
              <Box padding={16}>
                <S.PreviewText type="table">
                  {translate("ai_custom_prompt_header_preview")}
                </S.PreviewText>
                <EditorContent
                  editor={editor}
                  onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                />
              </Box>
            </S.EditorWrapper>
          </Box>
          <S.FooterWrapper>
            <S.FooterButton variant="danger" onClick={onDiscard}>
              <Icon icon="trash" width={16} height={16} initialViewbox />
              <Text type="formFieldEmpty">
                {translate("ai_custom_prompt_btn_discard")}
              </Text>
            </S.FooterButton>
            <S.FooterRightSection isLayoutModeMobile={isLayoutModeMobile}>
              <S.EditSheetRoot ref={editSheetRef}>
                {isEditSheetOpen && (
                  <AICustomPromptEditorOptions
                    editor={editor}
                    query={query}
                    onClose={() => setIsEditSheetOpen(false)}
                  />
                )}
                <S.FooterButton
                  onClick={() => {
                    setIsEditSheetOpen(!isEditSheetOpen);
                  }}
                >
                  <Icon icon="pencil" width={16} height={16} initialViewbox />
                  <Text type="formFieldEmpty">
                    {translate("ai_custom_prompt_btn_edit")}
                  </Text>
                  <Icon
                    icon="chevronDown"
                    width={16}
                    height={16}
                    initialViewbox
                  />
                </S.FooterButton>
              </S.EditSheetRoot>
            </S.FooterRightSection>
            <S.FooterButton onClick={onApply}>
              <Text type="formFieldEmpty">
                {translate("ai_custom_prompt_btn_apply")}
              </Text>
            </S.FooterButton>
          </S.FooterWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default observer(AICustomPromptEditor);
