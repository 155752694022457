import { Heading, Icon } from "@arcadia/design-system";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useCloseOnClickOutside } from "src/js/hooks";
import { SelectMonthYearDropdown } from "./SelectMonthYearDropdown";
import { last100Years, setDatePickerLocale } from "./utils";
import * as S from "./Calendar.styles";
import { CalendarProps } from "./types";

const Calendar = ({
  visible,
  selectedDate,
  years,
  maxDate,
  minDate,
  onChange
}: CalendarProps) => {
  const {
    ref: monthRef,
    isOpen: isMonthDropdownOpen,
    setIsOpen: setIsMonthDropdownOpen
  } = useCloseOnClickOutside(false);
  const {
    ref: yearRef,
    isOpen: isYearDropdownOpen,
    setIsOpen: setIsYearDropdownOpen
  } = useCloseOnClickOutside(false);
  const { translate } = useTranslation();

  useEffect(() => {
    setDatePickerLocale();
  }, []);

  useEffect(() => {
    setIsYearDropdownOpen(false);
    setIsMonthDropdownOpen(false);
  }, [visible]);

  const toggleYearDropdown = useCallback(() => {
    setIsYearDropdownOpen(value => !value);
  }, []);

  const toggleMonthDrodpown = useCallback(() => {
    setIsMonthDropdownOpen(value => !value);
  }, []);

  const months = useMemo(
    () => [
      translate("calendar_month_picker_option_january"),
      translate("calendar_month_picker_option_february"),
      translate("calendar_month_picker_option_march"),
      translate("calendar_month_picker_option_april"),
      translate("calendar_month_picker_option_may"),
      translate("calendar_month_picker_option_june"),
      translate("calendar_month_picker_option_july"),
      translate("calendar_month_picker_option_august"),
      translate("calendar_month_picker_option_september"),
      translate("calendar_month_picker_option_october"),
      translate("calendar_month_picker_option_november"),
      translate("calendar_month_picker_option_december")
    ],
    [translate]
  );

  if (!visible) return null;

  return (
    <S.CalendarContainer>
      <DatePicker
        locale="locale"
        selected={selectedDate}
        onChange={onChange}
        formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
        inline
        maxDate={maxDate}
        minDate={minDate}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          changeYear,
          changeMonth,
          nextMonthButtonDisabled,
          prevMonthButtonDisabled
        }) => {
          return (
            <S.HeaderContainer>
              <S.MonthNavigationContainer
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <Icon icon="chevronLargeLeft" width={16} height={16} />
              </S.MonthNavigationContainer>
              <S.DateContainer>
                <S.Selector ref={monthRef}>
                  <S.DateButton type="button" onClick={toggleMonthDrodpown}>
                    <Heading level="5">{format(date, "MMMM")}</Heading>
                    <Icon
                      icon={
                        isMonthDropdownOpen
                          ? "chevronLargeUp"
                          : "chevronLargeDown"
                      }
                    />
                  </S.DateButton>
                  {isMonthDropdownOpen ? (
                    <SelectMonthYearDropdown
                      data={months}
                      onChange={(month: string) => {
                        setIsMonthDropdownOpen(false);
                        changeMonth(months.findIndex(m => m === month));
                      }}
                    />
                  ) : null}
                </S.Selector>
                <S.Selector ref={yearRef}>
                  <S.DateButton type="button" onClick={toggleYearDropdown}>
                    <Heading level="5">{format(date, "yyyy")}</Heading>
                    <Icon
                      icon={
                        isYearDropdownOpen
                          ? "chevronLargeUp"
                          : "chevronLargeDown"
                      }
                    />
                  </S.DateButton>
                  {isYearDropdownOpen ? (
                    <SelectMonthYearDropdown
                      data={years || last100Years}
                      onChange={(year: number) => {
                        setIsYearDropdownOpen(false);
                        changeYear(year);
                      }}
                    />
                  ) : null}
                </S.Selector>
              </S.DateContainer>
              <S.MonthNavigationContainer
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <Icon icon="chevronLargeRight" width={16} height={16} />
              </S.MonthNavigationContainer>
            </S.HeaderContainer>
          );
        }}
      />
    </S.CalendarContainer>
  );
};

export default Calendar;
