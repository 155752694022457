/* eslint-disable no-shadow */
import React, { useCallback } from "react";
import { useTheme } from "styled-components";
import { FilterDropdown } from "src/js/components/forms/FilterDropdown";
import { Translate } from "src/js/translation/TranslationProvider";
import { Label, Tag, Text } from "@arcadia/design-system";
import * as S from "./CalendarGroupSelector.styles";

const CalendarGroupSelector = ({
  currentGroup = {},
  groupsList = [],
  selectedGroups = [],
  hasNextGroups = false,
  disabled = false,
  showDropdown = false,
  showNextGroups = () => {},
  setGroups = () => {}
}) => {
  const { key, value } = currentGroup;
  const originColor = {
    VIOLET: "violet",
    YELLOW: "yellow",
    CARNATION: "carnation",
    ROYALPURPLE: "royalpurple",
    SUMMERGREEN: "summergreen",
    LAGOON: "lagoon"
  };
  const colorArray = Object.values(originColor);

  const removeGroup = useCallback(
    id => {
      const filteredGroups =
        selectedGroups && selectedGroups.filter(group => group !== id);
      setGroups(filteredGroups);
    },
    [selectedGroups]
  );
  const { whiteTheme } = useTheme();

  return (
    <S.PillList>
      <S.InsideGroupLabel>
        <Text type="formSubtitle">
          <Translate text="calendar_event_modal_add_group_label" />
        </Text>
      </S.InsideGroupLabel>
      <Label theme={whiteTheme} color="bluegrey" key={key}>
        {value}
      </Label>
      {selectedGroups.map((groupId, idx) => {
        const moduleNumber = (idx + 1) % 6;
        const currentElement = groupsList.find(element => {
          return element.key === groupId;
        });
        if (!currentElement) return null;
        return (
          <Tag
            theme={whiteTheme}
            id={currentElement.key}
            key={currentElement.key}
            label={currentElement.value}
            onClick={id => removeGroup(id)}
            color={colorArray[moduleNumber]}
          />
        );
      })}
      {showDropdown && (
        <S.DropdownContainer>
          <FilterDropdown
            scrollableListId="calendar-group-dropdown-list"
            onChange={value => setGroups(value)}
            currentSelected={selectedGroups}
            placeholder="calendar_event_modal_add_group_dropdown"
            inputType="checkbox"
            minWidth="386px"
            maxListHeight="220px"
            options={groupsList}
            hasNext={hasNextGroups}
            showNext={showNextGroups}
            contentTitleWidth
            linkButtonMode
            disabled={disabled}
          />
        </S.DropdownContainer>
      )}
    </S.PillList>
  );
};

export default CalendarGroupSelector;
