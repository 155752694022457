import Tooltip from "rc-tooltip";
import React, { useRef } from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation";
import { Icon, IconName } from "@arcadia/design-system";
import * as S from "./EditBoardIconButton.styles";

type EditBoardIconButtonProps = {
  isAltered?: boolean;
  defaultIconName: IconName;
  alteredIconName?: IconName;
  defaultTooltipMessage: TranslationKeys;
  alteredTooltipMessage?: TranslationKeys;
  clickFunction: () => void;
};
const EditBoardIconButton = ({
  isAltered = false,
  defaultIconName,
  alteredIconName,
  defaultTooltipMessage,
  alteredTooltipMessage,
  clickFunction
}: EditBoardIconButtonProps) => {
  const TooltipWrapperNode = useRef();
  const { translate } = useTranslation();

  return defaultTooltipMessage ? (
    <S.TooltipWrapper ref={TooltipWrapperNode}>
      <Tooltip
        placement="top"
        overlay={translate(
          isAltered && alteredTooltipMessage
            ? alteredTooltipMessage
            : defaultTooltipMessage
        )}
        getTooltipContainer={() => TooltipWrapperNode.current}
      >
        <S.QuickActionButton onClick={clickFunction}>
          <Icon
            icon={
              isAltered && alteredIconName ? alteredIconName : defaultIconName
            }
            height={16}
            width={16}
          />
        </S.QuickActionButton>
      </Tooltip>
    </S.TooltipWrapper>
  ) : (
    <S.QuickActionButton onClick={clickFunction}>
      <Icon
        icon={isAltered && alteredIconName ? alteredIconName : defaultIconName}
        height={16}
        width={16}
      />
    </S.QuickActionButton>
  );
};

export default EditBoardIconButton;
