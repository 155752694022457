import React, { useEffect } from "react";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { Editor } from "@tiptap/core";
import { Icon } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "src/js/translation";
import { dropdownAnimationVariants } from "src/js/pages/boards/components/ModuleCreateDropdown/ModuleCreateDropdown.utils";

import { useAITransformer } from "./useAITransformer";
import * as S from "./AITransformer.styles";
import { AITransformerModal } from "../AITransformerModal";
import { AITransformationValue } from "./AITransformer.types";

const AITransformer = ({ editor }: { editor: Editor }) => {
  const {
    ref: formatRef,
    isOpen: isAiToolsOpen,
    setIsOpen: setIsAiToolsOpen
  } = useCloseOnClickOutside(false);
  const { items, aiTransformerProps, isSubMenu, onGoBack } =
    useAITransformer(editor);
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  useEffect(() => {
    if (!isAiToolsOpen) onGoBack();
  }, [isAiToolsOpen]);

  const filterItems = (item: AITransformationValue) => {
    const isEmpty = editor.state.selection.empty;
    if (!isEmpty) return item;
    const isAiWritter =
      aiTransformerProps[item].title === "ai_editor_ai_writter";
    return isAiWritter;
  };

  const openAiTransformerModal = () => {
    if (isLayoutModeMobile) {
      openModal(() => <AITransformerModal editor={editor} />);
    } else {
      setIsAiToolsOpen(!isAiToolsOpen);
    }
  };

  return (
    <div ref={formatRef}>
      <S.TextButton onClick={openAiTransformerModal}>
        <Icon icon="sparkles" height={20} width={20} initialViewbox />
        <Icon icon="aiMenu" height={20} width={20} initialViewbox />
        <Icon
          width={6}
          height={6}
          initialViewbox
          icon={isAiToolsOpen ? "chevronUp" : "chevronDown"}
        />
      </S.TextButton>
      <AnimatePresence initial={false}>
        {isAiToolsOpen && !isLayoutModeMobile && (
          <S.Dropdown
            variants={dropdownAnimationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {isSubMenu && (
              <S.DropdownItem onClick={onGoBack}>
                <S.NavIcon
                  icon="chevronLeft"
                  initialViewbox
                  width={12}
                  height={12}
                />
                <S.Text type="formSubtitle">{translate("back")}</S.Text>
              </S.DropdownItem>
            )}
            {items.filter(filterItems).map(item => (
              <S.DropdownItem
                key={`${aiTransformerProps[item].title}`}
                onClick={aiTransformerProps[item].action}
              >
                {aiTransformerProps[item].leadingIcon && (
                  <S.LeadingIcon
                    initialViewbox
                    width={16}
                    height={16}
                    icon={aiTransformerProps[item].leadingIcon}
                  />
                )}
                <S.Text type="formSubtitle">
                  {translate(aiTransformerProps[item].title)}
                </S.Text>
                {aiTransformerProps[item].trailingIcon && (
                  <S.NavIconWrapper>
                    <S.NavIcon
                      initialViewbox
                      width={12}
                      height={12}
                      icon={aiTransformerProps[item].trailingIcon}
                    />
                  </S.NavIconWrapper>
                )}
              </S.DropdownItem>
            ))}
          </S.Dropdown>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AITransformer;
