/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { formatBytes } from "src/js/modules/commonFunction";
import FileUploaderView from "src/js/components/global/FileUploaderView";

const InputFile = ({
  buttonUploadText,
  labelSizeText,
  mimeArray,
  maxSize,
  className,
  setInputFile,
  hasNoInputFile = true,
  isRemovable,
  isDisabled = false,
  theme
}) => {
  const [error, setError] = useState("");

  const checkMimeType = event => {
    const file = event.target.files[0];
    const types = mimeArray;
    if (types.every(type => file.type !== type)) {
      setError("file_extension_unsupported_error");
      event.target.value = null;
      return false;
    }
    return true;
  };

  const checkFileSize = event => {
    if (!maxSize) return true;
    const file = event.target.files[0];
    const size = maxSize;
    if (file.size > size) {
      setError("file_size_error");
      event.target.value = null;
      return false;
    }

    return true;
  };

  const onChangeHandler = event => {
    if (checkMimeType(event) && checkFileSize(event)) {
      setInputFile(event.target.files[0]);
      setError("");
    }
  };

  const removeFile = isRemovable ? () => setInputFile(null) : null;
  const maxSizeSupported = maxSize ? formatBytes(maxSize) : null;

  return (
    <FileUploaderView
      theme={theme}
      buttonUploadText={buttonUploadText}
      labelSizeText={labelSizeText}
      onChangeHandler={onChangeHandler}
      removeFile={removeFile}
      hasNoInputFile={hasNoInputFile}
      className={className}
      maxSize={maxSizeSupported}
      errorUploader={error}
      disabled={isDisabled}
    />
  );
};

export default InputFile;
