import { Drawer } from "src/js/components/Drawer";
import styled, { css } from "styled-components";

import { DrawerElStyleProps } from "./types";

export const DrawerEl = styled(Drawer)<DrawerElStyleProps>`
  padding: 24px 56px 24px 32px;

  ${({ open }) =>
    open &&
    css`
      box-shadow: -5px 0px 10px rgb(0 0 0 / 5%);
    `}
`;

export const BottomSheetBody = styled.div`
  padding: 32px 24px 16px;
`;
