/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
export const __IMG_PLACEHOLDER__ = require("src/img/icons/svg/image.svg");

export const __IMG_LOADING__ = "LOADING"; // todo rimuovere questo path e modificare il funzionamento del loader di default dove richiamato
export const __WESCHOOL_LOGO__ = require("Images/weschool_logo.svg");

export const __HERO_GROUPS_PLACEHOLDER__ = require("Images/placeholders/default_cover_image.jpg");
export const __GROUP_COVER_DEFAULT__ = require("Images/placeholders/default_group_background_spaces.jpg");
export const __GROUP_PLACEHOLDER__ = require("Images/placeholders/default_group_background_spaces.jpg");

export const __NO_FILTERED_NOTIFICATIONS_PLACEHOLDER__ = require("Images/placeholders/no-filtered-notifications-placeholder.svg");

export const __HOME_BACKGROUND__ = require("Images/backgrounds/pattern-background.svg");

export const __UNLOGGED_BG_DESKTOP__ = require("Images/backgrounds/unlogged-desktop.jpg");
export const __UNLOGGED_BG_MOBILE__ = require("Images/backgrounds/unlogged-mobile.jpg");

export const __ACTIVATE_ACCOUNT_BG_1_DESKTOP__ = require("Images/backgrounds/shade-fun-group.jpg");
export const __ACTIVATE_ACCOUNT_BG_2_DESKTOP__ = require("Images/backgrounds/shade-fun-smartphone.jpg");
export const __ACTIVATE_ACCOUNT_BG_3_DESKTOP__ = require("Images/backgrounds/shade-fun-desk.jpg");
export const __ACTIVATE_ACCOUNT_BG_4_DESKTOP__ = require("Images/backgrounds/shade-sitting-serious.jpg");

export const __SPACE_BADGE_SCHOOL_PLACEHOLDER__ = require("Images/placeholders/space_school_badge_placeholder.svg");

export const __CALENDAR_ARROW_ICON__ = require("Images/calendar-arrow-icon.svg");

export const __AGENDA_CAL_AUTUMN__ = require("Images/placeholders/agenda_placeholders/cal-autumn.svg");
export const __AGENDA_CAL_SPRING__ = require("Images/placeholders/agenda_placeholders/cal-spring.svg");
export const __AGENDA_CAL_SUMMER__ = require("Images/placeholders/agenda_placeholders/cal-summer.svg");
export const __AGENDA_CAL_TODAY__ = require("Images/placeholders/agenda_placeholders/cal-today.svg");
export const __AGENDA_CAL_WINTER__ = require("Images/placeholders/agenda_placeholders/cal-winter.svg");
export const __AGENDA_SEASON_AUTUMN__ = require("Images/placeholders/agenda_placeholders/season-autumn.svg");
export const __AGENDA_SEASON_SPRING__ = require("Images/placeholders/agenda_placeholders/season-spring.svg");
export const __AGENDA_SEASON_SUMMER__ = require("Images/placeholders/agenda_placeholders/season-summer.svg");
export const __AGENDA_SEASON_WINTER__ = require("Images/placeholders/agenda_placeholders/season-winter.svg");
export const __AGENDA_TODAY_NO_EVENTS__ = require("Images/placeholders/agenda_placeholders/today-no-events.svg");

export const __DELETE_ACCOUNT_ERASER__ = require("Images/placeholders/eraser.svg");
export const __DOODLE_BACKGROUND__ = require("Images/doodle-background.svg");

export const __LESSONS_EMPTY__ = require("Images/lessons-empty.svg");
export const __NO_MODULES_MOBILE__ = require("Images/no_modules.svg");

export const __GROUP_OVERQUOTA_BLOCKED_BACKGROUND_DESKTOP__ = require("Images/group-overquota-blocked-desktop.png");
export const __GROUP_OVERQUOTA_BLOCKED_BACKGROUND_MOBILE__ = require("Images/group-overquota-blocked-mobile.png");

export const __ILLUSTRATION_CREATE_NEW_GROUP__ = require("Images/placeholders/illustration-create-new-group.svg");
export const __GROUP_COVER_PLACEHOLDER__ = require("Images/placeholders/group_cover_placeholder.jpg");

export const __NO_GROUPS_IN_SPACE_PLACEHOLDER__ = require("Images/placeholders/no-group-in-space-placeholder.svg");

export const __AI_CONTENT_SUCCESS_PLACEHOLDER__ = require("Images/placeholders/ai_content_placeholder.png");
export const __AI_CONTENT_ERROR_PLACEHOLDER__ = require("Images/placeholders/ai_content_error_placeholder.png");
export const __STROKE_BACKGROUND__ = require("Images/backgrounds/stroke-background.svg");

export const __TESTIMONIAL_IMAGE_1__ = require("Images/testimonial/testimonial_img_1.png");
export const __TESTIMONIAL_IMAGE_2__ = require("Images/testimonial/testimonial_img_2.png");
export const __TESTIMONIAL_IMAGE_3__ = require("Images/testimonial/testimonial_img_3.png");
export const __TESTIMONIAL_IMAGE_4__ = require("Images/testimonial/testimonial_img_4.png");
export const __TESTIMONIAL_BACKGROUND_1__ = require("Images/testimonial/testimonial_bg_1.svg");
export const __TESTIMONIAL_BACKGROUND_2__ = require("Images/testimonial/testimonial_bg_2.svg");
export const __TESTIMONIAL_BRAND_1__ = require("Images/testimonial/testimonial_brand_1.svg");
export const __TESTIMONIAL_BRAND_2__ = require("Images/testimonial/testimonial_brand_2.svg");

export const __EXAMPLE_BACKGROUND_PATTERN__ = require("Images/examples/onboarding_bg_examples.svg");

export const __EXAMPLE_SPACE_CREATION_IMAGE__ = require("Images/examples/onboarding_space_creation.png");
export const __EXAMPLE_GROUP_CREATION_IMAGE__ = require("Images/examples/onboarding_group_creation.png");
export const __EXAMPLE_CLAIM_SCHOOL_SPACE_IMAGE__ = require("Images/examples/onboarding_claim_school_space.png");

export const __EXAMPLE_MEMBERS_INVITE_VIDEO__ = require("Images/examples/onboarding_invite_member.mp4");

// todo remove when new icon set is ready
export const __ICON_UPLOAD__ = require("src/img/icon-upload.png");
export const __ICON_HEART__ = require("src/img/icon-heart.png");
export const __ICON_HEART_O__ = require("src/img/icon-heart-o.png");

export const __WELCOME_CARD_BG__ = require("src/img/mapping.png");

export const __DEMO_BADGE__ = "https://static.weschool.com/demo-badge.png";

export const __NO_RESULTS__ = require("src/img/no_results.svg");
