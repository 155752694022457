import {
  api_user_group_invites,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";

let inviteList = null;

function fetchGroupInvitesList(start, limit, timestamp) {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_group_invites(), "v3"), {
      params: {
        start,
        limit,
        max_timestamp: timestamp
      }
    })
    .then(response => {
      inviteList = response.data.results;
      return inviteList;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_invite_list")
      );
      throw Err;
    });
}

export { fetchGroupInvitesList as default };
