import React from "react";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { Label } from "@arcadia/design-system";

const ExerciseQuizCountBadge = ({ quizCount }) => {
  const { whiteTheme } = useTheme();
  const quizCountLabel =
    quizCount === 1 ? "quiz_count_singular_label" : "quiz_count_label";
  return (
    <Label theme={whiteTheme} color="primary" outline={false}>
      {`${quizCount} `}
      <Translate text={quizCountLabel} />
    </Label>
  );
};

export default ExerciseQuizCountBadge;
