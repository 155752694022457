import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  BottomModalSheet,
  DoodleLoader,
  Icon,
  Text,
  VariantType
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import {
  ReactableEntity,
  ReactionCounterType
} from "src/js/types/models/Reaction";
import InfiniteScroll from "react-infinite-scroll-component";
import { REACTION_MODAL_TAB, ReactionModalTabType } from "../Reactions.types";
import { getShortcodeFromType } from "../Reactions.utils";
import { useModalProps } from "./hooks";
import { ReactionModalHeader } from "./components";
import { useUserReactionList } from "../hooks";

import * as S from "./ReactionListModal.styles";

type ReactionListModalProps = {
  reactions: ReactionCounterType[];
  onClose: () => void;
  entity: ReactableEntity;
  entityId: string;
};

const ReactionListModal = ({
  reactions,
  onClose = () => {},
  entity,
  entityId
}: ReactionListModalProps) => {
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: { checkIfUserIsTeacher }
  } = useStores();
  const { initUserReaction, isLoading, getUserReactionList, nextPage } =
    useUserReactionList({
      limit: 10,
      reactableEntity: entity,
      reactedId: entityId
    });
  const { whiteTheme, primaryColor } = useTheme();
  const { domEl } = useModalProps({
    onClose,
    portalId: "reaction-viewer-modal",
    // i need this 9999 for the zIndex in order to see the ReactionList above the old modal, that have like 9000 or so of zIndex
    customStyle: "position:fixed; z-index:9999"
  });
  const [selectedTab, setSelectedTab] = useState<ReactionModalTabType>(
    REACTION_MODAL_TAB.ALL
  );

  useEffect(() => {
    initUserReaction(
      selectedTab === REACTION_MODAL_TAB.ALL ? null : selectedTab
    );
  }, [selectedTab]);

  const reactionsList = getUserReactionList(selectedTab);

  const content = (
    <>
      <ReactionModalHeader
        reactions={reactions}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        onClose={onClose}
      />
      <S.ModalBody id={`reactionList${selectedTab}`}>
        <InfiniteScroll
          scrollableTarget={`reactionList${selectedTab}`}
          dataLength={reactionsList.results.length}
          next={() => {
            nextPage(selectedTab);
          }}
          hasMore={reactionsList.hasNext}
          loader={<DoodleLoader theme={whiteTheme} isMini />}
        >
          {isLoading ? (
            <DoodleLoader theme={whiteTheme} isMini />
          ) : (
            reactionsList &&
            reactionsList.results.map(({ user, reactionType }) => {
              return (
                <S.UserCard key={user.uuid}>
                  <S.AvatarWithReaction>
                    <S.StyledAvatar
                      theme={whiteTheme}
                      url={user.avatar?.medium}
                      size="m"
                    />
                    <em-emoji
                      shortcodes={getShortcodeFromType(reactionType)}
                      set="native"
                      size="8px"
                    />
                  </S.AvatarWithReaction>
                  <Text type="captionAlert">
                    {user.name} {user.surname}
                  </Text>

                  {checkIfUserIsTeacher(user.uuid) ? (
                    <Icon
                      icon="teacherRibbon"
                      height="16"
                      width="16"
                      color={primaryColor[500]}
                    />
                  ) : null}
                </S.UserCard>
              );
            })
          )}
        </InfiniteScroll>
      </S.ModalBody>
    </>
  );

  return (
    <>
      {createPortal(
        <S.Overlay onClick={onClose}>
          {isLayoutModeMobile ? (
            <BottomModalSheet
              title=""
              withDrag
              withHeaderLabels={false}
              isOpen
              onClose={onClose}
              variant={VariantType.White}
            >
              {content}
            </BottomModalSheet>
          ) : (
            <S.ReactionModal
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {content}
            </S.ReactionModal>
          )}
        </S.Overlay>,
        domEl
      )}
    </>
  );
};

export default ReactionListModal;
