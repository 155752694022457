import styled, { css } from "styled-components";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";

export const ColorLabelContainer = styled.div`
  display: inline-block;
  min-height: 24px;
  width: auto;
  font-size: 11px;
  text-align: center;
  padding: 0 4px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  background-color: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.basicWhite};
  border-radius: 4px;
  line-height: 24px;
  word-break: break-word;

  ${({ type }) =>
    type === COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS &&
    css`
      background-color: ${({ theme }) => theme.colors.exerciseLabelSuccess};
    `}
  ${({ type }) =>
    type === COLOR_LABEL_TYPE.COLOR_LABEL_ERROR &&
    css`
      background-color: ${({ theme }) => theme.colors.exerciseLabelError};
    `}
  ${({ type }) =>
    type === COLOR_LABEL_TYPE.COLOR_LABEL_WARNING &&
    css`
      background-color: ${({ theme }) => theme.colors.exerciseLabelWarning};
    `}
  ${({ type }) =>
    type === COLOR_LABEL_TYPE.COLOR_LABEL_ORANGE &&
    css`
      background-color: #ff7f1b;
    `}
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`;
