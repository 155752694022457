import React, { useEffect, useRef, useState } from "react";
import { Box, Text } from "@arcadia/design-system";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { isEllipsisActive } from "src/js/modules/commonFunction";
import * as S from "./EllipsisName.styles";

type EllipsisNameProps = {
  name: string;
  onClick?: () => void;
};

const EllipsisName = ({ name, onClick }: EllipsisNameProps) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const ellipsableNode = useRef();

  useEffect(() => {
    setHasEllipsis(isEllipsisActive(ellipsableNode.current));
  }, []);

  return (
    <TranslatedTooltip
      placement="top"
      forceHide={!hasEllipsis}
      customTooltip={
        <Box padding={12} width="max-content" textAlign="center" fontSize={14}>
          <Text type="formSubtitle">{name}</Text>
        </Box>
      }
    >
      <S.EllipsisNameWrapper onClick={() => onClick?.()}>
        <S.EllipsisNameContainer ref={ellipsableNode} isClickable={!!onClick}>
          <S.EllipsisNameText type="table">{name}</S.EllipsisNameText>
        </S.EllipsisNameContainer>
      </S.EllipsisNameWrapper>
    </TranslatedTooltip>
  );
};

export default EllipsisName;
