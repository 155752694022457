import styled, { css } from "styled-components";
import { Heading, Text } from "@arcadia/design-system";
import { Container } from "react-awesome-styled-grid";
import { motion } from "framer-motion";

export const SpaceModuleDetailsHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ContentWrapper = styled(Container)`
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 767px) {
    padding: 0px 16px;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: relative;
`;

export const ActionButtonWrapper = styled(motion.div)``;

export const MileStoneWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 72px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
`;

export const MiddleSection = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const MilestoneFlag = styled.div`
  margin-left: -2px;
  margin-right: -2px;
`;

export const Column = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ModuleNameRow = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const ModuleNameRowMobile = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 75%;
  width: 100%;
`;

export const HeadingMobile = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OptionalTag = styled(Text)`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  border-radius: 100px;
  padding: 6px 12px;
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const RightSection = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const MilestoneMessageWrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  padding: 0 16px;
  position: absolute;
  bottom: -45px;
  display: flex;
  justify-content: center;

  transition: visibility 0.2s ease-out;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}
`;

export const InputWrapper = styled.div`
  margin-top: -8px;
`;
