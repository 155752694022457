import styled from "styled-components";

export const Button = styled.button`
  font-family: "Roboto" !important;
  font-size: 14px;
  background: ${({ theme }) => theme.googleButton.background};
  color: rgba(0, 0, 0, 0.54);
  border: ${({ theme }) => theme.googleButton.border};
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 25px;
  border-radius: 20px;
  gap: 12px;
  cursor: pointer;
  &:hover {
    border: ${({ theme }) => theme.googleButton.hover.border};
  }
  &:focus {
    border: ${({ theme }) => theme.googleButton.focus.border};
  }
`;
