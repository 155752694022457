import React from "react";
import { colors } from "@arcadia/design-system";

export const CircleArrowIcon = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <circle cx={12} cy={12} r={12} fill={colors.grey[1000]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.195 9.94a.638.638 0 0 0 0 .918l5.325 5.193.01.009a.673.673 0 0 0 .474.19.672.672 0 0 0 .475-.199l5.326-5.193a.638.638 0 0 0 0-.918.678.678 0 0 0-.942 0L12 14.683 7.136 9.94a.678.678 0 0 0-.941 0Z"
      fill={colors.violet[501]}
    />
  </svg>
);

export const DotSelectionIcon = props => (
  <svg width={18} height={18} fill="none" {...props}>
    <rect
      x={1}
      y={1}
      width={16}
      height={16}
      rx={8}
      stroke={colors.grey[1000]}
      strokeWidth={1.5}
    />
    <rect x={3} y={3} width={12} height={12} rx={6} fill={colors.grey[1000]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.866 6.398a.49.49 0 0 0-.685-.016l-4.5 4.23L5.82 8.86a.49.49 0 0 0-.685.017.474.474 0 0 0 .016.676l2.197 2.065a.49.49 0 0 0 .67 0l4.833-4.544a.474.474 0 0 0 .016-.676Z"
      fill={colors.violet[501]}
    />
  </svg>
);

export const ArrowPopupIcon = props => (
  <svg
    viewBox="0 0 24 16"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2
    }}
    {...props}
  >
    <path
      d="M19.817 5.575 12 15.998 4.183 5.575h15.634Z"
      style={{
        fill: colors.grey[1000],
        fillRule: "nonzero"
      }}
    />
    <path
      d="M19.404 4.475 12 14.347 4.596 4.475h14.808Z"
      style={{
        fill: colors.violet[501],
        fillRule: "nonzero"
      }}
    />
  </svg>
);
