import styled from "styled-components";

export const CardAddContent = styled.button`
  width: 100%;
  height: 242px;
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.card.cardaddcontent.border};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  &:focus-within {
    outline: ${({ theme }) => theme.card.cardaddcontent.focus.border};
  }
  padding: 0px;
  margin-bottom: 6px;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const WrapperIcon = styled.div`
  height: 175px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px 3px 0px 0px;
  background-color: ${({ theme }) =>
    theme.card.cardaddcontent.wrapperIcon.background};
  color: ${({ theme }) => theme.card.cardaddcontent.wrapperIcon.text};
  border-bottom: ${({ theme }) => theme.card.cardaddcontent.wrapperIcon.border};
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.card.cardaddcontent.wrapperText.background};
  padding: 13px;
  border: ${({ theme }) => theme.card.cardaddcontent.border};
  border-radius: 50%;
  svg {
    color: ${({ theme }) => theme.card.cardaddcontent.wrapperText.text};
  }
`;

export const WrapperText = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.card.cardaddcontent.wrapperText.background};
  color: ${({ theme }) => theme.card.cardaddcontent.wrapperText.text};
  border-radius: 0px 0px 3px 3px;
  position: relative;
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  div {
    display: flex;
    align-items: center;
  }
`;
