import React, { useEffect, useState } from "react";
import { PillBarProps } from "./PillBar.types";

import * as S from "./PillBar.styles";

const PillBar = <T extends string | number>({
  items,
  onTabChange
}: PillBarProps<T>) => {
  const [selected, setSelected] = useState(items[0].id);

  useEffect(() => {
    if (onTabChange) onTabChange(items.find(item => item?.id === selected));
  }, [selected, onTabChange]);

  return (
    <S.PillBar>
      {items.map(pillItem => {
        const isActive = selected === pillItem.id;
        return (
          <S.Pill
            key={pillItem.label}
            onClick={() => {
              setSelected(pillItem.id);
            }}
            active={isActive}
          >
            <S.PillText
              active={isActive}
              type={isActive ? "formField" : "body"}
            >
              {pillItem.label}
            </S.PillText>
            {isActive && <S.Background layoutId="background" />}
          </S.Pill>
        );
      })}
    </S.PillBar>
  );
};

export default PillBar;
