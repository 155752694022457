import React, { useState } from "react";
import {
  useAIServiceFetcher,
  useAITransactionInfo,
  useStores
} from "src/js/hooks";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { useResourceUpload } from "src/js/hooks/resources";
import { AIGenerationRequestEnum } from "src/js/hooks/useAIServiceFetcher.types";
import { SaveResourceResponseType } from "src/js/repository/resourceRepository";
import SubjectAndMessageForm from "./steps/SubjectAndMessageForm";
import { AITextEditor, AILoadingView } from "./components";

type AddAITextEditorResource = {
  onSubmit: (resource: SaveResourceResponseType) => void;
  onClose?: () => void;
  setUploadingProgress?: (id: number, progress: number) => void;
  elementId?: number;
};

const AddAITextEditorResource = ({
  elementId,
  onClose,
  onSubmit,
  setUploadingProgress
}: AddAITextEditorResource) => {
  const [AIResponse, setAIResponse] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [defaultQuestion, setDefaultQuestion] = useState("");

  const { handleResourceTextUpload } = useResourceUpload(setUploadingProgress);
  const {
    BoardsStore: { setUploadingElementIndexes, filterUploadingElementIndexes }
  } = useStores();
  const AITransactionInfo = useAITransactionInfo({ isAI: true });

  const fetchContent = useAIServiceFetcher({
    requestType: AIGenerationRequestEnum.content,
    AITransactionInfo
  });
  const saveFunction = ({ text }) => {
    onClose?.();
    setUploadingElementIndexes(elementId);
    handleResourceTextUpload(
      title,
      text,
      elementId,
      AITransactionInfo.aiTransactionId
    ).then(response => {
      onSubmit(response);
      filterUploadingElementIndexes(elementId);
    });
  };

  if (loading) return <AILoadingView />;
  return AIResponse.length ? (
    <AITextEditor
      text={AIResponse}
      onReset={() => {
        setLoading(true);
        fetchContent({
          body: { subject: title, message: defaultQuestion }
        })
          .then(response => {
            setAIResponse(response.content);
          })
          .catch(error => {
            setAIResponse("");

            showToastError({
              str: extractErrorMessage(
                error,
                "Attenzione, si è verificato un errore"
              )
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      onBack={() => {
        setAIResponse("");
      }}
      onSubmit={(text: string) => {
        saveFunction({ text });
      }}
    />
  ) : (
    <SubjectAndMessageForm
      defaultSubject={title}
      defaultQuestion={defaultQuestion}
      onSubmit={(subject, question) => {
        setTitle(subject);
        setDefaultQuestion(question);
        setLoading(true);
        fetchContent({ body: { subject, message: question } })
          .then(response => {
            setAIResponse(response.content);
          })
          .catch(error => {
            setAIResponse("");
            showToastError({
              str: extractErrorMessage(
                error,
                "Attenzione, si è verificato un errore"
              )
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    />
  );
};

export default AddAITextEditorResource;
