import { ScormData, ScormDataDetail } from "./useScormGroup";

const __SCORM_KEY_ALL__ = "scorm";

export const scormQueryKey = {
  all: [__SCORM_KEY_ALL__] as const,
  groupId: ({
    groupId,
    orderBy,
    sortOrder,
    textSearch
  }: {
    groupId: number;
    orderBy?: keyof Omit<ScormData, "id">;
    sortOrder?: string;
    textSearch?: string;
  }) =>
    [
      `${__SCORM_KEY_ALL__}`,
      { groupId, orderBy, sortOrder, textSearch }
    ] as const,
  userUuid: ({
    userUuid,
    groupId,
    orderBy,
    sortOrder,
    textSearch
  }: {
    userUuid: string;
    groupId: number;
    orderBy?: keyof Omit<ScormDataDetail, "id">;
    sortOrder?: string;
    textSearch?: string;
  }) =>
    [
      `${__SCORM_KEY_ALL__}`,
      { userUuid, groupId, orderBy, sortOrder, textSearch }
    ] as const
} as const;
