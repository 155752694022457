import { ethers } from "ethers";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  getUserWalletDetails,
  createWallet,
  deleteUserWallet
} from "src/js/repository/userRepository";
import { useTranslation } from "src/js/translation";

const useWalletConnection = () => {
  const {
    UserStore: {
      updateUserWallet,
      activeUser,
      userWalletDetails: { walletId }
    }
  } = useStores();
  const { translate } = useTranslation();
  const { uuid } = activeUser || {};

  const disconnectWallet = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_revokePermissions",
        params: [
          {
            eth_accounts: {}
          }
        ]
      });
    } catch (error) {
      showToastError({ str: translate("metamask_disconnect_info") });
      throw error;
    } finally {
      await deleteUserWallet(uuid, walletId);
      window.location.reload();
    }

    updateUserWallet({ address: "", chainId: "", walletId: "" });
  };

  const connectToWallet = async (shouldShowToaster?: boolean) => {
    if (!window.ethereum) {
      showToastError({ str: translate("install_metamask_alert") });
    } else {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const network = await provider.getNetwork();
      const { chainId } = network;
      const stringChainId = String(chainId);

      try {
        const walletDetails = await createWallet(
          uuid,
          signer.address,
          stringChainId
        );

        if (shouldShowToaster) {
          showToastSuccess({
            str: translate("metamask_successful_connection")
          });
        }

        updateUserWallet({
          address: walletDetails.address,
          chainId: walletDetails.chain,
          walletId: walletDetails.id
        });
      } catch (error) {
        showToastError({ str: error?.message });
        disconnectWallet();
        throw error?.response.data;
      }
    }
  };

  const fetchUserWalletDetails = async () => {
    try {
      const userWalletDetails = await getUserWalletDetails(uuid);

      updateUserWallet({
        address: userWalletDetails.address,
        chainId: userWalletDetails.chain,
        walletId: userWalletDetails.id
      });
    } catch {
      Promise.resolve();
    }
  };

  return {
    fetchUserWalletDetails,
    connectToWallet,
    disconnectWallet
  };
};

export default useWalletConnection;
