import React from "react";
import { useTheme } from "styled-components";
import {
  Box,
  VerticalStepper,
  VerticalStepperStep
} from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import * as S from "../styles";

const MintingNFTs = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: { transactionStep, transactionLink, transactionFailed }
  } = useStores();
  const stepperItems: VerticalStepperStep[] = [
    {
      content: <div />,
      title: translate("emit_nft_to_students_first_step"),
      hasLoading: true
    },
    {
      content: <div />,
      title: translate(
        transactionFailed
          ? "emit_nft_to_students_second_step_failed"
          : "emit_nft_to_students_second_step"
      ),
      hasLoading: !transactionFailed,
      hasError: transactionFailed
    },
    {
      content: <div />,
      title: translate("emit_nft_to_students_third_step"),
      hasLoading: true
    }
  ];

  return (
    <S.MintingNFTsContainer>
      <S.Loader theme={whiteTheme} />
      <Box textAlign="center" margin="32px 0px">
        <S.MintingNFTsTitle level="5">
          {translate("emit_nft_to_students_loading_text")}
        </S.MintingNFTsTitle>
      </Box>
      <S.StepperContainer>
        <VerticalStepper items={stepperItems} step={transactionStep} />
      </S.StepperContainer>
      {transactionLink && (
        <S.TransactionDetails>
          <Box
            cursor="pointer"
            display="flex"
            alignItems="center"
            onClick={() => window.open(transactionLink)}
          >
            <S.TransactionTitle type="formSmallCaption">
              {translate("emit_nft_to_students_view_transaction_text")}
            </S.TransactionTitle>
            <S.TransactionLinkIcon
              icon="externalLink"
              initialViewbox
              width={12}
              height={12}
            />
          </Box>
        </S.TransactionDetails>
      )}
    </S.MintingNFTsContainer>
  );
};

export default observer(MintingNFTs);
