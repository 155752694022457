import {
  createSpaceCheckout,
  fetchSpacePortalSession
} from "src/js/repository/spacesRepository";
import { BillingFrequencyType, PlanType } from "src/js/types";
import {
  ChargebeeCallbacksType,
  ChargebeeWidgetType
} from "./ChargebeeUtils.types";

export const chargebeeSetupPortal = ({ activeSpaceId }) => {
  if (activeSpaceId) {
    const cbInstance = window.Chargebee?.getInstance();
    cbInstance?.setPortalSession(() => {
      // Hit your end point that returns portal session object as response
      return fetchSpacePortalSession({ spaceId: activeSpaceId });
    });
  }
};

export const chargebeePortal = ({
  subscriptionId,
  widgetType = ChargebeeWidgetType.SubscriptionDetails,
  callbacks = {}
}: {
  subscriptionId: string;
  widgetType: ChargebeeWidgetType;
  callbacks?: ChargebeeCallbacksType;
}) => {
  const cbInstance = window.Chargebee?.getInstance();
  const cbPortal = cbInstance?.createChargebeePortal();
  const options = {
    ...callbacks
  };
  cbPortal.open(options, {
    sectionType: window.Chargebee.getPortalSections()[widgetType],
    params: {
      subscriptionId
    }
  });
};

export const chargebeeCheckout = ({
  activeSpaceId,
  planType,
  planSeats,
  planBilling,
  planPriceCurrency,
  callbacks
}: {
  activeSpaceId: string;
  planType: PlanType;
  planSeats: number;
  planBilling: BillingFrequencyType;
  planPriceCurrency: string;
  callbacks?: ChargebeeCallbacksType;
}) => {
  const cbInstance = window.Chargebee?.getInstance();
  cbInstance?.openCheckout({
    hostedPage() {
      // Hit your end point that returns hosted page object as response
      // This sample end point will call the below api
      // You can pass hosted page object created as a result of checkout_new, checkout_existing, manage_payment_sources
      // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
      // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_existing_subscription
      // https://apidocs.chargebee.com/docs/api/hosted_pages#manage_payment_sources
      // If you want to use paypal, go cardless and plaid, pass embed parameter as false
      return createSpaceCheckout({
        spaceId: activeSpaceId,
        plan: planType,
        seats: planSeats,
        currency: planPriceCurrency,
        billing: planBilling
      });
    },
    ...callbacks
  });
};

export const chargebeeClosePortal = () => {
  const cbInstance = window.Chargebee?.getInstance();
  cbInstance?.closeAll();
};

export const chargebeeLogout = () => {
  const cbInstance = window.Chargebee?.getInstance();
  cbInstance?.logout();
};
