import getUserClient from "src/js/modules/connection";
import {
  api_dea_group_metadata,
  api_dea_import_group,
  api_dea_lesson_metadata,
  api_dea_import_lesson
} from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";

export function fetchDeaProject(projectId) {
  return getUserClient()
    .get(api_dea_group_metadata({ projectId }))
    .then(({ data }) => data)
    .catch(err => {
      const Err = new Error(extractErrorMessage(err, "error_wrong_url"));
      throw Err;
    });
}

export function createDeaProject(projectId) {
  return getUserClient()
    .post(api_dea_import_group({ projectId }))
    .then(({ data }) => data)
    .catch(err => {
      const Err = new Error(extractErrorMessage(err, "error_fetch_group"));
      throw Err;
    });
}

export function fetchDeaLesson(projectId, lessonId) {
  return getUserClient()
    .get(api_dea_lesson_metadata({ projectId, lessonId }))
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      const Err = new Error(extractErrorMessage(err, "error_wrong_url"));
      throw Err;
    });
}

export function createDeaLesson(groupId, projectId, lessonId) {
  const body = { group_id: groupId };
  return getUserClient()
    .post(api_dea_import_lesson({ projectId, lessonId }), body)
    .then(({ data }) => data)
    .catch(err => {
      const Err = new Error(extractErrorMessage(err, "error_fetch_group"));
      throw Err;
    });
}
