import { __DOODLE_BACKGROUND__ } from "src/js/settings/settingsImage";
import styled from "styled-components";

export const Background = styled.div`
  min-height: 100vh;
  background-image: url(${__DOODLE_BACKGROUND__});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${({ theme }) => theme.colors.violet[500]};
  position: relative;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    padding-bottom: 120px;
    min-height: unset;
  }
`;

export const CustomModalBody = styled.div`
  position: relative;
  width: 580px;
  margin: 16px;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: ${({ theme }) => theme.fontSizes.base};
  background-color: ${({ theme }) => theme.colors.basicWhite};
  z-index: ${({ theme }) => theme.zIndex.forModal};
  box-shadow: ${({ theme }) => theme.shadows.modals};
`;

export const BodyWrapper = styled.div`
  padding: 32px;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.title};

  @media (max-width: 767px) {
    padding: 24px 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h5`
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.basicBlack};
  margin: 0 8px 0 0;
`;

export const AttemptsTag = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.primaryColor[500]};
  background-color: ${({ theme }) => theme.primaryColor[800]};
  margin-right: 10px;
  padding: 0 12px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

export const CreatorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

export const CreatorInfoText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-left: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    padding-left: 8px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex: 1 0 162px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    & + & {
      margin-left: 8px;
    }
  }

  @media (max-width: 767px) {
    flex: 1 0 50%;
  }
`;

export const IconSquare = styled.div`
  color: ${({ theme }) => theme.colors.basicWhite};
  background-color: ${({ theme }) => theme.primaryColor[500]};
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  @media (max-width: 767px) {
    margin-right: 8px;
  }
`;

export const InfoText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InfoDescription = styled.p`
  color: ${({ theme }) => theme.colors.slate};
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin: 0;
`;

export const InfoValue = styled.p`
  color: ${({ theme }) => theme.colors.basicBlack};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  margin: 0;
`;

export const StatsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
`;

export const BannerWrapper = styled.div`
  margin-bottom: 24px;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const AnchorBanner = styled.a`
  &:hover {
    color: ${({ theme }) => theme.colors.basicWhite};
  }
`;

export const StatsBlock = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 0 0;
  border-radius: 6px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.accent4};

  & + & {
    margin-left: 8px;
  }

  @media (max-width: 767px) {
    flex: 1 0 100%;
    & + & {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
`;

export const StatsBlockLabel = styled.span`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 4px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  & > a:last-child {
    margin-left: auto;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    & > a:first-child {
      margin-top: 8px;
    }
  }
`;
