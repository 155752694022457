import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import filter from "lodash/filter";
import reject from "lodash/reject";
import each from "lodash/each";
import map from "lodash/map";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { __QUIZ_MAX_POINTS__ } from "src/js/settings/settingsExercise";
import {
  Box,
  Button,
  ContextualError,
  RadioGroup,
  Icon
} from "@arcadia/design-system";
import styled, { withTheme } from "styled-components";
import { detectWeschoolImg } from "src/js/modules/exercisesFunction";
import QuizLabel from "./QuizLabel";
import { fetchResourceImageInTitle } from "./quizFunctions";
import { stripHtml } from "../../../modules/utility";
import { __, getLanguage } from "../../../modules/localization";
import { __QUIZ_ANSWER_TITLE_MAX_LENGTH__ } from "../../../settings/settings";
import { textEditorManager } from "../../../modules/textEditorManager";

const StyledInput = styled.div`
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  input[type="text"] {
    max-width: 100% !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

function validateQuiz(title = "", vote = 0) {
  if (title == "") {
    return "Attenzione, verificare i dati inseriti";
  }
  if (title.length > __QUIZ_ANSWER_TITLE_MAX_LENGTH__) {
    return "max-question-char-800";
  }
  if (!vote || vote < 1 || vote > __QUIZ_MAX_POINTS__) {
    return "quiz_vote_validation_error";
  }
  return true;
}

const updateEditor = () => {
  textEditorManager.destroy("quiz-title");
  if (getBreakpoint() !== "desktop") {
    textEditorManager.init("#quiz-title", "mobile", getLanguage());
  } else {
    textEditorManager.init("#quiz-title", "exercise", getLanguage());
  }
};

const QuizBuilderMultipleChoice = withBackbone(
  createReactClass({
    form: "#form-quiz-builder-multiplechoice",
    getInitialState() {
      const { quiz } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();
      return {
        status: "loading",
        title: quiz.get("title"),
        available_points: quiz.getAvailablePoints(),
        solutions: map(solutions, "value"),
        questions: map(questions, "value"),
        errorMessage: null
      };
    },
    componentDidMount() {
      const self = this;
      const { title } = this.state;

      if (self.state.questions.length === 0) {
        self.addEmptyOption(); // predispongo form vuoto se non ci sono valori di default
        self.addEmptyOption();
        self.addEmptyOption();
      }

      $(self.form).on("submit", e => {
        e.preventDefault();
      });

      if (title && detectWeschoolImg(title)) {
        fetchResourceImageInTitle(title)
          .then(results => {
            this.setState({ title: results, status: "" }, () => {
              updateEditor();
            });
          })
          .catch(() => {
            this.setState({ status: "" }, () => {
              updateEditor();
            });
          });
      } else {
        this.setState({ status: "" }, () => {
          updateEditor();
        });
      }

      self.initValidation();
    },
    componentDidUpdate(prevProps, prevState) {
      this.initValidation();
    },
    componentWillUnmount() {
      textEditorManager.destroy("quiz-title");
    },
    submitForm() {
      $(this.form).trigger("submit");
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        borderColorOnError: "",
        onError() {
          self.setState({
            errorMessage: "Attenzione, verificare i dati inseriti"
          });
        },
        onSuccess() {
          const title = stripHtml(
            textEditorManager.getContent(`${self.form} #quiz-title`)
          );
          const available_points = self.state.available_points
            ? self.state.available_points
            : 1;
          const passValidation = validateQuiz(title, available_points);
          if (passValidation === true) {
            self.addQuiz();
          } else {
            self.setState({ errorMessage: passValidation });
          }
          return false;
        }
      });
    },
    updateSolution(value, index) {
      const { solutions } = this.state;
      solutions[index] = value;
      this.setState({ solutions, errorMessage: null });
      this.onChangeHandle(true);
    },
    addEmptyOption() {
      const { solutions } = this.state;
      const { questions } = this.state;
      solutions.push("false");
      questions.push(this.state.defAnswer);
      this.setState({
        solutions,
        questions,
        errorMessage: null
      });
    },
    addQuiz() {
      const self = this;
      const title = textEditorManager.getContent("#quiz-title");

      this.setState({ status: "loading" });

      const questions = [];
      const newQuestions = [];
      const solutions = [];
      const newSolutions = this.state.solutions;

      $(`${self.form} input[name="question"]`).each(function (index, element) {
        const cur = $(this).val();
        newQuestions.push(cur);
      });

      const trueCount = filter(
        newSolutions,
        solution => solution == "true"
      ).length;

      if (newQuestions.length < 2) {
        this.setState({
          status: "",
          errorMessage: "Inserire almeno due risposte"
        });
      } else if (trueCount == 0) {
        this.setState({
          status: "",
          errorMessage: "Impostare almeno una risposta come vera"
        });
      } else {
        const { quiz } = this.props;

        quiz.set("type", "multichoice");
        quiz.set("title", title);
        if (!self.state.available_points) {
          quiz.set("available_points", 1);
        } else {
          quiz.set(
            "available_points",
            parseInt(self.state.available_points, 10)
          );
        }

        each(newQuestions, (q, key) => {
          const new_q = q;
          const new_s = newSolutions[key];
          questions.push({ index: key, value: new_q });
          solutions.push({ index: key, value: new_s });
        });

        quiz.set("questions", questions);
        quiz.set("solutions", solutions);

        const optionalCallbackSuccess = function () {
          this.setState({ status: "" });
          this.props.backFunc();
        };

        const optionalCallbackError = function () {
          this.setState({ status: "" });
        };
        if (typeof this.props.setChanged === "function")
          this.props.setChanged(false);
        this.props.addFunc(
          quiz,
          optionalCallbackSuccess.bind(this),
          optionalCallbackError.bind(this)
        );
      }
    },
    removeQuestion(index) {
      let { questions } = this.state;
      let { solutions } = this.state;

      questions = reject(questions, (i, j) => j === index);
      solutions = reject(solutions, (i, j) => j === index);

      this.setState({ questions, solutions });
    },
    onChangeHandle(value) {
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(value);
    },
    handleChangeQuestion(key, e) {
      const { questions } = this.state;
      questions[key] = e.target.value;
      this.setState({ questions });
      this.onChangeHandle(true); // setto come cambiato
    },
    render() {
      const self = this;
      let textEditorType = "exercise";
      const { whiteTheme } = this.props.theme;
      const { errorMessage, status } = this.state;
      let answers = "";

      if (getBreakpoint() !== "desktop") {
        textEditorType = "mobile";
      }

      const radioOption = [
        { label: __("VERO"), value: "true" },
        { label: __("FALSO"), value: "false" }
      ];

      answers = this.state.questions.map((question, key) => {
        const checked = self.state.solutions[key] || "false";

        return (
          <Wrapper key={`${question}-${key}`}>
            <StyledInput>
              <input
                name="question"
                type="text"
                className="form-control primary"
                placeholder={__("Inserisci qui una risposta")}
                defaultValue={question}
                data-validation="length"
                data-validation-length="1-200"
                data-validation-error-msg={__(
                  "Inserire una risposta (massimo 200 caratteri)"
                )}
                onBlur={self.handleChangeQuestion.bind(null, key)}
              />
            </StyledInput>
            <div className="quiz-radio-group">
              <RadioGroup
                theme={whiteTheme}
                options={radioOption}
                id={`radio-solution-true-${key}`}
                name={`solution-${key}`}
                onChangeFunction={value => self.updateSolution(value, key)}
                value={checked}
              />
            </div>
            <Box
              onClick={self.removeQuestion.bind(null, key)}
              marginLeft="auto"
              marginRight="8px"
              display="flex"
              cursor="pointer"
            >
              <Icon icon="close" height={16} width={16} />
            </Box>
          </Wrapper>
        );
      });

      return (
        <div>
          <form
            id="form-quiz-builder-multiplechoice"
            className="form-quiz-builder"
          >
            {status !== "loading" && (
              <div>
                <textarea
                  className="form-control text-editor"
                  id="quiz-title"
                  name="title"
                  defaultValue={self.state.title}
                  placeholder={__("domanda")}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </div>
            )}
            <div className="form-quiz-builder-answers-box" ref="formItem">
              <QuizLabel text="Risposte possibili" />
              {answers}
              <Box marginTop={16}>
                <Button
                  onClick={this.addEmptyOption}
                  theme={whiteTheme}
                  variant="secondary"
                >
                  {__("AGGIUNGI RISPOSTA")}
                </Button>
              </Box>
            </div>
            <div>
              <QuizLabel text="quiz_creation_vote_label" />
              <div className="point-section">
                <div className="point-input">
                  <input
                    type="number"
                    id="quiz_available_points"
                    className="form-control primary"
                    defaultValue={self.state.available_points}
                    placeholder={1}
                    ref="quiz_available_points"
                    onChange={e =>
                      this.setState({ available_points: e.target.value })
                    }
                  />
                  <div className="point-label">
                    {__("quiz_creation_point_label")}
                  </div>
                </div>
                <div className="submit-quiz spaced-button-left">
                  {errorMessage && (
                    <ContextualError
                      theme={whiteTheme}
                      text={__(errorMessage)}
                    />
                  )}
                  <Button type="submit" theme={whiteTheme} variant="primary">
                    {__("save")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  })
);

export default withTheme(QuizBuilderMultipleChoice);
