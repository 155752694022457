import React, { FC } from "react";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ClosableInfo.styles";
import { ClosableInfoProps, ClosableVariantsConst } from "./types";

const ClosableInfo: FC<ClosableInfoProps> = ({
  hasIcon = false,
  fullWidth = false,
  variant = ClosableVariantsConst.Violet,
  text,
  onClickFunction
}) => {
  return (
    <S.ClosableInfoContainer fullWidth={fullWidth} variant={variant}>
      {hasIcon ? (
        <S.ClosableInfoIcon>
          <Icon icon="info" width={16} height={16} />
        </S.ClosableInfoIcon>
      ) : null}
      <S.ClosableInfoMessage>
        <Text type="cta">{text}</Text>
      </S.ClosableInfoMessage>
      {onClickFunction ? (
        <S.ClosableInfoClose onClick={onClickFunction}>
          <Icon icon="close" width={14} height={14} />
        </S.ClosableInfoClose>
      ) : null}
    </S.ClosableInfoContainer>
  );
};

export default ClosableInfo;
