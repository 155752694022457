/**
 * Is there an active user?
 * Use cookie to log in OR load app as unlogged client otherwise
 */
import isUndefined from "lodash/isUndefined";
import each from "lodash/each";
import isFunction from "lodash/isFunction";
import { createURL } from "./utility";
import { navigateTo } from "./history";
import { commonRefreshToken } from "src/js/modules/commonRefreshToken";
import { captureException } from "@sentry/browser";
import { __API_AUTH_PATH__, __API_PATH__ } from "src/js/settings/settingsUrl";
import {
  __COOKIE_LOGIN__,
  __COOKIE_REFRESH__
} from "src/js/settings/settingsCookie";
import { destroyAccessData } from "src/js/modules/storageFunction";

let tryingToRefresh = false;

let __REFRESH_TOKEN__ = "";
let __ACCESS_TOKEN__ = "";

let calls401 = []; // Array of calls that received 401 error (may need to be repeated after new tokens fetch)

export function setupAjaxQuery() {
  $.ajaxSetup({
    beforeSend(xhr, settings) {
      // /**
      //  * Setup offline options plugin
      //  * (This plugin handle the lost connection alert)
      //  */
      // Offline.options.interceptRequests = true;
      // Offline.options = {checks: {xhr: {url: __CHECK_ONLINE_PIXEL__ + "?" + Date.now()}}};
      // Offline.check();
      /**
       * Append credentials to calls made towards our api domains
       * TODO: Use a single domain variable
       */
      const re = new RegExp(__API_PATH__, "g");
      const re2 = new RegExp(__API_AUTH_PATH__, "g");
      if (this.url.match(re) || this.url.match(re2)) {
        this.crossDomain = true;
        this.xhrFields = {
          withCredentials: true
        };
      }

      /**
       * Overwrite standard ajax error function.
       * Always check error type first.
       * If it is 401 do not execute function: app is waiting for a refresh token
       *
       */
      if (isFunction(settings.error)) {
        const errorfunc = settings.error;
        settings.error = function (jqXHR2, textStatus, errorThrown) {
          const response = jqXHR2.responseJSON;
          if (jqXHR2.status === 401 && isErrorTokenExpiredMessage(response)) {
            console.info("I did not execute a callback because I'm 401");
          } else {
            errorfunc(jqXHR2, textStatus, errorThrown);
          }
        };
      }
    },
    cache: false, // Do not cache any ajax call

    /** Handle 401 errors */
    statusCode: {
      401(xhr) {
        const response = xhr.responseJSON;

        /**
         * Only try to refresh tokens when both of the following errors are given
         */
        //if (isErrorTokenExpiredMessage(response)) {
        /**
         * If call received 401 add it to array of calls to repeat.
         * When "ignoreCall" is set to TRUE in ajax call params do NOT do this.
         * Basically, do not repeat calls related to login/tokens and everything that can lead to a loop. Repeat normal calls only
         * @param  {Boolean} this.ignoreCall (set in  $.ajax call)
         */
        if (
          tryingToRefresh &&
          (this.ignoreCall === undefined || !this.ignoreCall)
        ) {
          calls401.push(this);
        }

        // TODO: check error type. Refetch only on invalid_grant
        // appRouter.updateRoutesHistory(); //TODO: I have no idea why this is here

        if (!tryingToRefresh) {
          /** Set to true, so we don't have multiple refreshes going on at the same time */
          tryingToRefresh = true;

          commonRefreshToken()
            .catch(exception => {
              captureException(exception);
              // tryingToRefresh = false;
              // logout();
              navigateTo(createURL("logout"));
            })
            .then(tokenRefreshResponse => {
              tryingToRefresh = false;

              if (calls401.length > 0) {
                /** Repeat each pending call and reset the array */
                each(calls401, deadCall => {
                  $.ajax(deadCall);
                });
                calls401 = [];
              }

              return tokenRefreshResponse;
            });
        }
      }
    }
    // },
  });
}

function isErrorTokenExpiredMessage(response) {
  if (isUndefined(response)) return false;
  return (
    response.error === "invalid_grant" &&
    (response.error_description === "The access token provided is invalid." ||
      response.error_description === "The access token provided has expired.")
  );
}

export let returnAccessToken = function () {
  return __ACCESS_TOKEN__;
};

/** Delete stored token cookies */
export let destroyAccessToken = function () {
  __ACCESS_TOKEN__ = "";
  __REFRESH_TOKEN__ = "";
  destroyAccessData();
};
