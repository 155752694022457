import { Box, Icon, Text, colors } from "@arcadia/design-system";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";

import * as S from "./PreviewModuleCard.styles";

type PreviewModuleCardProps = {
  defaultTitle?: string;
  defaultDescription?: string;
  sortable?: boolean;
  hourlyEffort?: number;
  onSave: (title: string, description: string) => void;
  onDelete: () => void;
  onExpand: (isExpanded: boolean) => void;
  defaultExpanded?: boolean;
};

const variants = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: "fit-content" },
  exit: { opacity: 0, height: 0 },
  transition: {
    ease: "easeInOut"
  }
};

const PreviewModuleCard = ({
  defaultTitle = "",
  defaultDescription = "",
  onSave,
  hourlyEffort,
  onDelete,
  sortable,
  onExpand,
  defaultExpanded = false
}: PreviewModuleCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const { translate } = useTranslation();

  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const {
    isOpen: isEditMode,
    ref: cardRef,
    setIsOpen: setIsEditMode
  } = useCloseOnClickOutside(false);

  const focusInput = () => {
    titleRef.current.focus();
    titleRef.current.setSelectionRange(
      titleRef.current.value.length,
      titleRef.current.value.length
    );
  };

  useEffect(() => {
    if (isEditMode && titleRef?.current) {
      focusInput();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (title === defaultTitle && description === defaultDescription) return;
    onSave(title, description);
  }, [title, description]);

  const handleKeyPressEnter: React.KeyboardEventHandler<
    HTMLDivElement
  > = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      setIsEditMode(false);
    }
  };

  const shouldShowHoverItems = useMemo(
    () => isHover && !isEditMode && !isLayoutModeMobile,
    [isHover, isEditMode, isLayoutModeMobile]
  );

  return (
    <>
      {sortable ? (
        <div
          onTouchStart={() => {
            if (!isLayoutModeMobile) return;
            onExpand(false);
          }}
        >
          <S.CustomDragHandle>
            <Icon icon="drag" />
          </S.CustomDragHandle>
        </div>
      ) : null}

      <S.Wrapper
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onKeyDown={handleKeyPressEnter}
        ref={cardRef}
        data-testid="preview-module-card-testid"
      >
        {shouldShowHoverItems && (
          <S.OptionsWrapper>
            <S.TimeChip>
              <Text type="ctaTertiary">
                {`${hourlyEffort} ${translate(
                  hourlyEffort === 1 ? "hour" : "hours"
                )}`}
              </Text>
            </S.TimeChip>
            <S.Option
              title="edit"
              type="button"
              onClick={() => setIsEditMode(true)}
            >
              <Icon icon="pencil" height="16px" width="16px" />
            </S.Option>
            <S.Option title="delete" isDelete onClick={onDelete}>
              <Icon icon="trash" height="16px" width="16px" />
            </S.Option>
          </S.OptionsWrapper>
        )}
        {isLayoutModeMobile ? (
          <S.OptionsWrapper>
            <S.Option
              title="open-menu"
              type="button"
              onClick={() => onExpand(!defaultExpanded)}
            >
              <Icon
                icon={!defaultExpanded ? "chevronLargeDown" : "chevronLargeUp"}
                height="16px"
                width="16px"
              />
            </S.Option>
          </S.OptionsWrapper>
        ) : null}
        <Box display="flex">
          <S.AutoGrowTextArea maxWidth="calc(100% - 62px)">
            <S.Title
              placeholder={translate(
                "ai_course_wizard_course_preview_card_title_placeholder"
              )}
              value={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
              maxLength={60}
              rows={1}
              ref={titleRef}
              disabled={!isEditMode}
            />
            <S.HiddenGrowText fontWeight={500}>{title}</S.HiddenGrowText>
          </S.AutoGrowTextArea>
        </Box>
        <AnimatePresence>
          {(!isLayoutModeMobile || defaultExpanded) && (
            <motion.div {...(isLayoutModeMobile && { ...variants })}>
              <S.AutoGrowTextArea>
                <S.Description
                  placeholder={translate(
                    "ai_course_wizard_course_preview_card_description_placeholder"
                  )}
                  value={description}
                  rows={1}
                  maxLength={200}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                  disabled={!isEditMode}
                />
                <S.HiddenGrowText fontWeight={400}>
                  {description}
                </S.HiddenGrowText>
              </S.AutoGrowTextArea>
              {isLayoutModeMobile ? (
                <S.MobileOptions>
                  <S.MobileOption
                    type="button"
                    whileTap={{
                      backgroundColor: colors.grey[800]
                    }}
                    onClick={() => setIsEditMode(true)}
                  >
                    <Icon icon="pencil" />
                    <S.FakeIosInput
                      onClick={() => focusInput()}
                      aria-hidden="true"
                    />
                    <Text type="formField">
                      {translate(
                        "ai_course_wizard_course_preview_card_mobile_edit_cta"
                      )}
                    </Text>
                  </S.MobileOption>
                  <S.MobileOption
                    isDelete
                    type="button"
                    onClick={onDelete}
                    whileTap={{
                      backgroundColor: colors.error[800]
                    }}
                  >
                    <Icon icon="trash" />
                    <Text type="formField">
                      {translate(
                        "ai_course_wizard_course_preview_card_mobile_delete_cta"
                      )}
                    </Text>
                  </S.MobileOption>
                </S.MobileOptions>
              ) : null}
            </motion.div>
          )}
        </AnimatePresence>
      </S.Wrapper>
    </>
  );
};

export default PreviewModuleCard;
