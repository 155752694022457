import { useMemo } from "react";
import { matchPath, useLocation } from "react-router";
import { useStores } from "src/js/hooks";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { LAYOUT_BLACKLIST, SIDEBAR_BLACKLIST } from "../AppLayout.utils";

export const useAppLayoutExceptions = () => {
  const {
    WhiteboardStore: { whiteboardUrl }
  } = useStores();
  const { pathname } = useLocation();

  const shouldSidebarBeHidden = useMemo(() => {
    return SIDEBAR_BLACKLIST.some(route => matchPath(pathname, route));
  }, [SIDEBAR_BLACKLIST, pathname]);

  const shouldHideAllLayouts = useMemo(() => {
    const filteredLayoutBlacklist = !whiteboardUrl
      ? LAYOUT_BLACKLIST.filter(
          route => route !== WeSchoolRoutes.App.Spaces.SpaceGroup.whiteboard
        )
      : LAYOUT_BLACKLIST;
    return filteredLayoutBlacklist.some(route => matchPath(pathname, route));
  }, [LAYOUT_BLACKLIST, pathname, whiteboardUrl]);

  return { shouldSidebarBeHidden, shouldHideAllLayouts };
};
