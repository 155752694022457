import { captureException } from "@sentry/browser";
import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/react";

const __ERROR_MESSAGE__ = [
  "Unable to get property 'off' of undefined or null reference",
  "Cannot read property 'off' of null",
  "nol_t is not defined",
  "Can't find variable: nol_t",
  "t.$element is null",
  "null is not an object (evaluating 't.$element.off')",
  "Cannot read property '_avast_submit' of undefined",
  // Explanation of ResizeObserver: https://webkit.org/blog/9997/resizeobserver-in-webkit/
  "ResizeObserver loop limit exceeded", // Chrome
  "ResizeObserver loop completed with undelivered notifications.", // Firefox
  /* Here it explains that the two above represent the the same error in 2 different browsers:
  https://www.bountysource.com/issues/93268927-resizeobserver-loop-limit-exceeded
  Here it explains that you can safely ignore this error: 
  https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded */
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  "Script error.",
  // error generated by google tag manager script
  "posthog is not defined",
  // sws-mqtt websocket timeout error
  "Socket is closed"
];

const __IGNORED_ULRS__ = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
];

const normalize = filename => {
  // sometimes the filename is [native code]
  if (filename.indexOf("/www/") !== -1) {
    return `~/${filename.split("/www/", 2)[1]}`;
  }

  if (filename.indexOf("http://app.weschool.localhost") !== -1) {
    return `~/${filename.split("http://app.weschool.localhost", 2)[1]}`;
  }

  return filename;
};

const baseConfiguration = {
  dsn: process.env.__SENTRY_REPORTING_KEY__,
  environment: process.env.__APP_ENV__,
  whitelistUrls: [
    /https?:\/\/((app|stage|local)\.)?weschool\.com/,
    /file:\/\//,
    /webpack-internal:\/\//
  ],
  ignoreErrors: __ERROR_MESSAGE__,
  ignoreUrls: __IGNORED_ULRS__,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  integrations: [
    new Sentry.Replay(),
    new RewriteFrames({
      iteratee: frame => {
        // eslint-disable-next-line no-param-reassign
        frame.filename = normalize(frame.filename);
        return frame;
      }
    })
  ]
  // uncomment this debug and beforeSent function to debug locally
  // debug: true,
  // beforeSend: event => {
  //   console.log("beforeSend", event);
  //   return event;
  // }
};

const sentry = {
  captureException,
  init: configuration => {
    Sentry.init({ ...baseConfiguration, ...configuration });
  }
};

export default sentry;
