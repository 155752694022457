import React, { forwardRef } from "react";
import { Icon } from "@arcadia/design-system";
import { Header } from "@tanstack/react-table";
import { ScormData, ScormDataDetail } from "src/js/query/scorm";
import { HTMLMotionProps } from "framer-motion";
import * as S from "./HeaderButton.styles";

type HeaderButtonProps = HTMLMotionProps<"button"> & {
  header: Header<ScormData, any> | Header<ScormDataDetail, any>;
};

const HeaderButton = forwardRef<HTMLButtonElement, HeaderButtonProps>(
  ({ children, header, ...props }, ref) => (
    <S.Button ref={ref} {...props}>
      <S.HeaderText type="table" active={!!header.column.getIsSorted()}>
        {children}
      </S.HeaderText>
      <S.SortingCaret active={!!header.column.getIsSorted()}>
        {{
          asc: (
            <Icon
              icon="arrowFull"
              initialViewbox
              style={{ transform: "rotate(180deg)", marginLeft: "8px" }}
              width={8}
              height={8}
            />
          ),
          desc: (
            <Icon
              icon="arrowFull"
              initialViewbox
              width={8}
              height={8}
              style={{ marginLeft: "8px" }}
            />
          )
        }[header.column.getIsSorted() as string] ?? null}
      </S.SortingCaret>
    </S.Button>
  )
);

HeaderButton.displayName = "HeaderButton";
export default HeaderButton;
