import React from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  INPUT_VARIANT,
  LABEL_POSITIONS
} from "src/js/components/forms/input";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const UnderageLockForm = ({ submitFunction }) => {
  const { register, handleSubmit, errors } = useForm();
  const { whiteTheme } = useTheme();

  const __FORM_PARENT_REQUEST__ = {
    email: {
      required: "underage_lock_email_required",
      pattern: {
        value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
        message: "email_validation_error"
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(formData => submitFunction(formData))}>
      <div className="margin-top-10">
        <Input
          label="share_with_mail"
          labelDirection={LABEL_POSITIONS.FROM_TOP}
          name="email"
          id="email"
          placeholder="insert_invite_email"
          ref={register(__FORM_PARENT_REQUEST__.email)}
          hasError={errors.email}
          errorMessage={errors.email?.message}
          marginTop={10}
          variant={INPUT_VARIANT.DARK}
        />
      </div>
      <div className="margin-top-10">
        <Button
          type="submit"
          variant="primary"
          theme={whiteTheme}
          fullWidth={getBreakpoint() === "smartphone"}
        >
          <Translate text="send_button_label" />
        </Button>
      </div>
    </form>
  );
};

export default UnderageLockForm;
