import styled from "styled-components";

export const ModalHeaderWrapper = styled.div`
  position: relative;
`;

export const ModalCloseContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
`;

export const ModalTitleContainer = styled.div`
  width: 100%;
  min-height: 60px;
  font-size: 16px;
  padding: 20px 56px 20px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent3};

  @media screen and (max-width: 768px) {
    padding: 10px 56px 10px;
    min-height: 40px;
  }
`;
