import colors from "@ds_themes/colors";
import { CardSchoolThemeOptions, Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

import { createAvatarTheme } from "./avatar";
import { createCheckIndicatorTheme } from "./checkIndicator";

export const createCardTheme = (palette: Palette) => {
  return {
    card: {
      ...createCardActivityTheme(palette),
      ...createCardContentTheme(palette),
      ...createCardAddContentTheme(palette),
      ...createCardGroupTheme(palette),
      ...createCardLessonTheme(palette),
      ...createCardSchoolTheme(palette)
    }
  };
};

const createCardActivityTheme = ({ grey, general, primary }: Palette) => {
  return {
    cardactivity: {
      title: general.contrastText,
      background: general.background,
      body: {
        color: grey[500]
      },
      border: `1.5px solid ${grey[800]}`,
      check: {
        background: primary.main,
        color: primary.contrastText
      },
      checked: {
        border: `1.5px solid ${primary.main}`
      },
      focus: {
        border: `${primary.light} solid 3px`
      },
      icon: {
        background: primary.lighter,
        color: primary.main
      }
    }
  };
};

const createCardContentTheme = (palette: Palette) => {
  const { general, primary, grey, coldGrey, blueGrey } = palette;
  return {
    cardcontent: {
      title: general.contrastText,
      body: grey[300],
      background: general.background,
      border: `1px solid ${grey[800]}`,
      viewed: {
        background: getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        ),
        color: primary.contrastText
      },
      notViewed: {
        background: grey[800],
        color: grey[1000]
      },
      wrapperIcon: {
        background: primary.main,
        text: primary.contrastText,
        border: `2px solid ${colors.grey[800]}`
      },
      wrapperText: {
        background: general.background,
        title: general.contrastText,
        body: general.contrastText
      },
      comment: {
        text: primary.contrastText,
        color: getHighContrastColor(primary.main, primary.dark, primary.main)
      },
      resource: {
        background: coldGrey[900],
        text: blueGrey[300]
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};

const createCardAddContentTheme = ({
  grey,
  primary,
  general,
  coldGrey
}: Palette) => {
  return {
    cardaddcontent: {
      border: `1px solid ${colors.grey[800]}`,

      wrapperIcon: {
        background: coldGrey[900],
        text: grey[50],
        border: `2px solid ${grey[800]}`
      },
      wrapperText: {
        background: general.background,
        text: general.contrastText
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};

const createCardGroupTheme = ({ grey, general, primary }: Palette) => {
  return {
    cardgroup: {
      title: general.contrastText,
      body: {
        color: grey[300]
      },
      background: general.background,
      check: {
        background: getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        ),
        color: primary.contrastText
      },
      border: `1.5px solid ${grey[800]}`,
      checked: {
        border: `1.5px solid ${getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        )}`
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};

const createCardLessonTheme = ({ primary, general }: Palette) => {
  return {
    cardlesson: {
      background: general.background,
      text: general.contrastText,
      icon: getHighContrastColor(primary.main, primary.dark, primary.main),
      loading: {
        color: primary["main"]
      },
      cart: {
        background: getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        ),
        icon: primary.contrastText
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};

const createCardSchoolTheme = (
  palette: Palette
): { cardschool: CardSchoolThemeOptions } => {
  const { primary, general } = palette;
  const checkIndicatorTheme = createCheckIndicatorTheme(palette);
  const avatarTheme = createAvatarTheme(palette);
  return {
    cardschool: {
      title: {
        color: general.contrastText
      },
      body: {
        color: colors.grey[300]
      },
      background: general.background,
      border: `1.5px solid ${general.background}`,
      check: {
        background: getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        ),
        color: primary.contrastText
      },
      checked: {
        border: `1.5px solid ${getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        )}`
      },
      focus: {
        border: `${primary.light} solid 3px`
      },
      checkindicator: checkIndicatorTheme.checkindicator,
      avatar: avatarTheme.avatar
    }
  };
};
