import { Heading, Icon, Text } from "@arcadia/design-system";
import React, { FC, useRef, useState } from "react";
import { useTranslation } from "src/js/translation";
import {
  SchoolClaimForm,
  getLabelsSchoolClaim
} from "../../spaces/space_creation/SchoolClaimForm";
import { SchoolClaimFormStatus } from "../../spaces/space_creation/SchoolClaimForm/SchoolClaimForm.types";
import {
  ActivateAccountWrapText,
  ActivateAccountWrapContent
} from "../ActivateAccount.styles";
import * as S from "./SchoolClaimPage.styles";
import { SchoolClaimPageProps } from "./SchoolClaimPage.types";

const SchoolClaimPage: FC<SchoolClaimPageProps> = ({
  goToCreation,
  onComplete,
  backgroundColor
}) => {
  const { translate } = useTranslation();
  const formRef = useRef(null);
  const [formStatus, setFormStatus] = useState<SchoolClaimFormStatus>(
    SchoolClaimFormStatus.Selection
  );

  const initialStep = formStatus === SchoolClaimFormStatus.Selection;
  const { title, subtitle } = getLabelsSchoolClaim(formStatus);

  return (
    <ActivateAccountWrapContent>
      <ActivateAccountWrapText>
        <S.TitleHeader>
          <Icon
            icon="chevronLeft"
            onClick={() => {
              if (initialStep) {
                goToCreation();
                return;
              }
              formRef?.current?.back();
            }}
          />
          <Heading level="3">{translate(title)}</Heading>
        </S.TitleHeader>
        {subtitle && <Text type="formDescription">{translate(subtitle)}</Text>}
      </ActivateAccountWrapText>
      <S.SchoolClaimFormWrapper formStatus={formStatus}>
        <SchoolClaimForm
          ref={formRef}
          onChangeStatus={status => setFormStatus(status)}
          onSuccessCallback={onComplete}
          backgroundColor={backgroundColor}
          goToSpaceCreation={() => {}}
        />
      </S.SchoolClaimFormWrapper>
      <S.StyledStepper steps={2} currentStep={initialStep ? 1 : 2} />
    </ActivateAccountWrapContent>
  );
};

export default SchoolClaimPage;
