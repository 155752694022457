import styled, { css } from "styled-components";

export const BadgeEarnedList = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-direction: row;
  overflow: auto;
  @media (max-width: 767px) {
    flex-direction: column;
    max-height: 500px;
  }
`;

export const EarnedBadgeContainer = styled.div<{ hasLink?: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  width: 168px;
  min-width: 168px;
  height: 168px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGrey[800]};

  cursor: ${({ hasLink }) => hasLink && "pointer"};

  @media (max-width: 767px) {
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 16px;
  }
`;

export const BadgeEarnedImage = styled.img<{ isExpired?: boolean }>`
  max-width: 55px;
  max-height: 55px;
  border-radius: 4px;
  ${({ isExpired }) =>
    isExpired &&
    css`
      filter: grayscale(100%);
    `}
  @media (max-width: 767px) {
    max-width: 44px;
    max-height: 44px;
  }
`;

export const BadgeEarnedName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 767px) {
    justify-content: flex-start;
    white-space: nowrap;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
  }
`;

export const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grey[50]};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
`;

export const BadgeImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AwardedNFTIndicatorContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -8px;
  right: -8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const NFTLabelContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -12px;
  right: -12px;
  border-radius: 48px;
  padding: 4px;
`;
