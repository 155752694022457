import React, { FC, useState } from "react";
import { useTheme } from "styled-components";
import {
  __AVAILABLE_LANG__,
  __COUNTRY_PARAMS_NAME__,
  __LANGUAGE_PARAMS_NAME__,
  __FLAG_LANG__
} from "src/js/settings/settingsLocalization";
import { createLocalData, readLocalData } from "src/js/modules/storageFunction";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import URI from "urijs";
import { translateString } from "src/js/translation/TranslationProvider";
import { BottomModalSheet, Button, Modal } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { TranslationKeys } from "src/js/translation";
import { CountryLanguageModalProps } from "./CountryLanguageModal.types";
import { ModalScrollableBody } from "../modal/ModalScrollableBody";
import * as S from "./CountryLanguageModal.styles";
import { Selector } from "./Selector";

function reloadUrlWithoutParams() {
  const location = window.location.href;
  const uri = new URI(location);
  if (uri.hasQuery(__COUNTRY_PARAMS_NAME__)) {
    uri.removeSearch(__COUNTRY_PARAMS_NAME__);
    window.location.href = uri.normalize().toString();
  } else {
    window.location.reload();
  }
  if (uri.hasQuery(__LANGUAGE_PARAMS_NAME__)) {
    uri.removeSearch(__LANGUAGE_PARAMS_NAME__);
    window.location.href = uri.normalize().toString();
  } else {
    window.location.reload();
  }
}

const CountryLanguageContent: FC<CountryLanguageModalProps> = props => {
  const { whiteTheme } = useTheme();
  const [selectedLanguage, setSelectedLanguage] = useState(
    readLocalData(__COOKIE_LANG__)
  );

  const saveFunction = () => {
    const { closeModal } = props;
    if (selectedLanguage !== readLocalData(__COOKIE_LANG__)) {
      createLocalData(__COOKIE_LANG__, selectedLanguage);
      reloadUrlWithoutParams();
    }
    closeModal();
  };

  const selectLanguage = language => {
    setSelectedLanguage(language);
  };

  return (
    <S.CountryLanguageContentWrap>
      <S.CountryLanguageContentBody>
        <Selector
          list={Object.keys(__AVAILABLE_LANG__).map(lang => ({
            label: `${__FLAG_LANG__[lang]} ${translateString({
              text: __AVAILABLE_LANG__[lang] as TranslationKeys
            })}`,
            value: lang
          }))}
          onChange={selectLanguage}
          current={selectedLanguage}
        />
      </S.CountryLanguageContentBody>
      <S.CountryLanguageContentFooter>
        <Button
          onClick={saveFunction}
          fullWidth
          variant="primary"
          theme={whiteTheme}
        >
          {translateString({
            text: "country_language_modal_confirm"
          })}
        </Button>
      </S.CountryLanguageContentFooter>
    </S.CountryLanguageContentWrap>
  );
};

const CountryLanguageModal: FC<CountryLanguageModalProps> = props => {
  const { closeModal } = props;
  const { UIStore } = useStores();
  const title = translateString({
    text: "country_language_modal_title"
  });

  const [showBottomSheet, setShowBottomSheet] = useState(true);

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
    setTimeout(() => closeModal(), 500);
  };

  return UIStore.isLayoutModeMobile ? (
    <BottomModalSheet
      title={title}
      isOpen={showBottomSheet}
      onClose={closeBottomSheet}
      closeLabel={translateString({ text: "close" })}
    >
      <S.MobileWrapper>
        <CountryLanguageContent {...props} />
      </S.MobileWrapper>
    </BottomModalSheet>
  ) : (
    <Modal onClose={closeModal} title={title}>
      <ModalScrollableBody
        maxHeight={1000}
        maxHeightOffset={100}
        flexHeight={null}
        flexHeightOffset={0}
      >
        <S.CountryLanguageModalContainer>
          <CountryLanguageContent {...props} />
        </S.CountryLanguageModalContainer>
      </ModalScrollableBody>
    </Modal>
  );
};

export default CountryLanguageModal;
