import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  border-radius: 6px;
  min-height: 60px;
  background-color: ${({ theme }) => theme.eventCard.background};
  border: 1px solid
    ${({ status, theme }) =>
      status === "ongoing"
        ? theme.eventCard.onGoing.border
        : theme.eventCard.border};
  color: ${({ theme }) => theme.eventCard.text.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;

  ${({ highlightBorder, theme }) =>
    highlightBorder &&
    css`
      border: 1px solid ${theme.eventCard.highlighted.border};
    `}

  & .ws-calendar-popup {
    z-index: 890;
    overflow: visible;

    .mbsc-popup-overlay {
      display: none;
    }

    .mbsc-popup {
      user-select: auto;
      max-height: 500px !important;
      left: calc(50% - 60px) !important;
      top: -8px !important;
    }

    .mbsc-popup-arrow-wrapper {
      display: none;
    }

    .mbsc-material.mbsc-popup-body {
      border: 1px solid ${({ theme }) => theme.eventCard.border};
      border-radius: 8px;
      box-shadow:
        0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);

      .mbsc-popup-content {
        overflow: visible;
      }
    }
  }
`;

export const HourRange = styled.span`
  margin-left: 8px;
  font-size: 14px;
  white-space: nowrap;
`;

export const EventTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0 12px 0 10px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ClockWrapper = styled.span`
  min-width: 10px;
  color: ${({ theme }) => theme.eventCard.border};
`;

export const LabelText = styled.div`
  margin-left: 2px;
`;
