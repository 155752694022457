import { useMemo } from "react";
import { useLocation } from "react-router";
import { getAppRoutingContext } from "../routing/appRoutingContext";

const useAppRoutingContext = () => {
  const location = useLocation();

  const context = useMemo(
    () => getAppRoutingContext(location),
    [location.pathname]
  );

  return { context };
};

export default useAppRoutingContext;
