import styled, { css } from "styled-components";

export const AgendaPlaceholderContainer = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  height: 115px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border};
`;

export const IconContainer = styled.img`
  margin: 0 40px 0 25px;
  width: 144px;

  ${({ isToday }) =>
    isToday &&
    css`
      height: 95px;
      object-fit: cover;
    `};
`;

export const DateLabel = styled.span`
  flex: 1;
  color: ${({ theme }) => theme.text.color};
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const PictureContainer = styled.img`
  height: 100%;
  width: 137px;
  object-fit: cover;
  object-position: top;
  border-radius: 0 5px 5px 0;
`;
