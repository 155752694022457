import styled from "styled-components";

import { SPageLinkProps } from "./types";

export const PageNav = styled.nav`
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: flex-start;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const PageNavList = styled.ul`
  display: flex;
  padding-left: 0;
  margin: 0;
  width: 100%;

  @media (max-width: 767px) {
    justify-content: right;
  }
`;

export const PageItem = styled.li`
  display: inline;
`;

export const PageLink = styled.button<SPageLinkProps>`
  display: ${({ numberedPageButton, displayControl }) =>
    numberedPageButton || displayControl ? "block" : "none"};
  cursor: pointer;
  margin: 0 4px;
  padding: ${({ numberedPageButton }) =>
    numberedPageButton ? "7px 12px 7px" : "7px 16px 8px 16px"};
  text-align: center;
  color: ${({ theme }) => theme.pagenav.button.color};
  background-color: ${({ theme }) => theme.pagenav.button.background};
  border: ${({ theme }) => `1px solid ${theme.pagenav.button.border}`};
  border-radius: 20px;
  height: 40px;
  width: ${({ numberedPageButton }) => (numberedPageButton ? "40px" : "auto")};

  &[aria-selected="true"] {
    color: ${({ theme }) => theme.pagenav.button.active.color};
    background-color: ${({ theme }) => theme.pagenav.button.active.background};
    border: ${({ theme }) => `1px solid ${theme.pagenav.button.hover.border}`};
  }

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.pagenav.button.hover.border}`};
  }

  &:focus-visible {
    outline: ${({ theme }) => `3px solid ${theme.pagenav.button.focus.border}`};
  }

  ${PageItem}:first-child & {
    margin-left: 0px;
  }
  ${PageItem}:last-child & {
    margin-right: 0px;
  }

  & > span {
    padding: ${({ numberedPageButton }) =>
      !numberedPageButton ? "6px" : null};
  }
`;

export const PaginationCounter = styled.div`
  margin-right: 10px;
  display: flex;
  flex-flow: row;
  width: auto;
  min-width: 60px;

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: right;
    margin-right: 4px;
    margin-bottom: 10px;
  }
`;
