/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Route, Switch } from "react-router";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import UserAccountMobile from "src/js/pages/user/UserAccountMobile/UserAccountMobile";
import UserSettings from "src/js/pages/user/settings/UserSettings";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";

export const SettingsRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={WeSchoolRoutes.App.Settings.settingsAccountOption}
        component={UserAccountMobile}
      />
      <Route
        path={WeSchoolRoutes.App.Settings.settingsOption}
        component={UserSettings}
      />
      <Route
        render={props => (
          <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
        )}
      />
    </Switch>
  );
};
