import colors from "@ds_themes/colors";

export const sidemenu = {
  background: colors.coldGrey[900],
  text: colors.grey[50],

  selected: {
    background: colors.violet[501],
    text: colors.grey[1000]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};
