import { Text } from "@arcadia/design-system";
import moment from "moment";
import React from "react";
import useLongPress from "src/js/hooks/useLongPress";
import { TextEditorRender } from "src/js/components/TextEditorRender";
import * as S from "./ThreadItem.styles";
import { ThreadItemProps } from "./ThreadItem.types";

const ThreadItem = ({
  id,
  className,
  badge,
  title,
  notification = 0,
  isMuted,
  onClick,
  onLongPress,
  numberUsers = 0,
  lastMessageDate,
  lastMessageText
}: ThreadItemProps) => {
  const longPressEvent = useLongPress(onLongPress, () => {}, {
    shouldPreventDefault: false,
    delay: 500
  });

  return (
    <S.ThreadItemWrapper
      className={className}
      id={id}
      onClick={onClick}
      {...longPressEvent}
    >
      <S.ThreadItemBadge>
        {badge}
        {numberUsers > 1 ? (
          <S.ThreadItemBadgeUsers data-testid="thread-item-bell-badge-users">
            <Text type="formSmallCaption">{numberUsers}</Text>
          </S.ThreadItemBadgeUsers>
        ) : null}
      </S.ThreadItemBadge>
      <S.ThreadItemContainer>
        <Text type="formField">{title}</Text>
        <S.ThreadItemSubtitle>
          <TextEditorRender content={lastMessageText} plainText />
        </S.ThreadItemSubtitle>
      </S.ThreadItemContainer>
      <S.ThreadItemSide>
        {lastMessageDate ? (
          <S.ThreadItemTime>
            {moment(lastMessageDate).format("HH:mm")}
          </S.ThreadItemTime>
        ) : null}
        <S.ThreadItemSideBottom>
          {isMuted ? (
            <S.ThreadItemBellIcon data-testid="thread-item-bell-icon" />
          ) : null}
          {notification > 0 ? (
            <S.ThreadItemNotifications data-testid="thread-item-bell-notification">
              {notification}
            </S.ThreadItemNotifications>
          ) : null}
        </S.ThreadItemSideBottom>
      </S.ThreadItemSide>
    </S.ThreadItemWrapper>
  );
};

export default ThreadItem;
