import { Box, Icon, IconName } from "@arcadia/design-system";
import React from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { StateMetricSeverity, StateMetricSeverityType } from "src/js/types";
import { useTheme } from "styled-components";

const IconMetricSeverity = ({
  loading,
  metricSeverity
}: {
  loading?: boolean;
  metricSeverity?: StateMetricSeverityType;
}) => {
  const { colors } = useTheme();

  const iconSeverityMap: {
    [key in StateMetricSeverityType]: {
      icon: IconName;
      style: {
        backgroundColor: string;
        color: string;
      };
    };
  } = {
    [StateMetricSeverity.Critical]: {
      icon: "cancel",
      style: {
        backgroundColor: colors.error[900],
        color: colors.error[500]
      }
    },
    [StateMetricSeverity.Warning]: {
      icon: "error",
      style: {
        backgroundColor: colors.yellow[800],
        color: colors.yellow[300]
      }
    },
    [StateMetricSeverity.Normal]: {
      icon: "checkCircle",
      style: {
        backgroundColor: colors.success[900],
        color: colors.success[400]
      }
    }
  };

  if (loading) {
    return (
      <Skeleton
        loading={loading}
        borderRadius={8}
        minWidth={32}
        minHeight={32}
      />
    );
  }

  if (!metricSeverity) {
    return null;
  }

  const iconSeverityObj = iconSeverityMap[metricSeverity];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minWidth={32}
      minHeight={32}
      borderRadius={8}
      {...iconSeverityObj.style}
    >
      <Icon
        initialViewbox
        icon={iconSeverityObj.icon}
        color={iconSeverityObj.style.color}
        width={16}
        height={16}
      />
    </Box>
  );
};

export default IconMetricSeverity;
