import { DefaultTheme } from "styled-components";
import colors from "@ds_themes/colors";
import {
  primary as ButtonPrimary,
  secondary as ButtonSecondary,
  tertiary as ButtonTertiary,
  danger as ButtonDanger
} from "@ds_themes/grey/button";
import {
  primary as ActionButtonPrimary,
  secondary as ActionButtonSecondary,
  secondaryColored as ActionButtonSecondaryColored,
  tertiary as ActionButtonTertiary
} from "@ds_themes/grey/actionButton";
import { link as Link } from "@ds_themes/grey/link";
import { ctaTertiary as CtaTertiary } from "@ds_themes/grey/ctaTertiary";
import { checkbox as Checkbox } from "@ds_themes/grey/checkbox";
import { radiogroup as RadioGroup } from "@ds_themes/grey/radiogroup";
import { toggle as Toggle } from "@ds_themes/grey/toggle";
import { picker as Picker } from "@ds_themes/grey/picker";
import { doodleloader as DoodleLoader } from "@ds_themes/grey/doodleloader";
import { contentswitcher as ContentSwitcher } from "@ds_themes/grey/contentswitcher";
import { sidemenu as Sidemenu } from "@ds_themes/grey/sidemenu";
import { dropdown as Dropdown } from "@ds_themes/grey/dropdown";
import { input as Input } from "@ds_themes/grey/input";
import {
  cardlesson as CardLesson,
  cardschool as CardSchool
} from "@ds_themes/grey/card";
import { sidebarItem as SidebarItem } from "@ds_themes/grey/sidebarItem";
import { pagenav as PageNav } from "@ds_themes/grey/pagenav";
import { searchbar as SearchBar } from "@ds_themes/grey/searchbar";
import { searchbarWithSuggestion as SearchbarWithSuggestion } from "@ds_themes/grey/searchbarWithSuggestion";
import { liveduration as LiveDuration } from "@ds_themes/grey/liveduration";
import { toast as Toast } from "@ds_themes/grey/toast";
import { avatar as Avatar } from "@ds_themes/grey/avatar";
import { checkindicator as CheckIndicator } from "@ds_themes/grey/checkindicator";

const greyTheme = {
  background: colors.coldGrey[900],
  avatar: Avatar,
  text: colors.grey[50],
  button: {
    primary: ButtonPrimary,
    secondary: ButtonSecondary,
    tertiary: ButtonTertiary,
    danger: ButtonDanger
  },
  actionButton: {
    primary: ActionButtonPrimary,
    secondary: ActionButtonSecondary,
    secondaryColored: ActionButtonSecondaryColored,
    tertiary: ActionButtonTertiary
  },
  card: {
    cardlesson: CardLesson,
    cardschool: CardSchool
  },
  link: Link,
  liveduration: LiveDuration,
  ctaTertiary: CtaTertiary,
  checkbox: Checkbox,
  checkindicator: CheckIndicator,
  radiogroup: RadioGroup,
  input: Input,
  toggle: Toggle,
  picker: Picker,
  doodleloader: DoodleLoader,
  contentswitcher: ContentSwitcher,
  sidebarItem: SidebarItem,
  sidemenu: Sidemenu,
  dropdown: Dropdown,
  pagenav: PageNav,
  searchbar: SearchBar,
  searchbarWithSuggestion: SearchbarWithSuggestion,
  toast: Toast
  // TODO: fix typings
} as unknown as DefaultTheme;

export default greyTheme;
