import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import videojs from "video.js";
import "videojs-youtube";
import filter from "lodash/filter";
import indexOf from "lodash/indexOf";
import each from "lodash/each";
import isFunction from "lodash/isFunction";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { Button, ConfirmationAlert } from "@arcadia/design-system";
import { Translate } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";
import { formatElapsedTime } from "../../../modules/utility";
import { agentManager } from "../../../components/agentManager";
import ExerciseEngineQuiz from "./exerciseEngine_quiz_base";

const QuizVideo = withBackbone(
  createReactClass({
    getInitialState() {
      const { quiz } = this.props;
      const quizzes = new QuizCollection(quiz.getQuizzes());
      const quizzesIds = quizzes.map("id");
      let timings = quiz.getTimings();

      timings = sortBy(timings, timing => timing.time);
      timings = filter(timings, t => indexOf(quizzesIds, t.quiz_id) >= 0);

      return {
        quizzes,
        timings,
        current_quiz: 0,
        currentTime: 0,
        video: undefined,
        shouldAutoQuiz: true,
        status: "",
        btnStatus: "",
        techOrder:
          this.props.quiz.getVideoType() === "VIMEO" ? "vimeo" : "youtube",
        playerParams: {
          controls: { muteToggle: false },
          techOrder: ["youtube"],
          sources: [{ type: "video/youtube", src: this.getUrlVideo() }],
          youtube: { playsinline: 1, rel: 0 }
        }
      };
    },
    componentDidMount() {
      this.setupVideo();
    },
    componentDidUpdate() {
      if (typeof this.state.video === "undefined") this.setupVideo();
      if (
        this.state.status === "quizzing" &&
        this.getCurrentQuiz() !== undefined
      ) {
        $(".app__fixed-footer:not(.js-videoquiz__fixed-footer)").hide();
      } else {
        $(".app__fixed-footer:not(.js-videoquiz__fixed-footer)").show();
      }
    },
    componentWillUnmount() {
      videojs("vid1").dispose();
    },
    destroyVideo() {
      // di base non fa nulla
      // quando inizalizzo il video la modifico
    },
    getUrlVideo() {
      const video_id = this.props.quiz.getVideoId();
      let src = `https://www.youtube.com/watch?v=${video_id}`;
      if (this.props.quiz.getVideoType() === "VIMEO") {
        src = `https://vimeo.com/${video_id}?api=1&amp;player_id=vid1&amp;fullscreen=0`;
      }
      return src;
    },
    setupVideo() {
      const self = this;
      videojs("vid1").ready(function () {
        self.onReadyVideo(this);
      });
    },
    onReadyVideo(video) {
      const self = this;
      self.setState(
        {
          video
        },
        () => {
          video.on("timeupdate", self.onCurrentTime);
          video.on("pause", self.onPauseVideo);
          self.destroyVideo = function () {
            video.off("timeupdate", self.onCurrentTime);
            video.dispose();
          };
        }
      );
    },
    onCurrentTime() {
      const self = this;
      const newCurrentTime = Math.floor(self.state.video.currentTime());
      if (newCurrentTime !== this.state.currentTime) {
        this.setState(
          {
            currentTime: newCurrentTime
          },
          () => {
            if (self.state.shouldAutoQuiz) {
              self.seekQuizByTime(self.state.currentTime);
            }
          }
        );
      }
    },
    seekQuizByTime(time) {
      const videoquiz = this.props.quiz;
      let timing;
      each(this.state.timings, t => {
        if (parseInt(t.time, 10) === time) timing = t;
      });
      if (timing !== undefined) {
        if (videoquiz.checkInnerQuizWasShown(timing.quiz_id) === false) {
          this.showQuiz(timing);
          videoquiz.addShownInnerQuiz(timing.quiz_id);
        }
      }
    },
    onPauseVideo() {
      const self = this;
      if (typeof self.state.video.webkitExitFullscreen === "function") {
        self.state.video.webkitExitFullscreen();
      }
      if (typeof self.state.video.mozCancelFullScreen === "function") {
        self.state.video.mozCancelFullScreen();
      }
      if (typeof document.webkitExitFullscreen === "function") {
        document.webkitExitFullscreen();
      }
      if (
        typeof document.getElementById("vid1").webkitExitFullscreen ===
        "function"
      ) {
        document.getElementById("vid1").webkitExitFullscreen();
      }
      if (
        typeof document.getElementById("vid1").webkitExitFullScreen ===
        "function"
      ) {
        document.getElementById("vid1").webkitExitFullScreen();
      }
      if (typeof document.webkitCancelFullScreen === "function") {
        document.webkitCancelFullScreen();
      }
      if (typeof document.msExitFullscreen === "function") {
        document.msExitFullscreen();
      }
    },
    playVideo() {
      if (typeof this.state.video !== "undefined") {
        $("#vid1").css("opacity", 1);
        this.state.video.play();
      }
    },
    pauseVideo() {
      if (typeof this.state.video !== "undefined") {
        this.state.video.pause();
      }
    },
    currentTimeVideo(time) {
      if (typeof this.state.video !== "undefined") {
        this.state.video.currentTime(time);
      }
    },
    setAnswer(e) {
      const answer = e.target.value;
      this.props.quiz.setUserAnswer(answer);
    },
    saveInnerQuiz(quiz) {
      const self = this;
      const callback_ok = function () {
        this.state.quizzes.add(quiz, { merge: true });
        this.props.quiz.setInnerAnswer(quiz.get("id"), quiz.getUserAnswer());
        this.setState(
          {
            status: "playing",
            current_quiz: 0
          },
          () => {
            $("#vid1").css("opacity");
            if (self.state.shouldAutoQuiz) {
              self.playVideo();
            }
          }
        );
      };

      this.stopInnerQuiz(callback_ok.bind(this));
    },
    showQuiz(timing) {
      const self = this;
      const wanted_quiz = this.state.quizzes.get(timing.quiz_id);
      if (
        wanted_quiz !== undefined &&
        timing.quiz_id !== this.state.current_quiz
      ) {
        this.pauseVideo();
        this.currentTimeVideo(timing.time);

        // carico risposte utente
        // non sono presenti nel modello dei sottoquiz, ma devo prenderle direttamente dal quiz video
        const answers = this.props.quiz.getInnerAnswers();
        let answer = "";
        if (typeof answers[timing.quiz_id] !== "undefined")
          answer = answers[timing.quiz_id];
        wanted_quiz.setUserAnswer(answer);

        const callback_ok = function () {
          this.setState(
            {
              status: "quizzing"
            },
            () => {
              $("#vid1").css("opacity", 0.05);
              /* adjust dimensions on show */
              $("html, body").animate({
                scrollTop: $(".videoquiz__container").offset().top
              });
            }
          );
        };

        this.setState(
          {
            current_quiz: timing.quiz_id
          },
          () => {
            self.startInnerQuiz(callback_ok.bind(self));
          }
        );
      }
    },
    setInitialQuizAnswer(answer) {
      this.initialQuizAnswer = answer;
    },
    startInnerQuiz(callback_ok) {
      this.pauseVideo();
      const self = this;
      const quiz = this.state.quizzes.get(this.state.current_quiz);
      this.setInitialQuizAnswer(quiz.getUserAnswer()); // salvo risposta data in precedenza dall'utente (anche se vuota)

      if (quiz.hasSession()) {
        // controllo se quiz è già stato inizializzato
        // bon, sono a posto
        if (isFunction(callback_ok)) callback_ok();
      } else {
        const data = JSON.stringify({
          exercise_execution_id: this.props.exercise.getSession().id,
          quiz_video_execution_id: this.props.quiz.getSession().id
        });
        const quizExecution = self.props.exercise.getQuizExecution();
        quiz.setSession(quizExecution);
        self.state.quizzes.add(quiz, { merge: true }); // aggiorno dati nella collection
        self.props.quiz.setQuizzes(self.state.quizzes.toJSON()); // aggiorno modello iniziale dei quiz, altrimenti perdo dati sulla sessione dei sottoquiz

        if (isFunction(callback_ok)) callback_ok(); // se server non risponde mando comunque avanti
      }
    },
    stopInnerQuiz(callback_ok, callback_error) {
      const self = this;
      const quiz = this.state.quizzes.get(this.state.current_quiz);
      let result = false;
      if (quiz.hasSession()) {
        // controllo se quiz è già stato inizializzato (in teoria a questo punto dovrebbe esserlo sempre)
        if (
          quiz.checkUserAnswer(this.props.exercise.get("success_threshold"))
        ) {
          result = true;
        }
        const data = {
          id: quiz.id,
          result,
          answers: quiz.getFormattedUserAnswer(),
          parent_id: self.props.quiz.get("id")
        };

        // console.log(quiz);
        self.props.exercise.saveInnerExecution(data);

        self.setState({ quizStatus: "" });
        if (isFunction(callback_ok)) callback_ok();
      }
    },
    getCurrentQuiz() {
      const currentQuiz = this.state.quizzes.get(this.state.current_quiz);
      return currentQuiz;
    },
    checkQuiz(force) {
      const self = this;
      const forceNext = !!(force !== undefined && force === true);
      const quiz = this.getCurrentQuiz();

      if (quiz.isFullyAnswered() || forceNext) {
        this.setState({ btnStatus: "loading" });

        const callback_ok = function () {
          // da eseguire dopo eventuale correzione
          this.setState({
            status: "running",
            btnStatus: ""
          });

          if (quiz.needsReview()) {
            this.showFeedback("REVIEW");
          } else {
            this.showFeedback("CORRECT");
          }
          this.saveInnerQuiz(quiz);
        }.bind(this);

        const callback_error = function () {
          this.setState(
            {
              status: "running",
              btnStatus: ""
            },
            () => {
              self.showFeedback("WRONG");
              self.saveInnerQuiz(quiz);
            }
          );
        }.bind(this);

        if (this.props.exercise.isStepByStep() && !forceNext) {
          if (this.props.exercise.isCorrectedInBackend()) {
            this.stopInnerQuiz(callback_ok, callback_error);
          } else if (
            quiz.checkUserAnswer(this.props.exercise.get("success_threshold"))
          ) {
            this.stopInnerQuiz(callback_ok, callback_error);
          } else {
            this.stopInnerQuiz(callback_error, callback_error);
          }
        } else {
          this.stopInnerQuiz(callback_ok, callback_error);
        }
      } else {
        const { ConfirmDialogStore, theme } = self.props;
        const { whiteTheme } = theme;
        let copyAlert = __("answer_not_given_forward_alert");

        if (this.props.exercise.isVerification()) {
          copyAlert = __("answer_examination_not_given_forward_alert");
        }

        ConfirmDialogStore.openConfirmDialog(
          <ConfirmationAlert
            theme={whiteTheme}
            text={copyAlert}
            declineText={__("Annulla")}
            onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
            acceptText={__("Conferma")}
            onAcceptFunction={() => {
              self.checkQuiz(true);
              ConfirmDialogStore.closeConfirmDialog();
            }}
          />
        );
      }
    },
    showFeedback(result) {
      // result = CORRECT || WRONG || REVIEW
      const $el = $(".exen__feedback-wrapper");
      if (this.props.exercise.isExercise()) {
        if (result === "CORRECT") {
          // esercizio corretto
          $el.html("");
          $el.html(
            `<div class='exen__feedback exen__feedback-success'><span class='exen__feedback-text'>${__(
              "WELL DONE!"
            )}</span></div>`
          );
        } else if (result === "REVIEW") {
          // esercizio in attesa di correzione
          $el.html("");
          $el.html(
            `<div class='exen__feedback exen__feedback-review'><span class='exen__feedback-text'>${__(
              "risposta inviata"
            )}</span></div>`
          );
        } else {
          // esercizio errato
          $el.html("");
          $el.html(
            `<div class='exen__feedback exen__feedback-error'><span class='exen__feedback-text'>${__(
              "OUCH!"
            )}</span></div>`
          );
        }
        setTimeout(() => {
          $(".exen__feedback").fadeOut(200, () => {
            $el.html("");
          });
        }, 1000);
      }
    },
    render() {
      const self = this;
      const videoquiz = this.props.quiz;
      const current_quiz = this.getCurrentQuiz();
      let quizContainer = null;
      let buttonContainer = null;
      const videoUrl = self.getUrlVideo();

      let addStyle = "";
      if (this.state.status === "quizzing") {
        addStyle = "quizzing";
      } else {
        addStyle = "";
      }

      if (this.state.status === "quizzing" && current_quiz !== undefined) {
        quizContainer = (
          <div className="videoquiz__container-wrapper">
            <div className="videoquiz__container">
              <ExerciseEngineQuiz
                quiz={current_quiz}
                quizStatus=""
                key={`quiz${current_quiz.get("id")}`}
              />
            </div>
          </div>
        );

        /* Higher z-index set for good measure. Standard app footer should be already hidden by javascript */
        buttonContainer = (
          <div
            className="app__fixed-footer js-videoquiz__fixed-footer"
            style={{ zIndex: "101" }}
          >
            <Button
              onClick={this.checkQuiz}
              theme={this.props.theme.whiteTheme}
              variant="primary"
            >
              <Translate text="continua" />
            </Button>
          </div>
        );
      }

      const quizzesSummary = (
        <div className="text-center">
          {map(this.state.timings, (timing, key) => {
            let selected = false;
            let shown = false;
            if (
              self.state.status === "quizzing" &&
              current_quiz !== undefined
            ) {
              if (timing.quiz_id === current_quiz.get("id")) selected = true;
            }
            if (videoquiz.checkInnerQuizWasShown(timing.quiz_id)) shown = true;
            return (
              <a
                className={[
                  "videoquiz__label",
                  "clickable",
                  shown ? "is-shown" : "",
                  selected ? "is-active" : ""
                ].join(" ")}
                onClick={self.showQuiz.bind(null, timing)}
                title={__("Vai alla domanda")}
              >
                {formatElapsedTime(timing.time)}
              </a>
            );
          })}
        </div>
      );
      return (
        <div className="videoquiz relative">
          {agentManager.isWindowsPhone ? (
            <div className="alert alert-warning">
              <strong>{__("attenzione").toUpperCase()}</strong>
              <p>{__("video_quiz_autostop_not_available")}</p>
            </div>
          ) : null}
          <video
            id="vid1"
            className={`quiz__videojs video-js vjs-default-skin ${addStyle}`}
            playsInline
            webkit-playsinline
            width="640"
            height="360"
            data-setup={`{"controls": { "muteToggle": false },"techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "${videoUrl}"}],"youtube": { "playsinline" : 1,"webkit-playsinline":1,"rel": 0}}`}
          />

          {this.state.status === "quizzing" && current_quiz !== undefined
            ? quizContainer
            : quizzesSummary}
          {buttonContainer}
        </div>
      );
    }
  })
);

export default withTheme(withConfirmDialogStore(QuizVideo));
