import { Avatar } from "@arcadia/design-system";
import styled from "styled-components";

export const AvatarThreadItem = styled(Avatar)`
  width: 28px;
  height: 28px;
`;

export const SidebarThreadListWrapper = styled.ul`
  all: unset;
`;
