/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import fetchActivity from "src/js/repository/activityRepository";
import { __ACTIVITY_LIST_LIMIT__ } from "src/legacy/settings/settings";
import { doRouteBack } from "../modules/history";
import { __ } from "../modules/localization";
import { basePageModel } from "../modules/pageStatus";
import ActivityPage from "../view/activity/activityPage";
import NewCustomActivity from "../view/activity/newCostumActivityView";

const ActivityController = ({ group }) => {
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState(null);

  const getActivity = async groupId => {
    let activity;
    const fetchActivityData = async (
      paginationStart = 0,
      limit = __ACTIVITY_LIST_LIMIT__,
      scope = "all"
    ) => {
      const { data, hasNext, userStatistics } = await fetchActivity(
        groupId,
        paginationStart,
        limit,
        scope
      );
      // used to display the skeleton
      setActivityData(prevActivityData => {
        if (prevActivityData === null) {
          return data;
        }
        return prevActivityData;
      });
      if (!activity) {
        activity = data;
      } else {
        activity.user_statistics =
          activity.user_statistics.concat(userStatistics);
      }
      if (hasNext) {
        await fetchActivityData(paginationStart + limit);
      }
    };
    await fetchActivityData();
    return activity;
  };

  useEffect(() => {
    if (group.id && !loading) {
      setLoading(true);
      getActivity(group.id)
        .then(activity => setActivityData(activity))
        .finally(() => setLoading(false));
    }
    return () => {
      setActivityData(null);
    };
  }, [group]);

  return (
    <Switch>
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Activity.new}
        render={props => {
          basePageModel.set("pageTitle", group.get("name"));

          const title = __("create_new_costum_activity");
          const back = __("new_costum_activity_back_label");
          let buttons;

          const back_function = function () {
            doRouteBack();
          };

          basePageModel.set({
            selectedTab: "custom",
            backFunc: {
              back,
              back_function,
              title,
              buttons
            }
          });
          return <NewCustomActivity {...props} />;
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Activity.mode}
        render={props => (
          <ActivityPage
            group={group}
            loading={loading}
            activityData={activityData}
            {...props}
          />
        )}
      />

      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  );
};

export default ActivityController;
