import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import {
  Dropdown,
  ActionButton,
  Text,
  DoodleLoader
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { hasVerticalScrollbar } from "src/js/modules/commonFunction";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { DROPDOWN_OPTIONS } from "./ManageGroupsTable.const";
import * as S from "./ManageGroupsTable.styles";

const ManageGroupItem = ({
  group,
  hasError = false,
  hasScrollbar = false,
  changeRole = () => {},
  toggleEnabled = () => {},
  whiteTheme,
  greyTheme
}) => {
  const { groupId, groupName, roles } = group;
  const [removed, setRemoved] = useState(false);
  const [role, setRole] = useState(
    roles.includes("ROLE_TEACHER") ? "ROLE_TEACHER" : "ROLE_STUDENT"
  );

  return (
    <S.ItemContainer hasScrollbar={hasScrollbar}>
      <S.ItemDescription hasError={hasError}>
        <S.GroupNameWrapper disabled={removed}>
          <Text type="table">{groupName}</Text>
        </S.GroupNameWrapper>
        <S.DropdownWrapper>
          <Translator>
            {({ translate }) => (
              <Dropdown
                id="subject"
                theme={whiteTheme}
                optionsList={DROPDOWN_OPTIONS.map(option => ({
                  id: option.key,
                  label: translate(option.value)
                }))}
                selectedOptionId={role}
                setSelectedOptionId={newRole => {
                  changeRole(newRole, groupId).then(() => {
                    setRole(newRole);
                  });
                }}
                disabled={removed}
                placeholder={translate("spaces_users_filter_role")}
              />
            )}
          </Translator>
        </S.DropdownWrapper>
        <S.ItemAction>
          <ActionButton
            theme={greyTheme}
            variant="tertiary"
            onClick={() => {
              toggleEnabled(!removed, groupId);
              setRemoved(!removed);
            }}
            icon={removed ? "undo" : "trash"}
          />
        </S.ItemAction>
      </S.ItemDescription>
    </S.ItemContainer>
  );
};

const ManageGroupsTable = ({
  groups,
  changeRole,
  toggleEnabled,
  errorGroupsId = [],
  showNext = () => {},
  hasNext = false
}) => {
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const { whiteTheme, greyTheme } = useTheme();
  useEffect(() => {
    const scrollableElement = document.getElementById("manage-group-table");
    setHasScrollbar(hasVerticalScrollbar(scrollableElement));
  }, [groups]);

  return (
    <>
      <S.TableHeader>
        <S.TableHeaderName>
          <Text type="label">
            <Translate text="spaces_users_manage_groups_header_name" />
          </Text>
        </S.TableHeaderName>
        <S.TableHeaderRole>
          <Text type="label">
            <Translate text="spaces_users_manage_groups_header_role" />
          </Text>
        </S.TableHeaderRole>
        <S.TableHeaderRemove>
          <Text type="label">
            <Translate text="spaces_users_manage_groups_header_remove" />
          </Text>
        </S.TableHeaderRemove>
      </S.TableHeader>
      <S.TableBody>
        <ScrollableDiv id="manage-group-table">
          <InfiniteScroll
            scrollableTarget="manage-group-table"
            style={{ overflow: "visible" }}
            dataLength={groups.length}
            next={showNext}
            hasMore={hasNext}
            loader={<DoodleLoader theme={whiteTheme} isMini />}
          >
            {groups.map(group => (
              <ManageGroupItem
                key={group.groupId}
                group={group}
                changeRole={changeRole}
                toggleEnabled={toggleEnabled}
                hasError={errorGroupsId.includes(group.groupId)}
                hasScrollbar={hasScrollbar}
                whiteTheme={whiteTheme}
                greyTheme={greyTheme}
              />
            ))}
          </InfiniteScroll>
        </ScrollableDiv>
      </S.TableBody>
    </>
  );
};

export default ManageGroupsTable;
