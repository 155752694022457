import React, { useMemo, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  Active,
  MeasuringStrategy
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates
} from "@dnd-kit/sortable";

import { DragHandle, SortableItem, SortableOverlay } from "./components";
import { BaseItem, SortableListProps } from "./SortableList.types";
import * as S from "./SortableList.styles";

const SortableList = <T extends BaseItem>({
  items,
  onChange,
  renderItem
}: SortableListProps<T>) => {
  const [activeElement, setActiveElement] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find(item => item.id === activeElement?.id),
    [activeElement, items]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always
    }
  };

  return (
    <DndContext
      autoScroll
      sensors={sensors}
      measuring={measuringConfig}
      onDragStart={({ active }) => {
        setActiveElement(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActiveElement(null);
      }}
      onDragCancel={() => {
        setActiveElement(null);
      }}
    >
      <SortableContext items={items}>
        <S.SortableList role="application">
          {items.map(item => (
            <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
          ))}
        </S.SortableList>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;

export default SortableList;
