import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const TabBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Tab = styled.div<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.primaryColor[500] : theme.colors.grey[800]};
  cursor: pointer;
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const TabCountPill = styled(motion.div)<{ active?: boolean }>`
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${({ theme, active }) =>
    active ? theme.primaryColor[500] : theme.colors.grey[800]};
`;

export const TabCountText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const UnderLine = styled(motion.div)<{ underLineDistance: number }>`
  left: 0px;
  right: 0px;
  height: 1px;
  border-radius: 1px;
  position: absolute;
  bottom: ${({ underLineDistance }) => underLineDistance}px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;
