import { ToolbarSpaceItem, Palette } from "@ds_themes/types";

export const createToolbarSpaceItemTheme = ({
  primary,
  grey
}: Palette): { toolbarspaceitem: ToolbarSpaceItem } => {
  return {
    toolbarspaceitem: {
      border: "transparent",
      selected: {
        border: primary.main
      },
      counter: {
        backgroundColor: primary.main,
        textColor: grey[1000],
        border: `1px solid ${grey[1000]}`
      }
    }
  };
};
