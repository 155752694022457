import { useMemo } from "react";
import { useTranslation } from "src/js/translation";
import { FilterProps } from "../types";

const useFilterSetter = ({ filters, onChange }: FilterProps) => {
  const { translate } = useTranslation();

  const contextOptions = useMemo(
    () => [
      {
        id: "wall",
        label: translate({ text: "search_type_dropdown_wall_option_label" }),
        selected: filters?.wall
      },
      {
        id: "exercise",
        label: translate({
          text: "search_type_dropdown_exercise_option_label"
        }),

        selected: filters?.exercise
      },
      {
        id: "module",
        label: translate({ text: "search_type_dropdown_module_option_label" }),
        selected: filters?.module
      }
    ],
    [filters.wall, filters.module, filters.exercise, translate]
  );

  const setContextFilter = (arrayValues: ["wall" | "module" | "exercise"]) => {
    const context = {
      wall: arrayValues.includes("wall"),
      module: arrayValues.includes("module"),
      exercise: arrayValues.includes("exercise")
    };

    const newFilters = { ...filters, ...context };

    onChange(newFilters);
  };

  const setGroupFilter = (groupId?: string) => {
    const newFilters = {
      ...filters,
      groupFilter: groupId ? [groupId] : undefined
    };

    onChange(newFilters);
  };

  const setSpaceFilter = (spaceId?: string) => {
    const newFilters = {
      ...filters,
      spaceFilter: spaceId ? [spaceId] : undefined
    };

    onChange(newFilters);
  };

  return {
    contextOptions,
    setContextFilter,
    setGroupFilter,
    setSpaceFilter
  };
};

export default useFilterSetter;
