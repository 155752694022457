import styled, { css } from "styled-components";

import { SButtonBasicProps, SButtonProps } from "./types";

export const buttonBasic = css<SButtonBasicProps>`
  background: ${({ theme, variant }) => theme.button[variant].background};
  color: ${({ theme, variant }) => theme.button[variant].text};
  border: ${({ theme, variant }) => theme.button[variant].border};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  &:hover:not([disabled]) {
    background: ${({ theme, variant }) =>
      theme.button[variant].hover.background};
    color: ${({ theme, variant }) => theme.button[variant].hover.text};
    cursor: pointer;
  }
  &:hover svg {
    color: ${({ theme, variant }) => theme.button[variant].hover.text};
  }

  &:disabled {
    background: ${({ theme, variant }) =>
      theme.button[variant].disabled.background};
    color: ${({ theme, variant }) => theme.button[variant].disabled.text};
    border: ${({ theme, variant }) => theme.button[variant].disabled.border};
    cursor: not-allowed;
  }
  &:disabled svg {
    color: ${({ theme, variant }) => theme.button[variant].disabled.text};
  }

  &:focus-visible:not([disabled]) {
    outline: ${({ theme, variant }) => theme.button[variant].focus.border};
  }
`;

// TODO: refactor gap
export const Button = styled.button<SButtonProps>`
  ${buttonBasic}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 25px;
  box-sizing: border-box;
  border-radius: 20px;
  flex-direction: ${({ withIcon = {} }) =>
    withIcon.positionIcon === "left" ? "row-reverse" : "row"};
  gap: ${({ withIcon = {} }) => (withIcon.icon === "stripe" ? "6px" : "11px")};
`;
