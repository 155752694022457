import { Popup } from "@mobiscroll/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutSide } from "src/js/hooks";
import { CalendarEventDetail } from "src/js/pages/calendar/CalendarEventDetail";
import { Translate } from "src/js/translation/TranslationProvider";

import { agenda } from "#themes/grey/agenda";
import { Icon } from "@arcadia/design-system";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { ThemeProvider } from "styled-components";
import { EventCategory } from "src/js/pages/calendar/Calendar.types";
import { CalendarTag } from "src/js/components/Calendar/CalendarTag";
import {
  getDatesUtils,
  getEventStatus
} from "src/js/pages/calendar/CalendarUtils";
import { format } from "date-fns";
import * as S from "./AgendaEventCard.styles";

const AgendaEventCard = ({
  id,
  type,
  title,
  allDay = false,
  start,
  end,
  groupId,
  eventDetail = {},
  selectedEventId,
  selectedEventAgendaKey,
  setSelectedEventAgendaKey,
  agendaDate,
  userIsTeacher = false,
  openPopupCallback = () => {},
  openRecipientsModal = () => {},
  openResource = () => {},
  deleteCalendarEvent = () => {},
  editCalendarEvent = () => {},
  copyCalendarEvent = () => {}
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const CardContainerRef = useRef();
  useOnClickOutSide(CardContainerRef, () => {
    setSelectedEventAgendaKey(null);
    setPopupOpen(false);
  });

  useEffect(() => {
    if (
      selectedEventId === id &&
      selectedEventAgendaKey === agendaDate &&
      !isEmptyObject(eventDetail)
    ) {
      setPopupOpen(true);
    }
    return () => {
      setPopupOpen(false);
    };
  }, [id, selectedEventId, eventDetail]);

  const { startDate, endDate, dateNow, hasSameTime } = getDatesUtils({
    start,
    end,
    allDay
  });

  const status = getEventStatus({
    dateNow,
    endDate,
    startDate,
    eventType: type
  });

  const handleCardClick = useCallback(() => {
    if (isEmptyObject(eventDetail)) {
      openPopupCallback({
        id,
        eventDetail,
        agendaDate
      });
    } else {
      setPopupOpen(true);
    }
  }, []);

  const deleteAndClosePopup = useCallback(eventDetailId => {
    deleteCalendarEvent(eventDetailId);
    setPopupOpen(false);
  }, []);

  const editAndClosePopup = useCallback(detail => {
    editCalendarEvent(detail);
    setPopupOpen(false);
  }, []);

  const copyAndClosePopup = useCallback(eventDetail => {
    copyCalendarEvent(eventDetail);
    setPopupOpen(false);
  }, []);

  return (
    <ThemeProvider theme={agenda}>
      <S.CardContainer
        status={type === EventCategory.Live && status}
        onClick={() => handleCardClick()}
        ref={CardContainerRef}
        highlightBorder={popupOpen}
      >
        <CalendarTag type={type} status={status} />
        <S.EventTitle>{title}</S.EventTitle>
        <S.ClockWrapper>
          <Icon icon="clock" height="10" width="10" />
        </S.ClockWrapper>
        <S.HourRange>
          {allDay ? (
            <Translate text="event_card_all_day" />
          ) : (
            <>
              {format(startDate, "HH:mm")}
              {!hasSameTime && ` - ${format(endDate, "HH:mm")}`}
            </>
          )}
        </S.HourRange>
        {popupOpen && (
          <Popup
            display="anchored"
            width={330}
            maxHeight={430}
            contentPadding={false}
            touchUi={false}
            scrollLock={false}
            theme="material"
            cssClass="ws-calendar-popup"
            isOpen={popupOpen}
            onClose={() => setPopupOpen(false)}
            context={CardContainerRef?.current}
          >
            <CalendarEventDetail
              eventDetail={eventDetail}
              groupId={groupId}
              userIsTeacher={userIsTeacher}
              openRecipientsModal={openRecipientsModal}
              openResource={openResource}
              deleteFunction={deleteAndClosePopup}
              editFunction={editAndClosePopup}
              copyFunction={copyAndClosePopup}
            />
          </Popup>
        )}
      </S.CardContainer>
    </ThemeProvider>
  );
};

export default AgendaEventCard;
