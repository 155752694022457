import UserModel from "../models/UserModel";

let activeUser = new UserModel();
/** @type {Number} Count the number of login tries to avoid infinite loop */

export const getActiveUser = function () {
  return activeUser;
};

export const getActiveUserJson = function () {
  return activeUser.attributes;
};

export const getUserId = function () {
  return activeUser.getId();
};

export const getUserUuid = () => {
  return activeUser.get("uuid");
};

export const checkUser = function () {
  return getUserId() > 0;
};

export function setActiveUser(user) {
  activeUser = new UserModel(user);
}
