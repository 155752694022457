import { observer } from "mobx-react";
import React from "react";
import { EditUserPermissionModal } from "src/js/components/EditUserPermissionModal";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";

const SpacesEditUserPermissionModal = ({ user }) => {
  const { SpaceStore, SpacesSettingsStore, ModalStore } = useStores();

  const { saveSpaceUserRole, removeSpaceUserRole } = SpacesSettingsStore;
  const { activeSpaceName } = SpaceStore;

  const submitPermission = ({ accessPermission, userId }) => {
    saveSpaceUserRole({ userId, role: accessPermission })
      .then(() => {
        ModalStore.closeModal();
      })
      .catch(() => {
        showToastError({
          str: <Translate text="space_add_user_permission_error_message" />
        });
      });
  };

  const revokePermissions = ({ userId }) => {
    removeSpaceUserRole({ userId })
      .then(() => {
        ModalStore.closeModal();
      })
      .catch(() => {
        showToastError({
          str: <Translate text="space_revoke_user_permission_error_message" />
        });
      });
  };

  return (
    <EditUserPermissionModal
      spaceName={activeSpaceName}
      user={user}
      closeModal={() => ModalStore.closeModal()}
      handleSubmit={({ accessPermission, userId }) => {
        if (accessPermission) {
          submitPermission({ accessPermission, userId });
        } else {
          revokePermissions({ userId });
        }
      }}
    />
  );
};

export default observer(SpacesEditUserPermissionModal);
