import styled, { css } from "styled-components";
import { calendar } from "#themes/grey/calendar";

export const CalendarHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: 767px) {
    padding: 15px;
    background-color: ${({ theme }) => theme.colors.basicWhite};
  }
`;

const handleContentAlignment = value => {
  switch (value) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

export const CalendarHeaderSection = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
  justify-content: ${({ alignContent }) =>
    handleContentAlignment(alignContent)};

  @media (max-width: 767px) {
    justify-content: space-between;
    ${({ hiddenMobile }) =>
      hiddenMobile &&
      css`
        display: none;
      `}
  }

  @media (min-width: 768px) {
    ${({ desktopMarginTop }) =>
      desktopMarginTop &&
      css`
        margin-top: ${desktopMarginTop}px;
      `}
  }
`;

export const TodayButton = styled.button`
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  margin-left: 10px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.basicWhite};
  color: ${({ theme }) => theme.colors.primary2};
  border: 1px solid ${({ theme }) => theme.colors.accent3};
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary2};
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const MobileViewSwitch = styled.button`
  display: none;

  @media (max-width: 767px) {
    touch-action: manipulation;
    display: inline-flex;
  }
`;

export const CurrentDate = styled.span`
  display: inline-flex;
  align-self: center;
  text-transform: capitalize;
  color: ${calendar.cell.selected.color};
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  white-space: nowrap;
`;

export const WrapperCurrentDate = styled.span`
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
