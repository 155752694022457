import { Avatar, Box, Text } from "@arcadia/design-system";
import React from "react";
import {
  CustomListElementProps,
  ListSelectorItem
} from "src/js/components/global/ListSelector";
import styled, { useTheme } from "styled-components";
import { GroupRecipient, UserRecipient } from "./EventRecipientsListModal";

const StyledText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;

const StyledListSelectorItem = styled(ListSelectorItem)`
  padding: 16px;
  margin-bottom: 12px;
  cursor: default;
  span {
    display: block;
  }
`;

const EventRecipientsCard = ({
  el
}: CustomListElementProps<UserRecipient | GroupRecipient>) => {
  const { whiteTheme } = useTheme();
  const avatarUrl =
    (el as GroupRecipient)?.cover?.small ||
    (el as UserRecipient)?.avatar?.small;
  const fullName = `${el.name} ${(el as UserRecipient)?.surname || ""}`;
  return (
    <StyledListSelectorItem isActive={false}>
      <StyledAvatar
        size="image"
        rounded
        url={avatarUrl}
        theme={whiteTheme}
        alt={fullName}
      />
      <Box width="100%" padding="0 12px" overflow="hidden">
        <StyledText>{fullName}</StyledText>
      </Box>
    </StyledListSelectorItem>
  );
};

export default EventRecipientsCard;
