/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";

/**
 * @name ConfirmDialogStore
 *
 * @description
 * ...
 */
class ConfirmDialogStore {
  $show = false;
  $body = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  closeConfirmDialog = () => {
    this.$show = false;
    this.$body = null;
  };

  openConfirmDialog = body => {
    if (!body) return;
    this.$show = true;
    this.$body = body;
  };

  get show() {
    return this.$show;
  }

  get body() {
    return this.$body;
  }
}

export default ConfirmDialogStore;
