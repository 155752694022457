import React from "react";
import * as S from "./MainContainer.styles";

const MainContainer = ({
  children,
  noHeaderWhite = false,
  className = "",
  paddingTop = "0px",
  paddingTopMobile = "0px"
}) => {
  return (
    <S.LayoutContainer
      paddingTop={paddingTop}
      paddingTopMobile={paddingTopMobile}
      noHeaderWhite={noHeaderWhite}
      className={className}
    >
      {children}
    </S.LayoutContainer>
  );
};

export default MainContainer;
