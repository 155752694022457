import { Editor } from "@tiptap/react";
import { ObjectValues } from "src/js/types";

export type BaseEditorProps = {
  fixedMenu?: (editor?: Editor) => React.ReactNode;
  bubbleMenu?: (editor?: Editor) => React.ReactNode;
  floatingMenu?: (editor?: Editor) => React.ReactNode;
  editor?: Editor;
  className?: string;
};

export const EditorExtension = {
  AI: "ai",
  Bold: "bold",
  Italic: "italic",
  Strike: "strike",
  Underline: "underline",
  BulletList: "bulletList",
  OrderedList: "orderedList",
  BlockQuote: "blockquote",
  TextFormatting: "textFormatting",
  Link: "link",
  Superscript: "superscript",
  Subscript: "subscript",
  Submenu: "submenu"
} as const;

export const Divider = "|" as const;

export type EditorExtensionType =
  | ObjectValues<typeof EditorExtension>
  | typeof Divider;
