import { AcknowledgementAlertThemeOptions, Palette } from "@ds_themes/types";

export const createAknowledgmentAlertTheme = ({
  yellow
}: Palette): { aknowledgmentalert: AcknowledgementAlertThemeOptions } => {
  return {
    aknowledgmentalert: {
      background: yellow[600],
      border: `1px solid ${yellow[100]}`,
      text: yellow[100]
    }
  };
};
