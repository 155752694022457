import React, { useEffect, useState } from "react";
import { Resource } from "src/js/types";
import { Post } from "src/js/types/models/Post";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { InternalDispatcher } from "src/legacy/modules/dispatcher";
import {
  updateBoardElementResource,
  updatePostResource
} from "src/js/repository/resourceRepository";
import {
  api_board_element_resource,
  api_post_resource,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import getUserClient from "src/js/modules/connection";
import { useTranslation } from "src/js/translation";
import ResourceModel from "src/legacy/models/resourceModel";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import ResourceImageGalleryEditor from "./ResourceImageGalleryEditor";
import { ResourceTextEditor } from "./ResourceTextEditor";
import {
  ResourceContext,
  fetchResourceDetail,
  navigateToOrigin
} from "./utils";

type ResourceEditorProps = {
  objId: number;
  contextId?: number;
  target?: ResourceContext;
  groupId: number;
  isDraft?: boolean;
};

const ResourceEditor = ({
  objId,
  contextId,
  target,
  groupId,
  isDraft
}: ResourceEditorProps) => {
  const [resource, setResource] = useState<Resource>(null);
  const [boardElementId, setBoardElementId] = useState<number>(null);
  const [post, setPost] = useState<Post>(null);
  const { greyTheme } = useTheme();

  const { translate } = useTranslation();

  useEffect(() => {
    setTimeout(
      () =>
        fetchResourceDetail({
          id: objId,
          contextId,
          context: target,
          setBoardElementId
        })
          .then(result => {
            if (target === "post") {
              setPost(result.post);
              setResource(result.resource);
            } else {
              setResource(result);
            }
          })
          .catch(error => {
            showToastError({
              str: extractErrorMessage(
                error,
                "Attenzione, si è verificato un errore"
              )
            });
          }),
      500
    );
  }, []);

  const navigateToOriginFn = response => {
    InternalDispatcher.trigger("leaveAlertCheck", {
      status: false,
      text: ""
    });
    if (target === "board") {
      navigateToOrigin({
        groupId,
        contextId,
        objId,
        context: target,
        isDraft
      });
    } else {
      navigateToOrigin({
        groupId,
        contextId,
        objId: response.id,
        context: target
      });
    }
  };

  const saveText = resource => {
    if (target === "board") {
      updateBoardElementResource({
        boardElementId,
        data: { resource }
      }).catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
      return;
    }

    if (target === "post") {
      const resourceArray = post.resources;
      const result = resourceArray.filter(res => {
        return res.id === objId;
      });
      result.push(resource);
      updatePostResource({
        postId: contextId,
        data: { resources: result }
      }).catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
    }
  };

  const save = (resource: Resource) => {
    let editUrl = api_board_element_resource({ board_element_id: objId });
    let data: { resource?: Resource; resources?: Resource[] } = { resource };

    if (target === "post") {
      const resourceArray = post.resources;
      const result = resourceArray.filter(res => {
        return res.id === objId;
      });
      result.push(resource);
      data = { resources: result };
      editUrl = api_post_resource({ post_id: contextId });
    }

    getUserClient()
      .put(getVersionedApiUrl(editUrl, "v2"), data)
      .then(res => {
        const response = res.data;
        const originId = target === "board" ? response.board.id : response.id;
        const resourceId = target === "board" ? response.id : response.id;

        switch (resource.type) {
          case "TEXT":
            showToastSuccess({
              str: translate("resource_text_update_success")
            });
            break;
          default:
            showToastSuccess({
              str: translate("resource_update_success")
            });
        }
        navigateToOrigin({
          groupId,
          contextId: originId,
          objId: resourceId,
          context: target
        });
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
    InternalDispatcher.trigger("leaveAlertCheck", { status: false, text: "" });
  };
  if (!resource) return <DoodleLoader theme={greyTheme} />;

  const resourceModel = new ResourceModel(resource);

  const backFunc = () =>
    navigateToOrigin({
      groupId,
      contextId,
      objId,
      context: target
    });

  if (!resource) return null;
  if (resource.type === "IMAGE_GALLERY" && target !== "board") {
    backFunc();
    return null;
  }

  if (resource.type !== "TEXT" && resource.type !== "IMAGE_GALLERY") {
    backFunc();
    return null;
  }

  const content =
    resource.type === "TEXT" ? (
      <ResourceTextEditor
        context={target}
        resource={resourceModel.attributes}
        onClose={navigateToOriginFn}
        addFunc={saveText}
      />
    ) : (
      <ResourceImageGalleryEditor
        title={resourceModel.attributes.name}
        resource={resourceModel.attributes}
        addFunc={save}
        backFunc={backFunc}
        mode="edit"
        context="board"
      />
    );

  return content;
};

export default ResourceEditor;
