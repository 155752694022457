export const STATUS_FILTER_OPTIONS = [
  {
    key: "active",
    value: "active",
    filter_family: "status"
  },
  {
    key: "inactive",
    value: "not_active",
    filter_family: "status"
  }
];

export const SORT_FILTER_OPTIONS = [
  {
    key: "created_at_descending",
    value: "creation_date_descending",
    filter_family: "descending"
  },
  {
    key: "created_at_ascending",
    value: "creation_date_ascending",
    filter_family: "ascending"
  },
  {
    key: "name_ascending",
    value: "name_ascending",
    filter_family: "ascending"
  },
  {
    key: "name_descending",
    value: "name_descending",
    filter_family: "descending"
  }
];
