import styled from "styled-components";
import { Heading } from "@arcadia/design-system";
import { motion } from "framer-motion";

export const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[1000]};
  margin-bottom: 100px;
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 52px 16px;
`;

export const MobileTitleWrapper = styled.div`
  position: relative;
  margin-bottom: 52px;
`;

export const MobileTitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.lagoon[200]};
`;

export const MobileSelectorWrapper = styled(motion.div)`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20vh;
`;

export const MobileButtonSection = styled(motion.div)`
  background: linear-gradient(
    transparent,
    ${({ theme }) => theme.colors.lagoon[700]}
  );
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 16px;
`;

export const LinkWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};
`;
