import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const CarouselWrapper = styled.div`
  height: 220px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
`;

export const BaseButton = css`
  all: unset;
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => `${theme.colors.grey[900]}AA`};
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.grey[1000]}`};
  }
`;
export const NextButton = styled.button`
  ${BaseButton}
  right: 8px;
  > svg {
    margin-left: 4px;
  }
`;
export const PrevButton = styled.button`
  ${BaseButton}
  left: 8px;
  > svg {
    margin-right: 4px;
  }
`;
export const InfoBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.grey[1000]};
  height: 60px;
  bottom: 0px;
  position: absolute;
  width: 100%;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  > span {
    max-height: 44px;
    overflow: hidden;
  }
`;

export const CarouselCell = styled(motion.div)`
  width: 100%;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.violet[501]};
  border-radius: 8px;
  height: 220px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }

  .slide-image {
    position: absolute;
    top: 0px;
    overflow: hidden;
    height: 220px;
    width: 100%;
    background-size: cover, auto !important;
    background-position: center center;
  }
`;
