import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 24px;
  width: 100%;
  outline: 4px solid ${({ theme }) => theme.colors.violet[700]};
  border-radius: 16px;
`;

export const ConnectYourWallet = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

export const InfoDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const MetaMaskIconContainer = styled.div`
  flex: 1;
`;

export const PrimaryDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.violet[100]};
`;

export const SecondaryDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[300]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: row-reverse;
    & button:first-child {
      flex: 1;
    }
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  padding: 12px;
`;
