import React, { useEffect, useRef, useState } from "react";
import Url from "url-parse";
import { observer } from "mobx-react";
import { DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { OldResource, Resource } from "src/js/types";
import { fetchResourceEmbeddable } from "src/js/repository/resourceRepository";
import { useTranslation } from "src/js/translation";
import { __RESOURCE_GMS_ULR__ } from "src/legacy/settings/settings";
import { createURL } from "src/legacy/modules/utility";
import { returnAccessToken } from "src/legacy/modules/ajax";
import FallbackPlaceholder from "./FallbackPlaceholder";

const Link = ({
  boardElementId,
  resource
}: {
  boardElementId: number;
  resource: (OldResource & { url?: string }) | Resource;
}) => {
  const [loading, setLoading] = useState(true);
  const [viewable, setViewable] = useState(true);
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();
  const iframe = useRef(null);

  const publicUrl =
    // @ts-expect-error - TODO fix the error
    resource.public_url || resource.url || resource.publicUrls[0];

  const getEmbeddableResource = () => {
    fetchResourceEmbeddable({ url: publicUrl })
      .then(res => {
        // TODO fix this BE side
        setViewable(res.result);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmbeddableResource();
  }, []);

  if (loading) {
    return <DoodleLoader theme={greyTheme} />;
  }

  if (!publicUrl) {
    return (
      <div className="text-center">{translate("general_error_message")}</div>
    );
  }

  // TODO check WTF is this SHIT
  if (
    publicUrl.indexOf(createURL(__RESOURCE_GMS_ULR__)) > -1 ||
    publicUrl.indexOf("/v2/crm") > -1
  ) {
    const customUrl = new Url(publicUrl, null, true);
    if (!Object.prototype.hasOwnProperty.call(customUrl.query, "beid"))
      customUrl.query.beid = String(boardElementId);
    customUrl.query.access_token = returnAccessToken();
    return (
      <div className="full-height" key={`res${resource.id}`}>
        <iframe
          title={String(resource.id)}
          allow="autoplay"
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          src={customUrl.toString()}
        />
      </div>
    );
  }
  if (viewable) {
    return (
      <div className="full-height">
        <iframe
          title={String(resource.id)}
          id="link-player"
          allow="autoplay"
          className="resource__iframe"
          width="100%"
          frameBorder="0"
          ref={iframe}
          src={publicUrl}
        />
      </div>
    );
  }

  return <FallbackPlaceholder url={publicUrl} />;
};

export default observer(Link);
