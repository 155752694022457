import styled from "styled-components";

export const PlaceholderContainer = styled.div`
  position: relative;
`;

export const PreUploadBox = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 16px);
  z-index: 2;
  background-color: ${({ theme }) => theme.primaryColor[800]};
  border: ${({ theme }) => `1px dashed ${theme.primaryColor[500]}`};
  border-radius: 8px;
  display: none;
  align-items: flex-end;
`;

export const PlaceholderIndex = styled.div<{ enabled?: boolean }>`
  position: absolute;
  z-index: 0;
  top: 12px;
  left: 20px;
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;

  user-select: none;
  color: ${({ theme, enabled }) =>
    enabled ? theme.colors.coldGrey[800] : theme.colors.grey[800]};

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
