import map from "lodash/map";
import indexOf from "lodash/indexOf";
import isUndefined from "lodash/isUndefined";
// import clone from "lodash/clone";
import { InternalDispatcher, RealTimeDispatcher } from "../modules/dispatcher";
import { getUserId } from "../modules/activeUser";
import { getActiveGroupId } from "../modules/activeGroup";
import { navigateTo } from "../modules/history";
import { instants } from "../modules/instant";
import createUrl from "src/js/modules/routing";
import { fetchPost } from "src/js/repository/postRepository";
import { getPostFromOld } from "src/js/pages/wall/post/Post.utils";

const agentIsTeacher = function (obj) {
  if (obj.action.agent_group_user !== undefined) {
    if (obj.action.agent_group_user.roles !== undefined) {
      const roles = obj.action.agent_group_user.roles;
      if (
        indexOf(roles, "ROLE_TEACHER") >= 0 ||
        indexOf(roles, "ROLE_CREATOR") >= 0
      ) {
        return true;
      }
      return false;
    }
  } else {
    return false;
  }
};

const entityAsResource = function (obj) {
  if (obj.target.entity !== undefined) {
    if (
      obj.target.entity.resources !== undefined &&
      obj.target.entity.resources.length > 0
    ) {
      return true;
    }
  }
  return false;
};

const targetIsTeacher = function (obj) {
  if (obj.action.target_group_user !== undefined) {
    if (obj.action.target_group_user.roles !== undefined) {
      const roles = obj.action.target_group_user.roles;
      if (
        indexOf(roles, "ROLE_TEACHER") >= 0 ||
        indexOf(roles, "ROLE_CREATOR") >= 0
      ) {
        return true;
      }
      return false;
    }
  } else {
    return false;
  }
};

const targetIsStudent = function (obj) {
  if (obj.action.target_group_user !== undefined) {
    if (obj.action.target_group_user.roles !== undefined) {
      const roles = obj.action.target_group_user.roles;
      if (indexOf(roles, "ROLE_STUDENT") >= 0) {
        return true;
      }
      return false;
    }
  } else {
    return false;
  }
};

export function postListDependentEvents(postsList, setRealtimePost) {
  RealTimeDispatcher.on("do_post", obj => {
    if (obj.agent.id === getUserId()) return;
    const targetId = obj.target.parent.id;
    const postId = obj.target.id;

    fetchPost(postId)
      .then(res => {
        if (targetId === getActiveGroupId()) {
          setRealtimePost(res);
        }
      })
      .catch(e => {
        InternalDispatcher.trigger("stopLoading");
        navigateTo(createUrl("home"));
      });
  });

  // TODO Check this event, seems to be not triggered anymore
  RealTimeDispatcher.on("do_post_comment", obj => {
    if (obj.agent.id !== getUserId()) {
      InternalDispatcher.trigger("post_new_comment", obj);
      let userHasCommentedThisPost = false;
      const commenters = obj.target.entity.commenters;

      if (commenters !== undefined && typeof commenters === "object") {
        if (indexOf(commenters, getUserId()) >= 0)
          userHasCommentedThisPost = true;
      }

      // const targetId = obj.target.parent.id;
      // const post = postsList.find((postFull) => postFull.post.id === targetId);
      // if (post !== undefined) {
      //   const comment = obj.target.entity;
      //   comment.doAnimation = true;
      //   const comments = clone(post.getComments());
      //   comments.push(comment);
      //   post.set("comments", comments);
      // }
    }
  });
}

export function postListOffDependentEvents() {
  RealTimeDispatcher.off("do_post_comment");
  RealTimeDispatcher.off("do_post");
}

RealTimeDispatcher.on("leaveAlertCheck", obj => {
  if (!isUndefined(obj)) {
    // leaveAlert = obj;
  }
});

RealTimeDispatcher.on("do_exercise_execution_instant_new", obj => {
  const instantData = obj.target;
  const exerciseName = obj.target.name;
  const exerciseData = instantData.parent;
  const agentId = obj.agent.id;
  const instantId = instantData.id;
  const exerciseId = exerciseData.id;
  const groupId = instantData.parent.parent.id;
  const groupName = instantData.parent.parent.entity.name;
  const role = targetIsTeacher(obj) ? "TEACHER" : "STUDENT";

  // TODO:
  if (instants.getActiveInstantId() < 1) {
    InternalDispatcher.trigger("instant_manual_start", obj);

    if (agentId === getUserId()) {
      instants.subscribeInstant(groupId, groupName, instantId); // TODO:
    }

    if (
      agentId === getUserId() ||
      (targetIsStudent(obj) && getActiveGroupId() === groupId)
    ) {
      // esecuzione automatica solo se creatore o se studente che è già all'interno del gruppo
      instants.executeInstant(
        instantId,
        exerciseId,
        groupId,
        role,
        exerciseName
      ); // TODO:
    } else {
      instants.showInstant(
        instantId,
        exerciseId,
        groupId,
        role,
        groupName,
        exerciseName
      ); // TODO:
    }
  }
});

RealTimeDispatcher.on("do_exercise_execution_instant_begin", obj => {
  InternalDispatcher.trigger("instant_begin", obj);
});

RealTimeDispatcher.on("do_quiz_execution_instant_stop", obj => {
  // fine quiz instant
  InternalDispatcher.trigger("quizprogress", obj);
});

RealTimeDispatcher.on("do_exercise_execution_instant_stop", obj => {
  // instant stoppato o terminato
  InternalDispatcher.trigger("instant_manual_stop", obj);
  if (agentIsTeacher(obj)) {
    app.endInstant(obj); // TODO:
  }
});

RealTimeDispatcher.on("job_exercise_execution_instant_stop", obj => {
  // instant stoppato o terminato
  InternalDispatcher.trigger("instant_automatic_stop", obj);
  app.endInstant(obj, true); // TODO:
});

RealTimeDispatcher.on("do_exercise_execution_instant_finish", obj => {
  // esecuzione instant terminata
  InternalDispatcher.trigger("instant_end", obj);
});

RealTimeDispatcher.on("do_group_invite_user_accept", obj => {
  if (obj.agent.id !== getUserId()) {
    if (getActiveGroupId() === obj.target.parent.entity.id) {
      obj.agent.avatar = obj.agent.picture; // aggiungo campo avatar, in RT si chiama picture
      // app.group.fetchUsers();
      // app.group.fetchInvites();
    }
  }
});

RealTimeDispatcher.on("do_group_invite_request_new", obj => {
  if (targetIsTeacher(obj)) {
    InternalDispatcher.trigger("group_invite_request_new", obj);
  }
});

RealTimeDispatcher.on("do_group_invite_request_approved", obj => {
  if (obj.target.entity.user.id === getUserId()) {
    InternalDispatcher.trigger("group_invite_request_approved", obj);
  }
});
