import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0px 15px;
`;

export const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.colors.disabledBk};
  padding-top: 10px;
`;
