import * as Tooltip from "@radix-ui/react-tooltip";
import { Button, shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const TooltipLayout = styled(motion.div)`
  width: 350px;
  color: ${({ theme }) => theme.colors.grey[50]};
  background: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  box-shadow: ${({ theme }) => theme.shadows.modals};
  box-shadow: ${shadow[600]};

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 24px;
  }
`;

export const TooltipHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: left;
  align-items: center;
`;

export const UserFullName = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  @media screen and (max-width: 767px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const TooltipFooter = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const TooltipFooterButton = styled(Button)`
  flex: 1;
`;

export const MobileWrapper = styled.div`
  flex: 1;
  cursor: pointer;
`;

export const StyledTooltipContent = styled(Tooltip.Content)`
  z-index: 9999;
`;
