import styled, { css } from "styled-components";
import colors from "@ds_themes/colors";

import { ChipProps, ChipVariant, SChipWrapper } from "./Chip.types";

const colorsMixinChip = ({ variant, theme }: ChipProps) => {
  switch (variant) {
    case ChipVariant.Success:
      return css`
        background-color: ${colors.success[700]};
        color: ${colors.success[100]};
      `;
    case ChipVariant.Warning:
      return css`
        background-color: ${colors.yellow[800]};
        color: ${colors.yellow[50]};
      `;
    case ChipVariant.Danger:
      return css`
        background-color: ${colors.error[800]};
        color: ${colors.error[100]};
      `;
    case ChipVariant.Primary:
      return css`
        background-color: ${theme.chip.background};
        color: ${theme.chip.color};
      `;
    default:
      return css`
        background-color: ${theme.chip.background};
        color: ${theme.chip.background};
      `;
  }
};

export const ChipWrapper = styled.span<SChipWrapper>`
  ${({ variant, theme }) => colorsMixinChip({ variant, theme })}
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  width: max-content;
`;
