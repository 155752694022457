import styled, { css } from "styled-components";
import { input } from "#themes/white/input";

export const LabelContainer = styled.label`
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  color: ${input.label};
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
  ${({ bold }) => {
    return (
      bold &&
      css`
        font-weight: ${({ theme }) => theme.weights.bold};
      `
    );
  }}
  ${({ visuallyHidden }) =>
    visuallyHidden &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    `}
`;
