import { TranslationKeys } from "src/js/translation";

// add here CtaType at needs and use it in the WarningBannerProps
export enum CtaType {
  Link = "link",
  SpaceCreation = "spaceCreation",
  GroupCreation = "groupCreation",
  GroupCreationByInvitation = "groupCreationByInvitation"
}

export type CtaTypeAndTranslationKey = {
  type: CtaType;
  key: TranslationKeys;
};

export type WarningBannerProps = {
  text: TranslationKeys;
  cta?: CtaTypeAndTranslationKey;
  margin?: number | string;
  maxWidth?: number | string;
};
