import { Palette } from "@ds_themes/types";

export const createSelfTheme = ({ primary, coldGrey }: Palette) => {
  return {
    self: {
      background: coldGrey[900],
      label: primary.main,
      focus: {
        border: primary.main
      }
    }
  };
};

export const createOtherTheme = ({ primary, violet, coldGrey }: Palette) => {
  return {
    other: {
      background: coldGrey[900],
      label: violet[200],
      focus: {
        border: primary.main
      }
    }
  };
};

export const createCodeInputTheme = (palette: Palette) => {
  return {
    codeInput: {
      ...createSelfTheme(palette),
      ...createOtherTheme(palette)
    }
  };
};
