import { HTMLMotionProps } from "framer-motion";

type AnimationStates = Pick<
  HTMLMotionProps<"div">,
  "initial" | "exit" | "transition" | "animate"
>;

export const __MODAL_PORTAL_DEFAULT_NAME__ = "modal-root";
export const __BACKDROP_PORTAL_DEFAULT_NAME__ = "backdrop-root";

export const modalContentStates: AnimationStates = {
  initial: {
    opacity: 0,
    scale: 1.1,
    filter: "blur(10px)"
  },
  animate: {
    opacity: 1,
    scale: 1,
    filter: "blur(0px)"
  },
  exit: {
    opacity: 0,
    scale: 1.1,
    filter: "blur(10px)",
    transition: { duration: 0.1 }
  },
  transition: {
    opacity: { duration: 0.2 },
    scale: { duration: 0.35 },
    filter: { duration: 0.2 }
  }
};

export const backdropStates: AnimationStates = {
  initial: {
    opacity: 0,
    backdropFilter: "blur(0px)"
  },
  animate: { opacity: 1, backdropFilter: "blur(4px)" },
  exit: {
    opacity: 0,
    backdropFilter: "blur(0px)",
    transition: { duration: 0.12 }
  },
  transition: { duration: 0.25 }
};
