/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import {
  ScormData,
  ScormDataDetail,
  ScormDataDetailOrderBy,
  ScormDataOrderBy,
  Sorting,
  SortOrder,
  useScormGroup,
  useScormUser
} from "src/js/query/scorm";
import { useLocation, useParams } from "react-router";
import { Box, Icon, Text } from "@arcadia/design-system";
import { getDateFnsLocale, useTranslation } from "src/js/translation";
import createUrl from "src/js/modules/routing";
import { __DEFAULT_LANGUAGE_COUNTRY__ } from "src/js/settings/settingsLocalization";
import { useTheme } from "styled-components";
import { formatDuration, format, intervalToDuration, parse } from "date-fns";
import { enUS } from "date-fns/locale";
import { useCreateQuickThread } from "src/js/pages/App/Spaces/hooks";
import { getCurrentLanguage } from "src/js/modules/localization";
import { User } from "../components/User";
import { ProgressBar } from "../components/ProgressBar";
import { HeaderButton } from "../components/HeaderButton";
import { EllipsisName } from "../components/EllipsisName";

import * as S from "../ScormActivityTab.styles";

const CellWidth = {
  xl: 160,
  lg: 120,
  md: 100,
  sm: 80,
  xs: 32
} as const;

const simplifyTimeString = (input: string): string => {
  const parts = input.split(" ");

  if (parts.length !== 4) {
    return input;
  }

  const number1 = parts[0];
  const firstLetter1 = parts[1].charAt(0);
  const number2 = parts[2];
  const firstLetter2 = parts[3].charAt(0);

  return `${number1}${firstLetter1} ${number2}${firstLetter2}`;
};

export const useScormActivityTab = () => {
  const { navigateToThread } = useCreateQuickThread();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState("");
  const { translate } = useTranslation();
  const scormColumnHelper = createColumnHelper<ScormData>();
  const scormDetailColumnHelper = createColumnHelper<ScormDataDetail>();
  const { colors } = useTheme();

  const location = useLocation();

  const { group_id } = useParams<{ group_id: string }>();

  const queryParams = new URLSearchParams(location.search);
  const userUuid = queryParams.get("userUuid");

  const sortingConfig = useMemo(() => {
    const sortingObject = sorting.reduce<{
      orderBy: ScormDataOrderBy | ScormDataDetailOrderBy;
      sortOrder: SortOrder;
    }>(
      (acc, sort) => {
        acc.orderBy = sort.id as ScormDataOrderBy | ScormDataDetailOrderBy;
        acc.sortOrder = sort.desc ? Sorting.Descending : Sorting.Ascending;
        return acc;
      },
      {} as {
        orderBy: ScormDataOrderBy | ScormDataDetailOrderBy;
        sortOrder: SortOrder;
      }
    );
    return sortingObject;
  }, [sorting]);

  const userQuery = useScormUser({
    userUuid,
    groupId: Number(group_id),
    options: { limit: 10, enabled: !!userUuid },
    sortOrder: sortingConfig.sortOrder,
    orderBy: sortingConfig.orderBy as ScormDataDetailOrderBy,
    textSearch: search.length > 0 ? search : undefined
  });

  const groupQuery = useScormGroup({
    groupId: Number(group_id),
    options: { limit: 10, enabled: !userUuid },
    sortOrder: sortingConfig.sortOrder,
    orderBy: sortingConfig.orderBy as ScormDataOrderBy,
    textSearch: search.length > 0 ? search : undefined
  });

  const query = userUuid ? userQuery : groupQuery;

  const rows = query?.data ?? [];

  useEffect(() => {
    const fetchUntilScrollable = async () => {
      while (
        !(document.documentElement.scrollHeight > window.innerHeight) &&
        query.hasNextPage &&
        !query.isFetchingNextPage
      ) {
        // eslint-disable-next-line no-await-in-loop
        await query.fetchNextPage();
      }
    };

    fetchUntilScrollable();

    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        if (query.hasNextPage && !query.isFetchingNextPage) {
          query.fetchNextPage();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [query.hasNextPage, query.isFetchingNextPage, query.fetchNextPage]);

  const scormDataColumns = useMemo(
    () => [
      scormColumnHelper.accessor("user", {
        id: "userName",
        size: CellWidth.xl,
        header: ({ header, table }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_learners")}{" "}
            {table.getRowModel().rows[0]?.original.learnersCount > 0 &&
              `(${table.getRowModel().rows[0]?.original.learnersCount})`}
          </HeaderButton>
        ),
        cell: info => (
          <User
            avatarUrl={info.row.original.user?.avatar?.small ?? ""}
            fullName={`${info.row.original.user?.name} ${info.row.original.user?.surname}`}
          />
        )
      }),
      scormColumnHelper.accessor("lastUpdatedAt", {
        id: "lastUpdatedAt",
        size: CellWidth.lg,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_view_date")}
          </HeaderButton>
        ),
        cell: info => {
          const language = getCurrentLanguage();
          const parsedDate = parse(
            info.row.original.lastUpdatedAt,
            "yyyy-MM-dd",
            new Date()
          );
          const isEnglish = language === __DEFAULT_LANGUAGE_COUNTRY__.en;
          return (
            <S.TableCellText type="table">
              {format(parsedDate, isEnglish ? "MM/dd/yyyy" : "dd/MM/yyyy")}
            </S.TableCellText>
          );
        }
      }),
      scormColumnHelper.accessor("timeSpent", {
        id: "timeSpent",
        size: CellWidth.md,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_timespent")}
          </HeaderButton>
        ),
        cell: info => {
          const duration = intervalToDuration({
            start: 0,
            end: info.row.original.timeSpent
          });
          const language = getCurrentLanguage();
          // use nh nm format for italian
          const locale =
            language !== __DEFAULT_LANGUAGE_COUNTRY__.it
              ? getDateFnsLocale(language)
              : enUS;
          const formatted = formatDuration(duration, {
            format: ["hours", "minutes"],
            locale,
            zero: true,
            delimiter: " "
          });
          return (
            <S.TableCellText type="table">
              {simplifyTimeString(formatted)}
            </S.TableCellText>
          );
        }
      }),
      scormColumnHelper.accessor("completion", {
        id: "completion",
        size: CellWidth.xl,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_scorm_progress")}
          </HeaderButton>
        ),
        cell: info => <ProgressBar percentage={info.row.original.completion} />
      }),
      {
        id: "actions",
        header: "",
        size: CellWidth.xs,
        cell: ({ row }) => {
          return (
            <S.CustomDotsMenu
              id="scorm-data-dots-menu"
              alignRight
              customIcon="kebabVertical"
              items={[
                {
                  id: "user-profile",
                  icon: "user",
                  label: translate("record_scorm_tab_action_profile"),
                  onClick: () =>
                    window.open(
                      `${window.location.origin}/#${createUrl(
                        "profile_public",
                        {
                          user_uuid: row.original.user.uuid
                        }
                      )}`,
                      "_blank"
                    )
                },
                {
                  id: "chat-with-user",
                  icon: "chat",
                  label: translate("record_scorm_tab_action_message"),
                  onClick: () => navigateToThread(row.original.user.uuid, true)
                }
              ]}
            />
          );
        }
      }
    ],
    [scormColumnHelper, translate]
  );

  const scormDataDetailColumns = useMemo(
    () => [
      scormDetailColumnHelper.accessor("boardElementName", {
        id: "boardElementName",
        size: CellWidth.xl,
        header: ({ header, table }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_scorms")}{" "}
            {table.getRowModel().rows[0]?.original.scormsCount > 0 &&
              `(${table.getRowModel().rows[0]?.original.scormsCount})`}
          </HeaderButton>
        ),
        cell: info => (
          <S.ScormNameWrapper>
            <Icon
              icon="folderZipVertical"
              initialViewbox
              width={16}
              height={16}
              color={colors.violet[501]}
            />
            <EllipsisName
              name={info.row.original.boardElementName}
              onClick={() => {
                window.open(
                  `${window.location.origin}/#${createUrl("module_element", {
                    group_id: Number(group_id),
                    module_id: `${info.row.original.boardId}`,
                    element_id: `${info.row.original.boardElementId}`
                  })}`,
                  "_blank"
                );
              }}
            />
          </S.ScormNameWrapper>
        )
      }),
      scormDetailColumnHelper.accessor("boardName", {
        id: "boardName",
        size: CellWidth.md,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_module")}
          </HeaderButton>
        ),
        cell: info => (
          <Box cursor="pointer" overflow="hidden" textOverflow="ellipsis">
            <EllipsisName
              name={info.row.original.boardName}
              onClick={() =>
                window.open(
                  `${window.location.origin}/#${createUrl("module_detail", {
                    group_id: Number(group_id),
                    module_id: `${info.row.original.boardId}`
                  })}`,
                  "_blank"
                )
              }
            />
          </Box>
        )
      }),
      scormDetailColumnHelper.accessor("updatedAt", {
        id: "updatedAt",
        size: CellWidth.md,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_view_date")}
          </HeaderButton>
        ),
        cell: info => {
          if (!info.row.original.updatedAt)
            return <S.TableCellText type="table">-</S.TableCellText>;
          const language = getCurrentLanguage();
          const parsedDate = parse(
            info.row.original.updatedAt,
            "yyyy-MM-dd",
            new Date()
          );
          const isEnglish = language === __DEFAULT_LANGUAGE_COUNTRY__.en;
          return (
            <S.TableCellText type="table">
              {format(parsedDate, isEnglish ? "MM/dd/yyyy" : "dd/MM/yyyy")}
            </S.TableCellText>
          );
        }
      }),
      scormDetailColumnHelper.accessor("timeOfExecution", {
        id: "timeOfExecution",
        size: CellWidth.md,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_timespent")}
          </HeaderButton>
        ),
        cell: info => {
          const duration = intervalToDuration({
            start: 0,
            end: info.row.original.timeOfExecution
          });
          const language = getCurrentLanguage();
          // use nh nm format for italian
          const locale =
            language !== __DEFAULT_LANGUAGE_COUNTRY__.it
              ? getDateFnsLocale(language)
              : enUS;
          const formatted = formatDuration(duration, {
            format: ["hours", "minutes"],
            locale,
            zero: true,
            delimiter: " "
          });
          return (
            <S.TableCellText type="table">
              {simplifyTimeString(formatted)}
            </S.TableCellText>
          );
        }
      }),
      scormDetailColumnHelper.accessor("completed", {
        id: "completed",
        size: CellWidth.md,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_status")}
          </HeaderButton>
        ),
        cell: info => (
          <S.PillStatus darkBk={info.row.original.completed}>
            <Text type="table">
              {info.row.original.completed
                ? translate("record_scorm_tab_status_completed")
                : translate("record_scorm_tab_status_incomplete")}
            </Text>
          </S.PillStatus>
        )
      }),
      scormDetailColumnHelper.accessor("score", {
        id: "score",
        size: CellWidth.md,
        header: ({ header }) => (
          <HeaderButton
            header={header}
            onClick={header.column.getToggleSortingHandler()}
          >
            {translate("record_scorm_tab_column_result")}
          </HeaderButton>
        ),
        cell: info => (
          <S.TableCellText type="table">
            {info.row.original.score}%
          </S.TableCellText>
        )
      }),
      {
        id: "actions",
        header: "",
        size: CellWidth.xs,
        cell: ({ row }) => (
          <S.CustomDotsMenu
            id="scorm-data-details-dots-menu"
            alignRight
            customIcon="kebabVertical"
            items={[
              {
                id: "scorm-details-redirect",
                icon: "gear",
                label: translate("record_scorm_tab_action_scorm"),
                onClick: () => {
                  window.open(
                    `${window.location.origin}/#${createUrl("module_element", {
                      group_id: Number(group_id),
                      module_id: `${row.original.boardId}`,
                      element_id: `${row.original.boardElementId}`
                    })}`,
                    "_blank"
                  );
                }
              }
            ]}
          />
        )
      }
    ],
    [scormDetailColumnHelper]
  );

  const tableInstance = useReactTable<ScormData | ScormDataDetail>({
    data: rows,
    // Check if we can improve this typings, couldn't find a better way without splitting everything
    columns: userUuid
      ? (scormDataDetailColumns as ColumnDef<
          ScormDataDetail | ScormData,
          any
        >[])
      : (scormDataColumns as ColumnDef<ScormDataDetail | ScormData, any>[]),
    getCoreRowModel: getCoreRowModel(),
    enableSorting:
      rows.length > 0 && !query.isLoading && !query.isFetchingNextPage,
    manualSorting: true,
    state: {
      sorting
    },
    onSortingChange: setSorting
  });

  return {
    columns: userUuid ? scormDataDetailColumns : scormDataColumns,
    tableInstance,
    search,
    setSearch,
    ...query
  };
};
