import getUserClient from "../modules/connection";
import {
  MarketingDataUpdate,
  UserTypeSelectionFields
} from "../types/models/MarketingData";
import {
  api_onboarding_create_marketing_data,
  api_onboarding_read_marketing_data,
  api_onboarding_update_marketing_data,
  getVersionedApiUrl
} from "./apiUrls";

export const fetchMarketingData = async () => {
  return getUserClient()
    .get<MarketingDataUpdate>(
      getVersionedApiUrl(api_onboarding_read_marketing_data(), "v3")
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const createMarketingData = async ({
  userType,
  onboardingCompleted
}: UserTypeSelectionFields &
  Pick<MarketingDataUpdate, "onboardingCompleted">) => {
  return getUserClient()
    .post<UserTypeSelectionFields>(
      getVersionedApiUrl(api_onboarding_create_marketing_data(), "v3"),
      {
        userType,
        onboardingCompleted
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const updateMarketingData = async ({
  userType,
  companyName = null,
  companyRole = null,
  companySize = null,
  weschoolUseCase = null,
  phoneNumber = null,
  onboardingCompleted = null
}: MarketingDataUpdate) => {
  return getUserClient()
    .patch<MarketingDataUpdate>(
      getVersionedApiUrl(api_onboarding_update_marketing_data(), "v3"),
      {
        userType,
        companyName,
        companyRole,
        companySize,
        weschoolUseCase,
        phoneNumber,
        onboardingCompleted
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};
