import { BottomModalSheet, Box, Modal } from "@arcadia/design-system";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { ModalLayoutProps } from "./ModalLayout.types";

const ModalLayout: FC<ModalLayoutProps> = ({
  className,
  labels,
  progressPercentage,
  closeModal,
  mobileProps,
  desktopProps,
  forceMobileLayout,
  children
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  if (
    (isLayoutModeMobile && forceMobileLayout === undefined) ||
    forceMobileLayout
  ) {
    return (
      <Box className={className}>
        <BottomModalSheet
          title={labels?.mobileTitle || labels?.title}
          subtitle={labels?.mobileSubtitle || labels?.subtitle}
          isOpen
          onClose={closeModal}
          closeLabel={labels?.mobileClose}
          progressPercentage={progressPercentage}
          {...mobileProps}
        >
          {children}
        </BottomModalSheet>
      </Box>
    );
  }

  return (
    <Box className={className}>
      <Modal
        title={labels?.title}
        onClose={closeModal}
        withProgressBar
        percentage={progressPercentage}
        {...desktopProps}
      >
        {children}
      </Modal>
    </Box>
  );
};

export default observer(ModalLayout);
