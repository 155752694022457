import { AvatarWithInitials } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { DotsMenu } from "src/js/components/DotsMenu";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import {
  isFeatureFlagEnabled,
  useFeatureFlag
} from "src/js/feature_flag/FeatureFlagProvider";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { SwitchDashboard } from "src/js/pages/spaces/dashboard/Analytics/components/SwitchDashboard";
import { FeatureFlagKey } from "src/js/settings/settingsFeatureFlag";
import { useTranslation } from "src/js/translation";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { DashboardSecondaryHeaderTabs } from "./components/SpaceSecondaryHeaderTabs";

import * as S from "./DashboardSecondaryHeader.styles";

const DashboardSecondaryHeader = ({
  hasAnalyticsSwitch
}: {
  hasAnalyticsSwitch?: boolean;
}) => {
  const { whiteTheme } = useTheme();
  const {
    SpaceStore: { activeSpace, activeSpaceId },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const featureFlagConfig = useFeatureFlag();
  const isAnalyticsV2Enabled = isFeatureFlagEnabled({
    flagKey: FeatureFlagKey.analyticsV2,
    featureFlagConfig
  });

  const menuItems: ListItemProps[] = [
    {
      id: "Customize",
      label: translate({ text: "spaces_settings_plan_customize" }),
      icon: "edit",
      onClick: () =>
        navigateTo(
          createUrl("dashboard_settings", {
            space_id: activeSpaceId,
            option: "general"
          })
        )
    },
    {
      id: "Staff",
      label: translate({
        text: "spaces_settings_permissions_staff"
      }),
      icon: "shieldPermissions",
      onClick: () =>
        navigateTo(
          createUrl("dashboard_settings", {
            space_id: activeSpaceId,
            option: "permissions"
          })
        )
    },
    {
      id: "Plan",
      label: translate({
        text: "spaces_settings_permissions_plan"
      }),
      icon: "creditcard",
      onClick: () =>
        navigateTo(
          createUrl("dashboard_settings", {
            space_id: activeSpaceId,
            option: "plan"
          })
        )
    }
  ];

  return (
    <S.DashboardSecondaryHeaderWrapper
      isLayoutModeMobile={isLayoutModeMobile}
      id={UsetifulId.DashboardSecondaryHeader}
    >
      <S.DashboardSecondaryHeaderContent
        isLayoutModeMobile={isLayoutModeMobile}
      >
        {!isLayoutModeMobile ? (
          <S.LeftSection>
            <AvatarWithInitials
              theme={whiteTheme}
              alt={activeSpace?.space?.name}
              url={activeSpace?.space?.badge?.small as string | undefined}
              name={activeSpace?.space?.name || ""}
              avatarColor={activeSpace?.space?.defaultBadgeColor}
              size="medium"
            />
            <S.Columnn>
              <S.SpaceNameRow>
                <S.SpaceName>{activeSpace?.space?.name}</S.SpaceName>
                <DotsMenu id="spaceWhiteTabOptionsDotsMenu" items={menuItems} />
              </S.SpaceNameRow>
              <DashboardSecondaryHeaderTabs />
            </S.Columnn>
          </S.LeftSection>
        ) : (
          <DashboardSecondaryHeaderTabs />
        )}
        {hasAnalyticsSwitch && !isLayoutModeMobile && isAnalyticsV2Enabled ? (
          <SwitchDashboard />
        ) : null}
      </S.DashboardSecondaryHeaderContent>
    </S.DashboardSecondaryHeaderWrapper>
  );
};

export default observer(DashboardSecondaryHeader);
