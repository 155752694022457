import { Box, Icon, colors } from "@arcadia/design-system";
import styled from "styled-components";
import { ArrowPopupIcon } from "../SpacesWelcome.icons";

export const StyledArrowPopupIcon = styled(ArrowPopupIcon)`
  position: absolute;
  height: 20px;
  left: calc(50% - 4px);
  bottom: -4px;
`;

export const HelperWrap = styled.div`
  position: relative;
`;

export const HelpIcon = styled(Icon)`
  margin-top: 2px;
  margin-left: 4px;
  height: 12;
  width: 12;
  cursor: pointer;
`;

export const PopupContent = styled.div`
  padding-bottom: 10px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${colors.grey[1000]};
  width: 100%;
  height: calc(100% - 8px);
  overflow-y: auto;
  padding: 12px;
  span {
    display: block;
    color: ${colors.grey[1000]};
  }
  ::-webkit-scrollbar {
    width: 1em;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    border: 0.25em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1em;
    background-color: ${colors.grey[1000]};
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const Popup = styled(Box)<{ ref }>`
  position: fixed;
  z-index: 99;
  bottom: 28px;
  left: -150px;
  width: 300px;
  height: 110px;
  transform: translate(calc(-50% - 8px), calc(-100% - 12px));
`;
