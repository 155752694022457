import styled from "styled-components";
import {
  SDomainTextProps,
  SInputWrapperProps,
  STooltipIconProps
} from "./types";

export const InputWrapper = styled.div<SInputWrapperProps>`
  position: relative;

  label {
    margin-bottom: 0;
  }

  & input {
    padding-right: 32px;
    color: ${({ theme }) => theme.primaryColor[500]};
    padding-left: ${({ domainLength }) => `${domainLength}px`};
    outline: none !important;
  }
`;

export const TooltipIcon = styled.div<STooltipIconProps>`
  position: absolute;
  top: ${({ hideLabel }) => (hideLabel ? "18px" : "38px")};
  right: 10px;
  width: 16px;
  height: 16px;
  flex: 0 0 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const TooltipWrapper = styled.div`
  .rc-tooltip-inner {
    padding: 8px;
    color: ${({ theme }) => theme.colors.grey[1000]};
    font-family: ${({ theme }) => theme.fontFamily.title};
    font-size: 10px;
    background-color: ${({ theme }) => theme.primaryColor[500]};
    border-radius: 4px;
    min-height: 22px;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${({ theme }) => theme.primaryColor[500]};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const DomainText = styled.div<SDomainTextProps>`
  position: absolute;
  top: ${({ hideLabel }) => (hideLabel ? "15px" : "35px")};
  left: 2px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontFamily.title};
  color: ${({ theme }) => theme.colors.blueGrey[300]};
`;
