import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ExerciseBuilder from "../view/exercise/exerciseBuilder";
import Exercises from "../view/exercise/exercisesView";
import SingleExerciseController from "./SingleExerciseController";

class ExerciseController extends React.Component {
  constructor() {
    super();
    this.state = { exercise: null };
    this.loaded = false;
  }
  render() {
    return (
      <Switch>
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exercisesDrafts}
          render={props => <Exercises tab="drafts" {...props} />}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exercisesNew}
          render={props => <ExerciseBuilder {...props} />}
        />
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.initialRouteName}
          render={props => <Exercises tab="assignments" {...props} />}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exercise}
          render={props => <SingleExerciseController {...props} />}
        />
        <Route render={() => <Redirect to={createUrl("home")} />} />
      </Switch>
    );
  }
}

export default ExerciseController;
