export const carouselVariants = {
  left: {
    x: -100,
    opacity: 0
  },
  right: {
    x: 100,
    opacity: 0
  },
  current: {
    x: 0,
    opacity: 1
  }
};
