import { Icon } from "@arcadia/design-system";
import React, { FC } from "react";
import { useResourcePreviewDetails } from "src/js/hooks/resources";
import { Resource } from "src/js/types";
import styled from "styled-components";

const ResourceMediaPreview = styled.div<{
  background: string;
}>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
`;

const ResourceIconWrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SkeletonResourcePreview = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
`;

const ResourceAssetPreview: FC<{
  isLoading?: boolean;
  resource?: Resource;
  isMediaResource?: boolean;
  mediaPublicUrl?: string;
}> = ({ isLoading, resource, isMediaResource, mediaPublicUrl }) => {
  const { getResourcePlaceholderIcon, getResourceMimeTypeIcon } =
    useResourcePreviewDetails();

  const { type, fileMimeType, publicUrls = [] } = resource || {};

  const resourceUrl = publicUrls && publicUrls.length > 0 ? publicUrls[0] : "";
  const mimeTypeIcon = getResourceMimeTypeIcon(type, fileMimeType, resourceUrl);
  const placeholderIcon = getResourcePlaceholderIcon(type, fileMimeType);

  if (isLoading) {
    return <SkeletonResourcePreview />;
  }

  const iconEl = mimeTypeIcon ? (
    <Icon width="auto" height="auto" icon={mimeTypeIcon} initialViewbox />
  ) : (
    <ResourceIconWrap>
      <Icon width="60%" height="60%" icon={placeholderIcon} initialViewbox />
    </ResourceIconWrap>
  );

  const mediaEl = mediaPublicUrl ? (
    <ResourceMediaPreview background={encodeURI(mediaPublicUrl)} />
  ) : (
    <SkeletonResourcePreview />
  );

  return isMediaResource ? mediaEl : iconEl;
};

export default ResourceAssetPreview;
