import { CreateAvatarOptions, Palette } from "@ds_themes/types";

export const createAvatarTheme = ({
  primary
}: Palette): { avatar: CreateAvatarOptions } => {
  return {
    avatar: {
      ribbon: primary.main,
      xxl: {
        mask: "170px",
        img: "170px"
      },
      xl: {
        mask: "100px",
        img: "200px"
      },
      l: {
        mask: "64px",
        img: "128px"
      },
      m: {
        mask: "40px",
        img: "80px",
        badge: {
          background: primary.main,
          text: primary.contrastText
        }
      },
      s: {
        mask: "32px",
        img: "64px"
      },
      xs: {
        mask: "16px",
        img: "32px"
      },
      image: {
        width: "100%"
      }
    }
  };
};
