// eslint-disable-next-line filenames/match-exported
import {
  BREAKPOINT_SETTINGS,
  GRID_SETTINGS,
  MAIN_SIDEBAR_SIZE
} from "src/js/theme/theme.const";
import { colors } from "@arcadia/design-system";
import { DefaultTheme } from "styled-components";

const primary1 = "#8452FF";
const primary2 = "#8B5CFF";
const primary3 = "#7613F4";
const primary4 = "#191D51";

const accent1 = "#79A5F1";
const accent2 = "#8A99B8";
const accent3 = "#C9CFDF";
const accent4 = "#F5F7FD";
const accent5 = "#2A66C6";
const accent6 = "#E1E5EF";
const accent7 = "#D8D8D8";

const paleGray = "#f5f7fd";

const semantic1 = "#FF5047";
const semantic2 = "#FF7F1B";
const semantic3 = "#80E83C";

const basicWhite = "#FFFFFF";

const WSTheme = {
  // grid
  awesomegrid: {
    mediaQuery: "only screen",
    columns: {
      // eslint-disable-next-line no-undef
      xs: GRID_SETTINGS.column_number,
      sm: GRID_SETTINGS.column_number,
      md: GRID_SETTINGS.column_number,
      lg: GRID_SETTINGS.column_number,
      xl: GRID_SETTINGS.column_number
    },
    gutterWidth: {
      xs: GRID_SETTINGS.gutter,
      sm: GRID_SETTINGS.gutter,
      md: GRID_SETTINGS.gutter,
      lg: GRID_SETTINGS.gutter,
      xl: GRID_SETTINGS.gutter
    },
    paddingWidth: {
      xs: 1,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    },
    container: {
      xs: "full", // width: 100%
      sm: 44.875, // 718px
      md: 58.6875, // 939px
      lg: 52.5625, // 841px
      xl: 64.25 // 1028px
    },
    breakpoints: BREAKPOINT_SETTINGS
  },

  // vars
  shadows: {
    default: "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
    panels: "1px 1px 11px 6px rgb(24 24 24 / 5%)",
    modals: "0 2px 20px 0 rgb(0 0 0 / 10%)"
  },
  background: {
    semiTransparent: "rgba(0, 0, 0, 0.5)"
  },
  colors: {
    ...colors,
    // basics
    basicWhite,
    transparent: "transparent",
    basicBlack: "#181818",
    basicError: "#f24e29",

    kiwiGreen: "#80e83c",
    lightBlueGrey: accent3,
    paleGray,
    darkGray: "#626262",
    darkestGray: "#3D3D3D",
    warningBk: "#ffc013",
    warningColor: "#c25f02",
    defaultBorderColor: "#c4cbdb",
    slate: "#4b556c",

    // should be added in styleguide
    disabledBk: paleGray,
    disabled: accent3,

    // styleGuide
    // new colors scheme from zeplin
    primary1,
    primary2,
    primary3,
    primary4,

    semantic1,
    semantic2,
    semantic3,

    // buttons
    customButtonPrimaryBk: primary1,
    customButtonPrimaryBorder: primary1,
    customButtonPrimaryText: basicWhite,

    // inputs
    inputColor: "#ffffff",
    inputBackground: "#c4cbdb",
    inputBorderColor: paleGray,
    inputColorActive: "#a3a5a9",
    inputBackgroundActive: "#93a0be",
    inputBorderColorActive: paleGray,
    inputColorDisabled: "#a3a5a9",
    inputBackgroundDisabled: paleGray,
    inputBorderColorDisabled: paleGray,

    // accents
    accent1,
    accent2,
    accent3,
    accent4,
    accent5,
    accent6,
    accent7,

    // light versions
    lightPrimary1: "#D8E5FB",
    lesslightPrimary1: "#6895E1",
    lightPrimary3: "#D7BBFC",
    lightSemantic1: "#FDF5F5",
    lightSemantic3: "#E6FAD8",

    // transparents
    transparent2: "hsla(0,0%,100%,0.2)",
    transparent3: "hsla(0,0%,100%,0.3)",
    transparent9: "hsla(0,0%,100%,0.9)",

    overlay1: "rgba(0, 0, 0, 0.5)",
    overlay2: "rgba(0, 0, 0, 0.15)",
    overlayWhite: "rgba(255, 255, 255, 0.7)",
    transparentPrimary3: "#7613F4CC",
    transparentPrimary2: "#37425BE6",

    // layout
    headerPrimaryColor: primary1,
    headerTextColor: "#ffffff",
    // headerSecondaryColor: increaseBrightness({
    //   hexColor: primary1,
    //   percent: 20
    // }),
    headerSecondaryColor: "#9C80FF", // violet[600]
    headerTextSecondaryColor: "#C7B7FF", // violet[700]
    // unloggedLayoutTextColor: basicWhite,

    // exercise
    exerciseLabelSuccess: "#45D2AD",
    exerciseLabelError: "#ff5047",
    exerciseLabelWarning: "#FFb22e",

    // resources
    resourceGreen: "#77E245",

    // pills
    pillGray: "#E1E5EF33",
    pillBlue: "#3D7DEA33",
    pillOrange: "#FF7F1B33",
    pillYellow: "#FFFF0033",
    pillRed: "#FF504733",
    pillPurple: "#7613F433",
    pillGreen: "#80E83C33",

    // boards
    boardBlue: "#6AB7E2",
    boardOrange: "#FF7F1B",
    boardYellow: "#F8EE33",
    boardRed: "#FF251A",
    boardPurple: "#9C80FF",
    boardGreen: "#80E83C",

    // notification icon
    iconRed: "#F95859"
  },
  fontFamily: {
    default: "WeSchoolSans",
    title: "WeSchoolSans"
  },
  fontSizes: {
    base: ".875rem", // should be in rem
    xxs: ".525rem",
    xs: ".6125rem",
    sm: ".71rem",
    large: "1rem",
    xlarge: "1.575rem",
    xxlarge: "3.5rem",
    huge: "5.25rem"
  },
  radius: {
    base: "8px",
    medium: "20px"
  },
  margin: {
    base: "15px"
  },
  lineHeights: {
    base: "20px"
  },
  weights: {
    base: "400",
    semiBold: "500",
    bold: "600",
    boldest: "700"
  },
  padding: {
    base: "15px",
    medium: "30px",
    large: "45px",
    xlarge: "60px"
  },
  zIndex: {
    behindMore: -2,
    behind: -1,
    base: 0,
    comboBody: 100,
    chatInput: 200, // overlay to toolbarmobile
    forFloatingButton: 990,
    forDrawer: 891,
    forDrawerOverlay: 890,
    forMobileOverlay: 1000,
    forCalendarEventModal: 969,
    forModal: 1100,
    // resource modal have 9001, loader will be displayed under this
    // resource modal should be refactored and z-index normalized
    forPersistentModal: 1099,
    header: 900,
    headerMain: 900,
    headerWhite: 899,
    overHeader: 901,
    topHeader: 948,
    topBanner: 950,
    quickActionButton: 100,
    sidebar: 940,
    trialCard: 945,
    toolbar: 951,
    toolbarMobile: 100,
    toTheSky: 999,
    up: 100,
    upper: 110
  },
  touchDimension: "40px",
  sizes: {
    mainSidebarWidth: MAIN_SIDEBAR_SIZE,
    fullHeaderHeight: "126px",
    defaultToolbarWidth: "60px",
    defaultMobileToolbarHeight: "60px",
    defaultHeaderHeight: "54px",
    defaultHeaderSecondaryHeight: "80px",
    defaultHeaderSecondaryInnerHeight: "60px",
    defaultMobileHeaderHeight: "80px",
    defaultMobileHeaderSecondaryHeight: "60px",
    safeAreaInsetBottom: "env(safe-area-inset-bottom) / 4",
    topBannerHeight: "40px"
  }
};

export default WSTheme;

export type MainTheme = typeof WSTheme & {
  whiteTheme: DefaultTheme;
  greyTheme: DefaultTheme;
  primaryColor: string;
  weschoolTheme: {
    white: DefaultTheme;
    grey: DefaultTheme;
  };
};
