import React, { ReactNode } from "react";
import { Heading } from "@arcadia/design-system";

import * as S from "./TitleBlock.styles";

export type TitleBlockProps = {
  backComponent?: ReactNode;
  title: string;
  subtitle?: string;
};

const TitleBlock = ({ backComponent, title, subtitle }: TitleBlockProps) => {
  return (
    <S.TitleBlockContainer>
      {backComponent || null}
      <S.TitleContainer>
        <Heading level="3">{title}</Heading>
        {subtitle ? <S.Subtitle>{subtitle}</S.Subtitle> : null}
      </S.TitleContainer>
    </S.TitleBlockContainer>
  );
};

export default TitleBlock;
