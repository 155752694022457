import { Box, colors } from "@arcadia/design-system";
import React, { FC, PropsWithChildren } from "react";
import WeschoolLogo from "src/img/icons/svg/weschool_logo.svg";
import WeSchoolMonogramSVG from "src/img/icons/svg/weschool_monogram.svg";
import { UnloggedGroupInformation } from "src/js/components/unlogged/UnloggedGroupInformation";
import { UnloggedInviterBox } from "src/js/components/unlogged/UnloggedInviterBox";
import { UnloggedLanguageSelector } from "src/js/components/unlogged/UnloggedLanguageSelector";
import UnloggedStoreLink from "src/js/components/unlogged/UnloggedStoreLink";
import { useStores } from "src/js/hooks";
import UnloggedLayout from "src/js/layout/unloggedLayout/UnloggedLayout";
import { InviteInfo } from "src/js/pages/registration/Register/types";
import { Background } from "src/js/pages/unlogged/components/Background";
import * as S from "./UnloggedRegisterLayout.styles";

type Props = {
  inviteInfo: InviteInfo;
};

export const UnloggedRegisterLayout: FC<PropsWithChildren<Props>> = ({
  children,
  inviteInfo
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const inviteGroupId = inviteInfo?.groupData?.group?.id;

  if (inviteGroupId) {
    return (
      <UnloggedLayout inviteInfo={inviteInfo}>
        {inviteInfo?.inviter && (
          <UnloggedInviterBox inviter={inviteInfo?.inviter} />
        )}
        <S.HeaderWrap>
          <S.LogoContainer hasInvite={!!inviteInfo}>
            <WeSchoolMonogramSVG
              width="36px"
              height="26px"
              viewBox="0 0 26 19"
            />
          </S.LogoContainer>
          <S.LanguageSelectorContainer>
            <UnloggedLanguageSelector />
          </S.LanguageSelectorContainer>
        </S.HeaderWrap>
        <S.Container
          id="register-container"
          hasGroupInvitation={!!inviteInfo?.groupData?.group}
        >
          {inviteInfo?.groupData?.group ? (
            <S.GroupInformationContainer>
              <UnloggedGroupInformation inviteInfo={inviteInfo} />
            </S.GroupInformationContainer>
          ) : null}
          <S.ChildrenContainer>{children}</S.ChildrenContainer>
          <S.MobileLinkContainer>
            <UnloggedStoreLink />
          </S.MobileLinkContainer>
        </S.Container>
      </UnloggedLayout>
    );
  }

  return (
    <Background mobileHasImage>
      {isLayoutModeMobile ? (
        <S.MobileLogoWrapper>
          <WeschoolLogo color={colors.grey[1000]} />
        </S.MobileLogoWrapper>
      ) : null}
      <Box>{children}</Box>
      <S.MobileLinkContainer>
        <UnloggedStoreLink />
      </S.MobileLinkContainer>
    </Background>
  );
};
