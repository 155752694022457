import { Palette } from "@ds_themes/types";

export const createButtonTheme = (palette: Palette) => {
  return {
    button: {
      primary: createPrimaryButtonTheme(palette).primary,
      secondary: createSecondaryButtonTheme(palette).secondary,
      tertiary: createTertiaryButtonTheme(palette).tertiary,
      danger: createDangerButtonTheme(palette).danger
    }
  };
};

export const createPrimaryButtonTheme = ({ primary }: Palette) => {
  return {
    primary: {
      background: primary.main,
      text: primary.contrastText,
      type: "cta",
      border: `1px solid ${primary.main}`,
      hover: {
        background: primary.contrastText,
        text: primary.main
      },
      focus: {
        border: `${primary.light} solid 3px`
      },
      disabled: {
        background: primary.light,
        text: primary.contrastText,
        border: `1px solid ${primary.light}`
      }
    }
  };
};

export const createSecondaryButtonTheme = ({
  grey,
  appearence,
  coldGrey
}: Palette) => {
  return {
    secondary: {
      background: grey[1000],
      text: grey[50],
      type: "cta",
      border: `1px solid ${grey[50]}`,
      hover: {
        background: grey[50],
        text: grey[1000]
      },
      focus: {
        border: `${grey[700]} solid 3px`
      },
      disabled: {
        background: appearence === "heavy" ? grey[1000] : coldGrey[900],
        text: grey[800],
        border: `1px solid ${grey[800]}`
      }
    }
  };
};

export const createTertiaryButtonTheme = ({ grey, yellow }: Palette) => {
  return {
    tertiary: {
      background: yellow[500],
      text: grey[50],
      type: "cta",
      border: `1px solid ${yellow[500]}`,
      hover: {
        background: yellow[800],
        text: grey[50]
      },
      focus: {
        border: `${yellow[800]} solid 3px`
      },
      disabled: {
        background: yellow[900],
        text: grey[700],
        border: `1px solid ${yellow[900]}`
      }
    }
  };
};

export const createDangerButtonTheme = ({ error, grey }: Palette) => {
  return {
    danger: {
      background: error[500],
      text: grey[1000],
      type: "cta",
      border: `1px solid ${error[500]}`,
      hover: {
        background: grey[1000],
        text: error[500]
      },
      focus: {
        border: `${error[400]} solid 3px`
      },
      disabled: {
        background: error[400],
        text: grey[1000],
        border: `1px solid ${error[400]}`
      }
    }
  };
};
