import React from "react";
import { useCloseOnClickOutside } from "src/js/hooks";
import { Editor } from "@tiptap/core";
import { useTranslation } from "src/js/translation";
import { Icon, Text } from "@arcadia/design-system";
import { Level } from "@tiptap/extension-heading";
import { AnimatePresence } from "framer-motion";
import { dropdownAnimationVariants } from "src/js/pages/boards/components/ModuleCreateDropdown/ModuleCreateDropdown.utils";
import {
  getIconFromTextFormat,
  getTitleAndDescriptionFromTextFormat
} from "../BaseTextEditor.utils";

import * as S from "./BaseTextEditorMenu.styles";

const AvailableHeading: Level[] = [1, 2, 3];

const TextFormattingButton = ({ editor }: { editor: Editor }) => {
  const {
    ref: formatRef,
    isOpen: isFormatOpen,
    setIsOpen: setIsFormatOpen
  } = useCloseOnClickOutside(false);

  const { translate } = useTranslation();

  const { title: textTitle, description: textDescription } =
    getTitleAndDescriptionFromTextFormat("text");
  const activeHeading = editor.getAttributes("heading").level;

  return (
    <div ref={formatRef}>
      <S.TextButton
        active={isFormatOpen}
        onClick={() => {
          setIsFormatOpen(!isFormatOpen);
        }}
      >
        <Icon
          icon={
            activeHeading
              ? getIconFromTextFormat("heading", activeHeading)
              : getIconFromTextFormat("text")
          }
          height={20}
          width={20}
          initialViewbox
        />
        <Icon
          width={6}
          height={6}
          initialViewbox
          icon={isFormatOpen ? "chevronUp" : "chevronDown"}
        />
      </S.TextButton>
      <AnimatePresence>
        {isFormatOpen && (
          <S.Dropdown
            variants={dropdownAnimationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {AvailableHeading.map(level => {
              const { title, description } =
                getTitleAndDescriptionFromTextFormat("heading", level);
              return (
                <S.DropdownItem
                  key={level}
                  onClick={() => {
                    editor.chain().focus().toggleHeading({ level }).run();
                    setIsFormatOpen(false);
                  }}
                >
                  <S.IconWrapper>
                    <Icon
                      icon={getIconFromTextFormat("heading", level)}
                      height={20}
                      width={20}
                      initialViewbox
                    />
                  </S.IconWrapper>
                  <S.TextWrapper>
                    <Text type="formTitle">{translate(title)}</Text>
                    <Text type="formSmallCaption">
                      {translate(description)}
                    </Text>
                  </S.TextWrapper>
                </S.DropdownItem>
              );
            })}

            <S.DropdownItem
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .toggleHeading({
                    level: activeHeading
                  })
                  .run();
                setIsFormatOpen(false);
              }}
            >
              <S.IconWrapper>
                <Icon
                  icon={getIconFromTextFormat("text")}
                  height={20}
                  width={20}
                  initialViewbox
                />
              </S.IconWrapper>
              <S.TextWrapper>
                <Text type="formTitle">{translate(textTitle)}</Text>
                <Text type="formSmallCaption">
                  {translate(textDescription)}
                </Text>
              </S.TextWrapper>
            </S.DropdownItem>
          </S.Dropdown>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TextFormattingButton;
