import styled from "styled-components";

export const ConfirmationAlertContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.confirmationalert.background};
  color: ${({ theme }) => theme.confirmationalert.text};
  width: 700px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ConfirmationAlertInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
`;

export const ConfirmationAlertIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ConfirmationAlertMessage = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 767px) {
    padding-right: 0px;
    padding-left: 12px;
  }
`;

export const ConfirmationAlertAction = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-flow: row-reverse;
    margin-top: 16px;
    padding-left: 12px;
  }
`;
export const ConfirmationAlertAccept = styled.div`
  white-space: nowrap;
  cursor: pointer;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.confirmationalert.decline};

  &:hover {
    text-decoration: underline;
  }

  & > span {
    white-space: nowrap;
    font-weight: 400 !important;
  }
`;
export const ConfirmationAlertDecline = styled.div`
  white-space: nowrap;
  cursor: pointer;
  color: ${({ theme }) => theme.confirmationalert.accept};

  &:hover {
    text-decoration: underline;
  }

  & > span {
    white-space: nowrap;
  }
`;
