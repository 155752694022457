import colors from "#themes/colors";

export const eventcard = {
  background: colors.grey[1000],
  text: {
    color: colors.grey[50]
  },
  secondText: {
    color: colors.grey[300]
  },
  border: `1px solid ${colors.grey[800]}`,
  colorIcon: colors.violet[501]
};
