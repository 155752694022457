import styled from "styled-components";

export const UserPreviewInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Fullname = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex: 1;
`;

export const Lastname = styled.span`
  font-weight: 500;
  margin-right: 10px;
`;

export const Mail = styled.span`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
