import React, { useEffect, useState } from "react";
import SelectDropdownOld from "src/js/components/forms/SelectDropdownOld";
import { useArrayState } from "src/js/hooks";
import { fetchFilteredGroupList } from "src/js/repository/groupsListRepository";
import { __SEARCH_GROUP_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { GroupUserRoleEnum } from "src/js/types";

const GroupSelector = ({
  doSelectGroupId = id => id,
  storybookMode = false
}) => {
  const [groupList, addGroups, resetGroups] = useArrayState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [hasNext, setHasNext] = useState(false);
  const [paginationStart, setPaginationStart] = useState(0);

  useEffect(() => {
    if (storybookMode) {
      addGroups([
        { id: 222, name: "mockGroup" },
        { id: 22, name: "mockGroup2" },
        { id: 23, name: "mockGroup3" }
      ]);
      setSelectedGroupId(222);
      return () => {};
    }
    fetchFilteredGroupList({
      name: null,
      role: GroupUserRoleEnum.Teacher,
      start: 0,
      limit: __SEARCH_GROUP_LIST_LIMIT__
    })
      .then(({ groups, has_next }) => {
        const completeGroups = [{ id: null, name: "all" }, ...groups];
        addGroups(completeGroups);
        setHasNext(has_next);
      })
      .catch(() => {
        resetGroups([]);
      });

    return () => {
      resetGroups([]);
      setPaginationStart(0);
    };
  }, []);

  const showNext = () => {
    fetchFilteredGroupList({
      name: null,
      role: GroupUserRoleEnum.Teacher,
      start: paginationStart + __SEARCH_GROUP_LIST_LIMIT__,
      limit: __SEARCH_GROUP_LIST_LIMIT__
    }).then(({ groups, has_next }) => {
      setPaginationStart(paginationStart + __SEARCH_GROUP_LIST_LIMIT__);
      addGroups(groups);
      setHasNext(has_next);
    });
  };

  return (
    <SelectDropdownOld
      id="groupSelector"
      activeOption={selectedGroupId}
      elementList={groupList}
      onChangeFunction={groupId => {
        setSelectedGroupId(groupId);
        doSelectGroupId(groupId);
      }}
      showNext={showNext}
      hasNext={hasNext}
    />
  );
};

export default GroupSelector;
