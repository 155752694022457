import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { Button, ContextualError, Text, Input } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";
import {
  addHttp,
  addHttpToUrl,
  createURL,
  isValidUrl
} from "../../../modules/utility";
import ResourceModel from "../../../models/resourceModel";
import { __ } from "../../../modules/localization";

const ResourceLink = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        context: "",
        type: "LINK"
      };
    },
    getInitialState() {
      return {
        loading: true,
        linkToAppend: "",
        type: "",
        errorPermission: false,
        urlNotEmbeddable: false,
        errorMessage: "",
        q: ""
      };
    },
    setQuery(value) {
      const self = this;
      self.setState({
        q: value,
        errorMessage: null,
        errorPermission: false,
        urlNotEmbeddable: false
      });
    },
    handleKeyPress(e) {
      if (e.key === "Enter") {
        const self = this;
        e.preventDefault();
        self.setPreview();
      }
    },
    setPreview() {
      const self = this;
      let s = self.state.q;
      self.props.setWorking(true);

      if (isValidUrl(s)) {
        s = addHttp(s);
        $.ajax({
          type: "GET",
          dataType: "json",
          url: createURL("api_iframe_embeddable"),
          data: {
            url: s
          },
          success(data) {
            if (data.result) {
              self.setState({
                linkToAppend: data.url,
                type: self.props.type,
                errorPermission: false,
                urlNotEmbeddable: false,
                errorMessage: null
              });
            } else if (self.props.type != "LINK") {
              self.props.setWorking(false);
              self.setState({
                errorPermission: true,
                errorMessage: __("url_not_embeddable")
              });
            } else {
              self.props.setWorking(false);
              self.setState({
                urlNotEmbeddable: true,
                errorMessage: __("url_not_embeddable")
              });
            }
          },
          error() {
            self.props.setWorking(false);

            self.setState({
              urlNotEmbeddable: true,
              errorMessage: __("url_not_embeddable")
            });
          }
        });
      } else {
        self.setState({
          errorMessage: __("invalid_url_error")
        });
        self.props.setWorking(false);
      }
    },
    addResource(item, e) {
      const self = this;

      self.props.setWorking(true);
      self.setState({ loading: true });

      item.link = addHttpToUrl(item.link);

      if (isValidUrl(item.link)) {
        const resource = new ResourceModel({
          original_url: item.link,
          name: item.title,
          link_description: item.htmlSnippet,
          type: "LINK"
        });
        resource.save(null, {
          success() {
            self.props.setWorking(false);
            self.setState({ loading: false });
            self.props.addFunc(resource.toJSON());
          },
          error(model, data) {
            self.props.setWorking(false);
            self.setState({ loading: false });
            const errorMsg = extractErrorMessage(
              data.responseJSON,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
          }
        });
      } else {
        self.setState({
          loading: false,
          errorMessage: __("invalid_url_error")
        });
      }
      e.stopPropagation(); // altrimenti viene invocato anche il deselect della risorsa
    },
    render() {
      const { errorMessage } = this.state;
      const self = this;
      const link = self.state.linkToAppend;
      let previewBox = "";
      let warningPermission = "";
      let forceAddResource = "";
      let buttonAdd = "";
      let item = "";
      let placeholder = "";
      const { whiteTheme, greyTheme } = this.props.theme;

      if (self.props.type == "LINK") {
        placeholder = __("insert_link");
      } else {
        placeholder = __("insert_google_link");
      }

      if (link != "") {
        item = {
          link: self.searchInput.value,
          title: "",
          htmlSnippet: ""
        };

        previewBox = (
          <div className="resource-preview-container relative">
            <div className="absolute">
              <iframe
                allow="autoplay"
                className="resource-preview"
                frameBorder="0"
                src={link}
              />
            </div>
          </div>
        );

        buttonAdd = (
          <div className="text-center margin-top-20">
            <div className="resource-attach-url-wrapper">
              <Button
                onClick={self.addResource.bind(undefined, item)}
                variant="primary"
                theme={whiteTheme}
              >
                {__("attach")}
              </Button>
            </div>
          </div>
        );
      }
      if (self.state.errorPermission) {
        warningPermission = (
          <div
            dangerouslySetInnerHTML={{ __html: __("warning_permission") }}
            className="font-18"
          />
        );
      }
      if (self.state.urlNotEmbeddable) {
        const itemForced = {
          link: self.searchInput.value,
          title: "",
          htmlSnippet: ""
        };
        forceAddResource = (
          <div className="text-center">
            <div className="margin-bottom-20 full-width">
              <Text type="body">{__("resource_warning_not_embeddable")}</Text>
            </div>
            <div className="flex-centered-column">
              <Button
                theme={greyTheme}
                variant="primary"
                onClick={self.addResource.bind(undefined, itemForced)}
              >
                {__("allega URL")}
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="resource__search">
          <div
            className="resource__search-form form-group"
            onKeyPress={self.handleKeyPress}
          >
            <Input
              id="searchInput"
              theme={greyTheme}
              name="searchInput"
              label="searchInput"
              hideLabel
              placeholder={placeholder}
              onChange={e => self.setQuery(e?.target?.value)}
              ref={searchInput => {
                this.searchInput = searchInput;
              }}
              rounded
            />
            <Button
              theme={greyTheme}
              variant="primary"
              onClick={self.setPreview}
            >
              {__("preview")}
            </Button>
          </div>
          {errorMessage && (
            <div className="margin-top-10 resource__search-form">
              <ContextualError theme={whiteTheme} text={errorMessage} />
            </div>
          )}
          <div className="resource__search-results" ref="container">
            {warningPermission}
            {forceAddResource}
            {previewBox}
            {buttonAdd}
          </div>
        </div>
      );
    }
  })
);

export default withTheme(ResourceLink);
