import { ActionItem } from "@arcadia/design-system";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { EventCategory } from "src/js/pages/calendar/Calendar.types";
import * as S from "./CalendarCreationSheet.styles";
import { CalendarCreationSheetProps } from "./CalendarCreationSheet.types";

const CalendarCreationSheet = ({
  open,
  setOpen,
  onTypeSelection
}: CalendarCreationSheetProps) => {
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <ModalLayout
      labels={null}
      mobileProps={{ withAdaptiveHeight: true, withHeaderLabels: false }}
      closeModal={() => {
        setOpen(false);
      }}
    >
      <S.BottomSheetBody>
        <ActionItem
          theme={greyTheme}
          title={translate("calendar_creation_dropdown_live_title")}
          subtitle={translate("calendar_creation_dropdown_live_description")}
          icon="videoCamera"
          onClick={() => {
            setOpen(false);
            onTypeSelection(EventCategory.Live);
          }}
        />
        <ActionItem
          theme={greyTheme}
          title={translate("calendar_creation_dropdown_activity_title")}
          subtitle={translate(
            "calendar_creation_dropdown_activity_description"
          )}
          icon="checkList"
          onClick={() => {
            setOpen(false);
            onTypeSelection(EventCategory.Activity);
          }}
        />
        <ActionItem
          theme={greyTheme}
          title={translate("calendar_creation_dropdown_deadline_title")}
          subtitle={translate(
            "calendar_creation_dropdown_deadline_description"
          )}
          icon="stopwatch"
          onClick={() => {
            setOpen(false);
            onTypeSelection(EventCategory.Deadline);
          }}
        />
      </S.BottomSheetBody>
    </ModalLayout>
  );
};

export default CalendarCreationSheet;
