import styled from "styled-components";

export const SearchBar = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 16px 24px;
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[400]};
    font-size: 16px;
    font-weight: 400;
  }
`;

export const SearchBarWrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 32px;
  width: 100%;
  box-shadow: inset 0 0 0 2px transparent;

  transition: box-shadow 0.3s ease-in-out;

  &:focus-within {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.violet[700]};
  }
`;

export const SearchButton = styled.button`
  border: none;
  outline: none;
  padding: 16px 24px;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;
