import { Button, Heading, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 16px 50px 16px;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

export const ActionButtons = styled.div`
  margin-top: 36px;
  display: flex;
  flex-flow: column;
  gap: 20px;
  align-items: center;
  width: 100%;
`;

export const IllustrationContainer = styled.div`
  margin-bottom: 12px;
`;

export const LogoutText = styled(Heading)`
  max-width: 215px;
  text-align: center;
`;

export const Cancel = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const LogoutButton = styled(Button)`
  padding: 18px 25px;
  border-radius: 40px;
`;
