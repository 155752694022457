import { BaseComponentProps, Box, Button } from "@arcadia/design-system";
import React, { FC, FormEventHandler, PropsWithChildren } from "react";
import { useHistory } from "react-router";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  formId: string;
  isDisabled?: boolean;
  isLoading?: boolean;
} & Pick<BaseComponentProps, "className">;

const ModalBody: FC<PropsWithChildren<Props>> = ({
  children,
  onSubmit,
  isDisabled,
  isLoading,
  className,
  formId
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    ModalStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const history = useHistory();

  const onClose = () => {
    if (isLayoutModeMobile) {
      history.goBack();
    } else {
      ModalStore.closeModal();
    }
  };

  return (
    <form id={formId} onSubmit={onSubmit} className={className}>
      <div className="main-modal__content ">
        {children}
        <div>
          <div className="settings-edit-buttons">
            <Box display="flex" gap={10} padding="10px 0px 40px" marginTop={56}>
              <Button
                onClick={onClose}
                theme={whiteTheme}
                variant="secondary"
                disabled={isLoading}
              >
                {translate({ text: "cancel_button_tooltip" })}
              </Button>
              <Button
                type="submit"
                theme={whiteTheme}
                variant="primary"
                disabled={isDisabled || isLoading}
              >
                {translate({ text: "confirm_button_label" })}
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ModalBody;
