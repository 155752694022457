import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
  margin-top: 32px;
  justify-content: center;

  @media screen and (max-width: 767px) {
    margin-top: 6px;
  }
`;
