import styled, { css } from "styled-components";
import { ListItemStyleProps } from "./types";

export const ListItem = styled.button<ListItemStyleProps>`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: ${({ theme }) => theme.colors.grey[50]};

  @media (max-width: 767px) {
    height: 44px;
    padding: 12px;
    border-radius: 12px;
  }

  svg {
    color: ${({ theme }) => theme.primaryColor[500]};
    margin-right: 12px;
  }

  :hover,
  :focus-within {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }

  ${({ redShade }) =>
    redShade &&
    css`
      &,
      svg {
        color: ${({ theme }) => theme.colors.error[500]};
      }
      :hover,
      :focus-within {
        background-color: ${({ theme }) => theme.colors.error[900]};
      }
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.grey[600]};
      pointer-events: none;

      svg {
        color: ${({ theme }) => theme.colors.grey[600]};
      }
    `};
`;
