import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { Icon } from "@arcadia/design-system";
import * as S from "./HeaderClean.styles";

const HeaderClean = ({ pageTitle, backFunction = null }) => {
  return (
    <header className="header__clean ">
      <Translate text={pageTitle} />
      {backFunction ? (
        <S.IconContainer onClick={backFunction}>
          <Icon icon="close" width={15} height={15} />
        </S.IconContainer>
      ) : null}
    </header>
  );
};

export default HeaderClean;
