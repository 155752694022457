import { makeAutoObservable } from "mobx";
import { fetchBadgesList } from "../repository/badgesRepository";
import { fetchSpacesGroups } from "../repository/dashboardRepository";
import { __DASHBOARD_BADGE_LIST_LIMIT__ } from "../settings/settingsDashboard";
import { __SPACE_GROUPS_LIST_SEARCH_LIMIT__ } from "../settings/settingsPagination";
import { SpaceBadge } from "../types/models/Badge";
import SpaceStore from "./SpaceStore";

/**
 * @name SpaceBadgesListStore
 *
 * @description
 * List of the groups of a specific space
 *
 */

class SpaceBadgesListStore {
  $filteredBadges: SpaceBadge[] = [];

  $offset = 0;

  $limit = __DASHBOARD_BADGE_LIST_LIMIT__;

  $totalBadges = 0;

  $areFetched = false;

  $queryString = "";

  $totalSpaceGroups = 0; // TODO: Detele this and unify the fecth of the group of a space in unique filter between stores

  private spaceStore: SpaceStore;

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
  }

  setSpaceBadges = (badges: SpaceBadge[]) => {
    this.$filteredBadges = badges;
  };

  setOffset = (offset: number) => {
    this.$offset = offset;
  };

  setTotalBadges = (totalBadges: number) => {
    this.$totalBadges = totalBadges;
  };

  setQueryString = (query: string) => {
    this.setAreFetched(true);
    this.$queryString = query;
  };

  setAreFetched = (areFetched: boolean) => {
    this.$areFetched = areFetched;
  };

  changePage = (page: number) => {
    const newOffset: number = Math.round((page - 1) * this.$limit);
    this.setOffset(newOffset);
    this.fetchFilteredBadges(this.spaceStore.activeSpaceId);
  };

  filterResult = (query: string) => {
    this.setQueryString(query);
    this.setOffset(0);
    this.fetchFilteredBadges(this.spaceStore.activeSpaceId);
  };

  setSpaceGroupTotal = (total: number) => {
    this.$totalSpaceGroups = total;
  };

  fetchFilteredBadges = async (spaceId: string) => {
    if (!spaceId) return;
    try {
      const badges = await fetchBadgesList({
        spaceId,
        limit: this.$limit,
        offset: this.$offset,
        name: this.$queryString
      });
      this.setSpaceBadges(badges?.results);
      this.setOffset(badges?.offset);
      this.setTotalBadges(badges?.total);
    } catch {
      this.setSpaceBadges([]);
    }
  };

  spaceFetchGroups = async (spaceId: string) => {
    if (!spaceId) return;
    try {
      const groups = await fetchSpacesGroups({
        spaceId,
        limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
        offset: 0
      });
      this.setSpaceGroupTotal(groups?.total);
    } catch {
      this.setSpaceGroupTotal(0);
    }
  };

  get filteredBadges() {
    return this.$filteredBadges;
  }

  get offset() {
    return this.$offset;
  }

  get totalBadges() {
    return this.$totalBadges;
  }

  get totalSpaceGroups() {
    return this.$totalSpaceGroups;
  }

  get queryString() {
    return this.$queryString;
  }

  get areFetched() {
    return this.$areFetched;
  }

  reset = () => {
    this.$filteredBadges = [];
    this.$offset = 0;
    this.$limit = __DASHBOARD_BADGE_LIST_LIMIT__;
    this.$totalBadges = 0;
    this.$queryString = "";
    this.$areFetched = false;
  };
}

export default SpaceBadgesListStore;
