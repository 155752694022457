import { observer } from "mobx-react";
import React, { FC } from "react";
import { useFetcher, useStores } from "src/js/hooks";
import { fetchSearchGroupList } from "src/js/repository/groupsListRepository";
import fetchSearchUserList from "src/js/repository/userListRepository";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { ContextFilterKey, SearchFiltersObject, TODO } from "src/js/types";
import { FiltersSection } from "./Filters";
import { SectionItem } from "./types";

type Props = {
  appliedFilters: SearchFiltersObject;
  setAppliedFilters: (params: SearchFiltersObject) => void;
};

const contextList: ContextFilterKey[] = ["module", "exercise", "wall"];

const SearchFiltersMobile: FC<Props> = ({
  appliedFilters,
  setAppliedFilters
}) => {
  const {
    SpaceStore: { spacesList },
    UserStore: { activeUser }
  } = useStores();
  const { translate } = useTranslation();
  const { data: groupsData = [], isLoading: groupsLoading } = useFetcher(
    async () => fetchSearchGroupList(activeUser?.id as TODO)
  );
  const {
    data: { results: usersData = [] } = { results: [] },
    isLoading: usersLoading
  } = useFetcher(async () => fetchSearchUserList(0, 20));

  const modifiedSpacesList = spacesList.map<SectionItem>(({ space }) => {
    const { spaceFilter = [] } = appliedFilters || {};

    return {
      id: space.id,
      name: space.name,
      image: space.badge?.small as string,
      checked: spaceFilter?.indexOf?.(space.id) > -1,
      defaultBadgeColor: space.defaultBadgeColor
    };
  });

  const modifiedGroupsData = groupsData.map<SectionItem>(group => {
    const { groupFilter = [] } = appliedFilters || {};
    const groupId = `${group.id}`;

    return {
      id: groupId,
      name: group.name,
      image: {
        ...group.cover,
        original_url: group.cover?.originalUrl
      },
      checked: groupFilter?.indexOf?.(groupId) > -1
    };
  });

  const modifiedUsers = usersData.map<SectionItem>(user => {
    const { userFilter = [] } = appliedFilters || {};

    return {
      id: user.uuid,
      name: `${user.name} ${user.surname}`,
      image: user.avatar?.small,
      checked: userFilter?.indexOf?.(`${user.uuid}`) > -1
    };
  });

  const modifiedContexts = contextList.map<SectionItem>(context => {
    return {
      id: context,
      name: translate(context as TranslationKeys),
      checked: !!appliedFilters?.[context]
    };
  });

  return (
    <div>
      <FiltersSection
        items={modifiedSpacesList}
        title={translate("mobile_search_filters_spaces_list_title")}
        type="spaceFilter"
        onFiltersChange={setAppliedFilters}
        filters={appliedFilters}
      />
      <FiltersSection
        items={modifiedGroupsData}
        title={translate("mobile_search_filters_group_list_title")}
        type="groupFilter"
        onFiltersChange={setAppliedFilters}
        filters={appliedFilters}
        isLoading={groupsLoading}
      />
      <FiltersSection
        items={modifiedUsers}
        title={translate("mobile_search_filters_user_list_title")}
        type="userFilter"
        onFiltersChange={setAppliedFilters}
        filters={appliedFilters}
        isLoading={usersLoading}
      />
      <FiltersSection
        items={modifiedContexts}
        title={translate("mobile_search_filters_type_list_title")}
        type="contextsFilter"
        onFiltersChange={setAppliedFilters}
        filters={appliedFilters}
      />
    </div>
  );
};

export default observer(SearchFiltersMobile);
