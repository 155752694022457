import { observer } from "mobx-react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useStores } from "src/js/hooks";
import * as S from "./ConfirmDialog.styles";

const ConfirmDialog = () => {
  let domEl = document.getElementById("confirm-dialog-root");

  if (!domEl) {
    domEl = document.createElement("div");
    domEl.setAttribute("id", "confirm-dialog-root");
    document.body.appendChild(domEl);
  }

  const { ConfirmDialogStore } = useStores();

  const { body, show, size } = ConfirmDialogStore;

  const handleEscape = event => {
    if (event.keyCode === 27) ConfirmDialogStore.closeConfirmDialog();
  };

  useEffect(() => {
    if (!show) return () => {};
    document.addEventListener("keydown", handleEscape, false);
    document.body.classList.add("no-scroll");

    return () => {
      document.removeEventListener("keydown", handleEscape, false);
      document.body.classList.remove("no-scroll");
    };
  }, [show]);

  return show
    ? ReactDOM.createPortal(
        <>
          <S.ConfirmDialogOverlay
            id="confirm-dialog-overlay"
            onClick={() => ConfirmDialogStore.closeConfirmDialog()}
          />
          <S.ConfirmDialogContainer size={size}>
            {body}
          </S.ConfirmDialogContainer>
        </>,
        domEl
      )
    : null;
};

export default observer(ConfirmDialog);
