import { Palette, TooltipThemeOptions } from "@ds_themes/types";

export const createTooltipTheme = (
  palette: Palette
): { tooltip: TooltipThemeOptions } => ({
  tooltip: {
    background: palette.primary.main,
    text: palette.primary.contrastText,
    top: {
      borderWidth: "0 9px 12px 9px",
      borderColor: `transparent transparent ${palette.primary.main} transparent`
    },
    bottom: {
      borderWidth: "12px 9px 0 9px",
      borderColor: ` ${palette.primary.main} transparent transparent transparent`
    },
    left: {
      borderWidth: "9px 12px 9px 0",
      borderColor: ` transparent ${palette.primary.main} transparent transparent`
    },
    right: {
      borderWidth: "9px 0 9px 12px",
      borderColor: `transparent transparent transparent ${palette.primary.main} `
    }
  }
});
