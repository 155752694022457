import React from "react";
import { Stepper } from "src/js/components/Stepper";
import styled from "styled-components";

export const StepperContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  margin-bottom: 32px;
  bottom: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export type StepperBlockProps = {
  currentStep: number;
  totalSteps: number;
};

const StepperBlock = ({ currentStep, totalSteps }: StepperBlockProps) => {
  return (
    <StepperContainer>
      <Stepper steps={totalSteps} currentStep={currentStep} />
    </StepperContainer>
  );
};

export default StepperBlock;
