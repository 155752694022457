import { useQueryClient } from "@tanstack/react-query";
import { ConfirmationAlert } from "@arcadia/design-system";
import React, { useState } from "react";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { spacesQueryKey } from "src/js/query/spaces";
import { editGroupIsTemplate } from "src/js/repository/groupRepository";
import { useTranslation } from "src/js/translation";
import { EventDomain, SpacesGroupType, TemplatingEvent } from "src/js/types";
import { useTheme } from "styled-components";
import { ModalSetupTemplate } from "../components";

const useGroupTemplateStatus = (groupObject: SpacesGroupType) => {
  const {
    ModalStore: { openModal, closeModal },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    SpaceStore: { activeSpaceId }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const queryClient = useQueryClient();
  const { trackVirtualView } = useViewTracking();

  const [isTemplate, setIsTemplate] = useState(groupObject?.isTemplate);

  const invalidateTemplates = (spaceId: string) => {
    queryClient.invalidateQueries(spacesQueryKey.templates(spaceId));
  };

  const setTemplate = (id: number) => {
    trackVirtualView(
      WeSchoolVirtualViewDescriptions.Groups.Templates.ConfirmSetCustomTemplate
    );
    openModal(() => (
      <ModalSetupTemplate
        group={groupObject?.group}
        onConfirm={() => {
          closeModal();
          editGroupIsTemplate({
            id,
            isTemplate: true
          })
            .then(() => {
              invalidateTemplates(activeSpaceId);
              setIsTemplate(true);
              showToastSuccess({
                str: translate("toast_creation_template_success")
              });
              _trackEvent(
                EventDomain.Templating,
                TemplatingEvent.ConfirmSetCustomTemplate
              );
            })
            .catch(error => {
              showToastError({ str: error.message });
            });
        }}
      />
    ));
  };

  const unsetTemplate = (id: number) => {
    trackVirtualView(
      WeSchoolVirtualViewDescriptions.Groups.Templates
        .ConfirmUnsetCustomTemplate
    );
    const onAccept = () => {
      editGroupIsTemplate({
        id,
        isTemplate: false
      })
        .then(() => {
          invalidateTemplates(activeSpaceId);
          setIsTemplate(false);
          showToastSuccess({
            str: translate("toast_delete_template_success")
          });
          _trackEvent(
            EventDomain.Templating,
            TemplatingEvent.ConfirmUnsetCustomTemplate
          );
        })
        .catch(error => {
          showToastError({ str: error.message });
        });
    };
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("toast_ask_delete_template")}
        declineText={translate("toast_ask_delete_template_cta_cancel")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("toast_ask_delete_template_cta_confirm")}
        onAcceptFunction={() => {
          closeConfirmDialog();
          onAccept();
        }}
      />
    );
  };

  return { isTemplate, setTemplate, unsetTemplate };
};

export default useGroupTemplateStatus;
