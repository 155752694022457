import { Button, DoodleLoader, Icon } from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";

const ListSelector = ({
  itemList,
  doSelect = () => {},
  isLoading,
  title,
  hasNext,
  showNext,
  noItemMessage,
  className
}) => {
  const [activeId, setActiveId] = useState(null);
  const { whiteTheme } = useTheme();

  useEffect(() => {
    setActiveId(null);
  }, [itemList]);

  if (!itemList) return null;

  if (itemList.length === 0 && !isLoading) {
    return (
      <div className="share__search-board-wrapper">
        <div>
          <div className="empty-list-message-box">
            <div className="empty-list-message-wrapper">{noItemMessage}</div>
          </div>
        </div>
      </div>
    );
  }

  const list = itemList.map(item => {
    let activeClass = "";
    if (item.id === activeId) {
      activeClass = "list-selector__list-item--active";
    }
    return (
      <div
        key={`SelectBoardView__${item.id}`}
        className={`list-selector__list-item ${activeClass}`}
        onClick={() => {
          setActiveId(item.id);
          doSelect(item.id);
        }}
      >
        {item.name}
        {activeClass ? (
          <span className="list-selector__list-item__icon">
            <Icon icon="check" height={15} width={15} />
          </span>
        ) : null}
      </div>
    );
  });
  return (
    <div className={["list-selector", className].join(" ")}>
      {title ? (
        <p className="uppercase margin-bottom-10">
          <Translate text={title} />:
        </p>
      ) : null}
      <div className="list-selector__list">{list}</div>
      {isLoading ? <DoodleLoader theme={whiteTheme} /> : null}
      {hasNext ? (
        <div className="text-center">
          <Button
            onClick={showNext}
            disabled={isLoading}
            variant="secondary"
            theme={whiteTheme}
          >
            <Translate text="show_more" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ListSelector;
