/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { basePageModel } from "../modules/pageStatus";
import ModerationPage from "../view/moderation/moderationPage";

class ModerationController extends React.Component {
  render() {
    basePageModel.set("selectedTab", "moderation");
    return (
      <Switch>
        <Route
          exact
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.moderation}
          render={props => (
            <ModerationPage group={this.props.group} {...props} />
          )}
        />
        <Route render={() => <Redirect to={createUrl("home")} />} />
      </Switch>
    );
  }
}

export default ModerationController;
