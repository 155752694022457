import styled, { css, DefaultTheme } from "styled-components";
import { ActionButton } from "@ds_universal/data_entry/ActionButton";
import { shadow } from "@ds_themes/index";

import {
  SPictureUploaderContainerProps,
  SPictureUploaderLabelProps,
  SDropZoneProps,
  SDropZoneContainerProps
} from "./types";

export const TrashButton = styled(ActionButton)`
  position: absolute;
  top: 20px;
  right: 12px;
  border: none !important;
  box-shadow: ${shadow[500]};
  z-index: 1;
`;

export const EditIconDropZone = styled(ActionButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.pictureUploader.background};
  outline: none !important;
  svg path {
    fill: ${({ theme }) => theme.pictureUploader.color};
  }
`;

export const Container = styled.div<SPictureUploaderContainerProps>`
  max-width: ${props => props.maxWidth};
  display: block;
  position: relative;
`;

export const Label = styled.label<SPictureUploaderLabelProps>`
  ${({ visuallyHidden }) =>
    visuallyHidden &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    `}
  color: ${({ theme }) => theme.pictureUploader.label};
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 8px 0;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${({ theme }) => theme.pictureUploader.opacityImg};
  border-radius: 16px;
`;

export const DropZone = styled.div<SDropZoneProps>`
  position: relative;
  align-content: center;
  height: ${props => props.height};
  background-color: ${props => props.backgroundColor};
  border-radius: 16px;
  border: ${({ theme }) => theme.pictureUploader.border};
  input {
    display: none !important;
  }

  img {
    position: absolute;
    top: 0;
  }

  // Active & Hover
  &:hover:not(.disabled) {
    ${props => ActiveAndHoverMixin(props)}
  }
  ${props => props.dropActive && ActiveAndHoverMixin(props)}

  // View mode
  ${({ isViewMode }) =>
    isViewMode &&
    css`
      border-color: transparent;
      ${ImagePreview} {
        transition: opacity 0.3s linear 2s;
        opacity: 1;
      }
    `}

  // Disabled
  ${({ disabled }) =>
    disabled &&
    css`
      border-color: transparent;
      button {
        opacity: 0.8;
      }
    `}
`;

export const EditIconDropZoneContainer = styled.div<SDropZoneContainerProps>`
  color: ${({ theme }) => theme.pictureUploader.color};
  padding: 0 20px;
  text-align: ${props => props.textAlign};
  svg path {
    fill: ${({ theme }) => theme.pictureUploader.color};
  }
  & > svg,
  span {
    font-size: ${props => props.fontSize};
    vertical-align: middle;
  }
`;

export const Feedbacks = styled.div`
  position: relative;
  & > div {
    max-width: 100%;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/**
 * Mixin
 */

const ActiveAndHoverMixin = ({
  theme,
  disabled,
  isViewMode
}: SDropZoneProps & { theme: DefaultTheme }) => {
  if (disabled || isViewMode) {
    return null;
  }
  return css`
    border: ${theme.pictureUploader.focus.border};
    cursor: pointer;
    button {
      background-color: ${theme.pictureUploader.focus.background};
      svg path {
        fill: ${theme.pictureUploader.focus.color};
      }
    }
  `;
};
