import { DoodleLoader, Icon } from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { getStudentProgress } from "src/js/repository/boardRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { CircularProgressBar } from "../CircularProgressBar";
import TranslatedTooltip from "../TranslatedTooltip";
import * as S from "./BoardsProgressRecap.styles";
import ProgressRowLabel from "./ProgressRowLabel/ProgressRowLabel";

const BoardsProgressRecap = ({ groupId, isLoading, className }) => {
  const [boardsCount, setBoardsCount] = useState(0);
  const [completedMandatoryCount, setCompletedMandatoryCount] = useState(0);
  const [mandatoryCount, setMandatoryCount] = useState(0);
  const [milestonesCount, setMilestonesCount] = useState(0);
  const [completedMilestonesCount, setCompletedMilestonesCount] = useState(0);
  const { whiteTheme, colors } = useTheme();

  useEffect(() => {
    if (groupId) {
      getStudentProgress({ groupId }).then(data => {
        setBoardsCount(data.boards_count);
        setCompletedMandatoryCount(data.completed_mandatory_count);
        setMandatoryCount(data.mandatory_count);
        setMilestonesCount(data.milestone_count);
        setCompletedMilestonesCount(data.completed_milestone_count);
      });
    }
  }, [groupId]);

  if (!groupId) return null;

  if (isLoading)
    return (
      <S.BoardsProgressRecapLoading className={className}>
        <DoodleLoader theme={whiteTheme} />
      </S.BoardsProgressRecapLoading>
    );

  const completedPercentage = mandatoryCount
    ? Math.round((completedMandatoryCount / mandatoryCount) * 100)
    : 0;

  return (
    mandatoryCount !== 0 && (
      <S.BoardsProgressRecap className={className}>
        <S.HeaderAndGraphWrapper>
          <S.BoardsProgressRecapHeader>
            <S.HeaderTitle>
              <Translate text="boards_progress_recap_title" />
            </S.HeaderTitle>
            <TranslatedTooltip tooltipString="boards_progress_recap_hint_tooltip">
              <Icon
                icon="dangerCircle"
                height="16"
                width="16"
                color={colors.grey[500]}
              />
            </TranslatedTooltip>
          </S.BoardsProgressRecapHeader>

          <S.ContentWrapper>
            <CircularProgressBar
              sqSize={90}
              percentage={completedPercentage}
              textValue={`${completedPercentage}%`}
              strokeWidth={4}
              textColor="basicBlack"
              internalCircleDistance={4}
              internalCircleColor="basicWhite"
            />
          </S.ContentWrapper>
        </S.HeaderAndGraphWrapper>
        <S.ListWrapper>
          <ProgressRowLabel
            icon="locked"
            label={<Translate text="boards_progress_recap_required_lesson" />}
            value={`${completedMandatoryCount}/${mandatoryCount}`}
          />
          <ProgressRowLabel
            icon="book"
            label={<Translate text="boards_progress_recap_total_lesson" />}
            value={boardsCount}
          />

          <ProgressRowLabel
            icon="crownEmpty"
            label={<Translate text="boards_progress_recap_milestone" />}
            value={`${completedMilestonesCount}/${milestonesCount}`}
          />
        </S.ListWrapper>
      </S.BoardsProgressRecap>
    )
  );
};

export default BoardsProgressRecap;
