import {
  Avatar,
  Box,
  Icon,
  Text,
  ToolbarSpaceItem
} from "@arcadia/design-system";
import React from "react";
import { groupGetCover } from "src/js/GroupFunction";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { SpaceDetailsMinimal } from "src/js/types";
import { GroupDetailsMinimal } from "src/js/types/models/Group";
import { DefaultTheme, useTheme } from "styled-components";

import * as S from "./SpaceAndGroupDetails.styles";

type SpaceAndGroupDetailsProps = {
  spaceDetails: SpaceDetailsMinimal;
  groupDetails: GroupDetailsMinimal;
  theme: DefaultTheme;
  isAvailable: boolean;
};

const SpaceAndGroupDetails = ({
  spaceDetails,
  groupDetails,
  theme,
  isAvailable
}: SpaceAndGroupDetailsProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  return (
    <S.GroupAndSpaceContainer>
      <Box position="relative" height={isLayoutModeMobile ? "40px" : "64px"}>
        <Avatar
          theme={theme}
          url={groupGetCover({ cover: groupDetails.cover?.small })}
          alt="Group-Cover"
          size={isLayoutModeMobile ? "s" : "l"}
          rounded={false}
        />
        <S.AvatarWrapper>
          <ToolbarSpaceItem
            id="space-badge"
            theme={whiteTheme}
            badge={spaceDetails.badge?.small as string}
            backgroundColorCode={spaceDetails.defaultBadgeColor}
            spaceName={spaceDetails.name}
            isSelected={false}
            size="auto"
          />
        </S.AvatarWrapper>
      </Box>
      <Box marginLeft={16} display="flex" flexDirection="column">
        <S.SpaceName type="formSmallCaption">{spaceDetails.name}</S.SpaceName>
        <Text type="formField">{groupDetails.name}</Text>
      </Box>
      <S.UserCount>
        <Text type="formSmallCaption">{groupDetails.usersCount}</Text>
        <Icon icon="user" width={12} height={12} initialViewbox />
      </S.UserCount>
      {!isAvailable && (
        <S.ColoredText type="formSubtitle">
          {translate("group_join_with_code_not_active_group")}
        </S.ColoredText>
      )}
    </S.GroupAndSpaceContainer>
  );
};

export default SpaceAndGroupDetails;
