import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import QuizModel from "../../../models/quiz/quizModel";
import TrueFalseCorrectionDisplayer from "./quizCorrectionTruefalse";
import ShortAnswerCorrectionDisplayer from "./quizCorrectionShortanswer";
import OpenAnswerCorrectionDisplayer from "./quizCorrectionOpenanswer";
import MultiChoiceCorrectionDisplayer from "./quizCorrectionMultichoice";
import FillBlankCorrectionDisplayer from "./quizCorrectionFillblanks";
import MatchingCorrectionDisplayer from "./quizCorrectionMatching";
import DocumentAnswerCorrectionDisplayer from "./quizCorrectionDocumentanswer";
import BaseCorrectionDisplayer from "./quizCorrectionBase";
import renderLatex from "src/js/modules/mathjaxFunction";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";

const QuizCorrection = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: null,
        execution: null,
        quiz_execution: null,
        number: 0,
        isVideoSubquiz: false,
        /** @type {Boolean} Check if execution is "son" of videoquiz */
        parentVideoQuiz:
          undefined /** @type {Object} The parent video quiz if this is a subquiz report. Will be undefined otherwise */
      };
    },
    componentDidUpdate() {
      renderLatex();
    },

    render() {
      const self = this;
      const {
        sidebarOpen,
        reportThreads,
        quiz_execution,
        execution,
        exercise,
        number,
        isVideoSubquiz
      } = self.props;
      let item = "";
      const quiz = new QuizModel(quiz_execution.quiz);

      const filteredThread =
        quiz.getType() === "quizdocumentanswer" ||
        quiz.getType() === "quizopenanswer"
          ? reportThreads[quiz_execution.id]
          : "";

      switch (quiz.getType()) {
        case "quiztruefalse":
          item = (
            <TrueFalseCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
            />
          );
          break;

        case "quizshortanswer":
          item = (
            <ShortAnswerCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
            />
          );
          break;

        case "quizopenanswer":
          item = (
            <OpenAnswerCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
              thread={filteredThread}
            />
          );
          break;

        case "quizmultichoice":
          item = (
            <MultiChoiceCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
            />
          );
          break;

        case "quizfillblank":
          item = (
            <FillBlankCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
            />
          );
          break;

        case "quizmatching":
        case "quizfadingmatching":
          item = (
            <MatchingCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
            />
          );
          break;

        case "quizvideo":
          item = <div />; // visualizzo direttamente sottoquiz
          break;

        case "quizdocumentanswer":
          item = (
            <DocumentAnswerCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
              thread={filteredThread}
            />
          );
          break;

        default:
          item = (
            <BaseCorrectionDisplayer
              quiz_execution={quiz_execution}
              execution={execution}
              exercise={exercise}
              number={number}
              isVideoSubquiz={isVideoSubquiz}
            />
          );
          break;
      }

      return (
        <div
          id={quiz.get("id")}
          className={`relative flex quiz-review mathjax-container ${
            sidebarOpen ? "quiz-review--sidebar-open" : ""
          }`}
        >
          {item}
        </div>
      );
    }
  })
);

const QuizCorrectionDisplayer = observer(({ ...props }) => {
  const { UIStore, ReportStore } = useStores();
  const sidebarOpen = UIStore.isSideBarOpen;
  const reportThreads = ReportStore.reportThreads;

  return (
    <QuizCorrection
      {...props}
      sidebarOpen={sidebarOpen}
      reportThreads={reportThreads}
    />
  );
});

export default QuizCorrectionDisplayer;
