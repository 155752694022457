import { Palette } from "@ds_themes/types";

export const createLinkTheme = ({ primary, grey, general }: Palette) => {
  return {
    link: {
      background: general.background,
      text: primary.main,
      hover: {
        text: primary.dark
      },
      disabled: {
        text: grey[600]
      },
      focus: {
        outline: `2px solid ${primary.lighter}`,
        border: `1.5px solid ${primary.main}`
      },
      visited: {
        text: primary.darker
      }
    }
  };
};
