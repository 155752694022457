import { Palette } from "@ds_themes/types";

import colors from "../colors";

export const createCalendarTheme = ({
  general,
  primary,
  blueGrey,
  grey
}: Palette) => {
  return {
    calendar: {
      body: {
        background: general.background
      },
      label: {
        live: {
          background: primary.lighter,
          color: primary.main
        },
        activity: {
          background: colors.lagoon[900],
          color: colors.lagoon[500]
        }
      },
      cell: {
        selected: {
          color: primary.main
        },
        hover: {
          background: primary.main,
          color: grey[1000]
        },
        text: {
          color: blueGrey[200]
        },
        border: {
          color: blueGrey[800]
        },
        current: {
          background: primary.main,
          color: primary.contrastText
        },
        outer: {
          background: blueGrey[900],
          color: blueGrey[500]
        }
      }
    }
  };
};
