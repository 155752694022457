import { useInfiniteScrollFetcher, useStores } from "src/js/hooks";
import { GroupInSpaceModel } from "src/js/types";
import { fetchSpaceGroups } from "src/js/repository/spacesRepository";
import { BadgeRelatedGroupTypeFetchParams } from "./types";

const useSpaceRelatedGroups = () => {
  const {
    SpaceStore: { activeSpaceId }
  } = useStores();
  const {
    data: spaceRelatedGroups = [],
    hasMoreResults,
    fetch,
    reset: resetResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    GroupInSpaceModel[],
    BadgeRelatedGroupTypeFetchParams
  >(
    async ({ limit, page: _page, textSearch }) => {
      const data = await fetchSpaceGroups({
        spaceId: activeSpaceId,
        filterQueryString: textSearch,
        limit,
        offset: _page * limit,
        filterByUser: false
      });
      return data.results;
    },
    { lazy: true, limit: 10 }
  );

  return {
    spaceRelatedGroups,
    hasMoreResults,
    fetch,
    resetResults,
    fetchNextPage
  };
};

export default useSpaceRelatedGroups;
