import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const BlockingModalLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${({ theme }) => theme.zIndex.forModal};
`;

export const BlockingModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  padding: 12px 20px;
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 767px) {
    width: max-content;
    max-width: calc(100% - 48px);
  }
`;

export const LoaderText = styled(Text)`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.violet[500]};

  @media (max-width: 767px) {
    white-space: normal;
  }
`;
