import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import styled from "styled-components";

export const Wrapper = styled.div`
  .EditorWrapper {
    height: calc(100vh - 360px);
    width: 100%;
    max-height: 428px;
    overflow: auto;
    min-width: 592px;
    max-width: 592px;
    ${scrollbarMixin(VariantType.White)}

    padding-bottom: 300px;

    @media (max-width: 767px) {
      height: 100vh;
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      min-width: unset;
    }
  }
  .ProseMirror {
    padding: 16px;

    height: 100%;
  }
`;

export const Footer = styled.div`
  padding: 16px 0px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 12px;
`;

export const EditorWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[800]}`};
  overflow: hidden;
`;

export const RegenerateFooter = styled.div`
  background: ${({ theme }) => theme.colors.grey[1000]};
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export const RegenerateButton = styled.button`
  all: unset;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.primaryColor[700]};
  }
`;
