import { useEffect } from "react";
import { useScormChannel } from "src/js/realtime";

import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";

const RealtimeResourceSubscribe = () => {
  const { UploadStore } = useStores();
  const resourceChannel = useScormChannel(UploadStore.uploadOnWatch);

  useEffect(() => {
    if (!resourceChannel) return () => {};

    const errorSubscribe = resourceChannel.subscribe(
      "upload_scorm_error",
      data => {
        RealTimeDispatcher.trigger(
          `${UploadStore.uploadOnWatch}_upload_scorm_error`,
          data
        );
      }
    );

    const successSubscribe = resourceChannel.subscribe(
      "upload_scorm_success",
      data => {
        RealTimeDispatcher.trigger(
          `${UploadStore.uploadOnWatch}_upload_scorm_success`,
          data
        );
      }
    );

    const successSubscribet = resourceChannel.subscribe(
      "resource_preview_generated",
      data => {
        console.log(data);
      }
    );
    return () => {
      successSubscribe.unsubscribe();
      errorSubscribe.unsubscribe();
      successSubscribet.unsubscribe();
    };
  }, [resourceChannel]);

  return null;
};

export default observer(RealtimeResourceSubscribe);
