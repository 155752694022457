import { ClosableErrorThemeOptions, Palette } from "@ds_themes/types";

export const createClosableErrorTheme = ({
  error,
  grey
}: Palette): { closableerror: ClosableErrorThemeOptions } => {
  return {
    closableerror: {
      background: error[400],
      text: grey[1000]
    }
  };
};
