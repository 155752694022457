import {
  Button,
  ConfirmationAlert,
  Heading,
  Icon
} from "@arcadia/design-system";
import createReactClass from "create-react-class";
import "jquery-ui";
import each from "lodash/each";
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import isUndefined from "lodash/isUndefined";
import last from "lodash/last";
import map from "lodash/map";
import union from "lodash/union";
import moment from "moment";
import React from "react";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import { getQueryParamFromFragment } from "src/js/modules/localization";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import Timer from "../../components/timer";
import UserModel from "../../models/UserModel";
import { getActiveGroup, getActiveGroupId } from "../../modules/activeGroup";
import { InternalDispatcher } from "../../modules/dispatcher";
import { navigateTo } from "../../modules/history";
import { __ } from "../../modules/localization";
import { basePageModel } from "../../modules/pageStatus";
import { createURL } from "../../modules/utility";
import EserciziInstantReportUserItem from "./report/exerciseInstantReportUserItem";

const InstantsLive = withBackbone(
  createReactClass({
    getInitialState() {
      const self = this;
      return {
        reports: self.props.reports || []
      };
    },
    componentDidMount() {
      const self = this;
      basePageModel.set("selectedTab", "esercizi");
      basePageModel.set("pageTitle", getActiveGroup().get("name"));

      InternalDispatcher.on("instant_begin", obj => {
        const user = new UserModel(obj.agent);
        const updatedReports = [];
        if (user.get("id")) {
          each(self.state.reports, report => {
            if (report.user.id === user.get("id")) {
              report.status = "progress";
              report.start_at = moment().unix();
              // console.log(report.start_at);
            }
            updatedReports.push(report);
          });
          self.setState({
            reports: updatedReports
          });
        }
      });
      InternalDispatcher.on("quizprogress", obj => {
        const quiz_id = obj.target.id;
        const session_id = obj.target.parent.id;
        const quizInfo = obj.target.entity;
        let isLastSubquiz = false;

        if (!isUndefined(quizInfo.isInVideoQuiz)) {
          const quiz = find(self.props.exercise.get("quizzes"), {
            id: quizInfo.videoQuizId
          });
          if (last(quiz.quizzes) == find(quiz.quizzes, { id: quiz_id })) {
            isLastSubquiz = true;
          }
        }

        let user_execution;
        let execution_key = 0;
        each(self.state.reports, (val, key) => {
          if (val.session_id === session_id) {
            user_execution = val;
            execution_key = key;
          }
        });

        if (user_execution !== undefined) {
          const quizzes_completes_ids = union(
            map(user_execution.quiz_failed, "id"),
            map(user_execution.quiz_passed, "id")
          );
          const quiz_completed =
            user_execution.quiz_completed || quizzes_completes_ids;
          let quiz_done_count = user_execution.quiz_done_count;

          if (indexOf(quiz_completed, quiz_id) < 0) {
            // controllo se quiz è già fatto
            // se non è già fatto => segno come fatto
            // => aggiorno contatore
            quiz_completed.push(quiz_id);
            if (!quizInfo.isInVideoQuiz || isLastSubquiz) {
              quiz_done_count += 1;
            }

            const new_state = self.state.reports;

            new_state[execution_key].quiz_done_count = quiz_done_count;
            new_state[execution_key].quiz_completed = quiz_completed;

            self.setState({
              reports: new_state
            });
          }
        }
      });

      InternalDispatcher.on("instant_end", obj => {
        // console.log('instant ends');
        // console.log(obj);
        const self = this;
        const session_id = obj.target.id;

        let user_execution;
        let execution_key = 0;
        each(self.state.reports, (val, key) => {
          // console.log(val);
          if (val.id === session_id) {
            user_execution = val;
            execution_key = key;
          }
        });
        if (user_execution !== undefined) {
          const new_state = this.state.reports;
          $.ajax({
            type: "GET",
            dataType: "json",
            contentType: "application/json",
            url: createURL("api_exercises_reports_sessions", {
              exercise_id: self.props.exercise.get("id"),
              session_id: user_execution.id
            }),
            success(ret) {
              new_state[execution_key].quizzes_ok = ret.quiz_passed_count;
              new_state[execution_key].quizzes_nok = ret.quiz_failed_count;
              new_state[execution_key].vote = ret.vote;
              new_state[execution_key].status = "finished";
              self.setState({
                reports: new_state
              });
            }
          });
        }
      });
      InternalDispatcher.on("instant_manual_stop", () => {
        const url = createURL("exercises", { group_id: getActiveGroupId() });
        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          navigateTo(
            createUrl("module_detail", {
              group_id: getActiveGroupId(),
              module_id: moduleId
            })
          );
          return;
        }
        navigateTo(url, { trigger: true });
      });

      InternalDispatcher.on("instant_automatic_stop", () => {
        const url = createURL("exercises", { group_id: getActiveGroupId() });
        const moduleId = getQueryParamFromFragment("module");
        if (moduleId) {
          navigateTo(
            createUrl("module_detail", {
              group_id: getActiveGroupId(),
              module_id: moduleId
            })
          );
          return;
        }
        navigateTo(url, { trigger: true });
      });
    },
    componentWillUnmount() {
      InternalDispatcher.off("instant_begin");
      InternalDispatcher.off("quizprogress");
      InternalDispatcher.off("instant_end");
      InternalDispatcher.off("instant_automatic_stop");
      InternalDispatcher.off("instant_manual_stop");
    },
    abortInstant() {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={__("instant_abort_confirmation_message")}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            ConfirmDialogStore.closeConfirmDialog();
            const exerciseId = self.props.instant.exercise_id;
            const instantId = self.props.instant.id;
            $.ajax({
              type: "PATCH",
              url: createURL("api_exercises_instant_abort", {
                exercise_id: exerciseId,
                execution_id: instantId
              }),
              success() {
                showToastSuccess({ str: __("instant_abort_success_message") });
                const moduleId = getQueryParamFromFragment("module");
                if (moduleId) {
                  navigateTo(
                    createUrl("module_detail", {
                      group_id: getActiveGroupId(),
                      module_id: moduleId
                    })
                  );
                  return;
                }
                navigateTo(
                  createURL("exercises", { group_id: getActiveGroupId() }),
                  { trigger: true }
                );
              },
              error(ret) {
                const errorMsg = extractErrorMessage(
                  ret,
                  __("Attenzione, si è verificato un errore")
                );
                showToastError({ str: errorMsg });
              }
            });
          }}
        />
      );
    },
    render() {
      const group = getActiveGroup();
      const self = this;
      let mainContent = "";
      const { whiteTheme } = this.props.theme;
      const reports = self.state.reports;
      let generalInfo = "";

      if (group.isTeacher()) {
        if (reports.length > 0) {
          let counterNotStarted = 0;
          let counterProgress = 0;
          let counterEnded = 0;
          const startTime = moment
            .unix(self.props.instant.start_at)
            .format("HH:mm");
          mainContent = map(reports, execution => {
            switch (execution.status) {
              case "not_started":
                counterNotStarted += 1;
                break;
              case "progress":
                counterProgress += 1;
                break;
              case "finished":
                counterEnded += 1;
                break;
              default:
                counterNotStarted += 1;
            }
            return (
              <EserciziInstantReportUserItem
                exercise={self.props.exercise}
                execution={execution}
              />
            );
          });

          generalInfo = (
            <div className="instant-info">
              <div className="start">
                <span className="margin-right-10 hidden-xs">
                  <Icon icon="clock" width={15} height={15} />
                </span>
                <strong>{__("started_at")}</strong>: {startTime}
              </div>
              <div className="elapsed">
                <strong>{__("elapsed")}</strong>:{" "}
                <Timer start_date={self.props.instant.start_at} />
              </div>
              <div className="separator" />
              <div className="waiting">
                <span className="margin-right-10 hidden-xs">
                  <Icon icon="group" width={15} height={15} />
                </span>
                <strong>{__("not_started")}</strong>: {counterNotStarted}
              </div>
              <div className="inprogress">
                <strong>{__("in_progress")}</strong>: {counterProgress}
              </div>
              <div className="completed">
                <strong> {__("completed")}</strong>: {counterEnded}
              </div>
            </div>
          );
        } else {
          mainContent = (
            <p className="alert alert-warning">
              {__("Non ci sono report disponibili")}
            </p>
          );
        }
      } else {
        mainContent = (
          <p className="alert alert-danger">
            {__("Non hai i permessi per visualizzare questa pagina")}
          </p>
        );
      }

      return (
        <div className="margin-top-20">
          <div className="margin-bottom-20 text-center">
            <span className="margin-right-10">
              <Icon icon="thunderbolt" width={20} height={20} />
            </span>
            <Heading level="1">{this.props.exercise.get("title")}</Heading>
          </div>
          {generalInfo}
          <div className="instant-list">{mainContent}</div>
          <div className="flex-centered-column margin-top-30 margin-bottom-30 margin-top-xs-20 margin-bottom-xs-20">
            <Button
              onClick={self.abortInstant}
              variant="danger"
              theme={whiteTheme}
            >
              <Translate text="abort_instant_button_label" />
            </Button>
          </div>
        </div>
      );
    }
  })
);

export default withConfirmDialogStore(withTheme(InstantsLive));
