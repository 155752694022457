import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.basicWhite};
    padding: 24px 20px 20px 24px;
    border-radius: 12px;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 12px;
`;

export const SubmitWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: auto;
`;

export const StyledLabel = styled.label`
  all: unset;
  display: block;
  margin: 8px 0 8px;
`;

export const Spacer = styled.div`
  margin-top: 16px;
`;

export const InputWrapper = styled.div`
  margin-top: 8px;
  label {
    font-family: "Twemoji Country Flags", "WeSchoolSans";
    all: unset;
    display: block;
  }
`;
