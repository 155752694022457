import {
  Dispatch,
  ReducerAction,
  ReducerState,
  useCallback,
  useEffect,
  useReducer,
  useRef
} from "react";
import {
  FetchReducerAction,
  FetchReducerState,
  FetcherCallback,
  FetcherOptions,
  FetcherResult
} from "./types";

type Reducer<TData> = (
  state: FetchReducerState<TData>,
  { type, payload }: FetchReducerAction<TData>
) => FetchReducerState<TData>;

const initialState: FetchReducerState<unknown> = {
  isLoading: true,
  isError: false,
  error: undefined,
  data: undefined,
  isEmpty: false,
  page: 0,
  isFetchingNextPage: false,
  hasNextPage: true
};

const reducer: Reducer<unknown> = (state, { type, payload }) => {
  switch (type) {
    case "fetch": {
      const isLoading = state.page > 0 ? undefined : true;
      const isFetchingNextPage = state.page > 0 ? true : undefined;

      return {
        ...state,
        isError: false,
        error: undefined,
        isLoading,
        isFetchingNextPage
      };
    }

    case "success": {
      const { data, limit } = payload || {};

      let newData = data;

      if (state.page > 0) {
        newData = [
          ...((state.data || []) as Array<unknown>),
          ...((data || []) as Array<unknown>)
        ];
      }

      let hasNextPage = false;

      if (Array.isArray(data) && data.length >= limit) {
        hasNextPage = true;
      }

      return {
        ...state,
        isLoading: false,
        isError: false,
        error: undefined,
        page: state.page + 1,
        data: newData,
        isFetchingNextPage: false,
        hasNextPage,
        // If the newData is an array, we check if it's empty, otherwise we check if
        // the newData is undefined.
        isEmpty: Array.isArray(newData) ? newData.length === 0 : !newData
      };
    }

    case "error": {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: payload?.error
      };
    }

    case "reset": {
      return initialState;
    }

    default:
      return state;
  }
};

/**
 * useFetcher is a custom hook that allows you to fetch data from an async function
 * and get the loading state, error state and data.
 * @param cb The async function that will be called to fetch the data.
 * @returns The loading state, error state and data.
 */
const useFetcher = <TData, TSelectData = TData>(
  cb: FetcherCallback<TData>,
  options?: FetcherOptions<TData, TSelectData>
): FetcherResult<TData, TSelectData> => {
  const [state, dispatch] = useReducer(reducer, initialState) as [
    ReducerState<Reducer<TData>>,
    Dispatch<ReducerAction<Reducer<TData>>>
  ];
  const promiseRef = useRef<Promise<void>>(undefined);
  const { select, onError, onFetch, onSuccess, limit = 10 } = options || {};

  const callFetchFn = useCallback((page = 0) => {
    // If the promiseRef is undefined, we call the async function.
    // Otherwise, we don't call the async function.
    // This is to prevent the async function from being called multiple times.
    // For example, if the user clicks on a button that calls the async function,
    // and then the user clicks on the button again, the async function will not
    // be called again.
    if (!promiseRef.current) {
      promiseRef.current = (async () => {
        try {
          dispatch({ type: "fetch" });
          onFetch?.();

          let data = await cb({ start: page * limit, limit });

          if (select) {
            data = select(data) as Awaited<TData>;
          }

          dispatch({
            type: "success",
            payload: { data, limit }
          });
          onSuccess?.(data);
        } catch (error) {
          dispatch({ type: "error", payload: { error } });
          onError?.(error);
        } finally {
          // We set the promiseRef to undefined so that the async function can
          // be called again.
          promiseRef.current = undefined;
        }
      })();
    }
  }, []);

  const fetchNextPage = useCallback(async () => {
    if (state.hasNextPage) {
      callFetchFn(state.page);
    }
  }, [state.page]);

  useEffect(() => {
    // fix hot reload
    if (state.page > 0) {
      dispatch({
        type: "reset"
      });
    }

    callFetchFn();
  }, []);

  return {
    ...state,
    fetchNextPage,
    data: state.data as unknown as TSelectData
  };
};

export default useFetcher;
