import React from "react";
import { Icon } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import * as S from "./MentionList.styles";
import { useMeasureDistance } from "../../hooks/useMeasureDistance";

const MentionList = ({
  children,
  targetRef,
  renderBottom
}: {
  children: React.ReactNode;
  renderBottom: boolean;
  targetRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const {
    dynamicElementRef,
    targetElementLeft,
    dynamicElementLeft,
    targetElementWidth,
    targetElementHeight
  } = useMeasureDistance({ targetRef });
  return (
    <S.MentionInputWrapper
      ref={dynamicElementRef}
      isMobile={isLayoutModeMobile}
      targetElementLeft={targetElementLeft}
      targetElementWidth={targetElementWidth}
      targetElementHeight={targetElementHeight}
      dynamicElementLeft={dynamicElementLeft}
      renderBottom={renderBottom}
    >
      {!isLayoutModeMobile ? (
        <S.WsMentionListHeader>
          <S.WsMentionListHeaderText type="label">
            {translate("mentions_header_title")}
          </S.WsMentionListHeaderText>
        </S.WsMentionListHeader>
      ) : undefined}
      <S.WsMentionListScrollableContent className="ws-mentions-portal-list-wrapper">
        {children}
      </S.WsMentionListScrollableContent>
      {!isLayoutModeMobile ? (
        <S.WsMentionListFooter>
          <S.WsMentionListFooterSection>
            <S.WsMentionListFooterSpacer>
              <Icon
                viewBox="0 0 12 12"
                width={12}
                height={12}
                fontSize={12}
                icon="swapVertical"
              />
            </S.WsMentionListFooterSpacer>
            <S.WsMentionListFooterText type="captionInfoDetails">
              {translate("mentions_footer_navigate")}
            </S.WsMentionListFooterText>
          </S.WsMentionListFooterSection>
          <S.WsMentionListFooterSection>
            <S.WsMentionListFooterSpacer>
              <Icon
                viewBox="0 0 12 12"
                width={12}
                height={12}
                fontSize={12}
                icon="arrowTopRight"
              />
            </S.WsMentionListFooterSpacer>
            <S.WsMentionListFooterText type="captionInfoDetails">
              {translate("mentions_footer_select")}
            </S.WsMentionListFooterText>
          </S.WsMentionListFooterSection>
          <S.WsMentionListFooterSection>
            <S.WsMentionListFooterSpacer>
              <S.WsMentionListEscText type="label" theme={whiteTheme}>
                Esc
              </S.WsMentionListEscText>
            </S.WsMentionListFooterSpacer>
            <S.WsMentionListFooterText type="captionInfoDetails">
              {translate("mentions_footer_exit")}
            </S.WsMentionListFooterText>
          </S.WsMentionListFooterSection>
        </S.WsMentionListFooter>
      ) : undefined}
    </S.MentionInputWrapper>
  );
};
export default observer(MentionList);
