import React, { FC } from "react";
import * as S from "./UnderlinedSpan.styles";
import { UnderlinedSpanProps } from "./UnderlinedSpan.types";

const UnderlinedSpan: FC<UnderlinedSpanProps> = ({ children, className }) => {
  return (
    <S.RelativeContainer className={className}>
      {children}
      <S.StyledUnderlinedLinesIcon />
    </S.RelativeContainer>
  );
};

export default UnderlinedSpan;
