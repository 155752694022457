import {
  AICustomPromptToneTransformations,
  AICustomPromptTransformationValue,
  AICustomPromptInitialTransformations
} from "./AICustomPromptEditor.types";

export const __INITIAL_TRANSFORMATIONS__: AICustomPromptTransformationValue[] =
  [
    AICustomPromptInitialTransformations.TryAgain,
    AICustomPromptInitialTransformations.Extend,
    AICustomPromptInitialTransformations.Shorten,
    AICustomPromptInitialTransformations.Simplify,
    AICustomPromptInitialTransformations.Summarise,
    AICustomPromptInitialTransformations.ChangeTone
  ];

export const __TONE_TRANSFORMATIONS__: AICustomPromptTransformationValue[] = [
  AICustomPromptToneTransformations.Default,
  AICustomPromptToneTransformations.Academic,
  AICustomPromptToneTransformations.Business,
  AICustomPromptToneTransformations.Casual,
  AICustomPromptToneTransformations.Childfriendly,
  AICustomPromptToneTransformations.Confident,
  AICustomPromptToneTransformations.Conversational,
  AICustomPromptToneTransformations.Creative,
  AICustomPromptToneTransformations.Emotional,
  AICustomPromptToneTransformations.Excited,
  AICustomPromptToneTransformations.Formal,
  AICustomPromptToneTransformations.Friendly,
  AICustomPromptToneTransformations.Funny,
  AICustomPromptToneTransformations.Humorous,
  AICustomPromptToneTransformations.Informative,
  AICustomPromptToneTransformations.Inspirational,
  AICustomPromptToneTransformations.Memeify,
  AICustomPromptToneTransformations.Narrative,
  AICustomPromptToneTransformations.Objective,
  AICustomPromptToneTransformations.Persuasive,
  AICustomPromptToneTransformations.Poetic
];
