import React from "react";
import { Box } from "@arcadia/design-system";

import * as S from "./Notification.styles";

export const NotificationLoading = () => {
  return (
    <S.NotificationWrapper read>
      <S.NotificationSection>
        <S.AvatarContainerSkeleton />
        <S.NotificationTextSection>
          <S.NotificationTextWrapper>
            <Box display="flex" flexDirection="column" gap={8}>
              <S.NotificationTextSkeleton width={220} />
              <S.NotificationTextSkeleton width={120} />
            </Box>
          </S.NotificationTextWrapper>
          <S.BottomRow>
            <S.MarkReadButton>
              <S.NotificationTextSkeleton width={90} />
            </S.MarkReadButton>
          </S.BottomRow>
        </S.NotificationTextSection>
      </S.NotificationSection>
    </S.NotificationWrapper>
  );
};
