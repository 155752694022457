import { ConfirmationAlertThemeOptions, Palette } from "@ds_themes/types";

export const createConfirmationAlertTheme = ({
  yellow
}: Palette): { confirmationalert: ConfirmationAlertThemeOptions } => {
  return {
    confirmationalert: {
      background: yellow[600],
      text: yellow[50],
      accept: yellow[50],
      decline: yellow[100]
    }
  };
};
