import styled from "styled-components";

export const Container = styled.div`
  input {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.basicWhite};
    padding: 24px 20px 24px 20px;
    border-radius: 12px;
  }
`;
