import styled, { createGlobalStyle } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

// TODO: Refactor mobile header
export const GlobalStyleHideGenericHeader = createGlobalStyle`
  ${Wrapper} {
    display: none;
  }
`;
