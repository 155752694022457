import React from "react";
import { useTheme } from "styled-components";
import { Box, Button, Icon, Modal } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

import * as S from "./GroupDeleteModal.styles";

type GroupDeleteModalProps = {
  onCloseModal: () => void;
  onDeleteGroup: () => void;
};

const GroupDeleteModal = ({
  onCloseModal,
  onDeleteGroup
}: GroupDeleteModalProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  return (
    <Modal title={translate("group_delete_modal_title")} onClose={onCloseModal}>
      <Box padding={40} textAlign="center">
        <S.ImageWrapper>
          <Icon
            icon="illustration-logout"
            height={150}
            width={150}
            viewbox="0 0 150 150"
          />
        </S.ImageWrapper>
        <S.Message level="3">
          {translate("group_delete_modal_message")}
        </S.Message>
        <S.ButtonWrapper>
          <Button
            theme={whiteTheme}
            onClick={onDeleteGroup}
            fullWidth
            variant="primary"
          >
            {translate("group_delete_modal_btn_delete")}
          </Button>
          <Box cursor="pointer" onClick={onCloseModal} marginTop={16}>
            <S.CancelText type="textLink">
              {translate("group_delete_modal_btn_cancel")}
            </S.CancelText>
          </Box>
        </S.ButtonWrapper>
      </Box>
    </Modal>
  );
};

export default GroupDeleteModal;
