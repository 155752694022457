import {
  AvatarWithInitials,
  Box,
  BoxWithIcons,
  Button,
  CodeInput,
  CodeInputActions,
  colors,
  Heading,
  Text
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  codeVerification,
  resendCodeVerificationOnEmail
} from "src/js/repository/activateAccountRepository";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import createUrl from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import * as S from "./UserVerificationPage.styles";

import {
  ActivateAccountCenterContent,
  ActivateAccountWrapContent,
  ActivateAccountWrapText
} from "../ActivateAccount.styles";

const CODE_LENGTH = 4;

const UserVerificationPage = ({ onComplete }: { onComplete: () => void }) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    UIStore,
    UserStore,
    ThemeStore: { customizationSettings, hasCustomization }
  } = useStores();
  const { isLayoutModeMobile: isMobile } = UIStore;

  const refCodeInput = React.useRef<CodeInputActions>(null);
  const [codeValue, setCodeValue] = useState<string>("");

  const confirmSubmission = e => {
    codeVerification({ code: codeValue })
      .then(() => {
        UserStore.enableUser();
        onComplete();
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
    e.preventDefault();
  };

  const resendCodeOnMail = () => {
    resendCodeVerificationOnEmail()
      .then(() => {
        showToastSuccess({
          str: translate("confirm_message_resend_mail")
        });
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  const unfilledForm = codeValue.length !== CODE_LENGTH;

  return (
    <ActivateAccountWrapContent>
      <ActivateAccountCenterContent isMobile={isMobile}>
        {customizationSettings?.whiteLabelLogo && isMobile ? (
          <S.InviteSpaceBadge>
            <AvatarWithInitials
              theme={whiteTheme}
              alt={customizationSettings?.whiteLabelName}
              url={customizationSettings?.whiteLabelLogo?.originalUrl}
              name={customizationSettings?.whiteLabelName}
              avatarColor={0}
              size="medium"
            />
          </S.InviteSpaceBadge>
        ) : null}
        {customizationSettings?.whiteLabelName ? (
          <S.InviteSpaceName level="2" isMobile={isMobile}>
            {customizationSettings?.whiteLabelName}
          </S.InviteSpaceName>
        ) : null}
        <ActivateAccountWrapText>
          <BoxWithIcons
            showIcons={isMobile && !hasCustomization}
            width="max-content"
            icons={[
              {
                icon: "sunglasses",
                color: colors.violet[500],
                top: "-36px",
                left: "64px",
                width: "48px",
                height: "40px"
              }
            ]}
          >
            <Heading level="3">{translate("user_verification_title")}</Heading>
          </BoxWithIcons>
          <Text type="formDescription">
            {translate("user_verification_subtitle")}
          </Text>
        </ActivateAccountWrapText>
        <S.StyledForm isMobile={isMobile} onSubmit={confirmSubmission}>
          <S.WrapCodeInput isMobile={isMobile}>
            <BoxWithIcons
              showIcons={isMobile && !hasCustomization}
              icons={[
                {
                  icon: "tornado",
                  color: colors.violet[500],
                  top: "-40px",
                  left: "142px",
                  width: "200px",
                  height: "200px"
                }
              ]}
            >
              <CodeInput
                ref={refCodeInput}
                theme={whiteTheme}
                label={translate("user_verification_code_label")}
                length={CODE_LENGTH}
                disabled={false}
                autoFocus
                onChange={values => {
                  setCodeValue(values.join(""));
                }}
                variant={isMobile ? "other" : "self"}
              />
            </BoxWithIcons>
          </S.WrapCodeInput>
          <Button
            type="submit"
            variant="primary"
            theme={whiteTheme}
            disabled={unfilledForm}
            fullWidth
          >
            {translate("user_verification_resend_btn_confirm")}
          </Button>
          <S.FooterText type="formDescription">
            {translate({
              text: "user_verification_resend_description",
              stringVariables: { email: UserStore?.activeUser?.email }
            })}
            <S.LinkBtn onClick={resendCodeOnMail}>
              <Text type="textLink">
                {translate("user_verification_resend_link")}
              </Text>
            </S.LinkBtn>
          </S.FooterText>
          <Box marginTop={16} marginBottom={62} textAlign="center">
            <Text type="formDescription">
              {translate("user_verification_logout_text")}
              <S.LinkBtn
                onClick={() => {
                  navigateTo(createUrl("logout"));
                }}
              >
                <Text type="textLink">
                  {translate("user_verification_logout_link")}
                </Text>
              </S.LinkBtn>
            </Text>
          </Box>
        </S.StyledForm>
      </ActivateAccountCenterContent>
    </ActivateAccountWrapContent>
  );
};

export default observer(UserVerificationPage);
