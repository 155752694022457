import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import moment from "moment";
import { Avatar } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { __ } from "../../../modules/localization";
import UserModel from "../../../models/UserModel";

const EserciziInstantReportUserItem = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: {},
        execution: {},
        showArchive: true,
        live: false,
        quiz_done_count: 0,
        quizzes_count: 0,
        percentage: 0
      };
    },
    getInitialState() {
      return {
        loadingBtn: "",
        executionDate: ""
      };
    },
    getExecutionClass(percentage) {
      const self = this;
      if (percentage < 60) {
        return "error";
      }
      if (percentage < 80) {
        return "warning";
      }
      return "success";
    },
    getRenderedInstantState(state) {
      const self = this;
      switch (state) {
        case "not_started":
          return (
            <div className="status">
              <i>{__("not_started")}</i>
            </div>
          );
        case "progress": {
          const startTime = moment
            .unix(self.props.execution.start_at)
            .format("HH:mm");
          return (
            <div className="status">
              <strong>{__("started_at")}:</strong> {startTime}
            </div>
          );
        }
        case "finished": {
          const total =
            self.props.execution.quizzes_ok + self.props.execution.quizzes_nok;
          const percentage = Math.round(self.props.execution.vote * 100);
          const className = self.getExecutionClass(percentage);
          return (
            <div className="status">
              <strong>
                {__("score")}: {self.props.execution.quizzes_ok}/{total} (
                {percentage}%)
              </strong>
              <div className={["score-dot", className].join(" ")} />
            </div>
          );
        }
        default:
          return (
            <div className="status">
              <i>{__("not_started")}</i>
            </div>
          );
      }
    },
    render() {
      const self = this;
      const { execution } = self.props;
      const user = new UserModel(execution.user);
      const { whiteTheme } = this.props.theme;
      const instanceState = self.getRenderedInstantState(execution.status);

      return (
        <div className="instant-user">
          <div className="user-logo">
            <Avatar
              url={user.getAvatar("small")}
              gender={user.getGenderString()}
              theme={whiteTheme}
              alt=""
              size="l"
              rounded
            />
          </div>
          <div className="profile-name">
            {user.get("name")} {user.get("surname")}
          </div>
          {instanceState}
        </div>
      );
    }
  })
);

export default withTheme(EserciziInstantReportUserItem);
