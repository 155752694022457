import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const GroupInformationContainer = styled.div`
  width: 448px;
  margin-top: 164px;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 448px;
    margin-top: initial;
  }
`;

export const GroupCoverContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
`;

export const GroupCover = styled.img`
  width: 100%;
  height: 192px;
  border-radius: 8px;
  object-fit: cover;
`;

export const GroupInformation = styled.div`
  padding: 16px 16px 24px 16px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
`;

export const SpaceName = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const GroupName = styled.h3`
  font-size: 22px;
  margin-top: 0px;
  margin-bottom: 8px;
`;

export const GroupMembers = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
`;

export const GroupMembersAvatar = styled.div`
  display: flex;
  position: relative;
  margin-right: 16px;

  & > div {
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 50%;
  }

  & > div:nth-child(1) {
    z-index: 3;
  }

  & > div:nth-child(2) {
    z-index: 2;
    margin-left: -16px;
  }

  & > div:nth-child(3) {
    z-index: 1;
    margin-left: -16px;
  }
`;

export const MembersCounter = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const AvatarBadgeContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;

  & > div:first-child > div > div {
    border-color: ${({ theme }) => theme.colors.grey[1000]};
    padding: 0px;
  }
`;

export const GroupDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  word-break: break-all;
`;
