import { Icon as WSIcon, Text as WSText } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const SettingsWrapper = styled(motion.div)<{ headerHeight?: number }>`
  width: 100%;
  /* 100dvh - headerHeight - tabBarHeight - verticalPadding */
  min-height: calc(
    100dvh - ${({ headerHeight }) => headerHeight ?? 0}px - 60px - 64px
  );
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
`;

export const Tile = styled.div`
  width: 100%;
  min-height: 56px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
`;

export const LeftIcon = styled(WSIcon)`
  color: ${({ theme }) => theme.colors.violet[500]};
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const ChevronIcon = styled(WSIcon).attrs(() => ({
  icon: "chevronRight",
  width: 16,
  height: 16
}))`
  color: ${({ theme }) => theme.colors.violet[50]};
`;

export const Text = styled(WSText).attrs(() => ({
  type: "formField"
}))`
  color: ${({ theme }) => theme.colors.violet[50]};
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
