import { shadow } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const BasicPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 16px;
  margin-bottom: 15px;
  box-shadow: ${shadow[200]};

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ isSelectable }) =>
    isSelectable &&
    css`
      cursor: pointer;
    `}

  ${({ noShadow }) =>
    noShadow &&
    css`
      box-shadow: none;
    `}

  @media screen and (min-width: 992px) {
    padding: 30px;

    ${({ padding }) =>
      padding &&
      css`
        padding: ${padding}px;
      `}

    ${({ height }) =>
      height &&
      css`
        height: ${height}px;
      `}
  }
`;
