import { Lottie, LottieProps } from "@arcadia/design-system";
import ClaimSpaceAnimation from "Images/lottie/building-grows.json";
import styled from "styled-components";

export const LottieAnimation = styled(Lottie).attrs((props: LottieProps) => ({
  ...props,
  src: ClaimSpaceAnimation
}))`
  width: 130px;
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
  white-space: pre-wrap;
  text-align: center;
`;
