import { Box, Text } from "@arcadia/design-system";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import {
  chargebeePortal,
  ChargebeeWidgetType
} from "src/js/pages/spaces/dashboard/SpacesSettings/ChargebeeUtils";
import { fetchSpacesUsers } from "src/js/repository/dashboardRepository";
import { useTranslation } from "src/js/translation";
import { PlanType } from "src/js/types";
import { ChargebeeModal } from "../../ChargebeeModal";
import { PlanCardChip, PlanCardChipType } from "../PlanCardChip";
import * as S from "./PlanCardDetailBody.styles";

const PlanCardDetailBody: FC = () => {
  const { translate } = useTranslation();
  const {
    ModalStore: { openModal, closeModal },
    SpaceStore,
    SpacesSettingsStore
  } = useStores();
  const { activeSpaceId } = SpaceStore;
  const { subscriptionId, planSeats, nextBillingDate, planType } =
    SpacesSettingsStore?.generalSettings?.planSettings || {};

  const [totalUsers, setTotalUsers] = useState(null);

  const openChargebeeModal = (widgetType: ChargebeeWidgetType) => {
    openModal(() => (
      <ChargebeeModal
        callbackChargebeeWidget={callbacks => {
          chargebeePortal({
            subscriptionId,
            widgetType,
            callbacks
          });
        }}
        close={closeModal}
      />
    ));
  };

  useEffect(() => {
    if (!activeSpaceId) return;
    fetchSpacesUsers({
      spaceId: activeSpaceId,
      limit: 0,
      offset: 0,
      name: null,
      groups: null,
      roles: null,
      order_by: null,
      sort_order: null,
      include_disabled: null
    })
      .then(data => {
        setTotalUsers(data?.unfilteredTotal);
      })
      .catch(() => {});
  }, [activeSpaceId]);

  return (
    <S.PlanCardDetailBodyWrapper>
      <Box marginBottom={4}>
        <PlanCardChip type={PlanCardChipType.PlanActive} />
      </Box>
      {totalUsers != null && planSeats && planSeats >= 0 ? (
        <Text type="body">
          {translate("plan_card_text_used_seats")}{" "}
          <S.Bold>
            {totalUsers}/{planSeats}
          </S.Bold>
        </Text>
      ) : null}
      {nextBillingDate ? (
        <Text type="body">
          {translate("plan_card_text_next_billing")}{" "}
          <S.Bold>
            {moment(nextBillingDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
          </S.Bold>
        </Text>
      ) : null}
      <S.PlanCardDetailFooter>
        <S.StyledLinkButton
          onClick={() =>
            openChargebeeModal(ChargebeeWidgetType.EditSubscription)
          }
          icon={{
            icon: "editSecondary",
            positionIcon: "left",
            width: 16,
            height: 16
          }}
        >
          {translate("plan_card_cta_edit")}
        </S.StyledLinkButton>
        {planType !== PlanType.FREE ? (
          <S.StyledLinkButton
            onClick={() =>
              openChargebeeModal(ChargebeeWidgetType.BillingHistory)
            }
            icon={{
              icon: "document",
              positionIcon: "left",
              width: 16,
              height: 16
            }}
          >
            {translate("plan_card_cta_review")}
          </S.StyledLinkButton>
        ) : null}
      </S.PlanCardDetailFooter>
    </S.PlanCardDetailBodyWrapper>
  );
};

export default PlanCardDetailBody;
