import { useStores } from "src/js/hooks";
import { useAssessmentScores, useGroupsProgress } from "src/js/query/analytics";
import useAnalyticsFilter from "./useAnalyticsFilter";

const useLearningMetrics = () => {
  const {
    SpaceStore: { activeSpaceId },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { fromDate, toDate, aggregation, groupIds } = useAnalyticsFilter();

  const { data: groupsProgressData, isFetching: groupsProgressLoading } =
    useGroupsProgress({
      spaceId: activeSpaceId,
      offset: 0,
      limit: 6,
      withChartData: !isLayoutModeMobile,
      fromDate,
      toDate,
      aggregation,
      groupIds
    });

  const { data: assessmentScoresData, isFetching: assessmentScoresLoading } =
    useAssessmentScores({
      spaceId: activeSpaceId,
      withChartData: !isLayoutModeMobile,
      fromDate,
      toDate,
      groupIds
    });

  const {
    averageNumber: assessmentScoresNumber,
    averageNumberTrend: assessmentScoresTrend
  } = assessmentScoresData || {
    averageNumber: 0,
    averageNumberTrend: 0
  };
  const {
    averageNumber: groupsProgressNumber,
    averageNumberTrend: groupsProgressTrend
  } = groupsProgressData || {
    averageNumber: 0,
    averageNumberTrend: 0
  };

  // assessmentScoresNumber 610 = 6.1/10 = 61%
  // groupsProgressNumber 4200 = 42%
  const percentageValue = parseFloat(
    ((10 * assessmentScoresNumber + groupsProgressNumber) / 200).toFixed(1)
  );

  const trendValue = parseFloat(
    ((10 * assessmentScoresTrend + groupsProgressTrend) / 200).toFixed(1)
  );

  return {
    percentageValue,
    trendValue,
    loading: groupsProgressLoading || assessmentScoresLoading
  };
};

export default useLearningMetrics;
