import styled, { css } from "styled-components";

export const ExternalContainer = styled.div`
  box-sizing: border-box;
`;

export const CollapsableContainer = styled.div`
  display: block;
`;

export const CollapsableContent = styled.div<{
  collapsed: boolean;
  lineNumber: number;
}>`
  display: ${({ collapsed }) => (collapsed ? "-webkit-box" : "block")};
  overflow: hidden;

  width: 100%;
  ${({ collapsed, lineNumber }) =>
    collapsed &&
    css`
      -webkit-line-clamp: ${lineNumber};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export const ShowMoreCta = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};

  &:hover {
    color: ${({ theme }) => theme.primaryColor[300]};
    text-decoration: underline;
  }
`;
