import { DragOptions } from "./types";

export const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0
    };
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? 300 : -300,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  }
};

const SwipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const dragOptions = (
  paginate: (direction: number) => void,
  swipeable?: boolean
): DragOptions =>
  swipeable
    ? {
        drag: "x",
        dragConstraints: { left: 0, right: 0 },
        dragElastic: 1,
        onDragEnd: (_, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -SwipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > SwipeConfidenceThreshold) {
            paginate(-1);
          }
        }
      }
    : {};
