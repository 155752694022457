import { Editor } from "@tiptap/react";
import { Icon } from "@arcadia/design-system";
import React, { useCallback } from "react";
import { useStores } from "src/js/hooks";
import { PrepareResourcePayload } from "src/js/hooks/resources";
import { PrepareWSResource } from "src/js/hooks/resources/useResourcePayload";
import { preventAndroidKeyboardClose } from "src/js/modules/commonFunction";
import { isTouchDevice } from "src/js/modules/deviceFunction";
import { MENU_ICON_DIMENSION } from "../../TextEditorInput.const";
import { TextEditorAttachments } from "../TextEditorAttachments";
import { TextEditorEmojiButton } from "../TextEditorEmojiButton";
import { TextEditorLinkModal } from "../TextEditorLinkModal";
import * as S from "./TextEditorMenu.styles";

const TextEditorMenu = ({
  className,
  editor,
  prepareModuleResourcePayload,
  prepareWSResource
}: {
  className?: string;
  editor?: Editor;
  prepareModuleResourcePayload: PrepareResourcePayload;
  prepareWSResource: PrepareWSResource;
}) => {
  if (!editor) return null;

  const showTextEditorAttachments =
    prepareModuleResourcePayload && prepareWSResource;

  const {
    ModalStore: { closeModal, openModal }
  } = useStores();

  const toggleLinkModal = useCallback(() => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    const defaultText = state.doc.textBetween(from, to, "");
    const defaultUrl = editor.getAttributes("link")?.href;
    openModal(() => (
      <TextEditorLinkModal
        defaultText={defaultText}
        defaultUrl={defaultUrl}
        closeModal={closeModal}
        submitValues={value =>
          editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: value.linkUrl })
            .command(({ tr }) => {
              tr.insertText(value.linkText);
              return true;
            })
            .unsetMark("link")
            .insertContent(" ")
            .run()
        }
      />
    ));
  }, [editor]);

  return (
    <S.MenuWrapper className={className}>
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isActive={editor.isActive("bold")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="bold"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isActive={editor.isActive("italic")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="italic"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        isActive={editor.isActive("strike")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="strikethrough"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuSeparator />
      <S.MenuButton
        type="button"
        onClick={() => toggleLinkModal()}
        isActive={editor.isActive("link")}
      >
        <Icon
          icon="linkDiagonal"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      {showTextEditorAttachments ? (
        <TextEditorAttachments
          prepareModuleResourcePayload={prepareModuleResourcePayload}
          prepareWSResource={prepareWSResource}
        />
      ) : null}
      <S.MenuSeparator />
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive("orderedList")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="orderedList"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        isActive={editor.isActive("unorderedList")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="unorderedList"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        disabled={!editor.can().chain().focus().toggleBlockquote().run()}
        isActive={editor.isActive("blockquote")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="quote"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuSeparator />
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleCode().run()}
        isActive={editor.isActive("code")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="code"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      <S.MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        isActive={editor.isActive("codeBlock")}
        {...preventAndroidKeyboardClose}
      >
        <Icon
          icon="codeBlock"
          height={MENU_ICON_DIMENSION}
          width={MENU_ICON_DIMENSION}
          initialViewbox
        />
      </S.MenuButton>
      {isTouchDevice() ? null : (
        <>
          <S.MenuSeparator />
          <TextEditorEmojiButton editor={editor} />
        </>
      )}
    </S.MenuWrapper>
  );
};

export default TextEditorMenu;
