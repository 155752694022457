import { Box, Heading, Icon, Text } from "@arcadia/design-system";
import React, { ReactNode } from "react";

type EmptyMetricProps = {
  title: string;
  description?: string;
  children?: ReactNode;
};

const EmptyMetric = ({ children, title, description }: EmptyMetricProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      gap={12}
    >
      <Icon
        initialViewbox
        icon="illustration-no-data-folder"
        width="100"
        height="100"
      />
      <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
        <Heading level="5">{title}</Heading>
        <Text type="body">{description}</Text>
      </Box>
      {children ? <Box marginTop={8}>{children}</Box> : null}
    </Box>
  );
};

export default EmptyMetric;
