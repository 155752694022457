import { MutableRefObject, useEffect, useState } from "react";

type IntersectionMarkAsReadProps = {
  ref: MutableRefObject<HTMLDivElement>;
  isRead: boolean;
  handleMarkAsRead: () => void;
};

function useIntersectionMarkAsRead({
  ref,
  isRead,
  handleMarkAsRead
}: IntersectionMarkAsReadProps): boolean {
  const [markAsRead, setMarkAsRead] = useState(isRead);

  useEffect(() => {
    if (markAsRead) return () => {};

    const options = {
      root: document.getElementById("message-list"),
      rootMargin: "0px",
      threshold: 0.5
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          setMarkAsRead(true);
          handleMarkAsRead();
        }
      });
    }, options);

    observer.observe(ref?.current);

    return () => {
      observer.disconnect();
    };
  }, [markAsRead, handleMarkAsRead]);

  return markAsRead;
}

export default useIntersectionMarkAsRead;
