import styled, { css } from "styled-components";
import {
  ColorPickerInputProps,
  InputVariantType,
  LabelPositions,
  LabelPositionsType
} from "./ColorPickerInput.types";

const variantOptions = theme => ({
  // TODO check dark variant
  dark: {
    backgroundColor: theme.colors.accent4,
    color: theme.colors.slate,
    borderColor: theme.colors.lightBlueGrey,
    placeholderColor: theme.colors.blueGrey[500],
    focus: {
      background: theme.colors.accent4,
      color: theme.colors.slate,
      border: theme.colors.blueGrey[500]
    },
    disabled: {
      background: theme.colors.coldGrey[900],
      color: theme.colors.blueGrey[800],
      border: theme.colors.coldGrey[900]
    },
    error: {
      background: theme.colors.error[700],
      color: theme.colors.error[500],
      border: theme.colors.error[500]
    }
  },

  light: {
    backgroundColor: theme.colors.grey[1000],
    color: theme.colors.grey[50],
    borderColor: theme.colors.grey[1000],
    placeholderColor: theme.colors.grey[50],
    focus: {
      background: theme.colors.grey[1000],
      color: theme.colors.grey[50],
      border: theme.colors.violet[500]
    },
    disabled: {
      background: theme.colors.grey[1000],
      color: theme.colors.blueGrey[800],
      border: theme.colors.grey[1000]
    },
    error: {
      background: theme.colors.grey[1000],
      color: theme.colors.error[500],
      border: theme.colors.error[500]
    }
  }
});

export const AlignContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;

export const InputContainer = styled.div<{
  hasLabel: boolean;
  labelDirection: LabelPositionsType;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ hasLabel, labelDirection }) =>
    hasLabel &&
    css`
      label {
        width: ${labelDirection === LabelPositions.FromTop ? "100%" : "180px"};
      }
      @media screen and (max-width: 767px) {
        label {
          width: 100%;
        }
      }
    `};
`;

type StyledInputProps = Partial<ColorPickerInputProps> & {
  visuallyDisabled: boolean;
};

export const StyledInput = styled.input<StyledInputProps>`
  flex-grow: 1;
  width: inherit;
  display: block;
  padding: 0 20px 0 48px;
  max-height: 40px;
  line-height: 40px;
  transition: 0.3s;
  font-size: 16px;
  border: none;
  box-shadow: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer !important;

  font-family: ${({ theme }) => theme.fontFamily.title};
  border-radius: 20px;

  ${({ theme, variant }) =>
    variant &&
    css`
      background-color: ${variantOptions(theme)[variant].backgroundColor};
      color: ${variantOptions(theme)[variant].color};
      border: 1px solid ${variantOptions(theme)[variant].borderColor};

      -webkit-text-fill-color: ${variantOptions(theme)[variant].color};
      -webkit-opacity: 1;

      ::-webkit-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      ::-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-ms-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      &:hover,
      &:focus {
        background-color: ${variantOptions(theme)[variant].focus
          .background}!important;
        color: ${variantOptions(theme)[variant].focus.color}!important;
        border-color: ${variantOptions(theme)[variant].focus.border}!important;
      }
    `}

  ${({ theme, variant, hasError }) =>
    hasError &&
    css`
      background-color: ${variantOptions(theme)[variant].error.background};
      color: ${variantOptions(theme)[variant].error.color};
      border-color: ${variantOptions(theme)[variant].error.border};

      ::-webkit-input-placeholder {
        color: ${variantOptions(theme)[variant].error.color} !important;
      }

      :-moz-placeholder {
        color: ${variantOptions(theme)[variant].error.color} !important;
      }

      ::-moz-placeholder {
        color: ${variantOptions(theme)[variant].error.color} !important;
      }

      :-ms-input-placeholder {
        color: ${variantOptions(theme)[variant].error.color} !important;
      }
    `}

      ${({ theme, variant, visuallyDisabled }) =>
    visuallyDisabled &&
    css`
      cursor: not-allowed !important;
      opacity: 1;
      -webkit-text-fill-color: ${variantOptions(theme)[variant].disabled.color};
      -webkit-opacity: 1;
      background-color: ${variantOptions(theme)[variant].disabled.background};
      color: ${variantOptions(theme)[variant].disabled.color};
      border-color: ${variantOptions(theme)[variant].disabled.border};

      &:hover,
      &:focus {
        background-color: ${variantOptions(theme)[variant].disabled
          .background}!important;
        color: ${variantOptions(theme)[variant].disabled.color}!important;
        border-color: ${variantOptions(theme)[variant].disabled
          .border}!important;
      }
    `}
`;

export const Container = styled.div`
  width: 100%;
`;

export const ClickableContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const TriggerContainer = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
`;

export const Trigger = styled.div<{ backgroundColor: string }>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
`;

export const ResetButton = styled.span<{
  variant: InputVariantType;
}>`
  right: 0;
  top: 0;
  position: absolute;
  font-size: 16px;
  color: ${({ theme, variant }) => variantOptions(theme)[variant].color};
  line-height: 40px;
  width: 40px;
  text-align: center;
  z-index: ${({ theme }) => theme.zIndex.up};
  > svg {
    overflow: visible;
  }
`;

export const SketchWrapper = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.upper};
  top: 48px;
  left: 0px;
  > div {
    width: 220px !important;
    margin-bottom: 16px;
  }
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 5px;
`;
