import { makeAutoObservable } from "mobx";
import { fetchUniversityList } from "src/js/repository/universityProvider";

class UniversityStore {
  $universityList = [];

  $isLoading = false;

  $error = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  fetchUniversityListElement = async () => {
    try {
      const data = await fetchUniversityList();
      this.setIsLoading(false);
      this.setUniversityList(data);
    } catch (e) {
      this.setStoreError(e);
    }
  };

  setUniversityList = value => {
    this.$universityList = value;
  };

  setIsLoading = value => {
    this.$isLoading = value;
  };

  setStoreError = error => {
    this.$error = error;
  };

  get universityList() {
    return this.$universityList;
  }

  get isLoading() {
    return this.$isLoading;
  }
}

export default UniversityStore;
