export const POSTS_AND_COMMENTS_TOPIC = "post_and_comments";
export const NUMBER_OF_SESSIONS_TOPIC = "number_of_session";
export const EXERCISES_EXECUTION_TOPIC = "executed_exercises";
export const TESTS_EXECUTION_TOPIC = "executed_tests";
export const PERMANENCE_TIME_TOPIC = "permanence_time";
export const BOARD_ELEMENTS_VISUALIZED_TOPIC = "visualized_board_elements";

export const BY_DAY_ACCURACY = "day";
export const BY_WEEK_ACCURACY = "week";
export const BY_MONTH_ACCURACY = "month";

export const LAST_DAY_INTERVAL = "last_day_interval";
export const LAST_7_DAYS_INTERVAL = "last_7_days_interval";
export const LAST_MONTH_INTERVAL = "last_month_interval";
export const LAST_3_MONTH_INTERVAL = "last_3_month_interval";
export const LAST_6_MONTH_INTERVAL = "last_6_month_interval";
export const LAST_YEAR_INTERVAL = "last_year";

export const __TOPIC_OPTIONS__ = [
  { key: POSTS_AND_COMMENTS_TOPIC, value: "post_and_comment_topic" },
  { key: NUMBER_OF_SESSIONS_TOPIC, value: "session_number_topic" },
  { key: EXERCISES_EXECUTION_TOPIC, value: "exercise_execution_topic" },
  { key: TESTS_EXECUTION_TOPIC, value: "test_execution_topic" },
  { key: PERMANENCE_TIME_TOPIC, value: "permanence_time_topic" },
  {
    key: BOARD_ELEMENTS_VISUALIZED_TOPIC,
    value: "board_element_visualized_topic"
  }
];

export const __ACCURACY_OPTIONS__ = [
  { key: BY_DAY_ACCURACY, value: "by_day_graph_result" },
  { key: BY_WEEK_ACCURACY, value: "by_week_graph_result" },
  { key: BY_MONTH_ACCURACY, value: "by_month_graph_result" }
];

export const __INTERVAL_OPTIONS__ = [
  { key: LAST_DAY_INTERVAL, value: "last_day_graph_interval" },
  { key: LAST_7_DAYS_INTERVAL, value: "last_7_days_graph_interval" },
  { key: LAST_MONTH_INTERVAL, value: "last_month_graph_interval" },
  { key: LAST_3_MONTH_INTERVAL, value: "last_3_months_graph_interval" },
  { key: LAST_6_MONTH_INTERVAL, value: "last_6_months_graph_interval" },
  { key: LAST_YEAR_INTERVAL, value: "last_year_graph_interval" }
];
