import { Box, Input } from "@arcadia/design-system";
import React from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

type ChangeTitleModalProps = {
  closeModal: () => void;
  onConfirm: (title: string) => void;
  initialTitle: string;
};

export const ChangeTitleModal = ({
  closeModal,
  initialTitle,
  onConfirm
}: ChangeTitleModalProps) => {
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();
  const [title, setTitle] = React.useState(initialTitle);

  return (
    <ModalLayout
      closeModal={closeModal}
      mobileProps={{
        onConfirm: () => {
          onConfirm(title);
        },
        onClose: closeModal,
        confirmLabel: translate("edit"),
        closeLabel: translate("close")
      }}
      labels={{
        title: translate("text_editor_resource_change_title_mobile_modal_title")
      }}
    >
      <Box margin="24px 24px" zIndex={2000}>
        <Input
          name="resource-title"
          label="resource-title"
          theme={greyTheme}
          rounded
          hideLabel
          value={title}
          onChange={e => {
            setTitle(e.target.value);
          }}
          type="field"
          autofocus
        />
      </Box>
    </ModalLayout>
  );
};
