import { Modal } from "@arcadia/design-system";
import React from "react";
import { ModalScrollableBody } from "../ModalScrollableBody";

import * as S from "./ActivityExerciseModal.styles";

const ActivityExerciseModal = ({
  closeModal = () => {},
  title = null,
  children
}) => {
  return (
    <Modal title={title} onClose={closeModal}>
      <ModalScrollableBody maxHeight={340} maxHeightOffset={240}>
        <S.Wrapper>{children}</S.Wrapper>
      </ModalScrollableBody>
    </Modal>
  );
};

export default ActivityExerciseModal;
