import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { Box, Heading } from "@arcadia/design-system";
import { SearchResponseItem } from "src/js/types";
import SearchResultItem from "./SearchResultItem";

type Props = {
  results: SearchResponseItem[];
  keyword: string;
};

const SearchResultsDesktop = ({ results, keyword }: Props) => {
  const { translate } = useTranslation();
  if (!results.length)
    return (
      <Box marginTop={32} textAlign="center">
        <Heading level="2">
          {translate("try_new_search", {
            key: keyword ? `'${keyword}'` : "''"
          })}
        </Heading>
      </Box>
    );

  return (
    <Box>
      {results.map(element => {
        return (
          <SearchResultItem
            key={element?.id}
            item={element}
            keyword={keyword}
          />
        );
      })}
    </Box>
  );
};

export default SearchResultsDesktop;
