export const ModalSizeType = {
  Normal: "NORMAL",
  Big: "BIG",
  Adaptive: "ADAPTIVE"
};
export type ModalSizeType = (typeof ModalSizeType)[keyof typeof ModalSizeType];

export type ModalProps = {
  width?: string;
  title: string;
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  percentage?: number;
  withProgressBar?: boolean;
  hasWhiteOverlay?: boolean;
  size?: ModalSizeType;
  hasAdaptiveWidth?: boolean;
  hasAdaptiveHeight?: boolean;
};

/**
 * Style
 */

export type SModalProps = {
  border: string;
  size: ModalSizeType;
  hasAdaptiveWidth?: boolean;
};

export type SModalScrollableArea = {
  size?: ModalSizeType;
  hasAdaptiveHeight?: boolean;
};
