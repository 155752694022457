import { Icon, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ThreadItemSubtitle = styled(Text).attrs(props => ({
  ...props,
  type: "body"
}))`
  font-size: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const ThreadItemBellIcon = styled(Icon).attrs(props => ({
  ...props,
  icon: "bellEmptyCrossed",
  width: 12,
  height: 12
}))`
  height: 16px;
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const ThreadItemNotifications = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ThreadItemBadgeUsers = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  border-radius: 2px;
  padding: 0 2px;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  span {
    font-size: 14px;
  }
`;

export const ThreadItemBadge = styled.div`
  position: relative;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ThreadItemTime = styled(Text).attrs(props => ({
  ...props,
  type: "body"
}))`
  font-size: 12px;
  height: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

export const ThreadItemSideBottom = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;
`;

export const ThreadItemSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  height: 100%;
`;

export const ThreadItemContainer = styled.div`
  overflow: hidden;
  width: 100%;
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ThreadItemWrapper = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 62px;
  padding: 8px 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  &:active {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;
