import { Heading, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const FooterText = styled(Text)`
  text-align: center;
  white-space: pre-line;
`;

export const StyledForm = styled.form<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 12px;
  padding: 20px 0 40px;
  button[type="submit"] {
    margin-top: 20px;
    margin-bottom: 8px;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 100%;
    `}
`;

export const WrapCodeInput = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: auto;
    `}
`;

export const LinkBtn = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  span {
    margin-left: 4px;
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const InviteSpaceBadge = styled.div`
  margin: 40px 0 8px;
  display: flex;
  justify-content: center;
`;

export const InviteSpaceName = styled(Heading)<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.primaryColor[500]};
  text-align: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? "0px" : "-32px")};
`;
