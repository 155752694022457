export const RESOURCE_PICKER_SECTION = {
  MODULES: "modules",
  WALL: "wall",
  CHAT: "chat",
  EXERCISE: "exercise"
} as const;

export const RESOURCE_PICKER_ITEMS = {
  AIEDITOR: "aieditor",
  EDITOR: "editor",
  MODULE: "module",
  MODULE_BLOCK: "moduleBlock",
  EXERCISE: "exercise",
  AGENDA: "agenda",
  FILE: "file",
  LINK: "link",
  IMAGE: "image",
  VIDEO: "video",
  SCORM: "scorm",
  GDRIVE: "googleDrive",
  DROPBOX: "dropbox",
  PDF: "pdf",
  GDOCS: "googleDocs",
  GSHEET: "googleSheet",
  GSLIDES: "googleSlides",
  EXCEL: "excel",
  POWERPOINT: "powerpoint",
  WORD: "word",
  VIMEO: "vimeo",
  YOUTUBE: "youtube"
} as const;
