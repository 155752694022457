import React from "react";
import styled from "styled-components";
import appStoreLogo from "Images/app_store_badge.svg";
import playStoreLogo from "Images/play_store_badge.png";
import huaweiStoreLogo from "Images/huawei_store_badge.png";

const UnloggedStoreLinkContainer = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 16px;
    gap: 24px;
    width: auto;
  }
  @media (max-width: 767px) {
    width: auto;
    max-width: none;
    gap: 8px;
    margin-bottom: 100px;
  }
`;

const StoreLinkContainer = styled.div``;

const StoreLink = styled.a`
  display: flex;
  text-align: center;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 100%;
  }
`;

const StoreLinkImage = styled.img`
  height: 42px;
  width: auto;

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 35px;
  }

  @media (max-width: 767px) {
    height: 35px;
  }
`;

const UnloggedStoreLink = () => {
  return (
    <UnloggedStoreLinkContainer>
      <StoreLinkContainer>
        <StoreLink
          href="https://apps.apple.com/it/app/weschool/id1451504331"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StoreLinkImage src={appStoreLogo} alt="Scarica l'app App Store" />
        </StoreLink>
      </StoreLinkContainer>
      <StoreLinkContainer>
        <StoreLink
          href="http://play.google.com/store/apps/details?id=com.weschool.androidapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StoreLinkImage src={playStoreLogo} alt="Scarica l'app  Play Store" />
        </StoreLink>
      </StoreLinkContainer>
      <StoreLinkContainer>
        <StoreLink
          href="https://appgallery.cloud.huawei.com/ag/n/app/C102291829?channelId=Homepage+App&id=af3df68e485444228064b84be544f789&s=DE22B1DF363EAD35F7649D2A390760DCBAD86C3D2B4D83511392FCDC7C296E58&detailType=0&v="
          target="_blank"
          rel="noopener noreferrer"
        >
          <StoreLinkImage
            src={huaweiStoreLogo}
            alt="Scarica l'app Huawei Store"
          />
        </StoreLink>
      </StoreLinkContainer>
    </UnloggedStoreLinkContainer>
  );
};

export default UnloggedStoreLink;
