import styled from "styled-components";

export const EventViewContainer = styled.div`
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const TitleContainer = styled.h2`
  padding-bottom: 12px;
  margin: 12px 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent3};

  @media (max-width: 767px) {
    margin-bottom: 8px;
    border-bottom: initial;
  }
`;

export const PillContainer = styled.div`
  margin-left: -5px;
  flex: 1;
  @media (max-width: 767px) {
    div {
      margin: 0;
    }
  }
`;

export const UsersContainer = styled.div`
  margin-left: 10px;
  flex: 1;
`;

export const AvatarsContainer = styled.div`
  white-space: nowrap;
`;

export const DateContainer = styled.span`
  margin-left: 10px;
  margin-right: 15px;
`;

export const HourContainer = styled.span`
  margin-left: 10px;
  @media (max-width: 767px) {
    margin-left: 5px;
  }
`;

export const DataRow = styled.div`
  min-height: 44px;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    background-color: ${({ theme }) => theme.colors.basicWhite};
    padding: 0 1rem;
  }
`;

export const DataGrid = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 1px;
    > ${DataRow} {
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }
`;

export const DataRowLabel = styled.span`
  flex-basis: 130px;
  margin-right: 15px;
  min-width: 80px;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    flex-basis: auto;
  }
`;

export const DescriptionRow = styled.div`
  padding-top: 12px;
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    & ${DataRowLabel} {
      flex-basis: auto;
    }
  }
`;

export const DescriptionContainer = styled.span`
  flex: 1;
  @media (max-width: 767px) {
    padding-top: 12px;
  }
`;

export const EventAttachments = styled.div`
  margin-top: 28px;
  margin-bottom: 28px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0fr));
  grid-gap: 10px 10px;
  @media (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;
