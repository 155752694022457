import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import moment from "moment";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { Translate } from "src/js/translation/TranslationProvider";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";
import QuizMatchingCorrectionItem from "./QuizMatchingCorrectionItem";

const MatchingCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        execution: null,
        quiz_execution: null,
        corrector: null,
        number: 0
      };
    },
    render() {
      /* use collection in order to auto init correct submodel */
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const quiz = tempQuizCollection.at(0);
      const { quiz_execution } = this.props;
      const { execution } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();

      const column1 = filter(questions, { column: 1 }); // domande, nella colonna di sx

      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));

      const answersGiven = map(column1, (val, key) => {
        let question = val.value;
        const risposta_utente = find(userAnswers, { index_1: val.index });
        const risposta_corretta = find(solutions, { index_1: val.index });

        let obj_risposta_utente;
        let item_risposta_utente = __("quiz_not_answered_label");

        const obj_risposta_corretta = find(questions, {
          index: risposta_corretta.index_2
        });
        let item_risposta_corretta = null;

        if (question == "") {
          question = <QuizMatchingCorrectionItem item={val.resource} />;
        }
        if (obj_risposta_corretta !== undefined) {
          if (obj_risposta_corretta.value != "") {
            item_risposta_corretta = obj_risposta_corretta.value;
          } else {
            item_risposta_corretta = (
              <QuizMatchingCorrectionItem
                item={obj_risposta_corretta.resource}
              />
            );
          }
        }

        if (
          risposta_utente !== undefined &&
          risposta_utente.index_2 !== undefined
        ) {
          obj_risposta_utente = find(questions, {
            index: risposta_utente.index_2
          });
        }

        if (obj_risposta_utente !== undefined) {
          let userAnswer = __("quiz_not_answered_label");
          let quiz_class = "is-wrong";
          if (obj_risposta_utente.index === obj_risposta_corretta.index) {
            quiz_class = "is-correct";
          }

          if (obj_risposta_utente.value != "") {
            userAnswer = (
              <span
                className={["exen_summary_quiz_label", quiz_class].join(" ")}
              >
                {obj_risposta_utente.value}
              </span>
            );
          }
          if (!isEmpty(obj_risposta_utente.resource)) {
            userAnswer = (
              <QuizMatchingCorrectionItem
                className={quiz_class}
                item={obj_risposta_utente.resource}
              />
            );
          }
          item_risposta_utente = userAnswer;
        }

        return (
          <tr>
            <td>
              <div className="correction_answer-item">
                <strong>{question}</strong>
              </div>
            </td>
            <td>
              <div className="correction_answer-item">
                {item_risposta_corretta}
              </div>
            </td>
            <td>
              <div className="correction_answer-item">
                {item_risposta_utente}
              </div>
            </td>
          </tr>
        );
      });

      let voteType;
      if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      const executionData = execution.get("updated_at");
      const executionDataFormatted = moment
        .unix(executionData)
        .format("DD/MM/YYYY HH:mm");
      const executionFromNow = moment.unix(executionData).fromNow();

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon icon="pickertest-matchpairs" width="60" height="60" />
              <ColorLabel type={voteType}>
                <strong>
                  {quiz_execution.points}/{quiz.getAvailablePoints()}
                </strong>{" "}
                <Translate text="quiz_points_label" />
              </ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  <ExerciseQuizTitle quizTitle={quiz.getTitle()} />
                </div>
              </div>
              <div className="correction_answers-wrapper margin-bottom-10 unselectable">
                <div className="answer_info">
                  <CommentTitle>
                    {__("quiz_report_reply_of_label")}&nbsp;
                    {execution.getQuizExecutioner()}
                  </CommentTitle>
                  <div className="margin-left-10 margin-left-xs-0">
                    <CommentSubtitle>
                      {`${executionDataFormatted} (${executionFromNow})`}
                    </CommentSubtitle>
                  </div>
                </div>
                <table className="table table-condensed table-borderless small table-fixed">
                  <tbody>
                    <tr>
                      <th>{__("domanda")}</th>
                      <th>{__("risposta corretta")}</th>
                      <th>{__("risposta utente")}</th>
                    </tr>
                    {answersGiven}
                  </tbody>
                </table>
              </div>
              {this.props.corrector}
            </div>
          </Col>
        </>
      );
    }
  })
);

export default MatchingCorrectionDisplayer;
