import { Avatar as WSAvatar, Text as WSText } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const CheckIndicator = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.colors.coldGrey[500]};
  path {
    display: none;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.coldGrey[500]};
      path {
        display: initial;
      }
    `}
`;

export const Text = styled(WSText)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const Avatar = styled(WSAvatar)`
  width: 40px;
  height: 40px;
`;

export const UserSelectableItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  border-radius: 8px;
  padding: 8px 12px 8px 8px;
  &:active {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
  }
  &:last-child {
    margin-bottom: 16px;
  }
`;
