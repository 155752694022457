import { Button, DoodleLoader, SearchBar, Text } from "@arcadia/design-system";
import isEqual from "lodash/isEqual";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListSelector } from "src/js/components/global/ListSelector";
import { useDebounce, useInfiniteScrollFetcher, useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import {
  genericIncludes,
  genericIndexOf
} from "../../global/ListSelector/ListSelector.utils";
import { SelectableListProps } from "./SelectableList.types";

import * as S from "./SelectableList.styles";

const SelectableList = <TResult extends { [key: string]: any }>({
  onCancel,
  onConfirm,
  fetchItems,
  customComponents: { getEmptyStateFromStatus, getCustomItemCard },
  labels: {
    translatedConfirm,
    translatedCancel,
    translatedTitle,
    searchPlaceholder
  },
  multipleSelect = false,
  selectedItemsIds = [],
  onChangeSelectedItemsIds,
  withCancelButton,
  withConfirmButton = true
}: SelectableListProps<TResult>) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const [filterString, setFilterString] = useState("");
  const debouncedFilterString = useDebounce(filterString, 300);

  const [selectedIds, setSelectedIds] = useState<number[] | string[]>(
    selectedItemsIds
  );

  const prevFilterString = useRef("");

  const {
    data: list = [],
    reset,
    isLoading,
    hasMoreResults,
    fetch: fetchItemsList,
    fetchNextPage
  } = useInfiniteScrollFetcher(
    async ({ limit, page: _page, filter }) => {
      const data = await fetchItems({
        name: filter as string,
        limit,
        offset: _page * limit
      });
      return data.results;
    },
    { lazy: true, limit: 30 }
  );

  useEffect(() => {
    setSelectedIds(selectedItemsIds || []);
  }, [selectedItemsIds]);

  useEffect(() => {
    if (!isEqual(selectedIds, selectedItemsIds) && onChangeSelectedItemsIds) {
      onChangeSelectedItemsIds(selectedIds);
    }
  }, [onChangeSelectedItemsIds, selectedIds]);

  useEffect(() => {
    setFilterString("");
    reset();
    fetchItemsList();
  }, [fetchItems]);

  useEffect(() => {
    if (!debouncedFilterString.length) {
      if (prevFilterString.current !== "") {
        reset();
        fetchItemsList();
      }
      return;
    }
    fetchItemsList(0, { filter: debouncedFilterString });
    prevFilterString.current = debouncedFilterString;
  }, [debouncedFilterString]);

  const deleteOption = ({ id }: { id: string | number }) => {
    const index = genericIndexOf(selectedIds, id);
    const newSelectedIds = [...selectedIds];
    newSelectedIds.splice(index, 1);
    setSelectedIds(newSelectedIds as string[] | number[]);
  };

  const handleOptionClick = ({ id }: { id: string | number }) => {
    if (!genericIncludes(selectedIds, id)) {
      if (!multipleSelect) {
        setSelectedIds([id] as string[] | number[]);
        return;
      }
      setSelectedIds([...selectedIds, id] as string[] | number[]);
    } else {
      deleteOption({ id });
    }
  };

  return (
    <>
      <S.SelectableListWrapper>
        {translatedTitle ? (
          <S.HeaderWrapper>
            <Text type="formField">{translatedTitle}</Text>
          </S.HeaderWrapper>
        ) : null}
        <S.ScrollableArea id="area-list">
          <S.SearchBarWrapper>
            <SearchBar
              // This is not a standard, we have mobile modals with both white and grey background
              theme={isLayoutModeMobile ? greyTheme : whiteTheme}
              label="searchQuery"
              value={filterString}
              placeholder={searchPlaceholder}
              onChange={event => {
                setFilterString(event.target.value);
              }}
            />
          </S.SearchBarWrapper>
          {list.length !== 0 || isLoading ? (
            <InfiniteScroll
              scrollableTarget="area-list"
              dataLength={list.length}
              next={() => {
                fetchNextPage({ filter: debouncedFilterString });
              }}
              hasMore={hasMoreResults}
              loader={<DoodleLoader theme={greyTheme} isMini />}
              style={{ overflowX: "hidden" }}
            >
              <ListSelector
                toggleItem={({ id }) => {
                  handleOptionClick({ id });
                }}
                selectedItem={multipleSelect ? selectedIds : selectedIds[0]}
                itemList={list}
                getCustomItemCard={getCustomItemCard}
                multipleSelect={multipleSelect}
              />
            </InfiniteScroll>
          ) : (
            getEmptyStateFromStatus(
              filterString ? "empty-search" : "empty-list"
            )
          )}
        </S.ScrollableArea>
      </S.SelectableListWrapper>
      <S.FooterWrapper id="selectable-list-footer">
        {!isLayoutModeMobile && withCancelButton && (
          <Button
            onClick={onCancel}
            theme={whiteTheme}
            variant="secondary"
            fullWidth
          >
            {translatedCancel}
          </Button>
        )}
        {withConfirmButton ? (
          <Button
            disabled={!selectedIds?.length}
            onClick={() => onConfirm(selectedIds, list)}
            theme={whiteTheme}
            variant="primary"
            fullWidth
          >
            {translatedConfirm}
          </Button>
        ) : null}
      </S.FooterWrapper>
    </>
  );
};

export default SelectableList;
