import EmojiPicker from "@emoji-mart/react";
import { Editor } from "@tiptap/react";
import { Icon } from "@arcadia/design-system";
import React, { useCallback, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { EmojiOnPick } from "src/js/components/SocialInput/SocialInput.types";
import { getCurrentLanguage } from "src/js/modules/localization";
import theme from "src/js/theme/theme";
import { MENU_ICON_DIMENSION } from "../../TextEditorInput.const";
import { MenuButton } from "../TextEditorMenu/TextEditorMenu.styles";
import * as S from "./TextEditorEmojiButton.styles";

const TextEditorEmojiButton = ({ editor }: { editor: Editor }) => {
  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = useCallback(() => {
    setShowPicker(!showPicker);
  }, [showPicker]);

  const addEmoji = (e: EmojiOnPick) => {
    editor.chain().focus().insertContent(e.native).run();
  };

  return (
    <S.ButtonRelativeWrapper>
      <MenuButton type="button" onClick={togglePicker} isActive={showPicker}>
        <Icon
          icon="emoji"
          width={MENU_ICON_DIMENSION}
          height={MENU_ICON_DIMENSION}
        />
      </MenuButton>
      <AnimatePresence>
        {showPicker ? (
          <S.EmojiPickerWrapper
            data-testid="emoji-picker"
            key="emoji-picker"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <EmojiPicker
              locale={getCurrentLanguage()?.split("_")[0] || "en"}
              theme="light"
              emojiButtonRadius="8px"
              dynamicWidth
              onEmojiSelect={addEmoji}
              onClickOutside={togglePicker}
              emojiButtonColors={[theme.colors.coldGrey[600]]}
            />
          </S.EmojiPickerWrapper>
        ) : null}
      </AnimatePresence>
    </S.ButtonRelativeWrapper>
  );
};

export default TextEditorEmojiButton;
