import styled from "styled-components";

import { SChatBubbleProps } from "./types";

export const ChatBubble = styled.div<SChatBubbleProps>`
  width: 564px;
  /* TODO: Wondering why is the height prop fixed? */
  height: 40px;
  display: flex;
  background-color: ${({ theme, owner }) => theme.chatbubble[owner].background};
  color: ${({ theme, owner }) => theme.chatbubble[owner].text};
  border-radius: ${({ theme, position, owner }) =>
    theme.chatbubble[owner].borderRadius[position]};
  float: ${({ owner }) => (owner === "self" ? "right" : "left")};
  align-items: center;
  justify-content: ${({ owner }) =>
    owner === "self" ? "flex-end" : "flex-start"};
  margin: 2.5px;
  padding: 0 15px;
  &:hover {
    background-color: ${({ theme, owner }) =>
      theme.chatbubble[owner].hover.background};
  }
`;

export const Wrapper = styled.div`
  display: flow-root;
`;
