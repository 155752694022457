import styled, { css } from "styled-components";

export const ElementContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  display: flex;
  padding: 16px 24px 16px 32px;
  border-radius: 8px;

  @media screen and (max-width: 767px) {
    padding: 24px 32px;
  }

  ${({ even }) =>
    even &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[1000]};
    `}
`;

export const ElementIconContainer = styled.div`
  display: flex;
  padding-right: 16px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const ElementInfoContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  padding-right: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    white-space: initial;
    text-overflow: initial;
    overflow: initial;
  }
`;

export const ElementRadioContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 767px) {
    align-items: start;
  }
`;

export const ElementRadio = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    margin: 3px;
    border-radius: 50%;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.primaryColor[500]};

      &:before {
        background-color: ${({ theme }) => theme.primaryColor[500]};
      }
      &:hover {
        &:before {
          background-color: ${({ theme }) => theme.primaryColor[400]};
        }
      }
    `}

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor[400]};
  }
`;

export const ElementDescription = styled.div`
  margin-top: 5px;
  display: flex;
  color: ${({ theme }) => theme.colors.grey[300]};
`;
