import React, { useEffect } from "react";
import { useOnboarding } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import {
  __EXAMPLE_BACKGROUND_PATTERN__,
  __EXAMPLE_MEMBERS_INVITE_VIDEO__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import styled, { useTheme } from "styled-components";
import EmailInvite from "../../group/settings/GroupInviteMemberModal/components/EmailInvite";
import Example from "../components/Example";
import StepperBlock from "../components/StepperBlock";
import { TitleBlock } from "../components/TitleBlock";

const FormContainer = styled.div`
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 24px 20px 24px 20px;
  }
`;

const MembersInvite = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { routeGuard } = useOnboarding();

  useEffect(() => {
    routeGuard();
  }, []);

  const membersInvite = (
    <FormContainer>
      <TitleBlock
        title={translate("onboarding_business_invite_members_page_title")}
        subtitle={translate("onboarding_business_invite_members_page_subtitle")}
      />
      <EmailInvite theme={whiteTheme} />
    </FormContainer>
  );

  return (
    <OnboardingLayout
      mainContent={membersInvite}
      sideContent={
        <Example
          background={__EXAMPLE_BACKGROUND_PATTERN__}
          video={__EXAMPLE_MEMBERS_INVITE_VIDEO__}
          imagePosition="right: 0"
          description={translate(
            "onboarding_example_members_invite_description"
          )}
        />
      }
      stepper={<StepperBlock currentStep={4} totalSteps={4} />}
      mobileCentered
    />
  );
};

export default MembersInvite;
