import { ConfirmationAlert } from "@arcadia/design-system";
import createReactClass from "create-react-class";
import "jquery-ui";
import React from "react";
import { withConfirmDialogStore, withGroupStore } from "src/js/hooks/useStores";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { ExerciseListEmptyState } from "src/js/pages/exercise/ExerciseListEmptyState";
import { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import ExerciseModel from "../../models/exerciseModel";
import { InternalDispatcher } from "../../modules/dispatcher";
import { __ } from "../../modules/localization";
import ExercisesListItem from "./exercisesListItem";

const ExercisesList = withBackbone(
  createReactClass({
    getInitialState() {
      return {
        exercisesList: this.props.exercisesList
      };
    },
    componentDidMount() {
      const self = this;
      InternalDispatcher.on("exercise_delete", exercise => {
        self.props.exercisesList.remove(exercise);
        self.forceUpdate();
      });
    },
    componentWillReceiveProps(nextProps) {
      const self = this;
      self.setState({
        exercisesList: nextProps.exercisesList
      });
    },
    doInstant(exercise) {
      const self = this;
      const report = exercise.get("report_stats");

      if (
        report.users_failed_count + report.users_passed_count != 0 &&
        exercise.isVerification()
      ) {
        const { ConfirmDialogStore, theme } = self.props;
        const { whiteTheme } = theme;

        ConfirmDialogStore.openConfirmDialog(
          <ConfirmationAlert
            theme={whiteTheme}
            text={__("launch_instant_check")}
            declineText={__("Annulla")}
            onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
            acceptText={__("Conferma")}
            onAcceptFunction={() => {
              self.launchInstant(exercise);
              ConfirmDialogStore.closeConfirmDialog();
            }}
          />
        );
      } else {
        self.launchInstant(exercise);
      }
    },
    launchInstant(exercise) {
      const callbackOk = function () {
        showToastSuccess({ str: __("Instant lanciato") });
      };
      const callbackError = function (data) {
        const errorMsg = extractErrorMessage(
          data,
          __("Attenzione, si è verificato un errore")
        );
        showToastError({ str: errorMsg });
      };
      exercise.doInstant(callbackOk, callbackError);
    },
    render() {
      const self = this;
      const { GroupStore } = self.props;
      let list = "";
      const exercisesList = self.state.exercisesList;
      let exercise = {};
      if (exercisesList.length > 0) {
        list = exercisesList.map(exercise => {
          return (
            <ExercisesListItem
              exercise={exercise}
              doInstantFunc={self.doInstant.bind(null, exercise)}
              numberOfStudents={GroupStore?.studentsCount}
              key={exercise.id}
            />
          );
        });

        return <div>{list}</div>;
      }

      exercise = new ExerciseModel({
        quizzes_count: 10,
        report_stats: {
          exercise_id: 835,
          vote_avg: 0.7,
          time_avg: 352,
          users_passed_count: 18,
          users_failed_count: 2,
          lower_time_execution: 243,
          highest_time_execution: 452,
          vote_aggregations: [
            {
              exercise_id: 835,
              vote_range: 3,
              users_count: 1
            },
            {
              exercise_id: 835,
              vote_range: 0,
              users_count: 0
            },
            {
              exercise_id: 835,
              vote_range: 1,
              users_count: 0
            },
            {
              exercise_id: 835,
              vote_range: 2,
              users_count: 0
            },
            {
              exercise_id: 835,
              vote_range: 4,
              users_count: 0
            },
            {
              exercise_id: 835,
              vote_range: 5,
              users_count: 0
            },
            {
              exercise_id: 835,
              vote_range: 6,
              users_count: 3
            },
            {
              exercise_id: 835,
              vote_range: 7,
              users_count: 4
            },
            {
              exercise_id: 835,
              vote_range: 8,
              users_count: 1
            },
            {
              exercise_id: 835,
              vote_range: 9,
              users_count: 0
            },
            {
              exercise_id: 835,
              vote_range: 10,
              users_count: 1
            }
          ]
        },
        title: "Primo esercizio",
        public_result: true,
        type: "ASSIGNMENT"
      });

      return (
        <div>
          <div className="empty-list-message-box">
            <div className="relative">
              <ExerciseListEmptyState />
              <div className="blurred">
                <ExercisesListItem exercise={exercise} numberOfStudents={20} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  })
);

export default withConfirmDialogStore(withTheme(withGroupStore(ExercisesList)));
