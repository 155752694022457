import {
  Input,
  Text,
  colors,
  scrollbarMixin,
  VariantType
} from "@arcadia/design-system";
import styled from "styled-components";
import { DotsMenu } from "../DotsMenu";

export const MenuContainer = styled.div`
  padding-bottom: 8px;
  color: ${({ theme }) => theme.colors.grey[50]};
  max-height: 468px;
  overflow-y: auto;
  width: 290px;
  ${scrollbarMixin(VariantType.Grey)};

  @media screen and (max-width: 767px) {
    max-height: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    width: 100%;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;

export const IconContainer = styled.div<{ purple?: boolean }>`
  background-color: ${({ theme, purple }) =>
    purple ? theme.primaryColor[500] : theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  width: 36px;
  height: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const ItemDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const SearchInput = styled(Input)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  padding: 16px;

  & > div {
    & > div {
      left: 16px;
      color: ${colors.grey[50]};
    }
    & > input {
      padding-left: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: ${colors.coldGrey[900]};
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 48px 32px 48px;
`;

export const CustomDotsMenu = styled(DotsMenu)`
  width: 16px;
  height: 16px;
  padding: 12px;

  & > svg {
    width: 16px;
    height: 16px;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;
