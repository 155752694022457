import styled from "styled-components";

export const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  @media (max-width: 767px) {
    gap: 8px;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 288px;
  height: 40px;
  flex: 1;
`;

export const DropdownLabelContainer = styled.div`
  min-width: 60px;
  margin-right: 16px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const DropdownGroupFilterContainer = styled.div`
  min-width: 300px;
  @media (max-width: 767px) {
    min-width: 170px;
    flex: 1;
  }
`;

export const DropdownFilterContainer = styled.div`
  min-width: 190px;
  @media (max-width: 767px) {
    min-width: 170px;
    flex: 1;
  }
`;

export const TypeFilterSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 24px auto;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    margin: 16px -16px 24px;
    padding: 0px 16px;
    width: 100%;
    flex-wrap: nowrap;
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TypeFilterLabelContainer = styled.div`
  margin-right: 16px;
  @media (max-width: 767px) {
    display: none;
  }
`;
