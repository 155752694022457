import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import moment from "moment";
import each from "lodash/each";
import { Button } from "@arcadia/design-system";
import { Translate } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { __ } from "../../modules/localization";
import { createURL } from "../../modules/utility";
import { getUserId } from "../../modules/activeUser";
import { doRouteBack } from "../../modules/history";

const ReportIssue = withBackbone(
  createReactClass({
    form: "#form-report-issue",
    maxChars: {
      desktop: 140,
      tablet: 60,
      smartphone: 40
    },
    getDefaultProps() {
      return {
        category: ""
      };
    },
    componentDidMount() {
      this.initValidation();
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        modules: "date",
        borderColorOnError: "",
        validateOnBlur: true,
        onError() {
          showToastError({ str: __("Attenzione, verificare i dati inseriti") });
        },
        onSuccess(form) {
          self.save();
          return false;
        }
      });
    },
    submitForm() {
      const self = this;
      $(self.form).trigger("submit");
    },
    save() {
      const pars = {
        report: {
          authorId: getUserId(),
          description: $(`${this.form} #report-abuse-description`).val(),
          type: $(`${this.form} #report-abuse-category`).val(),
          created: moment().format()
        }
      };

      const fields = $(this.form).serializeArray();
      each(fields, f => {
        pars.report[f.name] = f.value;
      });

      $.ajax({
        url: createURL("api_report_abuse"),
        dataType: "json",
        contentType: "application/json",
        type: "POST",
        data: JSON.stringify(pars)
      })
        .done(data => {
          showToastSuccess({ str: __("report_issue_success_message") });
          doRouteBack();
        })
        .fail(data => {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        });
    },
    render() {
      const self = this;
      const { whiteTheme } = this.props.theme;

      const categorySelectionFormGroup = (
        <div className="form-group">
          <label className="control-label col-sm-4 col-lg-4">
            {__("report_abuse_category_label")}
          </label>
          <div className="col-sm-8 col-lg-8">
            <div className="select-wrapper">
              <select
                id="report-abuse-category"
                name="category"
                className="form-control"
                data-validation="required"
                data-validation-error-msg={__("report_abuse_category_error")}
              >
                <option value="">-</option>
                <option value="tech">{__("report_issue_category_tech")}</option>
                <option value="content">
                  {__("report_issue_category_content")}
                </option>
              </select>
            </div>
          </div>
        </div>
      );

      const descriptionFormGroup = (
        <div className="form-group">
          <label className="control-label col-sm-4 col-lg-4">
            {__("report_abuse_description_label")}
          </label>
          <div className="col-sm-8 col-lg-8">
            <textarea
              id="report-abuse-description"
              name="description"
              className="form-control"
              rows="5"
              data-validation="length"
              data-validation-length="1-1024"
              data-validation-error-msg={__("report_abuse_description_error")}
            />
          </div>
        </div>
      );

      return (
        <div className="report-abuse-container">
          <div className="report-abuse-wrapper margin-vertical-20">
            <form
              id="form-report-issue"
              className="form-horizontal col-xs-12 col-sm-offset-1 col-sm-10 col-lg-offset-2 col-lg-8 margin-bottom-40"
              role="form"
              method="post"
            >
              {categorySelectionFormGroup}
              {descriptionFormGroup}
              <div className="clearfix" />
            </form>
            <div className="flex-centered-column">
              <Button
                variant="primary"
                theme={whiteTheme}
                onClick={this.submitForm}
              >
                <Translate text="report_abuse_send_label" />
              </Button>
            </div>
          </div>
        </div>
      );
    }
  })
);

export default withTheme(ReportIssue);
