import { ModalStyles } from "@arcadia/design-system";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import styled, { css } from "styled-components";

export const StyledModalLayout = styled(ModalLayout)`
  & > div {
    z-index: ${({ theme }) => theme.zIndex.forCalendarEventModal};
  }
  ${ModalStyles.Title} {
    ${({ showTabs }) =>
      showTabs &&
      css`
        border-bottom: 1px solid transparent;
      `}
  }
`;

export const ModalContentContainer = styled.div`
  padding: 0 30px;
  ${({ showTabs }) =>
    showTabs &&
    css`
      padding: 40px 30px 0px;
    `}

  @media screen and (max-width: 767px) {
    padding: 0 20px;
    ${({ showTabs }) =>
      showTabs &&
      css`
        padding: 30px 20px 0px;
      `}
  }

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 20px;
    `}
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  color: ${({ color }) => color || null};

  max-width: ${({ withButton }) => (withButton ? "395px" : "340px")};

  ${({ flexCenter }) =>
    flexCenter &&
    css`
      align-items: center;
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}

  @media screen and (max-width: 767px) {
    max-width: initial;
  }
`;

export const UserFilterWrapper = styled.div`
  margin-left: 10px;
  flex: 1;
`;

export const DatePickerWrapper = styled.div`
  max-width: 215px;
  display: inline-block;
  @media screen and (max-width: 767px) {
    max-width: initial;
    flex: 100%;
  }
`;

export const TimePickerWrapper = styled.div`
  max-width: 90px;
  margin-left: 15px;
  display: inline-block;
  @media screen and (max-width: 767px) {
    max-width: initial;
    padding: 0px;
    .ws-time-input {
      max-width: initial;
      flex: 1;
      width: 100%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 40px;
  margin-left: 15px;
  align-self: flex-end;
`;

export const VendorContainer = styled.div`
  max-width: 340px;
  margin-top: 20px;
  color: #000000;
  @media screen and (max-width: 767px) {
    max-width: initial;
  }
`;

export const VendorFallbackDisclaimer = styled.p`
  margin: 10px 0;
  font-size: 12px;
`;

export const ModalFooterContainer = styled.div`
  padding: 15px 30px 30px;
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AttachmentPickerDesktop = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    display: inline-block;
    width: 380px;
  }
`;

export const AttachmentPickerMobile = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const EventAttachments = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0fr));
  grid-gap: 10px 10px;
  @media (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;

export const DisplayWrapper = styled.div`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const AllDayContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-top: 0;
    margin-right: 10px;
    min-width: 10px;
    min-height: 10px;
  }
  label {
    font-size: 12px;
    margin-bottom: 0;
    text-transform: none;
  }
  @media screen and (max-width: 767px) {
    flex-flow: column;
    align-items: start;
    margin-bottom: 16px;
  }
`;
export const CheckboxContainer = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
`;
/**
 * Mobile
 */

export const ContentSwitcherWrap = styled.div`
  padding-bottom: 12px;
  div {
    margin: auto;
    button {
      min-width: 120px;
    }
  }
`;

export const DropdownWrapper = styled.div`
  max-width: 215px;
  display: inline-block;
  margin-right: 26px;
  width: 100%;
  @media screen and (max-width: 767px) {
    max-width: initial;
    flex: 100%;
  }
`;
export const LabelContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey[300]};
  margin-bottom: 14px;
`;
