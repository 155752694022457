import { Button } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  &:hover:not([disabled]) {
    background: initial;
    color: initial;
    cursor: initial;
  }
`;
