import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { handleGroupErrorMessage } from "src/js/context/utils/errorHandler";
import useStores from "src/js/hooks/useStores";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import ResourceEditor from "src/js/pages/resources/ResourceEditor";
import GroupModel from "../models/groupModel";
import ResourceModel from "../models/resourceModel";
import {
  getActiveGroupId,
  resetActiveGroup,
  useGroup
} from "../modules/activeGroup";
import { getActiveUser } from "../modules/activeUser";
import { doRouteBack } from "../modules/history";
import { __ } from "../modules/localization";
import { basePageModel } from "../modules/pageStatus";
import { createURL } from "../modules/utility";
import RankingGroup from "../view/ranking/rankingGroupView";
import ReportAbuse from "../view/report/reportAbuseView";
import ReportIssue from "../view/report/reportIssueView";
import ActivityController from "./ActivityController";
import ExerciseController from "./ExerciseController";
import ModerationController from "./ModerationController";
import ModulesController from "./ModulesController";
import WallController from "./WallController";

const updateActiveGroup = activeGroupModel => {
  if (activeGroupModel.attributes.id === getActiveGroupId()) return;
  useGroup(activeGroupModel);
};

/**
 * @name SingleGroupController
 *
 * @description ...
 *
 */
const SingleGroupController = () => {
  const { GroupStore } = useStores();

  if (GroupStore.hasError) {
    handleGroupErrorMessage();
  }

  const activeGroupModel = GroupStore.activeGroup?.id
    ? new GroupModel(GroupStore.groupBackboneModel)
    : null;

  if (activeGroupModel) {
    updateActiveGroup(activeGroupModel);
  } else resetActiveGroup();

  return activeGroupModel ? (
    <Switch>
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.wall}
        render={props => <WallController group={activeGroupModel} {...props} />}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.wallNew}
        render={props => <WallController group={activeGroupModel} {...props} />}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.edit}
        render={props => <WallController group={activeGroupModel} {...props} />}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Post.post}
        render={props => <WallController group={activeGroupModel} {...props} />}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.issueReport}
        render={props => {
          let title = __("report_abuse_title");
          const back = __("back");
          const back_function = function () {
            doRouteBack();
          };
          let buttons;
          title = __("report_issue_title");

          basePageModel.set("pageTitle", activeGroupModel.attributes.name);
          basePageModel.set({
            selectedTab: "custom",
            backFunc: {
              back,
              back_function,
              title,
              buttons
            }
          });
          return <ReportIssue category="issue" {...props} />;
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.rankingPage}
        render={props => {
          const pageNumber = props.match.params.page;

          basePageModel.set("pageTitle", activeGroupModel.attributes.name);
          basePageModel.set("selectedTab", "back");
          basePageModel.set("backFunc", "DO_APP_BACK");
          return (
            <RankingGroup
              groupModel={activeGroupModel}
              user={getActiveUser()}
              pageNumber={pageNumber}
            />
          );
        }}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.resourceEdit}
        render={props => (
          <ResourceEditor
            groupId={props.match.params.group_id}
            objId={props.match.params.resource_id}
          />
        )}
      />
      <Route
        exact
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.resourceReport}
        render={props => {
          const contentModel = new ResourceModel();

          contentModel.url = `${createURL("api_resources")}/${
            props.match.params.resource_id
          }`;
          contentModel.fetch();

          const title = __("report_abuse_title");
          const back = __("back");
          const back_function = function () {
            doRouteBack();
          };

          let buttons;
          basePageModel.set("pageTitle", activeGroupModel.attributes.name);
          basePageModel.set({
            selectedTab: "custom",
            backFunc: {
              back,
              back_function,
              title,
              buttons
            }
          });

          return (
            <ReportAbuse
              category="resource"
              contentModel={contentModel}
              {...props}
            />
          );
        }}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Boards.initialRouteName}
        render={props => <ModulesController {...props} />}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Modules.initialRouteName}
        render={props => <ModulesController {...props} />}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.initialRouteName}
        component={ExerciseController}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.moderation}
        render={() => <ModerationController group={activeGroupModel} />}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceGroup.Activity.initialRouteName}
      >
        <ActivityController group={activeGroupModel} />
      </Route>
      <Route render={() => <Redirect to={createUrl("home")} />} />
    </Switch>
  ) : (
    <div />
  );
};

export default observer(SingleGroupController);
