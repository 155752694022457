import { Avatar, Heading, PageNav, SearchBar } from "@arcadia/design-system";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SpaceListModel } from "src/js/types";
import { useTheme } from "styled-components";
import { SpaceCard } from "./SpaceCard";
import { SpaceAndGroups, useSearchNotificationGroups } from "./hooks";

import NoSpaces from "./NoSpaces";

import * as S from "./UserSettingsNotice.styles";

const limitSpacesNumber = 5;

const UserSettingsNotice = () => {
  const {
    SpaceStore: {
      spacesList: storeSpacesList,
      hasMoreSpaces,
      fetchSpacesNextPage
    },
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUserFullname, activeUser }
  } = useStores();

  const { greyTheme, whiteTheme } = useTheme();
  const [offset, setOffset] = useState(0);
  const [searchOffset, setSearchOffset] = useState(0);
  const {
    searchFilter,
    onChangeSearch,
    onSubmitSearch,
    listOfSpaceWithGroups
  } = useSearchNotificationGroups();
  const { translate } = useTranslation();

  const spacesList = toJS(storeSpacesList);

  useEffect(() => {
    // If we are getting close to the end of the spaces, fetch next batch
    if (offset > spacesList.length - limitSpacesNumber * 2 && hasMoreSpaces) {
      fetchSpacesNextPage();
    }
  }, [offset]);

  const { actualOffset, setActualOffset, actualSpaces } = useMemo(() => {
    const isSearched = !!listOfSpaceWithGroups;

    return {
      actualSpaces: isSearched ? listOfSpaceWithGroups : spacesList,
      actualOffset: isSearched ? searchOffset : offset,
      setActualOffset: isSearched ? setSearchOffset : setOffset
    };
  }, [listOfSpaceWithGroups, spacesList]);

  return (
    <>
      {isLayoutModeMobile ? (
        <S.MobileUserInfo>
          <Avatar
            theme={whiteTheme}
            url={activeUser.avatar?.small}
            alt={activeUserFullname}
            size="xl"
            rounded
          />
          <Heading level="5">{activeUserFullname}</Heading>
        </S.MobileUserInfo>
      ) : null}

      <S.UserSettingsSectionTitle>
        {!isLayoutModeMobile ? (
          <S.StyledHeading level="4">
            {translate("user_settings_notice_groups_title")}
          </S.StyledHeading>
        ) : null}
        <S.UserSettingsSearch>
          <SearchBar
            theme={greyTheme}
            value={searchFilter}
            onChange={event => {
              onChangeSearch(event.target.value);
            }}
            placeholder={translate("user_settings_groups_list_search")}
            onClick={onSubmitSearch}
          />
        </S.UserSettingsSearch>
      </S.UserSettingsSectionTitle>
      {!isLayoutModeMobile ? (
        <Heading level="6">
          {translate("user_notice_settings_spaces_title")}
        </Heading>
      ) : null}
      <S.SpaceList>
        {!actualSpaces.length ? (
          <NoSpaces />
        ) : (
          actualSpaces
            .slice(actualOffset, actualOffset + limitSpacesNumber)
            .map(
              ({ space, searchedGroups }: SpaceAndGroups & SpaceListModel) => {
                return (
                  <SpaceCard
                    key={space.id}
                    {...space}
                    searchedGroups={searchedGroups}
                  />
                );
              }
            )
        )}

        <PageNav
          totalRecords={actualSpaces.length}
          theme={greyTheme}
          pageLimit={limitSpacesNumber}
          startingOffset={actualOffset}
          onPageChanged={newPage => {
            setActualOffset(newPage * limitSpacesNumber - limitSpacesNumber);
          }}
          label={{ previous: translate("prev"), next: translate("next") }}
        />
      </S.SpaceList>
    </>
  );
};

export default observer(UserSettingsNotice);
