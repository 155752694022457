import { Icon, Text } from "@arcadia/design-system";
import React, {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./SearchUsersInput.styles";
import {
  SearchUsersInputProps,
  SearchUsersInputRef
} from "./SearchUsersInput.types";

const SearchUsersInput = forwardRef<SearchUsersInputRef, SearchUsersInputProps>(
  (
    {
      users,
      onChangeUsers,
      textQuery,
      onChangeTextQuery,
      className,
      presentationLabel,
      placeholderInputLabel
    },
    ref
  ) => {
    const { whiteTheme } = useTheme();
    const { translate } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        inputRef.current.focus();
        inputRef.current.scrollIntoView({ block: "nearest" });
      }
    }));

    const deleteUser = (index: number) => {
      onChangeUsers(prevState => prevState.filter((p, i) => i !== index));
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      inputRef.current.scrollIntoView({ block: "nearest" });
      onChangeTextQuery(value);
    };

    return (
      <S.SearchUsersInputWrapper
        className={className}
        onClick={() => inputRef?.current?.focus()}
      >
        <Text type="subHeaderTitle">{translate(presentationLabel)}</Text>
        <S.SearchUsersContent>
          {users.map((user, index) => (
            <S.UserItem key={`spi-el-${user.uuid}`}>
              <S.StyledAvatar
                url={user?.avatar?.small}
                size="image"
                theme={whiteTheme}
                alt={`${user.name} - ${user.surname}`}
                rounded
              />
              {user.name} {user.surname}
              <S.UserItemDeleteBtn
                data-testid="user-item-delete-btn"
                onClick={e => {
                  deleteUser(index);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Icon icon="close" width={12} height={12} />
              </S.UserItemDeleteBtn>
            </S.UserItem>
          ))}
          <S.InputQuery
            autoFocus
            data-testid="search-users-input"
            ref={inputRef}
            value={textQuery}
            placeholder={
              users.length > 0 ? "" : translate(placeholderInputLabel)
            }
            onChange={onChange}
          />
        </S.SearchUsersContent>
      </S.SearchUsersInputWrapper>
    );
  }
);

SearchUsersInput.displayName = "SearchUsersInput";

export default SearchUsersInput;
