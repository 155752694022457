import { ObjectValues } from "./Common";

export const USER_TYPE = {
  BUSINESS: "BUSINESS",
  K12: "K-12",
  MEMBER: "MEMBER"
} as const;

export const COMPANY_SIZE = [
  { label: "onboarding_marketing_data_size_1", value: "1-Freelancer" },
  { label: "onboarding_marketing_data_size_2", value: "2-10" },
  { label: "onboarding_marketing_data_size_3", value: "11-50" },
  { label: "onboarding_marketing_data_size_4", value: "51-200" },
  { label: "onboarding_marketing_data_size_5", value: "201-500" },
  { label: "onboarding_marketing_data_size_6", value: "501-1000" },
  { label: "onboarding_marketing_data_size_7", value: "1000+" }
] as const;

export const COMPANY_ROLE = [
  {
    label: "onboarding_marketing_data_role_1",
    value: "Instructor/Trainer"
  },
  {
    label: "onboarding_marketing_data_role_2",
    value: "Course Designer/Instructional Designer"
  },
  {
    label: "onboarding_marketing_data_role_3",
    value: "HR Manager/Training Manager"
  },
  { label: "onboarding_marketing_data_role_4", value: "Student/Learner" },
  { label: "onboarding_marketing_data_role_5", value: "Executive/Management" },
  { label: "onboarding_marketing_data_role_6", value: "Compliance Officer" },
  {
    label: "onboarding_marketing_data_role_7",
    value: "Sales/Marketing Professional"
  },
  {
    label: "onboarding_marketing_data_role_8",
    value: "Customer Support/Service"
  },
  {
    label: "onboarding_marketing_data_role_9",
    value: "External Consultant/Advisor"
  },
  { label: "onboarding_marketing_data_role_10", value: "Other" }
] as const;

export const WESCHOOL_USE_CASE = [
  { label: "onboarding_marketing_data_use_case_1", value: "Team Training" },
  {
    label: "onboarding_marketing_data_use_case_2",
    value: "Employee Onboarding"
  },
  {
    label: "onboarding_marketing_data_use_case_3",
    value: "Customer Education"
  },
  { label: "onboarding_marketing_data_use_case_4", value: "Sales Enablement" },
  {
    label: "onboarding_marketing_data_use_case_5",
    value: "Schools & Institutions"
  },
  { label: "onboarding_marketing_data_use_case_6", value: "Other" }
] as const;

export type UserType = ObjectValues<typeof USER_TYPE>;

export type UserTypeSelectionFields = {
  userType: UserType;
};

export type MarketingDataFormFields = {
  companyName: string;
  companySize: string;
  companyRole: string;
  weschoolUseCase: string;
  phoneNumber: string;
};

export type MarketingDataHubSpot = MarketingDataFormFields & {
  email: string;
};

export type MarketingDataUpdate = UserTypeSelectionFields & {
  companyName?: string;
  companySize?: string;
  companyRole?: string;
  weschoolUseCase?: string;
  phoneNumber?: string;
  onboardingCompleted?: boolean;
};

export type SisOnboarding = {
  isOnboarding?: boolean;
};
