import React from "react";
import { Heading, Lottie, LottieProps, Text } from "@arcadia/design-system";
import Error404Animation from "Images/lottie/error-404.json";
import styled from "styled-components";
import { useTranslation } from "src/js/translation";

const Wrapper = styled.div`
  display: flex;
  height: 228px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
  > span {
    max-width: 400px;
    text-align: center;
  }
`;

const LottieAnimation = styled(Lottie).attrs((props: LottieProps) => ({
  ...props,
  src: Error404Animation
}))`
  width: 120px;
  margin-bottom: 15px;
`;

const NoSpaces = () => {
  const { translate } = useTranslation();
  return (
    <Wrapper>
      <LottieAnimation />
      <Heading level="5">
        {translate("settings_notice_no_groups_title")}
      </Heading>
      <Text type="body">{translate("settings_notice_no_groups_subtitle")}</Text>
    </Wrapper>
  );
};

export default NoSpaces;
