import { ActionButton, RadioGroup, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledButton = styled(ActionButton)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PreferenceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px 24px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[800]}`};
  border-radius: 8px;
`;

export const Title = styled(Text)`
  margin-left: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  height: 40px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 12px;
`;

export const BackWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
`;

export const StyledRadioGroup = styled(RadioGroup)<{
  color: string;
  hoverBgColor: string;
}>`
  display: flex;
  gap: 4px;

  label {
    margin: 0;
    border-radius: 8px;
    padding: 8px;
    border-radius: 20px;
    &:hover {
      background-color: ${({ hoverBgColor }) => hoverBgColor};
    }
    input {
      margin: 0;
      outline: none !important;
      &:hover {
        background-color: transparent !important;
      }
      &:checked {
        background-color: ${({ color }) => color} !important;
      }
    }
    span {
      padding-left: 16px;
    }
  }
`;
