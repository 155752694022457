import { Box } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, {
  FC,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState
} from "react";
import {
  useCloseOnClickOutside,
  useDebounce,
  useInfiniteScrollFetcher,
  useStores
} from "src/js/hooks";
import { fetchSpaceMembers } from "src/js/repository/spacesRepository";
import { ThreadUser } from "src/js/types/models/Thread";
import {
  SearchUsersDropdown,
  SearchUsersDropdownRef
} from "./SearchUsersDropdown";
import { SearchUsersInput, SearchUsersInputRef } from "./SearchUsersInput";
import * as S from "./SearchUsersToolbar.styles";
import { SearchUsersToolbarProps } from "./SearchUsersToolbar.types";

const SearchUsersToolbar: FC<SearchUsersToolbarProps> = ({
  users,
  setUsers,
  threadExists
}) => {
  const {
    isOpen: isDropdownOpen,
    setIsOpen: setIsDropdownOpen,
    ref
  } = useCloseOnClickOutside(false);

  const [textQuery, setTextQuery] = useState("");
  const debouncedTextQuery = useDebounce(textQuery, 300);

  const inputRef = useRef<SearchUsersInputRef>(null);
  const dropdownRef = useRef<SearchUsersDropdownRef>(null);

  const {
    SpaceStore: { activeSpaceId }
  } = useStores();

  const {
    data: filteredSpaceUsers = [],
    hasMoreResults,
    fetch,
    fetchNextPage
  } = useInfiniteScrollFetcher(
    async ({ limit, page: _page }) => {
      const data = await fetchSpaceMembers({
        spaceId: activeSpaceId,
        textSearch: debouncedTextQuery,
        limit,
        offset: _page * limit
      });
      const filteredResult = data.results.filter(result => {
        return !users.find(user => user.uuid === result.uuid);
      });
      return filteredResult;
    },
    { lazy: true, limit: 30 }
  );

  useEffect(() => {
    fetch(0, { params: { textSearch: debouncedTextQuery } });
    setIsDropdownOpen(debouncedTextQuery.length > 0);
  }, [debouncedTextQuery]);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [users]);

  const handleKeyDown: KeyboardEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = e => {
    const { key } = e;

    switch (key) {
      case "Backspace":
        if (!debouncedTextQuery.length && users.length) {
          e.preventDefault();
          setUsers(prevState => prevState.slice(0, -1));
        }
        break;
      case "Enter":
        if (!isDropdownOpen) {
          if (users.length) {
            setIsDropdownOpen(true);
          }
        } else {
          dropdownRef.current.clickFocusedOption();
        }
        break;
      case "Escape":
        setIsDropdownOpen(false);
        break;
      case "ArrowUp":
        if (isDropdownOpen) {
          dropdownRef.current.focusPrevOption();
        }
        break;
      case "ArrowDown":
        if (isDropdownOpen) {
          dropdownRef.current.focusNextOption();
        }
        break;
      default:
        break;
    }
  };

  const onChangeUsersDropdown = (user: ThreadUser) => {
    setTextQuery("");
    setUsers(prevState => [...prevState, user]);
    setTimeout(() => inputRef.current.focus());
  };

  return (
    <S.SearchUsersToolbarWrapper
      ref={ref}
      onKeyDown={handleKeyDown}
      threadExists={threadExists}
    >
      <SearchUsersInput
        ref={inputRef}
        users={users}
        onChangeUsers={setUsers}
        textQuery={textQuery}
        onChangeTextQuery={setTextQuery}
        presentationLabel="search_users_input_presentation"
        placeholderInputLabel="search_users_input_placeholder_empty"
      />
      {isDropdownOpen ? (
        <Box
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          <SearchUsersDropdown
            ref={dropdownRef}
            onClickUser={onChangeUsersDropdown}
            spaceUsers={filteredSpaceUsers}
            hasNext={hasMoreResults}
            showNext={() =>
              fetchNextPage({ params: { textSearch: debouncedTextQuery } })
            }
            textQuery={textQuery}
          />
        </Box>
      ) : null}
    </S.SearchUsersToolbarWrapper>
  );
};

export default observer(SearchUsersToolbar);
