import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import moment from "moment";

const Timer = withBackbone(
  createReactClass({
    interval: null,
    getDefaultProps() {
      return {
        start_date: "",
        className: "",
        mode: "asc", // desc=countdown
        milliseconds: "" // only for countdown
      };
    },
    getInitialState() {
      return {
        difference: 0
      };
    },
    componentDidMount() {
      const self = this;
      self.update();
      self.interval = setInterval(self.update, 1000);
    },
    update() {
      const self = this;
      const startTime = moment
        .unix(self.props.start_date)
        .format("DD/MM/YYYY HH:mm:ss");
      const now = moment();
      const difference = moment
        .utc(
          moment(now, "DD/MM/YYYY HH:mm:ss").diff(
            moment(startTime, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .format("mm:ss");
      self.setState({ difference });
    },
    componentWillUnmount() {
      const self = this;
      clearInterval(self.interval);
    },
    render() {
      const self = this;

      return <div className="timer">{self.state.difference}</div>;
    }
  })
);

export default Timer;
