/* eslint-disable no-restricted-imports */
/* eslint-disable no-restricted-globals */
import {
  ActionButton,
  Box,
  Button,
  DoodleLoader,
  Heading,
  Input,
  Text
} from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router";
import { SecondaryBar } from "src/js/components/SecondaryBar";
import { InputPhone } from "src/js/components/forms/InputPhone";
import countryCodeJSON from "src/js/components/forms/InputPhone/countryCodes.json";
import { Country } from "src/js/components/forms/InputPhone/types";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { showToastError } from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import {
  fetchPublicProfileSettings,
  savePublicProfileSettings
} from "src/js/repository/publicProfileRepository";
import WSTheme from "src/js/theme/theme";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { TranslationKeys } from "src/js/translation/translated.types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { EditAvatar } from "./EditAvatar";
import { EditBirthday } from "./EditBirthday";
import { VisibilityToggle } from "./VisibilityToggle";
import {
  EditProfileFormData,
  UserInfoVisibility,
  UserInformation,
  UserSettingsResponse
} from "./types";
import { __EDIT_PROFILE_SCHEMA__, getPlaceholder } from "./utils";

import * as S from "./ProfileEdit.styles";

const ProfileEdit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { headerHeight } = useAppLayout();
  const [userVisibility, setUserVisibility] = useState<UserInfoVisibility>();
  const [userInformation, setUserInformation] =
    useState<Omit<UserInformation, "birthday">>();
  const [country, setCountry] = useState<Country>({
    name: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dial_code: "+39"
  });
  const [isDirty, setIsDirty] = useState(false);
  const [userBirthday, setUserBirthday] = useState<string>();
  const { translate } = useTranslation();

  const { register, errors, handleSubmit, formState, watch, setValue, reset } =
    useForm<EditProfileFormData>({
      mode: "onChange"
    });

  const watchAllFields = watch();

  const { UserStore, UIStore } = useStores();

  const { greyTheme } = useTheme();

  useEffect(() => {
    if (!UserStore.activeUser) return;
    fetchPublicProfileSettings().then((settings: UserSettingsResponse) => {
      setUserVisibility({
        showBirthday: settings.showBirthday,
        showEmail: settings.showEmail,
        showFacebook: settings.showFacebook,
        showInstagram: settings.showInstagram,
        showPhone: settings.showPhone,
        showStatus: settings.showStatus,
        showBadges: settings.showBadges
      });
      const splittedPhone = settings.phone?.split(/\s/) || [];

      setUserInformation({
        email: settings.email,
        facebook: settings.facebook,
        instagram: settings.instagram,
        phone: splittedPhone[1],
        status: settings.status
      });
      if (splittedPhone[0] && splittedPhone[0] !== "undefined") {
        const countryData = countryCodeJSON.find(
          ({ dial_code }) => dial_code === splittedPhone[0]
        );
        setCountry(
          countryData || {
            name: "Italy",
            flag: "🇮🇹",
            code: "IT",
            dial_code: "+39"
          }
        );
      }

      setUserBirthday(settings.birthday);

      setIsLoading(false);
    });
  }, [UserStore.activeUser]);

  useEffect(() => {
    if (isLoading) return;
    setValue([
      { email: userInformation.email },
      { phone: userInformation.phone },
      { facebook: userInformation.facebook },
      {
        instagram: userInformation.instagram
      },
      { status: userInformation.status },
      { birthday: format(new Date(userBirthday), "dd-MM-yyyy") }
    ]);
    reset({
      email: userInformation.email,
      birthday: format(new Date(userBirthday), "dd-MM-yyyy"),
      facebook: userInformation.facebook,
      instagram: userInformation.instagram,
      status: userInformation.status,
      phone: userInformation.phone
    });
  }, [isLoading]);

  const changeUserVisibility = (
    field: keyof UserInfoVisibility,
    value: boolean
  ) => {
    const newState = { ...userVisibility };
    newState[field] = value;

    setIsDirty(true);
    setUserVisibility(newState);
  };

  const submitChanges = (formData: EditProfileFormData) => {
    const body: UserInfoVisibility & UserInformation = {
      ...formData,
      ...userVisibility
    };

    // formatting the birthday as BE expect before calling api
    body.birthday = format(new Date(userBirthday), "dd-MM-yyyy");

    body.phone = `${country.dial_code} ${formData.phone}`;

    savePublicProfileSettings(body)
      .then(() => {
        reset();
        setIsDirty(false);
        navigateTo(
          createUrl("profile_public", { user_uuid: UserStore.activeUser.uuid })
        );
      })
      .catch(() => {
        showToastError({ str: "general_error" });
      });
  };

  let content = null;

  if (isLoading) content = <DoodleLoader theme={greyTheme} />;
  else
    content = (
      <S.MainWrapper>
        <Box>
          <EditAvatar />
        </Box>
        <Prompt
          when={formState.dirty || isDirty}
          message={() =>
            translate({
              text: "profile_edit_leaving_without_saving_prompt_message"
            })
          }
        />

        <S.SectionWrapper zIndex={WSTheme.zIndex.comboBody - 1}>
          <EditBirthday
            birthday={new Date(userBirthday)}
            placeholder={getPlaceholder("birthday")}
            onChangeBirthday={date => {
              setUserBirthday(date.toDateString());
              setValue("birthday", format(new Date(date), "dd-MM-yyyy"));
              setIsDirty(true);
            }}
            showBirthday={userVisibility.showBirthday}
            onChangeShow={value => changeUserVisibility("showBirthday", value)}
            ref={register(__EDIT_PROFILE_SCHEMA__.birthday)}
          />
        </S.SectionWrapper>
        <S.SectionWrapper>
          <Input
            label={translate({ text: "public_profile_email_label" })}
            theme={greyTheme}
            type="field"
            name="email"
            ref={register(__EDIT_PROFILE_SCHEMA__.email)}
            rounded
            icon="mailOutline"
            disabled
            rightSection={
              <VisibilityToggle
                visible={userVisibility.showEmail}
                onChange={value => changeUserVisibility("showEmail", value)}
              />
            }
          />
        </S.SectionWrapper>
        <S.SectionWrapper>
          <InputPhone
            label={translate({ text: "public_profile_phone_label" })}
            theme={greyTheme}
            country={country}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onChangeCountry={country => {
              setCountry(country);
              setIsDirty(true);
            }}
            name="phone"
            ref={register(__EDIT_PROFILE_SCHEMA__.phone)}
            error={{
              message: translate({
                text: errors?.phone?.message as TranslationKeys
              })
            }}
            rightSection={
              watchAllFields.phone ? (
                <VisibilityToggle
                  visible={userVisibility.showPhone}
                  onChange={value => changeUserVisibility("showPhone", value)}
                />
              ) : null
            }
          />
        </S.SectionWrapper>
        <S.SectionWrapper>
          <Input
            label={translate({
              text: "public_profile_facebook_label"
            })}
            theme={greyTheme}
            type="field"
            name="facebook"
            placeholder={translate({
              text: getPlaceholder("facebook")
            })}
            rounded
            icon="facebookEmpty"
            ref={register(__EDIT_PROFILE_SCHEMA__.facebook)}
            error={{
              message: translate({
                text: errors?.facebook?.message as TranslationKeys
              })
            }}
            rightSection={
              watchAllFields.facebook ? (
                <VisibilityToggle
                  visible={userVisibility.showFacebook}
                  onChange={value =>
                    changeUserVisibility("showFacebook", value)
                  }
                />
              ) : null
            }
          />
        </S.SectionWrapper>
        <S.SectionWrapper>
          <Input
            label={translate({
              text: "public_profile_instagram_label"
            })}
            theme={greyTheme}
            type="field"
            placeholder={translate({
              text: getPlaceholder("instagram")
            })}
            rounded
            icon="instagram"
            name="instagram"
            ref={register(__EDIT_PROFILE_SCHEMA__.instagram)}
            error={{
              message: translate({
                text: errors?.instagram?.message as TranslationKeys
              })
            }}
            rightSection={
              watchAllFields.instagram ? (
                <VisibilityToggle
                  visible={userVisibility.showInstagram}
                  onChange={value => {
                    changeUserVisibility("showInstagram", value);
                  }}
                />
              ) : null
            }
          />
        </S.SectionWrapper>
        <S.SectionWrapper>
          <Input
            label={translate({
              text: "public_profile_bio_label"
            })}
            theme={greyTheme}
            type="textarea"
            name="status"
            placeholder={translate({
              text: getPlaceholder("status")
            })}
            rows={6}
            ref={register(__EDIT_PROFILE_SCHEMA__.status)}
            rightSection={
              watchAllFields.status ? (
                <VisibilityToggle
                  visible={userVisibility.showStatus}
                  onChange={value => changeUserVisibility("showStatus", value)}
                />
              ) : null
            }
          />
        </S.SectionWrapper>
        <S.SectionWrapper>
          <Text type="formSubtitle">
            {translate("public_profile_badge_label")}
          </Text>
          <S.SectionPanel>
            <Text type="formFieldEmpty">
              {translate("public_profile_badge_title")}
            </Text>
            <Box position="absolute" right={16}>
              <VisibilityToggle
                visible={userVisibility.showBadges}
                onChange={value => changeUserVisibility("showBadges", value)}
              />
            </Box>
          </S.SectionPanel>
          <Box marginTop={6} padding="0px 6px">
            <S.BadgeSubtitle type="formSubtitle">
              {translate("public_profile_badge_text")}
            </S.BadgeSubtitle>
          </Box>
        </S.SectionWrapper>
        <Box alignSelf="flex-start" marginTop="24px">
          <Button
            variant="primary"
            theme={greyTheme}
            type="submit"
            fullWidth={UIStore.isLayoutModeMobile}
            disabled={!formState.dirty && !isDirty}
          >
            {translate({ text: "profile_edit_save_changes_submit_text" })}
          </Button>
        </Box>
      </S.MainWrapper>
    );
  return (
    <form
      id="edit-profile-form"
      style={{ width: "100%" }}
      onSubmit={handleSubmit(submitChanges)}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <SecondaryBar
          position="sticky"
          top={headerHeight}
          leftSection={
            UIStore.isLayoutModeMobile ? (
              <ActionButton
                variant="secondaryColored"
                icon="chevronLeft"
                theme={greyTheme}
                onClick={() =>
                  navigateTo(
                    createUrl("profile_public", {
                      user_uuid: UserStore.activeUser.uuid
                    })
                  )
                }
              />
            ) : null
          }
          middleSection={
            <Box display="flex" alignItems="center" justifyContent="center">
              <Heading level="5">
                {translate({
                  text: "profile_page_personal_information"
                })}
              </Heading>
            </Box>
          }
          rightSection={
            <S.SaveLinkButton
              type="submit"
              disabled={!formState.dirty && !isDirty}
            >
              <Text type="textLink">
                {translate({
                  text: "profile_edit_header_save_changes_submit_text"
                })}
              </Text>
            </S.SaveLinkButton>
          }
        />
        <MainContainer>{content}</MainContainer>
      </Box>
    </form>
  );
};

export default observer(ProfileEdit);
