import styled from "styled-components";

export const CommentSubtitleStyle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.default};
  color: ${({ theme }) => theme.colors.accent3};
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
`;
