import { motion } from "framer-motion";
import styled from "styled-components";

export const CollapsableContent = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndex.average - 1};
  overflow: hidden;
`;

export const CollapsableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.average};
`;
