import styled from "styled-components";

export const LiveDuration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px;
  background: ${({ theme }) => theme.liveduration.background};
  color: ${({ theme }) => theme.liveduration.text};
  & svg {
    margin-right: 4px;
    margin-top: 2px;
  }
`;
