import { Palette, TagThemeOptions } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

import { colors } from "..";

export const createTagTheme = ({
  grey,
  primary
}: Palette): { tag: TagThemeOptions } => {
  const contrastBackground = getHighContrastColor(
    primary.main,
    grey[300],
    grey[1000]
  );

  return {
    tag: {
      general: {
        outline: {
          violet: {
            background: contrastBackground,
            text: primary.main
          },
          red: {
            background: colors.grey[1000],
            text: colors.error[500]
          },
          royalpurple: {
            background: colors.grey[1000],
            text: colors.royalPurple[300]
          },
          summergreen: {
            background: colors.grey[1000],
            text: colors.summerGreen[300]
          },
          lagoon: {
            background: colors.grey[1000],
            text: colors.lagoon[300]
          }
        },
        focus: {
          background: grey[1000],
          border: grey[1000]
        },
        solid: {
          violet: {
            background: primary.light,
            text: grey[50]
          },
          yellow: {
            background: colors.yellow[500],
            text: colors.grey[50]
          },
          red: {
            background: colors.error[500],
            text: colors.grey[1000]
          },
          carnation: {
            background: colors.carnation[800],
            text: colors.grey[50]
          },
          royalpurple: {
            background: colors.royalPurple[700],
            text: colors.grey[50]
          },
          summergreen: {
            background: colors.summerGreen[700],
            text: colors.grey[50]
          },
          lagoon: {
            background: colors.lagoon[700],
            text: colors.grey[50]
          }
        }
      },
      activity: {
        background: grey[1000],
        closeColor: grey[50],
        icon: grey[1000],
        variant: {
          violet: colors.violet[501],
          green: colors.success[500],
          orange: colors.warning[500],
          black: grey[50]
        }
      }
    }
  };
};
