import {
  changePassword,
  checkEmailAvailability,
  deleteUserAccount,
  saveEmail
} from "src/js/repository/userRepository";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { translateString } from "src/js/translation/TranslationProvider";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { doRouteBack, navigateTo } from "src/legacy/modules/history";
import { useCallback } from "react";

export const useAccountChangeHandler = () => {
  const { UserStore, UIStore, ModalStore } = useStores();
  const { isLayoutModeMobile } = UIStore;

  const emailChangeHandler = useCallback(
    (email: string, password: string, isMobile = false) => {
      return checkEmailAvailability(email)
        .then(() => {
          saveEmail(email, password)
            .then(() => {
              showToastSuccess({
                str: translateString({ text: "change_email_confirmation_sent" })
              });
              UserStore.fetchUser();
              if (!isMobile) ModalStore.closeModal();
            })
            .catch(() =>
              showToastError({
                str: translateString({ text: "confirm_password_error" })
              })
            );
        })
        .catch(() =>
          showToastError({
            str: translateString({ text: "email_already_used" })
          })
        );
    },
    []
  );

  const changePasswordHandler = useCallback(
    (new_password, confirm_new_password, old_password) => {
      if (new_password !== confirm_new_password) {
        return showToastError({
          str: translateString({ text: "u_password_not_match" })
        });
      }

      return changePassword(new_password, old_password)
        .then(() => {
          showToastSuccess({
            str: translateString({ text: "password_update_success" })
          });
          if (isLayoutModeMobile) {
            doRouteBack();
          } else {
            ModalStore.closeModal();
          }
        })
        .catch(() =>
          showToastError({
            str: translateString({ text: "error_u_old_password_not_match" })
          })
        );
    },
    []
  );

  const deleteUserAccountHandler = useCallback(({ email, password }) => {
    return deleteUserAccount({ email: email.toLowerCase(), password }).then(
      () => {
        showToastSuccess({
          str: translateString({ text: "account_delete_success" })
        });
        ModalStore.closeModal();
        navigateTo(createUrl("logout"));
      }
    );
  }, []);

  return {
    emailChangeHandler,
    changePasswordHandler,
    deleteUserAccountHandler
  };
};
