import React, { FC, useMemo, useState } from "react";
import {
  getTitleUser,
  userIsDisabled
} from "src/js/components/Thread/Thread.const";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useDebounce, useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { ThreadUser } from "src/js/types/models/Thread";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import * as S from "./ThreadUsersModal.styles";

const ThreadUsersModal: FC<{ users: ThreadUser[] }> = ({ users = [] }) => {
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser }
  } = useStores();

  const [queryString, setQueryString] = useState("");
  const queryStringDebounced = useDebounce(queryString, 300);

  const filteredUsers: ThreadUser[] = useMemo(() => {
    if (queryStringDebounced.length === 0) {
      return users;
    }
    return users.filter(
      ({ name, surname }) =>
        `${name}${surname}`
          .toLowerCase()
          .trim()
          .indexOf(queryStringDebounced.toLowerCase().trim()) > -1
    );
  }, [users, queryStringDebounced]);

  const goToProfile = (user: ThreadUser) => {
    if (user?.uuid !== activeUser?.uuid) {
      const redirectToProfileUrl = createUrl("profile_public", {
        user_uuid: user.uuid
      });
      navigateTo(redirectToProfileUrl);
    }
  };

  return (
    <ModalLayout
      labels={{
        title: translate("thread_users_modal_title"),
        mobileClose: translate("thread_users_modal_cta_close")
      }}
      mobileProps={{
        withDrag: false
      }}
      desktopProps={{
        hasAdaptiveHeight: false
      }}
      closeModal={closeModal}
    >
      <S.ThreadUsersModalWrapper>
        <S.SearchBar
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          onChange={event => {
            event.persist();
            setQueryString(event.target.value);
          }}
          value={queryString}
          placeholder={translate("thread_users_modal_search_label")}
        />
        <S.UsersWrapper>
          {filteredUsers.map(user => {
            const disabled = userIsDisabled(user);
            return (
              <S.UserItem
                onClick={() => goToProfile(user)}
                key={`user-item-${user?.uuid}`}
              >
                <S.Avatar
                  disabled={disabled}
                  size="image"
                  rounded
                  url={user?.avatar?.small}
                  theme={whiteTheme}
                />
                <S.UserItemContent disabled={disabled}>
                  <S.Text type="formField">
                    {getTitleUser(user, {
                      removed: translate("chat_thread_user_removed")
                    })}
                    {user?.uuid === activeUser?.uuid
                      ? ` ${translate("thread_users_modal_active_user_label")}`
                      : null}
                  </S.Text>
                  {disabled ? (
                    <S.Text type="formSmallCaption">
                      {translate("thread_users_modal_user_disabled")}
                    </S.Text>
                  ) : null}
                </S.UserItemContent>
              </S.UserItem>
            );
          })}
        </S.UsersWrapper>
      </S.ThreadUsersModalWrapper>
    </ModalLayout>
  );
};

export default ThreadUsersModal;
