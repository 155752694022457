import styled from "styled-components";

import { SCardGroupProps } from "./types";

export const CardGroup = styled.button<SCardGroupProps>`
  height: 151px;
  min-width: 200px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.card.cardgroup.background};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: ${({ theme, checked }) =>
    checked
      ? theme.card.cardgroup.checked.border
      : theme.card.cardgroup.border};
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 20px;
  cursor: pointer;
  & > svg {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  &:focus-within {
    outline: ${({ theme }) => theme.card.cardaddcontent.focus.border};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.card.cardgroup.check.background};
    margin-right: 8px;
  }
  span {
    color: ${({ theme }) => theme.card.cardgroup.title};
  }
  svg {
    color: ${({ theme }) => theme.card.cardgroup.check.color};
  }
`;

export const TextWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
`;

export const HiddenMobile = styled.span`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;
