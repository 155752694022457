import colors from "@ds_themes/colors";

export const ctaTertiary = {
  background: colors.grey[1000],
  text: colors.grey[50],
  type: "ctaTertiary",
  hover: {
    text: colors.grey[50]
  },
  disabled: {
    text: colors.grey[800]
  },
  focus: {
    border: `${colors.grey[800]} solid 2px`
  }
};
