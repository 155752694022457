import { Button } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { __ILLUSTRATION_CREATE_NEW_GROUP__ } from "src/js/settings/settingsImage";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import * as S from "./SpacesNoGroups.styles";

const SpacesNoGroups = ({ spaceName, newGroup }) => {
  const { whiteTheme } = useTheme();
  return (
    <S.NoGroupContainer>
      <S.PlaceholderImage src={__ILLUSTRATION_CREATE_NEW_GROUP__} />
      <S.InfoTitle>
        <Translate text="create_new_group" />
      </S.InfoTitle>
      <S.InfoParagraph>
        <Translate
          text="create_inside_space"
          stringVariables={{
            space: spaceName
          }}
        />
      </S.InfoParagraph>
      <S.ButtonContainer>
        <Button
          onClick={newGroup}
          variant="primary"
          theme={whiteTheme}
          fullWidth={getBreakpoint() === "smartphone"}
        >
          <Translate text="crea nuovo gruppo" />
        </Button>
      </S.ButtonContainer>
    </S.NoGroupContainer>
  );
};

export default observer(SpacesNoGroups);
