import React from "react";
import { Box, BoxProps } from "@arcadia/design-system";
import * as S from "./CustomLegend.styles";

export type LegendItem = {
  name: string;
  color: string;
};

const CustomLegend = ({
  legendItems = [],
  ...props
}: {
  legendItems: LegendItem[];
} & BoxProps) => {
  return (
    <Box marginBottom={16} {...props}>
      <S.Legend>
        {legendItems.map(item => (
          <S.LegendItem key={item.name}>
            <S.ColorBox
              color={item.color}
              data-testid={`color-box-${item.name}`}
            />{" "}
            {item.name}
          </S.LegendItem>
        ))}
      </S.Legend>
    </Box>
  );
};

export default CustomLegend;
