import React, { useState } from "react";
import handleThumbnails from "src/js/modules/imageFunction";
import { __IMG_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import {
  useSetOriginLocation,
  useSetScrollOffset
} from "src/js/context/RestoreScrollProvider";
import { Icon, Text } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import ImagePreviewer from "src/legacy/components/imagePreviewer";
import { ImageModel, Resource } from "src/js/types";

import * as S from "./ResourceCarousel.styles";

const variants = {
  enter: (dir: number) => ({
    x: dir > 0 ? 530 : -530,
    transition: {
      duration: 0.1
    }
  }),
  center: { x: 0 },
  exit: (dir: number) => ({
    x: dir < 0 ? 530 : -530,
    transition: {
      duration: 0.1
    }
  })
};

const ResourceCarousel = ({
  resources,
  setSelectedIndex
}: {
  resources: Resource[];
  setSelectedIndex: (index: number) => void;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  const setScrollOffset = useSetScrollOffset();
  const setOriginLocation = useSetOriginLocation();

  const show = (index: number) => {
    setScrollOffset?.(window.pageYOffset);
    setOriginLocation?.(window.location.hash.replace("#", "/"));
    setSelectedIndex(index);
  };

  const next = () => {
    setDirection(1);
    setCurrentIndex(prevIndex => (prevIndex + 1) % resources.length);
  };

  const prev = () => {
    setDirection(-1);
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + resources.length) % resources.length
    );
  };

  const images = resources.map((resource, index) => {
    const placeholder = getPlaceholder(resource);
    // TODO fix this, seems that the resource preview is empty, the preview is not working in prod either
    let backgroundImage = handleThumbnails(
      resource.preview as unknown as ImageModel,
      "large"
    );
    if (
      resource.type === "EXERCISE" ||
      backgroundImage === __IMG_PLACEHOLDER__
    ) {
      backgroundImage = "";
    }

    return (
      <S.CarouselCell
        onClick={() => show(index)}
        key={resource.id}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: {
            type: "spring",
            stiffness: 400,
            damping: 40
          }
        }}
      >
        {/* TODO fix this legacy Previewer */}
        <ImagePreviewer
          className="slide-image"
          src={backgroundImage}
          placeholder={placeholder}
          multiple
          isReady
          type="DIV"
          background="#8b5cff"
          alt=""
          width=""
          height=""
          is_preview_generated={false}
          preview_available_generator={false}
          created={0}
          threshold={0}
          forceSrc={false}
          showLoading={false}
        />
        <S.InfoBox>
          <Text type="formFieldEmpty">{resource.name}</Text>
        </S.InfoBox>
      </S.CarouselCell>
    );
  });

  return (
    <S.CarouselWrapper>
      {currentIndex > 0 && (
        <S.PrevButton onClick={prev}>
          <Icon icon="chevronSmallLeft" width={24} height={24} initialViewbox />
        </S.PrevButton>
      )}
      <AnimatePresence custom={direction} initial={false} exitBeforeEnter>
        <div key={currentIndex}>{images[currentIndex]}</div>
      </AnimatePresence>
      {currentIndex < resources.length - 1 && (
        <S.NextButton onClick={next}>
          <Icon
            icon="chevronSmallRight"
            width={24}
            height={24}
            initialViewbox
          />
        </S.NextButton>
      )}
    </S.CarouselWrapper>
  );
};

export default ResourceCarousel;
