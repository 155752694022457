import styled from "styled-components";
import { Text } from "@arcadia/design-system";

export const CommentContainer = styled.div`
  padding: 16px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
`;

export const MobileHeaderTitle = styled(Text)`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;

export const MobileHeaderCancelCta = styled(Text)`
  font-weight: 400;
`;
