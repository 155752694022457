import React, { Component } from "react";
import { Translate } from "src/js/translation/TranslationProvider";

// https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929

class DragAndDropArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false
    };

    this.dropRef = React.createRef();

    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  handleDrop = e => {
    const { handleDrop } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = {
        target: {
          files: e.dataTransfer.files
        }
      };
      handleDrop(files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  render() {
    const { children } = this.props;
    const { dragging } = this.state;
    return (
      <div
        style={{ display: "inline-block", position: "relative" }}
        ref={this.dropRef}
      >
        {dragging && (
          <div className="resource-drag-area--active">
            <div className="resource-drag-area-content">
              <Translate text="release_file" />
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
}
export default DragAndDropArea;
