import {
  DropdownMultiple,
  SearchBarWithSuggestions,
  Text
} from "@arcadia/design-system";
import React, { useRef, useState } from "react";
import WarningBanner from "src/js/components/layout/WarningBanner/WarningBanner";
import { useOnClickOutSide, useStores } from "src/js/hooks";
import { readLocalData } from "src/js/modules/storageFunction";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import SearchBoxUserSuggestions from "./SearchBoxUserSuggestions";

import * as S from "./AnalyticsSectionFilters.styles";
import { warningProps } from "./translations";

const AnalyticsSectionFilters = ({
  groupsList = [],
  usersList = [],
  selectedUser = null,
  searchedUser = null,
  selectedGroups = [],
  hasNextGroups = false,
  showNextGroups = () => {},
  onUserSearch = () => {},
  onSelectUser = () => {},
  onResetUserFilter = () => {},
  onSearchSubmission = () => {},
  onSelectGroups = () => {}
}) => {
  const [filterString, setFilterString] = useState("");
  const [showRecentUsersList, setShowRecentUsersList] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filterGroupString, setFilterGroupString] = useState("");
  const { greyTheme } = useTheme();

  const optionsRef = useRef();

  const { SpaceStore } = useStores();
  const { isDemoSpace } = SpaceStore;

  useOnClickOutSide(optionsRef, () => {
    setShowSuggestions(false);
  });

  const recentUserList =
    JSON.parse(readLocalData("analytics-last-user-search")) || [];

  const resetInputField = () => {
    document.getElementById("analytics-searchbar-user").value = "";
  };

  const handleKeyDown = e => {
    if (e.key === "Backspace" && selectedUser) {
      onResetUserFilter();
      resetInputField();
    }
  };
  return (
    <S.FiltersContainer>
      <div onKeyDown={handleKeyDown}>
        <S.SearchBarContainer>
          <Translator>
            {({ translate }) => (
              <SearchBarWithSuggestions
                id="analytics-searchbar-user"
                theme={greyTheme}
                showSuggestions={
                  (showSuggestions && !selectedUser && usersList.length > 0) ||
                  (showRecentUsersList && recentUserList.length > 0)
                }
                onSearch={() => {
                  onSearchSubmission(selectedUser);
                  setShowRecentUsersList(false);
                }}
                onClick={() => {
                  if (selectedUser) return;
                  if (filterString === "") {
                    setShowRecentUsersList(true);
                  }
                  setShowSuggestions(true);
                }}
                placeholder={translate("user_search_placeholder")}
                value={
                  selectedUser
                    ? `${selectedUser.name} ${selectedUser.surname}`
                    : undefined
                }
                onChange={e => {
                  onUserSearch(e);
                  setShowRecentUsersList(false);
                  setFilterString(e.target.value);
                }}
                onReset={
                  searchedUser
                    ? () => {
                        onResetUserFilter();
                        resetInputField();
                      }
                    : undefined
                }
              >
                <div ref={optionsRef}>
                  <SearchBoxUserSuggestions
                    filterString={filterString}
                    usersList={usersList}
                    onSelectUser={e => {
                      onSelectUser(e);
                      setShowRecentUsersList(false);
                    }}
                    showRecentUsersList={showRecentUsersList}
                  />
                </div>
              </SearchBarWithSuggestions>
            )}
          </Translator>
        </S.SearchBarContainer>
      </div>
      <S.RowContainer>
        <S.DropdownContainer>
          <S.DropdownLabelContainer>
            <Text type="formDescription">
              <Translate text="analytics_filter_dropdown_label" />
            </Text>
          </S.DropdownLabelContainer>
          <S.DropdownFilterContainer>
            <Translator>
              {({ translate }) => {
                return (
                  <DropdownMultiple
                    theme={greyTheme}
                    hideButtonTags
                    optionsList={groupsList
                      .filter(({ value }) =>
                        value
                          .toLowerCase()
                          .includes(filterGroupString.toLowerCase())
                      )
                      .map(({ key, value }) => ({
                        id: key,
                        label: value
                      }))}
                    selectedOptionIds={
                      groupsList.length === 0 ? [] : selectedGroups
                    }
                    setSelectedOptionIds={value => onSelectGroups(value)}
                    placeholder={translate("groups")}
                    searchPlaceholder={translate("search_in_n_groups", {
                      nGroups: groupsList.length
                    })}
                    scrollableListId="space-group-filter-dropdown-multiple"
                    searchable
                    searchQuery={filterGroupString}
                    onSearchFunction={e => {
                      setFilterGroupString(e.target.value);
                    }}
                    hasNext={hasNextGroups}
                    showNext={showNextGroups}
                  />
                );
              }}
            </Translator>
          </S.DropdownFilterContainer>
        </S.DropdownContainer>
        {isDemoSpace ? (
          <WarningBanner text={warningProps.text} cta={warningProps.cta} />
        ) : null}
      </S.RowContainer>
      <Translator>
        {({ translate }) => (
          <S.StyledFilterBar
            selectedFiltersKeys={selectedGroups}
            options={groupsList.map(({ key, value }) => ({
              id: key,
              label: translate(value)
            }))}
            removeFilter={groupToRemove => {
              onSelectGroups(
                selectedGroups.filter(group => group !== groupToRemove)
              );
            }}
            removeAllFilters={() => onSelectGroups([])}
          />
        )}
      </Translator>
    </S.FiltersContainer>
  );
};

export default AnalyticsSectionFilters;
