import { skeletonAnimationMixin } from "@arcadia/design-system";
import React, { FC, MouseEventHandler } from "react";
import { useResourceMediaFetcher } from "src/js/hooks/resources";
import { Resource } from "src/js/types";
import styled from "styled-components";
import ResourceAssetPreview, {
  SkeletonResourcePreview
} from "./ResourceAssetPreview";

const GridItem = styled.div<{ isLoading: boolean }>`
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "zoom-in")};
  aspect-ratio: 1 / 1.05;
  @media (min-width: 768px) {
    :only-child {
      width: 300px;
    }
  }
  @media (max-width: 767px) {
    :only-child {
      max-width: 300px;
    }
    :nth-child(4n + 1) {
      grid-column: 1;
    }
    :nth-child(4n + 2) {
      grid-column: 2;
    }
    :nth-child(4n + 3) {
      grid-column: 3;
    }
    :nth-child(4n + 4) {
      grid-column: 4;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 8px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 767px) {
    grid-auto-flow: column;
  }
  ${SkeletonResourcePreview} {
    background-color: transparent;
    ${({ theme }) =>
      skeletonAnimationMixin(
        theme.colors.grey[1000],
        theme.colors.violet[900]
      )};
  }
`;

const ResourceItemPreview: FC<{
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  resource?: Resource;
  loading?: boolean;
}> = ({ className, resource, onClick, loading }) => {
  const { isMediaResource, mediaPublicUrl } = useResourceMediaFetcher(resource);

  const isLoading = (isMediaResource && !mediaPublicUrl) || loading;

  return (
    <GridItem isLoading={isLoading} className={className} onClick={onClick}>
      <ResourceAssetPreview
        isLoading={isLoading}
        resource={resource}
        isMediaResource={isMediaResource}
        mediaPublicUrl={mediaPublicUrl}
      />
    </GridItem>
  );
};

const ResourceGridPreview: FC<{
  resources?: Resource[];
  onClickItem?: (i: number) => void;
  loading?: boolean;
}> = ({ resources, onClickItem, loading }) => {
  return (
    <GridContainer>
      {resources.map((resource, i) => (
        <ResourceItemPreview
          key={`thread-grid-resource-${resource?.id}`}
          onClick={() => onClickItem(i)}
          resource={resource}
          loading={loading}
        />
      ))}
    </GridContainer>
  );
};

export default ResourceGridPreview;
