import { extractErrorMessage } from "src/js/modules/messageManager";
import getUserClient from "src/js/modules/connection";
import {
  api_search_users,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { UserDetailsModel } from "../types";

type ResType = { results: UserDetailsModel[]; hasNext: boolean };

async function fetchSearchUserList(start: number, limit: number, key?: string) {
  return getUserClient()
    .get<ResType>(getVersionedApiUrl(api_search_users(), "v3"), {
      params: {
        offset: start,
        limit,
        textSearch: key || undefined
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_search_user_list")
      );
      throw Err;
    });
}

export { fetchSearchUserList as default };
