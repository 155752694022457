import React from "react";
import { ResourceIcon } from "src/js/components/ResourceIcon";
import { RESOURCE_ICON_SIZE } from "src/js/components/ResourceIcon/ResourceIcon.const";
import { Icon } from "@arcadia/design-system";
import { EventAttachmentMode } from "src/js/pages/calendar/Calendar.types";
import * as S from "./EventAttachmentItem.styles";
import { EventAttachmentItemProps } from "./EventAttachmentItem.types";

const EventAttachmentItem = ({
  mode = EventAttachmentMode.View,
  name = "",
  id,
  type,
  deleteResource = () => {},
  openResource = () => {},
  marginBottom
}: EventAttachmentItemProps) => {
  return (
    <S.Container
      marginBottom={marginBottom}
      type={type}
      onClick={() => openResource({ id, type })}
    >
      <ResourceIcon type={type} size={RESOURCE_ICON_SIZE.CALENDAR_ATTACHMENT} />
      <S.TextContainer>{name}</S.TextContainer>
      {mode === EventAttachmentMode.Edit && (
        <S.DeleteContainer
          onClick={e => {
            e.stopPropagation();
            deleteResource(id);
          }}
        >
          <Icon icon="closeBold" height={8} width={8} />
        </S.DeleteContainer>
      )}
    </S.Container>
  );
};

export default EventAttachmentItem;
