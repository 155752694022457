import React from "react";
import { REACTION_TYPES, ReactionType } from "src/js/types/models/Reaction";
import { getShortcodeFromType } from "../Reactions.utils";

import * as S from "./ReactionPicker.styles";

const ReactionPicker = ({
  onSelect,
  selectedReaction,
  hasBubblesStyle,
  className
}: {
  onSelect: (reactionType: ReactionType) => void;
  selectedReaction?: ReactionType;
  hasBubblesStyle?: boolean;
  className?: string;
}) => {
  return (
    <S.Wrapper className={className}>
      {Object.values(REACTION_TYPES).map((reactionType: ReactionType) => (
        <S.Element
          key={reactionType}
          onClick={() => onSelect(reactionType)}
          hasBubblesStyle={hasBubblesStyle}
          isSelected={reactionType === selectedReaction}
        >
          <em-emoji
            shortcodes={getShortcodeFromType(reactionType)}
            set="native"
            size="24px"
          />
        </S.Element>
      ))}
    </S.Wrapper>
  );
};

export default ReactionPicker;
