import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./Button.styles";
import { ButtonProps } from "./types";

const Button: FC<ButtonProps> = ({
  className,
  theme,
  variant,
  children,
  disabled,
  onClick,
  withIcon = {},
  fullWidth,
  type = "button",
  id
}) => {
  const idButton = id || variant + Math.floor(Math.random() * 1000) + 1;
  return (
    <ThemeProvider theme={theme}>
      <S.Button
        className={className}
        type={type}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        withIcon={withIcon}
        fullWidth={fullWidth}
        id={idButton}
      >
        <Text type="cta">{children}</Text>
        {withIcon.icon ? (
          <Icon
            icon={withIcon.icon}
            height={withIcon.heightIcon}
            width={withIcon.widthIcon}
            viewbox={withIcon.viewbox}
            color={theme.button[variant].text}
          />
        ) : null}
      </S.Button>
    </ThemeProvider>
  );
};

export default Button;
