import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

const RestoreScrollContext = React.createContext();

const useRestoreScrollContext = () => {
  return React.useContext(RestoreScrollContext);
};

export const useSetScrollOffset = () => {
  const { setScrollOffset } = useRestoreScrollContext();
  return setScrollOffset;
};

export const useSetOriginLocation = () => {
  const { setOriginLocation } = useRestoreScrollContext();
  return setOriginLocation;
};

const RestoreScrollProvider = ({ children }) => {
  const [scrollOffset, setScrollOffset] = useState(null);
  const [originLocation, setOriginLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (scrollOffset && location.pathname === originLocation) {
      setTimeout(() => {
        window.scrollTo(0, scrollOffset);
        setScrollOffset(null);
      });
    }
  }, [location]);

  return (
    <RestoreScrollContext.Provider
      value={{ setScrollOffset, setOriginLocation }}
    >
      {children}
    </RestoreScrollContext.Provider>
  );
};

export default RestoreScrollProvider;
