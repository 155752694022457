// import styled from "styled-components";

import styled from "styled-components";

// TODO Define how to style a general Editor view
export const EditorWrapper = styled.div`
  table,
  tr,
  td {
    border: 1px solid black;
  }
  width: 100%;
  height: 100%;

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0.2px;
  }
  h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
  }
  h2 {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
  }
`;

export const FixedMenu = styled.div`
  position: relative;
`;
