import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import without from "lodash/without";
import map from "lodash/map";
import { textEditorManager } from "src/legacy/modules/textEditorManager";
import { __QUIZ_MAX_POINTS__ } from "src/js/settings/settingsExercise";
import {
  Box,
  Button,
  ContextualError,
  Text,
  Tag
} from "@arcadia/design-system";
import styled, { withTheme } from "styled-components";
import { detectWeschoolImg } from "src/js/modules/exercisesFunction";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { __QUIZ_ANSWER_TITLE_MAX_LENGTH__ } from "../../../settings/settings";
import { __, getLanguage } from "../../../modules/localization";
import { stripHtml } from "../../../modules/utility";
import QuizLabel from "./QuizLabel";
import { fetchResourceImageInTitle } from "./quizFunctions";

const StyledInputWrapper = styled.div`
  input[type="text"] {
    max-width: 100% !important;
  }
`;
function validateQuiz(title = "", vote = 0) {
  if (title == "") {
    return "Attenzione, verificare i dati inseriti";
  }
  if (title.length > __QUIZ_ANSWER_TITLE_MAX_LENGTH__) {
    return "max-question-char-800";
  }
  if (!vote || vote < 1 || vote > __QUIZ_MAX_POINTS__) {
    return "quiz_vote_validation_error";
  }
  return true;
}

const updateEditor = () => {
  textEditorManager.destroy("quiz-title");
  if (getBreakpoint() !== "desktop") {
    textEditorManager.init("#quiz-title", "mobile", getLanguage());
  } else {
    textEditorManager.init("#quiz-title", "exercise", getLanguage());
  }
};

const QuizBuilderShortanswer = withBackbone(
  createReactClass({
    form: "#form-quiz-builder-shortanswer",
    getInitialState() {
      const { quiz } = this.props;
      return {
        status: "loading",
        title: quiz.get("title"),
        available_points: quiz.getAvailablePoints(),
        solutions: quiz.getSolutions(),
        addedWords: map(quiz.getSolutions(), "value"),
        errorMessage: null
      };
    },
    componentDidMount() {
      const { title } = this.state;
      if (title && detectWeschoolImg(title)) {
        fetchResourceImageInTitle(title)
          .then(results => {
            this.setState({ title: results, status: "" }, () => {
              updateEditor();
            });
          })
          .catch(() => {
            this.setState({ status: "" }, () => {
              updateEditor();
            });
          });
      } else {
        this.setState({ status: "" }, () => {
          updateEditor();
        });
      }

      $(this.form).on("submit", e => {
        e.preventDefault();
      });

      this.initValidation();
    },
    componentDidUpdate() {
      this.initValidation();
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(true);
    },
    componentWillUnmount() {
      textEditorManager.destroy("quiz-title");
    },
    submitForm() {
      $(this.form).trigger("submit");
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        borderColorOnError: "",
        onError() {
          self.setState({
            errorMessage: "Attenzione, verificare i dati inseriti"
          });
        },
        onSuccess() {
          const title = stripHtml(
            textEditorManager.getContent(`${self.form} #quiz-title`)
          );
          const available_points = self.state.available_points
            ? self.state.available_points
            : 1;
          const passValidation = validateQuiz(title, available_points);
          if (passValidation === true) {
            self.addQuiz();
          } else {
            self.setState({ errorMessage: passValidation });
          }
          return false;
        }
      });
    },
    checkSubmit(e) {
      if (e.which === 13) {
        // invio
        e.preventDefault();
        this.addWord();
      }
    },
    addWord() {
      const word = $("#newWord").val().toLowerCase();
      if (word !== "") {
        const arr = this.state.addedWords;
        arr.push(word);
        this.setState({ addedWords: arr, errorMessage: null });
        $("#newWord").val("");
      }
    },
    removeWord(i) {
      const word = this.state.addedWords[i];
      const arr = without(this.state.addedWords, word);
      this.setState({ addedWords: arr });
    },
    addQuiz() {
      const self = this;
      const title = textEditorManager.getContent(`${self.form} #quiz-title`);
      this.addWord(); // salvo parola digitata anche su pulsante salva
      this.setState({ status: "loading" });

      if (this.state.addedWords.length === 0) {
        this.setState({
          status: "",
          errorMessage: "Inserire almeno una risposta"
        });
      } else {
        const { quiz } = self.props;

        quiz.set("type", "short_answer");
        quiz.set("title", title);
        if (!self.state.available_points) {
          quiz.set("available_points", 1);
        } else {
          quiz.set(
            "available_points",
            parseInt(self.state.available_points, 10)
          );
        }

        if (!quiz.isCreated()) {
          quiz.set("questions", []);
        }
        quiz.set(
          "solutions",
          self.state.addedWords.map((word, i) => ({ index: i, value: word }))
        );

        const optionalCallbackSuccess = function () {
          self.setState({ status: "" });
          self.props.backFunc();
        };

        const optionalCallbackError = function () {
          self.setState({ status: "" });
        };
        if (typeof self.props.setChanged === "function")
          self.props.setChanged(false);
        self.props.addFunc(
          quiz,
          optionalCallbackSuccess.bind(this),
          optionalCallbackError.bind(this)
        );
      }
    },
    render() {
      const self = this;
      const { whiteTheme } = this.props.theme;
      const { errorMessage, status } = this.state;
      const selectedWords = this.state.addedWords.map((word, i) => (
        <Tag
          theme={whiteTheme}
          onClick={() => self.removeWord(i)}
          type="solid"
          color="violet"
          label={word}
        />
      ));

      return (
        <div>
          <form
            id="form-quiz-builder-shortanswer"
            className="form-quiz-builder"
            role="form"
            method="post"
          >
            {status !== "loading" && (
              <div>
                <div>
                  <textarea
                    className="form-control text-editor"
                    id="quiz-title"
                    name="title"
                    defaultValue={self.state.title}
                    onChange={e =>
                      this.setState({
                        title: e.target.value,
                        errorMessage: null
                      })
                    }
                    placeholder={__("domanda")}
                  />
                </div>
              </div>
            )}
            <div>
              <QuizLabel text="risposte accettate" />
              <div>
                <StyledInputWrapper>
                  <input
                    type="text"
                    id="newWord"
                    className="form-control primary"
                    placeholder={__("Inserisci una risposta e premi Invio")}
                    ref="newWord"
                    onChange={() => {
                      this.setState({ errorMessage: null });
                    }}
                    onKeyDown={this.checkSubmit}
                  />
                </StyledInputWrapper>
                {selectedWords.length > 0 ? (
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="16px"
                    marginTop="16px"
                  >
                    {selectedWords}
                  </Box>
                ) : null}
              </div>
            </div>
            <div>
              <QuizLabel text="quiz_creation_vote_label" />
              <div className="point-section">
                <div className="point-input">
                  <input
                    type="number"
                    id="quiz_available_points"
                    className="form-control primary"
                    defaultValue={self.state.available_points}
                    placeholder={1}
                    ref="quiz_available_points"
                    onChange={e =>
                      this.setState({
                        available_points: e.target.value,
                        errorMessage: null
                      })
                    }
                  />
                  <div className="point-label">
                    {__("quiz_creation_point_label")}
                  </div>
                </div>
                <div className="submit-quiz spaced-button-left">
                  {errorMessage && (
                    <ContextualError
                      theme={whiteTheme}
                      text={__(errorMessage)}
                    />
                  )}
                  <Button type="submit" theme={whiteTheme} variant="primary">
                    {__("save")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  })
);

export default withTheme(QuizBuilderShortanswer);
