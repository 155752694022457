import styled from "styled-components";
import {
  Icon,
  VariantType,
  scrollbarMixin,
  shadow
} from "@arcadia/design-system";
import { motion } from "framer-motion";

export const EditSheet = styled(motion.div)`
  position: absolute;
  padding: 8px;
  bottom: 100%;
  right: 0;
  z-index: 1199;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  box-shadow: ${shadow[600]};
  max-height: 250px;
  overflow: auto;
  ${scrollbarMixin(VariantType.Grey)}
`;

export const SheetOption = styled.div`
  color: ${({ theme }) => theme.colors.grey[200]};
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  min-width: 184px;
  transition: 120ms ease-in-out;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;

export const LeadingIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.violet[501]};
`;

export const BottomSheetWrapper = styled.div`
  max-height: 50dvh;
`;

export const BottomSheetOption = styled.div`
  padding: 12px 16px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
