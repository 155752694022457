export const LABEL_POSITIONS = {
  FROM_LEFT: "from_left",
  FROM_TOP: "from_top"
};

export const INPUT_VARIANT = {
  LIGHT: "light",
  DARK: "dark",
  DEFAULT: "default"
};

export const ICON_POSITION = {
  LEFT: "left",
  RIGHT: "right"
};
