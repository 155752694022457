import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.primaryColor[500]};

  @media screen and (max-width: 767px) {
    flex-flow: row;
  }
`;
