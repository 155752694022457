import { WeSchoolRouteValuesType } from "src/js/pages/PagesRouter.types";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";

export const SIDEBAR_BLACKLIST: WeSchoolRouteValuesType[] = [
  WeSchoolRoutes.App.home,
  WeSchoolRoutes.App.Users.userProfile,
  WeSchoolRoutes.App.Settings.initialRouteName,
  WeSchoolRoutes.App.publicProfile,
  WeSchoolRoutes.App.privacyPolicy,
  WeSchoolRoutes.App.Settings.settingsAccountOption,
  WeSchoolRoutes.App.Settings.settingsOption,
  WeSchoolRoutes.App.Spaces.SpaceGroup.groupOverquotaBlocked,
  WeSchoolRoutes.App.Spaces.spaceGroupsOverquotaBlocked
];

export const LAYOUT_BLACKLIST: WeSchoolRouteValuesType[] = [
  ...Object.values(WeSchoolRoutes.Onboarding),
  WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseRun,
  WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseRunOption,
  WeSchoolRoutes.App.Spaces.SpaceGroup.Exercises.exerciseInstantRun,
  WeSchoolRoutes.App.Spaces.SpaceGroup.whiteboard,
  WeSchoolRoutes.App.underageApprovation
];
