import { AvatarWithInitials } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import WeschoolLogo from "src/img/icons/svg/weschool_logo.svg";
import WeSchoolMonogramSVG from "src/img/icons/svg/weschool_monogram.svg";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import * as S from "./HeroLayout.styles";
import { DesktopProps, HeroLayoutProps, MobileProps } from "./HeroLayout.types";

const Desktop: FC<DesktopProps> = ({
  background,
  backgroundColor,
  children,
  title,
  description,
  hasCustomization,
  customizationSettings
}) => {
  const { whiteTheme, primaryColor } = useTheme();

  return (
    <S.DesktopHeroLayout>
      <S.DesktopHero
        background={customizationSettings?.layoutColor ? null : background}
        backgroundColor={customizationSettings?.layoutColor || backgroundColor}
      >
        {hasCustomization ? (
          <S.MonogramContainer>
            <WeSchoolMonogramSVG
              width="42px"
              height="32px"
              viewBox="0 0 26 19"
            />
          </S.MonogramContainer>
        ) : (
          <S.HeroWrapText>
            <S.HeroTitle>{title}</S.HeroTitle>
            <S.HeroDescription>{description}</S.HeroDescription>
          </S.HeroWrapText>
        )}
        {customizationSettings?.whiteLabelLogo ? (
          <S.DesktopAvatarWrapper>
            <AvatarWithInitials
              theme={whiteTheme}
              alt={customizationSettings?.whiteLabelName}
              url={customizationSettings?.whiteLabelLogo?.originalUrl}
              name={customizationSettings?.whiteLabelName}
              avatarColor={0}
              size="auto"
            />
          </S.DesktopAvatarWrapper>
        ) : null}
      </S.DesktopHero>
      <S.DesktopContent>
        {!customizationSettings?.whiteLabelName ? (
          <S.Header>
            <WeschoolLogo color={primaryColor[500]} />
          </S.Header>
        ) : null}
        {children}
      </S.DesktopContent>
    </S.DesktopHeroLayout>
  );
};

const Mobile: FC<MobileProps> = ({
  background,
  backgroundColor,
  children,
  hasCustomization,
  customizationSettings
}) => {
  const { colors } = useTheme();
  return (
    <S.MobileHeroLayout
      background={hasCustomization ? null : background}
      backgroundColor={hasCustomization ? colors.grey[1000] : backgroundColor}
    >
      {!customizationSettings?.whiteLabelName ? (
        <S.Header>
          <WeschoolLogo color={colors.grey[1000]} />
        </S.Header>
      ) : null}
      {children}
    </S.MobileHeroLayout>
  );
};

const HeroLayout: FC<HeroLayoutProps> = ({ desktop, mobile }) => {
  const {
    UIStore: { isLayoutModeMobile },
    ThemeStore: { customizationSettings, hasCustomization }
  } = useStores();

  return isLayoutModeMobile ? (
    <Mobile
      {...mobile}
      hasCustomization={hasCustomization}
      customizationSettings={customizationSettings}
    />
  ) : (
    <Desktop
      {...desktop}
      hasCustomization={hasCustomization}
      customizationSettings={customizationSettings}
    />
  );
};

export default observer(HeroLayout);
