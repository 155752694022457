import * as Tooltip from "@radix-ui/react-tooltip";
import { Box, Heading, Icon, Text } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import React, { Children, ReactNode, useEffect, useState } from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { useStores } from "src/js/hooks";
import { EventDomain, StateTrendSeverityType } from "src/js/types";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { ChipMetricTrend } from "../ChipMetricTrend";
import * as S from "./MetricCard.styles";

type MetricCardProps = {
  loading?: boolean;
  children: ReactNode;
  titleLabel: string;
  resultLabel?: string;
  descriptionLabel: string;
  trendLabel?: string;
  trendSeverity?: StateTrendSeverityType;
  dateLabel?: string;
  activeUsersLabel?: string;
  titleChipLabel?: string;
  detailViewLabel?: string;
  onClickDetailView?: () => void;
  infoTooltip?: ReactNode;
  trackingEvents?: {
    tooltip: { domain: EventDomain; action: string };
  };
};

const MetricCard = ({
  loading,
  children,
  titleLabel,
  resultLabel,
  descriptionLabel,
  trendLabel,
  trendSeverity,
  dateLabel = null,
  activeUsersLabel = null,
  titleChipLabel = null,
  detailViewLabel,
  onClickDetailView,
  infoTooltip,
  trackingEvents
}: MetricCardProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { primaryColor, colors } = useTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (isTooltipOpen && trackingEvents?.tooltip) {
      _trackEvent(trackingEvents.tooltip.domain, trackingEvents.tooltip.action);
    }
  }, [isTooltipOpen]);

  return (
    <Box borderRadius={12} backgroundColor={colors.grey[1000]}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        padding="24px 24px 16px"
        {...(isLayoutModeMobile && {
          padding: 16
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={8}
          flex={1}
          {...(isLayoutModeMobile && {
            flexDirection: "column",
            alignItems: "start"
          })}
        >
          <Skeleton loading={loading} borderRadius={16}>
            <Heading level="6">{titleLabel}</Heading>
          </Skeleton>
          {dateLabel ? (
            <Skeleton loading={loading} borderRadius={16}>
              <S.ChipBox>
                <Icon initialViewbox icon="calendar" width={16} height={16} />
                <Text type="formSmallCaption">{dateLabel}</Text>
              </S.ChipBox>
            </Skeleton>
          ) : null}
          {!loading && titleChipLabel ? (
            <S.ChipBox
              background={primaryColor[900]}
              color={primaryColor[300]}
              marginLeft={isLayoutModeMobile ? 0 : "auto"}
            >
              <Text type="formSmallCaption">{titleChipLabel}</Text>
            </S.ChipBox>
          ) : null}
        </Box>
        {infoTooltip ? (
          <Box paddingLeft={8}>
            <Skeleton loading={loading} borderRadius={16}>
              <Tooltip.Provider delayDuration={0}>
                <Tooltip.Root
                  open={isTooltipOpen}
                  onOpenChange={setIsTooltipOpen}
                >
                  <Tooltip.Trigger
                    asChild
                    onClick={() => setIsTooltipOpen(true)}
                  >
                    <S.InfoButton>
                      <Icon
                        initialViewbox
                        icon="infoI"
                        width={12}
                        height={12}
                      />
                    </S.InfoButton>
                  </Tooltip.Trigger>
                  <Tooltip.Portal forceMount>
                    <AnimatePresence>
                      {isTooltipOpen ? (
                        <Tooltip.Content
                          forceMount
                          asChild
                          side="left"
                          sideOffset={8}
                        >
                          <S.MotionTooltipContent
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, y: -10 }}
                            exit={{ opacity: 0, y: 0 }}
                            transition={{
                              duration: 0.2
                            }}
                          >
                            {infoTooltip}
                          </S.MotionTooltipContent>
                        </Tooltip.Content>
                      ) : null}
                    </AnimatePresence>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </Skeleton>
          </Box>
        ) : null}
      </Box>
      <Box
        padding="0px 24px 0px"
        {...(isLayoutModeMobile && {
          padding: "0px 16px 16px"
        })}
      >
        {loading || resultLabel ? (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            gap={8}
            paddingBottom={8}
          >
            <Skeleton
              loading={loading}
              borderRadius={16}
              minHeight={24}
              minWidth={40}
            >
              <Heading level="4">{resultLabel}</Heading>
            </Skeleton>
            <ChipMetricTrend
              loading={loading}
              trendSeverity={trendSeverity}
              label={trendLabel}
            />
            {!loading && activeUsersLabel ? (
              <S.ChipBox
                background={primaryColor[900]}
                color={primaryColor[300]}
                {...(isLayoutModeMobile && {
                  margin: "0 0 0 auto"
                })}
              >
                <Icon
                  style={{ flexShrink: 0 }}
                  initialViewbox
                  icon="user"
                  width={12}
                  height={12}
                  color={primaryColor[300]}
                />
                <Text type="formSmallCaption">{activeUsersLabel}</Text>
              </S.ChipBox>
            ) : null}
            {!loading &&
            !isLayoutModeMobile &&
            onClickDetailView &&
            detailViewLabel ? (
              <Box paddingLeft={8} marginLeft="auto">
                <S.DetailButton onClick={onClickDetailView}>
                  <Icon
                    initialViewbox
                    icon="plusThin"
                    width={12}
                    height={12}
                    color={primaryColor[500]}
                  />
                  <Text type="formDescription">{detailViewLabel}</Text>
                </S.DetailButton>
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Skeleton loading={loading} borderRadius={16}>
          <S.DescriptionText type="formSubtitle">
            {descriptionLabel}
          </S.DescriptionText>
        </Skeleton>
      </Box>
      {Children.toArray(children)?.length > 0 ? (
        <Box padding={isLayoutModeMobile ? "16px 16px 32px" : "28px 24px 32px"}>
          <Skeleton loading={loading} borderRadius={16}>
            {children}
          </Skeleton>
        </Box>
      ) : null}
    </Box>
  );
};

export default MetricCard;
