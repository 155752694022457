import React from "react";
import { Icon, IconName } from "@arcadia/design-system";
import {
  EventCategory,
  EventCategoryType
} from "src/js/pages/calendar/Calendar.types";
import * as S from "./CalendarLabel.styles";

type CalendarLabelPropsType = {
  type: EventCategoryType;
  title: string;
  allDayMode?: boolean;
};

const CalendarLabel = ({
  type,
  title,
  allDayMode = false
}: CalendarLabelPropsType) => {
  let icon: IconName;
  switch (type) {
    case EventCategory.Live:
      icon = "videoCameraSolid";
      break;
    case EventCategory.Activity:
      icon = "bulletedList";
      break;
    case EventCategory.Deadline:
      icon = "stopWatchSolid";
      break;
    default:
      icon = "micSolid";
  }
  return (
    <S.CalendarLabelWrapper type={type}>
      <S.CalendarLabelContainer type={type} allDayMode={allDayMode}>
        <S.IconContainer>
          <Icon height="10" width="10" icon={icon} initialViewbox />
        </S.IconContainer>
        <S.TextContainer>{title}</S.TextContainer>
      </S.CalendarLabelContainer>
    </S.CalendarLabelWrapper>
  );
};

export default CalendarLabel;
