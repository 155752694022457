import { Palette, ToggleThemeOptions } from "@ds_themes/types";
import { decreaseBrightness, getHighContrastColor } from "@ds_themes/utils";

export const createToggleTheme = ({
  grey,
  primary
}: Palette): { toggle: ToggleThemeOptions } => {
  return {
    toggle: {
      backgroundOn: getHighContrastColor(
        primary.main,
        primary.dark,
        primary.main
      ),
      backgroundOff: grey[1000],
      sliderOn: grey[1000],
      sliderOff: getHighContrastColor(primary.main, primary.dark, primary.main),
      disabled: {
        background: getHighContrastColor(
          primary.main,
          decreaseBrightness(primary.main, 5),
          primary.light
        ),
        sliderOn: grey[1000],
        sliderOff: getHighContrastColor(
          primary.main,
          decreaseBrightness(primary.main, 5),
          primary.light
        )
      },
      hover: {
        background: getHighContrastColor(
          primary.main,
          primary.darker,
          primary.dark
        ),
        slider: grey[1000]
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};
