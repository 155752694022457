import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ConfirmationAlert.styles";
import { ConfirmationAlertProps } from "./types";

const ConfirmationAlert: FC<ConfirmationAlertProps> = ({
  theme,
  text,
  onDeclineFunction,
  declineText,
  onAcceptFunction,
  acceptText
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ConfirmationAlertContainer>
        <S.ConfirmationAlertIcon>
          <Icon icon="dangerCircle" width={16} height={16} />
        </S.ConfirmationAlertIcon>
        <S.ConfirmationAlertInfoContainer>
          <S.ConfirmationAlertMessage>
            <Text type="cta">{text}</Text>
          </S.ConfirmationAlertMessage>

          <S.ConfirmationAlertAction>
            <S.ConfirmationAlertAccept onClick={onAcceptFunction}>
              <Text type="cta">{acceptText}</Text>
            </S.ConfirmationAlertAccept>
            <S.ConfirmationAlertDecline onClick={onDeclineFunction}>
              <Text type="cta">{declineText}</Text>
            </S.ConfirmationAlertDecline>
          </S.ConfirmationAlertAction>
        </S.ConfirmationAlertInfoContainer>
      </S.ConfirmationAlertContainer>
    </ThemeProvider>
  );
};

export default ConfirmationAlert;
