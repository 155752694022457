import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  line-height: 27px;
  display: flex;
  & > span {
    line-height: unset;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: -8px;
  margin-left: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.violet[501]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 2px 4px;
  display: inline-flex;
  letter-spacing: 0.5px;
  @media (max-width: 767px) {
    margin-right: -12px;
    width: 8px;
    height: 8px;
    top: -6px;
    & > span {
      display: none;
    }
  }
`;
