import styled from "styled-components";
import { motion } from "framer-motion";
import { ItemStyleProps } from "./SidebarThreadItem.types";

export const UsersNumber = styled.div<ItemStyleProps>`
  position: absolute;
  right: -4px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  min-width: 12px;
  padding: 0 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const NotificationStatus = styled.div<ItemStyleProps>`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-left: 5px;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 80%;
`;

export const Name = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
  }
  > span {
    white-space: nowrap;
  }
`;

export const NotificationNumber = styled(motion.div)<ItemStyleProps>`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  border-radius: 9px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.yellow[500] : theme.primaryColor[500]};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.grey[100] : theme.colors.headerTextColor};
`;

export const MutedIcon = styled(motion.div)<ItemStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.yellow[500] : theme.colors.grey[500]};
`;

export const ItemContainer = styled.li<ItemStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  height: 40px;
  border-radius: 8px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.primaryColor[500] : theme.colors.grey[1000]};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.grey[1000] : theme.colors.grey[100]};
  overflow: hidden;
  transition:
    color 300ms ease-in-out,
    background-color 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, isSelected }) =>
      !isSelected && theme.colors.coldGrey[900]};
  }
`;
