import { useEffect } from "react";

type ModalProps = {
  onClose: () => void;
  portalId: string;
  customStyle?: string;
};

const useModalProps = ({ onClose, portalId, customStyle }: ModalProps) => {
  let domEl = document.getElementById(portalId);

  if (!domEl) {
    domEl = document.createElement("div");
    domEl.setAttribute("id", portalId);
    document.body.appendChild(domEl);
  }

  if (customStyle) {
    domEl.setAttribute("style", customStyle);
  }

  const handleEscape = (event: KeyboardEvent) => {
    if (event.keyCode === 27) onClose();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, false);
    document.body.classList.add("no-scroll");

    return () => {
      document.removeEventListener("keydown", handleEscape, false);
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return { domEl };
};

export default useModalProps;
