import colors from "@ds_themes/colors";

export const sidebarItem = {
  background: colors.yellow[500],
  text: colors.grey[50],
  hover: {
    background: colors.violet[500],
    text: colors.grey[1000]
  }
};
