import React from "react";
import { Avatar, ActionButton } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { getActiveGroupId } from "../../modules/activeGroup";
import { createURL } from "../../modules/utility";
import { __ } from "../../modules/localization";

class ModerationElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.element
    };
    this.submitted = false;
  }

  getBreadcrumb() {
    const self = this;
    let breadcrumb = "";
    switch (self.state.element.type) {
      case "post":
        breadcrumb = (
          <div className="box-breadcrumb margin-bottom-5">
            {__("type_post")}
            <a
              href={`#${createURL("post_detail", {
                group_id: getActiveGroupId(),
                post_id: self.state.element.post_id
              })}`}
              target="_blank"
              rel="noreferrer"
            >
              &#32;{__("go_to_post")}
            </a>
          </div>
        );
        break;
      case "comment":
        breadcrumb = (
          <div className="box-breadcrumb margin-bottom-5">
            {__("type_comment")}
            <a
              href={`#${createURL("post_detail", {
                group_id: getActiveGroupId(),
                post_id: self.state.element.post_id
              })}`}
              target="_blank"
              rel="noreferrer"
            >
              &#32;{__("go_to_post")}
            </a>
          </div>
        );
        break;
      case "answer":
        breadcrumb = (
          <div className="box-breadcrumb margin-bottom-5">
            {__("type_comment_reply")}
            <a
              href={`#${createURL("post_detail", {
                group_id: getActiveGroupId(),
                post_id: self.state.element.post_id
              })}`}
              target="_blank"
              rel="noreferrer"
            >
              &#32;{__("go_to_post")}
            </a>
          </div>
        );
        break;
    }
    return breadcrumb;
  }

  approveElement() {
    const self = this;
    const data = {
      id: self.state.element.id,
      type: self.state.element.type,
      accept: true,
      group_id: getActiveGroupId()
    };
    $.ajax({
      type: "POST",
      dataType: "json",
      contentType: "application/json",
      url: createURL("api_moderation_approve"),
      data: JSON.stringify(data),
      success(ret) {
        showToastSuccess({ str: __("element_approved") });
        if (typeof self.props.approveElement === "function") {
          self.props.approveElement(self.state.element.id);
        }
      },
      error(ret) {
        const errorMsg = extractErrorMessage(
          ret,
          __("Attenzione, si è verificato un errore")
        );
        showToastError({ str: errorMsg });
      }
    });
  }

  rejectElement() {
    const self = this;
    const data = {
      id: self.state.element.id,
      type: self.state.element.type,
      accept: false,
      group_id: getActiveGroupId()
    };
    $.ajax({
      type: "POST",
      dataType: "json",
      contentType: "application/json",
      url: createURL("api_moderation_reject"),
      data: JSON.stringify(data),
      success(ret) {
        showToastSuccess({ str: __("element_rejected") });
        if (typeof self.props.rejectElement === "function") {
          self.props.rejectElement(self.state.element.id);
        }
      },
      error(ret) {
        const errorMsg = extractErrorMessage(
          ret,
          __("Attenzione, si è verificato un errore")
        );
        showToastError({ str: errorMsg });
      }
    });
  }

  render() {
    const self = this;
    let resourceBox = "";
    const { whiteTheme } = this.props.theme;
    if (self.state.element.resource_count > 0) {
      resourceBox = (
        <div className="optional-info">
          <div className="number">{self.state.element.resource_count}</div>
          <div className="label-info">{__("resource")}</div>
        </div>
      );
    }
    return (
      <div className="default-box linear-box">
        <Avatar
          url={self.state.element.author_image.medium}
          size="m"
          theme={whiteTheme}
          gender={self.state.element.author_sex}
          alt=""
          rounded
        />
        <div className="container-info">
          {self.getBreadcrumb()}
          {self.state.element.text}
        </div>
        <div className="container-option">
          {resourceBox}
          <div className="action-button">
            <ActionButton
              icon="check"
              onClick={self.approveElement.bind(this)}
              theme={whiteTheme}
              variant="secondary"
            />
            <ActionButton
              icon="close"
              onClick={self.rejectElement.bind(this)}
              theme={whiteTheme}
              variant="secondary"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTheme(ModerationElement);
