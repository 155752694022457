import { useCallback, useEffect, useMemo, useState } from "react";
import { isToday, isYesterday, format, isSameDay } from "date-fns";
import { useScrollToBottom, useStores } from "src/js/hooks";
import { ObjectValues } from "src/js/types";
import { useTranslation } from "src/js/translation";
import { TabItem } from "../components/TabBar";
import { useNotificationFetcher } from "./useNotificationFetcher";

export const NotificationDrawerTabs = {
  AllGroups: "AllGroups",
  ThisGroup: "ThisGroup"
} as const;

export const AppContext = {
  Space: "Space",
  Group: "Group"
} as const;

export const useNotificationDrawer = () => {
  const { translate } = useTranslation();
  const [selectedTab, setSelectedTab] =
    useState<ObjectValues<typeof NotificationDrawerTabs>>();
  const [date, setDate] = useState("");

  const formatDate = useCallback(
    timestamp => {
      const dateObj = new Date(timestamp * 1000);
      if (isToday(dateObj))
        return translate("chat_conversation_day_pill_today_value");
      if (isYesterday(dateObj))
        return translate("chat_conversation_day_pill_yesterday_value");
      return format(dateObj, "dd/MM/yyyy");
    },
    [translate]
  );

  const diffIsMoreThanOneDay = useCallback((date1: number, date2: number) => {
    return !isSameDay(date1 * 1000, date2 * 1000);
  }, []);

  const {
    GroupStore: { activeGroup },
    SpaceGroupListStore: { activeSpaceGroups },
    NotificationStore: {
      addToNotifications,
      resetNotifications,
      setNotificationsNumber,
      setGroupNotificationCounter,
      setSpaceNotificationCounter,
      decreaseAllCounters,
      increaseAllCounters
    },
    SpaceStore: { activeSpace, spacesList }
  } = useStores();

  const { markAsRead, markAsUnread, ...query } = useNotificationFetcher({
    params: {
      spaceId:
        selectedTab === NotificationDrawerTabs.AllGroups
          ? activeSpace?.space?.id
          : undefined,
      groupId:
        selectedTab === NotificationDrawerTabs.ThisGroup
          ? activeGroup.id
          : undefined
    },
    onSuccess: addToNotifications
  });

  const onMarkRead = useCallback(
    async (notificationId: string) => {
      await markAsRead(notificationId);
      const notificationSpaceId = activeSpace?.space?.id;
      const notificationGroupId = query.notifications.find(
        n => n.id === notificationId
      )?.group?.id;
      if (notificationGroupId && notificationSpaceId)
        decreaseAllCounters({
          spaceId: activeSpace?.space?.id,
          groupId: notificationGroupId
        });
    },
    [markAsRead, query.notifications]
  );

  const onMarkUnRead = useCallback(
    async (notificationId: string) => {
      await markAsUnread(notificationId);
      const notificationSpaceId = activeSpace?.space?.id;
      const notificationGroupId = query.notifications.find(
        n => n.id === notificationId
      )?.group?.id;
      if (notificationGroupId && notificationSpaceId)
        increaseAllCounters({
          spaceId: activeSpace?.space?.id,
          groupId: notificationGroupId
        });
    },
    [markAsRead, query.notifications]
  );

  useEffect(() => {
    // Grab the first date for the header
    if (query.notifications.length > 0 && date === "")
      setDate(formatDate(query.notifications[0]?.updatedAt));
  }, [query.notifications, date]);

  const spaceId = useMemo(
    () =>
      selectedTab === NotificationDrawerTabs.AllGroups
        ? activeSpace?.space?.id
        : undefined,
    [activeSpace, selectedTab]
  );

  const groupId = useMemo(
    () =>
      selectedTab === NotificationDrawerTabs.ThisGroup
        ? activeGroup.id
        : undefined,
    [activeGroup, selectedTab]
  );

  useEffect(() => {
    resetNotifications();
  }, [spaceId, groupId]);

  const scrollableRef = useScrollToBottom<HTMLDivElement>(() => {
    if (query.hasNext && !query.isLoadingNextPage) query.fetchNext();
  });

  const activeGroupNotificationCount =
    activeSpaceGroups.find(spaceGroup => spaceGroup.group.id === activeGroup.id)
      ?.unreadNotificationsCount ?? 0;

  const activeSpaceNotificationCount =
    spacesList.find(space => space.space.id === activeSpace?.space?.id)
      ?.unreadNotificationCount ?? 0;

  const context = useMemo(() => {
    return activeGroup?.id ? AppContext.Group : AppContext.Space;
  }, [activeGroup]);

  useEffect(() => {
    setSelectedTab(
      context === AppContext.Space
        ? NotificationDrawerTabs.AllGroups
        : NotificationDrawerTabs.ThisGroup
    );
  }, [context]);

  const onChangeTab = useCallback(
    (tabItem: TabItem<ObjectValues<typeof NotificationDrawerTabs>>) => {
      setSelectedTab(tabItem.id);
    },
    []
  );

  const tabItems = useMemo<
    TabItem<ObjectValues<typeof NotificationDrawerTabs>>[]
  >(
    () =>
      context === AppContext.Group
        ? [
            {
              id: NotificationDrawerTabs.ThisGroup,
              label: translate("notification_drawer_tab_this_group"),
              count: activeGroupNotificationCount
            },
            {
              id: NotificationDrawerTabs.AllGroups,
              label: translate("notification_drawer_tab_all_groups"),
              count: activeSpaceNotificationCount
            }
          ]
        : [
            {
              id: NotificationDrawerTabs.AllGroups,
              label: translate("notification_drawer_tab_all_groups"),
              count: activeSpaceNotificationCount
            }
          ],
    [translate, context]
  );

  const markAllNotificationsAsRead = useCallback(() => {
    if (selectedTab === NotificationDrawerTabs.ThisGroup) {
      const newSpaceNotificationCount =
        activeSpaceNotificationCount - activeGroupNotificationCount;
      query.markGroupAsRead(activeGroup.id).then(() => {
        setNotificationsNumber(newSpaceNotificationCount);
        setSpaceNotificationCounter({
          spaceId: activeSpace?.space?.id,
          count: newSpaceNotificationCount
        });
        setGroupNotificationCounter({ groupId: activeGroup.id, count: 0 });
      });
    } else {
      query.markSpaceAsRead(activeSpace?.space?.id).then(() => {
        activeSpaceGroups.forEach(spaceGroup => {
          setGroupNotificationCounter({
            groupId: spaceGroup.group.id,
            count: 0
          });
        });
        setNotificationsNumber(0);
        setSpaceNotificationCounter({
          spaceId: activeSpace?.space?.id,
          count: 0
        });
      });
    }
  }, [
    activeGroupNotificationCount,
    activeSpaceNotificationCount,
    activeSpaceGroups,
    selectedTab,
    query.markGroupAsRead,
    query.markSpaceAsRead,
    activeSpace,
    activeGroup
  ]);

  return {
    onChangeTab,
    selectedTab,
    tabItems,
    spaceId,
    groupId,
    scrollableRef,
    formatDate,
    setDate,
    diffIsMoreThanOneDay,
    date,
    onMarkRead,
    onMarkUnRead,
    markAllNotificationsAsRead,
    ...query
  };
};
