import {
  COMPANY_ROLE,
  COMPANY_SIZE,
  WESCHOOL_USE_CASE
} from "src/js/types/models/MarketingData";

const __MARKETING_DATA_COMPANY_NAME_LIMIT__ = 30;

export const __FORM_MARKETING_DATA_SECOND_STEP__ = {
  companyName: {
    required: "marketing_data_form_company_name_required_error",
    maxLength: {
      value: __MARKETING_DATA_COMPANY_NAME_LIMIT__,
      message: "marketing_data_form_company_name_limit_exceeded"
    }
  },
  companySize: {
    required: "marketing_data_form_company_size_required_error"
  },
  companyRole: {
    required: "marketing_data_form_company_role_required_error"
  },
  weschoolUseCase: {
    required: "marketing_data_form_company_use_case_required_error"
  },
  phoneNumber: {
    required: "marketing_data_form_phone_number_required_error",
    pattern: {
      value: /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      message: "phone_number_validation_error"
    }
  }
};

export const companySizeOptions = () => {
  const optionsList = [];
  COMPANY_SIZE.forEach(option => {
    const { value, label } = option;
    optionsList.push({ value, label });
  });

  return optionsList;
};

export const companyRoleOptions = () => {
  const optionsList = [];
  COMPANY_ROLE.forEach(option => {
    const { value, label } = option;
    optionsList.push({ value, label });
  });

  return optionsList;
};

export const weschoolUseCaseOptions = () => {
  const optionsList = [];
  WESCHOOL_USE_CASE.forEach(option => {
    const { value, label } = option;
    optionsList.push({ value, label });
  });

  return optionsList;
};
