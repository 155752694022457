import React from "react";
import Backbone from "@weschooleng/backbone";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import each from "lodash/each";
import find from "lodash/find";
import reject from "lodash/reject";
import shuffle from "lodash/shuffle";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import maxBy from "lodash/maxBy";
import map from "lodash/map";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import { __IMG_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { fetchResource } from "src/js/repository/resourceRepository";
import { __QUIZ_MAX_POINTS__ } from "src/js/settings/settingsExercise";
import { showToastError } from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  Box,
  Button,
  ContextualError,
  Icon,
  Heading,
  Text,
  colors
} from "@arcadia/design-system";
import styled, { withTheme } from "styled-components";
import Dropdown from "../../../components/dropdown";
import ImagePreviewer from "../../../components/imagePreviewer";
import ResourceModel from "../../../models/resourceModel";
import { __ } from "../../../modules/localization";
import QuizLabel from "./QuizLabel";
import ExerciseResourceUploader from "../engine/ExerciseResourceUploader";

const MaxErrorsBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  input[type="text"] {
    max-width: 72px !important;
    padding-left: 36px !important;
    width: 100%;
  }
`;

const ResourceWrapper = styled.div`
  display: flex;
  max-width: 200px;
  width: 100%;
  align-items: center;
  gap: 16px;
  padding: 8px;
  border: 1px solid ${colors.grey[700]};
  border-radius: 8px;
`;

const OptionsWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  align-items: center;
  gap: 16px;
  display: flex;

  > span {
    min-width: 64px;
  }

  input[type="text"] {
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const QuizBuilderMatching = withBackbone(
  createReactClass({
    form: "#form-quiz-builder-matching",
    getDefaultProps() {
      return {
        fading: false
      };
    },
    getInitialState() {
      const { quiz } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();
      let maxErrors = 3;

      if (quiz.has("max_attempts") && this.props.fading) {
        maxErrors = quiz.getMaxAttempts();
      }
      return {
        status: "",
        title: quiz.get("title"),
        available_points: quiz.getAvailablePoints(),
        maxErrors,
        questions,
        solutions,
        listEditing: false,
        mode: "insert",
        editQuestion1: {},
        editQuestion2: {},
        refQuestion: "",
        resourceQuestion1: {},
        resourceQuestion2: {},
        errorMessage: null
      };
    },
    componentDidMount() {
      $(this.form).on("submit", e => {
        e.preventDefault();
      });

      this.initValidation();
    },
    componentDidUpdate() {
      this.initValidation();
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(true);
    },
    updateBP() {
      this.forceUpdate();
    },
    submitForm() {
      $(this.form).trigger("submit");
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        borderColorOnError: "",
        onError() {
          self.setState({
            errorMessage: "Attenzione, verificare i dati inseriti"
          });
        },
        onSuccess() {
          self.addQuiz();
          return false;
        }
      });
    },
    addQuiz() {
      const self = this;
      this.setState({ status: "loading" });
      const { quiz } = this.props;
      let { solutions } = this.state;
      let { questions } = this.state;

      const column1 = filter(questions, { column: 1 }); // domande, nella colonna di sx
      let column2 = filter(questions, { column: 2 }); // "risposte", nella colonna di dx

      const max_attempts = parseInt(this.state.maxErrors, 10) + column1.length; // numero errori + numeri coppie = numero tentativi

      column2 = shuffle(column2); // riordino colonna due
      questions = column1.concat(column2);

      questions = map(questions, (item, newIndex) => {
        // aggiorno index ma mantengo riferimento a quello vecchio (serve per ricreare solutions)
        item.oldIndex = item.index;
        item.index = newIndex;
        return item;
      });

      solutions = map(solutions, s => {
        const rel_question = find(questions, { oldIndex: s.index_1 });
        const rel_solution = find(questions, { oldIndex: s.index_2 });
        s.index_1 = rel_question.index;
        s.index_2 = rel_solution.index;
        return s;
      });

      if (questions.length < 4 || solutions.length < 2) {
        self.setState({
          errorMessage: "Attenzione, inserire almeno due coppie di elementi"
        });
        this.setState({ status: "" });
      } else if (questions.length > 70 || solutions.length > 35) {
        self.setState({
          errorMessage: "Non è possibile inserire più di 35 coppie"
        });
        this.setState({ status: "" });
      } else if (
        self.state.available_points &&
        (self.state.available_points < 1 ||
          self.state.available_points > __QUIZ_MAX_POINTS__)
      ) {
        self.setState({ errorMessage: "quiz_vote_validation_error" });
      } else {
        if (this.props.fading !== undefined && this.props.fading === true) {
          quiz.set("type", "fading_matching");
        } else {
          quiz.set("type", "matching");
        }

        quiz.set("title", __("quiz_matching_execution_label"));
        if (!self.state.available_points) {
          quiz.set("available_points", 1);
        } else {
          quiz.set(
            "available_points",
            parseInt(self.state.available_points, 10)
          );
        }
        quiz.set("questions", questions);
        quiz.set("solutions", solutions);
        quiz.set("max_attempts", max_attempts);

        const optionalCallbackSuccess = function () {
          this.setState({ status: "" });
          this.props.backFunc();
        };
        const optionalCallbackError = function () {
          this.setState({ status: "" });
        };
        if (typeof this.props.setChanged === "function")
          this.props.setChanged(false);
        this.props.addFunc(
          quiz,
          optionalCallbackSuccess.bind(this),
          optionalCallbackError.bind(this)
        );
      }
    },
    hideResourceManager() {
      $(".main-quiz-builder").removeClass("hidden");
      $(".resource-manager-container").addClass("hidden");
    },
    addResource(resource) {
      fetchResource(resource.id)
        .then(res => {
          if (this.state.refQuestion == "q1") {
            this.setState({ resourceQuestion1: res });
          }
          if (this.state.refQuestion == "q2") {
            this.setState({ resourceQuestion2: res });
          }
        })
        .catch(data => {
          const errorMsg = extractErrorMessage(
            data,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: <Translate text={errorMsg} /> });
        });
    },
    removeResource(refQuestion) {
      // console.log(this.state);
      if (refQuestion === "q1") {
        const question1 = this.state.editQuestion1;

        question1.resource = {};

        this.setState({
          editQuestion1: {},
          resourceQuestion1: {}
        });
      } else if (refQuestion === "q2") {
        const question2 = this.state.editQuestion2;

        question2.resource = {};

        this.setState({
          editQuestion2: {},
          resourceQuestion2: {}
        });
      }
    },
    checkCouple() {
      if ($("#form-quiz-builder-matching-couples").length > 0) {
        if (
          $("#matching-couples-text-1").val() == "" &&
          isEmpty(this.state.resourceQuestion1)
        ) {
          $("#matching-couples-text-1").addClass("error");
        } else {
          $("#matching-couples-text-1").removeClass("error");
        }

        if (
          $("#matching-couples-text-2").val() == "" &&
          isEmpty(this.state.resourceQuestion2)
        ) {
          $("#matching-couples-text-2").addClass("error");
        } else {
          $("#matching-couples-text-2").removeClass("error");
        }
      }
    },
    saveCouple() {
      const self = this;
      const questions = JSON.parse(JSON.stringify(this.state.questions));
      const solutions = JSON.parse(JSON.stringify(this.state.solutions));
      let obj1 = {};
      let obj2 = {};

      let newMaxIndex_1 = 0;
      if (questions.length > 0) {
        const maxItem = maxBy(questions, q => q.index);
        newMaxIndex_1 = maxItem.index + 1;
      }

      const newMaxIndex_2 = newMaxIndex_1 + 1;

      if (isEmpty(this.state.editQuestion1)) {
        obj1 = {
          index: newMaxIndex_1,
          column: 1,
          value: $("#matching-couples-text-1").val(),
          resource: {}
        };
        questions.push(obj1);
      } else {
        obj1 = JSON.parse(JSON.stringify(this.state.editQuestion1));
        obj1.value = $("#matching-couples-text-1").val();
      }

      if (isEmpty(this.state.editQuestion2)) {
        obj2 = {
          index: newMaxIndex_2,
          column: 2,
          value: $("#matching-couples-text-2").val(),
          resource: {}
        };
        questions.push(obj2);
      } else {
        obj2 = JSON.parse(JSON.stringify(this.state.editQuestion2));
        obj2.value = $("#matching-couples-text-2").val();
      }

      if (!isEmpty(this.state.resourceQuestion1)) {
        obj1.resource = JSON.parse(
          JSON.stringify(this.state.resourceQuestion1)
        );
      }
      if (!isEmpty(this.state.resourceQuestion2)) {
        obj2.resource = JSON.parse(
          JSON.stringify(this.state.resourceQuestion2)
        );
      }

      this.checkCouple();

      if (
        (obj1.value == "" && isEmpty(obj1.resource)) ||
        (obj2.value == "" && isEmpty(obj2.resource))
      ) {
        self.setState({
          errorMessage: "Inserire testi o risorse da associare"
        });
      } else {
        let questionsUpdate = map(questions, q => {
          let current = q;
          if (current.index == obj1.index) {
            current = obj1;
          }
          return current;
        });

        questionsUpdate = map(questionsUpdate, q => {
          let current = q;
          if (current.index == obj2.index) {
            current = obj2;
          }
          return current;
        });

        if (
          isEmpty(this.state.editQuestion1) &&
          isEmpty(this.state.editQuestion2)
        ) {
          solutions.push({
            index_1: obj1.index,
            index_2: obj2.index
          });
        }

        this.setState({
          questions: questionsUpdate,
          solutions
        });

        self.toggleCreate("insert");
      }
    },
    removeCouple(index) {
      let { solutions } = this.state;
      let { questions } = this.state;

      const currentSolution = find(solutions, { index_1: index });

      questions = reject(
        questions,
        item => item.index === currentSolution.index_1
      );
      questions = reject(
        questions,
        item => item.index === currentSolution.index_2
      );

      solutions = reject(
        solutions,
        item => item.index_1 === currentSolution.index_1
      );

      this.setState({
        questions,
        solutions
      });
    },
    updateValue(index, e) {
      const { questions } = this.state;
      questions[index].value = e.target.value;
      this.setState({
        questions
      });
    },
    toggleCreate(mode) {
      const { listEditing } = this.state;
      this.setState({
        listEditing: !listEditing,
        mode
      });

      if (!listEditing) {
        this.setState({
          refQuestion: "",
          resourceQuestion1: {},
          resourceQuestion2: {}
        });
      }

      if (mode == "insert") {
        this.setState({
          editQuestion1: {},
          editQuestion2: {}
        });
      }
    },
    edit(question1, question2) {
      this.setState({
        editQuestion1: question1,
        editQuestion2: question2
      });
      this.toggleCreate("edit");
    },
    render() {
      const self = this;
      const { quiz } = self.props;
      const { questions } = self.state;
      const { solutions } = self.state;
      let couplesContent = "";
      let couplesButtons = "";
      let saveButton = "";
      let maxErrorsBox = "";
      let optionsBox = "";
      const { whiteTheme } = this.props.theme;
      const { errorMessage } = this.state;

      const column1 = filter(questions, { column: 1 }); // domande, nella colonna di sx
      const column2 = filter(questions, { column: 2 }); // "risposte", nella colonna di dx

      const couples = [];
      if (this.props.fading) {
        maxErrorsBox = (
          <MaxErrorsBoxWrapper>
            <Text type="body">{__("Tolleranza")}</Text>
            <input
              type="text"
              className="form-control primary  pull-left"
              value={self.state.maxErrors}
              onChange={e =>
                this.setState({ maxErrors: e.target.value, errorMessage: null })
              }
              data-validation="number"
              data-validation-allowing="range[0;100]"
              data-validation-error-msg={__("Inserire un numero (massimo 100)")}
            />
          </MaxErrorsBoxWrapper>
        );
      }

      each(column1, (q, index) => {
        const matcher = find(solutions, { index_1: q.index });
        const solution = find(column2, { index: matcher.index_2 });

        let elementBox = "";
        let col1Box = "";
        let col2Box = "";
        let col1Text = "";
        let col2Text = "";
        let col1Resource = "";
        let col2Resource = "";
        const optionBox = "";
        const dropdownOptions = [];

        if (q !== undefined && q.value != "") {
          col1Text = (
            <div className="quiz-builder-couples-text pull-left">{q.value}</div>
          );
        }

        if (q.resource !== undefined && q.resource.hasOwnProperty("id")) {
          let resMod;
          if (q.resource instanceof Backbone.Model) {
            resMod = q.resource;
          } else {
            resMod = new ResourceModel(q.resource);
          }
          var backgroundImage = resMod.get("preview") || __IMG_PLACEHOLDER__;
          var is_preview_generated = true;
          var preview_available_generator = resMod.get(
            "preview_available_generator"
          );
          var preview_date_created = resMod.get("created") * 1000;
          var placeholder = getPlaceholder(resMod.attributes || resMod);
          var background = resMod.getBackground();

          if (col1Text == "") {
            col1Text = (
              <div className="quiz-builder-couples-text pull-left">
                {q.resource.name}
              </div>
            );
          }

          col1Resource = (
            <ImagePreviewer
              className="quiz-builder-couples-resource-preview pull-right text-right"
              src={backgroundImage}
              is_preview_generated={is_preview_generated}
              preview_available_generator={preview_available_generator}
              placeholder={placeholder}
              background={background}
              created={preview_date_created}
              type="DIV"
            />
          );
        }

        col1Box = (
          <div className="quiz-builder-couples-col1 pull-left-not-xs clearfix ">
            {col1Text}
            {col1Resource}
          </div>
        );

        if (solution !== undefined && solution.value != "") {
          col2Text = (
            <div className="quiz-builder-couples-text">{solution.value}</div>
          );
        }

        if (
          solution !== undefined &&
          solution.resource !== undefined &&
          solution.resource.hasOwnProperty("id")
        ) {
          let resMod;
          if (solution.resource instanceof Backbone.Model) {
            resMod = solution.resource;
          } else {
            resMod = new ResourceModel(solution.resource);
          }
          var backgroundImage = resMod.get("public_url") || __IMG_PLACEHOLDER__;
          var is_preview_generated = true;
          var preview_available_generator = resMod.get(
            "preview_available_generator"
          );
          var preview_date_created = resMod.get("created") * 1000;
          var placeholder = getPlaceholder(resMod.attributes || resMod);
          var background = resMod.getBackground();

          if (col2Text == "") {
            col2Text = (
              <div className="quiz-builder-couples-text">
                {solution.resource.name}
              </div>
            );
          }

          col2Resource = (
            <ImagePreviewer
              className="quiz-builder-couples-resource-preview pull-right text-right"
              src={backgroundImage}
              is_preview_generated={is_preview_generated}
              preview_available_generator={preview_available_generator}
              placeholder={placeholder}
              background={background}
              created={preview_date_created}
              type="DIV"
            />
          );
        }

        col2Box = (
          <div className="quiz-builder-couples-col2 pull-left-not-xs clearfix ">
            {col2Text}
            {col2Resource}
          </div>
        );

        dropdownOptions.push(
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              onClick={self.edit.bind(null, q, solution)}
            >
              {__("modifica")}
            </a>
          </li>
        );
        dropdownOptions.push(
          <li role="presentation">
            <a
              role="menuitem"
              tabIndex="-1"
              onClick={self.removeCouple.bind(null, q.index)}
            >
              {__("elimina")}
            </a>
          </li>
        );

        optionsBox = (
          <div className="quiz-builder-couples-element-options dropdown col-xs-2 col-sm-1 col-lg-1 text-center">
            <a
              className="quiz-builder-couples-element-options-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="quiz-builder-couples-element-options-icon">
                <Icon icon="kebabVertical" width={20} height={20} />
              </span>
            </a>
            <Dropdown items={dropdownOptions} />
          </div>
        );

        elementBox = (
          <Box display="flex" alignItems="center" marginTop="16px">
            <Box display="flex" width="100%" gap="16px" flexWrap="wrap">
              {col1Box}
              {col2Box}
            </Box>
            {optionsBox}
          </Box>
        );

        if (
          q != undefined &&
          (q.value != "" || !isEmpty(q.resource)) &&
          solution != undefined &&
          (solution.value != "" || !isEmpty(solution.resource))
        ) {
          couples.push(elementBox);
        }
      });

      if (this.state.listEditing) {
        let textQuestion1 = "";
        let textQuestion2 = "";
        let previewQuestion1 = "";
        let previewQuestion2 = "";
        let captionQuestion1 = "";
        let captionQuestion2 = "";

        const resUploader1 = (
          <ExerciseResourceUploader
            addResource={res => {
              this.setState({ refQuestion: "q1" });
              this.addResource(res);
            }}
          />
        );

        const resUploader2 = (
          <ExerciseResourceUploader
            addResource={res => {
              this.setState({ refQuestion: "q2" });
              this.addResource(res);
            }}
          />
        );
        let couplesResource1 = <Box minWidth="fit-content">{resUploader1}</Box>;
        let couplesResource2 = <Box minWidth="fit-content">{resUploader2}</Box>;

        if (self.props.fading) {
          couplesResource1 = "";
          couplesResource2 = "";
        }

        if (!isEmpty(this.state.editQuestion1)) {
          textQuestion1 = this.state.editQuestion1.value;

          if (!isEmpty(self.state.editQuestion1.resource)) {
            previewQuestion1 = self.state.editQuestion1.resource.preview;
            captionQuestion1 = self.state.editQuestion1.resource.name;
            let resMod;
            if (self.state.editQuestion1.resource instanceof Backbone.Model) {
              resMod = self.state.editQuestion1.resource;
            } else {
              resMod = new ResourceModel(self.state.editQuestion1.resource);
            }
            var backgroundImage =
              resMod.get("public_url") || __IMG_PLACEHOLDER__;
            var is_preview_generated = true;
            var preview_available_generator = resMod.get(
              "preview_available_generator"
            );
            var preview_date_created = resMod.get("created") * 1000;
            var placeholder = getPlaceholder(resMod.attributes || resMod);
            var background = resMod.getBackground();
          }
        }

        if (!isEmpty(this.state.editQuestion2)) {
          textQuestion2 = this.state.editQuestion2.value;

          if (!isEmpty(self.state.editQuestion2.resource)) {
            previewQuestion2 = self.state.editQuestion2.resource.preview;
            captionQuestion2 = self.state.editQuestion2.resource.name;
            let resMod2;
            if (self.state.editQuestion2.resource instanceof Backbone.Model) {
              resMod2 = self.state.editQuestion2.resource;
            } else {
              resMod2 = new ResourceModel(self.state.editQuestion2.resource);
            }
            var backgroundImage2 =
              resMod2.get("public_url") || __IMG_PLACEHOLDER__;
            var is_preview_generated2 = true;
            var preview_available_generator2 = resMod2.get(
              "preview_available_generator"
            );
            var preview_date_created2 = resMod2.get("created") * 1000;
            var placeholder2 = getPlaceholder(resMod2.attributes || resMod2);
            var background2 = resMod2.getBackground();
          }
        }

        if (!isEmpty(self.state.resourceQuestion1)) {
          previewQuestion1 = self.state.resourceQuestion1.preview;
          captionQuestion1 = self.state.resourceQuestion1.name;
          let resMod;
          if (self.state.resourceQuestion1 instanceof Backbone.Model) {
            resMod = self.state.resourceQuestion1;
          } else {
            resMod = new ResourceModel(self.state.resourceQuestion1);
          }
          var backgroundImage = resMod.get("public_url") || __IMG_PLACEHOLDER__;
          var is_preview_generated = true;
          var preview_available_generator = resMod.get(
            "preview_available_generator"
          );
          var preview_date_created = resMod.get("created") * 1000;
          var placeholder = getPlaceholder(resMod.attributes || resMod);
          var background = resMod.getBackground();
        }

        if (!isEmpty(self.state.resourceQuestion2)) {
          previewQuestion2 = self.state.resourceQuestion2.preview;
          captionQuestion2 = self.state.resourceQuestion2.name;
          let resMod2;
          if (self.state.resourceQuestion2 instanceof Backbone.Model) {
            resMod2 = self.state.resourceQuestion2;
          } else {
            resMod2 = new ResourceModel(self.state.resourceQuestion2);
          }
          var backgroundImage2 =
            resMod2.get("public_url") || __IMG_PLACEHOLDER__;
          var is_preview_generated2 = true;
          var preview_available_generator2 = resMod2.get(
            "preview_available_generator"
          );
          var preview_date_created2 = resMod2.get("created") * 1000;
          var placeholder2 = getPlaceholder(resMod2.attributes || resMod2);
          var background2 = resMod2.getBackground();
        }

        if (previewQuestion1 != "") {
          couplesResource1 = (
            <>
              <ResourceWrapper>
                <ImagePreviewer
                  className="matching-couples-resource-img"
                  src={backgroundImage}
                  is_preview_generated={is_preview_generated}
                  preview_available_generator={preview_available_generator}
                  placeholder={placeholder}
                  background={background}
                  created={preview_date_created}
                  type="DIV"
                />
                <Box
                  flex="1"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  maxHeight="44px"
                >
                  {captionQuestion1}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  onClick={self.removeResource.bind(null, "q1")}
                >
                  <Icon icon="close" height={16} width={16} />
                </Box>
              </ResourceWrapper>
              {resUploader1}
            </>
          );
        }

        if (previewQuestion2 != "") {
          couplesResource2 = (
            <>
              <ResourceWrapper>
                <ImagePreviewer
                  className="matching-couples-resource-img col-xs-2 col-sm-2 col-lg-2"
                  src={backgroundImage2}
                  is_preview_generated={is_preview_generated2}
                  preview_available_generator={preview_available_generator2}
                  placeholder={placeholder2}
                  background={background2}
                  created={preview_date_created2}
                  type="DIV"
                />
                <Box
                  flex="1"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  maxHeight="44px"
                >
                  {captionQuestion2}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  onClick={self.removeResource.bind(null, "q2")}
                >
                  <Icon icon="close" height={16} width={16} />
                </Box>
              </ResourceWrapper>
              {resUploader2}
            </>
          );
        }

        let firstElementLabel = __("quiz_matching_first_column_label");
        let secondElementLabel = __("quiz_matching_second_column_label");

        if (self.props.fading) {
          firstElementLabel = __("quiz_fadingmatches_first_column_label");
          secondElementLabel = __("quiz_fadingmatches_second_column_label");
        }

        couplesContent = (
          <div>
            <form
              id="form-quiz-builder-matching-couples"
              role="form"
              method="post"
            >
              <OptionsWrapper>
                <Text type="body">{firstElementLabel}</Text>
                <input
                  type="text"
                  className="form-control matching-couples-input-text"
                  id="matching-couples-text-1"
                  name="matching-couples-text-1"
                  placeholder={__("quiz_text_placeholder")}
                  defaultValue={textQuestion1}
                />
                {couplesResource1}
              </OptionsWrapper>
              <OptionsWrapper>
                <Text type="body">{secondElementLabel}</Text>
                <input
                  type="text"
                  className="form-control matching-couples-input-text"
                  id="matching-couples-text-2"
                  name="matching-couples-text-2"
                  placeholder={__("quiz_text_placeholder")}
                  defaultValue={textQuestion2}
                />
                {couplesResource2}
              </OptionsWrapper>
            </form>
          </div>
        );
        couplesButtons = (
          <Box marginTop={16} display="flex" gap={16} justifyContent="flex-end">
            <Button
              onClick={self.toggleCreate.bind(null, "insert")}
              theme={whiteTheme}
              variant="secondary"
            >
              {__("Annulla")}
            </Button>
            <Button
              onClick={self.saveCouple}
              theme={whiteTheme}
              variant="primary"
            >
              {__("Conferma")}
            </Button>
          </Box>
        );
      } else {
        couplesContent = (
          <div className="quiz-builder-couples-list">{couples}</div>
        );
        couplesButtons = (
          <Box marginTop={16}>
            <Button
              onClick={self.toggleCreate.bind(null, "insert")}
              theme={whiteTheme}
              variant="secondary"
            >
              {__("AGGIUNGI")}
            </Button>
          </Box>
        );
        saveButton = (
          <Button
            onClick={this.submitForm}
            theme={whiteTheme}
            variant="primary"
          >
            {__("save")}
          </Button>
        );
      }
      return (
        <div>
          <div className="quiz-builder-matching-wrapper">
            <form
              id="form-quiz-builder-matching"
              className="form-quiz-builder"
              role="form"
              method="post"
            >
              {maxErrorsBox}
            </form>
            <Box marginTop={this.props.fading ? "20px" : "undefined"}>
              <Box>
                <Heading level="5">
                  {this.props.fading
                    ? __("quiz_fadingmatches_elements_label")
                    : __("quiz_matching_elements_label")}
                </Heading>
              </Box>
              <Box>{couplesContent}</Box>
              <Box>{couplesButtons}</Box>
            </Box>
            <div>
              <QuizLabel text="quiz_creation_vote_label" />

              <div className="point-section">
                <div className="point-input">
                  <input
                    type="number"
                    id="quiz_available_points"
                    className="form-control primary"
                    defaultValue={self.state.available_points}
                    placeholder={1}
                    ref="quiz_available_points"
                    onChange={e =>
                      this.setState({
                        available_points: e.target.value,
                        errorMessage: null
                      })
                    }
                  />
                  <div className="point-label">
                    {__("quiz_creation_point_label")}
                  </div>
                </div>
                <div className="submit-quiz">
                  {errorMessage && (
                    <ContextualError
                      theme={whiteTheme}
                      text={__(errorMessage)}
                    />
                  )}
                  {saveButton}
                </div>
              </div>
            </div>
          </div>
          <div className="resource-manager-container hidden" />
        </div>
      );
    }
  })
);

export default withTheme(QuizBuilderMatching);
