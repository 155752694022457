import { Button, Heading, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { LeaveSpaceModalProps } from "src/js/components/modal/LeaveSpaceModal/types";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { destroyLocalData } from "src/js/modules/storageFunction";
import { leaveSpace } from "src/js/repository/spacesRepository";
import { translateString } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { ModalLayout } from "../ModalLayout";
import * as S from "./LeaveSpaceModal.styles";

const LeaveSpaceContent = ({ closeModal }: LeaveSpaceModalProps) => {
  const { whiteTheme } = useTheme();
  const {
    SpaceStore: {
      activeSpaceId,
      removeSpaceFromSpaceList,
      cleanLastActiveSpaceLocalStorage
    },
    SpaceGroupListStore: { reset }
  } = useStores();

  return (
    <>
      <S.IllustrationContainer>
        <Icon
          icon="illustration-logout"
          height={150}
          width={150}
          viewbox="0 0 150 150"
        />
      </S.IllustrationContainer>
      <Heading level="3">
        {translateString({ text: "leave_space_modal_text" })}
      </Heading>
      <S.ModalButtonsContainer>
        <Button
          variant="primary"
          theme={whiteTheme}
          fullWidth
          onClick={() => {
            leaveSpace(activeSpaceId).then(() => {
              removeSpaceFromSpaceList(activeSpaceId);
              destroyLocalData("space_generation_state");
              cleanLastActiveSpaceLocalStorage();
              reset();
              closeModal();
              navigateTo(createUrl("home"));
            });
          }}
        >
          {translateString({ text: "leave_space_modal_action_button" })}
        </Button>
        <S.LinkBtn onClick={closeModal}>
          <Text type="textLink">
            {translateString({
              text: "leave_space_modal_cancel_button"
            })}
          </Text>
        </S.LinkBtn>
      </S.ModalButtonsContainer>
    </>
  );
};

const LeaveSpaceModal: FC<LeaveSpaceModalProps> = ({ closeModal }) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  return (
    <ModalLayout
      mobileProps={{ withAdaptiveHeight: true, withHeaderLabels: false }}
      labels={{
        title: translateString({ text: "leave_space_modal_title" })
      }}
      closeModal={closeModal}
    >
      {isLayoutModeMobile ? (
        <S.BottomSheetContent>
          <LeaveSpaceContent closeModal={closeModal} />
        </S.BottomSheetContent>
      ) : (
        <S.ModalContent>
          <LeaveSpaceContent closeModal={closeModal} />
        </S.ModalContent>
      )}
    </ModalLayout>
  );
};

export default observer(LeaveSpaceModal);
