import React from "react";
import { BottomModalSheet, Box, Icon } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import SpaceCreationModal from "src/js/components/SpaceCreation/SpaceCreationModal/SpaceCreationModal";
import * as S from "./TopHeaderSpacesMobile.styles";
import { GroupCreationModal } from "../../GroupCreationModal";
import { GroupJoinWithCodeModal } from "../../GroupJoinWithCodeModal";

const CreateJoinSpaceOrGroup = ({
  isOpen,
  createGroup,
  setOpen
}: {
  isOpen: boolean;
  createGroup: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const {
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { translate } = useTranslation();

  const openGroupJoinWithCodeModal = () => {
    openModal(() => <GroupJoinWithCodeModal closeModal={closeModal} />);
  };

  const handleCreate = () => {
    setOpen(false);
    openModal(() =>
      createGroup ? <GroupCreationModal /> : <SpaceCreationModal />
    );
  };

  return (
    <BottomModalSheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title=""
      withHeaderLabels={false}
      withAdaptiveHeight
    >
      <S.ModuleButton firstButton onClick={handleCreate}>
        <Box display="flex" alignItems="center">
          <Icon
            icon={createGroup ? "plusInBox" : "plusInCircle"}
            height={24}
            width={24}
          />
          <S.ModalSheetItemTitle type="formField">
            {translate(
              createGroup
                ? "bottom_modal_sheet_create_group_option"
                : "tooltip_menu_option_create_space"
            )}
          </S.ModalSheetItemTitle>
        </Box>
        <Icon icon="plusThin" width={16} height={16} />
      </S.ModuleButton>
      <S.ModuleButton
        onClick={() => {
          setOpen(false);
          openGroupJoinWithCodeModal();
        }}
      >
        <Box display="flex" alignItems="center">
          <Icon icon="joinInBox" height={24} width={24} />
          <S.ModalSheetItemTitle type="formField">
            {translate("tooltip_menu_option_join_group_with_code")}
          </S.ModalSheetItemTitle>
        </Box>
        <Icon icon="plusThin" width={16} height={16} />
      </S.ModuleButton>
    </BottomModalSheet>
  );
};

export default CreateJoinSpaceOrGroup;
