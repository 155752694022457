import React, { useRef } from "react";
import moment from "moment";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { Box, Button, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import UserCommentCard from "src/js/components/UserCommentCard/UserCommentCard";
import { TextArea, TEXTAREA_VARIANT } from "../forms/TextArea";
import { ToggleCommentSidebar } from "../ToggleCommentSidebar";
import * as S from "./GeneralCommentSidebar.styles";
import { PersistentDrawer } from "../PersistentDrawer";

const GeneralCommentSidebar = ({ isOpen, onClose }) => {
  const {
    ReportStore,
    UserStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const textAreaRef = useRef();

  const executionId = ReportStore.activeReportId;

  const drawerFooter = (
    <div>
      <TextArea
        placeholder={translate("report_add_final_comment_placeholder")}
        variant={TEXTAREA_VARIANT.LIGHT}
        marginBottom={20}
        ref={textAreaRef}
      />
      <Button
        onClick={() => {
          if (textAreaRef.current.value !== "") {
            ReportStore.addComment({
              executionId,
              commentText: textAreaRef.current.value
            });
            textAreaRef.current.value = "";
          }
        }}
        variant="primary"
        theme={whiteTheme}
        fullWidth={getBreakpoint() === "smartphone"}
      >
        {translate("publish_comment_cta_label")}
      </Button>
    </div>
  );

  const commentList = ReportStore.commentList.map(comment => {
    // (formattedDate) => 10/09/2021 14:40
    // (dateFromNow) => 1 ora fa
    // locale is correctly aligned with users preferences
    const formattedDate = moment(comment.created_at).format("DD/MM/YYYY HH:mm");
    const dateFromNow = moment(comment.created_at).fromNow();

    return (
      <S.CommentContainer key={comment.comment_id}>
        <UserCommentCard
          avatar={comment.avatar?.small}
          username={`${comment.name} ${comment.surname}`}
          date={`${formattedDate} (${dateFromNow})`}
          message={comment.comment_text}
          editable={comment.user_id === UserStore.activeUser.id}
          onEditConfirm={text => {
            ReportStore.editComment({
              executionId,
              commentId: comment.comment_id,
              commentText: text
            });
          }}
        />
      </S.CommentContainer>
    );
  });

  const drawerHeaderDesktop = (
    <Box
      width="100%"
      justifyContent="space-between"
      display="flex"
      alignItems="center"
    >
      <Text type="labelWide">
        {translate("report_final_comment_sidebar_title")}
      </Text>
      <Box>
        <ToggleCommentSidebar
          numberOfComment={ReportStore.numberOfComments}
          isOpen={isOpen}
          onClick={onClose}
        />
      </Box>
    </Box>
  );

  const drawerHeaderMobile = (
    <Box position="relative" width="100%">
      <Box onClick={onClose} cursor="pointer">
        <S.MobileHeaderCancelCta type="headerTitleLg">
          {translate("report_final_comment_sidebar_mobile_cancel_cta")}
        </S.MobileHeaderCancelCta>
      </Box>
      <S.MobileHeaderTitle type="headerTitleLg">
        {translate("report_final_comment_sidebar_mobile_header_title")}
      </S.MobileHeaderTitle>
    </Box>
  );

  return (
    <PersistentDrawer
      header={isLayoutModeMobile ? drawerHeaderMobile : drawerHeaderDesktop}
      isOpen={isOpen}
      footer={drawerFooter}
    >
      {commentList}
    </PersistentDrawer>
  );
};

export default observer(GeneralCommentSidebar);
