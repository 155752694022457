import { AvatarWithInitials, Box, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC, useEffect } from "react";
import { BannerCta } from "src/js/components/BannerCta";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { openExternalLink } from "src/js/modules/commonFunction";
import { __ENTERPRISE_PLAN_URL__ } from "src/js/settings/settingsUrl";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { PlanType, SubscriptionStatusType } from "src/js/types";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  chargebeeLogout,
  chargebeeSetupPortal
} from "../ChargebeeUtils/ChargebeeUtils";
import { CreatePlanForm } from "./CreatePlanForm";
import { ManagePlan } from "./ManagePlan";
import * as S from "./SpacesSettingsPlan.styles";
import { SpacesSettingsPlanProps } from "./types";

const SpacesSettingsPlan: FC<SpacesSettingsPlanProps> = ({
  tabButtons,
  planSettings,
  space
}) => {
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { subscriptionStatus, planType } = planSettings || {};
  const showEnterpriseBanner =
    planSettings && planSettings?.planType !== PlanType.ENTERPRISE;

  useEffect(() => {
    chargebeeSetupPortal({ activeSpaceId: space?.id });
    return () => {
      chargebeeLogout();
    };
  }, [space]);

  useEffect(() => {
    _trackEvent("SpaceSettingsUsage", "PlanUserAccess");
  }, []);

  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
      <MainContainer paddingTop="10px" paddingTopMobile="72px">
        <S.AvatarWrapper>
          <AvatarWithInitials
            theme={whiteTheme}
            alt={space?.name}
            url={space?.badge?.small as string | undefined}
            name={space?.name || ""}
            avatarColor={space?.defaultBadgeColor}
            size="big"
          />
        </S.AvatarWrapper>
        {tabButtons}
        <S.TitleSection>
          <S.SectionHeading level="4">
            {translate({ text: "space_settings_plan_title" })}
          </S.SectionHeading>
          <Text type="body">
            {translate({
              text: "space_settings_plan_description"
            })}
          </Text>
        </S.TitleSection>
        {subscriptionStatus || planType === PlanType.FREE ? (
          <S.ContentBox>
            {subscriptionStatus === SubscriptionStatusType.Active ||
            planType === PlanType.FREE ? (
              <ManagePlan />
            ) : (
              <CreatePlanForm />
            )}
          </S.ContentBox>
        ) : null}
        {showEnterpriseBanner ? (
          <Box marginTop="12px" width="100%">
            <BannerCta
              ctaText={translate({
                text: "space_settings_plan_enterprise_banner_cta"
              })}
              ctaOnClick={() => openExternalLink(__ENTERPRISE_PLAN_URL__)}
            >
              <S.BannerHeading level={isLayoutModeMobile ? "5" : "6"}>
                {translate({
                  text: "space_settings_plan_enterprise_banner"
                })}
              </S.BannerHeading>
            </BannerCta>
          </Box>
        ) : null}
      </MainContainer>
    </Box>
  );
};

export default observer(SpacesSettingsPlan);
