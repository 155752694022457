import Backbone from "backbone";
import { createURL } from "../modules/utility";
import InstantModel from "../models/instantModel";

const InstantCollection = Backbone.Collection.extend({
  model: InstantModel,
  url: createURL("api_exercises_instant_list"),
  filterByGroup(groupId) {
    const filtered = this.filter(
      instant => instant.get("group").id === groupId
    );
    return new InstantCollection(filtered);
  },
  filterById(instantId) {
    const filtered = this.filter(instant => instant.get("id") === instantId);
    return new InstantCollection(filtered);
  }
});

export const instantList = new InstantCollection();
