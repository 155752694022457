/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import {
  changeUserRole,
  removeUser,
  restoreUser
} from "src/js/repository/groupRepository";
import { fetchSpaceUserRoles } from "src/js/repository/dashboardRepository";
import { __SPACE_USERS__ROLES_LIST_LIMIT__ } from "src/js/settings/settingsPagination";

class SpacesUsersRolesStore {
  $limit = __SPACE_USERS__ROLES_LIST_LIMIT__;
  $offset = 0;
  $selectedUser = {};
  $userRoles = [];
  $totalUserRoles = 0;
  $errorGroupsId = [];
  $hasRoleError = false;
  $hasRemoveError = false;
  $hasChanges = false;

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
  }

  // actions
  reset = () => {
    this.$limit = __SPACE_USERS__ROLES_LIST_LIMIT__;
    this.$offset = 0;
    this.$selectedUser = {};
    this.$userRoles = [];
    this.$totalUserRoles = 0;
    this.$errorGroupsId = [];
    this.$hasRoleError = false;
    this.$hasRemoveError = false;
    this.$hasChanges = false;
  };

  setSelectedUser = user => {
    this.$selectedUser = user;
  };

  setUserRoles = roles => {
    this.$userRoles = roles;
  };

  setOffset = value => {
    this.$offset = value;
  };

  setTotalUserRoles = value => {
    this.$totalUserRoles = value;
  };

  setHasRoleError = value => {
    this.$hasRoleError = value;
  };

  setHasRemoveError = value => {
    this.$hasRemoveError = value;
  };

  addErrorGroupsId = id => {
    this.$errorGroupsId = [...this.$errorGroupsId, id];
  };

  fetchUserRoles = () => {
    const limit = this.$limit;
    const offset = this.$offset;
    const userId = this.selectedUser?.user?.id;
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId || !userId) return Promise.resolve();
    return fetchSpaceUserRoles({
      spaceId,
      userId,
      limit,
      offset
    })
      .then(data => {
        this.setUserRoles([...this.$userRoles, ...data?.results]);
        this.setTotalUserRoles(data?.total);
      })
      .catch(error => {
        this.setUserRoles([]);
        this.setTotalUserRoles(0);
        throw error;
      });
  };

  showNextUserRoles = () => {
    if (!this.hasMore) return () => {};
    this.setOffset(this.$offset + this.$limit);
    return this.fetchUserRoles();
  };

  callChangeUserRole = ({ role, group_id }) => {
    const userId = this.selectedUser?.user?.id;
    if (!userId) return Promise.resolve();
    return changeUserRole(group_id, userId, role)
      .then(() => {
        this.$hasChanges = true;
        return Promise.resolve();
      })
      .catch(() => {
        this.addErrorGroupsId(group_id);
        this.setHasRoleError(true);
        return Promise.reject();
      });
  };

  toggleEnableUser = ({ remove, group_id }) => {
    const userId = this.selectedUser?.user?.id;
    if (!userId) return Promise.resolve();
    const userGroup = this.$userRoles.find(obj => obj.group_id === group_id);
    if (userGroup?.roles?.includes("ROLE_CREATOR")) {
      this.addErrorGroupsId(group_id);
      this.setHasRemoveError(true);
      return Promise.resolve();
    }
    if (remove) {
      return removeUser(group_id, userId)
        .then(() => {
          this.$hasChanges = true;
        })
        .catch(() => {
          this.addErrorGroupsId(group_id);
          this.setHasRemoveError(true);
        });
    }
    return restoreUser(group_id, userId).catch(error => {
      throw error;
    });
  };

  // computed
  get selectedUser() {
    return this.$selectedUser;
  }

  get userRoles() {
    return this.$userRoles;
  }

  get totalUserRoles() {
    return this.$totalUserRoles;
  }

  get limit() {
    return this.$limit;
  }

  get offset() {
    return this.$offset;
  }

  get hasMore() {
    return this.$userRoles.length < this.$totalUserRoles;
  }

  get errorGroupsId() {
    return this.$errorGroupsId;
  }

  get hasRoleError() {
    return this.$hasRoleError;
  }

  get hasRemoveError() {
    return this.$hasRemoveError;
  }

  get hasChanges() {
    return this.$hasChanges;
  }
}

export default SpacesUsersRolesStore;
