import React, { useEffect, useState } from "react";
import { Text, Icon } from "@arcadia/design-system";
import { fetchSpaceDetails } from "src/js/repository/spacesRepository";
import { SpaceDetailsModel } from "src/js/types";
import { format } from "date-fns";
import { useTranslation } from "src/js/translation";
import OpenBadgeCertificate from "./components/OpenBadgeCertificate";
import * as S from "./styles";
import { DownloadCertificateProps, OpenBadgeCertificateProps } from "./types";

const DownloadPDFCertificate = (certificateProps: DownloadCertificateProps) => {
  const { translate } = useTranslation();
  const [spaceDetails, setSpaceDetails] = useState<SpaceDetailsModel>(null);
  const { spaceId, userFullName, badgeName, awardedAt } = certificateProps;
  const getSpaceDetails = async () => {
    const res = await fetchSpaceDetails(spaceId);
    setSpaceDetails(res);
  };

  useEffect(() => {
    getSpaceDetails();
  }, []);

  const badgeCertificateProps: OpenBadgeCertificateProps = {
    ...certificateProps,
    spaceImage: spaceDetails?.space?.badge,
    spaceName: spaceDetails?.space?.name,
    spaceLayoutColour: spaceDetails?.customizationSettings?.layoutColor
  };

  const fileName = `${userFullName} - ${badgeName} ${format(
    new Date(awardedAt),
    "yyyy-MM-dd"
  )}`;

  return (
    <S.DownloadPDF
      document={<OpenBadgeCertificate {...badgeCertificateProps} />}
      fileName={fileName}
      ispdfready={spaceDetails?.space ? "true" : undefined}
    >
      <S.TooltipItem>
        <Text type="textLink">
          {translate("badge_pdf_certificate_tooltip_item_title")}
        </Text>
        <Icon icon="download" width={14} height={14} initialViewbox />
      </S.TooltipItem>
    </S.DownloadPDF>
  );
};

export default DownloadPDFCertificate;
