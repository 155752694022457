import { history } from "Legacy/modules/history";
import queryString from "query-string";
import URI from "urijs";
import { __LANGUAGE_PARAMS_NAME__ } from "../settings/settingsLocalization";

const changeUrl = function changeUrl(url, params) {
  if (typeof params !== "undefined") {
    history.push({
      pathname: url,
      search: `?${params}`
    });
  } else {
    history.push({
      pathname: url
    });
  }
};

export function encodeQueryData(data) {
  const ret = [];
  Object.keys(data).forEach(item => {
    ret.push(`${encodeURIComponent(item)}=${encodeURIComponent(data[item])}`);
  });
  return ret.join("&");
}

export const isRegisterUrl = function isRegisterUrl(url) {
  return url.indexOf("register") >= 0;
};

export const isLoginUrl = function isLoginUrl(url) {
  return url.indexOf("login") >= 0;
};

export const isProfileUrl = function isProfileUrl(url) {
  return url.indexOf("profile") >= 0;
};

export function getBaseUrl() {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;
}

export function navigateToRedirectUrl(url, query) {
  const redirectUrl = queryString.stringifyUrl({
    url,
    query
  });
  window.open(redirectUrl, "_self");
}

export function reloadUrlWithoutParams() {
  const location = window.location.href;
  const uri = new URI(location);
  if (uri.hasQuery(__LANGUAGE_PARAMS_NAME__)) {
    uri.removeSearch(__LANGUAGE_PARAMS_NAME__);
    window.location.href = uri.normalize().toString();
  } else {
    window.location.reload();
  }
}

export { changeUrl as default };
