import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const DescriptionText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const WarningDataText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
