import { DoodleLoader, Icon, ToolbarSpaceItem } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  SchoolClaimModal,
  SpaceCreationModal
} from "src/js/components/SpaceCreation";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { __DEMO_BADGE__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { SpaceListModel, SpacePurpose, SpaceType } from "src/js/types";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { JoinDemoSpace } from "../../JoinDemoSpace";
import * as S from "./ToolbarSpaceList.styles";

const ToolbarSpaceList = () => {
  const {
    SpaceStore: {
      spacesList = [],
      setActiveSpaceById,
      activeSpace,
      fetchSpacesNextPage,
      hasMoreSpaces,
      spacesListWithNoDemo,
      isDemoSpaceEnabled,
      addNewSpaceToSpaceList,
      userSpacesListLoading
    },
    ModalStore: { openModal }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  let spaceIndex = 0;

  useEffect(() => {
    if (!activeSpace) return;
    if (userSpacesListLoading) return;
    if (spacesList.some(space => space.space.id === activeSpace.space.id)) {
      return;
    }

    const newSpace: SpaceListModel = {
      ...activeSpace,
      unreadNotificationCount: 0,
      unreadThreadMessagesCount: 0
    };
    addNewSpaceToSpaceList(newSpace);
  }, [activeSpace, spacesList, userSpacesListLoading]);

  const openSpaceCreationModal = () => {
    switch (activeSpace?.space?.purpose) {
      case SpacePurpose.Company:
        openModal(() => <SpaceCreationModal />);
        break;
      case SpacePurpose.School:
        openModal(() => <SchoolClaimModal />);
        break;
      default:
        openModal(() => <SpaceCreationModal />);
        break;
    }
  };

  const spaceList = spacesListWithNoDemo.map(item => {
    const { space } = item;
    const isActiveSpace = activeSpace?.space.slug === space.slug;

    const getCounter = () => {
      if (item.unreadNotificationCount + item.unreadThreadMessagesCount > 99)
        return "99+";
      return item.unreadNotificationCount + item.unreadThreadMessagesCount;
    };

    spaceIndex += 1;

    return (
      <S.SpaceCard
        key={`space-${space.id}`}
        onClick={() => {
          setActiveSpaceById(space.id);
          navigateTo(
            createUrl("space_groups", {
              space_slug: space.slug
            })
          );
        }}
      >
        <ToolbarSpaceItem
          id={`toolbarSpace${spaceIndex}`}
          theme={whiteTheme}
          badge={space.badge?.small as string}
          backgroundColorCode={space.defaultBadgeColor}
          spaceName={space.name}
          counter={getCounter()}
          isSelected={isActiveSpace}
          size="small"
        />
        <S.NameAndSlugContainer>
          <S.ColoredNameText type="captionAlert">
            {space.name}
          </S.ColoredNameText>
          <S.ColoredSlugText type="captionInfoDetails">
            app.weschool.com/{space.slug}
          </S.ColoredSlugText>
        </S.NameAndSlugContainer>
      </S.SpaceCard>
    );
  });

  const spaceDemo = spacesList.find(
    ({ space }) => space.spaceType === SpaceType.DEMO
  );
  const isDemoActiveSpace =
    spaceDemo && activeSpace?.space.slug === spaceDemo.space.slug;

  const hasSpaces = spacesListWithNoDemo.length > 0;
  return (
    <>
      {hasSpaces && (
        <S.ListContainer id="toolbar-spaces-list-id">
          <InfiniteScroll
            scrollableTarget="toolbar-spaces-list-id"
            dataLength={spacesList.length}
            next={() => {
              fetchSpacesNextPage();
            }}
            hasMore={hasMoreSpaces}
            loader={<DoodleLoader theme={whiteTheme} isMini />}
          >
            {spaceList}
          </InfiniteScroll>
        </S.ListContainer>
      )}
      {isDemoSpaceEnabled ? (
        <S.SpaceDemoContainer hideBorderTop={!hasSpaces}>
          {spaceDemo ? (
            <S.SpaceCard
              key={`space-${spaceDemo.space.id}`}
              onClick={() => {
                setActiveSpaceById(spaceDemo.space.id);
                navigateTo(
                  createUrl("space_groups", {
                    space_slug: spaceDemo.space.slug
                  })
                );
              }}
            >
              <ToolbarSpaceItem
                id={UsetifulId.ToolbarDemoSpaceElement}
                theme={whiteTheme}
                badge={spaceDemo.space.badge?.small as string}
                backgroundColorCode={spaceDemo.space.defaultBadgeColor}
                spaceName={spaceDemo.space.name}
                counter={0}
                isSelected={isDemoActiveSpace}
                size="small"
              />
              <S.NameAndSlugContainer>
                <S.ColoredNameText type="captionAlert">
                  {spaceDemo.space.name}
                </S.ColoredNameText>
                <S.ColoredSlugText type="captionInfoDetails">
                  {translate("toolbar_space_list_demo_space_slug")}
                </S.ColoredSlugText>
              </S.NameAndSlugContainer>
            </S.SpaceCard>
          ) : (
            <JoinDemoSpace isMobile={false} isTopBanner={false}>
              <S.SpaceCard key="createDemoSpace">
                <ToolbarSpaceItem
                  id={UsetifulId.ToolbarDemoSpaceElement}
                  theme={whiteTheme}
                  badge={__DEMO_BADGE__}
                  backgroundColorCode={1}
                  spaceName="Demo Space"
                  counter={0}
                  isSelected={isDemoActiveSpace}
                  size="small"
                />
                <S.NameAndSlugContainer>
                  <S.ColoredNameText type="captionAlert">
                    {translate("toolbar_space_list_create_demo_space_name")}
                  </S.ColoredNameText>
                  <S.ColoredSlugText type="captionInfoDetails">
                    {translate("toolbar_space_list_create_demo_space_slug")}
                  </S.ColoredSlugText>
                </S.NameAndSlugContainer>
              </S.SpaceCard>
            </JoinDemoSpace>
          )}
        </S.SpaceDemoContainer>
      ) : (
        <S.SpaceDemoContainer />
      )}

      <S.ToolbarSpaceAdd type="button" onClick={() => openSpaceCreationModal()}>
        <S.IconContainer>
          <Icon icon="plusThin" height={18} width={18} />
        </S.IconContainer>
        <S.StyledText type="ctaTertiary">
          {translate("toolbar_space_list_add_new_space_cta")}
        </S.StyledText>
      </S.ToolbarSpaceAdd>
    </>
  );
};

export default observer(ToolbarSpaceList);
