import colors from "@ds_themes/colors";

export const checkbox = {
  background: colors.coldGrey[900],
  text: colors.grey[50],
  border: `1px solid ${colors.violet[501]}`,
  checked: {
    background: colors.violet[501],
    text: colors.grey[1000]
  },
  disabled: {
    border: `1px solid ${colors.violet[700]}`,
    background: colors.violet[700],
    text: colors.violet[700]
  },
  hover: {
    border: `1.5px solid ${colors.violet[500]}`,
    background: colors.coldGrey[900]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};
