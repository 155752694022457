import React from "react";
import { Heading, Icon, Text } from "@arcadia/design-system";
import * as S from "./NoResults.styles";

type NoResultsProps = {
  title?: string;
  subtitle?: string;
  height?: number;
  width?: number;
  viewbox?: string;
};

const NoResults = ({
  title,
  subtitle,
  height,
  width,
  viewbox
}: NoResultsProps) => {
  return (
    <S.Container>
      <S.InnerContainer>
        <Icon
          icon="illustration-ghost"
          height={height || 164}
          width={width || 127}
          viewBox={viewbox || "0 0 98 130"}
        />
        {title && <Heading level="5">{title}</Heading>}
        {subtitle && <Text type="body">{subtitle}</Text>}
      </S.InnerContainer>
    </S.Container>
  );
};

export default NoResults;
