import React, { FC, forwardRef } from "react";
import { AnimatePresence } from "framer-motion";

import { PopoverProps } from "./types";
import * as S from "./Popover.styles";

const Popover: FC<PopoverProps> = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      children,
      content,
      position = "bottom",
      isVisible,
      setIsVisible,
      ...rest
    },
    ref
  ) => {
    const handleTogglePopover = () => {
      setIsVisible(visible => !visible);
    };

    const handleHidePopover: React.FocusEventHandler<HTMLDivElement> = e => {
      // This checks if the currently blurred target contains the relatedTarget (content in our case).
      // In other words it enables clicking on the popped over content, without it being dismissed.
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setIsVisible(false);
      }
    };

    const handleContentWrapperOnClick: React.MouseEventHandler<
      HTMLDivElement
    > = e => {
      // If we don't stop propagation, upon clicking on the popped over content it will be dismissed.
      e.stopPropagation();
    };

    return (
      <S.Container {...rest} ref={ref}>
        <S.ChildrenWrapper
          onClick={handleTogglePopover}
          onBlur={handleHidePopover}
          // tabIndex is needed to be set so that this element is recognized as a focusable element.
          // More details here: https://stackoverflow.com/a/42764495/5049799
          tabIndex={0}
        >
          {children}
          <AnimatePresence>
            {isVisible && (
              <S.ContentWrapper
                position={position}
                onClick={handleContentWrapperOnClick}
                initial={{
                  opacity: 0
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3
                  }
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.3
                  }
                }}
              >
                {content}
              </S.ContentWrapper>
            )}
          </AnimatePresence>
        </S.ChildrenWrapper>
      </S.Container>
    );
  }
);

export default Popover;
