import uniqueId from "lodash/uniqueId";
import React, { FC, useRef } from "react";
import { CircleDurationSvg } from "./CircleDuration.styles";
import { CircleDurationProps } from "./CircleDuration.types";

const CircleDuration: FC<CircleDurationProps> = props => {
  const { current: id } = useRef(uniqueId());
  return (
    <CircleDurationSvg
      xmlns="http://www.w3.org/2000/svg"
      width={74}
      height={74}
      viewBox="0 0 224 224"
      fill="none"
      {...props}
    >
      <defs>
        <linearGradient id={`top-${id}`}>
          <stop offset="0%" stopOpacity={0} stopColor="currentColor" />
          <stop offset="100%" stopOpacity={0.5} stopColor="currentColor" />
        </linearGradient>
        <linearGradient id={`bottom-${id}`}>
          <stop offset="0%" stopColor="currentColor" />
          <stop offset="100%" stopOpacity={0.5} stopColor="currentColor" />
        </linearGradient>
      </defs>
      <g transform="translate(12,12)" strokeWidth={24}>
        <path stroke={`url(#top-${id})`} d="M4 100a96 96 0 0 1 192 0" />
        <path stroke={`url(#bottom-${id})`} d="M196 100a96 96 0 0 1-192 0" />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M4 100a96 96 0 0 1 0-2"
        />
      </g>
    </CircleDurationSvg>
  );
};

export default CircleDuration;
