import styled from "styled-components";

import { STagProps } from "./types";

export const Tag = styled.div<STagProps>`
  height: ${({ fat }) => (fat ? "36px" : "24px")};
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ fat }) => (fat ? "12px 16px" : "6px 12px")};
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;

  &:focus {
    outline: 3px solid
      ${({ theme, type, color }) =>
        type === "outline"
          ? theme.tag.general.outline[color].text
          : theme.tag.general.solid.violet.background};
  }

  border: 1px solid
    ${({ theme, type, color }) =>
      type === "outline"
        ? theme.tag.general.outline[color].text
        : theme.tag.general.solid[color].background};
  background-color: ${({ theme, type, color }) =>
    theme.tag.general[type][color].background};
  span {
    margin-right: 8px;
    margin-left: ${({ icon }) => (icon ? "8px" : "0px")};
    color: ${({ theme, type, color }) => theme.tag.general[type][color].text};
  }
  div {
    display: flex;
  }
  svg {
    color: ${({ theme, type, color }) => theme.tag.general[type][color].text};
  }
`;
