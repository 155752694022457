import { Icon } from "@arcadia/design-system";
import styled from "styled-components";
import { SpaceSchoolBadgeVariant } from "./SpaceSchoolBadge.types";

export const BadgeIcon = styled(Icon)<{ variant: SpaceSchoolBadgeVariant }>`
  color: ${({ theme, variant }) =>
    variant === SpaceSchoolBadgeVariant.Violet
      ? theme.colors.violet[501]
      : theme.colors.yellow[500]};
  margin-left: 8px;
  flex: 0 0 16px;
`;
