import React from "react";
import {
  getAppIconFromPreference,
  getEmailIconFromPreference
} from "src/js/pages/group/settings/GroupSettingsNotice/utils";
import {
  AppPreference,
  EmailPreference
} from "src/js/types/models/Notification";
import { AppPreferenceOptions, EmailPreferenceOptions } from "../utils";
import { PreferenceDropdown } from "./PreferenceDropdown";
import {
  PreferenceDropdownMode,
  PreferenceDropdownModeValue
} from "./PreferenceDropdown/PreferenceDropdown.types";

type PreferencePickerProps = {
  emailPreference: EmailPreference;
  appPreference: AppPreference;
  openFromTop?: boolean;
  onSelectEmail: (newEmailPref: EmailPreference) => void;
  onSelectApp: (newAppPref: AppPreference) => void;
  disabled?: boolean;
  mode?: PreferenceDropdownModeValue;
};

const DesktopPreferencePicker = ({
  emailPreference,
  appPreference,
  openFromTop,
  onSelectApp,
  onSelectEmail,
  disabled = false,
  mode = PreferenceDropdownMode.Radio
}: PreferencePickerProps) => {
  return (
    <>
      <PreferenceDropdown
        options={EmailPreferenceOptions}
        selectedOptionId={emailPreference}
        openTopPopover={openFromTop}
        setSelectedOptionId={onSelectEmail}
        disabled={disabled}
        mode={mode}
        iconDefault={getEmailIconFromPreference("Daily")}
      />
      <PreferenceDropdown
        options={AppPreferenceOptions}
        selectedOptionId={appPreference}
        openTopPopover={openFromTop}
        setSelectedOptionId={onSelectApp}
        disabled={disabled}
        mode={mode}
        iconDefault={getAppIconFromPreference("All")}
      />
    </>
  );
};

export default DesktopPreferencePicker;
