import { __API_PATH__, __JITSI_PATH__ } from "src/js/settings/settingsUrl";

const baseGroupUrl = "/<%=space_slug%>/group/";

export const URLS = {
  activity: `${baseGroupUrl}<%=group_id%>/activity`,
  activity_scorm: `${baseGroupUrl}<%=group_id%>/activity/scorm`,
  activity_download: `${baseGroupUrl}<%=group_id%>/activity/download`,
  activity_assignment: `${baseGroupUrl}<%=group_id%>/activity/assignment`,
  activity_examination: `${baseGroupUrl}<%=group_id%>/activity/examination`,
  activity_new: `${baseGroupUrl}<%=group_id%>/activity/new`,
  confirm_registration_age: "/registration/confirm/age/<%=token%>",
  post_edit: `${baseGroupUrl}<%=group_id%>/post/edit/<%=post_id%>`,
  post_detail: `${baseGroupUrl}<%=group_id%>/post/<%=post_id%>`,
  exercise_deatil: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>`,
  exercises: `${baseGroupUrl}<%=group_id%>/exercises`,
  exercises_instants: `${baseGroupUrl}<%=group_id%>/exercises/instants`,
  exercises_new: `${baseGroupUrl}<%=group_id%>/exercises/new`,
  exercises_edit: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/edit`,
  exercises_run: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/run`,
  exercises_run_quiz: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/run/<%=quiz_number%>`,
  exercises_autostart: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/run/autostart`,
  exercises_reports_users: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/reports/users`,
  exercises_instants_live: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/instants/<%=execution_id%>/live`,
  exercises_instants_users: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/instants/<%=execution_id%>/users`,
  exercises_instants_run: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/instants/<%=execution_id%>/run`,
  exercises_reports_sessions: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/reports/sessions/<%=session_id%>`,
  exercises_reports_sessions_live: `${baseGroupUrl}<%=group_id%>/exercises/<%=exercise_id%>/reports/sessions/<%=session_id%>/live`,
  groups_catalog: "/groups/catalog",
  home: "/",
  join: "/join/<%=alias_id%>",
  login: "/login",
  logout: "/logout",
  moderation: `${baseGroupUrl}<%=group_id%>/moderation`,
  notifications: "/notifications",
  // "payment": "payment",
  profile: "/profile",
  profile_public: "/users/<%=user_id%>/profile",
  parent_activation_url: "/underageapprovation/<%=user_code%>",
  register: "/register",
  register_social: "/register/social",
  report_abuse_post: `${baseGroupUrl}<%=group_id%>/post/<%=post_id%>/report`,
  report_abuse_resource: `${baseGroupUrl}<%=group_id%>/resource/<%=resource_id%>/report`,
  report_issue: `${baseGroupUrl}<%=group_id%>/issue/report`,
  resources_edit: `${baseGroupUrl}<%=group_id%>/resources/<%=resource_id%>/edit`,
  requestPassword: "/password/request",
  underage_lock: "/underagelock",
  settings: "/settings/notifications",
  search: "/search",
  virtual_class: `${__JITSI_PATH__}<%=classroom_id%>`,
  wall: `${baseGroupUrl}<%=group_id%>/wall`,
  wall_new_post: `${baseGroupUrl}<%=group_id%>/wall/new`,
  api_activity_download: `${__API_PATH__}groups/<%=group_id%>/activities/exports`,
  api_costum_activities_new: `${__API_PATH__}groups/<%=group_id%>/customactivities`,
  api_costum_activities_delete: `${__API_PATH__}groups/<%=group_id%>/customactivities/<%=activity_custom_id%>`,
  api_costum_activities_user_new: `${__API_PATH__}groups/<%=group_id%>/customactivities/<%=activity_custom_id%>/datas/<%=user_id%>`,
  api_costum_activities_user_update: `${__API_PATH__}groups/<%=group_id%>/customactivities/<%=activity_custom_id%>/datas/<%=user_id%>`,
  api_crm: `${__API_PATH__}crm`,
  api_notifications_read: `${__API_PATH__}notifications/read`,
  api_notification_read: `${__API_PATH__}notifications/<%=notification_id%>/read`,
  api_comment_delete: `${__API_PATH__}comments/<%=comment_id%>`,
  api_comment_edit: `${__API_PATH__}comments/<%=comment_id%>`,
  api_comment_new: `${__API_PATH__}comments`,
  api_comments_get: `${__API_PATH__}comments`,
  api_confirm_registration_age: `${__API_PATH__}users/guardian/registration/confirmation?token=<%=token%>`,
  api_posts: `${__API_PATH__}posts`,
  api_posts_entities: `${__API_PATH__}posts/<%=post_id%>`,
  api_posts_pin: `${__API_PATH__}posts/<%=post_id%>/pin`,
  api_posts_comments: `${__API_PATH__}posts/<%=post_id%>/comments`,
  api_posts_comments_entities: `${__API_PATH__}posts/<%=post_id%>/comments/<%=comment_id%>`,
  api_wall: `${__API_PATH__}groups/<%=group_id%>/wall`,
  api_boards_generic: `${__API_PATH__}boards`, // non in uso?
  api_boards_entities: `${__API_PATH__}boards/<%=board_id%>`,
  api_boards_order: `${__API_PATH__}boards/reorder`,
  api_boards_elements: `${__API_PATH__}boards/<%=board_id%>/elements`,
  api_boards_elements_edit: `${__API_PATH__}boards/<%=board_id%>/elements/<%=element_id%>`,
  api_boards_elements_comments: `${__API_PATH__}boards/<%=board_id%>/boardelements/<%=element_id%>/comments`,
  api_boards_elements_comments_entity: `${__API_PATH__}boards/<%=board_id%>/boardelements/<%=element_id%>/comments/<%=comment_id%>`,
  api_boards_delete: `${__API_PATH__}boards/<%=board_id%>`,
  api_boards_available_constraints: `${__API_PATH__}boards/<%=board_id%>/available/constraints`,
  api_boards_edit_constraints: `${__API_PATH__}boards/<%=board_id%>/constraints`,
  api_boards_publish: `${__API_PATH__}boards/<%=board_id%>/publish`,
  api_boards_unpublish: `${__API_PATH__}boards/<%=board_id%>/unpublish`,
  api_board_change_color: `${__API_PATH__}boards/<%=board_id%>/color`,
  api_users_generic: `${__API_PATH__}users`,
  api_exercises: `${__API_PATH__}exercises`,
  api_exercises_entities: `${__API_PATH__}exercises/<%=exercise_id%>`,
  api_exercises_publish: `${__API_PATH__}exercises/<%=exercise_id%>/publish`,
  api_exercises_unpublish: `${__API_PATH__}exercises/<%=exercise_id%>/unpublish`,
  api_exercises_instant: `${__API_PATH__}exercises/<%=exercise_id%>/executions/instant`,
  api_exercises_instant_list: `${__API_PATH__}exercises/executions/instant`,
  api_exercises_instant_entity: `${__API_PATH__}exercises/<%=exercise_id%>/executions/<%=execution_id%>/instant`,
  api_exercises_instant_abort: `${__API_PATH__}exercises/<%=exercise_id%>/executions/<%=execution_id%>/abort`,
  api_exercises_reports_stats: `${__API_PATH__}exercises/<%=exercise_id%>/reports/stats`,
  api_exercises_reports_users: `${__API_PATH__}exercises/<%=exercise_id%>/reports/users`,
  api_exercises_reports_users_instant: `${__API_PATH__}exercises/<%=exercise_id%>/reports/users/instant`,
  api_exercises_reports_quizzes: `${__API_PATH__}exercises/<%=exercise_id%>/reports/aggregations/question`,
  api_exercises_reports_users_sessions: `${__API_PATH__}exercises/<%=exercise_id%>/reports/users/<%=user_id%>`,
  api_exercises_reports_sessions: `${__API_PATH__}exercises/<%=exercise_id%>/reports/<%=session_id%>/sessions`,
  api_exercises_quizzes: `${__API_PATH__}exercises/<%=exercise_id%>/quizzes`,
  api_exercises_quizzes_entities: `${__API_PATH__}exercises/<%=exercise_id%>/quizzes/<%=quiz_id%>`,
  api_exercises_quizzes_reorder: `${__API_PATH__}exercises/<%=exercise_id%>/quizzes/reorder`,
  api_exercises_executions: `${__API_PATH__}exercises/<%=exercise_id%>/executions`,
  api_exercises_executions_entities: `${__API_PATH__}exercises/<%=exercise_id%>/executions/<%=session_id%>`,
  api_exercises_quizzes_executions: `${__API_PATH__}exercises/<%=exercise_id%>/quizzes/<%=quiz_id%>/executions`,
  api_exercises_quizzes_executions_entities: `${__API_PATH__}exercises/<%=exercise_id%>/quizzes/<%=quiz_id%>/executions/<%=session_id%>`,
  api_group_active: `${__API_PATH__}groups/<%=group_id%>`,
  api_group_boards_progress: `${__API_PATH__}score/groups/<%=group_id%>/progress`,
  api_group_copy: `${__API_PATH__}groups/<%=group_id%>/copy`,
  api_group_set_school: `${__API_PATH__}groups/<%=group_id%>/school/<%=school_id%>`,
  api_groups: `${__API_PATH__}groups`,
  api_groups_accepts_terms: `${__API_PATH__}groups/<%=group_id%>/users/<%=user_id%>/settings/<%=settings_type%>`,
  api_groups_delete: `${__API_PATH__}groups/<%=group_id%>`,
  api_groups_invites: `${__API_PATH__}groups/<%=group_id%>/invites`,
  api_groups_invites_alias: `${__API_PATH__}groups/<%=group_id%>/invites/alias`,
  api_groups_invites_alias_candidate: `${__API_PATH__}groups/<%=group_id%>/invites/alias/candidate`,
  api_groups_invites_alias_check: `${__API_PATH__}groups/<%=group_id%>/invites/<%=alias%>/check`,
  api_groups_invites_code: `${__API_PATH__}groups/invites/<%=code%>`,
  api_groups_invites_reject: `${__API_PATH__}groups/invites/<%=invite_id%>/users/reject`,
  api_groups_public_link_status: `${__API_PATH__}alias/<%=alias%>/active`,
  api_groups_ranking: `${__API_PATH__}score/groups/<%=group_id%>`,
  api_groups_user_ranking_window: `${__API_PATH__}score/groups/<%=group_id%>/users`,
  api_groups_report: `${__API_PATH__}groups/<%=group_id%>/reports`,
  api_groups_requests: `${__API_PATH__}groups/request/all`,
  api_groups_requests_handle: `${__API_PATH__}groups/<%=group_id%>/request`,
  api_groups_user: `${__API_PATH__}groups/<%=group_id%>/users/<%=user_id%>`,
  api_groups_users: `${__API_PATH__}groups/<%=group_id%>/users`,
  api_groups_user_invites: `${__API_PATH__}users/me/groups/invites`,
  api_groups_users_points: `${__API_PATH__}score/groups/<%=group_id%>/usersScore`,
  api_iframe_embeddable: `${__API_PATH__}embeddable`,
  api_moderation_approve: `${__API_PATH__}moderation/assign`,
  api_moderation_reject: `${__API_PATH__}moderation/assign`,
  api_moderations: `${__API_PATH__}groups/<%=group_id%>/moderation`,
  api_resources: `${__API_PATH__}resources`,
  api_resources_embeddable_check: `${__API_PATH__}resources/isembeddable`,
  api_resources_change_preview: `${__API_PATH__}boards/<%=board_id%>/elements/<%=element_id%>/preview`,
  api_resources_search: `${__API_PATH__}resources/search`,
  api_registration_confirm: `${__API_PATH__}users/me/registration/confirm?token=<%=token%>`,
  api_reset_password: `${__API_PATH__}users/password/reset/confirm`,
  api_school_new: `${__API_PATH__}schools`,
  api_tutor_email_request: `${__API_PATH__}users/me/ask-mail-confirmation`,
  api_user_activation_code: `${__API_PATH__}users/me/ask-confirmation`,
  api_user_code_status: `${__API_PATH__}confirm-user/<%=token%>`,
  api_users_me: `${__API_PATH__}users/me`,
  api_users_check_mail: `${__API_PATH__}users/me/email/availability?email=<%=email%>`,
  api_users_change_mail: `${__API_PATH__}users/me/email`,
  api_users_change_mail_confirm: `${__API_PATH__}users/me/email-change/confirm?token=<%=token%>`,
  api_users_change_mail_reject: `${__API_PATH__}users/me/email-change/reject`,
  api_users_change_mail_resend: `${__API_PATH__}users/me/email-change/send`,
  api_users_groups: `${__API_PATH__}users/<%=user_id%>/groups`,
  api_users_schools: `${__API_PATH__}users/<%=user_id%>/schools`,
  api_users_public_stream: `${__API_PATH__}users/<%=user_id%>/wall`,
  api_users_positions: `${__API_PATH__}score/users/<%=user_id%>/charts`,
  api_users_password: `${__API_PATH__}users/me/password`,
  api_users_avatar: `${__API_PATH__}users/me/avatar`,
  api_users_cover: `${__API_PATH__}users/me/cover`,
  api_users_name: `${__API_PATH__}users/me/name`,
  api_users_public_profile: `${__API_PATH__}users/<%=user_id%>`,
  api_users_following: `${__API_PATH__}users/follow`,
  api_users_follow: `${__API_PATH__}users/follow/user`,
  api_user_score: `${__API_PATH__}score/users/<%=user_id%>`,
  api_vimeo_generic: "https://api.vimeo.com",
  api_vimeo_search: "https://api.vimeo.com/videos",
  api_report_abuse: `${__API_PATH__}contentsreports`,
  api_resource_rating: `${__API_PATH__}rate/resources/<%=resource_id%>`,
  api_tags: `${__API_PATH__}tags`,
  api_resource_tags: `${__API_PATH__}tags/resources/<%=resource_id%>/associations`,
  api_resource_tags_association: `${__API_PATH__}tags/<%=tag_id%>/resources/<%=resource_id%>/associations`,
  api_registration_token_info: `${__API_PATH__}platforminvites/<%=token%>`,
  api_registration_code_info: `${__API_PATH__}groups/<%=token%>/invites/token`,
  api_registration_new: `${__API_PATH__}users/registration`,
  api_group_settings: `${__API_PATH__}groups/<%=group_id%>/users/<%=user_id%>/settings/<%=settings_type%>`
};
