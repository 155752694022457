import React, { FC } from "react";
import { DefaultTheme } from "styled-components";
import { AvatarWithInitials } from "@ds_universal/AvatarWithInitials";
import { Box } from "@ds_universal/Box";
import { whiteTheme } from "@ds_themes/white";

import { SpaceBaseCardProps, SpaceBaseCardSizeType } from "./types";
import * as S from "./SpaceBaseCard.styles";

const getAvatarSize = (sizeVariant: SpaceBaseCardSizeType) => {
  switch (sizeVariant) {
    case SpaceBaseCardSizeType.Auto:
      return "auto";
    case SpaceBaseCardSizeType.Small:
      return "small";
    case SpaceBaseCardSizeType.Medium:
      return "medium";
    case SpaceBaseCardSizeType.Big:
    default:
      return "big";
  }
};

const SpaceBaseCard: FC<SpaceBaseCardProps> = ({
  spaceAvatar: { name = "Space Name", avatarColor = 0, url, size } = {},
  sizeVariant,
  hasBorder = true,
  onClick,
  isSelected = false,
  isTransparent = false,
  centralContent,
  rightContent,
  className
}) => {
  return (
    <S.SpaceBaseCardContainer
      sizeVariant={sizeVariant}
      hasBorder={hasBorder}
      onClick={onClick}
      isSelected={isSelected}
      isTransparent={isTransparent}
      className={className}
    >
      <Box
        marginRight={
          sizeVariant === SpaceBaseCardSizeType.Small ? "8px" : "12px"
        }
      >
        <AvatarWithInitials
          theme={whiteTheme as unknown as DefaultTheme}
          url={url}
          alt={name}
          name={name}
          avatarColor={avatarColor}
          size={size || getAvatarSize(sizeVariant)}
        />
      </Box>
      <Box flex={1}>{centralContent}</Box>
      <Box>{rightContent}</Box>
    </S.SpaceBaseCardContainer>
  );
};

export default SpaceBaseCard;
