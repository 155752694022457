import { Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

export const createSidemenuTheme = ({ general, primary }: Palette) => {
  return {
    sidemenu: {
      background: "transparent",
      text: general.contrastText,

      selected: {
        background: getHighContrastColor(
          primary.main,
          primary.dark,
          primary.main
        ),
        text: primary.contrastText
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};
