/* eslint-disable no-nested-ternary */
import { observer } from "mobx-react";
import React, { useEffect, useReducer } from "react";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { EventDomain, GroupEvent } from "src/js/types";
import {
  GroupDetailsModel,
  ObjectValues,
  TemplateSelection,
  TemplateSelectionType
} from "src/js/types/models";
import { useTheme } from "styled-components";
import { GroupCreationForm } from "../GroupCreationForm";
import { TemplateCustomSelection } from "./components/TemplateCustomSelection";
import { TemplateIntro } from "./components/TemplateIntro";
import { TemplateWeschoolSelection } from "./components/TemplateWeschoolSelection";

export const TemplateActions = {
  SetSelection: "SET_SELECTION",
  SetGroup: "SET_GROUP",
  Back: "BACK",
  Reset: "RESET"
} as const;

export type TemplateActionsType = ObjectValues<typeof TemplateActions>;

type TemplateStateType = {
  prevSelection: TemplateSelectionType;
  selection: TemplateSelectionType;
  group: GroupDetailsModel;
};

type TemplateActionPayloadType = {
  selection?: TemplateSelectionType;
  group?: GroupDetailsModel;
};

type TemplateActionType = {
  type: TemplateActionsType;
  payload?: TemplateActionPayloadType;
};

export const templateReducer = (
  state: TemplateStateType,
  action: TemplateActionType
) => {
  switch (action.type) {
    case TemplateActions.SetSelection:
      // Update selection and reset group
      // Payload needed: { selection: TemplateSelectionType }
      return { ...state, selection: action?.payload?.selection, group: null };
    case TemplateActions.SetGroup:
      // Set group, update previous selection, and set current selection to Blank
      // Payload needed: { group: GroupDetailsModel }
      return {
        ...state,
        prevSelection: state.selection,
        selection: TemplateSelection.Blank,
        group: action?.payload?.group
      };
    case TemplateActions.Back:
      // Go back to previous selection and reset group
      // Payload needed: none
      return {
        ...state,
        prevSelection: null,
        selection: state?.prevSelection ?? null,
        group: null
      };
    case TemplateActions.Reset:
      // Reset all state properties
      // Payload needed: none
      return {
        ...state,
        prevSelection: null,
        selection: null,
        group: null
      };
    default:
      // Return current state if action type is not recognized
      return state;
  }
};

const GroupCreationModal = () => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme, greyTheme } = useTheme();
  const { trackVirtualView } = useViewTracking();

  useEffect(() => {
    return () => {
      trackVirtualView(WeSchoolVirtualViewDescriptions.Groups.GroupCreateClose);
    };
  }, []);

  const theme = isLayoutModeMobile ? greyTheme : whiteTheme;

  const [template, dispatchTemplate] = useReducer(templateReducer, {
    prevSelection: null,
    selection: null,
    group: null
  });

  const handleGoBack = () => {
    if (!template.prevSelection) {
      _trackEvent(EventDomain.Group, GroupEvent.BackToGroupCreateSelection);
    }
    dispatchTemplate({ type: TemplateActions.Back });
  };

  const handleSubmit = (group: GroupDetailsModel) => {
    dispatchTemplate({
      type: TemplateActions.SetGroup,
      payload: { group }
    });
  };

  const handleSelection = (selection: TemplateSelectionType) => {
    dispatchTemplate({
      type: TemplateActions.SetSelection,
      payload: { selection }
    });
  };

  switch (template.selection) {
    case TemplateSelection.Weschool:
      return (
        <TemplateWeschoolSelection
          theme={theme}
          onSubmit={handleSubmit}
          onGoBack={handleGoBack}
        />
      );
    case TemplateSelection.Custom:
      return (
        <TemplateCustomSelection
          theme={theme}
          onSubmit={handleSubmit}
          onGoBack={handleGoBack}
        />
      );
    case TemplateSelection.Blank:
      return (
        <GroupCreationForm
          onGoBack={handleGoBack}
          clonedGroup={template.group}
          prevSelection={template.prevSelection}
        />
      );
    default:
      return <TemplateIntro onSelection={handleSelection} />;
  }
};

export default observer(GroupCreationModal);
