import { Heading, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledHeading = styled(Heading)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.grey[50]};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  @media (max-width: 767px) {
    margin-right: 34px;
  }
`;

export const StyledText = styled(Text)`
  width: 100%;
  left: 20px;
  color: ${({ theme }) => theme.colors.grey[500]};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const RadioDot = styled.div<{ isSelected?: boolean }>`
  opacity: 1 !important;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.primaryColor[500] : "transparent"};
  outline: 1px solid ${({ theme }) => theme.primaryColor[500]};
  padding: 4px;
  outline-offset: 2px;
  @media (max-width: 767px) {
    top: 28px;
    right: 28px;
  }
`;

export const Cover = styled.div<{ cover: string }>`
  height: 128px;
  width: 100%;
  background: ${({ cover }) => `url(${cover}) center no-repeat`};
  background-size: cover;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[900]};
  margin-top: auto;
  @media (max-width: 767px) {
    height: 142px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 12px;
  gap: 4px;
  overflow: hidden;
  max-height: 124px;
  @media (max-width: 767px) {
    padding: 28px 28px 16px;
  }
`;

export const Card = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  height: 252px;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  @media (max-width: 767px) {
    height: unset;
  }
`;

export const StyledLi = styled(motion.li)<{
  isSelected?: boolean;
}>`
  border-radius: 16px;
  position: relative;
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.primaryColor[500] : theme.colors.grey[900]};
  cursor: pointer;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const StyledUl = styled.ul`
  all: unset;
  list-style: none;
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: auto;
  gap: 12px;
  padding: 2px;
  @media (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;
