import { Icon, Input, Text, colors } from "@arcadia/design-system";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.blueGrey[500]};

  & > div:first-child > div {
    max-width: fit-content;
    border-radius: 4px;
    left: 0px;
    top: 48px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      max-width: inherit;
      top: 0px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor[800]};
    border: ${({ theme }) => `1px solid ${theme.primaryColor[700]}`};

    & > div:last-child > div:last-child > input {
      border: none;
      background: none;
    }
  }

  &:has(#resource-dropdown-container:hover) {
    color: ${({ theme }) => theme.colors.blueGrey[500]};
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    border: 1px solid transparent;
  }
`;

export const LinkInput = styled(Input)`
  & > div:last-child {
    margin-top: 0px;

    &:focus-within::before {
      display: none;
    }

    & > input {
      padding-left: 2px;
      border: none;
      text-overflow: ellipsis;

      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
    }

    & > div:last-child {
      top: 0px;
      right: 8px;
    }
  }
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const ErrorMessageContainer = styled.div`
  color: ${colors.error[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const ArrowIconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  cursor: pointer;
`;

export const ArrowIcon = styled(Icon)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;
