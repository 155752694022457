import getUserClient from "src/js/modules/connection";
import {
  api_exercise,
  api_exercise_settings,
  api_exercises_publish,
  api_exercise_show_report_to_all,
  api_exercise_show_report_to_user,
  getVersionedApiUrl,
  api_exercises_entities,
  api_exercises,
  api_exercises_instant_abort
} from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";

export function fetchExerciseList(data) {
  return getUserClient()
    .get(getVersionedApiUrl(api_exercises()), {
      params: {
        ...data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_exercise"));
      throw Err;
    });
}

export function patchExerciseInstantAbort({ exerciseId, executionId }) {
  return getUserClient()
    .patch(
      getVersionedApiUrl(
        api_exercises_instant_abort({ exerciseId, executionId })
      )
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_exercise"));
      throw Err;
    });
}

export function fetchExercise(groupId, exerciseId) {
  return getUserClient()
    .get(getVersionedApiUrl(api_exercise({ groupId, exerciseId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_exercise"));
      throw Err;
    });
}

export function fetchExerciseSettings(exerciseId) {
  return getUserClient()
    .get(getVersionedApiUrl(api_exercise_settings({ exerciseId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_post_exercise_settings")
      );
      throw Err;
    });
}

export function publishExercise(exerciseId) {
  return getUserClient()
    .patch(getVersionedApiUrl(api_exercises_publish({ exerciseId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_publish_exercise")
      );
      throw Err;
    });
}

export function saveExerciseSettings(exerciseId, data) {
  return getUserClient()
    .put(getVersionedApiUrl(api_exercise_settings({ exerciseId })), data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_post_exercise_settings")
      );
      throw Err;
    });
}

export function showResultsToSingleUser(exerciseId, userId) {
  return getUserClient()
    .patch(
      getVersionedApiUrl(
        api_exercise_show_report_to_user({ exerciseId, userId })
      )
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_save_report_settings")
      );
      throw Err;
    });
}

export function showResultsToAllUsers(exerciseId) {
  return getUserClient()
    .patch(getVersionedApiUrl(api_exercise_show_report_to_all({ exerciseId })))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_save_report_settings")
      );
      throw Err;
    });
}

export function fetchExerciseEntities({ exerciseId, report_by }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_exercises_entities({ exerciseId })), {
      params: {
        report_by
      }
    })
    .then(({ data }) => {
      return data;
    });
}

export function saveExerciseEntity({ exerciseId, data }) {
  return getUserClient()
    .put(getVersionedApiUrl(api_exercises_entities({ exerciseId })), data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_post_exercise_settings")
      );
      throw Err;
    });
}
