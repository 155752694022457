import { Box, Heading, Icon } from "@arcadia/design-system";
import React, { FC, useMemo, useState } from "react";
import { SpaceCreationModal } from "src/js/components/SpaceCreation";
import { useStores } from "src/js/hooks";
import { openExternalLink } from "src/js/modules/commonFunction";
import { __WELCOME_CARD_BG__ } from "src/js/settings/settingsImage";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { GroupJoinWithCodeModal } from "../GroupJoinWithCodeModal";
import { MigrateGroupsModal } from "../MigrateGroupsModal";
import { ContactInstructorModal } from "./ContactInstructorModal";
import { STEPS, itemVariants, sidebarVariants } from "./SpacesWelcome.const";
import { CircleArrowIcon } from "./SpacesWelcome.icons";
import * as S from "./SpacesWelcome.styles";
import {
  SpacesWelcomeActionType,
  SpacesWelcomeProps
} from "./SpacesWelcome.types";
import { SpacesWelcomeActionsLabel } from "./SpacesWelcomeActionsLabel";
import { SpacesWelcomeHelper } from "./SpacesWelcomeHelper";
import { SpacesWelcomeTitle } from "./SpacesWelcomeTitle";

const SpacesWelcome: FC<SpacesWelcomeProps> = ({
  name,
  createCompleted,
  moveCompleted
}) => {
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore
  } = useStores();
  const { translate } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);

  const steps = useMemo(
    () =>
      STEPS.map(step => ({
        ...step,
        status: step.getStatus({ createCompleted, moveCompleted })
      })),
    [createCompleted, moveCompleted]
  );
  const doneSteps = steps.filter(step => step.status === "DONE").length;
  const activeSteps = steps.filter(step => step.status !== "HIDE").length;

  const createNewSpace = () => {
    ModalStore.openModal(() => <SpaceCreationModal />);
  };

  const actions: SpacesWelcomeActionType[] = [
    {
      id: "creation-space",
      onClick: () => createNewSpace()
    },
    {
      id: "join-flow",
      onClick: () =>
        ModalStore.openModal(() => (
          <GroupJoinWithCodeModal closeModal={ModalStore.closeModal} />
        ))
    },
    {
      id: "migration-groups",
      onClick: () => {
        ModalStore.openModal(() => (
          <MigrateGroupsModal onClose={() => ModalStore.closeModal()} />
        ));
      }
    },
    {
      id: "contact-instructor",
      onClick: () => {
        ModalStore.openModal(() => (
          <ContactInstructorModal
            close={() => ModalStore.closeModal()}
            onConfirm={() => {
              openExternalLink(translate("contact_instructor_link_url"));
            }}
          />
        ));
      }
    }
  ];

  return (
    <S.MainBox
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="menu"
    >
      <S.MainBoxBg src={__WELCOME_CARD_BG__} />
      <S.Header>
        <S.HeaderBody>
          <Heading level="1">
            <SpacesWelcomeTitle
              label={translate({
                text: "welcome_card_title",
                stringVariables: {
                  name: isLayoutModeMobile ? "" : `, ${name}`
                }
              })}
            />
          </Heading>
          <S.TextDescription>
            {translate({ text: "welcome_card_text" })}
          </S.TextDescription>
        </S.HeaderBody>
        <Box>
          <S.ArrowBtn
            onClick={() => setIsOpen(!isOpen)}
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 }
            }}
            transition={{ duration: 0.2 }}
          >
            <CircleArrowIcon />
          </S.ArrowBtn>
        </Box>
      </S.Header>
      <S.StatusTasksLabel
        variants={{
          open: { opacity: 0, height: 0 },
          closed: { opacity: 1, height: 28 }
        }}
        transition={{ duration: 0.2 }}
      >
        {doneSteps}/{activeSteps}{" "}
        {translate({ text: "welcome_card_tasks_done" })}
      </S.StatusTasksLabel>
      <S.StepsMotion variants={sidebarVariants}>
        <S.Steps>
          {steps.map((step, i) => (
            <S.Step
              status={step.status}
              key={step.id}
              variants={itemVariants(i)}
            >
              <Icon height="40" width="40" initialViewbox icon={step.icon} />
              <S.StepContent>
                <S.StepHelp>
                  {translate({ text: step.helpLabel as TranslationKeys })}{" "}
                  <SpacesWelcomeHelper {...step.helper} />
                </S.StepHelp>
                <SpacesWelcomeActionsLabel
                  label={`${i + 1}. ${translate({
                    text: step.titleLabel as TranslationKeys
                  })}`}
                  actions={actions}
                />
              </S.StepContent>
              <S.StyledDotSelectionIcon />
            </S.Step>
          ))}
        </S.Steps>
      </S.StepsMotion>
    </S.MainBox>
  );
};

export default SpacesWelcome;
