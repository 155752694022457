function isiOS() {
  const iDevices = [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod"
  ];

  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
}

const comparator = {
  "<": function (a, b) {
    return a < b;
  },
  "<=": function (a, b) {
    return a <= b;
  },
  ">": function (a, b) {
    return a > b;
  },
  ">=": function (a, b) {
    return a >= b;
  }
};

function compareVersion(version, range) {
  const string = `${range}`;
  const n = +(string.match(/\d+/) || NaN);
  const op = string.match(/^[<>]=?|/)[0];
  // eslint-disable-next-line no-self-compare
  return comparator[op] ? comparator[op](version, n) : version === n || n !== n;
}

export function isSafariBrowser() {
  const navigator = window && window.navigator;
  const userAgent = ((navigator && navigator.userAgent) || "").toLowerCase();
  const match = userAgent.match(/version\/(\d+).+?safari/);
  if (match === null) {
    return false;
  }
  return match;
}

function isCompatibleSafariVersion(range) {
  const match = isSafariBrowser();
  if (match !== false) {
    return compareVersion(match[1], range);
  }
  return true;
}

export function isCompatibleVersion(browser, version) {
  switch (browser) {
    case "safari":
      return isCompatibleSafariVersion(version);
    default:
      return true;
  }
}

export function getDefaultAgent() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "desktop";
}

export { isiOS as default };
