import {
  LinkButton,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import { SpaceSchoolCard } from "src/js/components/SpaceCards/SpaceSchoolCard";
import styled from "styled-components";
import { SFooterWrapper } from "./SchoolClaimSearch.types";

export const StyledSpaceSchoolCard = styled(SpaceSchoolCard)`
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div<{ withFeedback: boolean }>`
  margin-bottom: ${({ withFeedback }) => (withFeedback ? null : "16px")};
`;

export const FooterWrapper = styled.div<SFooterWrapper>`
  gap: 8px;
  width: 100%;
  background: ${({ backgroundColor }) =>
    `linear-gradient(180deg, transparent 0%, ${backgroundColor} 100%)`};
  @media screen and (max-width: 768px) {
    padding: 16px 0px;
    margin-top: auto;
  }
  button {
    width: 100%;
  }
`;

export const ScrollableArea = styled.div`
  overflow-y: auto;
  min-height: 230px;
  height: 100%;
  max-height: 294px;
  margin-right: -8px;
  padding-right: 8px;
  ${scrollbarMixin(VariantType.White)}
  @media screen and (max-width: 768px) {
    ${scrollbarMixin(VariantType.Grey)}
  }
`;

export const ScrollableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const SpaceCreationRedirectContainer = styled.div`
  width: 100%;
  padding-top: 12px;
  text-align: center;
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey[900]};
    padding-top: 12px;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  display: inline-block;
  color: ${({ theme }) => theme.primaryColor[500]};
  & span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
  }
`;
