import React from "react";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { CtaTertiary } from "@arcadia/design-system";
import * as S from "./GroupPanelUsersList.styles";

const getAbbreviatedWord = word => {
  return word ? `${word.charAt(0)}.` : "";
};

const GroupPanelUsersList = ({ users = [], showAll = () => {} }) => {
  const { whiteTheme } = useTheme();

  return (
    <S.UsersList>
      {users.length > 0 && (
        <S.StyledList>
          <S.Mobile>{getAbbreviatedWord(users[0].firstName)}</S.Mobile>
          <S.TabletAndUp>{users[0].firstName}</S.TabletAndUp>{" "}
          <span>{users[0].lastName}</span>
        </S.StyledList>
      )}
      {users.length > 1 && (
        <S.StyledList>
          <S.Mobile>{getAbbreviatedWord(users[1].firstName)}</S.Mobile>
          <S.TabletAndUp>{users[1].firstName}</S.TabletAndUp>{" "}
          <span>{users[1].lastName}</span>
        </S.StyledList>
      )}
      {users.length > 2 && (
        <S.WrapperCtaTertiary>
          <CtaTertiary theme={whiteTheme} onClick={showAll}>
            <Translate text="see_more_plus" />
          </CtaTertiary>
        </S.WrapperCtaTertiary>
      )}
    </S.UsersList>
  );
};

export default GroupPanelUsersList;
