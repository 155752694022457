import { ContextualFeedbackThemeOptions, Palette } from "@ds_themes/types";

export const createContextualFeedbackTheme = ({
  success
}: Palette): { contextualfeedback: ContextualFeedbackThemeOptions } => {
  return {
    contextualfeedback: {
      background: success[600],
      text: success[100]
    }
  };
};
