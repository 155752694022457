import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import each from "lodash/each";
import isUndefined from "lodash/isUndefined";
import debounce from "lodash/debounce";
import { __AVAILABLE_RESOURCES__ } from "src/js/settings/settingsResource";
import { DoodleLoader, Button, SearchBar } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { __ } from "../../../modules/localization";
import ResourceModel from "../../../models/resourceModel";
import { createURL } from "../../../modules/utility";
import { getActiveGroupId } from "../../../modules/activeGroup";
import ImagePreviewer from "../../../components/imagePreviewer";

const ResourceExercise = withBackbone(
  createReactClass({
    getInitialState() {
      this.props.setWorking(false);
      return {
        loading: false,
        items: [],
        q: ""
      };
    },
    componentDidMount() {
      const input = this.searchInput;
      const $input = $(input);
      const { container } = this;
      const $container = $(container);
      this.setState({ $input, $container });
      this.getData(true);
    },
    getData(force) {
      const self = this;

      const query = force ? "" : self.state.$input.val();

      if (query != this.state.q || (!isUndefined(force) && force == true)) {
        this.setState({ loading: true });

        $.ajax({
          url: createURL("api_resources_search"),
          method: "GET",
          data: {
            type: "EXERCISE",
            group_id: getActiveGroupId(),
            query
          },
          success(ret) {
            self.setState({
              items: ret,
              loading: false,
              q: query
            });
          },
          error(ret) {
            self.setState({ loading: false });
          }
        });
      }
    },
    typeEvent(event) {
      event.persist();

      this.search();
    },
    search: debounce(function (event) {
      this.getData();
    }, 500),
    toggleResource(item, e) {
      const newItems = map(this.state.items, i => {
        if (JSON.stringify(item) === JSON.stringify(i)) {
          i.selected = true;
        } else {
          i.selected = false;
        }
        return i;
      });
      this.setState({
        items: newItems
      });

      e.stopPropagation();
    },
    addResource(item, e) {
      item.type = "EXERCISE";
      this.props.setWorking(true);
      this.setState({ loading: true });
      const resource = new ResourceModel(item);
      this.props.addFunc(resource.toJSON());
      e.stopPropagation(); // altrimenti viene invocato anche il deselect della risorsa
    },
    render() {
      const self = this;
      let content = "";
      let list = "";
      let totalResults = 0;
      let selectedItem = null;
      let validLink = "";
      const attachButtonLabel = __("add_assessment").toUpperCase();
      const { whiteTheme, greyTheme } = this.props.theme;

      each(this.state.items, item => {
        if (!isUndefined(item.selected) && item.selected) {
          selectedItem = item;
        }
      });

      if (!isUndefined(this.state.items)) {
        list = this.state.items.map(item => {
          let elementExtraClass = "";
          let selectedOverlay = "";
          let badge = (
            <div className="resource__search-item-badge badge">
              {__("exercise_assignment")}
            </div>
          );

          if (item.exercise.type == "EXAMINATION") {
            badge = (
              <div className="resource__search-item-badge item-examination badge">
                {__("exercise_examination")}
              </div>
            );
          }

          const listItem = (
            <div
              className="clearfix"
              onClick={self.toggleResource.bind(self, item)}
            >
              <div className="row resource__search-item-wrapper">
                <div className="resource__search-item-image-wrapper">
                  <ImagePreviewer
                    className="resource__search-item-image contain"
                    type="DIV"
                    src={__AVAILABLE_RESOURCES__.exercises.image}
                    background={__AVAILABLE_RESOURCES__.exercises.background}
                    isReady
                  />
                </div>
                <div className="resource__search-item-text-wrapper">
                  <div className="resource__search-item-text">
                    {badge}
                    <div className="resource__search-item-title">
                      {item.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );

          if (item == selectedItem) {
            elementExtraClass = "is-selected";
            selectedOverlay = (
              <div
                className="resource__selected-overlay"
                onClick={self.toggleResource.bind(self, null)}
              >
                <div className="full-height flex-centered-column flex-centered-row">
                  <Button
                    theme={whiteTheme}
                    variant="secondary"
                    onClick={self.addResource.bind(self, selectedItem)}
                  >
                    {attachButtonLabel}
                  </Button>
                </div>
              </div>
            );
          }

          return (
            <div
              className="col-lg-3 col-sm-4 col-ms-6 col-xs-12"
              key={`resource-search-item-box-${Math.random()}`}
            >
              <div
                className={["resource__search-item", elementExtraClass].join(
                  " "
                )}
              >
                {listItem}
                {selectedOverlay}
              </div>
            </div>
          );
        });
      }

      if (!isUndefined(this.state.searchInformation)) {
        totalResults = this.state.searchInformation.totalResults;
      }

      if (this.state.loading) {
        const style = { padding: "20px 0" };
        content = <DoodleLoader theme={greyTheme} />;
      } else if (list != "") {
        content = <div className="row">{list}</div>;
      } else if (list == "" && self.state.q != "") {
        content = (
          <div className="empty-list-message-box">
            <div className="empty-list-message-wrapper">
              {__("search_no_result")}
            </div>
          </div>
        );
      }

      if (this.state.queryIsUrl) {
        const item = {
          link: this.state.q,
          title: "",
          htmlSnippet: ""
        };
        validLink = (
          <div className="alert alert-info">
            <p>
              {__("Stai cercando un URL. Vuoi allegarlo direttamente?")}
              <Button
                onClick={self.addResource.bind(self, item)}
                theme={whiteTheme}
                variant="primary"
                fullWidth={mobile}
              >
                {__("allega URL")}
              </Button>
            </p>
            <p>{__("Oppure scegli uno dei risultati sottostanti")}:</p>
          </div>
        );
      }

      return (
        <div className="resource__search resource__search-cards">
          <div className="clearfix">
            <div className="resource__search-form form-group clearfix">
              <SearchBar
                id="search"
                theme={greyTheme}
                onChange={this.typeEvent}
                ref={searchInput => {
                  this.searchInput = searchInput;
                }}
                placeholder={__("cerca tra gli esercizi")}
              />
            </div>
          </div>
          {validLink}
          <div
            className="resource__search-results resource__exercise"
            ref={container => (this.container = container)}
          >
            {content}
          </div>
        </div>
      );
    }
  })
);

export default withTheme(ResourceExercise);
