import { ObjectValues } from "./Common";

export const SCORMPlayerStatus = {
  Ready: "ready",
  Terminated: "terminated",
  Error: "error",
  Uploading: "uploading"
} as const;

export const SCORMMethods = {
  LMSFinish: "LMSFinish",
  LMSGetLastError: "LMSGetLastError",
  LMSInitialize: "LMSInitialize",
  LMSGetValue: "LMSGetValue",
  LMSSetValue: "LMSSetValue",
  LMSCommit: "LMSCommit",
  LMSGetDiagnostic: "LMSGetDiagnostic",
  LMSGetErrorString: "LMSGetErrorString"
} as const;

export const SCORM2004Methods = {
  Initialize: "Initialize",
  Terminate: "Terminate",
  GetValue: "GetValue",
  SetValue: "SetValue",
  Commit: "Commit",
  GetLastError: "GetLastError",
  GetErrorString: "GetErrorString",
  GetDiagnostic: "GetDiagnostic"
} as const;

export type SCORMPlayerStatus = ObjectValues<typeof SCORMPlayerStatus>;
export type SCORMMethods = ObjectValues<typeof SCORMMethods>;
export type SCORM2004Methods = ObjectValues<typeof SCORM2004Methods>;

export const SCORMErrorType = {
  ExtensionNotValid: "ExtensionNotValid",
  ManifestNotFound: "ManifestNotFound",
  VersionNotCompatible: "VersionNotCompatible",
  FetchError: "FetchError"
} as const;

export type SCORMErrorType = ObjectValues<typeof SCORMErrorType>;

export type ErrorData = {
  message: string;
  type: SCORMErrorType;
};

export type SCORMEventData =
  | { method: "error"; data?: ErrorData }
  | {
      method: "uploading";
      data: { scormId: string; message: string };
    }
  | {
      method: "ready";
      data: { version: "1.2" | "2004" };
    }
  | {
      method: SCORMMethods | SCORM2004Methods;
      data?: {
        CMIData?: string;
        CMIElement?: any;
      };
    };

// for reference
export type SCORMCMI = {
  comments: string | null;
  comments_from_lms: string | null;
  core: {
    student_id: string | null;
    student_name: string | null;
    session_time: string | null;
    total_time: string | null;
    score: { raw: number | null; min: number | null; max: number | null };
    credit: string | null;
    entry: string | null;
    exit: string | null;
    lesson_location: string | null;
    lesson_mode: string | null;
    lesson_status: string | null;
  };
  interactions: { childArray: Array<any> | null };
  launch_data: string | null;
  objectives: { childArray: Array<any> | null };
  student_data: {
    mastery_score: number | null;
    max_time_allowed: string | null;
    time_limit_action: string | null;
  };
  student_preference: {
    audio: number | null;
    language: string | null;
    speed: number | null;
    text: number | null;
  };
  suspend_data: string | null;
};

// for reference
export type SCORM2004CMI = {
  comments_from_learner: { comment: string | null; location: string | null }[];
  comments_from_lms: { comment: string | null; location: string | null }[];
  completion_status: string | null;
  completion_threshold: string | null;
  credit: string | null;
  entry: string | null;
  exit: string | null;
  interactions: {
    id: string | null;
    type: string | null;
    objectives: { id: string | null }[];
    timestamp: string | null;
    correct_responses: { pattern: string | null }[];
    weighting: number | null;
    learner_response: string | null;
    result: string | null;
    latency: string | null;
    description: string | null;
  }[];
  launch_data: string | null;
  learner_id: string | null;
  learner_name: string | null;
  learner_preference: {
    audio_level: number | null;
    language: string | null;
    delivery_speed: number | null;
    audio_captioning: number | null;
  };
  location: string | null;
  max_time_allowed: string | null;
  mode: string | null;
  objectives: {
    id: string | null;
    success_status: string | null;
    completion_status: string | null;
    progress_measure: number | null;
    description: string | null;
  }[];
  progress_measure: number | null;
  scaled_passing_score: number | null;
  score: {
    raw: number | null;
    min: number | null;
    max: number | null;
    scaled: number | null;
  };
  session_time: string | null;
  success_status: string | null;
  suspend_data: string | null;
  time_limit_action: string | null;
  total_time: string | null;
};
