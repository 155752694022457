import { useEffect } from "react";

type ScrollCallback = () => void;

function useScrollDetection(callback: ScrollCallback) {
  useEffect(() => {
    const handleScroll = () => {
      if (callback && typeof callback === "function") {
        callback();
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [callback]);
}

export default useScrollDetection;
