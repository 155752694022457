import { useEffect } from "react";
import { useLocation } from "react-router";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import changeUrl from "src/js/modules/urlFunction";
import { getUrlParameterValue } from "src/js/modules/commonFunction";
import { observer } from "mobx-react";

const getFilterUrlParameters = query => {
  return {
    status_filter: getUrlParameterValue(query, "status_filter") || null,
    ordination_by_filter:
      getUrlParameterValue(query, "ordination_by_filter") || null,
    ordination_direction_filter:
      getUrlParameterValue(query, "ordination_direction_filter") || null,
    name_filter: getUrlParameterValue(query, "name_filter") || null,
    limit: getUrlParameterValue(query, "limit") || null,
    offset: getUrlParameterValue(query, "offset") || null
  };
};

const SpacesGroupsUrlHandler = () => {
  const { SpacesGroupsFilterStore, SpaceStore } = useStores();
  const { filterValuesForQueryParams } = SpacesGroupsFilterStore;
  const location = useLocation();

  useEffect(() => {
    if (SpaceStore?.activeSpaceId) {
      const urlSearchParams = new URLSearchParams();
      Object.keys(filterValuesForQueryParams).map(key => {
        return (
          filterValuesForQueryParams[key] &&
          urlSearchParams.append(key, filterValuesForQueryParams[key])
        );
      });
      const params = urlSearchParams.toString();
      const locationParams = location.search;
      // Here it is necessary to get the activeSpace ID directly from the store
      // to ensure using the last updated value
      if (params !== locationParams.substring(1)) {
        const spaceUrl = createUrl("dashboard_groups", {
          space_id: SpaceStore?.activeSpaceId
        });
        changeUrl(spaceUrl, params);
      }
    }
  }, [filterValuesForQueryParams]);

  useEffect(() => {
    const query = location.search;
    if (query) {
      const filterUrlValues = getFilterUrlParameters(query);

      SpacesGroupsFilterStore.setFiltersAndPaginationFromQueryParams(
        filterUrlValues
      );
    }
  }, [location.search]);

  return null;
};

export default observer(SpacesGroupsUrlHandler);
