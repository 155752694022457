import { Avatar, Box, Icon } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CommentList } from "src/js/components/CommentList";
import { COMMENT_CONTEXT } from "src/js/components/CommentList/CommentList.types";
import { DotsMenu } from "src/js/components/DotsMenu";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { UserProfileTooltip } from "src/js/components/UserProfileTooltip";
import { ReactionList } from "src/js/components/reactions/ReactionList";
import { useModalProps } from "src/js/components/reactions/ReactionListModal/hooks";
import { ReactionPicker } from "src/js/components/reactions/ReactionPicker";
import { useCloseOnClickOutside } from "src/js/hooks";
import useStores from "src/js/hooks/useStores";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { parseMentions } from "src/js/modules/mentionParser";
import { useTranslation } from "src/js/translation";
import { REACTABLE_ENTITY } from "src/js/types/models/Reaction";
import { getActiveGroup } from "src/legacy/modules/activeGroup";
import ResourceViewer from "src/legacy/view/resource/ResourceViewer";
import { CollapsibleTextBox } from "src/js/components/global/CollapsibleTextBox";
import { POST_CONTEXT, PostProps } from "./Post.types";
import { ResourceCarousel } from "./components/ResourceCarousel";
import { PostActionButton } from "./components/PostActionButton";
import { useActionsByContext, useDropdownOptions, usePostState } from "./hooks";

import * as S from "./Post.styles";

const ResourceViewPortal = ({
  resourceView
}: {
  resourceView: React.ReactNode;
}) => {
  const { domEl } = useModalProps({
    onClose: () => {},
    portalId: "resource-viewer-modal"
  });

  return ReactDOM.createPortal(resourceView, domEl);
};

const Post = ({
  pinned,
  postFull,
  setPinnedFunc,
  onDeletePost,
  embedded,
  context = POST_CONTEXT.WALL
}: PostProps) => {
  const {
    PostCommentStore: { getPostById, setNewPost }
  } = useStores();

  useEffect(() => {
    if (!postFull?.post?.id) return;
    setNewPost(postFull.post.id, postFull);
  }, []);

  const [resourceView, setResourceView] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(null);

  const { addNewReaction, removeOldReaction, onNewComment, onDeleteComment } =
    usePostState();

  const {
    GroupStore: { checkIfUserIsTeacher }
  } = useStores();

  const { whiteTheme, primaryColor } = useTheme();
  const { translate } = useTranslation();
  const {
    isOpen: isOpenEmojiPicker,
    ref: emojiRef,
    setIsOpen: setIsOpenEmojiPicker
  } = useCloseOnClickOutside(false);
  const { dropdownOptions } = useDropdownOptions({
    post: postFull.post,
    pinned,
    setPinnedFunc,
    onDeletePost,
    embedded,
    context
  });
  const { inputRef, onShowCommentsClick, onCommentClick } = useActionsByContext(
    {
      context,
      postId: postFull.post.id,
      setPinnedFunc,
      onDeletePost
    }
  );

  const mobile = getBreakpoint() === "smartphone";

  const postFullState = getPostById(postFull.post.id);

  const { post, resources, reactions } = postFullState || {};

  const postText = postFull.post.text || "";

  useEffect(() => {
    if (selectedIndex === null || !postFullState) return;

    setResourceView(
      <ResourceViewer
        resources={resources}
        startingPosition={selectedIndex}
        postId={post.id}
        onClose={() => {
          setSelectedIndex(null);
          setResourceView(null);
        }}
      />
    );
  }, [selectedIndex, resources]);

  if (!postFullState) return null;

  const userName = `${post.user.name} ${post?.user.surname}`;
  const isTeacher = checkIfUserIsTeacher
    ? checkIfUserIsTeacher(post.user.uuid)
    : false;
  // TODO remove this with the store after callo rework
  const commentDisabled = getActiveGroup().shouldDisableWallComment();

  return (
    <>
      <S.Post pinned={pinned && POST_CONTEXT.WALL === context}>
        <Box display="flex">
          <UserProfileTooltip user={post?.user}>
            <Box display="flex" flex={1}>
              <Avatar
                url={post.user.avatar?.medium}
                alt={userName}
                size="m"
                theme={whiteTheme}
                rounded
              />
              <S.WrapperAuthor>
                <S.WrapperUser>
                  <S.CustomHeading level="4">{userName}</S.CustomHeading>
                  {isTeacher ? (
                    <TranslatedTooltip tooltipString="teacher_badge_tooltip_label">
                      <S.TeacherIcon
                        icon="teacherRibbon"
                        height="16"
                        width="16"
                        color={primaryColor[500]}
                      />
                    </TranslatedTooltip>
                  ) : null}
                </S.WrapperUser>
                <S.GreyText type="formSmallCaption">
                  {format(post.createdAt * 1000, "dd/MM/yyyy HH:mm")}
                </S.GreyText>
              </S.WrapperAuthor>
            </Box>
          </UserProfileTooltip>

          {pinned ? (
            <S.TooltipWrapper>
              <TranslatedTooltip placement="bottom" tooltipString="in evidenza">
                <Icon icon="pin" width="20" height="20" />
              </TranslatedTooltip>
            </S.TooltipWrapper>
          ) : null}
          <DotsMenu
            id={`postDotsMenu-${post.id}`}
            items={dropdownOptions}
            alignRight
          />
        </Box>
        <S.Body>
          <CollapsibleTextBox
            text={parseMentions(postText, primaryColor[500])}
            lineNumber={6}
            textType="formSubtitle"
          />
          {resources.length ? (
            // TODO update this legacy resource viewer
            <ResourceCarousel
              resources={resources}
              setSelectedIndex={(index: number) => setSelectedIndex(index)}
            />
          ) : null}
          <Box display="flex" flexDirection={mobile ? "column" : "row"}>
            <ReactionList
              reactionsCounter={reactions}
              entity={REACTABLE_ENTITY.POST}
              entityId={post.id as unknown as string}
              onClick={type => {
                if (type === reactions?.reactionLoggedUser?.reactionType) {
                  removeOldReaction({ post: postFullState });
                  return;
                }
                addNewReaction({ type, post: postFullState });
              }}
            />
            {/* Show cta only if there's more than 1 comments in the post */}
            {postFullState.commentsCount > 1 ? (
              <S.ShowAllCommentCta type="button" onClick={onShowCommentsClick}>
                <S.GreyText type="ctaTertiary">
                  {translate("wall_post_show_all_comments_cta", {
                    commentsCount: postFullState.commentsCount
                  })}
                </S.GreyText>
              </S.ShowAllCommentCta>
            ) : null}
          </Box>
        </S.Body>

        <S.PostActionWrapper>
          <span ref={emojiRef}>
            <AnimatePresence>
              {isOpenEmojiPicker && (
                <S.AnimatedWrapper
                  initial={{ top: 0, scale: 0, opacity: 0 }}
                  animate={{ top: -56, scale: 1, opacity: 1 }}
                  exit={{ top: 0, scale: 0, opacity: 0 }}
                >
                  <ReactionPicker
                    onSelect={type => {
                      addNewReaction({ type, post: postFullState });
                      setIsOpenEmojiPicker(false);
                    }}
                  />
                </S.AnimatedWrapper>
              )}
            </AnimatePresence>

            <PostActionButton
              onClick={() => {
                setIsOpenEmojiPicker(val => !val);
              }}
              isActive={isOpenEmojiPicker}
              iconName="emojiPlus"
              label={translate("post_action_button_reaction_label")}
            />
          </span>
          <PostActionButton
            onClick={onCommentClick}
            iconName="chat"
            label={translate("post_action_button_comment_label")}
          />
        </S.PostActionWrapper>
        {!commentDisabled ? (
          <CommentList
            parentId={post.id}
            shouldFetch={postFullState.commentsCount > 0}
            isPreview={context === POST_CONTEXT.WALL}
            onNewComment={() => onNewComment({ post: postFullState })}
            onDeleteComment={(nestedCommentNumber?: number) =>
              onDeleteComment({ post: postFullState, nestedCommentNumber })
            }
            onPreviewClick={
              context === POST_CONTEXT.WALL ? onCommentClick : null
            }
            newCommentInputRef={inputRef}
            context={COMMENT_CONTEXT.POST}
          />
        ) : null}
      </S.Post>
      {resourceView && <ResourceViewPortal resourceView={resourceView} />}
    </>
  );
};

export default observer(Post);
