import { colors } from "@arcadia/design-system";
import { __HOME_BACKGROUND__ } from "src/js/settings/settingsImage";
import styled, { css } from "styled-components";
import { SHomeEmptyLayout } from "./HomeEmptyState.types";

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url(${__HOME_BACKGROUND__});
`;

export const Header = styled.div`
  grid-area: header;
  position: relative;
  z-index: 1;

  & > div {
    @media screen and (max-width: 767px) {
      margin: 0px 16px 8px 16px;
    }
  }
`;

export const MainColumn = styled.div`
  grid-area: main;
  position: relative;
  z-index: 1;
`;

export const SidebarColumn = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 332px;
  z-index: 1;
  > button {
    width: 100%;
  }
`;

export const HomeEmptyGrid = styled.div`
  display: grid;
  padding-top: 48px;
  gap: 16px;
  grid-auto-flow: row;
  margin: auto;
  grid-template-areas:
    "header header"
    "main sidebar";
  max-width: 950px;
`;

export const HomeEmptyLayout = styled.div<SHomeEmptyLayout>`
  padding: 16px;
  width: 100%;
  margin-top: 54px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 80px 0 60px;
      background-image: none;
      padding: 16px 0 0 0;
      height: 100%;
      ${HomeEmptyGrid} {
        padding-top: 0px;
        grid-template-areas: "header" "main" "sidebar";
      }
      ${SidebarColumn} {
        padding: 16px;
        background: ${colors.grey[1000]};
        width: 100%;
      }
    `};
`;
