import { DotsMenu } from "src/js/components/DotsMenu";
import { ResourcePicker } from "src/js/components/ResourcePicker";
import styled, { createGlobalStyle } from "styled-components";

export const CustomResourcePicker = styled(ResourcePicker)`
  width: max-content;
  background: ${({ theme }) => theme.colors.grey[1000]};
  max-height: calc(70vh - 217px);
  @media screen and (max-width: 767px) {
    max-height: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    width: 100%;
  }
`;

export const CustomDotsMenu = styled(DotsMenu)`
  position: relative;
  border: none;
  padding: 0;
  width: 28px;
  height: 28px;
  flex: 0 0 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const GlobalStyleAttachmentDotsMenu = createGlobalStyle`
  #text-editor-attachments-list {
    max-width: fit-content !important;
    border-radius: 4px !important; 
    left: 0px !important; 
    overflow: hidden !important;
  }
`;
