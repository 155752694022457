import styled from "styled-components";

export const SpecificErrorContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.specificerror.text};
`;

export const SpecificErrorIcon = styled.div`
  display: flex;
  align-items: start;
`;

export const SpecificErrorMessage = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: start;
`;
