import styled from "styled-components";

export const TopBannerDemoSpace = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.sizes.topBannerHeight};
  background-color: ${({ theme }) => theme.colors.yellow[500]};
  padding: 12px 24px;
  z-index: ${({ theme }) => theme.zIndex.topBanner};
  display: flex;
  justify-content: center;
  z-index: 900;
  a {
    height: 20px;
    border: none;
    padding: 0;
    span {
      font-size: 15px;
      text-decoration: none;
      text-underline-offset: initial;
      border-bottom: 1px solid ${({ theme }) => theme.colors.basicBlack};
    }
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 60px;
    height: ${({ theme }) => theme.sizes.defaultMobileHeaderHeight};
    align-items: center;
  }
`;

export const BookDemo = styled.div`
  span {
    cursor: pointer;
    border-bottom: 1px solid;
  }
`;

export const TextWrapper = styled.div`
  font-size: 15px !important;
  display: flex;
  gap: 16px;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: block;
    padding: 0 40px 0 0;
    text-align: center;
    margin-top: 8px;
    & a {
      height: initial;
      display: inline-block;
      :last-child {
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
    & span {
      display: inline-block;
    }
    & span:last-child {
      margin-left: 20px;
    }
    & span:nth-child(1) {
      color: ${({ theme }) => theme.colors.basicBlack};
      display: block;
      margin-bottom: 8px;
      margin-left: 20px;
    }
    & div {
      display: inline-block;
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 24px;
`;
