import React from "react";
import { Text } from "@arcadia/design-system";
import PropTypes from "prop-types";
import * as S from "./PlanLabel.styles";

const PlanLabel = ({ text, label }) => {
  return (
    <S.Wrapper>
      {text}
      <S.Label>
        <Text type="label">{label}</Text>
      </S.Label>
    </S.Wrapper>
  );
};

PlanLabel.propTypes = {
  text: PropTypes.node,
  label: PropTypes.string
};

PlanLabel.defaultProps = {
  text: null,
  label: ""
};

export default PlanLabel;
