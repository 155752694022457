import {
  AvatarWithInitials,
  Box,
  Button,
  FabButton,
  Heading,
  Text
} from "@arcadia/design-system";
import React, { useEffect } from "react";
import { FadeableWrapper } from "src/js/components/FadeableWrapper";
import { UserPermissionPanel } from "src/js/components/UserPermissionPanel";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import * as S from "./SpacesSettingsPermissions.styles";

const SpacesSettingsPermissions = ({
  usersList = [],
  onAddClick = () => {},
  onEditClick = () => {},
  space,
  tabButtons
}) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  useEffect(() => {
    _trackEvent("SpaceSettingsUsage", "StaffUserAccess");
  }, []);

  const usersBlock = usersList.map(user => (
    <UserPermissionPanel
      key={user.id}
      firstName={user.name}
      lastName={user.surname}
      permission={user.role}
      email={user.email}
      createdAt={user.createdAt}
      onEditClick={() => onEditClick(user)}
    />
  ));

  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
      <MainContainer paddingTop="10px" paddingTopMobile="72px">
        <S.AvatarWrapper>
          <AvatarWithInitials
            theme={whiteTheme}
            alt={space?.name}
            url={space?.badge?.small}
            name={space?.name || ""}
            avatarColor={space?.defaultBadgeColor}
            size="big"
          />
        </S.AvatarWrapper>
        {tabButtons}
        <FadeableWrapper key="settings-container">
          <S.UserListTitleContainer>
            <S.HeaderInfoWrap>
              <S.TitleWrapper>
                <Heading level="1">
                  <Translate text="spaces_settings_permissions_users_title" />
                </Heading>
                <S.UsersCounter>{usersList.length}</S.UsersCounter>
              </S.TitleWrapper>
              <S.DescriptionContainer>
                <Text type="formDescription">
                  <Translate
                    text="spaces_settings_permissions_description"
                    stringVariables={{
                      spaceName: space?.name
                    }}
                  />
                </Text>
              </S.DescriptionContainer>
            </S.HeaderInfoWrap>
            {!isLayoutModeMobile && (
              <S.ButtonWrapper>
                <Button
                  onClick={onAddClick}
                  variant="secondary"
                  theme={greyTheme}
                  withIcon={{
                    icon: "plus",
                    positionIcon: "left",
                    heightIcon: "16",
                    widthIcon: "16",
                    viewbox: "0 0 24 24"
                  }}
                >
                  <Translate text="add_button_label" />
                </Button>
              </S.ButtonWrapper>
            )}
          </S.UserListTitleContainer>
          <S.UserListWrapper>
            <S.UserListHeader>
              <S.Column large>
                <S.UserListHeaderItem>
                  <Text type="label">
                    <Translate text="space_settings_user_list_name_title" />
                  </Text>
                </S.UserListHeaderItem>
              </S.Column>
              <S.Column large>
                <S.UserListHeaderItem>
                  <Text type="label">
                    <Translate text="space_settings_user_list_permissions_title" />
                  </Text>
                </S.UserListHeaderItem>
              </S.Column>
              <S.Column>
                <S.UserListHeaderItem>
                  <Text type="label">
                    <Translate text="nomination_date_title" />
                  </Text>
                </S.UserListHeaderItem>
              </S.Column>
              <S.Column>
                <S.UserListHeaderItem>
                  <Text type="label">
                    <Translate text="space_settings_user_list_actions_title" />
                  </Text>
                </S.UserListHeaderItem>
              </S.Column>
            </S.UserListHeader>
            <S.UserList>{usersBlock}</S.UserList>
          </S.UserListWrapper>
        </FadeableWrapper>
        {isLayoutModeMobile && (
          <FabButton
            onClick={onAddClick}
            theme={whiteTheme}
            variant="primary"
            icon="plus"
          />
        )}
      </MainContainer>
    </Box>
  );
};

export default SpacesSettingsPermissions;
