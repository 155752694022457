import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { setupAjaxQuery } from "src/legacy/modules/ajax";

const SetupAjaxQuery = () => {
  const { AuthStore } = useStores();

  if (!AuthStore.userIsLogged) return null;

  setupAjaxQuery();

  return null;
};

export default observer(SetupAjaxQuery);
