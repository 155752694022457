import { useState } from "react";
import { useInfiniteScrollFetcher } from "src/js/hooks";
import { structuredClone } from "src/js/modules/commonFunction";
import {
  updateGroupNotificationSettings,
  updateSpaceNotificationSettings
} from "src/js/repository/noticeSettingsRepository";
import { fetchSpaceGroups } from "src/js/repository/spacesRepository";
import { BasePaginationParams, GroupInSpaceModel } from "src/js/types";
import { NotificationSettingsModel } from "src/js/types/models/Notification";
import { UpdateAllGroupsType, UpdateSingleGroupType } from "../SpaceCard/types";

const useSpaceGroups = () => {
  const [groupList, setGroupList] = useState<GroupInSpaceModel[]>([]);
  const [notificationsPreference, setNotificationsPreference] =
    useState<NotificationSettingsModel>({
      appPreference: undefined,
      emailPreference: undefined
    });

  const { fetch, isLoading, hasMoreResults, fetchNextPage } =
    useInfiniteScrollFetcher<
      GroupInSpaceModel[],
      BasePaginationParams & { spaceId: string }
    >(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async ({ page: _, offset, spaceId }) => {
        if (!spaceId) return [];

        const res = await fetchSpaceGroups({
          spaceId,
          limit: 10,
          offset
        });

        return res.results;
      },
      {
        limit: 10,
        lazy: true,
        onSuccess: fetchedGroups => {
          setGroupList([...groupList, ...fetchedGroups]);
        }
      }
    );

  const updateAllGroups = ({
    spaceId,
    emailPreference,
    appPreference
  }: UpdateAllGroupsType) => {
    const actualUpdate = appPreference
      ? { appPreference }
      : { emailPreference };

    updateSpaceNotificationSettings({ spaceId, ...actualUpdate }).then(() => {
      setNotificationsPreference({
        ...notificationsPreference,
        ...actualUpdate
      });

      if (!groupList.length) return;

      const updatedGroupList = groupList.map(group => {
        const newGroup = { ...group };
        newGroup.notificationSettingsPreference = {
          ...newGroup.notificationSettingsPreference,
          ...actualUpdate
        };

        return newGroup;
      });

      setGroupList(updatedGroupList);
    });
  };

  const updateSingleGroupPreference = ({
    groupId,
    emailPreference,
    appPreference
  }: UpdateSingleGroupType) => {
    updateGroupNotificationSettings({
      groupId,
      emailPreference,
      appPreference
    }).then(() => {
      const actualUpdate = appPreference
        ? { appPreference }
        : { emailPreference };

      const updatedList = structuredClone(groupList);
      const groupIndex = updatedList.findIndex(
        ({ group }) => group.id === groupId
      );

      updatedList[groupIndex].notificationSettingsPreference = {
        ...updatedList[groupIndex].notificationSettingsPreference,
        ...actualUpdate
      };

      setGroupList(updatedList);
    });
  };

  return {
    fetch,
    groupList,
    setGroupList,
    fetchNextPage,
    notificationsPreference,
    updateAllGroups,
    updateSingleGroupPreference,
    isLoading,
    hasMoreResults
  };
};

export default useSpaceGroups;
