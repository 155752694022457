import React from "react";
import { IconName } from "@arcadia/design-system";
import { Editor } from "@tiptap/core";
import { Level } from "@tiptap/extension-heading";
import { TranslationKeys } from "src/js/translation";
import { EditorExtension, EditorExtensionType } from "./BaseTextEditor.types";
import TextFormattingButton from "./components/TextFormattingButton";
import StandardExtensionButton from "./components/StandardExtensionButton";
import LinkButton from "./components/LinkButton";
import { AITransformer } from "./components/AITransformer";
import { SubMenu } from "./components/SubMenu";

export const getIconFromExtension = (
  extension: EditorExtensionType
): IconName => {
  switch (extension) {
    case EditorExtension.BulletList:
      return "unorderedList";
    case EditorExtension.OrderedList:
      return "orderedList";
    case EditorExtension.Italic:
      return "italic";
    case EditorExtension.Strike:
      return "strikethrough";
    case EditorExtension.BlockQuote:
      return "quote";
    case EditorExtension.Link:
      return "linkDiagonal";
    case EditorExtension.Bold:
    default:
      return "bold";
  }
};

export const getIconFromTextFormat = (
  format: "heading" | "text",
  level?: Level
): IconName => {
  if (format === "text") return "textFormat";
  switch (level) {
    case 1:
      return "heading1";
    case 2:
      return "heading2";
    case 3:
    default:
      return "heading3";
  }
};

export const getTitleAndDescriptionFromTextFormat = (
  format: "heading" | "text",
  level?: Level
): { title: TranslationKeys; description: TranslationKeys } => {
  if (format === "text")
    return {
      title: "text_editor_menu_paragraph_option_title",
      description: "text_editor_menu_paragraph_option_body"
    };
  switch (level) {
    case 1:
      return {
        title: "text_editor_menu_heading_1_option_title",
        description: "text_editor_menu_heading_1_option_body"
      };
    case 2:
      return {
        title: "text_editor_menu_heading_2_option_title",
        description: "text_editor_menu_heading_2_option_body"
      };
    case 3:
    default:
      return {
        title: "text_editor_menu_heading_3_option_title",
        description: "text_editor_menu_heading_3_option_body"
      };
  }
};

export const getOnClickFromExtension = (
  extension: EditorExtensionType,
  editor: Editor
): (() => void) => {
  switch (extension) {
    case EditorExtension.BulletList:
      return () => editor.chain().focus().toggleBulletList().run();
    case EditorExtension.OrderedList:
      return () => editor.chain().focus().toggleOrderedList().run();
    case EditorExtension.Italic:
      return () => editor.chain().focus().toggleItalic().run();
    case EditorExtension.Strike:
      return () => editor.chain().focus().toggleStrike().run();
    case EditorExtension.BlockQuote:
      return () => editor.chain().focus().toggleBlockquote().run();
    case EditorExtension.Subscript:
      return () => editor.chain().focus().toggleSubscript().run();
    case EditorExtension.Superscript:
      return () => editor.chain().focus().toggleSuperscript().run();
    case EditorExtension.Underline:
      return () => editor.chain().focus().toggleUnderline().run();
    case EditorExtension.Bold:
    default:
      return () => editor.chain().focus().toggleBold().run();
  }
};

export const renderButtonFromExtension = (
  editor: Editor,
  extension: EditorExtensionType
) => {
  switch (extension) {
    case EditorExtension.Link:
      return <LinkButton editor={editor} />;
    case EditorExtension.TextFormatting:
      return <TextFormattingButton editor={editor} />;
    case EditorExtension.Submenu:
      return <SubMenu editor={editor} />;
    case EditorExtension.AI:
      return <AITransformer editor={editor} />;
    default:
      return <StandardExtensionButton editor={editor} extension={extension} />;
  }
};
