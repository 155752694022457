import {
  Icon,
  ImageViewer,
  SidebarGroupItem,
  Text
} from "@arcadia/design-system";
import { navigateTo } from "Legacy/modules/history";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { NOTIFICATION_SETTINGS_TYPE } from "src/js/settings/settingsNotice";
import { useTranslation } from "src/js/translation";
import { AppPreference } from "src/js/types/models/Notification";
import { useTheme } from "styled-components";
import * as S from "./SidebarGroupList.styles";

const MAX_LIMIT_VIEWABLE_GROUP = 8;

const getNotificationsNumber = ({
  appPreference,
  notificationsNumber
}: {
  appPreference: AppPreference;
  notificationsNumber: number;
}): number => {
  if (!appPreference) return notificationsNumber;
  if (appPreference === NOTIFICATION_SETTINGS_TYPE.NONE) return -1;

  if (!notificationsNumber) return 0;

  return notificationsNumber;
};

const SidebarGroupList = () => {
  const {
    SpaceStore: { activeSpaceSlug, activeSpaceId },
    GroupStore: { activeGroup },
    SpaceGroupListStore: {
      activeSpaceGroups,
      fetchActiveSpaceGroupsAll,
      activeSpaceHasMoreGroups
    }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const [limitViewableGroups, setLimitViewableGroups] = useState(true);

  useEffect(() => {
    setLimitViewableGroups(true);
  }, [activeSpaceSlug, activeSpaceId]);

  const handleLimitGroups = () => {
    if (activeSpaceHasMoreGroups) {
      fetchActiveSpaceGroupsAll();
    }
    setLimitViewableGroups(prevLimitViewableGroups => !prevLimitViewableGroups);
  };

  const isButtonAllGroupsVisible =
    activeSpaceHasMoreGroups ||
    activeSpaceGroups.length > MAX_LIMIT_VIEWABLE_GROUP;
  const viewableGroup = limitViewableGroups
    ? activeSpaceGroups.slice(0, MAX_LIMIT_VIEWABLE_GROUP)
    : activeSpaceGroups;

  const listElement = viewableGroup.map(element => {
    const isLoading = element?.group?.cloningCompleted === false;
    return (
      <SidebarGroupItem
        key={`sidebar-group-${element.group?.id}`}
        groupName={element.group?.name}
        onClick={() =>
          navigateTo(createUrl("wall", { group_id: element.group?.id }))
        }
        id={`sidebar-group-${element.group?.id}`}
        isSelected={element.group?.id === activeGroup?.id}
        theme={whiteTheme}
        isMuted={
          element.notificationSettingsPreference?.appPreference ===
            NOTIFICATION_SETTINGS_TYPE.NONE || false
        }
        notification={
          isLoading
            ? 0
            : getNotificationsNumber({
                appPreference:
                  element.notificationSettingsPreference?.appPreference,
                notificationsNumber: element.unreadNotificationsCount
              })
        }
        badge={
          <ImageViewer
            alt="group badge"
            url={
              element.group?.cover?.small || element.group?.cover?.originalUrl
            }
            fallbackImage={__GROUP_COVER_DEFAULT__}
          />
        }
        isLoading={isLoading}
      />
    );
  });

  return (
    <>
      {listElement}
      {isButtonAllGroupsVisible && (
        <S.ButtonAllGroups onClick={handleLimitGroups}>
          <S.ButtonAllGroupsIconWrap>
            <Icon
              icon={limitViewableGroups ? "chevronDown" : "chevronUp"}
              height={18}
              width={18}
            />
          </S.ButtonAllGroupsIconWrap>
          <Text type="formSubtitle">
            {translate(
              limitViewableGroups
                ? "sidebar_cta_all_groups"
                : "sidebar_cta_collapse_groups"
            )}
          </Text>
        </S.ButtonAllGroups>
      )}
    </>
  );
};

export default observer(SidebarGroupList);
