import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const BadgeOptionalFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  margin-bottom: 96px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  & > button {
    flex: 1;
  }
`;

export const DescriptionText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
