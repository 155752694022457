import { Button, Heading, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { LanguageSelector } from "src/js/components/global/LanguageSelector";
import { useOnboarding } from "src/js/hooks";
import useLanguageSwitch from "src/js/hooks/useLanguageSwitch";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { openExternalLink } from "src/js/modules/commonFunction";
import {
  __EXAMPLE_BACKGROUND_PATTERN__,
  __EXAMPLE_SPACE_CREATION_IMAGE__,
  __TESTIMONIAL_BACKGROUND_1__,
  __TESTIMONIAL_BRAND_1__,
  __TESTIMONIAL_IMAGE_1__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { EventDomain, OnboardingEvent } from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { useTheme } from "styled-components";
import Example from "../Example";
import LogoutBlock from "../LogoutBlock";
import Testimonial from "../Testimonial";
import * as S from "./Resume.styles";
import { useResumeOnboarding } from "./hook";

const Resume = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const { flowSwitcher } = useOnboarding();
  const { changeLanguage } = useLanguageSwitch({});

  const {
    isGroupCreateSelectionStep,
    isGroupCreationStep,
    isSpaceCreationStep,
    isLogoutVisible,
    setupTracking
  } = useResumeOnboarding();

  const goToNextStep = (onboardingTemplateWeSchool: boolean) => {
    setupTracking(onboardingTemplateWeSchool);
    flowSwitcher(USER_TYPE.BUSINESS, onboardingTemplateWeSchool);
  };

  const pageContainer = (
    <S.Container>
      <S.SelectionContainer>
        <S.Section>
          <Heading level="1">
            {isGroupCreateSelectionStep()
              ? translate("onboarding_group_create_selection_page_title")
              : translate("onboarding_resume_page_title")}
          </Heading>
          <Text>
            {isGroupCreateSelectionStep()
              ? translate("onboarding_group_create_selection_page_text")
              : translate("onboarding_resume_page_text")}
          </Text>
        </S.Section>
        <S.ActionsWrapper>
          <Button
            variant="primary"
            theme={whiteTheme}
            fullWidth
            onClick={() => goToNextStep(false)}
          >
            {isSpaceCreationStep()
              ? translate("onboarding_resume_create_space_submit")
              : translate("onboarding_resume_create_group_submit")}
          </Button>
          {isGroupCreationStep() ? (
            <Button
              variant="secondary"
              theme={whiteTheme}
              fullWidth
              onClick={() => goToNextStep(true)}
            >
              {translate("onboarding_resume_create_template_group_submit")}
            </Button>
          ) : null}
          {isLogoutVisible() ? (
            <Button
              variant="secondary"
              theme={whiteTheme}
              fullWidth
              onClick={() => {
                openExternalLink("https://www.weschool.com/get-started/");
                _trackEvent(EventDomain.Onboarding, OnboardingEvent.BookDemo);
              }}
            >
              {translate("onboarding_resume_demo_action_button")}
            </Button>
          ) : null}
        </S.ActionsWrapper>
      </S.SelectionContainer>
      {isLogoutVisible() ? <LogoutBlock /> : null}
    </S.Container>
  );

  const standardSideContent = (
    <Testimonial
      background={__TESTIMONIAL_BACKGROUND_1__}
      image={__TESTIMONIAL_IMAGE_1__}
      text={translate("onboarding_testimonial_welcome")}
      author={translate("onboarding_testimonial_welcome_author")}
      brand={__TESTIMONIAL_BRAND_1__}
    />
  );

  const groupCreateSelectionSideContent = (
    <Example
      background={__EXAMPLE_BACKGROUND_PATTERN__}
      image={__EXAMPLE_SPACE_CREATION_IMAGE__}
      imagePosition="right: 0"
      description={translate("onboarding_example_space_creation_description")}
    />
  );

  return (
    <OnboardingLayout
      languageSelector={<LanguageSelector successCallback={changeLanguage} />}
      mainContent={pageContainer}
      sideContent={
        isGroupCreateSelectionStep()
          ? groupCreateSelectionSideContent
          : standardSideContent
      }
      mobileCentered
    />
  );
};

export default observer(Resume);
