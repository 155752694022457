import styled from "styled-components";

export const TooltipContainer = styled.div`
  min-width: 150px;
  background-color: ${({ theme }) =>
    theme.whiteTheme.analyticstooltip.background};
  border-radius: 6px;
  font-size: 12px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

export const TooltipYValue = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.whiteTheme.analyticstooltip.yValue.color};

  @media (max-width: 767px) {
    font-size: 36px;
  }
`;

export const TooltipSubject = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.whiteTheme.analyticstooltip.subject.color};
`;

export const TooltipXValue = styled.div`
  margin-top: 10px;
  width: 100%;
  font-size: 10px;
  color: ${({ theme }) => theme.whiteTheme.analyticstooltip.xValue.color};
`;
