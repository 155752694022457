import styled, { css } from "styled-components";

export const DatePickerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  padding-right: 10px;
  min-width: 180px;
  position: relative;

  .mbsc-popup-wrapper-ctx {
    width: auto;
    overflow: visible;
    height: 368px;
  }

  .mbsc-font {
    font-size: 26px;
    line-height: 32px;
    font-family: "WeSchoolSans" !important;
  }

  .mbsc-textfield-inner-box {
    background-color: unset;
  }

  .mbsc-hover,
  .mbsc-focus {
    background: unset !important;
  }

  .mbsc-material.mbsc-textfield-ripple {
    background: unset;
  }

  .mbsc-material.mbsc-textfield-wrapper {
    margin: 0;
    flex: 1;
  }

  .mbsc-material.mbsc-textfield {
    height: unset;
    border-bottom: unset;
    width: auto;
    cursor: pointer;
    font-weight: 600;
    padding: 0;
  }

  .mbsc-calendar-button .mbsc-icon {
    font-size: 0.6em !important;
  }

  // override mobiscroll time picker styles to match design
  .ws-single-month-picker {
    .mbsc-popup {
      max-width: 100% !important;
    }
    .mbsc-popup-anchored {
      left: 0px !important;
      top: 40px !important;
    }
    .mbsc-calendar-button .mbsc-icon,
    .mbsc-calendar-button .mbsc-ripple {
      display: inline-block !important;
    }

    // hide chevron on mobiscroll calendar header
    .mbsc-calendar-title-wrapper .mbsc-icon {
      display: none !important;
    }

    .mbsc-calendar-month-text {
      margin: 0.5em 0;
    }
    .mbsc-calendar-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .mbsc-calendar-body-inner {
      margin: unset !important;
      margin-left: -1px !important;
    }
    .mbsc-material.mbsc-calendar-cell-text {
      border-radius: 2px !important;
      font-weight: 600;
      font-size: 12px;
      padding: 5px;
      line-height: 15px;
      height: 100%;
    }

    &.mbsc-material .mbsc-selected .mbsc-calendar-cell-text {
      color: ${({ theme }) => theme.cell.selected.color} !important;
      background: transparent !important;
      border: transparent !important;
    }

    .mbsc-calendar .mbsc-calendar-body .mbsc-calendar-body-inner {
      &:before,
      &:after {
        display: none;
      }
    }

    .mbsc-popup-overlay.mbsc-popup-overlay-anchored {
      position: fixed;
    }
  }
`;

export const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 72px;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;
