import { Heading, Icon, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ThreadToolbarBellIcon = styled(Icon).attrs(props => ({
  ...props,
  icon: "bellEmptyCrossed",
  width: 16,
  height: 16
}))`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const ThreadToolbarTitle = styled(Heading).attrs(props => ({
  ...props,
  level: 5
}))`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ThreadToolbarSubtitle = styled(Text).attrs(props => ({
  ...props,
  type: "formSmallCaption"
}))`
  color: ${({ theme }) => theme.colors.grey[400]};
`;

export const ThreadToolbarActions = styled.div`
  padding-right: 32px;
`;

export const ThreadToolbarContent = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  height: 100%;
  cursor: pointer;
`;

export const ThreadToolbarWrapper = styled.div`
  display: flex;
  height: 68px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  align-items: center;
`;

/** Mobile */

export const ThreadToolbarMobileBackBtn = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  margin: 0 8px;
  padding: 0 8px;
  height: 100%;
`;

export const ThreadToolbarMobileTitle = styled(Text).attrs(props => ({
  ...props,
  type: "formTitle"
}))`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  width: 100%;
`;

export const ThreadToolbarMobileText = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ThreadToolbarMobileContent = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  padding-right: 16px;
  overflow: hidden;
`;

export const ThreadToolbarMobileWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 68px;
  width: 100%;
  z-index: 1;
  display: flex;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  align-items: center;
  overflow: hidden;
`;
