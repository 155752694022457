import { Collapsable, Icon, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

type isOnboardingFlow = {
  onboardingFlow?: boolean;
};

export const CollapsibleContainer = styled(Collapsable)`
  & > div:first-child {
    margin-bottom: 24px;
  }
`;

export const Container = styled.div`
  text-align: center;
  & > div:first-child {
    margin-bottom: 24px;
  }
`;

export const LabelText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const InputWrapper = styled.div<{ isFocused: boolean }>`
  width: 100%;
  min-height: 120px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1.5px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.primaryColor[500] : theme.colors.grey[800]};
  background: ${({ theme }) => theme.colors.coldGrey[900]};
  cursor: text;

  & > div:last-child {
    flex: 1;
    min-width: 144px;

    & > div {
      margin-top: 0px;

      & > input {
        padding: 0px 10px;
        height: 36px;

        &:hover {
          border: none;
          background: inherit;
        }

        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 8px 0px 0px 8px;
`;

export const Chip = styled.div`
  min-width: 28px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  color: ${({ theme }) => theme.colors.grey[1000]};
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;

export const Input = styled.input`
  border: none;
  outline: none;
  min-height: 28px;
  flex: 1;
`;

export const CloseIcon = styled(Icon)`
  display: flex;
  cursor: pointer;
`;

export const SuccessFeedback = styled.div`
  & > div {
    width: auto;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & > div {
      max-width: initial;
    }
  }
`;

export const ButtonsContainer = styled.div<isOnboardingFlow>`
  display: flex;
  gap: 12px;
  ${({ onboardingFlow }) =>
    onboardingFlow &&
    css`
      width: 100%;
      & > button {
        width: 50%;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        & > button {
          width: 100%;
        }
      }
    `}
`;
