import styled from "styled-components";

export const AICustomPromptWrapper = styled.div<{ width?: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border: 1px solid ${({ theme }) => theme.colors.coldGrey[800]};
  border-radius: 8px;
`;

export const CustomPromptInput = styled.input`
  width: 100%;
  background: none;
  box-sizing: border-box;
  border: none;
  font-size: 16px;
  line-height: 20px;
  /* TODO: Add color to DS */
  color: #2d2d86;
  width: 100%;
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.coldGrey[700]};
  }
`;

export const InputRow = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.coldGrey[500]};
  padding: 16px;
`;

export const RoundButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[1000]};
  background-color: ${({ theme }) => theme.colors.violet[501]};
  border: none;
  outline: none;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.violet[300]};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.violet[700]};
  }
`;
