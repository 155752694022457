import {
  Box,
  Button,
  ModalSizeType,
  VariantType,
  scrollbarMixin
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import SelectableIconCardList, {
  SelectableIconCardItem
} from "src/js/components/SelectableList/SelectableIconCardList/SelectableIconCardList";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTemplates } from "src/js/query/spaces";
import { useTranslation } from "src/js/translation";
import { EventDomain, GroupDetailsModel, TemplatingEvent } from "src/js/types";
import styled, { DefaultTheme } from "styled-components";
import { TemplateDescription } from "../TemplateDescription";

export const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 767px;
  max-height: 500px;
  align-items: center;
  justify-content: center;
`;

export const StepWrapper = styled.div`
  padding: 24px;
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 1024px;
  max-height: 640px;
  @media (max-width: 767px) {
    max-height: unset;
    padding: 16px 16px 80px;
  }
`;

export const ScrollableList = styled.div`
  height: 100%;
  max-height: calc(90vh - 244px);
  padding-right: 12px;
  overflow: auto;
  ${scrollbarMixin(VariantType.White)}
  @media (max-width: 767px) {
    max-height: unset;
    padding-right: unset;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
  @media (max-width: 767px) {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 32px;
    margin: auto;
  }
`;

export type TemplateWeschoolSelectionProps = {
  theme: DefaultTheme;
  onSubmit?: (template: GroupDetailsModel) => void;
  onGoBack?: () => void;
};

const TemplateWeschoolSelection = ({
  theme,
  onSubmit,
  onGoBack = () => {}
}: TemplateWeschoolSelectionProps) => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    ModalStore: { closeModal },
    SpaceStore: { activeSpaceId }
  } = useStores();
  const { trackVirtualView } = useViewTracking();

  const { data: templatesData } = useTemplates({
    spaceId: activeSpaceId
  });
  const templates = templatesData?.weschoolTemplates;

  const [showTemplateDescription, setShowTemplateDescription] = useState(false);

  const [selectedGroupTemplateId, setSelectedGroupTemplateId] =
    useState<number>();

  useEffect(() => {
    _trackEvent(
      EventDomain.Templating,
      TemplatingEvent.WeSchoolTemplateSelection
    );
    trackVirtualView(
      WeSchoolVirtualViewDescriptions.Groups.Templates.WeSchoolTemplateSelection
    );
  }, []);

  useEffect(() => {
    if (!templates.length) return;
    setSelectedGroupTemplateId(templates[0]?.group?.id);
  }, [templates]);

  const selectedTemplate = useMemo(
    () =>
      templates?.find(
        template => template?.group?.id === selectedGroupTemplateId
      ),
    [templates, selectedGroupTemplateId]
  );

  const listSelectableIconCard: SelectableIconCardItem[] = templates.map(
    template => ({
      id: template?.group?.id,
      description: translate(template?.templateInfo?.description),
      title: translate(template?.templateInfo?.title),
      icon: template?.templateInfo?.icon
    })
  );

  return (
    <ModalLayout
      labels={{
        title: translate(
          isLayoutModeMobile
            ? "template_weschool_selection_title_mobile"
            : "template_weschool_selection_title"
        )
      }}
      progressPercentage={50}
      closeModal={closeModal}
      mobileProps={{
        withDrag: false,
        ...(!onGoBack
          ? {}
          : {
              closeLabel: translate("back"),
              onClose: onGoBack
            })
      }}
      desktopProps={{ hasAdaptiveWidth: true, size: ModalSizeType.Big }}
    >
      <StepWrapper>
        <Box display="flex" flexDirection="column" flex="1 1 0">
          <ScrollableList>
            <SelectableIconCardList
              selected={selectedGroupTemplateId}
              list={listSelectableIconCard}
              onChange={el => {
                setSelectedGroupTemplateId(el.id);
              }}
              labels={{
                footerCta: translate("preview")
              }}
              handleFooterCta={() => setShowTemplateDescription(true)}
            />
          </ScrollableList>
          <FooterWrapper>
            {!isLayoutModeMobile && onGoBack ? (
              <Button
                variant="secondary"
                type="button"
                theme={theme}
                fullWidth
                onClick={onGoBack}
              >
                {translate("back")}
              </Button>
            ) : undefined}
            <Button
              variant="primary"
              type="button"
              theme={theme}
              fullWidth
              onClick={() => onSubmit(selectedTemplate?.group)}
            >
              {translate("template_weschool_selection_submit_cta")}
            </Button>
          </FooterWrapper>
        </Box>
        {!isLayoutModeMobile || showTemplateDescription ? (
          <TemplateDescription
            cover={selectedTemplate?.templateInfo?.cover}
            sections={selectedTemplate?.templateInfo?.sections}
            templateTitle={translate(
              selectedTemplate?.templateInfo?.templateTitle
            )}
            templateAbstract={translate(
              selectedTemplate?.templateInfo?.templateAbstract
            )}
            onClose={() => setShowTemplateDescription(false)}
          />
        ) : null}
      </StepWrapper>
    </ModalLayout>
  );
};

export default observer(TemplateWeschoolSelection);
