import { mobileAndTabletcheck } from "../modules/utility";

class AgentManager {
  constructor() {
    this.isWindowsPhone = navigator.userAgent.match(/windows phone/i) !== null;
    this.isWindows = navigator.userAgent.match(/windows/i) !== null;
    this.isIE =
      (navigator.userAgent.match(/Trident/i) ||
        navigator.userAgent.match(/MSIE/i)) !== null;
    this.isIE9 = navigator.userAgent.match(/MSIE 9/i) !== null;
    this.isIE11 = navigator.userAgent.match(/Trident(.*)rv:11/i) !== null;
    this.isIEEdge = navigator.userAgent.match(/Windows(.*)Edge/i) !== null;
    this.isIPhone = navigator.userAgent.match(/iphone/i) !== null;
    this.isIPad = navigator.userAgent.match(/ipad/i) !== null;
    this.isAppleMobile =
      navigator.userAgent.match(/iphone/i) !== null ||
      navigator.userAgent.match(/ipad/i) !== null;
    this.isAndroid = navigator.userAgent.match(/android/i) !== null;
    this.isMobile = mobileAndTabletcheck();
  }
}

export const agentManager = new AgentManager();
