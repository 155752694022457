import { useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import useSocket from "src/js/hooks/websocket/useSocket";
import {
  fetchOldestUnreadMessageThread,
  fetchThreadMessages
} from "src/js/repository/threadsRepository";
import {
  REACTABLE_ENTITY,
  ReactionCounter,
  ReactionType
} from "src/js/types/models/Reaction";
import { Thread, ThreadMessage } from "src/js/types/models/Thread";
import {
  DOMAIN,
  EVENT,
  ThreadCreateReaction,
  ThreadCreateReactionHydrated,
  ThreadDeleteMessage,
  ThreadDeleteReaction,
  ThreadEditMessage,
  ThreadNewMessageHydrated,
  ThreadSendNewMessage
} from "src/js/types/models/WebSocket";
import {
  decreaseReactionListCounter,
  increaseReactionListCounter
} from "../../reactions/Reactions.utils";
import {
  FETCH_LIMIT_MESSAGES,
  getThreadMessagesFromOldestUnread
} from "../Thread.const";
import { HandleSendMessageProps } from "../Thread.types";

export const ThreadEvent = {
  OnAddNewMessage: "onAddNewMessage"
} as const;
export type ThreadEvent = (typeof ThreadEvent)[keyof typeof ThreadEvent];

export type CreateMessageReactionProps = {
  messageId: string;
  reactionType: ReactionType;
};

export type DeleteMessageReactionProps = {
  messageId: string;
  reactionId: string;
  reactionType: ReactionType;
};

const useThreads = ({
  spaceId,
  userId,
  thread,
  handleEvents,
  startScrollToChatBottom
}: {
  spaceId: string;
  userId: string;
  thread: Thread;
  handleEvents: (event: ThreadEvent) => void;
  startScrollToChatBottom: () => void;
}) => {
  const {
    ChatStore: { threadsList, setThreadsList },
    WebSocketStore: { socket, sendMessage }
  } = useStores();

  const { id: threadId, unreadMessages, lastMessage } = thread || {};
  const [messageList, setMessageList] = useState<ThreadMessage[]>([]);
  const [oldestUnreadMessage, setOldestUnreadMessage] =
    useState<ThreadMessage>(null);
  const [hasNextMessages, setHasNextMessages] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editableMessage, setEditableMessage] = useState<ThreadMessage>(null);

  const fetchDataThreadMessages = async () => {
    if (unreadMessages > 0) {
      try {
        const oldestUnread = await fetchOldestUnreadMessageThread({
          threadId
        });
        setOldestUnreadMessage(oldestUnread);
        const callFetchThreadMessages = await getThreadMessagesFromOldestUnread(
          {
            spaceId,
            threadId,
            oldestUnreadMessage: oldestUnread,
            lastMessage
          }
        );
        return callFetchThreadMessages;
      } catch (error) {
        const newThreadsList = threadsList.map(t => ({
          ...t,
          unreadMessages: t.id === threadId ? 0 : t.unreadMessages
        }));
        setThreadsList(newThreadsList);
      }
    }
    return fetchThreadMessages({
      spaceId,
      threadId,
      limit: FETCH_LIMIT_MESSAGES
    }).then(data => {
      if (data?.results?.length === 0 && lastMessage) {
        return { ...data, results: [lastMessage] };
      }
      return data;
    });
  };

  const loadMessageList = (showLoader = true) => {
    if (showLoader) {
      setIsLoading(true);
      setMessageList([]);
      setOldestUnreadMessage(null);
    }

    fetchDataThreadMessages()
      .then(data => {
        setMessageList(data?.results.reverse());
        setHasNextMessages(data?.hasNext);
      })
      .finally(() => {
        if (showLoader) setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!spaceId || !threadId) return;
    loadMessageList();
  }, [spaceId, threadId]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document?.visibilityState === "visible") {
        loadMessageList(false);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [loadMessageList]);

  const getNextMessages = () => {
    setHasNextMessages(false);
    fetchThreadMessages({
      spaceId,
      threadId,
      toId: messageList[0]?.id,
      limit: FETCH_LIMIT_MESSAGES
    }).then(data => {
      setMessageList([...data?.results.reverse(), ...messageList]);
      setHasNextMessages(data?.hasNext);
    });
  };

  const addNewMessage = async ({
    message,
    resources
  }: HandleSendMessageProps) => {
    const newMessageWS: ThreadSendNewMessage = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.NEW_MESSAGE_EVENT,
      data: { message, threadId, spaceId, resources }
    };
    await sendMessage(newMessageWS);
  };

  const onAddNewMessage = (message: ThreadNewMessageHydrated) => {
    if (message?.data?.threadId !== threadId) return;
    const messageWithRead = {
      ...message.data,
      isRead: message.data.userId === userId
    };
    setMessageList([...messageList, messageWithRead]);
    handleEvents(ThreadEvent.OnAddNewMessage);
  };

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.NEW_MESSAGE_EVENT,
    handler: onAddNewMessage
  });

  const removeMessageFromList = (messageId: string) => {
    const deleteMessageWS: ThreadDeleteMessage = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.DELETE_MESSAGE_EVENT,
      data: {
        threadId,
        spaceId,
        id: messageId,
        userId
      }
    };
    sendMessage(deleteMessageWS);
  };

  const onRemoveMessageFromList = (message: ThreadDeleteMessage) => {
    if (message?.data?.threadId !== threadId) return;

    setMessageList(prevMessageList => {
      const filteredMessageList = prevMessageList.filter(
        msg => msg.id !== message?.data?.id
      );
      return filteredMessageList;
    });
  };

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.DELETE_MESSAGE_EVENT,
    handler: onRemoveMessageFromList
  });

  const editMessage = ({ message }: { message: string }) => {
    if (message === editableMessage.message) {
      setEditableMessage(null);
      return;
    }
    const editMessageWS: ThreadEditMessage = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.EDIT_MESSAGE_EVENT,
      data: {
        threadId,
        spaceId,
        id: editableMessage.id,
        userId: editableMessage.userId,
        message
      }
    };
    sendMessage(editMessageWS);
    setEditableMessage(null);
  };

  const onEditMessage = (message: ThreadEditMessage) => {
    if (message?.data?.threadId !== threadId) return;

    setMessageList(prevMessageList => {
      const editedListMessageIndex = prevMessageList.findIndex(
        msg => msg.id === message.data.id
      );

      if (editedListMessageIndex === -1) {
        return prevMessageList; // If message is not found, return the current list
      }

      // Create a new array with the updated message
      const updatedMessageList = [...prevMessageList];
      updatedMessageList[editedListMessageIndex] = {
        ...updatedMessageList[editedListMessageIndex],
        message: message.data.message,
        updatedAt: message.data.updatedAt
      };

      return updatedMessageList;
    });
  };

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.EDIT_MESSAGE_EVENT,
    handler: onEditMessage
  });

  const createMessageReaction = ({
    messageId,
    reactionType
  }: CreateMessageReactionProps) => {
    const createMessageReactionWS: ThreadCreateReaction = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.CREATE_REACTION_EVENT,
      data: {
        threadId,
        spaceId,
        reactedId: messageId,
        userId,
        reactableEntity: REACTABLE_ENTITY.THREAD_MESSAGE,
        reactionType,
        userUuid: userId
      }
    };
    sendMessage(createMessageReactionWS);
  };

  const onCreateMessageReaction = (message: ThreadCreateReactionHydrated) => {
    if (message?.data?.threadId !== threadId) return;

    setMessageList(prevMessageList => {
      const reactedMessageIndex = prevMessageList.findIndex(
        msg => msg.id === message.data.id
      );

      if (reactedMessageIndex === -1) {
        return prevMessageList; // If message is not found, return the current list
      }

      // remove reactionLoggedUser if not current user
      const oldReactions: ReactionCounter =
        prevMessageList[reactedMessageIndex].reactions;

      const { reactions, total = 0, reactionLoggedUser } = oldReactions || {};

      const updatedList = increaseReactionListCounter({
        reactionList: reactions,
        reactionType: message.data.reaction.reactionType
      });

      // Create the updated reaction object
      const updatedReactions = {
        reactions: updatedList,
        reactionLoggedUser:
          message.data.reaction.userId === userId
            ? message.data.reaction
            : reactionLoggedUser,
        total: total + 1
      };

      // Create a new array with the updated Reactions
      const updatedMessageList = [...prevMessageList];
      updatedMessageList[reactedMessageIndex] = {
        ...updatedMessageList[reactedMessageIndex],
        reactions: updatedReactions
      };

      // Scroll bottom if reaction is made by current user on the last message
      if (
        reactedMessageIndex === updatedMessageList.length - 1 &&
        message.data.reaction.userId === userId
      ) {
        startScrollToChatBottom();
      }

      return updatedMessageList;
    });
  };

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.CREATE_REACTION_EVENT,
    handler: onCreateMessageReaction
  });

  const deleteMessageReaction = ({
    messageId,
    reactionId,
    reactionType
  }: DeleteMessageReactionProps) => {
    const deleteMessageReactionWS: ThreadDeleteReaction = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.DELETE_REACTION_EVENT,
      data: {
        id: messageId,
        userId,
        threadId,
        spaceId,
        reactionId,
        reactionType
      }
    };
    sendMessage(deleteMessageReactionWS);
  };

  const onDeleteMessageReaction = (message: ThreadDeleteReaction) => {
    if (message?.data?.threadId !== threadId) return;

    setMessageList(prevMessageList => {
      const reactedMessageIndex = prevMessageList.findIndex(
        msg => msg.id === message.data.id
      );

      if (reactedMessageIndex === -1) {
        return prevMessageList; // If message is not found, return the current list
      }

      // Create the updated reaction object
      const oldReactions: ReactionCounter =
        prevMessageList[reactedMessageIndex].reactions;

      const { reactions, total = 0, reactionLoggedUser } = oldReactions || {};

      const updatedList = decreaseReactionListCounter({
        reactionList: reactions,
        reactionType: message.data.reactionType
      });
      const updatedTotal = total > 1 ? total - 1 : 0;

      // Create the updated reaction object
      const updatedReactions = {
        reactions: updatedList,
        reactionLoggedUser:
          (message.data.userId === reactionLoggedUser?.userId ||
            message.data.userId === reactionLoggedUser?.userUuid) &&
          message.data.reactionType === reactionLoggedUser.reactionType
            ? null
            : reactionLoggedUser,
        total: updatedTotal
      };

      // Create a new array with the updated Reactions
      const updatedMessageList = [...prevMessageList];
      updatedMessageList[reactedMessageIndex] = {
        ...updatedMessageList[reactedMessageIndex],
        reactions: updatedReactions
      };

      return updatedMessageList;
    });
  };

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.DELETE_REACTION_EVENT,
    handler: onDeleteMessageReaction
  });

  return {
    messageList,
    hasNextMessages,
    isLoading,
    getNextMessages,
    addNewMessage,
    removeMessageFromList,
    oldestUnreadMessage,
    editableMessage,
    setEditableMessage,
    editMessage,
    createMessageReaction,
    deleteMessageReaction
  };
};

export default useThreads;
