import { DBSchema } from "idb";

export type QueryModel = {
  queryHash: string;
  queryKey: string;
  value: string;
};

export type QueryMutationModel = {
  mutationKey: string;
  value: string;
};

export type QueryClientStateModel = {
  id: number;
  buster: string;
  timestamp: number;
  queries?: QueryModel[];
  mutations?: QueryMutationModel[];
};

export const WSDatabaseStoreEnum = {
  QueryClientState: "queryClientState"
} as const;

export interface WSDatabase extends DBSchema {
  [WSDatabaseStoreEnum.QueryClientState]: {
    key: number;
    value: QueryClientStateModel;
  };
}
