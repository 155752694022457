import getUserClient from "src/js/modules/connection";
import {
  api_board_element_comment,
  api_board_element_comments,
  api_board_element_nested_comment,
  api_board_element_nested_comments,
  api_nested_comment,
  api_post_comment,
  api_post_comments,
  api_post_nested_comment,
  api_post_nested_comments,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  PostComment,
  NestedComment,
  BoardElementComment,
  isPostComment
} from "../types/models/Comment";
import {
  COMMENT_CONTEXT,
  CommentContext
} from "../components/CommentList/CommentList.types";
import { BasePaginationResponse } from "../types";

/**
 * POST APIs
 */

const fetchPostComments = async ({
  postId,
  limit,
  offset = 0
}: {
  postId: number;
  limit?: number;
  offset: number;
}) => {
  return getUserClient()
    .get<BasePaginationResponse<PostComment>>(
      getVersionedApiUrl(api_post_comments({ postId }), "v3"),
      {
        params: { limit, offset }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const updatePostComment = async ({
  postId,
  commentId,
  text
}: {
  postId: number;
  commentId: number;
  text: string;
}) => {
  return getUserClient()
    .patch<PostComment>(
      getVersionedApiUrl(api_post_comment({ postId, commentId }), "v3"),
      {
        text,
        id: commentId
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const deletePostComment = async ({
  postId,
  commentId
}: {
  postId: number;
  commentId: number;
}) => {
  return getUserClient()
    .delete(getVersionedApiUrl(api_post_comment({ postId, commentId }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const createPostComment = async ({
  postId,
  text
}: {
  postId: number;
  text: string;
}) => {
  return getUserClient()
    .post<PostComment>(
      getVersionedApiUrl(api_post_comments({ postId }), "v3"),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

/**
 * POST_NESTED APIs
 */

const createPostNestedComment = async ({
  commentId,
  text
}: {
  commentId: number;
  text: string;
}) => {
  return getUserClient()
    .post<NestedComment>(
      getVersionedApiUrl(api_post_nested_comments({ commentId }), "v3"),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const deletePostNestedComment = async ({
  commentId,
  nestedCommentId
}: {
  commentId: number;
  nestedCommentId: number;
}) => {
  return getUserClient()
    .delete(
      getVersionedApiUrl(
        api_post_nested_comment({ commentId, nestedCommentId }),
        "v3"
      )
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const updatePostNestedComment = async ({
  parentId,
  nestedCommentId,
  text
}: {
  parentId: number;
  nestedCommentId: number;
  text: string;
}) => {
  return getUserClient()
    .patch<NestedComment>(
      getVersionedApiUrl(
        api_nested_comment({ parentId, nestedCommentId }),
        "v3"
      ),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

/**
 * BOARDELEMENT APIs
 */

type ExtendedResponse = BasePaginationResponse<BoardElementComment> & {
  totalComments: number;
};

const fetchBoardElementComment = async ({
  boardElementId,
  limit,
  offset = 0
}: {
  boardElementId: number;
  limit?: number;
  offset: number;
}) => {
  return getUserClient()
    .get<ExtendedResponse>(
      getVersionedApiUrl(api_board_element_comments({ boardElementId }), "v3"),
      {
        params: { limit, offset }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const createBoardElementComment = async ({
  boardElementId,
  text
}: {
  boardElementId: number;
  text: string;
}) => {
  return getUserClient()
    .post<BoardElementComment>(
      getVersionedApiUrl(api_board_element_comments({ boardElementId }), "v3"),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const deleteBoardElementComment = async ({
  commentId
}: {
  commentId: number;
}) => {
  return getUserClient()
    .delete(getVersionedApiUrl(api_board_element_comment({ commentId }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const updateBoardElementComment = async ({
  commentId,
  text
}: {
  commentId: number;
  text: string;
}) => {
  return getUserClient()
    .patch<BoardElementComment>(
      getVersionedApiUrl(api_board_element_comment({ commentId }), "v3"),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

/**
 * BOARDELEMENT_NESTED APIs
 */

const createBoardElementNestedComment = async ({
  commentId,
  text
}: {
  commentId: number;
  text: string;
}) => {
  return getUserClient()
    .post<NestedComment>(
      getVersionedApiUrl(
        api_board_element_nested_comments({ commentId }),
        "v3"
      ),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const deleteBoardElementNestedComment = async ({
  nestedCommentId
}: {
  nestedCommentId: number;
}) => {
  return getUserClient()
    .delete(
      getVersionedApiUrl(
        api_board_element_nested_comment({ nestedCommentId }),
        "v3"
      )
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

const updateBoardElementNestedComment = async ({
  nestedCommentId,
  text
}: {
  nestedCommentId: number;
  text: string;
}) => {
  return getUserClient()
    .patch<NestedComment>(
      getVersionedApiUrl(
        api_board_element_nested_comment({ nestedCommentId }),
        "v3"
      ),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

/**
 * EXPORTED UTILS
 */

export const fetchComments = async ({
  parentId,
  limit,
  offset,
  context
}: {
  parentId: number;
  limit?: number;
  offset: number;
  context: CommentContext;
}) => {
  if (context === COMMENT_CONTEXT.MODULES) {
    return fetchBoardElementComment({
      boardElementId: parentId,
      limit,
      offset
    });
  }
  return fetchPostComments({ postId: parentId, limit, offset });
};

export const deleteComment = async ({
  comment
}: {
  comment: PostComment | BoardElementComment;
}) => {
  if (isPostComment(comment)) {
    return deletePostComment({
      postId: comment.postId,
      commentId: comment.id
    });
  }
  return deleteBoardElementComment({
    commentId: comment.id
  });
};

export const updateComment = async ({
  comment,
  text
}: {
  comment: PostComment | BoardElementComment;
  text: string;
}) => {
  if (isPostComment(comment)) {
    return updatePostComment({
      postId: comment.postId,
      commentId: comment.id,
      text
    });
  }
  return updateBoardElementComment({
    commentId: comment.id,
    text
  });
};

export const createComment = async ({
  parentId,
  text,
  context
}: {
  parentId: number;
  text: string;
  context: CommentContext;
}) => {
  if (context === COMMENT_CONTEXT.POST) {
    return createPostComment({ postId: parentId, text });
  }
  return createBoardElementComment({ boardElementId: parentId, text });
};

export const createNestedComment = async ({
  commentId,
  text,
  context
}: {
  commentId: number;
  text: string;
  context: CommentContext;
}) => {
  if (context === COMMENT_CONTEXT.POST) {
    return createPostNestedComment({ commentId, text });
  }
  return createBoardElementNestedComment({ commentId, text });
};

export const deleteNestedComment = async ({
  commentId,
  nestedCommentId,
  context
}: {
  commentId: number;
  nestedCommentId: number;
  context: CommentContext;
}) => {
  if (context === COMMENT_CONTEXT.POST) {
    return deletePostNestedComment({ commentId, nestedCommentId });
  }
  return deleteBoardElementNestedComment({ nestedCommentId });
};

export const updateNestedComment = async ({
  parentId,
  nestedCommentId,
  text,
  context
}: {
  parentId: number;
  nestedCommentId: number;
  text: string;
  context: CommentContext;
}) => {
  if (context === COMMENT_CONTEXT.POST) {
    return updatePostNestedComment({ parentId, nestedCommentId, text });
  }
  return updateBoardElementNestedComment({ nestedCommentId, text });
};
