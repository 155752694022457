import styled from "styled-components";

export const IllustrationContainer = styled.div`
  margin: 20px 0;
`;

export const SelectableListEmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 160px);
  padding: 12px;
  white-space: pre-wrap;
  text-align: center;
`;
