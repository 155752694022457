import { useEffect } from "react";
import { useParams } from "react-router";
import { useStores } from "src/js/hooks";

const MatchReportRoute = () => {
  const { exercise_id, session_id } = useParams();
  const { ReportStore } = useStores();

  useEffect(() => {
    const numericId = parseInt(session_id, 10);
    if (numericId) {
      ReportStore.initActiveReport({
        exerciseId: parseInt(exercise_id, 10),
        executionId: numericId
      });
    }

    return () => ReportStore.resetReport();
  }, [session_id]);

  return null;
};

export default MatchReportRoute;
