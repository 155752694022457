import colors from "@ds_themes/colors";

export const contentswitcher = {
  wrapper: {
    background: colors.grey[1000],
    border: colors.grey[800]
  },
  tab: {
    background: colors.grey[1000],
    backgroundSquared: colors.coldGrey[900],
    color: colors.blueGrey[300],
    active: {
      background: colors.violet[500],
      color: colors.grey[1000],
      disabled: colors.violet[700]
    },
    hover: {
      background: colors.coldGrey[900]
    },
    disabled: {
      color: colors.blueGrey[500]
    },
    focus: {
      color: colors.violet[700]
    }
  }
};
