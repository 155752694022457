// TODO POC

import getUserClient from "src/js/modules/connection";
import {
  api_ai_assistant_content,
  api_ai_assistant_token,
  api_ai_assistant_transaction_id,
  getVersionedApiUrl
} from "./apiUrls";

export const fetchAIContent = async (subject: string, message: string) => {
  return getUserClient()
    .post<{ response: string }>(
      getVersionedApiUrl(api_ai_assistant_content(), "v3"),
      {
        subject,
        message
      }
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export type AIAuthResponse = {
  jwt: string;
  expiresAt: string;
  apiUrl: string;
};

export type AITransactionIdResponse = {
  aiTransactionId: string;
};

export type AITransactionInfoResponse = AIAuthResponse &
  AITransactionIdResponse;

export const fetchAiAuth = async (groupId: number) => {
  return getUserClient()
    .get<AIAuthResponse>(
      getVersionedApiUrl(api_ai_assistant_token({ groupId }), "v3")
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};

export const fetchAiTransactionId = async (groupId: number) => {
  return getUserClient()
    .get<AITransactionIdResponse>(
      getVersionedApiUrl(api_ai_assistant_transaction_id({ groupId }), "v3")
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
};
