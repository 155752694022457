import React from "react";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { useRouteMatch } from "react-router";
import { Box, Icon } from "@arcadia/design-system";
import {
  GroupSectionsEnum,
  GroupSectionsRouteMatchEnum,
  GroupSectionsType
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { useWhiteBoard } from "../../hooks/useWhiteBoard";
import { LiveButton } from "../LiveButton";

import * as S from "./SpaceSecondaryHeaderTabs.styles";

const SpaceSecondaryHeaderTabs = () => {
  const {
    GroupStore: {
      activeGroup,
      userIsTeacher,
      settings,
      permissionToCustomization,
      isGroupSectionVisible
    },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const wallSelected = Boolean(
    useRouteMatch(GroupSectionsRouteMatchEnum[GroupSectionsEnum.Wall])
  );
  const modulesSelected = Boolean(
    useRouteMatch(GroupSectionsRouteMatchEnum[GroupSectionsEnum.Modules])
  );
  const exercisesSelected = Boolean(
    useRouteMatch(GroupSectionsRouteMatchEnum[GroupSectionsEnum.Exercises])
  );
  const activitySelected = Boolean(
    useRouteMatch(GroupSectionsRouteMatchEnum[GroupSectionsEnum.Activity])
  );
  const whiteboardSelected = Boolean(
    useRouteMatch(GroupSectionsRouteMatchEnum[GroupSectionsEnum.Whiteboard])
  );
  const calendarSelected = Boolean(
    useRouteMatch(GroupSectionsRouteMatchEnum[GroupSectionsEnum.Calendar])
  );
  const group_id = activeGroup?.id;
  const { shouldShowWhiteboard } = useWhiteBoard(group_id);
  if (!group_id) return null;

  const groupSectionsVisibility = Object.values(GroupSectionsEnum).reduce(
    (acc, key) => {
      acc[key] = isGroupSectionVisible(key);
      return acc;
    },
    {} as Record<GroupSectionsType, boolean>
  );

  const showTabAdd =
    permissionToCustomization &&
    Object.values(groupSectionsVisibility).some(visible => !visible);
  const groupShouldShowRanking = settings?.gamification === "enabled";
  const groupShouldShowExerciseTab = settings?.gamificationTest === "enabled";

  return (
    <S.TabWrapper>
      {groupSectionsVisibility[GroupSectionsEnum.Wall] && (
        <S.Tab
          isLayoutModeMobile={isLayoutModeMobile}
          active={wallSelected}
          onClick={() => navigateTo(createUrl("wall", { group_id }))}
        >
          {translate("wall")}
          {wallSelected && (
            <S.Underline
              isLayoutModeMobile={isLayoutModeMobile}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
            />
          )}
        </S.Tab>
      )}
      {groupSectionsVisibility[GroupSectionsEnum.Modules] && (
        <S.Tab
          isLayoutModeMobile={isLayoutModeMobile}
          active={modulesSelected}
          onClick={() => navigateTo(createUrl("modules", { group_id }))}
        >
          {translate("board")}
          {modulesSelected && (
            <S.Underline
              isLayoutModeMobile={isLayoutModeMobile}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
            />
          )}
        </S.Tab>
      )}
      {(!userIsTeacher &&
        groupShouldShowRanking &&
        !groupShouldShowExerciseTab) ||
      !groupSectionsVisibility[GroupSectionsEnum.Exercises] ? null : (
        <S.Tab
          isLayoutModeMobile={isLayoutModeMobile}
          active={exercisesSelected}
          onClick={() => navigateTo(createUrl("exercises", { group_id }))}
        >
          {translate("esercizi")}
          {exercisesSelected && (
            <S.Underline
              isLayoutModeMobile={isLayoutModeMobile}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
            />
          )}
        </S.Tab>
      )}
      {groupSectionsVisibility[GroupSectionsEnum.Whiteboard] &&
        shouldShowWhiteboard && (
          <S.Tab
            isLayoutModeMobile={isLayoutModeMobile}
            active={whiteboardSelected}
            onClick={() => navigateTo(createUrl("whiteboard", { group_id }))}
          >
            {translate("whiteboard")}
            {whiteboardSelected && (
              <S.Underline
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                exit={{ scaleX: 0 }}
                isLayoutModeMobile={isLayoutModeMobile}
              />
            )}
          </S.Tab>
        )}
      {groupSectionsVisibility[GroupSectionsEnum.Activity] &&
        userIsTeacher &&
        !isLayoutModeMobile && (
          <S.Tab
            isLayoutModeMobile={isLayoutModeMobile}
            active={activitySelected}
            onClick={() => navigateTo(createUrl("activity", { group_id }))}
          >
            {translate("activity")}
            {activitySelected && (
              <S.Underline
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                exit={{ scaleX: 0 }}
                isLayoutModeMobile={isLayoutModeMobile}
              />
            )}
          </S.Tab>
        )}
      {groupSectionsVisibility[GroupSectionsEnum.Calendar] && (
        <S.Tab
          isLayoutModeMobile={isLayoutModeMobile}
          active={calendarSelected}
          onClick={() => {
            navigateTo(createUrl("calendar", { group_id }));
          }}
        >
          <Box display="flex" alignItems="center" gap={8}>
            {translate("calendar_tab_title")}
            {isLayoutModeMobile && <LiveButton groupId={activeGroup?.id} />}
          </Box>
          {calendarSelected && (
            <S.Underline
              isLayoutModeMobile={isLayoutModeMobile}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0, opacity: 0 }}
            />
          )}
        </S.Tab>
      )}
      {showTabAdd && (
        <S.Tab
          isLayoutModeMobile={isLayoutModeMobile}
          isLight
          onClick={() => {
            navigateTo(
              createUrl("group_settings", {
                group_id,
                option: "sections"
              })
            );
          }}
        >
          <Icon icon="plusThin" width={10} height={10} />
          {translate("header_white_tab_group_cta_add")}
        </S.Tab>
      )}
    </S.TabWrapper>
  );
};

export default observer(SpaceSecondaryHeaderTabs);
