import { ActionButton } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { WeSchoolRoutes } from "../../Routes.const";

const BackToCreateGroupSelection = () => {
  const { whiteTheme } = useTheme();

  const {
    UserStore: { userOnboarding, setUserOnboarding }
  } = useStores();

  const goBack = () => {
    setUserOnboarding({
      ...userOnboarding,
      step: WeSchoolRoutes.Onboarding.groupCreateSelection
    });
    navigateTo(WeSchoolRoutes.Onboarding.groupCreateSelection);
  };

  return (
    <ActionButton
      variant="tertiary"
      icon="chevronSmallLeft"
      theme={whiteTheme}
      onClick={goBack}
    />
  );
};

export default observer(BackToCreateGroupSelection);
