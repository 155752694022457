import React from "react";
import { ThemeProvider } from "styled-components";
import { AnimatePresence } from "framer-motion";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import { Box } from "@ds_universal/Box";
import { Loader } from "@ds_universal/Loader";

import { ItemProps } from "./types";
import * as S from "./SidebarGroupItem.styles";

const fadeInRightfadeOutScale = {
  initial: {
    opacity: 0,
    x: 10
  },
  animate: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    scale: 0
  }
};

const SidebarGroupItem = ({
  id,
  theme,
  badge,
  groupName,
  notification,
  isSelected,
  isMuted,
  isLoading,
  onClick
}: ItemProps) => {
  const showNumber = notification && notification > 0 ? true : false;

  return (
    <ThemeProvider theme={theme}>
      <S.ItemContainer
        id={id}
        onClick={onClick}
        theme={theme}
        isSelected={isSelected}
      >
        <S.ImageContainer>{badge}</S.ImageContainer>
        <S.Name isLoading={isLoading}>
          <Text type="formTitle">{groupName}</Text>
        </S.Name>
        <Box display="flex" gap={4} alignItems="center">
          <AnimatePresence initial={false}>
            {isMuted && (
              <S.NotificationStatus
                isSelected={isSelected}
                key="notifications-status"
                layout
                {...fadeInRightfadeOutScale}
              >
                <Icon icon="notificationMuted" height={16} width={16} />
              </S.NotificationStatus>
            )}
            {showNumber && (
              <S.NotificationNumber
                isSelected={isSelected}
                key="notifications-number"
                {...fadeInRightfadeOutScale}
              >
                <Text type="formSmallCaption">{notification}</Text>
              </S.NotificationNumber>
            )}
            {isLoading && (
              <Loader
                loaderType="CircularLoader"
                theme={theme}
                loaderWidth="24px"
                loaderHeight="24px"
                width="24px"
                height="24px"
                mainColor={
                  isSelected
                    ? theme.sidebargroupitem.loading.selectedColor
                    : theme.sidebargroupitem.loading.color
                }
                borderColor={
                  isSelected
                    ? theme.sidebargroupitem.loading.selectedColor
                    : theme.sidebargroupitem.loading.color
                }
              />
            )}
          </AnimatePresence>
        </Box>
      </S.ItemContainer>
    </ThemeProvider>
  );
};

export default SidebarGroupItem;
