/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import fetchAccessToken from "src/js/repository/authenticationRepository";
import { User } from "src/js/types";
import { userQueryKey } from "src/js/query/user";
import { queryClient } from "src/js/query/client";

/**
 * @name AuthStore
 *
 * @description
 * ...
 */
class AuthStore {
  userLoggedInSuccessfully = false;
  private fetchUser: () => Promise<User>;
  private getIsLogged: () => boolean;

  constructor({
    fetchUser,
    getIsLogged
  }: {
    fetchUser: () => Promise<User>;
    getIsLogged: () => boolean;
  }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.fetchUser = fetchUser;
    this.getIsLogged = getIsLogged;
  }

  async loginUser(username: string, password: string) {
    await fetchAccessToken(username, password);
    const user = await this.fetchUser();
    queryClient.setQueryData(userQueryKey.me, user);
    this.userLoggedInSuccessfully = true;
  }

  resetUserLoggedInSuccessfully() {
    this.userLoggedInSuccessfully = false;
  }

  resetUser(callback = () => {}) {
    return this.fetchUser()
      .then(() => {
        callback();
      })
      .catch(error => {
        throw error;
      });
  }

  // computed
  get userIsLogged() {
    return this.getIsLogged();
  }
}

export default AuthStore;
