import { Model } from "backbone";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";

class QuizModel extends Model {
  getDefaultThreshold() {
    return 70;
  }
  isCreated() {
    return this.get("id") !== undefined && this.get("id") > 0;
  }
  getType() {
    return this.get("dtype") || "";
  }
  setUserAnswer(answer) {
    this.set("userAnswer", answer);
  }
  getUserAnswer() {
    let answer = "";
    if (typeof this.get("userAnswer") !== "undefined")
      answer = this.get("userAnswer");
    return answer;
  }
  getFormattedUserAnswer() {
    const questions = this.getQuestions();
    const answer = this.getUserAnswer();
    const fomattedObj = map(questions, q => ({
      index: q.index,
      value: answer === q.value
    }));
    return fomattedObj;
  }
  checkUserAnswer() {
    // TODO check da server
    return (
      JSON.stringify(this.getUserAnswer()).toLowerCase() ===
      JSON.stringify(this.getSolutions()).toLowerCase()
    );
  }
  isFullyAnswered() {
    // override nei vari submodel
    return this.getUserAnswer() !== "";
  }
  getQuestions() {
    return this.get("questions") || [];
  }
  getSolutions() {
    return this.get("solutions") || [];
  }
  hasSession() {
    // controllo se il quiz ha già una sessione associata
    return this.get("session") !== undefined;
  }
  setSession(session) {
    this.set("session", session);
  }
  getSession() {
    return this.get("session") || {};
  }
  needsReview() {
    return false;
  }
  setOrder(order) {
    if (!isUndefined(order)) {
      this.set("order", order);
    }
  }
  getOrder() {
    return this.get("order") || {};
  }

  /**
   * Returns the title.
   * May be overwritten by submodels
   * @return {String}
   */
  getTitle() {
    return this.get("title");
  }

  getAvailablePoints() {
    return this.get("available_points") || 1;
  }

  getPoints() {
    return this.get("points") || 0;
  }
}

export default QuizModel;
