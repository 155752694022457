import React, { useState, useEffect } from "react";
import { Box, DropdownMultiple, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useStores, useDebounce } from "src/js/hooks";
import useSpaceRelatedGroups from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/useSpaceRelatedGroups";
import * as S from "../styles";

type GroupLabel = { id: string; title: string };

const BadgeAssignToGroups = () => {
  const { translate } = useTranslation();
  const { greyTheme, whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceBadgesStore: { selectBadgeRelatedGroups, selectedBadgeRelatedGroups }
  } = useStores();
  const [filterGroupString, setFilterGroupString] = useState("");
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<GroupLabel[]>(
    []
  );
  const searchFilter = useDebounce(filterGroupString, 500);
  const {
    spaceRelatedGroups,
    hasMoreResults,
    fetch,
    resetResults,
    fetchNextPage
  } = useSpaceRelatedGroups();

  const handleSearchGroups = (value: string) => {
    setFilterGroupString(value);
  };

  useEffect(() => {
    if (searchFilter || searchFilter === "") {
      fetch(0, { textSearch: searchFilter });
    }
    return () => resetResults();
  }, [searchFilter]);

  const collectLabel = (ids: string[]) => {
    const uniqueSet = new Set(selectedGroupLabel.map(label => label.id));

    const newLabels: GroupLabel[] = spaceRelatedGroups
      .filter(
        item =>
          ids.includes(item.group.id.toString()) &&
          !uniqueSet.has(item.group.id.toString())
      )
      .map(item => {
        const { id, name } = item.group;
        uniqueSet.add(id.toString());
        return { id: id.toString(), title: name };
      });
    setSelectedGroupLabel([...selectedGroupLabel, ...newLabels]);
  };

  const getGroupsPlaceholder = () => {
    const commonGroupNames = selectedBadgeRelatedGroups.map(el => {
      const matchGroup = selectedGroupLabel.find(
        activeLabel => activeLabel.id === el
      );
      return matchGroup.title;
    });

    let placeholder = "";

    if (commonGroupNames.length > 2) {
      const slicedGroupNames = commonGroupNames.slice(0, 2);
      const restGroupNames = commonGroupNames.slice(2);
      placeholder = `${slicedGroupNames.join(", ")} + ${restGroupNames.length}`;
    } else {
      placeholder = commonGroupNames.join(", ");
    }

    return placeholder;
  };

  return (
    <Box>
      <Box marginBottom={8}>
        <Text type="headerTitle">
          {translate("badge_field_assign_to_groups_title")}
        </Text>
      </Box>
      <S.DescriptionText type="formSubtitle">
        {translate("badge_fields_assign_to_group_description")}
      </S.DescriptionText>
      <Box marginTop={16}>
        <DropdownMultiple
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          hideButtonTags
          optionsList={spaceRelatedGroups.map(activeGroup => ({
            id: activeGroup.group.id.toString(),
            label: activeGroup.group.name
          }))}
          selectedOptionIds={selectedBadgeRelatedGroups}
          setSelectedOptionIds={groupdIds => {
            collectLabel(groupdIds);
            selectBadgeRelatedGroups(groupdIds);
          }}
          placeholder={
            selectedBadgeRelatedGroups.length
              ? getGroupsPlaceholder()
              : translate("badge_field_assign_to_group_placeholder")
          }
          searchPlaceholder={translate(
            "badge_field_assign_to_groups_search_placeholder"
          )}
          scrollableListId="badge-assign-groups-filter-dropdown-multiple"
          searchable
          searchQuery={filterGroupString}
          onSearchFunction={event => handleSearchGroups(event.target.value)}
          hasNext={hasMoreResults}
          showNext={fetchNextPage}
          optionsListAlignment={{ bottom: "0px" }}
        />
      </Box>
    </Box>
  );
};

export default observer(BadgeAssignToGroups);
