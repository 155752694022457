/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "src/js/translation/TranslationProvider";
import {
  ActionButton,
  ActionItemProps,
  ActionItemsDropdown,
  ContentSwitcher,
  Text
} from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { CalendarAnalyticsEvent, EventDomain } from "src/js/types";
import * as S from "./CalendarDesktopControls.styles";
import {
  CalendarMode,
  CalendarViewSettings,
  CalendarViewSettingsType,
  EventCategory
} from "../Calendar.types";
import { CalendarDesktopControlsParams } from "./CalendarDesktopControls.types";

const CalendarDesktopControls = ({
  changeView = () => {},
  newEvent = () => {},
  view = CalendarViewSettings.Month,
  mode = CalendarMode.Calendar,
  userIsTeacher = false,
  agendaUrl,
  calendarUrl
}: CalendarDesktopControlsParams) => {
  const history = useHistory();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  const dropdownOptions: ActionItemProps[] = [
    {
      theme: greyTheme,
      title: translate("calendar_creation_dropdown_live_title"),
      subtitle: translate("calendar_creation_dropdown_live_description"),
      icon: "videoCamera",
      onClick: () => newEvent(EventCategory.Live)
    },
    {
      theme: greyTheme,
      title: translate("calendar_creation_dropdown_activity_title"),
      subtitle: translate("calendar_creation_dropdown_activity_description"),
      icon: "checkList",
      onClick: () => newEvent(EventCategory.Activity)
    },
    {
      theme: greyTheme,
      title: translate("calendar_creation_dropdown_deadline_title"),
      subtitle: translate("calendar_creation_dropdown_deadline_description"),
      icon: "stopwatch",
      onClick: () => newEvent(EventCategory.Deadline)
    }
  ];

  const trackEventContent = (viewMode: CalendarViewSettingsType) => {
    const trackedEvent =
      viewMode === CalendarViewSettings.Month
        ? CalendarAnalyticsEvent.CalendarMonthViewClick
        : CalendarAnalyticsEvent.CalendarWeekViewClick;
    _trackEvent(EventDomain.Calendar, trackedEvent);
  };

  return (
    <S.Container>
      <S.Section alignContent="left">
        <ActionButton
          onClick={() => {
            _trackEvent(
              EventDomain.Calendar,
              CalendarAnalyticsEvent.CalendarGridViewClick
            );
            history.push(calendarUrl);
          }}
          theme={greyTheme}
          variant={mode === CalendarMode.Calendar ? "primary" : "secondary"}
          icon="gridView"
        />
        <ActionButton
          onClick={() => {
            _trackEvent(
              EventDomain.Calendar,
              CalendarAnalyticsEvent.CalendarAgendaViewClick
            );
            history.push(agendaUrl);
          }}
          theme={greyTheme}
          variant={mode === CalendarMode.Agenda ? "primary" : "secondary"}
          icon="listView"
        />
      </S.Section>
      {mode === CalendarMode.Calendar ? (
        <S.Section>
          <ContentSwitcher
            theme={greyTheme}
            items={[
              {
                id: CalendarViewSettings.Month,
                value: "month",
                disabled: false,
                children: <Text type="cta">{translate("month")}</Text>
              },
              {
                id: CalendarViewSettings.Week,
                value: "week",
                disabled: false,
                children: <Text type="cta">{translate("week")}</Text>
              }
            ]}
            activeOption={view}
            onContentItemChange={val => {
              trackEventContent(val as CalendarViewSettingsType);
              changeView(val);
            }}
          />
        </S.Section>
      ) : null}
      <S.Section alignContent="right">
        {userIsTeacher ? (
          <S.ActionSection>
            <ActionItemsDropdown
              theme={greyTheme}
              mainButtonIcon="calendar"
              mainButtonString={translate("calendar_creation_dropdown_title")}
              dropdownOptions={dropdownOptions}
            />
          </S.ActionSection>
        ) : null}
      </S.Section>
    </S.Container>
  );
};

export default CalendarDesktopControls;
