import styled, { css } from "styled-components";
import shadow from "@ds_themes/shadow";
import { Icon } from "@ds_universal/Icon";

import {
  SArrowProps,
  SButtonProps,
  SOptionProps,
  SOptionsContainerProps,
  SScrollableWrapperProps,
  SWrapperProps
} from "./types";

export const Wrapper = styled.div<SWrapperProps>`
  width: ${({ width }) => width};
  color: ${({ theme }) => theme.dropdown.text};
  position: relative;
  button:focus,
  input[type="button"]:focus {
    background-color: ${({ theme }) => theme.dropdown.focus.backgroundButton};
  }
  input[type="checkbox"] {
    margin-left: -10px;
  }
`;

export const Button = styled.div<SButtonProps>`
  user-select: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  background: ${({ theme }) => theme.dropdown.backgroundButton};
  border: ${({ theme }) => theme.dropdown.border};
  box-sizing: border-box;
  border-radius: 20px;
  padding: ${({ isEmpty }) =>
    isEmpty ? "10px 55px 10px 20px" : "10px 55px 10px 10px"};
  height: fit-content;
  &:focus {
    border: ${({ theme }) => theme.dropdown.focus.border};
    outline: ${({ theme }) => theme.dropdown.focus.outline};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      &:focus {
        outline: none;
        border: ${theme.dropdown.border};
      }
      cursor: not-allowed;
      color: ${theme.dropdown.disabled.color};
      background: ${theme.dropdown.disabled.background};
    `}

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.dropdown.hover.background};
    border: ${({ theme }) => theme.dropdown.hover.border};
  }
`;

export const FilterIconButtonContainer = styled.div<SButtonProps>`
  background-color: ${({ theme }) => theme.dropdown.filterIconBackground};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 52px;
  padding: 16px;

  &:hover {
    & > svg {
      color: ${({ theme }) => theme.dropdown.filterIconActiveColor};
    }
  }
`;

export const FilterIconButton = styled(Icon)<{ isactive: boolean }>`
  color: ${({ theme, isactive }) =>
    isactive
      ? theme.dropdown.filterIconActiveColor
      : theme.dropdown.filterIconInactiveColor};
`;

export const Arrow = styled.div<SArrowProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  right: 20px;
`;

export const OptionContainer = styled.div<SOptionsContainerProps>`
  position: absolute;
  user-select: none;
  width: ${({ optionsListWidth }) => optionsListWidth || "100%"};
  margin-top: 8px;
  border: ${({ theme }) => theme.dropdown.border};
  background-color: ${({ theme }) => theme.dropdown.backgroundContainer};
  box-shadow: ${shadow[500]};
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.average};

  ${({ optionsListAlignment }) => css`
    right: ${optionsListAlignment?.right || undefined};
    left: ${optionsListAlignment?.left || undefined};
    top: ${optionsListAlignment?.top || undefined};
    bottom: ${optionsListAlignment?.bottom || undefined};
  `};
`;

export const ScrollableWrapper = styled.div<SScrollableWrapperProps>`
  width: 100%;
  height: ${({ withScrollbar }) => (withScrollbar ? "220px" : "fit-content")};
  overflow-y: ${({ withScrollbar }) => (withScrollbar ? "scroll" : "auto")};
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* styles to make the scroll bar customized */
  ::-webkit-scrollbar {
    width: 1.5em;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    height: 1em;
    border: 0.5em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1em;
    background-color: #c4c4c4;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  label {
    display: none;
  }
`;

export const Option = styled.div<SOptionProps>`
  width: 100%;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 30px;
  border: 0px;
  background: ${({ theme }) => theme.dropdown.option.background};
  display: flex;
  align-items: center;
  justify-content: start;
  &:focus {
    background: ${({ theme }) => theme.dropdown.focus.backgroundOptions};
    color: ${({ theme }) => theme.dropdown.option.hover.text};
    outline: none;
  }
  & span {
    margin-left: 10px;
  }

  &:hover {
    color: ${({ theme }) => theme.dropdown.option.hover.text};
    background: ${({ theme }) => theme.dropdown.focus.backgroundOptions};
    outline: none;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      &:focus {
        outline: none;
        background: ${theme.dropdown.option.background};
        border: none;
      }
      &:hover {
        color: ${theme.dropdown.disabled.color};
        background: ${theme.dropdown.option.background};
        outline: none;
      }
      cursor: not-allowed;
      color: ${theme.dropdown.disabled.color};
      background: ${theme.dropdown.option.background};
    `}

  input[type='checkbox'] {
    top: -2px !important;
  }
`;

export const SearchInputContainer = styled.div`
  margin: 8px;
`;
