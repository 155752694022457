import { useEffect } from "react";
import { useStores } from "src/js/hooks";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";

export const useWhiteBoard = (groupId?: number) => {
  const {
    GroupStore: { isWhiteboardEnabled, userIsTeacher },
    WhiteboardStore: { whiteboardUrl, fetchWhiteboardUrl, setWhiteboardUrl }
  } = useStores();

  useEffect(() => {
    RealTimeDispatcher.on("whiteboard_activated_event", () => {
      fetchWhiteboardUrl(groupId);
    });
    RealTimeDispatcher.on("whiteboard_deactivated_event", () => {
      setWhiteboardUrl(null);
    });
    return () => {
      RealTimeDispatcher.off("whiteboard_activated_event");
      RealTimeDispatcher.off("whiteboard_deactivated_event");
    };
  }, [groupId]);

  const shouldShowWhiteboard =
    isWhiteboardEnabled && (userIsTeacher || whiteboardUrl);

  return { shouldShowWhiteboard };
};
