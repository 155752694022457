import { BottomModalSheet, Box, Heading, Icon } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ListItem } from "src/js/components/DotsMenu/ListItem";
import {
  getAvatarData,
  getTitleFromUsers,
  getUsersWithoutMe
} from "src/js/components/Thread/Thread.const";
import { SidebarThreadsBlocked } from "src/js/components/layout/Sidebar/SidebarThreadsBlocked";
import { SearchBar } from "src/js/components/layout/TopHeaderSpacesMobile/TopHeaderSpacesMobile.styles";
import { useDebounce, useStores } from "src/js/hooks";
import { useAppLayout } from "src/js/layout/AppLayout";
import { showToastError } from "src/js/modules/messageManager";
import { createUrl } from "src/js/modules/routing";
import { editThreadSettings } from "src/js/repository/threadsRepository";
import { useTranslation } from "src/js/translation";
import { Thread } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { useFetchUserNewThread } from "../hooks";
import * as S from "./ChatPage.styles";
import { NewThreadModal } from "./components/NewThreadModal";
import { ThreadItem } from "./components/ThreadItem";

const ChatPage: FC = () => {
  const { whiteTheme, primaryColor } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceStore: {
      activeSpaceSlug,
      activeSpaceId,
      activeSpace,
      isClaimable,
      isDemoSpace
    },
    ChatStore: { threadsList, setThreadMuted, fetchThreadsList },
    UserStore: { activeUser },
    ModalStore: { openModal }
  } = useStores();

  const { pageMinHeight } = useAppLayout();
  const [queryString, setQueryString] = useState("");
  const queryStringDebounced = useDebounce(queryString, 300);
  const [threadViewOptions, setThreadViewOptions] = useState<Thread>(null);

  useEffect(() => {
    fetchThreadsList(activeSpaceId, true);
  }, []);

  useFetchUserNewThread({
    handleThreadUser: threadUser =>
      openModal(() => <NewThreadModal selectedUser={threadUser} />)
  });

  const filteredThreads: Thread[] = useMemo(() => {
    if (queryStringDebounced.length === 0) {
      return threadsList;
    }
    return threadsList.filter(({ users }) => {
      return users.find(
        ({ name, surname, uuid }) =>
          `${name}${surname}`
            .toLowerCase()
            .trim()
            .indexOf(queryStringDebounced.toLowerCase().trim()) > -1 &&
          uuid !== activeUser?.uuid
      );
    });
  }, [threadsList, queryStringDebounced]);

  const showNewThreadModal = () => {
    openModal(() => <NewThreadModal />);
  };

  const navigateToThread = (threadId: string) =>
    navigateTo(
      createUrl("chat_thread", {
        space_slug: activeSpaceSlug,
        thread_id: threadId
      })
    );

  const muteThread = ({ threadId, muted }) => {
    editThreadSettings({
      threadId,
      muted: !muted
    })
      .then(() => {
        setThreadMuted({ threadId, muted: !muted });
      })
      .catch(error => {
        showToastError({ str: error.message });
      });
  };

  if (isClaimable || activeSpace?.isTemporary || isDemoSpace)
    return (
      <S.ChatThreadsWrapper pageMinHeight={pageMinHeight}>
        <S.ChatThreadsBlockedWrapper>
          <SidebarThreadsBlocked
            isTemporarySpace={activeSpace?.isTemporary}
            isClaimable={isClaimable}
            isDemoSpace={isDemoSpace}
          />
        </S.ChatThreadsBlockedWrapper>
      </S.ChatThreadsWrapper>
    );

  const listElement = filteredThreads.map(thread => {
    const threadId = `sidebar-thread-${thread?.id}`;
    const usersWithoutMe = getUsersWithoutMe(thread?.users, activeUser?.uuid);
    const { disabled, url } = getAvatarData(usersWithoutMe);
    return (
      <ThreadItem
        key={threadId}
        title={getTitleFromUsers(usersWithoutMe, {
          removed: translate("chat_thread_user_removed")
        })}
        id={threadId}
        isMuted={thread?.muted}
        onClick={() => navigateToThread(thread?.id)}
        onLongPress={() => setThreadViewOptions(thread)}
        notification={thread?.muted ? 0 : thread?.unreadMessages}
        badge={
          <S.AvatarThreadItem
            disabled={disabled}
            size="image"
            rounded
            url={url}
            theme={whiteTheme}
          />
        }
        numberUsers={usersWithoutMe.length}
        lastMessageDate={thread?.lastMessage?.createdAt}
        lastMessageText={thread?.lastMessage?.message}
      />
    );
  });

  return (
    <>
      <S.ChatThreadsWrapper pageMinHeight={pageMinHeight}>
        <S.SearchBarWrapper>
          <SearchBar
            variant="thinner"
            theme={whiteTheme}
            onChange={event => {
              event.persist();
              setQueryString(event.target.value);
            }}
            value={queryString}
            placeholder={translate("chats_page_search_label")}
          />
        </S.SearchBarWrapper>
        <S.NewChatWrapper onClick={showNewThreadModal}>
          <Box display="flex" alignItems="center" gap="8px">
            <Icon
              icon="chatFilled"
              width={24}
              height={24}
              initialViewbox
              color={primaryColor[500]}
            />
            <Heading level="5">
              {translate("chats_page_new_chat_label")}
            </Heading>
          </Box>
          <Icon icon="plusThin" width={20} height={20} initialViewbox />
        </S.NewChatWrapper>

        <S.ChatThreads>{listElement}</S.ChatThreads>
      </S.ChatThreadsWrapper>
      <BottomModalSheet
        isOpen={!!threadViewOptions}
        onClose={() => setThreadViewOptions(null)}
        onConfirm={() => setThreadViewOptions(null)}
        withAdaptiveHeight
        withHeaderLabels={false}
        title={null}
      >
        <S.OptionsMessageContainerMobile>
          <ListItem
            id="threadActionsMute-1"
            icon={threadViewOptions?.muted ? "bellEmpty" : "bellEmptyCrossed"}
            label={translate(
              threadViewOptions?.muted
                ? "thread_toolbar_cta_unmute"
                : "thread_toolbar_cta_mute"
            )}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              muteThread({
                threadId: threadViewOptions?.id,
                muted: threadViewOptions?.muted
              });
              setThreadViewOptions(null);
            }}
          />
        </S.OptionsMessageContainerMobile>
      </BottomModalSheet>
    </>
  );
};

export default observer(ChatPage);
