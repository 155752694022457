/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import { ExerciseType } from "../types";

export type ExerciseMinimal = {
  name: string;
  type: ExerciseType;
};
/**
 * @name ExerciseStore
 *
 * @description
 * Store for the exercise values that needs to be shared across the codebase
 */
class ExerciseStore {
  $activeExerciseMinimal: ExerciseMinimal = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get activeExerciseName() {
    return this.$activeExerciseMinimal?.name;
  }

  get activeExerciseType() {
    return this.$activeExerciseMinimal?.type;
  }

  setActiveExerciseMinimal = (exercise: ExerciseMinimal) => {
    this.$activeExerciseMinimal = exercise;
  };
}

export default ExerciseStore;
