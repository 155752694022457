import { ActionButton, Box, Icon, Text } from "@arcadia/design-system";
import React from "react";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { ModuleMode } from "src/js/pages/modules/Modules.types";
import { useActiveBoard } from "src/js/context/BoardProvider";
import { CropImageModal } from "src/js/components/CropImageModal";
import { useResourcePreviewDetails } from "src/js/hooks/resources";
import * as S from "./ResourcePreview.styles";
import { ResourcePreviewProps } from "./ResourcePreview.types";

const ResourcePreview = ({
  resourceType,
  hasPreviewGenerated,
  resourceImage,
  commentsCount,
  mimeType,
  isSuggestedResource,
  approveSuggestedResource,
  rejectSuggestedResource,
  resourceId,
  isBoardElementEnabled,
  moduleId,
  resourcePosition,
  resourcePublicUrl = "",
  author,
  title
}: ResourcePreviewProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    GroupStore: { userIsTeacher },
    ModalStore: { openModal, closeModal },
    UserStore: { activeUser }
  } = useStores();
  const activeBoard = useActiveBoard();
  const {
    getResourcePreview,
    getResourcePlaceholderIcon,
    shouldDisplayIconAsResourceLabel,
    getResourceMimeTypeIcon,
    getResourceTypeLabel,
    saveNewCroppedImage
  } = useResourcePreviewDetails();

  const userHasEditPermission =
    userIsTeacher ||
    (activeBoard.mode === ModuleMode.edit && activeUser.id === author.id); // user can edit if the board is open or if is a teacher or if is the creator of the resources

  const updateNewCroppedImage = (
    newCroppedImage: File,
    setButtonDisabled: (isDisabled: boolean) => void
  ) => {
    saveNewCroppedImage(
      newCroppedImage,
      setButtonDisabled,
      moduleId,
      resourceId
    );
  };

  const openCropImageModal = () => {
    openModal(() => (
      <CropImageModal
        close={closeModal}
        shouldZoomInImage={hasPreviewGenerated}
        imagePreview={getResourcePreview(hasPreviewGenerated, resourceImage)}
        imageMimeType={mimeType}
        imageTitle={title}
        saveNewCroppedImage={updateNewCroppedImage}
        aspect={6 / 5}
        modalTitle="resource_edit_thumbnail_title"
      />
    ));
  };

  return (
    <S.ResourcePreview
      background={getResourcePreview(hasPreviewGenerated, resourceImage)}
      hasPreviewGenerated={hasPreviewGenerated}
    >
      {!isBoardElementEnabled && (
        <S.BlockedResourceOverlay>
          <S.IconWrapper>
            <Icon icon="lockClosed" width="16px" height="16px" />
          </S.IconWrapper>
          <Text type="formDescription">
            {translate("student_board_element_list_locked_element_message")}
          </Text>
        </S.BlockedResourceOverlay>
      )}
      {!hasPreviewGenerated && (
        <S.ResourceTypeIconPlaceholder>
          <Icon
            icon={getResourcePlaceholderIcon(resourceType, mimeType)}
            height={40}
            width={40}
            initialViewbox
          />
        </S.ResourceTypeIconPlaceholder>
      )}
      {userHasEditPermission && (
        <S.ThumbnailIconContainer
          id="thumbnail-icon-container"
          onClick={event => {
            event.stopPropagation();
            openCropImageModal();
          }}
        >
          <Icon icon="image" width="16px" height="16px" />
        </S.ThumbnailIconContainer>
      )}
      <S.ResourceTypeDetails isSuggestedResource={isSuggestedResource}>
        {isSuggestedResource ? null : (
          <Box display="flex" alignItems="center" gap={8}>
            <Icon icon="comment" height={16} width={16} />
            <Text type="formSubtitle">
              {`${commentsCount} ${translate("module_resource_comment_label")}`}
            </Text>
          </Box>
        )}
        {shouldDisplayIconAsResourceLabel(
          resourceType,
          mimeType,
          resourcePublicUrl
        ) ? (
          <Icon
            icon={getResourceMimeTypeIcon(
              resourceType,
              mimeType,
              resourcePublicUrl
            )}
            width={20}
            height={18}
            initialViewbox
          />
        ) : (
          <S.ResourceTypeLabel>
            <Text type="label">
              {getResourceTypeLabel(resourceType, mimeType)}
            </Text>
          </S.ResourceTypeLabel>
        )}
      </S.ResourceTypeDetails>
      {isSuggestedResource && userIsTeacher ? (
        <S.SuggestedResourceActionButtons id="suggested-resource-action-buttons-container">
          <ActionButton
            variant="primary"
            theme={whiteTheme}
            icon="check2"
            onClick={event => {
              event.stopPropagation();
              approveSuggestedResource(resourceId, resourcePosition);
            }}
          />
          <ActionButton
            variant="secondary"
            theme={whiteTheme}
            icon="close"
            onClick={event => {
              event.stopPropagation();
              rejectSuggestedResource(resourceId);
            }}
          />
        </S.SuggestedResourceActionButtons>
      ) : null}
    </S.ResourcePreview>
  );
};

export default ResourcePreview;
