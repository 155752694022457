import React from "react";
import { Icon, Text } from "@arcadia/design-system";
import * as S from "./PermissionCard.styles";

const PermissionCard = ({ children, ...props }) => {
  return (
    <S.Card {...props}>
      <S.TextContainer>
        <Text type="formDescription">{children}</Text>
      </S.TextContainer>
      <S.IconContainer>
        <Icon icon="successCircle" height="24px" width="24px" />
      </S.IconContainer>
    </S.Card>
  );
};

export default PermissionCard;
