import { placeholderColors } from "@ds_themes/index";

export const AVATAR_WITH_INITIALS_SIZE = {
  extraSmall: "30px",
  small: "40px",
  medium: "48px",
  large: "52px",
  big: "64px",
  auto: "100%"
};

export const AVATAR_WITH_INITIALS_FONT_SIZE = {
  extraSmall: "12px",
  small: "16px",
  medium: "22px",
  large: "22px",
  big: "22px",
  auto: "inherit"
};

export const AVATAR_WITH_INITIALS_COLOR = placeholderColors;
