import React from "react";

import * as S from "./NoResults.styles";

const scaleUp = {
  initial: { opacity: 0, scale: 0.6 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.6 }
};

const NoResults = () => {
  return (
    <S.Card {...scaleUp}>
      <S.Avatar {...scaleUp} transition={{ delay: 0.5 }} />
      <S.SkeletonContainer>
        <S.Skeleton
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "30%" }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.6 }}
        />
        <S.Skeleton
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "65%" }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.8 }}
        />
      </S.SkeletonContainer>
    </S.Card>
  );
};

export default NoResults;
