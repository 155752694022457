import { Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";
import { ListSelectorItem } from "../../global/ListSelector";
import { SAvatarWrapper, SCheckIndicator } from "./MigrateGroupsItemCard.types";

export const CheckIndicator = styled.div<SCheckIndicator>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.primaryColor[500]};
  ${({ isSelected }) =>
    !isSelected &&
    css`
      path,
      circle {
        display: none;
      }
    `}
`;

export const Description = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 2px;
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvatarWrapper = styled.div<SAvatarWrapper>`
  div {
    width: 48px;
    height: 48px;
    border-radius: ${({ isSquared }) => (isSquared ? "8px" : "100%")};
  }
`;

export const MigrateGroupsItemCardContent = styled.div`
  width: 100%;
  padding: 0 12px;
  overflow: hidden;
  span {
    display: block;
  }
`;

export const MigrateGroupsItemCardWrapper = styled(ListSelectorItem)`
  padding: 16px;
  margin-bottom: 12px;
`;
