import { useEffect } from "react";
import { useParams } from "react-router";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation/TranslationProvider";

const MatchDashboardRoute = () => {
  const { space_id } = useParams();
  const { SpaceStore, SpacesSettingsStore } = useStores();
  const { translate } = useTranslation();

  useEffect(() => {
    if (space_id) {
      SpaceStore.setActiveSpaceById(space_id);
      SpacesSettingsStore.getSpaceSettings({
        spaceId: space_id
      }).catch(() => {
        showToastError({
          str: translate("spaces_settings_general_error_message")
        });
      });
    }
  }, [space_id]);

  return null;
};

export default MatchDashboardRoute;
