import styled from "styled-components";
import colors from "@ds_themes/colors";

import headingDesktop from "./Heading.desktop.const";
import headingMobile from "./Heading.mobile.const";
import { SHeadingProps } from "./types";

export const Heading = styled.span<SHeadingProps>`
  color: ${colors.grey["50"]};
  white-space: pre-wrap;

  /*  @media screen and (max-width: 767px) {
  ${({ level = "1" }) => ({
    ...headingMobile[level]
  })}
  }*/

  ${({ level = "1" }) => ({
    ...headingDesktop[level]
  })}
`;
