import React from "react";
import { useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { deleteGroup, removeUser } from "src/js/repository/groupRepository";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { ConfirmationAlert } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { GroupDeleteModal } from "../GroupDeleteModal";

const useGroupActions = () => {
  const {
    ModalStore: { closeModal, openModal },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    SpaceGroupListStore: { removeGroupFromList },
    SpaceStore: {
      activeSpace,
      isAdmin,
      removeSpaceFromSpaceList,
      cleanLastActiveSpaceLocalStorage
    }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const callDeleteGroup = (groupId: number) => {
    deleteGroup(groupId)
      .then(() => {
        showToastSuccess({
          str: translate("group_delete_confirmation_message")
        });
        const newList = removeGroupFromList({ groupId });
        if (newList.length === 0 && !isAdmin && activeSpace) {
          removeSpaceFromSpaceList(activeSpace.space.id);
          cleanLastActiveSpaceLocalStorage();
          navigateTo(createUrl("home"));
        } else {
          navigateTo(
            createUrl("space_groups", { space_slug: activeSpace?.space?.slug })
          );
        }
      })
      .catch(() => {
        showToastError({
          str: translate("group_delete_error_message")
        });
      })
      .finally(() => closeModal());
  };

  const callLeaveGroup = (groupId: number) => {
    removeUser(groupId, "me")
      .then(() => {
        showToastSuccess({
          str: translate("group_leave_confirmation_message")
        });
        const newList = removeGroupFromList({ groupId });
        if (newList.length === 0 && !isAdmin && activeSpace) {
          removeSpaceFromSpaceList(activeSpace.space.id);
          cleanLastActiveSpaceLocalStorage();
          navigateTo(createUrl("home"));
        } else {
          navigateTo(
            createUrl("space_groups", { space_slug: activeSpace?.space?.slug })
          );
        }
      })
      .catch(() => {
        showToastError({ str: translate("group_leave_error_message") });
      });
  };

  const showDeleteGroupModal = (groupId: number) => {
    openModal(() => (
      <GroupDeleteModal
        onCloseModal={() => closeModal()}
        onDeleteGroup={() => callDeleteGroup(groupId)}
      />
    ));
  };

  const showLeaveGroupConfirmation = (groupId: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("group_leave_message")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          closeConfirmDialog();
          callLeaveGroup(groupId);
        }}
      />
    );
  };

  return {
    showDeleteGroupModal,
    showLeaveGroupConfirmation
  };
};

export default useGroupActions;
