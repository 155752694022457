import styled from "styled-components";
import { agenda } from "#themes/grey/agenda";
import {
  EventCategoryType,
  EventStatus,
  EventStatusType
} from "src/js/pages/calendar/Calendar.types";

type TypeContainerStyleProps = {
  type: EventCategoryType;
  status?: EventStatusType;
};

export const TagContainer = styled.div<TypeContainerStyleProps>`
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 10px;
  height: 20px;
  background-color: ${({ status, type }) =>
    status === EventStatus.Finished
      ? agenda.card[type.toLowerCase()].finished.background
      : agenda.card[type.toLowerCase()].background};
  color: ${({ type }) => agenda.card[type.toLowerCase()].color};
  position: relative;
  gap: 6px;
  margin: 2px;
  text-transform: uppercase;
`;

export const LabelText = styled.div`
  margin-left: 2px;
  white-space: nowrap;
`;
