import axios from "axios";
import { __CLIENT_ID__ } from "src/js/settings/settingsGeneral";
import {
  __API_AUTH_PATH__,
  __API_UNVERSIONED_PATH__
} from "src/js/settings/settingsUrl";
import {
  extractErrorMessage,
  getErrorMessage
} from "src/js/modules/messageManager";
import {
  api_registration_confirm,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";

/**
 * @param {string} username
 * @param {string} password
 * @returns {Promise<{access_token: string, expires_in: number, refresh_token: string}>}
 */
function fetchAccessToken(username, password) {
  const data = {
    client_id: __CLIENT_ID__,
    username,
    password,
    user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
  return axios({
    withCredentials: true,
    method: "post",
    url: __API_AUTH_PATH__,
    data
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        getErrorMessage(error.response.data, "error_fetch_access_token")
      );
      throw Err;
    });
}

export function confirmRegistration(token) {
  return axios({
    withCredentials: true,
    method: "get",
    url:
      __API_UNVERSIONED_PATH__ + getVersionedApiUrl(api_registration_confirm()),
    params: {
      token
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_base_access_token")
      );
      throw Err;
    });
}

export { fetchAccessToken as default };
