import styled from "styled-components";

export const BannerContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.basicWhite};
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.panels};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(-100%)"};
  transition:
    opacity 0.2s ease-out,
    transform 0.2s ease-out;
  width: 100%;
  max-width: 500px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const BannerIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
  margin-left: 16px;
`;

export const BannerText = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.title};
`;
