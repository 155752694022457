import React from "react";
import { Editor } from "@tiptap/core";
import { Divider, EditorExtensionType } from "../BaseTextEditor.types";
import { renderButtonFromExtension } from "../BaseTextEditor.utils";

import * as S from "./BaseTextEditorMenu.styles";

const BaseTextEditorMenu = ({
  editor,
  extensions
}: {
  editor: Editor;
  extensions: EditorExtensionType[];
}) => {
  if (!extensions && !extensions.length) return null;
  return (
    <S.Row>
      {extensions.map((extension, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${extension}-${index}`}>
          {extension === Divider ? (
            <S.Divider />
          ) : (
            renderButtonFromExtension(editor, extension)
          )}
        </React.Fragment>
      ))}
    </S.Row>
  );
};

export default BaseTextEditorMenu;
