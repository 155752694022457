import { useEffect, useState } from "react";
import {
  AIGenerationRequestEnum,
  AIModule
} from "src/js/hooks/useAIServiceFetcher.types";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiCourseGeneratorEvent, EventDomain } from "src/js/types";
import {
  useAIServiceFetcher,
  useAITransactionInfo,
  useStores
} from "src/js/hooks";
import { AITransactionStatus } from "src/js/stores/AIStore";
import { CourseDetailsWizardForm } from "../../CourseDetailsWizard/CourseDetailsWizard.types";
import {
  parseAIModule,
  parseModuleListItem,
  parseModulesReqBody
} from "../AICourseWizardModal.utils";
import { ModuleListItem } from "../../CourseListPreviewStep/components/PreviewModuleList";
import { CourseWizardModalStep } from "../AICourseWizardModal.const";

export const useAIState = () => {
  const AITransactionInfo = useAITransactionInfo({ isAI: true });
  const [step, setStep] = useState<CourseWizardModalStep>(
    CourseWizardModalStep.courseDetails
  );
  const [list, setList] = useState<ModuleListItem[]>(null);
  const [firstStepData, setFirstStepData] = useState<CourseDetailsWizardForm>();

  const {
    GroupStore: { activeGroup },
    UserStore: { activeUser },
    AIStore: { setAITransactionId, reset, status }
  } = useStores();

  useEffect(() => {
    if (status !== AITransactionStatus.fail) return;
    showToastError({ str: "Attenzione, si è verificato un errore" });
    setStep(CourseWizardModalStep.modulesPreview);
  }, [status]);

  useEffect(() => {
    if (!AITransactionInfo?.aiTransactionId) return () => {};
    setAITransactionId(AITransactionInfo.aiTransactionId);

    return () => {
      reset();
    };
  }, [AITransactionInfo?.aiTransactionId]);

  const fetchModules = useAIServiceFetcher({
    requestType: AIGenerationRequestEnum.modules,
    AITransactionInfo
  });
  const fetchCourse = useAIServiceFetcher({
    requestType: AIGenerationRequestEnum.course,
    AITransactionInfo
  });

  const fetchPreview = (data?: CourseDetailsWizardForm) => {
    fetchModules({
      body: parseModulesReqBody(data)
    })
      .then(({ modules }) => {
        const parsedList = parseAIModule(modules, data.effort);
        setList(parsedList);
        setStep(CourseWizardModalStep.modulesPreview);
      })
      .catch(error => {
        setStep(CourseWizardModalStep.courseDetails);
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  };

  const onCourseDetailsSubmit = (courseDetails: CourseDetailsWizardForm) => {
    setStep(CourseWizardModalStep.draftingModulePreview);
    setFirstStepData(courseDetails);
    fetchPreview(courseDetails);
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardStep5GeneratePreview
    );
  };

  const onRegenerateModules = () => {
    setStep(CourseWizardModalStep.draftingModulePreview);
    fetchPreview(firstStepData);
  };

  const onBack = () => {
    if (step === CourseWizardModalStep.modulesPreview)
      return setStep(CourseWizardModalStep.courseDetails);
    return null;
  };

  const onPreviewModulesSubmit = (newList: ModuleListItem[]) => {
    setStep(CourseWizardModalStep.draftingCourse);
    const notEmptyList = newList.filter(
      module => module.title && module.description
    );
    const modules: AIModule[] = parseModuleListItem(notEmptyList);
    setList(notEmptyList);

    fetchCourse({
      body: {
        groupId: activeGroup.id,
        modules,
        userUuid: activeUser.uuid
      }
    }).catch(error => {
      setStep(CourseWizardModalStep.modulesPreview);
      showToastError({
        str: extractErrorMessage(error, "Attenzione, si è verificato un errore")
      });
    });
  };

  return {
    onPreviewModulesSubmit,
    onCourseDetailsSubmit,
    onRegenerateModules,
    firstStepData,
    onBack,
    list,
    step
  };
};
