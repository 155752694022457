import { SortableList } from "@arcadia/design-system";
import React from "react";
import { PreviewModuleCard } from ".";

import * as S from "./PreviewModuleList.styles";

export type ModuleListItem = {
  id: string;
  title: string;
  description: string;
  time: number; // this time is the same for all Modules, and is defined in step 1
  isExpanded?: boolean;
};

export type ModuleSortableListItem = ModuleListItem & {
  component: JSX.Element;
};

const PreviewModuleList = ({
  list,
  onDelete,
  onUpdate
}: {
  list: ModuleListItem[];
  onDelete: (removeId: string) => void;
  onUpdate: (newList: ModuleListItem[]) => void;
}) => {
  return (
    <SortableList
      items={list}
      onChange={onUpdate}
      renderItem={({ title, description, time, id, isExpanded }) => (
        <SortableList.Item id={id}>
          <S.CardWrapper key={id}>
            <PreviewModuleCard
              defaultTitle={title}
              defaultDescription={description}
              defaultExpanded={isExpanded}
              onDelete={() => onDelete(id)}
              hourlyEffort={time}
              onSave={(newTitle, newDescription) => {
                const listAux = [...list];
                const itemId = listAux.findIndex(item => item.id === id);
                listAux[itemId].description = newDescription;
                listAux[itemId].title = newTitle;

                onUpdate(listAux);
              }}
              // eslint-disable-next-line @typescript-eslint/no-shadow
              onExpand={isExpanded => {
                const listAux = [...list];
                const itemId = listAux.findIndex(item => item.id === id);
                listAux[itemId].isExpanded = isExpanded;
                onUpdate(listAux);
              }}
              sortable
            />
          </S.CardWrapper>
        </SortableList.Item>
      )}
    />
  );
};

export default PreviewModuleList;
