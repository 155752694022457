import styled from "styled-components";

export const AnalyticsPageContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.title};

  padding: 60px 0px 120px;
  @media (max-width: 768px) {
    padding: 10px 0px 480px;
  }
`;

export const AnalyticsSectionTitle = styled.div`
  margin-bottom: 8px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const AnalyticsSectionTrendTitle = styled.div`
  margin-bottom: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AnalyticsSectionTrendContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AnalyticsIndexContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 7px;
  row-gap: 7px;
  margin: 0px auto;
  margin-bottom: 24px;
`;
