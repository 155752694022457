import styled from "styled-components";
import { motion } from "framer-motion";

export const Card = styled(motion.div)`
  padding: 40px 52px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UniListWrapper = styled(motion.div)`
  width: 100%;
  max-height: 650px;
  overflow-y: scroll;
  margin-top: 60px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ButtonWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: hidden;
  justify-content: center;
`;
