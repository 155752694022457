import React, { useState, FC } from "react";
import { useTheme } from "styled-components";
import {
  Toggle,
  Icon,
  Button,
  Input,
  ContextualFeedback
} from "@arcadia/design-system";

import {
  translateString,
  useTranslation
} from "src/js/translation/TranslationProvider";
import * as ToggleBaseS from "../EditBoardToggleElement/EditBoardToggleElement.styles";
import * as S from "./MilestoneElement.styles";
import { MilestoneElementTypes } from "./types";

const MilestoneElement: FC<MilestoneElementTypes> = ({
  onSaveMessage,
  setToggleValue,
  setMilestoneMessage,
  toggleValue,
  isDesktop,
  milestoneMessage = translateString({
    text: "milestone_sidebar_input_default_value"
  }),
  originalMilestoneMessage
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const placeholderMessage = translate("milestone_sidebar_input_placeholder");

  const onToggleChange = (value: boolean) => {
    setToggleValue(value);
  };

  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMilestoneMessage(e.target.value);
    setShowSuccessBanner(false);
  };

  const handleSubmit = () => {
    onSaveMessage(milestoneMessage).then(() => {
      setShowSuccessBanner(true);
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isDesktop) {
      e.preventDefault();
      if (milestoneMessage && milestoneMessage.length) {
        handleSubmit();
      }
    }
  };

  const shouldHideConfirmMessage =
    originalMilestoneMessage === milestoneMessage;

  return (
    <S.LayoutWrapper>
      <S.Layout isOpen={toggleValue}>
        <ToggleBaseS.IconContainer>
          <Icon icon="crownEmpty" height={16} width={16} />
        </ToggleBaseS.IconContainer>
        <ToggleBaseS.TextContainer>
          <ToggleBaseS.Title>
            {translate("milestone_sidebar_title")}
          </ToggleBaseS.Title>
          <ToggleBaseS.Description>
            {translate("milestone_sidebar_description")}
          </ToggleBaseS.Description>
        </ToggleBaseS.TextContainer>
        <Toggle
          title={translate("milestone_sidebar_title")}
          theme={whiteTheme}
          checked={toggleValue}
          size="small"
          onChange={event => onToggleChange(event.target.checked)}
        />
      </S.Layout>
      <S.Content isOpen={toggleValue}>
        <S.TextAreaWrapper onKeyPress={handleKeyPress}>
          <Input
            name="milestoneMessage"
            label="milestoneMessage"
            placeholder={placeholderMessage}
            theme={whiteTheme}
            hideLabel
            value={milestoneMessage}
            onChange={onMessageChange}
            type="textarea"
            rows={3}
          />
        </S.TextAreaWrapper>
        {showSuccessBanner && (
          <S.BannerWrapper>
            <ContextualFeedback theme={whiteTheme}>
              {translate("milestone_message_success")}
            </ContextualFeedback>
          </S.BannerWrapper>
        )}

        {shouldHideConfirmMessage
          ? null
          : isDesktop && (
              <S.ButtonContainer>
                <Button
                  onClick={handleSubmit}
                  theme={whiteTheme}
                  variant="primary"
                  disabled={!milestoneMessage || milestoneMessage.length === 0}
                >
                  {translate("milestone_sidebar_button_label")}
                </Button>
              </S.ButtonContainer>
            )}
      </S.Content>
    </S.LayoutWrapper>
  );
};

export default MilestoneElement;
