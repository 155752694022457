import { __CALENDAR_ARROW_ICON__ } from "src/js/settings/settingsImage";
import styled from "styled-components";

export const DatePickerInputContainer = styled.div`
  position: relative;
  > div > div > div > span {
    line-height: 34px;
  }

  & .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0px;
  }

  & .react-datepicker {
    display: flex;
    flex-wrap: wrap;
    max-width: 670px;
    padding-bottom: 10px;
    font-family: ${({ theme }) => theme.fontFamily.title};

    & .react-datepicker__aria-live {
      position: absolute;
      clip-path: circle(0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
      white-space: nowrap;
    }

    & .react-datepicker__triangle {
      display: none;
    }

    & .react-datepicker__navigation--previous,
    & .react-datepicker__navigation--next {
      background: no-repeat center;
    }

    & .react-datepicker__navigation-icon--previous::before,
    & .react-datepicker__navigation-icon--next::before {
      display: none;
    }

    & .react-datepicker__navigation--previous {
      left: 15px;
      transform: scaleX(-1);
      background-image: url(${__CALENDAR_ARROW_ICON__});
    }

    & .react-datepicker__navigation--next {
      right: 15px;
      background-image: url(${__CALENDAR_ARROW_ICON__});
    }

    & .react-datepicker__navigation {
      width: 24px;
      height: 24px;
      border-radius: 16px;
      border: none;
      top: 10px;

      &:hover {
        background-color: ${({ theme }) => theme.datepicker.text.background};
      }
    }
  }

  & .react-datepicker__header {
    background-color: white;
    height: 70px;

    & .react-datepicker__day-name {
      color: ${({ theme }) => theme.datepicker.text.color};
      line-height: 18px;
      font-size: 12px;
      text-transform: uppercase;
    }

    & .react-datepicker__current-month {
      font-weight: 500;
      color: ${({ theme }) => theme.datepicker.text.color} !important;
      padding: 5px 0px 10px 0px;
    }

    & .react-datepicker__day-name,
    & .react-datepicker__day,
    & .react-datepicker__time-name {
      max-width: 45px;
      width: 100%;
      text-align: center;
      margin: auto;
      @media (max-width: 380px) {
        max-width: 35px;
      }
    }
  }

  & .react-datepicker__month-container {
    flex: 1;
    width: 100%;
    max-width: 670px;

    & .react-datepicker__month {
      font-size: 14px;

      & .react-datepicker__week {
        height: 35px;

        & .react-datepicker__day--in-selecting-range,
        & .react-datepicker__day--in-range {
          background-color: ${({ theme }) =>
            theme.primaryColor[900]} !important;
          color: ${({ theme }) => theme.primaryColor[500]} !important;
        }

        & .react-datepicker__day--outside-month {
          padding: 0px !important;
          color: ${({ theme }) => theme.colors.accent3} !important;
          height: 0px !important;
        }

        & .react-datepicker__day--disabled {
          color: ${({ theme }) => theme.colors.accent3} !important;
        }

        // selected (last)
        & .react-datepicker__day--range-start,
        & .react-datepicker__day--selected,
        & .react-datepicker__day--range-end {
          background-color: ${({ theme }) =>
            theme.primaryColor[500]} !important;
          color: ${({ theme }) => theme.colors.basicWhite} !important;
        }
        & .react-datepicker__day--keyboard-selected {
          background-color: transparent;
          color: ${({ theme }) => theme.primaryColor[500]};
        }
      }

      & .react-datepicker__day-name,
      & .react-datepicker__day,
      & .react-datepicker__time-name {
        color: ${({ theme }) => theme.datepicker.text.color};
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 5px 10px 10px 5px;

        @media (max-width: 380px) {
          margin: 2px 5px 5px 2px;
        }
      }
    }
  }

  & .react-datepicker__input-container {
    label {
      color: ${({ theme }) => theme.colors.grey[300]};
    }
  }
`;
