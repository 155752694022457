import colors from "@ds_themes/colors";
import { CheckboxThemeOptions } from "@ds_themes/types";

export const checkbox: CheckboxThemeOptions = {
  background: colors.grey[1000],
  border: `1px solid ${colors.violet[501]}`,
  checked: {
    background: colors.violet[501],
    text: colors.grey[1000]
  },
  disabled: {
    border: `1px solid ${colors.violet[700]}`,
    background: colors.violet[700],
    text: colors.violet[700]
  },
  hover: {
    border: `1.5px solid ${colors.violet[500]}`,
    background: colors.grey[1000],
    text: colors.violet[500]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  },
  text: ""
};
