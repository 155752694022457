import {
  Lottie,
  LottieProps,
  Button as WSButton,
  Text as WSText
} from "@arcadia/design-system";
import NoMessagesAnimationJSON from "Images/lottie/hello.json";
import styled from "styled-components";

export const Button = styled(WSButton)`
  margin: auto;
`;

export const Text = styled(WSText)`
  display: block;
  text-align: center;
  margin-bottom: 24px;
`;

export const NoMessagesAnimation = styled(Lottie).attrs(
  (props: LottieProps) => ({
    src: NoMessagesAnimationJSON,
    ...props
  })
)`
  margin-bottom: 12px;
  width: 140px;
`;

export const SidebarThreadsBlockedWrapper = styled.div`
  padding: 40px 0;
`;
