import {
  startOfToday,
  startOfYesterday,
  subDays,
  subMonths,
  subYears
} from "date-fns";
import {
  BaseChartResponse,
  DashboardRangeTime,
  DashboardRangeTimeType,
  DashboardXAxisFrequency,
  StateTrendSeverity,
  StateTrendSeverityType
} from "src/js/types";

export const generateFromToDate = (range: DashboardRangeTimeType) => {
  const today = startOfToday();
  const yesterday = startOfYesterday();
  switch (range) {
    case DashboardRangeTime.LastDay:
      return { from: yesterday, to: yesterday };
    case DashboardRangeTime.LastMonth:
      return { from: subMonths(today, 1), to: yesterday };
    case DashboardRangeTime.Last3Month:
      return { from: subMonths(today, 3), to: yesterday };
    case DashboardRangeTime.Last6Month:
      return { from: subMonths(today, 6), to: yesterday };
    case DashboardRangeTime.LastYear:
      return { from: subYears(today, 1), to: yesterday };
    case DashboardRangeTime.Last7Days:
    default:
      return { from: subDays(today, 7), to: yesterday };
  }
};

export const getXAxisFrequency = (daysDiff: number) => {
  if (daysDiff) {
    if (daysDiff >= 1 && daysDiff <= 9) {
      return DashboardXAxisFrequency.EveryDay;
    }
    if (daysDiff >= 10 && daysDiff <= 23) {
      return DashboardXAxisFrequency.AlternateDays;
    }
    if (daysDiff >= 24 && daysDiff <= 67) {
      return DashboardXAxisFrequency.EveryWeek;
    }
    if (daysDiff >= 68 && daysDiff <= 180) {
      return DashboardXAxisFrequency.EveryMonth;
    }
    if (daysDiff >= 181) {
      return DashboardXAxisFrequency.Every3Months;
    }
  }
  return DashboardXAxisFrequency.EveryDay;
};

export const handleMetricCard = <T>({
  baseChartResponse,
  measurementUnit,
  trendMeasurementUnit,
  numberOfDecimalDigits = 1,
  resultMultiplier = 1
}: {
  baseChartResponse: BaseChartResponse<T>;
  measurementUnit: string;
  trendMeasurementUnit: string;
  numberOfDecimalDigits?: number;
  resultMultiplier?: number;
}) => {
  const { averageNumber, averageNumberTrend } = baseChartResponse || {};

  const resultLabel = `${((averageNumber * resultMultiplier) / 100).toFixed(
    numberOfDecimalDigits
  )}${measurementUnit}`;
  const trendLabel = `${Math.abs(
    (averageNumberTrend * resultMultiplier) / 100
  ).toFixed(numberOfDecimalDigits)} ${trendMeasurementUnit}`;
  const trendSeverity: StateTrendSeverityType =
    averageNumberTrend < 0
      ? StateTrendSeverity.Critical
      : StateTrendSeverity.Normal;

  return { resultLabel, trendLabel, trendSeverity };
};
