import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledText = styled(Text)`
  display: block;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 16px;
  white-space: pre-line;
`;

export const InputSubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  margin-top: auto;
`;

export const SchoolClaimSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  min-height: 260px;
`;
