import styled from "styled-components";
import shadow from "@ds_themes/shadow";

export const PostCreation = styled.div`
  width: 100%;
  height: fit-content;
  box-shadow: ${shadow[200]};
  border-radius: 16px;
  padding: 15px;
  position: relative;
  background-color: ${({ theme }) => theme.post.basic.background};
  color: ${({ theme }) => theme.post.basic.text};
  div div span {
    min-height: 104px;
  }
`;

export const AvatarBadgeScore = styled.div`
  height: 30px;
  width: 30px;
  left: 17px;
  top: 57px;
  border-radius: 35px;
  display: block;
  position: absolute;
  line-height: 26px;
  text-align: center;
  background-color: ${({ theme }) => theme.post.badgeScore.background};
  border: 1px solid ${({ theme }) => theme.post.badgeScore.border};
`;

export const WrapperUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 40px;
  svg {
    margin-left: 4px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.post.basic.ribbon};
  }
  span {
    padding-left: 8px;
  }
`;

export const WrapperButton = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
`;
