import React, { useState, useEffect, useRef, FC } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { BareCheckbox } from "@ds_universal/data_entry/Checkbox";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";
import { Tag } from "@ds_universal/data_display/Tag";
import { whiteTheme } from "@ds_themes/white";
import { DoodleLoader } from "@ds_universal/DoodleLoader";
import { SearchBar } from "@ds_universal/data_entry/SearchBar";
import * as S from "@ds_universal/data_entry/DropdownMultiple/DropdownMultiple.styles";
import { greyTheme } from "@ds_themes/grey";

import { DropdownMultipleProps } from "./types";

// TODO: Consider refactoring this and the other 2 dropdown components and maybe combine them into a single component
const DropdownMultiple: FC<DropdownMultipleProps> = ({
  theme,
  optionsList,
  selectedOptionIds = [],
  setSelectedOptionIds = () => {},
  placeholder = "",
  hideButtonTags,
  width = "100%",
  disabled,
  hasNext = false,
  showNext = () => {},
  scrollableListId = "dropdown-multiple-list",
  searchable,
  searchPlaceholder = "",
  onSearchFunction = () => {},
  searchQuery = "",
  withFilterIconAsAButton = false,
  optionsListAlignment,
  optionsListWidth,
  withLoader = true,
  onOptionsOpen = () => {}
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [indexOnFocus, setIndexOnFocus] = useState<number>(-1);
  const optionsRef = useRef<(HTMLDivElement | null)[]>([]);
  const ref = useRef<HTMLDivElement | null>(null);

  // theme.dropdown is the correct pick, greyTheme/whiteTheme are keep for retro-compatibility
  const searchbarTheme = (
    theme.dropdown.searchbar
      ? theme.dropdown
      : theme === whiteTheme
        ? greyTheme
        : whiteTheme
  ) as DefaultTheme;
  const tagTheme = (
    theme.dropdown.tag ? theme.dropdown : whiteTheme
  ) as DefaultTheme;
  const checkboxTheme = (
    theme.dropdown.checkbox
      ? theme.dropdown
      : theme === whiteTheme
        ? greyTheme
        : whiteTheme
  ) as DefaultTheme;

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    if (!isOptionsOpen) {
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
    optionsRef.current = optionsRef.current.slice(0, optionsList.length);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOptionsOpen, optionsList]);

  useEffect(() => {
    if (indexOnFocus !== -1 && isOptionsOpen) {
      optionsRef.current[indexOnFocus]?.focus();
    }
  }, [indexOnFocus, isOptionsOpen]);

  useEffect(() => {
    onOptionsOpen(isOptionsOpen);
  }, [isOptionsOpen, onOptionsOpen]);

  const toggleOptions = () => {
    if (disabled) return;
    setIsOptionsOpen(!isOptionsOpen);
  };

  const deleteOption = ({ id }: { id: string }) => {
    const index = selectedOptionIds.indexOf(id);
    const newSelectedOptionIds = [...selectedOptionIds];
    newSelectedOptionIds.splice(index, 1);
    setSelectedOptionIds(newSelectedOptionIds);
  };

  const handleOptionClick = ({ id }: { id: string }) => {
    if (!selectedOptionIds.includes(id)) {
      setSelectedOptionIds([...selectedOptionIds, id]);
    } else {
      deleteOption({ id });
    }
  };

  const getSelectedOptionById = ({ id }: { id: string }) =>
    optionsList.find(option => option.id === id);

  const handleListKeyDown: React.KeyboardEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = e => {
    if (disabled) return;
    switch (e.key) {
      case "Enter":
      case " ":
        e.preventDefault();
        toggleOptions();
        break;
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setIndexOnFocus(
          indexOnFocus - 1 >= 0 ? indexOnFocus - 1 : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setIndexOnFocus(
          indexOnFocus === optionsList.length - 1 || indexOnFocus === -1
            ? 0
            : indexOnFocus + 1
        );
        break;
      default:
        break;
    }
  };

  const handleKeyDownkOnOption = ({
    e,
    id
  }: {
    e: React.KeyboardEvent<HTMLDivElement>;
    id: string;
  }) => {
    if (disabled) return;
    e.stopPropagation();
    switch (e.key) {
      case "Enter":
      case " ":
        handleOptionClick({ id });
        break;
      default:
        break;
    }
  };

  const onHoverOption = (index: number) => {
    setIndexOnFocus(index);
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper ref={ref} width={width}>
        {withFilterIconAsAButton ? (
          <S.FilterIconButtonContainer
            aria-expanded={isOptionsOpen}
            aria-haspopup="listbox"
            role="button"
            tabIndex={0}
            onClick={toggleOptions}
            onKeyDown={handleListKeyDown}
            disabled={disabled}
            aria-disabled={disabled}
          >
            <S.FilterIconButton
              icon="tune"
              isactive={isOptionsOpen}
              width={20}
              height={20}
              initialViewbox
            />
          </S.FilterIconButtonContainer>
        ) : (
          <S.Button
            aria-expanded={isOptionsOpen}
            aria-haspopup="listbox"
            role="button"
            tabIndex={0}
            onClick={toggleOptions}
            onKeyDown={handleListKeyDown}
            isEmpty={hideButtonTags || selectedOptionIds.length === 0}
            disabled={disabled}
            aria-disabled={disabled}
          >
            {selectedOptionIds.length > 0 && !disabled && !hideButtonTags ? (
              selectedOptionIds.map((id, index) => {
                return (
                  <div tabIndex={-1} key={index}>
                    <Tag
                      theme={tagTheme}
                      onClick={() => {
                        deleteOption({ id });
                      }}
                      type="solid"
                      color="violet"
                      label={getSelectedOptionById({ id })?.label}
                    />
                  </div>
                );
              })
            ) : (
              <Text type="cta"> {placeholder} </Text>
            )}
            <S.Arrow
              hasTags={
                selectedOptionIds.length > 0 && !disabled && !hideButtonTags
              }
            >
              {isOptionsOpen ? (
                <Icon icon="chevronSmallUp" height="16" width="16" />
              ) : (
                <Icon icon="chevronSmallDown" height="16" width="16" />
              )}
            </S.Arrow>
          </S.Button>
        )}
        {isOptionsOpen ? (
          <S.OptionContainer
            optionsListAlignment={optionsListAlignment}
            optionsListWidth={optionsListWidth}
          >
            {searchable ? (
              <S.SearchInputContainer>
                <SearchBar
                  value={searchQuery}
                  theme={searchbarTheme}
                  onChange={onSearchFunction}
                  placeholder={searchPlaceholder}
                />
              </S.SearchInputContainer>
            ) : null}
            <S.ScrollableWrapper
              id={scrollableListId}
              role="listbox"
              aria-label="lista di opzioni"
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
              withScrollbar={optionsList.length > 6}
              aria-activedescendant={optionsList[indexOnFocus]?.id}
              aria-multiselectable="true"
            >
              <InfiniteScroll
                scrollableTarget={scrollableListId}
                dataLength={optionsList.length}
                next={showNext}
                hasMore={hasNext}
                loader={
                  withLoader ? <DoodleLoader theme={theme} isMini /> : null
                }
              >
                {optionsList.map(({ id, label, isDisabled }, index) => {
                  return (
                    <S.Option
                      key={index}
                      role="option"
                      tabIndex={isDisabled ? -1 : 0}
                      aria-selected={selectedOptionIds.includes(id)}
                      aria-disabled={isDisabled}
                      onClick={e => {
                        if (isDisabled) return;
                        stopPropagation(e);
                        handleOptionClick({ id });
                      }}
                      onKeyDown={e => {
                        if (isDisabled) return;
                        handleKeyDownkOnOption({ e, id });
                      }}
                      disabled={isDisabled}
                      onMouseEnter={() => {
                        if (isDisabled) return;
                        onHoverOption(index);
                      }}
                      aria-checked={selectedOptionIds.includes(id)}
                      aria-label={label}
                      ref={el => {
                        optionsRef.current[index] = el;
                      }}
                    >
                      <div aria-hidden="true" tabIndex={-1}>
                        <ThemeProvider theme={checkboxTheme}>
                          <BareCheckbox
                            aria-hidden="true"
                            tabIndex={-1}
                            value={label}
                            checked={selectedOptionIds.includes(id)}
                            onChange={() => {}}
                            name={`opzione ${label}`}
                            disabled={isDisabled}
                            id={id}
                          />
                        </ThemeProvider>
                      </div>
                      <Text type="cta">{label}</Text>
                    </S.Option>
                  );
                })}
              </InfiniteScroll>
            </S.ScrollableWrapper>
          </S.OptionContainer>
        ) : null}
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default DropdownMultiple;
