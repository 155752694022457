import React from "react";
import { CommentList } from "src/js/components/CommentList";
import { COMMENT_CONTEXT } from "src/js/components/CommentList/CommentList.types";

const ResourceComments = ({
  showing,
  parentId,
  onDeleteComment,
  onNewComment
}: {
  showing: boolean;
  parentId: number;
  onDeleteComment: (nestedCommentNumber?: number) => void;
  onNewComment: () => void;
}) => {
  return (
    <div
      className={["resource__comments-wrapper", showing ? "" : "hidden"].join(
        " "
      )}
      id="comment_list"
    >
      <div className="resource__comments-list">
        {showing && (
          <CommentList
            context={COMMENT_CONTEXT.MODULES}
            parentId={parentId}
            onDeleteComment={onDeleteComment}
            onNewComment={onNewComment}
          />
        )}
      </div>
    </div>
  );
};

export default ResourceComments;
