import styled, { css } from "styled-components";
import { Text } from "@arcadia/design-system";

export const MentionInputWrapper = styled.div<{
  renderBottom?: boolean;
  isMobile: boolean;
  targetElementLeft: number;
  targetElementWidth: number;
  targetElementHeight: number;
  dynamicElementLeft: number;
}>`
  box-shadow: ${({ theme }) => theme.shadows.modals};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: ${({ isMobile }) => (!isMobile ? "8px" : "12px")};
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.forModal};
  position: absolute;
  border: ${({ isMobile, theme }) =>
    isMobile ? `1px solid ${theme.colors.grey[800]}` : "none"};
  left: ${({ targetElementLeft, dynamicElementLeft }) =>
    targetElementLeft - dynamicElementLeft}px;
  ${({ renderBottom }) =>
    renderBottom
      ? css`
          top: calc(100% + 6px);
        `
      : css`
          bottom: calc(100% + 6px);
        `}
  width: ${({ targetElementWidth }) => targetElementWidth}px;
`;
export const WsMentionListHeader = styled.div`
  width: 100%;
  padding: 12px 16px;
`;
export const WsMentionListHeaderText = styled(Text)``;
export const WsMentionListScrollableContent = styled.div`
  width: 100%;
  max-height: 160px;
  overflow-y: scroll;
`;
export const WsMentionListFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;
export const WsMentionListFooterSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.grey[500]};
`;
export const WsMentionListFooterText = styled(Text)``;
export const WsMentionListFooterSpacer = styled.div`
  margin-right: 2px;
  display: flex;
  align-items: center;
`;
export const WsMentionListEscText = styled(Text)`
  font-size: 10;
`;
