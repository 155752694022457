import styled from "styled-components";

export const Card = styled.div`
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};

  @media screen and (max-width: 767px) {
    height: 56px;
  }
`;

export const UserData = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 16px;
  }
`;

export const UserLastname = styled.span`
  font-weight: 600;
`;

export const Mail = styled.div`
  color: ${({ theme }) => theme.colors.grey[600]};
  margin-bottom: 0;
  max-width: 50%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > span {
    white-space: nowrap;
  }
  @media screen and (max-width: 767px) {
    max-width: calc(100% - 48px);
  }
`;

export const NameWrapper = styled.div`
  width: 100%;
  max-width: 50%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > span {
    white-space: nowrap;
  }
  @media screen and (max-width: 767px) {
    max-width: calc(100% - 48px);
  }
`;
