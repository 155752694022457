import {
  THUMBNAIL_MINIMUM_HEIGHT,
  THUMBNAIL_MINIMUM_WIDTH
} from "src/js/settings/settingsResource";
import { ImageInfo } from "src/js/types";

const createImage = (url: string): Promise<ImageInfo> =>
  new Promise((resolve: (info: ImageInfo) => void, reject) => {
    const image = new Image();
    image.addEventListener("load", () => {
      const { width, height } = image;
      resolve({ image, width, height });
    });
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const getRadianAngle = (degreeValue: number): number => {
  return (degreeValue * Math.PI) / 180;
};

const rotateSize = (
  width: number,
  height: number,
  rotation: number
): { width: number; height: number } => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
};

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  imageMimeType = "image/jpeg",
  imageTitle = "",
  minimumImageWidth = THUMBNAIL_MINIMUM_WIDTH,
  minimumImageHeight = THUMBNAIL_MINIMUM_HEIGHT,
  rotation = 0,
  flip: { horizontal: boolean; vertical: boolean } = {
    horizontal: false,
    vertical: false
  }
): Promise<File | null> => {
  const imageInfo = await createImage(imageSrc);
  const { image, height, width } = imageInfo;

  if (width < minimumImageWidth || height < minimumImageHeight) {
    return null;
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    width,
    height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-width / 2, -height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Convert the cropped canvas to a File object
  const blob = await new Promise<Blob | null>(resolve => {
    croppedCanvas.toBlob(fileBlob => {
      resolve(fileBlob);
    }, imageMimeType);
  });

  if (blob) {
    const file = new File([blob], imageTitle, { type: blob.type });
    return file;
  }

  return null;
};
