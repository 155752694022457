import {
  fetchGroupsMigrate,
  fetchSpaceGroupsCanMigrate,
  fetchSpacesCanCreateGroup
} from "src/js/repository/spacesRepository";
import { useStores } from "src/js/hooks";
import { useMemo, useState } from "react";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { TEMPORARY_SPACE_SLUG } from "src/js/settings/settingsSpaces";
import {
  showToastError,
  showToastFunctional,
  showToastSuccess
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { useTheme } from "styled-components";
import { getMigrationSteps } from "../MigrateGroupsModal.const";
import {
  MigrateGroupsContextEnum,
  SpaceGroupsCanMigrateParams,
  SpacesCanCreateGroupParams
} from "../MigrateGroupsModal.types";

const useMigrateGroupsState = ({
  moveFromActiveSpace,
  onClose,
  skipIntro
}: {
  moveFromActiveSpace: boolean;
  onClose: () => void;
  skipIntro: boolean;
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [step, setStep] = useState(skipIntro ? 0 : null);
  const migrateSteps = getMigrationSteps();

  const stepParams = migrateSteps[step];
  const {
    SpaceStore: { activeSpace, spacesList }
  } = useStores();

  const startingSpace = useMemo(() => {
    if (moveFromActiveSpace && activeSpace) {
      return activeSpace;
    }
    const temporarySpace = spacesList.find(
      x => x?.space?.slug === TEMPORARY_SPACE_SLUG
    );
    // track migration of groups only from temporary space
    _trackEvent(
      "TemporarySpaceMigration",
      "MigrationOutOfTemporarySpaceAccessPoint"
    );
    return temporarySpace;
  }, [activeSpace, moveFromActiveSpace]);

  const fetchItems = () => {
    switch (stepParams.context) {
      case MigrateGroupsContextEnum.GroupsSelection:
        return (params: SpaceGroupsCanMigrateParams) =>
          fetchSpaceGroupsCanMigrate({
            spaceId: startingSpace?.space.slug,
            ...params
          });
      case MigrateGroupsContextEnum.SpaceSelection:
        return (params: SpacesCanCreateGroupParams) =>
          fetchSpacesCanCreateGroup(params).then(data => {
            const { results } = data;
            if (results?.length > 0 && moveFromActiveSpace && activeSpace) {
              return {
                ...data,
                results: results.filter(
                  space => space?.id !== activeSpace?.space?.id
                )
              };
            }
            return data;
          });
      default:
        return null;
    }
  };

  const migrateGroupsToSpace = (
    selectedSpaceId: string,
    selectedGroupIds: number[]
  ) => {
    fetchGroupsMigrate({
      spaceId: selectedSpaceId,
      groupIds: selectedGroupIds
    })
      .then(data => {
        onClose();
        if (data.errors.length > 0) {
          showToastFunctional(
            translate("migrate_groups_warning_incomplete_message"),
            whiteTheme
          );
        } else {
          showToastSuccess({
            str: translate("migrate_groups_confirmation_message")
          });
        }
        if (selectedSpaceId === activeSpace?.space?.id) {
          navigateTo(createUrl("home"));
          setTimeout(() => {
            navigateTo(
              createUrl("space_groups", {
                space_slug: activeSpace?.space?.slug
              })
            );
          }, 0);
          return;
        }
        const selectedSpaceSlug = spacesList.find(
          space => space?.space?.id === selectedSpaceId
        )?.space?.slug;
        navigateTo(
          createUrl("space_groups", {
            space_slug: selectedSpaceSlug
          })
        );
      })
      .catch(error => {
        showToastError({ str: error?.message });
      });
  };

  const onConfirm = (items: string[] | number[]) => {
    switch (stepParams.context) {
      case MigrateGroupsContextEnum.GroupsSelection:
        setSelectedGroups(items as number[]);
        setStep(1);
        break;
      case MigrateGroupsContextEnum.SpaceSelection:
        migrateGroupsToSpace(items[0] as string, selectedGroups);
        break;
      default:
        break;
    }
  };

  const onGoBack = () => {
    setStep(s => s - 1);
  };

  const getSelectedItemsIds = () => {
    switch (stepParams.context) {
      case MigrateGroupsContextEnum.GroupsSelection:
        return selectedGroups;
      default:
        return [] as string[];
    }
  };

  return {
    startingSpace,
    fetchItems,
    step,
    setStep,
    stepParams,
    totalSteps: migrateSteps.length,
    onCancel: step === 0 ? onClose : onGoBack,
    onConfirm,
    getSelectedItemsIds
  };
};

export default useMigrateGroupsState;
