import { DoodleLoader } from "@arcadia/design-system";
import React from "react";
import { OldResource } from "src/js/types";
import { useTheme } from "styled-components";

// TODO you can no longer upload type=== YOUTUBE in the platform, everything is treated as link
const Youtube = ({
  resource: { youtube_id, id, name }
}: {
  resource: OldResource & { youtube_id: string };
}) => {
  const { whiteTheme } = useTheme();
  let embedYT = (
    <div className="resource-loading text-center text-info">
      <DoodleLoader theme={whiteTheme} isMini />
    </div>
  );
  if (youtube_id) {
    let url = "";
    if (youtube_id.indexOf("?rel=0") === -1) {
      url = `https://www.youtube.com/embed/${youtube_id}?rel=0`;
    } else {
      url = `https://www.youtube.com/embed/${youtube_id}`;
    }
    embedYT = (
      <iframe
        title={`${name}`}
        className="resource__iframe"
        width="100%"
        frameBorder="0"
        src={url}
        allowFullScreen
      />
    );
  }
  return (
    <div className="full-height" key={`res${id}`}>
      {embedYT}
    </div>
  );
};

export default Youtube;
