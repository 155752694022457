import React, { FC } from "react";
import { useTranslation } from "src/js/translation";
import { GroupDetailsModel, SpaceModel } from "src/js/types";
import { SelectableList } from "../layout/SelectableList";
import { GenericPaginatedRequest } from "../layout/SelectableList/SelectableList.types";
import { ModalLayout } from "../modal/ModalLayout";
import { IntroPresentation } from "./IntroPresentation";
import {
  getCustomEmptyStateFromContext,
  getCustomItemCardFromContext
} from "./MigrateGroupsModal.const";
import { MigrateGroupsModalProps } from "./MigrateGroupsModal.types";
import { useMigrateGroupsState } from "./hooks";

const MigrateGroupsModal: FC<MigrateGroupsModalProps> = ({
  skipIntro = false,
  onClose,
  moveFromActiveSpace = false
}) => {
  const { translate } = useTranslation();

  const {
    getSelectedItemsIds,
    startingSpace,
    fetchItems,
    step,
    setStep,
    stepParams,
    totalSteps,
    onCancel,
    onConfirm
  } = useMigrateGroupsState({
    moveFromActiveSpace,
    onClose,
    skipIntro
  });

  if (!skipIntro && !stepParams) {
    return (
      <ModalLayout
        labels={{
          title: translate({ text: "migrate_groups_into_title" })
        }}
        progressPercentage={0}
        closeModal={onClose}
        mobileProps={{
          withAdaptiveHeight: true,
          withHeaderLabels: false
        }}
      >
        <IntroPresentation onConfirm={() => setStep(0)} onSkip={onClose} />
      </ModalLayout>
    );
  }

  const { context, modalLabels, listLabels, multipleSelect } = stepParams;
  const progressPercentage = (100 * (step + 1)) / totalSteps;

  return (
    <ModalLayout
      labels={{
        ...modalLabels,
        subtitle: translate({
          text: "migrate_groups_modal_subtitle_mobile",
          stringVariables: { index: step + 1, total: totalSteps }
        }),
        mobileClose: translate({
          text:
            step === 0
              ? "migrate_groups_modal_close"
              : "migrate_groups_modal_back"
        })
      }}
      progressPercentage={progressPercentage}
      closeModal={onClose}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false,
        onClose: onCancel
      }}
      desktopProps={{
        hasAdaptiveHeight: false
      }}
    >
      <SelectableList
        onCancel={onCancel}
        withCancelButton
        onConfirm={onConfirm}
        fetchItems={
          fetchItems() as GenericPaginatedRequest<
            GroupDetailsModel | SpaceModel
          >
        }
        selectedItemsIds={getSelectedItemsIds()}
        multipleSelect={multipleSelect}
        customComponents={{
          getCustomItemCard: getCustomItemCardFromContext(
            context,
            startingSpace?.space.name
          ),
          getEmptyStateFromStatus: getCustomEmptyStateFromContext(context)
        }}
        labels={listLabels}
      />
    </ModalLayout>
  );
};

export default MigrateGroupsModal;
