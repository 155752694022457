import React from "react";
import { useStores } from "src/js/hooks";
import * as S from "./SelectableImageCardList.styles";

export type SelectableImageCardItem = {
  id: number;
  title: string;
  description: string;
  cover: string;
};

type SelectableImageCardProps = {
  list?: SelectableImageCardItem[];
  selected: number;
  onChange?: (id: number) => void;
};

const SelectableImageCardList = ({
  list,
  selected,
  onChange
}: SelectableImageCardProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  if (!list?.length) return null;
  return (
    <S.StyledUl>
      {list.map(el => {
        const isSelected = selected === el.id;
        return (
          <S.StyledLi
            aria-label={el.title}
            key={el.id}
            onClick={() => {
              onChange(el.id);
            }}
            whileTap={{ scale: 0.98 }}
            isSelected={isSelected}
          >
            <S.Card isSelected={isSelected}>
              <S.RadioDot isSelected={isSelected} />
              <S.CardHeader>
                <S.StyledHeading level="6">{el?.title}</S.StyledHeading>
                {!isLayoutModeMobile ? (
                  <S.StyledText type="body">{el?.description}</S.StyledText>
                ) : null}
              </S.CardHeader>
              <S.Cover cover={el?.cover} />
            </S.Card>
          </S.StyledLi>
        );
      })}
    </S.StyledUl>
  );
};

export default SelectableImageCardList;
