import { EmptyListCardThemeOptions, Palette } from "@ds_themes/types";

export const createEmptyListCardTheme = ({
  grey
}: Palette): { emptylistcard: EmptyListCardThemeOptions } => {
  return {
    emptylistcard: {
      background: grey[1000]
    }
  };
};
