import styled from "styled-components";

export const GroupMemberContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;
  border: ${({ theme }) => theme.groupmember.container.border};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.groupmember.container.background};
  border-radius: 12px;
  padding: 15px;
  gap: 12px 15px;
  align-items: center;
  div:not(:last-child) {
    margin-top: 6px;
  }
`;

export const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: end;
`;
