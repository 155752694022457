import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { Translate } from "src/js/translation/TranslationProvider";
import { ReportThread } from "src/js/components/exercises/ReportThread";
import { ReportStudentOpenAnswerForm } from "src/js/components/exercises/ReportStudentOpenAnswerForm";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { ReportTeacherCorrectForm } from "src/js/components/exercises/ReportTeacherCorrectForm";
import { ReportSimpleFooter } from "src/js/components/exercises/ReportSimpleFooter";
import { Col } from "react-awesome-styled-grid";
import { Icon } from "@arcadia/design-system";
import { getExerciseIconString } from "src/js/modules/exercisesFunction";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { getActiveGroup } from "../../../modules/activeGroup";
import { __ } from "../../../modules/localization";
import QuizCollection from "../../../collections/quizzesCollection";

const OpenAnswerCorrectionDisplayer = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        execution: null,
        quiz_execution: null,
        corrector: null,
        answer: null,
        number: 0,
        thread: []
      };
    },
    getInitialState() {
      return {
        status: "",
        answer:
          this.props.execution.getQuizReviewCommentByExecId(
            this.props.quiz_execution.id
          ) || "",
        isEditing: false,
        shouldShowForm: !this.props.execution.getQuizReviewedByExecId(
          this.props.quiz_execution.id
        ),
        resubmissionAsked: this.props.quiz_execution.redo_execution
      };
    },
    handleChange(value) {
      this.setState({
        answer: value
      });
    },
    render() {
      const { thread, exercise } = this.props;
      const group = getActiveGroup();
      const tempQuizCollection = new QuizCollection(
        this.props.quiz_execution.quiz
      );
      const quiz = tempQuizCollection.at(0);
      const { quiz_execution } = this.props;
      const { execution } = this.props;
      const userAnswers = execution.getQuizAnswerById(quiz.get("id"));

      const quizType = quiz.getType();
      const quizIcon = getExerciseIconString(
        this.props.isVideoSubquiz ? "quizvideo" : quizType
      );

      let subQuizImage = null;
      if (this.props.isVideoSubquiz) {
        subQuizImage = (
          <Icon icon={getExerciseIconString(quizType)} width="30" height="30" />
        );
      }

      let userText = userAnswers[0] !== undefined ? userAnswers[0].value : "";

      if (userText == "") {
        userText = __("quiz_not_answered_label");
      }

      let resubmitForm = null;

      if (group.isTeacher()) {
        if (this.state.shouldShowForm) {
          resubmitForm = (
            <ReportTeacherCorrectForm
              exerciseId={execution.get("exercise_id")}
              executionId={execution.get("id")}
              quizExecutionId={quiz_execution.id}
              availablePoints={quiz.get("available_points")}
              onSaveSubmit={askResubmission => {
                console.log(askResubmission);
                this.setState({
                  shouldShowForm: false,
                  resubmissionAsked: askResubmission
                });
              }}
            />
          );
        } else {
          resubmitForm = (
            <ReportSimpleFooter
              defaultValue={this.state.resubmissionAsked}
              exerciseId={execution.get("exercise_id")}
              executionId={execution.get("id")}
              quizExecutionId={quiz_execution.id}
              onToggleChange={toggleStatus => {
                this.setState({
                  resubmissionAsked: toggleStatus
                });
              }}
            />
          );
        }
      } else {
        // Student
        const pingPongArray = thread.filter(element => {
          return element?.system_message !== true;
        });

        const lastThreadElement =
          pingPongArray.length > 0
            ? pingPongArray[pingPongArray.length - 1]
            : {};
        if (quiz_execution.redo_execution && lastThreadElement?.is_teacher) {
          resubmitForm = (
            <ReportStudentOpenAnswerForm
              exerciseId={execution.get("exercise_id")}
              executionId={execution.get("id")}
              quizExecutionId={quiz_execution.id}
              availablePoints={quiz.get("available_points")}
              onSaveSubmit={() => {
                this.setState({ shouldShowForm: false });
              }}
            />
          );
        }
      }

      let voteType;
      let statusLabel = (
        <>
          <strong>
            {quiz_execution.points}/{quiz.getAvailablePoints()}
          </strong>
          &nbsp;
          <Translate text="quiz_points_label" />
        </>
      );
      if (thread.length === 0) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_WARNING;
        statusLabel = <Translate text="quiz_status_need_review_label" />;
      } else if (quiz_execution.points / quiz.getAvailablePoints() < 0.6) {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_ERROR;
      } else {
        voteType = COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
      }

      return (
        <>
          <Col xs="2">
            <div className="report_quiz-info">
              <Icon icon={quizIcon} width="60" height="60" />
              <ColorLabel type={voteType}>{statusLabel}</ColorLabel>
            </div>
          </Col>
          <Col xs="10">
            <div className="report_quiz-detail">
              <div className="correction__quiz-info">
                <div className="correction__quiz-number">
                  {this.props.number}
                </div>
                <div className="correction__quiz-title">
                  {subQuizImage}
                  {quiz.getTitle() !== "" ? (
                    <ExerciseQuizTitle quizTitle={quiz.getTitle()} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="correction_answers-wrapper">
                <ReportThread
                  execution={execution.attributes}
                  thread={thread}
                  availablePoints={quiz.getAvailablePoints()}
                  executionMessage={userText}
                  exerciseId={exercise.id}
                  executionId={execution.get("id")}
                  quizExecutionId={quiz_execution.id}
                  editThreadMessage={isEditing => this.setState({ isEditing })}
                  askResubmissionValue={this.state.resubmissionAsked}
                  onDeleteMessage={() => {
                    this.setState({
                      shouldShowForm: true
                    });
                  }}
                  onEditSubmit={askResubmission => {
                    this.setState({
                      shouldShowForm: false,
                      resubmissionAsked: askResubmission
                    });
                  }}
                />
              </div>
              {this.state.isEditing ? null : resubmitForm}
              {this.props.corrector}
            </div>
          </Col>
        </>
      );
    }
  })
);

export default OpenAnswerCorrectionDisplayer;
