/* eslint-disable */
import React, { useEffect, useState } from "react";
import { fetchResource } from "src/js/repository/resourceRepository";
import { showToastError } from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import ResourceViewer from "src/legacy/view/resource/ResourceViewer";
import DocumentAnswerCard from "../DocumentAnswerCard/DocumentAnswerCard";

const QuizReportResource = ({ resource = {}, resourceId }) => {
  const [resourceFetched, setResourceFetched] = useState(null);
  const [resourceComponent, setResource] = useState(null);
  useEffect(() => {
    if (resourceId) {
      fetchResource(resourceId)
        .then(res => {
          setResourceFetched(res);
        })
        .catch(() => {
          showToastError({
            str: <Translate text="quiz_resource_fetch_error" />
          });
        });
    }
  }, []);

  const showResource = () => {
    setResource(
      <ResourceViewer
        disableOption
        resources={[resourceFetched]}
        startingPosition={0}
        onClose={() => setResource(null)}
      />
    );
  };

  return (
    <>
      <DocumentAnswerCard
        disableRemove
        onClick={showResource}
        resource={resourceFetched || resource}
      />
      {resourceComponent}
    </>
  );
};

export default QuizReportResource;
