import {
  Box,
  Icon,
  SearchBarStyles,
  Text,
  SearchBar as WSSearchBar,
  colors
} from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";
import { SpaceTrialCard } from "../../SpaceTrialCard";

export const StyledSpaceTrialCard = styled(SpaceTrialCard)`
  margin-top: auto;
  & > div {
    margin-top: 20px;
  }
`;

type IsActiveProps = {
  isActive?: boolean;
};

type ContainerProps = IsActiveProps & {
  isOpen: boolean;
};

type ToggledProps = {
  isCollapsed: boolean;
};

export const HeaderMobileWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const HeaderMobileContainer = styled.div`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.header + 1};
  background-color: ${({ theme }) => theme.colors.headerPrimaryColor};
  color: ${({ theme }) => theme.colors.headerTextColor};

  @media screen and (min-width: 767px) {
    display: none;
  }
`;

export const SpaceGroupContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: ${({ isActive, isOpen }) =>
    isOpen || isActive ? "8px 0px 0px" : "0px"};
`;

export const SpaceAndGroupDetails = styled(motion.div)`
  overflow: hidden;
`;

export const SpaceDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

export const SpaceSettings = styled.div`
  display: flex;
  align-items: center;
`;

export const HamburgerContainer = styled.div`
  display: flex;
  padding: 16px;
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.headerTextColor};
`;

export const AvatarContainer = styled(motion.div)`
  margin-right: 12px;
  img {
    object-fit: cover;
  }
`;

export const Title = styled(motion.div)`
  font-weight: 700;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const SpaceName = styled.div`
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.headerTextSecondaryColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;

  & > span {
    white-space: nowrap;
  }
`;

export const NameBold = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
`;

export const HeaderItemsContainer = styled(Box)``;

export const SpacesContainer = styled(motion.div)`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 12px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Space = styled(motion.div)<IsActiveProps>`
  position: relative;
  padding-top: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ isActive, theme }) =>
    isActive &&
    `&:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: ${theme.colors.yellow[500]};
  }`};
`;

export const SpaceAvatar = styled(motion.div)<IsActiveProps>`
  border-radius: 50%;
  width: fit-content;
  border: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.colors.yellow[500]};` : undefined};
`;

export const Badge = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  height: 16px;
  align-items: center;
  line-height: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const SpaceTitle = styled(motion.div)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 60px;
  color: ${({ theme }) => theme.colors.grey[1000]};
  margin-top: 8px;
  margin-bottom: 12px;
  text-align: center;
`;

export const HeaderWrapper = styled(motion.div)`
  min-height: 32px;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const GroupWrapper = styled(motion.div)`
  height: calc(100vh - 100%);
  height: calc(100dvh - 100%);
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  top: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
`;

export const MyGroupsText = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const GroupsInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const CollapsibleWrapper = styled(motion.div)`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const DownIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

export const SpaceDotWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChevronIcon = styled(Icon).attrs(
  ({ isCollapsed }: { isCollapsed: boolean }) => ({
    icon: isCollapsed ? "chevronDown" : "chevronUp",
    width: 16
  })
)<ToggledProps>`
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const PlusIcon = styled(Icon).attrs(() => ({
  icon: "plus",
  width: 16,
  height: 16
}))`
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const AlignIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const ViewAllText = styled(Text).attrs(() => ({
  type: "body"
}))`
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const GroupsTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 36px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ViewAllWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  gap: 12px;
`;

export const ActiveSpaceItem = styled.div`
  #active-space-header-item {
    margin-bottom: 0;
  }
`;

export const SearchBar = styled(WSSearchBar)`
  ${SearchBarStyles.SearchBar} {
    background-color: ${colors.violet[900]};
    padding: 8px 16px;
    height: 32px;
    color: ${colors.violet[600]};
  }

  svg {
    color: ${colors.violet[600]};
  }

  ${SearchBarStyles.IconWrapper} {
    width: unset;
  }
`;

export const ModuleButton = styled.div<{ firstButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px 24px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[800]}`};
  border-radius: 8px;
  margin: ${({ firstButton }) =>
    `${firstButton ? "14px" : "0px"} 18px ${
      firstButton ? "8px" : "14px"
    } 18px`};
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const ModalSheetItemTitle = styled(Text)`
  margin-left: 16px;
`;

export const DashboardLinkWrapper = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  min-height: 36px;
  margin-bottom: 24px;
  margin-top: 4px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  gap: 12px;
  margin-bottom: 12px;
`;
