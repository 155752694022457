import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Box, Text, Dropdown } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useFormContext } from "react-hook-form";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { CourseDetailsWizardForm } from "../../CourseDetailsWizard.types";
import {
  CourseTypology,
  __COURSE_TYPOLOGY_STEP_OPTIONS__
} from "./CourseTypologyStep.utils";

const CourseTypologyStep = () => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const [selectedOptionId, setSelectedOptionId] = useState<CourseTypology>();
  const { setValue, register, getValues } =
    useFormContext<CourseDetailsWizardForm>();

  useEffect(() => {
    register("courseTypology");
  }, []);

  useEffect(() => {
    const textValue = getValues().courseTypology;
    const defaultValue = __COURSE_TYPOLOGY_STEP_OPTIONS__.find(
      i => translate(i.label) === textValue
    )?.id;
    if (defaultValue && !selectedOptionId) setSelectedOptionId(defaultValue);
  }, [getValues().courseTypology]);

  useEffect(() => {
    if (selectedOptionId) {
      setValue("courseTypology", selectedOptionId, true);
    }
  }, [selectedOptionId]);

  const optionsList = useMemo(
    () =>
      __COURSE_TYPOLOGY_STEP_OPTIONS__.map(option => ({
        id: option.id,
        label: translate(option.label)
      })),
    []
  );

  return (
    <Box padding="20px 0px" width="100%">
      <Box marginBottom={8}>
        <Text type="formSubtitle">
          {translate("ai_wizard_course_typology_label")}
        </Text>
      </Box>
      <Dropdown
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        placeholder={translate("ai_wizard_course_typology_placeholder")}
        optionsList={optionsList}
        setSelectedOptionId={id => setSelectedOptionId(id as CourseTypology)}
        selectedOptionId={selectedOptionId}
      />
    </Box>
  );
};

export default observer(CourseTypologyStep);
