import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ClaimableSpaceCardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.grey[50]};
  margin: 8px 0 16px 0;
  position: relative;
`;

export const SecondRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 8px;
`;

export const Teachers = styled.div`
  display: flex;
  align-items: center;
  width: 68px;
  height: 36px;
  & > img {
    width: 32px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    position: absolute;
  }
`;

export const FirstTeacher = styled.img`
  left: 0;
`;
export const SecondTeacher = styled.img`
  left: 16px;
`;
export const ThirdTeacher = styled.img`
  left: 32px;
`;

export const Teacher = styled.img`
  width: 32px;
  border-radius: 50%;
  margin: 1px;
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
`;

export const ClaimableText = styled(Text)`
  flex: 1 1;
  align-self: center;
  margin-left: 0;
  text-align: left;
`;
