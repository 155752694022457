import { Avatar } from "@arcadia/design-system";
import styled from "styled-components";

export const InputQuery = styled.input`
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  min-width: 200px;
  width: 100%;
  max-width: 252px;
`;

export const UserItemDeleteBtn = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserItem = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 4px 12px 4px 4px;
  border-radius: 100px;
  gap: 8px;
  background-color: ${({ theme }) => theme.primaryColor[800]};
  border: 1px solid ${({ theme }) => theme.primaryColor[500]};
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

export const SearchUsersContent = styled.div`
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  height: 100%;
  width: 100%;
  overflow-wrap: break-word;
  gap: 12px;
  cursor: text;
`;

export const SearchUsersInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  min-height: 68px;
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 8px;
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
    border-radius: 8px;
    &:hover {
      background: ${({ theme }) => theme.colors.blueGrey[900]};
      background-clip: padding-box;
    }
  }
`;
