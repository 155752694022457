import reject from "lodash/reject";
import each from "lodash/each";
import indexOf from "lodash/indexOf";
import uniq from "lodash/uniq";
import find from "lodash/find";
import QuizModel from "./quizModel";
import QuizCollection from "../../collections/quizzesCollection";
import { _trackEvent } from "src/js/modules/analyticsFunction";

class QuizVideoModel extends QuizModel {
  /** This quiz has no title. */
  getTitle() {
    return "";
  }
  addInnerQuiz(time, quiz_id, quiz) {
    const timings = this.getTimings();
    const quizzes = this.getQuizzes();

    timings.push({
      time: parseInt(time),
      quiz_id: parseInt(quiz_id)
    });
    this.setTimings(timings);

    quizzes.push(quiz);
    this.setQuizzes(quizzes);

    _trackEvent("Assignments", "AssignmentsNewQuestion", quiz.type);
  }
  removeInnerQuiz(timing) {
    let timings = this.getTimings();
    let quizzes = this.getQuizzes();

    timings = reject(
      timings,
      t => t.time === timing.time && t.quiz_id === timing.quiz_id
    );
    this.setTimings(timings);

    quizzes = reject(quizzes, q => q.id === timing.quiz_id);

    this.setQuizzes(quizzes);
  }

  /**
   * Mark the given quiz_id as shown
   * @param  {Number} quiz_id The id of the quiz that was shown
   * @return {Array} Array of quiz ids
   */
  addShownInnerQuiz(quiz_id) {
    const ids = this.getShownInnerQuiz();
    ids.push(quiz_id);
    this.setShownInnerQuiz(ids);
    return this.getShownInnerQuiz();
  }

  /**
   * Set the given shown quizzes (after uniq);
   * @param {Array} shown_quizzes_ids The list of shown quizzes ids
   * @return {Array} Array of quiz ids
   */
  setShownInnerQuiz(shown_quizzes_ids) {
    this.set("shown_quizzes", uniq(shown_quizzes_ids));
    return this.getShownInnerQuiz();
  }

  /**
   * Return an array of shown quizzes ids
   * @return {Array} Array of quiz ids
   */
  getShownInnerQuiz() {
    return this.get("shown_quizzes") || [];
  }

  /**
   * Check if the given quiz_id was shown
   * @param  {Number} quiz_id The quiz id to check
   * @return {Boolean}
   */
  checkInnerQuizWasShown(quiz_id) {
    return indexOf(this.getShownInnerQuiz(), quiz_id) >= 0;
  }

  getQuizzes() {
    return this.get("quizzes") || [];
  }
  setQuizzes(quizzes) {
    this.set("quizzes", quizzes);
  }
  getTimings() {
    return this.get("timings") || [];
  }
  setTimings(timings) {
    this.set("timings", timings);
  }
  cleanTimings() {
    // remove unused timings
    const timings = this.getTimings();
    const quizzes = this.getQuizzes();
    const quizIds = [];
    const newTimings = [];

    each(quizzes, q => {
      quizIds.push(q.id);
    });

    each(timings, t => {
      if (indexOf(quizIds, t.quiz_id) >= 0) {
        newTimings.push(t);
      }
    });
    this.setTimings(newTimings);
  }
  checkUserAnswer(success_threshold) {
    let quiz_ok = 0;
    let quiz_error = 0;
    const inner_answers = this.getInnerAnswers();
    const quizzes = new QuizCollection(this.getQuizzes());
    quizzes.each(q => {
      q.setUserAnswer(inner_answers[q.get("id")]);
      if (q.checkUserAnswer()) {
        quiz_ok++;
      } else {
        quiz_error++;
      }
    });

    if (success_threshold === undefined)
      success_threshold = this.getDefaultThreshold(); // se non è specificata percentuale, metto default
    const perc = (quiz_ok / quizzes.length) * 100;
    let ret = perc >= success_threshold;
    return ret;
  }
  setInnerAnswer(quiz_id, quiz_answers) {
    const inner_answers = this.getInnerAnswers();
    inner_answers[quiz_id] = quiz_answers;
    this.setInnerAnswers(inner_answers);
  }
  getInnerAnswers() {
    return this.get("inner_answers") || {};
  }
  setInnerAnswers(answers) {
    this.set("inner_answers", answers);
  }
  isFullyAnswered() {
    const questions = this.getQuizzes();
    const real_answers = this.getInnerAnswers();
    const answers = Object.keys(real_answers); // estrae le chiavi di un oggetto
    const expected_answers = questions;
    return answers.length === expected_answers.length;
  }
  getVideoId() {
    if (this.get("resource") !== undefined) {
      const type = this.getVideoType();
      if (type === "VIMEO") {
        return this.get("resource").vimeo_id;
      } else if (type === "YOUTUBE") {
        return this.get("resource").youtube_id;
      }
      return "";
    }
    return "";
  }
  getVideoType() {
    if (this.get("resource") !== undefined) {
      return this.get("resource").type;
    }
    return "";
  }
  getVideoResource() {
    if (this.get("resource") !== undefined) {
      return this.get("resource");
    }
    return {};
  }
}

export default QuizVideoModel;
