/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import { BoardElementComment, PostComment } from "../types/models/Comment";
import { Post } from "../types/models/Post";

class PostCommentStore {
  $commentMap: Map<
    number,
    {
      commentList: PostComment[] | BoardElementComment[];
      hasNext: boolean;
    }
  > = new Map([]);
  $postMap: Map<number, Post> = new Map(null);

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getPostById = (postId: number) => {
    return this.$postMap.get(postId) || null;
  };

  getCommentListById = (parentId: number) => {
    return (
      this.$commentMap.get(parentId) || {
        commentList: [],
        hasNext: false
      }
    );
  };

  setNewPost = (postId: number, newPost: Post) => {
    this.$postMap.set(postId, newPost);
  };

  setCommentList = (
    parentId: number,
    newCommentList: PostComment[] | BoardElementComment[],
    hasMoreResults: boolean
  ) => {
    this.$commentMap.set(parentId, {
      commentList: newCommentList,
      hasNext: hasMoreResults
    });
  };

  reset = () => {
    this.$commentMap = new Map([]);
    this.$postMap = new Map(null);
  };
}

export default PostCommentStore;
