import getUserClient from "src/js/modules/connection";
import {
  api_notifications,
  api_notification_read,
  getVersionedApiUrl,
  api_notification_unread,
  api_notification_group_readall,
  api_notification_space_readall
} from "src/js/repository/apiUrls";
import { NotificationModel } from "../types/models/Notification";
import { FetchNotificationsParams } from "./types";

export const fetchNotifications = async (
  params?: FetchNotificationsParams
): Promise<{ hasNext: boolean; results: NotificationModel[] }> => {
  return getUserClient()
    .get(getVersionedApiUrl(api_notifications(), "v3"), { params })
    .then(response => {
      return response.data;
    });
};

export const markNotificationRead = async ({
  notificationId
}: {
  notificationId: string;
}): Promise<void> => {
  return getUserClient()
    .post(getVersionedApiUrl(api_notification_read({ notificationId }), "v3"))
    .then(response => {
      return response.data;
    });
};

export const markNotificationUnread = async ({
  notificationId
}: {
  notificationId: string;
}): Promise<void> => {
  return getUserClient()
    .post(getVersionedApiUrl(api_notification_unread({ notificationId }), "v3"))
    .then(response => {
      return response.data;
    });
};

export const markGroupNotificationsRead = async ({
  groupId
}: {
  groupId: number;
}): Promise<void> => {
  return getUserClient()
    .post(getVersionedApiUrl(api_notification_group_readall({ groupId }), "v3"))
    .then(response => {
      return response.data;
    });
};

export const markSpaceNotificationsRead = async ({
  spaceId
}: {
  spaceId: string;
}): Promise<void> => {
  return getUserClient()
    .post(getVersionedApiUrl(api_notification_space_readall({ spaceId }), "v3"))
    .then(response => {
      return response.data;
    });
};
