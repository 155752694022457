import { increaseBrightness } from "@arcadia/design-system";
import WSTheme from "src/js/theme/theme";
import {
  NotificationAreaLabel,
  NotificationAreaLabelModel,
  NotificationAreaModel,
  OtherAreaValue
} from "src/js/types/models/Notification";

export const getIconFromType = (type: NotificationAreaLabelModel) => {
  switch (type) {
    case NotificationAreaLabel.Wall:
      return "notificationWall";
    case NotificationAreaLabel.Exercise:
      return "notificationExercise";
    case NotificationAreaLabel.Lesson:
      return "notificationLessons";
    case NotificationAreaLabel.Calendar:
      return "micSolid";
    case NotificationAreaLabel.Other:
      return "notificationMarketing";
    default:
      return "notificationWall";
  }
};

export const getPillColorFromType = (type: NotificationAreaLabelModel) => {
  switch (type) {
    case NotificationAreaLabel.Wall:
      return WSTheme.colors.primary1;
    case NotificationAreaLabel.Exercise:
      return WSTheme.colors.resourceGreen;
    case NotificationAreaLabel.Lesson:
      return WSTheme.colors.semantic2;
    case NotificationAreaLabel.Calendar:
      return WSTheme.colors.slate;
    case NotificationAreaLabel.Other:
      return WSTheme.colors.coldGrey[500];
    default:
      return WSTheme.colors.primary1;
  }
};

export const getPillBkColorFromType = (type: NotificationAreaLabelModel) => {
  return increaseBrightness(getPillColorFromType(type), 70);
};

export const getNotificationTypeFromArea = (
  area: NotificationAreaModel
): NotificationAreaLabelModel => {
  if (OtherAreaValue.includes(area as any)) return NotificationAreaLabel.Other;
  return area as NotificationAreaLabelModel;
};
