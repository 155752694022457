import React from "react";
import { observer } from "mobx-react";
import { Button, Heading, Icon, IconName, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";

import * as S from "./AIDiscoveryModal.styles";

const __AI_DISCOVERY_ITEMS__: {
  title: TranslationKeys;
  icon: IconName;
  subtitle: TranslationKeys;
}[] = [
  {
    title: "ai_disc_modal_title_meet",
    icon: "wand",
    subtitle: "ai_disc_modal_subtitle_meet"
  },
  {
    title: "ai_disc_modal_title_look",
    icon: "undo",
    subtitle: "ai_disc_modal_subtitle_look"
  },
  {
    title: "ai_disc_modal_title_editing",
    icon: "pencil",
    subtitle: "ai_disc_modal_subtitle_editing"
  }
];

const AIDiscoveryModal = ({ onClose }: { onClose: () => void }) => {
  const {
    ModalStore: { closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const handleOnClose = () => {
    closeModal();
    onClose();
  };

  return (
    <ModalLayout
      labels={{
        title: translate("ai_disc_modal_title"),
        mobileClose: translate("close")
      }}
      closeModal={handleOnClose}
    >
      <S.Wrapper>
        {__AI_DISCOVERY_ITEMS__.map(item => (
          <S.Card key={item.icon} isLayoutModeMobile={isLayoutModeMobile}>
            <S.Row>
              <S.IconWrapper>
                <Icon width={20} height={20} initialViewbox icon={item.icon} />
              </S.IconWrapper>
              <Heading level="5">{translate(item.title)}</Heading>
            </S.Row>
            <S.Row faded>
              <S.LeftSection />
              <Text type="formFieldEmpty">{translate(item.subtitle)}</Text>
            </S.Row>
          </S.Card>
        ))}
      </S.Wrapper>
      <S.Footer isLayoutModeMobile={isLayoutModeMobile}>
        <Button
          theme={whiteTheme}
          variant="primary"
          fullWidth={isLayoutModeMobile}
          onClick={handleOnClose}
        >
          {translate("ai_disc_modal_footer_btn")}
        </Button>
      </S.Footer>
    </ModalLayout>
  );
};

export default observer(AIDiscoveryModal);
