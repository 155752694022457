export const __AGENDA_LIST_LIMIT__ = 60;

export const __GROUPS_HOME_LIST_LIMIT__ = {
  desktop: 12,
  tablet: 12,
  smartphone: 5
};

export const __GROUP_USERS_PREVIEW_LIST_LIMIT__ = 3;
export const __GROUP_MEMBERS_LIST_LIMIT__ = 30;
export const __GROUPS_BADGE_LIST_LIMIT__ = 10;

export const __GROUPS_SHARED_LIST_LIMIT__ = 10;

export const __NOTIFICATION_FILTER_GROUPS_LIST_LIMIT__ = 30;

export const __SPACE_GROUPS_LIST_LIMIT__ = 10;
export const __SPACE_USERS_LIST_LIMIT__ = 10;
export const __SPACE_USERS__ROLES_LIST_LIMIT__ = 50;
export const __SPACE_GROUPS_LIST_SEARCH_LIMIT__ = 50;

export const __SEARCH_AUTOCOMPLETE_LIMIT__ = 3;
export const __SEARCH_USER_LIST_LIMIT_LIMIT__ = 15;

export const __SEARCH_BOARD_LIST_LIMIT__ = 10;
export const __SEARCH_GROUP_LIST_LIMIT__ = 10;
