import React, { useState } from "react";
import { Translate } from "src/js/translation/TranslationProvider";

// styles
import {
  TextAreaErrorMessage,
  TextAreaContainer,
  TextAreaCounter,
  TextAreaInput
} from "./TextArea.styles";
import { Label } from "../Label";
import { Paragraph } from "../Paragraph";
import { TEXTAREA_VARIANT } from "./TextArea.const";

/**
 * @name TextArea
 *
 * @description this is the official textArea component.
 */
export const TextArea = React.forwardRef(
  (
    {
      id,
      name,
      rows,
      placeholder,
      defaultValue = "",
      handleInputChange = () => {},
      handleBlur = () => {},
      errors,
      label,
      value = undefined,
      paragraph,
      maxChar,
      maxLength,
      variant = TEXTAREA_VARIANT.DARK,
      ...props
    },
    ref
  ) => {
    const [charCount, setCharCount] = useState(defaultValue.length);

    const errorMessage =
      errors && errors[name] ? (
        <TextAreaErrorMessage>
          <Translate text={errors[name].message} />
        </TextAreaErrorMessage>
      ) : null;

    const getLabel = () => {
      if (!label) return null;
      return React.isValidElement(label) ? (
        label
      ) : (
        <Label marginBottom="5" htmlFor={id} text={label} />
      );
    };

    const getParagraph = () => {
      if (!paragraph) return null;
      return React.isValidElement(paragraph) ? (
        paragraph
      ) : (
        <Paragraph marginBottom="10" text={paragraph} />
      );
    };

    const getCounter = () => {
      if (!maxChar && errorMessage)
        return <TextAreaCounter>{errorMessage}</TextAreaCounter>;
      if (!maxChar) return null;
      return (
        <TextAreaCounter>
          {errorMessage}
          {charCount}/{maxChar}
        </TextAreaCounter>
      );
    };

    return (
      <TextAreaContainer {...props}>
        {getLabel()}
        {getParagraph()}
        <TextAreaInput
          value={value}
          hasError={errors}
          id={id}
          name={name}
          rows={rows}
          maxLength={maxChar || maxLength}
          placeholder={placeholder}
          defaultValue={defaultValue}
          ref={ref}
          onChange={event => {
            setCharCount(event.target.value.length);
            handleInputChange(event.target.value);
          }}
          onBlur={handleBlur ? event => handleBlur(event) : null}
          variant={variant}
        />
        {getCounter()}
      </TextAreaContainer>
    );
  }
);

TextArea.displayName = "TextArea";

export default TextArea;
