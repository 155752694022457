import { SpaceBaseCard, Text } from "@arcadia/design-system";
import React from "react";
import { translateString } from "src/js/translation/TranslationProvider";
import { CircleDuration } from "../../CircleDuration";
import * as S from "./SpaceInfoCard.styles";
import { SpaceInfoCardProps } from "./SpaceInfoCard.types";

const SpaceInfoCard = ({
  spaceName = "Space Name",
  sizeVariant,
  hasTrialLogo,
  color,
  url,
  days,
  hasBorder = true
}: SpaceInfoCardProps) => {
  return (
    <SpaceBaseCard
      spaceAvatar={{
        url,
        name: spaceName,
        avatarColor: color
      }}
      sizeVariant={sizeVariant}
      hasBorder={hasBorder}
      centralContent={<Text type="formField">{spaceName}</Text>}
      rightContent={
        hasTrialLogo && (
          <S.DurationBox>
            <CircleDuration />
            {days}
            <S.Subtitle>
              {translateString({ text: "space_info_card_trial_days" })}
            </S.Subtitle>
          </S.DurationBox>
        )
      }
    />
  );
};

export default SpaceInfoCard;
