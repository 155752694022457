import React, { useState } from "react";
import { ActionButton, Button } from "@arcadia/design-system";
import { openExternalLink } from "src/js/modules/commonFunction";
import { useTranslation } from "src/js/translation";
import { OldResource, Resource } from "src/js/types";
import { useTheme } from "styled-components";

const ImageGallery = ({
  resource
}: {
  resource: (OldResource | Resource) & { images: OldResource[] };
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const { greyTheme } = useTheme();

  const { translate } = useTranslation();

  const { images } = resource;

  if (!images) return null;
  const imgSrc =
    // @ts-expect-error - TODO fix the error
    images[currentSlide].public_url || images[currentSlide].publicUrl;

  return (
    <div className="resource-gallery-box">
      <div className="resource-gallery-preview-box row">
        <div className="resource-gallery-slideshow-box col-xs-12 col-sm-12 col-lg-12">
          <div className="resource-gallery-slide-wrapper text-center">
            {currentSlide > 0 && (
              <div className="resource-gallery-slide-navigation resource-gallery-slide-prev">
                <ActionButton
                  icon="chevronLeft"
                  theme={greyTheme}
                  variant="secondary"
                  onClick={() => {
                    setCurrentSlide(currentSlide - 1);
                  }}
                />
              </div>
            )}
            <div className="resource-gallery-slide">
              <img
                className="resource-gallery-slide-img"
                src={imgSrc}
                alt="gallery_image"
              />
            </div>
            <div className="flex-centered-column">
              <Button
                theme={greyTheme}
                variant="secondary"
                onClick={() => openExternalLink(imgSrc)}
              >
                {translate("Visualizza a dimensione originale")}
              </Button>
            </div>
            {currentSlide < images.length - 1 && (
              <div className="resource-gallery-slide-navigation resource-gallery-slide-next">
                <ActionButton
                  icon="chevronRight"
                  theme={greyTheme}
                  variant="secondary"
                  onClick={() => {
                    setCurrentSlide(currentSlide + 1);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
