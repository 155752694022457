import styled, { css } from "styled-components";

import { SActionButtonProps, SBadgeProps } from "./types";

export const buttonBasic = css<SActionButtonProps>`
  background: ${({ theme, variant }) => theme.actionButton[variant].background};
  color: ${({ theme, variant }) => theme.actionButton[variant].text};
  border: ${({ theme, variant }) => theme.actionButton[variant].border};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover:not([disabled]) {
    background: ${({ theme, variant }) =>
      theme.actionButton[variant].hover.background};
    color: ${({ theme, variant }) => theme.actionButton[variant].hover.text};
    border: ${({ theme, variant }) => theme.actionButton[variant].hover.border};
    cursor: pointer;
  }
  &:hover:not([disabled]) svg {
    color: ${({ theme, variant }) => theme.actionButton[variant].hover.text};
  }

  &:hover:disabled {
    background: ${({ theme, variant }) =>
      theme.actionButton[variant].disabled.background};
    color: ${({ theme, variant }) => theme.actionButton[variant].disabled.text};
    border: ${({ theme, variant }) =>
      theme.actionButton[variant].disabled.border};
    cursor: not-allowed;
  }
  &:disabled svg {
    color: ${({ theme, variant }) => theme.actionButton[variant].disabled.text};
  }
  &:disabled {
    color: ${({ theme, variant }) => theme.actionButton[variant].disabled.text};
    border: ${({ theme, variant }) =>
      theme.actionButton[variant].disabled.border};
    background-color: ${({ theme, variant }) =>
      theme.actionButton[variant].disabled.background};
  }

  &:focus:not([disabled]),
  &:active:not([disabled]) {
    ${({ theme, variant }) =>
      variant === "global" &&
      css`
        background-color: ${theme.actionButton[variant].focus.background};
        & > svg {
          color: ${theme.actionButton[variant].focus.text};
        }
        border: ${theme.actionButton[variant].focus.border};
        outline: none;
      `};
  }
`;

export const ActionButton = styled.button`
  ${buttonBasic}
  text-align: center;
  width: ${({ variant }) => (variant === "global" ? "32px" : "40px")};
  height: ${({ variant }) => (variant === "global" ? "32px" : "40px")};
  border-radius: 20px;
  /* this is needed to fix the dimension of svg icon in safari ios */
  position: relative;
  & > svg {
    position: absolute;
  }
`;

export const Badge = styled.div<SBadgeProps>`
  position: absolute;
  z-index: 1;
  top: -4px;
  right: -6px;
  height: 12px;
  align-items: center;
  line-height: 0px;
  border-radius: 100px;
  padding: 0px 4px;
  background: ${({ background }) => background};
  border: ${({ border }) => border};

  color: ${({ text }) => text};

  > span {
    line-height: 10px;
  }
`;
