import { Icon, Loader, shadow } from "@arcadia/design-system";
import React, { FC, MouseEventHandler } from "react";
import styled, { useTheme } from "styled-components";

const ResourceCircleBtnWrap = styled.button`
  all: unset;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.grey[1000]};
  box-shadow: ${shadow[100]};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -8px;
`;

type ResourceCircleBtnProps = {
  isLoading?: boolean;
  hasDelete?: boolean;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
};

const ResourceCircleBtn: FC<ResourceCircleBtnProps> = ({
  isLoading = false,
  hasDelete = false,
  onDelete
}) => {
  const { whiteTheme, primaryColor } = useTheme();

  if (isLoading) {
    return (
      <ResourceCircleBtnWrap onClick={onDelete}>
        <Loader
          theme={whiteTheme}
          loaderType="CircularLoader"
          position="absolute"
          top="2px"
          left="2px"
          loaderWidth="12px"
          loaderHeight="12px"
          width="12px"
          height="12px"
          mainColor={primaryColor[800]}
          borderColor={primaryColor[500]}
        />
      </ResourceCircleBtnWrap>
    );
  }

  if (hasDelete) {
    return (
      <ResourceCircleBtnWrap onClick={onDelete}>
        <Icon icon="close" width={8} height={8} initialViewbox />
      </ResourceCircleBtnWrap>
    );
  }

  return null;
};

export default ResourceCircleBtn;
