import React from "react";
import { useResourcePayload } from "src/js/hooks/resources";
import { useTranslation } from "src/js/translation";
import { showToastError } from "src/js/modules/messageManager";
import { RESOURCE_PICKER_SECTION } from "../../ResourcePicker/ResourcePicker.const";

import * as S from "./ReportStudentDocumentAnswerForm.styles";

const ReportStudentResourceViewer = ({ callbackOk = () => {} }) => {
  const { translate } = useTranslation();

  const onLoadingCallback = () => {
    // TODO add some loading status
    return null;
  };

  const addResource = resource => {
    callbackOk(resource);
  };

  const onErrorCallback = error => {
    showToastError({
      str: error?.data?.message
    });
  };

  // Used to upload directly FILES to s3
  const { prepareResourcePayload } = useResourcePayload({
    onLoadingCallback,
    onSuccessCallback: successData => addResource(successData),
    onErrorCallback
  });

  const resourcePickerEl = (
    <S.CustomResourcePicker
      section={RESOURCE_PICKER_SECTION.EXERCISE}
      // Used to upload regular resources (no upload to s3)
      onSubmitCallbackSuccess={(_, successData) => {
        addResource(successData);
      }}
      onSubmitCallbackError={onErrorCallback}
      prepareModuleResourcePayload={prepareResourcePayload}
      setUploadingProgress={onLoadingCallback}
      elementIndex={0}
      embedded
    />
  );

  return (
    <>
      <S.CustomDotsMenu
        id="reportStudentDotsMenu"
        openFromTop
        alignRight
        customIcon="attachment"
        customMenu={resourcePickerEl}
        withMobileHeaderLabels
        withMobileAdaptiveHeight={false}
        mobileCloseLabel={translate("resource_mobile_close_modal")}
        mobileConfirmLabel={translate("resource_mobile_confirm_modal")}
        mobileTitle={translate("resource_mobile_title_modal")}
        listContainerId="text-editor-attachments-list"
      />
      <S.GlobalStyleAttachmentDotsMenu />
    </>
  );
};

export default ReportStudentResourceViewer;
