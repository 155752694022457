import { Box, Text } from "@arcadia/design-system";
import React from "react";
import { TranslationKeys, useTranslation } from "src/js/translation";

const QuizLabel = ({ text }: { text: TranslationKeys }) => {
  const { translate } = useTranslation();

  return (
    <Box marginTop="20px" marginBottom="16px">
      <Text type="body">{translate({ text })}</Text>
    </Box>
  );
};

export default QuizLabel;
