import { Box, Heading } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AwardedBadges } from "src/js/components/AwardedBadges";
import { ListView } from "src/js/components/ListView";
import { SpacesWelcome } from "src/js/components/SpacesWelcome";
import { HeaderWhiteHome } from "src/js/components/layout/HeaderWhiteHome";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useFetcher, useStores } from "src/js/hooks";
import useSocket from "src/js/hooks/websocket/useSocket";
import { useAppLayout } from "src/js/layout/AppLayout";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { createUrl } from "src/js/modules/routing";
import { fetchSpaceGroups } from "src/js/repository/spacesRepository";
import { __GROUPS_HOME_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { GroupInSpaceModel, SpaceGroupsParams } from "src/js/types";
import {
  DOMAIN,
  EVENT,
  TemplatingGroupCloningCompleted
} from "src/js/types/models/WebSocket";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { GroupsList } from ".";
import { GroupsEmptyState } from "./GroupsEmptyState";
import { GroupsInvitations } from "./GroupsInvitations";

const Groups = observer(() => {
  const {
    SpaceStore: {
      activeSpace,
      isDemoSpace,
      setActiveSpaceBySlug,
      userSpacesListLoading,
      cleanLastActiveSpaceLocalStorage
    },
    SpaceGroupListStore: { activeSpaceGroups },
    UserStore: { activeUser },
    SpaceMoveGroupsStore: { hasDestinationsSpaces, hasTemporaryGroups },
    UIStore: { openSideBar },
    WebSocketStore: { socket }
  } = useStores();
  const { space_slug } = useParams<SpaceGroupsParams>();
  const { pageMinHeight } = useAppLayout();
  const { trackVirtualView } = useViewTracking();
  const { translate } = useTranslation();
  const { colors } = useTheme();
  const [groupsList, setGroupsList] = useState<GroupInSpaceModel[]>([]);

  useSocket({
    socket,
    domain: DOMAIN.TEMPLATING_DOMAIN,
    event: EVENT.GROUP_CLONING_COMPLETED,
    handler: ({ data }: TemplatingGroupCloningCompleted) => {
      const el = groupsList.find(el => el?.group?.id === data?.groupId);
      if (el?.group) {
        el.group.cloningCompleted = true;
        setGroupsList([...groupsList, el]);
      }
    }
  });

  useEffect(() => {
    openSideBar();
  }, []);

  useEffect(() => {
    if (isDemoSpace) {
      trackVirtualView(WeSchoolVirtualViewDescriptions.Groups.DemoSpaceGroups);
    }
  }, [isDemoSpace]);

  useEffect(() => {
    setActiveSpaceBySlug(space_slug);
  }, [space_slug]);

  const { isLoading, fetchNextPage, isFetchingNextPage } = useFetcher(
    async ({ limit, start }) => {
      const data = await fetchSpaceGroups({
        offset: start,
        limit,
        spaceId: space_slug
      });
      setGroupsList(data.results);
      return data;
    },
    {
      limit:
        __GROUPS_HOME_LIST_LIMIT__[
          getBreakpoint() as "desktop" | "tablet" | "smartphone"
        ],
      select: ({ results }) => setGroupsList(results),
      onError: () => {
        cleanLastActiveSpaceLocalStorage();
        navigateTo(createUrl("home"));
      }
    }
  );

  useEffect(() => {
    // TODO these 2 data are the same, we should be able to use the sidebar data in this page
    // and remove the useFetcher, but need further investigation
    // for now i'm aligning the groups in case of mismatch

    if (activeSpaceGroups.length !== groupsList.length) {
      setGroupsList(activeSpaceGroups);
    }
  }, [activeSpaceGroups, groupsList]);

  const isTemporarySpace = activeSpace?.isTemporary;

  return (
    <Box
      width="100%"
      backgroundColor={colors.grey[1000]}
      minHeight={pageMinHeight}
    >
      <ListView
        isLoading={isLoading}
        onEndReached={() => fetchNextPage()}
        isLoadingBottom={isFetchingNextPage}
      >
        {userSpacesListLoading ? null : (
          <>
            <HeaderWhiteHome />
            <div id="infinite-scroll">
              <MainContainer paddingTop="0" paddingTopMobile="0">
                {groupsList.length !== 0 && (
                  <Box marginBottom={8}>
                    <Heading level="3">
                      {translate({ text: "space_groups_title" })}
                    </Heading>
                  </Box>
                )}
                {isTemporarySpace ? (
                  <SpacesWelcome
                    name={activeUser?.name}
                    createCompleted={
                      hasDestinationsSpaces || !hasTemporaryGroups
                    }
                    moveCompleted={!hasTemporaryGroups}
                  />
                ) : null}
                <GroupsInvitations
                  appendToGroupList={(group: GroupInSpaceModel) => {
                    setGroupsList([group, ...groupsList]);
                  }}
                />
                <AwardedBadges requestForSpecificSpace />
                {groupsList.length !== 0 ? (
                  <GroupsList groupsList={groupsList} />
                ) : (
                  <GroupsEmptyState />
                )}
              </MainContainer>
            </div>
          </>
        )}
      </ListView>
    </Box>
  );
});

export default Groups;
