/* eslint-disable react/require-default-props */
import React from "react";
import { useTheme } from "styled-components";
import { Avatar } from "@arcadia/design-system";
import * as S from "./AvatarMultiple.styles";

const AvatarMultiple = ({ avatars = [], size = "s" }) => {
  const { whiteTheme } = useTheme();

  return (
    <S.AvatarMultipleContainer size={size}>
      {avatars.map((avatar, idx) => {
        return (
          <Avatar
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            theme={whiteTheme}
            url={avatar}
            alt=""
            size={size}
            rounded
          />
        );
      })}
    </S.AvatarMultipleContainer>
  );
};

export default AvatarMultiple;
