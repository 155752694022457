import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyLayout from "src/js/layout/cleanLayout/EmptyLayout";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import {
  fetchDeaProject,
  createDeaProject
} from "src/js/repository/deaImportRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import createUrl from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import InvalidUrlRedirect from "src/js/pages/exceptions/InvalidUrlRedirect";

const DeaImportProject = () => {
  const { project_id } = useParams();
  // const [isLoading, setIsLoading] = useState(true);
  const [projectTitle, setProjectTitle] = useState("");
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  useEffect(() => {
    setIsInvalidUrl(false);
    fetchDeaProject(project_id)
      .then(({ name }) => {
        setProjectTitle(name);
      })
      .catch(() => {
        setIsInvalidUrl(true);
      });
  }, [project_id]);

  const createProject = projectId => {
    createDeaProject(projectId)
      .then(({ id }) => {
        showToastSuccess({
          str: <Translate text="risorsa aggiunta con successo" />
        });
        const groupUrl = createUrl("modules", {
          group_id: id
        });
        navigateTo(groupUrl);
      })
      .catch(() =>
        showToastError({ str: <Translate text="general_error_retry" /> })
      );
  };

  if (isInvalidUrl)
    return <InvalidUrlRedirect pageTitle="dea_import_project" />;

  if (!projectTitle) return null;
  return (
    <EmptyLayout pageTitle="dea_import_project">
      <div className="margin-horizontal-default padding-header--no-white-header">
        <h3 className="text-center">
          <Translate text="dea_import_project" />:
        </h3>
        <h2 className="text-center">{projectTitle}</h2>

        <div className="text-center margin-top-20">
          <button
            type="button"
            className="btn btn-action btn-xs"
            onClick={() => createProject(project_id)}
          >
            <Translate text="Crea un nuovo gruppo" />
          </button>
        </div>
      </div>
    </EmptyLayout>
  );
};

export default DeaImportProject;
