import { Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CancelUploadModal } from "src/js/components/modal/CancelUploadModal";
import { useStores, useUnsavedChangesWarning } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";

import { ScrollableDiv } from "../../global/ScrollableDiv";
import { UploadWidgetBody } from "../UploadWidgetBody";

import * as S from "./UploadWidget.styles";

const UploadWidget = () => {
  const [collapsed, setCollapsed] = useState(false);

  const [warnBeforeExit, removeWarnBeforeExit] = useUnsavedChangesWarning();

  const {
    UploadStore,
    UserTasksStore,
    ModalStore: { openModal, closeModal }
  } = useStores();

  const { uploadRequestList, pendingUpload, cancelAllPendingUpload } =
    UploadStore;

  const { tasksAreaIsVisible, tasksAreaIsExpanded } = UserTasksStore;

  useEffect(() => {
    if (pendingUpload > 0) {
      warnBeforeExit();
    } else {
      removeWarnBeforeExit();
    }
  }, [pendingUpload]);

  if (uploadRequestList.length === 0) return null;

  let domEl = document.getElementById("upload-root");

  if (!domEl) {
    domEl = document.createElement("div");
    domEl.setAttribute("id", "upload-root");
    document.body.appendChild(domEl);
  }

  const modalBody = uploadRequestList.map(uploadRequest => (
    <UploadWidgetBody
      key={uploadRequest.eventTopic}
      uploadRequest={uploadRequest}
    />
  ));

  const openCancelUploadModal = () => {
    openModal(() => (
      <CancelUploadModal
        successCallback={() => {
          cancelAllPendingUpload();
          UploadStore.reset();
          closeModal();
        }}
        closeModal={closeModal}
      />
    ));
  };

  return ReactDOM.createPortal(
    <S.ModalContainer
      higherPosition={tasksAreaIsVisible}
      hidden={tasksAreaIsExpanded}
    >
      <S.ModalCollapseContainer
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <Icon
          icon={collapsed ? "chevronUp" : "chevronDown"}
          width="22px"
          height="22px"
        />
      </S.ModalCollapseContainer>
      <S.Divider />
      <S.ModalCloseContainer
        onClick={() => {
          if (pendingUpload <= 0) return UploadStore.reset();
          return openCancelUploadModal();
        }}
      >
        <Icon icon="close" width="16px" height="16px" />
      </S.ModalCloseContainer>

      <S.ModalTitleContainer collapsed={collapsed}>
        <Text type="headerTitle">
          <Translate text="uploading_label" />
          {collapsed ? (
            <span>&nbsp;(&nbsp;{uploadRequestList.length}&nbsp;)</span>
          ) : null}
        </Text>
      </S.ModalTitleContainer>
      <S.ModalBodyContainer
        collapsed={collapsed}
        heightDimension={uploadRequestList.length * 95}
      >
        <ScrollableDiv maxHeight="400px">{modalBody}</ScrollableDiv>
      </S.ModalBodyContainer>
    </S.ModalContainer>,
    domEl
  );
};

export default observer(UploadWidget);
