import getUserClient from "src/js/modules/connection";
import { PostDetails, Post } from "src/js/types/models/Post";
import {
  api_all_posts,
  api_pin_posts,
  api_post,
  api_posts,
  api_posts_resources,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";
import { BasePaginationParams, BasePaginationResponse } from "../types";

export const fetchPosts = async ({
  groupId,
  limit,
  offset = 0
}: { groupId: number } & BasePaginationParams) => {
  return getUserClient()
    .get<BasePaginationResponse<Post>>(
      getVersionedApiUrl(api_all_posts({ groupId }), "v3"),
      {
        params: { limit, offset }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

export const createPost = async ({
  groupId,
  text
}: {
  groupId: number;
  text: string;
}) => {
  const body = { groupId, text };

  return getUserClient()
    .post<Post>(getVersionedApiUrl(api_posts(), "v3"), body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};

// TODO to be checked because it's returning 500
export const deletePost = async ({ postId }: { postId: number }) => {
  return getUserClient()
    .delete(getVersionedApiUrl(api_post({ post_id: postId }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_post"));
      throw Err;
    });
};

export const updatePostText = async ({
  postId,
  text
}: {
  postId: number;
  text: string;
}) => {
  return getUserClient()
    .patch<PostDetails>(
      getVersionedApiUrl(api_post({ post_id: postId }), "v3"),
      {
        text
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_post"));
      throw Err;
    });
};

// TODO to be checked because on edit won't actually work
export const updatePostResources = async ({
  postId,
  resources
}: {
  postId: number;
  resources: { id: number; type: string }[];
}) => {
  return getUserClient()
    .put<Post>(
      getVersionedApiUrl(api_posts_resources({ post_id: postId }), "v3"),
      {
        resources
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_post"));
      throw Err;
    });
};

export const fetchPost = async (id: number) => {
  return getUserClient()
    .get<Post>(getVersionedApiUrl(api_post({ post_id: id }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_post"));
      throw Err;
    });
};

export const updatePinnedPost = async ({ postId }: { postId: number }) => {
  return getUserClient()
    .post<Post>(getVersionedApiUrl(api_pin_posts({ postId }), "v3"))
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Attenzione, si è verificato un errore")
      );
      throw Err;
    });
};
