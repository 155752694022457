import { BottomModalSheet, Box, Icon } from "@arcadia/design-system";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import { useBoardModuleCreate } from "src/js/pages/boards/useBoardModuleCreate";
import { useTranslation } from "src/js/translation";
import {
  AiCourseGeneratorEvent,
  EventDomain,
  LessonsEvent
} from "src/js/types";
import { InfoChip } from "src/js/components/InfoChip";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { FeatureFlag } from "src/js/feature_flag/FeatureFlagProvider";
import { FeatureFlagKey } from "src/js/settings/settingsFeatureFlag";
import { ImportModuleModal } from "../ImportModuleModal";
import * as S from "./ImportCreateModuleModal.styles";
import { Props } from "./types";
import { AICourseWizardModal } from "../AICourseWizardModal";

const ImportCreateModuleMobile: FC<Props> = ({
  isOpen,
  setImportCreateModalSheetOpen
}) => {
  const {
    ModalStore: { openModal }
  } = useStores();
  const { translate } = useTranslation();
  const { handleCreateBoardModule } = useBoardModuleCreate();

  const openImportModuleModal = () => {
    openModal(() => <ImportModuleModal />);
  };

  const openAICourseWizardModal = () => {
    openModal(() => <AICourseWizardModal />);
  };

  return (
    <BottomModalSheet
      isOpen={isOpen}
      onClose={() => setImportCreateModalSheetOpen(false)}
      title=""
      withHeaderLabels={false}
      withAdaptiveHeight
    >
      <S.ModuleButton
        firstButton
        onClick={() => {
          handleCreateBoardModule();
          _trackEvent(EventDomain.Lessons, LessonsEvent.NewModuleDropdownNew);
        }}
      >
        <Box display="flex" alignItems="center">
          <Icon icon="book" height={24} width={24} />
          <S.Title type="formField">
            {translate("boards_actions_new_title")}
          </S.Title>
        </Box>
        <Icon icon="plusThin" width={16} height={16} />
      </S.ModuleButton>
      <S.ModuleButton
        onClick={() => {
          setImportCreateModalSheetOpen(false);
          openImportModuleModal();
          _trackEvent(
            EventDomain.Lessons,
            LessonsEvent.NewModuleDropdownImport
          );
        }}
      >
        <Box display="flex" alignItems="center">
          <Icon icon="squares" height={24} width={24} />
          <S.Title type="formField">
            {translate("boards_actions_import_title")}
          </S.Title>
        </Box>
        <Icon icon="plusThin" width={16} height={16} />
      </S.ModuleButton>
      <FeatureFlag flagKey={FeatureFlagKey.aiCourseGeneration}>
        <S.ModuleButton
          onClick={() => {
            setImportCreateModalSheetOpen(false);
            openAICourseWizardModal();
            _trackEvent(
              EventDomain.AiCourseGenerator,
              AiCourseGeneratorEvent.NewModuleDropdownAi
            );
          }}
        >
          <Box display="flex" alignItems="center">
            <Icon icon="wand" initialViewbox height={24} width={24} />
            <S.Title type="formField">
              {translate("module_ai_btn_title")}
            </S.Title>
            <Box marginLeft={8}>
              <InfoChip text="beta_chip_text" />
            </Box>
          </Box>
          <Icon icon="plusThin" width={16} height={16} />
        </S.ModuleButton>
      </FeatureFlag>
    </BottomModalSheet>
  );
};

export default ImportCreateModuleMobile;
