import moment from "moment";
import {
  EventCategory,
  EventCategoryType
} from "src/js/pages/calendar/Calendar.types";
import { isSameDay, setHours, setMinutes } from "date-fns";
import { CalendarEventMode, CalendarEventModeType } from "./Calendar.types";

export const getModalHeaderTitle = ({
  mode,
  eventType
}: {
  mode: CalendarEventModeType;
  eventType: EventCategoryType;
}) => {
  const titles = {
    [EventCategory.Live]: {
      [CalendarEventMode.Create]: "calendar_event_modal_title_create_live",
      [CalendarEventMode.Edit]: "calendar_event_modal_title_edit_live",
      [CalendarEventMode.Copy]: "calendar_event_modal_title_copy_live"
    },
    [EventCategory.Activity]: {
      [CalendarEventMode.Create]: "calendar_event_modal_title_create_activity",
      [CalendarEventMode.Edit]: "calendar_event_modal_title_edit_activity",
      [CalendarEventMode.Copy]: "calendar_event_modal_title_copy_activity"
    },
    [EventCategory.Deadline]: {
      [CalendarEventMode.Create]: "calendar_event_modal_title_create_deadline",
      [CalendarEventMode.Edit]: "calendar_event_modal_title_edit_deadline",
      [CalendarEventMode.Copy]: "calendar_event_modal_title_copy_deadline"
    }
  };

  return (
    titles[eventType]?.[mode] ?? titles[eventType]?.[CalendarEventMode.Create]
  );
};

export const getDatesWithTime = ({
  startDate,
  endDate,
  startTime,
  endTime
}: {
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}) => {
  const dateFnsStartDate = startDate ? new Date(startDate) : new Date();
  const dateFnsEndDate = endDate ? new Date(endDate) : new Date();

  const correctedStartTime =
    startTime && startTime.length === 5 ? startTime : "00:00";
  const correctedEndTime = endTime && endTime.length === 5 ? endTime : "00:00";

  const [startHours, startMinutes] = correctedStartTime.split(":").map(Number);
  const [endHours, endMinutes] = correctedEndTime.split(":").map(Number);

  const startDateWithTime = setHours(
    setMinutes(dateFnsStartDate, startMinutes),
    startHours
  );
  const endDateWithTime = setHours(
    setMinutes(dateFnsEndDate, endMinutes),
    endHours
  );

  return { startDateWithTime, endDateWithTime };
};

export const hasSameDay = ({
  start,
  end
}: {
  start?: string;
  end?: string;
}) => {
  const startDate = start ? new Date(start) : undefined;
  const endDate = end ? new Date(end) : undefined;
  return startDate && endDate && isSameDay(startDate, endDate);
};

export const hasSameDateAndTime = ({
  start,
  end
}: {
  start?: string;
  end?: string;
}) => {
  const startDate = start && moment(start);
  const endDate = end && moment(end);
  return startDate?.isSame(endDate);
};
