import styled from "styled-components";

export const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.modals};
`;

export const NameContainer = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.grey[50]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const ImageContainer = styled.div`
  width: 56px;
  min-width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 12px;
  overflow: hidden;
`;
