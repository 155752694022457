import { GROUP_SECTION } from "../enums/Group";
import { ObjectValues } from "./Common";
import { NotificationSettingsModel } from "./Notification";
import { SpaceModel } from "./Space";
import { AvatarModel, UserRole } from "./User";

export type GroupDetailsModel = {
  id: number;
  name: string;
  cover: AvatarModel | null;
  badge: AvatarModel | null;
  description?: string;
  subject?: string;
  createdAt?: string;
  cloningCompleted: boolean | null;
};

// values match with the base route
export const GroupSectionsEnum = {
  Wall: "wall",
  Modules: "modules",
  Exercises: "exercises",
  Whiteboard: "whiteboard",
  Activity: "activity",
  Calendar: "calendar"
} as const;

export type GroupSectionsType = ObjectValues<typeof GroupSectionsEnum>;

export const GroupSectionsRouteMatchEnum = {
  [GroupSectionsEnum.Wall]: "/:space_slug?/group/:group_id/wall",
  [GroupSectionsEnum.Modules]: "/:space_slug?/group/:group_id/modules",
  [GroupSectionsEnum.Exercises]: "/:space_slug?/group/:group_id/exercises",
  [GroupSectionsEnum.Whiteboard]: "/:space_slug?/group/:group_id/whiteboard",
  [GroupSectionsEnum.Activity]: "/:space_slug?/group/:group_id/activity",
  [GroupSectionsEnum.Calendar]: "/:space_slug?/group/:group_id/calendar"
} as const;

export type GroupSectionsRouteMatchType = ObjectValues<
  typeof GroupSectionsRouteMatchEnum
>;

export const GroupVisibilityEnum = {
  Visible: "visible",
  Hidden: "hidden"
} as const;

export type GroupVisibilityType = ObjectValues<typeof GroupVisibilityEnum>;

export const GroupSettingsVisibilityEnum = {
  [GroupSectionsEnum.Wall]: "wallVisibility",
  [GroupSectionsEnum.Modules]: "modulesVisibility",
  [GroupSectionsEnum.Exercises]: "exerciseVisibility",
  [GroupSectionsEnum.Whiteboard]: "whiteboardVisibility",
  [GroupSectionsEnum.Activity]: "recordVisibility",
  [GroupSectionsEnum.Calendar]: "calendarVisibility"
} as const;

export type GroupSettingsVisibilityType = ObjectValues<
  typeof GroupSettingsVisibilityEnum
>;

export type GroupSettingsVisibility = Partial<
  Record<GroupSettingsVisibilityType, GroupVisibilityType>
>;

export type GroupSettingsModel = GroupSettingsVisibility & {
  moderation: string;
  gamification: string;
  gamificationTest: string;
  boardComments: string;
  boardOpener: string;
  wallPost: string;
  exerciseExecution: string;
  masterclass: string;
  customRegistration: string;
  customDelivery: string;
  customPalette: number;
  tC: unknown;
  createBoard: string;
  disableCreateGroups: boolean;
  requestUserField: boolean;
  preparatoryContent: boolean;
  whiteboardEnabled: boolean;
  enableAnalytics: boolean;
  layoutColor: string;
  ctaFillColor: string;
  logoImage: AvatarModel | null;
  blocked: boolean;
  spaceAdminUuid: string;
};

export type ActiveGroupFull = {
  group: GroupDetailsModel;
  alias: string | null;
  roles: UserRole[];
  notificationSettingsPreference: NotificationSettingsModel;
  space: SpaceModel;
  settings: GroupSettingsModel;
};

export type GroupInSpaceModel = {
  isTeacher: boolean;
  needReviewExercises: number;
  usersCount: number;
  unreadNotificationsCount: number;
  notificationSettingsPreference: NotificationSettingsModel;
  space?: SpaceModel;
  group: GroupDetailsModel;
};

export type GroupInviteModel = {
  id: string;
  invite_code: string;
  group_invite: {
    group: GroupDetailsModel;
  };
};

export interface Invitation {
  is_accepted: boolean;
  is_rejected: boolean;
  email: string;
  id: number;
  invite_role: string;
  free_invitation: boolean;
  created: number;
}

export type Teacher = {
  firstName: string;
  lastName: string;
  email: string;
};

export type SpacesGroupType = {
  group: GroupDetailsModel;
  usersCount: number;
  teachers: Teacher[];
  isActive: boolean;
  isTemplate: boolean;
};

export type GroupDetailsMinimal = Pick<GroupDetailsModel, "name" | "cover"> & {
  usersCount: number;
};

export type GroupSection = ObjectValues<typeof GROUP_SECTION>;
