import React, { FC } from "react";
import { BottomModalSheet, Box, Icon, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import * as S from "./LogoutMobile.styles";

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

const LogoutMobile: FC<Props> = ({ isOpen, setOpen }) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  return (
    <BottomModalSheet
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title=""
      withHeaderLabels={false}
      withAdaptiveHeight
    >
      <S.Container>
        <S.IllustrationContainer>
          <Icon
            icon="illustration-logout"
            height={145}
            width={145}
            viewbox="0 0 151 147"
          />
        </S.IllustrationContainer>
        <S.LogoutText level="4">{translate("logout_modal_text")}</S.LogoutText>
        <S.ActionButtons>
          <S.LogoutButton
            variant="primary"
            theme={whiteTheme}
            fullWidth
            onClick={() => {
              navigateTo(createUrl("logout"));
              setOpen(false);
            }}
          >
            <Text type="headerTitleLg">
              {translate("logout_modal_action_button")}
            </Text>
          </S.LogoutButton>
          <Box
            onClick={e => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            <S.Cancel type="textLink">
              {translate("logout_modal_cancel_button")}
            </S.Cancel>
          </Box>
        </S.ActionButtons>
      </S.Container>
    </BottomModalSheet>
  );
};

export default LogoutMobile;
