import getUserClient from "src/js/modules/connection";
import {
  api_spaces_analytics_assessment_scores,
  api_spaces_analytics_groups_progress,
  api_spaces_analytics_student_learning_time,
  api_spaces_analytics_student_sessions_count,
  api_spaces_analytics_student_training_hours,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import {
  FetchAssessmentScoresParams,
  FetchAssessmentScoresResponse,
  FetchGroupsProgressParams,
  FetchGroupsProgressResponse,
  FetchUserTrainingHoursParams,
  FetchUserTrainingHoursResponse,
  FetchUsersLearningTimeParams,
  FetchUsersLearningTimeResponse,
  FetchUsersSessionsCountParams,
  FetchUsersSessionsCountResponse
} from "../types";

export const fetchGroupsProgress = async (
  params: FetchGroupsProgressParams
) => {
  const {
    spaceId,
    groupIds,
    withChartData,
    limit = 6,
    offset = 0,
    fromDate,
    toDate
  } = params;
  return getUserClient()
    .get<FetchGroupsProgressResponse>(
      getVersionedApiUrl(
        api_spaces_analytics_groups_progress({ spaceId }),
        "v3"
      ),
      {
        params: {
          groupIds,
          withChartData,
          limit,
          offset,
          fromDate,
          toDate
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error.response.data;
    });
};

export const fetchAssessmentScores = async (
  params: FetchAssessmentScoresParams
) => {
  const { spaceId, groupIds, withChartData, fromDate, toDate } = params;
  return getUserClient()
    .get<FetchAssessmentScoresResponse>(
      getVersionedApiUrl(
        api_spaces_analytics_assessment_scores({ spaceId }),
        "v3"
      ),
      {
        params: {
          groupIds,
          withChartData,
          fromDate,
          toDate
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error.response.data;
    });
};

export const fetchUserTrainingHours = async (
  params: FetchUserTrainingHoursParams
) => {
  const { spaceId, groupIds, withChartData, fromDate, toDate, aggregation } =
    params;
  return getUserClient()
    .get<FetchUserTrainingHoursResponse>(
      getVersionedApiUrl(
        api_spaces_analytics_student_training_hours({ spaceId }),
        "v3"
      ),
      {
        params: {
          groupIds,
          withChartData,
          fromDate,
          toDate,
          aggregation
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error.response.data;
    });
};

export const fetchUsersLearningTime = async (
  params: FetchUsersLearningTimeParams
) => {
  const { spaceId, groupIds, withChartData, fromDate, toDate, aggregation } =
    params;
  return getUserClient()
    .get<FetchUsersLearningTimeResponse>(
      getVersionedApiUrl(
        api_spaces_analytics_student_learning_time({ spaceId }),
        "v3"
      ),
      {
        params: {
          groupIds,
          withChartData,
          fromDate,
          toDate,
          aggregation
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error.response.data;
    });
};

export const fetchUsersSessionsCount = async (
  params: FetchUsersSessionsCountParams
) => {
  const { spaceId, groupIds, withChartData, fromDate, toDate, aggregation } =
    params;
  return getUserClient()
    .get<FetchUsersSessionsCountResponse>(
      getVersionedApiUrl(
        api_spaces_analytics_student_sessions_count({ spaceId }),
        "v3"
      ),
      {
        params: {
          groupIds,
          withChartData,
          fromDate,
          toDate,
          aggregation
        }
      }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error.response.data;
    });
};
