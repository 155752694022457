import styled from "styled-components";

export const BoxContainer = styled.div`
  height: 138px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.analyticsbox.background};
`;

export const ValueContainer = styled.div`
  width: 100%;
`;

export const SubjectContainer = styled.div`
  margin-top: 8px;
  width: 100%;
  color: ${({ theme }) => theme.analyticsbox.title.color};
`;

export const SubtitleContainer = styled.div`
  margin-top: 4px;
  line-height: 11px;
  width: 100%;
  color: ${({ theme }) => theme.analyticsbox.color};
`;
