import getUserClient from "src/js/modules/connection";
import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  api_user_scorms,
  api_group_scorms,
  api_group_activate,
  api_group_active,
  api_group_alias_active,
  api_group_emails_by_role,
  api_group_info_from_invitation,
  api_group_whiteboard_activate,
  api_group_whiteboard_deactivate,
  api_group_whiteboard_join,
  api_groups,
  api_groups_admin_members,
  api_groups_clone,
  api_groups_count_students,
  api_groups_edit,
  api_groups_invites,
  api_groups_invites_alias,
  api_groups_invites_alias_check,
  api_groups_invites_alias_edit,
  api_groups_invites_delete,
  api_groups_invites_publish,
  api_groups_invites_reject,
  api_groups_user_invites,
  api_groups_users,
  api_groups_users_edit,
  api_groups_users_most_mentioned,
  api_groups_users_restore,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { __ITALIAN_SCHOOL_JSON_URL__ } from "src/js/settings/settingsUrl";

export const fetchActiveWhiteboard = id => {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_whiteboard_join({ groupId: id })), {
      params: {
        isAppMobile: false
      }
    })
    .then(response => {
      return response.data;
    });
};

export const fetchMostMentionedUsers = groupId => {
  return getUserClient()
    .get(getVersionedApiUrl(api_groups_users_most_mentioned({ groupId }), "v3"))
    .then(response => response.data);
};

export const fetchUsersToMention = (groupId, textSearch) => {
  return getUserClient()
    .get(
      getVersionedApiUrl(api_groups_users({ groupId }), "v3"),
      textSearch !== undefined && {
        params: {
          textSearch
        }
      }
    )
    .then(response => response.data);
};

export const fetchUserScormRecords = ({
  groupId,
  limit,
  offset,
  userUuid,
  orderBy,
  sortOrder,
  textSearch
}) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_user_scorms({ groupId, userUuid }), "v3"), {
      params: {
        userUuid,
        orderBy,
        limit,
        offset,
        sortOrder,
        textSearch
      }
    })
    .then(response => response.data);
};

export const fetchScormRecords = ({
  groupId,
  limit,
  offset,
  orderBy,
  sortOrder,
  textSearch
}) => {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_scorms({ groupId }), "v3"), {
      params: {
        orderBy,
        limit,
        offset,
        sortOrder,
        textSearch
      }
    })
    .then(response => response.data);
};

export const activateWhiteboard = id => {
  return getUserClient()
    .post(getVersionedApiUrl(api_group_whiteboard_activate({ groupId: id })), {
      params: {
        isAppMobile: false
      }
    })
    .then(response => {
      return response.data;
    });
};

export const deactivateWhiteboard = id => {
  return getUserClient()
    .post(getVersionedApiUrl(api_group_whiteboard_deactivate({ groupId: id })))
    .then(response => {
      return response.data;
    });
};

export function cloneGroup(data, groupId) {
  const groupKeys = Object.keys(data);
  const formData = new FormData();
  groupKeys.forEach(key => {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  const headers = {
    "Content-Type": "multipart/form-data"
  };
  return getUserClient()
    .post(getVersionedApiUrl(api_groups_clone({ groupId }), "v3"), formData, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function createGroup(data) {
  const groupKeys = Object.keys(data);
  const formData = new FormData();
  groupKeys.forEach(key => {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  const headers = {
    "Content-Type": "multipart/form-data"
  };
  return getUserClient()
    .post(getVersionedApiUrl(api_groups(), "v3"), formData, {
      headers
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

/**
 * This will substitute the fetchGroup() because it is the V2 of the same endpoint
 */
export function getGroup(id) {
  if (!id) {
    throw new Error("error_fetch_group");
  }
  return getUserClient()
    .get(getVersionedApiUrl(api_group_active({ groupId: id }), "v3"))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_fetch_group"));
      throw Err;
    });
}

export function editGroupInfo({ id, name, subject, description, fileCover }) {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("subject", subject);
  formData.append("description", description);
  if (fileCover) formData.append("cover", fileCover);

  const headers = {
    "Content-Type": "multipart/form-data"
  };
  return getUserClient()
    .post(
      getVersionedApiUrl(api_groups_edit({ groupId: id }), "v3"),
      formData,
      { headers }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_edit_group"));
      throw Err;
    });
}

/**
 * Edit the template status of a group
 * @param {Object} params - The parameters for the request
 * @param {number} params.id - The id of the group
 * @param {boolean} params.isTemplate - The new template status
 * @returns {Promise} - A promise that resolves with the updated group data
 */
export function editGroupIsTemplate({ id, isTemplate }) {
  const formData = new FormData();
  formData.append("isTemplate", isTemplate ? 1 : 0);

  const headers = {
    "Content-Type": "multipart/form-data"
  };
  return getUserClient()
    .post(
      getVersionedApiUrl(api_groups_edit({ groupId: id }), "v3"),
      formData,
      { headers }
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_edit_group"));
      throw Err;
    });
}

export function getAlias(groupId) {
  return getUserClient()
    .get(getVersionedApiUrl(api_groups_invites_alias({ groupId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_get_alias"));
      throw Err;
    });
}

export function patchPublicInvite(groupId) {
  return getUserClient()
    .patch(getVersionedApiUrl(api_groups_invites_publish({ groupId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_patch_public_invite")
      );
      throw Err;
    });
}

export function checkAlias(groupId, alias) {
  return getUserClient()
    .get(
      getVersionedApiUrl(
        api_groups_invites_alias_check({ groupId, alias }),
        "v3"
      )
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export function saveAlias(groupId, alias) {
  const body = {
    alias
  };
  return getUserClient()
    .patch(
      getVersionedApiUrl(api_groups_invites_alias_edit({ groupId }), "v3"),
      body
    )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}

export async function inviteUsers(groupId, body) {
  try {
    const { data } = await getUserClient().post(
      getVersionedApiUrl(api_groups_user_invites({ groupId }), "v3"),
      body
    );
    return data;
  } catch (error) {
    const Err = error.response.data;
    throw Err;
  }
}

export function fetchGroupUsers({ groupId, limit, offset, roles = [] }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_groups_users({ groupId }), "v3"), {
      params: {
        limit,
        offset,
        roles
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_get_users"));
      throw Err;
    });
}

export function fetchGroupEmailByRoles({ groupId, limit, offset, role }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_emails_by_role({ groupId })), {
      params: {
        limit,
        offset,
        role
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_get_group_by_email")
      );
      throw Err;
    });
}

export function getAllGroupMembers(
  groupId,
  limit,
  offset,
  isTeacher,
  timestamp
) {
  return getUserClient()
    .get(getVersionedApiUrl(api_groups_admin_members({ groupId }), "v2"), {
      params: {
        limit,
        offset,
        with_students: 1,
        with_teachers: isTeacher ? 1 : 0,
        timestamp
      }
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_get_users"));
      throw Err;
    });
}

export function getPendingInvites(groupId) {
  return getUserClient()
    .get(getVersionedApiUrl(api_groups_invites({ groupId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_get_invites"));
      throw Err;
    });
}

export function deleteInvite(inviteId) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_groups_invites_delete({ inviteId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "delete_invite_error"));
      throw Err;
    });
}

export function changeUserRole(groupId, userId, role) {
  const body = {
    target_role: role
  };
  return getUserClient()
    .put(getVersionedApiUrl(api_groups_users_edit({ groupId, userId })), body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "change_user_role_error")
      );
      throw Err;
    });
}

export function removeUser(groupId, userId) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_groups_users_edit({ groupId, userId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "remove_user_error"));
      throw Err;
    });
}

export function restoreUser(groupId, userId) {
  return getUserClient()
    .post(getVersionedApiUrl(api_groups_users_restore({ groupId, userId })))
    .then(({ data }) => {
      return data;
    });
}

export function deleteGroup(id) {
  return getUserClient()
    .delete(getVersionedApiUrl(api_group_active({ groupId: id })))
    .then(response => {
      return response.data;
    });
}

export function activateGroup(id) {
  return getUserClient()
    .put(getVersionedApiUrl(api_group_activate({ groupId: id })))
    .then(response => {
      return response.data;
    });
}

export function checkCodeStatus(alias) {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_alias_active({ alias })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = error.response.data;
      throw Err;
    });
}

export const getGroupInfoFromInvitation = async invitationId => {
  const { data } = await getUserClient().get(
    getVersionedApiUrl(api_group_info_from_invitation({ invitationId }), "v3")
  );
  return data;
};

export const rejectGroupInvitation = async invitationId => {
  try {
    const { data } = await getUserClient().patch(
      getVersionedApiUrl(api_groups_invites_reject({ inviteId: invitationId }))
    );
    return data;
  } catch (error) {
    const Err = new Error(error);
    throw Err;
  }
};

export function fetchItalianSchoolOptions() {
  return fetch(__ITALIAN_SCHOOL_JSON_URL__).then(response => response.json());
}

export function fetchGroupStudentsCount(groupId) {
  return getUserClient()
    .get(getVersionedApiUrl(api_groups_count_students({ groupId })))
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      const Err = error.response.data;
      throw Err;
    });
}
