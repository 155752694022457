import { SpecificError } from "@arcadia/design-system";
import React from "react";
import { InputCheckboxValidatedPrimaryProps } from "src/js/components/forms/InputCheckboxValidatedPrimary/types";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./InputCheckboxValidatedPrimary.styles";

const InputCheckboxValidatedPrimary = ({
  name,
  register,
  validation,
  errorMessage,
  id,
  label
}: InputCheckboxValidatedPrimaryProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <div>
      <S.CheckboxLabel>
        <S.CheckboxInput
          id={id}
          name={name}
          ref={register(validation)}
          type="checkbox"
        />
        <S.CheckboxCustom />
      </S.CheckboxLabel>
      <S.CheckboxText>{label}</S.CheckboxText>
      {errorMessage ? (
        <SpecificError theme={whiteTheme} text={translate(errorMessage)} />
      ) : null}
    </div>
  );
};

export default InputCheckboxValidatedPrimary;
