import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 72px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 16px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.5s ease 2.5s;
  opacity: 1;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 16px;
    box-shadow: ${({ theme }) => `0px 0px 12px ${theme.primaryColor[500]}4d`};
    transition: opacity 0.5s ease;
  }

  ${({ dismissAnimation }) =>
    dismissAnimation &&
    css`
      opacity: 0;
      height: 0;
      z-index: 0;
      transform: translateY(80px);

      &:after {
        opacity: 1;
      }
    `};
`;

export const Img = styled.img`
  height: 100%;
  padding: 16px;
  width: 88px;
  object-fit: cover;
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 160px;
  height: 50px;
  gap: 4px;
  & > :nth-child(2) {
    font-size: 10px !important;
    line-height: 10px !important;
  }
`;

export const WrapperButton = styled.div`
  margin-left: auto;
  margin-right: 16px;
  z-index: 1;
  & > button[disabled] {
    cursor: auto !important;
  }
`;

export const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
`;

export const ActionCompletedWrapper = styled.div`
  opacity: ${({ opacity }) => opacity};
  transition: all 0.5s ease;
`;
