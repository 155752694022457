import { __ } from "../../modules/localization";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import indexOf from "lodash/indexOf";
import maxBy from "lodash/maxBy";
import range from "lodash/range";
import without from "lodash/without";
import filter from "lodash/filter";
import find from "lodash/find";
import QuizModel from "./quizModel";

class QuizCrosswordsModel extends QuizModel {
  constructor(attributes, options) {
    super(attributes, options);
    this.placeholderChar = "_";
  }

  /** This quiz has no title */
  getTitle() {
    return "";
  }
  getGridSize() {
    const words = this.getQuestions();
    const horizontal = filter(words, function (word) {
      return word.orientation == "horizontal";
    });
    const vertical = filter(words, function (word) {
      return word.orientation == "vertical";
    });
    const max_offset_horz = maxBy(horizontal, function (word) {
      return word.x + word.length;
    });
    const max_offset_vert = maxBy(vertical, function (word) {
      return word.y + word.length;
    });
    return {
      x: max_offset_horz.x + max_offset_horz.length,
      y: max_offset_vert.y + max_offset_vert.length
    };
  }
  createGrid() {
    const gridSize = this.getGridSize();
    const grid = range(gridSize.y).map(n => range(gridSize.x).map(n => null));
    return grid;
  }
  populateGrid() {
    const grid = this.createGrid();
    let words = this.getQuestions();
    words = sortBy(words, q => q.index); // riordino per index

    each(words, word => {
      // let letters = word.value.split("");
      const letters = range(word.length).map(n => "");
      each(letters, (letter, key) => {
        let posX = 0;
        let posY = 0;
        if (word.orientation === "horizontal") {
          posX = word.x + key;
          posY = word.y;
        } else {
          posX = word.x;
          posY = word.y + key;
        }
        if (isEmpty(grid[posY][posX])) {
          const isFirstLetter = key === 0;
          const firstLetterOf = isFirstLetter ? [word.index] : [];
          grid[posY][posX] = {
            words: [word],
            letter,
            isFirstLetter,
            firstLetterOf
          };
        } else {
          const old_words = grid[posY][posX].words;
          old_words.push(word);
          const wasFirstLetter = grid[posY][posX].isFirstLetter;
          const isFirstLetter = key === 0 || wasFirstLetter;
          let firstLetterOf = [];
          if (isFirstLetter) {
            firstLetterOf = grid[posY][posX].firstLetterOf;
            firstLetterOf.push(word.index);
          } else {
            firstLetterOf = grid[posY][posX].firstLetterOf;
          }

          grid[posY][posX] = {
            words: old_words,
            letter,
            isFirstLetter,
            firstLetterOf
          };
        }
      });
    });

    // dovrei anche inserire preview risposte inserite

    return grid;
  }
  getUserAnswer() {
    const self = this;
    const answers = [];
    const grid = this.populateGrid();
    const questions = this.getQuestions();

    // prendo parole.
    each(questions, q => {
      const text = []; // elementi che compongono ogni parola
      each(grid, (row, row_n) => {
        // prendo quadrati dalla griglia che corrispondono all'index di ogni parola
        each(row, (col, col_n) => {
          if (!isEmpty(col)) {
            const indexes = map(col.words, "index");
            if (indexOf(indexes, q.index) >= 0) {
              const userInput = self.getUserInputBySquare(row_n, col_n);
              text.push(userInput); // costruisco valore
            }
          }
        });
      });

      const answer = {};
      answer.index = q.index;
      answer.x = q.x;
      answer.y = q.y;
      answer.orientation = q.orientation;
      answer.value = text.join(""); // setto obj
      answers.push(answer);
    });

    return answers;
  }
  getFormattedUserAnswer() {
    const result = this.getUserAnswer();
    return result;
  }
  isFullyAnswered() {
    const self = this;
    const questions = this.getQuestions();
    const real_answers = this.getUserAnswer();
    const answers = [];
    each(real_answers, a => {
      const split = a.value.split("");
      const normalChars = without(split, self.placeholderChar);
      if (normalChars.length > 0) answers.push(a);
    });
    const expected_answers = questions;
    return answers.length === expected_answers.length;
  }
  setUserInputBySquare(value, row, col) {
    if (value.length === 0) value = this.placeholderChar;
    const answer = this.getUserInputs();
    if (answer[row] === undefined) answer[row] = [];
    answer[row][col] = value;
    this.set("userInputs", answer);
  }
  getUserInputs() {
    return this.get("userInputs") || this.createGrid();
  }
  getUserInputBySquare(row, col) {
    let ret = this.placeholderChar;
    const answer = this.getUserInputs();
    if (answer[row] !== undefined && answer[row][col] !== undefined) {
      ret = answer[row][col];
      if (ret === null || ret.length === 0) ret = this.placeholderChar;
    }
    return ret;
  }
  checkUserAnswer(success_threshold) {
    let ret = false;
    const answers = this.getUserAnswer();
    const solutions = this.getSolutions();
    let correct = 0;
    let wrong = 0;

    each(solutions, s => {
      const answer = find(answers, { index: s.index });
      if (answer !== undefined) {
        if (answer.value.toLowerCase() === s.value.toLowerCase()) {
          correct += 1;
        } else {
          wrong += 1;
        }
      } else {
        wrong += 1;
      }
    });

    if (success_threshold === undefined)
      success_threshold = this.getDefaultThreshold(); // se non è specificata percentuale, metto default

    const perc = (correct / solutions.length) * 100;

    ret = perc >= success_threshold;

    return ret;
  }
}

export default QuizCrosswordsModel;

function CrosswordCell(letter) {
  this.char = letter; // the actual letter for the cell on the crossword
  // If a word hits this cell going in the "across" direction, this will be a CrosswordCellNode
  this.across = null;
  // If a word hits this cell going in the "down" direction, this will be a CrosswordCellNode
  this.down = null;
}

// You can tell if the Node is the start of a word (which is needed if you want to number the cells)
// and what word and clue it corresponds to (using the index)
function CrosswordCellNode(is_start_of_word, index) {
  this.is_start_of_word = is_start_of_word;
  this.index = index; // use to map this node to its word or clue
}

function WordElement(word, index) {
  this.word = word; // the actual word
  this.index = index; // use to map this node to its word or clue
}

export function Crossword(words_in, clues_in) {
  const GRID_ROWS = 50;
  const GRID_COLS = 50;
  // This is an index of the positions of the char in the crossword (so we know where we can potentially place words)
  // example {"a" : [{'row' : 10, 'col' : 5} {'row' : 62, 'col' :17}], {'row' : 54, 'col' : 12}], "b" : [{'row' : 3, 'col' : 13}]}
  // where the two item arrays are the row and column of where the letter occurs
  let char_index = {};

  // these words are the words that can't be placed on the crossword
  let bad_words;

  // returns a formatted objects of words with their starting position and direction
  this.getFormattedObj = function (grid) {
    const obj = [];
    const groups = { across: [], down: [] };
    let position = 1;
    if (typeof grid === "object" && grid !== null) {
      for (let r = 0; r < grid.length; r++) {
        for (let c = 0; c < grid[r].length; c++) {
          const cell = grid[r][c];
          let increment_position = false;
          // check across and down
          for (const k in groups) {
            // does a word start here? (make sure the cell isn't null, first)
            if (cell && cell[k] && cell[k].is_start_of_word) {
              const index = cell[k].index;
              // groups[k].push({"position" : position, "index" : index, "clue" : clues_in[index], "word" : words_in[index], "x": c, "y": r});
              obj.push({
                index,
                value: words_in[index],
                question: clues_in[index],
                x: c,
                y: r,
                orientation: k === "across" ? "horizontal" : "vertical"
              });
              increment_position = true;
            }
          }

          if (increment_position) position += 1;
        }
      }
    }
    return obj;
  };

  // returns the crossword grid that has the ratio closest to 1 or null if it can't build one
  this.getSquareGrid = function (max_tries) {
    let best_grid = null;
    let best_ratio = 0;
    for (let i = 0; i < max_tries; i++) {
      const a_grid = this.getGrid(1);
      if (a_grid == null) continue;
      const ratio =
        (Math.min(a_grid.length, a_grid[0].length) * 1.0) /
        Math.max(a_grid.length, a_grid[0].length);
      if (ratio > best_ratio) {
        best_grid = a_grid;
        best_ratio = ratio;
      }

      if (best_ratio == 1) break;
    }
    return best_grid;
  };

  // returns an abitrary grid, or null if it can't build one
  this.getGrid = function (max_tries) {
    let word_has_been_added_to_grid;
    let groups = [];
    for (let tries = 0; tries < max_tries; tries++) {
      clear(); // always start with a fresh grid and char_index
      // place the first word in the middle of the grid
      const start_dir = randomDirection();
      let r = Math.floor(grid.length / 2);
      let c = Math.floor(grid[0].length / 2);
      const word_element = word_elements[0];
      if (start_dir == "across") {
        c -= Math.floor(word_element.word.length / 2);
      } else {
        r -= Math.floor(word_element.word.length / 2);
      }

      if (canPlaceWordAt(word_element.word, r, c, start_dir) !== false) {
        placeWordAt(word_element.word, word_element.index, r, c, start_dir);
      } else {
        bad_words = [word_element];
        return null;
      }

      // start with a group containing all the words (except the first)
      // as we go, we try to place each word in the group onto the grid
      // if the word can't go on the grid, we add that word to the next group
      groups.push(word_elements.slice(1));
      for (let g = 0; g < groups.length; g++) {
        word_has_been_added_to_grid = false;
        // try to add all the words in this group to the grid
        for (let i = 0; i < groups[g].length; i++) {
          const word_element = groups[g][i];
          const best_position = findPositionForWord(word_element.word);
          if (!best_position) {
            // make the new group (if needed)
            if (groups.length - 1 == g) groups.push([]);
            // place the word in the next group
            groups[g + 1].push(word_element);
          } else {
            let r = best_position.row,
              c = best_position.col,
              dir = best_position.direction;
            placeWordAt(word_element.word, word_element.index, r, c, dir);
            word_has_been_added_to_grid = true;
          }
        }
        // if we haven't made any progress, there is no point in going on to the next group
        if (!word_has_been_added_to_grid) break;
      }
      // no need to try again
      if (word_has_been_added_to_grid) return minimizeGrid();
    }

    bad_words = groups[groups.length - 1];
    return null;
  };

  // returns the list of WordElements that can't fit on the crossword
  this.getBadWords = function () {
    return bad_words;
  };

  // get two arrays ("across" and "down") that contain objects describing the
  // topological position of the word (e.g. 1 is the first word starting from
  // the top left, going to the bottom right), the index of the word (in the
  // original input list), the clue, and the word itself
  this.getLegend = function (grid) {
    const groups = { across: [], down: [] };
    let position = 1;
    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid[r].length; c++) {
        const cell = grid[r][c];
        let increment_position = false;
        // check across and down
        for (const k in groups) {
          // does a word start here? (make sure the cell isn't null, first)
          if (cell && cell[k] && cell[k].is_start_of_word) {
            const index = cell[k].index;
            groups[k].push({
              position,
              index,
              clue: clues_in[index],
              word: words_in[index]
            });
            increment_position = true;
          }
        }

        if (increment_position) position += 1;
      }
    }
    return groups;
  };

  // move the grid onto the smallest grid that will fit it
  let minimizeGrid = function () {
    // find bounds
    let r_min = GRID_ROWS - 1,
      r_max = 0,
      c_min = GRID_COLS - 1,
      c_max = 0;
    for (let r = 0; r < GRID_ROWS; r++) {
      for (let c = 0; c < GRID_COLS; c++) {
        const cell = grid[r][c];
        if (cell != null) {
          if (r < r_min) r_min = r;
          if (r > r_max) r_max = r;
          if (c < c_min) c_min = c;
          if (c > c_max) c_max = c;
        }
      }
    }
    // initialize new grid
    const rows = r_max - r_min + 1;
    const cols = c_max - c_min + 1;
    const new_grid = new Array(rows);
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        new_grid[r] = new Array(cols);
      }
    }

    // copy the grid onto the smaller grid
    for (let r = r_min, r2 = 0; r2 < rows; r++, r2++) {
      for (let c = c_min, c2 = 0; c2 < cols; c++, c2++) {
        new_grid[r2][c2] = grid[r][c];
      }
    }

    return new_grid;
  };

  // helper for placeWordAt();
  const addCellToGrid = function (
    word,
    index_of_word_in_input_list,
    index_of_char,
    r,
    c,
    direction
  ) {
    const char = word.charAt(index_of_char);
    if (grid[r][c] == null) {
      grid[r][c] = new CrosswordCell(char);

      // init the char_index for that character if needed
      if (!char_index[char]) char_index[char] = [];

      // add to index
      char_index[char].push({ row: r, col: c });
    }

    const is_start_of_word = index_of_char == 0;
    grid[r][c][direction] = new CrosswordCellNode(
      is_start_of_word,
      index_of_word_in_input_list
    );
  };

  // place the word at the row and col indicated (the first char goes there)
  // the next chars go to the right (across) or below (down), depending on the
  let placeWordAt = function (
    word,
    index_of_word_in_input_list,
    row,
    col,
    direction
  ) {
    direction;
    if (direction == "across") {
      for (let c = col, i = 0; c < col + word.length; c++, i++) {
        addCellToGrid(word, index_of_word_in_input_list, i, row, c, direction);
      }
    } else if (direction == "down") {
      for (let r = row, i = 0; r < row + word.length; r++, i++) {
        addCellToGrid(word, index_of_word_in_input_list, i, r, col, direction);
      }
    } else {
      throw "Invalid Direction";
    }
  };

  // you can only place a char where the space is blank, or when the same
  // character exists there already
  // returns false, if you can't place the char
  // 0 if you can place the char, but there is no intersection
  // 1 if you can place the char, and there is an intersection
  const canPlaceCharAt = function (char, row, col) {
    // no intersection
    if (grid[row][col] == null) return 0;
    // intersection!
    if (grid[row][col].char == char) return 1;

    return false;
  };

  // determines if you can place a word at the row, column in the direction
  let canPlaceWordAt = function (word, row, col, direction) {
    let intersections = 0;
    // out of bounds
    if (row < 0 || row >= grid.length || col < 0 || col >= grid[row].length)
      return false;

    if (direction == "across") {
      // out of bounds (word too long)
      if (col + word.length > grid[row].length) return false;
      // can't have a word directly to the left
      if (col - 1 >= 0 && grid[row][col - 1] != null) return false;
      // can't have word directly to the right
      if (
        col + word.length < grid[row].length &&
        grid[row][col + word.length] != null
      )
        return false;

      // check the row above to make sure there isn't another word
      // running parallel. It is ok if there is a character above, only if
      // the character below it intersects with the current word
      for (
        let r = row - 1, c = col, i = 0;
        r >= 0 && c < col + word.length;
        c++, i++
      ) {
        const is_empty = grid[r][c] == null;
        const is_intersection =
          grid[row][c] != null && grid[row][c].char == word.charAt(i);
        const can_place_here = is_empty || is_intersection;
        if (!can_place_here) return false;
      }

      // same deal as above, we just search in the row below the word
      for (
        let r = row + 1, c = col, i = 0;
        r < grid.length && c < col + word.length;
        c++, i++
      ) {
        const is_empty = grid[r][c] == null;
        const is_intersection =
          grid[row][c] != null && grid[row][c].char == word.charAt(i);
        const can_place_here = is_empty || is_intersection;
        if (!can_place_here) return false;
      }

      // check to make sure we aren't overlapping a char (that doesn't match)
      // and get the count of intersections
      for (let c = col, i = 0; c < col + word.length; c++, i++) {
        const result = canPlaceCharAt(word.charAt(i), row, c);
        if (result === false) return false;
        intersections += result;
      }
    } else if (direction == "down") {
      // out of bounds
      if (row + word.length > grid.length) return false;
      // can't have a word directly above
      if (row - 1 >= 0 && grid[row - 1][col] != null) return false;
      // can't have a word directly below
      if (
        row + word.length < grid.length &&
        grid[row + word.length][col] != null
      )
        return false;

      // check the column to the left to make sure there isn't another
      // word running parallel. It is ok if there is a character to the
      // left, only if the character to the right intersects with the
      // current word
      for (
        let c = col - 1, r = row, i = 0;
        c >= 0 && r < row + word.length;
        r++, i++
      ) {
        const is_empty = grid[r][c] == null;
        const is_intersection =
          grid[r][col] != null && grid[r][col].char == word.charAt(i);
        const can_place_here = is_empty || is_intersection;
        if (!can_place_here) return false;
      }

      // same deal, but look at the column to the right
      for (
        let c = col + 1, r = row, i = 0;
        r < row + word.length && c < grid[r].length;
        r++, i++
      ) {
        const is_empty = grid[r][c] == null;
        const is_intersection =
          grid[r][col] != null && grid[r][col].char == word.charAt(i);
        const can_place_here = is_empty || is_intersection;
        if (!can_place_here) return false;
      }

      // check to make sure we aren't overlapping a char (that doesn't match)
      // and get the count of intersections
      for (let r = row, i = 0; r < row + word.length; r++, i++) {
        const result = canPlaceCharAt(word.charAt(i, 1), r, col);
        if (result === false) return false;
        intersections += result;
      }
    } else {
      throw __("Invalid Direction");
    }
    return intersections;
  };

  let randomDirection = function () {
    return Math.floor(Math.random() * 2) ? "across" : "down";
  };

  let findPositionForWord = function (word) {
    // check the char_index for every letter, and see if we can put it there in a direction
    const bests = [];
    for (let i = 0; i < word.length; i++) {
      const possible_locations_on_grid = char_index[word.charAt(i)];
      if (!possible_locations_on_grid) continue;
      for (let j = 0; j < possible_locations_on_grid.length; j++) {
        const point = possible_locations_on_grid[j];
        const r = point.row;
        const c = point.col;
        // the c - i, and r - i here compensate for the offset of character in the word
        const intersections_across = canPlaceWordAt(word, r, c - i, "across");
        const intersections_down = canPlaceWordAt(word, r - i, c, "down");

        if (intersections_across !== false) {
          bests.push({
            intersections: intersections_across,
            row: r,
            col: c - i,
            direction: "across"
          });
        }
        if (intersections_down !== false) {
          bests.push({
            intersections: intersections_down,
            row: r - i,
            col: c,
            direction: "down"
          });
        }
      }
    }

    if (bests.length == 0) return false;

    // find a good random position
    const best = bests[Math.floor(Math.random() * bests.length)];

    return best;
  };

  let clear = function () {
    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid[r].length; c++) {
        grid[r][c] = null;
      }
    }
    char_index = {};
  };

  // constructor
  if (words_in.length < 2) throw __("A crossword must have at least 2 words");
  if (words_in.length != clues_in.length)
    throw __("The number of words must equal the number of clues");

  // build the grid;
  let grid = new Array(GRID_ROWS);
  for (let i = 0; i < GRID_ROWS; i++) {
    grid[i] = new Array(GRID_COLS);
  }

  // build the element list (need to keep track of indexes in the originial input arrays)
  let word_elements = [];
  for (let i = 0; i < words_in.length; i++) {
    word_elements.push(new WordElement(words_in[i], i));
  }

  // I got this sorting idea from http://stackoverflow.com/questions/943113/algorithm-to-generate-a-crossword/1021800#1021800
  // seems to work well
  word_elements.sort((a, b) => b.word.length - a.word.length);
}

const CrosswordUtils = {
  PATH_TO_PNGS_OF_NUMBERS: "numbers/",

  toHtml(grid, show_answers) {
    if (grid == null) return;
    const html = [];
    html.push("<table class='crossword'>");
    let label = 1;
    for (let r = 0; r < grid.length; r++) {
      html.push("<tr>");
      for (let c = 0; c < grid[r].length; c++) {
        const cell = grid[r][c];
        const is_start_of_word = false;
        if (cell == null) {
          const char = "&nbsp;";
          const css_class = "no-border";
        } else {
          const char = cell.char;
          const css_class = "";
          const is_start_of_word =
            (cell.across && cell.across.is_start_of_word) ||
            (cell.down && cell.down.is_start_of_word);
        }

        if (is_start_of_word) {
          const img_url = `${
            CrosswordUtils.PATH_TO_PNGS_OF_NUMBERS + label
          }.png`;
          html.push(
            `<td class='${css_class}' title='${r}, ${c}' style="background-image:url('${img_url}')">`
          );
          label++;
        } else {
          html.push(`<td class='${css_class}' title='${r}, ${c}'>`);
        }

        if (show_answers) {
          html.push(char);
        } else {
          html.push("&nbsp;");
        }
      }
      html.push("</tr>");
    }
    html.push("</table>");
    return html.join("\n");
  }
};
