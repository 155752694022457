import {
  Box,
  Heading as DSHeading,
  Icon as DSIcon
} from "@arcadia/design-system";
import styled from "styled-components";

export const RegistrationInviteOptionsContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 20px;
  padding: 40px 60px 32px 60px;
  max-width: 514px;

  @media screen and (max-width: 767px) {
    margin: 0px 16px;
    padding: 40px 16px 32px 16px;
  }
`;

export const RegistrationOptionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 20px;
  padding: 40px 60px 32px 60px;
  max-width: 514px;

  @media screen and (max-width: 767px) {
    margin: 0px 16px;
    padding: 180px 0px 32px 0px;
    background-color: transparent;
  }
`;

export const Heading = styled(DSHeading)`
  margin-right: 6px;
`;

export const CustomTitleHeading = styled(DSHeading)`
  display: block;
  flex: 1;
`;

export const InviteSpaceName = styled.span`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const Icon = styled(DSIcon)`
  cursor: pointer;
  margin-right: 16px;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.coldGrey[900]};
  width: 392px;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor[500]};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const LoginButtonContainer = styled.div<{ isInRegisterForm: boolean }>`
  margin-top: ${({ isInRegisterForm }) => (isInRegisterForm ? "18px" : "86px")};
  margin-bottom: 32px;

  & > div:first-child {
    background-color: inherit;
    padding: 0;

    & > div {
      display: flex;
      align-items: center;
      & > span {
        font-weight: 400;
      }
      & > a {
        padding-bottom: 0;
        border: none;
      }
    }
  }
`;

export const StepContainer = styled.div`
  width: 100%;
  height: 6px;
  margin: 16px auto auto;
  max-width: 112px;
  background-color: ${({ theme }) => theme.colors.grey[900]};
  border-radius: 7px;
`;

export const Step = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  width: 100%;
  height: 100%;
  border-radius: 7px;
`;
