import React from "react";
import { useHistory } from "react-router";
import { ActionButton } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { Animations } from "src/js/settings/animations";
import { useTheme } from "styled-components";
import { SecondaryBar } from "../../SecondaryBar";

import * as S from "./HeaderBack.styles";

type HeaderBackProps = {
  onGoBack?: () => void;
  stringTitle?: string;
  title?: TranslationKeys;
  rightText?: TranslationKeys;
  onClickRightSection?: () => void;
};

const HeaderBack = ({
  onGoBack,
  stringTitle,
  title,
  rightText,
  onClickRightSection
}: HeaderBackProps) => {
  const { goBack } = useHistory();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const onClickBack = () => {
    if (!onGoBack) goBack();
    onGoBack();
  };

  const onClickRight = () => {
    if (!onClickRightSection) return;
    onClickRightSection();
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <SecondaryBar
        minHeight={70}
        leftSection={
          <S.LeftSectionWrapper
            {...Animations.Fade}
            transition={{ delay: 0.2 }}
          >
            <ActionButton
              variant="secondaryColored"
              theme={whiteTheme}
              icon="chevronLeft"
              onClick={onClickBack}
            />
          </S.LeftSectionWrapper>
        }
        middleSection={
          <S.MiddleSectionWrapper
            {...Animations.Fade}
            transition={{ delay: 0.4 }}
          >
            <S.Title level="5">
              {stringTitle || (title && translate(title))}
            </S.Title>
          </S.MiddleSectionWrapper>
        }
        rightSection={
          rightText && (
            <S.RightSectionWrapper
              {...Animations.Fade}
              transition={{ delay: 0.8 }}
              onClick={onClickRight}
            >
              <S.RightAction type="textLink">
                {translate(rightText)}
              </S.RightAction>
            </S.RightSectionWrapper>
          )
        }
      />
    </AnimatePresence>
  );
};

export default HeaderBack;
