import { TranslationKeys } from "src/js/translation";
import { SchoolClaimFormStatus } from "./SchoolClaimForm.types";

export const getLabelsSchoolClaim = (
  status: SchoolClaimFormStatus
): {
  title: TranslationKeys;
  subtitle?: TranslationKeys;
} => {
  switch (status) {
    case SchoolClaimFormStatus.NewSpace:
    case SchoolClaimFormStatus.UnclaimedSpace:
      return {
        title: "school_claim_title_success",
        subtitle: null
      };
    case SchoolClaimFormStatus.ClaimedSpace:
      return {
        title: "school_claim_title_error",
        subtitle: "school_claim_subtitle_error"
      };
    case SchoolClaimFormStatus.Selection:
    default:
      return {
        title: "school_claim_title",
        subtitle: null
      };
  }
};
