import React, { forwardRef } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./RadioGroup.styles";
import { RadioGroupProps } from "./types";

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      id,
      theme,
      name,
      options,
      value,
      onChangeFunction,
      orientationOption = "inline",
      orientationLabel = "left",
      textType,
      ...rest
    },
    ref
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
      onChangeFunction(event.target.value);
    };

    return (
      <ThemeProvider theme={theme}>
        <S.RadioGroup
          id={id}
          orientationColumn={orientationOption === "column"}
          {...rest}
        >
          {options.map(i => {
            return (
              <S.RadioButton
                key={i.value}
                value={i.value}
                label={i.label}
                id={i.value}
                parentId={id}
                disabled={i.disabled}
                name={name}
                checked={value === i.value}
                onChange={handleChange}
                ref={ref}
                orientationLabel={orientationLabel}
                orientationColumn={orientationOption === "column"}
                textType={textType}
              />
            );
          })}
        </S.RadioGroup>
      </ThemeProvider>
    );
  }
);

RadioGroup.displayName = "RadioGroup";

export default RadioGroup;
