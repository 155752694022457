import styled from "styled-components";
import { eventcard } from "#themes/white/eventcard";

export const Container = styled.div`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  color: ${({ theme }) => theme.colors.primary2};
`;

export const EventCardWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const FlexDescriptionHeader = styled.div`
  display: block;
  h4 {
    text-transform: uppercase;
  }
`;

export const EmptyCard = styled.div`
  border-radius: 6px;
  background-color: ${eventcard.background};
  min-height: 70px;
  border: ${eventcard.border};
  color: ${eventcard.text.color};
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
    margin-right: 12px;
  }
`;

export const WrapperLink = styled.div`
  margin-top: 8px;
  margin-left: -8px;
  width: max-content;
`;
