import {
  Avatar as WSAvatar,
  SearchBar as WSSearchBar,
  Text as WSText
} from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const Text = styled(WSText)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const Avatar = styled(WSAvatar)`
  width: 40px;
  height: 40px;
`;

export const SearchBar = styled(WSSearchBar)`
  input {
    height: 36px;
  }
`;

export const UserItemContent = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) =>
    disabled &&
    css`
      * {
        color: ${theme.colors.grey[300]};
      }
    `}
`;

export const UserItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  padding: 6px 0px;
`;

export const UsersWrapper = styled.ul`
  padding: 8px 0;
`;

export const ThreadUsersModalWrapper = styled.div`
  padding: 16px 16px 0 16px;
  @media (min-width: 768px) {
    padding: 20px 20px 0 20px;
  }
`;
