import {
  CtaType,
  WarningBannerProps
} from "src/js/components/layout/WarningBanner/types";

export const demoNoInvitationWarningBanner: WarningBannerProps = {
  text: "demo_space_warning_no_invitation_in_demo_space_text",
  cta: {
    type: CtaType.SpaceCreation,
    key: "demo_space_warning_no_invitation_in_demo_space_cta"
  }
};
