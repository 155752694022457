import { Icon } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStores } from "src/js/hooks";
import { createCookie, readCookie } from "src/js/modules/storageFunction";
import { useTranslation } from "src/js/translation";
import ClaimModal from "../layout/ClaimModal/ClaimModal";

import * as S from "./ClaimableSpaceInfo.styles";

/**
 * @name ClaimableSpaceInfo
 *
 * @description ClaimableSpaceInfo component.
 */

const ClaimableSpaceInfo = () => {
  const { translate } = useTranslation();
  const {
    SpaceStore,
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { isClaimable } = SpaceStore;

  const transition = {
    type: "easeOut",
    damping: 100,
    stiffness: 100
  };

  const initVisibleState =
    readCookie("claim_space_info") !== "dismissed" || !isClaimable;

  const [isVisible, setIsVisible] = useState(initVisibleState);

  const openClaimModal = () => {
    openModal(() => <ClaimModal close={closeModal} />);
  };

  const handleClaimableSpaceInfoDismiss = () => {
    createCookie("claim_space_info", "dismissed", 1);
    setIsVisible(false);
  };

  if (readCookie("claim_space_info") === "dismissed" || !isClaimable) {
    return null;
  }

  const textWithCta = (
    <S.TextBody>
      <S.StyledLinkButton onClick={openClaimModal}>
        {translate("claimable_space_info_cta")}
      </S.StyledLinkButton>
      {translate("claimable_space_info_text")}
    </S.TextBody>
  );

  return (
    <AnimatePresence>
      <S.ClaimableSpaceInfoContainer
        initial="closed"
        animate={isVisible ? "open" : "closed"}
        variants={{
          open: { visibility: "visible", opacity: 1 },
          closed: { visibility: "hidden", opacity: 0 }
        }}
        transition={transition}
      >
        <S.TextAndCta>
          {textWithCta}
          <S.IconContainer>
            <Icon
              width={12}
              height={12}
              icon="close"
              onClick={handleClaimableSpaceInfoDismiss}
            />
          </S.IconContainer>
        </S.TextAndCta>
      </S.ClaimableSpaceInfoContainer>
    </AnimatePresence>
  );
};

export default observer(ClaimableSpaceInfo);
