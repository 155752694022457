import moment from "moment";
import React from "react";
import { showToastError } from "src/js/modules/messageManager";
import { CalendarEventModal } from "src/js/pages/calendar/CalendarEventModal";
import { Translate } from "src/js/translation/TranslationProvider";

export const updateTodayLive = ({ groupId, getTodayLive }) => {
  const from = moment().startOf("day").utc().format("YYYY-MM-DDTHH:mm:ssZ");
  const to = moment().endOf("day").utc().format("YYYY-MM-DDTHH:mm:ssZ");
  getTodayLive({
    groupId,
    from,
    to
  }).catch(() => {
    showToastError({ str: <Translate text="calendar_events_loading_error" /> });
  });
};

export const updateNextThreeLive = ({ groupId, getCalendarNextEvents }) => {
  const from = moment().utc().format("YYYY-MM-DDTHH:mm:ssZ");
  getCalendarNextEvents({
    groupId,
    from,
    limit: 3
  }).catch(() => {
    showToastError({ str: <Translate text="calendar_events_loading_error" /> });
  });
};

export const newEvent = ({ defaultDate, type, openModal, closeModal }) => {
  const defaultEnd = new Date(defaultDate);
  defaultEnd.setHours(defaultEnd.getHours() + 1);
  openModal(() => (
    <CalendarEventModal
      creationMode
      hasCollapsableDates={false}
      defaultStartDate={defaultDate}
      defaultEndDate={defaultEnd}
      closeModal={closeModal}
      type={type}
    />
  ));
};

export const editEvent = ({ eventDetail, openModal, closeModal }) => {
  openModal(() => (
    <CalendarEventModal
      creationMode={false}
      defaultEventDetail={eventDetail}
      hasCollapsableDates={false}
      closeModal={closeModal}
      type={eventDetail?.type}
    />
  ));
};

export const copyEvent = ({ eventDetail, openModal, closeModal }) => {
  openModal(() => (
    <CalendarEventModal
      creationMode={false}
      defaultEventDetail={eventDetail}
      hasCollapsableDates={false}
      closeModal={closeModal}
      type={eventDetail?.type}
      copyEvent
    />
  ));
};

export const deleteEvent = ({ calendarEventId, deleteCalendarEvent }) => {
  const redirect_to = window.location.href;
  deleteCalendarEvent({ calendarEventId, redirect_to }).catch(
    // eslint-disable-next-line no-shadow
    ({ location }) => {
      if (location) {
        window.open(location, "_self");
      } else {
        showToastError({
          str: <Translate text="calendar_event_delete_error" />
        });
      }
    }
  );
};
