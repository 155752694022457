import { getDefaultAgent } from "src/js/modules/userAgentFunction";
import {
  __BREAKPOINT_LG_CODE__,
  __BREAKPOINT_LG__,
  __BREAKPOINT_MD_CODE__,
  __BREAKPOINT_MD__,
  __BREAKPOINT_SMARTPHONE__,
  __BREAKPOINT_SM_CODE__,
  __BREAKPOINT_SM__,
  __BREAKPOINT_TABLET__,
  __BREAKPOINT_VERTICAL_SMARTPHONE__,
  __BREAKPOINT_XL_CODE__,
  __BREAKPOINT_XL__,
  __BREAKPOINT_XS_CODE__
} from "src/js/settings/settingsGeneral";

export function showHeader() {
  if (document.getElementById("headerWhite") != null) {
    document
      .getElementById("headerWhite")
      .classList.remove("header__tabs--hidden");
  }
}

export function removeHeader() {
  if (document.getElementById("headerWhite") != null) {
    document
      .getElementById("headerWhite")
      .classList.add("header__tabs--hidden");
  }
}

export function headerWhiteVisibility() {
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function onscroll() {
    if (document.getElementById("headerWhite") != null) {
      const currentScrollPos = window.pageYOffset;
      if (
        prevScrollpos > currentScrollPos ||
        prevScrollpos === currentScrollPos
      ) {
        showHeader();
      } else if (getDefaultAgent() === "ios" && currentScrollPos <= 0) {
        showHeader();
      } else {
        removeHeader();
      }
      prevScrollpos = currentScrollPos;
    }
  };
}

export function getBreakpoint() {
  const w = window.innerWidth;
  let breakpoint = "desktop";

  if (w < __BREAKPOINT_TABLET__) breakpoint = "tablet";
  if (w < __BREAKPOINT_SMARTPHONE__) breakpoint = "smartphone";
  return breakpoint;
}

export function setBreakpoint(breakpoint) {
  document.body.classList.remove("desktop", "tablet", "smartphone");
  document.body.classList.add(breakpoint);
}

export function getInnerBreakpoint() {
  let w;
  const container = document.getElementsByClassName(
    "standard__inner__container"
  );
  if (container.length > 0) {
    w = container[0].offsetWidth;
  } else {
    w = window.innerWidth;
  }
  let breakpoint = "inner-desktop";

  if (w < __BREAKPOINT_TABLET__) breakpoint = "inner-tablet";
  if (w < __BREAKPOINT_SMARTPHONE__) breakpoint = "inner-smartphone";
  return breakpoint;
}

export function setInnerBreakpoint(breakpoint) {
  document.body.classList.remove(
    "inner-desktop",
    "inner-tablet",
    "inner-smartphone"
  );
  document.body.classList.add(breakpoint);
}

export function setRatio(ratio) {
  document.body.classList.remove("portrait", "landscape");
  document.body.classList.add(ratio);
}

export function getRatio() {
  const w = window.innerWidth;
  const h = window.innerHeight;
  let ratio = "landscape";
  if (h >= w) {
    ratio = "portrait";
  }
  return ratio;
}

export function getVerticalBreakpoint() {
  const h = window.innerHeight;
  let breakpoint = "desktop";

  if (h < __BREAKPOINT_VERTICAL_SMARTPHONE__) breakpoint = "smartphone";
  return breakpoint;
}

export function setLayoutClass() {
  setBreakpoint(getBreakpoint());
  setInnerBreakpoint(getInnerBreakpoint());
  setRatio(getRatio());
}

export function infiniteScrollFunction(callback) {
  if (
    window.innerHeight + document.documentElement.scrollTop ===
    document.documentElement.offsetHeight
  ) {
    callback();
  }
}

export function setBodyClass(className) {
  document.body.classList.add(className);
}

export function setTouchClass() {
  if ("ontouchstart" in window) {
    document.body.classList.add("touch-device");
  }
}

export function initLayout() {
  if (window.screen.availWidth < 768) {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function onscroll() {
      const currentScrollPos = window.pageYOffset;
      headerWhiteVisibility(currentScrollPos, prevScrollpos);
      prevScrollpos = currentScrollPos;
    };
  }

  setTouchClass(); // set touch class
  setLayoutClass();
  window.addEventListener("resize", setLayoutClass);
}

export function mergeThemes(mainTheme = {}, customizationTheme = {}) {
  return {
    ...mainTheme,
    colors: { ...mainTheme.colors, ...customizationTheme.colors }
  };
}

export function getBreakpointCode() {
  const w = window.innerWidth;

  switch (true) {
    case w >= __BREAKPOINT_XL__:
      return __BREAKPOINT_XL_CODE__;
    case w >= __BREAKPOINT_LG__:
      return __BREAKPOINT_LG_CODE__;
    case w >= __BREAKPOINT_MD__:
      return __BREAKPOINT_MD_CODE__;
    case w >= __BREAKPOINT_SM__:
      return __BREAKPOINT_SM_CODE__;
    default:
      return __BREAKPOINT_XS_CODE__;
  }
}
