import styled from "styled-components";
import { Text } from "@arcadia/design-system";

export const Wrapper = styled.div``;

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[50]};
  padding: 24px 16px 8px 16px;
`;

export const AITransformation = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.violet[800]};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  min-height: 64px;
`;

export const IconWrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.violet[501]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  flex: 1;
`;

const TransformationInfo = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TransformationName = styled(TransformationInfo)`
  color: ${({ theme }) => theme.colors.grey[200]};
`;

export const TransformationDescription = styled(TransformationInfo)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const TrailingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  color: ${({ theme }) => theme.colors.violet[501]};
  margin-left: auto;
`;
