import { useLocation } from "react-router";
import { getUrlParameterValue } from "src/js/modules/commonFunction";
import { SearchUrlParameters } from "src/js/types";

const getGroupedUrlParameterValue = (query: string, key: string): string[] => {
  const value = getUrlParameterValue(query, key);
  return value ? value.split(",") : undefined;
};

const getSearchUrlParameters = (query: string): SearchUrlParameters => {
  return {
    keyword: getUrlParameterValue(query, "q") || undefined,
    wall: getUrlParameterValue(query, "w"),
    module: getUrlParameterValue(query, "m"),
    exercise: getUrlParameterValue(query, "e"),
    groupFilter: getGroupedUrlParameterValue(query, "g") || undefined,
    userFilter: getGroupedUrlParameterValue(query, "u") || undefined,
    spaceFilter: getGroupedUrlParameterValue(query, "s") || undefined
  };
};

const useSearchParameters = () => {
  const { search } = useLocation();

  const searchParams = getSearchUrlParameters(search);

  return {
    searchParams
  };
};

export default useSearchParameters;
