import React, { useState } from "react";
import * as S from "src/js/components/exercises/CollapsableThread/CollapsableThread.styles";
import { Icon } from "@arcadia/design-system";

const CollapsableThread = ({
  children,
  clickFunction,
  defaultCollapsableOpen = false
}) => {
  const [collapsableOpen, setCollapsableOpen] = useState(
    defaultCollapsableOpen
  );

  const toggleCollapse = () => {
    setCollapsableOpen(!collapsableOpen);
  };

  return (
    <S.CollapsableContainer isOpen={collapsableOpen} onClick={clickFunction}>
      <S.CollapsableControl
        position={collapsableOpen ? "bottom" : "top"}
        onClick={toggleCollapse}
      >
        <Icon icon="chevronDown" height={15} width={15} />
      </S.CollapsableControl>
      {collapsableOpen ? children : ""}
    </S.CollapsableContainer>
  );
};

export default CollapsableThread;
