import styled, { css } from "styled-components";
import { ROUND_ICON_TYPE } from "src/js/components/global/RoundImage/RoundImage.const";

export const RoundImageLayout = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
`;

export const RoundImageContainer = styled.div`
  ${({ placeholder }) =>
    placeholder &&
    css`
      background-image: url(${placeholder});
      background-position: 50% 40%;
      background-repeat: no-repeat;
      background-size: auto;
    `}
  ${({ type }) =>
    type === ROUND_ICON_TYPE.SPACE_LIST_LOGO &&
    css`
      height: 80px;
      width: 80px;
      border-radius: 40px;
      overflow: hidden;
      ${RoundImageLayout} {
        object-fit: cover;
      }
      background-color: ${({ theme }) => theme.colors.accent1};
    `}
  ${({ type }) =>
    type === ROUND_ICON_TYPE.UNLOGGED_UNIVERSITY_LIST_LOGO &&
    css`
      height: 60px;
      width: 60px;
      border-radius: 30px;
      ${RoundImageLayout} {
        background-color: ${({ theme }) => theme.colors.basicWhite};
        object-fit: contain;
      }
      @media screen and (max-width: 768px) {
        height: 40px;
        width: 40px;
      }
    `}
  ${({ isSelectable }) =>
    isSelectable &&
    css`
      cursor: pointer;
    `}
`;
