/* eslint-disable no-restricted-globals */
import {
  ActionButton,
  Button,
  DropdownMultiple,
  Input as InputTextArea,
  ModalSizeType,
  Text,
  VariantType,
  colors
} from "@arcadia/design-system";
import { addDays, addHours, isAfter, isSameDay } from "date-fns";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { EventAttachmentItem } from "src/js/components/EventAttachmentItem";
import {
  DROPDOWN_POSITION,
  DROPDOWN_VARIANT,
  VendorDropdown
} from "src/js/components/forms/VendorDropdown";
import {
  INPUT_VARIANT,
  Input,
  LABEL_POSITIONS
} from "src/js/components/forms/input";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { ModalScrollableBody } from "src/js/components/modal/ModalScrollableBody";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import {
  EventAttachmentMode,
  EventCategory
} from "src/js/pages/calendar/Calendar.types";
import { CalendarAttachmentPicker } from "src/js/pages/calendar/CalendarAttachmentPicker";
import { __INTERNAL_RESOURCES__ } from "src/js/pages/calendar/CalendarAttachmentPicker/CalendarAttachmentPicker.const";
import { CalendarGroupSelector } from "src/js/pages/calendar/CalendarGroupSelector";
import { __FORM_CALENDAR_EVENT__ } from "src/js/settings/settingsFormValidation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import CalendarEventModalDates from "./CalendarEventModalDates";
import {
  getDatesWithTime,
  getModalHeaderTitle
} from "./CalendarEventModalUtils";

import { addHoursToTime, formatDateToUTCISOString } from "../CalendarUtils";
import { CalendarEventMode } from "./Calendar.types";
import CalendarEventContentSwitcher from "./CalendarEventContentSwitcher";
import CalendarEventModalTabs from "./CalendarEventModalTabs";
import * as S from "./CalendarEventModalView.styles";

const CalendarEventModalView = ({
  hidden = false,
  closeModal = () => {},
  submitFunction = () => {},
  currentGroup = {},
  groupsList = [],
  hasNextGroups = false,
  showNextGroups = () => {},
  usersList = [],
  hasNextUsers = false,
  showNextUsers = () => {},
  creationMode = true,
  type = EventCategory.Live,
  fromHomepage = false,
  hasCollapsableDates = false,
  vendors = [],
  accounts = [],
  lastAccountUsed = {},
  resourceAttachments = [],
  removeResourceAttachment = () => {},
  openResource = () => {},
  selectResource = () => {},
  defaultStartDate = new Date(),
  defaultEndDate = new Date(),
  defaultEventDetail,
  showTabs = true,
  resourceView,
  isLoading = false,
  copyEvent = false // workaround to allow copy of an event without touch all the files, delete when refactored
}) => {
  const [filterUserString, setFilterUserString] = useState("");
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  let defaultSelectedGroups = [];
  if (!creationMode && defaultEventDetail) {
    if (defaultEventDetail?.group_recipients?.length > 1) {
      defaultSelectedGroups = defaultEventDetail?.group_recipients
        .filter(group => group.id !== currentGroup?.key)
        .map(group => group.id);
    }
  }

  let defaultGroupsList = groupsList.filter(
    group => group.key !== currentGroup?.key
  );
  if (!creationMode && defaultEventDetail) {
    if (defaultEventDetail?.group_recipients?.length > 1) {
      const filteredDefaultGroupsList = defaultGroupsList.filter(
        group => !defaultSelectedGroups.includes(group.key)
      );
      const selectedGroupsToBeAdded = defaultEventDetail?.group_recipients
        .filter(group => group.id !== currentGroup?.key)
        .map(({ id, name }) => {
          return {
            key: id,
            value: name
          };
        });
      defaultGroupsList = [
        ...selectedGroupsToBeAdded,
        ...filteredDefaultGroupsList
      ];
    }
  }

  let defaultSelectedUsers = [];
  if (!creationMode && defaultEventDetail) {
    if (defaultEventDetail?.user_recipients?.length > 0) {
      defaultSelectedUsers = defaultEventDetail?.user_recipients.map(
        user => user.id
      );
    }
  }

  const dateCleanTimeZoneOffset = date => moment.utc(date).toDate();

  const originalTitle = defaultEventDetail?.title;
  const defaultTitle = copyEvent
    ? translate("calender_event_copy_text_prefix", {
        title: defaultEventDetail?.title
      })
    : defaultEventDetail?.title;

  const defaultObject = {
    eventTitle: defaultTitle,
    description: defaultEventDetail?.description,
    allDay: defaultEventDetail?.all_day,
    groupsList: defaultGroupsList,
    selectedGroups: defaultSelectedGroups,
    selectedUsers: defaultSelectedUsers,
    defaultStartDate: creationMode
      ? defaultStartDate
      : dateCleanTimeZoneOffset(defaultEventDetail?.start),
    defaultEndDate: creationMode
      ? defaultEndDate
      : dateCleanTimeZoneOffset(defaultEventDetail?.end),
    lastAccountUsed:
      creationMode || copyEvent
        ? lastAccountUsed
        : { live_vendor: defaultEventDetail?.live_room?.vendor }
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultObject
  });
  const [selectedGroups, setSelectedGroups] = useState(
    defaultObject.selectedGroups
  );
  const [selectedUsers, setSelectedUsers] = useState(
    defaultObject.selectedUsers
  );
  const [selectedVendor, setSelectedVendor] = useState(
    defaultObject.lastAccountUsed
  );
  const [vendorHasError, setVendorHasError] = useState(false);
  const [startTime, setStartTime] = useState(
    moment(defaultObject.defaultStartDate)?.format("HH:mm")
  );
  const [startDate, setStartDate] = useState(defaultObject.defaultStartDate);
  const [endTime, setEndTime] = useState(
    moment(defaultObject.defaultEndDate)?.format("HH:mm")
  );
  const [recurrence, setRecurrence] = useState(null);
  const [endDate, setEndDate] = useState(defaultObject.defaultEndDate);
  const [showDates, setShowDates] = useState(!hasCollapsableDates);
  const [datesHaveError, setDatesHaveError] = useState(false);
  const [eventType, setEventType] = useState(type);
  const mobile = getBreakpoint() === "smartphone";

  const handleSubmission = handleSubmit(formData => {
    if (datesHaveError) return;
    const { startDateWithTime, endDateWithTime } = getDatesWithTime({
      startDate,
      endDate,
      startTime: formData.allDay ? "00:01" : startTime,
      endTime: formData.allDay ? "23:59" : endTime
    });

    let liveVendorAccountId = null;
    let liveVendorId = null;

    if (selectedVendor?.account_name) {
      liveVendorAccountId = selectedVendor.id;
      liveVendorId = selectedVendor.live_vendor;
    } else {
      liveVendorId = selectedVendor.id;
    }

    if (creationMode && !liveVendorId && eventType === EventCategory.Live) {
      setVendorHasError(true);
      return;
    }

    const resource_attachments = [];
    const board_attachments = [];
    const exercise_attachments = [];
    resourceAttachments.forEach(e => {
      switch (e.type) {
        case "BOARD":
          board_attachments.push(e.id);
          break;
        case "EXERCISE":
          exercise_attachments.push(e.id);
          break;
        default:
          resource_attachments.push(e.id);
      }
    });

    const recursionTimes = recurrence ? 8 : null; // event recursion hardcoded by product requirement

    const dataBody = {
      id: defaultEventDetail?.id,
      title: formData.eventTitle,
      type: eventType,
      description: formData.description,
      group_recipients: [currentGroup?.key, ...selectedGroups],
      user_recipients: selectedUsers,
      live_vendor_account_id: liveVendorAccountId,
      live_vendor_id: liveVendorId,
      start: formatDateToUTCISOString({ date: startDateWithTime }),
      end: formatDateToUTCISOString({ date: endDateWithTime }),
      all_day: Boolean(formData.allDay),
      resource_attachments,
      board_attachments,
      exercise_attachments,
      live_url: formData.fallbackVendor,
      recursion_mode: recurrence,
      recursion_times: recursionTimes
    };
    submitFunction(dataBody);
  });

  const vendorDropdownMemo = useCallback(() => {
    return (
      <VendorDropdown
        id="calendar-modal-event-vendor-dropdown"
        name="vendorDropdown"
        disabled={!creationMode && !copyEvent}
        last_account_used={selectedVendor}
        accounts={accounts}
        vendors={vendors}
        onChangeFunction={value => {
          setSelectedVendor(value);
          setVendorHasError(false);
        }}
        hasError={vendorHasError}
        errorMessage={
          vendorHasError && "calendar_modal_event_vendor_validation_error"
        }
        variant={DROPDOWN_VARIANT.DARK}
        menuPosition={DROPDOWN_POSITION.UP}
        smallMenu={hasCollapsableDates}
      />
    );
  }, [vendorHasError]);

  let typedMode;
  if (copyEvent) {
    typedMode = CalendarEventMode.Copy;
  } else if (creationMode) {
    typedMode = CalendarEventMode.Create;
  } else {
    typedMode = CalendarEventMode.Edit;
  }

  const modalHeaderTitle = translate({
    text: getModalHeaderTitle({ mode: typedMode, eventType }),
    stringVariables: { eventName: originalTitle }
  });

  const setControlledDate = newStartDate => {
    setStartDate(newStartDate);
    if (isAfter(newStartDate, endDate)) {
      setEndDate(newStartDate);
    }
  };

  const setControlledTime = newStartTime => {
    setStartTime(newStartTime);
    const { startDateWithTime, endDateWithTime } = getDatesWithTime({
      startDate,
      endDate,
      startTime: newStartTime,
      endTime
    });
    if (isAfter(startDateWithTime, endDateWithTime)) {
      setEndTime(addHoursToTime({ time: newStartTime, extraHours: 1 }));
      if (!isSameDay(startDateWithTime, addHours(startDateWithTime, 1))) {
        setEndDate(addDays(endDate, 1));
      }
    }
  };

  const modalBody = (
    <>
      <CalendarEventModalTabs
        showTabs={showTabs}
        selectedType={eventType}
        setEventType={setEventType}
      />
      <S.ModalContentContainer showTabs={showTabs}>
        <CalendarEventContentSwitcher
          showTabs={showTabs}
          selectedType={eventType}
          setEventType={setEventType}
        />
        <Input
          id="event-title"
          name="eventTitle"
          placeholder="calendar_modal_event_title_placeholder"
          ref={register(__FORM_CALENDAR_EVENT__.eventTitle)}
          hasError={errors.eventTitle}
          errorMessage={errors.eventTitle?.message}
          variant={INPUT_VARIANT.DEFAULT}
          titleMode
          marginBottom={30}
          marginTop={20}
        />
        <CalendarGroupSelector
          currentGroup={currentGroup}
          selectedGroups={selectedGroups}
          setGroups={setSelectedGroups}
          groupsList={defaultObject.groupsList}
          hasNextGroups={hasNextGroups}
          showNextGroups={showNextGroups}
          disabled={selectedUsers && selectedUsers.length > 0}
          showDropdown={eventType === EventCategory.Live}
        />
        <S.FlexRow flexCenter color={colors.grey[300]}>
          <Text type="formSubtitle">
            {translate("calendar_event_modal_users")}
          </Text>
          <S.UserFilterWrapper>
            <DropdownMultiple
              theme={whiteTheme}
              hideButtonTags
              optionsList={usersList
                .filter(({ value }) =>
                  value.toLowerCase().includes(filterUserString.toLowerCase())
                )
                .map(({ key, value }) => ({
                  id: key,
                  label: value
                }))}
              selectedOptionIds={selectedUsers}
              disabled={selectedGroups && selectedGroups.length > 0}
              setSelectedOptionIds={value => {
                setSelectedUsers(value);
              }}
              placeholder={
                // eslint-disable-next-line no-nested-ternary
                selectedUsers.length === 0
                  ? translate("calendar_event_modal_users_placeholder")
                  : `${
                      usersList.find(({ key }) => key === selectedUsers[0])
                        ?.value
                    }  ${
                      selectedUsers.length > 1
                        ? `+ ${selectedUsers.length - 1}`
                        : ""
                    }`
              }
              searchPlaceholder={translate("user_search_placeholder")}
              scrollableListId="space-group-filter-dropdown-multiple"
              searchable
              searchQuery={filterUserString}
              onSearchFunction={e => {
                setFilterUserString(e.target.value);
              }}
              hasNext={hasNextUsers}
              showNext={showNextUsers}
            />
          </S.UserFilterWrapper>
          {hasCollapsableDates && eventType === EventCategory.Live && (
            <ActionButton
              theme={whiteTheme}
              onClick={() => setShowDates(!showDates)}
              variant="secondary"
              icon="calendar"
            />
          )}
        </S.FlexRow>
        <CalendarEventModalDates
          showDates={showDates}
          startDate={startDate}
          setStartDate={setControlledDate}
          startTime={startTime}
          setStartTime={setControlledTime}
          endDate={endDate}
          setEndDate={setEndDate}
          endTime={endTime}
          setEndTime={setEndTime}
          datesHaveError={datesHaveError}
          setDatesHaveError={setDatesHaveError}
          recurrence={recurrence}
          setRecurrence={setRecurrence}
          eventType={eventType}
          register={register}
          allDay={defaultObject.allDay}
          editMode={typedMode === CalendarEventMode.Edit}
        />
        {eventType === EventCategory.Live && (
          <S.VendorContainer>
            <Text type="formSubtitle">
              {translate("calendar_event_modal_vendor_dropdown")}
            </Text>
            {vendorDropdownMemo()}
          </S.VendorContainer>
        )}
        {selectedVendor && selectedVendor.type === "FALLBACK" && (
          <>
            <Input
              id="fallback-vendor"
              name="fallbackVendor"
              label={`virtual_class_input_label_${selectedVendor.id}`}
              labelDirection={LABEL_POSITIONS.FROM_TOP}
              ref={register(__FORM_CALENDAR_EVENT__.fallbackVendor)}
              hasError={errors.fallbackVendor}
              errorMessage={errors.fallbackVendor?.message}
              variant={INPUT_VARIANT.DARK}
              marginTop={10}
            />
            <S.VendorFallbackDisclaimer>
              {translate(`virtual_class_input_info_${selectedVendor.id}`)}
            </S.VendorFallbackDisclaimer>
          </>
        )}
        <InputTextArea
          theme={whiteTheme}
          label={translate("calendar_event_modal_description")}
          name="description"
          type="textarea"
          ref={register()}
          maxWidth="100%"
          maxLength={2000}
          rows={3}
        />
        {resourceAttachments && resourceAttachments.length > 0 && (
          <S.EventAttachments>
            {resourceAttachments.map(({ id, type: resourceType, name }) => {
              return (
                <EventAttachmentItem
                  key={id}
                  mode={EventAttachmentMode.Edit}
                  id={id}
                  type={resourceType}
                  name={name}
                  openResource={openResource}
                  deleteResource={removeResourceAttachment}
                />
              );
            })}
          </S.EventAttachments>
        )}
        <S.AttachmentPickerMobile>
          <CalendarAttachmentPicker
            selectFunction={selectResource}
            internalResources={
              eventType === EventCategory.Activity ? __INTERNAL_RESOURCES__ : []
            }
          />
        </S.AttachmentPickerMobile>
      </S.ModalContentContainer>
      <S.ModalFooterContainer>
        <S.ButtonContainer>
          <S.AttachmentPickerDesktop>
            <CalendarAttachmentPicker
              selectFunction={selectResource}
              internalResources={
                eventType === EventCategory.Activity
                  ? __INTERNAL_RESOURCES__
                  : []
              }
            />
          </S.AttachmentPickerDesktop>
          <Button
            type="submit"
            variant="primary"
            theme={whiteTheme}
            fullWidth={mobile}
            disabled={isLoading}
          >
            {translate(
              creationMode
                ? "calendar_event_modal_action_create"
                : "calendar_event_modal_action_edit"
            )}
          </Button>
        </S.ButtonContainer>
      </S.ModalFooterContainer>
    </>
  );

  if (fromHomepage) {
    return (
      <S.DisplayWrapper hidden={hidden}>
        <ModalLayout
          labels={{ title: translate(modalHeaderTitle) }}
          desktopProps={{ onClose: closeModal }}
        >
          <form onSubmit={formData => handleSubmission(formData)}>
            <ModalScrollableBody maxHeight="630" maxHeightOffset="205">
              {modalBody}
              {resourceView}
            </ModalScrollableBody>
          </form>
        </ModalLayout>
      </S.DisplayWrapper>
    );
  }

  return (
    <S.DisplayWrapper hidden={hidden}>
      <S.StyledModalLayout
        showTabs={showTabs}
        labels={{
          title: translate(modalHeaderTitle),
          mobileClose: translate("close")
        }}
        withHeaderLabels
        closeModal={closeModal}
        mobileProps={{
          variant: VariantType.White
        }}
        desktopProps={{
          size: ModalSizeType.Big
        }}
      >
        <form onSubmit={formData => handleSubmission(formData)}>
          {modalBody}
          {resourceView}
        </form>
      </S.StyledModalLayout>
    </S.DisplayWrapper>
  );
};

export default observer(CalendarEventModalView);
