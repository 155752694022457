import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ContextualError.styles";
import { ContextualErrorProps } from "./types";

// TODO: this and the two other contextual components could've been unified into a single one
const ContextualError: FC<ContextualErrorProps> = ({
  theme,
  text,
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ContextualErrorContainer {...props}>
        <S.ContextualErrorIcon>
          <Icon icon="dangerTriangle" width={16} height={16} />
        </S.ContextualErrorIcon>
        <S.ContextualErrorMessage>
          <Text type="cta">{text}</Text>
        </S.ContextualErrorMessage>
      </S.ContextualErrorContainer>
    </ThemeProvider>
  );
};

export default ContextualError;
