import { DoodleLoader } from "@arcadia/design-system";
import React from "react";
import { OldResource } from "src/js/types";
import { useTheme } from "styled-components";

// TODO you can no longer upload type=== VIMEO in the platform, everything is treated as link
const Vimeo = ({
  resource: { vimeo_id, id, name }
}: {
  resource: OldResource & { vimeo_id: string };
}) => {
  const { whiteTheme } = useTheme();
  let embedVimeo = (
    <div className="resource-loading text-center text-info">
      <DoodleLoader theme={whiteTheme} isMini />
    </div>
  );
  if (vimeo_id) {
    const addStyle = {
      border: "0",
      maxHeight: "100%",
      height: "100%"
    };
    embedVimeo = (
      <iframe
        className="resource__iframe"
        src={`https://player.vimeo.com/video/${vimeo_id}`}
        width="100%"
        title={`${name}`}
        style={addStyle}
        frameBorder="0"
        // @ts-expect-error - Legacy stuff
        webkitAllowFullScreen
        mozAllowFullScreen
        allowFullScreen
      />
    );
  }
  return (
    <div className="full-height" key={`res${id}`}>
      {embedVimeo}
    </div>
  );
};

export default Vimeo;
