import styled from "styled-components";

export const ModalTabsContainer = styled.div`
  position: absolute;
  width: 100%;
  min-height: 30px;
  padding: 0 30px;
  top: 59px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[800]};
  background: ${({ theme }) => theme.colors.grey[1000]};
  z-index: 1;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;
