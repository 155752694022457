import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import map from "lodash/map";
import { __ } from "../../../modules/localization";
import ResourceModel from "../../../models/resourceModel";
import { __GOOGLE_API_KEY__ } from "src/js/settings/settingsGeneral";
import { DoodleLoader } from "@arcadia/design-system";
import { Button } from "@arcadia/design-system";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { withTheme } from "styled-components";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";

let ResourceMaps = withTheme(
  withBackbone(
    createReactClass({
      getInitialState() {
        this.props.setWorking(false);
        return {
          loading: true,
          name: undefined,
          map: {}
        };
      },
      componentDidMount() {
        let self = this;
        let searchInput = this.searchInput;
        let $searchInput = $(searchInput);
        let container = this.container;
        let $container = $(container);
        this.setState(
          {
            $searchInput,
            $container
          },
          function () {
            window.globalGoogleCallback = this.onClientLoadCallback.bind(this);

            let idscript = "google_maps";
            if (document.getElementById(idscript) === null) {
              let script = document.createElement("script");
              script.id = idscript;
              script.type = "text/javascript";
              script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&callback=globalGoogleCallback&key=${__GOOGLE_API_KEY__}`;
              document.body.appendChild(script);
            } else {
              window.globalGoogleCallback();
            }
          }
        );
      },
      onClientLoadCallback() {
        this.setState({ loading: false });
        let self = this;

        let map = new google.maps.Map(self.state.$container.get(0));
        window.map = map;

        self.setState({ map });

        // Create the search box and link it to the UI element.
        let input = this.state.$searchInput.get(0);
        if (getBreakpoint() !== "smartphone") {
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
        }

        let searchBox = new google.maps.places.SearchBox(
          /** @type {HTMLInputElement} */ (input)
        );

        // default positon and zoom
        map.setCenter({ lat: 46, lng: 9 });
        map.setZoom(4);

        $("#modal-page").on("shown.bs.modal", e => {
          self.resizeMap();
        });

        google.maps.event.addListener(searchBox, "places_changed", () => {
          self.setState({ name: self.state.$searchInput.val() });

          let places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }
          /*
      for (var i = 0, marker; marker = markers[i]; i++) {
        marker.setMap(null);
      }
      */

          // For each place, get the icon, place name, and location.
          let markers = [];
          let bounds = new google.maps.LatLngBounds();
          for (let i = 0, place; (place = places[i]); i++) {
            let image = {
              url: place.icon,
              size: new google.maps.Size(71, 71),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25)
            };

            // Create a marker for each place.
            let marker = new google.maps.Marker({
              map,
              icon: image,
              title: place.name,
              position: place.geometry.location
            });

            markers.push(marker);

            bounds.extend(place.geometry.location);

            google.maps.event.addListener(marker, "click", () => {
              map.setCenter(marker.getPosition());
              self.setState({ name: marker.title });
            });
          }
          map.fitBounds(bounds);
          map.setZoom(14);
        });
      },
      componentWillUnmount() {
        let self = this;
        $("#modal-page").off("shown.bs.modal", e => {
          self.resizeMap();
        });
      },
      resizeMap() {
        google.maps.event.trigger(map, "resize"); // richiamata in automatico per compatibilità quando mappa è in modal bootstrap
        map.setCenter({ lat: 46, lng: 9 });
        map.setZoom(4);
      },
      addResource() {
        let self = this;
        this.props.setWorking(true);
        this.setState({ loading: true });
        let coords = this.state.map.getCenter();
        let resource = new ResourceModel({
          name: this.state.name || [coords.lat(), coords.lng()].join(", "),
          maps_latitude: coords.lat(),
          maps_longitude: coords.lng(),
          maps_zoom: this.state.map.getZoom(),
          type: "GOOGLE_MAPS"
        });

        resource.save(null, {
          success(model, data) {
            self.props.addFunc(resource.toJSON());
          },
          error(model, data) {
            self.props.setWorking(false);
            self.setState({ loading: false });
            let errorMsg = extractErrorMessage(
              data.responseJSON,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
          }
        });
      },
      render() {
        let content;
        const { greyTheme } = this.props.theme;
        if (this.state.loading) {
          content = <DoodleLoader theme={greyTheme} />;
        } else {
          content = (
            <Button
              onClick={this.addResource}
              variant="primary"
              theme={greyTheme}
            >
              {__("Salva")}
            </Button>
          );
        }

        return (
          <div className="resource-map__box">
            <input
              type="text"
              className="form-control light resource-map__searchbox margin-bottom-15"
              placeholder={__("resource_googlemaps_search_placeholder")}
              ref={searchInput => {
                this.searchInput = searchInput;
              }}
            />
            <div
              className="resource-map__container"
              ref={container => {
                this.container = container;
              }}
            />
            <div className="flex-centered-column">
              <Button
                onClick={this.addResource}
                variant="primary"
                theme={greyTheme}
              >
                {__("aggiungi mappa")}
              </Button>
            </div>
          </div>
        );
      }
    })
  )
);

export default ResourceMaps;
