import { ConfirmationAlert } from "@arcadia/design-system";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col } from "react-awesome-styled-grid";
import { ModulesResourceDraftItemMobile } from "src/js/components/modules/ModuleResourceDraftItem";
import { ModulesResourceItem } from "src/js/components/modules/ModuleResourceItem";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { getResourceImage } from "src/js/modules/resourceFunction";
import createUrl from "src/js/modules/routing";
import { ModulesDraftListProps } from "src/js/pages/modules/ModulesDraftListView/types";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import {
  approveModuleResource,
  rejectModuleResource
} from "src/js/repository/moduleResourcesRepository";
import { useTranslation } from "src/js/translation";
import { OldResource } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import ResourceViewer from "src/legacy/view/resource/ResourceViewer";
import { useTheme } from "styled-components";

const ModulesDraftListView = ({
  draftList,
  updateModule,
  boardIsConstrained,
  moduleId,
  hasMandatoryOrder
}: ModulesDraftListProps) => {
  const {
    GroupStore: { groupId, roles },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog },
    UIStore: { isLayoutModeMobile },
    GroupStore: { activeGroup }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const [resourceView, setResourceView] = useState(null);

  // Navigate to the ModuleDetail because suggestions are hidden
  useEffect(() => {
    if (hasMandatoryOrder) {
      navigateTo(
        createUrl("module_detail", {
          group_id: activeGroup.id,
          module_id: `${moduleId}`
        })
      );
    }
  }, [hasMandatoryOrder]);

  const showResource = ({ resource }: { resource: OldResource }) => {
    setResourceView(
      <ResourceViewer
        isDraft
        boardElementId={resource.id}
        resources={[resource]}
        startingPosition={0}
        disableOption
        onClose={() => setResourceView(null)}
      />
    );
  };

  const approveResource = (elementId: number, position: number) => {
    approveModuleResource({
      position,
      moduleId,
      elementId
    })
      .then(() => {
        _trackEvent("Lessons", "LessonsSuggestionsModeration", "Accept");
        updateModule(elementId);
      })
      .catch(ret => {
        const errorMsg = extractErrorMessage(
          ret,
          translate("Attenzione, si è verificato un errore")
        );
        showToastError({ str: errorMsg });
      });
  };

  const rejectResource = (elementId: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("reject_resource_confirmation_text")}
        declineText={translate("reject_resource_confirmation_cancel_button")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("reject_resource_confirmation_accept_button")}
        onAcceptFunction={() => {
          rejectModuleResource({
            groupId,
            moduleId,
            elementId
          })
            .then(() => {
              _trackEvent("Lessons", "LessonsSuggestionsModeration", "Reject");
              updateModule(elementId);
            })
            .catch(ret => {
              const errorMsg = extractErrorMessage(
                ret,
                translate("Attenzione, si è verificato un errore")
              );
              showToastError({ str: errorMsg });
            });
          closeConfirmDialog();
        }}
      />
    );
  };

  const requestConfirm = (elementId: number, position: number) => {
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("approve_resource_constrainted_board")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          approveResource(elementId, position);
          closeConfirmDialog();
        }}
      />
    );
  };

  const approveFunction = boardIsConstrained ? requestConfirm : approveResource;

  const draftListView = draftList.map((element, index) => {
    const placeholder = getPlaceholder(
      element.resource.attributes || element.resource
    );
    const resourceImage: string = getResourceImage(
      element.resource.attributes || element.resource
    );

    const resourceUser = element.user;

    if (isLayoutModeMobile) {
      return (
        <>
          <Col xs={12} key={`draftedResource${index + 1}`}>
            <ModulesResourceDraftItemMobile
              id={element.id}
              position={element.position}
              title={element.name || element.resource.name || ""}
              author={element.user}
              createdAt={moment(moment.unix(element.created)).format("L")}
              isExercise={element.resource.type === "EXERCISE"}
              resourceImage={resourceImage}
              placeholder={placeholder}
              roles={roles}
              approveFunction={approveFunction}
              rejectFunction={rejectResource}
              onClick={() => {
                showResource({ resource: element.resource });
              }}
            />
          </Col>
          {resourceView}
        </>
      );
    }

    return (
      <>
        <Col xs={12} sm={6} md={4} key={`draftedResource${index + 1}`}>
          <ModulesResourceItem
            key={`boarditem${element.id}`}
            resourceId={element.id}
            moduleId={moduleId}
            title={element.name || element.resource.name || ""}
            author={resourceUser}
            createdAt={format(
              new Date(element?.created ? element.created * 1000 : null),
              "d MMMM yyyy"
            )}
            isCompleted={element.is_completed}
            resourcePoints={element.assigned_score || 0}
            isBoardElementEnabled
            commentsCount={element.comments_count}
            mimeType={element.resource.mime_type}
            resourceImage={resourceImage}
            onClick={() => {
              showResource({ resource: element.resource });
            }}
            resourceType={element.resource.type}
            hasPreviewGenerated={element.resource.is_preview_generated}
            isSuggestedResource
            approveSuggestedResource={approveFunction}
            rejectSuggestedResource={rejectResource}
            resourcePosition={element.position}
          />
        </Col>
        {resourceView}
      </>
    );
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{draftListView}</>;
};
export default ModulesDraftListView;
