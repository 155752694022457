import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Container } from "react-awesome-styled-grid";
import {
  isFeatureFlagEnabled,
  useFeatureFlag
} from "src/js/feature_flag/FeatureFlagProvider";
import { useQuery, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { LocalStorageKey, readLocalData } from "src/js/modules/storageFunction";
import { fetchSpacesGroups } from "src/js/repository/dashboardRepository";
import { FeatureFlagKey } from "src/js/settings/settingsFeatureFlag";
import { DashboardEvent, EventDomain, SpacesGroupType } from "src/js/types";
import styled from "styled-components";
import { AnalyticsDashboard } from "../SpacesAnalytics";
import AnalyticsDashboardBlocked from "../SpacesAnalytics/AnalyticsDashboardBlocked";
import { Objectives } from "./Objectives";
import { AnalyticsInfoModal } from "./components/AnalyticsInfoModal";
import { FilterSelectors } from "./components/FilterSelectors";
import { SwitchDashboard } from "./components/SwitchDashboard";
import { SwitchDashboardMode } from "./components/SwitchDashboard/SwitchDashboard.types";

export const AnalyticsContainer = styled(Container)`
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding: 0 0 120px;
  }
`;

const AnalyticsContent = observer(() => {
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceId },
    ModalStore: { openModal }
  } = useStores();

  const [spaceGroups, setSpaceGroups] = useState<SpacesGroupType[]>();

  useEffect(() => {
    if (!activeSpaceId) return;
    // TODO: improve handling of fetch groups
    fetchSpacesGroups({
      spaceId: activeSpaceId,
      limit: 9999,
      offset: 0
    }).then(({ results }) => {
      setSpaceGroups(results);
    });
  }, [activeSpaceId]);

  useEffect(() => {
    _trackEvent(EventDomain.SpaceDashboardUsage, DashboardEvent.ViewDashboard);
    if (!readLocalData(LocalStorageKey.NewAnalyticsInfoModal)) {
      openModal(() => <AnalyticsInfoModal />);
    }
  }, []);

  return (
    <>
      <FilterSelectors spaceGroups={spaceGroups} />
      <AnalyticsContainer>
        <Objectives spaceGroups={spaceGroups} />
      </AnalyticsContainer>
      {isLayoutModeMobile ? <SwitchDashboard /> : null}
    </>
  );
});

const Analytics = () => {
  const {
    SpacesSettingsStore: { generalSettings },
    SpaceStore: { activeSpaceId },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { queryParams } = useQuery();
  const featureFlagConfig = useFeatureFlag();

  const isDisabled = generalSettings?.planSettings?.enableAnalytics === false;
  const isOld =
    queryParams.old === "true" ||
    readLocalData(LocalStorageKey.SwitchDashboardAnalyticsVersion) ===
      SwitchDashboardMode.Old;
  const isAnalyticsV2Enabled = isFeatureFlagEnabled({
    flagKey: FeatureFlagKey.analyticsV2,
    featureFlagConfig
  });

  if (isDisabled) {
    return <AnalyticsDashboardBlocked spaceId={activeSpaceId} />;
  }

  if (isOld || !isAnalyticsV2Enabled) {
    return (
      <>
        <AnalyticsDashboard />
        {isLayoutModeMobile && isAnalyticsV2Enabled ? (
          <SwitchDashboard />
        ) : null}
      </>
    );
  }

  return <AnalyticsContent />;
};

export default observer(Analytics);
