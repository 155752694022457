import React, { FC } from "react";
import { Resource } from "src/js/types";
import ResourceViewer from "src/legacy/view/resource/ResourceViewer";

const ResourceViewContent: FC<{
  resources: Resource[];
  startingPosition: number;
  onClose: () => void;
}> = ({ resources, startingPosition = 0, onClose }) => {
  return (
    <ResourceViewer
      resources={resources}
      disableOption
      startingPosition={startingPosition}
      onClose={onClose}
    />
  );
};

export default ResourceViewContent;
