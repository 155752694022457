import { useMemo } from "react";
import {
  FieldValues,
  FormContextValues,
  useForm as useRHForm,
  NestDataObject,
  FieldError
} from "react-hook-form";
import { useTranslation, TranslationKeys } from "src/js/translation";
import { UseFormOptions } from "./types";

/**
 * This is a wrapper around `react-hook-form`'s `useForm` hook. It includes an additional option, `translateErroMessages`, which will translate the error messages returned by react-hook-form.
 * Previously if you wanted to translate the error messages, you would have to do it manually in the component (and also cast it to `TranslationKeys`). Now you can just set the `translateErroMessages` option to `true` (the default is `true`) and the error messages will be translated automatically.
 * @param options options object
 * @returns `react-hook-form`'s `useForm` hook result with transformed error messages
 * @example
 * // By using the useForm hook imported from 'react-hook-form'
 * import { useForm } from "react-hook-form";
 * ...
 * ...
 * ...
 * const { errors } = useForm();
 *
 * <Input
 *   ...
 *   feedback={
 *     errors.field
 *       ? {
 *           type: "error",
 *           message: translate(errors.field.message as TranslationKeys)
 *         }
 *       : null
 *   }
 * />
 *
 * // By using this wrapper hook:
 * import { useForm } from "src/js/hooks";
 * ...
 * ...
 * ...
 * const { errors } = useForm();
 *
 * <Input
 *   ...
 *   feedback={
 *     errors.field
 *       ? {
 *           type: "error",
 *           message: errors.field.message
 *         }
 *       : null
 *   }
 * />
 *
 * // Starting from DS version 1.0.0-beta.43, this can be improved further:
 * import { useForm } from "src/js/hooks";
 * ...
 * ...
 * ...
 * const { errors } = useForm();
 *
 * <Input
 *   ...
 *   error={errors.field}
 * />
 */
const useForm = <
  FormValues extends FieldValues = FieldValues,
  ValidationContext = unknown
>(
  options?: UseFormOptions<FormValues, ValidationContext>
): FormContextValues<FormValues> => {
  const { translateErroMessages = true, ...otherOptions } = options || {};
  const { errors, ...otherResultParams } = useRHForm(otherOptions);
  const { translate } = useTranslation();

  const newErrors = useMemo(() => {
    let _errors: NestDataObject<FormValues> = errors;

    if (translateErroMessages) {
      _errors = Object.keys(errors).reduce((acc, key) => {
        const error = errors[key] as FieldError;

        let newMessage: string | undefined;

        if (error.message) {
          newMessage = translate(error.message as TranslationKeys);
        }

        return {
          ...acc,
          [key]: {
            ...error,
            message: newMessage
          }
        };
      }, {} as NestDataObject<FormValues>);
    }

    return _errors;
  }, [errors, translateErroMessages]);

  return {
    ...otherResultParams,
    errors: newErrors
  };
};

export default useForm;
