import { Box, Button, Icon, IconName, Input } from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { generateTempResource } from "src/js/modules/fileFunction";
import { saveResourceNew } from "src/js/repository/resourceRepository";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { FormInputs, RESOURCE_TYPE } from "src/js/types";
import { useTheme } from "styled-components";
import * as S from "./AddLinkResource.styles";
import { AddLinkFields, Props } from "./AddLinkResource.types";
import {
  __ADD_LINK_RESOURCE_SETTINGS__,
  getFaviconFromUrl,
  sanitizeIfVimeoUrl
} from "./AddLinkResource.utils";

const AddLinkResource = ({
  onSubmit,
  onError,
  onSuccess,
  setUploadingProgress,
  onClose,
  elementIndex,
  prepareWSResource
}: Props) => {
  const { whiteTheme, greyTheme } = useTheme();
  const [originalUrl, setOriginalUrl] = useState("");
  const { translate } = useTranslation();
  const { handleResourceLinkUpload } = useResourceUpload(setUploadingProgress);
  const [favIcon, setFavIcon] = useState<IconName>(null);
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceSlug: spaceSlug }
  } = useStores();

  const { register, handleSubmit, setValue, watch, errors } = useForm<
    FormInputs<AddLinkFields>
  >({
    defaultValues: { linkInput: "" }
  });

  const link = watch("linkInput");

  useEffect(() => {
    if (!link) {
      setFavIcon(null);
      return;
    }

    setFavIcon(getFaviconFromUrl({ url: link.toLowerCase().trim() }));
  }, [link]);

  const handleSubmission = handleSubmit(() => {
    setValue([{ linkInput: "" }]);

    const sanitizedUrl = sanitizeIfVimeoUrl({ url: originalUrl });

    const tempResource = generateTempResource({
      type: RESOURCE_TYPE.LINK,
      name: sanitizedUrl
    });
    const resourceUploadHandler = () =>
      handleResourceLinkUpload(sanitizedUrl, elementIndex);

    if (onClose) onClose();

    if (prepareWSResource) {
      prepareWSResource(tempResource, resourceUploadHandler);
      return;
    }

    if (onSubmit) onSubmit(tempResource);
    resourceUploadHandler()
      .then(response => {
        onSuccess(response);
      })
      .catch(error => {
        if (error && error.status === 403) {
          saveResourceNew({
            name: translate("private_resource_title"),
            type: "LINK",
            spaceSlug,
            publicUrls: [sanitizedUrl]
          }).then(response => {
            onSuccess(response);
          });
        } else {
          onError(error);
        }
      });
  });

  return (
    <S.FormWrapper onSubmit={handleSubmission} id="add-new-link-form">
      <Input
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        type="field"
        name="linkInput"
        label="linkInput"
        ref={register(__ADD_LINK_RESOURCE_SETTINGS__.linkInput)}
        error={{
          message: errors?.linkInput
            ? translate(errors.linkInput.message as TranslationKeys)
            : null
        }}
        value={link}
        onChange={event => {
          const url = event.target.value;
          const transformedLink = url.toLowerCase().trim();
          setValue([{ linkInput: transformedLink }]);
          setOriginalUrl(url);
        }}
        rounded
        hideLabel
        placeholder={translate("add_link_resource_input_placeholder")}
        rightSection={
          favIcon ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon icon={favIcon} initialViewbox height={20} width={20} />
            </Box>
          ) : null
        }
      />
      <S.ButtonWrapper>
        {onClose ? (
          <Button
            theme={whiteTheme}
            variant="secondary"
            type="button"
            onClick={onClose}
          >
            {translate("add_link_resource_cancel_cta")}
          </Button>
        ) : null}
        <Button theme={whiteTheme} variant="primary" type="submit">
          {translate("add_link_resource_submit_cta")}
        </Button>
      </S.ButtonWrapper>
    </S.FormWrapper>
  );
};

export default AddLinkResource;
