import styled, { css } from "styled-components";
import shadow from "@ds_themes/shadow";

import { SLiveButtonProps } from "./types";

export const LiveButton = styled.div<SLiveButtonProps>`
  min-width: 96px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 24px;
  box-sizing: border-box;
  gap: 10px;
  background-color: ${({ theme }) => theme.livebutton.background};
  color: ${({ theme }) => theme.livebutton.text};
  cursor: pointer;

  @media screen and (max-width: 767px) {
    ${({ isFloating, theme }) =>
      isFloating &&
      css`
        position: absolute;
        top: 70px;
        right: 15px;
        z-index: ${theme.zIndex.high};
        box-shadow: ${shadow[400]}};
      `}
  }
`;
