import styled from "styled-components";
import { motion } from "framer-motion";

export const TabWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Tab = styled.button<{
  isLight?: boolean;
  isLayoutModeMobile?: boolean;
  active?: boolean;
}>`
  position: relative;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  color: ${({ theme, isLight }) => isLight && theme.colors.grey[600]};
  color: ${({ theme, isLayoutModeMobile }) =>
    isLayoutModeMobile && theme.colors.headerTextSecondaryColor};
  color: ${({ theme, active, isLayoutModeMobile }) =>
    active && isLayoutModeMobile && theme.colors.headerTextColor};
`;

export const Underline = styled(motion.div)<{ isLayoutModeMobile?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  height: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: ${({ theme, isLayoutModeMobile }) =>
    isLayoutModeMobile ? theme.colors.yellow[500] : theme.primaryColor[500]};
`;
