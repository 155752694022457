import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import each from "lodash/each";
import find from "lodash/find";
import reject from "lodash/reject";
import map from "lodash/map";
import { showToastError } from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import { __QUIZ_MAX_POINTS__ } from "src/js/settings/settingsExercise";
import { Box, Button, Text } from "@arcadia/design-system";
import styled, { withTheme } from "styled-components";
import { nl2spaces } from "../../../modules/utility";
import { __ } from "../../../modules/localization";
import QuizLabel from "./QuizLabel";

const ModeWrapper = styled.div`
  flex: 1;
  text-align: center;
  border-bottom: 2px solid
    ${({ active, theme }) => (active ? theme.primaryColor[500] : "transparent")};
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > span {
    font-size: 14px;
  }
`;

const QuizBuilderFillBlanks = withBackbone(
  createReactClass({
    form: "#form-quiz-builder-fillblanks",
    getInitialState() {
      const { quiz } = this.props;
      const solutions = quiz.getSolutions();
      const questions = quiz.getQuestions();
      const splitText = [];
      each(questions, q => {
        if (q.value === "") {
          const relatedSolution = find(solutions, { index: q.index });
          splitText.push(relatedSolution);
          // prendo valore in solutions
        } else {
          splitText.push(q);
        }
      });

      const text = map(splitText, "value").join(" ");

      let editing = false;
      if (splitText.length == 0) {
        editing = true;
      }
      return {
        status: "",
        title: quiz.get("title"),
        available_points: quiz.getAvailablePoints(),
        words: [],
        text,
        solutions,
        editing
      };
    },
    componentDidMount() {
      $(this.form).on("submit", e => {
        e.preventDefault();
      });

      this.initValidation();
    },
    componentDidUpdate() {
      this.initValidation();
      if (typeof this.props.setChanged === "function")
        this.props.setChanged(true);
    },
    // regex: /(?=[\.,-\/#!$%\^&\*;:{}=\-_`~()\s])/, //splitta per spazio e punteggiatura, ma mantiene i caratteri nell'array splittato
    regex: /[\.,-\/#!?‘«»<>\|£$%\^&§\*;:{}=\-_`~()’''′‵“”\"\[\]\s\\]/g,
    customReplace(match) {
      return ` ${match} `;
    },
    customSplit(text) {
      text = nl2spaces(text);
      let replaced = text.replace(this.regex, this.customReplace);
      replaced = replaced.split(" ");
      replaced = reject(replaced, w => w === "");
      return replaced;
    },
    submitForm() {
      $(this.form).trigger("submit");
    },
    initValidation() {
      const self = this;

      $.validate({
        form: self.form,
        borderColorOnError: "",
        onError() {
          showToastError({
            str: <Translate text="attenzione, verificare i dati inseriti" />
          });
        },
        onSuccess() {
          self.addQuiz();
          return false;
        }
      });
    },
    addQuiz() {
      const self = this;
      this.setState({ status: "loading" });
      const { quiz } = this.props;
      const splitWords = this.customSplit(this.state.text);
      const { solutions } = this.state;
      const questions = [];

      if (solutions.length === 0) {
        showToastError({
          str: <Translate text="inserisci almeno una parola da compilare" />
        });
        this.setState({ status: "" });
      } else if (
        self.state.available_points &&
        (self.state.available_points < 1 ||
          self.state.available_points > __QUIZ_MAX_POINTS__)
      ) {
        showToastError({
          str: <Translate text="quiz_vote_validation_error" />
        });
        this.setState({ status: "" });
      } else {
        each(splitWords, (w, index) => {
          if (w.length > 0) {
            const relatedSolution = find(solutions, { index });
            if (
              relatedSolution !== undefined &&
              relatedSolution.value.trim() === w.trim()
            ) {
              questions.push({
                index,
                value: "",
                length: w.length
              });
            } else {
              questions.push({
                index,
                value: w,
                length: w.length
              });
            }
          }
        });

        quiz.set("type", "fill_blank");
        quiz.set("title", __("quiz_fillblanks_execution_label"));
        if (!self.state.available_points) {
          quiz.set("available_points", 1);
        } else {
          quiz.set(
            "available_points",
            parseInt(self.state.available_points, 10)
          );
        }
        quiz.set("questions", questions);
        quiz.set("solutions", solutions);

        const optionalCallbackSuccess = function () {
          this.setState({ status: "" });
          this.props.backFunc();
        };

        const optionalCallbackError = function () {
          this.setState({ status: "" });
        };
        if (typeof this.props.setChanged === "function")
          this.props.setChanged(false);
        this.props.addFunc(
          quiz,
          optionalCallbackSuccess.bind(this),
          optionalCallbackError.bind(this)
        );
      }
    },
    startEditing() {
      this.setState({ editing: true });
    },
    stopEditing() {
      // rimuovo parole non più usate nel testo
      const newSolutions = [];
      const { solutions } = this.state;
      const splitWords = this.customSplit(this.state.text);

      each(solutions, s => {
        const relatedWord = splitWords[s.index];
        if (relatedWord !== undefined) {
          if (s.value.trim() === relatedWord.trim()) newSolutions.push(s);
        }
      });

      this.setState({
        editing: false,
        solutions: newSolutions
      });
    },
    selectWord(index, word) {
      if (word.length >= 1) {
        const { solutions } = this.state;
        const splitWords = this.customSplit(this.state.text);
        if (solutions.length < splitWords.length - 1) {
          solutions.push({
            index,
            value: word,
            length: word.length
          });
          this.setState({
            solutions
          });
        } else {
          showToastError({
            str: <Translate text="Numero massimo di parole raggiunto" />
          });
        }
      } else {
        showToastError({
          str: <Translate text="Seleziona una parola di almeno 1 lettera" />
        });
      }
    },
    deselectWord(index, word) {
      let { solutions } = this.state;
      solutions = reject(solutions, s => s.value === word && s.index === index);
      this.setState({
        solutions
      });
    },
    render() {
      const self = this;
      const { solutions } = this.state;
      let editingContent = "";
      let saveButton = "";
      let tabs = "";
      let editActive = false;
      const { whiteTheme } = this.props.theme;

      if (this.state.editing) {
        editingContent = (
          <div className="quiz-builder-fillblanks-content">
            <textarea
              className="form-control"
              placeholder={__(
                "Inserisci il testo, poi seleziona le parole da nascondere"
              )}
              defaultValue={this.state.text}
              onChange={e => this.setState({ text: e.target.value })}
              rows="4"
            />
          </div>
        );

        editActive = true;
      } else {
        // var splitWords = this.state.text.split(" ");
        let splitWords = this.customSplit(this.state.text);
        splitWords = reject(splitWords, w => w === " ");
        let textContent = map(splitWords, (w, index) => {
          w = w.trim();
          if (w.length > 0) {
            const relatedWord = find(solutions, { index });
            let relatedWordExtraClass = "";
            const sep = " ";
            let clickFunction = self.selectWord.bind(null, index, w);

            if (relatedWord !== undefined && w === relatedWord.value) {
              relatedWordExtraClass = "selected";
              clickFunction = self.deselectWord.bind(null, index, w);
            }
            return (
              <span className="quiz-builder-fillblanks-text-element">
                <a
                  className={`quiz-builder-fillblanks-text-element-link selectable-word ${relatedWordExtraClass}`}
                  onClick={clickFunction}
                >
                  {w}
                </a>
                {sep}
              </span>
            );
          }
          return "";
        });

        if (textContent == "") {
          textContent = (
            <span className="quiz-builder-fillblanks-text-element">
              {__("Inserisci il testo, poi seleziona le parole da nascondere")}
            </span>
          );
        }

        editingContent = (
          <div className="quiz-builder-fillblanks-content">
            <div className="quiz-builder-fillblanks-content-text-wrapper">
              <div className="quiz-builder-fillblanks-content-text">
                {textContent}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        );

        editActive = false;
      }

      tabs = (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          height="44px"
          textTransform="capitalize"
          border="1px solid #ccc"
          borderBottom="none"
        >
          <ModeWrapper active={editActive} onClick={this.startEditing}>
            <Text type="label">{__("INSERIMENTO TESTO")}</Text>
          </ModeWrapper>
          <ModeWrapper active={!editActive} onClick={this.stopEditing}>
            <Text type="label">{__("SELEZIONE PAROLE")}</Text>
          </ModeWrapper>
        </Box>
      );

      if (self.state.solutions.length > 0) {
        saveButton = (
          <Button type="submit" theme={whiteTheme} variant="primary">
            {__("save")}
          </Button>
        );
      }

      return (
        <div>
          <div className="quiz-builder-fillblanks-wrapper">
            <form
              id="form-quiz-builder-fillblanks"
              className="form-quiz-builder"
              method="post"
            >
              <div>
                {tabs}
                {editingContent}
              </div>
              <div>
                <QuizLabel text="quiz_creation_vote_label" />
                <div className="point-section">
                  <div className="point-input">
                    <input
                      type="number"
                      id="quiz_available_points"
                      className="form-control primary"
                      defaultValue={self.state.available_points}
                      placeholder={1}
                      ref="quiz_available_points"
                      onChange={e =>
                        this.setState({ available_points: e.target.value })
                      }
                    />
                    <div className="point-label">
                      {__("quiz_creation_point_label")}
                    </div>
                  </div>
                  <div className="submit-quiz">{saveButton}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
  })
);

export default withTheme(QuizBuilderFillBlanks);
