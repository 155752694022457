import React from "react";
import { observer } from "mobx-react";
import { Icon, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

import * as S from "./DropdownContent.styles";

import { DropdownContentProps } from "./types";

const DropdownContent = ({
  suggestions,
  onSuggestionClick,
  searchQuery,
  onSubmit,
  setGroupFilter,
  groupFilterId,
  suggestedGroup
}: DropdownContentProps) => {
  const { translate } = useTranslation();

  return (
    <S.Wrapper>
      {searchQuery ? (
        <S.FirstLineSubmitButton onClick={onSubmit}>
          <Icon icon="search" />
          <Text type="captionAlert">{searchQuery}</Text>
        </S.FirstLineSubmitButton>
      ) : null}
      {suggestedGroup && !groupFilterId ? (
        <S.SuggestedGroupOption onClick={() => setGroupFilter(suggestedGroup)}>
          <Icon icon="search" />
          <Text type="captionAlert">
            {translate({
              text: "top_header_searchbar_suggested_group_filter_label",
              stringVariables: { groupName: suggestedGroup.name }
            })}
          </Text>
        </S.SuggestedGroupOption>
      ) : null}
      {suggestions?.length > 0 && (
        <>
          <S.RecentTitle type="label">
            {translate({ text: "top_header_searchbar_dropdown_recent_title" })}
          </S.RecentTitle>

          {suggestions.map((suggestion, i) => {
            return (
              <S.Option
                // eslint-disable-next-line react/no-array-index-key
                key={suggestion + i}
                onClick={() => {
                  onSuggestionClick(suggestion);
                }}
              >
                <Icon icon="clock" />
                {suggestion}
              </S.Option>
            );
          })}
        </>
      )}
    </S.Wrapper>
  );
};

export default observer(DropdownContent);
