import { Box, DoodleLoader, Heading, Icon, Text } from "@arcadia/design-system";
import { format } from "date-fns";
import React from "react";
import { CalendarTag } from "src/js/components/Calendar/CalendarTag";
import { EventAttachmentItem } from "src/js/components/EventAttachmentItem";
import { Pill } from "src/js/components/Pill";
import { RESOURCE_ICON_TYPE } from "src/js/components/ResourceIcon/ResourceIcon.const";
import { useStores } from "src/js/hooks";
import { isEmptyObject } from "src/js/modules/commonFunction";
import {
  EventAttachmentMode,
  EventCategory
} from "src/js/pages/calendar/Calendar.types";
import {
  getDatesUtils,
  getEventStatus
} from "src/js/pages/calendar/CalendarUtils";
import { EventDownload } from "src/js/pages/calendar/EventDownload";
import EventRecipientsList from "src/js/pages/calendar/EventRecipientsList/EventRecipientsList";
import theme from "src/js/theme/theme";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./EventActivityView.styles";

const EventActivityView = ({
  eventDetail = {},
  openResource = () => {},
  openRecipientsModal = () => {}
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    id: eventId,
    title,
    start,
    end,
    description,
    all_day,
    group_recipients = [],
    user_recipients = [],
    resource_attachments = [],
    board_attachments = [],
    exercise_attachments = []
  } = eventDetail || {};

  const { startDate, endDate, dateNow, hasSameTime } = getDatesUtils({
    start,
    end
  });

  const status = getEventStatus({
    dateNow,
    endDate,
    eventType: EventCategory.Activity
  });

  if (isEmptyObject(eventDetail)) return <DoodleLoader theme={greyTheme} />;

  const groupName = group_recipients[0]?.name;

  return (
    <S.EventViewContainer>
      <CalendarTag type={EventCategory.Activity} status={status} />
      <S.TitleContainer>
        <Heading level="1">{title}</Heading>
      </S.TitleContainer>
      <S.DataGrid>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_activity_groups")}</Text>
          </S.DataRowLabel>
          <S.PillContainer>
            <Pill
              id="event-activity-group"
              value={groupName}
              isDeletable={false}
              color={theme.colors.blueGrey[500]}
            />
          </S.PillContainer>
        </S.DataRow>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_detail_recipients")}</Text>
          </S.DataRowLabel>
          <EventRecipientsList
            group_recipients={group_recipients}
            user_recipients={user_recipients}
            onClickItem={() => {
              openRecipientsModal({
                group_recipients,
                user_recipients
              });
            }}
          />
        </S.DataRow>
        {!hasSameTime && (
          <S.DataRow>
            <S.DataRowLabel>
              <Text type="label">{translate("event_activity_start_date")}</Text>
            </S.DataRowLabel>
            <Icon icon="calendar" height={16} width={16} />
            <S.DateContainer>{format(startDate, "dd/MM/yyyy")}</S.DateContainer>
            {!all_day && format(startDate, "HH:mm")}
          </S.DataRow>
        )}
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">
              {translate(
                hasSameTime
                  ? "event_activity_single_date"
                  : "event_activity_end_date"
              )}
            </Text>
          </S.DataRowLabel>
          <Icon icon="calendar" height={16} width={16} />
          <S.DateContainer>{format(endDate, "dd/MM/yyyy")}</S.DateContainer>
          {!all_day && format(endDate, "HH:mm")}
        </S.DataRow>
        {isLayoutModeMobile && (
          <S.DataRow>
            <EventDownload eventId={eventId} />
          </S.DataRow>
        )}
      </S.DataGrid>
      {description && (
        <S.DescriptionRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_activity_description")}</Text>
          </S.DataRowLabel>
          <S.DescriptionContainer>
            <Text type="formDescription">{description}</Text>
          </S.DescriptionContainer>
        </S.DescriptionRow>
      )}
      {!isLayoutModeMobile && (
        <Box marginTop={16}>
          <EventDownload eventId={eventId} />
        </Box>
      )}
      <S.EventAttachments>
        {resource_attachments.map(({ id, type, name }) => {
          return (
            <EventAttachmentItem
              key={id}
              mode={EventAttachmentMode.View}
              id={id}
              type={type}
              name={name}
              openResource={openResource}
            />
          );
        })}
        {board_attachments.map(({ id, name }) => {
          return (
            <EventAttachmentItem
              key={id}
              mode={EventAttachmentMode.View}
              id={id}
              type={RESOURCE_ICON_TYPE.BOARD}
              name={name}
              openResource={openResource}
            />
          );
        })}
        {exercise_attachments.map(({ id, name }) => {
          return (
            <EventAttachmentItem
              key={id}
              mode={EventAttachmentMode.View}
              id={id}
              type={RESOURCE_ICON_TYPE.EXERCISE}
              name={name}
              openResource={openResource}
            />
          );
        })}
      </S.EventAttachments>
    </S.EventViewContainer>
  );
};

export default EventActivityView;
