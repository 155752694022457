import colors from "@ds_themes/colors";

export const pagenav = {
  button: {
    active: {
      color: colors.grey[1000],
      background: colors.violet[500]
    },
    color: colors.grey[50],
    background: colors.grey[1000],
    border: colors.grey[800],
    hover: {
      border: colors.violet[500]
    },
    focus: {
      border: colors.violet[700]
    }
  }
};
