import React from "react";
import * as S from "./CommentTitle.styles";

const CommentTitle = ({ children, testId, ...props }) => {
  return (
    <S.CommentTitleStyle data-testid={testId} {...props}>
      {children}
    </S.CommentTitleStyle>
  );
};

export default CommentTitle;
