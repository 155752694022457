import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { AvatarWithInitials } from "@ds_universal/AvatarWithInitials";
import { Box } from "@ds_universal/Box";
import { Text } from "@ds_universal/Text";

import * as S from "./ToolbarSpaceItem.styles";
import { ToolbarSpaceItemProps } from "./types";

const ToolbarSpaceItem: FC<ToolbarSpaceItemProps> = ({
  id,
  theme,
  isSelected,
  isLoading,
  backgroundColorCode,
  spaceName,
  badge,
  size,
  onClick,
  counter
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ItemContainer id={id} onClick={onClick} theme={theme}>
        <Box position="relative">
          <S.ImageContainer isSelected={isSelected} isLoading={isLoading}>
            {counter && Number(counter) !== 0 ? (
              <S.Counter>
                <Text type="table">{counter}</Text>
              </S.Counter>
            ) : null}
            <AvatarWithInitials
              size={size}
              name={spaceName}
              avatarColor={backgroundColorCode}
              url={badge}
              theme={theme}
              alt={spaceName}
            />
          </S.ImageContainer>
        </Box>
      </S.ItemContainer>
    </ThemeProvider>
  );
};

export default ToolbarSpaceItem;
