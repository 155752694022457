import styled from "styled-components";
import {
  colors,
  SearchBar as WSSearchBar,
  Text,
  DoodleLoader as WSDoodleLoader,
  DoodleLoaderStyles
} from "@arcadia/design-system";

export const SearchBar = styled(WSSearchBar)`
  input {
    background-color: ${colors.grey[1000]};
    height: 32px;
  }

  margin-bottom: 16px;
`;

export const Container = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const SelectedFiltersCountText = styled(Text)`
  color: ${colors.grey[1000]};
`;

export const DoodleLoader = styled(WSDoodleLoader)`
  ${DoodleLoaderStyles.SvgLoader} {
    height: 120px;
    min-height: 120px;
  }
`;
