import {
  Box,
  Button,
  ConfirmationAlert,
  ContentSwitcher,
  Dropdown,
  Heading,
  Label,
  Text
} from "@arcadia/design-system";
import "jquery-ui";
import isUndefined from "lodash/isUndefined";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import useStores from "src/js/hooks/useStores";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import {
  extractErrorMessage,
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import {
  fetchExerciseList,
  patchExerciseInstantAbort
} from "src/js/repository/exerciseRepository";
import {
  Translate,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { ExerciseType } from "src/js/types";
import { useTheme } from "styled-components";
import ExerciseCollection from "../../collections/exercisesCollection";
import { instantList } from "../../collections/instantsCollection";
import InstantModel from "../../models/instantModel";
import { modalMessage } from "../../models/modalMessageModel";
import { getActiveGroup } from "../../modules/activeGroup";
import { InternalDispatcher } from "../../modules/dispatcher";
import { navigateTo } from "../../modules/history";
import { instants } from "../../modules/instant";
import { basePageModel } from "../../modules/pageStatus";
import { createURL } from "../../modules/utility";
import { __EXERCISE_LIST_LIMIT__ } from "../../settings/settings";
import ExerciseCloner from "./exerciseCloner";
import ExercisesDraftList from "./exercisesDraftList";
import ExercisesList from "./exercisesList";

const getUrlByParams = ({ param, group_id }) => {
  switch (param) {
    case "drafts":
      return createUrl("exercises_drafts", { group_id });
    case "assignments":
    default:
      return createUrl("exercises", { group_id });
  }
};

const Exercises = ({ tab }) => {
  const { translate } = useTranslation();
  const {
    GroupStore: { groupName, groupId, userIsTeacher },
    UIStore: { isLayoutModeMobile },
    ConfirmDialogStore
  } = useStores();
  const { whiteTheme, greyTheme } = useTheme();
  const [activeTab, setActiveTab] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [currentInstant, setCurrentInstant] = useState(new InstantModel());
  const [pagination, setPagination] = useState(false);
  const [exercisesList, setExercisesList] = useState(new ExerciseCollection());
  const group = getActiveGroup();

  useEffect(() => {
    let data = {};
    InternalDispatcher.trigger("startLoading");
    basePageModel.set("selectedTab", "esercizi");
    basePageModel.set("pageTitle", group.get("name"));

    if (tab === "drafts") {
      setActiveTab("drafts");
      data = { filter: "not_published", group_id: groupId };
    } else {
      setActiveTab("assignments");
      data = {
        filter: activeFilter,
        group_id: groupId,
        report_by: "best"
      };
    }

    data.limit = __EXERCISE_LIST_LIMIT__[getBreakpoint()] + 1;

    fetchExerciseList(data)
      .then(ret => {
        if (ret.length > __EXERCISE_LIST_LIMIT__[getBreakpoint()]) {
          ret.pop();
          setPagination(true);
        } else {
          setPagination(false);
        }
        setExercisesList(new ExerciseCollection(ret));
        instantList.fetch({
          success(collection) {
            const newCurrentInstant = collection.filterByGroup(groupId).at(0);
            setCurrentInstant(newCurrentInstant);
          }
        });
        InternalDispatcher.trigger("stopLoading");
      })
      .catch(() => {
        InternalDispatcher.trigger("stopLoading");
      });
  }, [tab, group, groupId, activeFilter]);

  useEffect(() => {
    const checkInstant = obj => {
      if (
        !isUndefined(obj) &&
        obj.target.type === "exercise_execution_instant" &&
        !isUndefined(currentInstant) &&
        currentInstant.has("id") &&
        obj.target.id === currentInstant.get("id")
      ) {
        setCurrentInstant(new InstantModel());
      } else if (
        !isUndefined(obj) &&
        obj.target.type === "exercise_execution_instant" &&
        (isUndefined(currentInstant) ||
          (!isUndefined(currentInstant) && !currentInstant.has("id")))
      ) {
        instantList.fetch({
          success(collection) {
            const newCurrentInstant = collection.filterByGroup(groupId).at(0);
            setCurrentInstant(newCurrentInstant);
          }
        });
      }
    };

    InternalDispatcher.on("instant_manual_start", checkInstant);
    InternalDispatcher.on("instant_manual_stop", checkInstant);
    InternalDispatcher.on("instant_automatic_stop", checkInstant);

    return () => {
      InternalDispatcher.off("instant_manual_start", checkInstant);
      InternalDispatcher.off("instant_manual_stop", checkInstant);
      InternalDispatcher.off("instant_automatic_stop", checkInstant);
    };
  }, [currentInstant, groupId]);

  const nextPage = () => {
    const nextTab = tab === "drafts" ? "not_published" : activeFilter;

    const callback = () => {
      setPagination(false);
      setPagination(!exercisesList.noMoreItems);
    };
    exercisesList.nextPage(callback, nextTab);
  };

  const abortInstant = () => {
    ConfirmDialogStore.openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("instant_abort_confirmation_message")}
        declineText={translate("Annulla")}
        onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          ConfirmDialogStore.closeConfirmDialog();
          const exerciseId = currentInstant.get("exercise_id");
          const executionId = currentInstant.get("id");

          patchExerciseInstantAbort({ exerciseId, executionId })
            .then(() => {
              setCurrentInstant(new InstantModel());
              showToastSuccess({
                str: translate("instant_abort_success_message")
              });
            })
            .catch(data => {
              const errorMsg = extractErrorMessage(
                data,
                translate("Attenzione, si è verificato un errore")
              );
              showToastError({ str: errorMsg });
            });
        }}
      />
    );
  };

  const resumeInstant = () => {
    const instant_id = currentInstant.get("id");

    if (userIsTeacher) {
      const reportURL = createURL("exercises_instants_live", {
        group_id: groupId,
        exercise_id: currentInstant.get("exercise_id"),
        execution_id: instant_id
      });
      instants.subscribeInstant(groupId, groupName, instant_id);
      navigateTo(reportURL, { trigger: true });
    }
  };

  const showQuizCloner = () => {
    modalMessage.reset();
    modalMessage.set({
      header: {
        content: `<Text type="subHeaderTitle">${translate(
          "Importa esercizio o verifica"
        )}</Text>`,
        type: "HTML"
      },
      body: {
        content: <ExerciseCloner originalType={activeTab} />,
        type: "REACT"
      },
      footer: {
        content: "",
        type: "HTML"
      },
      addCloseButton: true
    });
    modalMessage.show();
  };

  let buttonNewExercise = "";
  let instantBox = "";
  let mainContent = "";
  const newExerciseURL = `#${createURL("exercises_new", {
    group_id: groupId
  })}`;

  if (!group.shouldShowCreateTest() && group.isTeacher()) {
    buttonNewExercise = (
      <div className="row">
        <div className="col-xs-6 padding-right-half">
          <a
            href={newExerciseURL}
            className="default-box new-element text-center"
          >
            <Heading level="5">
              <Translate text="new_exercise" />
            </Heading>
          </a>
        </div>
        <div className="col-xs-6 padding-left-half">
          <div
            className="default-box new-element text-center clickable"
            onClick={showQuizCloner}
          >
            <Heading level="5">
              <Translate text="import" />
            </Heading>
          </div>
        </div>
      </div>
    );
  }

  if (
    group.isTeacher() &&
    !isUndefined(currentInstant) &&
    currentInstant.has("id")
  ) {
    instantBox = (
      <div className="app__box exercise-list-item-wrapper">
        <div className="full-width flex flex-centered-row flex-spaced-column">
          <div className="flex">
            <span className="margin-right-5">
              <Heading level="4">
                {currentInstant.get("exercise_title")}
              </Heading>
            </span>
            <Label theme={whiteTheme} color="yellow" outline={false}>
              {translate("current_instant_info_label")}
            </Label>
          </div>
          <div className="flex-content-end">
            <span className="margin-right-5">
              <Button
                onClick={abortInstant}
                variant="secondary"
                theme={whiteTheme}
              >
                <Translate text="abort_instant_button_label" />
              </Button>
            </span>
            <Button
              onClick={resumeInstant}
              variant="primary"
              theme={whiteTheme}
            >
              <Translate text="resume_instant_button_label" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (activeTab === "drafts") {
    mainContent = (
      <ExercisesDraftList type={activeTab} exercisesList={exercisesList} />
    );
  } else {
    mainContent = (
      <ExercisesList type={activeTab} exercisesList={exercisesList} />
    );
  }

  const tabItems = [
    {
      id: 1,
      value: "assignments",
      children: (
        <Text type="cta">{translate("exercise_list_tab_published")}</Text>
      )
    },
    {
      id: 2,
      value: "drafts",
      children: <Text type="cta">{translate("exercise_list_tab_drafts")}</Text>
    }
  ];

  const filterOptions = [
    {
      id: "all",
      label: translate("exercise_list_filter_all")
    },
    {
      id: ExerciseType.ASSIGNMENT,
      label: translate("exercise_list_item_label_assignment")
    },
    {
      id: ExerciseType.EXAMINATION,
      label: translate("exercise_list_item_label_examination")
    }
  ];

  const filterDropdown =
    activeTab === "drafts" ? null : (
      <Box
        position={isLayoutModeMobile ? "relative" : "absolute"}
        right={0}
        marginBottom="20px"
      >
        <Dropdown
          theme={greyTheme}
          optionsList={filterOptions}
          selectedOptionId={activeFilter}
          setSelectedOptionId={id => setActiveFilter(id)}
          placeholder={translate("exercise_list_filter_label")}
          width={isLayoutModeMobile ? "100%" : "200px"}
        />
      </Box>
    );

  return (
    <div>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        height={isLayoutModeMobile ? "unset" : "42px"}
        marginBottom="24px"
        marginTop={isLayoutModeMobile && group.isTeacher() ? "24px" : 0}
      >
        {group.isTeacher() ? (
          <ContentSwitcher
            theme={greyTheme}
            onContentItemChange={url => {
              navigateTo(getUrlByParams({ param: url, group_id: groupId }));
            }}
            activeOption={activeTab}
            items={tabItems}
          />
        ) : null}
        {isLayoutModeMobile ? null : filterDropdown}
      </Box>
      {buttonNewExercise}
      {isLayoutModeMobile ? filterDropdown : null}
      {instantBox}
      {mainContent}
      {pagination && (
        <div className="margin-vertical-20 flex-centered-column">
          <Button onClick={nextPage} variant="primary" theme={greyTheme}>
            <Translate text="exercises_list_show_more_button_label" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(Exercises);
