const isISO8601Date = value => {
  const iso8601Pattern =
    // eslint-disable-next-line no-useless-escape
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([+\-]\d{2}:\d{2}|Z)$/;

  return iso8601Pattern.test(value);
};

export const userIsUnderage = user => {
  if (user?.birthday) {
    const currentDate = new Date();
    const dateParts = user.birthday.split("-");

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 from the month since it's zero-based
    const year = parseInt(dateParts[2], 10);

    const userBirthdate = isISO8601Date(user.birthday)
      ? new Date(user.birthday)
      : new Date(year, month, day);

    const userYears = currentDate.getFullYear() - userBirthdate.getFullYear();
    const userMonth = currentDate.getMonth() - userBirthdate.getMonth();
    const userDay = currentDate.getDate() - userBirthdate.getDate();

    if (
      userYears < 18 ||
      (userYears === 18 && userMonth < 0) || // previous month birthday
      (userYears === 18 && userMonth === 0 && userDay < 0) // same month birthday but not yet same day
    ) {
      return true;
    }
  }

  return false;
};

export function userIsTutorApproved(user) {
  if (user.tutorApproved) {
    return true;
  }
  return false;
}

export function userUnderageNeedApprobation(user) {
  if (userIsUnderage(user)) {
    if (!userIsTutorApproved(user)) {
      return true;
    }
  }
  return false;
}

export function userGetDeaAccount(user) {
  if (
    typeof user !== "undefined" &&
    typeof user.connectedProfiles !== "undefined"
  ) {
    const profileDea = user.connectedProfiles.find(
      profile => profile.provider === "dea"
    );
    return profileDea;
  }
  return false;
}

export function userIsExternalRegistered(user, provider) {
  if (
    typeof user !== "undefined" &&
    typeof user.connectedProfiles !== "undefined"
  ) {
    const profileDea = user.connectedProfiles.find(
      profile =>
        profile.is_registration_profile === "true" &&
        profile.provider === provider
    );
    return profileDea;
  }
  return false;
}

export function userHasDeaAccount(user) {
  if (userGetDeaAccount(user)) {
    return true;
  }
  return false;
}
