import { useParams } from "react-router";
import { SpaceChatsParams } from "src/js/types";
import { Thread } from "src/js/types/models/Thread";

/**
 * @name useFoundThread
 *
 * @description useFoundThread hook to get the active thread from the list of threads of the current space.
 */

const useFoundThread = ({
  threadsList,
  fetchedThreadId
}: {
  threadsList: Thread[];
  fetchedThreadId?: string;
}) => {
  const { thread_id } = useParams<SpaceChatsParams>();

  const foundThreadId = fetchedThreadId || thread_id;

  const foundThread =
    threadsList?.find(thread => thread.id === foundThreadId) || null;

  return {
    foundThread
  };
};

export default useFoundThread;
