/* eslint-disable @typescript-eslint/no-unused-vars */
import { VirtualViewDescriptionStrings } from "./ViewTrackingProvider.types";

export const __INITIAL_VIEWTRACKING_STATE__ = {
  trackVirtualView: (page: VirtualViewDescriptionStrings) => {},
  updatePageTitle: (title: string) => {},
  trackView: () => {}
};

export const __DEFAULT_SPACE_INFO__ = {
  id: undefined,
  slug: undefined,
  trial: undefined,
  isDemo: undefined
};

export const __DEFAULT_USER_DETAILS__ = {
  id: undefined,
  birthday: undefined,
  email: undefined
};
