import { useEffect, useState } from "react";
import { useDebounce } from "src/js/hooks";
import { fetchPublicProfile } from "src/js/repository/publicProfileRepository";
import fetchSearchUserList from "src/js/repository/userListRepository";
import { __SEARCH_USER_LIST_LIMIT_LIMIT__ } from "src/js/settings/settingsPagination";
import { UserDetailsModel } from "src/js/types";
import { FilterProps } from "../types";

// TODO improve the readability and performance

type PaginatedUsersData = {
  data: UserDetailsModel[];
  hasNext: boolean;
  start: number;
  limit: number;
  filterString: string;
};

type SelectedUser = {
  id: string;
  label: string;
};

const useUserFilter = ({
  activeUserUuid,
  filters,
  onChange
}: { activeUserUuid?: string } & FilterProps) => {
  const [filterString, setFilterString] = useState("");
  const [selectedUser, setSelectedUser] = useState<SelectedUser>(null);
  const [usersLoading, setLoading] = useState(false);
  const userFilterDebounced = useDebounce(filterString, 300);
  const [paginatedUsers, setPaginatedUsers] = useState<PaginatedUsersData>({
    data: [],
    hasNext: false,
    start: 0,
    limit: __SEARCH_USER_LIST_LIMIT_LIMIT__,
    filterString: ""
  });

  const fetchUsersList = () => {
    const { start, limit } = paginatedUsers;
    fetchSearchUserList(start, limit, filterString).then(
      ({ results, hasNext }) => {
        const filteredData = results.filter(
          userObj => userObj.uuid !== paginatedUsers.data[0]?.uuid
        );

        setPaginatedUsers({
          data: [...paginatedUsers.data, ...filteredData],
          hasNext,
          start: start + limit,
          limit,
          filterString
        });
      }
    );
  };

  const fetchUserLabel = () => {
    if (!activeUserUuid) {
      fetchUsersList();
      setLoading(false);
      return;
    }

    fetchPublicProfile(activeUserUuid).then(result => {
      setSelectedUser({
        id: activeUserUuid,
        label: `${result.name} ${result.surname}`
      });
      setPaginatedUsers({
        ...paginatedUsers,
        data: [
          {
            name: result.name,
            surname: result.surname,
            uuid: activeUserUuid,
            id: result.user_id
          },
          ...paginatedUsers.data
        ]
      });

      fetchUsersList();
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchSearchUserList(
      0,
      __SEARCH_USER_LIST_LIMIT_LIMIT__,
      userFilterDebounced
    ).then(({ results, hasNext }) => {
      setPaginatedUsers({
        data: results,
        hasNext,
        start: 0,
        limit: __SEARCH_USER_LIST_LIMIT_LIMIT__,
        filterString
      });
    });
  }, [userFilterDebounced]);

  const setUserFilter = (userId?: string) => {
    if (!userId) {
      setSelectedUser(null);
    }

    const usr = paginatedUsers.data.find(user => user.uuid === userId);

    if (usr) {
      setSelectedUser({
        id: usr.uuid,
        label: `${usr.name} ${usr.surname}`
      });
    }

    const newFilters = {
      ...filters,
      userFilter: userId ? [userId] : undefined
    };

    onChange(newFilters);
  };

  useEffect(() => {
    fetchUserLabel();
  }, []);

  return {
    usersLoading,
    paginatedUsers,
    selectedUser,
    setUserFilter,
    filterString,
    setFilterString,
    showNext: () => fetchUsersList()
  };
};

export default useUserFilter;
