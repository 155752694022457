import styled from "styled-components";

export const InformationBox = styled.div`
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.grey[800]};
  margin-top: 16px;
`;

export const SaveLinkButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.primaryColor[500]};

  border-bottom: 1px solid ${({ theme }) => theme.primaryColor[500]};
`;
