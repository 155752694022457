import styled from "styled-components";

export const TeacherFooterContainer = styled.div`
  margin: 20px -20px 0px;
  padding: 20px 20px 0px;
  border-top: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.title};
  @media (max-width: 767px) {
    align-items: initial;
    flex-direction: column;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.span`
  margin-left: 10px;
`;

export const SubmitButton = styled.span`
  margin-left: auto;
`;
