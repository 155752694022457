import { ObjectValues } from "./Common";
import { BasePaginationParams, StandardPaginationResponse } from "./Pagination";
import { AvatarModel } from "./User";

// Types for severity

export const StateMetricSeverity = {
  Critical: "critical",
  Warning: "warning",
  Normal: "normal"
} as const;

export type StateMetricSeverityType = ObjectValues<typeof StateMetricSeverity>;

export const StateTrendSeverity = {
  Critical: "critical",
  Normal: "normal"
} as const;

export type StateTrendSeverityType = ObjectValues<typeof StateTrendSeverity>;

// Types for filtering

export const DashboardAggregation = {
  Day: "day",
  Week: "week",
  Month: "month"
};

export type DashboardAggregationType = ObjectValues<
  typeof DashboardAggregation
>;

export const DashboardXAxisFrequency = {
  EveryDay: 1,
  AlternateDays: 2,
  EveryWeek: 7,
  EveryMonth: 30,
  Every3Months: 12
} as const;

export type DashboardXAxisFrequencyType = ObjectValues<
  typeof DashboardXAxisFrequency
>;

export const DashboardRangeTime = {
  LastDay: "last_day",
  Last7Days: "last_7_days",
  LastMonth: "last_month",
  Last3Month: "last_3_month",
  Last6Month: "last_6_month",
  LastYear: "last_year"
};

export type DashboardRangeTimeType = ObjectValues<typeof DashboardRangeTime>;

export type AnalyticsFilter = {
  groups?: string[];
  range?: DashboardRangeTimeType;
  from?: string;
  to?: string;
};

// Types for fetching

export type BaseChartItem = {
  label: string;
  value: number;
};

export type BaseChartTrend = {
  averageNumber: number;
  averageNumberTrend: number;
};

export type BaseChartResponse<T> = BaseChartTrend & {
  results: T[];
};

export type FetchGroupsProgressParams = BasePaginationParams & {
  spaceId: string;
  groupIds: string[];
  withChartData: boolean;
  fromDate: string;
  toDate: string;
  aggregation: string;
};

export type CourseProgressChartItem = {
  group: {
    id: number;
    name: string;
    cover: AvatarModel | null;
  };
  totalUsers: number;
  data: BaseChartItem[];
};

export type FetchGroupsProgressResponse =
  StandardPaginationResponse<CourseProgressChartItem> & BaseChartTrend;

export type FetchAssessmentScoresParams = {
  spaceId: string;
  groupIds: string[];
  withChartData: boolean;
  fromDate: string;
  toDate: string;
};

export type FetchAssessmentScoresResponse = BaseChartResponse<BaseChartItem> & {
  totalAttemptsNumber: number;
};

export type FetchUserTrainingHoursParams = {
  spaceId: string;
  groupIds: string[];
  withChartData: boolean;
  fromDate: string;
  toDate: string;
  aggregation: string;
};

export type FetchUserTrainingHoursResponse =
  BaseChartResponse<BaseChartItem> & {
    totalActiveUsers: number;
  };

export type FetchUsersLearningTimeParams = {
  spaceId: string;
  groupIds: string[];
  withChartData: boolean;
  fromDate: string;
  toDate: string;
  aggregation: string;
};

export type FetchUsersLearningTimeResponse = BaseChartResponse<BaseChartItem>;

export type FetchUsersSessionsCountParams = {
  spaceId: string;
  groupIds: string[];
  withChartData: boolean;
  fromDate: string;
  toDate: string;
  aggregation: string;
};

export type FetchUsersSessionsCountResponse = BaseChartResponse<BaseChartItem>;
