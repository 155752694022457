import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Box, Icon } from "@arcadia/design-system";
import { BaseTextEditor } from "src/js/components/global/BaseTextEditor";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { useResourceUpload } from "src/js/hooks/resources";
import { OldResource, Resource } from "src/js/types";
import { useTranslation } from "src/js/translation";
import { useSpaceGroupsLayout } from "src/js/layout/SpaceGroupsLayout";
import { useTextAutosaveState } from "../hooks";
import { TextResourceTitle } from "./components";

import * as S from "./AddTextResourceWithAI.styles";
import { ChangeTitleModal } from "./components/ChangeTitleModal";

type Props = {
  onSubmit: (resource: Resource, forceReload?: boolean) => void;
  onClose: (resource: Resource) => void;
  setUploadingProgress: (id: number, progress: number) => void;
  elementId?: number;
  defaultResource?: OldResource;
  editMode?: boolean;
  context: "board" | "post";
};

const fromOldResourceToResource = (oldResource: OldResource): Resource => {
  return {
    aiGenerated: false,
    exerciseId: null,
    fileExtension: null,
    id: oldResource.id,
    name: oldResource.name,
    textContent: oldResource.content,
    type: oldResource.type,
    fileMimeType: null,
    preview: null,
    size: null,
    uuid: null
  };
};

// This file is used in Wall and Modules, with AI and autosave
// elementId is null only in modules (modules has only edit mode)
const AddTextResourceWithAI = ({
  onSubmit,
  elementId,
  onClose,
  setUploadingProgress,
  defaultResource,
  editMode = false,
  context
}: Props) => {
  const {
    UIStore: {
      isLayoutModeMobile,
      isSideBarOpen,
      isStickyBannerVisible,
      updateStickyBannerVisibility
    }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { setHeaderComponent, resetHeaderComponent } = useSpaceGroupsLayout();
  const { translate } = useTranslation();
  const [resource, setResource] = useState<Resource>(
    defaultResource ? fromOldResourceToResource(defaultResource) : null
  );
  const [mobileTitle, setMobileTitle] = useState(
    defaultResource?.name || translate("text_new")
  );
  const [title, setTitle] = useState(
    defaultResource?.name || translate("text_new")
  );

  const {
    BoardsStore: { setUploadingElementIndexes, filterUploadingElementIndexes },
    UIStore: { closeSidebar },
    ModalStore: { openModal, closeModal }
  } = useStores();

  const { handleResourceTextUpload } = useResourceUpload(setUploadingProgress);

  useEffect(() => {
    if (isStickyBannerVisible) {
      updateStickyBannerVisibility(false);
    }

    closeSidebar();
    document.body.classList.add("stop-scrolling");
    // Only on post creation you will have edit mode false
    if (!editMode) {
      handleResourceTextUpload(title, "<p></p>", elementId).then(response => {
        setResource(response as Resource);
      });
    }

    return () => {
      if (isStickyBannerVisible) {
        updateStickyBannerVisibility(true);
      }
      document.body.classList.remove("stop-scrolling");
    };
  }, []);

  const { editor, textEditorMenu, saveResource, isSaving } =
    useTextAutosaveState({
      title,
      setResource,
      resource,
      onSubmit
    });

  const onCloseEditor = () => {
    saveResource().then(res => {
      if (onClose) onClose(res);
      onSubmit(res, true);
      if (!elementId) return;
      setUploadingElementIndexes(elementId);
      filterUploadingElementIndexes(elementId);
    });
  };

  const shouldShowTitle = context === "post";

  const openChangeTitleModal = () => {
    openModal(() => (
      <ChangeTitleModal
        initialTitle={mobileTitle}
        onConfirm={updatedTitle => {
          setMobileTitle(updatedTitle);
          setTitle(updatedTitle);
          closeModal();
        }}
        closeModal={closeModal}
      />
    ));
  };

  useEffect(() => {
    setHeaderComponent(
      isLayoutModeMobile ? (
        <S.MobileHeader>
          <Box display="flex" gap={8} width="100%" alignItems="center">
            <S.StlyedActionButton
              theme={whiteTheme}
              icon="chevronLargeLeft"
              variant="secondaryColored"
              onClick={onCloseEditor}
            />
            {isSaving ? (
              <S.AutosaveText type="captionAlert">
                <Icon icon="refresh" initialViewbox width={14} height={14} />
                {translate("text_editor_autosave_saving_message")}
              </S.AutosaveText>
            ) : (
              <S.AutosaveText type="captionAlert">
                <Icon icon="floppySave" initialViewbox width={14} height={14} />
                {translate("text_editor_autosave_success_message")}
              </S.AutosaveText>
            )}
            {shouldShowTitle && (
              <Box display="flex" marginLeft="auto">
                <S.UpdateTitleButton
                  type="button"
                  onClick={openChangeTitleModal}
                >
                  <Icon icon="pencil" initialViewbox />
                </S.UpdateTitleButton>
              </Box>
            )}
          </Box>
        </S.MobileHeader>
      ) : (
        <S.StyledSecondaryBar
          preventGrid
          leftSection={
            <Box marginLeft="16px">
              <S.StlyedActionButton
                theme={whiteTheme}
                icon="chevronLargeLeft"
                variant="secondaryColored"
                onClick={onCloseEditor}
              />
            </Box>
          }
          middleSection={
            shouldShowTitle && (
              <TextResourceTitle
                defaultTitle={title}
                onSubmit={(newTitle: string) => {
                  setTitle(newTitle);
                }}
              />
            )
          }
          rightSection={
            <Box position="relative" display="flex" alignItems="center">
              <S.AutosaveContainer>
                {isSaving ? (
                  <S.AutosaveText type="captionAlert">
                    <Icon
                      icon="refresh"
                      initialViewbox
                      width={14}
                      height={14}
                    />
                    {translate("text_editor_autosave_saving_message")}
                  </S.AutosaveText>
                ) : (
                  <S.AutosaveText type="captionAlert">
                    <Icon
                      icon="floppySave"
                      initialViewbox
                      width={14}
                      height={14}
                    />
                    {translate("text_editor_autosave_success_message")}
                  </S.AutosaveText>
                )}
              </S.AutosaveContainer>
            </Box>
          }
        />
      )
    );
    return () => {
      resetHeaderComponent();
    };
  }, [isLayoutModeMobile, shouldShowTitle, title, isSaving, mobileTitle]);

  if (!resource) return null;
  return (
    <S.Wrapper isSidebarOpen={isSideBarOpen}>
      <S.MainContainer paddingTop="0px" paddingTopMobile="0px">
        <S.EditorWrapper>
          <BaseTextEditor
            className="EditorWrapper"
            floatingMenu={textEditorMenu}
            bubbleMenu={textEditorMenu}
            editor={editor}
          />
        </S.EditorWrapper>
      </S.MainContainer>
    </S.Wrapper>
  );
};

export default observer(AddTextResourceWithAI);
