import { Input } from "@arcadia/design-system";
import React, { useEffect } from "react";
import { ValidationOptions } from "react-hook-form";
import { useForm, useMutation, useStores } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { savePublicProfileSettings } from "src/js/repository/publicProfileRepository";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { FormInputs, FormValidatorMap } from "src/js/types";
import { useTheme } from "styled-components";
import { ModalBody, ModalContent } from "../Modal";
import { UserSettingsChangeNameModalFields } from "../types";

const getNameValidator = (key: TranslationKeys): ValidationOptions => ({
  required: key,
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /^([a-zA-Z\.\u00C0-\u017F '-]{2,50})$/,
    message: key
  }
});

const __CHANGE_NAME_SCHEMA__: FormValidatorMap<UserSettingsChangeNameModalFields> =
  {
    name: getNameValidator("name_required_validation_error"),
    surname: getNameValidator("surname_required_validation_error")
  };

const UserAccountChangeNameModal = () => {
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const { errors, register, handleSubmit, setValue } =
    useForm<FormInputs<UserSettingsChangeNameModalFields>>();
  const {
    UserStore,
    ModalStore,
    UIStore: { isLayoutModeMobile }
  } = useStores();

  useEffect(() => {
    setValue("name", UserStore.activeUser.name);
    setValue("surname", UserStore.activeUser.surname);
  }, []);

  const { mutate: changeNameHandler, isLoading } = useMutation(
    async (vars: { name: string; surname: string }) => {
      return savePublicProfileSettings(vars);
    },
    {
      onError(error) {
        showToastError({ str: translate(error.message as TranslationKeys) });
      },
      onSuccess() {
        showToastSuccess({
          str: translate("name_update_success")
        });
        UserStore.fetchUser();
        ModalStore.closeModal();
      }
    }
  );

  const handleSubmission = handleSubmit(({ name, surname }) => {
    changeNameHandler({ name, surname });
  });

  return (
    <ModalContent title={translate({ text: "change_name" })}>
      <ModalBody
        onSubmit={handleSubmission}
        isLoading={isLoading}
        formId="change-name-form"
      >
        <div className="margin-top-20">
          <Input
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="name"
            label={translate("change_name_label")}
            ref={register(__CHANGE_NAME_SCHEMA__.name)}
            error={errors.name}
            rounded
          />
        </div>
        <div className="margin-top-20">
          <Input
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            label={translate("change_surname_label")}
            name="surname"
            ref={register(__CHANGE_NAME_SCHEMA__.surname)}
            error={errors.surname}
            rounded
          />
        </div>
      </ModalBody>
    </ModalContent>
  );
};

export default UserAccountChangeNameModal;
