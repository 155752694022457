import { SocialLoginButton } from "@arcadia/design-system";
import styled from "styled-components";

export const MobileContainer = styled.div`
  height: 72px;
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const DesktopContainer = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const UniversityButton = styled(SocialLoginButton)`
  @media screen and (max-width: 767px) {
    width: auto;
    height: 100%;
    padding: 0px;
    flex: 1;
  }
`;
