import { Icon, RadioGroupOption, Text } from "@arcadia/design-system";
import React from "react";
import { useTheme } from "styled-components";

import * as S from "./MobilePreferencePicker.styles";

type MobileRadioListProps = {
  title: string;
  elementList: RadioGroupOption[];
  selectedElement: string;
  onChange: (newValue: string) => void;
  onBack: () => void;
};

const MobileRadioList = ({
  title,
  elementList,
  selectedElement,
  onChange,
  onBack
}: MobileRadioListProps) => {
  const { whiteTheme, primaryColor, colors } = useTheme();
  return (
    <>
      <S.TitleWrapper>
        <S.BackWrapper onClick={onBack}>
          <Icon icon="chevronLargeLeft" width={20} height={20} />
        </S.BackWrapper>
        <Text type="formField">{title}</Text>
      </S.TitleWrapper>

      <S.StyledRadioGroup
        orientationLabel="right"
        theme={whiteTheme}
        orientationOption="column"
        options={elementList}
        onChangeFunction={onChange}
        value={selectedElement}
        color={primaryColor[500]}
        hoverBgColor={colors.coldGrey[900]}
      />
    </>
  );
};

export default MobileRadioList;
