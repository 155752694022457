import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { EventsListPreview } from "src/js/components/EventsListPreview";
import { useStores } from "src/js/hooks";
import { updateNextThreeLive } from "src/js/modules/calendarFunction";
import { openExternalLink } from "src/js/modules/commonFunction";
import { createStaticUrl } from "src/js/modules/routing";
import { CalendarEventModal } from "src/js/pages/calendar/CalendarEventModal";

const WallEventListPreview = () => {
  const {
    GroupStore,
    CalendarStore,
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { groupId, userIsTeacher } = GroupStore;
  const {
    nextEvents,
    getCalendarNextEvents,
    timerMilliseconds,
    setNextEventTimer
  } = CalendarStore;

  useEffect(() => {
    let timer;
    if (timerMilliseconds) {
      timer = setTimeout(() => {
        updateNextThreeLive({ groupId, getCalendarNextEvents });
        setNextEventTimer();
      }, timerMilliseconds);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [timerMilliseconds]);

  const openCreateEventDrawer = () => {
    const currentDate = new Date();
    const defaultEnd = new Date(currentDate);
    defaultEnd.setHours(defaultEnd.getHours() + 1);
    openModal(() => (
      <CalendarEventModal
        hasInstantCreationMode
        hasCollapsableDates
        defaultStartDate={currentDate}
        defaultEndDate={defaultEnd}
        closeModal={(url, start) => {
          const now = moment(new Date());
          const momentStart = moment(start);
          if (url && momentStart.isBefore(now)) {
            openExternalLink(url);
          }
          closeModal();
        }}
      />
    ));
  };

  return (
    <EventsListPreview
      events={nextEvents}
      newEvent={() => openCreateEventDrawer()}
      calendarLink={`#${createStaticUrl("agenda", {
        group_id: groupId,
        date: moment().format("YYYY-MM-DD")
      })}`}
      userIsTeacher={userIsTeacher}
      groupId={groupId}
    />
  );
};

export default observer(WallEventListPreview);
