import { TranslationKeys } from "src/js/translation";

export const __COURSE_LENGTH_STEP_OPTIONS__: {
  id: string;
  label: TranslationKeys;
}[] = [
  {
    id: "1",
    label: "course_length_dropdown_1_weeks"
  },
  {
    id: "2",
    label: "course_length_dropdown_2_weeks"
  },
  {
    id: "3",
    label: "course_length_dropdown_3_weeks"
  },
  {
    id: "4",
    label: "course_length_dropdown_4_weeks"
  },
  {
    id: "5",
    label: "course_length_dropdown_5_weeks"
  },
  {
    id: "6",
    label: "course_length_dropdown_6_weeks"
  },
  {
    id: "7",
    label: "course_length_dropdown_7_weeks"
  },
  {
    id: "8",
    label: "course_length_dropdown_8_weeks"
  }
];
