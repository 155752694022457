import { Button, Heading, Text } from "@arcadia/design-system";
import React, { FC, useEffect } from "react";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { openExternalLink } from "src/js/modules/commonFunction";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { VideoPlayer } from "../../VideoPlayer";
import { ModalLayout } from "../../modal/ModalLayout";
import * as S from "./ClaimModal.styles";

/**
 * @name ClaimModal
 *
 * @description ClaimModal component.
 */

type ClaimModalProps = {
  close: () => void;
  onGoBack?: () => void;
  header?: string;
  text?: string;
};

const ClaimModal: FC<ClaimModalProps> = ({ close, onGoBack, header, text }) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser },
    SpaceStore: { activeSpace }
  } = useStores();

  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("entry.1", "-");
  urlSearchParams.append("entry.196046153", activeUser?.name);
  urlSearchParams.append("entry.1545287469", activeUser?.name);
  urlSearchParams.append("entry.1994106920", activeUser?.surname);
  urlSearchParams.append("entry.289479491", activeUser?.email);
  urlSearchParams.append("entry.372617725", activeSpace?.space?.name);

  const formUrl = `${translate(
    "claim_space_form_url"
  )}?${urlSearchParams.toString()}`;

  useEffect(() => {
    _trackEvent("ClaimingSpace", "ClaimingSpaceAccessPoint");
  }, []);

  return (
    <ModalLayout
      labels={{
        title: translate({ text: "claim_modal_title" })
      }}
      closeModal={close}
      mobileProps={{
        withHeaderLabels: false,
        withAdaptiveHeight: true
      }}
    >
      <S.ClaimModalWrapper>
        <S.VideoWrapper>
          <VideoPlayer video={S3Video.ClaimExternalFlowModal} />
        </S.VideoWrapper>
        <Heading level="6">
          {header ?? translate({ text: "claim_modal_header_text" })}
        </Heading>
        <Text>{text ?? translate({ text: "claim_modal_text" })}</Text>
        <S.FooterWrapper>
          {onGoBack ? (
            <Button
              variant="secondary"
              type="button"
              theme={whiteTheme}
              fullWidth
              onClick={onGoBack}
            >
              {translate("back")}
            </Button>
          ) : null}
          <Button
            onClick={() => openExternalLink(formUrl)}
            theme={whiteTheme}
            variant="primary"
            fullWidth={isLayoutModeMobile}
          >
            {translate({ text: "claim_modal_cta" })}
          </Button>
        </S.FooterWrapper>
      </S.ClaimModalWrapper>
    </ModalLayout>
  );
};

export default ClaimModal;
