import { motion } from "framer-motion";
import styled from "styled-components";
import { Text } from "@ds_universal/Text";
import colors from "@ds_themes/colors";

export const ActionItemContainer = styled(motion.div)<{ isDisabled?: boolean }>`
  border-radius: 4px;
  display: flex;
  background-color: ${colors.grey[1000]};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px 24px;
  user-select: none;
  transition: background-color 300ms ease-in-out;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: ${colors.grey[700]};
  `}

  &:hover {
    background-color: ${colors.coldGrey[900]};
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
    background-color: ${({ isDisabled }) =>
      isDisabled ? colors.grey[1000] : colors.coldGrey[900]};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(Text)`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled(Text)<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) =>
    isDisabled ? colors.grey[700] : colors.grey[300]};
`;
