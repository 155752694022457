import {
  fetchBoardElementState,
  saveBoardElementState
} from "../repository/boardRepository";
import { SCORM2004CMI, SCORMCMI } from "../types/models/Scorm";
import { structuredClone } from "./commonFunction";

type BasicHandlerProps = {
  boardElementId: string;
  userId: number;
  username?: string;
  data: any;
};

type SCORMHandlerProps = Omit<BasicHandlerProps, "data"> & {
  iframe?: {
    current: { contentWindow: { postMessage: Window["postMessage"] } };
  };
  version: "1.2" | "2004";
};

// TODO move the key in the general file
const getLocalStorageKey = (boardElementId: string, userId: number): string =>
  `CMI-${boardElementId}-${userId}`;

const saveState = ({
  boardElementId,
  userId,
  data
}: BasicHandlerProps): void => {
  saveBoardElementState({
    board_element_id: boardElementId,
    body: data
  })
    .then(() =>
      window.localStorage.removeItem(getLocalStorageKey(boardElementId, userId))
    )
    .catch(() =>
      window.localStorage.setItem(
        getLocalStorageKey(boardElementId, userId),
        JSON.stringify(data)
      )
    );
};

export const LMSFinishHandler = ({
  boardElementId,
  userId,
  data
}: BasicHandlerProps): void => {
  saveState({ boardElementId, userId, data });
};

export const LMSCommitHandler = ({
  boardElementId,
  userId,
  data
}: BasicHandlerProps): void => {
  saveState({ boardElementId, userId, data });
};

export const LMSSetValueHandler = ({
  boardElementId,
  userId,
  data
}: BasicHandlerProps): void => {
  window.localStorage.setItem(
    getLocalStorageKey(boardElementId, userId),
    JSON.stringify(data)
  );
};

// TODO understand what we can omit
export function generateSCORMCMI(userId: string, username: string): SCORMCMI {
  return {
    comments: null,
    comments_from_lms: null,
    core: {
      student_id: userId,
      student_name: username,
      session_time: null,
      total_time: null,
      score: { raw: null, min: null, max: null },
      credit: null,
      entry: null,
      exit: null,
      lesson_location: null,
      lesson_mode: null,
      lesson_status: null
    },
    interactions: { childArray: null },
    launch_data: null,
    objectives: { childArray: null },
    student_data: {
      mastery_score: null,
      max_time_allowed: null,
      time_limit_action: null
    },
    student_preference: {
      audio: null,
      language: null,
      speed: null,
      text: null
    },
    suspend_data: null
  };
}

// TODO understand what we can omit
export function generateSCORM2004CMI(
  userId: string,
  username: string
): SCORM2004CMI {
  return {
    comments_from_learner: [{ comment: null, location: null }],
    comments_from_lms: [{ comment: null, location: null }],
    completion_status: null,
    completion_threshold: null,
    credit: null,
    entry: null,
    exit: null,
    interactions: [
      {
        id: null,
        type: null,
        objectives: [{ id: null }],
        timestamp: null,
        correct_responses: [{ pattern: null }],
        weighting: null,
        learner_response: null,
        result: null,
        latency: null,
        description: null
      }
    ],
    launch_data: null,
    learner_id: userId,
    learner_name: username,
    learner_preference: {
      audio_level: null,
      language: null,
      delivery_speed: null,
      audio_captioning: null
    },
    location: null,
    max_time_allowed: null,
    mode: null,
    objectives: [
      {
        id: null,
        success_status: null,
        completion_status: null,
        progress_measure: null,
        description: null
      }
    ],
    progress_measure: null,
    scaled_passing_score: null,
    score: {
      raw: null,
      min: null,
      max: null,
      scaled: null
    },
    session_time: null,
    success_status: null,
    suspend_data: null,
    time_limit_action: null,
    total_time: null
  };
}

export const readySCORMHandler = ({
  boardElementId,
  userId,
  username,
  iframe,
  version
}: SCORMHandlerProps): void => {
  const storageKey = getLocalStorageKey(boardElementId, userId);
  const initData = window.localStorage.getItem(storageKey);

  const postMessageToIframe = (
    data: SCORMCMI | SCORM2004CMI | string
  ): void => {
    const parsedData = typeof data === "string" ? data : JSON.stringify(data);
    const initObj = { method: "LMSInit", initData: parsedData };
    iframe?.current.contentWindow.postMessage(JSON.stringify(initObj), "*");
  };

  if (initData) {
    saveBoardElementState({
      board_element_id: boardElementId,
      body: JSON.parse(initData)
    })
      .then(() => window.localStorage.removeItem(storageKey))
      .catch(() => window.localStorage.setItem(storageKey, initData));

    postMessageToIframe(initData);
  } else {
    fetchBoardElementState({ board_element_id: boardElementId })
      .then(({ state }) => {
        let stateToPass: SCORM2004CMI | SCORMCMI;

        // This is useful for the SCORM already saved, so we can inject the data even if it's not present
        if (version === "1.2") {
          stateToPass = structuredClone(state) as SCORMCMI;
          if (!stateToPass.core.student_id) {
            stateToPass.core.student_id = `${userId}`;
          }
          if (!stateToPass.core.student_name) {
            stateToPass.core.student_name = username;
          }
        } else {
          // version 2004
          stateToPass = structuredClone(state) as SCORM2004CMI;
          if (!stateToPass.learner_id) {
            stateToPass.learner_id = `${userId}`;
          }
          if (!stateToPass.learner_name) {
            stateToPass.learner_name = username;
          }
        }

        postMessageToIframe(stateToPass);
      })
      .catch(() => {
        const emptyData =
          version === "1.2"
            ? generateSCORMCMI(`${userId}`, username)
            : generateSCORM2004CMI(`${userId}`, username);
        postMessageToIframe(emptyData);
      });
  }
};
