/* eslint-disable import/no-duplicates */
import { setDefaultOptions } from "date-fns";
import { enUS, es, fr, it } from "date-fns/locale";
import i18next, { TOptions } from "i18next";
import { createLocalData } from "Legacy/modules/utilityStorage";
import moment from "moment";
// imports needed for making moment locale work with vite
// https://github.com/moment/moment/issues/5926#issuecomment-1093233946
import "moment/dist/locale/es";
import "moment/dist/locale/it";
import "moment/dist/locale/fr";
import React, { useEffect, useState } from "react";
import {
  I18nextProvider,
  TFunction,
  useTranslation as i18nextUseTranslation
} from "react-i18next";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import customi18next from "../../i18next";
import { __DEFAULT_LANGUAGE_COUNTRY__ } from "../settings/settingsLocalization";
import { TranslationKeys } from "./translated.types";
import { TranslationFn } from "./types";

export type StringVariables =
  | string
  | TOptions<Record<string, string | number>>;

export type TranslateOptions = {
  text: TranslationKeys;
  stringVariables?: StringVariables;
};

export function Translate({
  text,
  stringVariables = {}
}: TranslateOptions): JSX.Element {
  const { t } = i18nextUseTranslation();
  const translatedText = t(text, stringVariables);

  return translatedText as unknown as JSX.Element;
}

export type TranslatorProps = {
  children: (params: {
    translate: (
      key: TranslationKeys,
      stringVariables?: StringVariables
    ) => string;
  }) => JSX.Element;
};

const translateText = (
  t: TFunction<TranslationKeys>,
  key: TranslationKeys,
  stringVariables?: StringVariables
) => {
  const translatedText = t(key, stringVariables);
  return translatedText;
};

export function Translator({ children }: TranslatorProps) {
  const { t } = i18nextUseTranslation();
  return children({
    translate: (key, stringVariables) => {
      return translateText(t, key, stringVariables);
    }
  });
}

export function translateString({
  text,
  stringVariables = {}
}: TranslateOptions) {
  const translatedText = i18next.t(text, stringVariables);
  return translatedText;
}

export const getDateFnsLocale = (lang: string) => {
  switch (lang) {
    case __DEFAULT_LANGUAGE_COUNTRY__.it:
      return it;
    case __DEFAULT_LANGUAGE_COUNTRY__.en:
      return enUS;
    case __DEFAULT_LANGUAGE_COUNTRY__.es:
      return es;
    case __DEFAULT_LANGUAGE_COUNTRY__.fr:
      return fr;
    default:
      return enUS;
  }
};

export const setLocale = (newLocale: string) => {
  createLocalData(__COOKIE_LANG__, newLocale);

  setDefaultOptions({ locale: getDateFnsLocale(newLocale) });

  moment.locale(newLocale.substring(0, 2));
  return i18next.changeLanguage(
    `${newLocale.substring(0, 2)}-${newLocale.substring(3, 5)}`
  );
};

type UseTranslationOptions = {
  translate(
    keyOrObj: TranslationKeys | TranslateOptions,
    stringVariables?: StringVariables
  ): string;
  setLocale: (newLocale: string) => Promise<TFunction>;
  translateStrict: TranslationFn;
};

export const useTranslation = (): UseTranslationOptions => {
  const { t } = i18nextUseTranslation();
  const translate: UseTranslationOptions["translate"] = (
    keyOrObj,
    stringVariables
  ) => {
    let key: TranslationKeys;
    let variables: StringVariables = stringVariables;

    if (typeof keyOrObj === "string") {
      key = keyOrObj;
    } else if (typeof keyOrObj === "object") {
      key = keyOrObj.text;
      variables = keyOrObj.stringVariables;
    }

    return translateText(t, key, variables);
  };

  return {
    translate,
    setLocale,
    translateStrict: (...args) => {
      const [key, options] = args;
      return translate(key, options);
    }
  };
};

const TranslationProvider = ({ children }) => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    setLocale(getCurrentLanguage()).then(() => {
      setTranslationsLoaded(true);
    });
  }, []);
  return translationsLoaded ? (
    <I18nextProvider i18n={customi18next}>{children}</I18nextProvider>
  ) : null;
};

export default TranslationProvider;
