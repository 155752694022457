import { extractErrorMessage } from "src/js/modules/messageManager";
import {
  api_groups,
  api_group_search,
  api_search_groups,
  getVersionedApiUrl,
  api_users_groups
} from "src/js/repository/apiUrls";
import getUserClient from "src/js/modules/connection";
import {
  BasePaginationResponse,
  GroupDetailsModel,
  GroupInSpaceModel,
  GroupUserRoleEnum
} from "src/js/types";

export async function fetchGroupsList({
  name,
  offset,
  limit
}: {
  name: string;
  offset: number;
  limit: number;
}) {
  return getUserClient()
    .get<BasePaginationResponse<GroupInSpaceModel>>(
      getVersionedApiUrl(api_groups(), "v3"),
      {
        params: {
          name,
          limit,
          offset
        }
      }
    )
    .then(({ data }) => {
      return data;
    });
}

export async function fetchSearchGroupList(activeUserId: string) {
  return getUserClient()
    .get<GroupDetailsModel[]>(
      getVersionedApiUrl(api_search_groups({ userId: activeUserId })),
      {
        params: {
          scope: "list",
          start: 0,
          limit: 0
        }
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_group_list")
      );
      throw Err;
    });
}

export async function fetchFilteredGroupList({
  name,
  limit,
  start,
  role,
  maxTimestamp
}: {
  name?: string;
  limit?: number;
  start?: number;
  role?: GroupUserRoleEnum;
  maxTimestamp?: number;
}) {
  return getUserClient()
    .get(getVersionedApiUrl(api_group_search()), {
      params: {
        name,
        start,
        limit,
        role,
        max_timestamp: maxTimestamp
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_fltered_group_list")
      );
      throw Err;
    });
}

export async function fetchUserGroups() {
  return getUserClient()
    .get(getVersionedApiUrl(api_users_groups(), "v3"))
    .then(response => {
      return response.data;
    });
}
