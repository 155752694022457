import { InputProps, Icon, Input } from "@arcadia/design-system";
import React, { forwardRef, useState } from "react";
import * as S from "./PasswordInput.styles";

type PasswordInputProps = Omit<InputProps, "isPassword" | "rightSection">;

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    const [visible, setVisible] = useState(false);

    return (
      <Input
        {...props}
        isPassword={!visible}
        ref={ref}
        rightSection={
          <S.IconWrapper onClick={() => setVisible(!visible)}>
            <Icon icon={visible ? "eyeClosed" : "eyeOpen"} />
          </S.IconWrapper>
        }
      />
    );
  }
);

PasswordInput.displayName = "PasswordInput";
export default PasswordInput;
