import styled, { css } from "styled-components";
import { SpaceCreationForm } from "../../spaces/space_creation/SpaceCreationForm";
import { SWrapSpaceCreationForm } from "./SpaceCreationPage.types";

export const WrapSpaceCreationForm = styled.div<SWrapSpaceCreationForm>`
  min-width: 100%;
  padding-bottom: 20px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      flex: 1;
      padding-bottom: 40px;
    `}
`;

export const StyledSpaceCreationForm = styled(SpaceCreationForm)`
  height: 100%;
`;
