import styled from "styled-components";

export const BadgeListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey[500]};
  width: 100%;
  margin-bottom: 12px;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderDetail = styled.div`
  margin-left: 22px;
  display: flex;
  align-items: center;
`;

export const HeaderInfo = styled.div`
  margin-right: 22px;
  gap: 64px;
  display: flex;
  align-items: center;
  flex-flow: row;
`;

export const HeaderInfoColumn = styled.div`
  flex: 1;
  display: flex;
  line-height: 18px;
  justify-content: center;
  width: 80px;
`;

export const HeaderActionColumn = styled.div`
  width: 40px;
`;

export const PageNavWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
