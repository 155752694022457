import { Text, VariantType, scrollbarMixin } from "@arcadia/design-system";
import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  max-height: 280px;
  height: 100%;
  padding-bottom: 2px;
  ${scrollbarMixin(VariantType.White)}
`;

export const ToolbarSpaceAdd = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  width: calc(100% - 8px);
  height: 16px;
  align-items: center;
  padding: 8px 2px;
  gap: 12px;
  color: ${({ theme }) => theme.colors.grey[100]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;

export const NameAndSlugContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
`;

export const ColoredSlugText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[400]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ColoredNameText = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[50]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledText = styled(Text)`
  font-weight: 700;
`;

export const SpaceDemoContainer = styled.div<{ hideBorderTop?: boolean }>`
  border-top: ${({ hideBorderTop, theme }) =>
    hideBorderTop ? "none" : `1px solid ${theme.colors.grey[900]}`};
  padding-top: 2px;
  margin-right: 8px;
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpaceCard = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  padding: 8px 4px;
  align-items: center;
  gap: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;
