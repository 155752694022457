import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

interface SelectableItem {
  active?: boolean;
}

export const PageWrapper = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 60px;
`;

export const ContentWrapper = styled.div`
  max-width: 680px;
  width: 100%;
  display: block;
  margin: auto;
  padding-bottom: 40px;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ImageWrapper = styled.img`
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 244px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;

export const CustomImageInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
`;

export const UpdateImageOverlay = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 244px;
  border-radius: 8px;
  background-color: ${({ theme }) => `${theme.primaryColor[900]}90`};
  border: 1px dashed ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const TabButton = styled(motion.div).attrs((props: SelectableItem) => ({
  ...props,
  whileHover: { scale: 1.05, cursor: "pointer" },
  whileTap: { scale: 0.95 },
  active: props.active
}))`
  width: 100%;
  height: 72px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: ${({ active = false, theme }) =>
    active
      ? `1px solid ${theme.primaryColor[500]}`
      : `1px solid ${theme.primaryColor[800]}`};
  color: ${({ active = false, theme }) =>
    active ? theme.primaryColor[500] : theme.colors.blueGrey[400]};
`;

export const TabButtonContainer = styled.div<{ showTabSections: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
  ${({ showTabSections }) =>
    showTabSections &&
    css`
      @media (max-width: 767px) {
        flex-wrap: wrap;
        ${TabButton} {
          width: calc(50% - 8px);
        }
      }
    `}
`;

export const TabButtonText = styled(Text).attrs(() => ({
  type: "cta"
}))`
  margin-top: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const EditButton = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;
