import styled, { css } from "styled-components";
import { shadow } from "@arcadia/design-system";

export const Wrapper = styled.div`
  position: relative;

  input[type="text"],
  input[type="number"] {
    padding-left: 128px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const OptionsWrapper = styled.ul`
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 16px;
  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 1rem !important;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3} !important;
    border-radius: 1rem !important;
    transition: 300ms ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.accent2} !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    max-height: 260px;
  }
`;

export const Option = styled.li`
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 44px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;

export const CountryCodeButton = styled.div`
  position: absolute;
  top: 32px;
  z-index: 1;
  padding-right: 12px;
  left: 24px;
  display: flex;
  height: 28px;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid ${({ theme }) => theme.colors.coldGrey[900]};
  gap: 12px;
`;

export const CountryCodeDropdown = styled.div<{ fromTop: boolean }>`
  background: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  top: 70px;
  box-shadow: ${shadow[200]};
  z-index: 1;
  border-radius: 8px;
  width: 208px;
  padding: 16px;
  padding-bottom: 4px;
  ${({ fromTop }) =>
    fromTop &&
    css`
      top: unset;
      bottom: 50px;
    `}
`;
