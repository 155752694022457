import { Palette } from "@ds_themes/types";

// TODO è gestito solo in arcadia
export const createLivePillTheme = ({ grey }: Palette) => {
  return {
    livepill: {
      background: grey[50],
      text: grey[1000],
      divider: grey[1000],
      border: `1px solid ${grey[800]}`,
      dropdown: {
        background: grey[1000],
        text: grey[50],
        border: `1px solid ${grey[800]}`,
        hover: {
          background: grey[900]
        }
      },
      scrollbar: {
        background: grey[800],
        hover: {
          background: grey[900]
        }
      }
    }
  };
};
