import { observer } from "mobx-react";
import { useEffect } from "react";
import { useGroupChannel } from "src/js/realtime";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import { useStores } from "../hooks";

const RealtimeGroupSubscribe = () => {
  const { GroupStore } = useStores();

  const { activeGroup } = GroupStore;

  const groupChannel = useGroupChannel(
    GroupStore.activeGroup?.id ? activeGroup : null
  );

  useEffect(() => {
    if (!groupChannel) return () => {};

    const subscriptions = [];
    subscriptions.push(
      groupChannel.subscribe("calendar_event_created", data => {
        RealTimeDispatcher.trigger("calendar_event_created", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("calendar_event_edited", data => {
        RealTimeDispatcher.trigger("calendar_event_edited", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("calendar_event_deleted", data => {
        RealTimeDispatcher.trigger("calendar_event_deleted", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("board_created", data => {
        RealTimeDispatcher.trigger("board_created", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("do_board_edit", data => {
        RealTimeDispatcher.trigger("do_board_edit", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("do_board_publish", data => {
        RealTimeDispatcher.trigger("do_board_publish", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("do_board_unpublish", data => {
        RealTimeDispatcher.trigger("do_board_unpublish", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("board_deleted", data => {
        RealTimeDispatcher.trigger("board_deleted", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("do_board_completed", data => {
        RealTimeDispatcher.trigger("do_board_completed", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("whiteboard_activated_event", data => {
        RealTimeDispatcher.trigger("whiteboard_activated_event", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("whiteboard_deactivated_event", data => {
        RealTimeDispatcher.trigger("whiteboard_deactivated_event", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("general-event", data => {
        RealTimeDispatcher.trigger(data.action.name, data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("conference_deleted_event", data => {
        RealTimeDispatcher.trigger("conference_deleted_event", data);
      })
    );

    subscriptions.push(
      groupChannel.subscribe("resource_preview_generated", data => {
        RealTimeDispatcher.trigger("resource_preview_generated", data);
      })
    );

    return () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  }, [groupChannel]);

  return null;
};

export default observer(RealtimeGroupSubscribe);
