import { DoodleLoader } from "@arcadia/design-system";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import getUserClient from "src/js/modules/connection";
import { createURL } from "src/legacy/modules/utility";
import { useTheme } from "styled-components";
import withBackbone from "with-backbone";
import QuizCollection from "../../../collections/quizzesCollection";
import { __ } from "../../../modules/localization";
import QuizzesReportItem from "./QuizzesReportItem";

const QuizzesReport = ({ exercise }) => {
  const { greyTheme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [quizzesReports, setQuizzesReports] = useState([]);

  useEffect(() => {
    const fetchQuizzesReports = async () => {
      const response = await getUserClient().get<any>(
        createURL("api_exercises_reports_quizzes", {
          exercise_id: exercise.get("id")
        }),
        {
          params: {
            report_by: exercise.isVerification() ? "last" : "best"
          }
        }
      );
      return response?.data;
    };

    setIsLoading(true);
    fetchQuizzesReports()
      .then(result => {
        setQuizzesReports(result.quiz_executions_aggregated);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [exercise]);

  if (isLoading) {
    return (
      <div className="app__box">
        <div className="text-center">
          <div style={{ padding: "12px" }}>
            <DoodleLoader theme={greyTheme} />
          </div>
        </div>
      </div>
    );
  }

  if (quizzesReports?.length === 0) {
    return (
      <div className="app__box">
        <div className="col-xs-12">
          {__("non sono disponibili statistiche per questo esercizio")}
        </div>
      </div>
    );
  }

  const quizzesList = sortBy(exercise.getQuizzes(), q => q.position);
  const quizzes = new QuizCollection(quizzesList);

  return (
    <div className="app__box">
      {quizzes.map((quiz, index) => {
        const report = find(quizzesReports, { quizId: quiz.get("id") });
        if (report?.questions?.length > 0) {
          report.subReports = [];
          report?.questions?.map(question => {
            report.subReports.push(find(quizzesReports, { quizId: question }));
          });
        }
        return <QuizzesReportItem report={report} quiz={quiz} index={index} />;
      })}
    </div>
  );
};

export default withBackbone(QuizzesReport);
