import styled from "styled-components";
import { motion, MotionConfigProps } from "framer-motion";

export const FadeableWrapper = styled(motion.div).attrs(
  (props: MotionConfigProps) => ({
    ...props,
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  })
)``;
