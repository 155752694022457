// eslint-disable-next-line filenames/match-exported
export const GRID_SETTINGS = {
  column_number: 12,
  gutter: 1
};

export const BREAKPOINT_SETTINGS = {
  xs: 1,
  sm: 48, // 768px
  md: 62, // 992px
  lg: 75, // 1200px
  xl: 90 // 1440px
};

export const BREAKPOINT_PIXEL_VALUES = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440
};

export const MAIN_SIDEBAR_SIZE = {
  sm: "255px",
  md: "303px",
  lg: "303px",
  xl: "303px"
};
