import { Model } from "backbone";
import { getActiveGroupId } from "../modules/activeGroup";
import { __ } from "../modules/localization";
import { createURL } from "../modules/utility";

const Editor = ({ textContent }) => {
  const editor = useEditor({
    options: { ...TextEditorOptions, editable: false, content: textContent }
  });

  return (
    <EditorWrapper>
      <BaseTextEditor editor={editor} className="EditorWrapper" />
    </EditorWrapper>
  );
};

class ResourceModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    this.urlRoot = createURL("api_resources");
  }

  initialize() {
    if (this.get("group_id") === undefined) {
      this.set("group_id", getActiveGroupId());
    }
  }

  getBackground() {
    // TODO: eliminare funzione e farla vis css
    return "#8b5cff";
  }

  getTypeLabel() {
    switch (this.get("type")) {
      case "LINK":
        return __("resource_type_google_label");
      case "VIMEO":
        return __("resource_type_vimeo_label");
      case "YOUTUBE":
        return __("resource_type_youtube_label");
      case "FILE":
        return __("resource_type_upload_label");
      case "DROPBOX":
        return __("resource_type_dropbox_label");
      case "GOOGLE_DRIVE":
      case "GOOGLEDRIVE":
        return __("resource_type_gdrive_label");
      case "ONE_DRIVE":
      case "ONEDRIVE":
        return __("resource_type_onedrive_label");
      case "GOOGLE_MAPS":
      case "GOOGLEMAPS":
        return __("resource_type_maps_label");
      case "EXERCISE":
        return __("resource_type_exercises_label");
      case "TEXT":
        return __("resource_type_texteditor_label");
      case "DRAWING":
        return __("resource_type_drawing_label");
      case "IMAGE_GALLERY":
      case "IMAGEGALLERY":
      case "IMAGE":
        return __("resource_type_imagegallery_label");
      case "SLIDESHOW":
        return __("resource_type_slideshow_label");
      case "DOCUMENT":
        return __("resource_type_document_label");
      case "SPREADSHEET":
        return __("resource_type_spreadsheet_label");
      default:
        return "";
    }
  }
}

export default ResourceModel;
