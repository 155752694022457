import React from "react";
import getUserClient from "src/js/modules/connection";
import { formatBytes } from "src/js/modules/commonFunction";
import DragAndDropArea from "src/js/components/DragAndDropArea";
import {
  api_resource_create,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import ResourceImageGalleryPreview from "src/js/pages/resources/ResourceImageGalleryPreview";
import {
  Button,
  ProgressBar,
  SpecificError,
  ContextualAlert,
  ConfirmationAlert
} from "@arcadia/design-system";
import { getActiveGroupId } from "Legacy/modules/activeGroup";
import { __ } from "Legacy/modules/localization";
import { withTheme } from "styled-components";
import { translateString } from "src/js/translation/TranslationProvider";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import { __ICON_UPLOAD__ } from "src/js/settings/settingsImage";

function chooseFile() {
  document.getElementById("fileOrigin").click();
}

class MultipleResourceUploader extends React.Component {
  constructor(props) {
    super(props);
    const { resource } = props;
    const resourceFile =
      resource && resource.type === "IMAGE_GALLERY" ? resource.images : [];

    this.state = {
      loaded: 0,
      error: "",
      collectionFiles: resourceFile,
      statusLoading: false
    };

    this.collectionTitle = React.createRef();

    this.checkMimeType = this.checkMimeType.bind(this);
    this.checkFileSize = this.checkFileSize.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.resetUpload = this.resetUpload.bind(this);
    this.sendFile = this.sendFile.bind(this);
    this.sendCollection = this.sendCollection.bind(this);
    this.deleteCollectionElement = this.deleteCollectionElement.bind(this);
  }

  onChangeHandler = event => {
    const { target } = event;
    const { collectionFiles } = this.state;
    for (let i = 0; i < target.files.length; i += 1) {
      if (
        this.checkMimeType(target.files[i]) &&
        this.checkFileSize(target.files[i])
      ) {
        collectionFiles.push(target.files[i]);
      }
    }
    this.setState({
      collectionFiles,
      loaded: 0,
      error: ""
    });
  };

  handleDrop = event => {
    this.onChangeHandler(event);
  };

  checkMimeType = file => {
    const { mimeArray } = this.props;
    const types = mimeArray;
    if (mimeArray === "*") {
      return true;
    }
    if (types.every(type => file.type !== type)) {
      this.setState({
        error: "file_extension_unsupported_error"
      });
      return false;
    }
    return true;
  };

  checkFileSize = file => {
    const { maxSize } = this.props;
    const size = maxSize;
    if (file.size > size) {
      this.setState({
        error: "file_size_error"
      });
      return false;
    }
    return true;
  };

  deleteCollectionElement(file) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const { collectionFiles } = self.state;
    let result;
    if (file.id) {
      result = collectionFiles.filter(res => {
        return res.id !== file.id;
      });
    } else {
      result = collectionFiles.filter(res => {
        return res.name !== file.name;
      });
    }
    this.setState({
      collectionFiles: result
    });
  }

  resetUpload() {
    const { resource, mode, backFunc, ConfirmDialogStore, theme } = this.props;
    const { whiteTheme } = theme;

    ConfirmDialogStore.openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={__("Sei sicuro di voler interrompere il caricamento?")}
        declineText={__("Annulla")}
        onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
        acceptText={__("Conferma")}
        onAcceptFunction={() => {
          if (mode === "edit") {
            backFunc();
          } else {
            document.getElementById("fileOrigin").value = "";
            this.setState({
              loaded: 0,
              error: "",
              collectionFiles: resource || [],
              statusLoading: false
            });
          }
          ConfirmDialogStore.closeConfirmDialog();
        }}
      />
    );
  }

  sendFile(file) {
    const data = new FormData();
    data.append("file", file);
    data.append("type", "FILE");
    data.append("name", file.name);
    data.append("group_id", getActiveGroupId());
    return getUserClient()
      .post(
        getVersionedApiUrl(api_resource_create("api_resource_create")),
        data,
        {
          onUploadProgress: ProgressEvent => {
            this.setState({
              statusLoading: true,
              loaded: parseInt(
                (ProgressEvent.loaded / ProgressEvent.total) * 100,
                10
              )
            });
          }
        }
      )
      .then(res => {
        this.setState({
          statusLoading: false
        });
        return res.data;
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  }

  sendCollection() {
    const { successCallback, errorCallback, type, title } = this.props;
    const { collectionFiles } = this.state;
    const resourceCollection = [];
    const collectionTitle =
      this.collectionTitle.current !== null
        ? this.collectionTitle.current.value
        : title;
    const promises = [];
    for (let i = 0; i < collectionFiles.length; i += 1) {
      if (typeof collectionFiles[i].id === "undefined") {
        promises.push(
          this.sendFile(collectionFiles[i]).then(response => {
            resourceCollection.push(response.id);
          })
        );
      } else {
        resourceCollection.push(collectionFiles[i].id);
      }
    }

    Promise.all(promises).then(() => {
      const data = {
        name: collectionTitle,
        group_id: getActiveGroupId(),
        type: type || "IMAGE_GALLERY",
        image_ids: resourceCollection
      };
      getUserClient()
        .post(
          getVersionedApiUrl(api_resource_create("api_resource_create")),
          data,
          {
            onUploadProgress: ProgressEvent => {
              this.setState({
                loaded: parseInt(
                  (ProgressEvent.loaded / ProgressEvent.total) * 100,
                  10
                )
              });
            }
          }
        )
        .then(res => {
          this.setState({
            statusLoading: false
          });
          successCallback(res.data);
        })
        .catch(() => {
          errorCallback();
        });
    });
  }

  render() {
    const {
      resource,
      placeholder,
      maxSize,
      mimeArray,
      uploadText,
      buttonText,
      title,
      theme
    } = this.props;
    const { greyTheme, whiteTheme } = theme;
    const { error, statusLoading, loaded, collectionFiles } = this.state;
    const submitText = __(buttonText) || __("save");
    let allowedFile = "*";
    if (mimeArray.length > 0) {
      allowedFile = mimeArray.valueOf();
    }
    const collectionTitle = title || "";
    let errorMessage = "";
    if (error !== "") {
      errorMessage = <SpecificError theme={whiteTheme} text={__(error)} />;
    }
    return (
      <div className="resource-uploader">
        <div className="text-center">
          {collectionFiles.length > 0 && typeof resource === "undefined" ? (
            <div className="form-group">
              <label htmlFor="collectionTitle" className="control-label">
                {__("resource_gallery_default_title")}
                {/* <Translate text="resource_gallery_default_title" /> */}
                <input
                  id="collectionTitle"
                  type="text"
                  name="collectionTitle"
                  className="form-control light margin-top-10"
                  defaultValue={collectionTitle}
                  ref={this.collectionTitle}
                />
              </label>
            </div>
          ) : (
            ""
          )}
          <input
            id="fileOrigin"
            type="file"
            multiple
            name="fileOrigin"
            className="hidden"
            accept={allowedFile}
            placeholder={placeholder}
            onChange={this.onChangeHandler}
          />
          {collectionFiles.length < 1 && (
            <DragAndDropArea handleDrop={this.handleDrop}>
              <div className="resource-drag-area">
                <div className="resource-uploader-icon" onClick={chooseFile}>
                  <img alt="upload-icon" src={__ICON_UPLOAD__} />
                </div>
                <div className="resource-upload-file-text">
                  {__(uploadText)}
                </div>
                <div className="margin-top-10">
                  <ContextualAlert
                    theme={whiteTheme}
                    text={`${translateString({
                      text: "upload_size_limit"
                    })} ${formatBytes(maxSize)}`}
                  />
                </div>
              </div>
            </DragAndDropArea>
          )}
          {statusLoading ? (
            <ProgressBar theme={greyTheme} percentage={loaded} />
          ) : (
            ""
          )}
          {errorMessage}
        </div>
        {collectionFiles.length > 0 && !statusLoading && (
          <div className="resource-upload-box resource-gallery-box">
            <div className="resource-upload-wrapper">
              <ResourceImageGalleryPreview
                addFunction={chooseFile}
                deleteFunction={this.deleteCollectionElement}
                images={collectionFiles}
              />
            </div>
          </div>
        )}
        {collectionFiles.length > 0 && (
          <div className="flex-centered-column margin-vertical-15">
            <span className="margin-right-10">
              <Button
                onClick={this.resetUpload}
                variant="secondary"
                theme={greyTheme}
              >
                {__("Annulla")}
              </Button>
            </span>
            <Button
              onClick={this.sendCollection}
              variant="primary"
              theme={greyTheme}
            >
              {submitText}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withTheme(withConfirmDialogStore(MultipleResourceUploader));
