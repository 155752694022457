import React, { forwardRef } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { ActionButton } from "@ds_universal/data_entry/ActionButton";
import { whiteTheme } from "@ds_themes/white";

import * as S from "./SearchBar.styles";
import { SearchBarProps } from "./types";

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      theme,
      id = "searchbar-input-id",
      placeholder = "Default",
      value,
      variant = "default",
      label = "default",
      onChange,
      onClick,
      "data-testid": testId,
      disabled,
      onContainerClick,
      startAdornment,
      endAdornment,
      onFocus,
      onBlur,
      ...rest
    },
    ref
  ) => {
    const handleKeyPress: React.KeyboardEventHandler<
      HTMLDivElement
    > = event => {
      if (!onClick) return;
      if (event.key === "Enter") {
        event.preventDefault();
        onClick(
          event as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>
        );
      }
    };

    let EndAdornmentComponent: React.ReactNode | null = null;

    if (onClick) {
      EndAdornmentComponent =
        variant === "default" ? (
          <ActionButton
            theme={whiteTheme as unknown as DefaultTheme}
            type="submit"
            variant="tertiary"
            icon="search"
            onClick={onClick}
            disabled={disabled}
          />
        ) : (
          <S.SearchButtonThinner onClick={onClick} disabled={disabled}>
            <Icon icon="search" width="14px" height="14px" />
          </S.SearchButtonThinner>
        );
    } else if (endAdornment) {
      EndAdornmentComponent = (
        <S.EndAdornmentWrapper>{endAdornment}</S.EndAdornmentWrapper>
      );
    } else {
      EndAdornmentComponent = (
        <S.IconWrapper>
          <Icon icon="search" width="14px" height="14px" />
        </S.IconWrapper>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <S.Container
          onKeyPress={handleKeyPress}
          onClick={onContainerClick}
          {...rest}
        >
          <label htmlFor={id}>{label}</label>
          <S.Wrapper>
            {startAdornment ? (
              <S.StartAdornmentWrapper>
                {startAdornment}
              </S.StartAdornmentWrapper>
            ) : null}
            <S.SearchBar
              autoComplete="off"
              variant={variant}
              id={id}
              placeholder={placeholder}
              ref={ref}
              value={value}
              onChange={onChange}
              data-id={testId}
              disabled={disabled}
              onFocus={onFocus}
              onBlur={onBlur}
              startAdornment={!!startAdornment}
            />
            {EndAdornmentComponent}
          </S.Wrapper>
        </S.Container>
      </ThemeProvider>
    );
  }
);

SearchBar.displayName = "SearchBar";

export default SearchBar;
