import styled, { css } from "styled-components";
import shadow from "@ds_themes/shadow";
import colors from "@ds_themes/colors";

import {
  SOptionContainerProps,
  SScrollableWrapperProps,
  SWrapperProps
} from "./types";

export const Wrapper = styled.div<SWrapperProps>`
  width: ${({ width }) => width};
  color: ${({ theme }) => theme.dropdown.text};
  position: relative;
  input[type="checkbox"] {
    margin-left: -10px;
  }
  ${({ isCustomized }) =>
    isCustomized &&
    css`
      ${Button}, ${OptionButton} {
        min-height: 37px;
        border-radius: 100px;
      }
      ${Button} {
        display: flex;
        align-items: center;
        padding: 2.5px 50px 2.5px 2.5px;
      }
      ${OptionButton} {
        padding: 2.5px;
      }
    `}
`;

export const Button = styled.button`
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  line-height: normal;
  color: ${({ theme }) => theme.dropdown.text};
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  background: ${({ theme }) => theme.dropdown.backgroundButton};
  border: ${({ theme }) => theme.dropdown.border};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px 50px 10px 20px;
  white-space: nowrap;
  height: fit-content;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  #arrow {
    position: absolute;
    right: 20px;
  }
  &:focus {
    border: ${({ theme }) => theme.dropdown.focus.border};
    outline: ${({ theme }) => theme.dropdown.focus.outline};
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.dropdown.hover.background};
    border: ${({ theme }) => theme.dropdown.hover.border};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.dropdown.disabled.color};
    background: ${({ theme }) => theme.dropdown.disabled.background};
  }
`;

export const OptionsListContainer = styled.div`
  padding: 10px;
`;

export const OptionContainer = styled.div<SOptionContainerProps>`
  position: absolute;
  user-select: none;
  width: 100%;
  margin-top: 8px;
  border: ${({ theme }) => theme.dropdown.border};
  background-color: ${({ theme }) => theme.dropdown.backgroundContainer};
  box-shadow: ${shadow[500]};
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.average};

  ${({ fromTop }) =>
    fromTop &&
    css`
      bottom: 48px;
    `}
`;

export const ScrollableWrapper = styled.div<SScrollableWrapperProps>`
  width: 100%;
  height: ${({ withScrollbar }) => (withScrollbar ? "220px" : "fit-content")};
  overflow-y: ${({ withScrollbar }) => (withScrollbar ? "scroll" : "auto")};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  /* styles to make the scroll bar customized */
  ::-webkit-scrollbar {
    width: 1em;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    border: 0.25em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1em;
    background-color: #c4c4c4;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  label {
    display: none;
  }
`;

export const ResetWrapper = styled.div`
  color: ${colors.grey[50]};
  height: 48px;
  display: flex;
  border-top: solid 1px ${colors.grey[700]};
  width: calc(100% + 2px);
  justify-content: center;
  align-items: center;
`;

export const ResetButton = styled.div`
  display: flex;
  align-self: center;
  padding: 8px 24px;
  color: ${({ theme }) => theme.dropdown.option.text};
  border-radius: 20px;
  > span {
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;

    color: ${({ theme }) => theme.dropdown.option.hover.text};
    background: ${({ theme }) => theme.dropdown.focus.backgroundOptions};
  }
`;

export const OptionButton = styled.button`
  width: 100%;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 30px;
  color: ${({ theme }) => theme.dropdown.option.text};
  border: 0px;
  background: ${({ theme }) => theme.dropdown.option.background};
  display: flex;
  align-items: center;
  justify-content: start;
  white-space: nowrap;
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.dropdown.focus.backgroundOptions};
    outline: none;
    color: ${({ theme }) => theme.dropdown.option.hover.text};
  }
  & span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > span {
    margin-left: 10px;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.dropdown.disabled.color};
    background: ${({ theme }) => theme.dropdown.option.background};
  }
`;

export const EmptyOption = styled.div`
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchInputContainer = styled.div`
  margin: 8px;
`;

/**
 * Dropdown.Option
 */

export const OptionCustom = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 0px;
  & > span {
    margin-left: 10px;
  }
`;
