import React, { useEffect, useState } from "react";
import { BasePaginationParams, GroupInSpaceModel } from "src/js/types";
import { UpdateSingleGroupType } from "../types";
import GroupCard from "./GroupCard";

import * as S from "./GroupList.styles";

type GroupListProps = {
  groupList: GroupInSpaceModel[];
  spaceId: string;
  fetchNextPage: (params: BasePaginationParams & { spaceId: string }) => void;
  hasMoreResults: boolean;
  updateSingleGroupPreference: (params: UpdateSingleGroupType) => void;
  disabled?: boolean;
};

const GroupList = ({
  groupList,
  spaceId,
  hasMoreResults,
  fetchNextPage,
  updateSingleGroupPreference,
  disabled = false
}: GroupListProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!groupList.length) return;
    setIsOverflowing(groupList.length > 4);
  }, [groupList]);

  return (
    <S.ScrollableArea id="group-list" isOverflowing={isOverflowing}>
      <S.GroupList
        scrollableTarget="group-list"
        dataLength={groupList.length}
        next={() => {
          fetchNextPage({
            limit: 10,
            offset: groupList.length,
            spaceId
          });
        }}
        hasMore={hasMoreResults}
        loader={<div />}
      >
        {groupList.map((group, i) => {
          // Open the popover from top, if there's less than 3 elements or for the last 3 elements
          const openTopPopover =
            groupList.length < 4 || groupList.length - i < 4;
          return (
            <GroupCard
              key={group.group.id}
              {...group}
              openTopPopover={openTopPopover}
              updateSingleGroupPreference={updateSingleGroupPreference}
              disabled={disabled}
            />
          );
        })}
      </S.GroupList>
    </S.ScrollableArea>
  );
};

export default GroupList;
