import { ObjectValues } from "src/js/types";

export const AICustomPromptInitialTransformations = {
  TryAgain: "tryAgain",
  Extend: "extend",
  Shorten: "shorten",
  Simplify: "simplify",
  Summarise: "summarise",
  ChangeTone: "changeTone"
} as const;

export const AICustomPromptToneTransformations = {
  Default: "default",
  Academic: "academic",
  Business: "business",
  Casual: "casual",
  Childfriendly: "childfriendly",
  Confident: "confident",
  Conversational: "conversational",
  Creative: "creative",
  Emotional: "emotional",
  Excited: "excited",
  Formal: "formal",
  Friendly: "friendly",
  Funny: "funny",
  Humorous: "humorous",
  Informative: "informative",
  Inspirational: "inspirational",
  Memeify: "memeify",
  Narrative: "narrative",
  Objective: "objective",
  Persuasive: "persuasive",
  Poetic: "poetic"
} as const;

export type AICustomPromptInitialTransformationsValue = ObjectValues<
  typeof AICustomPromptInitialTransformations
>;
export type AICustomPromptToneTransformationsValue = ObjectValues<
  typeof AICustomPromptToneTransformations
>;

export type AICustomPromptTransformationValue =
  | AICustomPromptInitialTransformationsValue
  | AICustomPromptToneTransformationsValue;
