import styled from "styled-components";

export const LegendaContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 33px;
  font-weight: 500;
`;

export const LegendaCircle = styled.span`
  height: 12px;
  width: 12px;
  border: solid 1px ${({ theme }) => theme.primaryColor[500]};
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
`;
