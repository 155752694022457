import React, { useRef } from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button } from "@arcadia/design-system";

const FileUploaderButton = ({
  theme,
  buttonText,
  disabled = false,
  onChangeHandler
}) => {
  const inputRef = useRef();

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        theme={theme}
        onClick={() => inputRef.current.click()}
      >
        <Translate text={buttonText} />
      </Button>

      <input
        ref={inputRef}
        type="file"
        name="file"
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </>
  );
};

export default FileUploaderButton;
