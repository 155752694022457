import styled from "styled-components";
import { Heading } from "@arcadia/design-system";

export const AvatarWrapper = styled.div`
  display: block;
  margin: 8px auto 20px;
  width: max-content;
  zoom: 175%;
`;

export const TitleSection = styled.div`
  margin: 24px 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const SectionHeading = styled(Heading)`
  display: block;
  margin-bottom: 4px;
`;

export const ContentBox = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 16px;
  @media screen and (max-width: 767px) {
    padding: 16px;
    border-radius: 8px;
  }
`;

export const BannerHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;
