import colors from "@ds_themes/colors";

export const self = {
  background: colors.violet[501],
  text: colors.grey[1000],
  hover: {
    background: colors.violet[600]
  },
  timeText: colors.blueGrey[500],
  attachmentBg: colors.grey[700],
  showMore: colors.violet[900],
  deletedMessage: {
    border: colors.violet[700],
    text: colors.violet[600],
    background: colors.grey[1000]
  },
  borderRadius: {
    top: "24px 24px 8px 24px",
    middle: "24px 8px 8px 24px",
    bottom: "24px 8px 24px 24px"
  }
};

export const other = {
  background: colors.blueGrey[900],
  text: colors.grey[50],
  hover: {
    background: colors.blueGrey[800]
  },
  timeText: colors.blueGrey[500],
  attachmentBg: colors.grey[700],
  showMore: colors.violet[900],
  deletedMessage: {
    border: colors.violet[700],
    text: colors.violet[600],
    background: colors.grey[1000]
  },
  borderRadius: {
    top: "24px 24px 24px 8px",
    middle: "8px 24px 24px 8px",
    bottom: "8px 24px 24px 24px"
  }
};
