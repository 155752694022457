import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@arcadia/design-system";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: colors.grey[1000],
    fontFamily: "WeSchoolSans"
  },
  section: {
    padding: 32,
    flexGrow: 1,
    overflow: "hidden",
    border: "10px solid white",
    borderRadius: 16,
    margin: "-5px"
  },
  badgeImage: {
    width: 106,
    height: 106,
    margin: "0px auto",
    objectFit: "cover",
    borderRadius: 4
  },
  badgeName: {
    margin: "16px 0px",
    textAlign: "center",
    fontSize: 32,
    fontWeight: 700
  },
  badgeAwardedTitle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: "4px",
    textAlign: "center"
  },
  awardedUser: {
    marginBottom: "24px",
    textAlign: "center",
    fontSize: 32,
    fontWeight: 700
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "16px",
    marginBottom: 24
  },
  baseText: {
    fontSize: 14,
    fontWeight: 400
  },
  mediumText: {
    fontSize: 14,
    fontWeight: 500
  },
  boldText: {
    fontSize: 14,
    fontWeight: 700
  },
  badgeDescription: {
    textAlign: "center",
    marginBottom: 32,
    fontSize: 12,
    fontWeight: 400
  },
  spaceImage: {
    width: 36,
    height: 36,
    marginBottom: 6,
    borderRadius: "50%",
    marginHorizontal: "auto",
    objectFit: "cover"
  },
  link: {
    fontSize: 12,
    fontWeight: 400
  }
});
