import { Text } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation";
import { PasswordStrengthProps } from "src/js/components/forms/PasswordStrenghtIndicator/type";
import * as S from "./PasswordStrengthIndicator.styles";

const PasswordStrengthIndicator = ({ strength }: PasswordStrengthProps) => {
  const { translate } = useTranslation();
  let passwordStrengthText;
  const passwordStrengthWidth = (100 / 9) * strength;
  switch (true) {
    case strength > 8:
      passwordStrengthText = "password_strong_message";
      break;
    case strength <= 8 && strength > 4:
      passwordStrengthText = "password_normal_message";
      break;
    case strength <= 4:
      passwordStrengthText = "password_weak_message";
      break;
    default:
      passwordStrengthText = "password_weak_message";
  }
  return (
    <>
      <S.Indicator>
        <S.Progress
          data-testid="progress-span"
          initial={{ width: 0 }}
          animate={{
            width:
              passwordStrengthWidth > 100 ? "100%" : `${passwordStrengthWidth}%`
          }}
        />
      </S.Indicator>
      <Text type="captionInfoDetails">{translate(passwordStrengthText)}</Text>
    </>
  );
};

export default PasswordStrengthIndicator;
