import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import map from "lodash/map";
import { DoodleLoader, Button } from "@arcadia/design-system";
import createUrl from "src/js/modules/routing";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { __ } from "../../modules/localization";
import { __EXERCISES_CLONE_LIMIT__ } from "../../settings/settings";
import { getActiveGroupId } from "../../modules/activeGroup";
import { createURL } from "../../modules/utility";
import { navigateTo } from "../../modules/history";

const ExerciseCloner = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          originalType: "ASSIGNMENT"
        };
      },
      getInitialState() {
        return {
          status: "loading",
          exercises: [],
          query: "",
          start: 0,
          completed: false,
          resultSearch: []
        };
      },
      componentDidMount() {
        const self = this;
        self.getData();
        self.preventBackspace();
      },
      getData() {
        const self = this;

        $.ajax({
          type: "GET",
          dataType: "json",
          url: createURL("api_resources_search"),
          data: {
            type: "EXERCISE",
            group_role: "ROLE_TEACHER",
            query: self.state.query,
            start: self.state.start,
            limit: __EXERCISES_CLONE_LIMIT__
          },
          success(ret) {
            const exercisesList = [];
            if (!isEmpty(ret)) {
              each(ret, item => {
                const entry = item.exercise;
                entry.title = item.name;
                exercisesList.push(entry);
              });
              self.setState(
                {
                  status: "",
                  exercises: self.state.exercises.concat(exercisesList),
                  resultSearch: self.state.resultSearch.concat(ret),
                  start: self.state.start + __EXERCISES_CLONE_LIMIT__
                },
                () => {
                  self.focusTextEnd(".exercise-import-search-text");
                }
              );
            } else {
              self.setState(
                {
                  status: "",
                  completed: true
                },
                () => {
                  self.focusTextEnd(".exercise-import-search-text");
                }
              );
            }
          },
          error(ret) {
            self.setState(
              {
                status: "",
                exercises: []
              },
              () => {
                self.focusTextEnd(".exercise-import-search-text");
              }
            );
          }
        });
      },
      focusTextEnd(input) {
        const searchTextLength = $(input).val().length;
        $(input).trigger("focus");
        if (searchTextLength > 0) {
          $(input)[0].setSelectionRange(searchTextLength, searchTextLength);
        }
      },
      preventBackspace(e) {
        $(document).on("keydown", e => {
          if (
            e.keyCode == 8 &&
            e.target.tagName != "INPUT" &&
            e.target.tagName != "TEXTAREA"
          ) {
            e.preventDefault();
          }
        });
      },
      searchExercise: debounce(function () {
        const self = this;
        self.setState(
          {
            query: $(".exercise-import-search-text").val(),
            exercises: [],
            start: 0,
            status: "loading",
            resultSearch: []
          },
          () => {
            self.getData();
          }
        );
      }, 500),
      cloneExercise(exerciseId) {
        const self = this;
        this.setState({ status: "loading" });
        $.ajax({
          type: "POST",
          dataType: "json",
          url: createURL("api_exercises_entities", { exercise_id: exerciseId }),
          data: {
            group_id: getActiveGroupId(),
            type: self.props.originalType
          },
          success(ret) {
            self.setState({ status: "" });
            showToastSuccess({ str: __("Esercizio importato con successo") });
            navigateTo(
              createUrl("exercises_edit", {
                group_id: getActiveGroupId(),
                exercise_id: ret.id
              }),
              { trigger: true }
            );
          },
          error(data) {
            self.setState({ status: "" });
            const errorMsg = extractErrorMessage(
              data,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
          }
        });
      },
      render() {
        const self = this;
        let content = "";
        let list = "";
        let searchBox = "";
        let showMoreButton = "";
        const { greyTheme } = this.props.theme;

        if (self.state.status === "loading") {
          content = <DoodleLoader theme={greyTheme} />;
        } else {
          searchBox = (
            <div className="exercise-import-search-box margin-bottom-10">
              <div>
                <input
                  type="text"
                  className="exercise-import-search-text form-control light"
                  name="importSearchText"
                  placeholder={__("cerca")}
                  onChange={self.searchExercise}
                  defaultValue={self.state.query}
                />
              </div>
            </div>
          );

          if (self.state.exercises.length > 0) {
            list = map(self.state.exercises, (exercise, index) => {
              const groupName = self.state.resultSearch[index].group_name;
              return (
                <a
                  className="list-group-item"
                  onClick={self.cloneExercise.bind(null, exercise.id)}
                >
                  {exercise.title} | <i>{groupName}</i>
                </a>
              );
            });
            if (
              list.length >= __EXERCISES_CLONE_LIMIT__ &&
              !self.state.completed
            ) {
              showMoreButton = (
                <div className="flex-centered-column">
                  <Button
                    onClick={self.getData}
                    variant="secondary"
                    theme={greyTheme}
                  >
                    {__("mostra altro")}
                  </Button>
                </div>
              );
            }

            content = (
              <div className="exercise-import-wrapper">
                {searchBox}
                <div>
                  <p>{__("scegli esercizio o verifica da clonare")}:</p>
                </div>
                <div className="list-group">{list}</div>
                {showMoreButton}
              </div>
            );
          } else {
            content = (
              <div className="exercise-import-wrapper">
                {searchBox}
                <div>
                  <div className="empty-list-message-box">
                    <div className="empty-list-message-wrapper">
                      {__(
                        "Spiacenti, non ci sono esercizi o verifiche disponibili"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        }
        return <div className="exercise-import-box">{content}</div>;
      }
    })
  )
);

export default ExerciseCloner;
