import React from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

const RedirectToLandingUrlOrDefault = ({ location, to }) => {
  const { redirect_url } = queryString.parse(location.search);
  let newUrl = to;
  if (redirect_url) {
    newUrl = decodeURIComponent(redirect_url);
  }
  return <Redirect to={newUrl} />;
};

export default RedirectToLandingUrlOrDefault;
