import styled, { css } from "styled-components";

import { SOptionContainerProps } from "./types";

export const Wrapper = styled.div`
  position: relative;
  button:focus,
  input[type="button"]:focus {
    background-color: ${({ theme }) =>
      theme.dropdownContextual.button.focus.background};
    color: ${({ theme }) => theme.dropdownContextual.button.focus.color};
  }
`;

export const Button = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  background: ${({ theme }) => theme.dropdownContextual.button.background};
  border: 1px solid ${({ theme }) => theme.dropdownContextual.button.border};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px 20px;
  height: fit-content;
  #arrow {
    position: absolute;
    right: 20px;
  }
  &:focus {
    border: 1px solid
      ${({ theme }) => theme.dropdownContextual.button.focus.border};
    outline: 3px solid
      ${({ theme }) => theme.dropdownContextual.button.focus.outline};
  }
`;

export const OptionContainer = styled.div<SOptionContainerProps>`
  position: absolute;
  width: max-content;
  min-width: 175px;
  z-index: ${({ theme }) => theme.zIndex.regular};

  ${({ rightAlignMenu }) =>
    rightAlignMenu &&
    css`
      right: 0;
    `}

  height: ${({ withScrollbar }) => (withScrollbar ? "220px" : "fit-content")};
  overflow-y: ${({ withScrollbar }) => (withScrollbar ? "scroll" : null)};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.dropdownContextual.body.background};
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.dropdownContextual.body.border};
  box-shadow:
    0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 8px;

  button {
    width: 100%;
    cursor: pointer;
    border-radius: 30px;
    border: 0px;
    padding: 10px 10px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.dropdownContextual.option.background};
    display: flex;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
    &:focus {
      background: ${({ theme }) =>
        theme.dropdownContextual.option.focus.background};
      outline: none;
    }
    & svg {
      margin-right: 10px;
    }
  }
  #dividerDropdownContextual {
    margin: 10px 0px;
  }
`;

export const OptionItem = styled.div`
  width: 100%;
`;

export const TextWrapper = styled.div`
  max-width: 195px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
