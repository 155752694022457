import { Button, Heading, SpaceBaseCardSizeType } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC, useEffect } from "react";
import { useStores } from "src/js/hooks";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { HubSpotRegistrationUser } from "src/js/repository/hubspotRepository";
import { TODO } from "src/js/types";
import { SpaceInfoCard } from "../SpaceCards/SpaceInfoCard";
import { ModalLayout } from "../modal/ModalLayout";
import * as S from "./SpaceFreeTrialModal.styles";
import { SpaceFreeTrialModalProps } from "./SpaceFreeTrialModal.types";

const SpaceFreeTrialModal: FC<SpaceFreeTrialModalProps> = ({ closeModal }) => {
  const {
    SpaceStore: { activeSpace },
    UIStore: { isLayoutModeMobile },
    UserStore: { activeUser }
  } = useStores();
  const { whiteTheme } = useTheme();

  const title = translateString({
    text: "space_free_trial_modal_title"
  });
  const titleMobile = translateString({
    text: "space_free_trial_mobile_modal_title"
  });
  const body1 = translateString({
    text: "space_free_trial_modal_body"
  });
  const body2 = translateString({
    text: "space_free_trial_modal_body_2",
    stringVariables: { pricingPageURL: "https://www.weschool.com/pricing" }
  });
  const cta = translateString({
    text: "space_free_trial_modal_cta"
  });

  const { name, badge, defaultBadgeColor } = activeSpace?.space || {};

  useEffect(() => {
    const hubspotUserData = { ...activeUser, terms: true };
    HubSpotRegistrationUser({
      registrationData: hubspotUserData as TODO
    });
  }, []);

  return (
    <ModalLayout
      labels={{ title }}
      closeModal={closeModal}
      mobileProps={{ withAdaptiveHeight: true, withHeaderLabels: false }}
    >
      <S.SpaceFreeTrialModalContainer>
        {isLayoutModeMobile ? (
          <S.SpaceFreeTrialModalMobileHeader>
            <Heading level="5">{titleMobile}</Heading>
          </S.SpaceFreeTrialModalMobileHeader>
        ) : null}
        <S.WrapSpaceInfoCard>
          <SpaceInfoCard
            spaceName={name}
            sizeVariant={SpaceBaseCardSizeType.Big}
            hasTrialLogo
            color={defaultBadgeColor}
            days={15}
            url={badge?.small as string | undefined}
          />
        </S.WrapSpaceInfoCard>
        <S.SpaceFreeTrialModalBody>
          <S.SpaceFreeTrialModalText>{body1}</S.SpaceFreeTrialModalText>
          <S.SpaceFreeTrialModalText>{body2}</S.SpaceFreeTrialModalText>
        </S.SpaceFreeTrialModalBody>
        <S.InputSubmitWrapper>
          <Button
            type="button"
            onClick={closeModal}
            theme={whiteTheme}
            variant="primary"
          >
            {cta}
          </Button>
        </S.InputSubmitWrapper>
      </S.SpaceFreeTrialModalContainer>
    </ModalLayout>
  );
};

export default observer(SpaceFreeTrialModal);
