import { Box, PageNav, Text } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { __DASHBOARD_BADGE_LIST_LIMIT__ } from "src/js/settings/settingsDashboard";
import { useStores } from "src/js/hooks";
import { BadgePanel } from "src/js/components/dashboard/BadgePanel";
import { deleteBadge } from "src/js/repository/badgesRepository";
import { showToastError } from "src/js/modules/messageManager";
import BadgePanelMobile from "src/js/components/dashboard/BadgePanel/BadgePanelMobile";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { iso8601DurationToReadable } from "src/js/modules/conversionFunctions";
import { observer } from "mobx-react";
import { NoResults } from "src/js/components/global/NoResults";
import { SpaceBadge } from "src/js/types";
import * as S from "./SpacesBadgesList.styles";
import AssociateBadgeToGroups from "../components/AssociateBadgeToGroups";
import { SpaceBadgesDeleteModal } from "../SpaceBadgesDeleteModal";
import EmitBadgeToStudents from "../components/EmitBadgeToStudents";
import { SpaceBadgeAssociatedGroupsModal } from "../components/SpaceBadgeAssociatedGroupsModal";
import { SpaceBadgeRewardedUserModal } from "../components/SpaceBadgeRewardesUserModal";

const SpacesBadgesList = ({ editBadge }: { editBadge: () => void }) => {
  const {
    SpaceBadgesListStore: {
      filteredBadges,
      totalBadges,
      offset,
      changePage,
      fetchFilteredBadges
    },
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceId },
    ModalStore: { openModal, closeModal },
    SpaceBadgesStore: {
      setEditingBadge,
      setEmitStudentsDrawerOpen,
      setGroupsDrawerOpen,
      transactionInProgress
    }
  } = useStores();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  const showDeleteBadgeModal = ({ id, name }: { id: string; name: string }) => {
    openModal(() => (
      <SpaceBadgesDeleteModal
        badgeName={name}
        onCloseModal={() => closeModal()}
        onDeleteBadge={() => {
          deleteBadge(id)
            .then(() => {
              fetchFilteredBadges(activeSpaceId);
              closeModal();
            })
            .catch(() => {
              showToastError({
                str: translate("error_delete_space_badge")
              });
            });
        }}
      />
    ));
  };

  const openAssociatedGroupsModal = (id: string) => {
    openModal(() => (
      <SpaceBadgeAssociatedGroupsModal closeModal={closeModal} badgeId={id} />
    ));
  };
  const openRewardedUsersModal = (badge: SpaceBadge) => {
    openModal(() => (
      <SpaceBadgeRewardedUserModal closeModal={closeModal} badge={badge} />
    ));
  };
  const badgeList =
    filteredBadges.length > 0 ? (
      filteredBadges.map(badge => {
        const menuItems: ListItemProps[] = [
          {
            id: `assign-badge-${badge.id}}`,
            label: translate("dashboard_badge_dropdown_associate_group"),
            icon: "windowPlus",
            onClick: () => {
              setEditingBadge(badge);
              setEmitStudentsDrawerOpen(false);
              setGroupsDrawerOpen(true);
            }
          },
          {
            id: `emit-badge-${badge.id}}`,
            label: translate("dashboard_badge_dropdown_emit_badge"),
            icon: "invite",
            isDisabled: transactionInProgress || !badge.associatedGroupsCount,
            onClick: () => {
              setEditingBadge(badge);
              setGroupsDrawerOpen(false);
              setEmitStudentsDrawerOpen(true);
            }
          },
          {
            id: `edit-badge-${badge.id}}`,
            label: translate("dashboard_badge_dropdown_edit_badge"),
            icon: "edit",
            isDisabled: !!badge.awardedUsersCount,
            onClick: () => {
              setEditingBadge(badge);
              editBadge();
            }
          },
          {
            id: `delete-badge-${badge.id}}`,
            label: translate("dashboard_badge_dropdown_delete_badge"),
            icon: "delete",
            isDisabled: !!badge.awardedUsersCount,
            onClick: () => {
              showDeleteBadgeModal({ id: badge.id, name: badge.name });
            }
          }
        ];

        const durationText = badge.duration
          ? iso8601DurationToReadable(badge.duration).reduce((text, item) => {
              let result = text;
              result += translate(item.label, { [item.param]: item.duration });
              return result;
            }, "")
          : translate("dashboard_badge_duration_permanent_label");

        return isLayoutModeMobile ? (
          <BadgePanelMobile
            key={badge.id}
            duration={durationText}
            menuItems={menuItems}
            spaceBadge={badge}
            openGroupsModal={() => {
              openAssociatedGroupsModal(badge.id);
            }}
            openUsersModal={() => {
              openRewardedUsersModal(badge);
            }}
          />
        ) : (
          <BadgePanel
            key={badge.id}
            duration={durationText}
            menuItems={menuItems}
            spaceBadge={badge}
            openGroupsModal={() => {
              openAssociatedGroupsModal(badge.id);
            }}
            openUsersModal={() => {
              openRewardedUsersModal(badge);
            }}
          />
        );
      })
    ) : (
      <Box marginTop={124}>
        <NoResults
          title={translate("dashboard_badge_list_no_results_title")}
          subtitle={translate("dashboard_badge_list_no_results_text")}
        />
      </Box>
    );

  return (
    <>
      <S.BadgeListHeader>
        <S.HeaderContent>
          <S.HeaderDetail>
            <Text type="label">
              {translate("dashboard_badge_list_column_name")}
            </Text>
          </S.HeaderDetail>
          <S.HeaderInfo>
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_privacy")}
              </Text>
            </S.HeaderInfoColumn>
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_association")}
              </Text>
            </S.HeaderInfoColumn>
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_award")}
              </Text>
            </S.HeaderInfoColumn>
            <S.HeaderInfoColumn>
              <Text type="label">
                {translate("dashboard_badge_list_column_duration")}
              </Text>
            </S.HeaderInfoColumn>
            <S.HeaderActionColumn />
          </S.HeaderInfo>
        </S.HeaderContent>
      </S.BadgeListHeader>
      {badgeList}
      <S.PageNavWrapper>
        <PageNav
          theme={greyTheme}
          totalRecords={totalBadges}
          pageLimit={__DASHBOARD_BADGE_LIST_LIMIT__}
          startingOffset={offset}
          onPageChanged={changePage}
          label={{
            previous: translate({ text: "prev" }),
            next: translate({ text: "next" })
          }}
        />
      </S.PageNavWrapper>
      <AssociateBadgeToGroups />
      <EmitBadgeToStudents />
    </>
  );
};

export default observer(SpacesBadgesList);
