import React from "react";
import { Editor } from "@tiptap/core";
import { Icon, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

import * as S from "./AIDiscoveryTooltip.styles";

const AIDiscoveryTooltip = ({ editor }: { editor: Editor }) => {
  const { translate } = useTranslation();
  return (
    !editor.storage.ai?.discovered &&
    editor.isEmpty && (
      <S.ToolTipWrapper>
        <S.ToolTipHeader>
          <S.Heading level="5">{translate("ai_disc_tooltip_title")}</S.Heading>
          <S.IconWrapper
            onClick={() => {
              editor.chain().setAiDiscovered(true).focus().run();
            }}
          >
            <Icon icon="close" width={12} height={12} initialViewbox />
          </S.IconWrapper>
        </S.ToolTipHeader>
        <S.TextWrapper>
          <Text type="body">{translate("ai_disc_tooltip_subtitle")}</Text>
        </S.TextWrapper>
      </S.ToolTipWrapper>
    )
  );
};

export default AIDiscoveryTooltip;
