import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const FormTitle = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  gap: 12px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const DropdownLabel = styled(Text)`
  display: block;
  margin-bottom: 8px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const Divider = styled.hr`
  background: ${({ theme }) => theme.colors.grey[900]};
  height: 1px;
  border: 0;
  margin: 20px 0;
`;

export const PlanCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const PlanCardBody = styled.div`
  padding: 12px 0 8px;
`;
