import { ObjectValues } from "./Common";

interface Image {
  originalUrl: string | null;
  large: string | null;
  medium: string | null;
  small: string | null;
}

export const AssessmentsGradingMethod = {
  Decimal: "DECIMAL",
  Percentage: "PERCENTAGE"
};

export type AssessmentsGradingMethodType = ObjectValues<
  typeof AssessmentsGradingMethod
>;

export interface CustomizationSettings {
  defaultBadgeColor: number | null;
  badge: Image | null;
  spaceCover: Image | null;
  autoApproveTutor: boolean;
  ctaFillColor: string | null;
  ctaSecondaryFillColor?: string | null;
  layoutColor: string | null;
  whiteLabelLogo: Image | null;
  whiteLabelName: string | null;
  assessmentsGradingMethod: AssessmentsGradingMethodType | null;
  assessmentsCompletionThreshold: number | null;
}
