import styled from "styled-components";

export const TooltipWrapper = styled.div`
  .rc-tooltip-inner {
    padding: 8px 12px;
    color: ${({ theme }) => theme.colors.grey[1000]};
    background-color: ${({ theme }) => theme.primaryColor[500]};
    border-radius: 8px;
    min-height: 22px;
    white-space: nowrap;
  }
  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${({ theme }) => theme.primaryColor[500]};
  }
  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${({ theme }) => theme.primaryColor[500]};
  }
`;
