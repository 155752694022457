import styled from "styled-components";

export const EventRecipientsListWrap = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const EventRecipientsNames = styled.div`
  flex: 1;
  margin-left: 8px;
  margin-top: 5px;
`;

export const ShowMore = styled.span`
  cursor: pointer;
`;
