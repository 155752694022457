import { Location } from "history";
import React from "react";
import { useLocation } from "react-router";
import { useStores } from "src/js/hooks";
import { UserAccountDeleteModal } from "src/js/pages/user/UserAccountDeleteModal";
import { UserAccountChangeEmailModal } from "src/js/pages/user/settings/UserAccountChangeEmailModal";
import { useAccountChangeHandler } from "src/js/pages/user/useAccountChangeHandler";
import { __FORM_EMAIL_EDIT_SETTINGS__ } from "src/js/pages/user/utils";
import { UserAccountChangePasswordModal } from "../UserAccountChangePasswordModal";
import { UserAccountChangeNameModal } from "../settings/UserAccountChangeNameModal";
import * as S from "./UserAccountMobile.styles";
import { getPath } from "./utils";

const UserAccountMobile = () => {
  const { UserStore } = useStores();
  const location = useLocation<Location>();
  const path = getPath(location);
  const {
    changePasswordHandler,
    deleteUserAccountHandler,
    emailChangeHandler
  } = useAccountChangeHandler();

  const renderContent = () => {
    switch (path) {
      case "delete":
        return (
          <UserAccountDeleteModal
            closeModal={() => {
              return true;
            }}
            userEmail={UserStore.activeUser.email}
            successCallback={deleteUserAccountHandler}
            isSSOAccount={UserStore.activeUser.isSingleSignOnAccount}
            displayAsModal={false}
          />
        );
      case "email":
        return (
          <UserAccountChangeEmailModal
            settings={__FORM_EMAIL_EDIT_SETTINGS__}
            confirm={(email: string, password: string) =>
              emailChangeHandler(email, password, true)
            }
          />
        );
      case "password":
        return (
          <UserAccountChangePasswordModal
            displayAsModal={false}
            handleSubmission={changePasswordHandler}
          />
        );
      case "name":
        return <UserAccountChangeNameModal />;
      default:
        return <div />;
    }
  };

  return <S.Container>{renderContent()}</S.Container>;
};

export default UserAccountMobile;
