import { __MATHJAX_URL__ } from "src/js/settings/settingsGeneral";

const libId = "mathjax-ajax";

function loadMathjaxScript(callback) {
  const script = document.createElement("script");
  script.id = libId;
  script.type = "text/javascript";
  script.src = __MATHJAX_URL__;

  if (script.readyState) {
    // only required for IE <9
    script.onreadystatechange = function onreadystatechange() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = function onload() {
      callback();
    };
  }

  document.getElementsByTagName("head")[0].appendChild(script);
}

function checkLatex(target) {
  let result = false;

  if (typeof target !== "undefined") {
    if (
      target.indexOf("$") > -1 ||
      target.indexOf("\\(") > -1 ||
      target.indexOf("\\[") > -1 ||
      target.indexOf("begin") > -1 ||
      target.indexOf("<math xmlns") > -1
    ) {
      result = true;
    }
  }

  return result;
}

function doRenderLatex() {
  const target = ".mathjax-container";

  if (
    typeof window.MathJax !== "undefined" &&
    typeof window.MathJax.Hub !== "undefined"
  ) {
    // eslint-disable-next-line no-undef
    $(target).each(function targetEach() {
      // eslint-disable-next-line no-undef
      if (checkLatex($(this).html())) {
        // eslint-disable-next-line no-undef
        window.MathJax.Hub.Queue([
          "Typeset",
          window.MathJax.Hub,
          // eslint-disable-next-line no-undef
          $(this)[0]
        ]);
      }
    });
  }
}

function renderLatex() {
  if (typeof window.MathJax === "undefined") {
    window.MathJax = {
      tex2jax: {
        inlineMath: [
          ["$", "$"],
          ["\\(", "\\)"]
        ],
        processEscapes: true
      }
    };
  }
  if (document.getElementById(libId) == null) {
    loadMathjaxScript(() => {
      doRenderLatex();
    });
  } else {
    doRenderLatex();
  }
}

export { renderLatex as default };
