export const { __API_AUTH_PATH__ } = process.env;
export const { __API_EXTERNAL_AUTH_PATH__ } = process.env;
export const { __API_OAUTH_PATH__ } = process.env;
export const __API_UNVERSIONED_PATH__ = process.env.__API_PATH_DOMAIN__;

export const __API_PATH__ = `${__API_UNVERSIONED_PATH__}v1/`;
export const __API_V2_PATH__ = `${__API_UNVERSIONED_PATH__}v2/`;

export const __APP_ROOT_DOMAIN__ = window.location.host.substring(
  window.location.host.indexOf("."),
  window.location.host.length
);

export const { __APP_VERSION_JSON_URL__ } = process.env;

export const __URL_BASE__ = `${window.location.origin}/`;

export const __REDIRECT_IN_PLATFORM_BASE_URL__ =
  process.env.__PLATFORM_BASE_URL__;

export const __LINK_URL_BASE__ = `${window.location.origin}/`;

export const __URL_OAUTH_REDRECT__ = `${window.location.origin}/`;

export const __OAUTH_REDIRECT_URL__ = __API_UNVERSIONED_PATH__;

export const { __FEATURE_FLAG_JSON_URL__ } = process.env;

export const __JITSI_PATH__ = `${__API_UNVERSIONED_PATH__}conference/redirect/`;

export const { __LANG_JSON_URL__ } = process.env;

export const { __UNIVERSITY_JSON_URL__ } = process.env;

export const __DEA_OAUTH_URL__ =
  "https://auth.staging.a.deascuola.it/oauth/authorize";

export const __SCORM_PLAYER_URL__ = process.env.__API_SCORM_PLAYER__;

export const __ANALYTICS_BASE_URL__ = process.env.__API_ANALYTICS_PATH__;

export const { __API_SESSION_PING_PATH__ } = process.env;

export const { __SPACE_PLANS_JSON_URL__ } = process.env;

export const { __ITALIAN_SCHOOL_JSON_URL__ } = process.env;

export const __ENTERPRISE_PLAN_URL__ = "https://lms.weschool.com/book-a-call";
