import { Heading, colors, shadow } from "@arcadia/design-system";
import { Drawer } from "src/js/components/Drawer";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import styled, { css } from "styled-components";

type Active = {
  isActive: boolean;
  withHover?: boolean;
};

export const StyledDrawer = styled(Drawer)<{ open: boolean }>`
  padding: 0px;
  color: ${({ theme }) => theme.colors.grey[50]};

  ${({ open }) =>
    open &&
    css`
      box-shadow: -5px 0px 10px rgb(0 0 0 / 5%);
    `};
`;

export const DrawerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

export const DrawerTitle = styled(Heading)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const BadgeImage = styled.img`
  height: 32px;
  margin-right: 16px;
  margin-left: 12px;
  border-radius: 4px;
`;

export const BadgeImageInReview = styled.img`
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 4px;
`;

export const DrawerBodyContainer = styled.div`
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    height: 100%;
  }
`;

export const ScrollableArea = styled(ScrollableDiv)`
  padding: 24px;
`;

export const BadgeRelatedGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BadgeRelatedGroup = styled.div<Active>`
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.primaryColor[500] : theme.colors.grey[800]};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 16px 22px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.primaryColor[700]};
      }
    `}
`;

export const BadgeImageAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GroupImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  object-fit: cover;
`;

export const DrawerFooter = styled.div`
  box-shadow: ${shadow[700]};
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: normal;
    gap: 16px;
  }
`;

export const SelectedGroups = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const GroupImagesContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 6px;
  }

  & > div:nth-child(1) {
    z-index: 1;
  }

  & > div:nth-child(2) {
    z-index: 2;
    margin-left: -16px;
  }

  & > div:nth-child(3) {
    z-index: 3;
    margin-left: -16px;
  }
`;

export const HeaderIconContainer = styled.div`
  cursor: pointer;
  padding: 8px;
  display: flex;
`;

export const ReviewSelectedGroupsContainer = styled.div`
  padding-top: 8px;
`;

export const GroupAssociatedImage = styled.img`
  margin-bottom: 12px;
  width: 72px;
  object-fit: cover;
  border-radius: 4px;
`;

export const SuccessMessage = styled(Heading)`
  text-align: center;
  padding: 0px 24px;
`;

export const ReviewButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  & > button {
    flex: 1;
  }
`;

export const GroupStatus = styled.div<{ isRemoved: boolean }>`
  display: flex;
  justify-content: center;
  padding: 6px 4px;
  border-radius: 4px;
  background-color: ${({ isRemoved }) =>
    isRemoved ? colors.warning[900] : colors.success[900]};
  color: ${({ isRemoved }) =>
    isRemoved ? colors.warning[500] : colors.success[400]};
`;
