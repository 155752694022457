import React from "react";
import { ThemeProvider, useTheme } from "styled-components";

import { Translate } from "src/js/translation/TranslationProvider";
import { Heading, Text } from "@arcadia/design-system";
import * as S from "./AnalyticsIndexBox.styles";

const AnalyticsIndexBox = ({ value, subject, subtitle }) => {
  const { greyTheme } = useTheme();
  if (!subject || !subtitle) return null;

  return (
    <ThemeProvider theme={greyTheme}>
      <S.BoxContainer>
        <S.ValueContainer>
          <Heading level="1">{value}</Heading>
        </S.ValueContainer>
        <S.SubjectContainer>
          <Text type="headerTitle">
            <Translate text={subject} />
          </Text>
        </S.SubjectContainer>
        <S.SubtitleContainer>
          <Text type="captionInfoDetails">
            <Translate text={subtitle} />
          </Text>
        </S.SubtitleContainer>
      </S.BoxContainer>
    </ThemeProvider>
  );
};

export default AnalyticsIndexBox;
