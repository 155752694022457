import { Button } from "@arcadia/design-system";
import { MainContainer } from "src/js/components/layout/MainContainer";
import styled from "styled-components";

export const MobileButtonDelete = styled(Button)`
  margin-top: 28px;
`;

export const MobileMainContainer = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MobileViewWrapper = styled.div`
  margin-bottom: auto;
`;

export const MobileLayoutWrapper = styled.div`
  height: 100%;
`;
