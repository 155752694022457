/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";
import { NewThreadPage } from "./NewThreadPage";
import { ThreadPage } from "./ThreadPage";

export const SpacesChatRouter = () => {
  return (
    <Switch>
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceChat.new}
        key="chatNew"
        component={NewThreadPage}
      />
      <Route
        path={WeSchoolRoutes.App.Spaces.SpaceChat.threadId}
        key="chatThread"
        exact
        component={ThreadPage}
      />
      <Route
        render={props => (
          <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
        )}
      />
    </Switch>
  );
};
