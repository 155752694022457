import {
  PopoverStyle,
  Popover as NonStyledPopover
} from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Popover = styled(NonStyledPopover)`
  ${PopoverStyle.ContentWrapper} {
    transform-origin: top right;
    z-index: ${({ theme }) => theme.zIndex.toTheSky};
    right: 0;
    margin-top: 16px;
    padding: 8px;

    @media screen and (max-width: 767px) {
      right: 16px;
    }

    input[type="radio"] {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 767px) {
    position: static;
  }
`;

export const FilterButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  span {
    margin-right: 16px;
    width: max-content;
  }
`;

export const IconWrapper = motion(styled.div`
  display: flex;
`);

export const PopoverContentWrapper = styled.div`
  width: 200px;

  label:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }

  label {
    transition: 0.3s;
    border-radius: 34px;
    padding: 8px 16px;
    margin-right: 0;
  }
`;
