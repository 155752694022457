import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { Text, Icon, LiveTag } from "@arcadia/design-system";
import {
  EventCategory,
  EventCategoryType,
  EventStatusType
} from "src/js/pages/calendar/Calendar.types";
import { useTheme } from "styled-components";
import * as S from "./CalendarTag.styles";

type CalendarTagProps = {
  type: EventCategoryType;
  status?: EventStatusType;
};

const CalendarTag = ({ type, status }: CalendarTagProps) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  switch (type) {
    case EventCategory.Live:
      return <LiveTag status={status} theme={whiteTheme} />;
    case EventCategory.Activity:
      return (
        <S.TagContainer status={status} type={type}>
          <Icon width="12" height="12" icon="bulletedList" />
          <Text type="captionInfoDetails">
            {translate("event_activity_label")}
          </Text>
        </S.TagContainer>
      );
    case EventCategory.Deadline:
      return (
        <S.TagContainer status={status} type={type}>
          <Icon width="12" height="12" icon="stopWatchSolid" />
          <Text type="captionInfoDetails">
            {translate("event_deadline_label")}
          </Text>
        </S.TagContainer>
      );
    default:
      return <LiveTag status={status} theme={whiteTheme} />;
  }
};

export default CalendarTag;
