import React from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

const SetLandingUrlAndRedirect = ({ location, to }) => {
  let newUrl = to;
  const excludedUrlArray = ["/logout", "/"];
  if (!excludedUrlArray.includes(location.pathname)) {
    newUrl = queryString.stringifyUrl({
      url: to,
      query: {
        redirect_url: encodeURIComponent(location.pathname + location.search)
      }
    });
  }
  return <Redirect to={newUrl} />;
};

export default SetLandingUrlAndRedirect;
