import { Text, VariantType, scrollbarMixin } from "@arcadia/design-system";
import styled from "styled-components";

export const NewModuleButton = styled.button`
  margin: 0px;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme.primaryColor[500]};
  padding: 16px 0px;
  border-radius: 8px;
  margin-bottom: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor[900]};
  }
`;

export const CustomText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const ScrollableArea = styled.div`
  height: 100%;
  max-height: calc(100% - 132px);
  overflow: auto;
  padding-right: 8px;
  ${scrollbarMixin(VariantType.White)}

  @media (max-width:767px) {
    max-height: calc(100dvh - 308px);
    padding-right: 0px;

    overflow: scroll;
    margin-right: -8px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  padding-top: 16px;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    position: sticky;
    bottom: 16px;
    align-items: stretch;
    > button {
      width: 100%;
    }
  }
`;
