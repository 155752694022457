import colors from "@ds_themes/colors";
import { InputThemeOptions, Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

import { createSpecificErrorTheme } from "./specificError";

export const createInputTheme = (
  palette: Palette
): { input: InputThemeOptions } => {
  const { appearence, general, primary, success, error, grey, blueGrey } =
    palette;

  return {
    input: {
      ...createSpecificErrorTheme(palette),
      background: general.background,
      text: general.contrastText,
      textPlaceholder:
        appearence === "heavy"
          ? getHighContrastColor(
              general.background,
              blueGrey[500],
              blueGrey[500]
            )
          : getHighContrastColor(
              general.background,
              blueGrey[500],
              blueGrey[500]
            ),
      label: general.contrastText,
      border: `1.5px solid ${general.background}`,
      hover: {
        background:
          appearence === "heavy" ? primary.lighter : general.background,
        border: `1.5px solid ${
          appearence === "heavy" ? primary.lighter : grey[800]
        }`
      },
      focus: {
        background: general.background,
        outline: `2px solid ${primary.lighter}`,
        border: `1.5px solid ${
          appearence === "heavy" ? primary.main : primary.light
        }`
      },
      required: {
        background: colors.yellow[500],
        text: colors.violet[501],
        border: `${colors.yellow[500]} solid 1.5px`
      },
      disabled: {
        background: general.background,
        text: getHighContrastColor(
          general.background,
          blueGrey[800],
          blueGrey[500]
        )
      },
      error: {
        text: error[300],
        border: `1.5px solid ${error[300]}`
      },
      success: {
        text: success[500],
        border: `1.5px solid ${success[500]}`
      },
      font: {
        style: "normal",
        weight: "400",
        size: "16px",
        lineHeight: "19px"
      }
    }
  };
};
