/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import LogoutPage from "src/js/pages/App/LogoutPage/LogoutPage";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { JoinPage } from "../JoinPage";
import { GroupCreateSelection } from "./GroupCreateSelectionPage";
import { GroupCreation } from "./GroupCreationPage";
import { WeschoolTemplateCreation } from "./WeschoolTemplateCreationPage";
import { Join } from "./JoinPage";
import { MarketingData } from "./MarketingDataPage";
import { MembersInvite } from "./MembersInvitePage";
import RedirectToOnboarding from "./RedirectToOnboarding";
import { ResumeOnboarding } from "./ResumeOnboardingPage";
import { ResumeSpace } from "./ResumeSpacePage";
import { SchoolSpaceConfirmation } from "./SchoolSpaceConfirmationPage";
import { SchoolSpaceCreation } from "./SchoolSpaceCreationPage";
import { SpaceCreation } from "./SpaceCreationPage";
import { Welcome } from "./WelcomePage";
import { SchoolSpaceCreationProvider } from "./utils/SchoolSpaceCreationProvider";

export const OnboardingRouter = () => {
  return (
    <SchoolSpaceCreationProvider>
      <Switch>
        <Route
          path={WeSchoolRoutes.Onboarding.initOnboarding}
          key="onboarding"
          component={RedirectToOnboarding}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.resumeOnboarding}
          key="onboarding"
          component={ResumeOnboarding}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.resumeSpace}
          key="onboarding"
          component={ResumeSpace}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.welcome}
          key="onboarding"
          component={Welcome}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.marketingData}
          key="onboarding"
          component={MarketingData}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.spaceCreation}
          key="onboarding"
          component={SpaceCreation}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.groupCreateSelection}
          key="onboarding"
          component={GroupCreateSelection}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.groupCreation}
          key="onboarding"
          component={GroupCreation}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.weschoolTemplateCreation}
          key="onboarding"
          component={WeschoolTemplateCreation}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.membersInvite}
          key="onboarding"
          component={MembersInvite}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.schoolSpaceCreation}
          key="OnboardingSpaceCreation"
          component={SchoolSpaceCreation}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.schoolSpaceConfirmation}
          key="OnboardingSpaceConfirmation"
          component={SchoolSpaceConfirmation}
        />
        <Route
          path={WeSchoolRoutes.Onboarding.join}
          key="OnboardingJoin"
          component={Join}
        />
        <Route
          path={WeSchoolRoutes.App.joinCodiceOption}
          component={JoinPage}
        />
        <Route path={WeSchoolRoutes.Onboarding.logout} component={LogoutPage} />
        <Route
          component={() => <Redirect to={createUrl("onboarding_init")} />}
        />
      </Switch>
    </SchoolSpaceCreationProvider>
  );
};
