import { DoodleLoader, Icon, colors } from "@arcadia/design-system";
import styled from "styled-components";

export const VideoPlayerWrapper = styled.div<{
  hideVideo: boolean;
  ratio: string;
}>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
  aspect-ratio: ${({ ratio }) => ratio};
  background-color: ${colors.violet[800]};
  video {
    visibility: ${({ hideVideo }) => (hideVideo ? "hidden" : null)};
  }
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledDoodleLoader = styled(DoodleLoader)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
