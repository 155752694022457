import React, { FC } from "react";

import * as S from "./SoundWave.styles";

const SoundWave: FC = () => {
  return (
    <S.SoundWave>
      <S.Bar />
      <S.Bar />
      <S.Bar />
      <S.Bar />
      <S.Bar />
      <S.Bar />
      <S.Bar />
    </S.SoundWave>
  );
};

export default SoundWave;
