import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { Text } from "@arcadia/design-system";
import { basePageModel } from "../../../modules/pageStatus";
import { __ } from "../../../modules/localization";

const ExerciseEngineProgressBar = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        current: 0,
        /** @type {Number} The current progress */ num: 0,
        /** @type {Number} The max progress AKA number of steps */
        showText: true,
        /** @type {Boolean} Check if label must be shown */
        showCurrentProgress: true,
        /** @type {Boolean} Check if current progress must be shown */
        addCustomClass: "",
        /** @type {String} Optional custom class to add */
        customText:
          "" /** @type {String} Optional custom text to be displayed */
      };
    },
    render() {
      const { current } = this.props;
      const { num } = this.props;

      const perc = 100 / num;
      let currentWidth = (current + 1) * perc;
      if (currentWidth > 100) currentWidth = 100;

      const containerClass =
        basePageModel.get("breakpoint") === "smartphone"
          ? "container"
          : "container-fluid";

      const addPastStyle = {
        width: `${current * perc}%`
      };

      const addCurrentStyle = {
        width: `${currentWidth}%`
      };

      /** remove border when current is 0 */
      if (current === 0) {
        addPastStyle.border = 0;
      }

      /** change border radius when doing last quiz */
      if (current + 1 === num) {
        addCurrentStyle.borderRadius = "35px";
      }

      /** hide current progress indicator if needed */
      if (this.props.showCurrentProgress === false) {
        addCurrentStyle.display = "none";
      }

      let text = __("quiz_n_of", { current: current + 1, num });
      if (this.props.customText.length > 0) {
        text = this.props.customText;
      }

      return (
        <div
          className={["exen__progress-wrapper", this.props.addCustomClass].join(
            " "
          )}
        >
          <div className="exen__progress-past" style={addPastStyle} />
          <div className="exen__progress-current" style={addCurrentStyle} />
          <div className="exen__progress-all" />
          <div className="exen__progress-text">
            <Text type="body">{this.props.showText ? text : null}</Text>
          </div>
        </div>
      );
    }
  })
);

export default ExerciseEngineProgressBar;
