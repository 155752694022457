import { QUIZ_TYPE, QuizType } from "../types";

export const getExerciseIconString = (type: QuizType) => {
  switch (type) {
    case QUIZ_TYPE.TRUEFALSE:
      return "pickertest-truefalse";
    case QUIZ_TYPE.SHORTANSWER:
      return "pickertest-shortanswer";
    case QUIZ_TYPE.OPENANSWER:
      return "pickertest-openanswer";
    case QUIZ_TYPE.MULTICHOICE:
      return "pickertest-multipleanswers";
    case QUIZ_TYPE.CROSSWORDS:
      return "pickertest-crosswordpuzzle";
    case QUIZ_TYPE.VIDEO:
      return "pickertest-videoquiz";
    case QUIZ_TYPE.FILLBLANK:
      return "pickertest-completesentence";
    case QUIZ_TYPE.MATCHING:
      return "pickertest-matchpairs";
    case QUIZ_TYPE.FADINGMATCHING:
      return "pickertest-matchcard";
    case QUIZ_TYPE.DOCUMENTANSWER:
      return "pickertest-answerwithattachment";
    default:
      return "pickertest-truefalse";
  }
};

export const getResourceIdFromImageUrl = (srcString: string) => {
  const url = new URL(srcString);
  const urlPathPortion = url.pathname.split("/").slice(1);
  return parseInt(urlPathPortion[1], 10);
};

export const getResourcesIdsFromQuiz = (quizTitle: string) => {
  const imagesUrl = quizTitle
    .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
    .map(x => x.replace(/.*src="([^"]*)".*/, "$1"));
  return imagesUrl.map(url => {
    return getResourceIdFromImageUrl(url);
  });
};

export const replaceQuizImageUrl = (
  quizTitle: string,
  id: number,
  newResurceUrl: string
) => {
  const idStr = id.toString();
  const pattern = new RegExp(
    `(<img[^>]*src=["'])([^"']*${idStr}[^"']*)["']`,
    "gi"
  );
  let quizModified = quizTitle;
  if (quizTitle.includes(idStr)) {
    quizModified = quizTitle.replace(pattern, `$1${newResurceUrl}"`);
  }
  return quizModified;
};

export const detectWeschoolImg = (quizTitle: string) => {
  const pattern = /<img[^>]*src=["'][^"']*weschool[^"']*["'][^>]*>/gi;
  const matches = quizTitle.match(pattern);
  if (matches && matches.length > 0) {
    return true;
  }
  return false;
};
