import styled from "styled-components";
import * as Tooltip from "@radix-ui/react-tooltip";

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.grey[100]};
  height: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const StyledTooltipContent = styled(Tooltip.Content)`
  z-index: 9999;
`;
