import React from "react";
import { Box, Text } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";

const EventRecurrenceInfo = ({ visible }: { visible: boolean }) => {
  const { translate } = useTranslation();
  return visible ? (
    <Box marginTop={8} marginBottom={14}>
      <Text type="body">
        {translate("event_recurrence_repetition_number_info")}
      </Text>
    </Box>
  ) : null;
};

export default EventRecurrenceInfo;
