import { Heading } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { useCreateQuickThread } from "src/js/pages/App/Spaces/hooks";
import { SpacesNoGroups } from "src/js/pages/spaces/dashboard/SpacesNoGroups";
import { SpacesRemoveFromGroupsModal } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesRemoveFromGroupsModal";
import SpacesUsersBlocked from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersBlocked";
import { SpacesUsersFilters } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersFilters";
import { SpacesUsersList } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersList";
import { SpacesUsersRolesManager } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersRolesManager";
import { SpacesUsersUrlHandler } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersUrlHandler";
import { Translate } from "src/js/translation/TranslationProvider";
import { ChatEvent, EventDomain, GroupEvent } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";

import * as S from "./SpacesUsers.styles";

const goToUserProfile = uuid => {
  navigateTo(
    createUrl("profile_public", {
      user_uuid: uuid
    })
  );
};

const SpacesUsers = () => {
  const {
    SpacesUsersFilterStore,
    ModalStore,
    SpaceStore,
    SpacesSettingsStore,
    SpacesUsersRolesStore
  } = useStores();
  const {
    spaceGroupsList,
    filteredUsers,
    unfilteredTotalUsers,
    totalUsers,
    paginationOffset,
    filters,
    spaceHasNextGroups,
    spaceShowNextGroups
  } = SpacesUsersFilterStore;
  const { searchQuery, selectedOrder, selectedGroups, selectedRoles } = filters;
  const { activeSpaceId, activeSpaceName } = SpaceStore;
  const { generalSettings } = SpacesSettingsStore;
  const { greyTheme } = useTheme();
  const [isLoading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { navigateToThread } = useCreateQuickThread();

  useEffect(() => {
    if (
      generalSettings?.planSettings?.dashboardUsersEnabled &&
      activeSpaceId &&
      SpacesUsersFilterStore
    ) {
      setLoading(true);
      SpacesUsersFilterStore.fetchSpaceGroups(activeSpaceId).catch(() => {
        showToastError({
          str: <Translate text="error_space_group_list" />
        });
      });
      SpacesUsersFilterStore.fetchUsers()
        .catch(() => {
          showToastError({
            str: <Translate text="error_fetch_space_users" />
          });
        })
        .finally(() => setLoading(false));
    }
    return () => {
      if (activeSpaceId) {
        SpacesUsersFilterStore.reset();
      }
    };
  }, [activeSpaceId, generalSettings]);

  const getUserRoles = userObject => {
    SpacesUsersRolesStore.reset();
    SpacesUsersRolesStore.setSelectedUser(userObject);
    SpacesUsersRolesStore.fetchUserRoles()
      .then(() => {
        ModalStore.openModal(() => (
          <SpacesRemoveFromGroupsModal user={userObject?.user} />
        ));
      })
      .catch(() => {
        showToastError({ str: <Translate text="error_fetch_user_roles" /> });
      });
  };

  const showUsersRolesManager = userObject => {
    setOpenDrawer(true);
    SpacesUsersRolesStore.setSelectedUser(userObject);
    SpacesUsersRolesStore.fetchUserRoles().catch(() => {
      showToastError({ str: <Translate text="error_fetch_user_roles" /> });
    });
  };

  const createNewGroup = () => {
    _trackEvent(EventDomain.Group, GroupEvent.DashboardGroupsEmptyState);
    ModalStore.openModal(() => <GroupCreationModal />);
  };

  const handleToggleEnableUser = ({ remove, group_id }) => {
    SpacesUsersRolesStore.toggleEnableUser({ remove, group_id }).catch(() => {
      showToastError({ str: <Translate text="error_restoring_user" /> });
    });
  };

  const handleDismissDrawer = () => {
    setOpenDrawer(false);
    if (SpacesUsersRolesStore.hasChanges) {
      SpacesUsersFilterStore.fetchUsers().catch(() => {
        showToastError({
          str: <Translate text="error_fetch_space_users" />
        });
      });
    }
    SpacesUsersRolesStore.reset();
  };

  let pageContent = <S.StyledDoodleLoader theme={greyTheme} />;

  if (generalSettings?.planSettings?.dashboardUsersEnabled === false) {
    pageContent = <SpacesUsersBlocked spaceId={activeSpaceId} />;
  }

  if (
    generalSettings?.planSettings?.dashboardUsersEnabled &&
    !isLoading &&
    unfilteredTotalUsers === 0
  ) {
    pageContent = (
      <SpacesNoGroups spaceName={activeSpaceName} newGroup={createNewGroup} />
    );
  }

  if (
    generalSettings?.planSettings?.dashboardUsersEnabled &&
    !isLoading &&
    unfilteredTotalUsers > 0
  ) {
    pageContent = (
      <MainContainer>
        <S.PageContainer>
          <S.TitleWrapper>
            <Heading level="1">
              <Translate text="users" />
            </Heading>
            <S.UsersCounter>{unfilteredTotalUsers}</S.UsersCounter>
          </S.TitleWrapper>
          <SpacesUsersFilters
            groupList={spaceGroupsList}
            groupsHasNext={spaceHasNextGroups}
            groupsShowNext={spaceShowNextGroups}
            roleFilterSelected={selectedRoles}
            groupFilterSelected={selectedGroups}
            sortFilterSelected={selectedOrder}
            searchDefaultValue={searchQuery}
            setFilter={(value, key) =>
              SpacesUsersFilterStore.setFilter(value, key)
            }
            removeStatusFilter={key => {
              SpacesUsersFilterStore.removeKeyFromFilter(key, "selectedGroups");
              SpacesUsersFilterStore.removeKeyFromFilter(key, "selectedRoles");
            }}
            removeAllStatusFilters={() => {
              SpacesUsersFilterStore.resetFilter("selectedGroups");
              SpacesUsersFilterStore.resetFilter("selectedRoles");
            }}
          />
          <SpacesUsersList
            filteredUsers={filteredUsers}
            totalUsers={totalUsers}
            currentOffset={paginationOffset}
            showProfile={uuid => goToUserProfile(uuid)}
            manageGroupsRoles={user => showUsersRolesManager(user)}
            removeFromGroups={user => getUserRoles(user)}
            openChat={uuid => {
              _trackEvent(EventDomain.Chat, ChatEvent.ChatStartDashboardClick);
              navigateToThread(uuid);
            }}
            changePage={page => SpacesUsersFilterStore.changePage(page)}
          />
          <SpacesUsersRolesManager
            userObject={SpacesUsersRolesStore.selectedUser}
            groups={SpacesUsersRolesStore.userRoles}
            changeRole={(role, group_id) =>
              SpacesUsersRolesStore.callChangeUserRole({
                role,
                group_id
              })
            }
            toggleEnabled={(remove, group_id) =>
              handleToggleEnableUser({ remove, group_id })
            }
            errorGroupsId={SpacesUsersRolesStore.errorGroupsId}
            hasRemoveError={SpacesUsersRolesStore.hasRemoveError}
            hasRoleError={SpacesUsersRolesStore.hasRoleError}
            open={openDrawer}
            dismissDrawer={() => handleDismissDrawer()}
            showNext={() => SpacesUsersRolesStore.showNextUserRoles()}
            hasNext={SpacesUsersRolesStore.hasMore}
            isInsideMainLayout
          />
        </S.PageContainer>
      </MainContainer>
    );
  }

  return (
    <>
      <SpacesUsersUrlHandler />
      {pageContent}
    </>
  );
};

export default observer(SpacesUsers);
