import React from "react";
import { Icon } from "@arcadia/design-system";
import { Editor } from "@tiptap/core";
import { EditorExtensionType } from "../BaseTextEditor.types";
import {
  getIconFromExtension,
  getOnClickFromExtension
} from "../BaseTextEditor.utils";

import * as S from "./BaseTextEditorMenu.styles";

const StandardExtensionButton = ({
  editor,
  extension
}: {
  editor: Editor;
  extension: EditorExtensionType;
}) => {
  return (
    <S.Button
      active={editor.isActive(extension)}
      type="button"
      onClick={getOnClickFromExtension(extension, editor)}
      className={editor.isActive(extension) ? "is-active" : ""}
    >
      <Icon
        icon={getIconFromExtension(extension)}
        width={13}
        height={13}
        initialViewbox
      />
    </S.Button>
  );
};

export default StandardExtensionButton;
