import colors from "@ds_themes/colors";
import { ButtonOptions } from "@ds_themes/types";

export const primary: ButtonOptions = {
  background: colors.violet[501],
  text: colors.grey[1000],
  type: "cta",
  border: `1px solid ${colors.violet[501]}`,
  hover: {
    background: colors.grey[1000],
    text: colors.violet[501]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  },
  disabled: {
    background: colors.violet[700],
    text: colors.grey[1000],
    border: `1px solid ${colors.violet[700]}`
  },
  badge: {
    background: colors.violet[501],
    text: colors.grey[1000],
    border: `1px solid ${colors.grey[1000]}`
  }
};

export const secondary: ButtonOptions = {
  background: colors.grey[1000],
  text: colors.grey[50],
  type: "cta",
  border: `1px solid ${colors.grey[50]}`,
  hover: {
    background: colors.grey[50],
    text: colors.grey[1000]
  },
  focus: {
    border: `${colors.grey[700]} solid 3px`
  },
  disabled: {
    background: colors.grey[1000],
    text: colors.grey[800],
    border: `1px solid ${colors.grey[800]}`
  },
  badge: {
    background: colors.violet[501],
    text: colors.grey[1000],
    border: `1px solid ${colors.grey[1000]}`
  }
};

export const secondaryColored: ButtonOptions = {
  background: colors.violet[900],
  text: colors.violet[501],
  type: "cta",
  border: "none",
  hover: {
    background: colors.violet[900],
    text: colors.violet[501],
    border: `1px solid ${colors.violet[501]}`
  },
  focus: {
    border: `1px solid ${colors.violet[501]}`,
    background: colors.violet[700]
  },
  disabled: {
    background: colors.violet[900],
    text: colors.violet[700]
  },
  badge: {
    background: colors.violet[501],
    text: colors.grey[1000],
    border: `1px solid ${colors.grey[1000]}`
  }
};

export const tertiary: ButtonOptions = {
  background: "none",
  text: colors.grey[50],
  type: "ctaTertiary",
  border: "0px",
  hover: {
    background: colors.blueGrey[900],
    text: colors.grey[50]
  },
  focus: {
    border: `${colors.grey[50]} solid 1px`
  },
  disabled: {
    text: colors.grey[800]
  },
  badge: {
    background: colors.yellow[500],
    text: colors.grey[50],
    border: `1px solid ${colors.yellow[500]}`
  }
};
