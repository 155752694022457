import { Icon, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 12px 24px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border-radius: 24px;
  justify-content: space-between;
  max-width: 420px;
  gap: 16px;

  @media screen and (max-width: 768px) {
    max-width: 220px;
  }
`;

export const StatItemTitle = styled(Text)`
  text-align: center;
  margin-left: 4px;

  @media screen and (max-width: 768px) {
    & #title {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    & #count {
      display: none;
    }
  }
`;

export const StatItemIcon = styled(Icon)`
  margin-right: 4px;
  color: ${({ theme }) => theme.primaryColor[500]};

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;
