import React, { useEffect } from "react";
import { BoardElementComment, PostComment } from "src/js/types/models/Comment";
import { Box, Button, DoodleLoader } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { useTranslation } from "src/js/translation";
import { Comment } from "./components";
import NewCommentInput from "./components/NewCommentInput";
import useComments from "./hooks/useComments";
import { CommentContext } from "./CommentList.types";

import * as S from "./CommentList.styles";

type CommentListProps = {
  parentId: number;
  shouldFetch?: boolean;
  isPreview?: boolean;
  onPreviewClick?: () => void;
  onNewComment?: (text: string) => void;
  onDeleteComment?: (nestedCommentNumber?: number) => void;
  newCommentInputRef?: React.MutableRefObject<HTMLInputElement>;
  context: CommentContext;
};

const COMMENT_LIMIT = {
  FULL_LIST: 8
} as const;

const CommentList = ({
  parentId,
  isPreview = false,
  shouldFetch = true,
  newCommentInputRef,
  context,
  onNewComment,
  onDeleteComment,
  onPreviewClick
}: CommentListProps) => {
  const {
    PostCommentStore: { getCommentListById }
  } = useStores();
  const { commentList, hasNext } = getCommentListById(parentId);
  const {
    fetch,
    deleteComment,
    updateComment,
    createComment,
    nextPage,
    isLoading,
    updateCommentReactionList,
    deleteNestedComment,
    updateNestedComment,
    createNestedComment
  } = useComments({
    parentId,
    limit: COMMENT_LIMIT.FULL_LIST,
    context
  });
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  useEffect(() => {
    if (commentList.length || !shouldFetch) return;
    fetch(0, { limit: COMMENT_LIMIT.FULL_LIST, offset: 0, parentId, context });
  }, [parentId]);

  if (isLoading && shouldFetch && !commentList.length)
    return (
      <Box padding="16px 0px">
        <DoodleLoader theme={whiteTheme} isMini />
      </Box>
    );

  return (
    <S.Wrapper>
      {commentList
        .slice(0, isPreview ? 1 : undefined)
        .map((comment: PostComment | BoardElementComment, index) => (
          <Comment
            commentIndex={index}
            key={comment.id}
            comment={comment}
            hideNestedComment={isPreview}
            onDelete={c => {
              deleteComment(c);
              onDeleteComment?.(c.nestedComments.length);
            }}
            onUpdate={updateComment}
            updateCommentReactionList={updateCommentReactionList}
            onCreateNestedComment={(commentId, text) => {
              createNestedComment(commentId, text);
              onNewComment?.(text);
            }}
            onDeleteNestedComment={nestedComment => {
              deleteNestedComment(nestedComment);
              onDeleteComment?.();
            }}
            onUpdateNestedComment={updateNestedComment}
            customReplyAction={onPreviewClick}
          />
        ))}
      {!isPreview && hasNext && commentList.length ? (
        <Box alignSelf="center" minWidth="56px" marginBottom="16px">
          <Button
            type="button"
            theme={whiteTheme}
            variant="secondary"
            onClick={nextPage}
          >
            {translate("comment_list_load_more_comments_cta")}
          </Button>
        </Box>
      ) : null}
      {isPreview ? null : (
        <S.InputWrapper>
          <NewCommentInput
            newCommentInputRef={newCommentInputRef}
            onSubmit={text => {
              createComment(text).then(() => {
                onNewComment?.(text);
              });
            }}
          />
        </S.InputWrapper>
      )}
    </S.Wrapper>
  );
};

export default observer(CommentList);
