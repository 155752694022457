import { Button, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const AwardedBadgesContainer = styled.div`
  margin-bottom: 32px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  height: auto;
  max-height: 220px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  & > div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const AwardedBadge = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BadgeImage = styled.img`
  object-fit: cover;
  width: 46px;
  margin-right: 24px;
  border-radius: 4px;
`;

export const BadgeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 32px;

  @media screen and (max-width: 767px) {
    white-space: inherit;
    margin: 8px 0px;
  }
`;

export const BadgeDescription = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 767px) {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
`;

export const BlurRow = styled.div`
  position: absolute;
  bottom: -36px;
  width: 100%;
  height: 60px;
  background: ${({ theme }) => `linear-gradient(
    180deg,
    ${theme.colors.grey[1000]}00 0%,
    ${theme.colors.grey[1000]} 44.27%,
    ${theme.colors.grey[1000]} 100%
  )`};
`;

export const ViewMore = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 767px) {
    flex-direction: row-reverse;
    width: 100%;
  }
`;

export const NavigateButton = styled(Button)`
  @media screen and (max-width: 767px) {
    flex: 1;
  }
`;

export const NFTLabelContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -8px;
  right: 12px;
  border-radius: 48px;
  padding: 4px;
`;

export const BadgeImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NFTChainIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -8px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;
