import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  ChangeEvent,
  KeyboardEvent
} from "react";

import {
  UseCodeInputProps,
  CodeInputValues,
  CodeInputClearOptions,
  CodeInputFieldProps
} from "./types";

export function useCodeInput({
  values: valuesProp,
  onChange: onChangeProp,
  onComplete,
  actionRef,
  autoFocus = false,
  length = 6,
  type = "numeric",
  otp = false,
  placeholder = "○",
  disabled = false,
  mask = false,
  error = false
}: UseCodeInputProps = {}) {
  const [valuesState, setValues] = useState<CodeInputValues>(
    Array(length).fill("")
  );
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  const isControlled = valuesProp !== undefined;
  const values = (isControlled ? valuesProp : valuesState) as CodeInputValues;
  const isTypeAlphanumeric = type === "alphanumeric";

  const fieldRefs = useRef<HTMLInputElement[]>(Array(values.length).fill(null));

  const prevFocusedIndex = useRef<number>(-1);
  useEffect(() => {
    prevFocusedIndex.current = focusedIndex;
  }, [focusedIndex]);

  const setFocus = useCallback((index = 0) => {
    fieldRefs.current[index]?.focus();
  }, []);

  useEffect(() => {
    if (autoFocus) {
      setFocus();
    }
  }, [autoFocus, setFocus]);

  const setBlur = useCallback(() => {
    fieldRefs.current[focusedIndex]?.blur();
  }, [focusedIndex]);

  useImperativeHandle(
    actionRef,
    () => ({
      focus: (index = 0) => {
        const emptyFieldIndex = values.findIndex(v => !v);

        setFocus(
          error ? (emptyFieldIndex === -1 ? index : emptyFieldIndex) : index
        );
      },
      blur: setBlur
    }),
    [setBlur, setFocus, values, error]
  );

  const setFieldRef = useCallback(
    (index: number) => (ref: HTMLInputElement) => {
      fieldRefs.current[index] = ref;
    },
    []
  );

  const updateValues = useCallback(
    (values: CodeInputValues) => {
      if (!isControlled) {
        setValues(values);
      }

      onChangeProp?.(values);
    },
    [isControlled, setValues, onChangeProp]
  );

  const onChange = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;

      value = value.trim();

      const regexType = isTypeAlphanumeric ? /^[a-z\d]*$/i : /^\d*$/;

      if (!regexType.test(value)) {
        return;
      }

      if (isTypeAlphanumeric) {
        value = value.toUpperCase();
      }

      if (index === values.length - 1 && values[index] && value !== "") {
        return;
      }

      if (value.length > 2) {
        if (value.length === values.length) {
          updateValues(value.split(""));
          onComplete?.(value);
        }

        return;
      }

      if (value.length === 2) {
        const currentValue = values[index];

        if (currentValue === value[0]) {
          value = value[1];
        } else if (currentValue === value[1]) {
          value = value[0];
        } else {
          return;
        }
      }

      const nextValues = values.slice();
      nextValues[index] = value;
      updateValues(nextValues);

      if (value) {
        if (!nextValues.includes("")) {
          onComplete?.(nextValues.join(""));
        }

        if (index !== values.length - 1) {
          if (error) {
            const emptyFieldIndex = nextValues.findIndex(v => !v);

            if (emptyFieldIndex !== -1) {
              setFocus(emptyFieldIndex);
            }
          } else {
            setFocus(index + 1);
          }
        }
      }
    },
    [isTypeAlphanumeric, values, updateValues, onComplete, setFocus, error]
  );

  const onKeyDown = useCallback(
    (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace" && !values[index] && index) {
        setFocus(index - 1);
      }
    },
    [values, setFocus]
  );

  const clear = useCallback(
    ({ focus = false }: CodeInputClearOptions = {}) => {
      updateValues(Array(values.length).fill(""));

      if (focus) {
        setFocus();
      } else {
        setBlur();
      }
    },
    [updateValues, values, setFocus, setBlur]
  );

  const onFocus = useCallback(
    (index: number) => () => {
      if (prevFocusedIndex.current === -1) {
        const emptyFieldIndex = values.findIndex(v => !v);
        if (emptyFieldIndex === -1) {
          setFocus(values.length - 1);
        } else if (emptyFieldIndex !== index) {
          setFocus(emptyFieldIndex);
        }
      }
      setFocusedIndex(index);
    },
    [values, setFocus]
  );

  const onBlur = useCallback(() => {
    setFocusedIndex(-1);
  }, []);

  const hasFocus = focusedIndex !== -1;

  const fields: CodeInputFieldProps[] = values.map(
    (value: string, index: number) => ({
      ref: setFieldRef(index),
      value,
      disabled,
      autoComplete: otp ? "one-time-code" : "off",
      inputMode: isTypeAlphanumeric ? "text" : "numeric",
      type: mask ? "password" : "text",
      placeholder: hasFocus ? "" : placeholder,
      ...(!disabled && {
        onBlur,
        onFocus: onFocus(index),
        onChange: onChange(index),
        onKeyDown: onKeyDown(index)
      })
    })
  );

  return { fields, clear, isFocused: hasFocus };
}

export type UseCodeInputReturn = ReturnType<typeof useCodeInput>;
