import { ZIndexThemeOptions } from "./types";

const zIndex: ZIndexThemeOptions = {
  regular: 1,
  behind: -1,
  average: 5,
  high: 20,
  higher: 999,
  highest: 99999
};

export default zIndex;
