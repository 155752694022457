import { useStores, useDeviceDetector } from "src/js/hooks";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import createUrl from "src/js/modules/routing";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useBoardContext } from "src/js/context/BoardProvider";
import { EventDomain, LessonsEvent, OldBoard } from "src/js/types";
import { EDIT_FIELD, EditField } from "./EditBoard.const";
import { BoardDataTypes } from "./types";
import { Constraint } from "../EditBoardBody/types";

const fieldMapping = {
  [EDIT_FIELD.COLOR]: "color",
  [EDIT_FIELD.IS_MANDATORY]: "is_mandatory",
  [EDIT_FIELD.NAME]: "name",
  [EDIT_FIELD.IS_MILESTONE]: "is_milestone",
  [EDIT_FIELD.HAS_MANDATORY_ORDER]: "has_mandatory_order"
};

export const useEditBoard = (
  boardId: number,
  boardData: BoardDataTypes,
  setBoardData: (data: BoardDataTypes) => void
) => {
  const {
    BoardsStore: {
      editBoardField,
      togglePublishBoard,
      addBoardConstraint,
      removeBoardConstraint,
      removeBoard
    },
    ConfirmDialogStore: { closeConfirmDialog },
    GroupStore: { groupId }
  } = useStores();
  const { isDesktop } = useDeviceDetector();
  const { translate } = useTranslation();
  const {
    activeBoard,
    updateBoard: saveBoardContext,
    setBoard
  }: {
    activeBoard: OldBoard;
    updateBoard: (board: OldBoard) => void;
    setBoard: (boardId: number, forceUpdate?: boolean) => void;
  } = useBoardContext();

  const refetchModules = () => setBoard(activeBoard.id, true);

  const showError = () =>
    showToastError({ str: translate("board_error_edit") });

  const updateField = async (
    value: string | boolean | number,
    field: EditField
  ) => {
    try {
      return editBoardField({
        groupId,
        boardId,
        value,
        field
      }).then(res => {
        const updatedField = fieldMapping[field];
        if (updatedField && activeBoard) {
          // Keep the activeBoard from provider updated
          saveBoardContext({ ...activeBoard, [updatedField]: value });
        }
        return res;
      });
    } catch {
      return showError();
    }
  };

  const editTitle = (value: string) => {
    if (isDesktop) {
      updateField(value, EDIT_FIELD.NAME);
    } else {
      setBoardData({
        ...boardData,
        name: value
      });
    }
  };

  const togglePublish = (publish: boolean) => {
    if (isDesktop) {
      togglePublishBoard({ groupId, boardId, publish }).catch(() => {
        if (publish) {
          showToastError({ str: translate("board_error_publish") });
        } else {
          showToastError({ str: translate("board_error_unpublish") });
        }
      });
    } else {
      setBoardData({
        ...boardData,
        isPublished: publish
      });
    }
  };

  const editColor = (value: number) => {
    if (isDesktop) {
      updateField(value, EDIT_FIELD.COLOR);
    } else {
      setBoardData({
        ...boardData,
        color: value
      });
    }
  };

  const editMandatory = (value: boolean) => {
    if (isDesktop) {
      updateField(value, EDIT_FIELD.IS_MANDATORY);
    } else {
      setBoardData({
        ...boardData,
        isMandatory: value
      });
    }
  };

  const editMilestone = (value: boolean) => {
    if (isDesktop) {
      updateField(value, EDIT_FIELD.IS_MILESTONE);
    } else {
      setBoardData({
        ...boardData,
        isMilestone: value
      });
    }
  };

  const editOpen = (value: boolean) => {
    if (isDesktop) {
      updateField(value, EDIT_FIELD.IS_OPEN).then(() => {
        _trackEvent(
          EventDomain.Lessons,
          LessonsEvent.LessonsLocking,
          value ? "Lock" : "Unlock"
        );
      });
    } else {
      setBoardData({
        ...boardData,
        isOpen: value
      });
    }
  };

  const editMandatoryOrder = (value: boolean) => {
    if (isDesktop) {
      updateField(value, EDIT_FIELD.HAS_MANDATORY_ORDER).then(() => {
        _trackEvent(
          EventDomain.Lessons,
          LessonsEvent.MandatoryOrder,
          value ? "Fixed" : "NotFixed"
        );
        if (value && activeBoard) {
          // in case of hasMandatoryOrder === true, we are re-fetching the board elements
          refetchModules();
        }
      });
    } else {
      setBoardData({
        ...boardData,
        hasMandatoryOrder: value
      });
    }
  };

  const editMilestoneMessage = async () => {
    const response = await updateField(
      boardData.milestoneMessage,
      EDIT_FIELD.MILESTONE_MESSAGE
    );

    return response;
  };

  const onAddBoardConstraint = (constraint: Constraint) => {
    addBoardConstraint({
      groupId,
      boardId,
      constraint
    }).catch(err => {
      if (err?.error === "circluar_dependency") {
        showToastError({
          str: translate("board_error_add_constraint_circular_dependency")
        });
      } else {
        showToastError({
          str: translate("board_error_add_constraint")
        });
      }
    });
  };

  const onRemoveBoardConstraint = (constraintId: number) => {
    removeBoardConstraint({
      groupId,
      boardId,
      constraintId
    }).catch(() =>
      showToastError({
        str: translate("board_error_remove_constraint")
      })
    );
  };

  const confirmDelete = (shouldNavigate: boolean, closeDrawer: () => void) => {
    removeBoard({ boardId, groupId })
      .then(() => {
        showToastSuccess({
          str: translate("delete_board_modal_success")
        });
        closeDrawer();
        closeConfirmDialog();

        if (shouldNavigate) {
          navigateTo(
            createUrl("modules", {
              group_id: groupId
            })
          );
        }
      })
      .catch(() =>
        showToastError({ str: translate("delete_board_modal_error") })
      );
  };

  return {
    editTitle,
    togglePublish,
    editColor,
    editMandatory,
    editMilestone,
    editOpen,
    editMandatoryOrder,
    editMilestoneMessage,
    onAddBoardConstraint,
    onRemoveBoardConstraint,
    confirmDelete,
    refetchModules
  };
};
