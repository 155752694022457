export type SClosableInfoContainerProps = {
  fullWidth: boolean;
  variant: ClosableVariantsType;
};

export const ClosableVariantsConst = {
  Violet: "violet",
  Grey: "grey"
};

export type ClosableVariantsType =
  (typeof ClosableVariantsConst)[keyof typeof ClosableVariantsConst];

export type ClosableInfoProps = {
  hasIcon?: boolean;
  fullWidth?: boolean;
  variant?: ClosableVariantsType;
  text: React.ReactNode;
  onClickFunction?: React.MouseEventHandler<HTMLDivElement>;
};
