import { Heading, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import { debounce } from "src/js/modules/commonFunction";
import AnalyticsDashboardBlocked from "src/js/pages/spaces/dashboard/SpacesAnalytics/AnalyticsDashboardBlocked";
import { Translate } from "src/js/translation/TranslationProvider";
import * as S from "./AnalyticsDashboard.styles";
import { AnalyticsFiltersUrlHandler } from "./AnalyticsFiltersUrlHandler";
import AnalyticsIndexBox from "./AnalyticsIndexBox";
import { AnalyticsSectionFilters } from "./AnalyticsSectionFilters";
import { AnalyticsSectionTrend } from "./AnalyticsSectionTrend";

const getIndexSubject = key => {
  switch (key) {
    case "usersCount":
      return "users_count";
    case "groupsCount":
      return "groups_count";
    case "contentsCreatedCount":
      return "contents_created_count";
    case "engagementRate":
      return "engagement_rate";
    case "trainingTime":
      return "training_time";
    case "groupsParticipation":
      return "groups_participation";
    default:
      return null;
  }
};

const getIndexSubtitle = key => {
  switch (key) {
    case "usersCount":
      return "users_count_subtitle";
    case "groupsCount":
      return "groups_count_subtitle";
    case "contentsCreatedCount":
      return "contents_created_count_subtitle";
    case "engagementRate":
      return "engagement_rate_subtitle";
    case "trainingTime":
      return "training_time_subtitle";
    case "groupsParticipation":
      return "groups_participation_subtitle";
    default:
      return null;
  }
};

const AnalyticsDashboard = () => {
  const { SpaceAnalyticsFilterStore, SpaceStore, SpacesSettingsStore } =
    useStores();

  const {
    spaceGroupsList,
    selectedUser,
    selectedGroups,
    selectedTopic,
    selectedRange,
    datePlaceholder,
    selectedPeriodAccuracy,
    trendGraphData,
    indexes,
    spaceHasNextGroups,
    spaceSetSelectedUser,
    spaceShowNextGroups,
    spaceSetSelectedGroups,
    spacesSearchUserByName,
    spaceSetSelectedTopic,
    spaceSetSelectedRange,
    spaceSetDatePlaceholder,
    spaceSetSelectedPeriodAccuracy
  } = SpaceAnalyticsFilterStore;

  const [usersList, setUsersList] = useState([]);
  const [userClicked, setUserClicked] = useState(selectedUser);

  const { activeSpaceId } = SpaceStore;
  const { generalSettings } = SpacesSettingsStore;

  useEffect(() => {
    if (generalSettings?.planSettings?.enableAnalytics && activeSpaceId) {
      SpaceAnalyticsFilterStore.fetchSpaceGroups(activeSpaceId);
      SpaceAnalyticsFilterStore.spaceFetchAnalyticsIndexes({
        spaceId: activeSpaceId
      });
      SpaceAnalyticsFilterStore.spaceFetchTrendGraphData({
        spaceId: activeSpaceId,
        range: selectedPeriodAccuracy,
        type: selectedTopic,
        from_date: moment(selectedRange.from_date)
          .locale("en")
          .format("DD-MM-YYYY"),

        to_date: moment(selectedRange.to_date).locale("en").format("DD-MM-YYYY")
      });
    }
    return () => {
      if (activeSpaceId) {
        SpaceAnalyticsFilterStore.reset();
      }
    };
  }, [activeSpaceId, generalSettings]);

  if (!activeSpaceId) return null;

  let debounceFunc = null;

  let indexesList = null;

  if (indexes) {
    const keys = Object.keys(indexes);
    indexesList = keys.map(key => (
      <div key={key}>
        <AnalyticsIndexBox
          value={indexes[key]}
          subject={getIndexSubject(key)}
          subtitle={getIndexSubtitle(key)}
        />
      </div>
    ));
  }

  return (
    <>
      <AnalyticsFiltersUrlHandler />
      {generalSettings?.planSettings?.enableAnalytics === false ? (
        <AnalyticsDashboardBlocked spaceId={activeSpaceId} />
      ) : (
        <MainContainer>
          <S.AnalyticsPageContainer>
            <S.AnalyticsSectionTitle>
              <Heading level="1">
                <Translate text="analytics_page_title" />
              </Heading>
            </S.AnalyticsSectionTitle>
            <AnalyticsSectionFilters
              groupsList={spaceGroupsList}
              usersList={usersList}
              selectedUser={userClicked || selectedUser}
              searchedUser={selectedUser}
              hasNextGroups={spaceHasNextGroups}
              showNextGroups={spaceShowNextGroups}
              selectedGroups={selectedGroups}
              onSelectGroups={groupsIds => {
                spaceSetSelectedGroups(groupsIds);
                setUserClicked(null);
              }}
              onSearchSubmission={user => spaceSetSelectedUser(user)}
              onSelectUser={user => {
                setUserClicked(user);
                setUsersList([]);
              }}
              onResetUserFilter={() => {
                spaceSetSelectedUser(null);
                setUserClicked(null);
              }}
              onUserSearch={event => {
                event.persist();
                if (!debounceFunc) {
                  debounceFunc = debounce(() => {
                    if (event.target.value.length > 2) {
                      spacesSearchUserByName(event.target.value).then(users =>
                        setUsersList(users)
                      );
                    }
                  }, 500);
                }
                debounceFunc();
              }}
            />
            <S.AnalyticsSectionTitle>
              <Text type="formTitle">
                <Translate text="analytics_index_title" />
              </Text>
            </S.AnalyticsSectionTitle>
            <S.AnalyticsIndexContainer>{indexesList}</S.AnalyticsIndexContainer>
            <S.AnalyticsSectionTrendTitle>
              <Text type="formTitle">
                <Translate text="analytics_trend_title" />
              </Text>
            </S.AnalyticsSectionTrendTitle>
            <S.AnalyticsSectionTrendContainer>
              <AnalyticsSectionTrend
                topic={selectedTopic}
                periodAccuracy={selectedPeriodAccuracy}
                dateRange={selectedRange}
                datePlaceholder={datePlaceholder}
                data={trendGraphData}
                setTopic={topic => {
                  spaceSetSelectedTopic(topic);
                }}
                setPeriodAccuracy={accuracy => {
                  spaceSetSelectedPeriodAccuracy(accuracy);
                }}
                setDateRange={({ from_date, to_date }) => {
                  spaceSetSelectedRange({ from_date, to_date });
                }}
                setDatePlaceholder={value => {
                  spaceSetDatePlaceholder(value);
                }}
              />
            </S.AnalyticsSectionTrendContainer>
          </S.AnalyticsPageContainer>
        </MainContainer>
      )}
    </>
  );
};

export default observer(AnalyticsDashboard);
