import styled from "styled-components";
import colors from "@ds_themes/colors";

import { ClosableVariantsConst, SClosableInfoContainerProps } from "./types";

export const ClosableInfoContainer = styled.div<SClosableInfoContainerProps>`
  display: inline-flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ variant }) =>
    variant === ClosableVariantsConst.Violet
      ? colors.violet[501]
      : colors.coldGrey[900]};
  color: ${({ variant }) =>
    variant === ClosableVariantsConst.Violet
      ? colors.grey[1000]
      : colors.blueGrey[400]};
  min-width: 360px;
  max-width: 767px;
  width: ${({ fullWidth }) => (fullWidth ? "700px" : "auto")};
  @media screen and (max-width: 767px) {
    min-width: auto;
    width: 100%;
  }
`;

export const ClosableInfoIcon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  @media screen and (max-width: 767px) {
    padding-right: 12px;
  }
`;

export const ClosableInfoMessage = styled.div`
  margin: auto;
`;

export const ClosableInfoClose = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;
