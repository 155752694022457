import { fetchSpaceTemplates } from "src/js/repository/spacesRepository";
import { useQuery } from "../hooks";

// Query keys

const __SPACES_KEY_ALL__ = "spaces";

const templates = (spaceId: string) => [
  __SPACES_KEY_ALL__,
  "templates",
  spaceId
];

export const spacesQueryKey = {
  templates
} as const;

// Hooks

export const useTemplates = (params: { spaceId: string }) => {
  return useQuery(spacesQueryKey.templates(params?.spaceId), () =>
    fetchSpaceTemplates(params)
  );
};
