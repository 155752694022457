import React from "react";
import { flexRender } from "@tanstack/react-table";
import { useHistory } from "react-router";
import { __NO_RESULTS__ } from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { Box, Heading, Text } from "@arcadia/design-system";
import { Search } from "./components/Search";
import { Header } from "./components/Header";
import { useScormActivityTab } from "./hooks/useScormActivityTab";
import * as S from "./ScormActivityTab.styles";

const ScormActivityTab = () => {
  const { isFetchingNextPage, isLoading, tableInstance, search, setSearch } =
    useScormActivityTab();
  const { translate } = useTranslation();
  const history = useHistory();

  return (
    <S.ScormActivityTabWrapper>
      <Search search={search} setSearch={setSearch} />
      <Header />
      <S.Table>
        <S.TableHeader>
          {tableInstance.getHeaderGroups().map(headerGroup => (
            <S.TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <S.TableHeaderCell key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </S.TableHeaderCell>
              ))}
            </S.TableRow>
          ))}
        </S.TableHeader>
        <S.TableBody>
          {tableInstance.getRowModel().rows.map(row => {
            const isUserTable = "user" in row.original;
            return (
              <S.TableRow
                key={row.id}
                cursorPointer={isUserTable}
                onClick={
                  isUserTable
                    ? () => {
                        if ("user" in row.original)
                          history.push(`?userUuid=${row.original.user.uuid}`);
                      }
                    : undefined
                }
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <S.TableCell
                      key={cell.id}
                      style={{
                        width: cell.column.getSize(),
                        maxWidth: cell.column.getSize()
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
            );
          })}
          {!isLoading &&
            !isFetchingNextPage &&
            tableInstance.getRowModel().rows.length === 0 && (
              <S.TableRow noHover>
                <S.TableCell
                  colSpan={tableInstance.getHeaderGroups()[0].headers.length}
                >
                  <S.NoResultsWrapper>
                    <img width={100} src={__NO_RESULTS__} alt="no results" />
                    <S.NoResultsMessageWrapper>
                      <Heading level="5">
                        {translate("record_scorm_tab_no_data")}
                      </Heading>
                      <Box maxWidth={240} textAlign="center">
                        <Text type="body">
                          {translate("record_scorm_tab_no_data_message")}
                        </Text>
                      </Box>
                    </S.NoResultsMessageWrapper>
                  </S.NoResultsWrapper>
                </S.TableCell>
              </S.TableRow>
            )}
          {(isFetchingNextPage || isLoading) &&
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Array.from({ length: 10 }).map((_, index) => (
              <S.TableRow
                noHover
                // eslint-disable-next-line react/no-array-index-key
                key={`${tableInstance.getRowModel().rows.length}${index}`}
              >
                {tableInstance.getHeaderGroups().map(headerGroup =>
                  headerGroup.headers.map(header => {
                    return (
                      <S.TableCell
                        key={header.id}
                        style={{
                          width: header.getSize(),
                          maxWidth: header.getSize()
                        }}
                      >
                        <S.Skeleton />
                      </S.TableCell>
                    );
                  })
                )}
              </S.TableRow>
            ))}
        </S.TableBody>
      </S.Table>
    </S.ScormActivityTabWrapper>
  );
};

export default ScormActivityTab;
