import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button, Heading, ContextualError } from "@arcadia/design-system";
import { Drawer } from "src/js/components/Drawer";
import { UserPreviewInfo } from "src/js/pages/spaces/dashboard/SpacesUsers/UserPreviewInfo";
import { ManageGroupsTable } from "src/js/pages/spaces/dashboard/SpacesUsers/ManageGroupsTable";
import { useTheme } from "styled-components";
import * as S from "./SpacesUsersRolesManager.styles";

const SpacesUsersRolesManager = ({
  open,
  userObject,
  groups,
  dismissDrawer,
  changeRole,
  toggleEnabled,
  errorGroupsId,
  hasRoleError,
  hasRemoveError,
  showNext,
  hasNext,
  isInsideMainLayout = false
}) => {
  const { whiteTheme } = useTheme();
  return (
    <Drawer
      open={open}
      onDismiss={dismissDrawer}
      isInsideMainLayout={isInsideMainLayout}
    >
      <S.InnerContainer>
        <S.TitleWrapper>
          <Heading level="5">
            <Translate text="manage_group_roles_title" />
          </Heading>
        </S.TitleWrapper>
        <UserPreviewInfo userObject={userObject} />
        <ManageGroupsTable
          groups={groups}
          changeRole={changeRole}
          toggleEnabled={toggleEnabled}
          errorGroupsId={errorGroupsId}
          showNext={showNext}
          hasNext={hasNext}
        />
        {hasRemoveError && (
          <S.ToastWrapper>
            <ContextualError
              theme={whiteTheme}
              text={<Translate text="user_group_remove_error" />}
            />
          </S.ToastWrapper>
        )}
        {hasRoleError && (
          <S.ToastWrapper>
            <ContextualError
              theme={whiteTheme}
              text={<Translate text="user_change_role_error" />}
            />
          </S.ToastWrapper>
        )}
        <S.Footer>
          <Button
            onClick={dismissDrawer}
            variant="secondary"
            theme={whiteTheme}
          >
            <Translate text="close" />
          </Button>
        </S.Footer>
      </S.InnerContainer>
    </Drawer>
  );
};

export default SpacesUsersRolesManager;
