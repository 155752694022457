/* eslint-disable jsx-a11y/media-has-caption */
import { Heading, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./FakePost.styles";
import { FakePostProps } from "./FakePost.types";

const FakePost: FC<FakePostProps> = ({ isMobile }) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const [showAllContent, setShowAllContent] = useState<boolean>(false);

  return (
    <S.Layout isMobile={isMobile}>
      <S.Header>
        <S.HeaderBody>
          <Heading level={isMobile ? "5" : "3"}>
            {translate({ text: "fake_post_title" })}
          </Heading>
        </S.HeaderBody>
      </S.Header>
      <S.Content showAllContent={showAllContent}>
        <Text type="body">{translate({ text: "fake_post_text" })} </Text>
        <S.PreOL>{translate({ text: "fake_post_pre_list_title" })}</S.PreOL>
        <S.Ol isMobile={isMobile} showAllContent={showAllContent}>
          <li>
            <S.ListItemTitle>
              {translate({ text: "fake_post_list_item_title_1" })}
            </S.ListItemTitle>
            {translate({ text: "fake_post_list_item_description_1" })}
          </li>
          <li>
            <S.ListItemTitle>
              {translate({ text: "fake_post_list_item_title_2" })}
            </S.ListItemTitle>
            {translate({ text: "fake_post_list_item_description_2" })}
          </li>
          <li>
            <S.ListItemTitle>
              {translate({ text: "fake_post_list_item_title_3" })}
            </S.ListItemTitle>
            {translate({ text: "fake_post_list_item_description_3" })}
          </li>
        </S.Ol>

        {isMobile && !showAllContent && (
          <S.ReadMore
            theme={whiteTheme}
            onClick={() => setShowAllContent(true)}
          >
            {translate({ text: "fake_post_read_more" })}
          </S.ReadMore>
        )}
      </S.Content>
      <VideoPlayer video={S3Video.FullyCustomisedDigitalWorkspace} />
    </S.Layout>
  );
};

export default observer(FakePost);
