import styled from "styled-components";
import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";

export const HeaderText = styled(Text)<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.primaryColor[500] : theme.colors.grey[300]};
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
`;

export const Button = styled(motion.button)`
  all: unset;
  display: flex;
  align-items: center;

  cursor: pointer;
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  padding: 6px 8px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor[900]};
    color: ${({ theme }) => theme.primaryColor[500]};

    & span {
      color: ${({ theme }) => theme.primaryColor[500]};
    }
  }
`;

export const SortingCaret = styled.span<{ active?: boolean }>`
  font-size: 10px;
  color: ${({ theme, active }) =>
    active ? theme.primaryColor[500] : theme.colors.grey[300]};
  transition: color 0.2s ease-in-out;
`;
