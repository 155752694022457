import styled from "styled-components";

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const InputPointContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const EvaluationText = styled.span`
  padding: 5px 10px;
  font-weight: 700;
  text-transform: capitalize;
`;

export const StudentFooterContainer = styled.div`
  margin: 20px -20px 0px;
  border-top: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
  padding: 20px 25px 0px;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.title};
  @media (max-width: 767px) {
    align-items: initial;
    flex-direction: column;
  }
`;

export const SubmitButton = styled.span`
  margin-left: auto;
`;
