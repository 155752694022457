import { Text } from "@arcadia/design-system";
import React, { FC } from "react";
import { translateString, TranslationKeys } from "src/js/translation";
import CircleDuration from "../CircleDuration/CircleDuration";
import * as S from "./SpaceDurationCard.styles";
import { SpaceDurationCardProps } from "./SpaceDurationCard.types";

const SpaceDurationCard: FC<SpaceDurationCardProps> = ({
  className,
  variant,
  title,
  link,
  onClickLink,
  duration,
  durationType
}) => {
  return (
    <S.SpaceDurationCardContainer variant={variant} className={className}>
      <S.SpaceDurationContent>
        <S.Title>{title}</S.Title>
        <S.LinkBtn onClick={onClickLink}>
          <Text type="textLink">{link}</Text>
        </S.LinkBtn>
      </S.SpaceDurationContent>
      <S.DurationBox>
        <CircleDuration />
        {duration}
        <S.Subtitle>
          {translateString({
            text: `space_duration_card_type_${durationType}` as TranslationKeys
          })}
        </S.Subtitle>
      </S.DurationBox>
    </S.SpaceDurationCardContainer>
  );
};

export default SpaceDurationCard;
