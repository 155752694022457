import styled from "styled-components";
import { Icon } from "@arcadia/design-system";

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const BadgeDetails = styled.div`
  gap: 16px;
  margin-left: 22px;
  display: flex;
  align-items: center;
  flex-flow: row;
  font-size: 16px;
  min-width: 0;
`;

export const BadgeImageContainer = styled.div`
  width: 36px;
  height: 36px;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
`;

export const BadgeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const PrivacyIndicationContainer = styled.div<{ isprivate: boolean }>`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme, isprivate }) =>
    isprivate ? theme.colors.orange[900] : theme.colors.success[900]};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  top: -10px;
  right: -10px;
`;

export const PrivacyIcon = styled(Icon)<{ isprivate: boolean }>`
  color: ${({ theme, isprivate }) =>
    isprivate ? theme.colors.orange[500] : theme.colors.success[500]};
`;

export const BadgeTitle = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BadgeInfo = styled.div`
  margin-right: 22px;
  gap: 64px;
  display: flex;
  align-items: center;
  flex-flow: row;
`;

export const BadgeInfoColumn = styled.div`
  flex: 1;
  display: flex;
  line-height: 18px;
  justify-content: center;
  width: 80px;
`;

export const BadgeActions = styled.div`
  width: 40px;
  height: 40px;
`;

export const BadgeDetailsMobile = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  flex-flow: row;
  font-size: 16px;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[800]};
`;

export const BadgeInfoMobile = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  flex-flow: row;
`;

export const BadgeActionsMobile = styled.div`
  width: 40px;
  height: 40px;
  align-self: flex-end;
`;

export const InfoContainerMobile = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  width: 100%;
`;

export const BadgeInfoColumnMobile = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

export const HeaderInfoColumn = styled.div`
  width: 100%;
`;

export const ActionLabel = styled.u`
  cursor: pointer;
`;
