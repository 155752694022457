import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { useStores } from "./hooks";
import { getCurrentLanguage } from "./modules/localization";
import { SpaceUserRoleEnum, TODO } from "./types";

const InitUsetifulTags = () => {
  const {
    UserStore: { activeUser },
    GroupStore: { roles },
    SpaceStore: { activeSpace, spaceUserStats }
  } = useStores();

  const lang = getCurrentLanguage();

  useEffect(() => {
    window.usetifulTags = {};
  }, []);

  useEffect(() => {
    if (!activeSpace?.role && !roles?.length) {
      window.usetifulTags.role = null;
    }
    if (activeSpace?.role === SpaceUserRoleEnum.Admin) {
      window.usetifulTags.role = activeSpace.role;
      return;
    }
    if (roles?.length) {
      // eslint-disable-next-line prefer-destructuring
      window.usetifulTags.role = roles[0];
      return;
    }
    window.usetifulTags.role = activeSpace?.role;
  }, [activeSpace, roles]);

  useEffect(() => {
    window.usetifulTags.groupsNumber = spaceUserStats?.groupsNumber;
  }, [spaceUserStats]);

  useEffect(() => {
    if (!lang) {
      window.usetifulTags.language = "en_US";
      return;
    }
    window.usetifulTags.language = lang;
  }, [lang]);

  useEffect(() => {
    if (!activeUser?.id) {
      window.usetifulTags = {};
      return;
    }
    window.usetifulTags.userId = activeUser?.id as TODO;
  }, [activeUser?.id]);

  return (
    <Helmet>
      <script
        id="usetifulScript"
        src="https://www.usetiful.com/dist/usetiful.js"
        data-token={process.env.__USETIFUL_TOKEN__}
        async
      />
    </Helmet>
  );
};

export default observer(InitUsetifulTags);
