import React from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { Label } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const UserStatusBadge = ({ is_active = true, outline = true }) => {
  const { whiteTheme } = useTheme();
  return (
    <Label
      outline={outline}
      theme={whiteTheme}
      color={is_active ? "green" : "bluegrey"}
    >
      <Translate text={is_active ? "active" : "not_active"} />
    </Label>
  );
};

export default UserStatusBadge;
