import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const User = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
`;

export const FooterSpacer = styled.div`
  margin-right: 2px;
  display: flex;
  align-items: center;
`;

export const EscText = styled(Text)`
  font-size: 10;
`;
