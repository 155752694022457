import React, { FC, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import { ContextualError } from "@ds_universal/data_display";
import { ContextualAlert } from "@ds_universal/data_display/ContextualAlert";
import { Text } from "@ds_universal/Text";
import colors from "@ds_themes/colors";
import { Icon } from "@ds_universal/Icon";

import { formatBytes } from "./utils";
import {
  ImageSrcType,
  PictureUploaderFeedbackIds,
  PictureUploaderFeedbackMessageParams,
  PictureUploaderProps
} from "./types";
import * as S from "./PictureUploader.styles";

const DEFAULT_FEEDBACK: PictureUploaderFeedbackIds = "upload_image_limit";

const parseImageSrcToUrl = (imageSrc: ImageSrcType | undefined) => {
  if (!imageSrc) return null;
  if (typeof imageSrc === "string") return imageSrc;
  return URL.createObjectURL(imageSrc as File);
};

const PictureUploader: FC<PictureUploaderProps> = ({
  imageSrc,
  onChangeHandler,
  removeFile,
  mode = "edit",
  height = "168px",
  fontSize = "14px",
  maxWidth = "100%",
  textAlign = "center",
  label = "",
  hideLabel = false,
  showPencil = true,
  backgroundColor = colors.violet[900],
  placeholder = "",
  theme,
  defaultCover = undefined,
  disabled = false,
  maxSize,
  mimeArray,
  showDefaultFeedback = true,
  feedbacks = []
}) => {
  const [dropActive, setDropActive] = useState<boolean>(false);
  const [error, setError] = useState<PictureUploaderFeedbackIds | null>(null);

  const ref = useRef<HTMLInputElement>(null);

  const isEmpty = !imageSrc;
  const isEditMode = mode === "edit";
  const imagePreview = defaultCover
    ? parseImageSrcToUrl(imageSrc) || defaultCover
    : null;

  const checkMimeType = (file: File) => {
    const types = mimeArray;
    if (types.every(type => file.type !== type)) {
      setError("file_extension_unsupported_error");
      return false;
    }
    return true;
  };

  const checkFileSize = (file: File) => {
    if (!maxSize) return true;
    const size = maxSize;
    if (file.size > size) {
      setError("file_size_error");
      return false;
    }
    return true;
  };

  const onSaveFile = (file: File | undefined) => {
    if (!file) return;
    if (checkMimeType(file) && checkFileSize(file)) {
      onChangeHandler(file);
      setError(null);
    }
  };

  const fileDrop: React.DragEventHandler = e => {
    e.preventDefault();
    setDropActive(false);
    const { files } = e.dataTransfer;
    onSaveFile(files[0]);
  };

  const dragLeave: React.DragEventHandler = e => {
    setDropActive(false);
    e.preventDefault();
  };

  const dragEnter: React.DragEventHandler = e => {
    setDropActive(true);
    e.preventDefault();
  };

  const dragOver: React.DragEventHandler = e => {
    setDropActive(true);
    e.preventDefault();
  };

  const feedbackMessage = (id: PictureUploaderFeedbackIds) => {
    const feedback = feedbacks?.find(feedback => feedback.id === id);
    if (!feedback) {
      return null;
    }
    if (typeof feedback.message === "function") {
      const messageParams: PictureUploaderFeedbackMessageParams = {
        maxSize: maxSize ? formatBytes(maxSize) : null
      };
      feedback.message = feedback.message(messageParams);
    }
    if (feedback.type === "warning") {
      return <ContextualAlert theme={theme} text={feedback.message} />;
    }
    return <ContextualError theme={theme} text={feedback.message} />;
  };

  return (
    <ThemeProvider theme={theme}>
      <S.Container maxWidth={maxWidth}>
        <S.Label
          htmlFor={`inputText${label.replace(/\s/g, "")}`}
          visuallyHidden={hideLabel}
        >
          <Text type="formSubtitle">{label}</Text>
        </S.Label>
        <S.Wrapper>
          {isEditMode && removeFile ? (
            <S.TrashButton
              theme={theme}
              variant="secondary"
              icon="trash"
              onClick={removeFile}
              disabled={isEmpty}
            />
          ) : null}
          <S.DropZone
            isViewMode={!isEditMode}
            dropActive={dropActive}
            disabled={disabled}
            backgroundColor={backgroundColor}
            height={height}
            {...(!disabled && {
              onDragEnter: dragEnter,
              onDragLeave: dragLeave,
              onDragOver: dragOver,
              onDrop: fileDrop,
              onClick: () => ref.current?.click()
            })}
          >
            {imagePreview ? (
              <S.ImagePreview src={imagePreview} alt="dropzone" />
            ) : null}
            <input
              type="file"
              name="file"
              disabled={disabled || !isEditMode}
              onChange={e => {
                onSaveFile(e.target.files?.[0]);
              }}
              className="none"
              ref={ref}
            />
            {isEditMode && showPencil ? (
              <S.EditIconDropZone theme={theme} icon="edit" variant="primary" />
            ) : null}
            {isEditMode && !showPencil ? (
              <S.EditIconDropZoneContainer
                fontSize={fontSize}
                textAlign={textAlign}
              >
                <Icon
                  icon="upload"
                  width={24}
                  height={24}
                  viewBox="0 0 40 40"
                />
                {placeholder ? <Text type="cta">{placeholder}</Text> : null}
              </S.EditIconDropZoneContainer>
            ) : null}
          </S.DropZone>
        </S.Wrapper>
        {isEditMode && (
          <S.Feedbacks>
            {!error && !disabled && showDefaultFeedback
              ? feedbackMessage(DEFAULT_FEEDBACK)
              : null}
            {error && feedbackMessage(error)}
          </S.Feedbacks>
        )}
      </S.Container>
    </ThemeProvider>
  );
};

export default PictureUploader;
