import {
  getAppIconFromPreference,
  getEmailIconFromPreference
} from "src/js/pages/group/settings/GroupSettingsNotice/utils";
import { PreferenceDropdownOption } from "./DesktopPreferencePicker/PreferenceDropdown";

export const AppPreferenceOptions: PreferenceDropdownOption[] = [
  {
    id: "All",
    value: "All",
    label: "user_settings_dropdown_all_notification",
    icon: getAppIconFromPreference("All")
  },
  {
    id: "Highlights",
    value: "Highlights",
    label: "user_settings_dropdown_highlights_notification",
    icon: getAppIconFromPreference("Highlights")
  },

  {
    id: "None",
    value: "None",
    label: "user_settings_dropdown_no_notification",
    icon: getAppIconFromPreference("None")
  }
];

export const EmailPreferenceOptions: PreferenceDropdownOption[] = [
  /* {
    id: "All",
    value: "All",
    label: "user_settings_dropdown_all_email",
    icon: getEmailIconFromPreference("All")
  }, option disabled because we have many complain for too many emails received */
  {
    id: "Daily",
    value: "Daily",
    label: "user_settings_dropdown_daily_email",
    icon: getEmailIconFromPreference("Daily")
  },

  {
    id: "Weekly",
    value: "Weekly",
    label: "user_settings_dropdown_weekly_email",
    icon: getEmailIconFromPreference("Weekly")
  },

  {
    id: "None",
    value: "None",
    label: "user_settings_dropdown_no_email",
    icon: getEmailIconFromPreference("None")
  }
];
