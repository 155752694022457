import { history } from "Legacy/modules/history";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import LogoutPage from "src/js/pages/App/LogoutPage/LogoutPage";
import { UnderageApprovationPage } from "src/js/pages/App/UnderageApprovationPage";
import { JoinPage } from "src/js/pages/JoinPage";
import UnderageLock from "src/js/pages/user/UnderageLock";
import { WeSchoolRoutes } from "../Routes.const";

export const UnderageRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path={WeSchoolRoutes.Underage.underageLock}
          component={UnderageLock}
        />
        <Route
          exact
          path={WeSchoolRoutes.Underage.underageApprovation}
          key="underageApprovation"
          component={UnderageApprovationPage}
        />
        <Route
          path={WeSchoolRoutes.Underage.joinCodiceOption}
          component={JoinPage}
        />
        <Route path={WeSchoolRoutes.Underage.logout} component={LogoutPage} />
        {/* eslint-disable-next-line react/no-unstable-nested-components */}
        <Route render={() => <Redirect to={createUrl("underage_lock")} />} />
      </Switch>
    </Router>
  );
};
