import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const FileUploadWrapper = styled.div`
  width: 100%;
  height: 284px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border: 1px dashed ${({ theme }) => theme.primaryColor[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const UploadMessage = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
  text-align: center;
`;

export const HiddenInput = styled.input`
  display: none !important;
`;

export const TextWrapper = styled.div`
  max-width: 176px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor[500]};
`;
