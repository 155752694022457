export const NOTIFICATION_SETTINGS_TYPE = {
  ALL: "All",
  HIGHLIGHTS: "Highlights",
  NONE: "None"
};

export const EMAIL_SETTINGS_TYPE = {
  ALL: "All",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  NONE: "None"
};
