import { DropdownOption } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { DurationPeriod } from "./types";

type DurationPerionOptions = DropdownOption & { months: number; years: number };

const useBadgeOptionalFields = () => {
  const { translate } = useTranslation();

  const durationPeriodOptions: DurationPerionOptions[] = [
    {
      id: DurationPeriod.ONE_MONTH,
      months: 1,
      years: 0,
      label: translate({
        text: "badge_duration_period_month_option",
        stringVariables: { numberOfMonths: 1 }
      })
    },
    {
      id: DurationPeriod.TWO_MONTHS,
      months: 2,
      years: 0,
      label: translate({
        text: "badge_duration_period_month_option",
        stringVariables: { numberOfMonths: 2 }
      })
    },
    {
      id: DurationPeriod.SIX_MONTHS,
      months: 6,
      years: 0,
      label: translate({
        text: "badge_duration_period_month_option",
        stringVariables: { numberOfMonths: 6 }
      })
    },
    {
      id: DurationPeriod.ONE_YEAR,
      months: 0,
      years: 1,
      label: translate({
        text: "badge_duration_period_year_option",
        stringVariables: { numberOfYears: 1 }
      })
    },
    {
      id: DurationPeriod.TWO_YEARS,
      months: 0,
      years: 2,
      label: translate({
        text: "badge_duration_period_year_option",
        stringVariables: { numberOfYears: 2 }
      })
    },
    {
      id: DurationPeriod.FOUR_YEARS,
      months: 0,
      years: 4,
      label: translate({
        text: "badge_duration_period_year_option",
        stringVariables: { numberOfYears: 4 }
      })
    },
    {
      id: DurationPeriod.SIX_YEARS,
      months: 0,
      years: 6,
      label: translate({
        text: "badge_duration_period_year_option",
        stringVariables: { numberOfYears: 6 }
      })
    }
  ];

  return { durationPeriodOptions };
};

export default useBadgeOptionalFields;
