import colors from "@ds_themes/colors";

export const general = {
  outline: {
    violet: {
      background: colors.grey[1000],
      text: colors.violet[300]
    },
    red: {
      background: colors.grey[1000],
      text: colors.error[500]
    },
    royalpurple: {
      background: colors.grey[1000],
      text: colors.royalPurple[300]
    },
    summergreen: {
      background: colors.grey[1000],
      text: colors.summerGreen[300]
    },
    lagoon: {
      background: colors.grey[1000],
      text: colors.lagoon[300]
    }
  },
  focus: {
    background: colors.grey[1000],
    border: colors.grey[1000]
  },
  solid: {
    violet: {
      background: colors.violet[700],
      text: colors.grey[50]
    },
    yellow: {
      background: colors.yellow[500],
      text: colors.grey[50]
    },
    red: {
      background: colors.error[500],
      text: colors.grey[1000]
    },
    carnation: {
      background: colors.carnation[800],
      text: colors.grey[50]
    },
    royalpurple: {
      background: colors.royalPurple[700],
      text: colors.grey[50]
    },
    summergreen: {
      background: colors.summerGreen[700],
      text: colors.grey[50]
    },
    lagoon: {
      background: colors.lagoon[700],
      text: colors.grey[50]
    }
  }
};

export const activity = {
  background: colors.grey[1000],
  closeColor: colors.grey[50],
  icon: colors.grey[1000],
  variant: {
    violet: colors.violet[501],
    green: colors.success[500],
    orange: colors.warning[500],
    black: colors.grey[50]
  }
};
