import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.text.color};
  display: flex;
  align-items: flex-start;
  padding: 15px 0 5px;
  border-top: 1px solid ${({ theme }) => theme.eventCard.border};

  ${({ isToday }) =>
    isToday &&
    css`
      border-top: 1px solid ${({ theme }) => theme.dayContainer.today.border};
      border-bottom: 1px solid ${({ theme }) => theme.dayContainer.today.border};
      margin-bottom: -1px;
      z-index: 1;
    `}
`;

export const EventDate = styled.div`
  width: 90px;
  flex: 0 0 90px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  justify-content: space-between;
  align-items: flex-end;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  margin-top: -2px;
`;

export const EventDateNumber = styled.div`
  font-size: 28px;
  line-height: 28px;
  margin-top: -4px;
  font-weight: 600;
`;

export const EventList = styled.span`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-transform: capitalize;
  min-width: 0;
`;

export const TodayCircle = styled.div`
  position: absolute;
  left: 8px;
  top: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.dayContainer.today.background};
`;
