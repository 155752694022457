import React, {
  createContext,
  useContext,
  useMemo,
  CSSProperties
} from "react";
import {
  useSortable,
  defaultAnimateLayoutChanges,
  AnimateLayoutChanges
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "@ds_universal/Icon";

import {
  DragHandleProps,
  SortableItemContextProps,
  SortableItemProps
} from "./SortableItem.types";
import * as S from "./SortableItem.styles";

const SortableItemContext = createContext<SortableItemContextProps>({
  attributes: {},
  listeners: undefined,
  ref() {}
});

const animateLayoutChanges: AnimateLayoutChanges = args => {
  const { isSorting, wasDragging } = args;

  if (isSorting || wasDragging) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
};

export const SortableItem = ({ children, id }: SortableItemProps) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ animateLayoutChanges, id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setNodeRef
    }),
    [attributes, listeners, setNodeRef]
  );

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <SortableItemContext.Provider value={context}>
      <S.SortableItem ref={setNodeRef} style={style}>
        {children}
      </S.SortableItem>
    </SortableItemContext.Provider>
  );
};

export const DragHandle = ({
  children,
  style,
  className
}: DragHandleProps & { className?: string }) => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <S.Handle
      type="button"
      className={className}
      style={style}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      {children !== undefined ? (
        children
      ) : (
        <Icon icon="drag" width={12} height={12} />
      )}
    </S.Handle>
  );
};
