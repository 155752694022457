/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";

/**
 * @name VendorsStore
 *
 * @description
 * Gets and stores the information of user's vendors
 */
class VendorsStore {
  $vendors = [];
  $accounts = [];
  $lastAccountUsed = {};

  constructor({ fetchVendors, userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.fetchVendors = fetchVendors;
    this.userStore = userStore;
    this.dispose();
  }

  fetchUserVendors = activeUser => {
    if (!activeUser) {
      this.resetValues();
      return null;
    }
    return this.fetchVendors()
      .then(results => {
        this.setVendors(results?.vendors);
        this.setAccounts(results?.accounts);
        this.setLastAccountUsed(results?.last_account_used);
      })
      .catch(() => {
        this.resetValues();
      });
  };

  setVendors = value => {
    this.$vendors = value;
  };

  setAccounts = value => {
    this.$accounts = value;
  };

  setLastAccountUsed = value => {
    this.$lastAccountUsed = value;
  };

  resetValues = () => {
    this.setVendors([]);
    this.setAccounts([]);
    this.setLastAccountUsed({});
  };

  get vendors() {
    return this.$vendors;
  }

  get accounts() {
    return this.$accounts;
  }

  get lastAccountUsed() {
    return this.$lastAccountUsed;
  }

  dispose = () => {
    reaction(
      // reacts on change of user
      () => this.userStore.activeUser,
      activeUser => {
        this.fetchUserVendors(activeUser);
      }
    );
  };
}

export default VendorsStore;
