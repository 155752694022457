import styled from "styled-components";

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 16px 16px;

  & > div {
    max-width: inherit;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 4px;
`;

export const DefaultFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  min-width: 152px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  padding: 0px 16px;

  & > div {
    max-width: inherit;
  }
`;
