import React from "react";
import { useTheme } from "styled-components";
import {
  Translate,
  translateString
} from "src/js/translation/TranslationProvider";
import FileUploaderButton from "src/js/components/global/FileUploaderButton";
import {
  ActionButton,
  ProgressBar,
  ContextualError,
  ContextualAlert
} from "@arcadia/design-system";

const FileUploaderView = ({
  theme,
  disabled,
  buttonUploadText,
  labelSizeText = "upload_image_limit",
  onChangeHandler,
  removeFile,
  hasNoInputFile,
  maxSize,
  className,
  statusLoading,
  loaded,
  errorUploader,
  centeredButton
}) => {
  const { greyTheme, whiteTheme } = useTheme();
  const defaultTheme = theme || greyTheme;
  return (
    <div className={["file-uploader", className].join(" ")}>
      <div
        className={`file-uploader__buttons ${
          centeredButton ? "file-uploader__buttons--centered" : ""
        }`}
      >
        <FileUploaderButton
          theme={defaultTheme}
          onChangeHandler={onChangeHandler}
          disabled={disabled}
          buttonText={buttonUploadText}
        />
        {removeFile ? (
          <ActionButton
            theme={greyTheme}
            variant="secondary"
            icon="trash"
            onClick={removeFile}
            disabled={hasNoInputFile}
          />
        ) : (
          ""
        )}
      </div>
      {maxSize ? (
        <div className="margin-top-10 margin-bottom-5">
          <ContextualAlert
            theme={whiteTheme}
            text={`${translateString({ text: labelSizeText })} ${maxSize}`}
          />
        </div>
      ) : (
        ""
      )}
      {statusLoading ? (
        <ProgressBar theme={greyTheme} percentage={loaded} mode="meter--thin" />
      ) : (
        ""
      )}
      {errorUploader ? (
        <ContextualError
          theme={whiteTheme}
          text={<Translate text={errorUploader} />}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FileUploaderView;
