/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { useTranslation } from "src/js/translation";

const VideoPlayer = ({
  publicUrl,
  videoId
}: {
  publicUrl: string;
  videoId: number;
}) => {
  const { translate } = useTranslation();
  return (
    <div
      className="scroll-wrapper full-height text-center"
      style={{ overflow: "hidden" }}
    >
      <video
        // @ts-expect-error - Legacy stuff
        id={videoId}
        src={publicUrl}
        className="resource-video__element video-js vjs-default-skin"
        controls
        preload="auto"
        width="480"
        height="360"
      />
      <p>&nbsp;</p>
      <div className="text-center">
        <strong>
          {translate("resource_video_trouble_viewing")}
          <br />
          <a
            href={publicUrl}
            title={translate("download")}
            target="_blank"
            rel="noreferrer"
          >
            {translate("download")}
          </a>
        </strong>
      </div>
    </div>
  );
};

export default VideoPlayer;
