import styled from "styled-components";
import { Text } from "@ds_universal/Text";
import colors from "@ds_themes/colors";

import {
  SWrapperChatAvatarProps,
  SWrapperTextProps,
  SWrapperTimeProps
} from "./types";

export const Wrapper = styled.div`
  position: relative;
`;

export const WrapperChatAvatar = styled.div<SWrapperChatAvatarProps>`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.regular + 1};
  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 8fr 4fr;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ theme, state }) =>
    state === "active"
      ? theme.wrapperchatavatar.active.background
      : theme.wrapperchatavatar.background};
  color: ${({ theme, state }) =>
    state === "active"
      ? theme.wrapperchatavatar.active.text
      : theme.wrapperchatavatar.text};

  &:hover {
    background: ${({ theme, state }) =>
      state === "active"
        ? theme.wrapperchatavatar.active.background
        : colors.coldGrey[900]};
  }

  @media (max-width: 767px) {
    border-radius: 0px;
    padding: 8px 20px 8px 16px;
    height: auto;
  }
`;

export const FullName = styled(Text)`
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const MessagePreview = styled(Text)`
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const WrapperText = styled.div<SWrapperTextProps>`
  margin-left: 8px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  span:nth-child(1) {
    white-space: nowrap;
    color: ${({ theme, state }) =>
      state === "active"
        ? theme.wrapperchatavatar.active.text
        : theme.wrapperchatavatar.text};
  }
  span:nth-child(2) {
    color: ${({ theme, state }) =>
      state === "active"
        ? theme.wrapperchatavatar.active.text
        : theme.wrapperchatavatar.message};
  }
`;

export const TimeAndUnreadContainer = styled.div<{
  hasUnreadMessages: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: ${({ hasUnreadMessages }) =>
    hasUnreadMessages ? "center" : "flex-start"};

  @media screen and (max-width: 767px) {
    gap: 6px;
    justify-content: center;
  }
`;

export const WrapperTime = styled.div<SWrapperTimeProps>`
  color: ${({ theme, state }) =>
    state === "active"
      ? theme.wrapperchatavatar.active.text
      : theme.wrapperchatavatar.text};
`;

export const UnreadMessagesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 16px;
`;

export const UnreadMessages = styled(Text)`
  background-color: ${colors.violet[501]};
  padding: 2px 8px;
  border-radius: 10px;
  color: ${colors.grey[1000]};
  min-width: 26px;
  text-align: center;
`;

export const MutedIconDesktopContainer = styled.div`
  color: ${colors.violet[700]};
  margin-left: 8px;
  display: flex;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const MutedIconMobileContainer = styled.div<{
  hasUnreadMessages: boolean;
}>`
  color: ${colors.violet[700]};
  display: flex;
  margin-right: ${({ hasUnreadMessages }) =>
    hasUnreadMessages ? "8px" : undefined};
  margin-top: ${({ hasUnreadMessages }) =>
    hasUnreadMessages ? undefined : "2px"};

  @media (min-width: 767px) {
    display: none;
  }
`;

export const TextAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BellContainer = styled.div`
  width: 110%;
  height: 70px;
  position: absolute;
  top: 0;
  right: -8px;
  padding-right: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 7px;
  z-index: ${({ theme }) => theme.zIndex.regular};
  text-align: right;

  background: ${colors.gradients.grey[100]};
  color: ${colors.grey[50]};

  @media (min-width: 767px) {
    display: none;
  }
`;
