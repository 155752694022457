import styled from "styled-components";

export const FabButtonWrap = styled.div`
  position: fixed;
  bottom: 76px;
  right: 16px;
  z-index: 999;
  button {
    height: 64px;
    width: 64px;
    border-radius: 24px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
