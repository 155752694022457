import styled from "styled-components";

import { SCodeInputBasicProps } from "./types";

export const Label = styled.label`
  display: block;
  margin-bottom: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const Input = styled.input`
  width: 36px;
  height: 52px;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  font-size: 16px;
  -webkit-appearance: none;
  outline: none;
  outline-width: 0;
  border: 2px solid transparent;
  -webkit-tap-highlight-color: transparent;
`;

export const Container = styled.div<SCodeInputBasicProps>`
  display: block;
  position: relative;
  text-align: center;
  font-family: "WeSchoolSans";
  ${Label} {
    color: ${({ theme, variant }) => theme.codeInput[variant].label};
  }
  ${Input} {
    background: ${({ theme, variant }) => theme.codeInput[variant].background};
    &:focus:not([disabled]) {
      background: ${({ theme, variant }) =>
        theme.codeInput[variant].background};
      border: ${({ theme, variant }) =>
        `2px solid ${theme.codeInput[variant].focus.border}`};
    }
  }
`;
