import styled, { css } from "styled-components";

export const ModalContentContainer = styled.div`
  text-align: center;
  padding: 0 24px;

  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 16px;
    `}
`;

export const ModalContentTitle = styled.p`
  text-align: center;
  margin: 24px 16px 8px;
`;

export const ModalContentSubtitle = styled.p`
  text-align: center;
  margin: 0px 16px 16px;
`;

export const UserGroupCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  margin-bottom: 8px;
  text-align: left;

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: 1px solid ${theme.colors.error[500]};
    `}
`;

export const UserFullName = styled.span`
  flex: 1;
  margin-right: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UserSurname = styled.span`
  font-weight: 600;
`;

export const GroupName = styled.span`
  max-width: 70%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ModalFooterContainer = styled.div`
  padding: 24px 24px 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 16px;
`;

export const LoaderContainer = styled.div`
  text-align: center;
  padding: 60px 0px 32px;
`;

export const LoaderTitleContainer = styled.div`
  display: block;
  width: 100%;
  margin-top: 24px;
`;

export const ModalBannerContainer = styled.div`
  padding: 8px 32px 0;

  @media screen and (max-width: 767px) {
    padding: 8px 24px 0;
  }
`;
