import styled from "styled-components";
import { motion } from "framer-motion";
import colors from "@ds_themes/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% + 40px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
`;

export const MotionDiv = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const SlideshowDot = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) =>
    isActive ? colors.violet[501] : colors.grey[800]};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const DotContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;
