import { Box, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { ResourcePicker } from "src/js/components/ResourcePicker";
import {
  RESOURCE_PICKER_ITEMS,
  RESOURCE_PICKER_SECTION
} from "src/js/components/ResourcePicker/ResourcePicker.const";
import { ResourcePickerItem } from "src/js/components/ResourcePicker/ResourcePicker.types";
import {
  linkRegex,
  sanitizeIfVimeoUrl
} from "src/js/components/ResourcePicker/components/AddLinkResource/AddLinkResource.utils";
import useResourceLinkUpload from "src/js/components/modules/ModuleResourceNewItem/hooks/useResourceUpdateModuleElements";
import { ElementDataType } from "src/js/components/modules/ModuleResourceNewItem/types";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { saveResourceNew } from "src/js/repository/resourceRepository";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import createUrl from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";

import * as S from "./AddOrPasteLink.styles";

const AddOrPasteLink = ({
  resourceElementIndex,
  prepareModuleResourcePayload
}: {
  resourceElementIndex: number;
  prepareModuleResourcePayload: (fileList: FileList) => void;
}) => {
  const {
    SpaceStore: { activeSpaceSlug: spaceSlug },
    GroupStore: { groupId, groupName },
    BoardsStore: {
      getActiveBoardId,
      filterUploadingElementIndexes,
      setUploadingProgressOfBoardElement,
      setUploadingElementIndexes,
      setErrorInUploadingElement,
      errorUploadingElements
    }
  } = useStores();
  const setUploadingProgress = (elementId: number, progress: number) =>
    setUploadingProgressOfBoardElement(elementId, progress);
  const { handleResourceLinkUpload, handleResourceTextUpload } =
    useResourceUpload(setUploadingProgress);
  const { attachResourceToModuleElement } =
    useResourceLinkUpload(resourceElementIndex);

  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const [linkValue, setLinkValue] = useState("");
  const failedTopUploadLink =
    errorUploadingElements[resourceElementIndex] &&
    errorUploadingElements[resourceElementIndex].hasError;

  const handleSubmitOfPickerElements = (
    item: ResourcePickerItem,
    elementData: ElementDataType
  ) => {
    switch (item) {
      case RESOURCE_PICKER_ITEMS.LINK:
      case RESOURCE_PICKER_ITEMS.GDOCS:
      case RESOURCE_PICKER_ITEMS.GSHEET:
      case RESOURCE_PICKER_ITEMS.GSLIDES:
      case RESOURCE_PICKER_ITEMS.VIMEO:
      case RESOURCE_PICKER_ITEMS.YOUTUBE:
        attachResourceToModuleElement(
          elementData.id,
          elementData.name,
          { type: "LINK" },
          () => filterUploadingElementIndexes(resourceElementIndex)
        );
        break;
      case RESOURCE_PICKER_ITEMS.EXERCISE:
        attachResourceToModuleElement(elementData.id, elementData.name, {
          type: "EXERCISE",
          selected: true,
          exercise: {
            group_id: groupId,
            group_name: groupName,
            id: elementData.id,
            name: elementData.name,
            title: elementData.name
          }
        });
        break;
      case RESOURCE_PICKER_ITEMS.EDITOR:
      case RESOURCE_PICKER_ITEMS.AIEDITOR:
        attachResourceToModuleElement(
          elementData.id,
          elementData.name,
          {
            type: "TEXT"
          },
          () => filterUploadingElementIndexes(resourceElementIndex)
        );
        break;
      case RESOURCE_PICKER_ITEMS.DROPBOX:
        attachResourceToModuleElement(
          elementData.id,
          elementData.name,
          {
            type: RESOURCE_PICKER_ITEMS.DROPBOX
          },
          () => filterUploadingElementIndexes(resourceElementIndex)
        );
        break;
      case RESOURCE_PICKER_ITEMS.GDRIVE:
        attachResourceToModuleElement(
          elementData.id,
          elementData.name,
          {
            type: RESOURCE_PICKER_ITEMS.GDRIVE
          },
          () => filterUploadingElementIndexes(resourceElementIndex)
        );
        break;
      default:
        break;
    }
  };

  return (
    <Box width="100%">
      <S.Container id="add-or-paste-link-container">
        <ResourcePicker
          section={RESOURCE_PICKER_SECTION.MODULES}
          onSubmitCallbackSuccess={(
            item: ResourcePickerItem,
            successData: ElementDataType
          ) => {
            handleSubmitOfPickerElements(item, successData);
          }}
          onSubmitCallbackError={() =>
            setErrorInUploadingElement(resourceElementIndex, true)
          }
          prepareModuleResourcePayload={prepareModuleResourcePayload}
          setUploadingProgress={setUploadingProgress}
          elementIndex={resourceElementIndex}
          onStartOfUpload={() =>
            setUploadingElementIndexes(resourceElementIndex)
          }
          openNewResourceCustomWizard={item => {
            // I'm saving the resource right away and then redirect to the edit view
            handleResourceTextUpload(translate("text_new"), "<p></p>", 0).then(
              resource => {
                handleSubmitOfPickerElements(
                  item.item,
                  resource as unknown as ElementDataType
                );

                const url = createUrl("module_element_edit", {
                  group_id: groupId,
                  module_id: getActiveBoardId,
                  board_element_id: String(resource.id)
                });

                navigateTo(url);
              }
            );
          }}
          defaultIsOpen
        />
        <S.LinkInput
          theme={whiteTheme}
          label="hiddenLabel"
          hideLabel
          name="linkInput"
          value={linkValue.toLowerCase().trim()}
          onChange={event => {
            setLinkValue(event.target.value);
            if (failedTopUploadLink) {
              setErrorInUploadingElement(resourceElementIndex, false);
            }
          }}
          placeholder={translate("add_content_paste_link")}
          rightSection={
            linkRegex.test(linkValue.toLowerCase().trim()) && (
              <S.ArrowIconContainer
                onClick={() => {
                  setUploadingElementIndexes(resourceElementIndex);
                  const sanitizedLink: string = sanitizeIfVimeoUrl({
                    url: linkValue.trim()
                  });
                  handleResourceLinkUpload(sanitizedLink, resourceElementIndex)
                    .then(response => {
                      attachResourceToModuleElement(
                        response.id,
                        response.name,
                        { type: "LINK" },
                        () =>
                          filterUploadingElementIndexes(resourceElementIndex)
                      );
                    })
                    .catch(error => {
                      if (error && error.status === 403) {
                        saveResourceNew({
                          name: translate("private_resource_title"),
                          type: "LINK",
                          spaceSlug,
                          publicUrls: [sanitizedLink]
                        }).then(response => {
                          attachResourceToModuleElement(
                            response.id,
                            response.name,
                            { type: "LINK" },
                            () =>
                              filterUploadingElementIndexes(
                                resourceElementIndex
                              )
                          );
                        });
                      } else {
                        setErrorInUploadingElement(resourceElementIndex, true);
                      }
                    });
                }}
              >
                <S.ArrowIcon icon="arrowRight" width={16} height={16} />
              </S.ArrowIconContainer>
            )
          }
        />
      </S.Container>
      <Box marginTop={12} textAlign="center" height="32px">
        {failedTopUploadLink ? (
          <S.ErrorMessageContainer>
            <Icon icon="dangerCircle" />
            <Text type="formSmallCaption">
              {errorUploadingElements[resourceElementIndex].errorMessage
                ? errorUploadingElements[resourceElementIndex].errorMessage
                : translate("uploading_module_element_error_message")}
            </Text>
          </S.ErrorMessageContainer>
        ) : (
          <S.Description type="formSmallCaption">
            {translate("add_content_paste_link_description")}
          </S.Description>
        )}
      </Box>
    </Box>
  );
};

export default observer(AddOrPasteLink);
