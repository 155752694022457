import styled from "styled-components";

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px;
`;

export const MenuButton = styled.button<{ isActive?: boolean }>`
  position: relative;
  border: none;
  padding: 0;
  width: 28px;
  height: 28px;
  flex: 0 0 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.blueGrey[900] : "none"};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.blueGrey[300] : theme.colors.blueGrey[500]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey[900]};
  }
`;

export const MenuSeparator = styled.div`
  margin: 0 4px;
  width: 1px;
  flex: 0 0 1px;
  height: 20px;
  background: ${({ theme }) => theme.colors.blueGrey[800]};
`;
