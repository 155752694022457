import { useState } from "react";
import { useHistory } from "react-router";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { BoardElement, BoardElementDetail } from "src/js/types";
import { modalPage } from "src/legacy/models/modalPageModel";
import { navigateTo } from "src/legacy/modules/history";
import { createURL } from "src/legacy/modules/utility";

// TODO improve string literal using const

const useBoardElementNavigation = ({
  indexDefault,
  boardElement,
  allBoardElements
}: {
  indexDefault: number;
  boardElement: BoardElementDetail;
  allBoardElements: BoardElement[];
}) => {
  const {
    UIStore: { isLayoutModeMobile },
    GroupStore: { activeGroup }
  } = useStores();
  const [currentIndex, setCurrentIndex] = useState(indexDefault);
  const [showComments, setShowComments] = useState(!isLayoutModeMobile);
  const history = useHistory();
  const { trackVirtualView } = useViewTracking();

  const updatePathWithNewId = (newId: number) => {
    const newPath = history.location.pathname.replace(
      /[^/]*$/,
      newId.toString()
    );
    history.replace(newPath);
  };

  const onPrevBoardElement = () => {
    const newIndex = currentIndex - 1;

    if (newIndex < 0) return;

    setCurrentIndex(newIndex);
    setShowComments(showComments && !isLayoutModeMobile);

    _trackEvent("Lessons", "LessonsInteractions", "Prev");

    updatePathWithNewId(allBoardElements[newIndex].id);
  };

  const onNextBoardElement = () => {
    if (currentIndex >= allBoardElements.length - 1) return;

    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);

    setShowComments(showComments && !isLayoutModeMobile);
    _trackEvent("Lessons", "LessonsInteractions", "Next");

    updatePathWithNewId(allBoardElements[newIndex].id);
  };

  const toggleComments = () => {
    const newShowComments = !showComments;
    setShowComments(newShowComments);

    if (!newShowComments) {
      setTimeout(() => {
        const scrollId = document.getElementById("scrollId");
        if (!scrollId) return;
        scrollId.scrollIntoView(true);
      }, 200);
    }
    trackVirtualView(
      newShowComments
        ? WeSchoolVirtualViewDescriptions.Groups.Boards.OpenCommentBar
        : WeSchoolVirtualViewDescriptions.Groups.Boards.CloseCommentBar
    );
  };

  const reportAbuse = () => {
    const reportAbuseUrl = createURL("report_abuse_resource", {
      group_id: activeGroup.id,
      resource_id: boardElement.resource.id
    });
    navigateTo(reportAbuseUrl);
    modalPage.hide();
  };

  return {
    onPrevBoardElement,
    onNextBoardElement,
    toggleComments,
    reportAbuse,
    currentIndex,
    showComments
  };
};

export default useBoardElementNavigation;
