import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./ProgressBar.styles";
import { ProgressBarProps } from "./types";

const ProgressBar: FC<ProgressBarProps> = ({
  theme,
  variant = "thin",
  percentage = 0,
  className
}) => {
  return (
    <ThemeProvider theme={theme}>
      {variant === "thick" ? (
        <S.ProgressBarGutter className={className}>
          <S.LineProgressBar percentage={percentage} variant={variant} />
        </S.ProgressBarGutter>
      ) : (
        <S.LineProgressBar
          className={className}
          percentage={percentage}
          variant={variant}
        />
      )}
    </ThemeProvider>
  );
};

export default ProgressBar;
