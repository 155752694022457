import { useEffect } from "react";
import { useUserChannel } from "src/js/realtime";

import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";

const RealtimeUserSubscribe = () => {
  const { UserStore, UserTasksStore } = useStores();
  const userChannel = useUserChannel(UserStore.activeUser);

  useEffect(() => {
    if (!userChannel) return () => {};

    const subscriptions = [];

    subscriptions.push(
      userChannel.subscribe("general-event", data => {
        RealTimeDispatcher.trigger(data.action.name, data);
      })
    );

    subscriptions.push(
      userChannel.subscribe("platform_task_completed", data => {
        UserTasksStore.realtimeTaskCompletion(data);
      })
    );

    subscriptions.push(
      userChannel.subscribe("resource_preview_generated", data => {
        RealTimeDispatcher.trigger("resource_preview_generated", data);
      })
    );

    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    };
  }, [userChannel]);

  return null;
};

export default observer(RealtimeUserSubscribe);
