/**
 *
 * @param {*} r
 * @param {*} g
 * @param {*} b
 * @returns return the value of lum of the color rgb passed
 */

import { DefaultTheme } from "styled-components";
import {
  createActionButtonTheme,
  createToggleTheme,
  createPageNavTheme,
  createDoodleloaderTheme,
  createDropdownMultipleTheme,
  createDropdownTheme,
  createInputTheme,
  createPictureUploaderTheme,
  createCodeInputTheme,
  createCardTheme,
  createLabelTheme,
  createToastTheme,
  createButtonTheme,
  createAvatarTheme,
  createLivetagTheme,
  createContentSwitcherTheme,
  createCtaTertiaryTheme,
  createEventcardTheme,
  createLinkTheme,
  createLiveButtonTheme,
  createPostTheme,
  createProgressBarTheme,
  createPromoBannerTheme,
  createRadioGroupTheme,
  createResourceProgressBarTheme,
  createSearchbarWithSuggestionTheme,
  createSidemenuTheme,
  createChatBubbleTheme,
  createWrapperchatavatarTheme,
  createSearchbarTheme,
  createDividerTheme,
  createPickerTheme,
  createTablinkTheme,
  createCheckboxTheme,
  createGoogleButtonTheme,
  createSocialLoginButtonTheme,
  createTagTheme,
  createLivedurationTheme,
  createGroupMemberTheme,
  createCalendarTheme,
  createDropdownContextualTheme,
  createAnalyticsBoxTheme,
  createDatepickerTheme,
  createAnalyticsTooltipTheme,
  createClosableErrorTheme,
  createClosableFeedbackTheme,
  createAknowledgmentAlertTheme,
  createContextualErrorTheme,
  createSpecificErrorTheme,
  createContextualAlertTheme,
  createConfirmationAlertTheme,
  createLivePillTheme,
  createContextualFeedbackTheme,
  createToolbarSpaceItemTheme,
  createToolbarMenuItemTheme,
  createSidebarGroupItemTheme,
  createEmptyListCardTheme,
  createChipTheme,
  createActionItemsDropdownTheme
} from "@ds_themes/create";

import colors from "./colors";
import { Palette, PaletteProps } from "./types";
import { createCheckIndicatorTheme } from "./create/checkIndicator";
import { createTooltipTheme } from "./create/tooltip";
import zIndex from "./zIndex";

const luminance = (r: number, g: number, b: number) => {
  const [rRes, gRes, bRes] = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return rRes * 0.2126 + gRes * 0.7152 + bRes * 0.0722;
};

/**
 * @param {*} hex
 * @returns return the rgb values of hex color passed
 */

const fromHexToRgb = (hex: string) => {
  if (!hex) return [0, 0, 0];
  const hexCol = hex.replace(/#/, "");
  const r = parseInt(hexCol.substring(0, 2), 16);
  const g = parseInt(hexCol.substring(2, 4), 16);
  const b = parseInt(hexCol.substring(4, 6), 16);

  return [r, g, b];
};

/**
 * @param {*} hex
 * @returns return true if the color is bright (over 0.65 lum)
 */

export const isBrightColor = (hex: string) => {
  const [r, g, b] = fromHexToRgb(hex);

  const lum = luminance(r, g, b);

  return lum > 0.55;
};

/**
 *
 * @param {*} hex
 * @param {*} percent
 * @returns new string (hex color) that is {hex} props with brightness increased by {percent}
 */

export const increaseBrightness = (hex: string, percent: number) => {
  const [r, g, b] = fromHexToRgb(hex);

  return `#${(0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
    .toString(16)
    .substring(1)}${(0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
    .toString(16)
    .substring(1)}${(0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
    .toString(16)
    .substring(1)}`;
};

/**
 *
 * @param {*} hex
 * @param {*} percent
 * @returns new string (hex color) that is {hex} props with brightness decreased by {percent}
 */

export const decreaseBrightness = (hex: string, percent: number) => {
  const [r, g, b] = fromHexToRgb(hex);

  return `#${(0 | ((1 << 8) + (r * (100 - percent)) / 100))
    .toString(16)
    .substring(1)}${(0 | ((1 << 8) + (g * (100 - percent)) / 100))
    .toString(16)
    .substring(1)}${(0 | ((1 << 8) + (b * (100 - percent)) / 100))
    .toString(16)
    .substring(1)}`;
};

/**
 *
 * @param {*} hex
 * @param {*} percent
 * @returns the correct shade of color (bright or dark) of {hex} based on its light
 */

export const getContrastShade = (hex: string, percent: number) => {
  return isBrightColor(hex)
    ? decreaseBrightness(hex, percent)
    : increaseBrightness(hex, percent);
};

/**
 *
 * @param {*} hex
 * @returns correct shade for disabled options
 */

export const getDisabledShade = (hex: string) => {
  return isBrightColor(hex)
    ? decreaseBrightness(hex, 20)
    : increaseBrightness(hex, 60);
};

/**
 *
 * @param {*} hex1
 * @param {*} hex2
 * @returns compare hex1 and hex2 and return {true} if the contrast is ok (over 4), false otherwise
 */

export const haveEnoughContrast = (hex1: string, hex2: string) => {
  const [r1, g1, b1] = fromHexToRgb(hex1);
  const [r2, g2, b2] = fromHexToRgb(hex2);

  const lum1 = luminance(r1, g1, b1);

  const lum2 = luminance(r2, g2, b2);

  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05) > 4;
};

/**
 *
 * @param {*} hex
 * @param {*} forceHexBright
 * @param {*} forceHexDark
 * @returns if {hex} is bright, return {color.grey[50]} (or forceHexBright is passed), if dark return {color.grey[1000]} (or forceHexDark is passed)
 */

export const getHighContrastColor = (
  hex: string,
  forceHexBright?: string,
  forceHexDark?: string
) => {
  return isBrightColor(hex)
    ? forceHexBright || colors.grey[50]
    : forceHexDark || colors.grey[1000];
};

export const createPalette = ({
  primary,
  secondary = colors.yellow[500],
  grey = colors.grey,
  blueGrey = colors.blueGrey,
  lagoon = colors.lagoon,
  coldGrey = colors.coldGrey,
  violet = colors.violet,
  background,
  error = colors.error,
  yellow = colors.yellow,
  success = colors.success,
  warning = colors.yellow[500],
  appearence = "light"
}: PaletteProps): Palette => {
  return {
    appearence,
    general: {
      background,
      contrastText: getHighContrastColor(background)
    },
    grey,
    blueGrey,
    coldGrey,
    violet,
    error,
    success,
    lagoon,
    yellow,
    primary: {
      main: primary,
      darker: decreaseBrightness(primary, 40),
      dark: decreaseBrightness(primary, 20),
      light: increaseBrightness(primary, 50),
      lighter: increaseBrightness(primary, 85),
      contrastText: getHighContrastColor(primary)
    },
    secondary: {
      main: secondary,
      darker: decreaseBrightness(secondary, 40),
      dark: decreaseBrightness(secondary, 20),
      light: increaseBrightness(secondary, 50),
      lighter: increaseBrightness(secondary, 85),
      contrastText: getHighContrastColor(secondary)
    },
    warning: {
      main: warning,
      darker: decreaseBrightness(warning, 40),
      dark: decreaseBrightness(warning, 20),
      light: increaseBrightness(warning, 50),
      lighter: increaseBrightness(warning, 85),
      contrastText: getHighContrastColor(warning)
    }
  };
};

export const updatePalette = ({
  palette,
  primary,
  secondary
}: {
  palette: Palette;
  primary?: string;
  secondary?: string;
}): Palette => {
  const newPalette = { ...palette };

  if (primary) {
    newPalette.primary = {
      main: primary,
      darker: decreaseBrightness(primary, 40),
      dark: decreaseBrightness(primary, 20),
      light: increaseBrightness(primary, 50),
      lighter: increaseBrightness(primary, 85),
      contrastText: getHighContrastColor(primary)
    };
  }

  if (secondary) {
    newPalette.secondary = {
      main: secondary,
      darker: decreaseBrightness(secondary, 40),
      dark: decreaseBrightness(secondary, 20),
      light: increaseBrightness(secondary, 50),
      lighter: increaseBrightness(secondary, 85),
      contrastText: getHighContrastColor(secondary)
    };
  }

  return newPalette;
};

export const createTheme = (palette: Palette): DefaultTheme => {
  return {
    ...createActionButtonTheme(palette),
    ...createAknowledgmentAlertTheme(palette),
    ...createAvatarTheme(palette),
    ...createButtonTheme(palette),
    ...createChatBubbleTheme(palette),
    ...createCheckboxTheme(palette),
    ...createClosableErrorTheme(palette),
    ...createClosableFeedbackTheme(palette),
    ...createConfirmationAlertTheme(palette),
    ...createContextualAlertTheme(palette),
    ...createContextualErrorTheme(palette),
    ...createContextualFeedbackTheme(palette),
    ...createDropdownTheme(palette),
    ...createDropdownMultipleTheme(palette),
    ...createToggleTheme(palette),
    ...createToastTheme(palette),
    ...createInputTheme(palette),
    ...createPictureUploaderTheme(palette),
    ...createCodeInputTheme(palette),
    ...createCardTheme(palette),
    ...createContentSwitcherTheme(palette),
    ...createCtaTertiaryTheme(palette),
    ...createDoodleloaderTheme(palette),
    ...createDividerTheme(palette),
    ...createEventcardTheme(palette),
    ...createLivetagTheme(palette),
    ...createLabelTheme(palette),
    ...createLinkTheme(palette),
    ...createLiveButtonTheme(palette),
    ...createPageNavTheme(palette),
    ...createPostTheme(palette),
    ...createProgressBarTheme(palette),
    ...createPromoBannerTheme(palette),
    ...createRadioGroupTheme(palette),
    ...createResourceProgressBarTheme(palette),
    ...createSearchbarTheme(palette),
    ...createSearchbarWithSuggestionTheme(palette),
    ...createSidemenuTheme(palette),
    ...createSpecificErrorTheme(palette),
    ...createTablinkTheme(palette),
    ...createWrapperchatavatarTheme(palette),
    ...createPickerTheme(palette),
    ...createGoogleButtonTheme(palette),
    ...createSocialLoginButtonTheme(palette),
    ...createTagTheme(palette),
    ...createLivedurationTheme(palette),
    ...createGroupMemberTheme(palette),
    ...createCalendarTheme(palette),
    ...createDropdownContextualTheme(palette),
    ...createAnalyticsBoxTheme(palette),
    ...createDatepickerTheme(palette),
    ...createAnalyticsTooltipTheme(palette),
    ...createLivePillTheme(palette),
    ...createSidebarGroupItemTheme(palette),
    ...createToolbarMenuItemTheme(palette),
    ...createToolbarSpaceItemTheme(palette),
    ...createCheckIndicatorTheme(palette),
    ...createTooltipTheme(palette),
    ...createEmptyListCardTheme(palette),
    ...createChipTheme(palette),
    ...createActionItemsDropdownTheme(palette),
    zIndex
    // TODO: Fix typings
  } as unknown as DefaultTheme;
};
