import React, { useCallback, useEffect, useState } from "react";
import { BaseTextEditorMenu } from "src/js/components/global/BaseTextEditor/components";
import {
  AiExtensions,
  TextEditorOptions
} from "src/js/components/global/BaseTextEditor/EditorOptions.const";
import { useEditor } from "src/js/components/global/BaseTextEditor/hooks";
import {
  EditorExtension,
  EditorExtensionType
} from "src/js/components/global/BaseTextEditor/BaseTextEditor.types";
import { useStores } from "src/js/hooks";
import { updateTextResource } from "src/js/repository/resourceRepository";
import { Resource } from "src/js/types";
import { useTranslation } from "src/js/translation";

const textMenuExtensions: Partial<EditorExtensionType>[] = [
  EditorExtension.AI,
  EditorExtension.TextFormatting,
  EditorExtension.Bold,
  EditorExtension.Italic,
  EditorExtension.Strike,
  EditorExtension.Submenu
];

const useTextAutosaveState = ({
  resource,
  title,
  setResource,
  onSubmit
}: {
  resource: Resource;
  title: string;
  setResource: (resource: Resource) => void;
  onSubmit: (resource: Resource) => void;
}) => {
  const [isSaving, setIsSaving] = useState(false); // TODO: use this to show a spinner while saving
  const [hasDirtyFields, setHasDirtyFields] = useState(false);
  const {
    GroupStore: { groupId }
  } = useStores();
  const { translate } = useTranslation();

  const saveResource = async () => {
    if (!resource) return null;

    return updateTextResource({
      groupId,
      resourceId: resource.id,
      name: resource.name,
      textContent: resource.textContent
    }).then(res => res);
  };

  const editor = useEditor({
    content: resource?.textContent || "",
    options: {
      injectCSS: true,
      onDestroy: () => {
        saveResource().then(res => {
          setHasDirtyFields(false);
          setResource(res);
          onSubmit(res);
          setIsSaving(false);
        });
      },
      extensions: [
        ...TextEditorOptions.extensions,
        ...AiExtensions({
          aiConfig: {
            autocompletion: true,
            autocompletionOptions: {
              modelName: "gpt-4o"
            }
          },
          placeholderConfig: {
            placeholder: translate("ai_text_editor_placeholder")
          }
        })
      ]
    }
  });

  useEffect(() => {
    if (!editor || editor.isFocused) return;
    // On opening the editor, focus the first word
    // not perfect with multiple white lines
    editor.commands.focus("start");
    const firstWord = editor.getText().split(" ")[0] || "";
    editor.commands.setTextSelection({
      from: 0,
      to: firstWord ? firstWord.length + 1 : 0
    });
  }, [editor]);

  useEffect(() => {
    if (!hasDirtyFields) return () => {};

    setIsSaving(true);

    const autosaveTimer = setInterval(() => {
      saveResource().then(res => {
        setHasDirtyFields(false);
        setResource(res);
        onSubmit(res);
        setIsSaving(false);
      });
    }, 5000); // 5 seconds

    return () => {
      clearInterval(autosaveTimer);
    };
  }, [hasDirtyFields, resource]);

  useEffect(() => {
    if (!editor || !resource) return;
    setResource({
      ...resource,
      name: title,
      textContent: editor.getHTML()
    });
    setHasDirtyFields(true);
  }, [title, editor?.getHTML()]);

  const textEditorMenu = useCallback(
    () => (
      <BaseTextEditorMenu editor={editor} extensions={textMenuExtensions} />
    ),
    [editor]
  );

  return {
    saveResource,
    editor,
    isSaving,
    textEditorMenu
  };
};

export default useTextAutosaveState;
