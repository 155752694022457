import { _trackEvent } from "src/js/modules/analyticsFunction";
import { BoardElement, BoardElementDetail, Resource } from "src/js/types";

export const trackInteractions = (resource: Resource) => {
  if (["VIMEO", "YOUTUBE"].includes(resource.type)) {
    _trackEvent("Lessons", "LessonsInteractions", resource.type);
  }

  if ("fileMimeType" in resource) {
    if (resource.fileMimeType === "video/mp4") {
      _trackEvent("Lessons", "LessonsInteractions", "Video");
    }
  }
};

export const shouldUpdateLocalBoardElementCompletion = (
  boardElements: BoardElement[],
  activeBoardElement: BoardElementDetail
) => {
  const storedBoardElement = boardElements.find(
    boardEl => boardEl.id === activeBoardElement.id
  );

  if (!storedBoardElement) return false;

  return (
    storedBoardElement.resource.type !== "EXERCISE" &&
    storedBoardElement.resource.type !== "SCORM" &&
    storedBoardElement.dtype !== "boardelementdraft"
  );
};
