import styled, { css } from "styled-components";

export type ModeParams = {
  accordionMode: boolean;
};

export const TimerContainer = styled.div`
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
  text-align: left;
`;

export const CalendarDetailContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.title};

  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const EventHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 0px 15px;
  min-height: 38px;
`;

export const QuickActionContainer = styled.div<ModeParams>`
  display: inline-flex;
  margin-left: auto;
  & div {
    width: 32px;
    height: 32px;
    margin-top: 0;
  }
  & div + div {
    margin-left: 8px;
  }

  ${({ accordionMode }) =>
    accordionMode &&
    css`
      margin-top: 15px;
    `}
`;

export const EventRecipientsContainer = styled.div`
  margin-bottom: 10px;
`;

export const EventLive = styled.div<ModeParams>`
  padding-bottom: 12px;
  ${({ accordionMode }) =>
    !accordionMode &&
    css`
      padding: 0px 15px 15px;
    `};
`;

export const EventBody = styled.div<ModeParams>`
  padding: 0px 15px;
  ${({ accordionMode }) =>
    accordionMode &&
    css`
      padding: 0;
    `};
`;

export const TextContainer = styled.span`
  flex: 1;
  min-width: 0px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const EventTitle = styled.a`
  display: block;
  text-decoration: none;
  margin: 4px 15px 10px;

  &:hover {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const EventActivityBadge = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.primary3};
  color: ${({ theme }) => theme.colors.basicWhite};
  font-family: ${({ theme }) => theme.fontFamily.title};
  line-height: 20px;
  font-size: 12px;
  font-weight: 700;
  position: relative;
`;

export const LabelText = styled.div`
  margin-left: 2px;
`;

export const CompleteDateRange = styled.div<ModeParams>`
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.slate};
  ${({ accordionMode }) =>
    accordionMode &&
    css`
      font-size: 12px;
    `}
`;

export const HourRange = styled.span`
  margin: 0 10px 0 4px;
  font-size: 14px;
`;

export const VerticalCenteredRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 8px;
`;

export const EventDescription = styled.p`
  margin-bottom: 10px;
`;

export const EventAttachments = styled.div`
  margin-bottom: 8px;
`;

export const EventFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.accent3};
`;

export const LockedEvent = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.colors.accent4};
  color: ${({ theme }) => theme.colors.primary2};
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`;

export const LockedEventIcon = styled.div`
  background: ${({ theme }) => theme.colors.basicWhite};
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 12px;
`;

export const RecordingResourceWrapper = styled.div`
  display: flex;
  padding: 10px 15px;
  border-radius: 6px;
  justify-content: space-between;
  &:hover {
    text-decoration: underline;
    background-color: ${({ theme }) => theme.colors.accent4};
  }
`;

export const RecordingResource = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary2};
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0px;
`;

export const SaveRecordingButton = styled.button`
  display: flex;
  align-self: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
