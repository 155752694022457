import { Box, Heading, Icon, Text, colors } from "@arcadia/design-system";
import React, { FC } from "react";
import WeschoolLogo from "src/img/icons/svg/weschool_logo.svg";
import UnloggedGoLogin from "src/js/components/unlogged/UnloggedGoLogin";
import UnloggedSupportLink from "src/js/components/unlogged/UnloggedSupportLink";
import { useStores } from "src/js/hooks";
import { Translate, useTranslation } from "src/js/translation";
import { NormalUser } from "../NormalUser";
import { Tutor } from "../Tutor";
import { Underage } from "../Underage";
import * as S from "./RegistrationOptions.styles";
import { Props } from "./types";

const InstructorAndLearnerButton = ({ label, onClick, value, icon }) => {
  return (
    <S.OptionContainer onClick={() => onClick(value)}>
      <Box paddingLeft={32}>
        <Heading level="4">
          <Translate text={label} />
        </Heading>
      </Box>
      <Box display="flex">{icon}</Box>
    </S.OptionContainer>
  );
};

const UnderageButton = ({ onClick, value }) => {
  return (
    <S.OptionContainer onClick={() => onClick(value)}>
      <Box paddingLeft={32} display="flex" flexDirection="column">
        <Heading level="4">
          <Translate text="im_an_underage_student" />
        </Heading>
        <Text type="formSubtitle">
          <Translate text="with_a_parent" />
        </Text>
      </Box>
      <Box display="flex">
        <Icon
          icon="illustration-underage"
          width="120"
          height="120"
          viewbox="0 0 164 152"
        />
      </Box>
    </S.OptionContainer>
  );
};

const RegistrationOptions: FC<Props> = ({
  onSelect,
  onReturn,
  handleRegister,
  saveTutorData,
  registerNormalUser,
  formData,
  token,
  step,
  contract,
  inviteInfo,
  whiteLayout
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const inviteGroupId = inviteInfo?.groupData?.group?.id;
  const renderRegistrationForm = () => {
    if (step === "type") {
      return (
        <>
          <InstructorAndLearnerButton
            label="im_a_teacher_title"
            value="teacher"
            onClick={onSelect}
            icon={
              <Icon
                icon="illustration-teacher"
                width="115"
                height="118"
                viewbox="0 0 164 166"
              />
            }
          />
          <InstructorAndLearnerButton
            label="im_a_student_title"
            value="student"
            onClick={onSelect}
            icon={
              <Icon
                icon="illustration-student"
                width="106"
                height="118"
                viewbox="0 0 150 166"
              />
            }
          />
          <UnderageButton onClick={onSelect} value="parent" />
        </>
      );
    }
    if (step === "tutor") {
      return (
        <Tutor
          onSubmitTutorData={saveTutorData}
          formData={formData}
          contract={contract}
        />
      );
    }
    if (step === "normal") {
      return (
        <NormalUser
          handleRegister={handleRegister}
          formData={formData}
          contract={contract}
          registerInSchool
        />
      );
    }
    if (step === "underage") {
      return <Underage handleRegister={handleRegister} formData={formData} />;
    }
    if (step === "registrationNormal") {
      return (
        <NormalUser
          formData={formData}
          handleRegister={registerNormalUser}
          registerInSchool={false}
          contract={false}
          inviteGroupId={inviteGroupId}
        />
      );
    }
    return null;
  };

  const handleStep = () => {
    switch (step) {
      case "tutor":
        return "Step 2 of 3";
      case "underage":
        return "Step 3 of 3";
      case "normal":
        return "Step 2 of 2";
      default:
        return "";
    }
  };

  if (whiteLayout || inviteGroupId) {
    return (
      <S.RegistrationInviteOptionsContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={
            step === "type" || step === "registrationNormal"
              ? "center"
              : "flex-start"
          }
          marginBottom={step === "type" ? 90 : 24}
          textAlign="center"
        >
          {step !== "type" && step !== "registrationNormal" && (
            <Box onClick={onReturn}>
              <S.Icon icon="arrowLeft" width={36} height={28} />
            </Box>
          )}
          {inviteInfo?.spaceCustomizationSettings?.whiteLabelName ? (
            <S.CustomTitleHeading level="1">
              {`${translate("registration_heading_join")} `}
              <S.InviteSpaceName>
                {inviteInfo?.spaceCustomizationSettings?.whiteLabelName}
              </S.InviteSpaceName>
            </S.CustomTitleHeading>
          ) : (
            <>
              <S.Heading level="1">
                {translate("registration_heading_join")}
              </S.Heading>
              <WeschoolLogo
                width="146"
                height="23"
                viewBox="0 0 207 32"
                color={colors.violet[501]}
              />
            </>
          )}
        </Box>
        {renderRegistrationForm()}
        <S.LoginButtonContainer isInRegisterForm={step !== "type"}>
          <UnloggedGoLogin alias={token} />
        </S.LoginButtonContainer>
        {step !== "type" && (
          <Box marginBottom={16}>
            <UnloggedSupportLink />
          </Box>
        )}
        {step !== "type" && step !== "registrationNormal" && (
          <Box textAlign="center">
            <Text type="formSubtitle">{handleStep()}</Text>
            <S.StepContainer>
              <S.Step />
            </S.StepContainer>
          </Box>
        )}
      </S.RegistrationInviteOptionsContainer>
    );
  }

  return (
    <S.RegistrationOptionsContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={
          step === "type" || step === "registrationNormal"
            ? "center"
            : "flex-start"
        }
        marginBottom={step === "type" ? 90 : 24}
      >
        {isLayoutModeMobile ? null : (
          <>
            {step !== "type" && step !== "registrationNormal" && (
              <Box onClick={onReturn}>
                <S.Icon icon="arrowLeft" width={36} height={28} />
              </Box>
            )}
            <S.Heading level="1">
              {translate("registration_heading_join")}
            </S.Heading>
            <WeschoolLogo
              width="146"
              height="23"
              viewBox="0 0 207 32"
              color={colors.violet[501]}
            />
          </>
        )}
      </Box>
      {renderRegistrationForm()}
      <S.LoginButtonContainer isInRegisterForm={step !== "type"}>
        <UnloggedGoLogin alias={token} />
      </S.LoginButtonContainer>
      {step !== "type" && (
        <Box marginBottom={16}>
          <UnloggedSupportLink />
        </Box>
      )}
      {step !== "type" && step !== "registrationNormal" && (
        <Box textAlign="center">
          <Text type="formSubtitle">{handleStep()}</Text>
          <S.StepContainer>
            <S.Step />
          </S.StepContainer>
        </Box>
      )}
    </S.RegistrationOptionsContainer>
  );
};

export default RegistrationOptions;
