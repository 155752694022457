export const DROPDOWN_OPTIONS = [
  { key: 0, value: "admin" },
  { key: 1, value: "tutor" },
  { key: 2, value: "teacher" }
];

export const FILTER_DROPDOWN_VARIANT = {
  DEFAULT: "default",
  LIGHT: "light",
  DARK: "dark"
};
