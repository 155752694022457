/* eslint-disable lines-between-class-members */
import { addDays, endOfMonth, format, isBefore, startOfMonth } from "date-fns";
import { isEqual } from "lodash";
import { makeAutoObservable, reaction, toJS } from "mobx";
import { AGENDA_MONTH_MODE } from "src/js/pages/calendar/Calendar.const";

/**
 * @name AgendaStore
 *
 * @description
 * gets and manipulates agenda data
 */
class AgendaStore {
  $agendaDaysMap = new Map();
  $agendaDaysArray = [];
  $agendaViewIsActive = false;
  $lastViewDate = null;
  $firstViewDate = null;
  $navigationDate = null;
  $agendaEventType = [];
  $selectedEventAgendaKey = null;
  $numberOfRowsAdded = 0;
  $shouldUpdateNumberOfRows = false;

  constructor({ calendarStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.calendarStore = calendarStore;
    this.dispose();
  }

  manipulateAgendaEvents = events => {
    let jsEvents = toJS(events);
    const initialNumberOfRows = this.$agendaDaysMap.size;
    this.$agendaDaysMap.clear();

    if (this.$agendaEventType.length === 1) {
      jsEvents = jsEvents.filter(
        element => element.type === this.$agendaEventType[0]
      );
    }

    const setPlaceholder = (date, mode) => {
      const formattedDate = format(date, "yyyy-MM-dd");
      if (!this.$agendaDaysMap.has(formattedDate)) {
        this.$agendaDaysMap.set(formattedDate, [mode]);
      } else {
        this.$agendaDaysMap.set(formattedDate, [
          ...this.$agendaDaysMap.get(formattedDate),
          mode
        ]);
      }
    };

    if (this.$navigationDate) {
      const firstPlaceholderDate = startOfMonth(new Date(this.$navigationDate));
      setPlaceholder(firstPlaceholderDate, AGENDA_MONTH_MODE);
    }

    jsEvents.forEach((event, index) => {
      let currentDay = new Date(event.start);
      if (this.$navigationDate) {
        currentDay = new Date(
          Math.max(currentDay, new Date(this.$navigationDate))
        );
      }

      const placeholderDate = startOfMonth(currentDay);

      if (index === 0) {
        setPlaceholder(placeholderDate, AGENDA_MONTH_MODE);
      }

      while (
        !this.$agendaDaysMap.has(format(placeholderDate, "yyyy-MM-dd")) ||
        !this.$agendaDaysMap
          .get(format(placeholderDate, "yyyy-MM-dd"))
          .includes(AGENDA_MONTH_MODE)
      ) {
        setPlaceholder(placeholderDate, AGENDA_MONTH_MODE);
        placeholderDate.setMonth(placeholderDate.getMonth() - 1);
      }

      while (
        isBefore(currentDay, new Date(event.end)) ||
        isEqual(currentDay, new Date(event.end))
      ) {
        if (
          !this.$navigationDate ||
          !isBefore(currentDay, startOfMonth(new Date(this.$navigationDate))) ||
          isEqual(currentDay, startOfMonth(new Date(this.$navigationDate)))
        ) {
          const formattedDay = event.lastAllDay
            ? format(currentDay, "yyyy-MM-dd")
            : format(currentDay, "yyyy-MM-dd");
          setPlaceholder(new Date(formattedDay), event.id);
        }
        currentDay = addDays(currentDay, 1);
      }
    });

    this.$agendaDaysArray = [...this.$agendaDaysMap.entries()].sort();

    if (this.$shouldUpdateNumberOfRows) {
      this.$numberOfRowsAdded = this.$agendaDaysMap.size - initialNumberOfRows;
      this.setShouldUpdateNumberOfRows(false);
    }

    if (this.$agendaDaysArray.length >= 1) {
      const lastDate =
        events && events.length && events[events.length - 1].start;
      this.setLastViewDate(new Date(lastDate));

      if (this.$agendaDaysArray.length > 1) {
        this.setFirstViewDate(
          endOfMonth(new Date(this.$agendaDaysArray[1][0]))
        );
      }
    }
  };

  setAgendaViewIsActive = agendaViewIsActive => {
    this.$agendaViewIsActive = agendaViewIsActive;
  };

  setNavigationDate = navigationDate => {
    this.$navigationDate = navigationDate;
  };

  setFirstViewDate = firstViewDate => {
    this.$firstViewDate = firstViewDate;
  };

  setLastViewDate = lastViewDate => {
    this.$lastViewDate = lastViewDate;
  };

  setAgendaEventTypeFilter = typesArray => {
    this.$agendaEventType = typesArray;
    this.manipulateAgendaEvents(this.calendarStore.events);
  };

  resetAgendaEventTypeFilter = () => {
    this.$agendaEventType = [];
  };

  setSelectedEventAgendaKey = selectedEventAgendaKey => {
    this.$selectedEventAgendaKey = selectedEventAgendaKey;
  };

  setShouldUpdateNumberOfRows = shouldUpdateNumberOfRows => {
    this.$shouldUpdateNumberOfRows = shouldUpdateNumberOfRows;
  };

  resetNumberOfRowsAdded = () => {
    this.$numberOfRowsAdded = 0;
  };

  get agendaDays() {
    return this.$agendaDaysArray;
  }

  get agendaViewIsActive() {
    return this.$agendaViewIsActive;
  }

  get firstViewDate() {
    return this.$firstViewDate;
  }

  get lastViewDate() {
    return this.$lastViewDate;
  }

  get agendaFilter() {
    return this.$agendaEventType;
  }

  get selectedEventAgendaKey() {
    return this.$selectedEventAgendaKey;
  }

  get numberOfRowsAdded() {
    return this.$numberOfRowsAdded;
  }

  dispose = () => {
    reaction(
      () => this.calendarStore.events,
      events => {
        if (!this.$agendaViewIsActive) return;
        this.manipulateAgendaEvents(events);
      }
    );
  };
}

export default AgendaStore;
