import styled from "styled-components";

const separatorHeight = 60;
const pillHeight = 24;

const Pill = styled.div`
  height: ${pillHeight}px;
  border-radius: 16px;
  padding: 0px 12px;
  span {
    line-height: 24px;
  }
`;

export const PillStickyDay = styled(Pill)`
  background-color: ${({ theme }) => theme.primaryColor[800]};
  color: ${({ theme }) => theme.colors.grey[50]};
  position: sticky;
  top: 10px;
  z-index: 1;
  margin: ${(separatorHeight - pillHeight) / 2}px auto;
  width: fit-content;
`;

export const PillUnread = styled(Pill)`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  top: ${(separatorHeight - pillHeight) / 2}px;
  right: 20px;
`;

const Separator = styled.div`
  width: 100%;
  height: ${separatorHeight}px;
  &:before {
    content: "";
    position: absolute;
    top: ${separatorHeight / 2 - 1}px;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.primaryColor[800]};
  }
`;

export const SeparatorRelative = styled(Separator)`
  position: relative;
`;

export const SeparatorAbsolute = styled(Separator)`
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const DayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
