import { colors } from "@arcadia/design-system";
import styled, { css } from "styled-components";

const variantOptions = theme => ({
  dark: {
    backgroundColor: colors.coldGrey[900],
    color: colors.grey[50],
    borderColor: colors.coldGrey[900],
    placeholderColor: colors.blueGrey[500],
    focus: {
      background: colors.coldGrey[900],
      color: colors.grey[50],
      border: theme.primaryColor[500]
    },
    error: {
      background: colors.coldGrey[900],
      color: colors.error[300],
      border: colors.error[300]
    },
    disabled: {
      background: colors.coldGrey[900],
      color: colors.blueGrey[800],
      border: colors.grey[1000]
    }
  },

  light: {
    backgroundColor: colors.grey[1000],
    color: colors.grey[50],
    borderColor: colors.grey[1000],
    placeholderColor: colors.blueGrey[300],
    focus: {
      background: colors.grey[1000],
      color: colors.grey[50],
      border: theme.primaryColor[500]
    },
    error: {
      background: colors.grey[1000],
      color: colors.error[300],
      border: colors.error[300]
    },
    disabled: {
      background: colors.grey[1000],
      color: colors.blueGrey[800],
      border: colors.grey[1000]
    }
  }
});

export const AlignContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TimePickerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}

  ${({ hasLabel, labelDirection }) =>
    hasLabel &&
    css`
      label {
        width: ${labelDirection === "from_top" ? "100%" : "180px"};
        padding-bottom: 12px;
      }
      @media screen and (max-width: 767px) {
        label {
          width: 100%;
        }
      }
    `};
`;

export const TimeInputWrapper = styled.div`
  position: relative;
  .ws-time-input {
    flex-grow: 1;
    width: inherit;
    display: block;
    padding: 0 20px;
    max-height: 40px;
    max-width: 90px;
    line-height: 40px;
    transition: 0.3s;
    font-size: 16px;
    text-align: center;
    border: none;
    box-shadow: none;
    font-weight: 500;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 20px;

    ${({ variant, theme }) =>
      variant &&
      css`
        background-color: ${variantOptions(theme)[variant].backgroundColor};
        color: ${variantOptions(theme)[variant].color};
        border: 1px solid ${variantOptions(theme)[variant].borderColor};

        ::-webkit-input-placeholder {
          color: ${variantOptions(theme)[variant].placeholderColor} !important;
        }

        :-moz-placeholder {
          color: ${variantOptions(theme)[variant].placeholderColor} !important;
        }

        ::-moz-placeholder {
          color: ${variantOptions(theme)[variant].placeholderColor} !important;
        }

        :-ms-input-placeholder {
          color: ${variantOptions(theme)[variant].placeholderColor} !important;
        }

        &:hover,
        &:focus {
          background-color: ${variantOptions(theme)[variant].focus
            .background}!important;
          color: ${variantOptions(theme)[variant].focus.color}!important;
          border-color: ${variantOptions(theme)[variant].focus
            .border}!important;
        }

        ${({ hasError }) =>
          hasError &&
          css`
            background-color: ${variantOptions(theme)[variant].error
              .background};
            color: ${variantOptions(theme)[variant].error.color};
            border-color: ${variantOptions(theme)[variant].error.border};

            ::-webkit-input-placeholder {
              color: ${variantOptions(theme)[variant].error.color} !important;
            }

            :-moz-placeholder {
              color: ${variantOptions(theme)[variant].error.color} !important;
            }

            ::-moz-placeholder {
              color: ${variantOptions(theme)[variant].error.color} !important;
            }

            :-ms-input-placeholder {
              color: ${variantOptions(theme)[variant].error.color} !important;
            }
          `}

        ${({ disabled }) =>
          disabled &&
          css`
            cursor: not-allowed !important;
            opacity: 1;
            -webkit-text-fill-color: ${variantOptions(theme)[variant].disabled
              .color};
            -webkit-opacity: 1;
            background-color: ${variantOptions(theme)[variant].disabled
              .background};
            color: ${variantOptions(theme)[variant].disabled.color};
            border-color: ${variantOptions(theme)[variant].disabled.border};

            &:hover,
            &:focus {
              background-color: ${variantOptions(theme)[variant].disabled
                .background}!important;
              color: ${variantOptions(theme)[variant].disabled.color}!important;
              border-color: ${variantOptions(theme)[variant].disabled
                .border}!important;
            }
          `}
      `}
  }
`;

export const SuggestionDropdownBody = styled.div`
  position: absolute;
  top: 45px;
  background-color: ${({ theme }) => theme.colors.basicWhite};
  border: 1px solid ${({ theme }) => theme.colors.lightBlueGrey};
  border-radius: 16px;
  max-height: 220px;
  width: 190px;
  z-index: ${({ theme }) => theme.zIndex.comboBody};
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
  -webkit-padding-end: 0;
  scrollbar-color: ${({ theme }) =>
    theme.colors.accent3 + theme.colors.accent4};
  scrollbar-width: thin;

  ${({ suggestionAlignedLeft }) =>
    suggestionAlignedLeft &&
    css`
      right: 0;
    `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}

  ::-webkit-scrollbar {
    width: 25px;
    height: 25px;
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3};
    border: 10px solid transparent;
    background-clip: padding-box;
    border-radius: 13px;
    min-height: 60px;
    &:hover {
      background: ${({ theme }) => theme.colors.accent2};
      background-clip: padding-box;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.accent4};
    border: 10px solid transparent;
    background-clip: padding-box;
    border-radius: 13px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const SuggestionDropdownElement = styled.div`
  font-weight: 500;
  height: ${({ theme }) => theme.touchDimension};
  line-height: ${({ theme }) => theme.touchDimension};
  padding: 0 10px;
  border-radius: 20px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-color: ${colors.coldGrey[900]};
    cursor: pointer;
  }
`;

export const StringValue = styled.span`
  font-size: 16px;
  width: 40px;
  display: inline-block;
`;

export const IncrementValue = styled.span`
  margin-left: 30px;
  font-size: 12px;
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 5px;
`;
