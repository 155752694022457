import React, { forwardRef, useRef, useState, useEffect } from "react";
import { Icon, SearchBar, Text, Input, Box } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import countryCodeJSON from "./countryCodes.json";

import * as S from "./InputPhone.styles";
import { Country, InputPhoneProps } from "./types";

const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  (
    {
      theme,
      country,
      onChangeCountry,
      value,
      onChangeValue,
      showSearchLabel = false,
      fromTop,
      rightSection,
      error,
      name,
      placeholder,
      label
    },
    ref
  ) => {
    const [isOpenDropdown, setIsOpen] = useState(false);
    const [filterString, setFilterString] = useState("");
    const { whiteTheme } = useTheme();
    const { translate } = useTranslation();
    const dropdownRef = useRef<HTMLDivElement>();

    useEffect(() => {
      // TODO: Proper type prop in DS Input component
      const phoneInput = document.getElementById(
        "inputTextTelephonenumber"
      ) as HTMLInputElement;
      if (phoneInput && phoneInput.type) {
        phoneInput.type = "number";
      }
    }, []);

    const handleHideDropdown: React.FocusEventHandler<HTMLDivElement> = e => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setIsOpen(false);
      }
    };

    const options = countryCodeJSON
      .filter(({ dial_code, name: countryName, code }) => {
        return (
          dial_code.includes(filterString) ||
          countryName.toLowerCase().includes(filterString.toLowerCase()) ||
          code.toLowerCase().includes(filterString.toLowerCase())
        );
      })
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .map((country: Country) => {
        return (
          <S.Option
            key={country.code}
            onClick={() => {
              onChangeCountry(country);
              setIsOpen(false);
            }}
          >
            <Box fontSize="28px" height="28px" width="28px" marginBottom="8px">
              {country.flag}
            </Box>
            <Text type="formFieldEmpty">{country.code}</Text>
            <Box marginLeft="auto">
              <Text type="captionAlert">{country.dial_code}</Text>
            </Box>
          </S.Option>
        );
      });
    return (
      <S.Wrapper>
        <Box onBlur={handleHideDropdown} tabIndex={0}>
          <S.CountryCodeButton
            onClick={() => {
              setIsOpen(val => !val);
            }}
          >
            <Text type="formFieldEmpty">
              {country.flag || "🇮🇹"} {country.dial_code || "+39"}
            </Text>
            <Icon icon={isOpenDropdown ? "chevronUp" : "chevronDown"} />
          </S.CountryCodeButton>
          {isOpenDropdown ? (
            <S.CountryCodeDropdown ref={dropdownRef} fromTop={fromTop}>
              <SearchBar
                variant="thinner"
                value={undefined}
                label={
                  showSearchLabel ? translate("input_phone_search_label") : ""
                }
                placeholder={translate({
                  text: "profile_settings_country_code_searchbar_placeholder"
                })}
                onChange={e => {
                  setFilterString(e.target.value);
                }}
                theme={whiteTheme}
              />
              <S.OptionsWrapper>{options}</S.OptionsWrapper>
            </S.CountryCodeDropdown>
          ) : null}
        </Box>

        <Input
          label={label}
          theme={theme}
          type="field"
          rounded
          ref={ref}
          name={name}
          error={error}
          value={value}
          onChange={onChangeValue}
          placeholder={placeholder}
          rightSection={rightSection}
        />
      </S.Wrapper>
    );
  }
);

InputPhone.displayName = "InputPhone";

export default InputPhone;
