import React from "react";
import { colors } from "@arcadia/design-system";

export const UnderlinedLinesIcon = props => (
  <svg fill="none" viewBox="0 0 80 8" {...props}>
    <path
      d="M1.31 3.337a316.592 316.592 0 0 1 77.057-.01M22.008 7.994a267.163 267.163 0 0 1 37.271-.056"
      stroke={colors.yellow[500]}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
