import { shadow } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  height: 48px;
  width: max-content;
  border-radius: 100px;
  box-shadow: ${shadow[100]};
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  align-items: center;
  justify-content: center;

  display: flex;
  gap: 24px;
  padding: 0px 32px;

  @media (max-width: 767px) {
    width: 100%;
    gap: 0px;
    justify-content: space-evenly;
    padding: 0px 8px;
  }
`;

export const Element = styled.div<{
  hasBubblesStyle?: boolean;
  isSelected?: boolean;
}>`
  cursor: pointer;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ hasBubblesStyle }) =>
    hasBubblesStyle &&
    css`
      border-radius: 22px;
      width: 44px !important;
      height: 44px;
      line-height: 24px;
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.primaryColor[900]};
      border: 1px solid ${({ theme }) => theme.primaryColor[500]};
    `}

  .emoji-mart-emoji {
    span {
      display: block;
      transition: all 0.1s ease-in-out;
      transform: translateY(0px);
      font-size: 24px !important;
      zoom: 1;
    }
  }

  &:hover {
    .emoji-mart-emoji {
      span {
        transform: translateY(-12px);
        font-size: 48px !important;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
