import { Button, ModalSizeType, Text } from "@arcadia/design-system";
import React from "react";
import { Trans } from "react-i18next";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { openExternalLink } from "src/js/modules/commonFunction";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation";
import styled, { useTheme } from "styled-components";

export const BodyWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledLinkBtn = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledButton = styled(Button)`
  width: 300px;
  margin: auto;
`;

const ModalPermissionTemplate = ({ onBack }: { onBack: () => void }) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  return (
    <ModalLayout
      closeModal={onBack}
      labels={{
        title: translate({ text: "modal_permission_template_title" }),
        mobileClose: translate("close")
      }}
      desktopProps={{
        size: ModalSizeType.Big
      }}
      mobileProps={{
        withAdaptiveHeight: true
      }}
    >
      <BodyWrapper>
        <VideoPlayer video={S3Video.StaffPermission} />
        <ContentWrapper>
          <Text type="captionAlert">
            <Trans
              i18nKey="modal_permission_template_description"
              components={{
                Permission: (
                  <StyledLinkBtn
                    onClick={() => {
                      openExternalLink(translate("staff_permissions_link_url"));
                    }}
                  />
                )
              }}
            />
          </Text>
          {!isLayoutModeMobile ? (
            <StyledButton
              variant="secondary"
              type="button"
              theme={whiteTheme}
              onClick={onBack}
            >
              {translate("back")}
            </StyledButton>
          ) : undefined}
        </ContentWrapper>
      </BodyWrapper>
    </ModalLayout>
  );
};

export default ModalPermissionTemplate;
