import { Model } from "backbone";
import { __ } from "../modules/localization";

export default class BasePageModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
  }

  initialize() {
    this.set({
      sidebarVisible: false,
      pageTitle: __("Homepage"),
      selectedTab: "bacheca",
      backFunc() {},
      backLabel: "back",
      titleFunc: "",
      displayTabs: true,
      infoText: "",
      rightButtons: "",
      bodyClass: ""
    });
  }

  setTitle(title) {
    this.set({
      pageTitle: title
    });
  }
}
