import styled, { css } from "styled-components";
import {
  __UNLOGGED_BG_DESKTOP__,
  __UNLOGGED_BG_MOBILE__
} from "src/js/settings/settingsImage";

/**
 * this color is custom and since we need it only in this component I'll put it here.
 * Think about moving outside if the same color is reused somewhere else.
 */
const backgroundImageGradientColor = "#A0D6E7";
const renderBackground = (isLayoutModeMobile, hasGroupInvitation, theme) => {
  if (hasGroupInvitation) {
    return theme.colors.violet[501];
  }
  if (isLayoutModeMobile) {
    return `url(${__UNLOGGED_BG_MOBILE__}) top no-repeat`;
  }
  return `url(${__UNLOGGED_BG_DESKTOP__}) center no-repeat`;
};

export const WrapperUnloggedDiv = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  padding: ${({ hasGroupInvitation }) =>
    hasGroupInvitation ? undefined : "32px 0px"};
  background: ${({ isLayoutModeMobile, hasGroupInvitation, theme }) =>
    renderBackground(isLayoutModeMobile, hasGroupInvitation, theme)};
  background-size: ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile ? "contain" : "cover"};
  background-color: ${({ hasGroupInvitation }) =>
    hasGroupInvitation ? undefined : backgroundImageGradientColor};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-image: none;
      background-color: ${backgroundColor};
    `}
`;

export const LanguageSelector = styled.div`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const MobileLogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
