import { getBreakpointCode } from "src/js/modules/layoutFunction";
import { __BREAKPOINT_SM_CODE__ } from "src/js/settings/settingsGeneral";
import { __MODULE_RESOURCE_SAFE_NUMBER__ } from "src/js/settings/settingsModules";

function getFillerNumber() {
  if (getBreakpointCode() === __BREAKPOINT_SM_CODE__) {
    return 2;
  }
  return 3;
}

export function getModulePlaceholderNumber(resourcesNumber) {
  const columnsNumber = getFillerNumber();
  let rest = resourcesNumber % columnsNumber;
  if (rest === 0) rest = columnsNumber;
  const fillRow = columnsNumber - rest;
  return fillRow + columnsNumber;
}

export function getModuleResourceList({
  resourceList = [],
  arrayLength = 0,
  forced = false
}) {
  if (forced || arrayLength > __MODULE_RESOURCE_SAFE_NUMBER__)
    return resourceList;
  const arr = new Array(arrayLength);
  arr.fill({});
  // TODO this is just a patch, we need to find a better way to handle all the modules and the board elements
  resourceList
    .filter(element => element.dtype === "boardelement")
    .forEach(element => {
      arr[element.position] = element;
    });
  return arr;
}

export const moveModuleResources = ({
  resourcesList = [],
  id = 0,
  nextPosition = 0
}) => {
  const movedResource = resourcesList.findIndex(o => {
    return o.id === id;
  });
  const currentPosition = resourcesList[movedResource].position;
  const nextPositionElement = resourcesList.findIndex(o => {
    return o.position === nextPosition;
  });
  let sortedResources = resourcesList;

  if (nextPositionElement > -1) {
    sortedResources = resourcesList.map(element => {
      const updatedElement = element;
      if (currentPosition > nextPosition) {
        if (
          element.position < currentPosition &&
          element.position >= nextPosition
        ) {
          updatedElement.position += 1;
        }
      } else if (
        element.position > currentPosition &&
        element.position <= nextPosition
      ) {
        updatedElement.position -= 1;
      }
      return updatedElement;
    });
  }

  sortedResources[movedResource].position = nextPosition;

  sortedResources.sort((a, b) => {
    return a.position - b.position;
  });

  return sortedResources;
};

export const updateModuleResourcePreview = ({
  resourcesList = [],
  name = "",
  preview
}) => {
  if (name) {
    const updatedResources = resourcesList.map(item =>
      item.resource.name === name
        ? {
            ...item,
            resource: { ...item.resource, is_preview_generated: true, preview }
          }
        : { ...item }
    );

    return updatedResources;
  }

  return resourcesList;
};
