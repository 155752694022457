/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import {
  ActiveGroupFull,
  GroupDetailsModel,
  GroupSectionsType,
  GroupSettingsVisibilityEnum,
  GroupVisibilityEnum,
  Invitation,
  SpaceBadge,
  SpaceModel,
  SpacePurpose,
  SpaceType
} from "src/js/types";

import { fetchGroupBadgesList } from "../repository/badgesRepository";
import {
  getAlias,
  getGroup,
  getGroupInfoFromInvitation,
  getPendingInvites,
  patchPublicInvite
} from "../repository/groupRepository";
import { __GROUPS_BADGE_LIST_LIMIT__ } from "../settings/settingsPagination";
import { WSAnalyticsInstance } from "../tracking/WSAnalyticsInstance";
import { NotificationSettingsModel } from "../types/models/Notification";
import SpaceStore from "./SpaceStore";

/**
 * @name GroupStore
 *
 * @description
 * ...
 */
class GroupStore {
  $activeGroupFull: ActiveGroupFull = {
    group: {
      id: undefined,
      name: undefined,
      cover: undefined,
      badge: undefined,
      cloningCompleted: undefined
    },
    alias: undefined,
    roles: [],
    space: undefined,
    notificationSettingsPreference: undefined,
    settings: undefined
  };

  $usersPreview = [];
  $usersCount = 0;

  $teachers = [];
  $studentsCount = 0;

  $error = false;

  $groupInvitations: Invitation[] = [];

  $publicInvitation = false;

  spaceStore: SpaceStore;

  $badgeAssociated: {
    badgeList: SpaceBadge[];
    offset: number;
    limit: number;
    total: number;
  } = {
    badgeList: [],
    offset: 0,
    limit: __GROUPS_BADGE_LIST_LIMIT__,
    total: 0
  };

  constructor({ spaceStore }) {
    this.spaceStore = spaceStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  fetchGroup = async (groupId, shouldTrack = true) => {
    try {
      const data = await getGroup(groupId);
      if (shouldTrack) {
        WSAnalyticsInstance.startGroupSession(groupId, `#group/${groupId}`);
        WSAnalyticsInstance.setShouldTrack(data?.settings?.enableAnalytics);
      }
      return data;
    } catch (e) {
      this.$error = true;
      throw e;
    }
  };

  setActiveGroupFull = groupFull => {
    this.$activeGroupFull = groupFull;
  };

  setActiveGroupSettings = settings => {
    this.$activeGroupFull.settings = settings;
  };

  setGroupNoticeSettings = (noticeSettings: NotificationSettingsModel) => {
    this.$activeGroupFull.notificationSettingsPreference = {
      ...noticeSettings
    };
  };

  setUsersPreview = users => {
    this.$usersPreview = users.map(user => ({
      ...user,
      fullName: `${user.name} ${user.surname}`
    }));
  };

  setUsersCount = count => {
    this.$usersCount = count;
  };

  setStudentsCount = count => {
    this.$studentsCount = count;
  };

  setTeachers = users => {
    this.$teachers = users;
  };

  setGroupInvitations = (invitations: Invitation[]) => {
    this.$groupInvitations = invitations;
  };

  checkIfUserIsTeacher = userId => {
    if (!userId) return false;
    // User id should be dismissed for the userUuid, this double check is for compatibility
    const filteredUser = this.$teachers.find(
      user => user.id === userId || user.uuid === userId
    );

    if (!filteredUser) return false;

    return filteredUser;
  };

  // TODO is returning null if settings is not fetched yet could be risky.
  isGroupSectionVisible = (section: GroupSectionsType) => {
    if (!this.$activeGroupFull?.settings) {
      return null;
    }
    const visibilityKey = GroupSettingsVisibilityEnum[section];
    const visibility = this.$activeGroupFull?.settings[visibilityKey];
    return visibility !== GroupVisibilityEnum.Hidden;
  };

  fetchGroupInvitations = async (groupId: number) => {
    if (!groupId) return;
    const data: Invitation[] = await getPendingInvites(groupId);
    const sortedData = [...data].sort((a, b) =>
      a.invite_role < b.invite_role ? 1 : -1
    );

    this.setGroupInvitations(sortedData);
  };

  fetchGroupInfoFromInvitation = async (invitationId: string) => {
    const data: {
      group: GroupDetailsModel;
      space: { space: SpaceModel };
      usersCount: number;
    } = await getGroupInfoFromInvitation(invitationId);
    return data;
  };

  fetchNextBadgesPage = () => {
    this.$badgeAssociated = {
      ...this.$badgeAssociated,
      offset: this.$badgeAssociated.offset + this.$badgeAssociated.limit
    };
    this.fetchGroupBadges(this.$activeGroupFull.group.id);
  };

  fetchGroupBadges = async (groupId: number) => {
    const response = await fetchGroupBadgesList({
      groupId,
      offset: this.$badgeAssociated.offset,
      limit: this.$badgeAssociated.limit
    });
    this.$badgeAssociated = {
      badgeList: [...this.$badgeAssociated.badgeList, ...response.results],
      offset: response.offset,
      total: response.total,
      limit: this.$badgeAssociated.limit
    };
    return response;
  };

  updatePublicInvitation = async (groupId: number) => {
    const response: { is_public: boolean } = await patchPublicInvite(groupId);
    this.$publicInvitation = response.is_public;
    return response;
  };

  updateGroupAlias = (alias: string) => {
    this.$activeGroupFull = { ...this.$activeGroupFull, alias };
  };

  fetchPublicInvitation = async (groupId: number) => {
    const response: { is_public: boolean; alias: string } =
      await getAlias(groupId);
    this.$publicInvitation = response.is_public;
    this.updateGroupAlias(response.alias);
    return response;
  };

  reset = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (WSAnalyticsInstance as any).endGroupSession(
      this.$activeGroupFull.group.id
    );
    this.$activeGroupFull = {
      group: {
        id: undefined,
        name: undefined,
        cover: undefined,
        badge: undefined,
        cloningCompleted: undefined
      },
      alias: undefined,
      roles: [],
      space: undefined,
      notificationSettingsPreference: undefined,
      settings: undefined
    };
    this.$error = false;
    this.$usersPreview = [];
    this.$groupInvitations = [];
    this.$publicInvitation = false;
    this.$badgeAssociated = {
      badgeList: [],
      offset: 0,
      limit: 10,
      total: 0
    };
  };

  // computed
  get activeGroupFull() {
    return this.$activeGroupFull;
  }

  get activeGroup() {
    return this.$activeGroupFull?.group;
  }

  get groupId() {
    return this.$activeGroupFull?.group?.id;
  }

  get groupCover() {
    return this.$activeGroupFull?.group.cover;
  }

  get groupName() {
    return this.$activeGroupFull?.group.name;
  }

  get hasError() {
    return this.$error;
  }

  get settings() {
    return this.$activeGroupFull?.settings;
  }

  get space() {
    return this.$activeGroupFull?.space;
  }

  get roles() {
    return this.$activeGroupFull?.roles;
  }

  get alias() {
    return this.$activeGroupFull?.alias;
  }

  get notificationSettingsPreference() {
    return this.$activeGroupFull?.notificationSettingsPreference;
  }

  get isWhiteboardEnabled() {
    return (
      this.$activeGroupFull?.group &&
      this.$activeGroupFull?.settings?.whiteboardEnabled &&
      this.$activeGroupFull?.space?.purpose !== SpacePurpose.School &&
      this.$activeGroupFull?.space?.spaceType === SpaceType.STANDARD &&
      !this.spaceStore.activeSpace?.isTemporary
    );
  }

  get userIsTeacher() {
    return this.$activeGroupFull?.roles?.includes("ROLE_TEACHER");
  }

  get usersPreview() {
    return this.$usersPreview;
  }

  get usersCount() {
    return this.$usersCount;
  }

  get studentsCount() {
    return this.$studentsCount;
  }

  get teachers() {
    return this.$teachers;
  }

  get groupInvitations() {
    return this.$groupInvitations;
  }

  get publicInvitation() {
    return this.$publicInvitation;
  }

  get permissionToCustomization() {
    return this.spaceStore.isAdmin || this.roles?.includes("ROLE_CREATOR");
  }

  get associatedBadge() {
    return this.$badgeAssociated;
  }

  get groupBackboneModel() {
    return {
      ...this.activeGroup,
      studentsCount: this.studentsCount,
      alias: this.$activeGroupFull?.alias,
      roles: this.$activeGroupFull?.roles,
      notificationSettingsPreference:
        this.$activeGroupFull?.notificationSettingsPreference,
      settings: this.$activeGroupFull?.settings
    };
  }
}

export default GroupStore;
