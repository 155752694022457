import styled, { css } from "styled-components";
import { FILTER_DROPDOWN_VARIANT } from "./FilterDropdown.const";

const variantOptions = theme => ({
  dark: {
    backgroundColor: theme.colors.accent4,
    color: theme.colors.slate,
    borderColor: theme.colors.lightBlueGrey,
    placeholderColor: theme.colors.blueGrey[500],
    focus: {
      background: theme.colors.accent4,
      color: theme.colors.slate,
      border: theme.colors.blueGrey[500]
    },
    error: {
      background: theme.colors.lightSemantic1,
      color: theme.colors.semantic1,
      border: theme.colors.semantic1
    },
    disabled: {
      background: theme.colors.basicWhite,
      color: theme.colors.blueGrey[500],
      border: theme.colors.lightBlueGrey
    }
  },

  light: {
    backgroundColor: theme.colors.basicWhite,
    color: theme.colors.primary2,
    borderColor: theme.colors.accent3,
    placeholderColor: theme.colors.accent2,
    focus: {
      background: theme.colors.basicWhite,
      color: theme.colors.primary2,
      border: theme.colors.primary1
    },
    error: {
      background: theme.colors.basicWhite,
      color: theme.colors.semantic1,
      border: theme.colors.semantic1
    },
    disabled: {
      background: theme.colors.disabledBk,
      color: theme.colors.blueGrey[500],
      border: theme.colors.lightBlueGrey
    }
  }
});

export const BaseDropdown = css`
  border: 1px solid ${({ theme }) => theme.colors.lightBlueGrey};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.basicWhite};
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const AutoWidthWrapper = styled.div`
  @media screen and (min-width: 768px) {
    ${({ contentTitleWidth, minWidth }) =>
      contentTitleWidth &&
      !minWidth &&
      css`
        display: inline-block;
      `}
  }
`;

export const SearchInputContainer = styled.div`
  margin: 10px;
  margin-bottom: 20px;
`;

export const SelectDropdownContainer = styled.div`
  position: relative;
  height: ${({ linkButtonMode }) => (linkButtonMode ? "auto" : "42px")};
  margin-bottom: 5px;
`;

export const SelectDropdownActionsContainer = styled.div`
  ${BaseDropdown};
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px;
  padding-right: 55px;
  margin-bottom: 5px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.accent3};
    `}

  ${({ defaultCaseTitle }) =>
    defaultCaseTitle &&
    css`
      text-transform: none;
    `}

  @media screen and (max-width: 767px) {
    padding-right: 45px;
  }

  ${({ theme, variant }) =>
    variant &&
    variant !== FILTER_DROPDOWN_VARIANT.DEFAULT &&
    css`
      background-color: ${variantOptions(theme)[variant].backgroundColor};
      color: ${variantOptions(theme)[variant].color};
      border: 1px solid ${variantOptions(theme)[variant].borderColor};

      ::-webkit-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      ::-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-ms-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      &:hover,
      &:focus {
        background-color: ${variantOptions(theme)[variant].focus
          .background}!important;
        color: ${variantOptions(theme)[variant].focus.color}!important;
        border-color: ${variantOptions(theme)[variant].focus.border}!important;
      }

      ${({ hasError }) =>
        hasError &&
        css`
          background-color: ${variantOptions(theme)[variant].error.background};
          color: ${variantOptions(theme)[variant].error.color};
          border-color: ${variantOptions(theme)[variant].error.border};

          ::-webkit-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          ::-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-ms-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }
        `}

      ${({ disabled }) =>
        disabled &&
        css`
          cursor: not-allowed !important;
          opacity: 1;
          -webkit-text-fill-color: ${variantOptions(theme)[variant].disabled
            .color};
          -webkit-opacity: 1;
          background-color: ${variantOptions(theme)[variant].disabled
            .background};
          color: ${variantOptions(theme)[variant].disabled.color};
          border-color: ${variantOptions(theme)[variant].disabled.border};

          &:hover,
          &:focus {
            background-color: ${variantOptions(theme)[variant].disabled
              .background}!important;
            color: ${variantOptions(theme)[variant].disabled.color}!important;
            border-color: ${variantOptions(theme)[variant].disabled
              .border}!important;
          }
        `}
    `}
`;

export const ShowSelectedText = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

export const ShowSelectedMoreCounter = styled.span`
  white-space: nowrap;
  margin-left: auto;
`;

export const SelectDropdownList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 450px;
  margin-right: -3px;
  overflow: auto;
  padding-right: 8px;

  ${({ maxListHeight }) =>
    maxListHeight &&
    css`
      max-height: ${maxListHeight};
    `}

  // rules for firefox scrollbar
  @supports (-moz-appearance: none) {
    overflow-y: auto;
    margin-right: 0;
    scrollbar-color: ${({ theme }) =>
      theme.colors.lightBlueGrey + theme.colors.accent4};
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3};
    border: 8px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    min-height: 50px;
    &:hover {
      background: ${({ theme }) => theme.colors.accent2};
      background-clip: padding-box;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.accent4};
    border: 8px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const SelectDropdownListItem = styled.li`
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.accent4};
      border-radius: 6px;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent4};
    border-radius: 6px;
  }

  ${({ lowerCaseOptions }) =>
    lowerCaseOptions &&
    css`
      text-transform: none;
      font-size: 14px;
      font-weight: 500;
    `}

  ${({ noWrapText }) =>
    noWrapText &&
    css`
      white-space: nowrap;
    `}
`;

export const SelectDropdownListItemText = styled.div`
  ${({ noWrapText }) =>
    noWrapText &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      min-width: 0;
    `}
`;

export const SelectDropdownOptionsDesktop = styled.div`
  ${BaseDropdown};
  border-radius: 5px !important;
  padding: 10px;
  display: none;
  position: absolute;
  width: 100%;
  z-index: 1;

  ${({ show }) =>
    show &&
    css`
      display: block;
    `}

  ${({ contentTitleWidth }) =>
    contentTitleWidth &&
    css`
      width: auto;
    `}

    ${({ minWidth }) =>
    minWidth &&
    css`
      width: ${minWidth};
    `}

    ${({ alignRight }) =>
    alignRight &&
    css`
      right: 0;
    `}

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const SelectDropdownOptionsMobile = styled.div`
  ${BaseDropdown};

  padding: 10px;
  display: none;
  position: absolute;
  z-index: 1;
  width: calc(100% - 30px);
  margin: 0 15px;
  left: 0;

  ${({ show }) =>
    show &&
    css`
      display: block;
    `}

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const CounterSelectionLabel = styled.span`
  display: none;
  margin-left: 10px;
  font-size: 10px;
  background-color: ${({ theme }) => theme.primaryColor[800]};
  border-radius: 3px;
  height: 15px;
  width: 15px;
  text-align: center;

  @media screen and (max-width: 767px) {
    ${({ type }) =>
      type === "checkbox" &&
      css`
        display: inline-block;
      `}
  }
`;

export const CurrentValue = styled.span`
  text-transform: uppercase;
  @media screen and (max-width: 476px) {
    text-transform: none;
  }
`;

export const SelectDropdownTrigger = styled.span`
  position: absolute;
  padding: 10px 12px;
  top: 0;
  right: 0;
  height: 40px;
  cursor: pointer;

  ${({ theme, variant }) =>
    variant &&
    variant === FILTER_DROPDOWN_VARIANT.DEFAULT &&
    css`
      border-left: 1px solid ${theme.colors.lightBlueGrey};
    `}
`;

export const SelectDropdownInput = styled.input`
  line-height: normal !important;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  flex: 0 0 16px; // for maintaining width in safari
  margin: 0 10px 0 0 !important;

  ${({ type }) =>
    type === "radio" &&
    css`
      width: 16px;
      height: 16px;
    `}
`;

export const PinnedOptionSeparator = styled.div`
  margin: 10px -11px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent3};
`;
