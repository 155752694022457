import { useCallback, useEffect } from "react";
import {
  WebSocketDomain,
  WebSocketEvent,
  WebSocketMessage,
  WebSocketMessageData
} from "src/js/types/models/WebSocket";

const useSocket = <T extends WebSocketEvent>({
  socket,
  domain,
  event,
  handler
}: {
  socket: WebSocket;
  domain: WebSocketDomain;
  event: T;
  // @ts-expect-error - This definitely needs to be improved
  handler: (message: WebSocketMessage<WebSocketMessageData[T]>) => void;
}) => {
  const eventType = `${domain}${event}`;
  const onMessage = useCallback(
    msg => {
      const newMessage = JSON.parse(msg.data);
      if (newMessage?.data?.success) return;

      const socketEventType = `${newMessage.domain}${newMessage.event}`;

      if (eventType === socketEventType) {
        handler(newMessage);
      }
    },
    [handler]
  );

  useEffect(() => {
    socket?.addEventListener("message", onMessage);
    return () => {
      socket?.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);
};

export default useSocket;
