export const __CLIENT_ID__ =
  process.env.DEFAULT_WESCHOOL_OAUTH_CLIENT_ID ||
  process.env.__APP_ENV__ === "local"
    ? "1_63vhapa1xosos88o004cgs4wkg444gcos8sc4gg0gggksc8k0g"
    : "2_63vhapa1xosos88o004cgs4wkg444gcos8sc4gg0gggksc8k0g";

export const { __GOOGLE_API_KEY__ } = process.env;
export const { __GOOGLE_CLIENT_ID__ } = process.env;

export const __IS_STORYBOOK__ = process.env.STORYBOOK === "true";

export const __MATHJAX_URL__ =
  "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-AMS-MML_HTMLorMML";

export const __BREAKPOINT_XL_CODE__ = "XL";
export const __BREAKPOINT_LG_CODE__ = "LG";
export const __BREAKPOINT_MD_CODE__ = "MD";
export const __BREAKPOINT_SM_CODE__ = "SM";
export const __BREAKPOINT_XS_CODE__ = "XS";

export const __BREAKPOINT_XL__ = 1400;
export const __BREAKPOINT_LG__ = 1200;
export const __BREAKPOINT_MD__ = 990;
export const __BREAKPOINT_SM__ = 768;

export const __BREAKPOINT_TABLET__ = 980;
export const __BREAKPOINT_SMARTPHONE__ = 768;
export const __BREAKPOINT_VERTICAL_SMARTPHONE__ = 600;

export const __TERMS_LINK__ = "terms_url";
export const __PRIVACY_LINK__ = process.env.__PRIVACY_POLICY_URL__;

export const __SUPPORT_MAIL__ = "support@weschool.com";

export const { __ONEDRIVE_CLIENT_ID__ } = process.env;

export const __OAUTH_CLIENT__ = {
  dea: 912348215
};

export const __AWS_MQTT_ENDPOINT__ =
  "a3f5tjx8vw4iyr-ats.iot.eu-west-1.amazonaws.com";

export const __MAX_TIMEOUT_VALUE__ = 86400000; // 86400000 = 1 day 2147483647 = 24.9 days (absolute max of timeout)

export const { __CHARGEBEE_SITE__ } = process.env;
export const { __CHARGEBEE_API_KEY__ } = process.env;

export const __HUBSPOT_REGISTRATION_FORM_ENDPOINT__ =
  "https://api.hsforms.com/submissions/v3/integration/submit/25552535/fc889e2f-5a8a-4171-9386-67bc2448def7";

export const __HUBSPOT_ACTIVATE_ACCOUNT_ENDPOINT__ =
  "https://api.hsforms.com/submissions/v3/integration/submit/25552535/d12295bb-e401-49ad-8b07-58c8442228c3";

export const __HUBSPOT_UPDATE_USER_ENDPOINT__ =
  "https://api.hsforms.com/submissions/v3/integration/submit/25552535/bf5e02da-34cd-40c1-a260-92955e0bda64";

export const __HUBSPOT_ONBOARDING_MARKETING_DATA_ENDPOINT__ =
  "https://api.hsforms.com/submissions/v3/integration/submit/25552535/2ad9bdf1-7a9e-4d43-bce2-345f90455bcc";
