import { Button, Text, Box } from "@arcadia/design-system";
import { S3Video } from "src/js/settings/settingsVideo";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { useTheme } from "styled-components";
import React from "react";
import { useTranslation } from "src/js/translation";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import * as S from "./ExplainDemoSpaceModal.styles";

const ExplainDemoSpaceModal = () => {
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const onConfirmModal = () => {
    closeModal();
  };

  return (
    <Box maxWidth={630}>
      <ModalLayout
        closeModal={closeModal}
        labels={{
          title: translate({ text: "explain_demo_space_modal_title" })
        }}
        desktopProps={{
          hasAdaptiveWidth: true
        }}
        forceMobileLayout={false}
      >
        <S.BodyWrapper>
          <VideoPlayer video={S3Video.ExplainDemoSpaceModal} />
          <S.ContentWrapper>
            <Text type="formFieldEmpty">
              {translate({ text: "explain_demo_space_modal_description" })}
            </Text>
            <Button
              variant="primary"
              theme={whiteTheme}
              fullWidth
              onClick={onConfirmModal}
            >
              {translate({
                text: "explain_demo_space_modal_confirmation_button"
              })}
            </Button>
          </S.ContentWrapper>
        </S.BodyWrapper>
      </ModalLayout>
    </Box>
  );
};

export default ExplainDemoSpaceModal;
