import { useTheme } from "styled-components";
import React, { useState } from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import { saveReportThreadMessage } from "src/js/repository/reportRepository";
import ReportStudentResourceViewer from "src/js/components/exercises/ReportStudentDocumentAnswerForm/ReportStudentResourceViewer";
import TextEditor from "src/js/components/TextEditor";
import { Button } from "@arcadia/design-system";
import { CommentTitle } from "../../typography/CommentTitle";
import { DocumentAnswerCard } from "../DocumentAnswerCard";
import * as S from "./ReportStudentDocumentAnswerForm.styles";

const ReportStudentDocumentAnswerForm = ({
  exerciseId,
  executionId,
  quizExecutionId,
  availablePoints,
  onSaveSubmit = () => {}
}) => {
  const [comment, setComment] = useState("");
  const [resource, setResource] = useState(null);

  const { ReportStore, UserStore } = useStores();
  const { whiteTheme } = useTheme();

  const handleSubmit = () => {
    const { activeUser } = UserStore;
    saveReportThreadMessage({
      exerciseId,
      executionId,
      userId: activeUser.id,
      quizExecutionId,
      threadMessage: comment,
      resourceId: resource?.id
    })
      .then(({ id }) => {
        onSaveSubmit();
        ReportStore.addThreadMessage({
          id,
          quizExecutionId,
          threadMessage: comment,
          resourceId: resource?.id,
          availablePoints,
          messageCorrection: false
        });
      })
      .catch(() => {
        showToastError({
          str: <Translate text="student_save_thread_message_error" />
        });
      });
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div>
        <S.TitleContainer>
          <CommentTitle>
            <Translate text="report_review_redelivery_docanswer_title" />
          </CommentTitle>
        </S.TitleContainer>
        <TextEditor content={comment} setContent={data => setComment(data)} />
      </div>
      <S.StudentFooterContainer>
        {resource ? (
          <S.CardContainer>
            <DocumentAnswerCard
              resource={resource}
              onRemove={() => {
                setResource(null);
              }}
            />
          </S.CardContainer>
        ) : null}
        <S.ButtonsContainer>
          <ReportStudentResourceViewer
            callbackOk={res => {
              setResource(res);
            }}
          />
          <S.SubmitButton>
            <Button
              type="submit"
              variant="primary"
              theme={whiteTheme}
              disabled={!resource && !comment}
            >
              <Translate text="report_redelivery_form_submit_label" />
            </Button>
          </S.SubmitButton>
        </S.ButtonsContainer>
      </S.StudentFooterContainer>
    </form>
  );
};

export default ReportStudentDocumentAnswerForm;
