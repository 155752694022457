import React from "react";
import { useStores } from "src/js/hooks";
import { ModalTabs } from "src/js/components/modal/ModalTabs";
import { ModalHeaderTabItem } from "src/js/components/modal/ModalHeaderTabItem";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { EventCategory, EventCategoryType } from "../Calendar.types";

type CalendarEventModalTabsPropsType = {
  showTabs: boolean;
  selectedType: EventCategoryType;
  setEventType: (type: EventCategoryType) => void;
};

const CalendarEventModalTabs = ({
  showTabs,
  selectedType,
  setEventType
}: CalendarEventModalTabsPropsType) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  return !isLayoutModeMobile ? (
    <ModalTabs showTabs={showTabs}>
      <ModalHeaderTabItem
        onClickFunction={() => setEventType(EventCategory.Live)}
        isSelected={selectedType === EventCategory.Live}
      >
        {translate("calendar_event_modal_tab_live")}
      </ModalHeaderTabItem>
      <ModalHeaderTabItem
        onClickFunction={() => setEventType(EventCategory.Activity)}
        isSelected={selectedType === EventCategory.Activity}
      >
        {translate("calendar_event_modal_tab_activity")}
      </ModalHeaderTabItem>
      <ModalHeaderTabItem
        onClickFunction={() => setEventType(EventCategory.Deadline)}
        isSelected={selectedType === EventCategory.Deadline}
      >
        {translate("calendar_event_modal_tab_deadline")}
      </ModalHeaderTabItem>
    </ModalTabs>
  ) : null;
};

export default CalendarEventModalTabs;
