import styled from "styled-components";

export const ResponseHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: initial;
  }
`;
export const ResponseFromNow = styled.div`
  margin-left: 10px;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;

export const ResponseText = styled.div`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 14px;
`;

export const ResponseResource = styled.div`
  width: 185px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.basicWhite};
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
`;

export const ResponseResourceTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: 14px;
`;

export const ResponceResource = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
