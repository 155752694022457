import {
  __COOKIE_LOGIN__,
  __COOKIE_REFRESH__
} from "src/js/settings/settingsCookie";

function createCookie(name, value, days, path, domain) {
  const strPath = typeof path === "undefined" ? "path=/" : `path=${path}`;
  const strDomain = typeof domain === "undefined" ? "" : `; domain=${domain}`;
  let expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toGMTString()}`;
  }
  // console.log(name + "=" + value + "; " + expires + ";" + strDomain + ";" + srPath + ";");
  document.cookie = `${name}=${value}; ${expires}${strDomain};${strPath};`;
}

export function createLocalData(name, value) {
  return localStorage.setItem(name, value);
}

export function readLocalData(name) {
  return localStorage.getItem(name);
}

export function createAccessData(name, value, days, path, domain) {
  createCookie(name, value, days, path, domain);
}
