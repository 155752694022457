import { Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

export const createProgressBarTheme = ({ primary, grey }: Palette) => {
  return {
    progressbar: {
      gutter: grey[800],
      background: getHighContrastColor(primary.main, primary.dark, primary.main)
    }
  };
};
