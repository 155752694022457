import { makeAutoObservable } from "mobx";
import { uniqueUsersArray } from "src/js/pages/spaces/dashboard/SpacesBadges/components/AssociateBadgeToGroups/utils";
import {
  BadgeRelatedGroupType,
  BadgeRelatedUserType,
  GroupInSpaceModel,
  SpaceBadge,
  TransactionStepType,
  UpdatedBadgeAssociation,
  UserAwardedBadgeType
} from "src/js/types";
import { DurationPeriodType } from "src/js/pages/spaces/dashboard/SpacesBadges/components/BadgeOptionalFields/types";
import { TransactionStep } from "../types/consts/Badge";
import { BadgeAssertion } from "../repository/types";

type BadgeRequiredFields = {
  image: string;
  name: string;
  description: string;
  criteria: string;
  issuer: string;
  customBadgeImage?: File;
};

type BadgeOptionalFields = {
  duration: DurationPeriodType | null;
  isPrivate: boolean;
};

const badgeOptionalFieldsInitialState: BadgeOptionalFields = {
  duration: null,
  isPrivate: false
};

const badgeRequiredFieldsInitialState: BadgeRequiredFields = {
  image: null,
  name: null,
  description: null,
  criteria: null,
  issuer: null,
  customBadgeImage: null
};

class SpaceBadgesStore {
  private $selectedBadgeRelatedGroups: string[] = [];

  private $badgeRequiredFields: BadgeRequiredFields =
    badgeRequiredFieldsInitialState;

  private $badgeOptionalFields: BadgeOptionalFields =
    badgeOptionalFieldsInitialState;

  private $activeSpaceGroups: GroupInSpaceModel[] = [];

  private $selectedUsersToEmitBadge: BadgeRelatedUserType[] = [];

  private $userAwardedBadges: UserAwardedBadgeType[] = [];

  private $isEmitStudentsDrawerOpen = false;

  private $isGroupsDrawerOpen = false;

  private $badgeToEdit: SpaceBadge = null;

  private $updatedBadgeAssociations: UpdatedBadgeAssociation[] = [];

  private $shouldDisplayEmptyState = false;

  private $isAbleToEmitBadge = false;

  private $transactionStep: TransactionStepType =
    TransactionStep.OpenBadgeCreation;

  private $transactionLink: string = null;

  private $badgeAssertions: BadgeAssertion[] = [];

  private $transactionFailed = false;

  private $transactionInProgress = false;

  private $transactionChainId: string = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  selectBadgeRelatedGroups = (selectedGroups: string[]) => {
    this.$selectedBadgeRelatedGroups = selectedGroups;
  };

  setBadgeRequiredFields = (payload: BadgeRequiredFields) => {
    this.$badgeRequiredFields = payload;
  };

  setBadgeOptionalFields = (payload: BadgeOptionalFields) => {
    this.$badgeOptionalFields = payload;
  };

  updateUsersToEmitBadge = (userToEmit: BadgeRelatedUserType) => {
    const storeUsers = [...this.$selectedUsersToEmitBadge];
    const updatedUsers = [...storeUsers, { ...userToEmit }];
    this.$selectedUsersToEmitBadge = updatedUsers;
  };

  removeUserFromEmitWithBadge = (id: string) => {
    const storeUsers = [...this.$selectedUsersToEmitBadge];
    const filtered = storeUsers.filter(user => user.id !== id);
    this.$selectedUsersToEmitBadge = filtered;
  };

  updateUserAwardedBadges = (badges: UserAwardedBadgeType[]) => {
    this.$userAwardedBadges = badges;
  };

  setGroupsDrawerOpen = (isOpen: boolean) => {
    this.$isGroupsDrawerOpen = isOpen;
  };

  setEmitStudentsDrawerOpen = (isOpen: boolean) => {
    this.$isEmitStudentsDrawerOpen = isOpen;
  };

  setEditingBadge = (badge: SpaceBadge) => {
    this.$badgeToEdit = badge;
  };

  setUpdatedGroupAssociations = (updatedAssociations: {
    added: BadgeRelatedGroupType[];
    removed: BadgeRelatedGroupType[];
  }) => {
    const addedGroups: UpdatedBadgeAssociation[] =
      updatedAssociations.added.map(group => ({
        ...group,
        isAdded: true
      }));
    const removedGroups: UpdatedBadgeAssociation[] =
      updatedAssociations.removed.map(group => ({
        ...group,
        isRemoved: true
      }));
    this.$updatedBadgeAssociations = [...addedGroups, ...removedGroups];
  };

  setEmptyStateVisible = (isVisible: boolean) => {
    this.$shouldDisplayEmptyState = isVisible;
  };

  setIsAbleToEmitBadge = (isAble: boolean) => {
    this.$isAbleToEmitBadge = isAble;
  };

  updateTransactionStep = (step: TransactionStepType) => {
    this.$transactionStep = step;
  };

  updateTransactionLink = (link: string) => {
    this.$transactionLink = link;
  };

  updateBadgeAssertions = (assertions: BadgeAssertion[]) => {
    this.$badgeAssertions = assertions;
  };

  updateTransactionFailed = (hasFailed: boolean) => {
    this.$transactionFailed = hasFailed;
  };

  updateTransactionInProgress = (inProgress: boolean) => {
    this.$transactionInProgress = inProgress;
  };

  updateTransactionChainId = (chainId: string) => {
    this.$transactionChainId = chainId;
  };

  get shouldDisplayEmptyState() {
    return this.$shouldDisplayEmptyState;
  }

  get badgeRequiredFields() {
    return this.$badgeRequiredFields;
  }

  get badgeOptionalFields() {
    return this.$badgeOptionalFields;
  }

  get selectedBadgeRelatedGroups() {
    return this.$selectedBadgeRelatedGroups;
  }

  get activeSpaceGroups() {
    return this.$activeSpaceGroups;
  }

  get usersToEmitBadge() {
    return uniqueUsersArray(this.$selectedUsersToEmitBadge);
  }

  get userAwardedBadges() {
    return this.$userAwardedBadges;
  }

  get isGroupsDrawerOpen() {
    return this.$isGroupsDrawerOpen;
  }

  get isEmitStudentsDrawerOpen() {
    return this.$isEmitStudentsDrawerOpen;
  }

  get badgeToEdit() {
    return this.$badgeToEdit;
  }

  get updatedBadgeAssociations() {
    return this.$updatedBadgeAssociations;
  }

  get isAbleToEmitBadge() {
    return this.$isAbleToEmitBadge;
  }

  get transactionStep() {
    return this.$transactionStep;
  }

  get transactionLink() {
    return this.$transactionLink;
  }

  get badgeAssertions() {
    return this.$badgeAssertions;
  }

  get transactionFailed() {
    return this.$transactionFailed;
  }

  get transactionInProgress() {
    return this.$transactionInProgress;
  }

  get transactionChainId() {
    return this.$transactionChainId;
  }

  reset = () => {
    this.$activeSpaceGroups = [];
    this.$selectedBadgeRelatedGroups = [];
    this.$badgeOptionalFields = badgeOptionalFieldsInitialState;
    this.$badgeRequiredFields = badgeRequiredFieldsInitialState;
    this.$selectedUsersToEmitBadge = [];
    this.$transactionStep = TransactionStep.OpenBadgeCreation;
    this.$transactionLink = null;
    this.$transactionFailed = false;
    this.$transactionChainId = null;
  };
}

export default SpaceBadgesStore;
