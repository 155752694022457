import styled from "styled-components";

export const AgendaBodyContainer = styled.div`
  margin-top: 30px;
`;

export const AgendaPlaceholderWrapper = styled.div`
  padding-bottom: 1px;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.up};
  background: ${({ theme }) => theme.colors.accent4};
`;

export const AgendaListContainer = styled.div`
  height: calc(100vh - 300px);
  position: relative;
  margin-right: -20px;
  @supports (-moz-appearance: none) {
    margin-right: -12px;
  }

  & .ws-agenda-list {
    @supports (-moz-appearance: none) {
      overflow: auto;
      scrollbar-color: ${({ theme }) =>
        theme.colors.lightBlueGrey + theme.colors.accent4};
      scrollbar-width: thin;
    }

    ::-webkit-scrollbar {
      width: 20px;
      height: 20px;
    }

    ::-webkit-scrollbar-button {
      width: 10px;
      height: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.accent3};
      border: 8px solid transparent;
      background-clip: padding-box;
      border-radius: 10px;
      min-height: 50px;
      &:hover {
        background: ${({ theme }) => theme.colors.accent2};
        background-clip: padding-box;
      }
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.accent4};
      border: 8px solid transparent;
      background-clip: padding-box;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`;
