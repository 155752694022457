import styled from "styled-components";

export const InputPointContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputPoint = styled.div`
  align-items: baseline;
  padding: 7px 30px;
  color: ${({ theme }) => theme.colors.grey[50]};
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: 500;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.colors.defaultBorderColor};
  background: ${({ theme }) => theme.colors.basicWhite};
  width: fit-content;
  margin-right: 10px;
`;

export const EditableInputPoint = styled.input`
  text-align: end;
  display: inline-block;
  border: none;
  width: 14px;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const FixedValue = styled.span`
  display: inline-block;
  padding-left: 3px;
  padding-right: 2px;
`;

export const InputPointLabel = styled.span``;
