import { useState } from "react";
import { fetchGroupsList } from "src/js/repository/groupsListRepository";
import { GroupInSpaceModel, SpaceModel } from "src/js/types";

export type SpaceAndGroups = {
  space: SpaceModel;
  searchedGroups: GroupInSpaceModel[];
};

const useSearchNotificationGroups = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const [listOfSpaceWithGroups, setListOfSpaceWithGroups] =
    useState<SpaceAndGroups[]>(null);

  const onSubmitSearch = event => {
    event.preventDefault();
    if (searchFilter.length < 3) {
      setListOfSpaceWithGroups(null);
      return;
    }

    setListOfSpaceWithGroups(null);
    fetchGroupsList({ name: searchFilter, limit: 30, offset: 0 }).then(
      ({ results }) => {
        const auxMap = new Map<string, SpaceAndGroups>([]);
        results.forEach(group => {
          const oldGroups = auxMap.get(group.space.id)?.searchedGroups || [];

          auxMap.set(group.space.id, {
            space: group.space,
            searchedGroups: [...oldGroups, group]
          });
        });
        // TODO remove this filter when i'll have a correct temporary-space-free endpoint
        const toSave = Array.from(auxMap.values()).filter(
          ({ space }) => space.slug !== "temporary"
        );

        setListOfSpaceWithGroups(toSave);
      }
    );
  };

  const onChangeSearch = (value: string) => {
    setSearchFilter(value);
  };

  return {
    onChangeSearch,
    onSubmitSearch,
    searchFilter,
    listOfSpaceWithGroups
  };
};

export default useSearchNotificationGroups;
