import getUserClient from "src/js/modules/connection";
import {
  api_embeddable_iframe,
  getVersionedApiUrl,
  api_board_search,
  api_url_info
} from "src/js/repository/apiUrls";
import { extractErrorMessage } from "src/js/modules/messageManager";

export function isUrlEmbeddable(url) {
  return getUserClient()
    .get(getVersionedApiUrl(api_embeddable_iframe()), {
      params: {
        url
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_iframe_embeddable")
      );
      throw Err;
    });
}

export function getUrlInfo(url) {
  return getUserClient()
    .get(getVersionedApiUrl(api_url_info()), {
      params: {
        url
      }
    })
    .then(({ data }) => {
      return { url: data.url, title: data.title, image: data.image };
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_iframe_embeddable")
      );
      throw Err;
    });
}

export function filterBoards(filterString, start, limit, groupId) {
  return getUserClient()
    .get(getVersionedApiUrl(api_board_search()), {
      params: {
        q: filterString,
        start,
        limit,
        group_id: groupId
      }
    })
    .then(({ data }) => {
      return { boards: data.data, has_next: data.has_next };
    })
    .catch(error => {
      const Err = new Error(extractErrorMessage(error, "error_filter_boards"));
      throw Err;
    });
}
