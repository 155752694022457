import { HeadingLevelsMap } from "./types";

const heading: HeadingLevelsMap = {
  1: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "31px"
  },
  2: {
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "32px"
  },
  3: {
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "31px"
  },
  4: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "27px"
  },
  5: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21px"
  },
  6: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px"
  }
};

export default heading;
