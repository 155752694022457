import { __UNIVERSITY_JSON_URL__ } from "src/js/settings/settingsUrl";
import axios from "axios";
import { extractErrorMessage } from "src/js/modules/messageManager";

export function fetchUniversityList() {
  return axios({
    method: "get",
    url: __UNIVERSITY_JSON_URL__
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "Error during download of language")
      );
      throw Err;
    });
}
