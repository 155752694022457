import React from "react";
import * as S from "./QuickActionButton.styles";

const QuickActionButton = ({
  children,
  clickFunction,
  isDisabled,
  ...props
}) => {
  return (
    <S.QuickActionLayout
      isDisabled={isDisabled}
      onClick={clickFunction}
      {...props}
    >
      {children}
    </S.QuickActionLayout>
  );
};

export default QuickActionButton;
