import styled from "styled-components";

export const LinkBtn = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-top: 4px;
  span {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-flow: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 344px;
`;

export const IllustrationContainer = styled.div`
  margin-bottom: 20px;
`;

/**
 * Root
 */

export const ModalContent = styled.div`
  margin: 42px 16px;
  display: flex;
  align-items: center;
  flex-flow: column;
  white-space: pre-line;
  text-align: center;
`;

export const BottomSheetContent = styled.div`
  padding: 16px 0 24px 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  white-space: pre-line;
  text-align: center;
`;
