/* eslint-disable react/no-danger */
import { Box, Button, Heading, Icon, Text } from "@arcadia/design-system";
import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useStores } from "src/js/hooks";
import { readLocalData } from "src/js/modules/storageFunction";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { __SUPPORT_MAIL__ } from "src/js/settings/settingsGeneral";
import { observer } from "mobx-react";
import * as S from "../StickyInfoBanner.styles";
import { BannerProps } from "../types";

/**
 * @name StickyInfoBannerMobile
 *
 * @description StickyInfoBannerMobile component.
 */

const StickyInfoBannerMobile = ({
  translations,
  withDescriptionButton,
  withCtaButton,
  withFooterLink,
  withDismissButton,
  footerLink,
  translationCtaButtonProps,
  onClickCtaButton,
  onDismiss = () => {}
}: BannerProps) => {
  const { whiteTheme, colors } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isStickyBannerOpen, toggleStickyBanner, isStickyBannerVisible }
  } = useStores();

  const [isDismissed, setIsDismissed] = useState(
    readLocalData("demo_info_banner_dismissed") === "dismissed"
  );

  const handleIsOpen = () => {
    toggleStickyBanner();
  };

  const handleDismiss = () => {
    toggleStickyBanner();
    setIsDismissed(!isDismissed);
    onDismiss();
  };

  const transition = {
    type: "easeOut",
    damping: 100,
    stiffness: 100
  };

  return (
    <AnimatePresence>
      {isStickyBannerVisible ? (
        <S.InfoBanner
          key="info-banner"
          initial="closed"
          animate={isStickyBannerOpen ? "open" : "closed"}
          transition={transition}
          variants={{
            open: {
              top: -290
            },
            closed: {
              top: isDismissed ? -4 : -48
            }
          }}
          exit={{ top: 0 }}
        >
          <S.StickyInfoBannerClosedContainer onClick={handleIsOpen}>
            <S.StickyInfoBannerClosed
              initial="dismissed"
              animate={isDismissed ? "dismissed" : "notDismissed"}
              transition={transition}
              variants={{
                dismissed: { display: "none" },
                notDismissed: { display: "flex" }
              }}
            >
              <S.ContentInfoBannerClosed>
                {isStickyBannerOpen ? (
                  <Box>
                    <Icon icon="close" />
                  </Box>
                ) : (
                  <Heading level="6">
                    {translate(translations.textClosed)}
                  </Heading>
                )}
                {isStickyBannerOpen ? null : <S.IconBannerClosed icon="help" />}
              </S.ContentInfoBannerClosed>
            </S.StickyInfoBannerClosed>
          </S.StickyInfoBannerClosedContainer>
          <S.StickyInfoBannerContainer>
            <S.StickyInfoBannerOpened
              initial="closed"
              animate={isStickyBannerOpen ? "open" : "closed"}
              transition={transition}
              variants={{
                open: { visibility: "visible", height: 250, opacity: 1 },
                closed: { visibility: "hidden", height: 8, opacity: 0 }
              }}
            >
              <Heading level="5">{translate(translations.titleOpened)}</Heading>
              <S.ContentInfoBannerOpened>
                <div
                  dangerouslySetInnerHTML={{
                    __html: translate({
                      text: translations.textOpened,
                      stringVariables: { supportLink: __SUPPORT_MAIL__ }
                    })
                  }}
                />
                {withDescriptionButton && (
                  <S.StyledLink
                    // whiteTheme itself doesn't contain the colors property,
                    // Link from DS is wrapped on ThemeProvider and override the internal one
                    theme={{ ...whiteTheme, colors: { ...colors } }}
                    target="_blank"
                    href={translate(translations.ctaLink)}
                  >
                    {translate(translations.ctaOpened)}
                  </S.StyledLink>
                )}
              </S.ContentInfoBannerOpened>
              {withCtaButton && (
                <S.SubmitButton
                  variant="primary"
                  theme={whiteTheme}
                  onClick={onClickCtaButton}
                >
                  {translate(translations.ctaButton, {
                    ...translationCtaButtonProps
                  })}
                </S.SubmitButton>
              )}
              {withFooterLink && (
                <S.InfoContainer
                  onClick={() => window.open(footerLink, "_blank")}
                >
                  <Text type="ctaTertiary">
                    {translate(translations.footerLink)}
                  </Text>
                  <Icon icon="arrowRight" />
                </S.InfoContainer>
              )}
              {withDismissButton && (
                <Button
                  variant="primary"
                  theme={whiteTheme}
                  onClick={handleDismiss}
                >
                  {translate(translations.ctaDismissMobile)}
                </Button>
              )}
            </S.StickyInfoBannerOpened>
          </S.StickyInfoBannerContainer>
        </S.InfoBanner>
      ) : null}
    </AnimatePresence>
  );
};

export default observer(StickyInfoBannerMobile);
