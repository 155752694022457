import {
  Box,
  ClosableFeedback,
  Heading,
  Input,
  Text,
  Toggle
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { ClosableWrapper } from "src/js/components/global/ClosableWrapper";
import { useStores } from "src/js/hooks";
import { copyToClipboard } from "src/js/modules/commonFunction";
import createUrl from "src/js/modules/routing";
import { __LINK_URL_BASE__ } from "src/js/settings/settingsUrl";
import { useTranslation } from "src/js/translation";
import { useLinkInvite } from "../useLinkInvite";
import * as S from "./LinkInvite.styles";
import { LinkInviteProps } from "./types";

const LinkInvite = ({ theme, isDemoSpace }: LinkInviteProps) => {
  const { translate } = useTranslation();
  const {
    GroupStore: { alias, publicInvitation },
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const [isDisabled, setDisabled] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [groupAlias, setGroupAlias] = useState<string>(alias);
  const [aliasAlreadyTaken, setAlreadyTaken] = useState(false);
  const [linkCopied, setCopied] = useState(false);
  const { handleGroupAlias, togglePublicInvite } = useLinkInvite(
    isDisabled,
    setDisabled
  );

  const urlToCopy = `${__LINK_URL_BASE__}#${createUrl("join", {
    alias_id: groupAlias
  })}`;

  return (
    <Box>
      <S.CollapsableContainer
        HeaderComponent={
          <Heading level="5">
            {translate("group_settings_invite_modal_section_link")}
          </Heading>
        }
        controlled={false}
        isCollapsed={isCollapsed}
        setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
      >
        {publicInvitation && (
          <>
            <Box
              display="flex"
              flexDirection={isLayoutModeMobile ? "column" : "row"}
              alignItems={isLayoutModeMobile ? null : "center"}
              justifyContent="space-between"
              flexWrap="wrap"
              gap={12}
            >
              <Box padding={2} flex={1}>
                <S.StyledInput
                  theme={theme}
                  label={translate("group_settings_invite_modal_code")}
                  rightSection={
                    <Box marginTop={isDisabled ? 2 : 0}>
                      {isDisabled ? (
                        <S.StyledIcon
                          icon="pencil"
                          width={16}
                          height={16}
                          onClick={() => {
                            if (!isDemoSpace) {
                              setDisabled(!isDisabled);
                            }
                          }}
                        />
                      ) : (
                        <S.ColoredText
                          onClick={() =>
                            alias === groupAlias
                              ? setDisabled(!isDisabled)
                              : handleGroupAlias({
                                  groupAlias,
                                  setAlreadyTaken
                                })
                          }
                        >
                          {translate("save")}
                        </S.ColoredText>
                      )}
                    </Box>
                  }
                  rounded
                  value={groupAlias}
                  disabled={isDisabled}
                  onChange={event => {
                    if (!isDemoSpace) {
                      setAlreadyTaken(false);
                      setGroupAlias(event.target.value.replace(/\s+/g, ""));
                    }
                  }}
                  error={aliasAlreadyTaken}
                />
              </Box>
              <Box padding={2} flex={1}>
                <Input
                  theme={theme}
                  label={translate("group_settings_invite_modal_link")}
                  rightSection={
                    <Box
                      marginTop={2}
                      cursor="pointer"
                      onClick={() => {
                        if (!isDemoSpace) {
                          copyToClipboard(urlToCopy, false);
                          setCopied(true);
                        }
                      }}
                    >
                      <S.StyledIcon icon="copyImport" width={16} height={16} />
                    </Box>
                  }
                  rounded
                  value={urlToCopy}
                  disabled
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop={8}>
              {aliasAlreadyTaken && (
                <Box
                  display="flex"
                  alignItems="center"
                  flex={1}
                  marginLeft={20}
                >
                  <S.DangerIcon icon="dangerCircle" width={16} height={16} />
                  <S.AliasUnavailable type="ctaTertiary">
                    {translate("group_alias_already_taken")}
                  </S.AliasUnavailable>
                </Box>
              )}
              {linkCopied && (
                <Box
                  width={isLayoutModeMobile ? "100%" : "49%"}
                  marginLeft="auto"
                >
                  <ClosableWrapper>
                    <S.CopySuccess>
                      <ClosableFeedback
                        text={translate("copiato!")}
                        onClickFunction={() => setCopied(false)}
                      />
                    </S.CopySuccess>
                  </ClosableWrapper>
                </Box>
              )}
            </Box>
          </>
        )}
        <Box
          display="flex"
          alignItems="center"
          marginTop={publicInvitation ? 24 : 8}
          marginBottom={32}
        >
          <Toggle
            theme={theme}
            title="Disable link"
            onChange={togglePublicInvite}
            checked={publicInvitation}
            size="small"
          />
          <Box marginLeft={10} />
          <Text type="subHeaderSubtitle">
            {translate(
              publicInvitation
                ? "group_settings_invite_modal_toggle_enable"
                : "group_settings_invite_modal_toggle_disable"
            )}
          </Text>
        </Box>
      </S.CollapsableContainer>
    </Box>
  );
};

export default observer(LinkInvite);
