import React from "react";
import Backbone from "@weschooleng/backbone";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { ActionButton, Icon } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import ImagePreviewer from "../../../components/imagePreviewer";
import ResourceModel from "../../../models/resourceModel";
import { __ } from "../../../modules/localization";

const AttachedResourceList = withBackbone(
  createReactClass({
    remove(resource) {
      this.props.removeFunc(resource);
    },
    edit(resource) {
      this.props.editTextFunc(resource);
    },
    render() {
      const self = this;
      const { resources } = this.props;
      const { whiteTheme } = this.props.theme;
      const board = "";
      const exercise = "";
      let resourceList = "";

      if (resources.length > 0) {
        resourceList = (
          <div>
            {resources.map(resource => {
              if (resource instanceof Backbone.Model == false) {
                resource = new ResourceModel(resource);
              }
              let resource_name = resource.get("name");
              if (
                resource.get("board") !== undefined &&
                resource.get("board").name !== undefined
              ) {
                resource_name = resource.get("board").name;
              }
              const created = resource.get("created") * 1000;
              const placeholder = getPlaceholder(
                resource.attributes || resource
              );
              const background = resource.getBackground();
              let editAction = "";
              if (
                resource.get("type") === "TEXT" &&
                typeof self.props.editTextFunc !== "undefined"
              ) {
                editAction = (
                  <TranslatedTooltip tooltipString="edit_resource_button_tooltip">
                    <div
                      className="post__new-resource-edit clickable tooltip-holder"
                      onClick={self.edit.bind(null, resource.toJSON())}
                    >
                      <Icon icon="pencil" />
                    </div>
                  </TranslatedTooltip>
                );
              }
              return (
                <div className="app__box post__new-resource__wrapper clearfix">
                  <ImagePreviewer
                    className="post__new-resource-image"
                    src={placeholder}
                    type="DIV"
                    background={background}
                    created={created}
                    isReady
                  />
                  <div className="post__new-resource-name">{resource_name}</div>
                  <TranslatedTooltip tooltipString="remove_resource_button_tooltip">
                    <ActionButton
                      theme={whiteTheme}
                      variant="tertiary"
                      onClick={self.remove.bind(null, resource.toJSON())}
                      type="button"
                      icon="close"
                    />
                  </TranslatedTooltip>
                  {editAction}
                </div>
              );
            })}
          </div>
        );
      }

      return (
        <div className="clearfix">
          {board}
          {exercise}
          {resourceList}
        </div>
      );
    }
  })
);

export default withTheme(AttachedResourceList);
