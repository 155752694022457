import styled from "styled-components";
import { Icon } from "@arcadia/design-system";

type ActiveImageButton = {
  isActive: boolean;
};

export const SelectBadgeImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectImageToUploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 58px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  margin-bottom: 32px;
  cursor: pointer;
`;

export const UploadIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const AlertContainer = styled.div`
  width: 100%;

  & > div:first-child {
    max-width: inherit;
  }
`;

export const ImagesListContainer = styled.div`
  margin: 32px 0 40px 0px;
  overflow-x: auto;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ImagesList = styled.div`
  display: inline-flex;
`;

export const BadgeImage = styled.img`
  width: 52px;
  height: 52px;
`;

export const BadgeImageContainer = styled.div<ActiveImageButton>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  margin-right: 12px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.primaryColor[700]}` : ""};
`;

const checkBorder = (isActive: boolean, color: string, isEditing?: boolean) => {
  if (isEditing) {
    return "none";
  }
  if (isActive) {
    return `2px solid ${color}`;
  }
  return "";
};

export const SelectOwnImage = styled.div<
  ActiveImageButton & { isEditing?: boolean }
>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  margin-right: 12px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  border: ${({ isActive, isEditing, theme }) =>
    checkBorder(isActive, theme.primaryColor[700], isEditing)};
`;

export const ArrowIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: ${({ theme }) => `1px solid ${theme.colors.grey[800]}`};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectedBadge = styled.img`
  width: 156px;
  height: 156px;
  object-fit: cover;
  margin-bottom: 32px;
  border-radius: 8px;
`;

export const SelectedBadgeSmallPreview = styled.img<ActiveImageButton>`
  width: 100%;
  height: 100%;
  border: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.primaryColor[700]}` : ""};
  border-radius: 10px;
`;

export const HiddenInput = styled.input`
  display: none !important;
`;

export const NewCroppedImageContainer = styled.div`
  position: relative;
`;

export const CroppedImageEditButtonContainer = styled.div`
  position: absolute;
  top: -16px;
  right: -16px;
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px;

  &:active {
    transform: scale(0.8);
  }
`;
