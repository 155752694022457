import React, { useRef } from "react";
import { useStores } from "src/js/hooks";
import { PostDetails } from "src/js/types/models/Post";
import { ContextType, POST_CONTEXT } from "../Post.types";
import PostModal from "../PostModal";

type ActionByContextProps = {
  context: ContextType;
  postId: number;
  setPinnedFunc: (post: PostDetails) => void;
  onDeletePost: (postId: number, groupId: number) => void;
};

const useActionsByContext = ({
  context,
  postId,
  onDeletePost,
  setPinnedFunc
}: ActionByContextProps) => {
  const {
    PostCommentStore: { getPostById }
  } = useStores();
  const postFull = getPostById(postId);
  const inputRef = useRef<HTMLInputElement>();
  const {
    ModalStore: { openModal }
  } = useStores();

  const openSinglePost = () => {
    if (!postFull) return;
    openModal(() => (
      <PostModal
        postFull={postFull}
        setPinnedFunc={setPinnedFunc}
        onDeletePost={onDeletePost}
      />
    ));
  };

  const focusInput = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  return {
    inputRef,
    onShowCommentsClick:
      context === POST_CONTEXT.WALL ? openSinglePost : () => {},
    onCommentClick: context === POST_CONTEXT.WALL ? openSinglePost : focusInput
  };
};

export default useActionsByContext;
