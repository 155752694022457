import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import reject from "lodash/reject";
import indexOf from "lodash/indexOf";
import map from "lodash/map";
import debounce from "lodash/debounce";
import { __ } from "../../../modules/localization";
import { basePageModel } from "../../../modules/pageStatus";
import { getActiveGroup, getActiveGroupId } from "../../../modules/activeGroup";
import { createURL, isElementInViewport } from "../../../modules/utility";
import QuizzesReport from "./QuizzesReport";
import EserciziReportsUsersItem from "./exerciseReportUsersDetail";
import { InternalDispatcher } from "../../../modules/dispatcher";
import {
  getBreakpoint,
  getInnerBreakpoint
} from "src/js/modules/layoutFunction";
import { showResultsToAllUsers } from "src/js/repository/exerciseRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import { withExerciseStore, withModalStore } from "src/js/hooks/useStores";
import ExerciseShowVoteConfirmModal from "src/js/pages/exercise/ExerciseShowVoteConfirmModal";
import { ContextualError, Toggle } from "@arcadia/design-system";
import { withTheme } from "styled-components";

const EserciziReportsUsers = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: null,
        /** @type {ExerciseModel} The exercise */ reports: [],
        /** @type {Array} The array containing the reports to display */
        showArchive: true,
        /** @type {Boolean} Check if the link to other executions should be visible or not */
        page: 1 /** @type {Number} The starting page (most probably always 1) */
      };
    },
    getInitialState() {
      return {
        offset: 10,
        activeTab: "executions",
        /** @type {String} executions|quizzes The active tab to display on mobile */
        reports: this.props.reports,
        /** @type {Array} The array of executions */ page: this.props.page,
        /** @type {Number} The current page of reports */
        loadingMoreItems: false,
        /** @type {Boolean} Tell the component if it loading more data or not */
        shouldFetchAgain: true,
        /** @type {Boolean} Check if the component can fetch more data */
        showAll: false
      };
    },
    componentWillMount() {
      const self = this;
      const name = self.props.exercise.get("title");
      const type = self.props.exercise.get("type");
      self.props.ExerciseStore.setActiveExerciseMinimal({ name, type });
      let reports = [];
      self.setupBasePageModel();

      InternalDispatcher.trigger("start");

      $.ajax({
        type: "GET",
        dataType: "json",
        url: createURL("api_exercises_reports_users", {
          exercise_id: self.props.exercise.get("id")
        }),
        data: {
          report_by: "last"
        },
        success(ret) {
          InternalDispatcher.trigger("stopLoading");
          reports = ret.user_reports;
          const showAll = reports.every(report => report.show_results === true);
          self.setState({
            reports,
            showAll,
            shouldFetchAgain: reports.length >= self.state.offset
          });
        },
        error(ret) {
          InternalDispatcher.trigger("stopLoading");
          reports = ret.user_reports;
          self.setState({
            reports,
            shouldFetchAgain: reports.length >= self.state.offset
          });
        }
      });
    },
    componentDidMount() {
      basePageModel.on("change:innerBreakpoint", this.updateBP);

      /** attach listener to scroll event */
      $(window).on("scroll", this.handleScroll);
    },
    componentWillUnmount() {
      basePageModel.off("change:innerBreakpoint", this.updateBP);
      $(window).off("scroll", this.handleScroll);
    },
    setActiveTab(tab) {
      this.setState({
        activeTab: tab
      });
    },

    setupBasePageModel() {
      if (getBreakpoint() === "smartphone") return;
      const self = this;
      const title = self.props.exercise.get("title");
      const group = getActiveGroup();
      basePageModel.set("pageTitle", group.get("name"));
      const back = __("torna alla lista");
      let back_function = "";
      const buttons = "";

      back_function = function () {
        let back_url = createURL("exercises", { group_id: getActiveGroupId() });
        window.location.hash = back_url;
      };

      basePageModel.set({
        selectedTab: "custom",
        backFunc: {
          back,
          back_function,
          title,
          buttons
        }
      });
    },

    onChangeShowResultAll() {
      showResultsToAllUsers(this.props.exercise.id)
        .then(res => {
          this.setState(prevState => ({
            showAll: !prevState.showAll
          }));
        })
        .catch(err => {
          throw err;
        });
    },

    updateBP() {
      this.forceUpdate();
    },
    /**
     * Handle loading of new data on scroll
     */
    handleScroll: debounce(function () {
      this.loadMoreItems();
    }, 300),

    /**
     * Handle loading of new data on scroll
     * If reference div is in viewport and it's not already loading
     */
    loadMoreItems() {
      const el = $(".js-load-more-reports");
      if (el.length > 0) {
        if (
          isElementInViewport(el, true) &&
          this.state.loadingMoreItems === false &&
          this.state.shouldFetchAgain
        ) {
          this.nextPage();
        }
      }
    },

    /**
     * Load the next page of reports and append the new data to our current array
     */
    nextPage() {
      const self = this;

      this.setState(
        {
          loadingMoreItems: true
        },
        () => {
          $.ajax({
            type: "GET",
            dataType: "json",
            url: createURL("api_exercises_reports_users", {
              exercise_id: self.props.exercise.get("id")
            }),
            data: {
              start: self.state.page * self.state.offset - 1,
              limit: self.state.offset
            },
            success(data) {
              const new_reports = data.user_reports;

              let reports = self.state.reports.concat(new_reports);
              const used_ids = [];

              /** Remove report that are already present in our array */
              reports = reject(reports, report => {
                if (indexOf(used_ids, report.user_id) >= 0) {
                  return true; // reject. report is already there
                }
                used_ids.push(report.user_id); // ok, this is a new report
                return false;
              });

              const showAll = reports.every(
                report => report.show_results === true
              );

              self.setState(
                {
                  loadingMoreItems: false,
                  reports,
                  showAll,
                  page: self.state.page + 1,
                  shouldFetchAgain:
                    new_reports.length ==
                    self.state
                      .offset /* false if new_reports are lower than offset */
                },
                () => {
                  /**
                   * Call function again, so it can keep loading while reference div is no longer in view
                   * (or when all data are fetched)
                   */
                  self.loadMoreItems();
                }
              );
            },
            error() {
              self.setState({
                loadingMoreItems: false,
                shouldFetchAgain: false /* stop calling, there is something wrong */
              });
            }
          });
        }
      );
    },

    render() {
      const group = getActiveGroup();
      const self = this;
      const { ModalStore } = this.props;
      let mainContent = "";
      let showArchive = self.props.showArchive;
      const { whiteTheme } = this.props.theme;

      const reports = this.state.reports || [];

      const openExerciseShowVoteConfirmModal = () => {
        ModalStore.openModal(() => (
          <ExerciseShowVoteConfirmModal
            closeModal={() => ModalStore.closeModal()}
            callbackOk={this.onChangeShowResultAll}
          />
        ));
      };

      if (group.isTeacher()) {
        if (reports.length > 0) {
          mainContent = map(reports, execution => (
            <EserciziReportsUsersItem
              exercise={self.props.exercise}
              execution={execution}
              showArchive={showArchive}
            />
          ));
        } else {
          mainContent = (
            <p className="alert alert-warning">
              {__("Non ci sono report disponibili")}
            </p>
          );
        }
      } else {
        mainContent = (
          <ContextualError
            theme={whiteTheme}
            text={__("Non hai i permessi per visualizzare questa pagina")}
          />
        );
      }

      const quizzesReport = <QuizzesReport exercise={this.props.exercise} />;

      const showAllToggleSection = (
        <div className="flex-row">
          <div className="flex-left margin-top-10">
            <Translate text="report_show_result_all_users" />
          </div>
          <div className="flex-right ">
            <Toggle
              title="report_show_all"
              theme={whiteTheme}
              checked={this.state.showAll}
              onChange={openExerciseShowVoteConfirmModal}
              disabled={this.state.showAll}
            />
          </div>
        </div>
      );

      let fullContent = (
        <div className="row">
          <div className="col-sm-8">
            <div className="app__subtitle" style={{ margin: "12px 0" }}>
              {__("Risultati per studente")}
            </div>
            {mainContent}
            <div className="text-center">
              <div
                className={[
                  "js-load-more-reports",
                  this.state.loadingMoreItems ? "loader" : ""
                ].join(" ")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="app__subtitle" style={{ margin: "12px 0" }}>
                {__("exercises_tests_see_results_verification_settings")}
              </div>
              <div className="quizzes-reports-wrapper clearfix">
                <div style={{ padding: "12px" }}>{showAllToggleSection}</div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="app__subtitle" style={{ margin: "12px 0" }}>
                {__("Andamento della classe")}
              </div>
              {quizzesReport}
            </div>
          </div>
        </div>
      );

      if (getInnerBreakpoint() === "inner-smartphone") {
        const isExecutionsTabActive = this.state.activeTab === "executions";
        fullContent = (
          <div className="row">
            <div className="col-sm-12">
              <div className="app__tabs">
                <a
                  onClick={this.setActiveTab.bind(this, "executions")}
                  className={[
                    "app__tabs-item",
                    isExecutionsTabActive ? "is-active" : ""
                  ].join(" ")}
                  title={__("Dettaglio esecuzioni")}
                >
                  {__("esecuzioni")}
                </a>
                <a
                  onClick={this.setActiveTab.bind(this, "quizzes")}
                  className={[
                    "app__tabs-item",
                    !isExecutionsTabActive ? "is-active" : ""
                  ].join(" ")}
                  title={__("Andamento della classe")}
                >
                  {__("andamento classe")}
                </a>
              </div>
            </div>
            {isExecutionsTabActive ? (
              <>
                {self.props.exercise.isVerification() ? (
                  <div className="col-sm-12">
                    <div className="app__box margin-bottom-15">
                      {showAllToggleSection}
                    </div>
                  </div>
                ) : null}
                <div className="col-sm-12">
                  {mainContent}
                  <div
                    className={[
                      "js-load-more-reports",
                      this.state.loadingMoreItems ? "loading-div" : ""
                    ].join(" ")}
                  />
                </div>
              </>
            ) : (
              <div className="col-sm-12">{quizzesReport}</div>
            )}
          </div>
        );
      }

      return (
        <div style={{ paddingBottom: "50px" }}>
          {this.props.showArchive ? fullContent : mainContent}
        </div>
      );
    }
  })
);

export default withTheme(
  withModalStore(withExerciseStore(EserciziReportsUsers))
);
