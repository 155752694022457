import { Palette } from "@ds_themes/types";

export const createPickerTheme = ({ general, primary }: Palette) => {
  return {
    picker: {
      background: general.background,
      border: `${primary.light} solid 1px`,
      focus: {
        border: `${primary.light} solid 3px`
      },
      icon: {
        color: primary.main
      }
    }
  };
};
