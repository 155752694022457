import { Box, Icon } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

import * as S from "./Suggestions.styles";

type Props = {
  items: string[];
  onSuggestionClick: (value: string) => void;
};

const Suggestions: FC<Props> = ({ items, onSuggestionClick }) => {
  const { translate } = useTranslation();
  const { colors } = useTheme();

  return (
    <>
      <Box marginBottom="16px">
        <S.SuggestionLabel type="label">
          {translate("recent_search_title")}
        </S.SuggestionLabel>
      </Box>
      {items.map((suggestion, i) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`${suggestion}-${i}`}
          marginBottom="16px"
        >
          <Box
            display="inline-flex"
            onClick={() => {
              onSuggestionClick(suggestion);
            }}
          >
            <Box marginRight="8px">
              <Icon
                icon="clock"
                width="16px"
                height="16px"
                color={colors.violet[200]}
              />
            </Box>
            <S.SuggestionLabel type="formSmallCaption">
              {suggestion}
            </S.SuggestionLabel>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default Suggestions;
