import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  padding: 16px 24px 24px;
`;

export const GroupInviteFooterButton = styled.div<{ centered: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 16px;

  button + button {
    margin-left: 8px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & button:first-child {
      margin-bottom: 20px;
    }
  }

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
      & button:first-child {
        margin-right: 20px;
      }
      @media (max-width: 767px) {
        flex-direction: row;
        & button:first-child {
          margin-right: 10px;
          margin-bottom: 0px;
        }
      }
    `}
`;

export const GroupInviteImportSearch = styled.div`
  margin-top: 10px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const GroupInviteImportListTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;
