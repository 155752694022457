import styled from "styled-components";

export const BannerCtaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: linear-gradient(180deg, #4644eb 56.78%, #645bff 100%);
  border-radius: 12px;
  gap: 20px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  & button {
    white-space: nowrap;
  }
`;
