import { Model } from "backbone";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import React from "react";
import { __ } from "../modules/localization";
import { createURL } from "../modules/utility";

class GroupModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    this.urlRoot = createURL("api_groups");
    this.usersPoints = [];
  }

  initialize() {
    if (this.get("name") === undefined) this.set("name", "");
  }

  getUsersPoints() {
    return this.get("usersPoints") || [];
  }

  getUserPoints(id) {
    const userpoints = this.getUsersPoints();
    let score = 0;
    if (!isEmpty(userpoints)) {
      userpoints.forEach(element => {
        if (isObject(element)) {
          if (element.user.id == id) {
            score = element.score;
          }
        }
      });
    }
    return score;
  }

  setUsersPoints(userpoint) {
    const self = this;
    self.set({
      usersPoints: userpoint
    });
  }

  getNumberOfStudents() {
    if (typeof this.get("studentsCount") === "undefined") {
      return 0;
    }
    return this.get("studentsCount");
  }

  fetchRank(callback) {
    // console.info("fetching rank");
    const self = this;
    $.ajax({
      type: "GET",
      dataType: "json",
      url: createURL("api_groups_ranking", {
        group_id: this.get("id")
      }),
      success(ret) {
        self.set({
          usersRanking: ret.chart.slice(0, 3),
          usersRankingLastPage: ret.pagination.last_page,
          usersRankingPerPage: ret.pagination.per_page
        });
        if (typeof callback === "function") callback();
      },
      error(ret) {
        // console.log('no users enrolled', ret);
        self.set({
          usersRanking: []
        });
        if (typeof callback === "function") callback();
      }
    });
  }

  getRanking() {
    return this.get("usersRanking") || [];
  }

  fetchUserRankWindow(callback) {
    const self = this;
    $.ajax({
      type: "GET",
      dataType: "json",
      url: createURL("api_groups_user_ranking_window", {
        group_id: this.get("id")
      }),
      success(ret) {
        self.set({
          userRankWindow: ret
        });
        self.setCurrentUserPosition();
        if (typeof callback === "function") callback();
      },
      error(ret) {
        // console.log('no users enrolled', ret);
        self.set({
          userRankWindow: []
        });
        if (typeof callback === "function") callback();
      }
    });
  }

  setCurrentUserPosition() {
    const self = this;
    const neighborMembers = self.getUserRankWindow();
    map(neighborMembers, member => {
      const position = member.position;
      const pageNumber =
        Math.floor(position / self.get("usersRankingPerPage")) + 1;
      // console.log(pageNumber);
      self.set({
        currentUserPosition: pageNumber
      });
    });
  }

  getUserRankWindow() {
    return this.get("userRankWindow") || [];
  }

  fetchAlias(retrieveIfMissing, callback) {
    const self = this;
    $.ajax({
      type: "GET",
      dataType: "json",
      url: createURL("api_groups_invites_alias", {
        group_id: self.get("id")
      }),
      success(ret) {
        self.set({
          alias: ret.alias,
          is_public: ret.is_public
        });
        if (typeof callback === "function") callback(ret);
      },
      error(ret) {
        self.set({
          alias: "",
          is_public: true
        });
        if (retrieveIfMissing !== undefined && retrieveIfMissing) {
          // se impostato recupero automatico
          self.getNewAliasCandidate(() => {
            // prendo nuovo candidato
            self.setNewAlias(self.getAlias(), () => {
              // salvo nuovo candidato
              if (typeof callback === "function") callback(ret);
            });
          });
        }
      }
    });
  }

  getNewAliasCandidate(callback) {
    const self = this;
    $.ajax({
      type: "GET",
      dataType: "json",
      url: createURL("api_groups_invites_alias_candidate", {
        group_id: self.get("id")
      }),
      success(ret) {
        self.set({
          alias: ret.alias,
          is_public: ret.is_public
        });
        if (typeof callback === "function") callback();
      },
      error(ret) {
        if (typeof callback === "function") callback();
      }
    });
  }

  setNewAlias(newAlias, callback, callType) {
    if (isUndefined(callType)) {
      callType = "POST";
    }
    const self = this;
    $.ajax({
      type: callType,
      dataType: "json",
      contentType: "application/json",
      url: createURL("api_groups_invites", {
        group_id: self.get("id")
      }),
      data: JSON.stringify({
        alias: newAlias
      }),
      success(ret) {
        self.set({
          alias: newAlias,
          is_public: true
        });
        if (typeof callback === "function") callback(ret);
      },
      error(ret) {
        if (typeof callback === "function") callback(ret);
      }
    });
  }

  getAlias() {
    return this.get("alias") || "";
  }

  errorInviteFormat(data) {
    const self = this;
    let okMessage = "";
    if (data.ok.length > 0) {
      okMessage = (
        <p>
          {__("correctly_sent_invites")}: {data.ok.length}
        </p>
      );
    }
    const errorlist = map(data.errors, email => (
      <li>
        {email.mail} ({__(email.error)})
      </li>
    ));
    const warningMessages = (
      <div>
        <p>{__("warning_invite_title")}</p>
        {okMessage}
        <p>{__("error_sent_invites")}:</p>
        <ul>{errorlist}</ul>
      </div>
    );
    return warningMessages;
  }

  /**
   * Check if members must be shown in this group.
   * Check if field is in model or return true otherwise.
   * @return {Boolean}
   */

  isTeacher() {
    const roles = this.get("roles");
    if (
      indexOf(roles, "ROLE_TEACHER") >= 0 ||
      indexOf(roles, "ROLE_CREATOR") >= 0
    ) {
      return true;
    }
    return false;
  }

  isStudent() {
    const roles = this.get("roles");
    if (indexOf(roles, "ROLE_STUDENT") >= 0) {
      return true;
    }
    return false;
  }

  /**
   * @return {Object} The group settings object
   */
  getSettings() {
    return this.get("settings");
  }

  shouldDisableWallPost() {
    const group_settings = this.getSettings();
    if (group_settings && group_settings.wallPost == "disabled") {
      return true;
    }
    return false;
  }

  shouldDisableWallComment() {
    const group_settings = this.getSettings();
    if (group_settings && group_settings.wallComments == "disabled") {
      return true;
    }
    return false;
  }

  shouldDisableExerciseExecution() {
    const group_settings = this.getSettings();
    if (group_settings && group_settings.exerciseExecution == "disabled") {
      return true;
    }
    return false;
  }

  shouldShowCreateTest() {
    const group_settings = this.getSettings();
    if (group_settings && group_settings.createTest == "disabled") {
      return true;
    }
    return false;
  }

  /**
   * Check if this group has gamification to show
   * @return {Boolean}
   */
  shouldShowRanking() {
    const group_settings = this.getSettings();
    if (
      group_settings &&
      "gamification" in group_settings &&
      group_settings.gamification == "enabled"
    ) {
      // docCookies.setItem('weschool_gamification', true); //todo:doccokie
      return true;
    }
    return false;
  }
}

export default GroupModel;
