import { useInfiniteScrollFetcher } from "src/js/hooks";
import { BadgeRelatedGroupType } from "src/js/types";
import { fetchBadgeRelatedGroups } from "src/js/repository/badgesRepository";
import { BadgeRelatedGroupsFetchParams } from "../components/AssociateBadgeToGroups/types";

const useBadgeRelatedGroups = () => {
  const {
    data: badgeRelatedGroups = [],
    hasMoreResults,
    fetch,
    reset: resetResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    BadgeRelatedGroupType[],
    BadgeRelatedGroupsFetchParams
  >(
    async ({
      limit,
      page: _page,
      textSearch,
      showUnassociated,
      badgeId: _badgeId
    }) => {
      const data = await fetchBadgeRelatedGroups({
        badgeId: _badgeId,
        textSearch,
        showUnassociated,
        limit,
        offset: _page * limit
      });
      return data.results;
    },
    { lazy: true, limit: 10 }
  );

  return {
    badgeRelatedGroups,
    hasMoreResults,
    fetch,
    resetResults,
    fetchNextPage
  };
};

export default useBadgeRelatedGroups;
