import {
  differenceInMinutes,
  format,
  isSameWeek,
  isSameYear,
  isToday,
  isYesterday
} from "date-fns";
import { translateString } from "src/js/translation/TranslationProvider";

import { ThreadMessage } from "src/js/types/models/Thread";
import {
  GroupMessage,
  ShouldAppendMessageProps,
  ThreadMapList
} from "./ThreadMessageList.types";

/**
 * Check wether append the message to the groupMessages, return a boolean
 * based on:
 *  - if the user is the same as the groupMessages user
 *  - the range is lower than the range passed (minutes)
 */
const shouldAppendMessage = ({
  groupMessages,
  message,
  rangeInMinutes
}: ShouldAppendMessageProps) => {
  const sameUser = groupMessages.author === message.userId;

  return (
    sameUser &&
    differenceInMinutes(
      new Date(message.createdAt),
      new Date(groupMessages.lastMessageDate)
    ) < rangeInMinutes
  );
};

const dateFormat = "dd MMMM yyyy";

const formatGroupMessage = (message: ThreadMessage) => {
  const lastMessage: GroupMessage = {
    messages: [message],
    author: message.userId,
    lastMessageDate: new Date(message.createdAt)
  };
  return lastMessage;
};

const addGroupToMap = (
  newGroupedMessage: GroupMessage,
  conversationMap: ThreadMapList
) => {
  const mapKey = format(
    new Date(newGroupedMessage.lastMessageDate),
    dateFormat
  );

  const oldConversation = conversationMap.get(mapKey);

  if (!oldConversation) {
    conversationMap.set(mapKey, {
      groupMessageDate: newGroupedMessage.lastMessageDate,
      groupMessageList: [newGroupedMessage]
    });
    return;
  }
  conversationMap.set(mapKey, {
    groupMessageDate: oldConversation.groupMessageDate,
    groupMessageList: [...oldConversation.groupMessageList, newGroupedMessage]
  });
};

const addMessageToMap = (
  message: ThreadMessage,
  conversationMap: ThreadMapList
) => {
  const dateOfMessage = format(new Date(message.createdAt), dateFormat);

  const { groupMessageList } = conversationMap.get(dateOfMessage) || {};

  if (groupMessageList) {
    if (
      shouldAppendMessage({
        groupMessages: groupMessageList[groupMessageList.length - 1],
        message,
        rangeInMinutes: 5
      })
    ) {
      groupMessageList[groupMessageList.length - 1].messages.push(message);
      groupMessageList[groupMessageList.length - 1].lastMessageDate = new Date(
        message.createdAt
      );
      return;
    }
    groupMessageList.push(formatGroupMessage(message));
    return;
  }

  const groupMessage = formatGroupMessage(message);
  addGroupToMap(groupMessage, conversationMap);
};

/**
 *
 * @param threadList ThreadMessage[]
 * @returns a Map<string, GroupMessage[]>
 * Returns a map of GroupMessages,the key of the map is the day in the format DD/MMMM/YYYY
 */
export const getGroupedMessages = (threadList: ThreadMessage[]) => {
  const messageMap: ThreadMapList = new Map();

  threadList.map(message => {
    return addMessageToMap(message, messageMap);
  });

  return messageMap;
};

export const getPillDate = (date: Date) => {
  const today = new Date();

  if (isToday(date))
    return translateString({ text: "chat_conversation_day_pill_today_value" });

  if (isYesterday(date))
    return translateString({
      text: "chat_conversation_day_pill_yesterday_value"
    });

  if (isSameWeek(date, today)) {
    return format(date, "EEEE");
  }

  if (isSameYear(date, today)) {
    return format(date, "d MMMM");
  }

  return format(date, "d MMMM yyyy");
};
