import React, { useEffect, useState } from "react";
import { EventAttachmentItem } from "src/js/components/EventAttachmentItem";
import { DurationCounterBadge } from "src/js/components/DurationCounterBadge";
import { Pill, PILL_COLOR } from "src/js/components/Pill";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { EventLiveStatusBanner } from "src/js/pages/event_live/EventLiveStatusBanner";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import {
  LiveTag,
  DoodleLoader,
  Icon,
  Heading,
  colors,
  Text,
  Box
} from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { EventRecipientsList } from "src/js/pages/calendar/EventRecipientsList/";
import { EventDownload } from "src/js/pages/calendar/EventDownload";
import {
  getDatesUtils,
  getEventStatus,
  hasLiveAccess
} from "src/js/pages/calendar/CalendarUtils";
import {
  EventAttachmentMode,
  EventCategory
} from "src/js/pages/calendar/Calendar.types";
import { intervalToDuration, format } from "date-fns";
import * as S from "./EventLiveView.styles";

const EventLiveView = ({
  eventDetail = {},
  vendors = [],
  openResource = () => {},
  openRecipientsModal = () => {}
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme, greyTheme } = useTheme();

  const {
    id: eventId,
    title,
    start,
    end,
    description,
    userIsCreator,
    live_room,
    group_recipients = [],
    user_recipients = [],
    resource_attachments = []
  } = eventDetail || {};

  const { startDate, endDate, dateNow, duration } = getDatesUtils({
    start,
    end
  });
  const { translate } = useTranslation();

  const [status, setStatus] = useState(
    getEventStatus({
      dateNow,
      startDate,
      endDate,
      eventType: EventCategory.Live
    })
  );

  const [timeFromStart, setTimeFromStart] = useState(
    startDate ? intervalToDuration({ start: new Date(), end: startDate }) : null
  );

  const [timeToStart, setTimeToStart] = useState(
    startDate ? intervalToDuration({ start: startDate, end: new Date() }) : null
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setStatus(
        getEventStatus({
          dateNow,
          endDate,
          startDate,
          eventType: EventCategory.Live
        })
      );
      setTimeFromStart(
        startDate
          ? intervalToDuration({ start: new Date(), end: startDate })
          : null
      );
      setTimeToStart(
        startDate
          ? intervalToDuration({ start: startDate, end: new Date() })
          : null
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [startDate, endDate]);

  if (isEmptyObject(eventDetail)) return <DoodleLoader theme={greyTheme} />;

  const colorArray = Object.values(PILL_COLOR);

  const vendorName =
    vendors && vendors.find(element => element.id === live_room?.vendor)?.name;

  let avatars = [];

  if (user_recipients && user_recipients.length > 0) {
    user_recipients.slice(0, 3).forEach(({ avatar }) => {
      avatars = [...avatars, avatar?.small];
    });
  }

  return (
    <S.EventViewContainer>
      <LiveTag status={status} theme={whiteTheme} />
      <S.TitleContainer>
        <Heading level="1">{title}</Heading>
      </S.TitleContainer>
      <S.DataGrid>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_live_groups")}</Text>
          </S.DataRowLabel>
          <S.PillContainer>
            {group_recipients.map((group, idx) => {
              const moduleNumber = idx % 7;
              if (moduleNumber === 0) {
                return (
                  <Pill
                    key={group.id}
                    id={group.id}
                    value={group.name}
                    isDeletable={false}
                    color={colors.grey[1000]}
                  />
                );
              }
              return (
                <Pill
                  key={group.id}
                  id={group.id}
                  value={group.name}
                  color={colorArray[moduleNumber]}
                  isDeletable={false}
                />
              );
            })}
          </S.PillContainer>
        </S.DataRow>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_detail_recipients")}</Text>
          </S.DataRowLabel>
          <EventRecipientsList
            group_recipients={group_recipients}
            user_recipients={user_recipients}
            onClickItem={() => {
              openRecipientsModal({
                group_recipients,
                user_recipients
              });
            }}
          />
        </S.DataRow>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_live_start_date")}</Text>
          </S.DataRowLabel>
          <Icon icon="calendar" height={16} width={16} />
          <S.DateContainer>{format(startDate, "dd/MM/yyyy")}</S.DateContainer>
          {format(startDate, "HH:mm")}
        </S.DataRow>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_live_end_date")}</Text>
          </S.DataRowLabel>
          <Icon icon="calendar" height={15} width={15} />
          <S.DateContainer>{format(endDate, "dd/MM/yyyy")}</S.DateContainer>
          {format(endDate, "HH:mm")}
          <S.HourContainer>
            <DurationCounterBadge duration={duration} theme={greyTheme} />
          </S.HourContainer>
        </S.DataRow>
        <S.DataRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_live_vendor")}</Text>
          </S.DataRowLabel>
          {vendorName}
        </S.DataRow>
        {isLayoutModeMobile && (
          <S.DataRow>
            <EventDownload eventId={eventId} />
          </S.DataRow>
        )}
      </S.DataGrid>
      {description && (
        <S.DescriptionRow>
          <S.DataRowLabel>
            <Text type="label">{translate("event_live_description")}</Text>
          </S.DataRowLabel>
          <S.DescriptionContainer>{description}</S.DescriptionContainer>
        </S.DescriptionRow>
      )}
      {!isLayoutModeMobile && (
        <Box marginTop={16}>
          <EventDownload eventId={eventId} />
        </Box>
      )}
      {resource_attachments && resource_attachments.length > 0 && (
        <S.EventAttachments>
          {resource_attachments.map(({ id, type, name }) => {
            return (
              <EventAttachmentItem
                key={id}
                mode={EventAttachmentMode.View}
                id={id}
                type={type}
                name={name}
                openResource={openResource}
              />
            );
          })}
        </S.EventAttachments>
      )}
      <EventLiveStatusBanner
        status={status}
        timeFromStart={timeFromStart}
        timeToStart={timeToStart}
        userIsCreator={userIsCreator}
        liveRoomUrl={live_room?.url}
        showLive={hasLiveAccess({
          dateNow,
          startDate,
          endDate
        })}
      />
    </S.EventViewContainer>
  );
};

export default EventLiveView;
