import {
  AvatarWithInitials,
  Box,
  Button,
  Input,
  InputFeedbackOptions,
  PictureUploader,
  placeholderColors,
  Text
} from "@arcadia/design-system";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import { InputUrl } from "src/js/components/forms/InputUrl";
import { useOnboarding, useStores } from "src/js/hooks";
import { formatBytes } from "src/js/modules/commonFunction";
import { showToastError } from "src/js/modules/messageManager";
import {
  createSpace,
  fetchSlugAvailability
} from "src/js/repository/spacesRepository";
import { SpaceCreationPayload } from "src/js/repository/types";
import {
  __GROUP_COVER_MIME__,
  __GROUP_COVER_SIZE__
} from "src/js/settings/settingFileUploader";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SpacePurpose } from "src/js/types";
import { useTheme } from "styled-components";
import { SLUGIFY_OPTIONS, STATIC_DOMAIN_URL } from "./SpaceCreationForm.const";
import {
  ErrorDetail,
  FormValidations,
  SpaceCreationProps
} from "./SpaceCreationForm.types";

import * as S from "./SpaceCreationForm.styles";

const SpaceCreationForm = ({
  className,
  onSuccessCallback,
  theme,
  purpose = SpacePurpose.Company,
  goToClaim
}: SpaceCreationProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const currentTheme = theme || whiteTheme;
  const { UIStore } = useStores();
  const { isOnboardingFlow } = useOnboarding();

  const [spaceName, setSpaceName] = useState("");
  const [spaceDomain, setSpaceDomain] = useState("");
  const [spaceBadge, setSpaceBadge] = useState<File | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: InputFeedbackOptions }>(
    {}
  );

  const randomSpaceNameColor = useMemo(
    () => Math.floor(Math.random() * placeholderColors.length),
    []
  );
  const { isLayoutModeMobile: isMobile } = UIStore;

  const formValidations: FormValidations = {
    spaceName: value => {
      if (value.length > 100)
        return translate("space_name_creation_space_name_character_limit");
      if (!value) return translate("space_name_creation_space_name_required");
      return false;
    },
    spaceDomain: value => {
      if (value.length > 100)
        return translate("space_name_creation_space_domain_character_limit");
      if (!value) return translate("space_name_creation_space_domain_required");
      return false;
    }
  };

  useEffect(() => {
    if (!spaceName || !spaceDomain) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [spaceName, spaceDomain]);

  const checkFormValidation = (
    field: keyof FormValidations,
    value: string
  ): boolean => {
    const error: Partial<Record<keyof FormValidations, ErrorDetail>> = {};
    const errorString = formValidations[field](value);
    error[field] = errorString
      ? { type: "error", message: errorString }
      : { type: "success", message: "" };
    setErrors({ ...errors, ...error });
    return !!errorString;
  };

  const checkSubmitValidation = (formInput: SpaceCreationPayload) => {
    if (checkFormValidation("spaceName", formInput.name)) return true;
    if (checkFormValidation("spaceDomain", formInput.slug)) return true;

    return false;
  };

  const handleOnSpaceNameChange = e => {
    const inputValue = e.target.value;
    checkFormValidation("spaceName", inputValue);
    setSpaceName(inputValue);
  };

  const checkSlugAvailability = useCallback(
    debounce(async inputValue => {
      if (!inputValue) return;

      const { availability = true, errorDetails } = await fetchSlugAvailability(
        {
          slug: inputValue
        }
      );

      if (!availability) {
        setErrors(currentErrors => ({
          ...currentErrors,
          spaceDomain: { type: "error", message: errorDetails }
        }));
      } else {
        setErrors(currentErrors => ({
          ...currentErrors,
          spaceDomain: null
        }));
      }
    }, 500),
    []
  );

  const getValue = (
    input: React.ChangeEvent<HTMLInputElement> | string
  ): string => {
    return typeof input === "string" ? input : input.target.value;
  };

  const handleOnSpaceDomainChange = (
    input: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    const value = getValue(input) || spaceName;
    const inputValue = slugify(value, SLUGIFY_OPTIONS);

    checkFormValidation("spaceDomain", inputValue);
    checkSlugAvailability(inputValue);
    setSpaceDomain(inputValue);
  };

  useEffect(() => {
    if (!spaceName.length) return;
    handleOnSpaceDomainChange(spaceName);
  }, [spaceName]);

  const handleSubmit = (onboardingWeSchoolTemplates: boolean) => {
    const formData: SpaceCreationPayload = {
      name: spaceName,
      slug: spaceDomain,
      defaultBadgeColor: randomSpaceNameColor,
      badge: spaceBadge,
      purpose
    };

    const submitErrors = checkSubmitValidation(formData);

    if (!submitErrors) {
      createSpace(formData)
        .then(newSpace =>
          onSuccessCallback(newSpace, onboardingWeSchoolTemplates)
        )
        .catch(error => {
          showToastError({ str: error.message });
        });
    }
  };

  let badgeImagePreview;
  if (spaceBadge) {
    badgeImagePreview =
      typeof spaceBadge === "string"
        ? spaceBadge
        : URL.createObjectURL(spaceBadge);
  }

  return (
    <S.SpaceCreationFormContainer
      className={className}
      isOnboarding={isOnboardingFlow}
    >
      <Box width="100%" position="relative">
        <S.SpaceBadgeImageContainer>
          <PictureUploader
            label={translate("space_creation_upload_company_logo_label")}
            theme={theme}
            onChangeHandler={setSpaceBadge}
            showPencil={false}
            height="80px"
            placeholder={translate(
              "space_creation_upload_company_logo_placeholder"
            )}
            textAlign="center"
            feedbacks={[
              {
                id: "upload_image_limit",
                type: "warning",
                message: `${translate("upload_image_limit")} ${formatBytes(
                  __GROUP_COVER_SIZE__
                )}`
              },
              {
                id: "file_extension_unsupported_error",
                type: "error",
                message: translate("file_extension_unsupported_error")
              },
              {
                id: "file_size_error",
                type: "error",
                message: translate("file_size_error")
              }
            ]}
            maxSize={__GROUP_COVER_SIZE__}
            mimeArray={__GROUP_COVER_MIME__}
          />
          <S.AvatarWrapper>
            <S.AvatarWithInitialsWrapper>
              <AvatarWithInitials
                theme={whiteTheme}
                alt={spaceName}
                url={badgeImagePreview}
                name={spaceName || ""}
                avatarColor={randomSpaceNameColor}
                size="small"
              />
            </S.AvatarWithInitialsWrapper>
          </S.AvatarWrapper>
        </S.SpaceBadgeImageContainer>
        <S.InputWrapper>
          <Input
            value={spaceName}
            theme={currentTheme}
            label={translate("space_name_creation_space_name")}
            name="spaceName"
            rounded
            placeholder={translate(
              "space_name_creation_space_name_placeholder"
            )}
            onChange={handleOnSpaceNameChange}
            hideLabel={false}
            feedback={errors.spaceName}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <InputUrl
            staticUrlDomain={STATIC_DOMAIN_URL}
            label={translate("space_name_creation_domain")}
            hideLabel={false}
            value={spaceDomain}
            onChange={e =>
              handleOnSpaceDomainChange(
                e as React.ChangeEvent<HTMLInputElement>
              )
            }
            theme={currentTheme}
            tooltipString={translate("space_name_creation_url_tooltip")}
            rounded
            feedback={errors.spaceDomain}
          />
        </S.InputWrapper>
      </Box>
      <S.InputSubmitWrapper isMobile={isMobile}>
        <Button
          type="button"
          onClick={() => handleSubmit(false)}
          theme={currentTheme}
          variant="primary"
          disabled={!!errors.spaceDomain || isSubmitDisabled}
        >
          {isOnboardingFlow
            ? translate("onboarding_space_creation_submit")
            : translate("space_creation_submit")}
        </Button>
        <S.Spacer />
        {isOnboardingFlow ? (
          <Button
            type="button"
            onClick={() => handleSubmit(true)}
            theme={currentTheme}
            variant="secondary"
            disabled={!!errors.spaceDomain || isSubmitDisabled}
          >
            {translate("onboarding_space_creation_template_selection_submit")}
          </Button>
        ) : null}
      </S.InputSubmitWrapper>
      {!isOnboardingFlow ? (
        <S.SchoolSpaceRedeemContainer>
          <Text type="headerTitle">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate("space_creation_school_text")
              }}
            />
          </Text>
          <S.StyledLinkButton onClick={goToClaim}>
            {translate("space_creation_school_action")}
          </S.StyledLinkButton>
        </S.SchoolSpaceRedeemContainer>
      ) : null}
    </S.SpaceCreationFormContainer>
  );
};

export default observer(SpaceCreationForm);
