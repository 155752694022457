import { TranslationKeys } from "src/js/translation";
import { ObjectValues } from "src/js/types";

const CourseTypology = {
  SelfPaced: "self",
  Cohort: "cohort"
} as const;

export type CourseTypology = ObjectValues<typeof CourseTypology>;

export const __COURSE_TYPOLOGY_STEP_OPTIONS__: {
  id: CourseTypology;
  label: TranslationKeys;
}[] = [
  {
    id: CourseTypology.Cohort,
    label: "course_typology_dropdown_coop"
  },
  {
    id: CourseTypology.SelfPaced,
    label: "course_typology_dropdown_solo"
  }
];
