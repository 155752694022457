import styled from "styled-components";

export const ClosableErrorContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.closableerror.background};
  color: ${({ theme }) => theme.closableerror.text};
  vertical-align: center;
  width: 700px;

  @media screen and (max-width: 767px) {
    max-width: 335px;
    width: 100%;
  }
`;

export const ClosableErrorIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ClosableErrorMessage = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 767px) {
    padding-left: 12px;
  }
`;

export const ClosableErrorClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  cursor: pointer;
`;
