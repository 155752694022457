import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./SpecificError.styles";
import { SpecificErrorProps } from "./types";

const SpecificError: FC<SpecificErrorProps> = ({ theme, text }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.SpecificErrorContainer>
        <S.SpecificErrorIcon>
          <Icon icon="dangerCircle" width={16} height={16} />
        </S.SpecificErrorIcon>
        <S.SpecificErrorMessage>
          <Text type="cta">{text}</Text>
        </S.SpecificErrorMessage>
      </S.SpecificErrorContainer>
    </ThemeProvider>
  );
};

export default SpecificError;
