import { useEffect } from "react";
import { useStores } from "src/js/hooks";
import { BoardElement, OldBoard } from "src/js/types";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";

const useBoardRealtimeEvents = ({
  activeBoard,
  updateBoard
}: {
  activeBoard: OldBoard;
  updateBoard: (board: OldBoard) => void;
}) => {
  const {
    UserStore: { activeUser }
  } = useStores();

  useEffect(() => {
    if (!activeBoard) return () => {};
    // TODO check if this function is still needed, the new comment strategy has changed
    RealTimeDispatcher.on("do_board_element_comment", obj => {
      let shouldUpdate = false;
      if (obj.agent.id === activeUser.id) return;
      if (obj.target.parent.parent.id !== activeBoard.id) return;

      const targetComment = obj.target.entity;
      targetComment.doAnimation = true;

      const elements = activeBoard.board_elements.filter(
        res => res.dtype !== "boardelementdraft"
      );

      if (!elements.length) return;

      const newElements = elements.map(element => {
        if (element.id !== obj.target.parent.entity.id) return element;

        shouldUpdate = true;
        const commentsCopy = [...element.comments];

        commentsCopy.map(comment => {
          if (comment.id !== targetComment.id) return comment;
          const commentCopy = { ...comment };
          commentCopy.comments_count = comment.comments_count
            ? Number(element.comments_count) + 1
            : 1;
          return commentCopy;
        });

        return { ...element, comments: commentsCopy } as BoardElement;
      });

      if (shouldUpdate) {
        updateBoard({ ...activeBoard, board_elements: newElements });
      }
    });

    // TODO check if this function is still needed, the new comment strategy has changed
    RealTimeDispatcher.on("do_nested_comment", obj => {
      if (!activeBoard) return;
      if (obj.target.parent.parent.id !== activeBoard.id) return;

      let shouldUpdate = false;

      const elements = activeBoard.board_elements.filter(
        res => res.dtype !== "boardelementdraft"
      );

      const resourceId = obj.target.parent.parent.id;

      const newElements = elements.map(element => {
        if (element.resource.id !== resourceId) return element;
        shouldUpdate = true;
        const newElement = element;
        newElement.comments_count += 1;
        return newElement;
      });

      if (shouldUpdate) {
        updateBoard({ ...activeBoard, board_elements: newElements });
      }
    });

    return () => {
      RealTimeDispatcher.off("do_board_element_comment");
      RealTimeDispatcher.off("do_nested_comment");
    };
  }, [activeBoard, activeUser]);
};

export default useBoardRealtimeEvents;
