import React, { FC } from "react";
import * as S from "./PlanCard.styles";
import { PlanCardProps } from "./PlanCard.types";

const PlanCard: FC<PlanCardProps> = ({
  titleLabel,
  priceLabel,
  chip,
  isSelected = false,
  disabled = false,
  onClick,
  children
}) => {
  return (
    <S.PlanCardWrapper
      onClick={disabled ? null : onClick}
      disabled={disabled}
      isSelected={isSelected}
    >
      <S.HeaderContainer>
        <S.TitleContainer>
          <S.StyledHeading level="5">{titleLabel}</S.StyledHeading>
          {chip}
        </S.TitleContainer>
        {priceLabel && !disabled ? (
          <S.StyledHeading level="5">{priceLabel}</S.StyledHeading>
        ) : null}
      </S.HeaderContainer>
      <S.Divider />
      <S.BodyContainer>{children}</S.BodyContainer>
      {isSelected ? (
        <S.CircleCheckIcon icon="check2" width="24px" height="24px" />
      ) : null}
    </S.PlanCardWrapper>
  );
};

export default PlanCard;
