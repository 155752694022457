import React from "react";
import { AnimatePresence } from "framer-motion";
import * as S from "./StackEffect.styles";

const StackEffect = ({
  children,
  shouldAnimate = true
}: {
  children: React.ReactNode;
  shouldAnimate?: boolean;
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <S.StackWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <S.AvatarWrapper>{children}</S.AvatarWrapper>
      <AnimatePresence>
        {isHovered && shouldAnimate && (
          <>
            <S.MidShadow
              initial={{ y: 0.5, opacity: 0 }}
              animate={{ y: 2, opacity: 1 }}
              exit={{ y: 0.5, opacity: 0 }}
              transition={{
                y: { duration: 0.7 },
                opacity: { duration: 0.4 }
              }}
            />
            <S.UnderShadow
              initial={{ y: 3, opacity: 0 }}
              animate={{ y: 6, opacity: 1 }}
              exit={{ y: 3, opacity: 0 }}
              transition={{
                y: { duration: 0.7 },
                opacity: { duration: 0.4 }
              }}
            />
          </>
        )}
      </AnimatePresence>
    </S.StackWrapper>
  );
};

export default StackEffect;
