import React, { FC } from "react";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ClosableFeedback.styles";
import { ClosableFeedbackProps } from "./types";

const ClosableFeedback: FC<ClosableFeedbackProps> = ({
  text,
  onClickFunction
}) => {
  return (
    <S.ClosableFeedbackContainer>
      <S.ClosableFeedbackIcon>
        <Icon icon="successCircle" width={16} height={16} />
      </S.ClosableFeedbackIcon>
      <S.ClosableFeedbackMessage>
        <Text type="cta">{text}</Text>
      </S.ClosableFeedbackMessage>
      <S.ClosableFeedbackClose onClick={onClickFunction}>
        <Icon icon="close" width={14} height={14} />
      </S.ClosableFeedbackClose>
    </S.ClosableFeedbackContainer>
  );
};

export default ClosableFeedback;
