import { Box, Button } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTranslation } from "src/js/translation";
import { EventDomain, GroupEvent, SpacesGroupType } from "src/js/types";
import { useTheme } from "styled-components";
import { AssessmentScore } from "./components/AssessmentScore";
import { CourseProgress } from "./components/CourseProgress";
import { LearningTime } from "./components/LearningTime";
import { PrimaryObjectives } from "./components/PrimaryObjectives";
import { SessionsCount } from "./components/SessionsCount";
import { TrainingHours } from "./components/TrainingHours";

const Objectives = ({ spaceGroups }: { spaceGroups: SpacesGroupType[] }) => {
  const {
    SpaceStore: { activeSpaceId },
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const noGroups = !spaceGroups || spaceGroups.length === 0;

  const openGroupCreationModal = () => {
    _trackEvent(EventDomain.Group, GroupEvent.DashboardGroupsEmptyState);
    openModal(() => <GroupCreationModal />);
  };

  const noGroupsButton = noGroups ? (
    <Button
      variant="secondary"
      theme={whiteTheme}
      onClick={openGroupCreationModal}
    >
      {translate("empty_metric_create_group_cta")}
    </Button>
  ) : null;

  if (!activeSpaceId) {
    return null;
  }

  return (
    <>
      <PrimaryObjectives noGroups={noGroups} />
      <Box
        display="flex"
        flexDirection="column"
        gap={16}
        {...(isLayoutModeMobile && {
          padding: "0 16px"
        })}
      >
        <CourseProgress noGroupsButton={noGroupsButton} />
        <AssessmentScore
          spaceGroups={spaceGroups}
          noGroupsButton={noGroupsButton}
        />
        <TrainingHours noGroupsButton={noGroupsButton} />
        <LearningTime noGroupsButton={noGroupsButton} />
        <SessionsCount noGroupsButton={noGroupsButton} />
      </Box>
    </>
  );
};

export default observer(Objectives);
