import colors from "@ds_themes/colors";

export const avatar = {
  xxl: {
    mask: "170px",
    img: "170px"
  },
  xl: {
    mask: "100px",
    img: "200px"
  },
  l: {
    mask: "64px",
    img: "128px"
  },
  m: {
    mask: "40px",
    img: "80px",
    badge: {
      background: colors.violet[501],
      text: colors.grey[1000]
    }
  },
  s: {
    mask: "32px",
    img: "64px"
  },
  xs: {
    mask: "16px",
    img: "32px"
  },
  image: {
    width: "100%"
  }
};
