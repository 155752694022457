import React, { useRef } from "react";
import { BoardPath } from "src/js/components/BoardPath";
import { useOnClickOutSide } from "src/js/hooks";

import * as S from "./Boards.styles";

const BoardPathWrapper = ({
  setShowPathBoard,
  tasksAreaIsVisible,
  incompletedTasksList,
  selectedBoard
}) => {
  const boardPathRef = useRef();
  useOnClickOutSide(boardPathRef, () => setShowPathBoard(false));
  return (
    <S.BoardPathWrapper
      tasksAreaIsVisible={
        tasksAreaIsVisible && incompletedTasksList?.length > 0
      }
      ref={boardPathRef}
      key={`board-item-${selectedBoard.id}`}
    >
      <BoardPath
        id={selectedBoard.id}
        name={selectedBoard.name}
        closeFunction={() => setShowPathBoard(false)}
      />
    </S.BoardPathWrapper>
  );
};

export default BoardPathWrapper;
