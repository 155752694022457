import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useStores } from "src/js/hooks";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { fetchSpaceDetails } from "src/js/repository/spacesRepository";
import { SpaceDetailsModel } from "src/js/types";

const useInitSpace = () => {
  const {
    SpaceStore: { setActiveSpaceById, activeSpaceId }
  } = useStores();

  const {
    params: { space_slug }
  } = useRouteMatch<{ space_slug: string }>({
    path: WeSchoolRoutes.App.Spaces.spaceGroups
  }) ?? { params: { space_slug: "" } };

  const checkAndSetActiveSpace = (response: SpaceDetailsModel) => {
    const { space } = response;
    if (space?.id === activeSpaceId) return Promise.resolve();
    return setActiveSpaceById(space.id);
  };

  useEffect(() => {
    if (space_slug) {
      fetchSpaceDetails(space_slug).then((data: SpaceDetailsModel) => {
        checkAndSetActiveSpace(data);
      });
    }
  }, [space_slug]);

  return { spaceSlug: space_slug };
};

export default useInitSpace;
