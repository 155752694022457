import styled from "styled-components";

import { ItemStyleProps } from "./types";

export const ItemContainer = styled.button<ItemStyleProps>`
  all: unset;
  width: 40px;
  height: 40px;
  display: flex;
  color: ${({ theme }) => theme.toolbarmenuitem.color};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.toolbarmenuitem.selected.background
      : theme.toolbarmenuitem.background};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    color: ${({ theme }) => theme.toolbarmenuitem.disabled.color};
    &:hover {
      cursor: not-allowed;
      color: ${({ theme }) => theme.toolbarmenuitem.disabled.color};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.toolbarmenuitem.hover.color};
  }
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
`;

export const EventDot = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  opacity: 1;
  border: 1px solid ${({ theme }) => theme.toolbarmenuitem.dot.border};
  background-color: ${({ theme }) => theme.toolbarmenuitem.dot.background};
  top: 0px;
  right: 0px;
`;
