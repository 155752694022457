import { shadow } from "@arcadia/design-system";
import styled from "styled-components";
import { motion } from "framer-motion";

export const SpaceListWrapper = styled(motion.div)`
  width: 296px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  position: absolute;
  padding: 8px 0px 12px 8px;
  border: 1px solid ${({ theme }) => theme.colors.violet[900]};
  border-radius: 8px;
  box-shadow: ${shadow[600]};
  z-index: ${({ theme }) => theme.zIndex.forModal - 1};
  top: calc(100% + 4px);
  left: 0px;
`;
export const PlusButton = styled.button`
  all: unset;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border: 2px solid ${({ theme }) => theme.primaryColor[500]};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UnreadNotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  outline: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -2px;
`;

export const AvatarWrapper = styled.div`
  margin-top: 8px;
  cursor: pointer;
  font-size: 16px;
`;
