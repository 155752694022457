import moment from "moment";
import React from "react";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { Translate } from "src/js/translation/TranslationProvider";
import { TextEditorRender } from "../../TextEditorRender";
import { QuizReportResource } from "../QuizReportResource";
import * as S from "./ExerciseThreadStudentReply.styles";

const ExerciseThreadStudentReply = ({ response = {} }) => {
  const dateCorrection = moment(response.created_at).format("DD/MM/YYYY HH:mm");
  const timeFromNow = moment(response.created_at).fromNow();
  let resourceViewer = "";

  if (response.resource || response.resource_id) {
    if (response.resource) {
      resourceViewer = (
        <S.ResponceResource>
          <QuizReportResource
            resource={response.resource}
            resourceId={response.resource.id}
          />
        </S.ResponceResource>
      );
    } else {
      resourceViewer = (
        <S.ResponceResource>
          <QuizReportResource resourceId={response.resource_id} />
        </S.ResponceResource>
      );
    }
  }

  return (
    <>
      <S.ResponseHeader>
        <CommentTitle>
          <Translate text="quiz_report_reply_of_label" />
          {` ${response.name} ${response.surname}`}
        </CommentTitle>
        <S.ResponseFromNow>
          <CommentSubtitle>
            {`${dateCorrection} (${timeFromNow})`}
          </CommentSubtitle>
        </S.ResponseFromNow>
      </S.ResponseHeader>
      {resourceViewer}
      <S.ResponseText>
        {response.thread_message ? (
          <TextEditorRender content={response.thread_message} />
        ) : null}
      </S.ResponseText>
    </>
  );
};

export default ExerciseThreadStudentReply;
