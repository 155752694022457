import { getActiveUser } from "../modules/activeUser";
import { InternalDispatcher } from "../modules/dispatcher";
import { __CONTENT_CLASS__ } from "../settings/settings";

InternalDispatcher.on("updateUser", () => {
  const user = getActiveUser();
  user.fetchScore(); // Fetch the current user score
});

/** Show the full page loading icon */
function startLoading() {
  const obj = `.${__CONTENT_CLASS__}`;
  if ($(obj).length > 0) {
    $(obj).append(
      "<div class='loader-absolute'><div class='loader'></div></div>"
    );
  }
}

/** Hide the full page loading icon */
function stopLoading() {
  $(".loader-absolute").remove();
}

InternalDispatcher.on("startLoading", () => {
  startLoading();
});

InternalDispatcher.on("stopLoading", () => {
  stopLoading();
});
