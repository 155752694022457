import { LinkButton } from "@arcadia/design-system";
import styled from "styled-components";

type BannerContainerProps = {
  margin?: number | string;
  maxWidth?: number | string;
};

export const BannerContainer = styled.div<BannerContainerProps>`
  display: flex;
  max-width: ${({ maxWidth }) => maxWidth || "65%"};
  flex-wrap: wrap;
  align-content: space-around;
  background: ${({ theme }) => theme.colors.yellow[800]};
  border: 1px solid ${({ theme }) => theme.colors.yellow[550]};
  border-radius: 8px;
  margin: ${({ margin }) => margin || null};
  & > svg {
    margin: auto 8px;
  }
  & > span {
    max-width: 80%;
    padding: 8px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  display: contents;
  > span {
    color: ${({ theme }) => theme.colors.grey[50]};
    font-weight: bold !important;
    &:hover {
      color: ${({ theme }) => theme.colors.grey[200]};
    }
  }
`;
