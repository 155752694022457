import React, { useState, useEffect } from "react";
import { Text, Icon, Box, IconName } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { saveModuleResourceTitle } from "src/js/repository/resourceRepository";
import { useTranslation } from "src/js/translation";
import { ListItemProps } from "src/js/components/DotsMenu/ListItem";
import { useActiveBoard, useSetBoard } from "src/js/context/BoardProvider";
import { useStores } from "src/js/hooks";
import { useResourcePreviewDetails } from "src/js/hooks/resources";
import { ModuleMode } from "src/js/pages/modules/Modules.types";
import { CropImageModal } from "src/js/components/CropImageModal";
import { useQueryClient } from "@tanstack/react-query";
import { boardElementsQueryKey } from "src/js/query";
import * as S from "./ResourceInfo.styles";
import { ResourceInfoProps } from "./ResourceInfo.types";

const ResourceInfo = ({
  isCompleted,
  author,
  createdAt,
  resourcePoints,
  title,
  resourceId,
  moduleId,
  removeResource,
  isSuggestedResource,
  hasPreviewGenerated,
  resourceImage,
  mimeType,
  setDraggable,
  resourcePosition
}: ResourceInfoProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    GroupStore: { userIsTeacher },
    GamificationStore: { resourcesScore },
    UserStore: { activeUser },
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const activeBoard = useActiveBoard();
  const saveBoard = useSetBoard();
  const { getResourcePreview, saveNewCroppedImage } =
    useResourcePreviewDetails();
  const [pointEarned, setPointEarned] = useState(0);
  const [editingTitle, setEditingTitle] = useState(false);
  const [resourceTitle, setNewTitle] = useState(title);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (resourcesScore && resourcesScore.length > 0) {
      const pointInfo = resourcesScore.find(
        ({ board_element_id }) => board_element_id === resourceId
      );
      setPointEarned(pointInfo?.score || 0);
    }
  }, [resourcesScore]);

  useEffect(() => {
    setDraggable?.(editingTitle);
  }, [editingTitle]);

  const userHasEditPermission =
    userIsTeacher ||
    (activeBoard.mode === ModuleMode.edit && activeUser.id === author.id); // user can edit if the board is open or if is a teacher or if is the creator of the resources

  const updateNewCroppedImage = (
    newCroppedImage: File,
    setButtonDisabled: (isDisabled: boolean) => void
  ) => {
    saveNewCroppedImage(
      newCroppedImage,
      setButtonDisabled,
      moduleId,
      resourceId
    );
  };

  const menuOptions: ListItemProps[] = [
    userIsTeacher && {
      id: "delete-resource",
      redShade: true,
      label: translate("resource_remove_label"),
      icon: "trash" as IconName,
      onClick: () => removeResource(resourceId)
    },
    isLayoutModeMobile &&
      userHasEditPermission && {
        id: "edit-thumbnail",
        label: translate("resource_edit_thumbnail_title"),
        icon: "editSecondary" as IconName,
        onClick: () =>
          openModal(() => (
            <CropImageModal
              close={closeModal}
              shouldZoomInImage={hasPreviewGenerated}
              imagePreview={getResourcePreview(
                hasPreviewGenerated,
                resourceImage
              )}
              saveNewCroppedImage={updateNewCroppedImage}
              aspect={3 / 2.2}
              modalTitle="resource_edit_thumbnail_title"
              imageMimeType={mimeType}
              imageTitle={title}
            />
          ))
      }
  ].filter(item => item);

  const titlePrefix = activeBoard.has_mandatory_order
    ? `${resourcePosition + 1}. `
    : "";

  const isAbleToChangeTitle = () => {
    if (isSuggestedResource || !userHasEditPermission) {
      return (
        <Box padding="8px">
          <S.ResourceTitle level="6">
            {titlePrefix}
            {resourceTitle}
          </S.ResourceTitle>
        </Box>
      );
    }

    return editingTitle ? (
      <S.InputContainer
        theme={whiteTheme}
        label="hiddenLabel"
        hideLabel
        value={resourceTitle}
        onChange={event => {
          event.preventDefault();
          event.stopPropagation();
          setNewTitle(event.target.value);
        }}
        onClick={event => {
          event.stopPropagation();
        }}
        rightSection={
          <Box
            onClick={event => {
              event.stopPropagation();
              saveModuleResourceTitle(moduleId, resourceId, resourceTitle)
                .then((response: { name: string }) => {
                  // Seems that this file is using `resourceId` but is actually the boardElementId
                  // so the invalidation should work fine

                  queryClient.invalidateQueries(
                    boardElementsQueryKey.boardElementDetails({
                      resourceId: `${resourceId}`
                    })
                  );

                  setNewTitle(response.name);
                  saveBoard(activeBoard.id, true);
                })
                .catch(() => {
                  setNewTitle(title);
                });
              setEditingTitle(false);
            }}
          >
            <Icon icon="check2" width={16} height={16} initialViewbox />
          </Box>
        }
      />
    ) : (
      <Box width="100%" id="container-input-box">
        <S.ResourceTitleWithIcon id="initial-resource-title-heading">
          <S.ResourceTitle level="6">
            {titlePrefix}
            {resourceTitle}
          </S.ResourceTitle>
          <S.EditIcon
            id="resource-edit-icon"
            onClick={event => {
              event.stopPropagation();
              setEditingTitle(true);
            }}
          >
            <Icon icon="pencil" width={16} height={16} initialViewbox />
          </S.EditIcon>
        </S.ResourceTitleWithIcon>
      </Box>
    );
  };

  return (
    <S.ResourceInfoContainer>
      <S.ResourceInfoDetail>
        <S.ResourceInfoCreation>
          <Text type="formSmallCaption">
            {`${author.name} ${author.surname}`} - {createdAt}
          </Text>
        </S.ResourceInfoCreation>
        <Box display="flex" gap="12px" alignItems="center">
          {isCompleted && !userIsTeacher && (
            <S.ResourceCompletedMarks>
              <Icon icon="check2" width="10" height="10" />
            </S.ResourceCompletedMarks>
          )}
          {!isSuggestedResource && (
            <S.CustomDotsMenu
              id="resourceInfoDotsMenu"
              alignRight
              items={menuOptions}
              customIcon="kebabVertical"
            />
          )}
        </Box>
      </S.ResourceInfoDetail>
      <S.ResourceTitleContainer>
        {isAbleToChangeTitle()}

        {resourcePoints !== 0 && (
          <S.GamificationPointsBox>
            {pointEarned}/{resourcePoints}
          </S.GamificationPointsBox>
        )}
      </S.ResourceTitleContainer>
    </S.ResourceInfoContainer>
  );
};

export default ResourceInfo;
