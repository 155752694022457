export enum SpaceGenerationStatesEnum {
  START_MESSAGE_TYPE = "demo_space_creation_started",
  FAIL_MESSAGE_TYPE = "demo_space_creation_failed",
  END_MESSAGE_TYPE = "demo_space_created"
}

export enum SpaceUserRoleEnum {
  Owner = "ROLE_OWNER",
  Admin = "ROLE_ADMIN",
  PowerUser = "ROLE_POWERUSER"
}

export enum SpaceType {
  STANDARD = "Standard",
  DEMO = "Demo",
  BLUEPRINT = "Blueprint"
}
