import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Card = styled.div<{ isLayoutModeMobile?: boolean }>`
  background-color: ${({ theme, isLayoutModeMobile }) =>
    isLayoutModeMobile ? theme.colors.grey[1000] : theme.colors.coldGrey[900]};
  border-radius: 8px;
  padding: 16px;
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.violet[501]};
`;

export const LeftSection = styled.div`
  padding-left: 30px;
`;

export const Row = styled.div<{ faded?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: ${({ theme, faded }) => theme.colors.grey[faded ? 300 : 50]};
`;

export const Footer = styled.div<{ isLayoutModeMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 12px;
  ${({ isLayoutModeMobile }) =>
    isLayoutModeMobile &&
    css`
      position: fixed;
      bottom: 0;
    `}
`;
