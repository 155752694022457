import styled from "styled-components";

export const FormSubtitleStyle = styled.span`
  color: ${({ theme }) => theme.colors.grey[50]};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
`;
