import { useActiveBoard, useSetBoard } from "src/js/context/BoardProvider";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { ModuleMode } from "src/js/pages/modules/Modules.types";
import {
  suggestModuleResource,
  updateBoard
} from "src/js/repository/boardRepository";

const useResourceUpdateModuleElements = (resourceElementIndex: number) => {
  const {
    BoardsStore: { setActiveBoardStore },
    GroupStore: { groupId, groupName, userIsTeacher }
  } = useStores();
  const setBoard = useSetBoard();
  const activeBoard = useActiveBoard();

  const attachResourceToModuleElement = async (
    id: number,
    name: string,
    resourceData: {
      type: string;
      name?: string;
      content?: string;
      selected?: boolean;
      exercise?: {
        group_id: number;
        group_name: string;
        id: number;
        name: string;
        title: string;
      };
    },
    onSuccess?: () => void,
    forceUpdate = true
  ) => {
    if (userIsTeacher || activeBoard.mode === ModuleMode.edit) {
      updateBoard(activeBoard.id, {
        ...activeBoard,
        name: activeBoard.name,
        updated: Date.now(),
        board_elements: [
          {
            position: resourceElementIndex,
            resource: {
              group_id: groupId,
              group_name: groupName,
              id,
              name,
              ...resourceData
            }
          }
        ]
      }).then(data => {
        if (onSuccess) onSuccess();
        _trackEvent(
          "Lessons",
          "LessonsNewTeacherResource",
          resourceData.type,
          0
        );
        setBoard(activeBoard.id, forceUpdate);
        setActiveBoardStore(data);
      });
    } else {
      suggestModuleResource(
        activeBoard.id,
        resourceElementIndex,
        {
          group_id: groupId,
          id,
          name,
          type: resourceData.type,
          exercise: resourceData.exercise
            ? { id: resourceData.exercise.id }
            : undefined
        },
        groupId
      ).then(() => {
        if (onSuccess) onSuccess();
        _trackEvent(
          "Lessons",
          "LessonsNewStudentResource",
          resourceData.type,
          0
        );
        setBoard(activeBoard.id, forceUpdate);
      });
    }
  };

  return { attachResourceToModuleElement };
};

export default useResourceUpdateModuleElements;
