import React, { useMemo } from "react";
import { useStores } from "src/js/hooks";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { GroupSection } from "src/js/types";
import { GROUP_SECTION } from "src/js/types/enums/Group";
import { SectionPill } from "./SectionPill";
import * as S from "./TemplateDescription.styles";
import TemplateDescriptionMobile from "./TemplateDescriptionMobile";

// Be aware that any changes to this component will affect the GroupTemplateCreation too

type TemplateDescriptionProps = {
  cover: TranslationKeys;
  sections?: GroupSection[];
  templateTitle: string;
  templateAbstract: string;
  onClose?: () => void;
  className?: string;
};

const TemplateDescription = ({
  cover,
  sections,
  templateTitle,
  templateAbstract,
  onClose,
  className
}: TemplateDescriptionProps) => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const orderedSections = useMemo(() => {
    const order = Object.values(GROUP_SECTION);
    return sections?.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  }, [sections]);

  const content = (
    <S.TemplateDescriptionWrap className={className}>
      <S.CoverWrapper>
        <S.CoverImg src={translate(cover)} alt="Template Cover" />
      </S.CoverWrapper>
      <S.SectionWrapper>
        <S.GreyHeading level="5">
          {translate("group_creation_template_description_featured_tool_title")}
        </S.GreyHeading>
        {orderedSections?.length > 0 ? (
          <S.PillsWrapper>
            {orderedSections?.map(section => (
              <SectionPill key={section} section={section} />
            ))}
          </S.PillsWrapper>
        ) : null}
        <S.GreyHeading level="5">{templateTitle}</S.GreyHeading>
        <S.GreyText type="body">{templateAbstract}</S.GreyText>
      </S.SectionWrapper>
    </S.TemplateDescriptionWrap>
  );

  if (isLayoutModeMobile) {
    return (
      <TemplateDescriptionMobile onClose={onClose}>
        {content}
      </TemplateDescriptionMobile>
    );
  }

  return content;
};

export default TemplateDescription;
