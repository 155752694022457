import { Model } from "backbone";
import handleThumbnails from "src/js/modules/imageFunction";
import isNull from "lodash/isNull";
import isFunction from "lodash/isFunction";
import isUndefined from "lodash/isUndefined";
import { createURL } from "../modules/utility";

/**
 * @deprecated Use UserStore
 */
class UserModel extends Model {
  constructor(attributes, options) {
    super(attributes, options);
    this.url = createURL("api_users_generic");
  }

  initialize() {
    // fallback per modello utente in notifiche, che ha picture invece di avatar
    const avatar = this.get("avatar");
    const picture = this.get("picture");
    if (typeof avatar === "undefined" && typeof picture !== "undefined") {
      this.set("avatar", picture);
    }
  }

  isStudent() {
    if (this.get("type") === "STUDENT") {
      return true;
    }
    return false;
  }

  isTeacher() {
    if (this.get("type") === "TEACHER") {
      return true;
    }
    return false;
  }

  getLanguage() {
    let lang = this.get("language");
    if (typeof lang === "undefined" || lang.length === 0)
      lang = this.get("locale");
    if (typeof lang === "undefined" || lang.length === 0) lang = undefined;
    return lang;
  }

  getAvatar(type) {
    const avatar = this.get("avatar");
    if (!avatar) {
      return undefined;
    }
    {
      return handleThumbnails(avatar, type);
    }
  }

  /**
   * Returns unknown|male|female based on sex field, which is a boolean
   * @return {String} unknown|male|female
   */
  getGenderString() {
    let str = "unknown";
    const sex = this.get("sex");
    if (isUndefined(sex) || isNull(sex)) {
      str = "unknown";
    } else {
      if (sex === true) str = "male";
      if (sex === false) str = "female";
    }

    return str;
  }

  getFullName() {
    return `${this.get("name")} ${this.get("surname")}`;
  }

  getLink() {
    return `#${createURL("profile_public", { user_id: this.get("uuid") })}`;
  }

  /**
   * Return the user score
   * @return {Number}
   */
  getScore() {
    return this.get("score");
  }

  /**
   * Set the user score
   * @param {Number} score
   * @return {Number} The current user score
   */
  setScore(score) {
    this.set("score", score);
    return score;
  }

  getId() {
    return this.get("id");
  }

  /**
   * Fetch the user score from the server and updates the model
   * Fetch only if the user already exists
   * @param  {Function} [callbackOk]    The optional function to call on success
   * @param  {Function} [callbackError] The optional function to call on error
   */
  fetchScore(callbackOk, callbackError) {
    const self = this;

    if (this.has("id")) {
      $.ajax({
        url: createURL("api_user_score", {
          user_id: this.get("id")
        }),
        method: "GET",
        success(ret) {
          const score = ret.score;
          self.setScore(score);
          if (isFunction(callbackOk)) callbackOk(ret);
        },
        error(data) {
          if (isFunction(callbackError)) callbackError(data);
        }
      });
    }
  }
}

export default UserModel;
