import { colors } from "@arcadia/design-system";
import styled from "styled-components";

export const BottomSheetBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px;

  & > div {
    border: 1px solid ${colors.grey[800]};
  }
`;
