import React, { useEffect, useMemo, useState } from "react";
import {
  BannerProps,
  StickyInfoBannerTranslations
} from "src/js/components/layout/StickyInfoBanner/types";
import { useStores } from "src/js/hooks";
import { readCookie } from "src/js/modules/storageFunction";
import { ClaimModal } from "src/js/components/layout/ClaimModal";
import { useTranslation } from "src/js/translation";

const useStickyBannerContext = (): BannerProps => {
  const {
    SpaceStore: { isDemoSpace, isClaimable, activeSpaceName },
    ModalStore: { openModal, closeModal },
    GroupStore: { groupId },
    UIStore: { updateStickyBannerVisibility }
  } = useStores();
  const [stickyBannerProps, setStickyBannerProps] = useState(null);
  const { translateStrict } = useTranslation();

  const isAbleToClaimSpace =
    (readCookie("claim_space_info") &&
      readCookie("claim_space_info") !== "dismissed") ||
    isClaimable;

  const demoSpaceTranslations: StickyInfoBannerTranslations = useMemo(
    () => ({
      titleOpened: "demo_space_main_info_banner_closed_text",
      textClosed: "demo_space_main_info_banner_closed_text",
      textOpened: "demo_space_main_info_banner_open_text",
      ctaDismissMobile: "demo_space_main_info_banner_dismiss_cta",
      ctaOpenedMobile: "demo_space_main_info_banner_open_mobile_cta",
      ctaOpened: "demo_space_main_info_banner_open_cta",
      ctaLink: "demo_space_link_url"
    }),
    []
  );

  const claimSpaceTranslations: StickyInfoBannerTranslations = useMemo(
    () => ({
      textClosed: groupId
        ? "claim_space_banner_group_part_of_unclaimed_space"
        : "claim_space_banner_main_button_title",
      titleOpened: "claim_space_banner_heading",
      textOpened: "claim_space_banner_description",
      ctaButton: "claim_space_banner_cta_button",
      footerLink: "claim_space_banner_how_to"
    }),
    [groupId]
  );

  const demoSpaceProps: BannerProps = useMemo(
    () => ({
      withDescriptionButton: true,
      translations: demoSpaceTranslations
    }),
    [demoSpaceTranslations]
  );

  const claimSpaceProps: BannerProps = useMemo(
    () => ({
      translations: claimSpaceTranslations,
      withCtaButton: true,
      withFooterLink: true,
      translationCtaButtonProps: { activeSpaceName },
      footerLink: translateStrict("claim_space_tutorial_link"),
      onClickCtaButton: () => openModal(() => <ClaimModal close={closeModal} />)
    }),
    [claimSpaceTranslations, activeSpaceName]
  );

  useEffect(() => {
    if (isAbleToClaimSpace) {
      updateStickyBannerVisibility(isAbleToClaimSpace);
      setStickyBannerProps(claimSpaceProps);
      return;
    }
    updateStickyBannerVisibility(isDemoSpace);
    setStickyBannerProps(demoSpaceProps);
  }, [isAbleToClaimSpace, isDemoSpace, claimSpaceProps, demoSpaceProps]);

  return stickyBannerProps;
};

export default useStickyBannerContext;
