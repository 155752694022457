import getUserClient from "src/js/modules/connection";
import {
  api_users_platform_tasks,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";

export function fetchUserTasksList() {
  return getUserClient()
    .get(getVersionedApiUrl(api_users_platform_tasks()))
    .then(({ data }) => {
      return data;
    });
}
