import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { Avatar, Icon } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { getUserId } from "../../modules/activeUser";
import UserModel from "../../models/UserModel";
import RankingModel from "../../models/rankingModel";

const RankMember = withBackbone(
  createReactClass({
    getInitialState() {
      return {
        showedAvatars: [],
        rankMember: ""
      };
    },
    getStateIndicator(state) {
      const rankMember = new RankingModel(this.props.rankMember);
      const indicator = {
        className: "stay-position",
        icon: "minus"
      };
      if (rankMember.getScore() == 0) {
        return indicator;
      }
      switch (true) {
        case state > 0:
          indicator.className = "up-position";
          indicator.icon = "chevronUp";
          break;
        case state == 0:
          indicator.className = "stay-position";
          indicator.icon = "minus";
          break;
        case state < 0:
          indicator.className = "down-position";
          indicator.icon = "chevronDown";
          break;
      }
      return indicator;
    },
    render() {
      const self = this;
      const rankMember = new RankingModel(this.props.rankMember);
      const member = new UserModel(rankMember.getUser());
      const memberUrl = member.getLink();
      const position = rankMember.getPosition();
      const score = rankMember.getScore();
      const stateIndicator = self.getStateIndicator(rankMember.getMove());
      const activeclass = member.get("id") == getUserId() ? "current-user" : "";
      const { whiteTheme } = this.props.theme;
      return (
        <li className={activeclass}>
          <a
            href={memberUrl}
            key={`box-member-${member.get("id")}`}
            title={member.getFullName()}
            className="clickable"
          >
            <div className="row">
              <div className="col-xs-1 text-center padding-lateral-xs-5 padding-left-xs-15">
                <div className="rank-number">{position}</div>
              </div>
              <div className="col-xs-2 padding-lateral-xs-5">
                <div className="image-profile">
                  <Avatar
                    url={member.getAvatar("medium")}
                    gender={member.getGenderString()}
                    alt={member.getFullName()}
                    size="l"
                    theme={whiteTheme}
                    rounded
                  />
                </div>
              </div>
              <div className="col-xs-5 col-sm-6 padding-lateral-xs-5">
                <span className="hidden-name">{member.get("name")}</span>{" "}
                {member.get("surname")}
              </div>
              <div className="col-sm-2 col-xs-2 padding-lateral-xs-5">
                <div className="point">{score}</div>
              </div>
              <div className="col-sm-1 col-xs-2 text-center padding-lateral-xs-5">
                <div className={`ranking-change ${stateIndicator.className}`}>
                  <Icon icon={stateIndicator.icon} height={20} width={20} />
                </div>
              </div>
            </div>
          </a>
        </li>
      );
    }
  })
);

export default withTheme(RankMember);
