import { NotificationAreaLabelModel } from "src/js/types/models/Notification";
import styled, { css } from "styled-components";
import {
  getPillBkColorFromType,
  getPillColorFromType
} from "../../NotificationPage.utils";

type SPillStyle = {
  type: NotificationAreaLabelModel;
  isActive: boolean;
};

export const PillWrapper = styled.div<SPillStyle>`
  border-radius: 20px;
  background-color: ${({ type }) => getPillBkColorFromType(type)};
  display: inline-flex;
  inline-size: fit-content;
  border: solid 1px transparent;
  padding: 3px;

  ${({ isActive, theme }) =>
    !isActive &&
    css`
      background-color: ${theme.colors.accent4};
      border: solid 1px ${theme.colors.accent3};
    `}

  @media (max-width: 767px) {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div<SPillStyle>`
  border-radius: 50%;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-right: 8px;
  align-self: center;
  background-color: ${({ type }) => getPillColorFromType(type)};

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.basicWhite};

  ${({ isActive, theme }) =>
    !isActive &&
    css`
      background-color: ${theme.colors.accent3};
    `}
`;

export const CheckboxLabel = styled.label<{ isActive: boolean }>`
  padding-right: 12px;
  user-select: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[50]};

  ${({ isActive, theme }) =>
    !isActive &&
    css`
      color: ${theme.colors.blueGrey[500]};
    `}
  @media (max-width: 767px) {
    cursor: pointer;
  }
`;

export const VisuallyHiddenCheckbox = styled.input`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const FakeCheckbox = styled.div`
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
