import { Avatar, Icon, Text } from "@arcadia/design-system";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import { useTheme } from "styled-components";
import { SuggestionDataItem } from "react-mentions";
import {
  MemberNonAdmin,
  Role
} from "src/js/pages/group/settings/GroupSettingsMembers/GroupSettingsMembers.types";
import * as S from "./Suggestion.styles";

const Suggestion = ({
  suggestion,
  users,
  focused
}: {
  suggestion: SuggestionDataItem;
  users: MemberNonAdmin[];
  focused: boolean;
}) => {
  const { whiteTheme } = useTheme();
  const userRef = useRef<HTMLDivElement>();
  const [isHovered, setIsHovered] = React.useState(false);
  useLayoutEffect(() => {
    const container = document.getElementsByClassName(
      "ws-mentions-portal-list-wrapper"
    )[0];
    const scrollableRect = container.getBoundingClientRect();
    const itemRect = userRef.current.getBoundingClientRect();

    const isItemVisible =
      itemRect.top >= scrollableRect.top &&
      itemRect.left >= scrollableRect.left &&
      itemRect.bottom <= scrollableRect.bottom &&
      itemRect.right <= scrollableRect.right;

    if (focused && userRef.current && !isItemVisible && !isHovered) {
      container.scrollTop = userRef.current.offsetTop - 49;
    }
  }, [focused, isHovered]);

  const getAvatar = useCallback(
    (uuid: string) => {
      try {
        return users.find(user => user.uuid === uuid).avatar?.small;
      } catch {
        return null;
      }
    },
    [users]
  );

  const getIsTeacher = useCallback((uuid: string) => {
    try {
      const user = users.find(u => u.uuid === uuid);
      return (
        user.roles.includes(Role.Teacher) || user.roles.includes(Role.Creator)
      );
    } catch {
      return null;
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <S.User
      ref={userRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Avatar theme={whiteTheme} size="s" url={getAvatar(`${suggestion.id}`)} />
      <Text type="subHeaderTitle">{suggestion.display}</Text>
      {getIsTeacher(`${suggestion.id}`) ? (
        <S.IconWrapper>
          <Icon icon="teacherRibbon" width={12} height={12} />
        </S.IconWrapper>
      ) : undefined}
    </S.User>
  );
};

export default Suggestion;
