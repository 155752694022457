import React from "react";
import * as S from "src/js/components/global/CheckIndicator/CheckIndicator.styles";
import { Icon } from "@arcadia/design-system";

const CheckIndicator = ({ isSelected }) => {
  return (
    <S.CheckIndicatorContainer isSelected={isSelected}>
      <Icon icon="check2" width={10} height={10} />
    </S.CheckIndicatorContainer>
  );
};

export default CheckIndicator;
