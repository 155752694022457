import styled from "styled-components";

export const DropdownFilterContainer = styled.div`
  width: 220px;
  > div > div > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 14px;
  }
  > div > div[role="button"] {
    height: 36px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 50%;
    > div > div:nth-child(2) {
      width: 150%;
    }
  }
`;
