import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[50]};
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  border-radius: 12px;
  min-height: 112px;
  margin-top: 12px;
  padding: 24px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    min-height: 52px;
    padding: 16px;
    border-radius: 8px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled(Text)`
  margin-left: 8px;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-weight: 500;
  }
`;

export const DescriptionContainer = styled.pre`
  all: unset;
  white-space: pre-wrap;
  margin: 12px 0 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
`;
