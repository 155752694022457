export const __FORM_LOGIN__ = {
  username: {
    required: "login_username_validation_error",
    pattern: {
      value: /^[A-Z0-9._%+-.'-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
      message: "email_validation_error"
    }
  },
  password: {
    required: "login_password_validation_error"
  }
};
