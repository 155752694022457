import { Editor } from "@tiptap/react";
import { ActionButton, Icon } from "@arcadia/design-system";
import React, { useState } from "react";
import {
  PrepareResourcePayload,
  PrepareWSResource
} from "src/js/hooks/resources/useResourcePayload";
import { preventAndroidKeyboardClose } from "src/js/modules/commonFunction";
import { isTouchDevice } from "src/js/modules/deviceFunction";
import { useTheme } from "styled-components";
import { TextEditorEmojiButton } from "../TextEditorEmojiButton";
import { TextEditorMenu } from "../TextEditorMenu";
import {
  MenuButton,
  MenuSeparator
} from "../TextEditorMenu/TextEditorMenu.styles";
import * as S from "./TextEditorMobileFooter.styles";

const TextEditorMobileFooter = ({
  editor,
  prepareModuleResourcePayload,
  handleSubmit,
  submitDisabled,
  prepareWSResource,
  isEditMode,
  clearEditMode
}: {
  editor: Editor;
  prepareModuleResourcePayload: PrepareResourcePayload;
  prepareWSResource: PrepareWSResource;
  handleSubmit: () => void;
  submitDisabled: boolean;
  isEditMode?: boolean;
  clearEditMode: () => void;
}) => {
  const { whiteTheme } = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <S.FooterContainer>
      {showMenu ? (
        <>
          <S.CloseButton
            {...preventAndroidKeyboardClose}
            type="button"
            onClick={() => setShowMenu(false)}
          >
            <Icon icon="close" height={12} width={12} />
          </S.CloseButton>
          <S.MenuContainer>
            <TextEditorMenu
              editor={editor}
              prepareModuleResourcePayload={prepareModuleResourcePayload}
              prepareWSResource={prepareWSResource}
            />
          </S.MenuContainer>
        </>
      ) : (
        <S.ActionsContainer>
          <MenuButton
            {...preventAndroidKeyboardClose}
            type="button"
            onClick={() => setShowMenu(true)}
          >
            <Icon icon="doubleA" height={20} width={20} initialViewbox />
          </MenuButton>
          {isTouchDevice() ? null : (
            <>
              <MenuSeparator />
              <TextEditorEmojiButton editor={editor} />
            </>
          )}
        </S.ActionsContainer>
      )}
      {isEditMode ? (
        <S.CancelButton
          variant="secondary"
          theme={whiteTheme}
          icon="close"
          onClick={clearEditMode}
        />
      ) : null}
      <ActionButton
        {...preventAndroidKeyboardClose}
        variant="primary"
        theme={whiteTheme}
        icon={isEditMode ? "check2" : "paperPlane"}
        onClick={handleSubmit}
        disabled={submitDisabled}
      />
    </S.FooterContainer>
  );
};

export default TextEditorMobileFooter;
