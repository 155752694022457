import styled from "styled-components";

export const NoGroupContainer = styled.div`
  padding: 200px 30px 120px;
  margin: 0 auto;
  max-width: 480px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  text-align: center;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 60px);
  }
`;

export const PlaceholderImage = styled.img`
  width: 100%;
`;

export const InfoTitle = styled.h3`
  margin-top: 30px;
  font-weight: ${({ theme }) => theme.weights.bold};
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
`;

export const InfoParagraph = styled.p`
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 767px) {
    margin: 30px auto 80px;
    position: absolute;
    bottom: 0;
    max-width: 420px;
    left: 30px;
    right: 30px;
  }
`;
