import { Ai, AiOptions } from "@tiptap-pro/extension-ai";
import { NodeWithPos, findChildrenInRange, Range } from "@tiptap/core";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiTextEditorEvent, EventDomain } from "src/js/types";

export type AiStoreType = {
  isCustomPromptActive: boolean;
  openedPosition: Range | undefined;
  loading: boolean;
  discovered: boolean;
};

export const __AI_TE_DISCOVERED_KEY__ = "teAiDiscovered";
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    aiStore: {
      setAIPromptActive: (value: boolean) => ReturnType;
      setAiDiscovered: (value: boolean) => ReturnType;
      setLoading: (value: boolean) => ReturnType;
    };
  }
}

const TipTapAi = Ai.extend<AiOptions, AiStoreType>({
  addStorage() {
    return {
      loading: false,
      isCustomPromptActive: false,
      openedPosition: undefined,
      discovered:
        JSON.parse(localStorage.getItem(__AI_TE_DISCOVERED_KEY__)) ?? false
    };
  },
  onTransaction({ transaction }) {
    const aiTransactionMetaData = transaction.getMeta(`${Ai.name}$`);
    if (aiTransactionMetaData) {
      const { queryId, action } = aiTransactionMetaData;
      if (action) {
        this.storage.loading = true;
        this.editor.setEditable(false);
      }
      if (queryId) {
        this.storage.loading = false;
        this.editor.setEditable(true);
      }
    }
    if (transaction.getMeta(this.name) === "spaceTrigger") {
      _trackEvent(
        EventDomain.AiTextEditor,
        AiTextEditorEvent.AiWriterCommandSpaceTrigger
      );
      this.storage.openedPosition = {
        from: transaction.selection.from,
        to: transaction.selection.from
      };
      this.editor.chain().setAiDiscovered(true).setAIPromptActive(true).run();
    }
    transaction.steps.forEach(step => {
      const map = step.getMap();
      map.forEach((oldStart, oldEnd, newStart, newEnd) => {
        const nodesInChangedRanges = findChildrenInRange(
          transaction.doc,
          { from: newStart, to: newEnd },
          node => node.isTextblock
        );
        let textBlock: NodeWithPos | undefined;
        let textBeforeWhitespace: string | undefined;
        if (
          nodesInChangedRanges.length &&
          // We want to make sure to include the block seperator argument to treat hard breaks like spaces.
          transaction.doc.textBetween(newStart, newEnd, " ", " ").endsWith(" ")
        ) {
          // eslint-disable-next-line prefer-destructuring
          textBlock = nodesInChangedRanges[0];
          textBeforeWhitespace = transaction.doc.textBetween(
            textBlock.pos,
            newEnd,
            undefined,
            " "
          );
          if (textBeforeWhitespace === " ") {
            this.editor
              .chain()
              .deleteRange({
                from: transaction.selection.from - 1,
                to: transaction.selection.to
              })
              .command(({ tr, dispatch }) => {
                dispatch(tr.setMeta(this.name, "spaceTrigger"));
                return true;
              })
              .run();
          }
        }
      });
    });
  },
  onSelectionUpdate() {
    if (this.storage.openedPosition) {
      const { from, to } = this.editor.state.selection;
      const { from: fromOpen, to: toOpen } = this.storage.openedPosition;
      if (
        !(this.storage.openedPosition && fromOpen === from && toOpen === to)
      ) {
        this.storage.isCustomPromptActive = false;
      }
    } else {
      this.storage.isCustomPromptActive = false;
    }
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setAIPromptActive: value => () => {
        this.storage.isCustomPromptActive = value;
        return true;
      },
      setAiDiscovered: value => () => {
        localStorage.setItem(__AI_TE_DISCOVERED_KEY__, JSON.stringify(value));
        this.storage.discovered = value;
        return true;
      },
      setLoading: value => () => {
        this.storage.loading = value;
        return true;
      }
    };
  }
});

export default TipTapAi;
