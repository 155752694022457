import React, { FC, useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { Button, Input } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import * as S from "./TextEditorLinkModal.styles";
import { linkRegex } from "./TextEditorLinkModal.utils";

type TextEditorLinkModalProps = {
  defaultText?: string;
  defaultUrl?: string;
  closeModal: () => void;
  submitValues: ({
    linkText,
    linkUrl
  }: {
    linkText?: string;
    linkUrl: string;
  }) => void;
};

const TextEditorLinkModal: FC<TextEditorLinkModalProps> = ({
  defaultText = "",
  defaultUrl = "",
  closeModal,
  submitValues
}) => {
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const theme = isLayoutModeMobile ? greyTheme : whiteTheme;

  const [linkText, setLinkText] = useState(defaultText);
  const [linkUrl, setLinkUrl] = useState(defaultUrl);

  const handleSubmit = () => {
    if (!linkRegex.test(linkUrl)) return;
    submitValues({ linkText: linkText || linkUrl, linkUrl });
    closeModal();
  };

  return (
    <ModalLayout
      closeModal={closeModal}
      labels={{
        title: translate("text_editor_link_modal_title"),
        mobileClose: translate("text_editor_link_modal_close")
      }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false,
        onConfirm: () => handleSubmit(),
        confirmLabel: translate("text_editor_link_modal_confirm")
      }}
      desktopProps={{
        hasAdaptiveHeight: true
      }}
    >
      <S.TextEditorLinkModalWrapper>
        <Input
          value={linkText}
          theme={theme}
          label={translate("text_editor_link_modal_text_label")}
          name="linkText"
          placeholder={translate("text_editor_link_modal_text_placeholder")}
          rounded
          onChange={e => setLinkText(e?.target?.value)}
          hideLabel={false}
        />
        <S.Spacer />
        <Input
          value={linkUrl}
          theme={theme}
          label={translate("text_editor_link_modal_url_label")}
          name="linkUrl"
          placeholder={translate("text_editor_link_modal_url_placeholder")}
          rounded
          onChange={e => setLinkUrl(e?.target?.value)}
          hideLabel={false}
        />
        {isLayoutModeMobile ? null : (
          <S.FooterWrapper>
            <Button onClick={closeModal} theme={theme} variant="secondary">
              {translate("text_editor_link_modal_close")}
            </Button>
            <Button
              disabled={!linkRegex.test(linkUrl)}
              onClick={handleSubmit}
              theme={theme}
              variant="primary"
            >
              {translate("text_editor_link_modal_confirm")}
            </Button>
          </S.FooterWrapper>
        )}
      </S.TextEditorLinkModalWrapper>
    </ModalLayout>
  );
};

export default observer(TextEditorLinkModal);
