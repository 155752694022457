import React, { FC } from "react";

import { ActionButton } from "../ActionButton";

import * as S from "./FabButton.styles";
import { FabButtonProps } from "./FabButton.types";

export const FabButton: FC<FabButtonProps> = props => {
  return (
    <S.FabButtonWrap className={props?.className}>
      <ActionButton {...props} />
    </S.FabButtonWrap>
  );
};

export default FabButton;
