import styled from "styled-components";

import { ImageStyleProps } from "./types";

export const ImgWiew = styled.img<ImageStyleProps>`
  width: ${({ customWidth }) => {
    return customWidth ? customWidth : "100%";
  }};
  height: 100%;
  object-fit: cover;
`;
