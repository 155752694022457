import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const AccordionText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const HintsToggle = styled.div`
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 5px;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToggleLeftSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
