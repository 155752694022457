import { Heading, Text } from "@arcadia/design-system";
import styled from "styled-components";

export const AILoadingContainer = styled.div`
  width: 600px;
  height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 54px;
  justify-content: center;

  @media (max-width: 767px) {
    width: unset;
    height: unset;
  }
`;

export const LoaderWrapper = styled.div`
  div {
    width: 128px;
    height: 100px;
  }

  @media (max-width: 767px) {
    div {
      width: 80px;
      height: 80px;
      min-height: 220px;
    }
  }
  @media (max-height: 580px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-height: 120px;

  @media (max-width: 767px) {
    position: absolute;
    width: calc(100% - 32px);
    top: 300px;
  }
`;

export const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.primaryColor[600]};
`;

export const StyledMessage = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor[300]};
  max-width: 320px;
  text-align: center;
`;
