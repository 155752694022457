import styled, { keyframes } from "styled-components";

const scroll = keyframes`
  0% { 
    transform: translateX(0);
  }
  50% { 
    transform: translateX(calc(-100% + 100vw));  
  }
  100% { 
    transform: translateX(0%);  
  }
`;

export const ScrollableContainer = styled.div`
  display: none;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  gap: 0.5rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0px 0px 15px;
  ::-webkit-scrollbar {
    display: none;
  }
  & span {
    white-space: nowrap;
  }
`;

export const AutoScroll = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  animation: ${scroll} 2s ease;
  animation-delay: 2s;
`;

export const CarouselDropdown = styled.div`
  position: absolute;
  top: 65px;
  right: 15px;
  left: 15px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.basicWhite};
`;
