import styled from "styled-components";

export const SpaceCreationModal = styled.div`
  padding: 20px 20px 24px 20px;
  @media (max-width: 767px) {
    padding: 20px 16px 40px 16px;
  }
  @media (min-width: 768px) {
    // TODO: Improve fixed list options
    & #dropdown-multiple-list {
      max-height: 180px;
    }
  }
`;
