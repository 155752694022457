import React, { useState } from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { Box, Icon, Input } from "@arcadia/design-system";
import { checkPasswordStrength } from "src/js/modules/formFunction";
import { InputPasswordStrengthValidationProps } from "./types";
import { PasswordStrengthIndicator } from "../PasswordStrenghtIndicator";

const InputPasswordStrengthValidation = ({
  name,
  disabled = false,
  placeholder,
  register,
  validation,
  rounded,
  hasError,
  errorMessage
}: InputPasswordStrengthValidationProps) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const getPasswordStrength = (password: string) => {
    setPasswordStrength(checkPasswordStrength(password));
  };

  return (
    <>
      <Input
        name={name}
        disabled={disabled}
        placeholder={translate(placeholder)}
        ref={register(validation)}
        type="field"
        isPassword={!showPassword}
        label=""
        hideLabel
        theme={whiteTheme}
        rounded={rounded}
        feedback={hasError ? { message: errorMessage, type: "error" } : null}
        rightSection={
          <Box
            display="flex"
            cursor="pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              icon={showPassword ? "eyeClosed" : "eyeOpened"}
              height={20}
              width={20}
              initialViewbox
            />
          </Box>
        }
        onChange={event => getPasswordStrength(event.target.value)}
      />
      {passwordStrength !== 0 && (
        <PasswordStrengthIndicator strength={passwordStrength} />
      )}
    </>
  );
};

export default InputPasswordStrengthValidation;
