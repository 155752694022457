import colors from "@ds_themes/colors";

export const label = {
  closeColor: colors.grey[50],
  icon: colors.grey[1000],
  variant: {
    primary: {
      background: colors.violet[501],
      text: colors.grey[1000]
    },
    lightPrimary: {
      background: colors.violet[900],
      text: colors.violet[501]
    },
    lightViolet: {
      background: colors.violet[700],
      text: colors.grey[1000]
    },
    violet: {
      background: colors.violet[501],
      text: colors.grey[1000]
    },
    green: {
      background: colors.success[500],
      text: colors.grey[1000]
    },
    lightGreen: {
      background: colors.success[900],
      text: colors.success[400]
    },
    red: {
      background: colors.error[600],
      text: colors.grey[1000]
    },
    bluegrey: {
      background: colors.blueGrey[500],
      text: colors.grey[1000]
    },
    yellow: {
      background: colors.yellow[500],
      text: colors.grey[50]
    },
    orange: {
      background: colors.warning[500],
      text: colors.grey[1000]
    },
    lightOrange: {
      background: colors.orange[900],
      text: colors.orange[500]
    }
  }
};
