import React from "react";
import { Heading, Text } from "@arcadia/design-system";

import * as S from "./NoNotifications.styles";

const NoNotifications = ({
  title,
  subtitle,
  className
}: {
  title: string;
  subtitle: string;
  className?: string;
}) => {
  return (
    <S.NoNotificationsContainer className={className}>
      <S.NoNotificationsImage />
      <S.NoNotificationsTextContainer>
        <Heading level="5">{title}</Heading>
        <S.Subtitle>
          <Text type="formSubtitle">{subtitle}</Text>
        </S.Subtitle>
      </S.NoNotificationsTextContainer>
    </S.NoNotificationsContainer>
  );
};

export default NoNotifications;
