import { Box, Text, Toggle } from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import * as S from "../styles";

const BadgePrivacy = ({
  privacyActive,
  setPrivacyActive
}: {
  privacyActive: boolean;
  setPrivacyActive: (privacy: boolean) => void;
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    SpaceBadgesStore: { badgeOptionalFields, setBadgeOptionalFields }
  } = useStores();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={8}
      >
        <Text type="headerTitle">{translate("badge_field_privacy_title")}</Text>
        <Toggle
          theme={whiteTheme}
          title={translate("badge_field_privacy_title")}
          size="small"
          checked={privacyActive}
          onChange={() => {
            setPrivacyActive(!privacyActive);
            setBadgeOptionalFields({
              ...badgeOptionalFields,
              isPrivate: !privacyActive
            });
          }}
        />
      </Box>
      <S.DescriptionText type="formSubtitle">
        {translate("badge_field_privacy_description")}
      </S.DescriptionText>
    </Box>
  );
};

export default observer(BadgePrivacy);
