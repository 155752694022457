import { Box, Button, ContextualAlert, Input } from "@arcadia/design-system";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { AIInstructions } from "../components";

import * as S from "./SubjectAndMessageForm.styles";

const SubjectAndMessageForm = ({
  defaultSubject,
  defaultQuestion,
  onSubmit
}: {
  defaultSubject: string;
  defaultQuestion: string;
  onSubmit: (subject: string, message: string) => void;
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const [subject, setSubject] = useState(defaultSubject);
  const [question, setQuestion] = useState(defaultQuestion);

  const theme = isLayoutModeMobile ? greyTheme : whiteTheme;

  return (
    <S.Wrapper>
      <S.LeftWrapper>
        <AIInstructions />
      </S.LeftWrapper>
      <S.RightWrapper>
        <S.Description type="formFieldEmpty">
          {translate("ai_editor_subject_and_message_form_description")}
        </S.Description>
        <Box>
          <S.Label type="formSubtitle">
            {translate("ai_editor_subject_and_message_subject_label")}
          </S.Label>
          <Input
            theme={theme}
            label={translate("ai_editor_subject_and_message_subject_label")}
            rounded
            hideLabel
            type="field"
            placeholder={translate(
              "ai_editor_subject_and_message_subject_placeholder"
            )}
            onChange={e => {
              setSubject(e.target.value);
            }}
            value={subject}
          />
        </Box>
        <Box>
          <S.Label type="formSubtitle">
            {translate("ai_editor_subject_and_message_question_label")}
          </S.Label>
          <Input
            theme={theme}
            label={translate("ai_editor_subject_and_message_question_label")}
            rows={9}
            maxHeight="176px"
            placeholder={translate(
              "ai_editor_subject_and_message_question_placeholder"
            )}
            hideLabel
            type="textarea"
            onChange={e => {
              setQuestion(e.target.value);
            }}
            value={question}
          />
        </Box>
        <ContextualAlert
          text={translate("ai_editor_subject_and_message_sensible_data_alert")}
          theme={theme}
        />
        <Button
          theme={theme}
          type="button"
          variant="primary"
          disabled={!subject || !question}
          onClick={() => {
            onSubmit(subject, question);
          }}
        >
          {translate("ai_editor_subject_and_message_submit_cta")}
        </Button>
      </S.RightWrapper>
    </S.Wrapper>
  );
};

export default SubjectAndMessageForm;
