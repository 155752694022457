import styled, { css } from "styled-components";

import { SCheckIndicatorProps } from "./types";

export const CheckIndicatorContainer = styled.div<SCheckIndicatorProps>`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: ${({ theme }) => theme.checkindicator.border};
  background-color: ${({ theme }) => theme.checkindicator.background};
  font-size: 10px;
  text-align: center;
  line-height: 23px;
  color: ${({ theme }) => theme.checkindicator.color};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: ${({ theme }) => theme.checkindicator.selected.border};
      background: ${({ theme }) => theme.checkindicator.selected.background};
      color: ${({ theme }) => theme.checkindicator.selected.color};
    `}
`;
