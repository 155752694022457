import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { SpaceCreationForm } from "src/js/pages/spaces/space_creation/SpaceCreationForm";
import {
  EventDomain,
  SpaceCreationEvent,
  SpaceListModel,
  SpaceModel,
  SpacePurpose,
  SpaceUserRoleEnum
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { SpaceFreeTrialModal } from "../../SpaceFreeTrialModal";
import { ModalLayout } from "../../modal/ModalLayout";
import { SchoolClaimModal } from "../SchoolClaimModal";

import * as S from "./SpaceCreationModal.styles";

const SpaceCreationModal = () => {
  const { trackVirtualView, trackView } = useViewTracking();
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { spacesList, addNewSpaceToSpaceList },
    ModalStore: { openModal, closeModal, setCustomCloseCb, customClose }
  } = useStores();

  const userHasNoSpaces = spacesList.length > 0;

  useEffect(() => {
    // Set a custom close function to track the user left this virtual view
    setCustomCloseCb(() => {
      trackView();
      closeModal();
    });
    return () => {
      setCustomCloseCb(null);
    };
  }, []);

  useEffect(() => {
    trackVirtualView(
      userHasNoSpaces
        ? WeSchoolVirtualViewDescriptions.Spaces.Create
            .InitialSpaceCreationModal
        : WeSchoolVirtualViewDescriptions.Spaces.Create.SpaceCreationModal
    );
  }, []);

  useEffect(() => {
    _trackEvent(
      EventDomain.SpaceCreation,
      SpaceCreationEvent.InAppSpaceCreationAccessPoint
    );
  }, []);

  const onSuccessCallback = (newSpace: SpaceModel) => {
    const spaceListModel: SpaceListModel = {
      space: newSpace,
      role: SpaceUserRoleEnum.Owner,
      unreadNotificationCount: 0,
      unreadThreadMessagesCount: 0
    };
    addNewSpaceToSpaceList(spaceListModel);
    closeModal();
    navigateTo(createUrl("space_groups", { space_slug: newSpace.slug }));
    setTimeout(() => {
      openModal(() => <SpaceFreeTrialModal closeModal={closeModal} />);
    }, 2000);
  };

  const openSchoolClaimModal = () => {
    openModal(() => <SchoolClaimModal />);
  };

  const title = translate("company_space_creation_title");

  return (
    <ModalLayout
      labels={{
        title,
        mobileClose: translate("close")
      }}
      closeModal={customClose}
    >
      <S.SpaceCreationModal>
        <SpaceCreationForm
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          onSuccessCallback={newSpace => {
            onSuccessCallback(newSpace);
            _trackEvent(EventDomain.SpaceCreation, SpaceCreationEvent.Creation);
          }}
          purpose={SpacePurpose.Company}
          goToClaim={openSchoolClaimModal}
        />
      </S.SpaceCreationModal>
    </ModalLayout>
  );
};

export default observer(SpaceCreationModal);
