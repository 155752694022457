import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { __ } from "../../../modules/localization";
import UserModel from "../../../models/UserModel";
import { convertSecondsToFormattedString } from "../../../modules/utility";
import { getActiveGroup, getActiveGroupId } from "../../../modules/activeGroup";
import { instants } from "../../../modules/instant";
import createUrl from "src/js/modules/routing";
import { Avatar, ContextualAlert } from "@arcadia/design-system";
import { Button } from "@arcadia/design-system";
import { Heading } from "@arcadia/design-system";
import { Text } from "@arcadia/design-system";
import { Icon } from "@arcadia/design-system";
import { Col, Row } from "react-awesome-styled-grid";
import { withTheme } from "styled-components";
import { withSpaceStore } from "src/js/hooks/useStores";

const ExerciseEngineSplash = withSpaceStore(
  withTheme(
    withBackbone(
      createReactClass({
        goBack() {
          let route = "exercises";

          if (!this.props.exercise.isPublished()) {
            route = "exercises_drafts";
          }

          instants.resetActiveInstantId();
          window.location.href = `#${createUrl(route, { group_id: getActiveGroupId() })}`;
        },
        render() {
          const group = getActiveGroup();
          const self = this;
          const { theme, SpaceStore } = self.props;
          const { whiteTheme } = theme;
          const { hasDecimalGrading } = SpaceStore || {};
          const exercise = self.props.exercise;
          let readyText = null;
          let infoNoResult = null;
          const { weschoolTheme } = this.props.theme;
          const creator = new UserModel(exercise.get("created_by"));
          const timeThreshold = Math.round(exercise.getTimeThreshold() / 60);
          const timeThresholdCopy =
            timeThreshold > 0
              ? convertSecondsToFormattedString(timeThreshold * 60)
              : "--";
          const successThreshold =
            self.props.exercise.get("success_threshold") || 60;
          const successThresholdCopy = hasDecimalGrading
            ? `${Math.round(successThreshold / 10)}/10 `
            : `${successThreshold}% `;

          let startButton = (
            <Button
              onClick={this.props.onClick}
              variant="primary"
              theme={weschoolTheme.white}
            >
              {__("start")}
            </Button>
          );

          if (self.props.exercise.get("type") == "ASSIGNMENT") {
            readyText = (
              <div className="exercise-splash-ready">{__("sei pronto?")}</div>
            );
          }
          if (self.props.exercise.get("type") == "EXAMINATION") {
            if (!self.props.exercise.attributes.show_results) {
              infoNoResult = (
                <ContextualAlert
                  theme={self.props.theme.whiteTheme}
                  text={__("exercise_result_will_not_being_shown_after_test")}
                />
              );
            }
          }

          if (group.shouldDisableExerciseExecution() == true) {
            startButton = "";
            readyText = (
              <div className="exercise-splash-ready">
                {__("disabled_exercise")}
              </div>
            );
            if (self.props.exercise.get("type") == "EXAMINATION") {
              readyText = (
                <div className="exercise-splash-ready">
                  {__("disabled_test")}
                </div>
              );
            }
          }
          let backCopy = __("torna alla lista");
          if (exercise.getQuizzes().length > 0) {
            backCopy = __("back");
            return (
              <div className="exen__splash-wrapper">
                <div className="exen__splash-box">
                  <div className="app__box exercise-splash-box">
                    <Heading level="5">{exercise.get("title")}</Heading>
                    <div className="flex flex-centered-row margin-top-15">
                      <Avatar
                        url={creator.getAvatar("medium")}
                        gender={creator.getGenderString()}
                        alt=""
                        size="s"
                        theme={weschoolTheme.white}
                        rounded
                      />
                      <div className="margin-left-10">
                        <Text type="formSmallCaption">
                          {__("created_by")} {creator.getFullName()}
                        </Text>
                      </div>
                    </div>
                    <div className="exercise-splash-info-wrapper">
                      <Row align="center">
                        <Col xs={12} sm={12} md={4} lg={4}>
                          <div className="flex">
                            <div className="exercise-splash-icon">
                              <Icon icon="cards"></Icon>
                            </div>
                            <div className="exercise-splash-info">
                              <Text type="label">{__("quiz_count_label")}</Text>
                              <Text type="subHeaderTitle">
                                {exercise.getQuizzes().length}
                              </Text>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                          <div className="flex margin-top-xs-15">
                            <div className="exercise-splash-icon">
                              <Icon icon="clock"></Icon>
                            </div>
                            <div className="exercise-splash-info">
                              <Text type="label">{__("TEMPO MASSIMO")}</Text>
                              <Text type="subHeaderTitle">
                                {timeThresholdCopy}
                              </Text>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                          <div className="flex margin-top-xs-15">
                            <div className="exercise-splash-icon">
                              <Icon icon="speedometer"></Icon>
                            </div>
                            <div className="exercise-splash-info">
                              <Text type="label">
                                {__("exercise_splash_threshold")}
                              </Text>
                              <Text type="subHeaderTitle">
                                {successThresholdCopy}
                                {__("exercise_splash_correct")}
                              </Text>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {infoNoResult}
                    {readyText}
                    <div className="exercise-splash-box-footer">
                      <Button
                        onClick={this.goBack}
                        variant="secondary"
                        theme={weschoolTheme.white}
                      >
                        {backCopy}
                      </Button>
                      {startButton}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div className="exen__splash-wrapper">
              <div className="exen__splash-box">
                <div className="app__box exercise-splash-box">
                  <div className="app__subtitle">{exercise.get("title")}</div>
                  <p>
                    <small>{exercise.get("description")}</small>
                  </p>
                  <ContextualAlert
                    theme={whiteTheme}
                    text={__("not_existant_quiz")}
                  />
                  <div className="exercise-splash-box-footer">
                    <a onClick={this.goBack} title={backCopy}>
                      {backCopy}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })
    )
  )
);

export default ExerciseEngineSplash;
