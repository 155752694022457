/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { AdminOverquotaBlocked } from "src/js/components/AdminOverquotaBlocked";
import { createUrl } from "src/js/modules/routing";
import { NotificationsPage } from "src/js/pages/App/Spaces/NotificationsPage";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import Groups from "src/js/pages/spaces/groups/Groups";
import RedirectToLandingUrlOrDefault from "src/js/routing/RedirectToLandingUrlOrDefault";
import { SessionPing } from "src/js/tracking/SessionPing";
import { SpaceGroupsParams, SpaceNotificationParams } from "src/js/types";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";
import { ChatPage } from "./ChatPage";
import { SpacesChatRouter } from "./SpacesChat/SpacesChatRouter";
import { SpacesGroupRouter } from "./SpacesGroup/SpacesGroupRouter";
import { useSetupSpace } from "./hooks";

export const SpacesRouter = observer(() => {
  useSetupSpace();
  const {
    SpaceStore: { activeSpace }
  } = useStores();

  return (
    <>
      {activeSpace?.enableAnalytics ? <SessionPing /> : null}
      <Switch>
        <Route
          path={WeSchoolRoutes.App.Spaces.spaceGroups}
          key="spaceGroups"
          exact
          render={(props: RouteComponentProps<SpaceGroupsParams>) => (
            <Groups key={props.match.params.space_slug} />
          )}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.spaceGroupsOverquotaBlocked}
          exact
          component={(props: RouteComponentProps<SpaceGroupsParams>) => (
            <AdminOverquotaBlocked key={props.match.params.space_slug} />
          )}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.spaceChats}
          key="spaceChats"
          exact
          component={ChatPage}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.spaceNotifications}
          key="spaceNotifications"
          exact
          render={(props: RouteComponentProps<SpaceNotificationParams>) => (
            <NotificationsPage key={props.match.params.space_slug} />
          )}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceGroup.initialRouteName}
          component={SpacesGroupRouter}
        />
        <Route
          path={WeSchoolRoutes.App.Spaces.SpaceChat.initialRouteName}
          component={SpacesChatRouter}
        />
        <Route
          render={props => (
            <RedirectToLandingUrlOrDefault {...props} to={createUrl("home")} />
          )}
        />
      </Switch>
    </>
  );
});
