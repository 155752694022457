import styled, { css } from "styled-components";
import { Tag } from "@ds_universal/data_display/Tag/Tag.styles";
import colors from "@ds_themes/colors";

import {
  Container,
  SearchBar,
  Wrapper as SearchbarWrapper,
  EndAdornmentWrapper,
  StartAdornmentWrapper
} from "../SearchBar/SearchBar.styles";

export const Wrapper = styled.div<{
  expanded: boolean;
  hasActiveLeftFilter: boolean;
  width: string | number;
  expandedWidth: string | number;
}>`
  width: 100%;
  position: relative;
  display: flex;

  ${SearchBar} {
    padding-left: 16px;
  }

  ${Tag} {
    width: 128px;
    display: flex;
    white-space: nowrap;
    text-align: left;

    > span {
      color: ${colors.grey[1000]};
      margin: 0px auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > svg {
      color: ${colors.grey[1000]};
      flex: 0 0 8px;
    }
  }

  > ${Container} {
    width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
    display: flex;
    margin: 0px auto;
    transition: all 0.1s ease-in-out;

    ${({ hasActiveLeftFilter, expanded }) =>
      hasActiveLeftFilter &&
      css`
        ${StartAdornmentWrapper} {
          left: 4px;
        }

        ${SearchBar} {
          padding-left: ${expanded ? "176px" : "140px"}!important;
        }
      `}

    ${({ expanded, expandedWidth }) =>
      expanded &&
      css`
        width: ${typeof expandedWidth === "number"
          ? `${expandedWidth}px`
          : expandedWidth};

        ${EndAdornmentWrapper} {
          right: 8px;
        }

        ${StartAdornmentWrapper} {
          left: 16px;
        }

        ${SearchBar} {
          border-radius: 16px 16px 0px 0px;
          height: 42px;
          padding-left: 40px;
        }

        > ${SearchbarWrapper} {
          &:focus-within::before {
            border-radius: 16px 16px 0px 0px;
          }
        }
      `}
  }
`;

export const DropdownWrapper = styled.div<{ width: string | number }>`
  position: absolute;
  box-sizing: border-box;
  top: 43px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: ${({ width }) => (typeof width === "number" ? `${width}px ` : width)};
  padding: 8px;
  display: flex;
  background: ${colors.grey[1000]};
  border-radius: 0px 0px 16px 16px;
  outline: ${({ theme }) => theme.searchbar.focus.outline};
`;

export const BadgeWrapper = styled.div`
  width: 24px;
  height: 24px;

  img,
  div {
    width: 24px;
    height: 24px;
  }
`;
