import { Palette, SidebarGroupItem } from "@ds_themes/types";

export const createSidebarGroupItemTheme = ({
  primary,
  secondary,
  grey,
  coldGrey
}: Palette): { sidebargroupitem: SidebarGroupItem } => {
  return {
    sidebargroupitem: {
      background: grey[1000],
      color: grey[100],
      hover: {
        background: coldGrey[900]
      },
      notificationstat: {
        color: grey["500"],
        selected: secondary["main"]
      },
      notification: {
        background: primary["main"],
        color: primary["contrastText"],
        selected: secondary["main"],
        selectedColor: secondary["contrastText"]
      },
      selected: {
        background: primary["main"],
        color: primary["contrastText"]
      },
      loading: {
        color: primary["main"],
        selectedColor: grey[1000]
      }
    }
  };
};
