import {
  Box,
  Icon,
  SpaceBaseCard,
  SpaceBaseCardSizeType,
  Text
} from "@arcadia/design-system";
import React from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import * as S from "./SpaceSchoolCard.styles";
import {
  SpaceSchoolCardProps,
  SpaceSchoolCardVariant
} from "./SpaceSchoolCard.types";

const SpaceSchoolCard = ({
  variant,
  spaceName,
  color,
  url,
  onClick,
  isSelected,
  address,
  className
}: SpaceSchoolCardProps) => {
  const { translate } = useTranslation();

  const centralContent = () => {
    if (variant === SpaceSchoolCardVariant.Unique) {
      return (
        <Text type="formField">{translate("space_school_card_create")}</Text>
      );
    }

    if (variant === SpaceSchoolCardVariant.Claimed) {
      return (
        <S.CentralContentContainer>
          <S.ClaimedTitle>
            <S.ContentText type="formField">{spaceName}</S.ContentText>
            <S.ClaimedIcon>
              <Icon icon="checkBadge" height={18} width={18} />
            </S.ClaimedIcon>
          </S.ClaimedTitle>
          <Box margin="4px 0 2px">
            <S.AddressText type="formSmallCaption">{address}</S.AddressText>
          </Box>
          <S.ErrorText type="formSmallCaption">
            {translate("space_school_card_claimed_label")}
          </S.ErrorText>
        </S.CentralContentContainer>
      );
    }

    return (
      <S.CentralContentContainer>
        <S.ContentText type="formField">{spaceName}</S.ContentText>
        <Box marginTop="6px">
          <S.AddressText type="formSmallCaption">{address}</S.AddressText>
        </Box>
      </S.CentralContentContainer>
    );
  };

  const rightContent = (
    <S.IconContainer>
      {variant === SpaceSchoolCardVariant.Selected ? (
        <Text type="formField">{translate("space_school_card_free")}</Text>
      ) : (
        <Icon
          icon={
            variant === SpaceSchoolCardVariant.Claimed
              ? "lockClosed"
              : "chevronRight"
          }
          height={20}
          width={20}
        />
      )}
    </S.IconContainer>
  );

  return (
    <SpaceBaseCard
      className={className}
      spaceAvatar={{
        url,
        name: spaceName,
        avatarColor: color
      }}
      sizeVariant={SpaceBaseCardSizeType.Big}
      hasBorder
      onClick={onClick}
      isTransparent={variant === SpaceSchoolCardVariant.Claimed}
      isSelected={isSelected || variant === SpaceSchoolCardVariant.Selected}
      centralContent={centralContent()}
      rightContent={rightContent}
    />
  );
};

export default SpaceSchoolCard;
