import { Input } from "@arcadia/design-system";
import React, { FC } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { readLocalData } from "src/js/modules/storageFunction";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import { __GOOGLE_API_KEY__ } from "src/js/settings/settingsGeneral";
import * as S from "./InputAddress.styles";
import { InputAddressProps } from "./InputAddress.types";

const InputAddress: FC<InputAddressProps> = ({
  onPlaceSelected,
  country,
  ...inputProps
}) => {
  const { ref } = usePlacesWidget({
    apiKey: __GOOGLE_API_KEY__,
    onPlaceSelected: obj => onPlaceSelected(obj),
    language: readLocalData(__COOKIE_LANG__),
    inputAutocompleteValue: "off",
    options: {
      types: ["address"],
      componentRestrictions: country ? { country } : null
    }
  });

  return (
    <>
      <S.GlobalStyleGoogleDialog />
      <Input ref={ref} {...inputProps} />
    </>
  );
};

export default InputAddress;
