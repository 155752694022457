import colors from "@ds_themes/colors";

export const input = {
  background: colors.grey[1000],
  text: colors.grey[50],
  textPlaceholder: colors.blueGrey[300],
  label: colors.grey[300],
  border: `1.5px solid ${colors.grey[1000]}`,
  hover: {
    background: colors.grey[1000],
    border: `1.5px solid ${colors.grey[800]}`
  },
  focus: {
    background: colors.grey[1000],
    outline: `2px solid ${colors.violet[900]}`,
    border: `1.5px solid ${colors.violet[700]}`
  },
  required: {
    background: colors.yellow[500],
    text: colors.violet[501],
    border: `${colors.yellow[500]} solid 1.5px`
  },
  disabled: {
    background: colors.grey[1000],
    text: colors.blueGrey[800]
  },
  error: {
    text: colors.error[600],
    border: `1.5px solid ${colors.error[600]}`
  },
  success: {
    text: colors.success[500],
    border: `1.5px solid ${colors.success[500]}`
  },
  font: {
    style: "normal",
    weight: "400",
    size: "16px",
    lineHeight: "19px"
  }
};
