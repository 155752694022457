import colors from "@ds_themes/colors";
import { DropdownThemeOptions } from "@ds_themes/types";

export const dropdown: DropdownThemeOptions = {
  backgroundButton: colors.grey[1000],
  backgroundContainer: colors.grey[1000],
  filterIconBackground: colors.grey[1000],
  filterIconActiveColor: colors.violet[501],
  filterIconInactiveColor: colors.grey[50],
  text: colors.grey[50],
  border: `1px solid ${colors.grey[1000]}`,
  option: {
    background: colors.grey[1000],
    hover: {
      text: colors.grey[50]
    }
  },
  hover: {
    background: colors.grey[1000],
    border: `1px solid ${colors.grey[800]}`
  },
  focus: {
    border: `1px solid ${colors.violet[501]}`,
    outline: `${colors.violet[900]} solid 3px`,
    backgroundOptions: colors.coldGrey[900],
    backgroundButton: colors.grey[1000]
  },
  disabled: {
    background: colors.grey[1000],
    color: colors.blueGrey[700]
  }
};
