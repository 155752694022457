import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import renderLatex from "src/js/modules/mathjaxFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import { DoodleLoader, Label } from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { ExerciseQuizTitle } from "src/js/components/exercises/ExerciseQuizTitle";
import { __ } from "../../../modules/localization";
import QuizTrueFalse from "./exerciseEngine_quiz_truefalse";
import QuizShortAnswer from "./exerciseEngine_quiz_shortanswer";
import QuizOpenAnswer from "./exerciseEngine_quiz_openanswer";
import QuizMultiChoice from "./exerciseEngine_quiz_multichoice";
import QuizCrosswords from "./exerciseEngine_quiz_crosswords";
import QuizVideo from "./exerciseEngine_quiz_video";
import QuizFillblank from "./exerciseEngine_quiz_fillblanks";
import QuizMatching from "./exerciseEngine_quiz_matching";
import { FadingMatches } from "./FadingMatches";
import QuizDocumentAnswer from "./exerciseEngine_quiz_documentanswer";

const ExerciseEngineQuiz = withTheme(
  withBackbone(
    createReactClass({
      getInitialState() {
        return { status: this.props.status };
      },
      getDefaultProps() {
        return {
          quiz: {},
          exercise: {},
          setCurrentInnerQuiz() {} // used by video quiz to set active inner quiz in exercise engine
        };
      },
      componentDidMount() {
        renderLatex();
      },
      componentDidUpdate() {
        /** Force opening of links in title in new window */
        if ($(".exen__quiz-header-title-inner").length > 0) {
          $(".exen__quiz-header-title-inner a").attr("target", "_blank");
        }
      },
      render() {
        const { quiz } = this.props;
        let quizContent = "";
        const quizTitle = quiz.getTitle();
        const { whiteTheme, greyTheme } = this.props.theme;

        switch (quiz.getType()) {
          case "quiztruefalse":
            quizContent = <QuizTrueFalse quiz={quiz} />;
            break;

          case "quizshortanswer":
            quizContent = <QuizShortAnswer quiz={quiz} />;
            break;

          case "quizopenanswer":
            quizContent = <QuizOpenAnswer quiz={quiz} />;
            break;

          case "quizmultichoice":
            quizContent = <QuizMultiChoice quiz={quiz} />;
            break;

          case "quizcrosswords":
            quizContent = <QuizCrosswords quiz={quiz} />;
            break;

          case "quizvideo":
            quizContent = (
              <QuizVideo
                quiz={quiz}
                exercise={this.props.exercise}
                setCurrentInnerQuiz={this.props.setCurrentInnerQuiz}
              />
            );
            break;

          case "quizfillblank":
            quizContent = <QuizFillblank quiz={quiz} />;
            break;

          case "quizmatching":
            quizContent = <QuizMatching quiz={quiz} />;
            break;

          case "quizfadingmatching":
            quizContent = <FadingMatches quiz={quiz} />;
            break;

          case "quizdocumentanswer":
            quizContent = <QuizDocumentAnswer quiz={quiz} />;
            break;

          default:
            quizContent = (
              <p className="alert alert-danger">
                {__("exercise_unknown_type_error")}
              </p>
            );
            break;
        }

        if (this.state.status === "loading") {
          return <DoodleLoader theme={greyTheme} />;
        }
        return (
          <div
            className={["exen__quiz-wrapper", `exen__${quiz.getType()}`].join(
              " "
            )}
          >
            <div className="exen__quiz-header">
              {quizTitle.length > 0 ? (
                <div className="exen__quiz-header-title">
                  <div className="exen__quiz-header-title-inner">
                    <Label theme={whiteTheme} color="yellow" outline={false}>
                      <strong>{quiz.getAvailablePoints()}</strong>&nbsp;
                      <Translate
                        text={
                          quiz.getAvailablePoints() > 1
                            ? "quiz_execution_points_label"
                            : "quiz_execution_point_label"
                        }
                      />
                    </Label>
                    <ExerciseQuizTitle quizTitle={quiz.get("title")} />
                  </div>
                </div>
              ) : null}
            </div>
            {quizContent}
          </div>
        );
      }
    })
  )
);

export default ExerciseEngineQuiz;
