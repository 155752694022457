import React from "react";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { __WESCHOOL_LOGO__ } from "src/js/settings/settingsImage";
import { __SUPPORT_MAIL__ } from "src/js/settings/settingsGeneral";
import styled from "styled-components";

export const ErrorPage = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.violet[501]};
  color: ${({ theme }) => theme.colors.violet[1000]};
`;

export const ErrorPageContainer = styled.div`
  width: calc(100% - 30px);
  max-width: 580px;
  margin: 0 auto;
  position: fixed;
  text-align: center;
`;

const ErrorBoundaryView = ({ error, info }) => {
  return (
    <ErrorPage>
      <ErrorPageContainer>
        <img
          alt="Weschool logo"
          src={__WESCHOOL_LOGO__}
          className="login-logo"
        />
        <h1 className="app__title white"> {error}</h1>
        <h2 className="app__subtitle white">
          <Translate text="general_error_message" />
        </h2>
        <br />
        <div className="white-text">
          <Translator>
            {({ translate }) => {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: translate("support_error_page_label", {
                      support_mail: __SUPPORT_MAIL__,
                      error,
                      info
                    })
                  }}
                />
              );
            }}
          </Translator>
        </div>
        <p className="margin-top-20">
          <a href="/" className="btn btn-option">
            Back to Home
          </a>
        </p>
      </ErrorPageContainer>
    </ErrorPage>
  );
};

export default ErrorBoundaryView;
