import { Box, Icon, Text } from "@arcadia/design-system";
import React, { FC, useEffect, useState } from "react";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useTimeout } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import {
  ChargebeeCallbacksType,
  chargebeeClosePortal
} from "../../ChargebeeUtils";
import { MAX_WIDTH_CHARGEBEE_FRAME } from "./ChargebeeModal.conts";
import * as S from "./ChargebeeModal.styles";
import { ChargebeeModalProps } from "./ChargebeeModal.types";

const ChargebeeModal: FC<ChargebeeModalProps> = ({
  callbackChargebeeWidget,
  close
}) => {
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorStatus, setErrorStatus] = useState<string>(null);

  const hideLoader = useTimeout(() => setIsLoading(false), 500);
  const setError = error => {
    hideLoader.start();
    setErrorStatus(error);
  };

  const moveFrameInsideContainer = () => {
    const callbacks: ChargebeeCallbacksType = {
      loaded() {
        hideLoader.start();
      },
      error(error) {
        setError(error);
      }
    };
    try {
      const layout = document.getElementById("cb-frame");
      const container = document.getElementById("cb-modal-container");
      container.prepend(layout);
      callbackChargebeeWidget(callbacks);
    } catch (error) {
      setError(error);
    }
  };

  const restoreFrameBeforeClose = () => {
    try {
      const containerFrame = document.getElementById("cb-container");
      const layout = document.getElementById("cb-frame");
      containerFrame.prepend(layout);
      chargebeeClosePortal();
    } catch (error) {
      setErrorStatus(error);
    }
    close();
  };

  useEffect(() => {
    moveFrameInsideContainer();
    return () => {
      restoreFrameBeforeClose();
    };
  }, []);

  const frameIsHidden = isLoading || errorStatus;

  const frameContainer = (
    <>
      {errorStatus ? (
        <S.EmptyStateWrapper>
          <S.IllustrationContainer>
            <Icon
              icon="illustration-ghost"
              height={130}
              width={98}
              initialViewbox
            />
          </S.IllustrationContainer>
          <Text>{translate("chargebee_modal_text_error")}</Text>
        </S.EmptyStateWrapper>
      ) : null}
      {isLoading ? <S.StyledDoodleLoader theme={greyTheme} /> : null}
      <Box
        display={frameIsHidden ? "none" : "initial"}
        height="100%"
        id="cb-modal-container"
      />
    </>
  );

  return (
    <ModalLayout
      labels={{
        title: translate("chargebee_modal_title"),
        mobileTitle: translate("chargebee_modal_mobile_title"),
        mobileClose: translate("chargebee_modal_mobile_cta_close")
      }}
      closeModal={restoreFrameBeforeClose}
      forceMobileLayout={window.innerWidth <= MAX_WIDTH_CHARGEBEE_FRAME}
      desktopProps={{
        hasAdaptiveHeight: false,
        hasAdaptiveWidth: true
      }}
    >
      <S.ModalInner>{frameContainer}</S.ModalInner>
    </ModalLayout>
  );
};

export default ChargebeeModal;
