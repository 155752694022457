import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import ICU from "i18next-icu";
import { __LANG_JSON_URL__ } from "src/js/settings/settingsUrl";
import { initReactI18next } from "react-i18next";

const backendOptions = {
  type: "backend",
  allowMultiLoading: true,
  loadPath: `${__LANG_JSON_URL__}{{lng}}.json`
};

i18next
  .use(ICU)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    whitelist: ["en-US", "it-IT", "es-ES", "fr-FR"],
    detection: {
      order: ["path", "cookie", "localStorage", "sessionStorage"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      caches: ["localStorage", "cookie"]
    },
    keySeparator: true,
    // nsSeparator = false permits the usage of ":" in translations string
    nsSeparator: false,
    // set to true manually if you are working on translations
    debug: false,
    react: {
      useSuspense: false,
      bindI18n: "languageChanged"
    }
  });

export default i18next;
