import { Checkbox, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { BadgeRelatedUserType } from "src/js/types";
import { useTheme } from "styled-components";
import SpaceAwardedBadgeImage from "src/js/pages/spaces/dashboard/SpacesBadges/components/SpaceAwardedBadgeImage";
import * as S from "../styles";

const BadgeRelatedUser = ({
  badgeRelatedUser,
  isEmitted = false
}: {
  badgeRelatedUser: BadgeRelatedUserType;
  isEmitted?: boolean;
}) => {
  const { greyTheme } = useTheme();
  const { avatar, name, surname, awardedWeschool, walletAddress } =
    badgeRelatedUser;
  const hasWalletConnected = !!walletAddress;
  const fullName = `${name} ${surname}`;
  const {
    UIStore: { isLayoutModeMobile },
    SpaceBadgesStore: {
      updateUsersToEmitBadge,
      removeUserFromEmitWithBadge,
      usersToEmitBadge,
      badgeToEdit,
      badgeAssertions,
      transactionFailed,
      transactionChainId
    }
  } = useStores();
  const { translate } = useTranslation();
  const { image } = badgeToEdit || {};

  const isActive = usersToEmitBadge.some(
    user => user.id === badgeRelatedUser.id
  );

  const handleBadgeEmittedImageColumn = () => {
    const awardedUser = badgeAssertions.find(
      assertion => assertion.userId === badgeRelatedUser.id
    );
    const hasAwardedNFT = awardedUser?.isNft && !transactionFailed;

    if (isEmitted) {
      return (
        <S.NFTIconContainer>
          <SpaceAwardedBadgeImage
            chainId={transactionChainId}
            image={image}
            userAwardedNFTBadge={hasAwardedNFT}
          />
        </S.NFTIconContainer>
      );
    }

    return (
      <Checkbox
        theme={greyTheme}
        checked={isActive}
        onChange={value => {
          if (value) {
            updateUsersToEmitBadge(badgeRelatedUser);
          } else {
            removeUserFromEmitWithBadge(badgeRelatedUser.id);
          }
        }}
      />
    );
  };

  const handleBadgeColumn = () => {
    const hasAwardedNFT = !!badgeRelatedUser.nftReference;

    if (isEmitted) {
      return null;
    }

    if (hasAwardedNFT) {
      return (
        <S.NFTIconContainer>
          <SpaceAwardedBadgeImage
            chainId={badgeRelatedUser.chainId}
            image={image}
            userAwardedNFTBadge={hasAwardedNFT}
          />
        </S.NFTIconContainer>
      );
    }
    if (awardedWeschool) {
      return <S.BadgeIcon src={image} alt="badge-icon" />;
    }
    return (
      <S.BadgePlaceholder>
        <S.BadgePlaceholderIcon
          icon="shieldCrossed"
          width={20}
          height={20}
          initialViewbox
        />
      </S.BadgePlaceholder>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleWalletColumn = () => {
    if (isEmitted) {
      return null;
    }

    return isLayoutModeMobile ? (
      <S.WalletStatusIconMobileContainer isConnected={hasWalletConnected}>
        <Icon icon="link" />
      </S.WalletStatusIconMobileContainer>
    ) : (
      <S.WalletStatus isConnected={hasWalletConnected}>
        <Text type="label">
          {translate(
            hasWalletConnected
              ? "emit_badge_students_wallet_status_connected"
              : "emit_badge_students_wallet_status_not_connected"
          )}
        </Text>
      </S.WalletStatus>
    );
  };

  return (
    <S.BadgeRelatedUser withHover={!isEmitted} isActive={isActive}>
      <S.UserAvatarAndName>
        <S.UserAvatar
          theme={greyTheme}
          url={avatar?.small}
          alt="user-avatar"
          size="s"
        />
        <S.UserName type="headerTitle">{fullName}</S.UserName>
      </S.UserAvatarAndName>
      {handleBadgeColumn()}
      {handleWalletColumn()}
      {handleBadgeEmittedImageColumn()}
    </S.BadgeRelatedUser>
  );
};

export default observer(BadgeRelatedUser);
