import { Button, DoodleLoader, FabButton } from "@arcadia/design-system";
import styled from "styled-components";

export const StyledDoodleLoader = styled(DoodleLoader)`
  & > div {
    width: 100%;
  }
`;

export const PageContainer = styled.div`
  padding: 60px 0 120px;
  display: flex;
  flex-flow: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px 0 120px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const CreateButton = styled(Button)`
  flex-direction: row-reverse;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const StyledFabButton = styled(FabButton)`
  z-index: 998;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const SearchBarWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const BadgeList = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;
