import { Box, BoxProps, skeletonAnimationMixin } from "@arcadia/design-system";
import React, { ReactNode } from "react";
import styled from "styled-components";

const WrapSkeletonBox = styled(Box)`
  ${({ theme }) =>
    skeletonAnimationMixin(
      theme.colors.coldGrey[900],
      theme.primaryColor[900]
    )};
  * {
    visibility: hidden !important;
    border: none !important;
  }
  svg * {
    visibility: visible !important;
    display: none;
  }
`;

type SkeletonProps = { loading?: boolean; children?: ReactNode } & BoxProps;

const Skeleton = ({ loading, children, ...props }: SkeletonProps) => {
  if (loading) {
    return <WrapSkeletonBox {...props}>{children}</WrapSkeletonBox>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
export default Skeleton;
