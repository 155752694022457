import styled from "styled-components";

export const TopFiltersContainer = styled.div`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

export const SearchbarWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const RowWithMargin = styled.div`
  margin-bottom: 16px;
`;

export const FlexWrapper = styled.div`
  flex: 1;
  padding: 0 0.4375rem;
`;

export const FixedWrapper = styled.div`
  padding: 0 0.4375rem;
  max-width: 228px;
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    > div {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const RightAlignWrapper = styled.div`
  text-align: right;
  @media (max-width: 767px) {
    width: 100%;
    > div {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const SearchButtonWrapper = styled.div`
  padding: 0 0.4375rem;
`;

export const SearchForm = styled.form`
  align-items: center;
  display: flex;
  margin-right: -0.4375rem;
  margin-left: -0.4375rem;
`;

export const Label = styled.span`
  display: inline-block;
  padding-left: 0.4375rem;
  margin-right: 16px;
  min-width: fit-content;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    padding: 20px ${({ theme }) => theme.padding.medium};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${({ theme }) => theme.zIndex.up};
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
    min-width: 100%;
    margin-bottom: 16px;
    gap: 16px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 228px;
  flex: 0 0 228px;
  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
`;
