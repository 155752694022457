/* eslint-disable no-nested-ternary */
import {
  ActionButton,
  Box,
  Button,
  DoodleLoader,
  Icon,
  colors
} from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { usePreventBodyScroll, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";

import { isLightColor } from "src/js/theme/utils";
import { useTranslation } from "src/js/translation";
import { EventDomain, LessonsEvent, OldBoard } from "src/js/types";
import { useBoardContext } from "src/js/context/BoardProvider";
import Dropdown from "src/legacy/components/dropdown";
import { modalPage } from "src/legacy/models/modalPageModel";
import { getCurrentRoute, navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import ResourceComments from "src/legacy/view/resource/ResourceComments";
import { showToastError } from "src/js/modules/messageManager";
import { useModuleResourceViewer } from "./hooks";
import { BlockedBoardElement } from "./components";

import * as S from "./ModuleResourceViewer.styles";

type ModuleResourceViewerProps = {
  indexDefault?: number;
  disableOption?: boolean;
  onClose: () => void;
};

// This is used only for non-draft modules

const ModuleResourceViewer = ({
  indexDefault = 0,
  disableOption = false,
  onClose
}: ModuleResourceViewerProps) => {
  usePreventBodyScroll();
  const { greyTheme, whiteTheme } = useTheme();
  const {
    ThemeStore: { customizationSettings },
    UserStore: { activeUser },
    GroupStore: { activeGroup },
    BoardsStore: {
      setBoardElementCommentNumberMap,
      boardElementCommentNumberMap
    }
  } = useStores();
  const {
    areCommentsDisabled,
    boardElement,
    allBoardElements,
    currentIndex,
    isLoadingBoardElement,
    isResourceEditable,
    onNextBoardElement,
    onPrevBoardElement,
    reportAbuse,
    resourceComponent,
    shouldShowFooter,
    showComments,
    toggleComments,
    boardElementError
  } = useModuleResourceViewer({ indexDefault });
  const { translate } = useTranslation();
  const { activeBoard }: { activeBoard: OldBoard } = useBoardContext();

  const elementIsBlocked = boardElementError?.response?.status === 403;

  let dropdownMenu = null;
  const dropdownOptions = [];

  const editFunc = () => {
    if (!isResourceEditable) return;

    const url = boardElement.id
      ? createUrl("module_element_edit", {
          group_id: activeGroup.id,
          module_id: `${boardElement.boardId}`,
          board_element_id: `${boardElement.id}`
        })
      : `${getCurrentRoute()}/edit`;

    navigateTo(url);
    modalPage.hide();
  };

  if (!!boardElementError && !elementIsBlocked) {
    onClose();
    showToastError({ str: boardElementError?.response?.data.error });
  }

  const textEditButton = boardElement?.resource.type === "TEXT" &&
    isResourceEditable && (
      <S.StyledOptionButton type="button">
        <Icon
          icon="editSecondary"
          initialViewbox
          width="20px"
          height="20px"
          onClick={editFunc}
        />
      </S.StyledOptionButton>
    );

  if (
    boardElement?.resource.fileMimeType &&
    boardElement?.resource.fileMimeType === "application/pdf"
  ) {
    dropdownOptions.push(
      <li>
        <a
          href={boardElement.resource.publicUrls[0]}
          target="_blank"
          rel="noreferrer"
        >
          {translate("download")}
        </a>
      </li>
    );
  }

  if (activeUser.uuid !== boardElement?.user.uuid) {
    dropdownOptions.push(
      <li>
        <S.StyledDropdownButton type="button" onClick={reportAbuse}>
          {translate("resource_report_abuse_option_label")}
        </S.StyledDropdownButton>
      </li>
    );
  }

  const commentNumber = boardElementCommentNumberMap.get(boardElement?.id);
  if (
    disableOption === false &&
    dropdownOptions.length > 0 &&
    !elementIsBlocked
  ) {
    dropdownMenu = (
      <div className="viewer-resource__header-button">
        <S.StyledOptionButton
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <Icon icon="gear" height={22} width={22} />
        </S.StyledOptionButton>
        <Dropdown
          labelledBy=""
          role="listbox"
          className="resource-options-dropdown"
          items={dropdownOptions}
        />
      </div>
    );
  }

  const boardElementNamePrefix = activeBoard.has_mandatory_order
    ? `${currentIndex + 1}. `
    : "";

  return (
    <S.ResourceViewerWrap>
      <Box
        backgroundColor={colors.grey[1000]}
        height={shouldShowFooter ? "calc(100% - 48px)" : "100%"}
        id={`resource-iframe${boardElement?.resource.id}`}
      >
        <S.StyledHeader
          backgroundColor={
            customizationSettings?.layoutColor || colors.violet[500]
          }
        >
          <S.StyledTextWrapper
            color={
              isLightColor(customizationSettings?.layoutColor)
                ? colors.grey[50]
                : colors.grey[1000]
            }
          >
            <Box overflow="hidden" display="flex" flex={1} alignItems="center">
              {boardElement ? (
                `${boardElementNamePrefix}${boardElement.name}`
              ) : !elementIsBlocked ? (
                <S.Skeleton width="20%" height={16} borderRadius={4} />
              ) : (
                <S.WhiteHeader level="6">
                  {translate("board_element_view_blocked_resource_name")}
                </S.WhiteHeader>
              )}
            </Box>
            {textEditButton}
            {dropdownMenu}
            <div className="viewer-resource__header-button">
              <ActionButton
                onClick={onClose}
                icon="close"
                theme={whiteTheme}
                variant="secondary"
              />
            </div>
          </S.StyledTextWrapper>
        </S.StyledHeader>
        <S.ResourceViewerBody>
          <S.ResourceWrapper>
            {isLoadingBoardElement ? (
              <DoodleLoader theme={whiteTheme} />
            ) : elementIsBlocked ? (
              <BlockedBoardElement />
            ) : (
              resourceComponent
            )}
          </S.ResourceWrapper>
          {!areCommentsDisabled && (
            <ResourceComments
              parentId={Number(boardElement?.id)}
              showing={showComments}
              onNewComment={() => {
                _trackEvent(EventDomain.Lessons, LessonsEvent.CommentCreate);
                const oldCommentNumber = boardElementCommentNumberMap.get(
                  boardElement?.id
                );
                setBoardElementCommentNumberMap(
                  boardElement?.id,
                  oldCommentNumber + 1
                );
              }}
              onDeleteComment={(nestedCommentNumber = 0) => {
                _trackEvent(EventDomain.Lessons, LessonsEvent.CommentDelete);
                const oldCommentNumber = boardElementCommentNumberMap.get(
                  boardElement?.id
                );
                setBoardElementCommentNumberMap(
                  boardElement?.id,
                  oldCommentNumber - 1 - nestedCommentNumber
                );
              }}
            />
          )}
        </S.ResourceViewerBody>
        {shouldShowFooter && (
          <S.FooterWrapper>
            {currentIndex !== 0 ? (
              <ActionButton
                icon="chevronLeft"
                onClick={onPrevBoardElement}
                theme={whiteTheme}
                variant="secondary"
              />
            ) : (
              <Box height={40} width={40} />
            )}
            {!areCommentsDisabled && (
              <S.CommentsToggle>
                <Button
                  onClick={toggleComments}
                  theme={greyTheme}
                  variant="primary"
                  withIcon={{
                    icon: showComments ? "chevronLeft" : "chevronRight",
                    positionIcon: "right",
                    heightIcon: 16,
                    widthIcon: 16,
                    viewbox: "0 0 24 24"
                  }}
                >
                  {`${commentNumber || 0} ${translate(
                    commentNumber === 1 ? "commento" : "commenti"
                  )}`}
                </Button>
              </S.CommentsToggle>
            )}
            {currentIndex !== allBoardElements.length - 1 ? (
              <ActionButton
                icon="chevronRight"
                onClick={onNextBoardElement}
                theme={whiteTheme}
                variant="secondary"
              />
            ) : (
              <Box height={40} width={40} />
            )}
          </S.FooterWrapper>
        )}
      </Box>
    </S.ResourceViewerWrap>
  );
};

export default observer(ModuleResourceViewer);
