import getUserClient from "../modules/connection";
import {
  api_group_alias_active,
  api_groups_invites_delete,
  getVersionedApiUrl
} from "./apiUrls";

export interface AliasActiveDTO {
  email: string;
  id: number;
  group_invite: {
    group: {
      name: string;
    };
    alias: string;
    is_public: boolean;
  };
  invite_role: string;
  free_invitation: boolean;
  created: number;
}

export const checkAliasActive = (code: string) => {
  return getUserClient().get<AliasActiveDTO>(
    getVersionedApiUrl(api_group_alias_active({ alias: code }), "v1")
  );
};

export const joinGroup = (code: string) => {
  return getUserClient().get<{
    id: number;
    is_joining: boolean;
    responseJSON: string;
  }>(getVersionedApiUrl(api_groups_invites_delete({ inviteId: code }), "v1"));
};
