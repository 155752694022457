import React from "react";
import { fetchResourcesBoards } from "src/js/repository/boardRepository";
import { useTranslation } from "src/js/translation";
import {
  SelectableList,
  SelectableListEmptyState
} from "../layout/SelectableList";
import { ModalLayout } from "../modal/ModalLayout";
import BoardPickerCard from "./BoardPickerCard";

export type ModuleType = {
  id: number;
  name: string;
  group_id: number;
  group_name: string;
};

type BoardPickerProps = {
  onSaveSubmit: (
    selectedBoardItem: ModuleType & {
      color?: number;
      is_published?: boolean;
      is_milestone?: boolean;
      is_mandatory?: boolean;
      is_open?: boolean;
    }
  ) => void;
  groupId: number;
  onClose?: () => void;
};

const BoardPicker = ({
  onSaveSubmit,
  groupId,
  onClose = () => {}
}: BoardPickerProps) => {
  const { translate } = useTranslation();

  const fetchItems = ({ name = "", limit, offset }) => {
    return fetchResourcesBoards({
      name,
      offset,
      limit,
      group_id: groupId
    }).then(data => {
      const results: ModuleType[] = data.results.map((item: ModuleType) => ({
        name: item?.name,
        group_name: item?.group_name,
        id: item?.id
      }));
      return { results, hasNext: null };
    });
  };

  const handleOnConfirm = (selectedIds: number[], list: ModuleType[]) => {
    if (!selectedIds[0]) return;
    const selectedBoardItem = list.find(item => item?.id === selectedIds[0]);
    onSaveSubmit(selectedBoardItem);
  };

  const getEmptyStateFromStatus = status => (
    <SelectableListEmptyState
      status={status}
      emptyListLabel="board_module_import_empty_list"
      emptySearchLabel="board_module_import_empty_search"
    />
  );

  const getCustomItemCard = props => <BoardPickerCard {...props} />;

  return (
    <ModalLayout
      labels={{
        title: translate("board_picker_title"),
        mobileClose: translate("close")
      }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false
      }}
      desktopProps={{
        hasAdaptiveHeight: false
      }}
      closeModal={onClose}
    >
      <SelectableList
        onConfirm={handleOnConfirm}
        fetchItems={fetchItems}
        selectedItemsIds={[]}
        customComponents={{
          getEmptyStateFromStatus,
          getCustomItemCard
        }}
        labels={{
          searchPlaceholder: translate("search_board_picker_modal_placeholder"),
          translatedTitle: translate("select_lesson_board_picker_modal_label"),
          translatedConfirm: translate("board_picker_submit_button")
        }}
      />
    </ModalLayout>
  );
};

export default BoardPicker;
