import {
  BadgeRelatedGroupType,
  SpaceBadge,
  ObjectValues,
  SpacePurpose
} from "../types";

import {
  NotificationAreaModel,
  NotificationTypeModel
} from "../types/models/Notification";

export type ApiSearchGroupsVars = {
  userId: string;
};

export type FetchNotificationsParams = {
  read?: number;
  type?: NotificationTypeModel[];
  area?: NotificationAreaModel[];
  spaceId?: string;
  groupId?: number;
  limit?: number;
  offset?: number;
};

export type SpaceCreationPayload = {
  name: string;
  slug: string;
  defaultBadgeColor: number;
  purpose: SpacePurpose;
  badge?: File;
};
export interface SpaceUserStats {
  id: number;
  uuid: string;
  groupsNumber: number;
  usersGroupsNumber: number;
  notificationNumber: number;
}

export type BadgeRelatedGroupsResponse = {
  limit: number;
  offset: number;
  total: number;
  results: BadgeRelatedGroupType[];
};

export type BadgeAssertion = {
  badgeId: string;
  ipfsReference: string;
  isNft: boolean;
  openBadgeReferece: string;
  status: string;
  userId: string;
};

export type BadgeCreationResponsePayload = {
  assertions: BadgeAssertion[];
};

export type BadgeCreationRequestPayload = Omit<SpaceBadge, "id">;

export type WalletResponse = {
  address: string;
  chain: string;
  id: string;
};

export const BadgeTransactionStatus = {
  Success: "SUCCESS",
  Fail: "FAILED"
};

export type BadgeTransactionStatusType = ObjectValues<
  typeof BadgeTransactionStatus
>;
