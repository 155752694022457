import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const ToggleWrapper = styled.div`
  width: 44px;
  span {
    width: 44px;
    height: 24px;
    :before {
      width: 20px;
      height: 20px;
    }
  }
  input:checked + span:before {
    transform: translateX(20px) !important;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey[900]};
`;

export const StyledSectionTitle = styled(Text)`
  font-weight: 500;
`;

export const StyledSectionDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const SectionCardContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  word-break: break-word;
`;

export const SectionCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SectionCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 8px;

  @media (max-width: 767px) {
    gap: 20px;
    padding: 12px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
`;

export const HeadingWrapper = styled.div`
  margin: 0 0 4px;
`;
