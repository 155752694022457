import {
  Heading,
  Lottie,
  LottieProps,
  shadow,
  Text
} from "@arcadia/design-system";
import NoMessagesAnimationJSON from "Images/lottie/hello.json";
import styled from "styled-components";

export const NoMessagesContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 288px;
  height: 100%;
  max-height: 312px;
  padding: 40px;
  backdrop-filter: blur(4.5px);
  border-radius: 16px;
  box-shadow: ${shadow[100]};
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const NoMessagesHeading = styled(Heading).attrs(props => ({
  ...props,
  level: 5
}))`
  text-align: center;
  margin-bottom: 8px;
`;

export const NoMessagesText = styled(Text).attrs(props => ({
  ...props,
  type: "body"
}))`
  text-align: center;
  max-width: 245px;
  margin: 0px auto;
  margin-bottom: 40px;
`;

export const NoMessagesAnimation = styled(Lottie).attrs(
  (props: LottieProps) => ({
    ...props,
    src: NoMessagesAnimationJSON
  })
)`
  width: 180px;
  margin-bottom: 25px;
`;
