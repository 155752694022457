import { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useStores } from "src/js/hooks";
import { createUrl } from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { fetchSpaceDetails } from "src/js/repository/spacesRepository";
import { SpaceDetailsModel } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";

const useSetupSpace = () => {
  const [blocked, setBlocked] = useState(false);
  const {
    SpaceStore: { setActiveSpaceById, activeSpaceId }
  } = useStores();

  const {
    params: { space_slug }
  } = useRouteMatch<{ space_slug: string }>({
    path: WeSchoolRoutes.App.Spaces.spaceGroups
  }) ?? { params: { space_slug: "" } };

  const { pathname } = useLocation();

  const checkSpaceBlocked = (response: SpaceDetailsModel) => {
    setBlocked(response.spaceSettings.blocked);
  };

  const checkAndSetActiveSpace = (response: SpaceDetailsModel) => {
    const { space } = response;
    if (space?.id === activeSpaceId) return Promise.resolve();
    return setActiveSpaceById(space.id);
  };

  useEffect(() => {
    if (space_slug !== "") {
      fetchSpaceDetails(space_slug).then((data: SpaceDetailsModel) => {
        checkAndSetActiveSpace(data);
        checkSpaceBlocked(data);
      });
    }
  }, [space_slug]);

  useEffect(() => {
    if (
      blocked &&
      !pathname.includes("overquotablocked") &&
      !pathname.includes("chat")
    ) {
      navigateTo(createUrl("space_groups_overquota_blocked", { space_slug }));
    }

    if (!blocked && pathname.includes("overquotablocked")) {
      navigateTo(createUrl("space_groups", { space_slug }));
    }
  }, [blocked, pathname]);

  return { spaceSlug: space_slug, spaceIsBlocked: blocked };
};

export default useSetupSpace;
