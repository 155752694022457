import { ChipVariant } from "@arcadia/design-system";
import { PlanCardChipType, PlanCardChipTypes } from "./PlanCardChip.types";

export const PLAN_CARD_CHIP_TYPES: PlanCardChipTypes = {
  [PlanCardChipType.PlanCancelled]: {
    label: "plan_card_chip_cancelled",
    variant: ChipVariant.Danger
  },
  [PlanCardChipType.PlanActive]: {
    label: "plan_card_chip_active",
    variant: ChipVariant.Success
  },
  [PlanCardChipType.MostPopular]: {
    label: "plan_card_chip_popular",
    variant: ChipVariant.Warning
  },
  [PlanCardChipType.WrongSeatsNumber]: {
    label: "plan_card_chip_wrong_seats_number",
    variant: ChipVariant.Danger
  },
  [PlanCardChipType.TrialPeriod]: {
    label: "plan_card_chip_trial",
    variant: ChipVariant.Primary
  }
};
