import {
  Button,
  ContextualAlert,
  Icon,
  Input,
  Text,
  ToolbarSpaceItem
} from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useViewTracking } from "src/js/context/ViewTrackingProvider";
import { WeSchoolVirtualViewDescriptions } from "src/js/context/ViewTrackingProvider/Routes.description";
import { useDebounce, useOnboarding, useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import createUrl from "src/js/modules/routing";
import { checkCodeStatus } from "src/js/repository/groupRepository";
import { __FORM_MANUAL_JOIN__ } from "src/js/settings/settingsFormValidation";
import { TranslationKeys, useTranslation } from "src/js/translation";
import {
  EventDomain,
  GroupDetailsMinimal,
  GroupEvent,
  SpaceModel,
  SpaceType
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { SpaceAndGroupDetails } from "../SpaceAndGroupDetails";
import TranslatedTooltip from "../TranslatedTooltip";

import * as S from "./GroupJoinWithCodeForm.styles";

type GroupJoinWithCodeForm = {
  onSuccessCallback?: () => void;
};

const GroupJoinWithCodeForm = ({
  onSuccessCallback
}: GroupJoinWithCodeForm) => {
  const { isOnboardingFlow } = useOnboarding();

  const [groupDetails, setGroupDetails] = useState<GroupDetailsMinimal>({
    name: "",
    cover: {
      small: null,
      originalUrl: null,
      medium: null,
      large: null
    },
    usersCount: 0
  });
  const [spaceDetails, setSpaceDetails] = useState<SpaceModel>({
    id: "",
    name: "",
    description: "",
    badge: "",
    slug: "",
    defaultBadgeColor: 1,
    purpose: "",
    hasContract: false,
    spaceType: SpaceType.STANDARD
  });
  const [isGroupAvailable, setIsGroupAvailable] = useState(false);
  const [groupAndSpaceDetailsVisible, setVisible] = useState(false);
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const { register, handleSubmit, watch, errors, setError, clearError } =
    useForm<{
      groupCode: string;
    }>({
      defaultValues: {
        groupCode: ""
      }
    });
  const groupCodeValue = watch("groupCode").split("/").pop();
  const groupCodeValueDebounced = useDebounce(groupCodeValue, 500);
  const {
    GroupStore: { fetchGroupInfoFromInvitation },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { trackVirtualView } = useViewTracking();

  useEffect(() => {
    setVisible(false);
    if (groupCodeValueDebounced) {
      checkCodeStatus(groupCodeValueDebounced)
        .then(() => {
          setIsGroupAvailable(true);
        })
        .catch(() => {
          setIsGroupAvailable(false);
          setError("groupCode", {
            type: "400",
            message: translate("group_code_validation_error")
          });
        });
      fetchGroupInfoFromInvitation(groupCodeValueDebounced).then(
        ({ group, space: { space }, usersCount }) => {
          setVisible(true);
          // this is to handle a very edge case related to error and warnings shown at the same time if a group is deactivated
          setError("groupCode", {});
          clearError("groupCode");
          setGroupDetails({
            name: group.name,
            cover: group.cover,
            usersCount
          });

          setSpaceDetails({ ...space });
        }
      );
    }
  }, [groupCodeValueDebounced]);

  useEffect(() => {
    if (!isOnboardingFlow) {
      _trackEvent(EventDomain.Group, GroupEvent.JoinCodeFlow);
      trackVirtualView(WeSchoolVirtualViewDescriptions.Groups.Join.WithCode);
    }
  }, []);

  const handleSubmission = handleSubmit(async formData => {
    const code = formData.groupCode.split("/").pop();
    onSuccessCallback();
    navigateTo(createUrl("join", { alias_id: code }));
  });

  const currentTheme = isLayoutModeMobile ? greyTheme : whiteTheme;

  const formIntro = isOnboardingFlow ? (
    <Text type="formField">
      {translate("onboarding_group_join_with_code_description")}
    </Text>
  ) : (
    <>
      <Icon icon="frameSource" width="48px" height="48px" initialViewbox />
      <Text type="formField">
        {translate("group_join_with_code_description")}
      </Text>
    </>
  );

  return (
    <>
      <S.TextWrapper>{formIntro}</S.TextWrapper>
      <S.Form id="group-join-with-code-form" onSubmit={handleSubmission}>
        <S.InputLabel>
          <Text type="formSubtitle">
            {translate("group_join_with_code_label")}
            <TranslatedTooltip tooltipString="group_join_tooltip_text">
              <Icon icon="helpCircle" height="10" width="10" />
            </TranslatedTooltip>
          </Text>
        </S.InputLabel>
        <Input
          theme={currentTheme}
          key="groupCode"
          name="groupCode"
          label={translate("group_join_with_code_label")}
          placeholder={translate("group_join_with_code_cta_placeholder")}
          rounded
          ref={register(__FORM_MANUAL_JOIN__.groupCode)}
          hideLabel
          rightSection={
            groupAndSpaceDetailsVisible && (
              <S.AvatarWrapperText>
                <ToolbarSpaceItem
                  id="space-badge"
                  theme={currentTheme}
                  badge={spaceDetails.badge?.small as string}
                  backgroundColorCode={spaceDetails.defaultBadgeColor}
                  spaceName={spaceDetails.name}
                  isSelected={false}
                  size="extraSmall"
                />
              </S.AvatarWrapperText>
            )
          }
          feedback={
            errors.groupCode
              ? {
                  message: translate(
                    (errors.groupCode.message ??
                      errors.groupCode?.types.message) as TranslationKeys
                  ),
                  type: "error"
                }
              : null
          }
        />
        {groupAndSpaceDetailsVisible && (
          <SpaceAndGroupDetails
            spaceDetails={spaceDetails}
            groupDetails={groupDetails}
            theme={currentTheme}
            isAvailable={isGroupAvailable}
          />
        )}
        {!isGroupAvailable && groupAndSpaceDetailsVisible && (
          <S.WarningContainer>
            <ContextualAlert
              theme={whiteTheme}
              text={translate("group_join_with_code_not_available_warning")}
            />
          </S.WarningContainer>
        )}
        <S.SubmitContainer>
          <Button
            type="submit"
            variant="primary"
            theme={currentTheme}
            fullWidth
            disabled={
              !isGroupAvailable ||
              !groupAndSpaceDetailsVisible ||
              spaceDetails.spaceType === SpaceType.DEMO
            }
          >
            {translate("group_join_with_code_button")}
          </Button>
        </S.SubmitContainer>
      </S.Form>
    </>
  );
};

export default GroupJoinWithCodeForm;
