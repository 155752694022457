import {
  Button,
  ConfirmationAlert,
  ContextualAlert,
  ProgressBar,
  SpecificError
} from "@arcadia/design-system";
import { __ } from "Legacy/modules/localization";
import React from "react";
import DragAndDropArea from "src/js/components/DragAndDropArea";
import { formatBytes } from "src/js/modules/commonFunction";
import getUserClient from "src/js/modules/connection";
import {
  api_resource_create,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { __ICON_UPLOAD__ } from "src/js/settings/settingsImage";
import { translateString } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import { withConfirmDialogStore, withUploadStore } from "../hooks/useStores";

function chooseFile() {
  document.getElementById("fileOrigin").click();
}

class SingleResourceUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileTitle: "",
      loaded: 0,
      error: "",
      selectedFile: null,
      statusLoading: false
    };

    this.checkMimeType.bind(this);
    this.checkFileSize.bind(this);
    this.handleDrop.bind(this);
    this.onChangeHandler.bind(this);
    this.onInputChange.bind(this);
    this.sendFile.bind(this);
    this.resetUpload = this.resetUpload.bind(this);
  }

  checkMimeType = file => {
    const { mimeArray } = this.props;
    const types = mimeArray;
    if (mimeArray === "*") {
      return true;
    }
    if (types.every(type => file.type !== type)) {
      this.setState({
        error: "file_extension_unsupported_error"
      });
      return false;
    }
    return true;
  };

  checkFileSize = file => {
    const { maxSize } = this.props;
    const size = maxSize;
    if (file.size > size) {
      this.setState({
        error: "file_size_error"
      });
      return false;
    }
    return true;
  };

  onChangeHandler = event => {
    const { target } = event;
    if (
      this.checkMimeType(target.files[0]) &&
      this.checkFileSize(target.files[0])
    ) {
      this.setState({
        selectedFile: target.files[0],
        fileTitle: target.files[0].name,
        loaded: 0,
        error: ""
      });
    } else {
      target.value = null;
    }
  };

  handleDrop = event => {
    this.onChangeHandler(event);
  };

  onInputChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  sendFile = () => {
    const { selectedFile, fileTitle } = this.state;
    const { successCallback, errorCallback, type, group_id, usePostPolicy } =
      this.props;

    const catchError = error => {
      this.setState({
        statusLoading: false,
        error:
          error?.request?.status === 413
            ? "file_size_error"
            : "file_generic_error"
      });
      errorCallback();
    };

    if (usePostPolicy) {
      getUserClient()
        .post(
          getVersionedApiUrl(api_resource_create("api_resource_create")),
          {
            name: fileTitle.replace(".zip", ""),
            type: "SCORM",
            size: selectedFile.size
          },
          {
            onUploadProgress: ProgressEvent => {
              this.setState({
                loaded: parseInt(
                  (ProgressEvent.loaded / ProgressEvent.total) * 100,
                  10
                )
              });
            }
          }
        )
        .then(({ data }) => {
          const { policy } = data;
          const { UploadStore, onPostPolicyBackHandler } = this.props;

          const inputs = Object.entries(policy.inputs);
          const formData = new FormData();
          let eventName = null;

          inputs.forEach(input => {
            formData.append(input[0], input[1]);
            // eslint-disable-next-line prefer-destructuring
            if (input[0] === "key") eventName = input[1];
          });

          formData.append("file", selectedFile);

          const req = new XMLHttpRequest();

          req.open("POST", policy.action);

          UploadStore.appendXMLHttpUpload({
            eventTopic: eventName.split("/")[1],
            fileName: fileTitle,
            XMLReq: req,
            payload: formData,
            successCallback: () => {
              successCallback(data);
            },
            cancelCallback: () => {
              req.abort();
            }
          });

          return onPostPolicyBackHandler();
        })
        .catch(catchError);
    } else {
      this.setState({
        statusLoading: true
      });

      const data = new FormData();
      data.append("file", selectedFile);
      data.append("type", type || "upload");
      data.append("name", fileTitle);
      data.append("group_id", group_id);
      getUserClient()
        .post(
          getVersionedApiUrl(api_resource_create("api_resource_create")),
          data,
          {
            onUploadProgress: ProgressEvent => {
              this.setState({
                loaded: parseInt(
                  (ProgressEvent.loaded / ProgressEvent.total) * 100,
                  10
                )
              });
            }
          }
        )
        .then(res => {
          this.setState({
            statusLoading: false
          });
          successCallback(res.data);
        })
        .catch(catchError);
    }
  };

  resetUpload() {
    const { theme, ConfirmDialogStore } = this.props;
    const { whiteTheme } = theme;

    ConfirmDialogStore.openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={__("Sei sicuro di voler interrompere il caricamento?")}
        declineText={__("Annulla")}
        onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
        acceptText={__("Conferma")}
        onAcceptFunction={() => {
          this.setState({
            fileTitle: "",
            loaded: 0,
            error: "",
            selectedFile: null,
            statusLoading: false
          });
          ConfirmDialogStore.closeConfirmDialog();
        }}
      />
    );
  }

  render() {
    const { placeholder, maxSize, uploadText, mimeArray, buttonText, theme } =
      this.props;
    const { error, statusLoading, loaded, fileTitle, selectedFile } =
      this.state;
    const { greyTheme, whiteTheme } = theme;
    let allowedFile = "*";
    if (mimeArray.length > 0) {
      allowedFile = mimeArray.valueOf();
    }
    let errorMessage = "";
    if (error !== "") {
      errorMessage = <SpecificError theme={whiteTheme} text={__(error)} />;
    }

    const submitText =
      typeof buttonText !== "undefined" ? __(buttonText) : __("save");

    return (
      <div className="resource-uploader">
        <div className="text-center">
          {selectedFile != null && (
            <div className="form-group">
              <h3>{__("Il file è stato caricato")}</h3>
              <p>{__("Il file verrà visualizzato con questo nome:")}</p>
              <label
                htmlFor="fileTitle"
                className="control-label text-editor-title-label"
              >
                {/* <Translate text="TITOLO" /> */}
                <input
                  id="fileTitle"
                  type="text"
                  name="fileTitle"
                  className="form-control light text-editor-title-input"
                  value={fileTitle}
                  onChange={this.onInputChange}
                />
              </label>
            </div>
          )}
          <input
            id="fileOrigin"
            type="file"
            name="fileOrigin"
            className="hidden"
            accept={allowedFile}
            placeholder={placeholder}
            onChange={this.onChangeHandler}
          />
          {selectedFile == null && (
            <DragAndDropArea handleDrop={this.handleDrop}>
              <div className="resource-drag-area">
                <div className="resource-uploader-icon" onClick={chooseFile}>
                  <img alt="upload-icon" src={__ICON_UPLOAD__} />
                </div>
                <div className="resource-upload-file-text">
                  {__(uploadText)}
                </div>
                <div className="margin-top-10">
                  <ContextualAlert
                    theme={whiteTheme}
                    text={`${translateString({
                      text: "upload_size_limit"
                    })} ${formatBytes(maxSize)}`}
                  />
                </div>
              </div>
            </DragAndDropArea>
          )}
          {statusLoading ? (
            <ProgressBar theme={greyTheme} percentage={loaded} />
          ) : (
            ""
          )}
          {errorMessage}
          {selectedFile != null && (
            <div className="flex-centered-column margin-vertical-15">
              <span className="margin-right-15">
                <Button
                  theme={greyTheme}
                  variant="secondary"
                  onClick={this.resetUpload}
                >
                  {__("annulla upload")}
                </Button>
              </span>
              <Button
                theme={greyTheme}
                variant="secondary"
                onClick={this.sendFile}
              >
                {submitText}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTheme(
  withConfirmDialogStore(withUploadStore(SingleResourceUploader))
);
