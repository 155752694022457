import styled from "styled-components";

export const UploadErrorContainer = styled.div`
  padding: 240px 50px;
  margin: 0px auto;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 40px 10px;
  }
`;

export const Image = styled.div`
  height: 150px;
  width: 100%;
`;

export const Text = styled.div`
  width: 300px;
  margin: auto;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 500;
`;

export const BannerContainer = styled.div`
  max-width: fit-content;
  margin: 20px auto 0;
  > div {
    max-width: unset;
  }
`;
