import { CheckboxThemeOptions, Palette } from "@ds_themes/types";

export const createCheckboxTheme = (
  palette: Palette
): { checkbox: CheckboxThemeOptions } => {
  const { general, primary } = palette;

  return {
    checkbox: {
      background: general.background,
      text: general.contrastText,
      border: `1px solid ${primary.main}`,
      checked: {
        background: primary.main,
        text: primary.contrastText
      },
      disabled: {
        border: `1px solid ${primary.light}`,
        background: primary.light,
        text: primary.light
      },
      hover: {
        text: general.contrastText,
        border: `1.5px solid ${primary.main}`,
        background: "transparent"
      },
      focus: {
        border: `${primary.light} solid 3px`
      }
    }
  };
};
