import { motion } from "framer-motion";
import styled from "styled-components";

export const Backdrop = styled(motion.div)`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; //fallback
  height: 100dvh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: ${({ theme }) => theme.zIndex.forModal};
  background-color: ${({ theme }) => theme.primaryColor[50]}80;
  pointer-events: all;
`;

export const ModalContent = styled(motion.div)`
  z-index: 1200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; //fallback
  width: 100dvw;
  min-height: 100vh; //fallback
  min-height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
`;
