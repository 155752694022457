import React, { ReactNode } from "react";
import * as S from "./ModalTabs.styles";

const ModalTabs = ({
  showTabs = true,
  children
}: {
  showTabs: boolean;
  children: ReactNode;
}) => {
  return showTabs ? (
    <S.ModalTabsContainer>{children}</S.ModalTabsContainer>
  ) : null;
};

export default ModalTabs;
