import styled from "styled-components";
import textDesktop from "@ds_universal/Text/Text.desktop.const";

import { STextProps } from "./types";

export const Text = styled.span<STextProps>`
  white-space: pre-wrap;
  ${({ type = "cta" }) => ({
    ...textDesktop[type]
  })}
`;
