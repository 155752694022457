import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import each from "lodash/each";
import sortBy from "lodash/sortBy";

const CrosswordsViewer = withBackbone(
  createReactClass({
    keywordToLetters(word) {
      let a, key, keyint, letter, p, posX, posY, result;
      if (word.orientation == undefined) {
        word.orientation = "horizontal";
      }
      if (word.x === undefined) {
        word.x = 0;
      }
      if (word.y === undefined) {
        word.y = 0;
      }
      result = [];
      a = word.value.split("");
      for (key in a) {
        letter = a[key];
        keyint = parseInt(key);
        if (word.orientation === "horizontal") {
          posX = word.x + keyint;
          posY = word.y;
        } else {
          posX = word.x;
          posY = word.y + keyint;
        }
        result.push({
          letter,
          posX,
          posY
        });
      }
      return result;
    },
    render() {
      const self = this;
      let maxLength = 0;
      let containerStyle = null;
      let words = this.props.words;
      words = sortBy(words, q => q.index); // riordino per index
      const squares = [];

      each(words, word => {
        const letters = self.keywordToLetters(word);
        maxLength = letters.length > maxLength ? letters.length : maxLength;
        for (let k = 0; k < letters.length; k++) {
          const l = letters[k];
          const addStyle = {
            top: `${l.posY * 30}px`,
            left: `${l.posX * 30}px`
          };
          squares.push(
            <span className="crossword__word" style={addStyle}>
              {l.letter}
            </span>
          );
        }
      });

      containerStyle = {
        height: `${maxLength * 30}px`,
        width: `${maxLength * 30}px`
      };

      return (
        <div className="crossword__preview-container ">
          <div className="crossword__preview">
            <div className="crossword__container" style={containerStyle}>
              {squares}
            </div>
          </div>
        </div>
      );
    }
  })
);
export default CrosswordsViewer;
