import styled from "styled-components";

export const ModalContentTitle = styled.p`
  text-align: center;
  margin: 30px 20px 10px;
`;

export const ModalContentSubtitle = styled.p`
  text-align: center;
  margin: 0px 20px 20px;
`;

export const ModalFooterContainer = styled.div`
  padding: 40px 20px 20px;
  @media screen and (max-width: 767px) {
    padding-top: 10px;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;

  & button {
    margin-bottom: 10px;
  }

  @media screen and (min-width: 768px) {
    button {
      margin: 0 5px 10px;
    }
  }
`;
