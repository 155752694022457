import axios from "axios";
import { extractErrorMessage } from "src/js/modules/messageManager";
import { __FEATURE_FLAG_JSON_URL__ } from "src/js/settings/settingsUrl";

const fetchFeatureConfig = () => {
  return axios({
    method: "get",
    url: __FEATURE_FLAG_JSON_URL__
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        extractErrorMessage(error, "error_fetch_feature_flag_config")
      );
      throw Err;
    });
};
export { fetchFeatureConfig as default };
