import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: ${({ theme }) => theme.eventcard.border};
  padding: 13px 26px;
  width: 100%;
  height: 86px;
  border-radius: 6px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.eventcard.text.color};
`;

export const LiveDate = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  align-items: flex-end;
  & span:nth-child(2) {
    font-size: 26px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  & > div:nth-child(1) > span:nth-child(2),
  & > div:nth-child(3) > span {
    color: ${({ theme }) => theme.eventcard.secondText.color};
  }
  & > div:nth-child(3) > svg {
    color: ${({ theme }) => theme.eventcard.colorIcon};
  }
`;
