import styled from "styled-components";

export const Wrapper = styled.div<{ maxWidth: string }>`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.modals};
  max-width: ${({ maxWidth }) => maxWidth || "unset"};
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  padding: 8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
`;

export const Body = styled.div`
  border-radius: 0 0 8px 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

export const ColorBox = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 16px;
  height: 12px;
  border-radius: 2px;
`;

export const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blueGrey[500]};
`;

export const HighlightedValue = styled.div<{ color: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ color }) => color};
`;
