import styled from "styled-components";

export const GroupMemberListing = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 33px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 33px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
