import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useResizeObserver, useStores } from "src/js/hooks";
import { SessionStorageKey } from "src/js/modules/storageFunction";
import { fetchSpaceSettings } from "src/js/repository/dashboardRepository";
import {
  PlanType,
  SpacePlanSettingsModel,
  SpacePurpose,
  SpaceType,
  SubscriptionStatusType
} from "src/js/types";

export const useStickyBanner = () => {
  const {
    UIStore: { isLayoutModeMobile },
    DemoSpaceStore: {
      isDemoSpaceTopBannerVisible,
      setDemoSpaceTopBannerVisibility
    },
    SpaceStore: { isAdmin, activeSpace, isDemoSpaceEnabled },
    SpacesSettingsStore: { generalSettings }
  } = useStores();

  const location = useLocation();

  const [stickyBannerHeight, setStickyBannerHeight] = useState<number>(0);
  const [planSettings, setPlanSettings] =
    useState<SpacePlanSettingsModel>(null);
  const [showBanner, setShowBanner] = useState(false);
  const stickyBannerContainerRef = useResizeObserver<HTMLDivElement>(header => {
    setStickyBannerHeight(header.height ?? 0);
  });

  const bannerBlacklist =
    activeSpace?.space.purpose === SpacePurpose.School ||
    activeSpace?.isTemporary;

  const onCloseDemoSpaceTopBanner = useCallback(() => {
    setDemoSpaceTopBannerVisibility(false);
  }, [setDemoSpaceTopBannerVisibility]);

  useEffect(() => {
    if (bannerBlacklist) {
      setShowBanner(false);
    }
    if (activeSpace && !bannerBlacklist) {
      setShowBanner(true);
      fetchSpaceSettings({
        spaceId: activeSpace.space.id
      }).then(generalSettingsFetched => {
        setPlanSettings(
          generalSettingsFetched.planSettings as SpacePlanSettingsModel
        );
      });
    }
  }, [activeSpace, generalSettings]);

  useEffect(() => {
    const topBannerNotClosed =
      sessionStorage.getItem(SessionStorageKey.DemoSpaceTopBannerClosed) !==
      "true";
    const isOverQuota = location.pathname.includes("overquotablocked");
    const shouldDisplayBanner =
      (!isOverQuota &&
        topBannerNotClosed &&
        [PlanType.FREE, PlanType.NONE].some(p => [
          planSettings?.planType === p
        ]) &&
        planSettings?.subscriptionStatus === SubscriptionStatusType.InTrial &&
        !activeSpace?.isTemporary &&
        activeSpace?.space.purpose !== SpacePurpose.School &&
        isAdmin) ||
      activeSpace?.space?.spaceType === SpaceType.DEMO;

    setDemoSpaceTopBannerVisibility(shouldDisplayBanner);
  }, [isLayoutModeMobile, planSettings, showBanner, activeSpace]);

  return {
    stickyBannerHeight,
    stickyBannerContainerRef,
    isDemoSpaceTopBannerVisible:
      isDemoSpaceTopBannerVisible && isDemoSpaceEnabled,
    onCloseDemoSpaceTopBanner
  };
};
