import styled from "styled-components";
import { UnderlinedLinesIcon } from "./UnderlinedSpan.icons";

export const StyledUnderlinedLinesIcon = styled(UnderlinedLinesIcon)`
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
`;

export const RelativeContainer = styled.span`
  position: relative;
`;
