import { RESOURCE_DEPRECATED_TYPE, RESOURCE_TYPE } from "src/js/types";
import {
  add,
  differenceInMinutes,
  format,
  isAfter,
  isSameMinute,
  isWithinInterval,
  subMinutes
} from "date-fns";
import { createStaticUrl } from "src/js/modules/routing";
import { AppUrlRouteParams } from "src/js/modules/types";
import {
  CalendarEventResource,
  EventCategory,
  EventCategoryType,
  EventStatus,
  EventStatusType
} from "./Calendar.types";

type GeneralAttachment = {
  id: number;
  name: string;
  status: string;
};

interface NormalizeAttachmentsParams {
  boardAttachments: GeneralAttachment[];
  exerciseAttachments: GeneralAttachment[];
  resourceAttachments: CalendarEventResource[]; // Updated to include type
}

export const normalizeAttachments = ({
  boardAttachments,
  exerciseAttachments,
  resourceAttachments
}: NormalizeAttachmentsParams): CalendarEventResource[] => {
  const newResourceAttachments = [...resourceAttachments];
  boardAttachments.forEach(board => {
    if (!newResourceAttachments.find(element => element.id === board.id)) {
      newResourceAttachments.push({
        ...board,
        type: RESOURCE_DEPRECATED_TYPE.BOARD
      });
    }
  });

  exerciseAttachments.forEach(exercise => {
    if (!newResourceAttachments.find(element => element.id === exercise.id)) {
      newResourceAttachments.push({
        ...exercise,
        type: RESOURCE_TYPE.EXERCISE
      });
    }
  });

  return newResourceAttachments;
};

export const hasLiveAccess = ({
  dateNow,
  startDate,
  endDate
}: {
  dateNow: Date;
  startDate: Date;
  endDate: Date;
}) => {
  const anticipatedStartDate = subMinutes(startDate, 10);
  return isWithinInterval(dateNow, {
    start: anticipatedStartDate,
    end: endDate
  });
};

export const getEventStatus = ({
  dateNow,
  startDate,
  endDate,
  eventType
}: {
  dateNow: Date;
  startDate: Date;
  endDate: Date;
  eventType: EventCategoryType;
}) => {
  let status: EventStatusType = EventStatus.Default;
  if (eventType === EventCategory.Live && startDate && endDate) {
    if (isWithinInterval(dateNow, { start: startDate, end: endDate })) {
      status = EventStatus.Ongoing;
    } else if (isAfter(dateNow, endDate)) {
      status = EventStatus.Finished;
    }
  }
  return status;
};

export interface DatesUtils {
  startDate?: Date;
  endDate?: Date;
  duration?: number;
  hasSameDay?: boolean;
  hasSameTime?: boolean;
  dateNow?: Date;
}

export const datesAreOnSameDay = (first: Date, second: Date): boolean => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

export const getDatesUtils = ({
  start,
  end
}: {
  start?: string | Date;
  end?: string | Date;
}): DatesUtils => {
  const startDate = start ? new Date(start) : null;
  const endDate = end ? new Date(end) : null;
  const dateNow = new Date();
  const duration =
    endDate && startDate ? differenceInMinutes(endDate, startDate) : undefined;
  const hasSameDay =
    startDate && endDate ? datesAreOnSameDay(startDate, endDate) : undefined;
  const hasSameTime =
    startDate && endDate ? isSameMinute(startDate, endDate) : undefined;

  return { startDate, endDate, dateNow, duration, hasSameDay, hasSameTime };
};

export const getEventUrl = ({
  eventId,
  eventType,
  groupId
}: {
  eventId: string;
  eventType: EventCategoryType;
  groupId: number;
}) => {
  let urlConst: keyof AppUrlRouteParams;

  switch (eventType) {
    case EventCategory.Live:
      urlConst = "event_live";
      break;
    case EventCategory.Activity:
      urlConst = "event_activity";
      break;
    case EventCategory.Deadline:
      urlConst = "event_deadline";
      break;
    default:
      urlConst = "event_live";
  }
  const titleUrl = `#${createStaticUrl(urlConst, {
    group_id: groupId,
    event_id: eventId
  })}`;
  return titleUrl;
};

export const addHoursToTime = ({
  time,
  extraHours
}: {
  time: string;
  extraHours: number;
}): string => {
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(Number(hours), Number(minutes), 0, 0);
  const newDate = add(date, { hours: extraHours });
  const formattedTime = format(newDate, "HH:mm");

  return formattedTime;
};

export const formatDateToUTCISOString = ({ date }: { date: Date }): string => {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
};
