import React, { useContext } from "react";
import { StoresContext } from "../context/StoreProvider";
import { StoresContext as fakeStoreContext } from "../context/__mocks__/StoreProvider";
import { __IS_STORYBOOK__ } from "../settings/settingsGeneral";

/**
 * @name useStores
 *
 * @description
 * ...
 */

const useStores = () =>
  useContext(__IS_STORYBOOK__ ? fakeStoreContext : StoresContext);

// HOC for mantain compatibility with legacy components

export function withGroupStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ GroupStore }) => <Component {...props} GroupStore={GroupStore} />}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withConfirmDialogStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ ConfirmDialogStore }) => (
          <Component {...props} ConfirmDialogStore={ConfirmDialogStore} />
        )}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withBoardsStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ BoardsStore }) => (
          <Component {...props} BoardsStore={BoardsStore} />
        )}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withModalStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ ModalStore }) => <Component {...props} ModalStore={ModalStore} />}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withUploadStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ UploadStore }) => (
          <Component {...props} UploadStore={UploadStore} />
        )}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withAuthStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ AuthStore }) => <Component {...props} AuthStore={AuthStore} />}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withUserStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ UserStore }) => <Component {...props} UserStore={UserStore} />}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withReportStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ ReportStore }) => (
          <Component {...props} ReportStore={ReportStore} />
        )}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withSpaceStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ SpaceStore }) => <Component {...props} SpaceStore={SpaceStore} />}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export function withExerciseStore(Component) {
  const StoreComponent = props => {
    return (
      <StoresContext.Consumer>
        {({ ExerciseStore }) => (
          <Component {...props} ExerciseStore={ExerciseStore} />
        )}
      </StoresContext.Consumer>
    );
  };
  return StoreComponent;
}

export default useStores;
