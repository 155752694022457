import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { SocialInputProps } from "./SocialInput.types";

export const EmojiPickerWrapper = styled(motion.div)<{
  renderBottom: boolean;
  targetElementRight: number;
  targetElementWidth: number;
  offsetRight: number;
}>`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  right: 0;
  ${({ offsetRight }) => css`
    right: ${offsetRight}px;
    width: 100%;
  `}

  ${({ targetElementWidth }) => css`
    width: ${targetElementWidth <= 400 ? targetElementWidth : "450"}px;
  `}

  ${({ renderBottom }) =>
    renderBottom
      ? css`
          top: 100%;
        `
      : css`
          bottom: 100%;
        `}
  z-index: 2;
  cursor: pointer;
`;

export const WsMentionWrapper = styled.div<{
  variant: SocialInputProps["variant"];
  maxHeight?: string;
}>`
  display: flex;
  flex-direction: row;
  padding: 5px;
  padding-right: 0px;
  padding-left: 16px;
  align-items: center;
  border-radius: 25px;
  max-height: ${({ maxHeight }) => maxHeight};
  justify-content: stretch;
  border: 1px solid transparent;
  background-color: ${({ theme, variant }) =>
    variant === "white" ? theme.colors.blueGrey[900] : theme.colors.grey[1000]};
  transition: 300ms ease-in-out;

  textarea,
  .ws-mentions__highlighter {
    overflow-y: scroll !important;
    max-height: ${({ maxHeight }) => maxHeight};

    // Remove scrollbar in Firefox & Edge
    -ms-overflow-style: none;
    scrollbar-width: none;

    // Remove scrollbar in Chrome & Safari
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.primaryColor[500]};
  }

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor[500]};
  }
`;

export const IconWrapper = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.primaryColor[300] : "#9e9e9e"};
  padding: 0px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;
