import styled from "styled-components";

export const ButtonFooterContainer = styled.div`
  background: ${({ theme }) => theme.colors.transparent9};
  border-top: 1px solid ${({ theme }) => theme.colors.defaultBorderColor};
  width: 100%;
  height: 64px;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
  text-align: center;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  height: 100vh;
  padding-top: ${({ theme }) => theme.sizes.defaultHeaderHeight};
`;

export const IframeContainer = styled.div`
  height: 100%;
`;

export const Iframe = styled.iframe`
  min-height: calc(100% - 64px);
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const HeaderWrapper = styled.div`
  .header__clean {
    background-color: ${({ theme }) =>
      theme.colors.headerPrimaryColor}!important;
    color: ${({ theme }) => theme.colors.headerTextColor}!important;
  }
`;

export const NoWhiteboardContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: auto;
  padding-top: 256px;
  max-width: 835px;
  margin: 0px auto;

  @media (max-width: 780px) {
    padding-top: 150px;
    justify-content: center;
    align-items: center;
    margin: 0px 30px;
  }
`;

export const NoWhiteboardImage = styled.div`
  height: 260px;
  width: 330px;
  margin-right: 50px;

  @media (max-width: 780px) {
    margin-right: 0px;
  }
`;

export const NoWhiteboardMessage = styled.div`
  align-self: center;
  max-width: 450px;
  min-width: 260px;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.title};
  align-items: center;
  justify-content: center;
  @media (max-width: 780px) {
    text-align: center;
  }
`;

export const MessageTitle = styled.p`
  margin-bottom: 8px;
`;

export const MessageSubtitle = styled.p`
  margin-bottom: 20px;
`;
