import { Editor } from "@tiptap/core";
import React, { useCallback } from "react";
import { useStores } from "src/js/hooks";
import { Icon } from "@arcadia/design-system";
import { TextEditorLinkModal } from "src/js/components/TextEditorInput/components/TextEditorLinkModal";
import { getIconFromExtension } from "../BaseTextEditor.utils";
import { EditorExtension } from "../BaseTextEditor.types";

import * as S from "./BaseTextEditorMenu.styles";

const LinkButton = ({ editor }: { editor: Editor }) => {
  const {
    ModalStore: { openModal, closeModal }
  } = useStores();

  const toggleLinkModal = useCallback(() => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    const defaultText = state.doc.textBetween(from, to, "");
    const defaultUrl = editor.getAttributes("link")?.href;
    openModal(() => (
      <TextEditorLinkModal
        defaultText={defaultText}
        defaultUrl={defaultUrl}
        closeModal={closeModal}
        submitValues={value =>
          editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: value.linkUrl })
            .command(({ tr }) => {
              tr.insertText(value.linkText);
              return true;
            })
            .unsetMark("link")
            .insertContent(" ")
            .run()
        }
      />
    ));
  }, [editor]);

  return (
    <S.Button
      active={editor.isActive("link")}
      type="button"
      onClick={toggleLinkModal}
      className={editor.isActive("link") ? "is-active" : ""}
    >
      <Icon
        icon={getIconFromExtension(EditorExtension.Link)}
        viewBox="0 0 16 16"
        initialViewbox
      />
    </S.Button>
  );
};

export default LinkButton;
