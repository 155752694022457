import styled from "styled-components";
import colors from "@ds_themes/colors";

export const Content = styled.div`
  margin: auto;
`;

export const HighlightedLink = styled.div`
  display: inline-flex;
  text-align: center;
  padding: 12px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${colors.violet[800]};
  width: 100%;
`;
