import { Text } from "@arcadia/design-system";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 144px 0px;
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  border-radius: 100px;
  margin-bottom: 16px;
  padding: 12px 20px;
  cursor: default;
`;

export const Email = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[500]};
  margin-left: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 767px) {
    max-width: 272px;
  }
`;

export const GoBackContainer = styled.div`
  cursor: pointer;
  margin: 14px auto 0px;
  width: fit-content;
`;

export const GoBack = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;
