import React, { PropsWithChildren } from "react";
import { DragOverlay, defaultDropAnimation } from "@dnd-kit/core";

const SortableOverlay = ({
  children
}: PropsWithChildren<Record<string, unknown>>) => {
  return (
    <DragOverlay
      dropAnimation={{
        ...defaultDropAnimation,
        duration: 280
      }}
    >
      {children}
    </DragOverlay>
  );
};

export default SortableOverlay;
