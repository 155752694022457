import { Avatar } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { GroupInSpaceModel } from "src/js/types";
import { useTheme } from "styled-components";
import { DesktopPreferencePicker } from "../DesktopPreferencePicker";
import { MobilePreferencePicker } from "../MobilePreferencePicker";
import { UpdateSingleGroupType } from "../types";
import * as S from "./GroupList.styles";

const GroupCard = ({
  group,
  notificationSettingsPreference: { appPreference, emailPreference },
  openTopPopover,
  updateSingleGroupPreference,
  disabled = false
}: GroupInSpaceModel & {
  openTopPopover: boolean;
  updateSingleGroupPreference: (params: UpdateSingleGroupType) => void;
  disabled?: boolean;
}) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();

  return (
    <S.GroupCard>
      <S.CoverWrapper>
        <Avatar
          theme={whiteTheme}
          url={group.cover?.small || __GROUP_COVER_DEFAULT__}
          size="s"
          alt={group.name}
          rounded={false}
        />
      </S.CoverWrapper>
      <S.EllispedGroupName type="headerTitle">{group.name}</S.EllispedGroupName>
      <S.OptionsWrapper onClick={e => e.stopPropagation()}>
        {isLayoutModeMobile ? (
          <MobilePreferencePicker
            emailPreference={emailPreference}
            appPreference={appPreference}
            onSelectEmail={newPreference => {
              updateSingleGroupPreference({
                groupId: group.id,
                emailPreference: newPreference
              });
            }}
            onSelectApp={newPreference => {
              updateSingleGroupPreference({
                groupId: group.id,
                appPreference: newPreference
              });
            }}
            disabled={disabled}
          />
        ) : (
          <DesktopPreferencePicker
            emailPreference={emailPreference}
            openFromTop={openTopPopover}
            appPreference={appPreference}
            onSelectEmail={newPreference => {
              updateSingleGroupPreference({
                groupId: group.id,
                emailPreference: newPreference
              });
            }}
            onSelectApp={newPreference => {
              updateSingleGroupPreference({
                groupId: group.id,
                appPreference: newPreference
              });
            }}
            disabled={disabled}
          />
        )}
      </S.OptionsWrapper>
    </S.GroupCard>
  );
};

export default GroupCard;
