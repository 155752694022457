import React from "react";
import { __GOOGLE_API_KEY__ } from "src/js/settings/settingsGeneral";
import { OldResource } from "src/js/types";

const Maps = ({
  resource: { name, maps_latitude, maps_longitude, maps_zoom }
}: {
  resource: OldResource & {
    maps_longitude: string;
    maps_latitude: string;
    maps_zoom: string;
  };
}) => {
  return (
    <iframe
      className="resource__iframe"
      width="100%"
      title={name}
      frameBorder="0"
      src={`https://www.google.com/maps/embed/v1/place?key=${__GOOGLE_API_KEY__}&zoom=${maps_zoom}&q=${maps_latitude}+${maps_longitude}`}
    />
  );
};

export default Maps;
