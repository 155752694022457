/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Heading, Text } from "@arcadia/design-system";
import React, { FC } from "react";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { VideoPlayer } from "../../VideoPlayer";
import * as S from "./IntroPresentation.styles";
import { IntroPresentationProps } from "./IntroPresentation.types";

const IntroPresentation: FC<IntroPresentationProps> = ({
  onSkip,
  onConfirm
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <S.IntroPresentationWrapper>
      <S.VideoWrapper>
        <VideoPlayer video={S3Video.MigrateGroupsModalIntro} />
      </S.VideoWrapper>
      <Heading level="6">
        {translate({ text: "migrate_groups_into_title_content" })}
      </Heading>
      <Text>{translate({ text: "migrate_groups_into_text_content" })}</Text>
      <S.FooterWrapper>
        <Button
          onClick={onSkip}
          theme={whiteTheme}
          variant="secondary"
          fullWidth
        >
          {translate({ text: "migrate_groups_into_btn_cancel" })}
        </Button>
        <Button
          onClick={onConfirm}
          theme={whiteTheme}
          variant="primary"
          fullWidth
        >
          {translate({ text: "migrate_groups_into_btn_confirm" })}
        </Button>
      </S.FooterWrapper>
    </S.IntroPresentationWrapper>
  );
};

export default IntroPresentation;
