import React from "react";
import { Box, CheckIndicator, Heading, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { CardItemProps } from "../AddExerciseResource.types";

import * as S from "../AddExerciseResource.styles";

const CustomCardItem = ({ el, isActive, onClick }: CardItemProps) => {
  const { greyTheme } = useTheme();

  return (
    <S.CustomCardWrapper isActive={isActive} onClick={onClick}>
      <S.IconContainer isActive={isActive}>
        <Icon icon="weight" height={24} width={24} />
      </S.IconContainer>
      <Heading level="6">{el.exercise.name}</Heading>
      <Box marginLeft="auto">
        <CheckIndicator theme={greyTheme} isSelected={isActive} />
      </Box>
    </S.CustomCardWrapper>
  );
};

export default CustomCardItem;
