import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import moment from "moment";
import isUndefined from "lodash/isUndefined";
import findIndex from "lodash/findIndex";
import mapValues from "lodash/mapValues";
import clone from "lodash/clone";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import map from "lodash/map";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import {
  DoodleLoader,
  ActionButton,
  ConfirmationAlert,
  Icon
} from "@arcadia/design-system";
import { withTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { getActiveGroupId } from "../../modules/activeGroup";
import { createURL } from "../../modules/utility";
import { __ } from "../../modules/localization";

const InclassActivityTable = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        activityData: {},
        loading: false
      };
    },
    getInitialState() {
      return {
        custom_activities: [],
        user_statistics: [],
        original_data: [],
        orderId: "",
        orderType: "",
        orderState: "",
        loader: true
      };
    },
    componentWillMount() {
      const self = this;
      self.setState({
        user_statistics: self.props.activityData.user_statistics,
        custom_activities: self.props.activityData.custom_activities
      });
    },
    componentDidMount() {
      const self = this;
      if (self.props.loading == false) {
        self.createStructure();
      }
    },
    componentWillReceiveProps(nextProps) {
      const self = this;
      self.setState(
        {
          user_statistics: nextProps.activityData.user_statistics
        },
        () => {
          if (nextProps.loading == false) {
            self.createStructure();
          }
        }
      );
    },
    createStructure() {
      const self = this;
      let userStatistics = self.state.user_statistics;
      const costumActivities = self.state.custom_activities;
      let updatedRows = "";
      updatedRows = map(userStatistics, userRow => {
        let activityData = userRow.custom_activity_data;
        if (isUndefined(activityData)) {
          userRow.custom_activity_data = {};
          activityData = {};
        }
        let countUserActivities = 0;
        mapValues(costumActivities, activity => {
          if (isUndefined(activityData[activity.id])) {
            userRow.custom_activity_data[activity.id] = {
              value: false,
              activity_custom_id: activity.id,
              exist: false
            };
          } else {
            const boardValues = activityData[activity.id];
            if (boardValues.value) {
              countUserActivities += 1;
            }

            userRow.custom_activity_data[activity.id].value = isUndefined(
              activityData[activity.id].value
            )
              ? 0
              : activityData[activity.id].value;
            userRow.custom_activity_data[activity.id].activity_custom_id =
              isUndefined(activityData[activity.id].activity_custom_id)
                ? activity.id
                : activityData[activity.id].activity_custom_id;
            userRow.custom_activity_data[activity.id].exist = true;
          }
        });
        userRow.countUserActivities = countUserActivities;
        return userRow;
      });
      userStatistics = updatedRows;
      self.setState({
        user_statistics: userStatistics,
        original_data: clone(userStatistics),
        loader: false
      });
    },
    setOrder(type, id) {
      const self = this;
      const userStatistics = self.state.user_statistics;
      let sortedStatistics = "";
      let order = self.state.orderState;
      switch (type) {
        case "status":
          sortedStatistics = sortBy(userStatistics, "countUserActivities");
          break;
        case "activity":
          sortedStatistics = sortBy(userStatistics, item => [
            item.custom_activity_data[id].value
          ]);
          break;
      }
      switch (self.state.orderState) {
        case "asc":
          order = "desc";
          sortedStatistics.reverse();
          break;
        case "desc":
          order = "";
          sortedStatistics = self.state.original_data;
          break;
        default:
          order = "asc";
          break;
      }

      self.setState({
        user_statistics: sortedStatistics,
        orderId: id,
        orderType: type,
        orderState: order
      });
    },
    updateInclass(object) {
      const self = this;
      const userStatistics = self.state.user_statistics;
      const copy = self.state.user_statistics;
      let rowIndex = 0;

      map(userStatistics, (userRow, userIndex) => {
        if (userRow.user.id == object.user.id) {
          rowIndex = userIndex;
          if (!isUndefined(userRow.custom_activity_data)) {
            map(userRow.custom_activity_data, (costumActivity, costumindex) => {
              if (
                costumActivity.activity_custom_id == object.activity_custom_id
              ) {
                copy[rowIndex].custom_activity_data[costumindex].value =
                  object.value;
                copy[rowIndex].countUserActivities += object.value ? 1 : -1;
              } else {
                copy[rowIndex].custom_activity_data[object.activity_custom_id] =
                  {
                    value: object.value,
                    activity_custom_id: object.activity_custom_id
                  };
              }
            });
          } else {
            const indexcostum = object.activity_custom_id;
            const addobject = {
              value: object.value,
              activity_custom_id: object.activity_custom_id,
              exist: true
            };
            copy[rowIndex].custom_activity_data = {};
            copy[rowIndex].custom_activity_data[indexcostum] = addobject;
            copy[rowIndex].countUserActivities = 1;
          }
        }
      });

      self.setState({
        user_statistics: copy
      });
    },
    deleteColumn(id) {
      const self = this;
      const costumActivities = self.state.custom_activities;
      const userStatistics = self.state.user_statistics;

      const index = findIndex(costumActivities, {
        id
      });

      map(userStatistics, userRow => {
        if (userRow.custom_activity_data[id].value) {
          userRow.countUserActivities -= 1;
        }
        return userRow;
      });

      if (index > -1) {
        costumActivities.splice(index, 1);
        self.setState({
          custom_activities: costumActivities,
          userStatistics
        });
      }
    },
    createActivity(userId, activityId) {
      const self = this;
      const ajaxUrl = createURL("api_costum_activities_user_new", {
        group_id: getActiveGroupId(),
        activity_custom_id: activityId,
        user_id: userId
      });
      const value = 1;
      $.ajax({
        type: "POST",
        dataType: "json",
        url: ajaxUrl,
        data: {
          value
        },
        success(data) {
          showToastSuccess({ str: __("activity_update_success") });
          self.updateInclass(data);
        },
        error(data) {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        }
      });
    },
    updateActivity(value, userId, activityId) {
      const self = this;
      const ajaxUrl = createURL("api_costum_activities_user_new", {
        group_id: getActiveGroupId(),
        activity_custom_id: activityId,
        user_id: userId
      });
      const bool = !value ? 1 : 0;
      $.ajax({
        type: "POST",
        dataType: "json",
        url: ajaxUrl,
        data: {
          value: bool
        },
        success(data) {
          showToastSuccess({ str: __("activity_update_success") });
          self.updateInclass(data);
        },
        error(data) {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        }
      });
    },
    deleteConfirmation(id) {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={<Translate text="activity_delete_confirmation" />}
          declineText={<Translate text="Annulla" />}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={<Translate text="Conferma" />}
          onAcceptFunction={() => {
            self.deleteActivity(id);
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    deleteActivity(id) {
      const self = this;
      const ajaxUrl = createURL("api_costum_activities_delete", {
        group_id: getActiveGroupId(),
        activity_custom_id: id
      });
      $.ajax({
        type: "delete",
        dataType: "json",
        url: ajaxUrl,
        success() {
          showToastSuccess({ str: __("activity_delete_success") });
          self.deleteColumn(id);
        },
        error(data) {
          const errorMsg = extractErrorMessage(
            data.responseJSON,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        }
      });
    },
    render() {
      const self = this;
      const userStatistics = self.state.user_statistics;
      const { greyTheme } = this.props.theme;
      const activities = self.state.custom_activities;
      let rows = "";
      let header = "";
      const countTotalActivities = activities.length;
      let orderSimbol = <Icon icon="minus" width="12" height="12" />;
      let loader = <DoodleLoader theme={greyTheme} isMini />;

      switch (self.state.orderState) {
        case "asc":
          orderSimbol = <Icon icon="chevronLargeUp" width="15" height="15" />;
          break;
        case "desc":
          orderSimbol = <Icon icon="chevronLargeDown" width="15" height="15" />;
          break;
        default:
          orderSimbol = <Icon icon="minus" width="12" height="12" />;
          break;
      }

      header = values(
        mapValues(activities, activity => {
          const thid = `activity-${activity.id}`;
          return (
            <th id={thid}>
              <div className="wrapper-delete-activity">
                <span>{activity.name}</span>
                <ActionButton
                  onClick={self.deleteConfirmation.bind(self, activity.id)}
                  theme={greyTheme}
                  variant="tertiary"
                  icon="trash"
                />
              </div>
              <TranslatedTooltip tooltipString="order_by_activity_completed">
                <div
                  className="filter text-center"
                  onClick={self.setOrder.bind(self, "activity", activity.id)}
                >
                  {self.state.orderType == "activity" &&
                  self.state.orderId == activity.id ? (
                    orderSimbol
                  ) : (
                    <Icon icon="minus" width="12" height="12" />
                  )}
                </div>
              </TranslatedTooltip>
              <TranslatedTooltip tooltipString="publication_date">
                <div className="publish-date">
                  {moment(activity.created_at).format("ll")}
                </div>
              </TranslatedTooltip>
            </th>
          );
        })
      );

      if (userStatistics.length > 0) {
        rows = map(userStatistics, userRow => {
          const { user } = userRow;
          let trackClass = "green-color";
          const activitiesStatistic = userRow.custom_activity_data;
          const column = values(
            mapValues(activities, function (activity) {
              if (isUndefined(activitiesStatistic)) {
                return (
                  <td>
                    <div className="text-center">
                      <input
                        type="checkbox"
                        onChange={self.createActivity.bind(
                          this,
                          user.id,
                          activity.id
                        )}
                      />
                    </div>
                  </td>
                );
              }
              if (
                isUndefined(activitiesStatistic[activity.id]) ||
                activitiesStatistic[activity.id].exist == false
              ) {
                return (
                  <td>
                    <div className="text-center">
                      <input
                        type="checkbox"
                        onChange={self.createActivity.bind(
                          this,
                          user.id,
                          activity.id
                        )}
                      />
                    </div>
                  </td>
                );
              }
              const activityValues = activitiesStatistic[activity.id];
              const { value } = activityValues;
              return (
                <td>
                  <div className="text-center">
                    <input
                      type="checkbox"
                      checked={activityValues.value}
                      onChange={self.updateActivity.bind(
                        this,
                        value,
                        user.id,
                        activity.id
                      )}
                    />
                  </div>
                </td>
              );
            })
          );

          if (userRow.countUserActivities == 0) {
            trackClass = "red-color";
          } else if (countTotalActivities != userRow.countUserActivities) {
            trackClass = "orange-color";
          }

          return (
            <tr>
              <td className="user">
                <strong>{userRow.user.surname}, </strong>
                {userRow.user.name}
              </td>
              <td className="font-18">
                <div className={trackClass}>
                  {userRow.countUserActivities}/{countTotalActivities}
                </div>
              </td>
              {column}
            </tr>
          );
        });
      } else {
        rows = (
          <tr>
            <td className="user transparent-color">fake user</td>
            <td colSpan={activities.length + 1} className="font-18">
              {__("placeholder_activity_nouser")}
            </td>
          </tr>
        );
      }
      if (!self.state.loader) {
        loader = "";
      }

      return (
        <div>
          {loader}
          <div className="scrollable-x-div">
            <table
              className={`table activity-table ${
                self.props.loading ? "loading" : ""
              }`}
            >
              <tbody>
                <tr>
                  <th className="user">placeholder</th>
                  <th>
                    <div className="full-width">{__("on_track")}</div>
                    <TranslatedTooltip tooltipString="order_by_activities_completed">
                      <div
                        className="filter text-center"
                        onClick={self.setOrder.bind(this, "status")}
                      >
                        {self.state.orderType == "status" ? (
                          orderSimbol
                        ) : (
                          <Icon icon="minus" width="12" height="12" />
                        )}
                      </div>
                    </TranslatedTooltip>
                  </th>
                  {header}
                </tr>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  })
);

export default withTheme(withConfirmDialogStore(InclassActivityTable));
