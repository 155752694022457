import { ActionButton } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useOnboarding, useStores } from "src/js/hooks";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  __EXAMPLE_BACKGROUND_PATTERN__,
  __EXAMPLE_SPACE_CREATION_IMAGE__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  OnboardingEvent,
  SpaceModel,
  SpaceUserRoleEnum
} from "src/js/types";
import { USER_TYPE } from "src/js/types/models/MarketingData";
import { navigateTo } from "src/legacy/modules/history";
import styled, { useTheme } from "styled-components";
import { WeSchoolRoutes } from "../../Routes.const";
import { SpaceCreationForm } from "../../spaces/space_creation/SpaceCreationForm";
import Example from "../components/Example";
import StepperBlock from "../components/StepperBlock";
import { TitleBlock } from "../components/TitleBlock";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 12px;
    padding: 24px 20px 24px 20px;
  }
`;

const SpaceCreation = () => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const {
    UserStore: { userOnboarding, setUserOnboarding },
    SpaceStore: { addNewSpaceToSpaceList, setActiveSpace }
  } = useStores();

  const { flowSwitcher, routeGuard } = useOnboarding();

  useEffect(() => {
    routeGuard();
  }, []);

  const onSuccessCallback = (
    newSpace: SpaceModel,
    onboardingWeSchoolTemplates: boolean
  ) => {
    addNewSpaceToSpaceList({
      space: newSpace,
      role: SpaceUserRoleEnum.Owner,
      unreadNotificationCount: 0,
      unreadThreadMessagesCount: 0
    });
    setActiveSpace({
      groupsCount: 0,
      usersCount: 0,
      space: newSpace,
      role: SpaceUserRoleEnum.Owner,
      isTemporary: false,
      isInTrial: true,
      spaceSettings: {
        blocked: false,
        dashboardGroupsEnabled: true
      }
    });
    _trackEvent(EventDomain.Onboarding, OnboardingEvent.SpaceCreation);
    if (onboardingWeSchoolTemplates) {
      _trackEvent(
        EventDomain.Onboarding,
        OnboardingEvent.WeSchoolTemplateSelection
      );
    } else {
      _trackEvent(
        EventDomain.Onboarding,
        OnboardingEvent.StandardGroupSelected
      );
    }
    flowSwitcher(USER_TYPE.BUSINESS, onboardingWeSchoolTemplates);
  };

  const goBack = () => {
    setUserOnboarding({
      ...userOnboarding,
      step: WeSchoolRoutes.Onboarding.marketingData
    });
    navigateTo(WeSchoolRoutes.Onboarding.marketingData);
  };

  const backButton = (
    <ActionButton
      variant="tertiary"
      icon="chevronSmallLeft"
      theme={whiteTheme}
      onClick={goBack}
    />
  );

  return (
    <OnboardingLayout
      mainContent={
        <Container>
          <TitleBlock
            title={translate("company_space_creation_title")}
            backComponent={backButton}
          />
          <SpaceCreationForm
            theme={whiteTheme}
            onSuccessCallback={(newSpace, onboardingWeSchoolTemplates) => {
              onSuccessCallback(newSpace, onboardingWeSchoolTemplates);
            }}
          />
        </Container>
      }
      sideContent={
        <Example
          background={__EXAMPLE_BACKGROUND_PATTERN__}
          image={__EXAMPLE_SPACE_CREATION_IMAGE__}
          imagePosition="right: 0"
          description={translate(
            "onboarding_example_space_creation_description"
          )}
        />
      }
      stepper={<StepperBlock currentStep={2} totalSteps={4} />}
      mobileCentered
    />
  );
};

export default observer(SpaceCreation);
