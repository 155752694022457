import React, { useEffect } from "react";

const cookieBotDeclarationUrl =
  "https://consent.cookiebot.com/ce5022a0-85dd-4de5-a1bf-44e73677f04e/cd.js";

const ItaPolicy = () => {
  useEffect(() => {
    const declarationBlock = document.getElementById("cookieDeclaration");
    if (!declarationBlock) return;
    const script = document.createElement("script");

    script.src = cookieBotDeclarationUrl;
    script.async = true;

    declarationBlock.appendChild(script);
  }, []);

  return (
    <div className="relative">
      <div
        id="privacy-policy"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <em>
                    Il Responsabile della Protezione dei Dati (RPD) di WeSchool
                    Srl è Studio E-Lex, con sede operativa in Roma, Via dei
                    Barbieri n. 6. Per contatti:{" "}
                    <a href="mailto:rpd@weschool.com">rpd@weschool.com</a>
                  </em>
                </p>

                <p>
                  <strong>(V13 – 20 febbraio 2023</strong>
                  <strong>)</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="definizioni"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                1. Definizioni
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Comunicazioni Didattiche, Condizioni Generali, Contratto
                Acquisto Servizi, Contratto Organizzazione, Cookies, Dati
                Personali, Educators, GDPR, Genitore, Gruppi, Identificativi
                Università, Informativa, Learners, Learners Maggiorenni,
                Learners Minorenni, Licenza, Marketing Diretto, Organizzazione,
                Piattaforma, Profilazione, Scuola, SEE, Servizi, Titolare,
                Trattamento, Università, Utenti, WeSchool.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <strong>
                    1.1 <b />
                  </strong>
                  <b>Comunicazioni Didattiche</b>
                  <span>
                    : comunicazioni relative alla disponibilità di materiali,
                    iniziative o servizi didattici sulla Piattaforma, senza
                    alcuna finalità commerciale, in base alla Scuola e al ciclo
                    scolastico.
                  </span>
                </p>

                <p>
                  <b>1.2 Condizioni Generali:</b>
                  <span>
                    {" "}
                    condizioni generali dei Servizi offerti da WeSchool,
                    sottoscritte da ciascun Utente.
                  </span>
                </p>

                <p>
                  <b>1.3 Contratto Acquisto Servizi: </b>contratto di
                  compravendita dei Servizi offerti dagli Educators (inclusa
                  WeSchool quando svolge il ruolo di Educator), che si instaura
                  tra, da un lato, il Learner (o in caso di Learner Minorenne,
                  il Genitore) e, dall’altro lato, l’Educator che offre il
                  Servizio (inclusa WeSchool quando svolge il ruolo di
                  Educator).
                  <b>
                    <br />
                  </b>
                </p>

                <p>
                  <b>
                    1.4 Contratto Organizzazione
                    <span>
                      : contratto relativo alla prestazione della Licenza
                      sottoscritto tra WeSchool e l’Organizzazione di
                      riferimento degli Utenti.
                    </span>
                  </b>
                </p>

                <p>
                  <b>1.5 Cookies: </b>
                  <span>
                    cookie sono file di testo (lettere e/o numeri) che
                    contengono pacchetti di informazioni che vengono memorizzati
                    sul computer o sul dispositivo mobile dell’Utente tutte le
                    volte che questo visita un sito web attraverso un{" "}
                  </span>
                  <i>
                    <span>browser</span>
                  </i>
                  <span>
                    {" "}
                    o attraverso una webapp. Ad ogni successiva visita il
                    browser invia i cookies al sito web che li ha originati.
                    I&nbsp;cookies&nbsp;possono essere memorizzati solo per il
                    tempo di utilizzo di un determinato sito (i.e.&nbsp;
                  </span>
                  <i>
                    <span>session cookies</span>
                  </i>
                  <span>
                    ), o per un periodo di tempo più lungo ed indipendente dalla
                    sessione (i.e.&nbsp;
                  </span>
                  <i>
                    <span>persistent cookies</span>
                  </i>
                  <span>).</span>
                </p>

                <p>
                  <b>1.6 Dati personali: </b>
                  <span>
                    qualunque informazione relativa ad una persona fisica,
                    identificata o identificabile, direttamente e
                    indirettamente, mediante riferimento al nome, ad un numero
                    di identificazione, ai dati di ubicazione, a un
                    identificativo online o a elementi caratteristici della sua
                    identità fisica, fisiologica, genetica, psichica, economica,
                    culturale o sociale.
                  </span>
                </p>

                <p>
                  <b>1.7 Educators: </b>
                  <span>
                    Utenti che utilizzano la Licenza per fornire i propri
                    Servizi a favore di Learners all’interno di Gruppi.&nbsp;
                  </span>
                </p>

                <p>
                  <b>1.8 GDPR:&nbsp;</b>
                  <b />
                  <span>Regolamento UE 2016/679 del 27 aprile 2016.</span>
                </p>

                <p>
                  <b>1.9 Genitore:</b> <b />
                  <span>
                    genitore e/o tutore esercente la responsabilità genitoriale
                    di un Learner Minorenne.
                  </span>
                </p>

                <p>
                  <b>
                    1.10 Gruppi:{" "}
                    <span>
                      gruppi privati di Learners creati dagli Educators
                      all’interno dei quali gli Educators erogano i Servizi.
                    </span>
                  </b>
                </p>

                <p>
                  <b>1.11 Identificativi Università: </b>
                  <span>
                    congiuntamente (i) “eduPersonPrincipalName”, campo
                    “obbligatorio”, rappresenta l’identificativo univoco
                    dell’Utente, la richiesta di questo campo è necessaria per
                    utilizzare il servizio di login con le credenziali
                    dell’Università; (ii) “eduPersonScopedAffiliaton”, campo
                    “obbligatorio”, rappresenta l’identificativo della tipologia
                    di affiliazione all’Università, la richiesta di questo campo
                    è necessaria per utilizzare il servizio di login con le
                    credenziali dell’Università; (iii)
                  </span>{" "}
                  <span>
                    “email”, campo “obbligatorio”, rappresenta l’indirizzo di
                    posta elettronica dell’Utente, la richiesta di questo campo
                    è necessaria per la creazione del profilo dell’Utente nella
                    Piattaforma; (iv) “givenName”, campo “opzionale”,
                    rappresenta il nome dell’Utente, la richiesta di questo
                    campo è necessaria per la creazione del profilo dell’Utente
                    nella Piattaforma; (v) “surname”, campo “opzionale”,
                    rappresenta il cognome dell’Utente, la richiesta di questo
                    campo è necessaria per la creazione del profilo dell’Utente
                    nella Piattaforma.
                  </span>
                </p>

                <p>
                  <b>1.12 Informativa: </b>
                  <span>
                    informativa sul trattamento dei Dati Personali e informativa
                    relativa ai Cookies che costituiscono il presente documento.
                    La presente Informativa non ha ad oggetto l’eventuale
                    Trattamento di Dati Personali condotto attraverso eventuali
                    siti terzi, pagine o servizi online di terzi, raggiungibili
                    dall’Utente tramite link presenti sulla Piattaforma.
                    L’Informativa è disponibile e facilmente accessibile{" "}
                  </span>
                  <span>dal sito di WeSchool (</span>
                  <a href="https://www.weschool.com/">
                    <span>https://www.weschool.com/</span>
                  </a>
                  <span>) o dalla pagina profilo di ciascun Utente.</span>
                </p>

                <p>
                  <strong>1.13</strong> <strong>Learners</strong>
                  <strong>:</strong>&nbsp;
                  <span>
                    Utenti che usano la Licenza per fruire dei Servizi offerti
                    dagli Educators all’interno di Gruppi.
                  </span>
                </p>

                <p>
                  <strong>1.14 Learners Maggiorenni</strong>
                  <b>: </b>
                  <span>Learners di età almeno pari a 18 anni.&nbsp;</span>
                </p>

                <p>
                  <b>1.15 Learners Minorenni: </b>
                  <span>
                    Learners di età inferiore a 18 anni che usano la Licenza per
                    fruire dei Servizi in virtù di specifica autorizzazione del
                    Genitore.
                  </span>
                </p>

                <p>
                  <b>1.16 Licenza: </b>
                  <span>
                    licenza della Piattaforma riconosciuta da WeSchool agli
                    Utenti per usufruire dei servizi tecnici e neutrali forniti
                    da WeSchool come meglio descritti dall’art. 4.1 delle
                    Condizioni Generali.
                  </span>
                </p>

                <p>
                  <b>
                    1.17 Marketing Diretto:{" "}
                    <span>
                      attività di marketing svolte da WeSchool, nel proprio
                      interesse, a mezzo e-mail, relative alla Licenza, ai
                      Servizi offerti da WeSchool in qualità di Educator, a
                      servizi, prodotti e iniziative simili alla Licenza, ai
                      Servizi di WeSchool e alla Piattaforma.
                    </span>{" "}
                  </b>
                </p>

                <p>
                  <strong>1.18</strong>&nbsp;
                  <b />
                  <b>Organizzazione</b>
                  <span>
                    :&nbsp;una Scuola, un’Università, una società, una P.A., un
                    ente no profit, un libero professionista o qualunque altra
                    entità giuridica.
                  </span>
                </p>

                <p>
                  <b>1.19 Piattaforma: </b>
                  <span>
                    piattaforma online finalizzata a promuovere l’accesso alla
                    cultura e all’istruzione, che consente varie forme di
                    interazione tra gli Utenti per condividere le proprie
                    conoscenze e competenze all’interno di una comunità
                    interattiva, in cui ognuno può essere sia creatore che
                    fruitore di servizi educativi, formativi e professionali.
                  </span>
                </p>

                <p>
                  <b>1.20 Profilazione: </b>
                  <b />
                  <span>
                    analisi con sistemi automatizzati di Dati Personali, ai fini
                    valutativi e predittivi.
                  </span>
                </p>

                <p>
                  <b>1.21 Scuola</b>
                  <span>
                    : istituto scolastico di ogni ordine o grado.
                    <br />
                  </span>
                </p>

                <p>
                  <strong>1.22 SEE:</strong> <b />
                  <span>“</span>
                  <i>
                    <span>Spazio Economico Europeo</span>
                  </i>
                  <span>
                    ” composto dai paesi membri dell’Unione Europea, la
                    Norvegia, l’Islanda e il Liechtenstein.
                  </span>
                </p>

                <p>
                  <b>1.23 Servizi</b>
                  <span>
                    : servizi didattici, formativi e professionali forniti dagli
                    Educators a favore dei Learners.
                  </span>
                </p>

                <p>
                  <b>1.24 Titolare</b>
                  <span>
                    : la persona fisica o giuridica, l’autorità pubblica, il
                    servizio o altro organismo che, singolarmente o insieme ad
                    altri, determina le finalità e i mezzi del Trattamento di
                    Dati Personali.
                  </span>
                </p>

                <p>
                  <b>1.25 Trattamento: </b>
                  <span>qualsiasi operazione</span>{" "}
                  <span>
                    o insieme di operazioni aventi ad oggetto i Dati Personali,
                    quali, a titolo esemplificativo, la raccolta,
                    l’organizzazione, la strutturazione, la conservazione, la
                    modifica, l’estrazione, la consultazione, l’uso, la
                    comunicazione, l’interconnessione, la limitazione, la
                    cancellazione e la distruzione.
                  </span>
                </p>

                <p>
                  <strong>1.26 Università:</strong> <b />
                  <span>
                    istituzione scientifico-didattica e culturale, pubblica o
                    privata, che rappresenta il più alto livello di istruzione.
                  </span>
                </p>

                <p>
                  <b>1.27 Utenti:</b>
                  <span>
                    {" "}
                    tutti gli utenti della Piattaforma, quali gli Educators, i
                    Learners e i Genitori.{" "}
                  </span>
                </p>

                <p>
                  <b>1.28 WeSchool:</b>
                  <span>
                    {" "}
                    WeSchool Srl, con sede legale in Via Guido Reni 42, 20133,
                    Milano C.F./P.IVA 07236760968 e Registro imprese di Milano
                    M-1946066.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="titolare"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                2. Titolare del Trattamento
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Trattamenti strettamente connessi e funzionali alla Licenza:
                i) in caso di Contratto Organizzazione con una Scuola o con
                l’Università, Scuola o Università di riferimento degli Utenti, o
                ii) in tutti i casi diversi dal punto i), WeSchool
                <br />
                b) Trattamenti connessi e funzionali alla fruizione dei Servizi:
                i) in caso di WeSchool nel ruolo di Educator, WeSchool; o ii) in
                caso di Educator diverso da WeSchool, Educator che offre il
                Servizio
                <br />
                c) Trattamenti non strettamente connessi e funzionali alla
                Licenza e ai Servizi: WeSchool
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>a) </span>
                  <span>Trattamenti dei Dati Personali </span>
                  <b>strettamente connessi e funzionali alla Licenza</b>
                  <span>:</span>
                </p>

                <p>
                  <span>i. </span>
                  <span>
                    in caso di sottoscrizione di un Contratto Organizzazione con
                    una Scuola o con una Università: il Titolare è la{" "}
                  </span>
                  <b>Scuola </b>
                  <span>o l’</span>
                  <b>Università</b>
                  <span>
                    {" "}
                    di riferimento di ciascun Utente, che svolge i Trattamenti
                    di cui al successivo punto 5 lettere da b) a f), in base
                    alla propria informativa privacy (che WeSchool invita ad
                    analizzare prima di usufruire della Licenza), e WeSchool
                    opera quale responsabile, ai sensi dell’art. 28 del GDPR,
                    per conto della Scuola o dell’Università, di volta in volta
                    titolare;
                  </span>
                </p>

                <p>
                  <span>ii. </span>
                  <span>
                    in tutti i casi diversi dal punto i): il Titolare è{" "}
                  </span>
                  <b>WeSchool</b>
                  <span>
                    {" "}
                    (come definita al punto 1.28 della presente Informativa),
                    che svolge i Trattamenti di cui al successivo punto 5
                    lettere da b) a f), in base alla presente Informativa;
                  </span>
                </p>

                <p>
                  <span>b) </span>
                  <span>Trattamenti dei dati Personali </span>
                  <b>
                    strettamente connessi e funzionali alla fruizione dei
                    Servizi
                  </b>
                  <span>:</span>
                </p>

                <p>
                  i){" "}
                  <span>
                    in caso di Servizi offerti da WeSchool come Educator: il
                    Titolare è{" "}
                  </span>
                  <b>WeSchool</b>
                  <span>
                    {" "}
                    (come definita al punto 1.28 della presente Informativa),
                    che svolge il Trattamento di cui al successivo punto 5
                    lettera g), in base alla presente Informativa;
                  </span>
                </p>

                <p>
                  <span>
                    ii) in caso di Servizi offerti da Educators diversi da
                    WeSchool: il Titolare è l’
                  </span>
                  <b>Educator</b>
                  <span>
                    {" "}
                    che svolge il Trattamento di cui al successivo punto 5
                    lettera g), in base alla propria informativa privacy (che
                    WeSchool invita ad analizzare prima di usufruire dei
                    Servizi), fermo restando che WeSchool è terza ed estranea a
                    tale Trattamento;
                  </span>
                </p>

                <p>
                  <span>c) </span>
                  <span>Trattamenti dei Dati Personali </span>
                  <b>
                    non strettamente connessi e funzionali alla Licenza e ai
                    Servizi
                  </b>
                  <span>: il Titolare è </span>
                  <b>WeSchool</b>
                  <span>
                    {" "}
                    (come definita al punto 1.28 della presente Informativa),
                    che svolge i Trattamenti di cui al successivo punto 5
                    lettere a) e da h) a l) in base alla presente Informativa.
                  </span>
                </p>

                <p>
                  <span>
                    Qualsiasi richiesta dell’Utente relativa ai Trattamenti
                    eseguiti da WeSchool in qualità di Titolare (ivi incluso
                    l’esercizio dei diritti di cui ai successivi punti 12 e 13
                    andrà rivolta a WeSchool con uno dei seguenti mezzi:
                  </span>
                </p>

                <ul>
                  <li>
                    <span>
                      a mezzo posta presso la sede legale: WeSchool Srl, in Via
                      Guido Reni 42, 20133, Milano;
                    </span>
                  </li>
                  <li>
                    a mezzo e-mail al seguente indirizzo{" "}
                    <a href="mailto:support@weschool.com">
                      support@weschool.com
                    </a>
                    .
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="responsabile"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                3. Responsabile della Protezione dei Dati Personali (DPO)
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Studio E-Lex - rpd@weschool.com
                <br />
                <br />
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  Studio E-Lex, con sede legale in Roma,{" "}
                  <span>00186, Via Dei Barbieri 6, P.IVA 11514241006.</span>
                </p>
                <p>
                  Per contatti:&nbsp;
                  <a
                    href="mailto:rpd@weschool.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    rpd@weschool.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="universita"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                4. Trattamenti per le Università
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  WeSchool partecipa alla Federazione nazionale IDEM (IDEntity
                  Management per l’accesso federato), per la realizzazione
                  dell’Infrastruttura di Autenticazione e Autorizzazione
                  federata della rete GARR, e all’interfederazione eduGAIN (che
                  collega le federazioni di identità nazionali tra loro per
                  semplificare l’accesso ai contenuti, servizi e risorse per la
                  comunità di Ricerca e Formazione). I Dati Personali degli
                  Utenti delle Università che partecipano alla Federazione
                  nazionale IDEM saranno protetti secondo il “Codice di Condotta
                  per Service Provider” approvato da eduGain, che rappresenta
                  uno standard per la protezione della privacy nel settore
                  dell’università e della ricerca.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="finalita"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                5. Finalità e natura del trattamento
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) navigazione sulla Piattaforma: necessario e facoltativo
                <br />
                b) in caso di Learner Minorenne, verifica maggiore età:
                necessario
                <br />
                c) registrazione ai Gruppi sulla Piattaforma: necessario
                <br />
                d) fornitura della Licenza: necessario
                <br />
                e) esecuzione degli eventuali pagamenti, previa identificazione:
                necessario
                <br />
                f) verifiche o accertamenti su usi illeciti o fraudolenti della
                Piattaforma: necessario
                <br />
                g) fruizione dei Servizi offerti da WeSchool come Educator:
                necessario
                <br />
                h) esecuzione Marketing Diretto da parte di WeSchool:
                facoltativo
                <br />
                i) profilazione: facoltativo
                <br />
                j) invio di comunicazioni commerciali e promozionali per conto
                di soggetti terzi: facoltativo
                <br />
                k) miglioramento della Licenza e dei Servizi: facoltativo
                <br />
                l) adempimento degli obblighi legali o per la tutela
                giudiziaria: necessario
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    WeSchool raccoglie e utilizza i Dati Personali degli Utenti
                    per il conseguimento delle seguenti finalità:
                  </span>
                </p>

                <p>
                  <span>a) Consentire la </span>
                  <b>navigazione sulla Piattaforma</b>
                  <span>
                    , essenzialmente mediante l’utilizzo di Cookies: il
                    Trattamento dei Dati Personali è disciplinato nell’
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Informativa Cookies</span>
                  </span>
                  <span>
                    {" "}
                    contenuta nei successivi punti 14 e 15 di cui WeSchool
                    raccomanda la lettura.
                  </span>
                </p>

                <p>
                  <span>
                    b) In caso di richiesta di registrazione da Learners
                    Minorenni, verificare il{" "}
                  </span>
                  <b>possesso della maggiore età dei Learners</b>
                  <span>
                    , ai fini di consentire ai Learners Minorenni di registrarsi
                    sulla Piattaforma e fruire della Licenza, mediante previa e
                    specifica autorizzazione del Genitore: il{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Trattamento dei Dati Personali</span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per consentire al Learner Minorenne di accedere alla
                    Piattaforma, e conseguentemente, per registrarsi e fruire
                    della Licenza.&nbsp;
                  </span>
                </p>

                <p>
                  <span>c) Consentire la </span>
                  <b>registrazione a un Gruppo sulla Piattaforma</b>
                  <span>
                    {" "}
                    agli Utenti e la creazione di un account personale: il
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span> Trattamento dei Dati Personali</span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per l’accesso alla Piattaforma e la successiva fruizione da
                    parte dell’Utente della Licenza e l’eventuale rifiuto
                    impedisce all’Utente di fruire della Licenza in base a
                    quanto previsto dalle Condizioni Generali. In particolare,
                    sulla base di{" "}
                  </span>
                  <i>
                    <span>form</span>
                  </i>
                  <span> di accesso specifici: </span>
                  <i>
                    <span>(i)</span>
                  </i>
                  <span>
                    {" "}
                    la registrazione sulla Piattaforma e la creazione di un{" "}
                  </span>
                  <i>
                    <span>account</span>
                  </i>
                  <span>
                    {" "}
                    personale dei Learners Minorenni vengono eseguite dal
                    Genitore, in nome e per conto del Learner Minorenne;{" "}
                  </span>
                  <i>
                    <span>(ii)</span>
                  </i>
                  <span>
                    {" "}
                    la registrazione degli Utenti delle Università avviene
                    tramite inserimento da parte degli Utenti degli
                    Identificativi Università e successiva comunicazione dalle
                    Università a WeSchool dei Dati Personali degli Utenti (e.g.
                    nome e cognome).&nbsp;
                  </span>
                </p>

                <p>
                  <span>d) Fornire la </span>
                  <b>Licenza</b>
                  <span>
                    {" "}
                    a favore degli Utenti, ivi inclusi la gestione del rapporto
                    amministrativo e contabile derivante dalla sottoscrizione
                    delle Condizioni Generali e l’invio di comunicazioni
                    strettamente connesse all’utilizzo della Licenza di
                    carattere tecnico (e.g. notifica di upload di un documento o
                    video sulla Piattaforma o consigli sull’utilizzo della
                    Piattaforma e sulla disponibilità di contenuti sulla
                    Piattaforma di natura tecnica) e/o di carattere
                    amministrativo (e.g. aggiornamenti della presente
                    Informativa):{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per l’accesso alla Piattaforma e la successiva fruizione da
                    parte dell’Utente della Licenza e l’eventuale rifiuto
                    impedisce all’Utente di fruire della Licenza in base a
                    quanto previsto dalle Condizioni Generali.
                  </span>
                </p>

                <p>
                  <span>e) Consentire agli Utenti di eseguire gli </span>
                  <b>eventuali pagamenti</b>
                  <span>
                    {" "}
                    previsti sulla Piattaforma, previa identificazione degli
                    Utenti, per i Servizi offerti a pagamento da WeSchool o
                    dagli altri Educators:{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per la fornitura o la fruizione da parte dell’Utente dei
                    Servizi offerti a pagamento sulla Piattaforma e l’eventuale
                    rifiuto impedisce all’Utente di fornire (in caso di
                    Educator) o di fruire (in caso di Learner) di tali Servizi
                    in base a quanto previsto dalle Condizioni Generali.&nbsp;
                  </span>
                </p>

                <p>
                  <span>f) Verificare e accertare eventuali </span>
                  <b>condotte illecite o fraudolente sulla Piattaforma</b>
                  <span>
                    {" "}
                    da parte degli Utenti, come ad esempio, il caricamento e
                    l’accesso a contenuti illeciti o fraudolenti (come, ad
                    esempio, contenuti di natura violenta, pornografica, oscena
                    o discriminatoria), o lesivi dei diritti (anche di immagine
                    o di proprietà intellettuale) e degli interessi di terzi
                    (ivi inclusi gli altri Utenti), o comunque non idonei al
                    supporto della formazione e dell’educazione dei Learners:{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per tutelare il corretto utilizzo della Piattaforma,
                    nell’interesse di tutti gli Utenti, qualora dovesse
                    verificarsi un atto o fatto, colposo o doloso, da parte di
                    un Utente.&nbsp;
                  </span>
                </p>

                <p>
                  <span>g) Consentire ai Learners di </span>
                  <b>fruire</b>{" "}
                  <b>dei Servizi offerti da WeSchool come Educator</b>
                  <span>
                    {" "}
                    e gestire il relativo rapporto amministrativo-contabile e di
                    post-vendita in essere tra i Learners e WeSchool:{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per la fruizione da parte del Learner dei Servizi offerti da
                    WeSchool come Educator sulla Piattaforma e l’eventuale
                    rifiuto impedisce al Learner di fruire di tali Servizi in
                    base a quanto previsto dalle Condizioni Generali e dal
                    Contratto Acquisto Servizi in essere tra WeSchool e il
                    Learner.
                  </span>
                </p>

                <p>
                  <span>h) Svolgere </span>
                  <b>attività di Marketing Diretto da parte di WeSchool</b>
                  <span>
                    , mediante invio di materiale informativo e pubblicitario,
                    segnalazione e inviti a iniziative promozionali:{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>facoltativo</span>
                  </span>
                  <span>
                    {" "}
                    e l’eventuale rifiuto da parte dell’Utente impedisce a
                    quest’ultimo di usufruire di attività di Marketing Diretto
                    da parte di WeSchool.&nbsp;
                  </span>
                </p>

                <p>
                  <span>i) Previo facoltativo consenso, svolgere la </span>
                  <b>Profilazione</b>
                  <span>, di cui al successivo punto 11: </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personal
                    </span>
                    i
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>facoltativo</span>
                  </span>
                  <span>
                    {" "}
                    e l’eventuale rifiuto da parte dell’Utente impedisce a
                    quest’ultimo di usufruire di attività di Profilazione sulle
                    preferenze manifestate dallo stesso sulla Piattaforma per
                    personalizzare la Licenza o i Servizi offerti da WeSchool
                    come Educator (mediante, ad esempio, la ricezione di
                    Comunicazioni Didattiche personalizzate) e le attività di{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    sia da parte di WeSchool (di cui alla precedente lett. h)
                    sia di soggetti terzi (di cui alla successiva lett.
                    j).&nbsp;
                  </span>
                </p>

                <p>
                  <span>j) Previo facoltativo consenso, svolgere </span>
                  <b>attività di </b>
                  <b>
                    <i>marketing</i>
                  </b>
                  <b> per conto di soggetti terzi</b>
                  <span>
                    {" "}
                    relative a prodotti e servizi digitali utili agli Utenti,
                    come a titolo esemplificativo corsi, dispositivi elettronici
                    per la formazione e servizi di connettività, mediante le
                    analisi statistiche e ricerche di mercato, l’invio di
                    materiale informativo e pubblicitario (anche mediante forme
                    di pubblicità tabellare a comparsa sulla Piattaforma) e la
                    segnalazione e l’invito a iniziative promozionali di tali
                    soggetti terzi:{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>facoltativo</span>
                  </span>
                  <span>
                    {" "}
                    e l’eventuale rifiuto da parte dell’Utente impedisce a
                    quest’ultimo di usufruire di attività di{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    relative a soggetti terzi che operano in settori di
                    interesse per gli Utenti.
                  </span>
                </p>

                <p>
                  <span>
                    k) Eseguire analisi statistiche, ricerche di mercato,{" "}
                  </span>
                  <b>
                    migliorare l’uso della Licenza e dei Servizi di WeSchool
                  </b>
                  <span>
                    {" "}
                    da parte degli Utenti. Laddove possibile, per conseguire la
                    presente finalità WeSchool utilizza dati anonimi (dati che
                    non identificano o rendono identificabili gli Utenti e che,
                    pertanto, non costituiscono Dati Personali):{" "}
                  </span>
                  <span>
                    il{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Trattamento dei Dati Personali
                    </span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>facoltativo</span>
                  </span>
                  <span>
                    {" "}
                    e l’eventuale rifiuto da parte dell’Utente impedisce a
                    quest’ultimo di fruire della Licenza e di Servizi di
                    WeSchool più affini alle proprie preferenze, senza alcun
                    pregiudizio per la fruizione della Licenza.
                  </span>
                </p>

                <p>
                  l) <span>Adempiere agli </span>
                  <b>obblighi di legge</b>
                  <span>
                    {" "}
                    e consentire a WeSchool di accertare, esercitare e difendere
                    i propri{" "}
                  </span>
                  <b>diritti</b>
                  <span>
                    {" "}
                    in tribunale o dinanzi a qualsiasi altra autorità
                    competente: il{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Trattamento dei Dati Personali</span>
                  </span>
                  <span> è </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>necessario</span>
                  </span>
                  <span>
                    {" "}
                    per tutelare la posizione giuridica, i diritti e gli
                    interessi di WeSchool in merito alla sottoscrizione,
                    all’interpretazione e all’adempimento delle Condizioni
                    Generali.
                  </span>{" "}
                  <span>
                    In particolare, qualora un Utente dovesse caricare sulla
                    Piattaforma contenuti (come testi, immagini e video),
                    WeSchool potrà conservare i Dati Personali dell’Utente (ivi
                    incluso del Genitore, in caso di Learner Minorenne), al fine
                    di tutelarsi in caso di potenziali verifiche o accertamenti
                    dalle autorità competenti o pretese da parte di soggetti
                    terzi derivanti dal caricamento di contenuti illeciti o
                    comunque lesivi delle Condizioni Generali.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="basi-giuridiche"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                6. Basi giuridiche dei Trattamenti
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) navigazione sulla Piattaforma: consenso
                <br />
                b) in caso di Learner Minorenne, verifica maggiore età: obbligo
                legale
                <br />
                c) registrazione ai Gruppi sulla Piattaforma: sottoscrizione
                delle Condizioni Generali e connesse misure precontrattuali
                <br />
                d) fornitura della Licenza: sottoscrizione delle Condizioni
                Generali
                <br />
                e) esecuzione degli eventuali pagamenti, previa identificazione:
                sottoscrizione delle Condizioni Generali
                <br />
                f) verifiche o accertamenti su usi illeciti o fraudolenti della
                Piattaforma: sottoscrizione delle Condizioni Generali e, in caso
                di coinvolgimento di autorità, obbligo legale
                <br />
                g) fruizione dei Servizi offerti da WeSchool come Educator:
                sottoscrizione delle Condizioni Generali e del Contratto
                Acquisto Servizi
                <br />
                h) esecuzione di Marketing Diretto da parte di WeSchool:
                legittimo interesse
                <br />
                i) Profilazione: consenso espresso
                <br />
                j) invio di comunicazioni commerciali e promozionali per conto
                di soggetti terzi: consenso espresso
                <br />
                k) miglioramento della Licenza e dei Servizi: legittimo
                interesse
                <br />
                l) adempimento degli obblighi legali o per la tutela
                giudiziaria: legittimo interesse
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    Qualora ai sensi del precedente punto 2 WeSchool sia
                    Titolare dei Trattamenti svolti per le finalità di cui al
                    precedente punto 5, si applicano le seguenti basi
                    giuridiche:
                  </span>
                </p>

                <p>
                  <span>a) Consentire la </span>
                  <b>navigazione sulla Piattaforma</b>
                  <span>
                    , essenzialmente mediante l’utilizzo di Cookies: si veda
                    l’Informativa Cookies.
                  </span>
                </p>

                <p>
                  <span>b) Verificare il </span>
                  <b>possesso della maggiore età dei Learners</b>
                  <span>
                    , ai fini di consentire ai Learners Minorenni di registrarsi
                    sulla Piattaforma e fruire della Licenza, mediante previa e
                    specifica autorizzazione del Genitore:{" "}
                  </span>
                  <b>obbligo legale</b>
                  <span>
                    , in capo a WeSchool, di verificare l’età e la conseguente
                    capacità d’agire dei Learners che intendono accedere alla
                    Piattaforma.
                  </span>
                </p>

                <p>
                  <span>c) Consentire la </span>
                  <b>registrazione a un Gruppo sulla Piattaforma</b>
                  <span>
                    {" "}
                    agli Utenti e la creazione di un account personale:{" "}
                  </span>
                  <b>sottoscrizione delle Condizioni Generali</b>
                  <span>
                    {" "}
                    da parte degli Educators, dei Learners Maggiorenni e del
                    Genitore, in nome e per conto proprio e del Learner
                    Minorenne, e sulle connesse misure precontrattuali proposte
                    da WeSchool.
                  </span>
                </p>

                <p>
                  <span>d) Fornire la </span>
                  <b>Licenza</b>
                  <span> a favore degli Utenti: </span>
                  <b>sottoscrizione delle Condizioni Generali</b>
                  <span>
                    {" "}
                    da parte degli Educators, dei Learners Maggiorenni e del
                    Genitore, in nome e per conto proprio e del Learner
                    Minorenne.
                  </span>
                </p>

                <p>
                  <span>e) Consentire agli Utenti di eseguire gli </span>
                  <b>eventuali pagamenti</b>
                  <span>
                    , previa identificazione, previsti sulla Piattaforma:{" "}
                  </span>
                  <b>sottoscrizione delle Condizioni Generali</b>
                  <span>
                    {" "}
                    da parte degli Educators, dei Learners Maggiorenni e del
                    Genitore, in nome e per conto proprio e del Learner
                    Minorenne.
                  </span>
                </p>

                <p>
                  <span>f) Verificare e accertare eventuali </span>
                  <b>condotte illecite o fraudolente sulla Piattaforma</b>
                  <span> da parte degli Utenti: </span>
                  <b>sottoscrizione delle Condizioni Generali</b>
                  <span>
                    {" "}
                    da parte degli Educators, dei Learners Maggiorenni e del
                    Genitore, in nome e per conto proprio e del Learner
                    Minorenne, e connesse misure precontrattuali proposte da
                    WeSchool e, qualora sia necessario od opportuno il
                    coinvolgimento di autorità terze, eventuale{" "}
                  </span>
                  <b>obbligo legale </b>
                  <span>
                    di WeSchool di collaborare con le autorità competenti nello
                    svolgimento di verifiche o accertamenti sugli atti e i fatti
                    illeciti o fraudolenti compiuti sulla Piattaforma.
                  </span>
                </p>

                <p>
                  <span>g) Consentire agli Utenti di </span>
                  <b>fruire dei Servizi</b>
                  <span> che sono offerti da WeSchool come Educator: </span>
                  <b>sottoscrizione delle Condizioni Generali</b>
                  <span> e del </span>
                  <b>Contratto Acquisto Servizi</b>
                  <span>
                    {" "}
                    relativo ai Servizi di cui WeSchool è Educator da parte dei
                    Learners Maggiorenni e del Genitore, in nome e per conto
                    proprio e del Learner Minorenne.
                  </span>
                </p>

                <p>
                  h) <span>Svolgere </span>
                  <b>attività di Marketing Diretto da parte di WeSchool</b>
                  <span>: </span>
                  <b>legittimo interesse</b>
                  <span>
                    {" "}
                    di WeSchool costituito dal beneficio che WeSchool può
                    ottenere dall’invio di comunicazioni commerciali relative
                    alla Licenza (ivi inclusi consigli sull’utilizzo della
                    Piattaforma e sulla disponibilità di contenuti sulla
                    Piattaforma aventi natura commerciale), ai Servizi offerti
                    da WeSchool in qualità di Educator o a servizi, prodotti e
                    iniziative simili alla Licenza, ai Servizi e alla
                    Piattaforma, coinvolgendo gli Utenti nel percorso di
                    crescita e sviluppo di WeSchool, che quest’ultima ritiene
                    essere prevalente rispetto al diritto alla riservatezza
                    degli Utenti.{" "}
                    <span style={{ textDecoration: "underline" }}>L’</span>
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>opposizione al presente Trattamento</span>
                  </span>
                  <span>
                    {" "}
                    può essere esercitata dall’Educator, dal Learner Maggiorenne
                    o dal Genitore (in proprio nonché in nome e per conto del
                    Learner Minorenne) in ogni tempo, e{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>senza alcuna motivazione</span>
                  </span>
                  <span>
                    , seguendo le indicazioni di cui al punto 13 della presente
                    Informativa.
                  </span>
                </p>

                <p>
                  <span>i) Previo facoltativo consenso, svolgere la </span>
                  <b>Profilazione</b>
                  <span>, di cui al successivo punto 11: </span>
                  <b>consenso</b>
                  <span>
                    {" "}
                    libero, specifico, informato ed inequivocabile
                    dell’Educator, del Learner Maggiorenne e/o del Genitore (in
                    nome e per conto del Learner Minorenne), espresso mediante
                    dichiarazione o azione positiva (e.g.{" "}
                  </span>
                  <i>
                    <span>flag</span>
                  </i>
                  <span>) sulla Piattaforma. La </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>revoca del consenso</span>
                  </span>
                  <span>
                    {" "}
                    può essere esercitata dall’Educator, del Learner Maggiorenne
                    e/o del Genitore in ogni tempo, seguendo le indicazioni di
                    cui al punto 12 lett. a) della presente Informativa.
                  </span>
                </p>

                <p>
                  <span>j) Previo facoltativo consenso, svolgere </span>
                  <b>attività di </b>
                  <b>
                    <i>marketing</i>
                  </b>
                  <b> per conto di soggetti terzi</b>
                  <span>
                    {" "}
                    relative a prodotti e servizi digitali utili ai Learners:{" "}
                  </span>
                  <b>consenso</b>
                  <span>
                    {" "}
                    libero, specifico, informato ed inequivocabile
                    dell’Educator, del Learner Maggiorenne e/o del Genitore (in
                    nome e per conto del Learner Minorenne), espresso mediante
                    dichiarazione o azione positiva (e.g.{" "}
                  </span>
                  <i>
                    <span>flag</span>
                  </i>
                  <span>) sulla Piattaforma. La </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>revoca del consenso</span>
                  </span>
                  <span>
                    {" "}
                    può essere esercitata dall’Educator, dal Learner Maggiorenne
                    o dal Genitore in ogni tempo, seguendo le indicazioni di cui
                    al punto 12 lett. a) della presente Informativa.
                  </span>
                </p>

                <p>
                  <span>
                    k) Eseguire analisi statistiche, ricerche di mercato,{" "}
                  </span>
                  <b>migliorare l’uso della Licenza e dei Servizi</b>
                  <span> da parte degli Utenti: </span>
                  <b>legittimo interesse</b>
                  <span>
                    {" "}
                    di WeSchool costituito dal beneficio che WeSchool può
                    ottenere dall’offerta della Licenza e di Servizi più affini
                    alle preferenze manifestate dai propri Utenti durante la
                    navigazione della Piattaforma e la fruizione della Licenza e
                    dei Servizi che WeSchool ritiene essere prevalente rispetto
                    al diritto alla riservatezza degli Utenti. L’
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>opposizione al presente Trattamento</span>
                  </span>
                  <span>
                    {" "}
                    (qualora non eseguito con dati anonimi) può essere
                    esercitata dall’Educator, dal Learner Maggiorenne o dal
                    Genitore (in nome e per conto del Learner Minorenne) in ogni
                    tempo seguendo le indicazioni di cui al punto 12 lett. f)
                    della presente Informativa.
                  </span>
                </p>

                <p>
                  <span>l) Adempiere agli </span>
                  <b>obblighi di legge</b>
                  <span>
                    {" "}
                    e consentire a WeSchool di accertare, esercitare e difendere
                    i propri{" "}
                  </span>
                  <b>diritti</b>
                  <span>
                    {" "}
                    in giudizio o dinanzi a qualsiasi altra autorità competente:
                    i){" "}
                  </span>
                  <b>legittimo interesse</b>
                  <span>
                    {" "}
                    di WeSchool, consistente nel beneficio che WeSchool può
                    ottenere nel proteggere la propria posizione giuridica, i
                    propri diritti e i propri interessi, che WeSchool considera
                    prevalenti rispetto al diritto alla riservatezza degli
                    Utenti; e ii) laddove il coinvolgimento di autorità terze
                    sia necessario o opportuno, in base all’
                  </span>
                  <b>obbligo legale</b>
                  <span>
                    {" "}
                    di WeSchool di collaborare con le autorità competenti nello
                    svolgimento di indagini relative all’esecuzione,
                    all’interpretazione e all’adempimento delle Condizioni
                    Generali.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modalita"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                7. Modalità di raccolta dei Dati Personali
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Dati Personali inseriti nella Piattaforma dagli Utenti
                <br />
                b) Dati Personali comunicati da soggetti terzi autorizzati o
                comunque collegati agli Utenti, come le Organizzazioni
                <br />
                c) Dati Personali espressamente comunicati a WeSchool dagli
                Utenti
                <br />
                d) Dati Personali raccolti automaticamente dalla Piattaforma
                (tramite Cookies)
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    WeSchool raccoglie i Dati Personali con le seguenti
                    modalità:
                  </span>
                </p>

                <p>
                  <span>a) </span>
                  <span>
                    Dati Personali inseriti nella Piattaforma dagli Utenti: sono
                    i Dati Personali forniti, a titolo esemplificativo e non
                    esaustivo, per navigare, registrarsi e accedere alla
                    Piattaforme e fruire della Licenza.
                  </span>
                </p>

                <p>
                  <span>
                    b) Dati Personali comunicati a WeSchool da soggetti terzi
                    autorizzati da, o comunque collegati a, gli Utenti: sono i
                    Dati Personali degli Utenti che vengono comunicati a
                    WeSchool da altri Utenti, come ad esempio dagli Educators
                    per la creazione e la gestione dei propri Gruppi di
                    Learners, oppure dalle Scuole, dalle Università (che sulla
                    base degli Identificativi Università comunicano a WeSchool i
                    Dati Personali dei propri Utenti) o dalle altre
                    Organizzazioni di riferimento degli Utenti, prima della
                    sottoscrizione delle Condizioni Generali da parte di ciascun
                    Utente, oppure dai fornitori dei registri elettronici delle
                    Scuole, durante il periodo di validità delle Condizioni
                    Generali.
                  </span>
                </p>

                <p>
                  <span>
                    c) Dati Personali espressamente comunicati a WeSchool dagli
                    Utenti in modalità{" "}
                  </span>
                  <i>
                    <span>offline</span>
                  </i>
                  <span>
                    : sono i Dati Personali forniti, a titolo esemplificativo,
                    al servizio clienti di WeSchool (in caso di richiesta di
                    contatto) ovvero raccolti in occasione di eventi e/o
                    iniziative organizzate da WeSchool.
                  </span>
                </p>

                <p>
                  <span>
                    d) Dati Personali raccolti automaticamente dalla
                    Piattaforma: sono i Dati Personali raccolti automaticamente
                    dalla Piattaforma, come ad esempio user agent o indirizzi IP
                    degli Utenti, anche attraverso, i Cookies (per ulteriori
                    informazioni, WeSchool raccomanda la lettura
                    dell’Informativa Cookies qui di seguito riportata).&nbsp;
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="categorie-destinatari"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                8. Eventuali categorie di destinatari dei Dati Personali
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Dipendenti, collaboratori, altri Utenti, Organizzazioni di
                riferimento degli Utenti, terzi fornitori di servizi IT, gestori
                dei servizi di pagamento, autorità competenti, terzi tecnici e
                commerciali.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    I Dati Personali sono trattati da WeSchool e/o da soggetti
                    terzi, selezionati per affidabilità e competenza, ed a cui
                    possono essere comunicati in quanto ciò sia necessario od
                    opportuno, purché all’interno dell’Italia o dello SEE. In
                    particolare, WeSchool informa gli Utenti che i Dati
                    Personali possono essere trattati da, e/o comunicati a:
                  </span>
                </p>

                <p>
                  <span>a) dipendenti e/o collaboratori di WeSchool;</span>
                </p>

                <p>
                  <span>b) </span>
                  <span>
                    altri Utenti che partecipano al medesimo Gruppo dell’Utente
                    a cui i Dati Personali si riferiscono;
                  </span>
                </p>

                <p>
                  <span>c) Organizzazioni di riferimento degli Utenti;</span>
                </p>

                <p>
                  <span>
                    d) soggetti terzi fornitori di servizi necessari a garantire
                    il funzionamento della Piattaforma (a titolo
                    esemplificativo: società che si occupa dei servizi di
                    hosting);
                  </span>
                </p>

                <p>
                  <span>
                    e) soggetti terzi fornitori di servizi necessari per la
                    fruizione della Licenza e dei Servizi (e.g. servizi
                    collegati ai Servizi);
                  </span>
                </p>

                <p>
                  <span>f) gestori dei servizi di pagamento;</span>
                </p>

                <p>
                  <span>
                    g) soggetti terzi che forniscono servizi di analisi dei dati
                    di navigazione della Piattaforma;
                  </span>
                </p>

                <p>
                  <span>
                    h) autorità competenti a svolgere verifiche o accertamenti
                    su atti o fatti illecito o fraudolenti commessi sulla
                    Piattaforma;
                  </span>
                </p>

                <p>
                  i){" "}
                  <span>
                    soggetti terzi che forniscono servizi automatici di invio di
                    newsletter e/o qualsiasi altro tipo di comunicazione
                    commerciale, servizi di marketing, promozione;
                  </span>
                </p>

                <p>
                  j){" "}
                  <span>
                    previo facoltativo consenso dell’Utente (in caso di Learner
                    Minorenne, da parte del Genitore), soggetti terzi che
                    forniscono la Profilazione;
                  </span>
                </p>

                <p>
                  <span>
                    k) previo facoltativo consenso dell’Utente (in caso di
                    Learner Minorenne, da parte del Genitore), soggetti terzi
                    che inviano comunicazioni commerciali e promozionali
                    relativi a propri prodotti e servizi, anche previa
                    Profilazione, e/o che effettuano analisi statistiche o
                    ricerche di mercato sugli Utenti.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="trasferimento"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                8. Trasferimento dei Dati Personali al di fuori dello SEE (ivi
                inclusa l’UE9
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Sì.
                <br />
                <br />
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    Per quanto possibile, WeSchool evita il trasferimento dei
                    Dati Personali al di fuori dello SEE. Tuttavia,
                    limitatamente ai servizi di seguito indicati, alcuni
                    fornitori di servizi di WeSchool possono trasferire i Dati
                    Personali nei Paesi extra SEE di seguito indicati:
                  </span>
                </p>

                <p>
                  <span>a) </span>
                  <span>
                    Regno Unito: in virtù della decisione di adeguatezza del
                    Regno Unito emessa dalla Commissione dell’Unione Europea il
                    28 giugno 2021.
                  </span>
                </p>

                <p>
                  i){" "}
                  <span>
                    [Limitatamente all’invio di newsletter] Three Hearts Digital
                    Ltd. (Email Octopus), con sede legale in Keltan House, 115
                    Mare Street, London, E8 4RU, Regno Unito.
                  </span>
                </p>

                <p>
                  <span>b) </span>
                  <span>
                    Stati Uniti: in attesa del nuovo “Trans-Atlantic Data
                    Privacy Agreement”, annunciato dalla Commissione Europea e
                    dal Presidente degli Stati Uniti il 25 marzo 2022, il
                    trasferimento dei Dati Personali tra UE e U.S.A. si basa
                    sulle garanzie attualmente disponibili ai sensi
                    dell’articolo 44 del GDPR proposte dai fornitori di servizi
                    americani (quali clausole standard di protezione dei dati o
                    norme vincolanti d’impresa) e su misure aggiuntive volte a
                    proteggere i Dati Personali adottate dai fornitori di
                    servizi americani a seguito di raccomandazioni delle
                    autorità competenti.
                  </span>
                </p>

                <p>
                  ii){" "}
                  <span>
                    [Limitatamente all’invio di push-notification per l’App]
                    Google Inc., con sede legale in 1600 Amphitheatre Parkway
                    Mountain View, CA 94043, Stati Uniti;&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    iii) [Limitatamente all’invio di push-notification per
                    l’App] Apple Inc., con sede legale in 1 Apple Park Way
                    Cupertino, California, Stati Uniti;
                  </span>
                </p>

                <p>
                  <span>
                    iv) [Limitatamente ai servizi di pagamento e controlli
                    anti-frode] Stripe Inc., con sede legale in Corporation
                    Trust Center, 1209 Orange Street, Wilmington, New Castle, DE
                    19801, USA;
                  </span>
                </p>

                <p>
                  <span>
                    v) [Limitatamente al monitoraggio errori della Piattaforma
                    sia{" "}
                  </span>
                  <i>
                    <span>frontend</span>
                  </i>
                  <span> sia </span>
                  <i>
                    <span>backend</span>
                  </i>
                  <span>
                    ] Sentry Inc., con sede legale in 45 Fremont Street, 8th
                    Floor, San Francisco, CA 94105;
                  </span>
                </p>

                <p>
                  <span>vi) [Limitatamente al monitoraggio delle </span>
                  <i>
                    <span>performance</span>
                  </i>
                  <span> del </span>
                  <i>
                    <span>backend</span>
                  </i>
                  <span>
                    {" "}
                    della Piattaforma] New Relic Inc., con sede legale in 188
                    Spear Street, Suite 1200, San Francisco, CA 94105;
                  </span>
                </p>

                <p>
                  <span>vii) [Limitatamente al servizio </span>
                  <i>
                    <span>customer care</span>
                  </i>
                  <span>
                    ] Zendesk Inc., San Francisco Bay Area, California;
                  </span>
                </p>

                <p>
                  <span>
                    viii) [Limitatamente alle notifiche push per l’app mobile]
                    OneSignal Inc., 2194 Esperanca Avenue Santa Clara, CA 95054;
                  </span>
                </p>

                <p>
                  <span>
                    ix) [Limitatamente agli script di tracciamento delle
                    campagne sulla Piattaforma] Meta Platforms Inc., con sede
                    legale in Facebook 1 Hacker Way Menlo Park, CA 94025.
                  </span>
                </p>

                <p>
                  <span>
                    Ulteriori informazioni su queste misure di protezione
                    possono essere richieste a WeSchool.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="conservazione"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                10. Periodo di conservazione dei Dati Personali (o criteri per
                la determinazione)
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Cookies: si veda Informativa Cookies;
                <br />
                b) Dati Personali per la verifica della maggiore età: 3
                settimane dalla richiesta di registrazione
                <br />
                c) Dati Personali per registrazione dei Gruppi, l’utilizzo della
                Licenza, l’esecuzione degli eventuali pagamenti e i connessi
                controlli: (i) qualora WeSchool sia Titolare, durata delle
                Condizioni Generali e per un periodo di 6 mesi dopo la
                cessazione delle Condizioni Generali; o (ii) qualora la Scuola o
                l’Università sia Titolare, per il periodo di tempo indicato
                dalla Scuola o dall’Università
                <br />
                d) Dati Personali per la fruizione dei Servizi: (i) qualora
                WeSchool (come Educator) sia Titolare, durata delle Condizioni
                Generali e del Contratto Acquisto Servizi e per un periodo di 6
                mesi dopo la cessazione delle Condizioni Generali e del
                Contratto Acquisto Servizi; (ii) qualora l’Educator (diverso da
                WeSchool) sia Titolare, per il periodo di tempo indicato
                dall’Educator
                <br />
                e) Dati Personali per finalità commerciali: per un periodo di 12
                mesi dall’ultima interazione dell’Utente (in caso di
                Profilazione) o di 24 mesi (in mancanza di Profilazione)
                <br />
                f) Dati Personali per la Profilazione e il miglioramento della
                Licenza e dei Servizi: per un periodo di 12 mesi dalla raccolta
                <br />
                g) Dati Personali per obblighi legali o tutela giudiziaria: 10
                anni dalla raccolta
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    I Dati Personali sono conservati da WeSchool per il periodo
                    di tempo strettamente necessario al conseguimento delle
                    finalità per le quali sono stati raccolti, come indicato al
                    punto 5 che precede. In particolare, salvo esercizio del
                    diritto di revoca del consenso di cui al successivo punto 12
                    lett. a) o del diritto di opposizione di cui ai successivi
                    punti 12 lett. f) e 13, WeSchool conserva i Dati Personali
                    per i seguenti termini:
                  </span>
                </p>

                <p>
                  <span>a) </span>
                  <span>
                    Cookies: per la finalità di cui al punto 5 lettera a), si
                    raccomanda la lettera dell’Informativa Cookies di cui ai
                    successivi punti 14 e 15;
                  </span>
                </p>

                <p>
                  <span>
                    b) Dati Personali per la verifica della maggiore età: per la
                    finalità di cui al punto 5 lettera b), in caso di mancata
                    autorizzazione da parte del Genitore entro{" "}
                  </span>
                  <b>3 (tre) settimane</b>
                  <span>
                    {" "}
                    dalla richiesta di registrazione del Learner Minorenne, i
                    Dati Personali del Learner Minorenne vengono cancellati da
                    WeSchool. In caso di autorizzazione da parte del Genitore
                    entro tale termine, si applica quanto previsto dalla
                    successiva lettera c);
                  </span>
                </p>

                <p>
                  <span>
                    c) Dati Personali per la registrazione nei Gruppi,
                    l’utilizzo della Licenza e l’esecuzione degli eventuali
                    pagamenti e connesse verifiche: per le finalità di cui al
                    punto 5 lettere c), d), e) e f), occorre distinguere come
                    segue (i) qualora WeSchool sia Titolare ai sensi di quanto
                    previsto dal precedente punto 2 lettera a) punto ii), per la{" "}
                  </span>
                  <b>
                    durata delle Condizioni Generali e per i successivi 6 mesi
                  </b>
                  <span>
                    ; o (ii) qualora la Scuola o l’Università sia Titolare ai
                    sensi di quanto previsto dal precedente punto 2 lettera a)
                    punto i), per il periodo di tempo indicato nella relativa
                    informativa privacy e comunicato dalla Scuola o
                    dall’Università a WeSchool;
                  </span>
                </p>

                <p>
                  <span>
                    d) Dati Personali per la fruizione dei Servizi offerti da
                    WeSchool come Educator: per le finalità di cui al punto 5
                    lettera g), occorre distinguere come segue (i), qualora
                    WeSchool sia Titolare ai sensi di quanto previsto dal
                    precedente punto 2 lettera b) punto i), per la{" "}
                  </span>
                  <b>
                    durata delle Condizioni Generali e del Contratto Acquisto
                    Servizi e per i successivi 6 mesi{" "}
                  </b>
                  <span>
                    o (ii) qualora l’Educator sia Titolare ai sensi di quanto
                    previsto dal precedente punto 2 lettera b) punto ii), per il
                    periodo di tempo indicato nella relativa informativa privacy
                    e comunicato dall’Educator al Learner;
                  </span>
                </p>

                <p>
                  <span>
                    e) Dati Personali per il Marketing Diretto di WeSchool o le
                    comunicazioni commerciali di soggetti terzi: per le finalità
                    di cui al punto 5 lettere h) e j), per un periodo di{" "}
                  </span>
                  <b>12 mesi</b>
                  <span>
                    {" "}
                    (in caso di Profilazione associata alle attività di
                    marketing previo consenso alla Profilazione) o di{" "}
                  </span>
                  <b>24 mesi</b>
                  <span>
                    {" "}
                    (in mancanza di Profilazione associata alle attività di
                    marketing in mancanza di consenso alla Profilazione),
                    successivi all’ultima interazione con WeSchool da parte
                    dell’Utente, idonea a dimostrare un interesse alla ricezione
                    di comunicazioni relative a WeSchool (come indicato al punto
                    5 lettera h) e/o a soggetti terzi (come descritti al punto 5
                    lettera j), come ad esempio la raccolta del consenso,
                    l’apertura di newsletter o la partecipazione a iniziative
                    promosse da WeSchool o dai soggetti terzi;&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    f) Dati Personali per la Profilazione e per il miglioramento
                    della Licenza e dei Servizi: per le finalità di cui al punto
                    5 lettere i) e k) (quest’ultima unicamente in caso di
                    utilizzo di Dati Personali e non di dati anonimi) per un
                    periodo di{" "}
                  </span>
                  <b>12 mesi</b>
                  <span>
                    {" "}
                    successivi alla raccolta dei Dati Personali da parte di
                    WeSchool;
                  </span>
                </p>

                <p>
                  <span>
                    g) Dati Personali per l’adempimento di obblighi di legge o
                    la difesa di diritti: per la finalità di cui al punto 5
                    lettera l), per il periodo massimo di{" "}
                  </span>
                  <b>10 anni</b>
                  <span>
                    {" "}
                    dalla raccolta o per il maggior termine di durata di un
                    eventuale procedimento giudiziario.&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    Decorsi tali termini, WeSchool provvederà alla cancellazione
                    automatica dei Dati Personali raccolti, ovvero alla loro
                    trasformazione in forma anonima in maniera irreversibile.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="profilazione"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                11. Profilazione
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Trattamento automatizzato dei Dati Personali degli Utenti, volti
                a rivelare le preferenze degli Utenti manifestate sulla
                Piattaforma, per finalità valutative e/o predittive.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    La Profilazione operata da WeSchool presenta le seguenti
                    caratteristiche:
                  </span>
                </p>

                <p>
                  <span>a) </span>
                  <span>
                    contesto: la Profilazione può essere associata sia
                    all’erogazione della Licenza o dei Servizi sulla Piattaforma
                    da parte di WeSchool (mediante, ad esempio, l’invio di
                    Comunicazioni Didattiche personalizzate) sia allo
                    svolgimento delle attività di{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    relative a WeSchool e/o ai soggetti terzi, come meglio
                    descritto al precedente punto 5. Pertanto, qualora l’Utente
                    acceda alla Piattaforma esclusivamente per fruire della
                    Licenza e dei Servizi e si sia opposto alle attività di
                    Marketing Diretto di WeSchool (punto 5 lett. h) o non abbia
                    aderito alle attività di{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    dei soggetti terzi (punto 5 lett. j), la Profilazione sarà
                    unicamente svolta in connessione all’erogazione della
                    Licenza e dei Servizi per migliorare ad esempio il contenuto
                    delle Comunicazioni Didattiche;
                  </span>
                </p>

                <p>
                  <span>
                    b) oggetto: informazioni sugli Utenti relative, ad esempio,
                    all’età, al ciclo scolastico di appartenenza, alla
                    ubicazione dell’Organizzazione di riferimento (laddove
                    disponibile), alla tipologia di contenuti fruiti nell’ambito
                    dei Servizi (e.g. corsi didattici);&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    c) base giuridica: consenso espresso mediante azione
                    positiva (flag) sulla Piattaforma da parte degli Educators,
                    dei Learners Maggiorenni e dei Genitori, in nome e per conto
                    dei Learners Minorenni;
                  </span>
                </p>

                <p>
                  <span>
                    d) logica del Trattamento: (i) analisi e valutazione delle
                    informazioni raccolte sugli Utenti di cui alla lettera b) su
                    base statistica tramite l’elaborazione e il confronto dei
                    Dati Personali di tutti gli Utenti della Piattaforma; e (ii)
                    classNameificazione degli Utenti all’interno di categorie
                    omogenee degli Utenti, per analizzare o fare previsioni
                    sulle loro possibili preferenze;&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    e) finalità: rendere più “personalizzati” sulle possibili
                    preferenze degli Utenti sia la Licenza e i Servizi sia le
                    attività di{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    e, pertanto, migliorare il grado di “soddisfazione” degli
                    Utenti sia nella fruizione della Licenza e dei Servizi
                    erogati sulla Piattaforma sia nella ricezione di
                    comunicazioni commerciali e promozionali di WeSchool e/o dei
                    soggetti terzi. La Profilazione è, quindi, volta a fare
                    previsioni sulle preferenze o sulle scelte future degli
                    Utenti, in base ai contenuti didattici della
                    Piattaforma;&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    f) effetti per gli Utenti: fruizione della Licenza e di
                    Servizi sulla Piattaforma o ricezione di comunicazioni
                    commerciali, in linea con le preferenze manifestate dagli
                    Utenti nella navigazione della Piattaforma e nell’utilizzo
                    dei Servizi.{" "}
                    <span style={{ textDecoration: "underline" }}>
                      In nessun modo
                    </span>
                    , la Profilazione di WeSchool: i) costituisce un processo
                    decisionale automatizzato da cui derivano effetti giuridici
                    o similmente significativi per gli Utenti; ii) pregiudica il
                    comportamento e le scelte formative o di acquisto degli
                    Utenti; iii) ha un impatto prolungato e permanente per gli
                    Utenti, considerato che i Dati Personali raccolti da
                    WeSchool possono essere autonomamente aggiornati in ogni
                    tempo dagli Utenti; nonché iv) non determina alcuna
                    discriminazione degli Utenti.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="diritti"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                12. Diritti dell&apos;utente
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Revoca del consenso, accesso ai Dati Personali, rettifica o
                integrazione dei Dati Personali, cancellazione dei Dati
                Personali, limitazione del Trattamento, opposizione al
                Trattamento, portabilità dei Dati Personali, data breach,
                reclamo alle autorità competenti
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    Ai sensi e per gli effetti del GDPR, per il periodo di cui
                    al punto 8 della presente Informativa, ciascun Utente, in
                    particolare il Genitore in proprio nonché in nome e per
                    conto Learner Minorenne, ha diritto di:
                  </span>
                </p>

                <p>
                  <span>a) </span>
                  <span>
                    con riferimento ai Trattamenti di cui al punto 5 lettere a),
                    i) e j),{" "}
                  </span>
                  <b>revocare il consenso</b>
                  <span>
                    {" "}
                    in qualsiasi momento senza pregiudicare la liceità del
                    Trattamento precedentemente eseguito, mediante invio di
                    un’e-mail a WeSchool ovvero esercitando l’opt-out nelle
                    comunicazioni commerciali di WeSchool (cfr. art. 7 del
                    GDPR);
                  </span>
                </p>

                <p>
                  b) <b>chiedere</b>
                  <span> a WeSchool l’</span>
                  <b>accesso</b>
                  <span>
                    {" "}
                    ai Dati Personali e alle informazioni del relativo
                    Trattamento ed eventuale copia in formato elettronico, salvo
                    specifica diversa richiesta dell’Utente (cfr. art. 15 del
                    GDPR);
                  </span>
                </p>

                <p>
                  c) <b>chiedere la rettifica e/o l’integrazione</b>
                  <span>
                    {" "}
                    dei Dati Personali, senza ingiustificato ritardo (cfr. art.
                    16 del GDPR);
                  </span>
                </p>

                <p>
                  <span>
                    d) per specifici motivi (ad esempio, trattamento illecito,
                    revoca del consenso, insussistenza della finalità del
                    Trattamento), chiedere la{" "}
                  </span>
                  <b>cancellazione dei Dati Personali</b>
                  <span>, senza ingiustificato ritardo, </span>
                  <span>mediante </span>
                  <i>
                    <span>click</span>
                  </i>
                  <span>
                    {" "}
                    sul tasto “Cancella per sempre” presente sul profilo
                    dell’Utente
                  </span>
                  <span>
                    , fermo restando che l’Utente può contattare WeSchool via
                    e-mail per maggiori dettagli (cfr. art. 17 del GDPR);
                  </span>
                </p>

                <p>
                  <span>
                    e) al ricorrere di specifiche ipotesi (ad esempio,
                    inesattezza dei Dati Personali, illiceità del Trattamento,
                    esercizio di un diritto in sede giudiziaria),{" "}
                  </span>
                  <b>chiedere la limitazione</b>
                  <span> del Trattamento (art. 18 del GDPR);</span>
                </p>

                <p>
                  <span>f) con riferimento </span>
                  <b>al Trattamento</b>
                  <span>
                    {" "}
                    di cui al punto 5 lettere k) (qualora non venissero usati
                    dati anonimi) e
                  </span>{" "}
                  <span>l), </span>
                  <b>opporsi</b>
                  <span>
                    {" "}
                    in qualsiasi momento al Trattamento dei Dati Personali,
                    mediante l’invio di un’e-mail a WeSchool (cfr. art. 21 del
                    GDPR);
                  </span>
                </p>

                <p>
                  <span>g) in caso di Trattamento automatizzato, </span>
                  <b>ricevere i Dati Personali in formato leggibile</b>
                  <span>
                    , ai fini della relativa comunicazione a un soggetto terzo,
                    ovvero, laddove tecnicamente fattibile, chiedere la
                    trasmissione dei Dati Personali da parte di WeSchool
                    direttamente a tale soggetto terzo (cd. diritto alla
                    portabilità dei Dati Personali – cfr. art. 20 del GDPR);
                  </span>
                </p>

                <p>
                  <span>h) essere </span>
                  <b>informato</b>
                  <span> da WeSchool senza ingiustificato ritardo di </span>
                  <b>eventuali violazioni o accessi non autorizzati</b>
                  <span>
                    {" "}
                    da parte di terzi ai propri sistemi contenenti i Dati
                    Personali (c.d.{" "}
                  </span>
                  <i>
                    <span>data breach </span>
                  </i>
                  <span>–</span> <span>cfr. art. 34 del GDPR);</span>
                </p>

                <p>
                  <span>i) proporre </span>
                  <b>reclamo all’autorità di controllo</b>
                  <span>
                    {" "}
                    del Paese UE in cui risiede, lavora ovvero in cui ritiene si
                    sia verificata la violazione dei propri diritti (cfr. art.
                    77 del GDPR);
                  </span>
                </p>

                <p>
                  <span>
                    Per ulteriori informazioni in merito ai termini e alle
                    condizioni per l’esercizio dei diritti riconosciuti
                    all’Utente, quest’ultimo può consultare il testo del GDPR UE
                    pubblicato al seguente{" "}
                    <a href="http://eur-lex.europa.eu/legal-content/IT/TXT/PDF/?uri=CELEX:32016R0679&amp;from=IT">
                      link
                    </a>
                    , ovvero contattare WeSchool nelle forme previste dal punto
                    2 della presente Informativa.{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="opposizione"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                13. Opposizione al Trattamento relativo al marketing diretto di
                WeSchool
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Diritto di opposizione alla ricezione di comunicazioni
                commerciali e promozionali relative a WeSchool.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    WeSchool evidenzia che ciascun Utente, in particolare il
                    Genitore in proprio nonché in nome e per conto Learner
                    Minorenne,{" "}
                  </span>
                  <span>ha diritto di opporsi</span>
                  <span>
                    {" "}
                    al Trattamento dei Dati Personali di cui al punto 5 lettera
                    h),{" "}
                  </span>
                  <span>in ogni tempo e senza alcuna motivazione</span>
                  <span>, inviando un’e-mail a </span>
                  <a href="mailto:support@weschool.com">
                    <span>support@weschool.com</span>
                  </a>
                  <span>
                    {" "}
                    o esercitando l’opt out presente nelle comunicazioni
                    commerciali e promozionali ricevute da WeSchool. In caso di
                    esercizio di tale diritto, WeSchool interromperà l’utilizzo
                    dei Dati Personali dell’Utente per le finalità di Marketing
                    Diretto della Licenza, dei Servizi offerti da WeSchool in
                    qualità di Educator o dei servizi, prodotti e iniziative
                    simili alla Licenza, ai Servizi e alla Piattaforma e non
                    svolgerà alcuna Profilazione associata alla propria attività
                    di Marketing Diretto.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cookies"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                14. Tipologia di cookies
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Cookies tecnici necessari alla navigazione
                <br />
                b) Cookies statistici
                <br />
                c) Cookies di marketing
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div id="cookieDeclaration" />
            </div>
          </div>
        </div>
      </div>
      <div
        id="accettazione-rifiuto-cookies"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                15. Accettazione e rinuncia ai Cookies
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Consenso, banner, accesso ai pannelli di controllo dei browser,
                servizio Your Online Choices
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    L’Utente potrà prestare il proprio consenso all’utilizzo dei
                    Cookies descritti nella precedente sezione 14 mediante le
                    modalità indicate sul banner pubblicato sulla Piattaforma e
                    alla precedente sezione 14.
                  </span>
                </p>

                <p>
                  <span>
                    Tuttavia, WeSchool specifica che la disattivazione dei
                    Cookies tecnici indicati nella precedente sezione 14 non è
                    possibile, in quanto impedirebbe la corretta navigazione
                    sulla Piattaforma e/o ne limiterebbe la fruibilità.&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    Ciò posto, con riferimento a tutti i Cookies della
                    Piattaforma, l’Utente potrà modificare le impostazioni
                    relative agli stessi, secondo le modalità previste nei
                    pannelli di controllo del browser utilizzato, quali a titolo
                    esemplificativo:
                  </span>
                </p>

                <ul>
                  <li aria-level={1}>
                    <span>Google-Chrome:&nbsp;</span>
                    <a href="https://support.google.com/chrome/answer/95647?hl=it-IT&amp;hlrm=fr&amp;hlrm=en">
                      <span>
                        https://support.google.com/chrome/answer/95647?hl=it-IT&amp;hlrm=fr&amp;hlrm=en
                      </span>
                    </a>
                    <span>&nbsp;</span>
                  </li>
                  <li aria-level={1}>
                    <span>Mozilla-Firefox:&nbsp;</span>
                    <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
                      <span>
                        https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
                      </span>
                    </a>
                    <span>&nbsp;</span>
                  </li>
                  <li aria-level={1}>
                    <span>Internet Explorer: </span>
                    <a href="https://support.microsoft.com/it-it/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                      <span>
                        https://support.microsoft.com/it-it/help/278835/how-to-delete-cookie-files-in-internet-explorer
                      </span>
                    </a>
                    <span>&nbsp;</span>
                  </li>
                  <li aria-level={1}>
                    <span>Microsoft Edge&nbsp;: </span>
                    <a href="https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies">
                      <span>
                        https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies
                      </span>
                    </a>
                    <span>&nbsp;</span>
                  </li>
                  <li aria-level={1}>
                    <span>Safari:&nbsp;</span>
                    <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">
                      <span>
                        https://support.apple.com/it-it/guide/safari/sfri11471/mac
                      </span>
                    </a>
                    <span>&nbsp;</span>
                  </li>
                </ul>

                <p>
                  <span>
                    Infine, con specifico riferimento ai Cookies di marketing,
                    oltre a poter utilizzare gli strumenti previsti dal browser
                    per attivare o disattivare i singoli Cookies, WeSchool
                    informa l’Utente che il sito&nbsp;
                  </span>
                  <a href="http://www.youronlinechoices.com/">
                    <span>www.youronlinechoices.com</span>
                  </a>
                  <span>
                    &nbsp;riporta l’elenco dei principali provider che lavorano
                    con i gestori dei siti web per raccogliere e utilizzare
                    informazioni utili alla fruizione della pubblicità
                    comportamentale. L’Utente può disattivare o attivare tutte
                    le società (ivi inclusa WeSchool) o, in alternativa,
                    regolare le proprie preferenze individualmente per ogni
                    società. Per procedere in tal senso, l’Utente può utilizzare
                    lo strumento presente nella pagina&nbsp;
                  </span>
                  <a href="http://www.youronlinechoices.com/it/le-tue-scelte">
                    <span>www.youronlinechoices.com/it/le-tue-scelte</span>
                  </a>
                  <span>
                    &nbsp;per controllare ogni preferenza sulla pubblicità
                    comportamentale prestata in occasione della navigazione in
                    Internet.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="international-privacy-policy"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                Our International Privacy Policy
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <a
                    href="https://www.weschool.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read our International Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItaPolicy;
