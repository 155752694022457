import React, { useState } from "react";
import { Icon } from "@arcadia/design-system";
import { useLocation } from "react-router";
import { useTranslation } from "src/js/translation";

import * as S from "./Search.styles";

const Search = ({
  search,
  setSearch
}: {
  search: string;
  setSearch: (value: string) => void;
}) => {
  const [searchValue, setSearchValue] = useState(search);
  const { translate } = useTranslation();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userUuid = queryParams.get("userUuid");

  return (
    <S.SearchBarWrapper
      onSubmit={e => {
        e.preventDefault();
        setSearch(searchValue);
      }}
    >
      <S.SearchBar
        type="text"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        placeholder={
          userUuid
            ? translate("record_scorm_tab_search_scorms")
            : translate("record_scorm_tab_search_user")
        }
      />
      <S.SearchButton type="submit">
        <Icon icon="search" width={16} height={16} initialViewbox />
      </S.SearchButton>
    </S.SearchBarWrapper>
  );
};

export default Search;
