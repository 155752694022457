import { Text } from "@arcadia/design-system";
import InfiniteScroll from "react-infinite-scroll-component";
import styled, { css } from "styled-components";

export { OptionsWrapper } from "../SpaceCard.styles";

export const CoverWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.violet[501]};
  border-radius: 4px;
  overflow: hidden;
  height: 32px;
  width: 32px;
  margin-right: 12px;
  img {
    object-fit: fill;
  }
`;

export const EllispedGroupName = styled(Text)`
  max-width: calc(100% - 244px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 767px) {
    max-width: calc(100% - 96px);
  }
`;

export const ScrollableArea = styled.div<{ isOverflowing: boolean }>`
  max-height: 392px;
  overflow: visible !important;

  > div > div {
    overflow: visible !important;
  }

  ${({ isOverflowing }) =>
    isOverflowing &&
    css`
      overflow: auto !important;

      > div > div {
        overflow: auto !important;
      }
    `}

  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 1rem !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3} !important;
    border-radius: 1rem !important;
    transition: 300ms ease-in-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.accent2} !important;
  }
`;

export const GroupList = styled(InfiniteScroll)`
  padding: 24px 20px 20px;
  display: flex;
  gap: 32px;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 24px 12px 20px 20px;
  }
`;

export const GroupCard = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
`;
