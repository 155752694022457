import colors from "@ds_themes/colors";

export const toast = {
  success: {
    background: colors.success[500],
    color: colors.grey[1000]
  },
  warning: {
    background: colors.yellow[500],
    color: colors.grey[50]
  },
  error: {
    background: colors.error[500],
    color: colors.grey[1000]
  },
  icon: {
    dark: {
      color: colors.grey[50]
    },
    light: {
      color: colors.grey[1000]
    }
  },
  button: {
    outline: colors.violet[700]
  }
};
