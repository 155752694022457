import styled from "styled-components";

export const RedeliveryBody = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const RedeliveryFromNow = styled.div`
  margin-left: 10px;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;
