import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";

export const useBoardModuleCreate = () => {
  const { translate } = useTranslation();
  const {
    BoardsStore,
    GroupStore: { groupId }
  } = useStores();
  const moduleTitle = translate("board_new_untitled");

  const handleCreateBoardModule = async () => {
    try {
      const board = await BoardsStore.addNewBoard(groupId, moduleTitle, {
        draft: true,
        editMode: true
      });
      navigateTo(
        createUrl("module_detail", {
          group_id: groupId,
          module_id: board.id
        })
      );
    } catch {
      showToastError({
        str: translate("board_creation_failed_error")
      });
    }
  };

  return { handleCreateBoardModule };
};
