import { Box, Button, Heading } from "@arcadia/design-system";
import React from "react";
import { GroupCreationModal } from "src/js/components/GroupCreationModal";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { EventDomain, GroupEvent } from "src/js/types";
import * as S from "./GroupsEmptyState.styles";

const GroupsEmptyState = () => {
  const {
    ModalStore: { openModal }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const openGroupCreationModal = () => {
    openModal(() => <GroupCreationModal />);
  };

  return (
    <S.Wrapper>
      <S.EmptyImage />
      <Heading level="5">
        {translate("groups_empty_page_no_groups_title")}
      </Heading>

      <Box>
        <Button
          type="button"
          variant="primary"
          id={UsetifulId.GroupsEmptyStateCreateNewGroupCta}
          theme={whiteTheme}
          onClick={() => {
            _trackEvent(EventDomain.Group, GroupEvent.GroupsEmptyState);
            openGroupCreationModal();
          }}
        >
          {translate("groups_empty_page_no_groups_create_group_cta")}
        </Button>
      </Box>
    </S.Wrapper>
  );
};

export default GroupsEmptyState;
