import { shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const DotsMenuButton = styled.button`
  all: unset;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
`;

export const DotsMenuListContainer = styled(motion.div)<{
  isOpen: boolean;
  alignRight: boolean;
  openFromTop: boolean;
}>`
  display: none;

  ${({ isOpen, alignRight, openFromTop }) =>
    isOpen &&
    css`
      display: block;
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      border: 1px solid ${({ theme }) => theme.colors.grey[800]};
      border-radius: 8px;
      top: ${openFromTop ? "unset" : "36px"};
      bottom: ${openFromTop ? "36px" : "unset"};
      width: max-content;
      max-width: 264px;
      position: absolute;
      box-shadow: ${shadow[200]};
      z-index: 2;
      right: ${alignRight ? "12px" : undefined};
      left: ${alignRight ? undefined : "12px"};
    `}
`;

export const DotsMenuList = styled.ul`
  min-width: 160px;
  margin: 0;
  list-style-type: none;
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const DotsMenuListItem = styled.li`
  white-space: nowrap;
  color: ${({ color, theme }) => color || theme.colors.grey[50]};
  padding: 8px;
  border-radius: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    color: ${({ theme }) => theme.primaryColor[500]};
  }
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.span`
  display: flex;
  width: 16px;
  margin-right: 8px;
`;

export const MobileBottomModalSheet = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  height: 100%;
`;
