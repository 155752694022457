const placeholderColors = [
  "#4BA013",
  "#1D2330",
  "#303A4F",
  "#43526F",
  "#4D0400",
  "#800600",
  "#B30900",
  "#204408",
  "#35720D",
  "#16004D",
  "#321F61",
  "#5333A1",
  "#7351C8",
  "#460615",
  "#750A22",
  "#A40E30",
  "#3B1116",
  "#621D25",
  "#8A2934",
  "#C43A4A",
  "#0C2E40",
  "#154C6B",
  "#1D6A95",
  "#261B32",
  "#3F2C53",
  "#583E74",
  "#1E321B",
  "#32532D",
  "#46743E",
  "#5A9550",
  "#445412",
  "#667E1B",
  "#89A824",
  "#233843",
  "#355464",
  "#502216",
  "#588DA7",
  "#793320",
  "#C95536"
];
export default placeholderColors;
