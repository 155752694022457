import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { Button } from "@arcadia/design-system";
import { Translate } from "src/js/translation/TranslationProvider";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import { modalMessage } from "../models/modalMessageModel";
import { __ } from "../modules/localization";

const SingleEmailForm = withTheme(
  withBackbone(
    createReactClass({
      form: "#form-email",
      getDefaultProps() {
        return {
          callback: null,
          precompiledMail: null,
          text: "",
          ajaxUrl: "",
          method: "",
          successMessages: "",
          isModal: false,
          className: "",
          buttonLabel: __("invia")
        };
      },
      getInitialState() {
        return {};
      },
      componentDidMount() {
        const self = this;
        $(self.form).on("submit", e => {
          e.preventDefault();
        });
        self.initValidation();
      },

      initValidation() {
        const self = this;
        $.validate({
          form: self.form,
          borderColorOnError: "",
          validateOnBlur: true,
          onError() {
            showToastError({
              str: __("Attenzione, verificare i dati inseriti")
            });
          },
          onSuccess() {
            self.sendToMail();
          }
        });
      },
      sendToMail() {
        const self = this;
        const email = $(`${self.form} #email`).val();
        $.ajax({
          type: self.props.method,
          dataType: "json",
          url: self.props.ajaxUrl,
          data: {
            email
          },
          success() {
            const text = self.props.successMessages;
            showToastSuccess({ str: text });
            if (self.props.isModal) {
              modalMessage.hide();
            }
          },
          error(data) {
            const errorMsg = extractErrorMessage(
              data.responseJSON,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
            if (self.props.isModal) {
              modalMessage.hide();
            }
          }
        });
      },
      render() {
        const self = this;
        const { greyTheme } = this.props.theme;
        const input = (
          <div className="form-group margin-top-20 single-email-wrapper">
            <label
              htmlFor="email"
              className="uppercase margin-right-15 margin-bottom-xs-20"
            >
              {__("email")}
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control light"
              data-validation="email"
              defaultValue={self.props.precompiledMail}
              data-validation-error-msg={__("Inserire una mail valida")}
              placeholder={__("registration_email_label")}
            />
          </div>
        );
        const buttons = (
          <div className="margin-top-20 register-button">
            <Button type="submit" theme={greyTheme} variant="primary">
              <Translate text={self.props.buttonLabel} />
            </Button>
          </div>
        );
        return (
          <div className={self.props.className}>
            <p>{self.props.text}</p>
            <form
              id="form-email"
              className="form-single-email"
              role="form"
              ref="formEmail"
              noValidate
            >
              {input}
              {buttons}
            </form>
          </div>
        );
      }
    })
  )
);

export default SingleEmailForm;
