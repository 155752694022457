/* eslint-disable no-restricted-globals */
import { Button, Heading, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import HeaderClean from "src/js/components/layout/HeaderClean/HeaderClean";
import { useStores } from "src/js/hooks";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import createUrl from "src/js/modules/routing";
import { Translate } from "src/js/translation/TranslationProvider";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import * as S from "./Whiteboard.styles";

const Whiteboard = () => {
  const { GroupStore, WhiteboardStore } = useStores();

  const { activeGroup, userIsTeacher } = GroupStore;
  const { whiteboardUrl } = WhiteboardStore;

  const { whiteTheme } = useTheme();
  const mobile = getBreakpoint() === "smartphone";

  useEffect(() => {
    RealTimeDispatcher.on("whiteboard_deactivated_event", () => {
      WhiteboardStore.setWhiteboardUrl(null);
    });
    return () => {
      RealTimeDispatcher.off("whiteboard_deactivated_event");
    };
  });

  if (!activeGroup) return null;

  const exitButton = userIsTeacher ? (
    <Button
      theme={whiteTheme}
      variant="secondary"
      fullWidth={mobile}
      onClick={() => {
        WhiteboardStore.closeWhiteboard(activeGroup.id);
      }}
    >
      <Translate text="whiteboard_close_teacher_button" />
    </Button>
  ) : (
    <Button
      theme={whiteTheme}
      variant="secondary"
      fullWidth={mobile}
      onClick={() => {
        navigateTo(createUrl("wall", { group_id: activeGroup.id }));
      }}
    >
      <Translate text="whiteboard_exit_student_button" />
    </Button>
  );

  if (!whiteboardUrl && !userIsTeacher)
    return (
      <S.NoWhiteboardContainer>
        <S.NoWhiteboardImage />
        <S.NoWhiteboardMessage>
          <S.MessageTitle>
            <Heading level="3">
              <Translate text="whiteboard_disabled_student_title" />
            </Heading>
          </S.MessageTitle>
        </S.NoWhiteboardMessage>
      </S.NoWhiteboardContainer>
    );

  const content = whiteboardUrl ? (
    <>
      <S.HeaderWrapper>
        <HeaderClean
          pageTitle="whiteboard"
          backFunction={() => {
            navigateTo(createUrl("wall", { group_id: activeGroup.id }));
          }}
        />
      </S.HeaderWrapper>

      <S.PageContainer>
        <S.IframeContainer>
          <S.Iframe
            frameBorder="0"
            src={whiteboardUrl}
            allow="camera; microphone; display-capture; autoplay; fullscreen"
            allowFullScreen
          />
          <S.ButtonFooterContainer>{exitButton}</S.ButtonFooterContainer>
        </S.IframeContainer>
      </S.PageContainer>
    </>
  ) : (
    <S.NoWhiteboardContainer>
      <S.NoWhiteboardImage>
        <Icon
          icon="illustration-activateWhiteboard"
          width="333"
          height="235"
          viewbox="0 0 333 235"
        />
      </S.NoWhiteboardImage>
      <S.NoWhiteboardMessage>
        <S.MessageTitle>
          <Heading level="3">
            <Translate text="whiteboard_disabled_teacher_title" />
          </Heading>
        </S.MessageTitle>
        <S.MessageSubtitle>
          <Text type="headerTitle">
            <Translate text="whiteboard_disabled_start_and_invite_subtitle" />
          </Text>
        </S.MessageSubtitle>
        <Button
          theme={whiteTheme}
          variant="primary"
          fullWidth={mobile}
          onClick={() => {
            WhiteboardStore.startWhiteboard(activeGroup.id);
          }}
        >
          <Translate text="whiteboard_start_teacher_button" />
        </Button>
      </S.NoWhiteboardMessage>
    </S.NoWhiteboardContainer>
  );

  return content;
};

export default observer(Whiteboard);
