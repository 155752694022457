import {
  addReactionToEntity,
  deleteReaction
} from "src/js/repository/reactionRepository";
import {
  ReactableEntity,
  Reaction,
  ReactionType
} from "src/js/types/models/Reaction";

const useReactions = () => {
  const removeReaction = async (reactionId: string) => {
    return deleteReaction({ reactionId });
  };

  const addReaction = async (
    reactableEntity: ReactableEntity,
    reactedId: string,
    reactionType: ReactionType,
    currentSelected?: Reaction
  ) => {
    if (currentSelected) {
      await removeReaction(currentSelected.id);
      return addReactionToEntity({ reactableEntity, reactedId, reactionType });
    }
    return addReactionToEntity({
      reactableEntity,
      reactedId,
      reactionType
    }).then(reaction => {
      return reaction;
    });
  };

  return {
    addReaction,
    removeReaction
  };
};

export default useReactions;
