export const WeSchoolRoutes = {
  App: {
    externalAuth: "/authFlow/:auth_code",
    home: "/home",
    joinCodiceOption: "/join/:codice/:option?",
    changeMail: "/change-mail-confirm/:token",
    logout: "/logout",
    publicProfile: "/publicprofile/edit",
    search: "/search/:keyword?/:filter?/:filter?",
    share: "/share",
    underageApprovation: "/underageapprovation/:code",
    privacyPolicy: "/privacy-policy",
    Users: {
      initialRouteName: "/users",
      userProfile: "/users/:userUuid/profile"
    },
    Spaces: {
      initialRouteName: "/:space_slug",
      spaceGroups: "/:space_slug/groups",
      spaceGroupsOverquotaBlocked: "/:space_slug/groups/overquotablocked",
      spaceChats: "/:space_slug/chats/:user_id?",
      spaceNotifications: "/:space_slug/notifications",
      SpaceGroup: {
        initialRouteName: "/:space_slug?/group",
        groupId: "/:space_slug?/group/:group_id",
        blocked: "/:space_slug?/group/:group_id/blocked",
        calendar: "/:space_slug?/group/:group_id/calendar",
        calendarActivity:
          "/:space_slug?/group/:group_id/calendar/activity/:event_id",
        calendarDeadline:
          "/:space_slug?/group/:group_id/calendar/deadline/:event_id",
        calendarAgenda: "/:space_slug?/group/:group_id/calendar/agenda/:date?",
        calendarLive: "/:space_slug?/group/:group_id/calendar/live/:event_id",
        exerciseInstant:
          "/:space_slug?/group/:group_id/exercises/:exercise_id/instants/:instant_id",
        exerciseInstantLive:
          "/:space_slug?/group/:group_id/exercises/:exercise_id/instants/:instant_id/live",
        exerciseRun:
          "/:space_slug?/group/:group_id/exercises/:exercise_id/run/:option?",
        groupOverquotaBlocked: "/:space_slug?/group/:group_id/overquotablocked",
        settings: "/:space_slug?/group/:group_id/settings/:option",
        whiteboard: "/:space_slug?/group/:group_id/whiteboard",
        modules: "/:space_slug?/group/:group_id/modules",
        Activity: {
          initialRouteName: "/:space_slug?/group/:group_id/activity",
          new: "/:space_slug?/group/:group_id/activity/new",
          mode: "/:space_slug?/group/:group_id/activity/:mode?"
        },
        Boards: {
          initialRouteName: "/:space_slug?/group/:group_id/boards",
          boardNew: "/:space_slug?/group/:group_id/boards/new",
          boardId: "/:space_slug?/group/:group_id/boards/:board_id",
          boardElementEdit:
            "/:space_slug?/group/:group_id/boards/:board_id/elements/:board_element_id/edit",
          boardElementResource:
            "/:space_slug?/group/:group_id/boards/:board_id/elements/:resource_id",
          boardElementResourceComments:
            "/:space_slug?/group/:group_id/boards/:board_id/elements/:resource_id/comments"
        },
        Exercises: {
          initialRouteName: "/:space_slug?/group/:group_id/exercises",
          exercisesDrafts: "/:space_slug?/group/:group_id/exercises/drafts",
          exercisesNew: "/:space_slug?/group/:group_id/exercises/new",
          exercise: "/:space_slug?/group/:group_id/exercises/:exercise_id",
          exerciseRun:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/run",
          exerciseRunOption:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/run/:option",
          exerciseEdit:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/edit",
          exerciseInstant:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/instants/:instant_id",
          exerciseInstantLive:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/instants/:instant_id/live",
          exerciseInstantRun:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/instants/:instant_id/run",
          exerciseReportsSession:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/reports/sessions/:session_id",
          exerciseReportsUsers:
            "/:space_slug?/group/:group_id/exercises/:exercise_id/reports/users"
        },
        Modules: {
          initialRouteName: "/:space_slug?/group/:group_id/modules",
          newModule: "/:space_slug?/group/:group_id/modules/new",
          module: "/:space_slug?/group/:group_id/modules/:module_id",
          moduleElement:
            "/:space_slug?/group/:group_id/modules/:module_id/elements/:element_id",
          moduleElementEdit:
            "/:space_slug?/group/:group_id/modules/:module_id/elements/:board_element_id/:draft?/edit",
          moduleElementComments:
            "/:space_slug?/group/:group_id/modules/:module_id/elements/:element_id/comments",
          moduleMode: "/:space_slug?/group/:group_id/modules/:module_id/:mode?"
        },
        Post: {
          edit: "/:space_slug?/group/:group_id/post/edit/:post_id",
          post: "/:space_slug?/group/:group_id/post/:post_id",
          postReport: "/:space_slug?/group/:group_id/post/:post_id/report",
          postResource:
            "/:space_slug?/group/:group_id/post/:post_id/resource/:resource_id",
          postResourceEdit:
            "/:space_slug?/group/:group_id/post/:post_id/resource/:resource_id/edit"
        },
        issueReport: "/:space_slug?/group/:group_id/issue/report",
        moderation: "/:space_slug?/group/:group_id/moderation",
        rankingPage: "/:space_slug?/group/:group_id/ranking/:page",
        resourceReport:
          "/:space_slug?/group/:group_id/resource/:resource_id/report",
        resourceEdit:
          "/:space_slug?/group/:group_id/resources/:resource_id/edit",
        wall: "/:space_slug?/group/:group_id/wall",
        wallNew: "/:space_slug?/group/:group_id/wall/new"
      },
      SpaceChat: {
        initialRouteName: "/:space_slug/chat",
        new: "/:space_slug/chat/new/:user_id?",
        threadId: "/:space_slug/chat/thread/:thread_id"
      }
    },
    Dashboard: {
      initialRouteName: "/dashboard",
      space: "/dashboard/:space_id",
      analytics: "/dashboard/:space_id/analytics",
      groups: "/dashboard/:space_id/groups",
      settings: "/dashboard/:space_id/settings/:option?",
      users: "/dashboard/:space_id/users",
      badges: "/dashboard/:space_id/badges"
    },
    Dea: {
      initialRouteName: "/dea",
      importProject: "/dea/import/:project_id",
      importProjectNode: "/dea/import/:project_id/:node_id"
    },
    Settings: {
      initialRouteName: "/settings",
      settingsOption: "/settings/:option?",
      settingsAccountOption: "/settings/account/:option"
    }
  },
  Auth: {
    register: "/register/:token?",
    sso: "/sso",
    joinCodiceOption: "/join/:codice/:option?",
    loginUniversity: "/loginuniversity",
    confirmRegistration: "/users/me/registration/confirm/:token",
    confirmRegistrationWithAlias:
      "/users/me/registration/confirm/:token/alias/:alias",
    requestPasswordReset: "/password/request",
    resetPassword: "/users/me/password/reset/confirm/:token",
    login: "/login/:token?/:client_id?",
    externalAuth: "/authFlow/:auth_code",
    underageApprovation: "/underageapprovation/:code",
    userConfirmMail: "/change-mail-confirm/:token",
    privacyPolicy: "/privacy-policy"
  },
  Onboarding: {
    initOnboarding: "/onboarding/init",
    resumeOnboarding: "/onboarding/resume-onboarding",
    resumeSpace: "/onboarding/resume-space",
    welcome: "/onboarding/welcome",
    marketingData: "/onboarding/marketing-data",
    spaceCreation: "/onboarding/space-creation",
    groupCreateSelection: "/onboarding/group-create-selection",
    groupCreation: "/onboarding/group-creation",
    weschoolTemplateCreation: "/onboarding/weschool-template-creation",
    membersInvite: "/onboarding/members-invite",
    schoolSpaceCreation: "/onboarding/school-space-creation",
    schoolSpaceConfirmation: "/onboarding/school-space-confirmation",
    join: "/onboarding/join-with-code",
    logout: "/logout"
  },
  Underage: {
    underageLock: "/underagelock",
    joinCodiceOption: "/join/:codice/:option?",
    underageApprovation: "/underageapprovation/:code",
    logout: "/logout"
  },
  UserVerification: {
    activateAccount: "/activateaccount/:option?",
    logout: "/logout"
  }
} as const;
