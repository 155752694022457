import React from "react";
import { Text } from "@arcadia/design-system";
import * as S from "./GroupNumberBadge.styles";

const GroupNumberBadge = ({ number = 0, ...props }) => {
  return (
    <S.StyledGroupNumberBadge is_active={number > 0} {...props}>
      <S.CircleIndicator is_active={number > 0} />
      <Text type="cta">
        {number > 0 && number < 10 ? "0" : ""}
        {number}
      </Text>
    </S.StyledGroupNumberBadge>
  );
};

export default GroupNumberBadge;
