import { makeAutoObservable } from "mobx";
import { fetchGroupEmailByRoles } from "src/js/repository/groupRepository";
import { fetchFilteredGroupList } from "src/js/repository/groupsListRepository";
import { __ROLE_TEACHER__ } from "src/js/settings/settingsUser";
import { GroupUserRoleEnum } from "../types";

class UserInviteStore {
  $inviteMode = "manual";

  $usersToInvite = [];

  $usersToInviteRole = GroupUserRoleEnum.Student;

  $groupImportList = [];

  $start = 0;

  $limit = 30;

  $query = null;

  $hasMoreGroup = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setMode = mode => {
    this.$inviteMode = mode;
  };

  setRole = role => {
    this.$usersToInviteRole = role;
  };

  setGroupList = list => {
    this.$groupImportList = this.$groupImportList.concat(list);
  };

  resetGroupList = () => {
    this.$groupImportList = [];
  };

  setStart = start => {
    this.$start = start;
  };

  setQuery = query => {
    this.$query = query || null;
  };

  setHasMoreGroup = more => {
    this.$hasMoreGroup = more || false;
  };

  updateUserList = email => {
    this.$usersToInvite = [...this.$usersToInvite, email];
  };

  removeUserFromList = email => {
    const updatedList = this.$usersToInvite.filter(
      userEmail => userEmail !== email
    );
    this.$usersToInvite = updatedList;
  };

  clearUserList = () => {
    this.$usersToInvite = [];
  };

  fetchEmailList = id => {
    return fetchGroupEmailByRoles({
      groupId: id,
      limit: 100,
      offset: 0,
      role: this.$usersToInviteRole
    }).then(userList => {
      this.$usersToInvite = [
        ...this.$usersToInvite,
        ...Object.values(userList.results)
      ];
    });
  };

  fetchGroupList = (searchMode = false) => {
    return fetchFilteredGroupList({
      name: this.$query,
      limit: this.$limit,
      start: this.$start,
      role: __ROLE_TEACHER__
    }).then(data => {
      if (searchMode) this.$groupImportList = [];
      this.setGroupList(data.groups);
      this.setHasMoreGroup(data.has_next);
    });
  };

  fetchNextPage = () => {
    this.setStart(this.$start + this.$limit);
    this.fetchGroupList(false);
  };

  fetchFilteredGroup = ({ query }) => {
    this.setQuery(query);
    this.setStart(0);
    this.fetchGroupList(true);
  };

  reset = () => {
    this.$inviteMode = "manual";
    this.$usersToInvite = [];
    this.$usersToInviteRole = GroupUserRoleEnum.Student;
    this.$groupImportList = [];
    this.$query = null;
    this.$limit = 30;
    this.$start = 0;
    this.$hasMoreGroup = false;
  };

  resetFilter = () => {
    this.$groupImportList = [];
    this.$query = null;
    this.$limit = 30;
    this.$start = 0;
    this.$hasMoreGroup = false;
  };

  get usersToInvite() {
    return [...new Set(this.$usersToInvite)];
  }

  get inviteMode() {
    return this.$inviteMode;
  }

  get groupImportList() {
    return this.$groupImportList;
  }

  get query() {
    return this.query;
  }

  get hasMoreGroup() {
    return this.$hasMoreGroup;
  }

  get usersToInviteRole() {
    return this.$usersToInviteRole;
  }
}

export default UserInviteStore;
