import React from "react";
import {
  ContentSwitcher,
  ContentSwitcherItem,
  Text
} from "@arcadia/design-system";
import createUrl from "src/js/modules/routing";
import { navigateTo } from "Legacy/modules/history";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { ModulesResourcesFilterProps } from "src/js/components/modules/ModulesResourcesFilter/types";
import * as S from "./ModulesResourcesFilter.styles";

const ModulesResourcesFilter = ({
  tabSelected,
  moduleId
}: ModulesResourcesFilterProps) => {
  const {
    GroupStore: { groupId }
  } = useStores();
  const { translate } = useTranslation();
  const { greyTheme } = useTheme();

  const filterOptions: ContentSwitcherItem[] = [
    {
      id: "1",
      value: "detail",
      children: (
        <Text type="cta">
          {translate("module_detail_menu_published_label")}
        </Text>
      )
    },
    {
      id: "2",
      value: "drafts",
      children: (
        <Text type="cta">{translate("module_detail_menu_draft_label")}</Text>
      )
    }
  ];

  return (
    <S.Container>
      <ContentSwitcher
        theme={greyTheme}
        onContentItemChange={param => {
          navigateTo(
            createUrl(
              param === "drafts" ? "module_detail_drafts" : "module_detail",
              {
                group_id: groupId,
                module_id: moduleId
              }
            )
          );
        }}
        squared={false}
        disabled={false}
        activeOption={tabSelected}
        items={filterOptions}
      />
    </S.Container>
  );
};

export default ModulesResourcesFilter;
