import {
  BasePaginationParams,
  GroupDetailsModel,
  SpaceModel
} from "src/js/types";
import { ListLabelsType } from "../layout/SelectableList";
import { ModalLayoutLabelsType } from "../modal/ModalLayout";

export enum MigrateGroupsContextEnum {
  GroupsSelection = "groups-selection",
  SpaceSelection = "space-selection"
}

export type MigrateGroupsFetchItemsType = SpaceModel & GroupDetailsModel;

export type MigrateGroupsStepType = {
  context: MigrateGroupsContextEnum;
  multipleSelect: boolean;
  modalLabels: ModalLayoutLabelsType;
  listLabels: ListLabelsType;
};

export type MigrateGroupsModalProps = {
  skipIntro?: boolean;
  onClose: () => void;
  moveFromActiveSpace?: boolean;
};

export type SpaceGroupsCanMigrateParams = BasePaginationParams & {
  spaceId: string;
  name?: string;
  role?: string;
};

export type SpacesCanCreateGroupParams = BasePaginationParams & {
  name?: string;
  role?: string[];
};
