import React from "react";
import { RegistrationOptions } from "./RegistrationOptions";

const FormStepUserUnderage = [
  {
    id: "type",
    label: "type_selection",
    index: 1,
    title: "user_type_intro"
  },
  {
    id: "tutor",
    label: "tutor_registration",
    index: 2,
    title: "register_step_underage_title"
  },
  {
    id: "underage",
    label: "underage_registration",
    index: 3,
    title: "register_step_underage_title"
  }
];

const FormStepUserNormal = [
  { id: "type", label: "type_selection", index: 1, title: "user_type_intro" },
  {
    id: "normal",
    label: "normal_registration",
    index: 2,
    title: "register_step_user_title"
  }
];

function getFormSteps(step) {
  switch (step) {
    case "teacher":
    case "student":
      return FormStepUserNormal;
    case "tutor":
      return FormStepUserUnderage;
    default:
      return FormStepUserUnderage;
  }
}

const StepViewer = ({
  step,
  saveTutorData,
  handleRegister,
  formData,
  selectType,
  backStepFunction,
  token,
  inviteInfo
}) => {
  switch (step) {
    case "type":
      return (
        <RegistrationOptions
          whiteLayout
          inviteInfo={inviteInfo}
          onSelect={selectType}
          token={token}
          step={step}
        />
      );
    case "tutor":
      return (
        <RegistrationOptions
          whiteLayout
          inviteInfo={inviteInfo}
          saveTutorData={saveTutorData}
          token={token}
          step={step}
          onReturn={backStepFunction}
          formData={formData}
          contract
        />
      );
    case "underage":
      return (
        <RegistrationOptions
          whiteLayout
          inviteInfo={inviteInfo}
          handleRegister={handleRegister}
          token={token}
          step={step}
          onReturn={backStepFunction}
          formData={formData}
        />
      );
    case "normal":
      return (
        <RegistrationOptions
          whiteLayout
          inviteInfo={inviteInfo}
          token={token}
          step={step}
          onReturn={backStepFunction}
          handleRegister={handleRegister}
          formData={formData}
          contract
        />
      );
    default:
      return null;
  }
};

class RegisterSchoolGroupContractForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      activeStep: 1,
      typeSelected: "",
      stepSet: FormStepUserUnderage
    };
    this.backStep = this.backStep.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.selectType = this.selectType.bind(this);
    this.addFormData = this.addFormData.bind(this);
    this.sendForm = this.sendForm.bind(this);
  }

  componentDidMount() {
    const { token } = this.props;
    if (token) {
      const data = { invite_token: token };
      this.setState({
        formData: data
      });
    }
  }

  changeStep(step) {
    this.setState({
      activeStep: step
    });
  }

  backStep() {
    const { activeStep } = this.state;
    this.changeStep(parseInt(activeStep, 10) - 1);
  }

  selectType(type) {
    const { token, formData } = this.props;
    const { typeSelected, activeStep } = this.state;
    if (type !== typeSelected) {
      const data = formData;
      if (token) {
        data.invite_token = token;
      }
      this.setState(
        {
          formData: data,
          stepSet: getFormSteps(type),
          typeSelected: type
        },
        this.changeStep(parseInt(activeStep, 10) + 1)
      );
    } else {
      this.setState(
        {
          typeSelected: type
        },
        this.changeStep(parseInt(activeStep, 10) + 1)
      );
    }
  }

  addFormData(newData) {
    const { formData, activeStep } = this.state;
    this.setState(
      {
        formData: Object.assign(formData, newData)
      },
      this.changeStep(parseInt(activeStep, 10) + 1)
    );
  }

  sendForm(newData) {
    const { registrationFunction } = this.props;
    const { formData } = this.state;
    this.setState(
      {
        formData: Object.assign(formData, newData)
      },
      registrationFunction(formData)
    );
  }

  render() {
    const { formData, activeStep, stepSet } = this.state;
    const { token, inviteInfo } = this.props;
    const activeStepId = stepSet.find(step => {
      if (step.index === activeStep) {
        return step;
      }
      return false;
    });
    return (
      <StepViewer
        step={activeStepId.id}
        formData={formData}
        saveTutorData={this.addFormData}
        handleRegister={this.sendForm}
        selectType={this.selectType}
        backStepFunction={this.backStep}
        token={token}
        inviteInfo={inviteInfo}
      />
    );
  }
}

export default RegisterSchoolGroupContractForm;
