import styled from "styled-components";
import { __UNLOGGED_BG_DESKTOP__ } from "src/js/settings/settingsImage";
import { Heading, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";

export const Background = styled.div<{ isLayoutMobile: boolean }>`
  width: 100vw;
  min-height: 100vh;
  background: ${({ isLayoutMobile, theme }) =>
    isLayoutMobile
      ? theme.colors.lagoon[700]
      : `url(${__UNLOGGED_BG_DESKTOP__}) center no-repeat`};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLayoutMobile }) =>
    isLayoutMobile ? "space-between" : "center"};
  align-items: center;
  padding: 52px 32px;
`;

export const SVGWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin-top: 40px;
`;

export const Card = styled(motion.div)`
  padding: 40px 52px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 40px;
`;

export const LogoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 52px 16px;
`;

export const MobileTitleWrapper = styled.div`
  position: relative;
`;

export const MobileTitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.lagoon[200]};
`;

export const MobileTextWrapper = styled(Text)`
  color: ${({ theme }) => theme.colors.lagoon[100]};
  margin-top: 20px;
  text-align: center;
  max-width: 314px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: -2px;
  border-radius: 50%;
  cursor: pointer;
`;
