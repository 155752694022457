import styled from "styled-components";

export const ContextualFeedbackContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.contextualfeedback.background};
  color: ${({ theme }) => theme.contextualfeedback.text};
  vertical-align: center;
  width: 100%;
`;

export const ContextualFeedbackIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ContextualFeedbackMessage = styled.div`
  padding-left: 10px;
  @media screen and (max-width: 767px) {
    padding-left: 12px;
  }
`;
