import { Box, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const ChipBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 12px;
  border-radius: 100px;
  min-height: 24px;
  height: auto;
  min-width: 100px;
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  color: ${({ theme }) => theme.colors.grey[100]};
  color: ${props => props.color};
`;

export const InfoButton = styled.span`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  border: ${({ theme }) => `2px solid ${theme.primaryColor[800]}`};
  width: 20px;
  height: 20px;
`;

export const DetailButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const DescriptionText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const MotionTooltipContent = motion(styled.div`
  white-space: pre-wrap;
  z-index: ${({ theme }) => theme.zIndex.forModal};
`);
