import styled from "styled-components";

export const BoardEmptyContainer = styled.div`
  padding: 0 32px 8px;
  display: flex;
  margin-top: 48px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 245px;
`;
