import { ActionButton, shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
  height: 54px;
  align-items: center;
  justify-content: space-between;
`;

export const TabWrapper = styled.ul`
  all: unset;
  display: flex;
  align-self: flex-end;
  padding: 0px 20px;
  gap: 4px;
`;
export const Tab = styled.li`
  all: unset;
  position: relative;
  width: max-content;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
`;

export const ActiveBar = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.primaryColor[500]};
`;

export const StyledCloseButton = styled(ActionButton)`
  margin-right: 8px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const ClickableTab = styled.button<{
  fullWidth?: boolean;
  isActive?: boolean;
}>`
  all: unset;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  min-width: 32px;
  height: 20px;
  max-height: 20px;
  width: ${({ fullWidth }) =>
    fullWidth ? "-webkit-fill-available" : "fit-content"};
  align-items: center;
  justify-content: ${({ fullWidth }) => (fullWidth ? "flex-start" : "center")};
  color: ${({ theme, isActive }) =>
    isActive ? theme.primaryColor[500] : theme.colors.grey[500]};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.grey[900]};
    border-radius: 8px;
  }
`;

export const HiddenTabsWrapper = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 767px) {
    position: unset;
  }
`;

export const OtherDropdown = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: ${shadow[200]};
  width: 88px;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};

  @media (max-width: 767px) {
    width: calc(100% - 8px);
    left: 4px;
    right: 4px;
    top: 54px;
  }
`;
