import React from "react";
import { Box, Icon, Text } from "@arcadia/design-system";
import { InfoChip } from "src/js/components/InfoChip";
import { useTranslation } from "src/js/translation";

import {
  CREATE_MODULE_TYPE,
  CreateModuleType
} from "./ModuleCreateButton.types";
import * as S from "./ModuleCreateButton.styles";

const ModuleCreateButton = ({
  type,
  onClick
}: {
  type: CreateModuleType;
  onClick?: () => void;
}) => {
  const { translate } = useTranslation();

  const handleClick = React.useCallback(() => onClick?.(), [onClick]);

  switch (type) {
    case CREATE_MODULE_TYPE.AI:
      return (
        <S.CardWrapper onClick={handleClick}>
          <S.Column>
            <S.TitleRow>
              <Icon icon="wand" initialViewbox width={24} height={24} />
              <Box display="flex" alignItems="center">
                <Text type="formField">{translate("module_ai_btn_title")}</Text>
                <Box marginLeft={8}>
                  <InfoChip text="beta_chip_text" />
                </Box>
              </Box>
            </S.TitleRow>
            <S.CardDescription type="body">
              {translate("module_ai_btn_desc")}
            </S.CardDescription>
          </S.Column>
          <Icon icon="plusThin" width={16} height={16} />
        </S.CardWrapper>
      );
    case CREATE_MODULE_TYPE.NEW:
      return (
        <S.CardWrapper onClick={handleClick}>
          <S.Column>
            <S.TitleRow>
              <Icon icon="book" width={24} height={24} />
              <Text type="formField">
                {translate("module_create_btn_title")}
              </Text>
            </S.TitleRow>
            <S.CardDescription type="body">
              {translate("module_create_btn_desc")}
            </S.CardDescription>
          </S.Column>
          <Icon icon="plusThin" width={16} height={16} />
        </S.CardWrapper>
      );
    case CREATE_MODULE_TYPE.IMPORT:
      return (
        <S.CardWrapper onClick={handleClick}>
          <S.Column>
            <S.TitleRow>
              <Icon icon="copyImport" width={24} height={24} />
              <Text type="formField">
                {translate("module_import_btn_title")}
              </Text>
            </S.TitleRow>
            <S.CardDescription type="body">
              {translate("module_import_btn_desc")}
            </S.CardDescription>
          </S.Column>
          <Icon icon="plusThin" width={16} height={16} />
        </S.CardWrapper>
      );
    default:
      return null;
  }
};

export default ModuleCreateButton;
