import axios from "axios";
import {
  api_invite_info,
  api_registration_code_info,
  api_registration_token,
  api_registration_token_info,
  api_group_users_unlogged,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { getErrorMessage } from "src/js/modules/messageManager";
import { __API_PATH__, __API_V2_PATH__ } from "src/js/settings/settingsUrl";
import { __COOKIE_JOIN_GROUP__ } from "src/js/settings/settingsCookie";
import { readLocalData } from "src/js/modules/storageFunction";
import { getAuthenticationToken } from "src/js/modules/tokens";
import getUserClient from "src/js/modules/connection";
import { fetchSpaceCustomization } from "src/js/repository/spacesRepository";

export function fetchRegistrationDataFromToken(token) {
  return axios({
    method: "get",
    url: __API_PATH__ + api_registration_token_info({ token }),
    headers: {
      Authorization: `Bearer ${getAuthenticationToken()}`
    },
    ignoreCall: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        getErrorMessage(
          error.response.data,
          "error_fetch_registration_data_from_token"
        )
      );
      throw Err;
    });
}

export function fetchRegistrationDataFromJoinCode() {
  return axios({
    method: "get",
    url:
      __API_PATH__ +
      api_registration_code_info({
        code: readLocalData(__COOKIE_JOIN_GROUP__)
      }),
    headers: {
      Authorization: `Bearer ${getAuthenticationToken()}`
    },
    ignoreCall: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        getErrorMessage(
          error.response.data,
          "error_fetch_registration_data_from_join_code"
        )
      );
      throw Err;
    });
}

export function fetchRegistrationDataOauth(auth_code, client_id) {
  const data = {
    auth_code,
    client_id
  };
  return axios({
    method: "post",
    url: __API_V2_PATH__ + api_registration_token(),
    headers: {
      Authorization: `Bearer ${getAuthenticationToken()}`
    },
    data
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        getErrorMessage(
          error.response.data,
          "error_fetch_registration_data_from_oauth"
        )
      );
      throw Err;
    });
}

export const fetchInviteInformation = async inviteCode => {
  try {
    const invitationResponse = await getUserClient().get(
      getVersionedApiUrl(api_invite_info({ inviteCode }), "v3")
    );
    const groupUsers = await getUserClient().get(
      getVersionedApiUrl(
        api_group_users_unlogged({
          groupId: invitationResponse.data?.groupData?.group?.id
        }),
        "v3"
      ),
      {
        params: {
          roles: ["ROLE_TEACHER", "ROLE_STUDENT"],
          limit: 3
        }
      }
    );
    const spaceCustomizationSettings = await fetchSpaceCustomization({
      spaceId: invitationResponse.data.groupData.space.id
    });
    return {
      ...invitationResponse.data,
      groupUsers: [...groupUsers.data?.results],
      spaceCustomizationSettings
    };
  } catch (error) {
    const Err = new Error("invitation_expired_already_used_or_not_valid");
    throw Err;
  }
};
