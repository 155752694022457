import { MimeType } from "../components/ResourcePicker/components/AddGDriveResource/types";

export const { __GOOGLE_API_KEY__, __GOOGLE_CLIENT_ID__, __GOOGLE_APP_ID__ } =
  process.env;

// non-restricted scope
// for more info: https://developers.google.com/identity/protocols/oauth2/scopes#drive
export const GoogleScope = "https://www.googleapis.com/auth/drive.file";

export const __GDRIVE_MIME_BLACKLIST__: MimeType[] = [
  "application/vnd.google-apps.form"
];

export const __DROPBOX_APP_ID__ = "mlw19xjss6v75n6";

export const __RESOURCE_SIZE_LIMIT__ = 104857600; // 100MB

export const __AVAILABLE_RESOURCES__ = {
  document: {
    logo: "word",
    title: "resource_document_label",
    width: "64",
    height: "60",
    type: "document"
  },
  dropbox: {
    logo: "dropbox",
    title: "resource_dropbox_label",
    width: "64",
    height: "55",
    type: "dropbox"
  },
  gdoc: {
    logo: "googledoc",
    title: "resource_gdoc_label",
    width: "47",
    height: "64",
    type: "gdoc"
  },
  google: {
    logo: "google",
    title: "resource_google_label",
    width: "64",
    height: "64",
    type: "google"
  },
  gslide: {
    logo: "googleslides",
    title: "resource_gslide_label",
    width: "47",
    height: "64",
    type: "gslide"
  },
  gsheet: {
    logo: "googlesheet",
    title: "resource_gsheet_label",
    width: "47",
    height: "64",
    type: "gsheet",
    initialViewbox: true
  },
  gdrive: {
    logo: "googledrive",
    title: "resource_gdrive_label",
    width: "64",
    height: "58",
    type: "gdrive"
  },
  youtube: {
    logo: "youtube",
    title: "resource_youtube_label",
    width: "69",
    height: "48",
    type: "youtube"
  },
  maps: {
    logo: "googlemaps",
    title: "resource_maps_label",
    width: "45",
    height: "64",
    viewbox: "0 0 45 64",
    type: "maps"
  },
  exercises: {
    logo: "testweschool",
    title: "resource_exercises_label",
    width: "47",
    height: "64",
    type: "exercises"
  },
  /*  onedrive: {
    name: __("resource_onedrive_label"),
    type: "onedrive",
    icon: "windows",
    image: oneDrivePlaceholder,
    colorImage: oneDriveColorPlaceholder,
    background: "#37425b" // "#094ab2"
  },
  instagram: {
    name: __("resource_instagram_label"),
    type: "instagram",
    icon: "instagram",
    image: instagramPlaceholder,
    colorImage: instagramColorPlaceholder,
    background: "#37425b" // "#5280a4"
  },  */
  imagegallery: {
    logo: "images",
    title: "resource_imagegallery_label",
    width: "56",
    height: "44",
    viewbox: "0 0 56 44",
    type: "imagegallery"
  },

  pdf: {
    logo: "pdf",
    title: "PDF",
    width: "53",
    height: "64",
    type: "pdf"
  },
  scorm: {
    logo: "scorm",
    title: "resource_scorm_label",
    width: "59",
    height: "64",
    viewbox: "0 0 59 64",
    type: "scorm"
  },
  slideshow: {
    logo: "powerpoint",
    title: "resource_slideshow_label",
    width: "64",
    height: "60",
    type: "slideshow"
  },
  spreadsheet: {
    logo: "excel",
    title: "Excel",
    width: "64",
    height: "60",
    type: "spreadsheet"
  },
  /*  lti: {
    name: __("resource LTI"),
    type: "lti",
    icon: "upload",
    image: ltiPlaceholder,
    colorImage: ltiPlaceholder,
    background: "#37425b" // "#5a99ff"
  },  */
  texteditor: {
    logo: "texteditor",
    title: "resource_texteditor_label",
    width: "47",
    height: "64",
    viewbox: "0 0 47 64",
    type: "texteditor"
  },
  upload: {
    logo: "file",
    title: "resource_upload_label",
    width: "64",
    height: "64",
    type: "upload"
  },
  url: {
    logo: "webpage",
    title: "resource_url_label",
    width: "77",
    height: "68",
    type: "link"
  },
  vimeo: {
    logo: "vimeo",
    title: "resource_vimeo_label",
    width: "64",
    height: "19",
    type: "vimeo"
  }
};

export const THUMBNAIL_MINIMUM_WIDTH = 300;
export const THUMBNAIL_MINIMUM_HEIGHT = 220;
export const BADGE_IMAGE_MINIMUM_WIDTH = 400;
export const BADGE_IMAGE_MINIMUM_HEIGHT = 400;
