import React from "react";
import * as S from "src/js/components/global/ColorLabel/ColorLabel.styles";

const ColorLabel = ({
  children,
  clickFunction = () => {},
  isClickable = false,
  type
}) => {
  return (
    <S.ColorLabelContainer
      type={type}
      onClick={clickFunction}
      isClickable={isClickable}
    >
      {children}
    </S.ColorLabelContainer>
  );
};

export default ColorLabel;
