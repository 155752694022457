import { EventDomain, ResourcePickerLabel } from "src/js/types";
import {
  RESOURCE_PICKER_SECTION,
  RESOURCE_PICKER_ITEMS
} from "./ResourcePicker.const";
import {
  ResourcePickerItem,
  ResourcePickerSectionType
} from "./ResourcePicker.types";

const SectionToEventDomainMap: Record<ResourcePickerSectionType, EventDomain> =
  {
    [RESOURCE_PICKER_SECTION.MODULES]: EventDomain.ModuleAddContent,
    [RESOURCE_PICKER_SECTION.CHAT]: EventDomain.ChatAddContent,
    [RESOURCE_PICKER_SECTION.EXERCISE]: EventDomain.ExerciseAddContent,
    [RESOURCE_PICKER_SECTION.WALL]: EventDomain.WallAddContent
  };

export const getEventDomainFromSection = (
  section: ResourcePickerSectionType
) => {
  return SectionToEventDomainMap[section];
};

// MODULE, MODULE_BLOCK, AGENDA are not used in the dropdown
const ItemToLabelMap: Partial<Record<ResourcePickerItem, ResourcePickerLabel>> =
  {
    [RESOURCE_PICKER_ITEMS.AIEDITOR]: ResourcePickerLabel.DraftWithAI,
    [RESOURCE_PICKER_ITEMS.EDITOR]: ResourcePickerLabel.Editor,
    [RESOURCE_PICKER_ITEMS.EXERCISE]: ResourcePickerLabel.Test,
    [RESOURCE_PICKER_ITEMS.FILE]: ResourcePickerLabel.File,
    [RESOURCE_PICKER_ITEMS.LINK]: ResourcePickerLabel.Link,
    [RESOURCE_PICKER_ITEMS.IMAGE]: ResourcePickerLabel.Images,
    [RESOURCE_PICKER_ITEMS.VIDEO]: ResourcePickerLabel.Video,
    [RESOURCE_PICKER_ITEMS.SCORM]: ResourcePickerLabel.Scorm,
    [RESOURCE_PICKER_ITEMS.GDRIVE]: ResourcePickerLabel.GoogleDrive,
    [RESOURCE_PICKER_ITEMS.DROPBOX]: ResourcePickerLabel.Dropbox,
    [RESOURCE_PICKER_ITEMS.PDF]: ResourcePickerLabel.PDF,
    [RESOURCE_PICKER_ITEMS.GDOCS]: ResourcePickerLabel.GoogleDocs,
    [RESOURCE_PICKER_ITEMS.GSHEET]: ResourcePickerLabel.GoogleSheet,
    [RESOURCE_PICKER_ITEMS.GSLIDES]: ResourcePickerLabel.GoogleSlides,
    [RESOURCE_PICKER_ITEMS.EXCEL]: ResourcePickerLabel.Excel,
    [RESOURCE_PICKER_ITEMS.POWERPOINT]: ResourcePickerLabel.Powerpoint,
    [RESOURCE_PICKER_ITEMS.WORD]: ResourcePickerLabel.Word,
    [RESOURCE_PICKER_ITEMS.VIMEO]: ResourcePickerLabel.Vimeo,
    [RESOURCE_PICKER_ITEMS.YOUTUBE]: ResourcePickerLabel.YouTube
  };
export const getEventLabelFromItem = (
  item: ResourcePickerItem
): ResourcePickerLabel => {
  return ItemToLabelMap[item];
};
