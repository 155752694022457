import styled from "styled-components";

export const ButtonAllGroupsIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
`;

export const ButtonAllGroups = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 36px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 12px 0 8px;
  :hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;
