import { SpaceModel } from "src/js/types";
import { DefaultTheme } from "styled-components";

export const SchoolClaimFormContext = {
  Search: "SEARCH",
  Selected: "SELECTED"
};
export type SchoolClaimFormContext =
  (typeof SchoolClaimFormContext)[keyof typeof SchoolClaimFormContext];

export const SchoolClaimFormStatus = {
  Selection: "SELECTION",
  NewSpace: "NEW_SPACE",
  ClaimedSpace: "CLAIMED_SPACE",
  UnclaimedSpace: "UNCLAIMED_SPACE"
};
export type SchoolClaimFormStatus =
  (typeof SchoolClaimFormStatus)[keyof typeof SchoolClaimFormStatus];

export type SchoolClaimFormProps = {
  onChangeStatus: (status: SchoolClaimFormStatus) => void;
  onSuccessCallback: (space?: SpaceModel) => void;
  theme?: DefaultTheme;
  backgroundColor: string;
  goToSpaceCreation: () => void;
};
