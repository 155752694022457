import { Box, Icon, Text } from "@arcadia/design-system";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { readLocalParsedData } from "src/js/modules/storageFunction";
import { __LOCAL_SEARCH_SUGGESTION__ } from "src/js/settings/settingsLocalStorage";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { SearchResponseItem, SearchUrlParameters } from "src/js/types";
import { useTheme } from "styled-components";
import SearchResultItem from "../SearchResultItem";

import * as S from "./SearchModalMobile.styles";
import { Suggestions } from "./Suggestions";
import { getNumberOfAppliedFilters, getSearchFiltersObject } from "../util";

type Props = {
  onFilterResultsClick: () => void;
  searchTerm: string;
  onSearchChange: (value: string) => void;
  searchParams: SearchUrlParameters;
  onSearchClear: () => void;
  data: SearchResponseItem[];
  isLoading: boolean;
  searchDebounced: string;
};

const SearchResultsMobile: FC<Props> = ({
  onFilterResultsClick,
  searchTerm = "",
  onSearchChange,
  searchParams,
  onSearchClear,
  data,
  isLoading,
  searchDebounced
}) => {
  const { translate } = useTranslation();
  const { whiteTheme, colors } = useTheme();
  const searchRef = useRef<HTMLInputElement>(null);

  const suggestions = useMemo(
    () => readLocalParsedData<string[]>(__LOCAL_SEARCH_SUGGESTION__),
    [readLocalParsedData, searchDebounced]
  );

  const handleSearchChange = (value: string) => {
    onSearchChange(value);
  };

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const EndAdornment = useMemo(
    () => (searchTerm ? <Icon icon="close" onClick={onSearchClear} /> : null),
    [searchTerm, onSearchClear]
  );

  let Content: React.ReactNode = <S.DoodleLoader theme={whiteTheme} />;

  if (!isLoading) {
    if (suggestions?.length > 0 && data.length === 0) {
      Content = (
        <Suggestions
          items={suggestions}
          onSuggestionClick={suggestion => {
            searchRef.current?.focus();
            handleSearchChange(suggestion);
          }}
        />
      );
    } else if (data.length > 0) {
      Content = data.map(element => (
        <SearchResultItem
          key={element?.id}
          item={element}
          keyword={searchDebounced}
        />
      ));
    } else {
      Content = null;
    }
  }

  const numberOfResults = useMemo(() => {
    return getNumberOfAppliedFilters(getSearchFiltersObject(searchParams));
  }, [searchParams]);

  return (
    <>
      <S.SearchBar
        theme={whiteTheme}
        onChange={e => handleSearchChange(e.target.value)}
        variant="thinner"
        placeholder={translate("search_input_placeholder")}
        ref={searchRef}
        value={searchTerm}
        endAdornment={EndAdornment}
      />
      <Box marginBottom="24px">
        <Box
          display="inline-flex"
          alignItems="center"
          onClick={onFilterResultsClick}
        >
          <Box marginRight="8px">
            <Icon icon="filter" width="16px" height="16px" />
          </Box>
          <Text type="formSubtitle">
            {translate("mobile_search_filter_results")}
          </Text>
          {numberOfResults > 0 ? (
            <Box marginLeft="4px">
              <Box
                backgroundColor={colors.violet[500]}
                borderRadius="50%"
                width="18px"
                height="18px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <S.SelectedFiltersCountText type="formSmallCaption">
                  {numberOfResults}
                </S.SelectedFiltersCountText>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      {Content}
    </>
  );
};

export default SearchResultsMobile;
