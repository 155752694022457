import styled from "styled-components";

import { ItemStyleProps } from "./types";

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const ImageContainer = styled.div<ItemStyleProps>`
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  border: ${({ theme, isSelected, isLoading }) =>
    isSelected && !isLoading
      ? `2px solid ${theme.toolbarspaceitem.selected.border}`
      : "2px solid transparent"};
  align-items: center;
  justify-content: center;
`;

export const Counter = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  right: -4px;
  height: 16px;
  align-items: center;
  line-height: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  background-color: ${({ theme }) =>
    theme.toolbarspaceitem.counter.backgroundColor};
  border: ${({ theme }) => theme.toolbarspaceitem.counter.border};
  color: ${({ theme }) => theme.toolbarspaceitem.counter.textColor};
`;
