export const hintsVariants = {
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.25,
      stiffness: 260,
      damping: 20,
      height: { duration: 0.2 },
      opacity: { duration: 0.2 }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.25,
      height: { duration: 0.2 },
      opacity: { duration: 0.15 }
    }
  }
};
