import { useEffect } from "react";
import { useLocation } from "react-router";
import { getUrlParameterValue } from "src/js/modules/commonFunction";
import { showToastError } from "src/js/modules/messageManager";
import { fetchPublicProfile } from "src/js/repository/publicProfileRepository";
import { useTranslation } from "src/js/translation";
import { ThreadUser } from "src/js/types";

const useFetchUserNewThread = ({
  handleThreadUser
}: {
  handleThreadUser: (threadUser: ThreadUser) => void;
}) => {
  const { search } = useLocation();
  const { translate } = useTranslation();
  const urlParameterUserId = getUrlParameterValue(search, "user_id");

  useEffect(() => {
    if (!urlParameterUserId) return;
    fetchPublicProfile(urlParameterUserId)
      .then(({ userUuid, name, surname, avatar }) => {
        const threadUser: ThreadUser = {
          uuid: userUuid,
          name,
          surname,
          avatar,
          hasLeft: false,
          isRemoved: false
        };
        handleThreadUser(threadUser);
      })
      .catch(() => {
        showToastError({ str: translate("error_fetch_chat_user") });
      });
  }, []);

  return null;
};

export default useFetchUserNewThread;
