import { useEffect, useRef } from "react";

type UseIntervalOptions = {
  immediate: boolean;
  paused: boolean;
};

const useInterval = <T extends () => void>(
  callback: T,
  delay: number,
  options?: Partial<UseIntervalOptions>
) => {
  const { immediate = false, paused = false } = options || {};
  const tickId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!paused && immediate) {
      callback();
    }
  }, [callback, immediate, paused]);

  useEffect(() => {
    if (tickId.current && paused) {
      clearInterval(tickId.current);
      return;
    }

    tickId.current = setInterval(callback, delay);

    // eslint-disable-next-line consistent-return
    return () => tickId.current && clearInterval(tickId.current);
  }, [delay, paused, callback]);
};

export default useInterval;
