import styled, { css } from "styled-components";

const variantOptions = theme => ({
  dark: {
    backgroundColor: theme.colors.coldGrey[900],
    color: theme.colors.grey[50],
    placeholderColor: theme.colors.blueGrey[500],
    label: theme.colors.grey[300],
    borderColor: theme.colors.coldGrey[900],
    hover: {
      background: theme.primaryColor[800],
      border: theme.primaryColor[800]
    },
    focus: {
      background: theme.colors.coldGrey[900],
      color: theme.colors.grey[50],
      outline: theme.primaryColor[800],
      border: theme.primaryColor[500]
    },
    disabled: {
      background: theme.colors.coldGrey[900],
      color: theme.colors.blueGrey[800],
      border: theme.colors.coldGrey[900]
    },
    required: {
      background: theme.colors.yellow[500],
      color: theme.primaryColor[500],
      border: theme.colors.yellow[500]
    },
    error: {
      background: theme.colors.lightSemantic1,
      color: theme.colors.error[300],
      border: theme.colors.error[300]
    }
  },

  light: {
    backgroundColor: theme.colors.basicWhite,
    color: theme.colors.primary2,
    borderColor: theme.colors.accent3,
    placeholderColor: theme.colors.accent2,
    hover: {
      background: theme.colors.grey[1000],
      border: theme.colors.grey[800]
    },
    required: {
      background: theme.colors.yellow[500],
      color: theme.primaryColor[500],
      border: theme.colors.yellow[500]
    },
    focus: {
      background: theme.colors.basicWhite,
      color: theme.colors.primary2,
      border: theme.colors.primary1
    },
    error: {
      background: theme.colors.basicWhite,
      color: theme.colors.semantic1,
      border: theme.colors.semantic1
    },
    disabled: {
      background: theme.colors.disabledBk,
      color: theme.colors.blueGrey[500],
      border: theme.colors.lightBlueGrey
    }
  },

  default: {
    backgroundColor: theme.colors.grey[1000],
    color: theme.colors.grey[50],
    borderColor: theme.primaryColor[800],
    placeholderColor: theme.colors.blueGrey[500],
    hover: {
      background: theme.colors.coldGrey[900],
      border: theme.primaryColor[500]
    },
    focus: {
      background: theme.colors.coldGrey[900],
      border: theme.primaryColor[500]
    },
    error: {
      color: theme.colors.error[300],
      border: theme.colors.error[300]
    },
    disabled: {
      background: theme.colors.coldGrey[900],
      color: theme.colors.blueGrey[800],
      border: theme.colors.blueGrey[800]
    }
  }
});

export const AlignContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ iconPosition }) =>
    iconPosition === "left"
      ? css`
          input {
            padding-left: 40px;
            padding-right: 15px;
          }
        `
      : css`
          input {
            padding-left: 15px;
            padding-right: 32px;
          }
        `}
`;

export const Icon = styled.span`
  bottom: ${({ errorMessage }) => (errorMessage ? "23px" : 0)};
  position: absolute;
  font-size: 16px;
  color: ${({ theme, variant }) => variantOptions(theme)[variant].color};
  line-height: 40px;
  ${({ iconPosition }) =>
    iconPosition === "left"
      ? css`
          left: 15px;
        `
      : css`
          right: 15px;
        `}
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${({ theme, variant }) =>
        variantOptions(theme)[variant].error.color};
    `}
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: start;

  // check if you can move this out
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}

  ${({ hasLabel, labelDirection }) =>
    hasLabel &&
    css`
      label {
        width: ${labelDirection === "from_top" ? "100%" : "180px"};
        padding: 10px 0px;
      }

      @media screen and (max-width: 767px) {
        label {
          width: 100%;
        }
      }
    `};
`;

export const StyledInput = styled.input`
  flex-grow: 1;
  width: inherit;
  display: block;
  padding: 8px 16px;
  transition: 0.3s;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  box-shadow: none;
  border-radius: 25px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;

  ${({ theme, variant }) =>
    variant &&
    css`
      background-color: ${variantOptions(theme)[variant].backgroundColor};
      color: ${variantOptions(theme)[variant].color};
      border: 1px solid ${variantOptions(theme)[variant].borderColor};

      ::-webkit-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      ::-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-ms-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      &:hover {
        background-color: ${variantOptions(theme)[variant].hover
          .background} !important;
        color: ${variantOptions(theme)[variant].hover.color} !important;
        border-color: ${variantOptions(theme)[variant].hover.border} !important;
      }

      &:focus {
        background-color: ${variantOptions(theme)[variant].focus
          .background} !important;
        color: ${variantOptions(theme)[variant].focus.color} !important;
        border-color: ${variantOptions(theme)[variant].focus.border} !important;
        outline: ${variant !== "default"} ? 1.5px solid
          ${variantOptions(theme)[variant].focus.outline}: "none";
      }

      ${({ hasError }) =>
        hasError &&
        css`
          background-color: ${variantOptions(theme)[variant].error.background};
          color: ${variantOptions(theme)[variant].error.color};
          border-color: ${variantOptions(theme)[variant].error.border};

          ::-webkit-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          ::-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-ms-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }
        `}

      ${({ disabled }) =>
        disabled &&
        css`
          cursor: not-allowed !important;
          opacity: 1;
          -webkit-text-fill-color: ${variantOptions(theme)[variant].disabled
            .color};
          -webkit-opacity: 1;
          background-color: ${variantOptions(theme)[variant].disabled
            .background};
          color: ${variantOptions(theme)[variant].disabled.color};
          border-color: ${variantOptions(theme)[variant].disabled.border};

          &:hover,
          &:focus {
            background-color: ${variantOptions(theme)[variant].disabled
              .background} !important;
            color: ${variantOptions(theme)[variant].disabled.color} !important;
            border-color: ${variantOptions(theme)[variant].disabled
              .border} !important;
          }
        `}
    `}

  ${({ titleMode }) =>
    titleMode &&
    css`
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
      padding: 0;
      color: ${({ theme }) => theme.colors.darkestGray};
      border: none !important;
      background: none !important;
      border-bottom: 1px solid ${({ theme }) => theme.colors.accent3} !important;
      border-radius: 0;

      &:hover,
      &:focus {
        background: none !important;
        border-color: ${({ theme }) => theme.primaryColor[500]};
      }
    `}
`;
