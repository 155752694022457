import { motion } from "framer-motion";
import styled from "styled-components";

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[900]};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioDot = styled.div<{ isSelected?: boolean }>`
  position: absolute;
  top: 28px;
  right: 24px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.primaryColor[500] : "transparent"};
  outline: 1px solid ${({ theme }) => theme.primaryColor[500]};
  padding: 4px;
  outline-offset: 2px;
`;

export const FooterBtn = styled.button<{ isSelected?: boolean }>`
  all: unset;
  margin-left: auto;
  margin-top: auto;
  padding: 2px;
  svg {
    vertical-align: bottom;
    padding-left: 6px;
  }
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.primaryColor[500] : theme.colors.grey[50]} !important;
`;

export const Card = styled.div<{ isSelected: boolean }>`
  height: 100%;
  padding: 24px 20px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  @media (max-width: 767px) {
    padding: 20px;
  }
`;

export const StyledLi = styled(motion.li)<{
  isSelected?: boolean;
}>`
  border-radius: 16px;
  position: relative;
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.primaryColor[500] : theme.colors.grey[900]};
  cursor: pointer;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const StyledUl = styled.ul`
  all: unset;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 12px;
`;
