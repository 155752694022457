import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import sortBy from "lodash/sortBy";
import map from "lodash/map";
import handleThumbnails from "src/js/modules/imageFunction";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import renderLatex from "src/js/modules/mathjaxFunction";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import Backbone from "@weschooleng/backbone";
import { fetchResource } from "src/js/repository/resourceRepository";
import {
  Box,
  Button,
  ConfirmationAlert,
  Text,
  Icon
} from "@arcadia/design-system";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import styled, { withTheme } from "styled-components";
import { withConfirmDialogStore } from "src/js/hooks/useStores";
import { __ } from "../../../modules/localization";
import {
  createURL,
  formatElapsedTime,
  convertTime
} from "../../../modules/utility";
import ResourceModel from "../../../models/resourceModel";
import QuizCollection from "../../../collections/quizzesCollection";
import VideoEmbed from "../../../components/videoEmbed";
import QuizVideoModel from "../../../models/quiz/quizModel_video";
import QuizBuilderTrueFalse from "./quizBuilderTrueFalse";
import QuizBuilderShortanswer from "./quizBuilderShortanswer";
import QuizBuilderOpenanswer from "./quizBuilderOpenanswer";
import QuizBuilderMultipleChoice from "./quizBuilderMultipleChoice";
import QuizModel from "../../../models/quiz/quizModel";
import { modalPage } from "../../../models/modalPageModel";
import ImagePreviewer from "../../../components/imagePreviewer";
import Dropdown from "../../../components/dropdown";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  showToastError,
  extractErrorMessage
} from "src/js/modules/messageManager";
import ResourceYoutube from "../../resource/resourceManager/resourceYoutube";

const EditTimeWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  input[type="text"] {
    max-width: 120px;
    width: 100%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const QuizBuilderVideo = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        addFunc() {},
        editFunc() {},
        backFunc() {},
        quiz: {},
        exercise: {},
        setChanged() {}
      };
    },
    getInitialState() {
      let { quiz } = this.props;

      if (
        isUndefined(quiz) ||
        isEmpty(quiz) ||
        quiz.getType() !== "quizvideo"
      ) {
        quiz = new QuizVideoModel();
      }

      return {
        status: "listing",
        title: __("quiz_video_execution_label"),
        video: quiz.getVideoId(),
        initialVideoId: quiz.getVideoId(),
        newQuizType: "",
        quizTiming: 0,
        quiz,
        currentTime: 0,
        currentState: "",
        currentTimeFormatted: "",
        videoResource: quiz.getVideoResource(),
        videoStatus: "",
        listEditing: false,
        timeEditing: false,
        mode: "insert"
      };
    },
    form: "#form-quiz-builder-video",
    componentDidMount() {
      $(this.form).on("submit", e => {
        e.preventDefault();
        this.addQuiz(true);
      });

      if (this.state.quiz.isNew()) {
        _trackEvent("Assignments", "AssignmentsNewVideo", "Create");
      }
      renderLatex();
    },
    componentDidUpdate() {
      this.props.setChanged(true);
      renderLatex();
    },
    submitForm() {
      $(this.form).trigger("submit");
    },
    updateTime(currentTime) {
      this.setState({ currentTime });
    },
    updateState(currentState) {
      this.setState({ currentState });
    },
    addQuiz(goBack) {
      const self = this;
      let createQuiz = function () {
        return false;
      };

      this.setState({ status: "loading" });
      if (this.state.quiz.getTimings().length === 0) {
        showToastError({ str: __("Inserire almeno un quiz") });
        this.setState({ status: "" });
      } else {
        createQuiz = function (resource_id) {
          self.state.quiz.cleanTimings();
          const timings = self.state.quiz.getTimings();
          self.state.quiz.set("type", "video");
          self.state.quiz.set("title", self.state.title);
          self.state.quiz.set("resource_id", resource_id);
          self.state.quiz.set("order", 0);
          self.state.quiz.set("timings", timings);
          self.state.quiz.set("quiz_ids", map(timings, "quiz_id"));

          const optionalCallbackSuccess = function (
            model,
            response,
            options,
            data
          ) {
            // console.log(model);
            // console.log(data);
            if (typeof data !== "object") {
              data = JSON.parse(data);
            }
            self.setState({
              quiz: new QuizVideoModel(data),
              status: ""
            });

            if (goBack && typeof this.props.backFunc === "function")
              this.props.backFunc();
          };

          const optionalCallbackError = function () {
            // console.log('error');
            self.setState({ status: "" });
          };

          const options = {
            redirect: false,
            showAlert: false
          };
          self.props.setChanged(false);
          // console.log(self.state.quiz.get('id'));
          // console.log(self.state.quiz);
          if (self.state.quiz.get("id") === undefined) {
            self.props.addFunc(
              self.state.quiz,
              optionalCallbackSuccess.bind(self),
              optionalCallbackError.bind(self),
              options
            );
          } else {
            self.props.editFunc(
              self.state.quiz,
              optionalCallbackSuccess.bind(self),
              optionalCallbackError.bind(self),
              options
            );
          }
        };
      }
      createQuiz(self.state.videoResource.id);
    },
    startVideo() {
      const self = this;
      this.setState(
        {
          videoStatus: "play"
        },
        () => {
          self.setState({
            videoStatus: "",
            listEditing: ""
          });
          // mi assiuro che sul play non ci sia l'overlay
          $(".quiz-builder-video-overlay").addClass("hidden");
          $("#quiz-builder-video-add").prop("disabled", false);
        }
      );
    },
    toggleInnerQuiz(mode) {
      console.info("toggleInnerQuiz", mode);
      const self = this;
      let videoStatus = "";

      if (!self.state.listEditing) {
        // $('.quiz-builder-video-box').hide();
        $(".quiz-builder-video-overlay").removeClass("hidden");
        $("#quiz-builder-video-add").prop("disabled", true);
        videoStatus = "pause";
      } else {
        // $('.quiz-builder-video-box').show();
        $(".quiz-builder-video-overlay").addClass("hidden");
        $("#quiz-builder-video-add").prop("disabled", false);
        videoStatus = "";
      }

      this.setState(
        {
          inner_quiz_id: undefined,
          newQuizType: "",
          quizTiming: self.state.currentTime,
          videoStatus,
          listEditing: !self.state.listEditing,
          timeEditing: false,
          mode
        },
        function () {
          if (self.state.listEditing) {
            if (self.checkQuizTime(new QuizModel({})) || mode !== "insert") {
              $("html, body").animate({
                scrollTop: $(".js-anchor").offset().top
              });
              if (mode === "insert") {
                this.setState({ inner_quiz: undefined });
              }
            } else {
              self.toggleInnerQuiz("insert");
              showToastError({ str: __("video_quiz_timing_already_used") });
            }
          }
        }
      );
    },
    toggleInnerQuizTiming(force) {
      console.info("toggleInnerQuizTiming", force);
      const self = this;
      let videoStatus = "";
      let listEditing = "";
      let timeEditing = "";

      if ((!self.state.listEditing && isUndefined(force)) || force === true) {
        $(".quiz-builder-video-box").hide();
        videoStatus = "pause";
        listEditing = true;
        timeEditing = true;
        $(".quiz-builder-video-overlay").removeClass("hidden");
        $("#quiz-builder-video-add").prop("disabled", true);
      }

      if ((self.state.listEditing && isUndefined(force)) || force === false) {
        $(".quiz-builder-video-box").show();
        videoStatus = "";
        listEditing = false;
        timeEditing = false;
        $(".quiz-builder-video-overlay").addClass("hidden");
        $("#quiz-builder-video-add").prop("disabled", false);
      }

      this.setState({
        videoStatus,
        listEditing,
        timeEditing
      });
    },
    editInnerQuiz(quiz, timing) {
      this.toggleInnerQuiz("edit");
      this.setState({
        inner_quiz: quiz,
        newQuizType: quiz.getType(),
        quizTiming: timing.time
      });
    },
    editInnerQuizTiming(quiz, timing) {
      this.toggleInnerQuizTiming(true);
      this.setState({
        inner_quiz: quiz,
        newQuizType: quiz.getType(),
        quizTiming: timing.time
      });
    },
    removeInnerQuiz(timing) {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={<Translate text="Il quiz per il video sarà eliminato" />}
          declineText={<Translate text="Annulla" />}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={<Translate text="Conferma" />}
          onAcceptFunction={() => {
            $.ajax({
              type: "DELETE",
              dataType: "json",
              contentType: "x-www-form-urlencoded",
              url: createURL("api_exercises_quizzes_entities", {
                exercise_id: self.props.exercise.get("id"),
                quiz_id: timing.quiz_id
              }),
              success() {
                self.state.quiz.removeInnerQuiz(timing);
                self.state.quiz.cleanTimings();
                self.forceUpdate();
              },
              error(data) {
                const errorMsg = extractErrorMessage(
                  data,
                  __("Attenzione, si è verificato un errore")
                );
                showToastError({ str: errorMsg });
              }
            });
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    checkQuizTime(quiz) {
      const quizParent = this.state.quiz;
      const timings = quizParent.getTimings();
      const time = this.state.quizTiming;
      let canUseTiming = true;
      each(timings, timing => {
        if (
          parseInt(time, 10) === parseInt(timing.time, 10) &&
          quiz.get("id") !== timing.quiz_id
        ) {
          canUseTiming = false;
        }
      });

      return canUseTiming;
    },
    saveInnerQuiz(quiz, callbackOk, callbackError) {
      const self = this;
      const quizParent = this.state.quiz;
      const time = this.state.quizTiming;
      const data = JSON.stringify(quiz.toJSON());

      let innerQuiz = new QuizModel();
      if (this.state.inner_quiz !== undefined) {
        innerQuiz = this.state.inner_quiz;
      }

      if (this.checkQuizTime(innerQuiz)) {
        self.setState({ status: "loading" });
        $.ajax({
          type: "POST",
          dataType: "json",
          contentType: "application/json",
          url: createURL("api_exercises_quizzes", {
            exercise_id: this.props.exercise.get("id")
          }),
          data,
          success(data) {
            quizParent.addInnerQuiz(time, data.id, data);
            const quizzes = quizParent.getQuizzes();
            quizzes.push(data);
            self.setState(
              {
                quiz: quizParent,
                status: ""
              },
              () => {
                self.addQuiz();
              }
            );
            self.toggleInnerQuiz("insert");
          },
          error(data) {
            const errorMsg = extractErrorMessage(
              data,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
            if (typeof callbackError === "function") callbackError();
          }
        });
      } else {
        showToastError({ str: __("video_quiz_timing_already_used") });
        self.setState({ status: "" });
      }
    },
    updateInnerQuizTiming(quiz) {
      const self = this;
      const quizTiming = convertTime({
        hours: $("#timing-hours").val(),
        minutes: $("#timing-minutes").val(),
        seconds: $("#timing-seconds").val()
      });

      this.setState({ quizTiming }, function () {
        this.updateInnerQuiz(quiz);
      });
    },
    updateInnerQuiz(quiz) {
      const self = this;
      const quizParent = this.state.quiz;
      const time = this.state.quizTiming;
      const data = JSON.stringify(quiz.toJSON());

      if (this.checkQuizTime(quiz)) {
        $.ajax({
          type: "PUT",
          dataType: "json",
          contentType: "application/json",
          url: createURL("api_exercises_quizzes_entities", {
            exercise_id: this.props.exercise.get("id"),
            quiz_id: quiz.get("id")
          }),
          data,
          success(ret) {
            let quizzes = quizParent.getQuizzes();
            let timings = quizParent.getTimings();
            quizzes = map(quizzes, q => {
              if (q.id === quiz.get("id")) {
                return ret;
              }
              return q;
            });
            timings = map(timings, t => {
              if (t.quiz_id === quiz.get("id")) {
                return { time: self.state.quizTiming, quiz_id: t.quiz_id };
              }
              return t;
            });
            quizParent.setQuizzes(quizzes);
            quizParent.setTimings(timings);

            self.setState({
              quiz: quizParent
            });

            self.toggleInnerQuizTiming(false);
            self.addQuiz();
          },
          error(ret) {
            // alert("nope");
          }
        });
      } else {
        showToastError({ str: __("video_quiz_timing_already_used") });
        self.setState({ status: "" });
      }
    },
    openYoutubeSearch() {
      const resourceManager = (
        <div className="scroll-wrapper modal-resource-wrapper">
          <ResourceYoutube addFunc={this.addResource} setWorking={() => {}} />
        </div>
      );

      modalPage.set({
        body: { content: resourceManager, type: "REACT" },
        footer: {
          content: (
            <Button
              onClick={modalPage.hide}
              variant="secondary"
              theme={this.props.theme.whiteTheme}
              fullWidth={getBreakpoint() === "smartphone"}
            >
              {__("Chiudi")}
            </Button>
          ),
          type: "REACT"
        },
        addCloseButton: false
      });

      modalPage.show();
    },

    hideResourceManager() {
      $(".quiz-builder-video-wrapper").removeClass("hidden");
      $(".resource-manager-container").addClass("hidden");
    },
    addResource(resource) {
      _trackEvent("Assignments", "AssignmentsNewVideo", resource.type);
      fetchResource(resource.id)
        .then(res => {
          this.setState({
            videoResource: res
          });
          modalPage.hide();
        })
        .catch(data => {
          const errorMsg = extractErrorMessage(
            data,
            __("Attenzione, si è verificato un errore")
          );
          showToastError({ str: errorMsg });
        });
    },
    removeResource() {
      return false;
    },
    render() {
      const self = this;
      const { quiz } = this.state;
      let timings = quiz.getTimings();
      const quizzes = new QuizCollection(quiz.getQuizzes());
      const subquizBox = "";
      let innerCont = "";
      const innerButtons = "";
      let saveButton = "";
      let videoBox = "";
      let videoPreview = "";
      let videoOverlay = "";
      let videoEmbed = "";
      let addVideoQuizButton = "";
      const expandButton = "";
      const { whiteTheme } = this.props.theme;

      if (!isEmpty(self.state.videoResource)) {
        let { videoResource } = self.state;
        if (!(videoResource instanceof Backbone.Model)) {
          videoResource = new ResourceModel(self.state.videoResource);
        }

        if (videoResource.get("vimeo_id") != undefined) {
          videoEmbed = (
            <VideoEmbed
              id="quiz-builder-video-embed"
              src={videoResource.get("public_url")}
              updateTime={self.updateTime}
              updateState={self.updateState}
              status={self.state.videoStatus}
              vimeo_id={videoResource.get("vimeo_id")}
              youtube_id={videoResource.get("youtube_id")}
            />
          );
        } else {
          videoEmbed = (
            <VideoEmbed
              id="quiz-builder-video-embed"
              src={videoResource.get("public_url")}
              updateTime={self.updateTime}
              updateState={self.updateState}
              status={self.state.videoStatus}
              youtube_id={videoResource.get("youtube_id")}
            />
          );
        }

        if (self.state.currentTime >= 1) {
          addVideoQuizButton = (
            <div>
              <Button
                onClick={() => {
                  self.toggleInnerQuiz("insert");
                }}
                theme={whiteTheme}
                variant="primary"
              >
                {__("aggiungi quiz a")}{" "}
                {formatElapsedTime(self.state.currentTime)}
              </Button>
              <br />
              {self.state.currentState === "pause" ? (
                <Button
                  onClick={self.startVideo}
                  theme={whiteTheme}
                  variant="secondary"
                >
                  {__("play")}
                </Button>
              ) : null}
            </div>
          );
        } else {
          addVideoQuizButton = (
            <div>
              <Button
                onClick={self.startVideo}
                theme={whiteTheme}
                variant="primary"
              >
                {__("play")}
              </Button>
              <p />
              <div className="alert alert-info">
                {__(
                  "fai partire il video ed aggiungi i quiz al secondo desiderato"
                )}
              </div>
            </div>
          );
        }

        const previewImage = handleThumbnails(
          videoResource.get("preview"),
          "small"
        );
        const is_preview_generated = videoResource.get("is_preview_generated");
        const preview_available_generator = videoResource.get(
          "preview_available_generator"
        );
        const created = videoResource.get("created") * 1000;
        const placeholder = getPlaceholder(
          videoResource.attributes || videoResource
        );
        const background = videoResource.getBackground();

        if (previewImage != undefined) {
          videoPreview = (
            <Box marginBottom={20}>
              <div className="quiz-builder-video-preview col-sm-8 col-lg-6">
                <ImagePreviewer
                  className="quiz-builder-video-preview-img-wrapper"
                  src={placeholder}
                  type="DIV"
                  is_preview_generated={is_preview_generated}
                  preview_available_generator={preview_available_generator}
                  placeholder={placeholder}
                  background={background}
                  created={created}
                  isReady
                />
                <div className="quiz-builder-video-preview-caption">
                  {videoResource.get("name")}
                </div>
                <div className="clearfix" />
              </div>
              <div className="quiz-builder-video-preview-buttons col-sm-4 col-lg-6">
                <a
                  className="quiz-builder-video-change-link"
                  onClick={self.openYoutubeSearch}
                >
                  {__("Cambia video")}
                </a>
              </div>
              <div className="clearfix" />
            </Box>
          );
        }

        videoOverlay = <div className="quiz-builder-video-overlay hidden" />;

        videoBox = (
          <div className="quiz-builder-video-box">
            <div>
              {videoPreview}
              <div className="quiz-builder-video-embed-wrapper col-sm-8 col-lg-8">
                {videoOverlay}
                {videoEmbed}
              </div>
              <div className="quiz-builder-video-buttons col-sm-4 col-lg-4">
                {addVideoQuizButton}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        );

        if (this.state.listEditing) {
          let quizType = null;
          let innerQuiz = new QuizModel();
          let saveFunc = this.saveInnerQuiz;
          let currentType = this.state.newQuizType;
          const innerQuizTiming = convertTime(self.state.quizTiming);

          if (this.state.inner_quiz !== undefined) {
            innerQuiz = this.state.inner_quiz;
            saveFunc = this.updateInnerQuiz.bind(null, innerQuiz);
            currentType = this.state.inner_quiz.getType();
          }

          switch (currentType) {
            case "quiztruefalse":
              quizType = (
                <QuizBuilderTrueFalse
                  addFunc={saveFunc}
                  quiz={innerQuiz}
                  cancelFunc={self.toggleInnerQuiz.bind(null, "insert")}
                />
              );
              break;
            case "quizshortanswer":
              quizType = (
                <QuizBuilderShortanswer
                  addFunc={saveFunc}
                  quiz={innerQuiz}
                  cancelFunc={self.toggleInnerQuiz.bind(null, "insert")}
                />
              );
              break;
            case "quizopenanswer":
              quizType = (
                <QuizBuilderOpenanswer
                  addFunc={saveFunc}
                  quiz={innerQuiz}
                  cancelFunc={self.toggleInnerQuiz.bind(null, "insert")}
                />
              );
              break;
            case "quizmultichoice":
              quizType = (
                <QuizBuilderMultipleChoice
                  addFunc={saveFunc}
                  quiz={innerQuiz}
                  cancelFunc={self.toggleInnerQuiz.bind(null, "insert")}
                />
              );
              break;
          }

          if (self.state.timeEditing) {
            innerCont = (
              <div>
                <Text type="body">
                  {__("Quando deve comparire il quiz per il video?")}
                </Text>
                <form role="form" method="post">
                  <EditTimeWrapper>
                    <Box display="flex" alignItems="center" gap="16px">
                      <input
                        type="text"
                        className="form-control input-number"
                        id="timing-hours"
                        name="timing-hours"
                        defaultValue={innerQuizTiming.hours}
                      />
                      <Text type="body">{__("ORE")}</Text>
                    </Box>
                    <Box display="flex" alignItems="center" gap="16px">
                      <input
                        type="text"
                        className="form-control input-number"
                        id="timing-minutes"
                        name="timing-minutes"
                        defaultValue={innerQuizTiming.minutes}
                      />
                      <Text type="body">{__("MIN")}</Text>
                    </Box>
                    <Box display="flex" alignItems="center" gap="16px">
                      <input
                        type="text"
                        className="form-control input-number"
                        id="timing-seconds"
                        name="timing-seconds"
                        defaultValue={innerQuizTiming.seconds}
                      />
                      <Text type="body">{__("SEC")}</Text>
                    </Box>
                  </EditTimeWrapper>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="16px"
                    gap="16px"
                  >
                    <Button
                      onClick={self.toggleInnerQuizTiming.bind(self, false)}
                      theme={whiteTheme}
                      variant="secondary"
                    >
                      {__("Annulla")}
                    </Button>
                    <Button
                      onClick={self.updateInnerQuizTiming.bind(null, innerQuiz)}
                      theme={whiteTheme}
                      variant="primary"
                    >
                      {__("Conferma")}
                    </Button>
                  </Box>
                </form>
              </div>
            );
          } else {
            let quizTypeSelect = <strong>{this.state.newQuizType}</strong>;

            if (innerQuiz.isNew()) {
              quizTypeSelect = (
                <div className="select-wrapper">
                  <select
                    className="form-control"
                    onChange={e =>
                      this.setState({ newQuizType: e.target.value })
                    }
                  >
                    <option>-</option>
                    <option value="quiztruefalse">{__("quiztruefalse")}</option>
                    <option value="quizshortanswer">
                      {__("quizshortanswer")}
                    </option>
                    <option value="quizopenanswer">
                      {__("quizopenanswer")}
                    </option>
                    <option value="quizmultichoice">
                      {__("quizmultichoice")}
                    </option>
                  </select>
                </div>
              );
            }
            innerCont = (
              <Box marginTop={20}>
                <form role="form" method="post">
                  <Box display="flex" alignItems="center" gap={16}>
                    <div>
                      <div>{__("tempo")}</div>
                      <Text type="formFieldEmpty">
                        {formatElapsedTime(self.state.quizTiming)}
                      </Text>
                    </div>
                    <Box width="100%" marginLeft="24px">
                      <label className="control-label js-anchor">
                        {__("SCEGLI UN TIPO DI QUIZ PER IL VIDEO")}
                      </label>
                      <div className="js-scroll-here">{quizTypeSelect}</div>
                    </Box>
                    <Box alignSelf="flex-end">
                      <Button
                        onClick={self.toggleInnerQuiz.bind(null, "insert")}
                        theme={whiteTheme}
                        variant="secondary"
                      >
                        {__("Annulla")}
                      </Button>
                    </Box>
                  </Box>
                </form>
                {quizType ? <Box marginTop={20}>{quizType}</Box> : null}
              </Box>
            );
          }
        } else if (timings.length > 0) {
          timings = sortBy(timings, t => parseInt(t.time, 10));

          const listSubquiz = timings.map(timing => {
            let listElement = "";
            let listOptionsBox = "";
            const currentTime = timing.time;
            const currentQuizId = timing.quiz_id;
            const currentQuiz = quizzes.get(currentQuizId);

            const dropdownOptions = [];
            dropdownOptions.push(
              <li role="presentation">
                <a
                  role="menuitem"
                  tabIndex="-1"
                  onClick={self.editInnerQuiz.bind(null, currentQuiz, timing)}
                >
                  {__("Modifica quiz")}
                </a>
              </li>
            );
            dropdownOptions.push(
              <li role="presentation">
                <a
                  role="menuitem"
                  tabIndex="-1"
                  onClick={self.editInnerQuizTiming.bind(
                    null,
                    currentQuiz,
                    timing
                  )}
                >
                  {__("Modifica tempo")}
                </a>
              </li>
            );
            dropdownOptions.push(
              <li role="presentation">
                <a
                  role="menuitem"
                  tabIndex="-1"
                  onClick={self.removeInnerQuiz.bind(null, timing)}
                >
                  {__("elimina")}
                </a>
              </li>
            );

            if (currentQuiz !== undefined) {
              listOptionsBox = (
                <div className="dropdown col-xs-2 col-sm-1 col-lg-1 text-right">
                  <a
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>
                      <Icon icon="kebabVertical" width={20} height={20} />
                    </span>
                  </a>
                  <Dropdown items={dropdownOptions} />
                </div>
              );

              listElement = (
                <Box
                  marginTop={16}
                  data-quizid={currentQuiz.get("id")}
                  marginBottom="1px solid #cccccc"
                >
                  <div className=" col-xs-2 col-sm-2 col-lg-1 text-right">
                    <Text type="formFieldEmpty">
                      {formatElapsedTime(currentTime)}
                    </Text>
                  </div>
                  <div className=" col-xs-8 col-sm-9 col-lg-10">
                    <div
                      className="mathjax-container"
                      dangerouslySetInnerHTML={{
                        __html: currentQuiz.get("title")
                      }}
                    />
                    <div>
                      <Text type="label">{__(currentQuiz.getType())}</Text>
                    </div>
                  </div>
                  {listOptionsBox}
                  <div className="clearfix" />
                </Box>
              );
            }
            return listElement;
          });

          innerCont = (
            <Box marginTop={20}>
              <div>
                <div className="col-xs-2 col-sm-2 col-lg-1 text-right">
                  <Text type="body">{__("tempo")}</Text>
                </div>
                <div className="col-xs-8 col-sm-9 col-lg-10">
                  <Text type="body">{__("quizzes")}</Text>
                </div>
                <div className="clearfix" />
              </div>
              {listSubquiz}
              <div className="clearfix" />
            </Box>
          );

          saveButton = (
            <div className="margin-top-15 flex-right-column">
              <Button
                onClick={this.submitForm}
                theme={whiteTheme}
                variant="primary"
              >
                {__("save")}
              </Button>
            </div>
          );
        }
      } else {
        videoBox = (
          <div className=" quiz-builder-selection-group">
            <div className="quiz-builder-video-selection pull-left">
              <Button
                onClick={self.openYoutubeSearch}
                theme={whiteTheme}
                variant="secondary"
              >
                {__("SELEZIONA VIDEO")}
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="no-margin-bottom">
          <div className="quiz-builder-video-wrapper">
            <form
              id="form-quiz-builder-video"
              className="form-quiz-builder"
              role="form"
              method="post"
            >
              {videoBox}
            </form>
            <div className="clearfix" />
            {innerCont}
            <div className="clearfix" />
            {saveButton}
          </div>
          <div className="resource-manager-container hidden" />
        </div>
      );
    }
  })
);

export default withTheme(withConfirmDialogStore(QuizBuilderVideo));
