import { Button } from "@arcadia/design-system";
import { Container } from "react-awesome-styled-grid";
import { motion } from "framer-motion";
import styled from "styled-components";

export const SpaceSecondaryHeaderWrapper = styled.div<{
  isLayoutModeMobile?: boolean;
}>`
  background-color: ${({ theme, isLayoutModeMobile }) =>
    isLayoutModeMobile
      ? theme.colors.headerPrimaryColor
      : theme.colors.grey[1000]};
  padding: ${({ isLayoutModeMobile }) => (isLayoutModeMobile ? "6px 20px" : 0)};
`;

export const ContentWrapper = styled(Container)`
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 767px) {
    padding: 0px 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupNameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const GroupName = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
`;

export const GroupRow = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DotsMenuWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

export const GroupMembersColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
`;

export const GroupMembersAvatarWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
  position: relative;
  & > div {
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 50%;
  }

  & > div:nth-child(1) {
    z-index: 3;
  }

  & > div:nth-child(2) {
    position: absolute;
    z-index: 2;
    right: 16px;
  }

  & > div:nth-child(3) {
    position: absolute;
    z-index: 1;
    right: 32px;
  }
`;

export const RightSection = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const InviteButton = styled(Button)`
  padding: 8px 16px;
`;
