import React from "react";
import { ThemeProvider } from "styled-components";
import { Heading } from "@ds_universal/Heading";
import { Button } from "@ds_universal/data_entry";
import { Text } from "@ds_universal/Text";

import * as S from "./EmptyListCard.styles";
import { EmptyListCardProps } from "./types";

const EmptyListCard = ({
  theme,
  image,
  text,
  title,
  ctaText,
  onClickCta
}: EmptyListCardProps) => {
  return (
    <ThemeProvider theme={theme}>
      <S.EmptyListCardLayout>
        <S.EmptyListCardHeader>{image}</S.EmptyListCardHeader>
        <S.EmptyListCardContent>
          <Heading level="5">{title}</Heading>
          <Text>{text}</Text>
        </S.EmptyListCardContent>
        <S.EmptyListCardActions>
          {onClickCta ? (
            <Button onClick={onClickCta} variant="primary" theme={theme}>
              {ctaText}
            </Button>
          ) : null}
        </S.EmptyListCardActions>
      </S.EmptyListCardLayout>
    </ThemeProvider>
  );
};

export default EmptyListCard;
