import React from "react";
import { ActionButton, Text } from "@arcadia/design-system";
import { useTheme } from "styled-components";

import * as S from "./SingleTaskCard.styles";

const SingleTaskCard = ({
  image,
  isCompleted,
  title,
  description,
  url,
  backgroundColor
}) => {
  const { greyTheme, colors } = useTheme();
  const whiteBk = colors.grey[1000];
  const greyBk = colors.coldGrey[900];
  return (
    <S.Wrapper
      backgroundColor={backgroundColor}
      dismissAnimation={backgroundColor === whiteBk && isCompleted}
    >
      <S.Link href={url} />
      <S.Img src={image} alt="single_task_card_img" />
      <S.WrapperText>
        <Text type="formTitle">{title}</Text>
        <Text type="body">{description}</Text>
      </S.WrapperText>
      <S.WrapperButton>
        {backgroundColor === greyBk ? (
          <ActionButton
            icon={isCompleted ? "check2" : "arrow"}
            onClick={() => {}}
            theme={greyTheme}
            variant="primary"
            disabled={isCompleted}
          />
        ) : (
          <S.ActionCompletedWrapper opacity={+isCompleted}>
            <ActionButton
              icon="check2"
              onClick={() => {}}
              theme={greyTheme}
              variant="primary"
            />
          </S.ActionCompletedWrapper>
        )}
      </S.WrapperButton>
    </S.Wrapper>
  );
};

SingleTaskCard.defaultProps = {
  isCompleted: false
};

export default SingleTaskCard;
