import React from "react";
import {
  __RESOURCE_DOCUMENT_LIMITS__,
  __RESOURCE_DOCUMENT_MIME__,
  __RESOURCE_PDF_LIMITS__,
  __RESOURCE_PDF_MIME__,
  __RESOURCE_SHEET_LIMITS__,
  __RESOURCE_SHEET_MIME__,
  __RESOURCE_SLIDE_LIMITS__,
  __RESOURCE_SLIDE_MIME__,
  __RESOURCE_UPLOAD_LIMITS__,
  __RESOURCE_UPLOAD_MIME__,
  __SCORM_UPLOAD_MIME__,
  __SCORM_UPLOAD_STANDARD_LIMITS__,
  __VIDEO_UPLOAD_MIME__,
  IMAGE_MIME
} from "src/js/settings/settingFileUploader";
import { FileUploaderProps, FileType } from "../FileUploader.types";

const useFileType = (type: FileUploaderProps["type"]) => {
  const limit = React.useMemo(() => {
    switch (type) {
      case FileType.DOCUMENT:
        return __RESOURCE_DOCUMENT_LIMITS__;
      case FileType.FILE:
        return __RESOURCE_UPLOAD_LIMITS__;
      case FileType.PDF:
        return __RESOURCE_PDF_LIMITS__;
      case FileType.SLIDE:
        return __RESOURCE_SLIDE_LIMITS__;
      case FileType.SPREADSHEET:
        return __RESOURCE_SHEET_LIMITS__;
      case FileType.SCORM:
        return __SCORM_UPLOAD_STANDARD_LIMITS__;
      default:
        return __RESOURCE_UPLOAD_LIMITS__;
    }
  }, [type]);

  const mimes = React.useMemo(() => {
    switch (type) {
      case FileType.DOCUMENT:
        return __RESOURCE_DOCUMENT_MIME__;
      case FileType.FILE:
        return __RESOURCE_UPLOAD_MIME__;
      case FileType.PDF:
        return __RESOURCE_PDF_MIME__;
      case FileType.SLIDE:
        return __RESOURCE_SLIDE_MIME__;
      case FileType.SPREADSHEET:
        return __RESOURCE_SHEET_MIME__;
      case FileType.SCORM:
        return __SCORM_UPLOAD_MIME__;
      case FileType.VIDEO:
        return __VIDEO_UPLOAD_MIME__;
      case FileType.IMAGE:
        return IMAGE_MIME;
      default:
        return __RESOURCE_UPLOAD_MIME__;
    }
  }, [type]);

  return { limit, mimes };
};

export default useFileType;
