import React from "react";
import { Text } from "@arcadia/design-system";
import * as S from "./WallBadgesModal.styles";
import { WallBadgeProps } from "../WallBadgeBox/types";

const WallModalBadge = ({ name, image }: WallBadgeProps) => {
  return (
    <S.ModalBadgeContainer>
      <S.ModalBadgeImage src={image} alt="Badge-logo" />
      <S.ModalBadgeName>
        <Text type="formField">{name}</Text>
      </S.ModalBadgeName>
    </S.ModalBadgeContainer>
  );
};

export default WallModalBadge;
