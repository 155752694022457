import each from "lodash/each";
import indexOf from "lodash/indexOf";
import map from "lodash/map";
import QuizModel from "./quizModel";

class QuizMultiChoiceModel extends QuizModel {
  getFormattedUserAnswer() {
    const questions = this.getQuestions();
    const answers = this.getUserAnswer();
    const fomattedObj = map(questions, q => ({
      index: q.index,
      value: (indexOf(answers, q.value) >= 0).toString()
    }));
    return fomattedObj;
  }
  checkUserAnswer() {
    const answers = this.getUserAnswer();
    const solutions = this.getSolutions();
    const questions = this.getQuestions();
    let ret = true;

    each(questions, (val, key) => {
      const ans = indexOf(answers, val.value) >= 0;
      if (solutions[key].value !== ans.toString()) ret = false;
    });

    return ret;
  }
  isFullyAnswered() {
    const answers = this.getUserAnswer();
    return answers.length > 0;
  }
}

export default QuizMultiChoiceModel;
