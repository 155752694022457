import moment from "moment";
import React from "react";
import { useStores } from "src/js/hooks";
import { openExternalLink } from "src/js/modules/commonFunction";
import { CalendarEventModal } from "src/js/pages/calendar/CalendarEventModal";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import {
  EventCategory,
  EventCategoryType
} from "src/js/pages/calendar/Calendar.types";
import * as S from "./CreateEventCarouselButton.styles";

const CreateEventCarouselButton = ({
  eventType = EventCategory.Live
}: {
  eventType: EventCategoryType;
}) => {
  const { ModalStore } = useStores();
  const { translate } = useTranslation();
  const defaultStart = new Date();
  const defaultEnd = new Date(defaultStart);
  defaultEnd.setHours(defaultEnd.getHours() + 1);

  const { greyTheme } = useTheme();

  let buttonString;

  switch (eventType) {
    case EventCategory.Live:
      buttonString = "create_live_carousel_button_label";
      break;
    case EventCategory.Activity:
      buttonString = "create_activity_carousel_button_label";
      break;
    case EventCategory.Deadline:
      buttonString = "create_deadline_carousel_button_label";
      break;
    default:
      buttonString = "create_live_carousel_button_label";
  }

  const handleModalClose = (url: string, start: Date) => {
    const now = moment(new Date());
    const momentStart = moment(start);
    if (momentStart.isBefore(now)) {
      openExternalLink(url);
    }
    ModalStore.closeModal();
  };

  const openCalendarEventModal = () => {
    ModalStore.openModal(() => (
      <CalendarEventModal
        hasCollapsableDates
        defaultStartDate={defaultStart}
        defaultEndDate={defaultEnd}
        closeModal={handleModalClose}
        type={eventType}
      />
    ));
  };

  return (
    <S.StyledButton
      theme={greyTheme}
      variant="secondary"
      onClick={openCalendarEventModal}
    >
      {translate(buttonString)}
    </S.StyledButton>
  );
};

export default CreateEventCarouselButton;
