import React, { FC } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import { LottieProps } from "../types";

const Lottie: FC<LottieProps> = ({
  autoplay = true,
  loop = true,
  ...restProps
}) => {
  return <Player {...restProps} autoplay={autoplay} loop={loop} />;
};

export default Lottie;
