import React from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";

import * as S from "./ActionItem.styles";
import { ActionItemProps } from "./types";

const ActionItem = ({
  theme,
  title,
  subtitle,
  icon,
  betaIndicator,
  isDisabled,
  onClick
}: ActionItemProps) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ActionItemContainer
        whileTap={{ scale: 0.99 }}
        key={title}
        onClick={isDisabled ? () => {} : onClick}
        isDisabled={isDisabled}
      >
        <Icon initialViewbox icon={icon} width={24} height={24} />
        <S.Column>
          <S.TitleContainer>
            <S.Title type="subHeaderTitle">{title}</S.Title>
            {betaIndicator}
          </S.TitleContainer>
          <S.Subtitle type="formSmallCaption" isDisabled={isDisabled}>
            {subtitle}
          </S.Subtitle>
        </S.Column>
      </S.ActionItemContainer>
    </ThemeProvider>
  );
};

export default ActionItem;
