import React, { forwardRef } from "react";
import { Icon } from "@ds_universal/Icon";

import * as S from "./Checkbox.styles";
import { InnerCheckboxProps } from "./types";

const BareCheckbox = forwardRef<HTMLInputElement, InnerCheckboxProps>(
  (
    {
      checked,
      value = "Default",
      disabled,
      onChange,
      onClick,
      name = "group",
      id = "id",
      tabIndex = 0,
      testId,
      variant
    },
    ref
  ) => {
    return (
      <S.CheckboxContainer
        checked={checked}
        disabled={disabled}
        tabIndex={-1}
        variant={variant}
      >
        <S.HiddenCheckbox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          id={id}
          name={name}
          value={value}
          ref={ref}
          placeholder={value}
          tabIndex={tabIndex}
          data-testid={testId}
          variant={variant}
        />
        <S.StyledCheckbox
          checked={checked}
          disabled={disabled}
          variant={variant}
        >
          <Icon icon="check2" width="10" height="10" />
        </S.StyledCheckbox>
      </S.CheckboxContainer>
    );
  }
);

BareCheckbox.displayName = "Checkbox";

export default BareCheckbox;
