import React from "react";
import * as S from "./ModalScrollableBody.styles";

const ModalScrollableBody = ({
  children,
  maxHeight,
  maxHeightOffset,
  flexHeight,
  flexHeightOffset
}) => {
  return (
    <S.ModalScrollableArea
      maxHeight={maxHeight}
      maxHeightOffset={maxHeightOffset}
      flexHeight={flexHeight}
      flexHeightOffset={flexHeightOffset}
    >
      {children}
    </S.ModalScrollableArea>
  );
};

export default ModalScrollableBody;
