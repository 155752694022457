import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const PillBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 120px;
  padding: 4px;
  width: 100%;
`;

export const Pill = styled(motion.div)<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 4px;
  position: relative;
  border-radius: 120px;
  padding: 12px;
  user-select: none;
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.colors.grey[1000] : theme.primaryColor[500]};
`;

export const PillText = styled(Text)<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.grey[1000] : theme.primaryColor[500]};
  z-index: 1;
`;

export const Background = styled(motion.div)`
  background-color: ${({ theme }) => theme.primaryColor[500]};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 120px;
`;
