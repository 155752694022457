import React, { useState } from "react";
import { OldResource } from "src/js/types";
import ImagePreviewer from "src/legacy/components/imagePreviewer";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import ResourceViewer from "../../resource/ResourceViewer";

const QuizMatchingCorrectionItem = ({
  item,
  className
}: {
  item: OldResource;
  className: string;
}) => {
  const [resourceViewer, setResourceViewer] = useState(null);

  const show = () => {
    setResourceViewer(
      <ResourceViewer
        disableOption
        startingPosition={0}
        resources={[item]}
        onClose={() => {
          setResourceViewer(null);
        }}
      />
    );
  };
  return (
    <>
      <div
        className={["quiz_matching-correction-resource", className].join(" ")}
        onClick={show}
      >
        {/* @ts-expect-error - Legacy stuff */}
        <ImagePreviewer
          className="prewiew-image"
          src={getPlaceholder(item)}
          type="DIV"
          background="#8b5cff"
          isReady
        />
        <div className="title">{item.name}</div>
      </div>
      {resourceViewer}
    </>
  );
};

export default QuizMatchingCorrectionItem;
