import { FabButton } from "@arcadia/design-system";
import styled from "styled-components";

export const TopFiltersContainer = styled.div`
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

export const SearchbarWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const RowWithMargin = styled.div`
  margin-bottom: 16px;
`;

export const FlexWrapper = styled.div`
  flex: 1;
  padding: 0 0.4375rem;
`;

export const StyledFabButton = styled(FabButton)`
  z-index: 2;
`;

export const RightAlignWrapper = styled.div`
  text-align: right;
  display: flex;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const SearchButtonWrapper = styled.div`
  padding: 0 0.4375rem;
`;

export const SearchForm = styled.form`
  position: relative;
`;

export const Label = styled.span`
  display: inline-block;
  padding-left: 0.4375rem;
  margin-right: 16px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
