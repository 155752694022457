import styled from "styled-components";

export const Wrapper = styled.div<{ isBeingDraggedOver: boolean }>`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.violet[501]};
  color: ${({ theme }) => theme.colors.grey[1000]};
  opacity: ${({ isBeingDraggedOver }) => (isBeingDraggedOver ? 0.5 : 1)};
  padding: 30px;
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
`;
