import React, { useState } from "react";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import { createUrl } from "src/js/modules/routing";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import EngPolicy from "./EngPolicy";
import ItaPolicy from "./ItaPolicy";

import * as S from "./PrivacyPolicy.styles";

const PrivacyPolicy = () => {
  const { colors } = useTheme();
  const [defaultLang, setDefaultLang] = useState(true);

  return (
    <S.PageWrapper>
      <S.TitleWrapper
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: "24px",
          position: "relative"
        }}
      >
        <WeSchoolLogoSVG
          color={colors.violet[501]}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigateTo(createUrl("home"));
          }}
        />
        <S.LangButton type="button" onClick={() => setDefaultLang(val => !val)}>
          {defaultLang ? "Ita 🇮🇹" : "Eng 🇬🇧"}
        </S.LangButton>
      </S.TitleWrapper>
      {defaultLang ? <EngPolicy /> : <ItaPolicy />}
    </S.PageWrapper>
  );
};

export default PrivacyPolicy;
