import { useCallback, useMemo, useState } from "react";
import { Editor } from "@tiptap/core";
import { IconName } from "@arcadia/design-system";
import { TranslationKeys } from "src/js/translation";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiTextEditorEvent, EventDomain } from "src/js/types";
import { TextOptions } from "@tiptap-pro/extension-ai";
import {
  AICustomPromptInitialTransformations,
  AICustomPromptToneTransformations,
  AICustomPromptTransformationValue
} from "./AICustomPromptEditor.types";
import {
  __INITIAL_TRANSFORMATIONS__,
  __TONE_TRANSFORMATIONS__
} from "./AICustomPromptEditor.const";

type CustomPromptEditProps = {
  leadingIcon?: IconName;
  trailingIcon?: IconName;
  title?: TranslationKeys;
  action: (query?: string) => void;
};

const config: TextOptions = {
  modelName: "gpt-4o",
  stream: true
};

export const useAICustomPromptActions = (editor: Editor) => {
  const [items, setItems] = useState<AICustomPromptTransformationValue[]>(
    __INITIAL_TRANSFORMATIONS__
  );
  const [isSubMenu, setIsSubMenu] = useState(false);

  const onGoBack = useCallback(() => {
    setItems(__INITIAL_TRANSFORMATIONS__);
    setIsSubMenu(false);
  }, []);

  const customPromptActions = useMemo<
    Record<AICustomPromptTransformationValue, CustomPromptEditProps>
  >(
    () => ({
      [AICustomPromptInitialTransformations.ChangeTone]: {
        title: "ai_editor_change_tone",
        leadingIcon: "microphoneEmpty",
        trailingIcon: "chevronRight",
        action: () => {
          setItems(__TONE_TRANSFORMATIONS__);
          setIsSubMenu(true);
        }
      },
      [AICustomPromptInitialTransformations.Extend]: {
        leadingIcon: "extend",
        title: "ai_editor_extend",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandExtend,
            editor.getText()
          );
          editor.chain().focus().selectAll().aiExtend(config).run();
        }
      },
      [AICustomPromptInitialTransformations.Simplify]: {
        leadingIcon: "simplify",
        title: "ai_editor_simplify",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandSimplify,
            editor.getText()
          );
          editor.chain().focus().selectAll().aiSimplify(config).run();
        }
      },
      [AICustomPromptInitialTransformations.Summarise]: {
        title: "ai_editor_summarise",
        leadingIcon: "summarise",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandSummarise,
            editor.getText()
          );
          editor.chain().focus().selectAll().aiSummarize(config).run();
        }
      },
      [AICustomPromptInitialTransformations.TryAgain]: {
        title: "ai_editor_try_again",
        leadingIcon: "undo",
        action: query => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandTryAgain,
            query
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiTextPrompt({ text: query, ...config })
            .run();
        }
      },
      [AICustomPromptInitialTransformations.Shorten]: {
        leadingIcon: "shorten",
        title: "ai_editor_shorten",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandShorten,
            editor.getText()
          );
          editor.chain().focus().selectAll().aiShorten(config).run();
        }
      },
      [AICustomPromptToneTransformations.Default]: {
        title: "ai_editor_tone_default",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneDefault,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Default, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Academic]: {
        title: "ai_editor_tone_academic",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneAcademic,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Academic, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Business]: {
        title: "ai_editor_tone_business",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneBusiness,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Business, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Casual]: {
        title: "ai_editor_tone_casual",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneCasual,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Casual, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Childfriendly]: {
        title: "ai_editor_tone_child_friendly",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneChildFriendly,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(
              AICustomPromptToneTransformations.Childfriendly,
              config
            )
            .run();
        }
      },
      [AICustomPromptToneTransformations.Confident]: {
        title: "ai_editor_tone_confident",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneConfident,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Confident, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Conversational]: {
        title: "ai_editor_tone_conversational",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneConversational,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(
              AICustomPromptToneTransformations.Conversational,
              config
            )
            .run();
        }
      },
      [AICustomPromptToneTransformations.Creative]: {
        title: "ai_editor_tone_creative",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneCreative,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Creative, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Emotional]: {
        title: "ai_editor_tone_emotional",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneEmotional,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Emotional, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Excited]: {
        title: "ai_editor_tone_excited",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneExcited,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Excited, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Formal]: {
        title: "ai_editor_tone_formal",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneFormal,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Formal, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Friendly]: {
        title: "ai_editor_tone_friendly",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneFriendly,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Friendly, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Funny]: {
        title: "ai_editor_tone_funny",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneFunny,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Funny, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Humorous]: {
        title: "ai_editor_tone_humorous",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneHumorous,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Humorous, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Informative]: {
        title: "ai_editor_tone_informative",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneInformative,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Informative, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Inspirational]: {
        title: "ai_editor_tone_inspirational",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneInspirational,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(
              AICustomPromptToneTransformations.Inspirational,
              config
            )
            .run();
        }
      },
      [AICustomPromptToneTransformations.Memeify]: {
        title: "ai_editor_tone_memeify",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneMemeify,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Memeify, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Narrative]: {
        title: "ai_editor_tone_narrative",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneNarrative,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Narrative, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Objective]: {
        title: "ai_editor_tone_objective",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandToneObjective,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Objective, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Persuasive]: {
        title: "ai_editor_tone_persuasive",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandTonePersuasive,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Persuasive, config)
            .run();
        }
      },
      [AICustomPromptToneTransformations.Poetic]: {
        title: "ai_editor_tone_poetic",
        action: () => {
          _trackEvent(
            EventDomain.AiTextEditor,
            AiTextEditorEvent.AiWriterCommandTonePoetic,
            editor.getText()
          );
          editor
            .chain()
            .focus()
            .selectAll()
            .aiAdjustTone(AICustomPromptToneTransformations.Poetic, config)
            .run();
        }
      }
    }),
    [editor]
  );

  return { items, customPromptActions, onGoBack, isSubMenu };
};
