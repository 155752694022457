import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { Board, TODO } from "src/js/types";
import { TopHeaderSpacesMobile } from "../TopHeaderSpacesMobile";
import { useMobileHeader } from "./useMobileHeader";

import * as S from "./TopHeaderMobileWrapper.styles";

const TopHeaderMobileWrapper = () => {
  const {
    UIStore: { isMobileHeaderVisible },
    BoardsStore: { getActiveBoardId, getSingleBoard },
    GroupStore: { groupId, activeGroup, roles },
    UserStore: { activeUser },
    ExerciseStore: { activeExerciseName }
  } = useStores();
  const activeBoard: Board = getSingleBoard({
    groupId,
    boardId: getActiveBoardId
  });

  const { shouldDisplayWhiteHeader } = useMobileHeader(
    activeBoard,
    activeExerciseName,
    activeUser as TODO,
    activeGroup,
    roles
  );

  if (!isMobileHeaderVisible) return null;

  return (
    <S.Wrapper>
      {shouldDisplayWhiteHeader() ? undefined : <TopHeaderSpacesMobile />}
    </S.Wrapper>
  );
};

export default observer(TopHeaderMobileWrapper);
