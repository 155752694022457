import styled from "styled-components";

type NotificationContainerProps = {
  isMobile: boolean;
};

export const NotificationContainer = styled.div<NotificationContainerProps>`
  font-size: 24px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${({ theme, isMobile }) =>
    isMobile ? undefined : theme.colors.grey[1000]};
  text-align: center;
  color: ${({ theme, isMobile }) =>
    isMobile ? theme.colors.headerTextColor : theme.colors.grey[50]};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;

export const NotificationCount = styled.span`
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 6px;
  z-index: 101;
  padding: 0px 4px;
  height: 12px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.yellow[500]};
  box-shadow: 0 0 1px ${({ theme }) => theme.colors.yellow[500]};
  color: ${({ theme }) => theme.colors.grey[50]};
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;

  @media screen and (max-width: 767px) {
    top: -8px;
    right: -8px;
  }
`;
