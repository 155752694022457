import { ObjectValues } from "src/js/types";

export const EDIT_FIELD = {
  COLOR: "color",
  NAME: "name",
  IS_MANDATORY: "isMandatory",
  IS_MILESTONE: "isMilestone",
  MILESTONE_MESSAGE: "milestoneMessage",
  IS_OPEN: "isOpen",
  HAS_MANDATORY_ORDER: "hasMandatoryOrder"
} as const;

export type EditField = ObjectValues<typeof EDIT_FIELD>;
