import {
  Page,
  Text,
  View,
  Image,
  Document,
  Link,
  Font
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { colors } from "@arcadia/design-system";
import React from "react";
import WeSchoolLogo from "src/img/weschool_logo.png";
import { OpenBadgeCertificateProps } from "src/js/pages/spaces/dashboard/SpacesBadges/components/DownloadPDFCertificate/types";
import { useTranslation } from "src/js/translation";
import { styles } from "./styles";

Font.register({
  family: "WeSchoolSans",
  fonts: [
    { src: "/assets/font/WeSchoolSans-Regular.ttf", fontWeight: "normal" },
    { src: "/assets/font/WeSchoolSans-Medium.ttf", fontWeight: "medium" },
    { src: "/assets/font/WeSchoolSans-Bold.ttf", fontWeight: "bold" }
  ]
});

const OpenBadgeCertificate = ({
  awardedAt,
  badgeDescription,
  badgeImage,
  badgeName,
  expiredAt,
  openBadgePublicUrl,
  userFullName,
  spaceName,
  spaceImage,
  spaceLayoutColour
}: OpenBadgeCertificateProps) => {
  const { translate } = useTranslation();
  const themeColour = spaceLayoutColour || colors.violet[501];
  const withExpirationDate = !!expiredAt;

  return (
    <Document>
      <Page
        size="A4"
        style={[styles.page, { border: `14px solid ${themeColour}` }]}
        orientation="landscape"
      >
        <View style={styles.section}>
          <Image source={badgeImage} style={styles.badgeImage} />
          <Text style={styles.badgeName}>{badgeName}</Text>
          <Text style={styles.badgeAwardedTitle}>
            {translate("badge_pdf_certificate_awarded_to_label")}
          </Text>
          <Text style={styles.awardedUser}>{userFullName}</Text>
          <View style={styles.datesContainer}>
            <View style={styles.flexContainer}>
              <Text style={styles.baseText}>
                {translate("badge_pdf_certificate_issued_on_label")}&nbsp;
              </Text>
              <Text style={styles.boldText}>
                {format(new Date(awardedAt), "d MMMM yyyy")}
              </Text>
            </View>
            {withExpirationDate ? (
              <View style={styles.flexContainer}>
                <Text style={styles.baseText}>
                  {translate("badge_pdf_certificate_expires_on_label")}&nbsp;
                </Text>
                <Text style={styles.boldText}>
                  {format(new Date(expiredAt), "d MMMM yyyy")}
                </Text>
              </View>
            ) : null}
          </View>
          <Text style={styles.badgeDescription}>{badgeDescription}</Text>
          <View style={{ margin: "0px auto" }}>
            <Image src={spaceImage?.small} style={styles.spaceImage} />
            <Text style={[styles.mediumText, { color: themeColour }]}>
              {spaceName}
            </Text>
          </View>
          <View
            style={[
              styles.flexContainer,
              { justifyContent: "space-between", marginTop: "auto" }
            ]}
          >
            <View style={[styles.flexContainer, { alignItems: "center" }]}>
              <Text style={styles.baseText}>
                {translate("badge_pdf_certificate_powered_by_label")}&nbsp;
              </Text>
              <Image src={WeSchoolLogo} style={{ width: 80, height: 12 }} />
            </View>
            <Link
              style={[styles.link, { color: themeColour }]}
              href={openBadgePublicUrl}
            >
              {translate("badge_pdf_certificate_public_page_link")}
            </Link>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OpenBadgeCertificate;
