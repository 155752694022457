import { __ } from "../../modules/localization";
import filter from "lodash/filter";
import each from "lodash/each";
import find from "lodash/find";
import QuizModel from "./quizModel";

class QuizMatchingModel extends QuizModel {
  /** This quiz has no title. Return the instructions instead */
  getTitle() {
    return __("quiz_matching_execution_label");
  }
  getUserAnswer() {
    return this.get("userAnswer") || [];
  }
  getFormattedUserAnswer() {
    return this.getUserAnswer();
  }
  checkUserAnswer() {
    const answers = this.getUserAnswer();
    const solutions = this.getSolutions();

    let ret = true;

    each(solutions, val => {
      const related_answer = find(answers, { index_1: val.index_1 });
      if (
        related_answer === undefined ||
        related_answer.index_2 !== val.index_2
      )
        ret = false;
    });

    return ret;
  }
  isFullyAnswered() {
    const questions = this.getQuestions();
    const answers = this.getUserAnswer();
    const expected_answers = filter(questions, { column: 1 });
    return answers.length === expected_answers.length;
  }
}

export default QuizMatchingModel;
