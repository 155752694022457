import { BoardElement } from "src/js/types";

export const getMaxPosition = ({
  boardElements
}: {
  boardElements: BoardElement[];
}) => {
  let missingPositions = 0;
  let maxPosition = boardElements.length;
  boardElements.forEach(boardElement => {
    if (!boardElement.position) {
      missingPositions = +1;
    }
    if (boardElement.position > maxPosition) {
      maxPosition = boardElement.position;
    }
  });

  return maxPosition + missingPositions;
};
