import { motion } from "framer-motion";
import styled from "styled-components";

export const Item = styled(motion.div)`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  border-radius: 4px;
  cursor: grab;
`;
