import { InternalDispatcher } from "./dispatcher";
import { getActiveGroupId } from "./activeGroup";
import { __IMAGE_SIZE_LIMIT__ } from "../settings/settings";
import { createURL } from "./utility";
import { __ } from "./localization";
import { __IMG_LOADING__ } from "src/js/settings/settingsImage";
import { fetchResource } from "src/js/repository/resourceRepository";
import { showToastError } from "src/js/modules/messageManager";

export const textEditorManager = {
  settings: {
    text: {
      selector: ".text-editor",
      height: 200,
      plugins: "autolink, paste, textcolor, link, table",
      default_link_target: "_blank",
      target_list: false,
      menubar: false,
      statusbar: false,
      // autoresize_bottom_margin: 0,
      // autoresize_min_height: 200,
      // autoresize_max_height: 800,
      extended_valid_elements: "a[href|target=_blank]",
      toolbar:
        "styleselect | bold italic underline removeformat | strikethrough superscript subscript | fontsizeselect | forecolor backcolor | bullist numlist | alignleft aligncenter alignright alignjustify indent outdent | link table",
      content_style: "img {max-width: 100%;}",
      image_caption: false,
      image_dimensions: false,
      file_browser_callback_types: "image",
      file_browser_callback(field_name, url, type, win) {
        if (type == "image") $("#text-editor-upload-form input").click();
      },
      setup(editor) {
        /**
         * Handle change both on keyUp and on change
         * because change is only triggered when tinymce adds an undo layer.
         * On IOS sometimes it is not triggered, so we need a keyUp event too.
         */
        editor.on("change", () => {
          InternalDispatcher.trigger("textEditorChange");
        });
        editor.on("keyUp", e => {
          InternalDispatcher.trigger("textEditorChange");
        });
      }
    },
    exercise: {
      selector: ".text-editor",
      height: 200,
      plugins: "autolink, paste, image, link",
      default_link_target: "_blank",
      target_list: false,
      menubar: false,
      statusbar: false,
      invalid_elements: "div",
      // autoresize_bottom_margin: 0,
      // autoresize_min_height: 200,
      // autoresize_max_height: 800,
      toolbar: "bold italic underline | superscript subscript | image link",
      content_style: "img {max-width: 100%;}",
      image_caption: false,
      image_dimensions: false,
      file_browser_callback_types: "image",
      file_browser_callback(field_name, url, type, win) {
        if (type == "image") $("#text-editor-upload-form input").click();
      },
      setup(editor) {
        /**
         * Handle change both on keyUp and on change
         * because change is only triggered when tinymce adds an undo layer.
         * On IOS sometimes it is not triggered, so we need a keyUp event too.
         */
        editor.on("keyUp", e => {
          InternalDispatcher.trigger("textEditorChange");
        });
        editor.on("change", () => {
          InternalDispatcher.trigger("textEditorChange");
        });
      }
    },
    mobile: {
      selector: ".text-editor",
      height: 150,
      plugins: "autolink, paste, image, link",
      default_link_target: "_blank",
      target_list: false,
      menubar: false,
      statusbar: false,
      toolbar: "bold italic underline | superscript subscript | image link",
      content_style: "img {max-width: 100%;}",
      image_caption: false,
      image_dimensions: false,
      file_browser_callback_types: "image",
      file_browser_callback(field_name, url, type, win) {
        if (type == "image") $("#text-editor-upload-form input").click();
      },
      setup(editor) {
        editor.on("init", e => {
          $(".mce-btn").on("click touchend", e => {
            e.preventDefault();
            const myBookmark = editor.selection.getBookmark();
            editor.selection.moveToBookmark(myBookmark);
            editor.focus();
          });
        });

        /**
         * Handle change both on keyUp and on change
         * because change is only triggered when tinymce adds an undo layer.
         * On IOS sometimes it is not triggered, so we need a keyUp event too.
         */
        editor.on("keyUp", e => {
          InternalDispatcher.trigger("textEditorChange");
        });
        editor.on("change", () => {
          InternalDispatcher.trigger("textEditorChange");
        });
      }
    },
    resourceText: {
      selector: ".text-editor",
      height: 200,
      plugins: "autolink, paste, link",
      default_link_target: "_blank",
      target_list: false,
      menubar: false,
      statusbar: false,
      extended_valid_elements: "a[href|target=_blank]",
      toolbar:
        "styleselect | bold italic strikethrough | link | alignleft aligncenter alignright alignjustify",
      content_style: "img {max-width: 100%;}",
      image_caption: false,
      image_dimensions: false,
      file_browser_callback_types: "image",
      file_browser_callback(field_name, url, type, win) {
        if (type == "image") $("#text-editor-upload-form input").click();
      },
      setup(editor) {
        /**
         * Handle change both on keyUp and on change
         * because change is only triggered when tinymce adds an undo layer.
         * On IOS sometimes it is not triggered, so we need a keyUp event too.
         */
        editor.on("change", () => {
          InternalDispatcher.trigger("textEditorChange");
        });
        editor.on("keyUp", e => {
          InternalDispatcher.trigger("textEditorChange");
        });
      }
    }
  },
  getContent(el) {
    const editor = tinymce.get($(el).attr("id"));
    if (editor === null) {
      return $(el).val(); // fallback se tiny non è caricato correttamente
    }
    return editor.getContent();
  },
  setContent(el, content) {
    tinymce.get(el).setContent(content);
  },
  destroy(ed_id) {
    if (typeof tinymce !== "undefined") {
      tinymce.execCommand("mceRemoveEditor", true, ed_id);
    }
  },
  uploadImage() {
    const formData = new FormData($("#text-editor-upload-form")[0]);
    const file = $("#text-editor-file-input")[0].files[0];

    if (file.size > __IMAGE_SIZE_LIMIT__) {
      showToastError({ str: __("file_too_big_error", { name: file.name }) });
      $("#text-editor-file-input").replaceWith(
        $("#text-editor-file-input").clone(true)
      );
    } else {
      $(".mce-window").append(
        `<div class="mce-window-loading-overlay-custom" style="width:100%;height:100%;z-index:999999;opacity:0.4;background-color:#e3e3e3;position:absolute;top:0;left:0;"><img class="mce-window-loading-icon" src="${__IMG_LOADING__}" style="position:absolute;top:37%;left:45%;z-index:999999;" /></div>`
      );
      $.ajax({
        url: createURL("api_resources"),
        data: formData,
        method: "POST",
        contentType: false,
        cache: false,
        processData: false
      }).done(data => {
        fetchResource(data.id)
          .then(res => {
            $(".mce-window-loading-overlay-custom").remove();
            $(".mce-btn.mce-open")
              .parent()
              .find(".mce-textbox")
              .val(res.public_url)
              .closest(".mce-window")
              .find(".mce-primary")
              .click();
          })
          .catch(data => {
            const errorMsg = extractErrorMessage(
              data,
              __("Attenzione, si è verificato un errore")
            );
            showToastError({ str: errorMsg });
            $(".mce-window-loading-overlay-custom").remove();
          });
      });
    }
  },
  init(el, type, lang, options) {
    const lib_id = "tinymce-ajax";
    let settings = textEditorManager.settings.text;

    settings.selector = el;

    if (typeof type !== "undefined") {
      settings = textEditorManager.settings[type];
    }

    if (typeof options !== "undefined") {
      settings = options;
    }
    // if (typeof lang !== 'undefined' && lang != 'en_US') {
    //   settings.language_url = getTinyMceLanguagePath(lang);
    // }

    window.editor = this; // todo: riformattare in modo che non sia setteta sempre una valiabile globale

    if ($("#text-editor-upload-form").length == 0) {
      $("body").append(
        `${
          '<iframe id="text-editor-upload-iframe" name="textEditorUploadIframe" style="display:none"></iframe>' +
          '<form id="text-editor-upload-form" target="textEditorUploadIframe" method="post" enctype="multipart/form-data" style="width:0; height:0; overflow:hidden;"><input type="hidden" name="name" value=""/><input type="hidden" name="type" value="IMAGE"/><input type="hidden" name="group_id" value="'
        }${getActiveGroupId()}"/><input type="file" id="text-editor-file-input" name="file" accept="image/*" onchange="window.editor.uploadImage();"/></form>`
      );
    }

    if ($(`#${lib_id}`).length === 0) {
      let head = document.getElementsByTagName("head")[0],
        script;
      script = document.createElement("script");
      script.id = lib_id;
      script.type = "text/javascript";
      script.src = "./lib/tinymce/tinymce.min.js";
      // normal browsers
      script.onload = function () {
        tinymce.init(settings);
      };

      // IE
      script.onreadystatechange = function () {
        if (js.readyState in { complete: 1, loaded: 1 }) {
          tinymce.init(settings);
        }
      };

      head.appendChild(script);
    } else {
      try {
        tinymce.init(settings);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
