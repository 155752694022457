import createReactClass from "create-react-class";
import "jquery-ui";
import React from "react";
import withBackbone from "with-backbone";
import { ConfirmationAlert } from "@arcadia/design-system";
import { withConfirmDialogStore, withGroupStore } from "src/js/hooks/useStores";
import { withTheme } from "styled-components";
import {
  showToastError,
  showToastSuccess,
  extractErrorMessage
} from "src/js/modules/messageManager";
import ExerciseModel from "../../models/exerciseModel";
import { getActiveGroup } from "../../modules/activeGroup";
import { __ } from "../../modules/localization";
import { basePageModel } from "../../modules/pageStatus";
import ExercisesListItem from "./exercisesListItem";

const ExerciseDetailView = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        exercise: new ExerciseModel(),
        filter: "ASSIGNMENT"
      };
    },
    getInitialState() {
      return {
        exercise: this.props.exercise
      };
    },
    componentWillMount() {
      const group = getActiveGroup();
      basePageModel.set("selectedTab", "esercizi");
      basePageModel.set("pageTitle", group.get("name"));
    },

    doInstant() {
      const self = this;
      const report = self.state.exercise.get("report_stats");
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      if (
        report.users_failed_count + report.users_passed_count != 0 &&
        self.state.exercise.isVerification()
      ) {
        ConfirmDialogStore.openConfirmDialog(
          <ConfirmationAlert
            theme={whiteTheme}
            text={__("launch_instant_check")}
            declineText={__("Annulla")}
            onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
            acceptText={__("Conferma")}
            onAcceptFunction={() => {
              self.launchInstant();
              ConfirmDialogStore.closeConfirmDialog();
            }}
          />
        );
      } else {
        self.launchInstant();
      }
    },
    launchInstant() {
      const callbackOk = function () {
        showToastSuccess({ str: __("Instant lanciato") });
      };
      const callbackError = function (data) {
        const errorMsg = extractErrorMessage(
          data,
          __("Attenzione, si è verificato un errore")
        );
        showToastError({ str: errorMsg });
      };
      self.state.exercise.doInstant(callbackOk, callbackError);
    },
    render() {
      const self = this;
      const { GroupStore } = self.props;
      const exerciseModel = self.state.exercise;

      return (
        <ExercisesListItem
          exercise={exerciseModel}
          doInstantFunc={self.doInstant}
          numberOfStudents={GroupStore?.studentsCount}
          key={exerciseModel.id}
        />
      );
    }
  })
);

export default withConfirmDialogStore(
  withTheme(withGroupStore(ExerciseDetailView))
);
