import { setLocale } from "src/js/translation/TranslationProvider";
import { observer } from "mobx-react";
import { useStores } from "src/js/hooks";

export default observer(() => {
  const { UserStore } = useStores();

  if (!UserStore.activeUser) return null;

  setLocale(UserStore.activeUser.language);

  return null;
});
