import { Box, Button, Input } from "@arcadia/design-system";
import React from "react";
import { TextResourceProps } from "src/js/components/ResourcePicker/components/AddTextResource/AddTextResource.types";
import { BaseTextEditor } from "src/js/components/global/BaseTextEditor";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { useTextResourceState } from "../hooks";

import * as S from "./AddTextResource.styles";

// This file is used in Chat and Exercise, no AIExtensions and no autosave
const AddTextResource = ({
  onSuccess,
  onCancel,
  onClose,
  onSubmit,
  setUploadingProgress,
  elementId,
  prepareWSResource
}: TextResourceProps) => {
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { editor, setTitle, submitFunction, textEditorMenu } =
    useTextResourceState({
      prepareWSResource,
      onSuccess,
      onSubmit,
      onClose,
      setUploadingProgress,
      elementId
    });
  const { whiteTheme, greyTheme } = useTheme();
  return (
    <>
      <Box marginBottom={20} marginLeft={24} marginRight={24}>
        <Input
          name="title"
          placeholder={translate("resource_text_title_placeholder")}
          onChange={e => {
            setTitle(e.target.value);
          }}
          theme={isLayoutModeMobile ? greyTheme : whiteTheme}
          label=""
          hideLabel
          rounded
        />
      </Box>
      <S.EditorWrapper>
        <BaseTextEditor
          className="EditorWrapper"
          floatingMenu={textEditorMenu}
          bubbleMenu={textEditorMenu}
          editor={editor}
        />
      </S.EditorWrapper>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={12}
        marginTop={20}
      >
        <Button
          fullWidth={isLayoutModeMobile}
          onClick={onCancel}
          theme={whiteTheme}
          variant="secondary"
        >
          {translate("resource_text_editor_cancel")}
        </Button>
        <Button
          fullWidth={isLayoutModeMobile}
          onClick={submitFunction}
          disabled={!editor?.getText().trim()}
          theme={whiteTheme}
          variant="primary"
        >
          {translate("resource_text_editor_save")}
        </Button>
      </Box>
    </>
  );
};

export default AddTextResource;
