import { history } from "Legacy/modules/history";
import React from "react";
import { Router } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import CookieBot from "src/js/CookieBot";
import InitChargebee from "src/js/InitChargebee";
import InitUsetifulTags from "src/js/InitUsetifulTags";
import InitZendesk from "src/js/InitZendesk";
import { ConfirmDialog } from "src/js/components/ConfirmationDialog";
import ErrorBoundary from "src/js/components/global/ErrorBoundary";
import { SetCustomerTheme } from "src/js/components/global/SetCustomerTheme";
import Modal from "src/js/components/modal/Modal";
import { TasksStatusWidget } from "src/js/components/task/TasksStatusWidget";
import { UploadWidget } from "src/js/components/widget/UploadWidget";
import BoardProvider from "src/js/context/BoardProvider";
import { StoreProvider } from "src/js/context/StoreProvider";
import { ViewTrackingProvider } from "src/js/context/ViewTrackingProvider";
import FeatureFlagProvider from "src/js/feature_flag/FeatureFlagProvider";
import InitWebSocketListeners from "src/js/init/InitWebSocketListeners";
import SetLegacyActiveUserModel from "src/js/init/SetLegacyActiveUserModel";
import SetupAjaxQuery from "src/js/init/SetupAjaxQuery";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { PagesRouter } from "src/js/pages/PagesRouter";
import { PersistedQueryProvider } from "src/js/query";
import { RealTimeProvider } from "src/js/realtime";
import RealtimeSubscribeList from "src/js/realtime/RealtimeSubscribeList";
import ThemeProvider from "src/js/theme/ThemeProvider";
import GTMLocalStorageAligner from "src/js/tracking/GTMLocalStorageAligner";
import WSAnalytics from "src/js/tracking/WSAnalytics";
import SetUserLocale from "src/js/translation/SetUserLocale";
import TranslationProvider from "src/js/translation/TranslationProvider";
import { PopoverNotificationItem } from "src/js/components/notification/PopoverNotificationItem";

const App = () => {
  return (
    <StoreProvider>
      <ThemeProvider>
        <PersistedQueryProvider>
          <TranslationProvider>
            <ErrorBoundary>
              <FeatureFlagProvider>
                <Router history={history}>
                  <ViewTrackingProvider>
                    <div id="msg">
                      <ToastContainer
                        position={toast.POSITION.TOP_CENTER}
                        transition={Slide}
                        autoClose={6000}
                      />
                    </div>
                    <RealTimeProvider>
                      <BoardProvider>
                        <PopoverNotificationItem />
                        <SetUserLocale />
                        <SetCustomerTheme />
                        <RealtimeSubscribeList />
                        <InitZendesk />
                        <GTMLocalStorageAligner />
                        <CookieBot />
                        <InitChargebee />
                        <InitUsetifulTags />
                        <InitWebSocketListeners />
                        <SetLegacyActiveUserModel />
                        <SetupAjaxQuery />
                        <PagesRouter />
                        <WSAnalytics />
                        <Modal />
                        <ConfirmDialog />
                        <UploadWidget />
                        {getBreakpoint() !== "smartphone" && (
                          <TasksStatusWidget mode="desktop" />
                        )}
                      </BoardProvider>
                    </RealTimeProvider>
                  </ViewTrackingProvider>
                </Router>
              </FeatureFlagProvider>
            </ErrorBoundary>
          </TranslationProvider>
        </PersistedQueryProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
