import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";

import * as S from "./DoodleLoader.styles";
import { DoodleLoaderProps } from "./types";

const DoodleLoader: FC<DoodleLoaderProps> = ({ theme, isMini, ...rest }) => {
  const svgProps = isMini ? { width: "20", height: "20" } : {};

  return (
    <ThemeProvider theme={theme}>
      <S.DoodleLoaderWrapper {...rest}>
        <S.SvgLoader isMini={isMini}>
          <Icon
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 101 100"
            icon="doodleFrame1"
            height={100}
            width={101}
            {...svgProps}
          />
          <Icon
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 106 100"
            icon="doodleFrame2"
            height={100}
            width={106}
            {...svgProps}
          />
          <Icon
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 104 100"
            icon="doodleFrame3"
            height={100}
            width={104}
            {...svgProps}
          />
          <Icon
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 115 100"
            icon="doodleFrame4"
            height={100}
            width={115}
            {...svgProps}
          />
          <Icon
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 89 100"
            icon="doodleFrame5"
            height={100}
            width={89}
            {...svgProps}
          />
          <Icon
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 100"
            icon="doodleFrame6"
            height={100}
            width={128}
            {...svgProps}
          />
        </S.SvgLoader>
      </S.DoodleLoaderWrapper>
    </ThemeProvider>
  );
};

export default DoodleLoader;
