/* eslint-disable no-restricted-globals */
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { CheckboxBlock } from "src/js/pages/spaces/dashboard/SpacesSettings/CheckboxBlock";
import { SPACE_ROLES } from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesAddUserPermissionModal/SpacesAddUserPermissionModal.const";
import { Translate, Translator } from "src/js/translation/TranslationProvider";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Button, Modal, Text } from "@arcadia/design-system";

import { UserCard } from "../UserCard";

import * as S from "./EditUserPermissionModal.styles";

const EditUserPermissionModal = ({
  closeModal = () => {},
  handleSubmit = () => {},
  spaceName = "",
  user = {
    id: "",
    role: SPACE_ROLES.ROLE_ADMIN,
    name: "",
    surname: "",
    email: ""
  }
}) => {
  const [accessPermission, setAccessPermission] = useState(true);
  const [createGroupPermission, setCreateGroupPermission] = useState(true);
  const mobile = getBreakpoint() === "smartphone";

  const { whiteTheme } = useTheme();

  useEffect(() => {
    if (user.role === SPACE_ROLES.ROLE_ADMIN) {
      setAccessPermission(true);
      setCreateGroupPermission(true);
    } else {
      setAccessPermission(false);
      setCreateGroupPermission(true);
    }
  }, []);

  useEffect(() => {
    if (!createGroupPermission) setAccessPermission(false);
  }, [createGroupPermission]);

  useEffect(() => {
    if (accessPermission) setCreateGroupPermission(true);
  }, [accessPermission]);

  const validatePermission = () => {
    if (accessPermission && createGroupPermission) {
      return SPACE_ROLES.ROLE_ADMIN;
    }
    if (accessPermission || createGroupPermission) {
      return SPACE_ROLES.ROLE_POWERUSER;
    }
    return null;
  };

  const modalBody = (
    <S.ModalContentContainer>
      <S.ModalContentSubtitle>
        <Text type="label">
          <Translate text="space_settings_modal_user_label" />
        </Text>
      </S.ModalContentSubtitle>
      <UserCard
        firstname={user.name}
        lastname={user.surname}
        email={user.email}
      />
      <S.ModalContentTitle>
        <Text type="formTitle">
          <Translate text="spaces_select_settings_permissions_edit_modal" />
        </Text>
      </S.ModalContentTitle>
      <S.ModalContentSubtitle>
        <Text type="label">
          <Translate text="space_settings_modal_edit_permissions_label" />
        </Text>
      </S.ModalContentSubtitle>
      <CheckboxBlock
        marginBottom={8}
        isSelected={createGroupPermission}
        onClick={() => setCreateGroupPermission(!createGroupPermission)}
      >
        <Text type="formDescription">
          <Translate
            text="spaces_group_creation_permission_edit_modal_bold_label"
            stringVariables={{ spaceName }}
          />
        </Text>
      </CheckboxBlock>
      <CheckboxBlock
        isSelected={accessPermission}
        onClick={() => setAccessPermission(!accessPermission)}
      >
        <Text type="formDescription">
          <Translate text="spaces_access_permission_edit_modal_bold_label" />
        </Text>
      </CheckboxBlock>
      <S.CaptionWrapper>
        <Text type="captionInfoDetails">
          <Translate
            text="spaces_permissions_caption"
            stringVariables={{ spaceName }}
          />
        </Text>
      </S.CaptionWrapper>
    </S.ModalContentContainer>
  );

  return (
    <Translator>
      {({ translate }) => (
        <Modal
          title={translate("handle_permissions_modal_title")}
          onClose={closeModal}
          scrollable
        >
          {modalBody}

          <S.ModalFooterContainer>
            <S.ButtonContainer>
              <Button
                onClick={closeModal}
                variant="secondary"
                theme={whiteTheme}
                fullWidth={mobile}
              >
                <Translate text="undo_button_tooltip" />
              </Button>
              <Button
                onClick={() =>
                  handleSubmit({
                    accessPermission: validatePermission(),
                    userId: user.id
                  })
                }
                variant="primary"
                theme={whiteTheme}
                fullWidth={mobile}
              >
                <Translate text="spaces_confirm_edit_permission_label" />
              </Button>
            </S.ButtonContainer>
          </S.ModalFooterContainer>
        </Modal>
      )}
    </Translator>
  );
};

export default observer(EditUserPermissionModal);
