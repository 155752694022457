import React from "react";
import * as S from "./CircularProgressBar.styles";

const CircularProgressBar = ({
  sqSize = 100,
  percentage = 0,
  strokeWidth = 5,
  textColor,
  textValue,
  textDimension = 1,
  internalCircleColor = "transparent",
  internalCircleDistance = 5
}) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  const internalCircleRadius =
    radius - internalCircleDistance - strokeWidth / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  // Scale text based on square size
  const textSize = (sqSize / 4) * textDimension;

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <S.CircleGutter
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <S.CircleProgress
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
      <S.InternalCircle
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={internalCircleRadius}
        internalCircleColor={internalCircleColor}
      />
      <S.PercentageText
        x="50%"
        y="50%"
        dy={textSize / 2.5}
        textAnchor="middle"
        textSize={textSize}
        textColor={textColor}
      >
        {textValue}
      </S.PercentageText>
    </svg>
  );
};

export default CircularProgressBar;
