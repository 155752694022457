import React, { useState } from "react";
import { translateString } from "src/js/translation/TranslationProvider";
import { __AVAILABLE_LANG__ } from "src/js/settings/settingsLocalization";
import { readLocalData } from "src/js/modules/storageFunction";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import { Text, Icon } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import * as S from "./LanguageSelector.styles";
import LanguageModal from "./components/LanguageModal";
import { LanguageSelectorProps } from "./LanguageSelector.types";

const LanguageSelector = ({ successCallback }: LanguageSelectorProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    ModalStore: { openModal },
    UIStore
  } = useStores();
  const { isLayoutModeMobile: isMobile } = UIStore;
  const language = __AVAILABLE_LANG__[readLocalData(__COOKIE_LANG__)];

  const showModal = () => {
    if (isMobile) {
      setModalOpen(true);
    }
    openModal(() => <LanguageModal successCallback={successCallback} />);
  };

  const sizeGlobeIcon = isMobile ? 20 : 16;

  return (
    <>
      {modalOpen && <LanguageModal successCallback={successCallback} />}
      <S.LanguageSelectorWrap isMobile={isMobile} onClick={showModal}>
        <Icon icon="globe" height={sizeGlobeIcon} width={sizeGlobeIcon} />
        {!isMobile && (
          <Text type="captionAlert">{translateString({ text: language })}</Text>
        )}
        <Icon icon="chevronSmallDown" height={14} width={14} />
      </S.LanguageSelectorWrap>
    </>
  );
};

export default LanguageSelector;
