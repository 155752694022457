import { Collapsable, Icon, Input, Text, colors } from "@arcadia/design-system";
import styled from "styled-components";

export const CollapsableContainer = styled(Collapsable)`
  & > div:first-child {
    margin-bottom: 24px;
  }
`;

export const ColoredText = styled.div`
  color: ${({ theme }) => theme.primaryColor[500]};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const DangerIcon = styled(Icon)`
  color: ${colors.error[400]};
`;

export const AliasUnavailable = styled(Text)`
  color: ${colors.error[400]};
  margin-left: 8px;
`;

export const StyledInput = styled(Input)<{ error?: boolean }>`
  & > div > input {
    border: ${({ error }) =>
      error ? `1px solid ${colors.error[400]}` : undefined};
  }
`;

export const CopySuccess = styled.div`
  & > div {
    width: auto;
  }
  @media screen and (max-width: 767px) {
    & > div {
      max-width: initial;
    }
  }
`;
