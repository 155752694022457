import { CtaTertiary, DoodleLoader, Icon } from "@arcadia/design-system";
import { any, bool, func, string } from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DropdownIconChevron } from "src/js/components/DropdownIconChevron";
import { INPUT_VARIANT, Input } from "src/js/components/forms/input";
import { useOnClickOutSide, useToggle } from "src/js/hooks";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import {
  DROPDOWN_OPTIONS,
  FILTER_DROPDOWN_VARIANT
} from "./FilterDropdown.const";
import * as S from "./FilterDropdown.styles";

const getCurrentDropdownValue = (options, selected = []) => {
  const current =
    selected.length > 0
      ? options.filter(opt => selected.includes(opt.key))
      : options && options.length > 0 && options[0];

  return current;
};

const addOrRemove = (arr, item) =>
  arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item];

const FilterDropdown = ({
  scrollableListId = "filter-dropdown-list",
  currentSelected,
  className = "",
  options,
  disabled,
  onChange,
  collapsed,
  placeholder,
  lowerCaseOptions,
  defaultCaseTitle = false,
  inputType,
  debug = false,
  contentTitleWidth = false,
  minWidth = null,
  maxListHeight = null,
  alignRight = false,
  searchable = false,
  searchPlaceholder = "",
  onSearchFunction = null,
  showSelected = false,
  hasNext = false,
  showNext = () => {},
  pinnedOption,
  translatePinnedOption = false,
  linkButtonMode = false,
  variant = FILTER_DROPDOWN_VARIANT.DEFAULT
}) => {
  const [open, toggle] = useToggle(false);
  const [theCurrentKeyValues, setCurrent] = useState(currentSelected || []);
  const [searchText, setSearchText] = useState("");

  const { whiteTheme } = useTheme();

  const filterDropdownRef = useRef();
  useOnClickOutSide(filterDropdownRef, () => toggle(false));

  useEffect(() => {
    if (collapsed) toggle(collapsed);
  }, []);

  useEffect(() => {
    setCurrent(currentSelected || []);
  }, [currentSelected]);

  const current = useMemo(() =>
    getCurrentDropdownValue(options, theCurrentKeyValues)
  );

  const onHandleChange = useCallback(e => {
    if (inputType !== "checkbox") toggle(!open);
    const newCurrent =
      inputType === "checkbox"
        ? addOrRemove(theCurrentKeyValues, e.key)
        : [e.key];
    setCurrent(newCurrent);
    if (onChange) onChange(newCurrent);
  });

  const RenderOptions = useMemo(
    () => (
      <>
        {pinnedOption && (
          <span
            onClick={() => {
              onHandleChange(pinnedOption);
            }}
          >
            <S.SelectDropdownListItem
              selected={theCurrentKeyValues.includes(pinnedOption.key)}
              lowerCaseOptions={lowerCaseOptions}
              noWrapText={contentTitleWidth}
            >
              {inputType && (
                <S.SelectDropdownInput
                  type={inputType}
                  onChange={() => onHandleChange(pinnedOption)}
                  checked={theCurrentKeyValues.includes(pinnedOption.key)}
                />
              )}
              {translatePinnedOption ? (
                <Translate text={pinnedOption.value} />
              ) : (
                pinnedOption.value
              )}
            </S.SelectDropdownListItem>
            <S.PinnedOptionSeparator />
          </span>
        )}
        <S.SelectDropdownList
          id={scrollableListId}
          maxListHeight={maxListHeight}
        >
          <InfiniteScroll
            scrollableTarget={scrollableListId}
            dataLength={options.length}
            next={showNext}
            hasMore={hasNext}
            loader={<DoodleLoader theme={whiteTheme} isMini />}
          >
            {options.map(opt => {
              if (
                searchable &&
                opt.value &&
                !opt.value.toLowerCase().includes(searchText.toLowerCase())
              )
                return null;
              return (
                <span
                  key={opt.key}
                  onClick={() => {
                    if (opt.disabled) return;
                    onHandleChange(opt);
                  }}
                >
                  <S.SelectDropdownListItem
                    selected={theCurrentKeyValues.includes(opt.key)}
                    lowerCaseOptions={lowerCaseOptions}
                    noWrapText={contentTitleWidth}
                  >
                    {inputType && (
                      <S.SelectDropdownInput
                        disabled={opt.disabled}
                        type={inputType}
                        value={opt.disabled ? false : undefined}
                        checked={theCurrentKeyValues.includes(opt.key)}
                      />
                    )}
                    <S.SelectDropdownListItemText
                      noWrapText={contentTitleWidth}
                    >
                      <Translate text={opt.value} />
                    </S.SelectDropdownListItemText>
                  </S.SelectDropdownListItem>
                </span>
              );
            })}
          </InfiniteScroll>
        </S.SelectDropdownList>
      </>
    ),
    [options, theCurrentKeyValues, searchText]
  );

  return (
    <S.AutoWidthWrapper
      ref={filterDropdownRef}
      contentTitleWidth={contentTitleWidth}
      minWidth={minWidth}
      className={className}
    >
      {debug ? JSON.stringify(current, null, 2) : null}
      <S.SelectDropdownContainer linkButtonMode={linkButtonMode}>
        {linkButtonMode ? (
          <CtaTertiary
            theme={whiteTheme}
            disabled={disabled}
            type="button"
            onClick={() => {
              if (!disabled) toggle(!open);
            }}
          >
            <Translate text={placeholder} />
          </CtaTertiary>
        ) : (
          <S.SelectDropdownActionsContainer
            variant={variant}
            disabled={disabled}
            defaultCaseTitle={defaultCaseTitle}
            onClick={() => {
              if (!disabled) toggle(!open);
            }}
          >
            {showSelected && current && current.length > 0 ? (
              <>
                <S.ShowSelectedText>
                  <Translate text={current && current[0]?.value} />
                </S.ShowSelectedText>
                {current.length > 1 && (
                  <S.ShowSelectedMoreCounter>
                    + {current.length - 1}
                  </S.ShowSelectedMoreCounter>
                )}
              </>
            ) : (
              <Translate text={placeholder} />
            )}
            {theCurrentKeyValues.length > 0 && !showSelected && (
              <S.CounterSelectionLabel type={inputType}>
                {theCurrentKeyValues.length}
              </S.CounterSelectionLabel>
            )}
            <S.SelectDropdownTrigger variant={variant}>
              <DropdownIconChevron open={open} />
            </S.SelectDropdownTrigger>
          </S.SelectDropdownActionsContainer>
        )}
        <S.SelectDropdownOptionsDesktop
          show={open}
          contentTitleWidth={contentTitleWidth}
          minWidth={minWidth}
          alignRight={alignRight}
        >
          {searchable ? (
            <S.SearchInputContainer>
              <Input
                id="searchDesktop"
                handleInputChange={event => {
                  if (onSearchFunction) {
                    onSearchFunction(event);
                  }
                  setSearchText(event.target.value);
                }}
                placeholder={searchPlaceholder}
                icon={<Icon icon="chevronUp" width={15} height={15} />}
                variant={INPUT_VARIANT.DARK}
              />
            </S.SearchInputContainer>
          ) : null}
          {getBreakpoint() !== "smartphone" && RenderOptions}
        </S.SelectDropdownOptionsDesktop>
      </S.SelectDropdownContainer>
      <S.SelectDropdownOptionsMobile show={open}>
        {searchable ? (
          <S.SearchInputContainer>
            <Input
              id="searchMobile"
              handleInputChange={event => {
                setSearchText(event.target.value);
              }}
              placeholder={searchPlaceholder}
              icon={<Icon icon="search" width={15} height={15} />}
              variant={INPUT_VARIANT.DARK}
            />
          </S.SearchInputContainer>
        ) : null}
        {getBreakpoint() === "smartphone" && RenderOptions}
      </S.SelectDropdownOptionsMobile>
    </S.AutoWidthWrapper>
  );
};

FilterDropdown.defaultProps = {
  options: DROPDOWN_OPTIONS,
  placeholder: "placeholder..",
  disabled: false,
  onChange: () => {},
  collapsed: false
};

FilterDropdown.propTypes = {
  // options: arrayOf(shape({ key: oneOf([number, string]), value: string })),
  // eslint-disable-next-line react/forbid-prop-types
  options: any,
  placeholder: string,
  onChange: func,
  disabled: bool,
  collapsed: bool
};

export default FilterDropdown;
