import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { __, getLanguage } from "../../../modules/localization";
import { textEditorManager } from "../../../modules/textEditorManager";
import { getBreakpoint } from "src/js/modules/layoutFunction";

const QuizOpenAnswer = withBackbone(
  createReactClass({
    editor: "#openanswer__editor",
    componentDidMount() {
      const self = this;
      let type = "exercise";

      if (getBreakpoint() !== "desktop") {
        type = "mobile";
      }

      textEditorManager.destroy("openanswer__editor");

      const settings = textEditorManager.settings[type];

      /**
       * Override default exercise or mobile option
       * Load mobile when needed, but never with fullscreen
       */
      settings.selector = self.editor;
      settings.plugins = "autolink, paste, link";
      settings.setup = function (editor) {
        /**
         * Set answer both on keyUp and on change
         * because change is only triggered when tinymce adds an undo layer.
         * On IOS, when going to the next quiz, it is not triggered, so we need a keyUp event too.
         */
        editor.on("keyUp", e => {
          self.setAnswer();
        });
        editor.on("change", e => {
          self.setAnswer();
        });
      };

      textEditorManager.init(self.editor, type, getLanguage(), settings);
    },
    componentWillUnmount() {
      textEditorManager.destroy("openanswer__editor");
    },
    setAnswer(e) {
      // TODO check
      const self = this;
      let answer;
      if (navigator.onLine) {
        answer = textEditorManager.getContent(self.editor);
      } else {
        answer = e;
      }
      this.props.quiz.setUserAnswer(answer);
    },
    render() {
      const self = this;
      const quiz = this.props.quiz;
      return (
        <div className="quiz__openanswer" ref="answersForm">
          <textarea
            id="openanswer__editor"
            className="form-control text-editor"
            rows="3"
            // per ovviare al mancato caricamento di tinymce
            onChange={e => {
              this.setAnswer(e.target.value);
            }}
            placeholder={__("inserisci la tua risposta")}
            defaultValue={quiz.getUserAnswer()}
            data-index="0"
          />
        </div>
      );
    }
  })
);

export default QuizOpenAnswer;
