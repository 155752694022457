import { Heading, Icon, Text } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 24px;

  .comment__form {
    width: 100%;
  }
`;

export const TextWrapper = styled(Text)`
  overflow: hidden;
  word-break: break-word;
  line-height: unset;
`;

export const GreyText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const Post = styled.div<{ pinned: boolean }>`
  border: 1px solid
    ${({ theme, pinned }) =>
      pinned ? theme.primaryColor[500] : theme.colors.grey[1000]};

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  border-radius: 16px;
  position: relative;
  padding: 24px 24px 0px;

  @media screen and (max-width: 767px) {
    padding: 20px 20px 0px;
  }
`;

export const ShowAllCommentCta = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-top: auto;
  align-items: center;
  min-height: 24px;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    margin-left: unset;
    margin-top: 24px;
  }
`;

export const Body = styled.div`
  margin-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[900]};
`;

export const WrapperAuthor = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-left: 20px;
`;

export const CustomHeading = styled(Heading)`
  transition: all 0.3s ease-in-out;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 80px);

  @media screen and (max-width: 767px) {
    max-width: calc(100% - 24px);
  }

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor[500]}!important;
  }
`;

export const WrapperUser = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const TeacherIcon = styled(Icon)`
  @media screen and (max-width: 768px) {
    margin: 12px 0 0 auto;
    flex: 0 0 16px;
  }
`;

export const AnimatedWrapper = styled(motion.div)`
  position: absolute;
  top: -56px;
  left: 0px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12px;
  & > svg {
    color: ${({ theme }) => theme.primaryColor[500]};
  }
  @media (min-width: 768px) {
    position: absolute;
    right: 8px;
  }
`;

export const ActionButtonWrapper = styled.div`
  margin-top: 8px;
`;

export const PostActionWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 8px;
  justify-content: space-around;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const ShowMoreCta = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor[500]};

  &:hover {
    color: ${({ theme }) => theme.primaryColor[300]};
    text-decoration: underline;
  }
`;
