/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Heading, Text } from "@arcadia/design-system";
import React, { FC } from "react";
import { S3Video } from "src/js/settings/settingsVideo";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { VideoPlayer } from "../../VideoPlayer";
import { ModalLayout } from "../../modal/ModalLayout";
import * as S from "./ContactInstructorModal.styles";
import { ContactInstructorModalProps } from "./ContactInstructorModal.types";

const ContactInstructorModal: FC<ContactInstructorModalProps> = ({
  close,
  onConfirm
}) => {
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  return (
    <ModalLayout
      labels={{
        title: translate({ text: "contact_instructor_title" })
      }}
      closeModal={close}
      mobileProps={{
        withHeaderLabels: false
      }}
    >
      <S.ContactInstructorModalWrapper>
        <S.VideoWrapper>
          <VideoPlayer video={S3Video.MigrateGroupsModalIntro} />
        </S.VideoWrapper>
        <Heading level="6">
          {translate({ text: "contact_instructor_title_content" })}
        </Heading>
        <Text>{translate({ text: "contact_instructor_text_content" })}</Text>
        <S.FooterWrapper>
          <Button
            onClick={onConfirm}
            theme={whiteTheme}
            variant="primary"
            fullWidth
          >
            {translate({ text: "contact_instructor_cta_read_more" })}
          </Button>
        </S.FooterWrapper>
      </S.ContactInstructorModalWrapper>
    </ModalLayout>
  );
};

export default ContactInstructorModal;
