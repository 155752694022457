import styled from "styled-components";
import { Text } from "@arcadia/design-system";
import { motion } from "framer-motion";

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const PercentageText = styled(Text)`
  width: 32px;
  color: ${({ theme }) => theme.colors.blueGrey[200]};
`;

export const ProgressBar = styled.div`
  width: 100%;
  max-width: 100px;
  height: 8px;
  background-color: ${({ theme }) => theme.primaryColor[800]};
  border-radius: 4px;
  overflow: hidden;
`;

export const ProgressBarFilled = styled(motion.div)`
  height: 100%;
  background-color: ${({ theme }) => theme.primaryColor[500]};
  border-radius: 4px;
`;
