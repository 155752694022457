import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  margin: 20px 0px;
`;

export const BreadCrumbWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.span`
  user-select: none;
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey[50]};
`;

export const MutedText = styled(Text)`
  color: ${({ theme }) => theme.colors.blueGrey[500]};
`;

export const ClickableText = styled(Text)`
  display: inline-flex;
  align-items: baseline;
  gap: 8px;
  margin-left: 8px;
  cursor: pointer;
  color: inherit;
  &:hover {
    color: ${({ theme }) => theme.primaryColor[500]};
    > span {
      text-decoration: underline;
    }
  }
`;
