import { IconName } from "@arcadia/design-system";
import { TranslationKeys } from "src/js/translation";
import { ObjectValues } from "src/js/types";

export const PreferenceDropdownMode = {
  Default: "default",
  Radio: "radio"
} as const;

export type PreferenceDropdownModeValue = ObjectValues<
  typeof PreferenceDropdownMode
>;

export type PreferenceDropdownOption = {
  icon: IconName;
  id: string | number;
  value: string | number;
  label: TranslationKeys;
};

export type PreferenceDropdownProps = {
  options: PreferenceDropdownOption[];
  selectedOptionId: string | number;
  setSelectedOptionId: (id: string | number) => void;
  openTopPopover?: boolean;
  disabled?: boolean;
  mode?: PreferenceDropdownModeValue;
  iconDefault?: IconName;
};
