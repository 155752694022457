import { Palette, SearchbarWithSuggestionThemeOptions } from "@ds_themes/types";
import { getHighContrastColor, increaseBrightness } from "@ds_themes/utils";

import { createSpecificErrorTheme } from "./specificError";

export const createSearchbarWithSuggestionTheme = (
  palette: Palette
): { searchbarWithSuggestion: SearchbarWithSuggestionThemeOptions } => {
  const { general, grey, primary, appearence, coldGrey } = palette;
  const contrastColor = appearence === "heavy" ? grey[1000] : coldGrey[900];
  const hoverTextColor = getHighContrastColor(contrastColor);

  return {
    searchbarWithSuggestion: {
      ...createSpecificErrorTheme(palette),
      background: general.background,
      backgroundContainer: general.background,
      text: general.contrastText,
      textPlaceholder: increaseBrightness(general.contrastText, 30),
      border: `1px solid ${general.background}`,
      focus: {
        background: contrastColor,
        text: hoverTextColor,
        outline: `3px solid ${primary.light}`
      },
      option: {
        background: general.background,
        text: general.contrastText
      },
      disabled: {
        color: grey[800]
      },
      font: {
        style: "normal",
        weight: "400",
        size: "16px",
        lineHeight: "19px"
      }
    }
  };
};
