import colors from "@ds_themes/colors";

export const livetag = {
  background: colors.grey[50],
  border: `1px solid ${colors.grey[50]}`,
  text: colors.grey[1000],
  finished: {
    background: colors.grey[1000],
    border: `1px solid ${colors.grey[300]}`,
    text: colors.grey[300]
  }
};
