import colors from "@ds_themes/colors";

export const livepill = {
  background: colors.grey[50],
  text: colors.grey[1000],
  divider: colors.grey[1000],
  border: `1px solid ${colors.grey[800]}`,
  dropdown: {
    background: colors.grey[1000],
    text: colors.grey[50],
    border: `1px solid ${colors.grey[800]}`,
    hover: {
      background: colors.grey[900]
    }
  },
  scrollbar: {
    background: colors.grey[800],
    hover: {
      background: colors.grey[900]
    }
  }
};
