import {
  api_request_reset_password,
  api_reset_password
} from "src/js/repository/apiUrls";
import axios from "axios";
import { __API_PATH__ } from "src/js/settings/settingsUrl";
import { handleErrorMessage } from "src/js/modules/messageManager";

export const requestResetPassword = data => {
  return axios
    .post(__API_PATH__ + api_request_reset_password(), data, {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true,
      ignoreCall: true
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      handleErrorMessage(error);
    });
};

export const resetPassword = data => {
  return axios
    .post(__API_PATH__ + api_reset_password(), data, {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true,
      ignoreCall: true
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      handleErrorMessage(error);
    });
};
