import styled, { css } from "styled-components";

export const CollapsableContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 5px;
  width: 100%;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.accent3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent3};

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
  ${({ isOpen }) =>
    isOpen &&
    css`
      padding: 30px 0;
    `}
`;

export const CollapsableControl = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.accent3};
  z-index: 100;
  cursor: pointer;

  ${({ position }) =>
    position === "top" &&
    css`
      top: -20px;
    `}
  ${({ position }) =>
    position === "bottom" &&
    css`
      bottom: -20px;
      & object {
        transition: all 0.3s linear;
        transform: rotate(180deg);
      }
    `}
`;
