import styled, { css } from "styled-components";
import { ScrollableDivProps } from "./ScrollableDiv.types";

export const ScrollableDivStyle = styled.div<ScrollableDivProps>`
  height: 100%;
  margin-right: -8px;
  overflow: auto;
  padding-right: 8px;

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

  // rules for firefox scrollbar
@supports (-moz-appearance: none) {
    overflow: auto;
    scrollbar-color: ${({ theme }) =>
      theme.colors.blueGrey[500] + theme.colors.accent4};
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent3};
    border: 8px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    min-height: 50px;
    &:hover {
      background: ${({ theme }) => theme.colors.accent2};
      background-clip: padding-box;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.accent4};
    border: 8px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
