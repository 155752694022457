import React from "react";

const Pdf = ({ publicUrl, id }: { publicUrl: string; id: number }) => {
  return (
    <iframe
      title={`pdf-res-${id}`}
      className="resource__iframe"
      width="100%"
      frameBorder="0"
      src={`lib/pdfjs/web/viewer.html?file=${encodeURIComponent(publicUrl)}`}
    />
  );
};

export default Pdf;
