import styled from "styled-components";

import { SDividerProps } from "./types";

export const Divider = styled.div<SDividerProps>`
  width: ${({ width = "674px" }) => width};
  height: 1px;
  background-color: ${({ theme, color = "primary" }) => theme.divider[color]};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
