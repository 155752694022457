import { BottomModalSheet, Box } from "@arcadia/design-system";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDebounce, useInfiniteScrollFetcher } from "src/js/hooks";
import {
  changeSearchParams,
  createSearchString,
  updateLocalSearchSuggestion
} from "src/js/modules/searchFunction";
import fetchSearchResult from "src/js/repository/searchRepository";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  SearchFiltersObject,
  SearchResponseItem,
  SearchUrlParameters,
  SearchbarEvent
} from "src/js/types";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { useSearchParameters } from "../hooks";
import SearchFiltersMobile from "./SearchFiltersMobile";

import * as S from "./SearchModalMobile.styles";
import SearchResultsMobile from "./SearchResultsMobile";
import {
  areSearchFiltersEmpty,
  areSearchParamsEmpty,
  getFormattedContexts,
  getFormattedSearchParam,
  getSearchFiltersObject
} from "../util";

const SearchModalMobile: FC = () => {
  const { translate } = useTranslation();
  const history = useHistory();
  const [isOnFilterPage, setIsOnFilterPage] = useState(false);
  const [initialFilters, setInitialFilters] = useState<SearchFiltersObject>();
  const [searchParameters, setSearchParameters] = useState<SearchUrlParameters>(
    {} as SearchUrlParameters
  );
  const { searchParams } = useSearchParameters();
  const searchDebounced = useDebounce(searchParameters.keyword, 400);
  const {
    fetch,
    reset,
    data = [],
    isLoading
  } = useInfiniteScrollFetcher<
    SearchResponseItem[],
    { params: SearchUrlParameters }
  >(
    async ({ page, params }) => {
      const res = await fetchSearchResult(
        params?.keyword,
        getFormattedContexts(params),
        params?.groupFilter,
        params?.userFilter,
        getFormattedSearchParam(params?.spaceFilter),
        page + 1
      );
      return res.results;
    },
    {
      lazy: true,
      onSuccess() {
        if (searchDebounced) {
          _trackEvent(EventDomain.Searchbar, SearchbarEvent.SearchBarUseSearch);
          updateLocalSearchSuggestion(searchDebounced);
        }
      }
    }
  );

  let title = translate("top_header_title_search_context");
  let confirmLabelTitle: string | undefined;

  if (isOnFilterPage) {
    title = translate("mobile_search_filters_header_title");
    confirmLabelTitle = translate("mobile_search_filters_apply");
  }

  const updateSearchFilters = (filters: SearchFiltersObject) => {
    setSearchParameters(old => ({
      ...old,
      ...filters
    }));
    changeSearchParams(
      createSearchString({ keyword: searchParameters.keyword, ...filters })
    );
  };

  const onClose = () => {
    if (isOnFilterPage) {
      setIsOnFilterPage(false);
      updateSearchFilters(initialFilters);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    setSearchParameters(searchParams);
    setInitialFilters(getSearchFiltersObject(searchParams));
    if (!areSearchParamsEmpty(searchParams)) {
      fetch(0, { params: searchParams });
    }
  }, []);

  useEffect(() => {
    if (searchDebounced) {
      fetch(0, { params: searchParameters });
    } else {
      reset();
    }
  }, [searchDebounced]);

  const onSearchChange = (value: string) => {
    const newSearchKeyword = createSearchString({
      ...searchParameters,
      keyword: value
    });
    changeSearchParams(newSearchKeyword);
    setSearchParameters(old => ({
      ...old,
      keyword: value
    }));
  };

  const onSearchClear = () => {
    onSearchChange("");
    reset();

    if (!areSearchFiltersEmpty(getSearchFiltersObject(searchParameters))) {
      fetch(0, { params: { ...searchParameters, keyword: "" } });
    }
  };

  const onFilterResultsClick = () => {
    setIsOnFilterPage(true);
  };

  const onConfirm = () => {
    reset();
    fetch(0, { params: searchParameters });
    setInitialFilters(getSearchFiltersObject(searchParameters));
    setIsOnFilterPage(false);
  };

  return (
    <S.Container>
      <BottomModalSheet
        title={title}
        isOpen
        closeLabel={translate("mobile_search_modal_cancel_title")}
        onClose={onClose}
        confirmLabel={confirmLabelTitle}
        onConfirm={onConfirm}
      >
        <Box padding="16px" marginTop="8px">
          {isOnFilterPage ? (
            <SearchFiltersMobile
              appliedFilters={getSearchFiltersObject(searchParameters)}
              setAppliedFilters={updateSearchFilters}
            />
          ) : (
            <SearchResultsMobile
              onFilterResultsClick={onFilterResultsClick}
              searchTerm={searchParameters.keyword}
              onSearchChange={onSearchChange}
              searchParams={searchParameters}
              onSearchClear={onSearchClear}
              data={data}
              isLoading={isLoading}
              searchDebounced={searchDebounced}
            />
          )}
        </Box>
      </BottomModalSheet>
    </S.Container>
  );
};

export default SearchModalMobile;
