import { DefaultTheme } from "styled-components";

export const ChipVariant = {
  Primary: "primary",
  Warning: "warning",
  Danger: "danger",
  Success: "success"
} as const;
export type ChipVariant = (typeof ChipVariant)[keyof typeof ChipVariant];

export type ChipProps = {
  variant: ChipVariant;
  theme: DefaultTheme;
  className?: string;
};

/**
 * Style
 */

export type SChipWrapper = {
  variant: ChipVariant;
};
