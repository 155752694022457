import styled from "styled-components";
import { Text, shadow } from "@arcadia/design-system";

export const ModalOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 120px);
  top: 120px;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: ${({ zIndex }) => zIndex};
`;

export const ModalContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[700]};
  border-radius: 8px;
  box-shadow: ${shadow[200]};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  max-width: 500px;
  padding: 24px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
    padding: 16px;
    position: fixed;
    bottom: 0;
    border-radius: 8px 8px 0 0;
  }
`;

export const Description = styled(Text)`
  display: block;
  margin-top: 24px;
  text-align: center;
`;

export const ModalFooterContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
