import { Box, DropdownOption, Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import { useAnalyticsFilter, useAnalyticsValidation } from "../../hooks";
import { FilterGroups } from "./FilterGroups";
import * as S from "./FilterSelectors.styles";
import { FilterTime } from "./FilterTime";

const FilterSelectors = ({ spaceGroups }) => {
  const { translate } = useTranslation();
  const { primaryColor, colors } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const {
    groupIds = [],
    setGroupIds,
    rangeDate,
    setRangeDate,
    intervalDate,
    setIntervalDate,
    dateLabel
  } = useAnalyticsFilter();

  useAnalyticsValidation({
    spaceGroupIds: spaceGroups?.map(({ group: { id } }) => String(id))
  });

  const groupsOptions: DropdownOption[] = spaceGroups?.map(
    ({ group: { id, name } }) => ({
      id: String(id),
      label: name
    })
  );

  return (
    <>
      <Box
        borderTop={`1px solid ${primaryColor[900]}`}
        borderBottom={`1px solid ${primaryColor[900]}`}
        backgroundColor={colors.grey[1000]}
        padding="16px 0px"
      >
        <S.StyledContainer
          padding={isLayoutModeMobile ? "0 16px" : "0 0 0 16px"}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={20}
            {...(isLayoutModeMobile && {
              gap: 12
            })}
          >
            {!isLayoutModeMobile ? (
              <Text type="formDescription">
                {translate("analytics_filter_dropdown_label")}
              </Text>
            ) : null}
            <FilterGroups
              groupsList={groupsOptions}
              selectedGroups={groupIds}
              onSelectGroups={setGroupIds}
            />
            <FilterTime
              range={rangeDate}
              interval={intervalDate}
              setRange={setRangeDate}
              setInterval={setIntervalDate}
              dateLabel={dateLabel}
            />
            {!isLayoutModeMobile ? (
              <Box display="flex" alignItems="center" gap={8} marginLeft="auto">
                <Icon
                  icon="stopwatch"
                  initialViewbox
                  width={16}
                  height={16}
                  color={colors.grey[200]}
                />
                <S.WarningDataText type="formSubtitle">
                  {translate("dashboard_analytics_warning_data_label")}
                </S.WarningDataText>
              </Box>
            ) : null}
          </Box>
        </S.StyledContainer>
      </Box>
      <S.StyledContainer>
        <S.StyledFilterBar
          autoScroll={false}
          selectedFiltersKeys={groupIds}
          options={groupsOptions}
          removeFilter={groupIdToRemove => {
            setGroupIds(groupIds.filter(id => id !== groupIdToRemove));
          }}
          removeAllFilters={
            !isLayoutModeMobile ? () => setGroupIds(undefined) : null
          }
        />
      </S.StyledContainer>
    </>
  );
};

export default observer(FilterSelectors);
