import styled from "styled-components";
import { motion } from "framer-motion";
import { Text } from "@ds_universal/Text";
import colors from "@ds_themes/colors";

import { SSelectableItem } from "./TabButtons.types";

export const TabButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const TabButton = styled(motion.div).attrs((props: SSelectableItem) => ({
  ...props,
  whileHover: { scale: 1.05, cursor: "pointer" },
  whileTap: { scale: 0.95 },
  active: props.active
}))`
  width: 100%;
  height: 72px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey[1000]};
  outline: ${({ active = false }) =>
    active ? `1px solid ${colors.violet[500]}` : undefined};
  color: ${({ active = false }) =>
    active ? colors.violet[500] : colors.blueGrey[400]};
`;

export const TabButtonText = styled(Text).attrs(() => ({
  type: "cta"
}))`
  margin-top: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
