import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ConfirmationAlert } from "@arcadia/design-system";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import useCreateOrEditBadge from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/useCreateOrEditBadge";
import { useTheme } from "styled-components";
import { CreateEditBadgeModalProps, CreateEditBadgeStep } from "./types";
import * as S from "./styles";
import SelectBadgeImage from "../SelectBadgeImage";
import BadgeRequiredFields from "../BadgeRequiredFields";
import BadgeOptionalFields from "../BadgeOptionalFields";

const CreateEditBadgeModal = ({
  isEditing = false,
  badgeImageCroppedPreview,
  customBadgeImage
}: CreateEditBadgeModalProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const {
    ModalStore: { closeModal, setCustomCloseCb },
    SpaceBadgesStore: {
      badgeToEdit,
      badgeRequiredFields,
      reset,
      setBadgeRequiredFields,
      setBadgeOptionalFields
    },
    ConfirmDialogStore: { openConfirmDialog, closeConfirmDialog }
  } = useStores();
  const {
    criteria,
    description,
    id,
    image,
    name,
    issuerName,
    isPrivate,
    duration
  } = badgeToEdit || {};
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [step, setStep] = useState<number>(
    CreateEditBadgeStep.SELECT_IMAGE_STEP
  );
  const [isLoading, setLoading] = useState(false);
  const { createBadge, editBadge } = useCreateOrEditBadge({ setLoading });

  const handleClose = () => {
    closeModal();
    reset();
  };

  const preventCloseOfModal = () =>
    openConfirmDialog(
      <ConfirmationAlert
        theme={whiteTheme}
        text={translate("space_badge_create_click_outside_alert_message")}
        declineText={translate("Annulla")}
        onDeclineFunction={closeConfirmDialog}
        acceptText={translate("Conferma")}
        onAcceptFunction={() => {
          closeConfirmDialog();
          handleClose();
        }}
      />
    );

  useEffect(() => {
    setCustomCloseCb(preventCloseOfModal);
    return () => {
      setCustomCloseCb(null);
    };
  }, []);

  const modalTitle = translate(
    isEditing
      ? "space_badges_edit_badge_modal_title"
      : "space_badges_create_badge_modal_title"
  );

  useEffect(() => {
    if (customBadgeImage) {
      setBadgeRequiredFields({ ...badgeRequiredFields, customBadgeImage });
    }
  }, [customBadgeImage]);

  useEffect(() => {
    if (isEditing) {
      setBadgeRequiredFields({
        name,
        description,
        criteria,
        issuer: issuerName,
        image,
        customBadgeImage: customBadgeImage || undefined
      });
      setBadgeOptionalFields({
        duration,
        isPrivate
      });
    }
  }, [isEditing]);

  const handleStepToRender = () => {
    switch (step) {
      case CreateEditBadgeStep.REQUIRED_FIELDS_STEP:
        return (
          <BadgeRequiredFields
            setStep={setStep}
            setProgressPercentage={setProgressPercentage}
            isEditing={isEditing}
          />
        );
      case CreateEditBadgeStep.OPTIONAL_FIELDS_STEP:
        return <BadgeOptionalFields isEditing={isEditing} />;
      default:
        return (
          <SelectBadgeImage
            isEditing={isEditing}
            editingBadgeImage={image}
            badgeImageCroppedPreview={badgeImageCroppedPreview}
            setStep={setStep}
            setProgressPercentage={setProgressPercentage}
          />
        );
    }
  };

  return (
    <ModalLayout
      closeModal={handleClose}
      progressPercentage={progressPercentage}
      labels={{
        title: modalTitle
      }}
      mobileProps={{
        closeLabel: translate("Annulla"),
        confirmLabel:
          step === CreateEditBadgeStep.OPTIONAL_FIELDS_STEP &&
          translate("Salva"),
        isConfirmDisabled: isLoading,
        withDrag: false,
        onConfirm: isEditing ? () => editBadge(id) : () => createBadge(false)
      }}
    >
      <S.ModalBodyContainer>{handleStepToRender()}</S.ModalBodyContainer>
    </ModalLayout>
  );
};

export default observer(CreateEditBadgeModal);
