import { readLocalData } from "src/js/modules/storageFunction";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import {
  __AVAILABLE_LANG__,
  __DEFAULT_LANG__,
  __LANGUAGE_PARAMS_NAME__
} from "src/js/settings/settingsLocalization";
import { getBrowserLanguage } from "./localizationFunction";

function getLocalDataLanguage() {
  const localDataLang = readLocalData(__COOKIE_LANG__);
  if (
    typeof localDataLang !== "undefined" &&
    localDataLang !== "null" &&
    localDataLang !== null
  ) {
    return localDataLang;
  }
  return false;
}

function getUrlLanguage() {
  const urlLang = new URL(window.location.href).searchParams.get(
    __LANGUAGE_PARAMS_NAME__
  );
  if (
    typeof urlLang !== "undefined" &&
    typeof __AVAILABLE_LANG__[urlLang] !== "undefined"
  ) {
    return urlLang;
  }
  return false;
}

export function getDefaultLanguage() {
  return __DEFAULT_LANG__;
}

export function getCurrentLanguage() {
  const urlLanguage = getUrlLanguage();
  if (urlLanguage) {
    return urlLanguage;
  }

  const cookieLanguage = getLocalDataLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const browserLanguage = getBrowserLanguage();
  if (browserLanguage) {
    return browserLanguage;
  }
  return getDefaultLanguage();
}

export function getUserIdFromLocalized(id) {
  return id.split("-")[1];
}

export function getQueryParamFromFragment(param) {
  const url = new URL(window.location.href);
  const fragment = url?.hash?.substring(1);
  const params = new URLSearchParams(fragment?.split("?")[1]);
  return params?.get(param);
}
