import React from "react";
import { Icon } from "@arcadia/design-system";

import * as S from "./CircularLoader.styles";

const CircularLoader = ({ size }: { size: number }) => {
  return (
    <S.LoaderWrapper
      size={size}
      animate={{
        rotate: [0, 360]
      }}
      transition={{
        duration: 0.65,
        ease: "linear",
        loop: Infinity,
        repeatType: "loop",
        repeatDelay: 0
      }}
    >
      <Icon icon="circleLoader" width={size} height={size} initialViewbox />
    </S.LoaderWrapper>
  );
};

export default CircularLoader;
