import { Box, Icon, ImageViewer } from "@arcadia/design-system";
import React from "react";
import { __GROUP_COVER_DEFAULT__ } from "src/js/settings/settingsImage";
import { GroupDetailsModel } from "src/js/types";
import styled from "styled-components";

const StyledImageViewerWrapper = styled.div`
  height: 100%;
  img {
    border-radius: 8px;
  }
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.primaryColor[500]};
  bottom: -8px;
  right: -8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.grey[1000]};
`;

const BadgeGroupWithIcon = ({ group }: { group: GroupDetailsModel }) => {
  return (
    <Box position="relative" width={64} height={64}>
      <StyledImageViewerWrapper>
        <ImageViewer
          alt="group badge"
          url={group?.cover?.small || group?.cover?.originalUrl}
          fallbackImage={__GROUP_COVER_DEFAULT__}
        />
      </StyledImageViewerWrapper>
      <StyledIconWrapper>
        <StyledIcon initialViewbox icon="bookmarkStar" height={16} width={16} />
      </StyledIconWrapper>
    </Box>
  );
};

export default BadgeGroupWithIcon;
