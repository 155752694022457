import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;
`;

export const PageWrapper = styled.div`
  padding: 32px;

  p {
    padding: 0px 24px;
  }
`;

export const LangButton = styled.button`
  all: unset;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;
