import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";

import * as S from "./CtaTertiary.styles";
import { CtaTertiaryProps } from "./types";

// TODO this should be a button variant as design docet https://www.figma.com/file/ywjEBd2juFgBrWq2I9j6U3/Library?node-id=177%3A885

export const CtaTertiary: FC<PropsWithChildren<CtaTertiaryProps>> = ({
  theme,
  children,
  withIcon = {},
  onClick,
  disabled,
  type = "button"
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.CtaTertiary onClick={onClick} disabled={disabled} type={type}>
        <Text type="ctaTertiary">{children}</Text>

        {withIcon.icon ? (
          <Icon
            icon={withIcon.icon}
            height={withIcon.height}
            width={withIcon.width}
            color={withIcon.color}
          />
        ) : null}
      </S.CtaTertiary>
    </ThemeProvider>
  );
};

export default CtaTertiary;
