import { useCallback, useState } from "react";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import { inviteUsers } from "src/js/repository/groupRepository";
import { useTranslation } from "src/js/translation";
import { __EMAIL_REGEX__ } from "src/js/settings/settingsInput";
import { useStores } from "src/js/hooks";
import { useGroupMembers } from "../GroupSettingsMembers/useGroupMembers";

export const useEmailInvite = () => {
  const {
    UserInviteStore: {
      removeUserFromList,
      clearUserList,
      updateUserList,
      usersToInviteRole,
      usersToInvite
    },
    GroupStore: {
      activeGroup: { id },
      alias
    }
  } = useStores();
  const [usersInvited, setUsersInvited] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [invitationSent, setInvitationSent] = useState<boolean>(false);
  const { translate } = useTranslation();
  const { getInvitations } = useGroupMembers();
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const lastCharacter = val[val.length - 1] || "";
      const SPECIAL_CHARACTERS = [",", " "];
      const hasSpecial = SPECIAL_CHARACTERS.includes(lastCharacter);
      const hasEmail = val.match(__EMAIL_REGEX__) !== null;
      if (hasEmail && hasSpecial) {
        const validEmails = val.match(__EMAIL_REGEX__);
        updateUserList(...validEmails);
        setInputValue("");
      } else {
        setInputValue(val.replace(/[,\s]/g, ""));
      }
    },
    [inputValue, usersToInvite]
  );

  const inviteMembers = async (setUsersInvited: (val: boolean) => void) => {
    const data = {
      alias,
      inviteRole: usersToInviteRole,
      userEmails: usersToInvite
    };
    inviteUsers(id, data)
      .then(response => {
        getInvitations();
        setUsersInvited(true);
        clearUserList();
        _trackEvent("Group", "GroupInvite", "EmailLink", usersToInvite.length);
        if (response?.errors?.length > 0) {
          response.errors.map(obj =>
            showToastError({
              str:
                obj?.error === "u_user_already_in_group"
                  ? `${obj?.mail}: ${translate(obj?.error)}`
                  : translate(obj?.error)
            })
          );
        }
      })
      .catch(({ ok: correctMails, errors }) => {
        if (correctMails.length > 0) {
          const message =
            correctMails.length > 1
              ? "group_invite_emails_n"
              : "group_invite_emails_1";
          showToastSuccess({ str: translate(message) });
        }
        if (errors && errors.length) {
          errors.map(({ error }) => showToastError({ str: translate(error) }));
        }
      });
  };

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      if (key === "Backspace" && inputValue === "") {
        const copy = [...usersToInvite];
        const removedEmail = copy.pop();
        removeUserFromList(removedEmail);
      }
    },
    [inputValue, usersToInvite]
  );

  const onPaste = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      const pastedData = e.clipboardData.getData("Text");
      const hasEmail = pastedData.match(__EMAIL_REGEX__) !== null;

      if (hasEmail) {
        e.preventDefault();
        const validaEmails = pastedData.match(__EMAIL_REGEX__);
        validaEmails.map(email => updateUserList(email));
        setInputValue("");
      }
    },
    [usersToInvite]
  );

  return {
    onChange,
    inviteMembers,
    onKeyDown,
    onPaste,
    setIsFocused,
    setInvitationSent,
    setUsersInvited,
    inputValue,
    isFocused,
    invitationSent,
    usersInvited
  };
};
