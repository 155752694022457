import { ProgressBar, Text } from "@arcadia/design-system";
import React from "react";
import { translateString } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import * as S from "./Stepper.styles";
import { StepperProps } from "./Stepper.types";

const Stepper = ({ className, steps, currentStep }: StepperProps) => {
  const { greyTheme } = useTheme();
  return (
    <S.StepperWrap className={className}>
      <Text type="body">
        {translateString({
          text: "activate_account_stepper_label",
          stringVariables: { currentStep, steps }
        })}
      </Text>
      <S.StepperProgressBar>
        <ProgressBar
          theme={greyTheme}
          percentage={100 / (steps / currentStep)}
        />
      </S.StepperProgressBar>
    </S.StepperWrap>
  );
};

export default Stepper;
