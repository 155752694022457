import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useArrayState } from "src/js/hooks";
import EmptyLayout from "src/js/layout/cleanLayout/EmptyLayout";
import { debounce } from "src/js/modules/commonFunction";
import {
  showToastError,
  showToastSuccess
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import DeaSelectGroup from "src/js/pages/deaImport/DeaSelectGroup";
import InvalidUrlRedirect from "src/js/pages/exceptions/InvalidUrlRedirect";
import {
  createDeaLesson,
  fetchDeaLesson
} from "src/js/repository/deaImportRepository";
import { fetchFilteredGroupList } from "src/js/repository/groupsListRepository";
import { __SEARCH_GROUP_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { Icon } from "@arcadia/design-system";

const DeaImportLesson = () => {
  const { project_id, node_id } = useParams();
  const [projectTitle, setProjectTitle] = useState("");
  const [lessonTitle, setLessonTitle] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupsList, addGroups, resetGroups] = useArrayState([]);
  const [filterString, setFilterString] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false);
  const [paginationStart, setPaginationStart] = useState(0);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  useEffect(() => {
    setIsInvalidUrl(false);
    fetchDeaLesson(project_id, node_id)
      .then(({ name, project }) => {
        setProjectTitle(project);
        setLessonTitle(name);
      })
      .catch(() => {
        setIsInvalidUrl(true);
      });
  }, [project_id, node_id]);

  useEffect(() => {
    setIsLoading(true);
    fetchFilteredGroupList({
      name: filterString,
      role: "ROLE_TEACHER",
      start: 0,
      limit: __SEARCH_GROUP_LIST_LIMIT__
    }).then(({ groups, has_next }) => {
      addGroups(groups);
      setIsLoading(false);
      setHasNext(has_next);
    });

    return () => {
      resetGroups([]);
      setPaginationStart(0);
    };
  }, [filterString]);

  // think about useCallback to avoid redundant re-render
  const showNext = () => {
    setIsLoading(true);
    fetchFilteredGroupList({
      name: filterString,
      role: "ROLE_TEACHER",
      start: paginationStart + __SEARCH_GROUP_LIST_LIMIT__,
      limit: __SEARCH_GROUP_LIST_LIMIT__
    }).then(({ groups, has_next }) => {
      setPaginationStart(paginationStart + __SEARCH_GROUP_LIST_LIMIT__);
      addGroups(groups);
      setIsLoading(false);
      setHasNext(has_next);
    });
  };

  const createLesson = (groupId, projectId, lessonId) => {
    createDeaLesson(groupId, projectId, lessonId)
      .then(({ id }) => {
        showToastSuccess({
          str: <Translate text="risorsa aggiunta con successo" />
        });
        const groupUrl = createUrl("module_detail", {
          group_id: groupId,
          module_id: id
        });
        navigateTo(groupUrl);
      })
      .catch(() => {
        showToastError({ str: <Translate text="general_error_retry" /> });
      });
  };

  if (isInvalidUrl) return <InvalidUrlRedirect pageTitle="dea_import_lesson" />;

  if (!projectTitle || !lessonTitle) return null;
  return (
    <EmptyLayout pageTitle="dea_import_lesson">
      <div className="margin-horizontal-default padding-header--no-white-header">
        <h4 className="text-center">
          <Translate text="dea_import_project" />:
        </h4>
        <h3 className="text-center">{projectTitle}</h3>
        <h4 className="text-center">
          <Translate text="dea_import_lesson" />:
        </h4>
        <h2 className="text-center">{lessonTitle}</h2>
        {selectedGroup ? (
          <>
            <div className="deaimport__landing-text">
              <Translate text="share_on" />: {selectedGroup.name}
              <button
                type="button"
                className="button-icon--inline right"
                onClick={() => setSelectedGroup(null)}
              >
                <Icon icon="close" height={15} width={15} />
              </button>
            </div>
            <div className="text-center margin-top-20">
              <button
                type="button"
                className="btn btn-action btn-xs"
                onClick={() =>
                  createLesson(selectedGroup.id, project_id, node_id)
                }
              >
                <Translate text="aggiungi board" />
              </button>
            </div>
          </>
        ) : (
          <DeaSelectGroup
            groupsList={groupsList}
            selectGroup={setSelectedGroup}
            filterList={debounce(setFilterString, 500)}
            isLoading={isLoading}
            filterString={filterString}
            hasNext={hasNext}
            showNext={debounce(showNext, 500)}
          />
        )}
      </div>
    </EmptyLayout>
  );
};

export default DeaImportLesson;
