import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { observer } from "mobx-react";
import { ActionButton, Icon } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import { useCloseOnClickOutside, useStores } from "src/js/hooks";
import { NotificationDrawer } from "src/js/components/notification/NotificationDrawer";
import { navigateTo } from "src/legacy/modules/history";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";
import { ModalLayout } from "src/js/components/modal/ModalLayout";

import * as S from "./HeaderNotificationToggle.styles";

const HeaderNotificationToggle = () => {
  const {
    isOpen,
    setIsOpen,
    ref: notificationDrawerRef
  } = useCloseOnClickOutside(false);
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    NotificationStore: { setNotificationsNumber, newNotificationsNumber },
    SpaceStore: { spacesList, activeSpaceId },
    UIStore: { isLayoutModeMobile },
    ModalStore: { openModal, closeModal }
  } = useStores();

  useEffect(() => {
    if (!activeSpaceId) return;

    const activeSpace = spacesList.find(
      ({ space }) => space.id === activeSpaceId
    );

    if (!activeSpace) return;

    setNotificationsNumber(activeSpace.unreadNotificationCount);
  }, [activeSpaceId]);

  const goToSettings = () => {
    closeModal();
    navigateTo(createUrl("user_settings", { option: "notice" }));
  };

  const openNotificationModal = () => {
    openModal(() => (
      <ModalLayout
        closeModal={closeModal}
        mobileProps={{
          confirmLabel: translate("notification_drawer_settings_label"),
          withDrag: false,
          onConfirm: goToSettings
        }}
        labels={{
          title: translate("notification_drawer_title"),
          mobileTitle: translate("notification_drawer_title"),
          mobileClose: "Close"
        }}
      >
        <NotificationDrawer />
      </ModalLayout>
    ));
  };

  return isLayoutModeMobile ? (
    <S.NotificationContainer
      id="headerNotifications"
      isMobile={isLayoutModeMobile}
      onClick={openNotificationModal}
    >
      <Icon icon="notificationUnmuted" height="20px" width="18px" />
      {newNotificationsNumber > 0 && (
        <S.NotificationCount>
          {newNotificationsNumber > 99 ? "" : newNotificationsNumber}
        </S.NotificationCount>
      )}
    </S.NotificationContainer>
  ) : (
    <div ref={notificationDrawerRef} id="headerNotifications">
      <ActionButton
        theme={whiteTheme}
        onClick={() => setIsOpen(!isOpen)}
        variant="global"
        role="button"
        icon="bellEmpty"
        counter={newNotificationsNumber > 99 ? "99+" : newNotificationsNumber}
      />
      <AnimatePresence initial={false}>
        {isOpen && <NotificationDrawer />}
      </AnimatePresence>
    </div>
  );
};

export default observer(HeaderNotificationToggle);
