/* eslint-disable import/no-duplicates */
import { registerLocale } from "react-datepicker";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __DEFAULT_LANGUAGE_COUNTRY__ } from "src/js/settings/settingsLocalization";
import it from "date-fns/locale/it";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";

export const setDatePickerLocale = () => {
  switch (getCurrentLanguage()) {
    case __DEFAULT_LANGUAGE_COUNTRY__.it:
      registerLocale("locale", it);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.en:
      registerLocale("locale", en);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.es:
      registerLocale("locale", es);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.fr:
      registerLocale("locale", fr);
      break;
    default:
      registerLocale("locale", it);
  }
};

export const last100Years = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - i
);
