import styled from "styled-components";

export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 554px;
  color: ${({ theme }) => theme.colors.grey[1000]};
  width: 100%;
`;

export const TestimonialImage = styled.img`
  margin: 0 auto;
  margin-bottom: 28px;
  width: 100%;
`;

export const TestimonialText = styled.div`
  text-align: center;
  margin-bottom: 28px;
`;

export const TestimonialMessage = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
`;

export const TestimonialBrand = styled.img`
  margin: 0 auto;
`;
