import React from "react";
import createUrl, { createStaticUrl } from "src/js/modules/routing";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  showToastError,
  showToastFunctional
} from "src/js/modules/messageManager";
import { navigateTo } from "src/legacy/modules/history";

// maybe we can split more those functions
const getConstraintsList = (activeGroupId, constraints) => {
  return constraints.map(constraint => {
    let url = "";
    if (constraint.type === "board") {
      url = `#${createStaticUrl("module_detail", {
        group_id: activeGroupId,
        module_id: constraint.board_constraint_id
      })}`;
    } else {
      url = `#${createStaticUrl("exercises_run", {
        group_id: activeGroupId,
        exercise_id: constraint.exercise_constraint_id
      })}`;
    }
    return (
      <li key={constraint.id}>
        <a href={url}>
          <Translate text={constraint.type} /> : {constraint.name}
        </a>
      </li>
    );
  });
};

export const handleBoardErrorMessage = (
  statusCode,
  constraints,
  activeGroupId,
  theme
) => {
  if (statusCode.toString() === "404") {
    showToastError({ str: <Translate text="error_board_not_found" /> });
    navigateTo(createUrl("modules", { group_id: activeGroupId }));
  } else if (statusCode.toString() === "403") {
    if (!constraints) {
      showToastError({ str: <Translate text="error_board_not_authorized" /> });
    } else {
      const list = getConstraintsList(activeGroupId, constraints);
      const messages = (
        <div>
          <p>
            <Translate text="error_constraint_list" />
          </p>
          <ul>{list}</ul>
        </div>
      );
      showToastFunctional(messages, theme);
      navigateTo(createUrl("modules", { group_id: activeGroupId }));
    }
  }
};

export const handleGroupErrorMessage = () => {
  showToastError({ str: <Translate text="group_not_available" /> });
  navigateTo(createUrl("home"));
};
