import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { Text } from "@ds_universal/Text";

import * as S from "./Link.styles";
import { LinkProps } from "./types";

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  theme,
  children,
  href,
  rel,
  target = "_self",
  disabled,
  onClick,
  className
}) => {
  return (
    <ThemeProvider theme={theme}>
      {disabled ? (
        <S.DisabledLink
          role="link"
          aria-disabled={disabled}
          className={className}
        >
          <Text type="textLink">{children}</Text>
        </S.DisabledLink>
      ) : (
        <S.Link
          href={href}
          onClick={onClick}
          rel={rel}
          target={target}
          className={className}
        >
          <Text type="textLink">{children}</Text>
        </S.Link>
      )}
    </ThemeProvider>
  );
};

export default Link;
