/**
 * https://stackoverflow.com/a/42320249/2500757
 */

import React from "react";
import { createHashHistory } from "history";
import ReactDom from "react-dom";

export const history = createHashHistory({ hashType: "noslash" });

export default history;

// TODO Added to prevent dependency cycle problem (copy of modalPage.hide() function) as soon as possible
const modalPageHide = () => {
  ReactDom.render(<div />, $("#modal-msg").get(0));
  ReactDom.render(<div />, $("#modal-page").get(0));
  $(".react-modal-page").modal("hide");
  ReactDom.unmountComponentAtNode($("#modal-page").get(0));
  // $("body").off("touchmove"); //stop scrolling on mobile
  $("body").removeClass("modal-page-open modal-open"); // forzo rimozione class
  $("body").css("padding-right", 0);
};

// TODO Added to prevent dependency cycle problem (copy of modalMessage.hide() function) remove as soon as possible
const modalMessageHide = () => {
  $(".react-modal-msg").modal("hide");
  $("body").removeClass("modal-msg-open modal-open"); // forzo rimozione class
};

function showHeader() {
  if (document.getElementById("headerWhite") != null) {
    document
      .getElementById("headerWhite")
      .classList.remove("header__tabs--hidden");
  }
}

history.listen((location, action) => {
  let currentUrl = location.pathname;
  if (currentUrl.match(/resource|elements/)) {
    if (currentUrl.match(/edit|option|report/)) {
      modalPageHide();
    }
  } else {
    modalPageHide();
  }
  modalMessageHide();
  showHeader();
});

export const navigateTo = function (url) {
  // console.log('navigateTo', url);
  history.push(url);
};

export const replaceTo = function (url) {
  // console.log('navigateTo', url);
  history.replace(url);
};

/*
Possibili problemi!
https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/history.md#history-is-mutable
 */
export const getCurrentRoute = function () {
  return history.location.pathname;
};
export const doRouteBack = function () {
  history.goBack();
};

export const isUnloggedLocation = function () {
  const locations = ["login", "/login", "/register", "register"];

  return locations.indexOf(getCurrentRoute()) >= 0;
};

export const changeUrl = function (url, params) {
  if (typeof params != "undefined") {
    history.push({
      pathname: url,
      search: `?${params}`
    });
  } else {
    history.push({
      pathname: url
    });
  }
};
