import getUserClient from "src/js/modules/connection";
import { getVersionedApiUrl, api_spaces_list } from "src/js/repository/apiUrls";

export async function fetchSpacesList({ limit, offset }) {
  return getUserClient()
    .get(getVersionedApiUrl(api_spaces_list(), "v3"), {
      params: {
        offset,
        limit
      }
    })
    .then(response => {
      return response.data;
    });
}
