import {
  AvatarWithInitials,
  Box,
  Collapsable,
  DoodleLoader,
  Text
} from "@arcadia/design-system";
import React, { FC } from "react";
import handleThumbnails from "src/js/modules/imageFunction";
import { ImageModel, SearchFiltersObject } from "src/js/types";
import { useTheme } from "styled-components";
import { SectionItem } from "../types";

import * as S from "./FiltersSection.styles";

type FiltersSectionProps = {
  items: SectionItem[];
  title: string;
  type:
    | keyof Omit<SearchFiltersObject, "wall" | "module" | "exercise">
    | "contextsFilter";
  onFiltersChange: (params: SearchFiltersObject) => void;
  filters: SearchFiltersObject;
  isLoading?: boolean;
};

const FiltersSection: FC<FiltersSectionProps> = ({
  items,
  title,
  type,
  onFiltersChange,
  filters,
  isLoading
}) => {
  const { whiteTheme } = useTheme();

  if (isLoading) {
    return (
      <Box marginBottom="16px">
        <DoodleLoader theme={whiteTheme} isMini />
      </Box>
    );
  }

  return (
    <S.FiltersWrapper>
      <Collapsable HeaderComponent={<Text type="formField">{title}</Text>}>
        {items.map(item => {
          let AvatarComponent: React.ReactNode;
          let avatarUrl = "";

          if (type === "groupFilter") {
            avatarUrl = handleThumbnails(item.image as ImageModel, "small");
          } else {
            avatarUrl = item.image as string;
          }

          if (type === "spaceFilter") {
            AvatarComponent = (
              <S.AvatarWithInitialsWrapper>
                <AvatarWithInitials
                  size="extraSmall"
                  theme={whiteTheme}
                  alt={item.name}
                  url={item.image as string}
                  name={item.name}
                  avatarColor={item.defaultBadgeColor}
                />
              </S.AvatarWithInitialsWrapper>
            );
          } else if (type === "groupFilter" || type === "userFilter") {
            AvatarComponent = (
              <S.Avatar
                theme={whiteTheme}
                url={avatarUrl}
                size="image"
                isGroupFilter={type === "groupFilter"}
              />
            );
          }

          const onCheckboxChange = (val: boolean) => {
            let newFiltersObject: SearchFiltersObject;

            if (type === "contextsFilter") {
              newFiltersObject = {
                ...filters,
                [item.id]: val
              };
            } else if (type === "spaceFilter") {
              newFiltersObject = {
                ...filters,
                spaceFilter: [item.id]
              };
            } else {
              const searchQueryValues = filters?.[type] || [];

              newFiltersObject = {
                ...filters,
                [type]: !val
                  ? searchQueryValues.filter(
                      searchQuery => searchQuery !== item.id
                    )
                  : [...searchQueryValues, item.id]
              };
            }

            onFiltersChange(newFiltersObject);
          };

          return (
            <S.Checkbox
              checked={item.checked}
              theme={whiteTheme}
              label={
                <Box display="flex" alignItems="center">
                  {AvatarComponent}
                  <Text type="formSubtitle">{item.name}</Text>
                </Box>
              }
              variant="black-and-white"
              key={item.id}
              onChange={onCheckboxChange}
            />
          );
        })}
      </Collapsable>
    </S.FiltersWrapper>
  );
};

export default FiltersSection;
