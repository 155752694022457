import { observer } from "mobx-react";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import BoardExerciseSplashView from "src/js/components/BoardExerciseSplash/BoardExerciseSplashView";
import { useStores } from "src/js/hooks";
import { showToastError } from "src/js/modules/messageManager";
// eslint-disable-next-line import/no-named-as-default
import createUrl from "src/js/modules/routing";
import { fetchExerciseEntities } from "src/js/repository/exerciseRepository";
import ThemeProvider from "src/js/theme/ThemeProvider";
import TranslationProvider, {
  Translate
} from "src/js/translation/TranslationProvider";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { DoodleLoader } from "@arcadia/design-system";

const sanitizeData = data => {
  const {
    title,
    created_by,
    quizzes_count,
    time_threshold,
    success_threshold,
    report_stats,
    user_last_execution_report,
    show_results,
    type
  } = data || {};
  return {
    title,
    creatorCompleteName: `${created_by?.name} ${created_by?.surname}`,
    avatar: created_by?.avatar?.small,
    quizzesCount: quizzes_count,
    timeThresholdMinutes: time_threshold / 60,
    successThreshold: success_threshold,
    averageMark: report_stats?.vote_avg
      ? Math.round(report_stats.vote_avg * 100) / 10
      : 0,
    bestMark: user_last_execution_report?.vote
      ? Math.round(user_last_execution_report?.vote * 100) / 10
      : 0,
    user_last_execution_report,
    usersPassedCount: report_stats?.users_passed_count,
    hiddenResults: !show_results,
    lastSessionId: user_last_execution_report?.session_id,
    pendingReply: user_last_execution_report?.count_redo_needed,
    type
  };
};

const BoardExerciseSplash = ({ exerciseId, exercise = {} }) => {
  const [exerciseData, setExerciseData] = useState(null);
  const {
    GroupStore: { activeGroup, userIsTeacher, settings, studentsCount },
    SpaceStore: { hasDecimalGrading }
  } = useStores();

  const { whiteTheme } = useTheme();

  // take old exercise object from old endpoint if available, to be removed after board detail refactor
  useEffect(() => {
    if (isEmptyObject(exercise)) {
      fetchExerciseEntities({ exerciseId, report_by: "best" })
        .then(data => {
          setExerciseData(sanitizeData(data));
        })
        .catch(() =>
          showToastError({ str: <Translate text="splash_entities_error" /> })
        );
    } else {
      setExerciseData(sanitizeData(exercise.attributes));
    }
  }, [exerciseId, exercise]);

  const exerciseUrl = `${createUrl("exercises_autostart", {
    group_id: activeGroup.id,
    exercise_id: exerciseId
  })}`;

  const reportUrl = `${createUrl("exercises_reports_users", {
    group_id: activeGroup.id,
    exercise_id: exerciseId
  })}`;

  const lastSessionUrl = exerciseData?.lastSessionId
    ? `${createUrl("exercises_reports_sessions", {
        group_id: activeGroup.id,
        exercise_id: exerciseId,
        session_id: exerciseData?.lastSessionId
      })}`
    : null;

  const examinationFailed =
    !userIsTeacher &&
    exerciseData &&
    exerciseData.type === "EXAMINATION" &&
    exerciseData.user_last_execution_report &&
    exerciseData.bestMark < exerciseData.successThreshold / 10;

  const exerciseExecutionDisabled = settings?.exerciseExecution === "disabled";

  return exerciseData ? (
    <ThemeProvider>
      <TranslationProvider>
        <BoardExerciseSplashView
          {...exerciseData}
          studentsCount={studentsCount}
          isTeacher={userIsTeacher}
          exerciseUrl={exerciseUrl}
          reportUrl={reportUrl}
          lastSessionUrl={lastSessionUrl}
          examinationFailed={examinationFailed}
          exerciseExecutionDisabled={exerciseExecutionDisabled}
          hasDecimalGrading={hasDecimalGrading}
        />
      </TranslationProvider>
    </ThemeProvider>
  ) : (
    <DoodleLoader theme={whiteTheme} />
  );
};

export default observer(BoardExerciseSplash);
