import styled from "styled-components";
import { __NO_RESULTS__ } from "src/js/settings/settingsImage";

export const BlockedBoardElementImage = styled.div`
  background-image: url(${__NO_RESULTS__});
  background-repeat: no-repeat;
  height: 104px;
  width: 104px;
`;

export const BlockedBoardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`;

export const TextBlock = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 280px;
  flex-direction: column;
`;
