import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import React, { createContext, useContext, useMemo } from "react";
import { Sidebar } from "src/js/components/layout/Sidebar";
import { Toolbar } from "src/js/components/layout/Toolbar";
import { ToolbarMobile } from "src/js/components/layout/ToolbarMobile";
import { TopBannerDemoSpace } from "src/js/components/layout/TopBannerDemoSpace";
import { TopBannerErrorConnection } from "src/js/components/layout/TopBannerErrorConnection";
import { useStores } from "src/js/hooks";
import { Animations } from "src/js/settings/animations";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import * as S from "./AppLayout.styles";
import { AppLayoutContextType, AppLayoutProps } from "./AppLayout.types";
import { useAppLayoutExceptions } from "./hooks/useAppLayoutExceptions";
import { useAppLayoutHeader } from "./hooks/useAppLayoutHeader";
import { useStickyBanner } from "./hooks/useStickyBanner";

const AppLayoutContext = createContext<AppLayoutContextType | undefined>(
  undefined
);

export const withAppLayout = Component => {
  const WithAppLayout = props => {
    return (
      <AppLayoutContext.Consumer>
        {context => {
          if (!context) {
            throw new Error(
              "withAppLayout must be used within a AppLayoutContext.Provider"
            );
          }
          return <Component {...props} appLayout={context} />;
        }}
      </AppLayoutContext.Consumer>
    );
  };

  return WithAppLayout;
};

export const useAppLayout = () => {
  const context = useContext(AppLayoutContext);
  if (!context) {
    throw new Error(
      "useAppLayout must be used within an AppLayoutContext.Provider"
    );
  }
  return context;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const {
    UIStore: { isSideBarOpen, isLayoutModeMobile }
  } = useStores();
  const {
    headerComponent,
    setHeaderComponent,
    headerHeight,
    headerContainerRef,
    resetHeaderComponent
  } = useAppLayoutHeader();
  const {
    stickyBannerContainerRef,
    stickyBannerHeight,
    isDemoSpaceTopBannerVisible,
    onCloseDemoSpaceTopBanner
  } = useStickyBanner();
  const { shouldSidebarBeHidden, shouldHideAllLayouts } =
    useAppLayoutExceptions();

  const pageMinHeight = useMemo(
    () =>
      isLayoutModeMobile
        ? // Full size - header - sticky banner - mobile tab bar
          `calc(100dvh - ${headerHeight}px - ${stickyBannerHeight}px - 60px)`
        : // Full size - header - sticky banner
          `calc(100dvh - ${headerHeight}px - ${stickyBannerHeight}px)`,
    [headerHeight, stickyBannerHeight, isLayoutModeMobile]
  );

  return (
    <AppLayoutContext.Provider
      value={{
        setHeaderComponent,
        resetHeaderComponent,
        headerHeight: headerHeight + stickyBannerHeight,
        shouldHideAllLayouts,
        shouldSidebarBeHidden,
        pageMinHeight
      }}
    >
      <>
        <TopBannerErrorConnection />
        <AnimatePresence>
          <S.StickyBannerWrapper
            ref={stickyBannerContainerRef}
            {...Animations.Height}
          >
            {!isLayoutModeMobile &&
              isDemoSpaceTopBannerVisible &&
              !shouldHideAllLayouts && (
                <TopBannerDemoSpace onClose={onCloseDemoSpaceTopBanner} />
              )}
          </S.StickyBannerWrapper>
        </AnimatePresence>
        <S.AppLayoutContainer>
          {!isLayoutModeMobile && !shouldHideAllLayouts && (
            <S.ToolbarContainer stickyBannerHeight={stickyBannerHeight}>
              <Toolbar />
            </S.ToolbarContainer>
          )}
          <S.MainContainer>
            {!shouldHideAllLayouts && (
              <S.TopHeaderContainer
                id={UsetifulId.TopHeaderContainer}
                stickyBannerHeight={stickyBannerHeight}
                ref={headerContainerRef}
              >
                {headerComponent}
              </S.TopHeaderContainer>
            )}
            <S.PageContainer id="page-container">
              {!isLayoutModeMobile && (
                <AnimatePresence initial={false} presenceAffectsLayout>
                  {isSideBarOpen &&
                    !shouldSidebarBeHidden &&
                    !shouldHideAllLayouts && (
                      <S.SidebarContainer
                        stickyBannerHeight={stickyBannerHeight}
                        id="sidebar-container"
                        key="ws-sidebar"
                        initial={{ width: 0, opacity: 0 }}
                        animate={{
                          width: "auto",
                          opacity: 1,
                          transition: { stiffness: 25, damping: 15 }
                        }}
                        exit={{ width: 0, opacity: 0 }}
                      >
                        <Sidebar />
                      </S.SidebarContainer>
                    )}
                </AnimatePresence>
              )}
              <S.PageContent isLayoutModeMobile={isLayoutModeMobile}>
                {children}
              </S.PageContent>
            </S.PageContainer>
          </S.MainContainer>
          {isLayoutModeMobile &&
            isDemoSpaceTopBannerVisible &&
            !shouldHideAllLayouts && (
              <TopBannerDemoSpace onClose={onCloseDemoSpaceTopBanner} />
            )}
          {isLayoutModeMobile && !shouldHideAllLayouts && <ToolbarMobile />}
        </S.AppLayoutContainer>
      </>
    </AppLayoutContext.Provider>
  );
};

export default observer(AppLayout);
