import { Heading } from "@arcadia/design-system";
import React from "react";
import { SpaceBlockModal } from "src/js/components/SpaceBlockModal";
import { MainContainer } from "src/js/components/layout/MainContainer";
import createUrl from "src/js/modules/routing";
import { BLOCKED_FAKE_USERS } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersBlocked.const";
import { SpacesUsersFilters } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersFilters";
import { SpacesUsersList } from "src/js/pages/spaces/dashboard/SpacesUsers/SpacesUsersList";
import { S3Video } from "src/js/settings/settingsVideo";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import * as S from "./SpacesUsers.styles";

const SpacesUsersBlocked = ({ spaceId }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <SpaceBlockModal
        video={S3Video.DashboardUsers}
        description="space_block_modal_description"
        primaryCta={() =>
          navigateTo(
            createUrl("dashboard_settings", {
              space_id: spaceId,
              option: "plan"
            })
          )
        }
        primaryCtaLabel="space_block_modal_primary_cta"
        zIndex="890"
      />
      <div style={{ filter: "blur(5px)" }}>
        <MainContainer>
          <S.PageContainer>
            <S.TitleWrapper>
              <Heading level="1">
                <Translate text="users" />
              </Heading>
              <S.UsersCounter>{5}</S.UsersCounter>
            </S.TitleWrapper>
            <SpacesUsersFilters />
            <SpacesUsersList
              filteredUsers={BLOCKED_FAKE_USERS}
              totalUsers={5}
            />
          </S.PageContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default SpacesUsersBlocked;
