import { Palette } from "@ds_themes/types";

export const createCtaTertiaryTheme = ({ grey }: Palette) => {
  return {
    ctaTertiary: {
      background: "transparent",
      text: grey[50],
      type: "ctaTertiary",
      hover: {
        text: grey[50]
      },
      disabled: {
        text: grey[800]
      },
      focus: {
        border: `${grey[800]} solid 2px`
      }
    }
  };
};
