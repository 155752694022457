import { BoxWithIcons, colors, Heading, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { SpaceListModel, SpaceModel, SpaceUserRoleEnum } from "src/js/types";
import { useTheme } from "styled-components";
import {
  ActivateAccountCenterContent,
  ActivateAccountWrapContent,
  ActivateAccountWrapText
} from "../ActivateAccount.styles";
import * as S from "./SpaceCreationPage.styles";
import { SpaceCreationPageProps } from "./SpaceCreationPage.types";

const SpaceCreationPage: FC<SpaceCreationPageProps> = ({
  goToClaim,
  onComplete
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  const { SpaceStore, UIStore } = useStores();
  const { addNewSpaceToSpaceList } = SpaceStore;
  const { isLayoutModeMobile: isMobile } = UIStore;

  const onSuccessCallback = (newSpace: SpaceModel) => {
    const spaceListModel: SpaceListModel = {
      space: newSpace,
      role: SpaceUserRoleEnum.Owner,
      unreadNotificationCount: 0,
      unreadThreadMessagesCount: 0
    };
    addNewSpaceToSpaceList(spaceListModel);
    onComplete(newSpace);
  };

  return (
    <ActivateAccountWrapContent>
      <ActivateAccountCenterContent isMobile={isMobile}>
        <ActivateAccountWrapText>
          <BoxWithIcons
            width="max-content"
            showIcons={isMobile}
            icons={[
              {
                icon: "snowflake",
                color: colors.violet[500],
                top: "-16px",
                right: "-24px",
                width: "25px",
                height: "25px"
              }
            ]}
          >
            <Heading level="3">{translate("space_creation_title")}</Heading>
          </BoxWithIcons>
          <Text type="formDescription">
            {translate("space_creation_subtitle")}
          </Text>
        </ActivateAccountWrapText>
        <S.WrapSpaceCreationForm isMobile={isMobile}>
          <S.StyledSpaceCreationForm
            theme={whiteTheme}
            onSuccessCallback={onSuccessCallback}
            goToClaim={goToClaim}
          />
        </S.WrapSpaceCreationForm>
      </ActivateAccountCenterContent>
    </ActivateAccountWrapContent>
  );
};

export default observer(SpaceCreationPage);
