import React, { useEffect, useRef } from "react";
import { Translate } from "src/js/translation/TranslationProvider";
import * as S from "./InputPoint.styles";

const InputPoint = ({ value = null, maxValue = 5, onChange = () => {} }) => {
  const editableInputRef = useRef();

  useEffect(() => {
    if (Number(value) > maxValue) {
      onChange(maxValue);
      const newWidth = String(maxValue).length * 10 + 4;
      editableInputRef.current.style.width = `${newWidth}px`;
    }
  });

  useEffect(() => {
    if (!value || value > maxValue) return;
    const newWidth = String(value).length * 10 + 4;

    editableInputRef.current.style.width = `${newWidth}px`;
  }, [value]);

  return (
    <S.InputPointContainer>
      <S.InputPoint>
        <S.EditableInputPoint
          type="number"
          value={value}
          ref={editableInputRef}
          placeholder="-"
          max={maxValue}
          min={0}
          onChange={event => {
            onChange(Number(event.target.value));
          }}
        />
        <span>/</span>
        <S.FixedValue>{maxValue}</S.FixedValue>
      </S.InputPoint>
      <S.InputPointLabel>
        <Translate text="quiz_points_input_label" />
      </S.InputPointLabel>
    </S.InputPointContainer>
  );
};

export default InputPoint;
