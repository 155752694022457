import React from "react";

import * as S from "./ImageViewer.styles";
import { ImageProps } from "./types";

const ImageViewer = ({ alt, url, fallbackImage, customWidth }: ImageProps) => {
  return (
    <S.ImgWiew src={url || fallbackImage} customWidth={customWidth} alt={alt} />
  );
};

export default ImageViewer;
