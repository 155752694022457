import React from "react";
import { DotsMenu } from "src/js/components/DotsMenu";
import { ResourcePicker } from "src/js/components/ResourcePicker";
import { RESOURCE_PICKER_SECTION } from "src/js/components/ResourcePicker/ResourcePicker.const";
import { useResourcePayload } from "src/js/hooks/resources";
import { showToastError } from "src/js/modules/messageManager";
import { useTranslation } from "src/js/translation";
import { Resource } from "src/js/types";
import styled, { createGlobalStyle } from "styled-components";

const CustomResourcePicker = styled(ResourcePicker)`
  width: 300px;
  background: ${({ theme }) => theme.colors.grey[1000]};

  max-height: 400px;
  @media screen and (max-width: 767px) {
    max-height: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
    width: 100%;
  }
`;

const CustomDotsMenu = styled(DotsMenu)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.grey[50]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[50]};
    color: ${({ theme }) => theme.colors.grey[1000]};
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

const GlobalStyleAttachment = createGlobalStyle<{ openFromTop?: boolean }>`
  #exercise-attachments-list {
    max-width: fit-content;
    border-radius: 4px;
    bottom: ${({ openFromTop }) => (openFromTop ? "48px" : "unset")};
    top: ${({ openFromTop }) => (openFromTop ? "unset" : "48px")};

    overflow: hidden;
    right: 0;
  }
`;

const ExerciseResourceUploader = ({
  addResource,
  openFromTop
}: {
  addResource: (res: Resource) => void;
  openFromTop?: boolean;
}) => {
  const { translate } = useTranslation();
  const onErrorCallback = errorData => {
    showToastError({
      str: errorData?.data?.message
    });
  };
  const { prepareResourcePayload } = useResourcePayload({
    onSuccessCallback: addResource,
    onLoadingCallback: () => {},
    onErrorCallback
  });

  const resourcePickerEl = (
    <CustomResourcePicker
      section={RESOURCE_PICKER_SECTION.EXERCISE}
      onSubmitCallbackSuccess={(_, successData: Resource) => {
        addResource(successData);
      }}
      // TODO add a loading state?
      setUploadingProgress={() => {}}
      onSubmitCallbackError={onErrorCallback}
      prepareModuleResourcePayload={prepareResourcePayload}
      elementIndex={0}
      embedded
    />
  );

  return (
    <>
      <GlobalStyleAttachment openFromTop={openFromTop} />
      <CustomDotsMenu
        id="exerciseResourceUploaderDotsMenu"
        alignRight
        openFromTop={openFromTop}
        customIcon="attachment"
        customMenu={resourcePickerEl}
        listContainerId="exercise-attachments-list"
        withMobileHeaderLabels
        withMobileAdaptiveHeight={false}
        mobileCloseLabel={translate("resource_mobile_close_modal")}
        mobileConfirmLabel={translate("resource_mobile_confirm_modal")}
        mobileTitle={translate("resource_mobile_title_modal")}
      />
    </>
  );
};

export default ExerciseResourceUploader;
