import { __ } from "../../modules/localization";
import filter from "lodash/filter";
import QuizMatchingModel from "./quizModel_matching";

/**
 * Fading Matches Model. Extend base matching.
 */
class QuizFadingMatchesModel extends QuizMatchingModel {
  /** This quiz has no title. Return the instructions instead */
  getTitle() {
    return __("quiz_fadingmatches_execution_label");
  }
  getMaxAttempts() {
    const column1 = filter(this.getQuestions(), { column: 1 });
    let max = this.get("max_attempts");
    if (max === undefined) max = 0;
    return max - column1.length;
  }
  getAvailableAttempts() {
    let att = this.get("available_attempts");
    if (att === undefined) att = this.getMaxAttempts();
    return att;
  }
  setAvailableAttempts(n) {
    this.set("available_attempts", n);
  }
  isFullyAnswered() {
    const questions = this.getQuestions();
    const answers = this.getUserAnswer();
    const expected_answers = filter(questions, { column: 1 });
    return (
      answers.length === expected_answers.length ||
      this.getAvailableAttempts() < 0
    );
  }
}

export default QuizFadingMatchesModel;
