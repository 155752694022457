import { createURL } from "../modules/utility";
import { Model } from "backbone";
import isUndefined from "lodash/isUndefined";
import union from "lodash/union";
import find from "lodash/find";

class ExerciseExecutionModel extends Model {
  url() {
    const url = createURL("api_exercises_executions_entities", {
      exercise_id: this.get("exercise_id"),
      session_id: this.get("id")
    });
    return url;
  }

  fetchWithStats(callbacks) {
    const self = this;
    this.fetch({
      success() {
        $.ajax({
          type: "GET",
          dataType: "json",
          url: createURL("api_exercises_reports_sessions", {
            exercise_id: self.get("exercise_id"),
            session_id: self.get("id")
          }),
          success(ret) {
            let user = self.get("user");
            user.avatar = ret.avatar;
            self.set("user", user);
            self.set("quiz_passed", ret.quiz_passed);
            self.set("quiz_failed", ret.quiz_failed);
            self.set("quiz_passed_count", ret.quiz_passed_count);
            self.set("quiz_failed_count", ret.quiz_failed_count);
            self.set("total_quiz", ret.total_quiz);
            self.set("session_init", ret.session_init);
            self.set("session_time", ret.session_time);
            self.set("session_status", ret.session_status);
            self.set("name", ret.name);
            self.set("surname", ret.surname);
            self.set("user_id", ret.user_id);
            self.set("vote", ret.vote);
            self.set("show_results", ret.show_results);

            if (typeof callbacks.success === "function") callbacks.success();
          },
          error(ret) {
            if (typeof callbacks.error === "function") callbacks.error();
          }
        });
      },
      error() {
        if (typeof callbacks.error === "function") callbacks.error();
      }
    });
  }

  getQuizAnswerById(quizId) {
    const allQuizzes = union(this.get("quiz_failed"), this.get("quiz_passed"));
    const answer = find(allQuizzes, { id: quizId });
    return answer !== undefined ? answer.answers : [];
  }

  getQuizAnswerResourceById(quizId) {
    const executions = this.get("quiz_executions");
    let resource = {};
    executions.forEach(execution => {
      if (execution.quiz.id == quizId) {
        resource = execution.resource;
      }
    });
    return resource;
  }

  getCheatsByQuizId(quizId) {
    const quizzes = union(this.get("quiz_failed"), this.get("quiz_passed"));
    const quiz = find(quizzes, { id: quizId });
    return !isUndefined(quiz) ? quiz.cheats : [];
  }

  getQuizReviewCommentByExecId(execId) {
    const executions = this.get("quiz_executions");
    const execution = find(executions, { id: execId });
    return execution !== undefined ? execution.review_comment : "";
  }

  getQuizReviewedByExecId(execId) {
    const executions = this.get("quiz_executions");
    const execution = find(executions, { id: execId });
    return (
      execution !== undefined &&
      execution.review_response !== undefined &&
      execution.reviewed_at !== undefined &&
      execution.reviewed_by !== undefined
    );
  }

  checkQuizIsCorrectById(quizId) {
    const answer = find(this.get("quiz_passed"), { id: quizId });
    return answer !== undefined;
  }

  getQuizExecutioner() {
    const user = this.get("user");
    const fullName = user?.name + " " + user?.surname;
    return user ? fullName : "";
  }
}

export default ExerciseExecutionModel;
