import React, { useState } from "react";
import { Avatar, Button, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { TextArea, TEXTAREA_VARIANT } from "../forms/TextArea";
import { CommentSubtitle } from "../typography/CommentSubtitle";
import { CommentTitle } from "../typography/CommentTitle";
import * as S from "./UserCommentCard.styles";

const UserCommentCard = ({
  avatar = null,
  username = "",
  date = "",
  message = "",
  editable = false,
  onEditConfirm = () => {}
}) => {
  const [editing, setEditing] = useState(false);
  const [editableMessage, setEditableMessage] = useState(message);

  const { whiteTheme } = useTheme();

  const submitEditMessage = text => {
    onEditConfirm(text);
    setEditing(false);
  };

  const cancelEdit = () => {
    setEditableMessage(message);
    setEditing(false);
  };

  const editSection = (
    <>
      <TextArea
        variant={TEXTAREA_VARIANT.LIGHT}
        defaultValue={editableMessage}
        handleInputChange={value => setEditableMessage(value)}
      />
      <S.EditButtonsContainer>
        <Button
          onClick={() => cancelEdit()}
          variant="secondary"
          theme={whiteTheme}
        >
          <Translate text="Annulla" />
        </Button>
        <Button
          onClick={() => {
            submitEditMessage(editableMessage);
          }}
          variant="primary"
          theme={whiteTheme}
        >
          <Translate text="Salva" />
        </Button>
      </S.EditButtonsContainer>
    </>
  );

  const messageSection = (
    <>
      <S.MessageHeader>
        <S.FromWho>
          <CommentTitle>
            <Translate
              text="report_final_comments_from_who_title"
              stringVariables={{
                username
              }}
            />
          </CommentTitle>
        </S.FromWho>
        <S.DateTime>
          <CommentSubtitle>{date}</CommentSubtitle>
        </S.DateTime>
      </S.MessageHeader>
      <S.MessageContent>{editableMessage}</S.MessageContent>
    </>
  );

  return (
    <S.CardContainer>
      <S.AvatarContainer>
        <Avatar
          theme={whiteTheme}
          url={avatar}
          alt={`${username}`}
          rounded="false"
          size="s"
        />
      </S.AvatarContainer>
      <S.BodyContainer editing={editing}>
        {editing ? editSection : messageSection}
      </S.BodyContainer>
      {editable && !editing ? (
        <S.EditIconContainer
          onClick={() => {
            setEditing(!editing);
          }}
        >
          <Icon icon="pencil" height={18} width={18} />
        </S.EditIconContainer>
      ) : null}
    </S.CardContainer>
  );
};

export default UserCommentCard;
