import { DropdownMultiple, DropdownOption } from "@arcadia/design-system";
import React, { useCallback, useState } from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { DashboardEvent, EventDomain } from "src/js/types";
import { useDebounceCallback } from "src/js/hooks";
import * as S from "./FilterGroups.styles";

type FilterGroupsProps = {
  groupsList?: DropdownOption[];
  selectedGroups?: string[];
  hasNextGroups?: boolean;
  showNextGroups?: () => void;
  onSelectGroups?: (groupIds: string[]) => void;
};

const FilterGroups = ({
  groupsList = [],
  selectedGroups = [],
  hasNextGroups = false,
  showNextGroups = () => {},
  onSelectGroups = () => {}
}: FilterGroupsProps) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const [filterGroupString, setFilterGroupString] = useState("");

  const debouncedTrackEvent = useDebounceCallback(() => {
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.FilterGroupsSearch
    );
  }, 5000);

  const onOptionsOpen = useCallback(isOpen => {
    if (!isOpen) return;
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.FilterGroupsOpen
    );
  }, []);

  return (
    <S.DropdownFilterContainer>
      <DropdownMultiple
        theme={whiteTheme}
        hideButtonTags
        optionsList={groupsList.filter(({ label }) =>
          label.toLowerCase().includes(filterGroupString.toLowerCase())
        )}
        selectedOptionIds={groupsList?.length === 0 ? [] : selectedGroups}
        setSelectedOptionIds={value => {
          onSelectGroups(value);
          _trackEvent(
            EventDomain.SpaceDashboardUsage,
            DashboardEvent.FilterGroupsCheckbox
          );
        }}
        placeholder={translate("groups")}
        searchPlaceholder={translate("search_in_n_groups", {
          nGroups: groupsList?.length
        })}
        scrollableListId="space-group-filter-dropdown-multiple"
        searchable
        searchQuery={filterGroupString}
        onSearchFunction={e => {
          setFilterGroupString(e.target.value);
          debouncedTrackEvent();
        }}
        hasNext={hasNextGroups}
        showNext={showNextGroups}
        onOptionsOpen={onOptionsOpen}
      />
    </S.DropdownFilterContainer>
  );
};

export default FilterGroups;
