import { PlanBillingPeriodTypes } from "src/js/pages/spaces/dashboard/SpacesSettings/SpacesSettingsPlan/CreatePlanForm/CreatePlanForm.types";
import { BillingFrequencyType } from "src/js/types";

export const PLAN_BILLING_PERIOD: PlanBillingPeriodTypes[] = [
  {
    id: BillingFrequencyType.Monthly,
    label: "space_settings_plan_edit_billing_monthly"
  },
  {
    id: BillingFrequencyType.Yearly,
    label: "space_settings_plan_edit_billing_yearly"
  }
];
