import React from "react";
import createReactClass from "create-react-class";

const ColorCodedNumber = createReactClass({
  render() {
    const number = this.props.number;
    const text = this.props.text || "";
    let addStyle = { color: "#000000" };
    if (number > 0) addStyle = { color: "#fb5f39" };
    if (number > 49) addStyle = { color: "#ffc013" };
    if (number > 70) addStyle = { color: "#80e83d" };
    return <span style={addStyle}>{`${number}${text}`}</span>;
  }
});

export default ColorCodedNumber;
