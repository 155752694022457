import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import { ChipProps } from "./Chip.types";
import * as S from "./Chip.styles";

const Chip: FC<ChipProps> = ({ variant, children, theme, className }) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ChipWrapper variant={variant} className={className}>
        {children}
      </S.ChipWrapper>
    </ThemeProvider>
  );
};

export default Chip;
