import React, { forwardRef } from "react";

import { iconMap } from "./iconMap";
import { IconProps } from "./types";

export const Icon = forwardRef<HTMLOrSVGElement, IconProps>(
  (
    {
      icon,
      height = "14",
      width = "14",
      initialViewbox = false,
      viewbox = "0 0 24 24",
      color = null,
      ...rest
    }: IconProps,
    ref
  ) => {
    const Icon = iconMap[icon];
    if (!Icon) return null;

    return (
      <Icon
        height={height}
        width={width}
        color={color}
        {...(!initialViewbox && { viewBox: viewbox })}
        aria-hidden="true"
        title={icon}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default Icon;
