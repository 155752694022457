import React from "react";
import SingleResourceUploader from "src/js/components/SingleResourceUploader";
import {
  __RESOURCE_UPLOAD_LIMITS__,
  __RESOURCE_UPLOAD_MIME__
} from "src/js/settings/settingFileUploader";
import { getActiveGroupId } from "Legacy/modules/activeGroup";

const ResourceUpload = props => {
  const { addFunc } = props;
  return (
    <SingleResourceUploader
      successCallback={addFunc}
      errorCallback={addFunc}
      maxSize={__RESOURCE_UPLOAD_LIMITS__}
      mimeArray={__RESOURCE_UPLOAD_MIME__}
      type="FILE"
      buttonText="add"
      uploadText="carica un nuovo file"
      group_id={getActiveGroupId()}
    />
  );
};
export default ResourceUpload;
