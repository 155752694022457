import { shadow } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const LoadingMenuWrapper = styled(motion.div)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  color: ${({ theme }) => theme.colors.violet[501]};
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${shadow[500]};
`;
