import React, { useState } from "react";
import { Input, INPUT_VARIANT } from "src/js/components/forms/input";
import { debounce } from "src/js/modules/commonFunction";
import BoardSelector from "src/js/pages/App/SharePage/components/BoardSelector";
import GroupSelector from "src/js/pages/App/SharePage/components/GroupSelector";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button, Icon } from "@arcadia/design-system";
import { useTheme } from "styled-components";

const ShareDestinationStep = ({ attachResource }) => {
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [filterString, setFilterString] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { greyTheme } = useTheme();

  let debounceFunc = null;
  return (
    <div className="padding-header margin-horizontal-default">
      <div className="share__search-board-wrapper">
        <Input
          id="search-input"
          defaultValue={filterString}
          handleInputChange={event => {
            event.persist();
            if (!debounceFunc) {
              debounceFunc = debounce(() => {
                setFilterString(event.target.value);
                setSelectedBoard(null);
              }, 500);
            }
            debounceFunc();
          }}
          placeholder="cerca"
          marginBottom={20}
          icon={<Icon icon="search" height={15} width={15} />}
          variant={INPUT_VARIANT.LIGHT}
        />
        <p className="uppercase margin-bottom-10">
          <Translate text="select_group" />
        </p>
        <GroupSelector
          doSelectGroupId={value => {
            setSelectedGroupId(value);
            setSelectedBoard(null);
          }}
        />
        <BoardSelector
          filterString={filterString}
          groupId={selectedGroupId}
          doSelectBoard={setSelectedBoard}
        />
        <div className="flex-centered-column full-width">
          <Button
            onClick={() => attachResource(selectedBoard)}
            disabled={!(selectedBoard && selectedBoard.id)}
            variant="primary"
            theme={greyTheme}
          >
            <Translate text="allega risorsa" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShareDestinationStep;
