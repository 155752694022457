import { useActiveBoard, useSetBoard } from "src/js/context/BoardProvider";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { ModuleMode } from "src/js/pages/modules/Modules.types";
import {
  suggestModuleResource,
  updateBoard
} from "src/js/repository/boardRepository";
import { saveResourceNew } from "src/js/repository/resourceRepository";
import {
  IMAGE_MIME,
  __SCORM_UPLOAD_STANDARD_LIMITS__,
  __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__
} from "src/js/settings/settingFileUploader";
import { ModuleResourceType, RESOURCE_TYPE, ResourceType } from "src/js/types";

type FileResponseData = {
  fileUuid: string;
  fileUrl: string;
  file: File;
  name: string;
  type: ResourceType;
};

type ImageGalleryData = {
  [propKey: string]: string;
  galleryName: string;
  fileUuid: string;
};

const useModuleResourcePayload = ({
  moduleResourcesList,
  elementIndex
}: {
  moduleResourcesList: ModuleResourceType[];
  elementIndex: number;
}) => {
  const {
    SpaceStore: { activeSpaceSlug: spaceSlug },
    GroupStore: { groupId, userIsTeacher },
    BoardsStore: {
      setUploadingProgressOfBoardElement,
      setUploadingElementIndexes,
      filterUploadingElementIndexes
    }
  } = useStores();
  const saveBoard = useSetBoard();
  const activeBoard = useActiveBoard();
  const { handleResourceFileUpload } = useResourceUpload(
    setUploadingProgressOfBoardElement
  );

  const { handleResourceGalleryUpload } = useResourceUpload(
    setUploadingProgressOfBoardElement
  );

  const findAvailabilePositions = () => {
    const listOfAvailablePositions: number[] = [];
    moduleResourcesList.map((resource, index: number) =>
      resource && resource.id ? null : listOfAvailablePositions.push(index)
    );
    return listOfAvailablePositions;
  };

  const isImageGallery = (files: File[]) => {
    if (files.length === 1) return false;
    let count = 0;
    files.map(el => {
      if (IMAGE_MIME.find(type => el.type === type)) count += 1;
      return true;
    });
    return count === files.length;
  };

  const onSuccessOfGalleryUpload = (
    resourcesArray: ImageGalleryData,
    position: number
  ) => {
    const { galleryName, fileUuid, ...rest } = resourcesArray;
    saveResourceNew({
      name: galleryName,
      type: RESOURCE_TYPE.IMAGE_GALLERY,
      spaceSlug,
      fileUuid,
      publicUrls: rest
    }).then(resp => {
      if (userIsTeacher || activeBoard.mode === ModuleMode.edit) {
        updateBoard(activeBoard.id, {
          ...activeBoard,
          updated: Date.now(),
          name: activeBoard.name,
          board_elements: [
            {
              position,
              resource: {
                group_id: groupId,
                id: resp.id,
                name: resp.name,
                type: resp.type
              }
            }
          ]
        }).then(() => {
          saveBoard(activeBoard.id, true);
          filterUploadingElementIndexes(position);
        });
      } else {
        suggestModuleResource(
          activeBoard.id,
          position,
          {
            group_id: groupId,
            id: resp.id,
            name: resp.name,
            type: RESOURCE_TYPE.IMAGE_GALLERY
          },
          groupId
        ).then(() => {
          saveBoard(activeBoard.id, true);
          filterUploadingElementIndexes(position);
        });
      }
    });
  };

  const onSuccessOfUpload = (
    resourceFileResponseData: FileResponseData,
    position: number
  ) => {
    saveResourceNew({
      name: resourceFileResponseData.name,
      type: resourceFileResponseData.type,
      size: resourceFileResponseData.file.size,
      fileMimeType: resourceFileResponseData.file.type,
      spaceSlug,
      fileUuid: resourceFileResponseData.fileUuid,
      publicUrls: [resourceFileResponseData.fileUrl]
    }).then(resp => {
      if (userIsTeacher || activeBoard.mode === ModuleMode.edit) {
        updateBoard(activeBoard.id, {
          ...activeBoard,
          updated: Date.now(),
          name: activeBoard.name,
          board_elements: [
            {
              position,
              resource: {
                group_id: groupId,
                id: resp.id,
                name: resp.name,
                type: resp.type
              }
            }
          ]
        }).then(() => {
          saveBoard(activeBoard.id, true);
          filterUploadingElementIndexes(position);
        });
      } else {
        suggestModuleResource(
          activeBoard.id,
          position,
          {
            group_id: groupId,
            id: resp.id,
            name: resp.name,
            type: RESOURCE_TYPE.FILE
          },
          groupId
        ).then(() => {
          saveBoard(activeBoard.id, true);
          filterUploadingElementIndexes(position);
        });
      }
    });
  };

  const prepareModuleResourcePayload = (
    fileList: FileList,
    isScorm = false
  ) => {
    let payload: {
      file: File;
      spaceSlug: string;
      uploadingElementId: number;
    } = {
      file: null,
      spaceSlug: null,
      uploadingElementId: null
    };
    const listOfUploadingElementsIndexes: number[] = [];

    let indexOfElement: number = null;

    const listOfAvailablePositions = findAvailabilePositions();

    const dropFilePositionIndex = listOfAvailablePositions.findIndex(
      element => element === elementIndex
    );

    const arrayListOfFiles = Array.from(fileList);
    const filteredFiles = arrayListOfFiles
      .filter(file => file.size > 0)
      .filter(file =>
        isScorm
          ? file.size < __SCORM_UPLOAD_STANDARD_LIMITS__
          : file.size < __TOTAL_FILE_RESOURCE_UPLOAD_LIMIT__
      );

    if (!filteredFiles.length) return;

    if (isImageGallery(filteredFiles)) {
      setUploadingElementIndexes(
        listOfAvailablePositions[dropFilePositionIndex]
      );
      handleResourceGalleryUpload(
        filteredFiles,
        listOfAvailablePositions[dropFilePositionIndex],
        res => {
          onSuccessOfGalleryUpload(
            res,
            listOfAvailablePositions[dropFilePositionIndex]
          );
        }
      );
    } else {
      filteredFiles.map(async (file, index) => {
        indexOfElement =
          listOfAvailablePositions[dropFilePositionIndex + index];

        setUploadingElementIndexes(indexOfElement);

        payload = {
          file,
          spaceSlug,
          uploadingElementId:
            listOfAvailablePositions[dropFilePositionIndex + index]
        };

        // Add the position in the array to display Uploading in progress box
        listOfUploadingElementsIndexes.push(
          listOfAvailablePositions[dropFilePositionIndex + index]
        );

        // Increase by 1 the final position in case that the user added more files than available positions.
        listOfAvailablePositions.push(
          listOfAvailablePositions[listOfAvailablePositions.length - 1] + 1
        );

        // Endpoint call for upload resource
        // We will call each upload endpoint for every file
        // ...

        handleResourceFileUpload(payload, isScorm, resp =>
          onSuccessOfUpload(
            resp,
            listOfAvailablePositions[dropFilePositionIndex + index]
          )
        );
      });
    }
  };
  return { prepareModuleResourcePayload };
};

export default useModuleResourcePayload;
