import styled from "styled-components";

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Footer = styled.div`
  margin-top: 32px;
  text-align: right;
`;

export const ToastWrapper = styled.div`
  margin-top: 8px;
`;
