import { startOfToday } from "date-fns";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { getCurrentLanguage } from "src/js/modules/localization";
import { __DEFAULT_LANGUAGE_COUNTRY__ } from "src/js/settings/settingsLocalization";
import * as S from "./AnalyticsCalendar.styles";

const AnalyticsCalendar = ({ startDate, endDate, onChange }) => {
  const [internalStartDate, setInternalStartDate] = useState(startDate);
  const [internalEndDate, setInternalEndDate] = useState(endDate);

  const onSelectingDate = dates => {
    const [start, end] = dates;
    setInternalEndDate(end);
    setInternalStartDate(start);

    if (start && end) {
      onChange([start, end]);
    }
  };

  switch (getCurrentLanguage()) {
    case __DEFAULT_LANGUAGE_COUNTRY__.it:
      registerLocale("locale", it);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.en:
      registerLocale("locale", en);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.es:
      registerLocale("locale", es);
      break;
    case __DEFAULT_LANGUAGE_COUNTRY__.fr:
      registerLocale("locale", fr);
      break;
    default:
      registerLocale("locale", it);
  }

  return (
    <S.AnalyticsCalendarContainer>
      <DatePicker
        locale="locale"
        className="my-class-name"
        selected={internalStartDate}
        onChange={onSelectingDate}
        monthsShown={2}
        startDate={internalStartDate}
        endDate={internalEndDate}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        selectsRange
        inline
        minDate={new Date(2014, 0, 1)}
        maxDate={startOfToday()}
      />
    </S.AnalyticsCalendarContainer>
  );
};

export default AnalyticsCalendar;
