import { z } from "zod";

const axiosErrorSchema = z.object({
  response: z.object({
    status: z.number(),
    data: z.object({
      error: z.literal("access_denied")
    })
  })
});

export const isAxiosError = (error: unknown) => {
  const result = axiosErrorSchema.safeParse(error);
  return result.success;
};

export const isForbiddenError = (error: unknown) => {
  const result = axiosErrorSchema.safeParse(error);
  return result.success && result.data.response.status === 403;
};

export const isAccessDeniedError = (error: unknown) => {
  const result = axiosErrorSchema.safeParse(error);
  return result.success && result.data.response.data.error === "access_denied";
};
