import { Palette, PostStylesMap } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";

import { createAvatarTheme } from "./avatar";

const createBasicTheme = ({ general, primary, grey }: Palette) => {
  return {
    basic: {
      background: general.background,
      text: general.contrastText,
      pin: getHighContrastColor(primary.main, primary.dark, primary.main),
      ribbon: getHighContrastColor(primary.main, primary.dark, primary.main),
      date: {
        text: grey[300]
      }
    }
  };
};

const createCommentTheme = ({ primary, general, grey }: Palette) => {
  return {
    comment: {
      background: general.background,
      ribbon: getHighContrastColor(primary.main, primary.dark, primary.main),
      date: {
        text: grey[300]
      }
    }
  };
};

const createBadgeScoreTheme = ({ general, primary }: Palette) => {
  return {
    badgeScore: {
      background: general.background,
      border: getHighContrastColor(primary.main, primary.dark, primary.main)
    }
  };
};

export const createPostTheme = (palette: Palette): { post: PostStylesMap } => {
  const avatarTheme = createAvatarTheme(palette);
  return {
    post: {
      ...createBasicTheme(palette),
      ...createCommentTheme(palette),
      ...createBadgeScoreTheme(palette),
      ...avatarTheme
    }
  };
};
