import { useState, useEffect } from "react";
import { TranslationKeys, useTranslation } from "../translation";

// On modern browser the functionality of having a custom messages has been removed
// https://stackoverflow.com/questions/66230698/how-to-set-my-own-message-on-beforeunload-event
const useUnsavedChangesWarning = (message: TranslationKeys) => {
  const [warnExit, setWarnExit] = useState(false);
  const { translate } = useTranslation();

  useEffect(() => {
    window.onbeforeunload = warnExit && (() => translate(message));

    return () => {
      window.onbeforeunload = null;
    };
  }, [warnExit]);

  return [() => setWarnExit(true), () => setWarnExit(false)];
};

export default useUnsavedChangesWarning;
