import React, { FC } from "react";
import { UnderlinedSpan } from "src/js/components/UnderlinedSpan";
import { SpacesWelcomeTitleProps } from "./SpacesWelcomeTitle.types";

const SpacesWelcomeTitle: FC<SpacesWelcomeTitleProps> = ({ label = "" }) => {
  const words = label?.split(" ");
  const lastWord = words?.pop();
  const main = words?.join(" ");
  return (
    <>
      {main} {lastWord && <UnderlinedSpan>{lastWord}</UnderlinedSpan>}
    </>
  );
};

export default SpacesWelcomeTitle;
