import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import styled from "styled-components";

export const FirstStepWrapper = styled.div`
  padding: 24px 20px 20px;
  width: 584px;
  height: 576px;
  overflow-y: scroll;
  ${scrollbarMixin(VariantType.White)};

  @media (max-width: 767px) {
    width: unset;
    height: unset;
    height: 100%;
    padding: 24px 16px 16px;
  }
`;
export const LoadingWrapper = styled.div`
  padding: 24px 20px 20px;
  width: 450px;

  @media (max-width: 767px) {
    width: unset;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const ThirdStepWrapper = styled.div`
  padding: 24px 12px 20px 20px;
  width: 584px;
  height: 554px;

  @media (max-width: 767px) {
    width: unset;
    height: unset;
    padding: 24px 16px 16px;
  }
`;
