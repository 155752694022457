/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction, toJS } from "mobx";
import moment from "moment";
import { getCurrentLanguage } from "src/js/modules/localization";
import {
  ANALYTICS_DASHBOARD_FAKE_INDEXES,
  generateAnalyticsDashboardFakeTrend
} from "src/js/pages/spaces/dashboard/SpacesAnalytics/AnalyticsDashboardFakeUtils";
import {
  fetchSpaceAnalyticsIndexes,
  fetchSpaceGroupsAnalyticsIndexes,
  fetchSpaceUserAnalyticsIndexes,
  fetchSpaceTrendGraphData,
  fetchSpacesGroups,
  fetchSpacesUsers
} from "src/js/repository/dashboardRepository";
import { equalsArrayIgnoreOrder } from "../modules/commonFunction";
import {
  BY_DAY_ACCURACY,
  POSTS_AND_COMMENTS_TOPIC
} from "../pages/spaces/dashboard/SpacesAnalytics/AnalyticsDashboard.const";
import { getIntervalRange } from "../pages/spaces/dashboard/SpacesAnalytics/AnalyticsSectionTrend/elaborateInterval";
import { __SPACE_GROUPS_LIST_SEARCH_LIMIT__ } from "../settings/settingsPagination";

/**
 * @name SpaceAnalyticsFilterStore
 *
 * @description
 * Sets user data useful for analytics filters, like user you're filtering on, or list of groups
 */
class SpaceAnalyticsFilterStore {
  $filters = { $selectedUser: null, $selectedGroups: [] };
  $trendFilters = {
    $topic: POSTS_AND_COMMENTS_TOPIC,
    $date_placeholder: "last_month_graph_interval",
    $from_date: getIntervalRange().fromDate,
    $to_date: getIntervalRange().toDate,
    $period_accuracy: BY_DAY_ACCURACY
  };

  $spaceGroups = {
    $spaceGroupsList: [],
    $offset: 0,

    $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
    $total: 0
  };

  $indexes = null;

  $trendData = [{}];

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
    this.disposeUpdateUser();
    this.disposeUpdateGroups();
    this.disposeUpdateGraph();
  }

  spaceFetchGroups(spaceId, nextOffset) {
    return fetchSpacesGroups({
      spaceId,
      limit: this.$spaceGroups.$limit,
      offset: nextOffset
    })
      .then(({ results, offset, total }) => {
        this.$spaceGroups.$spaceGroupsList = [
          ...this.$spaceGroups.$spaceGroupsList,
          ...results
        ];
        this.$spaceGroups.$offset = offset;
        this.$spaceGroups.$total = total;
      })
      .catch(() => {
        this.$spaceGroups = {
          $spaceGroupsList: [],
          $offset: 0,
          $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
          $total: 0
        };
      });
  }

  fetchSpaceGroups = spaceId => {
    if (!spaceId) {
      this.$spaceGroups = {
        $spaceGroupsList: [],
        $offset: 0,
        $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
        $total: 0
      };
      return null;
    }
    return this.spaceFetchGroups(spaceId, this.$spaceGroups.$offset);
  };

  spaceShowNextGroups = () => {
    if (!this.spaceHasNextGroups) return () => {};

    const spaceId = this.spaceStore?.activeSpaceId;

    const nextOffset = this.$spaceGroups.$offset + this.$spaceGroups.$limit;

    return this.spaceFetchGroups(spaceId, nextOffset);
  };

  spacesSearchUserByName = name => {
    const spaceId = this.spaceStore?.activeSpaceId;

    if (!spaceId) return null;

    return fetchSpacesUsers({
      spaceId,
      name
    })
      .then(({ results }) => {
        return results;
      })
      .catch(() => []);
  };

  spaceFetchAnalyticsIndexes = ({
    spaceId = null,
    userId = null,
    groups = null
  }) => {
    if (!spaceId) return;
    if (this.spaceStore?.isDemoSpace) {
      this.$indexes = ANALYTICS_DASHBOARD_FAKE_INDEXES;
      return;
    }

    fetchSpaceAnalyticsIndexes({
      spaceId,
      user: userId,
      groups
    })
      .then(res => {
        this.$indexes = res;
      })
      .catch(() => {
        this.$indexes = null;
      });
  };

  spaceFetchGroupsAnalyticsIndexes = ({ spaceId = null, groupIds = null }) => {
    if (!spaceId || !groupIds) return;
    if (this.spaceStore?.isDemoSpace) {
      this.$indexes = ANALYTICS_DASHBOARD_FAKE_INDEXES;
      return;
    }

    fetchSpaceGroupsAnalyticsIndexes({ spaceId, groupIds })
      .then(res => {
        this.$indexes = res;
      })
      .catch(() => {
        this.$indexes = null;
      });
  };

  spaceFetchUserAnalyticsIndexes = ({ spaceId = null, userId = null }) => {
    if (!spaceId || !userId) return;
    if (this.spaceStore?.isDemoSpace) {
      this.$indexes = ANALYTICS_DASHBOARD_FAKE_INDEXES;
      return;
    }

    fetchSpaceUserAnalyticsIndexes({ spaceId, userId })
      .then(res => {
        this.$indexes = res;
      })
      .catch(() => {
        this.$indexes = null;
      });
  };

  spaceFetchTrendGraphData = ({
    spaceId = null,
    userId = null,
    groupIds = null,
    type = null,
    range = null,
    from_date,
    to_date
  }) => {
    if (!spaceId || !from_date || !to_date) return;
    if (this.spaceStore?.isDemoSpace) {
      this.$trendData = generateAnalyticsDashboardFakeTrend();
      return;
    }
    fetchSpaceTrendGraphData({
      spaceId,
      userId,
      groupIds,
      type,
      range,
      from_date,
      to_date
    })
      .then(({ results }) => {
        this.$trendData = results;
      })
      .catch(() => {
        this.$trendData = [{}];
      });
  };

  spaceSetSelectedGroups = groupsIds => {
    this.$filters.$selectedGroups = groupsIds;
    return groupsIds;
  };

  spaceSetSelectedUser = user => {
    this.$filters.$selectedUser = user;
    return user;
  };

  spaceSetSelectedTopic = topic => {
    this.$trendFilters.$topic = topic;
    return topic;
  };

  spaceSetSelectedPeriodAccuracy = accuracy => {
    this.$trendFilters.$period_accuracy = accuracy;
    return accuracy;
  };

  spaceSetSelectedRange = ({ from_date, to_date }) => {
    this.$trendFilters.$from_date = from_date;
    this.$trendFilters.$to_date = to_date;

    return { from_date, to_date };
  };

  spaceSetDatePlaceholder = value => {
    this.$trendFilters.$date_placeholder = value;
    return value;
  };

  setFiltersFromQueryParams = queryParamsObject => {
    let $selectedUser = toJS(this.$filters.$selectedUser);
    if (queryParamsObject.user_filter) {
      $selectedUser = JSON.parse(queryParamsObject.user_filter);
    }

    let $selectedGroups = toJS(this.$filters.$selectedGroups);
    if (queryParamsObject.groups_filter) {
      const groupIds = queryParamsObject.groups_filter.split(",");
      const numericGroupIds = groupIds.map(groupId => Number(groupId));
      $selectedGroups = numericGroupIds;
    }

    const newFilters = {
      $selectedUser,
      $selectedGroups
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [keyFilter, valueFilter] of Object.entries(
      toJS(this.$filters)
    )) {
      const isArray = Array.isArray(this.$filters[keyFilter]);
      const hasSameValue = isArray
        ? equalsArrayIgnoreOrder(newFilters[keyFilter], valueFilter)
        : newFilters[keyFilter]?.id === valueFilter?.id;
      if (!hasSameValue) {
        this.$filters[keyFilter] = newFilters[keyFilter];
      }
    }

    const { type, from_date, to_date, range, date_placeholder } =
      queryParamsObject;

    const newTrendFilters = {
      $topic: type,
      $from_date: from_date ? new Date(from_date.replace(/\+/g, " ")) : null,
      $to_date: to_date ? new Date(to_date.replace(/\+/g, " ")) : null,
      $date_placeholder: date_placeholder
        ? date_placeholder.replace(/\+/g, " ")
        : null,
      $period_accuracy: range
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [keyTrend, valueTrend] of Object.entries(
      toJS(this.$trendFilters)
    )) {
      let hasSameValue = false;
      if (keyTrend === "$from_date" || keyTrend === "$to_date") {
        hasSameValue =
          moment(newTrendFilters[keyTrend])
            .locale("en")
            .format("DD-MMM-YYYY") ===
          moment(valueTrend).locale("en").format("DD-MMM-YYYY");
      } else {
        hasSameValue = newTrendFilters[keyTrend] === valueTrend;
      }

      if (!hasSameValue) {
        this.$trendFilters[keyTrend] = newTrendFilters[keyTrend];
      }
    }
  };

  reset = () => {
    this.$filters = { $selectedUser: null, $selectedGroups: [] };
    this.$trendFilters = {
      $topic: POSTS_AND_COMMENTS_TOPIC,
      $date_placeholder: "last_month_graph_interval",
      $from_date: getIntervalRange().fromDate,
      $to_date: getIntervalRange().toDate,
      $period_accuracy: BY_DAY_ACCURACY
    };

    this.$spaceGroups = {
      $spaceGroupsList: [],
      $offset: 0,

      $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
      $total: 0
    };

    this.$indexes = null;

    this.$trendData = [{}];
  };

  // computed
  get filterValuesForQueryParams() {
    const { $selectedUser, $selectedGroups } = this.$filters;
    const {
      $topic,
      $period_accuracy,
      $date_placeholder,
      $from_date,
      $to_date
    } = this.$trendFilters;

    let selectedUserJSON = null;
    if ($selectedUser) {
      selectedUserJSON = JSON.stringify({
        id: $selectedUser.id,
        name: $selectedUser.name,
        surname: $selectedUser.surname
      });
    }

    let customFromDate = null;
    let customToDate = null;
    if ($from_date) {
      customFromDate = $from_date.toDateString();
    }
    if ($to_date) {
      customToDate = $to_date.toDateString();
    }

    return {
      old: true,
      user_filter: selectedUserJSON,
      groups_filter: $selectedGroups.length === 0 ? null : $selectedGroups,
      type: $topic,
      from_date: customFromDate,
      to_date: customToDate,
      date_placeholder: $date_placeholder,
      range: $period_accuracy
    };
  }

  get spaceGroupsList() {
    return this.$spaceGroups.$spaceGroupsList.map(({ group }) => ({
      key: group.id,
      value: group.name
    }));
  }

  get spaceGroupsTotal() {
    return this.$spaceGroups.$total;
  }

  get spaceHasNextGroups() {
    return this.spaceGroupsList.length < this.spaceGroupsTotal;
  }

  get spaceGroupsEndpointInfo() {
    return {
      offset: this.$spaceGroups.$offset,
      limit: this.$spaceGroups.$limit,
      total: this.$spaceGroups.$total
    };
  }

  get indexes() {
    return this.$indexes;
  }

  get selectedUser() {
    return this.$filters.$selectedUser;
  }

  get selectedGroups() {
    return this.$filters.$selectedGroups;
  }

  get selectedTopic() {
    return this.$trendFilters.$topic;
  }

  get selectedRange() {
    return {
      from_date: this.$trendFilters.$from_date,
      to_date: this.$trendFilters.$to_date
    };
  }

  get datePlaceholder() {
    return this.$trendFilters.$date_placeholder;
  }

  get selectedPeriodAccuracy() {
    return this.$trendFilters.$period_accuracy;
  }

  get trendFilters() {
    return {
      $userId: this.$filters.$selectedUser?.id,
      $groups: this.$filters.$selectedGroups,
      $topic: this.$trendFilters.$topic,
      $from_date: this.$trendFilters.$from_date,
      $to_date: this.$trendFilters.$to_date,
      $period_accuracy: this.$trendFilters.$period_accuracy
    };
  }

  get trendGraphData() {
    const correctFormat =
      this.$trendFilters.$period_accuracy === "month"
        ? "MMM YYYY"
        : "DD MMM YYYY";
    return this.$trendData.map(val => {
      return {
        time: moment(val.time)
          .locale(getCurrentLanguage())
          .format(correctFormat),
        value: val.value
      };
    });
  }

  disposeUpdateGraph = () => {
    reaction(
      () => this.trendFilters,
      filters => {
        const spaceId = this.spaceStore?.activeSpaceId;
        if (spaceId) {
          this.spaceFetchTrendGraphData({
            spaceId,
            userId: filters.$userId,
            groupIds: filters.$groups,
            type: filters.$topic,
            range: filters.$period_accuracy,
            from_date: moment(filters.$from_date)
              .locale("en")
              .format("DD-MM-YYYY"),
            to_date: moment(filters.$to_date).locale("en").format("DD-MM-YYYY")
          });
        }
      }
    );
  };

  disposeUpdateUser = () => {
    reaction(
      // reacts on change of query params
      () => this.selectedUser,
      user => {
        const spaceId = this.spaceStore?.activeSpaceId;
        if (user) {
          this.$filters.$selectedGroups = [];
          this.spaceFetchUserAnalyticsIndexes({
            spaceId,
            userId: user.uuid
          });
        } else if (this.$filters.$selectedGroups.length === 0) {
          this.spaceFetchAnalyticsIndexes({
            spaceId
          });
        }
      }
    );
  };

  disposeUpdateGroups = () => {
    reaction(
      // reacts on change of query params
      () => this.selectedGroups,
      groups => {
        const spaceId = this.spaceStore?.activeSpaceId;
        if (groups.length > 0) {
          this.$filters.$selectedUser = null;
          this.spaceFetchGroupsAnalyticsIndexes({
            spaceId,
            groupIds: groups
          });
        } else if (this.$filters.$selectedUser === null) {
          this.spaceFetchAnalyticsIndexes({
            spaceId
          });
        }
      }
    );
  };
}

export default SpaceAnalyticsFilterStore;
