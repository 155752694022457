import styled from "styled-components";

export const Grid = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;

  // min breakpoint to contain this grid is:
  // 7 * (142 + 14) = 1092px
  @media (min-width: 1092px) {
    display: grid;
    grid-template-columns: repeat(7, 142px);
    grid-template-rows: repeat(3, 142px);
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }
`;

export const Cell = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 142px;
  height: 142px;
  border-radius: 8px;
  gap: 1.25rem;
  border: 0;
  background-color: ${({ theme }) => theme.picker.background};
  &:hover {
    cursor: pointer;
    border: ${({ theme }) => theme.picker.border};
  }
  &:focus {
    outline: ${({ theme }) => theme.picker.focus.border};
  }
  div {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 70px;
  }
  p {
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;
