import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { getActiveGroup } from "../modules/activeGroup";
import { isUnloggedLocation, navigateTo } from "../modules/history";
import AppLogged from "../view/core/AppLogged";
import GroupController from "./GroupController";

class LoggedController extends React.Component {
  constructor() {
    super();
    this.group = getActiveGroup();
    if (isUnloggedLocation()) {
      navigateTo(createUrl("home"));
    }
  }

  render() {
    return (
      <AppLogged user={this.props.user} group={this.group}>
        <Switch>
          <Route
            path={WeSchoolRoutes.App.Spaces.SpaceGroup.initialRouteName}
            component={GroupController}
          />
          <Route render={() => <Redirect to={createUrl("home")} />} />
        </Switch>
      </AppLogged>
    );
  }
}

export default LoggedController;
