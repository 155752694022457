import React, { useCallback, useState } from "react";
import {
  Divider,
  EditorExtension,
  EditorExtensionType
} from "src/js/components/global/BaseTextEditor/BaseTextEditor.types";
import { TextEditorOptions } from "src/js/components/global/BaseTextEditor/EditorOptions.const";
import { BaseTextEditorMenu } from "src/js/components/global/BaseTextEditor/components";
import { useEditor } from "src/js/components/global/BaseTextEditor/hooks";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { generateTempResource } from "src/js/modules/fileFunction";
import { useTranslation } from "src/js/translation";
import { RESOURCE_TYPE } from "src/js/types";
import { TextResourceProps } from "../AddTextResource/AddTextResource.types";

const textMenuExtensions: Partial<EditorExtensionType>[] = [
  EditorExtension.TextFormatting,
  Divider,
  EditorExtension.Bold,
  EditorExtension.Italic,
  EditorExtension.Strike,
  Divider,
  EditorExtension.BulletList,
  EditorExtension.OrderedList,
  EditorExtension.BlockQuote,
  EditorExtension.Submenu
];

// This file is used in Chat and Exercise, no AIExtensions and no autosave
const useTextResourceState = ({
  prepareWSResource,
  onSuccess,
  setUploadingProgress,
  onClose,
  onSubmit,
  elementId
}: Omit<TextResourceProps, "onCancel">) => {
  const editor = useEditor({ content: "", options: { ...TextEditorOptions } });
  const { handleResourceTextUpload } = useResourceUpload(setUploadingProgress);
  const { translate } = useTranslation();
  const [title, setTitle] = useState(translate("text_new"));

  const {
    BoardsStore: { setUploadingElementIndexes, filterUploadingElementIndexes }
  } = useStores();

  const submitFunction = () => {
    const tempResource = generateTempResource({
      type: RESOURCE_TYPE.TEXT,
      name: title
    });
    const resourceUploadHandler = () =>
      handleResourceTextUpload(title, editor.getHTML(), elementId);

    if (onClose) onClose();

    if (prepareWSResource) {
      prepareWSResource(tempResource, resourceUploadHandler);
      return;
    }

    if (onSubmit) onSubmit(tempResource);
    setUploadingElementIndexes(elementId);
    resourceUploadHandler().then(response => {
      onSuccess(response);
      filterUploadingElementIndexes(elementId);
    });
  };

  const textEditorMenu = useCallback(
    () => (
      <BaseTextEditorMenu editor={editor} extensions={textMenuExtensions} />
    ),
    [editor]
  );

  return {
    editor,
    textEditorMenu,
    submitFunction,
    title,
    setTitle
  };
};

export default useTextResourceState;
