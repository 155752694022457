import { Stepper } from "src/js/components/Stepper";
import styled from "styled-components";
import { SchoolClaimFormStatus } from "../../spaces/space_creation/SchoolClaimForm/SchoolClaimForm.types";
import { ScrollableArea } from "../../spaces/space_creation/SchoolClaimForm/SchoolClaimSearch/SchoolClaimSearch.styles";

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  svg,
  span {
    margin-right: auto;
  }
`;

export const StyledStepper = styled(Stepper)`
  padding-bottom: 20px;
`;

export const SchoolClaimFormWrapper = styled.div<{
  formStatus: SchoolClaimFormStatus;
}>`
  height: 100%;
  min-height: ${({ formStatus }) =>
    formStatus === SchoolClaimFormStatus.Selection ? "500px" : null};
  ${ScrollableArea} {
    height: calc(100vh - 400px);
  }
`;
