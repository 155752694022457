import { FC } from "react";
import ReactDOM from "react-dom";
import { useModalProps } from "../../reactions/ReactionListModal/hooks";

const ResourceViewPortal: FC = ({ children }) => {
  const { domEl } = useModalProps({
    onClose: () => {},
    portalId: "resource-viewer-modal"
  });

  return ReactDOM.createPortal(children, domEl);
};

export default ResourceViewPortal;
