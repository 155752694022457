import { Lottie } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const LottieContainer = styled(Lottie)`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const TitleWrapper = styled.div`
  margin-top: 24px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor[600]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  > span {
    color: ${({ theme }) => theme.primaryColor[600]};
  }
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.primaryColor[400]};
`;

export const ButtonWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
    `}
`;

export const ButtonContainer = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      max-width: 192px;
      width: 100%;
    `}
`;

export const CheckCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
`;

export const ImageWrapper = styled(motion.div)`
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  height: 268px;
  width: 410px;
  > img {
    height: 268px;
    width: 416px;
  }
`;

export const AnimationsWrapper = styled(motion.div)`
  height: 268px;
  width: 410px;
`;
