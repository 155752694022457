/* eslint-disable react/forbid-prop-types */
import { Icon } from "@arcadia/design-system";
import moment from "moment";
import React, { useCallback } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { GroupPanelUsersList } from "src/js/components/GroupPanelUsersList";
import { Panel } from "src/js/components/Panel";
import { Tooltip, TooltipContent } from "src/js/components/Tooltip";
import { UserStatusBadge } from "src/js/components/UserStatusBadge";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { createStaticUrl } from "src/js/modules/routing";
import {
  Translate,
  useTranslation
} from "src/js/translation/TranslationProvider";
import { EventDomain, TemplatingEvent } from "src/js/types";
import { useTheme } from "styled-components";
import { DotsMenu } from "../DotsMenu";
import { ListItemProps } from "../DotsMenu/ListItem";
import * as S from "./GroupPanel.styles";
import { GroupPanelProps } from "./GroupPanel.types";
import { useGroupTemplateStatus } from "./hooks";

const GroupPanel = ({
  showTooltipFirstTemplate,
  onClickDotsMenu,
  groupObject,
  isSelectable = false,
  onSelectRow,
  toggleActivation,
  cloneGroup,
  showTeachers,
  ...props
}: GroupPanelProps) => {
  const { group, usersCount, teachers, isActive } = groupObject || {};
  const { createdAt, name, id } = group || {};
  const { colors } = useTheme();
  const { translate } = useTranslation();
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { isDemoSpace }
  } = useStores();

  const { isTemplate, setTemplate, unsetTemplate } =
    useGroupTemplateStatus(groupObject);

  const dateCreation = moment(createdAt).format("L");

  // create dots menu items
  const menuItems: ListItemProps[] = [
    {
      id: `clone-group-${id}`,
      label: translate(isActive ? "deactivate_group" : "activate_group"),
      icon: isActive ? "block" : "group",
      onClick: () => toggleActivation({ ...group, isActive })
    }
  ];

  if (isActive) {
    menuItems.push({
      id: "clone-group",
      label: translate("space_group_list_clone_group"),
      icon: "copyImport",
      onClick: () => cloneGroup(id)
    });
  }

  if (!isDemoSpace) {
    menuItems.push(
      isTemplate
        ? {
            id: "delete-template",
            label: translate("space_group_list_delete_template"),
            icon: "cancelOutline",
            onClick: () => {
              _trackEvent(
                EventDomain.Templating,
                TemplatingEvent.UnsetCustomTemplate
              );
              unsetTemplate(id);
            }
          }
        : {
            id: "create-template",
            label: translate("space_group_list_create_template"),
            icon: "bookmarkStar",
            onClick: () => {
              _trackEvent(
                EventDomain.Templating,
                TemplatingEvent.SetCustomTemplate
              );
              setTemplate(id);
            }
          }
    );
  }

  const goToGroup = useCallback(() => {
    if (!id || !isActive) return;
    const url = `#${createStaticUrl("wall", {
      group_id: id
    })}`;
    window.open(url, "_blank");
  }, [id, isActive]);

  const tooltipContent = (
    <TooltipContent withPortal={false}>
      {translate("template_group_list_setup_tooltip")}
    </TooltipContent>
  );

  const dotsMenuWithToast = (isMobile: boolean) => (
    <Tooltip
      withPortal={false}
      open={showTooltipFirstTemplate}
      tooltipContent={
        isMobile ? (
          <S.ShowOnXS>{tooltipContent}</S.ShowOnXS>
        ) : (
          <S.HideOnXS>{tooltipContent}</S.HideOnXS>
        )
      }
    >
      <div>
        <DotsMenu
          onClickCustom={onClickDotsMenu}
          id={`${id}DotsMenu${isLayoutModeMobile ? "Mobile" : "Desktop"}`}
          items={menuItems}
          alignRight
        />
      </div>
    </Tooltip>
  );

  const iconTemplate = isTemplate ? (
    <S.StyledIconTemplate
      initialViewbox
      width={20}
      height={20}
      icon="bookmarkStar"
    />
  ) : null;

  return (
    <Panel {...props} isSelectable={isSelectable} height={130}>
      <S.TriggerRowArea
        onClick={
          isSelectable
            ? () => {
                onSelectRow(id);
              }
            : null
        }
      >
        <S.InfoContainer>
          <S.TopMobile>
            <S.TopMobileTitle>
              <S.GroupNameWrap>
                <S.GroupName isActive={isActive} onClick={goToGroup}>
                  {name}
                </S.GroupName>
                {iconTemplate}
              </S.GroupNameWrap>
              <UserStatusBadge is_active={isActive} />
            </S.TopMobileTitle>
            <S.TopMobileActionContainer>
              {dotsMenuWithToast(true)}
            </S.TopMobileActionContainer>
          </S.TopMobile>

          <S.BottomMobile>
            <Row justify={{ xs: "space-between" }}>
              <Col xs={6}>
                <S.BottomMobileTitle>
                  <Translate text="teachers" />
                </S.BottomMobileTitle>
                <GroupPanelUsersList
                  users={teachers}
                  showAll={() => showTeachers(teachers, name)}
                />
              </Col>
              <Col xs={2}>
                <S.BottomMobileTitle>
                  <Translate text="users" />
                </S.BottomMobileTitle>
                <S.GroupNumberWrapper>
                  <Icon
                    icon="successCircle"
                    width="16px"
                    height="16px"
                    color={colors.success[500]}
                  />
                  <S.UsersCounter>{usersCount}</S.UsersCounter>
                </S.GroupNumberWrapper>
              </Col>
              <Col xs={4}>
                <Row justify={{ xs: "end" }}>
                  <div>
                    <S.BottomMobileTitle>
                      <Translate text="creation_date" />
                    </S.BottomMobileTitle>
                    <div>{dateCreation}</div>
                  </div>
                </Row>
              </Col>
            </Row>
          </S.BottomMobile>

          <S.TabletAndUp>
            <S.Column large>
              <S.GroupNameWrap>
                <S.GroupName isActive={isActive} onClick={goToGroup}>
                  {name}
                </S.GroupName>
                {iconTemplate}
              </S.GroupNameWrap>
            </S.Column>
            <S.Column>
              <GroupPanelUsersList
                users={teachers}
                showAll={() => showTeachers(teachers, name)}
              />
            </S.Column>
            <S.Column>
              <UserStatusBadge is_active={isActive} />
            </S.Column>
            <S.Column small>
              <S.GroupNumberWrapper>
                <Icon
                  icon="successCircle"
                  width="16px"
                  height="16px"
                  color={colors.success[500]}
                />

                <S.UsersCounter>{usersCount}</S.UsersCounter>
              </S.GroupNumberWrapper>
            </S.Column>
            <S.Column small>{dateCreation}</S.Column>
          </S.TabletAndUp>
        </S.InfoContainer>
      </S.TriggerRowArea>
      <S.HideOnXS>{dotsMenuWithToast(false)}</S.HideOnXS>
    </Panel>
  );
};

export default GroupPanel;
