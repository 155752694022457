import styled from "styled-components";

export const StepperProgressBar = styled.div`
  width: 112px;
  background-color: ${({ theme }) => theme.colors.grey[900]};
  border-radius: 7px;
  div {
    border-radius: 7px;
  }
`;

export const StepperWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
  width: 100%;
`;
