import React, { FC } from "react";
import { ContextualError } from "@arcadia/design-system";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "styled-components";
import { LoginTestId } from "@arcadia/toolkit";
import { NestDataObject } from "react-hook-form";
import { useTranslation, TranslationKeys } from "src/js/translation";
import { LoginForm } from "../../LoginPage.types";
import { motionProps } from "./LoginErrors.utils";

type Props = {
  loginError?: string;
  inputErrors: NestDataObject<LoginForm>;
};

const LoginErrors: FC<Props> = ({ loginError, inputErrors }) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  return (
    <AnimatePresence>
      {loginError && (
        <motion.div
          key="error-feedback-username"
          style={{ overflow: "hidden", width: "100%" }}
          {...motionProps}
        >
          <ContextualError
            theme={whiteTheme}
            text={loginError}
            data-testid={LoginTestId.EmailErrorContainer}
          />
        </motion.div>
      )}
      {inputErrors.username && (
        <motion.div
          key="error-feedback-username"
          style={{
            overflow: "hidden",
            width: "100%",
            marginTop: loginError ? "16px" : "0px"
          }}
          {...motionProps}
        >
          <ContextualError
            theme={whiteTheme}
            text={
              inputErrors.username?.message
                ? translate(inputErrors.username.message as TranslationKeys)
                : undefined
            }
            data-testid={LoginTestId.EmailErrorContainer}
          />
        </motion.div>
      )}
      {inputErrors.password && (
        <motion.div
          key="error-feedback-password"
          style={{ overflow: "hidden", width: "100%", marginTop: 16 }}
          {...motionProps}
        >
          <ContextualError
            theme={whiteTheme}
            text={
              inputErrors.password?.message
                ? translate(inputErrors.password.message as TranslationKeys)
                : undefined
            }
            data-testid={LoginTestId.EmailErrorContainer}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoginErrors;
