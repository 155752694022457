import { Icon, Text } from "@arcadia/design-system";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useStores } from "src/js/hooks";
import useLongPress from "src/js/hooks/useLongPress";
import { isTouchDevice } from "src/js/modules/deviceFunction";
import { showToastError } from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import { RealTimeDispatcher } from "src/legacy/modules/dispatcher";
import { useTheme } from "styled-components";
import { UPLOAD_STATUS } from "./UploadWidgetBody.const";

import * as S from "./UploadWidgetBody.styles";

const UploadWidgetBody = ({ uploadRequest }) => {
  const {
    fileName,
    eventTopic,
    onProgress,
    onTerminate,
    startUpload,
    cancelCallback,
    successCallback
  } = uploadRequest;
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.PENDING);
  const [percentage, setPercentage] = useState(0);
  const [remove, setRemove] = useState(false);

  const { UploadStore } = useStores();
  const { primaryColor, colors } = useTheme();

  const { uploadOnWatch } = UploadStore;

  const showRemove = () => {
    setRemove(true);
  };

  const hideRemove = () => {
    setRemove(false);
  };

  const longPressEvent = useLongPress(
    () => {
      setRemove(!remove);
    },
    {
      delay: 500
    }
  );

  const longPressEventsForMobile = isTouchDevice() ? longPressEvent : {};

  const progressHandler = e => {
    setPercentage(Math.ceil((e.loaded * 100) / e.total));
  };

  useEffect(() => {
    if (!uploadOnWatch || uploadOnWatch !== eventTopic) return () => {};
    RealTimeDispatcher.on(`${uploadOnWatch}_upload_scorm_error`, () => {
      showToastError({
        str: (
          <Translate
            text="uploaded_resource_error_toast_text"
            stringVariables={{
              fileName
            }}
          />
        )
      });
      UploadStore.setWatchOnResource(null);
    });
    return () => {
      RealTimeDispatcher.off(`${uploadOnWatch}_upload_scorm_error`);
      UploadStore.setWatchOnResource(null);
    };
  }, [uploadOnWatch]);

  const onEndUploadHandler = e => {
    if (e.currentTarget.status >= 200 && e.currentTarget.status <= 299) {
      successCallback();
      UploadStore.setWatchOnResource(eventTopic);
      setUploadStatus(UPLOAD_STATUS.SUCCESS);
    } else {
      setPercentage(0);
      setUploadStatus(UPLOAD_STATUS.ERROR);
    }
  };

  useEffect(() => {
    if (onProgress) onProgress(progressHandler);
    onTerminate(onEndUploadHandler);
    startUpload();
  }, []);

  let progressBarContent = null;

  switch (uploadStatus) {
    case UPLOAD_STATUS.SUCCESS:
      progressBarContent = (
        <S.SuccessIcon>
          <Icon icon="check2" width="10px" height="10px" />
        </S.SuccessIcon>
      );
      break;
    case UPLOAD_STATUS.ERROR:
      progressBarContent = (
        <S.ErrorIcon>
          <Icon icon="close" width="10px" height="10px" />
        </S.ErrorIcon>
      );
      break;
    case UPLOAD_STATUS.ALERT:
      progressBarContent = (
        <S.AlertIcon>
          <Icon icon="exclamation" width="10px" height="10px" />
        </S.AlertIcon>
      );
      break;
    default:
      progressBarContent = !remove ? (
        <S.PercentageContainer>
          <S.PercentageText>{percentage}</S.PercentageText>%
        </S.PercentageContainer>
      ) : (
        <S.CancelIcon
          onClick={() => {
            cancelCallback();
            setPercentage(0);
            setUploadStatus(UPLOAD_STATUS.ALERT);
          }}
        >
          <Icon icon="close" width="16px" height="16px" />
        </S.CancelIcon>
      );
      break;
  }

  const loader = (
    <CircularProgressbarWithChildren
      styles={buildStyles({
        textColor: colors.grey[50],
        pathColor: primaryColor[500]
      })}
      value={percentage}
    >
      {progressBarContent}
    </CircularProgressbarWithChildren>
  );
  return (
    <S.ModalBody
      onMouseEnter={!isTouchDevice() && showRemove}
      onMouseLeave={!isTouchDevice() && hideRemove}
    >
      <S.LoaderContainer>{loader}</S.LoaderContainer>
      <S.ModalBodyTextContainer {...longPressEventsForMobile}>
        <Text type="formField">{fileName}</Text>
      </S.ModalBodyTextContainer>
    </S.ModalBody>
  );
};

export default observer(UploadWidgetBody);
