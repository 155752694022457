import styled from "styled-components";
import { motion } from "framer-motion";
import colors from "@ds_themes/colors";

import { SContentWrapperProps } from "./types";

export const Container = styled.div`
  position: relative;
`;

export const ContentWrapper = styled(motion.div)<SContentWrapperProps>`
  position: absolute;
  border-radius: 24px;
  border: 1px solid ${colors.violet[700]};
  background-color: ${colors.grey[1000]};
  padding: 16px;
  margin-top: 8px;
  z-index: ${({ theme }) => theme.zIndex.higher};

  @media screen and (min-width: 768px) {
    max-width: 400px;
  }

  max-width: 80%;
  max-height: 200px;
  overflow-y: auto;
`;

export const ChildrenWrapper = styled.div`
  display: inline-block;
`;
