import React from "react";
import * as S from "./CustomTooltip.styles";

export type CustomTooltipProps = {
  title: string;
  subtitle?: string;
  description: string;
  highlightedValue?: string;
  color?: string;
  maxWidth?: string;
};

const CustomTooltip = ({
  title,
  subtitle,
  description,
  highlightedValue,
  color,
  maxWidth
}: CustomTooltipProps) => {
  return (
    <S.Wrapper maxWidth={maxWidth}>
      <S.Header>{title}</S.Header>
      <S.Body>
        {subtitle ? <S.Subtitle>{subtitle}</S.Subtitle> : null}
        <S.MainContent>
          {color ? <S.ColorBox color={color} data-testid="color-box" /> : null}
          {description}
          {highlightedValue ? (
            <S.HighlightedValue color={color}>
              {highlightedValue}
            </S.HighlightedValue>
          ) : null}
        </S.MainContent>
      </S.Body>
    </S.Wrapper>
  );
};

export default CustomTooltip;
