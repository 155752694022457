import { Box, IconName } from "@arcadia/design-system";
import React, { forwardRef } from "react";
import { Skeleton } from "src/js/components/Skeleton";
import { useStores } from "src/js/hooks";
import * as S from "./TemplateIntroButton.styles";

type TemplateIntroButtonProps = {
  loading?: boolean;
  icon: IconName;
  title: string;
  description: string;
  disabledStyle?: boolean;
  onClick?: () => void;
};

const TemplateIntroButton = forwardRef(
  (
    {
      loading,
      disabledStyle,
      onClick,
      icon,
      title,
      description,
      ...props
    }: TemplateIntroButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const {
      UIStore: { isLayoutModeMobile }
    } = useStores();

    return (
      <Skeleton loading={loading} borderRadius={8}>
        <S.ButtonWrapper
          {...props}
          ref={ref}
          onClick={onClick}
          disabledStyle={disabledStyle}
        >
          <S.Column>
            <S.TitleRow>
              <S.StyledIcon icon={icon} initialViewbox width={24} height={24} />
              <Box display="flex" alignItems="center">
                <S.StyledText type="formField">{title}</S.StyledText>
              </Box>
            </S.TitleRow>
            {!isLayoutModeMobile ? (
              <S.CardDescription type="body">{description}</S.CardDescription>
            ) : null}
          </S.Column>
          <S.StyledIcon icon="plusThin" width={16} height={16} />
        </S.ButtonWrapper>
      </Skeleton>
    );
  }
);

TemplateIntroButton.displayName = "TemplateIntroButton";

export default TemplateIntroButton;
