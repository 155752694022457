import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import {
  SpaceDurationCard,
  spaceDuration
} from "src/js/components/SpaceDurationCard";
import { SidebarGroupList } from "src/js/components/layout/Sidebar/SidebarGroupList";
import { useAppRoutingContext, useStores } from "src/js/hooks";
import { openExternalLink } from "src/js/modules/commonFunction";
import { createUrl } from "src/js/modules/routing";
import { TEMPORARY_SPACE_DEADLINE } from "src/js/settings/settingsSpaces";
import { useTranslation } from "src/js/translation";
import {
  ChatEvent,
  DashboardEvent,
  EventDomain,
  GroupEvent,
  SpaceGroupsParams
} from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { Box, Icon } from "@arcadia/design-system";
import { UsetifulId } from "src/js/types/consts/UsetifulId";
import { GroupCreationModal } from "../../GroupCreationModal";
import * as S from "./Sidebar.styles";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarThreadList } from "./SidebarThreadList";
import { SidebarThreadsBlocked } from "./SidebarThreadsBlocked";
import { SidebarHeaderAction } from "./SidebarHeader/SidebarHeader.types";
import {
  SidebarHeaderTitle,
  SidebarHeaderIcon
} from "./SidebarHeader/SidebarHeader.styles";
import { GroupJoinWithCodeModal } from "../../GroupJoinWithCodeModal";

const Sidebar = () => {
  const { sizes } = useTheme();
  const {
    UIStore: { isSideBarOpen },
    DemoSpaceStore: { isDemoSpaceTopBannerVisible },
    SpaceStore: {
      userSpacesListLoading,
      activeSpaceSlug,
      setActiveSpaceBySlug,
      activeSpace,
      canCreateGroups,
      isClaimable,
      canCreateChats,
      isDemoSpace,
      isAdmin
    },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const { context } = useAppRoutingContext();
  const { space_slug } = useParams<SpaceGroupsParams>();
  const { translate } = useTranslation();

  useEffect(() => {
    if (space_slug) setActiveSpaceBySlug(space_slug);
  }, [space_slug]);

  const navigateToNewChat = () => {
    if (context !== "chat") {
      _trackEvent(EventDomain.Chat, ChatEvent.ChatStartThreadListNewClick);
    }
    navigateTo(
      createUrl("chat_new", {
        space_slug: activeSpaceSlug
      })
    );
  };

  const isTemporarySpace = activeSpace?.isTemporary;
  const { duration, durationType } = spaceDuration(
    new Date(TEMPORARY_SPACE_DEADLINE)
  );

  const joinGroupOption: SidebarHeaderAction = {
    label: "tooltip_menu_option_join_group_with_code",
    onClick: () => {
      openModal(() => <GroupJoinWithCodeModal closeModal={closeModal} />);
    }
  };

  const options: SidebarHeaderAction[] = canCreateGroups
    ? [
        {
          label: "tooltip_menu_option_create_group",
          onClick: () => {
            _trackEvent(EventDomain.Group, GroupEvent.NavBarGroupCreation);
            openModal(() => <GroupCreationModal />);
          }
        },
        joinGroupOption
      ]
    : [joinGroupOption];

  const navigateToDashboard = () => {
    navigateTo(
      createUrl("dashboard_analytics", {
        space_id: activeSpace?.space?.id
      })
    );
    _trackEvent(
      EventDomain.SpaceDashboardUsage,
      DashboardEvent.DashboardUserAccessSecNav
    );
  };

  const sidebarEl = (
    <>
      <S.SidebarContent>
        <Box id={UsetifulId.Sidebar}>
          {isAdmin && (
            <S.DashboardLinkWrapper
              id={UsetifulId.SidebarDashboardLink}
              onClick={navigateToDashboard}
            >
              <SidebarHeaderIcon>
                <Icon icon="compass" height={24} width={24} />
              </SidebarHeaderIcon>
              <SidebarHeaderTitle>
                {translate("sidebar_header_dashboard_title")}
              </SidebarHeaderTitle>
            </S.DashboardLinkWrapper>
          )}
          <SidebarHeader
            title="sidebar_header_title_groups"
            id={UsetifulId.SidebarGroupList}
            icon="gridViewFilled"
            actions={options}
          />
          <SidebarGroupList />

          <SidebarHeader
            title="sidebar_header_title_threads"
            icon="chatFilled"
            id={UsetifulId.SidebarChatList}
            onDirectClickAction={canCreateChats ? navigateToNewChat : undefined}
            actions={
              canCreateChats
                ? [
                    {
                      label: "sidebar_header_tooltip_threads",
                      onClick: navigateToNewChat
                    }
                  ]
                : undefined
            }
          />
          {canCreateChats ? <SidebarThreadList /> : null}
          {!canCreateChats && isDemoSpace ? (
            <SidebarThreadsBlocked
              isTemporarySpace={isTemporarySpace}
              isClaimable={isClaimable}
              isDemoSpace={isDemoSpace}
            />
          ) : null}
        </Box>
      </S.SidebarContent>
      <S.SidebarFooter>
        {isTemporarySpace ? (
          <SpaceDurationCard
            variant="secondary"
            title={translate({ text: "space_duration_card_title" })}
            link={translate({ text: "space_duration_card_link" })}
            onClickLink={() => {
              openExternalLink(translate("temporary_space_link_url"));
            }}
            duration={duration}
            durationType={durationType}
          />
        ) : null}
      </S.SidebarFooter>
    </>
  );

  return (
    <S.SidebarWrapper
      isDemoSpaceTopBannerVisible={isDemoSpaceTopBannerVisible}
      animate={isSideBarOpen ? "open" : "closed"}
      initial={isSideBarOpen ? "open" : "closed"}
      variants={{
        open: {
          left: sizes.defaultToolbarWidth
        },
        closed: {
          left: `-${sizes.mainSidebarWidth.xl}`
        }
      }}
      transition={{
        bounce: false,
        duration: 0.3
      }}
    >
      {userSpacesListLoading ? null : sidebarEl}
    </S.SidebarWrapper>
  );
};

export default observer(Sidebar);
