import React from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./ToolbarMenuItem.styles";
import { ItemProps } from "./types";

const ToolbarMenuItem = ({
  id,
  theme,
  isSelected,
  icon,
  onClick,
  isDisabled,
  hasEvent
}: ItemProps) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ItemContainer
        isSelected={isSelected}
        disabled={isDisabled}
        id={id}
        type="button"
        onClick={onClick}
        theme={theme}
      >
        <S.IconContainer>
          {icon}
          {hasEvent && <S.EventDot />}
        </S.IconContainer>
      </S.ItemContainer>
    </ThemeProvider>
  );
};

export default ToolbarMenuItem;
