import { Icon, Text } from "@arcadia/design-system";
import { format } from "date-fns";
import React from "react";
import TimeAgo from "react-timeago";
import { timeFormatter } from "src/js/modules/commonFunction";
import { getCurrentLanguage } from "src/js/modules/localization";
import { getNotificationTypeFromArea } from "src/js/pages/App/Spaces/NotificationsPage/NotificationPage.utils";
import { getDateFnsLocale, useTranslation } from "src/js/translation";
import {
  AreaIcon,
  NotificationAreaLabel,
  NotificationModel
} from "src/js/types/models/Notification";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import { NotificationLoading } from "./NotificationLoading";

import * as S from "./Notification.styles";

const Avatar = ({ notification }: { notification: NotificationModel }) => {
  const { whiteTheme } = useTheme();
  const { avatar, name, surname } = notification.sender;
  return (
    <S.AvatarContainer>
      <S.AvatarImage
        theme={whiteTheme}
        url={avatar?.small}
        size="m"
        alt={`${name} ${surname}`}
        rounded
      />
      <S.NotificationType area={getNotificationTypeFromArea(notification.area)}>
        <Icon
          icon={
            AreaIcon[notification.area] || AreaIcon[NotificationAreaLabel.Other]
          }
          width={8}
          height={8}
          initialViewbox
        />
      </S.NotificationType>
    </S.AvatarContainer>
  );
};

const NotificationContent = ({
  notification
}: {
  notification: NotificationModel;
}) => {
  const { sender, text } = notification;
  const senderName = `${sender.name} ${sender.surname}`;
  return (
    <>
      <S.LargeText type="captionAlert">{senderName}</S.LargeText>
      <S.SmallText>{text.split(senderName)}</S.SmallText>
    </>
  );
};

const NotificationFooter = ({
  notification,
  onMarkRead,
  onMarkUnread
}: {
  notification?: NotificationModel;
  onMarkRead?: (notificationId: string) => Promise<void>;
  onMarkUnread?: (notificationId: string) => Promise<void>;
}) => {
  const { translate } = useTranslation();
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const toggleNotificationStatus = async (
    e: React.MouseEvent<HTMLButtonElement>,
    isRead: boolean
  ) => {
    e.stopPropagation();
    if (isRequestInProgress) return;

    const action = isRead ? onMarkUnread : onMarkRead;
    if (!action) return;

    setIsRequestInProgress(true);
    try {
      await action(notification.id);
    } catch (err) {
      console.error("Error marking notification status", err);
    } finally {
      setIsRequestInProgress(false);
    }
  };

  return (
    <S.BottomRow>
      <S.MarkReadButton
        type="button"
        disabled={isRequestInProgress}
        onClick={e => toggleNotificationStatus(e, notification.isRead)}
      >
        <Text type="captionAlert">
          {notification.isRead
            ? translate("notification_set_unread_button_text")
            : translate("notification_set_read_button_text")}
        </Text>
      </S.MarkReadButton>

      <S.TimeRow>
        <Icon icon="stopwatch" width={12} height={12} initialViewbox />
        <TimeAgo
          title={format(
            notification.updatedAt * 1000,
            "EEEE d MMM yyyy HH:mm:ss",
            { locale: getDateFnsLocale(getCurrentLanguage()) }
          )}
          date={notification.updatedAt * 1000}
          formatter={(value, unit, suffix) =>
            timeFormatter(value, unit, suffix, translate)
          }
        />
      </S.TimeRow>
    </S.BottomRow>
  );
};

const Notification = ({
  notification,
  loading = false,
  onMarkRead,
  onMarkUnread,
  onClick
}: {
  loading?: boolean;
  notification?: NotificationModel;
  onMarkRead?: (notificationId: string) => Promise<void>;
  onMarkUnread?: (notificationId: string) => Promise<void>;
  onClick?: () => void;
}) => {
  if (!notification && !loading) return null;

  const handleClick = () => {
    if (loading) return;
    if (!notification.isRead && onMarkRead) onMarkRead(notification.id);
    if (onClick) onClick();
    navigateTo(notification.redirectUrl.replace("#", ""));
  };

  return loading ? (
    <NotificationLoading />
  ) : (
    <S.NotificationWrapper read={notification.isRead} onClick={handleClick}>
      <S.NotificationSection>
        <Avatar notification={notification} />
        <S.NotificationTextSection>
          <S.NotificationTextWrapper>
            <NotificationContent notification={notification} />
          </S.NotificationTextWrapper>
          <NotificationFooter
            notification={notification}
            onMarkRead={onMarkRead}
            onMarkUnread={onMarkUnread}
          />
        </S.NotificationTextSection>
      </S.NotificationSection>
      {!loading && !notification.isRead && <S.Dot />}
    </S.NotificationWrapper>
  );
};

export default Notification;
