import styled, { CSSProperties, css } from "styled-components";
import { colors, shadow } from "@ds_themes/index";
import { Text } from "@ds_universal/Text";

import {
  SCardLessonProps,
  SCardLessonTopWrapper,
  SCardLessonWrapperTextProps
} from "./types";

export const Cover = styled.div`
  height: 137px;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(black, black);
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 20px 20px 0 0;
  img {
    height: auto; /*137px */
    width: 100%;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    padding-left: 0 !important;
  }
`;

export const WrapperText = styled.div<SCardLessonWrapperTextProps>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  background-color: ${({ theme }) => theme.card.cardlesson.background};
  color: ${({ theme }) => theme.card.cardlesson.text};
  svg {
    color: ${({ theme }) => theme.card.cardlesson.icon};
  }
  & div > span {
    padding-left: 4px;
  }
  > span {
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${({ maxWidth }) => `calc(${maxWidth} - 32px)`};
    display: block;
  }
`;

export const Cart = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.card.cardlesson.cart.background};
  svg {
    color: ${({ theme }) => theme.card.cardlesson.cart.icon};
    padding-right: 0px;
  }
`;

export const Option = styled.button`
  border: none;
  z-index: ${({ theme }) => theme.zIndex.regular};
  width: 53px;
  height: 32px;
  background-color: ${({ theme }) => theme.card.cardlesson.background};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0px;
  cursor: pointer;
  &:focus-within {
    outline: ${({ theme }) => theme.card.cardlesson.focus.border};
  }
  svg {
    opacity: 1;
    color: ${({ theme }) => theme.card.cardlesson.text};
  }
`;

export const SpaceTitle = styled(Text)`
  margin-bottom: 4px;
  color: ${colors.blueGrey[400]};
`;

export const SpaceBadge = styled.div`
  box-shadow: ${shadow[100]};
  border-radius: 50%;
  border: 1px solid ${colors.grey[1000]};

  img {
    object-fit: cover;
  }
`;

export const TopWrapper = styled.div<SCardLessonTopWrapper>`
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: ${({ hasBadge }): CSSProperties["justifyContent"] =>
    hasBadge ? "space-between" : "flex-end"};
  padding: 0 16px;
  z-index: ${({ theme }) => theme.zIndex.regular};
`;

export const CardLesson = styled.div<SCardLessonProps>`
  width: ${({ maxWidth }) => maxWidth};
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  height: ${({ naked }) => naked && "188px"};

  border: 0px;
  padding: 0px;
  cursor: pointer;

  ${({ isLoading }) =>
    isLoading &&
    css`
      height: auto;
      opacity: 0.6;
      ${ContainerIcon}, ${Option} {
        visibility: hidden;
      }
    `}

  transition: box-shadow 0.3s ease-in-out;
  img {
    transition: transform 0.3s ease-in-out;
    transform: scale3d(1, 1, 1);
    backface-visibility: hidden;
  }
  &:focus {
    outline: ${({ theme }) => theme.card.cardlesson.focus.border};
  }
  &:hover {
    box-shadow: ${shadow[500]};

    img {
      transform: scale3d(1.03, 1.03, 1);
    }
  }
`;
