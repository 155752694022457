import { Box, Text as DSText } from "@arcadia/design-system";
import styled, { createGlobalStyle } from "styled-components";
import { Wrapper } from "../TopHeaderMobileWrapper/TopHeaderMobileWrapper.styles";

type ContainerProps = {
  isPurple: boolean;
};

// TODO: Refactor mobile header
export const GlobalStyleHideGenericHeader = createGlobalStyle`
  ${Wrapper} {
    display: none;
  }
`;

export const HeaderMobileWhiteContainer = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 16px;
  min-height: 70px;
  display: flex;
  background-color: ${({ theme, isPurple }) =>
    isPurple ? theme.colors.violet[501] : theme.colors.grey[1000]};
  z-index: ${({ theme }) => theme.zIndex.header + 1};
  border-bottom: ${({ theme, isPurple }) =>
    isPurple ? undefined : `1px solid ${theme.colors.grey[800]}`};

  @media screen and (min-width: 767px) {
    display: none;
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 70px;
`;

export const BoxButtons = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  position: absolute;
  padding-inline: 100px;
  width: 100%;
  text-align: center;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const HeaderTitle = styled(DSText)`
  font-weight: 700;
`;

export const EditAndSave = styled(DSText)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const NameWrapper = styled.div`
  margin-left: 8px;
  & > span {
    color: ${({ theme }) => theme.colors.grey[1000]};
  }
`;

export const BoardActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
