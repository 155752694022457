import { Box, colors, Icon, Text } from "@arcadia/design-system";
import Tooltip from "rc-tooltip";
import React, { useRef } from "react";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { VisibilityToggleProps } from "./types";
import * as S from "./VisiblityToggle.styles";

const VisibilityToggle = ({ visible, onChange }: VisibilityToggleProps) => {
  const TooltipWrapperNode = useRef<HTMLDivElement>();
  const { translate } = useTranslation();

  return (
    <Box
      width="20px"
      height="20px"
      display="flex"
      cursor="pointer"
      onClick={() => onChange(!visible)}
    >
      <S.TooltipWrapper ref={TooltipWrapperNode}>
        <Tooltip
          placement="top"
          overlay={
            <Text type="captionAlert">
              {translate({
                text: visible
                  ? "visibility_toggle_hide_info_tooltip_text"
                  : "visibility_toggle_show_info_tooltip_text"
              })}
            </Text>
          }
          getTooltipContainer={() => TooltipWrapperNode.current}
        >
          <Icon
            icon={visible ? "eyeOpen" : "eyeClosed"}
            width={20}
            height={20}
            color={colors.violet[501]}
          />
        </Tooltip>
      </S.TooltipWrapper>
    </Box>
  );
};

export default VisibilityToggle;
