import React, { useRef, useState } from "react";
import { ActionButton } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { ResourceIcon } from "src/js/components/ResourceIcon";
import { RESOURCE_ICON_SIZE } from "src/js/components/ResourceIcon/ResourceIcon.const";
import { useOnClickOutSide } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";
import {
  __EXTERNAL_RESOURCES__,
  __INTERNAL_RESOURCES__
} from "./CalendarAttachmentPicker.const";
import * as S from "./CalendarAttachmentPicker.styles";

const CalendarAttachmentPickerBody = ({
  id = "select-dropdown-body",
  externalResources = __EXTERNAL_RESOURCES__,
  internalResources = __INTERNAL_RESOURCES__,
  actionFunction = () => {}
}) => {
  const externalList = externalResources.map(option => {
    return (
      <S.CalendarAttachmentPickerElement
        key={option?.type}
        aria-label="externalResourceOption"
        role="option"
        data-testid="externalResourceOption"
        onClick={() => actionFunction(option?.type)}
      >
        <ResourceIcon
          type={option?.type}
          size={RESOURCE_ICON_SIZE.CALENDAR_ATTACHMENT}
        />
        <S.OptionName>
          <Translate text={option?.name} />
        </S.OptionName>
      </S.CalendarAttachmentPickerElement>
    );
  });
  const internalList = internalResources.map(option => {
    return (
      <S.CalendarAttachmentPickerElement
        key={option?.type}
        aria-label="internalResourceOption"
        role="option"
        data-testid="internalResourceOption"
        onClick={() => actionFunction(option?.type)}
      >
        <ResourceIcon
          type={option?.type}
          size={RESOURCE_ICON_SIZE.CALENDAR_ATTACHMENT}
        />
        <S.InternalResourceLabelContainer>
          <Translate text={option?.name} />
          <S.InternalResourceLabel>
            <Translate text="calendar_attachment_internal_resource_subtitle" />
          </S.InternalResourceLabel>
        </S.InternalResourceLabelContainer>
      </S.CalendarAttachmentPickerElement>
    );
  });
  return (
    <S.CalendarAttachmentPickerBody
      id={id}
      aria-label="comboboxBody"
      role="listbox"
      data-testid="listbox"
      tabindex="-1"
    >
      {internalResources && internalResources.length > 0 && (
        <>
          {externalResources && externalResources.length > 0 && (
            <S.DropdownBodyLabel>
              <Translate text="calendar_attachment_internal_resources" />
            </S.DropdownBodyLabel>
          )}
          {internalList}
        </>
      )}
      {externalResources && externalResources.length > 0 && (
        <>
          {internalResources && internalResources.length > 0 && (
            <S.DropdownBodyLabel>
              <Translate text="calendar_attachment_external_resources" />
            </S.DropdownBodyLabel>
          )}
          {externalList}
        </>
      )}
    </S.CalendarAttachmentPickerBody>
  );
};

const CalendarAttachmentPicker = ({
  id = "calendar-attachment-picker",
  externalResources = __EXTERNAL_RESOURCES__,
  internalResources = __INTERNAL_RESOURCES__,
  disabled = false,
  selectFunction = () => {}
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { whiteTheme } = useTheme();
  const node = useRef();
  useOnClickOutSide(node, () => setDropdownOpen(false));

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const actionFunction = value => {
    selectFunction(value);
    setDropdownOpen(false);
  };

  return (
    <S.CalendarAttachmentPickerContainer ref={node}>
      <ActionButton
        theme={whiteTheme}
        variant="secondary"
        icon="clip"
        onClick={toggleDropdown}
        disabled={disabled}
        aria-label="combobox"
        type="button"
      />
      {dropdownOpen && (
        <CalendarAttachmentPickerBody
          id={id}
          externalResources={externalResources}
          internalResources={internalResources}
          actionFunction={actionFunction}
        />
      )}
    </S.CalendarAttachmentPickerContainer>
  );
};

export default CalendarAttachmentPicker;
