import React from "react";
import { Icon } from "@arcadia/design-system";
import { RESOURCE_ICON_SIZE, RESOURCE_ICON_TYPE } from "./ResourceIcon.const";
import * as S from "./ResourceIcon.styles";

const ResourceIcon = ({
  type = RESOURCE_ICON_TYPE.FILE,
  size = RESOURCE_ICON_SIZE.CALENDAR_ATTACHMENT
}) => {
  let attachmentIcon = "";

  switch (type) {
    case RESOURCE_ICON_TYPE.FILE:
      attachmentIcon = (
        <Icon icon="desktop" height={size.icon} width={size.icon} />
      );
      break;
    case RESOURCE_ICON_TYPE.BOARD:
      attachmentIcon = (
        <Icon icon="book" height={size.icon} width={size.icon} />
      );
      break;
    case RESOURCE_ICON_TYPE.LINK:
      attachmentIcon = (
        <Icon icon="link" height={size.icon} width={size.icon} />
      );
      break;
    case RESOURCE_ICON_TYPE.EXERCISE:
      attachmentIcon = (
        <Icon icon="weight" height={size.icon} width={size.icon} />
      );
      break;
    case RESOURCE_ICON_TYPE.GOOGLE_DRIVE:
    case RESOURCE_ICON_TYPE.GDRIVE:
      attachmentIcon = (
        <Icon
          icon="picker-googledrive"
          height={size.icon}
          width={size.icon}
          viewbox="0 0 64 58"
        />
      );
      break;
    case RESOURCE_ICON_TYPE.GOOGLE_MAPS:
      attachmentIcon = (
        <Icon
          icon="picker-googlemaps"
          height={size.icon}
          width={size.icon}
          viewbox="0 0 45 64"
        />
      );
      break;
    case RESOURCE_ICON_TYPE.DROPBOX:
      attachmentIcon = (
        <Icon
          icon="picker-dropbox"
          height={size.icon}
          width={size.icon}
          viewbox="0 0 64 55"
        />
      );
      break;
    default:
      attachmentIcon = "";
  }

  return (
    <S.ColoredBackground type={type} size={size}>
      {attachmentIcon}
    </S.ColoredBackground>
  );
};

export default ResourceIcon;
