import React, { useEffect, useState } from "react";
import {
  useDebounce,
  useInfiniteScrollFetcher,
  useStores,
  useUnsavedChangesWarning
} from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { BottomModalSheet, Box, Heading, Icon } from "@arcadia/design-system";
import { BadgeRelatedUserType } from "src/js/types";
import { fetchBadgeRelatedUsers } from "src/js/repository/badgesRepository";
import { observer } from "mobx-react";
import * as S from "./styles";
import {
  BadgeRelatedUsersFetchParams,
  EmitBadgeToStudentsStep,
  EmitBadgeToStudentsStepType
} from "./types";
import SelectStudents from "./components/SelectStudents";
import ReviewSelectedStudents from "./components/ReviewSelectedStudents";
import MintingNFTs from "./components/MintingNFTs";

const EmitBadgeToStudents = () => {
  const [filterString, setFilterString] = useState("");
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [filterGroupString, setFilterGroupString] = useState("");
  const [step, setStep] = useState<EmitBadgeToStudentsStepType>(
    EmitBadgeToStudentsStep.SelectStudents
  );
  const {
    UIStore: { isSideBarOpen, toggleSidebar, isLayoutModeMobile },
    SpaceBadgesStore: {
      reset,
      selectedBadgeRelatedGroups,
      setEmptyStateVisible,
      badgeToEdit,
      isEmitStudentsDrawerOpen,
      setEmitStudentsDrawerOpen,
      transactionInProgress
    },
    SpaceBadgesListStore: { fetchFilteredBadges },
    SpaceStore: { activeSpaceId }
  } = useStores();
  const { translate } = useTranslation();
  const [preventCloseOfTab, enableCloseOfTab] = useUnsavedChangesWarning(
    "post_edit_leave_confirmation_message"
  );
  const filterValueDebounced = useDebounce(filterString, 300);
  const {
    data: badgeRelatedUsers = [],
    fetch,
    reset: resetResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    BadgeRelatedUserType[],
    BadgeRelatedUsersFetchParams
  >(
    async ({
      limit,
      page: _page,
      badgeId,
      textSearch,
      groupIds,
      showUnassociated
    }) => {
      const offset = _page * limit;
      setEmptyStateVisible(false);
      const data = await fetchBadgeRelatedUsers({
        badgeId,
        textSearch,
        showUnassociated,
        limit,
        groupIds,
        offset
      });

      if (!data.results.length) {
        setEmptyStateVisible(true);
      }

      setHasMoreResults(data.hasNext);

      return data.results;
    },
    { lazy: true, limit: 10 }
  );
  const { id, image, name } = badgeToEdit || {};

  useEffect(() => {
    setStep(EmitBadgeToStudentsStep.SelectStudents);
    setFilterString("");
    reset();
  }, [badgeToEdit]);

  useEffect(() => {
    if (id && isEmitStudentsDrawerOpen) {
      fetch(0, {
        badgeId: id,
        textSearch: filterValueDebounced || undefined,
        showUnassociated: true,
        groupIds: selectedBadgeRelatedGroups
      });
    }
    return () => resetResults();
  }, [
    id,
    isEmitStudentsDrawerOpen,
    filterValueDebounced,
    selectedBadgeRelatedGroups.length
  ]);

  useEffect(() => {
    if (isSideBarOpen && isEmitStudentsDrawerOpen) {
      toggleSidebar();
    }
  }, [isEmitStudentsDrawerOpen]);

  useEffect(() => {
    if (transactionInProgress) {
      preventCloseOfTab();
    } else {
      enableCloseOfTab();
    }
  }, [transactionInProgress]);

  const handleClose = (shouldRefetchBadges?: boolean) => {
    setEmitStudentsDrawerOpen(!isEmitStudentsDrawerOpen);
    setStep(EmitBadgeToStudentsStep.SelectStudents);
    setFilterString("");
    reset();

    if (shouldRefetchBadges) {
      fetchFilteredBadges(activeSpaceId);
    }
  };

  const handleStepToRender = () => {
    if (step === EmitBadgeToStudentsStep.SelectStudents) {
      return (
        <SelectStudents
          badgeRelatedUsers={badgeRelatedUsers}
          fetchNextPage={fetchNextPage}
          filterString={filterString}
          filterValueDebounced={filterValueDebounced}
          hasMoreResults={hasMoreResults}
          setFilterString={setFilterString}
          setStep={setStep}
          filterGroupString={filterGroupString}
          setFilterGroupString={setFilterGroupString}
        />
      );
    }
    if (step === EmitBadgeToStudentsStep.Loading) {
      return <MintingNFTs />;
    }
    return <ReviewSelectedStudents handleClose={handleClose} />;
  };

  if (!badgeToEdit) {
    return null;
  }

  return isLayoutModeMobile ? (
    <BottomModalSheet
      isOpen={isEmitStudentsDrawerOpen}
      title={translate("dashboard_badge_dropdown_emit_badge")}
      closeLabel={
        step === EmitBadgeToStudentsStep.SelectStudents
          ? translate("Annulla")
          : translate("close")
      }
      onClose={handleClose}
    >
      <Box id="drawer-body-wrapper" height="100%" paddingTop={24}>
        {handleStepToRender()}
      </Box>
    </BottomModalSheet>
  ) : (
    <S.StyledDrawer
      onDismiss={() => setEmitStudentsDrawerOpen(!isEmitStudentsDrawerOpen)}
      open={isEmitStudentsDrawerOpen}
      disableOverlay
      size="540px"
      isInsideMainLayout
      showCloseButton={false}
    >
      <Box id="drawer-body-wrapper" height="100%">
        <S.DrawerHeaderContainer>
          <Box display="flex" alignItems="center">
            <S.BadgeImage src={image} alt="badge-alternative" />
            <Heading level="5">
              {translate("emit_badge_students_sidebar_header", {
                badgeName: name
              })}
            </Heading>
          </Box>
          <S.HeaderIconContainer onClick={() => handleClose(true)}>
            <Icon icon="close" />
          </S.HeaderIconContainer>
        </S.DrawerHeaderContainer>
        {handleStepToRender()}
      </Box>
    </S.StyledDrawer>
  );
};

export default observer(EmitBadgeToStudents);
