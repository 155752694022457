import React, { useEffect } from "react";
import { ContextualAlert, Input } from "@arcadia/design-system";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { useForm, useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { FormInputs } from "src/js/types";
import {
  EmailEditSettingsFields,
  UserAccountChangeEmailModalProps
} from "../types";
import { ModalBody, ModalContent } from "../Modal";

const UserAccountChangeEmailModal: React.FC<
  UserAccountChangeEmailModalProps
> = ({ settings, confirm }) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile },
    UserStore
  } = useStores();
  const { translate } = useTranslation();
  const { register, handleSubmit, setValue, errors } =
    useForm<FormInputs<EmailEditSettingsFields>>();

  useEffect(() => {
    setValue([{ email: UserStore.activeUser.email }]);
  }, []);

  const handleSubmission = handleSubmit(({ email, confirm_password }) => {
    return confirm(email, confirm_password);
  });

  return (
    <ModalContent title={translate({ text: "change_email_title" })}>
      <ModalBody onSubmit={handleSubmission} formId="change-email-form">
        <div className="settings-edit-modal__container margin-top-20">
          <Input
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="email"
            label={translate({ text: "new_email" })}
            ref={register(settings.email)}
            error={errors.email}
            rounded
          />
        </div>

        <div className="settings-edit-modal__container margin-top-20">
          <span>{translate({ text: "password_check" })}</span>
        </div>

        <div className="settings-edit-modal__container margin-top-10">
          <Input
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            name="confirm_password"
            label={translate({ text: "confirm_password" })}
            isPassword
            ref={register(settings.confirm_password)}
            error={errors.confirm_password}
            rounded
          />
        </div>

        <div className="settings-edit-modal__container margin-top-20">
          <ContextualAlert
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            text={translate({ text: "change_email_alert_message" })}
          />
        </div>
      </ModalBody>
    </ModalContent>
  );
};

export default UserAccountChangeEmailModal;
