import styled from "styled-components";

export const StyledContentWrapper = styled.div`
  padding: 52px 32px 56px 32px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;
