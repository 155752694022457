import { mobileMixin } from "src/js/theme/breakpointsMixin";
import styled from "styled-components";

export const ProgressRowLabelEl = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const IconContainer = styled.div`
  background: ${({ theme }) => theme.colors.accent4};
  border-radius: 4px;
  width: 34px;
  height: 34px;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.primaryColor[500]};
  }

  ${mobileMixin`
    width: 30px;
    height: 30px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  ${mobileMixin`
    padding-left: 10px;
  `}
`;

export const RowLabel = styled.label`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.accent2};
`;

export const RowValue = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.basicBlack};
`;
