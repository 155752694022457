import {
  __AVAILABLE_LANG__,
  __DEFAULT_LANGUAGE_COUNTRY__
} from "../settings/settingsLocalization";

function getNavigatorLanguageCode() {
  const currentNavigatorLanguage = navigator.language;
  const languageConvertor = /^[a-z]{2}-[A-Z]{2}$/;
  if (languageConvertor.test(currentNavigatorLanguage)) {
    return currentNavigatorLanguage.replace("-", "_");
  }
  return __DEFAULT_LANGUAGE_COUNTRY__[currentNavigatorLanguage.substr(0, 2)];
}

export function getBrowserLanguage() {
  const navigatorLang = getNavigatorLanguageCode();
  if (typeof __AVAILABLE_LANG__[navigatorLang] !== "undefined") {
    return navigatorLang;
  }
  return false;
}
