import { Palette, ToastThemeOptions } from "@ds_themes/types";

export const createToastTheme = ({
  primary,
  success,
  error,
  warning,
  grey
}: Palette): { toast: ToastThemeOptions } => {
  return {
    toast: {
      success: {
        background: success[500],
        color: grey[1000]
      },
      warning: {
        background: warning.main,
        color: warning.contrastText
      },
      error: {
        background: error[500],
        color: grey[1000]
      },
      icon: {
        dark: {
          color: grey[50]
        },
        light: {
          color: grey[1000]
        }
      },
      button: {
        outline: primary.light
      }
    }
  };
};
