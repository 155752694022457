import { createUrl } from "src/js/modules/routing";
import {
  createSearchString,
  updateLocalSearchSuggestion
} from "src/js/modules/searchFunction";
import { navigateTo } from "src/legacy/modules/history";
import { useTranslation } from "src/js/translation";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { fetchPublicProfile } from "src/js/repository/publicProfileRepository";
import { useStores } from "src/js/hooks";
import { SearchContextProps } from "./types";

type SearchUser = {
  uuid: string;
  username: string;
};

export const useProfileContext = ({ searchQuery }: SearchContextProps) => {
  const { translate } = useTranslation();
  const { UserStore } = useStores();
  const { userUuid } = useParams<{ userUuid?: string }>();
  const [user, setUser] = useState<SearchUser>(null);

  const { activeUser } = UserStore;

  useEffect(() => {
    if (!userUuid) {
      setUser({
        uuid: activeUser.uuid,
        username: UserStore.activeUserFullname
      });
      return;
    }

    fetchPublicProfile(userUuid).then(({ userUuid: uuid, name, surname }) => {
      setUser({
        uuid,
        username: `${name} ${surname}`
      });
    });
  }, []);

  if (!user) return { onSubmit: () => {}, placeholder: "" };

  const placeholder = translate({
    text: "top_header_searchbar_desktop_profile_placeholder",
    stringVariables: { username: user.username }
  });

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateLocalSearchSuggestion(searchQuery);
    const url = `${createUrl("search")}?${createSearchString({
      keyword: searchQuery,
      userFilter: [user.uuid]
    })}`;

    navigateTo(url);
  };

  return { onSubmit, placeholder };
};
