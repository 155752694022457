import React, { useEffect, useState } from "react";
import { Editor } from "@tiptap/core";
import { useStores } from "src/js/hooks";
import { TipTapAi, __AI_TE_DISCOVERED_KEY__ } from "../customExtensions";
import { AIDiscoveryModal } from "../components/AIDiscoveryModal";

const useAIEditor = (editor: Editor, onClose: () => void) => {
  const [isAIEditor, setIsAIEditor] = useState(false);
  const {
    ModalStore: { openModal, closeModal }
  } = useStores();

  useEffect(() => {
    if (!isAIEditor) return () => {};
    return () => {
      closeModal();
    };
  }, [isAIEditor]);

  useEffect(() => {
    if (!editor) return () => {};
    const createCb = () => {
      const isAI =
        editor.extensionManager.extensions
          .map(e => e.name)
          .includes(TipTapAi.name) ?? false;
      const discovered =
        JSON.parse(localStorage.getItem(__AI_TE_DISCOVERED_KEY__)) ?? false;
      if (isAI && !discovered) {
        setIsAIEditor(true);
        openModal(() => <AIDiscoveryModal onClose={onClose} />);
      }
    };
    editor.on("create", createCb);
    return () => {
      editor.off("create", createCb);
    };
  }, [editor]);
  return { isAIEditor };
};

export default useAIEditor;
