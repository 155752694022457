import colors from "@ds_themes/colors";

export const primary = {
  background: colors.grey[1000],
  text: colors.violet[501],
  type: "cta",
  border: `1px solid ${colors.grey[1000]}`,
  hover: {
    background: colors.violet[501],
    text: colors.grey[1000]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  },
  disabled: {
    background: colors.grey[1000],
    text: colors.violet[500],
    border: "0px"
  },
  badge: {
    background: colors.yellow[500],
    text: colors.grey[50],
    border: `1px solid ${colors.yellow[500]}`
  }
};

export const tertiary = {
  background: "none",
  text: colors.grey[1000],
  type: "ctaTertiary",
  border: "0px",
  hover: {
    background: colors.violet[400],
    text: colors.grey[1000]
  },
  focus: {
    border: `${colors.grey[1000]} solid 1px`
  },
  disabled: {
    background: colors.violet[501],
    text: colors.violet[700],
    border: "0px"
  },
  badge: {
    background: colors.yellow[500],
    text: colors.grey[50],
    border: `1px solid ${colors.yellow[500]}`
  }
};
