import { useEffect, useMemo, useState } from "react";
import {
  Content,
  EditorOptions,
  useEditor as useTipTapEditor
} from "@tiptap/react";
import { useAIServiceFetcher, useAITransactionInfo } from "src/js/hooks";
import { AIGenerationRequestEnum } from "src/js/hooks/useAIServiceFetcher.types";
import {
  __DEFAULT_EXTENSIONS__,
  __TIPTAP_AI_CLIENT_ID__
} from "../EditorOptions.const";
import { TipTapAi } from "../customExtensions";

/**
 *
 * @param editor?:Editor custom editor
 * @param content?: Custom content
 * @returns custom editor, or, if null, return a default editor with custom content value
 */

// TODO we can also define more granularly the extensions, or other options, without the need to pass the whole editor obj

type UseTextEditorProps = {
  content?: Content;
  editable?: boolean;
  options?: Partial<EditorOptions>;
};

const useEditor = ({
  content = "",
  editable = true,
  options = {}
}: UseTextEditorProps) => {
  const { extensions: passedExtensions, ...rest } = options;
  const [tipTapAIToken, setTipTapAIToken] = useState("");

  const hasAIExtension = useMemo(
    () => passedExtensions?.some(e => e.name === TipTapAi.name),
    [passedExtensions]
  );

  const AITransactionInfo = useAITransactionInfo({ isAI: hasAIExtension });
  const fetchContent = useAIServiceFetcher({
    requestType: AIGenerationRequestEnum.tipTapAuth,
    AITransactionInfo
  });

  const extensions = useMemo(() => {
    if (!passedExtensions?.length) return __DEFAULT_EXTENSIONS__;
    const interceptedExtensions = passedExtensions.filter(
      extension => extension.name !== TipTapAi.name
    );
    // Add modified TipTap AI extension with JWT
    if (hasAIExtension) {
      const AiExtension = passedExtensions.find(
        extension => extension.name === TipTapAi.name
      );
      interceptedExtensions.push(
        AiExtension.configure({
          appId: __TIPTAP_AI_CLIENT_ID__,
          token: tipTapAIToken
        })
      );
    }
    return interceptedExtensions;
  }, [tipTapAIToken, hasAIExtension]);

  const editor = useTipTapEditor(
    {
      editable,
      content,
      extensions,
      ...rest
    },
    extensions
  );

  useEffect(() => {
    if (fetchContent && hasAIExtension) {
      fetchContent({ body: {} }).then(({ jwt }) => {
        setTipTapAIToken(jwt);
      });
    }
  }, [hasAIExtension, AITransactionInfo]);

  return editor;
};

export default useEditor;
