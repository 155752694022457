import React, { useState } from "react";
import {
  __SHARE_PREVIEW_MIME__,
  __SHARE_PREVIEW_SIZE__
} from "src/js/settings/settingFileUploader";
import { useTheme } from "styled-components";
import { Translate } from "src/js/translation/TranslationProvider";
import { Button, Icon } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { Input, INPUT_VARIANT } from "../forms/input";
import InputFile from "../forms/InputFile";
import * as S from "./SaveRecordingModal.styles";
import { Label } from "../forms/Label";

const SaveRecordingStepOne = ({
  registrationsNumber = 0,
  onSaveSubmit = () => {},
  recordingTitle = "",
  recordingImage = null
}) => {
  const [recordingTitleState, setRecordingTitleState] =
    useState(recordingTitle);
  const [recordingImageState, setRecordingImageState] =
    useState(recordingImage);
  const { whiteTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const preview = recordingImageState
    ? URL.createObjectURL(recordingImageState)
    : null;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSaveSubmit(recordingTitleState, recordingImageState);
      }}
    >
      <Label
        marginBottom={10}
        htmlFor="recording-title"
        text="edit_recording_name_modal_label"
      />
      {registrationsNumber > 1 ? (
        <S.RegistrationNumber>
          {registrationsNumber}/{registrationsNumber}{" "}
          <Translate text="recording_modal_registrations_number_label" />
        </S.RegistrationNumber>
      ) : null}
      <Input
        id="recording-title"
        handleInputChange={e => {
          setRecordingTitleState(e.target.value);
        }}
        value={recordingTitleState}
        variant={INPUT_VARIANT.DARK}
      />
      <S.PreviewWrapper>
        <Label
          marginBottom={10}
          htmlFor="file"
          text="edit_recording_preview_modal_label"
        />
        <S.PreviewContainer>
          {preview ? (
            <img src={preview} alt={recordingTitleState} />
          ) : (
            <Icon icon="image" width={40} height={40} />
          )}
        </S.PreviewContainer>
        <InputFile
          id="file"
          name="file"
          buttonUploadText="edit_recording_preview_upload_button"
          maxSize={__SHARE_PREVIEW_SIZE__}
          mimeArray={__SHARE_PREVIEW_MIME__}
          setInputFile={setRecordingImageState}
        />
      </S.PreviewWrapper>
      <S.Footer>
        <Button
          type="submit"
          variant="primary"
          theme={whiteTheme}
          fullWidth={isLayoutModeMobile}
        >
          <Translate text="recording_name_image_submit_button" />
        </Button>
      </S.Footer>
    </form>
  );
};

export default SaveRecordingStepOne;
