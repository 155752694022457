import { __IMG_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { ImageModel, ImageModelType } from "../types";

function handleThumbnails(obj: ImageModel, type: ImageModelType) {
  let imgType = type;
  const imageObject = obj;
  if (typeof imageObject === "string") {
    return imageObject;
  }

  if (!imageObject) return __IMG_PLACEHOLDER__;

  if (typeof imgType !== "string") {
    imgType = "small";
  }
  if (typeof imageObject[imgType] !== "undefined") {
    return imageObject[imgType];
  }
  if (typeof imageObject.thumbnail_types !== "undefined") {
    const thumbs = imageObject.thumbnail_types;
    const thumb = thumbs.find(t => t.type === imgType);
    if (thumb === undefined) {
      return imageObject.original_url;
    }
    return thumb.url;
  }
  return imageObject.original_url;
}

export { handleThumbnails as default };
