import React, { forwardRef, PropsWithChildren } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { ActionButton } from "@ds_universal/data_entry/ActionButton";
import { whiteTheme } from "@ds_themes/white";
import { Text } from "@ds_universal/Text";
import { Icon } from "@ds_universal/Icon";
import { Message } from "@ds_universal/data_entry/Input/Input.styles";
import { SpecificError } from "@ds_universal/data_display/SpecificError";

import * as S from "./SearchBarWithSuggestions.styles";
import { SearchbarWithSuggestionsProps } from "./types";

const SearchBarWithSuggestions = forwardRef<
  HTMLInputElement,
  PropsWithChildren<SearchbarWithSuggestionsProps>
>(
  (
    {
      id = "searchBar-with-suggestion-id",
      theme,
      placeholder,
      name = "SearchBarWithSuggestion",
      value,
      label = "Search Bar With Suggestion",
      type = "button",
      hideLabel = true,
      onChange,
      onClick,
      onSearchDisabled,
      onSearch,
      onReset,
      showSuggestions,
      children,
      feedback,
      "data-testid": testId,
      ...rest
    },
    ref
  ) => {
    const handleKeyPress = (event: React.KeyboardEvent<Element>) => {
      switch (event.key) {
        case "Enter":
          if (!onSearch || onSearchDisabled) break;
          onSearch(event as never);
          break;
        case "Escape":
          if (!onReset) break;
          onReset(event as never);
          break;
        default:
          break;
      }
    };
    return (
      <ThemeProvider theme={theme}>
        <S.Container onKeyDown={handleKeyPress} {...rest}>
          <S.Label htmlFor={id} visuallyHidden={hideLabel}>
            <Text type="formSubtitle">{label}</Text>
          </S.Label>
          <S.Wrapper>
            <S.SearchBarWithSuggestions
              id={id}
              type="text"
              name={name}
              onChange={onChange}
              onClick={onClick}
              value={value}
              placeholder={placeholder}
              ref={ref}
              data-testid={testId}
            />
            <S.ButtonsWrapper>
              {onReset && value ? (
                <ActionButton
                  theme={whiteTheme}
                  type="reset"
                  variant="tertiary"
                  icon="close"
                  onClick={onReset}
                />
              ) : onSearch ? (
                <ActionButton
                  theme={whiteTheme}
                  type={type}
                  variant="tertiary"
                  disabled={onSearchDisabled}
                  icon="search"
                  onClick={onSearch}
                />
              ) : (
                <S.IconWrapper>
                  <Icon icon="search" width="14px" height="14px" />
                </S.IconWrapper>
              )}
            </S.ButtonsWrapper>
          </S.Wrapper>
          {showSuggestions && <S.OptionContainer>{children}</S.OptionContainer>}
          {feedback && feedback.message ? (
            <Message feedbackType={feedback.type} type="textarea" theme={theme}>
              {feedback.type === "error" ? (
                <SpecificError
                  theme={
                    theme.searchbarWithSuggestion as unknown as DefaultTheme
                  }
                  text={feedback.message}
                />
              ) : (
                <>
                  <Icon
                    icon="successCircle"
                    height="12"
                    width="12"
                    viewbox="0 0 24 24"
                  />
                  <Text type="label">{feedback.message}</Text>
                </>
              )}
            </Message>
          ) : null}
        </S.Container>
      </ThemeProvider>
    );
  }
);

SearchBarWithSuggestions.displayName = "SearchBarWithSuggestions";

export default SearchBarWithSuggestions;
