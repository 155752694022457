import styled, { css } from "styled-components";

const variantOptions = theme => ({
  dark: {
    backgroundColor: theme.colors.accent4,
    color: theme.colors.slate,
    borderColor: theme.colors.lightBlueGrey,
    placeholderColor: theme.colors.blueGrey[500],
    focus: {
      background: theme.colors.accent4,
      color: theme.colors.slate,
      border: theme.colors.blueGrey[500]
    },
    error: {
      background: theme.colors.lightSemantic1,
      color: theme.colors.semantic1,
      border: theme.colors.semantic1
    },
    disabled: {
      background: theme.colors.basicWhite,
      color: theme.colors.blueGrey[500],
      border: theme.colors.lightBlueGrey
    }
  },

  light: {
    backgroundColor: theme.colors.basicWhite,
    color: theme.colors.grey[50],
    borderColor: theme.colors.accent3,
    placeholderColor: theme.colors.accent2,
    focus: {
      background: theme.colors.basicWhite,
      color: theme.colors.grey[50],
      border: theme.primaryColor[500]
    },
    error: {
      background: theme.colors.basicWhite,
      color: theme.colors.semantic1,
      border: theme.colors.semantic1
    },
    disabled: {
      background: theme.colors.disabledBk,
      color: theme.colors.blueGrey[500],
      border: theme.colors.lightBlueGrey
    }
  }
});

export const TextAreaInput = styled.textarea`
  display: block;
  width: 100%;
  padding: 16px;
  height: 100%;
  line-height: 20px;
  transition: 0.3s;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  color: ${({ theme }) => theme.colors.basicWhite};
  border-radius: ${({ theme }) => theme.radius.base};
  border: 1px solid ${({ theme }) => theme.colors.accent3};
  background-color: ${({ theme }) => theme.colors.accent3};
  box-shadow: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${({ theme, variant }) =>
    variant &&
    css`
      background-color: ${variantOptions(theme)[variant].backgroundColor};
      color: ${variantOptions(theme)[variant].color};
      border: 1px solid ${variantOptions(theme)[variant].borderColor};

      ::-webkit-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      ::-moz-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      :-ms-input-placeholder {
        color: ${variantOptions(theme)[variant].placeholderColor} !important;
      }

      &:hover,
      &:focus {
        background-color: ${variantOptions(theme)[variant].focus
          .background}!important;
        color: ${variantOptions(theme)[variant].focus.color}!important;
        border-color: ${variantOptions(theme)[variant].focus.border}!important;
      }

      ${({ hasError }) =>
        hasError &&
        css`
          background-color: ${variantOptions(theme)[variant].error.background};
          color: ${variantOptions(theme)[variant].error.color};
          border-color: ${variantOptions(theme)[variant].error.border};

          ::-webkit-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          ::-moz-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }

          :-ms-input-placeholder {
            color: ${variantOptions(theme)[variant].error.color} !important;
          }
        `}

      ${({ disabled }) =>
        disabled &&
        css`
          cursor: not-allowed !important;
          opacity: 1;
          -webkit-text-fill-color: ${variantOptions(theme)[variant].disabled
            .color};
          -webkit-opacity: 1;
          background-color: ${variantOptions(theme)[variant].disabled
            .background};
          color: ${variantOptions(theme)[variant].disabled.color};
          border-color: ${variantOptions(theme)[variant].disabled.border};

          &:hover,
          &:focus {
            background-color: ${variantOptions(theme)[variant].disabled
              .background}!important;
            color: ${variantOptions(theme)[variant].disabled.color}!important;
            border-color: ${variantOptions(theme)[variant].disabled
              .border}!important;
          }
        `}
    `}
`;

export const TextAreaContainer = styled.div`
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}

  height: 100%;
  ${TextAreaInput} {
    // override rules
  }
`;

export const TextAreaCounter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-weight: 400;
`;

export const TextAreaErrorMessage = styled.span`
  text-align: left;
  flex: 1;
  margin: 0 10px 0 0;
  display: inline-block;
  color: ${({ theme }) => theme.colors.semantic1};
`;
