import React from "react";
import * as S from "./ModalHeaderTabItem.styles";

const ModalHeaderTabItem = ({
  children,
  isSelected = false,
  isDisabled = false,
  onClickFunction = () => {}
}) => {
  return (
    <S.TabButton
      type="button"
      onClick={() => onClickFunction()}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      {children}
    </S.TabButton>
  );
};

export default ModalHeaderTabItem;
