import React, { FC, useMemo } from "react";
import { ThemeProvider } from "styled-components";

import * as S from "./AvatarWithInitials.styles";
import { AvatarWithInitialsProps } from "./types";

const AvatarWithInitials: FC<AvatarWithInitialsProps> = ({
  url,
  alt,
  name,
  avatarColor = 0,
  size,
  theme,
  className = undefined
}) => {
  const spaceName = useMemo(() => {
    const trimmedName = name.trim();

    if (!trimmedName) return null;
    return trimmedName[0].toUpperCase();
  }, [name]);

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper className={className}>
        <S.Circle size={size}>
          {url ? (
            <img src={url} alt={alt} />
          ) : (
            <S.CircleContent
              data-testid="circle-content"
              avatarColor={avatarColor}
            >
              <S.CircleLetters data-testid="circle-letters" size={size}>
                {spaceName}
              </S.CircleLetters>
            </S.CircleContent>
          )}
        </S.Circle>
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default AvatarWithInitials;
