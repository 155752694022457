import { makeAutoObservable, reaction, toJS } from "mobx";
import { equalsArrayIgnoreOrder } from "src/js/modules/commonFunction";
import {
  fetchSpacesGroups,
  fetchSpacesUsers
} from "src/js/repository/dashboardRepository";
import {
  __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
  __SPACE_USERS_LIST_LIMIT__
} from "src/js/settings/settingsPagination";

class SpacesUsersFilterStore {
  $filters = {
    searchQuery: "",
    selectedOrder: ["created_desc"],
    selectedGroups: [],
    selectedRoles: []
  };

  $pagination = {
    limit: __SPACE_USERS_LIST_LIMIT__,
    offset: 0
  };

  $spaceGroups = {
    $spaceGroupsList: [],
    $offset: 0,
    $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
    $total: 0
  };

  $filteredUsers = [];

  $totalUsers = 0;

  $unfilteredTotalUsers = 0;

  constructor({ spaceStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.spaceStore = spaceStore;
    this.dispose();
  }

  // actions
  setAllFilters = filterObject => {
    this.$filters = filterObject;
  };

  setFilter = (value, groupKey) => {
    this.$filters[groupKey] = value;
    this.setPaginationOffset(0);
  };

  resetFilter = groupKey => {
    this.$filters[groupKey] = [];
    this.setPaginationOffset(0);
  };

  removeKeyFromFilter = (key, groupKey) => {
    this.$filters[groupKey] = this.$filters[groupKey].filter(i => i !== key);
  };

  reset = () => {
    this.$filters = {
      searchQuery: "",
      selectedOrder: ["created_desc"],
      selectedGroups: [],
      selectedRoles: []
    };
    this.$pagination = {
      limit: __SPACE_USERS_LIST_LIMIT__,
      offset: 0
    };
    this.$spaceGroups = {
      $spaceGroupsList: [],
      $offset: 0,
      $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
      $total: 0
    };
  };

  setPaginationOffset = value => {
    this.$pagination.offset = value;
  };

  changePage = page => {
    const newOffset = Math.round((page - 1) * this.$pagination.limit);
    this.setPaginationOffset(newOffset);
  };

  setFiltersAndPaginationFromQueryParams = queryParamsObject => {
    const searchQuery = (queryParamsObject.name || "").replace(/\+/g, " ");
    const selectedOrder = [
      `${queryParamsObject.order_by}_${queryParamsObject.sort_order}`
    ];
    let selectedGroups = toJS(this.$filters.selectedGroups);
    if (queryParamsObject.groups) {
      const groupIds = queryParamsObject.groups.split(",");
      const numericGroupIds = groupIds.map(groupId => Number(groupId));
      selectedGroups = numericGroupIds;
    }
    let selectedRoles = toJS(this.$filters.selectedRoles);
    if (queryParamsObject.roles) {
      selectedRoles = queryParamsObject.roles.split(",");
    }
    const newFilters = {
      searchQuery,
      selectedOrder,
      selectedGroups,
      selectedRoles
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [keyFilter, valueFilter] of Object.entries(
      toJS(this.$filters)
    )) {
      const isArray = Array.isArray(this.$filters[keyFilter]);
      const hasSameValue = isArray
        ? equalsArrayIgnoreOrder(newFilters[keyFilter], valueFilter)
        : newFilters[keyFilter] === valueFilter;
      if (!hasSameValue) {
        this.$filters[keyFilter] = newFilters[keyFilter];
      }
    }

    const { limit, offset } = queryParamsObject;
    const newPagination = {
      limit: parseInt(limit, 10) || __SPACE_USERS_LIST_LIMIT__,
      offset: parseInt(offset, 10) || 0
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [keyPag, valuePag] of Object.entries(toJS(this.$pagination))) {
      const hasSameValue = newPagination[keyPag] === valuePag;
      if (!hasSameValue) {
        this.$pagination[keyPag] = newPagination[keyPag];
      }
    }
  };

  getOrderValues = selectedOrder => {
    const value = selectedOrder.length > 0 ? selectedOrder[0] : "created_desc";
    let order_by;
    let sort_order;
    switch (value) {
      case "name_asc":
        order_by = "name";
        sort_order = "asc";
        break;
      case "name_desc":
        order_by = "name";
        sort_order = "desc";
        break;
      case "created_asc":
        order_by = "created";
        sort_order = "asc";
        break;
      case "created_desc":
        order_by = "created";
        sort_order = "desc";
        break;
      case "birthday_asc":
        order_by = "birthday";
        sort_order = "asc";
        break;
      case "birthday_desc":
        order_by = "birthday";
        sort_order = "desc";
        break;
      default:
        order_by = "created";
        sort_order = "desc";
    }
    return { order_by, sort_order };
  };

  spaceSetSelectedGroups = groupsIds => {
    this.$filters.$selectedGroups = groupsIds;
    return groupsIds;
  };

  setFilteredUsers = users => {
    this.$filteredUsers = users;
  };

  setTotalUsers = value => {
    this.$totalUsers = value;
  };

  setUnfilteredTotalUsers = value => {
    this.$unfilteredTotalUsers = value;
  };

  fetchUsers = () => {
    const { limit, offset, name, groups, roles, order_by, sort_order } =
      this.filterValuesForQueryParams;
    const spaceId = this.spaceStore.activeSpaceId;
    if (!spaceId) return Promise.resolve();
    const include_disabled = groups && groups.includes(0);
    const filteredGroups = groups && groups.filter(id => id !== 0);
    return fetchSpacesUsers({
      spaceId,
      limit,
      offset,
      name,
      groups: filteredGroups,
      roles,
      order_by,
      sort_order,
      include_disabled
    })
      .then(data => {
        this.setFilteredUsers(data?.results);
        this.setTotalUsers(data?.total);
        this.setUnfilteredTotalUsers(data?.unfilteredTotal);
      })
      .catch(error => {
        this.setFilteredUsers([]);
        this.setTotalUsers(0);
        this.setUnfilteredTotalUsers(0);
        throw error;
      });
  };

  spaceFetchGroups(spaceId, nextOffset) {
    return fetchSpacesGroups({
      spaceId,
      limit: this.$spaceGroups.$limit,
      offset: nextOffset
    })
      .then(({ results, offset, total }) => {
        this.$spaceGroups.$spaceGroupsList = [
          ...this.$spaceGroups.$spaceGroupsList,
          ...results
        ];
        this.$spaceGroups.$offset = offset;
        this.$spaceGroups.$total = total;
      })
      .catch(() => {
        this.$spaceGroups = {
          $spaceGroupsList: [],
          $offset: 0,
          $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
          $total: 0
        };
      });
  }

  fetchSpaceGroups = spaceId => {
    if (!spaceId) {
      this.$spaceGroups = {
        $spaceGroupsList: [],
        $offset: 0,
        $limit: __SPACE_GROUPS_LIST_SEARCH_LIMIT__,
        $total: 0
      };
      return null;
    }
    return this.spaceFetchGroups(spaceId, this.$spaceGroups.$offset);
  };

  spaceShowNextGroups = () => {
    if (!this.spaceHasNextGroups) return () => {};

    const spaceId = this.spaceStore?.activeSpaceId;

    const nextOffset = this.$spaceGroups.$offset + this.$spaceGroups.$limit;

    return this.spaceFetchGroups(spaceId, nextOffset);
  };

  // computed
  get filterValuesForQueryParams() {
    const { searchQuery, selectedOrder, selectedGroups, selectedRoles } =
      this.$filters;
    const { order_by, sort_order } = this.getOrderValues(selectedOrder);
    const { limit, offset } = this.$pagination;

    return {
      limit,
      offset,
      name: searchQuery,
      groups: selectedGroups.length === 0 ? null : selectedGroups,
      roles: selectedRoles.length === 0 ? null : selectedRoles,
      order_by,
      sort_order
    };
  }

  get spaceGroupsList() {
    return this.$spaceGroups.$spaceGroupsList.map(({ group }) => ({
      key: group.id,
      value: group.name
    }));
  }

  get spaceGroupsTotal() {
    return this.$spaceGroups.$total;
  }

  get spaceHasNextGroups() {
    return this.spaceGroupsList.length < this.spaceGroupsTotal;
  }

  get filters() {
    return this.$filters;
  }

  get filteredUsers() {
    return this.$filteredUsers;
  }

  get totalUsers() {
    return this.$totalUsers;
  }

  get unfilteredTotalUsers() {
    return this.$unfilteredTotalUsers;
  }

  get paginationOffset() {
    return this.$pagination.offset;
  }

  dispose = () => {
    reaction(
      // reacts on change of query params
      () => this.filterValuesForQueryParams,
      () => {
        this.fetchUsers().catch();
      }
    );
  };
}

export default SpacesUsersFilterStore;
