import axios from "axios";
import {
  api_platform_invite,
  api_registration,
  getVersionedApiUrl
} from "src/js/repository/apiUrls";
import { __API_PATH__ } from "src/js/settings/settingsUrl";
import { getAuthenticationToken } from "src/js/modules/tokens";
import getUserClient from "src/js/modules/connection";

export function sendPlatformInviteRequest(data) {
  return axios({
    method: "post",
    dataType: "json",
    data,
    contentType: "application/json",
    url: __API_PATH__ + api_platform_invite(),
    headers: {
      Authorization: `Bearer ${getAuthenticationToken()}`
    },
    ignoreCall: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const Err = new Error(
        error.response.data.message_id || "error_send_email_platform_invite"
      );
      throw Err;
    });
}

// export function submitRegistration(data) {
//   return axios({
//     withCredentials: true,
//     method: "post",
//     url: __API_PATH__ + api_registration(),
//     ignoreCall: true,
//     data,
//     contentType: "application/json"
//   }).then(response => {
//     return response.data;
//   });
// }

export function submitRegistration(data) {
  return getUserClient()
    .post(getVersionedApiUrl(api_registration(), "v3"), data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error?.response?.data;
    });
}
