/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from "moment";
import { oneOf, string } from "prop-types";
import React, { useRef, useState, useEffect, useCallback } from "react";
import TimeInput from "react-time-input";
import { useOnClickOutSide, useToggle } from "src/js/hooks";
import { Translate } from "src/js/translation/TranslationProvider";
import { SpecificError } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { Label } from "../Label";
import { LABEL_POSITIONS, TIMEPICKER_VARIANT } from "./TimePicker.const";
import * as S from "./TimePicker.styles";

const scrollToClosestTime = ({ id, timeValue, setIsVisible }) => {
  const timeValueSplitted = timeValue.split(":");
  const roundedMinutes =
    Math.floor(parseInt(timeValueSplitted[1], 10) / 15) * 15;
  const textualRoundedMinutes = roundedMinutes > 0 ? roundedMinutes : "00";
  const element = document.querySelector(
    `#${id} [data-time-value="${timeValueSplitted[0]}:${textualRoundedMinutes}"]`
  );
  if (element) element.scrollIntoView({ block: "center" });
  setIsVisible(true);
};

const SuggestionDropdown = ({
  id = "time-picker-dropdown",
  startTime,
  actionFunction = () => {},
  suggestionAlignedLeft = false,
  isVisible = false
}) => {
  const suggestionsArray = [];
  const baseDate = moment(Date.now());

  if (startTime) {
    const startTimeSplitted = startTime.split(":");
    baseDate.minute(startTimeSplitted[1]);
    baseDate.hour(startTimeSplitted[0]);
    baseDate.add(15, "m");
    for (let i = 0; i < 3; i++) {
      suggestionsArray.push({
        stringvalue: baseDate.format("HH:mm"),
        unit: (i + 1) * 15,
        measurementUnitLabel: "minutes"
      });
      baseDate.add(15, "m");
    }
    for (let i = 0; i < 46; i++) {
      suggestionsArray.push({
        stringvalue: baseDate.format("HH:mm"),
        unit: ((i + 2) / 2).toString().replace(".", ","),
        measurementUnitLabel: i > 0 ? "hours" : "hour"
      });
      baseDate.add(30, "m");
    }
  } else {
    baseDate.minute("00");
    baseDate.hour("00");
    for (let i = 0; i < 96; i++) {
      suggestionsArray.push({
        stringvalue: baseDate.format("HH:mm"),
        unit: "",
        measurementUnitLabel: ""
      });
      baseDate.add(15, "m");
    }
  }

  if (suggestionsArray && suggestionsArray.length > 0) {
    const list = suggestionsArray.map(option => {
      return (
        <S.SuggestionDropdownElement
          key={option.stringvalue}
          aria-label="listOption"
          role="option"
          data-testid="listOption"
          data-time-value={option.stringvalue}
          onClick={() => actionFunction(option.stringvalue)}
        >
          <S.StringValue>{option.stringvalue}</S.StringValue>
          {startTime && (
            <S.IncrementValue>
              ({option.unit} <Translate text={option.measurementUnitLabel} />)
            </S.IncrementValue>
          )}
        </S.SuggestionDropdownElement>
      );
    });
    return (
      <S.SuggestionDropdownBody
        id={id}
        aria-label="comboboxBody"
        role="listbox"
        data-testid="listbox"
        tabindex="-1"
        suggestionAlignedLeft={suggestionAlignedLeft}
        isVisible={isVisible}
      >
        {list}
      </S.SuggestionDropdownBody>
    );
  }
  return "";
};

const TimePicker = ({
  id,
  name,
  defaultValue = undefined,
  label = null,
  hasError = false,
  hasSuggestion = false,
  suggestionAlignedLeft = false,
  startTime = null,
  errorMessage = "",
  disabled = false,
  labelDirection = LABEL_POSITIONS.FROM_TOP,
  handleInputChange = () => {},
  handleFocus = () => {},
  handleBlur = () => {},
  variant = TIMEPICKER_VARIANT.DARK,
  ...props
}) => {
  const getLabel = () => {
    if (!label) return <Label htmlFor={id} text={id} visuallyHidden />;
    return React.isValidElement(label) ? (
      label
    ) : (
      <Label marginBottom="0" htmlFor={id} text={label} />
    );
  };

  const [timeValue, setTimeValue] = useState(defaultValue);
  const [suggestionOpen, setSuggestionOpen] = useToggle(false);
  const [isVisible, setIsVisible] = useToggle(false);
  const { whiteTheme } = useTheme();

  const node = useRef();
  useOnClickOutSide(node, () => setSuggestionOpen(false));

  useEffect(() => {
    setTimeValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (suggestionOpen && !startTime) {
      scrollToClosestTime({
        id: `${id}-time-picker-dropdown`,
        timeValue,
        setIsVisible
      });
    } else {
      setIsVisible(suggestionOpen);
    }
  }, [suggestionOpen]);

  const onSelectValue = useCallback(value => {
    setSuggestionOpen(false);
    setTimeValue(value);
  });

  const onTimeChange = useCallback(value => {
    setSuggestionOpen(false);
    setTimeValue(value);
    handleInputChange(value);
  });

  const onClickHandler = useCallback(() => {
    if (hasSuggestion && !disabled) {
      setSuggestionOpen(!suggestionOpen);
    }
  });

  return (
    <S.TimePickerContainer
      {...props}
      labelDirection={labelDirection}
      hasLabel={label}
      ref={node}
    >
      {getLabel()}
      <S.AlignContainer>
        <S.TimeInputWrapper
          hasError={hasError}
          variant={variant}
          disabled={disabled}
          onClick={() => onClickHandler()}
          aria-label="combobox"
        >
          <TimeInput
            id={id}
            name={name}
            initTime={timeValue}
            className="ws-time-input"
            disabled={disabled}
            onBlurHandler={handleBlur ? event => handleBlur(event) : null}
            onFocusHandler={handleFocus ? event => handleFocus(event) : null}
            onTimeChange={onTimeChange}
          />

          {hasSuggestion && suggestionOpen && (
            <SuggestionDropdown
              id={`${id}-time-picker-dropdown`}
              startTime={startTime}
              actionFunction={onSelectValue}
              suggestionAlignedLeft={suggestionAlignedLeft}
              isVisible={isVisible}
            />
          )}
        </S.TimeInputWrapper>
        {hasError && errorMessage && (
          <S.ErrorMessageContainer>
            <SpecificError theme={whiteTheme} text={errorMessage} />
          </S.ErrorMessageContainer>
        )}
      </S.AlignContainer>
    </S.TimePickerContainer>
  );
};

/* eslint-disable react/require-default-props */
TimePicker.propTypes = {
  id: string.isRequired,
  labelDirection: oneOf(Object.values(LABEL_POSITIONS))
};

export default TimePicker;
