import { Input } from "@arcadia/design-system";
import Tooltip from "rc-tooltip";
import React, { useRef } from "react";
import * as S from "./InputUrl.styles";
import { InputUrlProps } from "./types";

const InputUrl = ({
  staticUrlDomain,
  tooltipString,
  hideLabel,
  ...props
}: InputUrlProps) => {
  const TooltipWrapperNode = useRef();
  const domainTextRef = useRef<HTMLDivElement>();

  return (
    <S.InputWrapper domainLength={domainTextRef?.current?.offsetWidth}>
      <Input hideLabel={hideLabel} {...props} />
      <S.TooltipWrapper ref={TooltipWrapperNode}>
        <Tooltip
          trigger="hover"
          placement="topRight"
          overlay={tooltipString}
          getTooltipContainer={() => TooltipWrapperNode.current}
        >
          <S.TooltipIcon hideLabel={hideLabel}>i</S.TooltipIcon>
        </Tooltip>
      </S.TooltipWrapper>
      <S.DomainText ref={domainTextRef} hideLabel={hideLabel}>
        {staticUrlDomain}
      </S.DomainText>
    </S.InputWrapper>
  );
};

export default InputUrl;
