import { Heading } from "@arcadia/design-system";
import React from "react";
import * as S from "./SelectMonthYearDropdown.styles";

const SelectMonthYearDropdown = ({
  data,
  onChange
}: {
  data: (string | number)[];
  onChange: (data: string | number) => void;
}) => {
  const options = data.map(value => (
    <S.Option
      key={value}
      onClick={() => {
        onChange(value);
      }}
    >
      <Heading level="5">{value}</Heading>
    </S.Option>
  ));
  return <S.Wrapper>{options}</S.Wrapper>;
};

export default SelectMonthYearDropdown;
