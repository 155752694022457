import { useCallback } from "react";
import { createUrl } from "src/js/modules/routing";
import { GroupDetailsModel } from "src/js/types";
import { navigateTo } from "src/legacy/modules/history";

export const useGroupSettingsNavigation = (group: GroupDetailsModel) => {
  const goBack = useCallback(() => {
    if (group) {
      navigateTo(createUrl("wall", { group_id: group.id }));
    }
  }, [group?.id]);

  const navigateNotifications = useCallback(() => {
    if (group) {
      navigateTo(
        createUrl("group_settings", { group_id: group.id, option: "notice" })
      );
    }
  }, [group?.id]);

  const navigateSections = useCallback(() => {
    if (group) {
      navigateTo(
        createUrl("group_settings", { group_id: group.id, option: "sections" })
      );
    }
  }, [group?.id]);

  const navigateMembers = useCallback(() => {
    if (group) {
      navigateTo(
        createUrl("group_settings", { group_id: group.id, option: "members" })
      );
    }
  }, [group?.id]);

  const navigateInformation = useCallback(() => {
    if (group) {
      navigateTo(
        createUrl("group_settings", {
          group_id: group.id,
          option: "information"
        })
      );
    }
  }, [group?.id]);

  return {
    goBack,
    navigateInformation,
    navigateMembers,
    navigateNotifications,
    navigateSections
  };
};
