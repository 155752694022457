import React, { FC } from "react";
import { ContextualAlert } from "@arcadia/design-system";
import styled, { useTheme } from "styled-components";
import { ColorSwatchPicker } from "src/js/components/ColorSwatchPicker";
import { useTranslation } from "src/js/translation";
import { EditBoardToggleElement } from "src/js/components/DrawerEditBoard/EditBoardToggleElement";
import { EditBoardConstraintsSection } from "../EditBoardConstraintsSection";
import MilestoneElement from "../MilestoneElement/MilestoneElement";
import { EditBoardBodyTypes } from "./types";

const StyledContextualAlert = styled.div`
  > div {
    max-width: unset;
  }
  margin-top: -12px;
`;

const EditBoardBody: FC<EditBoardBodyTypes> = ({
  color,
  isMandatory,
  constraints,
  isMilestone,
  isOpen,
  hasMandatoryOrder,
  milestoneMessage,
  originalMilestoneMessage,
  boardId,
  groupId,
  isDesktop,
  setMilestoneMessage,
  onSaveMilestoneMessage,
  editMilestone,
  editColor,
  editMandatory,
  editOpen,
  onAddBoardConstraint,
  onRemoveBoardConstraint,
  editMandatoryOrder
}) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  return (
    <>
      <ColorSwatchPicker value={color} onChange={editColor} />
      <EditBoardToggleElement
        title="mandatory_board_toggle_title"
        description="mandatory_board_toggle_description"
        iconName="asterisk"
        toggleValue={isMandatory}
        onToggleChange={editMandatory}
        noTopBorder
      />
      <EditBoardConstraintsSection
        groupId={groupId}
        boardId={boardId}
        selectedItem={constraints}
        onAddBoardConstraint={onAddBoardConstraint}
        onRemoveBoardConstraint={onRemoveBoardConstraint}
      />
      <MilestoneElement
        key={boardId}
        milestoneMessage={milestoneMessage}
        originalMilestoneMessage={originalMilestoneMessage}
        setMilestoneMessage={message => setMilestoneMessage(message)}
        toggleValue={isMilestone}
        onSaveMessage={onSaveMilestoneMessage}
        setToggleValue={editMilestone}
        isDesktop={isDesktop}
      />

      <EditBoardToggleElement
        title="open_board_toggle_title"
        description="open_board_toggle_description"
        iconName="paperPlus"
        toggleValue={isOpen}
        onToggleChange={editOpen}
      />
      <EditBoardToggleElement
        title="fixed_order_toggle_title"
        description="fixed_order_toggle_description"
        iconName="contentGrid"
        toggleValue={hasMandatoryOrder}
        onToggleChange={editMandatoryOrder}
      />
      <StyledContextualAlert>
        <ContextualAlert
          theme={whiteTheme}
          text={translate("fixed_order_toggle_warning_message")}
        />
      </StyledContextualAlert>
    </>
  );
};

export default EditBoardBody;
