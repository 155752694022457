import styled from "styled-components";
import { Text } from "@arcadia/design-system";

export const ModuleButton = styled.div<{ firstButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px 24px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey[800]}`};
  border-radius: 8px;
  margin: ${({ firstButton }) =>
    `${firstButton ? "14px" : "0px"} 18px ${
      firstButton ? "8px" : "14px"
    } 18px`};
`;

export const Title = styled(Text)`
  margin-left: 16px;
`;
