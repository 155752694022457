import { DoodleLoader, Dropdown, Icon } from "@arcadia/design-system";
import createReactClass from "create-react-class";
import clone from "lodash/clone";
import filter from "lodash/filter";
import isUndefined from "lodash/isUndefined";
import mapValues from "lodash/mapValues";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import moment from "moment";
import React from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { ActivityExerciseModal } from "src/js/components/modal/ActivityExerciseModal";
import { withModalStore, withSpaceStore } from "src/js/hooks/useStores";
import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import { Translate } from "src/js/translation/TranslationProvider";
import styled, { withTheme } from "styled-components";
import withBackbone from "with-backbone";
import ExerciseModel from "../../models/exerciseModel";
import { __ } from "../../modules/localization";
import { createURL } from "../../modules/utility";
import EserciziReportsUsersItem from "../exercise/report/exerciseReportUsersDetail";

const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -17px;
`;

const StyledDropdownWrapper = styled.div`
  max-width: 180px;
  margin: auto auto 24px;
`;

let QuizActivityTable = withTheme(
  withBackbone(
    createReactClass({
      getDefaultProps() {
        return {
          activityData: {},
          exerciseType: "ASSIGNMENT",
          loading: false
        };
      },
      getInitialState() {
        return {
          activeTab: "",
          validExercise: "",
          activityData: {},
          originalData: {},
          originalFilteredData: {},
          exerciseType: "ASSIGNMENT",
          orderId: "",
          orderType: "",
          orderState: "",
          filter: "all",
          loader: true
        };
      },
      componentWillMount() {
        let self = this;
        let exercises = self.props.activityData.exercises;
        let validExercise = [];
        exercises.map(exercise => {
          if (exercise.type == self.props.exerciseType) {
            validExercise.push(exercise);
          }
        });
        // console.log(self.props.activityData);
        self.setState({
          exerciseType: self.props.exerciseType,
          activityData: self.props.activityData,
          originalData: self.props.activityData,
          validExercise
        });
      },
      componentDidMount() {
        let self = this;
        if (self.props.loading == false) {
          // console.log('create');
          self.createStructure();
        }
      },
      componentWillReceiveProps(nextProps) {
        let self = this;
        if (nextProps.loading == false) {
          self.createStructure();
        }
      },
      createStructure() {
        let self = this;
        let activityData = self.state.activityData;
        let userStatistics = activityData.user_statistics;
        let exercises = self.state.validExercise;
        let updatedRows = "";
        updatedRows = userStatistics.map(userRowParam => {
          const userRow = clone(userRowParam);
          let exerciseStatistics = userRow.exercise_statistics;
          if (isUndefined(exerciseStatistics)) {
            userRow.exercise_statistics = {};
            exerciseStatistics = {};
          }
          let countExercise = 0;
          let needCorrection = false;
          let average = 0;
          let overallGradeSum = 0;
          mapValues(exercises, exercise => {
            if (
              isUndefined(exerciseStatistics[exercise.id]) ||
              exerciseStatistics[exercise.id].generated
            ) {
              userRow.exercise_statistics[exercise.id] = {
                best_vote: null,
                executions_number: null,
                not_correct_execution: null,
                generated: true
              };
              exerciseStatistics[exercise.id] =
                userRow.exercise_statistics[exercise.id];
            } else {
              let exerciseValue = exerciseStatistics[exercise.id];
              countExercise += 1;
              userRow.exercise_statistics[exercise.id].best_vote = isUndefined(
                exerciseValue.best_vote
              )
                ? 0
                : exerciseValue.best_vote;
              userRow.exercise_statistics[exercise.id].executions_number =
                isUndefined(exerciseValue.executions_number)
                  ? 0
                  : exerciseValue.executions_number;
              userRow.exercise_statistics[exercise.id].not_correct_execution =
                isUndefined(exerciseValue.not_correct_execution)
                  ? null
                  : exerciseValue.not_correct_execution;
              needCorrection =
                exerciseValue.not_correct_execution != null
                  ? true
                  : needCorrection;
              overallGradeSum += Math.round(exerciseValue.best_vote * 100) / 10;
            }
          });

          if (countExercise == 0) {
            average = null;
          } else {
            average = Math.round((overallGradeSum / countExercise) * 10) / 10;
          }

          userRow.countExercise = countExercise;
          userRow.needCorrection = needCorrection;
          userRow.average = average;
          return userRow;
        });
        // console.log(updatedRows,userStatistics);
        activityData.user_statistics = updatedRows;
        self.setState({
          activityData,
          originalData: clone(activityData),
          loader: false
        });
      },
      filterExercise(value) {
        let self = this;
        self.setState(
          {
            filter: value
          },
          self.setFilter
        );
      },
      setFilter() {
        let self = this;
        let activityData = self.state.activityData;
        let userStatistics = activityData.user_statistics;
        let filterdElement = userStatistics;
        let filtering = self.state.filter;
        let originalFilteredData = {};
        switch (filtering) {
          case "all":
            filterdElement = self.state.originalData.user_statistics;
            break;
          case "review":
            filterdElement = filter(
              filterdElement,
              elem => elem.needCorrection == true
            );
            break;
        }
        activityData.user_statistics = filterdElement;
        self.setState({
          activityData,
          originalFilteredData: clone(activityData)
        });
      },
      setOrder(type, id) {
        let self = this;
        let activityData = self.state.activityData;
        let userStatistics = activityData.user_statistics;
        let order = self.state.orderState;
        let sortedStatistics = "";
        switch (type) {
          case "average":
            sortedStatistics = sortBy(userStatistics, "average");
            break;
          case "exercise-completed":
            sortedStatistics = sortBy(userStatistics, "countExercise");
            break;
          case "single-average":
            sortedStatistics = sortBy(userStatistics, item => [
              item.exercise_statistics[id].best_vote
            ]);
            break;
          case "execution":
            sortedStatistics = sortBy(userStatistics, item => [
              item.exercise_statistics[id].executions_number
            ]);
            break;
          case "viewed-revision":
            sortedStatistics = sortBy(userStatistics, item => [
              item.exercise_statistics[id].not_correct_execution
            ]);
            break;
        }
        switch (self.state.orderState) {
          case "asc":
            order = "desc";
            sortedStatistics.reverse();
            break;
          case "desc":
            order = "";
            switch (self.state.filter) {
              case "all":
                sortedStatistics = self.state.originalData.user_statistics;
                break;
              case "review":
                sortedStatistics =
                  self.state.originalFilteredData.user_statistics;
                break;
            }
            break;
          default:
            order = "asc";
            break;
        }
        activityData.user_statistics = sortedStatistics;
        self.setState({
          activityData,
          orderId: id,
          orderType: type,
          orderState: order
        });
      },
      showUserSession(userId, exerciseId, openModal, closeModal) {
        let self = this;
        let exerciseModel = new ExerciseModel({
          id: exerciseId
        });
        let target = "_blank";

        exerciseModel.fetch({
          success() {
            $.ajax({
              type: "GET",
              dataType: "json",
              url: createURL("api_exercises_reports_users_sessions", {
                exercise_id: exerciseId,
                user_id: userId
              }),
              success(executions) {
                let name = "questo";
                let surname = "utente";
                let body = (
                  <div>
                    {executions.map(execution => {
                      return (
                        <EserciziReportsUsersItem
                          exercise={exerciseModel}
                          singleUser
                          targetLink={target}
                          execution={execution}
                          showArchive={false}
                          fromActivity={true}
                        />
                      );
                    })}
                  </div>
                );
                if (executions.length > 0) {
                  name = executions[0].name;
                  surname = executions[0].surname;
                }

                openModal(() => (
                  <ActivityExerciseModal
                    closeModal={closeModal}
                    title={
                      <Translate
                        text="sessioni utente"
                        stringVariables={{
                          name,
                          surname
                        }}
                      />
                    }
                  >
                    {body}
                  </ActivityExerciseModal>
                ));
              },
              error(data) {
                self.setState({ loadingBtn: "" });
                let errorMsg = extractErrorMessage(
                  data,
                  __("impossibile recuperare i dati richiesti")
                );
                showToastError({ str: errorMsg });
              }
            });
          },
          error(data) {
            self.setState({ loadingBtn: "" });
            let errorMsg = extractErrorMessage(
              data,
              __("impossibile recuperare i dati richiesti")
            );
            showToastError({ str: errorMsg });
          }
        });
      },
      render() {
        let self = this;
        let userStatistics = self.state.activityData.user_statistics;
        let exercises = self.state.validExercise;
        let rows = "";
        const { greyTheme } = this.props.theme;
        let header = "";
        let countTotalExercise = exercises.length;
        let exerciseTypeValue = self.state.exerciseType;
        const { ModalStore, SpaceStore } = this.props;
        const { activeSpaceCustomization, hasDecimalGrading } =
          SpaceStore || {};
        const normalizedThreshold =
          (activeSpaceCustomization?.assessmentsCompletionThreshold || 0) /
          (hasDecimalGrading ? 10 : 1);
        let orderSimbol = <Icon icon="minus" width="12" height="12" />;
        let loader = <DoodleLoader theme={greyTheme} isMini />;

        switch (self.state.orderState) {
          case "asc":
            orderSimbol = <Icon icon="chevronLargeUp" width="15" height="15" />;
            break;
          case "desc":
            orderSimbol = (
              <Icon icon="chevronLargeDown" width="15" height="15" />
            );
            break;
          default:
            orderSimbol = <Icon icon="minus" width="12" height="12" />;
            break;
        }

        header = values(
          mapValues(exercises, function (exercise) {
            let thid = `exercise-${exerciseTypeValue}-${exercise.id}`;
            let columnClass = "third";
            let filterAssignment = (
              <TranslatedTooltip tooltipString="order_by_number_of_executions">
                <div
                  className="filter text-center third"
                  onClick={self.setOrder.bind(this, "execution", exercise.id)}
                >
                  {self.state.orderType == "execution" &&
                  self.state.orderId == exercise.id ? (
                    orderSimbol
                  ) : (
                    <Icon icon="minus" width="12" height="12" />
                  )}
                </div>
              </TranslatedTooltip>
            );
            if (self.props.exerciseType != "ASSIGNMENT") {
              columnClass = "half";
              filterAssignment = "";
            }
            return (
              <th id={thid}>
                <div className="full-width">{exercise.title}</div>
                <TranslatedTooltip tooltipString="order_by_best_grade">
                  <div
                    className={`filter text-center ${columnClass}`}
                    onClick={self.setOrder.bind(
                      this,
                      "single-average",
                      exercise.id
                    )}
                  >
                    {self.state.orderType == "single-average" &&
                    self.state.orderId == exercise.id ? (
                      orderSimbol
                    ) : (
                      <Icon icon="minus" width="12" height="12" />
                    )}
                  </div>
                </TranslatedTooltip>
                {filterAssignment}
                <TranslatedTooltip tooltipString="order_by_need_review">
                  <div
                    className={`filter text-center ${columnClass}`}
                    onClick={self.setOrder.bind(
                      this,
                      "viewed-revision",
                      exercise.id
                    )}
                  >
                    {self.state.orderType == "viewed-revision" &&
                    self.state.orderId == exercise.id ? (
                      orderSimbol
                    ) : (
                      <Icon icon="minus" width="12" height="12" />
                    )}
                  </div>
                </TranslatedTooltip>
                <TranslatedTooltip tooltipString="publication_date">
                  <div className="publish-date">
                    {moment.unix(exercise.created_at).format("ll")}
                  </div>
                </TranslatedTooltip>
              </th>
            );
          })
        );
        if (userStatistics.length > 0) {
          rows = userStatistics.map(userRow => {
            let overallClass = "";
            let trackEvent = "green-color";
            let user = userRow.user;
            let exerciseStatistic = userRow.exercise_statistics;
            let averageBox = "";
            let rewiewbox;
            let column = exercises.map(function (exercise) {
              if (isUndefined(exerciseStatistic)) {
                return <td>-</td>;
              }
              if (isUndefined(exerciseStatistic[exercise.id])) {
                return <td>-</td>;
              }
              if (
                exerciseStatistic[exercise.id].executions_number == null ||
                exerciseStatistic[exercise.id].executions_number == 0
              ) {
                return <td>-</td>;
              }
              let exerciseValues = exerciseStatistic[exercise.id];
              let vote =
                Math.round(exerciseValues.best_vote * 100) /
                (hasDecimalGrading ? 10 : 1);
              let voteClass = vote < normalizedThreshold ? "red" : "";
              let voteBox = (
                <div className={["vote-box", voteClass].join(" ")}>
                  {hasDecimalGrading ? vote : `${vote}%`}
                </div>
              );
              let executionNumberBox = "";
              if (exerciseTypeValue == "ASSIGNMENT") {
                executionNumberBox = (
                  <div className="execution-box">
                    ({exerciseValues.executions_number})
                  </div>
                );
              }
              if (exerciseValues.not_correct_execution != null) {
                rewiewbox = (
                  <div className="result-box">
                    <IconsWrapper>
                      <IconContainer>
                        <Icon icon="dangerTriangle" width="12" height="12" />
                      </IconContainer>
                    </IconsWrapper>
                  </div>
                );
              } else if (exerciseValues.quiz_redo_executions) {
                rewiewbox = (
                  <div className="result-box">
                    <IconsWrapper>
                      <IconContainer>
                        <Icon icon="back" width="12" height="12" />
                      </IconContainer>
                    </IconsWrapper>
                  </div>
                );
              } else {
                rewiewbox = null;
              }
              if (exerciseTypeValue == "ASSIGNMENT") {
                return (
                  <td
                    className="quiz-summary"
                    onClick={() =>
                      self.showUserSession(
                        user.id,
                        exercise.id,
                        ModalStore.openModal,
                        ModalStore.closeModal
                      )
                    }
                  >
                    <div className="third">{voteBox}</div>
                    <div className="third">{executionNumberBox}</div>
                    <div className="third">{rewiewbox}</div>
                  </td>
                );
              }
              return (
                <td
                  className="quiz-summary"
                  onClick={() =>
                    self.showUserSession(
                      user.id,
                      exercise.id,
                      ModalStore.openModal,
                      ModalStore.closeModal
                    )
                  }
                >
                  <div className="half">{voteBox}</div>
                  <div className="half">{rewiewbox}</div>
                </td>
              );
            });

            const normalizedAverage = hasDecimalGrading
              ? userRow.average
              : userRow.average * 10;
            const overallClassColor =
              normalizedAverage < normalizedThreshold ? "red" : "";
            overallClass = userRow.countExercise == 0 ? "" : overallClassColor;

            averageBox = (
              <div className="total">
                {hasDecimalGrading
                  ? normalizedAverage
                  : `${normalizedAverage}%`}
              </div>
            );

            if (userRow.countExercise == 0) {
              averageBox = "-";
              trackEvent = "red-color";
            } else if (userRow.countExercise != countTotalExercise) {
              trackEvent = "orange-color";
            }

            return (
              <tr id={userRow.user.id}>
                <td className="user">
                  <strong>{userRow.user.surname}, </strong>
                  {userRow.user.name}
                </td>
                <td className="last-active font-18">
                  <div className={trackEvent}>
                    {userRow.countExercise}/{countTotalExercise}
                  </div>
                </td>
                <td className={["overall-grade", overallClass].join(" ")}>
                  {averageBox}
                </td>
                {column}
              </tr>
            );
          });
        } else {
          rows = (
            <tr>
              <td className="user transparent-color">fake user</td>
              <td colSpan={exercises.length + 2} className="font-18">
                {__("placeholder_activity_nouser")}
              </td>
            </tr>
          );
        }
        if (!self.state.loader) {
          loader = "";
        }

        return (
          <div className="text-center">
            <StyledDropdownWrapper>
              <Dropdown
                theme={greyTheme}
                id="mainExeFilter"
                selectedOptionId={self.state.filter}
                setSelectedOptionId={self.filterExercise}
                optionsList={[
                  { id: "all", label: __("all") },
                  { id: "review", label: __("need_review") }
                ]}
              />
            </StyledDropdownWrapper>
            {loader}
            <div className="scrollable-x-div">
              <table
                className={`table activity-table ${self.props.loading ? "loading" : ""}`}
              >
                <tbody>
                  <tr>
                    <th className="user">placeholder</th>
                    <th>
                      <div className="full-width">{__("on_track")}</div>
                      <TranslatedTooltip tooltipString="order_by_completed_exercises">
                        <div
                          className="filter text-center"
                          onClick={self.setOrder.bind(
                            this,
                            "exercise-completed"
                          )}
                        >
                          {self.state.orderType == "exercise-completed" ? (
                            orderSimbol
                          ) : (
                            <Icon icon="minus" width="12" height="12" />
                          )}
                        </div>
                      </TranslatedTooltip>
                    </th>
                    <th>
                      <div className="full-width">{__("average")}</div>
                      <TranslatedTooltip tooltipString="order_by_average">
                        <div
                          className="filter text-center"
                          onClick={self.setOrder.bind(this, "average")}
                        >
                          {self.state.orderType == "average" ? (
                            orderSimbol
                          ) : (
                            <Icon icon="minus" width="12" height="12" />
                          )}
                        </div>
                      </TranslatedTooltip>
                    </th>
                    {header}
                  </tr>
                  {rows}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    })
  )
);

export default withSpaceStore(withModalStore(QuizActivityTable));
