import { useEffect, useState } from "react";
import { getIsMediaResource } from "src/js/modules/fileFunction";
import { getResourceImage } from "src/js/modules/resourceFunction";
import { fetchResource } from "src/js/repository/resourceRepository";
import { Resource } from "src/js/types";

function useResourceMediaFetcher(resource: Resource) {
  const [mediaPublicUrl, setMediaPublicUrl] = useState<string>(null);
  const { id, fileMimeType, preview } = resource || {};

  const isMediaResource = getIsMediaResource(fileMimeType);

  useEffect(() => {
    if (!isMediaResource) return;
    const imagePreview = getResourceImage({ preview });
    if (imagePreview) {
      setMediaPublicUrl(imagePreview);
      return;
    }
    if (!id) return;
    fetchResource(id)
      .then(res => {
        if (res?.public_url) {
          setMediaPublicUrl(res?.public_url);
        }
      })
      .catch(() => {});
  }, [id, isMediaResource, preview]);

  return {
    isMediaResource,
    mediaPublicUrl
  };
}

export default useResourceMediaFetcher;
