import {
  CtaType,
  WarningBannerProps
} from "src/js/components/layout/WarningBanner/types";

export const warningProps: WarningBannerProps = {
  text: "demo_space_warning_fake_analytics_data_banner_text",
  cta: {
    type: CtaType.SpaceCreation,
    key: "demo_space_warning_fake_analytics_data_banner_cta"
  }
};
