import { Box, Button, Heading, Text } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { showToastError } from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import {
  __LESSONS_EMPTY__,
  __NO_MODULES_MOBILE__
} from "src/js/settings/settingsImage";
import { useTheme } from "styled-components";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import {
  AiCourseGeneratorEvent,
  EventDomain,
  LessonsEvent
} from "src/js/types";
import { useTranslation } from "src/js/translation";
import { navigateTo } from "src/legacy/modules/history";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { S3Video } from "src/js/settings/settingsVideo";
import { FeatureFlag } from "src/js/feature_flag/FeatureFlagProvider";
import { FeatureFlagKey } from "src/js/settings/settingsFeatureFlag";
import { ImportModuleModal } from "../ImportModuleModal";
import { ModuleCreateButton } from "../components/ModuleCreateButton";
import { CREATE_MODULE_TYPE } from "../components/ModuleCreateButton/ModuleCreateButton.types";
import { AICourseWizardModal } from "../AICourseWizardModal";

import * as S from "./BoardsEmptyState.styles";

const BoardsEmptyState = ({ isTeacher }: { isTeacher: boolean }) => {
  const {
    BoardsStore: { addNewBoard },
    GroupStore: { activeGroup },
    ModalStore: { openModal, closeModal },
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();

  const featureFlagKey = FeatureFlagKey.aiCourseGeneration;

  const handleImportModuleClick = () => {
    openModal(() => <ImportModuleModal />);
    _trackEvent(EventDomain.Lessons, LessonsEvent.NewModuleEmptyImport);
  };
  const handleAIWizardClick = () => {
    openModal(() => <AICourseWizardModal />);
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.NewModuleEmptyAi
    );
  };

  const onCreateBoard = () => {
    closeModal();

    const lessonEmptyTitle = translate("board_new_untitled");
    addNewBoard(activeGroup.id, lessonEmptyTitle, {
      draft: true,
      editMode: true
    })
      .then(board => {
        _trackEvent(EventDomain.Lessons, LessonsEvent.NewModuleEmptyNew);
        navigateTo(
          createUrl("module_detail", {
            group_id: activeGroup.id,
            module_id: board.id
          })
        );
      })
      .catch(() => {
        showToastError({
          str: translate("board_creation_failed_error")
        });
      });
  };

  const onOpenMobileDrawer = () => {
    openModal(() => (
      <ModalLayout
        closeModal={closeModal}
        mobileProps={{ withAdaptiveHeight: true, withHeaderLabels: false }}
        labels={{ title: "With adaptive" }}
      >
        <Box padding={20} display="flex" flexDirection="column" gap={8}>
          <ModuleCreateButton
            type={CREATE_MODULE_TYPE.NEW}
            onClick={onCreateBoard}
          />
          <ModuleCreateButton
            type={CREATE_MODULE_TYPE.IMPORT}
            onClick={handleImportModuleClick}
          />

          <FeatureFlag flagKey={featureFlagKey}>
            <ModuleCreateButton
              type={CREATE_MODULE_TYPE.AI}
              onClick={handleAIWizardClick}
            />
          </FeatureFlag>
        </Box>
      </ModalLayout>
    ));
  };

  return !isTeacher ? (
    <S.NoBoardsLayout>
      <S.NoBoardsImageContainer>
        <img src={__LESSONS_EMPTY__} alt="No board" />
      </S.NoBoardsImageContainer>
      <S.NoBoardsInfo>
        <S.NoBoardsInfoTitle>
          <Heading level="3">{translate("boards_empty_title")}</Heading>
        </S.NoBoardsInfoTitle>
      </S.NoBoardsInfo>
    </S.NoBoardsLayout>
  ) : (
    <S.NoBoardsLayout>
      {isLayoutModeMobile ? (
        <S.MobileWrapper>
          <img src={__NO_MODULES_MOBILE__} alt="No modules" />
          <S.MobileMessageWrapper>
            <Heading level="5">
              {translate("modules_empty_mobile_title")}
            </Heading>
            <Text type="body">
              {translate("modules_empty_mobile_subtitle")}
            </Text>
          </S.MobileMessageWrapper>
          <Button
            onClick={onOpenMobileDrawer}
            theme={whiteTheme}
            variant="primary"
          >
            {translate("modules_empty_mobile_btn")}
          </Button>
        </S.MobileWrapper>
      ) : (
        <S.NewModuleCard>
          <S.VideoContainer>
            <VideoPlayer video={S3Video.BoardsNoContent} />
          </S.VideoContainer>
          <S.ActionContainer>
            <Heading level="3">{translate("modules_empty_title")}</Heading>
            <S.DescriptionWrapper>
              <Text type="formFieldEmpty">
                {translate("modules_empty_description")}
              </Text>
            </S.DescriptionWrapper>
            <Box display="flex" flexDirection="column" gap={12}>
              <ModuleCreateButton
                type={CREATE_MODULE_TYPE.NEW}
                onClick={onCreateBoard}
              />
              <ModuleCreateButton
                type={CREATE_MODULE_TYPE.IMPORT}
                onClick={handleImportModuleClick}
              />
              <FeatureFlag flagKey={featureFlagKey}>
                <ModuleCreateButton
                  type={CREATE_MODULE_TYPE.AI}
                  onClick={handleAIWizardClick}
                />
              </FeatureFlag>
            </Box>
          </S.ActionContainer>
        </S.NewModuleCard>
      )}
    </S.NoBoardsLayout>
  );
};

export default observer(BoardsEmptyState);
