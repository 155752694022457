import { LiveTagThemeOptions, Palette } from "@ds_themes/types";

export const createLivetagTheme = ({
  grey
}: Palette): { livetag: LiveTagThemeOptions } => {
  return {
    livetag: {
      background: grey[50],
      border: `1px solid ${grey[50]}`,
      text: grey[1000],
      finished: {
        background: grey[1000],
        border: `1px solid ${grey[300]}`,
        text: grey[300]
      }
    }
  };
};
