import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Heading, Icon, Text } from "@arcadia/design-system";
import { AnimatePresence, motion } from "framer-motion";
import { useTheme } from "styled-components";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { observer } from "mobx-react";
import { AITransactionStatus } from "src/js/stores/AIStore";
import { navigateTo } from "src/legacy/modules/history";
import {
  AICourseGenFail,
  AICourseGenSuccess
} from "src/js/types/models/WebSocket";
import createUrl from "src/js/modules/routing";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiCourseGeneratorEvent, EventDomain } from "src/js/types";
import DraftingCourse from "Images/lottie/ai-course.json";
import DraftingContent from "Images/lottie/ai-content.json";
import {
  __AI_CONTENT_SUCCESS_PLACEHOLDER__,
  __AI_CONTENT_ERROR_PLACEHOLDER__
} from "src/js/settings/settingsImage";
import { DRAFTING_TYPE, DraftingType } from "./Drafting.types";

import * as S from "./Drafting.styles";

const fadeIn = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, transition: { duration: 0.5 } }
};

const motionProps = {
  variants: fadeIn,
  initial: "hidden",
  animate: "show",
  exit: "exit"
};

const Drafting = ({ type }: { type: DraftingType }) => {
  const { whiteTheme } = useTheme();
  const [error, setError] = useState<string>();
  const {
    UIStore: { isLayoutModeMobile },
    AIStore: { status, data },
    ModalStore: { setCustomCloseCb },
    GroupStore: {
      activeGroup: { id: groupId }
    },
    ModalStore: { closeModal },
    BoardsStore: { fetchBoardsList }
  } = useStores();
  const { translate } = useTranslation();
  const [boardId, setBoardId] = useState(null);

  const animation = useMemo(() => {
    switch (type) {
      case DRAFTING_TYPE.CONTENT:
        return DraftingContent;
      case DRAFTING_TYPE.COURSE:
        return DraftingCourse;
      default:
        return DraftingCourse;
    }
  }, [type]);

  const handleViewPath = useCallback(() => {
    closeModal();
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardPreviewViewPath
    );
  }, [closeModal]);

  useEffect(() => {
    if (
      type === DRAFTING_TYPE.CONTENT &&
      status !== AITransactionStatus.pending
    ) {
      setCustomCloseCb(null);
    }
  }, [type, status]);

  useEffect(() => {
    if (status !== AITransactionStatus.success) return;
    const { boardId: createdBoardId } = data as AICourseGenSuccess["data"];
    fetchBoardsList({ groupId, isPublished: false }).then(() => {
      setBoardId(createdBoardId);
    });
  }, [status, data]);

  useEffect(() => {
    if (status !== AITransactionStatus.fail) return;
    const { aiTransactionId } = data as AICourseGenFail["data"];
    setError(aiTransactionId);
  }, [status, data]);

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {error ? (
          <S.AnimationsWrapper key="error">
            <S.ImageWrapper {...motionProps}>
              <img
                src={__AI_CONTENT_ERROR_PLACEHOLDER__}
                alt="upload-error-placeholder"
              />
            </S.ImageWrapper>
          </S.AnimationsWrapper>
        ) : (
          <S.AnimationsWrapper key={boardId ? "lottie" : "image"}>
            {boardId ? (
              <S.ImageWrapper {...motionProps}>
                <img
                  src={__AI_CONTENT_SUCCESS_PLACEHOLDER__}
                  alt="upload-success-placeholder"
                />
              </S.ImageWrapper>
            ) : (
              <motion.div {...motionProps}>
                <S.LottieContainer src={animation} />
              </motion.div>
            )}
          </S.AnimationsWrapper>
        )}
      </AnimatePresence>
      <S.TitleWrapper>
        {!error && <Icon icon="sparkles" width={20} height={20} />}
        <Heading level="5">
          {error
            ? translate("ai_course_gen_error_title")
            : translate(
                // eslint-disable-next-line no-nested-ternary
                boardId
                  ? "ai_drafting_course_ready_title"
                  : type === DRAFTING_TYPE.CONTENT
                    ? "ai_drafting_contents_title"
                    : "ai_drafting_course_title"
              )}
        </Heading>
      </S.TitleWrapper>
      <S.DescriptionWrapper>
        <Text type="formSmallCaption">
          {error
            ? `ID: ${error}`
            : translate(
                boardId
                  ? "ai_drafting_course_ready_subtitle"
                  : "ai_drafting_subtitle"
              )}
        </Text>
      </S.DescriptionWrapper>
      {type === DRAFTING_TYPE.CONTENT && !error && (
        <S.ButtonWrapper isMobile={isLayoutModeMobile}>
          <S.ButtonContainer isMobile={isLayoutModeMobile}>
            <Button
              onClick={handleViewPath}
              fullWidth
              theme={whiteTheme}
              variant="secondary"
              disabled={!boardId}
            >
              {translate("ai_drafting_btn_path")}
            </Button>
          </S.ButtonContainer>
          <S.ButtonContainer isMobile={isLayoutModeMobile}>
            <Button
              onClick={() => {
                navigateTo(
                  createUrl("module_detail", {
                    group_id: groupId,
                    module_id: boardId
                  })
                );
                closeModal();
                _trackEvent(
                  EventDomain.AiCourseGenerator,
                  AiCourseGeneratorEvent.AiWizardPreviewJumpContent
                );
              }}
              fullWidth
              theme={whiteTheme}
              variant="primary"
              disabled={!boardId}
            >
              {translate("ai_drafting_btn_contents")}
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      )}
    </>
  );
};

export default observer(Drafting);
