/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction } from "mobx";
import { createLocalData, readLocalData } from "../modules/storageFunction";
import { getWebSocketAuthToken } from "../repository/webSocketRepository";
import { WS_ENDPOINT } from "../settings/settingsWebSocket";
import {
  AuthToken,
  AuthTokenResponse,
  WebSocketSendError
} from "../types/models/WebSocket";
import UserStore from "./UserStore";

const __MAX_RECONNECTION_ATTEMPTS__ = 5;
const __RECONNECTION_DELAY__ = 2000;

/**
 * @name WebSocketStore
 *
 * @description WebSocket connection and logics
 */
class WebSocketStore {
  private $socketUrl: string;
  private $socket: WebSocket = null;
  private $authToken: AuthToken;
  userStore: UserStore;

  private $isReconnecting: boolean = false;
  private $reconnectionAttempts = 0;
  private $reconnectionDelayMs = 1;

  private $socketOpenListener: (event: CloseEvent) => void;
  private $socketCloseListener: (event: CloseEvent) => void;
  private $socketErrorListener: (event: Event) => void;
  private $visibilityChangeListener: (event: Event) => void;
  private $onlineListener: (event: Event) => void;

  constructor({ userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.$socketUrl = WS_ENDPOINT;
    this.$authToken = readLocalData("WSAuthToken") || null;
    this.userStore = userStore;
    this.reactOnActiveUserChange();
  }

  refreshToken = async () => {
    if (!this.tokenIsExpired()) return;
    const authJWT = (await getWebSocketAuthToken()) as AuthTokenResponse;
    createLocalData(
      "WSAuthToken",
      authJWT.data.jwt,
      null,
      authJWT.data.expiresAt
    );
    this.setAuthToken({
      value: authJWT.data.jwt,
      expiresAt: authJWT.data.expiresAt
    } as AuthToken);
  };

  startConnection = () => {
    if (!this.$authToken) throw new Error("Auth token is not set");
    const ws = new WebSocket(
      `${this.$socketUrl}?auth=${this.$authToken?.value}`
    );
    this.setSocket(ws);
    this.addSocketEventListeners();
  };

  closeConnection = () => {
    this.removeSocketEventListeners();
    if (this.socket) {
      this.socket.close();
      this.setSocket(null);
    }
  };

  initConnection = async () => {
    this.closeConnection();
    await this.refreshToken();
    this.startConnection();
  };

  isConnected = () => {
    return (
      this.socket?.readyState === WebSocket.OPEN && window.navigator.onLine
    );
  };

  attemptConnection = async () => {
    if (!this.isConnected() && !this.$isReconnecting) {
      try {
        await this.initConnection();
      } catch (error) {
        this.handleReconnection();
      }
    }
  };

  resetReconnection = () => {
    this.$isReconnecting = false;
    this.$reconnectionAttempts = 0;
    this.$reconnectionDelayMs = 1;
  };

  handleReconnection = async () => {
    this.$isReconnecting = true;
    this.$reconnectionAttempts += 1;
    this.$reconnectionDelayMs =
      this.$reconnectionAttempts * __RECONNECTION_DELAY__;

    if (this.$reconnectionAttempts >= __MAX_RECONNECTION_ATTEMPTS__) {
      console.error("Maximum reconnection attempts reached. Giving up.");
      this.resetReconnection();
      return;
    }

    await new Promise(resolve => {
      setTimeout(resolve, this.$reconnectionDelayMs);
    });
    try {
      await this.initConnection();
    } catch (error) {
      this.handleReconnection();
    }
  };

  addSocketEventListeners = () => {
    this.$socketOpenListener = this.resetReconnection;
    this.$socketCloseListener = () => {
      this.removeSocketEventListeners();
      if (document?.visibilityState === "visible") {
        this.attemptConnection();
      }
    };
    this.$socketErrorListener = () => {
      this.removeSocketEventListeners();
      if (document?.visibilityState === "visible") {
        this.handleReconnection();
      }
    };

    this.socket.addEventListener("open", this.$socketOpenListener);
    this.socket.addEventListener("close", this.$socketCloseListener);
    this.socket.addEventListener("error", this.$socketErrorListener);
  };

  removeSocketEventListeners = () => {
    if (this.socket) {
      this.socket.removeEventListener("open", this.$socketOpenListener);
      this.socket.removeEventListener("close", this.$socketCloseListener);
      this.socket.removeEventListener("error", this.$socketErrorListener);
    }
  };

  addDOMEventListeners = () => {
    this.removeDOMEventListeners();
    this.$visibilityChangeListener = () => {
      if (document?.visibilityState === "visible") {
        this.attemptConnection();
      }
    };
    document.addEventListener(
      "visibilitychange",
      this.$visibilityChangeListener
    );
    this.$onlineListener = this.attemptConnection;
    window.addEventListener("online", this.$onlineListener);
  };

  removeDOMEventListeners = () => {
    document.removeEventListener(
      "visibilitychange",
      this.$visibilityChangeListener
    );
    window.removeEventListener("online", this.$onlineListener);
  };

  sendMessage = async message => {
    await this.attemptConnection();
    if (!this.isConnected()) {
      throw WebSocketSendError.SocketNotReady;
    } else if (message === undefined) {
      throw WebSocketSendError.MessageNotValid;
    }
    this.socket.send(JSON.stringify(message));
  };

  tokenIsExpired = () => {
    const authTokenExpiresAt = new Date(this.$authToken?.expiresAt);
    if (Number.isNaN(authTokenExpiresAt.getTime())) {
      return true;
    }
    const now = new Date();
    return now.getTime() >= authTokenExpiresAt.getTime();
  };

  setSocket = (socket: WebSocket) => {
    this.$socket = socket;
  };

  setAuthToken = (token: AuthToken) => {
    this.$authToken = token;
  };

  get socketUrl() {
    return this.$socketUrl;
  }

  get socket() {
    return this.$socket;
  }

  get authToken() {
    return this.$authToken;
  }

  reactOnActiveUserChange = () => {
    reaction(
      () => this.userStore?.activeUser,
      activeUser => {
        const userId = activeUser?.id;
        if (!userId) {
          this.closeConnection();
          this.removeDOMEventListeners();
          return;
        }
        try {
          this.attemptConnection();
          this.addDOMEventListeners();
        } catch (error) {
          console.log({ webSocketStoreConnectionError: error });
        }
      }
    );
  };
}

export default WebSocketStore;
