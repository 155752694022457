import React from "react";
import imagePlaceholder from "Images/placeholders/img.png";
import ImagePreviewer from "Legacy/components/imagePreviewer";
import { ActionButton, Button } from "@arcadia/design-system";
import { __ } from "Legacy/modules/localization";
import { useTheme } from "styled-components";

class ResourceImageGalleryPreviewElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false
    };

    this.deleteElement = this.deleteElement.bind(this);
    this.changeEditMode = this.changeEditMode.bind(this);
  }

  deleteElement() {
    const { deleteFunction, image } = this.props;
    deleteFunction(image);
  }

  changeEditMode() {
    const { editMode } = this.state;
    this.setState({
      editMode: !editMode
    });
  }

  render() {
    const { image, theme } = this.props;
    const { editMode } = this.state;
    const previewUrl = image.id ? image.public_url : URL.createObjectURL(image);
    const { whiteTheme } = theme;
    return (
      <div
        className="resource-gallery-slot col-xs-6 col-sm-4 col-lg-4"
        key={`resource-gallery-${image.id}`}
      >
        <div
          className="resource-gallery-preview-element squared-box"
          onClick={this.changeEditMode}
          key={`resource-gallery-image-${image.id}`}
        >
          <ImagePreviewer
            className="board-detail-resource-bg resource-gallery-slot-image"
            src={previewUrl}
            type="DIV"
            is_preview_generated="false"
            placeholder={imagePlaceholder}
            background="#37425b"
          />
          {editMode && (
            <div
              className="resource__selected-overlay"
              onClick={this.changeEditMode}
            >
              <div className="resource__selected-overlay-btn">
                <Button
                  onClick={this.deleteElement}
                  theme={whiteTheme}
                  variant="secondary"
                >
                  {__("elimina")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const ResourceImageGalleryPreview = props => {
  const theme = useTheme();
  const { deleteFunction, addFunction, images } = props;

  const list = images.map(image => (
    <ResourceImageGalleryPreviewElement
      deleteFunction={deleteFunction}
      image={image}
      key={`galleryElement-${image.id}`}
      theme={theme}
    />
  ));
  return (
    <div className="gallery-tile-container margin-top-15" id="tileContainer">
      {list}
      <div className="resource-gallery-slot col-xs-6 col-sm-4 col-lg-4">
        <div className="resource-gallery-preview-element flex-centered-column flex-centered-row">
          <ActionButton
            icon="plus"
            theme={theme.whiteTheme}
            variant="secondary"
            onClick={addFunction}
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceImageGalleryPreview;
