import { fetchExerciseEntities } from "src/js/repository/exerciseRepository";
import { UseQueryOptions, useQuery } from "src/js/query/hooks";
import { OldExercise } from "src/js/types";
import { exercisesQueryKey } from "./exercises.query";

export const useExerciseByIdV1 = (
  exerciseId: string,
  reportBy: "best" | "last",
  options?: UseQueryOptions<OldExercise, Error, OldExercise>
) => {
  const { data: exercise, ...otherData } = useQuery(
    exercisesQueryKey.exerciseIdV1({ exerciseId, reportBy }),
    () => fetchExerciseEntities({ exerciseId, report_by: reportBy }),
    options
  );

  return {
    data: exercise,
    ...otherData
  };
};
