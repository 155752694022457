import styled, { css } from "styled-components";

export const ResourceViewerWrap = styled.div`
  height: 100vh;
  width: 100vw;
  height: 100dvh;
  width: 100dvw;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
`;

export const StyledHeader = styled.div<{ backgroundColor: string }>`
  height: 48px;
  overflow: hidden;
  display: block;
  background: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
`;

export const BaseStyle = css`
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.violet[300]};
  background-color: ${({ theme }) => theme.colors.violet[800]};
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.violet[300]};
  }
`;

export const StyledOptionButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-right: 12px;
  align-self: center;
  height: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BaseStyle}
`;

export const ResourceWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.coldGrey[900]};
`;

export const StyledTextWrapper = styled.div`
  font-size: 16px;
  color: ${({ color }) => color};
  line-height: 48px;
  padding: 0 12px;
  height: 48px;
  display: flex;
`;
