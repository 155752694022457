import React from "react";
import { useTheme } from "styled-components";
import { EventCard } from "src/js/components/EventCard";
import { Translate } from "src/js/translation/TranslationProvider";
import { ActionButton, Heading, Link, Icon } from "@arcadia/design-system";
import * as S from "./EventsListPreview.styles";

const EventsListPreview = ({
  events = [],
  newEvent = () => {},
  calendarLink = "",
  userIsTeacher = false,
  groupId
}) => {
  const { whiteTheme } = useTheme();
  return (
    <S.Container>
      <S.ListHeader>
        <S.FlexDescriptionHeader>
          <Heading level="4">
            <Translate text="wall_event_list_title" />
          </Heading>
          {events && events.length > 0 && (
            <S.WrapperLink>
              <Link href={calendarLink} theme={whiteTheme}>
                <Translate text="wall_event_list_show_all" />
              </Link>
            </S.WrapperLink>
          )}
        </S.FlexDescriptionHeader>
        {userIsTeacher && (
          <ActionButton
            onClick={() => newEvent()}
            theme={whiteTheme}
            variant="primary"
            icon="plus"
          />
        )}
      </S.ListHeader>
      {events &&
        events.map(({ id, type, title, lastAllDay, start, end, liveUrl }) => {
          return (
            <S.EventCardWrapper key={id}>
              <EventCard
                id={id}
                type={type}
                title={title}
                allDay={lastAllDay}
                start={start}
                end={end}
                liveUrl={liveUrl}
                accordionMode={false}
                groupId={groupId}
              />
            </S.EventCardWrapper>
          );
        })}
      {(!events || events.length === 0) && (
        <S.EmptyCard>
          <Icon
            initialViewbox
            icon="illustration-draw"
            width="70"
            height="70"
          />
          <Translate text="wall_event_list_empty" />
        </S.EmptyCard>
      )}
    </S.Container>
  );
};

export default EventsListPreview;
