import { ObjectValues } from "src/js/types";

export const DurationPeriod = {
  ONE_MONTH: "P0Y1M",
  TWO_MONTHS: "P0Y2M",
  SIX_MONTHS: "P0Y6M",
  ONE_YEAR: "P1Y0M",
  TWO_YEARS: "P2Y0M",
  FOUR_YEARS: "P4Y0M",
  SIX_YEARS: "P6Y0M"
} as const;

export type DurationPeriodType = ObjectValues<typeof DurationPeriod>;
