import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { find, findIndex } from "lodash";
import { observer } from "mobx-react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Board } from "src/js/components/Board";
import { DrawerEditBoard } from "src/js/components/DrawerEditBoard";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useDeviceDetector, useStores } from "src/js/hooks";
// eslint-disable-next-line import/no-named-as-default
import createUrl from "src/js/modules/routing";
import { BoardsEmptyState } from "src/js/pages/boards/BoardsEmptyState";
import { ModuleCreateDropdown } from "src/js/pages/boards/components/ModuleCreateDropdown";
import { useTranslation } from "src/js/translation/TranslationProvider";
// eslint-disable-next-line no-restricted-imports
import { navigateTo } from "src/legacy/modules/history";
// eslint-disable-next-line no-restricted-imports
import { Box, DoodleLoader, Heading, Text } from "@arcadia/design-system";
import { BoardPath } from "src/js/components/BoardPath";
import { FilterBy } from "src/js/components/FilterBy";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { basePageModel } from "src/legacy/modules/pageStatus";
import { useTheme } from "styled-components";
import BoardPathWrapper from "./BoardPathWrapper";
import * as S from "./Boards.styles";
import { ImportCreateModuleMobile } from "./ImportCreateModuleMobile";
import { useBoardFetch } from "./useBoardFetch";

const Item = forwardRef(({ boardItem, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <Board
        {...boardItem}
        isTeacher
        key={`board-item-overlay-${boardItem.id}`}
      />
    </div>
  );
});

Item.displayName = "Item";

const boardDetailNavigation = ({ boardId: board_id, groupId: group_id }) => {
  navigateTo(
    createUrl("module_detail", {
      group_id,
      module_id: board_id
    })
  );
};

const SortableBoardItem = observer(
  ({
    boardItem,
    index,
    setDrawerOpen,
    groupId,
    setSelectedBoard,
    isActive
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({ id: boardItem.id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition
    };

    // Placeholder item when dragging
    if (isDragging) {
      return (
        <S.PlaceholderBoardItem
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          data-boardid={boardItem.id}
          key={`boardItem-dragged-${boardItem.id}`}
        />
      );
    }

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        data-boardid={boardItem.id}
        key={`boardItemWrapper-${boardItem.id}`}
      >
        <Board
          {...boardItem}
          index={index}
          isTeacher
          isActive={isActive}
          mainActionClick={() => {
            setDrawerOpen(prev => !prev);
            setSelectedBoard({ id: boardItem.id });
          }}
          layoutClick={() =>
            boardDetailNavigation({ boardId: boardItem.id, groupId })
          }
        />
      </div>
    );
  }
);

const Boards = ({ target = "boards" }) => {
  const {
    BoardsStore: { getBoardsLists, fetchBoardsList, moveBoardElement },
    GroupStore: { activeGroup: group, userIsTeacher: isTeacher, settings },
    UIStore: { isLayoutModeMobile, isSideBarOpen },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const [activeId, setActiveId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isNewModuleSheetOpen, setNewModuleSheetOpen] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState({
    id: null,
    name: null
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");

  useEffect(() => {
    if (isSideBarOpen) setDrawerOpen(false);
  }, [isSideBarOpen]);

  const deviceType = useDeviceDetector();
  const deviceIsMobile = deviceType.isSmartphone || deviceType.isTablet;
  const BoardsPageRef = useRef();
  const { greyTheme, whiteTheme } = useTheme();
  const boardObj = getBoardsLists[group?.id];
  const boardList = boardObj?.results;
  const status = boardObj?.status;
  const hasNext = boardObj?.hasNext;
  const [showPathBoard, setShowPathBoard] = useState(false);
  const { UserTasksStore } = useStores();
  const { translate } = useTranslation();
  const { handleFilterChange, showNext } = useBoardFetch({
    setSelectedFilter,
    groupId: group.id,
    selectedFilter
  });
  const { tasksAreaIsVisible, incompletedTasksList } = UserTasksStore;

  useEffect(() => {
    fetchBoardsList({ groupId: group.id });
    basePageModel.set("selectedTab", "boards");
  }, [group]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 200
      }
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 500,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const openBoardPathModal = () => {
    openModal(() => (
      <ModalLayout
        closeModal={closeModal}
        mobileProps={{
          withAdaptiveHeight: true,
          withHeaderLabels: false
        }}
      >
        <S.ModalLayoutContent>
          <BoardPath
            id={selectedBoard.id}
            name={selectedBoard.name}
            closeFunction={e => {
              e.stopPropagation();
              setShowPathBoard(false);
            }}
          />
        </S.ModalLayoutContent>
      </ModalLayout>
    ));
  };

  const boardsTemplate = () => {
    return boardList.map(boardItem => {
      if (isTeacher && target !== "drafts") {
        return (
          <SortableBoardItem
            key={`sortable-board-item-${boardItem.id}`}
            boardItem={boardItem}
            id={boardItem.id}
            setDrawerOpen={setDrawerOpen}
            groupId={group.id}
            isActive={drawerOpen && selectedBoard.id === boardItem.id}
            setSelectedBoard={setSelectedBoard}
          />
        );
      }
      return (
        <Board
          {...boardItem}
          isTeacher={false}
          key={`board-item-${boardItem.id}`}
          layoutClick={
            boardItem.isLocked && !isTeacher
              ? () => {
                  setSelectedBoard({
                    id: boardItem.id,
                    name: boardItem.name
                  });
                  if (deviceIsMobile) {
                    openBoardPathModal();
                    return;
                  }
                  setShowPathBoard(true);
                }
              : () =>
                  boardDetailNavigation({
                    boardId: boardItem.id,
                    groupId: group.id
                  })
          }
          mainActionClick={setSelectedBoard}
        />
      );
    });
  };

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(boardList, { id: active.id });
      const newIndex = findIndex(boardList, { id: over.id });

      moveBoardElement(newIndex, oldIndex, group.id);
    }

    setActiveId(null);
  };

  const initialOptions = [
    {
      label: translate({ text: "boards_filter_dropdown_option_all" }),
      value: "ALL"
    },
    {
      label: translate({ text: "boards_filter_dropdown_option_published" }),
      value: "isPublished"
    },
    {
      label: translate({ text: "boards_filter_dropdown_option_drafts" }),
      value: "isDraft"
    }
  ];

  return (
    <>
      <S.MainLayoutBoards drawerOpen={drawerOpen}>
        <MainContainer paddingTop="126px">
          <div>
            <DrawerEditBoard
              boardId={selectedBoard.id}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
            {status === "pending" && <S.StyledDoodleLoader theme={greyTheme} />}
            {status === "success" &&
              (!boardList || boardList.length === 0) &&
              selectedFilter === "ALL" && (
                <BoardsEmptyState isTeacher={isTeacher} />
              )}
            {status === "success" &&
              boardList.length === 0 &&
              selectedFilter !== "ALL" && (
                <S.ContentWrapper ref={BoardsPageRef}>
                  <S.BoardsContainer>
                    {settings?.createBoard !== "disabled" &&
                      isTeacher &&
                      !isLayoutModeMobile && <ModuleCreateDropdown />}
                    <S.BoardsListHeader>
                      {isTeacher && (
                        <FilterBy
                          isVisible={isFilterVisible}
                          setIsVisible={setIsFilterVisible}
                          selectedOption={selectedFilter}
                          setSelectedOption={handleFilterChange}
                          initialOptions={initialOptions}
                        />
                      )}
                    </S.BoardsListHeader>
                    <S.LottieContainer>
                      <S.LottieAnimation />
                      <Heading level="5">
                        {translate("not_found_module_filter_title")}
                      </Heading>
                      <Box textAlign="center" marginTop={8}>
                        <Text type="body">
                          {translate("not_found_module_filter_message")}
                        </Text>
                      </Box>
                    </S.LottieContainer>
                  </S.BoardsContainer>
                </S.ContentWrapper>
              )}
            {boardList && boardList.length > 0 && (
              <S.ContentWrapper ref={BoardsPageRef}>
                <S.BoardsContainer>
                  {settings?.createBoard !== "disabled" &&
                    isTeacher &&
                    !isLayoutModeMobile && <ModuleCreateDropdown />}

                  <S.BoardsListHeader>
                    {isTeacher && (
                      <FilterBy
                        isVisible={isFilterVisible}
                        setIsVisible={setIsFilterVisible}
                        selectedOption={selectedFilter}
                        setSelectedOption={handleFilterChange}
                        initialOptions={initialOptions}
                      />
                    )}
                  </S.BoardsListHeader>
                  <S.BoardsList>
                    <DndContext
                      sensors={sensors}
                      autoScroll
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      collisionDetection={closestCenter}
                    >
                      <InfiniteScroll
                        scrollableTarget="board-list"
                        dataLength={boardList.length}
                        next={showNext}
                        hasMore={hasNext}
                        style={{ overflow: "visible" }}
                        loader={
                          <S.LoaderWrapper>
                            <DoodleLoader isMini theme={greyTheme} />
                          </S.LoaderWrapper>
                        }
                      >
                        {showPathBoard && !isLayoutModeMobile ? (
                          <BoardPathWrapper
                            setShowPathBoard={setShowPathBoard}
                            tasksAreaIsVisible={tasksAreaIsVisible}
                            incompletedTasksList={incompletedTasksList}
                            selectedBoard={selectedBoard}
                          />
                        ) : null}
                        <SortableContext
                          items={boardList}
                          strategy={verticalListSortingStrategy}
                        >
                          {boardsTemplate()}
                        </SortableContext>
                        {isTeacher && (
                          <S.StyledFabButton
                            theme={whiteTheme}
                            variant="primary"
                            icon="plus"
                            onClick={() => setNewModuleSheetOpen(true)}
                          />
                        )}
                      </InfiniteScroll>
                      <DragOverlay>
                        {activeId ? (
                          <Item
                            boardItem={find(boardList, { id: activeId })}
                            id={activeId}
                          />
                        ) : null}
                      </DragOverlay>
                    </DndContext>
                    {!isTeacher && <S.BoardsProgressRecap groupId={group.id} />}
                  </S.BoardsList>
                </S.BoardsContainer>
              </S.ContentWrapper>
            )}
          </div>
        </MainContainer>
      </S.MainLayoutBoards>
      <ImportCreateModuleMobile
        isOpen={isNewModuleSheetOpen}
        setImportCreateModalSheetOpen={setNewModuleSheetOpen}
      />
    </>
  );
};

export default observer(Boards);
