import React from "react";
import { useStores } from "src/js/hooks";
import { observer } from "mobx-react";
import { navigateTo } from "src/legacy/modules/history";
import { useRouteMatch } from "react-router";
import { createUrl } from "src/js/modules/routing";
import { useTranslation } from "src/js/translation";

import * as S from "./DashboardSecondaryHeaderTabs.styles";

const DashboardSecondaryHeaderTabs = () => {
  const {
    UIStore: { isLayoutModeMobile },
    SpaceStore: { activeSpaceId: space_id }
  } = useStores();
  const { translate } = useTranslation();

  const groupsSelected = useRouteMatch("/dashboard/:space_id/groups");
  const analyticsSelected = useRouteMatch("/dashboard/:space_id/analytics");
  const usersSelected = useRouteMatch("/dashboard/:space_id/users");
  const badgesSelected = useRouteMatch("/dashboard/:space_id/badges");

  return (
    <S.TabWrapper>
      <S.Tab
        isLayoutModeMobile={isLayoutModeMobile}
        id="spaceTabAnalytics"
        active={!!analyticsSelected}
        onClick={() =>
          navigateTo(createUrl("dashboard_analytics", { space_id }))
        }
      >
        {translate("analytics_header_tab")}
        {!!analyticsSelected && (
          <S.Underline
            isLayoutModeMobile={isLayoutModeMobile}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            exit={{ scaleX: 0 }}
          />
        )}
      </S.Tab>
      <S.Tab
        isLayoutModeMobile={isLayoutModeMobile}
        id="spaceTabGroups"
        active={!!groupsSelected}
        onClick={() => navigateTo(createUrl("dashboard_groups", { space_id }))}
      >
        {translate("groups")}
        {!!groupsSelected && (
          <S.Underline
            isLayoutModeMobile={isLayoutModeMobile}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            exit={{ scaleX: 0 }}
          />
        )}
      </S.Tab>
      <S.Tab
        isLayoutModeMobile={isLayoutModeMobile}
        id="spaceTabUsers"
        active={!!usersSelected}
        onClick={() => navigateTo(createUrl("dashboard_users", { space_id }))}
      >
        {translate("users")}
        {!!usersSelected && (
          <S.Underline
            isLayoutModeMobile={isLayoutModeMobile}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            exit={{ scaleX: 0 }}
          />
        )}
      </S.Tab>
      <S.Tab
        isLayoutModeMobile={isLayoutModeMobile}
        id="spaceTabBadges"
        active={!!badgesSelected}
        onClick={() => navigateTo(createUrl("dashboard_badges", { space_id }))}
      >
        {translate("badges_header_tab")}
        {!!badgesSelected && (
          <S.Underline
            isLayoutModeMobile={isLayoutModeMobile}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            exit={{ scaleX: 0 }}
          />
        )}
      </S.Tab>
    </S.TabWrapper>
  );
};

export default observer(DashboardSecondaryHeaderTabs);
