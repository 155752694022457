import { Icon, Text } from "@arcadia/design-system";
import React from "react";
import { SelectableListEmptyStatusType } from "src/js/components/layout/SelectableList";
import { useTranslation } from "src/js/translation";
import * as S from "../AddExerciseResource.styles";

const EmptyState = ({ status }: { status: SelectableListEmptyStatusType }) => {
  const { translate } = useTranslation();

  return (
    <S.EmptyStateWrapper>
      <S.IllustrationContainer>
        <Icon
          icon="illustration-ghost"
          height={130}
          width={98}
          initialViewbox
        />
      </S.IllustrationContainer>
      {status === "empty-search" ? (
        <Text>{translate("add_exercise_resource_empty_search")}</Text>
      ) : (
        <Text>{translate("add_exercise_resource_empty_list")}</Text>
      )}
    </S.EmptyStateWrapper>
  );
};

export default EmptyState;
