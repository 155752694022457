import { VariantType, scrollbarMixin } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";
import { SpaceTrialCard } from "../../SpaceTrialCard";

export const StyledSpaceTrialCard = styled(SpaceTrialCard)`
  display: none;
  margin-top: 12px;
  @media (max-width: 1700px) {
    display: initial;
  }
`;

export const SidebarFooter = styled.div`
  &:not(:empty) {
    padding: 12px;
  }
`;
export const SidebarContent = styled.div`
  height: 100%;
  overflow: auto;
  padding: 6px 8px;
  ${scrollbarMixin(VariantType.White)}
`;

export const SidebarWrapper = styled(motion.div)<{
  isDemoSpaceTopBannerVisible: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  font-family: ${({ theme }) => theme.fontFamily.title};
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${({ theme }) => theme.colors.grey[50]};
  min-width: ${({ theme }) => theme.sizes.mainSidebarWidth.xl};
  width: ${({ theme }) => theme.sizes.mainSidebarWidth.xl};
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  border-right: 1px solid ${({ theme }) => theme.primaryColor[900]};
  left: ${({ theme }) => `-${theme.sizes.mainSidebarWidth.xl}`};

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    min-width: ${({ theme }) => theme.sizes.mainSidebarWidth.lg};
    width: ${({ theme }) => theme.sizes.mainSidebarWidth.lg};
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    min-width: ${({ theme }) => theme.sizes.mainSidebarWidth.md};
    width: ${({ theme }) => theme.sizes.mainSidebarWidth.md};
  }

  @media screen and (max-width: 767px) {
    min-width: 100%;
    width: 100%;
    overflow-y: auto;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const DashboardLinkWrapper = styled.button`
  all: unset;
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.coldGrey[900]};
  }
`;
