import { shadow } from "@arcadia/design-system";
import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const ConfirmDialogOverlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  display: flex;
  min-height: 100vh;
  background: ${({ theme }) => theme.background.semiTransparent};
  z-index: 9999;
`;

export const ConfirmDialogContainer = styled.div`
  max-height: calc(100% - 30px);
  margin: 0 auto;
  border-radius: 8px;
  position: fixed;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  background-color: #ffffff;
  z-index: 9999;
  box-shadow: ${shadow[400]};

  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
  }
`;
