import { Location } from "history";
import { UserSettingsRouteOption } from "../settings/types";

export const getPath = (location: Location) => {
  const hash = location.pathname;
  const paths = hash && hash.split("/");
  const path = paths && paths.length && paths[paths.length - 1];

  return path as UserSettingsRouteOption;
};
