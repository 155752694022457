import axios, { AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { commonRefreshToken } from "src/js/modules/commonRefreshToken";
import { __API_UNVERSIONED_PATH__ } from "src/js/settings/settingsUrl";
import { navigateTo } from "src/legacy/modules/history";

const refreshAuthLogic = () => {
  return commonRefreshToken()
    .catch(() => {
      navigateTo("/logout");
    })
    .then(status => {
      return status;
    });
};

const unloggedUserLogic = () => {
  return commonRefreshToken()
    .catch(() => {}) // we don't want to send to sentry unhandled errors about user fetch
    .then(status => {
      return status;
    });
};

function addTokenInterceptor(clientParams) {
  return clientParams;
}

const client = createAuthRefreshInterceptor(
  addTokenInterceptor(
    axios.create({
      withCredentials: true,
      headers: {
        common: {
          "Cache-Control": "no-cache"
        }
      }
    })
  ),
  refreshAuthLogic
);

const unloggedClient = createAuthRefreshInterceptor(
  addTokenInterceptor(
    axios.create({
      withCredentials: true,
      headers: {
        common: {
          "Cache-Control": "no-cache"
        }
      }
    })
  ),
  unloggedUserLogic
);

const getUserClient = () => {
  client.interceptors.request.use(config => {
    const newConfig = config;
    newConfig.baseURL = __API_UNVERSIONED_PATH__;
    return newConfig;
  });
  return client as AxiosInstance;
};

export const getLoginUserClient = () => {
  unloggedClient.interceptors.request.use(config => {
    const newConfig = config;
    newConfig.baseURL = __API_UNVERSIONED_PATH__;
    return newConfig;
  });
  return unloggedClient as AxiosInstance;
};

export { getUserClient as default };
