import styled from "styled-components";

export const Button = styled.div`
  background-color: ${({ theme }) => theme.socialLoginButton.background};
  border: ${({ theme }) => theme.socialLoginButton.border};
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  cursor: pointer;
`;
