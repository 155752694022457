import {
  Button,
  DoodleLoader,
  Input,
  InputFeedbackOptions,
  Text,
  placeholderColors
} from "@arcadia/design-system";
import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SpaceSchoolCardVariant } from "src/js/components/SpaceCards/SpaceSchoolCard/SpaceSchoolCard.types";
import { InputAddress } from "src/js/components/forms/InputAddress";
import {
  CustomListElementProps,
  ListSelector
} from "src/js/components/global/ListSelector";
import {
  useDebounce,
  useInfiniteScrollFetcher,
  useOnboarding
} from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { fetchSearchSpaceSchools } from "src/js/repository/spacesRepository";
import { useTranslation } from "src/js/translation";
import {
  EventDomain,
  OnboardingEvent,
  SpaceCreationEvent,
  SpaceModel,
  SpacePurpose
} from "src/js/types";
import { SchoolClaimFormStatus } from "../SchoolClaimForm.types";
import { EmptyState } from "./EmptyState";
import {
  CARD_NEW_SPACE_ID,
  parseObjPlaceData
} from "./SchoolClaimSearch.const";
import * as S from "./SchoolClaimSearch.styles";
import { PlaceType, SchoolClaimSearchProps } from "./SchoolClaimSearch.types";

const SchoolClaimSearch = ({
  onSelectedCallback,
  theme,
  backgroundColor,
  goToSpaceCreation
}: SchoolClaimSearchProps) => {
  const { translate } = useTranslation();
  const { isOnboardingFlow } = useOnboarding();

  const [selectedId, setSelectedId] = useState<string>(null);
  const [spaceName, setSpaceName] = useState("");
  const debouncedSpaceName = useDebounce(spaceName, 300);
  const [place, setPlace] = useState<PlaceType>(null);
  const { city } = place || {};

  const randomSpaceNameColor = useMemo(
    () => Math.floor(Math.random() * placeholderColors.length),
    []
  );

  const [errors, setErrors] = useState<{ [key: string]: InputFeedbackOptions }>(
    {}
  );

  const formValidations = {
    spaceName: (value: string) => {
      if (value.length > 50)
        return translate("school_claim_field_space_name_character_limit");
      return false;
    },
    instituteAddress: (value: PlaceType) => {
      if (!value?.streetNumber)
        return translate("school_claim_field_institute_street_number_required");
      return false;
    }
  };

  const checkFormValidation = (field, value) => {
    const error = {};
    const errorString = formValidations[field](value);
    error[field] = errorString ? { type: "error", message: errorString } : {};
    setErrors(errorsValue => ({ ...errorsValue, ...error }));
    return !!errorString;
  };

  const { formIsFill, formIsValid } = useMemo(() => {
    const isFill =
      place?.city?.length > 0 && debouncedSpaceName?.trim().length > 0;
    if (!isFill) {
      setErrors({});
      return { isFill, formIsValid: false };
    }
    let isValid = true;
    if (checkFormValidation("spaceName", debouncedSpaceName)) isValid = false;
    if (checkFormValidation("instituteAddress", place)) isValid = false;
    return { formIsFill: isFill, formIsValid: isValid };
  }, [debouncedSpaceName, place]);

  const {
    data: list = [],
    reset,
    isLoading,
    hasMoreResults,
    fetch: fetchItemsList,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    SpaceModel[],
    { filter: { name: string; city: string } }
  >(
    async ({ limit, page: _page, filter }) => {
      const data = await fetchSearchSpaceSchools({
        name: filter?.name,
        city: filter?.city,
        limit,
        offset: _page * limit
      });
      return data?.results?.map(({ space }) => space);
    },
    { lazy: true, limit: 30 }
  );

  useEffect(() => {
    reset(); // First call to reset isLoading, even is lazy
    if (formIsFill) {
      fetchItemsList(0, {
        filter: {
          name: debouncedSpaceName,
          city
        }
      });
    }
  }, [debouncedSpaceName, city]);

  // Catch cases reset setSelectedId
  useEffect(() => {
    if (
      !formIsFill ||
      (selectedId !== CARD_NEW_SPACE_ID &&
        !isLoading &&
        !list.map(el => el?.id).includes(selectedId))
    ) {
      setSelectedId(null);
    }
  }, [list, formIsFill, isLoading]);

  const handleOnSpaceNameChange = e => {
    const inputValue = e?.target?.value;
    setSpaceName(inputValue);
  };

  const handleOnInstituteAddressChange = () => {
    setPlace(null);
  };

  const handleOnPlaceSelected = rawPlace => {
    const newPlace = parseObjPlaceData(rawPlace);
    setPlace(newPlace);
  };

  const customCard = ({ el, onClick }: CustomListElementProps<SpaceModel>) => {
    return (
      <S.StyledSpaceSchoolCard
        spaceName={el?.name}
        address={el?.address}
        isSelected={el?.id === selectedId}
        color={el?.defaultBadgeColor}
        url={el?.badge?.small as string}
        variant={
          el?.isOpen
            ? SpaceSchoolCardVariant.Unclaimed
            : SpaceSchoolCardVariant.Claimed
        }
        onClick={onClick}
      />
    );
  };

  const onConfirm = () => {
    if (selectedId === CARD_NEW_SPACE_ID) {
      if (isOnboardingFlow) {
        _trackEvent(EventDomain.Onboarding, OnboardingEvent.SchoolSpaceCreate);
      } else {
        _trackEvent(
          EventDomain.SpaceCreation,
          SpaceCreationEvent.SchoolSpaceCreate
        );
      }
      onSelectedCallback(SchoolClaimFormStatus.NewSpace, {
        name: spaceName,
        defaultBadgeColor: randomSpaceNameColor,
        purpose: SpacePurpose.School,
        ...place
      });
      return;
    }
    const newSpace = list.find(elm => elm.id === selectedId);
    if (isOnboardingFlow) {
      _trackEvent(EventDomain.Onboarding, OnboardingEvent.SchoolSpaceJoin);
    } else {
      _trackEvent(
        EventDomain.SpaceCreation,
        SpaceCreationEvent.SchoolSpaceJoin
      );
    }
    onSelectedCallback(
      newSpace.isOpen
        ? SchoolClaimFormStatus.UnclaimedSpace
        : SchoolClaimFormStatus.ClaimedSpace,
      newSpace
    );
  };

  return (
    <S.ScrollableListWrapper>
      <S.InputWrapper withFeedback={!isEmptyObject(errors?.spaceName || {})}>
        <Input
          value={spaceName}
          theme={theme}
          label={translate("school_claim_form_institute_name")}
          name="space_name"
          rounded
          placeholder={translate(
            "school_claim_form_institute_name_placeholder"
          )}
          onChange={e => handleOnSpaceNameChange(e)}
          hideLabel={false}
          feedback={errors.spaceName}
        />
      </S.InputWrapper>
      <S.InputWrapper
        withFeedback={!isEmptyObject(errors?.instituteAddress || {})}
      >
        <InputAddress
          theme={theme}
          label={translate("school_claim_form_institute_address")}
          name="institute_address"
          rounded
          placeholder={translate(
            "school_claim_form_institute_address_placeholder"
          )}
          hideLabel={false}
          onChange={() => handleOnInstituteAddressChange()}
          onPlaceSelected={handleOnPlaceSelected}
          feedback={errors.instituteAddress}
        />
      </S.InputWrapper>
      <S.ScrollableArea id="area-list">
        {formIsFill ? (
          <>
            <S.StyledSpaceSchoolCard
              isSelected={CARD_NEW_SPACE_ID === selectedId}
              spaceName={spaceName}
              variant={SpaceSchoolCardVariant.Unique}
              color={randomSpaceNameColor}
              onClick={() => setSelectedId(CARD_NEW_SPACE_ID)}
            />
            {list.length !== 0 || isLoading ? (
              <InfiniteScroll
                scrollableTarget="area-list"
                dataLength={list.length}
                next={() => {
                  fetchNextPage({
                    filter: {
                      name: spaceName,
                      city
                    }
                  });
                }}
                hasMore={hasMoreResults}
                loader={<DoodleLoader theme={theme} isMini />}
                style={{ overflowX: "hidden" }}
              >
                <ListSelector
                  toggleItem={({ id }) => {
                    setSelectedId(id);
                  }}
                  selectedItem={selectedId}
                  itemList={list}
                  getCustomItemCard={customCard}
                />
              </InfiniteScroll>
            ) : null}
          </>
        ) : (
          <EmptyState />
        )}
      </S.ScrollableArea>
      <S.FooterWrapper backgroundColor={backgroundColor}>
        <Button
          disabled={selectedId === null || isLoading || !formIsValid}
          onClick={onConfirm}
          theme={theme}
          variant="primary"
          fullWidth
        >
          {translate("school_claim_cta_save_continue")}
        </Button>
      </S.FooterWrapper>
      {!isOnboardingFlow ? (
        <S.SpaceCreationRedirectContainer>
          <Text type="headerTitle">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate("space_creation_cta_text")
              }}
            />
          </Text>
          <S.StyledLinkButton onClick={goToSpaceCreation}>
            {translate("space_creation_cta_action")}
          </S.StyledLinkButton>
        </S.SpaceCreationRedirectContainer>
      ) : null}
    </S.ScrollableListWrapper>
  );
};

export default SchoolClaimSearch;
