import React, { FC } from "react";
import { RESOURCE_PICKER_SECTION } from "src/js/components/ResourcePicker/ResourcePicker.const";
import { PrepareResourcePayload } from "src/js/hooks/resources";
import { PrepareWSResource } from "src/js/hooks/resources/useResourcePayload";
import { useTranslation } from "src/js/translation";
import * as S from "./TextEditorAttachments.styles";

const TextEditorAttachments: FC<{
  prepareModuleResourcePayload: PrepareResourcePayload;
  prepareWSResource: PrepareWSResource;
}> = ({ prepareModuleResourcePayload, prepareWSResource }) => {
  const { translate } = useTranslation();

  const resourcePickerEl = (
    <S.CustomResourcePicker
      section={RESOURCE_PICKER_SECTION.CHAT}
      prepareModuleResourcePayload={prepareModuleResourcePayload}
      prepareWSResource={prepareWSResource}
      elementIndex={0}
      embedded
    />
  );

  return (
    <>
      <S.CustomDotsMenu
        id="textEditorAttachmentsDotsMenu"
        openFromTop
        alignRight={false}
        customIcon="attachment"
        customMenu={resourcePickerEl}
        withMobileHeaderLabels
        withMobileAdaptiveHeight={false}
        mobileCloseLabel={translate("resource_mobile_close_modal")}
        mobileConfirmLabel={translate("resource_mobile_confirm_modal")}
        mobileTitle={translate("resource_mobile_title_modal")}
        listContainerId="text-editor-attachments-list"
        forceMount={false}
      />
      <S.GlobalStyleAttachmentDotsMenu />
    </>
  );
};

export default TextEditorAttachments;
