import { Box, Heading, Icon } from "@arcadia/design-system";
import React from "react";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import {
  InstructionTopic,
  InstructionTopicType
} from "../AddAITextEditorResource.types";
import {
  getIconFromInstructionTopic,
  getInstructionFromTopic
} from "../AddAITextEditorResource.utils";

import * as S from "./AIInstructions.styles";

const InstructionBlock = ({ topic }: { topic: InstructionTopicType }) => {
  const { color, icon } = getIconFromInstructionTopic(topic);
  const { title, description } = getInstructionFromTopic(topic);
  const { translate } = useTranslation();

  return (
    <S.BlockWrapper>
      <S.FlexCenter>
        <Icon
          icon={icon}
          color={color}
          height="26px"
          width="26px"
          initialViewbox
        />
      </S.FlexCenter>
      <S.StyledTitle level="5"> {translate(title)}</S.StyledTitle>
      <div />
      <S.StyledText type="formField">{translate(description)}</S.StyledText>
    </S.BlockWrapper>
  );
};

const AIInstructions = () => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();

  return (
    <S.Wrapper>
      <Box padding="0px 16px">
        <Heading level={isLayoutModeMobile ? "4" : "1"}>
          {translate("ai_instruction_title")}
        </Heading>
      </Box>
      {isLayoutModeMobile ? (
        <S.Slideshow
          msTimeout={3500}
          swipeable
          showPagination
          items={[
            <InstructionBlock
              key={InstructionTopic.SensitiveData}
              topic={InstructionTopic.SensitiveData}
            />,
            <InstructionBlock
              key={InstructionTopic.Language}
              topic={InstructionTopic.Language}
            />,
            <InstructionBlock
              key={InstructionTopic.AIAccuracy}
              topic={InstructionTopic.AIAccuracy}
            />
          ]}
        />
      ) : (
        <>
          <InstructionBlock topic={InstructionTopic.SensitiveData} />
          <InstructionBlock topic={InstructionTopic.Language} />
          <InstructionBlock topic={InstructionTopic.AIAccuracy} />
        </>
      )}
    </S.Wrapper>
  );
};

export default AIInstructions;
