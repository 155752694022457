/* eslint-disable consistent-return */
/* eslint-disable lines-between-class-members */
import { makeAutoObservable, reaction, toJS } from "mobx";
import { isEmptyObject } from "src/js/modules/commonFunction";
import { fetchUserTasksList } from "src/js/repository/userTasksRepository";
import { __MAX_TIMEOUT_VALUE__ } from "src/js/settings/settingsGeneral";

export const sanitizeTaskListData = data =>
  data.map(({ id, title, description, image, target_url, is_completed }) => {
    return {
      id,
      title,
      description,
      image,
      targetUrl: target_url,
      isCompleted: is_completed
    };
  });

class UserTasksStore {
  $tasksList = [];
  $incompletedTasksList = [];
  $tasksAreaIsVisible = true;
  $tasksAreaIsExpanded = false;
  $tasksAreaIsTemporarilyHidden = false;

  constructor({ userStore }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.userStore = userStore;
    this.disposeOnUserChange();
    this.disposeOnTask();
  }

  setIncompletedTasksList = value => {
    this.$incompletedTasksList = value;
  };

  setTasksList = value => {
    this.$tasksList = value;
  };

  setTasksAreaVisible = value => {
    this.$tasksAreaIsVisible = value;
  };

  setTasksAreaExpanded = value => {
    this.$tasksAreaIsExpanded = value;
  };

  setTasksAreaTemporarilyHidden = value => {
    this.$tasksAreaIsTemporarilyHidden = value;
  };

  initializeUserTasksList = () => {
    fetchUserTasksList()
      .then(({ tasks }) => {
        if (tasks) {
          const sanitizedTasksList = sanitizeTaskListData(tasks);
          this.setTasksList(sanitizedTasksList);
          this.setIncompletedTasksList(
            sanitizedTasksList.filter(task => !task.isCompleted)
          );
          this.checkFloatArea();
        }
      })
      .catch(() => {
        this.setTasksList([]);
        this.setIncompletedTasksList([]);
      });
  };

  // set task completed among the incomplete task list, then filter it after the css animation
  setSingleTaskComplete = id => {
    const taskIndex = this.$tasksList.findIndex(task => task.id === id);
    if (taskIndex > -1) {
      this.$tasksList[taskIndex].isCompleted = true;
    }
    const incompletedTaskIndex = this.$incompletedTasksList.findIndex(
      task => task.id === id
    );
    if (incompletedTaskIndex > -1) {
      this.$incompletedTasksList[incompletedTaskIndex].isCompleted = true;
      setTimeout(() => {
        this.$incompletedTasksList = this.$incompletedTasksList.filter(
          task => task.id !== id
        );
      }, 3000); // this is the delay of animations before removing card element from dom
    }
  };

  realtimeTaskCompletion = ({ completed_task, new_tasks }) => {
    if (!isEmptyObject(completed_task)) {
      this.setSingleTaskComplete(completed_task.id);
    }
    if (new_tasks?.length > 0) {
      const sanitizedNewTasksList = sanitizeTaskListData(new_tasks);
      const actualTasksList = toJS(this.$tasksList);
      const updatedTasksList = [...actualTasksList, ...sanitizedNewTasksList];

      setTimeout(() => {
        this.$tasksList = updatedTasksList;
        this.$incompletedTasksList = updatedTasksList.filter(
          task => !task.isCompleted
        );
      }, 3000);
    }
  };

  // computed
  get tasksList() {
    return this.$tasksList;
  }

  get incompletedTasksList() {
    return this.$incompletedTasksList;
  }

  get tasksAreaIsVisible() {
    return this.$tasksAreaIsVisible;
  }

  get tasksAreaIsExpanded() {
    return this.$tasksAreaIsExpanded;
  }

  get tasksAreaIsTemporarilyHidden() {
    return this.$tasksAreaIsTemporarilyHidden;
  }

  get tasksTotalNumber() {
    return this.$tasksList?.length;
  }

  get tasksCompletedNumber() {
    return this.$tasksList?.length - this.incompletedTasksList?.length || 0;
  }

  moreThanOneDayAgo = date => {
    // const day = 1000 * 60 * 60 * 24;
    const oneMinute = 1000 * 60;
    const dayAgo = Date.now() - oneMinute;

    return date < dayAgo;
  };

  checkFloatArea = () => {
    const cookie = localStorage.getItem("isTaskAreaVisible");
    if (cookie != null) {
      this.setTasksAreaVisible(false);
      if (this.moreThanOneDayAgo(cookie)) {
        this.setTasksAreaVisible(true);
        return localStorage.removeItem("isTaskAreaVisible");
      }
      this.setFloatAreaTimer(Date.now() - cookie);
    }
  };

  setFloatAreaTimer = timerValue => {
    const safeTimerValue = Math.min(__MAX_TIMEOUT_VALUE__, timerValue);
    setTimeout(() => {
      this.setTasksAreaVisible(true);
      localStorage.removeItem("isTaskAreaVisible");
    }, safeTimerValue);
  };

  disposeOnUserChange = () => {
    reaction(
      // reacts on change of active user
      () => this.userStore.activeUser,
      () => {
        this.initializeUserTasksList();
      }
    );
  };

  disposeOnTask = () => {
    reaction(
      () => this.tasksAreaIsVisible,
      tasksAreaIsVisible => {
        if (tasksAreaIsVisible === false) {
          localStorage.setItem("isTaskAreaVisible", JSON.stringify(Date.now()));
        }
      }
    );
  };
}

export default UserTasksStore;
