import { Variants } from "framer-motion";
import { SpacesWelcomeStepType } from "./SpacesWelcome.types";

/**
 * Constants
 */

export const STEPS: SpacesWelcomeStepType[] = [
  {
    id: "create",
    icon: "plusWithCard",
    helpLabel: "welcome_step_create_help",
    titleLabel: "welcome_step_create_title",
    getStatus: ({ createCompleted }) => (createCompleted ? "DONE" : "UNDONE"),
    helper: {
      title: "welcome_step_create_helper_title",
      text: "welcome_step_create_helper_text"
    }
  },
  {
    id: "move",
    icon: "transferCards",
    helpLabel: "welcome_step_move_help",
    titleLabel: "welcome_step_move_title",
    getStatus: ({ moveCompleted }) => (moveCompleted ? "DONE" : "UNDONE"),
    helper: {
      title: "welcome_step_move_helper_title",
      text: "welcome_step_move_helper_text"
    }
  },
  {
    id: "contact",
    icon: "userWithFlagBadge",
    helpLabel: "welcome_step_contact_help",
    titleLabel: "welcome_step_contact_title",
    getStatus: ({ createCompleted, moveCompleted }) =>
      createCompleted && moveCompleted ? "SHOW" : "HIDE",
    helper: {
      title: "welcome_step_contact_helper_title",
      text: "welcome_step_contact_helper_text"
    }
  }
];

/**
 * Animations
 */

export const sidebarVariants: Variants = {
  open: {
    height: "auto",
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.5 }
  },
  closed: {
    height: 0,
    opacity: 0
  }
};

export const itemVariants = (i: number) => ({
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24, delay: i * 0.2 }
  },
  closed: { opacity: 0, transition: { duration: 0.2 } }
});
