/* eslint-disable no-restricted-imports */
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/js/hooks";
import createUrl from "src/js/modules/routing";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { Button } from "@arcadia/design-system";
import { useTheme } from "styled-components";

export const WhiteboardCarouselButton = observer(({ group_id }) => {
  const {
    GroupStore: { isWhiteboardEnabled, userIsTeacher },
    WhiteboardStore: { whiteboardUrl }
  } = useStores();
  const { greyTheme } = useTheme();

  if (!isWhiteboardEnabled) return null;
  if (!whiteboardUrl && !userIsTeacher) return null;

  return (
    <Button
      theme={greyTheme}
      variant="secondary"
      onClick={() => navigateTo(createUrl("whiteboard", { group_id }))}
    >
      <Translate text="whiteboard" />
    </Button>
  );
});
