import React from "react";
import { Text, Icon } from "@arcadia/design-system";
import * as S from "./NoticeRadioElement.styles";

const NoticeRadioElement = ({
  isSelected,
  value,
  title,
  description,
  icon,
  even = false,
  onClick
}) => {
  return (
    <S.ElementContainer even={even}>
      <S.ElementIconContainer>
        <Icon height={20} width={20} icon={icon} />
      </S.ElementIconContainer>
      <S.ElementInfoContainer>
        <Text type="formSubtitle">{title}</Text>
        <S.ElementDescription>
          <Text type="formDescription">{description}</Text>
        </S.ElementDescription>
      </S.ElementInfoContainer>
      <S.ElementRadioContainer>
        <S.ElementRadio
          isSelected={isSelected}
          onClick={() => onClick(value)}
        />
      </S.ElementRadioContainer>
    </S.ElementContainer>
  );
};

export default NoticeRadioElement;
