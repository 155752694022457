import { DoodleLoader } from "@arcadia/design-system";
import { format } from "date-fns";
import { observer } from "mobx-react";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router";
import AppUrl from "src/js/appUrl";
import { EventRecipientsListModal } from "src/js/components/EventRecipientsListModal";
import { MainContainer } from "src/js/components/layout/MainContainer";
import { useStores } from "src/js/hooks";
import {
  copyEvent,
  deleteEvent,
  editEvent,
  newEvent
} from "src/js/modules/calendarFunction";
import { showToastError } from "src/js/modules/messageManager";
import { openResource } from "src/js/modules/resourceFunction";
import { createUrl } from "src/js/modules/routing";
import changeUrl from "src/js/modules/urlFunction";
import { AgendaBody } from "src/js/pages/agenda/AgendaBody";
import { CalendarBody } from "src/js/pages/calendar/CalendarBody";
import { __AGENDA_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";

const Calendar = () => {
  const {
    GroupStore: { groupId, userIsTeacher },
    CalendarStore: {
      events,
      selectedEvent,
      getCalendarEvents,
      getCalendarEventDetail,
      deleteCalendarEvent,
      setEventTypeFilter,
      resetEventTypeFilter,
      setCalendarLastSearchValues,
      getAgendaEvents,
      agendaHasNextPage,
      agendaHasPreviousPage
    },
    AgendaStore: {
      agendaDays,
      firstViewDate,
      lastViewDate,
      selectedEventAgendaKey,
      numberOfRowsAdded,
      setNavigationDate,
      setAgendaViewIsActive,
      setAgendaEventTypeFilter,
      resetAgendaEventTypeFilter,
      setSelectedEventAgendaKey,
      setShouldUpdateNumberOfRows,
      resetNumberOfRowsAdded
    },
    ModalStore: { openModal, closeModal }
  } = useStores();
  const [resourceView, setResource] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const agendaView = useRouteMatch(AppUrl.agenda);
  const { date: urlDateString } = useParams();
  const { greyTheme } = useTheme();
  const { translate } = useTranslation();

  useEffect(() => {
    // window location parsing because oauth_error is
    // inserted by backend before the routing # fragment
    const { oauth_error } = queryString.parse(window.location.search);
    const { reload_vendors: app_reload_vendors, oauth_error: app_oauth_error } =
      queryString.parse(location.search);
    if (oauth_error) {
      showToastError({ str: translate(oauth_error) });
      window.history.replaceState(
        null,
        "",
        window.location.origin + window.location.pathname + window.location.hash
      );
      return;
    }
    if (app_oauth_error) {
      showToastError({ str: translate(app_oauth_error) });
      history.replace({
        search: null
      });
      return;
    }
    if (app_reload_vendors) {
      history.replace({
        search: null
      });
      window.location.reload();
    }
  }, [window.location.search, location.search]);

  useEffect(() => {
    return () => {
      resetEventTypeFilter();
      resetAgendaEventTypeFilter();
    };
  }, []);

  useEffect(() => {
    setAgendaViewIsActive(agendaView);
    return () => {
      setAgendaViewIsActive(false);
    };
  }, [agendaView]);

  const onPageLoading = useCallback(
    event => {
      const from = format(
        new Date(event.viewStart),
        "yyyy-MM-dd'T'HH:mm:ssXXX"
      );
      const to = format(new Date(event.viewEnd), "yyyy-MM-dd'T'HH:mm:ssXXX");
      setCalendarLastSearchValues({
        groupId,
        from,
        to
      });
      getCalendarEvents({
        groupId,
        from,
        to
      }).catch(() => {
        showToastError({
          str: translate("calendar_events_loading_error")
        });
      });
    },
    [events, groupId]
  );

  const agendaDownscrollLoading = useCallback(
    ({ viewStart, overrideEvents = false }) => {
      const from = format(new Date(viewStart), "yyyy-MM-dd'T'HH:mm:ssXXX");
      if (overrideEvents) {
        setNavigationDate(viewStart);
        if (urlDateString !== format(new Date(viewStart), "yyyy-MM-dd")) {
          const agendaUrlWithDate = createUrl("agenda", {
            group_id: groupId,
            date: format(new Date(viewStart), "yyyy-MM-dd")
          });
          changeUrl(agendaUrlWithDate);
        }
      }
      return getAgendaEvents({
        groupId,
        from,
        limit: __AGENDA_LIST_LIMIT__,
        overrideEvents
      }).catch(() => {
        showToastError({
          str: translate("calendar_events_loading_error")
        });
      });
    },
    [events, groupId]
  );

  const agendaUpscrollLoading = useCallback(
    ({ viewEnd }) => {
      const to = format(new Date(viewEnd), "yyyy-MM-dd'T'HH:mm:ssXXX");
      setNavigationDate(null);
      setShouldUpdateNumberOfRows(true);
      return getAgendaEvents({
        groupId,
        to,
        limit: __AGENDA_LIST_LIMIT__
      }).catch(() => {
        showToastError({
          str: translate("calendar_events_loading_error")
        });
      });
    },
    [events, groupId]
  );

  const getEventDetail = useCallback(
    calendarEventId => {
      getCalendarEventDetail({
        groupId,
        calendarEventId
      }).catch(() => {
        showToastError({
          str: translate("calendar_event_detail_loading_error")
        });
      });
    },
    [events, groupId]
  );

  const openRecipientsModal = useCallback(
    ({ group_recipients, user_recipients }) => {
      openModal(() => (
        <EventRecipientsListModal
          closeModal={() => closeModal()}
          group_recipients={group_recipients}
          user_recipients={user_recipients}
        />
      ));
    },
    []
  );

  const calendarOpenResource = async ({ id, type, eventType, eventId }) => {
    const resView = await openResource({
      id,
      type,
      groupId,
      eventType,
      eventId,
      onClose: () => setResource(null)
    });
    if (resView) {
      setResource(resView);
    }
  };

  const calendarNewEvent = ({ defaultDate, type }) => {
    newEvent({ defaultDate, type, openModal, closeModal });
  };

  const calendarEditEvent = eventDetail => {
    editEvent({ eventDetail, openModal, closeModal });
  };

  const calendarCopyEvent = eventDetail => {
    const copiedEvent = { ...eventDetail };
    delete copiedEvent.id;
    copyEvent({ eventDetail: copiedEvent, openModal, closeModal });
  };

  const calendarDeleteEvent = calendarEventId => {
    deleteEvent({ calendarEventId, deleteCalendarEvent });
  };

  return (
    <>
      {groupId ? (
        <MainContainer>
          {agendaView ? (
            <AgendaBody
              agendaDays={agendaDays}
              events={events}
              agendaDownscrollLoading={agendaDownscrollLoading}
              agendaUpscrollLoading={agendaUpscrollLoading}
              firstViewDate={firstViewDate}
              lastViewDate={lastViewDate}
              agendaHasNextPage={agendaHasNextPage}
              agendaHasPreviousPage={agendaHasPreviousPage}
              numberOfRowsAdded={numberOfRowsAdded}
              resetNumberOfRowsAdded={resetNumberOfRowsAdded}
              urlDateString={urlDateString}
              userIsTeacher={userIsTeacher}
              getEventDetail={getEventDetail}
              selectedEvent={selectedEvent}
              selectedEventAgendaKey={selectedEventAgendaKey}
              setSelectedEventAgendaKey={setSelectedEventAgendaKey}
              openRecipientsModal={openRecipientsModal}
              openResource={calendarOpenResource}
              deleteCalendarEvent={calendarDeleteEvent}
              editCalendarEvent={calendarEditEvent}
              copyCalendarEvent={calendarCopyEvent}
              newEvent={calendarNewEvent}
              setEventTypeFilter={setAgendaEventTypeFilter}
              agendaUrl={createUrl("agenda", {
                group_id: groupId,
                date: format(new Date(), "yyyy-MM-dd")
              })}
              calendarUrl={createUrl("calendar", { group_id: groupId })}
              groupId={groupId}
            />
          ) : (
            <CalendarBody
              events={events}
              onPageLoading={onPageLoading}
              userIsTeacher={userIsTeacher}
              getEventDetail={getEventDetail}
              selectedEvent={selectedEvent}
              openRecipientsModal={openRecipientsModal}
              openResource={calendarOpenResource}
              deleteCalendarEvent={calendarDeleteEvent}
              editCalendarEvent={calendarEditEvent}
              copyCalendarEvent={calendarCopyEvent}
              newEvent={calendarNewEvent}
              setEventTypeFilter={setEventTypeFilter}
              agendaUrl={createUrl("agenda", {
                group_id: groupId,
                date: format(new Date(), "yyyy-MM-dd")
              })}
              calendarUrl={createUrl("calendar", { group_id: groupId })}
              groupId={groupId}
            />
          )}
        </MainContainer>
      ) : (
        <DoodleLoader theme={greyTheme} />
      )}
      {resourceView}
    </>
  );
};

export default observer(Calendar);
