import { GroupSectionsEnum } from "src/js/types";
import { GroupSettingsSection } from "./GroupSettingsSections.types";

export const GROUP_SETTINGS_SECTIONS: GroupSettingsSection[] = [
  {
    id: GroupSectionsEnum.Wall,
    icon: "teamDashboard",
    title: "group_settings_section_wall_title",
    description: "group_settings_section_wall_description"
  },
  {
    id: GroupSectionsEnum.Modules,
    icon: "book",
    title: "group_settings_section_modules_title",
    description: "group_settings_section_modules_description"
  },
  {
    id: GroupSectionsEnum.Exercises,
    icon: "weight",
    title: "group_settings_section_assessments_title",
    description: "group_settings_section_assessments_description"
  },
  {
    id: GroupSectionsEnum.Whiteboard,
    icon: "stylusNote",
    title: "group_settings_section_board_title",
    description: "group_settings_section_board_description"
  },
  {
    id: GroupSectionsEnum.Activity,
    icon: "document",
    title: "group_settings_section_report_title",
    description: "group_settings_section_report_description"
  },
  {
    id: GroupSectionsEnum.Calendar,
    icon: "calendar",
    title: "group_settings_section_calendar_title",
    description: "group_settings_section_calendar_description"
  }
];
