import { LabelThemeOptions, Palette } from "@ds_themes/types";
import { getHighContrastColor } from "@ds_themes/utils";
import colors from "@ds_themes/colors";

export const createLabelTheme = ({
  grey,
  blueGrey,
  error,
  success,
  primary
}: Palette): { label: LabelThemeOptions } => {
  return {
    label: {
      closeColor: grey[50],
      icon: grey[1000],
      variant: {
        primary: {
          background: primary.main,
          text: primary.contrastText
        },
        lightPrimary: {
          background: primary.lighter,
          text: primary.main
        },
        lightViolet: {
          background: colors.violet[700],
          text: grey[1000]
        },
        violet: {
          background: colors.violet[501],
          text: grey[1000]
        },
        green: {
          background: success[500],
          text: getHighContrastColor(grey[1000])
        },
        lightGreen: {
          background: colors.success[900],
          text: colors.success[400]
        },
        red: {
          background: error[500],
          text: getHighContrastColor(error[500])
        },
        bluegrey: {
          background: blueGrey[500],
          text: grey[1000]
        },
        yellow: {
          background: colors.yellow[500],
          text: grey[50]
        },
        lightOrange: {
          background: colors.orange[900],
          text: colors.orange[500]
        },
        orange: {
          background: colors.warning[500],
          text: grey[50]
        }
      }
    }
  };
};
