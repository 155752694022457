import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { Icon } from "@ds_universal/Icon";
import { Text } from "@ds_universal/Text";

import * as S from "./ActionButton.styles";
import { ActionButtonProps } from "./types";

export const ActionButton: FC<ActionButtonProps> = ({
  theme,
  className,
  variant,
  icon,
  disabled = false,
  onClick,
  counter,
  details,
  type = "button",
  fullWidth,
  onKeyDown,
  role,
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <S.ActionButton
        className={className}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        aria-label={details}
        id={details}
        fullWidth={fullWidth}
        onKeyDown={onKeyDown}
        type={type}
        role={role}
        {...props}
      >
        {counter && Number(counter) !== 0 ? (
          <S.Badge
            background={theme.actionButton[variant].badge.background}
            text={theme.actionButton[variant].badge.text}
            border={theme.actionButton[variant].badge.border}
          >
            <Text type="captionInfoDetails">{counter}</Text>
          </S.Badge>
        ) : null}
        <Icon icon={icon} color={theme.actionButton[variant].text} />
      </S.ActionButton>
    </ThemeProvider>
  );
};

export default ActionButton;
