import styled, { css } from "styled-components";

export const SidebarHeaderWrapper = styled.button<{
  hasActions?: boolean;
  isClickable?: boolean;
}>`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 36px;
  width: 100%;
  padding: 4px 8px;
  margin-top: 16px;
  margin-bottom: 4px;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  ${({ hasActions }) =>
    hasActions &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.coldGrey[900]};
      }
    `}
  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.coldGrey[900]};
      }
      cursor: pointer;
    `}
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  max-height: 36px;
`;

export const TooltipOption = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const SidebarHeaderTitle = styled.span`
  display: flex;
  align-items: center;
  color: unset;
  // No text style in the DS
  font-size: 14px;
  font-weight: 700;
`;

export const SidebarHeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  color: ${({ theme }) => theme.primaryColor[500]};
  overflow: hidden;
`;

export const SidebarHeaderAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  overflow: hidden;
`;
