import { Icon, colors } from "@arcadia/design-system";
import React, { FC } from "react";
import { useTheme } from "styled-components";
import * as S from "./UserSelectableItem.styles";
import { UserSelectableItemProps } from "./UserSelectableItem.types";

const UserSelectableItem: FC<UserSelectableItemProps> = ({
  isActive,
  onClick,
  el
}) => {
  const { whiteTheme } = useTheme();
  return (
    <S.UserSelectableItemWrapper
      data-testid="user-selectable-item-wrapper"
      onClick={onClick}
    >
      <S.Avatar
        size="image"
        rounded
        url={el?.avatar?.small}
        theme={whiteTheme}
      />
      <S.Text type="formField">
        {el?.name} {el?.surname}
      </S.Text>
      <S.CheckIndicator isSelected={isActive}>
        <Icon
          color={colors.grey[1000]}
          icon="check2"
          width={24}
          height={24}
          initialViewbox
        />
      </S.CheckIndicator>
    </S.UserSelectableItemWrapper>
  );
};

export default UserSelectableItem;
