import React, { ReactNode } from "react";
import { BoxProps } from "@arcadia/design-system";
import * as S from "./SecondaryBar.styles";

type SecondaryBarProps = BoxProps & {
  leftSection?: ReactNode;
  middleSection?: ReactNode;
  rightSection?: ReactNode;
  isProfilePage?: boolean;
  preventGrid?: boolean;
};

const ConditionalWrapper = ({ preventGrid, children }) =>
  preventGrid ? (
    <S.Content>{children}</S.Content>
  ) : (
    <S.GridContent>{children}</S.GridContent>
  );

const SecondaryBar = ({
  leftSection,
  rightSection,
  middleSection,
  isProfilePage = false,
  preventGrid = false,
  ...rest
}: SecondaryBarProps) => {
  return (
    <S.Bar {...rest}>
      <ConditionalWrapper preventGrid={preventGrid}>
        <S.LeftSection isProfilePage={isProfilePage}>
          {leftSection}
        </S.LeftSection>
        <S.MiddleSection isProfilePage={isProfilePage}>
          {middleSection}
        </S.MiddleSection>
        <S.LeftSection isProfilePage={isProfilePage}>
          {rightSection}
        </S.LeftSection>
      </ConditionalWrapper>
    </S.Bar>
  );
};

export default SecondaryBar;
