import React from "react";
import { Editor } from "@tiptap/core";
import { BottomModalSheet, Box, Icon, Text } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import { useTranslation } from "src/js/translation";
import { observer } from "mobx-react";

import { AICustomPromptTransformationValue } from "./AICustomPromptEditor.types";
import { useAICustomPromptActions } from "./useAICustomPromptActions";
import * as S from "./AICustomPromptEditorOptions.styles";

type AICustomPromptEditorOptionsProps = {
  editor: Editor;
  query: string;
  onClose: () => void;
};

const AICustomPromptEditorOptions = ({
  editor,
  query,
  onClose
}: AICustomPromptEditorOptionsProps) => {
  const { translate } = useTranslation();
  const { items, customPromptActions, isSubMenu, onGoBack } =
    useAICustomPromptActions(editor);
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  const onClick = React.useCallback(
    (item: AICustomPromptTransformationValue) => () => {
      customPromptActions[item].action(query);
    },
    [customPromptActions, query]
  );

  React.useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isLayoutModeMobile) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      if (isLayoutModeMobile) {
        document.body.style.overflow = originalStyle;
      }
    };
  }, [isLayoutModeMobile]);

  return isLayoutModeMobile ? (
    <BottomModalSheet
      onClose={onClose}
      isOpen
      title=""
      withHeaderLabels={false}
      withAdaptiveHeight
      withDrag
    >
      <S.BottomSheetWrapper>
        {isSubMenu && (
          <S.BottomSheetOption onClick={onGoBack}>
            <Icon icon="arrowLeft" width={16} height={16} initialViewbox />
            <Text type="formFieldEmpty">{translate("back")}</Text>
          </S.BottomSheetOption>
        )}
        {items.map(item => (
          <S.BottomSheetOption
            key={customPromptActions[item].title}
            onClick={onClick(item)}
          >
            {customPromptActions[item].leadingIcon && (
              <S.LeadingIcon
                icon={customPromptActions[item].leadingIcon}
                width={16}
                height={16}
                initialViewbox
              />
            )}
            <Text type="formFieldEmpty">
              {translate(customPromptActions[item].title)}
            </Text>
            {customPromptActions[item].trailingIcon && (
              <Box marginLeft="auto">
                <Icon
                  icon={customPromptActions[item].trailingIcon}
                  width={16}
                  height={16}
                  initialViewbox
                />
              </Box>
            )}
          </S.BottomSheetOption>
        ))}
      </S.BottomSheetWrapper>
    </BottomModalSheet>
  ) : (
    <S.EditSheet>
      {isSubMenu && (
        <S.SheetOption onClick={onGoBack}>
          <Icon icon="arrowLeft" width={16} height={16} initialViewbox />
          <Text type="formSubtitle">{translate("back")}</Text>
        </S.SheetOption>
      )}
      {items.map(item => (
        <S.SheetOption
          key={customPromptActions[item].title}
          onClick={onClick(item)}
        >
          {customPromptActions[item].leadingIcon && (
            <S.LeadingIcon
              icon={customPromptActions[item].leadingIcon}
              width={16}
              height={16}
              initialViewbox
            />
          )}
          <Text type="formSubtitle">
            {translate(customPromptActions[item].title)}
          </Text>
          {customPromptActions[item].trailingIcon && (
            <Box marginLeft="auto">
              <Icon
                icon={customPromptActions[item].trailingIcon}
                width={16}
                height={16}
                initialViewbox
              />
            </Box>
          )}
        </S.SheetOption>
      ))}
    </S.EditSheet>
  );
};

export default observer(AICustomPromptEditorOptions);
