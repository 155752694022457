import { useEffect } from "react";
import { GDriveResourceProps } from "src/js/components/ResourcePicker/components/AddGDriveResource/AddGDriveResource.types";
import { useStores } from "src/js/hooks";
import { useResourceUpload } from "src/js/hooks/resources";
import { generateTempResource } from "src/js/modules/fileFunction";
import { showToastError } from "src/js/modules/messageManager";
import {
  GoogleScope,
  __GDRIVE_MIME_BLACKLIST__,
  __GOOGLE_API_KEY__,
  __GOOGLE_APP_ID__,
  __GOOGLE_CLIENT_ID__
} from "src/js/settings/settingsResource";
import { useTranslation } from "src/js/translation";
import { RESOURCE_TYPE } from "src/js/types";
import { MimeType, WSGDriveDocumentResource } from "./types";

const AddGDriveResource = ({
  onSubmit,
  onClose,
  onSuccess,
  setUploadingProgress = () => {},
  elementIndex,
  // used in chat
  prepareWSResource
}: GDriveResourceProps) => {
  const { translate } = useTranslation();
  const { handleResourceLinkUpload } = useResourceUpload(setUploadingProgress);
  const {
    BoardsStore: { setUploadingElementIndexes, setErrorInUploadingElement }
  } = useStores();

  const addResource = (file: WSGDriveDocumentResource) => {
    setUploadingElementIndexes(elementIndex);

    const tempResource = generateTempResource({
      type: RESOURCE_TYPE.DROPBOX
    });

    const resourceUploadHandler = () => {
      return handleResourceLinkUpload(
        file.url,
        elementIndex,
        file.title,
        file.url
      );
    };

    if (onClose) onClose();

    if (prepareWSResource) {
      prepareWSResource(tempResource, resourceUploadHandler);
      return;
    }

    if (onSubmit) onSubmit(tempResource);
    resourceUploadHandler()
      .then(response => {
        onSuccess(response);
      })
      .catch(() => {
        setErrorInUploadingElement(
          elementIndex,
          true,
          translate("file_drag_drop_size_error", {
            fileName: file.title
          })
        );
      });
  };

  const pickerCallback = (data: google.picker.ResponseObject) => {
    if (data[google.picker.Response.ACTION] !== google.picker.Action.PICKED)
      return;

    if (
      __GDRIVE_MIME_BLACKLIST__.indexOf(data.docs[0].mimeType as MimeType) > -1
    ) {
      showToastError({ str: translate("file_type_not_supported_error") });
      return;
    }
    // Upload as link.
    data.docs.forEach(doc => {
      addResource({
        title: doc.name,
        // YOu can use embedUrl or url, the first is safer, the second allows also the edits
        url: doc.embedUrl
      });
    });
  };

  const openPicker = (accessToken: string) => {
    const view = new google.picker.DocsView(google.picker.ViewId.DOCS);
    const picker = new google.picker.PickerBuilder()
      .setAppId(__GOOGLE_APP_ID__)
      .setOAuthToken(accessToken)
      .addView(view)
      .setDeveloperKey(__GOOGLE_API_KEY__)
      // Upon selecting a file in the picker, call the addResource function with the file ID
      .setCallback(data => pickerCallback(data))
      .build();
    picker.setVisible(true);
  };

  useEffect(() => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: __GOOGLE_CLIENT_ID__,
      scope: GoogleScope,
      // Once the user has authorized access to their Drive account, open the picker
      callback: response => {
        gapi.load("picker", () => openPicker(response.access_token));
      }
    });
    client.requestAccessToken();
  }, []);

  return null;
};

export default AddGDriveResource;
