const style = (primaryColor: string) => `
  border-radius: 32px;
  text-decoration: none;
  background-color: ${primaryColor[800]};
  color: ${primaryColor[500]};
  line-height: 1;
  padding: 2px 4px;
`;

export const parseMentions = (text: string, primaryColor: string) => {
  let textCopy = text;
  const regex = /@\[([\s\S]*?)\]\(([\s\S]*?)\)/gm;
  const matches = textCopy.matchAll(regex);
  [...matches].forEach(match => {
    const fullName = match[1];
    const uuid = match[2];
    textCopy = textCopy.replace(
      `@[${fullName}](${uuid})`,
      `<a style="${style(
        primaryColor
      )}" href="#users/${uuid}/profile">@${fullName}</a>`
    );
  });

  return textCopy;
};
