import {
  extractErrorMessage,
  showToastError
} from "src/js/modules/messageManager";
import createUrl from "src/js/modules/routing";
import { fetchBoard } from "src/js/repository/boardRepository";
import { fetchPost } from "src/js/repository/postRepository";
import { fetchResource } from "src/js/repository/resourceRepository";
import { history, doRouteBack, navigateTo } from "src/legacy/modules/history";

export type ResourceContext = "post" | "board";

// TODO fix these functions, the types are random (like contextId string and number and similar)
type FetchResourceDetail = {
  id: number | string;
  contextId: number;
  context?: ResourceContext;
  setBoardElementId: (id: number) => void;
};

type NavigateToOrigin = {
  groupId: number;
  contextId: number;
  objId: number;
  context?: ResourceContext;
  isDraft?: boolean;
};

export const fetchResourceDetail = ({
  id,
  contextId,
  context,
  setBoardElementId
}: FetchResourceDetail) => {
  if (context === "board") {
    return fetchBoard(contextId)
      .then(result => {
        const { board_elements } = result;
        let boardElement = board_elements.find(
          res => parseInt(res.id, 10) === Number(id)
        );

        if (!boardElement) {
          boardElement = board_elements.find(
            res => parseInt(res.resource.id, 10) === Number(id)
          );
        }

        setBoardElementId(boardElement.id);

        return fetchResource(boardElement.resource.id).then(res => {
          return res;
        });
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  }
  if (context === "post") {
    return fetchPost(contextId)
      .then(async result => {
        return fetchResource(Number(id)).then(res => {
          const data = {
            resource: res,
            post: result
          };
          return data;
        });
      })
      .catch(error => {
        showToastError({
          str: extractErrorMessage(
            error,
            "Attenzione, si è verificato un errore"
          )
        });
      });
  }
  return fetchResource(Number(id))
    .then(result => {
      return result;
    })
    .catch(error => {
      showToastError({
        str: extractErrorMessage(error, "Attenzione, si è verificato un errore")
      });
    });
};

export const navigateToOrigin = ({
  groupId,
  contextId,
  objId,
  context,
  isDraft
}: NavigateToOrigin) => {
  if (typeof context !== "undefined") {
    if (context === "board") {
      if (isDraft) {
        navigateTo(
          createUrl("module_detail_drafts", {
            group_id: groupId,
            module_id: String(contextId)
          })
        );
      } else
        navigateTo(
          createUrl("module_detail", {
            group_id: groupId,
            module_id: String(contextId)
          })
        );
    } else {
      const url = createUrl("post_resource", {
        group_id: groupId,
        post_id: String(contextId),
        resource_id: String(objId)
      });
      navigateTo(url);
    }
  } else if (history.length > 0) {
    doRouteBack();
  } else {
    navigateTo(
      createUrl("wall", {
        group_id: Number(groupId)
      })
    );
  }
};
