import { colors, shadow } from "@arcadia/design-system";
import styled, { css, createGlobalStyle } from "styled-components";

type NavigationIconContainerProps = {
  isActiveItem: boolean;
};

type IsOverquota = {
  isOverquota: boolean;
};

export const MobileBottomNavigationBar = styled.div<IsOverquota>`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  z-index: ${({ theme }) => theme.zIndex.toolbarMobile};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.sizes.defaultMobileToolbarHeight};
  height: ${({ theme }) =>
    `calc(${theme.sizes.safeAreaInsetBottom} + ${theme.sizes.defaultMobileToolbarHeight})`};
  padding-bottom: ${({ theme }) => `calc(${theme.sizes.safeAreaInsetBottom})`};
  box-shadow: ${shadow[500]};
  justify-content: space-between;
  padding: 0px 16px;

  ${({ isOverquota }) =>
    isOverquota &&
    css`
      z-index: 999;
    `}

  @media screen and (min-width: 767px) {
    display: none;
  }
`;

export const NavigationIconContainer = styled.div<NavigationIconContainerProps>`
  text-align: center;
  color: ${({ theme, isActiveItem }) =>
    !isActiveItem ? colors.blueGrey[400] : theme.primaryColor[500]};

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
  position: relative;

  & > span {
    margin-top: 7px;
    font-weight: ${({ isActiveItem }) => (isActiveItem ? 600 : 400)};
  }
`;

export const EventDot = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  opacity: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
  background-color: ${({ theme }) => theme.colors.error[600]};
  top: 0px;
  right: 10px;
`;

export const AvatarContainer = styled.div<NavigationIconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  text-align: center;
  margin-top: -4px;
  color: ${({ theme, isActiveItem }) =>
    !isActiveItem ? colors.blueGrey[400] : theme.colors.violet[501]};

  & > div {
    border: ${({ theme, isActiveItem }) =>
      `1px solid ${!isActiveItem ? "transparent" : theme.colors.violet[501]}`};
    border-radius: 50%;
    padding: 1px;
    & > div:first-child {
      width: 32px;
      height: 32px;
    }
  }
`;

export const GlobalStyleHideNavigationBar = createGlobalStyle`
  ${MobileBottomNavigationBar} {
    display: none;
  }
`;
