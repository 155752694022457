import styled from "styled-components";
import { Text, Heading } from "@arcadia/design-system";
import { motion } from "framer-motion";

export const LeftSectionWrapper = styled(motion.div)`
  @media screen and (min-width: 767px) {
    padding-left: 16px;
  }
`;

export const MiddleSectionWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  width: 100%;
  flex: 0.8;
`;

export const RightSectionWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (min-width: 767px) {
    padding-right: 16px;
  }
`;

export const Title = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
`;

export const RightAction = styled(Text)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;
