import styled from "styled-components";

import {
  SCircleProps,
  SCircleContentProps,
  SCircleLettersProps
} from "./types";
import {
  AVATAR_WITH_INITIALS_SIZE,
  AVATAR_WITH_INITIALS_COLOR,
  AVATAR_WITH_INITIALS_FONT_SIZE
} from "./AvatarWithInitials.const";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  align-self: center;
`;

export const Circle = styled.div<SCircleProps>`
  width: ${({ size }) => `${AVATAR_WITH_INITIALS_SIZE[size]}`};
  height: ${({ size }) => `${AVATAR_WITH_INITIALS_SIZE[size]}`};
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CircleContent = styled.div<SCircleContentProps>`
  background-color: ${({ avatarColor }) =>
    `${AVATAR_WITH_INITIALS_COLOR[avatarColor]}`};
  color: #ffffff;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const CircleLetters = styled.span<SCircleLettersProps>`
  font-size: ${({ size }) => `${AVATAR_WITH_INITIALS_FONT_SIZE[size]}`};
  font-weight: 700;
  height: ${({ size }) => `${AVATAR_WITH_INITIALS_SIZE[size]}`};
  width: ${({ size }) => `${AVATAR_WITH_INITIALS_SIZE[size]}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;
