import { colors, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

const OptionStyle = css`
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${colors.coldGrey[900]};
  }
`;

export const Option = styled.div`
  ${OptionStyle}

  gap: 8px;
`;

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.primaryColor[300]};
  display: flex;
  width: calc(100% - 16px);
  flex-direction: column;
  align-items: flex-start;
`;

export const RecentTitle = styled(Text)`
  margin: 8px;
`;

export const FirstLineSubmitButton = styled.button`
  all: unset;
  ${OptionStyle}
  gap: 12px;
`;

export const SuggestedGroupOption = styled.div`
  ${OptionStyle}

  gap: 12px;
`;
