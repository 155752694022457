import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Box, Text, Button, Input } from "@arcadia/design-system";
import { useTheme } from "styled-components";
import { useFormContext } from "react-hook-form";
import { useStores } from "src/js/hooks";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { AiCourseGeneratorEvent, EventDomain } from "src/js/types";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { __FORM_AI_COURSE_WIZARD__ } from "../../CourseDetailsWizard.utils";
import { CourseDetailsWizardForm } from "../../CourseDetailsWizard.types";

const AudienceStep = ({ onNext }: { onNext: () => void }) => {
  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const { translate } = useTranslation();
  const {
    register,
    errors,
    formState: { dirtyFields }
  } = useFormContext<CourseDetailsWizardForm>();

  const handleNext = useCallback(() => {
    onNext();
    _trackEvent(
      EventDomain.AiCourseGenerator,
      AiCourseGeneratorEvent.AiWizardStep1
    );
  }, [onNext]);

  return (
    <Box padding="20px 0px" width="100%">
      <Text type="formSubtitle">{translate("ai_wizard_audience_label")}</Text>
      <Input
        name="audience"
        ref={register(__FORM_AI_COURSE_WIZARD__.audience)}
        error={{
          message:
            errors.audience &&
            translate(errors.audience.message as TranslationKeys)
        }}
        placeholder={translate("ai_wizard_audience_placeholder")}
        theme={isLayoutModeMobile ? greyTheme : whiteTheme}
        label="audience"
        hideLabel
        rounded
      />
      <Box marginTop={16}>
        <Button
          theme={whiteTheme}
          disabled={
            !dirtyFields.has("audience") || errors.audience !== undefined
          }
          variant="primary"
          onClick={handleNext}
        >
          {translate("next")}
        </Button>
      </Box>
    </Box>
  );
};

export default observer(AudienceStep);
