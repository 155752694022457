/* eslint-disable no-use-before-define */
import { ObjectValues } from "src/js/types";
import {
  BoardElementComment,
  NestedComment,
  PostComment
} from "src/js/types/models/Comment";
import { Reaction } from "src/js/types/models/Reaction";

export type CommentFetchParams = {
  parentId: number;
  limit: number;
  offset?: number;
  context: CommentContext;
};

export const DROPDOWN_OPTIONS_CONTEXT = {
  NESTED_COMMENT: "nested_comment",
  COMMENT: "comment"
} as const;

export type DropdownOptionsContext = ObjectValues<
  typeof DROPDOWN_OPTIONS_CONTEXT
>;

export const COMMENT_CONTEXT = {
  POST: "post",
  MODULES: "modules"
} as const;

export type CommentContext = ObjectValues<typeof COMMENT_CONTEXT>;

export type CommentProps = {
  comment: PostComment | BoardElementComment;
  onDelete?: (comment: PostComment | BoardElementComment) => void;
  onDeleteNestedComment?: (nestedComment: NestedComment) => void;
  commentIndex: number;
  onUpdate?: (
    comment: PostComment | BoardElementComment,
    newText: string
  ) => void;
  updateCommentReactionList: (
    commentId: number,
    reaction?: Reaction,
    nestedCommentId?: number
  ) => void;
  onUpdateNestedComment?: (
    nestedComment: NestedComment,
    newText: string
  ) => void;
  onCreateNestedComment?: (commentId: number, text: string) => void;
  hideNestedComment?: boolean;
  customReplyAction?: () => void;
};
