import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useStores } from "src/js/hooks";
import {
  Box,
  Button,
  Heading,
  Text,
  Toggle,
  Avatar,
  ContextualAlert,
  Icon
} from "@arcadia/design-system";
import InfiniteScroll from "react-infinite-scroll-component";
import { observer } from "mobx-react";
import { NoResults } from "src/js/components/global/NoResults";
import useEmitBadgeToStudents from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/useEmitBadgeToStudents";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import {
  __MAINNET_CHAIN_ICONS__,
  __MAINNET_CHAIN_IDS__
} from "src/js/pages/spaces/dashboard/SpacesBadges/hooks/const";
import {
  MaxNumberOfStudentsToMintNFT,
  MaxNumberOfStudentsToEmitBadge
} from "src/js/pages/spaces/dashboard/SpacesBadges/SpacesBadges.utils";
import { SelectUsersTypes } from "../types";
import * as S from "../styles";
import BadgeRelatedUser from "./BadgeRelatedUser";
import StudentsFilters from "./StudentsFilters";
import SelectStudentsTableTitles from "./SelectStudentsTableTitles";

const SelectStudents = ({
  filterString,
  setFilterString,
  filterGroupString,
  setFilterGroupString,
  badgeRelatedUsers,
  fetchNextPage,
  filterValueDebounced,
  hasMoreResults,
  setStep
}: SelectUsersTypes) => {
  const { whiteTheme, greyTheme } = useTheme();
  const { translate } = useTranslation();
  const {
    SpaceBadgesStore: {
      usersToEmitBadge,
      shouldDisplayEmptyState,
      selectedBadgeRelatedGroups,
      badgeToEdit
    },
    UserStore: { userWalletDetails }
  } = useStores();
  const { emitBadgeToStudents, isEmitButtonDisabled, toggleSbt, isSbtEnabled } =
    useEmitBadgeToStudents({
      setStep
    });
  const { id } = badgeToEdit || {};
  const { address, chainId } = userWalletDetails || {};
  const userWithUnconnectedWallet = usersToEmitBadge.some(
    user => !user.walletAddress
  );
  const isToggleDisabled =
    !window.ethereum ||
    !address ||
    userWithUnconnectedWallet ||
    usersToEmitBadge.length > MaxNumberOfStudentsToMintNFT;

  const handleDescriptionText = (): TranslationKeys => {
    if (
      usersToEmitBadge.length > MaxNumberOfStudentsToEmitBadge &&
      !isSbtEnabled
    )
      return "emit_open_badge_to_students_max_number_warning";
    if (userWithUnconnectedWallet)
      return "space_badge_emit_to_users_footer_no_wallet_description";
    if (usersToEmitBadge.length > MaxNumberOfStudentsToMintNFT)
      return "emit_nft_to_students_max_number_warning";
    return "space_badge_emit_to_users_footer_default_description";
  };

  const handleFooterDetails = () => {
    const userAwardedWeSchoolBadge = usersToEmitBadge.some(
      user => user.awardedWeschool
    );
    const shouldDisplaySbtAlert = userAwardedWeSchoolBadge && isSbtEnabled;

    return (
      <Box>
        <S.FooterDescription>
          <Text>{translate(handleDescriptionText())}</Text>
        </S.FooterDescription>
        {shouldDisplaySbtAlert && (
          <S.FooterAlert>
            <ContextualAlert
              theme={greyTheme}
              text={translate(
                "space_badge_emit_to_users_footer_only_sbt_description"
              )}
            />
          </S.FooterAlert>
        )}
        {(!address || !window.ethereum) && (
          <S.FooterAlert>
            <ContextualAlert
              theme={greyTheme}
              text={translate("space_badge_emit_nft_no_wallet_connected")}
            />
          </S.FooterAlert>
        )}
      </Box>
    );
  };

  const renderStudents = () => {
    return shouldDisplayEmptyState ? (
      <Box marginTop={40}>
        <NoResults
          subtitle={translate("dashboard_badge_list_no_results_text")}
          title={translate("emit_badge_to_students_no_results_title")}
        />
      </Box>
    ) : (
      badgeRelatedUsers.map(user => {
        return <BadgeRelatedUser key={user.id} badgeRelatedUser={user} />;
      })
    );
  };

  const tooltipString = `${translate(
    "emit_nft_to_students_selected_chain_in_footer",
    { chainId: __MAINNET_CHAIN_IDS__[chainId]?.toLowerCase() }
  )}`;

  useEffect(() => {
    if (isToggleDisabled) {
      toggleSbt(false);
    }
  }, [isToggleDisabled]);

  return (
    <S.DrawerBodyContainer>
      <StudentsFilters
        filterString={filterString}
        setFilterString={setFilterString}
        filterGroupString={filterGroupString}
        setFilterGroupString={setFilterGroupString}
      />
      <Box id="scroll-area-container" minHeight={400} marginBottom="auto">
        <S.ScrollableArea id="badge-related-users-scrollable-list">
          <InfiniteScroll
            scrollableTarget="badge-related-users-scrollable-list"
            dataLength={badgeRelatedUsers.length}
            next={() =>
              fetchNextPage({
                textSearch: filterValueDebounced,
                badgeId: id,
                showUnassociated: true,
                groupIds: selectedBadgeRelatedGroups
              })
            }
            hasMore={hasMoreResults}
            loader={null}
          >
            <S.BadgeRelatedUsersContainer>
              <SelectStudentsTableTitles />
              {renderStudents()}
            </S.BadgeRelatedUsersContainer>
          </InfiniteScroll>
        </S.ScrollableArea>
      </Box>
      {usersToEmitBadge.length ? (
        <S.DrawerFooter>
          <S.SbtFooter>
            <Box display="flex" alignItems="center" gap={8}>
              {__MAINNET_CHAIN_ICONS__[chainId] ? (
                <TranslatedTooltip
                  tooltipString={tooltipString as TranslationKeys}
                  placement="top"
                >
                  <Icon
                    icon={__MAINNET_CHAIN_ICONS__[chainId]}
                    width={24}
                    height={24}
                    initialViewbox
                  />
                </TranslatedTooltip>
              ) : null}
              <Text type="headerTitle">
                {translate("emit_badge_students_sidebar_switch_label")}
              </Text>
            </Box>
            <Toggle
              theme={greyTheme}
              checked={isSbtEnabled}
              onChange={() => toggleSbt(!isSbtEnabled)}
              size="small"
              title={translate("emit_badge_students_sidebar_switch_label")}
              disabled={isToggleDisabled}
            />
          </S.SbtFooter>
          <S.EmitFooterDetails>{handleFooterDetails()}</S.EmitFooterDetails>

          <S.UsersFooter>
            <S.SelectUsers>
              <S.UserImagesContainer>
                {usersToEmitBadge.slice(0, 3).map(user => (
                  <div key={user.id}>
                    <Avatar
                      theme={greyTheme}
                      url={user.avatar?.small}
                      alt="user-avatar"
                      size="s"
                    />
                  </div>
                ))}
              </S.UserImagesContainer>
              <Heading level="6">
                {translate({
                  text: "space_badge_emit_to_students_selected_label",
                  stringVariables: {
                    numberOfStudents: usersToEmitBadge.length
                  }
                })}
              </Heading>
            </S.SelectUsers>
            <Button
              theme={whiteTheme}
              variant="primary"
              disabled={isEmitButtonDisabled}
              onClick={emitBadgeToStudents}
            >
              {translate("space_badge_emit_to_students_primary_button")}
            </Button>
          </S.UsersFooter>
        </S.DrawerFooter>
      ) : null}
    </S.DrawerBodyContainer>
  );
};

export default observer(SelectStudents);
