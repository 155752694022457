import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDebounceCallback, useInterval, useStores } from "src/js/hooks";
import getUserClient from "src/js/modules/connection";
import { getVersionedApiUrl } from "src/js/repository/apiUrls";
import { __API_SESSION_PING_PATH__ } from "src/js/settings/settingsUrl";
import { SESSION_PING_INTERVAL } from "./SessionPing.const";

type SessionPingPayload = {
  spaceId: string;
  groupId?: number;
  isTeacher?: boolean;
  page: string;
};

const SessionPing = () => {
  const {
    SpaceStore: { activeSpaceId },
    GroupStore: { groupId, userIsTeacher }
  } = useStores();
  const location = useLocation();
  const [paused, setPaused] = useState(false);

  const payload: SessionPingPayload = {
    spaceId: activeSpaceId,
    groupId,
    isTeacher: groupId ? userIsTeacher : undefined,
    page: location?.pathname
  };

  const pingAnalytics = () => {
    getUserClient()
      .post(
        `${__API_SESSION_PING_PATH__}/${getVersionedApiUrl("ping")}`,
        payload
      )
      .then(() => {})
      .catch(error => console.log(error));
  };

  const debouncedPingAnalytics = useDebounceCallback(() => {
    pingAnalytics();
  }, 1000);

  useInterval(debouncedPingAnalytics, SESSION_PING_INTERVAL, {
    immediate: true,
    paused
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      setPaused(document?.visibilityState !== "visible");
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return null;
};

export default observer(SessionPing);
