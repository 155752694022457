import colors from "@ds_themes/colors";

export const socialLoginButton = {
  background: colors.grey[1000],
  text: colors.grey[50],
  border: `1px solid ${colors.grey[50]}`,
  hover: {
    border: `1px solid ${colors.violet[501]}`
  },
  focus: {
    border: `3px solid ${colors.violet[700]}`
  }
};
