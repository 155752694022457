import styled from "styled-components";

export const ToolbarContainer = styled.div<{
  isDemoSpaceTopBannerVisible: boolean;
}>`
  display: flex;
  flex-grow: 1;
  width: 60px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  outline: 1px solid ${({ theme }) => theme.primaryColor[800]};
  align-items: center;
  flex-flow: column;
  z-index: ${({ theme }) => theme.zIndex.toolbar};
`;

export const ToolbarLogo = styled.div`
  margin: 8px auto;
  cursor: pointer;
`;

export const ToolbarMenu = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 12px;
  margin-top: 16px;
  gap: 8px;
`;

export const ToolbarFooter = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`;
