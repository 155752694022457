import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { SpaceNotificationParams } from "src/js/types";
import { useNotificationFetcher } from "src/js/components/notification/NotificationDrawer/hooks/useNotificationFetcher";
import { useScrollToBottom, useStores } from "src/js/hooks";
import { NotificationRead } from "src/js/types/models/Notification";
import useNotificationsParameters from "./useNotificationsParameters";

const useNotificationsPage = () => {
  const { area, groupId, read } = useNotificationsParameters();
  const {
    SpaceStore: { activeSpace, setActiveSpaceById },
    NotificationStore: { decreaseAllCounters, increaseAllCounters }
  } = useStores();
  const { space_slug } = useParams<SpaceNotificationParams>();
  const { goBack } = useHistory();

  useEffect(() => {
    if (!space_slug) return;
    setActiveSpaceById(space_slug);
  }, []);

  const readValue = useMemo(() => {
    switch (read) {
      case NotificationRead.All:
        return undefined;
      case NotificationRead.Read:
        return 1;
      case NotificationRead.Unread:
        return 0;
      default:
        return undefined;
    }
  }, [read]);

  const { markAsRead, markAsUnread, ...query } = useNotificationFetcher({
    params: {
      area,
      groupId,
      read: readValue,
      spaceId: groupId ? undefined : activeSpace?.space?.id
    }
  });

  const toggleNotificationStatus = useCallback(
    async (notificationId: string, isRead: boolean) => {
      const action = isRead ? markAsUnread : markAsRead;
      if (!action) return;
      try {
        await action(notificationId);
      } catch (err) {
        console.error("Error marking notification status", err);
      } finally {
        const bellAction = isRead ? increaseAllCounters : decreaseAllCounters;
        const notificationSpaceId = activeSpace?.space?.id;
        const notificationGroupId = query.notifications.find(
          n => n.id === notificationId
        )?.group?.id;
        if (notificationGroupId && notificationSpaceId) {
          bellAction({
            spaceId: activeSpace?.space?.id,
            groupId: notificationGroupId
          });
        }
      }
    },
    [markAsRead, markAsUnread, query.notifications]
  );

  const scrollableRef = useScrollToBottom<HTMLDivElement>(() => {
    if (query.hasNext && !query.isLoadingNextPage) query.fetchNext();
  });

  return {
    ...query,
    scrollableRef,
    activeSpace,
    goBack,
    toggleNotificationStatus
  };
};

export default useNotificationsPage;
