import React from "react";
import { Chip } from "@arcadia/design-system";
import { TranslationKeys, useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import * as S from "./InfoChip.styles";

const InfoChip = ({ text }: { text: TranslationKeys }) => {
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();
  return (
    <S.InfoChip>
      <Chip variant="primary" theme={whiteTheme}>
        {translate(text)}
      </Chip>
    </S.InfoChip>
  );
};

export default InfoChip;
