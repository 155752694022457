import { toJS } from "mobx";
import React, { createContext, FC, PropsWithChildren } from "react";
import { fetchVendors } from "src/js/repository/vendorsRepository";
import {
  AgendaStore,
  AIStore,
  AuthStore,
  BoardsStore,
  CalendarStore,
  ChatStore,
  ConfirmDialogStore,
  DemoSpaceStore,
  ExerciseStore,
  GamificationStore,
  GroupStore,
  ModalStore,
  NotificationFilterStore,
  NotificationStore,
  PostCommentStore,
  ReportStore,
  SpaceAnalyticsFilterStore,
  SpaceBadgesListStore,
  SpaceGroupListStore,
  SpaceMoveGroupsStore,
  SpacesGroupsFilterStore,
  SpacesSettingsStore,
  SpaceStore,
  SpacesUsersFilterStore,
  SpacesUsersRolesStore,
  ThemeStore,
  UIStore,
  UniversityStore,
  UploadStore,
  UserInviteStore,
  UserStore,
  UserTasksStore,
  VendorsStore,
  WebSocketStore,
  WhiteboardStore,
  SpaceBadgesStore
} from "src/js/stores";
import { RootStore } from "../types";

const userStore = new UserStore();

export const spaceStore = new SpaceStore({
  userStore
});

const groupStore = new GroupStore({ spaceStore });

const spaceGroupListStore = new SpaceGroupListStore({
  spaceStore
});

const spaceMoveGroupsStore = new SpaceMoveGroupsStore({
  userStore
});

const spacesSettingsStore = new SpacesSettingsStore({
  spaceStore
});

const uiStore = new UIStore();

const themeStore = new ThemeStore({
  userStore,
  groupStore,
  spacesSettingsStore,
  spaceStore
});

const calendarStore = new CalendarStore({
  groupStore,
  userStore
});

export const rootStore: RootStore = {
  AIStore: new AIStore(),
  AgendaStore: new AgendaStore({
    calendarStore
  }),
  AuthStore: new AuthStore({
    fetchUser: userStore.fetchUser,
    getIsLogged: () => !!userStore.activeUser
  }),
  BoardsStore: new BoardsStore(),
  CalendarStore: calendarStore,
  ChatStore: new ChatStore({ spaceStore, userStore }),
  ConfirmDialogStore: new ConfirmDialogStore(),
  PostCommentStore: new PostCommentStore(),
  DemoSpaceStore: new DemoSpaceStore(),
  ExerciseStore: new ExerciseStore(),
  GamificationStore: new GamificationStore(),
  GroupStore: groupStore,
  ModalStore: new ModalStore(),
  NotificationStore: new NotificationStore({ spaceStore, spaceGroupListStore }),
  NotificationFilterStore: new NotificationFilterStore(),
  SpaceBadgesListStore: new SpaceBadgesListStore({ spaceStore }),
  SpaceGroupListStore: spaceGroupListStore,
  SpaceStore: spaceStore,
  SpaceMoveGroupsStore: spaceMoveGroupsStore,
  SpaceAnalyticsFilterStore: new SpaceAnalyticsFilterStore({
    spaceStore
  }),
  SpacesGroupsFilterStore: new SpacesGroupsFilterStore({
    spaceStore
  }),
  SpacesUsersFilterStore: new SpacesUsersFilterStore({
    spaceStore
  }),
  SpacesUsersRolesStore: new SpacesUsersRolesStore({
    spaceStore
  }),
  SpacesSettingsStore: spacesSettingsStore,
  SpaceBadgesStore: new SpaceBadgesStore(),
  ReportStore: new ReportStore({
    userStore
  }),
  ThemeStore: themeStore,
  UIStore: uiStore,
  UniversityStore: new UniversityStore(),
  UploadStore: new UploadStore(),
  UserStore: userStore,
  UserInviteStore: new UserInviteStore(),
  UserTasksStore: new UserTasksStore({
    userStore
  }),
  VendorsStore: new VendorsStore({
    fetchVendors,
    userStore
  }),
  WebSocketStore: new WebSocketStore({
    userStore
  }),
  WhiteboardStore: new WhiteboardStore({
    groupStore
  })
};

const StoresContext = createContext(rootStore);

type StoreProviderProps = {
  store?: RootStore;
};

const StoreProvider: FC<PropsWithChildren<StoreProviderProps>> = ({
  children,
  store = rootStore
}) => <StoresContext.Provider value={store}>{children}</StoresContext.Provider>;

/**
 *
 * DEBUG IN CHROME CONSOLE ONLY IN LOCAL/DEV MODE
 * @example
 * window.stores.UserStore.method() or property
 * window.MobxDebug(window.stores.UIStore.sidebarIsOpen)
 */
if (
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "local"
) {
  window.rootStore = rootStore;
  window.MobxDebug = value => toJS(value);
}

export { StoreProvider, StoresContext };
