import styled from "styled-components";
import { calendar } from "#themes/grey/calendar";
import { EventCategoryType } from "../Calendar.types";

export const CalendarLabelContainer = styled.div<{
  type: EventCategoryType;
  allDayMode: boolean;
}>`
  border-radius: 4px;
  font-weight: 700;
  font-size: 11px;
  background-color: ${({ type }) =>
    calendar.label[type.toLowerCase()].background};
  color: ${({ type }) => calendar.label[type.toLowerCase()].color};
  display: flex;
  align-items: center;
  padding: 0px 5px;
  height: 100%;
  min-height: ${({ allDayMode }) => (allDayMode ? "25px" : "15px")};
`;

export const IconContainer = styled.span`
  display: inline-flex;
  margin-right: 5px;
`;

export const TextContainer = styled.span`
  flex: 1;
  min-width: 0px;

  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const CalendarLabelWrapper = styled.span<{ type: EventCategoryType }>`
  width: 100%;
  display: block;
  padding: 0px 3px;
  border-left: 3px solid
    ${({ type }) => calendar.label[type.toLowerCase()].color};
`;
