import { Button, Heading, Icon, Text } from "@arcadia/design-system";
import React, { useEffect, useState } from "react";
import { LanguageSelector } from "src/js/components/global/LanguageSelector";
import { useOnboarding, useStores } from "src/js/hooks";
import useLanguageSwitch from "src/js/hooks/useLanguageSwitch";
import { OnboardingLayout } from "src/js/layout/OnoardingLayout";
import { _trackEvent } from "src/js/modules/analyticsFunction";
import { showToastError } from "src/js/modules/messageManager";
import {
  createMarketingData,
  fetchMarketingData,
  updateMarketingData
} from "src/js/repository/marketingDataRepository";
import {
  __TESTIMONIAL_BACKGROUND_1__,
  __TESTIMONIAL_BRAND_1__,
  __TESTIMONIAL_IMAGE_1__
} from "src/js/settings/settingsImage";
import { useTranslation } from "src/js/translation";
import { EventDomain, OnboardingEvent } from "src/js/types";
import {
  MarketingDataUpdate,
  USER_TYPE,
  UserType,
  UserTypeSelectionFields
} from "src/js/types/models/MarketingData";
import { useTheme } from "styled-components";
import { WeSchoolRoutes } from "../../Routes.const";
import LogoutBlock from "../components/LogoutBlock";
import Testimonial from "../components/Testimonial";

import * as S from "./Welcome.styles";

const Welcome = () => {
  const {
    UserStore: { userOnboarding, setUserOnboarding }
  } = useStores();
  const { whiteTheme } = useTheme();
  const { translate } = useTranslation();
  const [userType, setUserType] = useState<UserType>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { flowSwitcher, routeGuard } = useOnboarding();
  const { changeLanguage } = useLanguageSwitch({});

  useEffect(() => {
    routeGuard();
    _trackEvent(EventDomain.Onboarding, OnboardingEvent.StartFlow);
    if (!userOnboarding.userType) {
      fetchMarketingData()
        .then(data => {
          setUserOnboarding({
            ...userOnboarding,
            userType: data.userType,
            step: WeSchoolRoutes.Onboarding.welcome
          });
        })
        // It is acceptable to have a 404 on first step
        // because marketing data does not exist yet
        .catch(() => {});
    }
  }, []);

  const trackInitFlow = (uType: UserType) => {
    switch (uType) {
      case USER_TYPE.BUSINESS:
        _trackEvent(EventDomain.Onboarding, OnboardingEvent.BusinessSpaceFlow);
        break;
      case USER_TYPE.K12:
        _trackEvent(EventDomain.Onboarding, OnboardingEvent.SchoolSpaceFlow);
        break;
      case USER_TYPE.MEMBER:
        _trackEvent(EventDomain.Onboarding, OnboardingEvent.JoinCodeFlow);
        break;
      default:
        break;
    }
  };

  const submitType = () => {
    if (userOnboarding.userType) {
      updateMarketingData({ userType })
        .then((marketingData: MarketingDataUpdate) => {
          trackInitFlow(userType);
          flowSwitcher(marketingData.userType);
        })
        .catch(error => {
          showToastError(error);
        });
    } else {
      createMarketingData({ userType })
        .then((marketingData: UserTypeSelectionFields) => {
          trackInitFlow(userType);
          flowSwitcher(marketingData.userType);
        })
        .catch(error => {
          showToastError(error);
        });
    }
  };

  const changeUserTypeSelected = (type: UserType) => {
    setUserType(type);
    setIsDisabled(false);
  };

  const pageContainer = (
    <S.Container>
      <S.SelectionContainer>
        <S.Section>
          <Heading level="3">
            {translate("onboarding_user_type_create_a_course")}
          </Heading>
          <S.ButtonBlock
            isSelected={userType === USER_TYPE.BUSINESS}
            onClick={() => changeUserTypeSelected(USER_TYPE.BUSINESS)}
          >
            <Icon icon="briefcase" height={24} width={24} />
            <Text type="formField">
              {translate("onboarding_user_type_business")}
            </Text>
          </S.ButtonBlock>
          <S.ButtonBlock
            isSelected={userType === USER_TYPE.K12}
            onClick={() => changeUserTypeSelected(USER_TYPE.K12)}
          >
            <Icon icon="book" height={24} width={24} />
            <Text type="formField">
              {translate("onboarding_user_type_k12")}
            </Text>
          </S.ButtonBlock>
        </S.Section>
        <S.Section>
          <Heading level="3">
            {translate("onboarding_user_type_join_a_course")}
          </Heading>
          <S.ButtonBlock
            isSelected={userType === USER_TYPE.MEMBER}
            onClick={() => changeUserTypeSelected(USER_TYPE.MEMBER)}
          >
            <Icon icon="graduateCap" height={24} width={24} />
            <Text type="formField">
              {translate("onboarding_user_type_student")}
            </Text>
          </S.ButtonBlock>
        </S.Section>
        <S.SubmitWrapper>
          <Button
            variant="primary"
            theme={whiteTheme}
            disabled={isDisabled}
            fullWidth
            onClick={submitType}
          >
            {translate("onboarding_user_type_submit_button")}
          </Button>
        </S.SubmitWrapper>
      </S.SelectionContainer>
      <LogoutBlock />
    </S.Container>
  );

  return (
    <OnboardingLayout
      languageSelector={<LanguageSelector successCallback={changeLanguage} />}
      mainContent={pageContainer}
      sideContent={
        <Testimonial
          background={__TESTIMONIAL_BACKGROUND_1__}
          image={__TESTIMONIAL_IMAGE_1__}
          text={translate("onboarding_testimonial_welcome")}
          author={translate("onboarding_testimonial_welcome_author")}
          brand={__TESTIMONIAL_BRAND_1__}
        />
      }
      mobileCentered
    />
  );
};

export default Welcome;
