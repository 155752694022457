import getUserClient from "src/js/modules/connection";
import {
  getVersionedApiUrl,
  api_vendor_accounts
} from "src/js/repository/apiUrls";

export const fetchVendors = () => {
  return getUserClient()
    .get(getVersionedApiUrl(api_vendor_accounts()))
    .then(({ data }) => {
      return data;
    });
};
