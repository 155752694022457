export const listOfDefaultBadgeImages: string[] = [
  "https://static.weschool.com/badges/default/leaf.png",
  "https://static.weschool.com/badges/default/hand.png",
  "https://static.weschool.com/badges/default/computer.png",
  "https://static.weschool.com/badges/default/draw.png",
  "https://static.weschool.com/badges/default/gear.png",
  "https://static.weschool.com/badges/default/heart.png",
  "https://static.weschool.com/badges/default/help.png",
  "https://static.weschool.com/badges/default/rocket.png"
];

export const __BADGE_IMAGE_ACCEPTED_TYPES__ = "image/png, image/svg+xml";

export const checkIfBadgeHasDefaultImage = (badgeImage: string) => {
  return listOfDefaultBadgeImages.includes(badgeImage);
};

export const MaxNumberOfStudentsToMintNFT = 20;
export const MaxNumberOfStudentsToEmitBadge = 50;
