import React, { useState } from "react";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import { isLightColor } from "src/js/theme/utils";
import { Resource } from "src/js/types";
import ImagePreviewer from "src/legacy/components/imagePreviewer";
import { useTheme } from "styled-components";
import ResourceViewer from "../../resource/ResourceViewer";

const QuizDocumentAnswerItem = ({ item }: { item: Resource }) => {
  const { colors } = useTheme();
  const [resourceView, setResourceView] = useState(null);
  const resource = item;
  const placeholder = getPlaceholder(resource);
  const background = "#8b5cff";

  const show = () => {
    setResourceView(
      <ResourceViewer
        disableOption
        resources={[resource]}
        startingPosition={0}
        onClose={() => setResourceView(null)}
      />
    );
  };

  return (
    <>
      {resourceView}
      <div
        className="post__resource clickable"
        onClick={show}
        style={{
          backgroundColor: background,
          color: isLightColor(background) ? colors.grey[50] : colors.grey[1000],
          display: "flex",
          alignItems: "center",
          padding: "0px 32px"
        }}
      >
        {/* @ts-expect-error - Legacy stuff */}
        <ImagePreviewer
          className="post__resource-image-wrapper"
          src={placeholder}
          type="DIV"
          background={background}
          isReady
        />
        <div className="clearfix">
          <div className="post__resource-text">{resource.name}</div>
        </div>
      </div>
    </>
  );
};

export default QuizDocumentAnswerItem;
