import styled from "styled-components";

export const Legend = styled.ul`
  text-align: center;
  margin: unset;
  list-style-type: none;
`;

export const LegendItem = styled.li`
  display: inline-flex;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[500]};
  gap: 8px;
  align-items: center;
  margin: 0 10px;
`;

export const ColorBox = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 16px;
  height: 12px;
  border-radius: 2px;
`;
