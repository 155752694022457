import { Icon } from "@arcadia/design-system";
import moment from "moment";
import React from "react";
import { ExerciseThreadDarkBox } from "src/js/components/exercises/ExerciseThreadDarkBox";
import { QuickActionButton } from "src/js/components/exercises/QuickActionButton";
import { ColorLabel } from "src/js/components/global/ColorLabel";
import { COLOR_LABEL_TYPE } from "src/js/components/global/ColorLabel/ColorLabel.const";
import { CommentSubtitle } from "src/js/components/typography/CommentSubtitle";
import { CommentTitle } from "src/js/components/typography/CommentTitle";
import { FormFieldTitle } from "src/js/components/typography/FormFieldTitle";
import { Translate } from "src/js/translation/TranslationProvider";
import { TextEditorRender } from "../../TextEditorRender";
import * as S from "./ExerciseThreadTeacherCorrection.styles";

const ExerciseThreadTeacherCorrection = ({
  response = {},
  editFunction,
  deleteFunction,
  actionEnabled = true
}) => {
  const actionButton = actionEnabled ? (
    <S.QuickActionContainer>
      <QuickActionButton onClick={() => editFunction(response.id)}>
        <Icon icon="edit" width={15} height={15} />
      </QuickActionButton>
      <QuickActionButton onClick={() => deleteFunction(response.id)}>
        <Icon icon="trash" width={15} height={15} />
      </QuickActionButton>
    </S.QuickActionContainer>
  ) : (
    ""
  );

  const dateCorrection = moment(response.created_at).format("DD/MM/YYYY HH:mm");
  const timeFromNow = moment(response.created_at).fromNow();

  const vote =
    (response.quiz_execution_points / response.quiz_available_points) * 100;
  const colorLabelType =
    vote < 60
      ? COLOR_LABEL_TYPE.COLOR_LABEL_ERROR
      : COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS;
  const labelText = vote < 60 ? "errata" : "corretta";

  return (
    <ExerciseThreadDarkBox>
      {actionButton}
      <S.CorrectionHeader>
        <CommentTitle>
          <Translate text="quiz_report_correction_of_label" />
          {` ${response.name} ${response.surname}`}
        </CommentTitle>
        <S.CorrectionfromNow>
          <CommentSubtitle>
            {`${dateCorrection} (${timeFromNow})`}
          </CommentSubtitle>
        </S.CorrectionfromNow>
      </S.CorrectionHeader>
      <S.CorrectionText>
        {response.thread_message ? (
          <TextEditorRender content={response.thread_message} />
        ) : null}
      </S.CorrectionText>
      <S.CorrectionFooter>
        <S.CorrectionVote>
          <FormFieldTitle>
            {`${response.quiz_execution_points} / ${response.quiz_available_points} `}
          </FormFieldTitle>
          &nbsp;
          <Translate text="quiz_points_label" />
        </S.CorrectionVote>
        <ColorLabel type={colorLabelType}>
          <Translate text={labelText} />
        </ColorLabel>
      </S.CorrectionFooter>
    </ExerciseThreadDarkBox>
  );
};

export default ExerciseThreadTeacherCorrection;
