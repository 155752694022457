import { Avatar, Button, ContextualAlert, Icon } from "@arcadia/design-system";
import React from "react";
import { useParams } from "react-router";
import { CircularProgressBar } from "src/js/components/CircularProgressBar";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import { useTheme } from "styled-components";
import * as S from "./BoardExerciseSplash.styles";

const BoardExerciseSplashView = ({
  title,
  creatorCompleteName,
  avatar,
  quizzesCount,
  timeThresholdMinutes,
  successThreshold,
  averageMark = 0,
  bestMark = 0,
  user_last_execution_report,
  type,
  usersPassedCount = 0,
  studentsCount = 0,
  isTeacher,
  hiddenResults,
  examinationFailed,
  exerciseExecutionDisabled,
  pendingReply = 0,
  exerciseUrl,
  reportUrl,
  lastSessionUrl,
  hasDecimalGrading = true
}) => {
  const { module_id: moduleId } = useParams();
  const mobile = getBreakpoint() === "smartphone";
  const { whiteTheme } = useTheme();
  const attemptsText = user_last_execution_report ? (
    <>
      0 <Translate text="exercise_splash_attempts" />
    </>
  ) : (
    <>
      1 <Translate text="exercise_splash_attempt" />
    </>
  );

  // fallback to 0 to avoid NaN values
  const averageMarkRounded = Math.floor(averageMark * 10) || 0;
  const bestMarkRounded = Math.floor(bestMark * 10) || 0;
  let studentCountTextDimension = 1;
  if (studentsCount > 99) studentCountTextDimension = 0.7;
  if (studentsCount > 999) studentCountTextDimension = 0.5;

  const isRepeatable =
    isTeacher || type !== "EXAMINATION" || !user_last_execution_report;
  const hasReport = isTeacher || (!hiddenResults && lastSessionUrl);

  return (
    <S.Background>
      <S.CustomModalBody>
        <S.BodyWrapper>
          <S.Header>
            <S.Title>{title}</S.Title>
            {type === "EXAMINATION" && (
              <S.AttemptsTag data-testid="attempts-tag">
                {attemptsText}
              </S.AttemptsTag>
            )}
          </S.Header>
          <S.CreatorInfo>
            <Avatar
              theme={whiteTheme}
              url={avatar}
              alt={`${creatorCompleteName}`}
              size="s"
              rounded
            />
            <S.CreatorInfoText>
              <Translate text="exercise_splash_created_by" />{" "}
              {creatorCompleteName}
            </S.CreatorInfoText>
          </S.CreatorInfo>
          <S.InfoContainer>
            <S.InfoBlock>
              <S.IconSquare>
                <Icon icon="cards" height={16} width={16} />
              </S.IconSquare>
              <S.InfoText>
                <S.InfoDescription>
                  <Translate text="exercise_splash_questions" />
                </S.InfoDescription>
                <S.InfoValue>{quizzesCount}</S.InfoValue>
              </S.InfoText>
            </S.InfoBlock>
            {timeThresholdMinutes > 0 && (
              <S.InfoBlock>
                <S.IconSquare>
                  <Icon icon="clock" height={16} width={16} />
                </S.IconSquare>
                <S.InfoText>
                  <S.InfoDescription>
                    <Translate text="exercise_splash_time" />
                  </S.InfoDescription>
                  <S.InfoValue>
                    {timeThresholdMinutes}{" "}
                    <Translate text="exercise_splash_minutes" />
                  </S.InfoValue>
                </S.InfoText>
              </S.InfoBlock>
            )}
            <S.InfoBlock>
              <S.IconSquare>
                <Icon icon="speedometer" height={16} width={16} />
              </S.IconSquare>
              <S.InfoText>
                <S.InfoDescription>
                  <Translate text="exercise_splash_threshold" />
                </S.InfoDescription>
                <S.InfoValue>
                  {hasDecimalGrading
                    ? `${successThreshold / 10}/10 `
                    : `${successThreshold}% `}
                  <Translate text="exercise_splash_correct" />
                </S.InfoValue>
              </S.InfoText>
            </S.InfoBlock>
          </S.InfoContainer>

          <S.StatsContainer>
            <S.StatsBlock>
              <CircularProgressBar
                sqSize={66}
                percentage={averageMarkRounded}
                textValue={`${
                  hasDecimalGrading
                    ? averageMarkRounded / 10
                    : `${averageMarkRounded}%`
                }`}
                strokeWidth={4}
                textColor="basicBlack"
                internalCircleDistance={4}
                internalCircleColor="basicWhite"
              />
              <S.StatsBlockLabel>
                <Translate text="exercise_splash_average_mark" />
              </S.StatsBlockLabel>
            </S.StatsBlock>
            {isTeacher ? (
              <S.StatsBlock>
                <CircularProgressBar
                  sqSize={66}
                  percentage={
                    studentsCount ? (usersPassedCount / studentsCount) * 100 : 0
                  }
                  textValue={`${usersPassedCount}/${studentsCount}`}
                  textDimension={studentCountTextDimension}
                  strokeWidth={4}
                  gutterColor="transparent"
                  progressColor={
                    usersPassedCount === studentsCount
                      ? "semantic3"
                      : "semantic1"
                  }
                  textColor="basicBlack"
                  internalCircleDistance={4}
                  internalCircleColor="basicWhite"
                />
                <S.StatsBlockLabel>
                  <Translate text="exercise_splash_students_completed" />
                </S.StatsBlockLabel>
              </S.StatsBlock>
            ) : (
              <S.StatsBlock>
                <CircularProgressBar
                  sqSize={66}
                  percentage={bestMarkRounded}
                  textValue={`${bestMarkRounded}%`}
                  strokeWidth={4}
                  gutterColor="transparent"
                  progressColor={
                    bestMarkRounded < successThreshold
                      ? "semantic1"
                      : "semantic3"
                  }
                  textColor="basicBlack"
                  internalCircleDistance={4}
                  internalCircleColor="basicWhite"
                />
                <S.StatsBlockLabel>
                  <Translate text="exercise_splash_threshold_reached" />
                </S.StatsBlockLabel>
              </S.StatsBlock>
            )}
          </S.StatsContainer>

          {hiddenResults && (
            <S.BannerWrapper>
              <ContextualAlert
                theme={whiteTheme}
                text={
                  <Translate text="exercise_splash_examination_hidden_results" />
                }
              />
            </S.BannerWrapper>
          )}
          {examinationFailed && (
            <S.BannerWrapper>
              <ContextualAlert
                theme={whiteTheme}
                text={<Translate text="exercise_splash_examination_failed" />}
              />
            </S.BannerWrapper>
          )}
          {exerciseExecutionDisabled && (
            <S.BannerWrapper>
              <ContextualAlert
                theme={whiteTheme}
                text={
                  <Translate
                    text={
                      type === "EXAMINATION"
                        ? "disabled_test"
                        : "disabled_exercise"
                    }
                  />
                }
              />
            </S.BannerWrapper>
          )}
          {hasReport && pendingReply > 0 && (
            <S.AnchorBanner href={lastSessionUrl}>
              <S.BannerWrapper>
                <ContextualAlert
                  theme={whiteTheme}
                  text={<Translate text="exercise_splash_pending_reply" />}
                />
              </S.BannerWrapper>
            </S.AnchorBanner>
          )}

          <S.Footer>
            {hasReport && (
              <Button
                onClick={() =>
                  navigateTo(isTeacher ? reportUrl : lastSessionUrl)
                }
                theme={whiteTheme}
                variant="secondary"
                fullWidth={mobile}
              >
                <Translate text="exercise_splash_report" />
              </Button>
            )}
            {isRepeatable && !exerciseExecutionDisabled && (
              <Button
                onClick={() =>
                  navigateTo(
                    moduleId ? `${exerciseUrl}?module=${moduleId}` : exerciseUrl
                  )
                }
                theme={whiteTheme}
                variant="primary"
                fullWidth={mobile}
              >
                <Translate text="exercise_splash_do_exercise" />
              </Button>
            )}
          </S.Footer>
        </S.BodyWrapper>
      </S.CustomModalBody>
    </S.Background>
  );
};

export default BoardExerciseSplashView;
