import React, { useEffect } from "react";

const cookieBotDeclarationUrl =
  "https://consent.cookiebot.com/ce5022a0-85dd-4de5-a1bf-44e73677f04e/cd.js";

const EngPolicy = () => {
  useEffect(() => {
    const declarationBlock = document.getElementById("cookieDeclaration");
    if (!declarationBlock) return;
    const script = document.createElement("script");

    script.src = cookieBotDeclarationUrl;
    script.async = true;

    declarationBlock.appendChild(script);
  }, []);

  return (
    <>
      <div
        id="privacy-policy"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <strong>
                    PRIVACY POLICY AS PER ARTICLES 13 AND 14 OF REGULATION (EU)
                    2016/679 (GDPR)
                  </strong>
                </p>

                <p>
                  The Data Protection Officer of WeSchool is Studio E-Lex, with
                  registered office in Rome, 00186, Via Dei Barbieri, 6, VAT no.
                  11514241006. For contacts:{" "}
                  <a href="mailto:rpd@weschool.com">rpd@weschool.com</a>.
                </p>

                <p>
                  <strong>(V13 – 20 February 2023)</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="definitions"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                1. Definitions
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Adult Learners, Cookies, Data Controller, Didactic
                Communications, Direct Marketing, Educators, EEA, GDPR, General
                Terms and Conditions, Groups, Young Learners, Learners, License,
                Organization, Organization Agreement, Parent, Personal Data,
                Platform, Privacy Policy, Profiling, Processing, School,
                Services, Service Agreement, University, University ID, Users,
                WeSchool.{" "}
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <strong>1.1</strong>
                  <b>Adult Learners:</b>
                  <span> Learners who are at least 18 years.</span>
                </p>

                <p>
                  <b>1.2 Cookies:</b>
                  <span>
                    {" "}
                    Cookies are text files (letters and/or numbers) that contain
                    information that is stored on a User’s computer or mobile
                    device each time the User visits a website through a browser
                    or web app. Upon subsequent visits, the browser sends
                    cookies to the website that generated them. Cookies are
                    stored either for a limited period of time for a specific
                    site (i.e. session cookies), or for a longer period of time
                    regardless of browsing time (i.e. persistent cookies).
                  </span>
                </p>

                <p>
                  <b>1.3 Data Controller: </b>
                  <span>
                    the natural or legal person, public authority, agency or
                    other body which, alone or jointly with others, determines
                    the aims and means of the Processing of Personal Data.
                  </span>
                </p>

                <p>
                  <b>1.4 Didactic Communications: </b>
                  <span>
                    communications relating to the availability of materials,
                    initiatives or educational services on the Platform, without
                    any commercial purpose, based on the Organization and on the
                    educational cycle.
                  </span>
                </p>

                <p>
                  <b>1.5 Direct Marketing:</b>
                  <span>
                    {" "}
                    marketing activities carried out by WeSchool, in its
                    interest, by e-mail, relating to the License, the Services
                    offered by WeSchool as an Educator, services, products and
                    initiatives similar to the License, the WeSchool’s Services
                    and the Platform.
                  </span>
                </p>

                <p>
                  <b>1.6 Educators:</b>
                  <span>
                    {" "}
                    Users who use the License to provide their Services to
                    Learners within Groups.
                  </span>
                </p>

                <p>
                  <b>1.7 EEA:</b>
                  <span> “European </span>
                  <i>
                    <span>Economic Area</span>
                  </i>
                  <span>
                    ” composed of the member countries of the European Union,
                    Norway, Iceland and Liechtenstein.
                  </span>
                </p>

                <p>
                  <b>1.8 GDPR:</b>
                  <span> Regulation (EU) 2016</span>{" "}
                  <span>/679 of 27 April 2016.</span>
                </p>

                <p>
                  <b>1.9 General Terms and Conditions: </b>
                  <span>
                    general terms and conditions of the License executed between
                    WeSchool and each User.
                  </span>
                </p>

                <p>
                  <b>1.10 Groups: </b>
                  <span>
                    private groups of Learners created by the Educators within
                    which the Educators provide the Services.
                  </span>
                </p>

                <p>
                  <b>1.11 Young Learners: </b>
                  <span>
                    Learners under the age of 18 who use the License to receive
                    the Services by virtue of specific authorization by a
                    Parent.
                  </span>
                </p>

                <p>
                  <b>1.12 Learners: </b>
                  <span>
                    Users who use the License to receive the Services offered by
                    Educators within Groups.
                  </span>
                </p>

                <p>
                  <b>1.13 License: </b>
                  <span>
                    license of the Platform granted by WeSchool to Users to use
                    the technical and neutral services provided by WeSchool as
                    better described in art. 4.1 of the General Terms and
                    Conditions.
                  </span>
                </p>

                <p>
                  <b>1.14 Organization: </b>
                  <span>
                    a School, a University, a company, a public authority, a
                    non-profit organization, a freelancer or any other legal
                    entity.
                  </span>
                </p>

                <p>
                  <b>1.15 Organization Agreement: </b>
                  <span>
                    an agreement relating to the provision of the License
                    executed between WeSchool and the Organization of reference
                    of the Users.
                  </span>
                </p>

                <p>
                  <b>1.16 Parent: </b>
                  <span>
                    parent and/or guardian who has parental responsibility for a
                    Young Learner.
                  </span>
                </p>

                <p>
                  <b>1.17 Personal Data: </b>
                  <span>
                    any information that directly or indirectly concerns an
                    identified or identifiable natural person such as the
                    person’s name, identification number, location data, online
                    identifiers, or factors of the person’s physical,
                    physiological, genetic, psychic, economic, cultural or
                    social identity.
                  </span>
                </p>

                <p>
                  <b>1.18 Platform:</b>
                  <span>
                    {" "}
                    an online platform aimed at promoting access to culture and
                    education, which allows various forms of interaction between
                    the Users to share their knowledge and skills within an
                    interactive community, where everyone can be both creator
                    and user of educational, training and professional services.
                  </span>
                </p>

                <p>
                  <b>1.19 Privacy Policy: </b>
                  <span>
                    Policy on the Processing of Personal Data and Policy on the
                    Cookies which constitute this document. This Privacy Policy
                    does not concern any Processing of Personal Data conducted
                    through any third-party sites, pages or online services of
                    third parties, reachable by the User through links on the
                    Platform. The Privacy Policy is available and easily
                    accessible from WeSchool’s website (
                  </span>
                  <a href="https://www.weschool.com/">
                    <span>https://www.weschool.com/</span>
                  </a>
                  <span>) or from each User’s profile page.</span>
                </p>

                <p>
                  <b>1.20 Profiling: </b>
                  <span>
                    analysis of Personal Data using automated systems, for
                    evaluation and predictive purposes.
                  </span>
                </p>

                <p>
                  <b>1.21 Processing:</b>
                  <span>
                    {" "}
                    any operation or set of operations concerning Personal Data,
                    such as, by way of example, their collection, organization,
                    structuring, storage, modification, extraction,
                    consultation, use, communication, interconnection,
                    limitation, cancellation and destruction.
                  </span>
                </p>

                <p>
                  <b>1.22 School:</b>
                  <span> educational institution of any order or degree.</span>
                </p>

                <p>
                  <b>1.23 Services: </b>
                  <span>
                    educational, training and professional services provided by
                    Educators in favor of Learners.
                  </span>
                </p>

                <p>
                  <b>1.24 Service Agreement:</b>
                  <span>
                    {" "}
                    agreement governing the sale of the Services offered by the
                    Educators (including WeSchool when it plays the role of
                    Educator), which is executed between, on the one hand, the
                    Learner (or in the case of Young learners, the Parent) and,
                    on the other hand, the Educator offering the Service
                    (including WeSchool when it plays the role of Educator).
                  </span>
                </p>

                <p>
                  <b>1.25 University:</b>
                  <span>
                    {" "}
                    scientific-didactic and cultural institution, public or
                    private, which represents the highest level of education.
                  </span>
                </p>

                <p>
                  <b>1.26 University ID: </b>
                  <span>
                    jointly (i) “eduPersonPrincipalName”, “mandatory field”,
                    represents the unique identifier of the User, this field is
                    necessary to use the login service with the University
                    credentials; (ii) “eduPersonScopedAffiliaton”, “mandatory
                    field”, represents the identification of the type of
                    affiliation to the University, this field is necessary to
                    use the login service with the University credentials; (iii)
                    “email”, “mandatory field”, represents the User’s e-mail
                    address, this field is necessary for the creation of the
                    User’s profile on the Platform; (iv) “givenName”, “optional
                    field”, represents the name of the User, this field is
                    necessary for the creation of the User’s profile on the
                    Platform; (v) “surname”, “optional field”, represents the
                    surname of the User, this field is necessary for the
                    creation of the User’s profile on the Platform.
                  </span>
                </p>

                <p>
                  <b>1.27 Users: </b>
                  <span>
                    all individuals who use the Platform, such as Educators,
                    Learners and Parents.
                  </span>
                </p>

                <p>
                  <strong>
                    1.28 WeSchool:{" "}
                    <span>
                      WeSchool S.r.l., with registered office in Via Guido Reni
                      42, 20133, Milan Tax Code/VAT 07236760968 and Milan
                      Companies Register M-1946066.
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="controller"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                2. Data controller
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Processing strictly connected and functional to the License:
                i) in the case of an Organization Agreement with a School or
                with the University, School or University of reference of the
                Users, or ii) in all cases other than point i), WeSchool <br />
                b) Processing strictly connected and functional to the use of
                the Services: i) in the case of WeSchool in the role of
                Educator, WeSchool; or ii) in the case of Educator other than
                WeSchool, Educator offering the Service <br />
                c) Processing not strictly connected and functional to the
                License and Services: WeSchool
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>a) Processing of Personal Data </span>
                  <b>strictly connected and functional to the License</b>
                  <span>:</span>
                </p>

                <p>
                  <span>
                    i) in case of executing an Organization Agreement with a
                    School or University: the Data Controller is the{" "}
                  </span>
                  <b>School</b>
                  <span> or </span>
                  <b>University</b>
                  <span>
                    {" "}
                    of reference of each User, which carries out the Processing
                    as referred to in paragraph 5 letters b) to f) below,
                    according to its privacy policy (which WeSchool invites to
                    analyze before using the License), and WeSchool operates as
                    data processor, pursuant to art. 28 of the GDPR, on behalf
                    of the School or University, from time to time Data
                    Controller;
                  </span>
                </p>

                <p>
                  <span>
                    ii) in all cases other than point i): the Data Controller is{" "}
                  </span>
                  <b>WeSchool</b>
                  <span>
                    {" "}
                    (as defined in point 1.28 of this Privacy Policy), which
                    carries out the Processing referred to in paragraph 5
                    letters b) to f) below, according to this Privacy Policy;
                  </span>
                </p>

                <p>
                  <span>b) Processing of Personal Data </span>
                  <b>strictly connected and functional to </b>
                  <span>the </span>
                  <b>use of the Services</b>
                  <span>:</span>
                </p>

                <p>
                  <span>
                    i) in case of Services offered by WeSchool as Educator: the
                    Data Controller is{" "}
                  </span>
                  <b>WeSchool</b>
                  <span>
                    {" "}
                    (as defined in point 1.28 of this Privacy Policy), which
                    carries out the Processing referred to in paragraph 5 letter
                    g) below, according to this Privacy Policy;
                  </span>
                </p>

                <p>
                  <span>
                    ii) in the case of Services offered by Educators other than
                    WeSchool: the Data Controller is the{" "}
                  </span>
                  <b>Educator</b>
                  <span>
                    {" "}
                    who carries out the Processing referred to in paragraph 5
                    letter g) below, according to its privacy policy (which
                    WeSchool invites you to analyze before using the Services),
                    it being understood that WeSchool is third party not
                    involved in such Processing;&nbsp;
                  </span>
                </p>

                <p>
                  c) <span>Processing of Personal Data </span>
                  <b>not strictly connected and functional to the</b>{" "}
                  <b>License and Services</b>
                  <span>: the Data Controller is </span>
                  <b>WeSchool</b>
                  <span>
                    {" "}
                    (as defined in point 1.28 of this Privacy Policy), which
                    carries out the Processing referred to in paragraph 5
                    letters a) and h) to l) below, according to this Privacy
                    Policy.&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    Any request by the User relating to the Processing carried
                    out by WeSchool as Data Controller (including the exercise
                    of the rights referred to in paragraphs 12 and 13 below)
                    must be addressed to WeSchool by one of the following
                    means:&nbsp;
                  </span>
                </p>

                <ul>
                  <li>
                    <span>
                      by post to the registered office: WeSchool S.r.l., in Via
                      Guido Reni 42, 20133, Milan;
                    </span>
                  </li>
                  <li aria-level={1}>
                    <span>by e-mail to the following address </span>
                    <a href="mailto:support@weschool.com">
                      <span>support@weschool.com</span>
                    </a>
                    <span>.&nbsp;</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="DPO" className="relative widget w-text-only scrollspy-section">
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                3. Data Protection Officer (DPO)
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Studio E-Lex - rpd@weschool.com
                <br />
                <br />
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  Studio E-Lex, with registered office in Rome, 00186, Via Dei
                  Barbieri, 6, VAT no. 11514241006
                </p>
                <p>
                  For contacts:&nbsp;
                  <a
                    href="mailto:rpd@weschool.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    rpd@weschool.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="universities"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                4. Processing for Universities
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    WeSchool participates in the IDEM National Federation
                    (IDEntity Management for federated access), for the creation
                    of the federated Authentication and Authorization
                    Infrastructure of the GARR network, and in the eduGAIN
                    interfederation (which connects federations of national
                    identities to each other to simplify access to contents,
                    services and resources for the Research and Training
                    community). The Personal Data of Users of the Universities
                    participating in the IDEM National Federation will be
                    protected according to the “
                  </span>
                  <i>
                    <span>Code of Conduct for Service Providers</span>
                  </i>
                  <span>” approved by eduGain, which represents a </span>
                  <i>
                    <span>standard</span>
                  </i>
                  <span> for the protection of </span>
                  <i>
                    <span>privacy</span>
                  </i>
                  <span> in the university and research field. </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="purposes"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                5. Purposes and nature of the Processing
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) navigation on the Platform: necessary and optional
                <br />
                b) in case of Learner, verification of their age: necessary
                <br />
                c) registration with Groups on the d) Platform: necessary
                <br />
                provision of the License: necessary
                <br />
                e) performance of any payments, after identification: necessary
                <br />
                f) verifications or assessments on illegal or fraudulent use of
                the Platform: necessary
                <br />
                g) use of the Services offered by WeSchool as an Educator:
                necessary
                <br />
                h) Direct Marketing by WeSchool: optional
                <br />
                i) Profiling: optional
                <br />
                j) sending commercial and promotional communications on behalf
                of third parties: optional
                <br />
                k) improvement of the License and Services: optional
                <br />
                l) fulfillment of legal obligations or for judicial protection:
                necessary
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    WeSchool collects and uses Users’ Personal Data to achieve
                    the following purposes:
                  </span>
                </p>

                <p>
                  a) <span>Allow </span>
                  <b>navigation on the Platform</b>
                  <span>
                    , essentially through the use of Cookies: the Processing of
                    Personal Data is governed by the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Cookies Policy</span>
                  </span>
                  <span>
                    {" "}
                    set forth in points 14 and 15 below, which WeSchool
                    recommends reading.
                  </span>
                </p>

                <p>
                  <span>
                    b) In case of registration request from Learners,{" "}
                  </span>
                  <b>verify their age</b>
                  <span>
                    {" "}
                    for assessing the lack of legal power to register on the
                    Platform and use the License, without prior and specific
                    authorization of a Parent: the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span>
                    {" "}
                    to allow the Young Learner to access the Platform, and
                    consequently, to register and use the License.&nbsp;
                  </span>
                </p>

                <p>
                  <span>c) Allow Users to </span>
                  <b>register with a Group on</b> <b>the Platform</b>
                  <span> and create a personal account: the </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span>
                    {" "}
                    to allow Users to access to the Platform and, subsequently,
                    use the License and any refusal prevents the User from using
                    the License according to the provisions of the General Terms
                    and Conditions. In particular, on the basis of specific
                    access{" "}
                  </span>
                  <i>
                    <span>forms</span>
                  </i>
                  <span>:</span>
                  <i>
                    <span> (i)</span>
                  </i>
                  <span>
                    {" "}
                    the registration on the Platform and the creation of a
                    personal{" "}
                  </span>
                  <i>
                    <span>account</span>
                  </i>
                  <span>
                    {" "}
                    for Young Learners are performed by a Parent, in the name
                    and on behalf of the Young Learners;{" "}
                  </span>
                  <i>
                    <span>(ii)</span>
                  </i>
                  <span>
                    {" "}
                    the registration of University Users takes place through the
                    insertion by the Users of the University ID and the
                    subsequent communication from the Universities to WeSchool
                    of the Users’ Personal Data (e.g. name and surname).&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    d) Provide the License in favor of Users, including the
                    management of the administrative and accounting relationship
                    deriving from the execution of the General Terms and
                    Conditions and the sending of communications strictly
                    related to the use of the{" "}
                  </span>
                  <b>License</b>
                  <span>
                    {" "}
                    of a technical nature (e.g. notification of upload of a
                    document or video on the Platform or advices on the use of
                    the Platform and the availability of contents of a technical
                    nature on the Platform) and / or of an administrative nature
                    (e.g. updates to this Privacy Policy): the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span>
                    {" "}
                    to allow Users to access to the Platform and subsequently
                    use the License and any refusal prevents the User from using
                    the License according to the General Terms and Conditions.
                  </span>
                </p>

                <p>
                  <span>e) Enable Users to make </span>
                  <b>any payments</b>
                  <span>
                    {" "}
                    provided on the Platform, upon identification of Users, for
                    the Services offered for a fee by WeSchool or by other
                    Educators: the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span>
                    {" "}
                    for the&nbsp; provision or the use by the User of the
                    Services offered for a fee on the Platform, and any refusal
                    prevents the User from providing (in the case of Educators)
                    or using (in the case of Learners) such Services according
                    to the General Terms and Conditions.&nbsp;
                  </span>
                </p>

                <p>
                  <span>f) Verify and ascertain any </span>
                  <b>illegal or fraudulent</b> <b>conduct on the Platform</b>
                  <span>
                    {" "}
                    by Users, such as, for example, uploading and accessing
                    illegal or fraudulent content (such as, for example, content
                    of a violent, pornographic, obscene or discriminatory
                    nature), or harmful to the rights (including the ones
                    connected to image or intellectual property) and interests
                    of third parties (including other Users), or in any case not
                    suitable for supporting the training and education of the
                    Learners: the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span>
                    {" "}
                    to protect the correct use of the Platform, in the interest
                    of all Users, should an act or fact, negligent or willful by
                    a User occur.&nbsp;
                  </span>
                </p>

                <p>
                  <span>g) Allow Learners to </span>
                  <b>use the Services offered by WeSchool as Educator</b>
                  <span>
                    {" "}
                    and manage the related administrative-accounting and
                    after-sales relationship between Learners and WeSchool: the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span> for the use</span>{" "}
                  <span>
                    by the Learner of the Services offered by WeSchool as an
                    Educator on the Platform and any refusal prevents the
                    Learner from using these Services on the basis of the
                    General Terms and Conditions and the Services Agreement
                    between WeSchool and the Learner.
                  </span>
                </p>

                <p>
                  <span>h) Carry out </span>
                  <b>Direct</b> <b>Marketing by WeSchool</b>
                  <span>
                    , by sending information and advertising material, reporting
                    and invitations to promotional initiatives: the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>optional</span>
                  </span>
                  <span>
                    {" "}
                    and any refusal by the User prevents the latter from
                    receiving Direct Marketing by WeSchool.&nbsp;
                  </span>
                </p>

                <p>
                  <span>i) Carry out the </span>
                  <b>Profiling</b>
                  <span>
                    , following optional consent referred to in point 11 below,:
                    the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>optional</span>
                  </span>
                  <span>
                    {" "}
                    and any refusal by the User prevents the latter from taking
                    advantage of Profiling on the preferences expressed by the
                    same on the Platform to customize the License or the
                    Services offered by WeSchool as an Educator (through, for
                    example, the receipt of personalized Didactic
                    Communications) and{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    activities both by WeSchool (referred to in the previous
                    letter h) and third parties (referred to in the following
                    letter j).&nbsp;
                  </span>
                </p>

                <p>
                  <span>j) Carry out </span>
                  <b>
                    <i>marketing</i>
                  </b>{" "}
                  <b>activities on behalf of third parties,</b>
                  <span>
                    {" "}
                    following optional consent, relating to digital products and
                    services useful to Users, such as courses, electronic
                    devices for education and connectivity services, through
                    statistical analysis and market research, sending
                    information and advertising material (also through pop-up
                    advertising on the Platform) and reporting and inviting
                    promotional initiatives by such third parties: the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>optional</span>
                  </span>
                  <span>
                    {" "}
                    and any refusal by the User prevents the latter from using{" "}
                  </span>
                  <i>
                    <span>marketing</span>
                  </i>
                  <span>
                    {" "}
                    activities relating to third parties operating in sectors of
                    interest to Users.
                  </span>
                </p>

                <p>
                  <span>
                    k) Perform statistical analysis, market research,{" "}
                  </span>
                  <b>improve the use of the</b>{" "}
                  <b>License and the Services of WeSchool</b>
                  <span>
                    {" "}
                    by Users. Where possible, to achieve this purpose WeSchool
                    uses anonymous data (data that do not identify or make Users
                    identifiable and which, therefore, do not constitute
                    Personal Data): the{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>Processing of Personal Data</span>
                    <span> is </span>
                    <span>optional</span>
                  </span>
                  <span>
                    {" "}
                    and any refusal by the User prevents the latter from using
                    the License and WeSchool’s Services more akin to User’s own
                    preferences, without prejudice to the use of the
                    License.&nbsp;
                  </span>
                </p>
                <p>
                  <span>
                    l) Comply with legal obligations and enable WeSchool to
                    ascertain, exercise and defend its rights in court or before
                    any other competent authority:{" "}
                  </span>
                  <span>
                    the{" "}
                    <span style={{ textDecoration: "underline" }}>
                      Processing of Personal Data
                    </span>
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span> is </span>
                    <span>necessary</span>
                  </span>
                  <span>
                    {" "}
                    to protect the legal position, rights and interests of
                    WeSchool with regards to the execution, interpretation and
                    fulfilment of the General Terms and Conditions. In
                    particular, if a User uploads content (such as texts, images
                    and videos) on the Platform, WeSchool may retain the User’s
                    Personal Data (including the Parent’s, in the case of a
                    Young Learner), in order to protect itself in case of
                    potential verification or assessments by the competent
                    authorities or claims by third parties deriving from the
                    upload of illegal content or otherwise harmful to the
                    General Terms and Conditions.{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="legal-basis"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                6. Legal Basis of the Processing
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) navigation on the Platform: consent
                <br />
                b) in case of Learner, verify their age: legal obligation
                <br />
                c) registration with Groups on the Platform: acceptance of the
                General Terms and Conditions and related pre-contractual
                measures
                <br />
                d) provision of the License: acceptance of the General Terms and
                Conditions
                <br />
                e) performance of any payments, after identification: acceptance
                of the General Terms Conditions
                <br />
                f) verifications or assessments of illegal or fraudulent use of
                the Platform: acceptance of the General Terms and Conditions
                and, in case of involvement of authorities, legal obligation
                <br />
                g) use of the Services offered by WeSchool as an Educator:
                acceptance of the General Terms and Conditions and the Service
                Agreement
                <br />
                h) Direct Marketing by WeSchool: legitimate interest
                <br />
                i) Profiling: express consent
                <br />
                j) sending commercial and promotional communications on behalf
                of third parties: express consent
                <br />
                k) improvement of the License and Services: legitimate interest
                <br />
                l) fulfillment of legal obligations or for judicial protection:
                legitimate interest
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    If, pursuant to point 2 above, WeSchool is the Data
                    Controller of the Processing carried out for the purposes
                    referred to in point 5 above, the following legal bases
                    apply:
                  </span>
                </p>

                <p>
                  <span>a) </span>
                  <span>Allow </span>
                  <b>navigation on the Platform</b>
                  <span>
                    , essentially through the use of Cookies: see the Cookies
                    Policy.
                  </span>
                </p>

                <p>
                  <span>b) Verify </span>
                  <b>the possession of the age of the Learners</b>
                  <span>
                    , in order to allow Young Learners to register on the
                    Platform and use the License, through prior and specific
                    authorization of the Parent (if necessary by applicable
                    law):{" "}
                  </span>
                  <b>legal obligation</b>
                  <span>
                    , on the part of WeSchool, to verify the age and consequent
                    capacity to act of the Learners who intend to access the
                    Platform.
                  </span>
                  <b>&nbsp;</b>
                </p>

                <p>
                  <span>c) Allow Users </span>
                  <b>to register with a Group</b>
                  <span> on the Platform and create a personal account: </span>
                  <b>execution of the</b> <b>General Terms and Conditions</b>
                  <span>
                    {" "}
                    by the Educators, the Adult Learners and the Parent, in the
                    name and on behalf of the same Parent and the Young Learner,
                    and on the related pre-contractual measures proposed by
                    WeSchool.&nbsp;
                  </span>
                </p>

                <p>
                  <span>d) Provide the </span>
                  <b>License</b>
                  <span> in favor of Users: acceptance of the </span>
                  <b>General Terms and Conditions</b>
                  <span>
                    {" "}
                    by the Educators, the Adult Learners and the Parent, in the
                    name and on behalf of the same Parent and the Young
                    Learner.&nbsp;
                  </span>
                </p>

                <p>
                  <span>e) Allow Users to make </span>
                  <b>any payments</b>
                  <span>, upon identification, provided on the Platform: </span>
                  <b>acceptance of the General Terms and Conditions</b>
                  <span>
                    {" "}
                    by the Educators, the Adult Learners and the Parent, in the
                    name and on behalf of the same Parent and the Young
                    Learner.&nbsp;
                  </span>
                </p>

                <p>
                  <span>f) Verify and ascertain any </span>
                  <b>illegal or fraudulent conduct on the Platform</b>
                  <span> by Users: </span>
                  <b>acceptance of the General Terms and Conditions</b>
                  <span>
                    {" "}
                    by Educators, Adult Learners and Parent, in the name and on
                    behalf of the same Parent and the Young Learner, and related
                    pre-contractual measures proposed by WeSchool and, if the
                    involvement of third parties is necessary or appropriate,
                    any{" "}
                  </span>
                  <b>legal obligation </b>
                  <span>
                    of WeSchool to collaborate with the competent authorities in
                    carrying out verifications or investigations on illegal or
                    fraudulent acts and facts carried out on the Platform.
                  </span>
                </p>

                <p>
                  <span>g) Allow Users to </span>
                  <b>use the Services</b>
                  <span> that are offered by WeSchool as an Educator: </span>
                  <b>acceptance of the General Terms and Conditions</b>
                  <span> and of the</span>
                  <b> Service Agreement</b>
                  <span>
                    {" "}
                    relating to the Services of which WeSchool is an Educator by
                    the Adult Learners and the Parent, in the name and on behalf
                    of same Parent and of the Young Learner.&nbsp;
                  </span>
                </p>

                <p>
                  h) <b>Direct</b> <b>Marketing</b>{" "}
                  <b>by WeSchool: WeSchool’s legitimate</b> <b>interest</b>
                  <span>
                    {" "}
                    consisting of the benefit that WeSchool can obtain from
                    sending commercial communications relating to the License
                    (including advice on the use of the Platform and the
                    availability of content on the Platform of a commercial
                    nature), to the Services offered by WeSchool as an Educator
                    or to services, products and initiatives similar to the
                    License, Services and Platform, involving Users in
                    WeSchool’s course of growth and development. WeSchool
                    considers its legitimate interest as prevailing over the
                    right to privacy of Users. The{" "}
                  </span>
                  <span>
                    <span style={{ textDecoration: "underline" }}>
                      objection to this Processing
                    </span>{" "}
                  </span>
                  <span>
                    can be exercised by the Educator, the Adult Learner or the
                    Parent (on his/her own and in the name and on behalf of the
                    Young Learner) at any time, and{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>without any reason</span>
                  </span>
                  <span>
                    , following the instructions referred to in point 13 of this
                    Privacy Policy.
                  </span>
                </p>

                <p>
                  <span>i) Carry out the </span>
                  <b>Profiling</b>
                  <span>
                    , following optional consent, referred to in point 11 below:
                    freely-given, specific, informed and unambiguous{" "}
                  </span>
                  <b>consent</b>
                  <span>
                    {" "}
                    of the Educator, the Adult Learner and/or the Parent (in the
                    name and on behalf of the Young Learner), expressed by
                    declaration or positive action (e.g.{" "}
                  </span>
                  <i>
                    <span>flag</span>
                  </i>
                  <span>) on the Platform. The </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>withdrawal of consent</span>
                  </span>
                  <span>
                    {" "}
                    can be exercised by the Educator, the Adult Learner and/or
                    the Parent at any time, following the instructions referred
                    to in point 12 letter a) of this Privacy Policy.
                  </span>
                </p>

                <p>
                  <span>j) Carry out </span>
                  <b>marketing</b> <b>activities on behalf</b>{" "}
                  <b>of third parties</b>
                  <span>
                    , following optional consent, relating to digital products
                    and services useful to Learners: freely-given, specific,
                    informed and unambiguous{" "}
                  </span>
                  <b>consent</b>
                  <span>
                    {" "}
                    of the Educator, the Adult Learner and / or the Parent (in
                    the name and on behalf of the Young Learner), expressed by
                    declaration or positive action (e.g.{" "}
                  </span>
                  <i>
                    <span>flag</span>
                  </i>
                  <span>) on the Platform. The withdrawal </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>of consent</span>
                  </span>
                  <span>
                    {" "}
                    can be exercised by the Educator, the Adult Learner or the
                    Parent at any time, following the instructions referred to
                    in point 12 letter a) of this Privacy Policy.&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    k) Perform statistical analysis, market research,{" "}
                  </span>
                  <b>improve the use of the License and WeSchool’s Services </b>
                  <span>by Users: </span>
                  <b>legitimate interest</b>
                  <span>
                    {" "}
                    of WeSchool consisting of the benefit that WeSchool can
                    obtain in offering the License and Services that most fit
                    the preferences expressed by its Users while browsing the
                    Platform and using the License
                  </span>{" "}
                  <span>
                    and the Services. WeSchool considers its legitimate interest
                    as prevailing over the right to privacy of Users. The{" "}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <span>objection to this Processing</span>
                  </span>
                  <span>
                    {" "}
                    (if not performed with anonymous data) can be exercised by
                    the Educator, the Adult Learner or the Parent (in the name
                    and on behalf of the Young Learner) at any time following
                    the instructions referred to in point 12 letter f) of this
                    Privacy Policy.
                  </span>
                </p>

                <p>
                  <span>l) To comply with </span>
                  <b>legal obligations</b>
                  <span>
                    {" "}
                    and enable WeSchool to ascertain, exercise and defend its
                    rights in court or before any other competent authority: i){" "}
                  </span>
                  <b>legitimate interest</b>
                  <span>
                    {" "}
                    of WeSchool consisting in the benefit that WeSchool can
                    obtain in protecting its legal position, its rights and its
                    interests, which WeSchool considers prevailing over the
                    right to privacy of Users; and ii) where the involvement of
                    third parties authorities is necessary or appropriate,
                    WeSchool’s{" "}
                  </span>
                  <b>legal obligation</b>
                  <span>
                    {" "}
                    to cooperate with the competent authorities in carrying out
                    investigations relating to the execution, interpretation and
                    fulfillment of the General Terms and Conditions.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="collect"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                7. How we collect Personal Data
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Personal Data provided by Users on the Platform
                <br />
                b) Personal Data provided by third parties authorized by or
                connected to the Users,such as Organizations
                <br />
                c) Personal Data expressly provided to WeSchool by Users
                <br />
                d) Personal Data automatically collected by the Platform
                (through Cookies)
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    WeSchool collects Personal Data in the following ways:
                  </span>
                </p>

                <p>
                  a){" "}
                  <span>
                    Personal Data entered on the Platform by Users: these are
                    the Personal Data provided, by way of example and not
                    exhaustively, to browse, register and access the Platforms
                    and use the License.
                  </span>
                </p>

                <p>
                  <span>
                    b) Personal Data provided to WeSchool by third parties
                    authorized by, or otherwise connected to, Users: these are
                    Users’ Personal Data provided to WeSchool by other Users,
                    for example, by Educators to create and manage their Groups,
                    or by Schools, Universities (that, based on the University
                    ID, communicate the Personal Data of their Users to
                    WeSchool) or by other Users’ Organizations of reference,
                    prior to the acceptance of the General Terms and Conditions
                    by each User, or by the providers of the Schools’ electronic
                    registers, throughout the period of validity of the General
                    Terms and Conditions.
                  </span>
                </p>

                <p>
                  <span>
                    c) Personal Data expressly provided to WeSchool by Users in{" "}
                  </span>
                  <i>
                    <span>offline</span>
                  </i>
                  <span>
                    {" "}
                    mode: these are the Personal Data provided, by way of
                    example, to WeSchool’s customer service (in case of contact
                    request) or collected during events and / or initiatives
                    organized by WeSchool.
                  </span>
                </p>

                <p>
                  <span>
                    d) Personal Data automatically collected by the Platform:
                    these Personal Data are automatically collected by the
                    Platform, for example user agents or IP addresses of Users,
                    including through Cookies (for more information, WeSchool
                    recommends consulting the Cookies Policy below).&nbsp;
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="categories-recipients"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                8. Possible categories of recipients of Personal Data
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Employees, collaborators, other Users, Users reference
                Organizations, third party providers of IT services, payment
                service providers, competent authorities, technical and
                commercial third parties.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    Personal Data are processed by WeSchool and / or third
                    parties, selected for reliability and competence, and to
                    which they may be communicated as necessary or appropriate,
                    provided that they are within Italy or the EEA. In
                    particular, WeSchool informs Users that Personal Data may be
                    processed by, and/or communicated to:
                  </span>
                </p>

                <p>
                  a) <span>employees and/or collaborators of WeSchool;</span>
                </p>

                <p>
                  <span>
                    b) other Users who participate in the same Group as the User
                    to whom the Personal Data refers;
                  </span>
                </p>

                <p>
                  <span>c) Users’ reference Organizations;</span>
                </p>

                <p>
                  <span>
                    d) third party providers of services necessary to guarantee
                    the functioning of the Platform (by way of example: company
                    that deals with hosting services);
                  </span>
                </p>

                <p>
                  <span>
                    e) third party providers of services necessary to use the
                    License and Services (e.g. services related to the
                    Services);
                  </span>
                </p>

                <p>
                  <span>f) payment service providers;</span>
                </p>

                <p>
                  <span>
                    g) third parties that provide browsing data analysis
                    concerning the Platform;
                  </span>
                </p>

                <p>
                  <span>
                    h) competent authorities to carry out verifications or
                    investigations on illegal or fraudulent acts or facts
                    committed on the Platform;
                  </span>
                </p>

                <p>
                  <span>
                    i) third parties, that provide automatic services for
                    sending newsletters and / or any other type of commercial
                    communication, marketing services, promotion;
                  </span>
                </p>

                <p>
                  <span>
                    j) third parties, subject to the optional consent of the
                    User (in the case of a Young Learner, by the Parent), that
                    provide Profiling;
                  </span>
                </p>

                <p>
                  <span>
                    k) third parties, subject to the optional consent of the
                    User (in the case of Underage Learner, by the Parent), that
                    send commercial and promotional communications concerning
                    their products and services, also after Profiling, and / or
                    that carry out statistical analysis or market research
                    concerning Users.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="transfer"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                9. Transfer of Personal Data outside the EEA (including the EU)
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Yes.
                <br />
                <br />
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  <span>
                    To the extent possible, WeSchool avoids the transfer of
                    Personal Data outside the EEA. However, limited to the
                    services indicated below, some WeSchool service providers
                    may transfer Personal Data to non-EEA Countries indicated
                    below:&nbsp;
                  </span>
                </p>

                <p>
                  a){" "}
                  <span>
                    United Kingdom: by virtue of the United Kingdom’s adequacy
                    decision issued by the European Union Commission on June 28,
                    2021.
                  </span>
                </p>

                <p>
                  <span>
                    i) [Limited to sending newsletters] Three Hearts Digital
                    Ltd. (Email Octopus), with registered office at Keltan
                    House, 115 Mare Street, London, E8 4RU, United Kingdom.
                  </span>
                </p>

                <p>
                  b){" "}
                  <span>
                    United States: waiting for the a new “Trans-Atlantic Data
                    Privacy Agreement”, that was announced by the European
                    Commission and the President of the United States on March
                    25th, 2022, the transfer of Personal Data between UE and
                    U.S.A. is based on the safeguards currently available
                    pursuant to Article 44 of the GDPR and proposed by the
                    following service providers (such as standard data
                    protection clauses or binding corporate rules) and on
                    additional measures designed to protect the Personal Data
                    adopted by the same service providers following
                    recommendation of the competent authorities.
                  </span>
                </p>

                <p>
                  <span>
                    ii. [Limited to sending push-notifications for the App]
                    Google Inc., with registered office at 1600 Amphitheatre
                    Parkway Mountain View, CA 94043, United States;&nbsp;
                  </span>
                </p>

                <p>
                  <span>
                    iii. [Subject to push-notifications for the App] Apple Inc.,
                    with registered office at 1 Apple Park Way Cupertino,
                    California, United States;
                  </span>
                </p>

                <p>
                  <span>
                    iv. [Limited to Payment Services and Anti-Fraud Controls
                    Only] Stripe Inc., with registered office at Corporation
                    Trust Center, 1209 Orange Street, Wilmington, New Castle, DE
                    19801, USA;
                  </span>
                </p>

                <p>
                  <span>v. [Limited to the error targeting of both </span>
                  <i>
                    <span>frontend</span>
                  </i>
                  <span> and </span>
                  <i>
                    <span>backend</span>
                  </i>
                  <span>
                    {" "}
                    Platform] Sentry Inc., with registered office at 45 Fremont
                    Street, 8th Floor, San Francisco, CA 94105;
                  </span>
                </p>

                <p>
                  <span>
                    vi. [Limited to performance monitoring of the Platform{" "}
                  </span>
                  <i>
                    <span>backend</span>
                  </i>
                  <span>
                    ] New Relic Inc., with registered office at 188 Spear
                    Street, Suite 1200, San Francisco, CA 94105;
                  </span>
                </p>

                <p>
                  <span>
                    vii. [Limited to the customer care’s service] Zendesk Inc.,
                    San Francisco Bay Area, California;
                  </span>
                </p>

                <p>
                  <span>
                    viii. [Limited to Mobile app push notifications] OneSignal
                    Inc., with registered office in 2194 Esperanca Avenue Santa
                    Clara, CA 95054;
                  </span>
                </p>

                <p>
                  <span>ix. [Limited to</span>{" "}
                  <span>
                    campaign tracking scripts on the Platform] Meta Platforms
                    Inc., with registered office at Facebook 1 Hacker Way Menlo
                    Park, CA 94025 .
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="storage"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                10. Period of storage of Personal Data (or criteria for
                determining such period)
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                a) Cookies: see Cookies Policy
                <br />
                b) Personal Data for the verification of the age of Young
                Learners: 3 weeks from the registration request
                <br />
                c) Personal Data for registration of the Groups, the use of the
                License, the performance of any payments and the related
                controls: (i) if WeSchool is data Controller, duration of the
                General Terms and Conditions and for a period of 6 months after
                the termination of the General Terms and Conditions; (ii) if the
                School or University is Data Controller, for the period of time
                indicated by the School or the University
                <br />
                d) Personal Data for the use of the Services: (i) if WeSchool
                (as an Educator) is Data Controller, duration of the General
                Terms and Conditions and the Services Agreement and for a period
                of 6 months after the termination of the General Terms and
                Conditions and the Services Agreement; (ii) if the Educator
                (other than WeSchool) is Data Controller, for the period of time
                indicated by the Educator
                <br />
                e) Personal Data for commercial purposes: for a period of 12
                months from the last interaction of the User (in case of
                Profiling) or 24 months (in the absence of Profiling)
                <br />
                f) Personal Data for Profiling and improvement of the License
                and Services: for a period of 12 months from collection
                <br />
                g) Personal Data for legal obligations or judicial protection:
                10 years from collection
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  WeSchool will keep Personal Data for the period of time
                  strictly necessary to achieve the purposes for which the
                  Personal Data were collected, as per point 4 above. Without
                  prejudice to User’s exercise of their right to withdraw their
                  consent as per point 10 a) below or their right to object as
                  per point 10 f) below, WeSchool will keep Personal Data as
                  follows:
                </p>

                <p>
                  a) Cookies: for the purposes as per point 4 a), see the Cookie
                  Policy as per point 12 below;
                </p>

                <p>
                  b) Personal Data to verify whether Users are 18 or older for
                  Non-School Groups: for the purposes under point 4 letter b),
                  if a Parent does not grant authorisation within{" "}
                  <strong>3 (three) weeks</strong> from the Young Learner’s
                  registration request, the Young Learner’s Personal Data will
                  be erased by WeSchool. If the Parent provides authorisation
                  within the above term, letter c) below will apply;
                </p>

                <p>
                  c) Personal Data for the use of the Services: for the purposes
                  under point 4 letters c), d) and e): (i) if WeSchool is the
                  data controller as per point 2 letters a.i) and b), for the{" "}
                  <strong>
                    duration of the General Terms and Conditions and for the
                    following 6 months
                  </strong>
                  , without prejudice to letter f) below; or (ii) if the School
                  or the Company is the data controller as per point 2 a.ii),
                  for the period of time that the School or the Company
                  communicate to WeSchool as indicated in the related privacy
                  policy, without prejudice to letter f) below;
                </p>

                <p>
                  d) Personal Data for commercial communications of WeSchool or
                  third parties: for the purposes as per point 4 letters f) and
                  h), for a period of <strong>12 months</strong> (in case
                  Profiling is associated with marketing activities, if consent
                  to Profiling was previously given); or{" "}
                  <strong>24 months</strong> (in case no Profiling is associated
                  with marketing activities, lacking previous consent to
                  Profiling) following the User’s last interaction with
                  WeSchool, suitable to demonstrate an interest in receiving
                  communications concerning WeSchool (as per point 4 letter f)
                  and/or third parties (as per point 4 letter h), including the
                  collection of consent, the opening of newsletters, or
                  participating in initiatives promoted by WeSchool or by third
                  parties;
                </p>

                <p>
                  e) Personal Data for Profiling and for the improvement of the
                  Services: for the purposes as per point 4 letters g) and i)
                  (the latter only in cases of use of Personal Data and not
                  anonymous data) for a period of <strong>12 months</strong>{" "}
                  after the collection of Personal Data by WeSchool;
                </p>

                <p>
                  f) in any case, WeSchool is authorised to keep, in whole or in
                  part, Personal Data for a maximum of <strong>10 years</strong>{" "}
                  from when they are collected or for a longer period of any
                  legal proceedings, limited to the information necessary to
                  comply with legal obligations and allow WeSchool to ascertain,
                  exercise and defend its rights in court or before any other
                  competent authority. If a User uploads content (e.g. texts,
                  images and videos) to the Platform, WeSchool may keep the
                  User’s Personal Data (including that of the Parent, in the
                  event of a Young Learner) for the term envisaged under this
                  letter f), in order to protect itself in the event of
                  investigations by the competent authorities or claims by third
                  parties deriving from the uploading of unlawful content or
                  content that is in breach of the General Terms and Conditions.
                </p>

                <p>
                  Once these terms expired, WeSchool will automatically erase
                  the Personal Data collected or transform it into an
                  irreversibly anonymous form.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="profiling"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                11. Profiling
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Automated processing of Users’ Personal Data in order to reveal
                their preferences on the Platform, and for evaluation and
                predictive purposes.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>Profiling is carried out by WeSchool as follows:</p>

                <p>
                  a) context: Profiling can be associated both with WeSchool’s
                  offering the Services on the Platform (by means of, for
                  example, personalised Didactic Communication) and with the
                  performance of marketing activities relating to WeSchool
                  and/or third parties, as better described in point 4 above.
                  Therefore, if the User accesses the Platform exclusively to
                  use the Services and objects to WeSchool marketing (point 4
                  lett. f) or has not consented to third-party marketing (point
                  4 lett. h), the Profiling will only be carried out in
                  connection with the Services, for improving for example the
                  content of the Didactic Communication;
                </p>

                <p>
                  b) object: information concerning Users, e.g. age, education,
                  location of the School of reference (where available), the
                  type of Services used (e.g. educational courses);
                </p>

                <p>
                  c) legal basis: consent expressed, for example, by Teachers,
                  Adult Learners, and Parents (on behalf of the Young Learners)
                  flagging a box on the Platform;
                </p>

                <p>
                  d) rationale of Processing: (i) analysis and evaluation of the
                  information collected regarding the Users as per letter b) on
                  a statistical basis through the processing and comparison of
                  all User Personal Data on the Platform; and (ii)
                  classification of Users within homogeneous categories of Users
                  to analyse or make predictions regarding their possible
                  preferences;
                </p>

                <p>
                  e) purpose: to customise the Services and marketing activities
                  based on User possible preferences and, therefore, improve
                  Users’ degree of satisfaction with the Services provided on
                  the Platform and in receiving WeSchool and/or third-party
                  commercial and promotional communications. Profiling is
                  therefore aimed at making predictions regarding the
                  preferences and the future choices of the Users, based on the
                  didactic content of the Platform;
                </p>

                <p>
                  f) effects for Users: use of the Services on the Platform or
                  receipt of commercial communications that are in line with the
                  interests and preferences expressed by Users in browsing the
                  Platform and using the Services. In no way does WeSchool
                  Profiling: i) constitute an automated decision-making process
                  whereby legal or similarly significant effects derive for
                  Users; ii) affect the behaviour and training or purchasing
                  choices of Users; iii) have a prolonged and permanent impact
                  for Users, considering that Personal Data collected by
                  WeSchool can be independently updated at any time by Users; or
                  iv) discriminate against Users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="rights"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                12. User Rights
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Withdrawal of consent, access to Personal Data, rectification or
                completion of Personal Data, erasure of Personal Data,
                limitation of Processing, objection to Processing, portability
                of Personal Data, data breaches, complaints to the competent
                authorities.
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  In accordance with the GDPR, Users, in particular a Parent in
                  the Parent’s and the Young Learner’s name and on their behalf,
                  have the right, for the period as per point 8 of this Privacy
                  Policy, to:
                </p>

                <p>
                  a) with reference to Processing as per point 4 g) and h),
                  withdraw consent at any time without prejudice to the
                  lawfulness of prior Processing, by sending an email to
                  WeSchool or by opting out of WeSchool commercial
                  communications (Article 7 of the GDPR);
                </p>

                <p>
                  b) ask WeSchool for access to the Personal Data and
                  information regarding the Processing, as well as request an
                  electronic copy, unless otherwise specifically requested by
                  the User (Article 15 of the GDPR);
                </p>

                <p>
                  c) request the rectification of the Personal Data and/or have
                  it completed without undue delay (Article 16 of the GDPR);
                </p>

                <p>
                  d) request, for specific reasons (e.g. unlawful processing,
                  withdrawal of consent, non-existence of the purpose for
                  processing), the erasure of the Personal Data without undue
                  delay (Article 17 of the GDPR);
                </p>

                <p>
                  e) in certain circumstances (e.g. inaccurate Personal Data,
                  unlawful processing, exercise of a right before court) request
                  that the Processing be restricted (Article 18 of the GDPR);
                </p>

                <p>
                  f) with reference to the Processing under point 4 f) and i)
                  (in the latter case, if anonymous data were not used), object
                  to the Processing of the Personal Data at any time by sending
                  an email to WeSchool (Article 21 of the GDPR);
                </p>

                <p>
                  g) in cases of automated processing, receive the Personal Data
                  in a readable format, for the purpose of communicating them to
                  a third party, or, where technically feasible, request that
                  WeSchool send the Personal Data directly to the third party
                  (right to Personal Data portability – Article 20 of the GDPR);
                </p>

                <p>
                  h) be informed by WeSchool without undue delay of any breaches
                  or unauthorised access by third parties to WeSchool’s systems
                  that contain the Personal Data (data breaches – Article 34 of
                  the GDPR);
                </p>

                <p>
                  i) lodge a complaint with the supervisory authority of the EU
                  country where the User resides, works or where the User
                  believes that his or her rights have been breached (Article 77
                  of the GDPR).
                </p>

                <p>
                  For additional information on the terms and conditions for
                  exercising their rights, Users can consult the GDPR at{" "}
                  <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=OJ:L:2016:119:TOC">
                    https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=OJ:L:2016:119:TOC
                  </a>
                  , or contact WeSchool as per point 2 of this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="objection"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                13. Objection to Processing concerning WeSchool direct marketing
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                Right to object to receiving commercial and promotional
                communications relating to WeSchool.
                <br />
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div className="md:text-lg md:leading-lg">
                <p>
                  Users, in particular Parents on their own behalf and on behalf
                  of Young Learners, have the right to object to the Processing
                  of their Personal Data as per point 4 letter f), at any time
                  and for any reason, by sending an email to{" "}
                  <a href="mailto:support@weschool.com">support@weschool.com</a>{" "}
                  or by opting out of receiving commercial and promotional
                  communications from WeSchool. If Users exercise this right,
                  WeSchool will stop using the User’s Personal Data for purposes
                  of marketing the Services or services, products or initiatives
                  similar to the Services and the Platform and will not carry
                  out Profiling associated with its direct marketing activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cookies"
        className="relative widget w-text-only scrollspy-section"
      >
        <div className="relative 2xl:container mx-auto">
          <div className="grid grid-cols-12 site-gap w-h3">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                14. Types of Cookies
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 site-gap w-h4">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <h4 className="text-lg leading-lg font-bold">
                1. Technical Cookies, necessary to browsing the Platform
                <br />
                2. Statistical Cookies
                <br />
                3. Marketing Cookies
                <br />
                <br />
              </h4>
            </div>
          </div>

          <div className="grid grid-cols-12 site-gap w-paragraph">
            <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
              <div id="cookieDeclaration" />
            </div>
          </div>
          <div
            id="enabling-blocking-cookies"
            className="relative widget w-text-only scrollspy-section"
          >
            <div className="relative 2xl:container mx-auto">
              <div className="grid grid-cols-12 site-gap w-h3">
                <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
                  <h3 className="text-1.5xl lg:text-3xl leading-1.5xl lg:leading-3xl font-bold">
                    15. Enabling and blocking Cookies
                  </h3>
                </div>
              </div>
              <div className="grid grid-cols-12 site-gap w-h4">
                <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
                  <h4 className="text-lg leading-lg font-bold">
                    Consent, banners, access to browser control panels, Your
                    Online Choices website.
                    <br />
                  </h4>
                </div>
              </div>
              <div className="grid grid-cols-12 site-gap w-paragraph">
                <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-4">
                  <div className="md:text-lg md:leading-lg">
                    <p>
                      Users can enable the Cookies described in point 14 above
                      as indicated on the banner published on the Platform. In
                      any case, disabling technical Cookies described in point
                      14 is not possible, because it would prevent Users from
                      correcting browsing the Platform and/or limit its
                      usability.
                    </p>
                    <p>
                      This being said, Users can modify Cookie settings on the
                      Platform as described in the settings section of the
                      User’s browser:
                    </p>

                    <ul>
                      <li>
                        Google-Chrome:{" "}
                        <a href="https://support.google.com/chrome/answer/95647?hl=en-EN&amp;hlrm=fr&amp;hlrm=en">
                          https://support.google.com/chrome/answer/95647?hl=en-EN&amp;hlrm=fr&amp;hlrm=en
                        </a>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Mozilla-Firefox:{" "}
                        <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop">
                          https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop
                        </a>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Internet Explorer:{" "}
                        <a href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                          https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer
                        </a>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Microsoft Edge:{" "}
                        <a href="https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies">
                          https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies
                        </a>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Safari:{" "}
                        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                          https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                        </a>{" "}
                        <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">
                          https://support.apple.com/it-it/guide/safari/sfri11471/mac
                        </a>
                      </li>
                    </ul>

                    <p>
                      Finally, not only can Users use their browser settings to
                      enable or disable individual marketing Cookies,{" "}
                      <a href="https://www.youronlinechoices.com/">
                        www.youronlinechoices.com
                      </a>{" "}
                      shows a list of the main providers that work with website
                      managers to collect and use information useful for the use
                      of behavioural advertising. Users can deactivate or
                      activate all companies (including WeSchool) or adjust
                      their preferences for each company. Users can use the tool
                      at page www.youronlinechoices.com/it/le-tue-scelte to
                      check all behavioural advertising preferences when
                      browsing the Internet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngPolicy;
