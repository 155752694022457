import { Icon, Text } from "@arcadia/design-system";
import styled, { css } from "styled-components";

export const StyledText = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
`;

export const StyledIcon = styled(Icon)`
  flex: none;
`;

export const ButtonWrapper = styled.button<{ disabledStyle: boolean }>`
  all: unset;
  user-select: none;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[800]};
  transition: 300ms ease-in-out;
  cursor: pointer;
  &:active {
    background-color: ${({ theme }) => theme.colors.grey[900]};
  }
  &:not(:active):hover {
    border-color: ${({ theme }) => theme.primaryColor[500]};
  }
  ${({ disabledStyle }) =>
    disabledStyle &&
    css`
      opacity: 0.5;
      border-color: ${({ theme }) => theme.colors.grey[800]} !important;
      background-color: unset !important;
      cursor: not-allowed;
    `}
`;

export const CardDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;
