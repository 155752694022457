import { Icon, Text } from "@arcadia/design-system";
import React from "react";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { TranslationKeys } from "src/js/translation";
import { useTranslation } from "src/js/translation/TranslationProvider";
import { NotificationAreaLabelModel } from "src/js/types/models/Notification";
import { getIconFromType } from "../../NotificationPage.utils";

import * as S from "./PillToggle.styles";

type PillToggleType = {
  id: string;
  testId?: string;
  type: NotificationAreaLabelModel;
  active?: boolean;
  onChange?: (activeOnChange) => void;
};

const PillToggle = ({
  id,
  testId = "pill-testId",
  type,
  active = false,
  onChange = () => {}
}: PillToggleType) => {
  const isSmartphone = getBreakpoint() === "smartphone";
  const { translate } = useTranslation();

  return (
    <S.PillWrapper
      key={id}
      data-testid={testId}
      type={type}
      isActive={active}
      onClick={() => (isSmartphone ? onChange(!active) : null)}
    >
      <S.IconContainer
        data-testid="icon-container"
        type={type}
        isActive={active}
        aria-hidden
      >
        <Icon icon={getIconFromType(type)} width={12} height={12} />
      </S.IconContainer>
      <S.CheckboxLabel
        htmlFor={`${type} ${id}`}
        isActive={active}
        onClick={e => {
          e.preventDefault();
        }}
        aria-hidden
      >
        <Text type="labelWide">
          {translate(
            `notification_filter_pill_toggle_${type.toLowerCase()}_placeholder` as unknown as TranslationKeys
          )}
        </Text>
      </S.CheckboxLabel>
      <S.FakeCheckbox
        onClick={() => (isSmartphone ? null : onChange(!active))}
        aria-hidden
      >
        <Icon icon={active ? "close" : "plus"} width={12} height={12} />
      </S.FakeCheckbox>
      <S.VisuallyHiddenCheckbox
        type="checkbox"
        id={`${type} ${id}`}
        name={type}
        checked={active}
        onClick={() => onChange(!active)}
        onChange={() => {}}
      />
    </S.PillWrapper>
  );
};

export default PillToggle;
