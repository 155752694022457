import { shadow } from "@arcadia/design-system";
import styled, { css } from "styled-components";

const BaseNavigationStyle = css`
  cursor: pointer;
  border-radius: 8px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &:hover {
    box-shadow: ${shadow[700]};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grey[800]};
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export const MonthNavigationContainer = styled.button`
  ${BaseNavigationStyle}
  width: 32px;
  height: 32px;
`;

export const CalendarContainer = styled.div`
  width: fit-content;
  border-radius: 16px;
  box-shadow: ${shadow[200]};

  & .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.title};
    display: flex;
    flex-wrap: wrap;
    max-width: fit-content;
    padding-bottom: 12px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.blueGrey[900]};

    & .react-datepicker__aria-live {
      position: absolute;
      clip-path: circle(0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
      white-space: nowrap;
    }
  }

  & .react-datepicker__header {
    background-color: white;
    height: 70px;
    padding: 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[900]};
    margin: 0px 16px;

    & .react-datepicker__day-name {
      color: ${({ theme }) => theme.primaryColor[50]} !important;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
    }

    & .react-datepicker__day-name,
    & .react-datepicker__day,
    & .react-datepicker__time-name {
      padding-top: 12px;
      max-width: 50px;
      text-transform: capitalize;
      width: 100%;
      text-align: center;
      user-select: none;

      @media (max-width: 450px) {
        max-width: 32px;
      }
    }
  }

  & .react-datepicker__month-container {
    flex: 1;
    user-select: none;

    & .react-datepicker__month {
      font-size: 14px;
      margin: 0px 12px 12px;

      & .react-datepicker__week {
        height: 40px;
        & .react-datepicker__day--outside-month {
          padding: 0px !important;
          color: ${({ theme }) => theme.colors.grey[800]} !important;
          height: 0px !important;
        }

        // selected (last)
        & .react-datepicker__day--range-start,
        & .react-datepicker__day--selected,
        & .react-datepicker__day--range-end {
          background-color: ${({ theme }) =>
            theme.primaryColor[500]} !important;
          color: ${({ theme }) => theme.colors.grey[1000]} !important;
        }
        & .react-datepicker__day--keyboard-selected {
          background-color: transparent;
          color: ${({ theme }) => theme.colors.grey[50]};
        }
      }

      & .react-datepicker__day-name,
      & .react-datepicker__day,
      & .react-datepicker__time-name {
        color: ${({ theme }) => theme.colors.blueGrey[400]};
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: center;

        display: inline-flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 12px;

        @media (max-width: 450px) {
          margin: 2px 5px 5px 2px;
        }
      }

      & .react-datepicker__day--today {
        color: ${({ theme }) => theme.primaryColor[500]};
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px 4px 0;
  align-items: center;
`;

export const DateContainer = styled.div`
  text-transform: capitalize;
  position: relative;
  color: ${({ theme }) => theme.primaryColor[50]};
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
`;

export const Selector = styled.div`
  position: relative;
`;

export const DateButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
`;
