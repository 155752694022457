import colors from "@ds_themes/colors";

export const primary = {
  background: colors.grey[1000],
  text: colors.violet[501],
  type: "cta",
  border: `1px solid ${colors.grey[1000]}`,
  hover: {
    background: colors.violet[501],
    text: colors.grey[1000]
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  },
  disabled: {
    background: colors.violet[700],
    text: colors.violet[500],
    border: `1px solid ${colors.violet[700]}`
  }
};
