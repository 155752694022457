/* eslint-disable react/no-unstable-nested-components */
import { history } from "Legacy/modules/history";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import createUrl from "src/js/modules/routing";
import LogoutPage from "src/js/pages/App/LogoutPage/LogoutPage";
import { WeSchoolRoutes } from "src/js/pages/Routes.const";
import { ActivateAccount } from "src/js/pages/activate_account";

export const UserVerificationRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={WeSchoolRoutes.UserVerification.activateAccount}
          key="activateAccountRoute"
          component={ActivateAccount}
        />
      </Switch>
      <Route
        path={WeSchoolRoutes.UserVerification.logout}
        component={LogoutPage}
      />
      <Route
        render={() => (
          <Redirect to={createUrl("activate_account", { option: "code" })} />
        )}
      />
    </Router>
  );
};
