import { Button, Text } from "@arcadia/design-system";
import { S3Video } from "src/js/settings/settingsVideo";
import { VideoPlayer } from "src/js/components/VideoPlayer";
import { useTheme } from "styled-components";
import React from "react";
import { useTranslation } from "src/js/translation";
import { ModalLayout } from "src/js/components/modal/ModalLayout";
import { useStores } from "src/js/hooks";
import { LocalStorageKey } from "src/js/modules/storageFunction";
import * as S from "./AnalyticsInfoModal.styles";

const AnalyticsInfoModal = () => {
  const {
    ModalStore: { closeModal }
  } = useStores();
  const { translate } = useTranslation();
  const { whiteTheme } = useTheme();

  const onConfirmModal = () => {
    closeModal();
    localStorage.setItem(LocalStorageKey.NewAnalyticsInfoModal, "true");
  };

  return (
    <S.ModalLayoutWrapper>
      <ModalLayout
        closeModal={closeModal}
        labels={{
          title: translate({ text: "analytics_informing_modal_title" }),
          mobileClose: translate("close")
        }}
        desktopProps={{
          hasAdaptiveWidth: true
        }}
        mobileProps={{
          withAdaptiveHeight: true
        }}
      >
        <S.BodyWrapper>
          <VideoPlayer video={S3Video.AnalyticsInfoModal} />
          <S.ContentWrapper>
            <Text type="formFieldEmpty">
              {translate({ text: "analytics_informing_modal_description" })}
            </Text>
            <Button
              variant="primary"
              theme={whiteTheme}
              fullWidth
              onClick={onConfirmModal}
            >
              {translate({
                text: "analytics_informing_modal_confirmation_button"
              })}
            </Button>
          </S.ContentWrapper>
        </S.BodyWrapper>
      </ModalLayout>
    </S.ModalLayoutWrapper>
  );
};

export default AnalyticsInfoModal;
