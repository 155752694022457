import { shadow, Avatar } from "@arcadia/design-system";
import { motion } from "framer-motion";
import styled from "styled-components";

export const PopoverWrapper = styled(motion.div)`
  position: fixed;
  top: 52px;
  right: 16px;
  z-index: ${({ theme }) => theme.zIndex.forPersistentModal - 2};
  user-select: none;
  width: 380px;
  background-color: ${({ theme }) => theme.primaryColor[900]};
  border: 1px solid ${({ theme }) => theme.primaryColor[800]};
  box-shadow: ${shadow[200]};
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 767px) {
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    transition: all 0.02s ease-in-out;
  }
`;

export const PopoverHeader = styled.div`
  width: 100%;
  justify-content: space-between;
  position: relative;
  display: flex;
  padding: 12px 16px;
  padding-bottom: 0px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.toTheSky};
`;

export const NotificationContent = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  padding-top: 12px;
  cursor: pointer;
`;

export const AvatarImage = styled(Avatar)`
  object-fit: cover;
  width: 40px;
  height: 40px;
`;
