import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";
import { __ } from "../../../modules/localization";
import { truncateTextSimple } from "../../../modules/utility";
import ResourceModel from "../../../models/resourceModel";
import ImagePreviewer from "../../../components/imagePreviewer";
import getPlaceholder from "src/js/pages/resources/getPlaceholderFromResource";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { __IMG_PLACEHOLDER__ } from "src/js/settings/settingsImage";
import { __QUIZ_MATCHING_LENGHT__ } from "src/js/settings/settingsCharLenght";

const QuizMatchingItem = withBackbone(
  createReactClass({
    getDefaultProps() {
      return {
        item: {},
        onClickFunc() {},
        openResourceFunc() {},
        className: "",
        dropIndex: null
      };
    },
    openRourceFunc(item) {
      const self = this;
      // console.log(item);
      const value = item;
      if (isFunction(self.props.openResourceFunc)) {
        self.props.openResourceFunc(value);
      }
    },
    render() {
      const self = this;
      const item = this.props.item;
      const onClickFunc = this.props.onClick;
      const className = this.props.className;
      const dropIndex = this.props.dropIndex;
      let title = item.value;

      if (!isEmpty(item.resource)) {
        title = item.resource.name;
      }

      if (title === "") title = __("risorsa allegata");

      if (item !== null) {
        if (item.resource !== undefined && item.resource.hasOwnProperty("id")) {
          const resMod = new ResourceModel(item.resource);
          const backgroundImage = resMod.get("public_url")
            ? resMod.get("public_url")
            : __IMG_PLACEHOLDER__;
          const is_preview_generated = true;
          const preview_available_generator = resMod.get(
            "preview_available_generator"
          );
          const preview_date_created = resMod.get("created") * 1000;
          const placeholder = getPlaceholder(resMod.attributes || resMod);
          const background = resMod.getBackground();

          // <a href="javascript:void(0)" className="quiz__matching-zoom hidden" title={ __("open resource")} ><span className="glyphicon glyphicon-zoom-in"/></a>

          if (title.length > __QUIZ_MATCHING_LENGHT__[getBreakpoint()]) {
            title = truncateTextSimple(
              title,
              __QUIZ_MATCHING_LENGHT__[getBreakpoint()]
            );
          }

          return (
            <div
              className={[className, "has-image"].join(" ")}
              data-index={item.index}
            >
              <div className="quiz__matching-content clearfix">
                <div
                  className="quiz__matching-description clickable pull-left"
                  onClick={onClickFunc}
                >
                  {title}
                </div>
                <div onClick={this.openRourceFunc.bind(this, item.resource)}>
                  <ImagePreviewer
                    className="quiz__matching-zoom-resource-preview clickable pull-right"
                    src={backgroundImage}
                    is_preview_generated={is_preview_generated}
                    preview_available_generator={preview_available_generator}
                    placeholder={placeholder}
                    background={background}
                    created={preview_date_created}
                    type="DIV"
                    onClick={this.openRourceFunc}
                  />
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className={className} data-index={item.index}>
            <div
              className="quiz__matching-content clickable"
              onClick={onClickFunc}
            >
              <div className="quiz__matching-description mathjax-container">
                {item.value}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          className="quiz__matching-item quiz__matching-droppable js-droppable"
          data-index1={dropIndex}
        >
          <div className="quiz__matching-empty">
            {__("trascina qui l'elemento corretto")}
          </div>
        </div>
      );
    }
  })
);

export default QuizMatchingItem;
