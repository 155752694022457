import colors from "@ds_themes/colors";

export const checkbox = {
  background: colors.violet[501],
  text: colors.grey[1000],
  border: `1px solid ${colors.violet[800]}`,
  checked: {
    background: colors.violet[800],
    text: colors.violet[501],
    border: `1px solid ${colors.violet[501]}`
  },
  disabled: {
    border: `1px solid ${colors.violet[700]}`,
    background: colors.violet[700],
    text: colors.violet[700]
  },
  hover: {
    background: colors.grey[1000],
    border: `1.5px solid ${colors.grey[1000]}`
  },
  focus: {
    border: `${colors.violet[700]} solid 3px`
  }
};
