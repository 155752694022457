import { ClosableFeedbackThemeOptions, Palette } from "@ds_themes/types";

export const createClosableFeedbackTheme = ({
  success
}: Palette): { closablefeedback: ClosableFeedbackThemeOptions } => {
  return {
    closablefeedback: {
      background: success[600],
      text: success[100]
    }
  };
};
