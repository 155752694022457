import React, { useEffect, useState } from "react";
import { useTranslation } from "src/js/translation";
import { useTheme } from "styled-components";
import {
  Box,
  DoodleLoader,
  ModalSizeType,
  SearchBar,
  Text
} from "@arcadia/design-system";
import { useDebounce, useInfiniteScrollFetcher, useStores } from "src/js/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import { observer } from "mobx-react";
import { fetchBadgeRelatedUsers } from "src/js/repository/badgesRepository";
import { BadgeRelatedUserType, SpaceBadge } from "src/js/types";
import { NoResults } from "src/js/components/global/NoResults";
import SpaceBadgeRewardedUser from "./SpaceBadgeRewardedUser";
import * as S from "./SpaceBadgeRewardedUserModal.styles";
import { BadgeRelatedUsersFetchParams } from "../EmitBadgeToStudents/types";

type SpaceBadgeRewardedUserModalProps = {
  closeModal: () => void;
  badge: SpaceBadge;
};

const SpaceBadgeRewardedUserModal = ({
  closeModal,
  badge
}: SpaceBadgeRewardedUserModalProps) => {
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();
  const [filterString, setFilterString] = useState("");
  const [loading, setLoading] = useState(true);
  const filterValueDebounced = useDebounce(filterString, 300);
  const { translate } = useTranslation();
  const { whiteTheme, greyTheme } = useTheme();
  const {
    data: badgeRelatedUsers = [],
    hasMoreResults,
    fetch,
    reset: resetResults,
    fetchNextPage
  } = useInfiniteScrollFetcher<
    BadgeRelatedUserType[],
    BadgeRelatedUsersFetchParams
  >(
    async ({
      limit,
      page: _page,
      badgeId: _badgeId,
      textSearch,
      groupIds,
      showUnassociated
    }) => {
      const data = await fetchBadgeRelatedUsers({
        badgeId: _badgeId,
        textSearch,
        showUnassociated,
        limit,
        groupIds,
        offset: _page * limit
      });
      return data.results;
    },
    { lazy: true, limit: 10 }
  );
  const { id } = badge;

  useEffect(() => {
    setLoading(true);
    fetch(0, {
      badgeId: id,
      textSearch: filterValueDebounced || undefined,
      showUnassociated: false,
      groupIds: []
    }).then(() => setLoading(false));
    return () => resetResults();
  }, [filterValueDebounced]);

  let modalContent: React.ReactNode;

  if (loading) {
    modalContent = <DoodleLoader theme={greyTheme} isMini />;
  } else if (badgeRelatedUsers.length > 0) {
    modalContent = badgeRelatedUsers.map(user => {
      return <SpaceBadgeRewardedUser key={user.id} user={user} badge={badge} />;
    });
  } else {
    modalContent = (
      <Box marginTop={64} marginBottom={64}>
        <NoResults
          title={translate(
            "dashboard_badges_rewarded_users_modal_noresult_title"
          )}
          subtitle={translate(
            "dashboard_badges_rewarded_users_modal_noresult_text"
          )}
        />
      </Box>
    );
  }

  return (
    <S.CustomModalLayout
      closeModal={closeModal}
      labels={{
        title: translate("dashboard_badges_rewarded_users_modal_title"),
        mobileTitle: translate("dashboard_badges_rewarded_users_modal_title"),
        mobileClose: translate("close")
      }}
      desktopProps={{ hasAdaptiveHeight: true, size: ModalSizeType.Normal }}
      mobileProps={{
        withAdaptiveHeight: false,
        withDrag: false
      }}
    >
      <Box id="modal-users-awarded-container">
        <S.SearchBarWrapper>
          <SearchBar
            theme={isLayoutModeMobile ? greyTheme : whiteTheme}
            label="searchQuery"
            value={filterString}
            onChange={event => {
              setFilterString(event.target.value);
            }}
            placeholder={translate(
              "dashboard_badges_rewarded_users_search_placeholder"
            )}
          />
        </S.SearchBarWrapper>
        <Box
          id="scroll-area-container"
          minHeight={400}
          maxHeight={400}
          overflow="auto"
          marginBottom="auto"
        >
          <S.ScrollableArea id="modal-rewarded-user-list">
            <InfiniteScroll
              scrollableTarget="scroll-area-container"
              next={() => {
                fetchNextPage({
                  badgeId: id,
                  textSearch: filterValueDebounced || undefined,
                  showUnassociated: false,
                  groupIds: []
                });
              }}
              dataLength={badgeRelatedUsers.length}
              hasMore={hasMoreResults}
              loader={<DoodleLoader theme={whiteTheme} isMini />}
            >
              {badgeRelatedUsers.length ? (
                <Box paddingLeft={16} marginBottom={12}>
                  <Text type="label">
                    {translate("dashboard_badges_rewarded_users_list_label")}
                  </Text>
                </Box>
              ) : null}
              <S.UserListWrapper>{modalContent}</S.UserListWrapper>
            </InfiniteScroll>
          </S.ScrollableArea>
        </Box>
      </Box>
    </S.CustomModalLayout>
  );
};

export default observer(SpaceBadgeRewardedUserModal);
