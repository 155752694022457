import styled, { css } from "styled-components";

export const ActivateAccountWrapText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 40px 0 20px;
  > span {
    text-align: center;
    white-space: pre-line;
  }
`;

export const ActivateAccountCenterContent = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile
      ? css`
          height: 100%;
        `
      : css`
          margin-top: auto;
          margin-bottom: auto;
        `}
`;

export const ActivateAccountWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 432px;
  margin: auto;
  padding: 0 16px;
  @media screen and (max-width: 767px) {
    max-width: initial;
  }
`;
