import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "styled-components";
import { ListSelector } from "src/js/components/global/ListSelector";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import { useArrayState, useStores } from "src/js/hooks";
import { debounce } from "src/js/modules/commonFunction";
import GroupSelector from "src/js/pages/App/SharePage/components/GroupSelector";
import { filterBoards } from "src/js/repository/shareRepository";
import { __SEARCH_BOARD_LIST_LIMIT__ } from "src/js/settings/settingsPagination";
import { Translate } from "src/js/translation/TranslationProvider";
import { DoodleLoader, Button, Input } from "@arcadia/design-system";

import { Label } from "../forms/Label";
import * as S from "./SaveRecordingModal.styles";

const SaveRecordingStepTwo = ({ onSaveSubmit, storybookMode = false }) => {
  const [filterString, setFilterString] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [paginationStart, setPaginationStart] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [boardsList, addBoards, resetBoards] = useArrayState([]);

  const { whiteTheme, greyTheme } = useTheme();
  const {
    UIStore: { isLayoutModeMobile }
  } = useStores();

  useEffect(() => {
    if (storybookMode) {
      addBoards([
        { id: 222, name: "mockBoard1" },
        { id: 333, name: "mockBoard2" },
        { id: 444, name: "mockBoard3" },
        { id: 555, name: "mockBoard4" }
      ]);
      return () => {};
    }
    filterBoards(filterString, 0, __SEARCH_BOARD_LIST_LIMIT__, selectedGroupId)
      .then(({ boards, has_next }) => {
        addBoards(boards);
        setHasNext(has_next);
      })
      .catch(() => {
        resetBoards([]);
        setHasNext(false);
      });

    return () => {
      resetBoards([]);
      setPaginationStart(0);
    };
  }, [filterString, selectedGroupId]);

  const showNext = () => {
    filterBoards(
      filterString,
      paginationStart + __SEARCH_BOARD_LIST_LIMIT__,
      __SEARCH_BOARD_LIST_LIMIT__,
      selectedGroupId
    ).then(({ boards, has_next }) => {
      setPaginationStart(paginationStart + __SEARCH_BOARD_LIST_LIMIT__);
      addBoards(boards);
      setHasNext(has_next);
    });
  };

  let debounceFunc = null;
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSaveSubmit(selectedBoardId);
      }}
    >
      <Input
        id="search-input"
        hideLabel
        label="search-input"
        rounded
        theme={whiteTheme}
        onChange={event => {
          if (storybookMode) {
            setFilterString(event.target.value);
            setSelectedBoardId(null);
          } else {
            event.persist();
            if (!debounceFunc) {
              debounceFunc = debounce(() => {
                setFilterString(event.target.value);
                setSelectedBoardId(null);
              }, 500);
            }
            debounceFunc();
          }
        }}
        icon="search"
      />
      <Label
        marginTop={20}
        marginBottom={10}
        htmlFor="group-selector-dropdown"
        text="select_group_save_recording_modal_label"
      />
      <GroupSelector
        storybookMode={storybookMode}
        doSelectGroupId={value => {
          setSelectedGroupId(value);
          setSelectedBoardId(null);
        }}
      />
      <Label
        marginBottom={10}
        htmlFor="list-selector-lesson"
        text="select_lesson_save_recording_modal_label"
      />
      <ScrollableDiv maxHeight="240px" id="import-user-group-list">
        <InfiniteScroll
          scrollableTarget="import-user-group-list"
          dataLength={boardsList.length}
          next={showNext}
          hasMore={hasNext}
          loader={<DoodleLoader theme={greyTheme} isMini />}
        >
          {boardsList.length !== 0 ? (
            <ListSelector
              toggleItem={({ id }) => {
                setSelectedBoardId(id);
              }}
              selectedItem={selectedBoardId}
              itemList={boardsList}
            />
          ) : (
            <S.NoBoardsMessage>
              <Translate text="recording_destination_board_list_empty_message" />
            </S.NoBoardsMessage>
          )}
        </InfiniteScroll>
      </ScrollableDiv>
      <S.Footer>
        <Button
          type="submit"
          variant="primary"
          theme={whiteTheme}
          fullWidth={isLayoutModeMobile}
        >
          <Translate text="recording_destination_submit_button" />
        </Button>
      </S.Footer>
    </form>
  );
};

export default SaveRecordingStepTwo;
