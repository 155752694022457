import { useState } from "react";
import { useStores } from "src/js/hooks";
import useSocket from "src/js/hooks/websocket/useSocket";
import { useTranslation } from "src/js/translation";
import { Thread, ThreadUser } from "src/js/types/models/Thread";
import {
  DOMAIN,
  EVENT,
  ThreadIsTypingMessage
} from "src/js/types/models/WebSocket";

const useThreadIsTyping = ({
  userId,
  thread
}: {
  userId: string;
  thread: Thread;
}) => {
  const {
    WebSocketStore: { socket, sendMessage }
  } = useStores();
  const { translate } = useTranslation();
  const [userIsTypingArray, setUserIsTypingArray] = useState<ThreadUser[]>([]);

  let timer: ReturnType<typeof setTimeout>;

  const handleIsTyping = () => {
    if (timer) return;
    timer = setTimeout(() => {
      timer = null;
    }, 2000);

    const threadIsTypingWS: ThreadIsTypingMessage = {
      domain: DOMAIN.CHAT_DOMAIN,
      event: EVENT.IS_TYPING_MESSAGE_EVENT,
      data: {
        isTyping: true,
        userId,
        threadId: thread.id
      }
    };

    sendMessage(threadIsTypingWS);
  };

  const onIsTypingMessage = (message: ThreadIsTypingMessage) => {
    if (
      message?.data?.userId === userId ||
      message?.data?.threadId !== thread.id
    )
      return;
    clearTimeout(timer);
    const messageUserInArray = userIsTypingArray.find(
      user => user.uuid === message?.data?.userId
    );
    if (!messageUserInArray) {
      setUserIsTypingArray(prevState => {
        const usersOfThreadIsTyping = thread?.users?.find(
          user => user.uuid === message?.data?.userId
        );
        return [...prevState, usersOfThreadIsTyping];
      });
    }
    timer = setTimeout(() => {
      setUserIsTypingArray([]);
    }, 2000);
  };

  useSocket({
    socket,
    domain: DOMAIN.CHAT_DOMAIN,
    event: EVENT.IS_TYPING_MESSAGE_EVENT,
    handler: onIsTypingMessage
  });

  const numPeopleTyping = userIsTypingArray?.length;

  let isTypingStringToPars: string;

  switch (numPeopleTyping) {
    case 0:
      isTypingStringToPars = null;
      break;
    case 1:
      isTypingStringToPars = translate("thread_toolbar_subtitle_typing", {
        user: `${userIsTypingArray[0]?.name} ${userIsTypingArray[0]?.surname}`
      });
      break;

    default:
      isTypingStringToPars = translate(
        "thread_toolbar_subtitle_typing_several"
      );
      break;
  }

  const isTypingString = isTypingStringToPars;

  return {
    isTypingString,
    handleIsTyping
  };
};

export default useThreadIsTyping;
