import React, { useEffect, useState } from "react";
import { useStores } from "src/js/hooks";
import { useTheme } from "styled-components";
import { showToastError } from "src/js/modules/messageManager";
import {
  saveReportThreadMessage,
  editReportThreadsCorrection
} from "src/js/repository/reportRepository";
import { Translate } from "src/js/translation/TranslationProvider";
import TextEditor from "src/js/components/TextEditor";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { Toggle, Button } from "@arcadia/design-system";
import { InputPoint } from "../../forms/InputPoint";
import { ColorLabel } from "../../global/ColorLabel";
import { COLOR_LABEL_TYPE } from "../../global/ColorLabel/ColorLabel.const";
import { CommentTitle } from "../../typography/CommentTitle";
import { FormSubtitle } from "../../typography/FormSubtitle";
import * as S from "./ReportTeacherCorrectForm.styles";

const ReportTeacherCorrectForm = ({
  availablePoints = 5,
  exerciseId,
  executionId,
  quizExecutionId,
  editMode = false,
  threadMessageId = null,
  oldResubmissionValue = false,
  oldComment = "",
  oldPoints = "",
  onSaveSubmit = () => {},
  onEditSubmit = () => {}
}) => {
  const [points, setPoints] = useState(oldPoints);
  const [comment, setComment] = useState(oldComment);
  const [evaluationLabel, setEvaluationLabel] = useState(null);
  const [askResubmission, setAskResubmission] = useState(oldResubmissionValue);

  const { ReportStore, UserStore } = useStores();
  const { whiteTheme } = useTheme();

  const handleSubmit = () => {
    const { activeUser } = UserStore;
    saveReportThreadMessage({
      exerciseId,
      executionId,
      userId: activeUser.id,
      quizExecutionId,
      threadMessage: comment,
      points,
      redoExecution: askResubmission
    })
      .then(({ id }) => {
        onSaveSubmit(askResubmission);
        ReportStore.addThreadMessage({
          id,
          quizExecutionId,
          threadMessage: comment,
          points,
          availablePoints,
          messageCorrection: true
        });
      })
      .catch(() => {
        showToastError({
          str: <Translate text="add_thread_correction_error" />
        });
      });
  };

  const handleEditSubmit = () => {
    editReportThreadsCorrection({
      exerciseId,
      executionId,
      quizExecutionId,
      id: threadMessageId,
      text: comment,
      points,
      redoExecution: askResubmission
    })
      .then(() => {
        onEditSubmit(askResubmission);
        ReportStore.editThreadCorrection({
          correctionId: threadMessageId,
          quizExecutionId,
          correctionNew: comment,
          pointsNew: points
        });
      })
      .catch(() => {
        showToastError({
          str: <Translate text="edit_thread_correction_error" />
        });
      });
  };

  useEffect(() => {
    if (points >= 0) {
      if ((points / availablePoints) * 100 < 60) {
        setEvaluationLabel(
          <ColorLabel type={COLOR_LABEL_TYPE.COLOR_LABEL_ERROR}>
            <S.EvaluationText>
              <Translate text="errata" />
            </S.EvaluationText>
          </ColorLabel>
        );
      } else {
        setEvaluationLabel(
          <ColorLabel type={COLOR_LABEL_TYPE.COLOR_LABEL_SUCCESS}>
            <S.EvaluationText>
              <Translate text="corretta" />
            </S.EvaluationText>
          </ColorLabel>
        );
      }
    }
  }, [points]);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        if (editMode) {
          return handleEditSubmit();
        }
        return handleSubmit();
      }}
    >
      <div>
        <div>
          <S.TitleContainer>
            <CommentTitle>
              <Translate text="report_review_answer_title" />
            </CommentTitle>
          </S.TitleContainer>
          <TextEditor content={comment} setContent={data => setComment(data)} />
          <S.InputPointContainer>
            <InputPoint
              maxValue={availablePoints}
              value={points}
              onChange={selectedPoints => setPoints(selectedPoints)}
            />
            {evaluationLabel}
          </S.InputPointContainer>
        </div>
        <S.TeacherFooterContainer>
          <S.ToggleContainer>
            <Toggle
              title={`requestResubmission ${quizExecutionId}`}
              theme={whiteTheme}
              checked={askResubmission}
              onChange={() => {
                setAskResubmission(!askResubmission);
              }}
            />
            <FormSubtitle>
              <S.ToggleLabel>
                <Translate text="report_ask_for_resubmission_toggle_label" />
              </S.ToggleLabel>
            </FormSubtitle>
          </S.ToggleContainer>
          <S.SubmitButton>
            <Button
              type="submit"
              variant="primary"
              theme={whiteTheme}
              fullWidth={getBreakpoint() === "smartphone"}
              disabled={points === ""}
            >
              <Translate text="correggi" />
            </Button>
          </S.SubmitButton>
        </S.TeacherFooterContainer>
      </div>
    </form>
  );
};

export default ReportTeacherCorrectForm;
