export function iso8601DurationToReadable(duration: string) {
  const regex =
    /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(regex);
  const years = matches[1] ? Number(matches[1]) : 0;
  const months = matches[2] ? Number(matches[2]) : 0;
  const days = matches[3] ? Number(matches[3]) : 0;
  const hours = matches[4] ? Number(matches[4]) : 0;
  const minutes = matches[5] ? Number(matches[5]) : 0;
  const seconds = matches[6] ? Number(matches[6]) : 0;

  const readable = [];
  if (years > 0)
    readable.push({
      label: "year_countable",
      duration: years,
      param: "yearCount"
    });
  if (months > 0)
    readable.push({
      label: "month_countable",
      duration: months,
      param: "monthCount"
    });
  if (days > 0)
    readable.push({
      label: "day_countable",
      duration: days,
      param: "dayCount"
    });
  if (hours > 0)
    readable.push({
      label: "hour_countable",
      duration: hours,
      param: "hourCount"
    });
  if (minutes > 0)
    readable.push({
      label: "minute_countable",
      duration: minutes,
      param: "minuteCount"
    });
  if (seconds > 0)
    readable.push({
      label: "second_countable",
      duration: seconds,
      param: "secondCount"
    });

  return readable;
}
