import { LiveDurationThemeOptions, Palette } from "@ds_themes/types";

export const createLivedurationTheme = ({
  blueGrey
}: Palette): { liveduration: LiveDurationThemeOptions } => {
  return {
    liveduration: {
      background: blueGrey[900],
      text: blueGrey[300]
    }
  };
};
