import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

type QueryParams = Record<string, string | string[]>;

const stringifyParams = (params: QueryParams): string => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        searchParams.append(`${key}[]`, value.join(","));
      } else {
        searchParams.append(key, value);
      }
    }
  });
  return searchParams.toString();
};

const parseParams = <T extends QueryParams>(queryString: string): T => {
  const params = new URLSearchParams(queryString);
  const result: Partial<T> = {};
  params.forEach((value, key) => {
    if (key.includes("[]")) {
      const parsedKey = key.replace("[]", "") as keyof T;
      result[parsedKey] = value.split(",") as unknown as T[keyof T];
    } else {
      const parsedKey = key as keyof T;
      result[parsedKey] = value as unknown as T[keyof T];
    }
  });
  return result as T;
};

function useQuery<T extends QueryParams>() {
  const location = useLocation();
  const history = useHistory();

  const parsedQuery = useMemo(
    () => parseParams<Partial<T>>(location.search),
    [location]
  );

  const [params, setParams] = useState<Partial<T>>(parsedQuery || {});

  useEffect(() => {
    if (!parsedQuery) {
      setParams({} as T);
      return;
    }
    setParams(parsedQuery as T);
  }, [parsedQuery]);

  const setQueryParams = (newQueryParams?: Partial<T>) => {
    history.replace({
      search:
        Object.keys(newQueryParams).length !== 0
          ? `?${stringifyParams(newQueryParams)}`
          : null
    });
  };

  return {
    queryParams: params,
    setQueryParams
  };
}

export default useQuery;
