import {
  Avatar,
  DoodleLoader,
  Heading,
  Icon,
  Text,
  colors,
  shadow
} from "@arcadia/design-system";
import { Drawer } from "src/js/components/Drawer";
import { ScrollableDiv } from "src/js/components/global/ScrollableDiv";
import styled, { css } from "styled-components";

type Active = { isActive: boolean; withHover?: boolean };

type WalletConnectedType = { isConnected: boolean };

export const StyledDrawer = styled(Drawer)<{ open: boolean }>`
  padding: 0px;
  color: ${({ theme }) => theme.colors.grey[50]};

  ${({ open }) =>
    open &&
    css`
      box-shadow: -5px 0px 10px rgb(0 0 0 / 5%);
    `};
`;

export const DrawerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

export const BadgeImage = styled.img`
  height: 32px;
  margin-right: 16px;
  margin-left: 12px;
  border-radius: 4px;
`;

export const HeaderIconContainer = styled.div`
  cursor: pointer;
  padding: 8px;
  display: flex;
`;

export const DrawerBodyContainer = styled.div`
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    height: 100%;
  }
`;

export const ScrollableArea = styled(ScrollableDiv)`
  padding: 24px;
`;

export const BadgeTableTitles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  color: ${({ theme }) => theme.colors.grey[300]};
`;

export const NameTitle = styled(Text)`
  width: 100%;
  max-width: 146px;

  @media screen and (max-width: 767px) {
    max-width: 142px;
  }
`;

export const WalletTitle = styled(Text)`
  width: 100%;
  max-width: 94px;
  text-align: center;

  @media screen and (max-width: 767px) {
    max-width: fit-content;
  }
`;

export const BadgeRelatedUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BadgeRelatedUser = styled.div<Active>`
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.primaryColor[500] : theme.colors.grey[800]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.grey[1000]};
  padding: 16px;

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.primaryColor[700]};
      }
    `}
`;

export const UserAvatar = styled(Avatar)`
  margin-right: 12px;
`;

export const UserAvatarAndName = styled.div`
  display: flex;
  align-items: center;
  max-width: 146px;
  width: 100%;

  @media screen and (max-width: 767px) {
    max-width: 142px;
  }
`;

export const UserName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NFTIconContainer = styled.div`
  position: relative;
`;

export const BadgeIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
`;

export const BadgePlaceholder = styled.div`
  background-color: ${({ theme }) => theme.primaryColor[900]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 2px;
`;

export const BadgePlaceholderIcon = styled(Icon)`
  color: ${({ theme }) => theme.primaryColor[700]};
`;

export const WalletStatus = styled.div<WalletConnectedType>`
  display: flex;
  justify-content: center;
  width: 94px;
  padding: 6px 0px;
  border-radius: 4px;
  background-color: ${({ isConnected }) =>
    isConnected ? colors.success[900] : colors.warning[900]};
  color: ${({ isConnected }) =>
    isConnected ? colors.success[400] : colors.warning[500]};
`;

export const WalletStatusIconMobileContainer = styled.div<WalletConnectedType>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ isConnected }) =>
    isConnected ? colors.success[900] : colors.warning[900]};
  color: ${({ isConnected }) =>
    isConnected ? colors.success[400] : colors.warning[500]};
`;

export const DrawerFooter = styled.div`
  box-shadow: ${shadow[700]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[1000]};

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: normal;
    gap: 16px;
  }
`;

export const SelectUsers = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const UserImagesContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    border-radius: 50%;
  }

  & > div:nth-child(1) {
    z-index: 1;
  }

  & > div:nth-child(2) {
    z-index: 2;
    margin-left: -16px;
  }

  & > div:nth-child(3) {
    z-index: 3;
    margin-left: -16px;
  }
`;

export const UsersFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SbtFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const BadgeEmiitedImage = styled.img`
  margin-bottom: 12px;
  width: 72px;
  object-fit: cover;
  border-radius: 4px;
`;

export const BadgeImageInReview = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  object-fit: cover;
`;

export const SuccessMessage = styled(Heading)`
  max-width: 35%;
  text-align: center;
`;

export const AlertContainer = styled.div`
  padding: 0px 24px;
  margin-top: 32px;

  & > div {
    max-width: fit-content;
  }
`;

export const BadgeEmittedUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ReviewButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

export const StudentsFiltersContainer = styled.div``;

export const SearchFiltersContainer = styled.div`
  padding: 0px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const FilterBarContainer = styled.div`
  padding: 0px 24px;
  margin-top: 16px;

  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

export const EmitFooterDetails = styled.div`
  margin-bottom: 16px;
`;

export const FooterDescription = styled.div`
  color: ${({ theme }) => theme.colors.grey[300]};
  max-width: 376px;
`;

export const FooterAlert = styled.div`
  margin-top: 16px;
  & > div:first-child {
    max-width: fit-content;
  }
`;

export const MintingNFTsContainer = styled.div`
  margin-top: 104px;

  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
`;

export const Loader = styled(DoodleLoader)`
  & > div {
    width: 100%;
    height: 104px;

    @media screen and (max-width: 767px) {
      min-height: auto;
    }
  }
`;

export const StepperContainer = styled.div`
  width: 100%;
  max-width: 360px;
  margin: auto;
`;

export const MintingNFTsTitle = styled(Heading)`
  color: ${({ theme }) => theme.primaryColor[500]};
`;

export const TransactionDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const TransactionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[400]};
  text-decoration: underline;
`;

export const TransactionLinkIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.grey[400]};
  margin-left: 4px;
`;
