import { Box, Icon, Text } from "@arcadia/design-system";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import TranslatedTooltip from "src/js/components/TranslatedTooltip";
import { Animations } from "src/js/settings/animations";
import { isEllipsisActive } from "src/js/modules/commonFunction";
import * as S from "./SidebarThreadItem.styles";
import { SidebarThreadItemProps } from "./SidebarThreadItem.types";

const SidebarThreadItem = ({
  id,
  className,
  badge,
  title,
  notification = 0,
  isSelected,
  isMuted,
  onClick,
  numberUsers = 0,
  usersTooltipString = ""
}: SidebarThreadItemProps) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const ellipsableNode = useRef();

  useEffect(() => {
    setHasEllipsis(isEllipsisActive(ellipsableNode.current));
  }, []);

  return (
    <TranslatedTooltip
      placement="top"
      forceHide={!hasEllipsis}
      customTooltip={
        <Box padding="8px" maxWidth="256px" textAlign="center">
          <Text type="formSubtitle">{usersTooltipString}</Text>
        </Box>
      }
    >
      <S.ItemContainer
        className={className}
        isMuted={isMuted}
        id={id}
        onClick={onClick}
        isSelected={isSelected}
      >
        <S.LeftSection>
          <S.ImageContainer>
            {badge}
            {numberUsers > 1 ? (
              <S.UsersNumber>
                <Text type="formSmallCaption">{numberUsers}</Text>
              </S.UsersNumber>
            ) : null}
          </S.ImageContainer>
          <S.Name ref={ellipsableNode}>
            <Text type="formTitle">{title}</Text>
          </S.Name>
        </S.LeftSection>
        <AnimatePresence initial={false}>
          {isMuted && (
            <S.MutedIcon
              isSelected={isSelected}
              key="chat-mute"
              layout
              {...Animations.FadeInRightFadeOutScale}
            >
              <Icon icon="bellCrossed" height={16} width={16} />
            </S.MutedIcon>
          )}
          {notification > 0 && !isMuted ? (
            <S.NotificationNumber
              isSelected={isSelected}
              key="chat-notification-count"
              layout
              {...Animations.FadeInRightFadeOutScale}
            >
              <Text type="formSmallCaption">
                {notification > 9 ? "+9" : notification}
              </Text>
            </S.NotificationNumber>
          ) : null}
        </AnimatePresence>
      </S.ItemContainer>
    </TranslatedTooltip>
  );
};

export default SidebarThreadItem;
