import { observer } from "mobx-react";
import React from "react";
import WeSchoolLogoSVG from "src/img/icons/svg/weschool_logo.svg";
import { useStores } from "src/js/hooks";
import { OnboardingLayoutProps } from "./OnboardingLayout.types";

import * as S from "./OnboardingLayout.styles";

const OnboardingLayout = ({
  languageSelector,
  mainContent,
  stepper = null,
  sideContent,
  mobileCentered = false
}: OnboardingLayoutProps) => {
  const {
    UIStore: { isLayoutModeMobile: isMobile }
  } = useStores();
  return (
    <S.DesktopWrapper>
      <S.MainColumn mobileCentered={mobileCentered}>
        <S.TopSection mobileCentered={mobileCentered}>
          {languageSelector ? (
            <S.LangSelectorWrapper>{languageSelector}</S.LangSelectorWrapper>
          ) : null}
          <S.LogoWrapper>
            <WeSchoolLogoSVG width="146" height="23" viewBox="0 0 207 32" />
          </S.LogoWrapper>
        </S.TopSection>
        <S.MainContainer>
          <S.Container>
            <S.InnerContent>{mainContent}</S.InnerContent>
          </S.Container>
          {!isMobile ? stepper : null}
        </S.MainContainer>
      </S.MainColumn>
      <S.TestimonialColumn>
        {!isMobile ? sideContent : null}
      </S.TestimonialColumn>
    </S.DesktopWrapper>
  );
};

export default observer(OnboardingLayout);
