import React, { useEffect, useState } from "react";
import {
  detectWeschoolImg,
  getResourcesIdsFromQuiz,
  replaceQuizImageUrl
} from "src/js/modules/exercisesFunction";
import { fetchResource } from "src/js/repository/resourceRepository";

interface ExerciseQuizTitleProps {
  quizTitle: string;
}

const ExerciseQuizTitle = ({ quizTitle }: ExerciseQuizTitleProps) => {
  const [title, setTitle] = useState<string>(quizTitle);
  useEffect(() => {
    if (detectWeschoolImg(quizTitle)) {
      const ids = getResourcesIdsFromQuiz(quizTitle);
      ids.forEach(id => {
        fetchResource(id)
          .then(res => {
            setTitle(prevTitle =>
              replaceQuizImageUrl(prevTitle, id, res.public_url)
            );
          })
          .catch(() => {});
      });
    }
  }, [quizTitle]);
  return (
    <div
      className="mathjax-container"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

export default ExerciseQuizTitle;
