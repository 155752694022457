import React from "react";
import createReactClass from "create-react-class";
import withBackbone from "with-backbone";
import { getBreakpoint } from "src/js/modules/layoutFunction";
import { ConfirmationAlert, ActionButton } from "@arcadia/design-system";

import { withConfirmDialogStore } from "src/js/hooks/useStores";
import styled, { withTheme } from "styled-components";
import { showToastSuccess } from "src/js/modules/messageManager";
import { textEditorManager } from "../../../modules/textEditorManager";
import { __, getLanguage } from "../../../modules/localization";
import QuizDocumentAnswerItem from "./QuizDocumentAnswerItem";
import ExerciseResourceUploader from "./ExerciseResourceUploader";

const StyledResourceBox = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;

  > div {
    width: 100%;
    display: flex;
    justify-content: right;
  }
`;
const StyledResourceBoxUploaded = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  align-items: center;
`;

const QuizDocumentAnswer = withBackbone(
  createReactClass({
    editor: "#documentanswer__editor",
    getInitialState() {
      return {
        resource: null
      };
    },
    componentDidMount() {
      const self = this;
      let type = "exercise";

      if (getBreakpoint() !== "desktop") {
        type = "mobile";
      }
      const { quiz } = this.props;
      const answer = quiz.getUserAnswer();
      const answerResource = answer.fullResource ? answer.fullResource : null;

      textEditorManager.destroy("documentanswer__editor");

      const settings = textEditorManager.settings[type];

      /**
       * Override default exercise or mobile option
       * Load mobile when needed, but never with fullscreen
       */
      settings.selector = self.editor;
      settings.plugins = "autolink, paste, link";
      settings.setup = function (editor) {
        /**
         * Set answer both on keyUp and on change
         * because change is only triggered when tinymce adds an undo layer.
         * On IOS, when going to the next quiz, it is not triggered, so we need a keyUp event too.
         */
        editor.on("keyUp", e => {
          self.setAnswer();
        });
        editor.on("change", e => {
          self.setAnswer();
        });
      };

      self.setState({
        resource: answerResource
      });

      textEditorManager.init(self.editor, type, getLanguage(), settings);
    },
    componentWillUnmount() {
      textEditorManager.destroy("documentanswer__editor");
    },
    addResource(resource) {
      const self = this;
      showToastSuccess({ str: __("resource_attach_success_message") });
      self.setState({ resource });
      self.setAnswer();
    },

    hideResourceManager() {
      $(".js-board__detail-container").removeClass("hidden");
      $(".resource-manager-container-wrapper").addClass("hidden");
    },

    setAnswer(e) {
      const self = this;
      const { resource } = this.state;
      const resourceId = resource == null ? 0 : resource.id;
      let comment;
      if (navigator.onLine) {
        comment = textEditorManager.getContent(self.editor);
      } else {
        comment = e;
      }
      const answer = {};
      answer.value = comment;
      answer.resource = resourceId;
      answer.fullResource = resource == null ? {} : resource;
      this.props.quiz.setUserAnswer(answer);
    },
    deleteResource() {
      const self = this;
      const { ConfirmDialogStore, theme } = self.props;
      const { whiteTheme } = theme;

      ConfirmDialogStore.openConfirmDialog(
        <ConfirmationAlert
          theme={whiteTheme}
          text={__("La risorsa sarà eliminata")}
          declineText={__("Annulla")}
          onDeclineFunction={ConfirmDialogStore.closeConfirmDialog}
          acceptText={__("Conferma")}
          onAcceptFunction={() => {
            self.setState({ resource: null });
            ConfirmDialogStore.closeConfirmDialog();
          }}
        />
      );
    },
    render() {
      const self = this;
      const { quiz } = this.props;
      const { resource } = this.state;
      const defaultValue = quiz.getUserAnswer().value || "";
      const { weschoolTheme } = this.props.theme;
      let resourceBox = (
        <ExerciseResourceUploader
          addResource={res => this.addResource(res)}
          openFromTop
        />
      );
      if (resource != null) {
        resourceBox = (
          <StyledResourceBoxUploaded>
            <QuizDocumentAnswerItem item={resource} disableOption />
            <ActionButton
              onClick={this.deleteResource}
              theme={weschoolTheme.grey}
              variant="secondary"
              icon="trash"
            />
          </StyledResourceBoxUploaded>
        );
      }
      return (
        <div className="documentanswer__editor" ref="answersForm">
          <div className="resource-manager-container-wrapper resource_exercise hidden">
            <div className="resource-manager-container" />
            <div className="picker-modal-close">
              <ActionButton
                variant="tertiary"
                theme={weschoolTheme.white}
                icon="close"
                onClick={self.hideResourceManager}
              />
            </div>
          </div>
          <label className="capitalize">{__("commento")}:</label>
          <textarea
            id="documentanswer__editor"
            className="form-control text-editor"
            rows="3"
            // per ovviare al mancato caricamento di tinymce
            onChange={e => {
              this.setAnswer(e.target.value);
            }}
            placeholder={__("inserisci la tua risposta")}
            defaultValue={defaultValue}
            data-index="0"
          />
          <StyledResourceBox>{resourceBox}</StyledResourceBox>
          <div className="clearfix" />
        </div>
      );
    }
  })
);

export default withTheme(withConfirmDialogStore(QuizDocumentAnswer));
