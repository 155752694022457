import React, { useEffect, useState, FC } from "react";
import { useTheme } from "styled-components";
import { __BOARD_ELEMENT_TITLE_MAX_LENGTH__ } from "src/js/settings/settingsCharLenght";
import { Icon, Input } from "@arcadia/design-system";
import * as S from "./EditBoardTitleElement.styles";
import { EditBoardTitleElementTypes } from "./types";

const EditBoardTitleElement: FC<EditBoardTitleElementTypes> = ({
  value,
  editFunction = () => {}
}) => {
  const [editMode, setEditMode] = useState(false);
  const [titleValueState, setTitleValueState] = useState(value);
  const { whiteTheme } = useTheme();

  const handleSubmission = () => {
    if (titleValueState && titleValueState !== value) {
      editFunction(titleValueState);
    }
    setTitleValueState(value);
    setEditMode(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitleValueState(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmission();
    }
  };

  useEffect(() => {
    setTitleValueState(value);
  }, [value]);

  return editMode ? (
    <S.InputWrapper onKeyPress={handleKeyPress}>
      <Input
        name="title"
        label="title"
        theme={whiteTheme}
        hideLabel
        value={titleValueState}
        onBlur={handleSubmission}
        onChange={handleInputChange}
        type="textarea"
        maxLength={__BOARD_ELEMENT_TITLE_MAX_LENGTH__}
        autofocus
      />
    </S.InputWrapper>
  ) : (
    <S.TitleContainer onClick={() => setEditMode(true)}>
      <S.TitleText data-testid="sidebar-title-element-testid">
        {value}
      </S.TitleText>
      <S.IconContainer>
        <Icon icon="pencil" height={16} width={16} />
      </S.IconContainer>
    </S.TitleContainer>
  );
};

export default EditBoardTitleElement;
