import { BadgeRelatedGroupType, ObjectValues } from "src/js/types";

export type BadgeRelatedGroupsFetchParams = {
  badgeId: string;
  textSearch: string;
  showUnassociated: boolean;
};

export const AssociateGroupStep = {
  SELECT_GROUPS: "select-groups",
  REVIEW_SELECTED_GROUPS: "review-selected-groups"
} as const;

export type AssociateGroupStepType = ObjectValues<typeof AssociateGroupStep>;

export type SelectGroupsTypes = {
  filterString: string;
  setFilterString: (value: string) => void;
  fetchNextPage: (args?: BadgeRelatedGroupsFetchParams) => void;
  filterValueDebounced: string;
  hasMoreResults: boolean;
  setStep: (step: AssociateGroupStepType) => void;
  badgeRelatedGroups: BadgeRelatedGroupType[];
};
