import React, { FC, useState } from "react";
import { translateString } from "src/js/translation/TranslationProvider";
import { __AVAILABLE_LANG__ } from "src/js/settings/settingsLocalization";
import { readLocalData } from "src/js/modules/storageFunction";
import { __COOKIE_LANG__ } from "src/js/settings/settingsCookie";
import { CountryLanguageModal } from "src/js/components/CountryLanguageModal";
import { Text, Icon } from "@arcadia/design-system";
import { useStores } from "src/js/hooks";
import * as S from "./UnloggedLanguageSelector.styles";

const UnloggedLanguageSelector: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { ModalStore, UIStore } = useStores();
  const { isLayoutModeMobile: isMobile } = UIStore;
  const language = __AVAILABLE_LANG__[readLocalData(__COOKIE_LANG__)];

  const closeModal = () => {
    if (isMobile) {
      setModalOpen(false);
      return;
    }
    ModalStore.closeModal();
  };

  const showModal = () => {
    if (isMobile) {
      setModalOpen(true);
      return;
    }
    ModalStore.openModal(() => (
      <CountryLanguageModal closeModal={closeModal} />
    ));
  };

  const sizeGlobeIcon = isMobile ? 20 : 16;

  return (
    <>
      {modalOpen && <CountryLanguageModal closeModal={closeModal} />}
      <S.UnloggedLanguageSelectorWrap isMobile={isMobile} onClick={showModal}>
        <Icon icon="globe" height={sizeGlobeIcon} width={sizeGlobeIcon} />
        {!isMobile && (
          <Text type="captionAlert">{translateString({ text: language })}</Text>
        )}
        <Icon icon="chevronSmallDown" height={14} width={14} />
      </S.UnloggedLanguageSelectorWrap>
    </>
  );
};

export default UnloggedLanguageSelector;
