import styled, { css } from "styled-components";

export const Container = styled.div<{
  addContentBoxVisible: boolean;
  isLocked?: boolean;
}>`
  position: relative;
  height: 334px;
  background-color: ${({ theme, addContentBoxVisible }) =>
    addContentBoxVisible ? theme.colors.grey[1000] : undefined};
  border: ${({ theme, addContentBoxVisible }) =>
    addContentBoxVisible
      ? undefined
      : `1px dashed ${theme.colors.blueGrey[500]}`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  cursor: ${({ addContentBoxVisible, isLocked }) =>
    // eslint-disable-next-line no-nested-ternary
    addContentBoxVisible ? "default" : isLocked ? "not-allowed" : "pointer"};

  @media screen and (max-width: 767px) {
    height: ${({ addContentBoxVisible }) =>
      addContentBoxVisible ? undefined : "40px"};

    span,
    svg {
      color: ${({ theme, isLocked }) =>
        isLocked ? theme.colors.violet[700] : ""} !important;
    }
  }
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    aspect-ratio: 0.9;
  }
`;

export const LockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
  width: 196px;
`;

const IconWrapperBase = css`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.grey[1000]};
  @media screen and (max-width: 767px) {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
  }
`;

export const ModuleNumberWrapper = styled.div`
  ${IconWrapperBase}
  background-color: ${({ theme }) => theme.colors.violet[501]};
`;

export const IconLockWrapper = styled.div`
  ${IconWrapperBase}
  background-color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 767px) {
    flex-flow: row;
  }
`;
