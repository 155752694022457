import { ReactNode } from "react";
import { ObjectValues } from "src/js/types";

export const LabelPositions = {
  FromLeft: "FROM_LEFT",
  FromTop: "FROM_TOP"
};

export type LabelPositionsType = ObjectValues<typeof LabelPositions>;

export const InputVariant = {
  Light: "light",
  Dark: "dark"
};

export type InputVariantType = ObjectValues<typeof InputVariant>;

export type ColorPickerInputProps = {
  id: string;
  name: string;
  label: ReactNode | string;
  hasError?: boolean;
  errorMessage?: string;
  type?: string;
  labelDirection: LabelPositionsType;
  variant: InputVariantType;
  defaultValue: string;
  onChange: (color: string) => void;
  resetColor: string;
  disabled?: boolean;
};
