import { agenda } from "#themes/grey/agenda";
import { Heading, Text } from "@arcadia/design-system";
import React from "react";
import { AgendaEventCard } from "src/js/pages/agenda/AgendaEventCard";
import { ThemeProvider } from "styled-components";
import { format, isToday as isCurrentDay } from "date-fns";
import * as S from "./AgendaDayContainer.styles";

const AgendaDayContainer = ({
  date,
  groupId,
  events = [],
  userIsTeacher = false,
  selectedEventId,
  selectedEventAgendaKey,
  setSelectedEventAgendaKey,
  openPopupCallback = () => {},
  openRecipientsModal = () => {},
  openResource = () => {},
  deleteCalendarEvent = () => {},
  editCalendarEvent = () => {},
  copyCalendarEvent = () => {}
}) => {
  if (!events || events.length === 0) return null;
  const momentDate = date && new Date(date);
  const isToday = momentDate && isCurrentDay(momentDate);

  return (
    <ThemeProvider theme={agenda}>
      <S.CardContainer isToday={isToday}>
        {isToday && <S.TodayCircle />}
        <S.EventDate>
          <Text type="formSubtitle">{format(momentDate, "iii")}</Text>
          <S.EventDateNumber>
            <Heading level="3">{format(momentDate, "dd")}</Heading>
          </S.EventDateNumber>
          <Text type="formSubtitle">{format(momentDate, "MMM")}</Text>
        </S.EventDate>
        <S.EventList>
          {events &&
            events.map(
              ({ id, type, title, lastAllDay, start, end, eventDetail }) => {
                return (
                  <AgendaEventCard
                    id={id}
                    key={id}
                    type={type}
                    title={title}
                    allDay={lastAllDay}
                    start={start}
                    end={end}
                    groupId={groupId}
                    eventDetail={eventDetail}
                    selectedEventId={selectedEventId}
                    selectedEventAgendaKey={selectedEventAgendaKey}
                    setSelectedEventAgendaKey={setSelectedEventAgendaKey}
                    agendaDate={date}
                    userIsTeacher={userIsTeacher}
                    openPopupCallback={openPopupCallback}
                    openRecipientsModal={openRecipientsModal}
                    openResource={openResource}
                    deleteCalendarEvent={deleteCalendarEvent}
                    editCalendarEvent={editCalendarEvent}
                    copyCalendarEvent={copyCalendarEvent}
                  />
                );
              }
            )}
        </S.EventList>
      </S.CardContainer>
    </ThemeProvider>
  );
};

export default AgendaDayContainer;
