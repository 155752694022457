import { TranslationKeys } from "src/js/translation";
import { DashboardRangeTime, DashboardRangeTimeType } from "src/js/types";

export const RangeTimeOptions: {
  key: DashboardRangeTimeType;
  value: TranslationKeys;
}[] = [
  { key: DashboardRangeTime.LastDay, value: "last_day_graph_interval" },
  { key: DashboardRangeTime.Last7Days, value: "last_7_days_graph_interval" },
  { key: DashboardRangeTime.LastMonth, value: "last_month_graph_interval" },
  { key: DashboardRangeTime.Last3Month, value: "last_3_months_graph_interval" },
  { key: DashboardRangeTime.Last6Month, value: "last_6_months_graph_interval" },
  { key: DashboardRangeTime.LastYear, value: "last_year_graph_interval" }
];
