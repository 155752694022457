import { LiveButtonThemeOptions, Palette } from "@ds_themes/types";

export const createLiveButtonTheme = ({
  grey
}: Palette): { livebutton: LiveButtonThemeOptions } => {
  return {
    livebutton: {
      background: grey[50],
      text: grey[1000]
    }
  };
};
