import { LinkButton } from "@arcadia/design-system";
import styled from "styled-components";

export const Bold = styled.b`
  font-weight: 600;
`;

export const PlanCardDetailBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    display: block;
  }
`;

export const PlanCardDetailFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLinkButton = styled(LinkButton)`
  padding: 8px 0;
`;
