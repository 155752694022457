import styled from "styled-components";

export const AvatarWrapper = styled.div<{ isSelected: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
`;
