/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Icon, SpecificError } from "@arcadia/design-system";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useOnClickOutSide } from "src/js/hooks";
import { useTheme } from "styled-components";
import { Label } from "../Label";
import * as S from "./ColorPickerInput.styles";
import {
  ColorPickerInputProps,
  InputVariant,
  LabelPositions
} from "./ColorPickerInput.types";

// type ColorPickerInputProps = Omit<InputProps, "isPassword" | "rightSection">;

/**
 * @name ColorPickerInput
 *
 * @description
 * ColorPicker with Input component, will handle ref for form validation
 */
const ColorPickerInput = forwardRef<HTMLInputElement, ColorPickerInputProps>(
  (
    {
      id,
      name,
      label = null,
      hasError = false,
      errorMessage = "",
      type = "text",
      labelDirection = LabelPositions.FromLeft,
      variant = InputVariant.Dark,
      defaultValue = null,
      onChange = () => {},
      resetColor = null,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const { whiteTheme, colors } = useTheme();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [selectedColor, setSelectedColor] = useState(
      defaultValue || colors.violet[501]
    );
    const pickerRef = useRef();
    useOnClickOutSide(pickerRef, () => setDisplayColorPicker(false));

    useEffect(() => {
      setSelectedColor(defaultValue);
    }, [defaultValue]);

    const getLabel = () => {
      if (!label) return <Label htmlFor={id} text={id} visuallyHidden />;
      return React.isValidElement(label) ? (
        label
      ) : (
        <Label marginBottom="0" htmlFor={id} text={label as string} />
      );
    };

    const input = (
      <S.InputContainer
        {...props}
        labelDirection={labelDirection}
        hasLabel={Boolean(label)}
      >
        {getLabel()}
        <S.AlignContainer>
          <S.TriggerContainer>
            <S.Trigger backgroundColor={selectedColor || defaultValue} />
          </S.TriggerContainer>
          <S.Container ref={pickerRef}>
            <S.StyledInput
              hasError={hasError}
              id={id}
              ref={ref}
              type={type}
              name={name}
              disabled
              visuallyDisabled={disabled}
              defaultValue={selectedColor || defaultValue}
              value={selectedColor || defaultValue}
              variant={variant}
            />
            <S.ClickableContainer
              onClick={() => setDisplayColorPicker(!disabled)}
            />
            {displayColorPicker && (
              <S.SketchWrapper>
                <SketchPicker
                  color={selectedColor || defaultValue}
                  onChange={color => {
                    onChange(color?.hex);
                    setSelectedColor(color?.hex);
                  }}
                  disableAlpha
                />
              </S.SketchWrapper>
            )}
          </S.Container>
          {disabled ? null : (
            <S.ResetButton
              variant={variant}
              onClick={() => {
                onChange(resetColor || colors.violet[501]);
                setSelectedColor(resetColor || colors.violet[501]);
              }}
            >
              <Icon icon="undo" width="16px" height="16px" />
            </S.ResetButton>
          )}
          {hasError && errorMessage && (
            <S.ErrorMessageContainer>
              <SpecificError theme={whiteTheme} text={errorMessage} />
            </S.ErrorMessageContainer>
          )}
        </S.AlignContainer>
      </S.InputContainer>
    );

    return input;
  }
);

ColorPickerInput.displayName = "ColorPickerInput";

// /* eslint-disable react/require-default-props */
// ColorPickerInput.propTypes = {
//   id: string.isRequired,
//   labelDirection: oneOf(Object.values(LABEL_POSITIONS))
// };

export default ColorPickerInput;
