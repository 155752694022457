import styled from "styled-components";

export const PlaceholderModuleResource = styled.div`
  height: 335px;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.primaryColor[900]};
  border: 1px dashed ${({ theme }) => theme.primaryColor[500]};
`;
