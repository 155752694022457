import { Heading } from "@arcadia/design-system";
import React from "react";
import { SpaceBlockModal } from "src/js/components/SpaceBlockModal";
import MainContainer from "src/js/components/layout/MainContainer/MainContainer";
import createUrl from "src/js/modules/routing";
import { SpacesGroupsFilters } from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsFilters";
import { SpacesGroupsList } from "src/js/pages/spaces/dashboard/SpacesGroups/SpacesGroupsList";
import { S3Video } from "src/js/settings/settingsVideo";
import { Translate } from "src/js/translation/TranslationProvider";
import { navigateTo } from "src/legacy/modules/history";
import * as S from "./SpacesGroups.styles";
import { BLOCKED_FAKE_GROUPS } from "./SpacesGroupsBlocked.const";

const SpacesGroupsBlocked = ({ spaceId }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <SpaceBlockModal
        video={S3Video.DashboardGroups}
        description="space_block_modal_description"
        primaryCta={() =>
          navigateTo(
            createUrl("dashboard_settings", {
              space_id: spaceId,
              option: "plan"
            })
          )
        }
        primaryCtaLabel="space_block_modal_primary_cta"
        zIndex="890"
      />
      <div style={{ filter: "blur(5px)" }}>
        <MainContainer>
          <S.PageContainer>
            <S.TitleWrapper>
              <Heading level="1">
                <Translate text="groups" />
              </Heading>
              <S.GroupCounter>{5}</S.GroupCounter>
            </S.TitleWrapper>
            <SpacesGroupsFilters
              statusFilterSelected={[]}
              sortFilterSelected={[]}
              setFilter={() => {}}
              removeStatusFilter={() => {}}
              removeAllStatusFilters={() => {}}
              groupNewLink="#"
            />
            <SpacesGroupsList
              filteredGroups={BLOCKED_FAKE_GROUPS}
              totalGroups={5}
            />
          </S.PageContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default SpacesGroupsBlocked;
