import { Events } from "backbone";

import extend from "lodash/extend";
import clone from "lodash/clone";

export const InternalDispatcher = extend(Events);

export const RealTimeDispatcher = clone(Events);

export const NotificationDispatcher = clone(Events);
