import React, { createContext, ReactNode, useContext } from "react";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import { useAppLayout } from "src/js/layout/AppLayout";

import { useSpaceGroupsHeader } from "./hooks/useSpaceGroupsHeader";
import { SpaceGroupsLayoutContextType } from "./SpaceGroupsLayout.types";
import * as S from "./SpaceGroupsLayout.styles";

const SpaceGroupsLayoutContext = createContext<
  SpaceGroupsLayoutContextType | undefined
>(undefined);

export const withSpaceGroupsLayout = Component => {
  const WithSpaceGroupsLayout = props => {
    return (
      <SpaceGroupsLayoutContext.Consumer>
        {context => {
          if (!context) {
            throw new Error(
              "withSpaceGroupsLayout must be used within a SpaceLayoutProvider"
            );
          }
          return <Component {...props} spaceGroupsLayout={context} />;
        }}
      </SpaceGroupsLayoutContext.Consumer>
    );
  };

  return WithSpaceGroupsLayout;
};

export const useSpaceGroupsLayout = () => {
  const context = useContext(SpaceGroupsLayoutContext);
  if (!context) {
    throw new Error("useSpaceLayout must be used within a SpaceLayoutProvider");
  }
  return context;
};

const SpaceGroupsLayout = ({ children }: { children: ReactNode }) => {
  const { headerHeight: topHeaderHeight, shouldHideAllLayouts } =
    useAppLayout();
  const { headerComponent, setHeaderComponent, resetHeaderComponent } =
    useSpaceGroupsHeader();

  return (
    <SpaceGroupsLayoutContext.Provider
      value={{ setHeaderComponent, resetHeaderComponent }}
    >
      <S.SpaceGroupsLayoutWrapper>
        {!shouldHideAllLayouts && (
          <S.SpaceGroupsHeaderWrapper topHeaderHeight={topHeaderHeight}>
            <AnimatePresence exitBeforeEnter>{headerComponent}</AnimatePresence>
          </S.SpaceGroupsHeaderWrapper>
        )}
        {children}
      </S.SpaceGroupsLayoutWrapper>
    </SpaceGroupsLayoutContext.Provider>
  );
};

export default observer(SpaceGroupsLayout);
