//TODO remove this file in the refactor

import {
  getResourcesIdsFromQuiz,
  replaceQuizImageUrl
} from "src/js/modules/exercisesFunction";
import { fetchResource } from "src/js/repository/resourceRepository";

export const fetchResourceImageInTitle = (title: string): Promise<string> => {
  const ids = getResourcesIdsFromQuiz(title);

  return Promise.all(ids.map(id => fetchResource(id)))
    .then(results => {
      let updatedQuizTitle = title;
      results.forEach((res, index) => {
        updatedQuizTitle = replaceQuizImageUrl(
          updatedQuizTitle,
          ids[index],
          res.public_url
        );
      });
      return updatedQuizTitle;
    })
    .catch(error => {
      return title;
    });
};
